define({
  "Switching_Template": [
    {
      "siteProfileUuid": "f6991790-c055-41d2-8ec2-64ab9e089873",
      "id": "f6991790-c055-41d2-8ec2-64ab9e089873",
      "version": 4,
      "name": "switch-stack-3members",
      "namespace": "switching",
      "status": "final",
      "lastUpdatedBy": "admin",
      "lastUpdatedDatetime": 1547851540301,
      "profileAttributes": [
        {
          "key": "day0.templates",
          "attribs": [
            {
              "key": "device.family",
              "value": "Switches and Hubs",
              "attribs": [
                {
                  "key": "device.series",
                  "value": "Cisco Catalyst 9300 Series Switches",
                  "attribs": [
                    {
                      "key": "device.type",
                      "attribs": [
                        {
                          "key": "template.id",
                          "value": "36cbc01c-0fba-44ed-aaa0-df3cafaaadba",
                          "attribs": [
                            {
                              "key": "template.version",
                              "value": "1"
                            },
                            {
                              "key": "template.name",
                              "value": "pnp-stack-switch"
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ],
      "sites": []
    },
    {
      "siteProfileUuid": "g6991790-c055-41d2-8ec2-64ab9e089874",
      "id": "g6991790-c055-41d2-8ec2-64ab9e089874",
      "version": 4,
      "name": "mobExpr-switching-profile",
      "namespace": "switching",
      "status": "final",
      "lastUpdatedBy": "admin",
      "lastUpdatedDatetime": 1547851540301,
      "profileAttributes": [
        {
          "key": "day0.templates",
          "attribs": [
            {
              "key": "device.family",
              "value": "Switches and Hubs",
              "attribs": [
                {
                  "key": "device.series",
                  "value": "Cisco Catalyst 9200 Series Switches",
                  "attribs": [
                    {
                      "key": "device.type",
                      "value": null,
                      "attribs": [
                        {
                          "key": "template.id",
                          "value": "46cbc01c-0fba-44ed-aaa0-df3cafaaadbb",
                          "attribs": [
                            {
                              "key": "template.version",
                              "value": "1"
                            },
                            {
                              "key": "template.name",
                              "value": "Small-Branch-Template"
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "key": "device.family",
              "value": "Switches and Hubs",
              "attribs": [
                {
                  "key": "device.series",
                  "value": "Cisco Catalyst 9200 Series Switches",
                  "attribs": [
                    {
                      "key": "device.type",
                      "value": null,
                      "attribs": [
                        {
                          "key": "template.id",
                          "value": "56cbc01c-0fba-44ed-aaa0-df3cafaaadbc",
                          "attribs": [
                            {
                              "key": "template.version",
                              "value": "1"
                            },
                            {
                              "key": "template.name",
                              "value": "Medium-Branch-Template"
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "key": "device.family",
              "value": "Switches and Hubs",
              "attribs": [
                {
                  "key": "device.series",
                  "value": "Cisco Catalyst 9200 Series Switches",
                  "attribs": [
                    {
                      "key": "device.type",
                      "value": null,
                      "attribs": [
                        {
                          "key": "template.id",
                          "value": "66cbc01c-0fba-44ed-aaa0-df3cafaaadbd",
                          "attribs": [
                            {
                              "key": "template.version",
                              "value": "1"
                            },
                            {
                              "key": "template.name",
                              "value": "Large-Branch-Template"
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ],
      "sites": []
    },
    {
      "siteProfileUuid": "335f67a4-21fe-4b6c-bcb5-1f5b32716607",
      "id": "335f67a4-21fe-4b6c-bcb5-1f5b32716607",
      "version": 4,
      "name": "Wide Area Bonjour Profile",
      "namespace": "switching",
      "status": "final",
      "lastUpdatedBy": "admin",
      "lastUpdatedDatetime": 1565138816125,
      "profileAttributes": [
        {
          "key": "cli.templates",
          "attribs": [
            {
              "key": "device.family",
              "value": "Switches and Hubs",
              "attribs": [
                {
                  "key": "device.series",
                  "attribs": [
                    {
                      "key": "device.type",
                      "attribs": [
                        {
                          "key": "template.id",
                          "value": "1e0e24c6-fcab-461e-b9e7-5b0d0a6f97eb",
                          "attribs": [
                            {
                              "key": "template.version",
                              "value": "1"
                            },
                            {
                              "key": "template.name",
                              "value": "SDG Agent - Controller Configuration"
                            }
                          ]
                        },
                        {
                          "key": "device.tag",
                          "value": "SDG_Agents"
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ],
      "sites": []
    },
    {
      "siteProfileUuid": "335f67a4-1111-2222-bcb5-1f5b32716607",
      "id": "335f67a4-1111-2222-bcb5-1f5b32716607",
      "version": 4,
      "name": "IE_alarm_profile",
      "namespace": "switching",
      "status": "final",
      "lastUpdatedBy": "admin",
      "lastUpdatedDatetime": 1565138816125,
      "profileAttributes": [
        {
          "key": "cli.templates",
          "attribs": [
            {
              "key": "device.family",
              "value": "Switches and Hubs",
              "attribs": [
                {
                  "key": "device.series",
                  "value": "Cisco Catalyst IE3400 Rugged Series",
                  "attribs": [
                    {
                      "key": "device.type",
                      "attribs": [
                        {
                          "key": "template.id",
                          "value": "1e0e24c6-1111-461e-b9e7-5b0d0a6f97eb",
                          "attribs": [
                            {
                              "key": "template.version",
                              "value": "1"
                            },
                            {
                              "key": "template.name",
                              "value": "IE3x00_AlarmRelay_Template"
                            }
                          ]
                        },
                        {
                          "key": "device.tag"
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "key": "device.family",
              "value": "Switches and Hubs",
              "attribs": [
                {
                  "key": "device.series",
                  "value": "Cisco Catalyst IE3300 Rugged Series",
                  "attribs": [
                    {
                      "key": "device.type",
                      "attribs": [
                        {
                          "key": "template.id",
                          "value": "1e0e24c6-1111-461e-b9e7-5b0d0a6f97eb",
                          "attribs": [
                            {
                              "key": "template.version",
                              "value": "1"
                            },
                            {
                              "key": "template.name",
                              "value": "IE3x00_AlarmRelay_Template"
                            }
                          ]
                        },
                        {
                          "key": "device.tag"
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ],
      "sites": []
    }
  ]
});