define({
    "WIFI6_LATENCY_BE": {
        "records": [
            {
                "modificationtime": 1588617300000,
                "nonAxLatency": 3229642,
                "axLatency": 2155576,
                "timestamp": "2020-05-04T18:35:00.000+0000"
            },
            {
                "modificationtime": 1588617600000,
                "nonAxLatency": 3078013,
                "axLatency": 2037861,
                "timestamp": "2020-05-04T18:40:00.000+0000"
            },
            {
                "modificationtime": 1588617900000,
                "nonAxLatency": 3032608,
                "axLatency": 2194055,
                "timestamp": "2020-05-04T18:45:00.000+0000"
            },
            {
                "modificationtime": 1588618200000,
                "nonAxLatency": 2667893,
                "axLatency": 2283162,
                "timestamp": "2020-05-04T18:50:00.000+0000"
            },
            {
                "modificationtime": 1588618500000,
                "nonAxLatency": 2490016,
                "axLatency": 2091507,
                "timestamp": "2020-05-04T18:55:00.000+0000"
            },
            {
                "modificationtime": 1588618800000,
                "nonAxLatency": 2961012,
                "axLatency": 2346008,
                "timestamp": "2020-05-04T19:00:00.000+0000"
            },
            {
                "modificationtime": 1588619100000,
                "nonAxLatency": 3587125,
                "axLatency": 2470248,
                "timestamp": "2020-05-04T19:05:00.000+0000"
            },
            {
                "modificationtime": 1588619400000,
                "nonAxLatency": 3968113,
                "axLatency": 2643234,
                "timestamp": "2020-05-04T19:10:00.000+0000"
            },
            {
                "modificationtime": 1588619700000,
                "nonAxLatency": 2913608,
                "axLatency": 2326928,
                "timestamp": "2020-05-04T19:15:00.000+0000"
            },
            {
                "modificationtime": 1588620000000,
                "nonAxLatency": 3533917,
                "axLatency": 2379779,
                "timestamp": "2020-05-04T19:20:00.000+0000"
            },
            {
                "modificationtime": 1588620300000,
                "nonAxLatency": 2916281,
                "axLatency": 1942010,
                "timestamp": "2020-05-04T19:25:00.000+0000"
            },
            {
                "modificationtime": 1588620600000,
                "nonAxLatency": 726870,
                "axLatency": 895189,
                "timestamp": "2020-05-04T19:30:00.000+0000"
            },
            {
                "modificationtime": 1588620900000,
                "nonAxLatency": 3421487,
                "axLatency": 2452634,
                "timestamp": "2020-05-04T19:35:00.000+0000"
            },
            {
                "modificationtime": 1588621200000,
                "nonAxLatency": 3116065,
                "axLatency": 2136777,
                "timestamp": "2020-05-04T19:40:00.000+0000"
            },
            {
                "modificationtime": 1588621500000,
                "nonAxLatency": 3166118,
                "axLatency": 2372692,
                "timestamp": "2020-05-04T19:45:00.000+0000"
            },
            {
                "modificationtime": 1588621800000,
                "nonAxLatency": 2830173,
                "axLatency": 2078137,
                "timestamp": "2020-05-04T19:50:00.000+0000"
            },
            {
                "modificationtime": 1588622100000,
                "nonAxLatency": 3917257,
                "axLatency": 2415719,
                "timestamp": "2020-05-04T19:55:00.000+0000"
            },
            {
                "modificationtime": 1588622400000,
                "nonAxLatency": 3585226,
                "axLatency": 2173680,
                "timestamp": "2020-05-04T20:00:00.000+0000"
            },
            {
                "modificationtime": 1588622700000,
                "nonAxLatency": 79964,
                "axLatency": 558965,
                "timestamp": "2020-05-04T20:05:00.000+0000"
            },
            {
                "modificationtime": 1588623000000,
                "nonAxLatency": 66278,
                "axLatency": 654658,
                "timestamp": "2020-05-04T20:10:00.000+0000"
            },
            {
                "modificationtime": 1588623300000,
                "nonAxLatency": 4139802,
                "axLatency": 2317447,
                "timestamp": "2020-05-04T20:15:00.000+0000"
            },
            {
                "modificationtime": 1588623600000,
                "nonAxLatency": 15765669,
                "axLatency": 6404673,
                "timestamp": "2020-05-04T20:20:00.000+0000"
            },
            {
                "modificationtime": 1588623900000,
                "nonAxLatency": 3847089,
                "axLatency": 2700946,
                "timestamp": "2020-05-04T20:25:00.000+0000"
            },
            {
                "modificationtime": 1588624200000,
                "nonAxLatency": 18012641,
                "axLatency": 3269425,
                "timestamp": "2020-05-04T20:30:00.000+0000"
            },
            {
                "modificationtime": 1588624500000,
                "nonAxLatency": 2961675,
                "axLatency": 2157148,
                "timestamp": "2020-05-04T20:35:00.000+0000"
            },
            {
                "modificationtime": 1588624800000,
                "nonAxLatency": 2691438,
                "axLatency": 1318088,
                "timestamp": "2020-05-04T20:40:00.000+0000"
            },
            {
                "modificationtime": 1588625100000,
                "nonAxLatency": 2708242,
                "axLatency": 2007781,
                "timestamp": "2020-05-04T20:45:00.000+0000"
            },
            {
                "modificationtime": 1588625400000,
                "nonAxLatency": 2668913,
                "axLatency": 2203209,
                "timestamp": "2020-05-04T20:50:00.000+0000"
            },
            {
                "modificationtime": 1588625700000,
                "nonAxLatency": 2486373,
                "axLatency": 1914878,
                "timestamp": "2020-05-04T20:55:00.000+0000"
            },
            {
                "modificationtime": 1588626000000,
                "nonAxLatency": 2323366,
                "axLatency": 2078697,
                "timestamp": "2020-05-04T21:00:00.000+0000"
            },
            {
                "modificationtime": 1588626300000,
                "nonAxLatency": 6880704,
                "axLatency": 2729376,
                "timestamp": "2020-05-04T21:05:00.000+0000"
            },
            {
                "modificationtime": 1588626600000,
                "nonAxLatency": 1785671,
                "axLatency": 2608046,
                "timestamp": "2020-05-04T21:10:00.000+0000"
            },
            {
                "modificationtime": 1588626900000,
                "nonAxLatency": 1383098.5,
                "axLatency": 2244450,
                "timestamp": "2020-05-04T21:15:00.000+0000"
            },
            {
                "modificationtime": 1588627200000,
                "nonAxLatency": 1608833.5,
                "axLatency": 2179066,
                "timestamp": "2020-05-04T21:20:00.000+0000"
            },
            {
                "modificationtime": 1588627500000,
                "nonAxLatency": 2988615,
                "axLatency": 2185822,
                "timestamp": "2020-05-04T21:25:00.000+0000"
            },
            {
                "modificationtime": 1588627800000,
                "nonAxLatency": 2716672,
                "axLatency": 2204648,
                "timestamp": "2020-05-04T21:30:00.000+0000"
            },
            {
                "modificationtime": 1588628100000,
                "nonAxLatency": 6617140,
                "axLatency": 2279687,
                "timestamp": "2020-05-04T21:35:00.000+0000"
            },
            {
                "modificationtime": 1588628400000,
                "nonAxLatency": 2553338,
                "axLatency": 1546030,
                "timestamp": "2020-05-04T21:40:00.000+0000"
            },
            {
                "modificationtime": 1588628700000,
                "nonAxLatency": 2616728,
                "axLatency": 1927075,
                "timestamp": "2020-05-04T21:45:00.000+0000"
            },
            {
                "modificationtime": 1588629000000,
                "nonAxLatency": 2525937,
                "axLatency": 2124541,
                "timestamp": "2020-05-04T21:50:00.000+0000"
            },
            {
                "modificationtime": 1588629300000,
                "nonAxLatency": 2674044,
                "axLatency": 1781051,
                "timestamp": "2020-05-04T21:55:00.000+0000"
            },
            {
                "modificationtime": 1588629600000,
                "nonAxLatency": 2881685,
                "axLatency": 2093478,
                "timestamp": "2020-05-04T22:00:00.000+0000"
            },
            {
                "modificationtime": 1588629900000,
                "nonAxLatency": 2136526,
                "axLatency": 2924498,
                "timestamp": "2020-05-04T22:05:00.000+0000"
            },
            {
                "modificationtime": 1588630200000,
                "nonAxLatency": 3426204,
                "axLatency": 2490510,
                "timestamp": "2020-05-04T22:10:00.000+0000"
            },
            {
                "modificationtime": 1588630500000,
                "nonAxLatency": 3446127,
                "axLatency": 2516937,
                "timestamp": "2020-05-04T22:15:00.000+0000"
            },
            {
                "modificationtime": 1588630800000,
                "nonAxLatency": 2567839,
                "axLatency": 2679148,
                "timestamp": "2020-05-04T22:20:00.000+0000"
            },
            {
                "modificationtime": 1588631100000,
                "nonAxLatency": 2725474,
                "axLatency": 2051222,
                "timestamp": "2020-05-04T22:25:00.000+0000"
            },
            {
                "modificationtime": 1588631400000,
                "nonAxLatency": 2865643,
                "axLatency": 2123722,
                "timestamp": "2020-05-04T22:30:00.000+0000"
            },
            {
                "modificationtime": 1588631700000,
                "nonAxLatency": 3365450,
                "axLatency": 2507757,
                "timestamp": "2020-05-04T22:35:00.000+0000"
            },
            {
                "modificationtime": 1588632000000,
                "nonAxLatency": 132167,
                "axLatency": 1192114,
                "timestamp": "2020-05-04T22:40:00.000+0000"
            },
            {
                "modificationtime": 1588632300000,
                "nonAxLatency": 3040748,
                "axLatency": 2149702,
                "timestamp": "2020-05-04T22:45:00.000+0000"
            },
            {
                "modificationtime": 1588632600000,
                "nonAxLatency": 1370172,
                "axLatency": 2183477,
                "timestamp": "2020-05-04T22:50:00.000+0000"
            },
            {
                "modificationtime": 1588632900000,
                "nonAxLatency": 2585876,
                "axLatency": 1626793,
                "timestamp": "2020-05-04T22:55:00.000+0000"
            },
            {
                "modificationtime": 1588633200000,
                "nonAxLatency": 3660286,
                "axLatency": 2141058,
                "timestamp": "2020-05-04T23:00:00.000+0000"
            },
            {
                "modificationtime": 1588633500000,
                "nonAxLatency": 4653685,
                "axLatency": 1496177,
                "timestamp": "2020-05-04T23:05:00.000+0000"
            },
            {
                "modificationtime": 1588633800000,
                "nonAxLatency": 8099318,
                "axLatency": 9018903,
                "timestamp": "2020-05-04T23:10:00.000+0000"
            },
            {
                "modificationtime": 1588634100000,
                "nonAxLatency": 99350,
                "axLatency": 1157286,
                "timestamp": "2020-05-04T23:15:00.000+0000"
            },
            {
                "modificationtime": 1588634400000,
                "nonAxLatency": 98415,
                "axLatency": 999633,
                "timestamp": "2020-05-04T23:20:00.000+0000"
            },
            {
                "modificationtime": 1588634700000,
                "nonAxLatency": 106220,
                "axLatency": 1300403,
                "timestamp": "2020-05-04T23:25:00.000+0000"
            },
            {
                "modificationtime": 1588635000000,
                "nonAxLatency": 3174528,
                "axLatency": 2373844,
                "timestamp": "2020-05-04T23:30:00.000+0000"
            },
            {
                "modificationtime": 1588635300000,
                "nonAxLatency": 2364172,
                "axLatency": 1968061,
                "timestamp": "2020-05-04T23:35:00.000+0000"
            },
            {
                "modificationtime": 1588635600000,
                "nonAxLatency": 2338240,
                "axLatency": 1977029,
                "timestamp": "2020-05-04T23:40:00.000+0000"
            },
            {
                "modificationtime": 1588635900000,
                "nonAxLatency": 2191216,
                "axLatency": 1832155,
                "timestamp": "2020-05-04T23:45:00.000+0000"
            },
            {
                "modificationtime": 1588636200000,
                "nonAxLatency": 2632028,
                "axLatency": 2037916,
                "timestamp": "2020-05-04T23:50:00.000+0000"
            },
            {
                "modificationtime": 1588636500000,
                "nonAxLatency": 2710794,
                "axLatency": 2181584,
                "timestamp": "2020-05-04T23:55:00.000+0000"
            },
            {
                "modificationtime": 1588636800000,
                "nonAxLatency": 2269671,
                "axLatency": 1803913,
                "timestamp": "2020-05-05T00:00:00.000+0000"
            },
            {
                "modificationtime": 1588637100000,
                "nonAxLatency": 3101290,
                "axLatency": 2234798,
                "timestamp": "2020-05-05T00:05:00.000+0000"
            },
            {
                "modificationtime": 1588637400000,
                "nonAxLatency": 2263925,
                "axLatency": 1945621,
                "timestamp": "2020-05-05T00:10:00.000+0000"
            },
            {
                "modificationtime": 1588637700000,
                "nonAxLatency": 3034170,
                "axLatency": 2238689,
                "timestamp": "2020-05-05T00:15:00.000+0000"
            },
            {
                "modificationtime": 1588638000000,
                "nonAxLatency": 3474259,
                "axLatency": 2152703,
                "timestamp": "2020-05-05T00:20:00.000+0000"
            },
            {
                "modificationtime": 1588638300000,
                "nonAxLatency": 3943770,
                "axLatency": 2432662,
                "timestamp": "2020-05-05T00:25:00.000+0000"
            },
            {
                "modificationtime": 1588638600000,
                "nonAxLatency": 3348766,
                "axLatency": 2298288,
                "timestamp": "2020-05-05T00:30:00.000+0000"
            },
            {
                "modificationtime": 1588638900000,
                "nonAxLatency": 3763803,
                "axLatency": 2347244,
                "timestamp": "2020-05-05T00:35:00.000+0000"
            },
            {
                "modificationtime": 1588639200000,
                "nonAxLatency": 3309108,
                "axLatency": 2253821,
                "timestamp": "2020-05-05T00:40:00.000+0000"
            },
            {
                "modificationtime": 1588639500000,
                "nonAxLatency": 9942587,
                "axLatency": 3000301,
                "timestamp": "2020-05-05T00:45:00.000+0000"
            },
            {
                "modificationtime": 1588639800000,
                "nonAxLatency": 2620728,
                "axLatency": 2080796,
                "timestamp": "2020-05-05T00:50:00.000+0000"
            },
            {
                "modificationtime": 1588640100000,
                "nonAxLatency": 3976985,
                "axLatency": 1342622,
                "timestamp": "2020-05-05T00:55:00.000+0000"
            },
            {
                "modificationtime": 1588640400000,
                "nonAxLatency": 2795826,
                "axLatency": 2095550,
                "timestamp": "2020-05-05T01:00:00.000+0000"
            },
            {
                "modificationtime": 1588640700000,
                "nonAxLatency": 2710762,
                "axLatency": 2249825,
                "timestamp": "2020-05-05T01:05:00.000+0000"
            },
            {
                "modificationtime": 1588641000000,
                "nonAxLatency": 3582584,
                "axLatency": 2122724,
                "timestamp": "2020-05-05T01:10:00.000+0000"
            },
            {
                "modificationtime": 1588641300000,
                "nonAxLatency": 21494170,
                "axLatency": 2150144,
                "timestamp": "2020-05-05T01:15:00.000+0000"
            },
            {
                "modificationtime": 1588641600000,
                "nonAxLatency": 2140389,
                "axLatency": 1923782,
                "timestamp": "2020-05-05T01:20:00.000+0000"
            },
            {
                "modificationtime": 1588641900000,
                "nonAxLatency": 2290686,
                "axLatency": 2002663,
                "timestamp": "2020-05-05T01:25:00.000+0000"
            },
            {
                "modificationtime": 1588642200000,
                "nonAxLatency": 2703245,
                "axLatency": 2125163,
                "timestamp": "2020-05-05T01:30:00.000+0000"
            },
            {
                "modificationtime": 1588642500000,
                "nonAxLatency": 2826630,
                "axLatency": 1967686,
                "timestamp": "2020-05-05T01:35:00.000+0000"
            },
            {
                "modificationtime": 1588642800000,
                "nonAxLatency": 2773868,
                "axLatency": 1953917,
                "timestamp": "2020-05-05T01:40:00.000+0000"
            },
            {
                "modificationtime": 1588643100000,
                "nonAxLatency": 5293587,
                "axLatency": 2149171,
                "timestamp": "2020-05-05T01:45:00.000+0000"
            },
            {
                "modificationtime": 1588643400000,
                "nonAxLatency": 2465488,
                "axLatency": 1857229,
                "timestamp": "2020-05-05T01:50:00.000+0000"
            },
            {
                "modificationtime": 1588643700000,
                "nonAxLatency": 2953437,
                "axLatency": 2140578,
                "timestamp": "2020-05-05T01:55:00.000+0000"
            },
            {
                "modificationtime": 1588644000000,
                "nonAxLatency": 3050901,
                "axLatency": 2010893,
                "timestamp": "2020-05-05T02:00:00.000+0000"
            },
            {
                "modificationtime": 1588644300000,
                "nonAxLatency": 3631017,
                "axLatency": 2382740,
                "timestamp": "2020-05-05T02:05:00.000+0000"
            },
            {
                "modificationtime": 1588644600000,
                "nonAxLatency": 2962783,
                "axLatency": 2151134,
                "timestamp": "2020-05-05T02:10:00.000+0000"
            },
            {
                "modificationtime": 1588644900000,
                "nonAxLatency": 2708261,
                "axLatency": 1907685,
                "timestamp": "2020-05-05T02:15:00.000+0000"
            },
            {
                "modificationtime": 1588645200000,
                "nonAxLatency": 4503335,
                "axLatency": 2165200,
                "timestamp": "2020-05-05T02:20:00.000+0000"
            },
            {
                "modificationtime": 1588645500000,
                "nonAxLatency": 3031570,
                "axLatency": 1948663,
                "timestamp": "2020-05-05T02:25:00.000+0000"
            },
            {
                "modificationtime": 1588645800000,
                "nonAxLatency": 2606093,
                "axLatency": 2141011,
                "timestamp": "2020-05-05T02:30:00.000+0000"
            },
            {
                "modificationtime": 1588646100000,
                "nonAxLatency": 2660934,
                "axLatency": 2057525,
                "timestamp": "2020-05-05T02:35:00.000+0000"
            },
            {
                "modificationtime": 1588646400000,
                "nonAxLatency": 2754311,
                "axLatency": 2067785,
                "timestamp": "2020-05-05T02:40:00.000+0000"
            },
            {
                "modificationtime": 1588646700000,
                "nonAxLatency": 2867348,
                "axLatency": 2234615,
                "timestamp": "2020-05-05T02:45:00.000+0000"
            },
            {
                "modificationtime": 1588647000000,
                "nonAxLatency": 2879031,
                "axLatency": 2205593,
                "timestamp": "2020-05-05T02:50:00.000+0000"
            },
            {
                "modificationtime": 1588647300000,
                "nonAxLatency": 2504497,
                "axLatency": 1534918,
                "timestamp": "2020-05-05T02:55:00.000+0000"
            },
            {
                "modificationtime": 1588647600000,
                "nonAxLatency": 101027,
                "axLatency": 1147510,
                "timestamp": "2020-05-05T03:00:00.000+0000"
            },
            {
                "modificationtime": 1588647900000,
                "nonAxLatency": 99205,
                "axLatency": 919084,
                "timestamp": "2020-05-05T03:05:00.000+0000"
            },
            {
                "modificationtime": 1588648200000,
                "nonAxLatency": 108655,
                "axLatency": 1160772,
                "timestamp": "2020-05-05T03:10:00.000+0000"
            },
            {
                "modificationtime": 1588648500000,
                "nonAxLatency": 2912926,
                "axLatency": 2194320,
                "timestamp": "2020-05-05T03:15:00.000+0000"
            },
            {
                "modificationtime": 1588648800000,
                "nonAxLatency": 2505294,
                "axLatency": 2162261,
                "timestamp": "2020-05-05T03:20:00.000+0000"
            },
            {
                "modificationtime": 1588649100000,
                "nonAxLatency": 2300222,
                "axLatency": 2568863,
                "timestamp": "2020-05-05T03:25:00.000+0000"
            },
            {
                "modificationtime": 1588649400000,
                "nonAxLatency": 2943726,
                "axLatency": 2355493,
                "timestamp": "2020-05-05T03:30:00.000+0000"
            },
            {
                "modificationtime": 1588649700000,
                "nonAxLatency": 3225854,
                "axLatency": 2280408,
                "timestamp": "2020-05-05T03:35:00.000+0000"
            },
            {
                "modificationtime": 1588650000000,
                "nonAxLatency": 2463278,
                "axLatency": 1858966,
                "timestamp": "2020-05-05T03:40:00.000+0000"
            },
            {
                "modificationtime": 1588650300000,
                "nonAxLatency": 2326153,
                "axLatency": 2081299,
                "timestamp": "2020-05-05T03:45:00.000+0000"
            },
            {
                "modificationtime": 1588650600000,
                "nonAxLatency": 2642665,
                "axLatency": 2300217,
                "timestamp": "2020-05-05T03:50:00.000+0000"
            },
            {
                "modificationtime": 1588650900000,
                "nonAxLatency": 23218799,
                "axLatency": 2849886,
                "timestamp": "2020-05-05T03:55:00.000+0000"
            },
            {
                "modificationtime": 1588651200000,
                "nonAxLatency": 2923578,
                "axLatency": 2133332,
                "timestamp": "2020-05-05T04:00:00.000+0000"
            },
            {
                "modificationtime": 1588651500000,
                "nonAxLatency": 2580029,
                "axLatency": 1873655,
                "timestamp": "2020-05-05T04:05:00.000+0000"
            },
            {
                "modificationtime": 1588651800000,
                "nonAxLatency": 2780546,
                "axLatency": 2186592,
                "timestamp": "2020-05-05T04:10:00.000+0000"
            },
            {
                "modificationtime": 1588652100000,
                "nonAxLatency": 2968597,
                "axLatency": 2345584,
                "timestamp": "2020-05-05T04:15:00.000+0000"
            },
            {
                "modificationtime": 1588652400000,
                "nonAxLatency": 2737965,
                "axLatency": 2186618,
                "timestamp": "2020-05-05T04:20:00.000+0000"
            },
            {
                "modificationtime": 1588652700000,
                "nonAxLatency": 2618292,
                "axLatency": 1317169,
                "timestamp": "2020-05-05T04:25:00.000+0000"
            },
            {
                "modificationtime": 1588653000000,
                "nonAxLatency": 2668717,
                "axLatency": 2802956,
                "timestamp": "2020-05-05T04:30:00.000+0000"
            },
            {
                "modificationtime": 1588653300000,
                "nonAxLatency": 3849723,
                "axLatency": 2635680,
                "timestamp": "2020-05-05T04:35:00.000+0000"
            },
            {
                "modificationtime": 1588653600000,
                "nonAxLatency": 4037835,
                "axLatency": 2425124,
                "timestamp": "2020-05-05T04:40:00.000+0000"
            },
            {
                "modificationtime": 1588653900000,
                "nonAxLatency": 2851459,
                "axLatency": 2158610,
                "timestamp": "2020-05-05T04:45:00.000+0000"
            },
            {
                "modificationtime": 1588654200000,
                "nonAxLatency": 3453122,
                "axLatency": 1865216,
                "timestamp": "2020-05-05T04:50:00.000+0000"
            },
            {
                "modificationtime": 1588654500000,
                "nonAxLatency": 81917,
                "axLatency": 460180,
                "timestamp": "2020-05-05T04:55:00.000+0000"
            },
            {
                "modificationtime": 1588654800000,
                "nonAxLatency": 13945291,
                "axLatency": 2703878,
                "timestamp": "2020-05-05T05:00:00.000+0000"
            },
            {
                "modificationtime": 1588655100000,
                "nonAxLatency": 1706357,
                "axLatency": 6281310,
                "timestamp": "2020-05-05T05:05:00.000+0000"
            },
            {
                "modificationtime": 1588655400000,
                "nonAxLatency": 2247942,
                "axLatency": 2583105,
                "timestamp": "2020-05-05T05:10:00.000+0000"
            },
            {
                "modificationtime": 1588655700000,
                "nonAxLatency": 2742989,
                "axLatency": 2101768,
                "timestamp": "2020-05-05T05:15:00.000+0000"
            },
            {
                "modificationtime": 1588656000000,
                "nonAxLatency": 2544638,
                "axLatency": 2091197,
                "timestamp": "2020-05-05T05:20:00.000+0000"
            },
            {
                "modificationtime": 1588656300000,
                "nonAxLatency": 2405241,
                "axLatency": 2017528,
                "timestamp": "2020-05-05T05:25:00.000+0000"
            },
            {
                "modificationtime": 1588656600000,
                "nonAxLatency": 2452754,
                "axLatency": 1989102,
                "timestamp": "2020-05-05T05:30:00.000+0000"
            },
            {
                "modificationtime": 1588656900000,
                "nonAxLatency": 2756069,
                "axLatency": 2112254,
                "timestamp": "2020-05-05T05:35:00.000+0000"
            },
            {
                "modificationtime": 1588657200000,
                "nonAxLatency": 2303287,
                "axLatency": 1983354,
                "timestamp": "2020-05-05T05:40:00.000+0000"
            },
            {
                "modificationtime": 1588657500000,
                "nonAxLatency": 2675842,
                "axLatency": 2183169,
                "timestamp": "2020-05-05T05:45:00.000+0000"
            },
            {
                "modificationtime": 1588657800000,
                "nonAxLatency": 2679154,
                "axLatency": 2091101,
                "timestamp": "2020-05-05T05:50:00.000+0000"
            },
            {
                "modificationtime": 1588658100000,
                "nonAxLatency": 2416697,
                "axLatency": 2054417,
                "timestamp": "2020-05-05T05:55:00.000+0000"
            },
            {
                "modificationtime": 1588658400000,
                "nonAxLatency": 2790150,
                "axLatency": 2150247,
                "timestamp": "2020-05-05T06:00:00.000+0000"
            },
            {
                "modificationtime": 1588658700000,
                "nonAxLatency": 3295985,
                "axLatency": 2286532,
                "timestamp": "2020-05-05T06:05:00.000+0000"
            },
            {
                "modificationtime": 1588659000000,
                "nonAxLatency": 2630927,
                "axLatency": 2103148,
                "timestamp": "2020-05-05T06:10:00.000+0000"
            },
            {
                "modificationtime": 1588659300000,
                "nonAxLatency": 102736,
                "axLatency": 1212341,
                "timestamp": "2020-05-05T06:15:00.000+0000"
            },
            {
                "modificationtime": 1588659600000,
                "nonAxLatency": 2211325,
                "axLatency": 1920393,
                "timestamp": "2020-05-05T06:20:00.000+0000"
            },
            {
                "modificationtime": 1588659900000,
                "nonAxLatency": 2526257,
                "axLatency": 2256729,
                "timestamp": "2020-05-05T06:25:00.000+0000"
            },
            {
                "modificationtime": 1588660200000,
                "nonAxLatency": 2775243,
                "axLatency": 2154447,
                "timestamp": "2020-05-05T06:30:00.000+0000"
            },
            {
                "modificationtime": 1588660500000,
                "nonAxLatency": 3007630,
                "axLatency": 2255440,
                "timestamp": "2020-05-05T06:35:00.000+0000"
            },
            {
                "modificationtime": 1588660800000,
                "nonAxLatency": 2709983,
                "axLatency": 2330394,
                "timestamp": "2020-05-05T06:40:00.000+0000"
            },
            {
                "modificationtime": 1588661100000,
                "nonAxLatency": 3061107,
                "axLatency": 1981366,
                "timestamp": "2020-05-05T06:45:00.000+0000"
            },
            {
                "modificationtime": 1588661400000,
                "nonAxLatency": 3248304,
                "axLatency": 2322364,
                "timestamp": "2020-05-05T06:50:00.000+0000"
            },
            {
                "modificationtime": 1588661700000,
                "nonAxLatency": 2994162,
                "axLatency": 2093382,
                "timestamp": "2020-05-05T06:55:00.000+0000"
            },
            {
                "modificationtime": 1588662000000,
                "nonAxLatency": 2714211,
                "axLatency": 2357778,
                "timestamp": "2020-05-05T07:00:00.000+0000"
            },
            {
                "modificationtime": 1588662300000,
                "nonAxLatency": 2500448,
                "axLatency": 2027963,
                "timestamp": "2020-05-05T07:05:00.000+0000"
            },
            {
                "modificationtime": 1588662600000,
                "nonAxLatency": 2786057,
                "axLatency": 2152462,
                "timestamp": "2020-05-05T07:10:00.000+0000"
            },
            {
                "modificationtime": 1588662900000,
                "nonAxLatency": 13504386,
                "axLatency": 5569342,
                "timestamp": "2020-05-05T07:15:00.000+0000"
            },
            {
                "modificationtime": 1588663200000,
                "nonAxLatency": 2821029,
                "axLatency": 2191854,
                "timestamp": "2020-05-05T07:20:00.000+0000"
            },
            {
                "modificationtime": 1588663500000,
                "nonAxLatency": 2649967,
                "axLatency": 2073410,
                "timestamp": "2020-05-05T07:25:00.000+0000"
            },
            {
                "modificationtime": 1588663800000,
                "nonAxLatency": 2455447,
                "axLatency": 1611590,
                "timestamp": "2020-05-05T07:30:00.000+0000"
            },
            {
                "modificationtime": 1588664100000,
                "nonAxLatency": 2326238,
                "axLatency": 1834510,
                "timestamp": "2020-05-05T07:35:00.000+0000"
            },
            {
                "modificationtime": 1588664400000,
                "nonAxLatency": 2878820,
                "axLatency": 2063972,
                "timestamp": "2020-05-05T07:40:00.000+0000"
            },
            {
                "modificationtime": 1588664700000,
                "nonAxLatency": 2896533,
                "axLatency": 913392,
                "timestamp": "2020-05-05T07:45:00.000+0000"
            },
            {
                "modificationtime": 1588665000000,
                "nonAxLatency": 3423790,
                "axLatency": 2290799,
                "timestamp": "2020-05-05T07:50:00.000+0000"
            },
            {
                "modificationtime": 1588665300000,
                "nonAxLatency": 2575303,
                "axLatency": 2021100,
                "timestamp": "2020-05-05T07:55:00.000+0000"
            },
            {
                "modificationtime": 1588665600000,
                "nonAxLatency": 1103041,
                "axLatency": 1792494,
                "timestamp": "2020-05-05T08:00:00.000+0000"
            },
            {
                "modificationtime": 1588665900000,
                "nonAxLatency": 2916575,
                "axLatency": 2181049,
                "timestamp": "2020-05-05T08:05:00.000+0000"
            },
            {
                "modificationtime": 1588666200000,
                "nonAxLatency": 2176742,
                "axLatency": 1821103,
                "timestamp": "2020-05-05T08:10:00.000+0000"
            },
            {
                "modificationtime": 1588666500000,
                "nonAxLatency": 2398478,
                "axLatency": 1940152,
                "timestamp": "2020-05-05T08:15:00.000+0000"
            },
            {
                "modificationtime": 1588666800000,
                "nonAxLatency": 4335425,
                "axLatency": 2361133,
                "timestamp": "2020-05-05T08:20:00.000+0000"
            },
            {
                "modificationtime": 1588667100000,
                "nonAxLatency": 2807777,
                "axLatency": 1948729,
                "timestamp": "2020-05-05T08:25:00.000+0000"
            },
            {
                "modificationtime": 1588667400000,
                "nonAxLatency": 2209659,
                "axLatency": 1846834,
                "timestamp": "2020-05-05T08:30:00.000+0000"
            },
            {
                "modificationtime": 1588667700000,
                "nonAxLatency": 2283063,
                "axLatency": 1858238,
                "timestamp": "2020-05-05T08:35:00.000+0000"
            },
            {
                "modificationtime": 1588668000000,
                "nonAxLatency": 2280370,
                "axLatency": 1990205,
                "timestamp": "2020-05-05T08:40:00.000+0000"
            },
            {
                "modificationtime": 1588668300000,
                "nonAxLatency": 2615003,
                "axLatency": 2143393,
                "timestamp": "2020-05-05T08:45:00.000+0000"
            },
            {
                "modificationtime": 1588668600000,
                "nonAxLatency": 19920141,
                "axLatency": 3419125,
                "timestamp": "2020-05-05T08:50:00.000+0000"
            },
            {
                "modificationtime": 1588668900000,
                "nonAxLatency": 17086951,
                "axLatency": 2842066,
                "timestamp": "2020-05-05T08:55:00.000+0000"
            },
            {
                "modificationtime": 1588669200000,
                "nonAxLatency": 9363080,
                "axLatency": 2791906,
                "timestamp": "2020-05-05T09:00:00.000+0000"
            },
            {
                "modificationtime": 1588669500000,
                "nonAxLatency": 17271196,
                "axLatency": 1790015,
                "timestamp": "2020-05-05T09:05:00.000+0000"
            },
            {
                "modificationtime": 1588669800000,
                "nonAxLatency": 2476222,
                "axLatency": 9681413,
                "timestamp": "2020-05-05T09:10:00.000+0000"
            },
            {
                "modificationtime": 1588670100000,
                "nonAxLatency": 2547076,
                "axLatency": 1887598,
                "timestamp": "2020-05-05T09:15:00.000+0000"
            },
            {
                "modificationtime": 1588670400000,
                "nonAxLatency": 2317736,
                "axLatency": 2013962,
                "timestamp": "2020-05-05T09:20:00.000+0000"
            },
            {
                "modificationtime": 1588670700000,
                "nonAxLatency": 2441259,
                "axLatency": 1967446,
                "timestamp": "2020-05-05T09:25:00.000+0000"
            },
            {
                "modificationtime": 1588671000000,
                "nonAxLatency": 2212157,
                "axLatency": 1931597,
                "timestamp": "2020-05-05T09:30:00.000+0000"
            },
            {
                "modificationtime": 1588671300000,
                "nonAxLatency": 3070445,
                "axLatency": 2112663,
                "timestamp": "2020-05-05T09:35:00.000+0000"
            },
            {
                "modificationtime": 1588671600000,
                "nonAxLatency": 2868243,
                "axLatency": 2076386,
                "timestamp": "2020-05-05T09:40:00.000+0000"
            },
            {
                "modificationtime": 1588671900000,
                "nonAxLatency": 2679179,
                "axLatency": 2016619,
                "timestamp": "2020-05-05T09:45:00.000+0000"
            },
            {
                "modificationtime": 1588672200000,
                "nonAxLatency": 2630480,
                "axLatency": 2280784,
                "timestamp": "2020-05-05T09:50:00.000+0000"
            },
            {
                "modificationtime": 1588672500000,
                "nonAxLatency": 2570007,
                "axLatency": 2013426,
                "timestamp": "2020-05-05T09:55:00.000+0000"
            },
            {
                "modificationtime": 1588672800000,
                "nonAxLatency": 3813486,
                "axLatency": 2438898,
                "timestamp": "2020-05-05T10:00:00.000+0000"
            },
            {
                "modificationtime": 1588673100000,
                "nonAxLatency": 2732583,
                "axLatency": 2120216,
                "timestamp": "2020-05-05T10:05:00.000+0000"
            },
            {
                "modificationtime": 1588673400000,
                "nonAxLatency": 2539703,
                "axLatency": 2004131,
                "timestamp": "2020-05-05T10:10:00.000+0000"
            },
            {
                "modificationtime": 1588673700000,
                "nonAxLatency": 2918291,
                "axLatency": 2193894,
                "timestamp": "2020-05-05T10:15:00.000+0000"
            },
            {
                "modificationtime": 1588674000000,
                "nonAxLatency": 2145423,
                "axLatency": 1855728,
                "timestamp": "2020-05-05T10:20:00.000+0000"
            },
            {
                "modificationtime": 1588674300000,
                "nonAxLatency": 2756161,
                "axLatency": 2068011,
                "timestamp": "2020-05-05T10:25:00.000+0000"
            },
            {
                "modificationtime": 1588674600000,
                "nonAxLatency": 2628817,
                "axLatency": 2004487,
                "timestamp": "2020-05-05T10:30:00.000+0000"
            },
            {
                "modificationtime": 1588674900000,
                "nonAxLatency": 2054243,
                "axLatency": 1366470,
                "timestamp": "2020-05-05T10:35:00.000+0000"
            },
            {
                "modificationtime": 1588675200000,
                "nonAxLatency": 53069105,
                "axLatency": 2715332,
                "timestamp": "2020-05-05T10:40:00.000+0000"
            },
            {
                "modificationtime": 1588675500000,
                "nonAxLatency": 15496973,
                "axLatency": 17406847,
                "timestamp": "2020-05-05T10:45:00.000+0000"
            },
            {
                "modificationtime": 1588675800000,
                "nonAxLatency": 12015806,
                "axLatency": 13846151,
                "timestamp": "2020-05-05T10:50:00.000+0000"
            },
            {
                "modificationtime": 1588676100000,
                "nonAxLatency": 18920225,
                "axLatency": 2691922,
                "timestamp": "2020-05-05T10:55:00.000+0000"
            },
            {
                "modificationtime": 1588676400000,
                "nonAxLatency": 2201522,
                "axLatency": 1952507,
                "timestamp": "2020-05-05T11:00:00.000+0000"
            },
            {
                "modificationtime": 1588676700000,
                "nonAxLatency": 1939946,
                "axLatency": 1505327,
                "timestamp": "2020-05-05T11:05:00.000+0000"
            },
            {
                "modificationtime": 1588677000000,
                "nonAxLatency": 3369644,
                "axLatency": 2551496,
                "timestamp": "2020-05-05T11:10:00.000+0000"
            },
            {
                "modificationtime": 1588677300000,
                "nonAxLatency": 2515155,
                "axLatency": 2231588,
                "timestamp": "2020-05-05T11:15:00.000+0000"
            },
            {
                "modificationtime": 1588677600000,
                "nonAxLatency": 2201167,
                "axLatency": 1000652,
                "timestamp": "2020-05-05T11:20:00.000+0000"
            },
            {
                "modificationtime": 1588677900000,
                "nonAxLatency": 3640786,
                "axLatency": 2547232,
                "timestamp": "2020-05-05T11:25:00.000+0000"
            },
            {
                "modificationtime": 1588678200000,
                "nonAxLatency": 14390379,
                "axLatency": 2719174,
                "timestamp": "2020-05-05T11:30:00.000+0000"
            },
            {
                "modificationtime": 1588678500000,
                "nonAxLatency": 2565015,
                "axLatency": 2589207,
                "timestamp": "2020-05-05T11:35:00.000+0000"
            },
            {
                "modificationtime": 1588678800000,
                "nonAxLatency": 2719560,
                "axLatency": 2220355,
                "timestamp": "2020-05-05T11:40:00.000+0000"
            },
            {
                "modificationtime": 1588679100000,
                "nonAxLatency": 2489774,
                "axLatency": 2072733,
                "timestamp": "2020-05-05T11:45:00.000+0000"
            },
            {
                "modificationtime": 1588679400000,
                "nonAxLatency": 215304,
                "axLatency": 920648,
                "timestamp": "2020-05-05T11:50:00.000+0000"
            },
            {
                "modificationtime": 1588679700000,
                "nonAxLatency": 145836,
                "axLatency": 1291650,
                "timestamp": "2020-05-05T11:55:00.000+0000"
            },
            {
                "modificationtime": 1588680000000,
                "nonAxLatency": 105098,
                "axLatency": 1088157,
                "timestamp": "2020-05-05T12:00:00.000+0000"
            },
            {
                "modificationtime": 1588680300000,
                "nonAxLatency": 2446034,
                "axLatency": 2047832,
                "timestamp": "2020-05-05T12:05:00.000+0000"
            },
            {
                "modificationtime": 1588680600000,
                "nonAxLatency": 3019294,
                "axLatency": 2393892,
                "timestamp": "2020-05-05T12:10:00.000+0000"
            },
            {
                "modificationtime": 1588680900000,
                "nonAxLatency": 2935501,
                "axLatency": 2266220,
                "timestamp": "2020-05-05T12:15:00.000+0000"
            },
            {
                "modificationtime": 1588681200000,
                "nonAxLatency": 2566513,
                "axLatency": 1995065,
                "timestamp": "2020-05-05T12:20:00.000+0000"
            },
            {
                "modificationtime": 1588681500000,
                "nonAxLatency": 2389011,
                "axLatency": 1817099,
                "timestamp": "2020-05-05T12:25:00.000+0000"
            },
            {
                "modificationtime": 1588681800000,
                "nonAxLatency": 3067306,
                "axLatency": 2086404,
                "timestamp": "2020-05-05T12:30:00.000+0000"
            },
            {
                "modificationtime": 1588682100000,
                "nonAxLatency": 1333067,
                "axLatency": 1983237,
                "timestamp": "2020-05-05T12:35:00.000+0000"
            },
            {
                "modificationtime": 1588682400000,
                "nonAxLatency": 1428927,
                "axLatency": 0,
                "timestamp": "2020-05-05T12:40:00.000+0000"
            },
            {
                "modificationtime": 1588682700000,
                "nonAxLatency": 3313117,
                "axLatency": 1938725,
                "timestamp": "2020-05-05T12:45:00.000+0000"
            },
            {
                "modificationtime": 1588683000000,
                "nonAxLatency": 2331297,
                "axLatency": 2029672,
                "timestamp": "2020-05-05T12:50:00.000+0000"
            },
            {
                "modificationtime": 1588683300000,
                "nonAxLatency": 2447068,
                "axLatency": 2057359,
                "timestamp": "2020-05-05T12:55:00.000+0000"
            },
            {
                "modificationtime": 1588683600000,
                "nonAxLatency": 2921266,
                "axLatency": 2156788,
                "timestamp": "2020-05-05T13:00:00.000+0000"
            },
            {
                "modificationtime": 1588683900000,
                "nonAxLatency": 2813938,
                "axLatency": 1417886,
                "timestamp": "2020-05-05T13:05:00.000+0000"
            },
            {
                "modificationtime": 1588684200000,
                "nonAxLatency": 2972305,
                "axLatency": 2135057,
                "timestamp": "2020-05-05T13:10:00.000+0000"
            },
            {
                "modificationtime": 1588684500000,
                "nonAxLatency": 1178416,
                "axLatency": 2074098,
                "timestamp": "2020-05-05T13:15:00.000+0000"
            },
            {
                "modificationtime": 1588684800000,
                "nonAxLatency": 2336469,
                "axLatency": 2110864,
                "timestamp": "2020-05-05T13:20:00.000+0000"
            },
            {
                "modificationtime": 1588685100000,
                "nonAxLatency": 2184618,
                "axLatency": 1822049,
                "timestamp": "2020-05-05T13:25:00.000+0000"
            },
            {
                "modificationtime": 1588685400000,
                "nonAxLatency": 3896494,
                "axLatency": 2140483,
                "timestamp": "2020-05-05T13:30:00.000+0000"
            },
            {
                "modificationtime": 1588685700000,
                "nonAxLatency": 11108212,
                "axLatency": 2360375,
                "timestamp": "2020-05-05T13:35:00.000+0000"
            },
            {
                "modificationtime": 1588686000000,
                "nonAxLatency": 2572954,
                "axLatency": 1978576,
                "timestamp": "2020-05-05T13:40:00.000+0000"
            },
            {
                "modificationtime": 1588686300000,
                "nonAxLatency": 2292847,
                "axLatency": 1549901,
                "timestamp": "2020-05-05T13:45:00.000+0000"
            },
            {
                "modificationtime": 1588686600000,
                "nonAxLatency": 2409169,
                "axLatency": 2013288,
                "timestamp": "2020-05-05T13:50:00.000+0000"
            },
            {
                "modificationtime": 1588686900000,
                "nonAxLatency": 2470911,
                "axLatency": 1952958,
                "timestamp": "2020-05-05T13:55:00.000+0000"
            },
            {
                "modificationtime": 1588687200000,
                "nonAxLatency": 2244884,
                "axLatency": 1880925,
                "timestamp": "2020-05-05T14:00:00.000+0000"
            },
            {
                "modificationtime": 1588687500000,
                "nonAxLatency": 3033398,
                "axLatency": 1945225,
                "timestamp": "2020-05-05T14:05:00.000+0000"
            },
            {
                "modificationtime": 1588687800000,
                "nonAxLatency": 119318,
                "axLatency": 979647,
                "timestamp": "2020-05-05T14:10:00.000+0000"
            },
            {
                "modificationtime": 1588688100000,
                "nonAxLatency": 160459,
                "axLatency": 1096517,
                "timestamp": "2020-05-05T14:15:00.000+0000"
            },
            {
                "modificationtime": 1588688400000,
                "nonAxLatency": 156445,
                "axLatency": 969057,
                "timestamp": "2020-05-05T14:20:00.000+0000"
            },
            {
                "modificationtime": 1588688700000,
                "nonAxLatency": 2856421,
                "axLatency": 2280152,
                "timestamp": "2020-05-05T14:25:00.000+0000"
            },
            {
                "modificationtime": 1588689000000,
                "nonAxLatency": 3090947,
                "axLatency": 2112283,
                "timestamp": "2020-05-05T14:30:00.000+0000"
            },
            {
                "modificationtime": 1588689300000,
                "nonAxLatency": 2658305,
                "axLatency": 2234056,
                "timestamp": "2020-05-05T14:35:00.000+0000"
            },
            {
                "modificationtime": 1588689600000,
                "nonAxLatency": 2599419,
                "axLatency": 1806691,
                "timestamp": "2020-05-05T14:40:00.000+0000"
            },
            {
                "modificationtime": 1588689900000,
                "nonAxLatency": 3729975,
                "axLatency": 2110981,
                "timestamp": "2020-05-05T14:45:00.000+0000"
            },
            {
                "modificationtime": 1588690200000,
                "nonAxLatency": 3524706,
                "axLatency": 2137071,
                "timestamp": "2020-05-05T14:50:00.000+0000"
            },
            {
                "modificationtime": 1588690500000,
                "nonAxLatency": 2042693,
                "axLatency": 1846685,
                "timestamp": "2020-05-05T14:55:00.000+0000"
            },
            {
                "modificationtime": 1588690800000,
                "nonAxLatency": 2231227,
                "axLatency": 1894715,
                "timestamp": "2020-05-05T15:00:00.000+0000"
            },
            {
                "modificationtime": 1588691100000,
                "nonAxLatency": 2743510,
                "axLatency": 2181666,
                "timestamp": "2020-05-05T15:05:00.000+0000"
            },
            {
                "modificationtime": 1588691400000,
                "nonAxLatency": 21978217,
                "axLatency": 26205254,
                "timestamp": "2020-05-05T15:10:00.000+0000"
            },
            {
                "modificationtime": 1588691700000,
                "nonAxLatency": 3181595,
                "axLatency": 2393719,
                "timestamp": "2020-05-05T15:15:00.000+0000"
            },
            {
                "modificationtime": 1588692000000,
                "nonAxLatency": 2935555,
                "axLatency": 2375121,
                "timestamp": "2020-05-05T15:20:00.000+0000"
            },
            {
                "modificationtime": 1588692300000,
                "nonAxLatency": 3689225,
                "axLatency": 2383500,
                "timestamp": "2020-05-05T15:25:00.000+0000"
            },
            {
                "modificationtime": 1588692600000,
                "nonAxLatency": 3609962,
                "axLatency": 2273219,
                "timestamp": "2020-05-05T15:30:00.000+0000"
            },
            {
                "modificationtime": 1588692900000,
                "nonAxLatency": 4862198,
                "axLatency": 2901424,
                "timestamp": "2020-05-05T15:35:00.000+0000"
            },
            {
                "modificationtime": 1588693200000,
                "nonAxLatency": 3530949,
                "axLatency": 2643844,
                "timestamp": "2020-05-05T15:40:00.000+0000"
            },
            {
                "modificationtime": 1588693500000,
                "nonAxLatency": 3846199,
                "axLatency": 1806754,
                "timestamp": "2020-05-05T15:45:00.000+0000"
            },
            {
                "modificationtime": 1588693800000,
                "nonAxLatency": 4024217,
                "axLatency": 2374005,
                "timestamp": "2020-05-05T15:50:00.000+0000"
            },
            {
                "modificationtime": 1588694100000,
                "nonAxLatency": 2915428,
                "axLatency": 2352042,
                "timestamp": "2020-05-05T15:55:00.000+0000"
            },
            {
                "modificationtime": 1588694400000,
                "nonAxLatency": 2581950,
                "axLatency": 2004241,
                "timestamp": "2020-05-05T16:00:00.000+0000"
            },
            {
                "modificationtime": 1588694700000,
                "nonAxLatency": 3183847,
                "axLatency": 2110048,
                "timestamp": "2020-05-05T16:05:00.000+0000"
            },
            {
                "modificationtime": 1588695000000,
                "nonAxLatency": 4128115,
                "axLatency": 1848239,
                "timestamp": "2020-05-05T16:10:00.000+0000"
            },
            {
                "modificationtime": 1588695300000,
                "nonAxLatency": 2378609,
                "axLatency": 1850379,
                "timestamp": "2020-05-05T16:15:00.000+0000"
            },
            {
                "modificationtime": 1588695600000,
                "nonAxLatency": 3204185,
                "axLatency": 2048221,
                "timestamp": "2020-05-05T16:20:00.000+0000"
            },
            {
                "modificationtime": 1588695900000,
                "nonAxLatency": 3172635,
                "axLatency": 2193449,
                "timestamp": "2020-05-05T16:25:00.000+0000"
            },
            {
                "modificationtime": 1588696200000,
                "nonAxLatency": 2666374,
                "axLatency": 1904342,
                "timestamp": "2020-05-05T16:30:00.000+0000"
            },
            {
                "modificationtime": 1588696500000,
                "nonAxLatency": 2523849,
                "axLatency": 2197725,
                "timestamp": "2020-05-05T16:35:00.000+0000"
            },
            {
                "modificationtime": 1588696800000,
                "nonAxLatency": 2446920,
                "axLatency": 9154490,
                "timestamp": "2020-05-05T16:40:00.000+0000"
            },
            {
                "modificationtime": 1588697100000,
                "nonAxLatency": 2280427,
                "axLatency": 1798883,
                "timestamp": "2020-05-05T16:45:00.000+0000"
            },
            {
                "modificationtime": 1588697400000,
                "nonAxLatency": 2198866,
                "axLatency": 1578955,
                "timestamp": "2020-05-05T16:50:00.000+0000"
            },
            {
                "modificationtime": 1588697700000,
                "nonAxLatency": 2169896,
                "axLatency": 1897100,
                "timestamp": "2020-05-05T16:55:00.000+0000"
            },
            {
                "modificationtime": 1588698000000,
                "nonAxLatency": 2267955,
                "axLatency": 1984475,
                "timestamp": "2020-05-05T17:00:00.000+0000"
            },
            {
                "modificationtime": 1588698300000,
                "nonAxLatency": 2358263,
                "axLatency": 2017172,
                "timestamp": "2020-05-05T17:05:00.000+0000"
            },
            {
                "modificationtime": 1588698600000,
                "nonAxLatency": 2212795,
                "axLatency": 1887204,
                "timestamp": "2020-05-05T17:10:00.000+0000"
            },
            {
                "modificationtime": 1588698900000,
                "nonAxLatency": 2377135,
                "axLatency": 1851734,
                "timestamp": "2020-05-05T17:15:00.000+0000"
            },
            {
                "modificationtime": 1588699200000,
                "nonAxLatency": 3210163,
                "axLatency": 2077487,
                "timestamp": "2020-05-05T17:20:00.000+0000"
            },
            {
                "modificationtime": 1588699500000,
                "nonAxLatency": 3354118,
                "axLatency": 2078548,
                "timestamp": "2020-05-05T17:25:00.000+0000"
            },
            {
                "modificationtime": 1588699800000,
                "nonAxLatency": 3267366,
                "axLatency": 2170946,
                "timestamp": "2020-05-05T17:30:00.000+0000"
            },
            {
                "modificationtime": 1588700100000,
                "nonAxLatency": 2881948,
                "axLatency": 2089131,
                "timestamp": "2020-05-05T17:35:00.000+0000"
            },
            {
                "modificationtime": 1588700400000,
                "nonAxLatency": 2302466,
                "axLatency": 1829786,
                "timestamp": "2020-05-05T17:40:00.000+0000"
            },
            {
                "modificationtime": 1588700700000,
                "nonAxLatency": 4053614,
                "axLatency": 2383436,
                "timestamp": "2020-05-05T17:45:00.000+0000"
            },
            {
                "modificationtime": 1588701000000,
                "nonAxLatency": 2353306,
                "axLatency": 1781666,
                "timestamp": "2020-05-05T17:50:00.000+0000"
            },
            {
                "modificationtime": 1588701300000,
                "nonAxLatency": 3860741,
                "axLatency": 2299564,
                "timestamp": "2020-05-05T17:55:00.000+0000"
            },
            {
                "modificationtime": 1588701600000,
                "nonAxLatency": 3591913,
                "axLatency": 2252021,
                "timestamp": "2020-05-05T18:00:00.000+0000"
            },
            {
                "modificationtime": 1588701900000,
                "nonAxLatency": 3074969,
                "axLatency": 2152522,
                "timestamp": "2020-05-05T18:05:00.000+0000"
            },
            {
                "modificationtime": 1588702200000,
                "nonAxLatency": 3345424,
                "axLatency": 2083984,
                "timestamp": "2020-05-05T18:10:00.000+0000"
            },
            {
                "modificationtime": 1588702500000,
                "nonAxLatency": 3345023,
                "axLatency": 2695351,
                "timestamp": "2020-05-05T18:15:00.000+0000"
            },
            {
                "modificationtime": 1588702800000,
                "nonAxLatency": 2726961,
                "axLatency": 2181754,
                "timestamp": "2020-05-05T18:20:00.000+0000"
            },
            {
                "modificationtime": 1588703100000,
                "nonAxLatency": 3077157,
                "axLatency": 2590254,
                "timestamp": "2020-05-05T18:25:00.000+0000"
            }
        ],
        "insights": null
    }
})