define({
    "JourneyMapTemplate": 
    {
        "data": {
            "name": "DNAC Features",
            "children": [
                {
                    "name": "AIOps",
                    "children": [
                        {
                            "name": "End to end visibility",
                            "children": [
                                {
                                    "name": "Health Dashboard",
                                    "link": "/dna/assurance/dashboards/health/overall",
                                    "package": "assurance",
                                    "tooltip-disabled": "Assurance package is not installed.",
                                    "tooltip-normal": "Enables proactive monitoring and insights from the network, devices, and applications to predict problems faster. Please onboard one or more devices to start using Assurance Dashboards.",
                                    "tooltip-filled": "Enables proactive monitoring and insights from your network, devices, and applications to predict problems faster.",
                                    "tooltip-normal-width": 320,
                                    "tooltip-filled-width": 320,
                                    "tooltip-disabled-width": 320,
                                    "ncp-id": "assurance-dashboards"
                                },
                                {
                                    "name": "360 Views",
                                    "link": "/dna/assurance/dashboards/health/overall",
                                    "package": "assurance",
                                    "tooltip-disabled": "Assurance package is not installed.",
                                    "tooltip-normal": "Facilitates zooming in to specific devices on your network and analyze their health. Please onboard one or more devices to  view their 360-degree view in Assurance.",
                                    "tooltip-filled": "Facilitates zooming in to specific devices on your network and analyze their health.",
                                    "tooltip-normal-width": 320,
                                    "tooltip-filled-width": 320,
                                    "tooltip-disabled-width": 320,
                                    "ncp-id": "assurance-360"
                                },
                                {
                                    "name": "Wireless Maps",
                                    "link": "/dna/design/networkHierarchy",
                                    "package": "network-visibility",
                                    "tooltip-disabled": "Network Visibility package is not installed.",
                                    "tooltip-normal": "Visualize wireless network heatmaps in both 2D and 3D. Upload a floor map, add obstacles and place access points to view heat maps.",
                                    "tooltip-filled": "Visualize wireless network heatmaps in both 2D and 3D.",
                                    "ncp-id": "wirelessMaps"
                                }
                            ]
                        },
                        {
                            "name": "Observability",
                            "children": [
                                {
                                    "name": "Application Experience",
                                    "link": "/dna/assurance/dashboards/health/application",
                                    "package": "assurance",
                                    "tooltip-disabled": "Assurance package is not installed.",
                                    "tooltip-normal": "Send application data to view application experience health for your network and get insight into the performance of applications. Enable it now.",
                                    "tooltip-filled": "View Application experience health for your network and get insight into the performance of applications.",
                                    "tooltip-normal-width": 320,
                                    "tooltip-filled-width": 320,
                                    "tooltip-disabled-width": 320,
                                    "ncp-id": "assurance-appx"
                                },
                                {
                                    "name": "AI Network Analytics",
                                    "link": "/dna/assurance/trends/baselines",
                                    "package": "network-visibility",
                                    "tooltip-disabled": "Learn the expected behavior of network KPI's and investigate potential risks.",
                                    "tooltip-normal": "Learn the expected behavior of network KPI's and investigate potential risks.",
                                    "tooltip-filled": "Learn the expected behavior of network KPI's and investigate potential risks.",
                                    "ncp-id": "kairosBaselines"
                                },
                                {
                                    "name": "ThousandEyes Integration",
                                    "link": "/dna/systemSettings/settings?settings-item=thousanadeyesconfiguration",
                                    "package": "assurance",
                                    "tooltip-disabled": "Assurance package is not installed.",
                                    "tooltip-normal": "Provide ThousandEyes tests information running on enterprise catalyst 9300/9400 device agents for Application Health dashboard. Please ensure that devices are onboarded, deploy ThousandEyes agent on the desired monitoring devices and enabled ThousandEyes integration.",
                                    "tooltip-filled": "Provide ThousandEyes tests information running on enterprise catalyst 9300/9400 device agents for Application Health dashboard.",
                                    "tooltip-normal-width": 320,
                                    "tooltip-filled-width": 320,
                                    "tooltip-disabled-width": 320,
                                    "ncp-id": "assurance-appx-thousandeyes-intg"
                                },
                                {
                                    "name": "Collaboration Integration",
                                    "link": "/dna/systemSettings/settings",
                                    "package": "assurance",
                                    "tooltip-disabled": "Assurance package is not installed.",
                                    "tooltip-normal": "Get deep insights into collaboration meeting for specific clients. Enable Webex/MS Teams now.",
                                    "tooltip-filled": "Improve client experience with insights into collaboration meeting.",
                                    "tooltip-normal-width": 320,
                                    "tooltip-filled-width": 320,
                                    "tooltip-disabled-width": 320,
                                    "ncp-id": "assurance-appx-webex-ms-teams-intg"
                                },
                                {
                                    "name": "Location Service",
                                    "link": "/dna/systemSettings/settings?settings-item=DNASpaces-CMXServers",
                                    "package": "network-visibility",
                                    "tooltip-disabled": "Network Visibility package is not installed.",
                                    "tooltip-normal": "Allow to get the exact location of your wireless clients, rogue access points and interferers on the floor map within the Cisco DNA Center user interface. Connect Cisco Spaces/CMX to be able to visualize client location on floor maps.",
                                    "tooltip-filled": "Allow to get the exact location of your wireless clients, rogue access points and interferers on the floor map within the Cisco DNA Center user interface.",
                                    "ncp-id": "locationService"
                                },
                                {
                                    "name": "Network Service Analytics",
                                    "tooltip-normal": "View and monitor all the DHCP/AAA/DNS server transactions reported by wireless controllers in your network. Try it now.",
                                    "tooltip-filled": "View and monitor all the DHCP/AAA/DNS server transactions reported by wireless controllers in your network."
                                },
                                {
                                    "name": "POE Analytics",
                                    "link": "/dna/assurance/dashboards/poe",
                                    "package": "assurance",
                                    "tooltip-disabled": "Assurance package is not installed.",
                                    "tooltip-normal": "There are no PoE devices in your network. Please make sure that the devices are active.",
                                    "tooltip-filled": "Enables monitoring PoE devices in your network.",
                                    "tooltip-normal-width": 320,
                                    "tooltip-filled-width": 320,
                                    "tooltip-disabled-width": 320,
                                    "ncp-id": "assurance-poe"
                                }
                            ]
                        },
                        {
                            "name": "Insights and Action",
                            "children": [
                                {
                                    "name": "Issue Dashboard",
                                    "link": "/dna/assurance/dashboards/issues-events/issues/open",
                                    "package": "assurance",
                                    "tooltip-disabled": "Assurance package is not installed.",
                                    "tooltip-normal": "Proactively get alerted on any network issues and view open and resolved issues. Your network currently does not show any issues. Please ensure that devices are onboarded and visible in Assurance dashboards.",
                                    "tooltip-filled": "Proactively get alerted on any network issues and view open and resolved issues",
                                    "tooltip-normal-width": 320,
                                    "tooltip-filled-width": 320,
                                    "tooltip-disabled-width": 320,
                                    "ncp-id": "assurance-issues"
                                },
                                {
                                    "name": "ITSM Integration, Issue Subscription"
                                },
                                {
                                    "name": "Intelligent Capture",
                                    "link": "/dna/assurance/manage/icap/client-schedule-capture",
                                    "package": "icap-automation",
                                    "tooltip-disabled": "ICAP Automation package is not installed.",
                                    "tooltip-normal": "Allows scheduling and analyzing intelligent packet capture on Access Points in your network. To schedule one or more packet captures, navigate to the intelligent-capture page by clicking on ICAP. Try it now.",
                                    "tooltip-filled": "Schedule and analyze intelligent packet capture on Access Points in your network.",
                                    "tooltip-normal-width": 320,
                                    "tooltip-filled-width": 320,
                                    "tooltip-disabled-width": 320,
                                    "ncp-id": "assurance-icap"
                                },
                                {
                                    "name": "AI Network Analytics: Network Insights",
                                    "link": "/dna/assurance/trends/trends-and-insights",
                                    "package": "network-visibility",
                                    "tooltip-disabled": "Learn and investigate key insights on network performance.",
                                    "tooltip-normal": "Learn and investigate key insights on network performance.",
                                    "tooltip-filled": "Learn and investigate key insights on network performance.",
                                    "ncp-id": "kairosNetworkInsights"
                                },
                                {
                                    "name": "True Trace",
                                    "tooltip-normal": "You can perform a path trace between two nodes in your network and collect packet capture at the same time. Try it now.",
                                    "tooltip-filled": "Perform a path trace between two nodes in your network and collect packet capture at the same time."
                                },
                                {
                                    "name": "Network Reasoner",
                                    "link": "/dna/tools/network-reasoner/home",
                                    "package": "machine-reasoning",
                                    "tooltip-disabled": "Machine Reasoning Engine package is not installed.",
                                    "tooltip-normal": "Allows you to troubleshoot various issues on your network quickly using machine reasoning technology. Try it now.",
                                    "tooltip-filled": "Troubleshoot various issues on your network quickly using machine reasoning technology.",
                                    "ncp-id": "networkReasoner"
                                },
                                {
                                    "name": "AI Enhanced RRM",
                                    "link": "/dna/assurance/trends/RRMControlCenter",
                                    "package": "ai-network-analytics",
                                    "tooltip-disabled": "The AI Network Analytics package (includes the AI-Enhanced RRM service) is not installed.",
                                    "tooltip-normal": "Continuously optimize your network with machine learning algorithms to define the behavior of a radio frequency (RF) network within a building enabled with enhanced Radio Resource Management (RRM). Enable it now.",
                                    "tooltip-filled": "Continue to optimize your network with machine learning algorithms to define the behavior of a radio frequency (RF) network within a building enabled with enhanced Radio Resource Management (RRM)",
                                    "ncp-id": "sunrayAiEnhancedRrm"
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "NetOps",
                    "children": [
                        {
                            "name": "Simplified Onboarding",
                            "children": [
                                {
                                    "name": "Network Discovery",
                                    "link": "/dna/tools/discovery/dashboard",
                                    "package": "network-visibility",
                                    "tooltip-disabled": "This service is not installed.",
                                    "tooltip-normal": "The Discovery feature scans the devices in your network and sends the list of discovered devices to inventory. Start network discovery now",
                                    "tooltip-filled": "The Discovery feature scans the devices in your network and sends the list of discovered devices to inventory. Add more devices to the inventory.",
                                    "ncp-id": "discovery"
                                },
                                {
                                    "name": "Learn Device Config",
                                    "link": "/dna/workflows/brownfield-workflow/wirelessController",
                                    "package": "network-visibility",
                                    "tooltip-disabled": "Device inventory service is not installed.",
                                    "tooltip-normal": "Allows you to learn the configurations from existing sites with pre-existing devices configuration such as Cisco Wireless Controllers and save the configurations at the global level. Learn configuration now.",
                                    "tooltip-filled": "Allows you to learn the configurations from existing sites with pre-existing devices configuration such as Cisco Wireless Controllers and save the configurations at the global level.",
                                    "ncp-id": "inventory"
                                },
                                {
                                    "name": "Plug and Play",
                                    "link": "/dna/provision/devices/plugandplay",
                                    "package": "device-onboarding",
                                    "tooltip-disabled": "Device onboarding service is not installed.",
                                    "tooltip-normal": "Plug and Play provisioning provides a way to automatically and remotely provision and onboard new network devices with minimal network administrator and field personnel involvement. Onboard devices now.",
                                    "tooltip-filled": "Plug and Play provisioning provides a way to automatically and remotely provision and onboard new network devices with minimal network administrator and field personnel involvement.",
                                    "ncp-id": "pnpPluginConv"
                                },
                                {
                                    "name": "PnP Connect",
                                    "link": "/dna/systemSettings/settings?settings-item=pnpConnect",
                                    "package": "device-onboarding",
                                    "tooltip-disabled": "Device onboarding service is not installed.",
                                    "tooltip-normal": "Automatically import device from cisco plug and play cloud portal to Cisco DNA Center. Register Pnp connect and add smart account.",
                                    "tooltip-filled": "Automatically import device from cisco plug and play cloud portal to Cisco DNA Center. Register Pnp connect and add smart account.",
                                    "ncp-id": "pnpPluginSettings"
                                }
                            ]
                        },
                        {
                            "name": "Reporting, Compliance & Security",
                            "children": [
                                {
                                    "name": "Generate Network Reports",
                                    "link": "/data-sets-reports",
                                    "package": "network-visibility",
                                    "tooltip-disabled": "Report service is not installed",
                                    "tooltip-normal": "You can utilize data from the Reports feature to derive insights into your network and its operation. By reporting this data in several formats and providing flexible scheduling and configuration options, both data and reports are easily customized to meet your operational needs.",
                                    "tooltip-filled": "You can utilize data from the Reports feature to derive insights into your network and its operation. By reporting this data in several formats and providing flexible scheduling and configuration options, both data and reports are easily customized to meet your operational needs.",
                                    "ondemand-api-url": "/api/dnacaap/v1/daas/core/data-set",
                                    "ondemand-api-response-attr": "response"
                                },
                                {
                                    "name": "Configuration Compliance",
                                    "link": "/dna/provision/devices/inventory/list",
                                    "package": "network-visibility",
                                    "tooltip-disabled": "This service is not installed.",
                                    "tooltip-normal": "Compliance helps in identifying any intent deviation or out-of-band changes in the network that may be injected or reconfigured without affecting the original content. A network administrator can conveniently identify devices in Cisco DNA Center that do not meet compliance requirements for the different aspects of compliance, such as software images, PSIRT, network profiles, and so on.",
                                    "tooltip-filled": "Compliance helps in identifying any intent deviation or out-of-band changes in the network that may be injected or reconfigured without affecting the original content. A network administrator can conveniently identify devices in Cisco DNA Center that do not meet compliance requirements for the different aspects of compliance, such as software images, PSIRT, network profiles, and so on.",
                                    "tooltip-normal-width": "400",
                                    "ncp-id": "compliance"
                                },
                                {
                                    "name": "Security Advisories",
                                    "link": "/dna/tools/security-advisories/overview",
                                    "package": "machine-reasoning",
                                    "tooltip-disabled": "Machine Reasoning Engine package is not installed.",
                                    "tooltip-normal": "Provides insights into the impact of security advisories on your network, broken down by severity.",
                                    "tooltip-filled": "Provides insights into the impact of security advisories on your network, broken down by severity.",
                                    "ncp-id": "securityAdvisories"
                                },
                                {
                                    "name": "Image Repository",
                                    "link": "design/swim/image-families",
                                    "package": "image-management",
                                    "tooltip-disabled": "This service is not installed.",
                                    "tooltip-normal": "Updates the software on devices managed by Cisco DNA Center. You can tag an image as golden. Designating an image as golden saves you time by eliminating the need to make repetitive configuration changes and ensures consistency across your devices.",
                                    "tooltip-filled": "Updates the software on devices managed by Cisco DNA Center. You can tag an image as golden. Designating an image as golden saves you time by eliminating the need to make repetitive configuration changes and ensures consistency across your devices.",
                                    "tooltip-normal-width": "400",
                                    "ncp-id": "swimNew"
                                },
                                {
                                    "name": "Network Bug Identifier",
                                    "link": "/dna/tools/bug-identifier/overview",
                                    "package": "machine-reasoning",
                                    "tooltip-disabled": "Machine Reasoning Engine package is not installed.",
                                    "tooltip-normal": "Provides insights into the impact of defects on your network, broken down by severity.",
                                    "tooltip-filled": "Provides insights into the impact of defects on your network, broken down by severity.",
                                    "ncp-id": "networkBugIdentifier"
                                }
                            ]
                        },
                        {
                            "name": "Network Change Management",
                            "children": [
                                {
                                    "name": "Templates Hub",
                                    "link": "/dna/tools/templatesHub/templateList",
                                    "package": "network-visibility",
                                    "tooltip-disabled": "Network Device templates package is not installed.",
                                    "tooltip-normal": "Design templates with predefined configuration by using parameterized elements or variables. After creating a template, you can use them to deploy your devices in one or more sites that are configured anywhere in your network.",
                                    "tooltip-filled": "Design templates with predefined configuration by using parameterized elements or variables. After creating a template, you can use them to deploy your devices in one or more sites that are configured anywhere in your network.",
                                    "tooltip-normal-width": "400",
                                    "ncp-id": "templateHub"
                                },
                                {
                                    "name": "IP Address Manager (IPAM)",
                                    "link": "/dna/design/networkSettings/ipAddressPools",
                                    "package": "network-visibility",
                                    "tooltip-disabled": "Network Visibility package is not installed.",
                                    "tooltip-normal": "You can configure Cisco DNA Center to communicate with an external IP address manager (IPAM). When you create, reserve, or delete any IP address pool, Cisco DNA Center conveys this information to your external IPAM.",
                                    "tooltip-filled": "You can configure Cisco DNA Center to communicate with an external IP address manager (IPAM). When you create, reserve, or delete any IP address pool, Cisco DNA Center conveys this information to your external IPAM.",
                                    "ncp-id": "ipam"
                                },
                                {
                                    "name": "IP Access Control",
                                    "tooltip-normal": "IP access control allows you to control the access to Cisco DNA Center based on the IP address of the host or network.",
                                    "tooltip-filled": "IP access control allows you to control the access to Cisco DNA Center based on the IP address of the host or network.",
                                    "link": "dna/policy/ipacl/policies"
                                },
                                {
                                    "name": "Application QoS",
                                    "link": "/dna/policy/applicationQoS/application-qos",
                                    "package": "application-policy",
                                    "tooltip-disabled": "Application Policy package is not installed.",
                                    "tooltip-normal": "You can configure QoS in your network using application policies in Cisco DNA Center. By configuring QoS, you can ensure that network traffic is handled in such a way that makes the most efficient use of network resources while still adhering to the objectives of the business, such as guaranteeing that voice quality meets enterprise standards, or ensuring a high Quality of Experience (QoE) for video.",
                                    "tooltip-filled": "You can configure QoS in your network using application policies in Cisco DNA Center. By configuring QoS, you can ensure that network traffic is handled in such a way that makes the most efficient use of network resources while still adhering to the objectives of the business, such as guaranteeing that voice quality meets enterprise standards, or ensuring a high Quality of Experience (QoE) for video.",
                                    "tooltip-normal-width": 320,
                                    "tooltip-filled-width": 320,
                                    "tooltip-disabled-width": 320,
                                    "ncp-id": "application-policy"
                                },
                                {
                                    "name": "Network Profile",
                                    "link": "/dna/design/networkProfiles",
                                    "package": "network-visibility",
                                    "tooltip-disabled": "Network Profile is not installed.",
                                    "tooltip-normal": "Network profiles allow you to configure settings and apply them to a specific site or group of sites. You can create network profiles for various elements in Cisco DNA Center",
                                    "tooltip-filled": "Network profiles allow you to configure settings and apply them to a specific site or group of sites. You can create network profiles for various elements in Cisco DNA Center",
                                    "ncp-id": "network-profiles"
                                },
                                {
                                    "name": "Device Refresh",
                                    "tooltip-normal": "Device Refresh feature allows you to replace older AP models with the newer AP models using the Cisco DNA Center workflow.",
                                    "tooltip-filled": "Device Refresh feature allows you to replace older AP models with the newer AP models using the Cisco DNA Center workflow.",
                                    "link": "/dna/workflows/network-refresh/taskNameStep"
                                },
                                {
                                    "name": "Device Replacement (RMA)",
                                    "link": "/dna/workflows/device-replacement/taskname",
                                    "package": "network-visibility",
                                    "tooltip-disabled": "This service is not installed.",
                                    "tooltip-normal": "The Return Material Authorization (RMA) provides a common workflows to replace failed routers, switches, and APs.",
                                    "tooltip-filled": "The Return Material Authorization (RMA) provides a common workflows to replace failed routers, switches, and APs.",
                                    "tooltip-normal-width": "400",
                                    "ncp-id": "rmaDeviceView"
                                }
                            ]
                        }
                    ]
                },
                {
                    "name": "DevOps",
                    "children": [
                        {
                            "name": "Configuration",
                            "children": [
                                {
                                    "name": "Ansible Collections",
                                    "link": "https://galaxy.ansible.com/cisco/dnac",
                                    "external": true,
                                    "link-only": true,
                                    "description": "The dnacenter-ansible project provides an Ansible collection for managing and automating your Cisco DNA Center environment. It consists of a set of modules and roles for performing tasks related to DNA Center."
                                }
                            ]
                        },
                        {
                            "name": "Orcherstration",
                            "children": [
                                {
                                    "name": "Terraform",
                                    "link": "https://registry.terraform.io/providers/cisco-en-programmability/dnacenter",
                                    "external": true,
                                    "link-only": true,
                                    "description": "The terraform-provider-dnacenter provides a Terraform provider for managing and automating your Cisco DNA Center environment."
                                }
                            ]
                        },
                        {
                            "name": "Automation",
                            "children": [
                                {
                                    "name": "Developer Guides",
                                    "link": "https://developer.cisco.com/dnacenter/",
                                    "external": true,
                                    "link-only": true,
                                    "description": "Get full access to use cases, code and more on everything automation."
                                },
                                {
                                    "name": "Python SDK",
                                    "link": "https://dnacentersdk.readthedocs.io/en/latest/",
                                    "external": true,
                                    "link-only": true,
                                    "description": "The package represents all of the Cisco DNA Center API interactions via native Python tools. Making working with the Cisco DNA Center APIs in Python a native and natural experience."
                                },
                                {
                                    "name": "Intent-based APIs",
                                    "link": "/dna/platform/app/consumer-portal/developer-toolkit/apis",
                                    "link-only": true,
                                    "description": "Intent-based APIs available for programmability"
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    },
    "JourneyMapDataTemplate":
    {
        "data": {
            "assurance-icap": {
                "hasCompletedCaptures": false
            },
            "assurance-appx-webex-ms-teams-intg": {
                "hasDevices": true
            },
            "assurance-appx-thousandeyes-intg": {
                "hasDevices": false
            },
            "assurance-poe": {
                "hasPoeDevices": true
            },
            "assurance-dashboards": {
                "hasDevices": true
            },
            "assurance-issues": {
                "hasIssues": true
            },
            "sunrayAiEnhancedRrm": {
                "aiSiteCount": false,
                "aiRrmEnabled": true
            },
            "assurance-360": {
                "hasDevices": true
            },
            "assurance-appx": {
                "hasDevices": false
            },
            "networkReasoner": {
                "hasNetworkReasoner": true
            },
            "networkBugIdentifier": {
                "hasNetworkBugIdentifier": true
            },
            "securityAdvisories": {
                "hasSecurityAdvisories": true
            },
            "application-policy": {
                "hasDevices": true
            },
            "swimNew": {
                "hasDoneUpgrade": true
            },
            "kairosNetworkInsights": {
                "isOnboarded": true
            },
            "kairosBaselines": {
                "isOnboarded": true
            },
            "rmaDeviceView": {
                "hasDevices": false
            },
            "discovery": {
                "hasDiscovery": true
            },
            "compliance": {
                "hasDoneUpgrade": true
            },
            "network-profiles": {
                "hasProfiles": true
            },
            "pnpPluginConv": {
                "hasDevices": true
            },
            "ipam": {
                "hasIpam": true
            },
            "inventory": {
                "hasDevices": true
            },
            "grouping": {
                "hasSites": true,
                "hasSitesAndDevices": true
            },
            "locationService": {
                "hasLocationServices": false
            },
            "pnpPluginSettings": {
                "hasDevices": true
            },
            "wirelessMaps": {
                "hasWirelessMaps": false
            },
            "templateHub": {
                "hasCLITemplates": true
            }
        },
        "errors": {}
    }
})