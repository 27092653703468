define({
    "CloudConnectivitySense_Template":{
        "status": 200,
        "message": "",
        "body": {
          "posts": [],
          "meta": {
            "pagination": {
              "page": 1,
              "limit": 15,
              "pages": 1,
              "total": 4,
              "next": null,
              "prev": null
            }
          }
        }
      }
});