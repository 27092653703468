define({
    "TIME_Template": {
      "version":"1.0",
      "response":[
        {
          "timeType":"GLOBAL",
          "time":1514913900000
        },
        {
          "timeType":"CLIENT",
          "time":1514913900000
       },
       {
         "timeType":"NETWORK",
         "time":1514914200000
       },
       {
         "timeType":"CURRENT",
         "time":1514914692990
       },
       {
        "timeType":"POE",
        "time":1514914692990
      }
    ]}
});
