define({
  "HOST_LISTING": {
    "version": "1.0",
    "response": [{
      "id": "6C:19:C0:BD:87:C9",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "Grace.Smith",
      "hostName": "Grace.Smith-iPad",
      "hostOs": "Apple-iPad",
      "hostVersion": null,
      "subType": "Apple-iPad",
      "lastUpdated": 1525023087661,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 7
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 2
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 2
        }
      ],
      "hostMac": "6C:19:C0:BD:87:C9",
      "hostIpV4": "10.30.100.27",
      "hostIpV6": [
        "2001:420:81:450::4ade:cfa5"
      ],
      "authType": "WPA/WPA2+NAC_POSTURE_REDIR",
      "vlanId": "100",
      "ssid": "LA-Corporate2",
      "frequency": "5.0",
      "channel": 153,
      "channelWidth": 20,
      "apGroup": "LA1",
      "location": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "clientConnection": "LA2-AP3802-23",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-22.0",
      "avgRssi": "-22.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
      "snr": "8.0",
      "avgSnr": "8.0",
      "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "173.0",
      "txBytes": "7448.0",
      "rxBytes": "91362.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "-1.0",
      "dnsFailure": "-1.0",
      "onboarding": {
        "averageRunDuration": "5000",
        "maxRunDuration": "5000",
        "averageAssocDuration": "2500",
        "maxAssocDuration": "2800",
        "averageAuthDuration": "2800",
        "maxAuthDuration": "2800",
        "averageDhcpDuration": "2800",
        "maxDhcpDuration": "2800",
        "maxRoamingDuration": "5000",
        "aaaServerIp": "192.168.139.168",
        "dhcpServerIp": "10.13.4.2",
        "authDoneTime": 1527204768355,
        "assocDoneTime": 1527204732979,
        "dhcpDoneTime": 1527204738372
      },
      "onboardingTime": 1527169633409,
      "clientType": "OLD",
      "port": null,
      "usage": 279106
    },
    {
      "id": "A8:BE:27:36:70:11",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "Grace.Smith",
      "hostName": "Grace.Smith-iPhone",
      "hostOs": null,
      "hostVersion": "iOS11.0",
      "subType": "iPhone 7",
      "lastUpdated": 1525023087661,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 8
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 4
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 4
        }
      ],
      "hostMac": "A8:BE:27:36:70:11",
      "hostIpV4": "10.30.100.45",
      "hostIpV6": [
        "2001:420:81:450::fac1:afe2"
      ],
      "authType": "DHCP+WPA/WPA2+NAC_POSTURE_REDIR",
      "vlanId": "100",
      "ssid": "LA-Corporate3",
      "frequency": "5.0",
      "channel": "153",
      "apGroup": "LA1",
      "location": "USA/LA/LA-Level16",
      "clientConnection": "LA1-AP3802-31",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-50.0",
      "avgRssi": "-50.0",
      "snr": "39.0",
      "avgSnr": "39.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "674.0",
      "txBytes": "7448.0",
      "rxBytes": "91362.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "-1.0",
      "dnsFailure": "-1.0",
      "onboarding": {
        "averageRunDuration": "2000",
        "maxRunDuration": "2000",
        "averageAssocDuration": "1000",
        "maxAssocDuration": "1000",
        "averageAuthDuration": "1000",
        "maxAuthDuration": "1000",
        "averageDhcpDuration": "1000",
        "maxDhcpDuration": "1000",
        "aaaServerIp": "192.168.139.168",
        "dhcpServerIp": "10.13.4.2",
        "authDoneTime": 1527204768355,
        "assocDoneTime": 1527204732979,
        "dhcpDoneTime": 1527204738372
      },
      "onboardingTime": 1527169633409,
      "port": null,
      "usage": 38868
    },
    {
      "id": "A8:BE:27:36:70:12",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "DR.Dogood",
      "hostName": "DR.Dogood-iPhone",
      "hostOs": null,
      "hostVersion": "iOS11.0",
      "subType": "iPhone 7",
      "lastUpdated": 1525023087661,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 8
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 4
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 4
        }
      ],
      "hostMac": "A8:BE:27:36:70:12",
      "hostIpV4": "10.30.100.47",
      "hostIpV6": [
        "fe80::7e46:85ff:fe20:3cd5"
      ],
      "authType": "DHCP+WPA/WPA2+NAC_POSTURE_REDIR",
      "vlanId": "100",
      "ssid": "LA-Corporate3",
      "frequency": "5.0",
      "channel": "153",
      "apGroup": "LA1",
      "location": "USA/LA/LA-Level16",
      "clientConnection": "LA1-AP3802-31",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-50.0",
      "avgRssi": "-50.0",
      "snr": "39.0",
      "avgSnr": "39.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "670.0",
      "txBytes": "7448.0",
      "rxBytes": "91362.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "-1.0",
      "dnsFailure": "-1.0",
      "onboarding": {
        "averageRunDuration": "2000",
        "maxRunDuration": "1200",
        "maxRoamingDuration": "250",
        "averageAssocDuration": "600",
        "maxAssocDuration": "600",
        "averageAuthDuration": "600",
        "maxAuthDuration": "600",
        "averageDhcpDuration": "600",
        "maxDhcpDuration": "600",
        "aaaServerIp": "192.168.139.168",
        "dhcpServerIp": "10.13.4.2",
        "authDoneTime": 1527204768355,
        "assocDoneTime": 1527204732979,
        "dhcpDoneTime": 1527204738372
      },
      "onboardingTime": 1527169633409,
      "port": null,
      "usage": 34567,
    },
    {
      "id": "B8:27:EB:0C:57:C8",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "john.zoidberg",
      "hostName": "John-PC",
      "hostOs": "dhcpcd-6.7.1:Linux-4.9.53-v7+:armv7l:BCM2835",
      "hostVersion": null,
      "subType": "Linux-Workstation",
      "lastUpdated": 1525399771624,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 8
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 4
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 6
        }
      ],
      "hostMac": "B8:27:EB:0C:57:C8",
      "hostIpV4": "10.13.4.108",
      "hostIpV6": [
        "fe80::7e46:85ff:fe20:4cd5"
      ],
      "authType": "DHCP+WPA/WPA2+NAC_POSTURE_REDIR",
      "vlanId": null,
      "ssid": "LA-Guest2",
      "frequency": "2.4",
      "channel": 6,
      "apGroup": "LA1",
      "location": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "clientConnection": "LA2-AP3802-23",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-56.0",
      "avgRssi": "-56.0",
      "snr": "38.0",
      "avgSnr": "38.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "860.0",
      "txBytes": "8364.0",
      "rxBytes": "8690.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "-1.0",
      "dnsFailure": "-1.0",
      "onboarding": {
        "averageRunDuration": "1200",
        "maxRunDuration": "1200",
        "averageAssocDuration": "600",
        "maxAssocDuration": "600",
        "averageAuthDuration": "600",
        "maxAuthDuration": "600",
        "averageDhcpDuration": "600",
        "maxDhcpDuration": "600",
        "maxRoamingDuration": "1200",
        "aaaServerIp": "192.168.139.168",
        "dhcpServerIp": "10.13.4.2",
        "authDoneTime": 1527204768355,
        "assocDoneTime": 1527204732979,
        "dhcpDoneTime": 1527204738372
      },
      "onboardingTime": 1527169633409,
      "port": null,
      "usage": 345678,
    },
    {
      "id": "A8:B5:27:36:70:09",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "Grace.Smith",
      "hostName": "Grace.Smith-Galaxy-S20",
      "hostOs": "Android 10",
      "hostVersion": null,
      "subType": "Samsung Galaxy S20",
      "lastUpdated": 1525301404148,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 9
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 9
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 8
        }
      ],
      "hostMac": "A8:B5:27:36:70:09",
      "hostIpV4": "10.30.100.30",
      "hostIpV6": [
        "2001:420:81:450::be3f:eb31"
      ],
      "authType": "WPA/WPA2+DOT1X",
      "vlanId": "10",
      "vnid": "0",
      "ssid": "@CorpSSID_98",
      "frequency": "5.0",
      "channel": "44.0",
      "apGroup": "default-group",
      "location": "Netherlands/AMS/Level3",
      "clientConnection": "C9120.D244",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-44.0",
      "avgRssi": "-44.0",
      "snr": "39.0",
      "avgSnr": "39.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "820.0",
      "txBytes": "2440.0",
      "rxBytes": "5811.0",
      "rxRetryPct": "0.0",
      "dnsResponse": "3.0",
      "dnsRequest": "3.0",
      "dnsSuccess": "-1.0",
      "dnsFailure": "-1.0",
      "onboarding": {
        "averageRunDuration": "1000",
        "maxRunDuration": "1000",
        "averageAssocDuration": "500",
        "maxAssocDuration": "500",
        "averageAuthDuration": "500",
        "maxAuthDuration": "500",
        "averageDhcpDuration": "500",
        "maxDhcpDuration": "500",
        "maxRoamingDuration": "1000",
        "aaaServerIp": "192.168.139.168",
        "dhcpServerIp": "10.13.4.2",
        "authDoneTime": 1527204768355,
        "assocDoneTime": 1527204732979,
        "dhcpDoneTime": 1527204738372
      },
      "onboardingTime": 1527169633409,
      "port": null,
      "usage": 23456,
      "clientType": "OLD",
      "iosCapable": false,
      "linkSpeed": 0,
      "remoteEndDuplexMode": null,
      "txLinkError": 0,
      "rxLinkError": 0,
      "txRate": 0,
      "rxRate": 0,
      "dot11Protocol": "PROTOCOL_802_11_AX_5",
      "slotId": 0,
      "versionTime": 1580515380000
    },    
    {
      "id": "B8:27:EB:CA:AA:88",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "Grace.Smith",
      "hostName": "Grace.Smith-PC",
      "hostOs": "Windows 10",
      "hostVersion": null,
      "subType": "Windows-Workstation",
      "lastUpdated": 1525399758756,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 8
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 8
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 8
        }
      ],
      "hostMac": "B8:27:EB:CA:AA:88",
      "hostIpV4": "10.30.100.20",
      "hostIpV6": [
        "2001:420:81:450::adb4:4ea1"
      ],
      "authType": "DHCP+WPA/WPA2+NAC_POSTURE_REDIR",
      "vlanId": "100",
      "ssid": "LA-Topsecret2",
      "frequency": "2.4",
      "channel": "6",
      "apGroup": "LA1",
      "location": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "clientConnection": "LA2-AP3802-23",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-46.0",
      "avgRssi": "-46.0",
      "snr": "38.0",
      "avgSnr": "38.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "860.0",
      "txBytes": "0.0",
      "rxBytes": "128244.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "-1.0",
      "dnsFailure": "-1.0",
      "onboarding": {
        "averageRunDuration": "800",
        "maxRunDuration": "800",
        "averageAssocDuration": "400",
        "maxAssocDuration": "400",
        "averageAuthDuration": "400",
        "maxAuthDuration": "400",
        "averageDhcpDuration": "400",
        "maxDhcpDuration": "400",
        "maxRoamingDuration": "800",
        "aaaServerIp": "192.168.139.168",
        "dhcpServerIp": "10.13.4.2",
        "authDoneTime": 1527204768355,
        "assocDoneTime": 1527204732979,
        "dhcpDoneTime": 1527204738372
      },
      "onboardingTime": 1527169633409,
      "port": null,
      "usage": 15542,
      "intelCapable": true,
      "deviceVendor":"Intel",
      "hwModel":"AX210 160MHz",
      "protocol": "8.0",
      "dot11ProtocolCapability":"7.0",
      "modelName":"GB-BXi3-5010",
      "powerMode":"MEDIUM_POWER_25_5_W",
      "powerType":"AC",
      "protocol":"4",
      "firmwareVersion":"22.130.00.05"
       },
    {
      "id": "6C:19:C0:BD:87:C7",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "Gordon.Thomson",
      "hostName": "Gordon.Thomson-iPad",
      "hostOs": "Apple-iPad",
      "hostVersion": null,
      "subType": "Apple-iPad",
      "lastUpdated": 1525023087661,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 2
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 1
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 0
        }
      ],
      "hostMac": "6C:19:C0:BD:87:C7",
      "hostIpV4": "10.30.100.41",
      "hostIpV6": [
        "fe80::4aa:40a6:6cf8:4ea2"
      ],
      "authType": "WPA/WPA2+NAC_POSTURE_REDIR",
      "vlanId": "100",
      "ssid": "LA-Corporate2",
      "frequency": "5.0",
      "channel": 153,
      "channelWidth": 20,
      "apGroup": "LA1",
      "location": "Global/North America/USA/Nevada/Las Vegas/LV01/Flr-LV1-1",
      "clientConnection": "LA2-AP3802-23",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-73.0",
      "avgRssi": "-73.0",
      "snr": "12.0",
      "avgSnr": "12.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "20.0",
      "txBytes": "7448.0",
      "rxBytes": "91362.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "-1.0",
      "dnsFailure": "-1.0",
      "onboarding": {
        "averageRunDuration": "11000",
        "maxRunDuration": "11000",
        "averageAssocDuration": "6000",
        "maxAssocDuration": "6000",
        "averageAuthDuration": "6000",
        "maxAuthDuration": "6000",
        "averageDhcpDuration": "6000",
        "maxDhcpDuration": "6000",
        "maxRoamingDuration": "11000",
        "aaaServerIp": "10.13.1.113",
        "dhcpServerIp": "10.13.4.1",
        "authDoneTime": 1527165218091,
        "assocDoneTime": 1527165179208,
        "dhcpDoneTime": 1527165184711
      },
      "onboardingTime": 1527169633409,
      "port": null,
      "usage": 45672
    },
    {
      "id": "B8:27:EB:72:11:FA",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "shaggy.rogers",
      "hostName": "Shaggy-PC",
      "hostOs": "dhcpcd-6.7.1:Linux-4.9.53-v7+:armv7l:BCM2835",
      "hostVersion": null,
      "subType": "Linux-Workstation",
      "lastUpdated": 1525399145843,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 3
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 1
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 0
        }
      ],
      "hostMac": "B8:27:EB:72:11:FA",
      "hostIpV4": "10.30.100.19",
      "hostIpV6": [
        "fe80::4aa:40a1:6cf8:4ea2"
      ],
      "authType": "WPA/WPA2+NAC_POSTURE_REDIR",
      "vlanId": "100",
      "ssid": "LA-Corporate2",
      "frequency": "2.4",
      "channel": 6,
      "apGroup": "LA1",
      "location": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "clientConnection": "LA2-AP3802-23",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-68.0",
      "avgRssi": "-68.0",
      "snr": "16.0",
      "avgSnr": "16.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "60.0",
      "txBytes": "3.2323121E7",
      "rxBytes": "1.5713199E7",
      "rxRetryPct": "0.0",
      "dnsSuccess": "-1.0",
      "dnsFailure": "-1.0",
      "onboarding": {
        "averageRunDuration": "9000",
        "maxRunDuration": "9000",
        "averageAssocDuration": "4500",
        "maxAssocDuration": "4500",
        "averageAuthDuration": "4500",
        "maxAuthDuration": "4500",
        "averageDhcpDuration": "4500",
        "maxDhcpDuration": "4500",
        "maxRoamingDuration": "9000",
        "aaaServerIp": "192.168.139.168",
        "dhcpServerIp": "10.13.4.2",
        "authDoneTime": 1527204768365,
        "assocDoneTime": 1527204732979,
        "dhcpDoneTime": 1527204738352
      },
      "onboardingTime": 1527169633409,
      "port": null,
      "usage": 14322
    },
    {
      "id": "B8:27:EB:72:11:FF",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "Mason.Davis",
      "hostName": "Mason-Davis",
      "hostOs": "dhcpcd-6.7.1:Linux-4.9.53-v7+:armv7l:BCM2848",
      "hostVersion": null,
      "subType": "Linux-Workstation",
      "lastUpdated": 1525399145843,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 7
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 2
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 2
        }
      ],
      "hostMac": "B8:27:EB:72:11:FF",
      "hostIpV4": "10.30.100.53",
      "hostIpV6": [
        "fe80::4aa:40a2:6cf8:4ea2"
      ],
      "authType": "WPA/WPA2+NAC_POSTURE_REDIR",
      "vlanId": "100",
      "ssid": "LA-Corporate2",
      "frequency": "2.4",
      "channel": 6,
      "apGroup": "LA1",
      "location": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "clientConnection": "LA2-AP3802-23",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-75.0",
      "avgRssi": "-75.0",
      "snr": "7.0",
      "avgSnr": "7.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "465.0",
      "txBytes": "3.2323121E7",
      "rxBytes": "1.5713199E7",
      "rxRetryPct": "0.0",
      "dnsSuccess": "-1.0",
      "dnsFailure": "-1.0",
      "onboarding": {
        "averageRunDuration": "6800",
        "maxRunDuration": "6800",
        "averageAssocDuration": "3400",
        "maxAssocDuration": "3400",
        "averageAuthDuration": "3400",
        "maxAuthDuration": "3400",
        "averageDhcpDuration": "3400",
        "maxDhcpDuration": "3400",
        "maxRoamingDuration": "6800",
        "aaaServerIp": "192.168.139.168",
        "dhcpServerIp": "10.13.4.2",
        "authDoneTime": 1527204768365,
        "assocDoneTime": 1527204732979,
        "dhcpDoneTime": 1527204738352
      },
      "onboardingTime": 1527169633409,
      "port": null,
      "usage": 87651
    },
    {
      "id": "B8:27:EB:72:11:F1",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "Richard.Davis",
      "hostName": "Richard-Device",
      "hostOs": "dhcpcd-6.7.1:Linux-4.9.53-v7+:armv7l:BCM2848",
      "hostVersion": null,
      "subType": "Linux-Workstation",
      "lastUpdated": 1525399145843,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 6
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 2
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 2
        }
      ],
      "hostMac": "B8:27:EB:72:11:F1",
      "hostIpV4": "10.30.100.54",
      "hostIpV6": [
        "fe80::4aa:40a3:6cf8:4ea2"
      ],
      "authType": "WPA/WPA2+NAC_POSTURE_REDIR",
      "vlanId": "100",
      "ssid": "LA-Corporate2",
      "frequency": "2.4",
      "channel": 6,
      "apGroup": "LA1",
      "location": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "clientConnection": "LA2-AP3802-23",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-53.0",
      "avgRssi": "-53.0",
      "snr": "8.0",
      "avgSnr": "8.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "375.0",
      "txBytes": "3.2323121E7",
      "rxBytes": "1.5713199E7",
      "rxRetryPct": "0.0",
      "dnsSuccess": "-1.0",
      "dnsFailure": "-1.0",
      "onboarding": {
        "averageRunDuration": "6800",
        "maxRunDuration": "6800",
        "averageAssocDuration": "3400",
        "maxAssocDuration": "3400",
        "averageAuthDuration": "3400",
        "maxAuthDuration": "3400",
        "averageDhcpDuration": "3400",
        "maxDhcpDuration": "3400",
        "maxRoamingDuration": "6800",
        "aaaServerIp": "192.168.139.168",
        "dhcpServerIp": "10.13.4.2",
        "authDoneTime": 1527204768365,
        "assocDoneTime": 1527204732979,
        "dhcpDoneTime": 1527204738352
      },
      "onboardingTime": 1527169633409,
      "port": null,
      "usage": 43762
    },
    {
      "id": "B8:27:EB:72:11:FE",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "Jacob.Wilson",
      "hostName": "Jacob-Wilson",
      "hostOs": "dhcpcd-6.7.1:Linux-4.9.53-v7+:armv7l:BCM3848",
      "hostVersion": null,
      "subType": "Linux-Workstation",
      "lastUpdated": 1525399145843,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 10
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 4
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 6
        }
      ],
      "hostMac": "B8:27:EB:72:11:FE",
      "hostIpV4": "10.30.100.55",
      "hostIpV6": [
        "fe80::4aa:40a3:6cf8:4ea3"
      ],
      "authType": "WPA/WPA2+NAC_POSTURE_REDIR",
      "vlanId": "100",
      "ssid": "LA-Corporate2",
      "frequency": "2.4",
      "channel": 6,
      "apGroup": "LA1",
      "location": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "clientConnection": "LA2-AP3802-23",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-43.0",
      "avgRssi": "-43.0",
      "snr": "43.0",
      "avgSnr": "43.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "1500.0",
      "txBytes": "3.2323121E7",
      "rxBytes": "1.5713199E7",
      "rxRetryPct": "0.0",
      "dnsSuccess": "-1.0",
      "dnsFailure": "-1.0",
      "onboarding": {
        "averageRunDuration": "1000",
        "maxRunDuration": "1000",
        "averageAssocDuration": "500",
        "maxAssocDuration": "500",
        "averageAuthDuration": "500",
        "maxAuthDuration": "500",
        "averageDhcpDuration": "500",
        "maxDhcpDuration": "500",
        "maxRoamingDuration": "10",
        "aaaServerIp": "192.168.139.168",
        "dhcpServerIp": "10.13.4.2",
        "authDoneTime": 1527204768365,
        "assocDoneTime": 1527204732979,
        "dhcpDoneTime": 1527204738352
      },
      "onboardingTime": 1527169633409,
      "port": null,
      "usage": 18921
    },
    {
      "id": "7C:46:85:20:80:A3",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "oliverp",
      "hostName": "oliverp-Google_Nexus",
      "hostOs": "android-dhcp-7.1.1",
      "hostVersion": null,
      "subType": "Android-Motorola",
      "lastUpdated": 1527171600000,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 8
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 8
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 8
        }
      ],
      "hostMac": "7C:46:85:20:80:A3",
      "hostIpV4": "10.13.4.106",
      "hostIpV6": [
        "fe80::7e46:85ff:fe20:80a3"
      ],
      "authType": "DHCP+WPA/WPA2",
      "vlanId": "0",
      "ssid": "@CorpSSID",
      "frequency": "5.0",
      "channel": 48,
      "apGroup": "Wave2_APs",
      "location": "SAN JOSE/SJC23/Flr1",
      "clientConnection": "AP9120_2",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-20.0",
      "avgRssi": "-20.0",
      "snr": "75.0",
      "avgSnr": "75.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "786.0",
      "txBytes": "3559.0",
      "rxBytes": "3364.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "",
      "dnsFailure": "",
      "onboarding": {
        "averageRunDuration": "1800",
        "maxRunDuration": "1800",
        "averageAssocDuration": "900",
        "maxAssocDuration": "900",
        "averageAuthDuration": "900",
        "maxAuthDuration": "900",
        "averageDhcpDuration": "900",
        "maxDhcpDuration": "900",
        "maxRoamingDuration": "2000",
        "aaaServerIp": "10.13.1.113",
        "dhcpServerIp": "10.13.4.1",
        "authDoneTime": 1527165218091,
        "assocDoneTime": 1527165179208,
        "dhcpDoneTime": 1527165184711
      },
      "onboardingTime": 1527165218208,
      "port": null,
      "usage": 18723
    },
    {
      "id": "00:81:C4:CD:AB:90",
      "connectionStatus": "DISCONNECTED",
      "hostType": "WIRELESS",
      "userId": null,
      "hostName": null,
      "hostOs": "Sensor-Client-AP",
      "hostVersion": null,
      "subType": "Sensor-Client-AP",
      "lastUpdated": 1527171687001,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 1
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 1
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 0
        }
      ],
      "hostMac": "00:81:C4:CD:AB:90",
      "hostIpV4": "10.13.4.105",
      "hostIpV6": [
        "fe80::4aa:40a3:3cf8:4ea3"
      ],
      "authType": "DHCP+WPA/WPA2+PSK",
      "vlanId": "0",
      "ssid": "c9800AP11AX",
      "frequency": "5.0",
      "channel": null,
      "apGroup": "Wave1_APs",
      "location": "SAN JOSE/SJC23/Flr1",
      "clientConnection": "AP3700B",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-80.0",
      "avgRssi": "-80.0",
      "snr": "8.0",
      "avgSnr": "8.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "19.0",
      "txBytes": "33544.0",
      "rxBytes": "31768.0",
      "dnsSuccess": "",
      "dnsFailure": "",
      "onboarding": {
        "averageRunDuration": "12000",
        "maxRunDuration": "12000",
        "averageAssocDuration": "6000",
        "maxAssocDuration": "6000",
        "averageAuthDuration": "6000",
        "maxAuthDuration": "6000",
        "averageDhcpDuration": "6000",
        "maxDhcpDuration": "6000",
        "maxRoamingDuration": "12000",
        "aaaServerIp": "10.42.136.32",
        "dhcpServerIp": "10.13.4.1",
        "authDoneTime": 1527171632230,
        "assocDoneTime": 1527171633992,
        "dhcpDoneTime": 1527171460387
      },
      "onboardingTime": 1527171460387,
      "port": null,
      "usage": 27891
    },
    {
      "id": "14:F6:5A:67:62:A5",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "george.baker",
      "hostName": "George-PC",
      "hostOs": "dhcpcd-6.7.1:Linux-4.9.53-v7+:armv7l:BCM2835",
      "hostVersion": null,
      "subType": "Linux-Workstation",
      "lastUpdated": 1527171600000,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 8
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 8
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 9
        }
      ],
      "hostMac": "14:F6:5A:67:62:A5",
      "hostIpV4": "10.13.4.175",
      "hostIpV6": [
        "fe80::4aa:40a3:4cf8:4ea3"
      ],
      "authType": "DHCP+WPA/WPA2",
      "vlanId": "0",
      "ssid": "@CorpSSID",
      "frequency": "5.0",
      "channel": null,
      "apGroup": "Wave2_APs",
      "location": "SAN JOSE/SJC23/Flr1",
      "clientConnection": "AP9120_2",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-54.0",
      "avgRssi": "-54.0",
      "snr": "38.0",
      "avgSnr": "38.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "680.0",
      "txBytes": "5486.0",
      "rxBytes": "5887.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "",
      "dnsFailure": "",
      "onboarding": {
        "averageRunDuration": "1000",
        "maxRunDuration": "1000",
        "averageAssocDuration": "500",
        "maxAssocDuration": "500",
        "averageAuthDuration": "500",
        "maxAuthDuration": "500",
        "averageDhcpDuration": "500",
        "maxDhcpDuration": "500",
        "maxRoamingDuration": "120",
        "aaaServerIp": "10.13.1.113",
        "dhcpServerIp": "10.13.4.1",
        "authDoneTime": 1527169633039,
        "assocDoneTime": 1527169632901,
        "dhcpDoneTime": 1527169633409
      },
      "onboardingTime": 1527169633409,
      "port": null,
      "usage": 54321
    },
    {
      "id": "FC:C2:DE:EC:15:46",
      "connectionStatus": "DISCONNECTED",
      "hostType": "WIRELESS",
      "userId": "noah",
      "hostName": "Galaxy-Tab-S3",
      "hostOs": "dhcpcd-5.5.6",
      "hostVersion": null,
      "subType": "Android",
      "lastUpdated": 1527206778353,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 4
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 2
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 2
        }
      ],
      "hostMac": "FC:C2:DE:EC:15:46",
      "hostIpV4": "10.13.4.245",
      "hostIpV6": [
        "fe80::fec2:deff:feec:1546"
      ],
      "authType": "DHCP+WPA/WPA2",
      "vlanId": "0",
      "ssid": "@CorpSSID",
      "frequency": "5.0",
      "channel": null,
      "apGroup": "Wave2_APs",
      "location": "SAN JOSE/SJC23/Flr1",
      "clientConnection": "AP3800-1",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-75.0",
      "avgRssi": "-75.0",
      "snr": "19.0",
      "avgSnr": "19.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "173.0",
      "txBytes": "5641.0",
      "rxBytes": "10401.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "",
      "dnsFailure": "",
      "onboarding": {
        "averageRunDuration": "3800",
        "maxRunDuration": "3800",
        "averageAssocDuration": "1800",
        "maxAssocDuration": "1800",
        "averageAuthDuration": "1800",
        "maxAuthDuration": "1800",
        "averageDhcpDuration": "1800",
        "maxDhcpDuration": "1800",
        "maxRoamingDuration": "5000",
        "aaaServerIp": "10.13.1.113",
        "dhcpServerIp": "10.13.4.1",
        "authDoneTime": 1527032053276,
        "assocDoneTime": 1527032053020,
        "dhcpDoneTime": 1527032058043
      },
      "onboardingTime": 1527032058043,
      "port": null,
      "usage": 76512
    },
    {
      "id": "FC:C2:DE:EC:15:48",
      "connectionStatus": "DISCONNECTED",
      "hostType": "WIRELESS",
      "userId": "HANNAH",
      "hostName": "Galaxy-Tab-S5",
      "hostOs": "dhcpcd-5.5.6",
      "hostVersion": null,
      "subType": "Android",
      "lastUpdated": 1527206778353,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 5
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 2
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 2
        }
      ],
      "hostMac": "FC:C2:DE:EC:15:48",
      "hostIpV4": "10.13.4.250",
      "hostIpV6": [
        "fe80::fec2:deff:feec:1546"
      ],
      "authType": "DHCP+WPA/WPA2",
      "vlanId": "0",
      "ssid": "@CorpSSID",
      "frequency": "5.0",
      "channel": null,
      "apGroup": "Wave2_APs",
      "location": "SAN JOSE/SJC23/Flr1",
      "clientConnection": "AP3800-1",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-59.0",
      "avgRssi": "-59.0",
      "snr": "8.0",
      "avgSnr": "8.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "173.0",
      "txBytes": "5641.0",
      "rxBytes": "10401.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "",
      "dnsFailure": "",
      "onboarding": {
        "averageRunDuration": "5800",
        "maxRunDuration": "5800",
        "averageAssocDuration": "2800",
        "maxAssocDuration": "2800",
        "averageAuthDuration": "2800",
        "maxAuthDuration": "2800",
        "averageDhcpDuration": "2800",
        "maxDhcpDuration": "2800",
        "maxRoamingDuration": "5800",
        "aaaServerIp": "10.13.1.113",
        "dhcpServerIp": "10.13.4.1",
        "authDoneTime": 1527032053276,
        "assocDoneTime": 1527032053020,
        "dhcpDoneTime": 1527032058043
      },
      "onboardingTime": 1527032058043,
      "port": null,
      "usage": 67812
    },
    {
      "id": "7C:46:85:20:7C:D6",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "ethan",
      "hostName": "ethan-Google-Pixel-C",
      "hostOs": "android-dhcp-7.1.1",
      "hostVersion": null,
      "subType": "Android-Motorola",
      "lastUpdated": 1527216000000,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 7
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 2
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 2
        }
      ],
      "hostMac": "7C:46:85:20:7C:D6",
      "hostIpV4": "10.13.4.251",
      "hostIpV6": [
        "fe80::7e46:85ff:fe20:7cd6"
      ],
      "authType": "DHCP+WPA/WPA2",
      "vlanId": "0",
      "ssid": "@CorpSSID",
      "frequency": "5.0",
      "channel": null,
      "apGroup": "Wave2_APs",
      "location": "SAN JOSE/SJC23/Flr1",
      "clientConnection": "AP3800-1",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-76.0",
      "avgRssi": "-76.0",
      "snr": "32.0",
      "avgSnr": "32.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "700.0",
      "txBytes": "6640.0",
      "rxBytes": "11284.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "",
      "dnsFailure": "",
      "onboarding": {
        "averageRunDuration": "1800",
        "maxRunDuration": "1800",
        "averageAssocDuration": "900",
        "maxAssocDuration": "900",
        "averageAuthDuration": "900",
        "maxAuthDuration": "900",
        "averageDhcpDuration": "900",
        "maxDhcpDuration": "900",
        "maxRoamingDuration": "3000",
        "aaaServerIp": "10.13.1.113",
        "dhcpServerIp": "10.13.4.1",
        "authDoneTime": 1527204768355,
        "assocDoneTime": 1527204732979,
        "dhcpDoneTime": 1527204738372
      },
      "onboardingTime": 1527204738372,
      "port": null,
      "usage": 65721
    },
    {
      "id": "DC:CE:C1:2E:BD:D1",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "ethan",
      "hostName": "ethan-sensor1",
      "hostOs": "Sensor-Client-AP",
      "hostVersion": null,
      "subType": "Sensor-Client-AP",
      "lastUpdated": 1527216000000,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 7
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 2
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 2
        }
      ],
      "hostMac": "DC:CE:C1:2E:BD:D1",
      "hostIpV4": "10.13.4.103",
      "hostIpV6": [
        "fe80::4aa:40a3:5cf8:4ea3"
      ],
      "authType": "DHCP+WPA/WPA2",
      "vlanId": "0",
      "ssid": "@CorpSSID",
      "frequency": "5.0",
      "channel": null,
      "apGroup": "Wave2_APs",
      "location": "SAN JOSE/SJC23/Flr1",
      "clientConnection": "AP3800-1",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-56.0",
      "avgRssi": "-56.0",
      "snr": "6.0",
      "avgSnr": "6.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "700.0",
      "txBytes": "2024.0",
      "rxBytes": "2588.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "",
      "dnsFailure": "",
      "onboarding": {
        "averageRunDuration": "1800",
        "maxRunDuration": "1800",
        "averageAssocDuration": "900",
        "maxAssocDuration": "900",
        "averageAuthDuration": "900",
        "maxAuthDuration": "900",
        "averageDhcpDuration": "900",
        "maxDhcpDuration": "900",
        "maxRoamingDuration": "500",
        "aaaServerIp": "10.42.136.32",
        "dhcpServerIp": "10.13.4.1",
        "authDoneTime": 1527215682918,
        "assocDoneTime": 1527215682894,
        "dhcpDoneTime": 1527215682918
      },
      "onboardingTime": 1527215682918,
      "port": null,
      "usage": 10982
    },
    {
      "id": "DC:CE:C1:22:B1:D2",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "MAZIN",
      "hostName": "MAZIN-sensor1",
      "hostOs": "Sensor-Client-AP",
      "hostVersion": null,
      "subType": "Sensor-Client-AP",
      "lastUpdated": 1527216000000,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 6
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 2
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 2
        }
      ],
      "hostMac": "DC:CE:C1:22:B1:D2",
      "hostIpV4": "10.13.4.290",
      "hostIpV6": [
        "fe80::4aa:40a3:5cf8:4ae3"
      ],
      "authType": "DHCP+WPA/WPA2",
      "vlanId": "0",
      "ssid": "@CorpSSID",
      "frequency": "5.0",
      "channel": null,
      "apGroup": "Wave2_APs",
      "location": "SAN JOSE/SJC23/Flr1",
      "clientConnection": "AP3800-1",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-56.0",
      "avgRssi": "-56.0",
      "snr": "6.0",
      "avgSnr": "6.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "400.0",
      "txBytes": "2024.0",
      "rxBytes": "2588.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "",
      "dnsFailure": "",
      "onboarding": {
        "averageRunDuration": "5800",
        "maxRunDuration": "5800",
        "averageAssocDuration": "2800",
        "maxAssocDuration": "2800",
        "averageAuthDuration": "2800",
        "maxAuthDuration": "2800",
        "averageDhcpDuration": "2800",
        "maxDhcpDuration": "2800",
        "maxRoamingDuration": "700",
        "aaaServerIp": "10.42.136.32",
        "dhcpServerIp": "10.13.4.1",
        "authDoneTime": 1527215682918,
        "assocDoneTime": 1527215682894,
        "dhcpDoneTime": 1527215682918
      },
      "onboardingTime": 1527215682918,
      "port": null,
      "usage": 45672
    },
    {
      "id": "F0:DB:F8:4B:DC:BF",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "ethan",
      "hostName": "Min-Ses-iPad",
      "hostOs": "Apple-iPad",
      "hostVersion": null,
      "subType": "Apple-iPad",
      "lastUpdated": 1527142916597,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 7
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 2
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 2
        }
      ],
      "hostMac": "F0:DB:F8:4B:DC:BF",
      "hostIpV4": "10.13.4.107",
      "hostIpV6": [
        "fe80::4aa:40a6:6cf8:4ea0"
      ],
      "authType": "DHCP+WPA/WPA2",
      "vlanId": "0",
      "ssid": "@CorpSSID",
      "frequency": "5.0",
      "channel": null,
      "apGroup": "Wave2_APs",
      "location": "SAN JOSE/SJC23/Flr1",
      "clientConnection": "AP9120_2",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-80.0",
      "avgRssi": "-80.0",
      "snr": "35.0",
      "avgSnr": "35.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "750.0",
      "txBytes": "31406.0",
      "rxBytes": "63277.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "",
      "dnsFailure": "",
      "onboarding": {
        "averageRunDuration": "2800",
        "maxRunDuration": "2800",
        "averageAssocDuration": "1400",
        "maxAssocDuration": "1400",
        "averageAuthDuration": "1400",
        "maxAuthDuration": "1400",
        "averageDhcpDuration": "1400",
        "maxDhcpDuration": "1400",
        "maxRoamingDuration": "2800",
        "aaaServerIp": "10.42.136.32",
        "dhcpServerIp": "10.13.4.1",
        "authDoneTime": 1527142880867,
        "assocDoneTime": 1527142880568,
        "dhcpDoneTime": 1527142889674
      },
      "onboardingTime": 1527142889674,
      "port": null,
      "usage": 14567
    },
    {
      "id": "7C:46:85:20:79:5E",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "Mario",
      "hostName": "Mario-Motorola_XOOM",
      "hostOs": "android-dhcp-7.1.1",
      "hostVersion": null,
      "subType": "Android-Motorola",
      "lastUpdated": 1528359513218,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 9
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 4
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 6
        }
      ],
      "hostMac": "7C:46:85:20:79:5E",
      "hostIpV4": "172.20.228.61",
      "hostIpV6": [
        "fe80::7e46:25ff:fe20:4cd5"
      ],
      "authType": "DHCP+WPA/WPA2",
      "vlanId": "118",
      "ssid": "@CorpSSID",
      "frequency": "5.0",
      "channel": null,
      "apGroup": "Wave2_APs",
      "location": "SAN JOSE/SJC23/Flr1",
      "clientConnection": "AP3800-1",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-40.0",
      "avgRssi": "-40.0",
      "snr": "38.0",
      "avgSnr": "38.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "720.0",
      "txBytes": "6640.0",
      "rxBytes": "11284.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "",
      "dnsFailure": "",
      "onboarding": {
        "averageRunDuration": "1800",
        "maxRunDuration": "1800",
        "averageAssocDuration": "900",
        "maxAssocDuration": "900",
        "averageAuthDuration": "900",
        "maxAuthDuration": "900",
        "averageDhcpDuration": "900",
        "maxDhcpDuration": "900",
        "maxRoamingDuration": "230",
        "aaaServerIp": "10.42.136.32",
        "dhcpServerIp": "10.13.4.1",
        "authDoneTime": 1527204768355,
        "assocDoneTime": 1527204732979,
        "dhcpDoneTime": 1527204738372
      },
      "onboardingTime": 1527204738372,
      "port": null,
      "usage": 15678
    },
    {
      "id": "00:42:5A:0A:22:41",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "Zayn",
      "hostName": "Zayn-sensor",
      "hostOs": "Sensor-Client-AP",
      "hostVersion": null,
      "subType": "Sensor-Client-AP",
      "lastUpdated": 1527216000000,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 8
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 4
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 6
        }
      ],
      "hostMac": "00:42:5A:0A:22:41",
      "hostIpV4": "10.13.4.104",
      "hostIpV6": [
        "fe80::7e46:35ff:fe20:4cd5"
      ],
      "authType": "DHCP+WPA/WPA2",
      "vlanId": "0",
      "ssid": "@CorpSSID",
      "frequency": "5.0",
      "channel": null,
      "apGroup": "Wave2_APs",
      "location": "SAN JOSE/SJC23/Flr1",
      "clientConnection": "AP3800-1",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-56.0",
      "avgRssi": "-56.0",
      "snr": "38.0",
      "avgSnr": "38.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "820.0",
      "txBytes": "2024.0",
      "rxBytes": "2588.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "",
      "dnsFailure": "",
      "onboarding": {
        "averageRunDuration": "1800",
        "maxRunDuration": "1800",
        "averageAssocDuration": "1000",
        "maxAssocDuration": "1000",
        "averageAuthDuration": "1000",
        "maxAuthDuration": "1000",
        "averageDhcpDuration": "1000",
        "maxDhcpDuration": "1000",
        "maxRoamingDuration": "250",
        "aaaServerIp": "10.42.136.32",
        "dhcpServerIp": "10.13.4.1",
        "authDoneTime": 1527215682918,
        "assocDoneTime": 1527215682894,
        "dhcpDoneTime": 1527215682918
      },
      "onboardingTime": 1527215682918,
      "port": null,
      "usage": 12345
    },
    {
      "id": "4C:66:41:2C:09:39",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "lemccart",
      "hostName": "SAMSUNG-SM-G930V",
      "hostOs": "android-dhcp-8.0.0",
      "hostVersion": null,
      "subType": "Unclassified",
      "lastUpdated": 1525023087661,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 2
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 1
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 0
        }
      ],
      "hostMac": "4C:66:41:2C:09:39",
      "hostIpV4": "10.33.248.101",
      "hostIpV6": [
        "fe80::7e46:35ff:ff20:4cd5"
      ],
      "authType": "WPA/WPA2+NAC_POSTURE_REDIR",
      "vlanId": "100",
      "ssid": "LA-Corporate2",
      "frequency": "5.0",
      "channel": 36,
      "channelWidth": 20,
      "apGroup": "LA1",
      "location": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "clientConnection": "LA2-AP3802-23",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-76.0",
      "avgRssi": "-76.0",
      "snr": "8.0",
      "avgSnr": "8.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "20.0",
      "txBytes": "751749.0",
      "rxBytes": "154251.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "-1.0",
      "dnsFailure": "-1.0",
      "onboarding": {
        "averageRunDuration": "9000",
        "maxRunDuration": "9000",
        "averageAssocDuration": "4500",
        "maxAssocDuration": "4500",
        "averageAuthDuration": "4500",
        "maxAuthDuration": "4500",
        "averageDhcpDuration": "4500",
        "maxDhcpDuration": "4500",
        "maxRoamingDuration": "300",
        "aaaServerIp": "10.42.136.32",
        "dhcpServerIp": "10.13.4.1",
        "authDoneTime": 1527204768355,
        "assocDoneTime": 1527204732979,
        "dhcpDoneTime": 1527204738372
      },
      "onboardingTime": 1527169633409,
      "port": null,
      "usage": 12342
    },
    {
      "id": "80:B0:3D:AD:32:6C",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "sbindi",
      "hostName": "Scotts-iPhone",
      "hostOs": "iOS11.4",
      "hostVersion": "iOS11.4",
      "subType": "iPhone X",
      "lastUpdated": 1525023087661,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 8
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 4
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 6
        }
      ],
      "hostMac": "80:B0:3D:AD:32:6C",
      "hostIpV4": "10.33.248.98",
      "hostIpV6": [
        "fe80::7e46:35ff:ff20:4ce5"
      ],
      "authType": "WPA/WPA2+NAC_POSTURE_REDIR",
      "vlanId": "100",
      "ssid": "LA-Corporate2",
      "frequency": "5.0",
      "channel": 36,
      "channelWidth": 20,
      "apGroup": "LA1",
      "location": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "clientConnection": "LA2-AP3802-23",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-60.0",
      "avgRssi": "-60.0",
      "snr": "36.0",
      "avgSnr": "36.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "800.0",
      "txBytes": "751749.0",
      "rxBytes": "154251.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "-1.0",
      "dnsFailure": "-1.0",
      "onboarding": {
        "averageRunDuration": "1000",
        "maxRunDuration": "1000",
        "averageAssocDuration": "500",
        "maxAssocDuration": "500",
        "averageAuthDuration": "500",
        "maxAuthDuration": "500",
        "averageDhcpDuration": "500",
        "maxDhcpDuration": "500",
        "maxRoamingDuration": "130",
        "aaaServerIp": "10.42.136.32",
        "dhcpServerIp": "10.13.4.1",
        "authDoneTime": 1527204768355,
        "assocDoneTime": 1527204732979,
        "dhcpDoneTime": 1527204738372
      },
      "onboardingTime": 1527169633409,
      "port": null,
      "usage": 56788
    },
    {
      "id": "D4:8C:B5:0B:57:A9",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "lemccart",
      "hostName": "lemccart-iphone",
      "hostOs": "Cisco Systems, Inc. IP Phone CP-9971",
      "hostVersion": null,
      "subType": "Cisco-IP-Phone-9971",
      "lastUpdated": 1525023087661,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 9
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 9
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 9
        }
      ],
      "hostMac": "D4:8C:B5:0B:57:A9",
      "hostIpV4": "10.33.116.25",
      "hostIpV6": [
        "fe80::7f46:35ff:ff20:4cd5"
      ],
      "authType": "WPA/WPA2+NAC_POSTURE_REDIR",
      "vlanId": "100",
      "ssid": "LA-Corporate2",
      "frequency": "5.0",
      "channel": 36,
      "channelWidth": 20,
      "apGroup": "LA1",
      "location": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "clientConnection": "LA2-AP3802-23",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-46.0",
      "avgRssi": "-46.0",
      "snr": "48.0",
      "avgSnr": "48.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "1250.0",
      "txBytes": "751749.0",
      "rxBytes": "154251.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "-1.0",
      "dnsFailure": "-1.0",
      "onboarding": {
        "averageRunDuration": "900",
        "maxRunDuration": "900",
        "averageAssocDuration": "450",
        "maxAssocDuration": "450",
        "averageAuthDuration": "450",
        "maxAuthDuration": "450",
        "averageDhcpDuration": "450",
        "maxDhcpDuration": "500",
        "maxRoamingDuration": "250",
        "aaaServerIp": "10.42.136.32",
        "dhcpServerIp": "10.13.4.1",
        "authDoneTime": 1527204768355,
        "assocDoneTime": 1527204732979,
        "dhcpDoneTime": 1527204738372
      },
      "onboardingTime": 1527169633409,
      "port": null,
      "usage": 765765
    },
    {
      "id": "B8:27:EB:72:11:FB",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "dnaise01",
      "hostName": "dnac-ise-01",
      "hostOs": null,
      "hostVersion": null,
      "subType": "Workstation",
      "lastUpdated": 1540367460000,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 10
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 4
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 6
        }
      ],
      "hostMac": "B8:27:EB:72:11:FB",
      "hostIpV4": "10.30.100.56",
      "hostIpV6": [
        "fe80::7d46:35ff:ff20:4cd5"
      ],
      "authType": null,
      "vlanId": "139",
      "vnid": null,
      "ssid": "LA-Topsecret2",
      "frequency": "2.4",
      "channel": null,
      "apGroup": null,
      "location": "USA/DC",
      "clientConnection": "DC-SERVICES",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-46.0",
      "avgRssi": "-46.0",
      "snr": "38.0",
      "avgSnr": "38.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "1200.0",
      "txBytes": "0.0",
      "rxBytes": "0.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "",
      "dnsFailure": "",
      "onboarding": {
        "averageRunDuration": "800",
        "maxRunDuration": "800",
        "averageAssocDuration": "400",
        "maxAssocDuration": "400",
        "averageAuthDuration": "400",
        "maxAuthDuration": "400",
        "averageDhcpDuration": "400",
        "maxDhcpDuration": "400",
        "maxRoamingDuration": "10",
        "aaaServerIp": "10.42.136.32",
        "dhcpServerIp": "10.13.4.2",
        "authDoneTime": 0,
        "assocDoneTime": 0,
        "dhcpDoneTime": 0
      },
      "onboardingTime": 0,
      "port": null,
      "iosCapable": false,
      "usage": 23415
    },
    {
      "id": "D4:8C:B5:0B:57:AA",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "LiamBrown",
      "hostName": "liam-iphone",
      "hostOs": "Cisco Systems, Inc. IP Phone CP-9971",
      "hostVersion": null,
      "subType": "Cisco-IP-Phone-9971",
      "lastUpdated": 1525023087661,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 9
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 9
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 9
        }
      ],
      "hostMac": "D4:8C:B5:0B:57:AA",
      "hostIpV4": "10.33.116.61",
      "hostIpV6": [
        "fe80::7d46:35ff:fe20:4cd5"
      ],
      "authType": "WPA/WPA2+NAC_POSTURE_REDIR",
      "vlanId": "100",
      "ssid": "LA-Corporate2",
      "frequency": "5.0",
      "channel": 36,
      "channelWidth": 20,
      "apGroup": "LA1",
      "location": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "clientConnection": "LA2-AP3802-23",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-46.0",
      "avgRssi": "-46.0",
      "snr": "48.0",
      "avgSnr": "48.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "1300.0",
      "txBytes": "751749.0",
      "rxBytes": "154251.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "-1.0",
      "dnsFailure": "-1.0",
      "onboarding": {
        "averageRunDuration": "900",
        "maxRunDuration": "900",
        "averageAssocDuration": "450",
        "maxAssocDuration": "450",
        "averageAuthDuration": "450",
        "maxAuthDuration": "450",
        "averageDhcpDuration": "450",
        "maxDhcpDuration": "500",
        "maxRoamingDuration": "300",
        "aaaServerIp": "10.42.136.32",
        "dhcpServerIp": "10.13.4.1",
        "authDoneTime": 1527204768355,
        "assocDoneTime": 1527204732979,
        "dhcpDoneTime": 1527204738372
      },
      "onboardingTime": 1527169633409,
      "port": null,
      "usage": 78231
    },
    {
      "id": "B8:27:EB:72:11:FC",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "Emily jackson",
      "hostName": "emily-01",
      "hostOs": null,
      "hostVersion": null,
      "subType": "Workstation",
      "lastUpdated": 1540367460000,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 10
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 4
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 6
        }
      ],
      "hostMac": "B8:27:EB:72:11:FC",
      "hostIpV4": "10.30.100.57",
      "hostIpV6": [
        "fe80::4aa:40b3:5cf8:4ae3"
      ],
      "authType": null,
      "vlanId": "139",
      "vnid": null,
      "ssid": "LA-Topsecret2",
      "frequency": "2.4",
      "channel": null,
      "apGroup": null,
      "location": "USA/DC",
      "clientConnection": "DC-SERVICES",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-46.0",
      "avgRssi": "-46.0",
      "snr": "38.0",
      "avgSnr": "38.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "2000.0",
      "txBytes": "0.0",
      "rxBytes": "0.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "",
      "dnsFailure": "",
      "onboarding": {
        "averageRunDuration": "800",
        "maxRunDuration": "800",
        "averageAssocDuration": "400",
        "maxAssocDuration": "400",
        "averageAuthDuration": "400",
        "maxAuthDuration": "400",
        "averageDhcpDuration": "400",
        "maxDhcpDuration": "400",
        "maxRoamingDuration": "10",
        "aaaServerIp": "10.42.136.32",
        "dhcpServerIp": "10.13.4.2",
        "authDoneTime": 0,
        "assocDoneTime": 0,
        "dhcpDoneTime": 0
      },
      "onboardingTime": 0,
      "port": null,
      "iosCapable": false,
      "usage": 12078
    },
    {
      "id": "D4:8C:B5:0B:57:A8",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "Adelina Miller",
      "hostName": "adelina-iphone",
      "hostOs": "Cisco Systems, Inc. IP Phone CP-9971",
      "hostVersion": null,
      "subType": "Cisco-IP-Phone-9971",
      "lastUpdated": 1525023087661,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 9
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 9
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 9
        }
      ],
      "hostMac": "D4:8C:B5:0B:57:A8",
      "hostIpV4": "10.33.116.62",
      "hostIpV6": [
        "fe80::4aa:4cb3:5cf8:4ae3"
      ],
      "authType": "WPA/WPA2+NAC_POSTURE_REDIR",
      "vlanId": "100",
      "ssid": "LA-Corporate2",
      "frequency": "5.0",
      "channel": 36,
      "channelWidth": 20,
      "apGroup": "LA1",
      "location": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "clientConnection": "LA2-AP3802-23",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-46.0",
      "avgRssi": "-46.0",
      "snr": "48.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "avgSnr": "48.0",
      "dataRate": "1300.0",
      "txBytes": "751749.0",
      "rxBytes": "154251.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "-1.0",
      "dnsFailure": "-1.0",
      "onboarding": {
        "averageRunDuration": "900",
        "maxRunDuration": "900",
        "averageAssocDuration": "450",
        "maxAssocDuration": "450",
        "averageAuthDuration": "450",
        "maxAuthDuration": "450",
        "averageDhcpDuration": "450",
        "maxDhcpDuration": "500",
        "maxRoamingDuration": "300",
        "aaaServerIp": "10.42.136.32",
        "dhcpServerIp": "10.13.4.1",
        "authDoneTime": 1527204768355,
        "assocDoneTime": 1527204732979,
        "dhcpDoneTime": 1527204738372
      },
      "onboardingTime": 1527169633409,
      "port": null,
      "usage": 67821
    },
    {
      "id": "B8:27:EB:72:11:FD",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "Zac William",
      "hostName": "zac-ise-01",
      "hostOs": null,
      "hostVersion": null,
      "subType": "Workstation",
      "lastUpdated": 1540367460000,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 10
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 4
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 6
        }
      ],
      "hostMac": "B8:27:EB:72:11:FD",
      "hostIpV4": "10.30.100.58",
      "hostIpV6": [
        "fe80::4aa:4cb3:5df8:4ae3"
      ],
      "authType": null,
      "vlanId": "139",
      "vnid": null,
      "ssid": "LA-Topsecret2",
      "frequency": "2.4",
      "channel": null,
      "apGroup": null,
      "location": "USA/DC",
      "clientConnection": "DC-SERVICES",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-46.0",
      "avgRssi": "-46.0",
      "snr": "38.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "avgSnr": "38.0",
      "dataRate": "1100.0",
      "txBytes": "0.0",
      "rxBytes": "0.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "",
      "dnsFailure": "",
      "onboarding": {
        "averageRunDuration": "800",
        "maxRunDuration": "800",
        "averageAssocDuration": "400",
        "maxAssocDuration": "400",
        "averageAuthDuration": "400",
        "maxAuthDuration": "400",
        "averageDhcpDuration": "400",
        "maxDhcpDuration": "400",
        "maxRoamingDuration": "20",
        "aaaServerIp": "10.42.136.32",
        "dhcpServerIp": "10.13.4.2",
        "authDoneTime": 0,
        "assocDoneTime": 0,
        "dhcpDoneTime": 0
      },
      "onboardingTime": 0,
      "port": null,
      "iosCapable": false,
      "usage": 2567,
    },
    {
      "id": "A8:BE:27:36:60:1A",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "Amelia.Johnson",
      "hostName": "Amelia-johnson",
      "hostOs": null,
      "hostVersion": "iOS11.0",
      "subType": "iPhone 7",
      "lastUpdated": 1525023087661,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 8
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 4
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 4
        }
      ],
      "hostMac": "A8:BE:27:36:60:1A",
      "hostIpV4": "10.30.100.48",
      "hostIpV6": [
        "fe80::4aa:4cb3:5ef8:4ae3"
      ],
      "authType": "DHCP+WPA/WPA2+NAC_POSTURE_REDIR",
      "vlanId": "100",
      "ssid": "LA-Corporate3",
      "frequency": "5.0",
      "channel": "153",
      "apGroup": "LA1",
      "location": "USA/LA/LA-Level16",
      "clientConnection": "LA1-AP3802-31",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-50.0",
      "avgRssi": "-50.0",
      "snr": "39.0",
      "avgSnr": "39.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "640.0",
      "txBytes": "7448.0",
      "rxBytes": "91362.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "-1.0",
      "dnsFailure": "-1.0",
      "onboarding": {
        "averageRunDuration": "2000",
        "maxRunDuration": "2000",
        "averageAssocDuration": "1000",
        "maxAssocDuration": "1000",
        "averageAuthDuration": "1000",
        "maxAuthDuration": "1000",
        "averageDhcpDuration": "1000",
        "maxDhcpDuration": "1000",
        "maxRoamingDuration": "2000",
        "aaaServerIp": "192.168.139.168",
        "dhcpServerIp": "10.13.4.2",
        "authDoneTime": 1527204768355,
        "assocDoneTime": 1527204732979,
        "dhcpDoneTime": 1527204738372
      },
      "onboardingTime": 1527169633409,
      "port": null,
      "usage": 65412
    },
    {
      "id": "A8:BE:27:36:60:1B",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "James.Robert",
      "hostName": "James-robert",
      "hostOs": null,
      "hostVersion": "null",
      "subType": "android",
      "lastUpdated": 1525023087661,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 8
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 4
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 4
        }
      ],
      "hostMac": "A8:BE:27:36:60:1B",
      "hostIpV4": "10.30.100.29",
      "hostIpV6": [
        "fe80::4aa:4cb3:5ef9:4ae3"
      ],
      "authType": "DHCP+WPA/WPA2+NAC_POSTURE_REDIR",
      "vlanId": "100",
      "ssid": "LA-Corporate3",
      "frequency": "5.0",
      "channel": "153",
      "apGroup": "LA1",
      "location": "USA/LA/LA-Level16",
      "clientConnection": "LA1-AP3802-31",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-49.0",
      "avgRssi": "-49.0",
      "snr": "38.0",
      "avgSnr": "38.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "910.0",
      "txBytes": "7448.0",
      "rxBytes": "91362.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "-1.0",
      "dnsFailure": "-1.0",
      "onboarding": {
        "averageRunDuration": "2000",
        "maxRunDuration": "2000",
        "averageAssocDuration": "1000",
        "maxAssocDuration": "1000",
        "averageAuthDuration": "1000",
        "maxAuthDuration": "1000",
        "averageDhcpDuration": "1000",
        "maxDhcpDuration": "1000",
        "maxRoamingDuration": "450",
        "aaaServerIp": "192.168.139.168",
        "dhcpServerIp": "10.13.4.2",
        "authDoneTime": 1527204768355,
        "assocDoneTime": 1527204732979,
        "dhcpDoneTime": 1527204738372
      },
      "onboardingTime": 1527169633409,
      "port": null,
      "usage": 23133
    },
    {
      "id": "A8:BE:27:36:60:1C",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "Lauren.OKelly",
      "hostName": "Lauren-O-Kelly",
      "hostOs": "MSFT 5.0",
      "hostVersion": null,
      "subType": "Microsoft-Workstation",
      "lastUpdated": 1527939380371,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 8
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 4
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 4
        }
      ],
      "hostMac": "A8:BE:27:36:60:1C",
      "hostIpV4": "10.30.100.43",
      "hostIpV6": [
        "fe80::7d46:35ff:ff20:4cd6"
      ],
      "authType": "DHCP+WPA/WPA2+NAC_POSTURE_REDIR",
      "vlanId": "100",
      "ssid": "LA-Corporate3",
      "frequency": "5.0",
      "channel": "153",
      "apGroup": "LA1",
      "location": "USA/LA/LA-Level16",
      "clientConnection": "LA1-AP3802-31",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-50.0",
      "avgRssi": "-50.0",
      "snr": "39.0",
      "avgSnr": "39.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "835.0",
      "txBytes": "7448.0",
      "rxBytes": "91362.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "-1.0",
      "dnsFailure": "-1.0",
      "onboarding": {
        "averageRunDuration": "2000",
        "maxRunDuration": "2000",
        "averageAssocDuration": "1000",
        "maxAssocDuration": "1000",
        "averageAuthDuration": "1000",
        "maxAuthDuration": "1000",
        "averageDhcpDuration": "1000",
        "maxDhcpDuration": "1000",
        "maxRoamingDuration": "200",
        "aaaServerIp": "192.168.139.168",
        "dhcpServerIp": "10.13.4.2",
        "authDoneTime": 1527204768355,
        "assocDoneTime": 1527204732979,
        "dhcpDoneTime": 1527204738372
      },
      "onboardingTime": 1527169633409,
      "port": null,
      "usage": 21312
    },
    {
      "id": "A8:BE:27:36:60:1D",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "Charlotte.Brown",
      "hostName": "Charlotte-iPhone",
      "hostOs": "Apple-iPad",
      "hostVersion": null,
      "subType": "Apple-Device",
      "lastUpdated": 1527714010378,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 8
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 4
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 4
        }
      ],
      "hostMac": "A8:BE:27:36:60:1D",
      "hostIpV4": "10.30.100.44",
      "hostIpV6": [
        "fe80::4aa:4cb3:5ff8:5ae3"
      ],
      "authType": "DHCP+WPA/WPA2+NAC_POSTURE_REDIR",
      "vlanId": "100",
      "ssid": "LA-Corporate3",
      "frequency": "5.0",
      "channel": "153",
      "apGroup": "LA1",
      "location": "USA/LA/LA-Level16",
      "clientConnection": "LA1-AP3802-31",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-50.0",
      "avgRssi": "-50.0",
      "snr": "39.0",
      "avgSnr": "39.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "730.0",
      "txBytes": "7448.0",
      "rxBytes": "91362.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "-1.0",
      "dnsFailure": "-1.0",
      "onboarding": {
        "averageRunDuration": "2000",
        "maxRunDuration": "2000",
        "averageAssocDuration": "1000",
        "maxAssocDuration": "1000",
        "averageAuthDuration": "1000",
        "maxAuthDuration": "1000",
        "averageDhcpDuration": "1000",
        "maxDhcpDuration": "1000",
        "maxRoamingDuration": "400",
        "aaaServerIp": "192.168.139.168",
        "dhcpServerIp": "10.13.4.2",
        "authDoneTime": 1527204768355,
        "assocDoneTime": 1527204732979,
        "dhcpDoneTime": 1527204738372
      },
      "onboardingTime": 1527169633409,
      "port": null,
      "usage": 32132
    },
    {
      "id": "6C:19:C0:BD:93:AA",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "William.Roy",
      "hostName": "William.Roy-iPad",
      "hostOs": "Apple-iPad",
      "hostVersion": null,
      "subType": "Apple-iPad",
      "lastUpdated": 1525023087661,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 2
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 1
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 0
        }
      ],
      "hostMac": "6C:19:C0:BD:93:AA",
      "hostIpV4": "10.30.100.49",
      "hostIpV6": [
        "fe80::4aa:4cb3:5ff8:5ee3"
      ],
      "authType": "WPA/WPA2+NAC_POSTURE_REDIR",
      "vlanId": "100",
      "ssid": "LA-Corporate2",
      "frequency": "5.0",
      "channel": 153,
      "channelWidth": 20,
      "apGroup": "LA1",
      "location": "Global/North America/USA/Nevada/Las Vegas/LV01/Flr-LV1-1",
      "clientConnection": "LA2-AP3802-23",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-73.0",
      "avgRssi": "-73.0",
      "snr": "12.0",
      "avgSnr": "12.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "15.0",
      "txBytes": "7448.0",
      "rxBytes": "91362.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "-1.0",
      "dnsFailure": "-1.0",
      "onboarding": {
        "averageRunDuration": "12000",
        "maxRunDuration": "12000",
        "averageAssocDuration": "6100",
        "maxAssocDuration": "6100",
        "averageAuthDuration": "6100",
        "maxAuthDuration": "6100",
        "averageDhcpDuration": "6100",
        "maxDhcpDuration": "6100",
        "maxRoamingDuration": "12000",
        "aaaServerIp": "10.13.1.113",
        "dhcpServerIp": "10.13.4.1",
        "authDoneTime": 1527165218091,
        "assocDoneTime": 1527165179208,
        "dhcpDoneTime": 1527165184711
      },
      "onboardingTime": 1527169633409,
      "port": null,
      "usage": 43523
    },
    {
      "id": "6C:19:C0:BD:93:AB",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "Luther.Knight",
      "hostName": "Luther.Knight",
      "hostOs": "Apple-iPhone",
      "hostVersion": null,
      "subType": "Apple-iPhone",
      "lastUpdated": 1525023087661,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 2
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 1
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 0
        }
      ],
      "hostMac": "6C:19:C0:BD:93:AB",
      "hostIpV4": "10.30.100.50",
      "hostIpV6": [
        "fe80::7d46:35ff:ff20:5cd6"
      ],
      "authType": "WPA/WPA2+NAC_POSTURE_REDIR",
      "vlanId": "100",
      "ssid": "LA-Corporate2",
      "frequency": "5.0",
      "channel": 153,
      "channelWidth": 20,
      "apGroup": "LA1",
      "location": "Global/North America/USA/Nevada/Las Vegas/LV01/Flr-LV1-1",
      "clientConnection": "LA2-AP3802-23",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-72.0",
      "avgRssi": "-72.0",
      "snr": "13.0",
      "avgSnr": "13.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "13.0",
      "txBytes": "7448.0",
      "rxBytes": "91362.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "-1.0",
      "dnsFailure": "-1.0",
      "onboarding": {
        "averageRunDuration": "0",
        "maxRunDuration": "0",
        "averageAssocDuration": "6100",
        "maxAssocDuration": "6100",
        "averageAuthDuration": "6100",
        "maxAuthDuration": "6100",
        "averageDhcpDuration": "0",
        "maxDhcpDuration": "0",
        "maxRoamingDuration": "0",
        "aaaServerIp": "10.13.1.113",
        "dhcpServerIp": "10.13.4.1",
        "authDoneTime": 1527165218091,
        "assocDoneTime": 1527165179208,
        "dhcpDoneTime": 1527165184711
      },
      "onboardingTime": 1527169633409,
      "port": null,
      "usage": 21342
    },

    {
      "id": "6C:18:C0:BD:93:00",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "Mia.Smith",
      "hostName": "Mia.Smith",
      "hostOs": "Apple-iPhone",
      "hostVersion": null,
      "subType": "Apple-iPhone",
      "lastUpdated": 1525023087661,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 2
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 1
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 0
        }
      ],
      "hostMac": "6C:18:C0:BD:93:00",
      "hostIpV4": "10.30.100.51",
      "hostIpV6": [
        "fe80::7d46:35fe:ff20:4cd6"
      ],
      "authType": "WPA/WPA2+NAC_POSTURE_REDIR",
      "vlanId": "100",
      "ssid": "LA-Corporate2",
      "frequency": "5.0",
      "channel": 153,
      "channelWidth": 20,
      "apGroup": "LA1",
      "location": "Global/North America/USA/Nevada/Las Vegas/LV01/Flr-LV1-1",
      "clientConnection": "LA2-AP3802-23",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-72.0",
      "avgRssi": "-72.0",
      "snr": "13.0",
      "avgSnr": "13.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "15.0",
      "txBytes": "7448.0",
      "rxBytes": "91362.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "-1.0",
      "dnsFailure": "-1.0",
      "onboarding": {
        "averageRunDuration": "0",
        "maxRunDuration": "0",
        "averageAssocDuration": "6100",
        "maxAssocDuration": "6100",
        "averageAuthDuration": "0",
        "maxAuthDuration": "0",
        "averageDhcpDuration": "6100",
        "maxDhcpDuration": "6100",
        "maxRoamingDuration": "0",
        "aaaServerIp": "10.13.1.113",
        "dhcpServerIp": "10.13.4.1",
        "authDoneTime": 1527165218091,
        "assocDoneTime": 1527165179208,
        "dhcpDoneTime": 1527165184711
      },
      "onboardingTime": 1527169633409,
      "port": null,
      "usage": 12432
    },
    {
      "id": "6C:18:C0:BD:81:00",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "Aaliya.Khan",
      "hostName": "Aaliya.Khan",
      "hostOs": "Apple-iPhone",
      "hostVersion": null,
      "subType": "Apple-iPhone",
      "lastUpdated": 1525023087661,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 3
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 1
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 0
        }
      ],
      "hostMac": "6C:18:C0:BD:81:00",
      "hostIpV4": "10.30.100.52",
      "hostIpV6": [
        "fe80::4aa:4cf3:5ff8:5ae3"
      ],
      "authType": "WPA/WPA2+NAC_POSTURE_REDIR",
      "vlanId": "100",
      "ssid": "LA-Corporate2",
      "frequency": "5.0",
      "channel": 153,
      "channelWidth": 20,
      "apGroup": "LA1",
      "location": "Global/North America/USA/Nevada/Las Vegas/LV01/Flr-LV1-1",
      "clientConnection": "LA2-AP3802-23",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-72.0",
      "avgRssi": "-72.0",
      "snr": "13.0",
      "avgSnr": "13.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "100.0",
      "txBytes": "7448.0",
      "rxBytes": "91362.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "-1.0",
      "dnsFailure": "-1.0",
      "onboarding": {
        "averageRunDuration": "0",
        "maxRunDuration": "0",
        "averageAssocDuration": "6100",
        "maxAssocDuration": "6100",
        "averageAuthDuration": "0",
        "maxAuthDuration": "0",
        "averageDhcpDuration": "6100",
        "maxDhcpDuration": "6100",
        "maxRoamingDuration": "0",
        "aaaServerIp": "10.13.1.113",
        "dhcpServerIp": "10.13.4.1",
        "authDoneTime": 1527165218091,
        "assocDoneTime": 1527165179208,
        "dhcpDoneTime": 1527165184711
      },
      "onboardingTime": 1527169633409,
      "port": null,
      "usage": 23145
    },
    {
      "id": "B8:27:EB:72:77:1A",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "Liam.Smith",
      "hostName": "Liam-PC",
      "hostOs": "dhcpcd-6.7.1:Linux-4.9.53-v7+:armv7l:BCM2835",
      "hostVersion": null,
      "subType": "Linux-Workstation",
      "lastUpdated": 1525399145843,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 3
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 1
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 0
        }
      ],
      "hostMac": "B8:27:EB:72:77:1A",
      "hostIpV4": "10.30.100.59",
      "hostIpV6": [
        "fe80::4aa:4cf3:5ff8:6ae3"
      ],
      "authType": "WPA/WPA2+NAC_POSTURE_REDIR",
      "vlanId": "100",
      "ssid": "LA-Corporate2",
      "frequency": "2.4",
      "channel": 6,
      "apGroup": "LA1",
      "location": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "clientConnection": "LA2-AP3802-23",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-68.0",
      "avgRssi": "-68.0",
      "snr": "16.0",
      "avgSnr": "16.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "120.0",
      "txBytes": "3.2323121E7",
      "rxBytes": "1.5713199E7",
      "rxRetryPct": "0.0",
      "dnsSuccess": "-1.0",
      "dnsFailure": "-1.0",
      "onboarding": {
        "averageRunDuration": "9000",
        "maxRunDuration": "9000",
        "averageAssocDuration": "4500",
        "maxAssocDuration": "4500",
        "averageAuthDuration": "4500",
        "maxAuthDuration": "4500",
        "averageDhcpDuration": "4500",
        "maxDhcpDuration": "4500",
        "maxRoamingDuration": "9000",
        "aaaServerIp": "192.168.139.168",
        "dhcpServerIp": "10.13.4.2",
        "authDoneTime": 1527204768365,
        "assocDoneTime": 1527204732979,
        "dhcpDoneTime": 1527204738352
      },
      "onboardingTime": 1527169633409,
      "port": null,
      "usage": 21387
    },
    {
      "id": "B8:27:EB:72:81:12",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "Emily.Knight",
      "hostName": "Emily-PC",
      "hostOs": "dhcpcd-6.7.1:Linux-4.9.53-v7+:armv7l:BCM2835",
      "hostVersion": null,
      "subType": "Linux-Workstation",
      "lastUpdated": 1525399145843,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 3
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 1
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 0
        }
      ],
      "hostMac": "B8:27:EB:72:81:12",
      "hostIpV4": "10.30.100.60",
      "hostIpV6": [
        "fe80::4aa:4df3:4ff8:6ae3"
      ],
      "authType": "WPA/WPA2+NAC_POSTURE_REDIR",
      "vlanId": "100",
      "ssid": "LA-Corporate2",
      "frequency": "2.4",
      "channel": 6,
      "apGroup": "LA1",
      "location": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "clientConnection": "LA2-AP3802-23",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-68.0",
      "avgRssi": "-68.0",
      "snr": "16.0",
      "avgSnr": "16.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "130.0",
      "txBytes": "3.2323121E7",
      "rxBytes": "1.5713199E7",
      "rxRetryPct": "0.0",
      "dnsSuccess": "-1.0",
      "dnsFailure": "-1.0",
      "onboarding": {
        "averageRunDuration": "9000",
        "maxRunDuration": "9000",
        "averageAssocDuration": "4500",
        "maxAssocDuration": "4500",
        "averageAuthDuration": "4500",
        "maxAuthDuration": "4500",
        "averageDhcpDuration": "4500",
        "maxDhcpDuration": "4500",
        "maxRoamingDuration": "9000",
        "aaaServerIp": "192.168.139.168",
        "dhcpServerIp": "10.13.4.2",
        "authDoneTime": 1527204768365,
        "assocDoneTime": 1527204732979,
        "dhcpDoneTime": 1527204738352
      },
      "onboardingTime": 1527169633409,
      "port": null,
      "usage": 5432,
    },
    {
      "id": "4C:66:41:2C:19:39",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "Zac Roger",
      "hostName": "SAMSUNG-SM-G930V",
      "hostOs": "android-dhcp-8.0.0",
      "hostVersion": null,
      "subType": "Android",
      "lastUpdated": 1525023087661,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 2
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 1
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 0
        }
      ],
      "hostMac": "4C:66:41:2C:19:39",
      "hostIpV4": "10.33.248.121",
      "hostIpV6": [
        "fe80::4aa:4ef3:6ff8:6ae3"
      ],
      "authType": "WPA/WPA2+NAC_POSTURE_REDIR",
      "vlanId": "100",
      "ssid": "LA-Corporate2",
      "frequency": "5.0",
      "channel": 36,
      "channelWidth": 20,
      "apGroup": "LA1",
      "location": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "clientConnection": "LA2-AP3802-23",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-76.0",
      "avgRssi": "-76.0",
      "snr": "8.0",
      "avgSnr": "8.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "17.0",
      "txBytes": "751749.0",
      "rxBytes": "154251.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "-1.0",
      "dnsFailure": "-1.0",
      "onboarding": {
        "averageRunDuration": "9000",
        "maxRunDuration": "9000",
        "averageAssocDuration": "4500",
        "maxAssocDuration": "4500",
        "averageAuthDuration": "4500",
        "maxAuthDuration": "4500",
        "averageDhcpDuration": "4500",
        "maxDhcpDuration": "4500",
        "maxRoamingDuration": "9000",
        "aaaServerIp": "10.42.136.32",
        "dhcpServerIp": "10.13.4.1",
        "authDoneTime": 1527204768355,
        "assocDoneTime": 1527204732979,
        "dhcpDoneTime": 1527204738372
      },
      "onboardingTime": 1527169633409,
      "port": null,
      "usage": 15232
    },
    {
      "id": "4C:66:41:2C:19:40",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "Dr.Staywell",
      "hostName": "Dr.Staywell-iPhone",
      "hostOs": "Apple-iOS",
      "hostVersion": "iOS12.4",
      "subType": "iPhone XR",
      "lastUpdated": 1525023087661,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 9
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 8
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 7
        }
      ],
      "hostMac": "4C:66:41:2C:19:40",
      "hostIpV4": "10.33.248.122",
      "hostIpV6": [
        "fe80::4aa:4ef3:6ff8:6ae4"
      ],
      "authType": "WPA/WPA2+NAC_POSTURE_REDIR",
      "vlanId": "100",
      "ssid": "LA-Corporate2",
      "frequency": "5.0",
      "channel": 36,
      "channelWidth": 20,
      "apGroup": "LA1",
      "location": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "clientConnection": "LA2-AP3802-23",
      "connectedDevice": [],
      "issueCount": 2,
      "rssi": "-56.0",
      "avgRssi": "-56.0",
      "snr": "48.0",
      "avgSnr": "48.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "1200.0",
      "txBytes": "751749.0",
      "rxBytes": "154251.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "-1.0",
      "dnsFailure": "-1.0",
      "onboarding": {
        "averageRunDuration": "9000",
        "maxRunDuration": "9000",
        "averageAssocDuration": "4500",
        "maxAssocDuration": "4500",
        "averageAuthDuration": "4500",
        "maxAuthDuration": "4500",
        "averageDhcpDuration": "4500",
        "maxDhcpDuration": "4500",
        "maxRoamingDuration": "9000",
        "aaaServerIp": "10.42.136.32",
        "dhcpServerIp": "10.13.4.1",
        "authDoneTime": 1527204768355,
        "assocDoneTime": 1527204732979,
        "dhcpDoneTime": 1527204738372
      },
      "onboardingTime": 1527169633409,
      "port": null,
      "usage": 15232
    },
    {
      "id": "4C:66:41:2C:19:41",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "Samuel Jones",
      "hostName": "Samuel Jones-iPad",
      "hostOs": "Apple-iOS",
      "hostVersion": "iOS12.4",
      "subType": "iPad",
      "lastUpdated": 1525023087661,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 8
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 9
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 7
        }
      ],
      "hostMac": "4C:66:41:2C:19:41",
      "hostIpV4": "10.33.248.123",
      "hostIpV6": [
        "fe80::4aa:4ef3:6ff8:6ae5"
      ],
      "authType": "WPA/WPA2+NAC_POSTURE_REDIR",
      "vlanId": "100",
      "ssid": "LA-Corporate2",
      "frequency": "5.0",
      "channel": 36,
      "channelWidth": 20,
      "apGroup": "LA1",
      "location": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "clientConnection": "LA2-AP3802-23",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-46.0",
      "avgRssi": "-46.0",
      "snr": "39.0",
      "avgSnr": "39.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "730.0",
      "txBytes": "851749.0",
      "rxBytes": "254251.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "-1.0",
      "dnsFailure": "-1.0",
      "onboarding": {
        "averageRunDuration": "10000",
        "maxRunDuration": "10000",
        "averageAssocDuration": "5000",
        "maxAssocDuration": "5000",
        "averageAuthDuration": "5000",
        "maxAuthDuration": "5000",
        "averageDhcpDuration": "5000",
        "maxDhcpDuration": "5000",
        "maxRoamingDuration": "10000",
        "aaaServerIp": "10.42.136.32",
        "dhcpServerIp": "10.13.4.1",
        "authDoneTime": 1527204768355,
        "assocDoneTime": 1527204732979,
        "dhcpDoneTime": 1527204738372
      },
      "onboardingTime": 1527169633409,
      "port": null,
      "usage": 15232
    },
    {
      "id": "C0:D3:C0:4D:B3:17",
      "connectionStatus": "CONNECTED",
      "hostType": "WIRELESS",
      "userId": "velma.dinkley",
      "hostName": "velma.dinkley-Galaxy-Tab-S2",
      "hostOs": "android-dhcp-7.0",
      "hostVersion": null,
      "subType": "Samsung-Device",
      "lastUpdated": 1525301404148,
      "healthScore": [
        {
          "healthType": "OVERALL",
          "reason": "",
          "score": 9
        },
        {
          "healthType": "ONBOARDED",
          "reason": "",
          "score": 9
        },
        {
          "healthType": "CONNECTED",
          "reason": "",
          "score": 8
        }
      ],
      "hostMac": "C0:D3:C0:4D:B3:17",
      "hostIpV4": "10.11.100.50",
      "hostIpV6": [
        "fe80::7e46:85ff:fe20:5cd5"
      ],
      "authType": "DHCP+WPA/WPA2+NAC_POSTURE_REDIR",
      "vlanId": "100",
      "ssid": "AMS-Corporate2",
      "frequency": "5.0",
      "channel": null,
      "apGroup": "AMS",
      "location": "Netherlands/AMS/Level3",
      "clientConnection": "AMS-AP3802-24",
      "connectedDevice": [],
      "issueCount": 0,
      "rssi": "-44.0",
      "avgRssi": "-44.0",
      "snr": "39.0",
      "avgSnr": "39.0",
      "rssiIsInclude": "true",
      "rssiThreshold": "-72.0",
	  "snrIsInclude": "true",
      "snrThreshold": "9.0",
      "dataRate": "820.0",
      "txBytes": "2440.0",
      "rxBytes": "5811.0",
      "rxRetryPct": "0.0",
      "dnsSuccess": "-1.0",
      "dnsFailure": "-1.0",
      "onboarding": {
        "averageRunDuration": "1000",
        "maxRunDuration": "1000",
        "averageAssocDuration": "500",
        "maxAssocDuration": "500",
        "averageAuthDuration": "500",
        "maxAuthDuration": "500",
        "averageDhcpDuration": "500",
        "maxDhcpDuration": "500",
        "maxRoamingDuration": "1000",
        "aaaServerIp": "192.168.139.168",
        "dhcpServerIp": "10.13.4.2",
        "authDoneTime": 1527204768355,
        "assocDoneTime": 1527204732979,
        "dhcpDoneTime": 1527204738372
      },
      "onboardingTime": 1527169633409,
      "port": null,
      "usage": 23456
    }
    ],
    "page": {
      "pageSize": 5,
      "offset": 0,
      "totalCount": 5
    }
  }
});
