//Cisco_4451_Series_Integrated_Services_Router
define({
    "runningImageList": [
        {
            "name": "isr4400-universalk9.17.09.01a.SPA.bin",
            "imageUuid": "4d56568b-fedc-5342-234a-9c944ddf005a4",
            "version": "17.9.1",
            "softwareType": null,
            "imageType": "RELEASE",
            "isTaggedGolden": "true",
            "md5Checksum": "61aa4b9172e29ed2fc65c2ac2d92088d",
            "shaCheckSum": "aca9c85cd4276bb077d2e20937237038df5fb88e5975b0f000a304e6af704313ac13cad47f66eaea24450390476abb153ff3525706ae8c15fd0ec22162446be0",
            "createdTime": "2022-08-24 15:29:00.00",
            "applicationType": null,
            "feature": null,
            "fileServiceId": "a15b132d-4263-4444-aa32-f2619ceae23c",
            "imageSeries": null,
            "imageSource": "file:/opt/maglev/services/swim-service/7.1.26.60138/localdisk/Staging/isr4400-universalk9.17.09.01a.SPA.bin",
            "imageSourceType": "Imported from File System",
            "imageFamily": "ISR4000",
            "vendor": null,
            "filesize": 793395087 ,
            "deviceCount": 0,
            "deviceUuidList": [
            ],
            "smuCount": 0,
            "imageAttributes": [
            ],
            "isSMUApplicable": true,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": true,
            "reasonForNotSupportingImport": null,
            "tagList": [
                {
                    "taggedGolden": true,
                    "role": "ALL",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "CORE",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "DISTRIBUTION",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "BORDER ROUTER",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "ACCESS",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": null,
            "ccorecommended": false,
            "ccolatest": true
        },{
            "name": "isr4400-universalk9.17.06.01a.SPA.bin",
            "imageUuid": "4d56568b-fedc-1243-234a-9c944ddf005a4",
            "version": "17.6.1",
            "softwareType": null,
            "imageType": "RELEASE",
            "isTaggedGolden": "false",
            "md5Checksum": "61aa4b9172e29ed2fc65c2ac2d92088d",
            "shaCheckSum": "aca9c85cd4276bb077d2e20937237038df5fb88e5975b0f000a304e6af704313ac13cad47f66eaea24450390476abb153ff3525706ae8c15fd0ec22162446be0",
            "createdTime": "2021-09-14 15:29:00.00",
            "applicationType": null,
            "feature": null,
            "fileServiceId": "a15b132d-4263-4444-aa32-f2619ceae23c",
            "imageSeries": null,
            "imageSource": "file:/opt/maglev/services/swim-service/7.1.26.60138/localdisk/Staging/isr4400-universalk9.17.03.01a.SPA.bin",
            "imageSourceType": "Imported from File System",
            "imageFamily": "ISR4000",
            "vendor": null,
            "filesize": 793395087 ,
            "deviceCount": 0,
            "deviceUuidList": [
            ],
            "smuCount": 0,
            "imageAttributes": [
            ],
            "isSMUApplicable": true,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": true,
            "reasonForNotSupportingImport": null,
            "tagList": [
                {
                    "taggedGolden": false,
                    "role": "ALL",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "CORE",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "DISTRIBUTION",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "BORDER ROUTER",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "UNKNOWN",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "ACCESS",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": null,
            "ccorecommended": false,
            "ccolatest": true
        },
        {
            "name": "isr4400-universalk9.17.03.03.SPA.bin",
            "imageUuid": "4d56568b-fedc-1243-234a-9c944f4005a4",
            "version": "17.3.3",//Amsterdam-17.03.03
            "softwareType": null,
            "imageType": "RELEASE",
            "isTaggedGolden": "false",
            "md5Checksum": "69bc148996ccb8ddebad4be732631c91",
            "shaCheckSum": "46d0b1028075dabc30f4b137180c197def6773370d011e2fa1af542a10d953f0f53283428b1f9421280ba0876420c05d8e9e7a3a5b3b2307b4c753c23e3660f0",
            "createdTime": "2021-03-05 15:29:00.00",//05-Mar-2021 Release
            "applicationType": null,
            "feature": null,
            "fileServiceId": "a15b132d-4263-4444-aa32-f2619ceae23c",
            "imageSeries": null,
            "imageSource": "file:/opt/maglev/services/swim-service/7.1.26.60138/localdisk/Staging/isr4400-universalk9.17.03.01a.SPA.bin",
            "imageSourceType": "Imported from File System",
            "imageFamily": "ISR4000",
            "vendor": null,
            "filesize": 703926387,
            "deviceCount": 0,
            "deviceUuidList": [
            ],
            "smuCount": 0,
            "imageAttributes": [
            ],
            "isSMUApplicable": true,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": true,
            "reasonForNotSupportingImport": null,
            "tagList": [
                {
                    "taggedGolden": false,
                    "role": "ALL",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "CORE",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "DISTRIBUTION",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "BORDER ROUTER",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "UNKNOWN",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "ACCESS",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": null,
            "ccorecommended": false,
            "ccolatest": true
        },
        {
            "name": "isr4400-universalk9.17.03.01a.SPA.bin",
            "imageUuid": "4d94948b-fedc-1243-234a-9c944f4005a4",
            "version": "17.3.1",//Amsterdam-17.3.1
            "softwareType": null,
            "imageType": "RELEASE",
            "isTaggedGolden": "false",
            "md5Checksum": "1f5a453543f95948966187ae21e15d74",
            "shaCheckSum": "f3f9ec9a55625a4e15824e5e9d9a91f9d85c614f143e1691a88eb5ac448980ae4e996a2fc3203757ae8586755e25f5d9dbb5e68811db3ff0387e91aed1a53f53",
            "createdTime": "2020-10-16 15:29:00.00",//16 Aug 2020 Release
            "applicationType": null,
            "feature": null,
            "fileServiceId": "a15b132d-4263-43e8-aa32-f2619ceae23c",
            "imageSeries": null,
            "imageSource": "file:/opt/maglev/services/swim-service/7.1.26.60138/localdisk/Staging/isr4400-universalk9.17.03.01a.SPA.bin",
            "imageSourceType": "Imported from File System",
            "imageFamily": "ISR4000",
            "vendor": null,
            "filesize": 705643984,
            "deviceCount": 0,
            "deviceUuidList": [
            ],
            "smuCount": 0,
            "imageAttributes": [
            ],
            "isSMUApplicable": true,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": true,
            "reasonForNotSupportingImport": null,
            "tagList": [
                {
                    "taggedGolden": false,
                    "role": "ALL",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "CORE",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "DISTRIBUTION",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "BORDER ROUTER",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "UNKNOWN",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "ACCESS",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": null,
            "ccorecommended": false,
            "ccolatest": true
        },
        {
            "name": "isr4400-universalk9.17.02.01r.SPA.bin",
            "imageUuid": "4d94948b-48cf-1243-234a-9c944f4005a4",
            "version": "17.2.1r",//Amsterdam-17.2.1r
            "softwareType": null,
            "imageType": "RELEASE",
            "isTaggedGolden": "false",
            "md5Checksum": "a89a6335b78e4a540d28934cea94809d",
            "shaCheckSum": "5f0dfa698afcf9dba157f7764b3f18404c81b04ac1f8ffe1cc10306553411f3b3834956536470e704b20351abf9522bffdb57abc6342ad4886712337819dab98",
            "createdTime": "2020-04-29 13:18:00.00",//14 Apr 2020 Release
            "applicationType": null,
            "feature": null,
            "fileServiceId": "a15b132d-1111-43e8-aa32-f2619ceae23c",
            "imageSeries": null,
            "imageSource": "file:/opt/maglev/services/swim-service/7.1.26.60138/localdisk/Staging/isr4400-universalk9.17.02.01r.SPA.bin",
            "imageSourceType": "Imported from File System",
            "imageFamily": "ISR4000",
            "vendor": null,
            "filesize": 695011020 ,
            "deviceCount": 0,
            "deviceUuidList": [
            ],
            "smuCount": 0,
            "imageAttributes": [
            ],
            "isSMUApplicable": true,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": true,
            "reasonForNotSupportingImport": null,
            "tagList": [
                {
                    "taggedGolden": false,
                    "role": "ALL",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "CORE",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "DISTRIBUTION",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "BORDER ROUTER",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "UNKNOWN",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "ACCESS",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": null,
            "ccorecommended": false,
            "ccolatest": true
        },
        {
            "name": "isr4400-universalk9.16.12.02t.SPA.bin",
            "imageUuid": "4d94948b-48cf-6412-234a-9c944f4005a4",
            "version": "16.12.2t",//Gibraltar-16.12.2t
            "softwareType": null,
            "imageType": "RELEASE",
            "isTaggedGolden": "false",
            "md5Checksum": "a89a6335b78e4a540d28934cea94809d",
            "shaCheckSum": "5f0dfa698afcf9dba157f7764b3f18404c81b04ac1f8ffe1cc10306553411f3b3834956536470e704b20351abf9522bffdb57abc6342ad4886712337819dab98",
            "createdTime": null,
            "applicationType": null,
            "feature": null,
            "fileServiceId": "a15b132d-1111-43e8-aa32-f2619ceae23c",
            "imageSeries": null,
            "imageSource": "file:/opt/maglev/services/swim-service/7.1.26.60138/localdisk/Staging/isr4400-universalk9.16.12.02t.SPA.bin",
            "imageSourceType": "Imported from File System",
            "imageFamily": "ISR4000",
            "vendor": null,
            "filesize": 634767534 ,
            "deviceCount": 0,
            "deviceUuidList": [
            ],
            "smuCount": 0,
            "imageAttributes": [
            ],
            "isSMUApplicable": true,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": true,
            "reasonForNotSupportingImport": null,
            "tagList": [
                {
                    "taggedGolden": false,
                    "role": "ALL",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "CORE",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "DISTRIBUTION",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "BORDER ROUTER",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "UNKNOWN",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "ACCESS",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": null,
            "ccorecommended": false,
            "ccolatest": true
        },
        {
            "name": "isr4400-universalk9.17.01.01.SPA.bin",
            "imageUuid": "4d94948b-48cf-3232-234a-9c944f4005a4",
            "version": "17.1.1",//Amsterdam-17.1.1
            "softwareType": null,
            "imageType": "RELEASE",
            "isTaggedGolden": "false",
            "md5Checksum": "a89a6335b78e4a540d28934cea94809d",
            "shaCheckSum": "5f0dfa698afcf9dba157f7764b3f18404c81b04ac1f8ffe1cc10306553411f3b3834956536470e704b20351abf9522bffdb57abc6342ad4886712337819dab98",
            "createdTime": null,
            "applicationType": null,
            "feature": null,
            "fileServiceId": "a15b132d-1111-43e8-aa32-f2619ceae23c",
            "imageSeries": null,
            "imageSource": "file:/opt/maglev/services/swim-service/7.1.26.60138/localdisk/Staging/isr4400-universalk9.17.01.01.SPA.bin",
            "imageSourceType": "Imported from File System",
            "imageFamily": "ISR4000",
            "vendor": null,
            "filesize": 634767534 ,
            "deviceCount": 0,
            "deviceUuidList": [
            ],
            "smuCount": 0,
            "imageAttributes": [
            ],
            "isSMUApplicable": true,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": true,
            "reasonForNotSupportingImport": null,
            "tagList": [
                {
                    "taggedGolden": false,
                    "role": "ALL",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "CORE",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "DISTRIBUTION",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "BORDER ROUTER",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "UNKNOWN",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "ACCESS",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": null,
            "ccorecommended": false,
            "ccolatest": true
        },
        {
            "name": "isr4400-universalk9.16.12.01s.SPA.bin",
            "imageUuid": "4d94948b-48cf-4956-234a-9c944f4005a4",
            "version": "16.12.1s",//Gibraltar-16.12.1a
            "softwareType": null,
            "imageType": "RELEASE",
            "isTaggedGolden": "false",
            "md5Checksum": "a89a6335b78e4a540d28934cea94809d",
            "shaCheckSum": "5f0dfa698afcf9dba157f7764b3f18404c81b04ac1f8ffe1cc10306553411f3b3834956536470e704b20351abf9522bffdb57abc6342ad4886712337819dab98",
            "createdTime": null,
            "applicationType": null,
            "feature": null,
            "fileServiceId": "a15b132d-1111-43e8-aa32-f2619ceae23c",
            "imageSeries": null,
            "imageSource": "file:/opt/maglev/services/swim-service/7.1.26.60138/localdisk/Staging/isr4400-universalk9.16.12.01s.SPA.bin",
            "imageSourceType": "Imported from File System",
            "imageFamily": "ISR4000",
            "vendor": null,
            "filesize": 634767534 ,
            "deviceCount": 0,
            "deviceUuidList": [
            ],
            "smuCount": 0,
            "imageAttributes": [
            ],
            "isSMUApplicable": true,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": true,
            "reasonForNotSupportingImport": null,
            "tagList": [
                {
                    "taggedGolden": false,
                    "role": "ALL",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "CORE",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "DISTRIBUTION",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "BORDER ROUTER",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "UNKNOWN",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "ACCESS",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": null,
            "ccorecommended": false,
            "ccolatest": true
        }
    ]
});
