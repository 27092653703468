define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/swim/SimSwimHelper',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/ImageTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/ImageListingTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/ImageBaseTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/SimulationUtility',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/DefaultConfig',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/ImportBaseTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/task/TaskTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/VerifyConfig',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/SwimTaskList',
   'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/Virtual_Images',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/inventory/ScheduledJobTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_processes_memory_platform_sorted',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimScheduledJobData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/UpgradeImageTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/list/New_Virtual_Image'
], function(SimLokiDatabaseActions,SimSwimHelper,SimTaskDataGenerator,ImageTemplate, ImageListingTemplate,
            ImageBaseTemplate, UtilityFunctions,SimulationUtility,DefaultConfig,ImportBaseTemplate, TaskTemplate,
            VerifyConfig, SwimTaskList, Virtual_Images, ScheduledJobTemplate, show_processes_memory_platform_sorted,
            SimScheduledJobData,UpgradeImageTemplate,New_Virtual_Image){

  //Site vars for common Usage
  var siteName = "Global";
  var siteId = "-1";
  var SwimList= [];
  var deletionTime = 2*1000;//2 seconds
  var importTime = 90*1000;//90 seconds
  var DISTRIBUTION_TIME = 2;
  var ACTIVATION_TIME_IN_MIN = 3;
  var DISTRIBUTION_ERROR_TIME = 1;

  function isImageNotPresentForDevice(type) {
      return ImageListingTemplate.ImageTypeMapping[type] == undefined  ? true : false;
  }

  function deleteDevice(deviceId) {
      var record = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': deviceId});
      if(record && record.length > 0) {
           SimLokiDatabaseActions.delete('network-device', record);
      }
  }



//  function importImageTemplate(){
//     var rJson = JSON.parse(JSON.stringify(ImportBaseTemplate.Template));
//     var SwimList = SimSwimData.SwimList;

//     for(var i = 0; i < SwimList.length; i++) {
//         rJson.deviceFamily = SwimList[i].deviceFamily;
//         rJson.deviceTypeOrdinal = SwimList[i].deviceTypeOrdinal;
//         rJson.softwareType = SwimList[i].softwareType;
//         rJson.runningImageList= SwimList[i].runningImageList;

//         console.log(rJson);
//   }
//   }

  function associateDeviceToImageInDB(type, deviceId, softwareVersion, platformId) {
      //var obj = ImageListingTemplate.ImageTypeMapping[type];
      var record = SimLokiDatabaseActions.getFilteredRecordHandler('images', {'deviceFamily': type});

      if(record.length == 0) {
          //console.log('trying to create image entry for ' + type);
          var tJson = JSON.parse(JSON.stringify( ImageBaseTemplate.Template ));
          var obj = ImageListingTemplate.ImageTypeMapping[type];
          tJson.deviceFamily = type;
          tJson.deviceTypeOrdinal = obj.deviceTypeOrdinal;
          tJson.softwareType = obj.softwareType;
          tJson.productFamily = obj.productFamily;
          var t = type.replace(/ /g,"_");
          t = t.replace(/-/g,"_");
          var RunningImgList = SimSwimHelper.getRunningImageList(t);
          if( RunningImgList != undefined && RunningImgList.length) {
              for(var i = 0; i < RunningImgList.length; i++) {
                RunningImgList[i].addOnCount = 0;
                  if(RunningImgList[i].version == softwareVersion){
                    var isValidImage = SimSwimHelper.imageInApplicableDevices(RunningImgList[i].applicableDevicesForImage, platformId);
                    if(isValidImage) {
                        RunningImgList[i].deviceCount = 1;
                        RunningImgList[i].deviceUuidList.push(deviceId);
                    }
                  } else {
                      //sofware vestion missing. may need fix.
                  }
                  tJson.runningImageList.push(RunningImgList[i]);
              }
              SimLokiDatabaseActions.insert('images', tJson );
          }
          else {
              //we have to work record[0] and do update for that devicid ,
              //at last update to image db in loki has to happen...
          }
      } else {
          RunningImgList=record[0].runningImageList;
          if( RunningImgList != undefined && RunningImgList.length) {
              for(var i = 0; i < RunningImgList.length; i++) {
                if(RunningImgList[i].version == softwareVersion){
                    //console.log("found matching software version!!");
                  var isValidImage = SimSwimHelper.imageInApplicableDevices(RunningImgList[i].applicableDevicesForImage, platformId);
                  if(isValidImage) {
                      //console.log("found validimage!!");
                      record[0].runningImageList[i].deviceCount = record[0].runningImageList[i].deviceCount + 1;
                      record[0].runningImageList[i].deviceUuidList.push(deviceId);
                      //alert('match found in update -- increment count.. ' + type + ' ' + record[0].runningImageList[i].deviceCount);
                  }
                } //else {
                      //alert('something wrong -- image sw version not correct when duplicate image used..');
                  //}
            }
              SimLokiDatabaseActions.update( 'images', record );
          }
      }
  }

  //This function creates the images`entries in the Design tab. It loads the contents from the Image Template and shows.
  //@No Param
  function createDefaultImages() {
      var devices = SimLokiDatabaseActions.getAll('network-device');
      var pnpTypes = [];
      for(var i = 0; i < devices.length; i++) {
          if(isImageNotPresentForDevice(devices[i].type)) {
              //console.log('Not handled -- ' + devices[i].type);
              continue;
          }
          associateDeviceToImageInDB(devices[i].type, devices[i].id, devices[i].softwareVersion, devices[i].platformId);
      }
      //also add images that will be used in PnP
      Object.keys(DefaultConfig.PNP_DEVICE_DETAILS).forEach(e1 => {
          var demo = DefaultConfig.PNP_DEVICE_DETAILS[e1].network;
          Object.keys(demo).forEach(e2 => {
            pnpTypes = pnpTypes.concat(demo[e2].map(e3 => e3.deviceInfo.type));
          })
      });
      pnpTypes.forEach(pnpType => {
          if(!isImageNotPresentForDevice(pnpType)) {
              associateDeviceToImageInDB(pnpType);
          }
      });
      
  }

  function createVirtualImages() {
     // var tJson = JSON.parse(JSON.stringify(Virtual_Images.VIRTUAL_IMAGES));
     var tJson = JSON.parse(JSON.stringify(New_Virtual_Image.VIRTUAL_IMAGE));
      for(var i=0; i<tJson.length; i++) {
          SimLokiDatabaseActions.insert('images', tJson[i]);
      }
  }
 


  function createImageTaskList() {
    var tJson = JSON.parse(JSON.stringify(SwimTaskList.Taskresponse));
    for(var i=0; i<tJson.length; i++) {
        var timeDiff = Number(tJson[i].completionTime) - Number(tJson[i].startTime);
        var startTime = UtilityFunctions.getTimeStamp() - UtilityFunctions.getMinuteToMilliSeconds(i*60);
        tJson[i].startTime = startTime.toString();
        tJson[i].completionTime = (startTime + timeDiff).toString();
    }
    SimLokiDatabaseActions.insert('image-task-list', tJson);
  }

  //Mark or unmark Golden
  function tagGolden(existingRunningImage, deviceRole, tagGolden) {
      var null_const = "null";
      if(existingRunningImage && deviceRole && existingRunningImage.tagList) {
        var tagGoldenCount = 0;
        for(var i = 0; i<existingRunningImage.tagList.length; i++) {
            if(existingRunningImage.tagList[i].taggedGolden) {
                tagGoldenCount = tagGoldenCount+1;
            }
            if(existingRunningImage.tagList[i].role) {
                // Iterate to pick the correct tag in the imageFamily.. To pick the exact one, deviceRole is sent in the request.
                if(existingRunningImage.tagList[i].role == deviceRole) {
                    var isTagGolden = "true", tSiteName = siteName, tSiteId = siteId;
                    if(tagGolden == false) {
                        isTagGolden = "false";
                        tSiteName = null_const;
                        tSiteId = null_const;
                        tagGoldenCount = tagGoldenCount-1;
                    } else {
                        tagGoldenCount = tagGoldenCount+1;
                    }
                    //existingRunningImage.isTaggedGolden = isTagGolden;
                    existingRunningImage.tagList[i].taggedGolden = tagGolden;
                    existingRunningImage.goldenTagInheritedFromSiteName= tSiteName;
                    existingRunningImage.goldenTagInheritedFromSiteUuid= tSiteId;
                    //return existingRunningImage;
                }
            }
        }
        //even if one is tagged golden, the taggolden flag of running image should be tagged golden
        if(tagGoldenCount>0) {
            existingRunningImage.isTaggedGolden = "true";
        } else {
            existingRunningImage.isTaggedGolden = "false";
        }
        return existingRunningImage;
      }
      if(deviceRole=="" || existingRunningImage.tagList.length==0) {
        existingRunningImage.isTaggedGolden = tagGolden.toString();
      }
  }

  // Fetch RunningImage for imageUUid
  //@param existingRecord : one Record in which the runningimageList needs to be chosen
  //imageUUid: id of the runningimage
  //Returns if the update is done or not Status
  function fetchImageForImageUuid(imageUuid, existingRecord, deviceRole, isGolden) {
      if(existingRecord != undefined && imageUuid != undefined) {
          if(existingRecord.runningImageList != undefined) {
              //Iterate the images` existingRecord to pick the correct image family
              for(secondIndex=0;secondIndex<existingRecord.runningImageList.length;secondIndex++)
              {
                  var existingRunningImage = existingRecord.runningImageList[secondIndex];
                  existingImageUuid=existingRunningImage.imageUuid;
                  if(existingImageUuid != undefined) {
                      if(existingImageUuid.trim() == imageUuid){
                          tagGolden(existingRunningImage,deviceRole,isGolden);
                          return true;
                      }
                  }
              }
          }
      }
      return false;
  }

  // Fetch RunningImage for deviceUuid
  //@param existingRecord : one Record in which the runningimageList needs to be chosen
  //deviceUuid: id of the device running the runningimage
  function fetchImageForDeviceUuid(deviceUuid, existingRecord, deviceRole, isGolden) {
      if(existingRecord != undefined && deviceUuid != undefined) {
          if(existingRecord.runningImageList != undefined) {
              for(secondIndex=0; secondIndex<existingRecord.runningImageList.length; secondIndex++)
              {
                  var existingDeviceUuidList=existingRecord.runningImageList[secondIndex].deviceUuidList;
                  if(existingDeviceUuidList != undefined) {
                      //console.log("Checking for match of image with "+ existingRecord.runningImageList[secondIndex].name);
                      // Iterate to pick the correct image in the imageFamily.. To pick the exact one, deviceUuid is sent in the request.
                      for(thirdIndex=0; thirdIndex<existingDeviceUuidList.length; thirdIndex++) {
                          if(existingDeviceUuidList[thirdIndex] == deviceUuid) {
                              tagGolden(existingRecord.runningImageList[secondIndex],deviceRole,isGolden);
                              return true;
                          }
                      }
                  }
              }
          }
      }
      return false;
  }

  function updateImageUsingImageName(imageName, existingRecord, deviceRole, isGolden) {
        if(existingRecord.runningImageList != undefined) {
        for(var i=0;i<existingRecord.runningImageList.length;i++)
        {
            var existingRunningImage = existingRecord.runningImageList[i];
            if(existingRunningImage.name && existingRunningImage.name == imageName){
                existingRunningImage = tagGolden(existingRunningImage,deviceRole,isGolden);
                return true;
            }
        }
    }
    return false;
  }

  //use only for upgrade os as we are modifying the running image details based on the golden image too
  function getGoldenImageDetail(deviceId, deviceType, deviceRole, devPlatformId) {
    var imageRecord = SimLokiDatabaseActions.getFilteredRecordHandler('images', {'deviceAvailable':true});//physical device images only
    var goldenImageDetail = null;
    for(var j=0; j<imageRecord.length; j++) {
        if(imageRecord[j].deviceFamily == deviceType) {
            for(var k=0; k<imageRecord[j].runningImageList.length; k++) {
                var runningImage = imageRecord[j].runningImageList[k];
                var index = runningImage.deviceUuidList.indexOf(deviceId);
                if (index>-1) {
                    runningImage.deviceUuidList.splice(index,1);
                    runningImage.deviceCount = runningImage.deviceCount-1;
                }
                var isValidImage = SimSwimHelper.imageInApplicableDevices(runningImage.applicableDevicesForImage, devPlatformId);
                if(isValidImage && runningImage.isTaggedGolden === "true") {
                    for(var i=0; i<runningImage.tagList.length; i++) {
                        var tag = runningImage.tagList[i];
                        //check the role
                        if((tag.role == "ALL" || tag.role == deviceRole) && tag.taggedGolden) {
                            //if (runningImage.deviceUuidList.indexOf(deviceId)<0) {
                                runningImage.deviceUuidList.push(deviceId);
                                runningImage.deviceCount = runningImage.deviceCount+1;
                                //update images only if there has been a change in deviceUuidList and count
                                SimLokiDatabaseActions.update('images',imageRecord);
                                goldenImageDetail = runningImage;
                                break;
                            //}
                        }
                    }
                }
            }
        }
    }
    return goldenImageDetail;
  }

  /**
   * image-activation table: used to show first level of data in Upgrade Status
   * upgrade-os-list table: used to show data when expanding the second level data in Upgrade Status (Show details of a status)
   * validation-result table: used to display detail of View Detail of distribute task
   */
  function upgradeList(action, deviceData, taskId, startTime, isScheduled, generatedUUid, isInit) {
    var isMarkFailure = isOsUpgradeDeviceFailure(deviceData.id);
    if(action == "activate" && isMarkFailure && !isInit) {
        return;
    }
    let isRollingApWlc = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo',{'networkDeviceId':deviceData.id});
    isRollingApWlc = deviceData.family=="Wireless Controller" && isRollingApWlc.length>0 &&
        isRollingApWlc[0].rapUpgradeCfsProperty && isRollingApWlc[0].rapUpgradeCfsProperty.enableRapUpgrade;
    var imageDtl = getGoldenImageForDevice(deviceData.type);
    var tJson1 = JSON.parse(JSON.stringify(TaskTemplate.TASK_SWIM_IMAGE_ACTIVATE));
    var activationTaskUuid = UtilityFunctions.generate_uuid();
    var parentTaskUuid = UtilityFunctions.generate_uuid();
    tJson1.taskUuid = taskId;
    tJson1.deviceTaskUuid = UtilityFunctions.generate_uuid();
    tJson1.isSimulationOsUpgraded = false;
    if(!isScheduled && generatedUUid) {
        tJson1.deviceTaskUuid = generatedUUid.deviceTaskUuid;
        tJson1.parentTaskUuid = generatedUUid.unitTaskUuid;
    }
    tJson1.unitTaskUuid = parentTaskUuid;
    if(action == "distribute") {
        //to complete immediate: (show only time diff)
        tJson1.completionTime = (startTime + UtilityFunctions.getMinuteToMilliSeconds(DISTRIBUTION_TIME)).toString();
        tJson1.taskStatus = "success";
    }if(action == "activate") {
        //to complete after some time (as requested by Satpal)
        tJson1.taskType = "activate";
        tJson1.completionTime = (startTime + UtilityFunctions.getMinuteToMilliSeconds(ACTIVATION_TIME_IN_MIN)).toString();
        tJson1.taskStatus = "in-progress";
    }
    tJson1.startTime = startTime.toString();
    //tJson1.taskStatus = "success";//"In Progress";//"success";
    tJson1.deviceId = deviceData.id;
    tJson1.imageName = imageDtl.name;
    tJson1.targetImageVersion = imageDtl.version;
    tJson1.deviceIp = deviceData.managementIpAddress;
    tJson1.hostName = deviceData.hostname;
    tJson1.family = deviceData.family;
    if(isMarkFailure && !isInit) {
        tJson1.taskStatus = 'failure';
        tJson1.isError = true;
        tJson1.failureReason = 'Insufficient memory on device!';
        tJson1.completionTime = (startTime + UtilityFunctions.getMinuteToMilliSeconds(DISTRIBUTION_ERROR_TIME)).toString();
        tJson1.failureCount = tJson1.failureCount+1;
    }

    if(!isScheduled && action == "activate") {
        var record = SimLokiDatabaseActions.getFilteredRecordHandler("image-activation", {"parentTaskUuid":tJson1.parentTaskUuid});
        record[0].completionTime = tJson1.completionTime;//distribute+activate time
        record[0].taskType = "activate";
        SimLokiDatabaseActions.update("image-activation",record);
    } else {
        //scheduled activate or distribute of scheduled or immediate
        SimLokiDatabaseActions.insert("image-activation", tJson1); //to handle another call that follows immediately after this
    }

    if(action == "distribute") {
        var tJson2 = [];
        tJson2.push(JSON.parse(JSON.stringify(TaskTemplate.TASK_IMAGE_ACTIVATE_PARENT_REQ[0])));
        tJson2[0].startTime = startTime;
        //to complete immediate: (show only time diff)
        //tJson2[0].endTime = startTime + UtilityFunctions.getMinuteToMilliSeconds(8);
        //to complete after some time (as requested by Satpal)
        tJson2[0].endTime = startTime + UtilityFunctions.getMinuteToMilliSeconds(DISTRIBUTION_TIME);
        tJson2[0].version = startTime;
        tJson2[0].lastUpdate = tJson2[0].version;
        tJson2[0].parentId = tJson1.deviceTaskUuid;
        var progress = tJson2[0].progress.replace('[]', tJson1.imageName);
        progress = progress.replace('[]', tJson1.deviceIp);
        tJson2[0].progress = progress;
        tJson2[0].rootId = tJson1.taskUuid;
        tJson2[0].id = parentTaskUuid;
        var data = {"parentId": tJson1.deviceTaskUuid, "result": tJson2}; // for easy retrieval
        if(isMarkFailure && !isInit) {
            var idx = tJson2[0].progress.indexOf('with protocol');
            tJson2[0].progress = tJson2[0].progress.substring(0,idx) + "failed due to insufficeient memory on device";
            tJson2[0].isError = true;
            tJson2[0].failureReason = 'Insufficient memory on device!';
            tJson2[0].endTime = startTime + UtilityFunctions.getMinuteToMilliSeconds(DISTRIBUTION_ERROR_TIME);
        }
        SimLokiDatabaseActions.insert("upgrade-os-list", data); //show distribute and activate in upgrade os list
    } else if(action == "activate") {
        var tJson2 = [], endTime;
        if(isScheduled) {
            tJson2.push(JSON.parse(JSON.stringify(TaskTemplate.TASK_IMAGE_ACTIVATE_PARENT_REQ[0])));
            tJson2[0].startTime = startTime;
            tJson2[0].endTime = startTime + 900; //hardly a second as distribution is already completed
            tJson2[0].version = startTime;
            tJson2[0].lastUpdate = tJson2[0].version;
            tJson2[0].parentId = tJson1.deviceTaskUuid;
            tJson2[0].progress = "The image is already present in the device";
            tJson2[0].rootId = tJson1.taskUuid;
            tJson2[0].id = UtilityFunctions.generate_uuid(); //newly generated as this id should not be present in verify config task
            //to complete immediate: (show only time diff)
            //startTime = startTime + 1000; //a second delayed
            tJson2.push(JSON.parse(JSON.stringify(TaskTemplate.TASK_IMAGE_ACTIVATE_PARENT_REQ[1])));
            tJson2[1].startTime = startTime;
            //to complete immediate: (show only time diff)
            //tJson2[1].endTime = startTime + UtilityFunctions.getMinuteToMilliSeconds(10);
            //to complete after some time (as requested by Satpal)
            tJson2[1].endTime = startTime + UtilityFunctions.getMinuteToMilliSeconds(ACTIVATION_TIME_IN_MIN);
            tJson2[1].version = tJson2[1].startTime;
            tJson2[1].parentId = tJson1.deviceTaskUuid;
            progress = tJson2[1].progress.replace('[]', tJson1.imageName);
            progress = progress.replace('[]', tJson1.deviceIp);
            if(deviceData.family=="Wireless Controller") {
                var aps = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'associatedWlcIp':tJson1.deviceIp});
                progress += ". \nAP Image Predownload Status : Total number of APs = "+aps.length+", initiated = 0, downloading = 0, predownloading = 0, completed predownloading = "+aps.length+", not supported = 0, failed to predownload = 0. "
            }
            tJson2[1].progress = progress;
            tJson2[1].rootId = tJson2[0].rootId;
            tJson2[1].id = activationTaskUuid;
            endTime = tJson2[1].endTime;
            if(isRollingApWlc) {
                tJson2.push(JSON.parse(JSON.stringify(tJson2[1])));
                tJson2[2].startTime = tJson2[1].endTime;
                tJson2[2].endTime = tJson2[2].startTime + 10000;//10s
                tJson2[2].data = "RAP Upgrade";
                tJson2[2].additionalStatusURL = "/dna-wireless-service/rolling-ap-status/62f4cb08-ff42-4878-b434-b53539efff8b";
                tJson2[2].serviceType = "DNA_WIRLESS_SERVICE_RAPUPGRADE_HANDLER";
                tJson2[2].progress = "Rolling AP Upgrade completed successfully";
                tJson2[2].id = "62f4cb08-ff42-4878-b434-b53539efff8b";
            }
            var data = {"parentId": tJson1.deviceTaskUuid, "result": tJson2};
            SimLokiDatabaseActions.insert("upgrade-os-list", data);

            var imageActivationData = SimLokiDatabaseActions.getAll("image-activation");
            var successCount = imageActivationData.filter(record => {
                return record.taskStatus == 'success';
            });
            var inProgressCount = imageActivationData.filter(record => {
                return record.taskStatus == 'in-progress';
            });
            var failureCount = imageActivationData.filter(record => {
                return record.taskStatus == 'failure';
            });
            imageActivationData.map(record =>{
                record.inProgressCount = inProgressCount.length;
                record.successCount = successCount.length;
                record.failureCount = failureCount.length;
                SimLokiDatabaseActions.update("image-activation",JSON.parse(JSON.stringify(record)));
            })

        } else {
            var record = SimLokiDatabaseActions.getFilteredRecordHandler("upgrade-os-list", {"parentId":tJson1.deviceTaskUuid});
            tJson2 = record[0].result;
            record[0].result.push(JSON.parse(JSON.stringify(TaskTemplate.TASK_IMAGE_ACTIVATE_PARENT_REQ[1])));
            record[0].result[1].startTime = startTime;
            //to complete immediate: (show only time diff)
            //record[0].result[1].endTime = startTime + UtilityFunctions.getMinuteToMilliSeconds(10);
            //to complete after some time (as requested by Satpal)
            record[0].result[1].endTime = startTime + UtilityFunctions.getMinuteToMilliSeconds(ACTIVATION_TIME_IN_MIN);
            record[0].result[1].version = record[0].result[1].startTime;
            record[0].result[1].parentId = tJson1.deviceTaskUuid;
            progress = record[0].result[1].progress.replace('[]', tJson1.imageName);
            progress = progress.replace('[]', tJson1.deviceIp);
            if(deviceData.family=="Wireless Controller") {
                var aps = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'associatedWlcIp':tJson1.deviceIp});
                progress += ". \nAP Image Predownload Status : Total number of APs = "+aps.length+", initiated = 0, downloading = 0, predownloading = 0, completed predownloading = "+aps.length+", not supported = 0, failed to predownload = 0. "
                
                 // for WLC adding Rolling AP Upgrade
                //  rData = JSON.parse(JSON.stringify(TaskTemplate.TASK_IMAGE_ACTIVATE_PARENT_REQ));
                //  for (var i=0; i<rData.length; i++){
                //      if(i==2){
                //          record[0].result.push(rData[i]);
                //      }
                //  }
                //  record[0].result[2].startTime = record[0].result[1].startTime;
                //  record[0].result[2].endTime = record[0].result[1].endTime; //need to verify
                //  record[0].result[2].version = record[0].result[1].startTime;
                //  record[0].result[2].parentId = tJson1.deviceTaskUuid;
                //  record[0].result[2].rootId = record[0].result[0].rootId;
            }
            record[0].result[1].progress = progress;
            record[0].result[1].rootId = record[0].result[0].rootId;
            record[0].result[1].id = activationTaskUuid;
            endTime = record[0].result[1].endTime;
            if(isRollingApWlc) {
                record[0].result.push(JSON.parse(JSON.stringify(record[0].result[1])));
                record[0].result[2].startTime = record[0].result[1].endTime;
                record[0].result[2].endTime = record[0].result[2].startTime + 10000;//10s
                record[0].result[2].data = "RAP Upgrade";
                record[0].result[2].additionalStatusURL = "/dna-wireless-service/rolling-ap-status/62f4cb08-ff42-4878-b434-b53539efff8b";
                record[0].result[2].serviceType = "DNA_WIRLESS_SERVICE_RAPUPGRADE_HANDLER";
                record[0].result[2].progress = "Rolling AP Upgrade completed successfully";
                record[0].result[2].id = "62f4cb08-ff42-4878-b434-b53539efff8b";
            }
            SimLokiDatabaseActions.update("upgrade-os-list", record);
        } 
        //to complete after some time (as requested by Satpal) - start
        var timeLag = endTime - UtilityFunctions.getTimeStamp();
        console.log("Device "+deviceData.hostname+"'s image will be updated after "+Math.ceil(timeLag/(1000*60))+" minutes.");
        /* timeout will work if we stay on the same page without refreshing. 
        Hence moving this logic to where status needs to be checked and only then the device will be updated. */
        //var x = setTimeout(updateImageVersionAfterUpgrade(deviceData), timeLag);
        //to complete after some time (as requested by Satpal) - end
    }
    //if(action == "distribute" && !isMarkFailure) {
    if(action == "distribute") {
        //CPU Health
        //var tJson = JSON.parse(JSON.stringify(VerifyConfig.VERIFY_IMAGE_RESULT));
        //Flash check
        var tJson = JSON.parse(JSON.stringify(VerifyConfig.VERIFY_DISTRIBUTION_STATUS));
        //tJson[0].resultTaskUuid = UtilityFunctions.generate_uuid();
        tJson[0].parentTaskUuid = parentTaskUuid;
        //tJson[0].validationBindingUuid = UtilityFunctions.generate_uuid();//fixed
        //tJson[0].validatorUuid = UtilityFunctions.generate_uuid();
        tJson[0].createdTime = startTime;
        tJson[0].updatedTime = UtilityFunctions.getGivenTimeInVerifyFormat(startTime + 6000);
        tJson[0].deviceUuid = tJson1.deviceId;
        tJson[0].deviceIpAddress = tJson1.deviceIp;
        tJson[0].hostName = deviceData.hostname;
        tJson[0].simCompletedTime = startTime + DISTRIBUTION_TIME;
        //tJson[0].resultDetail[3].value = tJson[0].resultDetail[3].value.replace('SS-FanOut-3K',deviceData.hostname);//for CPU Health check
        if(isMarkFailure && !isInit) {
            tJson[0].resultStatus = "FAILED";
            tJson[0].detail = "Flash check: FAILED",
            tJson[0].detail = "Insufficient Memory on device";
            tJson[0].resultDetail[0].value = "688 MB\n Available Free space is: 578 MB";
            tJson[0].resultDetail[1].value = "FAILED";
        }
        SimLokiDatabaseActions.insert("validation-result", tJson); //show script in upgrade os list of distribute
    } else if(action == "activate") {
        //added in 1.3
        var tJson = JSON.stringify(VerifyConfig.VERIFY_ACTIVATION_STATUS);
        //tJson = tJson.replace(/Cat9300-Edge-02/g, deviceData.hostname);
        tJson = tJson.replace(/C9300-Edge02.ibng.local/g, deviceData.hostname);
        tJson = JSON.parse(tJson);
        if(deviceData.family=="Wireless Controller")
            tJson = tJson.filter(e => ["Config register check", "Startup config check"].indexOf(e.validatorName) > -1);
        for(var iter1=0; iter1<tJson.length; iter1++) {
            //purposely one of the objects in this array is commented as it has neighbouring device data. - CDP Neighbour Check
            //tJson[iter1].resultTaskUuid = UtilityFunctions.generate_uuid();//already unique in template, hence commenting
            tJson[iter1].parentTaskUuid = activationTaskUuid;
            //tJson[iter1].validationBindingUuid = UtilityFunctions.generate_uuid();//already unique in template, hence commenting
            //tJson[iter1].validatorUuid = UtilityFunctions.generate_uuid();//should be same for PRE and POSt of a same validator
            tJson[iter1].createdTime = startTime;
            tJson[iter1].updatedTime = UtilityFunctions.getGivenTimeInVerifyFormat(startTime + 2000);
            tJson[iter1].deviceUuid = tJson1.deviceId;
            tJson[iter1].deviceIpAddress = tJson1.deviceIp;
            tJson[iter1].hostName = deviceData.hostname;
            tJson[iter1].simCompletedTime = startTime + (iter1+1)*1800000;//30s increment
            if(iter1==tJson.length-1 || (tJson[iter1].simCompletedTime > startTime+ACTIVATION_TIME_IN_MIN) ) {
                tJson[iter1].simCompletedTime = startTime + ACTIVATION_TIME_IN_MIN;
            }
        }
        SimLokiDatabaseActions.insert("validation-result", tJson); //show script in upgrade os list of distribute
    }

    if(isMarkFailure && !isInit) {
        return {'isError':true, 'failureReason':'Insufficient memory on device!', 'imageName':imageDtl.name};
    } else {
        return {'isError':false, 'failureReason':null, 'imageName':imageDtl.name};
    }
  }

  function getGoldenImageForDevice(devType) {
    var imageObj = {};
    var records = SimLokiDatabaseActions.getFilteredRecordHandler('images', {'deviceFamily': devType});
    for(var i=0; records.length>0 && i<records[0].runningImageList.length; i++) {
        if(records[0].runningImageList[i].isTaggedGolden=="true") {
            imageObj.imageUuid = records[0].runningImageList[i].imageUuid;
            imageObj.name = records[0].runningImageList[i].name;
            imageObj.version = records[0].runningImageList[i].version;
            break;
        }
    }
    return imageObj;
  }

  function isOsUpgradeDeviceFailure(deviceId) {
    var isDeviceMarkedFailure = false;
    var issueRecord = SimLokiDatabaseActions.getFilteredRecordHandler('issue', {'$and':
        [{'name': {'$in':['snmp_memory_high_80','switch_memory_high']}}, {'deviceId':deviceId}]});
    if(issueRecord && issueRecord.length>0) {
        isDeviceMarkedFailure = true;
    }
    /* let failDev = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'name': 'p1.edge1-sda1.local'});
    if(failDev && failDev.length>0 && failDev.id==deviceId) isDeviceMarkedFailure = true; */
    return isDeviceMarkedFailure;
  }

  return {
      init: function(){
            createDefaultImages();
            createVirtualImages();
            createImageTaskList();
         },

      //Fetch the RunningImage details, by passing teh groupUuid and name of the image
      fetchRunningImageForName: function(id,name) {
          //api/v1/image/importation/name=
          id = "-1";
          var record = SimLokiDatabaseActions.getFilteredRecordHandler('images', {'groupUuid': id});
          if(record && record.length > 0) {
              for(var j=0; j<record.length; j++) {
                  for(var i=0; i<record[j].runningImageList.length; i++) {
                      var currImage = record[j].runningImageList[i];
                      if(currImage.name == name){
                         return [currImage];
                      }
                  }
              }
          }
          return [];
      },

        //Fetch the RunningImage details, by passing the deviceTypeOrdinal or deviceFamily
        fetchImageForDeviceTypeOrdinal: function(deviceTypeOdinalOrImageFamily) {
            var records = SimLokiDatabaseActions.getFilteredRecordHandler('images',
                    {'deviceTypeOrdinal':typeof(deviceTypeOdinalOrImageFamily)=="string"?Number(deviceTypeOdinalOrImageFamily):deviceTypeOdinalOrImageFamily});
            if(!(records && records.length>0)) {
                switch (deviceTypeOdinalOrImageFamily) {
                    //this is added for handling mark image golden from nfv network profile in design
                    case "vwaas": 
                        deviceTypeOdinalOrImageFamily = "waas"; break;
                    case "isrv": 
                        deviceTypeOdinalOrImageFamily = "isr"; break;
                    case "ngfwv": 
                        deviceTypeOdinalOrImageFamily = "ngfw"; break;
                    case "asav": 
                        deviceTypeOdinalOrImageFamily = "asa"; break;               
                    default:
                        break;
                }
                records = SimLokiDatabaseActions.filterByRegx('images', 'deviceFamily', deviceTypeOdinalOrImageFamily);
            }
            return records;
        },

      // This Function will unmark the image Golden, in the Design tab
      //@urlAction : Param with details of request
      unmarkGoldenImages:function(urlAction) {
          var taskObj = SimTaskDataGenerator.createTask('IMGService');
          //api/v1/image/importation/golden  OR   /api/v1/image/importation/source/device Both these api calls hits here. But one api call restpayload is an arry and other is not. So this check needed
          var restPayload = urlAction.restPayload.constructor === Array?urlAction.restPayload[0]:urlAction.restPayload;
          var null_const = "null";
          var imageFound = false;
          if(restPayload != undefined) {
              var deviceTypeOrdinal = restPayload.deviceTypeOrdinal == undefined ? restPayload.deviceTypeOrdinalOrImageFamily:restPayload.deviceTypeOrdinal ;
              //var imageRecord = SimLokiDatabaseActions.getAll('images');
              var imageUuid = restPayload.imageUuid;
              var deviceRole = restPayload.deviceRole;

              //Iterating the images to pick the correct imageDetails
              var existingRecords = undefined;
              if(deviceTypeOrdinal != undefined) {
                  existingRecords = this.fetchImageForDeviceTypeOrdinal(deviceTypeOrdinal);
              }
              if(existingRecords != undefined) {
                  //console.log("Checking for match of device Family for "+existingRecord.deviceFamily);
                  if(imageUuid != undefined) {
                      imageFound = fetchImageForImageUuid(imageUuid,existingRecords[0],deviceRole,false);
                  }
                  else if(deviceTypeUuid != undefined) {
                      imageFound = fetchImageForDeviceUuid(deviceUuid,existingRecords[0],deviceRole,false);
                  }
                  else {
                      failureError = "Import of Image failed, invalid input data received. null" ;
                      SimulationUtility.updateTaskError(failureError,taskObj,"Invalid Data");
                      return taskObj;
                  }
              }
              else{
                  //There is no matching deviceUuid in the Database. Update the template file
              }
          }
          if( imageFound == false){
              failureError = "Import of Image failed, invalid  data received. null" ;
              SimulationUtility.updateTaskError(failureError,taskObj,"Invalid Data");
              return taskObj;
          }
          if(existingRecords){
              SimLokiDatabaseActions.update('images',existingRecords);
          }
          SimTaskDataGenerator.updateTaskCompletion(taskObj.taskId, {isError: false });
          return taskObj;
      },

      // This Function will mark the image Golden, in the Design tab
      markGoldenImages:function(urlAction) {
          var taskObj = SimTaskDataGenerator.createTask('IMGService');
          /*api/v1/image/importation/golden
          OR /api/v1/image/importation/source/device Both these api calls hits here.
          But one api call restpayload is an arry and other is not. So this check needed*/

          var restPayload = urlAction.restPayload.constructor === Array?urlAction.restPayload[0]:urlAction.restPayload;
          var imageFound = false;
          if(restPayload != undefined) {
              var deviceTypeOrdinal = restPayload.deviceTypeOrdinal == undefined ? restPayload.deviceTypeOrdinalOrImageFamily:restPayload.deviceTypeOrdinal;
              var imageUuid = restPayload.imageUuid;
              var deviceTypeUuid = restPayload.deviceUuid;
              var deviceRole = restPayload.deviceRole;
              var imageName = restPayload.imageName;
              if(!imageUuid && restPayload.imageUuidList && restPayload.imageUuidList.length) {
                imageUuid = restPayload.imageUuidList[0];
              }

              //Iterating the images to pick the correct imageDetails
              var existingRecords = undefined;
              if(deviceTypeOrdinal != undefined) {
                  existingRecords = this.fetchImageForDeviceTypeOrdinal(deviceTypeOrdinal);
              }
              if(existingRecords != undefined) {
                  //console.log("Checking for match of device Family for "+existingRecord.deviceFamily);
                  if(imageUuid != undefined){
                      imageFound = fetchImageForImageUuid(imageUuid,existingRecords[0],deviceRole,true);
                  } else if (imageName) {
                      //in some cases, imageName is sent in restPayload instead of imageUuid
                      imageFound = updateImageUsingImageName(imageName,existingRecords[0],deviceRole,true);
                  }
                  //UI sends undefined string , if the deviceid is empty..
                  else if(deviceTypeUuid != "undefined" && deviceTypeUuid != undefined) {
                      imageFound = fetchImageForDeviceUuid(deviceTypeUuid,existingRecords[0],deviceRole,true);
                  } else {
                      failureError = "Import of Image failed, invalid input data received. null" ;
                      SimulationUtility.updateTaskError(failureError,taskObj,"Invalid Data");
                      return taskObj;
                  }
              }
              else {
                  //There is no matching deviceUuid in the Database. Update the template file.
              }
          }
          if( imageFound == false) {
              failureError = "Import of Image failed, invalid  data received. null" ;
              SimulationUtility.updateTaskError(failureError,taskObj,"Invalid Data");
              return taskObj;
          } else {
              SimLokiDatabaseActions.update('images',existingRecords);
          }
          SimTaskDataGenerator.updateTaskCompletion(taskObj.taskId, {isError: false });
          return taskObj;
      },

        deleteImage: function(imageId, startTime) {
            var imageName ="";
            var taskObj = SimTaskDataGenerator.createTask('IMGService');
            var imageList = SimLokiDatabaseActions.getFilteredRecordHandler('images', {'deviceAvailable':true});//physical device images only
            for(var i=0; i<imageList.length; i++) {
                var newRunningImageList = [];
                for(var j=0; j<imageList[i].runningImageList.length; j++) {
                    var image = imageList[i].runningImageList[j];
                    if(image.imageUuid != imageId) {
                        newRunningImageList.push(image);
                    } else {
                        imageName = image.name;
                    }
                }
                imageList[i].runningImageList = newRunningImageList;
            }
            SimLokiDatabaseActions.update('images', imageList);
            SimTaskDataGenerator.updateTaskCompletion(taskObj.taskId, {isError: false });
            this.updateImageTaskList("delete-image-repo", taskObj, imageName, startTime);
            return taskObj;
        },

        //Update the task list when import or delete action of image occurs
        updateImageTaskList: function(action, taskObj, imageName, startTime) {
            var tJson = JSON.parse(JSON.stringify(TaskTemplate.TASK_SWIM_TASKLIST_INDV));
            if(action=="import"){
                taskObj = SimTaskDataGenerator.createTask('IMGService');
                SimTaskDataGenerator.updateTaskCompletion(taskObj.taskId, {isError: false });
                tJson.completionTime = (startTime + importTime).toString()
                tJson.taskType = "import"
                //this field (unitTaskUuid) is present only for import and not for delete
                tJson.unitTaskUuid = taskObj.taskId;
            } else {
                //delete image
                tJson.completionTime = (startTime + deletionTime).toString();
            }
            tJson.taskUuid = taskObj.taskId;
            tJson.taskType = action;
            tJson.startTime = startTime.toString();
            tJson.imageName = imageName;

            SimLokiDatabaseActions.insert('image-task-list', tJson);
        },

        //Upgrade OS - image activation - Distribute and activate Now
        activateImage: function(urlAction) {
            var taskObj = SimTaskDataGenerator.createTask('IMGActivateService');
            if(typeof(urlAction.restPayload)=="string") {
                urlAction.restPayload = JSON.parse(urlAction.restPayload);
            }
            var childTaskObj = SimTaskDataGenerator.createChildTask('IMGActivateService');
            for(var i=0; i<urlAction.restPayload.length; i++) {
                //var childTaskObj = SimTaskDataGenerator.createChildTask('IMGActivateService');
                /* var imageId = urlAction.restPayload[i].imageUuid;
                // Handle temp for demo
                if(imageId!=undefined && imageId=="62894a79-6b4b-4a14-b85b-7c4d4f301079") {
                    var id =UtilityFunctions.generate_uuid();
                    var resultObj = SimTaskDataGenerator.createTask("Swim Service",{ id: id });
                    SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                    resultObj.statusCode= 202;
                    return resultObj;
                } *///didnt seem valid nw. hence commenting.
                var deviceId = urlAction.restPayload[i].deviceUuid;
                var record = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': deviceId});
                taskObj.childTaskId = []
                if(record && record.length > 0) {
                    //manipulating the time so that distribute and activate can be seen upgrade os list
                    //to complete immediate: (show only time diff)
                    //var manipulatedStartTime = UtilityFunctions.getTimeStamp() - UtilityFunctions.getMinuteToMilliSeconds(18);
                    //to complete after some time (as requested by Satpal)
                    var manipulatedStartTime = UtilityFunctions.getTimeStamp();
                    if(urlAction.isInit) manipulatedStartTime = UtilityFunctions.getTimeStamp() - UtilityFunctions.getMinuteToMilliSeconds(20);
                    var generatedUUid = {"deviceTaskUuid": UtilityFunctions.generate_uuid(), "unitTaskUuid": UtilityFunctions.generate_uuid()};
                    //to complete immediate: (show only time diff)
                    //var activateStartTime = manipulatedStartTime + UtilityFunctions.getMinuteToMilliSeconds(10);
                    //to complete after some time (as requested by Satpal)
                    var activateStartTime = manipulatedStartTime + UtilityFunctions.getMinuteToMilliSeconds(DISTRIBUTION_TIME);
                    var distChecks = urlAction.restPayload[i].distributeValidationBindingUuidList;
                    var actChecks = urlAction.restPayload[i].activateValidationBindingUuidList;
                    if(distChecks && distChecks.length>0 && actChecks && actChecks.length>0) {
                        upgradeList("distribute", record[0], childTaskObj.taskId, manipulatedStartTime, false, generatedUUid, urlAction.isInit);
                        upgradeList("activate", record[0], childTaskObj.taskId, activateStartTime, false, generatedUUid, urlAction.isInit);
                    } else {
                        if(distChecks && distChecks.length>0)
                            //upgradeList("distribute", record[0], childTaskObj.taskId, manipulatedStartTime, false, generatedUUid, urlAction.isInit);
                            this.scheduledDistribution(urlAction, deviceId, childTaskObj);
                        if(actChecks && actChecks.length>0)
                            //upgradeList("activate", record[0], childTaskObj.taskId, activateStartTime, false, generatedUUid, urlAction.isInit);
                            this.scheduledActivation(urlAction, deviceId, childTaskObj);
                    }
                    //var imageVersion = result.imageName;

                    //update images's device list and device detail in network.
                    //to complete immediate: (show only time diff)
                    /* record[0].softwareVersion = imageVersion;
                    SimLokiDatabaseActions.update('network-device', record); */
                    //to complete after some time (as requested by Satpal) - handled in "activate" of upgradeList
                }
                SimTaskDataGenerator.updateTaskCompletion(childTaskObj.taskId, {isError: false });
                taskObj.childTaskId.push(childTaskObj.taskId);
            }
            SimTaskDataGenerator.updateTaskCompletion(taskObj.taskId, {isError: false });
            //taskObj.childTaskId = childTaskObj.taskId;
            taskObj.statusCode = 202;
            return taskObj;
        },

        //Get the activate update status
        getActivateUpdate: function(urlAction) {
            var records = [], isDevLatestUpgradeApplicable=false;
            //api/v1/image/task?operation=&taskType=activate&limit=50&sortOrder=des
            //api/v1/image/task?taskType=distribute,activate&limit=50&sortOrder=des
            records = SimLokiDatabaseActions.getAll('image-activation');
            if(urlAction.filter['deviceId'])  {
                //handles both the url:
                //api/v1/image/task?operation=&taskType=activate&deviceId=0741042b-b84d-4dbc-a0ea-76e151d25671&sortBy=startTime&sortOrder=des&limit=1
                //api/v1/image/task?deviceTaskUuid=eabfbf7b-44b4-4150-8f5c-f57b286a156d&taskType=activate&operation=&deviceId=0741042b-b84d-4dbc-a0ea-76e151d25671&sortBy=startTime&sortOrder=des&limit=1
                var deviceId = urlAction.filter['deviceId'];
                if(deviceId.includes("#null")) deviceId = (deviceId.split("#"))[0];
                records = records.filter(e => e.deviceId == deviceId);
                if(records.length>1) {
                    //get the latest record which is in progress or completed
                    isDevLatestUpgradeApplicable = true;
                }
            }
            if(urlAction.filter.deviceTaskUuid) {
                records = records.filter(e => e.deviceTaskUuid==urlAction.filter.deviceTaskUuid);
            }
            if(urlAction.filter.taskUuid) {
                records = records.filter(e => e.taskUuid==urlAction.filter.taskUuid);
            }

            //to complete after some time (as requested by Satpal) - start
            var tRecords = JSON.parse(JSON.stringify(records))
            var recordIdxSplice = [];
            for(var i=0; i<tRecords.length; i++) {
                if(Number(tRecords[i].startTime)>UtilityFunctions.getTimeStamp()) {
                    //the activity has not yet started - dont show in UI
                    //tRecords.splice(i,1);
                    recordIdxSplice.push(i);
                    continue;
                }
                if(Number(tRecords[i].completionTime)>UtilityFunctions.getTimeStamp()) {
                    //completion time is future time - show in progress
                    tRecords[i].taskStatus = "In Progress";
                    tRecords[i].completionTime = UtilityFunctions.getTimeStamp().toString();//fix for NaN
                    tRecords[i].isError = false;
                    if(tRecords[i].taskStatus == "failure") {
                        delete tRecords[i].failureReason;
                        records[i].inProgressCount = 0;
                        records[i].failureCount = 1;
                    }
                } else if(!records[i].isSimulationOsUpgraded && records[i].taskStatus.toLowerCase() == "success" && 
                    (Number(tRecords[i].completionTime)-Number(tRecords[i].startTime)>=(DISTRIBUTION_TIME+ACTIVATION_TIME_IN_MIN))) {
                    //update the version in device - only first time
                    //completion time is checked to understand if the task is scheduled or immediate as scheduled creates 2 tasks
                    var devRecord = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id':tRecords[i].deviceId});
                    if(devRecord && devRecord.length>0) {
                        //updateImageVersionAfterUpgrade(devRecord[0]);
                        records[i].isSimulationOsUpgraded = true;
                        records[i].inProgressCount = 0;
                        records[i].successCount = 1;
                        tRecords[i].inProgressCount = 0;
                        tRecords[i].successCount = 1;
                    }
                } else {
                    //scheduled distribute
                    if(records[i].taskStatus != "failure") {
                        records[i].isSimulationOsUpgraded = true;
                        records[i].inProgressCount = 0;
                        records[i].successCount = 1;
                        tRecords[i].inProgressCount = 0;
                        tRecords[i].successCount = 1;
                    } else {
                        //taskstatus with failure. this is a temporary fix and needs to be corrected in next release!!
                        var resultObj = SimTaskDataGenerator.createTaskWithTaskId(records[i].deviceTaskUuid);
                        SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false});
                    }
                }
            }
            SimLokiDatabaseActions.update('image-activation', records);
            for(var i=recordIdxSplice.length-1; i>=0; i--) {
                tRecords.splice(recordIdxSplice[i],1);
            }
            //to complete after some time (as requested by Satpal) - end
            if(urlAction.filter['taskStatus']) {
                //to handle in progres key word, lowercase is used
                tRecords = tRecords.filter(function(v) {return v.taskStatus.toLowerCase() == urlAction.filter['taskStatus'].toLowerCase()});
            }
            if(isDevLatestUpgradeApplicable) {
                //for scheduled update, there would be 2 records, take the completed/in progress and activate whenever applicable for that device
                //this is used in See Details of Activate successful for that record
                tRecords.sort((a, b) => {
                    return parseInt(b.startTime) - parseInt(a.startTime);//desc order
                });
                tRecords = [tRecords[0]];
            }
            return tRecords;
        },

        getOutdatedInformation: function(urlAction) {
            var deviceId =  urlAction.filter.deviceUuId==undefined ? urlAction.filter.deviceUuIds : urlAction.filter.deviceUuId;
            var tJson;
            var deviceDetail = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': deviceId});
            if(deviceDetail[0].family == "Wireless Controller"){
                if(deviceDetail[0].platformId=="C9800-CL-K9" && urlAction.filter["validatorName"] && 
                    urlAction.filter["validatorName"]=="ISSU Compatibility Check") {
                    tJson = JSON.parse(JSON.stringify(VerifyConfig.ISSU_VALIDATOR_TEMPLATE));
                    tJson[0].createdTime = UtilityFunctions.getTimeStamp();
                    tJson[0].updatedTime = tJson[0].createdTime;
                    tJson[0].deviceUuid = deviceDetail[0].id;
                    tJson[0].deviceIpAddress = deviceDetail[0].managementIpAddress;
                    tJson[0].hostName = deviceDetail[0].hostname;
                } else {
                    tJson = JSON.parse(JSON.stringify(VerifyConfig.VERIFY_OUTDATED_IMAGE_WLC));
                    if(deviceDetail[0].platformId=="C9800-CL-K9")
                        tJson.push(JSON.parse(JSON.stringify(VerifyConfig.ISSU_VALIDATOR_TEMPLATE[0])));
                }
            }else{
                tJson = JSON.parse(JSON.stringify(VerifyConfig.VERIFY_OUTDATED_IMAGE));
            }
            for(var i=0; i< tJson.length; i++) {
                tJson[i].updatedTime = UtilityFunctions.getTimeInVerifyFormat();
            }
            return tJson
        },

        getCountDetails: function(urlAction) {
            var count = 0;
            var records = SimLokiDatabaseActions.getAll("images");
            if(urlAction.filter['imageIntegrityStatus'] && (urlAction.filter['imageIntegrityStatus']=="failed" ||
              urlAction.filter['imageIntegrityStatus']=="UNKNOWN")) {
                for(var i=0; i<records.length && records[i].runningImageList && records[i].runningImageList.length; i++) {
                    for(var j=0; j<records[i].runningImageList.length; j++) {
                        if(records[i].runningImageList[j].imageIntegrityStatus == null ||
                          records[i].runningImageList[j].imageIntegrityStatus != "VERIFIED") {
                            count++; //imageIntegrityStatus values are null, "", UNKNOWN, VERIFIED - seen so far.
                        }
                    }
                }
            } else if(urlAction.filter['isTaggedGolden']!=undefined && urlAction.filter['isTaggedGolden']=="false") {
                for(var i=0; i<records.length && records[i].runningImageList && records[i].runningImageList.length; i++) {
                    for(var j=0; j<records[i].runningImageList.length; j++) {
                        if(records[i].runningImageList[j].isTaggedGolden == "false" ||
                          records[i].runningImageList[j].isTaggedGolden == false) {
                            count++;
                        }
                    }
                }
            } else if(urlAction.service.indexOf('advisory')>-1) {
                var versions = urlAction.filter['version'].split(','), resp = {};
                versions.forEach(version => {
                    resp[version] = {"NA":0,"HIGH":0,"MEDIUM":0,"LOW":0,"INFORMATIONAL":0,"CRITICAL":0};
                    if(version=="16.12.3") resp[version]["HIGH"]=8, resp[version]["MEDIUM"]=4;
                });
                return resp;
            } else {
                for(var i=0; i<records.length && records[i].runningImageList && records[i].runningImageList.length; i++) {
                    for(var j=0; j<records[i].runningImageList.length; j++) {
                        count++;
                    }
                }
            }
            return count;
        },

        scheduledDistribution: function(urlAction, deviceId, taskObj) {
            //var taskObj = SimTaskDataGenerator.createTask('Scheduler Service',{action:"distribute"});
            /* if(typeof(urlAction.restPayload)=="string") {
                urlAction.restPayload = JSON.parse(urlAction.restPayload);
            } */
            //var deviceId = urlAction.restPayload[0].externalSchedule.notificationBody[0].deviceUuid;
            var record = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': deviceId});
            if(record && record.length > 0) {
                //upgradeList("distribute", record[0], taskObj.taskId, urlAction.restPayload[0].startTime, true);
                //var manipulatedStartTime = urlAction.restPayload[0].startTime - UtilityFunctions.getMinuteToMilliSeconds(18);
                //to complete immediate: (show only time diff)
                //var manipulatedStartTime = UtilityFunctions.getTimeStamp() - UtilityFunctions.getMinuteToMilliSeconds(18);
                //to complete after some time (as requested by Satpal)
                var manipulatedStartTime = UtilityFunctions.getTimeStamp();
                //upgradeList("distribute", record[0], taskObj.taskId, manipulatedStartTime, true);

                //trial for Later-Later option to show both Distribute and Activate
                var generatedUUid = {"deviceTaskUuid": UtilityFunctions.generate_uuid(), "unitTaskUuid": UtilityFunctions.generate_uuid()};
                var checkExisting = SimLokiDatabaseActions.getFilteredRecordHandler("temporary-data", {"key":"Swim"});
                if(checkExisting.length>0) {
                    checkExisting[0].value[deviceId] = generatedUUid;
                    SimLokiDatabaseActions.update("temporary-data", checkExisting);
                } else {
                    SimLokiDatabaseActions.insert("temporary-data", {"key":"Swim", "value":{deviceId:generatedUUid}});
                }
                upgradeList("distribute", record[0], taskObj.taskId, manipulatedStartTime, true, generatedUUid);
            }
            /* SimTaskDataGenerator.updateTaskCompletion(taskObj.taskId, {isError: false });
            return taskObj; */
        },

        scheduledActivation: function(urlAction, deviceId, taskObj) {
            //var taskObj = SimTaskDataGenerator.createTask('Scheduler Service',{action:"activate"});
            /* if(typeof(urlAction.restPayload)=="string") {
                urlAction.restPayload = JSON.parse(urlAction.restPayload);
            } */
            //var deviceId = urlAction.restPayload[0].externalSchedule.notificationBody[0].deviceUuid;
            var record = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': deviceId});
            if(record && record.length > 0) {
                //var imageVersion = upgradeList("activate", record[0], taskObj.taskId, urlAction.restPayload[0].startTime, true);
                //var manipulatedStartTime = urlAction.restPayload[0].startTime - UtilityFunctions.getMinuteToMilliSeconds(10);
                //to complete immediate: (show only time diff)
                //var manipulatedStartTime = UtilityFunctions.getTimeStamp() - UtilityFunctions.getMinuteToMilliSeconds(10);
                //to complete after some time (as requested by Satpal)
                //var manipulatedStartTime = UtilityFunctions.getTimeStamp() + UtilityFunctions.getMinuteToMilliSeconds(DISTRIBUTION_TIME);
                var manipulatedStartTime = UtilityFunctions.getTimeStamp();
                //var result = upgradeList("activate", record[0], taskObj.taskId, manipulatedStartTime, true);

                //trial for Later-Later option to show both Distribute and Activate 
                var allSwimRecords = SimLokiDatabaseActions.getFilteredRecordHandler("temporary-data", {"key":"Swim"});
                //{"Swim":{deviceId:generatedUUid}});
                if(allSwimRecords && allSwimRecords.length>0 && allSwimRecords[0].value) {
                    allSwimRecords = allSwimRecords[0].value[deviceId];
                }
                var result = upgradeList("activate", record[0], taskObj.taskId, manipulatedStartTime, true, allSwimRecords);

                //var imageVersion = result.imageName;
                //update images's device list and device detail in network.
                //to complete immediate: (show only time diff)
                /* record[0].softwareVersion = imageVersion;
                SimLokiDatabaseActions.update('network-device', record); */
                //to complete after some time (as requested by Satpal) - handled in "activate" of upgradeList
            }
            /* SimTaskDataGenerator.updateTaskCompletion(taskObj.taskId, {isError: false });
            return taskObj; */
        },
        
        manualAssignmentDeviceToimage: function(deviceRecord) {
            if(isImageNotPresentForDevice(deviceRecord.type)) { return; }
            associateDeviceToImageInDB(deviceRecord.type, deviceRecord.id, deviceRecord.softwareVersion, deviceRecord.platformId);
            var taskResponse = this.activateImage({"restPayload":[{"deviceUuid":deviceRecord.id}], "isInit":true});
            //to display in Image Upgrade Status in Provision
            var restPayload = JSON.parse(JSON.stringify(UpgradeImageTemplate.REQUEST_FORMAT));
            restPayload[0].description = restPayload[0].description.replace('$DevName', '');
            restPayload[0].externalSchedule.notificationBody[0].deviceUuid = deviceRecord.id;
            SimScheduledJobData.insertScheduledJobTask(restPayload, taskResponse.taskId, taskResponse.childTaskId);

            console.log("Mapping of device to image done successfully for : "+deviceRecord.name);
        },

        getImageDtlsFromUuid: function(imageId) {
            var records = SimLokiDatabaseActions.getAll('images');
            for(var i=0; i<records.length; i++) {
                var imagesUnderSeries = records[i].runningImageList;
                for(var j=0; j<imagesUnderSeries.length; j++) {
                    if(imagesUnderSeries[j].imageUuid == imageId) {
                        imagesUnderSeries[j].fileSize = (imagesUnderSeries[j].filesize).toString();//as UI checking this field
                        imagesUnderSeries[j].imageName = imagesUnderSeries[j].name;
                        return imagesUnderSeries[j];
                    }
                }
            }
        },

        getGoldenImagesForMdf: function(mdfId) {
            var mdfDevTypeMapping = DefaultConfig.MDF_DEVICE_TYPE_MAPPING, devType, result=[];
            for(var i=0; i<mdfDevTypeMapping.length; i++) {
                if(mdfId == mdfDevTypeMapping[i].mdfId) {
                    devType = mdfDevTypeMapping[i].type;
                }
            }
            if(!devType) {return result;}
            var tJson = {"instanceType":"swim","instanceUuid":"829b9008-a704-4f9c-8610-2369b562943c","namespace":"swim",
                "type":"swim.tag","key":"swim.tag.286315874.access","version":3,
                "value":[{"patchUuids":[],"imageUuid":"bc5ec97b-dd4a-4c32-ba1b-fa32f5b6327c","kickstartUuid":""}],
                "groupUuid":"46c29cd2-ae1d-4af5-9883-c6a567f8bd34","inheritedGroupUuid":"-1","inheritedGroupName":"Global"};
            var records = SimLokiDatabaseActions.getFilteredRecordHandler('images', {'deviceFamily': devType});
            for(var i=0; records.length>0 && i<records[0].runningImageList.length; i++) {
                if(records[0].runningImageList[i].isTaggedGolden=="true") {
                    tJson.value[0].imageUuid = records[0].runningImageList[i].imageUuid;
                    result.push(tJson);
                    return result;
                }
            }
            return result;
        },

        getSwimImageList: function(urlAction) {
            if(urlAction.filter['deviceTypeOdinalOrImageFamily']) {
                return this.fetchImageForDeviceTypeOrdinal(urlAction.filter['deviceTypeOdinalOrImageFamily']);
            } else if (urlAction.filter['imageCategory'] && urlAction.filter['imageCategory'] == 'VIRTUAL' ) {
                return SimLokiDatabaseActions.getFilteredRecordHandler('images', {'deviceAvailable': false});
            } else {
                return SimLokiDatabaseActions.getFilteredRecordHandler('images', {'$and': [{'groupUuid': '-1'},{'deviceAvailable': true}]});
            }
        },

        updateImageVersionAfterUpgrade: function(deviceData) {
            //return function(){
              var imageDtl = getGoldenImageDetail(deviceData.id, deviceData.type, deviceData.role, deviceData.platformId);
              var record = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id':deviceData.id});
              record[0].softwareVersion = imageDtl.version;
              SimLokiDatabaseActions.update('network-device', record);
              console.log("Device "+record[0].hostname+"'s image is updated");
            // The below code to update the SWIM tasks status 
            var imageActivationData = SimLokiDatabaseActions.getAll("image-activation");
            imageActivationData.map(record => {
                if (record.taskStatus == 'in-progress') {
                    record.taskStatus = 'success'
                }
                SimLokiDatabaseActions.update("image-activation", JSON.parse(JSON.stringify(record)));
            });
            imageActivationData = SimLokiDatabaseActions.getAll("image-activation");
            var successTasks = imageActivationData.filter(record => {
                return record.taskStatus == 'success';
            });
            var inProgressTasks = imageActivationData.filter(record => {
                return record.taskStatus == 'in-progress';
            });
            var failureTasks = imageActivationData.filter(record => {
                return record.taskStatus == 'failure';
            });
            
            imageActivationData.map(record => {
                record.successCount = successTasks.length;
                record.inProgressCount = inProgressTasks.length;
                record.failureCount = failureTasks.length;
                SimLokiDatabaseActions.update("image-activation", JSON.parse(JSON.stringify(record)));
            });
            // Swim tasks update code ends here
              return imageDtl;
            //}
        }

   };

  });

