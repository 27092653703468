define([
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/assurance/issue/ClientOnboardingResponses',
], function (SimLokiDatabaseActions, ClientOnboardingResponses) {

    function packOnboardingTime(tIssueId, onboardingResponse) {
        var obj =  SimLokiDatabaseActions.getFilteredRecordHandler('issue',{'id':tIssueId} );
        if(obj.length == 0) {
            obj =  SimLokiDatabaseActions.getFilteredRecordHandler('customIssue',{'id':tIssueId} );
        }
        var issueTime = obj.length ? obj[0].timestamp : new Date().getTime(), i, j;
        issueTime = new Date(issueTime).getTime();

        onboardingResponse.response.transactions[0].startTime = issueTime;       
        //var tDuration = onboardingResponse.response.transactions[0].duration;
        var tDuration; 
        var tIssueTime = issueTime;

        for(i = 0; i < onboardingResponse.response.transactions.length; i++) {
            tDuration = onboardingResponse.response.transactions[i].duration;
            tIssueTime = new Date(tIssueTime).getTime();

            for(j = 0; j < onboardingResponse.response.transactions[i].events.length; j++) {
                onboardingResponse.response.transactions[i].events[j].timestamp = tIssueTime;

                tDuration = onboardingResponse.response.transactions[i].events[j].duration;
                //tIssueTime = new Date(tIssueTime + tDuration).getTime();
                var t1 = new Date(tIssueTime);
                tIssueTime = t1.setTime(t1.getTime() + tDuration);
            }
        }

        return onboardingResponse;
    }

    return {
        init: function() {
        },

        getClientOnboatrdingResponse: function(urlAction, tIssueId) {
             var issueObj =  SimLokiDatabaseActions.getFilteredRecordHandler('issue',{'id':tIssueId});
             if(issueObj.length == 0) {
                issueObj =  SimLokiDatabaseActions.getFilteredRecordHandler('customIssue',{'id':tIssueId} );
             }
             var response = "";
             if(issueObj.length) {              
                 var issueKey = issueObj[0].rootCause.toLowerCase();
                 response = ClientOnboardingResponses.getClientOnboardingResponse(issueKey);
                 //response = response != undefined ? response.Template:"";
                 if(response != undefined) {
                     response = response.Template;
                     return packOnboardingTime(tIssueId, response);
                 } else {
                     response = "";
                 }
             }
             return response;
        }
    };

});
