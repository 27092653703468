define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/service-mapper/RestResponse'
], function (RestResponse) {

  var MAGLEV_RESPONSE = 1;
  var ASSURANCE_RESPONSE = 2;
  var serviceResponseType = {};

  var Services =  [
        { "name" : "aaa", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "app", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "assurance", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "archive-config", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "deploy-config", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "health", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "commonsetting", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "core-services", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "customer-facing-service", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "audit", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "cfs-intent", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "compliance", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "device-config-status", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "device-credential", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "device-image", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "device-replacement", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "discovery", isGetDefaultProcess: false, restResponseType: 2},//changed restResponseType from 1 to 2 in DNAC 1.3.1
        { "name" : "devicepkg", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "dna-maps-service", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "dna", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "dashboard", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "eox-status", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "event", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "events", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "flow-analysis", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "global-credential", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "group", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "host", isGetDefaultProcess: true, restResponseType: 1},
        { "name" : "image", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "interface", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "inventory", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "ippool", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "issue", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "kairos", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "lazy-load", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "license", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "licensemanager", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "mdf", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "mdfdata", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "member", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "ndp", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "na", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "ncp-node", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "network-device", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "onboarding", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "orchestration-engine", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "network-orchestration", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "network-programmer", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "nfv-provisioning-service", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "nfv-service-chaining", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "query-domain", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "resource-manager", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "topology", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "system", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "scheduled-job", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "siteprofile", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "service-deployment-status", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "sd-mdns-preferences", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "service-policy", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "service-entry", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "sdg-node", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "service-type", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "task", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "tag", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "template-programmer", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "dna-wireless-service", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "validation", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "network-device-poller", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "nfv-provisioning-service", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "file", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "workflow-status", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "sda-helper-service", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "server-management", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "cisco.dna.provision.uci", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "cisco.dna.provision.uci.virtual_networks", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "cisco.dna.provision", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "cisco.dna.rmaDeviceView", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "fedsearch", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "aca-controller-service", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "reasoner", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "troubleshooting", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "identitymgmt", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "integrity-verification", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "kairos", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "pki", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "proxy-certificate", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "pxgridsecuritygroup", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "magellan", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "dcs", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "endpoint-analytics", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "disaster-recovery", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "backup", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "restore", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "schedule", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "token-registration", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "certificate-authority", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "cisco-ise", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "cisco.dna.wireless-workflows", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "cisco.dna.core.dashboard", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "cisco.dna.design.swimnew", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "cisco.dna.licenseManagement", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "security-advisory", isGetDefaultProcess: false, restResponseType: 1},
        { "name" : "sftp", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "snmp-property", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "systemsettings", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "ipam", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "cloud-provisioning", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "diagnostics", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "cisco.dna.securityAdvisories", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "iox", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "cisco.dna.appHosting", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "workflow", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "cloud-connectivity-sense", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "vmanage-service", isGetDefaultProcess: false, restResponseType: 2},
        { "name" : "registration", isGetDefaultProcess: false, restResponseType: 2}
 ];

  function findServiceInfo(serviceName){
      for(var i = 0, len = Services.length; i < len; i++){
          if(Services[i].name == serviceName) {
              return Services[i];
          }
      }

      return undefined;
  }

  function filterBasedOnOffset(urlAction, data) {
      //ToDo -- limit and offset based returning of recrods..
      var offset = 1, limit = data.length, response = [];
      if(urlAction.filter.offset) {
          offset = urlAction.filter.offset;
      }
      if(urlAction.filter.limit) {
          limit = urlAction.filter.limit;
          limit = limit == -1 ? 1: limit;
      }
      for (var i = offset-1; i < data.length && i<offset-1+limit; i++) {
          response.push(data[i]);
      }
      return response;
  }

  /* function decorateData(urlAction, data) {
      var sortBy = urlAction.filter['sortBy'];
      var orderBy = urlAction.filter['order'];
      if(sortBy != undefined) {
          return data.sort(function(a, b) {
              /*if(orderBy == undefined || orderBy == 'asc') {
                  return a[sortBy] > b[sortBy];
              } else {
                  return b[sortBy] > a[sortBy];
              }*/
             /* if(a[sortBy] == undefined) {
                  return 1;
              }
              if(b[sortBy] == undefined) {
                  return 1;
              }

              if(orderBy == undefined || orderBy == 'asc') {
                  if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) return -1;
                  if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) return 1;
                  return 0;
              }
              else {
                  if (a[sortBy].toLowerCase() > b[sortBy].toLowerCase()) return -1;
                  if (a[sortBy].toLowerCase() < b[sortBy].toLowerCase()) return 1;
                  return 0;
              }

         });
      }
      return data;
  } */

  function decorateData(urlAction, data) {
    var sortBy = urlAction.filter['sortBy'] == undefined ? urlAction.filter['_sort'] : urlAction.filter['sortBy'];
    var orderBy = urlAction.filter['order'] == undefined ? urlAction.filter['_order'] : urlAction.filter['order'];
    if(sortBy != undefined) {
        return data.sort(function(a, b) {
            var x = a[sortBy], y = b[sortBy];
            if(x == undefined) {
                return 1;
            }
            if(y == undefined) {
                return 1;
            }
            // added boolen condition to handle /api/v1/sda-helper-service/insights?sortBy=isNew&order=ASC
            // in shockwave release 
            if(typeof(x)!="number" && typeof(x) !="boolean" ) {
                x = x.toLowerCase();
            }
            if(typeof(y)!="number" && typeof(y) !="boolean" ) {
                y = y.toLowerCase();
            }
            
            if(orderBy == undefined || orderBy.toLowerCase() == 'asc') {
                if (x < y) return -1;
                if (x > y) return 1;
                return 0;
            }
            else {
                if (x > y) return -1;
                if (x < y) return 1;
                return 0;
            }

       });
    }
    return data;
  }
  function isExcludeMaglevType(urlAction) {
    var isExcludeRespStructure = false;
    if( urlAction.filter["applicationSg"] == "true" || urlAction.filter["applicationPolicyReadiness"] == "true") {
        isExcludeRespStructure = true;
    } else if( urlAction.service.indexOf("customer-facing-service")>-1 && urlAction.action.summary && 
    urlAction.service.indexOf("access")>-1 ) {
        if(urlAction.service.indexOf("policy")>-1 || urlAction.service.indexOf("scalablegroup")>-1 || 
            urlAction.service.indexOf("contract")>-1) {
            isExcludeRespStructure= true;
        }
    } else if( urlAction.service.indexOf("customer-facing-service")>-1 && urlAction.action.summary && 
    urlAction.service.indexOf("virtualnetworkcontext")>-1 ) {
        isExcludeRespStructure= true;
    }else if( urlAction.service.indexOf("customer-facing-service")>-1 && urlAction.action.summary && 
    urlAction.service.indexOf("VirtualNetwork")>-1) {
        isExcludeRespStructure= true;
    } else if( urlAction.service.indexOf("customer-facing-service")>-1 && urlAction.action.summary && 
    urlAction.service.indexOf("ConnectivityDomain")>-1 ) {
        isExcludeRespStructure= true;
    }else if( urlAction.service.indexOf("customer-facing-service")>-1 && urlAction.action.summary && 
    urlAction.service.indexOf("Segment")>-1) {
        isExcludeRespStructure= true;
    }else if( urlAction.service.indexOf("customer-facing-service")>-1 && urlAction.action.summary && 
    urlAction.service.indexOf("L2Segment")>-1) {
        isExcludeRespStructure= true;
    }else if( urlAction.service.indexOf('aca-controller-service' >-1) && urlAction.service.indexOf('migration' >-1) && urlAction.service.indexOf('log' >-1) && urlAction.filter["entityId"] >-1){
        isExcludeRespStructure= true;
    } else if(  urlAction.service.indexOf('deploy') >-1) {
            isExcludeRespStructure= true;
    // } else if( urlAction.service.indexOf("reasoner")>-1 ) {
    //     isExcludeRespStructure= true;
    } else if(  urlAction.service.indexOf('pushSGs') >-1) {
        isExcludeRespStructure= true;
    } else if( urlAction.service.indexOf('network-device')>-1 && urlAction.service.indexOf('deviceview')>-1){
        isExcludeRespStructure= true;
    } else if( urlAction.service.indexOf('network-device')>-1 && urlAction.action.count && urlAction.service.indexOf('family')>-1) {
        isExcludeRespStructure= true;
    } else if( urlAction.service.indexOf('dna-wireless-service')>-1 && urlAction.service.indexOf('getMobilityGroupNames')>-1){
        isExcludeRespStructure= true;
    } else if( urlAction.service.indexOf('ncp-node')>-1 && urlAction.service.indexOf('DeviceStore')>-1){
        isExcludeRespStructure= true;
    } else if( urlAction.service.indexOf('sdg-node')>-1 && urlAction.service.indexOf('status')>-1){
        isExcludeRespStructure= true;
    } else if( urlAction.service.indexOf('orchestration-engine')>-1 && urlAction.service.indexOf('workflow')>-1 && 
        urlAction.service.indexOf('TASK_MAJOR')>-1){
        isExcludeRespStructure= true;
    } else if( urlAction.service.indexOf('dna-wireless-service')>-1 && urlAction.service.indexOf('getSitesWithUpnSupportedDevices')>-1){
        isExcludeRespStructure= true;
    } else if( urlAction.service.indexOf('dna-wireless-service')>-1 && urlAction.service.indexOf('guest')>-1 && urlAction.service.indexOf('policy')>-1){
        isExcludeRespStructure= true;
    } else if (urlAction.service.indexOf('dna-wireless-service') > -1 && urlAction.service.indexOf('getNATInventoryDetailsByDeviceId') > -1) {
        isExcludeRespStructure = true;
    } else if (urlAction.service.indexOf('dna-wireless-service') > -1 && urlAction.service.indexOf('getRemoteWorkerSites') > -1) {
        isExcludeRespStructure = true;
    } else if (urlAction.service.indexOf('dna-wireless-service') > -1 && urlAction.service.indexOf('getMeshEnabledSites') > -1) {
        isExcludeRespStructure = true;
    } else if (urlAction.service.indexOf('dna-wireless-service') > -1 && urlAction.service.indexOf('getAllWLCDetail') > -1) {
        isExcludeRespStructure = true;
    } else if (urlAction.service.indexOf('dna-wireless-service') > -1 && urlAction.service.indexOf('getAllAnchorManagedSites') > -1) {
        isExcludeRespStructure = true;
    } else if (urlAction.service.indexOf('dna-wireless-service') > -1 && urlAction.service.indexOf('getPreProvisionGroupSettingsSites') > -1) {
        isExcludeRespStructure = true;
    } else if( urlAction.service.indexOf('sda-helper-service')>-1 && urlAction.service.indexOf('mdnac')>-1 && urlAction.service.indexOf('sync-status')>-1){
        isExcludeRespStructure= true;
    }
    return isExcludeRespStructure;
    }

  return {
      init: function(clientCallback) {
          serviceResponseType  = new Object();
            for(var i = 0, len = Services.length; i < len; i++) {
                serviceResponseType[Services[i].name] = Services[i].restResponseType;
            }
      },

      getServiceDBMapping: function(urlAction) {
          var serviceName = '';

          if(urlAction.service.length == 1){
              serviceName = urlAction.service[0];
          } else {
              //FIXME...
             //customer-facing-service/type=xyz
             //network-device/1234/
             //copy/policy/
             //....
             serviceName = urlAction.service[0];

             /* if(serviceName == 'member'){
                 //FIXME do it in cleaner way..
                 if(urlAction.service.length == 2){
                     serviceName = urlAction.service[1];
                 }
             } */
          }

          serviceObject = findServiceInfo(serviceName);
          if(serviceObject != undefined){
              urlAction.isDefaultHandling = serviceObject.isGetDefaultProcess;
              urlAction.jsonTemplate = serviceObject.template;
              urlAction.serviceName = serviceName;
          }

      },

      encodeRestResponse: function(urlAction, data){
          var tData = data, clonedArray, isResponseIncluded = false;
          if(Array.isArray(tData)) {
              clonedArray = JSON.parse(JSON.stringify(tData));
          } else if(tData.response && Array.isArray(tData.response)) {
              clonedArray = JSON.parse(JSON.stringify(tData.response));
              isResponseIncluded = true;
          }
          if(clonedArray != undefined) {
              //Sorts based on attribute.. and order it asc or desc..
              clonedArray =  decorateData(urlAction, clonedArray)
              //get limited data based on offset and limit
              clonedArray = filterBasedOnOffset(urlAction, clonedArray);
              if(isResponseIncluded) {
                  tData.response = clonedArray;
              } else {
                  tData = clonedArray;
              }
          }

          if(serviceResponseType[urlAction.serviceName] != undefined){
            if(urlAction.service.indexOf("exists")>=0) {
             return tData;
            }
            if(serviceResponseType[urlAction.serviceName] == MAGLEV_RESPONSE) {
                var isExcludeRespStructure = isExcludeMaglevType(urlAction);
                if(isExcludeRespStructure) {
                  return tData;
                } else if (tData.isExcludeAppend) {
                    return tData.data; //does similar to isExcludeRespStructure condition, but handled in the call
                } else{
                  return {"response":tData,"version":"1.0"};
                }
            }
        }
          return tData;
      }
  };

});
