define({
  "Template":{
    "id":"",
    "name":"",
    "entityType":"Client",
    "entity":"",
    "groupBy":"",
    "severity":"",
    "category":"",
    "summary":"",
    "rootCause":"",
    "timestamp":0,
    "occurrences":22,
    "scope":"LOCALIZED",
    "status":{"status":"ACTIVE","updatedBy":"Unknown","notes":"Unknown","source":"Unknown","updatedAt":null},
    "totalOccurrences":{"count":22,"startTime":0,"endTime":0},
    "historyOccurrences":{"count":22,"startTime":0,"endTime":0},
    "priority":"P3",
    "device": "",
    "deviceId": "",
    "deviceName": "",
    "instances": "",
    "lastOccurence":0,
    "isAiDriven":false,
    "kairosId":""
    //"deviceRole": ""
  }
})
