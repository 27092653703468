define({
  "Template": {
  "version": "1.0",
  "response":[
 {
    "id": "AWjH1-AjsIPgh8_7RT6c",
    "name": "global_wireless_client_auth_fail_sec_param_mismatch_trigger",
    "enabled": true,
    "flattened": true,
    "entityType": "Client",
    "entity": "5C:5F:67:CE:3D:7B",
    "groupBy": "@CorpSSID",
    "category": "Onboarding",
    "severity": "HIGH",
    "summary": "Wireless client failed to connect (SSID: SSID@ToChange, AP: AP@ToChange, Band:Band@ToChange GHz) - Security Parameter Mismatch",
    "scope": "LOCALIZED",
    "priority": "P3",
    "rootCause": "AUTH_SEC_PARAM_MISMATCH",
    "timestamp": 1549540815625,
    "description": "This client failed to complete the authentication process during onboarding because the security parameters are mismatched. This client was connecting to SSID \"SSID@ToChange\" on AP \"AP@ToChange\"Band@ToChange in Site@ToChange. The AP was connected to WLC \"Wlc@ToChange\".",
    "suggestions": [
      {
        "message": "If you are running older client software, consider upgrading the client software. If the client software has gone through a recent update, consider rolling back as this may have been a recent client behavior change.",
        "steps": [

        ]
      },
      {
        "message": "Verify whether the WLAN security profile on the WLC 'CT5520-MK', matches with the security configuration on the client's Wi-Fi setting as there might have been some parameter changes that were done on the client device.",
        "steps": [

        ]
      },
      {
        "message": "Verify whether the authentication methods that are supported on the AAA server match the client's authentication profile.",
        "steps": [

        ]
      }
    ],
    "additionalParams": [
      {
        "key": "__key__",
        "value": "Interface:5C:5F:67:CE:3D:7B:5C:5F:67:CE:3D:7B:AUTH_SEC_PARAM_MISMATCH:@CorpSSID:false"
      },
      {
        "key": "hostName",
        "value": "CAGGQ0MMOJVH1"
      },
      {
        "key": "groupType",
        "value": "SSID"
      },
      {
        "key": "assocTime",
        "value": "0"
      },
      {
        "key": "authTime",
        "value": "0"
      },
      {
        "key": "groupBy",
        "value": "@CorpSSID"
      },
      {
        "key": "slot",
        "value": "0"
      },
      {
        "key": "type",
        "value": "issue"
      },
      {
        "key": "reasonType",
        "value": "cl_INVALID_EAPOL_KEY"
      },
      {
        "key": "previousApName",
        "value": "AP4800.8DEC"
      },
      {
        "key": "ssid",
        "value": "@CorpSSID"
      },
      {
        "key": "apName",
        "value": "AP4800.922C"
      },
      {
        "key": "frequency",
        "value": "2.4"
      },
      {
        "key": "reasonCodes",
        "value": "0,0,89,174,0,0,0"
      },
      {
        "key": "vlan",
        "value": "0"
      },
      {
        "key": "hostType",
        "value": "WIRELESS"
      },
      {
        "key": "rootCause",
        "value": "AUTH_SEC_PARAM_MISMATCH"
      },
      {
        "key": "aaaServerIp",
        "value": ""
      },
      {
        "key": "resultType",
        "value": "cl_FAIL"
      },
      {
        "key": "__suppression__",
        "value": "1800"
      },
      {
        "key": "dhcpTime",
        "value": "0"
      },
      {
        "key": "hostOSType",
        "value": "Microsoft-Workstation"
      },
      {
        "key": "isRoaming",
        "value": "false"
      },
      {
        "key": "entityType",
        "value": "interface_host"
      },
      {
        "key": "totalTime",
        "value": "0"
      },
      {
        "key": "__entity_type__",
        "value": "Interface"
      },
      {
        "key": "previousWlcName",
        "value": ""
      },
      {
        "key": "apGroup",
        "value": "iCap_AP"
      },
      {
        "key": "dhcpServerIp",
        "value": ""
      },
      {
        "key": "apMac",
        "value": "F4:DB:E6:87:39:E0"
      },
      {
        "key": "entityId",
        "value": "5C:5F:67:CE:3D:7B"
      },
      {
        "key": "eventType",
        "value": "cl_EVENT_L2KEY_DONE"
      },
      {
        "key": "priority",
        "value": "P3"
      },
      {
        "key": "eventTypes",
        "value": "12,3,4,5,14,14,11"
      },
      {
        "key": "wlcName",
        "value": "CT5520-MK"
      },
      {
        "key": "osCategory",
        "value": "Windows"
      },
      {
        "key": "deviceCategory",
        "value": "Microsoft-Workstation"
      },
      {
        "key": "eventTimestamps",
        "value": "1549540803969,0,8526,3130,0,20452,10216"
      },
      {
        "key": "resultTypes",
        "value": "0,0,0,5,0,0,0"
      },
      {
        "key": "siteId",
        "value": "2572a60e-c5d0-4e9a-a332-28881db6bdc8"
      },
      {
        "key": "_id",
        "value": "5C:5F:67:CE:3D:7B"
      },
      {
        "key": "category",
        "value": "onboarding"
      },
      {
        "key": "timestring",
        "value": "2019-02-07T12:00:15.625+0000"
      },
      {
        "key": "status",
        "value": "active"
      },
      {
        "key": "macAddr",
        "value": "5C:5F:67:CE:3D:7B"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1"
  }
 ]
}
})