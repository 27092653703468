define({
    "Template":
    {
        "request": {
          "key": "citrix-static",
          "type": "Application",
          "label": "citrix-static",
          "collection": false,
          "componentContent": [
            {
              "component": "fusion",
              "additional": "f156c82d-5c73-4cc5-9f10-c65ebb4669d6"
            }
          ]
        },
        "data": {
          "response": {
            "id": "f156c82d-5c73-4cc5-9f10-c65ebb4669d6",
            "instanceId": 4263,
            "authEntityId": 4263,
            "displayName": "4263",
            "authEntityClass": -1909073334,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "createTime": 1528265511216,
            "deployed": false,
            "isSeeded": true,
            "isStale": false,
            "lastUpdateTime": 1528265511216,
            "name": "citrix-static",
            "namespace": "scalablegroup:application",
            "provisioningState": "DEFINED",
            "qualifier": "application",
            "resourceVersion": 0,
            "targetIdList": [],
            "type": "scalablegroup",
            "cfsChangeInfo": [],
            "customProvisions": [],
            "scalableGroupExternalHandle": "citrix-static",
            "scalableGroupType": "APPLICATION",
            "securityGroupTag": 0,
            "state": "ACTIVE",
            "vnAgnostic": true,
            "identitySource": {
              "id": "607e5f6a-d4bd-41e6-9861-bcd939c316b6",
              "instanceId": 10010,
              "authEntityId": 10010,
              "displayName": "10010",
              "authEntityClass": 1738953278,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "state": "INACTIVE",
              "type": "NBAR"
            },
            "indicativeNetworkIdentity": [
              {
                "id": "29987076-ff28-4f8c-9335-8a9d9b194cd9",
                "instanceId": 12560,
                "authEntityId": 12560,
                "displayName": "12560",
                "authEntityClass": 124090504,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "ipv4Subnet": [],
                "ipv6Subnet": [],
                "lowerPort": 0,
                "ports": "1494,1604,2512,2513,2598",
                "protocol": "TCP",
                "upperPort": 0
              },
              {
                "id": "1e9eea26-1ceb-4b32-8363-0707b5f1a6f6",
                "instanceId": 12561,
                "authEntityId": 12561,
                "displayName": "12561",
                "authEntityClass": 124090504,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "ipv4Subnet": [],
                "ipv6Subnet": [],
                "lowerPort": 0,
                "ports": "1604,2512,2513",
                "protocol": "UDP",
                "upperPort": 0
              }
            ],
            "networkApplications": [
              {
                "id": "60f50b23-7636-4b3b-9df8-3e992809b50c",
                "instanceId": 11216,
                "authEntityId": 11216,
                "displayName": "11216",
                "authEntityClass": -217092956,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "appProtocol": "tcp/udp",
                "applicationGroup": "other",
                "applicationType": "DEFAULT",
                "categoryId": "2748fc1c-a274-42da-a4c2-8c2ec3bfe875",
                "createTime": 1528265511216,
                "encrypted": "false",
                "engineId": "3",
                "helpString": "Citrix Static",
                "ignoreConflict": false,
                "lastUpdateTime": 1528265511216,
                "longDescription": "Citrix is an application that mediates users remotely to their corporate applications. ICA:  Independed Computing Architecture is a designated protocol for application server system; it is used for transferring data between clients and servers.CGP: CGP is a tunneling protocol, the latest addition to the family of Citrix protocol.As of today it encapsulates ICA protocol but will be extended to other Citrix protocol such as RDP, HTTP/HTTPS.IMA: used for server-server communication. Server-Browser: Used mainly a control connection which has Published Application Name and triggers an ICA connection",
                "name": "citrix-static",
                "nbarId": "1433",
                "p2pTechnology": "false",
                "popularity": 7,
                "rank": 65535,
                "references": "http://www.citrix.com/site/resources/dynamic/additional/ICA_Acceleration_0709a.pdf",
                "selectorId": "1604",
                "subCategory": "55e6e70d-6c22-4c2d-8a68-0e4a0e9a5967",
                "trafficClass": "MULTIMEDIA_STREAMING",
                "tunnel": "false"
              }
            ],
            "networkIdentity": [
              {
                "id": "fcbadab4-cb94-4d7b-a920-5a03db761ae0",
                "instanceId": 12558,
                "authEntityId": 12558,
                "displayName": "12558",
                "authEntityClass": 124090504,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "ipv4Subnet": [],
                "ipv6Subnet": [],
                "lowerPort": 0,
                "ports": "1494,1604,2512,2513,2598",
                "protocol": "TCP",
                "upperPort": 0
              },
              {
                "id": "003dc522-6f77-4ed1-88c5-f1656d368e4b",
                "instanceId": 12559,
                "authEntityId": 12559,
                "displayName": "12559",
                "authEntityClass": 124090504,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "ipv4Subnet": [],
                "ipv6Subnet": [],
                "lowerPort": 0,
                "ports": "1604,2512,2513",
                "protocol": "UDP",
                "upperPort": 0
              }
            ],
            "parentScalableGroup": {
              "idRef": "86194015-632b-4646-949c-36963743fc72"
            },
            "user": [],
            "userGroup": []
          }
        },
        "result": [
          {
            "bookID": {
              "namespace": "fusion",
              "name": "core",
              "version": "1.0.1"
            },
            "response": {
              "id": "f156c82d-5c73-4cc5-9f10-c65ebb4669d6",
              "instanceId": 4263,
              "authEntityId": 4263,
              "displayName": "4263",
              "authEntityClass": -1909073334,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "createTime": 1528265511216,
              "deployed": false,
              "isSeeded": true,
              "isStale": false,
              "lastUpdateTime": 1528265511216,
              "name": "citrix-static",
              "namespace": "scalablegroup:application",
              "provisioningState": "DEFINED",
              "qualifier": "application",
              "resourceVersion": 0,
              "targetIdList": [],
              "type": "scalablegroup",
              "cfsChangeInfo": [],
              "customProvisions": [],
              "scalableGroupExternalHandle": "citrix-static",
              "scalableGroupType": "APPLICATION",
              "securityGroupTag": 0,
              "state": "ACTIVE",
              "vnAgnostic": true,
              "identitySource": {
                "id": "607e5f6a-d4bd-41e6-9861-bcd939c316b6",
                "instanceId": 10010,
                "authEntityId": 10010,
                "displayName": "10010",
                "authEntityClass": 1738953278,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "state": "INACTIVE",
                "type": "NBAR"
              },
              "indicativeNetworkIdentity": [
                {
                  "id": "29987076-ff28-4f8c-9335-8a9d9b194cd9",
                  "instanceId": 12560,
                  "authEntityId": 12560,
                  "displayName": "12560",
                  "authEntityClass": 124090504,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "ipv4Subnet": [],
                  "ipv6Subnet": [],
                  "lowerPort": 0,
                  "ports": "1494,1604,2512,2513,2598",
                  "protocol": "TCP",
                  "upperPort": 0
                },
                {
                  "id": "1e9eea26-1ceb-4b32-8363-0707b5f1a6f6",
                  "instanceId": 12561,
                  "authEntityId": 12561,
                  "displayName": "12561",
                  "authEntityClass": 124090504,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "ipv4Subnet": [],
                  "ipv6Subnet": [],
                  "lowerPort": 0,
                  "ports": "1604,2512,2513",
                  "protocol": "UDP",
                  "upperPort": 0
                }
              ],
              "networkApplications": [
                {
                  "id": "60f50b23-7636-4b3b-9df8-3e992809b50c",
                  "instanceId": 11216,
                  "authEntityId": 11216,
                  "displayName": "11216",
                  "authEntityClass": -217092956,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "appProtocol": "tcp/udp",
                  "applicationGroup": "other",
                  "applicationType": "DEFAULT",
                  "categoryId": "2748fc1c-a274-42da-a4c2-8c2ec3bfe875",
                  "createTime": 1528265511216,
                  "encrypted": "false",
                  "engineId": "3",
                  "helpString": "Citrix Static",
                  "ignoreConflict": false,
                  "lastUpdateTime": 1528265511216,
                  "longDescription": "Citrix is an application that mediates users remotely to their corporate applications. ICA:  Independed Computing Architecture is a designated protocol for application server system; it is used for transferring data between clients and servers.CGP: CGP is a tunneling protocol, the latest addition to the family of Citrix protocol.As of today it encapsulates ICA protocol but will be extended to other Citrix protocol such as RDP, HTTP/HTTPS.IMA: used for server-server communication. Server-Browser: Used mainly a control connection which has Published Application Name and triggers an ICA connection",
                  "name": "citrix-static",
                  "nbarId": "1433",
                  "p2pTechnology": "false",
                  "popularity": 7,
                  "rank": 65535,
                  "references": "http://www.citrix.com/site/resources/dynamic/additional/ICA_Acceleration_0709a.pdf",
                  "selectorId": "1604",
                  "subCategory": "55e6e70d-6c22-4c2d-8a68-0e4a0e9a5967",
                  "trafficClass": "MULTIMEDIA_STREAMING",
                  "tunnel": "false"
                }
              ],
              "networkIdentity": [
                {
                  "id": "fcbadab4-cb94-4d7b-a920-5a03db761ae0",
                  "instanceId": 12558,
                  "authEntityId": 12558,
                  "displayName": "12558",
                  "authEntityClass": 124090504,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "ipv4Subnet": [],
                  "ipv6Subnet": [],
                  "lowerPort": 0,
                  "ports": "1494,1604,2512,2513,2598",
                  "protocol": "TCP",
                  "upperPort": 0
                },
                {
                  "id": "003dc522-6f77-4ed1-88c5-f1656d368e4b",
                  "instanceId": 12559,
                  "authEntityId": 12559,
                  "displayName": "12559",
                  "authEntityClass": 124090504,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "ipv4Subnet": [],
                  "ipv6Subnet": [],
                  "lowerPort": 0,
                  "ports": "1604,2512,2513",
                  "protocol": "UDP",
                  "upperPort": 0
                }
              ],
              "parentScalableGroup": {
                "idRef": "86194015-632b-4646-949c-36963743fc72"
              },
              "user": [],
              "userGroup": []
            }
          }
        ]
      }
    })