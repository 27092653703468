define({
    "Topology_Site_Template": {
        "id": "",
        "name": "",
        "parentId": "",
        "latitude": "",
        "longitude": "",
        "locationType": "",
        "locationAddress": "",
        "locationCountry": "",
        "displayName": "29031",
        "groupNameHierarchy": "Global/San Jose"
    }
});