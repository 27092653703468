define({
"Template":
      {
        "time":"2022-10-06T111:21:11.305+0000","UnavailableCount":"20","AvailableCount":"25",
        "time":"2022-10-06T111:23:11.305+0000","UnavailableCount":"30","AvailableCount":"35",
        "time":"2022-10-06T111:25:11.305+0000","UnavailableCount":"40","AvailableCount":"45",
        "time":"2022-10-06T111:27:11.305+0000","UnavailableCount":"50","AvailableCount":"55",
        "time":"2022-10-06T111:29:11.305+0000","UnavailableCount":"60","AvailableCount":"65",
        "time":"2022-10-06T111:31:11.305+0000","UnavailableCount":"70","AvailableCount":"75"
        }
  });