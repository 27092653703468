define({
    "Template":
    {
      "version": "1.0",
      "response": [
        {
          "time": "2018-05-08T03:00:00.000+0000",
          "healthScore": 6,
          "packetLoss": 0.08,
          "latency": 28.44,
          "applicationDelay": 12.49
        },
        {
          "time": "2018-05-08T02:55:00.000+0000",
          "healthScore": 6,
          "packetLoss": 0.01,
          "latency": 25.79,
          "applicationDelay": 13.21
        },
        {
          "time": "2018-05-08T02:50:00.000+0000",
          "healthScore": 8.9,
          "packetLoss": 0.61,
          "latency": 14.46,
          "applicationDelay": 6.44
        },
        {
          "time": "2018-05-08T02:45:00.000+0000",
          "healthScore": 8.28,
          "packetLoss": 7,
          "latency": 30.59,
          "applicationDelay": 5.11
        },
        {
          "time": "2018-05-08T02:40:00.000+0000",
          "healthScore": 9.67,
          "packetLoss": 0.01,
          "latency": 33.31,
          "applicationDelay": 5.29
        },
        {
          "time": "2018-05-08T02:35:00.000+0000",
          "healthScore": 9.58,
          "packetLoss": 0.01,
          "latency": 49.25,
          "applicationDelay": 4.83
        },
        {
          "time": "2018-05-08T02:30:00.000+0000",
          "healthScore": 6.19,
          "packetLoss": 8.84,
          "latency": 19.71,
          "applicationDelay": 15.95
        },
        {
          "time": "2018-05-08T02:25:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.56,
          "applicationDelay": 5.16
        },
        {
          "time": "2018-05-08T02:20:00.000+0000",
          "healthScore": 9.17,
          "packetLoss": 0.66,
          "latency": 2.06,
          "applicationDelay": 14.31
        },
        {
          "time": "2018-05-08T02:15:00.000+0000",
          "healthScore": 8.8,
          "packetLoss": 8.33,
          "latency": 1.63,
          "applicationDelay": 4.78
        },
        {
          "time": "2018-05-08T02:10:00.000+0000",
          "healthScore": 9.67,
          "packetLoss": 0.21,
          "latency": 9.28,
          "applicationDelay": 3.94
        },
        {
          "time": "2018-05-08T02:05:00.000+0000",
          "healthScore": 9.2,
          "packetLoss": 0.06,
          "latency": 59.2,
          "applicationDelay": 30.6
        },
        {
          "time": "2018-05-08T02:00:00.000+0000",
          "healthScore": 9.85,
          "packetLoss": 0.01,
          "latency": 13.58,
          "applicationDelay": 10.57
        },
        {
          "time": "2018-05-08T01:55:00.000+0000",
          "healthScore": 9.44,
          "packetLoss": 0.08,
          "latency": 14.56,
          "applicationDelay": 2.83
        },
        {
          "time": "2018-05-08T01:50:00.000+0000",
          "healthScore": 8.58,
          "packetLoss": 1.05,
          "latency": 18.05,
          "applicationDelay": 5.83
        },
        {
          "time": "2018-05-08T01:45:00.000+0000",
          "healthScore": 8.28,
          "packetLoss": 8.34,
          "latency": 22.97,
          "applicationDelay": 4.53
        },
        {
          "time": "2018-05-08T01:40:00.000+0000",
          "healthScore": 9.08,
          "packetLoss": 1.82,
          "latency": 46.25,
          "applicationDelay": 34.08
        },
        {
          "time": "2018-05-08T01:35:00.000+0000",
          "healthScore": 9.5,
          "packetLoss": 0.07,
          "latency": 28.19,
          "applicationDelay": 5
        },
        {
          "time": "2018-05-08T01:30:00.000+0000",
          "healthScore": 6.42,
          "packetLoss": 10.15,
          "latency": 46.93,
          "applicationDelay": 26.92
        },
        {
          "time": "2018-05-08T01:25:00.000+0000",
          "healthScore": 9.79,
          "packetLoss": 0.02,
          "latency": 20.69,
          "applicationDelay": 6.71
        },
        {
          "time": "2018-05-08T01:20:00.000+0000",
          "healthScore": 7.56,
          "packetLoss": 1.88,
          "latency": 45.61,
          "applicationDelay": 4.56
        },
        {
          "time": "2018-05-08T01:15:00.000+0000",
          "healthScore": 7.25,
          "packetLoss": 7.84,
          "latency": 436.42,
          "applicationDelay": 35.08
        },
        {
          "time": "2018-05-08T01:10:00.000+0000",
          "healthScore": 9.31,
          "packetLoss": 1.53,
          "latency": 25.94,
          "applicationDelay": 6.25
        },
        {
          "time": "2018-05-08T01:05:00.000+0000",
          "healthScore": 9.13,
          "packetLoss": 0.07,
          "latency": 221.47,
          "applicationDelay": 3.47
        },
        {
          "time": "2018-05-08T01:00:00.000+0000",
          "healthScore": 7.72,
          "packetLoss": 6.37,
          "latency": 102.03,
          "applicationDelay": 6.01
        },
        {
          "time": "2018-05-08T00:55:00.000+0000",
          "healthScore": 8.92,
          "packetLoss": 0.87,
          "latency": 45.08,
          "applicationDelay": 5.5
        },
        {
          "time": "2018-05-08T00:50:00.000+0000",
          "healthScore": 8.17,
          "packetLoss": 0.99,
          "latency": 125.81,
          "applicationDelay": 12.19
        },
        {
          "time": "2018-05-08T00:45:00.000+0000",
          "healthScore": 8.17,
          "packetLoss": 5.7,
          "latency": 30.94,
          "applicationDelay": 8.12
        },
        {
          "time": "2018-05-08T00:40:00.000+0000",
          "healthScore": 8.75,
          "packetLoss": 1.53,
          "latency": 5961.97,
          "applicationDelay": 4.28
        },
        {
          "time": "2018-05-08T00:35:00.000+0000",
          "healthScore": 9.75,
          "packetLoss": 0.02,
          "latency": 24.22,
          "applicationDelay": 5.16
        },
        {
          "time": "2018-05-08T00:30:00.000+0000",
          "healthScore": 6.5,
          "packetLoss": 5.92,
          "latency": 73.94,
          "applicationDelay": 23.06
        },
        {
          "time": "2018-05-08T00:25:00.000+0000",
          "healthScore": 9.56,
          "packetLoss": 0.04,
          "latency": 5.33,
          "applicationDelay": 13.67
        },
        {
          "time": "2018-05-08T00:20:00.000+0000",
          "healthScore": 8.31,
          "packetLoss": 8.56,
          "latency": 42,
          "applicationDelay": 4.94
        },
        {
          "time": "2018-05-08T00:15:00.000+0000",
          "healthScore": 7.11,
          "packetLoss": 6.76,
          "latency": 738.11,
          "applicationDelay": 18.78
        },
        {
          "time": "2018-05-08T00:10:00.000+0000",
          "healthScore": 9.26,
          "packetLoss": 0.42,
          "latency": 17.36,
          "applicationDelay": 3.9
        },
        {
          "time": "2018-05-08T00:05:00.000+0000",
          "healthScore": 9.2,
          "packetLoss": 0.08,
          "latency": 175.8,
          "applicationDelay": 3.7
        },
        {
          "time": "2018-05-08T00:00:00.000+0000",
          "healthScore": 9.61,
          "packetLoss": 0.07,
          "latency": 29.39,
          "applicationDelay": 41.78
        },
        {
          "time": "2018-05-07T23:55:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 1.88,
          "applicationDelay": 8.08
        },
        {
          "time": "2018-05-07T23:50:00.000+0000",
          "healthScore": 8.75,
          "packetLoss": 5.23,
          "latency": 2.25,
          "applicationDelay": 4.58
        },
        {
          "time": "2018-05-07T23:45:00.000+0000",
          "healthScore": 8.47,
          "packetLoss": 6.99,
          "latency": 3.67,
          "applicationDelay": 4.18
        },
        {
          "time": "2018-05-07T23:40:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.33,
          "applicationDelay": 5.5
        },
        {
          "time": "2018-05-07T23:35:00.000+0000",
          "healthScore": 9.5,
          "packetLoss": 0.04,
          "latency": 4.5,
          "applicationDelay": 14.25
        },
        {
          "time": "2018-05-07T23:30:00.000+0000",
          "healthScore": 7.59,
          "packetLoss": 7.3,
          "latency": 4.69,
          "applicationDelay": 7.06
        },
        {
          "time": "2018-05-07T23:25:00.000+0000",
          "healthScore": 9.5,
          "packetLoss": 0.04,
          "latency": 2.19,
          "applicationDelay": 5.84
        },
        {
          "time": "2018-05-07T23:20:00.000+0000",
          "healthScore": 9.17,
          "packetLoss": 0.6,
          "latency": 2.5,
          "applicationDelay": 3.17
        },
        {
          "time": "2018-05-07T23:15:00.000+0000",
          "healthScore": 8,
          "packetLoss": 13.89,
          "latency": 1.4,
          "applicationDelay": 4.9
        },
        {
          "time": "2018-05-07T23:10:00.000+0000",
          "healthScore": 9.7,
          "packetLoss": 0.02,
          "latency": 1.4,
          "applicationDelay": 41.6
        },
        {
          "time": "2018-05-07T23:05:00.000+0000",
          "healthScore": 9.72,
          "packetLoss": 0.05,
          "latency": 2.06,
          "applicationDelay": 3.17
        },
        {
          "time": "2018-05-07T23:00:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 4.1,
          "applicationDelay": 9.03
        },
        {
          "time": "2018-05-07T22:55:00.000+0000",
          "healthScore": 9.49,
          "packetLoss": 0.05,
          "latency": 1.96,
          "applicationDelay": 3.87
        },
        {
          "time": "2018-05-07T22:50:00.000+0000",
          "healthScore": 8.1,
          "packetLoss": 1.57,
          "latency": 1.56,
          "applicationDelay": 4.48
        },
        {
          "time": "2018-05-07T22:45:00.000+0000",
          "healthScore": 7.94,
          "packetLoss": 13.89,
          "latency": 1.28,
          "applicationDelay": 4.39
        },
        {
          "time": "2018-05-07T22:40:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0.01,
          "latency": 2.4,
          "applicationDelay": 4.8
        },
        {
          "time": "2018-05-07T22:35:00.000+0000",
          "healthScore": 9.38,
          "packetLoss": 0.13,
          "latency": 2.33,
          "applicationDelay": 5.38
        },
        {
          "time": "2018-05-07T22:30:00.000+0000",
          "healthScore": 6.4,
          "packetLoss": 10.57,
          "latency": 2.96,
          "applicationDelay": 6.26
        },
        {
          "time": "2018-05-07T22:25:00.000+0000",
          "healthScore": 9.75,
          "packetLoss": 0.03,
          "latency": 2.31,
          "applicationDelay": 7.38
        },
        {
          "time": "2018-05-07T22:20:00.000+0000",
          "healthScore": 8.33,
          "packetLoss": 1.4,
          "latency": 1.63,
          "applicationDelay": 3.21
        },
        {
          "time": "2018-05-07T22:15:00.000+0000",
          "healthScore": 7.93,
          "packetLoss": 13.89,
          "latency": 1.78,
          "applicationDelay": 19.13
        },
        {
          "time": "2018-05-07T22:10:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.83,
          "applicationDelay": 4.17
        },
        {
          "time": "2018-05-07T22:05:00.000+0000",
          "healthScore": 8,
          "packetLoss": 3.57,
          "latency": 1.38,
          "applicationDelay": 5.33
        },
        {
          "time": "2018-05-07T22:00:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 15.67,
          "applicationDelay": 21.89
        },
        {
          "time": "2018-05-07T21:55:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0.02,
          "latency": 4.21,
          "applicationDelay": 5.86
        },
        {
          "time": "2018-05-07T21:50:00.000+0000",
          "healthScore": 7.96,
          "packetLoss": 13.89,
          "latency": 2.38,
          "applicationDelay": 23.74
        },
        {
          "time": "2018-05-07T21:45:00.000+0000",
          "healthScore": 8.82,
          "packetLoss": 3.94,
          "latency": 1.17,
          "applicationDelay": 3.91
        },
        {
          "time": "2018-05-07T21:40:00.000+0000",
          "healthScore": 9.26,
          "packetLoss": 1.45,
          "latency": 1.74,
          "applicationDelay": 4.96
        },
        {
          "time": "2018-05-07T21:35:00.000+0000",
          "healthScore": 9.33,
          "packetLoss": 0.05,
          "latency": 3,
          "applicationDelay": 4.33
        },
        {
          "time": "2018-05-07T21:30:00.000+0000",
          "healthScore": 6.57,
          "packetLoss": 13.33,
          "latency": 15.81,
          "applicationDelay": 21.24
        },
        {
          "time": "2018-05-07T21:25:00.000+0000",
          "healthScore": 9.31,
          "packetLoss": 0.04,
          "latency": 4.06,
          "applicationDelay": 11.69
        },
        {
          "time": "2018-05-07T21:20:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.25,
          "applicationDelay": 6.5
        },
        {
          "time": "2018-05-07T21:15:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2,
          "applicationDelay": 5
        },
        {
          "time": "2018-05-07T21:10:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 1.83,
          "applicationDelay": 4.67
        },
        {
          "time": "2018-05-07T21:05:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.2,
          "applicationDelay": 6.6
        },
        {
          "time": "2018-05-07T21:00:00.000+0000",
          "healthScore": 9.83,
          "packetLoss": 0.01,
          "latency": 15.72,
          "applicationDelay": 22.28
        },
        {
          "time": "2018-05-07T20:55:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 1.72,
          "applicationDelay": 10.89
        },
        {
          "time": "2018-05-07T20:50:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 3,
          "applicationDelay": 8
        },
        {
          "time": "2018-05-07T20:45:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0.01,
          "latency": 1.5,
          "applicationDelay": 5.25
        },
        {
          "time": "2018-05-07T20:40:00.000+0000",
          "healthScore": 9.7,
          "packetLoss": 0.02,
          "latency": 2.2,
          "applicationDelay": 11.3
        },
        {
          "time": "2018-05-07T20:35:00.000+0000",
          "healthScore": 9.36,
          "packetLoss": 1.26,
          "latency": 1.43,
          "applicationDelay": 37.36
        },
        {
          "time": "2018-05-07T20:30:00.000+0000",
          "healthScore": 7.97,
          "packetLoss": 2.47,
          "latency": 11.89,
          "applicationDelay": 18.19
        },
        {
          "time": "2018-05-07T20:25:00.000+0000",
          "healthScore": 9.67,
          "packetLoss": 0.03,
          "latency": 1.92,
          "applicationDelay": 5.75
        },
        {
          "time": "2018-05-07T20:20:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.75,
          "applicationDelay": 6.25
        },
        {
          "time": "2018-05-07T20:15:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.38,
          "applicationDelay": 4.5
        },
        {
          "time": "2018-05-07T20:10:00.000+0000",
          "healthScore": 9,
          "packetLoss": 1.35,
          "latency": 2.25,
          "applicationDelay": 10.38
        },
        {
          "time": "2018-05-07T20:05:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.33,
          "applicationDelay": 5.67
        },
        {
          "time": "2018-05-07T20:00:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 12.15,
          "applicationDelay": 18.45
        },
        {
          "time": "2018-05-07T19:55:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 3.25,
          "applicationDelay": 4.75
        },
        {
          "time": "2018-05-07T19:50:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 1.75,
          "applicationDelay": 4.75
        },
        {
          "time": "2018-05-07T19:45:00.000+0000",
          "healthScore": 9.75,
          "packetLoss": 0.02,
          "latency": 3.5,
          "applicationDelay": 36.38
        },
        {
          "time": "2018-05-07T19:40:00.000+0000",
          "healthScore": 9.75,
          "packetLoss": 0.02,
          "latency": 1.58,
          "applicationDelay": 4.58
        },
        {
          "time": "2018-05-07T19:35:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 1.9,
          "applicationDelay": 5.43
        },
        {
          "time": "2018-05-07T19:30:00.000+0000",
          "healthScore": 6.33,
          "packetLoss": 11.79,
          "latency": 14.56,
          "applicationDelay": 21.39
        },
        {
          "time": "2018-05-07T19:25:00.000+0000",
          "healthScore": 9.75,
          "packetLoss": 0.03,
          "latency": 2.75,
          "applicationDelay": 6.31
        },
        {
          "time": "2018-05-07T19:20:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.1,
          "applicationDelay": 16.4
        },
        {
          "time": "2018-05-07T19:15:00.000+0000",
          "healthScore": 8.75,
          "packetLoss": 0.24,
          "latency": 1.5,
          "applicationDelay": 6
        },
        {
          "time": "2018-05-07T19:10:00.000+0000",
          "healthScore": 9.86,
          "packetLoss": 0.02,
          "latency": 1.95,
          "applicationDelay": 5
        },
        {
          "time": "2018-05-07T19:05:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.33,
          "applicationDelay": 6.33
        },
        {
          "time": "2018-05-07T19:00:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 15.08,
          "applicationDelay": 26.92
        },
        {
          "time": "2018-05-07T18:55:00.000+0000",
          "healthScore": 9.5,
          "packetLoss": 0.1,
          "latency": 2.1,
          "applicationDelay": 15.4
        },
        {
          "time": "2018-05-07T18:50:00.000+0000",
          "healthScore": 9,
          "packetLoss": 2.09,
          "latency": 1.5,
          "applicationDelay": 5
        },
        {
          "time": "2018-05-07T18:45:00.000+0000",
          "healthScore": 9.93,
          "packetLoss": 0.01,
          "latency": 2.27,
          "applicationDelay": 5.13
        },
        {
          "time": "2018-05-07T18:40:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 1.38,
          "applicationDelay": 6
        },
        {
          "time": "2018-05-07T18:35:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.38,
          "applicationDelay": 3.75
        },
        {
          "time": "2018-05-07T18:30:00.000+0000",
          "healthScore": 6.52,
          "packetLoss": 8.88,
          "latency": 14.62,
          "applicationDelay": 30.38
        },
        {
          "time": "2018-05-07T18:25:00.000+0000",
          "healthScore": 9.36,
          "packetLoss": 0.82,
          "latency": 65.29,
          "applicationDelay": 4.71
        },
        {
          "time": "2018-05-07T18:20:00.000+0000",
          "healthScore": 9.07,
          "packetLoss": 0.96,
          "latency": 127.27,
          "applicationDelay": 6.93
        },
        {
          "time": "2018-05-07T18:15:00.000+0000",
          "healthScore": 9.25,
          "packetLoss": 1.33,
          "latency": 86.92,
          "applicationDelay": 4.75
        },
        {
          "time": "2018-05-07T18:10:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 3.25,
          "applicationDelay": 4.75
        },
        {
          "time": "2018-05-07T18:05:00.000+0000",
          "healthScore": 8.67,
          "packetLoss": 0.2,
          "latency": 1.5,
          "applicationDelay": 30.5
        },
        {
          "time": "2018-05-07T18:00:00.000+0000",
          "healthScore": 9.67,
          "packetLoss": 0.02,
          "latency": 16.42,
          "applicationDelay": 22.17
        },
        {
          "time": "2018-05-07T17:55:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0.01,
          "latency": 5.67,
          "applicationDelay": 6.33
        },
        {
          "time": "2018-05-07T17:50:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 1.58,
          "applicationDelay": 5.75
        },
        {
          "time": "2018-05-07T17:45:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 1.75,
          "applicationDelay": 5.42
        },
        {
          "time": "2018-05-07T17:40:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 3.64,
          "applicationDelay": 30.57
        },
        {
          "time": "2018-05-07T17:35:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0.01,
          "latency": 3.25,
          "applicationDelay": 5.08
        },
        {
          "time": "2018-05-07T17:30:00.000+0000",
          "healthScore": 7.5,
          "packetLoss": 5.39,
          "latency": 11.46,
          "applicationDelay": 21.04
        },
        {
          "time": "2018-05-07T17:25:00.000+0000",
          "healthScore": 9.71,
          "packetLoss": 0.04,
          "latency": 2.36,
          "applicationDelay": 5.07
        },
        {
          "time": "2018-05-07T17:20:00.000+0000",
          "healthScore": 9.5,
          "packetLoss": 1.39,
          "latency": 1.83,
          "applicationDelay": 3.75
        },
        {
          "time": "2018-05-07T17:15:00.000+0000",
          "healthScore": 9.9,
          "packetLoss": 0.01,
          "latency": 2.1,
          "applicationDelay": 32.6
        },
        {
          "time": "2018-05-07T17:10:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.2,
          "applicationDelay": 10.3
        },
        {
          "time": "2018-05-07T17:05:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.56,
          "applicationDelay": 4.89
        },
        {
          "time": "2018-05-07T17:00:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 15.67,
          "applicationDelay": 23.67
        },
        {
          "time": "2018-05-07T16:55:00.000+0000",
          "healthScore": 9.67,
          "packetLoss": 0.06,
          "latency": 1.92,
          "applicationDelay": 5.33
        },
        {
          "time": "2018-05-07T16:50:00.000+0000",
          "healthScore": 9.25,
          "packetLoss": 0.06,
          "latency": 2.12,
          "applicationDelay": 4.25
        },
        {
          "time": "2018-05-07T16:45:00.000+0000",
          "healthScore": 9.83,
          "packetLoss": 0.01,
          "latency": 1.92,
          "applicationDelay": 26.25
        },
        {
          "time": "2018-05-07T16:40:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 3,
          "applicationDelay": 4.11
        },
        {
          "time": "2018-05-07T16:35:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 1.75,
          "applicationDelay": 15
        },
        {
          "time": "2018-05-07T16:30:00.000+0000",
          "healthScore": 7.07,
          "packetLoss": 7.79,
          "latency": 14.81,
          "applicationDelay": 22.59
        },
        {
          "time": "2018-05-07T16:25:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.31,
          "applicationDelay": 15.94
        },
        {
          "time": "2018-05-07T16:20:00.000+0000",
          "healthScore": 9.3,
          "packetLoss": 0.11,
          "latency": 3.3,
          "applicationDelay": 4.4
        },
        {
          "time": "2018-05-07T16:15:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 1.33,
          "applicationDelay": 6.58
        },
        {
          "time": "2018-05-07T16:10:00.000+0000",
          "healthScore": 9.17,
          "packetLoss": 0.17,
          "latency": 2,
          "applicationDelay": 6.5
        },
        {
          "time": "2018-05-07T16:05:00.000+0000",
          "healthScore": 9.38,
          "packetLoss": 0.12,
          "latency": 2.25,
          "applicationDelay": 6
        },
        {
          "time": "2018-05-07T16:00:00.000+0000",
          "healthScore": 9.67,
          "packetLoss": 0.03,
          "latency": 15.19,
          "applicationDelay": 22.29
        },
        {
          "time": "2018-05-07T15:55:00.000+0000",
          "healthScore": 9.3,
          "packetLoss": 0.15,
          "latency": 1.9,
          "applicationDelay": 3.7
        },
        {
          "time": "2018-05-07T15:50:00.000+0000",
          "healthScore": 9.73,
          "packetLoss": 0.05,
          "latency": 1.4,
          "applicationDelay": 7.73
        },
        {
          "time": "2018-05-07T15:45:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0.02,
          "latency": 3.33,
          "applicationDelay": 5.83
        },
        {
          "time": "2018-05-07T15:40:00.000+0000",
          "healthScore": 8.67,
          "packetLoss": 0.21,
          "latency": 2.67,
          "applicationDelay": 6.17
        },
        {
          "time": "2018-05-07T15:35:00.000+0000",
          "healthScore": 9.64,
          "packetLoss": 0.07,
          "latency": 2,
          "applicationDelay": 7.07
        },
        {
          "time": "2018-05-07T15:30:00.000+0000",
          "healthScore": 6.71,
          "packetLoss": 10.36,
          "latency": 15.75,
          "applicationDelay": 20.75
        },
        {
          "time": "2018-05-07T15:25:00.000+0000",
          "healthScore": 9.71,
          "packetLoss": 0.03,
          "latency": 2.14,
          "applicationDelay": 10.79
        },
        {
          "time": "2018-05-07T15:20:00.000+0000",
          "healthScore": 9.75,
          "packetLoss": 0.02,
          "latency": 2.42,
          "applicationDelay": 3.42
        },
        {
          "time": "2018-05-07T15:15:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.5,
          "applicationDelay": 6.25
        },
        {
          "time": "2018-05-07T15:10:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.07,
          "applicationDelay": 4.36
        },
        {
          "time": "2018-05-07T15:05:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.5,
          "applicationDelay": 37.3
        },
        {
          "time": "2018-05-07T15:00:00.000+0000",
          "healthScore": 9.92,
          "packetLoss": 0.01,
          "latency": 14.83,
          "applicationDelay": 26
        },
        {
          "time": "2018-05-07T14:55:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.25,
          "applicationDelay": 5.83
        },
        {
          "time": "2018-05-07T14:50:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 3,
          "applicationDelay": 5
        },
        {
          "time": "2018-05-07T14:45:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 3.29,
          "applicationDelay": 6.14
        },
        {
          "time": "2018-05-07T14:40:00.000+0000",
          "healthScore": 9.42,
          "packetLoss": 0.09,
          "latency": 2.17,
          "applicationDelay": 21.92
        },
        {
          "time": "2018-05-07T14:35:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0.02,
          "latency": 2,
          "applicationDelay": 4.33
        },
        {
          "time": "2018-05-07T14:30:00.000+0000",
          "healthScore": 8.96,
          "packetLoss": 3.31,
          "latency": 12.14,
          "applicationDelay": 15.79
        },
        {
          "time": "2018-05-07T14:25:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 3.4,
          "applicationDelay": 6.6
        },
        {
          "time": "2018-05-07T14:20:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 1.7,
          "applicationDelay": 6.4
        },
        {
          "time": "2018-05-07T14:15:00.000+0000",
          "healthScore": 9.5,
          "packetLoss": 0.1,
          "latency": 2.3,
          "applicationDelay": 42.3
        },
        {
          "time": "2018-05-07T14:10:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 1.88,
          "applicationDelay": 5.88
        },
        {
          "time": "2018-05-07T14:05:00.000+0000",
          "healthScore": 9.92,
          "packetLoss": 0,
          "latency": 2.67,
          "applicationDelay": 4.58
        },
        {
          "time": "2018-05-07T14:00:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 15.33,
          "applicationDelay": 18.67
        },
        {
          "time": "2018-05-07T13:55:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 1.7,
          "applicationDelay": 6.2
        },
        {
          "time": "2018-05-07T13:50:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0.01,
          "latency": 1.17,
          "applicationDelay": 15.58
        },
        {
          "time": "2018-05-07T13:45:00.000+0000",
          "healthScore": 9.5,
          "packetLoss": 0.1,
          "latency": 1.5,
          "applicationDelay": 6.6
        },
        {
          "time": "2018-05-07T13:40:00.000+0000",
          "healthScore": 9.58,
          "packetLoss": 0.07,
          "latency": 1.92,
          "applicationDelay": 5.33
        },
        {
          "time": "2018-05-07T13:35:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 1.67,
          "applicationDelay": 5.5
        },
        {
          "time": "2018-05-07T13:30:00.000+0000",
          "healthScore": 6.67,
          "packetLoss": 9.02,
          "latency": 14.48,
          "applicationDelay": 24.05
        },
        {
          "time": "2018-05-07T13:25:00.000+0000",
          "healthScore": 9.75,
          "packetLoss": 0.02,
          "latency": 2.31,
          "applicationDelay": 8.5
        },
        {
          "time": "2018-05-07T13:20:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 1.4,
          "applicationDelay": 3.6
        },
        {
          "time": "2018-05-07T13:15:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0.01,
          "latency": 2.4,
          "applicationDelay": 7.6
        },
        {
          "time": "2018-05-07T13:10:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 1.75,
          "applicationDelay": 5
        },
        {
          "time": "2018-05-07T13:05:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 1.38,
          "applicationDelay": 11.75
        },
        {
          "time": "2018-05-07T13:00:00.000+0000",
          "healthScore": 9.92,
          "packetLoss": 0,
          "latency": 15.08,
          "applicationDelay": 21.75
        },
        {
          "time": "2018-05-07T12:55:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.38,
          "applicationDelay": 7.62
        },
        {
          "time": "2018-05-07T12:50:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.33,
          "applicationDelay": 4.83
        },
        {
          "time": "2018-05-07T12:45:00.000+0000",
          "healthScore": 9.44,
          "packetLoss": 0.11,
          "latency": 1.33,
          "applicationDelay": 8.44
        },
        {
          "time": "2018-05-07T12:40:00.000+0000",
          "healthScore": 9.62,
          "packetLoss": 0.03,
          "latency": 2.25,
          "applicationDelay": 3.88
        },
        {
          "time": "2018-05-07T12:35:00.000+0000",
          "healthScore": 9.6,
          "packetLoss": 0.07,
          "latency": 2.5,
          "applicationDelay": 3.6
        },
        {
          "time": "2018-05-07T12:30:00.000+0000",
          "healthScore": 6.9,
          "packetLoss": 7.61,
          "latency": 15.52,
          "applicationDelay": 21.57
        },
        {
          "time": "2018-05-07T12:25:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.5,
          "applicationDelay": 6.17
        },
        {
          "time": "2018-05-07T12:20:00.000+0000",
          "healthScore": 9.67,
          "packetLoss": 0.07,
          "latency": 2.6,
          "applicationDelay": 6.07
        },
        {
          "time": "2018-05-07T12:15:00.000+0000",
          "healthScore": 8.75,
          "packetLoss": 0.25,
          "latency": 2.5,
          "applicationDelay": 6
        },
        {
          "time": "2018-05-07T12:10:00.000+0000",
          "healthScore": 9.83,
          "packetLoss": 0.01,
          "latency": 1.92,
          "applicationDelay": 14.67
        },
        {
          "time": "2018-05-07T12:05:00.000+0000",
          "healthScore": 9.58,
          "packetLoss": 0.06,
          "latency": 3.25,
          "applicationDelay": 3.83
        },
        {
          "time": "2018-05-07T12:00:00.000+0000",
          "healthScore": 9.5,
          "packetLoss": 3.93,
          "latency": 16.83,
          "applicationDelay": 22.08
        },
        {
          "time": "2018-05-07T11:55:00.000+0000",
          "healthScore": 9.56,
          "packetLoss": 0.03,
          "latency": 162.78,
          "applicationDelay": 6.22
        },
        {
          "time": "2018-05-07T11:50:00.000+0000",
          "healthScore": 8.25,
          "packetLoss": 0.26,
          "latency": 276.88,
          "applicationDelay": 6
        },
        {
          "time": "2018-05-07T11:45:00.000+0000",
          "healthScore": 8.67,
          "packetLoss": 0.35,
          "latency": 60.33,
          "applicationDelay": 3.5
        },
        {
          "time": "2018-05-07T11:40:00.000+0000",
          "healthScore": 9.25,
          "packetLoss": 0.35,
          "latency": 38.42,
          "applicationDelay": 4.75
        },
        {
          "time": "2018-05-07T11:35:00.000+0000",
          "healthScore": 8.62,
          "packetLoss": 0.35,
          "latency": 30.75,
          "applicationDelay": 4.62
        },
        {
          "time": "2018-05-07T11:30:00.000+0000",
          "healthScore": 8.38,
          "packetLoss": 2.72,
          "latency": 119.25,
          "applicationDelay": 18.35
        },
        {
          "time": "2018-05-07T11:25:00.000+0000",
          "healthScore": 9.44,
          "packetLoss": 0.17,
          "latency": 58.75,
          "applicationDelay": 10.19
        },
        {
          "time": "2018-05-07T11:20:00.000+0000",
          "healthScore": 9.1,
          "packetLoss": 1.32,
          "latency": 56.8,
          "applicationDelay": 20.8
        },
        {
          "time": "2018-05-07T11:15:00.000+0000",
          "healthScore": 8.92,
          "packetLoss": 0.15,
          "latency": 497.17,
          "applicationDelay": 5.83
        },
        {
          "time": "2018-05-07T11:10:00.000+0000",
          "healthScore": 9.1,
          "packetLoss": 0.48,
          "latency": 418.1,
          "applicationDelay": 6.9
        },
        {
          "time": "2018-05-07T11:05:00.000+0000",
          "healthScore": 9.33,
          "packetLoss": 0.9,
          "latency": 15.13,
          "applicationDelay": 6.07
        },
        {
          "time": "2018-05-07T11:00:00.000+0000",
          "healthScore": 9.25,
          "packetLoss": 1.71,
          "latency": 290.08,
          "applicationDelay": 23.67
        },
        {
          "time": "2018-05-07T10:55:00.000+0000",
          "healthScore": 9.1,
          "packetLoss": 1.36,
          "latency": 105,
          "applicationDelay": 21.1
        },
        {
          "time": "2018-05-07T10:50:00.000+0000",
          "healthScore": 9.1,
          "packetLoss": 0.05,
          "latency": 549.7,
          "applicationDelay": 51.5
        },
        {
          "time": "2018-05-07T10:45:00.000+0000",
          "healthScore": 8.88,
          "packetLoss": 2.51,
          "latency": 71.5,
          "applicationDelay": 2.62
        },
        {
          "time": "2018-05-07T10:40:00.000+0000",
          "healthScore": 9.5,
          "packetLoss": 0.24,
          "latency": 203.67,
          "applicationDelay": 4.28
        },
        {
          "time": "2018-05-07T10:35:00.000+0000",
          "healthScore": 8.5,
          "packetLoss": 2.55,
          "latency": 1509,
          "applicationDelay": 2.75
        },
        {
          "time": "2018-05-07T10:30:00.000+0000",
          "healthScore": 8.37,
          "packetLoss": 4,
          "latency": 174.96,
          "applicationDelay": 32.63
        },
        {
          "time": "2018-05-07T10:25:00.000+0000",
          "healthScore": 9.31,
          "packetLoss": 1.41,
          "latency": 21.75,
          "applicationDelay": 5.69
        },
        {
          "time": "2018-05-07T10:20:00.000+0000",
          "healthScore": 8.88,
          "packetLoss": 0.22,
          "latency": 1224.88,
          "applicationDelay": 4.5
        },
        {
          "time": "2018-05-07T10:15:00.000+0000",
          "healthScore": 9.48,
          "packetLoss": 0.06,
          "latency": 71.71,
          "applicationDelay": 5.24
        },
        {
          "time": "2018-05-07T10:10:00.000+0000",
          "healthScore": 9.1,
          "packetLoss": 0.21,
          "latency": 171.4,
          "applicationDelay": 5.6
        },
        {
          "time": "2018-05-07T10:05:00.000+0000",
          "healthScore": 9.25,
          "packetLoss": 0.08,
          "latency": 176.25,
          "applicationDelay": 4.5
        },
        {
          "time": "2018-05-07T10:00:00.000+0000",
          "healthScore": 9.08,
          "packetLoss": 0.22,
          "latency": 244.08,
          "applicationDelay": 41.75
        },
        {
          "time": "2018-05-07T09:55:00.000+0000",
          "healthScore": 8.5,
          "packetLoss": 0.46,
          "latency": 287.17,
          "applicationDelay": 3.33
        },
        {
          "time": "2018-05-07T09:50:00.000+0000",
          "healthScore": 9.39,
          "packetLoss": 0.23,
          "latency": 29.94,
          "applicationDelay": 7.94
        },
        {
          "time": "2018-05-07T09:45:00.000+0000",
          "healthScore": 8.7,
          "packetLoss": 2.13,
          "latency": 41,
          "applicationDelay": 3.9
        },
        {
          "time": "2018-05-07T09:40:00.000+0000",
          "healthScore": 8.79,
          "packetLoss": 1.04,
          "latency": 104.57,
          "applicationDelay": 4.21
        },
        {
          "time": "2018-05-07T09:35:00.000+0000",
          "healthScore": 8.88,
          "packetLoss": 3.15,
          "latency": 127.38,
          "applicationDelay": 25
        },
        {
          "time": "2018-05-07T09:30:00.000+0000",
          "healthScore": 6.29,
          "packetLoss": 11.37,
          "latency": 1432.43,
          "applicationDelay": 21.71
        },
        {
          "time": "2018-05-07T09:25:00.000+0000",
          "healthScore": 9.67,
          "packetLoss": 0.02,
          "latency": 27.79,
          "applicationDelay": 6.5
        },
        {
          "time": "2018-05-07T09:20:00.000+0000",
          "healthScore": 9.5,
          "packetLoss": 0.32,
          "latency": 13.17,
          "applicationDelay": 5
        },
        {
          "time": "2018-05-07T09:15:00.000+0000",
          "healthScore": 9.36,
          "packetLoss": 1.44,
          "latency": 58.21,
          "applicationDelay": 5.57
        },
        {
          "time": "2018-05-07T09:10:00.000+0000",
          "healthScore": 8.6,
          "packetLoss": 1.31,
          "latency": 111.4,
          "applicationDelay": 19.9
        },
        {
          "time": "2018-05-07T09:05:00.000+0000",
          "healthScore": 9.33,
          "packetLoss": 0.04,
          "latency": 58,
          "applicationDelay": 5
        },
        {
          "time": "2018-05-07T09:00:00.000+0000",
          "healthScore": 9.69,
          "packetLoss": 0.01,
          "latency": 85.12,
          "applicationDelay": 17.81
        },
        {
          "time": "2018-05-07T08:55:00.000+0000",
          "healthScore": 9.24,
          "packetLoss": 0.14,
          "latency": 236.48,
          "applicationDelay": 6.52
        },
        {
          "time": "2018-05-07T08:50:00.000+0000",
          "healthScore": 9.36,
          "packetLoss": 0.24,
          "latency": 825.29,
          "applicationDelay": 6.5
        },
        {
          "time": "2018-05-07T08:45:00.000+0000",
          "healthScore": 9.33,
          "packetLoss": 5.59,
          "latency": 2.67,
          "applicationDelay": 17.25
        },
        {
          "time": "2018-05-07T08:40:00.000+0000",
          "healthScore": 8.5,
          "packetLoss": 5.24,
          "latency": 273.62,
          "applicationDelay": 4.38
        },
        {
          "time": "2018-05-07T08:35:00.000+0000",
          "healthScore": 9.25,
          "packetLoss": 0.62,
          "latency": 124.58,
          "applicationDelay": 2.92
        },
        {
          "time": "2018-05-07T08:30:00.000+0000",
          "healthScore": 7.47,
          "packetLoss": 6.2,
          "latency": 51.28,
          "applicationDelay": 17.78
        },
        {
          "time": "2018-05-07T08:25:00.000+0000",
          "healthScore": 9.7,
          "packetLoss": 0.01,
          "latency": 47.75,
          "applicationDelay": 4.4
        },
        {
          "time": "2018-05-07T08:20:00.000+0000",
          "healthScore": 9.25,
          "packetLoss": 0.06,
          "latency": 62.5,
          "applicationDelay": 36.08
        },
        {
          "time": "2018-05-07T08:15:00.000+0000",
          "healthScore": 9.25,
          "packetLoss": 0.02,
          "latency": 1227.5,
          "applicationDelay": 6.75
        },
        {
          "time": "2018-05-07T08:10:00.000+0000",
          "healthScore": 8.83,
          "packetLoss": 0.07,
          "latency": 55,
          "applicationDelay": 15.67
        },
        {
          "time": "2018-05-07T08:05:00.000+0000",
          "healthScore": 9.83,
          "packetLoss": 0.01,
          "latency": 14.33,
          "applicationDelay": 5.92
        },
        {
          "time": "2018-05-07T08:00:00.000+0000",
          "healthScore": 9.67,
          "packetLoss": 0.02,
          "latency": 39.29,
          "applicationDelay": 21.83
        },
        {
          "time": "2018-05-07T07:55:00.000+0000",
          "healthScore": 9.21,
          "packetLoss": 0.11,
          "latency": 9.86,
          "applicationDelay": 15.14
        },
        {
          "time": "2018-05-07T07:50:00.000+0000",
          "healthScore": 8.75,
          "packetLoss": 0.21,
          "latency": 27.62,
          "applicationDelay": 8.62
        },
        {
          "time": "2018-05-07T07:45:00.000+0000",
          "healthScore": 9.17,
          "packetLoss": 0.16,
          "latency": 29.83,
          "applicationDelay": 5.33
        },
        {
          "time": "2018-05-07T07:40:00.000+0000",
          "healthScore": 8.67,
          "packetLoss": 0.15,
          "latency": 199.56,
          "applicationDelay": 6.11
        },
        {
          "time": "2018-05-07T07:35:00.000+0000",
          "healthScore": 9.57,
          "packetLoss": 0.03,
          "latency": 25.14,
          "applicationDelay": 4.86
        },
        {
          "time": "2018-05-07T07:30:00.000+0000",
          "healthScore": 6.93,
          "packetLoss": 3.38,
          "latency": 25,
          "applicationDelay": 32.41
        },
        {
          "time": "2018-05-07T07:25:00.000+0000",
          "healthScore": 8.79,
          "packetLoss": 0.13,
          "latency": 27.86,
          "applicationDelay": 6.64
        },
        {
          "time": "2018-05-07T07:20:00.000+0000",
          "healthScore": 9.5,
          "packetLoss": 0.02,
          "latency": 60,
          "applicationDelay": 7.62
        },
        {
          "time": "2018-05-07T07:15:00.000+0000",
          "healthScore": 9.58,
          "packetLoss": 0.05,
          "latency": 18.25,
          "applicationDelay": 4.5
        },
        {
          "time": "2018-05-07T07:10:00.000+0000",
          "healthScore": 9.25,
          "packetLoss": 0.28,
          "latency": 28.25,
          "applicationDelay": 4.38
        },
        {
          "time": "2018-05-07T07:05:00.000+0000",
          "healthScore": 9.75,
          "packetLoss": 0.01,
          "latency": 19.12,
          "applicationDelay": 22.25
        },
        {
          "time": "2018-05-07T07:00:00.000+0000",
          "healthScore": 9.5,
          "packetLoss": 0.07,
          "latency": 39.67,
          "applicationDelay": 23.25
        },
        {
          "time": "2018-05-07T06:55:00.000+0000",
          "healthScore": 9.33,
          "packetLoss": 0.06,
          "latency": 32,
          "applicationDelay": 5.17
        },
        {
          "time": "2018-05-07T06:50:00.000+0000",
          "healthScore": 9.5,
          "packetLoss": 0.07,
          "latency": 27.33,
          "applicationDelay": 5.17
        },
        {
          "time": "2018-05-07T06:45:00.000+0000",
          "healthScore": 9.12,
          "packetLoss": 0.06,
          "latency": 68.5,
          "applicationDelay": 3.88
        },
        {
          "time": "2018-05-07T06:40:00.000+0000",
          "healthScore": 9.5,
          "packetLoss": 0.05,
          "latency": 26.5,
          "applicationDelay": 16.28
        },
        {
          "time": "2018-05-07T06:35:00.000+0000",
          "healthScore": 9.5,
          "packetLoss": 0.07,
          "latency": 19,
          "applicationDelay": 4.3
        },
        {
          "time": "2018-05-07T06:30:00.000+0000",
          "healthScore": 6.48,
          "packetLoss": 9.99,
          "latency": 26.86,
          "applicationDelay": 19.52
        },
        {
          "time": "2018-05-07T06:25:00.000+0000",
          "healthScore": 9.75,
          "packetLoss": 0.02,
          "latency": 20.04,
          "applicationDelay": 5.92
        },
        {
          "time": "2018-05-07T06:20:00.000+0000",
          "healthScore": 8.5,
          "packetLoss": 0.26,
          "latency": 38.75,
          "applicationDelay": 6.75
        },
        {
          "time": "2018-05-07T06:15:00.000+0000",
          "healthScore": 9.67,
          "packetLoss": 0.02,
          "latency": 12.5,
          "applicationDelay": 11.94
        },
        {
          "time": "2018-05-07T06:10:00.000+0000",
          "healthScore": 9.86,
          "packetLoss": 0.01,
          "latency": 16.93,
          "applicationDelay": 6.14
        },
        {
          "time": "2018-05-07T06:05:00.000+0000",
          "healthScore": 7.62,
          "packetLoss": 0.27,
          "latency": 39.25,
          "applicationDelay": 6
        },
        {
          "time": "2018-05-07T06:00:00.000+0000",
          "healthScore": 9.9,
          "packetLoss": 0.01,
          "latency": 18.05,
          "applicationDelay": 17.85
        },
        {
          "time": "2018-05-07T05:55:00.000+0000",
          "healthScore": 8.83,
          "packetLoss": 0.19,
          "latency": 32.17,
          "applicationDelay": 4
        },
        {
          "time": "2018-05-07T05:50:00.000+0000",
          "healthScore": 9.5,
          "packetLoss": 0.08,
          "latency": 8.57,
          "applicationDelay": 15.29
        },
        {
          "time": "2018-05-07T05:45:00.000+0000",
          "healthScore": 9.08,
          "packetLoss": 0.08,
          "latency": 35.33,
          "applicationDelay": 4.5
        },
        {
          "time": "2018-05-07T05:40:00.000+0000",
          "healthScore": 8.33,
          "packetLoss": 0.24,
          "latency": 21.67,
          "applicationDelay": 15
        },
        {
          "time": "2018-05-07T05:35:00.000+0000",
          "healthScore": 9.33,
          "packetLoss": 0.08,
          "latency": 20.53,
          "applicationDelay": 5.2
        },
        {
          "time": "2018-05-07T05:30:00.000+0000",
          "healthScore": 7.97,
          "packetLoss": 3.73,
          "latency": 20.81,
          "applicationDelay": 17.34
        },
        {
          "time": "2018-05-07T05:25:00.000+0000",
          "healthScore": 9.6,
          "packetLoss": 0.03,
          "latency": 14.55,
          "applicationDelay": 4.2
        },
        {
          "time": "2018-05-07T05:20:00.000+0000",
          "healthScore": 9.5,
          "packetLoss": 0.06,
          "latency": 27.07,
          "applicationDelay": 37.29
        },
        {
          "time": "2018-05-07T05:15:00.000+0000",
          "healthScore": 9.75,
          "packetLoss": 0.02,
          "latency": 24.88,
          "applicationDelay": 5.25
        },
        {
          "time": "2018-05-07T05:10:00.000+0000",
          "healthScore": 9.87,
          "packetLoss": 0.01,
          "latency": 14.07,
          "applicationDelay": 3.6
        },
        {
          "time": "2018-05-07T05:05:00.000+0000",
          "healthScore": 9.53,
          "packetLoss": 0.1,
          "latency": 21.4,
          "applicationDelay": 4.47
        },
        {
          "time": "2018-05-07T05:00:00.000+0000",
          "healthScore": 9.76,
          "packetLoss": 0.01,
          "latency": 32.62,
          "applicationDelay": 21.52
        },
        {
          "time": "2018-05-07T04:55:00.000+0000",
          "healthScore": 9.33,
          "packetLoss": 0.06,
          "latency": 20.17,
          "applicationDelay": 32.83
        },
        {
          "time": "2018-05-07T04:50:00.000+0000",
          "healthScore": 8.67,
          "packetLoss": 0.08,
          "latency": 78.17,
          "applicationDelay": 4.67
        },
        {
          "time": "2018-05-07T04:45:00.000+0000",
          "healthScore": 9.67,
          "packetLoss": 0.02,
          "latency": 26.83,
          "applicationDelay": 4.67
        },
        {
          "time": "2018-05-07T04:40:00.000+0000",
          "healthScore": 9.87,
          "packetLoss": 0.01,
          "latency": 13.2,
          "applicationDelay": 5.33
        },
        {
          "time": "2018-05-07T04:35:00.000+0000",
          "healthScore": 9.4,
          "packetLoss": 0.51,
          "latency": 14.2,
          "applicationDelay": 5.8
        },
        {
          "time": "2018-05-07T04:30:00.000+0000",
          "healthScore": 8.3,
          "packetLoss": 2.89,
          "latency": 21.39,
          "applicationDelay": 21.42
        },
        {
          "time": "2018-05-07T04:25:00.000+0000",
          "healthScore": 9.86,
          "packetLoss": 0.03,
          "latency": 13.64,
          "applicationDelay": 6.43
        },
        {
          "time": "2018-05-07T04:20:00.000+0000",
          "healthScore": 7.67,
          "packetLoss": 0.36,
          "latency": 51.5,
          "applicationDelay": 5.5
        },
        {
          "time": "2018-05-07T04:15:00.000+0000",
          "healthScore": 9.78,
          "packetLoss": 0.01,
          "latency": 26.11,
          "applicationDelay": 5.06
        },
        {
          "time": "2018-05-07T04:10:00.000+0000",
          "healthScore": 9.75,
          "packetLoss": 0.01,
          "latency": 26.25,
          "applicationDelay": 3.58
        },
        {
          "time": "2018-05-07T04:05:00.000+0000",
          "healthScore": 9.57,
          "packetLoss": 0.05,
          "latency": 13.57,
          "applicationDelay": 29.64
        },
        {
          "time": "2018-05-07T04:00:00.000+0000",
          "healthScore": 9.4,
          "packetLoss": 0.08,
          "latency": 51.07,
          "applicationDelay": 21
        },
        {
          "time": "2018-05-07T03:55:00.000+0000",
          "healthScore": 9.5,
          "packetLoss": 0.03,
          "latency": 51,
          "applicationDelay": 4.67
        },
        {
          "time": "2018-05-07T03:50:00.000+0000",
          "healthScore": 9.25,
          "packetLoss": 0.11,
          "latency": 22.83,
          "applicationDelay": 5.25
        },
        {
          "time": "2018-05-07T03:45:00.000+0000",
          "healthScore": 9.64,
          "packetLoss": 0.01,
          "latency": 32.43,
          "applicationDelay": 5.71
        },
        {
          "time": "2018-05-07T03:40:00.000+0000",
          "healthScore": 9.57,
          "packetLoss": 0.03,
          "latency": 23.86,
          "applicationDelay": 3.93
        },
        {
          "time": "2018-05-07T03:35:00.000+0000",
          "healthScore": 9.3,
          "packetLoss": 0.09,
          "latency": 15.3,
          "applicationDelay": 6.8
        },
        {
          "time": "2018-05-07T03:30:00.000+0000",
          "healthScore": 6.22,
          "packetLoss": 11.42,
          "latency": 22.28,
          "applicationDelay": 21.28
        },
        {
          "time": "2018-05-07T03:25:00.000+0000",
          "healthScore": 9.62,
          "packetLoss": 0.03,
          "latency": 18.52,
          "applicationDelay": 6.81
        },
        {
          "time": "2018-05-07T03:20:00.000+0000",
          "healthScore": 9.5,
          "packetLoss": 0.06,
          "latency": 11.07,
          "applicationDelay": 4.79
        },
        {
          "time": "2018-05-07T03:15:00.000+0000",
          "healthScore": 9.8,
          "packetLoss": 0.01,
          "latency": 2,
          "applicationDelay": 16.4
        },
        {
          "time": "2018-05-07T03:15:00.000+0000",
          "healthScore": 9.8,
          "packetLoss": 0.01,
          "latency": 2,
          "applicationDelay": 16.4
        },
        {
          "time": "2018-05-07T03:15:00.000+0000",
          "healthScore": 9.8,
          "packetLoss": 0.01,
          "latency": 2,
          "applicationDelay": 16.4
        }
      ]
    }

    });
