define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/SimulationUtility',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimNetworkDeviceData'
], function(SimLokiDatabaseActions,SimulationUtility, UtilityFunctions, SimTaskDataGenerator, SimNetworkDeviceData)
{
 var wlanTable = 'wlan';
//Function to fetch the rfprofile from the wlan table and adapt it to suit the intention of forming separate objects
function formRFWlanProfileAttribs(siteNameAttributes,keyValue)
{
      var  jObj = SimLokiDatabaseActions.getFilteredRecordHandler('wlan', {'key' : keyValue});
      var rfProfileObject;
      if( jObj  && jObj.length>0 && jObj[0].value)
      {
          var attribsArray,individualParam,individualArray;
          for(index=0;index<jObj[0].value.length;index++)
          {
            valueObj = jObj[0].value[index];
            attribsArray = [];
            for(var key in valueObj)
            {
              var value = valueObj[key];
              individualArray = {key:key,value:value};
              attribsArray.push(individualArray);
            }
            rfprofileKey = jObj[0].namespace+"."+jObj[0].key;
            rfProfileObject = {key:rfprofileKey,attribs:attribsArray};
            siteNameAttributes.push(rfProfileObject);
          }
      }
   }


//Function to fetch the commonsettings from the servers table and adapt it to suit the intention of forming separate objects
function formCommonSettingAttribs(urlAction,siteNameAttributes)
{
      var serversTable = "servers";
      var interfaceInfo = "interface.info";
        if(urlAction.service[2]!=undefined) {
             groupUuid = urlAction.service[2];
        } else {
             groupUuid = urlAction.action.id;
        }

       var attribsArray =[];
       var oldRecord = SimLokiDatabaseActions.getFilteredRecordHandler('servers', {'groupUuid': groupUuid});
       if(oldRecord)
       {
         var record= JSON.parse(JSON.stringify(oldRecord));
         var serverRecord,settingKey,individualArray;
         if(record[0].serverDetails)
         {
           for(j=0;j< record[0].serverDetails.length;j++) {
             var attribsArray;
             serverRecord = record[0].serverDetails[j];
             settingKey = serverRecord.namespace+"."+serverRecord.key;

             for(index=0;index<serverRecord.value.length;index++)
             {
               attribsArray=[];
                for(var serverKey in serverRecord.value[index])
                {
                  if( isNaN(serverKey))
                  {
                    var value = serverRecord.value[index][serverKey];
                    var word="";
                   // Separate the keys like ïpAddress,sharedSecret,etc to ip.address, shared.secret.. Remove uppercase and add dot.
                    var words=serverKey.match(/([A-Z]?[^A-Z]*)/g).slice(0,-1);
                    for(var secondIndex=0;secondIndex<words.length;secondIndex++)
                    {
                      word=word+"."+words[secondIndex];
                    }
                    word=word.toLowerCase().replace(/^\./, "");
                    individualArray = {key:word,value:value};
                    attribsArray.push(individualArray);
                  }
                  else
                  { // some settings like timezone.setting, dhcp server, etc has 0,1,... as keys.. To handle them, use type as key
                    word = serverRecord.type;
                    var value = serverRecord.value[index];
                    individualArray = {key:word,value:value};
                    attribsArray.push(individualArray);
                    break;
                  }

                }
                individualArray = {key:settingKey,attribs:attribsArray};
                siteNameAttributes.push(individualArray);
             }
           }
          }
       }
}

  function addRequiredAttribs (jObj) {
    var profileAttributes = jObj.profileAttributes;
    var isrv = 'vEdge', isOtpPresent = false, isUuidPresent = false, isSet = false;
    for(var i=0; i<profileAttributes.length; i++) {
      if(profileAttributes[i].key == 'nfv.device.name') {
        var attribs1 = profileAttributes[i].attribs;
        for(var j=0; j<attribs1.length; j++) {
          if(attribs1[j].key == 'nfv.isrv.name' || attribs1[j].key == 'nfv.vedge.name') {
            isrv = attribs1[j].value;
            var attribs2 = attribs1[j].attribs;
            if(isrv == 'vEdge') {
              for(var k=0; k<attribs2.length; k++) {
                if(attribs2[k].key == 'nfv.vedge.otp' && attribs2[k].value != "" && attribs2[k].value != undefined) {
                  isOtpPresent == true;
                }
                if(attribs2[k].key == 'nfv.vedge.uuid' && attribs2[k].value != "" && attribs2[k].value != undefined) {
                  isUuidPresent == true;
                }
              }
              if(!isOtpPresent) {
                var otpObj = {
                  "key": "nfv.vedge.otp",
                  "value": "e7b8c47e7e490bd2ed197bdb804bd6e3",
                  "attribs": []
                };
                jObj.profileAttributes[i].attribs[j].attribs.push(otpObj);
              }
              if(!isUuidPresent) {
                var uuidObj = {
                  "key": "nfv.vedge.uuid",
                  "value": "128f8487-cd5a-4ad5-b8a4-b8e83f2febd8",
                  "attribs": []
                };
                jObj.profileAttributes[i].attribs[j].attribs.push(uuidObj);
              }
              isSet = true;
              break;
            }
          }
        }
      }
      if(isSet) {break;}
    }
    return jObj;
  }

  function boolToString(profileAttr) {
    for(var i=0; i<profileAttr.length; i++) {
      if (profileAttr[i].hasOwnProperty("value") && !isString(profileAttr[i]["value"])) {
        profileAttr[i]["value"] = profileAttr[i]["value"].toString();
      } else if (profileAttr[i]["attribs"] != undefined) {
        for(var j=0; j<profileAttr[i]["attribs"].length; j++) {
          var attrLevel1 = profileAttr[i]["attribs"][j];
          if (attrLevel1.hasOwnProperty("value") && !isString(attrLevel1["value"])) {
            attrLevel1["value"] = attrLevel1["value"].toString();
          } else if (attrLevel1["attribs"] != undefined) {
            for(var k=0; k<attrLevel1["attribs"].length; k++) {
              if (attrLevel1["attribs"][k].hasOwnProperty("value") && !isString(attrLevel1["attribs"][k]["value"])) {
                attrLevel1["attribs"][k]["value"] = attrLevel1["attribs"][k]["value"].toString();
              }
            }
          }
          profileAttr[i]["attribs"][j] = attrLevel1;
        }
      }
    }
    return profileAttr;
  }

  function isString(property) {
    if (typeof property != "string") {
      return false;
    }
    return true;
  }

return {
        init: function () {
        },

        //To provision for a wireless device, the site needs to have the rfprofile, wlan, timezone, interface details.
        //This function handles this
        // @urlAction : Url param which has teh request details
        formProfileAttributes: function (urlAction,siteDetails,profileDetails) {
           var siteNameAttributes = [];
           var siteSettingKey = "site.settings";
           formRFWlanProfileAttribs(siteNameAttributes,'wlan.info');
           formRFWlanProfileAttribs(siteNameAttributes,'rfprofile.info');
           formCommonSettingAttribs(urlAction,siteNameAttributes);

           otherAttribs = [{key:"site.name",value:siteDetails.name,attribs:siteNameAttributes}];
           // Add the site Id as a profileAttribute

           var flag=false;
           for(index=0;index<profileDetails.profileAttributes.length;index++)
           {
             if(profileDetails.profileAttributes[index].key == "site.settings")
             {
               flag = true;
               var array={key:"site.uuid",value:siteDetails.uuid,attribs:otherAttribs};
               profileDetails.profileAttributes[index].attribs.push(array);
               break;
             }
           }
           if(flag==false)
           {
             var array=[{key:"site.uuid",value:siteDetails.uuid,attribs:otherAttribs}];
             var profileAttribsValue={key:siteSettingKey,attribs:array};
             profileDetails.profileAttributes.push(profileAttribsValue);
           }

        },

        addSiteAttributes: function (urlAction){
             var t = urlAction.url.split('/');
            var profileId = t[4], siteId = t[6];

            var siteData = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'id': siteId });
            if (siteData != undefined && siteData.length) {
               var childrenSites=[];
               SimulationUtility.getChildrenSites(siteData[0].id,childrenSites);
               childrenSites.push(siteData[0]);
               console.log(childrenSites);
               var profileData = SimLokiDatabaseActions.getFilteredRecordHandler('siteprofile', { 'id': profileId });
               for(var childSiteIndex in childrenSites)
                {
                    var siteType;
                    var childSite = childrenSites[childSiteIndex];
                    var siteId = childSite.id;
                    var isChildAlreadyAvailable = false;
                    if(profileData != undefined && profileData.length > 0)
                    {
                      for(var siteToCheck in profileData[0].sites)
                      {
                        if(profileData[0].sites[siteToCheck].uuid == siteId )
                        {
                          isChildAlreadyAvailable = true;
                          break;
                        }
                      }
                    }
                    if(isChildAlreadyAvailable == true)
                    {
                     continue;
                    }

                    isInheritedFlag = true;
                    //For all the children sites, set the flag as true.Only the parent site, should have the flag as false
                    if(siteData[0].id==siteId)
                    {
                        isInheritedFlag = false;
                    }
                    var siteTemp = {isInherited: isInheritedFlag, name: childSite.name, uuid: siteId };
                    //Get the updated profile every time, as we add the profile Attributes of child every time
                    profileData = SimLokiDatabaseActions.getFilteredRecordHandler('siteprofile', { 'id': profileId });
                    if (profileData != undefined && profileData.length > 0) {
                        var newprofile = JSON.parse(JSON.stringify(profileData[0]));
                        if(newprofile['sites'] == undefined) {
                            newprofile['sites'] = [siteTemp];
                        } else {
                            newprofile['sites'].push(siteTemp);
                        }
                        newprofile['siteCount'] = newprofile['sites'].length;
                        newprofile['siteName'] = siteData[0].name;

                        // Add the site Id,name,rfprofile,wlan,etc as  newprofile¨s profileAttributes to siteInfo.
                        this.formProfileAttributes(urlAction,siteTemp,newprofile);
                        newprofile.status = 'final';
                        SimLokiDatabaseActions.update('siteprofile', newprofile);
                    }
                }
            }
        },

        updateProfileAttributes: function (urlAction){

            if(urlAction.action.id != undefined){
                var id = urlAction.action.id;
                var jObj = SimLokiDatabaseActions.getFilteredRecordHandler('siteprofile', {'id': id});
                var reqObject = urlAction.restPayload.profileAttributes;

                jObj[0].profileAttributes=reqObject;
                console.log(jObj);
                SimLokiDatabaseActions.update('siteprofile', jObj);
            }
        },

        nfvProvision: function (urlAction, action) {
          if(action == "putNfv") {
            /* var id = urlAction.restPayload[0], obj = {};
            obj.networkDeviceId = id;
            var record = SimLokiDatabaseActions.getFilteredRecordHandler('nfv-provision-details', {'deviceId':id});
            var progressStmt = "Provisioning Notifications Sent for Network Profile(s): " + record[0].siteProfileName;
            var resultObj = SimTaskDataGenerator.createTask('SPService', {progress: progressStmt});
            SimNetworkDeviceData.updateDeviceConfigStatus(obj);//update aggregated status of provisioning
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false});
            resultObj.statusCode= 202;
            return resultObj;
          } else if(action == "postNfv") { */
            var resultObj = SimTaskDataGenerator.createTask('Network Design Service');
            let childTask = SimTaskDataGenerator.createChildTask('Network Design Service');
            var profileAttributes = urlAction.restPayload[0].profileAttributes;
            var deviceId = "";
            //can try handling recursively, it is possible..
            for(var i=0; i<profileAttributes.length; i++) {
              if(profileAttributes[i].key == "nfv.device.name") {
                for(var j=0; j<profileAttributes[i].attribs.length; j++) {
                  if(profileAttributes[i].attribs[j].key == "nfv.nfvis.deviceId" &&
                    (profileAttributes[i].attribs[j].value != undefined || profileAttributes[i].attribs[j].value != "") ){
                    deviceId = profileAttributes[i].attribs[j].value;
                    break;
                  }
                }
                if(deviceId != "") {break;}
              }
              if(deviceId == "" && profileAttributes[i].key == "site.id") {
                for(var j=0; j<profileAttributes[i].attribs.length; j++) {
                  if(profileAttributes[i].attribs[j].key == "device.list" &&
                    (profileAttributes[i].attribs[j].attribs[0].value != undefined || profileAttributes[i].attribs[j].attribs[0].value != "") ){
                    deviceId = profileAttributes[i].attribs[j].attribs[0].value;//key is device.id
                    break;
                  }
                }
                if(deviceId != "") {break;}
              }
            }
            var obj = {};
            obj.deviceId =deviceId;
            obj.siteProfileUuid = urlAction.restPayload[0].siteProfileUuid;
            obj.siteProfileName = urlAction.restPayload[0].name;
            obj.startTime = UtilityFunctions.getTimeStamp();
            obj.workflowId = UtilityFunctions.generate_uuid();
            SimLokiDatabaseActions.insert('nfv-provision-details', obj);
            //below code is to retain the data when provisioning the second time
            var records = SimLokiDatabaseActions.getFilteredRecordHandler('nfv-provision-data', {'deviceId': deviceId});
            var provisionDuration = 64000;//ms
            if(records && records.length){
              records[0].data.lastUpdatedDatetime = obj.startTime + provisionDuration;
              records[0].data.profileAttributes = boolToString(urlAction.restPayload[0].profileAttributes);
              //SimLokiDatabaseActions.update('siteprofile', records);
              SimLokiDatabaseActions.update('nfv-provision-data', records);
            } else {
              //update few fields similar to network profile creation
              urlAction.restPayload[0].lastUpdatedDatetime = obj.startTime + provisionDuration;
              urlAction.restPayload[0].profileAttributes = boolToString(urlAction.restPayload[0].profileAttributes);
              urlAction.restPayload[0].version = 1;
              urlAction.restPayload[0].namespace = 'nfv';
              var provObj = {deviceId:deviceId, data:urlAction.restPayload[0]};
              SimLokiDatabaseActions.insert('nfv-provision-data', provObj);
            }
            SimNetworkDeviceData.updateDeviceConfigStatus({'networkDeviceId': deviceId});//update aggregated status of provisioning
            SimTaskDataGenerator.updateTaskCompletion(childTask.taskId, {isError: false });
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
            resultObj.childTaskId = childTask.taskId;
            //resultObj.statusCode= 201;
            return resultObj;
          }
        },

        formNfvObj: function(jObj, deviceId) {
          //For NFV with siteprofileid and deviceId in the url, params will have the details of siteprofile
          //If already provisioned, pick that data
          var records = SimLokiDatabaseActions.getFilteredRecordHandler('nfv-provision-data', {'deviceId': deviceId});
          if(records && records.length){
            if(jObj.siteProfileUuid == records[0].data.siteProfileUuid && jObj.lastUpdatedDatetime<records[0].data.lastUpdatedDatetime) {
              //make sure the network profile is the same & it has not been updated after that. Only then update..
              jObj = records[0].data;
            }
          }
          var obj = {};
          obj.lastUpdatedDatetime = jObj.lastUpdatedDatetime;
          obj.deviceId = deviceId;
          obj.siteProfileVersion = jObj.version;
          obj.siteProfileUuid = jObj.siteProfileUuid;
          obj.instanceUuid = "512b37d0-c762-4064-9ee3-7f18264a2b41";
          obj.instanceTenantId = "5bb0145f841f5f004c767d53";
          obj.id = obj.instanceUuid;
          jObj = addRequiredAttribs(jObj);
          obj.params = JSON.stringify(jObj);
          console.log("Formatted response for NFV with device detail: "+ JSON.stringify(obj));
          return obj;
        }
    }
 }
 )
