define({
  "Template":
    [{
      "id": "1167ac65-33b4-43c5-b225-56116564ab7d",
      "instanceId": 4802802,
      "authEntityId": 4802802,
      "displayName": "0",
      "authEntityClass": 190389,
      "instanceTenantId": "5b161c0f04a351008902742f",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1537939224349,
      "deployed": true,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1537939224349,
      "name": "anomaly",
      "namespace": "anomaly Global Enable",
      "provisioningState": "DEPLOYED",
      "qualifier": "anomaly",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "airsense",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "isAll": "no",
      "isAllProtocol": "no",
      "isClientAnomalyDetectionPacketTrace": "no",
      "isDisable": "no",
      "isEnable": "yes",
      "isFilter": "no",
      "isFull": "no",
      "isGlobal": "yes",
      "siteId": "33bbd24d-7df6-4dd6-bd4e-ce8397345889"
    },
    {
      "id": "1167ac65-33b4-43c5-b225-56116564ab7d",
      "instanceId": 4802802,
      "authEntityId": 4802802,
      "displayName": "0",
      "authEntityClass": 190389,
      "instanceTenantId": "5b161c0f04a351008902742f",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1537939224349,
      "deployed": true,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1537939224349,
      "name": "anomaly",
      "namespace": "anomaly Global Enable",
      "provisioningState": "DEPLOYED",
      "qualifier": "anomaly",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "airsense",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "isAll": "no",
      "isAllProtocol": "no",
      "isClientAnomalyDetectionPacketTrace": "yes",
      "isDisable": "no",
      "isEnable": "yes",
      "isFilter": "no",
      "isFull": "no",
      "isGlobal": "yes",
      "siteId": "33bbd24d-7df6-4dd6-bd4e-ce8397345889"
    },

     {
      "id": "5a6cc946-2439-42a0-a7d4-acb24100db40",
      "instanceId": 4802975,
      "authEntityId": 4802975,
      "displayName": "0",
      "authEntityClass": 190389,
      "instanceTenantId": "5b161c0f04a351008902742f",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1539132965985,
      "deployed": true,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1539132965985,
      "name": "ap-statistic",
      "namespace": "ap-statistic radio Global Enable",
      "provisioningState": "DEPLOYED",
      "qualifier": "ap-statistic",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "airsense",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "apStatisticsFrequency": "30",
      "apStatisticsType": "radio",
      "isAll": "no",
      "isAllProtocol": "no",
      "isClientAnomalyDetectionPacketTrace": "no",
      "isDisable": "no",
      "isEnable": "yes",
      "isFilter": "no",
      "isFull": "no",
      "isGlobal": "yes",
      "siteId": "33bbd24d-7df6-4dd6-bd4e-ce8397345889"
    },
    {
      "id": "c2c472a1-457e-46ea-ad9e-758c473d6e1a",
      "instanceId": 4802974,
      "authEntityId": 4802974,
      "displayName": "0",
      "authEntityClass": 190389,
      "instanceTenantId": "5b161c0f04a351008902742f",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1539132955621,
      "deployed": true,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1539132955621,
      "name": "ap-statistic",
      "namespace": "ap-statistic wlan Global Enable",
      "provisioningState": "DEPLOYED",
      "qualifier": "ap-statistic",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "airsense",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "apStatisticsFrequency": "30",
      "apStatisticsType": "wlan",
      "isAll": "no",
      "isAllProtocol": "no",
      "isClientAnomalyDetectionPacketTrace": "no",
      "isDisable": "no",
      "isEnable": "yes",
      "isFilter": "no",
      "isFull": "no",
      "isGlobal": "yes",
      "siteId": "33bbd24d-7df6-4dd6-bd4e-ce8397345889"
    }],

    "AP_TEMPLATE": {
      "id": "47abcb95-86ef-4205-a713-8704ebe621d4",
      "instanceId": 692699,
      "authEntityId": 692699,
      "displayName": "0",
      "authEntityClass": 190389,
      "instanceTenantId": "5bf85c470f2f8f004c9aa43b",
      "deployPending": "NONE",
      "instanceVersion": 1,
      "createTime": 1543033166285,
      "deployed": true,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime": 1544166059427,
      "name": "ap-statistic",
      //"namespace": "f:ap-statistic ast:wlan s:AP4800-4 a:Enable op:CREATE",
      "namespace": "f:ap-statistic ast:<ApStatisticsType> s:<AP_Name> a:<State> op:<Operation>",
      "provisioningState": "DEPLOYED",
      "qualifier": "ap-statistic",
      "resourceVersion": 1,
      "targetIdList": [],
      "type": "airsense",
      "cfsChangeInfo": [],
      "customProvisions": [],
      "apName": "AP4800-4",
      "apStatisticsFrequency": "30",
      "apStatisticsType": "wlan",
      "isAll": "no",
      "isAllProtocol": "no",
      "isClientAnomalyDetectionPacketTrace": "no",
      "isDisable": "no",
      "isEnable": "yes",
      "isFilter": "no",
      "isFull": "no",
      "isGlobal": "no",
      "siteId": "8e57203b-f35c-4c41-871e-802179e0a9e5"
    }

});