define([], function(){
    return {
        sync: function(key, value) {
            // to get region from DNAC host name ex: https://dcloud-dna-sda-lon.cisco.com/?_open=ext
            let DNACHostName = window.location.host;
            var availableRegions = ['lon', 'rtp', 'sjc', 'sng'];
            var region = "";
            for (var i = 0; i < availableRegions.length; i++) {
                if(DNACHostName.toLowerCase().indexOf(availableRegions[i]) != -1){
                    region = availableRegions[i];
                    break;
                }
            }
            let iframe = document.getElementById("ise-sync-frame") || document.createElement("iframe");
            iframe.src = 'https://dcloud-ise-ctf-inst-xxx.cisco.com/simulation/sync';
            // ISE host name should be dcloud-ise-sim-inst-xxx.cisco.com
            if(iframe.src.toLowerCase().indexOf('dcloud') > -1) {
                iframe.src = iframe.src.replace("xxx", region)
            }
            iframe.name = "ise-sync-frame"
            iframe.id = "ise-sync-frame"
            document.body.appendChild(iframe);
            iframe.style.display="none"
            iframe.onload = ()=>iframe.contentWindow.postMessage({key:key, value:value},'*')
        }
    }
})