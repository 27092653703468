define({
    "Template":
    {
        "request": {
          "key": "espn-browsing",
          "type": "Application",
          "label": "espn-browsing",
          "collection": false,
          "componentContent": [
            {
              "component": "fusion",
              "additional": "b31203d3-36af-47ab-962b-f44a668637e7"
            }
          ]
        },
        "data": {
          "response": {
            "id": "b31203d3-36af-47ab-962b-f44a668637e7",
            "instanceId": 4424,
            "authEntityId": 4424,
            "displayName": "4424",
            "authEntityClass": -1909073334,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "createTime": 1528265512267,
            "deployed": false,
            "isSeeded": true,
            "isStale": false,
            "lastUpdateTime": 1528265512267,
            "name": "espn-browsing",
            "namespace": "scalablegroup:application",
            "provisioningState": "DEFINED",
            "qualifier": "application",
            "resourceVersion": 0,
            "targetIdList": [],
            "type": "scalablegroup",
            "cfsChangeInfo": [],
            "customProvisions": [],
            "scalableGroupExternalHandle": "espn-browsing",
            "scalableGroupType": "APPLICATION",
            "securityGroupTag": 0,
            "state": "ACTIVE",
            "vnAgnostic": true,
            "identitySource": {
              "id": "607e5f6a-d4bd-41e6-9861-bcd939c316b6",
              "instanceId": 10010,
              "authEntityId": 10010,
              "displayName": "10010",
              "authEntityClass": 1738953278,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "state": "INACTIVE",
              "type": "NBAR"
            },
            "indicativeNetworkIdentity": [],
            "networkApplications": [
              {
                "id": "1841bfc1-715e-4dc5-8257-eb492f6bfaaa",
                "instanceId": 11374,
                "authEntityId": 11374,
                "displayName": "11374",
                "authEntityClass": -217092956,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "appProtocol": "tcp/udp",
                "applicationGroup": "espn-group",
                "applicationType": "DEFAULT",
                "categoryId": "fb8d9520-2bd7-43aa-97b5-c202d09feec2",
                "createTime": 1528265512267,
                "encrypted": "true",
                "engineId": "13",
                "helpString": "ESPN related websites browsing and mobile applications",
                "ignoreConflict": false,
                "lastUpdateTime": 1528265512267,
                "longDescription": "ESPN is a global television network focused on sports-related programming. Espn-browsing protocol is used for accessing and browsing the network's websites and mobile applications for iPhone, iPad, Android and  WinRT. There is a separate protocol, espn-video, for the network's video streaming services.",
                "name": "espn-browsing",
                "nbarId": "1486",
                "p2pTechnology": "false",
                "popularity": 9,
                "rank": 65535,
                "references": "http://www.espn.com/",
                "selectorId": "551",
                "subCategory": "b57a227c-5cb5-43e7-a30c-527dc6d57358",
                "trafficClass": "TRANSACTIONAL_DATA",
                "tunnel": "false"
              }
            ],
            "networkIdentity": [
              {
                "id": "5cf574fe-32f4-40ea-b905-1b139a0af705",
                "instanceId": 13066,
                "authEntityId": 13066,
                "displayName": "13066",
                "authEntityClass": 124090504,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "ipv4Subnet": [],
                "ipv6Subnet": [],
                "lowerPort": 0,
                "ports": "53,5353",
                "protocol": "UDP",
                "upperPort": 0
              },
              {
                "id": "717e95cf-b170-49d0-8d99-6a6bee77b965",
                "instanceId": 13067,
                "authEntityId": 13067,
                "displayName": "13067",
                "authEntityClass": 124090504,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "ipv4Subnet": [],
                "ipv6Subnet": [],
                "lowerPort": 0,
                "ports": "53,80,443,5353",
                "protocol": "TCP",
                "upperPort": 0
              }
            ],
            "parentScalableGroup": {
              "idRef": "a431ac87-190b-4e81-bad0-3f8b1e0e1471"
            },
            "user": [],
            "userGroup": []
          }
        },
        "result": [
          {
            "bookID": {
              "namespace": "fusion",
              "name": "core",
              "version": "1.0.1"
            },
            "response": {
              "id": "b31203d3-36af-47ab-962b-f44a668637e7",
              "instanceId": 4424,
              "authEntityId": 4424,
              "displayName": "4424",
              "authEntityClass": -1909073334,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "createTime": 1528265512267,
              "deployed": false,
              "isSeeded": true,
              "isStale": false,
              "lastUpdateTime": 1528265512267,
              "name": "espn-browsing",
              "namespace": "scalablegroup:application",
              "provisioningState": "DEFINED",
              "qualifier": "application",
              "resourceVersion": 0,
              "targetIdList": [],
              "type": "scalablegroup",
              "cfsChangeInfo": [],
              "customProvisions": [],
              "scalableGroupExternalHandle": "espn-browsing",
              "scalableGroupType": "APPLICATION",
              "securityGroupTag": 0,
              "state": "ACTIVE",
              "vnAgnostic": true,
              "identitySource": {
                "id": "607e5f6a-d4bd-41e6-9861-bcd939c316b6",
                "instanceId": 10010,
                "authEntityId": 10010,
                "displayName": "10010",
                "authEntityClass": 1738953278,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "state": "INACTIVE",
                "type": "NBAR"
              },
              "indicativeNetworkIdentity": [],
              "networkApplications": [
                {
                  "id": "1841bfc1-715e-4dc5-8257-eb492f6bfaaa",
                  "instanceId": 11374,
                  "authEntityId": 11374,
                  "displayName": "11374",
                  "authEntityClass": -217092956,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "appProtocol": "tcp/udp",
                  "applicationGroup": "espn-group",
                  "applicationType": "DEFAULT",
                  "categoryId": "fb8d9520-2bd7-43aa-97b5-c202d09feec2",
                  "createTime": 1528265512267,
                  "encrypted": "true",
                  "engineId": "13",
                  "helpString": "ESPN related websites browsing and mobile applications",
                  "ignoreConflict": false,
                  "lastUpdateTime": 1528265512267,
                  "longDescription": "ESPN is a global television network focused on sports-related programming. Espn-browsing protocol is used for accessing and browsing the network's websites and mobile applications for iPhone, iPad, Android and  WinRT. There is a separate protocol, espn-video, for the network's video streaming services.",
                  "name": "espn-browsing",
                  "nbarId": "1486",
                  "p2pTechnology": "false",
                  "popularity": 9,
                  "rank": 65535,
                  "references": "http://www.espn.com/",
                  "selectorId": "551",
                  "subCategory": "b57a227c-5cb5-43e7-a30c-527dc6d57358",
                  "trafficClass": "TRANSACTIONAL_DATA",
                  "tunnel": "false"
                }
              ],
              "networkIdentity": [
                {
                  "id": "5cf574fe-32f4-40ea-b905-1b139a0af705",
                  "instanceId": 13066,
                  "authEntityId": 13066,
                  "displayName": "13066",
                  "authEntityClass": 124090504,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "ipv4Subnet": [],
                  "ipv6Subnet": [],
                  "lowerPort": 0,
                  "ports": "53,5353",
                  "protocol": "UDP",
                  "upperPort": 0
                },
                {
                  "id": "717e95cf-b170-49d0-8d99-6a6bee77b965",
                  "instanceId": 13067,
                  "authEntityId": 13067,
                  "displayName": "13067",
                  "authEntityClass": 124090504,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "ipv4Subnet": [],
                  "ipv6Subnet": [],
                  "lowerPort": 0,
                  "ports": "53,80,443,5353",
                  "protocol": "TCP",
                  "upperPort": 0
                }
              ],
              "parentScalableGroup": {
                "idRef": "a431ac87-190b-4e81-bad0-3f8b1e0e1471"
              },
              "user": [],
              "userGroup": []
            }
          }
        ]
      }
    });