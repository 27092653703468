define({
    "AuthProfile_Template": [
        {
      "siteProfileUuid": "55c4d9e1-7350-4333-a6d9-ab5eab79f20e",
      "id": "55c4d9e1-7350-4333-a6d9-ab5eab79f20e",
      "version": 1,
      "name": "Closed Authentication",
      "namespace": "authentication",
      "status": "draft",
      "lastUpdatedBy": "admin",
      "lastUpdatedDatetime": 1522791848627,
      "siteCount": 0,
       "profileAttributes": [
                              {
                                "key": "authentication.wired.dot1x_to_mab_fallback_tmout",
                                "value": "21"
                              },
                              {
                                "key": "authentication.wired.priority",
                                "value": "dot1x mab"
                              },
                              {
                                "key": "authentication.wired.hostmode",
                                "value": "multi_auth"
                              },
                              {
                                "key": "authentication.wired.wakeonlan",
                                "value": "false"
                              },
                              {
                                "key": "authentication.wired.webauth_bypass_map"
                              },
                              {
                                "key": "authentication.type",
                                "value": "wired.dot1x"
                              },
                              {
                                "key": "authentication.wired.order",
                                "value": "dot1x"
                              },
                              {
                                "key": "authentication.wired.deployment_mode",
                                "value": "closed"
                              }
                            ]
    },
    {
      "siteProfileUuid": "2de6409d-c39b-40bf-9366-1ba3427eb413",
      "id": "2de6409d-c39b-40bf-9366-1ba3427eb413",
      "version": 1,
      "name": "Low Impact",
      "namespace": "authentication",
      "status": "draft",
      "lastUpdatedBy": "admin",
      "lastUpdatedDatetime": 1522791848231,
      "siteCount": 0,
      "profileAttributes": [
                                                      {
                                                        "key": "authentication.type",
                                                        "value": "wired.easy_connect"
                                                      },
                                                      {
                                                        "key": "authentication.wired.order",
                                                        "value": "dot1x"
                                                      },
                                                      {
                                                        "key": "authentication.wired.deployment_mode",
                                                        "value": "open"
                                                      },
                                                      {
                                                        "key": "authentication.wired.dot1x_to_mab_fallback_tmout",
                                                        "value": "21"
                                                      },
                                                      {
                                                        "key": "authentication.wired.priority",
                                                        "value": "mab"
                                                      },
                                                      {
                                                        "key": "authentication.wired.hostmode",
                                                        "value": "multi_auth"
                                                      },
                                                      {
                                                        "key": "authentication.wired.webauth_bypass_map"
                                                      },
                                                      {
                                                        "key": "authentication.wired.wakeonlan",
                                                        "value": "false"
                  },
                  {
                    "key": "authentication.wired.preauth",
                    "attribs": [
                      {
                        "key": "authentication.wired.preauth.acl_description",
                        "value": ""
                      },
                      {
                        "key": "authentication.wired.preauth.acl_name",
                        "value": "IPV4_PRE_AUTH_ACL"
                      },
                      {
                        "key": "authentication.wired.preauth.contract",
                        "attribs": [
                          {
                            "key": "authentication.wired.preauth.contractentry",
                            "value": "1",
                            "attribs": [
                              {
                                "key": "authentication.wired.preauth.contract.action",
                                "value": "permit"
                              },
                              {
                                "key": "authentication.wired.preauth.contract.port",
                                "value": "bootpc"
                              },
                              {
                                "key": "authentication.wired.preauth.contract.protocol",
                                "value": "udp"
                              }
                            ]
                          },
                          {
                            "key": "authentication.wired.preauth.contractentry",
                            "value": "2",
                            "attribs": [
                              {
                                "key": "authentication.wired.preauth.contract.action",
                                "value": "permit"
                              },
                              {
                                "key": "authentication.wired.preauth.contract.port",
                                "value": "domain"
                              },
                              {
                                "key": "authentication.wired.preauth.contract.protocol",
                                "value": "udp"
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "key": "authentication.wired.preauth.default_action",
                        "value": "deny"
                      },
                      {
                        "key": "authentication.wired.preauth.enabled",
                        "value": "true"
                      }
                    ]
                  }
                ]
    },
    {
      "siteProfileUuid": "06546b5d-7cb2-4cf9-8380-5eb862fe7fa4",
      "id": "fe98c87c-840f-4e2f-a421-a4ba645e1892",
      "version": 1,
      "name": "No Authentication",
      "namespace": "authentication",
      "status": "draft",
      "lastUpdatedBy": "admin",
      "lastUpdatedDatetime": 1522791846843,
      "siteCount": 0
    },
    {
      "siteProfileUuid": "ae76c87c-840f-4e2f-a421-a4ba645e1892",
      "id": "ae76c87c-840f-4e2f-a421-a4ba645e1892",
      "version": 1,
      "name": "Open Authentication",
      "namespace": "authentication",
      "status": "draft",
      "lastUpdatedBy": "admin",
      "lastUpdatedDatetime": 1522791847966,
      "siteCount": 0,
      "profileAttributes": [
                              {
                                "key": "authentication.wired.order",
                                "value": "dot1x"
                              },
                              {
                                "key": "authentication.wired.deployment_mode",
                                "value": "open"
                              },
                              {
                                "key": "authentication.wired.dot1x_to_mab_fallback_tmout",
                                "value": "21"
                              },
                              {
                                "key": "authentication.wired.priority",
                                "value": "dot1x mab"
                              },
                              {
                                "key": "authentication.wired.hostmode",
                                "value": "multi_auth"
                              },
                              {
                                "key": "authentication.wired.wakeonlan",
                                "value": "false"
                              },
                              {
                                "key": "authentication.wired.webauth_bypass_map"
                              },
                              {
                                "key": "authentication.type",
                                "value": "wired.dot1x"
                              }
    ]
    }
    ]
});
