define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/SimulationUtility',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/PoeAnalytics/PoeSummaryTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/PoeAnalytics/KpiOper_Status',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/PoeAnalytics/KpiPower_Budget',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/PoeAnalytics/KpiPd_Class',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/PoeAnalytics/Poe_Insights',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/PoeAnalytics/KpiSwitch_Power_Budget',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/PoeAnalytics/AP_Power_Mode',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/PoeAnalytics/Port_Availability',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/PoeAnalytics/KpiType'

], function(SimLokiDatabaseActions, UtilityFunctions, SimulationUtility, PoeSummaryTemplate, KpiOper_Status, KpiPower_Budget, KpiPd_Class,
            Poe_Insights, KpiSwitch_Power_Budget,AP_Power_Mode,Port_Availability, KpiType){

  return {
       init: function(){
         let DeviceRecords = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceConfigStatus', {'type':
                             { '$in':['Cisco Catalyst 9300 Switch','Cisco Catalyst WS-C3850-12X48U-S Switch',
                             'Cisco Catalyst 9404R Switch','Cisco Catalyst 9300L Switch Stack']}});
         this.createPoeDetails(DeviceRecords);
       },

       createPoeDetails : function (DeviceRecords){
            DeviceRecords.map(recordObj => {
              let devSpecInterface = SimLokiDatabaseActions.getFilteredRecordHandler('device-interface',{'deviceId':recordObj.deviceId})
              let hostsConnected = SimLokiDatabaseActions.getFilteredRecordHandler('host',{'clientConnection':recordObj.hostname}) //[0]

              //if(hostsConnected.length>0){
                let siteId = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'id':recordObj.deviceId})[0].siteId;
                let siteRec = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'id': siteId });

                let switchDetails = {"deviceName":"","powerBudget":"","powerConsumed":"","powerRemaining":"","powerLoad":""};
                let switchPower,switchPowerInValue, power_Consumed, powerConsumedInValue,switchPowerConsumed, switchPowerRemaining, load;
                switchPower = getRandomIntForRangeValues(2200,8000); // string
                switchPowerInValue = parseFloat(switchPower);
                power_Consumed = ((switchPowerInValue <= 2200)? getRandomIntForRangeValues(1800,1900):
                                          (switchPowerInValue >= 2200 && switchPowerInValue < 3000) ? getRandomIntForRangeValues(300,800):
                                          (switchPowerInValue >= 3000 && switchPowerInValue < 4000) ? getRandomIntForRangeValues(2100,2900):
                                          (switchPowerInValue >= 4000 && switchPowerInValue < 5000) ? getRandomIntForRangeValues(3000,3975):
                                          (switchPowerInValue >= 5000 && switchPowerInValue < 6000) ? getRandomIntForRangeValues(4000,4950):
                                          (switchPowerInValue >= 6000 && switchPowerInValue < 7000) ? getRandomIntForRangeValues(5000,5980):
                                          (switchPowerInValue >= 7000 && switchPowerInValue <= 8000) ? getRandomIntForRangeValues(6000,6970):
                                          getRandomIntForRangeValues(300,400)) //string
                powerConsumedInValue =  parseFloat(power_Consumed);
                //to make sure atLeast one device has >80% Load;
                if(recordObj.hostname == "p1.edge1-sda1.local"){
                    switchPower = 2500.0;
                    power_Consumed = 350.0;
                    switchPowerInValue = 2500.0;
                    powerConsumedInValue = 350.0;
                }
                switchPowerConsumed = switchPowerInValue - powerConsumedInValue;
                switchPowerRemaining = switchPowerInValue - switchPowerConsumed;
                load = (switchPowerConsumed/switchPowerInValue*100).toFixed(1);
                 switchDetails.deviceName =  recordObj.hostname;
                 switchDetails.powerBudget = `${switchPower}W`
                 switchDetails.powerConsumed = `${switchPowerConsumed}W`
                 switchDetails.powerRemaining = `${switchPowerRemaining}W`;
                 switchDetails.powerLoad = `${load}`;


                let timestamp = UtilityFunctions.getTimeStamp();
                devSpecInterface.map(interfaceObj => {
                    let tJson = JSON.parse(JSON.stringify(PoeSummaryTemplate.Template));


                    tJson.SwitchPowerDetails = switchDetails;
                    tJson.intfName = interfaceObj.portName;
                    tJson.intfOperStatus = interfaceObj.adminStatus.toLowerCase();
                    tJson.connectedSwitchType = interfaceObj.series;
                    tJson.pdConnectedDeviceList[0] = ( hostsConnected.length >0 ) ? hostsConnected[0].id : "";//interfaceObj.macAddress; // client macAddress
                    tJson.pdConnectedSwitch = recordObj.hostname;
                    tJson.connectedSwitchUUID = recordObj.deviceId; //switchId
                    tJson.intfDescr = null;
                    tJson.pdClassSpare = "NONE";
                    tJson.fastPoEEnabled = false;
                    tJson.poeAdminStatus = "AUTO";
                    tJson.timestamp = timestamp;

                    tJson.pdDeviceName = (hostsConnected.length >0 ) ? hostsConnected[0].hostName : "";
                    tJson.pdDeviceType = (hostsConnected.length >0) ? hostsConnected[0].subType : "unknown"; //
                    tJson.pdLocation = (siteRec.length >0) ? siteRec[0].groupNameHierarchy : "";
                    let models,modelPicked;
                    if(tJson.pdDeviceType == 'unknown'){
                        models = ["IEEE PD","AIR-AP2802I-B-K9", "AIR-AP3802I-B-K9","IP Phone 6961","AIR-CAP2702E-B-K9",
                            "AIR-CAP3702I-F-K9","IP Phone 8841"]
                        let randModel =  Math.floor(Math.random()*(models.length));
                        modelPicked = models[randModel]
                    }else if(tJson.pdDeviceType == 'IP_PHONE'){
                        models = [" IP Phone 8861"," IP Phone 7975"," IP Phone 7945"," IP Phone 7941"," IP Phone 7965",
                        " IP Phone 7940", " IP Phone 7942", " IP Phone 7912", " IP Phone 7910"]
                       let randModel = Math.floor(Math.random()*(models.length));
                       modelPicked = models[randModel]
                    }else if(tJson.pdDeviceType == 'Microsoft-Workstation' || tJson.pdDeviceType == "Apple-Mackbook-Pro"){
                        models = ["DESKTOP-MJ901LO","LAPTOP","DESKTOP-VLU07OD","DESKTOP-MBNGUKF"]
                       let randModel = Math.floor(Math.random()*(models.length));
                       modelPicked = models[randModel]
                    }else if(tJson.pdDeviceType == 'VIDEO_SURVEILLANCE_IP_CAMERA'){
                        models = ["CIVS-IPC-4300","CIVS-IPC-4500","CIVS-IPC-4500E","CIVS-IPC-4300E"]
                       let randModel = Math.floor(Math.random()*(models.length));
                       modelPicked = models[randModel]
                    }else {
                        modelPicked = "";
                    }
                    tJson.pdDeviceModel = modelPicked;

                    let powerAdminMax = ["60.0W","30.0W","90.0W"];
                    tJson.pdPowerAdminMax = powerAdminMax[Math.floor(Math.random()*(powerAdminMax.length))];


                    if(interfaceObj.adminStatus.toLowerCase() === 'up'){
                        let ieeeClass = ["IEEE1","IEEE2","IEEE3","IEEE4","CISCO","IEEE8"];

                        tJson.pdClassSignal = ieeeClass[Math.floor(Math.random()*(ieeeClass.length))];

                        let powerBudget = (tJson.pdClassSignal === 'IEEE1')? getRandomIntForRangeValues(3,4) :
                                      (tJson.pdClassSignal == "IEEE2") ? getRandomIntForRangeValues(4,7) :
                                      (tJson.pdClassSignal == "IEEE3") ? getRandomIntForRangeValues(6,48):
                                      (tJson.pdClassSignal == "IEEE4") ? getRandomIntForRangeValues(9,30):
                                      (tJson.pdClassSignal == "CISCO") ? getRandomIntForRangeValues(6,15):
                                      (tJson.pdClassSignal == "IEEE8") ? "90.0":
                                      getRandomIntForRangeValues(6,15); // generated value eg:"5.0" because of using toFixed(1)
                        let powerBudgetInValue = parseFloat(powerBudget);
                        let powerConsumed = powerBudgetInValue - ((powerBudgetInValue < 4)? UtilityFunctions.getRandomIntForRangeValues(1,2):
                                                          (powerBudgetInValue > 4 && powerBudget < 8) ? UtilityFunctions.getRandomIntForRangeValues(3,4):
                                                          (powerBudgetInValue > 9 && powerBudget < 15) ? UtilityFunctions.getRandomIntForRangeValues(4,6):
                                                          (powerBudgetInValue > 15 && powerBudget < 30) ? UtilityFunctions.getRandomIntForRangeValues(5,7):
                                                          (powerBudgetInValue > 30 && powerBudget < 48) ? UtilityFunctions.getRandomIntForRangeValues(8,10):
                                                           UtilityFunctions.getRandomIntForRangeValues(1,2))
                        let powerRemaining = powerBudgetInValue - powerConsumed;
                        tJson.pdPowerBudget = `${powerBudget}W`;
                        tJson.pdPowerConsumed = `${powerConsumed}W`;
                        tJson.pdPowerRemaining =`${powerRemaining}W`;
                        tJson.ieeeCompliant = (recordObj.type === 'Cisco Catalyst 9300 Switch' ) ? "Yes" : "No";
                        tJson.poeOperStatus = "ON";
                        tJson.policingPoEEnabled = false;
                        tJson.upoePlusEnabled = (recordObj.type === 'Cisco Catalyst 9300 Switch' ) ? true : false;
                        tJson.perpetualPoEEnabled = (recordObj.type === 'Cisco Catalyst 9300 Switch' ) ? false : true;
                        //in reference cluster upoePlusEnabled is enabled for 9300(stack-N20) so enabling true for all 9300 in our simulation.

                    }else if(interfaceObj.adminStatus.toLowerCase() === 'down'){
                        let operSatus = ['OFF:POWER_DENY','OFF:PD_FAULTY','OFF:ERROR_DISABLED']
                        tJson.poeOperStatus = operSatus[Math.floor(Math.random()*(operSatus.length))]
                        tJson.pdClassSignal = "NONE";
                        tJson.pdPowerBudget = "0.0W";
                        tJson.pdPowerConsumed = "0.0W";
                        tJson.pdPowerRemaining = "0.0W";
                        tJson.policingPoEEnabled = true;
                        tJson.ieeeCompliant = "Not Applicable";
                        tJson.upoePlusEnabled = false;
                        tJson.perpetualPoEEnabled = false;
                    }
                    tJson.poeOperPriority = (tJson.pdClassSignal === "IEEE8") ? "PRIORITY-1" :"LOW";
                    tJson.switchSiteId = siteId;
                    SimLokiDatabaseActions.insert('poe-aggregated-data', tJson);
                })
             // }
            })
            console.log('Poe data is generated');
       },
       
       getPoeAnalyticsData : function(urlAction){
            if(urlAction.service.indexOf('distribution') > -1 ) {
                let poedata = SimLokiDatabaseActions.getAll('poe-aggregated-data'), deviceNames = '';
                if (urlAction.restPayload.kpi === 'AP_POWER_MODE') {
                    var apsdata = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'family': 'Unified AP' }); 
                    let tJson = JSON.parse(JSON.stringify(AP_Power_Mode.Template));
                    let currentTime = UtilityFunctions.getTimeStamp();
                    let time = UtilityFunctions.getGivenDateAndTimeInValidFormat(currentTime);
                    tJson.response.detailList.count = apsdata.length;

                    var fullyPoweredAps = apsdata.filter(item => {
                        return item.assuranceHealthScore > 6
                    })
                    tJson.response.detailList[0].nestedItems[0].count = fullyPoweredAps.length
                    var partiallyPoweredAps = apsdata.filter(item => {
                        return item.assuranceHealthScore <= 6
                    })
                    tJson.response.detailList[0].nestedItems[1].count = partiallyPoweredAps.length
                    tJson.response.detailList[0].timestamp = time;
                    tJson.response.detailList[0].nestedItems[0].timestamp = tJson.response.detailList[0].nestedItems[1].timestamp = time;
                    let oneJsonObj = [];
                    oneJsonObj.push(tJson.response.detailList[0])
                    if (urlAction.restPayload.queryType === "LATEST") {
                        return tJson;
                    } else if (urlAction.restPayload.queryType === "TREND") {
                        let trendData = generatePoeTrendData(urlAction, oneJsonObj[0]);
                        return { "version": "1.0", "response": { "detailList": trendData } }
                    } 
                    
                    // let siteId = (urlAction.restPayload.siteList.length > 0) ? urlAction.restPayload.siteList[0] : undefined;
                    //     let poePowerdata;
                    //     poePowerdata = SimLokiDatabaseActions.getAll('poe-aggregated-data');
                    //     if (siteId != undefined) {
                    //         poePowerdata = getPoeDataBasedOnSite(urlAction);
                    //     }
                        // if (poePowerdata.length > 0) {
                        //     let tJson = JSON.parse(JSON.stringify(AP_Power_Mode.Template));
                        //     let currentTime = UtilityFunctions.getTimeStamp()
                        //     let oneJsonObj = [];
                        //     let time = UtilityFunctions.getGivenDateAndTimeInValidFormat(currentTime);
                        //     tJson.response.detailList[0].timestamp = time;
                        //     //tJson.response.detailList[0].count = poePowerdata.length
                        //     let poePowerdata = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'family': 'Unified AP' }); 
                        //     let ApsCount = poePowerdata.length
                        //     tJson.response.detailList[0].nestedItems.map((itemList, index) => {
                        //         if (itemList.itemType == "Fully Powered") {
                        //             let FpCount = poePowerdata.filter(obj => obj.ApPowerMode == 'Fully Powered')
                        //             tJson.response.detailList[0].nestedItems[index].count == FpCount.length;
                        //             tJson.response.detailList[0].nestedItems[index].timestamp = new Date()
                        //         }
                        //         if (itemList.itemType == "Partially Powered") {
                        //             let PpCount = poePowerdata.filter(obj => obj.ApPowerMode == 'Partially Powered')
                        //             tJson.response.detailList[0].nestedItems[index].count = 8;
                        //             tJson.response.detailList[0].nestedItems[index].timestamp = new Date()
                        //         }
                        //     }) 
                        //     oneJsonObj.push(tJson.response.detailList[0])
                        //     if (urlAction.restPayload.queryType === "LATEST") {
                        //         return tJson;
                        //     } else if (urlAction.restPayload.queryType === "TREND") {
                        //         let trendData = generatePoeTrendData(urlAction, oneJsonObj[0]);
                        //         return { "version": "1.0", "response": { "detailList": trendData } }
                        //     }        
                        // } else return { "version": "1.0", "response": { "detailList": [] } }
                }
                // Below Code added to Handle to specific deivce AP4800 as per Satpal request in Guardian/Groot
                else if(urlAction.restPayload.kpi === "AP_POWER_DISTRIBUTION"){
                    if(urlAction.restPayload.idList && urlAction.restPayload.idList.length > 0){
                        let deviceId = urlAction.restPayload.idList[0];
                        let deviceDatas =  SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'id':deviceId});
                        deviceNames = deviceDatas[0].name    
                    }
                    if(deviceNames == 'AP4800'){
                        let siteId = (urlAction.restPayload.siteList.length > 0) ? urlAction.restPayload.siteList[0] : undefined;
                            let poePowerdata;
                            poePowerdata = SimLokiDatabaseActions.getAll('poe-aggregated-data');
                            if (siteId != undefined) {
                                poePowerdata = getPoeDataBasedOnSite(urlAction);
                            }
                            if (poePowerdata.length > 0) {
                                let tJson = JSON.parse(JSON.stringify(AP_Power_Mode.AP4800_Template));
                                let currentTime = UtilityFunctions.getTimeStamp()
                                let oneJsonObj = [];
                                let time = UtilityFunctions.getGivenDateAndTimeInValidFormat(currentTime);
                                tJson.response.detailList[0].timestamp = time;
                                //tJson.response.detailList[0].count = poePowerdata.length
                                let poePowerdata = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'family': 'Unified AP' }); 
                                let ApsCount = poePowerdata.length
                                tJson.response.detailList[0].nestedItems.map((itemList, index) => {
                                    if (itemList.itemType == "Consumed Power") {
                                        let FpCount = poePowerdata.filter(obj => obj.ApPowerMode == 'Consumed Power')
                                        tJson.response.detailList[0].nestedItems[index].count == FpCount.length;
                                        tJson.response.detailList[0].nestedItems[index].timestamp = new Date()
                                    }
                                    if (itemList.itemType == "Allocated Power") {
                                        let PpCount = poePowerdata.filter(obj => obj.ApPowerMode == 'Allocated Power')
                                        tJson.response.detailList[0].nestedItems[index].count = 8;
                                        tJson.response.detailList[0].nestedItems[index].timestamp = new Date()
                                    }
                                }) 
                                oneJsonObj.push(tJson.response.detailList[0])
                                if (urlAction.restPayload.queryType === "LATEST") {
                                    return tJson;
                                } else if (urlAction.restPayload.queryType === "TREND") {
                                    let trendData = generatePoeTrendData(urlAction, oneJsonObj[0]);
                                    trendData.map((item, index) => {
                                        item.nestedItems[0].count = UtilityFunctions.getRandomIntForRangeValues(9, 10);
                                        item.nestedItems[1].count = index > 125 ? 30 : 20;
                                    })
                                    return { "version": "1.0", "response": { "detailList": trendData } }
                                }        
                            } else return { "version": "1.0", "response": { "detailList": [] } }
                    }
                }                
                if(urlAction.restPayload.kpi === 'OPER_STATUS'){
                    let siteId = (urlAction.restPayload.siteList.length > 0)? urlAction.restPayload.siteList[0] : undefined;
                    let poeOperdata;
                    poeOperdata = SimLokiDatabaseActions.getAll('poe-aggregated-data');
                    if(siteId != undefined){
                        poeOperdata = getPoeDataBasedOnSite(urlAction);
                    }
                    if(poeOperdata.length > 0){
                         let tJson  = JSON.parse(JSON.stringify(KpiOper_Status.Template));
                         let currentTime = UtilityFunctions.getTimeStamp()
                         let oneJsonObj = [];
                         let time = UtilityFunctions.getGivenDateAndTimeInValidFormat(currentTime);
                         tJson.response.detailList[0].timestamp = time;
                         tJson.response.detailList[0].count = poeOperdata.length
                         tJson.response.detailList[0].nestedItems.map((itemList,index) => {
                            if(itemList.itemType == "POWER_STATE_ON"){
                               let onCount =  poeOperdata.filter(obj => obj.poeOperStatus == 'ON')
                                tJson.response.detailList[0].nestedItems[index].count = onCount.length;
                                tJson.response.detailList[0].nestedItems[index].timestamp = new Date()
                            }
                            if(itemList.itemType == "POWER_STATE_ERROR_DISABLE"){
                                let pdCount =  poeOperdata.filter(obj => obj.poeOperStatus == 'OFF:ERROR_DISABLED')
                                tJson.response.detailList[0].nestedItems[index].count = pdCount.length;
                                tJson.response.detailList[0].nestedItems[index].timestamp = new Date()
                            }
                            if(itemList.itemType == "POWER_STATE_FAULTY"){
                                let pfCount =  poeOperdata.filter(obj => obj.poeOperStatus == 'OFF:PD_FAULTY')
                                tJson.response.detailList[0].nestedItems[index].count = pfCount.length;
                                tJson.response.detailList[0].nestedItems[index].timestamp = new Date()
                            }
                            if(itemList.itemType == "POWER_STATE_POWER_DENY"){
                                let peCount =  poeOperdata.filter(obj => obj.poeOperStatus == 'OFF:POWER_DENY')
                                tJson.response.detailList[0].nestedItems[index].count = peCount.length;
                                tJson.response.detailList[0].nestedItems[index].timestamp = new Date()
                            }
                         })
                         oneJsonObj.push(tJson.response.detailList[0]);
                         if(urlAction.restPayload.queryType === "LATEST"){
                            return tJson;
                         }else if(urlAction.restPayload.queryType === "TREND"){
                            let trendData = generatePoeTrendData(urlAction,oneJsonObj[0]);
                            return {"version":"1.0","response":{"detailList": trendData}}
                         }
                    }else return {"version":"1.0","response":{"detailList":[]}}

                }
                else if(urlAction.restPayload.kpi === 'PD_POWER_BUDGET'){

                        let siteId = (urlAction.restPayload.siteList.length>0) ? urlAction.restPayload.siteList[0] : undefined;
                        let poeTotalData = SimLokiDatabaseActions.getAll('poe-aggregated-data');
                        let poeSpecificUpData;
                        poeSpecificUpData = poeTotalData.filter(rec => rec.poeOperStatus == 'ON');
                        if(siteId != undefined){
                            let poePBdata = getPoeDataBasedOnSite(urlAction);
                            poeSpecificUpData = poePBdata.filter(rec => rec.poeOperStatus == 'ON');
                        }
                        if(poeSpecificUpData.length>0){
                            let tJson = JSON.parse(JSON.stringify(KpiPower_Budget.Template));
                            let powerBudgetSpecific = poeSpecificUpData.map(obj => {
                                    if(typeof(obj.pdPowerBudget)=="string"){
                                        obj.pdPowerBudget = parseFloat(obj.pdPowerBudget.replace(/W/g,''));
                                    }
                                    return obj});  //to convert pdPowerBudget to numbersssssssss
                            let oneJsonObj = [];
                            tJson.response.detailList[0].timestamp = new Date();
                            tJson.response.detailList[0].count = poeSpecificUpData.length;
                            tJson.response.detailList[0].nestedItems.map((itemList,index) => {
                                if(itemList.itemType == "<0"){
                                    let zeroCount =  powerBudgetSpecific.filter(obj => obj.pdPowerBudget <= 0 );
                                    tJson.response.detailList[0].nestedItems[index].count = zeroCount.length;
                                    tJson.response.detailList[0].nestedItems[index].timestamp = new Date();
                                }
                                if(itemList.itemType == "0-4"){
                                    let rangeCount =  powerBudgetSpecific.filter(obj => obj.pdPowerBudget > 0 && obj.pdPowerBudget <= 4);
                                    tJson.response.detailList[0].nestedItems[index].count = rangeCount.length;
                                    tJson.response.detailList[0].nestedItems[index].timestamp = new Date();
                                }
                                if(itemList.itemType == ">4-15.4"){
                                    let rangeCount =  powerBudgetSpecific.filter(obj => obj.pdPowerBudget > 4 && obj.pdPowerBudget <= 15.4 );
                                    tJson.response.detailList[0].nestedItems[index].count = rangeCount.length;
                                    tJson.response.detailList[0].nestedItems[index].timestamp = new Date();
                                }
                                if(itemList.itemType == ">15.4-30"){
                                    let rangeCount =  powerBudgetSpecific.filter(obj => obj.pdPowerBudget > 15.4 && obj.pdPowerBudget <= 30 );
                                    tJson.response.detailList[0].nestedItems[index].count = rangeCount.length;
                                    tJson.response.detailList[0].nestedItems[index].timestamp = new Date();
                                }
                                if(itemList.itemType == ">30-60"){
                                    let rangeCount =  powerBudgetSpecific.filter(obj => obj.pdPowerBudget > 30 && obj.pdPowerBudget <= 60 );
                                    tJson.response.detailList[0].nestedItems[index].count = rangeCount.length;
                                    tJson.response.detailList[0].nestedItems[index].timestamp = new Date();
                                }
                                if(itemList.itemType == ">60-90"){
                                    let rangeCount =  powerBudgetSpecific.filter(obj => obj.pdPowerBudget > 60 && obj.pdPowerBudget <= 90 );
                                    tJson.response.detailList[0].nestedItems[index].count = rangeCount.length;
                                    tJson.response.detailList[0].nestedItems[index].timestamp = new Date();
                                }
                                if(itemList.itemType == ">90"){
                                    let rangeCount =  powerBudgetSpecific.filter(obj => obj.pdPowerBudget > 90 );
                                    tJson.response.detailList[0].nestedItems[index].count = rangeCount.length;
                                    tJson.response.detailList[0].nestedItems[index].timestamp = new Date();
                                }
                            })
                            oneJsonObj.push(tJson.response.detailList[0]);

                            if(urlAction.restPayload.queryType === "LATEST"){
                               return tJson;
                            }else if(urlAction.restPayload.queryType === "TREND"){
                                let trendData = generatePoeTrendData(urlAction,oneJsonObj[0]);
                                return {"version":"1.0","response":{"detailList": trendData}}
                            }
                        }else return {"version":"1.0","response":{"detailList":[]}}
                }
                else if(urlAction.restPayload.kpi ==='PD_CLASS'){
                    let SiteId = (urlAction.restPayload.siteList.length > 0) ? urlAction.restPayload.siteList[0] : undefined;
                    let poeUpdata;
                    poeUpdata = poedata.filter(rec => rec.poeOperStatus === 'ON');
                    if(SiteId != undefined){
                        let poePdClassData = getPoeDataBasedOnSite(urlAction);
                        poeUpdata = poePdClassData.filter(rec => rec.poeOperStatus === 'ON' );
                    }
                    if(poeUpdata.length>0){
                        let tJson = JSON.parse(JSON.stringify(KpiPd_Class.Template));
                        let oneJsonObj = [];
                        tJson.response.detailList[0].timestamp = new Date();
                        tJson.response.detailList[0].count = poeUpdata.length;
                        tJson.response.detailList[0].nestedItems.map((itemList,index) => {
                            let itemType = itemList.itemType;
                            let filter = poeUpdata.filter(obj => obj.pdClassSignal === itemType);
                            tJson.response.detailList[0].nestedItems[index].count = filter.length;
                            tJson.response.detailList[0].nestedItems[index].timestamp = new Date();
                        })
                        oneJsonObj.push(tJson.response.detailList[0])
                        if(urlAction.restPayload.queryType === "LATEST"){
                            return tJson;
                        }else if(urlAction.restPayload.queryType === "TREND"){
                            let trendData = generatePoeTrendData(urlAction,oneJsonObj[0]);
                            return {"version":"1.0","response":{"detailList": trendData}}
                        }
                    }else return {"version":"1.0","response":{"detailList":[]}}
                }
                else if(urlAction.restPayload.kpi === 'PD_TYPE'){
                    debugger;
                    let siteId = (urlAction.restPayload.siteList.length>0) ? urlAction.restPayload.siteList[0] : undefined;
                    let poeTotalData = SimLokiDatabaseActions.getAll('poe-aggregated-data');
                    let poeSpecificUpData;
                    poeSpecificUpData = poeTotalData.filter(rec => rec.poeOperStatus == 'ON');
                    if(siteId != undefined){
                        let poePBdata = getPoeDataBasedOnSite(urlAction);
                        poeSpecificUpData = poePBdata.filter(rec => rec.poeOperStatus == 'ON');
                    }
                    if(poeSpecificUpData.length>0){
                        let tJson = JSON.parse(JSON.stringify(KpiType.Template));
                        var devTypes = [...new Set(poeSpecificUpData.map(e=>e.pdDeviceType))];
                        devTypes.forEach(e=>{
                            var x = {"itemType":e,"timestamp":new Date(),"count":0};
                            tJson.response.detailList[0].nestedItems.push(x);
                        });
                        tJson.response.detailList[0].nestedItems.forEach(x => {
                            x.count = (poeSpecificUpData.filter(e => x.itemType == e.pdDeviceType)).length;
                        });                        
                        if(urlAction.restPayload.queryType === "LATEST"){
                           return tJson;
                        } else if(urlAction.restPayload.queryType === "TREND"){
                            let trendData = generatePoeTrendData(JSON.parse(JSON.stringify(tJson.response.detailList[0])));
                            return {"version":"1.0","response":{"detailList": trendData}}
                        }
                    } else return {"version":"1.0","response":{"detailList":[]}}
                }
                else if(urlAction.restPayload.kpi ==='PERPETUAL_POE'){
                    let SiteId = (urlAction.restPayload.siteList.length > 0) ? urlAction.restPayload.siteList[0] : undefined;
                    let poeUpdata;
                    poeUpdata = poedata.filter(rec => rec.poeOperStatus === 'ON');
                    if(SiteId != undefined){
                        let poePdPoeData = getPoeDataBasedOnSite(urlAction);
                        poeUpdata = poePdPoeData.filter(rec => rec.poeOperStatus === 'ON');
                    }
                    if(poeUpdata.length>0){
                        let tJson = JSON.parse(JSON.stringify(Poe_Insights.Template));
                            let wiredTypes = [...new Set(poeUpdata.map(obj => obj.pdDeviceType))];
                            let items = [];
                            wiredTypes.map(type => {
                                let pdDeviceTypes = poeUpdata.filter(obj => obj.pdDeviceType === type );
                                let perpPoeFalse = poeUpdata.filter(obj => (obj.perpetualPoEEnabled === false && obj.pdDeviceType === type));
                                let perpPoeTrue = poeUpdata.filter(obj => (obj.perpetualPoEEnabled === true && obj.pdDeviceType === type));
                                let nestedListObj = {"itemType": "","timestamp": "","count": 0,"nestedItems":[{"itemType":"0","timestamp":"","count":0},{"itemType":"1","timestamp":"","count":0}]}
                                nestedListObj.itemType = type;
                                nestedListObj.timestamp = new Date();
                                nestedListObj.count = pdDeviceTypes.length;
                                nestedListObj.nestedItems[0].itemType = "0"; // for false it's 0 ,true it's 1, we have only false condition
                                nestedListObj.nestedItems[0].timestamp = new Date();
                                nestedListObj.nestedItems[0].count = perpPoeFalse.length;
                                nestedListObj.nestedItems[1].itemType = "1"; // for false it's 0 ,true it's 1, we have only false condition
                                nestedListObj.nestedItems[1].timestamp = new Date();
                                nestedListObj.nestedItems[1].count = perpPoeTrue.length;
                                items.push(nestedListObj);
                            });
                            tJson.response.detailList[0].timestamp = new Date();
                            tJson.response.detailList[0].count = poeUpdata.length;
                            tJson.response.detailList[0].nestedItems = items;
                        if(urlAction.restPayload.queryType === "LATEST"){
                             return tJson;
                        }
                    }else return {"version":"1.0","response":{"detailList":[]}}
                }
                else if(urlAction.restPayload.kpi ==='FAST_POE'){
                    let SiteId = (urlAction.restPayload.siteList.length > 0) ? urlAction.restPayload.siteList[0] : undefined;
                    let poeUpdata;
                    poeUpdata = poedata.filter(rec => rec.poeOperStatus === 'ON');
                    if(SiteId != undefined){
                        let poePdFastData = getPoeDataBasedOnSite(urlAction);
                        poeUpdata = poePdFastData.filter(rec => rec.poeOperStatus === 'ON');
                    }
                    if(urlAction.restPayload.queryType === "LATEST"){
                        let tJson = JSON.parse(JSON.stringify(Poe_Insights.Template));
                        let wiredTypes = [...new Set(poeUpdata.map(obj => obj.pdDeviceType))];
                        let items = [];
                        wiredTypes.map(type => {
                            let pdDeviceTypes = poeUpdata.filter(obj => obj.pdDeviceType === type );
                            let fastPoeFalse = poeUpdata.filter(obj => obj.fastPoEEnabled === false && obj.pdDeviceType === type);
                            let nestedListObj = {"itemType": "","timestamp": "","count": 0,"nestedItems":[{"itemType":"0","timestamp":"","count":0}]};
                            nestedListObj.itemType = type;
                            nestedListObj.timestamp = new Date();
                            nestedListObj.count = pdDeviceTypes.length;
                            nestedListObj.nestedItems[0].itemType = "0";
                            nestedListObj.nestedItems[0].timestamp = new Date();
                            nestedListObj.nestedItems[0].count = fastPoeFalse.length;
                            items.push(nestedListObj);
                        })
                        tJson.response.detailList[0].timestamp = new Date();
                        tJson.response.detailList[0].count = poeUpdata.length;
                        tJson.response.detailList[0].nestedItems = items;
                        return tJson;
                    }
                }
                else if(urlAction.restPayload.kpi === "IEEE_COMPLIANT"){
                    let SiteId = (urlAction.restPayload.siteList.length > 0) ? urlAction.restPayload.siteList[0] : undefined;
                    let poeUpdata;
                    poeUpdata = poedata.filter(rec => rec.poeOperStatus === 'ON');
                    if(SiteId != undefined){
                        let poeIeeData = getPoeDataBasedOnSite(urlAction);
                        poeUpdata = poeIeeData.filter(rec => rec.poeOperStatus === 'ON' );
                    }
                    if(poeUpdata.length>0){
                        let tJson = JSON.parse(JSON.stringify(Poe_Insights.Template));
                        let wiredTypes = [...new Set(poeUpdata.map(obj => obj.pdDeviceType))];
                        let items = [];
                        wiredTypes.map(type => {
                            let pdDeviceTypes = poeUpdata.filter(obj => obj.pdDeviceType === type );
                            let ieeeCompliantYes = poeUpdata.filter(obj => obj.ieeeCompliant === "Yes" && obj.pdDeviceType === type);
                            let ieeeCompliantNo = poeUpdata.filter(obj => obj.ieeeCompliant === "No" && obj.pdDeviceType === type);
                            let nestedListObj = {"itemType": "","timestamp": "","count": 0,"nestedItems":[{"itemType":"1","timestamp":"","count":0},{"itemType":"2","timestamp":"","count":0}]};
                            nestedListObj.itemType = type;
                            nestedListObj.timestamp = new Date();
                            nestedListObj.count = pdDeviceTypes.length;
                            nestedListObj.nestedItems[0].itemType = "1";
                            nestedListObj.nestedItems[0].timestamp = new Date();
                            nestedListObj.nestedItems[0].count = ieeeCompliantYes.length;
                            nestedListObj.nestedItems[1].itemType = "2"; // for YES it's 1 ,NO it's 2
                            nestedListObj.nestedItems[1].timestamp = new Date();
                            nestedListObj.nestedItems[1].count = ieeeCompliantNo.length;
                            items.push(nestedListObj);
                        })
                        tJson.response.detailList[0].timestamp = new Date();
                        tJson.response.detailList[0].count = poeUpdata.length;
                        tJson.response.detailList[0].nestedItems = items;

                        if(urlAction.restPayload.queryType === "LATEST"){
                             return tJson;
                        }
                    }else return {"version":"1.0","response":{"detailList":[]}}
                }
                else if(urlAction.restPayload.kpi === "UPOE_PLUS"){
                    let SiteId = (urlAction.restPayload.siteList.length > 0) ? urlAction.restPayload.siteList[0] : undefined;
                    let poeUpdata;
                    poeUpdata = poedata.filter(rec => rec.poeOperStatus === 'ON');
                    if(SiteId != undefined){
                        let poeUpoeData = getPoeDataBasedOnSite(urlAction);
                        poeUpdata = poeUpoeData.filter(rec => rec.poeOperStatus === 'ON');
                    }
                    if(poeUpdata.length>0){
                        let tJson = JSON.parse(JSON.stringify(Poe_Insights.Template));
                        let wiredTypes = [...new Set(poeUpdata.map(obj => obj.pdDeviceType))];
                        let items = [];
                        wiredTypes.map(type => {
                            let pdDeviceTypes = poeUpdata.filter(obj => obj.pdDeviceType === type );  // eg filter only microsoftworkstation
                            //let upoePlusTrueNFalse = poeUpdata.filter(obj => obj.upoePlusEnabled === true && obj.pdDeviceType === type);
                            let upoePlusTrue = poeUpdata.filter(obj => obj.upoePlusEnabled === true && obj.pdDeviceType === type);
                            let upoePlusfalse = poeUpdata.filter(obj => obj.upoePlusEnabled === false && obj.pdDeviceType === type);
                            let nestedListObj = {"itemType": "","timestamp": "","count": 0,
                                                 "nestedItems":[{"itemType":"0","timestamp":"","count":0},
                                                 {"itemType":"1","timestamp":"","count":0}]};
                            nestedListObj.itemType = type;
                            nestedListObj.timestamp = new Date();
                            nestedListObj.count = pdDeviceTypes.length;
                            nestedListObj.nestedItems[0].itemType = "0";
                            nestedListObj.nestedItems[0].timestamp = new Date();
                            nestedListObj.nestedItems[0].count = upoePlusfalse.length;
                            nestedListObj.nestedItems[1].itemType = "1";
                            nestedListObj.nestedItems[1].timestamp = new Date();
                            nestedListObj.nestedItems[1].count = upoePlusTrue.length;
                            items.push(nestedListObj);
                        })
                        tJson.response.detailList[0].timestamp = new Date();
                        tJson.response.detailList[0].count = poeUpdata.length;
                        tJson.response.detailList[0].nestedItems = items;
                        if(urlAction.restPayload.queryType === "LATEST"){
                            return tJson;
                       }
                    }else return {"version":"1.0","response":{"detailList":[]}}
                }
                else if(urlAction.restPayload.kpi === "SWITCH_POWER_ALLOCATION"){
                    let SiteId = (urlAction.restPayload.siteList.length > 0) ? urlAction.restPayload.siteList[0] : undefined;
                    let poeUpdata;
                    poeUpdata = poedata.filter(rec => rec.poeOperStatus === 'ON');
                    if(SiteId != undefined){
                        let poeSwichPBData = getPoeDataBasedOnSite(urlAction);
                        poeUpdata = poeSwichPBData.filter(rec => rec.poeOperStatus === 'ON');
                    }
                    if(poeUpdata.length>0){
                        let result = [];poeUpdata.map(obj => {
                            if(obj != undefined){
                                result.push(obj.SwitchPowerDetails)
                            }
                        })
                        let SwichData = [], oneJsonObj = [];
                        const map = new Map();
                        for (const item of result){
                            if(!map.has(item.deviceName)){
                                map.set(item.deviceName,true);
                                SwichData.push({
                                     deviceName : item.deviceName,
                                     powerLoad : parseFloat(item.powerLoad),
                                     powerConsumed: parseFloat(item.powerConsumed),
                                     powerRemaining : parseFloat(item.powerRemaining)
                                });
                            }
                        }
                        let powerConsumed = 0, remaining = 0
                        powerConsumed = Math.floor(SwichData.reduce((tot,rec) => tot+rec.powerConsumed, 0))
                        remaining = Math.floor(SwichData.reduce((tot,rec) => tot+rec.powerRemaining, 0))
                        let tJson = JSON.parse(JSON.stringify(KpiPower_Budget.Power_Usage_Template));
                        tJson.response.detailList[0].timestamp = new Date();
                        tJson.response.detailList[0].count = powerConsumed+remaining
                        tJson.response.detailList[0].nestedItems[0].timestamp = new Date();
                        tJson.response.detailList[0].nestedItems[0].count = powerConsumed
                        tJson.response.detailList[0].nestedItems[1].timestamp = new Date();
                        tJson.response.detailList[0].nestedItems[1].count = remaining
                        oneJsonObj.push(tJson.response.detailList[0])
                        if(urlAction.restPayload.queryType === "LATEST"){
                            return tJson;
                        }else if(urlAction.restPayload.queryType === "TREND"){
                            let trendData = generatePoeTrendData(urlAction,oneJsonObj[0]);
                            return {"version":"1.0","response":{"detailList": trendData}}
                        }
                    }else return {"version":"1.0","response":{"detailList": []}}

                }
                 if (urlAction.restPayload.kpi === "PORT_AVAILABILITY") {
                    let siteId = (urlAction.restPayload.siteList.length > 0) ? urlAction.restPayload.siteList[0] : undefined;
                        let poePortdata;
                        poePortdata = SimLokiDatabaseActions.getAll('poe-aggregated-data');
                        if (siteId != undefined) {
                            poeOperdata = getPoeDataBasedOnSite(urlAction);
                        }
                        if (poePortdata.length > 0) {
                            let tJson = JSON.parse(JSON.stringify(Port_Availability.Template));
                            let currentTime = UtilityFunctions.getTimeStamp()
                            let oneJsonObj = [];
                            let time = UtilityFunctions.getGivenDateAndTimeInValidFormat(currentTime);
                            tJson.response.detailList[0].timestamp = time;
                            tJson.response.detailList[0].count = poePortdata.length
                            tJson.response.detailList[0].nestedItems.map((itemList, index) => {
                                if (itemList.itemType == "15.4 W PoE") {
                                    let WCount = poePortdata.filter(obj => obj.poePortStatus == '15.4 W')
                                    tJson.response.detailList[0].nestedItems[index].count = WCount.length;
                                    tJson.response.detailList[0].nestedItems[index].timestamp = new Date()
                                }
                                if (itemList.itemType == "30 W PoE") {
                                    let WCount = poePortdata.filter(obj => obj.poePortStatus == '30 W')
                                    tJson.response.detailList[0].nestedItems[index].count = WCount.length;
                                    tJson.response.detailList[0].nestedItems[index].timestamp = new Date()
                                }
                                if (itemList.itemType == "60 W PoE") {
                                    let WCount = poePortdata.filter(obj => obj.poePortStatus == '60 W')
                                    tJson.response.detailList[0].nestedItems[index].count = WCount.length;
                                    tJson.response.detailList[0].nestedItems[index].timestamp = new Date()
                                }
                                if (itemList.itemType == "90 W PoE") {
                                    let WCount = poePortdata.filter(obj => obj.poePortStatus == '90 W')
                                    tJson.response.detailList[0].nestedItems[index].count = WCount.length;
                                    tJson.response.detailList[0].nestedItems[index].timestamp = new Date()
                                }
                            })
                            oneJsonObj.push(tJson.response.detailList[0]);
                            if (urlAction.restPayload.queryType === "LATEST") {
                                return tJson;
                            } else if (urlAction.restPayload.queryType === "TREND") {
                                let trendData = generatePoeTrendData(urlAction, oneJsonObj[0]);
                                return { "version": "1.0", "response": { "detailList": trendData } }
                            }else return { "version": "1.0", "response": { "detailList": [] } }
                        }
                }
                else if(urlAction.restPayload.kpi === "SWITCH_POWER_BUDGET"){
                    let SiteId = (urlAction.restPayload.siteList.length > 0) ? urlAction.restPayload.siteList[0] : undefined;
                    let poeUpdata;
                    poeUpdata = poedata.filter(rec => rec.poeOperStatus === 'ON');
                    if(SiteId != undefined){
                        let poeSwichPBData = getPoeDataBasedOnSite(urlAction);
                        poeUpdata = poeSwichPBData.filter(rec => rec.poeOperStatus === 'ON');
                    }
                    if(poeUpdata.length>0){
                        let tJson = JSON.parse(JSON.stringify(KpiSwitch_Power_Budget.Template));
                        let switchNames = [...new Set(poeUpdata.map(obj => obj.pdConnectedSwitch))];
                        let result = [];poeUpdata.map(obj => {
                            if(obj != undefined){
                                result.push(obj.SwitchPowerDetails)
                            }
                        })
                        let SwichData = [], oneJsonObj = [];
                        const map = new Map();
                        for (const item of result){
                            if(!map.has(item.deviceName)){
                                map.set(item.deviceName,true);
                                SwichData.push({
                                 deviceName : item.deviceName,
                                 powerLoad : parseFloat(item.powerLoad)
                                });
                            }
                        }
                        tJson.response.detailList[0].timestamp = new Date();
                        tJson.response.detailList[0].count = switchNames.length;
                        tJson.response.detailList[0].nestedItems.map((itemList,index) => {
                            if(itemList.itemType == "<0"){

                                let zeroCount = SwichData.filter(obj => obj.powerLoad < 0)
                                tJson.response.detailList[0].nestedItems[index].count = zeroCount.length;
                                tJson.response.detailList[0].nestedItems[index].timestamp = new Date();
                            }
                            if(itemList.itemType == "0-20"){

                                let rangeCount = SwichData.filter(obj => obj.powerLoad > 0 && obj.powerLoad <= 20)
                                tJson.response.detailList[0].nestedItems[index].count = rangeCount.length;
                                tJson.response.detailList[0].nestedItems[index].timestamp = new Date();
                            }
                            if(itemList.itemType == ">20-40"){

                                let rangeCount = SwichData.filter(obj => obj.powerLoad > 20.0 && obj.powerLoad <= 40.0 )
                                tJson.response.detailList[0].nestedItems[index].count = rangeCount.length;
                                tJson.response.detailList[0].nestedItems[index].timestamp = new Date();
                            }
                            if(itemList.itemType == ">40-60"){

                                let rangeCount = SwichData.filter(obj => obj.powerLoad > 40.0 && obj.powerLoad <= 60.0)
                                tJson.response.detailList[0].nestedItems[index].count = rangeCount.length;
                                tJson.response.detailList[0].nestedItems[index].timestamp = new Date();
                            }
                            if(itemList.itemType == ">60-80"){

                                let rangeCount = SwichData.filter(obj => obj.powerLoad > 60.0 && obj.powerLoad <= 80.0)
                                tJson.response.detailList[0].nestedItems[index].count = rangeCount.length;
                                tJson.response.detailList[0].nestedItems[index].timestamp = new Date();
                            }
                            if(itemList.itemType == ">80"){

                                let rangeCount = SwichData.filter(obj => obj.powerLoad > 80)
                                tJson.response.detailList[0].nestedItems[index].count = rangeCount.length;
                                tJson.response.detailList[0].nestedItems[index].timestamp = new Date();
                            }

                        })
                        oneJsonObj.push(tJson.response.detailList[0])
                        if(urlAction.restPayload.queryType === "LATEST"){
                            return tJson;
                        }else if(urlAction.restPayload.queryType === "TREND"){
                            let trendData = generatePoeTrendData(urlAction,oneJsonObj[0]);
                            return {"version":"1.0","response":{"detailList": trendData}}
                        }
                    }else return {"version":"1.0","response":{"detailList": []}}
                }
            }else if(urlAction.service.indexOf('topncount') > -1 ){
                let chartList = urlAction.restPayload.chartList;
                let kpiAttr = (urlAction.restPayload.filters.kpi.length > 0)? urlAction.restPayload.filters.kpi[0].kpiAttr : undefined ;
                let SiteId = (urlAction.restPayload.siteList.length > 0) ? urlAction.restPayload.siteList[0] : undefined;
                let Poe_Up_Data;
                Poe_Up_Data = SimLokiDatabaseActions.getFilteredRecordHandler('poe-aggregated-data',{'poeOperStatus':'ON'});
                if(SiteId != undefined){
                        let poeSiteData = getPoeDataBasedOnSite(urlAction);
                        Poe_Up_Data = poeSiteData.filter(rec => rec.poeOperStatus === 'ON');
                }
                if(kpiAttr === 'OPER_STATUS' || kpiAttr == undefined){
                    let kpiValue = (urlAction.restPayload.filters.kpi.length > 0)?
                                        urlAction.restPayload.filters.kpi[0].condition[0].value[0]: undefined;
                    let kpiFilterValue  = (kpiValue != undefined && kpiValue === "POWER_STATE_POWER_DENY")? "OFF:POWER_DENY" :
                             (kpiValue != undefined && kpiValue === "POWER_STATE_FAULTY") ? "OFF:PD_FAULTY":
                             (kpiValue != undefined && kpiValue === "POWER_STATE_ERROR_DISABLE") ? "OFF:ERROR_DISABLED":
                             (kpiValue != undefined && kpiValue === "POWER_STATE_ON")? "ON": undefined
                    let poe_DATA;
                    if(SiteId != undefined && kpiFilterValue == undefined){
                        let poeSiteData = getPoeDataBasedOnSite(urlAction);
                        poe_DATA = [...poeSiteData]
                    }else if(SiteId != undefined && kpiFilterValue != undefined){
                        let poeSiteData = getPoeDataBasedOnSite(urlAction);
                        poe_DATA = poeSiteData.filter(rec => rec.poeOperStatus === kpiFilterValue );
                    }else if(kpiAttr != undefined && kpiValue != undefined && SiteId == undefined){ //In trend section kpiAttr =[], so checking for undefined condition
                        poe_DATA= SimLokiDatabaseActions.getFilteredRecordHandler('poe-aggregated-data',{'poeOperStatus':kpiFilterValue });
                    }else poe_DATA = SimLokiDatabaseActions.getAll('poe-aggregated-data');

                    let wiredTypes = getTopRecBasedOnKpiValue(poe_DATA,"pdDeviceType")
                    let uniqSiteLocs = getTopRecBasedOnKpiValue(poe_DATA,"pdLocation")
                    let switchTypes =  getTopRecBasedOnKpiValue(poe_DATA,"connectedSwitchType")
                    let switchNames = getTopRecBasedOnKpiValue(poe_DATA,"pdConnectedSwitch")
                    return {"version":"1.0","response":{"SITE_ID":uniqSiteLocs,"SWITCH_NAME":switchNames,
                                "SWITCH_MODEL":switchTypes,"PD_TYPE":wiredTypes}}
                }
                else if(kpiAttr === 'PD_POWER_BUDGET'){
                    let kpiValue0 = parseFloat(urlAction.restPayload.filters.kpi[0].condition[0].value[0]);
                    let kpiValue1 = parseFloat(urlAction.restPayload.filters.kpi[0].condition[1].value[0]);

                    let powerFilterData = Poe_Up_Data.filter(obj => obj.pdPowerBudget>kpiValue0 && obj.pdPowerBudget <= kpiValue1);
                    let powerBudget = getTopRecBasedOnKpiValue(powerFilterData,"pdPowerBudget");
                    let wiredTypes = getTopRecBasedOnKpiValue(powerFilterData,"pdDeviceType")
                    let uniqSiteLocs = getTopRecBasedOnKpiValue(powerFilterData,"pdLocation")
                    let switchTypes =  getTopRecBasedOnKpiValue(powerFilterData,"connectedSwitchType")
                    let switchNames = getTopRecBasedOnKpiValue(powerFilterData,"pdConnectedSwitch")
                    return {"version":"1.0","response":{"SITE_ID":uniqSiteLocs,"SWITCH_NAME":switchNames,
                                "SWITCH_MODEL":switchTypes,"PD_TYPE":wiredTypes,"PD_POWER_BUDGET":powerBudget}}
                }
                else if(kpiAttr === 'PD_CLASS'){
                    let kpiValue0 = urlAction.restPayload.filters.kpi[0].condition[0].value[0];

                    let poeTotalData = Poe_Up_Data.filter(obj => obj.pdClassSignal === kpiValue0);
                    let powerBudget = getTopRecBasedOnKpiValue(poeTotalData,"pdPowerBudget");
                    let wiredTypes = getTopRecBasedOnKpiValue(poeTotalData,"pdDeviceType")
                    let uniqSiteLocs = getTopRecBasedOnKpiValue(poeTotalData,"pdLocation")
                    let switchTypes =  getTopRecBasedOnKpiValue(poeTotalData,"connectedSwitchType")
                    let switchNames = getTopRecBasedOnKpiValue(poeTotalData,"pdConnectedSwitch")
                    return {"version":"1.0","response":{"SITE_ID":uniqSiteLocs,"SWITCH_NAME":switchNames,
                                "SWITCH_MODEL":switchTypes,"PD_TYPE":wiredTypes,"PD_POWER_BUDGET":powerBudget}}
                }else if(kpiAttr === 'PERPETUAL_POE'){
                    let kpiValue0 = urlAction.restPayload.filters.kpi[0].condition[0].value[0]; //PERPETUAL_POE false/True
                    let kpiValue1 = urlAction.restPayload.filters.kpi[1].condition[0].value[0]; //PERPETUAL_POE of ipPhone
                    kpiValue0 = (kpiValue0 == 0) ? false : true;

                    let poeTotalData = Poe_Up_Data.filter(obj => obj.perpetualPoEEnabled === kpiValue0 && obj.pdDeviceType == kpiValue1);
                    let uniqSiteLocs = getTopRecBasedOnKpiValue(poeTotalData,"pdLocation");
                    let switchTypes =  getTopRecBasedOnKpiValue(poeTotalData,"connectedSwitchType");
                    let switchNames = getTopRecBasedOnKpiValue(poeTotalData,"pdConnectedSwitch");
                    return {"version":"1.0","response":{"SITE_ID":uniqSiteLocs,"SWITCH_NAME":switchNames,
                                "SWITCH_MODEL":switchTypes}}
                }else if(kpiAttr === 'FAST_POE'){
                    let kpiValue0 = urlAction.restPayload.filters.kpi[0].condition[0].value[0]; //fastPOE false/True
                    let kpiValue1 = urlAction.restPayload.filters.kpi[1].condition[0].value[0]; //fastPOE of ipPhone
                    kpiValue0 = (kpiValue0 == 0) ? false : true;

                    let poeTotalData = Poe_Up_Data.filter(obj => obj.fastPoEEnabled === kpiValue0 && obj.pdDeviceType == kpiValue1);
                    let uniqSiteLocs = getTopRecBasedOnKpiValue(poeTotalData,"pdLocation");
                    let switchTypes =  getTopRecBasedOnKpiValue(poeTotalData,"connectedSwitchType");
                    let switchNames = getTopRecBasedOnKpiValue(poeTotalData,"pdConnectedSwitch");
                    return {"version":"1.0","response":{"SITE_ID":uniqSiteLocs,"SWITCH_NAME":switchNames,
                                "SWITCH_MODEL":switchTypes}}
                }else if(kpiAttr === 'IEEE_COMPLIANT'){
                    let kpiValue0 = urlAction.restPayload.filters.kpi[0].condition[0].value[0]; //fastPOE false/True
                    let kpiValue1 = urlAction.restPayload.filters.kpi[1].condition[0].value[0]; //fastPOE of ipPhone
                    kpiValue0 = (kpiValue0 == 0) ? "No" : "Yes";

                    let poeTotalData = Poe_Up_Data.filter(obj => obj.ieeeCompliant === kpiValue0 && obj.pdDeviceType == kpiValue1);
                    let uniqSiteLocs = getTopRecBasedOnKpiValue(poeTotalData,"pdLocation");
                    let switchTypes =  getTopRecBasedOnKpiValue(poeTotalData,"connectedSwitchType");
                    let switchNames = getTopRecBasedOnKpiValue(poeTotalData,"pdConnectedSwitch");
                    return {"version":"1.0","response":{"SITE_ID":uniqSiteLocs,"SWITCH_NAME":switchNames,
                                "SWITCH_MODEL":switchTypes}}
                }else if(kpiAttr === 'UPOE_PLUS'){
                    let kpiValue0 = urlAction.restPayload.filters.kpi[0].condition[0].value[0]; //UPOE_PLUS false/True
                    let kpiValue1 = urlAction.restPayload.filters.kpi[1].condition[0].value[0]; //UPOE_PLUS of ipPhone
                    kpiValue0 = (kpiValue0 == 0) ? false: true;

                    let poeTotalData = Poe_Up_Data.filter(obj => obj.upoePlusEnabled === kpiValue0 && obj.pdDeviceType === kpiValue1);
                    let uniqSiteLocs = getTopRecBasedOnKpiValue(poeTotalData,"pdLocation");
                    let switchTypes =  getTopRecBasedOnKpiValue(poeTotalData,"connectedSwitchType");
                    let switchNames = getTopRecBasedOnKpiValue(poeTotalData,"pdConnectedSwitch");
                    return {"version":"1.0","response":{"SITE_ID":uniqSiteLocs,"SWITCH_NAME":switchNames,
                                "SWITCH_MODEL":switchTypes}}
                }else if(kpiAttr === 'SWITCH_POWER_BUDGET'){

                    let SwitchFilter = getSwitchPowerDetails(urlAction,Poe_Up_Data);

                    let uniqSiteLocs = getTopRecBasedOnKpiValue(SwitchFilter,"site");
                    let switchTypes =  getTopRecBasedOnKpiValue(SwitchFilter,"deviceType");
                    let switchNames = getTopRecBasedOnKpiValue(SwitchFilter,"deviceName");
                    return {"version":"1.0","response":{"SITE_ID":uniqSiteLocs,"SWITCH_NAME":switchNames,
                                "SWITCH_MODEL":switchTypes}}
                }else if(kpiAttr === 'SWITCH_POWER_USAGE'){
                    let SwitchFilter = getSwitchPowerDetails(urlAction,Poe_Up_Data);

                    let uniqSiteLocs = getTopRecBasedOnKpiValue(SwitchFilter,"site");
                    let switchTypes =  getTopRecBasedOnKpiValue(SwitchFilter,"deviceType");
                    let switchPowerLoad = getTopPowerLoad(SwitchFilter)

                    return {"version":"1.0","response":{"SITE_ID":uniqSiteLocs,"SWITCH_MODEL":switchTypes,"SWITCH_POWER_LOAD":switchPowerLoad}}
                } else if (kpiAttr === 'AP_POWER_MODE') {
                    let apsdata = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'family': 'Unified AP' });
                    let filtered_APs = undefined;
                    if (urlAction.restPayload.filters.kpi[0].condition[0].value[0] == "FULLY_POWERED") {
                        filtered_APs = apsdata.filter(item => {
                            return item.assuranceHealthScore > 6
                        })
                    } else if (urlAction.restPayload.filters.kpi[0].condition[0].value[0] == "PARTIALLY_POWERED") {
                        filtered_APs = apsdata.filter(item => {
                            return item.assuranceHealthScore <= 6
                        })
                    }
                    var site_response = [];
                    const siteIds = new Set();
                    
                    filtered_APs.map((AP) => {
                        siteIds.add(AP.siteId)
                    })
                    
                    siteIds.forEach ((id) => {
                        var sitesArray = filtered_APs.filter(item => {
                            return item.siteId == id
                        })
                        let sitedata = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'id': id });
                        var res_obj = {
                            "id": id,
                            "name": sitedata[0].groupNameHierarchy,
                            "count": sitesArray.length
                        }
                        site_response.push(res_obj)
                    })
                    return {
                        "version": "1.0",
                        "response": {
                            "SITE_ID": site_response
                        }
                    }                 
                }
                else return {"version":"1.0","response":{}}

            }else if(urlAction.service.indexOf('pd-data') > -1 ){
                let kpiAttr = (urlAction.restPayload.filters.kpi.length > 0)? urlAction.restPayload.filters.kpi[0].kpiAttr : undefined ;
                let SiteId = (urlAction.restPayload.siteList.length > 0) ? urlAction.restPayload.siteList[0] : undefined;
                let Poe_Up_Data;
                Poe_Up_Data = SimLokiDatabaseActions.getFilteredRecordHandler('poe-aggregated-data',{'poeOperStatus':'ON'});
                if(SiteId != undefined){
                        let poeSiteData = getPoeDataBasedOnSite(urlAction);
                        Poe_Up_Data = poeSiteData.filter(rec => rec.poeOperStatus === 'ON');
                }
                if(kpiAttr === 'OPER_STATUS' || kpiAttr == undefined){ //for OPER_STATUS data will be based on ON/OFF
                    let kpiValue = (urlAction.restPayload.filters.kpi.length > 0)?
                                        urlAction.restPayload.filters.kpi[0].condition[0].value[0]: undefined;
                    let kpiFilterValue  = (kpiValue != undefined && kpiValue === "POWER_STATE_POWER_DENY")? "OFF:POWER_DENY" :
                             (kpiValue != undefined && kpiValue === "POWER_STATE_FAULTY") ? "OFF:PD_FAULTY":
                             (kpiValue != undefined && kpiValue === "POWER_STATE_ERROR_DISABLE") ? "OFF:ERROR_DISABLED":
                             (kpiValue != undefined && kpiValue === "POWER_STATE_ON")? "ON": undefined
                    let poe_DATA;
                    if(SiteId != undefined && kpiFilterValue != undefined){
                        let poeSiteData = getPoeDataBasedOnSite(urlAction);
                        poe_DATA = poeSiteData.filter(rec => rec.poeOperStatus === kpiFilterValue );
                    }else if(SiteId != undefined && kpiFilterValue == undefined){
                        let poeSiteData = getPoeDataBasedOnSite(urlAction);
                        poe_DATA = [...poeSiteData]
                    }else if(kpiAttr != undefined && kpiValue != undefined){ //In trend section kpiAttr =[], so checking for undefined condition
                        poe_DATA= SimLokiDatabaseActions.getFilteredRecordHandler('poe-aggregated-data',{'poeOperStatus':kpiFilterValue });
                    }else poe_DATA = SimLokiDatabaseActions.getAll('poe-aggregated-data');

                    if(urlAction.restPayload.filters.kpi.length> 1){
                        let filteredData
                        filteredData = getFilterBasedOnKpi(urlAction,poe_DATA,1)
                        return {"version":"1.0","response":filteredData,
                        "page":{"pageSize":filteredData.length,"offset":0,"totalCount":filteredData.length,"nextKey":null,"topN":null},
                        "downCount":null};
                    }else return {"version":"1.0","response":poe_DATA,
                        "page":{"pageSize":poe_DATA.length,"offset":0,"totalCount":poe_DATA.length,"nextKey":null,"topN":null},
                        "downCount":null};
                }
                else if(kpiAttr === 'PD_CLASS'){
                    let kpiValue0 = urlAction.restPayload.filters.kpi[0].condition[0].value[0];
                    let poeTotalData = Poe_Up_Data.filter(obj => obj.pdClassSignal === kpiValue0);
                    if(urlAction.restPayload.filters.kpi.length> 2){
                        let filteredData = getFilterBasedOnKpi(urlAction,poeTotalData,2)
                        return {"version":"1.0","response":filteredData,
                        "page":{"pageSize":filteredData.length,"offset":0,"totalCount":filteredData.length,"nextKey":null,"topN":null},
                        "downCount":null};

                    }else return {"version":"1.0","response":poeTotalData,
                        "page":{"pageSize":poeTotalData.length,"offset":0,"totalCount":poeTotalData.length,"nextKey":null,"topN":null},
                        "downCount":null};
                }else if(kpiAttr === 'PD_POWER_BUDGET'){
                    let kpiValue0 = parseFloat(urlAction.restPayload.filters.kpi[0].condition[0].value[0]);
                    let kpiValue1 = parseFloat(urlAction.restPayload.filters.kpi[0].condition[1].value[0]);

                    let powerFilterData = Poe_Up_Data.filter(obj => obj.pdPowerBudget>kpiValue0 && obj.pdPowerBudget <= kpiValue1);
                    if(urlAction.restPayload.filters.kpi.length> 2){
                        let filteredData = getFilterBasedOnKpi(urlAction,powerFilterData,2)
                        return {"version":"1.0","response":filteredData,
                        "page":{"pageSize":filteredData.length,"offset":0,"totalCount":filteredData.length,"nextKey":null,"topN":null},
                        "downCount":null};
                    }else return {"version":"1.0","response":powerFilterData,
                        "page":{"pageSize":powerFilterData.length,"offset":0,"totalCount":powerFilterData.length,"nextKey":null,"topN":null},
                        "downCount":null};
                }else if(kpiAttr === 'PERPETUAL_POE'){
                    let kpiValue0 = urlAction.restPayload.filters.kpi[0].condition[0].value[0]; //PERPETUAL_POE false/True
                    let kpiValue1 = urlAction.restPayload.filters.kpi[1].condition[0].value[0]; //PERPETUAL_POE of ipPhone
                    kpiValue0 = (kpiValue0 == 0) ? false : true;

                    let powerFilterData = Poe_Up_Data.filter(obj => obj.perpetualPoEEnabled === kpiValue0 && obj.pdDeviceType == kpiValue1);

                    if(urlAction.restPayload.filters.kpi.length> 3){
                        let filteredData = getFilterBasedOnKpi(urlAction,powerFilterData,3)
                        return {"version":"1.0","response":filteredData,
                        "page":{"pageSize":filteredData.length,"offset":0,"totalCount":filteredData.length,"nextKey":null,"topN":null},
                        "downCount":null};
                    }else return {"version":"1.0","response":powerFilterData,
                        "page":{"pageSize":powerFilterData.length,"offset":0,"totalCount":powerFilterData.length,"nextKey":null,"topN":null},
                        "downCount":null};
                }else if(kpiAttr === 'FAST_POE'){
                    let kpiValue0 = urlAction.restPayload.filters.kpi[0].condition[0].value[0]; //fastPOE false/True
                    let kpiValue1 = urlAction.restPayload.filters.kpi[1].condition[0].value[0]; //fastPOE of ipPhone
                    kpiValue0 = (kpiValue0 == 0) ? false : true;

                    let powerFilterData = Poe_Up_Data.filter(obj => obj.fastPoEEnabled === kpiValue0 && obj.pdDeviceType == kpiValue1);

                    if(urlAction.restPayload.filters.kpi.length> 3){
                        let filteredData = getFilterBasedOnKpi(urlAction,powerFilterData,3)
                        return {"version":"1.0","response":filteredData,
                        "page":{"pageSize":filteredData.length,"offset":0,"totalCount":filteredData.length,"nextKey":null,"topN":null},
                        "downCount":null};
                    }else return {"version":"1.0","response":powerFilterData,
                        "page":{"pageSize":powerFilterData.length,"offset":0,"totalCount":powerFilterData.length,"nextKey":null,"topN":null},
                        "downCount":null};
                }else if(kpiAttr === 'IEEE_COMPLIANT'){
                    let kpiValue0 = urlAction.restPayload.filters.kpi[0].condition[0].value[0]; //fastPOE false/True
                    let kpiValue1 = urlAction.restPayload.filters.kpi[1].condition[0].value[0]; //fastPOE of ipPhone
                    kpiValue0 = (kpiValue0 == 0) ? "Not Applicable" : "Yes";

                    let powerFilterData = Poe_Up_Data.filter(obj => obj.ieeeCompliant === kpiValue0 && obj.pdDeviceType == kpiValue1);

                    if(urlAction.restPayload.filters.kpi.length> 3){
                        let filteredData = getFilterBasedOnKpi(urlAction,powerFilterData,3)
                        return {"version":"1.0","response":filteredData,
                        "page":{"pageSize":filteredData.length,"offset":0,"totalCount":filteredData.length,"nextKey":null,"topN":null},
                        "downCount":null};
                    }else return {"version":"1.0","response":powerFilterData,
                        "page":{"pageSize":powerFilterData.length,"offset":0,"totalCount":powerFilterData.length,"nextKey":null,"topN":null},
                        "downCount":null};
                }else if(kpiAttr === 'UPOE_PLUS'){
                    let kpiValue0 = urlAction.restPayload.filters.kpi[0].condition[0].value[0]; //UPOE_PLUS false/True
                    let kpiValue1 = urlAction.restPayload.filters.kpi[1].condition[0].value[0]; //UPOE_PLUS of ipPhone
                    kpiValue0 = (kpiValue0 == 0) ? false: true;

                    let powerFilterData = Poe_Up_Data.filter(obj => obj.upoePlusEnabled === kpiValue0 && obj.pdDeviceType === kpiValue1);

                    if(urlAction.restPayload.filters.kpi.length> 3){
                        let filteredData = getFilterBasedOnKpi(urlAction,powerFilterData,3)
                        return {"version":"1.0","response":filteredData,
                        "page":{"pageSize":filteredData.length,"offset":0,"totalCount":filteredData.length,"nextKey":null,"topN":null},
                        "downCount":null};
                    }else return {"version":"1.0","response":powerFilterData,
                        "page":{"pageSize":powerFilterData.length,"offset":0,"totalCount":powerFilterData.length,"nextKey":null,"topN":null},
                        "downCount":null};
                }else return {"version":"1.0","response":[],
                        "page":{"pageSize":0,"offset":0,"totalCount":0,"nextKey":null,"topN":null},
                        "downCount":null};

            }else if(urlAction.service.indexOf('switch-data') > -1){
                let SiteId = (urlAction.restPayload.siteList.length > 0) ? urlAction.restPayload.siteList[0] : undefined;
                let Poe_Up_Data;
                Poe_Up_Data = SimLokiDatabaseActions.getFilteredRecordHandler('poe-aggregated-data',{'poeOperStatus':'ON'});
                if(SiteId != undefined){
                        let poeSiteData = getPoeDataBasedOnSite(urlAction);
                        Poe_Up_Data = poeSiteData.filter(rec => rec.poeOperStatus === 'ON');
                }
                //get unique records based on switch name;
                let SwitchData = getSwitchPowerDetails(urlAction,Poe_Up_Data);
                if(urlAction.restPayload.filters.kpi.length> 1){
                    let kpiFilter = urlAction.restPayload.filters.kpi
                    let kpi_array = [], returnArr = []
                    for(let i=1;i<kpiFilter.length; i++){
                        let obj = {'kpikey':'','kpiname':'','kpival':[]}
                        //let jlen = kpiFilter[i].condition.length
                        if(kpiFilter[i].kpiAttr === "SWITCH_POWER_LOAD"){
                            obj.kpikey = kpiFilter[i].kpiAttr;
                            obj.kpival = kpiFilter[i].condition
                            obj.kpiname = kpiFilter[i].condition[0].name
                        }else {
                            obj.kpikey = kpiFilter[i].kpiAttr;
                            obj.kpival = kpiFilter[i].condition[0].value;
                            obj.kpiname = kpiFilter[i].condition[0].name
                        }
                        kpi_array.push(obj)
                        /*for(let j=0;j<jlen;j++){
                            let obj = {'kpikey':'','kpiname':'','kpival':[]}
                            obj.kpikey = kpiFilter[i].kpiAttr;
                            obj.kpival = kpiFilter[i].condition[j].value;
                            obj.kpiname = kpiFilter[i].condition[j].name
                            kpi_array.push(obj)
                        }*/
                    }
                    kpi_array.map(obj => {
                        if(obj.kpikey == 'SWITCH_NAME') obj.kpikey = 'deviceName'
                        else if(obj.kpikey == 'SITE_ID') obj.kpikey = 'site'
                        else if(obj.kpikey == 'SWITCH_MODEL') obj.kpikey = 'deviceType'
                        else if(obj.kpikey === 'SWITCH_POWER_LOAD') obj.kpikey = 'powerLoad'
                        else ''
                    });
                    if(kpi_array.length>0){
                        let data;
                        kpi_array.forEach(kpiObj => {
                            let curFiltr = "" , prevFiltr = ""
                            curFiltr = kpiObj.kpikey
                            let dbParam = kpiObj.kpikey;
                            let arrlen = kpiObj.kpival.length;
                            if(kpiObj.kpikey === "powerLoad"){
                                let conditionArr = kpiObj.kpival
                                let cmpMin = parseFloat(conditionArr[0].value[0])
                                let cmpMax = parseFloat(conditionArr[arrlen-1].value[0])
                                data = SwitchData.filter(obj => obj[dbParam]  >= cmpMin && obj[dbParam]  <= cmpMax );
                                returnArr = data
                            }else{
                                for(let j=0; j<arrlen;j++){
                                    data = SwitchData.filter(obj => obj[dbParam] == kpiObj.kpival[j] );
                                    returnArr = curFiltr === prevFiltr ? [...returnArr,...data] : data
                                    returnArr = returnArr.flat()
                                    prevFiltr = kpiObj.kpikey
                                }
                            }
                            SwitchData = [...returnArr]
                            prevFiltr = kpiObj.kpikey
                        })
                    }
                    return {"version":"1.0","response":returnArr,"page":{"pageSize":returnArr.length,"offset":0,
                            "totalCount":returnArr.length,"nextKey":null,"topN":null}}

                }else return {"version":"1.0","response":SwitchData,"page":{"pageSize":SwitchData.length,"offset":0,
                            "totalCount":SwitchData.length,"nextKey":null,"topN":null}}

            
            } else if (urlAction.service.indexOf('ap-data') > -1) {
                let apsdata = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'family': 'Unified AP' });
                let poe_up_data = SimLokiDatabaseActions.getFilteredRecordHandler('poe-aggregated-data',{'poeOperStatus':'ON'});
                var filtered_aps;
                if (urlAction.restPayload.filters.kpi[0].condition[0].value[0] == "FULLY_POWERED") {
                    filtered_aps = apsdata.filter(item => {
                        return item.assuranceHealthScore > 6
                    })
                }
                else {
                    filtered_aps = apsdata.filter(item => {
                        return item.assuranceHealthScore <= 6
                    })
                }
                let response = [];
                for (let i = 0; i < filtered_aps.length; i++) {
                    let obj = {}
                    obj.deviceUUID = filtered_aps[i].instanceUuid;
                    obj.deviceName = filtered_aps[i].hostname;
                    obj.switchUUID = filtered_aps[i].lineCardId;
                    obj.switchName = poe_up_data[i].pdConnectedSwitch;
                    obj.deviceType = filtered_aps[i].family;
                    obj.deviceVersion = filtered_aps[i].softwareVersion;
                    obj.deviceIPAddress = filtered_aps[i].managementIpAddress;
                    let siteDetails = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id': filtered_aps[i].siteId})
                    obj.site = siteDetails[0].groupNameHierarchy;
                    obj.siteUUID = filtered_aps[i].siteId;
                    obj.apPoePowerMode = filtered_aps[i].assuranceHealthScore;
                    obj.id = poe_up_data[i].connectedSwitchUUID;
                    obj.switchInterfaceName = poe_up_data[i].intfName;
                    obj.powerBudget = poe_up_data[i].pdPowerBudget;
                    obj.powerConsumed = poe_up_data[i].pdPowerConsumed;
                    obj.powerRemaining = poe_up_data[i].pdPowerRemaining;
                    obj.powerLoad = "55.9",
                    obj.apPowerType = "POWER_SRC_TYPE_POE_LGCY",
                    obj.apPowerMode = "POWER_MODE_TYPE_25_5_PWR",
                    obj.apPowerStatus = null,
                    obj.apPoePowerMode = urlAction.restPayload.filters.kpi[0].condition[0].value[0]
                    response.push(obj)
                }                
                return {
                    "response": response, "version": 1.0, "page": {
                        "pageSize": response.length, "offset": 0,
                        "totalCount": response.length, "nextKey": null, "topN": null
                    }
                };  
            } 
                
            else if(urlAction.service.indexOf('poe-details') > -1){
                let deviceId = urlAction.action.id;
                    let poe_Data = SimLokiDatabaseActions.getFilteredRecordHandler('poe-aggregated-data',{'poeOperStatus':'ON'})
                    let totSwitchData = getSwitchPowerDetails(urlAction,poe_Data);
                    let SwitchData = totSwitchData.filter(obj => obj.deviceUUID === deviceId);
                    if(SwitchData.length==0) {
                        return {"version":"1.0"};
                    }
                    let time = UtilityFunctions.getTimeStamp()
                    let timeStamp = UtilityFunctions.getGivenDateAndTimeInValidFormat(time)
                    let consumedPower = SwitchData[0].powerConsumed;
                    consumedPower = parseFloat(consumedPower.replace(/W/,'')).toFixed(1)
                if(urlAction.restPayload.groupType == 'DEVICE'){
                    let obj = {"id":"ALL","powerBudget":"","usedPower":"","remainingPower":"",
                                            "powerUsagePercent":"","timestamp":""}
                    let entries = [];
                    obj.powerBudget = SwitchData[0].powerBudget;
                    obj.usedPower = `${consumedPower}W`;//SwitchData[0].powerConsumed
                    obj.remainingPower = SwitchData[0].powerRemaining;
                    obj.powerUsagePercent = `${SwitchData[0].powerLoad}%`;
                    obj.timestamp = timeStamp
                    entries.push(obj)
                    return {"version":"1.0","response":{"DEVICE":[{"time":timeStamp,"entries":entries}]}};


                }else if(urlAction.restPayload.groupType == 'MODULE'){

                    let tJson = JSON.parse(JSON.stringify(PoeSummaryTemplate.poe_details_module))
                    tJson.response.MODULE[0].time = timeStamp;
                    tJson.response.MODULE[0].entries[0].powerBudget = SwitchData[0].powerBudget;
                    tJson.response.MODULE[0].entries[0].usedPower = `${consumedPower}W`;//SwitchData[0].powerConsumed;
                    tJson.response.MODULE[0].entries[0].remainingPower = SwitchData[0].powerRemaining;
                    tJson.response.MODULE[0].entries[0].powerUsagePercent = `${SwitchData[0].powerLoad}%`;
                    tJson.response.MODULE[0].entries[0].timestamp = timeStamp;
                    tJson.response.MODULE[0].entries[0].nestedCounts[0].powerBudget = SwitchData[0].powerBudget;
                    tJson.response.MODULE[0].entries[0].nestedCounts[0].usedPower = `${consumedPower}W`;//SwitchData[0].powerConsumed;
                    tJson.response.MODULE[0].entries[0].nestedCounts[0].remainingPower = SwitchData[0].powerRemaining;
                    tJson.response.MODULE[0].entries[0].nestedCounts[0].powerUsagePercent = `${SwitchData[0].powerLoad}%`;
                    tJson.response.MODULE[0].entries[0].nestedCounts[0].timestamp = timeStamp
                    return tJson
                    //platformId,serialNumber
                }
            }else if(urlAction.service.indexOf('poe-portData') > -1){
                let deviceId = urlAction.action.id;
                let poeAdminStatus = urlAction.restPayload.filters.poeAdminStatus;
                let poeConfigType = urlAction.restPayload.filters.poeConfigType;
                let poeOperStatus = urlAction.restPayload.filters.poeOperStatus;
                let data = SimLokiDatabaseActions.getAll('poe-aggregated-data')
                let deviceSpecific = data.filter(obj => obj.connectedSwitchUUID === deviceId);
                poeConfigType = (poeConfigType == 'FAST_POE')? 'fastPoEEnabled' :
                                (poeConfigType == 'UPOE_PLUS')? 'upoePlusEnabled':
                                (poeConfigType == 'PERSISTENT_POE')? 'perpetualPoEEnabled':
                                (poeConfigType == 'POLICING')? 'policingPoEEnabled':
                                (poeConfigType == 'FOUR_PAIR_FORCED')? 'fourPairEnabled': poeConfigType
                poeOperStatus = (poeOperStatus == 'ON_ON')? 'ON':
                                (poeOperStatus == 'OFF_PD_FAULTY')? 'OFF:PD_FAULTY':
                                (poeOperStatus == 'OFF_POWER_DENY')? 'OFF:POWER_DENY':
                                (poeOperStatus == 'OFF_ERROR_DISABLED')?'OFF:ERROR_DISABLED':poeOperStatus;
                let filterdata
                if(poeAdminStatus != 'ALL' && poeConfigType != 'ALL' && poeOperStatus != 'ALL'){
                   filterdata = deviceSpecific.filter(obj => obj[poeConfigType] === true && obj.poeOperStatus === poeOperStatus && obj.poeAdminStatus == poeAdminStatus )
                }else if(poeAdminStatus != 'ALL' && poeConfigType == 'ALL' && poeOperStatus != 'ALL'){
                    filterdata = deviceSpecific.filter(obj => obj.poeOperStatus === poeOperStatus && obj.poeConfigType == poeConfigType )
                }else if(poeAdminStatus == 'ALL' && poeConfigType != 'ALL' && poeOperStatus != 'ALL'){
                    filterdata = deviceSpecific.filter(obj => obj[poeConfigType] === true  && obj.poeOperStatus === poeOperStatus)
                }else if(poeAdminStatus != 'ALL' && poeConfigType != 'ALL' && poeOperStatus == 'ALL'){
                    filterdata = deviceSpecific.filter(obj => obj[poeConfigType] === true  && obj.poeAdminStatus === poeAdminStatus)
                }else if(poeAdminStatus == 'ALL' && poeConfigType != 'ALL' && poeOperStatus == 'ALL'){
                    filterdata = deviceSpecific.filter(obj => obj[poeConfigType] === true)
                }else if(poeAdminStatus != 'ALL' && poeConfigType == 'ALL' && poeOperStatus == 'ALL'){
                    filterdata = deviceSpecific.filter(obj => obj.poeAdminStatus === poeAdminStatus)
                }else if(poeAdminStatus == 'ALL' && poeConfigType == 'ALL' && poeOperStatus != 'ALL'){
                    filterdata = deviceSpecific.filter(obj => obj.poeOperStatus === poeOperStatus)
                }else filterdata = [...deviceSpecific]

                // Adding below condition to Show POE tab for AP4800 device -- As per satpal request in Guardian/Groot 
                if (urlAction.service.indexOf("network-device") != -1) {
                    var deviceDatas = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'id': deviceId });
                    var deviceNames = deviceDatas.length > 0 ? deviceDatas[0].name : "";
                    if (deviceNames == 'AP4800') {
                        filterdata = JSON.parse(JSON.stringify(PoeSummaryTemplate.PoE_Port_Analytics_AP4800))
                        filterdata[0].timestamp = UtilityFunctions.getTimeStamp()
                    }
                }                
                return {"version":"1.0","response":filterdata,"page":{"pageSize":filterdata.length,"offset":0,
                        "totalCount":filterdata.length,"nextKey":null,"topN":null},"downCount":0};
            } else if(urlAction.service.indexOf('poe-stack-details') > -1) {
                return {"totalCount":0, "version":"1.0"};
            }
       }


  };

  function getRandomIntForRangeValues(min,max){
    return ((Math.random() * (max - min)) + min).toFixed(1);
  }
  function getPoeDataBasedOnSite(urlAction){
        let siteId = urlAction.restPayload.siteList[0];
        let childrenSites=[],siteIdList = [];
        let siteList = SimulationUtility.getChildrenSites(siteId,childrenSites);
         siteIdList.push(siteId);
         for(let i=0;i<childrenSites.length;i++) {
             siteIdList.push(childrenSites[i].id);
         }
         let poeSiteData = SimLokiDatabaseActions.getFilteredRecordHandler('poe-aggregated-data',{'switchSiteId': {'$containsAny' : siteIdList}});
         return poeSiteData;
  }
  function generatePoeTrendData(urlAction,jsonObject){
        let stTime = urlAction.restPayload.startTime;
        let endTime = urlAction.restPayload.endTime;
        let hours = Math.floor(UtilityFunctions.getHours(urlAction.restPayload.startTime,urlAction.restPayload.endTime));
        let tWindowTime = 10;
        if(hours > 24){
             tWindowTime = 60
        }else if(hours < 24 ){
             tWindowTime = 5;
        }else {
             tWindowTime = 10;
        }
        let noOfIntervals = (hours*60)/tWindowTime;
        let incrementTime = 0;
        let arrRecords = [];
        for(let i=0; i< noOfIntervals; i++){
             jsonObject.timestamp = UtilityFunctions.incrementDateByMinutes(endTime,incrementTime);
             arrRecords.push(JSON.parse(JSON.stringify(jsonObject)));
             incrementTime-= tWindowTime
        }
        return arrRecords
  }

  function getTopRecBasedOnKpiValue(data,param){
        let returnArr = []
        let arrData = [...new Set(data.map(obj => obj[param]))];
        arrData.map(val => {
            let kpiObj = {"id":"","name":"","count":0};
            let typeCount = data.filter(obj => obj[param] == val);
            kpiObj.id = val
            kpiObj.name = val;
            kpiObj.count = typeCount.length
            returnArr.push(kpiObj);
        })
        return returnArr.slice(0,4);// returns top 5
  }

  function getPowerValueBasedOnKpi(urlAction,data,param){
    let kpiValue0 = urlAction.restPayload.filters.kpi[0].condition[0].value[0];
    let kpiValue1 = urlAction.restPayload.filters.kpi[0].condition[1].value[0];
    let rangeCount =  data.filter(obj => obj[param] > kpiValue0 && obj[param] <= kpiValue1);
  }

  function getSwitchPowerDetails(urlAction,poeUpdata){
    let kpiValue0 = (urlAction.restPayload.filters != undefined)? ((urlAction.restPayload.filters.kpi[0].condition[0] != undefined) ? urlAction.restPayload.filters.kpi[0].condition[0].value[0] : undefined) : undefined;
    let kpiValue1 = (urlAction.restPayload.filters != undefined)? ((urlAction.restPayload.filters.kpi[0].condition[1] != undefined) ? urlAction.restPayload.filters.kpi[0].condition[1].value[0] : undefined) : undefined;

    let switchNames = [...new Set(poeUpdata.map(obj => obj.pdConnectedSwitch))];
    let result = [];poeUpdata.map(obj => {
        if(obj != undefined){
            result.push(obj.SwitchPowerDetails)
        }
    })
    let SwichData = [], oneJsonObj = [];
    const map = new Map();
    for (const item of result){
        if(!map.has(item.deviceName)){
                map.set(item.deviceName,true);
                SwichData.push({
                     deviceName : item.deviceName,
                     powerLoad : parseFloat(item.powerLoad),
                     powerBudget : item.powerBudget,
                     powerConsumed: item.powerConsumed,
                     powerRemaining : item.powerRemaining

                });
        }
    }
    let SwitchFilter;
    if(kpiValue0 == undefined && kpiValue1 == undefined || urlAction.restPayload.filters.kpi[0].kpiAttr === "SWITCH_POWER_USAGE"){
        SwitchFilter = SwichData;
    }else if(kpiValue0 != undefined && kpiValue1 == undefined){
        SwitchFilter = SwichData.filter(data => data.powerLoad >= kpiValue0);
    }else {
        SwitchFilter = SwichData.filter(data => data.powerLoad > kpiValue0 && data.powerLoad <= kpiValue1 );
    }

    let returnArr = []
    SwitchFilter.map(switchObj => {
                    let deviceRec = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'hostname': switchObj.deviceName})
                    let siteName = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'id': deviceRec[0].siteId})
                    let obj = {}
                    obj['chassisCount'] = 0; //obj.chassidId
                    obj['deviceIPAddress'] = deviceRec[0].managementIpAddress

                    obj['deviceName'] = switchObj.deviceName
                    obj['deviceType'] = deviceRec[0].type
                    obj['deviceUUID'] = deviceRec[0].id
                    obj['deviceVersion'] = deviceRec[0].softwareVersion
                    obj['moduleCount'] = 1;
                    obj['pdCount'] = null
                    obj['powerBudget'] = switchObj.powerBudget
                    obj['powerConsumed'] = switchObj.powerConsumed
                    obj['powerLoad'] = switchObj.powerLoad
                    obj['powerRemaining'] = switchObj.powerRemaining
                    obj['site'] = siteName[0].groupNameHierarchy;
                    obj['siteUUID'] = deviceRec[0].siteId
                    returnArr.push(obj)

    })
    return returnArr;
  }

  function getTopPowerLoad(switchData){
    let ranges = {"0-20%":[0,20],"20-40%":[20,40],"40-60%":[40,60],"60-80%":[60,80]}
    let dataList = []
    Object.keys(ranges).forEach(range => {
        let rangeCount = switchData.filter(data => data.powerLoad > ranges[range][0] && data.powerLoad < ranges[range][1])
        let obj = {"id": "","name": "","count": 0}
        obj.id = range
        obj.name = range
        obj. count =  rangeCount.length
        dataList.push(obj)
    })
    return dataList
  }

  function getFilterBasedOnKpi(urlAction,poeTotalData,ivalue){
     let kpiFilter = urlAction.restPayload.filters.kpi
     let kpi_array = [], returnArr = []
     for(let i=ivalue;i<kpiFilter.length; i++){
            let obj = {'kpikey':'','kpival':[]}
            obj.kpikey = kpiFilter[i].kpiAttr;
            obj.kpival = kpiFilter[i].condition[0].value;
            kpi_array.push(obj)
     }
     kpi_array.map(obj => {
        if(obj.kpikey == 'SWITCH_NAME') obj.kpikey = 'pdConnectedSwitch'
        else if(obj.kpikey == 'PD_TYPE') obj.kpikey = 'pdDeviceType'
        else if(obj.kpikey == 'SITE_ID') obj.kpikey = 'pdLocation'
        else if(obj.kpikey == 'SWITCH_MODEL') obj.kpikey = 'connectedSwitchType'
        else ''
     });
    for(let i=0; i<kpi_array.length;i++){
        let dbParam = kpi_array[i].kpikey;
        let data;
        returnArr = [];
        for(let j=0; j<kpi_array[i].kpival.length;j++){
            data = poeTotalData.filter(obj => obj[dbParam] == kpi_array[i].kpival[j] );
            returnArr.push(data);
        }
        returnArr = returnArr.flat()
        poeTotalData = [...returnArr]
    }
    return returnArr;
  }







});