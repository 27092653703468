define({
    "Template":
    {
  "version": "1.0",
  "response": [
    {
      "time": "2018-05-31T01:55:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.25,
      "latency": 799.0,
      "applicationDelay": 79.0
    },
    {
      "time": "2018-05-31T01:50:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.54,
      "latency": 1391.0,
      "applicationDelay": 64.0
    },
    {
      "time": "2018-05-31T01:45:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 5.19,
      "latency": 423.0,
      "applicationDelay": 86.0
    },
    {
      "time": "2018-05-31T01:40:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 3.79,
      "latency": 273.0,
      "applicationDelay": 127.0
    },
    {
      "time": "2018-05-31T01:35:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 3.4,
      "latency": 328.0,
      "applicationDelay": 62.0
    },
    {
      "time": "2018-05-31T01:30:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.23,
      "latency": 414.0,
      "applicationDelay": 67.0
    },
    {
      "time": "2018-05-31T01:25:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.5,
      "latency": 512.0,
      "applicationDelay": 78.0
    },
    {
      "time": "2018-05-31T01:20:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.89,
      "latency": 275.0,
      "applicationDelay": 65.0
    },
    {
      "time": "2018-05-31T01:15:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 3.38,
      "latency": 283.0,
      "applicationDelay": 95.0
    },
    {
      "time": "2018-05-31T01:10:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 3.93,
      "latency": 353.0,
      "applicationDelay": 99.0
    },
    {
      "time": "2018-05-31T01:05:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 3.79,
      "latency": 265.0,
      "applicationDelay": 76.0
    },
    {
      "time": "2018-05-31T01:00:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.97,
      "latency": 357.0,
      "applicationDelay": 68.0
    },
    {
      "time": "2018-05-31T00:55:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 3.43,
      "latency": 281.0,
      "applicationDelay": 105.0
    },
    {
      "time": "2018-05-31T00:50:00.000+0000",
      "healthScore": 1.0,
      "packetLoss": 5.47,
      "latency": 1791.0,
      "applicationDelay": 102.0
    },
    {
      "time": "2018-05-31T00:45:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.82,
      "latency": 324.0,
      "applicationDelay": 143.0
    },
    {
      "time": "2018-05-31T00:40:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.77,
      "latency": 451.0,
      "applicationDelay": 87.0
    },
    {
      "time": "2018-05-31T00:35:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.03,
      "latency": 497.0,
      "applicationDelay": 81.0
    },
    {
      "time": "2018-05-31T00:30:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.35,
      "latency": 546.0,
      "applicationDelay": 72.0
    },
    {
      "time": "2018-05-31T00:25:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 2.68,
      "latency": 281.0,
      "applicationDelay": 84.0
    },
    {
      "time": "2018-05-31T00:20:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.63,
      "latency": 430.0,
      "applicationDelay": 87.0
    },
    {
      "time": "2018-05-31T00:15:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.38,
      "latency": 495.0,
      "applicationDelay": 130.0
    },
    {
      "time": "2018-05-31T00:10:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.36,
      "latency": 536.0,
      "applicationDelay": 66.0
    },
    {
      "time": "2018-05-31T00:05:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 3.03,
      "latency": 253.0,
      "applicationDelay": 91.0
    },
    {
      "time": "2018-05-31T00:00:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 3.62,
      "latency": 247.0,
      "applicationDelay": 52.0
    },
    {
      "time": "2018-05-30T23:55:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 3.99,
      "latency": 317.0,
      "applicationDelay": 70.0
    },
    {
      "time": "2018-05-30T23:50:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 4.55,
      "latency": 225.0,
      "applicationDelay": 81.0
    },
    {
      "time": "2018-05-30T23:45:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.87,
      "latency": 359.0,
      "applicationDelay": 64.0
    },
    {
      "time": "2018-05-30T23:40:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 3.43,
      "latency": 186.0,
      "applicationDelay": 83.0
    },
    {
      "time": "2018-05-30T23:35:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.61,
      "latency": 522.0,
      "applicationDelay": 66.0
    },
    {
      "time": "2018-05-30T23:30:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 3.18,
      "latency": 158.0,
      "applicationDelay": 68.0
    },
    {
      "time": "2018-05-30T23:25:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 3.95,
      "latency": 275.0,
      "applicationDelay": 65.0
    },
    {
      "time": "2018-05-30T23:20:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 3.06,
      "latency": 127.0,
      "applicationDelay": 80.0
    },
    {
      "time": "2018-05-30T23:15:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 4.2,
      "latency": 234.0,
      "applicationDelay": 71.0
    },
    {
      "time": "2018-05-30T23:10:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 3.99,
      "latency": 233.0,
      "applicationDelay": 83.0
    },
    {
      "time": "2018-05-30T23:05:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 3.35,
      "latency": 234.0,
      "applicationDelay": 121.0
    },
    {
      "time": "2018-05-30T23:00:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 3.31,
      "latency": 312.0,
      "applicationDelay": 111.0
    },
    {
      "time": "2018-05-30T22:55:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 4.02,
      "latency": 196.0,
      "applicationDelay": 115.0
    },
    {
      "time": "2018-05-30T22:50:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.46,
      "latency": 378.0,
      "applicationDelay": 62.0
    },
    {
      "time": "2018-05-30T22:45:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 5.2,
      "latency": 431.0,
      "applicationDelay": 86.0
    },
    {
      "time": "2018-05-30T22:40:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 4.59,
      "latency": 254.0,
      "applicationDelay": 96.0
    },
    {
      "time": "2018-05-30T22:35:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 2.87,
      "latency": 322.0,
      "applicationDelay": 111.0
    },
    {
      "time": "2018-05-30T22:30:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 2.67,
      "latency": 239.0,
      "applicationDelay": 101.0
    },
    {
      "time": "2018-05-30T22:25:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.73,
      "latency": 329.0,
      "applicationDelay": 65.0
    },
    {
      "time": "2018-05-30T22:20:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.74,
      "latency": 425.0,
      "applicationDelay": 74.0
    },
    {
      "time": "2018-05-30T22:15:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 5.08,
      "latency": 276.0,
      "applicationDelay": 64.0
    },
    {
      "time": "2018-05-30T22:10:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 3.86,
      "latency": 199.0,
      "applicationDelay": 97.0
    },
    {
      "time": "2018-05-30T22:05:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 3.18,
      "latency": 177.0,
      "applicationDelay": 103.0
    },
    {
      "time": "2018-05-30T22:00:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 6.33,
      "latency": 508.0,
      "applicationDelay": 104.0
    },
    {
      "time": "2018-05-30T21:55:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.89,
      "latency": 533.0,
      "applicationDelay": 110.0
    },
    {
      "time": "2018-05-30T21:50:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.24,
      "latency": 658.0,
      "applicationDelay": 83.0
    },
    {
      "time": "2018-05-30T21:45:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 2.4,
      "latency": 250.0,
      "applicationDelay": 63.0
    },
    {
      "time": "2018-05-30T21:40:00.000+0000",
      "healthScore": 1.0,
      "packetLoss": 3.0,
      "latency": 1458.0,
      "applicationDelay": 100.0
    },
    {
      "time": "2018-05-30T21:35:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.43,
      "latency": 727.0,
      "applicationDelay": 88.0
    },
    {
      "time": "2018-05-30T21:30:00.000+0000",
      "healthScore": 1.0,
      "packetLoss": 11.92,
      "latency": 496.0,
      "applicationDelay": 66.0
    },
    {
      "time": "2018-05-30T21:25:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.71,
      "latency": 309.0,
      "applicationDelay": 63.0
    },
    {
      "time": "2018-05-30T21:20:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.0,
      "latency": 332.0,
      "applicationDelay": 94.0
    },
    {
      "time": "2018-05-30T21:15:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 3.39,
      "latency": 218.0,
      "applicationDelay": 94.0
    },
    {
      "time": "2018-05-30T21:10:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.82,
      "latency": 601.0,
      "applicationDelay": 109.0
    },
    {
      "time": "2018-05-30T21:05:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 3.23,
      "latency": 316.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-30T21:00:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 3.29,
      "latency": 354.0,
      "applicationDelay": 66.0
    },
    {
      "time": "2018-05-30T20:55:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.21,
      "latency": 500.0,
      "applicationDelay": 54.0
    },
    {
      "time": "2018-05-30T20:50:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 5.04,
      "latency": 514.0,
      "applicationDelay": 76.0
    },
    {
      "time": "2018-05-30T20:45:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.2,
      "latency": 476.0,
      "applicationDelay": 98.0
    },
    {
      "time": "2018-05-30T20:40:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 3.75,
      "latency": 355.0,
      "applicationDelay": 110.0
    },
    {
      "time": "2018-05-30T20:35:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 3.58,
      "latency": 326.0,
      "applicationDelay": 123.0
    },
    {
      "time": "2018-05-30T20:30:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 1.76,
      "latency": 968.0,
      "applicationDelay": 84.0
    },
    {
      "time": "2018-05-30T20:25:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.4,
      "latency": 620.0,
      "applicationDelay": 69.0
    },
    {
      "time": "2018-05-30T20:20:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.57,
      "latency": 338.0,
      "applicationDelay": 79.0
    },
    {
      "time": "2018-05-30T20:15:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.29,
      "latency": 392.0,
      "applicationDelay": 74.0
    },
    {
      "time": "2018-05-30T20:10:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.03,
      "latency": 624.0,
      "applicationDelay": 104.0
    },
    {
      "time": "2018-05-30T20:05:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.02,
      "latency": 422.0,
      "applicationDelay": 94.0
    },
    {
      "time": "2018-05-30T20:00:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.91,
      "latency": 430.0,
      "applicationDelay": 71.0
    },
    {
      "time": "2018-05-30T19:55:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.97,
      "latency": 550.0,
      "applicationDelay": 84.0
    },
    {
      "time": "2018-05-30T19:50:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.68,
      "latency": 310.0,
      "applicationDelay": 76.0
    },
    {
      "time": "2018-05-30T19:45:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 5.05,
      "latency": 394.0,
      "applicationDelay": 105.0
    },
    {
      "time": "2018-05-30T19:40:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.72,
      "latency": 349.0,
      "applicationDelay": 136.0
    },
    {
      "time": "2018-05-30T19:35:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 4.0,
      "latency": 263.0,
      "applicationDelay": 145.0
    },
    {
      "time": "2018-05-30T19:30:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 3.35,
      "latency": 251.0,
      "applicationDelay": 143.0
    },
    {
      "time": "2018-05-30T19:25:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 4.56,
      "latency": 160.0,
      "applicationDelay": 108.0
    },
    {
      "time": "2018-05-30T19:20:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 3.58,
      "latency": 261.0,
      "applicationDelay": 107.0
    },
    {
      "time": "2018-05-30T19:15:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 3.91,
      "latency": 208.0,
      "applicationDelay": 98.0
    },
    {
      "time": "2018-05-30T19:10:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 3.74,
      "latency": 308.0,
      "applicationDelay": 63.0
    },
    {
      "time": "2018-05-30T19:05:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.7,
      "latency": 474.0,
      "applicationDelay": 83.0
    },
    {
      "time": "2018-05-30T19:00:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 3.44,
      "latency": 207.0,
      "applicationDelay": 119.0
    },
    {
      "time": "2018-05-30T18:55:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.18,
      "latency": 365.0,
      "applicationDelay": 117.0
    },
    {
      "time": "2018-05-30T18:50:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 2.79,
      "latency": 294.0,
      "applicationDelay": 100.0
    },
    {
      "time": "2018-05-30T18:45:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 3.79,
      "latency": 338.0,
      "applicationDelay": 109.0
    },
    {
      "time": "2018-05-30T18:40:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 2.82,
      "latency": 256.0,
      "applicationDelay": 153.0
    },
    {
      "time": "2018-05-30T18:35:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 3.43,
      "latency": 304.0,
      "applicationDelay": 121.0
    },
    {
      "time": "2018-05-30T18:30:00.000+0000",
      "healthScore": 1.0,
      "packetLoss": 4.13,
      "latency": 1108.0,
      "applicationDelay": 105.0
    },
    {
      "time": "2018-05-30T18:25:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.79,
      "latency": 468.0,
      "applicationDelay": 99.0
    },
    {
      "time": "2018-05-30T18:20:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.21,
      "latency": 534.0,
      "applicationDelay": 74.0
    },
    {
      "time": "2018-05-30T18:15:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 2.55,
      "latency": 188.0,
      "applicationDelay": 133.0
    },
    {
      "time": "2018-05-30T18:10:00.000+0000",
      "healthScore": 6.0,
      "packetLoss": 0.58,
      "latency": 32.0,
      "applicationDelay": 86.0
    },
    {
      "time": "2018-05-30T18:05:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 1.64,
      "latency": 33.0,
      "applicationDelay": 94.0
    },
    {
      "time": "2018-05-30T18:00:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 30.2,
      "latency": 20.0,
      "applicationDelay": 100.0
    },
    {
      "time": "2018-05-30T17:55:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 14.22,
      "latency": 457.0,
      "applicationDelay": 80.0
    },
    {
      "time": "2018-05-30T17:50:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 15.75,
      "latency": 457.0,
      "applicationDelay": 57.5
    },
    {
      "time": "2018-05-30T17:45:00.000+0000",
      "healthScore": 3.5,
      "packetLoss": 6.29,
      "latency": 374.0,
      "applicationDelay": 58.0
    },
    {
      "time": "2018-05-30T17:40:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 1.19,
      "latency": 414.5,
      "applicationDelay": 39.5
    },
    {
      "time": "2018-05-30T17:35:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.54,
      "latency": 303.0,
      "applicationDelay": 82.0
    },
    {
      "time": "2018-05-30T17:30:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.44,
      "latency": 502.0,
      "applicationDelay": 75.0
    },
    {
      "time": "2018-05-30T17:25:00.000+0000",
      "healthScore": 1.0,
      "packetLoss": 7.22,
      "latency": 1962.0,
      "applicationDelay": 53.0
    },
    {
      "time": "2018-05-30T17:20:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 3.68,
      "latency": 348.0,
      "applicationDelay": 85.0
    },
    {
      "time": "2018-05-30T17:15:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.45,
      "latency": 587.0,
      "applicationDelay": 63.0
    },
    {
      "time": "2018-05-30T17:10:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 3.35,
      "latency": 324.0,
      "applicationDelay": 58.0
    },
    {
      "time": "2018-05-30T17:05:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.83,
      "latency": 575.0,
      "applicationDelay": 82.0
    },
    {
      "time": "2018-05-30T17:00:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 3.72,
      "latency": 313.0,
      "applicationDelay": 81.0
    },
    {
      "time": "2018-05-30T16:55:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 21.52,
      "latency": 469.0,
      "applicationDelay": 84.0
    },
    {
      "time": "2018-05-30T16:50:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.07,
      "latency": 383.0,
      "applicationDelay": 97.0
    },
    {
      "time": "2018-05-30T16:45:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 3.36,
      "latency": 228.0,
      "applicationDelay": 77.0
    },
    {
      "time": "2018-05-30T16:40:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.68,
      "latency": 536.0,
      "applicationDelay": 96.0
    },
    {
      "time": "2018-05-30T16:35:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.4,
      "latency": 452.0,
      "applicationDelay": 123.0
    },
    {
      "time": "2018-05-30T16:30:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.14,
      "latency": 491.0,
      "applicationDelay": 126.0
    },
    {
      "time": "2018-05-30T16:25:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 3.94,
      "latency": 382.0,
      "applicationDelay": 74.0
    },
    {
      "time": "2018-05-30T16:20:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 5.89,
      "latency": 427.0,
      "applicationDelay": 58.0
    },
    {
      "time": "2018-05-30T16:15:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.5,
      "latency": 428.0,
      "applicationDelay": 68.0
    },
    {
      "time": "2018-05-30T16:10:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 3.49,
      "latency": 408.0,
      "applicationDelay": 60.0
    },
    {
      "time": "2018-05-30T16:05:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 3.52,
      "latency": 281.0,
      "applicationDelay": 109.0
    },
    {
      "time": "2018-05-30T16:00:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.25,
      "latency": 755.0,
      "applicationDelay": 143.0
    },
    {
      "time": "2018-05-30T15:55:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 11.88,
      "latency": 323.0,
      "applicationDelay": 77.0
    },
    {
      "time": "2018-05-30T15:50:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 5.03,
      "latency": 460.0,
      "applicationDelay": 66.0
    },
    {
      "time": "2018-05-30T15:45:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.14,
      "latency": 366.0,
      "applicationDelay": 103.0
    },
    {
      "time": "2018-05-30T15:40:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 4.2,
      "latency": 161.0,
      "applicationDelay": 134.0
    },
    {
      "time": "2018-05-30T15:35:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 5.74,
      "latency": 365.0,
      "applicationDelay": 119.0
    },
    {
      "time": "2018-05-30T15:30:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 4.81,
      "latency": 249.0,
      "applicationDelay": 100.0
    },
    {
      "time": "2018-05-30T15:25:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 3.09,
      "latency": 182.0,
      "applicationDelay": 98.0
    },
    {
      "time": "2018-05-30T15:20:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.03,
      "latency": 271.0,
      "applicationDelay": 111.0
    },
    {
      "time": "2018-05-30T15:15:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.4,
      "latency": 470.0,
      "applicationDelay": 103.0
    },
    {
      "time": "2018-05-30T15:10:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 3.91,
      "latency": 385.0,
      "applicationDelay": 117.0
    },
    {
      "time": "2018-05-30T15:05:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 4.97,
      "latency": 253.0,
      "applicationDelay": 114.0
    },
    {
      "time": "2018-05-30T15:00:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 3.73,
      "latency": 390.0,
      "applicationDelay": 108.0
    },
    {
      "time": "2018-05-30T14:55:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.98,
      "latency": 275.0,
      "applicationDelay": 108.0
    },
    {
      "time": "2018-05-30T14:50:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 8.18,
      "latency": 129.0,
      "applicationDelay": 116.0
    },
    {
      "time": "2018-05-30T14:45:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 4.04,
      "latency": 237.0,
      "applicationDelay": 128.0
    },
    {
      "time": "2018-05-30T14:40:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 2.83,
      "latency": 127.0,
      "applicationDelay": 91.0
    },
    {
      "time": "2018-05-30T14:35:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.79,
      "latency": 359.0,
      "applicationDelay": 66.0
    },
    {
      "time": "2018-05-30T14:30:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 3.63,
      "latency": 198.0,
      "applicationDelay": 86.0
    },
    {
      "time": "2018-05-30T14:25:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.89,
      "latency": 414.0,
      "applicationDelay": 124.0
    },
    {
      "time": "2018-05-30T14:20:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.29,
      "latency": 440.0,
      "applicationDelay": 132.0
    },
    {
      "time": "2018-05-30T14:15:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.32,
      "latency": 313.0,
      "applicationDelay": 122.0
    },
    {
      "time": "2018-05-30T14:10:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 2.4,
      "latency": 171.0,
      "applicationDelay": 106.0
    },
    {
      "time": "2018-05-30T14:05:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.11,
      "latency": 426.0,
      "applicationDelay": 110.0
    },
    {
      "time": "2018-05-30T14:00:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.62,
      "latency": 618.0,
      "applicationDelay": 103.0
    },
    {
      "time": "2018-05-30T13:55:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 4.92,
      "latency": 217.0,
      "applicationDelay": 58.0
    },
    {
      "time": "2018-05-30T13:50:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 2.21,
      "latency": 641.0,
      "applicationDelay": 83.0
    },
    {
      "time": "2018-05-30T13:45:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.31,
      "latency": 588.0,
      "applicationDelay": 104.0
    },
    {
      "time": "2018-05-30T13:40:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 2.72,
      "latency": 390.0,
      "applicationDelay": 67.0
    },
    {
      "time": "2018-05-30T13:35:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 4.13,
      "latency": 249.0,
      "applicationDelay": 105.0
    },
    {
      "time": "2018-05-30T13:30:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.71,
      "latency": 655.0,
      "applicationDelay": 117.0
    },
    {
      "time": "2018-05-30T13:25:00.000+0000",
      "healthScore": 1.0,
      "packetLoss": 4.4,
      "latency": 777.0,
      "applicationDelay": 64.0
    },
    {
      "time": "2018-05-30T13:20:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 5.79,
      "latency": 532.0,
      "applicationDelay": 74.0
    },
    {
      "time": "2018-05-30T13:15:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.27,
      "latency": 552.0,
      "applicationDelay": 55.0
    },
    {
      "time": "2018-05-30T13:10:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.31,
      "latency": 436.0,
      "applicationDelay": 80.0
    },
    {
      "time": "2018-05-30T13:05:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 3.63,
      "latency": 163.0,
      "applicationDelay": 83.0
    },
    {
      "time": "2018-05-30T13:00:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.72,
      "latency": 603.0,
      "applicationDelay": 109.0
    },
    {
      "time": "2018-05-30T12:55:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 3.11,
      "latency": 375.0,
      "applicationDelay": 96.0
    },
    {
      "time": "2018-05-30T12:50:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.66,
      "latency": 330.0,
      "applicationDelay": 80.0
    },
    {
      "time": "2018-05-30T12:45:00.000+0000",
      "healthScore": 1.0,
      "packetLoss": 5.2,
      "latency": 824.0,
      "applicationDelay": 77.0
    },
    {
      "time": "2018-05-30T12:40:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 3.19,
      "latency": 381.0,
      "applicationDelay": 61.0
    },
    {
      "time": "2018-05-30T12:35:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 5.36,
      "latency": 294.0,
      "applicationDelay": 87.0
    },
    {
      "time": "2018-05-30T12:30:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 3.07,
      "latency": 353.0,
      "applicationDelay": 78.0
    },
    {
      "time": "2018-05-30T12:25:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.99,
      "latency": 354.0,
      "applicationDelay": 125.0
    },
    {
      "time": "2018-05-30T12:20:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 3.34,
      "latency": 193.0,
      "applicationDelay": 119.0
    },
    {
      "time": "2018-05-30T12:15:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 5.41,
      "latency": 353.0,
      "applicationDelay": 142.0
    },
    {
      "time": "2018-05-30T12:10:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.99,
      "latency": 532.0,
      "applicationDelay": 124.0
    },
    {
      "time": "2018-05-30T12:05:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.54,
      "latency": 384.0,
      "applicationDelay": 131.0
    },
    {
      "time": "2018-05-30T12:00:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.34,
      "latency": 276.0,
      "applicationDelay": 93.0
    },
    {
      "time": "2018-05-30T11:55:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 3.23,
      "latency": 369.0,
      "applicationDelay": 54.0
    },
    {
      "time": "2018-05-30T11:50:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.01,
      "latency": 447.0,
      "applicationDelay": 61.0
    },
    {
      "time": "2018-05-30T11:45:00.000+0000",
      "healthScore": 1.0,
      "packetLoss": 5.61,
      "latency": 993.0,
      "applicationDelay": 55.0
    },
    {
      "time": "2018-05-30T11:40:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.49,
      "latency": 671.0,
      "applicationDelay": 117.0
    },
    {
      "time": "2018-05-30T11:35:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.41,
      "latency": 341.0,
      "applicationDelay": 104.0
    },
    {
      "time": "2018-05-30T11:30:00.000+0000",
      "healthScore": 1.0,
      "packetLoss": 4.08,
      "latency": 963.0,
      "applicationDelay": 50.0
    },
    {
      "time": "2018-05-30T11:25:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.28,
      "latency": 750.0,
      "applicationDelay": 65.0
    },
    {
      "time": "2018-05-30T11:20:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.32,
      "latency": 600.0,
      "applicationDelay": 97.0
    },
    {
      "time": "2018-05-30T11:15:00.000+0000",
      "healthScore": 1.0,
      "packetLoss": 7.9,
      "latency": 1365.0,
      "applicationDelay": 59.0
    },
    {
      "time": "2018-05-30T11:10:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 5.14,
      "latency": 704.0,
      "applicationDelay": 68.0
    },
    {
      "time": "2018-05-30T11:05:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.9,
      "latency": 365.0,
      "applicationDelay": 67.0
    },
    {
      "time": "2018-05-30T11:00:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 5.61,
      "latency": 647.0,
      "applicationDelay": 70.0
    },
    {
      "time": "2018-05-30T10:55:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 3.66,
      "latency": 229.0,
      "applicationDelay": 57.0
    },
    {
      "time": "2018-05-30T10:50:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 5.69,
      "latency": 458.0,
      "applicationDelay": 80.0
    },
    {
      "time": "2018-05-30T10:45:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 12.36,
      "latency": 408.0,
      "applicationDelay": 57.0
    },
    {
      "time": "2018-05-30T10:40:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.16,
      "latency": 759.0,
      "applicationDelay": 78.0
    },
    {
      "time": "2018-05-30T10:35:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 5.25,
      "latency": 726.0,
      "applicationDelay": 109.0
    },
    {
      "time": "2018-05-30T10:30:00.000+0000",
      "healthScore": 1.0,
      "packetLoss": 4.89,
      "latency": 806.0,
      "applicationDelay": 111.0
    },
    {
      "time": "2018-05-30T10:25:00.000+0000",
      "healthScore": 4.5,
      "packetLoss": 1.97,
      "latency": 860.0,
      "applicationDelay": 33.0
    },
    {
      "time": "2018-05-30T10:20:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 4.62,
      "latency": 221.0,
      "applicationDelay": 111.0
    },
    {
      "time": "2018-05-30T10:15:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.19,
      "latency": 569.0,
      "applicationDelay": 69.0
    },
    {
      "time": "2018-05-30T10:10:00.000+0000",
      "healthScore": 1.0,
      "packetLoss": 4.92,
      "latency": 906.0,
      "applicationDelay": 87.0
    },
    {
      "time": "2018-05-30T10:05:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.99,
      "latency": 568.0,
      "applicationDelay": 89.0
    },
    {
      "time": "2018-05-30T10:00:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 2.56,
      "latency": 585.0,
      "applicationDelay": 121.0
    },
    {
      "time": "2018-05-30T09:55:00.000+0000",
      "healthScore": 1.0,
      "packetLoss": 4.03,
      "latency": 822.0,
      "applicationDelay": 119.0
    },
    {
      "time": "2018-05-30T09:50:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.48,
      "latency": 433.0,
      "applicationDelay": 128.0
    },
    {
      "time": "2018-05-30T09:45:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.46,
      "latency": 396.0,
      "applicationDelay": 131.0
    },
    {
      "time": "2018-05-30T09:40:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.37,
      "latency": 686.0,
      "applicationDelay": 134.0
    },
    {
      "time": "2018-05-30T09:35:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.05,
      "latency": 657.0,
      "applicationDelay": 57.0
    },
    {
      "time": "2018-05-30T09:30:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.82,
      "latency": 339.0,
      "applicationDelay": 53.0
    },
    {
      "time": "2018-05-30T09:25:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.98,
      "latency": 283.0,
      "applicationDelay": 57.0
    },
    {
      "time": "2018-05-30T09:20:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 3.91,
      "latency": 397.0,
      "applicationDelay": 93.0
    },
    {
      "time": "2018-05-30T09:15:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 27.48,
      "latency": 118.0,
      "applicationDelay": 38.0
    },
    {
      "time": "2018-05-30T09:10:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 40.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T09:05:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 50.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T09:00:00.000+0000",
      "healthScore": 1.0,
      "packetLoss": 18.13,
      "latency": 1876.0,
      "applicationDelay": 49.0
    },
    {
      "time": "2018-05-30T08:55:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 2.96,
      "latency": 482.0,
      "applicationDelay": 70.0
    },
    {
      "time": "2018-05-30T08:50:00.000+0000",
      "healthScore": 1.0,
      "packetLoss": 4.29,
      "latency": 1634.0,
      "applicationDelay": 46.0
    },
    {
      "time": "2018-05-30T08:45:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 6.09,
      "latency": 334.0,
      "applicationDelay": 52.0
    },
    {
      "time": "2018-05-30T08:40:00.000+0000",
      "healthScore": 1.0,
      "packetLoss": 5.58,
      "latency": 1240.0,
      "applicationDelay": 74.0
    },
    {
      "time": "2018-05-30T08:35:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.84,
      "latency": 455.0,
      "applicationDelay": 62.0
    },
    {
      "time": "2018-05-30T08:30:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.42,
      "latency": 577.0,
      "applicationDelay": 65.0
    },
    {
      "time": "2018-05-30T08:25:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.71,
      "latency": 367.0,
      "applicationDelay": 95.0
    },
    {
      "time": "2018-05-30T08:20:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.18,
      "latency": 720.0,
      "applicationDelay": 93.0
    },
    {
      "time": "2018-05-30T08:15:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.84,
      "latency": 441.0,
      "applicationDelay": 108.0
    },
    {
      "time": "2018-05-30T08:10:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.03,
      "latency": 534.0,
      "applicationDelay": 132.0
    },
    {
      "time": "2018-05-30T08:05:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.27,
      "latency": 693.0,
      "applicationDelay": 112.0
    },
    {
      "time": "2018-05-30T08:00:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 2.96,
      "latency": 494.0,
      "applicationDelay": 67.0
    },
    {
      "time": "2018-05-30T07:55:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 5.0,
      "latency": 360.0,
      "applicationDelay": 63.0
    },
    {
      "time": "2018-05-30T07:50:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.63,
      "latency": 766.0,
      "applicationDelay": 44.0
    },
    {
      "time": "2018-05-30T07:45:00.000+0000",
      "healthScore": 1.0,
      "packetLoss": 5.48,
      "latency": 905.0,
      "applicationDelay": 103.0
    },
    {
      "time": "2018-05-30T07:40:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 6.26,
      "latency": 263.0,
      "applicationDelay": 129.0
    },
    {
      "time": "2018-05-30T07:35:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.51,
      "latency": 575.0,
      "applicationDelay": 117.0
    },
    {
      "time": "2018-05-30T07:30:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.37,
      "latency": 485.0,
      "applicationDelay": 111.0
    },
    {
      "time": "2018-05-30T07:25:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.95,
      "latency": 454.0,
      "applicationDelay": 120.0
    },
    {
      "time": "2018-05-30T07:20:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.94,
      "latency": 771.0,
      "applicationDelay": 82.0
    },
    {
      "time": "2018-05-30T07:15:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 5.65,
      "latency": 681.0,
      "applicationDelay": 106.0
    },
    {
      "time": "2018-05-30T07:10:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 6.14,
      "latency": 470.0,
      "applicationDelay": 80.0
    },
    {
      "time": "2018-05-30T07:05:00.000+0000",
      "healthScore": 1.0,
      "packetLoss": 4.84,
      "latency": 824.0,
      "applicationDelay": 106.0
    },
    {
      "time": "2018-05-30T07:00:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.63,
      "latency": 320.0,
      "applicationDelay": 77.0
    },
    {
      "time": "2018-05-30T06:55:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.55,
      "latency": 394.0,
      "applicationDelay": 58.0
    },
    {
      "time": "2018-05-30T06:50:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.91,
      "latency": 690.0,
      "applicationDelay": 86.0
    },
    {
      "time": "2018-05-30T06:45:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.57,
      "latency": 463.0,
      "applicationDelay": 87.0
    },
    {
      "time": "2018-05-30T06:40:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 5.06,
      "latency": 516.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-30T06:35:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.67,
      "latency": 733.0,
      "applicationDelay": 71.0
    },
    {
      "time": "2018-05-30T06:30:00.000+0000",
      "healthScore": 1.0,
      "packetLoss": 5.12,
      "latency": 976.0,
      "applicationDelay": 65.0
    },
    {
      "time": "2018-05-30T06:25:00.000+0000",
      "healthScore": 1.0,
      "packetLoss": 4.46,
      "latency": 887.0,
      "applicationDelay": 112.0
    },
    {
      "time": "2018-05-30T06:20:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.21,
      "latency": 565.0,
      "applicationDelay": 79.0
    },
    {
      "time": "2018-05-30T06:15:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 3.23,
      "latency": 387.0,
      "applicationDelay": 58.0
    },
    {
      "time": "2018-05-30T06:10:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.88,
      "latency": 482.0,
      "applicationDelay": 144.0
    },
    {
      "time": "2018-05-30T06:05:00.000+0000",
      "healthScore": 1.0,
      "packetLoss": 4.74,
      "latency": 799.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-30T06:00:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 3.74,
      "latency": 406.0,
      "applicationDelay": 98.0
    },
    {
      "time": "2018-05-30T05:55:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 2.97,
      "latency": 365.0,
      "applicationDelay": 66.0
    },
    {
      "time": "2018-05-30T05:50:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 5.4,
      "latency": 623.0,
      "applicationDelay": 61.0
    },
    {
      "time": "2018-05-30T05:45:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 5.51,
      "latency": 610.0,
      "applicationDelay": 65.0
    },
    {
      "time": "2018-05-30T05:40:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 3.46,
      "latency": 192.0,
      "applicationDelay": 57.0
    },
    {
      "time": "2018-05-30T05:35:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 10.88,
      "latency": 339.0,
      "applicationDelay": 85.0
    },
    {
      "time": "2018-05-30T05:30:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 4.86,
      "latency": 263.0,
      "applicationDelay": 86.0
    },
    {
      "time": "2018-05-30T05:25:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 5.44,
      "latency": 460.0,
      "applicationDelay": 67.0
    },
    {
      "time": "2018-05-30T05:20:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.57,
      "latency": 479.0,
      "applicationDelay": 70.0
    },
    {
      "time": "2018-05-30T05:15:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 5.04,
      "latency": 560.0,
      "applicationDelay": 68.0
    },
    {
      "time": "2018-05-30T05:10:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.73,
      "latency": 644.0,
      "applicationDelay": 63.0
    },
    {
      "time": "2018-05-30T05:05:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.3,
      "latency": 412.0,
      "applicationDelay": 62.0
    },
    {
      "time": "2018-05-30T05:00:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 2.93,
      "latency": 167.0,
      "applicationDelay": 71.0
    },
    {
      "time": "2018-05-30T04:55:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.12,
      "latency": 492.0,
      "applicationDelay": 63.0
    },
    {
      "time": "2018-05-30T04:50:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 2.79,
      "latency": 704.0,
      "applicationDelay": 50.0
    },
    {
      "time": "2018-05-30T04:45:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.84,
      "latency": 477.0,
      "applicationDelay": 113.0
    },
    {
      "time": "2018-05-30T04:40:00.000+0000",
      "healthScore": 1.0,
      "packetLoss": 4.42,
      "latency": 951.0,
      "applicationDelay": 127.0
    },
    {
      "time": "2018-05-30T04:35:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 6.96,
      "latency": 337.0,
      "applicationDelay": 97.0
    },
    {
      "time": "2018-05-30T04:30:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 5.36,
      "latency": 388.0,
      "applicationDelay": 63.0
    },
    {
      "time": "2018-05-30T04:25:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 5.19,
      "latency": 658.0,
      "applicationDelay": 86.0
    },
    {
      "time": "2018-05-30T04:20:00.000+0000",
      "healthScore": 1.0,
      "packetLoss": 3.48,
      "latency": 987.0,
      "applicationDelay": 111.0
    },
    {
      "time": "2018-05-30T04:15:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 2.6,
      "latency": 401.0,
      "applicationDelay": 110.0
    },
    {
      "time": "2018-05-30T04:10:00.000+0000",
      "healthScore": 1.0,
      "packetLoss": 3.86,
      "latency": 1708.0,
      "applicationDelay": 146.0
    },
    {
      "time": "2018-05-30T04:05:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.17,
      "latency": 433.0,
      "applicationDelay": 155.0
    },
    {
      "time": "2018-05-30T04:00:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.34,
      "latency": 449.0,
      "applicationDelay": 144.0
    },
    {
      "time": "2018-05-30T03:55:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.02,
      "latency": 530.0,
      "applicationDelay": 111.0
    },
    {
      "time": "2018-05-30T03:50:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.55,
      "latency": 395.0,
      "applicationDelay": 54.0
    },
    {
      "time": "2018-05-30T03:45:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 1.79,
      "latency": 462.0,
      "applicationDelay": 59.0
    },
    {
      "time": "2018-05-30T03:40:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.35,
      "latency": 447.0,
      "applicationDelay": 69.0
    },
    {
      "time": "2018-05-30T03:35:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.77,
      "latency": 769.0,
      "applicationDelay": 68.0
    },
    {
      "time": "2018-05-30T03:30:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.54,
      "latency": 567.0,
      "applicationDelay": 67.0
    },
    {
      "time": "2018-05-30T03:25:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.09,
      "latency": 624.0,
      "applicationDelay": 61.0
    },
    {
      "time": "2018-05-30T03:20:00.000+0000",
      "healthScore": 1.0,
      "packetLoss": 5.03,
      "latency": 1646.0,
      "applicationDelay": 68.0
    },
    {
      "time": "2018-05-30T03:15:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 1.86,
      "latency": 93.0,
      "applicationDelay": 99.0
    },
    {
      "time": "2018-05-30T03:10:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.95,
      "latency": 30.0,
      "applicationDelay": 77.0
    },
    {
      "time": "2018-05-30T03:05:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 1.19,
      "latency": 53.0,
      "applicationDelay": 86.0
    },
    {
      "time": "2018-05-30T03:00:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 1.28,
      "latency": 33.0,
      "applicationDelay": 79.0
    },{
      "time": "2018-05-30T04:05:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.17,
      "latency": 433.0,
      "applicationDelay": 155.0
    },
    {
      "time": "2018-05-30T04:00:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.34,
      "latency": 449.0,
      "applicationDelay": 144.0
    },
    {
      "time": "2018-05-30T03:55:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 4.02,
      "latency": 530.0,
      "applicationDelay": 111.0
    },
    {
      "time": "2018-05-30T03:50:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 4.55,
      "latency": 395.0,
      "applicationDelay": 54.0
    },
    {
      "time": "2018-05-30T03:45:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 1.79,
      "latency": 462.0,
      "applicationDelay": 59.0
    },
    {
      "time": "2018-05-30T03:40:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.35,
      "latency": 447.0,
      "applicationDelay": 69.0
    },
    {
      "time": "2018-05-30T03:35:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.77,
      "latency": 769.0,
      "applicationDelay": 68.0
    },
    {
      "time": "2018-05-30T03:30:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.54,
      "latency": 567.0,
      "applicationDelay": 67.0
    },
    {
      "time": "2018-05-30T03:25:00.000+0000",
      "healthScore": 2.0,
      "packetLoss": 3.09,
      "latency": 624.0,
      "applicationDelay": 61.0
    },
    {
      "time": "2018-05-30T03:20:00.000+0000",
      "healthScore": 1.0,
      "packetLoss": 5.03,
      "latency": 1646.0,
      "applicationDelay": 68.0
    }
  ]
}
});