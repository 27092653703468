define({
    "SOFTWARE_17_3": {
        "bulletinHeadline": "Cisco IOS XE 17.3.x",
        "bulletinNumber": "EOL14513",
        "bulletinURL": "https://www.cisco.com/c/en/us/products/collateral/ios-nx-os-software/ios-xe-17/ios-xe-17-3-x-eol.html",
        "endOfHardwareNewServiceAttachmentDate": 0,
        "endOfHardwareServiceContractRenewalDate": 0,
        "endOfLastHardwareShipDate": 1648684800000,
        "endOfLifeDate": 1806451200000,
        "endOfLifeExternalAnnouncementDate": 1632960000000,
        "endOfSaleDate": 1648684800000,
        "endOfSignatureReleasesDate": 0,
        "endOfSoftwareVulnerabilityOrSecuritySupportDate": 1696032000000,
        "endOfSoftwareVulnerabilityOrSecuritySupportDateHw": 0,
        "endOfSoftwareMaintenanceReleasesDate": 0,
        "eoxAlertType": "SOFTWARE",
        "lastDateOfSupport": 0,
        "name": "EOL14513"
    },
    "MODULE_17_3": {
        "bulletinName": "C3850 Fiber SKUs",
        "bulletinNumber": "EOL13873",
        "bulletinURL": "https://www.cisco.com/c/en/us/products/collateral/switches/catalyst-3850-series-switches/eos-eol-notice-c51-2438804.html",
        "bulletinPID": "PWR-C1-1100WAC",
        "endOfHardwareNewServiceAttachmentDate": 1682812800000,
        "endOfHardwareServiceContractRenewalDate": 1785283200000,
        "endOfLastHardwareShipDate": 1659139200000,
        "endOfLifeDate": 0,
        "endOfLifeExternalAnnouncementDate": 1619740800000,
        "endOfSaleDate": 1651276800000,
        "endOfSignatureReleasesDate": 0,
        "endOfSoftwareVulnerabilityOrSecuritySupportDate": 0,
        "endOfSoftwareVulnerabilityOrSecuritySupportDateHw": 1745884800000,
        "endOfSoftwareMaintenanceReleasesDate": 1682812800000,
        "eoxAlertType": "MODULE",
        "lastDateOfSupport": 1809043200000,
        "name": "EOL13873",
        "eoXPhysicalType": "Power Supply"
    },
    "HARDWARE": {
        "bulletinName": "Cisco Catalyst 3850 switches",
        "bulletinNumber": "EOL13188",
        "bulletinURL": "https://www.cisco.com/c/en/us/products/collateral/switches/catalyst-3850-series-switches/eos-eol-notice-c51-743072.html",
        "bulletinPID": "WS-C3850-24U-E",
        "endOfHardwareNewServiceAttachmentDate": 1635552000000,
        "endOfHardwareServiceContractRenewalDate": 1738022400000,
        "endOfLastHardwareShipDate": 1611878400000,
        "endOfLifeDate": 0,
        "endOfLifeExternalAnnouncementDate": 1572480000000,
        "endOfSaleDate": 1604016000000,
        "endOfSignatureReleasesDate": 0,
        "endOfSoftwareVulnerabilityOrSecuritySupportDate": 0,
        "endOfSoftwareVulnerabilityOrSecuritySupportDateHw": 1698624000000,
        "endOfSoftwareMaintenanceReleasesDate": 1635552000000,
        "eoxAlertType": "HARDWARE",
        "lastDateOfSupport": 1761868800000,
        "name": "EOL13188",
        "eoXPhysicalType": "Chassis"
    },
    "SOFTWARE_LINKS": {
        "17.2":"https://www.cisco.com/c/en/us/products/collateral/ios-nx-os-software/ios-xe-17/eos-eol-notice-c51-744388.html",
        "17.4":"https://www.cisco.com/c/en/us/products/collateral/ios-nx-os-software/ios-xe-17/eos-eol-notice-c51-2442351.html",
        "17.5":"https://www.cisco.com/c/en/us/products/collateral/ios-nx-os-software/ios-xe-17/ios-xe-17-5-x-eol.html",
        "17.6":"https://www.cisco.com/c/en/us/products/collateral/ios-nx-os-software/ios-xe-17/ios-xe-17-6-x-eol.html",
        "17.7":"https://www.cisco.com/c/en/us/products/collateral/ios-nx-os-software/ios-xe-17/ios-xe-17-7-x-eol.html",
        "17.8":"https://www.cisco.com/c/en/us/products/collateral/ios-nx-os-software/ios-xe-17/ios-xe-17-8-x-eol.html"
    }
});