define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimMdfData'
  ], function (SimMdfData) {
    return {
      init: function () {
      },

      doProcessRestRequest: function (urlAction) {
        var data={};
        switch (urlAction.method) {
          case "GET":
            return processGetRequest(urlAction);
          default:
            break;
        }
        return data;
      }
    };

    function processGetRequest(urlAction) {
        if((urlAction.url.split('/')).indexOf('mdfdata')>-1) {
          return SimMdfData.getPidList(true);
        } else if(urlAction.service.indexOf('pids')>-1 && urlAction.service.indexOf('flat')>-1) {
          return SimMdfData.getPidList(false);
        } else if(urlAction.service.indexOf('details')>-1) {
           return SimMdfData.getPidDetail(urlAction.filter['ciscoPID']);
        } else if(urlAction.service.indexOf('tree')>-1) {
          return SimMdfData.getNfvisMdfProv();
        }
    }

});
