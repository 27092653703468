define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/cfs/SimCfsInit',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/graphql/SimGraphQL'
], function (SimCfsInit, SimGraphQL) {
       return {
           init: function() {
               SimCfsInit.init();
           }
       };
});
