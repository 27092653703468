define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimGlobalCredentialData',
    ], function (SimLokiDatabaseActions, SimTaskDataGenerator, SimGlobalCredentialData) {

        return {
            init: function() {
            },

            doProcessRestRequest: function(urlAction){
                console.log(urlAction.url);
                console.log(urlAction);
                var data;
                switch(urlAction.method) {
                    case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                    case 'POST':
                    data = processPostRequest(urlAction);
                    break;

                    case 'PUT':
                    data = processPutRequest(urlAction);
                    break;

                    case 'DELETE':
                    data = processDeleteRequest(urlAction);
                    break;

                    default:
                    break;
                }

                return data;
            }
        };

    function processGetRequest(urlAction){
        if(urlAction.service.indexOf('global-credential') >= 0){
            return SimGlobalCredentialData.getCredential(urlAction);
        }
    }

    function processPostRequest(urlAction){
       return SimGlobalCredentialData.createCredential(urlAction);
    }

    function processPutRequest(urlAction) {
       return SimGlobalCredentialData.updateCredential(urlAction);
    }

    function processDeleteRequest(urlAction) {

     return SimGlobalCredentialData.deleteCredential(urlAction);
    }

});
