define({
"Template":
{
  "version": "1.0",
  "response": {
    "detailList": [
      {
        "itemType": "ALL",
        "timestamp": "2020-05-07T10:40:00.000+0000",
        "count": 539,
        "nestedItems": [
          {
            "itemType": "IEEE2",
            "timestamp": "2020-05-07T10:40:00.000+0000",
            "count": 394
          },
          {
            "itemType": "IEEE4",
            "timestamp": "2020-05-07T10:40:00.000+0000",
            "count": 83
          },
          {
            "itemType": "IEEE3",
            "timestamp": "2020-05-07T10:40:00.000+0000",
            "count": 36
          },
          {
            "itemType": "CISCO",
            "timestamp": "2020-05-07T10:40:00.000+0000",
            "count": 13
          },
          {
            "itemType": "IEEE1",
            "timestamp": "2020-05-07T10:40:00.000+0000",
            "count": 10
          },
          {
            "itemType": "IEEE8",
            "timestamp": "2020-05-07T10:40:00.000+0000",
            "count": 2
          }
        ]
      }
    ]
  }
}
})

