//asr1000rp1 Cisco ASR 1002 Router
define({

  "runningImageList": [
    {
      "name": "asr1000rp1-adventerprisek9.03.17.04.S.156-1.S4-std.b",
      "imageUuid": "afc318e2-1ff3-429a-ad5d-a4ab9c76c60c",
      "version": "03.17.04.S",
      "softwareType": null,
      "imageType": "RELEASE",
      "isTaggedGolden": "false",
      "md5Checksum": null,
      "shaCheckSum": null,
      "createdTime": null,
      "applicationType": null,
      "feature": null,
      "fileServiceId": null,
      "imageSeries": null,
      "imageSource": null,
      "imageSourceType": null,
      "imageFamily": "asr1000rp1",
      "vendor": null,
      "filesize": 0,
      "deviceCount": 0,
      "deviceUuidList": [
      ],
      "smuCount": 0,
      "imageAttributes": [

      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": null,
      "ccorecommended": false,
      "ccolatest": false
    },
    {
      "name": "asr1000rp1-ipbasek9.03.02.01.S.151-1.S1.bin",
      "imageUuid": "3a6058db-9dba-4df2-9577-83cd8014c750",
      "version": "3.2.1S",
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "ce421775e9c46546ba86dc945dc8fd8b",
      "shaCheckSum": "1da9f1e57e409cb63213550386f0e6b475fb4084d91266222fee7d63a8d764189e2fb9bf4536ed08c4ab5abbccc1da93b3a34c8dfefa7cf3457c17dfda7e1623",
      "createdTime": "2018-09-12 02:20:33.508",
      "applicationType": "UNKNOWN",
      "feature": null,
      "fileServiceId": "d15d786c-567e-89e0-aa32-f2619cebe23c",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "asr1000rp1",
      "vendor": "CISCO",
      "filesize": 270158136,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "deviceMNEId",
          "propertyValue": "122293"
        },
        {
          "propertyName": "field_notices",
          "propertyValue": "Field Notices"
        },
        {
          "propertyName": "image_guid",
          "propertyValue": "58050F7925F648BEE73AF6A0E11A135978E35F40"
        },
        {
          "propertyName": "udi",
          "propertyValue": "PID: ASR1004 VID: V02, SN: FOX1521G5SM"
        },
        {
          "propertyName": "mdf_id",
          "propertyValue": "281782372,281782371"
        },
        {
          "propertyName": "image_size",
          "propertyValue": "270158136"
        },
        {
          "propertyName": "image_description",
          "propertyValue": "Cisco ASR 1000 Series RP1 IP BASE"
        },
        {
          "propertyName": "metadata_trans_id",
          "propertyValue": "141202115"
        },
        {
          "propertyName": "min_flash",
          "propertyValue": "1024"
        },
        {
          "propertyName": "field_notice_url",
          "propertyValue": "https://www.cisco.com/en/US/products/ps9343/prod_field_notices_list.html"
        },
        {
          "propertyName": "min_dram",
          "propertyValue": "2048"
        },
        {
          "propertyName": "encryption_software_indicator",
          "propertyValue": "Y"
        },
        {
          "propertyName": "release_fcs_date",
          "propertyValue": "14/Feb/2011"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "",
      "imageImported": true,
      "applicableDevicesForImage": null,
      "ccorecommended": false,
      "ccolatest": true
    }
  ]
});