define({
    "Service_Deployment_Status_Template":
    [{
      "id": "28925f85-84f2-4228-a183-75d3d9a14ee0",
      "instanceId": 1456659,
      "displayName": "1456659",
      "instanceVersion": 2,
      "cfsVersion": 105,
      "createTime": 1571383524403,
      "lastUpdateTime": 1571383550436,
      "namespace": "e6b9d8a9-4d1a-4274-86a6-f05c563ce75e",
      "provisioningDescription": "Update device configurations for fabric Site@ToChange ",
      "rollbackVersion": 0,
      "status": "DEPLOYED",
      "taskEndtime": 1571383550906,
      "taskId": "cf5b8f57-63fa-4654-87fe-577e56e54d3d",
      "taskStarttime": 1571383521208,
      "type": "ConnectivityDomain",
      "workflowId": "53495dde-7a66-4e69-b754-5c9d613fe9fd"
    },
    {
      "id": "a251f1df-c535-4ca2-9f9e-ccaaa1643cab",
      "instanceId": 1456657,
      "displayName": "1456657",
      "instanceVersion": 2,
      "cfsVersion": 104,
      "createTime": 1571383502105,
      "lastUpdateTime": 1571383507232,
      "namespace": "e6b9d8a9-4d1a-4274-86a6-f05c563ce75e",
      "provisioningDescription": "Update device provision config for device Device@ToChange",
      "rollbackVersion": 0,
      "status": "DEPLOYED",
      "taskEndtime": 1571383507724,
      "taskId": "2950f26b-4f61-4514-8915-fd61443b457e",
      "taskStarttime": 1571383500438,
      "type": "DeviceInfo",
      "workflowId": "d6f7c035-7ce4-4032-9591-0ac85e9973eb"
    }]


});