define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/design/NetworkPrgmrTemplate'
], function (NetworkPrgmrTemplate) {

    return {
        init: function() {
        },

        doProcessRestRequest: function(urlAction){
           console.log(urlAction);
            var data = {};
            switch(urlAction.method) {
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;
                default:
                    break;
            }
            return data;
        }
    };

    function processGetRequest(urlAction){
        if(urlAction.service.indexOf('network-programmer')>-1) {
            if(urlAction.service.indexOf('nom-commands-list')>-1) {
                return JSON.parse(JSON.stringify(NetworkPrgmrTemplate.CommandList_Template));
            } else if(urlAction.service.indexOf('nom-cli-keywords-list')>-1) {
                return JSON.parse(JSON.stringify(NetworkPrgmrTemplate.CliList_Template));
            } else if(urlAction.service.indexOf('nom-sub-commands-syntax-tree')>-1) {
                return JSON.parse(JSON.stringify(NetworkPrgmrTemplate.Nom_sub_commands_syntax_tree_Template));
            }
        } else if(urlAction.service.indexOf('resource-manager')>-1) {
            return JSON.parse(JSON.stringify(NetworkPrgmrTemplate.Resource_Template));
        }
    }

});
