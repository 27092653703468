define({
    "AAA_Template": [{
        ipAddress: "172.21.228.240",
        sharedSecret:"",
        protocol: "RADIUS_TACACS",
        role: "primary",
        port:0,
        authenticationPort: 1812,
        accountingPort: 1813,
        retries: 1,
        timeoutSeconds: 2,
        isIseEnabled: true,
        instanceUuid: "7d891b8a-33e0-4216-bbc4-c8979e805855",
        rbacUuid: "95e81cf1-dce1-4a7b-868e-a7285f240bae",
        state: "ACTIVE",
        ciscoIseDtos: [{subscriberName: "ersadmin", description: null, password: "", userName: "admin",
            fqdn: "ISE-summetha.cisco.com", ipAddress: "172.21.228.240", trustState: "TRUSTED",
            instanceUuid: "7201b206-0dcf-407b-82e4-1253eff361c0", sshkey:"", type: "ISE", failureReason: null,
            role: "PRIMARY"
        }],
        externalCiscoIseIpAddrDtos:[{subscriberName: "ersadmin", description: null, password: "", userName: "admin",
            fqdn: "ISE-summetha.cisco.com", ipAddress: "172.21.228.240", trustState: "TRUSTED",
            instanceUuid: "7201b206-0dcf-407b-82e4-1253eff361c0", sshkey:"", type: "ISE", failureReason: null,
            role: "PRIMARY"
        }],
        iseEnabled: true
    }],
    "AAA_Template_1_3_1":[
        {
          "ipAddress": "dcloud-ise-ctf-inst-xxx.cisco.com",
          "sharedSecret": "",
          "protocol": "RADIUS",
          "role": "primary",
          "port": 0,
          "authenticationPort": 1812,
          "accountingPort": 1813,
          "retries": 3,
          "timeoutSeconds": 4,
          "isIseEnabled": true,
          "instanceUuid": "4958c984-b388-44b0-a6fc-f22d05188322",
          "rbacUuid": "a2b39b97-00c3-4b6b-854b-e47b5a39b75a",
          "state": "ACTIVE",
          "ciscoIseDtos": [
            {
              "subscriberName": "DNAC-58-83",
              "description": "",
              "password": "",
              "userName": "admin",
              "fqdn": "ISE-186-86.cisco.com",
              "ipAddress": "dcloud-ise-ctf-inst-xxx.cisco.com",
              "trustState": "TRUSTED",
              "instanceUuid": "5a2c3ae7-ebd8-4155-bd69-c12b6d606d85",
              "sshkey": "",
              "type": "ISE",
              "failureReason": null,
              "role": "PRIMARY"
            }
          ],
          "externalCiscoIseIpAddrDtos": [],
          "iseEnabled": true,
          "pxgridEnabled":true,
          "encryptionKey": "",
          "encryptionScheme": "",
          "messageKey": "",
          "useDnacCertForPxgrid": false
        },
        {
            "ipAddress": "10.1.30.71",
            "sharedSecret": "",
            "protocol": "RADIUS",
            "role": "secondary",
            "port": 49,
            "authenticationPort": 1812,
            "accountingPort": 1813,
            "retries": 1,
            "timeoutSeconds": 10,
            "isIseEnabled": false,
            "instanceUuid": "aabfc3d1-ac8a-48b6-94ad-03419005c1e2",
            "rbacUuid": "00b68733-68d1-4caf-98c4-d888452f003d",
            "state": "ACTIVE",
            "ciscoIseDtos": [],
            "externalCiscoIseIpAddrDtos": [],
            "pxgridEnabled": true,
            "iseEnabled": false,
            "pxgridEnabled":true,
            "encryptionKey": "",
            "encryptionScheme": "",
            "messageKey": "",
            "useDnacCertForPxgrid": false,
            "doAvgLatency": null,
            "eapAvgLatency": 15,
            "eapFailedTransaction": 0,
            "eapTransaction": 200,
            "failedTransaction": 0,
            "mabAvgLatency": null,
            "mabFailedTransaction": 0,
            "mabTransaction": 0,
            "raAvgLatency": null,
            "totalAvgLatency": 15,
            "totalTransaction": 200,
            "wlcLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
            "wlcName": "WLC-FABRIC-01",
            "wlcUuid": "4a273d8a-4ffd-1670-ab97-52b27d286cfe"
        },
        {
            "ipAddress": "10.1.30.75",
            "sharedSecret": "",
            "protocol": "RADIUS",
            "role": "secondary",
            "port": 49,
            "authenticationPort": 1812,
            "accountingPort": 1813,
            "retries": 1,
            "timeoutSeconds": 10,
            "isIseEnabled": false,
            "instanceUuid": "aabfc3d1-ac8a-48b6-85fd-03419005c1e2",
            "rbacUuid": "00b68733-68d1-4caf-67d3-d888452f003d",
            "state": "ACTIVE",
            "ciscoIseDtos": [],
            "externalCiscoIseIpAddrDtos": [],
            "pxgridEnabled": true,
            "iseEnabled": false,
            "pxgridEnabled":true,
            "encryptionKey": "",
            "encryptionScheme": "",
            "messageKey": "",
            "useDnacCertForPxgrid": false,
            "eapAvgLatency": 12,
            "eapFailedTransaction": 0,
            "eapTransaction": 195,
            "failedTransaction": 0,
            "mabAvgLatency": null,
            "mabFailedTransaction": 0,
            "mabTransaction": 0,
            "raAvgLatency": null,
            "totalAvgLatency": 12,
            "totalTransaction": 195,
            "wlcLocation": "Global/North America/USA/California/San Jose/SJC06/Flr-SJC6-1",
            "wlcName": "SJC06-vWLC-9800",
            "wlcUuid": "4b5976d6-6eb5-62fd-e7da-1647d682aafe"
        },
        {
            "ipAddress": "10.1.30.89",
            "sharedSecret": "",
            "protocol": "RADIUS",
            "role": "secondary",
            "port": 49,
            "authenticationPort": 1812,
            "accountingPort": 1813,
            "retries": 1,
            "timeoutSeconds": 10,
            "isIseEnabled": false,
            "instanceUuid": "aabfc3d1-ac8a-48b6-37ea-03419005c1e2",
            "rbacUuid": "00b68733-68d1-4caf-45a2-d888452f003d",
            "state": "ACTIVE",
            "ciscoIseDtos": [],
            "externalCiscoIseIpAddrDtos": [],
            "pxgridEnabled": true,
            "iseEnabled": false,
            "pxgridEnabled":true,
            "encryptionKey": "",
            "encryptionScheme": "",
            "messageKey": "",
            "useDnacCertForPxgrid": false,
            "eapAvgLatency": 8,
            "eapFailedTransaction": 0,
            "eapTransaction": 167,
            "failedTransaction": 0,
            "mabAvgLatency": null,
            "mabFailedTransaction": 0,
            "mabTransaction": 0,
            "raAvgLatency": null,
            "totalAvgLatency": 8,
            "totalTransaction": 167,
            "wlcLocation": "Global/North America/USA/California/San Jose/SJC22/Flr-SJC22-1",
            "wlcName": "WLC-3504",
            "wlcUuid": "2a9bc297-ec2d-3795-408b-a33faff3508c"
        }
      ]
});
