define({
    "TIMESTAMP_CLIENTCOUNT_SSID": {
      "version": 1.0,
      "response": {
        "detailList": [{
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }, {
          "breakdown": null,
          "bucket": null,
          "value1": 0,
          "value2": 0,
          "scoreList": [{
            "breakdown": "c9800AP11AC",
            "bucket": null,
            "value1": 199,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "CorpSSID",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }, {
            "breakdown": "c9800AP11AX",
            "bucket": null,
            "value1": 25,
            "value2": 0,
            "scoreList": []
          }]
        }],
        "legendList": []
      }
    },
    "NAMES": [{"name":"c9800AP11AC","avg":199},{"name":"CorpSSID","avg":25},{"name":"c9800AP11AX","avg":25}]
});
