define({
    "Wlan_Template": {

      "id": "8fcf8340-f58b-44f5-8df1-e590c55433e9",
      "instanceId": 1110116,
      "authEntityId": 1110116,
      "displayName": "1d594268[guest-comm_F_7e45c,c0d0aacc-9238-4b82-92db-f31a8954ef30]",
      "authEntityClass": 492388968,
      "deployPending": "NONE",
      "instanceVersion": 14,
      "deployed": false,
      "isSeeded": false,
      "isStale": false,
      "namespace": "fb744ab3-fe46-4247-912d-82f5f5ad2f1e,1d3452a4-2e4b-4cf3-a823-f4d3e4bd5119",
      "provisioningState": "UNKNOWN",
      "resourceVersion": 14,
      "type": "Wlan",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "authMode": "WEBAUTH",
      "fastTransition": "ADAPTIVE",
      "flexAuthMode": "CENTRAL_AUTH",
      "isEnabled": true,
      "isFabric": true,
      "isFastLane": false,
      "isFlexConnect": false,
      "isMacfilteringEnabled": false,
      "managedSites": [
        "be273f1f-98e4-49f5-860d-135dd77423e7"
      ],
      "networkDeviceId": "c0d0aacc-9238-4b82-92db-f31a8954ef30",
      "peerIp": "",
      "profileName": "guest-comm_F_7e45c",
      "ssid": "guest-common",
      "switchingMode": "CENTRAL_SWITCH",
      "trafficType": "VOICE_VIDEO",
      "wlanId": 17,
      "wlanType": "Guest",
      "wirelessAuth": {
        "id": "0b7e8957-bd6d-4057-964d-2b1c1ef8ae6f",
        "instanceId": 1520521,
        "displayName": "0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "authServerIp": {
          "paddedAddress": "  1.  1.  1.  1",
          "addressType": "IP_V4",
          "address": "1.1.1.1"
        },
        "authServerIse": false,
        "authServerUrl": "https://1.1.1.1/login"
      }
    },

    "Enterprise_Wlan_Template": [
      {
        "groupUuid": "-1",
        "instanceType": "wlan",
        "key": "wlan.info.@CorpSSID",
        "namespace": "wlan",
        "type": "wlan.setting",
        "value": [
        {
          "acctServers": [
            "dcloud-ise-ctf-inst-xxx.cisco.com"
        ],
        "authServer": "auth_ise",
        "authServers": ["dcloud-ise-ctf-inst-xxx.cisco.com"],
        "authType": "wpa2_enterprise",
        "authSecServer": "",
        "externalAuthIpAddress": "",
        "fastTransition": "ADAPTIVE",
        "fabricId": null,
        "isEnabled": true,
        "isFabric":true,
        "isFastLaneEnabled": false,
        "isMacfilteringEnabled": false,
        "isUpnEnabled":true,
        "passphrase": "",
        "peerIp": "",
        "portalName": "",
        "portalType": "",
        "profileName": "@CorpSSID",
        "redirectUrl": "",
        "redirectUrlType": "",
        "radioPolicy": 0,
        "scalableGroupTag": "",
        "ssid": "@CorpSSID",
        "trafficType": "voicedata",
        "isBroadcastSSID": true,
        "wlanBandSelectEnable": false,
        "wlanType": "Enterprise",
        "basicServiceSetClientIdleTimeout": 300,
        "aclName": "",
        "basicServiceSetMaxIdleEnable": true,
        "clientExclusionEnable": true,
        "clientExclusionTimeout": 180,
        "directedMulticastServiceEnable": true,
        "fastTransitionOverTheDistributedSystemEnable": true,
        "isBrownfield": false,
        "isCustomNasIdOptions": false,
        "isEmailReqd": false,
        "isHex": false,
        "isPosturingEnabled": false,
        "isRadiusProfilingEnabled": true,
        "isRandomMacFilterEnabled": false,
        "isSensorPnp": false,
        "isUnicastFilteringEnabled": false,
        "l3AuthType": "open",
        "managementFrameProtectionClientprotection": "Optional",
        "nasOptions": [],
        "neighborListEnable": true,
        "openSsid": "",
        "passiveClient": false,
        "policyProfileName": "wlan.info.@CorpSSID",
        "sessionTimeOut": 1800,
        "sessionTimeOutEnable": true,
        "sleepingClientEnable": false,
        "sleepingClientTimeout": 720,
        "webPassthrough": false,
        "isUpgraded": true,
        "retainOldAcctServerBehaviour": true,
        },
        ],
        "version": 6
        },

      {
      "groupUuid": "-1",
      "instanceType": "wlan",
      "key": "wlan.info.Corporate",
      "namespace": "wlan",
      "type": "wlan.setting",
      "value": [
      {
        "acctServers": [
          "dcloud-ise-ctf-inst-xxx.cisco.com"
      ],
      "authServers": ["dcloud-ise-ctf-inst-xxx.cisco.com"],
      "authType": "wpa2_enterprise",
      "authSecServer": "",
      "externalAuthIpAddress": "",
      "fastTransition": "ADAPTIVE",
      "fabricId": null,
      "isEnabled": true,
      "isFabric":true,
      "isFastLaneEnabled": false,
      "isMacfilteringEnabled": false,
      "passphrase": "",
      "peerIp": "",
      "portalName": "",
      "portalType": "",
      "profileName": "",
      "redirectUrl": "",
      "redirectUrlType": "",
      "radioPolicy": 0,
      "scalableGroupTag": "",
      "ssid": "Corporate",
      "trafficType": "voicedata",
      "isBroadcastSSID": true,
      "wlanBandSelectEnable": false,
      "wlanType": "Enterprise",
      "basicServiceSetClientIdleTimeout": 300,
      "aclName": "",
      "basicServiceSetMaxIdleEnable": true,
      "clientExclusionEnable": true,
      "clientExclusionTimeout": 180,
      "directedMulticastServiceEnable": true,
      "fastTransitionOverTheDistributedSystemEnable": true,
      "isBrownfield": false,
      "isCustomNasIdOptions": false,
      "isEmailReqd": false,
      "isHex": false,
      "isPosturingEnabled": false,
      "isRadiusProfilingEnabled": true,
      "isRandomMacFilterEnabled": false,
      "isSensorPnp": false,
      "isUnicastFilteringEnabled": false,
      "l3AuthType": "open",
      "managementFrameProtectionClientprotection": "Optional",
      "nasOptions": [],
      "neighborListEnable": true,
      "openSsid": "",
      "passiveClient": false,
      "policyProfileName": "wlan.info.Corporate",
      "sessionTimeOut": 1800,
      "sessionTimeOutEnable": true,
      "sleepingClientEnable": false,
      "sleepingClientTimeout": 720,
      "webPassthrough": false,
      "isUpgraded": true,
      "retainOldAcctServerBehaviour": true,
      },

      ],
      "version": 6
      },

      {
        "groupUuid": "-1",
        "instanceType": "wlan",
        "key": "wlan.info.c9800AP11AX",
        "namespace": "wlan",
        "type": "wlan.setting",
        "value": [
        {
          "acctServers": [
            "dcloud-ise-ctf-inst-xxx.cisco.com"
        ],
        "authServers": ["dcloud-ise-ctf-inst-xxx.cisco.com"],
        "authType": "wpa2_enterprise",
        "authSecServer": "",
        "externalAuthIpAddress": "",
        "fastTransition": "ADAPTIVE",
        "fabricId": null,
        "isEnabled": true,
        "isFabric":true,
        "isFastLaneEnabled": false,
        "isMacfilteringEnabled": false,
        "passphrase": "",
        "peerIp": "",
        "portalName": "",
        "portalType": "",
        "profileName": "",
        "redirectUrl": "",
        "redirectUrlType": "",
        "radioPolicy": 0,
        "scalableGroupTag": "",
        "ssid": "c9800AP11AX",
        "trafficType": "voicedata",
        "isBroadcastSSID": true,
        "wlanBandSelectEnable": false,
        "wlanType": "Enterprise",
        "basicServiceSetClientIdleTimeout": 300,
        "aclName": "",
        "basicServiceSetMaxIdleEnable": true,
        "clientExclusionEnable": true,
        "clientExclusionTimeout": 180,
        "directedMulticastServiceEnable": true,
        "fastTransitionOverTheDistributedSystemEnable": true,
        "isBrownfield": false,
        "isCustomNasIdOptions": false,
        "isEmailReqd": false,
        "isHex": false,
        "isPosturingEnabled": false,
        "isRadiusProfilingEnabled": true,
        "isRandomMacFilterEnabled": false,
        "isSensorPnp": false,
        "isUnicastFilteringEnabled": false,
        "l3AuthType": "open",
        "managementFrameProtectionClientprotection": "Optional",
        "nasOptions": [],
        "neighborListEnable": true,
        "openSsid": "",
        "passiveClient": false,
        "policyProfileName": "wlan.info.c9800AP11AX",
        "sessionTimeOut": 1800,
        "sessionTimeOutEnable": true,
        "sleepingClientEnable": false,
        "sleepingClientTimeout": 720,
        "webPassthrough": false,
        "isUpgraded": true,
        "retainOldAcctServerBehaviour": true,

        },

        ],
        "version": 6
        },
         {
        "groupUuid": "-1",
        "instanceType": "wlan",
        "key": "wlan.info.c9800AP11AC",
        "namespace": "wlan",
        "type": "wlan.setting",
        "value": [
        {
          "acctServers": [
            "dcloud-ise-ctf-inst-xxx.cisco.com"
        ],
        "authServers": ["dcloud-ise-ctf-inst-xxx.cisco.com"],
        "authType": "wpa2_enterprise",
        "authSecServer": "",
        "externalAuthIpAddress": "",
        "fastTransition": "ADAPTIVE",
        "fabricId": null,
        "isEnabled": true,
        "isFabric":true,
        "isFastLaneEnabled": false,
        "isMacfilteringEnabled": false,
        "passphrase": "",
        "peerIp": "",
        "portalName": "",
        "portalType": "",
        "profileName": "",
        "redirectUrl": "",
        "redirectUrlType": "",
        "radioPolicy": 0,
        "scalableGroupTag": "",
        "ssid": "c9800AP11AC",
        "trafficType": "voicedata",
        "isBroadcastSSID": true,
        "wlanBandSelectEnable": false,
        "wlanType": "Enterprise",
        "basicServiceSetClientIdleTimeout": 300,
        "aclName": "",
        "basicServiceSetMaxIdleEnable": true,
        "clientExclusionEnable": true,
        "clientExclusionTimeout": 180,
        "directedMulticastServiceEnable": true,
        "fastTransitionOverTheDistributedSystemEnable": true,
        "isBrownfield": false,
        "isCustomNasIdOptions": false,
        "isEmailReqd": false,
        "isHex": false,
        "isPosturingEnabled": false,
        "isRadiusProfilingEnabled": true,
        "isRandomMacFilterEnabled": false,
        "isSensorPnp": false,
        "isUnicastFilteringEnabled": false,
        "l3AuthType": "open",
        "managementFrameProtectionClientprotection": "Optional",
        "nasOptions": [],
        "neighborListEnable": true,
        "openSsid": "",
        "passiveClient": false,
        "policyProfileName": "wlan.info.c9800AP11AC",
        "sessionTimeOut": 1800,
        "sessionTimeOutEnable": true,
        "sleepingClientEnable": false,
        "sleepingClientTimeout": 720,
        "webPassthrough": false,
        "isUpgraded": true,
        "retainOldAcctServerBehaviour": true,

        },

        ],
        "version": 6
        },{
      "groupUuid": "-1",
      "instanceType": "wlan",
      "key": "wlan.info.DEMO-NF-ASSURANCE",
      "namespace": "wlan",
      "type": "wlan.setting",
      "value": [
      {
        "acctServers": [
          "dcloud-ise-ctf-inst-xxx.cisco.com"
      ],
      "authServers": ["dcloud-ise-ctf-inst-xxx.cisco.com"],
      "authType": "wpa2_enterprise",
      "authSecServer": "",
      "externalAuthIpAddress": "",
      "fastTransition": "ADAPTIVE",
      "fabricId": null,
      "isEnabled": true,
      "isFabric":true,
      "isFastLaneEnabled": false,
      "isMacfilteringEnabled": false,
      "passphrase": "",
      "peerIp": "",
      "portalName": "",
      "portalType": "",
      "profileName": "",
      "redirectUrl": "",
      "redirectUrlType": "",
      "radioPolicy": 0,
      "scalableGroupTag": "",
      "ssid": "DEMO-NF-ASSURANCE",
      "trafficType": "voicedata",
      "isBroadcastSSID": true,
      "wlanBandSelectEnable": false,
      "wlanType": "Enterprise",
      "basicServiceSetClientIdleTimeout": 300,
      "aclName": "",
      "basicServiceSetMaxIdleEnable": true,
      "clientExclusionEnable": true,
      "clientExclusionTimeout": 180,
      "directedMulticastServiceEnable": true,
      "fastTransitionOverTheDistributedSystemEnable": true,
      "isBrownfield": false,
      "isCustomNasIdOptions": false,
      "isEmailReqd": false,
      "isHex": false,
      "isPosturingEnabled": false,
      "isRadiusProfilingEnabled": true,
      "isRandomMacFilterEnabled": false,
      "isSensorPnp": false,
      "isUnicastFilteringEnabled": false,
      "l3AuthType": "open",
      "managementFrameProtectionClientprotection": "Optional",
      "nasOptions": [],
      "neighborListEnable": true,
      "openSsid": "",
      "passiveClient": false,
      "policyProfileName": "wlan.info.DEMO-NF-ASSURANCE",
      "sessionTimeOut": 1800,
      "sessionTimeOutEnable": true,
      "sleepingClientEnable": false,
      "sleepingClientTimeout": 720,
      "webPassthrough": false,
      "isUpgraded": true,
      "retainOldAcctServerBehaviour": true,

      },

      ],
      "version": 6
      },
       {
      "groupUuid": "-1",
      "instanceType": "wlan",
      "key": "wlan.info.DEMO-FEW-ASSURANCE",
      "namespace": "wlan",
      "type": "wlan.setting",
      "value": [
      {
        "acctServers": [
          "dcloud-ise-ctf-inst-xxx.cisco.com"
      ],
      "authServers": ["dcloud-ise-ctf-inst-xxx.cisco.com"],
      "authType": "wpa2_enterprise",
      "authSecServer": "",
      "externalAuthIpAddress": "",
      "fastTransition": "ADAPTIVE",
      "fabricId": null,
      "isEnabled": true,
      "isFabric":true,
      "isFastLaneEnabled": false,
      "isMacfilteringEnabled": false,
      "passphrase": "",
      "peerIp": "",
      "portalName": "",
      "portalType": "",
      "profileName": "",
      "redirectUrl": "",
      "redirectUrlType": "",
      "radioPolicy": 0,
      "scalableGroupTag": "",
      "ssid": "DEMO-FEW-ASSURANCE",
      "trafficType": "voicedata",
      "isBroadcastSSID": true,
      "wlanBandSelectEnable": false,
      "wlanType": "Enterprise",
      "basicServiceSetClientIdleTimeout": 300,
      "aclName": "",
      "basicServiceSetMaxIdleEnable": true,
      "clientExclusionEnable": true,
      "clientExclusionTimeout": 180,
      "directedMulticastServiceEnable": true,
      "fastTransitionOverTheDistributedSystemEnable": true,
      "isBrownfield": false,
      "isCustomNasIdOptions": false,
      "isEmailReqd": false,
      "isHex": false,
      "isPosturingEnabled": false,
      "isRadiusProfilingEnabled": true,
      "isRandomMacFilterEnabled": false,
      "isSensorPnp": false,
      "isUnicastFilteringEnabled": false,
      "l3AuthType": "open",
      "managementFrameProtectionClientprotection": "Optional",
      "nasOptions": [],
      "neighborListEnable": true,
      "openSsid": "",
      "passiveClient": false,
      "policyProfileName": "wlan.info.DEMO-FEW-ASSURANCE",
      "sessionTimeOut": 1800,
      "sessionTimeOutEnable": true,
      "sleepingClientEnable": false,
      "sleepingClientTimeout": 720,
      "webPassthrough": false,
      "isUpgraded": true,
      "retainOldAcctServerBehaviour": true,

      },

      ],
      "version": 6
      },

       {
      "groupUuid": "-1",
      "instanceType": "wlan",
      "key": "wlan.info.FabricDemo",
      "namespace": "wlan",
      "type": "wlan.setting",
      "value": [
      {
        "acctServers": [
          "dcloud-ise-ctf-inst-xxx.cisco.com"
      ],
      "authServers": ["dcloud-ise-ctf-inst-xxx.cisco.com"],
      "authType": "wpa2_enterprise",
      "authSecServer": "",
      "externalAuthIpAddress": "",
      "fastTransition": "ADAPTIVE",
      "fabricId": null,
      "isEnabled": true,
      "isFabric":true,
      "isFastLaneEnabled": false,
      "isMacfilteringEnabled": false,
      "passphrase": "",
      "peerIp": "",
      "portalName": "",
      "portalType": "",
      "profileName": "",
      "redirectUrl": "",
      "redirectUrlType": "",
      "radioPolicy": 0,
      "scalableGroupTag": "",
      "ssid": "FabricDemo",
      "trafficType": "voicedata",
      "isBroadcastSSID": true,
      "wlanBandSelectEnable": false,
      "wlanType": "Enterprise",
      "basicServiceSetClientIdleTimeout": 300,
      "aclName": "",
      "basicServiceSetMaxIdleEnable": true,
      "clientExclusionEnable": true,
      "clientExclusionTimeout": 180,
      "directedMulticastServiceEnable": true,
      "fastTransitionOverTheDistributedSystemEnable": true,
      "isBrownfield": false,
      "isCustomNasIdOptions": false,
      "isEmailReqd": false,
      "isHex": false,
      "isPosturingEnabled": false,
      "isRadiusProfilingEnabled": true,
      "isRandomMacFilterEnabled": false,
      "isSensorPnp": false,
      "isUnicastFilteringEnabled": false,
      "l3AuthType": "open",
      "managementFrameProtectionClientprotection": "Optional",
      "nasOptions": [],
      "neighborListEnable": true,
      "openSsid": "",
      "passiveClient": false,
      "policyProfileName": "wlan.info.FabricDemo",
      "sessionTimeOut": 1800,
      "sessionTimeOutEnable": true,
      "sleepingClientEnable": false,
      "sleepingClientTimeout": 720,
      "webPassthrough": false,
      "isUpgraded": true,
      "retainOldAcctServerBehaviour": true,
      },

      ],
      "version": 6
      },  {
      "groupUuid": "-1",
      "instanceType": "wlan",
      "key": "wlan.info.SJC06_WLC",
      "namespace": "wlan",
      "type": "wlan.setting",
      "value": [
      {
        "acctServers": [
          "dcloud-ise-ctf-inst-xxx.cisco.com"
      ],
      "authServers": ["dcloud-ise-ctf-inst-xxx.cisco.com"],
      "authType": "wpa2_enterprise",
      "authSecServer": "",
      "externalAuthIpAddress": "",
      "fastTransition": "ADAPTIVE",
      "fabricId": null,
      "isEnabled": true,
      "isFabric":true,
      "isFastLaneEnabled": false,
      "isMacfilteringEnabled": false,
      "passphrase": "",
      "peerIp": "",
      "portalName": "",
      "portalType": "",
      "profileName": "",
      "redirectUrl": "",
      "redirectUrlType": "",
      "radioPolicy": 0,
      "scalableGroupTag": "",
      "ssid": "SJC06_WLC",
      "trafficType": "voicedata",
      "isBroadcastSSID": true,
      "wlanBandSelectEnable": false,
      "wlanType": "Enterprise",
      "basicServiceSetClientIdleTimeout": 300,
      "aclName": "",
      "basicServiceSetMaxIdleEnable": true,
      "clientExclusionEnable": true,
      "clientExclusionTimeout": 180,
      "directedMulticastServiceEnable": true,
      "fastTransitionOverTheDistributedSystemEnable": true,
      "isBrownfield": false,
      "isCustomNasIdOptions": false,
      "isEmailReqd": false,
      "isHex": false,
      "isPosturingEnabled": false,
      "isRadiusProfilingEnabled": true,
      "isRandomMacFilterEnabled": false,
      "isSensorPnp": false,
      "isUnicastFilteringEnabled": false,
      "l3AuthType": "open",
      "managementFrameProtectionClientprotection": "Optional",
      "nasOptions": [],
      "neighborListEnable": true,
      "openSsid": "",
      "passiveClient": false,
      "policyProfileName": "wlan.info.SJC06_WLC",
      "sessionTimeOut": 1800,
      "sessionTimeOutEnable": true,
      "sleepingClientEnable": false,
      "sleepingClientTimeout": 720,
      "webPassthrough": false,
      "isUpgraded": true,
      "retainOldAcctServerBehaviour": true,
      },

      ],
      "version": 6
      },  {
      "groupUuid": "-1",
      "instanceType": "wlan",
      "key": "wlan.info.SJC06_VWLC",
      "namespace": "wlan",
      "type": "wlan.setting",
      "value": [
      {
        "acctServers": [
          "dcloud-ise-ctf-inst-xxx.cisco.com"
      ],
      "authServers": ["dcloud-ise-ctf-inst-xxx.cisco.com"],
      "authType": "wpa2_enterprise",
      "authSecServer": "",
      "externalAuthIpAddress": "",
      "fastTransition": "ADAPTIVE",
      "fabricId": null,
      "isEnabled": true,
      "isFabric":true,
      "isFastLaneEnabled": false,
      "isMacfilteringEnabled": false,
      "passphrase": "",
      "peerIp": "",
      "portalName": "",
      "portalType": "",
      "profileName": "",
      "redirectUrl": "",
      "redirectUrlType": "",
      "radioPolicy": 0,
      "scalableGroupTag": "",
      "ssid": "SJC06_VWLC",
      "trafficType": "voicedata",
      "isBroadcastSSID": true,
      "wlanBandSelectEnable": false,
      "wlanType": "Enterprise",
      "basicServiceSetClientIdleTimeout": 300,
      "aclName": "",
      "basicServiceSetMaxIdleEnable": true,
      "clientExclusionEnable": true,
      "clientExclusionTimeout": 180,
      "directedMulticastServiceEnable": true,
      "fastTransitionOverTheDistributedSystemEnable": true,
      "isBrownfield": false,
      "isCustomNasIdOptions": false,
      "isEmailReqd": false,
      "isHex": false,
      "isPosturingEnabled": false,
      "isRadiusProfilingEnabled": true,
      "isRandomMacFilterEnabled": false,
      "isSensorPnp": false,
      "isUnicastFilteringEnabled": false,
      "l3AuthType": "open",
      "managementFrameProtectionClientprotection": "Optional",
      "nasOptions": [],
      "neighborListEnable": true,
      "openSsid": "",
      "passiveClient": false,
      "policyProfileName": "wlan.info.SJC06_VWLC",
      "sessionTimeOut": 1800,
      "sessionTimeOutEnable": true,
      "sleepingClientEnable": false,
      "sleepingClientTimeout": 720,
      "webPassthrough": false,
      "isUpgraded": true,
      "retainOldAcctServerBehaviour": true,

      },

      ],
      "version": 6
      },
       {
      "groupUuid": "-1",
      "instanceType": "wlan",
      "key": "wlan.info.SFO12_WLC",
      "namespace": "wlan",
      "type": "wlan.setting",
      "value": [
      {
        "acctServers": [
          "dcloud-ise-ctf-inst-xxx.cisco.com"
      ],
      "authServers": ["dcloud-ise-ctf-inst-xxx.cisco.com"],
      "authType": "wpa2_enterprise",
      "authSecServer": "",
      "externalAuthIpAddress": "",
      "fastTransition": "ADAPTIVE",
      "fabricId": null,
      "isEnabled": true,
      "isFabric":true,
      "isFastLaneEnabled": false,
      "isMacfilteringEnabled": false,
      "passphrase": "",
      "peerIp": "",
      "portalName": "",
      "portalType": "",
      "profileName": "",
      "redirectUrl": "",
      "redirectUrlType": "",
      "radioPolicy": 0,
      "scalableGroupTag": "",
      "ssid": "SFO12_WLC",
      "trafficType": "voicedata",
      "isBroadcastSSID": true,
      "wlanBandSelectEnable": false,
      "wlanType": "Enterprise",
      "basicServiceSetClientIdleTimeout": 300,
      "aclName": "",
      "basicServiceSetMaxIdleEnable": true,
      "clientExclusionEnable": true,
      "clientExclusionTimeout": 180,
      "directedMulticastServiceEnable": true,
      "fastTransitionOverTheDistributedSystemEnable": true,
      "isBrownfield": false,
      "isCustomNasIdOptions": false,
      "isEmailReqd": false,
      "isHex": false,
      "isPosturingEnabled": false,
      "isRadiusProfilingEnabled": true,
      "isRandomMacFilterEnabled": false,
      "isSensorPnp": false,
      "isUnicastFilteringEnabled": false,
      "l3AuthType": "open",
      "managementFrameProtectionClientprotection": "Optional",
      "nasOptions": [],
      "neighborListEnable": true,
      "openSsid": "",
      "passiveClient": false,
      "policyProfileName": "wlan.info.SFO12_WLC",
      "sessionTimeOut": 1800,
      "sessionTimeOutEnable": true,
      "sleepingClientEnable": false,
      "sleepingClientTimeout": 720,
      "webPassthrough": false,
      "isUpgraded": true,
      "retainOldAcctServerBehaviour": true,

      },

      ],
      "version": 6
      },
       {
      "groupUuid": "-1",
      "instanceType": "wlan",
      "key": "wlan.info.SFO11_WLC",
      "namespace": "wlan",
      "type": "wlan.setting",
      "value": [
      {
        "acctServers": [
          "dcloud-ise-ctf-inst-xxx.cisco.com"
      ],
      "authServers": ["dcloud-ise-ctf-inst-xxx.cisco.com"],
      "authType": "wpa2_enterprise",
      "authSecServer": "",
      "externalAuthIpAddress": "",
      "fastTransition": "ADAPTIVE",
      "fabricId": null,
      "isEnabled": true,
      "isFabric":true,
      "isFastLaneEnabled": false,
      "isMacfilteringEnabled": false,
      "passphrase": "",
      "peerIp": "",
      "portalName": "",
      "portalType": "",
      "profileName": "",
      "redirectUrl": "",
      "redirectUrlType": "",
      "radioPolicy": 0,
      "scalableGroupTag": "",
      "ssid": "SFO11_WLC",
      "trafficType": "voicedata",
      "isBroadcastSSID": true,
      "wlanBandSelectEnable": false,
      "wlanType": "Enterprise",
      "basicServiceSetClientIdleTimeout": 300,
      "aclName": "",
      "basicServiceSetMaxIdleEnable": true,
      "clientExclusionEnable": true,
      "clientExclusionTimeout": 180,
      "directedMulticastServiceEnable": true,
      "fastTransitionOverTheDistributedSystemEnable": true,
      "isBrownfield": false,
      "isCustomNasIdOptions": false,
      "isEmailReqd": false,
      "isHex": false,
      "isPosturingEnabled": false,
      "isRadiusProfilingEnabled": true,
      "isRandomMacFilterEnabled": false,
      "isSensorPnp": false,
      "isUnicastFilteringEnabled": false,
      "l3AuthType": "open",
      "managementFrameProtectionClientprotection": "Optional",
      "nasOptions": [],
      "neighborListEnable": true,
      "openSsid": "",
      "passiveClient": false,
      "policyProfileName": "wlan.info.SFO11_WLC",
      "sessionTimeOut": 1800,
      "sessionTimeOutEnable": true,
      "sleepingClientEnable": false,
      "sleepingClientTimeout": 720,
      "webPassthrough": false,
      "isUpgraded": true,
      "retainOldAcctServerBehaviour": true,
      },

      ],
      "version": 6
      },
      {
        "instanceType": "wlan",
        "instanceUuid": "e290df2c-1d6a-46a4-afd0-1056b412af66",
        "namespace": "wlan",
        "type": "wlan.setting",
        "key": "wlan.info.SJC22_SSID",
        "version": 7,
        "value": [
          {
            "acctServers": [
              "10.16.30.70"
          ],
            "profileName": "",
            "ssid": "SJC22_SSID",
            "wlanType": "Enterprise",
            "isFastLaneEnabled": false,
            "authType": "wpa2_personal",
            "authServer": "auth_ise",
            "passphrase": "NO!$DATA!$",
            "peerIp": "",
            "scalableGroupTag": "",
            "trafficType": "voicedata",
            "isMacFilteringEnabled": false,
            "isEnabled": true,
            "isFabric":true,
            "isEmailReqd": false,
            "isFabric": true,
            "fabricId": null,
            "redirectUrl": "",
            "authSecServer": "",
            "portalType": "",
            "redirectUrlType": "",
            "externalAuthIpAddress": "",
            "portalName": "",
            "fastTransition": "Adaptive",
            "radioPolicy": 0,
            "wlanBandSelectEnable": false,
            "isBroadcastSSID": true,
            "webPassthrough": false,
            "sleepingClientEnable": false,
            "sleepingClientTimeout": 720,
            "sessionTimeOutEnable": true,
            "sessionTimeOut": 1800,
            "clientExclusionEnable": true,
            "clientExclusionTimeout": 180,
            "basicServiceSetMaxIdleEnable": true,
            "basicServiceSetClientIdleTimeout": 300,
            "directedMulticastServiceEnable": true,
            "neighborListEnable": true,
            "managementFrameProtectionClientprotection": "Optional",
            "fastTransitionOverTheDistributedSystemEnable": true,
            "passiveClient": false,
            "isUpgraded": true,
            "retainOldAcctServerBehaviour": true,
            "policyProfileName": "wlan.info.SJC22_SSID",
          }
        ],
        "groupUuid": "-1",
        "inheritedGroupUuid": "",
        "inheritedGroupName": ""
      }


      ],


      "Sensor_Template":[
        {
          "instanceType": "sensor_ssids",
          "instanceUuid": "51b1c8ee-cc7a-4dc9-95d2-7022cfe97a7a",
          "namespace": "sensor",
          "type": "sensor.setting",
          "key": "sensor.ssid",
          "version": 6,
          "value": [
            {
              "name": "DEMO-Sensor-ASSURANCE",
              "ssid": "Corporate",
              "username": "",
              "password": "hvr2iCSlNGFTqUq/kxYWsL9DqIMSDSrZKy19LV+86KBkDXbZ84RaKckyM5TsykmaeHNBVf1eD6AvW+4Zy2eEtcGgvM/f5ubq",
              "authType": "open",
              "eapType": "",
              "encrypted": "true"
            }
          ],
          "groupUuid": "-1",
          "inheritedGroupUuid": "",
          "inheritedGroupName": ""
        }
      ],

      /* "GUEST_WLAN_TEMPLATE" : {
        "groupUuid": "-1",
        "inheritedGroupName": "",
        "inheritedGroupUuid": "",
        "instanceType": "wlan",
        "instanceUuid": "0c8a6c52-57c9-4f0f-88ce-4ca040d4172c",
        "key": "wlan.info.Guest",
        "namespace": "wlan",
        "type": "wlan.setting",
        "value": [
            {
                "authSecServer": "",
                "authServer": "auth_ise",
                "authType": "web_auth",
                "basicServiceSetClientIdleTimeout": 300,
                "basicServiceSetMaxIdleEnable": true,
                "clientExclusionEnable": true,
                "clientExclusionTimeout": 180,
                "directedMulticastServiceEnable": true,
                "externalAuthIpAddress": "",
                "fabricId": null,
                "fastTransition": "Disable",
                "fastTransitionOverTheDistributedSystemEnable": false,
                "isBroadcastSSID": true,
                "isEmailReqd": false,
                "isEnabled": true,
                "isFabric": true,
                "isFastLaneEnabled": false,
                "isMacFilteringEnabled": true,
                "managementFrameProtectionClientprotection": "Optional",
                "neighborListEnable": true,
                "passiveClient": false,
                "passphrase": "",
                "peerIp": "",
                "portalName": "BettysPortal$$4989e312-983d-4b15-9024-e02abce1c4fc",
                "portalType": "selfreg",
                "profileName": "",
                "radioPolicy": 0,
                "redirectUrl": null,
                "redirectUrlType": "original_page",
                "scalableGroupTag": "",
                "sessionTimeOut": 1800,
                "sessionTimeOutEnable": true,
                "sleepingClientEnable": false,
                "sleepingClientTimeout": 720,
                "ssid": "Guest",
                "trafficType": "data",
                "webPassthrough": false,
                "wlanBandSelectEnable": false,
                "wlanType": "Guest"
            }
        ],
        "version": 7
      }, */
    
       "WlanProfile_Template": [{
          "attributesList":[],
          "groupTypeList":[],
          "interfaceList":[],
          "siteProfileUuid":"4bead126-9c3a-4553-a34f-cc2d10cc1118",
          "id": "4bead126-9c3a-4553-a34f-cc2d10cc1118",
          "version":1,
          "name":"Corporate",
          "siteName":"SJC01",
          "namingPrefix":"",
          "namespace":"wlan",
          "primaryDeviceType":"",
          "secondaryDeviceType":"",
          "status":"",
          "lastUpdatedBy":"admin",
          "lastUpdatedDatetime":0,
          "siteAssociationId":"",
          "siteCount":0,
          "siteProfileType":"",
          "profileAttributes": [
                              {
                                "key": "wireless.ssid",
                                "value": "Corporate",
                                "attribs":[{"key": "wireless.fabric","value": true},{"key": "wireless.flexconnect","value": false},{"key": "wireless.authMode","value": "central"},{"key": "wireless.trafficSwitchingMode","value": "fabric"},
                                {
                                  "key": "wireless.wlanProfileName",
                                  "value": "DEMO-CORP_de798bfe67_profile"
                              },
                              {
                                  "key": "wireless.policyProfileName",
                                  "value": "DEMO-CORP_de798bfe67_profile"
                              },
                                ]
                              }

                            ]

    } , {
          "attributesList":[],
          "groupTypeList":[],
          "interfaceList":[],
          "siteProfileUuid":"4bead126-9c3a-4553-a34f-cc2d10cc1119",
          "id": "4bead126-9c3a-4553-a34f-cc2d10cc1119",
          "version":1,
          "name":"c9800AP11AX",
          "siteName":"SJC01",
          "namingPrefix":"",
          "namespace":"wlan",
          "primaryDeviceType":"",
          "secondaryDeviceType":"",
          "status":"",
          "lastUpdatedBy":"admin",
          "lastUpdatedDatetime":0,
          "siteAssociationId":"",
          "siteCount":0,
          "siteProfileType":"",
          "profileAttributes": [
                              {
                                "key": "wireless.ssid",
                                "value": "c9800AP11AX",
                                "attribs":[{"key": "wireless.fabric","value": true},{"key": "wireless.flexconnect","value": false},{"key": "wireless.authMode","value": "central"},{"key": "wireless.trafficSwitchingMode","value": "fabric"},
                                {
                                  "key": "wireless.wlanProfileName",
                                  "value": "DEMO-CORP_de798bfe67_profile"
                              },
                              {
                                  "key": "wireless.policyProfileName",
                                  "value": "DEMO-CORP_de798bfe67_profile"
                              },
                                ]
                              }

                            ]

    },{
          "attributesList":[],
          "groupTypeList":[],
          "interfaceList":[],
          "siteProfileUuid":"4bead126-9c3a-4553-a34f-cc2d10cc1120",
          "id": "4bead126-9c3a-4553-a34f-cc2d10cc1120",
          "version":1,
          "name":"c9800AP11AC",
          "siteName":"SJC01",
          "namingPrefix":"",
          "namespace":"wlan",
          "primaryDeviceType":"",
          "secondaryDeviceType":"",
          "status":"",
          "lastUpdatedBy":"admin",
          "lastUpdatedDatetime":0,
          "siteAssociationId":"",
          "siteCount":0,
          "siteProfileType":"",
          "profileAttributes": [
                              {
                                "key": "wireless.ssid",
                                "value": "c9800AP11AC",
                                "attribs":[{"key": "wireless.fabric","value": true},{"key": "wireless.flexconnect","value": false},{"key": "wireless.authMode","value": "central"},{"key": "wireless.trafficSwitchingMode","value": "fabric"},
                                {
                                  "key": "wireless.wlanProfileName",
                                  "value": "DEMO-CORP_de798bfe67_profile"
                              },
                              {
                                  "key": "wireless.policyProfileName",
                                  "value": "DEMO-CORP_de798bfe67_profile"
                              },
                                ]
                              }

                            ]

    },
    {
      "attributesList":[],
      "groupTypeList":[],
      "interfaceList":[],
      "siteProfileUuid":"4bead126-9c3a-4553-a34f-cc2d10cc1121",
      "id": "4bead126-9c3a-4553-a34f-cc2d10cc1121",
      "version":1,
      "name":"@CorpSSID",
      "siteName":"SJC01,SJC04",
      "namingPrefix":"",
      "namespace":"wlan",
      "primaryDeviceType":"",
      "secondaryDeviceType":"",
      "status":"",
      "lastUpdatedBy":"admin",
      "lastUpdatedDatetime":0,
      "siteAssociationId":"",
      "siteCount":0,
      "siteProfileType":"",
      "profileAttributes": [
                          {
                            "key": "wireless.ssid",
                            "value": "@CorpSSID",
                            "attribs":[{"key": "wireless.fabric","value": "false"},{"key": "wireless.flexconnect","value": "false"},{"key": "wireless.authMode","value": "central"},{"key": "wireless.trafficSwitchingMode","value": "fabric"},{"key":"wireless.interfaceName","value":"management"},{"key":"wireless.ssid","value":"@CorpSSID"},
                            {
                              "key": "wireless.wlanProfileName",
                              "value": "DEMO-CORP_de798bfe67_profile"
                          },
                          {
                              "key": "wireless.policyProfileName",
                              "value": "DEMO-CORP_de798bfe67_profile"
                          },
                            ]
                          }

                        ]

     },{
          "attributesList":[],
          "groupTypeList":[],
          "interfaceList":[],
          "siteProfileUuid":"4bead126-9c3a-4553-a34f-cc2d10cc1124",
          "id": "4bead126-9c3a-4553-a34f-cc2d10cc1124",
          "version":1,
          "name":"DEMO-NF-ASSURANCE",
          "siteName":"SJC01",
          "namingPrefix":"",
          "namespace":"wlan",
          "primaryDeviceType":"",
          "secondaryDeviceType":"",
          "status":"",
          "lastUpdatedBy":"admin",
          "lastUpdatedDatetime":0,
          "siteAssociationId":"",
          "siteCount":0,
          "siteProfileType":"",
          "profileAttributes": [
                              {
                                "key": "wireless.ssid",
                                "value": "DEMO-NF-ASSURANCE",
                                "attribs":[{"key": "wireless.fabric","value": true},{"key": "wireless.flexconnect","value": false},{"key": "wireless.authMode","value": "central"},{"key": "wireless.trafficSwitchingMode","value": "fabric"},
                                {
                                  "key": "wireless.wlanProfileName",
                                  "value": "DEMO-CORP_de798bfe67_profile"
                              },
                              {
                                  "key": "wireless.policyProfileName",
                                  "value": "DEMO-CORP_de798bfe67_profile"
                              },
                                ]
                              }

                            ]

    },{
          "attributesList":[],
          "groupTypeList":[],
          "interfaceList":[],
          "siteProfileUuid":"4bead126-9c3a-4553-a34f-cc2d10cc1125",
          "id": "4bead126-9c3a-4553-a34f-cc2d10cc1125",
          "version":1,
          "name":"DEMO-FEW-ASSURANCE",
          "siteName":"SJC01",
          "namingPrefix":"",
          "namespace":"wlan",
          "primaryDeviceType":"",
          "secondaryDeviceType":"",
          "status":"",
          "lastUpdatedBy":"admin",
          "lastUpdatedDatetime":0,
          "siteAssociationId":"",
          "siteCount":0,
          "siteProfileType":"",
          "profileAttributes": [
                              {
                                "key": "wireless.ssid",
                                "value": "DEMO-FEW-ASSURANCE",
                                "attribs":[{"key": "wireless.fabric","value": true},{"key": "wireless.flexconnect","value": false},{"key": "wireless.authMode","value": "central"},{"key": "wireless.trafficSwitchingMode","value": "fabric"},
                                {
                                  "key": "wireless.wlanProfileName",
                                  "value": "DEMO-CORP_de798bfe67_profile"
                              },
                              {
                                  "key": "wireless.policyProfileName",
                                  "value": "DEMO-CORP_de798bfe67_profile"
                              },
                                ]
                              }

                            ]

    },
    {
          "attributesList":[],
          "groupTypeList":[],
          "interfaceList":[],
          "siteProfileUuid":"4bead126-9c3a-4553-a34f-cc2d10cc1725",
          "id": "4bead126-9c3a-4553-a34f-cc2d10cc1725",
          "version":1,
          "name":"FabricDemoProfile",
          "siteName":"SJC01",
          "namingPrefix":"",
          "namespace":"wlan",
          "primaryDeviceType":"",
          "secondaryDeviceType":"",
          "status":"",
          "lastUpdatedBy":"admin",
          "lastUpdatedDatetime":0,
          "siteAssociationId":"",
          "siteCount":0,
          "siteProfileType":"",
          "profileAttributes": [
                              {
                                "key": "wireless.ssid",
                                "value": "FabricDemoProfile",
                                "attribs":[{"key": "wireless.fabric","value": true},{"key": "wireless.flexconnect","value": false},{"key": "wireless.authMode","value": "central"},{"key": "wireless.trafficSwitchingMode","value": "fabric"},{
                                  "key": "wireless.wlanProfileName",
                                  "value": "DEMO-CORP_de798bfe67_profile"
                              },
                              {
                                  "key": "wireless.policyProfileName",
                                  "value": "DEMO-CORP_de798bfe67_profile"
                              },
                                ]
                              }

                            ]

    },
    {
          "attributesList":[],
          "groupTypeList":[],
          "interfaceList":[],
          "siteProfileUuid":"4bead126-9c3a-4553-a34f-cc2d10cc1726",
          "id": "4bead126-9c3a-4553-a34f-cc2d10cc1726",
          "version":1,
          "name":"SJC06_WLC",
          "siteName":"SJC06",
          "namingPrefix":"",
          "namespace":"wlan",
          "primaryDeviceType":"",
          "secondaryDeviceType":"",
          "status":"",
          "lastUpdatedBy":"admin",
          "lastUpdatedDatetime":0,
          "siteAssociationId":"",
          "siteCount":0,
          "siteProfileType":"",
          "profileAttributes": [
                              {
                                "key": "wireless.ssid",
                                "value": "SJC06_WLC",
                                "attribs":[{"key": "wireless.fabric","value": true},{"key": "wireless.flexconnect","value": false},{"key": "wireless.authMode","value": "central"},{"key": "wireless.trafficSwitchingMode","value": "fabric"},
                                {
                                  "key": "wireless.wlanProfileName",
                                  "value": "DEMO-CORP_de798bfe67_profile"
                              },
                              {
                                  "key": "wireless.policyProfileName",
                                  "value": "DEMO-CORP_de798bfe67_profile"
                              },
                                ]
                              }

                            ]

    },
    {
          "attributesList":[],
          "groupTypeList":[],
          "interfaceList":[],
          "siteProfileUuid":"4bead126-9c3a-4553-a34f-cc2d10cc1727",
          "id": "4bead126-9c3a-4553-a34f-cc2d10cc1727",
          "version":1,
          "name":"SJC06_VWLC",
          "siteName":"SJC06",
          "namingPrefix":"",
          "namespace":"wlan",
          "primaryDeviceType":"",
          "secondaryDeviceType":"",
          "status":"",
          "lastUpdatedBy":"admin",
          "lastUpdatedDatetime":0,
          "siteAssociationId":"",
          "siteCount":0,
          "siteProfileType":"",
          "profileAttributes": [
                              {
                                "key": "wireless.ssid",
                                "value": "SJC06_VWLC",
                                "attribs":[{"key": "wireless.fabric","value": true},{"key": "wireless.flexconnect","value": false},{"key": "wireless.authMode","value": "central"},{"key": "wireless.trafficSwitchingMode","value": "fabric"},
                                {
                                  "key": "wireless.wlanProfileName",
                                  "value": "DEMO-CORP_de798bfe67_profile"
                              },
                              {
                                  "key": "wireless.policyProfileName",
                                  "value": "DEMO-CORP_de798bfe67_profile"
                              },
                                ]
                              }

                            ]

    }, {
          "attributesList":[],
          "groupTypeList":[],
          "interfaceList":[],
          "siteProfileUuid":"4bead126-9c3a-4553-a34f-cc2d10cc1728",
          "id": "4bead126-9c3a-4553-a34f-cc2d10cc1728",
          "version":1,
          "name":"SFO12_WLC",
          "siteName":"SFO12",
          "namingPrefix":"",
          "namespace":"wlan",
          "primaryDeviceType":"",
          "secondaryDeviceType":"",
          "status":"",
          "lastUpdatedBy":"admin",
          "lastUpdatedDatetime":0,
          "siteAssociationId":"",
          "siteCount":0,
          "siteProfileType":"",
          "profileAttributes": [
                              {
                                "key": "wireless.ssid",
                                "value": "SFO12_WLC",
                                "attribs":[{"key": "wireless.fabric","value": true},{"key": "wireless.flexconnect","value": false},{"key": "wireless.authMode","value": "central"},
                                {"key": "wireless.trafficSwitchingMode","value": "fabric"},
                                {
                                  "key": "wireless.wlanProfileName",
                                  "value": "DEMO-CORP_de798bfe67_profile"
                              },
                              {
                                  "key": "wireless.policyProfileName",
                                  "value": "DEMO-CORP_de798bfe67_profile"
                              },
                                ]
                              }

                            ]

    },{
          "attributesList":[],
          "groupTypeList":[],
          "interfaceList":[],
          "siteProfileUuid":"4bead126-9c3a-4553-a34f-cc2d10cc1777",
          "id": "4bead126-9c3a-4553-a34f-cc2d10cc1777",
          "version":1,
          "name":"SFO11_WLC",
          "siteName":"SFO11",
          "namingPrefix":"",
          "namespace":"wlan",
          "primaryDeviceType":"",
          "secondaryDeviceType":"",
          "status":"",
          "lastUpdatedBy":"admin",
          "lastUpdatedDatetime":0,
          "siteAssociationId":"",
          "siteCount":0,
          "siteProfileType":"",
          "profileAttributes": [
                              {
                                "key": "wireless.ssid",
                                "value": "SFO11_WLC",
                                "attribs":[{"key": "wireless.fabric","value": true},{"key": "wireless.flexconnect","value": false},{"key": "wireless.authMode","value": "central"},{"key": "wireless.trafficSwitchingMode","value": "fabric"},
                                {
                                  "key": "wireless.wlanProfileName",
                                  "value": "DEMO-CORP_de798bfe67_profile"
                              },
                              {
                                  "key": "wireless.policyProfileName",
                                  "value": "DEMO-CORP_de798bfe67_profile"
                              },
                                ]
                              }

                            ]

    },

    {
      "attributesList":[],
      "groupTypeList":[],
      "interfaceList":[],
      "siteProfileUuid":"f3988658-d73a-49c3-8d7b-47501a4a54b7",
      "id": "f3988658-d73a-49c3-8d7b-47501a4a54b7",
      "version":1,
      "name":"SJC22_WLC",
      "siteName":"SJC22",
      "namingPrefix":"",
      "namespace":"wlan",
      "primaryDeviceType":"",
      "secondaryDeviceType":"",
      "status":"final",
      "lastUpdatedBy":"admin",
      "lastUpdatedDatetime":0,
      "siteAssociationId":"",
      "siteCount":0,
      "siteProfileType":"",
      "profileAttributes": [
        {
          "key": "wireless.ssid",
          "value": "SJC22_SSID",
          "attribs":[
            {"key": "wireless.fabric","value": "true"},{"key": "wireless.flexconnect","value": "false"},{"key": "wireless.authMode","value": "central"},{"key": "wireless.trafficSwitchingMode","value": "fabric"}, {"key": "wireless.isGuestAnchor","value": "false"},
            {
              "key": "wireless.wlanProfileName",
              "value": "DEMO-CORP_de798bfe67_profile"
          },
          {
              "key": "wireless.policyProfileName",
              "value": "DEMO-CORP_de798bfe67_profile"
          },
          ]
        }/* ,
        {
          "key": "wireless.ssid",
          "value": "Guest",
          "attribs": [
            {"key": "wireless.authMode","value": "central"},{"key": "wireless.fabric","value": "true"},{"key": "wireless.flexConnect","value": "false"},{"key": "wireless.isGuestAnchor","value": "false"},{"key": "wireless.ssid","value": "Guest"},{"key": "wireless.trafficSwitchingMode","value": "fabric"}
          ]                
        } */
      ]
    }

    ],

    "RFProfile_Template": [{
          "instanceType": "rfprofile",
          "instanceUuid": "df035ba2-919a-4125-8d78-9cb20f3e8f1b",
          "namespace": "wlan",
          "type": "rfprofile.setting",
          "key": "rfprofile.info",
          "version": 8,
          "value": [],
          "groupUuid": "-1",
          "inheritedGroupUuid": "",
          "inheritedGroupName": ""
    }],

    "AAAvlanaclTemplate":[{
      "instanceType":"aaavlanacl",
      "instanceUuid":"45a9664f-9034-47dc-997b-7659bcff1a6a",
      "namespace":"wlan",
      "type":"aaavlanacl.setting",
      "key":"aaavlanacl.info",
      "version":0,
      "value":[{
        "aclListName": ""
      }],
      "groupUuid":"-1",
      "inheritedGroupUuid":"",
      "inheritedGroupName":""
    }],
    "WlanNativeVlanTemplate":[{"instanceType":"nativeVlan","instanceUuid":"257fb43d-05fc-4c2b-b085-010fd4665b50","namespace":"wlan","type":"wlanNativeVlan.setting","key":"wlanNativeVlan.info","version":0,"value":[],"groupUuid":"-1","inheritedGroupUuid":"","inheritedGroupName":""}],
    "ApimpersonationTemplate":[{"instanceType":"apimpersonation","instanceUuid":"b6d75bd3-f91c-4c9b-a66c-7b7698672dd7","namespace":"wlan","type":"apimpersonation.setting","key":"apimpersonation.info","version":0,"value":[{"apimpersonationEnabled":true}],"groupUuid":"-1","inheritedGroupUuid":"","inheritedGroupName":""}],
    "DtlscipherTemplate":[{"instanceType":"dtlscipher","instanceUuid":"469d7bae-020c-4ffe-ae8d-5fe154df2003","namespace":"wlan","type":"dtlscipher.setting","key":"dtlscipher.info","version":0,"value":[],"groupUuid":"-1","inheritedGroupUuid":"","inheritedGroupName":""}],
    "AwipsTemplate":[{"instanceType":"awips","instanceUuid":"26012d3e-5084-4ff6-b728-b7bce0e6dac9","namespace":"wlan","type":"awips.setting","key":"awips.info","version":0,"value":[{"brownfieldLearnValue":"awips","awipsEnabled":true,"awipsForensicEnabled":false}],"groupUuid":"-1","inheritedGroupUuid":"","inheritedGroupName":""}],

    "WLAN_APGROUP_TEMPLATE": {
      "instanceType":"apgroup",
      "instanceUuid":"3864ee57-b941-4ab0-ad8a-92c479f85165",
      "namespace":"wlan",
      "type":"apgroup.setting",
      "key":"apgroup.info",
      "version":1,
      "value":[
        {
          "rfProfileName":"TYPICAL",
          "apGroupName":"AP-Group-1",
          "siteHierarchy":"Global/North_America/United_States/California/San_Jose/Bldg-23/DEMO-SUITE-02"
        }
      ],
      "groupUuid":"38d14cd6-ba69-41fd-a5bf-18b4c250e2a5",
      "inheritedGroupUuid":"",
      "inheritedGroupName":""
    }
});
