define({
    "VirtualNetwork_Template": {
        id: "",
        instanceId: 83092,
        authEntityId: 83092,
        displayName: "0",
        authEntityClass: 1897203097,
        deployPending: "NONE",
        instanceVersion: 4,
        deployed: false,
        isStale: false,
        lastUpdateTime: 0,
        name: "Default_VN",
        namespace: "",
        provisioningState: "DEFINED",
        type: "VirtualNetwork",
        cfsChangeInfo: [],
        isDefault: false,
        isInfra:false,
        l3Instance: 4098,
        virtualNetworkContextId: "",
        fabricOverride: [],
        multicastSiteCount: 0,
        segment: [],
        segmentCount: 0,
        segmentSummary: {},
        siteCount: 0,
        siteList: [],
        transitConnectivityDomainSummary:[],
        virtualNetworkContextName : '',
        virtualNetworkContextType: '',
        multicastSiteCount: '',
        connectivityDomainId : '',
        createtime : 1631175011891,
        customProvisions: [],
        instanceCreatedOn: 1631175011908,
        instanceUpdatedOn: 1631175011908,
        isSeeded: false,
        lastUpdateTime: 1631175011891,
        targetIdList: []
    },

    "ANCHOR_PART_TEMPLATE" : {
        "id": "c78ed2f7-3cb5-465e-8ae1-6263d2d7b4a3",
        "instanceId": 5472471,
        "displayName": "0",
        "instanceVersion": 1,
        "anchorInfo": [
            {
                "id": "979de5ad-52fb-441d-b622-95d43d1b1596",
                "name": "anchorCdL2FloodingIndex",
                "value": "1"
            },
            {
                "id": "f04fd47f-a3cc-482d-b620-d88f2317af5a",
                "name": "connectivityDomain_499600ea-172b-4750-b634-1f2b0e208121",
                "value": "d18cfaee-d6df-43d7-8eb3-149ca6b084be"
            }
        ],
        "anchoredAt": "499600ea-172b-4750-b634-1f2b0e208121",//siteid
        "anchoredAuthKey": "$5$/mTpCXAExkvYIUHqV0yoxDCRLGWgil6/UYT8F4zNgY5oZVMDzhCeUIDWV3vPV6dyDucWmx+vZLG8Ay4hKhOo0v3OKTNQCxLr",
        "anchoredTo": [],
        "anchoredType": "VirtualNetwork",
        "isAnchor": false,
        "siteName": "Bldg23",//sitename where anchor is enabled/created
        "vnId": "147268f2-9077-4067-a7c3-72b4b6420d69",//vnid where anchor is enabled/created
        "anchorDevice": [
            {
                "id": "f29e6b96-d398-42ad-aabb-19325a7e9da7",
                "instanceId": 5790792,
                "displayName": "0",
                "instanceVersion": 0,
                "deviceType": "BORDER_499600ea-172b-4750-b634-1f2b0e208121",//BORDER_<siteId>
                "deviceuuid": [
                    "d75d39bb-ff14-4d1b-9b03-aa6dd4ed59d8"//network device ids of devices with role of Border in site where anchor VN was created
                ]
            },
            {
                "id": "13721e7d-ff19-4e20-971f-7d0555f3c32a",
                "instanceId": 5790791,
                "displayName": "0",
                "instanceVersion": 0,
                "deviceType": "CP_499600ea-172b-4750-b634-1f2b0e208121",//CP_<siteId>
                "deviceuuid": [
                    "d75d39bb-ff14-4d1b-9b03-aa6dd4ed59d8"//network device ids of devices with role of Control Plane in site where anchor VN was created
                ]
            }
        ]
    }        
});
