define({
    "IPPool_Template": {
        clientOptions: {},
        configureExternalDhcp: false,
        context :[{contextKey:"type",contextValue:"generic", owner: "DNAC"}],
	ipPoolName: "",
        dhcpServerIps: [],
        gateways: [],
        createTime: 1495754447059,
        lastUpdateTime: 1495754529860,
        totalIpAddressCount: 65536,
        totalAssignableIpAddressCount : 65534,
        unavailableIpAddressCount : 0,
        availableIpAddressCount : 65532,
        defaultAssignedIpAddressCount: 2,
        usedIpAddressCount: 0,
        parentUuid: "root",
        shared: true,
        overlapping: false,
        dnsServerIps: [],
        id: "",
        ipPoolCidr: "",
	ipv6: false,
	usedPercentage: "0",
	ipPoolType: "generic",
        owner: "ipam"
    },

     "IPPool_Sub_Template": {
        url: "/api/v2/ippool/subpool",
        method: "POST",
        service:["ippool","subpool"],
        restPayload: {
                    context :[{contextKey:"siteId",contextValue:"33f3b4b8-c7f8-d86d-ab8e-815638f4ccd3"}],
                    ipPoolName: "",
                    dhcpServerIps: [],
                    gateways: [],
                    parentUuid: "root",
                    ipPoolOwner:"DNAC",
                    shared: true,
                    overlapping: false,
                    dnsServerIps: [],
                    ipPoolCidr: "",
                    siteId:""
                    }

    },

    "Common_Setting_Template" : {

            url: "/api/v1/commonsetting/global/2fa8be61-ac60-a13e-85e5-01ba9ce31671",
            method: "POST",
            service:["commonsetting", "global"],
            action :{id:""},
            restPayload: [{
                        groupUuid:"b32cea8f-9cca-8dc5-7262-571ed2a592cf",
                        instanceType:"reference",
                        key:"ip.pool.generic.39a59e1b-6c6f-3595-7dab-49698bbc1a75",
                        namespace:"global",
                        type:"reference.setting",
                        value:[{objReferences:[],
                               type:"generic",
                               url: ""}]
                        }]
    }
});
