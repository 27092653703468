define({
    "Template":
    {
      "request": {
        "key": "snmp",
        "type": "Application",
        "label": "snmp",
        "collection": false,
        "componentContent": [
          {
            "component": "fusion",
            "additional": "8c816382-d0a0-464f-b75f-b138e849106f"
          }
        ]
      },
      "data": {
        "response": {
          "id": "8c816382-d0a0-464f-b75f-b138e849106f",
          "instanceId": 15173,
          "authEntityId": 15173,
          "displayName": "15173",
          "authEntityClass": -1909073334,
          "instanceTenantId": "SYS0",
          "deployPending": "NONE",
          "instanceVersion": 0,
          "createTime": 1528178811422,
          "deployed": false,
          "isSeeded": true,
          "isStale": false,
          "lastUpdateTime": 1528178811422,
          "name": "snmp",
          "namespace": "scalablegroup:application",
          "provisioningState": "DEFINED",
          "qualifier": "application",
          "resourceVersion": 0,
          "targetIdList": [],
          "type": "scalablegroup",
          "cfsChangeInfo": [],
          "customProvisions": [],
          "scalableGroupExternalHandle": "snmp",
          "scalableGroupType": "APPLICATION",
          "securityGroupTag": 0,
          "state": "ACTIVE",
          "vnAgnostic": true,
          "identitySource": {
            "id": "2689858e-a1e6-4c66-85d1-8bd282f8cf29",
            "instanceId": 10010,
            "authEntityId": 10010,
            "displayName": "10010",
            "authEntityClass": 1738953278,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "state": "INACTIVE",
            "type": "NBAR"
          },
          "indicativeNetworkIdentity": [
            {
              "id": "d1542517-3f65-4a0e-ba21-1d54934a9b0e",
              "instanceId": 17168,
              "authEntityId": 17168,
              "displayName": "17168",
              "authEntityClass": 124090504,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "ipv4Subnet": [],
              "ipv6Subnet": [],
              "lowerPort": 0,
              "ports": "161,162",
              "protocol": "UDP",
              "upperPort": 0
            },
            {
              "id": "921c064a-d3c3-44fc-b432-c066c47cdf50",
              "instanceId": 17167,
              "authEntityId": 17167,
              "displayName": "17167",
              "authEntityClass": 124090504,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "ipv4Subnet": [],
              "ipv6Subnet": [],
              "lowerPort": 0,
              "ports": "161,162,1161,1162",
              "protocol": "TCP",
              "upperPort": 0
            }
          ],
          "networkApplications": [
            {
              "id": "85ac3928-f5f8-4de0-bf36-8f4674b0c246",
              "instanceId": 16115,
              "authEntityId": 16115,
              "displayName": "16115",
              "authEntityClass": -217092956,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "appProtocol": "tcp/udp",
              "applicationGroup": "snmp-group",
              "applicationType": "DEFAULT",
              "categoryId": "48237211-9fec-476d-8f2f-2d60bc0d3e8e",
              "createTime": 1528178811422,
              "encrypted": "false",
              "engineId": "3",
              "helpString": "Simple Network Messaging Protocol",
              "ignoreConflict": false,
              "lastUpdateTime": 1528178811422,
              "longDescription": "Simple Network Management Protocol (SNMP) is a protocol used for a TCP/IP network management. It collects data about the nework enteties and distributes it among them.  Typically the protocol uses TCP/UDP ports 161-162.",
              "name": "snmp",
              "nbarId": "38",
              "p2pTechnology": "false",
              "popularity": 9,
              "rank": 65535,
              "references": "http://www.ietf.org/rfc/rfc1157.txt",
              "selectorId": "161",
              "subCategory": "33353ae0-7f7c-49e7-af3e-c759f007d9d9",
              "trafficClass": "OPS_ADMIN_MGMT",
              "tunnel": "false"
            }
          ],
          "networkIdentity": [
            {
              "id": "77432c7a-8d45-4a28-b59c-a69ae961220e",
              "instanceId": 17165,
              "authEntityId": 17165,
              "displayName": "17165",
              "authEntityClass": 124090504,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "ipv4Subnet": [],
              "ipv6Subnet": [],
              "lowerPort": 0,
              "ports": "161,162,1161,1162",
              "protocol": "TCP",
              "upperPort": 0
            },
            {
              "id": "2c489632-01ed-43d9-b45f-e63873c3e50c",
              "instanceId": 17166,
              "authEntityId": 17166,
              "displayName": "17166",
              "authEntityClass": 124090504,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "ipv4Subnet": [],
              "ipv6Subnet": [],
              "lowerPort": 0,
              "ports": "161,162",
              "protocol": "UDP",
              "upperPort": 0
            }
          ],
          "parentScalableGroup": {
            "idRef": "6af07333-7132-42bb-817b-41f5ea0f5698"
          },
          "user": [],
          "userGroup": []
        }
      },
      "result": [
        {
          "bookID": {
            "namespace": "fusion",
            "name": "core",
            "version": "1.0.1"
          },
          "response": {
            "id": "8c816382-d0a0-464f-b75f-b138e849106f",
            "instanceId": 15173,
            "authEntityId": 15173,
            "displayName": "15173",
            "authEntityClass": -1909073334,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "createTime": 1528178811422,
            "deployed": false,
            "isSeeded": true,
            "isStale": false,
            "lastUpdateTime": 1528178811422,
            "name": "snmp",
            "namespace": "scalablegroup:application",
            "provisioningState": "DEFINED",
            "qualifier": "application",
            "resourceVersion": 0,
            "targetIdList": [],
            "type": "scalablegroup",
            "cfsChangeInfo": [],
            "customProvisions": [],
            "scalableGroupExternalHandle": "snmp",
            "scalableGroupType": "APPLICATION",
            "securityGroupTag": 0,
            "state": "ACTIVE",
            "vnAgnostic": true,
            "identitySource": {
              "id": "2689858e-a1e6-4c66-85d1-8bd282f8cf29",
              "instanceId": 10010,
              "authEntityId": 10010,
              "displayName": "10010",
              "authEntityClass": 1738953278,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "state": "INACTIVE",
              "type": "NBAR"
            },
            "indicativeNetworkIdentity": [
              {
                "id": "d1542517-3f65-4a0e-ba21-1d54934a9b0e",
                "instanceId": 17168,
                "authEntityId": 17168,
                "displayName": "17168",
                "authEntityClass": 124090504,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "ipv4Subnet": [],
                "ipv6Subnet": [],
                "lowerPort": 0,
                "ports": "161,162",
                "protocol": "UDP",
                "upperPort": 0
              },
              {
                "id": "921c064a-d3c3-44fc-b432-c066c47cdf50",
                "instanceId": 17167,
                "authEntityId": 17167,
                "displayName": "17167",
                "authEntityClass": 124090504,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "ipv4Subnet": [],
                "ipv6Subnet": [],
                "lowerPort": 0,
                "ports": "161,162,1161,1162",
                "protocol": "TCP",
                "upperPort": 0
              }
            ],
            "networkApplications": [
              {
                "id": "85ac3928-f5f8-4de0-bf36-8f4674b0c246",
                "instanceId": 16115,
                "authEntityId": 16115,
                "displayName": "16115",
                "authEntityClass": -217092956,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "appProtocol": "tcp/udp",
                "applicationGroup": "snmp-group",
                "applicationType": "DEFAULT",
                "categoryId": "48237211-9fec-476d-8f2f-2d60bc0d3e8e",
                "createTime": 1528178811422,
                "encrypted": "false",
                "engineId": "3",
                "helpString": "Simple Network Messaging Protocol",
                "ignoreConflict": false,
                "lastUpdateTime": 1528178811422,
                "longDescription": "Simple Network Management Protocol (SNMP) is a protocol used for a TCP/IP network management. It collects data about the nework enteties and distributes it among them.  Typically the protocol uses TCP/UDP ports 161-162.",
                "name": "snmp",
                "nbarId": "38",
                "p2pTechnology": "false",
                "popularity": 9,
                "rank": 65535,
                "references": "http://www.ietf.org/rfc/rfc1157.txt",
                "selectorId": "161",
                "subCategory": "33353ae0-7f7c-49e7-af3e-c759f007d9d9",
                "trafficClass": "OPS_ADMIN_MGMT",
                "tunnel": "false"
              }
            ],
            "networkIdentity": [
              {
                "id": "77432c7a-8d45-4a28-b59c-a69ae961220e",
                "instanceId": 17165,
                "authEntityId": 17165,
                "displayName": "17165",
                "authEntityClass": 124090504,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "ipv4Subnet": [],
                "ipv6Subnet": [],
                "lowerPort": 0,
                "ports": "161,162,1161,1162",
                "protocol": "TCP",
                "upperPort": 0
              },
              {
                "id": "2c489632-01ed-43d9-b45f-e63873c3e50c",
                "instanceId": 17166,
                "authEntityId": 17166,
                "displayName": "17166",
                "authEntityClass": 124090504,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "ipv4Subnet": [],
                "ipv6Subnet": [],
                "lowerPort": 0,
                "ports": "161,162",
                "protocol": "UDP",
                "upperPort": 0
              }
            ],
            "parentScalableGroup": {
              "idRef": "6af07333-7132-42bb-817b-41f5ea0f5698"
            },
            "user": [],
            "userGroup": []
          }
        }
      ]
    }
});      