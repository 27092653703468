define ({
    "WEBEX_MEETINGS": [
        {
            "meetingInstanceId": "cb459da011eab7027479b7ed42ac5062_I_206866592884095900",
            "meetingNumber": null,
            "meetingTitle": "Grace Smith's Personal Room",
            "webexUserEmail": "gracesmith@cisco.com",
            "starTime": "2021-09-30T09:00:00Z",
            "endTime": "2021-09-30T10:00:35Z",
            "duration": "01:00",
            "participants": null,
            "status": "ENDED",
            "audioQuality": null,
            "videoQuality": null,
            "shareQuality": null
        },{
            "meetingInstanceId": "cb459da011eab7027479b7ed42ac5062_I_206863206265682683",
            "meetingNumber": null,
            "meetingTitle": "Grace Smith's Personal Room",
            "webexUserEmail": "gracesmith@cisco.com",
            "starTime": "2021-09-30T08:30:29Z",
            "endTime": "2021-09-30T09:00:56Z",
            "duration": "00:31",
            "participants": null,
            "status": "ENDED",
            "audioQuality": null,
            "videoQuality": null,
            "shareQuality": null
        },{
            "meetingInstanceId": "cb459da011eab7027479b7ed42ac5062_I_205451504262347181",
            "meetingNumber": null,
            "meetingTitle": "Grace Smith's Personal Room",
            "webexUserEmail": "gracesmith@cisco.com",
            "starTime": "2021-09-30T07:15:56Z",
            "endTime": "2021-09-30T07:30:06Z",
            "duration": "00:15",
            "participants": null,
            "status": "ENDED",
            "audioQuality": null,
            "videoQuality": null,
            "shareQuality": null
        }
    ],

    "WEBEX_DETAILS": {
        "meetingInstanceId":"cb459da011eab7027479b7ed42ac5062_I_206866592884095900",
        "meetingNumber":null, "meetingTitle":null,
        "webexUserEmail":"gracesmith@cisco.com",
        "starTime":"2021-09-30T09:01:32.000000Z",
        "endTime":"2021-09-30T10:12:45.000000Z",
        "duration":"01:11","participants":2,"status":"ENDED",
        "audioQuality":10,
        "videoQuality":10,
        "shareQuality":10,
        "videoIn":[{
            "time":"2021-09-30T09:02:32Z","packetLoss":-1.0,"latency":109, "jitter":-1,"mediaBitRate":-1,"signalQuality":10
        }],
        "videoOut":[{
            "time":"2021-09-30T09:02:32Z","packetLoss":0.0,"latency":109,"jitter":11,"mediaBitRate":230902,"signalQuality":10
        }],
        "audioIn":[{
            "time":"2021-09-30T09:02:32Z","packetLoss":0.0,"latency":0,"jitter":3,"mediaBitRate":2608,"signalQuality":10
        }],
        "audioOut":[{
            "time":"2021-09-30T09:02:32Z","packetLoss":0.0,"latency":0,"jitter":0,"mediaBitRate":-1,"signalQuality":10
        }],
        "shareIn":[{
            "time": "2021-09-30T09:02:32Z", "packetLoss": -1, "latency": 94, "jitter": -1, "mediaBitRate": -1, "signalQuality": 10
        }],
        "shareOut":[{
            "time":"2021-09-30T09:02:32Z","packetLoss":0.0,"latency":94,"jitter":31,"mediaBitRate":64898,"signalQuality":10
        }]
    },

    "TREND_TEMPLATE": [{
        "webexAppName": "cisco-spark",
        "minHealthScore": 9,
        "avgHealthScore": 10,
        "healthTrend": [{
            "time": "2021-09-30T09:01:31.000+0000",
            "healthScore": 10,
            "packetLoss": 0,
            "jitter": null,
            "latency": null,
            "applicationDelay": 14192,
            "clientNetworkDelay": null,
            "serverNetworkDelay": null,
            "dscp": null,
            "usage": null,
            "rate": 452.83
        }]
    },{
        "webexAppName": "webex-video",
        "minHealthScore": 10,
        "avgHealthScore": 10,
        "healthTrend": [{
            "time": "2021-09-30T09:01:31.000+0000",
            "healthScore": 10,
            "packetLoss": 5.2,
            "jitter": 9634,
            "latency": null,
            "applicationDelay": 6,
            "clientNetworkDelay": 7,
            "serverNetworkDelay": 6,
            "dscp": null,
            "usage": null,
            "rate": 7050.67
        }]
    },{
        "webexAppName": "webex-audio",
        "minHealthScore": 10,
        "avgHealthScore": 10,
        "healthTrend": [{
            "time": "2021-09-30T09:01:31.000+0000",
            "healthScore": 10,
            "packetLoss": 0,
            "jitter": 3156,
            "latency": null,
            "applicationDelay": null,
            "clientNetworkDelay": null,
            "serverNetworkDelay": null,
            "dscp": null,
            "usage": null,
            "rate": 3723.44
        }]
    },{
        "webexAppName": "webex-meeting",
        "minHealthScore": 9,
        "avgHealthScore": 9,
        "healthTrend": [{
            "time": "2021-09-30T09:01:31.000+0000",
            "healthScore": 9,
            "packetLoss": 0,
            "jitter": null,
            "latency": 91.6,
            "applicationDelay": 96.4,
            "clientNetworkDelay": 1.4,
            "serverNetworkDelay": 90.2,
            "dscp": null,
            "usage": null,
            "rate": 1857.59
        }]
    },{
        "webexAppName": "webex-media",
        "minHealthScore": 8,
        "avgHealthScore": 10,
        "healthTrend": [{
            "time": "2021-09-30T09:01:31.000+0000",
            "healthScore": 8,
            "packetLoss": 0.17,
            "jitter": null,
            "latency": 77.5,
            "applicationDelay": 97,
            "clientNetworkDelay": 0.5,
            "serverNetworkDelay": 77,
            "dscp": null,
            "usage": null,
            "rate": 4083.05
        }]
    },{
        "webexAppName": "webex-app-sharing",
        "minHealthScore": 10,
        "avgHealthScore": 10,
        "healthTrend": [{
            "time": "2021-09-30T09:01:31.000+0000",
            "healthScore": 10,
            "packetLoss": 0,
            "jitter": null,
            "latency": 104.625,
            "applicationDelay": 1.125,
            "clientNetworkDelay": 1.25,
            "serverNetworkDelay": 103.375,
            "dscp": null,
            "usage": null,
            "rate": 897.26
        }]
    }]
   
});