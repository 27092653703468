define({
"Template":{success:true,result:"show power inline CmdIfNamePlaceHolder detail\n"
+"Interface: Tw2/0/16\n"
+"Inline Power Mode: auto\n"
+"Operational status: on\n"
+"Device Detected: yes\n"
+"Device Type: cisco DevicePidPlaceHolder\n"
+"IEEE Class: 4\n"
+"Discovery mechanism used/configured: Ieee and Cisco\n"
+"Police: off\n"
+"\n"
+"Power Allocated\n"
+"Admin Value: 60.0\n"
+"Power drawn from the source: 31.4\n"
+"Power available to the device: 31.4\n"
+"\n"
+"Actual consumption\n"
+"Measured at the port: 14.4\n"
+"Maximum Power drawn by the device since powered on: 17.1\n"
+"\n"
+"Absent Counter: 0\n"
+"Over Current Counter: 0\n"
+"Short Current Counter: 0\n"
+"Invalid Signature Counter: 0\n"
+"Power Denied Counter: 0\n"
+"\n"
+"Power Negotiation Used: CDP\n"
+"LLDP Power Negotiation --Sent to PD--      --Rcvd from PD--\n"
+"Power Type:          -                    -\n"
+"Power Source:        -                    -\n"
+"Power Priority:      -                    -\n"
+"Requested Power(W):  -                    -\n"
+"Allocated Power(W):  -                    -\n"
+"\n"
+"Four-Pair PoE Supported: Yes\n"
+"Spare Pair Power Enabled: Yes\n"
+"Four-Pair PD Architecture: Shared\n"
+"DeviceNamePlaceHolder#"}
})