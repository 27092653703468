define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/ApplicationHealthTrend',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/Application',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/Clientlist',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/assurance/SimAppNameData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/app_experience/AppExperience',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/ApplicationviewDetails',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/ApplicationLocationDetails',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SiteConfig',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/Applicationtopclients',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/SimulationUtility',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/HealthTrend/HealthtrendMsTeams',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/ApplicationAppAggbyIdentifier'
], function (ApplicationHealthTrend, Application, Clientlist, SimAppNameData, UtilityFunctions, SimLokiDatabaseActions, AppExperience,
    ApplicationviewDetails, ApplicationLocationDetails, SiteConfig, Applicationtopclients,SimulationUtility, HealthtrendMsTeams, ApplicationAppAggbyIdentifier) {
        return {
            init: function () {

            },

            getviewDetails: function (urlAction) {
                var applicationName = urlAction.filter["service"];
                var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('application', { 'applicationName': applicationName });
                var services = [];
                if (recordObj && recordObj.length) {
                    var tservices = JSON.parse(JSON.stringify(ApplicationviewDetails.Template));
                    tservices.id = recordObj[0].Category;
                    tservices.properties.businessRelevance[0].value = recordObj[0].businessRelevance;
                    tservices.properties.trafficClass[0].value = recordObj[0].trafficClass;
                    services.push(tservices);
                }
                return services;
            },

            getTopClients: function (urlAction) {

              var tJson = JSON.parse(JSON.stringify(Applicationtopclients.Template));
              var client = tJson.topClients
              var result = []
              var clientNames = []
              client.forEach(clientobject => {
                  var requieredclientName = clientobject.clientName
                  var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('host', {'userId': requieredclientName });
                  clientobject.clientIp = recordObj[0] ? recordObj[0].hostIpV4 : "";
                  clientobject.clientMacAddress = recordObj[0] ? recordObj[0].id : "";
                  result.push(clientobject);
              })
              tJson.topClients = result
              return {"version":"1.0","response":tJson};
            },

            /* getHealthTrend: function (urlAction, siteId) {
                  var tJson = JSON.parse(JSON.stringify(ApplicationHealthTrend.Template));
                 tJson.response.trend = generateData(urlAction, tJson.response.trend);
                 return tJson;
               }, */

            //used to display content in the application overall health's top section - graph, summary
            getHealthTrend: function (urlAction, siteId) {
                var tJson = JSON.parse(JSON.stringify(ApplicationHealthTrend.Template));
                var counts = {};
                var appData = [], totalAppData;
                if(urlAction.filter.siteId && (urlAction.filter.siteId != 'unknownSiteId' &&
                        urlAction.filter.siteId != '__global__')) {
                    var siteId = urlAction.filter.siteId;
                    var childrenSites = [];
                    SimulationUtility.getChildrenSites(siteId, childrenSites);
                    var siteIdList = [];
                    siteIdList.push(siteId);
                    for (var i = 0; i < childrenSites.length; i++) {
                        siteIdList.push(childrenSites[i].id);
                        console.log(childrenSites);
                    }
                    totalAppData = SimLokiDatabaseActions.getFilteredRecordHandler('application', { 'clientSiteId': { '$in': siteIdList } });

                } else {
                    totalAppData = SimLokiDatabaseActions.getAll('application');
                }

                //totalAppData = SimLokiDatabaseActions.getAll('application');

                var appList = ['google-services', 'telnet', 'youtube'];
                for(var k=0; k < totalAppData.length ; k++){
                    if(appList.indexOf(totalAppData[k].applicationName)== -1)
                        appData.push(totalAppData[k])
                }
                var templateLowHealthApp = JSON.parse(JSON.stringify(tJson.response.lowHealthApp));

                var counts = {};
                var poorCount = 0; fairCount = 0, goodCount = 0, unknownCount = 0;
                tJson.response.usage.topApplications = [];
                tJson.response.lowHealthApp.poor = []; tJson.response.lowHealthApp.fair = []; tJson.response.lowHealthApp.good = [];tJson.response.lowHealthApp.unknown = [];
                tJson.response.usage.usageDetails["business-relevant"].applicationGroup = {};
                tJson.response.usage.usageDetails["business-irrelevant"].applicationGroup = {};
                tJson.response.usage.usageDetails["default"].applicationGroup = {};
                tJson.response.usage.usageDetails["all"].applicationGroup = {};
                tJson.response.usage.usageDetails["business-relevant"].trafficClass = {};
                tJson.response.usage.usageDetails["business-irrelevant"].trafficClass = {};
                tJson.response.usage.usageDetails["default"].trafficClass = {};
                tJson.response.usage.usageDetails["all"].trafficClass = {};
                var bussRelCount = 0, bussIrrelCount = 0, defCount = 0, bussRelTotalUsage = 0, bussIrrelTotalUsage = 0, defTotalUsage = 0, totalUsage = 0;
                var bussRelDistTrafficClass = { "all": { "distribution": 0, "topApplications": [] } }, bussIrrelDistTrafficClass = { "all": { "distribution": 0, "topApplications": [] } }, defDistTrafficClass = { "all": { "distribution": 0, "topApplications": [] } }, allDistTrafficClass = { "all": { "distribution": 0, "topApplications": [] } };
                var bussRelDistAppGroup = { "all": { "distribution": 0, "topApplications": [] } }, bussIrrelDistAppGroup = { "all": { "distribution": 0, "topApplications": [] } }, defDistAppGroup = { "all": { "distribution": 0, "topApplications": [] } }, allDistAppGroup = { "all": { "distribution": 0, "topApplications": [] } };

                appData.sort(function (a, b) {
                    return b.usage - a.usage
                });

                for (var i = 0; i < appData.length; i++) {
                    if (appData[i].businessRelevance == 'business-relevant') {
                        bussRelTotalUsage = bussRelTotalUsage + appData[i].usage;
                    } else if (appData[i].businessRelevance == 'business-irrelevant') {
                        bussIrrelTotalUsage = bussIrrelTotalUsage + appData[i].usage;
                    } else if (appData[i].businessRelevance == 'default') {
                        defTotalUsage = defTotalUsage + appData[i].usage;
                    }
                    totalUsage = totalUsage + appData[i].usage;
                }


                for (var i = 0; i < appData.length; i++) {
                    var healthAppObj = {};
                    var appName = appData[i].applicationName;
                    var healthScore = appData[i].healthScore;
                    var topAppObjAll = getAppdetail(appData[i], totalUsage);

                    //update lowHealthApp
                   if(appData[i].businessRelevance == 'business-relevant' && appData[i].applicationName != "telnet" ){
                    healthAppObj.applicationName = appName;
                    healthAppObj.healthScore = healthScore;
                    if(healthScore == null){
                        tJson.response.lowHealthApp.unknown.push(healthAppObj);
                        unknownCount++;
                    }else if (healthScore >= 0 && healthScore <= 3) {
                        tJson.response.lowHealthApp.poor.push(healthAppObj);
                        poorCount++;
                    } else if (healthScore >= 4 && healthScore <= 7) {
                        tJson.response.lowHealthApp.fair.push(healthAppObj);
                        fairCount++;
                    } else if (healthScore >= 8 && healthScore <= 10){
                        tJson.response.lowHealthApp.good.push(healthAppObj);
                        goodCount++;
                    }
                   }

                    switch (appData[i].businessRelevance) {
                        case "business-relevant":
                            bussRelCount++;
                            if (appData[i].trafficClass) {
                                bussRelDistTrafficClass = updateObjs(bussRelDistTrafficClass, appData[i].trafficClass, appData[i].usage, topAppObjAll);
                                allDistTrafficClass = updateObjs(allDistTrafficClass, appData[i].trafficClass, appData[i].usage, topAppObjAll);
                            }
                            if (appData[i].applicationGroup) {
                                bussRelDistAppGroup = updateObjs(bussRelDistAppGroup, appData[i].applicationGroup, appData[i].usage, topAppObjAll);
                                allDistAppGroup = updateObjs(allDistAppGroup, appData[i].applicationGroup, appData[i].usage, topAppObjAll);
                            }
                        break;
                        case "business-irrelevant":
                            bussIrrelCount++;
                            if (appData[i].trafficClass) {
                                bussIrrelDistTrafficClass = updateObjs(bussIrrelDistTrafficClass, appData[i].trafficClass, appData[i].usage, topAppObjAll);
                                allDistTrafficClass = updateObjs(allDistTrafficClass, appData[i].trafficClass, appData[i].usage, topAppObjAll);
                            }
                            if (appData[i].applicationGroup) {
                                bussIrrelDistAppGroup = updateObjs(bussIrrelDistAppGroup, appData[i].applicationGroup, appData[i].usage, topAppObjAll);
                                allDistAppGroup = updateObjs(allDistAppGroup, appData[i].applicationGroup, appData[i].usage, topAppObjAll);
                            }
                        break;
                        case "default":
                            defCount++;
                            if (appData[i].trafficClass) {
                                defDistTrafficClass = updateObjs(defDistTrafficClass, appData[i].trafficClass, appData[i].usage, topAppObjAll);
                                allDistTrafficClass = updateObjs(allDistTrafficClass, appData[i].trafficClass, appData[i].usage, topAppObjAll);
                            }
                            if (appData[i].applicationGroup) {
                                defDistAppGroup = updateObjs(defDistAppGroup, appData[i].applicationGroup, appData[i].usage, topAppObjAll);
                                allDistAppGroup = updateObjs(allDistAppGroup, appData[i].applicationGroup, appData[i].usage, topAppObjAll);
                            }
                        break;
                    }
                }
                counts["fairCount"] = fairCount;
                counts["goodCount"] = goodCount;
                counts["poorCount"] = poorCount;
                counts["unknownCount"] = unknownCount;

                tJson.response.usage.applicationCount['business-relevant'] = bussRelCount;
                tJson.response.usage.applicationCount['business-irrelevant'] = bussIrrelCount;
                tJson.response.usage.applicationCount['default'] = defCount;

                tJson.response.usage.distribution['business-relevant'] = bussRelTotalUsage;
                tJson.response.usage.distribution['business-irrelevant'] = bussIrrelTotalUsage;
                tJson.response.usage.distribution['default'] = defTotalUsage;

                tJson.response.usage.usageDetails["business-relevant"].applicationGroup = bussRelDistAppGroup;
                tJson.response.usage.usageDetails["business-irrelevant"].applicationGroup = bussIrrelDistAppGroup;
                tJson.response.usage.usageDetails["default"].applicationGroup = defDistAppGroup;
                tJson.response.usage.usageDetails["all"].applicationGroup = allDistAppGroup;

                tJson.response.usage.usageDetails["business-relevant"].trafficClass = bussRelDistTrafficClass;
                tJson.response.usage.usageDetails["business-irrelevant"].trafficClass = bussIrrelDistTrafficClass;
                tJson.response.usage.usageDetails["default"].trafficClass = defDistTrafficClass;
                tJson.response.usage.usageDetails["all"].trafficClass = allDistTrafficClass;

                tJson.response.latestCounts.poor = poorCount;
                tJson.response.latestCounts.fair = fairCount;
                tJson.response.latestCounts.good = goodCount;
                tJson.response.latestCounts.unknown = unknownCount;
                totalCount = goodCount + fairCount + poorCount + unknownCount;
                tJson.response.latestCounts.healthyAppsPercent = (fairCount + goodCount) * 100 / totalCount;
                tJson.response.usage.appUsageTimeRange.startTime = UtilityFunctions.getTimeStamp();
                tJson.response.usage.appUsageTimeRange.endTime = UtilityFunctions.getTimeStamp();
                tJson.response.usage.appUsageTimeRange.displayTime = new Date().getTime()

                //we need only top 10 applications based on usage
                for (var k = 0; k < appData.length && k < 10; k++) {
                    var topAppObj = getAppdetail(appData[k], totalUsage);
                    tJson.response.usage.topApplications.push(topAppObj);
                }

                if (appData.length == 0) {
                    tJson.response.usage = {};
                    tJson.response.lowHealthApp = {};
                    tJson.response.latestCounts = {};
                    // tJson.response.trend=[];
                }

                tJson.response.trend = generateData(urlAction, tJson.response.trend, counts);
                return tJson;

            },
            getHealthTrendInfo: function (urlAction) {
                let startTime = urlAction.filter.startTime;
                let endTime = urlAction.filter.endTime;
                let coeff = 1000 * 60 * 5;
                let windowTime = ((endTime - startTime) / 1000) / 60 / 60 == 168 ? 3600000 : 300000;
                let tJson = ApplicationAppAggbyIdentifier.HealthTrendInfoTemplate;
                let trendData = tJson.response.trend;
                var healthTrendData = {}
                trendData.map(appData => {
                    appData.healthTrend = [];
                    let current = Math.round(startTime / coeff) * coeff;
                    while (current < endTime) {
                        healthTrendData = {
                            healthScore: null,
                            packetLoss: null,
                            clientPacketLoss: null,
                            serverPacketLoss: null,
                            jitter: null,
                            clientJitter: null,
                            serverJitter: null,
                            latency: null,
                            applicationDelay: null,
                            clientNetworkDelay: null,
                            serverNetworkDelay: null,
                            dscp: "",
                            usage: null,
                            rate: null
                        };                     
                        healthTrendData.time = UtilityFunctions.getGivenTimeInGivenFormat(current, 'YYYY-MM-DDTHH:mm:ss.SSSZZ');
                        if((((endTime - current) >= 60*60*1000) && ((endTime - current) <= 2*60*60*1000)) ||
                        (((endTime - current) >= 2.5*60*60*1000) && ((endTime - current) <= 3*60*60*1000)) || 
                        (((endTime - current) >= 3*60*60*1000) && ((endTime - current) <= 4*60*60*1000)))  {
                            healthTrendData.healthScore = UtilityFunctions.getRandomIntForRangeValues(6, 8)
                            healthTrendData.packetLoss = UtilityFunctions.getRandomIntForRangeValues(1000, 8000)
                            healthTrendData.clientPacketLoss = UtilityFunctions.getRandomIntForRangeValues(1000, 8000)
                            healthTrendData.serverPacketLoss = UtilityFunctions.getRandomIntForRangeValues(1000, 8000)
                            healthTrendData.jitter = UtilityFunctions.getRandomIntForRangeValues(1000, 2000)
                            healthTrendData.clientJitter = UtilityFunctions.getRandomIntForRangeValues(1000, 2000)
                            healthTrendData.serverJitter = UtilityFunctions.getRandomIntForRangeValues(1000, 2000)
                            healthTrendData.latency = UtilityFunctions.getRandomIntForRangeValues(6, 8)
                            healthTrendData.applicationDelay = UtilityFunctions.getRandomIntForRangeValues(6, 8)
                            healthTrendData.clientNetworkDelay = UtilityFunctions.getRandomIntForRangeValues(6, 8)
                            healthTrendData.serverNetworkDelay = UtilityFunctions.getRandomIntForRangeValues(6, 8)
                            healthTrendData.dscp = ""
                            healthTrendData.usage = UtilityFunctions.getRandomIntForRangeValues(600, 800)
                            healthTrendData.rate = UtilityFunctions.getRandomIntForRangeValues(6, 8)
                        } 
                        appData.healthTrend.push(JSON.parse(JSON.stringify(healthTrendData)))
                        current += windowTime;
                    }
                })
                tJson.response.trend = JSON.parse(JSON.stringify(trendData));
                return tJson;
            },

            //called when loading App360 - right top site display
            getAppExporterSiteId: function (urlAction) {
                var exporterSite = [];
                var obj = {};
                /* var groupType = SimLokiDatabaseActions.getFilteredRecordHandler("site", { 'name': 'SJC01' });
                var siteId = groupType.length ? groupType[0].id : 'unknownSiteId'; */
                var strArr = urlAction.filter.query.split('.'), appName;
                strArr.forEach(str => {
                    if (str.startsWith("hasOr(has('applicationName'")) {
                        str = str.substring(str.indexOf(',') + 1, str.indexOf(')')).trim();
                        appName = str.replace(/'/g, "");
                        return;
                    }
                });
                var appRecord = SimLokiDatabaseActions.getFilteredRecordHandler('application', { 'applicationName': appName });
                var siteId;                
                if (appRecord.length == 0) {
                    appRecord = SimLokiDatabaseActions.getFilteredRecordHandler('application', { 'children.applicationName': appName });
                } 
                siteId = appRecord[0].exporterSiteId;
                obj[siteId] = ['200'];
                exporterSite.push(obj);
                return exporterSite;
            },

            //called when loading default Application Overall Health page
            getGroupData: function (urlAction) {
                var exporterSite = [];
                var obj = {};
                var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler("site", { 'name': 'SJC01' });
                var siteId = recordObj.length >0 ? recordObj[0].id : "";
                obj[siteId] = [10431];
                exporterSite.push(obj);
                return exporterSite;
            },

            //gets the buildings and area in specific format
            getLocationData: function (urlAction) {
                //var obj=JSON.parse(JSON.stringify(ApplicationLocationDetails.Template));
                console.log("enter into location");
                var siteArray = createArrayName();
                var site = SimLokiDatabaseActions.getFilteredRecordHandler('site', { name: { '$in': siteArray } });
                //var obj=JSON.parse(JSON.stringify(ApplicationLocationDetails.Template));
                var data = [];
                for (var i = 0; i < site.length; i++) {
                    if (site[i].additionalInfo[1].attributes.type == 'area') {
                        var obj = JSON.parse(JSON.stringify(ApplicationLocationDetails.Template[0]));
                        obj.id = site[i].id;
                        obj.siteHierarchy = [site[i].name],
                            obj.name = [site[i].name],
                            obj.pmtimestamp = new Date().getTime();
                        obj.siteType = [site[i].additionalInfo[1].attributes.type];
                        data.push(obj);
                    }
                    else if (site[i].additionalInfo[1].attributes.type == 'building') {
                        var obj = JSON.parse(JSON.stringify(ApplicationLocationDetails.Template[1]));
                        obj.id = site[i].id;
                        obj.siteHierarchy = [site[i].name],
                            obj.name = [site[i].name],
                            obj.siteType = [site[i].additionalInfo[1].attributes.type];
			    obj.latitude = [site[i].additionalInfo[1].attributes.latitude]
                            obj.longitude = [site[i].additionalInfo[1].attributes.longitude]
                        obj.pmtimestamp = new Date().getTime();
                        data.push(obj);
                    }
                }
                var obj = JSON.parse(JSON.stringify(ApplicationLocationDetails.Template[1]));
                obj.id = '__global__';
                obj.siteHierarchy = ['All Buildings'],
                obj.name = ['All Buildings'],
                obj.siteType = ['building'];
                obj.latitude = ['']
                obj.longitude = ['']
                obj.pmtimestamp = new Date().getTime();
                data.push(obj);
                return data;
            },

            getAppexperience: function (urlAction) {
                var busReleFilter = urlAction.restPayload.businessRelevance;
                var scoreTypeFilter = urlAction.restPayload.scoreType;
                var start = 0;
                var end = 0;
                if (scoreTypeFilter.length >= 1) {
                    if(scoreTypeFilter[0] == "unknown"){
                        start = null;
                        end = null;
                    }else if (scoreTypeFilter[0] == "poor"){
                        start = 0;
                        end = 3;
                    }else if (scoreTypeFilter[0] == "fair"){
                        start = 4;
                        end = 7;
                    }else if(scoreTypeFilter[0] == "good"){
                        start = 8;
                        end = 10;
                    }
                }

                var appFunction = function getFilterApplication(application) {
                    if(scoreTypeFilter[0] == "unknown"){
                        if(busReleFilter.length == 0) {
                            return application.healthScore == null
                        }else{
                            return application.businessRelevance == busReleFilter[0] && application.healthScore == null
                        }
                    } else if (busReleFilter.length == 0 && scoreTypeFilter.length == 0) {
                        return application;
                    } else if (busReleFilter.length == 1 && scoreTypeFilter.length == 0) {
                        return application.businessRelevance == busReleFilter[0];
                    } else if (busReleFilter.length == 0 && scoreTypeFilter.length == 1) {
                        return application.healthScore >= start && application.healthScore <= end && application.healthScore != null
                    } else {
                        return (application.businessRelevance == busReleFilter[0] && application.healthScore >= start
                            && application.healthScore <= end && application.healthScore != null);
                    }
                }

                var data = [];
                if (!urlAction.restPayload.siteId || urlAction.restPayload.siteId == 'unknownSiteId' || 
                        urlAction.restPayload.siteId == '__global__') {
                    data = SimLokiDatabaseActions.getAll('application');
                } else {
                    var siteId = urlAction.restPayload.siteId;
                    var childrenSites = [];
                    SimulationUtility.getChildrenSites(siteId, childrenSites);
                    var siteIdList = [];
                    siteIdList.push(siteId);
                    for (var i = 0; i < childrenSites.length; i++) {
                        siteIdList.push(childrenSites[i].id);
                    }
                    data = SimLokiDatabaseActions.getFilteredRecordHandler('application', { 'clientSiteId': { '$in': siteIdList } });
                }
                if (data.length == 0) {
                    return { version: "1.0", response: [], "page": { "pageSize": 0, "offset": 1 } };
                }

                //var data = SimLokiDatabaseActions.getAll('application');

                //HERE SORTING DONE
                data.sort(function (a, b) {
                    return b.usage - a.usage
                });
                var hours = UtilityFunctions.getHours(urlAction.restPayload["startTime"], urlAction.restPayload["endTime"]);
                if (hours >= 24 || hours == 3 ) {
                    var response = [];
                    for (var i = 0; i < data.length; i++) {
                        var appInfo = JSON.parse(JSON.stringify(data[i]));
                        appInfo.averageRate = appInfo.usage * 8 / 86400;
                        response.push(appInfo);

                    }

                    var resultData = response.filter(appFunction);
                    return { version: "1.0", response: resultData, "page": { "pageSize": resultData.length, "offset": 1, "totalCount": resultData.length } };
                }
                else {
                    var pageSize = 3;       //else part shows latest(5 min) data
                    var response = [];

                    var appList15Min = ['google-services', 'telnet', 'youtube'];
                    for (var i = 0; i < data.length; i++) {
                        var appInfo = JSON.parse(JSON.stringify(data[i]));
                        if (appList15Min.indexOf(appInfo.applicationName) == -1) {
                            appInfo.usage = appInfo.usage / 96;
                            appInfo.averageLatency = appInfo.averageLatency / 2;                            
                            appInfo.averageRate = appInfo.usage * 8 / 900;
                            appInfo.averagePacketLoss = appInfo.averagePacketLoss / 2;  
                            if (appInfo.applicationName == "MedicalRecords") {                                
                                appInfo.averageJitter=2;
                                appInfo.averageLatency = 200;
                                appInfo.averagePacketLoss = 20;                                
                            }
                            response.push(appInfo);
                        }
                    }

                    var resultData = response.filter(appFunction);
                   return { version: "1.0", response: resultData, "page": { "pageSize": resultData.length, "offset": 1, "totalCount": resultData.length } };
              //return minData;
                }
                // return Application.App_Template;
            },

            getClientlist: function (urlAction) {

                var applicationName = (urlAction.service[1] == "msteams") ? "microsoft-teams" : urlAction.service[2];
                var tJson = JSON.parse(JSON.stringify(Clientlist.Template));
                var appData = SimLokiDatabaseActions.getFilteredRecordHandler('application', { 'applicationName': applicationName });

                if (appData.length > 0 && appData[0].hostNames.length > 0) {

                    var result = [];
                    var hostNames = appData[0].hostNames;
                    var totalUsage = appData[0].usage, hostLen = hostNames.length;
                    var avgUsage = totalUsage/hostLen, bufferVal = 100000, usageVal, usageConsumed = 0;

                    for (var i = 0; i < hostLen; i++) {
                        if(i<hostLen-1) {
                            usageVal = UtilityFunctions.getRandomIntForRangeValues((avgUsage-bufferVal),(avgUsage+bufferVal));
                            usageConsumed = usageConsumed + usageVal;
                        } else {
                            usageVal = totalUsage - usageConsumed;
                        }

                        var host = SimLokiDatabaseActions.getFilteredRecordHandler('host', { 'hostName': hostNames[i] });
                        if (host.length > 0) {
                            var locationVal = host[0].location;
                            var location = (locationVal != undefined) ? locationVal.substring(locationVal.indexOf("/") + 1, locationVal.length) : "";
                            result.push({
                                "appHealthScore": appData[0].healthScore,
                                "deviceType": host[0].hostType,
                                "vlan":host[0].vlanId,
                                "healthScore": host[0].healthScore[0].score,
                                "clientName": host[0].hostName,
                                "utilization": usageVal,
                                "location": location,
                                "operatingSystem": host[0].hostOs,
                                "clientMacAddress": host[0].id
                            });
                        }
                    }

                    return { version: "1.0", response: result, "page": { "pageSize": 25, "offset": 1, "totalCount": result.length } };


                } else {

                    for (var i = 0; i < tJson.response.length; i++) {

                        var host = SimLokiDatabaseActions.getFilteredRecordHandler('host', { 'id': tJson.response[i].clientMacAddress });
                        if (host.length > 0) {
                            var locationVal = host[0].location;
                            var location = locationVal.substring(locationVal.indexOf("/") + 1, locationVal.length);
                            tJson.response[i].location = location;
                            tJson.response[i].operatingSystem = host[0].hostOs;

                        }
                    }
                }
                return tJson;
            },
            getHealthTrendDetail: function (urlAction) {
                var applicationName = urlAction.url.split('/')[5];
                return SimAppNameData.getApplication(urlAction, applicationName);
            },

            getAppName: function (urlAction) {
                var applicationName = (urlAction.service.indexOf("msteams") > -1) ? 'microsoft-teams' : urlAction.service[2]; 
                //var applicationName = urlAction.service[2] //urlAction.url.split('/')[5];
                return SimAppNameData.getApplicationResponse(applicationName);
            },

            getClientAppExperience: function (urlAction) {
                //for app experience section called in device 360 of exporter device 
                if(urlAction.service.indexOf('network-device') && urlAction.action.id) {
                    var exporterUuid = urlAction.action.id;
                    var brApps = SimLokiDatabaseActions.getFilteredRecordHandler('application', {
                        '$and': [ {'businessRelevance': 'business-relevant'}, {'exporterUUID': exporterUuid} ] });
                    var birApps = SimLokiDatabaseActions.getFilteredRecordHandler('application', {
                        '$and': [ {'businessRelevance': 'business-irrelevant'}, {'exporterUUID': exporterUuid} ] });
                    var defApps = SimLokiDatabaseActions.getFilteredRecordHandler('application', {
                        '$and': [ {'businessRelevance': 'default'}, {'exporterUUID': exporterUuid} ] });
                    //return { version: "1.0", response: { "business-irrelevant": birApps, "business-relevant": brApps, "default": defApps } };
                    //This is temp fix for loading applications for Device 360 page
                    return AppExperience.AppExperience_Template;
                }

                //for app experience section called in client 360
                var clientMacAddress = urlAction.service[2];
                var host = SimLokiDatabaseActions.getFilteredRecordHandler('host', { 'id': clientMacAddress });

                if (host.length > 0) {
                    var hostName = host[0].hostName;

                    var business_irrelevant = getAppExperienceList("business-irrelevant", hostName);
                    var business_relevant = getAppExperienceList("business-relevant", hostName);
                    var default_list = getAppExperienceList("default", hostName);

                    if (business_irrelevant.length == 0 && business_relevant == 0 && default_list == 0) {
                        return AppExperience.AppExperience_Template;
                    }

                    return { version: "1.0", response: { "business-irrelevant": business_irrelevant, "business-relevant": business_relevant, "default": default_list } };
                } else {

                    return AppExperience.AppExperience_Template;
                }
            }
        };

        function getAppExperienceList(applicationType, hostName) {
            var apps = SimLokiDatabaseActions.getFilteredRecordHandler('application', {
                '$and': [ {'businessRelevance': applicationType}, {'hostNames': { '$contains': hostName }} ]
            });
            return apps;
        }

        function generateData(urlAction, trendData, counts) {
            var tWindowTime = 5, offset = 1;
            var start, end;
            var hours = UtilityFunctions.getHours(urlAction.filter["startTime"], urlAction.filter["endTime"]);
            if (hours > 24) {
                start = 46, end = 49;
                tWindowTime = 180;
            }else if(hours==24){
                start = 14, end = 17;
                tWindowTime = 60;
            }else if (hours > 3) {
                start = 15, end = 18;
                tWindowTime = 5;
            }
            else {
                start = 26, end = 29;
                tWindowTime = 5;
            }
            var incrementBy = UtilityFunctions.getMinuteToMilliSeconds(tWindowTime);

            var iLen = 0;
            var data = [];
            if (trendData != undefined && trendData.length) {
                iLen = (hours * 60) / tWindowTime;
                if (iLen > trendData.length) {
                    iLen = trendData.length;
                }

            }
            var fromTime = urlAction.filter["startTime"];

            var timeToSet = '';
            var totalApps = counts.goodCount + counts.fairCount + counts.poorCount + counts.unknownCount;
            for (var i = 0; i < iLen; i++) {
                timeToSet = UtilityFunctions.getGivenDateAndTimeInValidFormat(fromTime);
                if (trendData[i] != undefined) {
                    trendData[i].time = timeToSet;
                    if (totalApps == 0) {
                        trendData[i].numberOfGoodApps = 0;
                        trendData[i].numberOfFairApps = 0;
                        trendData[i].numberOfPoorApps = 0;
                        trendData[i].numberOfUnmonitoredApps = 0;
                        trendData[i].healthyAppsPercent = 0;
                    } else {
                        var goodCount = counts.goodCount;
                        var randgoodCount = UtilityFunctions.getRandomIntForRangeValues(goodCount - offset, goodCount + offset);
                            randgoodCount = (randgoodCount>totalApps || randgoodCount<0) ? goodCount : randgoodCount;
                        trendData[i].numberOfGoodApps = (counts.goodCount == undefined) ? trendData[i].numberOfGoodApps : randgoodCount;

                        var fairCount = counts.fairCount;
                        var randFairCount = UtilityFunctions.getRandomIntForRangeValues(fairCount - offset, fairCount + offset);
                            randFairCount = (randFairCount>totalApps || randFairCount<0) ? fairCount : randFairCount;
                        trendData[i].numberOfFairApps = (counts.fairCount == undefined) ? trendData[i].numberOfFairApps : randFairCount;

                        var poorCount = counts.poorCount;
                        var randpoorCount = UtilityFunctions.getRandomIntForRangeValues(poorCount - offset, poorCount + offset);
                            randpoorCount = (randpoorCount>totalApps || randpoorCount<0) ? poorCount : randpoorCount;
                            trendData[i].numberOfPoorApps = (counts.poorCount == undefined) ? trendData[i].numberOfPoorApps : randpoorCount;

                        var unknownCount = counts.unknownCount;
                        var randunknownCount = UtilityFunctions.getRandomIntForRangeValues(unknownCount - offset, unknownCount + offset);
                            randunknownCount = (randunknownCount>totalApps || randunknownCount<0) ? goodCount : randunknownCount;                        
                        trendData[i].numberOfUnmonitoredApps = (counts.unknownCount == undefined) ? trendData[i].numberOfUnmonitoredApps : randunknownCount;
                        // trendData[i].healthyAppsPercent = trendData[i].numberOfGoodApps*100/totalApps;
                        trendData[i].healthyAppsPercent = (i > start && i <= end) ? 10 :(trendData[i].numberOfFairApps + trendData[i].numberOfGoodApps) * 100 / totalApps;
                        if(trendData[i].healthyAppsPercent > 100) trendData[i].healthyAppsPercent = 100;
                    }
                    data.push(trendData[i]);
                }
                fromTime = fromTime + incrementBy;
            }

            return data.reverse();
        }

        function getAppdetail(app, totalRelevantUsage) {
            var topAppObj = {};
            if (app.length == 0) {
                return topAppObj;
            }
            topAppObj.applicationName = app.applicationName;
            topAppObj.bandwidthUsage = parseFloat((app.usage / totalRelevantUsage * 100) / 100 * 100).toFixed(2)
            topAppObj.totalBytes = parseFloat((app.usage).toFixed(2))
            topAppObj.businessRelevance = app.businessRelevance
            topAppObj.applicationGroup = app.applicationGroup
            topAppObj.trafficClass = app.trafficClass
            return topAppObj;
        }
        function createArrayName() {
            var tJson = JSON.parse(JSON.stringify(SiteConfig.SITE_DEFAULT_DATA));
            var siteName = [];
            for (j = 0; j < tJson.length; j++) {
                if (tJson[j].type == "floor") {
                    continue;
                }
                siteName.push(tJson[j].name);
            }
            return siteName;
        }

        function updateObjs (allClass, appClass, appusage, topAppObjAll) {
            if(allClass[appClass]){
                allClass[appClass].distribution = allClass[appClass].distribution + appusage;
                if (allClass[appClass].topApplications.length < 10) {
                    allClass[appClass].topApplications.push(topAppObjAll);
                }
            } else {
                allClass[appClass] = {};
                allClass[appClass].topApplications = [];
                allClass[appClass].distribution = appusage;
                if (allClass[appClass].topApplications.length < 10) {
                    allClass[appClass].topApplications.push(topAppObjAll);
                }
            }
            allClass["all"].distribution = allClass["all"].distribution + appusage;
            if (allClass["all"].topApplications.length < 10) {
                allClass["all"].topApplications.push(topAppObjAll);
            }
            return allClass;
        }

    });

