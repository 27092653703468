define({
"Template":
{
  "version": "1.0",
  "response": {
    "id": "AWXEpK7zCj-ipzc8r4Gz",
    "name": "isis_adjacency_failure",
    "entityType": "network_device",
    "entity": "192.168.120.1",
    "enabled": true,
    "flattened": true,
    "groupBy": "Unknown",
    "category": "Connectivity",
    "severity": "HIGH",
    "priority": "P2",
    "summary": "ISIS Adjacency Failed on Device DeviceIPPlaceHolder Interface InterfaceDownPlaceHolder",
    "scope": "GLOBAL",
    "groupId": "interface_connectivity_failure_grouping",
    "groupName": "Network Device Interface Connectivity - ISIS Adjacency Failure",
    "rootCause": "505",
    "timestamp": 1536559529713,
    "description": "ISIS adjacency failed on device name:'DeviceNamePlaceHolder', interface:'InterfaceDownPlaceHolder' at site:'SJCFloor1'",
    "suggestions": [
      {
        "message": "Ping the neighbor IP to verify connectivity.",
        "steps": [

        ]
      },
      {
        "message": "Check ISIS neighbors.",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check ISIS neighbors",
            "command": "show isis neighbor",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Check interface InterfaceDownPlaceHolder has any incrementing errors or mismatch in MTU with remote interface.",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check for errors on the interface InterfaceDownPlaceHolder",
            "command": "show int InterfaceDownPlaceHolder | inc errors|runts|discard|drop|MTU",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Check remote interface has any incrementing errors.",
        "steps": [

        ]
      },
      {
        "message": "Check DOM status if optical interface GigabitEthernet1/0/22.",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check DOM status on interface InterfaceDownPlaceHolder",
            "command": "show interface InterfaceDownPlaceHolder transceiver detail",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Check the ARP table for adjacency entry.",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check the ARP table for adjacency entry",
            "command": "show ip arp | inc InterfaceDownPlaceHolder",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Contact Cisco TAC World Wide – https://www.cisco.com/c/en/us/support/web/tsd-cisco-worldwide-contacts.html",
        "steps": [

        ]
      }
    ],
    "additionalParams": [
      {
        "key": "managementIpAddr",
        "value": "192.168.120.1"
      },
      {
        "key": "remoteIP",
        "value": "NA"
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "__entity_type__",
        "value": "NetworkDevice"
      },
      {
        "key": "entityId",
        "value": "192.168.120.1"
      },
      {
        "key": "type",
        "value": "issue"
      },
      {
        "key": "priority",
        "value": "P1"
      },
      {
        "key": "scope",
        "value": "global"
      },
      {
        "key": "failureReason",
        "value": "ISIS Router Adjacency failed on GigabitEthernet1/0/22"
      },
      {
        "key": "eventStatus",
        "value": "Down"
      },
      {
        "key": "rootCause",
        "value": "505"
      },
      {
        "key": "siteId",
        "value": "b43846ea-a00a-4c4e-96d2-6505a4de2775"
      },
      {
        "key": "syslogMsg",
        "value": "interface GigabitEthernet1/0/22 update CLNS Routing state to DOWN, interface is not suppressed"
      },
      {
        "key": "category",
        "value": "connectivity"
      },
      {
        "key": "timestring",
        "value": "2018-09-10T06:05:29.713+0000"
      },
      {
        "key": "intfName",
        "value": "GigabitEthernet1/0/22"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}
})