define({
  "Template": {
    "version": "1.0",
    "response": [
      {
        "id": "AWQM1GpSxNnJ13sZKwK5",
        "name": "global_sensor_web_server_unreach_trigger",
        "entityType": "Sensor",
        "entity": "global",
        "groupBy": "Unknown",
        "category": "Sensor Test",
        "severity": "HIGH",
        "priority": "P1",
        "summary": "Unreachable Web server from \"Site@ToChange\"",
        "scope": "GLOBAL",
        "rootCause": "sensor_connected_global_webserver_not_reachable",
        "timestamp": 1529223000000,
        "description": "2 sensors from \"Site@ToChange\" are not able to reach the Web server host. Pings are failing.",
        "suggestions": [
          {
            "message": "Verify the Web server address. The sensor test may have been setup incorrectly.",
            "steps": [

            ]
          },
          {
            "message": "Verify if a firewall in the path is blocking ICMP messages.",
            "steps": [

            ]
          },
          {
            "message": "Verify if the Web server host is up.",
            "steps": [

            ]
          },
          {
            "message": "Verify the network path to the Web server host.",
            "steps": [

            ]
          }
        ],
        "additionalParams": [
          {
            "key": "summary",
            "value": "Multiple sensor tests failed"
          },
          {
            "key": "locationName",
            "value": "Global/San Jose/SJ23/SJ23-3"
          },
          {
            "key": "test",
            "value": "webserver"
          },
          {
            "key": "entityType",
            "value": "Sensor"
          },
          {
            "key": "__entity_type__",
            "value": "global"
          },
          {
            "key": "failure_count",
            "value": "2.0"
          },
          {
            "key": "entityId",
            "value": "global"
          },
          {
            "key": "scope",
            "value": "global"
          },
          {
            "key": "tenantid",
            "value": "TNT0"
          },
          {
            "key": "rootCause",
            "value": "sensor_connected_global_webserver_not_reachable"
          },
          {
            "key": "location",
            "value": "b7bb6612-253d-4aac-9640-0a6a0f764b20"
          },
          {
            "key": "category",
            "value": "Sensor Test"
          },
          {
            "key": "device",
            "value": "sensor"
          },
          {
            "key": "status",
            "value": "active"
          },
          {
            "key": "timestring",
            "value": "2018-06-17T08:10:00.000+0000"
          }
        ],
        "status": {
          "status": "ACTIVE",
          "updatedBy": "Unknown",
          "notes": "Unknown",
          "source": "Unknown",
          "updatedAt": null
        },
        "siteQulaifiedName": null
      }
    ]
  }
})