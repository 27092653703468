define({
"Template":{
  "version": "1.0",
  "response": [{
    "id": "3daf3e83-fa98-4294-90b5-c67c75258bb1",
    "name": "global_wireless_kairos_excessive_dhcp_time_trigger",
    "enabled": true,
    "flattened": true,
    "entityType": "Client",
    "entityName": "Unknown",
    "entity": "a1cb44dd724b4059d40e",
    "groupBy": "a1cb44dd724b4059d40e",
    "category": "Onboarding",
    "severity": "HIGH",
    "summary": "Excessive time to get IP Address - At least 94% increase in time on SSID-SSID@ToChange in Site@ToChange.",
    "groupId": "global_wireless_client_onboarding_issues_grouping",
    "groupName": "Global Wireless Client Onboarding Issues",
    "scope": "GLOBAL",
    "priority": "P2",
    "rootCause": "Unknown",
    "timestamp": 1610470800000,
    "description": "Error: issue details are missing. Please check connectivity to AI Network Analytics cloud.",
    "suggestions": null,
    "additionalParams": [
      {
        "key": "groupType",
        "value": "site_ssid"
      },
      {
        "key": "_name",
        "value": "global_wireless_kairos_excessive_dhcp_time_trigger"
      },
      {
        "key": "_entity_type",
        "value": "global"
      },
      {
        "key": "eventSource",
        "value": "Client"
      },
      {
        "key": "groupBy",
        "value": "a1cb44dd724b4059d40e"
      },
      {
        "key": "type",
        "value": "issue"
      },
      {
        "key": "_key",
        "value": "no_suppression"
      },
      {
        "key": "ssid",
        "value": "aes:5YUwDTXnYysIOltsw4psFOBBxENwZQ2DMYRUpA=="
      },
      {
        "key": "floorId",
        "value": ""
      },
      {
        "key": "_count",
        "value": "1"
      },
      {
        "key": "areaName",
        "value": ""
      },
      {
        "key": "scope",
        "value": "global"
      },
      {
        "key": "_primary_key",
        "value": "no_suppression"
      },
      {
        "key": "floorName",
        "value": ""
      },
      {
        "key": "triggerSource",
        "value": "kairos"
      },
      {
        "key": "clientCount",
        "value": "28"
      },
      {
        "key": "siteType",
        "value": "building"
      },
      {
        "key": "severity",
        "value": "HIGH"
      },
      {
        "key": "deviceRole",
        "value": "Wireless"
      },
      {
        "key": "isAIDriven",
        "value": "Yes"
      },
      {
        "key": "kairosIssueId",
        "value": "a1cb44dd724b4059d40e"
      },
      {
        "key": "entityType",
        "value": "interface_host"
      },
      {
        "key": "_eventTime",
        "value": "1610470800000"
      },
      {
        "key": "_mostRecent",
        "value": "1610470800000"
      },
      {
        "key": "entityId",
        "value": "a1cb44dd724b4059d40e"
      },
      {
        "key": "priority",
        "value": "P2"
      },
      {
        "key": "pctDeviation",
        "value": "94"
      },
      {
        "key": "buildingId",
        "value": ""
      },
      {
        "key": "orgTriggerTime",
        "value": "1610485588899156"
      },
      {
        "key": "buildingName",
        "value": ""
      },
      {
        "key": "areaId",
        "value": ""
      },
      {
        "key": "siteHierarchy",
        "value": "Global"
      },
      {
        "key": "category",
        "value": "Onboarding"
      },
      {
        "key": "timestring",
        "value": "2021-01-12T17:00:00.000+0000"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }]
}
})