define({
    "ACCESS":[
        [{"portMode":["access"],"vlanId":["ALL"],"mediaType":["ETHERNETCSMACD"],"speed":["10000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["TenGigabitEthernet1/0/1"],"operStatus":["DOWN"],"interfaceId":["3"],"duplexOper":["AutoNegotiate"]}],
        [{"portMode":["access"],"vlanId":["ALL"],"mediaType":["ETHERNETCSMACD"],"speed":["5000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["TenGigabitEthernet1/0/13"],"operStatus":["UP"],"interfaceId":["15"],"duplexOper":["FullDuplex"]}],
        [{"portMode":["access"],"vlanId":["ALL"],"mediaType":["ETHERNETCSMACD"],"speed":["1000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["TenGigabitEthernet1/0/14"],"operStatus":["UP"],"interfaceId":["16"],"duplexOper":["FullDuplex"]}],
        [{"portMode":["access"],"vlanId":["ALL"],"mediaType":["ETHERNETCSMACD"],"speed":["1000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["TenGigabitEthernet1/0/2"],"operStatus":["UP"],"interfaceId":["4"],"duplexOper":["FullDuplex"]}],
        [{"portMode":["dynamic_auto"],"vlanId":["1"],"mediaType":["ETHERNETCSMACD"],"speed":["40000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["FortyGigabitEthernet1/1/1"],"operStatus":["DOWN"],"interfaceId":["39"],"duplexOper":["AutoNegotiate"]}],
        [{"portMode":["dynamic_auto"],"vlanId":["1"],"mediaType":["ETHERNETCSMACD"],"speed":["40000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["FortyGigabitEthernet1/1/2"],"operStatus":["DOWN"],"interfaceId":["40"],"duplexOper":["AutoNegotiate"]}],
        [{"portMode":["dynamic_auto"],"vlanId":["ALL"],"mediaType":["ETHERNETCSMACD"],"speed":["10000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["TenGigabitEthernet1/0/10"],"operStatus":["DOWN"],"interfaceId":["12"],"duplexOper":["AutoNegotiate"]}],
        [{"portMode":["dynamic_auto"],"vlanId":["ALL"],"mediaType":["ETHERNETCSMACD"],"speed":["10000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["TenGigabitEthernet1/0/11"],"operStatus":["DOWN"],"interfaceId":["13"],"duplexOper":["AutoNegotiate"]}],
        [{"portMode":["dynamic_auto"],"vlanId":["ALL"],"mediaType":["ETHERNETCSMACD"],"speed":["10000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["TenGigabitEthernet1/0/12"],"operStatus":["DOWN"],"interfaceId":["14"],"duplexOper":["AutoNegotiate"]}],
        [{"portMode":["routed"],"vlanId":["0"],"mediaType":["ETHERNETCSMACD"],"speed":["1000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["GigabitEthernet0/0"],"operStatus":["DOWN"],"interfaceId":["1"],"duplexOper":["FullDuplex"]}],
        [{"portMode":["routed"],"vlanId":["0"],"mediaType":["SOFTWARELOOPBACK"],"speed":["8000000"],"interfaceType":["Virtual"],"portType":["Service Module Interface"],"isL3Interface":["TRUE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["Loopback0"],"operStatus":["UP"],"interfaceId":["45"]}],
        [{"portMode":["routed"],"vlanId":["1"],"mediaType":["PROPVIRTUAL"],"speed":["1000000"],"interfaceType":["Virtual"],"portType":["Ethernet SVI"],"isL3Interface":["FALSE"],"adminStatus":["DOWN"],"portChannelId":["0"],"name":["Vlan1"],"operStatus":["DOWN"],"interfaceId":["44"]}],
        [{"portMode":["routed"],"vlanId":["90"],"mediaType":["PROPVIRTUAL"],"speed":["1000000"],"interfaceType":["Virtual"],"portType":["Ethernet SVI"],"isL3Interface":["TRUE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["Vlan90"],"operStatus":["UP"],"interfaceId":["46"]}],
        [{"portMode":["routed"],"vlanId":["999"],"mediaType":["PROPVIRTUAL"],"speed":["1000000"],"interfaceType":["Virtual"],"portType":["Ethernet SVI"],"isL3Interface":["TRUE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["Vlan999"],"operStatus":["UP"],"interfaceId":["47"]}],
        [{"portMode":["trunk"],"vlanId":["ALL"],"mediaType":["ETHERNETCSMACD"],"speed":["10000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["TenGigabitEthernet1/0/24"],"operStatus":["UP"],"interfaceId":["26"],"duplexOper":["FullDuplex"]}]
    ],
    "CORE":[
        [{"portMode":["routed"],"vlanId":["0"],"mediaType":["ETHERNETCSMACD"],"speed":["1000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["TRUE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["Ethernet2/3"],"operStatus":["DOWN"],"interfaceId":["436740096"],"duplexOper":["AutoNegotiate"]}],
        [{"portMode":["routed"],"vlanId":["0"],"mediaType":["ETHERNETCSMACD"],"speed":["10000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["TRUE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["Ethernet1/4"],"operStatus":["UP"],"interfaceId":["436219904"],"duplexOper":["AutoNegotiate"]}],
        [{"portMode":["routed"],"vlanId":["0"],"mediaType":["ETHERNETCSMACD"],"speed":["1000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["TRUE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["Ethernet3/1"],"operStatus":["UP"],"interfaceId":["437256192"],"duplexOper":["AutoNegotiate"]}],
        [{"portMode":["routed"],"vlanId":["0"],"mediaType":["ETHERNETCSMACD"],"speed":["100000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["TRUE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["mgmt0"],"operStatus":["UP"],"interfaceId":["83886080"],"duplexOper":["AutoNegotiate"]}],
        [{"portMode":["routed"],"vlanId":["0"],"mediaType":["ETHERNETCSMACD"],"speed":["10000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["TRUE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["Ethernet1/3"],"operStatus":["UP"],"interfaceId":["436215808"],"duplexOper":["AutoNegotiate"]}],
        [{"portMode":["routed"],"vlanId":["0"],"mediaType":["ETHERNETCSMACD"],"speed":["1000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["DOWN"],"portChannelId":["0"],"name":["Ethernet3/9"],"operStatus":["DOWN"],"interfaceId":["437288960"],"duplexOper":["AutoNegotiate"]}],
        [{"portMode":["routed"],"vlanId":["0"],"mediaType":["ETHERNETCSMACD"],"speed":["1000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["DOWN"],"portChannelId":["0"],"name":["Ethernet3/11"],"operStatus":["DOWN"],"interfaceId":["437297152"],"duplexOper":["AutoNegotiate"]}],
        [{"portMode":["routed"],"vlanId":["0"],"mediaType":["ETHERNETCSMACD"],"speed":["1000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["DOWN"],"portChannelId":["0"],"name":["Ethernet3/13"],"operStatus":["DOWN"],"interfaceId":["437305344"],"duplexOper":["AutoNegotiate"]}],
        [{"portMode":["routed"],"vlanId":["0"],"mediaType":["ETHERNETCSMACD"],"speed":["1000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["DOWN"],"portChannelId":["0"],"name":["Ethernet3/15"],"operStatus":["DOWN"],"interfaceId":["437313536"],"duplexOper":["AutoNegotiate"]}],
        [{"portMode":["routed"],"vlanId":["0"],"mediaType":["ETHERNETCSMACD"],"speed":["10000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["TRUE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["Ethernet1/2"],"operStatus":["DOWN"],"interfaceId":["436211712"],"duplexOper":["AutoNegotiate"]}],
        [{"portMode":["routed"],"vlanId":["0"],"mediaType":["ETHERNETCSMACD"],"speed":["1000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["TRUE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["Ethernet2/1"],"operStatus":["UP"],"interfaceId":["436731904"],"duplexOper":["AutoNegotiate"]}],
        [{"portMode":["routed"],"vlanId":["0"],"mediaType":["ETHERNETCSMACD"],"speed":["1000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["DOWN"],"portChannelId":["0"],"name":["Ethernet3/17"],"operStatus":["DOWN"],"interfaceId":["437321728"],"duplexOper":["AutoNegotiate"]}],
        [{"portMode":["routed"],"vlanId":["0"],"mediaType":["ETHERNETCSMACD"],"speed":["10000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["TRUE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["Ethernet1/1"],"operStatus":["UP"],"interfaceId":["436207616"],"duplexOper":["AutoNegotiate"]}],
        [{"portMode":["routed"],"vlanId":["1"],"mediaType":["PROPVIRTUAL"],"speed":["1000000"],"interfaceType":["Virtual"],"portType":["Ethernet SVI"],"isL3Interface":["FALSE"],"adminStatus":["DOWN"],"portChannelId":["0"],"name":["Vlan1"],"operStatus":["DOWN"],"interfaceId":["151060481"]}],
        [{"portMode":["routed"],"vlanId":["0"],"mediaType":["PROPVIRTUAL"],"speed":["100000"],"interfaceType":["Virtual"],"portType":["Ethernet SVI"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["1"],"name":["Port-channel1"],"operStatus":["DOWN"],"interfaceId":["369098752"],"duplexOper":["AutoNegotiate"]}],	[{"portMode":["routed"],"vlanId":["0"],"mediaType":["TUNNEL"],"speed":["9"],"interfaceType":["Virtual"],"portType":["Others"],"isL3Interface":["TRUE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["Tunnel0"],"operStatus":["UP"],"interfaceId":["402718720"]}],	[{"portMode":["routed"],"vlanId":["0"],"mediaType":["TUNNEL"],"speed":["9"],"interfaceType":["Virtual"],"portType":["Others"],"isL3Interface":["TRUE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["Tunnel1"],"operStatus":["UP"],"interfaceId":["402718721"]}],	[{"portMode":["routed"],"vlanId":["0"],"mediaType":["TUNNEL"],"speed":["9"],"interfaceType":["Virtual"],"portType":["Others"],"isL3Interface":["TRUE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["Tunnel2"],"operStatus":["UP"],"interfaceId":["402718722"]}],
        [{"portMode":["routed"],"vlanId":["0"],"mediaType":["SOFTWARELOOPBACK"],"speed":["8000"],"interfaceType":["Virtual"],"portType":["Service Module Interface"],"isL3Interface":["TRUE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["Loopback0"],"operStatus":["UP"],"interfaceId":["335544320"]}],
        [{"portMode":["access"],"vlanId":["ALL"],"mediaType":["ETHERNETCSMACD"],"speed":["1000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["Ethernet2/5"],"operStatus":["DOWN"],"interfaceId":["436748288"],"duplexOper":["AutoNegotiate"]}]
    ],
    "DISTRIBUTION":[
        [{"portMode":["access"],"vlanId":["ALL"],"mediaType":["ETHERNETCSMACD"],"speed":["1000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["TenGigabitEthernet1/0/6"],"operStatus":["UP"],"interfaceId":["13"],"duplexOper":["FullDuplex"]}],
        [{"portMode":["access"],"vlanId":["ALL"],"mediaType":["ETHERNETCSMACD"],"speed":["10000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["TenGigabitEthernet1/0/5"],"operStatus":["DOWN"],"interfaceId":["12"],"duplexOper":["AutoNegotiate"]}],
        [{"portMode":["dynamic_auto"],"vlanId":["1"],"mediaType":["ETHERNETCSMACD"],"speed":["1000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["GigabitEthernet1/1/4"],"operStatus":["DOWN"],"interfaceId":["35"],"duplexOper":["AutoNegotiate"]}],
        [{"portMode":["dynamic_auto"],"vlanId":["1"],"mediaType":["ETHERNETCSMACD"],"speed":["1000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["GigabitEthernet1/1/2"],"operStatus":["DOWN"],"interfaceId":["33"],"duplexOper":["AutoNegotiate"]}],
        [{"portMode":["dynamic_auto"],"vlanId":["1"],"mediaType":["ETHERNETCSMACD"],"speed":["1000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["GigabitEthernet1/1/3"],"operStatus":["DOWN"],"interfaceId":["34"],"duplexOper":["AutoNegotiate"]}],
        [{"portMode":["dynamic_auto"],"vlanId":["1"],"mediaType":["ETHERNETCSMACD"],"speed":["1000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["GigabitEthernet1/1/1"],"operStatus":["DOWN"],"interfaceId":["32"],"duplexOper":["AutoNegotiate"]}],
        [{"portMode":["dynamic_auto"],"vlanId":["1"],"mediaType":["ETHERNETCSMACD"],"speed":["10000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["TenGigabitEthernet1/1/8"],"operStatus":["DOWN"],"interfaceId":["43"],"duplexOper":["AutoNegotiate"]}],
        [{"portMode":["dynamic_auto"],"vlanId":["ALL"],"mediaType":["ETHERNETCSMACD"],"speed":["10000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["TenGigabitEthernet1/0/9"],"operStatus":["DOWN"],"interfaceId":["16"],"duplexOper":["AutoNegotiate"]}],
        [{"portMode":["dynamic_auto"],"vlanId":["ALL"],"mediaType":["ETHERNETCSMACD"],"speed":["10000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["TenGigabitEthernet1/0/8"],"operStatus":["DOWN"],"interfaceId":["15"],"duplexOper":["AutoNegotiate"]}],
        [{"portMode":["dynamic_auto"],"vlanId":["ALL"],"mediaType":["ETHERNETCSMACD"],"speed":["10000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["TenGigabitEthernet1/0/7"],"operStatus":["DOWN"],"interfaceId":["14"],"duplexOper":["AutoNegotiate"]}],
        [{"portMode":["dynamic_auto"],"vlanId":["ALL"],"mediaType":["ETHERNETCSMACD"],"speed":["10000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["TenGigabitEthernet1/0/4"],"operStatus":["DOWN"],"interfaceId":["11"],"duplexOper":["AutoNegotiate"]}],
        [{"portMode":["routed"],"vlanId":["0"],"mediaType":["ETHERNETCSMACD"],"speed":["1000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["GigabitEthernet0/0"],"operStatus":["DOWN"],"interfaceId":["1"],"duplexOper":["FullDuplex"]}],
        [{"portMode":["routed"],"mediaType":["ETHERNETCSMACD"],"speed":["1000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["TRUE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["TenGigabitEthernet1/0/17"],"operStatus":["UP"],"interfaceId":["24"],"duplexOper":["FullDuplex"]}],
        [{"portMode":["routed"],"mediaType":["ETHERNETCSMACD"],"speed":["10000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["TRUE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["TenGigabitEthernet1/0/19"],"operStatus":["UP"],"interfaceId":["26"],"duplexOper":["FullDuplex"]}],
        [{"portMode":["routed"],"vlanId":["1"],"mediaType":["PROPVIRTUAL"],"speed":["1000000"],"interfaceType":["Virtual"],"portType":["Ethernet SVI"],"isL3Interface":["FALSE"],"adminStatus":["DOWN"],"portChannelId":["0"],"name":["Vlan1"],"operStatus":["DOWN"],"interfaceId":["49"]}],
        [{"portMode":["routed"],"vlanId":["15"],"mediaType":["PROPVIRTUAL"],"speed":["1000000"],"interfaceType":["Virtual"],"portType":["Ethernet SVI"],"isL3Interface":["TRUE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["Vlan15"],"operStatus":["UP"],"interfaceId":["56"]}],
        [{"portMode":["routed"],"vlanId":["16"],"mediaType":["PROPVIRTUAL"],"speed":["1000000"],"interfaceType":["Virtual"],"portType":["Ethernet SVI"],"isL3Interface":["TRUE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["Vlan16"],"operStatus":["UP"],"interfaceId":["57"]}],
        [{"portMode":["routed"],"vlanId":["120"],"mediaType":["PROPVIRTUAL"],"speed":["1000000"],"interfaceType":["Virtual"],"portType":["Ethernet SVI"],"isL3Interface":["TRUE"],"adminStatus":["DOWN"],"portChannelId":["0"],"name":["Vlan120"],"operStatus":["DOWN"],"interfaceId":["66"]}],
        [{"portMode":["routed"],"vlanId":["130"],"mediaType":["PROPVIRTUAL"],"speed":["1000000"],"interfaceType":["Virtual"],"portType":["Ethernet SVI"],"isL3Interface":["TRUE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["Vlan130"],"operStatus":["UP"],"interfaceId":["67"]}],	[{"portMode":["routed"],"vlanId":["0"],"mediaType":["OTHER"],"speed":["0"],"interfaceType":["Virtual"],"portType":["OTHER"],"isL3Interface":["FALSE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["LISP0.4100"],"operStatus":["UP"],"interfaceId":["75"]}],	[{"portMode":["routed"],"vlanId":["0"],"mediaType":["OTHER"],"speed":["0"],"interfaceType":["Virtual"],"portType":["OTHER"],"isL3Interface":["FALSE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["LISP0.4099"],"operStatus":["UP"],"interfaceId":["74"]}],
        [{"portMode":["routed"],"vlanId":["0"],"mediaType":["SOFTWARELOOPBACK"],"speed":["8000000"],"interfaceType":["Virtual"],"portType":["Service Module Interface"],"isL3Interface":["TRUE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["Loopback0"],"operStatus":["UP"],"interfaceId":["50"]}],
        [{"portMode":["routed"],"vlanId":["0"],"mediaType":["SOFTWARELOOPBACK"],"speed":["8000000"],"interfaceType":["Virtual"],"portType":["Service Module Interface"],"isL3Interface":["TRUE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["Loopback1021"],"operStatus":["UP"],"interfaceId":["51"]}],
        [{"portMode":["trunk"],"vlanId":["1"],"mediaType":["ETHERNETCSMACD"],"speed":["100000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["TenGigabitEthernet1/0/21"],"operStatus":["UP"],"interfaceId":["28"],"duplexOper":["FullDuplex"]}],
        [{"portMode":["trunk"],"vlanId":["ALL"],"mediaType":["ETHERNETCSMACD"],"speed":["1000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["10"],"name":["TenGigabitEthernet1/0/1"],"operStatus":["UP"],"interfaceId":["8"],"duplexOper":["FullDuplex"]}],
        [{"portMode":["trunk"],"vlanId":["ALL"],"mediaType":["ETHERNETCSMACD"],"speed":["1000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["TenGigabitEthernet1/0/3"],"operStatus":["UP"],"interfaceId":["10"],"duplexOper":["FullDuplex"]}],
        [{"portMode":["trunk"],"vlanId":["ALL"],"mediaType":["ETHERNETCSMACD"],"speed":["1000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["10"],"name":["TenGigabitEthernet1/0/2"],"operStatus":["UP"],"interfaceId":["9"],"duplexOper":["FullDuplex"]}],
        [{"portMode":["trunk"],"vlanId":["ALL"],"mediaType":["PROPVIRTUAL"],"speed":["2000000"],"interfaceType":["Virtual"],"portType":["Ethernet SVI"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["10"],"name":["Port-channel10"],"operStatus":["UP"],"interfaceId":["53"],"duplexOper":["AutoNegotiate"]}],
        [{"portMode":["trunk"],"vlanId":["ALL"],"mediaType":["PROPVIRTUAL"],"speed":["100000"],"interfaceType":["Virtual"],"portType":["Ethernet SVI"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["11"],"name":["Port-channel11"],"operStatus":["DOWN"],"interfaceId":["54"],"duplexOper":["AutoNegotiate"]}]
    ],
    "ROUTER":[
        [{"portMode":["routed"],"vlanId":["0"],"mediaType":["ETHERNETCSMACD"],"speed":["1000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["TRUE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["GigabitEthernet0/1/7"],"operStatus":["UP"],"interfaceId":["8"],"duplexOper":["FullDuplex"]}],
        [{"portMode":["routed"],"vlanId":["0"],"mediaType":["ETHERNETCSMACD"],"speed":["10000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["DOWN"],"portChannelId":["0"],"name":["GigabitEthernet0"],"operStatus":["DOWN"],"interfaceId":["9"],"duplexOper":["HalfDuplex"]}],
        [{"portMode":["routed"],"vlanId":["0"],"mediaType":["ETHERNETCSMACD"],"speed":["1000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["TRUE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["GigabitEthernet0/1/0"],"operStatus":["UP"],"interfaceId":["1"],"duplexOper":["FullDuplex"]}],
        [{"portMode":["routed"],"vlanId":["0"],"mediaType":["ETHERNETCSMACD"],"speed":["1000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["TRUE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["GigabitEthernet0/1/2"],"operStatus":["DOWN"],"interfaceId":["3"],"duplexOper":["FullDuplex"]}],
        [{"portMode":["routed"],"vlanId":["0"],"mediaType":["ETHERNETCSMACD"],"speed":["1000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["TRUE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["GigabitEthernet0/1/1"],"operStatus":["DOWN"],"interfaceId":["2"],"duplexOper":["FullDuplex"]}],
        [{"portMode":["routed"],"vlanId":["0"],"mediaType":["ETHERNETCSMACD"],"speed":["1000000"],"interfaceType":["Physical"],"portType":["Ethernet Port"],"isL3Interface":["FALSE"],"duplexConfig":["AUTO_NEGOTIATE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["GigabitEthernet0/1/5"],"operStatus":["DOWN"],"interfaceId":["6"],"duplexOper":["FullDuplex"]}],	[{"portMode":["routed"],"vlanId":["0"],"mediaType":["OTHER"],"speed":["10000000"],"interfaceType":["Virtual"],"portType":["OTHER"],"isL3Interface":["FALSE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["VoIP-Null0"],"operStatus":["UP"],"interfaceId":["10"]}],
        [{"portMode":["routed"],"vlanId":["0"],"mediaType":["SOFTWARELOOPBACK"],"speed":["8000000"],"interfaceType":["Virtual"],"portType":["Service Module Interface"],"isL3Interface":["TRUE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["Loopback0"],"operStatus":["UP"],"interfaceId":["12"]}],	[{"portMode":["routed"],"vlanId":["0"],"mediaType":["TUNNEL"],"speed":["10000"],"interfaceType":["Virtual"],"portType":["Others"],"isL3Interface":["FALSE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["Tunnel0"],"operStatus":["UP"],"interfaceId":["14"]}],	[{"portMode":["routed"],"vlanId":["0"],"mediaType":["TUNNEL"],"speed":["10000"],"interfaceType":["Virtual"],"portType":["Others"],"isL3Interface":["TRUE"],"adminStatus":["UP"],"portChannelId":["0"],"name":["Tunnel100"],"operStatus":["UP"],"interfaceId":["13"]}]
    ]
});