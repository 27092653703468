define({
  "TEMPLATE_720": [
    {
      "time": "2019-09-18T12:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-09-19T00:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-09-19T12:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-09-20T00:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-09-20T12:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-09-21T00:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-09-21T12:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-09-22T00:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-09-22T12:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-09-23T00:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-09-23T12:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-09-24T00:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-09-24T12:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-09-25T00:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-09-25T12:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-09-26T00:00:00",
      "dropped": "49",
      "processed": "0",
      "avgDropped": "49",
      "avgProcessed": "0"
    },
    {
      "time": "2019-09-26T12:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-09-27T00:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-09-27T12:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-09-28T00:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-09-28T12:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-09-29T00:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-09-29T12:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-09-30T00:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-09-30T12:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-10-01T00:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-10-01T12:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-10-02T00:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-10-02T12:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-10-03T00:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-10-03T12:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-10-04T00:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-10-04T12:00:00",
      "dropped": "136",
      "processed": "375",
      "avgDropped": "136",
      "avgProcessed": "375"
    },
    {
      "time": "2019-10-05T00:00:00",
      "dropped": "219",
      "processed": "338",
      "avgDropped": "219",
      "avgProcessed": "338"
    },
    {
      "time": "2019-10-05T12:00:00",
      "dropped": "48",
      "processed": "372",
      "avgDropped": "48",
      "avgProcessed": "372"
    },
    {
      "time": "2019-10-06T00:00:00",
      "dropped": "48",
      "processed": "372",
      "avgDropped": "48",
      "avgProcessed": "372"
    },
    {
      "time": "2019-10-06T12:00:00",
      "dropped": "52",
      "processed": "373",
      "avgDropped": "52",
      "avgProcessed": "373"
    },
    {
      "time": "2019-10-07T00:00:00",
      "dropped": "48",
      "processed": "372",
      "avgDropped": "48",
      "avgProcessed": "372"
    },
    {
      "time": "2019-10-07T12:00:00",
      "dropped": "48",
      "processed": "372",
      "avgDropped": "48",
      "avgProcessed": "372"
    },
    {
      "time": "2019-10-08T00:00:00",
      "dropped": "48",
      "processed": "372",
      "avgDropped": "48",
      "avgProcessed": "372"
    },
    {
      "time": "2019-10-08T12:00:00",
      "dropped": "48",
      "processed": "372",
      "avgDropped": "48",
      "avgProcessed": "372"
    },
    {
      "time": "2019-10-09T00:00:00",
      "dropped": "48",
      "processed": "372",
      "avgDropped": "48",
      "avgProcessed": "372"
    },
    {
      "time": "2019-10-09T12:00:00",
      "dropped": "48",
      "processed": "372",
      "avgDropped": "48",
      "avgProcessed": "372"
    },
    {
      "time": "2019-10-10T00:00:00",
      "dropped": "48",
      "processed": "372",
      "avgDropped": "48",
      "avgProcessed": "372"
    },
    {
      "time": "2019-10-10T12:00:00",
      "dropped": "888",
      "processed": "582",
      "avgDropped": "888",
      "avgProcessed": "582"
    },
    {
      "time": "2019-10-11T00:00:00",
      "dropped": "759",
      "processed": "551",
      "avgDropped": "759",
      "avgProcessed": "551"
    },
    {
      "time": "2019-10-11T12:00:00",
      "dropped": "600",
      "processed": "510",
      "avgDropped": "600",
      "avgProcessed": "510"
    },
    {
      "time": "2019-10-12T00:00:00",
      "dropped": "655",
      "processed": "524",
      "avgDropped": "655",
      "avgProcessed": "524"
    },
    {
      "time": "2019-10-12T12:00:00",
      "dropped": "548",
      "processed": "501",
      "avgDropped": "548",
      "avgProcessed": "501"
    },
    {
      "time": "2019-10-13T00:00:00",
      "dropped": "160",
      "processed": "400",
      "avgDropped": "160",
      "avgProcessed": "400"
    },
    {
      "time": "2019-10-13T12:00:00",
      "dropped": "1160",
      "processed": "651",
      "avgDropped": "1160",
      "avgProcessed": "651"
    },
    {
      "time": "2019-10-14T00:00:00",
      "dropped": "684",
      "processed": "536",
      "avgDropped": "684",
      "avgProcessed": "536"
    },
    {
      "time": "2019-10-14T12:00:00",
      "dropped": "740",
      "processed": "548",
      "avgDropped": "740",
      "avgProcessed": "548"
    },
    {
      "time": "2019-10-15T00:00:00",
      "dropped": "648",
      "processed": "523",
      "avgDropped": "648",
      "avgProcessed": "523"
    },
    {
      "time": "2019-10-15T12:00:00",
      "dropped": "444",
      "processed": "472",
      "avgDropped": "444",
      "avgProcessed": "472"
    },
    {
      "time": "2019-10-16T00:00:00",
      "dropped": "507",
      "processed": "487",
      "avgDropped": "507",
      "avgProcessed": "487"
    },
    {
      "time": "2019-10-16T12:00:00",
      "dropped": "556",
      "processed": "370",
      "avgDropped": "556",
      "avgProcessed": "370"
    },
    {
      "time": "2019-10-17T00:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-10-17T12:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    },
    {
      "time": "2019-10-18T00:00:00",
      "dropped": "0",
      "processed": "0",
      "avgDropped": "0",
      "avgProcessed": "0"
    }
  ],
  "TEMPLATE_60": [
    {
      "time": "2019-10-11T10:00:00",
      "dropped": "36",
      "processed": "39",
      "avgDropped": "36",
      "avgProcessed": "39"
    },
    {
      "time": "2019-10-11T11:00:00",
      "dropped": "72",
      "processed": "48",
      "avgDropped": "72",
      "avgProcessed": "48"
    },
    {
      "time": "2019-10-11T12:00:00",
      "dropped": "112",
      "processed": "58",
      "avgDropped": "112",
      "avgProcessed": "58"
    },
    {
      "time": "2019-10-11T13:00:00",
      "dropped": "56",
      "processed": "44",
      "avgDropped": "56",
      "avgProcessed": "44"
    },
    {
      "time": "2019-10-11T14:00:00",
      "dropped": "36",
      "processed": "39",
      "avgDropped": "36",
      "avgProcessed": "39"
    },
    {
      "time": "2019-10-11T15:00:00",
      "dropped": "36",
      "processed": "39",
      "avgDropped": "36",
      "avgProcessed": "39"
    },
    {
      "time": "2019-10-11T16:00:00",
      "dropped": "64",
      "processed": "46",
      "avgDropped": "64",
      "avgProcessed": "46"
    },
    {
      "time": "2019-10-11T17:00:00",
      "dropped": "99",
      "processed": "55",
      "avgDropped": "99",
      "avgProcessed": "55"
    },
    {
      "time": "2019-10-11T18:00:00",
      "dropped": "72",
      "processed": "48",
      "avgDropped": "72",
      "avgProcessed": "48"
    },
    {
      "time": "2019-10-11T19:00:00",
      "dropped": "36",
      "processed": "39",
      "avgDropped": "36",
      "avgProcessed": "39"
    },
    {
      "time": "2019-10-11T20:00:00",
      "dropped": "68",
      "processed": "47",
      "avgDropped": "68",
      "avgProcessed": "47"
    },
    {
      "time": "2019-10-11T21:00:00",
      "dropped": "68",
      "processed": "47",
      "avgDropped": "68",
      "avgProcessed": "47"
    },
    {
      "time": "2019-10-11T22:00:00",
      "dropped": "68",
      "processed": "47",
      "avgDropped": "68",
      "avgProcessed": "47"
    },
    {
      "time": "2019-10-11T23:00:00",
      "dropped": "96",
      "processed": "54",
      "avgDropped": "96",
      "avgProcessed": "54"
    },
    {
      "time": "2019-10-12T00:00:00",
      "dropped": "8",
      "processed": "32",
      "avgDropped": "8",
      "avgProcessed": "32"
    },
    {
      "time": "2019-10-12T01:00:00",
      "dropped": "4",
      "processed": "31",
      "avgDropped": "4",
      "avgProcessed": "31"
    },
    {
      "time": "2019-10-12T02:00:00",
      "dropped": "4",
      "processed": "31",
      "avgDropped": "4",
      "avgProcessed": "31"
    },
    {
      "time": "2019-10-12T03:00:00",
      "dropped": "4",
      "processed": "31",
      "avgDropped": "4",
      "avgProcessed": "31"
    },
    {
      "time": "2019-10-12T04:00:00",
      "dropped": "40",
      "processed": "40",
      "avgDropped": "40",
      "avgProcessed": "40"
    },
    {
      "time": "2019-10-12T05:00:00",
      "dropped": "92",
      "processed": "53",
      "avgDropped": "92",
      "avgProcessed": "53"
    },
    {
      "time": "2019-10-12T06:00:00",
      "dropped": "68",
      "processed": "47",
      "avgDropped": "68",
      "avgProcessed": "47"
    },
    {
      "time": "2019-10-12T07:00:00",
      "dropped": "4",
      "processed": "32",
      "avgDropped": "4",
      "avgProcessed": "32"
    },
    {
      "time": "2019-10-12T08:00:00",
      "dropped": "16",
      "processed": "35",
      "avgDropped": "16",
      "avgProcessed": "35"
    },
    {
      "time": "2019-10-12T09:00:00",
      "dropped": "28",
      "processed": "37",
      "avgDropped": "28",
      "avgProcessed": "37"
    },
    {
      "time": "2019-10-12T10:00:00",
      "dropped": "92",
      "processed": "53",
      "avgDropped": "92",
      "avgProcessed": "53"
    },
    {
      "time": "2019-10-12T11:00:00",
      "dropped": "96",
      "processed": "56",
      "avgDropped": "96",
      "avgProcessed": "56"
    },
    {
      "time": "2019-10-12T12:00:00",
      "dropped": "68",
      "processed": "47",
      "avgDropped": "68",
      "avgProcessed": "47"
    },
    {
      "time": "2019-10-12T13:00:00",
      "dropped": "36",
      "processed": "39",
      "avgDropped": "36",
      "avgProcessed": "39"
    },
    {
      "time": "2019-10-12T14:00:00",
      "dropped": "4",
      "processed": "31",
      "avgDropped": "4",
      "avgProcessed": "31"
    },
    {
      "time": "2019-10-12T15:00:00",
      "dropped": "28",
      "processed": "37",
      "avgDropped": "28",
      "avgProcessed": "37"
    },
    {
      "time": "2019-10-12T16:00:00",
      "dropped": "88",
      "processed": "52",
      "avgDropped": "88",
      "avgProcessed": "52"
    },
    {
      "time": "2019-10-12T17:00:00",
      "dropped": "8",
      "processed": "32",
      "avgDropped": "8",
      "avgProcessed": "32"
    },
    {
      "time": "2019-10-12T18:00:00",
      "dropped": "4",
      "processed": "31",
      "avgDropped": "4",
      "avgProcessed": "31"
    },
    {
      "time": "2019-10-12T19:00:00",
      "dropped": "4",
      "processed": "31",
      "avgDropped": "4",
      "avgProcessed": "31"
    },
    {
      "time": "2019-10-12T20:00:00",
      "dropped": "4",
      "processed": "31",
      "avgDropped": "4",
      "avgProcessed": "31"
    },
    {
      "time": "2019-10-12T21:00:00",
      "dropped": "4",
      "processed": "31",
      "avgDropped": "4",
      "avgProcessed": "31"
    },
    {
      "time": "2019-10-12T22:00:00",
      "dropped": "4",
      "processed": "31",
      "avgDropped": "4",
      "avgProcessed": "31"
    },
    {
      "time": "2019-10-12T23:00:00",
      "dropped": "4",
      "processed": "31",
      "avgDropped": "4",
      "avgProcessed": "31"
    },
    {
      "time": "2019-10-13T00:00:00",
      "dropped": "4",
      "processed": "31",
      "avgDropped": "4",
      "avgProcessed": "31"
    },
    {
      "time": "2019-10-13T01:00:00",
      "dropped": "4",
      "processed": "31",
      "avgDropped": "4",
      "avgProcessed": "31"
    },
    {
      "time": "2019-10-13T02:00:00",
      "dropped": "4",
      "processed": "31",
      "avgDropped": "4",
      "avgProcessed": "31"
    },
    {
      "time": "2019-10-13T03:00:00",
      "dropped": "156",
      "processed": "69",
      "avgDropped": "156",
      "avgProcessed": "69"
    },
    {
      "time": "2019-10-13T04:00:00",
      "dropped": "64",
      "processed": "46",
      "avgDropped": "64",
      "avgProcessed": "46"
    },
    {
      "time": "2019-10-13T05:00:00",
      "dropped": "172",
      "processed": "73",
      "avgDropped": "172",
      "avgProcessed": "73"
    },
    {
      "time": "2019-10-13T06:00:00",
      "dropped": "124",
      "processed": "61",
      "avgDropped": "124",
      "avgProcessed": "61"
    },
    {
      "time": "2019-10-13T07:00:00",
      "dropped": "120",
      "processed": "60",
      "avgDropped": "120",
      "avgProcessed": "60"
    },
    {
      "time": "2019-10-13T08:00:00",
      "dropped": "124",
      "processed": "61",
      "avgDropped": "124",
      "avgProcessed": "61"
    },
    {
      "time": "2019-10-13T09:00:00",
      "dropped": "80",
      "processed": "50",
      "avgDropped": "80",
      "avgProcessed": "50"
    },
    {
      "time": "2019-10-13T10:00:00",
      "dropped": "8",
      "processed": "33",
      "avgDropped": "8",
      "avgProcessed": "33"
    },
    {
      "time": "2019-10-13T11:00:00",
      "dropped": "124",
      "processed": "61",
      "avgDropped": "124",
      "avgProcessed": "61"
    },
    {
      "time": "2019-10-13T12:00:00",
      "dropped": "60",
      "processed": "45",
      "avgDropped": "60",
      "avgProcessed": "45"
    },
    {
      "time": "2019-10-13T13:00:00",
      "dropped": "124",
      "processed": "61",
      "avgDropped": "124",
      "avgProcessed": "61"
    },
    {
      "time": "2019-10-13T14:00:00",
      "dropped": "40",
      "processed": "41",
      "avgDropped": "40",
      "avgProcessed": "41"
    },
    {
      "time": "2019-10-13T15:00:00",
      "dropped": "64",
      "processed": "46",
      "avgDropped": "64",
      "avgProcessed": "46"
    },
    {
      "time": "2019-10-13T16:00:00",
      "dropped": "36",
      "processed": "39",
      "avgDropped": "36",
      "avgProcessed": "39"
    },
    {
      "time": "2019-10-13T17:00:00",
      "dropped": "72",
      "processed": "49",
      "avgDropped": "72",
      "avgProcessed": "49"
    },
    {
      "time": "2019-10-13T18:00:00",
      "dropped": "4",
      "processed": "31",
      "avgDropped": "4",
      "avgProcessed": "31"
    },
    {
      "time": "2019-10-13T19:00:00",
      "dropped": "88",
      "processed": "52",
      "avgDropped": "88",
      "avgProcessed": "52"
    },
    {
      "time": "2019-10-13T20:00:00",
      "dropped": "112",
      "processed": "59",
      "avgDropped": "112",
      "avgProcessed": "59"
    },
    {
      "time": "2019-10-13T21:00:00",
      "dropped": "40",
      "processed": "41",
      "avgDropped": "40",
      "avgProcessed": "41"
    },
    {
      "time": "2019-10-13T22:00:00",
      "dropped": "60",
      "processed": "45",
      "avgDropped": "60",
      "avgProcessed": "45"
    },
    {
      "time": "2019-10-13T23:00:00",
      "dropped": "40",
      "processed": "40",
      "avgDropped": "40",
      "avgProcessed": "40"
    },
    {
      "time": "2019-10-14T00:00:00",
      "dropped": "40",
      "processed": "41",
      "avgDropped": "40",
      "avgProcessed": "41"
    },
    {
      "time": "2019-10-14T01:00:00",
      "dropped": "88",
      "processed": "52",
      "avgDropped": "88",
      "avgProcessed": "52"
    },
    {
      "time": "2019-10-14T02:00:00",
      "dropped": "36",
      "processed": "39",
      "avgDropped": "36",
      "avgProcessed": "39"
    },
    {
      "time": "2019-10-14T03:00:00",
      "dropped": "56",
      "processed": "44",
      "avgDropped": "56",
      "avgProcessed": "44"
    },
    {
      "time": "2019-10-14T04:00:00",
      "dropped": "64",
      "processed": "46",
      "avgDropped": "64",
      "avgProcessed": "46"
    },
    {
      "time": "2019-10-14T05:00:00",
      "dropped": "12",
      "processed": "33",
      "avgDropped": "12",
      "avgProcessed": "33"
    },
    {
      "time": "2019-10-14T06:00:00",
      "dropped": "40",
      "processed": "40",
      "avgDropped": "40",
      "avgProcessed": "40"
    },
    {
      "time": "2019-10-14T07:00:00",
      "dropped": "168",
      "processed": "72",
      "avgDropped": "168",
      "avgProcessed": "72"
    },
    {
      "time": "2019-10-14T08:00:00",
      "dropped": "44",
      "processed": "41",
      "avgDropped": "44",
      "avgProcessed": "41"
    },
    {
      "time": "2019-10-14T09:00:00",
      "dropped": "32",
      "processed": "38",
      "avgDropped": "32",
      "avgProcessed": "38"
    },
    {
      "time": "2019-10-14T10:00:00",
      "dropped": "40",
      "processed": "42",
      "avgDropped": "40",
      "avgProcessed": "42"
    },
    {
      "time": "2019-10-14T11:00:00",
      "dropped": "116",
      "processed": "59",
      "avgDropped": "116",
      "avgProcessed": "59"
    },
    {
      "time": "2019-10-14T12:00:00",
      "dropped": "44",
      "processed": "41",
      "avgDropped": "44",
      "avgProcessed": "41"
    },
    {
      "time": "2019-10-14T13:00:00",
      "dropped": "88",
      "processed": "53",
      "avgDropped": "88",
      "avgProcessed": "53"
    },
    {
      "time": "2019-10-14T14:00:00",
      "dropped": "116",
      "processed": "60",
      "avgDropped": "116",
      "avgProcessed": "60"
    },
    {
      "time": "2019-10-14T15:00:00",
      "dropped": "72",
      "processed": "48",
      "avgDropped": "72",
      "avgProcessed": "48"
    },
    {
      "time": "2019-10-14T16:00:00",
      "dropped": "40",
      "processed": "40",
      "avgDropped": "40",
      "avgProcessed": "40"
    },
    {
      "time": "2019-10-14T17:00:00",
      "dropped": "36",
      "processed": "39",
      "avgDropped": "36",
      "avgProcessed": "39"
    },
    {
      "time": "2019-10-14T18:00:00",
      "dropped": "64",
      "processed": "46",
      "avgDropped": "64",
      "avgProcessed": "46"
    },
    {
      "time": "2019-10-14T19:00:00",
      "dropped": "40",
      "processed": "40",
      "avgDropped": "40",
      "avgProcessed": "40"
    },
    {
      "time": "2019-10-14T20:00:00",
      "dropped": "152",
      "processed": "68",
      "avgDropped": "152",
      "avgProcessed": "68"
    },
    {
      "time": "2019-10-14T21:00:00",
      "dropped": "44",
      "processed": "41",
      "avgDropped": "44",
      "avgProcessed": "41"
    },
    {
      "time": "2019-10-14T22:00:00",
      "dropped": "60",
      "processed": "45",
      "avgDropped": "60",
      "avgProcessed": "45"
    },
    {
      "time": "2019-10-14T23:00:00",
      "dropped": "16",
      "processed": "34",
      "avgDropped": "16",
      "avgProcessed": "34"
    },
    {
      "time": "2019-10-15T00:00:00",
      "dropped": "4",
      "processed": "31",
      "avgDropped": "4",
      "avgProcessed": "31"
    },
    {
      "time": "2019-10-15T01:00:00",
      "dropped": "4",
      "processed": "31",
      "avgDropped": "4",
      "avgProcessed": "31"
    },
    {
      "time": "2019-10-15T02:00:00",
      "dropped": "56",
      "processed": "44",
      "avgDropped": "56",
      "avgProcessed": "44"
    },
    {
      "time": "2019-10-15T03:00:00",
      "dropped": "8",
      "processed": "32",
      "avgDropped": "8",
      "avgProcessed": "32"
    },
    {
      "time": "2019-10-15T04:00:00",
      "dropped": "40",
      "processed": "40",
      "avgDropped": "40",
      "avgProcessed": "40"
    },
    {
      "time": "2019-10-15T05:00:00",
      "dropped": "4",
      "processed": "31",
      "avgDropped": "4",
      "avgProcessed": "31"
    },
    {
      "time": "2019-10-15T06:00:00",
      "dropped": "4",
      "processed": "31",
      "avgDropped": "4",
      "avgProcessed": "31"
    },
    {
      "time": "2019-10-15T07:00:00",
      "dropped": "4",
      "processed": "31",
      "avgDropped": "4",
      "avgProcessed": "31"
    },
    {
      "time": "2019-10-15T08:00:00",
      "dropped": "56",
      "processed": "45",
      "avgDropped": "56",
      "avgProcessed": "45"
    },
    {
      "time": "2019-10-15T09:00:00",
      "dropped": "124",
      "processed": "61",
      "avgDropped": "124",
      "avgProcessed": "61"
    },
    {
      "time": "2019-10-15T10:00:00",
      "dropped": "44",
      "processed": "41",
      "avgDropped": "44",
      "avgProcessed": "41"
    },
    {
      "time": "2019-10-15T11:00:00",
      "dropped": "4",
      "processed": "31",
      "avgDropped": "4",
      "avgProcessed": "31"
    },
    {
      "time": "2019-10-15T12:00:00",
      "dropped": "64",
      "processed": "46",
      "avgDropped": "64",
      "avgProcessed": "46"
    },
    {
      "time": "2019-10-15T13:00:00",
      "dropped": "36",
      "processed": "39",
      "avgDropped": "36",
      "avgProcessed": "39"
    },
    {
      "time": "2019-10-15T14:00:00",
      "dropped": "120",
      "processed": "60",
      "avgDropped": "120",
      "avgProcessed": "60"
    },
    {
      "time": "2019-10-15T15:00:00",
      "dropped": "8",
      "processed": "32",
      "avgDropped": "8",
      "avgProcessed": "32"
    },
    {
      "time": "2019-10-15T16:00:00",
      "dropped": "64",
      "processed": "46",
      "avgDropped": "64",
      "avgProcessed": "46"
    },
    {
      "time": "2019-10-15T17:00:00",
      "dropped": "35",
      "processed": "39",
      "avgDropped": "35",
      "avgProcessed": "39"
    },
    {
      "time": "2019-10-15T18:00:00",
      "dropped": "52",
      "processed": "43",
      "avgDropped": "52",
      "avgProcessed": "43"
    },
    {
      "time": "2019-10-15T19:00:00",
      "dropped": "44",
      "processed": "41",
      "avgDropped": "44",
      "avgProcessed": "41"
    },
    {
      "time": "2019-10-15T20:00:00",
      "dropped": "36",
      "processed": "39",
      "avgDropped": "36",
      "avgProcessed": "39"
    },
    {
      "time": "2019-10-15T21:00:00",
      "dropped": "4",
      "processed": "31",
      "avgDropped": "4",
      "avgProcessed": "31"
    },
    {
      "time": "2019-10-15T22:00:00",
      "dropped": "4",
      "processed": "31",
      "avgDropped": "4",
      "avgProcessed": "31"
    },
    {
      "time": "2019-10-15T23:00:00",
      "dropped": "36",
      "processed": "39",
      "avgDropped": "36",
      "avgProcessed": "39"
    },
    {
      "time": "2019-10-16T00:00:00",
      "dropped": "36",
      "processed": "39",
      "avgDropped": "36",
      "avgProcessed": "39"
    },
    {
      "time": "2019-10-16T01:00:00",
      "dropped": "68",
      "processed": "47",
      "avgDropped": "68",
      "avgProcessed": "47"
    },
    {
      "time": "2019-10-16T02:00:00",
      "dropped": "68",
      "processed": "47",
      "avgDropped": "68",
      "avgProcessed": "47"
    },
    {
      "time": "2019-10-16T03:00:00",
      "dropped": "124",
      "processed": "61",
      "avgDropped": "124",
      "avgProcessed": "61"
    },
    {
      "time": "2019-10-16T04:00:00",
      "dropped": "64",
      "processed": "46",
      "avgDropped": "64",
      "avgProcessed": "46"
    },
    {
      "time": "2019-10-16T05:00:00",
      "dropped": "72",
      "processed": "48",
      "avgDropped": "72",
      "avgProcessed": "48"
    },
    {
      "time": "2019-10-16T06:00:00",
      "dropped": "148",
      "processed": "68",
      "avgDropped": "148",
      "avgProcessed": "68"
    },
    {
      "time": "2019-10-16T07:00:00",
      "dropped": "24",
      "processed": "36",
      "avgDropped": "24",
      "avgProcessed": "36"
    },
    {
      "time": "2019-10-16T08:00:00",
      "dropped": "52",
      "processed": "43",
      "avgDropped": "52",
      "avgProcessed": "43"
    },
    {
      "time": "2019-10-16T09:00:00",
      "dropped": "4",
      "processed": "21",
      "avgDropped": "4",
      "avgProcessed": "21"
    }
  ],
  "TEMPLATE_30": [
    {
      "time": "2019-10-14T06:30:00",
      "dropped": "28",
      "processed": "23",
      "avgDropped": "28",
      "avgProcessed": "23"
    },
    {
      "time": "2019-10-14T07:00:00",
      "dropped": "140",
      "processed": "49",
      "avgDropped": "140",
      "avgProcessed": "49"
    },
    {
      "time": "2019-10-14T07:30:00",
      "dropped": "40",
      "processed": "26",
      "avgDropped": "40",
      "avgProcessed": "26"
    },
    {
      "time": "2019-10-14T08:00:00",
      "dropped": "4",
      "processed": "15",
      "avgDropped": "4",
      "avgProcessed": "15"
    },
    {
      "time": "2019-10-14T08:30:00",
      "dropped": "32",
      "processed": "24",
      "avgDropped": "32",
      "avgProcessed": "24"
    },
    {
      "time": "2019-10-14T09:00:00",
      "dropped": "0",
      "processed": "14",
      "avgDropped": "0",
      "avgProcessed": "14"
    },
    {
      "time": "2019-10-14T09:30:00",
      "dropped": "36",
      "processed": "27",
      "avgDropped": "36",
      "avgProcessed": "27"
    },
    {
      "time": "2019-10-14T10:00:00",
      "dropped": "4",
      "processed": "15",
      "avgDropped": "4",
      "avgProcessed": "15"
    },
    {
      "time": "2019-10-14T10:30:00",
      "dropped": "84",
      "processed": "37",
      "avgDropped": "84",
      "avgProcessed": "37"
    },
    {
      "time": "2019-10-14T11:00:00",
      "dropped": "32",
      "processed": "22",
      "avgDropped": "32",
      "avgProcessed": "22"
    },
    {
      "time": "2019-10-14T11:30:00",
      "dropped": "8",
      "processed": "18",
      "avgDropped": "8",
      "avgProcessed": "18"
    },
    {
      "time": "2019-10-14T12:00:00",
      "dropped": "36",
      "processed": "23",
      "avgDropped": "36",
      "avgProcessed": "23"
    },
    {
      "time": "2019-10-14T12:30:00",
      "dropped": "32",
      "processed": "25",
      "avgDropped": "32",
      "avgProcessed": "25"
    },
    {
      "time": "2019-10-14T13:00:00",
      "dropped": "56",
      "processed": "28",
      "avgDropped": "56",
      "avgProcessed": "28"
    },
    {
      "time": "2019-10-14T13:30:00",
      "dropped": "88",
      "processed": "39",
      "avgDropped": "88",
      "avgProcessed": "39"
    },
    {
      "time": "2019-10-14T14:00:00",
      "dropped": "28",
      "processed": "21",
      "avgDropped": "28",
      "avgProcessed": "21"
    },
    {
      "time": "2019-10-14T14:30:00",
      "dropped": "8",
      "processed": "18",
      "avgDropped": "8",
      "avgProcessed": "18"
    },
    {
      "time": "2019-10-14T15:00:00",
      "dropped": "64",
      "processed": "30",
      "avgDropped": "64",
      "avgProcessed": "30"
    },
    {
      "time": "2019-10-14T15:30:00",
      "dropped": "8",
      "processed": "18",
      "avgDropped": "8",
      "avgProcessed": "18"
    },
    {
      "time": "2019-10-14T16:00:00",
      "dropped": "32",
      "processed": "22",
      "avgDropped": "32",
      "avgProcessed": "22"
    },
    {
      "time": "2019-10-14T16:30:00",
      "dropped": "4",
      "processed": "17",
      "avgDropped": "4",
      "avgProcessed": "17"
    },
    {
      "time": "2019-10-14T17:00:00",
      "dropped": "32",
      "processed": "22",
      "avgDropped": "32",
      "avgProcessed": "22"
    },
    {
      "time": "2019-10-14T17:30:00",
      "dropped": "4",
      "processed": "17",
      "avgDropped": "4",
      "avgProcessed": "17"
    },
    {
      "time": "2019-10-14T18:00:00",
      "dropped": "60",
      "processed": "29",
      "avgDropped": "60",
      "avgProcessed": "29"
    },
    {
      "time": "2019-10-14T18:30:00",
      "dropped": "36",
      "processed": "25",
      "avgDropped": "36",
      "avgProcessed": "25"
    },
    {
      "time": "2019-10-14T19:00:00",
      "dropped": "4",
      "processed": "15",
      "avgDropped": "4",
      "avgProcessed": "15"
    },
    {
      "time": "2019-10-14T19:30:00",
      "dropped": "64",
      "processed": "32",
      "avgDropped": "64",
      "avgProcessed": "32"
    },
    {
      "time": "2019-10-14T20:00:00",
      "dropped": "88",
      "processed": "36",
      "avgDropped": "88",
      "avgProcessed": "36"
    },
    {
      "time": "2019-10-14T20:30:00",
      "dropped": "12",
      "processed": "19",
      "avgDropped": "12",
      "avgProcessed": "19"
    },
    {
      "time": "2019-10-14T21:00:00",
      "dropped": "32",
      "processed": "22",
      "avgDropped": "32",
      "avgProcessed": "22"
    },
    {
      "time": "2019-10-14T21:30:00",
      "dropped": "32",
      "processed": "24",
      "avgDropped": "32",
      "avgProcessed": "24"
    },
    {
      "time": "2019-10-14T22:00:00",
      "dropped": "28",
      "processed": "21",
      "avgDropped": "28",
      "avgProcessed": "21"
    },
    {
      "time": "2019-10-14T22:30:00",
      "dropped": "12",
      "processed": "19",
      "avgDropped": "12",
      "avgProcessed": "19"
    },
    {
      "time": "2019-10-14T23:00:00",
      "dropped": "4",
      "processed": "15",
      "avgDropped": "4",
      "avgProcessed": "15"
    },
    {
      "time": "2019-10-14T23:30:00",
      "dropped": "0",
      "processed": "16",
      "avgDropped": "0",
      "avgProcessed": "16"
    },
    {
      "time": "2019-10-15T00:00:00",
      "dropped": "4",
      "processed": "15",
      "avgDropped": "4",
      "avgProcessed": "15"
    },
    {
      "time": "2019-10-15T00:30:00",
      "dropped": "0",
      "processed": "16",
      "avgDropped": "0",
      "avgProcessed": "16"
    },
    {
      "time": "2019-10-15T01:00:00",
      "dropped": "4",
      "processed": "15",
      "avgDropped": "4",
      "avgProcessed": "15"
    },
    {
      "time": "2019-10-15T01:30:00",
      "dropped": "0",
      "processed": "16",
      "avgDropped": "0",
      "avgProcessed": "16"
    },
    {
      "time": "2019-10-15T02:00:00",
      "dropped": "56",
      "processed": "28",
      "avgDropped": "56",
      "avgProcessed": "28"
    },
    {
      "time": "2019-10-15T02:30:00",
      "dropped": "4",
      "processed": "17",
      "avgDropped": "4",
      "avgProcessed": "17"
    },
    {
      "time": "2019-10-15T03:00:00",
      "dropped": "4",
      "processed": "15",
      "avgDropped": "4",
      "avgProcessed": "15"
    },
    {
      "time": "2019-10-15T03:30:00",
      "dropped": "32",
      "processed": "24",
      "avgDropped": "32",
      "avgProcessed": "24"
    },
    {
      "time": "2019-10-15T04:00:00",
      "dropped": "8",
      "processed": "16",
      "avgDropped": "8",
      "avgProcessed": "16"
    },
    {
      "time": "2019-10-15T04:30:00",
      "dropped": "0",
      "processed": "16",
      "avgDropped": "0",
      "avgProcessed": "16"
    },
    {
      "time": "2019-10-15T05:00:00",
      "dropped": "4",
      "processed": "15",
      "avgDropped": "4",
      "avgProcessed": "15"
    },
    {
      "time": "2019-10-15T05:30:00",
      "dropped": "0",
      "processed": "16",
      "avgDropped": "0",
      "avgProcessed": "16"
    },
    {
      "time": "2019-10-15T06:00:00",
      "dropped": "4",
      "processed": "15",
      "avgDropped": "4",
      "avgProcessed": "15"
    }
  ],
  "TEMPLATE_15": [
    {
      "time": "2019-10-14T19:15:00",
      "dropped": "0",
      "processed": "7",
      "avgDropped": "0",
      "avgProcessed": "7"
    },
    {
      "time": "2019-10-14T19:30:00",
      "dropped": "32",
      "processed": "16",
      "avgDropped": "32",
      "avgProcessed": "16"
    },
    {
      "time": "2019-10-14T19:45:00",
      "dropped": "36",
      "processed": "17",
      "avgDropped": "36",
      "avgProcessed": "17"
    },
    {
      "time": "2019-10-14T20:00:00",
      "dropped": "48",
      "processed": "19",
      "avgDropped": "48",
      "avgProcessed": "19"
    },
    {
      "time": "2019-10-14T20:15:00",
      "dropped": "40",
      "processed": "17",
      "avgDropped": "40",
      "avgProcessed": "17"
    },
    {
      "time": "2019-10-14T20:30:00",
      "dropped": "4",
      "processed": "9",
      "avgDropped": "4",
      "avgProcessed": "9"
    },
    {
      "time": "2019-10-14T20:45:00",
      "dropped": "4",
      "processed": "9",
      "avgDropped": "4",
      "avgProcessed": "9"
    },
    {
      "time": "2019-10-14T21:00:00",
      "dropped": "0",
      "processed": "7",
      "avgDropped": "0",
      "avgProcessed": "7"
    },
    {
      "time": "2019-10-14T21:15:00",
      "dropped": "40",
      "processed": "17",
      "avgDropped": "40",
      "avgProcessed": "17"
    },
    {
      "time": "2019-10-14T21:30:00",
      "dropped": "20",
      "processed": "13",
      "avgDropped": "20",
      "avgProcessed": "13"
    },
    {
      "time": "2019-10-14T21:45:00",
      "dropped": "4",
      "processed": "9",
      "avgDropped": "4",
      "avgProcessed": "9"
    },
    {
      "time": "2019-10-14T22:00:00",
      "dropped": "0",
      "processed": "7",
      "avgDropped": "0",
      "avgProcessed": "7"
    },
    {
      "time": "2019-10-14T22:15:00",
      "dropped": "28",
      "processed": "14",
      "avgDropped": "28",
      "avgProcessed": "14"
    },
    {
      "time": "2019-10-14T22:30:00",
      "dropped": "8",
      "processed": "10",
      "avgDropped": "8",
      "avgProcessed": "10"
    },
    {
      "time": "2019-10-14T22:45:00",
      "dropped": "4",
      "processed": "9",
      "avgDropped": "4",
      "avgProcessed": "9"
    },
    {
      "time": "2019-10-14T23:00:00",
      "dropped": "0",
      "processed": "7",
      "avgDropped": "0",
      "avgProcessed": "7"
    },
    {
      "time": "2019-10-14T23:15:00",
      "dropped": "4",
      "processed": "8",
      "avgDropped": "4",
      "avgProcessed": "8"
    },
    {
      "time": "2019-10-14T23:30:00",
      "dropped": "0",
      "processed": "8",
      "avgDropped": "0",
      "avgProcessed": "8"
    },
    {
      "time": "2019-10-14T23:45:00",
      "dropped": "0",
      "processed": "8",
      "avgDropped": "0",
      "avgProcessed": "8"
    },
    {
      "time": "2019-10-15T00:00:00",
      "dropped": "0",
      "processed": "7",
      "avgDropped": "0",
      "avgProcessed": "7"
    },
    {
      "time": "2019-10-15T00:15:00",
      "dropped": "4",
      "processed": "8",
      "avgDropped": "4",
      "avgProcessed": "8"
    },
    {
      "time": "2019-10-15T00:30:00",
      "dropped": "0",
      "processed": "8",
      "avgDropped": "0",
      "avgProcessed": "8"
    },
    {
      "time": "2019-10-15T00:45:00",
      "dropped": "0",
      "processed": "8",
      "avgDropped": "0",
      "avgProcessed": "8"
    },
    {
      "time": "2019-10-15T01:00:00",
      "dropped": "0",
      "processed": "7",
      "avgDropped": "0",
      "avgProcessed": "7"
    },
    {
      "time": "2019-10-15T01:15:00",
      "dropped": "4",
      "processed": "8",
      "avgDropped": "4",
      "avgProcessed": "8"
    },
    {
      "time": "2019-10-15T01:30:00",
      "dropped": "0",
      "processed": "8",
      "avgDropped": "0",
      "avgProcessed": "8"
    },
    {
      "time": "2019-10-15T01:45:00",
      "dropped": "0",
      "processed": "8",
      "avgDropped": "0",
      "avgProcessed": "8"
    },
    {
      "time": "2019-10-15T02:00:00",
      "dropped": "52",
      "processed": "20",
      "avgDropped": "52",
      "avgProcessed": "20"
    },
    {
      "time": "2019-10-15T02:15:00",
      "dropped": "4",
      "processed": "8",
      "avgDropped": "4",
      "avgProcessed": "8"
    },
    {
      "time": "2019-10-15T02:30:00",
      "dropped": "4",
      "processed": "9",
      "avgDropped": "4",
      "avgProcessed": "9"
    },
    {
      "time": "2019-10-15T02:45:00",
      "dropped": "0",
      "processed": "8",
      "avgDropped": "0",
      "avgProcessed": "8"
    },
    {
      "time": "2019-10-15T03:00:00",
      "dropped": "4",
      "processed": "8",
      "avgDropped": "4",
      "avgProcessed": "8"
    },
    {
      "time": "2019-10-15T03:15:00",
      "dropped": "0",
      "processed": "7",
      "avgDropped": "0",
      "avgProcessed": "7"
    },
    {
      "time": "2019-10-15T03:30:00",
      "dropped": "28",
      "processed": "15",
      "avgDropped": "28",
      "avgProcessed": "15"
    },
    {
      "time": "2019-10-15T03:45:00",
      "dropped": "8",
      "processed": "10",
      "avgDropped": "8",
      "avgProcessed": "10"
    },
    {
      "time": "2019-10-15T04:00:00",
      "dropped": "0",
      "processed": "7",
      "avgDropped": "0",
      "avgProcessed": "7"
    },
    {
      "time": "2019-10-15T04:15:00",
      "dropped": "4",
      "processed": "8",
      "avgDropped": "4",
      "avgProcessed": "8"
    },
    {
      "time": "2019-10-15T04:30:00",
      "dropped": "0",
      "processed": "8",
      "avgDropped": "0",
      "avgProcessed": "8"
    },
    {
      "time": "2019-10-15T04:45:00",
      "dropped": "0",
      "processed": "8",
      "avgDropped": "0",
      "avgProcessed": "8"
    },
    {
      "time": "2019-10-15T05:00:00",
      "dropped": "0",
      "processed": "7",
      "avgDropped": "0",
      "avgProcessed": "7"
    },
    {
      "time": "2019-10-15T05:15:00",
      "dropped": "4",
      "processed": "8",
      "avgDropped": "4",
      "avgProcessed": "8"
    },
    {
      "time": "2019-10-15T05:30:00",
      "dropped": "0",
      "processed": "8",
      "avgDropped": "0",
      "avgProcessed": "8"
    },
    {
      "time": "2019-10-15T05:45:00",
      "dropped": "0",
      "processed": "8",
      "avgDropped": "0",
      "avgProcessed": "8"
    },
    {
      "time": "2019-10-15T06:00:00",
      "dropped": "0",
      "processed": "7",
      "avgDropped": "0",
      "avgProcessed": "7"
    },
    {
      "time": "2019-10-15T06:15:00",
      "dropped": "4",
      "processed": "8",
      "avgDropped": "4",
      "avgProcessed": "8"
    }
  ],
  "TEMPLATE_5": [
    {
      "time": "2019-10-14T18:40:00",
      "dropped": "0",
      "processed": "3",
      "avgDropped": "0",
      "avgProcessed": "3"
    },
    {
      "time": "2019-10-14T18:45:00",
      "dropped": "0",
      "processed": "3",
      "avgDropped": "0",
      "avgProcessed": "3"
    },
    {
      "time": "2019-10-14T18:50:00",
      "dropped": "4",
      "processed": "3",
      "avgDropped": "4",
      "avgProcessed": "3"
    },
    {
      "time": "2019-10-14T18:55:00",
      "dropped": "0",
      "processed": "2",
      "avgDropped": "0",
      "avgProcessed": "2"
    },
    {
      "time": "2019-10-14T19:00:00",
      "dropped": "0",
      "processed": "3",
      "avgDropped": "0",
      "avgProcessed": "3"
    },
    {
      "time": "2019-10-14T19:05:00",
      "dropped": "0",
      "processed": "3",
      "avgDropped": "0",
      "avgProcessed": "3"
    },
    {
      "time": "2019-10-14T19:10:00",
      "dropped": "0",
      "processed": "2",
      "avgDropped": "0",
      "avgProcessed": "2"
    }
  ]
});