define({

"OnBoarding":
{"tags":[{"tagType":"band","data":[{"tagName":"5 GHz","id":["5 GHz"],"count":85},{"tagName":"2.4 GHz","id":["2.4 GHz"],"count":57}]},{"tagType":"apName","data":[{"tagName":"AP1800S_Sensor_01","id":["08:4f:f9:2e:db:e0"],"count":53},{"tagName":"AP1800S_Sensor_02","id":["08:4f:f9:2f:95:00"],"count":46},{"tagName":"AP1800S_Sensor_03","id":["f4:db:e6:87:21:60"],"count":43},{"tagName":"AP1800S_Sensor_04","id":["f4:db:e6:86:f5:e0"],"count":12}]},{"tagType":"floor","data":[{"tagName":"Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1","id":["97b1a269-4ec2-472f-9e35-4247fccd9d8b"],"count":10}]},{"tagType":"ssid","data":[{"tagName":"@CorpSSID","id":["@CorpSSID"],"count":18},{"tagName":"c9800AP11AX","id":["@c9800AP11AX"],"count":6},{"tagName":"c9800AP11AC","id":["@c9800AP11AC"],"count":6}]},{"tagType":"errorCode","data":[{"tagName":"Deauthenticated because Sensor is leaving","id":["assoc_1064"],"count":7},{"tagName":"Undefined Error","id":["auth_1064"],"count":6}]},{"tagType":"server","data":[]}]},

"RF_ASSESMENT":
{"tags":[{"tagType":"band","data":[{"tagName":"2.4 GHz","id":["2.4 GHz"],"count":93},{"tagName":"5 GHz","id":["5 GHz"],"count":62}]},{"tagType":"apName","data":[{"tagName":"AP1800S_Sensor_01","id":["08:4f:f9:2e:db:e0"],"count":53},{"tagName":"AP1800S_Sensor_02","id":["08:4f:f9:2f:95:00"],"count":46},{"tagName":"AP1800S_Sensor_03","id":["f4:db:e6:87:21:60"],"count":43},{"tagName":"AP1800S_Sensor_04","id":["f4:db:e6:86:f5:e0"],"count":12}]},{"tagType":"floor","data":[{"tagName":"Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1","id":["3a8a1794-1c7a-4ad9-becc-6d3baa8228c3"],"count":18}]},{"tagType":"ssid","data":[{"tagName":"@CorpSSID","id":["@CorpSSID"],"count":18},{"tagName":"c9800AP11AX","id":["@c9800AP11AX"],"count":6},{"tagName":"c9800AP11AC","id":["@c9800AP11AC"],"count":6}]},{"tagType":"server","data":[]}]},

"NetworkSevices":
{"tags":[{"tagType":"band","data":[{"tagName":"2.4 GHz","id":["2.4 GHz"],"count":37},{"tagName":"5 GHz","id":["5 GHz"],"count":25}]},{"tagType":"apName","data":[{"tagName":"AP1800S_Sensor_01","id":["08:4f:f9:2e:db:e0"],"count":18},{"tagName":"AP1800S_Sensor_02","id":["08:4f:f9:2f:95:00"],"count":15},{"tagName":"AP1800S_Sensor_03","id":["f4:db:e6:87:21:60"],"count":14},{"tagName":"AP1800S_Sensor_04","id":["f4:db:e6:86:f5:e0"],"count":4}]},{"tagType":"floor","data":[{"tagName":"Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1","id":["3a8a1794-1c7a-4ad9-becc-6d3baa8228c3"],"count":28}]},{"tagType":"ssid","data":[{"tagName":"@CorpSSID","id":["@CorpSSID"],"count":8},{"tagName":"c9800AP11AX","id":["@c9800AP11AX"],"count":6},{"tagName":"c9800AP11AC","id":["@c9800AP11AC"],"count":5}]},{"tagType":"errorCode","data":[{"tagName":"RADIUS: Server Unreachable","id":["radius_13007"],"count":14},{"tagName":"Undefined Error","id":["dns_5003"],"count":13},{"tagName":"DNS: Name resolution failure","id":["dns_5002"],"count":12}]},{"tagType":"server","data":[{"tagName":"www.cisco.com","id":["www.cisco.com"],"count":2},{"tagName":"192.168.152.43","id":["192.168.152.43"],"count":2}]}]},

"Performances":
{"tags":[{"tagType":"band","data":[{"tagName":"2.4 GHz","id":["2.4 GHz"],"count":18},{"tagName":"5 GHz","id":["5 GHz"],"count":15}]},{"tagType":"apName","data":[{"tagName":"AP1800S_Sensor_01","id":["08:4f:f9:2e:db:e0"],"count":26},{"tagName":"AP1800S_Sensor_02","id":["08:4f:f9:2f:95:00"],"count":21},{"tagName":"AP1800S_Sensor_03","id":["f4:db:e6:87:21:60"],"count":20},{"tagName":"AP1800S_Sensor_04","id":["f4:db:e6:86:f5:e0"],"count":0}]},{"tagType":"floor","data":[{"tagName":"Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1","id":["3a8a1794-1c7a-4ad9-becc-6d3baa8228c3"],"count":34}]},{"tagType":"ssid","data":[{"tagName":"@CorpSSID","id":["@CorpSSID"],"count":8},{"tagName":"c9800AP11AX","id":["@c9800AP11AX"],"count":6},{"tagName":"c9800AP11AC","id":["@c9800AP11AC"],"count":7}]},{"tagType":"errorCode","data":[{"tagName":"No response from IP SLA responder AP","id":["ipslasender_11006"],"count":23}]},{"tagType":"server","data":[]}]},

"AppConnectivity":
{"tags":[{"tagType":"band","data":[{"tagName":"2.4 GHz","id":["2.4 GHz"],"count":36},{"tagName":"5 GHz","id":["5 GHz"],"count":24}]},{"tagType":"apName","data":[{"tagName":"AP1800S_Sensor_01","id":["08:4f:f9:2e:db:e0"],"count":44},{"tagName":"AP1800S_Sensor_02","id":["08:4f:f9:2f:95:00"],"count":38},{"tagName":"AP1800S_Sensor_03","id":["f4:db:e6:87:21:60"],"count":35},{"tagName":"AP1800S_Sensor_04","id":["f4:db:e6:86:f5:e0"],"count":10}]},{"tagType":"floor","data":[{"tagName":"Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1","id":["3a8a1794-1c7a-4ad9-becc-6d3baa8228c3"],"count":23}]},{"tagType":"ssid","data":[{"tagName":"@CorpSSID","id":["@CorpSSID"],"count":6},{"tagName":"c9800AP11AX","id":["@c9800AP11AX"],"count":1},{"tagName":"c9800AP11AC","id":["@c9800AP11AC"],"count":7}]},{"tagType":"errorCode","data":[{"tagName":"DNS Resolve Fail","id":["webserver_8004"],"count":23},{"tagName":"Ping: Target Address Unreachable","id":["ping_14002"],"count":25},{"tagName":"FTP: Server not reachable","id":["webserver_8002"],"count":2}]},{"tagType":"server","data":[{"tagName":"192.168.152.1","id":["192.168.152.1"],"count":1}]}]},

"Email":
{"tags":[{"tagType":"band","data":[{"tagName":"2.4 GHz","id":["2.4 GHz"],"count":36},{"tagName":"5 GHz","id":["5 GHz"],"count":24}]},{"tagType":"apName","data":[{"tagName":"AP1800S_Sensor_01","id":["08:4f:f9:2e:db:e0"],"count":17},{"tagName":"AP1800S_Sensor_02","id":["08:4f:f9:2f:95:00"],"count":15},{"tagName":"AP1800S_Sensor_03","id":["f4:db:e6:87:21:60"],"count":10},{"tagName":"AP1800S_Sensor_04","id":["f4:db:e6:86:f5:e0"],"count":4}]},{"tagType":"floor","data":[{"tagName":"Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1","id":["3a8a1794-1c7a-4ad9-becc-6d3baa8228c3"],"count":23}]},{"tagType":"ssid","data":[{"tagName":"@CorpSSID","id":["@CorpSSID"],"count":7},{"tagName":"c9800AP11AX","id":["@c9800AP11AX"],"count":6},{"tagName":"c9800AP11AC","id":["@c9800AP11AC"],"count":7}]},{"tagType":"errorCode","data":[{"tagName":"OWA: Connection Fail","id":["outlookserver_9006"],"count":5},{"tagName":"Exception Error","id":["mailserver_6005"],"count":2}]},{"tagType":"server","data":[{"tagName":"192.168.152.1","id":["192.168.152.1"],"count":1}]}]}

})