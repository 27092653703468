define({
    "DnsNetworkService_Template":
    {
        "version": "v2",
        "data": [
            {
                "values": {
                    "totalServerCount": 1,
                    "averageLatency": 12,
                    "failureTransactionCount": 18,
                    "insight": {
                        "totalServerCount": 1,
                        "averageLatency": 13,
                        "failureTransactionCount": 0,
                        "failureTransactionPercent": 0.0,
                        "averageLatencyPercent": -7.6923076923076929,
                        "totalLatency": 0.0,
                        "successTransactionCount": 29393,
                        "successTransactionPercent": 36.39982308712959,
                        "totalTransactionCount": 29393,
                        "totalTransactionPercent": 36.461062157656588
                    },
                    "successTransactionCount": 40092,
                    "totalTransactionCount": 40110
                }
            }
        ],
        "page": null,
        "scope": {
            "startTime": 1678877580000,
            "endTime": 1678963980000,
            "selectedTimeZone": null,
            "defaultTimeZone": null,
            "currentTime": 1678964630723,
            "timeApiTime": 1678963980000,
            "instrumentation": null,
            "query": {
                "fields": [
                    "totalTransactionCount",
                    "failureTransactionCount",
                    "successTransactionCount",
                    "totalServerCount",
                    "averageLatency"
                ],
                "filters": [
                    {
                        "key": "networkServiceType",
                        "value": "dns",
                        "operator": "eq"
                    },
                    {
                        "key": "insight",
                        "value": true,
                        "operator": "eq"
                    }
                ],
                "target": "networkService",
                "queryType": "summary",
                "page": {
                    "limit": 5,
                    "offset": 0,
                    "cursor": null,
                    "pages": 1,
                    "count": 5
                },
                "includeMetadata": false
            }
        },
        "instrumentation": null,
        "message": "success"
    },
    "TrendWindowSizeRoutersData":
    {
        "version": "v2",
        "data": [
            {
                "time": 1687692600000,
                "values": [
                    {
                        "count": 178,
                        "deviceFamily": "Switches and Hubs"
                    },
                    {
                        "count": 3,
                        "deviceFamily": "Wireless Controller"
                    },
                    {
                        "count": 180,
                        "deviceFamily": "Routers"
                    }
                ]
            }
        ],
        "page": null,
        "scope": {
            "startTime": 1687692600000,
            "endTime": 1687714200000,
            "selectedTimeZone": null,
            "defaultTimeZone": null,
            "currentTime": null,
            "timeApiTime": null,
            "instrumentation": null,
            "query": {
                "groupBy": [
                    "deviceFamily"
                ],
                "filters": [
                    {
                        "key": "trendWindowSize",
                        "value": "30m",
                        "operator": null
                    },
                    {
                        "key": "siteHierarchyGraphId",
                        "value": "/e206d6b6-43d9-482c-b737-e213d4218afc/",
                        "operator": "like"
                    },
                    {
                        "key": "deviceFamily",
                        "value": [
                            "Routers",
                            "Switches and Hubs",
                            "Wireless Controller"
                        ],
                        "operator": "in"
                    }
                ],
                "target": "events",
                "queryType": "trend",
                "page": {
                    "limit": null,
                    "offset": null,
                    "cursor": null,
                    "pages": null,
                    "count": null
                },
                "includeMetadata": false
            }
        },
        "instrumentation": null,
        "message": "Success"
    },
    "TrendWindowSizeAPData":
    {
        "version": "v2",
        "data": [
            {
                "time": 1687692600000,
                "values": [
                    {
                        "count": 5,
                        "deviceFamily": "Unified AP"
                    }
                ]
            }
        ],
        "page": null,
        "scope": {
            "startTime": 1687692600000,
            "endTime": 1687714200000,
            "selectedTimeZone": null,
            "defaultTimeZone": null,
            "currentTime": null,
            "timeApiTime": null,
            "instrumentation": null,
            "query": {
                "groupBy": [
                    "deviceFamily"
                ],
                "filters": [
                    {
                        "key": "trendWindowSize",
                        "value": "30m",
                        "operator": null
                    },
                    {
                        "key": "siteHierarchyGraphId",
                        "value": "/e206d6b6-43d9-482c-b737-e213d4218afc/",
                        "operator": "like"
                    },
                    {
                        "key": "deviceFamily",
                        "value": [
                            "Unified AP"
                        ],
                        "operator": "in"
                    }
                ],
                "target": "events",
                "queryType": "trend",
                "page": {
                    "limit": null,
                    "offset": null,
                    "cursor": null,
                    "pages": null,
                    "count": null
                },
                "includeMetadata": false
            }
        },
        "instrumentation": null,
        "message": "Success"
    },
    "TrendWindowSizeWiredClientData":
    {
        "version": "v2",
        "data": [
            {
                "time": 1687692600000,
                "values": [
                    {
                        "count": 260,
                        "deviceFamily": "Wired Client"
                    }
                ]
            }
        ],
        "page": null,
        "scope": {
            "startTime": 1687757400000,
            "endTime": 1687779000000,
            "selectedTimeZone": null,
            "defaultTimeZone": null,
            "currentTime": null,
            "timeApiTime": null,
            "instrumentation": null,
            "query": {
                "groupBy": [
                    "deviceFamily"
                ],
                "filters": [
                    {
                        "key": "trendWindowSize",
                        "value": "30m",
                        "operator": null
                    },
                    {
                        "key": "siteHierarchyGraphId",
                        "value": "/e206d6b6-43d9-482c-b737-e213d4218afc/",
                        "operator": "like"
                    },
                    {
                        "key": "deviceFamily",
                        "value": [
                            "Wired Client"
                        ],
                        "operator": "in"
                    }
                ],
                "target": "events",
                "queryType": "trend",
                "page": {
                    "limit": null,
                    "offset": null,
                    "cursor": null,
                    "pages": null,
                    "count": null
                },
                "includeMetadata": false
            }
        },
        "instrumentation": null,
        "message": "Success"
    },
    "TrendWindowSizeWirelessClientData":
    {
        "version": "v2",
        "data": [
            {
                "time": 1687692600000,
                "values": [
                    {
                        "count": 260,
                        "deviceFamily": "Wireless Client"
                    }
                ]
            }
        ],
        "page": null,
        "scope": {
            "startTime": 1687692600000,
            "endTime": 1687714200000,
            "selectedTimeZone": null,
            "defaultTimeZone": null,
            "currentTime": null,
            "timeApiTime": null,
            "instrumentation": null,
            "query": {
                "groupBy": [
                    "deviceFamily"
                ],
                "filters": [
                    {
                        "key": "trendWindowSize",
                        "value": "30m",
                        "operator": null
                    },
                    {
                        "key": "siteHierarchyGraphId",
                        "value": "/e206d6b6-43d9-482c-b737-e213d4218afc/",
                        "operator": "like"
                    },
                    {
                        "key": "deviceFamily",
                        "value": [
                            "Wireless Client"
                        ],
                        "operator": "in"
                    }
                ],
                "target": "events",
                "queryType": "trend",
                "page": {
                    "limit": null,
                    "offset": null,
                    "cursor": null,
                    "pages": null,
                    "count": null
                },
                "includeMetadata": false
            }
        },
        "instrumentation": null,
        "message": "Success"
    },
    "DeviceFamilyData": 
    {
        "version": "v2",
        "data": [
            {
                "time": null,
                "values": [
                    {
                        "count": 3941,
                        "deviceFamily": "Switches and Hubs"
                    },{
                        "count": 69,
                        "deviceFamily": "Wireless Controller"
                    },{
                        "count": 69,
                        "deviceFamily": "Unified AP"
                    },{
                        "count": 69,
                        "deviceFamily": "Routers"
                    },{
                        "count": 0,
                        "deviceFamily": "Wired Client"
                    },{
                        "count": 69,
                        "deviceFamily": "Wireless Client"
                    }
                ]
            }
        ],
        "page": null,
        "scope": {
            "startTime": 1687251978600,
            "endTime": 1687295178600,
            "selectedTimeZone": null,
            "defaultTimeZone": null,
            "currentTime": null,
            "timeApiTime": null,
            "instrumentation": null,
            "query": {
                "groupBy": [
                    "deviceFamily"
                ],
                "filters": [
                    {
                        "key": "siteHierarchyGraphId",
                        "value": "/e206d6b6-43d9-482c-b737-e213d4218afc/",
                        "operator": "like"
                    },
                    {
                        "key": "deviceFamily",
                        "value": [
                            "Routers",
                            "Switches and Hubs",
                            "Wireless Controller"
                        ],
                        "operator": "in"
                    }
                ],
                "target": "events",
                "queryType": "trend",
                "page": {
                    "limit": null,
                    "offset": null,
                    "cursor": null,
                    "pages": null,
                    "count": null
                },
                "includeMetadata": false
            }
        },
        "instrumentation": null,
        "message": "Success"
    },
    "EventDataAPTemplate":
    {
        "version": "v2",
        "data": [
            {
                "id": "UzokDIkBsYPaEvt5fDnB",
                "values": {
                    "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                    "apMac": "4C:A6:4D:23:33:A0",
                    "managementIpAddress": "10.14.70.63",
                    "deviceFamily": "Unified AP",
                    "type": "ap_rrm_event_v2",
                    "additionalDetails": {
                        "WLC UUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                        "Color Level": "2",
                        "EventType": "Tx Power Change",
                        "Current Power Level": "26 dBm",
                        "Previous Power Level": "23 dBm",
                        "additionalInfo": "Radio Slot : 1 (5.0GHz) | Power: 23 dBm -> 26 dBm | System Driven",
                        "Frequency": "5.0GHz",
                        "Radio": "1",
                        "Wlc Type": "2"
                    },
                    "deviceName": "AP3C57.31C5.9CC4",
                    "frequency": "5.0GHz",
                    "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                    "messageType": "Device Event",
                    "switchName": "B18-live-C9200.wireless-tme.com",
                    "eventTime": 1688125819063,
                    "eventName": "Tx Power Change",
                    "location": "Global/San Jose/Building 18/Floor1",
                    "details": "System Driven : Tx Power change due to OpenRRM.",
                    "deviceUUID": "f1a99f49-2cea-444f-85a7-a8c869d54646"
                }
            },
            {
                "id": "UjokDIkBsYPaEvt5fDnB",
                "values": {
                    "switchUUID": "07f7aa01-d737-41f2-89e7-e11a8ab1b3de",
                    "apMac": "10:F9:20:FD:65:A0",
                    "managementIpAddress": "10.14.70.59",
                    "deviceFamily": "Unified AP",
                    "type": "ap_rrm_event_v2",
                    "additionalDetails": {
                        "WLC UUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                        "Color Level": "2",
                        "EventType": "Tx Power Change",
                        "Current Power Level": "17 dBm",
                        "Previous Power Level": "20 dBm",
                        "additionalInfo": "Radio Slot : 0 (2.4GHz) | Power: 20 dBm -> 17 dBm | System Driven",
                        "Frequency": "2.4GHz",
                        "Radio": "0",
                        "Wlc Type": "2"
                    },
                    "deviceName": "SJC14-F1-9166-2",
                    "frequency": "2.4GHz",
                    "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/d927416c-fc2f-4344-bb6c-cd07c658c665/",
                    "messageType": "Device Event",
                    "switchName": "SJC14F1-WTME-C9K-48UXM.wireless-tme.com",
                    "eventTime": 1688125816865,
                    "eventName": "Tx Power Change",
                    "location": "Global/San Jose/Building 14/Floor1",
                    "details": "System Driven : Tx Power change due to OpenRRM.",
                    "deviceUUID": "79b9e9fd-08b9-4886-b3dd-981ed4f9f1b2"
                }
            },
            {
                "id": "UTokDIkBsYPaEvt5fDnB",
                "values": {
                    "switchUUID": "07f7aa01-d737-41f2-89e7-e11a8ab1b3de",
                    "apMac": "10:F9:20:FD:68:80",
                    "managementIpAddress": "10.14.70.66",
                    "deviceFamily": "Unified AP",
                    "type": "ap_rrm_event_v2",
                    "additionalDetails": {
                        "WLC UUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                        "Color Level": "2",
                        "EventType": "Tx Power Change",
                        "Current Power Level": "15 dBm",
                        "Previous Power Level": "18 dBm",
                        "additionalInfo": "Radio Slot : 0 (2.4GHz) | Power: 18 dBm -> 15 dBm | System Driven",
                        "Frequency": "2.4GHz",
                        "Radio": "0",
                        "Wlc Type": "2"
                    },
                    "deviceName": "SJC14-F1-9166-1",
                    "frequency": "2.4GHz",
                    "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/d927416c-fc2f-4344-bb6c-cd07c658c665/",
                    "messageType": "Device Event",
                    "switchName": "SJC14F1-WTME-C9K-48UXM.wireless-tme.com",
                    "eventTime": 1688125816213,
                    "eventName": "Tx Power Change",
                    "location": "Global/San Jose/Building 14/Floor1",
                    "details": "System Driven : Tx Power change due to OpenRRM.",
                    "deviceUUID": "f679ea98-ff04-4eee-9cff-5b8b7675bdf5"
                }
            },
            {
                "id": "UDokDIkBsYPaEvt5fDnB",
                "values": {
                    "switchUUID": "07f7aa01-d737-41f2-89e7-e11a8ab1b3de",
                    "apMac": "6C:8D:77:2E:04:20",
                    "managementIpAddress": "10.14.70.62",
                    "deviceFamily": "Unified AP",
                    "type": "ap_rrm_event_v2",
                    "additionalDetails": {
                        "WLC UUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                        "Color Level": "2",
                        "EventType": "Tx Power Change",
                        "Current Power Level": "11 dBm",
                        "Previous Power Level": "14 dBm",
                        "additionalInfo": "Radio Slot : 0 (2.4GHz) | Power: 14 dBm -> 11 dBm | System Driven",
                        "Frequency": "2.4GHz",
                        "Radio": "0",
                        "Wlc Type": "2"
                    },
                    "deviceName": "SJC14-F1-9164-3",
                    "frequency": "2.4GHz",
                    "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/d927416c-fc2f-4344-bb6c-cd07c658c665/",
                    "messageType": "Device Event",
                    "switchName": "SJC14F1-WTME-C9K-48UXM.wireless-tme.com",
                    "eventTime": 1688125816112,
                    "eventName": "Tx Power Change",
                    "location": "Global/San Jose/Building 14/Floor1",
                    "details": "System Driven : Tx Power change due to OpenRRM.",
                    "deviceUUID": "9bbe389a-8936-4e6c-8aa9-d8d7bf0cc210"
                }
            },
            {
                "id": "HDkJDIkBsYPaEvt5Bcx2",
                "values": {
                    "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                    "apMac": "4C:A6:4D:23:33:A0",
                    "managementIpAddress": "10.14.70.63",
                    "deviceFamily": "Unified AP",
                    "type": "ap_rrm_event_v2",
                    "additionalDetails": {
                        "WLC UUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                        "Color Level": "2",
                        "EventType": "Tx Power Change",
                        "Current Power Level": "23 dBm",
                        "Previous Power Level": "20 dBm",
                        "additionalInfo": "Radio Slot : 1 (5.0GHz) | Power: 20 dBm -> 23 dBm | System Driven",
                        "Frequency": "5.0GHz",
                        "Radio": "1",
                        "Wlc Type": "2"
                    },
                    "deviceName": "AP3C57.31C5.9CC4",
                    "frequency": "5.0GHz",
                    "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                    "messageType": "Device Event",
                    "switchName": "B18-live-C9200.wireless-tme.com",
                    "eventTime": 1688124029971,
                    "eventName": "Tx Power Change",
                    "location": "Global/San Jose/Building 18/Floor1",
                    "details": "System Driven : Tx Power change due to OpenRRM.",
                    "deviceUUID": "f1a99f49-2cea-444f-85a7-a8c869d54646"
                }
            },
            {
                "id": "GzkJDIkBsYPaEvt5Bcx2",
                "values": {
                    "switchUUID": "07f7aa01-d737-41f2-89e7-e11a8ab1b3de",
                    "apMac": "10:F9:20:FD:68:80",
                    "managementIpAddress": "10.14.70.66",
                    "deviceFamily": "Unified AP",
                    "type": "ap_rrm_event_v2",
                    "additionalDetails": {
                        "WLC UUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                        "Color Level": "2",
                        "EventType": "Tx Power Change",
                        "Current Power Level": "18 dBm",
                        "Previous Power Level": "15 dBm",
                        "additionalInfo": "Radio Slot : 0 (2.4GHz) | Power: 15 dBm -> 18 dBm | System Driven",
                        "Frequency": "2.4GHz",
                        "Radio": "0",
                        "Wlc Type": "2"
                    },
                    "deviceName": "SJC14-F1-9166-1",
                    "frequency": "2.4GHz",
                    "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/d927416c-fc2f-4344-bb6c-cd07c658c665/",
                    "messageType": "Device Event",
                    "switchName": "SJC14F1-WTME-C9K-48UXM.wireless-tme.com",
                    "eventTime": 1688124027582,
                    "eventName": "Tx Power Change",
                    "location": "Global/San Jose/Building 14/Floor1",
                    "details": "System Driven : Tx Power change due to OpenRRM.",
                    "deviceUUID": "f679ea98-ff04-4eee-9cff-5b8b7675bdf5"
                }
            },
            {
                "id": "GDkJDIkBsYPaEvt5Bcx2",
                "values": {
                    "switchUUID": "07f7aa01-d737-41f2-89e7-e11a8ab1b3de",
                    "apMac": "74:11:B2:D8:57:20",
                    "managementIpAddress": "10.14.70.64",
                    "deviceFamily": "Unified AP",
                    "type": "ap_rrm_event_v2",
                    "additionalDetails": {
                        "WLC UUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                        "Color Level": "2",
                        "EventType": "Tx Power Change",
                        "Current Power Level": "14 dBm",
                        "Previous Power Level": "11 dBm",
                        "additionalInfo": "Radio Slot : 0 (2.4GHz) | Power: 11 dBm -> 14 dBm | System Driven",
                        "Frequency": "2.4GHz",
                        "Radio": "0",
                        "Wlc Type": "2"
                    },
                    "deviceName": "SJC14-F1-9164-5",
                    "frequency": "2.4GHz",
                    "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/d927416c-fc2f-4344-bb6c-cd07c658c665/",
                    "messageType": "Device Event",
                    "switchName": "SJC14F1-WTME-C9K-48UXM.wireless-tme.com",
                    "eventTime": 1688124027290,
                    "eventName": "Tx Power Change",
                    "location": "Global/San Jose/Building 14/Floor1",
                    "details": "System Driven : Tx Power change due to OpenRRM.",
                    "deviceUUID": "a78b29e3-6286-4270-9cde-ed1a13eadc6c"
                }
            },
            {
                "id": "GjkJDIkBsYPaEvt5Bcx2",
                "values": {
                    "switchUUID": "07f7aa01-d737-41f2-89e7-e11a8ab1b3de",
                    "apMac": "6C:8D:77:2E:04:20",
                    "managementIpAddress": "10.14.70.62",
                    "deviceFamily": "Unified AP",
                    "type": "ap_rrm_event_v2",
                    "additionalDetails": {
                        "WLC UUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                        "Color Level": "2",
                        "EventType": "Tx Power Change",
                        "Current Power Level": "14 dBm",
                        "Previous Power Level": "11 dBm",
                        "additionalInfo": "Radio Slot : 0 (2.4GHz) | Power: 11 dBm -> 14 dBm | System Driven",
                        "Frequency": "2.4GHz",
                        "Radio": "0",
                        "Wlc Type": "2"
                    },
                    "deviceName": "SJC14-F1-9164-3",
                    "frequency": "2.4GHz",
                    "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/d927416c-fc2f-4344-bb6c-cd07c658c665/",
                    "messageType": "Device Event",
                    "switchName": "SJC14F1-WTME-C9K-48UXM.wireless-tme.com",
                    "eventTime": 1688124027186,
                    "eventName": "Tx Power Change",
                    "location": "Global/San Jose/Building 14/Floor1",
                    "details": "System Driven : Tx Power change due to OpenRRM.",
                    "deviceUUID": "9bbe389a-8936-4e6c-8aa9-d8d7bf0cc210"
                }
            },
            {
                "id": "GTkJDIkBsYPaEvt5Bcx2",
                "values": {
                    "switchUUID": "07f7aa01-d737-41f2-89e7-e11a8ab1b3de",
                    "apMac": "10:F9:20:FD:65:A0",
                    "managementIpAddress": "10.14.70.59",
                    "deviceFamily": "Unified AP",
                    "type": "ap_rrm_event_v2",
                    "additionalDetails": {
                        "WLC UUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                        "Color Level": "2",
                        "EventType": "Tx Power Change",
                        "Current Power Level": "20 dBm",
                        "Previous Power Level": "17 dBm",
                        "additionalInfo": "Radio Slot : 0 (2.4GHz) | Power: 17 dBm -> 20 dBm | System Driven",
                        "Frequency": "2.4GHz",
                        "Radio": "0",
                        "Wlc Type": "2"
                    },
                    "deviceName": "SJC14-F1-9166-2",
                    "frequency": "2.4GHz",
                    "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/d927416c-fc2f-4344-bb6c-cd07c658c665/",
                    "messageType": "Device Event",
                    "switchName": "SJC14F1-WTME-C9K-48UXM.wireless-tme.com",
                    "eventTime": 1688124026959,
                    "eventName": "Tx Power Change",
                    "location": "Global/San Jose/Building 14/Floor1",
                    "details": "System Driven : Tx Power change due to OpenRRM.",
                    "deviceUUID": "79b9e9fd-08b9-4886-b3dd-981ed4f9f1b2"
                }
            },
            {
                "id": "8TntC4kBsYPaEvt5jmBY",
                "values": {
                    "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                    "apMac": "4C:A6:4D:23:33:A0",
                    "managementIpAddress": "10.14.70.63",
                    "deviceFamily": "Unified AP",
                    "type": "ap_rrm_event_v2",
                    "additionalDetails": {
                        "WLC UUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                        "Color Level": "2",
                        "EventType": "Tx Power Change",
                        "Current Power Level": "20 dBm",
                        "Previous Power Level": "17 dBm",
                        "additionalInfo": "Radio Slot : 1 (5.0GHz) | Power: 17 dBm -> 20 dBm | System Driven",
                        "Frequency": "5.0GHz",
                        "Radio": "1",
                        "Wlc Type": "2"
                    },
                    "deviceName": "AP3C57.31C5.9CC4",
                    "frequency": "5.0GHz",
                    "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                    "messageType": "Device Event",
                    "switchName": "B18-live-C9200.wireless-tme.com",
                    "eventTime": 1688122222216,
                    "eventName": "Tx Power Change",
                    "location": "Global/San Jose/Building 18/Floor1",
                    "details": "System Driven : Tx Power change due to OpenRRM.",
                    "deviceUUID": "f1a99f49-2cea-444f-85a7-a8c869d54646"
                }
            }
        ],
        "page": {
            "limit": 10,
            "offset": 1,
            "cursor": null,
            "pages": 30,
            "count": 295
        },
        "scope": {
            "startTime": 1688040713980,
            "endTime": 1688127113980,
            "selectedTimeZone": null,
            "defaultTimeZone": null,
            "currentTime": null,
            "timeApiTime": null,
            "instrumentation": null,
            "query": {
                "filters": [
                    {
                        "key": "deviceFamily",
                        "value": [
                            "Unified AP"
                        ],
                        "operator": "in"
                    },
                    {
                        "key": "siteHierarchyGraphId",
                        "value": "/e206d6b6-43d9-482c-b737-e213d4218afc/",
                        "operator": "like"
                    }
                ],
                "target": "events",
                "queryType": "summary",
                "page": {
                    "limit": 10,
                    "offset": 0,
                    "cursor": null,
                    "pages": 0,
                    "count": 0,
                    "orderBy": [
                        {
                            "name": "eventTime",
                            "order": "desc"
                        }
                    ]
                },
                "includeMetadata": false
            }
        },
        "instrumentation": null,
        "message": "Success"
    },
    "EventDataRoutersTemplate":
    {
        "version": "v2",
        "data": [
            {
                "id": "FQsFZYgBsYPaEvt5DWf3",
                "values": {
                    "severity": 0,
                    "managementIpAddress": "",
                    "deviceFamily": "",
                    "type": "syslog_event_v2",
                    "additionalDetails": {
                        "Color Level": "1",
                        "Mnemonic": "",
                        "Facility": ""
                    },
                    "deviceName": "",
                    "siteHierarchyGraphId": "",
                    "messageType": "Device Controller",
                    "eventTime": 1685322005008,
                    "eventName": "DEVICE_AVAILABILITY",
                    "details": "",
                    "location": "",
                    "deviceUUID": ""
                }
            }
        ],
        "page": {
            "limit": 1,
            "offset": 1,
            "cursor": null,
            "pages": 10000,
            "count": 10000
        },
        "scope": {
            "startTime": null,
            "endTime": null,
            "selectedTimeZone": null,
            "defaultTimeZone": null,
            "currentTime": null,
            "timeApiTime": null,
            "instrumentation": null,
            "query": {
                "fields": [
                    "eventTime"
                ],
                "filters": [
                    {
                        "key": "deviceFamily",
                        "value": [
                            "Routers",
                            "Switches and Hubs",
                            "Wireless Controller"
                        ],
                        "operator": "in"
                    }
                ],
                "target": "events",
                "queryType": "summary",
                "page": {
                    "limit": 1,
                    "offset": 0,
                    "cursor": null,
                    "pages": 0,
                    "count": 0,
                    "orderBy": [
                        {
                            "name": "eventTime",
                            "order": "asc"
                        }
                    ]
                },
                "includeMetadata": false
            }
        },
        "instrumentation": null,
        "message": "Success"
    },
    "EventDeviceFamilyWLCDataTemplate":
    {
        "version": "v2",
        "data": [
            {
                "id": "qMd684gBsYPaEvt5Z5YB",
                "values": {
                    "severity": 4,
                    "managementIpAddress": "10.14.70.4",
                    "deviceFamily": "Wireless Controller",
                    "type": "syslog_event_v2",
                    "additionalDetails": {
                        "Color Level": "3",
                        "Mnemonic": "REACHABLE",
                        "Facility": "DEVICE_AVAILABILITY"
                    },
                    "deviceName": "LIVE-C9800-40-SSO.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/",
                    "messageType": "Device Event",
                    "eventTime": 1687712063109,
                    "eventName": "DEVICE_AVAILABILITY:REACHABLE",
                    "details": "DEVICE IS REACHABLE",
                    "location": "Global/San Jose/Building 14",
                    "deviceUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8"
                }
            },
            {
                "id": "rcdw84gBsYPaEvt58GJu",
                "values": {
                    "severity": 4,
                    "managementIpAddress": "10.14.70.4",
                    "deviceFamily": "Wireless Controller",
                    "type": "syslog_event_v2",
                    "additionalDetails": {
                        "Color Level": "3",
                        "Mnemonic": "REACHABLE",
                        "Facility": "DEVICE_AVAILABILITY"
                    },
                    "deviceName": "LIVE-C9800-40-SSO.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/",
                    "messageType": "Device Event",
                    "eventTime": 1687711441720,
                    "eventName": "DEVICE_AVAILABILITY:REACHABLE",
                    "details": "DEVICE IS REACHABLE",
                    "location": "Global/San Jose/Building 14",
                    "deviceUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8"
                }
            },
            {
                "id": "Ycdn84gBsYPaEvt5ei90",
                "values": {
                    "severity": 4,
                    "managementIpAddress": "10.14.70.4",
                    "deviceFamily": "Wireless Controller",
                    "type": "syslog_event_v2",
                    "additionalDetails": {
                        "Color Level": "3",
                        "Mnemonic": "REACHABLE",
                        "Facility": "DEVICE_AVAILABILITY"
                    },
                    "deviceName": "LIVE-C9800-40-SSO.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/",
                    "messageType": "Device Event",
                    "eventTime": 1687710821025,
                    "eventName": "DEVICE_AVAILABILITY:REACHABLE",
                    "details": "DEVICE IS REACHABLE",
                    "location": "Global/San Jose/Building 14",
                    "deviceUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8"
                }
            },
            {
                "id": "QcZd84gBsYPaEvt53fzm",
                "values": {
                    "severity": 4,
                    "managementIpAddress": "10.14.70.4",
                    "deviceFamily": "Wireless Controller",
                    "type": "syslog_event_v2",
                    "additionalDetails": {
                        "Color Level": "3",
                        "Mnemonic": "REACHABLE",
                        "Facility": "DEVICE_AVAILABILITY"
                    },
                    "deviceName": "LIVE-C9800-40-SSO.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/",
                    "messageType": "Device Event",
                    "eventTime": 1687710190049,
                    "eventName": "DEVICE_AVAILABILITY:REACHABLE",
                    "details": "DEVICE IS REACHABLE",
                    "location": "Global/San Jose/Building 14",
                    "deviceUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8"
                }
            },
            {
                "id": "xcZU84gBsYPaEvt5VMSc",
                "values": {
                    "severity": 4,
                    "managementIpAddress": "10.14.70.4",
                    "deviceFamily": "Wireless Controller",
                    "type": "syslog_event_v2",
                    "additionalDetails": {
                        "Color Level": "3",
                        "Mnemonic": "REACHABLE",
                        "Facility": "DEVICE_AVAILABILITY"
                    },
                    "deviceName": "LIVE-C9800-40-SSO.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/",
                    "messageType": "Device Event",
                    "eventTime": 1687709569009,
                    "eventName": "DEVICE_AVAILABILITY:REACHABLE",
                    "details": "DEVICE IS REACHABLE",
                    "location": "Global/San Jose/Building 14",
                    "deviceUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8"
                }
            },
            {
                "id": "e8ZK84gBsYPaEvt53ozz",
                "values": {
                    "severity": 4,
                    "managementIpAddress": "10.14.70.4",
                    "deviceFamily": "Wireless Controller",
                    "type": "syslog_event_v2",
                    "additionalDetails": {
                        "Color Level": "3",
                        "Mnemonic": "REACHABLE",
                        "Facility": "DEVICE_AVAILABILITY"
                    },
                    "deviceName": "LIVE-C9800-40-SSO.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/",
                    "messageType": "Device Event",
                    "eventTime": 1687708947762,
                    "eventName": "DEVICE_AVAILABILITY:REACHABLE",
                    "details": "DEVICE IS REACHABLE",
                    "location": "Global/San Jose/Building 14",
                    "deviceUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8"
                }
            },
            {
                "id": "9MZB84gBsYPaEvt5aGt7",
                "values": {
                    "severity": 4,
                    "managementIpAddress": "10.14.70.4",
                    "deviceFamily": "Wireless Controller",
                    "type": "syslog_event_v2",
                    "additionalDetails": {
                        "Color Level": "3",
                        "Mnemonic": "REACHABLE",
                        "Facility": "DEVICE_AVAILABILITY"
                    },
                    "deviceName": "LIVE-C9800-40-SSO.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/",
                    "messageType": "Device Event",
                    "eventTime": 1687708326311,
                    "eventName": "DEVICE_AVAILABILITY:REACHABLE",
                    "details": "DEVICE IS REACHABLE",
                    "location": "Global/San Jose/Building 14",
                    "deviceUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8"
                }
            },
            {
                "id": "pMY384gBsYPaEvt5zFAr",
                "values": {
                    "severity": 4,
                    "managementIpAddress": "10.14.70.4",
                    "deviceFamily": "Wireless Controller",
                    "type": "syslog_event_v2",
                    "additionalDetails": {
                        "Color Level": "3",
                        "Mnemonic": "REACHABLE",
                        "Facility": "DEVICE_AVAILABILITY"
                    },
                    "deviceName": "LIVE-C9800-40-SSO.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/",
                    "messageType": "Device Event",
                    "eventTime": 1687707695159,
                    "eventName": "DEVICE_AVAILABILITY:REACHABLE",
                    "details": "DEVICE IS REACHABLE",
                    "location": "Global/San Jose/Building 14",
                    "deviceUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8"
                }
            },
            {
                "id": "mMYu84gBsYPaEvt5QjOe",
                "values": {
                    "severity": 4,
                    "managementIpAddress": "10.14.70.4",
                    "deviceFamily": "Wireless Controller",
                    "type": "syslog_event_v2",
                    "additionalDetails": {
                        "Color Level": "3",
                        "Mnemonic": "REACHABLE",
                        "Facility": "DEVICE_AVAILABILITY"
                    },
                    "deviceName": "LIVE-C9800-40-SSO.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/",
                    "messageType": "Device Event",
                    "eventTime": 1687707073666,
                    "eventName": "DEVICE_AVAILABILITY:REACHABLE",
                    "details": "DEVICE IS REACHABLE",
                    "location": "Global/San Jose/Building 14",
                    "deviceUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8"
                }
            },
            {
                "id": "zcYk84gBsYPaEvt5zBg1",
                "values": {
                    "severity": 4,
                    "managementIpAddress": "10.14.70.4",
                    "deviceFamily": "Wireless Controller",
                    "type": "syslog_event_v2",
                    "additionalDetails": {
                        "Color Level": "3",
                        "Mnemonic": "REACHABLE",
                        "Facility": "DEVICE_AVAILABILITY"
                    },
                    "deviceName": "LIVE-C9800-40-SSO.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/",
                    "messageType": "Device Event",
                    "eventTime": 1687706452976,
                    "eventName": "DEVICE_AVAILABILITY:REACHABLE",
                    "details": "DEVICE IS REACHABLE",
                    "location": "Global/San Jose/Building 14",
                    "deviceUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8"
                }
            }
        ],
        "page": {
            "limit": 10,
            "offset": 1,
            "cursor": null,
            "pages": 14,
            "count": 138
        },
        "scope": {
            "startTime": 1687626210222,
            "endTime": 1687712610222,
            "selectedTimeZone": null,
            "defaultTimeZone": null,
            "currentTime": null,
            "timeApiTime": null,
            "instrumentation": null,
            "query": {
                "filters": [
                    {
                        "key": "deviceFamily",
                        "value": [
                            "Wireless Controller"
                        ],
                        "operator": "in"
                    },
                    {
                        "key": "siteHierarchyGraphId",
                        "value": "/e206d6b6-43d9-482c-b737-e213d4218afc/",
                        "operator": "like"
                    }
                ],
                "target": "events",
                "queryType": "summary",
                "page": {
                    "limit": 10,
                    "offset": 0,
                    "cursor": null,
                    "pages": 0,
                    "count": 0,
                    "orderBy": [
                        {
                            "name": "eventTime",
                            "order": "desc"
                        }
                    ]
                },
                "includeMetadata": false
            }
        },
        "instrumentation": null,
        "message": "Success"
    },
    "EventDeviceFamilySwitchandHubsData":
    {
        "version": "v2",
        "data": [
            {
                "id": "fciR84gBsYPaEvt55hQl",
                "values": {
                    "severity": 3,
                    "managementIpAddress": "10.14.70.3",
                    "deviceFamily": "Switches and Hubs",
                    "type": "syslog_event_v2",
                    "additionalDetails": {
                        "Color Level": "2",
                        "Mnemonic": "UPDOWN",
                        "Facility": "LINK"
                    },
                    "deviceName": "B18-live-C9200.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                    "messageType": "Syslog",
                    "eventTime": 1687713596945,
                    "eventName": "LINK:UPDOWN",
                    "details": "2011191: 2011184: *Jun 25 17:22:51.645:  Interface GigabitEthernet1/0/25, changed state to down",
                    "location": "Global/San Jose/Building 18/Floor1",
                    "deviceUUID": "3e360557-3863-4cfc-b501-10018bba7a5e"
                }
            },
            {
                "id": "gMiR84gBsYPaEvt55hQl",
                "values": {
                    "severity": 5,
                    "managementIpAddress": "10.14.70.3",
                    "deviceFamily": "Switches and Hubs",
                    "type": "syslog_event_v2",
                    "additionalDetails": {
                        "Color Level": "2",
                        "Mnemonic": "UPDOWN",
                        "Facility": "LINEPROTO"
                    },
                    "deviceName": "B18-live-C9200.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                    "messageType": "Syslog",
                    "eventTime": 1687713595921,
                    "eventName": "LINEPROTO:UPDOWN",
                    "details": "2011190: 2011183: *Jun 25 17:22:50.640:  Line protocol on Interface GigabitEthernet1/0/25, changed state to down",
                    "location": "Global/San Jose/Building 18/Floor1",
                    "deviceUUID": "3e360557-3863-4cfc-b501-10018bba7a5e"
                }
            },
            {
                "id": "fMiR84gBsYPaEvt50hQ3",
                "values": {
                    "severity": 3,
                    "managementIpAddress": "10.14.70.3",
                    "deviceFamily": "Switches and Hubs",
                    "type": "syslog_event_v2",
                    "additionalDetails": {
                        "Color Level": "2",
                        "Mnemonic": "DOWN",
                        "Facility": "LINK"
                    },
                    "deviceName": "B18-live-C9200.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                    "messageType": "Trap",
                    "eventTime": 1687713595162,
                    "eventName": "LINK:DOWN",
                    "details": "GigabitEthernet1/0/25 LINK DOWN",
                    "location": "Global/San Jose/Building 18/Floor1",
                    "deviceUUID": "3e360557-3863-4cfc-b501-10018bba7a5e"
                }
            },
            {
                "id": "1siR84gBsYPaEvt5NRHP",
                "values": {
                    "severity": 5,
                    "managementIpAddress": "10.14.70.3",
                    "deviceFamily": "Switches and Hubs",
                    "type": "syslog_event_v2",
                    "additionalDetails": {
                        "Color Level": "3",
                        "Mnemonic": "UP",
                        "Facility": "LINK"
                    },
                    "deviceName": "B18-live-C9200.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                    "messageType": "Trap",
                    "eventTime": 1687713556799,
                    "eventName": "LINK:UP",
                    "details": "GigabitEthernet1/0/25 LINK UP",
                    "location": "Global/San Jose/Building 18/Floor1",
                    "deviceUUID": "3e360557-3863-4cfc-b501-10018bba7a5e"
                }
            },
            {
                "id": "f8iR84gBsYPaEvt55hQl",
                "values": {
                    "severity": 5,
                    "managementIpAddress": "10.14.70.3",
                    "deviceFamily": "Switches and Hubs",
                    "type": "syslog_event_v2",
                    "additionalDetails": {
                        "Color Level": "2",
                        "Mnemonic": "UPDOWN",
                        "Facility": "LINEPROTO"
                    },
                    "deviceName": "B18-live-C9200.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                    "messageType": "Syslog",
                    "eventTime": 1687713556603,
                    "eventName": "LINEPROTO:UPDOWN",
                    "details": "2011189: 2011182: *Jun 25 17:22:12.277:  Line protocol on Interface GigabitEthernet1/0/25, changed state to up",
                    "location": "Global/San Jose/Building 18/Floor1",
                    "deviceUUID": "3e360557-3863-4cfc-b501-10018bba7a5e"
                }
            },
            {
                "id": "fsiR84gBsYPaEvt55hQl",
                "values": {
                    "severity": 3,
                    "managementIpAddress": "10.14.70.3",
                    "deviceFamily": "Switches and Hubs",
                    "type": "syslog_event_v2",
                    "additionalDetails": {
                        "Color Level": "2",
                        "Mnemonic": "UPDOWN",
                        "Facility": "LINK"
                    },
                    "deviceName": "B18-live-C9200.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                    "messageType": "Syslog",
                    "eventTime": 1687713556602,
                    "eventName": "LINK:UPDOWN",
                    "details": "2011188: 2011181: *Jun 25 17:22:11.277:  Interface GigabitEthernet1/0/25, changed state to up",
                    "location": "Global/San Jose/Building 18/Floor1",
                    "deviceUUID": "3e360557-3863-4cfc-b501-10018bba7a5e"
                }
            },
            {
                "id": "WsiQ84gBsYPaEvt5-xDt",
                "values": {
                    "severity": 3,
                    "managementIpAddress": "10.14.70.3",
                    "deviceFamily": "Switches and Hubs",
                    "type": "syslog_event_v2",
                    "additionalDetails": {
                        "Color Level": "2",
                        "Mnemonic": "UPDOWN",
                        "Facility": "LINK"
                    },
                    "deviceName": "B18-live-C9200.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                    "messageType": "Syslog",
                    "eventTime": 1687713515656,
                    "eventName": "LINK:UPDOWN",
                    "details": "2011187: 2011180: *Jun 25 17:21:30.372:  Interface GigabitEthernet1/0/25, changed state to down",
                    "location": "Global/San Jose/Building 18/Floor1",
                    "deviceUUID": "3e360557-3863-4cfc-b501-10018bba7a5e"
                }
            },
            {
                "id": "W8iQ84gBsYPaEvt5-xDt",
                "values": {
                    "severity": 5,
                    "managementIpAddress": "10.14.70.3",
                    "deviceFamily": "Switches and Hubs",
                    "type": "syslog_event_v2",
                    "additionalDetails": {
                        "Color Level": "2",
                        "Mnemonic": "UPDOWN",
                        "Facility": "LINEPROTO"
                    },
                    "deviceName": "B18-live-C9200.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                    "messageType": "Syslog",
                    "eventTime": 1687713514692,
                    "eventName": "LINEPROTO:UPDOWN",
                    "details": "2011186: 2011179: *Jun 25 17:21:29.369:  Line protocol on Interface GigabitEthernet1/0/25, changed state to down",
                    "location": "Global/San Jose/Building 18/Floor1",
                    "deviceUUID": "3e360557-3863-4cfc-b501-10018bba7a5e"
                }
            },
            {
                "id": "38iQ84gBsYPaEvt5hg6h",
                "values": {
                    "severity": 3,
                    "managementIpAddress": "10.14.70.3",
                    "deviceFamily": "Switches and Hubs",
                    "type": "syslog_event_v2",
                    "additionalDetails": {
                        "Color Level": "2",
                        "Mnemonic": "DOWN",
                        "Facility": "LINK"
                    },
                    "deviceName": "B18-live-C9200.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                    "messageType": "Trap",
                    "eventTime": 1687713513893,
                    "eventName": "LINK:DOWN",
                    "details": "GigabitEthernet1/0/25 LINK DOWN",
                    "location": "Global/San Jose/Building 18/Floor1",
                    "deviceUUID": "3e360557-3863-4cfc-b501-10018bba7a5e"
                }
            },
            {
                "id": "68iP84gBsYPaEvt5_goq",
                "values": {
                    "severity": 5,
                    "managementIpAddress": "10.14.70.3",
                    "deviceFamily": "Switches and Hubs",
                    "type": "syslog_event_v2",
                    "additionalDetails": {
                        "Color Level": "3",
                        "Mnemonic": "UP",
                        "Facility": "LINK"
                    },
                    "deviceName": "B18-live-C9200.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                    "messageType": "Trap",
                    "eventTime": 1687713475201,
                    "eventName": "LINK:UP",
                    "details": "GigabitEthernet1/0/25 LINK UP",
                    "location": "Global/San Jose/Building 18/Floor1",
                    "deviceUUID": "3e360557-3863-4cfc-b501-10018bba7a5e"
                }
            }
        ],
        "page": {
            "limit": 10,
            "offset": 1,
            "cursor": null,
            "pages": 789,
            "count": 7887
        },
        "scope": {
            "startTime": 1687627215340,
            "endTime": 1687713615340,
            "selectedTimeZone": null,
            "defaultTimeZone": null,
            "currentTime": null,
            "timeApiTime": null,
            "instrumentation": null,
            "query": {
                "filters": [
                    {
                        "key": "deviceFamily",
                        "value": [
                            "Switches and Hubs"
                        ],
                        "operator": "in"
                    },
                    {
                        "key": "siteHierarchyGraphId",
                        "value": "/e206d6b6-43d9-482c-b737-e213d4218afc/",
                        "operator": "like"
                    }
                ],
                "target": "events",
                "queryType": "summary",
                "page": {
                    "limit": 10,
                    "offset": 0,
                    "cursor": null,
                    "pages": 0,
                    "count": 0,
                    "orderBy": [
                        {
                            "name": "eventTime",
                            "order": "desc"
                        }
                    ]
                },
                "includeMetadata": false
            }
        },
        "instrumentation": null,
        "message": "Success"
    },
    "EventDeviceFamilyWirelessClientData":
    {
        "version": "v2",
        "data": [
            {
                "id": "x_Kl_YgBsYPaEvt57q9D",
                "values": {
                    "identifier": "Grace.Smith",
                    "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                    "apMac": "10:F9:20:FD:65:A0",
                    "type": "event_viewer_session_v2",
                    "additionalDetails": {
                        "AUTH Server": "10.10.105.35",
                        "additionalInfo": "AP:SJC14-F1-9166-2/WLC:LIVE-C9800-40-SSO.wireless-tme.com/WLAN:@TME_live_secure",
                        "WLAN": "@TME_live_secure",
                        "startTime": "1687882660303",
                        "endTime": "1687882660303",
                        "Frequency(GHz)": "6.0",
                        "isPrivateMac": "true"
                    },
                    "ssid": "@CorpSSID",
                    "apName": "SJC14-F1-9166-2",
                    "frequency": "6.0",
                    "resultStatus": "CL_SUCCESS",
                    "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/d927416c-fc2f-4344-bb6c-cd07c658c665/",
                    "apUuid": "79b9e9fd-08b9-4886-b3dd-981ed4f9f1b2",
                    "messageType": "Device Controller",
                    "ipv4": "10.14.70.51",
                    "children": [
                        {
                            "identifier": "rose.black",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 0,
                            "apMac": "10:F9:20:FD:65:A0",
                            "eventType": 1003,
                            "reasonType": 1152,
                            "additionalDetails": {
                                "AUTH Server": "10.10.105.35",
                                "additionalInfo": "Key Exchange",
                                "WLAN": "@TME_live_secure",
                                "Radio": "2",
                                "isPrivateMac": "true"
                            },
                            "ssid": "@TME_live_secure",
                            "apName": "SJC14-F1-9166-2",
                            "frequency": "6.0",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "79b9e9fd-08b9-4886-b3dd-981ed4f9f1b2",
                            "messageType": "Device Controller",
                            "ipv4": "10.14.70.51",
                            "subreasonType": 0,
                            "ipv6": "fe80::c49e:f5ff:fe66:4dd5",
                            "eventName": "Authenticating",
                            "location": "Global/San Jose/Building 14/Floor1",
                            "id": "1687882660303000_#_1003",
                            "macAddr": "C6:9E:F5:66:4D:D5",
                            "username": "rose.black",
                            "timestamp": 1687882660303
                        },
                        {
                            "identifier": "rose.black",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 1,
                            "apMac": "10:F9:20:FD:65:A0",
                            "eventType": 4,
                            "reasonType": 0,
                            "additionalDetails": {
                                "AUTH Server": "10.10.105.35",
                                "WLAN": "@TME_live_secure",
                                "Radio": "2",
                                "isPrivateMac": "true"
                            },
                            "ssid": "@TME_live_secure",
                            "apName": "SJC14-F1-9166-2",
                            "frequency": "6.0",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "79b9e9fd-08b9-4886-b3dd-981ed4f9f1b2",
                            "messageType": "Device Controller",
                            "ipv4": "10.14.70.51",
                            "subreasonType": 0,
                            "ipv6": "fe80::c49e:f5ff:fe66:4dd5",
                            "eventName": "Authentication Done",
                            "location": "Global/San Jose/Building 14/Floor1",
                            "id": "1687882660303000_#_4",
                            "macAddr": "C6:9E:F5:66:4D:D5",
                            "username": "rose.black",
                            "timestamp": 1687882660303
                        },
                        {
                            "identifier": "rose.black",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 2,
                            "apMac": "10:F9:20:FD:65:A0",
                            "eventType": 4,
                            "reasonType": 1126,
                            "additionalDetails": {
                                "AUTH Server": "10.10.105.35",
                                "additionalInfo": "L2 Auth Response",
                                "WLAN": "@TME_live_secure",
                                "Radio": "2",
                                "isPrivateMac": "true"
                            },
                            "ssid": "@TME_live_secure",
                            "apName": "SJC14-F1-9166-2",
                            "frequency": "6.0",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "79b9e9fd-08b9-4886-b3dd-981ed4f9f1b2",
                            "messageType": "Device Controller",
                            "ipv4": "10.14.70.51",
                            "subreasonType": 0,
                            "ipv6": "fe80::c49e:f5ff:fe66:4dd5",
                            "eventName": "Authentication Done",
                            "location": "Global/San Jose/Building 14/Floor1",
                            "id": "1687882660303000_#_4",
                            "macAddr": "C6:9E:F5:66:4D:D5",
                            "username": "rose.black",
                            "timestamp": 1687882660303
                        }
                    ],
                    "ipv6": "fe80::c49e:f5ff:fe66:4dd5",
                    "eventTime": 1687882660303,
                    "eventName": "Authenticating",
                    "location": "Global/San Jose/Building 14/Floor1",
                    "id": "0aeb3039-af89-4f5a-8945-a51b212c94e7",
                    "macAddr": "C6:9E:F5:66:4D:D5",
                    "username": "Grace.Smith",
                    "timestamp": 1687882660303
                }
            },
            {
                "id": "yfKl_YgBsYPaEvt57q9D",
                "values": {
                    "identifier": "clint.barton",
                    "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                    "dhcpServerIp": "10.10.105.7",
                    "apMac": "10:F9:20:FD:65:A0",
                    "type": "event_viewer_session_v2",
                    "additionalDetails": {
                        "SubReasonType": "IP Learn DHCP",
                        "additionalInfo": "AP:SJC14-F1-9166-2/WLC:LIVE-C9800-40-SSO.wireless-tme.com/WLAN:@TME_live_secure",
                        "WLAN": "@TME_live_secure",
                        "startTime": "1687882651053",
                        "endTime": "1687882651053",
                        "VLAN ID/VNID": "70",
                        "Frequency(GHz)": "5.0",
                        "isPrivateMac": "false"
                    },
                    "ssid": "@TME_live_secure",
                    "apName": "SJC14-F1-9166-2",
                    "frequency": "5.0",
                    "resultStatus": "CL_SUCCESS",
                    "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/d927416c-fc2f-4344-bb6c-cd07c658c665/",
                    "apUuid": "79b9e9fd-08b9-4886-b3dd-981ed4f9f1b2",
                    "messageType": "Device Controller",
                    "ipv4": "10.14.70.82",
                    "children": [
                        {
                            "identifier": "clint.barton",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 0,
                            "dhcpServerIp": "10.10.105.7",
                            "apMac": "10:F9:20:FD:65:A0",
                            "eventType": 7,
                            "reasonType": 1162,
                            "additionalDetails": {
                                "SubReasonType": "IP Learn DHCP",
                                "additionalInfo": "IP Learn DHCP",
                                "VLAN ID": "70",
                                "WLAN": "@TME_live_secure",
                                "Radio": "1",
                                "isPrivateMac": "false"
                            },
                            "ssid": "@TME_live_secure",
                            "apName": "SJC14-F1-9166-2",
                            "frequency": "5.0",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "79b9e9fd-08b9-4886-b3dd-981ed4f9f1b2",
                            "messageType": "Device Controller",
                            "ipv4": "10.14.70.82",
                            "subreasonType": 191,
                            "ipv6": "fe80::72f3:5aff:fe7a:99d1",
                            "eventName": "DHCP",
                            "location": "Global/San Jose/Building 14/Floor1",
                            "id": "1687882651053000_#_7",
                            "macAddr": "70:F3:5A:7A:99:D1",
                            "username": "clint.barton",
                            "timestamp": 1687882651053
                        }
                    ],
                    "ipv6": "fe80::72f3:5aff:fe7a:99d1",
                    "eventTime": 1687882651053,
                    "eventName": "DHCP",
                    "location": "Global/San Jose/Building 14/Floor1",
                    "id": "ad2c97b1-4065-4b03-8b15-acac24a68ff2",
                    "macAddr": "70:F3:5A:7A:99:D1",
                    "username": "clint.barton",
                    "timestamp": 1687882651053
                }
            },
            {
                "id": "yPKl_YgBsYPaEvt57q9D",
                "values": {
                    "identifier": "clint.barton",
                    "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                    "dhcpServerIp": "10.10.105.7",
                    "apMac": "10:F9:20:FD:65:A0",
                    "type": "event_viewer_session_v2",
                    "additionalDetails": {
                        "SubReasonType": "IP Learn DHCP",
                        "WLAN": "@TME_live_secure",
                        "Radio": "1",
                        "VLAN ID/VNID": "70",
                        "Frequency(GHz)": "5.0",
                        "isPrivateMac": "false",
                        "AUTH Server": "10.10.105.35",
                        "ROLE": "LOCAL",
                        "RSSI": "-44 dBm",
                        "SNR": "51 dB",
                        "additionalInfo": "AP:SJC14-F1-9166-2/WLC:LIVE-C9800-40-SSO.wireless-tme.com/WLAN:@TME_live_secure",
                        "startTime": "1687882646735",
                        "endTime": "1687882649999"
                    },
                    "ssid": "@TME_live_secure",
                    "apName": "SJC14-F1-9166-2",
                    "frequency": "5.0",
                    "resultStatus": "CL_SUCCESS",
                    "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/d927416c-fc2f-4344-bb6c-cd07c658c665/",
                    "apUuid": "79b9e9fd-08b9-4886-b3dd-981ed4f9f1b2",
                    "messageType": "Device Controller",
                    "ipv4": "10.14.70.82",
                    "children": [
                        {
                            "identifier": "clint.barton",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 0,
                            "dhcpServerIp": "10.10.105.7",
                            "apMac": "10:F9:20:FD:65:A0",
                            "eventType": 1,
                            "reasonType": 1169,
                            "additionalDetails": {
                                "RSSI": "-44 dBm",
                                "SNR": "51 dB",
                                "additionalInfo": "Assoc Request",
                                "WLAN": "@TME_live_secure",
                                "Radio": "1",
                                "isPrivateMac": "false"
                            },
                            "ssid": "@TME_live_secure",
                            "apName": "SJC14-F1-9166-2",
                            "frequency": "5.0",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "79b9e9fd-08b9-4886-b3dd-981ed4f9f1b2",
                            "messageType": "Device Controller",
                            "ipv4": "10.14.70.82",
                            "subreasonType": 0,
                            "ipv6": "fe80::72f3:5aff:fe7a:99d1",
                            "eventName": "Association Start",
                            "location": "Global/San Jose/Building 14/Floor1",
                            "id": "1687882646735000_#_1",
                            "macAddr": "70:F3:5A:7A:99:D1",
                            "username": "clint.barton",
                            "timestamp": 1687882646735
                        },
                        {
                            "identifier": "clint.barton",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 1,
                            "dhcpServerIp": "10.10.105.7",
                            "apMac": "10:F9:20:FD:65:A0",
                            "eventType": 2,
                            "reasonType": 0,
                            "additionalDetails": {
                                "WLAN": "@TME_live_secure",
                                "Radio": "1",
                                "isPrivateMac": "false"
                            },
                            "ssid": "@TME_live_secure",
                            "apName": "SJC14-F1-9166-2",
                            "frequency": "5.0",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "79b9e9fd-08b9-4886-b3dd-981ed4f9f1b2",
                            "messageType": "Device Controller",
                            "ipv4": "10.14.70.82",
                            "subreasonType": 0,
                            "ipv6": "fe80::72f3:5aff:fe7a:99d1",
                            "eventName": "Association Done",
                            "location": "Global/San Jose/Building 14/Floor1",
                            "id": "1687882646735000_#_2",
                            "macAddr": "70:F3:5A:7A:99:D1",
                            "username": "clint.barton",
                            "timestamp": 1687882646735
                        },
                        {
                            "identifier": "clint.barton",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 2,
                            "dhcpServerIp": "10.10.105.7",
                            "apMac": "10:F9:20:FD:65:A0",
                            "eventType": 3,
                            "reasonType": 1144,
                            "additionalDetails": {
                                "additionalInfo": "L2 Auth Start",
                                "WLAN": "@TME_live_secure",
                                "Radio": "1",
                                "isPrivateMac": "false"
                            },
                            "ssid": "@TME_live_secure",
                            "apName": "SJC14-F1-9166-2",
                            "frequency": "5.0",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "79b9e9fd-08b9-4886-b3dd-981ed4f9f1b2",
                            "messageType": "Device Controller",
                            "ipv4": "10.14.70.82",
                            "subreasonType": 0,
                            "ipv6": "fe80::72f3:5aff:fe7a:99d1",
                            "eventName": "Authentication Start",
                            "location": "Global/San Jose/Building 14/Floor1",
                            "id": "1687882646736000_#_3",
                            "macAddr": "70:F3:5A:7A:99:D1",
                            "username": "clint.barton",
                            "timestamp": 1687882646736
                        },
                        {
                            "identifier": "clint.barton",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 3,
                            "dhcpServerIp": "10.10.105.7",
                            "apMac": "10:F9:20:FD:65:A0",
                            "eventType": 1003,
                            "reasonType": 1152,
                            "additionalDetails": {
                                "AUTH Server": "10.10.105.35",
                                "additionalInfo": "Key Exchange",
                                "WLAN": "@TME_live_secure",
                                "Radio": "1",
                                "isPrivateMac": "false"
                            },
                            "ssid": "@TME_live_secure",
                            "apName": "SJC14-F1-9166-2",
                            "frequency": "5.0",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "79b9e9fd-08b9-4886-b3dd-981ed4f9f1b2",
                            "messageType": "Device Controller",
                            "ipv4": "10.14.70.82",
                            "subreasonType": 0,
                            "ipv6": "fe80::72f3:5aff:fe7a:99d1",
                            "eventName": "Authenticating",
                            "location": "Global/San Jose/Building 14/Floor1",
                            "id": "1687882648064000_#_1003",
                            "macAddr": "70:F3:5A:7A:99:D1",
                            "username": "clint.barton",
                            "timestamp": 1687882648064
                        },
                        {
                            "identifier": "clint.barton",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 4,
                            "dhcpServerIp": "10.10.105.7",
                            "apMac": "10:F9:20:FD:65:A0",
                            "eventType": 4,
                            "reasonType": 0,
                            "additionalDetails": {
                                "AUTH Server": "10.10.105.35",
                                "WLAN": "@TME_live_secure",
                                "Radio": "1",
                                "isPrivateMac": "false"
                            },
                            "ssid": "@TME_live_secure",
                            "apName": "SJC14-F1-9166-2",
                            "frequency": "5.0",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "79b9e9fd-08b9-4886-b3dd-981ed4f9f1b2",
                            "messageType": "Device Controller",
                            "ipv4": "10.14.70.82",
                            "subreasonType": 0,
                            "ipv6": "fe80::72f3:5aff:fe7a:99d1",
                            "eventName": "Authentication Done",
                            "location": "Global/San Jose/Building 14/Floor1",
                            "id": "1687882648064000_#_4",
                            "macAddr": "70:F3:5A:7A:99:D1",
                            "username": "clint.barton",
                            "timestamp": 1687882648064
                        },
                        {
                            "identifier": "clint.barton",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 5,
                            "dhcpServerIp": "10.10.105.7",
                            "apMac": "10:F9:20:FD:65:A0",
                            "eventType": 6,
                            "reasonType": 0,
                            "additionalDetails": {
                                "ROLE": "LOCAL",
                                "WLAN": "@TME_live_secure",
                                "Radio": "1",
                                "isPrivateMac": "false"
                            },
                            "ssid": "@TME_live_secure",
                            "apName": "SJC14-F1-9166-2",
                            "frequency": "5.0",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "79b9e9fd-08b9-4886-b3dd-981ed4f9f1b2",
                            "messageType": "Device Controller",
                            "ipv4": "10.14.70.82",
                            "subreasonType": 0,
                            "ipv6": "fe80::72f3:5aff:fe7a:99d1",
                            "eventName": "Mobility",
                            "location": "Global/San Jose/Building 14/Floor1",
                            "id": "1687882648066000_#_6",
                            "macAddr": "70:F3:5A:7A:99:D1",
                            "username": "clint.barton",
                            "timestamp": 1687882648066
                        },
                        {
                            "identifier": "clint.barton",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 6,
                            "dhcpServerIp": "10.10.105.7",
                            "apMac": "10:F9:20:FD:65:A0",
                            "eventType": 100,
                            "reasonType": 1161,
                            "additionalDetails": {
                                "additionalInfo": "IP Learn Start",
                                "WLAN": "@TME_live_secure",
                                "Radio": "1",
                                "isPrivateMac": "false"
                            },
                            "ssid": "@TME_live_secure",
                            "apName": "SJC14-F1-9166-2",
                            "frequency": "5.0",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "79b9e9fd-08b9-4886-b3dd-981ed4f9f1b2",
                            "messageType": "Device Controller",
                            "ipv4": "10.14.70.82",
                            "subreasonType": 0,
                            "ipv6": "fe80::72f3:5aff:fe7a:99d1",
                            "eventName": "DHCP Start",
                            "location": "Global/San Jose/Building 14/Floor1",
                            "id": "1687882648067000_#_100",
                            "macAddr": "70:F3:5A:7A:99:D1",
                            "username": "clint.barton",
                            "timestamp": 1687882648067
                        },
                        {
                            "identifier": "clint.barton",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 7,
                            "dhcpServerIp": "10.10.105.7",
                            "apMac": "10:F9:20:FD:65:A0",
                            "eventType": 7,
                            "reasonType": 1162,
                            "additionalDetails": {
                                "SubReasonType": "IP Learn DHCP",
                                "additionalInfo": "IP Learn DHCP",
                                "VLAN ID": "70",
                                "WLAN": "@TME_live_secure",
                                "Radio": "1",
                                "isPrivateMac": "false"
                            },
                            "ssid": "@TME_live_secure",
                            "apName": "SJC14-F1-9166-2",
                            "frequency": "5.0",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "79b9e9fd-08b9-4886-b3dd-981ed4f9f1b2",
                            "messageType": "Device Controller",
                            "ipv4": "10.14.70.82",
                            "subreasonType": 191,
                            "ipv6": "fe80::72f3:5aff:fe7a:99d1",
                            "eventName": "DHCP",
                            "location": "Global/San Jose/Building 14/Floor1",
                            "id": "1687882649998000_#_7",
                            "macAddr": "70:F3:5A:7A:99:D1",
                            "username": "clint.barton",
                            "timestamp": 1687882649998
                        },
                        {
                            "identifier": "clint.barton",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 8,
                            "dhcpServerIp": "10.10.105.7",
                            "apMac": "10:F9:20:FD:65:A0",
                            "eventType": 9,
                            "reasonType": 0,
                            "additionalDetails": {
                                "ROLE": "LOCAL",
                                "additionalInfo": "Client Onboarded",
                                "VLAN ID": "70",
                                "WLAN": "@TME_live_secure",
                                "Radio": "1",
                                "isPrivateMac": "false"
                            },
                            "ssid": "@TME_live_secure",
                            "apName": "SJC14-F1-9166-2",
                            "frequency": "5.0",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "79b9e9fd-08b9-4886-b3dd-981ed4f9f1b2",
                            "messageType": "Device Controller",
                            "ipv4": "10.14.70.82",
                            "subreasonType": 0,
                            "ipv6": "fe80::72f3:5aff:fe7a:99d1",
                            "eventName": "Run",
                            "location": "Global/San Jose/Building 14/Floor1",
                            "id": "1687882649999000_#_9",
                            "macAddr": "70:F3:5A:7A:99:D1",
                            "username": "clint.barton",
                            "timestamp": 1687882649999
                        }
                    ],
                    "ipv6": "fe80::72f3:5aff:fe7a:99d1",
                    "eventTime": 1687882646735,
                    "eventName": "Onboarding",
                    "location": "Global/San Jose/Building 14/Floor1",
                    "id": "f857c15b-752e-4d10-b70e-b5b94a8ff59e",
                    "macAddr": "70:F3:5A:7A:99:D1",
                    "username": "clint.barton",
                    "timestamp": 1687882646735
                }
            },
            {
                "id": "APKo_YgBsYPaEvt5rcJP",
                "values": {
                    "identifier": "clint.barton",
                    "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                    "dhcpServerIp": "10.10.105.7",
                    "apMac": "10:F9:20:FD:68:80",
                    "type": "event_viewer_session_v2",
                    "additionalDetails": {
                        "additionalInfo": "Due to EAP Timeout/AP:SJC14-F1-9166-1/WLC:LIVE-C9800-40-SSO.wireless-tme.com/WLAN:@TME_live_secure",
                        "WLAN": "@TME_live_secure",
                        "startTime": "1687882632293",
                        "endTime": "1687882632294",
                        "Frequency(GHz)": "5.0",
                        "isPrivateMac": "false",
                        "Reason Description": "Client deleted due to Authentication EAP timeout."
                    },
                    "ssid": "@TME_live_secure",
                    "apName": "SJC14-F1-9166-1",
                    "frequency": "5.0",
                    "resultStatus": "CL_SUCCESS",
                    "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/d927416c-fc2f-4344-bb6c-cd07c658c665/",
                    "apUuid": "f679ea98-ff04-4eee-9cff-5b8b7675bdf5",
                    "messageType": "Device Controller",
                    "ipv4": "10.14.70.110",
                    "children": [
                        {
                            "identifier": "clint.barton",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 0,
                            "dhcpServerIp": "10.10.105.7",
                            "apMac": "10:F9:20:FD:68:80",
                            "eventType": 17,
                            "reasonType": 901,
                            "additionalDetails": {
                                "additionalInfo": "EAP Timeout",
                                "WLAN": "@TME_live_secure",
                                "Radio": "1",
                                "isPrivateMac": "false",
                                "Reason Description": "Client deleted due to Authentication EAP timeout."
                            },
                            "ssid": "@TME_live_secure",
                            "apName": "SJC14-F1-9166-1",
                            "frequency": "5.0",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "f679ea98-ff04-4eee-9cff-5b8b7675bdf5",
                            "messageType": "Device Controller",
                            "ipv4": "10.14.70.110",
                            "subreasonType": 0,
                            "ipv6": "fe80::72f3:5aff:fe7b:c9b1",
                            "eventName": "Client sent Disassociation",
                            "location": "Global/San Jose/Building 14/Floor1",
                            "id": "1687882632293000_#_17",
                            "macAddr": "70:F3:5A:7B:C9:B1",
                            "username": "clint.barton",
                            "timestamp": 1687882632293
                        },
                        {
                            "identifier": "clint.barton",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 1,
                            "dhcpServerIp": "10.10.105.7",
                            "apMac": "10:F9:20:FD:68:80",
                            "eventType": 17,
                            "reasonType": 1176,
                            "additionalDetails": {
                                "additionalInfo": "Disconnect Req",
                                "WLAN": "@TME_live_secure",
                                "Radio": "1",
                                "isPrivateMac": "false"
                            },
                            "ssid": "@TME_live_secure",
                            "apName": "SJC14-F1-9166-1",
                            "frequency": "5.0",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "f679ea98-ff04-4eee-9cff-5b8b7675bdf5",
                            "messageType": "Device Controller",
                            "ipv4": "10.14.70.110",
                            "subreasonType": 0,
                            "ipv6": "fe80::72f3:5aff:fe7b:c9b1",
                            "eventName": "Client sent Disassociation",
                            "location": "Global/San Jose/Building 14/Floor1",
                            "id": "1687882632293000_#_17",
                            "macAddr": "70:F3:5A:7B:C9:B1",
                            "username": "clint.barton",
                            "timestamp": 1687882632293
                        },
                        {
                            "identifier": "clint.barton",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 2,
                            "dhcpServerIp": "10.10.105.7",
                            "apMac": "10:F9:20:FD:68:80",
                            "eventType": 17,
                            "reasonType": 1135,
                            "additionalDetails": {
                                "additionalInfo": "Delete Client",
                                "WLAN": "@TME_live_secure",
                                "Radio": "1",
                                "isPrivateMac": "false"
                            },
                            "ssid": "@TME_live_secure",
                            "apName": "SJC14-F1-9166-1",
                            "frequency": "5.0",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "f679ea98-ff04-4eee-9cff-5b8b7675bdf5",
                            "messageType": "Device Controller",
                            "ipv4": "10.14.70.110",
                            "subreasonType": 0,
                            "ipv6": "fe80::72f3:5aff:fe7b:c9b1",
                            "eventName": "Client sent Disassociation",
                            "location": "Global/San Jose/Building 14/Floor1",
                            "id": "1687882632294000_#_17",
                            "macAddr": "70:F3:5A:7B:C9:B1",
                            "username": "clint.barton",
                            "timestamp": 1687882632294
                        },
                        {
                            "identifier": "clint.barton",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 3,
                            "dhcpServerIp": "10.10.105.7",
                            "apMac": "10:F9:20:FD:68:80",
                            "eventType": 11,
                            "reasonType": 901,
                            "additionalDetails": {
                                "additionalInfo": "EAP Timeout",
                                "WLAN": "@TME_live_secure",
                                "Radio": "1",
                                "isPrivateMac": "false",
                                "Reason Description": "Client deleted due to Authentication EAP timeout."
                            },
                            "ssid": "@TME_live_secure",
                            "apName": "SJC14-F1-9166-1",
                            "frequency": "5.0",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "f679ea98-ff04-4eee-9cff-5b8b7675bdf5",
                            "messageType": "Device Controller",
                            "ipv4": "10.14.70.110",
                            "subreasonType": 0,
                            "ipv6": "fe80::72f3:5aff:fe7b:c9b1",
                            "eventName": "Deleted",
                            "location": "Global/San Jose/Building 14/Floor1",
                            "id": "1687882632294000_#_11",
                            "macAddr": "70:F3:5A:7B:C9:B1",
                            "username": "clint.barton",
                            "timestamp": 1687882632294
                        }
                    ],
                    "ipv6": "fe80::72f3:5aff:fe7b:c9b1",
                    "eventTime": 1687882632293,
                    "eventName": "Delete",
                    "location": "Global/San Jose/Building 14/Floor1",
                    "id": "34574176-5a86-46a8-9c6e-b71d8049c174",
                    "macAddr": "70:F3:5A:7B:C9:B1",
                    "username": "clint.barton",
                    "timestamp": 1687882632293
                }
            },
            {
                "id": "yfKl_YgBsYPaEvt5BKkL",
                "values": {
                    "identifier": "10.14.70.143",
                    "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                    "dhcpServerIp": "10.10.105.7",
                    "apMac": "6C:8D:77:2E:04:20",
                    "type": "event_viewer_session_v2",
                    "additionalDetails": {
                        "SubReasonType": "IP Learn DHCP",
                        "additionalInfo": "AP:SJC14-F1-9164-3/WLC:LIVE-C9800-40-SSO.wireless-tme.com/WLAN:TME_live_psk",
                        "WLAN": "TME_live_psk",
                        "startTime": "1687882616001",
                        "endTime": "1687882616001",
                        "VLAN ID/VNID": "70",
                        "Frequency(GHz)": "2.4",
                        "isPrivateMac": "false"
                    },
                    "ssid": "TME_live_psk",
                    "apName": "SJC14-F1-9164-3",
                    "frequency": "2.4",
                    "resultStatus": "CL_SUCCESS",
                    "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/d927416c-fc2f-4344-bb6c-cd07c658c665/",
                    "apUuid": "9bbe389a-8936-4e6c-8aa9-d8d7bf0cc210",
                    "messageType": "Device Controller",
                    "ipv4": "10.14.70.143",
                    "children": [
                        {
                            "identifier": "10.14.70.143",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 0,
                            "dhcpServerIp": "10.10.105.7",
                            "apMac": "6C:8D:77:2E:04:20",
                            "eventType": 7,
                            "reasonType": 1162,
                            "additionalDetails": {
                                "SubReasonType": "IP Learn DHCP",
                                "additionalInfo": "IP Learn DHCP",
                                "VLAN ID": "70",
                                "WLAN": "TME_live_psk",
                                "Radio": "0",
                                "isPrivateMac": "false"
                            },
                            "ssid": "TME_live_psk",
                            "apName": "SJC14-F1-9164-3",
                            "frequency": "2.4",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "9bbe389a-8936-4e6c-8aa9-d8d7bf0cc210",
                            "messageType": "Device Controller",
                            "ipv4": "10.14.70.143",
                            "subreasonType": 191,
                            "eventName": "DHCP",
                            "location": "Global/San Jose/Building 14/Floor1",
                            "id": "1687882616001000_#_7",
                            "macAddr": "2C:33:11:7A:2B:29",
                            "timestamp": 1687882616001
                        }
                    ],
                    "eventTime": 1687882616001,
                    "eventName": "DHCP",
                    "location": "Global/San Jose/Building 14/Floor1",
                    "id": "922f6ffd-f967-4eb7-811d-8b8f41e88126",
                    "macAddr": "2C:33:11:7A:2B:29",
                    "timestamp": 1687882616001
                }
            },
            {
                "id": "y_Kl_YgBsYPaEvt5BKkL",
                "values": {
                    "identifier": "10.14.70.143",
                    "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                    "apMac": "6C:8D:77:2E:04:20",
                    "type": "event_viewer_session_v2",
                    "additionalDetails": {
                        "ROLE": "LOCAL",
                        "RSSI": "-45 dBm",
                        "SubReasonType": "IP Learn DHCP",
                        "SNR": "55 dB",
                        "additionalInfo": "AP:SJC14-F1-9164-3/WLC:LIVE-C9800-40-SSO.wireless-tme.com/WLAN:TME_live_psk",
                        "WLAN": "TME_live_psk",
                        "Radio": "0",
                        "startTime": "1687882614058",
                        "endTime": "1687882614138",
                        "VLAN ID/VNID": "70",
                        "Frequency(GHz)": "2.4",
                        "isPrivateMac": "false"
                    },
                    "ssid": "TME_live_psk",
                    "apName": "SJC14-F1-9164-3",
                    "frequency": "2.4",
                    "resultStatus": "CL_SUCCESS",
                    "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/d927416c-fc2f-4344-bb6c-cd07c658c665/",
                    "apUuid": "9bbe389a-8936-4e6c-8aa9-d8d7bf0cc210",
                    "messageType": "Device Controller",
                    "ipv4": "10.14.70.143",
                    "children": [
                        {
                            "identifier": "10.14.70.143",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 0,
                            "apMac": "6C:8D:77:2E:04:20",
                            "eventType": 1,
                            "reasonType": 1169,
                            "additionalDetails": {
                                "RSSI": "-45 dBm",
                                "SNR": "55 dB",
                                "additionalInfo": "Assoc Request",
                                "WLAN": "TME_live_psk",
                                "Radio": "0",
                                "isPrivateMac": "false"
                            },
                            "ssid": "TME_live_psk",
                            "apName": "SJC14-F1-9164-3",
                            "frequency": "2.4",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "9bbe389a-8936-4e6c-8aa9-d8d7bf0cc210",
                            "messageType": "Device Controller",
                            "ipv4": "10.14.70.143",
                            "subreasonType": 0,
                            "eventName": "Association Start",
                            "location": "Global/San Jose/Building 14/Floor1",
                            "id": "1687882614058000_#_1",
                            "macAddr": "2C:33:11:7A:2B:29",
                            "timestamp": 1687882614058
                        },
                        {
                            "identifier": "10.14.70.143",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 1,
                            "apMac": "6C:8D:77:2E:04:20",
                            "eventType": 2,
                            "reasonType": 0,
                            "additionalDetails": {
                                "WLAN": "TME_live_psk",
                                "Radio": "0",
                                "isPrivateMac": "false"
                            },
                            "ssid": "TME_live_psk",
                            "apName": "SJC14-F1-9164-3",
                            "frequency": "2.4",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "9bbe389a-8936-4e6c-8aa9-d8d7bf0cc210",
                            "messageType": "Device Controller",
                            "ipv4": "10.14.70.143",
                            "subreasonType": 0,
                            "eventName": "Association Done",
                            "location": "Global/San Jose/Building 14/Floor1",
                            "id": "1687882614058000_#_2",
                            "macAddr": "2C:33:11:7A:2B:29",
                            "timestamp": 1687882614058
                        },
                        {
                            "identifier": "10.14.70.143",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 2,
                            "apMac": "6C:8D:77:2E:04:20",
                            "eventType": 3,
                            "reasonType": 1144,
                            "additionalDetails": {
                                "additionalInfo": "L2 Auth Start",
                                "WLAN": "TME_live_psk",
                                "Radio": "0",
                                "isPrivateMac": "false"
                            },
                            "ssid": "TME_live_psk",
                            "apName": "SJC14-F1-9164-3",
                            "frequency": "2.4",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "9bbe389a-8936-4e6c-8aa9-d8d7bf0cc210",
                            "messageType": "Device Controller",
                            "ipv4": "10.14.70.143",
                            "subreasonType": 0,
                            "eventName": "Authentication Start",
                            "location": "Global/San Jose/Building 14/Floor1",
                            "id": "1687882614059000_#_3",
                            "macAddr": "2C:33:11:7A:2B:29",
                            "timestamp": 1687882614059
                        },
                        {
                            "identifier": "10.14.70.143",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 3,
                            "apMac": "6C:8D:77:2E:04:20",
                            "eventType": 1003,
                            "reasonType": 1152,
                            "additionalDetails": {
                                "additionalInfo": "Key Exchange",
                                "WLAN": "TME_live_psk",
                                "Radio": "0",
                                "isPrivateMac": "false"
                            },
                            "ssid": "TME_live_psk",
                            "apName": "SJC14-F1-9164-3",
                            "frequency": "2.4",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "9bbe389a-8936-4e6c-8aa9-d8d7bf0cc210",
                            "messageType": "Device Controller",
                            "ipv4": "10.14.70.143",
                            "subreasonType": 0,
                            "eventName": "Authenticating",
                            "location": "Global/San Jose/Building 14/Floor1",
                            "id": "1687882614134000_#_1003",
                            "macAddr": "2C:33:11:7A:2B:29",
                            "timestamp": 1687882614134
                        },
                        {
                            "identifier": "10.14.70.143",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 4,
                            "apMac": "6C:8D:77:2E:04:20",
                            "eventType": 4,
                            "reasonType": 0,
                            "additionalDetails": {
                                "WLAN": "TME_live_psk",
                                "Radio": "0",
                                "isPrivateMac": "false"
                            },
                            "ssid": "TME_live_psk",
                            "apName": "SJC14-F1-9164-3",
                            "frequency": "2.4",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "9bbe389a-8936-4e6c-8aa9-d8d7bf0cc210",
                            "messageType": "Device Controller",
                            "ipv4": "10.14.70.143",
                            "subreasonType": 0,
                            "eventName": "Authentication Done",
                            "location": "Global/San Jose/Building 14/Floor1",
                            "id": "1687882614134000_#_4",
                            "macAddr": "2C:33:11:7A:2B:29",
                            "timestamp": 1687882614134
                        },
                        {
                            "identifier": "10.14.70.143",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 5,
                            "apMac": "6C:8D:77:2E:04:20",
                            "eventType": 6,
                            "reasonType": 0,
                            "additionalDetails": {
                                "ROLE": "LOCAL",
                                "WLAN": "TME_live_psk",
                                "Radio": "0",
                                "isPrivateMac": "false"
                            },
                            "ssid": "TME_live_psk",
                            "apName": "SJC14-F1-9164-3",
                            "frequency": "2.4",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "9bbe389a-8936-4e6c-8aa9-d8d7bf0cc210",
                            "messageType": "Device Controller",
                            "ipv4": "10.14.70.143",
                            "subreasonType": 0,
                            "eventName": "Mobility",
                            "location": "Global/San Jose/Building 14/Floor1",
                            "id": "1687882614136000_#_6",
                            "macAddr": "2C:33:11:7A:2B:29",
                            "timestamp": 1687882614136
                        },
                        {
                            "identifier": "10.14.70.143",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 6,
                            "apMac": "6C:8D:77:2E:04:20",
                            "eventType": 100,
                            "reasonType": 1161,
                            "additionalDetails": {
                                "additionalInfo": "IP Learn Start",
                                "WLAN": "TME_live_psk",
                                "Radio": "0",
                                "isPrivateMac": "false"
                            },
                            "ssid": "TME_live_psk",
                            "apName": "SJC14-F1-9164-3",
                            "frequency": "2.4",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "9bbe389a-8936-4e6c-8aa9-d8d7bf0cc210",
                            "messageType": "Device Controller",
                            "ipv4": "10.14.70.143",
                            "subreasonType": 0,
                            "eventName": "DHCP Start",
                            "location": "Global/San Jose/Building 14/Floor1",
                            "id": "1687882614137000_#_100",
                            "macAddr": "2C:33:11:7A:2B:29",
                            "timestamp": 1687882614137
                        },
                        {
                            "identifier": "10.14.70.143",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 7,
                            "apMac": "6C:8D:77:2E:04:20",
                            "eventType": 7,
                            "reasonType": 1162,
                            "additionalDetails": {
                                "SubReasonType": "IP Learn DHCP",
                                "additionalInfo": "IP Learn DHCP",
                                "VLAN ID": "70",
                                "WLAN": "TME_live_psk",
                                "Radio": "0",
                                "isPrivateMac": "false"
                            },
                            "ssid": "TME_live_psk",
                            "apName": "SJC14-F1-9164-3",
                            "frequency": "2.4",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "9bbe389a-8936-4e6c-8aa9-d8d7bf0cc210",
                            "messageType": "Device Controller",
                            "ipv4": "10.14.70.143",
                            "subreasonType": 191,
                            "eventName": "DHCP",
                            "location": "Global/San Jose/Building 14/Floor1",
                            "id": "1687882614137000_#_7",
                            "macAddr": "2C:33:11:7A:2B:29",
                            "timestamp": 1687882614137
                        },
                        {
                            "identifier": "10.14.70.143",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 8,
                            "apMac": "6C:8D:77:2E:04:20",
                            "eventType": 9,
                            "reasonType": 0,
                            "additionalDetails": {
                                "ROLE": "LOCAL",
                                "additionalInfo": "Client Onboarded",
                                "VLAN ID": "70",
                                "WLAN": "TME_live_psk",
                                "Radio": "0",
                                "isPrivateMac": "false"
                            },
                            "ssid": "TME_live_psk",
                            "apName": "SJC14-F1-9164-3",
                            "frequency": "2.4",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "9bbe389a-8936-4e6c-8aa9-d8d7bf0cc210",
                            "messageType": "Device Controller",
                            "ipv4": "10.14.70.143",
                            "subreasonType": 0,
                            "eventName": "Run",
                            "location": "Global/San Jose/Building 14/Floor1",
                            "id": "1687882614138000_#_9",
                            "macAddr": "2C:33:11:7A:2B:29",
                            "timestamp": 1687882614138
                        }
                    ],
                    "eventTime": 1687882614058,
                    "eventName": "Onboarding",
                    "location": "Global/San Jose/Building 14/Floor1",
                    "id": "6fe2d75b-6b20-4e12-b0f4-8979669ee004",
                    "macAddr": "2C:33:11:7A:2B:29",
                    "timestamp": 1687882614058
                }
            },
            {
                "id": "yvKl_YgBsYPaEvt5BKkL",
                "values": {
                    "identifier": "10.14.70.143",
                    "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                    "dhcpServerIp": "10.10.105.7",
                    "apMac": "6C:8D:77:2E:04:20",
                    "type": "event_viewer_session_v2",
                    "additionalDetails": {
                        "additionalInfo": "Due to Session Timeout/AP:SJC14-F1-9164-3/WLC:LIVE-C9800-40-SSO.wireless-tme.com/WLAN:TME_live_psk",
                        "WLAN": "TME_live_psk",
                        "startTime": "1687882613905",
                        "endTime": "1687882613905",
                        "Frequency(GHz)": "2.4",
                        "isPrivateMac": "false",
                        "Reason Description": "Client is deleted due to session time out."
                    },
                    "ssid": "TME_live_psk",
                    "apName": "SJC14-F1-9164-3",
                    "frequency": "2.4",
                    "resultStatus": "CL_SUCCESS",
                    "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/d927416c-fc2f-4344-bb6c-cd07c658c665/",
                    "apUuid": "9bbe389a-8936-4e6c-8aa9-d8d7bf0cc210",
                    "messageType": "Device Controller",
                    "ipv4": "10.14.70.143",
                    "children": [
                        {
                            "identifier": "10.14.70.143",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 0,
                            "dhcpServerIp": "10.10.105.7",
                            "apMac": "6C:8D:77:2E:04:20",
                            "eventType": 11,
                            "reasonType": 1045,
                            "additionalDetails": {
                                "additionalInfo": "Session Timeout",
                                "WLAN": "TME_live_psk",
                                "Radio": "0",
                                "isPrivateMac": "false",
                                "Reason Description": "Client is deleted due to session time out."
                            },
                            "ssid": "TME_live_psk",
                            "apName": "SJC14-F1-9164-3",
                            "frequency": "2.4",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "9bbe389a-8936-4e6c-8aa9-d8d7bf0cc210",
                            "messageType": "Device Controller",
                            "ipv4": "10.14.70.143",
                            "subreasonType": 0,
                            "eventName": "Deleted",
                            "location": "Global/San Jose/Building 14/Floor1",
                            "id": "1687882613905000_#_11",
                            "macAddr": "2C:33:11:7A:2B:29",
                            "timestamp": 1687882613905
                        }
                    ],
                    "eventTime": 1687882613905,
                    "eventName": "Delete",
                    "location": "Global/San Jose/Building 14/Floor1",
                    "id": "ae22fbb7-4c89-4a21-897d-a1cbcd6330f8",
                    "macAddr": "2C:33:11:7A:2B:29",
                    "timestamp": 1687882613905
                }
            },
            {
                "id": "-PKn_YgBsYPaEvt5w7p7",
                "values": {
                    "identifier": "DESKTOP-B6UG4LR",
                    "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                    "apMac": "AC:4A:56:AE:92:C0",
                    "type": "event_viewer_session_v2",
                    "additionalDetails": {
                        "ROLE": "LOCAL",
                        "RSSI": "-46 dBm",
                        "SNR": "51 dB",
                        "additionalInfo": "Due to IP Learn Timeout/AP:Assurance_9130_3/WLC:LIVE-C9800-40-SSO.wireless-tme.com/WLAN:live-legacy",
                        "WLAN": "live-legacy",
                        "Radio": "1",
                        "startTime": "1687882560901",
                        "endTime": "1687882680917",
                        "VLAN ID/VNID": "70",
                        "Frequency(GHz)": "5.0",
                        "isPrivateMac": "false",
                        "Reason Description": "Client deleted as it didn't get an IP address in time."
                    },
                    "ssid": "live-legacy",
                    "apName": "Assurance_9130_3",
                    "frequency": "5.0",
                    "resultStatus": "CL_SUCCESS",
                    "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                    "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                    "messageType": "Device Controller",
                    "children": [
                        {
                            "identifier": "DESKTOP-B6UG4LR",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 0,
                            "apMac": "AC:4A:56:AE:92:C0",
                            "eventType": 1,
                            "reasonType": 1169,
                            "additionalDetails": {
                                "RSSI": "-46 dBm",
                                "SNR": "51 dB",
                                "additionalInfo": "Assoc Request",
                                "WLAN": "live-legacy",
                                "Radio": "1",
                                "isPrivateMac": "false"
                            },
                            "ssid": "live-legacy",
                            "apName": "Assurance_9130_3",
                            "frequency": "5.0",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "messageType": "Device Controller",
                            "subreasonType": 0,
                            "eventName": "Association Start",
                            "location": "Global/San Jose/Building 18/Floor3",
                            "id": "1687882560901000_#_1",
                            "macAddr": "DC:FB:48:A3:7E:38",
                            "timestamp": 1687882560901
                        },
                        {
                            "identifier": "DESKTOP-B6UG4LR",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 1,
                            "apMac": "AC:4A:56:AE:92:C0",
                            "eventType": 1002,
                            "reasonType": 122,
                            "additionalDetails": {
                                "additionalInfo": "Intra Roam",
                                "WLAN": "live-legacy",
                                "Radio": "1",
                                "isPrivateMac": "false"
                            },
                            "ssid": "live-legacy",
                            "apName": "Assurance_9130_3",
                            "frequency": "5.0",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "messageType": "Device Controller",
                            "subreasonType": 0,
                            "eventName": "Associating",
                            "location": "Global/San Jose/Building 18/Floor3",
                            "id": "1687882560901000_#_1002",
                            "macAddr": "DC:FB:48:A3:7E:38",
                            "timestamp": 1687882560901
                        },
                        {
                            "identifier": "DESKTOP-B6UG4LR",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 2,
                            "apMac": "AC:4A:56:AE:92:C0",
                            "eventType": 2,
                            "reasonType": 0,
                            "additionalDetails": {
                                "WLAN": "live-legacy",
                                "Radio": "1",
                                "isPrivateMac": "false"
                            },
                            "ssid": "live-legacy",
                            "apName": "Assurance_9130_3",
                            "frequency": "5.0",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "messageType": "Device Controller",
                            "subreasonType": 0,
                            "eventName": "Association Done",
                            "location": "Global/San Jose/Building 18/Floor3",
                            "id": "1687882560901000_#_2",
                            "macAddr": "DC:FB:48:A3:7E:38",
                            "timestamp": 1687882560901
                        },
                        {
                            "identifier": "DESKTOP-B6UG4LR",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 3,
                            "apMac": "AC:4A:56:AE:92:C0",
                            "eventType": 3,
                            "reasonType": 1144,
                            "additionalDetails": {
                                "additionalInfo": "L2 Auth Start",
                                "WLAN": "live-legacy",
                                "Radio": "1",
                                "isPrivateMac": "false"
                            },
                            "ssid": "live-legacy",
                            "apName": "Assurance_9130_3",
                            "frequency": "5.0",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "messageType": "Device Controller",
                            "subreasonType": 0,
                            "eventName": "Authentication Start",
                            "location": "Global/San Jose/Building 18/Floor3",
                            "id": "1687882560902000_#_3",
                            "macAddr": "DC:FB:48:A3:7E:38",
                            "timestamp": 1687882560902
                        },
                        {
                            "identifier": "DESKTOP-B6UG4LR",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 4,
                            "apMac": "AC:4A:56:AE:92:C0",
                            "eventType": 1003,
                            "reasonType": 1152,
                            "additionalDetails": {
                                "additionalInfo": "Key Exchange",
                                "WLAN": "live-legacy",
                                "Radio": "1",
                                "isPrivateMac": "false"
                            },
                            "ssid": "live-legacy",
                            "apName": "Assurance_9130_3",
                            "frequency": "5.0",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "messageType": "Device Controller",
                            "subreasonType": 0,
                            "eventName": "Authenticating",
                            "location": "Global/San Jose/Building 18/Floor3",
                            "id": "1687882560914000_#_1003",
                            "macAddr": "DC:FB:48:A3:7E:38",
                            "timestamp": 1687882560914
                        },
                        {
                            "identifier": "DESKTOP-B6UG4LR",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 5,
                            "apMac": "AC:4A:56:AE:92:C0",
                            "eventType": 4,
                            "reasonType": 0,
                            "additionalDetails": {
                                "WLAN": "live-legacy",
                                "Radio": "1",
                                "isPrivateMac": "false"
                            },
                            "ssid": "live-legacy",
                            "apName": "Assurance_9130_3",
                            "frequency": "5.0",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "messageType": "Device Controller",
                            "subreasonType": 0,
                            "eventName": "Authentication Done",
                            "location": "Global/San Jose/Building 18/Floor3",
                            "id": "1687882560914000_#_4",
                            "macAddr": "DC:FB:48:A3:7E:38",
                            "timestamp": 1687882560914
                        },
                        {
                            "identifier": "DESKTOP-B6UG4LR",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 6,
                            "apMac": "AC:4A:56:AE:92:C0",
                            "eventType": 6,
                            "reasonType": 0,
                            "additionalDetails": {
                                "ROLE": "LOCAL",
                                "WLAN": "live-legacy",
                                "Radio": "1",
                                "isPrivateMac": "false"
                            },
                            "ssid": "live-legacy",
                            "apName": "Assurance_9130_3",
                            "frequency": "5.0",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "messageType": "Device Controller",
                            "subreasonType": 0,
                            "eventName": "Mobility",
                            "location": "Global/San Jose/Building 18/Floor3",
                            "id": "1687882560916000_#_6",
                            "macAddr": "DC:FB:48:A3:7E:38",
                            "timestamp": 1687882560916
                        },
                        {
                            "identifier": "DESKTOP-B6UG4LR",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 7,
                            "apMac": "AC:4A:56:AE:92:C0",
                            "eventType": 7,
                            "reasonType": 1162,
                            "additionalDetails": {
                                "additionalInfo": "Received Client IP",
                                "VLAN ID": "70",
                                "WLAN": "live-legacy",
                                "Radio": "1",
                                "isPrivateMac": "false"
                            },
                            "ssid": "live-legacy",
                            "apName": "Assurance_9130_3",
                            "frequency": "5.0",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "messageType": "Device Controller",
                            "subreasonType": 0,
                            "eventName": "DHCP",
                            "location": "Global/San Jose/Building 18/Floor3",
                            "id": "1687882560917000_#_7",
                            "macAddr": "DC:FB:48:A3:7E:38",
                            "timestamp": 1687882560917
                        },
                        {
                            "identifier": "DESKTOP-B6UG4LR",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 8,
                            "apMac": "AC:4A:56:AE:92:C0",
                            "eventType": 11,
                            "reasonType": 1324,
                            "additionalDetails": {
                                "additionalInfo": "IP Learn Timeout",
                                "WLAN": "live-legacy",
                                "Radio": "1",
                                "isPrivateMac": "false",
                                "Reason Description": "Client deleted as it didn't get an IP address in time."
                            },
                            "ssid": "live-legacy",
                            "apName": "Assurance_9130_3",
                            "frequency": "5.0",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "messageType": "Device Controller",
                            "subreasonType": 0,
                            "eventName": "Deleted",
                            "location": "Global/San Jose/Building 18/Floor3",
                            "id": "1687882680917000_#_11",
                            "macAddr": "DC:FB:48:A3:7E:38",
                            "timestamp": 1687882680917
                        }
                    ],
                    "ipv6": "fe80::3197:36d6:59ff:1ee3",
                    "eventTime": 1687882560901,
                    "eventName": "INTRA-WLC Roaming -- Incomplete",
                    "location": "Global/San Jose/Building 18/Floor3",
                    "id": "9af9c9e9-7046-4329-9c74-c77307e4e92b",
                    "macAddr": "DC:FB:48:A3:7E:38",
                    "timestamp": 1687882560901
                }
            },
            {
                "id": "xvKl_YgBsYPaEvt57q9D",
                "values": {
                    "identifier": "DESKTOP-B6UG4LR",
                    "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                    "apMac": "4C:A6:4D:23:33:A0",
                    "type": "event_viewer_session_v2",
                    "additionalDetails": {
                        "RSSI": "-72 dBm",
                        "SNR": "16 dB",
                        "additionalInfo": "AP:AP3C57.31C5.9CC4/WLC:LIVE-C9800-40-SSO.wireless-tme.com/WLAN:live-legacy",
                        "WLAN": "live-legacy",
                        "Radio": "1",
                        "startTime": "1687882559683",
                        "endTime": "1687882559685",
                        "Frequency(GHz)": "5.0",
                        "isPrivateMac": "false"
                    },
                    "ssid": "live-legacy",
                    "apName": "AP3C57.31C5.9CC4",
                    "frequency": "5.0",
                    "resultStatus": "CL_SUCCESS",
                    "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                    "apUuid": "f1a99f49-2cea-444f-85a7-a8c869d54646",
                    "messageType": "Device Controller",
                    "children": [
                        {
                            "identifier": "DESKTOP-B6UG4LR",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 0,
                            "apMac": "4C:A6:4D:23:33:A0",
                            "eventType": 1,
                            "reasonType": 1169,
                            "additionalDetails": {
                                "RSSI": "-72 dBm",
                                "SNR": "16 dB",
                                "additionalInfo": "Assoc Request",
                                "WLAN": "live-legacy",
                                "Radio": "1",
                                "isPrivateMac": "false"
                            },
                            "ssid": "live-legacy",
                            "apName": "AP3C57.31C5.9CC4",
                            "frequency": "5.0",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "f1a99f49-2cea-444f-85a7-a8c869d54646",
                            "messageType": "Device Controller",
                            "subreasonType": 0,
                            "eventName": "Association Start",
                            "location": "Global/San Jose/Building 18/Floor1",
                            "id": "1687882559683000_#_1",
                            "macAddr": "DC:FB:48:A3:7E:38",
                            "timestamp": 1687882559683
                        },
                        {
                            "identifier": "DESKTOP-B6UG4LR",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 1,
                            "apMac": "4C:A6:4D:23:33:A0",
                            "eventType": 2,
                            "reasonType": 0,
                            "additionalDetails": {
                                "WLAN": "live-legacy",
                                "Radio": "1",
                                "isPrivateMac": "false"
                            },
                            "ssid": "live-legacy",
                            "apName": "AP3C57.31C5.9CC4",
                            "frequency": "5.0",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "f1a99f49-2cea-444f-85a7-a8c869d54646",
                            "messageType": "Device Controller",
                            "subreasonType": 0,
                            "eventName": "Association Done",
                            "location": "Global/San Jose/Building 18/Floor1",
                            "id": "1687882559684000_#_2",
                            "macAddr": "DC:FB:48:A3:7E:38",
                            "timestamp": 1687882559684
                        },
                        {
                            "identifier": "DESKTOP-B6UG4LR",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 2,
                            "apMac": "4C:A6:4D:23:33:A0",
                            "eventType": 3,
                            "reasonType": 1144,
                            "additionalDetails": {
                                "additionalInfo": "L2 Auth Start",
                                "WLAN": "live-legacy",
                                "Radio": "1",
                                "isPrivateMac": "false"
                            },
                            "ssid": "live-legacy",
                            "apName": "AP3C57.31C5.9CC4",
                            "frequency": "5.0",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "f1a99f49-2cea-444f-85a7-a8c869d54646",
                            "messageType": "Device Controller",
                            "subreasonType": 0,
                            "eventName": "Authentication Start",
                            "location": "Global/San Jose/Building 18/Floor1",
                            "id": "1687882559685000_#_3",
                            "macAddr": "DC:FB:48:A3:7E:38",
                            "timestamp": 1687882559685
                        }
                    ],
                    "ipv6": "fe80::3197:36d6:59ff:1ee3",
                    "eventTime": 1687882559683,
                    "eventName": "Onboarding -- Incomplete",
                    "location": "Global/San Jose/Building 18/Floor1",
                    "id": "4f5ba98b-fd60-4a27-bff5-e78b0267d7fe",
                    "macAddr": "DC:FB:48:A3:7E:38",
                    "timestamp": 1687882559683
                }
            },
            {
                "id": "z_Kl_YgBsYPaEvt5BKkL",
                "values": {
                    "identifier": "clint.barton",
                    "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                    "dhcpServerIp": "10.10.105.7",
                    "apMac": "10:F9:20:FD:68:80",
                    "type": "event_viewer_session_v2",
                    "additionalDetails": {
                        "SubReasonType": "IP Learn DHCP",
                        "additionalInfo": "AP:SJC14-F1-9166-1/WLC:LIVE-C9800-40-SSO.wireless-tme.com/WLAN:@TME_live_secure",
                        "WLAN": "@TME_live_secure",
                        "startTime": "1687882547204",
                        "endTime": "1687882547204",
                        "VLAN ID/VNID": "70",
                        "Frequency(GHz)": "5.0",
                        "isPrivateMac": "false"
                    },
                    "ssid": "@TME_live_secure",
                    "apName": "SJC14-F1-9166-1",
                    "frequency": "5.0",
                    "resultStatus": "CL_SUCCESS",
                    "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                    "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/d927416c-fc2f-4344-bb6c-cd07c658c665/",
                    "apUuid": "f679ea98-ff04-4eee-9cff-5b8b7675bdf5",
                    "messageType": "Device Controller",
                    "ipv4": "10.14.70.110",
                    "children": [
                        {
                            "identifier": "clint.barton",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "index": 0,
                            "dhcpServerIp": "10.10.105.7",
                            "apMac": "10:F9:20:FD:68:80",
                            "eventType": 7,
                            "reasonType": 1162,
                            "additionalDetails": {
                                "SubReasonType": "IP Learn DHCP",
                                "additionalInfo": "IP Learn DHCP",
                                "VLAN ID": "70",
                                "WLAN": "@TME_live_secure",
                                "Radio": "1",
                                "isPrivateMac": "false"
                            },
                            "ssid": "@TME_live_secure",
                            "apName": "SJC14-F1-9166-1",
                            "frequency": "5.0",
                            "resultStatus": "CL_SUCCESS",
                            "wlcName": "LIVE-C9800-40-SSO.wireless-tme.com",
                            "apUuid": "f679ea98-ff04-4eee-9cff-5b8b7675bdf5",
                            "messageType": "Device Controller",
                            "ipv4": "10.14.70.110",
                            "subreasonType": 191,
                            "ipv6": "fe80::72f3:5aff:fe7b:c9b1",
                            "eventName": "DHCP",
                            "location": "Global/San Jose/Building 14/Floor1",
                            "id": "1687882547204000_#_7",
                            "macAddr": "70:F3:5A:7B:C9:B1",
                            "username": "clint.barton",
                            "timestamp": 1687882547204
                        }
                    ],
                    "ipv6": "fe80::72f3:5aff:fe7b:c9b1",
                    "eventTime": 1687882547204,
                    "eventName": "DHCP",
                    "location": "Global/San Jose/Building 14/Floor1",
                    "id": "8afdbbf7-8c75-460c-9726-a72725e1a14b",
                    "macAddr": "70:F3:5A:7B:C9:B1",
                    "username": "clint.barton",
                    "timestamp": 1687882547204
                }
            }
        ],
        "page": {
            "limit": 10,
            "offset": 1,
            "cursor": null,
            "pages": 1000,
            "count": 10000
        },
        "scope": {
            "startTime": 1687796262594,
            "endTime": 1687882662594,
            "selectedTimeZone": null,
            "defaultTimeZone": null,
            "currentTime": null,
            "timeApiTime": null,
            "instrumentation": null,
            "query": {
                "filters": [
                    {
                        "key": "deviceFamily",
                        "value": [
                            "Wireless Client"
                        ],
                        "operator": "in"
                    },
                    {
                        "key": "siteHierarchyGraphId",
                        "value": "/e206d6b6-43d9-482c-b737-e213d4218afc/",
                        "operator": "like"
                    }
                ],
                "target": "events",
                "queryType": "summary",
                "page": {
                    "limit": 10,
                    "offset": 0,
                    "cursor": null,
                    "pages": 0,
                    "count": 0,
                    "orderBy": [
                        {
                            "name": "eventTime",
                            "order": "desc"
                        }
                    ]
                },
                "includeMetadata": false
            }
        },
        "instrumentation": null,
        "message": "Success"
    },
    "EventsResponseTemplate":{
        "version": "v2",
        "data": [
            
        ],
        "page": {
            "limit": 1,
            "offset": 1,
            "cursor": null,
            "pages": 10000,
            "count": 10000
        },
        "scope": {
            "startTime": null,
            "endTime": null,
            "selectedTimeZone": null,
            "defaultTimeZone": null,
            "currentTime": null,
            "timeApiTime": null,
            "instrumentation": null,
            "query": {
                "fields": [
                    "eventTime"
                ],
                "filters": [
                    {
                        "key": "deviceFamily",
                        "value": [
                            "Routers",
                            "Switches and Hubs",
                            "Wireless Controller"
                        ],
                        "operator": "in"
                    }
                ],
                "target": "events",
                "queryType": "summary",
                "page": {
                    "limit": 1,
                    "offset": 0,
                    "cursor": null,
                    "pages": 0,
                    "count": 0,
                    "orderBy": [
                        {
                            "name": "eventTime",
                            "order": "asc"
                        }
                    ]
                },
                "includeMetadata": false
            }
        },
        "instrumentation": null,
        "message": "Success"
    }

  });