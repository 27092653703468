define({
    "AP_Wlan_Template": {
      "apMacAddrList":[],
      "authEntityId": 1132970653,
      "authEntityClass": 395482,
      "cfsChangeInfo": [

      ],
      "createtime":"",
      "customProvisions": [

      ],
      "deployPending": "NONE",
      "deployed": true,
      "deviceInfo": [

       ],
      "displayName": "4387c29d[cc5169ef-5a7f-4217-99ee-a20ec5e056c9,<null>]",
      "id": "",
      "instanceId": 395482,
      "instanceVersion": 2,
      "isSeeded": false,
      "isStale": false,
      "lastUpdateTime":"",
      "name":"",
      "namespace": "",
      "provisioningState": "DEPLOYED",
      "resourceVersion": 2,
      "rfProfile":"",
      "type": "ApWirelessConfiguration",
      "siteId": ""
    }

});
