define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/bonjour/WideAreaBonjourTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/bonjour/BonjourDashboardGraphTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/libs/node_modules/moment'
], function (SimLokiDatabaseActions, WideAreaBonjourTemplate, BonjourDashboardGraphTemplate, UtilityFunctions, moment) {

    return {
        init: function () {
            var ifIpStart = "10.20.30.0", count=0;
            var devIfUsed = {};
            var tagRecord = SimLokiDatabaseActions.getFilteredRecordHandler('tag',{'name':'SDG_Agents'});
            var records = SimLokiDatabaseActions.getFilteredRecordHandler('tag-device-mapping',{'tag':tagRecord[0].id});

            //settings configured in Administration -> Global
            var tJson = JSON.parse(JSON.stringify(WideAreaBonjourTemplate.PREFERENCE_TEMPLATE));
            SimLokiDatabaseActions.insert('bonjour-global-settings', tJson);
            console.log('Bonjour :: Settings added :: '+SimLokiDatabaseActions.getAll('bonjour-global-settings').length);

            //node or devices taking part in bonjour
            records.forEach(node => {
                tJson = JSON.parse(JSON.stringify(WideAreaBonjourTemplate.NODE_TEMPLATE));
                var devRecord = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id':node.device});
                if(devRecord.length>0) {
                    devRecord = devRecord[0];
                    tJson.id = devRecord.id;
                    tJson.name = devRecord.hostname;//for easy of use
                    tJson.ipAddress = devRecord.managementIpAddress;
                    var mappingRec = WideAreaBonjourTemplate.SOURCE_QUERY_MAPPINGS.filter( rec => rec.source==tJson.name || rec.query==tJson.name );
                    tJson.sdgRole = mappingRec.map(mapRec => mapRec.source==tJson.name?"SOURCE":"QUERY");
                    tJson.subDomains = mappingRec.map(mapRec => mapRec.subdomain);
                    tJson.serviceFilters = mappingRec.map(mapRec => mapRec.policyUsed);
                    var interfaces = SimLokiDatabaseActions.getFilteredRecordHandler('device-interface', {'deviceId':devRecord.id});                    
                    interfaces.forEach(intFace => {
                        count++;
                        var obj = {"network": "3.3.3.9", "prefix": "32", "name": "Loopback0"};
                        obj.name = intFace.portName;
                        obj.prefix = obj.name.toLowerCase().indexOf('loopback')>-1 ? '32' : '24';
                        obj.network = obj.name.toLowerCase()=='loopback0' ? devRecord.managementIpAddress : 
                            UtilityFunctions.incrementIpAddrres(ifIpStart,count);
                        tJson.networks.push(obj);
                        if(obj.name == 'GigabitEthernet1/3') {
                            devIfUsed[devRecord.hostname] = obj.network;//this will have ipaddress of the interface
                        }
                    });
                    tJson.endStartTime = UtilityFunctions.getGivenDateAndTimeInValidFormat( UtilityFunctions.getTimeStamp());
                    SimLokiDatabaseActions.insert('bonjour-nodes', tJson);
                }
            });
            console.log('Bonjour :: Nodes added :: '+SimLokiDatabaseActions.getAll('bonjour-nodes').length);

            //service policies/filters
            tJson = JSON.parse(JSON.stringify(WideAreaBonjourTemplate.SERVICE_POLICY));
            for(var i=0;i<tJson.length; i++) {
                var mappingRec = WideAreaBonjourTemplate.SOURCE_QUERY_MAPPINGS.filter( rec => rec.policyUsed==tJson[i].name );
                var sourceDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname':mappingRec[0].source});
                var queryDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname':mappingRec[0].query});
                sourceDevice = sourceDevice[0], queryDevice = queryDevice[0];
                tJson[i].sourceConditions[0].sourceIp = sourceDevice.managementIpAddress;
                tJson[i].sourceConditions[0].network = devIfUsed[sourceDevice.hostname];
                tJson[i].queryConditions[0].sourceIp = queryDevice.managementIpAddress;
                tJson[i].queryConditions[0].network = devIfUsed[queryDevice.hostname];
                tJson[i].sourceConditions[0].sourceName = sourceDevice.hostname;
                tJson[i].queryConditions[0].sourceName = queryDevice.hostname;
                tJson[i].sourceConditions[0].interfaceName = 'GigabitEthernet1/3';
                tJson[i].queryConditions[0].interfaceName = 'GigabitEthernet1/3';
                SimLokiDatabaseActions.insert('bonjour-service-policy', tJson[i]);
            }
            console.log('Bonjour :: Service Policies added :: '+SimLokiDatabaseActions.getAll('bonjour-service-policy').length);

            //service entry shown in Monitor -> ServiceInstance
            tJson = JSON.parse(JSON.stringify(WideAreaBonjourTemplate.SERVICE_ENTRY_TEMPLATE));
            for(var i=0; i<tJson.length; i++) {
                var serviceRec = SimLokiDatabaseActions.getFilteredRecordHandler('bonjour-service-policy',{'name':tJson[i].associatedToServicePolicy});
                var sourceIp = serviceRec[0].sourceConditions[0].sourceIp;
                records = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'managementIpAddress':sourceIp});
                tJson[i].reporterIp = sourceIp;
                tJson[i].ipAddress = sourceIp;
                tJson[i].macAddress = records[0].macAddress;
                SimLokiDatabaseActions.insert('bonjour-service-entry', tJson[i]);
            }
            console.log('Bonjour :: Service Entries added :: '+SimLokiDatabaseActions.getAll('bonjour-service-entry').length);

            //domains and sub domains
            tJson = JSON.parse(JSON.stringify(WideAreaBonjourTemplate.DOMAIN_TEMPLATE));
            SimLokiDatabaseActions.insert('bonjour-domain', tJson);
            console.log('Bonjour :: Domains/Sub-domains added :: '+SimLokiDatabaseActions.getAll('bonjour-domain').length);

            //service types
            tJson = JSON.parse(JSON.stringify(WideAreaBonjourTemplate.SERVICE_TYPE_TEMPLATE));
            SimLokiDatabaseActions.insert('bonjour-service-type', tJson);
            console.log('Bonjour :: Service Types added :: '+SimLokiDatabaseActions.getAll('bonjour-service-type').length);
        },

        getPreferenceDetail: function() {
            var settings = SimLokiDatabaseActions.getAll('bonjour-global-settings');
            return settings[0];
        },

        getServiceEntry: function(urlAction) {
            var records = SimLokiDatabaseActions.getAll('bonjour-service-entry');
            if(urlAction.filter['filterBy'] != undefined && urlAction.filter['filterBy'].trim() != '') {
                var filters = getFilters(urlAction.filter['filterBy'], {'isStale':'active', 'domains':'associatedToQueryDomain',
                    'policies':'associatedToServicePolicy', 'serviceType':'type'}, []);
                if(filters != undefined && filters.valid != undefined) {
                    records = SimLokiDatabaseActions.getFilteredRecordHandler('bonjour-service-entry', filters.valid);
                }
            }
            if(urlAction.action.count) {
                return records.length;
            }
            return records;
        },

        getDomains: function(urlAction) {
            var records = SimLokiDatabaseActions.getAll('bonjour-domain');
            var data = {}, id = urlAction.action.id;
            if(urlAction.action.count) {
                return records.length;
            }
            //groupPoliciesAndNodes=true&groupPolicyByServiceType=false&includeNodes=true&includeParent=false&includePolicies=true
            if(id != undefined && id != ''){
                //if there are more than one sub-domains for a domain, it is not handled in code, for now.
                var tJson = {'id':'id', 'name':'name', 'description': 'description', 'type':'domain', 'children':[],
                    'selected':false}, parentId;
                var includeParent = isFilterVal(urlAction.filter['includeParent']);
                var includePolicies = isFilterVal(urlAction.filter['includePolicies']);
                var includeNodes = isFilterVal(urlAction.filter['includeNodes']);
                var isParent = false, reqId = id;
                records = SimLokiDatabaseActions.getFilteredRecordHandler('bonjour-domain', {'id':id});
                if(includeParent) {
                    parentId = records.length>0 ? records[0].parent : undefined;
                    if(parentId == '') {
                        parentId = undefined; includeParent = false;
                    } else {
                        records = SimLokiDatabaseActions.getFilteredRecordHandler('bonjour-domain', {'id':parentId}); 
                        isParent = true;
                    }
                }
                if(records.length>0) {
                    data = {'id':records[0].id, 'name':records[0].name, 'description': records[0].description, 'type':'domain', 
                        'children':[], 'selected':true};
                    if(includeParent) {
                        data.selected = false;
                    }
                    if(isFilterVal(urlAction.filter['groupPoliciesAndNodes'])) {

                        function fillData(recursiveObj, domain, includePolicies, includeParent, includeNodes, isParent, reqDomainId) {
                            if(includePolicies && !(includeParent && isParent)) {
                                var obj = JSON.parse(JSON.stringify(tJson));
                                obj.id = UtilityFunctions.generate_uuid(), obj.name = domain.policies, obj.type = 'policySummary';
                                obj.description = 'Total number of Filters contained in this subdomain.'
                                recursiveObj.children.push(obj);
                            }
                            if(includeNodes && !(includeParent && isParent)) {
                                var obj = JSON.parse(JSON.stringify(tJson));
                                obj.id = UtilityFunctions.generate_uuid(), obj.name = domain.sdgNodes, obj.type = 'nodeSummary';
                                obj.description = 'Total number of SDG Agents contained in this subdomain.'
                                if(domain.sdgNodes > 0) { // if there are no agents, do not push.
                                    recursiveObj.children.push(obj);
                                }
                            }

                            var domRecords = SimLokiDatabaseActions.getFilteredRecordHandler('bonjour-domain', {'parent':domain.id});
                            if(domRecords.length>0){
                                for(var a=0; a<domRecords.length; a++) {
                                    if(!isParent || (isParent && domRecords[a].id==reqId)) {
                                        //if parent domain is selected, then push only the selected domain
                                        id = domRecords[a].id;
                                        var obj = JSON.parse(JSON.stringify(tJson));
                                        obj.id = domRecords[a].id, obj.name = domRecords[a].name, obj.description = domRecords[a].description;
                                        if(id == reqDomainId) {
                                            obj.selected = true;
                                        }
                                        recursiveObj.children.push(obj);
                                        if(isParent && domRecords[a].id==reqId) {
                                            isParent = false;
                                            break;
                                        }  
                                    }
                                }
                            }
                            recursiveObj = recursiveObj.children.filter(child => child.type == 'domain' );
                            recursiveObj.forEach(element => {
                                var d = SimLokiDatabaseActions.getFilteredRecordHandler('bonjour-domain', {'id':element.id});
                                fillData(element, d[0], includePolicies, includeParent, includeNodes, false, reqId);
                            });
                            return recursiveObj;
                        }

                        fillData(data, records[0], includePolicies, includeParent, includeNodes, isParent, reqId);
                    }
                }
                return data;
            }
            return records;
        },

        getPolicy: function(urlAction) {
            var records = SimLokiDatabaseActions.getAll('bonjour-service-policy'), id = urlAction.action.id;
            if(urlAction.action.count) {
                return records.length;
            }
            if(urlAction.service.indexOf('query-domain') > -1 && id!=undefined) {
                var data = SimLokiDatabaseActions.getFilteredRecordHandler('bonjour-service-policy', {'domainId':id});
                return data;
            } else if(urlAction.service.indexOf('vlan') > -1) {
                var ip = urlAction.service[urlAction.service.indexOf('vlan') + 1], data = [];
                records = SimLokiDatabaseActions.getFilteredRecordHandler('bonjour-nodes', {'ipAddress':ip});
                if(records.length>0) {
                    var tJson = {"vlanType": "","vlanNumber": 0,"numberOfIPs": 0,"mask": 0,"ipAddress": "192.168.13.5",
                        "prefix": "32","interfaceName": "Loopback4099","networkAddress": "192.168.13.5"}
                    records[0].networks.forEach(ifDtl => {
                        var obj = JSON.parse(JSON.stringify(tJson));
                        obj.ipAddress = ifDtl.network, obj.networkAddress = ifDtl.network;
                        obj.prefix = ifDtl.prefix, obj.interfaceName = ifDtl.name;
                        data.push(obj);
                    });
                }
                return data;
            } else if(id!=undefined && id != '') {
                var data = SimLokiDatabaseActions.getFilteredRecordHandler('bonjour-service-policy', {'id':id});
                return data.length>0 ? data[0] : {};
            }
            return records;
        },

        getNodeInfo: function(urlAction) {
            var records = SimLokiDatabaseActions.getAll('bonjour-nodes'), id = urlAction.action.id;
            records = JSON.parse(JSON.stringify(records)); //this may be the fix
            if(urlAction.service.indexOf('status') > -1) {
                var len, active, inactive, reachable, unreachable;
                len = records.length;
                active = (records.filter(dev => { return dev.active; })).length;
                inactive = len - active;
                reachable = (records.filter(dev => { return dev.reachability=='Reachable'; })).length;
                unreachable = len - reachable;
                return {"totalNode":len,"activeNode":active,"inactiveNode":inactive,"rechableNode":reachable,
                    "unrechableNode":unreachable};
            } else if(urlAction.service.indexOf('sdgManagedDevice') > -1) {
                var data = records.map(node => { return {'instanceUUID':node.id, 'ipAddress':node.ipAddress, 'name': node.name}; });
                return data;
            } else if(urlAction.service.indexOf('query-domain') > -1 && id != undefined && id != '') {
                var domains = SimLokiDatabaseActions.getFilteredRecordHandler('bonjour-domain', {'id':id});
                if(domains.length > 0) {
                    records = records.filter(node => { return node.subDomains.indexOf(domains[0].name) > -1; })
                }
                records.forEach(node => {
                    node.networks = null, node.serviceFilters = null, node.subDomains = null, node.sdgRole = null, 
                    node.reachability = null;
                });
                return records;
            } else if(urlAction.filter['filterBy'] != undefined && urlAction.filter['filterBy'].trim() != '') {
                var skipFields = ['queryDomains','sdgRole','serviceFilters'];
                var filters = getFilters(urlAction.filter['filterBy'], {'syncStatus':'syncState','isActive':'active'}, skipFields);
                if(filters != undefined) {
                    if(filters.valid != undefined) {
                        records = SimLokiDatabaseActions.getFilteredRecordHandler('bonjour-nodes', filters.valid);
                    }
                    skipFields.forEach(field => {
                        var value = filters.skipped[field];
                        if(value == undefined) return;
                        if(value['$in'] != undefined) {
                            if(field == 'sdgRole') {
                                var upperCaseNames = value['$in'].map(function(val) { return val.toUpperCase(); });
                                records = records.filter(node => upperCaseNames.indexOf(node.sdgRole[0]) > -1 );
                            }
                        } else if(value['$regex'] != undefined) {
                            var upperCaseNames = value['$regex'].map(function(val) { return val.toUpperCase(); });
                            if(field == 'queryDomains') {
                                records = records.filter(node => node.subDomains[0].indexOf(value['$regex']) > -1 );
                            } else if(field == 'serviceFilters') {
                                records = records.filter(node => node.serviceFilters[0].indexOf(value['$regex']) > -1 );
                            }
                        }
                    });
                }
            }
            return records;
        },

        getServiceTypes: function(urlAction) {
            var records = SimLokiDatabaseActions.getAll('bonjour-service-type');
            if(urlAction.filter['filterBy'] != undefined && urlAction.filter['filterBy'].trim() != '') {
                var filters = getFilters(urlAction.filter['filterBy'], {}, []);
                if(filters != undefined && filters.valid != undefined) {
                    records = SimLokiDatabaseActions.getFilteredRecordHandler('bonjour-service-type', filters.valid);
                }
            }
            if(urlAction.action.count) {
                return records.length;
            }
            return records;
        },

        getDashboardDetails: function(urlAction) {
            var records = SimLokiDatabaseActions.getAll('bonjour-service-entry');
            var countMapping = {}, response = [], field;
            if(urlAction.service.indexOf('service-type') > -1 ) {
                field = 'type';                
            } else if(urlAction.service.indexOf('query-domain') > -1 ) {
                field = 'associatedToQueryDomain';                
            } else if(urlAction.service.indexOf('sdg-node') > -1 ) {
                field = 'reporterIp';                
            }

            records.map(entry => { 
                if(countMapping[entry[field]]) {
                    countMapping[entry[field]] = countMapping[entry[field]]++;
                } else {
                    countMapping[entry[field]] = 1;
                }
            });

            Object.keys(countMapping).forEach(function(key) {
                var id = UtilityFunctions.getRandomIntForRangeValues(1000000, 2000000).toString()
                if(urlAction.service.indexOf('sdg-node') > -1 ) {
                    id = key;
                }
                response.push({"id":id, "name":key, "noOfServices":countMapping[key]});
            });
            return response;
        },

        getDashboardGraphDetails: function(urlAction) {
            if(urlAction.service.indexOf('date-range') > -1) {
                return {"startDate":UtilityFunctions.getDateInTimestamp_SubtractedByDays_FromCurrentDate(30),
                    "endDate":UtilityFunctions.getTimeStamp()};
            } else {
                return generateData(urlAction);
            }
        },

        addPolicy: function(restPayload) {
            var isError = false;
            var records = SimLokiDatabaseActions.getFilteredRecordHandler('bonjour-service-policy', {'name': restPayload.name});
            if(records.length>0) {
                //there is a policy with this name already
                isError = true;
            } else {
                //restPayload.id = UtilityFunctions.generate_uuid();
                restPayload.serviceTypeId = null, restPayload.serviceTypeName = null;
                restPayload.sourceConditions.forEach(condition => {
                    condition.sourceId = null, condition.sourceType = null;
                    //condition.sourceName = null, condition.vlanCount = null, condition.interfaceName = null;
                    condition.interfaceName = getInterfaceName(condition.sourceName, condition.network);
                });
                restPayload.queryConditions.forEach(condition => {
                    condition.sourceId = null, condition.sourceType = null;
                    //condition.sourceName = null, condition.vlanCount = null, condition.interfaceName = null;
                    condition.interfaceName = getInterfaceName(condition.sourceName, condition.network);
                });
                if(restPayload.selectiveSourceCondition == undefined) {
                    restPayload.selectiveSourceCondition = { "macAddress": [] };
                }
                records = SimLokiDatabaseActions.getFilteredRecordHandler('bonjour-domain', {'id':restPayload.domainId});
                restPayload.domainName = records[0].name;
                restPayload.isActive = true, restPayload.serviceCount = 1;
                restPayload.invalidEntries =null, restPayload.operationalStatus = null;
                SimLokiDatabaseActions.insert('bonjour-service-policy', restPayload);
                //incement the policy count for the domain
                records[0].policies++;
                SimLokiDatabaseActions.update('bonjour-domain', records);
            }
            return isError;
        },

        editPolicy: function(restPayload) {
            var records = SimLokiDatabaseActions.getFilteredRecordHandler('bonjour-service-policy', {'id':restPayload.id});
            records[0].description = restPayload.description;
            records[0].sourceConditions = restPayload.sourceConditions;
            records[0].queryConditions = restPayload.queryConditions;
            records[0].serviceTypeList = restPayload.serviceTypeList;
            if(records[0].domainId != restPayload.domainId) {
                //not sure if domain can be moved to different
                records[0].domainId = restPayload.domainId;
            }
            records[0].selectiveSourceCondition = restPayload.selectiveSourceCondition;
            SimLokiDatabaseActions.update('bonjour-service-policy', records);
        },

        deletePolicy: function(id) {
            var records = SimLokiDatabaseActions.getFilteredRecordHandler('bonjour-service-policy', {'id':id});
            //update the policy count of the domain before deleting the policy
            var domain = SimLokiDatabaseActions.getFilteredRecordHandler('bonjour-domain', {'id':records[0].domainId});
            domain[0].policies--;
            SimLokiDatabaseActions.update('bonjour-domain', domain);
            SimLokiDatabaseActions.delete('bonjour-service-policy', records);
        },

        addDomain: function(restPayload) {
            var isError = false;
            var records = SimLokiDatabaseActions.getFilteredRecordHandler('bonjour-domain', {'name': restPayload.name});
            if(records.length>0) { //there is a domain with this name already
                isError = true;
            } else {
                //restPayload.id = UtilityFunctions.generate_uuid();
                restPayload.sdgNodes = 0, restPayload.policies = 0, restPayload.subDomains = 0;
                SimLokiDatabaseActions.insert('bonjour-domain', restPayload);
                records = SimLokiDatabaseActions.getFilteredRecordHandler('bonjour-domain', {'id': restPayload.parent});
                records[0].subDomains++;
                SimLokiDatabaseActions.update(records);
            }
            return isError;
        },

        deleteDomain: function(id) {
            var domain = SimLokiDatabaseActions.getFilteredRecordHandler('bonjour-domain', {'id':id});

            var records = SimLokiDatabaseActions.getFilteredRecordHandler('bonjour-domain', {'id': domain[0].parent});
            records[0].subDomains--;
            SimLokiDatabaseActions.update(records);
            
            SimLokiDatabaseActions.delete('bonjour-domain', domain);
            //might need to handle deletion of - subdomains under it, policies under the subdomains, nodes under the subdomain?
        },

        addServiceType: function(restPayload) {
            var isError = false;
            var records = SimLokiDatabaseActions.getFilteredRecordHandler('bonjour-service-type', {'name': restPayload.name});
            if(records.length>0) { //there is a service type with this name already
                isError = true;
            } else {
                //restPayload.id = UtilityFunctions.generate_uuid();
                SimLokiDatabaseActions.insert('bonjour-service-type', restPayload);
            }
            return isError;
        },

        editServiceType: function(restPayload) {
            var records = SimLokiDatabaseActions.getFilteredRecordHandler('bonjour-service-type', {'id':restPayload.id});
            records[0].pointerTypes = restPayload.pointerTypes;
            SimLokiDatabaseActions.update('bonjour-service-type', records);
        },

        deleteServiceType: function(id) {
            var serviceTypes = SimLokiDatabaseActions.getFilteredRecordHandler('bonjour-service-type', {'id':id});
            SimLokiDatabaseActions.delete('bonjour-service-type', serviceTypes);
            //var policies = SimLokiDatabaseActions.getFilteredRecordHandler('bonjour-service-policy', {'serviceTypeList.':id});
            //SimLokiDatabaseActions.delete('bonjour-service-policy', policies);
            /* Relations : Service Type (ST)'s name, Service Policy (SP)'s serviceTypeList.name , Service Entry (SE)'s type.
            to update SE with what?
            */
        },

    };

    function generateData(urlAction) {
        var i = 0, timeWindow = urlAction.filter['interval'], incrementBy = 0;
        var startTime = new Date(urlAction.filter["startTime"]);
        var endTime = new Date(urlAction.filter["endTime"]);
        //order is always ascending
        var updatedStartTime = new Date(startTime);
        var tJson, data = [];
        
        updatedStartTime = getAdjustedStartTime(updatedStartTime, timeWindow);
        var timeinMills = new Date(updatedStartTime).getTime(), endTimeInMills = endTime.getTime();

        var avgRecLength = (endTimeInMills-timeinMills)/timeWindow;
        var range = [
            {"dropped":{'min':20,'max':100}, "processed": {'min':60,'max':150}},
            {"dropped":{'min':150,'max':180}, "processed": {'min':250,'max':300}},
            {"dropped":{'min':200,'max':400}, "processed": {'min':300,'max':500}},
            {"dropped":{'min':180,'max':200}, "processed": {'min':400,'max':500}},
            {"dropped":{'min':200,'max':300}, "processed": {'min':400,'max':600}}
        ];
        for(var i=0; timeinMills<endTimeInMills; i++) {
            var obj = { "time": "2019-09-18T12:00:00","dropped": "0","processed": "0","avgDropped": "0","avgProcessed": "0"};
            obj.time = UtilityFunctions.getGivenTimeInGivenFormat(updatedStartTime, "YYYY-MM-DDTHH:mm:00");
            if(i<avgRecLength/5) {
                obj.processed = (UtilityFunctions.getRandomIntForRangeValues(range[0].processed.min, range[0].processed.max)).toString();
                obj.avgProcessed = obj.processed;
                obj.dropped = (UtilityFunctions.getRandomIntForRangeValues(range[0].dropped.min, range[0].dropped.max)).toString();
                obj.avgDropped = obj.dropped;
            } else if(i<2*avgRecLength/5) {
                obj.processed = (UtilityFunctions.getRandomIntForRangeValues(range[1].processed.min, range[1].processed.max)).toString();
                obj.avgProcessed = obj.processed;
                obj.dropped = (UtilityFunctions.getRandomIntForRangeValues(range[1].dropped.min, range[1].dropped.max)).toString();
                obj.avgDropped = obj.dropped;
            } else if(i<3*avgRecLength/5) {
                obj.processed = (UtilityFunctions.getRandomIntForRangeValues(range[2].processed.min, range[2].processed.max)).toString();
                obj.avgProcessed = obj.processed;
                obj.dropped = (UtilityFunctions.getRandomIntForRangeValues(range[2].dropped.min, range[2].dropped.max)).toString();
                obj.avgDropped = obj.dropped;
            } else if(i<4*avgRecLength/5) {
                obj.processed = (UtilityFunctions.getRandomIntForRangeValues(range[3].processed.min, range[3].processed.max)).toString();
                obj.avgProcessed = obj.processed;
                obj.dropped = (UtilityFunctions.getRandomIntForRangeValues(range[3].dropped.min, range[3].dropped.max)).toString();
                obj.avgDropped = obj.dropped;
            } else {
                obj.processed = (UtilityFunctions.getRandomIntForRangeValues(range[4].processed.min, range[4].processed.max)).toString();
                obj.avgProcessed = obj.processed;
                obj.dropped = (UtilityFunctions.getRandomIntForRangeValues(range[4].dropped.min, range[4].dropped.max)).toString();
                obj.avgDropped = obj.dropped;
            }
            data.push(obj);
            updatedStartTime = incrementMinutes(timeinMills, timeWindow);
            timeinMills = new Date(updatedStartTime).getTime();
        }

        /* switch (timeWindow) {
            case 5:
                tJson = BonjourDashboardGraphTemplate.TEMPLATE_5;
                break;
            case 15:
                tJson = BonjourDashboardGraphTemplate.TEMPLATE_15;
                break;
            case 30:
                tJson = BonjourDashboardGraphTemplate.TEMPLATE_30;
                break;
            case 60:
                tJson = BonjourDashboardGraphTemplate.TEMPLATE_60;
                break;
            default:
                tJson = BonjourDashboardGraphTemplate.TEMPLATE_720;
                break;
        };
        var tJsonLen = tJson.length;
        var obj1 = tJson[tJsonLen-1];
        var obj2 = tJson[tJsonLen-2];      
        for(var i=0; timeinMills<endTimeInMills; i++) {
            if(tJsonLen>i) {
                obj = JSON.parse(JSON.stringify(tJson[i]));
            } else {
                obj = JSON.parse(JSON.stringify(obj1));
                var min, max;
                obj1.dropped = parseInt(obj1.dropped), obj2.dropped = parseInt(obj2.dropped);
                if(obj1.dropped > obj2.dropped) {
                    min = obj2.dropped; max = obj1.dropped;
                } else {
                    min = obj1.dropped; max = obj2.dropped;
                }
                obj.dropped = (UtilityFunctions.getRandomIntForRangeValues(min, max)).toString();
                obj.avgDropped = obj.dropped;
                obj1.processed = parseInt(obj1.processed), obj2.processed = parseInt(obj2.processed);
                if(obj1.processed > obj2.processed) {
                    min = obj2.processed; max = obj1.processed;
                } else {
                    min = obj1.processed; max = obj2.processed;
                }
                obj.processed = (UtilityFunctions.getRandomIntForRangeValues(min, max)).toString();
                obj.avgProcessed = obj.processed;
            }
            obj.time = UtilityFunctions.getGivenTimeInGivenFormat(updatedStartTime, "YYYY-MM-DDTHH:mm:00");;
            data.push(obj);
            updatedStartTime = incrementMinutes(timeinMills, timeWindow);
            timeinMills = new Date(updatedStartTime).getTime();
        } */
        return data;
    }
    
    function getAdjustedStartTime(updatedStartTime, timeWindow) {
        switch (timeWindow) {
            case 720:
                if(updatedStartTime.getUTCHours()>=12) {
                    updatedStartTime.setUTCDate(updatedStartTime.getUTCDate()+1);
                    updatedStartTime.setUTCHours(0);
                } else {
                    updatedStartTime.setUTCHours(12);
                }
                updatedStartTime.setUTCMinutes(0);
                break;
            case 60:
                if(updatedStartTime.getUTCMinutes()>0) {
                    updatedStartTime.setUTCHours(updatedStartTime.getUTCHours()+1);
                }
                updatedStartTime.setUTCMinutes(0);
                break;
            case 30:
                if(updatedStartTime.getUTCMinutes()>=30) {
                    updatedStartTime.setUTCHours(updatedStartTime.getUTCHours()+1);
                }
                updatedStartTime.setUTCMinutes(30);
                break;
            case 15:
                var min = updatedStartTime.getUTCMinutes();
                var diff = min % 15;
                updatedStartTime.setUTCMinutes(min + 15 - diff);
                break;
            case 5:
                var min = updatedStartTime.getUTCMinutes();
                var diff = min % 5;
                updatedStartTime.setUTCMinutes(min + 5 - diff);
                break;
            default:
                break;
        }
        var year  = updatedStartTime.getUTCFullYear(), month = updatedStartTime.getUTCMonth();
        var date  = updatedStartTime.getUTCDate(), hour = updatedStartTime.getUTCHours();
        var minute = updatedStartTime.getUTCMinutes();
        //updatedStartTime = moment([year,month,date,hour,minute,0,0]).format("YYYY-MM-DDTHH:mm:ss");
        updatedStartTime = new Date(updatedStartTime);
        return updatedStartTime;
    }

    function incrementMinutes (tDate, IncrementMinuteBy) {
        updatedStartTime = new Date(tDate+(IncrementMinuteBy*60*1000));
        return updatedStartTime;
    }

    function getInterfaceName(devName, interfaceIp) {
        var dev = SimLokiDatabaseActions.getFilteredRecordHandler('bonjour-nodes', {'name' : devName} );
        if(dev.length > 0) {
            var interfaces = dev[0].networks;
            interfaces = interfaces.filter(ifDtl => ifDtl.network==interfaceIp);
            if(interfaces.length>0) {
                return interfaces[0].network;
            }
        }
        return null;
    }

    function getFilters(urlFilter, diffField, skipFields) {
        /* Sample URLs: 
        - /api/v1/sdg-node?filterBy=ipAddress~3.3.3.9,queryDomains~22,serviceFilters~TV,sdgRole-:-Source::Query::None,
        isActive-:-true::false,syncStatus-:-Failed::In+Progress::Not+Initiated::Successful
        - /api/v1/service-entry?filterBy=name~tv,domains~22,policies~TV,reporterIP~13,serviceType~TV,ipAddress~13,
        ipAddress~13,macAddress~79&limit=15&offset=1&sortBy=reporterIP:asc */
        var filters = {'valid':{'$and':[]}, 'skipped':{}};
        if(urlFilter != undefined && urlFilter != '') {
            urlFilter = urlFilter.split(',');
            for(var i=0; i<urlFilter.length; i++) {
                var filterData = urlFilter[i].split('-:-'), filterVal;
                if(filterData.length<2) {
                    filterData = urlFilter[i].split('~');
                    filterData[1] = filterData[1].replace(/\+/g, ' ');
                    if(skipFields.indexOf(filterData[0]) > -1) {
                        filters.skipped[filterData[0]] = {'$regex':filterData[1]};
                    } else {
                        filters.valid['$and'].push( {[filterData[0]] : {'$regex':filterData[1]} } );
                    }
                } else {
                    filterVal = filterData[1].replace(/\+/g, ' ');
                    filterVal = filterVal.split('::');
                    var splHandling = false;
                    if(diffField[filterData[0]] != undefined) {
                        if(filterData[0] == 'isStale') { //service-entry
                            splHandling = true; //boolean value. negate it.
                        }
                        filterData[0] = diffField[filterData[0]];
                    }
                    //convert boolean string to boolean
                    /* if(Array.isArray(filterVal)) { */
                        if(filterVal.indexOf("true") > -1 && filterVal.indexOf("false") > -1) {
                            continue;
                        }
                        for(var j=0; j<filterVal.length; j++) {
                            if(["true","false"].indexOf(filterVal[j]) > -1) { 
                                filterVal[j] = isFilterVal(filterVal[j]) 
                                if(splHandling) { filterVal[j] = !filterVal[j]; }
                            }
                        }
                        if(skipFields.indexOf(filterData[0]) > -1) {
                            filters.skipped[filterData[0]] = {'$in':filterVal};
                        } else {
                            filters.valid['$and'].push( {[filterData[0]] : {'$in' : filterVal}} );
                        }
                    /* } else {
                        //should ideally not enter here..
                        if(["true","false"].indexOf(filterVal) > -1) { filterVal = isFilterVal(filterVal) }
                        filters.valid['$and'].push( {[filterData[0]] : filterVal} );
                    } */
                
                }
            }
        }
        filters = filters.valid['$and'].length>0 || filters.skipped != {} ? filters : undefined;
        console.log('updated filter condition: '+JSON.stringify(filters));
        return filters;
    }

    //this method is used to check the boolean value only, if present
    function isFilterVal (booleanFilterVal) {
        if(booleanFilterVal != undefined && (booleanFilterVal == "true" || booleanFilterVal == true)) {
            return true;
        } else {
            return false;
        }
    }

});