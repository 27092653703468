define({
  "Template": {
  "version": "1.0",
  "response": {
    "id": "AWjBklIZsIPgh8_7Prcl",
    "name": "radio_24_util_trigger",
    "enabled": true,
    "flattened": true,
    "entityType": "network_device",
    "entity": "F4:DB:E6:86:F5:E0",
    "groupBy": "Unknown",
    "category": "Utilization",
    "severity": "HIGH",
    "summary": "The 2.4 GHz radio 0 on AP DeviceNamePlaceHolder is experiencing high utilization.",
    "scope": "LOCALIZED",
    "priority": "P3",
    "groupId": "radio_util_high_grouping",
    "groupName": "Radio High Utilization (2.4GHz)",
    "rootCause": "AP_RADIO_HIGH_UTIL",
    "timestamp": 1549435500000,
    "description": "The 2.4 GHz radio on the AP DeviceNamePlaceHolder has exceeded the radioChannelUtilizationAP% threshold and is currently experiencing currVal% utilization. This is impacting 1 client(s).",
    "suggestions": [
      {
        "message": "Migrate clients to 5 GHz since that band is less vulnerable to interference.",
        "steps": [

        ]
      },
      {
        "message": "Check the neighbor APs on the same channel. High traffic on their clients will cause higher interference.",
        "steps": [

        ]
      },
      {
        "message": "Consider adding 5 GHz radio coverage to this location if not available.",
        "steps": [

        ]
      },
      {
        "message": "Disable 2.4 GHz on some access points to reduce co-channel interferences.",
        "steps": [

        ]
      },
      {
        "message": "Check for the presence of rogue APs (show rogue ap summary) and rogue clients (show rogue client summary) on these APs channels. ",
        "steps": [

        ]
      },
      {
        "message": "Use the show 802.11b cleanair device ap DeviceNamePlaceHolder command to check the CleanAir Interference Severity Index (ISI) on this AP, and the RSSI from other APs.",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "show 802.11b cleanair device ap DeviceNamePlaceHolder",
            "command": "show 802.11b cleanair device ap DeviceNamePlaceHolder",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Consider enabling Optimized Roaming on the WLC using - https://www.cisco.com/c/en/us/td/docs/wireless/controller/8-5/config-guide/b_cg85/client_roaming.html",
        "steps": [

        ]
      }
    ],
    "additionalParams": [
      {
        "key": "__key__",
        "value": "NetworkDevice:F4:DB:E6:86:F5:E0:0"
      },
      {
        "key": "sourceDeviceType",
        "value": "1"
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "__entity_type__",
        "value": "NetworkDevice"
      },
      {
        "key": "channel",
        "value": "1.0"
      },
      {
        "key": "threshold",
        "value": "70"
      },
      {
        "key": "utilization",
        "value": "80.0"
      },
      {
        "key": "entityId",
        "value": "F4:DB:E6:86:F5:E0"
      },
      {
        "key": "slot",
        "value": "0"
      },
      {
        "key": "type",
        "value": "Issue"
      },
      {
        "key": "priority",
        "value": "P3"
      },
      {
        "key": "macAddress",
        "value": "F4:DB:E6:86:F5:E0"
      },
      {
        "key": "entityName",
        "value": "macAddr"
      },
      {
        "key": "rootCause",
        "value": "AP_RADIO_HIGH_UTIL"
      },
      {
        "key": "apname",
        "value": "AP4800.8DEC"
      },
      {
        "key": "category",
        "value": "Utilization"
      },
      {
        "key": "timestring",
        "value": "2019-02-06T06:45:00.000+0000"
      },
      {
        "key": "__suppression__",
        "value": "1800"
      },
      {
        "key": "radioSlot",
        "value": "1"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}
})