define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/HostIssueListing',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/HostIssueSummaryTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/DeviceIssueListing',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/DeviceIssueSummaryTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/host1/WirelessHostListing',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/host1/WirelessHostListing_more1',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/host1/WiredHostListing',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/user/UserTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/SimulationUtility',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/DefaultConfig',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimHostIssues',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimDeviceIssues',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimDeviceTrigger',
     'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/HostIssueTimings',
     'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/HostIssueTimings_Devices',
     'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/Application',
     'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/application/ClientAppConfig',
     'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/CustomIssueListing',
     'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/host1/AdditionalWiredHosts',

], function (SimLokiDatabaseActions, HostIssueListing, HostIssueSummaryTemplate, DeviceIssueListing,
             DeviceIssueSummaryTemplate, WirelessHostListing, WirelessHostListing_more1, WiredHostListing, UserTemplate,
             UtilityFunctions, SimulationUtility, DefaultConfig, SimHostIssues, SimDeviceIssues, SimDeviceTrigger,
             HostIssueTimings, HostIssueTimings_Devices, Application, ClientAppConfig, CustomIssueListing, AdditionalWiredHosts
           ) {

 var deviceRenameList= {"Access_1":"p1.edge1-sda1.local", "WAN_EDGE1" :"border_cp_sda.local","CAMPUS-CORE1": "C9404R-border_cp_sda.local"};
  return {

      init: function() {

          var tUsers = SimLokiDatabaseActions.getAll('user');
          if(tUsers.length == 0) {
              //this.createAssuranceHostsAndUser();
              this.createUsers();
          }

          var tApplication = SimLokiDatabaseActions.getAll('application');
          if(tApplication.length == 0) {
            this.createApplication();
          }


          tIssues = SimLokiDatabaseActions.getAll('issue'); //NOTE: change here
          if(tIssues.length == 0) {
            //  this.updateDeviceName();
              this.createHostIssues();
              this.createDeviceIssues();
              this.createIssueTimings();
              this.createIssueTimingsForDevice();
          }

          this.createIcapCaptures()

          //console.log('DEBUG:hit', SimLokiDatabaseActions.getAll('issue'))
          let allIssues = SimLokiDatabaseActions.getAll('issue')
          let uniqueIssues = Array.from(new Set(allIssues.map(function(d){return d.rootCause})))
                                    .map(function(d){return allIssues.find(function(e){return e.rootCause===d})})
                                    .map(function(d) {return d.summary})
          var topTenIssues = [
              'Fabric Edge 10.32.168.29 Lost Connectivity to the ISE Server dcloud-ise-ctf-inst-xxx.cisco.com in the Physical Network',
              'This Device is Experiencing TCAM Exhaustion 97.0%. SGACL TCAM Usage is 3976/512',
              'Interface GigabitEthernet1/7 is Down on Network Device "10.13.6.2"',
              'Host flaps observed in 1 VLAN(s)',
              'Wireless client failed to connect (SSID: c9800AP11AC, AP: AP70B3.1733.D6D4, Band: 2.4 GHz) - AAA Server Rejected Client',
              'Wireless client failed to connect (SSID: @CorpSSID, AP: AP00F2.8B27.B788, Band: 2.4 GHz, Site: Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1) - Failed to authenticate due to Client Timeout',
              'Wireless client failed to connect (SSID: c9800AP11AC, AP: AP70B3.1733.D6D4, Band:2.4 GHz) - Security Parameter Mismatch',
              '	Unreachable Web server from "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1"',
              'Failed to load page from Web Servers from "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1"',
              'Clients are failing web authentication test from location "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1"'
          ]

          uniqueIssues.forEach(function (issueName, index) {
              if (topTenIssues.indexOf(issueName) > -1) {
                  uniqueIssues.splice(topTenIssues.indexOf(issueName), 1)
              }
          })

          uniqueIssues = topTenIssues.concat(uniqueIssues);
          
          $.ajax({
            type: 'POST',
            url: '/simulation/data/issues',
            data: JSON.stringify(uniqueIssues),
            success: function(data) { console.log('DEBUG: set sim issue data; ' + data); },
            contentType: "application/json",
            dataType: 'json'
        });

	//to map the deviceIds in service now portal - start
        let devices = SimLokiDatabaseActions.getAll('network-device');
        let devIds = {};
        devices.forEach(e => {
            devIds[e.hostname] = e.id;
        });

        $.ajax({
            type: 'POST',
            url: '/simulation/data/deviceIds',
            data: JSON.stringify(devIds),
            success: function(data) { console.log('DEBUG: set deviceIds data; ' + data); },
            contentType: "application/json",
            dataType: 'json'
        });
	//to map the deviceIds in service now portal - end

      },

      // update the device name for fabric related usecases

      updateDeviceName : function() {

       var deviceList = ["Access_1","WAN_EDGE1","CAMPUS-CORE1"] ;
       for(var i=0; i<deviceList.length;i++) {

            var device =  SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'name': deviceList[i]});

            if(device.length>0 && deviceRenameList[device[0].hostname] !=undefined) {
                // Updating the device name in network collection
                device[0].name= deviceRenameList[device[0].hostname];
                device[0].hostname= deviceRenameList[device[0].hostname];
                SimLokiDatabaseActions.update('network-device', device);

                // updating the device name in device info

               var deviceObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', {'name': deviceList[i]});

               deviceObj[0].name= deviceRenameList[deviceObj[0].name];
               SimLokiDatabaseActions.update('DeviceInfo', deviceObj);

                // updating the device name in device config

                var deviceConfigObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceConfigStatus', {'hostname': deviceList[i]});

                deviceConfigObj[0].hostname= deviceRenameList[deviceConfigObj[0].name];
                SimLokiDatabaseActions.update('DeviceConfigStatus', deviceConfigObj);


                // updating the device nmae in topology


            }

       }

      },

      createIcapCaptures:function() {

        let connectedClients = SimLokiDatabaseActions.getFilteredRecordHandler('host', {"connectionStatus":"CONNECTED","hostType":"WIRELESS"}).slice(0,4)

        //in progress captures
        SimLokiDatabaseActions.insert( 'icap-captures', {"type":"SCHEDULED","command":null,"state":"RUNNING","errorString":"","clientMacs":[connectedClients[0].hostMac],"clientDetails":[{"macAddress":connectedClients[0].hostMac,"hostName":"","userId":""}],"startTimeSecs":Math.floor(new Date() / 1000)-15082,"configDuration":28800,"actualDuration":15082,"remainingDuration":13718,"sessionId":0,"spectrumSlot":0,"owner":"admin"});

        //completed captures
        connectedClients.slice(1,4).forEach((e,i)=>{
          SimLokiDatabaseActions.insert( 'icap-captures', {"type":"LIVE","command":null,"state":"COMPLETED","errorString":"","clientMacs":[e.hostMac],"clientDetails":[{"macAddress":e.hostMac,"hostName":"","userId":""}],"startTimeSecs":Math.round(Date.now()/1000)-(i+1)*77000,"configDuration":10800,"actualDuration":10800,"remainingDuration":0,"sessionId":i+1,"spectrumSlot":0,"owner":"admin"})
        })

        //ap captures
        //let rfCap1 = {"type":"ICAP_RF_STATS","command":"NO_ACTION","state":"RUNNING","errorString":"","startTimeSecs":1558664514,"configDuration":0,"actualDuration":468450,"remainingDuration":0,"sessionId":5,"spectrumSlot":0,"owner":"admin"}
        let rfCap2 = {apName:['AP4800'],"type":"ICAP_RF_STATS","command":"NO_ACTION","state":"RUNNING","errorString":"","startTimeSecs":1558664514,"configDuration":0,"actualDuration":468450,"remainingDuration":0,"sessionId":6,"spectrumSlot":0,"owner":"admin"}
        let anomalyCap = {"type":"ICAP_ANOMALY","command":"NO_ACTION","state":"RUNNING","errorString":"","startTimeSecs":1558664558,"configDuration":0,"actualDuration":469568,"remainingDuration":0,"sessionId":7,"spectrumSlot":0,"owner":"admin"}

        //SimLokiDatabaseActions.insert( 'icap-captures',rfCap1)
        SimLokiDatabaseActions.insert( 'icap-captures',rfCap2)
        SimLokiDatabaseActions.insert( 'icap-captures',anomalyCap)

      },

      createUsers: function() {
          var userMap = new Object();
          var hosts = getHosts();
          hosts = hosts.filter(function(v){return v.userId != null && v.userId != '' });

          for(i = 0; i < hosts.length; i++) {
             var userId = hosts[i].userId;
             if(userMap[userId] == undefined) {
                 var tUser = JSON.parse(JSON.stringify( UserTemplate.User_Template ));
                 tUser.id = userId;
                 tUser.properties.userId[0].value = userId;
                 SimLokiDatabaseActions.insert( 'user', tUser );
                 userMap[userId] = userId;
             }
          }
      },

      createApplication:function(){
        var tJson = JSON.parse(JSON.stringify(Application.App_Template));
        var appConfig = JSON.parse(JSON.stringify(ClientAppConfig.Client_App_Template));
        var locations = getValidLocations(), randInt=0;
        var graceApps = ["ssh", "ms-office-365", "webex", "MedicalRecords", "ms-teams", "microsoft-teams"];
        for(var i=0;i<tJson.length;i++) {
            tJson[i].hostNames =[];
            randInt = UtilityFunctions.getRandomIntForRangeValues(0, locations.length-1);
            var location = graceApps.indexOf(tJson[i].applicationName) > -1 ? "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1" : locations[randInt];
            var site= SimLokiDatabaseActions.getFilteredRecordHandler('site', {'groupNameHierarchy':location });
            tJson[i].clientSiteId=site[0].id;
            var idx = location.indexOf('/');
            tJson[i].sourceLocation = location.substring(idx+1);
            for(var j=0;j<appConfig.length;j++) {
                if(appConfig[j].applicationName==tJson[i].applicationName )
                    tJson[i].hostNames =appConfig[j].hostNames;
            }
            var device = getBorderRouterDetail(location);
            tJson[i].exporterIpAddress = device.managementIpAddress;
            tJson[i].exporterUUID = device.id;
            tJson[i].exporterSiteId = device.siteId;
            tJson[i].exporterName = device.hostname;
            tJson[i].exporterFamily = device.family;
            if(tJson[i].children && tJson[i].children.length != 0){
                tJson[i].children.map((childApp) => {
                    childApp.exporterIpAddress = device.managementIpAddress;
                    childApp.exporterUUID = device.id;
                    childApp.exporterSiteId = device.siteId;
                    childApp.exporterName = device.hostname;
                    childApp.exporterFamily = device.family;
                })
            }
            SimLokiDatabaseActions.insert('application', tJson[i]);
        }
    },


      createHosts: function() {
          var hosts = getHosts();
          return { hosts: hosts };
      },

      createHostIssues: function() {
          for(var issue in HostIssueListing.issuesTemplate) {
              var issueDetail = SimHostIssues.getHostIssue(issue);
              if(issueDetail != undefined) {
                  createIssueForHost(HostIssueListing.issuesTemplate[issue], issueDetail.Template.response);
              }
          }
      },

      createIssueTimings: function() {

          var issueTimings = JSON.parse(JSON.stringify( HostIssueTimings.Issue_Time_Template));

          SimLokiDatabaseActions.insert( 'sim-issue-timing', issueTimings );
      },

      createIssueTimingsForDevice: function(){
          var deviceissueTimings = JSON.parse(JSON.stringify(HostIssueTimings_Devices.Issue_Time_Device_Template))

          SimLokiDatabaseActions.insert('sim-issue-timing-device', deviceissueTimings);
      },

      createDeviceIssues: function() {

         updatingAPDeviceIssues();
          for(var issueNameAsKey in DeviceIssueListing.issuesTemplate) {
              if( createIssueForDevice(DeviceIssueListing.issuesTemplate[issueNameAsKey], issueNameAsKey) ) {

                  if(DeviceIssueListing.issuesTemplate[issueNameAsKey].triggerExists) {
                      var triggerDetail = SimDeviceTrigger.getDeviceTrigger(issueNameAsKey);

                      if(triggerDetail != undefined) {
                          var  issueList = SimLokiDatabaseActions.getFilteredRecordHandler('issue', {'issueNamekey': issueNameAsKey});
                          for(var i=0;i<issueList.length;i++) {
                            var data = JSON.parse(JSON.stringify( triggerDetail.Template ));
                            data.id= issueList[i].id;
                            console.log(data);
                            SimLokiDatabaseActions.insert('issue-trigger', data);
                          }
                      }
                  }
              }
          }
      },

      createCustomIssue: function(issueName, role, isEnabled) {
          for(var issueNameAsKey in CustomIssueListing.issuesTemplate) {
              if(issueName == issueNameAsKey && isEnabled) {
                  //create custom issue and update with new values
                  //no overlapping issues across role, for issues with threshold, so far.
                  if(createIssueForDevice(CustomIssueListing.issuesTemplate[issueNameAsKey], issueNameAsKey, true)) {
                    if(CustomIssueListing.issuesTemplate[issueNameAsKey].triggerExists) {
                        var triggerDetail = SimDeviceTrigger.getDeviceTrigger(issueNameAsKey);
                        if(triggerDetail != undefined) {
                            var  issueList = SimLokiDatabaseActions.getFilteredRecordHandler('customIssue', {'issueNamekey': issueNameAsKey});
                            for(var i=0;i<issueList.length;i++) {
                                var data = JSON.parse(JSON.stringify( triggerDetail.Template ));
                                data.id= issueList[i].id;
                                console.log(data);
                                SimLokiDatabaseActions.insert('issue-trigger', data);
                            }
                        }
                    }
                  } 
              }
          }
      },

      deleteCustomIssue: function(issueName, role) {
        //delete existing custom issue for the issue
        //no overlapping issues across role, for issues with threshold, so far.
        var records1 = SimLokiDatabaseActions.getFilteredRecordHandler('customIssue', {'name':issueName});
        for(var i=0; i<records1.length; i++) {
            var records2 = SimLokiDatabaseActions.getFilteredRecordHandler('customAssuranceissue-detail', {'id':records1[i].id});
            SimLokiDatabaseActions.delete('customAssuranceissue-detail', records2);
        }
        SimLokiDatabaseActions.delete('customIssue', records1);
      }
  };

   function sortByProperty (property) {

    return function (x, y) {

        return ((x[property] === y[property]) ? 0 : ((x[property] < y[property]) ? 1 : -1));

    };

   };

  function updatingAPDeviceIssues() {
      var accessDeviceList = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'family':"Unified AP"});
      var recordObj=[];

         for(var i = 0; i < accessDeviceList.length; i++) {
              var hosts = SimLokiDatabaseActions.getFilteredRecordHandler('host',{'connectedNetworkDeviceId': accessDeviceList[i].id });
              var obj = {"id":accessDeviceList[i].id,"name":accessDeviceList[i].hostname,"count":hosts.length};
              recordObj.push(obj);
         }

      recordObj.sort(sortByProperty("count"));
      // adding issues for 2.4
      var  ap_24_list = DeviceIssueListing.issuesTemplate["radio_util_trigger"].devices;
      var  custom_ap_list = CustomIssueListing.issuesTemplate["radio_util_trigger"].devices;
      var customApDevices = [];
      for(var i=0; i<custom_ap_list.length; i++) {
          customApDevices.push(custom_ap_list[i]);
      }

      var   apList =0;
      for(var i = 0; i < recordObj.length; i++){

         var client_List = SimLokiDatabaseActions.getFilteredRecordHandler('host',{'$and': [ {
                                                                                                  'connectedNetworkDeviceId': recordObj[i].id},
                                                                                                   { 'frequency':"2.4" }]});
          if(client_List.length>0 && apList<2 && customApDevices.indexOf(recordObj[i].name)==-1) {
            var flag = true;
            apList = apList+1;
            for(var j = 0; j < ap_24_list.length; j++){
               if(ap_24_list[j].hostName==recordObj[i].name || recordObj[i].name == "SJC01_9136_1") {
                 flag =false;
               }
            }
            if(flag) {
             DeviceIssueListing.issuesTemplate["radio_util_trigger"].devices.push({hostName: recordObj[i].name, daysOld: 1});
            }

          }
        }
       // adding issues for 5
      var  ap_5_list = DeviceIssueListing.issuesTemplate["radio_util_trigger_ap"].devices;
      var   apList =0;
      for(var i = 0; i < recordObj.length; i++){

         var client_List = SimLokiDatabaseActions.getFilteredRecordHandler('host',{'$and': [ {
                                                                                                  'connectedNetworkDeviceId': recordObj[i].id},
                                                                                                   { 'frequency':"5.0" }]});
          if(client_List.length>0 && apList<2 && customApDevices.indexOf(recordObj[i].name)==-1) {
            var flag = true;
            apList = apList+1;
            for(var j = 0; j < ap_5_list.length; j++){
               if(ap_5_list[j].hostName==recordObj[i].name || recordObj[i].name == "SJC01_9136_1") {
                 flag =false;
               }
            }
            if(flag) {
             DeviceIssueListing.issuesTemplate["radio_util_trigger_ap"].devices.push({hostName: recordObj[i].name, daysOld: 1});
            }

          }
        }

  }



  function createIssueForHost(issueInfo, issueDetail) {
      var curTime = UtilityFunctions.getTimeStamp();
      var lastOccurenceTime = curTime-(60*30*1000)
      var incrementTime = 0;
      var startTime = UtilityFunctions.getDateInTimestamp_SubtractedByDays_FromCurrentDate(1);
      startTime = new Date(startTime);
      startTime.setHours(23);
      startTime.setMinutes(59);

      for(var i = 0; i < issueInfo.hostNames.length; i++) {
          var host = SimLokiDatabaseActions.getFilteredRecordHandler("host", {'hostName' : issueInfo.hostNames[i] });
          if(host.length == 0) {
              continue;
          }

          for(var j = 0; j < issueDetail.length; j++) {

              var tIssueSummary = JSON.parse(JSON.stringify( HostIssueSummaryTemplate.Template ));
              //var issueId = UtilityFunctions.incrementSerialNo(issueDetail.id, i + 1);//original -- CAUTION
              var issueId = UtilityFunctions.generate_uuid();

              var record = SimLokiDatabaseActions.getFilteredRecordHandler("site", {'name' : 'Flr-SJC1-1' });
              var siteId = record.length ? record[0].id :  'f4fa7637-85d5-4301-9336-5c3d81c7c058';

              var tSummary = issueDetail[j].summary;
              tSummary = tSummary.replace(/SSID@ToChange/g, host[0].ssid );
              //tSummary = tSummary.replace(/AP@ToChange/g, host[0].apGroup );
              tSummary = tSummary.replace(/AP@ToChange/g, host[0].clientConnection );

              tSummary = tSummary.replace(/Band@ToChange/g, host[0].frequency );
              tSummary = tSummary.replace(/Site@ToChange/g, host[0].location );
              tSummary = tSummary.replace(/VlanId@ToChange/g, host[0].vlanId );
              tSummary = tSummary.replace(/MacAddress@ToChange/g, host[0].id);

              tSummary = tSummary.replace(/Wlc@ToChange/g, DefaultConfig.Wlc_Provision_list[0] );
              //kairosId
              tIssueSummary.isAiDriven = (issueInfo.AiDriven == undefined)?false :true;
              tIssueSummary.kairosId = (tIssueSummary.isAiDriven === true)?issueDetail[j].entity:""

              tIssueSummary.id = issueId;
              tIssueSummary.name = issueDetail[j].name;
              tIssueSummary.entity = host[0].id;
              tIssueSummary.groupBy = siteId;
              tIssueSummary.category = issueDetail[j].category;
              tIssueSummary.severity = issueDetail[j].severity;
              if(issueDetail[j].priority && issueDetail[j].priority!=null) {
                tIssueSummary.priority = issueDetail[j].priority;

              }
              if(issueDetail[j].entityType=="Sensor") {
                tIssueSummary.entityType ="Sensor"
              }
              tIssueSummary.summary = tSummary;

              tIssueSummary.rootCause = issueDetail[j].rootCause;
              tIssueSummary.timestamp = curTime;//new Date().getTime();
              tIssueSummary.status.status = issueDetail[j].status.status.toLowerCase();
              tIssueSummary.totalOccurrences.startTime = startTime;
              tIssueSummary.totalOccurrences.endTime = curTime;
              var issueCnt = UtilityFunctions.getRandomIntForRangeValues( 22, 70);
              tIssueSummary.totalOccurrences.count = issueCnt;
              tIssueSummary.occurrences = issueCnt;
              tIssueSummary.scope = issueDetail[j].scope;
              tIssueSummary.device = host[0].hostMac;
              tIssueSummary.deviceId = host[0].hostMac;
              tIssueSummary.deviceName = host[0].hostName;
              tIssueSummary.instances = issueCnt;
              //to map lastoccurence parameter in issues-open , and shows vary in timestamp for issue
              tIssueSummary.lastOccurence = UtilityFunctions.incrementDateByMinutes(lastOccurenceTime, incrementTime);
              incrementTime -= 5;

              //tIssueSummary.deviceRole = host[0].role;


              SimLokiDatabaseActions.insert('issue', tIssueSummary);

              var issueDetail1 = JSON.parse(JSON.stringify(issueDetail[j]));
              issueDetail1.id = issueId;
              issueDetail1.entity = host[0].id;
              issueDetail1.groupBy = siteId;
              issueDetail1.timestamp = startTime;
              issueDetail1.summary = tSummary;
              issueDetail1.status.status = issueDetail[j].status.status.toLowerCase();

              // Added for 1.2.5
              tIssueSummary.historyOccurrences.startTime = startTime;
              tIssueSummary.historyOccurrences.endTime = curTime;
              tIssueSummary.historyOccurrences.count = issueCnt;

             // issueDetail1.historyOccurrences =tIssueSummary.historyOccurrences;

              var tDesc = issueDetail1.description;
              tDesc = tDesc.replace(/SSID@ToChange/g, host[0].ssid );
              //tDesc = tDesc.replace(/AP@ToChange/g, host[0].apGroup );
              tDesc = tDesc.replace(/AP@ToChange/g, host[0].clientConnection);

              tDesc = tDesc.replace(/Band@ToChange/g, host[0].frequency );
              tDesc = tDesc.replace(/Site@ToChange/g, host[0].location );
              tDesc = tDesc.replace(/VlanId@ToChange/g, host[0].vlanId );

              tDesc = tDesc.replace(/Wlc@ToChange/g, DefaultConfig.Wlc_Provision_list[0] );
              tDesc = tDesc.replace(/MacAddress@ToChange/g, host[0].id);
              issueDetail1.description = tDesc;


              var tSugg = JSON.stringify(issueDetail1.suggestions);
              tSugg = tSugg.replace(/SSID@ToChange/g, host[0].ssid );
              tSugg = tSugg.replace(/Wlc@ToChange/g, DefaultConfig.Wlc_Provision_list[0] );
              issueDetail1.suggestions = JSON.parse(tSugg);



              SimLokiDatabaseActions.insert('assuranceissue-detail', issueDetail1);
          }
      }
  }

  function createIssueForDevice(issueInfo, issueNameAsKey, isCustumIssue) {
      var t = SimDeviceIssues.getDeviceIssue(issueNameAsKey);
      if(t == undefined) {
          return false;
      }

      var issueData = t.Template.response;

      var curTime = UtilityFunctions.getTimeStamp();
      var lastOccurenceTime = curTime-(60*30*1000)
      var incrementTime = 0;
      //var issueStartDate = daysOld;
      //var startTime = UtilityFunctions.getDateInTimestamp_SubtractedByDays_FromCurrentDate(1);

      for(var i = 0; i < issueInfo.devices.length; i++) {
          var  issueDetail =JSON.parse(JSON.stringify( issueData ));
          var tIssueSummary = JSON.parse(JSON.stringify( DeviceIssueSummaryTemplate.Template ));
          //id format changed in 1.3.1 (5bf49596-da05-4ea3-be90-9b11b273d3fe)
          var issueId = UtilityFunctions.generate_uuid();//UtilityFunctions.incrementSerialNo(issueDetail.id, i + 1);
          if(isCustumIssue) {
            issueId =  UtilityFunctions.generate_uuid();//UtilityFunctions.incrementSerialNo(issueDetail.id, i + 10);
          }
         // var issueId = issueDetail.id;

           // adding hack for device name change
      /*    if(deviceRenameList[issueInfo.devices[i].hostName] !=undefined) {
              issueInfo.devices[i].hostName=deviceRenameList[issueInfo.devices[i].hostName];
          } */

          var obj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': issueInfo.devices[i].hostName});
          var deviceSiteInfo = obj[0] ? SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id': obj[0].siteId }): {};
          if( ! obj.length) {
              continue;
          }
            //collecting interface connected to specific device
          if(obj != undefined && obj[0].family != "Unified AP"){
              var interfaceRec = SimLokiDatabaseActions.getFilteredRecordHandler('device-interface',{'deviceId': obj[0].id });
              var interfaceUp = interfaceRec.filter(obj => obj.status === 'up');
              var interfaceDown = interfaceRec.filter(obj => obj.status === 'down');
          }

          var siteId = obj[0].siteId;
          var entityValue = obj[0][issueInfo.identifier].toUpperCase();

          var issueNoOfOldDays = issueInfo.devices[i].daysOld;
          var startTime = UtilityFunctions.getDateInTimestamp_SubtractedByDays_FromCurrentDate(issueNoOfOldDays);
          startTime = new Date(startTime);
          startTime.setHours(23);
          startTime.setMinutes(59);
          if(isCustumIssue) {
            startTime = new Date();//current time onwards
          }

          //var issueEndDate = issueInfo.devices[i].daysOld;
          //var endTime = UtilityFunctions.getDateInTimestamp_SubtractedByDays_FromCurrentDate(issueEndDate);
          var endTime = new Date();
          /*endTime.setHours(23);
          endTime.setMinutes(59);*/
          if(isCustumIssue) {
            endTime = new Date();//current time
          }


          issueDetail.summary = issueDetail.summary.replace(/DeviceNamePlaceHolder/g, issueInfo.devices[i].hostName );
          issueDetail.summary = issueDetail.summary.replace(/DeviceIPPlaceHolder/g, entityValue );
          issueDetail.summary = issueDetail.summary.replace(/SiteNamePlaceHolder/g, deviceSiteInfo[0].groupNameHierarchy );
          if(obj[0].family != "Unified AP"){
              issueDetail.summary = issueDetail.summary.replace(/InterfaceUpPlaceHolder/g, interfaceUp[0].portName);
              issueDetail.summary = issueDetail.summary.replace(/InterfaceDownPlaceHolder/g, interfaceDown[0].portName);
              if(interfaceDown && interfaceDown.length>1){
                issueDetail.summary = issueDetail.summary.replace(/PeerInterfacePlaceHolder/g, interfaceDown[1].portName);
              }
          }

          if(isCustumIssue) {
              //update with custom threshold values, if present
            var records = SimLokiDatabaseActions.getFilteredRecordHandler('issueSettingCustomised', {'issueName':issueNameAsKey});
            if(records && records.length) {
                for(var j=0; j<records.length; j++) {
                    if(records[j].kpi) {
                        var key = new RegExp(records[j].kpi,'g')
                        var val = (records[j].threshold+5 > 100)? records[j].threshold : records[j].threshold+5;
                        issueDetail.summary = issueDetail.summary.replace(key, records[j].threshold);
                        issueDetail.summary = issueDetail.summary.replace('currVal', val);
                        issueDetail.description = issueDetail.description.replace(key, records[j].threshold);
                        issueDetail.description = issueDetail.description.replace('currVal', val);
                    }
                }
            }
          }
          if(issueInfo.defThresholdVals) {
              //if threshold is not customised, use the default
            for(var j=0; issueInfo.defThresholdVals && j<issueInfo.defThresholdVals.length; j++) {
                var key = new RegExp(issueInfo.defThresholdVals[j].key,'g')
                issueDetail.summary = issueDetail.summary.replace(key, issueInfo.defThresholdVals[j].value);
                issueDetail.description = issueDetail.description.replace(key, issueInfo.defThresholdVals[j].value);
            }
          }

          //kairos Id
          tIssueSummary.isAiDriven = (issueInfo.AiDriven == undefined)? false : true;
          tIssueSummary.kairosId = issueDetail.entity

          tIssueSummary.id = issueId;
          tIssueSummary.name = issueDetail.name;
          tIssueSummary.entityType = 'Device';
          tIssueSummary.entity = entityValue;

          if(issueDetail.priority && issueDetail.priority!=null) {
                tIssueSummary.priority = issueDetail.priority;
          }

          tIssueSummary.groupBy = siteId;
          tIssueSummary.category = issueDetail.category;
          tIssueSummary.severity = issueDetail.severity;
          tIssueSummary.summary = issueDetail.summary;
          tIssueSummary.rootCause = issueDetail.rootCause;
          tIssueSummary.timestamp = endTime; //new Date().getTime();
          tIssueSummary.status.status = issueDetail.status.status.toLowerCase();
          var issueCnt = UtilityFunctions.getRandomIntForRangeValues( 44, 70);
          tIssueSummary.totalOccurrences.count = issueCnt;
          tIssueSummary.totalOccurrences.startTime = startTime;
          tIssueSummary.totalOccurrences.endTime = endTime;//curTime;

          tIssueSummary.historyOccurrences.count = issueCnt;
          tIssueSummary.historyOccurrences.startTime = startTime;
          tIssueSummary.historyOccurrences.endTime = endTime;//curTime;
          tIssueSummary.occurrences=issueCnt;
          tIssueSummary.scope=issueDetail.scope;

          tIssueSummary.device = obj[0].macAddress;
          tIssueSummary.deviceId = obj[0].instanceUuid;
          tIssueSummary.deviceName = obj[0].hostname;
          tIssueSummary.deviceRole = obj[0].role;
          tIssueSummary.instances = issueCnt;
          //to map lastoccurence parameter in issues-open , and shows vary in timestamp for issue
          tIssueSummary.lastOccurence = UtilityFunctions.incrementDateByMinutes(lastOccurenceTime, incrementTime);
          incrementTime -= 5;



          // adding key to set same issue id for device trigger

          tIssueSummary.issueNamekey = issueNameAsKey;

          if(!isCustumIssue) {
              SimLokiDatabaseActions.insert('issue', tIssueSummary);
          } else {
              updateCustomAddinationals(issueDetail, tIssueSummary);
              SimLokiDatabaseActions.insert('customIssue', tIssueSummary);
          }

          issueDetail.id = issueId;
          issueDetail.entity = entityValue;
          issueDetail.groupBy = siteId;
          issueDetail.timestamp = startTime;
          issueDetail.status.status = issueDetail.status.status.toLowerCase();

          var tDesc = JSON.stringify(issueDetail.description);
          tDesc = tDesc.replace(/DeviceNamePlaceHolder/g, issueInfo.devices[i].hostName );
          tDesc = tDesc.replace(/ConnectedWlcNamePlaceHolder/g, DefaultConfig.Wlc_Provision_list[0] );
          if(obj[0].family != "Unified AP"){
            tDesc = tDesc.replace(/InterfaceUpPlaceHolder/g, interfaceUp[0].portName);
            tDesc = tDesc.replace(/InterfaceDownPlaceHolder/g, interfaceDown[0].portName);
            if(interfaceDown && interfaceDown.length>1){
                tDesc = tDesc.replace(/PeerInterfacePlaceHolder/g, interfaceDown[1].portName);
            }
          }
          issueDetail.description = JSON.parse(tDesc);


          var t = JSON.stringify(issueDetail.suggestions);
          var t1 = t.replace(/DeviceIdPlaceHolder/g, obj[0].id );
              t1 = t1.replace(/DeviceNamePlaceHolder/g,  issueInfo.devices[i].hostName);
              if(obj[0].family != "Unified AP"){
                  t1 = t1.replace(/InterfaceUpPlaceHolder/g, interfaceUp[0].portName);
                  t1 = t1.replace(/InterfaceDownPlaceHolder/g, interfaceDown[0].portName);
                  if(interfaceDown && interfaceDown.length>1){
                     t1 = t1.replace(/PeerInterfacePlaceHolder/g, interfaceDown[1].portName);
                  }
              }
          issueDetail.suggestions = JSON.parse(t1);

          if(!isCustumIssue) {
              SimLokiDatabaseActions.insert('assuranceissue-detail', issueDetail);
          } else {
              SimLokiDatabaseActions.insert('customAssuranceissue-detail', issueDetail);
          }
      }

      return true;
  }

  function getHosts() {
      var hosts = [], i;

      for(i = 0; i < WirelessHostListing.HOST_LISTING.response.length; i++) {
          if(WirelessHostListing.HOST_LISTING.response[i].userId != null &&
             WirelessHostListing.HOST_LISTING.response[i].userId != ''&& 
             WirelessHostListing.HOST_LISTING.response[i].hostName != null &&
             WirelessHostListing.HOST_LISTING.response[i].hostName != '') {
                 hosts.push(WirelessHostListing.HOST_LISTING.response[i]);
          }
      }

      for(i = 0; i < WirelessHostListing_more1.HOST_LISTING.response.length; i++) {
          if(WirelessHostListing_more1.HOST_LISTING.response[i].userId != null &&
             WirelessHostListing_more1.HOST_LISTING.response[i].userId != '' &&
             WirelessHostListing_more1.HOST_LISTING.response[i].hostName != null &&
             WirelessHostListing_more1.HOST_LISTING.response[i].hostName != ''
              ) {
              hosts.push(WirelessHostListing_more1.HOST_LISTING.response[i]);
          }
      }
      let additionalHosts = getAdditionalWiredClients();
      hosts = hosts.concat(additionalHosts)
      for(i = 0; i < WiredHostListing.HOST_LISTING.response.length; i++) {
          if(WiredHostListing.HOST_LISTING.response[i].hostName != null &&
             WiredHostListing.HOST_LISTING.response[i].hostName != '') {
              hosts.push(WiredHostListing.HOST_LISTING.response[i]);
          }
      }

      return hosts;
  }

  function updateCustomAddinationals (issueDetail, tIssueSummary) {
    var customDetail = SimLokiDatabaseActions.getFilteredRecordHandler('issueSettingCustomised', {'issueName': issueDetail.name});
    var dtls = {}, kpi, key;
    switch(issueDetail.name) {
        case 'tcam_util_high': 
            kpi = 'tcamUtilization', key = 'max_tcam_util';
            break;
        case 'radio_util_trigger': 
            kpi = 'radioChannelUtilizationAP', key = 'utilization';
            break;
    }

    for(var j=0; j<customDetail.length; j++) {
        if(customDetail[j].kpi == kpi) {
            dtls['threshold'] = customDetail[j].threshold;
        }
        if(customDetail[0].priority) {
            dtls['priority'] = customDetail[0].priority;
            tIssueSummary.priority = customDetail[0].priority;
        }
    }
    if(Object.keys(dtls).length == 0) {
        return;//nothing to update..
    }
    for(var i=0; i<issueDetail.additionalParams.length; i++){
        if(dtls['threshold'] != undefined) {
            if (issueDetail.additionalParams[i].key == "threshold") {  
                issueDetail.additionalParams[i].value = dtls['threshold'].toString();
            } else if (issueDetail.additionalParams[i].key == key) {
                issueDetail.additionalParams[i].value = (parseFloat(dtls['threshold'])+5).toString();
            }
        }
        if(dtls['priority'] != undefined && issueDetail.additionalParams[i].key == "priority") {
            issueDetail.additionalParams[i].value = dtls['priority'];
        }
    }

  }
    function getValidLocations() {
        var locations = [], apLocs = [], clientLocs = [], childrenSites = [];
        //the location should have border router
        var records = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'role':'BORDER ROUTER'});
        for(var i=0; i<records.length; i++) {
            if(locations.indexOf(records[i].siteId)==-1) locations.push(records[i].siteId);
        }
        //get the children site as clients are at floor level
        for(var i=0; i<locations.length; i++) {
            SimulationUtility.getChildrenSites(locations[i], childrenSites);
        }
        for (var i = 0; i < childrenSites.length; i++) {
            locations.push(childrenSites[i].id);
        }

        //the location should have AP. This will also take care of floor level site.
        records = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'family':'Unified AP'});
        apLocs = records.map(ap => ap.siteId);
        locations = locations.filter(e => apLocs.indexOf(e)>-1);

        records = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id':{'$in':locations}});
        locations = records.map(e => e.groupNameHierarchy);

        //the location should have clients
        records = SimLokiDatabaseActions.getAll('host');
        clientLocs = records.map(client => client.location);
        locations = locations.filter(e => clientLocs.indexOf(e)>-1);
        return locations;
    }

    function getBorderRouterDetail(appLocation) {
        var location = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'groupNameHierarchy':appLocation});
        var parentLocations = [], parentId = location[0].parentId;
        parentLocations.push(location[0].id);
        while (parentId) {
            parentLocations.push(parentId);
            location = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id':parentId});
            parentId = location.length>0?location[0].parentId:undefined;
        }
        var records = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',
          {'$and': [{'role':'BORDER ROUTER'}, {'siteId':{'$in':parentLocations}}]
        });
        return records.length>0?records[0]:null;
    }
    function getAdditionalWiredClients(){
        let tJson = JSON.parse(JSON.stringify(AdditionalWiredHosts.Template))
        let newClients = [];
        let WiredHostList = [{'refClient':'0022BDDC3D49','hostNames':["0022BDDC3D50", "0022BDDC3D51", "0022BDDC3D52",
         "0022BDDC3D53", "0022BDDC3D54", "0022BDDC3D55", "0022BDDC3D56", "0022BDDC3D57",
        "0022BDDC3D58","0022BDDC3D59","0022BDDC3D60","0022BDDC3D61","0022BDDC3D62","Camera1","Camera2"],'macAddress':['00:22:BD:DC:3D:50','00:22:BD:DC:3D:51','00:22:BD:DC:3D:52',
        '00:22:BD:DC:3D:53','00:22:BD:DC:3D:54','00:22:BD:DC:3D:55','00:22:BD:DC:3D:56','00:22:BD:DC:3D:57','00:22:BD:DC:3D:58',
        '00:22:BD:DC:3D:59','00:22:BD:DC:3D:60','00:22:BD:DC:3D:61','00:22:BD:DC:3D:62','00:22:BD:DC:3D:63','0C:75:BD:2D:5D:C1'],'subType':'VIDEO_SURVEILLANCE_IP_CAMERA'},
        {'refClient':'SEP189C5D21F515','hostNames':["SEP189C5D21F516", "SEP189C5D21F517", "SEP189C5D21F518", "SEP189C5D21F519",
         "SEP189C5D21F520", "SEP189C5D21F521", "SEP189C5D21F522", "SEP189C5D21F523","SEP189C5D21F524",
        "SEP189C5D21F525","SEP189C5D21F526"],'macAddress':['F0:25:72:78:8A:70','F0:25:72:78:8A:71','F0:25:72:78:8A:72',
        'F0:25:72:78:8A:73','F0:25:72:78:8A:74','F0:25:72:78:8A:75','F0:25:72:78:8A:76','F0:25:72:78:8A:77',
        'F0:25:72:78:8A:78','F0:25:72:78:8A:79','F0:25:72:78:8A:80'],'subType':'IP_PHONE'}]

        for(let i=0;i<WiredHostList.length;i++){
            let hostLength = WiredHostList[i].hostNames.length;
            for(let e=0; e<hostLength; e++){
                    tJson.hostName = WiredHostList[i].hostNames[e]
                    tJson.id = WiredHostList[i].macAddress[e];
                    tJson.hostMac = WiredHostList[i].macAddress[e];
                    tJson.hostOs = WiredHostList[i].subType;
                    tJson.subType = WiredHostList[i].subType;
                    tJson.hostIpV4 = UtilityFunctions.incrementIpAddrres(tJson.hostIpV4, e);
                    if(WiredHostList[i].hostNames[e] == "Camera2") {
                        tJson.healthScore[0].score = 1;
                    }
                    newClients.push(JSON.parse(JSON.stringify(tJson)))
                    tJson = JSON.parse(JSON.stringify(AdditionalWiredHosts.Template))
            }
        }
        return newClients;
    }


});
