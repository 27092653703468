define({
"Template":
{
  "version": "1.0",
  "response": {
    "id": "1f84aa7a-43b6-448c-abce-6c27e6ebd20b",
    "name": "OSPF_Down",
    "enabled": true,
    "flattened": true,
    "entityType": "network_device",
    "entityName": "Unknown",
    "entity": "10.30.255.101",
    "groupBy": "Unknown",
    "category": "Connectivity",
    "severity": "HIGH",
    "summary": "OSPF Adjacency Failed on Device DeviceIPPlaceHolder Interface InterfaceDownPlaceHolder with Neighbor 10.30.255.2",
    "groupId": "interface_connectivity_failure_grouping",
    "groupName": "Network Connectivity Failures on Network Device",
    "scope": "GLOBAL",
    "priority": "P2",
    "rootCause": "504",
    "timestamp": 1584002859978,
    "description": "OSPF adjacency failed on device name:'DeviceNamePlaceHolder', interface:'InterfaceDownPlaceHolder' at site:'SJCFloor1' with neighbor '10.30.255.2'",
    "suggestions": [
      {
        "message": "Ping the neighbor IP to verify connectivity.",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "ping neighbor IP",
            "command": "ping 10.30.255.2",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Check OSPF neighbors.",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check OSPF neighbors",
            "command": "show ip ospf neighbor",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "If the Neighbor is in \"Init\" state. Check if there is authentication configured using \"show run | sec OSPF\". Authentication type and keys should match on both routers",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check if there is authentication configured",
            "command": "show run | sec ospf",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "If the Neighbor is in \"Exstart\" state. Check if the MTU settings are same on the interface connecting the routers.",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check the interface MTU",
            "command": "show ip interface InterfaceDownPlaceHolder | in MTU",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Check interface InterfaceDownPlaceHolder has any incrementing errors ",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check for errors on the interface InterfaceDownPlaceHolder",
            "command": "show int InterfaceDownPlaceHolder | inc errors|runts|discard|drop|MTU",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Contact Cisco TAC World Wide – https://www.cisco.com/c/en/us/support/web/tsd-cisco-worldwide-contacts.html"
      }
    ],
    "additionalParams": [
      {
        "key": "managementIpAddr",
        "value": "10.30.255.101"
      },
      {
        "key": "remoteIP",
        "value": "10.30.255.2"
      },
      {
        "key": "_name",
        "value": "OSPF_Down"
      },
      {
        "key": "_entity_type",
        "value": "NetworkDevice"
      },
      {
        "key": "eventSource",
        "value": "Network Device"
      },
      {
        "key": "type",
        "value": "issue"
      },
      {
        "key": "_key",
        "value": "NetworkDevice:10.30.255.101:10.30.255.2:TenGigabitEthernet1/0/23"
      },
      {
        "key": "floorId",
        "value": "eab2a08d-7bbb-42b0-a207-d927784ec63e"
      },
      {
        "key": "_count",
        "value": "1"
      },
      {
        "key": "areaName",
        "value": "Global/USA"
      },
      {
        "key": "scope",
        "value": "global"
      },
      {
        "key": "rootCause",
        "value": "504"
      },
      {
        "key": "_primary_key",
        "value": "NetworkDevice:10.30.255.101"
      },
      {
        "key": "syslogMsg",
        "value": "Process 100, Nbr 10.30.255.2 on TenGigabitEthernet1/0/23 from EXSTART to DOWN, Neighbor Down: Too many retransmissions"
      },
      {
        "key": "floorName",
        "value": "Global/USA/LA/Level16"
      },
      {
        "key": "deviceType",
        "value": "Cisco Catalyst38xx stack-able ethernet switch"
      },
      {
        "key": "severity",
        "value": "HIGH"
      },
      {
        "key": "deviceUuid",
        "value": "5e880058-ffe6-4e69-b593-fd3d8ef649e3"
      },
      {
        "key": "deviceRole",
        "value": "ACCESS"
      },
      {
        "key": "_suppression",
        "value": "7200"
      },
      {
        "key": "deviceOS",
        "value": "16.6.1"
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "nwDeviceName",
        "value": "LA1-3850-CSW-2.corp.local"
      },
      {
        "key": "_eventTime",
        "value": "1584002859978"
      },
      {
        "key": "_mostRecent",
        "value": "1584002859978"
      },
      {
        "key": "entityId",
        "value": "10.30.255.101"
      },
      {
        "key": "eventUniqueId",
        "value": "10.30.255.101"
      },
      {
        "key": "deviceFamily",
        "value": "Switches and Hubs"
      },
      {
        "key": "priority",
        "value": "P2"
      },
      {
        "key": "buildingId",
        "value": "1e4b2421-4a4e-461f-b5ca-333129dda0e1"
      },
      {
        "key": "buildingName",
        "value": "Global/USA/LA"
      },
      {
        "key": "areaId",
        "value": "8edbff1a-e8f0-46d7-8b3a-c77ded39c4ab"
      },
      {
        "key": "siteHierarchy",
        "value": "Global/USA/LA/Level16"
      },
      {
        "key": "failureReason",
        "value": "Issue"
      },
      {
        "key": "eventStatus",
        "value": "Down"
      },
      {
        "key": "siteId",
        "value": "eab2a08d-7bbb-42b0-a207-d927784ec63e"
      },
      {
        "key": "_source",
        "value": "internal"
      },
      {
        "key": "deviceModel",
        "value": "FCW2129C0XU"
      },
      {
        "key": "category",
        "value": "connectivity"
      },
      {
        "key": "timestring",
        "value": "2020-03-12T08:47:39.978+0000"
      },
      {
        "key": "status",
        "value": "active"
      },
      {
        "key": "intfName",
        "value": "TenGigabitEthernet1/0/23"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}
})