define({
  SiteAssuranceAnalyticsTemplate: {
    version: "2.0",
    data: [
      {
        reportType: "reportedErrors",
        values: {
          eventList: [
            {
              timestamp: 1669310061783,
              apList: [
                {
                  apMacAddress: "12:3F:0B:F2:B1:D0",
                  bssid: "12:3F:0B:F2:B1:DB",
                  type: "Invalid IE",
                  frameType: "Beacon",
                  ies: "49",
                },
                {
                  apMacAddress: "12:3F:0B:F2:B1:10",
                  bssid: "12:3F:0B:F2:B1:15",
                  type: "Invalid IE",
                  frameType: "Beacon",
                  ies: "49",
                },
                {
                  apMacAddress: "12:3F:0B:F2:B3:60",
                  bssid: "12:3F:0B:F2:B3:60",
                  type: "Invalid IE",
                  frameType: "Beacon",
                  ies: "49",
                },
                {
                  apMacAddress: "12:3F:0B:F2:B3:40",
                  bssid: "12:3F:0B:F2:B3:4D",
                  type: "Invalid IE",
                  frameType: "Beacon",
                  ies: "49",
                },
                {
                  apMacAddress: "12:3F:0B:F2:B3:90",
                  bssid: "12:3F:0B:F2:B3:90",
                  type: "Invalid IE",
                  frameType: "Beacon",
                  ies: "49",
                },
                {
                  apName: "AP4800",
                  apMacAddress: "10:F9:20:FD:A2:A0",
                  apUUID: "ec181be5-6343-40f7-8b0f-a3424f18d948",
                  bssid: "10:F9:20:FD:A2:A0",
                  type: "Missing Response",
                  frameType: "FT",
                },
                {
                  apName: "AP4800",
                  apMacAddress: "10:F9:20:FD:A2:A0",
                  apUUID: "ec181be5-6343-40f7-8b0f-a3424f18d948",
                  bssid: "10:F9:20:FD:A2:AF",
                  type: "Missing Response",
                  frameType: "FT",
                },
                {
                  apName: "AP4800",
                  apMacAddress: "10:F9:20:FD:A2:A0",
                  apUUID: "ec181be5-6343-40f7-8b0f-a3424f18d948",
                  bssid: "10:F9:20:FD:A2:A7",
                  type: "Missing Response",
                  frameType: "FT",
                },
              ],
            },
            {
              timestamp: 1669308256503,
              apList: [
                {
                  apMacAddress: "A6:18:88:C0:46:60",
                  bssid: "A6:18:88:C0:46:60",
                  type: "Invalid IE",
                  frameType: "Beacon",
                  ies: "49",
                },
                {
                  apMacAddress: "A6:18:88:C0:56:70",
                  bssid: "A6:18:88:C0:56:78",
                  type: "Invalid IE",
                  frameType: "Beacon",
                  ies: "49",
                },
                {
                  apMacAddress: "12:3F:0B:F2:B3:60",
                  bssid: "12:3F:0B:F2:B3:60",
                  type: "Invalid IE",
                  frameType: "Beacon",
                  ies: "49",
                },
                {
                  apMacAddress: "12:3F:0B:F2:B3:40",
                  bssid: "12:3F:0B:F2:B3:4D",
                  type: "Invalid IE",
                  frameType: "Beacon",
                  ies: "49",
                },
                {
                  apMacAddress: "12:3F:0B:F2:B3:90",
                  bssid: "12:3F:0B:F2:B3:90",
                  type: "Invalid IE",
                  frameType: "Beacon",
                  ies: "49",
                },
                {
                  apName: "AP4800",
                  apMacAddress: "10:F9:20:FD:A2:A0",
                  apUUID: "ec181be5-6343-40f7-8b0f-a3424f18d948",
                  bssid: "10:F9:20:FD:A2:A0",
                  type: "Missing Response",
                  frameType: "FT",
                },
                {
                  apName: "AP4800",
                  apMacAddress: "10:F9:20:FD:A2:A0",
                  apUUID: "ec181be5-6343-40f7-8b0f-a3424f18d948",
                  bssid: "10:F9:20:FD:A2:AF",
                  type: "Missing Response",
                  frameType: "FT",
                },
                {
                  apName: "AP4800",
                  apMacAddress: "10:F9:20:FD:A2:A0",
                  apUUID: "ec181be5-6343-40f7-8b0f-a3424f18d948",
                  bssid: "10:F9:20:FD:A2:A7",
                  type: "Missing Response",
                  frameType: "FT",
                },
              ],
            },
            {
              timestamp: 1669306451395,
              apList: [
                {
                  apMacAddress: "A6:18:88:C0:46:60",
                  bssid: "A6:18:88:C0:46:60",
                  type: "Invalid IE",
                  frameType: "Beacon",
                  ies: "49",
                },
                {
                  apMacAddress: "12:3F:0B:F2:B3:50",
                  bssid: "12:3F:0B:F2:B3:5A",
                  type: "Invalid IE",
                  frameType: "Beacon",
                  ies: "49",
                },
                {
                  apMacAddress: "C6:9E:28:75:52:D0",
                  bssid: "C6:9E:28:75:52:D8",
                  type: "Invalid IE",
                  frameType: "Beacon",
                  ies: "49",
                },
                {
                  apMacAddress: "C6:9E:28:75:62:20",
                  bssid: "C6:9E:28:75:62:23",
                  type: "Invalid IE",
                  frameType: "Beacon",
                  ies: "49",
                },
                {
                  apMacAddress: "A6:18:88:C0:56:70",
                  bssid: "A6:18:88:C0:56:78",
                  type: "Invalid IE",
                  frameType: "Beacon",
                  ies: "49",
                },
                {
                  apName: "AP4800",
                  apMacAddress: "10:F9:20:FD:A2:A0",
                  apUUID: "ec181be5-6343-40f7-8b0f-a3424f18d948",
                  bssid: "10:F9:20:FD:A2:A0",
                  type: "Missing Response",
                  frameType: "FT",
                },
                {
                  apName: "AP4800",
                  apMacAddress: "10:F9:20:FD:A2:A0",
                  apUUID: "ec181be5-6343-40f7-8b0f-a3424f18d948",
                  bssid: "10:F9:20:FD:A2:AF",
                  type: "Missing Response",
                  frameType: "FT",
                },
                {
                  apName: "AP4800",
                  apMacAddress: "10:F9:20:FD:A2:A0",
                  apUUID: "ec181be5-6343-40f7-8b0f-a3424f18d948",
                  bssid: "10:F9:20:FD:A2:A7",
                  type: "Missing Response",
                  frameType: "FT",
                },
              ],
            },
            {
              timestamp: 1669304646337,
              apList: [
                {
                  apMacAddress: "12:3F:0B:F2:B3:50",
                  bssid: "12:3F:0B:F2:B3:5A",
                  type: "Invalid IE",
                  frameType: "Beacon",
                  ies: "49",
                },
                {
                  apMacAddress: "C6:9E:28:75:52:D0",
                  bssid: "C6:9E:28:75:52:D8",
                  type: "Invalid IE",
                  frameType: "Beacon",
                  ies: "49",
                },
                {
                  apMacAddress: "A6:18:88:C0:56:70",
                  bssid: "A6:18:88:C0:56:78",
                  type: "Invalid IE",
                  frameType: "Beacon",
                  ies: "49",
                },
                {
                  apMacAddress: "12:3F:0B:F2:B3:60",
                  bssid: "12:3F:0B:F2:B3:60",
                  type: "Invalid IE",
                  frameType: "Beacon",
                  ies: "49",
                },
                {
                  apMacAddress: "12:3F:0B:F2:B3:40",
                  bssid: "12:3F:0B:F2:B3:4D",
                  type: "Invalid IE",
                  frameType: "Beacon",
                  ies: "49",
                },
                {
                  apName: "AP4800",
                  apMacAddress: "10:F9:20:FD:A2:A0",
                  apUUID: "ec181be5-6343-40f7-8b0f-a3424f18d948",
                  bssid: "10:F9:20:FD:A2:A0",
                  type: "Missing Response",
                  frameType: "FT",
                },
                {
                  apName: "AP4800",
                  apMacAddress: "10:F9:20:FD:A2:A0",
                  apUUID: "ec181be5-6343-40f7-8b0f-a3424f18d948",
                  bssid: "10:F9:20:FD:A2:AF",
                  type: "Missing Response",
                  frameType: "FT",
                },
                {
                  apName: "AP4800",
                  apMacAddress: "10:F9:20:FD:A2:A0",
                  apUUID: "ec181be5-6343-40f7-8b0f-a3424f18d948",
                  bssid: "10:F9:20:FD:A2:A7",
                  type: "Missing Response",
                  frameType: "FT",
                },
              ],
            },
            {
              timestamp: 1669303593886,
              apList: [
                {
                  apMacAddress: "12:3F:0B:F2:B3:50",
                  bssid: "12:3F:0B:F2:B3:5A",
                  type: "Invalid IE",
                  frameType: "Beacon",
                  ies: "49",
                },
                {
                  apMacAddress: "C6:9E:28:75:52:D0",
                  bssid: "C6:9E:28:75:52:D8",
                  type: "Invalid IE",
                  frameType: "Beacon",
                  ies: "49",
                },
                {
                  apMacAddress: "12:3F:0B:F2:B3:60",
                  bssid: "12:3F:0B:F2:B3:60",
                  type: "Invalid IE",
                  frameType: "Beacon",
                  ies: "49",
                },
                {
                  apMacAddress: "12:3F:0B:F2:B1:10",
                  bssid: "12:3F:0B:F2:B1:15",
                  type: "Invalid IE",
                  frameType: "Beacon",
                  ies: "49",
                },
                {
                  apMacAddress: "12:3F:0B:F2:B3:70",
                  bssid: "12:3F:0B:F2:B3:7A",
                  type: "Invalid IE",
                  frameType: "Beacon",
                  ies: "49",
                },
                {
                  apName: "AP4800",
                  apMacAddress: "10:F9:20:FD:A2:A0",
                  apUUID: "ec181be5-6343-40f7-8b0f-a3424f18d948",
                  bssid: "10:F9:20:FD:A2:A0",
                  type: "Missing Response",
                  frameType: "FT",
                },
                {
                  apName: "AP4800",
                  apMacAddress: "10:F9:20:FD:A2:A0",
                  apUUID: "ec181be5-6343-40f7-8b0f-a3424f18d948",
                  bssid: "10:F9:20:FD:A2:AF",
                  type: "Missing Response",
                  frameType: "FT",
                },
                {
                  apName: "AP4800",
                  apMacAddress: "10:F9:20:FD:A2:A0",
                  apUUID: "ec181be5-6343-40f7-8b0f-a3424f18d948",
                  bssid: "10:F9:20:FD:A2:A7",
                  type: "Missing Response",
                  frameType: "FT",
                },
              ],
            },
          ],
        },
      },
      { reportType: "lowRssi", values: { eventList: [] } },
      { reportType: "temporaryDisconnection", values: { eventList: [] } },
      {
        reportType: "roamEvents",
        values: {
          eventList: [
            {
              apName: "AP4800",
              apMacAddress: "10:F9:20:FD:A2:A0",
              apUUID: "ec181be5-6343-40f7-8b0f-a3424f18d948",
              bssid: "10:F9:20:FD:A2:AF",
              timestamp: 1669310061783,
              rssiValue: -48,
              roamReasons: "Intel Proprietary",
              candidateApList: [
                {
                  apName: "AP4800",
                  apMacAddress: "10:F9:20:FD:A2:A0",
                  apUUID: "ec181be5-6343-40f7-8b0f-a3424f18d948",
                  bssid: "10:F9:20:FD:A2:A7",
                  rssiValue: -52,
                },
                {
                  apName: "AP4800",
                  apMacAddress: "10:F9:20:FD:A2:A0",
                  apUUID: "ec181be5-6343-40f7-8b0f-a3424f18d948",
                  bssid: "10:F9:20:FD:A2:A0",
                  rssiValue: -49,
                },
              ],
            },
            {
              apName: "AP4800",
              apMacAddress: "10:F9:20:FD:A2:A0",
              apUUID: "ec181be5-6343-40f7-8b0f-a3424f18d948",
              bssid: "10:F9:20:FD:A2:AF",
              timestamp: 1669308256503,
              rssiValue: -48,
              roamReasons: "Intel Proprietary",
              candidateApList: [
                {
                  apName: "AP4800",
                  apMacAddress: "10:F9:20:FD:A2:A0",
                  apUUID: "ec181be5-6343-40f7-8b0f-a3424f18d948",
                  bssid: "10:F9:20:FD:A2:A7",
                  rssiValue: -52,
                },
                {
                  apName: "AP4800",
                  apMacAddress: "10:F9:20:FD:A2:A0",
                  apUUID: "ec181be5-6343-40f7-8b0f-a3424f18d948",
                  bssid: "10:F9:20:FD:A2:A0",
                  rssiValue: -45,
                },
              ],
            },
            {
              apName: "AP4800",
              apMacAddress: "10:F9:20:FD:A2:A0",
              apUUID: "ec181be5-6343-40f7-8b0f-a3424f18d948",
              bssid: "10:F9:20:FD:A2:AF",
              timestamp: 1669306451395,
              rssiValue: -48,
              roamReasons: "Intel Proprietary",
              candidateApList: [
                {
                  apName: "AP4800",
                  apMacAddress: "10:F9:20:FD:A2:A0",
                  apUUID: "ec181be5-6343-40f7-8b0f-a3424f18d948",
                  bssid: "10:F9:20:FD:A2:A7",
                  rssiValue: -52,
                },
                {
                  apName: "AP4800",
                  apMacAddress: "10:F9:20:FD:A2:A0",
                  apUUID: "ec181be5-6343-40f7-8b0f-a3424f18d948",
                  bssid: "10:F9:20:FD:A2:A0",
                  rssiValue: -49,
                },
              ],
            },
            {
              apName: "AP4800",
              apMacAddress: "10:F9:20:FD:A2:A0",
              apUUID: "ec181be5-6343-40f7-8b0f-a3424f18d948",
              bssid: "10:F9:20:FD:A2:AF",
              timestamp: 1669304646337,
              rssiValue: -48,
              roamReasons: "Missed Beacons,11v recommendation",
            },
            {
              apName: "AP4800",
              apMacAddress: "10:F9:20:FD:A2:A0",
              apUUID: "ec181be5-6343-40f7-8b0f-a3424f18d948",
              bssid: "10:F9:20:FD:A2:AF",
              timestamp: 1669303593886,
              rssiValue: -48,
              roamReasons: "Intel Proprietary",
              candidateApList: [
                {
                  apName: "AP4800",
                  apMacAddress: "10:F9:20:FD:A2:A0",
                  apUUID: "ec181be5-6343-40f7-8b0f-a3424f18d948",
                  bssid: "10:F9:20:FD:A2:A7",
                  rssiValue: -51,
                },
                {
                  apName: "AP4800",
                  apMacAddress: "10:F9:20:FD:A2:A0",
                  apUUID: "ec181be5-6343-40f7-8b0f-a3424f18d948",
                  bssid: "10:F9:20:FD:A2:A0",
                  rssiValue: -48,
                },
              ],
            },
          ],
        },
      },
    ],
    page: null,
    scope: null,
    instrumentation: null,
    message: null,
  },
});
