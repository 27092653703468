define([
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/client_onboarding/dhcp_server_or_client_timeout',
 'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/client_onboarding/auth_aaa_rejected',
 'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/client_onboarding/auth_cl_timeout_or_rf',
 'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/client_onboarding/auth_sec_param_mismatch',
 'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/client_onboarding/excessive_auth_fail_client',
 'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/client_onboarding/excessive_auth_fail_wlc',
 'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/client_onboarding/excessive_auth_fail_server',
 'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/client_onboarding/excessive_auth_server',
 'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/client_onboarding/excessive_assoc_fail',
 'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/client_onboarding/excessive_total',
 'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/client_onboarding/excessive_dhcp'
], function (dhcp_server_or_client_timeout, auth_aaa_rejected, auth_cl_timeout_or_rf, auth_sec_param_mismatch,
             excessive_auth_fail_client, excessive_auth_fail_wlc, excessive_auth_fail_server, excessive_auth_server,
             excessive_assoc_fail, excessive_total,excessive_dhcp) {

    /*var self;

    function mapCommandResponses() {
        for(var cmd in CommandResponseListing.commandsTemplate) {
            self.cmdResponse[cmd] = eval(cmd);
        }
    }*/

    return {
        init: function() {
            /*this.cmdResponse = {};
            self = this;
            mapCommandResponses();*/
        },
        
        getClientOnboardingResponse: function(issue_key) {
            var t = eval(issue_key);
            //return t == undefined ? "" : t;
            return t;
        }

        /*getCommandResponse: function(cmd) {
            console.log(cmd);
            console.log(self.cmdResponse[cmd]);

            return self.cmdResponse[cmd];
        }*/
    };

});
