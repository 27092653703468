define({
    "issuesTemplate": {
      /* "router_cpu_high": {
        devices: [{hostName: 'border_cp_sda.local', daysOld: 1}],
        issueDetailFileName: 'fabric_reachability_session.json',
        triggerExists:true,
        triggerFileName: 'fabric_reachability_session',
        identifier:"managementIpAddress"
      },
      "switch_interface_input_output_errors": {
        devices: [{hostName: 'AP4800', daysOld: 1}],
        issueDetailFileName: 'switch_interface_input_output_errors.json',
        triggerExists:false,
        triggerFileName: '',
        identifier:"managementIpAddress"
      }, */
      "tcam_util_high": {
        devices: [{hostName: 'BLD1-FLR2-ACCESS'}],
        issueDetailFileName: 'tcam_util_high.json',
        triggerExists:false,
        triggerFileName: '',
        identifier:"managementIpAddress",
        defThresholdVals: [{'key':'currVal', 'value':'97.0'}],
        additionalParams: ['threshold', 'priority']//'max_tcam_util', 
      }, /*
      "wlc_memory_high": {
        devices: [{hostName: 'BLD1-FLR1-DST1', daysOld: 1}],
        issueDetailFileName: 'switch_port_flapping.json',
        triggerExists:false,
        triggerFileName: '',
        identifier:"managementIpAddress"
      }, */
      "radio_util_trigger": {
        devices: [{hostName: 'AP9120_3'}],
        issueDetailFileName: 'radio_util_trigger.json',
        triggerExists:true,
        triggerFileName: 'radio_util_trigger_ap',
        identifier:"macAddress",
        defThresholdVals: [{'key':'radioChannelUtilizationAP', 'value':'70'}, {'key':'currVal', 'value':'80.0'}],
        additionalParams: ['threshold', 'priority']//'utilization',
      },
      "radio_util_trigger_ap": {
        devices: [{hostName: 'AP9120_3'}],
        issueDetailFileName: 'radio_util_trigger_ap.json',
        triggerExists:true,
        triggerFileName: 'radio_util_trigger_ap',
        identifier:"macAddress",
        defThresholdVals: [{'key':'radioChannelUtilizationAP', 'value':'20.0'}, {'key':'currVal', 'value':'85.0'}],//radioChannelUtilizationAP should be 70 ideally
        additionalParams: ['threshold', 'priority']//'utilization',
      },
    }
})
