define({
    "profileRules_Template":[
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "802automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "802automation Device Mac Oui Rule 1",
          "ruleId": "802automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "AAEON-Technology-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "AAEON Technology Device Mac Oui Rule 1",
          "ruleId": "AAEON-Technology-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ABB-AB-Cewe-Control-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ABB AB Cewe Control Device Mac Oui Rule 1",
          "ruleId": "ABB-AB-Cewe-Control-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ABB-AG-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ABB AG Device Mac Oui Rule 1",
          "ruleId": "ABB-AG-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ABB-AS-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ABB AS Automation Device Mac Oui Rule 1",
          "ruleId": "ABB-AS-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ABB-Australia-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ABB Australia Device Mac Oui Rule 1",
          "ruleId": "ABB-Australia-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ABB-Automation-Products-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ABB Automation Products Device Mac Oui Rule 1",
          "ruleId": "ABB-Automation-Products-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ABB-Bomem-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ABB Bomem Device Mac Oui Rule 1",
          "ruleId": "ABB-Bomem-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ABB-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ABB Device Mac Oui Rule 1",
          "ruleId": "ABB-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ABB-Genway-Xiamen-Electrical-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ABB Genway Xiamen Electrical Device Mac Oui Rule 1",
          "ruleId": "ABB-Genway-Xiamen-Electrical-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ABB-Global-Industries-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ABB Global Industries Device Mac Oui Rule 1",
          "ruleId": "ABB-Global-Industries-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ABB-Industrial-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ABB Industrial Systems Device Mac Oui Rule 1",
          "ruleId": "ABB-Industrial-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ABB-Ltd-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ABB Ltd Device Mac Oui Rule 1",
          "ruleId": "ABB-Ltd-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ABB-Oy-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ABB Oy Device Mac Oui Rule 1",
          "ruleId": "ABB-Oy-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ABB-Oy-Drives-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ABB Oy Drives Device Mac Oui Rule 1",
          "ruleId": "ABB-Oy-Drives-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ABB-Oy-Medium-Voltage-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ABB Oy Medium Voltage Device Mac Oui Rule 1",
          "ruleId": "ABB-Oy-Medium-Voltage-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ABB-Power-Quality-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ABB Power Quality Device Mac Oui Rule 1",
          "ruleId": "ABB-Power-Quality-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ABB-Power-Systems-Generation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ABB Power Systems Generation Device Mac Oui Rule 1",
          "ruleId": "ABB-Power-Systems-Generation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ABB-Power-Technologies-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ABB Power Technologies Device Mac Oui Rule 1",
          "ruleId": "ABB-Power-Technologies-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ABB-Power-Transmission-Distribution-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ABB Power Transmission Distribution Device Mac Oui Rule 1",
          "ruleId": "ABB-Power-Transmission-Distribution-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ABB-SPA-SACE-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ABB SPA SACE Device Mac Oui Rule 1",
          "ruleId": "ABB-SPA-SACE-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ABB-Stotz-Kontakt-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ABB Stotz Kontakt Device Mac Oui Rule 1",
          "ruleId": "ABB-Stotz-Kontakt-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ABB-Switzerland-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ABB Switzerland Device Mac Oui Rule 1",
          "ruleId": "ABB-Switzerland-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ABB-Switzerland-Power-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ABB Switzerland Power Systems Device Mac Oui Rule 1",
          "ruleId": "ABB-Switzerland-Power-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ABB-Totalflow-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ABB Totalflow Device Mac Oui Rule 1",
          "ruleId": "ABB-Totalflow-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ABB-Xiamen-Low-Voltage-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ABB Xiamen Low Voltage Device Mac Oui Rule 1",
          "ruleId": "ABB-Xiamen-Low-Voltage-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ABB-Xiamen-Transmission-Distribution-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ABB Xiamen Transmission Distribution Device Mac Oui Rule 1",
          "ruleId": "ABB-Xiamen-Transmission-Distribution-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ACTi-Corporation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ACTi Corporation Device Mac Oui Rule 1",
          "ruleId": "ACTi-Corporation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "AMSC-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "AMSC Device Mac Oui Rule 1",
          "ruleId": "AMSC-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "APG-Cash-Drawer-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "APG Cash Drawer Device Mac Oui Rule 1",
          "ruleId": "APG-Cash-Drawer-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ATI-Industrial-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ATI Industrial Automation Device Mac Oui Rule 1",
          "ruleId": "ATI-Industrial-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "AUMA-Riester-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "AUMA Riester Device Mac Oui Rule 1",
          "ruleId": "AUMA-Riester-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "AVAT-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "AVAT Automation Device Mac Oui Rule 1",
          "ruleId": "AVAT-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "AVTECH-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "AVTECH Device Mac Oui Rule 1",
          "ruleId": "AVTECH-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266339
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Aastra-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Aastra Device Mac Oui Rule",
          "ruleId": "aastraDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "ABB Power Technologies"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Abb Power Technologies Manufacturer Mac Oui Rule 1",
          "ruleId": "abbPowerTechnologiesManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Abb-Tropos-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Abb Tropos Device Mac Oui Rule 1",
          "ruleId": "Abb-Tropos-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Abbott Medical Optics"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Abbott Medical Optics Manufacturer Mac Oui Rule 1",
          "ruleId": "abbottMedicalOpticsManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Abbott Medical Optics"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Abbott Medical Optics Manufacturer Mac Oui Rule 2",
          "ruleId": "abbottMedicalOpticsManufacturerMacOuiRule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Access-Control-Systems-JSC-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Access Control Systems JSC Device Mac Oui Rule 1",
          "ruleId": "Access-Control-Systems-JSC-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Access Control Systems JSC"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Access Control Systems Jsc Manufacturer Mac Oui Rule 1",
          "ruleId": "accessControlSystemsJscManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Accu-Sort-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Accu Sort Systems Device Mac Oui Rule 1",
          "ruleId": "Accu-Sort-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Accu-Time-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Accu Time Systems Device Mac Oui Rule 1",
          "ruleId": "Accu-Time-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Acroloop-Motion-Control-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Acroloop Motion Control Device Mac Oui Rule 1",
          "ruleId": "Acroloop-Motion-Control-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Active-Control-Technology-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Active Control Technology Device Mac Oui Rule 1",
          "ruleId": "Active-Control-Technology-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Advanced-Control-Systems-Doo-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Advanced Control Systems Doo Device Mac Oui Rule 1",
          "ruleId": "Advanced-Control-Systems-Doo-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Advanced-Control-Systems-Inc-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Advanced Control Systems Inc Device Mac Oui Rule 1",
          "ruleId": "Advanced-Control-Systems-Inc-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Advanced-Illumination-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Advanced Illumination Device Mac Oui Rule 1",
          "ruleId": "Advanced-Illumination-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Advanced Medical Information"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Advanced Medical Information Manufacturer Mac Oui Rule 1",
          "ruleId": "advancedMedicalInformationManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Advanced-Micro-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Advanced Micro Controls Device Mac Oui Rule 1",
          "ruleId": "Advanced-Micro-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Advanced-Motion-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Advanced Motion Controls Device Mac Oui Rule 1",
          "ruleId": "Advanced-Motion-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Advantage-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Advantage Controls Device Mac Oui Rule 1",
          "ruleId": "Advantage-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Advantech-Co-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Advantech Co Device Mac Oui Rule 1",
          "ruleId": "Advantech-Co-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Advantech-Technologies-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Advantech Technologies Device Mac Oui Rule 1",
          "ruleId": "Advantech-Technologies-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Advantech-Technology-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Advantech Technology Device Mac Oui Rule 1",
          "ruleId": "Advantech-Technology-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Advatek-Lighting-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Advatek Lighting Device Mac Oui Rule 1",
          "ruleId": "Advatek-Lighting-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Advatek Lighting Pty Ltd"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Advatek Lighting Pty Ltd Manufacturer Mac Oui Rule 1",
          "ruleId": "advatekLightingPtyLtdManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Aerohive-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Aerohive Device Mac Oui Rule",
          "ruleId": "aerohiveDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Aiphone-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Aiphone Device Mac Oui Rule 1",
          "ruleId": "Aiphone-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Alfa-Laval-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Alfa Laval Automation Device Mac Oui Rule 1",
          "ruleId": "Alfa-Laval-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Alstom-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Alstom Device Mac Oui Rule 1",
          "ruleId": "Alstom-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Alstom-Grid-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Alstom Grid Device Mac Oui Rule 1",
          "ruleId": "Alstom-Grid-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Alstom-Hydro-France-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Alstom Hydro France Device Mac Oui Rule 1",
          "ruleId": "Alstom-Hydro-France-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Alstom-Signalling-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Alstom Signalling Device Mac Oui Rule 1",
          "ruleId": "Alstom-Signalling-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Alstom-Strongwish-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Alstom Strongwish Device Mac Oui Rule 1",
          "ruleId": "Alstom-Strongwish-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Alstom-Transport-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Alstom Transport Device Mac Oui Rule 1",
          "ruleId": "Alstom-Transport-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Altech-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Altech Controls Device Mac Oui Rule 1",
          "ruleId": "Altech-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Amatis-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Amatis Controls Device Mac Oui Rule 1",
          "ruleId": "Amatis-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Amazing-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Amazing Controls Device Mac Oui Rule 1",
          "ruleId": "Amazing-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Amazon-Device"
          ],
          "hardwareManufacturer": [
            "Amazon.com, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Amazon Mac Oui Rule",
          "ruleId": "amazonMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "American-Power-Conversion-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "American Power Conversion Device Mac Oui Rule",
          "ruleId": "americanPowerConversionDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Andover-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Andover Controls Device Mac Oui Rule 1",
          "ruleId": "Andover-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "devicePlatform"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Android ACIDEX Rule",
          "ruleId": "androidAcidexRule",
          "operatingSystem": [
            "Android"
          ],
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Google-Device"
          ],
          "hardwareManufacturer": [
            "Google, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Android Google Mac Oui Rule",
          "ruleId": "androidGoogleMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Android-Motorola"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Android Motorola Mac Oui Rule",
          "ruleId": "androidMotorolaMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Anviz-Biometric-Tech-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Anviz Biometric Tech Device Mac Oui Rule 1",
          "ruleId": "Anviz-Biometric-Tech-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "APG Cash Drawer, LLC"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apg Cash Drawer Llc Manufacturer Mac Oui Rule 1",
          "ruleId": "apgCashDrawer,LlcManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "devicePlatform"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple Acidex Rule",
          "ruleId": "appleAcidexRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "dhcpParameterRequestList"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-Airport",
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple Airport Dhcp Fingerprint Rule",
          "ruleId": "appleAirportDhcpFingerprintRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "sysDescr"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-Airport",
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple Airport Snmp Rule 1",
          "ruleId": "appleAirportSnmpRrule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dhcpParameterRequestList"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple Dhcp Fingerprint Rule 1",
          "ruleId": "appleDhcpFingerprintRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dhcpParameterRequestList"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple Dhcp Fingerprint Rule 2",
          "ruleId": "appleDhcpFingerprintRule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dhcpParameterRequestList"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple Dhcp Fingerprint Rule 3",
          "ruleId": "appleDhcpFingerprintRule3",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dhcpParameterRequestList"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple Dhcp Fingerprint Rule 4",
          "ruleId": "appleDhcpFingerprintRule4",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "devicePlatform"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple Ios ACIDEX Rule",
          "ruleId": "appleIosAcidexRule",
          "operatingSystem": [
            "iOS"
          ],
          "lastModifiedOn": 1589339266339
        },
        {
          "deviceType": [
            "Mobile Device"
          ],
          "usedAttributes": [
            "dhcpHostName",
            "cdpCacheDeviceId",
            "mdnsHostName",
            "lldpSystemName"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-iPad",
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple Ipad Hostname Rule",
          "ruleId": "appleIpadHostNameRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "Mobile Device"
          ],
          "usedAttributes": [
            "mdnsModelName",
            "deviceType"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-iPad",
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple Ipad Model Name Rule",
          "ruleId": "appleIpadModelNameRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Mobile Device"
          ],
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-iPad",
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple Ipad User Agent Rule",
          "ruleId": "appleIpadUserAgentRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "Mobile Device"
          ],
          "usedAttributes": [
            "dhcpHostName",
            "cdpCacheDeviceId",
            "mdnsHostName",
            "lldpSystemName"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-iPhone",
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple Iphone Hostname Rule",
          "ruleId": "appleIphoneHostNameRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "Mobile Device"
          ],
          "usedAttributes": [
            "mdnsModelName",
            "deviceType"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-iPhone",
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple Iphone Model Name Rule",
          "ruleId": "appleIphoneModelNameRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Mobile Device"
          ],
          "usedAttributes": [
            "oui",
            "operatingSystem"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-iPhone",
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple Iphone Os Rule",
          "ruleId": "appleIphoneOsRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Mobile Device"
          ],
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-iPhone",
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple Iphone User Agent Rule",
          "ruleId": "appleIphoneUserAgentRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Mobile Device"
          ],
          "usedAttributes": [
            "dhcpHostName",
            "cdpCacheDeviceId",
            "mdnsHostName",
            "lldpSystemName"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-iPod",
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple Ipod Hostname Rule",
          "ruleId": "appleIpodHostNameRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "Mobile Device"
          ],
          "usedAttributes": [
            "mdnsModelName",
            "deviceType"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-iPod",
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple Ipod Model Name Rule",
          "ruleId": "appleIpodModelNameRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "Mobile Device"
          ],
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-iPod",
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple Ipod User Agent Rule",
          "ruleId": "appleIpodUserAgentRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple Mac Oui Rule",
          "ruleId": "appleMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Workstation"
          ],
          "usedAttributes": [
            "mdnsModelName",
            "deviceType"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-MacBook",
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple MacBook Model Name Rule",
          "ruleId": "appleMacBookModelNameRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Workstation"
          ],
          "usedAttributes": [
            "operatingSystem"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-MacBook",
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple MacBook OS Rule 1",
          "ruleId": "appleMacBookOSRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Workstation"
          ],
          "usedAttributes": [
            "operatingSystem"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-MacBook",
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple MacBook OS Rule 2",
          "ruleId": "appleMacBookOSRule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Workstation"
          ],
          "usedAttributes": [
            "userAgent",
            "operatingSystem"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-MacBook",
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple MacBook User Agent Rule 1",
          "ruleId": "appleMacBookUserAgentRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Workstation"
          ],
          "usedAttributes": [
            "userAgent",
            "operatingSystem"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-MacBook",
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple MacBook User Agent Rule 2",
          "ruleId": "appleMacBookUserAgentRule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "operatingSystem"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple Os Rule",
          "ruleId": "appleOsRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui",
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple TV OS User Agent Rule",
          "ruleId": "appleTvOsRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "IOT Device"
          ],
          "usedAttributes": [
            "dhcpHostName",
            "cdpCacheDeviceId",
            "mdnsHostName",
            "lldpSystemName"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-TV",
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple Tv Hostname Rule",
          "ruleId": "appleTvHostNameRule",
          "operatingSystem": [
            "tvOS"
          ],
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "IOT Device"
          ],
          "usedAttributes": [
            "mdnsModelName",
            "deviceType"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-TV",
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple Tv Model Name Rule",
          "ruleId": "appleTvModelNameRule",
          "operatingSystem": [
            "tvOS"
          ],
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "IOT Device"
          ],
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-TV",
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple Tv User Agent Rule",
          "ruleId": "appleTvUserAgentRule",
          "operatingSystem": [
            "tvOS"
          ],
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Mobile Device"
          ],
          "usedAttributes": [
            "dhcpHostName",
            "cdpCacheDeviceId",
            "mdnsHostName",
            "lldpSystemName"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-Watch",
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple Watch Hostname Rule",
          "ruleId": "appleWatchHostNameRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Mobile Device"
          ],
          "usedAttributes": [
            "mdnsModelName",
            "deviceType"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-Watch",
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple Watch Model Name Rule",
          "ruleId": "appleWatchModelNameRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Mobile Device"
          ],
          "usedAttributes": [
            "operatingSystem"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-Watch",
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple Watch Os Rule",
          "ruleId": "appleWatchOsRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266339
        },
        {
          "deviceType": [
            "Mobile Device"
          ],
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-Watch",
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple Watch User Agent Rule",
          "ruleId": "appleWatchUserAgentRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "Workstation"
          ],
          "usedAttributes": [
            "mdnsModelName"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Apple-iMac",
            "Apple-Device"
          ],
          "hardwareManufacturer": [
            "Apple, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple iMac Model Name Rule",
          "ruleId": "appleMacModelNameRule",
          "operatingSystem": [
            "macOS"
          ],
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "hardwareModel"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Apple iOS Rule",
          "ruleId": "appleIOsRule",
          "operatingSystem": [
            "iOS"
          ],
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Applera-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Applera Device Mac Oui Rule",
          "ruleId": "appleraDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Applied-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Applied Automation Device Mac Oui Rule 1",
          "ruleId": "Applied-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Applied-Materials-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Applied Materials Device Mac Oui Rule 1",
          "ruleId": "Applied-Materials-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Applied Materials"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Applied Materials Manufacturer Mac Oui Rule 1",
          "ruleId": "appliedMaterialsManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Applied Materials"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Applied Materials Manufacturer Mac Oui Rule 2",
          "ruleId": "appliedMaterialsManufacturerMacOuiRule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Applied Medical Technologies, Inc DBA AirClean Systems"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Applied Medical Technologies Inc Dba Airclean Systems Manufacturer Mac Oui Rule 1",
          "ruleId": "appliedMedicalTechnologies IncDbaAircleanSystemsManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Arco-Electronic-Control-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Arco Electronic Control Device Mac Oui Rule 1",
          "ruleId": "Arco-Electronic-Control-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Arcom-Control-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Arcom Control Systems Device Mac Oui Rule 1",
          "ruleId": "Arcom-Control-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Arecont-Vision-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Arecont Vision Device Mac Oui Rule 1",
          "ruleId": "Arecont-Vision-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "ARKRAY, Inc. Kyoto Laboratory"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Arkray Inc Kyoto Laboratory Manufacturer Mac Oui Rule 1",
          "ruleId": "arkray-Inc KyotoLaboratoryManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Armour-Home-Electronics-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Armour Home Electronics Device Mac Oui Rule 1",
          "ruleId": "Armour-Home-Electronics-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Armour Home Electronics LTD"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Armour Home Electronics Ltd Manufacturer Mac Oui Rule 1",
          "ruleId": "armourHomeElectronicsLtdManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Arris-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Arris Device Mac Oui Rule",
          "ruleId": "arrisDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Aruba-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Aruba Device Mac Oui Rule",
          "ruleId": "arubaDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Assa-Abloy-Cote-Picarde-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Assa Abloy Cote Picarde Device Mac Oui Rule 1",
          "ruleId": "Assa-Abloy-Cote-Picarde-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Assa-Abloy-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Assa Abloy Device Mac Oui Rule 1",
          "ruleId": "Assa-Abloy-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Asus-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Asus Device Mac Oui Rule",
          "ruleId": "asusDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Atrie-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Atrie Device Mac Oui Rule",
          "ruleId": "atrieDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Audio-Code-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Audio Code Device Mac Oui Rule",
          "ruleId": "audioCodeDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266339
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "AudioControl-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "AudioControl Device Mac Oui Rule 1",
          "ruleId": "AudioControl-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "August-Home-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "August Home Device Mac Oui Rule 1",
          "ruleId": "August-Home-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Autec-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Autec Device Mac Oui Rule 1",
          "ruleId": "Autec-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Automated-Logic-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Automated Logic Device Mac Oui Rule 1",
          "ruleId": "Automated-Logic-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Automatic-Bar-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Automatic Bar Controls Device Mac Oui Rule 1",
          "ruleId": "Automatic-Bar-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Automation-Components-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Automation Components Device Mac Oui Rule 1",
          "ruleId": "Automation-Components-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Automation-Electronics-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Automation Electronics Device Mac Oui Rule 1",
          "ruleId": "Automation-Electronics-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Automation-Products-Group-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Automation Products Group Device Mac Oui Rule 1",
          "ruleId": "Automation-Products-Group-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Autronica-Fire-And-Security-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Autronica Fire And Security Device Mac Oui Rule 1",
          "ruleId": "Autronica-Fire-And-Security-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Avaya-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Avaya Device Mac Oui Rule",
          "ruleId": "avayaDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Aventura-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Aventura Device Mac Oui Rule 1",
          "ruleId": "Aventura-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Avigilon-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Avigilon Device Mac Oui Rule 1",
          "ruleId": "Avigilon-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266339
        },
        {
          "deviceType": [
            "Camera"
          ],
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Axis-Communications-Device"
          ],
          "hardwareManufacturer": [
            "Axis Communications AB"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Axis Communications Device Mac Oui Rule",
          "ruleId": "Axis-Communications-Device-Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Axis-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Axis Device Mac Oui Rule",
          "ruleId": "axisDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Axis-Shield PoC AS"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Axis Shield Poc As Manufacturer Mac Oui Rule 1",
          "ruleId": "axisShieldPocAsManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "BC-Illumination-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "BC Illumination Device Mac Oui Rule 1",
          "ruleId": "BC-Illumination-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "BR-Controls-Nederland-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "BR Controls Nederland Device Mac Oui Rule 1",
          "ruleId": "BR-Controls-Nederland-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Bada Os User Agent Rule",
          "ruleId": "badaOsUserAgentRule",
          "operatingSystem": [
            "Bada OS"
          ],
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Baldor-Electric-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Baldor Electric Device Mac Oui Rule 1",
          "ruleId": "Baldor-Electric-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Balogh-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Balogh Device Mac Oui Rule 1",
          "ruleId": "Balogh-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Balogh S.A."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Balogh S-A Manufacturer Mac Oui Rule 1",
          "ruleId": "baloghS A ManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "BALOGH T.A.G"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Balogh T-A-G Manufacturer Mac Oui Rule 1",
          "ruleId": "baloghT-A-GManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266339
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Balogh-TAG-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Balogh TAG Device Mac Oui Rule 1",
          "ruleId": "Balogh-TAG-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Barco-Control-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Barco Control Controls Device Mac Oui Rule 1",
          "ruleId": "Barco-Control-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Basler-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Basler Device Mac Oui Rule 1",
          "ruleId": "Basler-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Basler-Electric-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Basler Electric Device Mac Oui Rule 1",
          "ruleId": "Basler-Electric-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Bay-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Bay Controls Device Mac Oui Rule 1",
          "ruleId": "Bay-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "BC Illumination, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Bc Illumination Inc Manufacturer Mac Oui Rule 1",
          "ruleId": "bcIllumination-Inc-ManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Beckhoff-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Beckhoff Automation Device Mac Oui Rule 1",
          "ruleId": "Beckhoff-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Beckmann-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Beckmann Device Mac Oui Rule 1",
          "ruleId": "Beckmann-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Beckmann-Egle-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Beckmann Egle Device Mac Oui Rule 1",
          "ruleId": "Beckmann-Egle-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Beckmann+Egle GmbH"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Beckmann Egle Gmbh Manufacturer Mac Oui Rule 1",
          "ruleId": "beckmann-EgleGmbhManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Beijing-E3Control-Technology-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Beijing E3Control Technology Device Mac Oui Rule 1",
          "ruleId": "Beijing-E3Control-Technology-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Beijing-General-Research-Institute-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Beijing General Research Institute Device Mac Oui Rule 1",
          "ruleId": "Beijing-General-Research-Institute-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Belkin-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Belkin Device Mac Oui Rule",
          "ruleId": "belkinDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Berghof-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Berghof Automation Device Mac Oui Rule 1",
          "ruleId": "Berghof-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Berkeley-Process-Control-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Berkeley Process Control Device Mac Oui Rule 1",
          "ruleId": "Berkeley-Process-Control-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Bharat-Electronics-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Bharat Electronics Device Mac Oui Rule 1",
          "ruleId": "Bharat-Electronics-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "BioControl-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "BioControl Device Mac Oui Rule 1",
          "ruleId": "BioControl-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Biometric-Access-Company-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Biometric Access Company Device Mac Oui Rule 1",
          "ruleId": "Biometric-Access-Company-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Bioscrypt-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Bioscrypt Device Mac Oui Rule 1",
          "ruleId": "Bioscrypt-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "BIOTAGE"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Biotage Manufacturer Mac Oui Rule 1",
          "ruleId": "biotageManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Biotage Sweden AB"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Biotage Sweden Ab Manufacturer Mac Oui Rule 1",
          "ruleId": "biotageSwedenAbManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Bitwise-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Bitwise Controls Device Mac Oui Rule 1",
          "ruleId": "Bitwise-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "BL Healthcare, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Bl Healthcare Inc Manufacturer Mac Oui Rule 1",
          "ruleId": "blHealthcare-Inc-ManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "BlackBerry"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Blackberry Mac Oui Rule",
          "ruleId": "blackberryMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Blackberry Os User Agent Rule",
          "ruleId": "blackberryOsUserAgentRule",
          "operatingSystem": [
            "BlackBerry OS"
          ],
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Bonfiglioli-Vectron-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Bonfiglioli Vectron Device Mac Oui Rule 1",
          "ruleId": "Bonfiglioli-Vectron-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Bosch-Access-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Bosch Access Systems Device Mac Oui Rule 1",
          "ruleId": "Bosch-Access-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Bosch-Automotive-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Bosch Automotive Device Mac Oui Rule 1",
          "ruleId": "Bosch-Automotive-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": null,
          "conditionGroups": {
            "conditionGroup": [
              {
                "condition": {
                  "attribute": "deviceType",
                  "value": "Coffee Machine",
                  "attributeDictionary": "",
                  "operator": "equals"
                },
                "type": "classification"
              }
            ],
            "operator": "and"
          },
          "hardwareModel": [
            "Bosch-Coffee"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Custom",
          "ruleVersion": 1,
          "lastModifiedBy": "admin",
          "ruleName": "Bosch Coffee",
          "ruleId": "custom-b973c814-e43f-4c01-99d2-fb73d15566de",
          "operatingSystem": null,
          "lastModifiedOn": 1589575282755
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Bosch-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Bosch Device Mac Oui Rule 1",
          "ruleId": "Bosch-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Bosch-Rexroth-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Bosch Rexroth Device Mac Oui Rule 1",
          "ruleId": "Bosch-Rexroth-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Bosch-Security-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Bosch Security Systems Device Mac Oui Rule 1",
          "ruleId": "Bosch-Security-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Bosch-Security-Systems-Netherlands-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Bosch Security Systems Netherlands Device Mac Oui Rule 1",
          "ruleId": "Bosch-Security-Systems-Netherlands-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Bosch-Security-Systems-USA-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Bosch Security Systems USA Device Mac Oui Rule 1",
          "ruleId": "Bosch-Security-Systems-USA-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Bosch-Siemens-Home-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Bosch Siemens Home Device Mac Oui Rule 1",
          "ruleId": "Bosch-Siemens-Home-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Bosch-Software-Innovations-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Bosch Software Innovations Device Mac Oui Rule 1",
          "ruleId": "Bosch-Software-Innovations-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Brilliant-Home-Technology-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Brilliant Home Technology Device Mac Oui Rule 1",
          "ruleId": "Brilliant-Home-Technology-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Brooks-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Brooks Automation Device Mac Oui Rule 1",
          "ruleId": "Brooks-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Brooks-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Brooks Automation Device Mac Oui Rule 2",
          "ruleId": "Brooks-Automation-Device-Rule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Brooks-Automation-RFID-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Brooks Automation RFID Device Mac Oui Rule 1",
          "ruleId": "Brooks-Automation-RFID-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Brother-Device"
          ],
          "hardwareManufacturer": [
            "Brother Industries, Ltd."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Brother Device Mac Oui Rule",
          "ruleId": "brotherDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui",
            "mdnsTy"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Brother-Device"
          ],
          "hardwareManufacturer": [
            "Brother Industries, Ltd."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Brother Device Mdns Ty Rule",
          "ruleId": "brotherDeviceMdnsTyRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266339
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "hrDeviceDescr"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Brother-Device"
          ],
          "hardwareManufacturer": [
            "Brother Industries, Ltd."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Brother Device Snmp Rule",
          "ruleId": "brotherDeviceSnmpRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "Printer"
          ],
          "usedAttributes": [
            "dhcpParameterRequestList"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Brother-Printer",
            "Brother-Device"
          ],
          "hardwareManufacturer": [
            "Brother Industries, Ltd."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Brother Printer Dhcp Fingerprint Rule 1",
          "ruleId": "brotherPrinterDhcpFingerprintRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266339
        },
        {
          "deviceType": [
            "Printer"
          ],
          "usedAttributes": [
            "dhcpParameterRequestList"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Brother-Printer",
            "Brother-Device"
          ],
          "hardwareManufacturer": [
            "Brother Industries, Ltd."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Brother Printer Dhcp Fingerprint Rule 2",
          "ruleId": "brotherPrinterDhcpFingerprintRule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266339
        },
        {
          "deviceType": [
            "Printer"
          ],
          "usedAttributes": [
            "dhcpParameterRequestList"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Brother-Printer",
            "Brother-Device"
          ],
          "hardwareManufacturer": [
            "Brother Industries, Ltd."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Brother Printer Dhcp Fingerprint Rule 3",
          "ruleId": "brotherPrinterDhcpFingerprintRule3",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266339
        },
        {
          "deviceType": [
            "Printer"
          ],
          "usedAttributes": [
            "dhcpParameterRequestList"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Brother-Printer",
            "Brother-Device"
          ],
          "hardwareManufacturer": [
            "Brother Industries, Ltd."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Brother Printer Dhcp Fingerprint Rule 4",
          "ruleId": "brotherPrinterDhcpFingerprintRule4",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266339
        },
        {
          "deviceType": [
            "Printer"
          ],
          "usedAttributes": [
            "dhcpParameterRequestList"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Brother-Printer",
            "Brother-Device"
          ],
          "hardwareManufacturer": [
            "Brother Industries, Ltd."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Brother Printer Dhcp Fingerprint Rule 5",
          "ruleId": "brotherPrinterDhcpFingerprintRule5",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266339
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui",
            "mdnsTy"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Brother-Device"
          ],
          "hardwareManufacturer": [
            "Brother Industries, Ltd."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Brother Printer Mdns Ty Rule",
          "ruleId": "brotherPrinterMdnsTyRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "hrDeviceDescr"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Brother-Device"
          ],
          "hardwareManufacturer": [
            "Brother Industries, Ltd."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Brother Printer Snmp Rule",
          "ruleId": "brotherPrinterSnmpRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Workstation"
          ],
          "usedAttributes": [
            "operatingSystem"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "BSD Workstation"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Bsd Workstation Os Rule",
          "ruleId": "bsdWorkstationOsRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Building-Block-Video-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Building Block Video Device Mac Oui Rule 1",
          "ruleId": "Building-Block-Video-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Burroughs-Payment-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Burroughs Payment Systems Device Mac Oui Rule 1",
          "ruleId": "Burroughs-Payment-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "CA-Traffic-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "CA Traffic Device Mac Oui Rule 1",
          "ruleId": "CA-Traffic-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "CAREL-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "CAREL Device Mac Oui Rule 1",
          "ruleId": "CAREL-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "CCV-Deutschland-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "CCV Deutschland Device Mac Oui Rule 1",
          "ruleId": "CCV-Deutschland-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "CONTROLtronic-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "CONTROLtronic Device Mac Oui Rule 1",
          "ruleId": "CONTROLtronic-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "CSI-Control-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "CSI Control Systems Device Mac Oui Rule 1",
          "ruleId": "CSI-Control-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "CSIRO-Exploration-Mining-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "CSIRO Exploration Mining Device Mac Oui Rule 1",
          "ruleId": "CSIRO-Exploration-Mining-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "CA Traffic Ltd"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Ca Traffic Ltd Manufacturer Mac Oui Rule 1",
          "ruleId": "caTrafficLtdManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Canon-Device"
          ],
          "hardwareManufacturer": [
            "Canon Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Canon Device Mac Oui Rule",
          "ruleId": "canonDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Canon-Device"
          ],
          "hardwareManufacturer": [
            "Canon Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Canon Device Mac Oui Rule 2",
          "ruleId": "canonDeviceMacOuiRule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "CardioMEMS Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cardiomems Inc Manufacturer Mac Oui Rule 1",
          "ruleId": "cardiomemsInc-ManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Care Everywhere LLC"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Care Everywhere Llc Manufacturer Mac Oui Rule 1",
          "ruleId": "careEverywhereLlcManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Carlo-Gavazzi-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Carlo Gavazzi Controls Device Mac Oui Rule 1",
          "ruleId": "Carlo-Gavazzi-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Carrier-Corporation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Carrier Corporation Device Mac Oui Rule 1",
          "ruleId": "Carrier-Corporation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cash-Systemes-Industrie-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cash Systemes Industrie Device Mac Oui Rule 1",
          "ruleId": "Cash-Systemes-Industrie-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "CashGuard-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "CashGuard Device Mac Oui Rule 1",
          "ruleId": "CashGuard-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cashmaster-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cashmaster Device Mac Oui Rule 1",
          "ruleId": "Cashmaster-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Castles-Technology-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Castles Technology Device Mac Oui Rule 1",
          "ruleId": "Castles-Technology-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Caterpillar-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Caterpillar Device Mac Oui Rule 1",
          "ruleId": "Caterpillar-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Caterpillar-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Caterpillar Device Mac Oui Rule 2",
          "ruleId": "Caterpillar-Device-Rule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Caterpillar-Trimble-Control-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Caterpillar Trimble Control Device Mac Oui Rule 1",
          "ruleId": "Caterpillar-Trimble-Control-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cent Os User Agent Rule",
          "ruleId": "centOsUserAgentRule",
          "operatingSystem": [
            "CentOS"
          ],
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "CentraLite-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "CentraLite Systems Device Mac Oui Rule 1",
          "ruleId": "CentraLite-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Chrome Os User Agent Rule",
          "ruleId": "chromeOsUserAgentRule",
          "operatingSystem": [
            "Chrome OS"
          ],
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Chubb-Electronic-Security-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Chubb Electronic Security Systems Device Mac Oui Rule 1",
          "ruleId": "Chubb-Electronic-Security-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Circontrol-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Circontrol Device Mac Oui Rule 1",
          "ruleId": "Circontrol-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "cdpCachePlatform"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Access Point Cdp Platform Rule",
          "ruleId": "ciscoAccessPointCdpPlatformRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "dhcpClassIdentifier"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Access-Point",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Access Point Dhcp Class Identifier Rule",
          "ruleId": "ciscoAccessPointDhcpIdentifierRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "dhcpClassIdentifier"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Access Point Dhcp Class Identifier Rule 2",
          "ruleId": "ciscoAccessPointDhcpIdentifierRule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "dhcpParameterRequestList"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Access-Point",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Access Point Dhcp Fingerprint Rule",
          "ruleId": "ciscoAccessPointDhcpFingerprintRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "dhcpv6VendorClass"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Access-Point",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Access Point DhcpV6 Class Identifier Rule",
          "ruleId": "ciscoAccessPointDhcpV6IdentifierRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "cdpCachePlatform"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Device Cdp Platform Rule",
          "ruleId": "ciscoDeviceCdpPlatformRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "lldpSystemDescription"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Device Lldp System Description Rule",
          "ruleId": "ciscoDeviceLldpSysDescRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Device Mac Oui Rule 1",
          "ruleId": "ciscoDeviceMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Device Mac Oui Rule 2",
          "ruleId": "ciscoDeviceMacOuiRule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Device Mac Oui Rule 3",
          "ruleId": "ciscoDeviceMacOuiRule3",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Device Mac Oui Rule 4",
          "ruleId": "ciscoDeviceMacOuiRule4",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "operatingSystem"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Device Os Rule",
          "ruleId": "ciscoDeviceOsRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "sysDescr"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Device Snmp Rule",
          "ruleId": "ciscoDeviceSnmpRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "dhcpClassIdentifier"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Dhcp Class Identifier Rule",
          "ruleId": "ciscoDhcpClassIdRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "dhcpClassIdentifier"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Dmp Dhcp Class Identifier Rule",
          "ruleId": "ciscoDmpDhcpIdentifierRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-DMP",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Dmp Mac Oui Rule",
          "ruleId": "ciscoDmpMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "cdpCacheVersion"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Ios Cdp Cache Version Rule",
          "ruleId": "ciscoIosCdpCacheVersionRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "lldpSystemDescription"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Ios Lldp System Decription Rule",
          "ruleId": "ciscoIosLldpSystemDescriptionRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "IP Phone"
          ],
          "usedAttributes": [
            "oui",
            "cdpCacheCapabilities"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-IP-Phone",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Ip Phone Cdp Cache Capabilities Rule",
          "ruleId": "ciscoIpPhoneCdpCacheCapabilitiesRule",
          "operatingSystem": [
            "Cisco IP Phone"
          ],
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "cdpCachePlatform"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Ip Phone Cdp Platform Rule 1",
          "ruleId": "ciscoIpPhoneCdpPlatformRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "IP Phone"
          ],
          "usedAttributes": [
            "cdpCachePlatform"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-IP-Phone",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Ip Phone Cdp Platform Rule 2",
          "ruleId": "ciscoIpPhoneCdpPlatformRule2",
          "operatingSystem": [
            "Cisco IP Phone"
          ],
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "IP Phone"
          ],
          "usedAttributes": [
            "dhcpClassIdentifier"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-IP-Phone",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Ip Phone Dhcp Class Identifier Rule 1",
          "ruleId": "ciscoIpPhoneDhcpIdentifierRule1",
          "operatingSystem": [
            "Cisco IP Phone"
          ],
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "dhcpClassIdentifier"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Ip Phone Dhcp Class Identifier Rule 2",
          "ruleId": "ciscoIpPhoneDhcpIdentifierRule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "IP Phone"
          ],
          "usedAttributes": [
            "dhcpClassIdentifier"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-IP-Phone",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Ip Phone Dhcp Class Identifier Rule 3",
          "ruleId": "ciscoIpPhoneDhcpIdentifierRule3",
          "operatingSystem": [
            "Cisco IP Phone"
          ],
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "IP Phone"
          ],
          "usedAttributes": [
            "dhcpv6VendorClass"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-IP-Phone",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Ip Phone DhcpV6 Class Identifier Rule 1",
          "ruleId": "ciscoIpPhoneDhcpV6IdentifierRule1",
          "operatingSystem": [
            "Cisco IP Phone"
          ],
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "IP Phone"
          ],
          "usedAttributes": [
            "dhcpv6VendorClass"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-IP-Phone",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Ip Phone DhcpV6 Class Identifier Rule 2",
          "ruleId": "ciscoIpPhoneDhcpV6IdentifierRule2",
          "operatingSystem": [
            "Cisco IP Phone"
          ],
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "IP Phone"
          ],
          "usedAttributes": [
            "dhcpv6VendorClass"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-IP-Phone",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Ip Phone DhcpV6 Class Identifier Rule 3",
          "ruleId": "ciscoIpPhoneDhcpV6IdentifierRule3",
          "operatingSystem": [
            "Cisco IP Phone"
          ],
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "IP Phone"
          ],
          "usedAttributes": [
            "oui",
            "lldpCacheCapabilities"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-IP-Phone",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Ip Phone Lldp Cache Capabilities Rule",
          "ruleId": "ciscoIpPhoneLldpCacheCapabilitiesRule",
          "operatingSystem": [
            "Cisco IP Phone"
          ],
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "IP Phone"
          ],
          "usedAttributes": [
            "lldpSystemDescription"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-IP-Phone",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Ip Phone Lldp System Description Rule",
          "ruleId": "ciscoIpPhoneLldpSysDescRule",
          "operatingSystem": [
            "Cisco IP Phone"
          ],
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "IP Phone"
          ],
          "usedAttributes": [
            "operatingSystem"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-IP-Phone",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Ip Phone Os Rule",
          "ruleId": "ciscoIpPhoneOsRule",
          "operatingSystem": [
            "Cisco IP Phone"
          ],
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Ip Phone User Agent Rule 1",
          "ruleId": "ciscoIpPhoneUserAgentRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "hardwareModel",
            "lldpSystemDescription"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Meraki-Access-Point",
            "Cisco-Meraki-Device",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Meraki Access Point Lldp System Description Rule",
          "ruleId": "ciscoMerakiAccessPointLldpSysDescRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Meraki-Device",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Meraki Device Mac Oui Rule",
          "ruleId": "ciscoMerakiDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "hardwareModel",
            "lldpSystemDescription"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Meraki-Security-Appliance",
            "Cisco-Meraki-Device",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Meraki Security Appliance Lldp System Description Rule",
          "ruleId": "ciscoMerakiSecurityApplianceLldpSysDescRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "hardwareModel",
            "cdpCachePlatform"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Meraki-Switch",
            "Cisco-Meraki-Device",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Meraki Switch Cdp Platform Rule",
          "ruleId": "ciscoMerakiSwitchCdpPlatformRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "hardwareModel",
            "lldpSystemDescription"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Meraki-Switch",
            "Cisco-Meraki-Device",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Meraki Switch Lldp System Description Rule",
          "ruleId": "ciscoMerakiSwitchLldpSysDescRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "lldpSystemDescription"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Nexus Switch Lldp Model Rule",
          "ruleId": "ciscoNexusSwitchLldpModelRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "lldpSystemDescription"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Nexus Switch Lldp OS Rule",
          "ruleId": "ciscoNexusSwitchLldpOsRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "cdpCacheCapabilities"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Router",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Router Cdp Cache Capabilities Rule",
          "ruleId": "ciscoRouteCdpCacheCapabilitiesRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "cdpCachePlatform"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Router",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Router Cdp Platform Rule",
          "ruleId": "ciscoRouterCdpPlatformRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "oui",
            "lldpCacheCapabilities"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Router",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Router Lldp Cache Capabilities Rule",
          "ruleId": "ciscoRouterLldpCacheCapabilitiesRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "lldpSystemDescription"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Router",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Router Lldp System Description Rule",
          "ruleId": "ciscoRouterLldpSysDescRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "oui",
            "cdpCacheCapabilities"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Switch",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Switch Cdp Cache Capabilities Rule",
          "ruleId": "ciscoSwitchCdpCacheCapabilitiesRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "cdpCachePlatform"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Switch",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Switch Cdp Platform Rule 1",
          "ruleId": "ciscoSwitchCdpPlatformRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "cdpCachePlatform"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Switch Cdp Platform Rule 2",
          "ruleId": "ciscoSwitchCdpPlatformRule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "lldpSystemDescription"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Switch",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Switch Lldp System Description Rule",
          "ruleId": "ciscoSwitchLldpSysDescRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "operatingSystem"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Switch",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Switch Os Rule 1",
          "ruleId": "CiscoSwitchOsRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "operatingSystem"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Switch",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Switch Os Rule 2",
          "ruleId": "CiscoSwitchOsRule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "sysDescr"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Switch",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Switch Snmp Rule",
          "ruleId": "ciscoSwitchSnmpRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dhcpParameterRequestList"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Tandberg-Device",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Tandberg Device Dhcp Fingerprint Rule 1",
          "ruleId": "ciscoTandbergDeviceDhcpFingerPrintRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dhcpParameterRequestList"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Tandberg-Device",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Tandberg Device Dhcp Fingerprint Rule 2",
          "ruleId": "ciscoTandbergDeviceDhcpFingerPrintRule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "cdpCachePlatform"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-TelePresence",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Telepresence Cdp Platform Rule",
          "ruleId": "ciscoTelepresenceCdpPlatformRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "cdpCachePlatform"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Unified Computing System Cdp Rule 1",
          "ruleId": "ciscoUcsCdpRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "cdpCacheVersion"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Unified Computing System Cdp Rule 2",
          "ruleId": "ciscoUcsCdpRule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "operatingSystem"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-WLC",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco WLC Os Rule",
          "ruleId": "CiscoWLCOsRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "cdpCacheVersion"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-WLC",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Wlc Cdp Cache Version Rule",
          "ruleId": "ciscoWlcCdpCacheVersionRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "cdpCachePlatform"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Wlc Cdp Platform Rule",
          "ruleId": "ciscoWlcCdpPlatformRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Network Device"
          ],
          "usedAttributes": [
            "sysDescr"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cisco-WLC",
            "Cisco-Device"
          ],
          "hardwareManufacturer": [
            "Cisco Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cisco Wlc Snmp Rule",
          "ruleId": "ciscoWlcSnmpRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Clare-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Clare Controls Device Mac Oui Rule 1",
          "ruleId": "Clare-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Colorado-vNet-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Colorado vNet Device Mac Oui Rule 1",
          "ruleId": "Colorado-vNet-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Companytec-Automation-Control-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Companytec Automation Control Device Mac Oui Rule 1",
          "ruleId": "Companytec-Automation-Control-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Compex-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Compex Device Mac Oui Rule",
          "ruleId": "compexDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Compressor-Controls-Corp-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Compressor Controls Corp Device Mac Oui Rule 1",
          "ruleId": "Compressor-Controls-Corp-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Computerized-Elevator-Contol-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Computerized Elevator Contol Device Mac Oui Rule 1",
          "ruleId": "Computerized-Elevator-Contol-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Computerized Elevator Contol"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Computerized Elevator Contol Manufacturer Mac Oui Rule 1",
          "ruleId": "computerizedElevatorContolManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Comtrol-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Comtrol Device Mac Oui Rule 1",
          "ruleId": "Comtrol-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Connected-Home-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Connected Home Device Mac Oui Rule 1",
          "ruleId": "Connected-Home-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Contemporary-Control-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Contemporary Control Device Mac Oui Rule 1",
          "ruleId": "Contemporary-Control-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Control and Robotics Solutions"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Control And Robotics Solutions Manufacturer Mac Oui Rule 1",
          "ruleId": "controlAndRoboticsSolutionsManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Control-Applications-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Control Applications Device Mac Oui Rule 1",
          "ruleId": "Control-Applications-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Control-Dynamics-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Control Dynamics Device Mac Oui Rule 1",
          "ruleId": "Control-Dynamics-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Control-Express-Finland-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Control Express Finland Device Mac Oui Rule 1",
          "ruleId": "Control-Express-Finland-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Control-Microsystems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Control Microsystems Device Mac Oui Rule 1",
          "ruleId": "Control-Microsystems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Control-Module-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Control Module Device Mac Oui Rule 1",
          "ruleId": "Control-Module-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Control-Products-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Control Products Device Mac Oui Rule 1",
          "ruleId": "Control-Products-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Control-Resources-Corp-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Control Resources Corp Device Mac Oui Rule 1",
          "ruleId": "Control-Resources-Corp-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Control-Robotics-Solutions-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Control Robotics Solutions Device Mac Oui Rule 1",
          "ruleId": "Control-Robotics-Solutions-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Control-Sistematizado-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Control Sistematizado Device Mac Oui Rule 1",
          "ruleId": "Control-Sistematizado-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Control-Solutions-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Control Solutions Device Mac Oui Rule 1",
          "ruleId": "Control-Solutions-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Control-Solutions-Inc-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Control Solutions Inc Device Mac Oui Rule 1",
          "ruleId": "Control-Solutions-Inc-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Control-Techniques-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Control Techniques Device Mac Oui Rule 1",
          "ruleId": "Control-Techniques-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266339
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Control-Technology-Corp-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Control Technology Corp Device Mac Oui Rule 1",
          "ruleId": "Control-Technology-Corp-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Control-Technology-Inc-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Control Technology Inc Device Mac Oui Rule 1",
          "ruleId": "Control-Technology-Inc-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Control-Technology-Inc-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Control Technology Inc Device Mac Oui Rule 2",
          "ruleId": "Control-Technology-Inc-Device-Rule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Control-iD-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Control iD Device Mac Oui Rule 1",
          "ruleId": "Control-iD-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Control4-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Control4 Device Mac Oui Rule 1",
          "ruleId": "Control4-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ControlNet-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ControlNet Device Mac Oui Rule 1",
          "ruleId": "ControlNet-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ControlThings-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ControlThings Device Mac Oui Rule 1",
          "ruleId": "ControlThings-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Controlled-Electronic-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Controlled Electronic Device Mac Oui Rule 1",
          "ruleId": "Controlled-Electronic-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Controlled-Environments-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Controlled Environments Device Mac Oui Rule 1",
          "ruleId": "Controlled-Environments-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Convergent Bioscience Ltd."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Convergent Bioscience Ltd Manufacturer Mac Oui Rule 1",
          "ruleId": "convergentBioscienceLtd-ManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cool-Control-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cool Control Device Mac Oui Rule 1",
          "ruleId": "Cool-Control-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cooper-Industries-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cooper Industries Device Mac Oui Rule 1",
          "ruleId": "Cooper-Industries-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Copley-Controls-Corp-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Copley Controls Corp Device Mac Oui Rule 1",
          "ruleId": "Copley-Controls-Corp-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Creative Lighting And Sound Systems Pty Ltd"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Creative Lighting And Sound Systems Pty Ltd Manufacturer Mac Oui Rule 1",
          "ruleId": "creativeLightingAndSoundSystemsPtyLtdManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Creative-Lighting-Sound-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Creative Lighting Sound Device Mac Oui Rule 1",
          "ruleId": "Creative-Lighting-Sound-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cree-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cree Device Mac Oui Rule 1",
          "ruleId": "Cree-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Crestron-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Crestron Device Mac Oui Rule",
          "ruleId": "crestronDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Crestron-Electronics-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Crestron Electronics Device Mac Oui Rule 1",
          "ruleId": "Crestron-Electronics-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Crestron Electronics"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Crestron Electronics Manufacturer Mac Oui Rule 1",
          "ruleId": "CrestronElectronicsManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Crisp-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Crisp Automation Device Mac Oui Rule 1",
          "ruleId": "Crisp-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Ctek-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Ctek Device Mac Oui Rule 1",
          "ruleId": "Ctek-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cummins-Allison-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cummins Allison Device Mac Oui Rule 1",
          "ruleId": "Cummins-Allison-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Curtiss-Wright-Avionics-Electronics-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Curtiss Wright Avionics Electronics Device Mac Oui Rule 1",
          "ruleId": "Curtiss-Wright-Avionics-Electronics-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Curtiss-Wright-Embedded-Computing-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Curtiss Wright Embedded Computing Device Mac Oui Rule 1",
          "ruleId": "Curtiss-Wright-Embedded-Computing-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Custom-Control-Concepts-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Custom Control Concepts Device Mac Oui Rule 1",
          "ruleId": "Custom-Control-Concepts-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cyber-Power-System-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cyber Power System Device Mac Oui Rule",
          "ruleId": "cyberPowerSystemDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Cylon-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Cylon Controls Device Mac Oui Rule 1",
          "ruleId": "Cylon-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "DASA-Robot-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "DASA Robot Device Mac Oui Rule 1",
          "ruleId": "DASA-Robot-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "DEOS-Control-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "DEOS Control Systems Device Mac Oui Rule 1",
          "ruleId": "DEOS-Control-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "DRS-Consolidated-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "DRS Consolidated Controls Device Mac Oui Rule 1",
          "ruleId": "DRS-Consolidated-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "DVTel-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "DVTel Device Mac Oui Rule 1",
          "ruleId": "DVTel-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Daeyeon-Control-Instrument-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Daeyeon Control Instrument Device Mac Oui Rule 1",
          "ruleId": "Daeyeon-Control-Instrument-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Dai-ichi-Dentsu-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dai ichi Dentsu Device Mac Oui Rule 1",
          "ruleId": "Dai-ichi-Dentsu-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Dalian-Seasky-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dalian Seasky Automation Device Mac Oui Rule 1",
          "ruleId": "Dalian-Seasky-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "DanControl-Engineering-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "DanControl Engineering Device Mac Oui Rule 1",
          "ruleId": "DanControl-Engineering-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Darfon-Lighting-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Darfon Lighting Device Mac Oui Rule 1",
          "ruleId": "Darfon-Lighting-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "DASA ROBOT Co., Ltd."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dasa Robot Co Ltd Manufacturer Mac Oui Rule 1",
          "ruleId": "dasaRobotCo-Ltd-ManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Dassault-Electronique-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dassault Electronique Device Mac Oui Rule 1",
          "ruleId": "Dassault-Electronique-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Data-Control-Equipment-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Data Control Equipment Device Mac Oui Rule 1",
          "ruleId": "Data-Control-Equipment-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Datasym-POS-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Datasym POS Device Mac Oui Rule 1",
          "ruleId": "Datasym-POS-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "DeLaval-International-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "DeLaval International Device Mac Oui Rule 1",
          "ruleId": "DeLaval-International-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Debian User Agent Rule",
          "ruleId": "debianUserAgentRule",
          "operatingSystem": [
            "Debian"
          ],
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Dell-Device"
          ],
          "hardwareManufacturer": [
            "Dell Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dell  Device Mac Oui Rule",
          "ruleId": "dellDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Delta-Control-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Delta Control Device Mac Oui Rule 1",
          "ruleId": "Delta-Control-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Delta-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Delta Controls Device Mac Oui Rule 1",
          "ruleId": "Delta-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Delta-Danish-Electronics-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Delta Danish Electronics Device Mac Oui Rule 1",
          "ruleId": "Delta-Danish-Electronics-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Delta-Electronics-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Delta Electronics Device Mac Oui Rule 1",
          "ruleId": "Delta-Electronics-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Delta-Elektronika-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Delta Elektronika Device Mac Oui Rule 1",
          "ruleId": "Delta-Elektronika-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Denon-Device"
          ],
          "hardwareManufacturer": [
            "Denon"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Denon Manufacturer Rule",
          "ruleId": "denonManufacturerRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui",
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Denon-Device"
          ],
          "hardwareManufacturer": [
            "Denon"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Denon User Agent And Mac Oui Rule",
          "ruleId": "denonUserAgentAndMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Denon-Device"
          ],
          "hardwareManufacturer": [
            "Denon"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Denon User Agent Rule",
          "ruleId": "denonUserAgentRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Deutschmann-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Deutschmann Automation Device Mac Oui Rule 1",
          "ruleId": "Deutschmann-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Radiology PACS software",
            "Workstation"
          ],
          "usedAttributes": [
            "dicomApplicationEntity"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Application Entity Pacs Rule",
          "ruleId": "DicomApplicationEntityPacsRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dicomManufacturer"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [],
          "ruleType": "Cisco Default - Dynamic",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Manufacturer Rule",
          "ruleId": "DicomManufacturerRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dicomManufacturerModelName"
          ],
          "conditionGroups": null,
          "hardwareModel": [],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Dynamic",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Model Name Rule",
          "ruleId": "dicomModelNameRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "stationary mammographic x-ray system, digital",
            "Medical Device"
          ],
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Seno Iris"
          ],
          "hardwareManufacturer": [
            "GE Healthcare"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Uid 1-2-276-0-69-25-1-1  Rule",
          "ruleId": "DicomUid1-2-276-0-69-25-1-1Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Computed radiography digital imaging scanner",
            "Medical Device"
          ],
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "REGIUS CONSOLE CS-3"
          ],
          "hardwareManufacturer": [
            "Konica Minolta"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Uid 1-2-392-200036-9107-500-305  Rule",
          "ruleId": "DicomUid1-2-392-200036-9107-500-305Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Diagnostic x-ray digital imaging system workstation application software",
            "Medical Device"
          ],
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "CS-7"
          ],
          "hardwareManufacturer": [
            "Konica Minolta"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Uid 1-2-392-200036-9107-632-1  Rule",
          "ruleId": "DicomUid1-2-392-200036-9107-632-1Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "Indexed-immobilization patient positioning system",
            "Medical Device"
          ],
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Aquilion"
          ],
          "hardwareManufacturer": [
            "TOSHIBA"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Uid 1-2-392-200036-9116-2-6-1-100  Rule",
          "ruleId": "DicomUid1-2-392-200036-9116-2-6-1-100Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "General-purpose ultrasound imaging system",
            "Medical Device"
          ],
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "CS-7"
          ],
          "hardwareManufacturer": [
            "Hitachi"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Uid 1-2-392-200036-9123-100-14-15  Rule",
          "ruleId": "DicomUid1-2-392-200036-9123-100-14-15Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Computed radiography digital imaging scanner",
            "Medical Device"
          ],
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "CR Console"
          ],
          "hardwareManufacturer": [
            "Fujifilm"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Uid 1-2-392-200036-9125-5342-1-  Rule",
          "ruleId": "DicomUid1-2-392-200036-9125-5342-1-Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Radiology DICOM image processing application software",
            "Medical Device"
          ],
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Centricity Radiology"
          ],
          "hardwareManufacturer": [
            "General Electric (GE) Medical Systems Nederland BV"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Uid 1-2-528-1-1001-2-20060808-1  Rule",
          "ruleId": "DicomUid1-2-528-1-1001-2-20060808-1Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Medical image management system",
            "Workstation"
          ],
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Centricity Enterprise Archive product"
          ],
          "hardwareManufacturer": [
            "General Electric (GE) Medical Systems Nederland BV"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Uid 1-2-528-1-1001-2-800  Rule",
          "ruleId": "DicomUid1-2-528-1-1001-2-800Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "CD Burner",
            "Radiology DICOM image processing application software",
            "Medical Device"
          ],
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Media Center R.2.3"
          ],
          "hardwareManufacturer": [
            "Philips Medical Systems"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Uid 1-2-826-0-1-3680043-8-195-0-200410  Rule",
          "ruleId": "DicomUid1-2-826-0-1-3680043-8-195-0-200410Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Nuclear medicine system workstation",
            "Medical Device"
          ],
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Xeleris"
          ],
          "hardwareManufacturer": [
            "GE Healthcare"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Uid 1-2-840-113619-6-124  Rule",
          "ruleId": "DicomUid1-2-840-113619-6-124Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "General-purpose ultrasound imaging system",
            "Medical Device"
          ],
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "LOGIQ"
          ],
          "hardwareManufacturer": [
            "GE Healthcare"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Uid 1-2-840-113619-6-256  Rule",
          "ruleId": "DicomUid1-2-840-113619-6-256Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "Medical image management system",
            "Medical Device"
          ],
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Infinia"
          ],
          "hardwareManufacturer": [
            "General Electric (GE) Medical Systems Nederland BV"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Uid 1-2-840-113619-6-265  Rule",
          "ruleId": "DicomUid1-2-840-113619-6-265Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Medical image management system",
            "Medical Device"
          ],
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Vivid E90"
          ],
          "hardwareManufacturer": [
            "General Electric (GE) Medical Systems Nederland BV"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Uid 1-2-840-113619-6-390-  Rule",
          "ruleId": "DicomUid1-2-840-113619-6-390-Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Medical image management system",
            "Medical Device"
          ],
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Vivid E95"
          ],
          "hardwareManufacturer": [
            "General Electric (GE) Medical Systems Nederland BV"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Uid 1-2-840-113619-6-391-  Rule",
          "ruleId": "DicomUid1-2-840-113619-6-391-Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Medical image management system",
            "Medical Device"
          ],
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Vivid E80"
          ],
          "hardwareManufacturer": [
            "General Electric (GE) Medical Systems Nederland BV"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Uid 1-2-840-113619-6-392-  Rule",
          "ruleId": "DicomUid1-2-840-113619-6-392-Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Medical image management system",
            "Medical Device"
          ],
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Vivid S60N"
          ],
          "hardwareManufacturer": [
            "General Electric (GE) Medical Systems Nederland BV"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Uid 1-2-840-113619-6-393-  Rule",
          "ruleId": "DicomUid1-2-840-113619-6-393-Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "Medical image management system",
            "Medical Device"
          ],
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Vivid S70N"
          ],
          "hardwareManufacturer": [
            "General Electric (GE) Medical Systems Nederland BV"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Uid 1-2-840-113619-6-394-  Rule",
          "ruleId": "DicomUid1-2-840-113619-6-394-Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Medical image management system",
            "Medical Device"
          ],
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Vivid T8"
          ],
          "hardwareManufacturer": [
            "General Electric (GE) Medical Systems Nederland BV"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Uid 1-2-840-113619-6-400-  Rule",
          "ruleId": "DicomUid1-2-840-113619-6-400-Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266339
        },
        {
          "deviceType": [
            "Medical image management system",
            "Medical Device"
          ],
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Vivid iq"
          ],
          "hardwareManufacturer": [
            "General Electric (GE) Medical Systems Nederland BV"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Uid 1-2-840-113619-6-435-  Rule",
          "ruleId": "DicomUid1-2-840-113619-6-435-Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "Medical image management system",
            "Medical Device"
          ],
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Vivid T9"
          ],
          "hardwareManufacturer": [
            "General Electric (GE) Medical Systems Nederland BV"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Uid 1-2-840-113619-6-460-  Rule",
          "ruleId": "DicomUid1-2-840-113619-6-460-Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Radiology PACS software",
            "Medical Device"
          ],
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Centricity PACS"
          ],
          "hardwareManufacturer": [
            "GE Healthcare"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Uid 1-2-840-113619-6-94  Rule",
          "ruleId": "DicomUid1-2-840-113619-6-94Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Radiology PACS software",
            "Medical Device"
          ],
          "usedAttributes": [
            "dicomImplementationUid",
            "dicomImplementationVer"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "EIS V3"
          ],
          "hardwareManufacturer": [
            "Carestream Health"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Uid 1-2-840-113674-6-15-95 Ver -xX--iI--sS----wW- Rule",
          "ruleId": "DicomUid1-2-840-113674-6-15-95Ver-xX--iI--sS----wW-Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Radiology PACS software",
            "Medical Device"
          ],
          "usedAttributes": [
            "dicomImplementationUid",
            "dicomImplementationVer"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Carestream PACS"
          ],
          "hardwareManufacturer": [
            "Carestream Health"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Uid 1-2-840-113704-7-0-2 Ver DCM Pro-- Rule",
          "ruleId": "DicomUid1-2-840-113704-7-0-2VerDCMPro--Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Medical video image recorder",
            "Medical Device"
          ],
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "MDR Video"
          ],
          "hardwareManufacturer": [
            "Hyland"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Uid 1-2-840-113742-2-200  Rule",
          "ruleId": "DicomUid1-2-840-113742-2-200Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Stationary mammographic x-ray system, digital",
            "Medical Device"
          ],
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Selenia Dimensions"
          ],
          "hardwareManufacturer": [
            "Hologic, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Uid 1-2-840-114089-1-0-0  Rule",
          "ruleId": "DicomUid1-2-840-114089-1-0-0Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266339
        },
        {
          "deviceType": [
            "General-purpose ultrasound imaging system",
            "Medical Device"
          ],
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Edge"
          ],
          "hardwareManufacturer": [
            "SonoSite, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Uid 1-2-840-114340-3  Rule",
          "ruleId": "DicomUid1-2-840-114340-3Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "General-purpose ultrasound imaging system",
            "Medical Device"
          ],
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "iU22 - iE33  Ultrasound System"
          ],
          "hardwareManufacturer": [
            "Philips Ultrasound, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Uid 1-3-46-670589-5-2-10  Rule",
          "ruleId": "DicomUid1-3-46-670589-5-2-10Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "CT system workstation",
            "Medical Device"
          ],
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "IntelliSpace Portal"
          ],
          "hardwareManufacturer": [
            "Philips Medical Systems"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Uid 1-3-46-670589-50-1  Rule",
          "ruleId": "DicomUid1-3-46-670589-50-1Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Radiography digital imaging scanner",
            "Medical Device"
          ],
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "PACS Scan"
          ],
          "hardwareManufacturer": [
            "Hyland"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dicom Uid 1-3-6-1-4-1-23849-1  Rule",
          "ruleId": "DicomUid1-3-6-1-4-1-23849-1Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Digi-International-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Digi International Device Mac Oui Rule 2",
          "ruleId": "Digi-International-Device-Rule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "DigiBoard-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "DigiBoard Device Mac Oui Rule 1",
          "ruleId": "DigiBoard-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Digital-Control-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Digital Control Device Mac Oui Rule 1",
          "ruleId": "Digital-Control-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Digital-Lighting-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Digital Lighting Systems Device Mac Oui Rule 1",
          "ruleId": "Digital-Lighting-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Digital-Payment-Technologies-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Digital Payment Technologies Device Mac Oui Rule 1",
          "ruleId": "Digital-Payment-Technologies-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Digitronic-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Digitronic Device Mac Oui Rule 1",
          "ruleId": "Digitronic-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Direct-Payment-Solutions-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Direct Payment Solutions Device Mac Oui Rule 1",
          "ruleId": "Direct-Payment-Solutions-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "DLink-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dlink Device Mac Oui Rule",
          "ruleId": "dlinkDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Draeger-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Draeger Device Mac Oui Rule",
          "ruleId": "draegerDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Dynacolor-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Dynacolor Device Mac Oui Rule 1",
          "ruleId": "Dynacolor-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "E-Control-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "E Control Systems Device Mac Oui Rule 1",
          "ruleId": "E-Control-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "EControls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "EControls Device Mac Oui Rule 1",
          "ruleId": "EControls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "EKE-Building-Technology-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "EKE Building Technology Device Mac Oui Rule 1",
          "ruleId": "EKE-Building-Technology-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ELAN-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ELAN Systems Device Mac Oui Rule 1",
          "ruleId": "ELAN-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ELC-Lighting-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ELC Lighting Device Mac Oui Rule 1",
          "ruleId": "ELC-Lighting-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ELPRO-Technologies-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ELPRO Technologies Device Mac Oui Rule 1",
          "ruleId": "ELPRO-Technologies-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Eagle-Traffic-Control-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Eagle Traffic Control Device Mac Oui Rule 1",
          "ruleId": "Eagle-Traffic-Control-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Eaton-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Eaton Automation Device Mac Oui Rule 1",
          "ruleId": "Eaton-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Eaton-Corporation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Eaton Corporation Device Mac Oui Rule 1",
          "ruleId": "Eaton-Corporation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Eaton-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Eaton Device Mac Oui Rule 1",
          "ruleId": "Eaton-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Eaton-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Eaton Device Mac Oui Rule 2",
          "ruleId": "Eaton-Device-Rule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Eaton-FHF-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Eaton FHF Device Mac Oui Rule 1",
          "ruleId": "Eaton-FHF-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "EATON FHF Funke + Huster Fernsig GmbH"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Eaton Fhf Funke Huster Fernsig Gmbh Manufacturer Mac Oui Rule 1",
          "ruleId": "eatonFhfFunke-HusterFernsigGmbhManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Eaton-Industries-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Eaton Industries Device Mac Oui Rule 1",
          "ruleId": "Eaton-Industries-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Eaton-Pulizzi-Electrical-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Eaton Pulizzi Electrical Device Mac Oui Rule 1",
          "ruleId": "Eaton-Pulizzi-Electrical-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Mobile Device"
          ],
          "usedAttributes": [
            "oui",
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Amazon-Echo",
            "Amazon-Device"
          ],
          "hardwareManufacturer": [
            "Amazon.com, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Echo User Agent Rule",
          "ruleId": "echoUserAgentRule",
          "operatingSystem": [
            "Fire OS"
          ],
          "lastModifiedOn": 1589339266339
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "hardwareManufacturer",
            "dhcpClassIdentifier"
          ],
          "conditionGroups": null,
          "hardwareModel": [],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Dynamic",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Ecobee Dhcp Class Identifier Rule",
          "ruleId": "ecobeeDhcpClassIdentifierRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "IOT Device"
          ],
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Ecobee-Device"
          ],
          "hardwareManufacturer": [
            "ecobee Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Ecobee Mac Oui Rule",
          "ruleId": "ecobeeMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "mdnsMd",
            "hardwareManufacturer"
          ],
          "conditionGroups": null,
          "hardwareModel": [],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Dynamic",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Ecobee Mdns Md Rule",
          "ruleId": "ecobeeMdnsMdRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Econolite-Control-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Econolite Control Device Mac Oui Rule 1",
          "ruleId": "Econolite-Control-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Eddy-Smart-Home-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Eddy Smart Home Device Mac Oui Rule 1",
          "ruleId": "Eddy-Smart-Home-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Edwards-Ltd-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Edwards Ltd Device Mac Oui Rule 1",
          "ruleId": "Edwards-Ltd-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Elan Systems"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Elan Systems Manufacturer Mac Oui Rule 1",
          "ruleId": "elanSystemsManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "ELC Lighting"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Elc Lighting Manufacturer Mac Oui Rule 1",
          "ruleId": "elcLightingManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Electric-Controller-Manufacturing-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Electric Controller Manufacturing Device Mac Oui Rule 1",
          "ruleId": "Electric-Controller-Manufacturing-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Electronic-Theatre-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Electronic Theatre Controls Device Mac Oui Rule 1",
          "ruleId": "Electronic-Theatre-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Eliwell-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Eliwell Controls Device Mac Oui Rule 1",
          "ruleId": "Eliwell-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Elk-Products-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Elk Products Device Mac Oui Rule 1",
          "ruleId": "Elk-Products-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Elk Products"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Elk Products Manufacturer Mac Oui Rule 1",
          "ruleId": "elkProductsManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Ellex Medical Pty Ltd"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Ellex Medical Pty Ltd Manufacturer Mac Oui Rule 1",
          "ruleId": "ellexMedicalPtyLtdManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Elmo-Motion-Control-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Elmo Motion Control Device Mac Oui Rule 1",
          "ruleId": "Elmo-Motion-Control-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Embedded-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Embedded Automation Device Mac Oui Rule 1",
          "ruleId": "Embedded-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "EMC-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Emc Device Mac Oui Rule",
          "ruleId": "emcDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Emerson-Appliance-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Emerson Appliance Device Mac Oui Rule 1",
          "ruleId": "Emerson-Appliance-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Emerson-Climate-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Emerson Climate Device Mac Oui Rule 1",
          "ruleId": "Emerson-Climate-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Emerson-Climate-Retail-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Emerson Climate Retail Device Mac Oui Rule 1",
          "ruleId": "Emerson-Climate-Retail-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Emerson-Climate-Transportation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Emerson Climate Transportation Device Mac Oui Rule 1",
          "ruleId": "Emerson-Climate-Transportation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Emerson-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Emerson Device Mac Oui Rule 1",
          "ruleId": "Emerson-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Emerson-Energy-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Emerson Energy Systems Device Mac Oui Rule 1",
          "ruleId": "Emerson-Energy-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Emerson-Network-Power-Avocent-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Emerson Network Power Avocent Device Mac Oui Rule 1",
          "ruleId": "Emerson-Network-Power-Avocent-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Emerson-Network-Power-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Emerson Network Power Device Mac Oui Rule 1",
          "ruleId": "Emerson-Network-Power-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Emerson-Power-Water-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Emerson Power Water Device Mac Oui Rule 1",
          "ruleId": "Emerson-Power-Water-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Enovation-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Enovation Controls Device Mac Oui Rule 1",
          "ruleId": "Enovation-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Enterasys-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Enterasys Device Mac Oui Rule",
          "ruleId": "enterasysDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Epson-Device"
          ],
          "hardwareManufacturer": [
            "Seiko Epson Corporation"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Epson Device Mac Oui Rule",
          "ruleId": "epsonDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Equinox-Payments-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Equinox Payments Device Mac Oui Rule 1",
          "ruleId": "Equinox-Payments-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "EquipTrans-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Equiptrans Device Mac Oui Rule",
          "ruleId": "equiptransDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Estun-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Estun Automation Device Mac Oui Rule 1",
          "ruleId": "Estun-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Eurotherm-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Eurotherm Controls Device Mac Oui Rule 1",
          "ruleId": "Eurotherm-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Exceptional-Innovation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Exceptional Innovation Device Mac Oui Rule 1",
          "ruleId": "Exceptional-Innovation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Extreme-Networks-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Extreme Networks Device Mac Oui Rule",
          "ruleId": "extremeNetworksDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266339
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "F-Robotics-Acquisitions-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "F Robotics Acquisitions Device Mac Oui Rule 1",
          "ruleId": "F-Robotics-Acquisitions-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "F5-Networks-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "F5 Networks Device Mac Oui Rule",
          "ruleId": "f5NetworksDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "FAG-Industrial-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "FAG Industrial Device Mac Oui Rule 1",
          "ruleId": "FAG-Industrial-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "FANUC-Robotics-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "FANUC Robotics Device Mac Oui Rule 1",
          "ruleId": "FANUC-Robotics-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "FATEK-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "FATEK Automation Device Mac Oui Rule 1",
          "ruleId": "FATEK-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "FS-Forth-Systeme-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "FS Forth Systeme Device Mac Oui Rule 1",
          "ruleId": "FS-Forth-Systeme-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "FUJI-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "FUJI Automation Device Mac Oui Rule 1",
          "ruleId": "FUJI-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "FUJI-Electric-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "FUJI Electric Device Mac Oui Rule 1",
          "ruleId": "FUJI-Electric-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Fagor-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Fagor Automation Device Mac Oui Rule 1",
          "ruleId": "Fagor-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Fairmount-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Fairmount Automation Device Mac Oui Rule 1",
          "ruleId": "Fairmount-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Fedora User Agent Rule",
          "ruleId": "fedoraUserAgentRule",
          "operatingSystem": [
            "Fedora"
          ],
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Fisher-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Fisher Controls Device Mac Oui Rule 1",
          "ruleId": "Fisher-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Fluke Biomedical, Radiation Management Services"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Fluke Biomedical Radiation Management Services Manufacturer Mac Oui Rule 1",
          "ruleId": "flukeBiomedical RadiationManagementServicesManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Flytech-Technology-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Flytech Technology Device Mac Oui Rule 1",
          "ruleId": "Flytech-Technology-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Free BSD User Agent Rule",
          "ruleId": "freeBSDUserAgentRule",
          "operatingSystem": [
            "FreeBSD"
          ],
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Fuji-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Fuji Device Mac Oui Rule 1",
          "ruleId": "Fuji-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Fuji-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Fuji Device Mac Oui Rule 2",
          "ruleId": "Fuji-Device-Rule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "GE-Consumer-Industrial-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "GE Consumer Industrial Device Mac Oui Rule 1",
          "ruleId": "GE-Consumer-Industrial-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "GE-Corp-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "GE Corp Device Mac Oui Rule 1",
          "ruleId": "GE-Corp-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "GE-Digital-Energy-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "GE Digital Energy Device Mac Oui Rule 1",
          "ruleId": "GE-Digital-Energy-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "GE-Energy-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "GE Energy Device Mac Oui Rule 1",
          "ruleId": "GE-Energy-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "GE-Fanuc-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "GE Fanuc Automation Device Mac Oui Rule 1",
          "ruleId": "GE-Fanuc-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "GE-Global-Research-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "GE Global Research Device Mac Oui Rule 1",
          "ruleId": "GE-Global-Research-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "GE-Water-Process-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "GE Water Process Device Mac Oui Rule 1",
          "ruleId": "GE-Water-Process-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "GKB-CCTV-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "GKB CCTV Device Mac Oui Rule 1",
          "ruleId": "GKB-CCTV-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Galil-Motion-Control-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Galil Motion Control Device Mac Oui Rule 1",
          "ruleId": "Galil-Motion-Control-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Gemalto-ePayment-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Gemalto ePayment Device Mac Oui Rule 1",
          "ruleId": "Gemalto-ePayment-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "General-Dynamics-Robotic-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "General Dynamics Robotic Systems Device Mac Oui Rule 1",
          "ruleId": "General-Dynamics-Robotic-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "General-Electric-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "General Electric Device Mac Oui Rule 1",
          "ruleId": "General-Electric-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "General-Electric-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "General Electric Device Mac Oui Rule 2",
          "ruleId": "General-Electric-Device-Rule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Genetec-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Genetec Device Mac Oui Rule 1",
          "ruleId": "Genetec-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Genie-Industries-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Genie Industries Device Mac Oui Rule 1",
          "ruleId": "Genie-Industries-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Genmark-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Genmark Automation Device Mac Oui Rule 1",
          "ruleId": "Genmark-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Gentoo User Agent Rule",
          "ruleId": "gentooUserAgentRule",
          "operatingSystem": [
            "Gentoo"
          ],
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "GeoVision-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "GeoVision Device Mac Oui Rule 1",
          "ruleId": "GeoVision-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "German-Light-Products-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "German Light Products Device Mac Oui Rule 1",
          "ruleId": "German-Light-Products-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Getinge IT Solutions ApS"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Getinge It Solutions Aps Manufacturer Mac Oui Rule 1",
          "ruleId": "getingeItSolutionsApsManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Global-Traffic-Technologies-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Global Traffic Technologies Device Mac Oui Rule 1",
          "ruleId": "Global-Traffic-Technologies-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Golden-River-Traffic-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Golden River Traffic Device Mac Oui Rule 1",
          "ruleId": "Golden-River-Traffic-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Golden River Traffic"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Golden River Traffic Manufacturer Mac Oui Rule 1",
          "ruleId": "goldenRiverTrafficManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Goldline-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Goldline Controls Device Mac Oui Rule 1",
          "ruleId": "Goldline-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "IOT Device"
          ],
          "usedAttributes": [
            "oui",
            "userAgent",
            "dhcpClassIdentifier"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Google-Chromecast",
            "Google-Device"
          ],
          "hardwareManufacturer": [
            "Google, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Google Chromecast Rule 1",
          "ruleId": "GoogleChromecastRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "IOT Device"
          ],
          "usedAttributes": [
            "hardwareModel"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Google Iot Devices Rule",
          "ruleId": "googleIotDevicesRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "mdnsMd",
            "hardwareManufacturer"
          ],
          "conditionGroups": null,
          "hardwareModel": [],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Dynamic",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Google Manufacturer And Mdns Md Rule",
          "ruleId": "googleManufacturerAndMdnsMdRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "hardwareModel"
          ],
          "conditionGroups": null,
          "hardwareModel": [],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Dynamic",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Google Pixel Device Rule 2",
          "ruleId": "googlePixelDeviceRule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Grandeye-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Grandeye Device Mac Oui Rule 1",
          "ruleId": "Grandeye-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Grundfos-Holding-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Grundfos Holding Device Mac Oui Rule 1",
          "ruleId": "Grundfos-Holding-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Gunnebo-Cash-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Gunnebo Cash Automation Device Mac Oui Rule 1",
          "ruleId": "Gunnebo-Cash-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "HESS-Cash-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "HESS Cash Systems Device Mac Oui Rule 1",
          "ruleId": "HESS-Cash-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "HID-Corp-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "HID Corp Device Mac Oui Rule 1",
          "ruleId": "HID-Corp-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "HMS-Industrial-Networks-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "HMS Industrial Networks Device Mac Oui Rule 1",
          "ruleId": "HMS-Industrial-Networks-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "HP-Device"
          ],
          "hardwareManufacturer": [
            "HP Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "HP Device Mac Oui Rule",
          "ruleId": "hpDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui",
            "mdnsTy"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "HP-Device"
          ],
          "hardwareManufacturer": [
            "HP Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "HP Device Mdns Ty Rule",
          "ruleId": "hpDeviceMdnsTyRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "hrDeviceDescr"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "HP-Device"
          ],
          "hardwareManufacturer": [
            "HP Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "HP Device Snmp Rule",
          "ruleId": "hpDeviceSnmpRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "Printer"
          ],
          "usedAttributes": [
            "dhcpClassIdentifier"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "HP-Printer",
            "HP-Device"
          ],
          "hardwareManufacturer": [
            "HP Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "HP Printer DHCP Class Identifier Rule",
          "ruleId": "hpPrinterDhcpClassIdentifier",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dhcpClassIdentifier"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "HP-Device"
          ],
          "hardwareManufacturer": [
            "HP Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "HP Printer Dhcp Class Identifier Rule 1",
          "ruleId": "hpPrinterDhcpClassIdentifierRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dhcpClassIdentifier"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "HP-Device"
          ],
          "hardwareManufacturer": [
            "HP Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "HP Printer Dhcp Class Identifier Rule 2",
          "ruleId": "hpPrinterDhcpClassIdentifierRule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "mdnsTy",
            "hardwareManufacturer"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "HP-Device"
          ],
          "hardwareManufacturer": [
            "HP Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "HP Printer Mdns Ty Rule",
          "ruleId": "hpPrinterMdnsTyRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "hrDeviceDescr"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "HP-Device"
          ],
          "hardwareManufacturer": [
            "HP Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "HP Printer Snmp Rule",
          "ruleId": "hpPrinterSnmpRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "sysDescr"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "HP-Device"
          ],
          "hardwareManufacturer": [
            "HP Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "HP Printer Snmp System Description Rule",
          "ruleId": "hpPrinterSnmpSysDescRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Printer"
          ],
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "HP-Printer",
            "HP-Device"
          ],
          "hardwareManufacturer": [
            "HP Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "HP Printer User Agent Rule",
          "ruleId": "hpPrinterUserAgentRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Haag-Streit"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Haag Streit Manufacturer Mac Oui Rule 1",
          "ruleId": "haagStreitManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Haas-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Haas Automation Device Mac Oui Rule 1",
          "ruleId": "Haas-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Hangzhou-Hikvision-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Hangzhou Hikvision Device Mac Oui Rule 1",
          "ruleId": "Hangzhou-Hikvision-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Hangzhou-Hollysys-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Hangzhou Hollysys Automation Device Mac Oui Rule 1",
          "ruleId": "Hangzhou-Hollysys-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Hanwha-Techwin-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Hanwha Techwin Device Mac Oui Rule 1",
          "ruleId": "Hanwha-Techwin-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Hanwha-Techwin-Security-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Hanwha Techwin Security Device Mac Oui Rule 1",
          "ruleId": "Hanwha-Techwin-Security-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Heart Force Medical"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Heart Force Medical Manufacturer Mac Oui Rule 1",
          "ruleId": "heartForceMedicalManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Hectronic-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Hectronic Device Mac Oui Rule 1",
          "ruleId": "Hectronic-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Heraeus Noblelight"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Heraeus Noblelight Manufacturer Mac Oui Rule 1",
          "ruleId": "heraeusNoblelightManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Highlight Parking"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Highlight Parking Manufacturer Mac Oui Rule 1",
          "ruleId": "highlightParkingManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Highlight-Parking-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Highlight Parking Systems Device Mac Oui Rule 1",
          "ruleId": "Highlight-Parking-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Hills-Sound-Vision-Lighting-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Hills Sound Vision Lighting Device Mac Oui Rule 1",
          "ruleId": "Hills-Sound-Vision-Lighting-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Hirschmann-Austria-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Hirschmann Austria Device Mac Oui Rule 1",
          "ruleId": "Hirschmann-Austria-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Hirschmann-Automation-Control-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Hirschmann Automation Control Device Mac Oui Rule 1",
          "ruleId": "Hirschmann-Automation-Control-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Hirschmann-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Hirschmann Automation Device Mac Oui Rule 1",
          "ruleId": "Hirschmann-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Hirschmann-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Hirschmann Device Mac Oui Rule 1",
          "ruleId": "Hirschmann-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Hirschmann-Multimedia-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Hirschmann Multimedia Device Mac Oui Rule 1",
          "ruleId": "Hirschmann-Multimedia-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Hitachi-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Hitachi Device Mac Oui Rule 1",
          "ruleId": "Hitachi-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Hitachi-High-Tech-Control-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Hitachi High Tech Control Device Mac Oui Rule 1",
          "ruleId": "Hitachi-High-Tech-Control-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Hitachi-Industrial-Equipment-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Hitachi Industrial Equipment Device Mac Oui Rule 1",
          "ruleId": "Hitachi-Industrial-Equipment-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Hitachi-Industry-Control-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Hitachi Industry Control Device Mac Oui Rule 1",
          "ruleId": "Hitachi-Industry-Control-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Hitachi-Johnson-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Hitachi Johnson Controls Device Mac Oui Rule 1",
          "ruleId": "Hitachi-Johnson-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Homag-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Homag Device Mac Oui Rule 1",
          "ruleId": "Homag-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Home-Automation-Europe-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Home Automation Europe Device Mac Oui Rule 1",
          "ruleId": "Home-Automation-Europe-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Home Automation Europe"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Home Automation Europe Manufacturer Mac Oui Rule 1",
          "ruleId": "homeAutomationEuropeManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Home-Control-Singapore-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Home Control Singapore Device Mac Oui Rule 1",
          "ruleId": "Home-Control-Singapore-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Home-Director-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Home Director Device Mac Oui Rule 1",
          "ruleId": "Home-Director-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Home Director"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Home Director Manufacturer Mac Oui Rule 1",
          "ruleId": "homeDirectorManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Home-Network-System-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Home Network System Device Mac Oui Rule 1",
          "ruleId": "Home-Network-System-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "HomeLogic-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "HomeLogic Device Mac Oui Rule 1",
          "ruleId": "HomeLogic-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "HomeScenario-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "HomeScenario Device Mac Oui Rule 1",
          "ruleId": "HomeScenario-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Homenet-Communications-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Homenet Communications Device Mac Oui Rule 1",
          "ruleId": "Homenet-Communications-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Homerider-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Homerider Systems Device Mac Oui Rule 1",
          "ruleId": "Homerider-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "HomeScenario"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Homescenario Manufacturer Mac Oui Rule 1",
          "ruleId": "homescenarioManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Honeywell-ACS-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Honeywell ACS Device Mac Oui Rule 1",
          "ruleId": "Honeywell-ACS-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Honeywell-Analytics-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Honeywell Analytics Device Mac Oui Rule 1",
          "ruleId": "Honeywell-Analytics-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Honeywell-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Honeywell Automation Device Mac Oui Rule 1",
          "ruleId": "Honeywell-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266339
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Honeywell-CMSS-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Honeywell CMSS Device Mac Oui Rule 1",
          "ruleId": "Honeywell-CMSS-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Honeywell-China-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Honeywell China Device Mac Oui Rule 1",
          "ruleId": "Honeywell-China-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Honeywell-Dating-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Honeywell Dating Device Mac Oui Rule 1",
          "ruleId": "Honeywell-Dating-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Honeywell-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Honeywell Device Mac Oui Rule 1",
          "ruleId": "Honeywell-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Honeywell-ECC-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Honeywell ECC Device Mac Oui Rule 1",
          "ruleId": "Honeywell-ECC-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Honeywell-Environmental-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Honeywell Environmental Device Mac Oui Rule 1",
          "ruleId": "Honeywell-Environmental-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Honeywell-Fire-System-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Honeywell Fire System Device Mac Oui Rule 1",
          "ruleId": "Honeywell-Fire-System-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Honeywell-Germany-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Honeywell Germany Device Mac Oui Rule 1",
          "ruleId": "Honeywell-Germany-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Honeywell-International-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Honeywell International Device Mac Oui Rule 1",
          "ruleId": "Honeywell-International-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Honeywell-Korea-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Honeywell Korea Device Mac Oui Rule 1",
          "ruleId": "Honeywell-Korea-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Honeywell-Life-Safety-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Honeywell Life Safety Device Mac Oui Rule 1",
          "ruleId": "Honeywell-Life-Safety-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Honeywell-Safety-Products-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Honeywell Safety Products Device Mac Oui Rule 1",
          "ruleId": "Honeywell-Safety-Products-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Honeywell-Security-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Honeywell Security Device Mac Oui Rule 1",
          "ruleId": "Honeywell-Security-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Honeywell-Video-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Honeywell Video Systems Device Mac Oui Rule 1",
          "ruleId": "Honeywell-Video-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Honeywld-Technology-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Honeywld Technology Device Mac Oui Rule 1",
          "ruleId": "Honeywld-Technology-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "HORIBA MEDICAL"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Horiba Medical Manufacturer Mac Oui Rule 1",
          "ruleId": "horibaMedicalManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Horner-Electric-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Horner Electric Device Mac Oui Rule 1",
          "ruleId": "Horner-Electric-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Hospira-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Hospira Device Mac Oui Rule",
          "ruleId": "hospiraDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "HTC-Device"
          ],
          "hardwareManufacturer": [
            "HTC Corporation"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Htc Device Mac Oui Rule",
          "ruleId": "htcDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Huawei-Device"
          ],
          "hardwareManufacturer": [
            "Huawei Technologies Co., Ltd."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Huawei Device Mac Oui Rule",
          "ruleId": "huaweiDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Huawei-Device"
          ],
          "hardwareManufacturer": [
            "Huawei Technologies Co., Ltd."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Huawei Device Mac Oui Rule 2",
          "ruleId": "huaweiDeviceMacOuiRule 2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Hubbell-Building-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Hubbell Building Automation Device Mac Oui Rule 1",
          "ruleId": "Hubbell-Building-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Hubbell-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Hubbell Device Mac Oui Rule 1",
          "ruleId": "Hubbell-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Hubbell-Power-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Hubbell Power Systems Device Mac Oui Rule 1",
          "ruleId": "Hubbell-Power-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Hurco-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Hurco Automation Device Mac Oui Rule 1",
          "ruleId": "Hurco-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Hypercom-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Hypercom Device Mac Oui Rule 1",
          "ruleId": "Hypercom-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "IBASE-Technology-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "IBASE Technology Device Mac Oui Rule 1",
          "ruleId": "IBASE-Technology-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ICC-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ICC Device Mac Oui Rule 1",
          "ruleId": "ICC-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ICM-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ICM Controls Device Mac Oui Rule 1",
          "ruleId": "ICM-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ICP-ePayment-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ICP ePayment Device Mac Oui Rule 1",
          "ruleId": "ICP-ePayment-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "IDEC-Corp-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "IDEC Corp Device Mac Oui Rule 1",
          "ruleId": "IDEC-Corp-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "IDIS-Co-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "IDIS Co Device Mac Oui Rule 1",
          "ruleId": "IDIS-Co-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "IQinVision-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "IQinVision Device Mac Oui Rule 1",
          "ruleId": "IQinVision-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "ICU Scandinavia"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Icu Scandinavia Manufacturer Mac Oui Rule 1",
          "ruleId": "icuScandinaviaManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "IOT Device"
          ],
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Igor-Device"
          ],
          "hardwareManufacturer": [
            "Igor, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Igor Mac Oui Rule",
          "ruleId": "igorMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Impinj-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Impinj Device Mac Oui Rule 1",
          "ruleId": "Impinj-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Imricor Medical Systems"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Imricor Medical Systems Manufacturer Mac Oui Rule 1",
          "ruleId": "imricorMedicalSystemsManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "In-Home-Displays-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "In Home Displays Device Mac Oui Rule 1",
          "ruleId": "In-Home-Displays-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Industrea-Mining-Technology-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Industrea Mining Technology Device Mac Oui Rule 1",
          "ruleId": "Industrea-Mining-Technology-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Industrea Mining Technology"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Industrea Mining Technology Manufacturer Mac Oui Rule 1",
          "ruleId": "industreaMiningTechnologyManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Industrial Control and Communication"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Industrial Control And Communication Manufacturer Mac Oui Rule 1",
          "ruleId": "industrialControlAndCommunicationManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Industrial-Control-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Industrial Control Device Mac Oui Rule 1",
          "ruleId": "Industrial-Control-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Industrial-Control-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Industrial Control Systems Device Mac Oui Rule 1",
          "ruleId": "Industrial-Control-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Inex-Technologies-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Inex Technologies Device Mac Oui Rule 1",
          "ruleId": "Inex-Technologies-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Infinova-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Infinova Device Mac Oui Rule 1",
          "ruleId": "Infinova-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Ingenico-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Ingenico Device Mac Oui Rule 1",
          "ruleId": "Ingenico-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Ingenico-POS-Payment-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Ingenico POS Payment Device Mac Oui Rule 1",
          "ruleId": "Ingenico-POS-Payment-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Ingenico-Terminals-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Ingenico Terminals Device Mac Oui Rule 1",
          "ruleId": "Ingenico-Terminals-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Ingersoll-Rand-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Ingersoll Rand Device Mac Oui Rule 1",
          "ruleId": "Ingersoll-Rand-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Innomed Medical"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Innomed Medical Manufacturer Mac Oui Rule 1",
          "ruleId": "innomedMedicalManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Intec-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Intec Automation Device Mac Oui Rule 1",
          "ruleId": "Intec-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "INTEGRA Biosciences AG"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Integra Biosciences Ag Manufacturer Mac Oui Rule 1",
          "ruleId": "integraBiosciencesAgManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Integra LifeSciences"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Integra Lifesciences Manufacturer Mac Oui Rule 1",
          "ruleId": "integraLifesciencesManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Integrated-Control-Corp-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Integrated Control Corp Device Mac Oui Rule 1",
          "ruleId": "Integrated-Control-Corp-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Integrated Control Corp."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Integrated Control Corp Manufacturer Mac Oui Rule 1",
          "ruleId": "integratedControlCorp-ManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Integrated-Control-Technology-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Integrated Control Technology Device Mac Oui Rule 1",
          "ruleId": "Integrated-Control-Technology-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Intel-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Intel Device Mac Oui Rule",
          "ruleId": "intelDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Intelligent-Control-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Intelligent Control Systems Device Mac Oui Rule 1",
          "ruleId": "Intelligent-Control-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Intelligent-Distributed-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Intelligent Distributed Controls Device Mac Oui Rule 1",
          "ruleId": "Intelligent-Distributed-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "International Mining"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "International Mining Manufacturer Mac Oui Rule 1",
          "ruleId": "internationalMiningManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "International-Mining-Technologies-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "International Mining Technologies Device Mac Oui Rule 1",
          "ruleId": "International-Mining-Technologies-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Intrepid-Control-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Intrepid Control Systems Device Mac Oui Rule 1",
          "ruleId": "Intrepid-Control-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Intuitive Surgical"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Intuitive Surgical Manufacturer Mac Oui Rule 1",
          "ruleId": "intuitiveSurgicalManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Invensys-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Invensys Controls Device Mac Oui Rule 1",
          "ruleId": "Invensys-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Invisua-Lighting-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Invisua Lighting Device Mac Oui Rule 1",
          "ruleId": "Invisua-Lighting-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Invisua Lighting"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Invisua Lighting Manufacturer Mac Oui Rule 1",
          "ruleId": "invisuaLightingManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Isilon-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Isilon Systems Device Mac Oui Rule",
          "ruleId": "isilonSystemsDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "JK-microsystems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "JK microsystems Device Mac Oui Rule 1",
          "ruleId": "JK-microsystems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Japan-Cash-Machine-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Japan Cash Machine Device Mac Oui Rule 1",
          "ruleId": "Japan-Cash-Machine-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": null,
          "conditionGroups": {
            "conditionGroup": [
              {
                "condition": {
                  "attribute": "callingStationId",
                  "value": "00-50-56-8F-4D-8C",
                  "attributeDictionary": "RADIUS",
                  "operator": "equals"
                },
                "type": "attr"
              }
            ],
            "operator": "and"
          },
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Joff Ind"
          ],
          "ruleType": "Custom",
          "ruleVersion": 1,
          "lastModifiedBy": "admin",
          "ruleName": "Joff",
          "ruleId": "custom-14d55ad2-6700-4ec0-a6ed-1c83c69c6497",
          "operatingSystem": null,
          "lastModifiedOn": 1587466069899
        },
        {
          "deviceType": null,
          "usedAttributes": null,
          "conditionGroups": {
            "conditionGroup": [
              {
                "condition": {
                  "attribute": "assetMacAddress",
                  "value": "00:0C:29:5E:49:32",
                  "attributeDictionary": "IOTAsset",
                  "operator": "equals"
                },
                "type": "attr"
              }
            ],
            "operator": "and"
          },
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Joff Ind"
          ],
          "ruleType": "Custom",
          "ruleVersion": 1,
          "lastModifiedBy": "admin",
          "ruleName": "Joff2",
          "ruleId": "custom-f1bc60cb-0cbb-4aeb-b129-edb66a6aa218",
          "operatingSystem": null,
          "lastModifiedOn": 1587466293799
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Johnson-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Johnson Controls Device Mac Oui Rule 1",
          "ruleId": "Johnson-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Jostra AB"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Jostra Ab Manufacturer Mac Oui Rule 1",
          "ruleId": "jostraAbManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Juniper-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Juniper Device Mac Oui Rule",
          "ruleId": "juniperDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "KMC-CONTROLS-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "KMC CONTROLS Device Mac Oui Rule 1",
          "ruleId": "KMC-CONTROLS-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "KUKA-Welding-Robots-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "KUKA Welding Robots Device Mac Oui Rule 1",
          "ruleId": "KUKA-Welding-Robots-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Kaba-Benzing-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Kaba Benzing Device Mac Oui Rule 1",
          "ruleId": "Kaba-Benzing-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Kinco-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Kinco Automation Device Mac Oui Rule 1",
          "ruleId": "Kinco-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Kingtek-CCTV-Alliance-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Kingtek CCTV Alliance Device Mac Oui Rule 1",
          "ruleId": "Kingtek-CCTV-Alliance-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Knapp-Logistik-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Knapp Logistik Device Mac Oui Rule 1",
          "ruleId": "Knapp-Logistik-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Kone-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Kone Device Mac Oui Rule 1",
          "ruleId": "Kone-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Kongsberg-Norcontrol-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Kongsberg Norcontrol Device Mac Oui Rule 1",
          "ruleId": "Kongsberg-Norcontrol-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Konica-Device"
          ],
          "hardwareManufacturer": [
            "Konica Minolta, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Konica Device Mac Oui Rule",
          "ruleId": "konicaDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "hrDeviceDescr"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Konica-Device"
          ],
          "hardwareManufacturer": [
            "Konica Minolta, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Konica Device Snmp Rule",
          "ruleId": "konicaDeviceSnmpRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "hrDeviceDescr"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Konica-Device"
          ],
          "hardwareManufacturer": [
            "Konica Minolta, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Konica Printer Snmp Rule 1",
          "ruleId": "konicaPrinterSnmpHrDeviceDecrRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "sysDescr"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Konica-Device"
          ],
          "hardwareManufacturer": [
            "Konica Minolta, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Konica Printer Snmp Rule 2",
          "ruleId": "konicaPrinterSnmpSysDecrRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Kontron-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Kontron Device Mac Oui Rule 1",
          "ruleId": "Kontron-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Kontron-Electronik-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Kontron Electronik Device Mac Oui Rule 1",
          "ruleId": "Kontron-Electronik-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Kronos-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Kronos Device Mac Oui Rule 1",
          "ruleId": "Kronos-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Kubuntu User Agent Rule",
          "ruleId": "kubuntuUserAgentRule",
          "operatingSystem": [
            "Kubuntu"
          ],
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Kyocera-Device"
          ],
          "hardwareManufacturer": [
            "Kyocera Corporation"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Kyocera Device Mac Oui Rule",
          "ruleId": "kyoceraDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "LACROIX-Traffic-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "LACROIX Traffic Device Mac Oui Rule 1",
          "ruleId": "LACROIX-Traffic-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "LARsys-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "LARsys Automation Device Mac Oui Rule 1",
          "ruleId": "LARsys-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "LED-Roadway-Lighting-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "LED Roadway Lighting Device Mac Oui Rule 1",
          "ruleId": "LED-Roadway-Lighting-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "LEGRAND-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "LEGRAND Device Mac Oui Rule 1",
          "ruleId": "LEGRAND-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "IOT Device"
          ],
          "usedAttributes": [
            "oui",
            "dhcpParameterRequestList"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "LG Smart TV",
            "LG-Device"
          ],
          "hardwareManufacturer": [
            "LG Corporation"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "LG Smart TV Dhcp Fingerprint Rule",
          "ruleId": "lgSmartTvDhcpFingerprintRule",
          "operatingSystem": [
            "webOS"
          ],
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "IOT Device"
          ],
          "usedAttributes": [
            "oui",
            "dhcpParameterRequestList",
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "LG Smart TV",
            "LG-Device"
          ],
          "hardwareManufacturer": [
            "LG Corporation"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "LG Smart TV User Agent And Dhcp Fingerprint Rule",
          "ruleId": "lgSmartTvUserAgentAndDhcpFingerprintRule",
          "operatingSystem": [
            "webOS"
          ],
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "IOT Device"
          ],
          "usedAttributes": [
            "oui",
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "LG Smart TV",
            "LG-Device"
          ],
          "hardwareManufacturer": [
            "LG Corporation"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "LG Smart TV User Agent Rule",
          "ruleId": "lgSmartTvUserAgentRule",
          "operatingSystem": [
            "webOS"
          ],
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "LNT-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "LNT Automation Device Mac Oui Rule 1",
          "ruleId": "LNT-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "LOYTEC-electronics-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "LOYTEC electronics Device Mac Oui Rule 1",
          "ruleId": "LOYTEC-electronics-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Lagotek-Corp-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Lagotek Corp Device Mac Oui Rule 1",
          "ruleId": "Lagotek-Corp-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Laser-Light-Engines-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Laser Light Engines Device Mac Oui Rule 1",
          "ruleId": "Laser-Light-Engines-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Leadtek-Device"
          ],
          "hardwareManufacturer": [
            "Leadtek Research, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Leadtek Device Mac Oui Rule",
          "ruleId": "leadtekDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "Workstation"
          ],
          "usedAttributes": [
            "oui",
            "dhcpClassIdentifier"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Leadtek Thin Client",
            "Leadtek-Device"
          ],
          "hardwareManufacturer": [
            "Leadtek Research, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Leadtek Thin Client Dhcp Class Identifier Rule",
          "ruleId": "leadtekThinClientDhcpClassIdentifierRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Leedarson-Lighting-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Leedarson Lighting Device Mac Oui Rule 1",
          "ruleId": "Leedarson-Lighting-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Legrand-Home-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Legrand Home Systems Device Mac Oui Rule 1",
          "ruleId": "Legrand-Home-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Leica Biosystems"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Leica Biosystems Manufacturer Mac Oui Rule 1",
          "ruleId": "leicaBiosystemsManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "mdnsHw"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Lenovo-Device"
          ],
          "hardwareManufacturer": [
            "Lenovo Group Limited"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Lenovo Mdns Hw Rule",
          "ruleId": "lenovoMdnsHwRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Leroy-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Leroy Automation Device Mac Oui Rule 1",
          "ruleId": "Leroy-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Leviton-Manufacturing-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Leviton Manufacturing Device Mac Oui Rule 1",
          "ruleId": "Leviton-Manufacturing-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266339
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dhcpClassIdentifier"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Lexmark-Device"
          ],
          "hardwareManufacturer": [
            "Lexmark International, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Lexmark Device Dhcp Class Identifier Rule 1",
          "ruleId": "lexmarkDeviceDhcpClassIdentifierRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dhcpClassIdentifier"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Lexmark-Device"
          ],
          "hardwareManufacturer": [
            "Lexmark International, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Lexmark Device Dhcp Class Identifier Rule 2",
          "ruleId": "lexmarkDeviceDhcpClassIdentifierRule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Lexmark-Device"
          ],
          "hardwareManufacturer": [
            "Lexmark International, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Lexmark Device Mac Oui Rule",
          "ruleId": "lexmarkDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Lexmark-Device"
          ],
          "hardwareManufacturer": [
            "Lexmark International, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Lexmark Device Mac Oui Rule 2",
          "ruleId": "lexmarkDeviceMacOuiRule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "hrDeviceDescr"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Lexmark-Device"
          ],
          "hardwareManufacturer": [
            "Lexmark International, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Lexmark Device Snmp Rule 1",
          "ruleId": "lexmarkDeviceSnmpRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "hrDeviceDescr"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Lexmark-Device"
          ],
          "hardwareManufacturer": [
            "Lexmark International, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Lexmark Device Snmp Rule 2",
          "ruleId": "lexmarkDeviceSnmpRule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dhcpClassIdentifier"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Lexmark-Device"
          ],
          "hardwareManufacturer": [
            "Lexmark International, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Lexmark Printer Dhcp Class Identifier Rule",
          "ruleId": "lexmarkPrinterDhcpClassIdentifierRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "hrDeviceDescr"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Lexmark-Device"
          ],
          "hardwareManufacturer": [
            "Lexmark International, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Lexmark Printer Snmp Rule",
          "ruleId": "lexmarkPrinterSnmpRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "sysDescr"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Lexmark-Device"
          ],
          "hardwareManufacturer": [
            "Lexmark International, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Lexmark Printer Snmp System Description Rule",
          "ruleId": "lexmarkPrinterSnmpSysDescRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "LG-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Lg Device Mac Oui Rule",
          "ruleId": "lgDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "mdnsModelName",
            "deviceType"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "LG-Device"
          ],
          "hardwareManufacturer": [
            "LG Corporation"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Lg Smart Tv Manufacturer And Model Name Rule",
          "ruleId": "lgSmartTvManufacturerAndModelNameRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "IOT Device"
          ],
          "usedAttributes": [
            "sysDescr"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Liebert-Device"
          ],
          "hardwareManufacturer": [
            "Liebert Corporation",
            "Vertiv"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Liebert Snmp System Description Rule 1",
          "ruleId": "liebertSnmpSystemDescRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "sysDescr",
            "hardwareModel"
          ],
          "conditionGroups": null,
          "hardwareModel": [],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Dynamic",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Liebert Snmp System Description Rule 2",
          "ruleId": "liebertSnmpSystemDescRule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Lifesize-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Lifesize Device Mac Oui Rule",
          "ruleId": "lifesizeDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "LightHaus-Logic-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "LightHaus Logic Device Mac Oui Rule 1",
          "ruleId": "LightHaus-Logic-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "LightStar-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "LightStar Device Mac Oui Rule 1",
          "ruleId": "LightStar-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Lighting-Science-Group-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Lighting Science Group Device Mac Oui Rule 1",
          "ruleId": "Lighting-Science-Group-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Lightstar"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Lightstar Manufacturer Mac Oui Rule 1",
          "ruleId": "lightstarManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Linksys-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Linksys Device Mac Oui Rule",
          "ruleId": "linksysDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "devicePlatform"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Linux ACIDEX Rule",
          "ruleId": "linuxAcidexRule",
          "operatingSystem": [
            "Linux"
          ],
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dhcpClassIdentifier"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Linux Dhcp Class Identifier Rule 1",
          "ruleId": "linuxDhcpClassIdentifierRule1",
          "operatingSystem": [
            "Linux"
          ],
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dhcpClassIdentifier"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Linux Dhcp Class Identifier Rule 2",
          "ruleId": "linuxDhcpClassIdentifierRule2",
          "operatingSystem": [
            "SunOs"
          ],
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Linux User Agent Rule",
          "ruleId": "linuxUserAgentRule",
          "operatingSystem": [
            "Linux"
          ],
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "Workstation"
          ],
          "usedAttributes": [
            "operatingSystem"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Linux-Workstation"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Linux Workstation Os Rule",
          "ruleId": "linuxWorkstationOsRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Lipman-TransAction-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Lipman TransAction Device Mac Oui Rule 1",
          "ruleId": "Lipman-TransAction-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Lippert-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Lippert Automation Device Mac Oui Rule 1",
          "ruleId": "Lippert-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Liquid-Robotics-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Liquid Robotics Device Mac Oui Rule 1",
          "ruleId": "Liquid-Robotics-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Liquid Robotics, Inc"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Liquid Robotics Manufacturer Mac Oui Rule 1",
          "ruleId": "liquidRoboticsManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Listman-Home-Technologies-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Listman Home Technologies Device Mac Oui Rule 1",
          "ruleId": "Listman-Home-Technologies-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "LiteTouch-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "LiteTouch Device Mac Oui Rule 1",
          "ruleId": "LiteTouch-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Logic-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Logic Controls Device Mac Oui Rule 1",
          "ruleId": "Logic-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Logic-Product-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Logic Product Device Mac Oui Rule 1",
          "ruleId": "Logic-Product-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Longdhua-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Longdhua Controls Device Mac Oui Rule 1",
          "ruleId": "Longdhua-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Lorex-Technology-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Lorex Technology Device Mac Oui Rule 1",
          "ruleId": "Lorex-Technology-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "LUMINEX Lighting Control Equipment"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Luminex Lighting Control Equipment Manufacturer Mac Oui Rule 1",
          "ruleId": "luminexLightingControlEquipmentManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Luninex-Lighting-Control-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Luninex Lighting Control Device Mac Oui Rule 1",
          "ruleId": "Luninex-Lighting-Control-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Lupine-Lighting-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Lupine Lighting Systems Device Mac Oui Rule 1",
          "ruleId": "Lupine-Lighting-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Lutron-Electronics-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Lutron Electronics Device Mac Oui Rule 1",
          "ruleId": "Lutron-Electronics-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "MA-Lighting-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "MA Lighting Device Mac Oui Rule 1",
          "ruleId": "MA-Lighting-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "MCS-Logic-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "MCS Logic Device Mac Oui Rule 1",
          "ruleId": "MCS-Logic-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Maas-International-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Maas International Device Mac Oui Rule 1",
          "ruleId": "Maas-International-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Workstation"
          ],
          "usedAttributes": [
            "operatingSystem"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Macintosh-Workstation"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Mac Workstation Os Rule",
          "ruleId": "macWorkstationOsRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "mdnsModelName",
            "deviceType"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Mac iOS Model Name Rule",
          "ruleId": "macIOsModelNameRule",
          "operatingSystem": [
            "iOS"
          ],
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Magal-Senstar-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Magal Senstar Device Mac Oui Rule 1",
          "ruleId": "Magal-Senstar-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Magal Senstar"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Magal Senstar Manufacturer Mac Oui Rule 1",
          "ruleId": "magalSenstarManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Magnetic-Autocontrol-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Magnetic Autocontrol Device Mac Oui Rule 1",
          "ruleId": "Magnetic-Autocontrol-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "March-Networks-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "March Networks Device Mac Oui Rule 1",
          "ruleId": "March-Networks-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Masimo-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Masimo Device Mac Oui Rule",
          "ruleId": "masimoDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Masterclock-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Masterclock Device Mac Oui Rule 1",
          "ruleId": "Masterclock-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "McCain-Traffic-Supply-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "McCain Traffic Supply Device Mac Oui Rule 1",
          "ruleId": "McCain-Traffic-Supply-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "McCain Traffic"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Mccain Traffic Manufacturer Mac Oui Rule 1",
          "ruleId": "mccainTrafficManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "mdnsOs"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Dynamic",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Mdns Os Rule",
          "ruleId": "mdnsOsRule",
          "operatingSystem": [],
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "MDS SCIEX"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Mds Sciex Manufacturer Mac Oui Rule 1",
          "ruleId": "mdsSciexManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "MEDAV GmbH"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Medav Gmbh Manufacturer Mac Oui Rule 1",
          "ruleId": "medavGmbhManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "MedAvant Healthcare"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Medavant Healthcare Manufacturer Mac Oui Rule 1",
          "ruleId": "medavantHealthcareManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Mediana"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Mediana Manufacturer Mac Oui Rule 1",
          "ruleId": "medianaManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dicomManufacturerModelName",
            "dicomProductName",
            "dicomDeviceId",
            "hl7udi",
            "hl7di",
            "hl7oid",
            "hl7SymbologyIdentifier",
            "dicomManufacturer",
            "dicomProductType"
          ],
          "conditionGroups": null,
          "hardwareModel": [],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Dynamic",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Medical Brand And Model Rule",
          "ruleId": "MedicalBrandAndModelRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dicomManufacturerModelName",
            "dicomProductName",
            "dicomDeviceId",
            "hl7udi",
            "hl7di",
            "hl7oid",
            "hl7SymbologyIdentifier",
            "dicomManufacturer",
            "dicomProductType"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [],
          "ruleType": "Cisco Default - Dynamic",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Medical Company Name Rule",
          "ruleId": "MedicalCompanyNameRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [],
          "usedAttributes": [
            "dicomManufacturerModelName",
            "dicomProductName",
            "dicomDeviceId",
            "hl7udi",
            "hl7di",
            "hl7oid",
            "hl7SymbologyIdentifier",
            "dicomManufacturer",
            "dicomProductType"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Dynamic",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Medical GMDN Rule",
          "ruleId": "MedicalGMDNRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Medical Device"
          ],
          "usedAttributes": [
            "dicomManufacturerModelName",
            "dicomProductName",
            "dicomDeviceId",
            "hl7udi",
            "hl7di",
            "hl7oid",
            "hl7SymbologyIdentifier",
            "dicomManufacturer",
            "dicomProductType"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Medical Protocol Rule",
          "ruleId": "MedicalProtocolRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Mercury-Security-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Mercury Security Device Mac Oui Rule 1",
          "ruleId": "Mercury-Security-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Merten-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Merten Device Mac Oui Rule 1",
          "ruleId": "Merten-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Meteocontrol-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Meteocontrol Device Mac Oui Rule 1",
          "ruleId": "Meteocontrol-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Metso-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Metso Automation Device Mac Oui Rule 1",
          "ruleId": "Metso-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Micro-Control-Designs-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Micro Control Designs Device Mac Oui Rule 1",
          "ruleId": "Micro-Control-Designs-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Micro-Control-Systems-Inc-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Micro Control Systems Inc Device Mac Oui Rule 1",
          "ruleId": "Micro-Control-Systems-Inc-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Micro-Control-Systems-Ltd-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Micro Control Systems Ltd Device Mac Oui Rule 1",
          "ruleId": "Micro-Control-Systems-Ltd-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Micro-Robotics-Limited-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Micro Robotics Limited Device Mac Oui Rule 1",
          "ruleId": "Micro-Robotics-Limited-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Microrobot-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Microrobot Device Mac Oui Rule 1",
          "ruleId": "Microrobot-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Microsoft-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Microsoft Device Mac Oui Rule",
          "ruleId": "microsoftDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Workstation"
          ],
          "usedAttributes": [
            "operatingSystem"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Microsoft-Workstation"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Microsoft Workstation Os Rule",
          "ruleId": "microsoftWorkstationOsRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Workstation"
          ],
          "usedAttributes": [
            "oui",
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Windows-Workstation"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Microsoft Workstation Rule",
          "ruleId": "microsoftWorkStationRule",
          "operatingSystem": [
            "Windows"
          ],
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Mine-Site-Technologies-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Mine Site Technologies Device Mac Oui Rule 1",
          "ruleId": "Mine-Site-Technologies-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Mint User Agent Rule",
          "ruleId": "mintUserAgentRule",
          "operatingSystem": [
            "Mint"
          ],
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "MitelCorp-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Mitelcorp Device Mac Oui Rule",
          "ruleId": "mitelcorpDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Mitsubishi-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Mitsubishi Device Mac Oui Rule 1",
          "ruleId": "Mitsubishi-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Mitsubishi-Electric-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Mitsubishi Electric Device Mac Oui Rule 1",
          "ruleId": "Mitsubishi-Electric-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Mitsubishi-Electric-Service-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Mitsubishi Electric Service Device Mac Oui Rule 1",
          "ruleId": "Mitsubishi-Electric-Service-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Mitsubishi-Electronics-Logistic-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Mitsubishi Electronics Logistic Device Mac Oui Rule 1",
          "ruleId": "Mitsubishi-Electronics-Logistic-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Mitsubishi-Heavy-Industries-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Mitsubishi Heavy Industries Device Mac Oui Rule 1",
          "ruleId": "Mitsubishi-Heavy-Industries-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Mitsubishi-Precision-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Mitsubishi Precision Device Mac Oui Rule 1",
          "ruleId": "Mitsubishi-Precision-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Mobotix-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Mobotix Device Mac Oui Rule 1",
          "ruleId": "Mobotix-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Mode-Lighting-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Mode Lighting Device Mac Oui Rule 1",
          "ruleId": "Mode-Lighting-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "mdnsModelName",
            "deviceType",
            "hardwareModel"
          ],
          "conditionGroups": null,
          "hardwareModel": [],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Dynamic",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Model Name Rule 1",
          "ruleId": "modelNameRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "mdnsModelName",
            "deviceType"
          ],
          "conditionGroups": null,
          "hardwareModel": [],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Dynamic",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Model Name Rule 2",
          "ruleId": "modelNameRule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Modular-Mining-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Modular Mining Systems Device Mac Oui Rule 1",
          "ruleId": "Modular-Mining-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Molex-Canada-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Molex Canada Device Mac Oui Rule 1",
          "ruleId": "Molex-Canada-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Molex-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Molex Device Mac Oui Rule 1",
          "ruleId": "Molex-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Molex-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Molex Device Mac Oui Rule 2",
          "ruleId": "Molex-Device-Rule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Moog-Broad-Reach-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Moog Broad Reach Device Mac Oui Rule 1",
          "ruleId": "Moog-Broad-Reach-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Moog Broad Reach"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Moog Broad Reach Manufacturer Mac Oui Rule 1",
          "ruleId": "moogBroadReachManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Moog-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Moog Device Mac Oui Rule 1",
          "ruleId": "Moog-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Moog-Germany-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Moog Germany Device Mac Oui Rule 1",
          "ruleId": "Moog-Germany-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Moog-USA-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Moog USA Device Mac Oui Rule 1",
          "ruleId": "Moog-USA-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Motion-Control-Engineering-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Motion Control Engineering Device Mac Oui Rule 1",
          "ruleId": "Motion-Control-Engineering-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Motion-Control-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Motion Control Systems Device Mac Oui Rule 1",
          "ruleId": "Motion-Control-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "MotorolaMobile-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Motorolamobile Device Mac Oui Rule",
          "ruleId": "motorolamobileDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Multivision-Intelligent-Surveillance-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Multivision Intelligent Surveillance Device Mac Oui Rule 1",
          "ruleId": "Multivision-Intelligent-Surveillance-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "NBS-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "NBS Device Mac Oui Rule 1",
          "ruleId": "NBS-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "NBS-Payment-Solutions-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "NBS Payment Solutions Device Mac Oui Rule 1",
          "ruleId": "NBS-Payment-Solutions-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "NCR-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "NCR Device Mac Oui Rule 1",
          "ruleId": "NCR-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "NCTech-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "NCTech Device Mac Oui Rule 1",
          "ruleId": "NCTech-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "NEWPOS-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "NEWPOS Device Mac Oui Rule 1",
          "ruleId": "NEWPOS-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Nanjing-SAC-Power-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Nanjing SAC Power Device Mac Oui Rule 1",
          "ruleId": "Nanjing-SAC-Power-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Nanjing-Shining-Electric-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Nanjing Shining Electric Device Mac Oui Rule 1",
          "ruleId": "Nanjing-Shining-Electric-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Navionics-Research-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Navionics Research Device Mac Oui Rule 1",
          "ruleId": "Navionics-Research-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Nedap-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Nedap Device Mac Oui Rule 1",
          "ruleId": "Nedap-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Nest-Labs-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Nest Labs Device Mac Oui Rule 1",
          "ruleId": "Nest-Labs-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Netatmo-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Netatmo Device Mac Oui Rule 1",
          "ruleId": "Netatmo-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Netcontrol-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Netcontrol Device Mac Oui Rule 1",
          "ruleId": "Netcontrol-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Netgear-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Netgear Device Mac Oui Rule",
          "ruleId": "netgearDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "NetSys-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Netsys Device Mac Oui Rule",
          "ruleId": "netsysDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Network-Controls-International-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Network Controls International Device Mac Oui Rule 1",
          "ruleId": "Network-Controls-International-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Networked Robotics"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Networked Robotics Manufacturer Mac Oui Rule 1",
          "ruleId": "networkedRoboticsManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Neuberger-Building-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Neuberger Building Automation Device Mac Oui Rule 1",
          "ruleId": "Neuberger-Building-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Neuron-Robotics-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Neuron Robotics Device Mac Oui Rule 1",
          "ruleId": "Neuron-Robotics-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "New-Light-LED-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "New Light LED Device Mac Oui Rule 1",
          "ruleId": "New-Light-LED-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Nexans-Germany-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Nexans Germany Device Mac Oui Rule 1",
          "ruleId": "Nexans-Germany-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Nidec-Copal-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Nidec Copal Device Mac Oui Rule 1",
          "ruleId": "Nidec-Copal-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Nidec-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Nidec Device Mac Oui Rule 1",
          "ruleId": "Nidec-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Nidec-Sanyo-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Nidec Sanyo Device Mac Oui Rule 1",
          "ruleId": "Nidec-Sanyo-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Gaming Device"
          ],
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Nintendo-Device"
          ],
          "hardwareManufacturer": [
            "Nintendo Co., Ltd."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Nintendowii Mac Oui Rule",
          "ruleId": "nintendoMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Nixdorf-Computer-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Nixdorf Computer Device Mac Oui Rule 1",
          "ruleId": "Nixdorf-Computer-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "Mobile Device"
          ],
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Nmap Open Ports Apple Iphone Sync Rule",
          "ruleId": "nmapOpenPortsAppleIphoneSyncRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Nokia-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Nokia Device Mac Oui Rule",
          "ruleId": "nokiaDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Nortel-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Nortel Device Mac Oui Rule",
          "ruleId": "nortelDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Novus-Security-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Novus Security Device Mac Oui Rule 1",
          "ruleId": "Novus-Security-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "NuLEDs-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "NuLEDs Device Mac Oui Rule 1",
          "ruleId": "NuLEDs-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Nuki-Home-Solutions-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Nuki Home Solutions Device Mac Oui Rule 1",
          "ruleId": "Nuki-Home-Solutions-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "SuperSonic Imagine"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "OID 1-2-250-1-204 Manufacturer SuperSonic Imagine Rule",
          "ruleId": "OID1-2-250-1-204ManufacturerSuperSonicImagineRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "MeVis Medical Solutions AG"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "OID 1-2-276-0-28 Manufacturer MeVis Medical Solutions AG Rule",
          "ruleId": "OID1-2-276-0-28ManufacturerMeVisMedicalSolutionsAGRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Dornier MedTech Systems GmbH"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "OID 1-2-276-0-52 Manufacturer Dornier MedTech Systems GmbH Rule",
          "ruleId": "OID1-2-276-0-52ManufacturerDornierMedTechSystemsGmbHRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "GE Healthcare GmbH"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "OID 1-2-276-0-69 Manufacturer GE Healthcare GmbH Rule",
          "ruleId": "OID1-2-276-0-69ManufacturerGEHealthcareGmbHRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Carl Zeiss Meditec AG"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "OID 1-2-276-0-75 Manufacturer Carl Zeiss Meditec AG Rule",
          "ruleId": "OID1-2-276-0-75ManufacturerCarlZeissMeditecAGRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Konica Minolta Medical & Graphic, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "OID 1-2-392-200036-9107 Manufacturer Konica Minolta Medical - Graphic- Inc- Rule",
          "ruleId": "OID1-2-392-200036-9107ManufacturerKonicaMinoltaMedical-Graphic-Inc-Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Toshiba Medical Systems Co., Ltd."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "OID 1-2-392-200036-9116 Manufacturer Toshiba Medical Systems Co-- Ltd- Rule",
          "ruleId": "OID1-2-392-200036-9116ManufacturerToshibaMedicalSystemsCo--Ltd-Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Hitachi Medical Corporation"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "OID 1-2-392-200036-9123 Manufacturer Hitachi Medical Corporation Rule",
          "ruleId": "OID1-2-392-200036-9123ManufacturerHitachiMedicalCorporationRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Fuji Photo Film Co., Ltd."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "OID 1-2-392-200036-9125 Manufacturer Fuji Photo Film Co-- Ltd- Rule",
          "ruleId": "OID1-2-392-200036-9125ManufacturerFujiPhotoFilmCo--Ltd-Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "General Electric"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "OID 1-2-840-113619 Manufacturer General Electric Rule",
          "ruleId": "OID1-2-840-113619ManufacturerGeneralElectricRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Philips"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "OID 1-2-840-113704 Manufacturer Philips Rule",
          "ruleId": "OID1-2-840-113704ManufacturerPhilipsRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Siemens Healthcare"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "OID 1-3-12-2-1107 Manufacturer Siemens Healthcare Rule",
          "ruleId": "OID1-3-12-2-1107ManufacturerSiemensHealthcareRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Philips Medical Systems"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "OID 1-3-46-670589 Manufacturer Philips Medical Systems Rule",
          "ruleId": "OID1-3-46-670589ManufacturerPhilipsMedicalSystemsRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "MultiTech Solutions Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "OID 1-3-6-1-4-1-11157 Manufacturer MultiTech Solutions Inc- Rule",
          "ruleId": "OID1-3-6-1-4-1-11157ManufacturerMultiTechSolutionsInc-Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "PACSGEAR, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "OID 1-3-6-1-4-1-23849 Manufacturer PACSGEAR- Inc- Rule",
          "ruleId": "OID1-3-6-1-4-1-23849ManufacturerPACSGEAR-Inc-Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "ClearCanvas Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "OID 1-3-6-1-4-1-25403 Manufacturer ClearCanvas Inc- Rule",
          "ruleId": "OID1-3-6-1-4-1-25403ManufacturerClearCanvasInc-Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Colby Dillion"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "OID 1-3-6-1-4-1-30071 Manufacturer Colby Dillion Rule",
          "ruleId": "OID1-3-6-1-4-1-30071ManufacturerColbyDillionRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266339
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Novasonics"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "OID 1-3-6-1-4-1-6052 Manufacturer Novasonics Rule",
          "ruleId": "OID1-3-6-1-4-1-6052ManufacturerNovasonicsRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "BioFocus Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "OID 2-16-124-113543 Manufacturer BioFocus Inc- Rule",
          "ruleId": "OID2-16-124-113543ManufacturerBioFocusInc-Rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dicomImplementationUid"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Merge Technologies"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "OID 2-16-840-1-113669 Manufacturer Merge Technologies Rule",
          "ruleId": "OID2-16-840-1-113669ManufacturerMergeTechnologiesRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Omnex-Control-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Omnex Control Systems Device Mac Oui Rule 1",
          "ruleId": "Omnex-Control-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "OnePlus-Device"
          ],
          "hardwareManufacturer": [
            "One Plus Technology (Shenzhen) Co., Ltd."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "One Plus Device Mac Oui Rule",
          "ruleId": "onePlusDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Open BSD User Agent Rule",
          "ruleId": "openBSDUserAgentRule",
          "operatingSystem": [
            "OpenBSD"
          ],
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Open-Source-Robotics-Foundation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Open Source Robotics Foundation Device Mac Oui Rule 1",
          "ruleId": "Open-Source-Robotics-Foundation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Orient-Power-Home-Network-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Orient Power Home Network Device Mac Oui Rule 1",
          "ruleId": "Orient-Power-Home-Network-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Otis-Elevator-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Otis Elevator Device Mac Oui Rule 1",
          "ruleId": "Otis-Elevator-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Ouya-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Ouya Device Mac Oui Rule",
          "ruleId": "ouyaDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "PChome-Online-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "PChome Online Device Mac Oui Rule 1",
          "ruleId": "PChome-Online-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "POSBANK-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "POSBANK Device Mac Oui Rule 1",
          "ruleId": "POSBANK-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "POSDATA-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "POSDATA Device Mac Oui Rule 1",
          "ruleId": "POSDATA-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "POSDATA-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "POSDATA Device Mac Oui Rule 2",
          "ruleId": "POSDATA-Device-Rule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "PROBA-Building-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "PROBA Building Automation Device Mac Oui Rule 1",
          "ruleId": "PROBA-Building-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Panasonic-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Panasonic Device Mac Oui Rule 1",
          "ruleId": "Panasonic-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Panasonic-Electric-Works-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Panasonic Electric Works Automation Device Mac Oui Rule 1",
          "ruleId": "Panasonic-Electric-Works-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Panasonic-Electric-Works-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Panasonic Electric Works Device Mac Oui Rule 1",
          "ruleId": "Panasonic-Electric-Works-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Panasonic-Industrial-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Panasonic Industrial Device Mac Oui Rule 1",
          "ruleId": "Panasonic-Industrial-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Park-Assist-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Park Assist Device Mac Oui Rule 1",
          "ruleId": "Park-Assist-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Parker-Hannifin-Compumotor-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Parker Hannifin Compumotor Device Mac Oui Rule 1",
          "ruleId": "Parker-Hannifin-Compumotor-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Parker-Hannifin-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Parker Hannifin Device Mac Oui Rule 1",
          "ruleId": "Parker-Hannifin-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Parker-Hannifin-Electromechanical-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Parker Hannifin Electromechanical Device Mac Oui Rule 1",
          "ruleId": "Parker-Hannifin-Electromechanical-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Parker-Hannifin-Manufacturing-Germany-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Parker Hannifin Manufacturing Germany Device Mac Oui Rule 1",
          "ruleId": "Parker-Hannifin-Manufacturing-Germany-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Parker-Hannifin-Manufacturing-UK-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Parker Hannifin Manufacturing UK Device Mac Oui Rule 1",
          "ruleId": "Parker-Hannifin-Manufacturing-UK-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Parking-BOXX-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Parking BOXX Device Mac Oui Rule 1",
          "ruleId": "Parking-BOXX-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Peek-Traffic-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Peek Traffic Device Mac Oui Rule 1",
          "ruleId": "Peek-Traffic-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Peek-Traffic-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Peek Traffic Device Mac Oui Rule 2",
          "ruleId": "Peek-Traffic-Device-Rule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Pelco-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Pelco Device Mac Oui Rule 1",
          "ruleId": "Pelco-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Peperoni-Light-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Peperoni Light Device Mac Oui Rule 1",
          "ruleId": "Peperoni-Light-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266339
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Peperoni-Light"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Peperoni Light Manufacturer Mac Oui Rule 1",
          "ruleId": "peperoniLightManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Phase-Motion-Control-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Phase Motion Control Device Mac Oui Rule 1",
          "ruleId": "Phase-Motion-Control-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Philips-CFT-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Philips CFT Device Mac Oui Rule 1",
          "ruleId": "Philips-CFT-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Philips-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Philips Device Mac Oui Rule 1",
          "ruleId": "Philips-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Philips-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Philips Device Mac Oui Rule 2",
          "ruleId": "Philips-Device-Rule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Philips-Lighting-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Philips Lighting Device Mac Oui Rule 1",
          "ruleId": "Philips-Lighting-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Philips Personal Health Solutions"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Philips Personal Health Solutions Manufacturer Mac Oui Rule 1",
          "ruleId": "philipsPersonalHealthSolutionsManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "IOT Device"
          ],
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Phorus-Device"
          ],
          "hardwareManufacturer": [
            "Phorus, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Phorus Device Mac Oui Rule",
          "ruleId": "phorusDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Plast-Control-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Plast Control Device Mac Oui Rule 1",
          "ruleId": "Plast-Control-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "hardwareModel"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "PlayStation 3 OS Rule",
          "ruleId": "PS3OS",
          "operatingSystem": [
            "CellOS"
          ],
          "lastModifiedOn": 1589339266339
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "hardwareModel"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "PlayStation 4 OS Rule",
          "ruleId": "PS4OS",
          "operatingSystem": [
            "Orbis OS"
          ],
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Plugin-Validity-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "PluginValidityRulesGroup-rule",
          "ruleId": "PluginValidityRulesGroup-rule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Pneu-Logic-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Pneu Logic Device Mac Oui Rule 1",
          "ruleId": "Pneu-Logic-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Pneu-Logic"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Pneu Logic Manufacturer Mac Oui Rule 1",
          "ruleId": "pneuLogicManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Pointe Conception Medical"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Pointe Conception Medical Manufacturer Mac Oui Rule 1",
          "ruleId": "pointeConceptionMedicalManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Polycom-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Polycom Device Mac Oui Rule",
          "ruleId": "polycomDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Portwell-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Portwell Device Mac Oui Rule 1",
          "ruleId": "Portwell-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Posiflex-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Posiflex Device Mac Oui Rule 1",
          "ruleId": "Posiflex-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Power-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Power Automation Device Mac Oui Rule 1",
          "ruleId": "Power-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Power Medical Interventions"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Power Medical Interventions Manufacturer Mac Oui Rule 1",
          "ruleId": "powerMedicalInterventionsManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Precise-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Precise Automation Device Mac Oui Rule 1",
          "ruleId": "Precise-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Process-Control-Corp-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Process Control Corp Device Mac Oui Rule 1",
          "ruleId": "Process-Control-Corp-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Prolon-Control-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Prolon Control Systems Device Mac Oui Rule 1",
          "ruleId": "Prolon-Control-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Q-Light-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Q Light Device Mac Oui Rule 1",
          "ruleId": "Q-Light-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "QSR-Automations-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "QSR Automations Device Mac Oui Rule 1",
          "ruleId": "QSR-Automations-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Quantum Medical Imaging"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Quantum Medical Imaging Manufacturer Mac Oui Rule 1",
          "ruleId": "quantumMedicalImagingManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Quatech-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Quatech Device Mac Oui Rule 1",
          "ruleId": "Quatech-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Quatech, LLC"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Quatech Manufacturer Mac Oui Rule 1",
          "ruleId": "QuatechManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Quest-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Quest Controls Device Mac Oui Rule 1",
          "ruleId": "Quest-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "RBZ-Embedded-Logics-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "RBZ Embedded Logics Device Mac Oui Rule 1",
          "ruleId": "RBZ-Embedded-Logics-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ROBE-Show-Lighting-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ROBE Show Lighting Device Mac Oui Rule 1",
          "ruleId": "ROBE-Show-Lighting-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ROBOTIS-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ROBOTIS Device Mac Oui Rule 1",
          "ruleId": "ROBOTIS-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ROTEC-Industry-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ROTEC Industry Automation Device Mac Oui Rule 1",
          "ruleId": "ROTEC-Industry-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "RS-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "RS Automation Device Mac Oui Rule 1",
          "ruleId": "RS-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Radiation-Safety-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Radiation Safety Device Mac Oui Rule 1",
          "ruleId": "Radiation-Safety-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Radiometer Medical"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Radiometer Medical Manufacturer Mac Oui Rule 1",
          "ruleId": "radiometerMedicalManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Radiometer Medical"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Radiometer Medical Manufacturer Mac Oui Rule 2",
          "ruleId": "radiometerMedicalManufacturerMacOuiRule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Radisys-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Radisys Device Mac Oui Rule",
          "ruleId": "radisysDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Rainforest-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Rainforest Automation Device Mac Oui Rule 1",
          "ruleId": "Rainforest-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "RaspberryPi-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Raspberrypi Device Mac Oui Rule",
          "ruleId": "raspberrypiDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "rbz robot design s.l."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Rbz Robot Design S-L Manufacturer Mac Oui Rule 1",
          "ruleId": "rbzRobotDesignS-L ManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Real-Time-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Real Time Automation Device Mac Oui Rule 1",
          "ruleId": "Real-Time-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Realand-Bio-Co-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Realand Bio Co Device Mac Oui Rule 1",
          "ruleId": "Realand-Bio-Co-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Recardo-Test-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Recardo Test Automation Device Mac Oui Rule 1",
          "ruleId": "Recardo-Test-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Red Hat User Agent Rule",
          "ruleId": "redHatUserAgentRule",
          "operatingSystem": [
            "Red Hat"
          ],
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Red-Lion-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Red Lion Controls Device Mac Oui Rule 1",
          "ruleId": "Red-Lion-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Reliable-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Reliable Controls Device Mac Oui Rule 1",
          "ruleId": "Reliable-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Remote-Technologies-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Remote Technologies Device Mac Oui Rule 1",
          "ruleId": "Remote-Technologies-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Residential-Control-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Residential Control Systems Device Mac Oui Rule 1",
          "ruleId": "Residential-Control-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Residential Control Systems"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Residential Control Systems Manufacturer Mac Oui Rule 1",
          "ruleId": "residentialControlSystemsManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Resurgent Health And Medical"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Resurgent Health And Medical Manufacturer Mac Oui Rule 1",
          "ruleId": "resurgentHealthMedicalManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "RICOH-Device"
          ],
          "hardwareManufacturer": [
            "Ricoh Company, Ltd."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Ricoh Device Mac Oui Rule",
          "ruleId": "ricohDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "RICOH-Device"
          ],
          "hardwareManufacturer": [
            "Ricoh Company, Ltd."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Ricoh Device Mac Oui Rule 2",
          "ruleId": "ricohDeviceMacOuiRule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "hrDeviceDescr"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "RICOH-Device"
          ],
          "hardwareManufacturer": [
            "Ricoh Company, Ltd."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Ricoh Device Snmp Rule",
          "ruleId": "ricohDeviceSnmpRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "hrDeviceDescr"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "RICOH-Device"
          ],
          "hardwareManufacturer": [
            "Ricoh Company, Ltd."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Ricoh Printer Snmp Rule",
          "ruleId": "ricohPrinterSnmpRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "sysDescr"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "RICOH-Device"
          ],
          "hardwareManufacturer": [
            "Ricoh Company, Ltd."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Ricoh Printer Snmp Sys Description Rule",
          "ruleId": "ricohPrinterSnmpSysDescRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Robert-Bosch-ATMO-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Robert Bosch ATMO Device Mac Oui Rule 1",
          "ruleId": "Robert-Bosch-ATMO-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Robert-Bosch-Automotive-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Robert Bosch Automotive Device Mac Oui Rule 1",
          "ruleId": "Robert-Bosch-Automotive-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Robert-Bosch-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Robert Bosch Device Mac Oui Rule 1",
          "ruleId": "Robert-Bosch-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Robert-Bosch-Smart-Home-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Robert Bosch Smart Home Device Mac Oui Rule 1",
          "ruleId": "Robert-Bosch-Smart-Home-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "RoboTech-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "RoboTech Device Mac Oui Rule 1",
          "ruleId": "RoboTech-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Robot-Visual-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Robot Visual Systems Device Mac Oui Rule 1",
          "ruleId": "Robot-Visual-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Robot Visual Systems"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Robot Visual Systems Manufacturer Mac Oui Rule 1",
          "ruleId": "robotVisualSystemsManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Robotous-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Robotous Device Mac Oui Rule 1",
          "ruleId": "Robotous-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Rockwell-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Rockwell Automation Device Mac Oui Rule 1",
          "ruleId": "Rockwell-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "IOT Device"
          ],
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Roku-Device"
          ],
          "hardwareManufacturer": [
            "Roku, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Roku Device Mac Oui Rule",
          "ruleId": "rokuDeviceMacOuiRule",
          "operatingSystem": [
            "Roku OS"
          ],
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "IOT Device"
          ],
          "usedAttributes": [
            "mdnsModelName",
            "deviceType",
            "oui",
            "hardwareManufacturer"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Roku-Stick",
            "Roku-Device"
          ],
          "hardwareManufacturer": [
            "Roku, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Roku Stick Model Name Rule",
          "ruleId": "rokuStickModelNameRule",
          "operatingSystem": [
            "Roku OS"
          ],
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Rosemont-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Rosemont Controls Device Mac Oui Rule 1",
          "ruleId": "Rosemont-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Ross-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Ross Controls Device Mac Oui Rule 1",
          "ruleId": "Ross-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "S2-Security-Corp-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "S2 Security Corp Device Mac Oui Rule 1",
          "ruleId": "S2-Security-Corp-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "SE-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "SE Controls Device Mac Oui Rule 1",
          "ruleId": "SE-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "SEGGER-Microcontroller-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "SEGGER Microcontroller Device Mac Oui Rule 1",
          "ruleId": "SEGGER-Microcontroller-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "SEL-Schweitzer-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "SEL Schweitzer Device Mac Oui Rule 1",
          "ruleId": "SEL-Schweitzer-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "SGM-Technology-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "SGM Technology Device Mac Oui Rule 1",
          "ruleId": "SGM-Technology-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "SHI-Control-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "SHI Control Systems Device Mac Oui Rule 1",
          "ruleId": "SHI-Control-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "SPG-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "SPG Controls Device Mac Oui Rule 1",
          "ruleId": "SPG-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "SSI-Schaefer-Peem-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "SSI Schaefer Peem Device Mac Oui Rule 1",
          "ruleId": "SSI-Schaefer-Peem-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "SWAC-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "SWAC Device Mac Oui Rule 1",
          "ruleId": "SWAC-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Safetran-Traffic-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Safetran Traffic Systems Device Mac Oui Rule 1",
          "ruleId": "Safetran-Traffic-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Saia-Burgess-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Saia Burgess Controls Device Mac Oui Rule 1",
          "ruleId": "Saia-Burgess-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "SAIA "
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Saia Manufacturer Mac Oui Rule 1",
          "ruleId": "saiaManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dhcpClassIdentifier"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Samsung-Device"
          ],
          "hardwareManufacturer": [
            "Samsung Electronics Co., Ltd"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Samsung Device Dhcp Class Identifier Rule",
          "ruleId": "SamsungDeviceDhcpClassIdentifierRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dhcpv6VendorClass"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Samsung-Device"
          ],
          "hardwareManufacturer": [
            "Samsung Electronics Co., Ltd"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Samsung Device DhcpV6 Class Identifier Rule",
          "ruleId": "SamsungDeviceDhcpV6ClassIdentifierRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Samsung-Device"
          ],
          "hardwareManufacturer": [
            "Samsung Electronics Co., Ltd"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Samsung Device Mac Oui Rule",
          "ruleId": "samsungDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "hrDeviceDescr"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Samsung-Device"
          ],
          "hardwareManufacturer": [
            "Samsung Electronics Co., Ltd"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Samsung Device Snmp Rule",
          "ruleId": "samsungDeviceSnmpRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Mobile Device"
          ],
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Samsung-Galaxy",
            "Samsung-Device"
          ],
          "hardwareManufacturer": [
            "Samsung Electronics Co., Ltd"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Samsung Galaxy User Agent Rule",
          "ruleId": "SamsungGalaxyUserAgentRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Printer"
          ],
          "usedAttributes": [
            "dhcpClassIdentifier"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Samsung-Printer",
            "Samsung-Device"
          ],
          "hardwareManufacturer": [
            "Samsung Electronics Co., Ltd"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Samsung Printer Dhcp Class Identifier Rule",
          "ruleId": "SamsungPrinterDhcpClassIdentifierRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "hrDeviceDescr"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Samsung-Device"
          ],
          "hardwareManufacturer": [
            "Samsung Electronics Co., Ltd"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Samsung Printer Snmp Rule",
          "ruleId": "SamsungPrinterSnmpRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "IOT Device"
          ],
          "usedAttributes": [
            "oui",
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Samsung-Smart-Tv",
            "Samsung-Device"
          ],
          "hardwareManufacturer": [
            "Samsung Electronics Co., Ltd"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Samsung Smart TV User Agent Rule",
          "ruleId": "SamsungSmartTvUserAgentRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "IOT Device"
          ],
          "usedAttributes": [
            "oui",
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Samsung-Smart-Tv",
            "Samsung-Device"
          ],
          "hardwareManufacturer": [
            "Samsung Electronics Co., Ltd"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Samsung Smart TV User Agent Rule 2",
          "ruleId": "SamsungSmartTvUserAgentRule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Samsung-Techwin-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Samsung Techwin Device Mac Oui Rule 1",
          "ruleId": "Samsung-Techwin-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Sanritz-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Sanritz Automation Device Mac Oui Rule 1",
          "ruleId": "Sanritz-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Satchwell-Control-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Satchwell Control Systems Device Mac Oui Rule 1",
          "ruleId": "Satchwell-Control-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Savant-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Savant Systems Device Mac Oui Rule 1",
          "ruleId": "Savant-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": null,
          "conditionGroups": {
            "conditionGroup": [
              {
                "condition": {
                  "attribute": "assetMacAddress",
                  "value": "00:50:56:A0:C8:67",
                  "attributeDictionary": "IOTAsset",
                  "operator": "equals"
                },
                "type": "attr"
              }
            ],
            "operator": "and"
          },
          "hardwareModel": [
            "Magnetom Vida"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Custom",
          "ruleVersion": 1,
          "lastModifiedBy": "admin",
          "ruleName": "Scanner-HW",
          "ruleId": "custom-c6253fd3-8794-4a25-8f7f-650732f28922",
          "operatingSystem": null,
          "lastModifiedOn": 1590763493599
        },
        {
          "deviceType": null,
          "usedAttributes": null,
          "conditionGroups": {
            "conditionGroup": [
              {
                "condition": {
                  "attribute": "assetMacAddress",
                  "value": "00:50:56:A0:C8:67",
                  "attributeDictionary": "IOTAsset",
                  "operator": "equals"
                },
                "type": "attr"
              }
            ],
            "operator": "and"
          },
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Custom",
          "ruleVersion": 1,
          "lastModifiedBy": "admin",
          "ruleName": "Scanner-Linux",
          "ruleId": "custom-7cee7314-8b9e-47e7-ace8-12babf2ef52b",
          "operatingSystem": [
            "Linux"
          ],
          "lastModifiedOn": 1591009140386
        },
        {
          "deviceType": null,
          "usedAttributes": null,
          "conditionGroups": {
            "conditionGroup": [
              {
                "condition": {
                  "attribute": "assetMacAddress",
                  "value": "00:50:56:A0:C8:67",
                  "attributeDictionary": "IOTAsset",
                  "operator": "equals"
                },
                "type": "attr"
              }
            ],
            "operator": "and"
          },
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Siemens Healthcare"
          ],
          "ruleType": "Custom",
          "ruleVersion": 1,
          "lastModifiedBy": "admin",
          "ruleName": "Scanner-Siemens",
          "ruleId": "custom-2ada07c2-8914-42f7-9f78-014765c88fbe",
          "operatingSystem": null,
          "lastModifiedOn": 1590763586664
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Schilling-Robotics-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Schilling Robotics Device Mac Oui Rule 1",
          "ruleId": "Schilling-Robotics-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Schindler-Elevator-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Schindler Elevator Device Mac Oui Rule 1",
          "ruleId": "Schindler-Elevator-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Schlumberger-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Schlumberger Device Mac Oui Rule 1",
          "ruleId": "Schlumberger-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Schlumberger-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Schlumberger Device Mac Oui Rule 2",
          "ruleId": "Schlumberger-Device-Rule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Schlumberger-Kabushiki-Kaisha-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Schlumberger Kabushiki Kaisha Device Mac Oui Rule 1",
          "ruleId": "Schlumberger-Kabushiki-Kaisha-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Schlumberger-Retail-Petroleum-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Schlumberger Retail Petroleum Device Mac Oui Rule 1",
          "ruleId": "Schlumberger-Retail-Petroleum-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Schlumberger-Well-Services-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Schlumberger Well Services Device Mac Oui Rule 1",
          "ruleId": "Schlumberger-Well-Services-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Schneider-Electric-Asia-Pacific-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Schneider Electric Asia Pacific Device Mac Oui Rule 1",
          "ruleId": "Schneider-Electric-Asia-Pacific-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Schneider-Electric-Australia-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Schneider Electric Australia Device Mac Oui Rule 1",
          "ruleId": "Schneider-Electric-Australia-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Schneider-Electric-Canada-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Schneider Electric Canada Device Mac Oui Rule 1",
          "ruleId": "Schneider-Electric-Canada-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Schneider-Electric-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Schneider Electric Device Mac Oui Rule 1",
          "ruleId": "Schneider-Electric-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Schneider-Electric-Fire-Security-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Schneider Electric Fire Security Device Mac Oui Rule 1",
          "ruleId": "Schneider-Electric-Fire-Security-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Schneider-Electric-France-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Schneider Electric France Device Mac Oui Rule 1",
          "ruleId": "Schneider-Electric-France-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Schneider-Electric-Germany-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Schneider Electric Germany Device Mac Oui Rule 1",
          "ruleId": "Schneider-Electric-Germany-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Schneider Electric GmbH"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Schneider Electric Gmbh Manufacturer Mac Oui Rule 1",
          "ruleId": "schneiderElectricGmbhManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Schneider-Electric-Motion-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Schneider Electric Motion Device Mac Oui Rule 1",
          "ruleId": "Schneider-Electric-Motion-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Schneider Electric Motion"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Schneider Electric Motion Manufacturer Mac Oui Rule 1",
          "ruleId": "schneiderElectricMotionManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Schneider Electric Motion"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Schneider Electric Motion Manufacturer Mac Oui Rule 2",
          "ruleId": "schneiderElectricMotionManufacturerMacOuiRule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Schneider Electric Motion"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Schneider Electric Motion Manufacturer Mac Oui Rule 3",
          "ruleId": "schneiderElectricMotionManufacturerMacOuiRule3",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Schneider-Electric-Ultra-Terminal-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Schneider Electric Ultra Terminal Device Mac Oui Rule 1",
          "ruleId": "Schneider-Electric-Ultra-Terminal-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266339
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Scientific-Atlanta-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Scientific Atlanta Device Mac Oui Rule",
          "ruleId": "scientificAtlantaDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Se-Kure-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Se Kure Controls Device Mac Oui Rule 1",
          "ruleId": "Se-Kure-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Securite-Communications-Honeywell-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Securite Communications Honeywell Device Mac Oui Rule 1",
          "ruleId": "Securite-Communications-Honeywell-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Sensys-Traffic-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Sensys Traffic Device Mac Oui Rule 1",
          "ruleId": "Sensys-Traffic-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Sentry-360-Security-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Sentry 360 Security Device Mac Oui Rule 1",
          "ruleId": "Sentry-360-Security-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Sepro-Robotique-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Sepro Robotique Device Mac Oui Rule 1",
          "ruleId": "Sepro-Robotique-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Servo-Robot-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Servo Robot Device Mac Oui Rule 1",
          "ruleId": "Servo-Robot-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Servo-Robot Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Servo Robot Inc Manufacturer Mac Oui Rule 1",
          "ruleId": "servoRobotInc-ManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ShangHai-Sun-Up-Lighting-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ShangHai Sun Up Lighting Device Mac Oui Rule 1",
          "ruleId": "ShangHai-Sun-Up-Lighting-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Shanghai-iComhome-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Shanghai iComhome Device Mac Oui Rule 1",
          "ruleId": "Shanghai-iComhome-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Shenzhen-HT-Intelligent-Control-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Shenzhen HT Intelligent Control Device Mac Oui Rule 1",
          "ruleId": "Shenzhen-HT-Intelligent-Control-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Shenzhen-Heguang-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Shenzhen Heguang Device Mac Oui Rule 1",
          "ruleId": "Shenzhen-Heguang-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Shenzhen-Shidean-Legrand-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Shenzhen Shidean Legrand Device Mac Oui Rule 1",
          "ruleId": "Shenzhen-Shidean-Legrand-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Shibaura-Vending-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Shibaura Vending Device Mac Oui Rule 1",
          "ruleId": "Shibaura-Vending-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ShopperTrak-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ShopperTrak Device Mac Oui Rule 1",
          "ruleId": "ShopperTrak-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Shoretel-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Shoretel Device Mac Oui Rule",
          "ruleId": "shoretelDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "IOT Device"
          ],
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Shure-Device"
          ],
          "hardwareManufacturer": [
            "Shure Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Shure Mac Oui Rule",
          "ruleId": "ShureMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "SiboTech-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "SiboTech Automation Device Mac Oui Rule 1",
          "ruleId": "SiboTech-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Siemens-Asia-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Siemens Asia Device Mac Oui Rule 1",
          "ruleId": "Siemens-Asia-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Siemens-Austria-SIMEA-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Siemens Austria SIMEA Device Mac Oui Rule 1",
          "ruleId": "Siemens-Austria-SIMEA-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Siemens-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Siemens Automation Device Mac Oui Rule 1",
          "ruleId": "Siemens-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266339
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Siemens-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Siemens Automation Device Mac Oui Rule 2",
          "ruleId": "Siemens-Automation-Device-Rule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266339
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Siemens-Automation-Drives-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Siemens Automation Drives Device Mac Oui Rule 1",
          "ruleId": "Siemens-Automation-Drives-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Siemens-Belgium-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Siemens Belgium Device Mac Oui Rule 1",
          "ruleId": "Siemens-Belgium-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Siemens-Building-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Siemens Building Device Mac Oui Rule 1",
          "ruleId": "Siemens-Building-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Siemens-Building-Technologies-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Siemens Building Technologies Device Mac Oui Rule 1",
          "ruleId": "Siemens-Building-Technologies-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Siemens-Convergence-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Siemens Convergence Device Mac Oui Rule 1",
          "ruleId": "Siemens-Convergence-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Siemens-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Siemens Device Mac Oui Rule 1",
          "ruleId": "Siemens-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Siemens-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Siemens Device Mac Oui Rule 2",
          "ruleId": "Siemens-Device-Rule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Siemens-Digital-Factory-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Siemens Digital Factory Device Mac Oui Rule 1",
          "ruleId": "Siemens-Digital-Factory-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Siemens-Energy-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Siemens Energy Automation Device Mac Oui Rule 1",
          "ruleId": "Siemens-Energy-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Siemens-Energy-Management-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Siemens Energy Management Device Mac Oui Rule 1",
          "ruleId": "Siemens-Energy-Management-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Siemens-Home-Office-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Siemens Home Office Device Mac Oui Rule 1",
          "ruleId": "Siemens-Home-Office-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Siemens-ICM-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Siemens ICM Device Mac Oui Rule 1",
          "ruleId": "Siemens-ICM-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Siemens-Industrial-Automation-China-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Siemens Industrial Automation China Device Mac Oui Rule 1",
          "ruleId": "Siemens-Industrial-Automation-China-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266339
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Siemens-Industrial-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Siemens Industrial Automation Device Mac Oui Rule 1",
          "ruleId": "Siemens-Industrial-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Siemens-Industrial-Automation-EWA-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Siemens Industrial Automation EWA Device Mac Oui Rule 1",
          "ruleId": "Siemens-Industrial-Automation-EWA-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Siemens-Industrial-Solutions-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Siemens Industrial Solutions Device Mac Oui Rule 1",
          "ruleId": "Siemens-Industrial-Solutions-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Siemens-Industry-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Siemens Industry Device Mac Oui Rule 1",
          "ruleId": "Siemens-Industry-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Siemens-Low-Voltage-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Siemens Low Voltage Device Mac Oui Rule 1",
          "ruleId": "Siemens-Low-Voltage-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Siemens-Numerical-Control-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Siemens Numerical Control Device Mac Oui Rule 1",
          "ruleId": "Siemens-Numerical-Control-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Siemens-Sector-Industry-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Siemens Sector Industry Device Mac Oui Rule 1",
          "ruleId": "Siemens-Sector-Industry-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Siemens-Switzerland-BT-HVP-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Siemens Switzerland BT HVP Device Mac Oui Rule 1",
          "ruleId": "Siemens-Switzerland-BT-HVP-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Siemens-Transportation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Siemens Transportation Device Mac Oui Rule 1",
          "ruleId": "Siemens-Transportation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "SightLogix-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "SightLogix Device Mac Oui Rule 1",
          "ruleId": "SightLogix-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Silex-Technology-America-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Silex Technology America Device Mac Oui Rule 1",
          "ruleId": "Silex-Technology-America-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Silex-Technology-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Silex Technology Device Mac Oui Rule 1",
          "ruleId": "Silex-Technology-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Silex-Technology-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Silex Technology Device Mac Oui Rule 2",
          "ruleId": "Silex-Technology-Device-Rule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Siqura-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Siqura Device Mac Oui Rule 1",
          "ruleId": "Siqura-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Sistemas-Avanzados-Control-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Sistemas Avanzados Control Device Mac Oui Rule 1",
          "ruleId": "Sistemas-Avanzados-Control-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "SmarteBuilding-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "SmarteBuilding Device Mac Oui Rule 1",
          "ruleId": "SmarteBuilding-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Smarthome-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Smarthome Device Mac Oui Rule 1",
          "ruleId": "Smarthome-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Soenen-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Soenen Controls Device Mac Oui Rule 1",
          "ruleId": "Soenen-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Softlink-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Softlink Automation Device Mac Oui Rule 1",
          "ruleId": "Softlink-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Workstation"
          ],
          "usedAttributes": [
            "operatingSystem"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Solaris-Workstation"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Solaris Workstation Os Rule",
          "ruleId": "solarisWorkstationOsRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Solberg-Andersen-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Solberg Andersen Device Mac Oui Rule 1",
          "ruleId": "Solberg-Andersen-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Somat-Corp-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Somat Corp Device Mac Oui Rule 1",
          "ruleId": "Somat-Corp-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Sonicwall-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Sonicwall Device Mac Oui Rule",
          "ruleId": "sonicwallDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266339
        },
        {
          "deviceType": [
            "IOT Device"
          ],
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Sonos-Device"
          ],
          "hardwareManufacturer": [
            "Sonos, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Sonos Device Mac Oui Rule",
          "ruleId": "sonosDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Wireless Speaker",
            "IOT Device"
          ],
          "usedAttributes": [
            "oui",
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Sonos-Device"
          ],
          "hardwareManufacturer": [
            "Sonos, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Sonos User Agent Rule 1",
          "ruleId": "sonosUserAgentRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "Wireless Speaker",
            "IOT Device"
          ],
          "usedAttributes": [
            "oui",
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Sonos One",
            "Sonos-Device"
          ],
          "hardwareManufacturer": [
            "Sonos, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Sonos User Agent Rule 2",
          "ruleId": "sonosUserAgentRule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "Wireless Speaker",
            "IOT Device"
          ],
          "usedAttributes": [
            "oui",
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Sonos Beam",
            "Sonos-Device"
          ],
          "hardwareManufacturer": [
            "Sonos, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Sonos User Agent Rule 3",
          "ruleId": "sonosUserAgentRule3",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Sony-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Sony Device Mac Oui Rule",
          "ruleId": "sonyDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "IOT Device"
          ],
          "usedAttributes": [
            "mdnsModelName",
            "deviceType",
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Sony-Device"
          ],
          "hardwareManufacturer": [
            "Sony Interactive Entertainment Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Sony Model Name And Ssdp Manufacturer And Mac OUI  Rule",
          "ruleId": "sonyModelNameAndSsdpManufacturerAndMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "IOT Device"
          ],
          "usedAttributes": [
            "oui",
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Sony-Device"
          ],
          "hardwareManufacturer": [
            "Sony Interactive Entertainment Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Sony User Agent And Mac OUI Rule",
          "ruleId": "sonyUserAgentAndMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Soredex Instrumentarium Oyj"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Soredex Instrumentarium Oyj Manufacturer Mac Oui Rule 1",
          "ruleId": "soredexInstrumentariumOyjManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Spectra-Technologies-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Spectra Technologies Device Mac Oui Rule 1",
          "ruleId": "Spectra-Technologies-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Spectronix-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Spectronix Device Mac Oui Rule 1",
          "ruleId": "Spectronix-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Spectrum-Control-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Spectrum Control Device Mac Oui Rule 1",
          "ruleId": "Spectrum-Control-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Spectrum-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Spectrum Controls Device Mac Oui Rule 1",
          "ruleId": "Spectrum-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Spire-Payments-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Spire Payments Device Mac Oui Rule 1",
          "ruleId": "Spire-Payments-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Sprecher-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Sprecher Automation Device Mac Oui Rule 1",
          "ruleId": "Sprecher-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Square-D-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Square D Device Mac Oui Rule 1",
          "ruleId": "Square-D-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "SSI Schaefer Peem"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Ssi Schaefer Peem Manufacturer Mac Oui Rule 1",
          "ruleId": "ssiSchaeferPeemManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "St Jude Medical, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "St Jude Medical Inc Manufacturer Mac Oui Rule 1",
          "ruleId": "stJudeMedical-Inc-ManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Star-Micronics-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Star Micronics Device Mac Oui Rule 1",
          "ruleId": "Star-Micronics-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "StarDot-Technologies-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "StarDot Technologies Device Mac Oui Rule 1",
          "ruleId": "StarDot-Technologies-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266339
        },
        {
          "deviceType": null,
          "usedAttributes": null,
          "conditionGroups": {
            "conditionGroup": [
              {
                "condition": {
                  "attribute": "assetMacAddress",
                  "value": "00:50:56:A0:D7:3F",
                  "attributeDictionary": "IOTAsset",
                  "operator": "equals"
                },
                "type": "attr"
              }
            ],
            "operator": "and"
          },
          "hardwareModel": [
            "PACS Scan"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Custom",
          "ruleVersion": 1,
          "lastModifiedBy": "admin",
          "ruleName": "Storage-HW",
          "ruleId": "custom-f7958d1e-33dc-4971-82f8-7df363bdac67",
          "operatingSystem": null,
          "lastModifiedOn": 1590763805555
        },
        {
          "deviceType": null,
          "usedAttributes": null,
          "conditionGroups": {
            "conditionGroup": [
              {
                "condition": {
                  "attribute": "assetMacAddress",
                  "value": "00:50:56:A0:D7:3F",
                  "attributeDictionary": "IOTAsset",
                  "operator": "equals"
                },
                "type": "attr"
              }
            ],
            "operator": "and"
          },
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Custom",
          "ruleVersion": 1,
          "lastModifiedBy": "admin",
          "ruleName": "Storage-Linux",
          "ruleId": "custom-54ab632a-2124-4585-acb6-ccb645abb196",
          "operatingSystem": [
            "Linux"
          ],
          "lastModifiedOn": 1590763750196
        },
        {
          "deviceType": null,
          "usedAttributes": null,
          "conditionGroups": {
            "conditionGroup": [
              {
                "condition": {
                  "attribute": "assetMacAddress",
                  "value": "00:50:56:A0:D7:3F",
                  "attributeDictionary": "IOTAsset",
                  "operator": "equals"
                },
                "type": "attr"
              }
            ],
            "operator": "and"
          },
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Siemens Healthcare"
          ],
          "ruleType": "Custom",
          "ruleVersion": 1,
          "lastModifiedBy": "admin",
          "ruleName": "Storage-Siemens",
          "ruleId": "custom-2a30beb7-36d3-423b-aa25-d65cb1e79ffb",
          "operatingSystem": null,
          "lastModifiedOn": 1590763845672
        },
        {
          "deviceType": [
            "Storage"
          ],
          "usedAttributes": null,
          "conditionGroups": {
            "conditionGroup": [
              {
                "condition": {
                  "attribute": "assetMacAddress",
                  "value": "00:50:56:A0:D7:3F",
                  "attributeDictionary": "IOTAsset",
                  "operator": "equals"
                },
                "type": "attr"
              }
            ],
            "operator": "and"
          },
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Custom",
          "ruleVersion": 1,
          "lastModifiedBy": "admin",
          "ruleName": "Storage-Type",
          "ruleId": "custom-51da1180-6172-4d22-b382-48b4833dede8",
          "operatingSystem": null,
          "lastModifiedOn": 1590763692837
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Strand-Lighting-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Strand Lighting Device Mac Oui Rule 1",
          "ruleId": "Strand-Lighting-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Sun Os User Agent Rule",
          "ruleId": "sunOsUserAgentRule",
          "operatingSystem": [
            "SunOS"
          ],
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Sunw User Agent Rule",
          "ruleId": "sunwUserAgentRule",
          "operatingSystem": [
            "SUNW"
          ],
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Super-Micro-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Super Micro Device Mac Oui Rule",
          "ruleId": "superMicroDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Sur-Gard-Security-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Sur Gard Security Device Mac Oui Rule 1",
          "ruleId": "Sur-Gard-Security-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Suse User Agent Rule",
          "ruleId": "suseUserAgentRule",
          "operatingSystem": [
            "SUSE"
          ],
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Swarco-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Swarco Device Mac Oui Rule 1",
          "ruleId": "Swarco-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Symbian Os User Agent Rule",
          "ruleId": "symbianOsUserAgentRule",
          "operatingSystem": [
            "Symbian"
          ],
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Symbol-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Symbol Device Mac Oui Rule",
          "ruleId": "symbolDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Symbol-POS-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Symbol POS Device Mac Oui Rule 1",
          "ruleId": "Symbol-POS-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Synergy-Lighting-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Synergy Lighting Controls Device Mac Oui Rule 1",
          "ruleId": "Synergy-Lighting-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Synology-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Synology Device Mac Oui Rule",
          "ruleId": "synologyDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Syscor-Controls-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Syscor Controls Automation Device Mac Oui Rule 1",
          "ruleId": "Syscor-Controls-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "TF-Payment-Service-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "TF Payment Service Device Mac Oui Rule 1",
          "ruleId": "TF-Payment-Service-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "TPA-Traffic-Parking-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "TPA Traffic Parking Automation Device Mac Oui Rule 1",
          "ruleId": "TPA-Traffic-Parking-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "TST-Biometrics-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "TST Biometrics Device Mac Oui Rule 1",
          "ruleId": "TST-Biometrics-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "TTC-Next-Gen-Home-Network-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "TTC Next Gen Home Network Device Mac Oui Rule 1",
          "ruleId": "TTC-Next-Gen-Home-Network-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "TVT-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "TVT Device Mac Oui Rule 1",
          "ruleId": "TVT-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "TagMaster-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "TagMaster Device Mac Oui Rule 1",
          "ruleId": "TagMaster-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Takagi-Industrial-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Takagi Industrial Device Mac Oui Rule 1",
          "ruleId": "Takagi-Industrial-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Tesco-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Tesco Controls Device Mac Oui Rule 1",
          "ruleId": "Tesco-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "The Trane Company"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "The Trane Company Manufacturer Mac Oui Rule 1",
          "ruleId": "theTraneCompanyManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Thermo-Fisher-Scientific-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Thermo Fisher Scientific Device Mac Oui Rule 1",
          "ruleId": "Thermo-Fisher-Scientific-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Thomas-Betts-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Thomas Betts Device Mac Oui Rule 1",
          "ruleId": "Thomas-Betts-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ThyssenKrupp-Elevator-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ThyssenKrupp Elevator Device Mac Oui Rule 1",
          "ruleId": "ThyssenKrupp-Elevator-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Time-Management-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Time Management Device Mac Oui Rule 1",
          "ruleId": "Time-Management-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Time-O-Matic-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Time O Matic Device Mac Oui Rule 1",
          "ruleId": "Time-O-Matic-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "TimeKeeping-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "TimeKeeping Systems Device Mac Oui Rule 1",
          "ruleId": "TimeKeeping-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Tivo-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Tivo Device Mac Oui Rule",
          "ruleId": "tivoDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "TopControl-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "TopControl Device Mac Oui Rule 1",
          "ruleId": "TopControl-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Toshiba-Mitsubishi-Electric-Industrial-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Toshiba Mitsubishi Electric Industrial Device Mac Oui Rule 1",
          "ruleId": "Toshiba-Mitsubishi-Electric-Industrial-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Touchless Biometric Systems AG"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Touchless Biometric Systems Ag Manufacturer Mac Oui Rule 1",
          "ruleId": "touchlessBiometricSystemsAgManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Touchless-Biometric-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Touchless Biometric Systems Device Mac Oui Rule 1",
          "ruleId": "Touchless-Biometric-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "TP-LINK-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Tp Link Device Mac Oui Rule",
          "ruleId": "tpLinkDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "TPA Traffic"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Tpa Traffic Manufacturer Mac Oui Rule 1",
          "ruleId": "tpaTrafficManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "TrafficCast-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "TrafficCast Device Mac Oui Rule 1",
          "ruleId": "TrafficCast-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Trane-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Trane Device Mac Oui Rule 1",
          "ruleId": "Trane-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Trane-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Trane Device Mac Oui Rule 2",
          "ruleId": "Trane-Device-Rule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Trebing-Himstedt-Proze-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Trebing Himstedt Proze Automation Device Mac Oui Rule 1",
          "ruleId": "Trebing-Himstedt-Proze-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Trendnet-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Trendnet Device Mac Oui Rule",
          "ruleId": "trendnetDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Tridium-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Tridium Device Mac Oui Rule 1",
          "ruleId": "Tridium-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "TrippLite-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Tripplite Device Mac Oui Rule",
          "ruleId": "trippliteDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Triton-Electronics-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Triton Electronics Device Mac Oui Rule 1",
          "ruleId": "Triton-Electronics-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Triton Electronics LTD"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Triton Electronics Ltd Manufacturer Mac Oui Rule 1",
          "ruleId": "tritonElectronicsLtdManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Triton Electronics LTD"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Triton Electronics Ltd Manufacturer Mac Oui Rule 2",
          "ruleId": "tritonElectronicsLtdManufacturerMacOuiRule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Triton-Systems-ATM-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Triton Systems ATM Device Mac Oui Rule 1",
          "ruleId": "Triton-Systems-ATM-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Truen-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Truen Device Mac Oui Rule 1",
          "ruleId": "Truen-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "TurControlSystme-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "TurControlSystme Device Mac Oui Rule 1",
          "ruleId": "TurControlSystme-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Tyco Traffic And Transportation"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Tyco Traffic And Transportation Manufacturer Mac Oui Rule 1",
          "ruleId": "tycoTrafficTransportationManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Tyco-Traffic-Transportation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Tyco Traffic Transportation Device Mac Oui Rule 1",
          "ruleId": "Tyco-Traffic-Transportation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "CT-Scanner"
          ],
          "usedAttributes": null,
          "conditionGroups": {
            "conditionGroup": [
              {
                "condition": {
                  "attribute": "assetMacAddress",
                  "value": "00:50:56:A0:C8:67",
                  "attributeDictionary": "IOTAsset",
                  "operator": "equals"
                },
                "type": "attr"
              }
            ],
            "operator": "and"
          },
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Custom",
          "ruleVersion": 1,
          "lastModifiedBy": "admin",
          "ruleName": "Type-CT-Scanner",
          "ruleId": "custom-c9614f73-790c-4a6a-b668-7dbe08342ad7",
          "operatingSystem": null,
          "lastModifiedOn": 1590763542673
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "US-Traffic-Corp-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "US Traffic Corp Device Mac Oui Rule 1",
          "ruleId": "US-Traffic-Corp-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "UTC-Climate-Control-Security-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "UTC Climate Control Security Device Mac Oui Rule 1",
          "ruleId": "UTC-Climate-Control-Security-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "UTC-Fire-Security-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "UTC Fire Security Device Mac Oui Rule 1",
          "ruleId": "UTC-Fire-Security-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Ubuntu User Agent Rule",
          "ruleId": "ubuntuUserAgentRule",
          "operatingSystem": [
            "Ubuntu"
          ],
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Ultra-Electronics-Command-Control-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Ultra Electronics Command Control Device Mac Oui Rule 1",
          "ruleId": "Ultra-Electronics-Command-Control-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Ultra-Electronics-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Ultra Electronics Device Mac Oui Rule 1",
          "ruleId": "Ultra-Electronics-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "UniControls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "UniControls Device Mac Oui Rule 1",
          "ruleId": "UniControls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "United-Technologies-Corp-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "United Technologies Corp Device Mac Oui Rule 1",
          "ruleId": "United-Technologies-Corp-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "United-Technologies-Corp-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "United Technologies Corp Device Mac Oui Rule 2",
          "ruleId": "United-Technologies-Corp-Device-Rule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "United-Technologies-Corp-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "United Technologies Corp Device Mac Oui Rule 3",
          "ruleId": "United-Technologies-Corp-Device-Rule3",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "United-Western-Technologies-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "United Western Technologies Device Mac Oui Rule 1",
          "ruleId": "United-Western-Technologies-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "United Western Technologies"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "United Western Technologies Manufacturer Mac Oui Rule 1",
          "ruleId": "unitedWesternTechnologiesManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Universal-Control-Solution-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Universal Control Solution Device Mac Oui Rule 1",
          "ruleId": "Universal-Control-Solution-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Universal-Remote-Control-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Universal Remote Control Device Mac Oui Rule 1",
          "ruleId": "Universal-Remote-Control-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Universidad de Chile Facultad de Medicina"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Universidad De Chile Facultad De Medicina Manufacturer Mac Oui Rule 1",
          "ruleId": "universidadDeChileFacultadDeMedicinaManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Uwe-Schneider-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Uwe Schneider Device Mac Oui Rule 1",
          "ruleId": "Uwe-Schneider-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Uwe Schneider"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Uwe Schneider Manufacturer Mac Oui Rule 1",
          "ruleId": "uweSchneiderManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "VG-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "VG Controls Device Mac Oui Rule 1",
          "ruleId": "VG-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Valtronic SA"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Valtronic Sa Manufacturer Mac Oui Rule 1",
          "ruleId": "valtronicSaManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Vanderbilt-International-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Vanderbilt International Device Mac Oui Rule 1",
          "ruleId": "Vanderbilt-International-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Vantage-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Vantage Controls Device Mac Oui Rule 1",
          "ruleId": "Vantage-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Vectron-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Vectron Systems Device Mac Oui Rule 1",
          "ruleId": "Vectron-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "VendNovation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "VendNovation Device Mac Oui Rule 1",
          "ruleId": "VendNovation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "VendNovation LLC"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Vendnovation Llc Manufacturer Mac Oui Rule 1",
          "ruleId": "vendnovationLlcManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Verifone-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Verifone Device Mac Oui Rule 1",
          "ruleId": "Verifone-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Verifone-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Verifone Device Mac Oui Rule 2",
          "ruleId": "Verifone-Device-Rule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Verifone-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Verifone Device Mac Oui Rule 3",
          "ruleId": "Verifone-Device-Rule3",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Verint-Loronix-Video-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Verint Loronix Video Device Mac Oui Rule 1",
          "ruleId": "Verint-Loronix-Video-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "IOT Device"
          ],
          "usedAttributes": [
            "sysDescr"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Vertiv WatchDog 100",
            "Vertiv-Device"
          ],
          "hardwareManufacturer": [
            "Vertiv"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Vertiv Watchdog 100 rule",
          "ruleId": "VertivWatchdog100",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "sysDescr"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Vertiv-Device"
          ],
          "hardwareManufacturer": [
            "Vertiv"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Vertiv Watchdog rule",
          "ruleId": "VertivWatchdog",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Vialis-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Vialis Device Mac Oui Rule 1",
          "ruleId": "Vialis-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Vicon-Industries-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Vicon Industries Device Mac Oui Rule 1",
          "ruleId": "Vicon-Industries-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "VideoIQ-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "VideoIQ Device Mac Oui Rule 1",
          "ruleId": "VideoIQ-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Visonic-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Visonic Device Mac Oui Rule 1",
          "ruleId": "Visonic-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Vista-Control-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Vista Control Systems Device Mac Oui Rule 1",
          "ruleId": "Vista-Control-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Vista-Controls-Corp-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Vista Controls Corp Device Mac Oui Rule 1",
          "ruleId": "Vista-Controls-Corp-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "vitalcare"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Vitalcare Manufacturer Mac Oui Rule 1",
          "ruleId": "vitalcareManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Vivotek-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Vivotek Device Mac Oui Rule 1",
          "ruleId": "Vivotek-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Vizio-Device"
          ],
          "hardwareManufacturer": [
            "Vizio, Inc"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Vizio Device Oui Rule",
          "ruleId": "vizioDeviceOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": [
            "IOT Device"
          ],
          "usedAttributes": [
            "oui",
            "mdnsMd"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Vizio-Sound-Bar",
            "Vizio-Device"
          ],
          "hardwareManufacturer": [
            "Vizio, Inc"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Vizio Sound Bar Iot Mdns Md Rule",
          "ruleId": "vizioSoundBarIotMdnsMdRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "hardwareModel",
            "mdnsMd"
          ],
          "conditionGroups": null,
          "hardwareModel": [],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Dynamic",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Vizio Sound Bar Model Name Mdns Md Rule",
          "ruleId": "vizioSoundBarModelNameMdnsMdRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "VMWare-Device"
          ],
          "hardwareManufacturer": [
            "VMware, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "WPS-Parking-Systems-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "WPS Parking Systems Device Mac Oui Rule 1",
          "ruleId": "WPS-Parking-Systems-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Wachendorff-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Wachendorff Automation Device Mac Oui Rule 1",
          "ruleId": "Wachendorff-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Wayne-Dalton-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Wayne Dalton Device Mac Oui Rule 1",
          "ruleId": "Wayne-Dalton-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Wayne Dalton"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Wayne Dalton Manufacturer Mac Oui Rule 1",
          "ruleId": "wayneDaltonManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Weiss-Robotics-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Weiss Robotics Device Mac Oui Rule 1",
          "ruleId": "Weiss-Robotics-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Weiss Robotics GmbH And Co. KG"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Weiss Robotics Gmbh And Co Kg Manufacturer Mac Oui Rule 1",
          "ruleId": "weissRoboticsGmbhCo-KgManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Medical Device"
          ],
          "usedAttributes": [
            "dhcpClassIdentifier"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Welch-Allyn-Device"
          ],
          "hardwareManufacturer": [
            "Welch Allyn Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Welch Allyn Dhcp Rule",
          "ruleId": "welchAllynDhcpDeviceRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Medical Device"
          ],
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Welch-Allyn-Device"
          ],
          "hardwareManufacturer": [
            "Welch Allyn Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Welch Allyn Mac Rule",
          "ruleId": "welchAllynMacRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "West-Com Nurse Call Systems, Inc."
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "West Com Nurse Call Systems Inc Manufacturer Mac Oui Rule 1",
          "ruleId": "westComNurseCallSystems-Inc ManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Westcontrol-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Westcontrol Device Mac Oui Rule 1",
          "ruleId": "Westcontrol-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Wincor-Nixdorf-Diebold-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Wincor Nixdorf Diebold Device Mac Oui Rule 1",
          "ruleId": "Wincor-Nixdorf-Diebold-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Wincor-Nixdorf-Diebold-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Wincor Nixdorf Diebold Device Mac Oui Rule 2",
          "ruleId": "Wincor-Nixdorf-Diebold-Device-Rule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "devicePlatformVersion",
            "devicePlatform"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Windows 10 Acidex Rule",
          "ruleId": "windows10AcidexRule",
          "operatingSystem": [
            "Windows 10",
            "Windows"
          ],
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Windows 311 Os User Agent Rule",
          "ruleId": "windows311OsUserAgentRule",
          "operatingSystem": [
            "Windows 3.11",
            "Windows"
          ],
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "devicePlatformVersion",
            "devicePlatform"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Windows 7 Acidex Rule",
          "ruleId": "windows7AcidexRule",
          "operatingSystem": [
            "Windows 7",
            "Windows"
          ],
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "devicePlatformVersion",
            "devicePlatform"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Windows 8 Acidex Rule 1",
          "ruleId": "windows8AcidexRule1",
          "operatingSystem": [
            "Windows 8",
            "Windows"
          ],
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "devicePlatformVersion",
            "devicePlatform"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Windows 8 Acidex Rule 2",
          "ruleId": "windows8AcidexRule2",
          "operatingSystem": [
            "Windows 8",
            "Windows"
          ],
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Windows 95 Os User Agent Rule",
          "ruleId": "windows95OsUserAgentRule",
          "operatingSystem": [
            "Windows 95",
            "Windows"
          ],
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Windows 98 Os User Agent Rule",
          "ruleId": "windows98OsUserAgentRule",
          "operatingSystem": [
            "Windows 98",
            "Windows"
          ],
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": [
            "Workstation"
          ],
          "usedAttributes": [
            "dhcpClassIdentifier"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Windows Dhcp Class Identifier Rule",
          "ruleId": "windowsDhcpClassIdentifierRule",
          "operatingSystem": [
            "Windows"
          ],
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": [
            "Workstation"
          ],
          "usedAttributes": [
            "dhcpv6VendorClass"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Windows DhcpV6 Class Identifier Rule",
          "ruleId": "windowsDhcpV6ClassIdentifierRule",
          "operatingSystem": [
            "Windows"
          ],
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Windows Nt Os User Agent Rule",
          "ruleId": "windowsNtOsUserAgentRule",
          "operatingSystem": [
            "Windows NT",
            "Windows"
          ],
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Windows Os User Agent Rule 2",
          "ruleId": "windowsOsUserAgentRule2",
          "operatingSystem": [
            "Windows"
          ],
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Windows Os User Agent Rule 3",
          "ruleId": "windowsOsUserAgentRule3",
          "operatingSystem": [
            "Windows"
          ],
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Windows Os User Agent Rule 4",
          "ruleId": "windowsOsUserAgentRule4",
          "operatingSystem": [
            "Windows"
          ],
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Windows Os User Agent Rule 5",
          "ruleId": "windowsOsUserAgentRule5",
          "operatingSystem": [
            "Windows"
          ],
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Windows Xp Os User Agent Rule",
          "ruleId": "windowsXpOsUserAgentRule",
          "operatingSystem": [
            "Windows XP",
            "Windows"
          ],
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Winpresa-Building-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Winpresa Building Automation Device Mac Oui Rule 1",
          "ruleId": "Winpresa-Building-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Wintech-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Wintech Automation Device Mac Oui Rule 1",
          "ruleId": "Wintech-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Wuhan-FiberHome-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Wuhan FiberHome Device Mac Oui Rule 1",
          "ruleId": "Wuhan-FiberHome-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "WYSE-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Wyse Device Mac Oui Rule",
          "ruleId": "wyseDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "XAC-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "XAC Automation Device Mac Oui Rule 1",
          "ruleId": "XAC-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Xanboo-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Xanboo Device Mac Oui Rule 1",
          "ruleId": "Xanboo-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "userAgent"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Xandros User Agent Rule",
          "ruleId": "xandrosUserAgentRule",
          "operatingSystem": [
            "xandros"
          ],
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Xantech-Corp-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Xantech Corp Device Mac Oui Rule 1",
          "ruleId": "Xantech-Corp-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dhcpClassIdentifier"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Xerox-Device"
          ],
          "hardwareManufacturer": [
            "Xerox Corporation"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Xerox Device Dhcp Class Identifier Rule",
          "ruleId": "xeroxDeviceDhcpClassIdentifierRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Xerox-Device"
          ],
          "hardwareManufacturer": [
            "Xerox Corporation"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Xerox Device Mac Oui Rule",
          "ruleId": "xeroxDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "hrDeviceDescr"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Xerox-Device"
          ],
          "hardwareManufacturer": [
            "Xerox Corporation"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Xerox Device Snmp Rule",
          "ruleId": "xeroxDeviceSnmpRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dhcpClassIdentifier"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Xerox-Device"
          ],
          "hardwareManufacturer": [
            "Xerox Corporation"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Xerox Printer Dhcp Class Identifier Rule 1",
          "ruleId": "xeroxPrinterDhcpClassIdentifierRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dhcpClassIdentifier"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Xerox-Device"
          ],
          "hardwareManufacturer": [
            "Xerox Corporation"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Xerox Printer Dhcp Class Identifier Rule 2",
          "ruleId": "xeroxPrinterDhcpClassIdentifierRule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Xerox-Device"
          ],
          "hardwareManufacturer": [
            "Xerox Corporation"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Xerox Printer Nmap Os Rule",
          "ruleId": "xeroxPrinterNmapOsRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "hrDeviceDescr"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Xerox-Device"
          ],
          "hardwareManufacturer": [
            "Xerox Corporation"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Xerox Printer Snmp Rule",
          "ruleId": "xeroxPrinterSnmpRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Xiaomi-Device"
          ],
          "hardwareManufacturer": [
            "Xiaomi Corporation"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Xiaomi Device Mac Oui Rule",
          "ruleId": "xiaomiDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Xin-Hua-Control-Engineering-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Xin Hua Control Engineering Device Mac Oui Rule 1",
          "ruleId": "Xin-Hua-Control-Engineering-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Xin-Hua-Control-Engineering-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Xin Hua Control Engineering Device Mac Oui Rule 2",
          "ruleId": "Xin-Hua-Control-Engineering-Device-Rule2",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Xipher-Technologies-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Xipher Technologies Device Mac Oui Rule 1",
          "ruleId": "Xipher-Technologies-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "YORK-Refrigeration-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "YORK Refrigeration Device Mac Oui Rule 1",
          "ruleId": "YORK-Refrigeration-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Yacoub-Automation-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Yacoub Automation Device Mac Oui Rule 1",
          "ruleId": "Yacoub-Automation-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Yamatake-Honeywell-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Yamatake Honeywell Device Mac Oui Rule 1",
          "ruleId": "Yamatake-Honeywell-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Yarg-Biometrics-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Yarg Biometrics Device Mac Oui Rule 1",
          "ruleId": "Yarg-Biometrics-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Yarg Biometrics Limited"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Yarg Biometrics Limited Manufacturer Mac Oui Rule 1",
          "ruleId": "yargBiometricsLimitedManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Z-Com-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Z Com Device Mac Oui Rule",
          "ruleId": "zComDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ZIEHL-industrie-electronik-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "ZIEHL industrie electronik Device Mac Oui Rule 1",
          "ruleId": "ZIEHL-industrie-electronik-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Zebra-Device"
          ],
          "hardwareManufacturer": [
            "Zebra Technologies Corporation"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Zebra Device Mac Oui Rule",
          "ruleId": "zebraDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "dhcpClassIdentifier"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Zebra-Device"
          ],
          "hardwareManufacturer": [
            "Zebra Technologies Corporation"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Zebra Printer DHCP Rule",
          "ruleId": "zebraPrinterDhcpClassIdentifierRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Zebra-Device"
          ],
          "hardwareManufacturer": [
            "Zebra Technologies Corporation"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Zebra Printer Nmap Os Rule",
          "ruleId": "zebraPrinterNmapOsRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Zhejiang-Dahua-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Zhejiang Dahua Device Mac Oui Rule 1",
          "ruleId": "Zhejiang-Dahua-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Zhejiang-Shenghui-Lighting-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Zhejiang Shenghui Lighting Device Mac Oui Rule 1",
          "ruleId": "Zhejiang-Shenghui-Lighting-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Zhejiang-Uniview-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Zhejiang Uniview Device Mac Oui Rule 1",
          "ruleId": "Zhejiang-Uniview-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Ziehl-Abegg AG"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Ziehl Abegg Ag Manufacturer Mac Oui Rule 1",
          "ruleId": "ziehlAbeggAgManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "Ziehl-Abegg-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Ziehl Abegg Device Mac Oui Rule 1",
          "ruleId": "Ziehl-Abegg-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266339
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": null,
          "hardwareManufacturer": [
            "Zoe Medical"
          ],
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Zoe Medical Manufacturer Mac Oui Rule 1",
          "ruleId": "zoeMedicalManufacturerMacOuiRule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "ZTE-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "Zte Device Mac Oui Rule",
          "ruleId": "zteDeviceMacOuiRule",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "adp-Gauselmann-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "adp Gauselmann Device Mac Oui Rule 1",
          "ruleId": "adp-Gauselmann-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "automationNEXT-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "automationNEXT Device Mac Oui Rule 1",
          "ruleId": "automationNEXT-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266342
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "home2net-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "home2net Device Mac Oui Rule 1",
          "ruleId": "home2net-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "i-Controls-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "i Controls Device Mac Oui Rule 1",
          "ruleId": "i-Controls-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266341
        },
        {
          "deviceType": null,
          "usedAttributes": [
            "oui"
          ],
          "conditionGroups": null,
          "hardwareModel": [
            "iControl-Device"
          ],
          "hardwareManufacturer": null,
          "ruleType": "Cisco Default - Static",
          "ruleVersion": null,
          "lastModifiedBy": "System",
          "ruleName": "iControl Device Mac Oui Rule 1",
          "ruleId": "iControl-Device-Rule1",
          "operatingSystem": null,
          "lastModifiedOn": 1589339266340
        }
      ]
})