define({
  "PathTrace_Completed_Template": {
    "response": {
      "request": {
        "sourceIP": "10.30.100.18",
        "destIP": "10.30.120.10",
        "periodicRefresh": false,
        "inclusions": [
          "ACL-TRACE"
        ],
        "id": "80006230-d0d9-4811-beef-0e5c26b9d1a3",
        "status": "COMPLETED",
        "createTime": 1516251404098,
        "lastUpdateTime": 1516251404821,
        "controlPath": false
      },
      "lastUpdate": "Thu Jan 18 04:56:49 UTC 2018",
      "networkElementsInfo": [
        {
          "id": "cbd50129-c04b-4953-8549-63dd85f34843",
          "name": "AP7069.5A76.7FD4",
          "type": "WIRELESS",
          "ip": "10.30.100.18",
          "role": "ACCESS",
          "linkInformationSource": "Wireless"
        },
        {
          "id": "6bb52750-35ce-5c91-cede-0a3bdef4eb51",
          "name": "AP0081.C424.3CE2",
          "type": "Unified AP",
          "ip": "10.30.15.15",
          "role": "ACCESS",
          "linkInformationSource": "Switched",
          "tunnels": [
            "CAPWAP Tunnel"
          ]
        },
        // {
        //   "id": "e5df3334-c405-25ae-8ea1-cb153f36f660",
        //   "name": "p1.edge1-sda1.local",
        //   "type": "Switches and Hubs",
        //   "ip": "10.30.255.101",
        //   "ingressInterface": {
        //     "physicalInterface": {
        //       "id": "8acdad1c-b186-4389-a4f6-d364a7cd2686",
        //       "name": "TenGigabitEthernet1/0/9",
        //       "aclAnalysis": {
        //         "result": "NONE",
        //         "matchingAces": [

        //         ]
        //       },
        //       "usedVlan": "15"
        //     }
        //   },
        //   "egressInterface": {
        //     "physicalInterface": {
        //       "id": "44e3b8e7-4436-4ec3-b01b-17c77e1f6209",
        //       "name": "TenGigabitEthernet1/0/24",
        //       "aclAnalysis": {
        //         "result": "NONE",
        //         "matchingAces": [

        //         ]
        //       },
        //       "usedVlan": "15"
        //     }
        //   },
        //   "role": "ACCESS",
        //   "linkInformationSource": "Switched",
        //   "tunnels": [
        //     "CAPWAP Tunnel"
        //   ]
        // },
        {
          "id": "3f8b0255-b5f3-1751-b1d7-a4edeaa7b7c9",
          "name": "BLD2-FLR1-ACCESS",
          "type": "Switches and Hubs",
          "ip": "10.30.255.100",
          "ingressInterface": {
            "physicalInterface": {
              "id": "87d56e86-9d79-471c-880b-60cfa6d93454",
              "name": "TenGigabitEthernet1/0/24",
              "aclAnalysis": {
                "result": "NONE",
                "matchingAces": [

                ]
              },
              "usedVlan": "15"
            },
            "virtualInterface": [
              {
                "id": "e2372b2f-c946-4c68-be81-5f5daf252894",
                "name": "Vlan15",
                "aclAnalysis": {
                  "result": "NONE",
                  "matchingAces": [

                  ]
                },
                "usedVlan": "15"
              }
            ]
          },
          "egressInterface": {
            "physicalInterface": {
              "id": "fbff4475-4d27-42b5-bb1f-f7698e59d890",
              "name": "TenGigabitEthernet1/0/14",
              "aclAnalysis": {
                "result": "NONE",
                "matchingAces": [

                ]
              },
              "usedVlan": "NA"
            }
          },
          "role": "ACCESS",
          "linkInformationSource": "Switched",
          "tunnels": [
            "CAPWAP Tunnel"
          ]
        },
        {
          "id": "12029393-4586-ce86-d6b8-9f0da7cae6f5",
          "name": "WLC-9800",
          "type": "Wireless Controller",
          "ip": "10.30.200.3",
          "ingressInterface": {
            "physicalInterface": {
              "id": "a25fa29a-f7d6-438e-a96b-2be5351ef6b1",
              "name": "TenGigabitEthernet0/0/2",
              "aclAnalysis": {
                "result": "NONE",
                "matchingAces": [

                ]
              },
              "usedVlan": "NA"
            },
            "virtualInterface": [
              {
                "id": "fa6859e6-bcff-49cb-8528-d17bac885f20",
                "name": "corporate",
                "aclAnalysis": {
                  "result": "NONE",
                  "matchingAces": [

                  ]
                },
                "usedVlan": "100"
              }
            ]
          },
          "egressInterface": {
            "physicalInterface": {
              "id": "a25fa29a-f7d6-438e-a96b-2be5351ef6b1",
              "name": "TenGigabitEthernet0/0/2",
              "aclAnalysis": {
                "result": "NONE",
                "matchingAces": [

                ]
              },
              "usedVlan": "NA"
            }
          },
          "role": "ACCESS",
          "linkInformationSource": "Switched",
          "tunnels": [
            "CAPWAP Tunnel"
          ]
        },
        {
          "id": "3f8b0255-b5f3-1751-b1d7-a4edeaa7b7c9",
          "name": "BLD1-FLR1-DST1",
          "type": "Switches and Hubs",
          "ip": "10.30.255.100",
          "ingressInterface": {
            "physicalInterface": {
              "id": "fbff4475-4d27-42b5-bb1f-f7698e59d890",
              "name": "TenGigabitEthernet1/0/14",
              "aclAnalysis": {
                "result": "NONE",
                "matchingAces": [

                ]
              },
              "usedVlan": "NA"
            },
            "virtualInterface": [
              {
                "id": "fc54fbd2-37d3-497e-9f1b-6a963b96dff1",
                "name": "Vlan100",
                "aclAnalysis": {
                  "result": "NONE",
                  "matchingAces": [

                  ]
                },
                "usedVlan": "100"
              }
            ]
          },
          "egressInterface": {
            "physicalInterface": {
              "id": "87d56e86-9d79-471c-880b-60cfa6d93454",
              "name": "TenGigabitEthernet1/0/24",
              "aclAnalysis": {
                "result": "NONE",
                "matchingAces": [

                ]
              },
              "usedVlan": "120"
            },
            "virtualInterface": [
              {
                "id": "f50ed128-4cc0-456a-b745-aab985a61959",
                "name": "Vlan120",
                "aclAnalysis": {
                  "result": "NONE",
                  "matchingAces": [

                  ]
                },
                "usedVlan": "120"
              }
            ]
          },
          "role": "ACCESS",
          "linkInformationSource": "InterVlan Routing"
        },
        {
          "id": "e5df3334-c405-25ae-8ea1-cb153f36f660",
          "name": "p1.edge1-sda1.local",
          "type": "Switches and Hubs",
          "ip": "10.30.255.101",
          "ingressInterface": {
            "physicalInterface": {
              "id": "44e3b8e7-4436-4ec3-b01b-17c77e1f6209",
              "name": "TenGigabitEthernet1/0/24",
              "aclAnalysis": {
                "result": "NONE",
                "matchingAces": [

                ]
              },
              "usedVlan": "120"
            }
          },
          "egressInterface": {
            "physicalInterface": {
              "id": "0db9712f-c3f6-4795-83cc-6439b23d215a",
              "name": "TenGigabitEthernet1/0/1",
              "aclAnalysis": {
                "result": "NONE",
                "matchingAces": [

                ]
              },
              "usedVlan": "120"
            },
            "virtualInterface": [
              {
                "id": "b8b4e8e9-0226-4f41-bd93-3ad5b57e1baf",
                "name": "Vlan120",
                "aclAnalysis": {
                  "aclName": "120",
                  "result": "DENY",
                  "matchingAces": [
                    {
                      "ace": "40 deny ip any any",
                      "result": "DENY",
                      "matchingPorts": [
                        {
                          "protocol": "udp"
                        },
                        {
                          "protocol": "tcp"
                        },
                        {
                          "protocol": "Other IP protocols"
                        }
                      ]
                    }
                  ]
                },
                "usedVlan": "120"
              }
            ]
          },
          "role": "ACCESS",
          "linkInformationSource": "Switched"
        },
        {
          "id": "ed781cbb-ed34-4e77-9bf0-a92f75f7a932",
          "name": "C9500-eBorder.ibng.local",
          "type": "Switches and Hubs",
          "ip": "10.30.120.10",
          "role": "BORDER ROUTER",
          "linkInformationSource": "InterVlan Routing"
        }
      ],
      "detailedStatus": {
        "aclTraceCalculation": "SUCCESS"
      }
    },
    "version": "1.0"
  },
  "Pathtrace_Completed_NoDevices_Template": {
    "response": {
      "request": {
        "sourceIP": "10.30.100.18",
        "destIP": "10.30.120.10",
        "periodicRefresh": false,
        "inclusions": [
          "ACL-TRACE"
        ],
        "id": "80006230-d0d9-4811-beef-0e5c26b9d1a3",
        "status": "FAILED",
        "createTime": 1516251404098,
        "lastUpdateTime": 1516251404821,
        "failureReason": "Failed to Obtain Complete L2 path between SourceDevice and DestDevice over SVI. Please make sure CDP is enabled.",
        "controlPath": false
      },
      "lastUpdate": "Thu Jan 18 04:56:49 UTC 2018",
      "networkElementsInfo": [],
      "detailedStatus": {
        "aclTraceCalculation": "SUCCESS"
      }
    },
    "version": "1.0"

  },
  "Wired_NetworkElementsInfo": [
    {
      "id": "1dd82439-3451-4a6f-9647-493472b4a44a",
      "type": "Unified AP",
      "ip": "10.13.1.18",
      "linkInformationSource": "Switched",
      "name": "AP7069.5A76.7FD4",
      "role": "ACCESS",
    },
    {
      "id": "294d5036-aeaa-42d3-864e-baf14ebc400a",
      "name": "p1.edge1-sda1.local",
      "type": "Switches and Hubs",
      "ip": "10.13.1.2",
      "ingressInterface": {
        "physicalInterface": {
          "id": "76ee1ea7-be24-4fbf-ac5b-8eec218aaf04",
          "name": "GigabitEthernet1/0/9",
          "aclAnalysis": {
            "result": "NONE",
            "matchingAces": [

            ]
          },
          "usedVlan": "1"
        }
      },
      "egressInterface": {
        "physicalInterface": {
          "id": "f58299ec-bda7-4ce0-a2f5-81038558563c",
          "name": "GigabitEthernet1/0/1",
          "aclAnalysis": {
            "result": "NONE",
            "matchingAces": [

            ]
          },
          "usedVlan": "110"
        }
      },
      "role": "ACCESS",
      "linkInformationSource": "Switched"
    },
    {
      "id": "966b5dfb-ebd1-49fb-b029-38ddc11d3551",
      "name": "C9404R-border_cp_sda.local",
      "type": "Switches and Hubs",
      "ip": "10.13.1.1",
      "ingressInterface": {
        "physicalInterface": {
          "id": "8a783c33-879d-4f56-8a15-979443f2c7dc",
          "name": "GigabitEthernet1/1",
          "aclAnalysis": {
            "result": "NONE",
            "matchingAces": [

            ]
          },
          "usedVlan": "110"
        },
        "virtualInterface": [
          {
            "id": "2cd0b0bb-daa1-480f-af00-7d9892520a68",
            "name": "Vlan110",
            "aclAnalysis": {
              "result": "NONE",
              "matchingAces": [

              ]
            },
            "usedVlan": "110"
          }
        ]
      },
      "egressInterface": {
        "physicalInterface": {
          "id": "f9586de4-ece9-4138-8382-5c150c92898b",
          "name": "GigabitEthernet3/3",
          "aclAnalysis": {
            "result": "NONE",
            "matchingAces": [

            ]
          },
          "usedVlan": "NA"
        }
      },
      "role": "CORE",
      "linkInformationSource": "CONNECTED"
    },
    {
      "id": "a07ec173-022f-43bf-871a-44566f083603",
      "name": "BLD1-FLR1-DST1",
      "type": "Switches and Hubs",
      "ip": "10.13.6.2",
      "ingressInterface": {
        "physicalInterface": {
          "id": "70711660-2475-4c07-a549-66882e8aafbc",
          "name": "TwoGigabitEthernet1/0/1",
          "aclAnalysis": {
            "result": "NONE",
            "matchingAces": [

            ]
          },
          "usedVlan": "NA"
        }
      },
      "role": "DISTRIBUTION",
      "linkInformationSource": "InterVlan Routing"
    }
  ],
  "deviceEntityObj": {
    "entity": {
      "id": "b2bc1973-692b-430d-9661-d819b006a3ad",
      "label": "NetworkDevice",
      "timestamp": "2021-04-07T03:14:44.066+0000",
      "isDeleted": false,
      "type": "vertex",
      "properties": {
        "managementIpAddr": [
          {
            "value": "10.16.50.9"
          }
        ],
        "haStatus": [
          {
            "value": "Non-redundant"
          }
        ],
        "serialNumber": [
          {
            "value": "FOC2243U18C"
          }
        ],
        "communicationState": [
          {
            "value": "REACHABLE"
          }
        ],
        "nwDeviceName": [
          {
            "value": "C9300-Edge01.ibng.local"
          }
        ],
        "platformId": [
          {
            "value": "C9300-24U"
          }
        ],
        "nwDeviceId": [
          {
            "value": "b2bc1973-692b-430d-9661-d819b006a3ad"
          }
        ],
        "nwDeviceRole": [
          {
            "value": "ACCESS"
          }
        ],
        "nwDeviceFamily": [
          {
            "value": "Switches and Hubs"
          }
        ],
        "macAddress": [
          {
            "value": "D4:AD:71:56:C7:80"
          }
        ],
        "collectionStatus": [
          {
            "value": "SUCCESS"
          }
        ],
        "deviceSeries": [
          {
            "value": "Cisco Catalyst 9300 Series Switches"
          }
        ],
        "osType": [
          {
            "value": "IOS-XE"
          }
        ],
        "ringStatus": [
          {
            "value": false
          }
        ],
        "ip_addr_managementIpAddr": [
          {
            "value": "10.16.50.9"
          }
        ],
        "lastBootTime": [
          {
            "value": 1613843443924.0
          }
        ],
        "stackType": [
          {
            "value": "STACK"
          }
        ],
        "softwareVersion": [
          {
            "value": "17.3.2a"
          }
        ],
        "nwDeviceType": [
          {
            "value": "Cisco Catalyst 9300 Switch"
          }
        ]
      }
    },
    "metric": 8.0
  }
})