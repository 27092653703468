define({
  "runningImageList": [
    {
      "name": "cat9k_iosxe.17.09.01.SPA.bin",
      "imageUuid": "fe16966b-afab-dddd-abc1-60323249791f",
      "version": "17.9.1",
      "softwareType": null,
      "imageType": "WLC_SW",
      "isTaggedGolden": "true",
      "md5Checksum": "4a64084f8108c9645b2ba264048d7665",
      "shaCheckSum": "615f982dadbbecbddb3d48fff152b5ec6ea6697ce8534f57e13d6fa04c2028e91804eac4a2e18f48cf46b878919059f02c58c664e48b71bd751519f5c1a2c122",
      "createdTime": "2022-08-05 19:20:00.000",
      "applicationType": "UNKNOWN",
      "feature": null,
      "fileServiceId": "d15d786c-5432-2323-cc12-f2619cebe23c",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "C9800",
      "vendor": "CISCO",
      "filesize": 1018097396,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "image_size",
          "propertyValue": "1216469331"
        },
        {
          "propertyName": "image_guid",
          "propertyValue": "8C894F503ADAEE78B13C4BCDC0B834FE5A932457"
        },
        {
          "propertyName": "image_description",
          "propertyValue": "Cisco Catalyst 9800-40 Wireless Controller"
        },
        {
          "propertyName": "deviceMNEId",
          "propertyValue": "431431"
        },
        {
          "propertyName": "metadata_trans_id",
          "propertyValue": "147784482"
        },
        {
          "propertyName": "encryption_software_indicator",
          "propertyValue": "N"
        },
        {
          "propertyName": "mdf_id",
          "propertyValue": "282600534"
        },
        {
          "propertyName": "udi",
          "propertyValue": "PID: C9800-40-K9 VID: V03, SN: FCW1826L03L"
        },
        {
          "propertyName": "release_fcs_date",
          "propertyValue": "01/Aug/2022"
        }
      ],
      "isSMUApplicable": false,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": true,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": null,
      "imageImported": true,
      "applicableDevicesForImage": null,
      "ccoreverseSync": false,
      "ccorecommended": false,
      "ccolatest": true
    },{
      "name": "cat9k_iosxe.17.08.01.SPA.bin",
      "imageUuid": "fe16966b-afab-aaaa-abc1-60323249791f",
      "version": "17.8.1",
      "softwareType": null,
      "imageType": "WLC_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "4a64084f8108c9645b2ba264048d7665",
      "shaCheckSum": "615f982dadbbecbddb3d48fff152b5ec6ea6697ce8534f57e13d6fa04c2028e91804eac4a2e18f48cf46b878919059f02c58c664e48b71bd751519f5c1a2c122",
      "createdTime": "2022-08-05 19:20:00.000",
      "applicationType": "UNKNOWN",
      "feature": null,
      "fileServiceId": "d15d786c-5432-5555-cc12-f2619cebe23c",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "C9800",
      "vendor": "CISCO",
      "filesize": 1018097396,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "image_size",
          "propertyValue": "1216469331"
        },
        {
          "propertyName": "image_guid",
          "propertyValue": "8C894F503ADAEE78B13C4BCDC0B834FE5A932457"
        },
        {
          "propertyName": "image_description",
          "propertyValue": "Cisco Catalyst 9800-40 Wireless Controller"
        },
        {
          "propertyName": "deviceMNEId",
          "propertyValue": "431431"
        },
        {
          "propertyName": "metadata_trans_id",
          "propertyValue": "147784482"
        },
        {
          "propertyName": "encryption_software_indicator",
          "propertyValue": "N"
        },
        {
          "propertyName": "mdf_id",
          "propertyValue": "282600534"
        },
        {
          "propertyName": "udi",
          "propertyValue": "PID: C9800-40-K9 VID: V03, SN: FCW1826L03L"
        },
        {
          "propertyName": "release_fcs_date",
          "propertyValue": "05/Aug/2022"
        }
      ],
      "isSMUApplicable": false,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": null,
      "imageImported": true,
      "applicableDevicesForImage": null,
      "ccoreverseSync": false,
      "ccorecommended": false,
      "ccolatest": true
    },{
      "name": "cat9k_iosxe.17.06.01.SPA.bin",
      "imageUuid": "fe16966b-afab-aaaa-abc1-59223249791f",
      "version": "17.6.1",
      "softwareType": null,
      "imageType": "WLC_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "4a64084f8108c9645b2ba264048d7665",
      "shaCheckSum": "504f982dadbbecbddb3d48fff152b5ec6ea6697ce8534f57e13d6fa04c2028e91804eac4a2e18f48cf46b878919059f02c58c664e48b71bd751519f5c1a2c122",
      "createdTime": "2021-09-14 19:20:00.000",
      "applicationType": "UNKNOWN",
      "feature": null,
      "fileServiceId": "d15d786c-4321-5555-cc12-f2619cebe23c",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "C9800",
      "vendor": "CISCO",
      "filesize": 1018097396,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "image_size",
          "propertyValue": "1216469331"
        },
        {
          "propertyName": "image_guid",
          "propertyValue": "8C894F503ADAEE78B13C4BCDC0B834FE5A932457"
        },
        {
          "propertyName": "image_description",
          "propertyValue": "Cisco Catalyst 9800-40 Wireless Controller"
        },
        {
          "propertyName": "deviceMNEId",
          "propertyValue": "431431"
        },
        {
          "propertyName": "metadata_trans_id",
          "propertyValue": "147784482"
        },
        {
          "propertyName": "encryption_software_indicator",
          "propertyValue": "N"
        },
        {
          "propertyName": "mdf_id",
          "propertyValue": "282600534"
        },
        {
          "propertyName": "udi",
          "propertyValue": "PID: C9800-40-K9 VID: V03, SN: FCW1826L03L"
        },
        {
          "propertyName": "release_fcs_date",
          "propertyValue": "05/Mar/2021"
        }
      ],
      "isSMUApplicable": false,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": null,
      "imageImported": true,
      "applicableDevicesForImage": null,
      "ccoreverseSync": false,
      "ccorecommended": false,
      "ccolatest": true
    },
    {
      "name": "C9800-40-universalk9_wlc.17.03.03.SPA.bin",
      "imageUuid": "fe16966b-abab-aaaa-abc1-59223249791f",
      "version": "17.3.3",
      "softwareType": null,
      "imageType": "WLC_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "cb26871c61378b1a1840fdf367f25ce2",
      "shaCheckSum": "b73386bfb01dcc7971bf8c7ce08069b107794e69e409ac0ae58097913df37fb4da71cd9c143657e6bb7491e2a1f57b76e19f9c3141cacbca224e232253d644a8-",
      "createdTime": "2021-03-15 19:20:00.000",
      "applicationType": "UNKNOWN",
      "feature": null,
      "fileServiceId": "d15d786c-4321-5555-cc12-f2619cebe23c",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "C9800",
      "vendor": "CISCO",
      "filesize": 1216469331,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "image_size",
          "propertyValue": "1216469331"
        },
        {
          "propertyName": "image_guid",
          "propertyValue": "8C894F503ADAEE78B13C4BCDC0B834FE5A932457"
        },
        {
          "propertyName": "image_description",
          "propertyValue": "Cisco Catalyst 9800-40 Wireless Controller"
        },
        {
          "propertyName": "deviceMNEId",
          "propertyValue": "431431"
        },
        {
          "propertyName": "metadata_trans_id",
          "propertyValue": "147784482"
        },
        {
          "propertyName": "encryption_software_indicator",
          "propertyValue": "N"
        },
        {
          "propertyName": "mdf_id",
          "propertyValue": "282600534"
        },
        {
          "propertyName": "udi",
          "propertyValue": "PID: C9800-40-K9 VID: V03, SN: FCW1826L03L"
        },
        {
          "propertyName": "release_fcs_date",
          "propertyValue": "05/Mar/2021"
        }
      ],
      "isSMUApplicable": false,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": null,
      "imageImported": true,
      "applicableDevicesForImage": null,
      "ccoreverseSync": false,
      "ccorecommended": false,
      "ccolatest": true
    },
    {
      "name": "C9800-40-universalk9_wlc.17.03.01.SPA.bin",
      "imageUuid": "ab16966b-abab-aaaa-abc1-59223249791f",
      "version": "17.3.1",//Amsterdam-17.3.1
      "softwareType": null,
      "imageType": "WLC_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "cb26871c61378b1a1840fdf367f25ce2",
      "shaCheckSum": "b73386bfb01dcc7971bf8c7ce08069b107794e69e409ac0ae58097913df37fb4da71cd9c143657e6bb7491e2a1f57b76e19f9c3141cacbca224e232253d644a8-",
      "createdTime": "2020-10-16 14:39:00.000",
      "applicationType": "UNKNOWN",
      "feature": null,
      "fileServiceId": "d15d786c-4321-2346-cc12-f2619cebe23c",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "C9800",
      "vendor": "CISCO",
      "filesize": 1217304482,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "image_size",
          "propertyValue": "1217304482"
        },
        {
          "propertyName": "image_guid",
          "propertyValue": "8C894F503ADAEE78B13C4BCDC0B834FE5A932457"
        },
        {
          "propertyName": "image_description",
          "propertyValue": "Cisco Catalyst 9800-40 Wireless Controller"
        },
        {
          "propertyName": "deviceMNEId",
          "propertyValue": "431431"
        },
        {
          "propertyName": "metadata_trans_id",
          "propertyValue": "147784482"
        },
        {
          "propertyName": "encryption_software_indicator",
          "propertyValue": "N"
        },
        {
          "propertyName": "mdf_id",
          "propertyValue": "282600534"
        },
        {
          "propertyName": "udi",
          "propertyValue": "PID: C9800-40-K9 VID: V03, SN: FCW1826L03L"
        },
        {
          "propertyName": "release_fcs_date",
          "propertyValue": "09/Aug/2020"
        }
      ],
      "isSMUApplicable": false,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": null,
      "imageImported": true,
      "applicableDevicesForImage": null,
      "ccoreverseSync": false,
      "ccorecommended": false,
      "ccolatest": true
    },
    {
      "name": "C9800-40-universalk9_wlc.17.02.01.SPA.bin",
      "imageUuid": "ab16966b-4049-aaaa-abc1-59223249791f",
      "version": "17.2.1",//Amsterdam-17.2.1
      "softwareType": null,
      "imageType": "WLC_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "7b064ae5de64ee05b2963de74d18ce5d",
      "shaCheckSum": "e7bf3acc0e849295a0bce520e727f99b2cde0ba544ec0eed757f831f6f3355fb3ed65efbb697fbb01d67d4bf93fc80c4d57bb71766c1569ac5cf9ee3215a1bbe-",
      "createdTime": "2020-04-28 21:36:00.000",
      "applicationType": "UNKNOWN",
      "feature": null,
      "fileServiceId": "d15d786c-123a-2346-cc12-f2619cebe23c",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "C9800",
      "vendor": "CISCO",
      "filesize": 1155335584,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "image_size",
          "propertyValue": "1030467910 "
        },
        {
          "propertyName": "image_guid",
          "propertyValue": "8C88DF503ADAEE78B13C4BCDC0B834FE5A932457"
        },
        {
          "propertyName": "image_description",
          "propertyValue": "Cisco Catalyst 9800-40 Wireless Controller"
        },
        {
          "propertyName": "deviceMNEId",
          "propertyValue": "431431"
        },
        {
          "propertyName": "metadata_trans_id",
          "propertyValue": "147784482"
        },
        {
          "propertyName": "encryption_software_indicator",
          "propertyValue": "N"
        },
        {
          "propertyName": "mdf_id",
          "propertyValue": "282600534"
        },
        {
          "propertyName": "udi",
          "propertyValue": "PID: C9800-40-K9 VID: V03, SN: FCW1826L03L"
        },
        {
          "propertyName": "release_fcs_date",
          "propertyValue": "31/Mar/2020"
        }
      ],
      "isSMUApplicable": false,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": null,
      "imageImported": true,
      "applicableDevicesForImage": null,
      "ccoreverseSync": false,
      "ccorecommended": true,
      "ccolatest": true
    },
    {
      "name": "C9800-40-universalk9_wlc.17.01.01s.SPA.bin",
      "imageUuid": "ab16966b-cccc-9999-abc1-59223249791f",
      "version": "17.1.1s",//Amsterdam-17.1.1s
      "softwareType": null,
      "imageType": "WLC_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "7b064ae5de64ee05b2963de74d18ce5d",
      "shaCheckSum": "e7bf3acc0e849295a0bce520e727f99b2cde0ba544ec0eed757f831f6f3355fb3ed65efbb697fbb01d67d4bf93fc80c4d57bb71766c1569ac5cf9ee3215a1bbe-",
      "createdTime": "2020-04-28 21:39:00.000",
      "applicationType": "UNKNOWN",
      "feature": null,
      "fileServiceId": "d15d786c-123a-2346-cc12-f2619cebe23c",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "C9800",
      "vendor": "CISCO",
      "filesize": 1030467910,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "image_size",
          "propertyValue": "1030467910 "
        },
        {
          "propertyName": "image_guid",
          "propertyValue": "8C88DF503ADAEE78B13C4BCDC0B834FE5A932457"
        },
        {
          "propertyName": "image_description",
          "propertyValue": "Cisco Catalyst 9800-40 Wireless Controller"
        },
        {
          "propertyName": "deviceMNEId",
          "propertyValue": "431431"
        },
        {
          "propertyName": "metadata_trans_id",
          "propertyValue": "147784482"
        },
        {
          "propertyName": "encryption_software_indicator",
          "propertyValue": "N"
        },
        {
          "propertyName": "mdf_id",
          "propertyValue": "282600534"
        },
        {
          "propertyName": "udi",
          "propertyValue": "PID: C9800-40-K9 VID: V03, SN: FCW1826L03L"
        },
        {
          "propertyName": "release_fcs_date",
          "propertyValue": "20/Feb/2020"
        }
      ],
      "isSMUApplicable": false,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": null,
      "imageImported": true,
      "applicableDevicesForImage": null,
      "ccoreverseSync": false,
      "ccorecommended": true,
      "ccolatest": true
    },
    {
      "name": "C9800-40-universalk9_wlc.16.12.02t.SPA.bin",
      "imageUuid": "ab16966b-4049-9999-abc1-59223249791f",
      "version": "16.12.2t",//	Gibraltar-16.12.2t
      "softwareType": null,
      "imageType": "WLC_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "7b064ae5de64ee05b2963de74d18ce5d",
      "shaCheckSum": "e7bf3acc0e849295a0bce520e727f99b2cde0ba544ec0eed757f831f6f3355fb3ed65efbb697fbb01d67d4bf93fc80c4d57bb71766c1569ac5cf9ee3215a1bbe-",
      "createdTime": "2020-02-12 12:17:00.000",
      "applicationType": "UNKNOWN",
      "feature": null,
      "fileServiceId": "d15d786c-123a-2346-cc12-f2619cebe23c",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "C9800",
      "vendor": "CISCO",
      "filesize": 1030467910,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "image_size",
          "propertyValue": "1030467910 "
        },
        {
          "propertyName": "image_guid",
          "propertyValue": "8C88DF503ADAEE78B13C4BCDC0B834FE5A932457"
        },
        {
          "propertyName": "image_description",
          "propertyValue": "Cisco Catalyst 9800-40 Wireless Controller"
        },
        {
          "propertyName": "deviceMNEId",
          "propertyValue": "431431"
        },
        {
          "propertyName": "metadata_trans_id",
          "propertyValue": "147784482"
        },
        {
          "propertyName": "encryption_software_indicator",
          "propertyValue": "N"
        },
        {
          "propertyName": "mdf_id",
          "propertyValue": "282600534"
        },
        {
          "propertyName": "udi",
          "propertyValue": "PID: C9800-40-K9 VID: V03, SN: FCW1826L03L"
        },
        {
          "propertyName": "release_fcs_date",
          "propertyValue": "12/Feb/2020"
        }
      ],
      "isSMUApplicable": false,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": null,
      "imageImported": true,
      "applicableDevicesForImage": null,
      "ccoreverseSync": false,
      "ccorecommended": true,
      "ccolatest": true
    },
    {
      "name": "C9800-40-universalk9_wlc.16.12.01s.SPA.bin",
      "imageUuid": "ab16966b-4049-9999-3ac7-59223249791f",
      "version": "16.12.1s",//Gibraltar-16.12.1
      "softwareType": null,
      "imageType": "WLC_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "8c0eab24f18e58816044e884ef66991d",
      "shaCheckSum": "d2dc3207cd32fabf33f0eb95767039a083c2eb53c3f3463398cd586c9c0b5376cd4df572423a072d8acf568a981a449074bfebce79508fe00aaaf243df8d4008",
      "createdTime": "2019-09-04 12:17:00.000",
      "applicationType": "UNKNOWN",
      "feature": null,
      "fileServiceId": "d15d786c-123a-2346-cc12-f2619cebe23c",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "C9800",
      "vendor": "CISCO",
      "filesize": 959021256,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "image_size",
          "propertyValue": "959021256"
        },
        {
          "propertyName": "image_guid",
          "propertyValue": "8C88DF503ADAEE78B13C4BCDC0B834FE5A932457"
        },
        {
          "propertyName": "image_description",
          "propertyValue": "Cisco Catalyst 9800-40 Wireless Controller"
        },
        {
          "propertyName": "deviceMNEId",
          "propertyValue": "431431"
        },
        {
          "propertyName": "metadata_trans_id",
          "propertyValue": "147784482"
        },
        {
          "propertyName": "encryption_software_indicator",
          "propertyValue": "N"
        },
        {
          "propertyName": "mdf_id",
          "propertyValue": "282600534"
        },
        {
          "propertyName": "udi",
          "propertyValue": "PID: C9800-40-K9 VID: V03, SN: FCW1826L03L"
        },
        {
          "propertyName": "release_fcs_date",
          "propertyValue": "01/Aug/2019"
        }
      ],
      "isSMUApplicable": false,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": null,
      "imageImported": true,
      "applicableDevicesForImage": null,
      "ccoreverseSync": false,
      "ccorecommended": true,
      "ccolatest": true
    }
  ]
});