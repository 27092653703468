define({
  "Template":
    {
  "issueId": "AWQM1Gn8xNnJ13sZKwKw",
  "location": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
  "SensorIssueDetails": [
    {
      "sensorName": "AP1800S_Sensor_01",
      "macAddress": "58:ac:78:df:bd:20",
      "url": "",
      "apName": "AP00EB.D510.3F18",
      "apMacAddress": "70:df:2f:a7:ab:af",
      "testSuiteId": "Sensor_Test_01",
      "band": "5 GHz"
    },
    {
      "sensorName": "AP1800S_Sensor_03",
      "macAddress": "00:42:5a:0a:22:30",
      "url": "http://www.sustainablesites.org/",
	  "apName": "AP4800",
      "apMacAddress": "58:ac:78:df:bd:2f",
      "testSuiteId": "Sensor_Test_02",
      "band": "5 GHz"
    },
    {
      "sensorName": "AP1800S_Sensor_04",
      "macAddress": "70:df:2f:9b:f9:80",
      "url": "",
	  "apName": "AP4800_1",
      "apMacAddress": "58:ac:78:df:bd:7f",
      "testSuiteId": "Sensor_Test_01",
      "band": "5 GHz"
    },
    {
      "sensorName": "AP1800S_Sensor_02",
      "macAddress": "00:42:5a:0a:22:30",
      "url": "",
	  "apName": "AP9120_2",
      "apMacAddress": "58:ac:78:df:bd:2f",
      "testSuiteId": "Sensor_Test_04",
      "band": "5 GHz"
    },
    {
      "sensorName": "AP1800S_Sensor_02",
      "macAddress": "00:42:5a:0a:22:30",
      "url": "",
	  "apName": "SJC01_9136_12",
      "apMacAddress": "58:ac:78:df:b6:3f",
      "testSuiteId": "Sensor_Test_03",
      "band": "5 GHz"
    },
    {
      "sensorName": "AP1800S_Sensor_01",
      "macAddress": "00:42:5a:0a:22:30",
      "url": "",
	  "apName": "AP4800",
      "apMacAddress": "70:df:2f:9b:f9:8f",
      "testSuiteId": "Sensor_Test_01",
      "band": "5 GHz"
    },
    {
      "sensorName": "AP1800S_Sensor_04",
      "macAddress": "00:42:5a:0a:22:30",
      "url": "",
	  "apName": "AP4800",
      "apMacAddress": "58:ac:78:df:bd:7f",
      "testSuiteId": "Sensor_Test_02",
      "band": "5 GHz"
    },
    {
      "sensorName": "AP1800S_Sensor_01",
      "macAddress": "0c:75:bd:05:10:20",
      "url": "http://www.sustainablesites.org/",
	  "apName": "AP4800_1",
      "apMacAddress": "0c:75:bd:05:12:af",
      "testSuiteId": "Sensor_Test_03",
      "band": "5 GHz"
    },
    {
      "sensorName": "AP1800S_Sensor_03",
      "macAddress": "0c:75:bd:05:10:20",
      "url": "",
	  "apName": "AP00EB.D510.3F18",
      "apMacAddress": "0c:75:bd:05:12:af",
      "testSuiteId": "Sensor_Test_04",
      "band": "5 GHz"
    },
    {
      "sensorName": "AP1800S_Sensor_02",
      "macAddress": "58:ac:78:df:bd:20",
      "url": "http://www.sustainablesites.org/",
	  "apName": "AP9120_2",
      "apMacAddress": "70:df:2f:a7:ab:af",
      "testSuiteId": "Sensor_Test_01",
      "band": "5 GHz"
    },
    {
	  "sensorName": "AP1800S_Sensor_04",
      "macAddress": "00:42:5a:0a:22:30",
      "url": "http://www.sustainablesites.org/",
	  "apName": "AP9120_3",
      "apMacAddress": "58:ac:78:df:b6:3f",
      "testSuiteId": "Sensor_Test_01",
      "band": "5 GHz"
    },
    {
	  "sensorName": "AP1800S_Sensor_01",
      "macAddress": "00:42:5a:0a:22:30",
      "url": "http://www.sustainablesites.org/",
	  "apName": "AP4800_1",
      "apMacAddress": "70:df:2f:9b:f9:8f",
      "testSuiteId": "Sensor_Test_01",
      "band": "5 GHz"
    },
    {
	  "sensorName": "AP1800S_Sensor_02",
      "macAddress": "00:42:5a:0a:22:30",
      "url": "http://www.sustainablesites.org/",
	  "apName": "AP4800",
      "apMacAddress": "58:ac:78:df:bd:7f",
      "testSuiteId": "Sensor_Test_02",
      "band": "5 GHz"
    },
    {
	  "sensorName": "AP1800S_Sensor_01",
      "macAddress": "70:df:2f:9b:f9:80",
      "url": "http://https://google.com",
	  "apName": "AP9120_3",
      "apMacAddress": "58:ac:78:df:b7:e0",
      "testSuiteId": "Sensor_Test_03",
      "band": "5 GHz"
    },
    {
	  "sensorName": "AP1800S_Sensor_04",
      "macAddress": "70:df:2f:9b:f9:80",
      "url": "http://https://google.com",
	  "apName": "AP4800",
      "apMacAddress": "58:ac:78:df:bd:7f",
      "testSuiteId": "Sensor_Test_04",
      "band": "5 GHz"
    },
    {
	  "sensorName": "AP1800S_Sensor_03",
      "macAddress": "70:df:2f:9b:f9:80",
      "url": "",
	  "apName": "AP4800",
      "apMacAddress": "58:ac:78:df:b7:e0",
      "testSuiteId": "Sensor_Test_01",
      "band": "5 GHz"
    },
    {
	  "sensorName": "AP1800S_Sensor_01",
      "macAddress": "0c:75:bd:05:10:20",
      "url": "http://www.sustainablesites.org/",
	  "apName": "AP4800",
      "apMacAddress": "00:78:88:e9:f3:2f",
      "testSuiteId": "Sensor_Test_03",
      "band": "5 GHz"
    },
    {
	  "sensorName": "AP1800S_Sensor_03",
      "macAddress": "0c:75:bd:05:10:20",
      "url": "",
	  "apName": "AP9120_2",
      "apMacAddress": "00:78:88:e9:f3:2f",
      "testSuiteId": "Sensor_Test_01",
      "band": "5 GHz"
    },
    {
      "sensorName": "AP1800S_Sensor_04",
      "macAddress": "0c:75:bd:05:10:20",
      "url": "http://www.sustainablesites.org/",
	  "apName": "AP9120_3",
      "apMacAddress": "0c:75:bd:05:1e:4f",
      "testSuiteId": "Sensor_Test_03",
      "band": "5 GHz"
    },
    {
      "sensorName": "AP1800S_Sensor_02",
      "macAddress": "0c:75:bd:05:10:20",
      "url": "",
	  "apName": "AP4800_1",
      "apMacAddress": "0c:75:bd:05:1e:4f",
      "testSuiteId": "Sensor_Test_04",
      "band": "5 GHz"
    }
  ]
}
})
