define({
    "ClientSummaryWlccData":
    {
        "version": null,
        "data": [
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682230200000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "firstSeenTimestamp": 1682230200000
                        }
                    ],
                    "__timestamp": 1682230200000
                },
                "time": 1682230200000
            }
        ],
        "page": null,
        "scope": null,
        "instrumentation": null,
        "message": null
    },
    "ClientSummaryEntityTypeData":
    {
        "version": null,
        "data": [
            {
                "entityType": "site",
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3"
                            },
                            "entityId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/"
                        },
                        {
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1"
                            },
                            "entityId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/"
                        },
                        {
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/4f6b56db-2bda-4b34-ab55-51a9d50de1bf/",
                                "siteHierarchy": "Global/San Jose/Building 18/Floor4"
                            },
                            "entityId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/4f6b56db-2bda-4b34-ab55-51a9d50de1bf/"
                        }
                    ]
                }
            }
        ],
        "page": null,
        "scope": null,
        "instrumentation": null,
        "message": null
    },
    "ClientAnalyticsSummaryData":
    {
        "version": "v2",
        "data": [
            {
                "dataType": "summary",
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "attemptCount": 26
                                                    },
                                                    "resultCategory": "fast"
                                                },
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 23
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 23
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 49
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 12
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 12
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 12
                                        },
                                        "resultType": "incomplete"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Auth Key Exchange Timeout",
                                                                    "attemptCount": 3
                                                                },
                                                                "reasonCode": "1326"
                                                            },
                                                            {
                                                                "values": {
                                                                    "failureReason": "Client Connect Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1120"
                                                            }
                                                        ],
                                                        "attemptCount": 4
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 4
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "attemptCount": 65
                            },
                            "category": "roaming"
                        },
                        {
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 66
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 19
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 19
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 19
                                        },
                                        "resultType": "incomplete"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Auth Key Exchange Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1326"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "attemptCount": 86
                            },
                            "category": "onboarding"
                        },
                        {
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "aggregatePercentage": 100,
                                            "aggregateValue": 766.15
                                        },
                                        "resultType": "dataRate"
                                    },
                                    {
                                        "values": {
                                            "aggregatePercentage": 8.9,
                                            "aggregateValue": 236436
                                        },
                                        "resultType": "rxRetries"
                                    },
                                    {
                                        "values": {
                                            "aggregatePercentage": 100,
                                            "aggregateValue": -45.93
                                        },
                                        "resultType": "rssi"
                                    },
                                    {
                                        "values": {
                                            "aggregatePercentage": 100,
                                            "aggregateValue": 43.23
                                        },
                                        "resultType": "snr"
                                    }
                                ]
                            },
                            "category": "connectivity"
                        }
                    ]
                }
            },
            {
                "dataType": "insight",
                "values": {
                    "nestedData": [
                        {
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [],
                                                        "insightMessage": [
                                                            "Onboarding failed during Authentication (1 out of 1), due to 'Auth Key Exchange Timeout' (1)."
                                                        ],
                                                        "reasonCode": "1326"
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ]
                                        },
                                        "resultType": "fail"
                                    }
                                ]
                            },
                            "category": "onboarding"
                        },
                        {
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [],
                                                        "insightMessage": [
                                                            "Roaming failed during Authentication (4 out of 4), mostly due to 'Auth Key Exchange Timeout' (3)."
                                                        ],
                                                        "reasonCode": "1326"
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ]
                                        },
                                        "resultType": "fail"
                                    }
                                ]
                            },
                            "category": "roaming"
                        },
                        {
                            "values": {
                                "nestedData": [],
                                "insightMessage": [
                                    ""
                                ]
                            },
                            "category": "connectivity"
                        }
                    ]
                }
            }
        ],
        "page": null,
        "scope": null,
        "instrumentation": null,
        "message": null
    },
    "IncludeSiteTrendData":{
        "version": "v2",
        "data": [ 
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Auth Key Exchange Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1326"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    },

                                ],
                                "siteHierarchy": "Global/North America/USA/California/San Jose/SJC22/Flr-SJC22-1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682686500000
                },
                "time": 1682686500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/North America/USA/California/San Jose/SJC22/Flr-SJC22-1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682478660000
                },
                "time": 1682478660000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Client Connect Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1120"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/North America/USA/California/San Jose/SJC22/Flr-SJC22-1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682478720000
                },
                "time": 1682478720000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/North America/USA/California/San Jose/SJC22/Flr-SJC22-1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682478660000
                },
                "time": 1682478660000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Client Connect Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1120"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
                                "attemptCount": 4
                            }
                        }
                    ],
                    "__timestamp": 1682478600000
                },
                "time": 1682478600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682478240000
                },
                "time": 1682478240000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682478000000
                },
                "time": 1682478000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682477940000
                },
                "time": 1682477940000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 3
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                },
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Auth Key Exchange Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1326"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 2
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 5
                            }
                        }
                    ],
                    "__timestamp": 1682477700000
                },
                "time": 1682477700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682477580000
                },
                "time": 1682477580000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682477460000
                },
                "time": 1682477460000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Auth Key Exchange Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1326"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682477220000
                },
                "time": 1682477220000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682477160000
                },
                "time": 1682477160000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682477040000
                },
                "time": 1682477040000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682476800000
                },
                "time": 1682476800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682476260000
                },
                "time": 1682476260000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682475900000
                },
                "time": 1682475900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682475780000
                },
                "time": 1682475780000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 4
                            }
                        }
                    ],
                    "__timestamp": 1682475600000
                },
                "time": 1682475600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682475420000
                },
                "time": 1682475420000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682475240000
                },
                "time": 1682475240000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682475060000
                },
                "time": 1682475060000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 3
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Client Connect Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1120"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 4
                            }
                        }
                    ],
                    "__timestamp": 1682475000000
                },
                "time": 1682475000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682474880000
                },
                "time": 1682474880000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682474700000
                },
                "time": 1682474700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682474520000
                },
                "time": 1682474520000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682474400000
                },
                "time": 1682474400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682474340000
                },
                "time": 1682474340000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682474100000
                },
                "time": 1682474100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Client Connect Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1120"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682473800000
                },
                "time": 1682473800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682473620000
                },
                "time": 1682473620000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682473560000
                },
                "time": 1682473560000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682473440000
                },
                "time": 1682473440000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682473260000
                },
                "time": 1682473260000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682473200000
                },
                "time": 1682473200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682472900000
                },
                "time": 1682472900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Auth Key Exchange Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1326"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 2
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 2
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 2
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 4
                            }
                        }
                    ],
                    "__timestamp": 1682472720000
                },
                "time": 1682472720000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682472660000
                },
                "time": 1682472660000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Auth Key Exchange Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1326"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682472600000
                },
                "time": 1682472600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682472540000
                },
                "time": 1682472540000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682472420000
                },
                "time": 1682472420000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                },
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Client Connect Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1120"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 2
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 4
                            }
                        }
                    ],
                    "__timestamp": 1682472300000
                },
                "time": 1682472300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682472180000
                },
                "time": 1682472180000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682472000000
                },
                "time": 1682472000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682471820000
                },
                "time": 1682471820000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682471640000
                },
                "time": 1682471640000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682471580000
                },
                "time": 1682471580000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682471520000
                },
                "time": 1682471520000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682471040000
                },
                "time": 1682471040000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682470500000
                },
                "time": 1682470500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682470200000
                },
                "time": 1682470200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682469900000
                },
                "time": 1682469900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682469720000
                },
                "time": 1682469720000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 2
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 2
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 2
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "attemptCount": 2
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 2
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 2
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 2
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682469600000
                },
                "time": 1682469600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682469480000
                },
                "time": 1682469480000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Client Connect Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1120"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682469300000
                },
                "time": 1682469300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682469240000
                },
                "time": 1682469240000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682469120000
                },
                "time": 1682469120000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 3
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Auth Key Exchange Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1326"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 4
                            }
                        }
                    ],
                    "__timestamp": 1682469060000
                },
                "time": 1682469060000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682468700000
                },
                "time": 1682468700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682468400000
                },
                "time": 1682468400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682468100000
                },
                "time": 1682468100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Client Connect Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1120"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682467920000
                },
                "time": 1682467920000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682467740000
                },
                "time": 1682467740000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682467680000
                },
                "time": 1682467680000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682467620000
                },
                "time": 1682467620000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682467440000
                },
                "time": 1682467440000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682467380000
                },
                "time": 1682467380000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682466900000
                },
                "time": 1682466900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682466780000
                },
                "time": 1682466780000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682466600000
                },
                "time": 1682466600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682466300000
                },
                "time": 1682466300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682465880000
                },
                "time": 1682465880000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682465640000
                },
                "time": 1682465640000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682465100000
                },
                "time": 1682465100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682464560000
                },
                "time": 1682464560000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682464500000
                },
                "time": 1682464500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682464320000
                },
                "time": 1682464320000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682464200000
                },
                "time": 1682464200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682464080000
                },
                "time": 1682464080000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682463840000
                },
                "time": 1682463840000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682463780000
                },
                "time": 1682463780000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682463720000
                },
                "time": 1682463720000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682463300000
                },
                "time": 1682463300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Auth Key Exchange Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1326"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682462760000
                },
                "time": 1682462760000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 4
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 4
                            }
                        }
                    ],
                    "__timestamp": 1682462700000
                },
                "time": 1682462700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682462640000
                },
                "time": 1682462640000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682462580000
                },
                "time": 1682462580000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Auth Key Exchange Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1326"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682462520000
                },
                "time": 1682462520000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682462400000
                },
                "time": 1682462400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682462040000
                },
                "time": 1682462040000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682461980000
                },
                "time": 1682461980000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 3
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682461920000
                },
                "time": 1682461920000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Client Connect Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1120"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682461800000
                },
                "time": 1682461800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682461500000
                },
                "time": 1682461500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682460960000
                },
                "time": 1682460960000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682460900000
                },
                "time": 1682460900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682460600000
                },
                "time": 1682460600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682460240000
                },
                "time": 1682460240000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Client Connect Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1120"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682460180000
                },
                "time": 1682460180000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682460120000
                },
                "time": 1682460120000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682459940000
                },
                "time": 1682459940000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682459760000
                },
                "time": 1682459760000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682459700000
                },
                "time": 1682459700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682459400000
                },
                "time": 1682459400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682459340000
                },
                "time": 1682459340000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682459220000
                },
                "time": 1682459220000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682458380000
                },
                "time": 1682458380000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682458200000
                },
                "time": 1682458200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682458140000
                },
                "time": 1682458140000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682457900000
                },
                "time": 1682457900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682457540000
                },
                "time": 1682457540000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682457420000
                },
                "time": 1682457420000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682457360000
                },
                "time": 1682457360000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 4
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 4
                            }
                        }
                    ],
                    "__timestamp": 1682457300000
                },
                "time": 1682457300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682457120000
                },
                "time": 1682457120000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682457000000
                },
                "time": 1682457000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682456520000
                },
                "time": 1682456520000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682456400000
                },
                "time": 1682456400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682456100000
                },
                "time": 1682456100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682455800000
                },
                "time": 1682455800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682455740000
                },
                "time": 1682455740000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682455620000
                },
                "time": 1682455620000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Client Connect Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1120"
                                                            },
                                                            {
                                                                "values": {
                                                                    "failureReason": "Auth Key Exchange Timeout",
                                                                    "attemptCount": 3
                                                                },
                                                                "reasonCode": "1326"
                                                            }
                                                        ],
                                                        "attemptCount": 4
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 4
                                        },
                                        "resultType": "fail"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 6
                            }
                        }
                    ],
                    "__timestamp": 1682455560000
                },
                "time": 1682455560000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682455500000
                },
                "time": 1682455500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682455440000
                },
                "time": 1682455440000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682455260000
                },
                "time": 1682455260000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682455200000
                },
                "time": 1682455200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Client Connect Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1120"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682454840000
                },
                "time": 1682454840000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682454780000
                },
                "time": 1682454780000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682454600000
                },
                "time": 1682454600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682454540000
                },
                "time": 1682454540000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682454300000
                },
                "time": 1682454300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 2
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 2
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 2
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "attemptCount": 3
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 2
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 2
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 2
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682454000000
                },
                "time": 1682454000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Auth Key Exchange Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1326"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682453820000
                },
                "time": 1682453820000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682453700000
                },
                "time": 1682453700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682452800000
                },
                "time": 1682452800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682452740000
                },
                "time": 1682452740000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682452620000
                },
                "time": 1682452620000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682452500000
                },
                "time": 1682452500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682452200000
                },
                "time": 1682452200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682452020000
                },
                "time": 1682452020000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682451900000
                },
                "time": 1682451900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682451660000
                },
                "time": 1682451660000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 3
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 4
                            }
                        }
                    ],
                    "__timestamp": 1682451300000
                },
                "time": 1682451300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Client Connect Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1120"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682451180000
                },
                "time": 1682451180000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682451120000
                },
                "time": 1682451120000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682450940000
                },
                "time": 1682450940000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682450700000
                },
                "time": 1682450700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 3
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682450400000
                },
                "time": 1682450400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682450280000
                },
                "time": 1682450280000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682450220000
                },
                "time": 1682450220000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682450100000
                },
                "time": 1682450100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682449860000
                },
                "time": 1682449860000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682449500000
                },
                "time": 1682449500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Client Connect Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1120"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 2
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 2
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 2
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 4
                            }
                        }
                    ],
                    "__timestamp": 1682449320000
                },
                "time": 1682449320000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682449140000
                },
                "time": 1682449140000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 3
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 4
                            }
                        }
                    ],
                    "__timestamp": 1682448900000
                },
                "time": 1682448900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682448600000
                },
                "time": 1682448600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682448540000
                },
                "time": 1682448540000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682448480000
                },
                "time": 1682448480000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Client Connect Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1120"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682448420000
                },
                "time": 1682448420000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682448300000
                },
                "time": 1682448300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Client Connect Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1120"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682448240000
                },
                "time": 1682448240000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682448180000
                },
                "time": 1682448180000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682448120000
                },
                "time": 1682448120000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682447940000
                },
                "time": 1682447940000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682447880000
                },
                "time": 1682447880000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Association Failure",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1123"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "assoc"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682447700000
                },
                "time": 1682447700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682447400000
                },
                "time": 1682447400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682447100000
                },
                "time": 1682447100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682446800000
                },
                "time": 1682446800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 3
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682446500000
                },
                "time": 1682446500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682446440000
                },
                "time": 1682446440000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682446320000
                },
                "time": 1682446320000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682445720000
                },
                "time": 1682445720000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682445300000
                },
                "time": 1682445300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Client Connect Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1120"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682445000000
                },
                "time": 1682445000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Auth Key Exchange Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1326"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682444760000
                },
                "time": 1682444760000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682444700000
                },
                "time": 1682444700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682444640000
                },
                "time": 1682444640000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Auth Key Exchange Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1326"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682444580000
                },
                "time": 1682444580000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Client Connect Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1120"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682444460000
                },
                "time": 1682444460000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682444220000
                },
                "time": 1682444220000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682443920000
                },
                "time": 1682443920000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682443620000
                },
                "time": 1682443620000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682443500000
                },
                "time": 1682443500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682443200000
                },
                "time": 1682443200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682443080000
                },
                "time": 1682443080000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682442900000
                },
                "time": 1682442900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682442840000
                },
                "time": 1682442840000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682442720000
                },
                "time": 1682442720000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682442660000
                },
                "time": 1682442660000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682442120000
                },
                "time": 1682442120000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682442000000
                },
                "time": 1682442000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682441940000
                },
                "time": 1682441940000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 3
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 4
                            }
                        }
                    ],
                    "__timestamp": 1682441820000
                },
                "time": 1682441820000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682441700000
                },
                "time": 1682441700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682441400000
                },
                "time": 1682441400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682441280000
                },
                "time": 1682441280000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682441100000
                },
                "time": 1682441100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682441040000
                },
                "time": 1682441040000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Auth Key Exchange Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1326"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682440860000
                },
                "time": 1682440860000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682440800000
                },
                "time": 1682440800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682440560000
                },
                "time": 1682440560000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682440320000
                },
                "time": 1682440320000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682440140000
                },
                "time": 1682440140000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682440020000
                },
                "time": 1682440020000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682439900000
                },
                "time": 1682439900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682439720000
                },
                "time": 1682439720000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682439660000
                },
                "time": 1682439660000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682439600000
                },
                "time": 1682439600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682439540000
                },
                "time": 1682439540000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682439480000
                },
                "time": 1682439480000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682439360000
                },
                "time": 1682439360000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682439300000
                },
                "time": 1682439300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682439240000
                },
                "time": 1682439240000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682438820000
                },
                "time": 1682438820000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682438460000
                },
                "time": 1682438460000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682438340000
                },
                "time": 1682438340000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682438220000
                },
                "time": 1682438220000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682438160000
                },
                "time": 1682438160000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 3
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 4
                            }
                        }
                    ],
                    "__timestamp": 1682438100000
                },
                "time": 1682438100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682437860000
                },
                "time": 1682437860000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682437800000
                },
                "time": 1682437800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682437680000
                },
                "time": 1682437680000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682437500000
                },
                "time": 1682437500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682437440000
                },
                "time": 1682437440000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682436720000
                },
                "time": 1682436720000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682436420000
                },
                "time": 1682436420000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682436360000
                },
                "time": 1682436360000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 3
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 4
                            }
                        }
                    ],
                    "__timestamp": 1682436300000
                },
                "time": 1682436300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682435880000
                },
                "time": 1682435880000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682435700000
                },
                "time": 1682435700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682435640000
                },
                "time": 1682435640000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682434920000
                },
                "time": 1682434920000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682434860000
                },
                "time": 1682434860000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Client Connect Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1120"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682434800000
                },
                "time": 1682434800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682434620000
                },
                "time": 1682434620000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682434560000
                },
                "time": 1682434560000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "attemptCount": 2
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 4
                            }
                        }
                    ],
                    "__timestamp": 1682434500000
                },
                "time": 1682434500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682434260000
                },
                "time": 1682434260000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 4
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            },
                                                            {
                                                                "values": {
                                                                    "failureReason": "4 Way Key Exchange Failure - M3 Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1369"
                                                            }
                                                        ],
                                                        "attemptCount": 2
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 2
                                        },
                                        "resultType": "fail"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 7
                            }
                        }
                    ],
                    "__timestamp": 1682434200000
                },
                "time": 1682434200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682434080000
                },
                "time": 1682434080000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682433900000
                },
                "time": 1682433900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682433840000
                },
                "time": 1682433840000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682433240000
                },
                "time": 1682433240000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Auth Key Exchange Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1326"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682433120000
                },
                "time": 1682433120000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682432820000
                },
                "time": 1682432820000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682432760000
                },
                "time": 1682432760000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682432700000
                },
                "time": 1682432700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682432280000
                },
                "time": 1682432280000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682432100000
                },
                "time": 1682432100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682432040000
                },
                "time": 1682432040000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682431020000
                },
                "time": 1682431020000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682430960000
                },
                "time": 1682430960000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682430900000
                },
                "time": 1682430900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682430660000
                },
                "time": 1682430660000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682430480000
                },
                "time": 1682430480000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682430300000
                },
                "time": 1682430300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682430240000
                },
                "time": 1682430240000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682429520000
                },
                "time": 1682429520000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682429220000
                },
                "time": 1682429220000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Auth Key Exchange Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1326"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682429160000
                },
                "time": 1682429160000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Auth Key Exchange Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1326"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Auth Key Exchange Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1326"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682429100000
                },
                "time": 1682429100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 2
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 2
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 2
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682428800000
                },
                "time": 1682428800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682428680000
                },
                "time": 1682428680000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682428500000
                },
                "time": 1682428500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682428440000
                },
                "time": 1682428440000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682428020000
                },
                "time": 1682428020000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682427720000
                },
                "time": 1682427720000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Auth Key Exchange Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1326"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682427660000
                },
                "time": 1682427660000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682427540000
                },
                "time": 1682427540000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682427420000
                },
                "time": 1682427420000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682427300000
                },
                "time": 1682427300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682427000000
                },
                "time": 1682427000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682426880000
                },
                "time": 1682426880000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682426760000
                },
                "time": 1682426760000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682426700000
                },
                "time": 1682426700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682426640000
                },
                "time": 1682426640000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682426520000
                },
                "time": 1682426520000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682426280000
                },
                "time": 1682426280000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682426220000
                },
                "time": 1682426220000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682426160000
                },
                "time": 1682426160000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682425920000
                },
                "time": 1682425920000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682425620000
                },
                "time": 1682425620000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682425560000
                },
                "time": 1682425560000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 2
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 2
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 2
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "attemptCount": 2
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 2
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 2
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 2
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682425500000
                },
                "time": 1682425500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682425260000
                },
                "time": 1682425260000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682425080000
                },
                "time": 1682425080000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682424960000
                },
                "time": 1682424960000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Auth Key Exchange Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1326"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682424900000
                },
                "time": 1682424900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682424840000
                },
                "time": 1682424840000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682424600000
                },
                "time": 1682424600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682424360000
                },
                "time": 1682424360000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682424120000
                },
                "time": 1682424120000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682424060000
                },
                "time": 1682424060000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682423820000
                },
                "time": 1682423820000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682423760000
                },
                "time": 1682423760000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682423700000
                },
                "time": 1682423700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682423460000
                },
                "time": 1682423460000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682423280000
                },
                "time": 1682423280000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682423100000
                },
                "time": 1682423100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682422560000
                },
                "time": 1682422560000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682422380000
                },
                "time": 1682422380000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682422320000
                },
                "time": 1682422320000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682422260000
                },
                "time": 1682422260000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682422200000
                },
                "time": 1682422200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682422020000
                },
                "time": 1682422020000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682421960000
                },
                "time": 1682421960000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 5
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 6
                            }
                        }
                    ],
                    "__timestamp": 1682421900000
                },
                "time": 1682421900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682421840000
                },
                "time": 1682421840000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682420220000
                },
                "time": 1682420220000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682420160000
                },
                "time": 1682420160000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682420100000
                },
                "time": 1682420100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682420040000
                },
                "time": 1682420040000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682419980000
                },
                "time": 1682419980000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Client Connect Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1120"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682418900000
                },
                "time": 1682418900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682418720000
                },
                "time": 1682418720000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682418600000
                },
                "time": 1682418600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682418420000
                },
                "time": 1682418420000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Auth Key Exchange Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1326"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682418360000
                },
                "time": 1682418360000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682418300000
                },
                "time": 1682418300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682418240000
                },
                "time": 1682418240000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682418180000
                },
                "time": 1682418180000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682417700000
                },
                "time": 1682417700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682417340000
                },
                "time": 1682417340000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682417220000
                },
                "time": 1682417220000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682417160000
                },
                "time": 1682417160000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682417100000
                },
                "time": 1682417100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682416920000
                },
                "time": 1682416920000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682416740000
                },
                "time": 1682416740000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682416500000
                },
                "time": 1682416500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 2
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 2
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 2
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682416380000
                },
                "time": 1682416380000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682415900000
                },
                "time": 1682415900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682415840000
                },
                "time": 1682415840000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682415720000
                },
                "time": 1682415720000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682415660000
                },
                "time": 1682415660000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682415420000
                },
                "time": 1682415420000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682415120000
                },
                "time": 1682415120000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682415060000
                },
                "time": 1682415060000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682415000000
                },
                "time": 1682415000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682414880000
                },
                "time": 1682414880000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "attemptCount": 2
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682414700000
                },
                "time": 1682414700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682414460000
                },
                "time": 1682414460000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682414400000
                },
                "time": 1682414400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682414100000
                },
                "time": 1682414100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682414040000
                },
                "time": 1682414040000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682413920000
                },
                "time": 1682413920000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682413860000
                },
                "time": 1682413860000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682413620000
                },
                "time": 1682413620000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 2
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 2
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 2
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682413320000
                },
                "time": 1682413320000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682413260000
                },
                "time": 1682413260000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682413080000
                },
                "time": 1682413080000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682412900000
                },
                "time": 1682412900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 4
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Auth Key Exchange Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1326"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 2
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 2
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 2
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 7
                            }
                        }
                    ],
                    "__timestamp": 1682412600000
                },
                "time": 1682412600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682412240000
                },
                "time": 1682412240000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Client Connect Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1120"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682412120000
                },
                "time": 1682412120000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682411820000
                },
                "time": 1682411820000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682411520000
                },
                "time": 1682411520000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Client Connect Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1120"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "dhcp"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682411400000
                },
                "time": 1682411400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682411160000
                },
                "time": 1682411160000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682411100000
                },
                "time": 1682411100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682410440000
                },
                "time": 1682410440000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682410320000
                },
                "time": 1682410320000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682410200000
                },
                "time": 1682410200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682410080000
                },
                "time": 1682410080000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682410020000
                },
                "time": 1682410020000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682409960000
                },
                "time": 1682409960000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682409780000
                },
                "time": 1682409780000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 3
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 4
                            }
                        }
                    ],
                    "__timestamp": 1682409720000
                },
                "time": 1682409720000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682409660000
                },
                "time": 1682409660000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682409600000
                },
                "time": 1682409600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682409540000
                },
                "time": 1682409540000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Client Connect Timeout",
                                                                    "attemptCount": 2
                                                                },
                                                                "reasonCode": "1120"
                                                            }
                                                        ],
                                                        "attemptCount": 2
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 2
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682409480000
                },
                "time": 1682409480000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682409420000
                },
                "time": 1682409420000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682409360000
                },
                "time": 1682409360000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682409300000
                },
                "time": 1682409300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682409180000
                },
                "time": 1682409180000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682409000000
                },
                "time": 1682409000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682408640000
                },
                "time": 1682408640000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682408520000
                },
                "time": 1682408520000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682408220000
                },
                "time": 1682408220000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682407920000
                },
                "time": 1682407920000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682407860000
                },
                "time": 1682407860000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682407560000
                },
                "time": 1682407560000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682407500000
                },
                "time": 1682407500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682407380000
                },
                "time": 1682407380000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682407200000
                },
                "time": 1682407200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682406840000
                },
                "time": 1682406840000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682406720000
                },
                "time": 1682406720000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 2
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 2
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 2
                                        },
                                        "resultType": "fail"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 4
                            }
                        }
                    ],
                    "__timestamp": 1682406420000
                },
                "time": 1682406420000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682406120000
                },
                "time": 1682406120000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682405820000
                },
                "time": 1682405820000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682405700000
                },
                "time": 1682405700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682405580000
                },
                "time": 1682405580000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682405400000
                },
                "time": 1682405400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682405040000
                },
                "time": 1682405040000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682404920000
                },
                "time": 1682404920000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 2
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 2
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 2
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682404620000
                },
                "time": 1682404620000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682404320000
                },
                "time": 1682404320000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682404140000
                },
                "time": 1682404140000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 2
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 2
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 2
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682404020000
                },
                "time": 1682404020000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 3
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 5
                            }
                        }
                    ],
                    "__timestamp": 1682403900000
                },
                "time": 1682403900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682403780000
                },
                "time": 1682403780000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682403600000
                },
                "time": 1682403600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682403240000
                },
                "time": 1682403240000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682403120000
                },
                "time": 1682403120000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682402760000
                },
                "time": 1682402760000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682402520000
                },
                "time": 1682402520000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682402160000
                },
                "time": 1682402160000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "attemptCount": 2
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 3
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 5
                            }
                        }
                    ],
                    "__timestamp": 1682402100000
                },
                "time": 1682402100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682401980000
                },
                "time": 1682401980000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 3
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Auth Key Exchange Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1326"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 5
                            }
                        }
                    ],
                    "__timestamp": 1682401800000
                },
                "time": 1682401800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682401440000
                },
                "time": 1682401440000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682401320000
                },
                "time": 1682401320000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682401260000
                },
                "time": 1682401260000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682401020000
                },
                "time": 1682401020000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682400720000
                },
                "time": 1682400720000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682400300000
                },
                "time": 1682400300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682400180000
                },
                "time": 1682400180000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Client Connect Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1120"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "dhcp"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682400000000
                },
                "time": 1682400000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682399640000
                },
                "time": 1682399640000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682399520000
                },
                "time": 1682399520000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682399460000
                },
                "time": 1682399460000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682398620000
                },
                "time": 1682398620000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682398500000
                },
                "time": 1682398500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682398380000
                },
                "time": 1682398380000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682398200000
                },
                "time": 1682398200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682397840000
                },
                "time": 1682397840000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682397780000
                },
                "time": 1682397780000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682397720000
                },
                "time": 1682397720000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682397660000
                },
                "time": 1682397660000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682397360000
                },
                "time": 1682397360000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682397300000
                },
                "time": 1682397300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682397120000
                },
                "time": 1682397120000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682397000000
                },
                "time": 1682397000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682396940000
                },
                "time": 1682396940000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Mobility Profile Mismatch",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1249"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "other"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682396700000
                },
                "time": 1682396700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682396580000
                },
                "time": 1682396580000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682396400000
                },
                "time": 1682396400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682396040000
                },
                "time": 1682396040000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682395920000
                },
                "time": 1682395920000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682395500000
                },
                "time": 1682395500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682395200000
                },
                "time": 1682395200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682395140000
                },
                "time": 1682395140000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682394900000
                },
                "time": 1682394900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682394780000
                },
                "time": 1682394780000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682394360000
                },
                "time": 1682394360000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682394300000
                },
                "time": 1682394300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682394240000
                },
                "time": 1682394240000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682394120000
                },
                "time": 1682394120000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682394060000
                },
                "time": 1682394060000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682393820000
                },
                "time": 1682393820000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682393760000
                },
                "time": 1682393760000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682393520000
                },
                "time": 1682393520000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682393100000
                },
                "time": 1682393100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682393040000
                },
                "time": 1682393040000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/8dce5dc9-2df6-450c-bf12-9efdbee80527/",
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 2
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "siteHierarchy": "Global/San Jose/Building 18/Floor1",
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682392980000
                },
                "time": 1682392980000
            }
        ],
        "page": null,
        "scope": null,
        "instrumentation": null,
        "message": null
    },
    
                           
    "IncludeSiteFalseData":
    {
        "version": "v2",
        "data": [
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        },
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 2
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 2
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 2
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "attemptCount": 2
                            }
                        },
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Auth Key Exchange Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1326"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682478600000
                },
                "time": 1682478600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682477700000
                },
                "time": 1682477700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682476800000
                },
                "time": 1682476800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682475000000
                },
                "time": 1682475000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682473200000
                },
                "time": 1682473200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682471400000
                },
                "time": 1682471400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 2
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 2
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 2
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682469600000
                },
                "time": 1682469600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682469300000
                },
                "time": 1682469300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682467500000
                },
                "time": 1682467500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682466000000
                },
                "time": 1682466000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682464200000
                },
                "time": 1682464200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682462700000
                },
                "time": 1682462700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682462400000
                },
                "time": 1682462400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682460600000
                },
                "time": 1682460600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682458800000
                },
                "time": 1682458800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 2
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 2
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 2
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682457900000
                },
                "time": 1682457900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682457600000
                },
                "time": 1682457600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682455800000
                },
                "time": 1682455800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682455500000
                },
                "time": 1682455500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682455200000
                },
                "time": 1682455200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 2
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 2
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 2
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682454000000
                },
                "time": 1682454000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682453700000
                },
                "time": 1682453700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682452200000
                },
                "time": 1682452200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 2
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 2
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 2
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682451300000
                },
                "time": 1682451300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682450400000
                },
                "time": 1682450400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682448600000
                },
                "time": 1682448600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682446800000
                },
                "time": 1682446800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682445300000
                },
                "time": 1682445300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682443500000
                },
                "time": 1682443500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682441700000
                },
                "time": 1682441700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682439900000
                },
                "time": 1682439900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682438100000
                },
                "time": 1682438100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682436300000
                },
                "time": 1682436300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682434500000
                },
                "time": 1682434500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682432700000
                },
                "time": 1682432700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682430900000
                },
                "time": 1682430900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Auth Key Exchange Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1326"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682429100000
                },
                "time": 1682429100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682427300000
                },
                "time": 1682427300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 2
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 2
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 2
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682425500000
                },
                "time": 1682425500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682423700000
                },
                "time": 1682423700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682421900000
                },
                "time": 1682421900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682420100000
                },
                "time": 1682420100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682418300000
                },
                "time": 1682418300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682416500000
                },
                "time": 1682416500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682415000000
                },
                "time": 1682415000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682414700000
                },
                "time": 1682414700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682412900000
                },
                "time": 1682412900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682412600000
                },
                "time": 1682412600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682411100000
                },
                "time": 1682411100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682410200000
                },
                "time": 1682410200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    }
                                ],
                                "attemptCount": 2
                            }
                        }
                    ],
                    "__timestamp": 1682409300000
                },
                "time": 1682409300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682407500000
                },
                "time": 1682407500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682405700000
                },
                "time": 1682405700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682403900000
                },
                "time": 1682403900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "0"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "NONE"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "incomplete"
                                    },
                                    {
                                        "values": {
                                            "nestedData": [
                                                {
                                                    "values": {
                                                        "nestedData": [
                                                            {
                                                                "values": {
                                                                    "failureReason": "Client Connect Timeout",
                                                                    "attemptCount": 1
                                                                },
                                                                "reasonCode": "1120"
                                                            }
                                                        ],
                                                        "attemptCount": 1
                                                    },
                                                    "resultCategory": "aaa"
                                                }
                                            ],
                                            "attemptCount": 1
                                        },
                                        "resultType": "fail"
                                    }
                                ],
                                "attemptCount": 3
                            }
                        }
                    ],
                    "__timestamp": 1682402100000
                },
                "time": 1682402100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682401800000
                },
                "time": 1682401800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682400000000
                },
                "time": 1682400000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682398200000
                },
                "time": 1682398200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682397300000
                },
                "time": 1682397300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682396400000
                },
                "time": 1682396400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682395500000
                },
                "time": 1682395500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "nestedData": [
                                    {
                                        "values": {
                                            "nestedData": [],
                                            "attemptCount": 1
                                        },
                                        "resultType": "success"
                                    }
                                ],
                                "attemptCount": 1
                            }
                        }
                    ],
                    "__timestamp": 1682393700000
                },
                "time": 1682393700000
            }
        ],
        "page": null,
        "scope": null,
        "instrumentation": null,
        "message": null
    },

    "ApMacData":
    {
        "version": null,
        "data": [
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682230200000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "d8e3e8b1-40cd-4f79-b82a-95fa06ee9e1e",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:B7:40",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682230200000,
                            "switchPort": "GigabitEthernet1/0/12",
                            "apName": "SJC14-TME-AP9"
                        }
                    ],
                    "__timestamp": 1682230200000
                },
                "time": 1682230200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682230317746,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682230317746,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682230500000
                },
                "time": 1682230500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682230800000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682230800000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682230800000
                },
                "time": 1682230800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682231100000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682231100000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682231100000
                },
                "time": 1682231100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682231400000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682231400000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682231400000
                },
                "time": 1682231400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682231700000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682231700000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682231700000
                },
                "time": 1682231700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682232000000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682232000000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682232000000
                },
                "time": 1682232000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682232119935,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "d8e3e8b1-40cd-4f79-b82a-95fa06ee9e1e",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:B7:40",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682232119935,
                            "switchPort": "GigabitEthernet1/0/12",
                            "apName": "SJC14-TME-AP9"
                        }
                    ],
                    "__timestamp": 1682232300000
                },
                "time": 1682232300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682232600000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682232600000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682232600000
                },
                "time": 1682232600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682232959289,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682232959289,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682232900000
                },
                "time": 1682232900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682232962188,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "78e175cd-ce40-4e1d-8f61-6f1974f306c7",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:40",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682232962188,
                            "switchPort": "GigabitEthernet1/0/13",
                            "apName": "Traffic_Assurance_01"
                        },
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682232971908,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682232971908,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682233200000
                },
                "time": 1682233200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682233500000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682233500000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682233500000
                },
                "time": 1682233500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682233800000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682233800000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682233800000
                },
                "time": 1682233800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682234100000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682234100000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682234100000
                },
                "time": 1682234100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682234400000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682234400000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682234400000
                },
                "time": 1682234400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682234700000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682234700000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682234700000
                },
                "time": 1682234700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682234773161,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682234773161,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682235000000
                },
                "time": 1682235000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682235300000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "797c583c-7f12-4b89-99e3-bd992cdb1072",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "08:4F:F9:83:47:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682235300000,
                            "switchPort": "GigabitEthernet1/0/10",
                            "apName": "AP2CF8.9B5F.AEB8"
                        }
                    ],
                    "__timestamp": 1682235300000
                },
                "time": 1682235300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682235600000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682235600000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682235600000
                },
                "time": 1682235600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682235900000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682235900000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682235900000
                },
                "time": 1682235900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682236200000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682236200000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682236200000
                },
                "time": 1682236200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682236500000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682236500000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682236500000
                },
                "time": 1682236500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682236575374,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "d8e3e8b1-40cd-4f79-b82a-95fa06ee9e1e",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:B7:40",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682236575374,
                            "switchPort": "GigabitEthernet1/0/12",
                            "apName": "SJC14-TME-AP9"
                        }
                    ],
                    "__timestamp": 1682236800000
                },
                "time": 1682236800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682237100000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "d8e3e8b1-40cd-4f79-b82a-95fa06ee9e1e",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:B7:40",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682237100000,
                            "switchPort": "GigabitEthernet1/0/12",
                            "apName": "SJC14-TME-AP9"
                        }
                    ],
                    "__timestamp": 1682237100000
                },
                "time": 1682237100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682237400000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "d8e3e8b1-40cd-4f79-b82a-95fa06ee9e1e",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:B7:40",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682237400000,
                            "switchPort": "GigabitEthernet1/0/12",
                            "apName": "SJC14-TME-AP9"
                        }
                    ],
                    "__timestamp": 1682237400000
                },
                "time": 1682237400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682237700000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "d8e3e8b1-40cd-4f79-b82a-95fa06ee9e1e",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:B7:40",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682237700000,
                            "switchPort": "GigabitEthernet1/0/12",
                            "apName": "SJC14-TME-AP9"
                        }
                    ],
                    "__timestamp": 1682237700000
                },
                "time": 1682237700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682238000000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "d8e3e8b1-40cd-4f79-b82a-95fa06ee9e1e",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:B7:40",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682238000000,
                            "switchPort": "GigabitEthernet1/0/12",
                            "apName": "SJC14-TME-AP9"
                        }
                    ],
                    "__timestamp": 1682238000000
                },
                "time": 1682238000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682238300000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "d8e3e8b1-40cd-4f79-b82a-95fa06ee9e1e",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:B7:40",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682238300000,
                            "switchPort": "GigabitEthernet1/0/12",
                            "apName": "SJC14-TME-AP9"
                        }
                    ],
                    "__timestamp": 1682238300000
                },
                "time": 1682238300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682238618633,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682238618633,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        },
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682238376647,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682238376647,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682238600000
                },
                "time": 1682238600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682238900000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682238900000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682238900000
                },
                "time": 1682238900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682239200000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682239200000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682239200000
                },
                "time": 1682239200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682239500000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682239500000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682239500000
                },
                "time": 1682239500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682239800000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682239800000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682239800000
                },
                "time": 1682239800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682240100000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682240100000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682240100000
                },
                "time": 1682240100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682240177667,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682240177667,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682240400000
                },
                "time": 1682240400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682240700000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682240700000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682240700000
                },
                "time": 1682240700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682241000000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682241000000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682241000000
                },
                "time": 1682241000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682241300000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682241300000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682241300000
                },
                "time": 1682241300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682241600000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682241600000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682241600000
                },
                "time": 1682241600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682241900000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682241900000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682241900000
                },
                "time": 1682241900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682241978751,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682241978751,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682242200000
                },
                "time": 1682242200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682242500000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682242500000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682242500000
                },
                "time": 1682242500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682242800000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682242800000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682242800000
                },
                "time": 1682242800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682243100000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682243100000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682243100000
                },
                "time": 1682243100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682243400000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682243400000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682243400000
                },
                "time": 1682243400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682243700000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682243700000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682243700000
                },
                "time": 1682243700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682243779945,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682243779945,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682244000000
                },
                "time": 1682244000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682244300000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682244300000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682244300000
                },
                "time": 1682244300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682244600000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682244600000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682244600000
                },
                "time": 1682244600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682244900000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682244900000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682244900000
                },
                "time": 1682244900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682245187181,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682245171344,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682245200000
                },
                "time": 1682245200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682245500000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682245500000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682245500000
                },
                "time": 1682245500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682245800000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682245800000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682245800000
                },
                "time": 1682245800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682246100000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682246100000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682246100000
                },
                "time": 1682246100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682246400000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682246400000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682246400000
                },
                "time": 1682246400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682246700000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682246700000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682246700000
                },
                "time": 1682246700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682246988036,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682246988036,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682247000000
                },
                "time": 1682247000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682247300000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682247300000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682247300000
                },
                "time": 1682247300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682247600000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682247600000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682247600000
                },
                "time": 1682247600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682247900000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682247900000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682247900000
                },
                "time": 1682247900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682248200000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682248200000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682248200000
                },
                "time": 1682248200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682248500000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682248500000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682248500000
                },
                "time": 1682248500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682248790286,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682248790286,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682248800000
                },
                "time": 1682248800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682249100000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682249100000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682249100000
                },
                "time": 1682249100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682249400000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682249400000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682249400000
                },
                "time": 1682249400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682249700000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682249700000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682249700000
                },
                "time": 1682249700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682249842344,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "d8e3e8b1-40cd-4f79-b82a-95fa06ee9e1e",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:B7:40",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682249839705,
                            "switchPort": "GigabitEthernet1/0/12",
                            "apName": "SJC14-TME-AP9"
                        }
                    ],
                    "__timestamp": 1682250000000
                },
                "time": 1682250000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682250300000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "d8e3e8b1-40cd-4f79-b82a-95fa06ee9e1e",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:B7:40",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682250300000,
                            "switchPort": "GigabitEthernet1/0/12",
                            "apName": "SJC14-TME-AP9"
                        }
                    ],
                    "__timestamp": 1682250300000
                },
                "time": 1682250300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682250600000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "d8e3e8b1-40cd-4f79-b82a-95fa06ee9e1e",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:B7:40",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682250600000,
                            "switchPort": "GigabitEthernet1/0/12",
                            "apName": "SJC14-TME-AP9"
                        }
                    ],
                    "__timestamp": 1682250600000
                },
                "time": 1682250600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682250900000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682250900000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682250900000
                },
                "time": 1682250900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682251053769,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "d8e3e8b1-40cd-4f79-b82a-95fa06ee9e1e",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:B7:40",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682251053769,
                            "switchPort": "GigabitEthernet1/0/12",
                            "apName": "SJC14-TME-AP9"
                        }
                    ],
                    "__timestamp": 1682251200000
                },
                "time": 1682251200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682251500000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682251500000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682251500000
                },
                "time": 1682251500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682251643518,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682251643518,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682251800000
                },
                "time": 1682251800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682252100000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682252100000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682252100000
                },
                "time": 1682252100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682252400000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682252400000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682252400000
                },
                "time": 1682252400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682252694927,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682252694927,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682252700000
                },
                "time": 1682252700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682253000000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682253000000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682253000000
                },
                "time": 1682253000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682253300000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682253300000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682253300000
                },
                "time": 1682253300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682253444550,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682253444550,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682253600000
                },
                "time": 1682253600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682253900000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682253900000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682253900000
                },
                "time": 1682253900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682254200000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682254200000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682254200000
                },
                "time": 1682254200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682254500000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682254500000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682254500000
                },
                "time": 1682254500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682254800000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682254800000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682254800000
                },
                "time": 1682254800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682255100000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "d8e3e8b1-40cd-4f79-b82a-95fa06ee9e1e",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:B7:40",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682255100000,
                            "switchPort": "GigabitEthernet1/0/12",
                            "apName": "SJC14-TME-AP9"
                        }
                    ],
                    "__timestamp": 1682255100000
                },
                "time": 1682255100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682255245629,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "d8e3e8b1-40cd-4f79-b82a-95fa06ee9e1e",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:B7:40",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682255245629,
                            "switchPort": "GigabitEthernet1/0/12",
                            "apName": "SJC14-TME-AP9"
                        }
                    ],
                    "__timestamp": 1682255400000
                },
                "time": 1682255400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682255700000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "d8e3e8b1-40cd-4f79-b82a-95fa06ee9e1e",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:B7:40",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682255700000,
                            "switchPort": "GigabitEthernet1/0/12",
                            "apName": "SJC14-TME-AP9"
                        }
                    ],
                    "__timestamp": 1682255700000
                },
                "time": 1682255700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682256000000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682256000000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682256000000
                },
                "time": 1682256000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682256178021,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "1793f7c3-30e4-4cc3-9e46-9ed09587fcf3",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "2C:57:41:57:87:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682256178021,
                            "switchPort": "GigabitEthernet1/0/14",
                            "apName": "Assurance_9130_1"
                        }
                    ],
                    "__timestamp": 1682256300000
                },
                "time": 1682256300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682256600000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682256600000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682256600000
                },
                "time": 1682256600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682256900000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682256900000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682256900000
                },
                "time": 1682256900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682257047774,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682257047774,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682257200000
                },
                "time": 1682257200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682257500000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682257500000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682257500000
                },
                "time": 1682257500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682257800000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682257800000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682257800000
                },
                "time": 1682257800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682258100000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682258100000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682258100000
                },
                "time": 1682258100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682258400000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682258400000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682258400000
                },
                "time": 1682258400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682258700000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682258700000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682258700000
                },
                "time": 1682258700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682258849117,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682258849117,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682259000000
                },
                "time": 1682259000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682259300000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682259300000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682259300000
                },
                "time": 1682259300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682259600000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682259600000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682259600000
                },
                "time": 1682259600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682259900000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682259900000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682259900000
                },
                "time": 1682259900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682260200000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682260200000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682260200000
                },
                "time": 1682260200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682260500000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682260500000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682260500000
                },
                "time": 1682260500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682260650101,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682260650101,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682260800000
                },
                "time": 1682260800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682261100000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682261100000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682261100000
                },
                "time": 1682261100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682261400000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682261400000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682261400000
                },
                "time": 1682261400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682261698026,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682261698026,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682261700000
                },
                "time": 1682261700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682262000000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682262000000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682262000000
                },
                "time": 1682262000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682262300000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682262300000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682262300000
                },
                "time": 1682262300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682262462143,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682262452207,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682262600000
                },
                "time": 1682262600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682262900000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682262900000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682262900000
                },
                "time": 1682262900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682263200000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682263200000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682263200000
                },
                "time": 1682263200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682263500000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682263500000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682263500000
                },
                "time": 1682263500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682263800000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682263800000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682263800000
                },
                "time": 1682263800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682264100000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682264100000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682264100000
                },
                "time": 1682264100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682264258337,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682264258337,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682264400000
                },
                "time": 1682264400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682264700000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682264700000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682264700000
                },
                "time": 1682264700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682265000000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682265000000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682265000000
                },
                "time": 1682265000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682265300000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682265300000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682265300000
                },
                "time": 1682265300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682265600000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "d8e3e8b1-40cd-4f79-b82a-95fa06ee9e1e",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:B7:40",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682265600000,
                            "switchPort": "GigabitEthernet1/0/12",
                            "apName": "SJC14-TME-AP9"
                        }
                    ],
                    "__timestamp": 1682265600000
                },
                "time": 1682265600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682265900000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "d8e3e8b1-40cd-4f79-b82a-95fa06ee9e1e",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:B7:40",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682265900000,
                            "switchPort": "GigabitEthernet1/0/12",
                            "apName": "SJC14-TME-AP9"
                        }
                    ],
                    "__timestamp": 1682265900000
                },
                "time": 1682265900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682266060137,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682266059480,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682266200000
                },
                "time": 1682266200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682266500000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682266500000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682266500000
                },
                "time": 1682266500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682266800000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682266800000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682266800000
                },
                "time": 1682266800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682267100000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682267100000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682267100000
                },
                "time": 1682267100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682267400000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682267400000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682267400000
                },
                "time": 1682267400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682267700000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682267700000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682267700000
                },
                "time": 1682267700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682267860647,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "d8e3e8b1-40cd-4f79-b82a-95fa06ee9e1e",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:B7:40",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682267860647,
                            "switchPort": "GigabitEthernet1/0/12",
                            "apName": "SJC14-TME-AP9"
                        }
                    ],
                    "__timestamp": 1682268000000
                },
                "time": 1682268000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682268300000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "d8e3e8b1-40cd-4f79-b82a-95fa06ee9e1e",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:B7:40",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682268300000,
                            "switchPort": "GigabitEthernet1/0/12",
                            "apName": "SJC14-TME-AP9"
                        }
                    ],
                    "__timestamp": 1682268300000
                },
                "time": 1682268300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682268600000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "d8e3e8b1-40cd-4f79-b82a-95fa06ee9e1e",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:B7:40",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682268600000,
                            "switchPort": "GigabitEthernet1/0/12",
                            "apName": "SJC14-TME-AP9"
                        }
                    ],
                    "__timestamp": 1682268600000
                },
                "time": 1682268600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682268900000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "d8e3e8b1-40cd-4f79-b82a-95fa06ee9e1e",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:B7:40",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682268900000,
                            "switchPort": "GigabitEthernet1/0/12",
                            "apName": "SJC14-TME-AP9"
                        }
                    ],
                    "__timestamp": 1682268900000
                },
                "time": 1682268900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682269200000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682269200000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682269200000
                },
                "time": 1682269200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682269500000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682269500000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682269500000
                },
                "time": 1682269500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682269661787,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682269661787,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682269800000
                },
                "time": 1682269800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682270100000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682270100000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682270100000
                },
                "time": 1682270100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682270400000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682270400000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682270400000
                },
                "time": 1682270400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682270700000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682270700000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682270700000
                },
                "time": 1682270700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682271000000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682271000000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682271000000
                },
                "time": 1682271000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682271300000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682271300000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682271300000
                },
                "time": 1682271300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682271492313,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682271491686,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682271600000
                },
                "time": 1682271600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682271900000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682271900000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682271900000
                },
                "time": 1682271900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682272200000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682272200000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682272200000
                },
                "time": 1682272200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682272500000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682272500000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682272500000
                },
                "time": 1682272500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682272800000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682272800000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682272800000
                },
                "time": 1682272800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682273100000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682273100000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682273100000
                },
                "time": 1682273100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682273297880,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682273297227,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682273400000
                },
                "time": 1682273400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682273700000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682273700000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682273700000
                },
                "time": 1682273700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682274000000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682274000000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682274000000
                },
                "time": 1682274000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682274300000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682274300000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682274300000
                },
                "time": 1682274300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682274389465,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682274389465,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682274600000
                },
                "time": 1682274600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682274900000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682274900000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682274900000
                },
                "time": 1682274900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682275200000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682275200000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682275200000
                },
                "time": 1682275200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682275384327,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682275384327,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682275500000
                },
                "time": 1682275500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682275800000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682275800000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682275800000
                },
                "time": 1682275800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682276100000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682276100000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682276100000
                },
                "time": 1682276100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682276314982,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682276314982,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        },
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682276191355,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682276191355,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682276400000
                },
                "time": 1682276400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682276700000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682276700000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682276700000
                },
                "time": 1682276700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682277000000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682277000000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682277000000
                },
                "time": 1682277000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682277300000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682277300000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682277300000
                },
                "time": 1682277300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682277600000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682277600000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682277600000
                },
                "time": 1682277600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682277900000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "d8e3e8b1-40cd-4f79-b82a-95fa06ee9e1e",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:B7:40",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682277900000,
                            "switchPort": "GigabitEthernet1/0/12",
                            "apName": "SJC14-TME-AP9"
                        }
                    ],
                    "__timestamp": 1682277900000
                },
                "time": 1682277900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682278115465,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682278115465,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682278200000
                },
                "time": 1682278200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682278500000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682278500000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682278500000
                },
                "time": 1682278500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682278634764,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "1793f7c3-30e4-4cc3-9e46-9ed09587fcf3",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "2C:57:41:57:87:C0",
                            "slot": "2",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682278634764,
                            "switchPort": "GigabitEthernet1/0/14",
                            "apName": "Assurance_9130_1"
                        }
                    ],
                    "__timestamp": 1682278800000
                },
                "time": 1682278800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682279100000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682279100000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682279100000
                },
                "time": 1682279100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682279400000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682279400000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682279400000
                },
                "time": 1682279400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682279700000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682279700000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682279700000
                },
                "time": 1682279700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682280000000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682280000000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682280000000
                },
                "time": 1682280000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682280300000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682280300000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682280300000
                },
                "time": 1682280300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682280446734,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682280446734,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682280600000
                },
                "time": 1682280600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682280900000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682280900000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682280900000
                },
                "time": 1682280900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682281200000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682281200000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682281200000
                },
                "time": 1682281200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682281504859,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682281504859,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682281500000
                },
                "time": 1682281500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682281800000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682281800000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682281800000
                },
                "time": 1682281800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682282100000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682282100000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682282100000
                },
                "time": 1682282100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682282247705,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682282247705,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682282400000
                },
                "time": 1682282400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682282700000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682282700000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682282700000
                },
                "time": 1682282700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682283000000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682283000000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682283000000
                },
                "time": 1682283000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682283300000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682283300000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682283300000
                },
                "time": 1682283300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682283600000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682283600000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682283600000
                },
                "time": 1682283600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682283900000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682283900000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682283900000
                },
                "time": 1682283900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682284119278,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682284119278,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682284200000
                },
                "time": 1682284200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682284500000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682284500000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682284500000
                },
                "time": 1682284500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682284800000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682284800000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682284800000
                },
                "time": 1682284800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682285100000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682285100000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682285100000
                },
                "time": 1682285100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682285400000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682285400000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682285400000
                },
                "time": 1682285400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682285700000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682285700000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682285700000
                },
                "time": 1682285700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682285921450,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682285921450,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682286000000
                },
                "time": 1682286000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682286300000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682286300000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682286300000
                },
                "time": 1682286300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682286600000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "d8e3e8b1-40cd-4f79-b82a-95fa06ee9e1e",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:B7:40",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682286600000,
                            "switchPort": "GigabitEthernet1/0/12",
                            "apName": "SJC14-TME-AP9"
                        }
                    ],
                    "__timestamp": 1682286600000
                },
                "time": 1682286600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682286900000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682286900000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682286900000
                },
                "time": 1682286900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682287200000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682287200000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682287200000
                },
                "time": 1682287200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682287500000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682287500000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682287500000
                },
                "time": 1682287500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682287800000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "1793f7c3-30e4-4cc3-9e46-9ed09587fcf3",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "2C:57:41:57:87:C0",
                            "slot": "2",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682287800000,
                            "switchPort": "GigabitEthernet1/0/14",
                            "apName": "Assurance_9130_1"
                        }
                    ],
                    "__timestamp": 1682287800000
                },
                "time": 1682287800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682287908733,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682287908733,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682288100000
                },
                "time": 1682288100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682288400000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682288400000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682288400000
                },
                "time": 1682288400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682288700000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682288700000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682288700000
                },
                "time": 1682288700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682289000000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682289000000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682289000000
                },
                "time": 1682289000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682289300000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682289300000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682289300000
                },
                "time": 1682289300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682289429359,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682289429359,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682289600000
                },
                "time": 1682289600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682289900000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682289900000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682289900000
                },
                "time": 1682289900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682290116543,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682290116543,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682290200000
                },
                "time": 1682290200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682290500000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682290500000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682290500000
                },
                "time": 1682290500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682290800000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682290800000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682290800000
                },
                "time": 1682290800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682291100000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682291100000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682291100000
                },
                "time": 1682291100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682291400000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682291400000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682291400000
                },
                "time": 1682291400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682291700000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682291700000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682291700000
                },
                "time": 1682291700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682291917744,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682291917419,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682292000000
                },
                "time": 1682292000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682292300000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682292300000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682292300000
                },
                "time": 1682292300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682292600000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682292600000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682292600000
                },
                "time": 1682292600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682292900000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682292900000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682292900000
                },
                "time": 1682292900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682293200000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682293200000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682293200000
                },
                "time": 1682293200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682293500000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682293500000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682293500000
                },
                "time": 1682293500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682293721603,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "d8e3e8b1-40cd-4f79-b82a-95fa06ee9e1e",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:B7:40",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682293721603,
                            "switchPort": "GigabitEthernet1/0/12",
                            "apName": "SJC14-TME-AP9"
                        }
                    ],
                    "__timestamp": 1682293800000
                },
                "time": 1682293800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682294100000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682294100000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682294100000
                },
                "time": 1682294100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682294400000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682294400000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682294400000
                },
                "time": 1682294400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682294700000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682294700000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682294700000
                },
                "time": 1682294700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682295000000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682295000000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682295000000
                },
                "time": 1682295000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682295178873,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682295178873,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682295300000
                },
                "time": 1682295300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682295522747,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682295522747,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682295600000
                },
                "time": 1682295600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682295900000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682295900000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682295900000
                },
                "time": 1682295900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682296200000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682296200000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682296200000
                },
                "time": 1682296200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682296500000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682296500000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682296500000
                },
                "time": 1682296500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682296800000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682296800000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682296800000
                },
                "time": 1682296800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682297100000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682297100000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682297100000
                },
                "time": 1682297100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682297324260,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "1793f7c3-30e4-4cc3-9e46-9ed09587fcf3",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "2C:57:41:57:87:C0",
                            "slot": "2",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682297324260,
                            "switchPort": "GigabitEthernet1/0/14",
                            "apName": "Assurance_9130_1"
                        }
                    ],
                    "__timestamp": 1682297400000
                },
                "time": 1682297400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682297700000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "1793f7c3-30e4-4cc3-9e46-9ed09587fcf3",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "2C:57:41:57:87:C0",
                            "slot": "2",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682297700000,
                            "switchPort": "GigabitEthernet1/0/14",
                            "apName": "Assurance_9130_1"
                        }
                    ],
                    "__timestamp": 1682297700000
                },
                "time": 1682297700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682298000000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "1793f7c3-30e4-4cc3-9e46-9ed09587fcf3",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "2C:57:41:57:87:C0",
                            "slot": "2",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682298000000,
                            "switchPort": "GigabitEthernet1/0/14",
                            "apName": "Assurance_9130_1"
                        }
                    ],
                    "__timestamp": 1682298000000
                },
                "time": 1682298000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682298300000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "1793f7c3-30e4-4cc3-9e46-9ed09587fcf3",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "2C:57:41:57:87:C0",
                            "slot": "2",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682298300000,
                            "switchPort": "GigabitEthernet1/0/14",
                            "apName": "Assurance_9130_1"
                        }
                    ],
                    "__timestamp": 1682298300000
                },
                "time": 1682298300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682298600000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "1793f7c3-30e4-4cc3-9e46-9ed09587fcf3",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "2C:57:41:57:87:C0",
                            "slot": "2",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682298600000,
                            "switchPort": "GigabitEthernet1/0/14",
                            "apName": "Assurance_9130_1"
                        }
                    ],
                    "__timestamp": 1682298600000
                },
                "time": 1682298600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682298900000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682298900000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682298900000
                },
                "time": 1682298900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682299158655,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "d8e3e8b1-40cd-4f79-b82a-95fa06ee9e1e",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:B7:40",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682299127482,
                            "switchPort": "GigabitEthernet1/0/12",
                            "apName": "SJC14-TME-AP9"
                        },
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682299126443,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682299126443,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682299200000
                },
                "time": 1682299200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682299500000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682299500000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682299500000
                },
                "time": 1682299500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682299800000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "d8e3e8b1-40cd-4f79-b82a-95fa06ee9e1e",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:B7:40",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682299800000,
                            "switchPort": "GigabitEthernet1/0/12",
                            "apName": "SJC14-TME-AP9"
                        }
                    ],
                    "__timestamp": 1682299800000
                },
                "time": 1682299800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682300100000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "d8e3e8b1-40cd-4f79-b82a-95fa06ee9e1e",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:B7:40",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682300100000,
                            "switchPort": "GigabitEthernet1/0/12",
                            "apName": "SJC14-TME-AP9"
                        }
                    ],
                    "__timestamp": 1682300100000
                },
                "time": 1682300100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682300246096,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "d8e3e8b1-40cd-4f79-b82a-95fa06ee9e1e",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:B7:40",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682300246096,
                            "switchPort": "GigabitEthernet1/0/12",
                            "apName": "SJC14-TME-AP9"
                        }
                    ],
                    "__timestamp": 1682300400000
                },
                "time": 1682300400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682300700000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "d8e3e8b1-40cd-4f79-b82a-95fa06ee9e1e",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:B7:40",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682300700000,
                            "switchPort": "GigabitEthernet1/0/12",
                            "apName": "SJC14-TME-AP9"
                        }
                    ],
                    "__timestamp": 1682300700000
                },
                "time": 1682300700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682301007837,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "1793f7c3-30e4-4cc3-9e46-9ed09587fcf3",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "2C:57:41:57:87:C0",
                            "slot": "2",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682301007837,
                            "switchPort": "GigabitEthernet1/0/14",
                            "apName": "Assurance_9130_1"
                        }
                    ],
                    "__timestamp": 1682301000000
                },
                "time": 1682301000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682301300000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "1793f7c3-30e4-4cc3-9e46-9ed09587fcf3",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "2C:57:41:57:87:C0",
                            "slot": "2",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682301300000,
                            "switchPort": "GigabitEthernet1/0/14",
                            "apName": "Assurance_9130_1"
                        }
                    ],
                    "__timestamp": 1682301300000
                },
                "time": 1682301300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682301600000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "1793f7c3-30e4-4cc3-9e46-9ed09587fcf3",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "2C:57:41:57:87:C0",
                            "slot": "2",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682301600000,
                            "switchPort": "GigabitEthernet1/0/14",
                            "apName": "Assurance_9130_1"
                        }
                    ],
                    "__timestamp": 1682301600000
                },
                "time": 1682301600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682301900000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "1793f7c3-30e4-4cc3-9e46-9ed09587fcf3",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "2C:57:41:57:87:C0",
                            "slot": "2",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682301900000,
                            "switchPort": "GigabitEthernet1/0/14",
                            "apName": "Assurance_9130_1"
                        }
                    ],
                    "__timestamp": 1682301900000
                },
                "time": 1682301900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682302200000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "1793f7c3-30e4-4cc3-9e46-9ed09587fcf3",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "2C:57:41:57:87:C0",
                            "slot": "2",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682302200000,
                            "switchPort": "GigabitEthernet1/0/14",
                            "apName": "Assurance_9130_1"
                        }
                    ],
                    "__timestamp": 1682302200000
                },
                "time": 1682302200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682302500000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "1793f7c3-30e4-4cc3-9e46-9ed09587fcf3",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "2C:57:41:57:87:C0",
                            "slot": "2",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682302500000,
                            "switchPort": "GigabitEthernet1/0/14",
                            "apName": "Assurance_9130_1"
                        }
                    ],
                    "__timestamp": 1682302500000
                },
                "time": 1682302500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682302813026,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682302813026,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682302800000
                },
                "time": 1682302800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682303072232,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682303064642,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682303100000
                },
                "time": 1682303100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682303400000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682303400000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682303400000
                },
                "time": 1682303400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682303700000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682303700000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682303700000
                },
                "time": 1682303700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682304000000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682304000000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682304000000
                },
                "time": 1682304000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682304300000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682304300000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682304300000
                },
                "time": 1682304300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682304600000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682304600000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682304600000
                },
                "time": 1682304600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682304872472,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682304872472,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682304900000
                },
                "time": 1682304900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682305200000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682305200000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682305200000
                },
                "time": 1682305200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682305500000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682305500000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682305500000
                },
                "time": 1682305500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682305800000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682305800000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682305800000
                },
                "time": 1682305800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682306100000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682306100000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682306100000
                },
                "time": 1682306100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682306400000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682306400000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682306400000
                },
                "time": 1682306400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682306673735,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682306673735,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682306700000
                },
                "time": 1682306700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682307000000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682307000000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682307000000
                },
                "time": 1682307000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682307233779,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682307233779,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682307300000
                },
                "time": 1682307300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682307600000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682307600000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682307600000
                },
                "time": 1682307600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682307813548,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682307809797,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682307900000
                },
                "time": 1682307900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682308200000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682308200000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682308200000
                },
                "time": 1682308200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682308500000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682308500000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682308500000
                },
                "time": 1682308500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682308800000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682308800000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682308800000
                },
                "time": 1682308800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682309100000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682309100000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682309100000
                },
                "time": 1682309100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682309400000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682309400000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682309400000
                },
                "time": 1682309400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682309614760,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682309614760,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682309700000
                },
                "time": 1682309700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682310000000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682310000000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682310000000
                },
                "time": 1682310000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682310300000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682310300000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682310300000
                },
                "time": 1682310300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682310600000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682310600000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682310600000
                },
                "time": 1682310600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682310900000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682310900000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682310900000
                },
                "time": 1682310900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682311200000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682311200000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682311200000
                },
                "time": 1682311200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682311416890,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682311416890,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682311500000
                },
                "time": 1682311500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682311800000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682311800000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682311800000
                },
                "time": 1682311800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682312100000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "4f886bb9-c648-437a-a713-19c921e4b4b2",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "10:B3:C6:24:9E:60",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682312100000,
                            "switchPort": "GigabitEthernet1/0/11",
                            "apName": "SJC14-TME-AP11"
                        }
                    ],
                    "__timestamp": 1682312100000
                },
                "time": 1682312100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682312266787,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682312266787,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682312400000
                },
                "time": 1682312400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682312700000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682312700000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682312700000
                },
                "time": 1682312700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682313000000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682313000000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682313000000
                },
                "time": 1682313000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682313300000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682313300000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682313300000
                },
                "time": 1682313300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682313600000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682313600000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682313600000
                },
                "time": 1682313600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682313900000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682313900000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682313900000
                },
                "time": 1682313900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682314068020,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682314068020,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682314200000
                },
                "time": 1682314200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682314500000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682314500000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682314500000
                },
                "time": 1682314500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682314800000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682314800000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682314800000
                },
                "time": 1682314800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682315100000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682315100000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682315100000
                },
                "time": 1682315100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682315400000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682315400000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682315400000
                },
                "time": 1682315400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682315700000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "ba8209a5-0c55-4f38-a02c-498aa63e2197",
                            "switchUUID": "3e360557-3863-4cfc-b501-10018bba7a5e",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "AC:4A:56:AE:92:C0",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682315700000,
                            "switchPort": "GigabitEthernet1/0/3",
                            "apName": "Assurance_9130_3"
                        }
                    ],
                    "__timestamp": 1682315700000
                },
                "time": 1682315700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "lastSeenTimestamp": 1682315869565,
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682315869565,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682316000000
                },
                "time": 1682316000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "lastSeenTimestamp": 1682316300000,
                            "wlcName": "c9800-40-TMEDNAC.wireless-tme.com",
                            "wlcUUID": "fd2a83ba-be0e-4b32-8dd9-9bc73f92c7a8",
                            "apUuid": "5ac1b143-4ac2-45e7-bbf4-25d9f905e90a",
                            "switchName": "B18-live-C9200.wireless-tme.com",
                            "apMac": "04:62:73:EB:C2:80",
                            "slot": "1",
                            "apInterfaceName": "GigabitEthernet0",
                            "firstSeenTimestamp": 1682316300000,
                            "switchPort": "GigabitEthernet1/0/22",
                            "apName": "SJC18-Legacy-3702-1"
                        }
                    ],
                    "__timestamp": 1682316300000
                },
                "time": 1682316300000
            }
        ],
        "page": null,
        "scope": null,
        "instrumentation": null,
        "message": null
    }
})