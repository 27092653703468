define({
"Template": {
  "version": "1.0",
  "response": {
    "id": "552ad62b-e92d-463f-9f01-3c8c71ac93de",
    "name": "ap_down",
    "enabled": true,
    "flattened": true,
    "entityType": "network_device",
    "entityName": "Unknown",
    "entity": "10:B3:C6:24:9E:60",
    "groupBy": "Unknown",
    "category": "Availability",
    "severity": "MEDIUM",
    "summary": "AP \"DeviceNamePlaceHolder\" disconnected from WLC.",
    "groupId": "ap_down_grouping",
    "groupName": "AP went down",
    "scope": "GLOBAL",
    "priority": "P2",
    "rootCause": "Unknown",
    "timestamp": 1614677396250,
    "description": "The AP \"DeviceNamePlaceHolder\" is CAPWAP disconnected from WLC and is no longer joined to it. The WLC has missed the AP’s CAPWAP heartbeat message. At the time, AP was connected to switch \"BLD2-FLR2-ACCESS\" - port \"GigabitEthernet1/0/14\"",
    "suggestions": [
      {
        "message": "Check the CDP neighbors command on the switch \"DeviceNamePlaceHolder\" port \"GigabitEthernet1/0/14\" to verify if the AP is still up, connected to the switch and the AP hostname has not changed. If the AP hostname has reset to the default value (in the form AP{mac-address}), this indicates that the AP has lost its configuration.",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check the CDP neighbors on the switch",
            "command": "show cdp neighbors",
            "stepType": "command-Runner",
            "runButton": null
          }
        ]
      },
      {
        "message": "Check the CDP neighbors detail command on the switch \"BLD2-FLR2-ACCESS\" port \"GigabitEthernet1/0/14\" to verify if the AP has a valid IP address. If it doesn't have an IP address, check the DHCP server.",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check the CDP neighbors details on the switch",
            "command": "show cdp neighbor GigabitEthernet5/0/6 detail",
            "stepType": "command-Runner",
            "runButton": null
          }
        ]
      },
      {
        "message": "Check whether PoE is enabled on the switch \"BLD2-FLR2-ACCESS\" port \"GigabitEthernet1/0/14\".",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check whether PoE is enabled",
            "command": "show power inline GigabitEthernet5/0/6",
            "stepType": "command-Runner",
            "runButton": null
          }
        ]
      },
      {
        "message": "Check the condition of the copper cable by running TDR (Time Domain Reflector) test on the switch \"BLD2-FLR2-ACCESS\" port \"GigabitEthernet1/0/14\". (Warning: Running this step might be disruptive to the connection)",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Start the TDR test, check the result in subsequent steps. (Warning: This command might fail for some interface types)",
            "command": "test cable-diagnostics tdr interface GigabitEthernet5/0/6",
            "stepType": "command-Runner",
            "runButton": null
          }
        ]
      },
      {
        "message": "Check the result of TDR (Time Domain Reflector) test. (Warning: Please wait for a few seconds before checking the results)",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check the result of the TDR test on the switch",
            "command": "show cable-diagnostics tdr interface GigabitEthernet5/0/6",
            "stepType": "command-Runner",
            "runButton": null
          }
        ]
      },
      {
        "message": "Use the Path Trace tool to verify whether the AP can communicate with the WLC via UDP ports: 5246 & 5247 (CAPWAP)."
      },
      {
        "message": "Reboot the AP so it can rejoin the WLC."
      },
      {
        "message": "Contact Cisco TAC World Wide – https://www.cisco.com/c/en/us/support/web/tsd-cisco-worldwide-contacts.html"
      }
    ],
    "additionalParams": [
      {
        "key": "_name",
        "value": "ap_down"
      },
      {
        "key": "sourceDeviceType",
        "value": "2"
      },
      {
        "key": "_entity_type",
        "value": "NetworkDevice"
      },
      {
        "key": "eventSource",
        "value": "Network Device"
      },
      {
        "key": "type",
        "value": "issue"
      },
      {
        "key": "portId",
        "value": ""
      },
      {
        "key": "_key",
        "value": "NetworkDevice:10:B3:C6:24:9E:60"
      },
      {
        "key": "uuid",
        "value": "24b529a9-bd4b-4679-bfe9-3ddbf3a88bc5"
      },
      {
        "key": "apName",
        "value": "9120_1"
      },
      {
        "key": "floorId",
        "value": "5783af77-ddec-4a3e-be80-49eec2a748fe"
      },
      {
        "key": "_count",
        "value": "1"
      },
      {
        "key": "areaName",
        "value": "Global/San Jose"
      },
      {
        "key": "switchName",
        "value": ""
      },
      {
        "key": "scope",
        "value": "global"
      },
      {
        "key": "_primary_key",
        "value": "NetworkDevice:10:B3:C6:24:9E:60"
      },
      {
        "key": "floorName",
        "value": "Global/San Jose/SCJ06/Floor 1"
      },
      {
        "key": "siteType",
        "value": "floor"
      },
      {
        "key": "deviceType",
        "value": "Cisco Catalyst 9120AXI Unified Access Point"
      },
      {
        "key": "severity",
        "value": "MEDIUM"
      },
      {
        "key": "deviceUuid",
        "value": "65637617-c868-42d1-83f7-97174d621b14"
      },
      {
        "key": "deviceRole",
        "value": "Access Point"
      },
      {
        "key": "_suppression",
        "value": "3600"
      },
      {
        "key": "deviceOS",
        "value": "17.4.1.6"
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "nwDeviceName",
        "value": "9120_1"
      },
      {
        "key": "_eventTime",
        "value": "1614677396250"
      },
      {
        "key": "_mostRecent",
        "value": "1614677396250"
      },
      {
        "key": "entityId",
        "value": "10:B3:C6:24:9E:60"
      },
      {
        "key": "eventUniqueId",
        "value": "9120_1"
      },
      {
        "key": "apMac",
        "value": "10:B3:C6:24:9E:60"
      },
      {
        "key": "deviceFamily",
        "value": "Unified AP"
      },
      {
        "key": "priority",
        "value": "P2"
      },
      {
        "key": "buildingId",
        "value": "a016a29d-6941-4750-8d00-c836a0d7348e"
      },
      {
        "key": "buildingName",
        "value": "Global/San Jose/SCJ06"
      },
      {
        "key": "macAddress",
        "value": "10:B3:C6:24:9E:60"
      },
      {
        "key": "areaId",
        "value": "bde99fe8-696a-4540-8858-9169bcb96eab"
      },
      {
        "key": "siteHierarchy",
        "value": "Global/San Jose/SCJ06/Floor 1"
      },
      {
        "key": "siteId",
        "value": "5783af77-ddec-4a3e-be80-49eec2a748fe"
      },
      {
        "key": "deviceModel",
        "value": "FGL2332L7MJ"
      },
      {
        "key": "category",
        "value": "availability"
      },
      {
        "key": "timestring",
        "value": "2021-03-02T09:29:56.250+0000"
      },
      {
        "key": "status",
        "value": "active"
      },
      {
        "key": "apStatus",
        "value": "AP_DOWN"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  },
  "poorRFImpactedInfo": {
    "apCount": 0.0,
    "apSlotCount": 0,
    "impactedClientCount": 0,
    "globalIssueIdForSingleIssue": null,
    "impactedApPercentageForGlobal": 0.0
  }
}
})