define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimDesignData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimVerifyData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/swim/SimSwimData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions'

], function (SimDesignData, SimLokiDatabaseActions,SimTaskDataGenerator,SimVerifyData,SimSwimData, UtilityFunctions) {

    return {
        init: function() {
        },

        doProcessRestRequest: function(urlAction){
            console.log(urlAction.url);
            console.log(urlAction);
            var data;
            switch(urlAction.method) {
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                case 'POST':
                    data = processPostRequest(urlAction);
                    break;

                case 'DELETE':
                    data = processDeleteRequest(urlAction);
                    break;


                default:
                    break;
            }

            return data;
        }
    };


    function processGetRequest(urlAction){

       var id = urlAction.action.id;

        if(urlAction.service.indexOf('validation')>=0 && urlAction.service.indexOf('validator-binding')>=0 && id!=undefined && id.length>0) {
            var  jObj = SimLokiDatabaseActions.getFilteredRecordHandler("validation-base-operation", {'namespaceUuid' : id});
            var   operationList = jObj[0].operations;
            var operationUuidList =[];

            for(var i=0;i<operationList.length;i++) {
                    operationUuidList.push(operationList[i].operationUuid);
            }

            var  jObj = SimLokiDatabaseActions.getFilteredRecordHandler("validation-options", { 'operationUuid' : { '$in' : operationUuidList } });
            var   data = jObj && jObj.length ? jObj : [];
            console.log(data);

        return data;
        }
        if(urlAction.service.indexOf('validation')>=0 && urlAction.service.indexOf('registration')>=0 && (id==undefined || id.length==0)) {

            var  jObj = SimLokiDatabaseActions.getFilteredRecordHandler("validation-base-config", {'namespaceName' : urlAction.filter['namespaceName']});
            var   data = jObj && jObj.length ? jObj : [];
            console.log(data);

        return data;
        }

        if(urlAction.service.indexOf('validation')>=0 && urlAction.service.indexOf('registration')>=0 && id!=undefined && id.length>0) {
            var  jObj = SimLokiDatabaseActions.getFilteredRecordHandler("validation-base-operation", {'namespaceUuid' : id});

        return jObj[0].operations;
        }

        if(urlAction.filter['namespace'] && urlAction.filter['namespace']=='image-management' && 
            urlAction.filter['operationName'] && urlAction.filter['operationName']=='upgrade-analysis') {
            //on clicking outdated image from provision home page
            return SimSwimData.getOutdatedInformation(urlAction);
        }

         if(urlAction.service.indexOf('validation')>=0 && urlAction.service.indexOf('result')>=0) {

           var taskId = urlAction.filter['parentTaskUuId'];
           if(taskId!=undefined) {
                //part of SWIM - OS upgrade task details section. Not sure if it is used elsewhere too..
                //on clicking In Progress or See Details on Success
                var records =  SimLokiDatabaseActions.getFilteredRecordHandler("validation-result", {'parentTaskUuid' : taskId});
                //if(records && records.length>0 && records[0].createdTime+UtilityFunctions.getMinuteToMilliSeconds(3) > UtilityFunctions.getTimeStamp()) {
                if(records && records.length>0) {                    
                    records = records.filter(e=> UtilityFunctions.getTimeStamp() > e.simCompletedTime);
                }
                return records;
           }
           var operationName =urlAction.filter['operationName'];
           var id =urlAction.action.id;

           if(id!=undefined) {
               var  jObj = SimLokiDatabaseActions.getFilteredRecordHandler("validation-base-operation", {'namespaceUuid' : id});

               //temporary fix for image task status
                if(jObj.length==0 && urlAction.service.indexOf('diff')>-1) {
                    return [];
                }

               var operationList =jObj[0].operations;
               var operationUuid ;

               for(var i=0;i<operationList.length;i++) {
                 if(operationList[i].operationName ==operationName) {
                     operationUuid =operationList[i].operationUuid;
                 }
               }
               var validatorUuidList=[];
               var validationOptionList = SimLokiDatabaseActions.getFilteredRecordHandler("validation-options", {'operationUuid' : operationUuid});

               for(var i=0;i<validationOptionList.length;i++) {
                  validatorUuidList.push(validationOptionList[i].validatorUuid);
               }
              return SimLokiDatabaseActions.getFilteredRecordHandler("validation-result", { 'validatorUuid' : { '$in' : validatorUuidList } });
           }

        }


    }

    function processPostRequest(urlAction){

       if(urlAction.service.indexOf('validation')>=0 && urlAction.service.indexOf('context-validator')>=0) {
         var result =  SimVerifyData.verifyProcess(urlAction);
          return result;
        }

    }

    function processDeleteRequest(urlAction){

    }
});
