define({
     "ACAControllerService_Template": {
      "multiDnacFeatureEnabled": false,
      "multiDnacRole": "AUTHOR",
      "acaIseCompatibility": [
        "MULTI_DNAC",
        "MULTI_DNAC_TRANSIT",
        "BASE"
      ],
      "dnacDeployment": "STANDALONE",
      "iseAvailabilityStatus": "AVAILABLE",
      "pxGridNodeStatus": "AVAILABLE",
      "workflow": {
        "importStatus": "COMPLETED",
        "selectedMode": "ACA",
        "message": "",
        "lastUpdatedTime": "2022-05-20T04:51:11.572Z"
      },
      "iseVersion": {
        "releaseVersion": "3.1.0.518",
        "patchVersion": "0"
      },
      "requiredIseVersions": [
        {
          "releaseVersion": "2.4.0.357",
          "patchVersion": "7"
        },
        {
          "releaseVersion": "2.6.0.156",
          "patchVersion": "1"
        }
      ],
      "lastUpdateTime": "2022-05-20T14:05:44.433124Z",
      "syncStatusFlag": {
        "enableRuntimeSync": true,
        "syncRuntimeInProgress": false
      },
      "clusterReadyToPromote": false
    },
    "ACAControllerService_Template_1": {
      "dnaAuthor": true,
      "iseVersion": "",
      "patchVersion": "",
      "minIseVersion": "2.4.0.357",
      "minIsePatchVersion": "7 / 2.6.0.156 and patch 1",
      "acaCompatibleWithIse": false,
      "iseAvailabilityStatus": "UNAVAILABLE",
      "pxGridNodeStatus": "UNAVAILABLE"
    },
      "ACAControllerServiceStatus_Template": {
          "instanceUuid": "4c106b17-7691-4490-bbfa-f4dee7ecee35",
          "instanceId": 1187186,
          "authEntityId": 1187186,
          "displayName": "1187186",
          "authEntityClass": 2073750444,
          "instanceTenantId": "SYS0",
          "_orderedListOEIndex": 0,
          "_orderedListOEAssocName": null,
          "_creationOrderIndex": 0,
          "_isBeingChanged": false,
          "deployPending": "NONE",
          "instanceOrigin": null,
          "lazyLoadedEntities": null,
          "instanceVersion": 0,
          "importStatus": "REQUIRED_DAY_0",
          "lastUpdatedTime": 1566315837657,
          "message": "",
          "selectedMode": "UNKNOWN",
          "internalKey": {
            "type": "AcaWorkflowTable",
            "id": 1187186,
            "longType": "com.cisco.apicem.aca.sync.model.entity.AcaWorkflowTable",
            "url": "../../AcaWorkflowTable/1187186"
          }
      },
      "GBACConfigurationStatus_Template": {
        "instanceUuid": "effd3431-b403-41f6-b9e6-3621eaee89c5",
        "instanceId": 122122,
        "authEntityId": 122122,
        "displayName": "122122",
        "authEntityClass": 2073750444,
        "instanceTenantId": "SYS0",
        "_orderedListOEIndex": 0,
        "_orderedListOEAssocName": null,
        "_creationOrderIndex": 0,
        "_isBeingChanged": false,
        "deployPending": "NONE",
        "instanceOrigin": null,
        "lazyLoadedEntities": null,
        "instanceVersion": 3,
        "importStatus": "COMPLETED_ACK",
        "lastUpdatedTime": 1561826697930,
        "message": "",
        "selectedMode": "ACA",
        "internalKey": {
          "type": "AcaWorkflowTable",
          "id": 122122,
          "longType": "com.cisco.apicem.aca.sync.model.entity.AcaWorkflowTable",
          "url": "../../AcaWorkflowTable/122122"
        }
      },
      "GBACConfigurationScalableGroup_Template": {
        "migrationLogCount": 6,
        "migrationLogList": [
          {
            "instanceUuid": "4fab94d8-3765-403b-850e-cbc4c0266692",
            "instanceId": 200205,
            "authEntityId": 200205,
            "displayName": "ScalableGroup",
            "authEntityClass": -1247620393,
            "instanceTenantId": "5d16b24717731500c8655d93",
            "_orderedListOEIndex": 0,
            "_orderedListOEAssocName": null,
            "_creationOrderIndex": 0,
            "_isBeingChanged": false,
            "deployPending": "NONE",
            "instanceOrigin": null,
            "lazyLoadedEntities": null,
            "instanceVersion": 0,
            "entityId": "1",
            "entityName": "ScalableGroup",
            "iseInstanceUuid": "",
            "lastUpdatedTime": 1561826631931,
            "level": "INFO",
            "message": "Created 6 new ScalableGroup entities in Cisco DNA Center",
            "internalKey": {
              "type": "AcaReportTable",
              "id": 200205,
              "longType": "com.cisco.apicem.aca.sync.model.entity.AcaReportTable",
              "url": "../../AcaReportTable/200205"
            }
          },
          {
            "instanceUuid": "53b3ab17-a33d-4c76-9e2f-087c4db51408",
            "instanceId": 200204,
            "authEntityId": 200204,
            "displayName": "Test_Servers",
            "authEntityClass": -1247620393,
            "instanceTenantId": "SYS0",
            "_orderedListOEIndex": 0,
            "_orderedListOEAssocName": null,
            "_creationOrderIndex": 0,
            "_isBeingChanged": false,
            "deployPending": "NONE",
            "instanceOrigin": null,
            "lazyLoadedEntities": null,
            "instanceVersion": 0,
            "entityId": "1",
            "entityName": "ScalableGroup",
            "iseInstanceUuid": "7e8aa410-2e1b-11e8-bd6e-005056b83f27",
            "lastUpdatedTime": 1561826629149,
            "level": "INFO",
            "message": "ScalableGroup name overwritten to Finance_Server",
            "internalKey": {
              "type": "AcaReportTable",
              "id": 200204,
              "longType": "com.cisco.apicem.aca.sync.model.entity.AcaReportTable",
              "url": "../../AcaReportTable/200204"
            }
          },
          {
            "instanceUuid": "f94700ee-7187-44ba-a166-7f263442d4f2",
            "instanceId": 200203,
            "authEntityId": 200203,
            "displayName": "Intranet",
            "authEntityClass": -1247620393,
            "instanceTenantId": "SYS0",
            "_orderedListOEIndex": 0,
            "_orderedListOEAssocName": null,
            "_creationOrderIndex": 0,
            "_isBeingChanged": false,
            "deployPending": "NONE",
            "instanceOrigin": null,
            "lazyLoadedEntities": null,
            "instanceVersion": 0,
            "entityId": "1",
            "entityName": "ScalableGroup",
            "iseInstanceUuid": "be280760-32f9-11e8-bd6e-005056b83f27",
            "lastUpdatedTime": 1561826629149,
            "level": "INFO",
            "message": "ScalableGroup name overwritten to Test_Servers",
            "internalKey": {
              "type": "AcaReportTable",
              "id": 200203,
              "longType": "com.cisco.apicem.aca.sync.model.entity.AcaReportTable",
              "url": "../../AcaReportTable/200203"
            }
          },
          {
            "instanceUuid": "9e8b38e8-5209-477a-b372-10eba64df0c4",
            "instanceId": 200202,
            "authEntityId": 200202,
            "displayName": "Extranet",
            "authEntityClass": -1247620393,
            "instanceTenantId": "SYS0",
            "_orderedListOEIndex": 0,
            "_orderedListOEAssocName": null,
            "_creationOrderIndex": 0,
            "_isBeingChanged": false,
            "deployPending": "NONE",
            "instanceOrigin": null,
            "lazyLoadedEntities": null,
            "instanceVersion": 0,
            "entityId": "1",
            "entityName": "ScalableGroup",
            "iseInstanceUuid": "32047010-5e01-11e8-a04e-005056b83f27",
            "lastUpdatedTime": 1561826629149,
            "level": "INFO",
            "message": "ScalableGroup name overwritten to HVAC",
            "internalKey": {
              "type": "AcaReportTable",
              "id": 200202,
              "longType": "com.cisco.apicem.aca.sync.model.entity.AcaReportTable",
              "url": "../../AcaReportTable/200202"
            }
          },
          {
            "instanceUuid": "7c772c77-e3a5-4651-b8ea-6b3de058d228",
            "instanceId": 200201,
            "authEntityId": 200201,
            "displayName": "Test_Servers",
            "authEntityClass": -1247620393,
            "instanceTenantId": "5d16b24717731500c8655d93",
            "_orderedListOEIndex": 0,
            "_orderedListOEAssocName": null,
            "_creationOrderIndex": 0,
            "_isBeingChanged": false,
            "deployPending": "NONE",
            "instanceOrigin": null,
            "lazyLoadedEntities": null,
            "instanceVersion": 0,
            "entityId": "1",
            "entityName": "ScalableGroup",
            "iseInstanceUuid": "be280760-32f9-11e8-bd6e-005056b83f27",
            "lastUpdatedTime": 1561826624540,
            "level": "INFO",
            "message": "ScalableGroup name overwritten to Test_Servers_DNA",
            "internalKey": {
              "type": "AcaReportTable",
              "id": 200201,
              "longType": "com.cisco.apicem.aca.sync.model.entity.AcaReportTable",
              "url": "../../AcaReportTable/200201"
            }
          },
          {
            "instanceUuid": "586ff9d9-3a03-4d1d-96d3-584521670770",
            "instanceId": 200200,
            "authEntityId": 200200,
            "displayName": "Test_Servers",
            "authEntityClass": -1247620393,
            "instanceTenantId": "5d16b24717731500c8655d93",
            "_orderedListOEIndex": 0,
            "_orderedListOEAssocName": null,
            "_creationOrderIndex": 0,
            "_isBeingChanged": false,
            "deployPending": "NONE",
            "instanceOrigin": null,
            "lazyLoadedEntities": null,
            "instanceVersion": 0,
            "entityId": "1",
            "entityName": "ScalableGroup",
            "iseInstanceUuid": "be280760-32f9-11e8-bd6e-005056b83f27",
            "lastUpdatedTime": 1561826624536,
            "level": "WARN",
            "message": "ScalableGroup name conflicts with existing ScalableGroup in Cisco DNA Center",
            "internalKey": {
              "type": "AcaReportTable",
              "id": 200200,
              "longType": "com.cisco.apicem.aca.sync.model.entity.AcaReportTable",
              "url": "../../AcaReportTable/200200"
            }
          }
        ]
      },
      "GBACConfigurationContracts_Template":{
          "migrationLogCount": 1,
          "migrationLogList": [
            {
              "instanceUuid": "bdd0467f-0016-437b-8c01-11470cf30f5e",
              "instanceId": 200206,
              "authEntityId": 200206,
              "displayName": "Contract",
              "authEntityClass": -1247620393,
              "instanceTenantId": "5d16b24717731500c8655d93",
              "_orderedListOEIndex": 0,
              "_orderedListOEAssocName": null,
              "_creationOrderIndex": 0,
              "_isBeingChanged": false,
              "deployPending": "NONE",
              "instanceOrigin": null,
              "lazyLoadedEntities": null,
              "instanceVersion": 0,
              "entityId": "2",
              "entityName": "Contract",
              "iseInstanceUuid": "",
              "lastUpdatedTime": 1561826641040,
              "level": "INFO",
              "message": "Created 1 new Contract entities in Cisco DNA Center",
              "internalKey": {
                "type": "AcaReportTable",
                "id": 200206,
                "longType": "com.cisco.apicem.aca.sync.model.entity.AcaReportTable",
                "url": "../../AcaReportTable/200206"
              }
            }
          ]
        },
      "GBACConfigurationPolicies_Template":{
          "migrationLogCount": 1,
          "migrationLogList": [
            {
              "instanceUuid": "c9c86054-e2f3-428a-9877-11f9875754d9",
              "instanceId": 200207,
              "authEntityId": 200207,
              "displayName": "GroupBasedPolicy",
              "authEntityClass": -1247620393,
              "instanceTenantId": "5d16b24717731500c8655d93",
              "_orderedListOEIndex": 0,
              "_orderedListOEAssocName": null,
              "_creationOrderIndex": 0,
              "_isBeingChanged": false,
              "deployPending": "NONE",
              "instanceOrigin": null,
              "lazyLoadedEntities": null,
              "instanceVersion": 0,
              "entityId": "3",
              "entityName": "GroupBasedPolicy",
              "iseInstanceUuid": "",
              "lastUpdatedTime": 1561826647040,
              "level": "INFO",
              "message": "Created 9 new GroupBasedPolicy entities in Cisco DNA Center",
              "internalKey": {
                "type": "AcaReportTable",
                "id": 200207,
                "longType": "com.cisco.apicem.aca.sync.model.entity.AcaReportTable",
                "url": "../../AcaReportTable/200207"
              }
            }
          ]
        }
});