define({
    "VIRTUAL_ACCOUNTS": [
        {
          "name": "Enterprise Marketing",
          "description": "Enterprise Marketing",
          "dnaLicenseCount": 100,
          "isSelected": false,
          "parentSmartAccount": 4395615917926549748,
          "id": "83081",
          "vaCSSMId": "105801"
        },
        {
          "name": "EFT FIELD SEVT",
          "description": "EFT FIELD SEVT DEMO",
          "dnaLicenseCount": 955,
          "isSelected": true,
          "parentSmartAccount": 4395615917926549748,
          "id": "83082",
          "vaCSSMId": "105810"
        },
        {
          "name": "CVD",
          "description": "CVD",
          "dnaLicenseCount": 1620,
          "isSelected": false,
          "parentSmartAccount": 4395615917926549748,
          "id": "83083",
          "vaCSSMId": "105820"
        },
        {
          "name": "Catalyst 9K POD 9",
          "description": null,
          "dnaLicenseCount": 30,
          "isSelected": false,
          "parentSmartAccount": 4395615917926549748,
          "id": "83084",
          "vaCSSMId": "105830"
        },
        {
          "name": "Catalyst 9K POD 8",
          "description": null,
          "dnaLicenseCount": 30,
          "isSelected": false,
          "parentSmartAccount": 4395615917926549748,
          "id": "83085",
          "vaCSSMId": "105840"
        },
        {
          "name": "Catalyst 9K POD 7",
          "description": null,
          "dnaLicenseCount": 30,
          "isSelected": false,
          "parentSmartAccount": 4395615917926549748,
          "id": "83086",
          "vaCSSMId": "105850"
        },
        {
          "name": "Catalyst 9K POD 6",
          "description": null,
          "dnaLicenseCount": 30,
          "isSelected": false,
          "parentSmartAccount": 4395615917926549748,
          "id": "83087",
          "vaCSSMId": "105860"
        },
        {
          "name": "Catalyst 9K POD 5",
          "description": null,
          "dnaLicenseCount": 30,
          "isSelected": false,
          "parentSmartAccount": 4395615917926549748,
          "id": "83088",
          "vaCSSMId": "105870"
        },
        {
          "name": "Catalyst 9K POD 4",
          "description": null,
          "dnaLicenseCount": 30,
          "isSelected": false,
          "parentSmartAccount": 4395615917926549748,
          "id": "83089",
          "vaCSSMId": "105880"
        },
        {
          "name": "Catalyst 9K POD 3",
          "description": null,
          "dnaLicenseCount": 30,
          "isSelected": false,           
          "parentSmartAccount": 4395615917926549748,
          "id": "83090",
          "vaCSSMId": "105890"
        },
        {
          "name": "Catalyst 9K POD 2",
          "description": null,
          "dnaLicenseCount": 30,
          "isSelected": false,
          "parentSmartAccount": 4395615917926549748,
          "id": "83091",
          "vaCSSMId": "105900"
        },
        {
          "name": "Catalyst 9K POD 10",
          "description": null,
          "dnaLicenseCount": 30,
          "isSelected": false,
          "parentSmartAccount": 4395615917926549748,
          "id": "83092",
          "vaCSSMId": "105910"
        },
        {
          "name": "Catalyst 9K POD 1",
          "description": null,
          "dnaLicenseCount": 30,
          "isSelected": false,
          "parentSmartAccount": 4395615917926549748,
          "id": "83093",
          "vaCSSMId": "105920"
        }
    ]
});