define({
  "Topology_Host_Template": {
    id: "",
    deviceType: "wired",
    label: "",
    ip: "",
    nodeType: "HOST",
    family: "",
    role: "HOST",
    customParam: {}
  }
});
