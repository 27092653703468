define({
    "COMBINED_DNS":{
        "version": "1.0",
        "response": {
          "detailList": [
            {
              "breakdown": null,
              "bucket": "current",
              "value1": null,
              "value2": null,
              "scoreList": [
                {
                  "breakdown": "request per min",
                  "bucket": "2019-03-14T04:42:00.000+0000",
                  "value1": 35.8,
                  "value2": 179,
                  "scoreList": []
                },
                {
                  "breakdown": "response per min",
                  "bucket": "2019-03-14T04:42:00.000+0000",
                  "value1": 35.8,
                  "value2": 179,
                  "scoreList": []
                }
              ]
            },
            {
              "breakdown": null,
              "bucket": "request/response timeseries",
              "value1": null,
              "value2": null,
              "scoreList": [
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T04:35:00.000+0000",
                  "value1": 1823,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T04:35:00.000+0000",
                  "value1": 1823,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T04:40:00.000+0000",
                  "value1": 229,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T04:40:00.000+0000",
                  "value1": 229,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T04:45:00.000+0000",
                  "value1": 224,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T04:45:00.000+0000",
                  "value1": 224,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T04:50:00.000+0000",
                  "value1": 1827,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T04:50:00.000+0000",
                  "value1": 1827,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T04:55:00.000+0000",
                  "value1": 156,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T04:55:00.000+0000",
                  "value1": 156,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T05:00:00.000+0000",
                  "value1": 146,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T05:00:00.000+0000",
                  "value1": 146,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T05:05:00.000+0000",
                  "value1": 175,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T05:05:00.000+0000",
                  "value1": 175,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T05:10:00.000+0000",
                  "value1": 601,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T05:10:00.000+0000",
                  "value1": 601,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T05:15:00.000+0000",
                  "value1": 1871,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T05:15:00.000+0000",
                  "value1": 1871,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T05:20:00.000+0000",
                  "value1": 1762,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T05:20:00.000+0000",
                  "value1": 1762,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T05:25:00.000+0000",
                  "value1": 192,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T05:25:00.000+0000",
                  "value1": 192,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T05:30:00.000+0000",
                  "value1": 1742,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T05:30:00.000+0000",
                  "value1": 1742,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T05:35:00.000+0000",
                  "value1": 158,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T05:35:00.000+0000",
                  "value1": 158,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T05:40:00.000+0000",
                  "value1": 226,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T05:40:00.000+0000",
                  "value1": 226,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T05:45:00.000+0000",
                  "value1": 253,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T05:45:00.000+0000",
                  "value1": 253,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T05:50:00.000+0000",
                  "value1": 278,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T05:50:00.000+0000",
                  "value1": 278,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T05:55:00.000+0000",
                  "value1": 303,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T05:55:00.000+0000",
                  "value1": 303,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T06:00:00.000+0000",
                  "value1": 165,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T06:00:00.000+0000",
                  "value1": 165,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T06:05:00.000+0000",
                  "value1": 146,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T06:05:00.000+0000",
                  "value1": 146,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T06:10:00.000+0000",
                  "value1": 241,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T06:10:00.000+0000",
                  "value1": 241,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T06:15:00.000+0000",
                  "value1": 231,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T06:15:00.000+0000",
                  "value1": 231,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T06:20:00.000+0000",
                  "value1": 2226,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T06:20:00.000+0000",
                  "value1": 2226,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T06:25:00.000+0000",
                  "value1": 150,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T06:25:00.000+0000",
                  "value1": 150,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T06:30:00.000+0000",
                  "value1": 165,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T06:30:00.000+0000",
                  "value1": 165,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T06:35:00.000+0000",
                  "value1": 172,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T06:35:00.000+0000",
                  "value1": 172,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T06:40:00.000+0000",
                  "value1": 194,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T06:40:00.000+0000",
                  "value1": 194,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T06:45:00.000+0000",
                  "value1": 315,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T06:45:00.000+0000",
                  "value1": 315,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T06:50:00.000+0000",
                  "value1": 337,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T06:50:00.000+0000",
                  "value1": 337,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T06:55:00.000+0000",
                  "value1": 2294,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T06:55:00.000+0000",
                  "value1": 2294,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T07:00:00.000+0000",
                  "value1": 186,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T07:00:00.000+0000",
                  "value1": 186,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T07:05:00.000+0000",
                  "value1": 164,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T07:05:00.000+0000",
                  "value1": 164,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T07:10:00.000+0000",
                  "value1": 244,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T07:10:00.000+0000",
                  "value1": 244,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T07:15:00.000+0000",
                  "value1": 247,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T07:15:00.000+0000",
                  "value1": 247,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T07:20:00.000+0000",
                  "value1": 186,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T07:20:00.000+0000",
                  "value1": 186,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T07:25:00.000+0000",
                  "value1": 362,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T07:25:00.000+0000",
                  "value1": 362,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T07:30:00.000+0000",
                  "value1": 323,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T07:30:00.000+0000",
                  "value1": 323,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T07:35:00.000+0000",
                  "value1": 161,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T07:35:00.000+0000",
                  "value1": 161,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T07:40:00.000+0000",
                  "value1": 226,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T07:40:00.000+0000",
                  "value1": 226,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T07:45:00.000+0000",
                  "value1": 267,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T07:45:00.000+0000",
                  "value1": 267,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T07:50:00.000+0000",
                  "value1": 303,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T07:50:00.000+0000",
                  "value1": 303,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T07:55:00.000+0000",
                  "value1": 176,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T07:55:00.000+0000",
                  "value1": 176,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T08:00:00.000+0000",
                  "value1": 132,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T08:00:00.000+0000",
                  "value1": 132,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T08:05:00.000+0000",
                  "value1": 164,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T08:05:00.000+0000",
                  "value1": 164,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T08:10:00.000+0000",
                  "value1": 178,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T08:10:00.000+0000",
                  "value1": 178,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T08:15:00.000+0000",
                  "value1": 220,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T08:15:00.000+0000",
                  "value1": 220,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T08:20:00.000+0000",
                  "value1": 169,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T08:20:00.000+0000",
                  "value1": 169,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T08:25:00.000+0000",
                  "value1": 165,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T08:25:00.000+0000",
                  "value1": 165,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T08:30:00.000+0000",
                  "value1": 178,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T08:30:00.000+0000",
                  "value1": 178,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T08:35:00.000+0000",
                  "value1": 116,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T08:35:00.000+0000",
                  "value1": 116,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T08:40:00.000+0000",
                  "value1": 189,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T08:40:00.000+0000",
                  "value1": 189,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T08:45:00.000+0000",
                  "value1": 233,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T08:45:00.000+0000",
                  "value1": 233,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T08:50:00.000+0000",
                  "value1": 270,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T08:50:00.000+0000",
                  "value1": 270,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T08:55:00.000+0000",
                  "value1": 272,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T08:55:00.000+0000",
                  "value1": 272,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T09:00:00.000+0000",
                  "value1": 286,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T09:00:00.000+0000",
                  "value1": 286,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T09:05:00.000+0000",
                  "value1": 134,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T09:05:00.000+0000",
                  "value1": 134,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T09:10:00.000+0000",
                  "value1": 182,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T09:10:00.000+0000",
                  "value1": 182,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T09:15:00.000+0000",
                  "value1": 230,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T09:15:00.000+0000",
                  "value1": 230,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T09:20:00.000+0000",
                  "value1": 196,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T09:20:00.000+0000",
                  "value1": 196,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T09:25:00.000+0000",
                  "value1": 168,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T09:25:00.000+0000",
                  "value1": 168,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T09:30:00.000+0000",
                  "value1": 130,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T09:30:00.000+0000",
                  "value1": 130,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T09:35:00.000+0000",
                  "value1": 137,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T09:35:00.000+0000",
                  "value1": 137,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T09:40:00.000+0000",
                  "value1": 180,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T09:40:00.000+0000",
                  "value1": 180,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T09:45:00.000+0000",
                  "value1": 238,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T09:45:00.000+0000",
                  "value1": 238,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T09:50:00.000+0000",
                  "value1": 288,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T09:50:00.000+0000",
                  "value1": 288,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T09:55:00.000+0000",
                  "value1": 173,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T09:55:00.000+0000",
                  "value1": 173,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T10:00:00.000+0000",
                  "value1": 271,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T10:00:00.000+0000",
                  "value1": 271,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T10:05:00.000+0000",
                  "value1": 141,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T10:05:00.000+0000",
                  "value1": 141,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T10:10:00.000+0000",
                  "value1": 175,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T10:10:00.000+0000",
                  "value1": 175,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T10:15:00.000+0000",
                  "value1": 252,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T10:15:00.000+0000",
                  "value1": 252,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T10:20:00.000+0000",
                  "value1": 3225,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T10:20:00.000+0000",
                  "value1": 3225,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T10:25:00.000+0000",
                  "value1": 160,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T10:25:00.000+0000",
                  "value1": 160,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T10:30:00.000+0000",
                  "value1": 168,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T10:30:00.000+0000",
                  "value1": 168,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T10:35:00.000+0000",
                  "value1": 125,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T10:35:00.000+0000",
                  "value1": 125,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T10:40:00.000+0000",
                  "value1": 178,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T10:40:00.000+0000",
                  "value1": 178,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T10:45:00.000+0000",
                  "value1": 187,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T10:45:00.000+0000",
                  "value1": 187,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T10:50:00.000+0000",
                  "value1": 189,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T10:50:00.000+0000",
                  "value1": 189,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T10:55:00.000+0000",
                  "value1": 356,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T10:55:00.000+0000",
                  "value1": 356,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T11:00:00.000+0000",
                  "value1": 285,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T11:00:00.000+0000",
                  "value1": 285,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T11:05:00.000+0000",
                  "value1": 145,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T11:05:00.000+0000",
                  "value1": 145,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T11:10:00.000+0000",
                  "value1": 174,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T11:10:00.000+0000",
                  "value1": 174,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T11:15:00.000+0000",
                  "value1": 921,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T11:15:00.000+0000",
                  "value1": 921,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T11:20:00.000+0000",
                  "value1": 252,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T11:20:00.000+0000",
                  "value1": 252,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T11:25:00.000+0000",
                  "value1": 202,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T11:25:00.000+0000",
                  "value1": 202,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T11:30:00.000+0000",
                  "value1": 206,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T11:30:00.000+0000",
                  "value1": 206,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T11:35:00.000+0000",
                  "value1": 144,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T11:35:00.000+0000",
                  "value1": 144,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T11:40:00.000+0000",
                  "value1": 164,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T11:40:00.000+0000",
                  "value1": 164,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T11:45:00.000+0000",
                  "value1": 232,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T11:45:00.000+0000",
                  "value1": 232,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T11:50:00.000+0000",
                  "value1": 355,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T11:50:00.000+0000",
                  "value1": 355,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T11:55:00.000+0000",
                  "value1": 325,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T11:55:00.000+0000",
                  "value1": 325,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T12:00:00.000+0000",
                  "value1": 1559,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T12:00:00.000+0000",
                  "value1": 1559,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T12:05:00.000+0000",
                  "value1": 130,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T12:05:00.000+0000",
                  "value1": 130,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T12:10:00.000+0000",
                  "value1": 3697,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T12:10:00.000+0000",
                  "value1": 3697,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T12:15:00.000+0000",
                  "value1": 183,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T12:15:00.000+0000",
                  "value1": 183,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T12:20:00.000+0000",
                  "value1": 211,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T12:20:00.000+0000",
                  "value1": 211,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T12:25:00.000+0000",
                  "value1": 192,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T12:25:00.000+0000",
                  "value1": 192,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T12:30:00.000+0000",
                  "value1": 155,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T12:30:00.000+0000",
                  "value1": 155,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T12:35:00.000+0000",
                  "value1": 130,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T12:35:00.000+0000",
                  "value1": 130,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T12:40:00.000+0000",
                  "value1": 156,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T12:40:00.000+0000",
                  "value1": 156,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T12:45:00.000+0000",
                  "value1": 195,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T12:45:00.000+0000",
                  "value1": 195,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T12:50:00.000+0000",
                  "value1": 202,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T12:50:00.000+0000",
                  "value1": 202,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T12:55:00.000+0000",
                  "value1": 181,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T12:55:00.000+0000",
                  "value1": 181,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T13:00:00.000+0000",
                  "value1": 157,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T13:00:00.000+0000",
                  "value1": 157,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T13:05:00.000+0000",
                  "value1": 3839,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T13:05:00.000+0000",
                  "value1": 3839,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T13:10:00.000+0000",
                  "value1": 144,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T13:10:00.000+0000",
                  "value1": 144,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T13:15:00.000+0000",
                  "value1": 212,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T13:15:00.000+0000",
                  "value1": 212,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T13:20:00.000+0000",
                  "value1": 218,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T13:20:00.000+0000",
                  "value1": 218,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T13:25:00.000+0000",
                  "value1": 168,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T13:25:00.000+0000",
                  "value1": 168,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T13:30:00.000+0000",
                  "value1": 210,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T13:30:00.000+0000",
                  "value1": 210,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T13:35:00.000+0000",
                  "value1": 146,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T13:35:00.000+0000",
                  "value1": 146,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T13:40:00.000+0000",
                  "value1": 153,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T13:40:00.000+0000",
                  "value1": 153,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T13:45:00.000+0000",
                  "value1": 247,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T13:45:00.000+0000",
                  "value1": 247,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T13:50:00.000+0000",
                  "value1": 440,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T13:50:00.000+0000",
                  "value1": 440,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T13:55:00.000+0000",
                  "value1": 317,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T13:55:00.000+0000",
                  "value1": 317,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T14:00:00.000+0000",
                  "value1": 181,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T14:00:00.000+0000",
                  "value1": 181,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T14:05:00.000+0000",
                  "value1": 320,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T14:05:00.000+0000",
                  "value1": 320,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T14:10:00.000+0000",
                  "value1": 125,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T14:10:00.000+0000",
                  "value1": 125,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T14:15:00.000+0000",
                  "value1": 191,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T14:15:00.000+0000",
                  "value1": 191,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T14:20:00.000+0000",
                  "value1": 208,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T14:20:00.000+0000",
                  "value1": 208,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T14:25:00.000+0000",
                  "value1": 220,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T14:25:00.000+0000",
                  "value1": 220,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T14:30:00.000+0000",
                  "value1": 159,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T14:30:00.000+0000",
                  "value1": 159,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T14:35:00.000+0000",
                  "value1": 147,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T14:35:00.000+0000",
                  "value1": 147,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T14:40:00.000+0000",
                  "value1": 119,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T14:40:00.000+0000",
                  "value1": 119,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T14:45:00.000+0000",
                  "value1": 177,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T14:45:00.000+0000",
                  "value1": 177,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T14:50:00.000+0000",
                  "value1": 202,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T14:50:00.000+0000",
                  "value1": 202,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T14:55:00.000+0000",
                  "value1": 646,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T14:55:00.000+0000",
                  "value1": 646,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T15:00:00.000+0000",
                  "value1": 351,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T15:00:00.000+0000",
                  "value1": 351,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T15:05:00.000+0000",
                  "value1": 339,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T15:05:00.000+0000",
                  "value1": 339,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T15:10:00.000+0000",
                  "value1": 120,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T15:10:00.000+0000",
                  "value1": 120,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T15:15:00.000+0000",
                  "value1": 189,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T15:15:00.000+0000",
                  "value1": 189,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T15:20:00.000+0000",
                  "value1": 246,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T15:20:00.000+0000",
                  "value1": 246,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T15:25:00.000+0000",
                  "value1": 175,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T15:25:00.000+0000",
                  "value1": 175,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T15:30:00.000+0000",
                  "value1": 158,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T15:30:00.000+0000",
                  "value1": 158,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T15:35:00.000+0000",
                  "value1": 727,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T15:35:00.000+0000",
                  "value1": 727,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T15:40:00.000+0000",
                  "value1": 158,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T15:40:00.000+0000",
                  "value1": 158,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T15:45:00.000+0000",
                  "value1": 190,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T15:45:00.000+0000",
                  "value1": 190,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T15:50:00.000+0000",
                  "value1": 221,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T15:50:00.000+0000",
                  "value1": 221,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T15:55:00.000+0000",
                  "value1": 382,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T15:55:00.000+0000",
                  "value1": 382,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T16:00:00.000+0000",
                  "value1": 149,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T16:00:00.000+0000",
                  "value1": 149,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T16:05:00.000+0000",
                  "value1": 361,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T16:05:00.000+0000",
                  "value1": 361,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T16:10:00.000+0000",
                  "value1": 186,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T16:10:00.000+0000",
                  "value1": 186,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T16:15:00.000+0000",
                  "value1": 155,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T16:15:00.000+0000",
                  "value1": 155,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T16:20:00.000+0000",
                  "value1": 222,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T16:20:00.000+0000",
                  "value1": 222,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T16:25:00.000+0000",
                  "value1": 233,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T16:25:00.000+0000",
                  "value1": 233,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T16:30:00.000+0000",
                  "value1": 170,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T16:30:00.000+0000",
                  "value1": 170,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T16:35:00.000+0000",
                  "value1": 150,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T16:35:00.000+0000",
                  "value1": 150,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T16:40:00.000+0000",
                  "value1": 153,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T16:40:00.000+0000",
                  "value1": 153,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T16:45:00.000+0000",
                  "value1": 156,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T16:45:00.000+0000",
                  "value1": 156,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T16:50:00.000+0000",
                  "value1": 1725,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T16:50:00.000+0000",
                  "value1": 1725,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T16:55:00.000+0000",
                  "value1": 1763,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T16:55:00.000+0000",
                  "value1": 1763,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T17:00:00.000+0000",
                  "value1": 164,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T17:00:00.000+0000",
                  "value1": 164,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T17:05:00.000+0000",
                  "value1": 168,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T17:05:00.000+0000",
                  "value1": 168,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T17:10:00.000+0000",
                  "value1": 139,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T17:10:00.000+0000",
                  "value1": 139,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T17:15:00.000+0000",
                  "value1": 165,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T17:15:00.000+0000",
                  "value1": 165,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T17:20:00.000+0000",
                  "value1": 223,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T17:20:00.000+0000",
                  "value1": 223,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T17:25:00.000+0000",
                  "value1": 279,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T17:25:00.000+0000",
                  "value1": 279,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T17:30:00.000+0000",
                  "value1": 191,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T17:30:00.000+0000",
                  "value1": 191,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T17:35:00.000+0000",
                  "value1": 137,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T17:35:00.000+0000",
                  "value1": 137,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T17:40:00.000+0000",
                  "value1": 140,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T17:40:00.000+0000",
                  "value1": 140,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T17:45:00.000+0000",
                  "value1": 155,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T17:45:00.000+0000",
                  "value1": 155,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T17:50:00.000+0000",
                  "value1": 217,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T17:50:00.000+0000",
                  "value1": 217,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T17:55:00.000+0000",
                  "value1": 188,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T17:55:00.000+0000",
                  "value1": 188,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T18:00:00.000+0000",
                  "value1": 596,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T18:00:00.000+0000",
                  "value1": 596,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T18:05:00.000+0000",
                  "value1": 358,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T18:05:00.000+0000",
                  "value1": 358,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T18:10:00.000+0000",
                  "value1": 129,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T18:10:00.000+0000",
                  "value1": 129,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T18:15:00.000+0000",
                  "value1": 155,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T18:15:00.000+0000",
                  "value1": 155,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T18:20:00.000+0000",
                  "value1": 190,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T18:20:00.000+0000",
                  "value1": 190,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T18:25:00.000+0000",
                  "value1": 243,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T18:25:00.000+0000",
                  "value1": 243,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T18:30:00.000+0000",
                  "value1": 176,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T18:30:00.000+0000",
                  "value1": 176,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T18:35:00.000+0000",
                  "value1": 140,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T18:35:00.000+0000",
                  "value1": 140,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T18:40:00.000+0000",
                  "value1": 164,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T18:40:00.000+0000",
                  "value1": 164,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T18:45:00.000+0000",
                  "value1": 135,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T18:45:00.000+0000",
                  "value1": 135,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T18:50:00.000+0000",
                  "value1": 175,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T18:50:00.000+0000",
                  "value1": 175,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T18:55:00.000+0000",
                  "value1": 251,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T18:55:00.000+0000",
                  "value1": 251,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T19:00:00.000+0000",
                  "value1": 5397,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T19:00:00.000+0000",
                  "value1": 5397,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T19:05:00.000+0000",
                  "value1": 145,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T19:05:00.000+0000",
                  "value1": 145,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T19:10:00.000+0000",
                  "value1": 116,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T19:10:00.000+0000",
                  "value1": 116,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T19:15:00.000+0000",
                  "value1": 134,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T19:15:00.000+0000",
                  "value1": 134,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T19:20:00.000+0000",
                  "value1": 176,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T19:20:00.000+0000",
                  "value1": 176,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T19:25:00.000+0000",
                  "value1": 213,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T19:25:00.000+0000",
                  "value1": 213,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T19:30:00.000+0000",
                  "value1": 184,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T19:30:00.000+0000",
                  "value1": 184,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T19:35:00.000+0000",
                  "value1": 145,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T19:35:00.000+0000",
                  "value1": 145,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T19:40:00.000+0000",
                  "value1": 126,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T19:40:00.000+0000",
                  "value1": 126,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T19:45:00.000+0000",
                  "value1": 159,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T19:45:00.000+0000",
                  "value1": 159,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T19:50:00.000+0000",
                  "value1": 161,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T19:50:00.000+0000",
                  "value1": 161,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T19:55:00.000+0000",
                  "value1": 261,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T19:55:00.000+0000",
                  "value1": 261,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T20:00:00.000+0000",
                  "value1": 177,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T20:00:00.000+0000",
                  "value1": 177,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T20:05:00.000+0000",
                  "value1": 161,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T20:05:00.000+0000",
                  "value1": 161,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T20:10:00.000+0000",
                  "value1": 420,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T20:10:00.000+0000",
                  "value1": 420,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T20:15:00.000+0000",
                  "value1": 158,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T20:15:00.000+0000",
                  "value1": 158,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T20:20:00.000+0000",
                  "value1": 138,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T20:20:00.000+0000",
                  "value1": 138,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T20:25:00.000+0000",
                  "value1": 227,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T20:25:00.000+0000",
                  "value1": 227,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T20:30:00.000+0000",
                  "value1": 183,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T20:30:00.000+0000",
                  "value1": 183,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T20:35:00.000+0000",
                  "value1": 161,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T20:35:00.000+0000",
                  "value1": 161,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T20:40:00.000+0000",
                  "value1": 121,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T20:40:00.000+0000",
                  "value1": 121,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T20:45:00.000+0000",
                  "value1": 155,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T20:45:00.000+0000",
                  "value1": 155,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T20:50:00.000+0000",
                  "value1": 132,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T20:50:00.000+0000",
                  "value1": 132,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T20:55:00.000+0000",
                  "value1": 214,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T20:55:00.000+0000",
                  "value1": 214,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T21:00:00.000+0000",
                  "value1": 387,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T21:00:00.000+0000",
                  "value1": 387,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T21:05:00.000+0000",
                  "value1": 194,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T21:05:00.000+0000",
                  "value1": 194,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T21:10:00.000+0000",
                  "value1": 242,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T21:10:00.000+0000",
                  "value1": 242,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T21:15:00.000+0000",
                  "value1": 6208,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T21:15:00.000+0000",
                  "value1": 6208,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T21:20:00.000+0000",
                  "value1": 412,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T21:20:00.000+0000",
                  "value1": 412,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T21:25:00.000+0000",
                  "value1": 174,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T21:25:00.000+0000",
                  "value1": 174,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T21:30:00.000+0000",
                  "value1": 452,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T21:30:00.000+0000",
                  "value1": 452,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T21:35:00.000+0000",
                  "value1": 176,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T21:35:00.000+0000",
                  "value1": 176,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T21:40:00.000+0000",
                  "value1": 254,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T21:40:00.000+0000",
                  "value1": 254,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T21:45:00.000+0000",
                  "value1": 189,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T21:45:00.000+0000",
                  "value1": 189,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T21:50:00.000+0000",
                  "value1": 118,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T21:50:00.000+0000",
                  "value1": 118,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T21:55:00.000+0000",
                  "value1": 177,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T21:55:00.000+0000",
                  "value1": 177,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T22:00:00.000+0000",
                  "value1": 138,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T22:00:00.000+0000",
                  "value1": 138,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T22:05:00.000+0000",
                  "value1": 166,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T22:05:00.000+0000",
                  "value1": 166,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T22:10:00.000+0000",
                  "value1": 218,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T22:10:00.000+0000",
                  "value1": 218,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T22:15:00.000+0000",
                  "value1": 171,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T22:15:00.000+0000",
                  "value1": 171,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T22:20:00.000+0000",
                  "value1": 698,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T22:20:00.000+0000",
                  "value1": 698,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T22:25:00.000+0000",
                  "value1": 210,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T22:25:00.000+0000",
                  "value1": 210,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T22:30:00.000+0000",
                  "value1": 176,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T22:30:00.000+0000",
                  "value1": 176,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T22:35:00.000+0000",
                  "value1": 201,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T22:35:00.000+0000",
                  "value1": 201,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T22:40:00.000+0000",
                  "value1": 278,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T22:40:00.000+0000",
                  "value1": 278,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T22:45:00.000+0000",
                  "value1": 198,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T22:45:00.000+0000",
                  "value1": 198,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T22:50:00.000+0000",
                  "value1": 6332,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T22:50:00.000+0000",
                  "value1": 6332,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T22:55:00.000+0000",
                  "value1": 171,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T22:55:00.000+0000",
                  "value1": 171,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T23:00:00.000+0000",
                  "value1": 126,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T23:00:00.000+0000",
                  "value1": 126,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T23:05:00.000+0000",
                  "value1": 127,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T23:05:00.000+0000",
                  "value1": 127,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T23:10:00.000+0000",
                  "value1": 234,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T23:10:00.000+0000",
                  "value1": 234,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T23:15:00.000+0000",
                  "value1": 491,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T23:15:00.000+0000",
                  "value1": 491,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T23:20:00.000+0000",
                  "value1": 137,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T23:20:00.000+0000",
                  "value1": 137,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T23:25:00.000+0000",
                  "value1": 445,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T23:25:00.000+0000",
                  "value1": 445,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T23:30:00.000+0000",
                  "value1": 460,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T23:30:00.000+0000",
                  "value1": 460,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T23:35:00.000+0000",
                  "value1": 142,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T23:35:00.000+0000",
                  "value1": 142,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T23:40:00.000+0000",
                  "value1": 224,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T23:40:00.000+0000",
                  "value1": 224,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T23:45:00.000+0000",
                  "value1": 252,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T23:45:00.000+0000",
                  "value1": 252,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T23:50:00.000+0000",
                  "value1": 6586,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T23:50:00.000+0000",
                  "value1": 6586,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-13T23:55:00.000+0000",
                  "value1": 143,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-13T23:55:00.000+0000",
                  "value1": 143,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T00:00:00.000+0000",
                  "value1": 154,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T00:00:00.000+0000",
                  "value1": 154,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T00:05:00.000+0000",
                  "value1": 140,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T00:05:00.000+0000",
                  "value1": 140,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T00:10:00.000+0000",
                  "value1": 238,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T00:10:00.000+0000",
                  "value1": 238,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T00:15:00.000+0000",
                  "value1": 240,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T00:15:00.000+0000",
                  "value1": 240,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T00:20:00.000+0000",
                  "value1": 440,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T00:20:00.000+0000",
                  "value1": 440,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T00:25:00.000+0000",
                  "value1": 511,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T00:25:00.000+0000",
                  "value1": 511,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T00:30:00.000+0000",
                  "value1": 438,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T00:30:00.000+0000",
                  "value1": 438,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T00:35:00.000+0000",
                  "value1": 135,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T00:35:00.000+0000",
                  "value1": 135,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T00:40:00.000+0000",
                  "value1": 214,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T00:40:00.000+0000",
                  "value1": 214,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T00:45:00.000+0000",
                  "value1": 1119,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T00:45:00.000+0000",
                  "value1": 1119,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T00:50:00.000+0000",
                  "value1": 164,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T00:50:00.000+0000",
                  "value1": 164,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T00:55:00.000+0000",
                  "value1": 171,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T00:55:00.000+0000",
                  "value1": 171,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T01:00:00.000+0000",
                  "value1": 208,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T01:00:00.000+0000",
                  "value1": 208,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T01:05:00.000+0000",
                  "value1": 157,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T01:05:00.000+0000",
                  "value1": 157,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T01:10:00.000+0000",
                  "value1": 230,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T01:10:00.000+0000",
                  "value1": 230,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T01:15:00.000+0000",
                  "value1": 282,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T01:15:00.000+0000",
                  "value1": 282,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T01:20:00.000+0000",
                  "value1": 455,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T01:20:00.000+0000",
                  "value1": 455,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T01:25:00.000+0000",
                  "value1": 497,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T01:25:00.000+0000",
                  "value1": 497,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T01:30:00.000+0000",
                  "value1": 195,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T01:30:00.000+0000",
                  "value1": 195,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T01:35:00.000+0000",
                  "value1": 166,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T01:35:00.000+0000",
                  "value1": 166,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T01:40:00.000+0000",
                  "value1": 221,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T01:40:00.000+0000",
                  "value1": 221,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T01:45:00.000+0000",
                  "value1": 279,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T01:45:00.000+0000",
                  "value1": 279,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T01:50:00.000+0000",
                  "value1": 202,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T01:50:00.000+0000",
                  "value1": 202,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T01:55:00.000+0000",
                  "value1": 162,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T01:55:00.000+0000",
                  "value1": 162,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T02:00:00.000+0000",
                  "value1": 186,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T02:00:00.000+0000",
                  "value1": 186,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T02:05:00.000+0000",
                  "value1": 157,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T02:05:00.000+0000",
                  "value1": 157,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T02:10:00.000+0000",
                  "value1": 171,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T02:10:00.000+0000",
                  "value1": 171,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T02:15:00.000+0000",
                  "value1": 284,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T02:15:00.000+0000",
                  "value1": 284,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T02:20:00.000+0000",
                  "value1": 802,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T02:20:00.000+0000",
                  "value1": 802,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T02:25:00.000+0000",
                  "value1": 485,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T02:25:00.000+0000",
                  "value1": 485,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T02:30:00.000+0000",
                  "value1": 533,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T02:30:00.000+0000",
                  "value1": 533,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T02:35:00.000+0000",
                  "value1": 167,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T02:35:00.000+0000",
                  "value1": 167,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T02:40:00.000+0000",
                  "value1": 230,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T02:40:00.000+0000",
                  "value1": 230,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T02:45:00.000+0000",
                  "value1": 270,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T02:45:00.000+0000",
                  "value1": 270,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T02:50:00.000+0000",
                  "value1": 239,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T02:50:00.000+0000",
                  "value1": 239,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T02:55:00.000+0000",
                  "value1": 312,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T02:55:00.000+0000",
                  "value1": 312,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T03:00:00.000+0000",
                  "value1": 253,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T03:00:00.000+0000",
                  "value1": 253,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T03:05:00.000+0000",
                  "value1": 215,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T03:05:00.000+0000",
                  "value1": 215,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T03:10:00.000+0000",
                  "value1": 202,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T03:10:00.000+0000",
                  "value1": 202,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T03:15:00.000+0000",
                  "value1": 252,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T03:15:00.000+0000",
                  "value1": 252,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T03:20:00.000+0000",
                  "value1": 748,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T03:20:00.000+0000",
                  "value1": 748,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T03:25:00.000+0000",
                  "value1": 500,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T03:25:00.000+0000",
                  "value1": 500,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T03:30:00.000+0000",
                  "value1": 816,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T03:30:00.000+0000",
                  "value1": 816,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T03:35:00.000+0000",
                  "value1": 179,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T03:35:00.000+0000",
                  "value1": 179,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T03:40:00.000+0000",
                  "value1": 151,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T03:40:00.000+0000",
                  "value1": 151,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T03:45:00.000+0000",
                  "value1": 290,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T03:45:00.000+0000",
                  "value1": 290,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T03:50:00.000+0000",
                  "value1": 208,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T03:50:00.000+0000",
                  "value1": 208,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T03:55:00.000+0000",
                  "value1": 147,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T03:55:00.000+0000",
                  "value1": 147,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T04:00:00.000+0000",
                  "value1": 154,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T04:00:00.000+0000",
                  "value1": 154,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T04:05:00.000+0000",
                  "value1": 163,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T04:05:00.000+0000",
                  "value1": 163,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T04:10:00.000+0000",
                  "value1": 179,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T04:10:00.000+0000",
                  "value1": 179,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T04:15:00.000+0000",
                  "value1": 807,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T04:15:00.000+0000",
                  "value1": 807,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T04:20:00.000+0000",
                  "value1": 556,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T04:20:00.000+0000",
                  "value1": 556,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T04:25:00.000+0000",
                  "value1": 820,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T04:25:00.000+0000",
                  "value1": 820,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T04:30:00.000+0000",
                  "value1": 179,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T04:30:00.000+0000",
                  "value1": 179,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T04:35:00.000+0000",
                  "value1": 179,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T04:35:00.000+0000",
                  "value1": 179,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "dnsResponseCount",
                  "bucket": "2019-03-14T04:37:00.000+0000",
                  "value1": 179,
                  "value2": null,
                  "scoreList": []
                },
                {
                  "breakdown": "TOTAL",
                  "bucket": "2019-03-14T04:37:00.000+0000",
                  "value1": 179,
                  "value2": null,
                  "scoreList": []
                }
              ]
            }
          ],
          "legendList": []
        }
      }
});