define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/segment/SegmentTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/site/SiteTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions'
], function (SimLokiDatabaseActions, SegmentTemplate, SiteTemplate, UtilityFunctions) {

    return {

        init: function () {
            var tJson = JSON.parse(JSON.stringify( SiteTemplate.SITE_DUMMY_DATA));
            SimLokiDatabaseActions.insert( 'segment-ssid', tJson );               
        },

        segmentUpdate :function(operation, data) {
            var tJson = JSON.parse(JSON.stringify(data));
            for(i = 0; i < tJson.length; i++) {
                var tObj = SimLokiDatabaseActions.getFilteredRecordHandler('Segment', { 'id' : tJson[i].id });
                if(tObj && tObj.length) {
                    var tArr = [];
                    for(var k = 0; k < tJson[i].wlan.length; k++) { 
                        var wlanId = tJson[i].wlan[k].id;
                        tArr.push( {idRef:wlanId} );
                        var tWlan = SimLokiDatabaseActions.getFilteredRecordHandler('wlan-ssid', { 'id' : wlanId });
                        if(tWlan && tWlan.length) {
                            tWlan[0].scalableGroupTag = tJson[i].wlan[k].scalableGroupTag.toString();
                            SimLokiDatabaseActions.update('wlan-ssid', tWlan);
                        }
                     }
                     tJson[i].wlan.length = 0;
                     tJson[i].wlan = tArr;
                     SimLokiDatabaseActions.update( 'Segment', tJson[i] );
                }
            }
        }
    };
});
