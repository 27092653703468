define({
"Template":{
  "version": "1.0",
  "response": {
    "id": "eac4c256-f38c-42a5-a404-a235ddccb45a",
    "name": "default_trap_event_trigger",
    "enabled": true,
    "flattened": true,
    "entityType": "network_device",
    "entityName": "Unknown",
    "entity": "10.4.111.254",
    "groupBy": "Unknown",
    "category": "Device",
    "severity": "HIGH",
    "summary": "Stack member 2 removed from stack.",
    "groupId": "trap_events_issue_grouping",
    "groupName": "Stack Member Removal",
    "scope": "GLOBAL",
    "priority": "P3",
    "rootCause": "Unknown",
    "timestamp": 1617299303658,
    "description": "Stack member 2 removed from stack DeviceNamePlaceHolder",
    "suggestions": [
      {
        "message": "Check for state = Provisioned. This means the switch is configured but not present currently",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check for state = Provisioned",
            "command": "show switch",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Confirm stack member switch is powered up"
      },
      {
        "message": "Confirm stack cables are installed"
      }
    ],
    "additionalParams": [
      {
        "key": "eventMessage",
        "value": ""
      },
      {
        "key": "managementIpAddr",
        "value": "10.4.111.254"
      },
      {
        "key": "_name",
        "value": "default_trap_event_trigger"
      },
      {
        "key": "_entity_type",
        "value": "NetworkDevice"
      },
      {
        "key": "eventSource",
        "value": "Network Device"
      },
      {
        "key": "oid",
        "value": "1.3.6.1.4.1.9.9.500.0.0.6"
      },
      {
        "key": "type",
        "value": "Issue"
      },
      {
        "key": "_key",
        "value": "NetworkDevice:10.4.111.254:2"
      },
      {
        "key": "floorId",
        "value": ""
      },
      {
        "key": "eventValue",
        "value": "2"
      },
      {
        "key": "_count",
        "value": "1"
      },
      {
        "key": "areaName",
        "value": "Global/Milpitas"
      },
      {
        "key": "scope",
        "value": "global"
      },
      {
        "key": "_primary_key",
        "value": "NetworkDevice:10.4.111.254"
      },
      {
        "key": "floorName",
        "value": ""
      },
      {
        "key": "siteType",
        "value": "building"
      },
      {
        "key": "deviceType",
        "value": "Cisco Catalyst 9500 Switch"
      },
      {
        "key": "severity",
        "value": "HIGH"
      },
      {
        "key": "deviceUuid",
        "value": "26afeb20-e550-465a-b46a-c703251370ba"
      },
      {
        "key": "deviceRole",
        "value": "DISTRIBUTION"
      },
      {
        "key": "_suppression",
        "value": "3600"
      },
      {
        "key": "deviceOS",
        "value": "17.3.1"
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "nwDeviceName",
        "value": "D4-C9500.cisco.local"
      },
      {
        "key": "_eventTime",
        "value": "1617299303658"
      },
      {
        "key": "_mostRecent",
        "value": "1617299303658"
      },
      {
        "key": "entityId",
        "value": "10.4.111.254"
      },
      {
        "key": "eventUniqueId",
        "value": "10.4.111.254"
      },
      {
        "key": "deviceFamily",
        "value": "Switches and Hubs"
      },
      {
        "key": "priority",
        "value": "P3"
      },
      {
        "key": "buildingId",
        "value": "a7c562a3-f0c3-48e1-88ca-0948dcf9b385"
      },
      {
        "key": "buildingName",
        "value": "Global/Milpitas/SJC23"
      },
      {
        "key": "areaId",
        "value": "c37b31ab-03b8-49c7-a742-3d1565d9c969"
      },
      {
        "key": "siteHierarchy",
        "value": "Global/Milpitas/SJC23"
      },
      {
        "key": "deviceModel",
        "value": "FCW2243F1SF"
      },
      {
        "key": "category",
        "value": "Device"
      },
      {
        "key": "timestring",
        "value": "2021-04-01T17:48:23.658+0000"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}

})