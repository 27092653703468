define({
    "Guest_Policy_Template": {
        "portal":"",
        "portalInstanceUuid":"",
        "portalType":"selfreg",
        "redirectRuleId":null,
        "permitRuleId":null,
        "authzProfileId":null
    },

    "Guest_Portal_Template" :
        {
        "type": "selfreg",
        "iseInstanceId": "59ba1562-1022-11e8-82d5-02427e26eeef",
        "integrationMessage": "HTTP/1.1 200 OK",
        "dnaPortalString": "",
        "wlans": [],
        "iseProfileId": null,
        "iseRedirectId": null,
        "iseAuthId": null,
        "name": "",
        "instanceUuid": "",
        "id": ""
      }
});
