define({
    "Assurance_Site_Template": {
      "id":"",
      "label":"Site",
      "timestamp":"0",
      "isDeleted":false,
      "type":"vertex",
      "properties":{
           "siteHierarchy":[{"value":""}],
           "name":[{"value":""}],
           "siteType":[{"value":""}],
           "latitude":[{"value":0}],
           "longitude":[{"value":0}]
      }
    }
/*
 case 1:
       properties:{
         siteHierarchy:
         [{value:"Global/San Jose"}],
         name:[{"value":"San Jose"}],
         siteType:[{value:"area"}]
       }
case 2:
       properties:{
         siteHierarchy:
         [{"value":"Global/xyz"}],
         latitude:[{"value":-6.236923}],
         name:[{"value":"xyz"}],
         longitude:[{"value":106.590607}],
         siteType:[{"value":"building"}]
       }
*/
});
