define([
    'core/../../../public/libs/node_modules/sinon/pkg/sinon-3.3.0',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/sinonmock/rest/SimSinonMockRest',
], function (sinon, SinonRestHandler) {

    //http://localhost:3001/api/v1/file/40f5be7b-424d-8bcf-de76-440be86c02e9
    /*browser('/api/v1/file/*', function(er, response, body) {
      //if(er)
        //throw er;
        alert('file image load with http works..');
        console.log("I got: " + body);
    })*/
    /*fetchmock1.get('/api/v1/file/', function getSession(url, opts) {
    alert('image fetch is done.. ' + url);
    return delay({
      status: 200,
      body: JSON.stringify({
        success: true,
        data: "123"
      })
    })
  })*/

    return {
        init: function() {
            this.xhr = sinon.useFakeXMLHttpRequest();

            sinon.FakeXMLHttpRequest.useFilters = true;
            sinon.FakeXMLHttpRequest.addFilter(function(method, url) {
                return SinonRestHandler.isRestRequestHandled(url);
            });

            this.server = sinon.createFakeServer();

            this.server.autoRespond = true;

            //this.server.autoRespondAfter = 400; //110;

            SinonRestHandler.init(this.server);
        }
    };

});
