var Handlebars = require("../../../../node_modules/.pnpm/engci-maven.cisco.com+handlebars@4.7.7/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul class='dropdown-menu dropdown-menu-right settings'>\n	<li class='dropdown-header apic-em menu-system'>"
    + alias4(((helper = (helper = lookupProperty(helpers,"system") || (depth0 != null ? lookupProperty(depth0,"system") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"system","hash":{},"data":data,"loc":{"start":{"line":2,"column":49},"end":{"line":2,"column":59}}}) : helper)))
    + "</li>\n	<div class='_slot system'></div>\n\n	<li role='separator' class='divider'></li>\n	<li class='_usernameHolder disabled'>\n		<a>\n			<i class='dnac-icon-ham_user_sm admin-icon' />\n			<span class='username'></span>\n		</a>\n	</li>\n	<li class='_signoutHolder'>\n		<a>\n			<span class='_signout'>"
    + alias4(((helper = (helper = lookupProperty(helpers,"sign_out") || (depth0 != null ? lookupProperty(depth0,"sign_out") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sign_out","hash":{},"data":data,"loc":{"start":{"line":14,"column":26},"end":{"line":14,"column":38}}}) : helper)))
    + "</span>\n		</a>\n	</li>\n</ul>";
},"useData":true});