define({
  "Template": {
    "testResultDetails": [
       {
        "id": "5adf649d64825e0081e87d46",
        "sensorName": "AP0081.C424.3CE2",
        "sensorType": "AP_AS_SENSOR",
        "testTime": "2018-01-25T17:02:45.126+0000",
        "testName": "dhcp",
        "targetHost": "",
        "ap": "AP4800",
        "ssid": "DEMO-FEW-ASSURANCE",
        "band": "5 GHz",
        "result": "fail",
        "details": [

        ],
        "order": 1
      },{
        "id": "5adf649d64825e0081e87d46",
        "sensorName": "AP0081.C424.3CE2",
        "sensorType": "AP_AS_SENSOR",
        "testTime": "2018-01-25T17:02:45.126+0000",
        "testName": "dns",
        "targetHost": "",
        "ap": "AP4800",
        "ssid": "DEMO-FEW-ASSURANCE",
        "band": "5 GHz",
        "result": "fail",
        "details": [

        ],
        "order": 1
      },{
        "id": "5adf649d64825e0081e87d46",
        "sensorName": "AP0081.C424.3CE2",
        "sensorType": "AP_AS_SENSOR",
        "testTime": "2018-01-25T17:02:45.126+0000",
        "testName": "webserver",
        "targetHost": "",
        "ap": "AP4800",
        "ssid": "DEMO-FEW-ASSURANCE",
        "band": "5 GHz",
        "result": "fail",
        "details": [

        ],
        "order": 1
      },
      {
        "id": "5adf649d64825e0081e87d46",
        "sensorName": "AP0081.C424.3CE2",
        "sensorType": "AP_AS_SENSOR",
        "testTime": "2018-01-25T17:02:18.590+0000",
        "testName": "onboarding",
        "targetHost": "",
        "ap": "AP4800_1",
        "ssid": "DEMO-FEW-ASSURANCE",
        "band": "5 GHz",
        "result": "fail",
        "details": [

        ],
        "order": 1
      },


      {
        "id": "5a659bd4e5605e0020dc1211",
        "sensorName": "AP00EB.D510.3F18",
        "sensorType": "RADIO_AS_SENSOR",
        "testTime": "2018-01-25T17:02:45.126+0000",
        "testName": "webserver",
        "targetHost": "",
        "ap": "04:da:d2:91:f6:cc",
        "ssid": "LA-Topsecret3",
        "band": "5 GHz",
        "result": "pass",
        "details": [

        ],
        "order": 8
      },

      {
        "id": "5a659bd4e5605e0020dc1211",
        "sensorName": "AP00EB.D510.3F18",
        "sensorType": "RADIO_AS_SENSOR",
        "testTime": "2018-01-25T17:02:45.126+0000",
        "testName": "dhcp",
        "targetHost": "",
        "ap": "04:da:d2:91:f6:cc",
        "ssid": "LA-Topsecret3",
        "band": "5 GHz",
        "result": "pass",
        "details": [

        ],
        "order": 2
      },
      {
        "id": "5a659bd4e5605e0020dc1211",
        "sensorName": "AP00EB.D510.3F18",
        "sensorType": "RADIO_AS_SENSOR",
        "testTime": "2018-01-25T17:02:18.590+0000",
        "testName": "webserver",
        "targetHost": "",
        "ap": "LA1-AP2802-38",
        "ssid": "LA-Topsecret3",
        "band": "5 GHz",
        "result": "pass",
        "details": [

        ],
        "order": 8
      },

      {
        "id": "5a659bd4e5605e0020dc1211",
        "sensorName": "AP00EB.D510.3F18",
        "sensorType": "RADIO_AS_SENSOR",
        "testTime": "2018-01-25T17:02:18.590+0000",
        "testName": "dhcp",
        "targetHost": "",
        "ap": "LA1-AP2802-38",
        "ssid": "LA-Topsecret3",
        "band": "5 GHz",
        "result": "pass",
        "details": [

        ],
        "order": 2
      }, {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:21:58.190+0000",
      "testName": "mailserver",
      "targetHost": "demo-ad-81-10.cisco.local",
      "ap": "AP9120_3",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "fail",
      "details": [
        "Test Result Status is 0"
      ],
      "order": 8
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:21:58.190+0000",
      "testName": "onboarding",
      "targetHost": "demo-ad-81-10.cisco.local",
      "ap": "AP9120_3",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 1
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:21:58.190+0000",
      "testName": "dhcp",
      "targetHost": "demo-ad-81-10.cisco.local",
      "ap": "AP9120_3",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 2
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:21:09.926+0000",
      "testName": "mailserver",
      "targetHost": "demo-ad-81-10.cisco.local",
      "ap": "AP9120",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "fail",
      "details": [
        "Test Result Status is 0"
      ],
      "order": 8
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:21:09.926+0000",
      "testName": "onboarding",
      "targetHost": "demo-ad-81-10.cisco.local",
      "ap": "AP9120",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 1
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:21:09.926+0000",
      "testName": "dhcp",
      "targetHost": "demo-ad-81-10.cisco.local",
      "ap": "AP9120",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 2
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:19:39.192+0000",
      "testName": "mailserver",
      "targetHost": "demo-ad-81-10.cisco.local",
      "ap": "AP9115",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "fail",
      "details": [
        "Test Result Status is 0"
      ],
      "order": 8
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:19:39.192+0000",
      "testName": "onboarding",
      "targetHost": "demo-ad-81-10.cisco.local",
      "ap": "AP9115",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 1
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:19:39.192+0000",
      "testName": "dhcp",
      "targetHost": "demo-ad-81-10.cisco.local",
      "ap": "AP9115",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 2
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:18:50.938+0000",
      "testName": "mailserver",
      "targetHost": "demo-ad-81-10.cisco.local",
      "ap": "AP0081.C424.3CE2",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "fail",
      "details": [
        "Test Result Status is 0"
      ],
      "order": 8
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:18:50.938+0000",
      "testName": "onboarding",
      "targetHost": "demo-ad-81-10.cisco.local",
      "ap": "AP0081.C424.3CE2",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 1
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:18:50.938+0000",
      "testName": "dhcp",
      "targetHost": "demo-ad-81-10.cisco.local",
      "ap": "AP0081.C424.3CE2",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 2
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:18:02.759+0000",
      "testName": "mailserver",
      "targetHost": "demo-ad-81-10.cisco.local",
      "ap": "AP4800_1",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "fail",
      "details": [
        "Test Result Status is 0"
      ],
      "order": 8
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:18:02.759+0000",
      "testName": "onboarding",
      "targetHost": "demo-ad-81-10.cisco.local",
      "ap": "AP4800_1",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 1
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:18:02.759+0000",
      "testName": "dhcp",
      "targetHost": "demo-ad-81-10.cisco.local",
      "ap": "AP4800_1",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 2
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:17:13.197+0000",
      "testName": "webserver",
      "targetHost": "",
      "ap": "AP9120_3",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 9
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:16:41.185+0000",
      "testName": "webserver",
      "targetHost": "",
      "ap": "AP9120",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 9
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:16:14.407+0000",
      "testName": "webserver",
      "targetHost": "",
      "ap": "AP9115",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 9
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:15:47.638+0000",
      "testName": "webserver",
      "targetHost": "",
      "ap": "AP0081.C424.3CE2",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 9
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:15:20.876+0000",
      "testName": "webserver",
      "targetHost": "",
      "ap": "AP4800_1",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 9
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:14:53.968+0000",
      "testName": "filetransfer",
      "targetHost": "",
      "ap": "AP9120_3",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 10
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:14:22.216+0000",
      "testName": "filetransfer",
      "targetHost": "",
      "ap": "AP9120",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 10
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:13:20.714+0000",
      "testName": "filetransfer",
      "targetHost": "",
      "ap": "AP9115",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 10
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:12:49.240+0000",
      "testName": "filetransfer",
      "targetHost": "",
      "ap": "AP0081.C424.3CE2",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 10
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:12:18.317+0000",
      "testName": "filetransfer",
      "targetHost": "",
      "ap": "AP4800_1",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 10
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:11:47.124+0000",
      "testName": "radius",
      "targetHost": "10.16.81.86",
      "ap": "AP9120_3",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 6
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:11:16.991+0000",
      "testName": "radius",
      "targetHost": "10.16.81.86",
      "ap": "AP9120",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 6
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:10:46.937+0000",
      "testName": "radius",
      "targetHost": "10.16.81.86",
      "ap": "AP9115",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 6
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:10:17.025+0000",
      "testName": "radius",
      "targetHost": "10.16.81.86",
      "ap": "AP0081.C424.3CE2",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 6
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:09:47.053+0000",
      "testName": "radius",
      "targetHost": "10.16.81.86",
      "ap": "AP4800_1",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 6
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:09:07.020+0000",
      "testName": "ping",
      "targetHost": "10.16.0.1",
      "ap": "AP9120_3",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 5
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:08:14.052+0000",
      "testName": "ping",
      "targetHost": "10.16.0.1",
      "ap": "AP9120",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 5
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:07:41.207+0000",
      "testName": "ping",
      "targetHost": "10.16.0.1",
      "ap": "AP9115",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 5
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:07:08.324+0000",
      "testName": "ping",
      "targetHost": "10.16.0.1",
      "ap": "AP0081.C424.3CE2",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 5
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:06:30.207+0000",
      "testName": "ping",
      "targetHost": "10.16.0.1",
      "ap": "AP4800_1",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 5
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:05:57.369+0000",
      "testName": "dns",
      "targetHost": "www.cisco.com",
      "ap": "AP9120_3",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 4
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:04:09.271+0000",
      "testName": "dns",
      "targetHost": "www.cisco.com",
      "ap": "AP9120",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 4
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:03:27.325+0000",
      "testName": "dns",
      "targetHost": "www.cisco.com",
      "ap": "AP9115",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 4
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:02:55.179+0000",
      "testName": "dns",
      "targetHost": "www.cisco.com",
      "ap": "AP0081.C424.3CE2",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 4
    },
    {
      "id": "5ad7498764825e0020f152a1",
      "sensorName": "AP00EB.D510.3F18",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-06-03T02:02:23.224+0000",
      "testName": "dns",
      "targetHost": "www.cisco.com",
      "ap": "AP4800_1",
      "ssid": "demo-nf-assurance",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 4
    },
    {
      "id": "5b60b6f236fe7400cf82c7b7",
      "sensorName": "AP9120_3",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-09-24T06:56:29.665+0000",
      "testName": "webserver",
      "targetHost": "",
      "ap": "AP4800_1",
      "ssid": "@CorpSSID",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 9
    },
    {
      "id": "5b60b6f236fe7400cf82c7b7",
      "sensorName": "AP9120_3",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-09-24T06:56:29.665+0000",
      "testName": "onboarding",
      "targetHost": "",
      "ap": "AP4800_1",
      "ssid": "@CorpSSID",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 1
    },
    {
      "id": "5b60b6f236fe7400cf82c7b7",
      "sensorName": "AP9120_3",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-09-24T06:56:29.665+0000",
      "testName": "dhcp",
      "targetHost": "",
      "ap": "AP4800_1",
      "ssid": "@CorpSSID",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 2
    },
    {
      "id": "5b60b6f236fe7400cf82c7b7",
      "sensorName": "AP9120_3",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-09-24T06:56:07.515+0000",
      "testName": "ping",
      "targetHost": "10.13.1.1",
      "ap": "AP4800_1",
      "ssid": "@CorpSSID",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 5
    },
    {
      "id": "5b60b6f236fe7400cf82c7b7",
      "sensorName": "AP9120_3",
      "sensorType": "AP_AS_SENSOR",
      "testTime": "2018-09-24T06:55:40.709+0000",
      "testName": "dns",
      "targetHost": "www.cisco.com",
      "ap": "AP4800_1",
      "ssid": "@CorpSSID",
      "band": "2.4 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 4
    },
    {
      "id": "5b994c2c3ecd34001e3aa2c3",
      "sensorName": "AP1800S_Sensor_01",
      "sensorType": "ACTIVE_SENSOR",
      "testTime": "2018-09-26T12:26:24.506+0000",
      "testName": "ping",
      "targetHost": "192.168.139.160",
      "ap": "AP00EB.D510.3F18",
      "ssid": "c9800AP11AX",
      "band": "5 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 5
    },
    {
      "id": "5b994c2c3ecd34001e3aa2c3",
      "sensorName": "AP1800S_Sensor_01",
      "sensorType": "ACTIVE_SENSOR",
      "testTime": "2018-09-26T12:26:24.506+0000",
      "testName": "onboarding",
      "targetHost": "192.168.139.160",
      "ap": "AP00EB.D510.3F18",
      "ssid": "c9800AP11AX",
      "band": "5 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 1
    },

    {
      "id": "5b994c2c3ecd34001e3aa2c3",
      "sensorName": "AP1800S_Sensor_01",
      "sensorType": "ACTIVE_SENSOR",
      "testTime": "2018-09-26T12:22:34.932+0000",
      "testName": "dns",
      "targetHost": "cisco.com",
      "ap": "AP00EB.D510.3F18",
      "ssid": "c9800AP11AX",
      "band": "5 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 4
    },
    {
      "id": "5b994c2c3ecd34001e3aa2c3",
      "sensorName": "AP1800S_Sensor_01",
      "sensorType": "ACTIVE_SENSOR",
      "testTime": "2018-09-26T12:26:24.506+0000",
      "testName": "dhcp",
      "targetHost": "192.168.139.160",
      "ap": "AP00EB.D510.3F18",
      "ssid": "c9800AP11AX",
      "band": "5 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 2
    },
     {
      "id": "5b994c2c3ecd34001e3aa2c3",
      "sensorName": "AP1800S_Sensor_01",
      "sensorType": "ACTIVE_SENSOR",
      "testTime": "2018-09-26T11:59:51.002+0000",
      "testName": "webserver",
      "targetHost": "",
      "ap": "AP00EB.D510.3F18",
      "ssid": "c9800AP11AX",
      "band": "5 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 9
    },
    {
      "id": "5b994c2c3ecd34001e3aa2c3",
      "sensorName": "AP1800S_Sensor_01",
      "sensorType": "ACTIVE_SENSOR",
      "testTime": "2018-09-26T12:24:36.003+0000",
      "testName": "ping",
      "targetHost": "192.168.139.160",
      "ap": "AP9120_3",
      "ssid": "c9800AP11AX",
      "band": "5 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 5
    },
    {
      "id": "5b994c2c3ecd34001e3aa2c3",
      "sensorName": "AP1800S_Sensor_01",
      "sensorType": "ACTIVE_SENSOR",
      "testTime": "2018-09-26T11:58:19.267+0000",
      "testName": "webserver",
      "targetHost": "",
      "ap": "AP9120_3",
      "ssid": "c9800AP11AX",
      "band": "5 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 9
    },

    {
      "id": "5b994c2c3ecd34001e3aa2c3",
      "sensorName": "AP1800S_Sensor_01",
      "sensorType": "ACTIVE_SENSOR",
      "testTime": "2018-09-26T11:58:19.267+0000",
      "testName": "speed",
      "targetHost": "",
      "ap": "AP00EB.D510.3F18",
      "ssid": "c9800AP11AX",
      "band": "5 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 9
    },
    {
      "id": "5b994c2c3ecd34001e3aa2c3",
      "sensorName": "AP1800S_Sensor_01",
      "sensorType": "ACTIVE_SENSOR",
      "testTime": "2018-09-26T11:59:51.002+0000",
      "testName": "ipslasender",
      "targetHost": "",
      "ap": "AP00EB.D510.3F18",
      "ssid": "c9800AP11AX",
      "band": "5 GHz",
      "result": "fail",
      "details": [

      ],
      "order": 9
    },

    {
      "id": "5b994c2c3ecd34001e3aa2c3",
      "sensorName": "AP1800S_Sensor_01",
      "sensorType": "ACTIVE_SENSOR",
      "testTime": "2018-09-26T12:24:36.003+0000",
      "testName": "onboarding",
      "targetHost": "192.168.139.160",
      "ap": "AP9120_3",
      "ssid": "c9800AP11AX",
      "band": "5 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 1
    },
    {
      "id": "5b994c2c3ecd34001e3aa2c3",
      "sensorName": "AP1800S_Sensor_01",
      "sensorType": "ACTIVE_SENSOR",
      "testTime": "2018-09-26T12:24:36.003+0000",
      "testName": "dhcp",
      "targetHost": "192.168.139.160",
      "ap": "AP9120_3",
      "ssid": "c9800AP11AX",
      "band": "5 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 2
    },

    {
      "id": "5b994c2c3ecd34001e3aa2c3",
      "sensorName": "AP1800S_Sensor_01",
      "sensorType": "ACTIVE_SENSOR",
      "testTime": "2018-09-26T11:58:19.267+0000",
      "testName": "speed",
      "targetHost": "",
      "ap": "AP9120_3",
      "ssid": "c9800AP11AX",
      "band": "5 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 9
    },
    {
      "id": "5b994c2c3ecd34001e3aa2c3",
      "sensorName": "AP1800S_Sensor_01",
      "sensorType": "ACTIVE_SENSOR",
      "testTime": "2018-09-26T11:59:51.002+0000",
      "testName": "ipslasender",
      "targetHost": "",
      "ap": "AP9120_3",
      "ssid": "c9800AP11AX",
      "band": "5 GHz",
      "result": "fail",
      "details": [

      ],
      "order": 9
    },
    {
      "id": "5b994c2c3ecd34001e3aa2c3",
      "sensorName": "AP1800S_Sensor_01",
      "sensorType": "ACTIVE_SENSOR",
      "testTime": "2018-09-26T12:20:19.112+0000",
      "testName": "dns",
      "targetHost": "cisco.com",
      "ap": "AP9120_3",
      "ssid": "c9800AP11AX",
      "band": "5 GHz",
      "result": "pass",
      "details": [

      ],
      "order": 4
    }
    ],
     "testResultStatusCounts": {
    "totalTestCount": 62,
    "passCount": 32,
    "slowCount": 0,
    "failDetails": {
      "1141": {
        "ErrorDetails": {
          "errorCode": "1141",
          "title": "EAP Error",
          "fullDescription": "802.1x/EAP authentication failed because username is not found",
          "suggestedActions": [

          ]
        },
        "count": 9
      }
    }
  },
     "threshold": 10000
  }
})
