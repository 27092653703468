define({
    "HealthMetric_Template":
      {"version":"10","response":[{"id":"de97e2fe-f015-4bd8-85ea-8470c0c91313","deviceModelType":"Cisco 4451 Series Integrated Services Router","name":"MX1-ISR4451-2.corp.local","softwareVersion":"15.5(3)S4b","family":"ROUTERS","lastUpdated":null,"managementIpAddress":"10.32.255.2","deviceMacAddress":"70:7D:B9:07:A2:B0","platformId":"ISR4451-X/K9","location":"Global/Mexico/MX/MX-Level23","issueCount":null,"healthScore":[{"healthType":"OVERALL","reason":"","score":10},{"healthType":"SYSTEMHEALTH","reason":"","score":10.0},{"healthType":"DPHEALTH","reason":"","score":10},{"healthType":"CPHEALTH","reason":"","score":10}],"kpiMetrics":[],"roleSource":"BORDER_ROUTER"}],"page":{"pageSize":10,"offset":0,"totalCount":1}},

    "AP_TEMPLATE" : {
      "interferenceScore": 1,
      "fabricRole": "Unified AP",
      "uuid": "467b4d59-a1b9-46d8-87c0-92cf1ded1606",
      "fabricDomainName": "-1",
      "slot0chUtil": 100,
      "band5chNs": -85,
      "roleSource": "ACCESS",
      "id": "467b4d59-a1b9-46d8-87c0-92cf1ded1606",
      "clCount": -1,
      "slot1chNs": -85,
      "band5chUtil": 41.667,
      "groupUUID": "-1",
      "slot1clCount": 0,
      "overallScore": 1,
      "dpScore": -1,
      "cpu": 4,
      "slot0chNs": -75.333,
      "deviceFamily": "Unified AP",
      "deviceModelType": "Cisco Catalyst 9115AXI Unified Access Point",
      "slot1chIntfr": 39.667,
      "name": "AP9115-row12-rack16",
      "utilizationScore": 6,
      "modifiedTimestamp": 1565755200000,
      "siteUUID": "1b026757-7de9-4169-95eb-5f59a1a79d06",
      "band5chIntfr": 39.667,
      "band24chIntfr": 92.667,
      "softwareVersion": "16.12.1.0",
      "_NODE_IDS_": "467b4d59-a1b9-46d8-87c0-92cf1ded1606",
      "errorScore": 10,
      "owningEntityId": "C4:F7:D5:E9:63:40",
      "deviceMacAddress": "C4:F7:D5:E9:63:40",
      "cpScore": -1,
      "__count__": 1,
      "memory": 75,
      "parentSiteId": "6a0f8481-ce49-4cba-ae83-16e3280ccee8",
      "channelAirQualityScore": -1,
      "communicationState": "UP",
      "slot1band": 5,
      "healthScore": [
        {"healthType": "OVERALL","reason": "","score": 1},
        {"healthType": "SYSTEMHEALTH","reason": "","score": -1},
        {"healthType": "CPHEALTH","reason": "","score": -1},
        {"healthType": "DPHEALTH","reason": "","score": -1}
      ],
      "issueCount": 0,
      "band5clCount": 0,
      "band24clCount": 0,
      "osVersion": "16.12.1.0",
      "channelNoiseScore": 6,
      //"allMetricValue": "MEM=75.0::CPU=4.0::NE=-75.333,-85.0::UTIL=100.0,41.667::ITF=92.667,39.667",
      "allMetricValue": "MEM=$memory::CPU=$cpu::NE=$noise_24,$noise5::UTIL=$util24,$util5::ITF=$int24,$int5",
      "eventTime": 1565755200000,
      "band24chNs": -75.333,
      "reachability": "UP",
      "connectedToWlcUuid": "e469f916-6766-4b94-b711-2a2fd03a955f",
      "nwDeviceType": "Cisco Catalyst 9115AXI Unified Access Point",
      "slot0clCount": 0,
      "deviceRole": "ACCESS",
      "managementIpAddress": "172.20.98.186",
      "platformId": "C9115AXI-B",
      "systemScore": -1,
      "slot0chIntfr": 92.667,
      "memoryScore": 10,
      "slot0band": 24,
      "slot1chUtil": 41.667,
      "hsVer": "1.4.0+",
      "ancestorSiteId": "34cb4756-6b14-4611-9cd0-9d1ecd89c1c4",
      "deviceModel": "C9115AXI-B",
      "location": "Global/San Jose/SJC04/MobilityExpress",
      "category": "6",
      "family": "UNIFIED_AP",
      "band24chUtil": 100,
      "connectivityStatus": 100,
      "fabricName": "-1",
      "cpuScore": 10,
      "buildingId":"",
      "siteHierarchy":"",
      "floorId":"",
      "areaId":"",
      "intfDiscard":"",
      "utilizationScore":-1.0,
      "allThresholds":"MEM=I_95::CPU=I_95::RE=I_::LE=I_1::UL=I_::DISC=I_10"
    },

    "WLC_TEMPLATE" : {
      "packetPool": -1,
      "wqePool": -1,
      "fabricRole": "-1",
      "uuid": "d8445ed5-7603-4d94-b4f4-ccde54f206cd",
      "fabricDomainName": "-1",
      "freeMbufScore": -1,
      "mbufFree": -1,
      "tcpConnScore": -1,
      "roleSource": "ACCESS",
      "id": "d8445ed5-7603-4d94-b4f4-ccde54f206cd",
      "clCount": 1,
      "apCount": 1,
      "groupUUID": "-1",
      "envtempScore": -1,
      "overallScore": 10,
      "dpScore": -1,
      "licenseUse": 1,
      "deviceFamily": "Wireless Controller",
      "wqePoolScore": -1,
      "deviceModelType": "Cisco Catalyst 9800-CL Wireless Controller for Cloud",
      "name": "namtest-eWLC-111",
      "modifiedTimestamp": 1565755200000,
      "siteUUID": "839c7797-3126-474b-b8f9-c967df249dc1",
      "softwareVersion": "16.12.20190717:004620",
      "errorScore": 10,
      "_NODE_IDS_": "d8445ed5-7603-4d94-b4f4-ccde54f206cd",
      "owningEntityId": "172.20.124.111",
      "deviceMacAddress": "00:1E:BD:A6:BA:00",
      "cpScore": -1,
      "__count__": 1,
      "memory": 48.913547095908946,
      "intfError": "",
      "parentSiteId": "6a0f8481-ce49-4cba-ae83-16e3280ccee8",
      "communicationState": "REACHABLE",
      "upLinkScore": -1,
      "healthScore": [
        {"healthType": "OVERALL","reason": "","score": 10},
        {"healthType": "SYSTEMHEALTH","reason": "","score": -1},
        {"healthType": "CPHEALTH","reason": "","score": -1},
        {"healthType": "DPHEALTH","reason": "","score": -1}
      ],
      "issueCount": 0,
      "osVersion": "16.12.20190717:004620",
      //"allMetricValue": "MEM=48.913547095908946",
      "allMetricValue": "MEM=$memory",
      "eventTime": 1565755200000,
      "reachability": "REACHABLE",
      "nwDeviceType": "Cisco Catalyst 9800-CL Wireless Controller for Cloud",
      "deviceRole": "ACCESS",
      "freeTimerScore": -1,
      "managementIpAddress": "172.20.124.111",
      "platformId": "C9800-CL-K9",
      "systemScore": -1,
      "memoryScore": 10,
      "packetPoolScore": -1,
      "hsVer": "1.4.0+",
      "ancestorSiteId": "34cb4756-6b14-4611-9cd0-9d1ecd89c1c4",
      "deviceModel": "C9800-CL-K9",
      "location": "Global/San Jose/SJC04/eWLC",
      "WANScore": -1,
      "category": "5",
      "family": "WIRELESS_CONTROLLER",
      "connectivityStatus": 100,
      "fabricName": "-1",
      "cpuScore": -1,
      "buildingId":"",
      "siteHierarchy":"",
      "floorId":"",
      "areaId":"",
      "intfDiscard":"",
      "utilizationScore":-1.0,
      "allThresholds":"MEM=I_95::CPU=I_95::RE=I_::LE=I_1::UL=I_::DISC=I_10"
    },

    "SWITCHES_TEMPLATE" : {
      "fabricRole": "EDGE:MAP-SERVER:BORDER:WLC",
      "uuid": "432f3b5e-b029-49e4-b9eb-3c2871013d24",
      "fabricDomainName": "eCA-Fabric",
      "freeMbufScore": -1,
      "tcpConnScore": -1,
      "roleSource": "ACCESS",
      "id": "432f3b5e-b029-49e4-b9eb-3c2871013d24",
      "groupUUID": "-1",
      "envtempScore": -1,
      "overallScore": 10,
      "dpScore": -1,
      "cpu": 6.125,
      "deviceFamily": "Switches and Hubs",
      "wqePoolScore": -1,
      "deviceModelType": "Cisco Catalyst 9300 Switch",
      "name": "namlab-C9300",
      "modifiedTimestamp": 1565755200000,
      "siteUUID": "5355a2bd-ccd1-4b67-8250-8516a223d30b",
      "softwareVersion": "16.12.1",
      "_NODE_IDS_": "432f3b5e-b029-49e4-b9eb-3c2871013d24",
      "errorScore": -1,
      "owningEntityId": "172.20.125.7",
      "deviceMacAddress": "00:77:8D:85:BD:80",
      "upLink": "FiveGigabitEthernet1/0/29,FiveGigabitEthernet1/0/27",
      "cpScore": -1,
      "__count__": 1,
      "memory": 64.1167779882981,
      "parentSiteId": "6a0f8481-ce49-4cba-ae83-16e3280ccee8",
      "intfError": "",
      "communicationState": "REACHABLE",
      "upLinkScore": -1,
      "healthScore": [
        {"healthType": "OVERALL","reason": "","score": 10},
        {"healthType": "SYSTEMHEALTH","reason": "","score": -1},
        {"healthType": "CPHEALTH","reason": "","score": -1},
        {"healthType": "DPHEALTH","reason": "","score": -1}
      ],
      "issueCount": 0,
      "osVersion": "16.12.1",
      //"allMetricValue": "MEM=64.1167779882981::CPU=6.125",
      "allMetricValue": "MEM=$memory::CPU=$cpu",
      "eventTime": 1565755200000,
      "reachability": "REACHABLE",
      "nwDeviceType": "Cisco Catalyst 9300 Switch",
      "deviceRole": "ACCESS",
      "freeTimerScore": -1,
      "managementIpAddress": "172.20.125.7",
      "platformId": "C9300-48UN",
      "systemScore": -1,
      "memoryScore": 10,
      "hsVer": "1.4.0+",
      "packetPoolScore": -1,
      "ancestorSiteId": "34cb4756-6b14-4611-9cd0-9d1ecd89c1c4",
      "deviceModel": "C9300-48UN",
      "location": "Global/San Jose/SJC04/eCA",
      "WANScore": -1,
      "category": "3",
      "family": "SWITCHES_AND_HUBS",
      "connectivityStatus": 100,
      "fabricName": "Global/San_Jose/SJC04/eCA_eCA-Fabric",
      "cpuScore": 10,
      "buildingId":"",
      "siteHierarchy":"",
      "floorId":"",
      "areaId":"",
      "intfDiscard":"",
      "utilizationScore":-1.0,
      "allThresholds":"MEM=I_95::CPU=I_95::RE=I_::LE=I_1::UL=I_::DISC=I_10"
    }
});
