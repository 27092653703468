define({
    "Template":
    {
        "version": "1.0",
        "response": {
            "summary": {
                "averageRate": 578187.63,
                "totalUsage": 4853032690,
                "activeClients": 1
            },
            "trend": [
                {
                    "collToolsAppName": "ms-teams-app-sharing",
                    "minHealthScore": 7,
                    "avgHealthScore": 7,
                    "businessRelevance": [
                        "business-relevant"
                    ],
                    "trafficClass": [
                        "multimedia-conferencing"
                    ],
                    "healthTrend": [
                        {
                            "time": "2022-11-07T10:00:00.000+0000",
                            "healthScore": null,
                            "packetLoss": null,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": null,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": null,
                            "applicationDelay": null,
                            "clientNetworkDelay": null,
                            "serverNetworkDelay": null,
                            "dscp": "",
                            "usage": 300,
                            "rate": 4
                        },
                        {
                            "time": "2022-11-06T20:00:00.000+0000",
                            "healthScore": null,
                            "packetLoss": null,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": null,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": null,
                            "applicationDelay": null,
                            "clientNetworkDelay": null,
                            "serverNetworkDelay": null,
                            "dscp": "",
                            "usage": 300,
                            "rate": 4
                        },
                        {
                            "time": "2022-11-06T17:00:00.000+0000",
                            "healthScore": null,
                            "packetLoss": null,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": null,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": null,
                            "applicationDelay": null,
                            "clientNetworkDelay": null,
                            "serverNetworkDelay": null,
                            "dscp": "",
                            "usage": 300,
                            "rate": 4
                        },
                        {
                            "time": "2022-11-06T15:00:00.000+0000",
                            "healthScore": null,
                            "packetLoss": null,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": null,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": null,
                            "applicationDelay": null,
                            "clientNetworkDelay": null,
                            "serverNetworkDelay": null,
                            "dscp": "",
                            "usage": 616,
                            "rate": 4.11
                        },
                        {
                            "time": "2022-11-06T14:00:00.000+0000",
                            "healthScore": 7,
                            "packetLoss": 1.74,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": 27021.545454545456,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": 39.2,
                            "applicationDelay": 2.9,
                            "clientNetworkDelay": 0.6999999999999993,
                            "serverNetworkDelay": 38.5,
                            "dscp": "",
                            "usage": 913632272,
                            "rate": 1107688.67
                        },
                        {
                            "time": "2022-11-06T13:00:00.000+0000",
                            "healthScore": 7,
                            "packetLoss": 1.7,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": 26238.8,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": 36.725,
                            "applicationDelay": 6,
                            "clientNetworkDelay": 0.9749999999999996,
                            "serverNetworkDelay": 35.75,
                            "dscp": "",
                            "usage": 753605808,
                            "rate": 1004807.74
                        },
                        {
                            "time": "2022-11-06T12:00:00.000+0000",
                            "healthScore": 7,
                            "packetLoss": 1.73,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": 25553.909090909092,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": 32.1625,
                            "applicationDelay": 3.3636363636363638,
                            "clientNetworkDelay": 0.5375000000000014,
                            "serverNetworkDelay": 32,
                            "dscp": "",
                            "usage": 1025995087,
                            "rate": 621817.02
                        },
                        {
                            "time": "2022-11-06T11:00:00.000+0000",
                            "healthScore": 7,
                            "packetLoss": 1.74,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": 25970.5,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": 36.35,
                            "applicationDelay": 0,
                            "clientNetworkDelay": 0.8500000000000014,
                            "serverNetworkDelay": 35.5,
                            "dscp": "",
                            "usage": 139022576,
                            "rate": 926817.17
                        },
                        {
                            "time": "2022-11-06T07:00:00.000+0000",
                            "healthScore": 7,
                            "packetLoss": 1.73,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": 26750.428571428572,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": 36.1375,
                            "applicationDelay": 6.714285714285714,
                            "clientNetworkDelay": 0.5124999999999993,
                            "serverNetworkDelay": 36,
                            "dscp": "",
                            "usage": 566845280,
                            "rate": 1079390.53
                        },
                        {
                            "time": "2022-11-06T06:00:00.000+0000",
                            "healthScore": 7,
                            "packetLoss": 1.72,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": 26024.5,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": 36.2625,
                            "applicationDelay": 23.25,
                            "clientNetworkDelay": 0.3874999999999993,
                            "serverNetworkDelay": 36,
                            "dscp": "",
                            "usage": 141875746,
                            "rate": 945838.31
                        },
                        {
                            "time": "2022-11-03T15:00:00.000+0000",
                            "healthScore": 7,
                            "packetLoss": 1.8,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": 27288,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": 35.9875,
                            "applicationDelay": 3,
                            "clientNetworkDelay": 0.8625000000000007,
                            "serverNetworkDelay": 35.5,
                            "dscp": "",
                            "usage": 591854577,
                            "rate": 1127342.05
                        }
                    ],
                    "category": [
                        "collaboration-apps"
                    ]
                },
                {
                    "collToolsAppName": "ms-teams-video",
                    "minHealthScore": 8,
                    "avgHealthScore": 8,
                    "businessRelevance": [
                        "business-relevant"
                    ],
                    "trafficClass": [
                        "multimedia-conferencing"
                    ],
                    "healthTrend": [
                        {
                            "time": "2022-11-07T05:00:00.000+0000",
                            "healthScore": null,
                            "packetLoss": null,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": null,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": null,
                            "applicationDelay": null,
                            "clientNetworkDelay": null,
                            "serverNetworkDelay": null,
                            "dscp": "",
                            "usage": 300,
                            "rate": 4
                        },
                        {
                            "time": "2022-11-06T23:00:00.000+0000",
                            "healthScore": null,
                            "packetLoss": null,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": null,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": null,
                            "applicationDelay": null,
                            "clientNetworkDelay": null,
                            "serverNetworkDelay": null,
                            "dscp": "",
                            "usage": 300,
                            "rate": 4
                        },
                        {
                            "time": "2022-11-06T14:00:00.000+0000",
                            "healthScore": 8,
                            "packetLoss": 1.61,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": 470.8181818181818,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": 38.375,
                            "applicationDelay": 4.85,
                            "clientNetworkDelay": 0.875,
                            "serverNetworkDelay": 37.5,
                            "dscp": "",
                            "usage": 98034935,
                            "rate": 118830.22
                        },
                        {
                            "time": "2022-11-06T13:00:00.000+0000",
                            "healthScore": 8,
                            "packetLoss": 1.72,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": 432.25,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": 35.875,
                            "applicationDelay": 0,
                            "clientNetworkDelay": 0.875,
                            "serverNetworkDelay": 35,
                            "dscp": "",
                            "usage": 90486647,
                            "rate": 100540.72
                        },
                        {
                            "time": "2022-11-06T12:00:00.000+0000",
                            "healthScore": 8,
                            "packetLoss": 1.64,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": 148.25,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": 32.8,
                            "applicationDelay": 0.045454545454545456,
                            "clientNetworkDelay": 0.8000000000000007,
                            "serverNetworkDelay": 32,
                            "dscp": "",
                            "usage": 108003754,
                            "rate": 120004.17
                        },
                        {
                            "time": "2022-11-06T11:00:00.000+0000",
                            "healthScore": 8,
                            "packetLoss": 1.63,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": 1721,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": 36.075,
                            "applicationDelay": 0.25,
                            "clientNetworkDelay": 0.5749999999999993,
                            "serverNetworkDelay": 35.5,
                            "dscp": "",
                            "usage": 20856933,
                            "rate": 139046.22
                        },
                        {
                            "time": "2022-11-06T07:00:00.000+0000",
                            "healthScore": 8,
                            "packetLoss": 1.81,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": 52,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": 36.75,
                            "applicationDelay": 0,
                            "clientNetworkDelay": 0.75,
                            "serverNetworkDelay": 36.5,
                            "dscp": "",
                            "usage": 57613915,
                            "rate": 95840.51
                        },
                        {
                            "time": "2022-11-06T06:00:00.000+0000",
                            "healthScore": 8,
                            "packetLoss": 1.64,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": 881,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": 36.9,
                            "applicationDelay": 0,
                            "clientNetworkDelay": 0.3999999999999986,
                            "serverNetworkDelay": 36.5,
                            "dscp": "",
                            "usage": 19566452,
                            "rate": 130443.01
                        },
                        {
                            "time": "2022-11-03T16:00:00.000+0000",
                            "healthScore": null,
                            "packetLoss": null,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": null,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": null,
                            "applicationDelay": null,
                            "clientNetworkDelay": null,
                            "serverNetworkDelay": null,
                            "dscp": "",
                            "usage": 1052,
                            "rate": 28.05
                        },
                        {
                            "time": "2022-11-03T15:00:00.000+0000",
                            "healthScore": 8,
                            "packetLoss": 1.3,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": 4306.714285714285,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": 39.4875,
                            "applicationDelay": 20.875,
                            "clientNetworkDelay": 2.4875000000000007,
                            "serverNetworkDelay": 37,
                            "dscp": "",
                            "usage": 65038522,
                            "rate": 108397.54
                        }
                    ],
                    "category": [
                        "collaboration-apps"
                    ]
                },
                {
                    "collToolsAppName": "ms-teams-audio",
                    "minHealthScore": 8,
                    "avgHealthScore": 8,
                    "businessRelevance": [
                        "business-relevant"
                    ],
                    "trafficClass": [
                        "multimedia-conferencing"
                    ],
                    "healthTrend": [
                        {
                            "time": "2022-11-06T14:00:00.000+0000",
                            "healthScore": 8,
                            "packetLoss": 1.74,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": 568.6818181818182,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": 38.55,
                            "applicationDelay": 0,
                            "clientNetworkDelay": 0.5500000000000007,
                            "serverNetworkDelay": 38,
                            "dscp": "",
                            "usage": 55546468,
                            "rate": 67329.05
                        },
                        {
                            "time": "2022-11-06T13:00:00.000+0000",
                            "healthScore": 8,
                            "packetLoss": 1.73,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": 669.25,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": 36,
                            "applicationDelay": 0,
                            "clientNetworkDelay": 0.3000000000000007,
                            "serverNetworkDelay": 35.75,
                            "dscp": "",
                            "usage": 48147423,
                            "rate": 64196.56
                        },
                        {
                            "time": "2022-11-06T12:00:00.000+0000",
                            "healthScore": 8,
                            "packetLoss": 1.72,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": 676.6363636363636,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": 32.775,
                            "applicationDelay": 0.09090909090909091,
                            "clientNetworkDelay": 0.4750000000000014,
                            "serverNetworkDelay": 32.5,
                            "dscp": "",
                            "usage": 62496379,
                            "rate": 75753.19
                        },
                        {
                            "time": "2022-11-06T11:00:00.000+0000",
                            "healthScore": 8,
                            "packetLoss": 1.73,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": 675.25,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": 36.1,
                            "applicationDelay": 0.25,
                            "clientNetworkDelay": 0.6000000000000014,
                            "serverNetworkDelay": 35.5,
                            "dscp": "",
                            "usage": 9856066,
                            "rate": 65707.11
                        },
                        {
                            "time": "2022-11-06T07:00:00.000+0000",
                            "healthScore": 8,
                            "packetLoss": 1.69,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": 646.1428571428571,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": 36.9,
                            "applicationDelay": 0.07142857142857142,
                            "clientNetworkDelay": 0.3999999999999986,
                            "serverNetworkDelay": 36.5,
                            "dscp": "",
                            "usage": 35192174,
                            "rate": 62512.78
                        },
                        {
                            "time": "2022-11-06T06:00:00.000+0000",
                            "healthScore": 8,
                            "packetLoss": 1.77,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": 604.75,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": 36.3,
                            "applicationDelay": 0.25,
                            "clientNetworkDelay": 0.3000000000000007,
                            "serverNetworkDelay": 36,
                            "dscp": "",
                            "usage": 10001569,
                            "rate": 66677.13
                        },
                        {
                            "time": "2022-11-03T15:00:00.000+0000",
                            "healthScore": 8,
                            "packetLoss": 1.29,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": 679,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": 32.575,
                            "applicationDelay": 41.25,
                            "clientNetworkDelay": 0.6583333333333339,
                            "serverNetworkDelay": 31.916666666666664,
                            "dscp": "",
                            "usage": 39356939,
                            "rate": 65594.9
                        }
                    ],
                    "category": [
                        "collaboration-apps"
                    ]
                },
                {
                    "collToolsAppName": "total",
                    "minHealthScore": 7,
                    "avgHealthScore": 7,
                    "businessRelevance": [
                        "business-relevant"
                    ],
                    "trafficClass": [
                        "multimedia-conferencing"
                    ],
                    "healthTrend": [
                        {
                            "time": "2022-11-07T10:00:00.000+0000",
                            "healthScore": null,
                            "packetLoss": null,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": null,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": null,
                            "applicationDelay": null,
                            "clientNetworkDelay": null,
                            "serverNetworkDelay": null,
                            "dscp": null,
                            "usage": 300,
                            "rate": 4
                        },
                        {
                            "time": "2022-11-07T05:00:00.000+0000",
                            "healthScore": null,
                            "packetLoss": null,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": null,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": null,
                            "applicationDelay": null,
                            "clientNetworkDelay": null,
                            "serverNetworkDelay": null,
                            "dscp": null,
                            "usage": 300,
                            "rate": 4
                        },
                        {
                            "time": "2022-11-06T23:00:00.000+0000",
                            "healthScore": null,
                            "packetLoss": null,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": null,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": null,
                            "applicationDelay": null,
                            "clientNetworkDelay": null,
                            "serverNetworkDelay": null,
                            "dscp": null,
                            "usage": 300,
                            "rate": 4
                        },
                        {
                            "time": "2022-11-06T20:00:00.000+0000",
                            "healthScore": null,
                            "packetLoss": null,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": null,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": null,
                            "applicationDelay": null,
                            "clientNetworkDelay": null,
                            "serverNetworkDelay": null,
                            "dscp": null,
                            "usage": 300,
                            "rate": 4
                        },
                        {
                            "time": "2022-11-06T17:00:00.000+0000",
                            "healthScore": null,
                            "packetLoss": null,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": null,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": null,
                            "applicationDelay": null,
                            "clientNetworkDelay": null,
                            "serverNetworkDelay": null,
                            "dscp": null,
                            "usage": 300,
                            "rate": 4
                        },
                        {
                            "time": "2022-11-06T15:00:00.000+0000",
                            "healthScore": null,
                            "packetLoss": null,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": null,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": null,
                            "applicationDelay": null,
                            "clientNetworkDelay": null,
                            "serverNetworkDelay": null,
                            "dscp": null,
                            "usage": 616,
                            "rate": 4.11
                        },
                        {
                            "time": "2022-11-06T14:00:00.000+0000",
                            "healthScore": 7,
                            "packetLoss": 1.74,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": 27021.545454545456,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": 39.2,
                            "applicationDelay": 4.85,
                            "clientNetworkDelay": 0.875,
                            "serverNetworkDelay": 38.5,
                            "dscp": null,
                            "usage": 1067213675,
                            "rate": 1293847.94
                        },
                        {
                            "time": "2022-11-06T13:00:00.000+0000",
                            "healthScore": 7,
                            "packetLoss": 1.73,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": 26238.8,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": 36.725,
                            "applicationDelay": 6,
                            "clientNetworkDelay": 0.9749999999999996,
                            "serverNetworkDelay": 35.75,
                            "dscp": null,
                            "usage": 892239878,
                            "rate": 1169545.02
                        },
                        {
                            "time": "2022-11-06T12:00:00.000+0000",
                            "healthScore": 7,
                            "packetLoss": 1.73,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": 25553.909090909092,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": 32.8,
                            "applicationDelay": 3.3636363636363638,
                            "clientNetworkDelay": 0.8000000000000007,
                            "serverNetworkDelay": 32.5,
                            "dscp": null,
                            "usage": 1196495220,
                            "rate": 817574.38
                        },
                        {
                            "time": "2022-11-06T11:00:00.000+0000",
                            "healthScore": 7,
                            "packetLoss": 1.74,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": 25970.5,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": 36.35,
                            "applicationDelay": 0.25,
                            "clientNetworkDelay": 0.8500000000000014,
                            "serverNetworkDelay": 35.5,
                            "dscp": null,
                            "usage": 169735575,
                            "rate": 1131570.5
                        },
                        {
                            "time": "2022-11-06T07:00:00.000+0000",
                            "healthScore": 7,
                            "packetLoss": 1.81,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": 26750.428571428572,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": 36.9,
                            "applicationDelay": 6.714285714285714,
                            "clientNetworkDelay": 0.75,
                            "serverNetworkDelay": 36.5,
                            "dscp": null,
                            "usage": 659651369,
                            "rate": 1237743.82
                        },
                        {
                            "time": "2022-11-06T06:00:00.000+0000",
                            "healthScore": 7,
                            "packetLoss": 1.77,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": 26024.5,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": 36.9,
                            "applicationDelay": 23.25,
                            "clientNetworkDelay": 0.3999999999999986,
                            "serverNetworkDelay": 36.5,
                            "dscp": null,
                            "usage": 171443767,
                            "rate": 1142958.45
                        },
                        {
                            "time": "2022-11-03T16:00:00.000+0000",
                            "healthScore": null,
                            "packetLoss": null,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": null,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": null,
                            "applicationDelay": null,
                            "clientNetworkDelay": null,
                            "serverNetworkDelay": null,
                            "dscp": null,
                            "usage": 1052,
                            "rate": 28.05
                        },
                        {
                            "time": "2022-11-03T15:00:00.000+0000",
                            "healthScore": 7,
                            "packetLoss": 1.8,
                            "clientPacketLoss": null,
                            "serverPacketLoss": null,
                            "jitter": 27288,
                            "clientJitter": null,
                            "serverJitter": null,
                            "latency": 39.4875,
                            "applicationDelay": 41.25,
                            "clientNetworkDelay": 2.4875000000000007,
                            "serverNetworkDelay": 37,
                            "dscp": null,
                            "usage": 696250038,
                            "rate": 1301334.49
                        }
                    ],
                    "category": [
                        "collaboration-apps"
                    ]
                }
            ]
        }
    }
})