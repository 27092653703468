define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/graphql/SimGraphQL',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/kairos/SimKairosTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/kairos/SimKairosNetworkInsightTemplate',
    'core/../../../public/libs/node_modules/moment',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/assurance/SimKairosData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/kairos/SimKairosIssuesDynGenTemplates',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/kairos/networkInsight/Insight1',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/kairos/networkInsight/Insight2',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/kairos/networkInsight/Insight3',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/kairos/networkInsight/Insight4',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/kairos/networkInsight/Insight5',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/kairos/SimKairosHeatMapTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/SimulationUtility',    
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/assurance/SimEnhancedRRMData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/kairos/SimKairosInsightDetail',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/kairos/SimKairosInsightsTemplate',
    
], function (SimGraphQL, SimKairosTemplate, SimLokiDatabaseActions, SimKairosNetworkInsightTemplate, moment,
    SimKairosData, SimKairosIssuesDynGenTemplates, UtilityFunctions, Insight1, Insight2, Insight3, Insight4, Insight5,
    SimKairosHeatMapTemplate, SimulationUtility, SimEnhancedRRMData,SimKairosInsightDetail,SimKairosInsightsTemplate) {

    const Days = {SUNDAY:0, MONDAY:1, TUESDAY:2, WEDNESDAY:3, THURSDAY:4, FRIDAY:5, SATURDAY:6};

    return {
        init: function() {
            //Kairos - Network Insight
            var insightDateMapping = JSON.parse(JSON.stringify(SimKairosNetworkInsightTemplate.INSIGHT_LASTDATE_MAPPING));
            insightDateMapping = createInsightTimingMap(insightDateMapping);
            var combinedJson = [];
            combinedJson.push(Insight1.NETWORK_INSIGHTS_ONLOAD_TEMPLATE);
            combinedJson.push(Insight2.NETWORK_INSIGHTS_ONLOAD_TEMPLATE);
            combinedJson.push(Insight3.NETWORK_INSIGHTS_ONLOAD_TEMPLATE);
            combinedJson.push(Insight4.NETWORK_INSIGHTS_ONLOAD_TEMPLATE);
            combinedJson.push(Insight5.NETWORK_INSIGHTS_ONLOAD_TEMPLATE);
            combinedJson = JSON.stringify(combinedJson);
            combinedJson = updateApNames(combinedJson);
            combinedJson = updateSiteNames(combinedJson);
            combinedJson = JSON.parse(combinedJson);
            SimLokiDatabaseActions.insert('kairos-network-insight',combinedJson);

            combinedJson = [];
            combinedJson = combinedJson.concat(Insight1.ENTITY_DETAIL);
            combinedJson = combinedJson.concat(Insight2.ENTITY_DETAIL);
            combinedJson = combinedJson.concat(Insight3.ENTITY_DETAIL);
            combinedJson = combinedJson.concat(Insight4.ENTITY_DETAIL);
            combinedJson = combinedJson.concat(Insight5.ENTITY_DETAIL);
            combinedJson = JSON.stringify(combinedJson);
            combinedJson = updateApNames(combinedJson);
            combinedJson = updateSiteNames(combinedJson);
            combinedJson = JSON.parse(combinedJson);
            SimLokiDatabaseActions.insert('kairos-network-insight-entity-details',combinedJson);

            combinedJson = [];
            combinedJson.push(Insight1.NETWORK_ENTITY_360);
            combinedJson.push(Insight2.NETWORK_ENTITY_360);
            combinedJson.push(Insight3.NETWORK_ENTITY_360);
            combinedJson.push(Insight4.NETWORK_ENTITY_360);
            combinedJson.push(Insight5.NETWORK_ENTITY_360);
            combinedJson = JSON.parse(JSON.stringify(combinedJson));
            for(var i=0; i<combinedJson.length; i++) {
                var record = SimLokiDatabaseActions.getFilteredRecordHandler('kairos-network-insight',{'insightId':combinedJson[i].insightId});
                if(record.length>0) {
                    var temp = JSON.stringify(combinedJson[i]);
                    temp = updateNetworkInsightTimings(temp, insightDateMapping[combinedJson[i].insightId]);
                    combinedJson[i] = JSON.parse(temp);
                    record[0].entityStats = combinedJson[i].entityStats;
                    record[0].ncubeDetails = combinedJson[i].ncubeDetails;
                    var idx = insightDateMapping[combinedJson[i].insightId].old.indexOf(record[0].endTimestamp);
                    record[0].endTimestamp = insightDateMapping[combinedJson[i].insightId].new[idx];
                    idx = insightDateMapping[combinedJson[i].insightId].old.indexOf(record[0].startTimestamp);
                    record[0].startTimestamp = insightDateMapping[combinedJson[i].insightId].new[idx];
                    record[0].timestamp = record[0].endTimestamp;
                    SimLokiDatabaseActions.update('kairos-network-insight', record);
                }
            }

            //Kairos - Network Heatmap
            let transformedHeatmapData = SimKairosHeatMapTemplate.heatmap_data_sample.data.metrics.nodes;
            let aps = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{"family":"Unified AP"}).map(e=>e.hostname)
            let topApNames = ['AP4800','SFO11-AP4800-01','SFO15-AP4800-01']
            aps = [...topApNames,...aps]
            transformedHeatmapData[0].heatmapData.forEach((e,i)=>e.aesApName=aps[i])
            transformedHeatmapData[0].heatmapData = transformedHeatmapData[0].heatmapData.filter(e=>e.aesApName)
            SimLokiDatabaseActions.insert('kairos-heatmap',transformedHeatmapData);

            //Common
            var tJson = SimKairosTemplate.FEEDBACK;
            SimLokiDatabaseActions.insert('kairos-feedback',tJson);
            
            //PeerComparisionData
            tJson = SimKairosTemplate.PEER_COMPARISOIN_ALL_DATA
            SimLokiDatabaseActions.insert('kairos-peer-comparision-totalKpisData',tJson);
            tJson = SimKairosTemplate.PEER_COMPARISION_AGGREGATED_WEEKDAY_DATA;
            SimLokiDatabaseActions.insert('kairos-peer-comparision-Aggregated-Weekday-Data',tJson);
            tJson = SimKairosTemplate.PEER_COMPARISION_AGGREGATED_DATA;
            SimLokiDatabaseActions.insert('kairos-peer-comparision-Aggregated-Data',tJson);

            //initialise timestamps - intentionally to update the template directly
            createTimingTemplate();
            console.log(SimKairosIssuesDynGenTemplates.ISSUE_MAPPINGS);
            console.log('Initialised Kairos data.');

            //Site Comparison - template mapping for few KPIS using metadata - for top and below graph sync
            /* var buildings = SimKairosData.buildingsBasedOnApCount();
            //var buildings = SimulationUtility.getAllBuildingsOnly(false);
            buildings.forEach(building => {
                var idx=0, min=1,max=1;
                idx = ( building.siteName=="SJC01" ? 1 : (building.siteName=="SFO15" ? 2 : UtilityFunctions.getRandomIntForRangeValues(3,5) ));
                var tJson = {"siteId":building.siteId, "siteName": building.siteName, "dayTemplate":"DayTemplate"+idx};
                SimLokiDatabaseActions.insert("kairos-sitecmprsn-site-day-mapping",tJson);
            }); */

            //to maintain ap to its entityid mapping in responses
            let sjc01SiteId = SimLokiDatabaseActions.getFilteredRecordHandler('site',
                {"groupNameHierarchy":"Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1"})
            aps = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',
                {'$and':[{"family":"Unified AP"},{'siteId':sjc01SiteId[0].id}]});
            let apEntityIdMapping = {}, apNameMap, entityIdMap;
            aps.forEach(ap => {
                apNameMap = ap.hostname, entityIdMap = UtilityFunctions.generateId(20)
                apEntityIdMapping[apNameMap] = entityIdMap;
            });
            SimLokiDatabaseActions.insert("kairos-mappings",{"key":"apEntityIdMapping","values":apEntityIdMapping});


            //kairos-heatMap-- aps of sjc01 from above section
            aps.sort(function(a, b){return b.assuranceHealthScore - a.assuranceHealthScore});
            let topListApNames = ['SJC01_9136_12'], uniq = {}
            let topListApRecs = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': {'$in':
                topListApNames}});
            aps = [...topListApRecs,...aps]
            aps = aps.filter(obj => !uniq[obj.hostname] && (uniq[obj.hostname] = true));
            
            let datesList = UtilityFunctions.getLastDayOfGivenMonths(4)
            this.createHeatMapData(aps,datesList)

        },
        //generating heat map data for kpis : "radioClientCount","utilization","logTotalBitsPerSec"
        createHeatMapData : function(aps,datesList){
            datesList.map( dte => {
                let recordLength, DateToConsider
                let currentDayDate = new Date();
                let referenceDate = new Date(dte);
                if(currentDayDate<referenceDate){
                    recordLength = currentDayDate.getDate()
                    DateToConsider = currentDayDate
                }else {
                    recordLength = referenceDate.getDate()
                    DateToConsider = referenceDate
                }
                //let targetKpis = ["radioClientCount","utilization","logTotalBitsPerSec"] //logTotalBitsPerSec
                let targetKpis = ["logTotalBitsPerSec","logCloudBitsPerSec","logSocialBitsPerSec","logMediaBitsPerSec",
                "datarate","radioResetCount","channelChangeCount","radioDistinctAppCount","packetFailureRate","deltaQueueFailed",
                "deltaQueueDiscarded","radioClientCount","interference","avgClientRssi","avgClientSnr","traffic","utilization"]
                let apsData = getApsDataInRequiredFormat(aps)
                targetKpis.map(eachKpi => {
                    apsData.map(e => {
                        let refDateObj = {"refDate": "","targetKpi":"","apname":"","data":[], "apNameForReference":"",
                        "clientCount":0,"frequency":'',"radioEntityId":"", "apEntityId":""}
                        let apNameForReference = `${e.apname}_${e.frequency}`
                        let device = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'hostname':e.apname})
                        let genratdHtMapData = generateHeatMapDataForTargetKpi(eachKpi,recordLength,DateToConsider,
                                                                device[0].assuranceHealthScore,e.apname,e.frequency)
                        refDateObj.refDate = dte
                        refDateObj.targetKpi = eachKpi
                        refDateObj.apname = e.apname
                        refDateObj.data = genratdHtMapData
                        refDateObj.frequency = e.frequency
                        refDateObj.clientCount = e.clientCount
                        refDateObj.radioEntityId = e.radioEntityId
                        refDateObj.apEntityId = e.radioEntityId
                        SimLokiDatabaseActions.insert('kairos-heatmap-kpivalues',refDateObj)
                    })
                })
            })
        },
        //generating heat map data for kpis : "radioClientCount","utilization","logTotalBitsPerSec"
        createHeatMapDailyData : function(aps,datesList){
            datesList.map( dte => {
                let recordLength, DateToConsider
                let currentDayDate = new Date();
                let referenceDate = new Date(dte);
                if(currentDayDate<referenceDate){
                    recordLength = currentDayDate.getDate()
                    DateToConsider = currentDayDate
                }else {
                    recordLength = referenceDate.getDate()
                    DateToConsider = referenceDate
                }
                //let targetKpis = ["radioClientCount","utilization","logTotalBitsPerSec"] //logTotalBitsPerSec
                let targetKpis = ["logTotalBitsPerSec","logCloudBitsPerSec","logSocialBitsPerSec","logMediaBitsPerSec",
                "datarate","radioResetCount","channelChangeCount","radioDistinctAppCount","packetFailureRate","deltaQueueFailed",
                "deltaQueueDiscarded","radioClientCount","interference","avgClientRssi","avgClientSnr","traffic","utilization"]
                let apsData = getApsDataInRequiredFormat(aps)
                targetKpis.map(eachKpi => {
                    apsData.map(e => {
                        //let refDateObj = {"refDate": "","targetKpi":"","apname":"","data":[], "apNameForReference":"",
                        //"clientCount":0,"frequency":'',"radioEntityId":"", "apEntityId":""}
                        let refDateObj = {"__typename": "", "aesApGroup": null, "aesApMac": "", "aesApName": "", "aesWlcId": "", "apEntityId": "", "avgClientRssi": 0, "avgClientSnr": 0, "channelChangeCount": 0, "datarate": 0, "deltaQueueDiscarded": "0", "deltaQueueFailed": "0", "entityId": "", "frequencyBand": "", "interference": 0, "logCloudBitsPerSec": 0, "logMediaBitsPerSec": 0, "logSocialBitsPerSec": 0, "logTotalBitsPerSec": 0, "maxClientCount": 0, "maxClientRssi": 0, "maxClientSnr": 0, "maxDatarate": 0, "maxInterference": 0, "maxTraffic": 0, "maxUtilization": 0, "minClientRssi": 0, "minClientSnr": 0, "numClientsSuccessFailOnboarding": "", "numClientsSuccessFailRoamIn": "", "numFailOnboarding": "0", "numFailRoamIn": "0", "numFailRoamOut": "", "numSuccessOnboarding": "", "numSuccessRoamIn": "", "numSuccessRoamOut": "", "packetFailureRate": 0, "radioClientCount": 0, "radioDistinctAppCount": 0, "radioProtocol": null, "radioResetCount": 0, "siteHierarchyArray": [],"siteId": "", "slot": 0, "timestamp": "", "traffic": 0, "utilization":0 }
                        let apNameForReference = `${e.apname}_${e.frequency}`
                        let device = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'hostname':e.apname})
                        let genratdHtMapData = generateHeatMapDataForTargetKpi(eachKpi,recordLength,DateToConsider, device[0].assuranceHealthScore,e.apname,e.frequency)
                        refDateObj.refDate = dte
                        refDateObj.targetKpi = eachKpi
                        refDateObj.apname = e.apname
                        refDateObj.data = genratdHtMapData
                        refDateObj.frequency = e.frequency
                        refDateObj.clientCount = e.clientCount
                        refDateObj.radioEntityId = e.radioEntityId
                        refDateObj.apEntityId = e.radioEntityId
                        SimLokiDatabaseActions.insert('kairos-heatmap-daily',refDateObj)
                    })
                })
            })
        },

        doProcessRestRequest: function(urlAction){
            var data;
            switch(urlAction.method) {
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                case 'POST':
                    data = processPostRequest(urlAction);
                    break;

                case 'DELETE':
                    data = processDeleteRequest(urlAction);
                    break;

                default:
                    break;
            }
            return data;
        }
    };

    function processGetRequest(urlAction) {
        
        if(urlAction.service.indexOf('proxy')>-1 && urlAction.service.indexOf('status')>-1) {
            return {"isOnboarded":true};
        }
        if(urlAction.url.indexOf("status") >-1) {
            return {"isOnboarded":true,"isCloudReachable":true,"cloudReacheableError":"","customerID":"ldz6ctrh","cloudHost":"https://api.use1.prd.kairos.ciscolabs.com"}
        }
        if(urlAction.url.indexOf("config/data") > -1){
            return {"cloud-location":"us-east-1","data-dna-analytics-enabled":"true","data-kronos-enabled":"true","data-kronos-spoofing-collection-enabled":"true","data-kronos-spoofing-detection-enabled":"true","rrm-cloud-enabled":"true"}
        }
        
        if(urlAction.service.indexOf('proxy')>-1 && urlAction.service.indexOf('core-services')>-1) {
             return { "response": { "statusCode": 404 }
             };
        }

       if(urlAction.service.indexOf("kairos")>-1 && urlAction.service.indexOf("notifications")>-1) {
            return { "response": { "statusCode": 200 } };
       }

       if(urlAction.service.indexOf("kairos")>-1 && urlAction.service.indexOf("customer-id")>-1  && (urlAction.service.indexOf('proxy')>-1 && urlAction.service.indexOf('core-services')>-1)) {
           return {"data":{"allFeedback36S":{"__typename":"Feedback36SConnection","nodes":[]}}};
        }
            
       if(urlAction.service.indexOf("kairos")>-1 && urlAction.service.indexOf("products")>-1) {
           return {"version":"1.0.0","products":[{"name":"rrm-cloud-enabled", "allowed":true},{"name":"kairos","allowed":true},{"name":"sunray","allowed":true},{"name":"kronos","allowed":true},{"name":"hydra-collection","allowed":true},{"name":"hydra-detection","allowed":true},{"name":"data-kronos-spoofing-detection-enabled","allowed":true},{"name":"data-kronos-spoofing-collection-enabled","allowed":true}]};
       }
    }

    function processPostRequest(urlAction) {
        
        if(urlAction.service.indexOf('dummy-token') > -1) { return {}; }
        if(urlAction.url.indexOf('sunray/customer-id/compute/aps') > -1 && urlAction.restPayload.simulation == true){
            return {"buildingId":urlAction.restPayload.buildingId,"requestId":1677648286431}
        }
        const randomize = (o,ks) => ks.forEach(k=>{
            let key = typeof(k)==='object'&&k.length?k[0]:k
            let val = typeof(k)==='object'&&k.length?k[1]:o[k]
            o[k]=o[k]*(1+(Math.random()*5))})

        const earlier = (d1,d2)=>d1>d2?d2:d1
        
        var operationName = urlAction.restPayload.operationName, variables = urlAction.restPayload.variables;
        var rootCauseId = variables.rootCauseId;
        var apMac = variables.apMac;
        if(urlAction.service.indexOf('graphql')>-1) {
            if(operationName == 'getInsightCategoryCounts') {
                //Called from DNAC Dashboard
                return SimKairosData.getInsightCategoryCounts();
            }          
            if(operationName==="getPcRecordList") {
                //Site Comparison - Clustering KPIs
                //return SimKairosTemplate.getPcRecordList
                return SimKairosData.getPcRecordList(variables);
            }
            //Code for AP performance advisories
            if (operationName === "getClientInsightHistory") {
                return SimKairosData.getClientInsightHistoryData();
            }
            if (operationName === "getAllInsightQuery") {
                let data = SimGraphQL.getData(urlAction.restPayload);
                // let staticData = SimKairosData.getAllInsightQueryData(variables);
                //data.data.getAllInsight36.nodes = 
                   // data.data.getAllInsight36.nodes.concat(staticData.data.getAllInsight36.nodes);
                return data;
            }
            if (operationName === "getClientInsightSummary") {
                var clientInsightSummaryTemplate;

                var date = new Date();

                var currentTimestamp = UtilityFunctions.getGivenTimeInGivenFormat(date.getTime(), "YYYY-MM-DDTHH:mm:ss");

                console.log(new Date(variables.endTimeStamp).getTime());

                console.log("Time in String format", new Date('2021-10-27T00:00:00').getTime());
                if (variables.endTimeStamp === "2021-10-27T00:00:00") {
                    clientInsightSummaryTemplate = SimKairosData.getClientInsightSummaryData(variables);
                    clientInsightSummaryTemplate.data.getClientInsightSummary36.nodes.push(SimKairosData.getClientInsightSummaryHighClientActivityNode());
                    clientInsightSummaryTemplate.data.getClientInsightSummary36.nodes.push(SimKairosData.getClientInsightSummaryExternalRFNode());
                    clientInsightSummaryTemplate.data.getClientInsightSummary36.nodes.push(SimKairosData.getClientInsightSummaryHighDensityNode());
                    clientInsightSummaryTemplate.data.getClientInsightSummary36.nodes.push(SimKairosData.getClientInsightSummaryLowDensityNode());

                } else if (variables.endTimeStamp === "2021-11-24T00:00:00") {
                    clientInsightSummaryTemplate = SimKairosData.getClientInsightSummaryData(variables);

                    clientInsightSummaryTemplate.data.getClientInsightSummary36.nodes.push(SimKairosData.getClientInsightSummaryHighClientActivityNode());
                    clientInsightSummaryTemplate.data.getClientInsightSummary36.nodes.push(SimKairosData.getClientInsightSummaryExternalRFNode());
                    clientInsightSummaryTemplate.data.getClientInsightSummary36.nodes.push(SimKairosData.getClientInsightSummaryHighDensityNode());


                } else if (variables.endTimeStamp === "2021-12-01T00:00:00") {
                    clientInsightSummaryTemplate = SimKairosData.getClientInsightSummaryData(variables);
                    clientInsightSummaryTemplate.data.getClientInsightSummary36.nodes.push(SimKairosData.getClientInsightSummaryHighDensityNode());
                    clientInsightSummaryTemplate.data.getClientInsightSummary36.nodes.push(SimKairosData.getClientInsightSummaryLowDensityNode());

                } else if (variables.endTimeStamp === "2022-01-19T00:00:00") {
                    clientInsightSummaryTemplate = SimKairosData.getClientInsightSummaryData(variables);
                    clientInsightSummaryTemplate.data.getClientInsightSummary36.nodes.push(SimKairosData.getClientInsightSummaryHighClientActivityNode());
                    clientInsightSummaryTemplate.data.getClientInsightSummary36.nodes.push(SimKairosData.getClientInsightSummaryExternalRFNode());

                } else {

                    clientInsightSummaryTemplate = SimKairosData.getClientInsightSummaryData(variables);
                    clientInsightSummaryTemplate.data.getClientInsightSummary36.nodes.push(SimKairosData.getClientInsightSummaryHighClientActivityNode());
                    clientInsightSummaryTemplate.data.getClientInsightSummary36.nodes.push(SimKairosData.getClientInsightSummaryExternalRFNode());
                    clientInsightSummaryTemplate.data.getClientInsightSummary36.nodes.push(SimKairosData.getClientInsightSummaryHighDensityNode());
                    clientInsightSummaryTemplate.data.getClientInsightSummary36.nodes.push(SimKairosData.getClientInsightSummaryLowDensityNode());
                }
                return clientInsightSummaryTemplate;
            }
            //Code for get List Page Summary Data
            if (operationName === "getListPageSummaryData") {
                var listPageTemplate;

                if (rootCauseId === "a70b30fe3321351b19dd") {
                    listPageTemplate = SimKairosData.getListPageSummaryDataForLowDensityData();

                } else if (rootCauseId === "a7960cbabf5293d7cbe6") {
                    listPageTemplate = SimKairosData.getListPageSummaryDataForHighDensityData();

                } else if (rootCauseId === "a72edc5da049e7f555a3") {
                    listPageTemplate = SimKairosData.getListPageSummaryDataForExternalRFLoadData();

                } else if (rootCauseId === "a71d771533202605c099") {
                    listPageTemplate = SimKairosData.getListPageSummaryDataForHighClientActivityData();
                }
                return listPageTemplate;
            }

            if (operationName === "allFeedbackByUserQuery") {                
                return {"data":{"allFeedback36S":{"__typename":"Feedback36SConnection","nodes":[]}}} ;
            }
            if (operationName === "insightEntityDetails") {
                var data = SimGraphQL.getData(urlAction.restPayload);
                data.data.insight36EntityDetails.nodes = 
                    data.data.insight36EntityDetails.nodes.concat(SimKairosInsightsTemplate.data.insight36EntityDetails.nodes);
                //return SimKairosInsightsTemplate;
                return data;
            }
            if (operationName === "insightDetails") {
                var data = SimGraphQL.getData(urlAction.restPayload);
                data.data.insight36DetailsV2.nodes = 
                    data.data.insight36DetailsV2.nodes.concat(SimKairosInsightDetail.data.insight36DetailsV2.nodes);
                //return SimKairosInsightDetail;
                return data;
            }
            if (operationName === "getListPageAnalysisData") {
                var listPageTemplate;
                if (rootCauseId === "a70b30fe3321351b19dd") {
                    listPageTemplate = SimKairosData.getListPageAnalysisDataForLowDensityData();

                } else if (rootCauseId === "a72edc5da049e7f555a3") {
                    listPageTemplate = SimKairosData.getListPageAnalysisDataForExternalRFLoadData();

                } else if (rootCauseId === "a71d771533202605c099") {
                    listPageTemplate = SimKairosData.getListPageAnalysisDataForHighClientActivityData();

                } else if (rootCauseId === "a7960cbabf5293d7cbe6") {
                    listPageTemplate = SimKairosData.getListPageAnalysisDataForHighAPDensityData();
                }
                return listPageTemplate;
            }
            if (operationName === "getClientInsightRadios") {
                var listPageTemplate;
                if (rootCauseId === "a70b30fe3321351b19dd") {
                    listPageTemplate = SimKairosData.getClientInsightRadiosDataForLowDensityData();

                } else if (rootCauseId === "a72edc5da049e7f555a3") {
                    listPageTemplate = SimKairosData.getClientInsightRadiosDataForExternalRFLoadData();

                } else if (rootCauseId === "a71d771533202605c099") {
                    listPageTemplate = SimKairosData.getClientInsightRadiosDataForHighClientActivityData();

                } else if (rootCauseId === "a7960cbabf5293d7cbe6") {
                    listPageTemplate = SimKairosData.getClientInsightRadiosDataForHighDensityData();
                }
                return listPageTemplate;
            }
            if (operationName === "getWLCFirstTimeStamp") {
                return SimKairosData.getWLCFirstTimeStampData(variables);
            }
            if (operationName === "getBaselineDetailData") {
                return SimKairosData.getBaselineDetailDataForLowDensityData();
            }
            if (operationName === "getApDetailData") {
                var apDetailTemplate;
                console.log("Inside getApDetailData");
                if (apMac === "a0:f8:49:7e:c0:57") {
                    apDetailTemplate = SimKairosData.getApDetailDataForLowAPDensityAP1Data();

                } else if (apMac === "a0:f8:49:7e:c0:55") {
                    apDetailTemplate = SimKairosData.getApDetailDataForLowAPDensityAP2Data();

                } else if (apMac === "a0:f8:49:7e:c0:56") {
                    apDetailTemplate = SimKairosData.getApDetailDataForLowAPDensityAP3Data();

                } else if (apMac === "a0:f8:49:7e:c0:53") {
                    apDetailTemplate = SimKairosData.getApDetailDataForLowAPDensityAP4Data();

                } else if (apMac === "a0:f8:49:7e:c0:43") {
                    apDetailTemplate = SimKairosData.getApDetailDataForLowAPDensityAP5Data();

                } else if (apMac === "a0:f8:49:7e:c0:50") {
                    apDetailTemplate = SimKairosData.getApDetailDataForLowAPDensityAP6Data();

                } else if (apMac === "a0:f8:49:7e:c0:41") {
                    apDetailTemplate = SimKairosData.getApDetailDataForLowAPDensityAP7Data();

                } else if (apMac === "a0:f8:49:7e:c0:44") {
                    apDetailTemplate = SimKairosData.getApDetailDataForLowAPDensityAP8Data();

                } else if (apMac === "a0:f8:49:7e:c0:42") {
                    apDetailTemplate = SimKairosData.getApDetailDataForLowAPDensityAP9Data();

                } else if (apMac === "a0:f8:49:7e:c0:52") {
                    apDetailTemplate = SimKairosData.getApDetailDataForLowAPDensityAP10Data();

                } else if (apMac === "a0:f8:49:7e:c0:59") {
                    apDetailTemplate = SimKairosData.getApDetailDataForLowAPDensityAP11Data();

                } else if (apMac === "a0:f8:49:7e:c0:51") {
                    apDetailTemplate = SimKairosData.getApDetailDataForLowAPDensityAP12Data();

                } else if (apMac === "a0:f8:49:7e:c0:65") {
                    apDetailTemplate = SimKairosData.getApDetailDataForLowAPDensityAP13Data();

                } else if (apMac === "a0:f8:49:7e:c0:67") {
                    apDetailTemplate = SimKairosData.getApDetailDataForLowAPDensityAP14Data();

                } else if (apMac === "a0:f8:49:7e:c0:66") {
                    apDetailTemplate = SimKairosData.getApDetailDataForLowAPDensityAP15Data();

                } else if (apMac === "a0:f8:49:7e:c0:58") {
                    apDetailTemplate = SimKairosData.getApDetailDataForLowAPDensityAP16Data();

                } else if (apMac === "a0:f8:49:7e:c0:40") {
                    apDetailTemplate = SimKairosData.getApDetailDataForLowAPDensityAP17Data();

                } else if (apMac === "a0:f8:49:7e:c0:54") {
                    apDetailTemplate = SimKairosData.getApDetailDataForLowAPDensityAP18Data();

                    // AP Detail Data for High Co-Channel Interference
                } else if (apMac === "a0:f8:49:7e:c0:60") {
                    apDetailTemplate = SimKairosData.getApDetailDataForHighCoChannelAP1Data();

                } else if (apMac === "a0:f8:49:7e:c0:61") {
                    apDetailTemplate = SimKairosData.getApDetailDataForHighCoChannelAP2Data();

                } else if (apMac === "a0:f8:49:7e:c0:62") {
                    apDetailTemplate = SimKairosData.getApDetailDataForHighCoChannelAP3Data();

                } else if (apMac === "a0:f8:49:7e:c0:63") {
                    apDetailTemplate = SimKairosData.getApDetailDataForHighCoChannelAP4Data();

                } else if (apMac === "a0:f8:49:7e:c0:64") {
                    apDetailTemplate = SimKairosData.getApDetailDataForHighCoChannelAP5Data();

                } else if (apMac === "a0:f8:49:7e:c0:69") {
                    apDetailTemplate = SimKairosData.getApDetailDataForHighCoChannelAP6Data();

                } else if (apMac === "a0:f8:49:7e:c0:75") {
                    apDetailTemplate = SimKairosData.getApDetailDataForHighCoChannelAP7Data();

                } else if (apMac === "a0:f8:49:7e:c0:76") {
                    apDetailTemplate = SimKairosData.getApDetailDataForHighCoChannelAP8Data();

                } else if (apMac === "a0:f8:49:7e:c0:77") {
                    apDetailTemplate = SimKairosData.getApDetailDataForHighCoChannelAP9Data();

                    // AP Detail Data for High Client Activity
                } else if (apMac === "a0:f8:49:7e:c0:70") {
                    apDetailTemplate = SimKairosData.getApDetailDataForHighClientActivityAP1Data();
                } else if (apMac === "a0:f8:49:7e:c0:78") {
                    apDetailTemplate = SimKairosData.getApDetailDataForHighClientActivityAP2Data();
                } else if (apMac === "a0:f8:49:7e:c0:79") {
                    apDetailTemplate = SimKairosData.getApDetailDataForHighClientActivityAP3Data();

                    // AP Detail Data for High AP Density
                } else if (apMac === "a0:f8:49:7e:c0:71") {
                    apDetailTemplate = SimKairosData.getApDetailDataForHighAPDensityAP1Data();
                } else if (apMac === "a0:f8:49:7e:c0:72") {
                    apDetailTemplate = SimKairosData.getApDetailDataForHighAPDensityAP2Data();
                }
                return apDetailTemplate;
            }
            
            //Kairos Peer Comparison is not working 
            // if (operationName == 'smartDashboardHistogramAggregatedWeekday') {
            //     var variable = variables.variable
            //     var nodes = SimLokiDatabaseActions.getFilteredRecordHandler('kairos-peer-comparision-Aggregated-Weekday-Data', { 'variable': variable });
            //     var response = SimKairosTemplate.AGGREGATED_WEEKDAY_RESPONSE_TEMPLATE
            //     response.data.data.nodes = nodes;
            //     response.data.data.totalCount = nodes.length;
            //     return response;
            // }
            // if (operationName == 'smartDashboardHistogramAggregated') {
            //     var variable = variables.variable
            //     var nodes = SimLokiDatabaseActions.getFilteredRecordHandler('kairos-peer-comparision-Aggregated-Data', { 'variable': variable });
            //     var response = SimKairosTemplate.AGGREGATED_WEEKDAY_RESPONSE_TEMPLATE
            //     response.data.data.nodes = nodes;
            //     response.data.data.totalCount = nodes.length;
            //     return response;
            //  } 
            // if (operationName == null && urlAction.restPayload.query.indexOf('logTotalBitsPerSec') > -1) {
            //         var peerComparisonData = SimLokiDatabaseActions.getAll("kairos-peer-comparision-totalKpisData");
            //         return {data: peerComparisonData[0]}
            //  }
            // }
            //Kairos Peer Comparison is not working 

            // Kairos peer comparison code start for Guardian Release 
            if (operationName === "smartDashboardHistogramAggregatedWeekday") {
                return SimKairosData.getsmartDashboardHistogramAggregatedWeekday(variables);
            }
            if (operationName === "smartDashboardHistogramAggregated") {
                return SimKairosData.getsmartDashboardHistogramAggregated(variables);
            }
            if (operationName == null &&
                urlAction.restPayload.query.replace('{', '').replace(/\n/g, '').trim().indexOf('logTotalBitsPerSec') == 0) {
                return SimKairosData.getSmartDashboardPeers36SConnection(urlAction);
            }
            //Kairos Peer Comparison Code End

            if(operationName==="getPcDistributionDetailRankConnection") {
                //Site Comparison - Building drop down with getPcRecordSitesWithCluster
                return SimKairosData.getPcDistributionDetailRankConnection(variables);
          }
            if(operationName==="getPcRecordSitesWithCluster") {
                //Site Comparison - Building drop down with getPcDistributionDetailRankConnection
                return SimKairosData.getPcRecordSitesWithCluster();
            }
            if(operationName==="getPcDistributionDetailRange") {
                return SimKairosData.getPcDistributionDetailRange(urlAction);
            }
            if(operationName==="getPcMetadataDetail") {
                return SimKairosData.getPcMetadataDetail(urlAction);
            }
            if(operationName==='monthlyHeatmapRadioPaginatedInTimeRange'  || operationName==='monthlyHeatmapRadioWifi6ePaginatedInTimeRange' ) {
                return SimKairosData.getMonthlyHeatmapRadio(urlAction);
            }
            if(operationName==='radioDayDayWifi6ePaginatedInTimeRange') {
                return SimKairosData.getDayHeatmapRadio(urlAction);
            }
            
            if(operationName==='getRadioEntities'){
                return SimKairosData.getRadioEntities(variables);
            }
            if(operationName === "getGroupStatsV3ForAllBuildings"){
                return SimKairosData.getGroupStatsV3ForAllBuildings();
            }
            if(operationName === 'getPcRecordClusterWithApModels'){
                return SimKairosData.getPcRecordClusterWithApModels(variables);
            }
            if(operationName == 'getGroupStatsForAllBuildings'){ //passing empty data as no reference cluster, once we get reference cluster need to handle this call
                return SimKairosData.getGroupStatsForAllBuildings(urlAction);
            }

            // Calls for EnhancedRRM - Start

            if(operationName == "getRfCoverageSummaryLatest01"){
                return SimEnhancedRRMData.getRfCoverageSummaryLatest01(variables)
            }
            if(operationName == "getRfPerformanceSummaryLatest01"){
                return SimEnhancedRRMData.getRfPerformanceSummaryLatest01(variables)
            }
            if(operationName == "getCurrentInsights01"){
                return SimEnhancedRRMData.getCurrentInsights01(variables)
            }
            if(operationName == "getRrmChangesLatestSummaryV201"){
                return SimEnhancedRRMData.getRrmChangesLatestSummaryV201(variables)
            }
            if(operationName == "getRrmChangesLatestSummary01"){ //need to review this as its not in latest code
                return SimEnhancedRRMData.getRrmChangesLatestSummary01(variables)
            }
            if(operationName == "getRrmHealthLatestSummary01"){
                return SimEnhancedRRMData.getRrmHealthLatestSummary01(variables)
            }
            if(operationName == "getCoChannelInterferenceLatestSummary01"){
                return SimEnhancedRRMData.getCoChannelInterferenceLatestSummary01(variables)
            }
            if(operationName == "getUtilizationPerChannelLatestSummary01"){
                return SimEnhancedRRMData.getUtilizationPerChannelLatestSummary01(variables)
            }
            if(operationName == "getApRogueAndRadarPerChannelLatestSummary01"){
                return SimEnhancedRRMData.getApRogueAndRadarPerChannelLatestSummary01(variables)
            }
            if(operationName == "getApSpatialDensityLatestSummary01"){
                return SimEnhancedRRMData.getApSpatialDensityLatestSummary01(variables)
            }
            if(operationName == "getPowerDistributionLatestSummary01"){
                return SimEnhancedRRMData.getPowerDistributionLatestSummary01(variables)
            }
            if(operationName == "getApSpatialDensityTrendSummary01"){
                return SimEnhancedRRMData.getApSpatialDensityTrendSummary01(variables)
            }
            if(operationName == "getRrmHealthTrendSummary01"){
                return SimEnhancedRRMData.getRrmHealthTrendSummary01(variables)
            }
            if(operationName == "getFraLatestDetail01"){
                return SimEnhancedRRMData.getFraLatestDetail01(variables)
            }
            if(operationName == "getFraLatestSummary01"){
                return SimEnhancedRRMData.getFraLatestSummary01(variables)
            }
            if(operationName == "getFraTrendSummary01"){
                return SimEnhancedRRMData.getFraTrendSummary01(variables)
            }
            if(operationName == "getCoChannelInterferenceTrendSummary01"){
                return SimEnhancedRRMData.getCoChannelInterferenceTrendSummary01(variables)
            }
            if(operationName == "getPowerDistributionTrendSummary01"){
                return SimEnhancedRRMData.getPowerDistributionTrendSummary01(variables)
            }
            if(operationName == "getRrmChangesTrendSummaryV201"){
                return SimEnhancedRRMData.getRrmChangesTrendSummaryV201(variables)
            }
            if(operationName == "getRrmChangesTrendSummary01"){
                return SimEnhancedRRMData.getRrmChangesTrendSummary01(variables)
            }
            if(operationName == "getUtilizationPerChannelTrendSummary01"){
                return SimEnhancedRRMData.getUtilizationPerChannelTrendSummary01(variables)
            }
            if(operationName == "getApRogueRadarPerChannelTrendSummary01"){
                return SimEnhancedRRMData.getApRogueRadarPerChannelTrendSummary01(variables)
            }
            if(operationName == "getRrmChangesLatestDetail01"){
                return SimEnhancedRRMData.getRrmChangesLatestDetail01(variables)
            }
            if(operationName == "getRrmChangesTrendDetail01"){
                return SimEnhancedRRMData.getRrmChangesTrendDetail01(variables)
            }
            if(operationName == "getRrmHealthLatestDetail01"){
                return SimEnhancedRRMData.getRrmHealthLatestDetail01(variables)
            }
            if(operationName == "getRrmHealthTrendDetail01"){
                return SimEnhancedRRMData.getRrmHealthTrendDetail01(variables)
            }
            if(operationName == "getCoChannelInterferenceLatestDetail01"){
                return SimEnhancedRRMData.getCoChannelInterferenceLatestDetail01(variables)
            }
            if(operationName == "getCoChannelInterferenceTrendDetail01"){
                return SimEnhancedRRMData.getCoChannelInterferenceTrendDetail01(variables)
            }
           if(operationName == "getApSpatialDensityLatestDetail01"){
                return SimEnhancedRRMData.getApSpatialDensityLatestDetail01(variables)
            }   
           if(operationName == "getApSpatialDensityTrendDetail01"){
                return SimEnhancedRRMData.getApSpatialDensityTrendDetail01(variables)
            }
           if(operationName == "getPowerDistributionLatestDetail01"){
                return SimEnhancedRRMData.getPowerDistributionLatestDetail01(variables)
            }
           if(operationName == "getPowerDistributionTrendDetail01"){
                return SimEnhancedRRMData.getPowerDistributionTrendDetail01(variables)
            }
            if(operationName == "getServiceabilityRadioLevel01"){
                return SimEnhancedRRMData.getServiceabilityRadioLevel01(variables)
            }
            if(operationName == "getServiceabilityBuildingLevel01"){
                return SimEnhancedRRMData.getServiceabilityBuildingLevel01(variables)
            }
            

            // Calls for EnhancedRRM - End

            //Temp codes for AI/ML Issue handling - in Progress - start
            if(operationName == 'issueEventDetailsById') {
                return SimKairosData.getIssueEventDetailsById(variables.issueId);
            } else if(operationName == 'eventById') {
                return SimKairosData.getEventById(variables.issueId);
            } else if(operationName == 'onboardingAndParents') {
                if(variables.useCase =="onboarding-time") {
                    if(variables.orderByRadio == "avgFailedOnboardingSecs")
                        return SimKairosData.getOnboardingTimeAvgFailed(variables.onboardingEntityId, variables.startTimestamp, variables.endTimestamp);
                    else if(variables.orderByRadio == "numFailedOnboardings")
                        return SimKairosData.getOnboardingTimeNumFailed(variables.onboardingEntityId, variables.startTimestamp, variables.endTimestamp);
                } else if(variables.useCase =="anomalous") {
                    if(variables.orderByRadio == "avgSuccessfulOnboardingSecs")
                        return SimKairosData.getAnomalousSuccess(variables.onboardingEntityId, variables.startTimestamp, variables.endTimestamp);
                    if(variables.orderByRadio == "avgFailedOnboardingSecs" || variables.orderByRadio == "avgHasFailed" || 
                        variables.orderByRadio == "numFailedOnboardings")
                        return SimKairosData.getAnamalousFailed(variables.onboardingEntityId, variables.startTimestamp, variables.endTimestamp, variables.orderByRadio);
                } else if(variables.useCase == 'onboarding-failure') {
                    if(variables.orderByRadio == "numFailedOnboardings" || variables.orderByRadio == "numClients")
                        return SimKairosData.getOnboardingNumFailed(variables.onboardingEntityId, variables.startTimestamp, variables.endTimestamp);
                    else if(variables.orderByRadio == "avgHasFailed")
                        return SimKairosData.getOnboardingAvgHasFailed(variables.onboardingEntityId, variables.startTimestamp, variables.endTimestamp);
                }else if(variables.useCase == 'dhcpTime') {
                    if(variables.orderByRadio == "numFailedOnboardings")
                        return SimKairosData.getDhcpOnboardingNumFailed(variables.onboardingEntityId, variables.startTimestamp, variables.endTimestamp);
                    else if(variables.orderByRadio == "avgSuccessfulDhcpTime")
                        return SimKairosData.getavgSuccessfulDhcpTime(variables.onboardingEntityId, variables.startTimestamp, variables.endTimestamp);
               }else if(urlAction.restPayload.query.indexOf('query onboardingAndParents') > -1) {
                     return SimKairosData.getOnboardingAndParentsAllKpis(variables.onboardingEntityId, variables.startTimestamp, variables.endTimestamp);
               }else {
                    return SimKairosData.getOnboardingAndParents(variables.onboardingEntityId, variables.startTimestamp, variables.endTimestamp);
               }
            } else if(operationName == 'eventsInTimeRangeQuery') {
                return SimKairosData.getEventsInTimeRangeQuery(variables.entityId, variables.startTimestamp, variables.endTimestamp);
            } else if(operationName == 'allIssueEventById') {
                return SimKairosData.getAllIssueEventById(variables.entityId, variables.issueId);
            } else if(operationName == 'thresholdByRadioIdQuery') {
                return SimKairosData.getThresholdByRadioIdQuery(variables.entityId, variables.startTimestamp, variables.endTimestamp);
            }  else if(operationName == null && urlAction.restPayload.query.indexOf('client36ByIssue36Id') > -1) {
                return SimKairosData.getConnectedClients(variables.issueId);
            } else if(operationName == 'radioClseqStatInRange') {
                if(urlAction.restPayload.query.indexOf('avgSuccessfulDhcpTime') > -1 && urlAction.restPayload.query.indexOf('failEventDistribution') > -1)
                    return SimKairosData.getRadioClientSuccessfulDhcp(variables.onboardingEntityId, variables.startTimestamp, variables.endTimestamp);
                else if(urlAction.restPayload.query.indexOf('failEventDistribution') > -1)
                    return SimKairosData.getRadioClientFailedDistribution(variables.onboardingEntityId, variables.startTimestamp, variables.endTimestamp);
                else  if(urlAction.restPayload.query.indexOf('totalSuccessfulOnboardingSecs') > -1)
                    return SimKairosData.getRadioClientFailedPercentage(variables.onboardingEntityId, variables.startTimestamp, variables.endTimestamp);
            } else if (operationName == 'radioInTimeRangeQuery') {
                if(urlAction.restPayload.query.indexOf('query radioInTimeRangeQuery') > -1) {
                    return SimKairosData.getRadioInTimeRangeQueryAllKpis(variables.radioEntityId, variables.startTimestamp, variables.endTimestamp);
                }
                else return SimKairosData.getRadioInTimeRangeQuery(variables.radioEntityId, variables.startTimestamp, variables.endTimestamp);
            }/* else if(operationName == undefined && urlAction.restPayload.query.indexOf('query radioInTimeRangeQuery') > -1) {
                return SimKairosData.getRadioInTimeRangeQueryAllKpis(variables.radioEntityId, variables.startTimestamp, variables.endTimestamp);
            }*/ else if (operationName == 'radioWithOnboarding') {
                return SimKairosData.getRadioWithOnboarding(variables.entityId,variables.startTimestamp, variables.endTimestamp);
            } else if(operationName == null && urlAction.restPayload.query.indexOf('radio36sInTimeRange') > -1) {
                return SimKairosData.getradio36sInTimeRange(variables.entityIds[0]);
            }else if ( operationName == "radioWithAppGroupInfoInTimeRange") {
                return SimKairosData.getRadioWithAppGroupInfo(variables.entityId,variables.startTimestamp, variables.endTimestamp);
            } else if (operationName == "getPcRecordClusterWithApDeviceFamily") {
                return SimKairosData.getPcRecord36ClusterWithDeviceFamily(variables.tsMin, variables.tsMax);
            } else if (operationName == "onboardingBaselineAggregated") {
                return SimKairosData.getOnboardingBaselineAggregated();
            } else if (operationName == "onboardingBaselineNetworkStats") {
                return SimKairosData.getOnboardingBaselineNetworkStats()
            } else if (operationName == 'onboardingBaselineBySite') {
                return SimKairosData.getOnboardingBaselineBySiteData(variables.siteId)
            } else if (operationName == 'thresholdInTimeRangeByOnboardingId') {
                return SimKairosData.getThresholdInTimeRangeByOnboardingIddata(variables.entityId,variables.startTimestamp, variables.endTimestamp)
            } else if (operationName == "eventInTimeRange") {
                return SimKairosData.getEventInTimeRangeData(variables.entityId, variables.startTimestamp, variables.endTimestamp);
            } else if (operationName == "onboardingAndParentsByOnboardingId" || operationName == 'onboardingInTimeRange') {
                return SimKairosData.getOnboardingAndParentsByOnboardingIdData(variables.entityId,variables.startTimestamp, variables.endTimestamp)
            } else if(operationName == "onboardingDhcpAssocAuthTimeAndParentsById") {
                return SimKairosData.getOnboardingDhcpAssocAuthTimeAndParentsByIdData(variables.entityId,variables.startTimestamp, variables.endTimestamp)
            } else if(operationName == "onboardingDhcpAssocAuthTimeInTimeRange") {
                return SimKairosData.getOnboardingDhcpAssocAuthTimeAndParentsByIdData(variables.onboardingEntityId,variables.startTimestamp, variables.endTimestamp)
            } else if (operationName == "onboardingDhcpAssocAuthFailureInTimeRange") {
                return SimKairosData.getOnboardingDhcpAssocAuthFailureInTimeRangeData(variables.entityId,variables.startTimestamp, variables.endTimestamp);
            } else if (operationName == "onboardingImpactedRadiosByFloor") {
                return SimKairosData.getOnboardingImpactedRadiosByFloorData(variables.issueList);
            } else if (operationName == "clientImpactedDevicesByIssueList") {
                return SimKairosData.getClientImpactedDevicesByIssueListData(variables.issueList)
            } else if (operationName == "onboardingAndRadioStatsBaselineDashboard") {
                return SimKairosData.getOnboardingAndRadioStatsBaselineDashboard(variables);
            } else if (operationName == "onboardingStatsByFloorAndDevice") {
                return SimKairosData.getOnboardingStatsByFloorAndDeviceData(variables);
                //Test
            } else if (operationName == "radioDayPaginatedInTimeRange") {
                return SimKairosData.getradioDayPaginatedInTimeRange(variables);
            }
            //Temp codes for AI/ML Issue handling - in Progress - end
            return SimGraphQL.getData(urlAction.restPayload);
        }
    }

    function processDeleteRequest(urlAction) {
    }

    function updateApNames(tJson) {
        var obj = SimKairosNetworkInsightTemplate.AP_RENAME;
        Object.keys(obj).forEach(function(key) {
            var regKey = new RegExp(key,'g');
            tJson = tJson.replace(regKey, obj[key]);
        });
        return tJson;
    }

    function updateSiteNames(tJson) {
        var obj = SimKairosNetworkInsightTemplate.SITE_RENAME;
        Object.keys(obj).forEach(function(key) {
            var regKey = new RegExp(key,'g');
            tJson = tJson.replace(regKey, obj[key]);
        });
        return tJson;
    }

    function updateTime(reduceDayBy, time) {
        var date = new Date();
        date.setDate(date.getDate() + reduceDayBy);//reduceDayBy is negative
        var timestamp = UtilityFunctions.getGivenTimeInGivenFormat(date.getTime(), "YYYY-MM-DDTHH:mm:ss");
        timestamp = timestamp.split("T");
        timestamp[1] = time;
        return timestamp[0]+"T"+timestamp[1];
    }

    function createTimingTemplate() {
        Object.keys(SimKairosIssuesDynGenTemplates.ISSUE_MAPPINGS).forEach(key => {
            if(SimKairosIssuesDynGenTemplates.ISSUE_MAPPINGS[key].issueDateTimes.length ==0) {
                Object.keys(SimKairosIssuesDynGenTemplates.ISSUE_MAPPINGS[key].issueTimings).forEach(k=>{
                    if(k!="length" && !isNaN(k)) {
                        var reduceBy = Number(k);
                        for(var i=0; i<SimKairosIssuesDynGenTemplates.ISSUE_MAPPINGS[key].issueTimings[k].length; i++) {
                            SimKairosIssuesDynGenTemplates.ISSUE_MAPPINGS[key].issueDateTimes.push(updateTime(reduceBy, SimKairosIssuesDynGenTemplates.ISSUE_MAPPINGS[key].issueTimings[k][i]));
                        };
                    }
                });
            }
        });
    }

    function updateNetworkInsightTimings(tJson, insightDateMappingSpecific) {
        for(var i=0; i<insightDateMappingSpecific.old.length; i++) {
            var regKey = new RegExp(insightDateMappingSpecific.old[i],'g');
            tJson = tJson.replace(regKey, insightDateMappingSpecific.new[i]);
        }
        return tJson;
    }

    function createInsightTimingMap(insightDateMapping) {
        Object.keys(insightDateMapping).forEach(function(insightId) {
            var randDate = moment().subtract(insightDateMapping[insightId].daysToSubtract, 'day');
            var endDate = UtilityFunctions.getSpecificWeekday(new Date(randDate), Days.MONDAY);
            for(var i=0; i<insightDateMapping[insightId].old.length; i++) {
                var formatted = moment(endDate).format("YYYY-MM-DDT00:00:00");
                insightDateMapping[insightId].new.push(formatted);
                endDate = moment(endDate).subtract(7,'day');
            }
        });
        return insightDateMapping;
    }
    function getAPUniqClientConctdData(data, apEntityID){
     let arr = [],uniq = {}, radioEntityId
        radioEntityId = UtilityFunctions.generateId(20)
        data.map( c => {
            let obj = {"clientCount":0,"frequency":'',"apname":"","radioEntityId":"", "apEntityId":""}
            obj.clientCount = data.length
            obj.frequency = c.frequency
            obj.apname = c.clientConnection
            obj.radioEntityId = radioEntityId
            obj.apEntityId = apEntityID
            arr.push(obj)
        })
        arr = arr.filter(obj => !uniq[obj.apname] && (uniq[obj.apname] = true));
        return arr
    }
    function generateHeatMapDataForTargetKpi(targetKpi,recordLength,DateToConsider,assuranceHealthScore,apName,frequency){
        let kpiValueList = [],minVal=0,maxVal=0;

        //executive demo fury explicit changes
        if(apName ==="SJC01_9136_12"){ 
            assuranceHealthScore = 10;
        }

            for(let i=0; i<recordLength ; i++){
                let obj = {"__typename":"HeatmapKpiValuesType","kpiValue":9,"timestamp":""}
                let date = getDateInTimestamp_SubtractedByDays_FromGivenDate(DateToConsider,i)
                let Date_Day = new Date(date).getDay()
                let kpiValGenetated
                if([1,2,3,4].indexOf(assuranceHealthScore)>-1){
                    if([0,6].indexOf(Date_Day)>-1){
                       details = {"logTotalBitsPerSec":[0,0],"logCloudBitsPerSec":[0,0],"logSocialBitsPerSec":[0,0],
                            "logMediaBitsPerSec":[0,0],"datarate":[0,10.1],"radioResetCount":[0,10],"channelChangeCount":[0,10],
                             "radioDistinctAppCount":[5,12],"packetFailureRate":[0,0],"deltaQueueFailed":[50000,60000],
                             "deltaQueueDiscarded":[0,3],"radioClientCount":[0,0],"interference":[20,30],"avgClientRssi":[-90,-70],
                             "avgClientSnr":[10,12],"traffic":[0,10],"utilization":[0,0]
                       }
                    }else {
                       details = {"logTotalBitsPerSec":[0,5.1],"logCloudBitsPerSec":[0,6.1],"logSocialBitsPerSec":[0,5.1],
                            "logMediaBitsPerSec":[0,7.1],"datarate":[40,60.1],"radioResetCount":[10,15],"channelChangeCount":[10,15],
                             "radioDistinctAppCount":[10,20],"packetFailureRate":[0,0.08],"deltaQueueFailed":[60000,70000],
                             "deltaQueueDiscarded":[0,3],"radioClientCount":[0,1],"interference":[20,30],"avgClientRssi":[-90,-70],
                             "avgClientSnr":[15,17],"traffic":[0,10],"utilization":[20,25]
                       }
                    }
                }else if([5,6,7,8].indexOf(assuranceHealthScore)>-1){
                    if([0,6].indexOf(Date_Day)>-1){
                        details = {"logTotalBitsPerSec":[0,3.1],"logCloudBitsPerSec":[0,4.1],"logSocialBitsPerSec":[0,3.1],
                            "logMediaBitsPerSec":[0,4.1],"datarate":[10,20.1],"radioResetCount":[0,10],"channelChangeCount":[0,10],
                             "radioDistinctAppCount":[5,15],"packetFailureRate":[0,0],"deltaQueueFailed":[10000,15000],
                             "deltaQueueDiscarded":[0,1],"radioClientCount":[3,5],"interference":[0,10],"avgClientRssi":[-60,-50],
                             "avgClientSnr":[18,20],"traffic":[0,5],"utilization":[0,5]
                        }
                    }else {
                       details = {"logTotalBitsPerSec":[12,17.1],"logCloudBitsPerSec":[10,30.1],"logSocialBitsPerSec":[10,20.1],
                            "logMediaBitsPerSec":[10,25.1],"datarate":[70,80.1],"radioResetCount":[0,10],"channelChangeCount":[0,10],
                             "radioDistinctAppCount":[20,30],"packetFailureRate":[0,0.9],"deltaQueueFailed":[10000,20000],
                             "deltaQueueDiscarded":[2,5],"radioClientCount":[20,25],"interference":[15,25],"avgClientRssi":[-60,-50],
                             "avgClientSnr":[18,20],"traffic":[10,15],"utilization":[30,45]
                       }
                    }
                }else {
                    if([0,6].indexOf(Date_Day)>-1){
                        details = {"logTotalBitsPerSec":[5,8.1],"logCloudBitsPerSec":[6,9.1],"logSocialBitsPerSec":[5,10.1],
                            "logMediaBitsPerSec":[5,8.1],"datarate":[20,30.1],"radioResetCount":[0,10],"channelChangeCount":[0,10],
                             "radioDistinctAppCount":[5,15],"packetFailureRate":[0,0],"deltaQueueFailed":[0,2000],
                             "deltaQueueDiscarded":[1,3],"radioClientCount":[4,7],"interference":[10,12],"avgClientRssi":[-50,-40],
                             "avgClientSnr":[20,30],"traffic":[5,10],"utilization":[0,10]
                       }
                    }else {
                       details = {"logTotalBitsPerSec":[20,25.1],"logCloudBitsPerSec":[20,33.1],"logSocialBitsPerSec":[20,35.1],
                            "logMediaBitsPerSec":[20,30.1],"datarate":[90,99.1],"radioResetCount":[0,10],"channelChangeCount":[0,10],
                             "radioDistinctAppCount":[40,50],"packetFailureRate":[0,0.7],"deltaQueueFailed":[0,5000],
                             "deltaQueueDiscarded":[6,5],"radioClientCount":[35,53],"interference":[0,10],"avgClientRssi":[-50,-40],
                             "avgClientSnr":[20,30],"traffic":[20,25],"utilization":[70,75]
                       }
                    }
                }
                if(details && Object.keys(details).length !== 0){
                    for(let key in details){
                        if(targetKpi === key){
                            minVal = details[key][0];maxVal = details[key][1];
                        }
                    }
                }
                if(targetKpi !== "packetFailureRate"){
                    maxVal = (frequency === "2.4")? maxVal : maxVal + 5
                }
                //fury executive demo ask
                if(targetKpi === "logTotalBitsPerSec" && apName ==="SJC01_9136_12"){
                    let insightLogTtlBts = SimLokiDatabaseActions.getFilteredRecordHandler('kairos-network-insight',{"insightId":"a5502c42bdcbd50da26e"})
                    let insightEndTime = insightLogTtlBts[0].endTimestamp
                    let insightEndTimeStamp = new Date(insightEndTime).getTime()
                    let insightStartTimeStamp= getDateInTimestamp_SubtractedByDays_FromGivenDate(insightEndTime,7)
                    // let insightEndDateDay = new Date(insightEndTimeStamp).getDay()
                    // let insightStDateDay = insightEndDateDay - 7
                    if(insightEndTimeStamp >= date && insightStartTimeStamp <= date){
                        minVal = 0.91 //0.9228932959748551
                        maxVal = 0.97
                    }else { 
                        if([0,6].indexOf(Date_Day)>-1){
                            minVal = 15
                            maxVal = 19.1
                        }else { 
                            minVal = 30
                            maxVal = 40.1
                        }
                    }                    
                }
                kpiValGenetated = Number.isInteger(maxVal) ? UtilityFunctions.getRandomIntForRangeValues(minVal,maxVal) :
                            UtilityFunctions.getRandomFloatForRangeValues(minVal,maxVal);
                obj.timestamp = getGivenDateAndTimeInIso(date)
                obj.kpiValue = kpiValGenetated
                kpiValueList.push(obj)
            }
        return kpiValueList
    }
    function getDateInTimestamp_SubtractedByDays_FromGivenDate(passedDate,noofday) {
        var d = new Date(passedDate);
        var d1 = d.setDate(d.getDate() - noofday);
        return d1;
    }

    function getApsDataInRequiredFormat(aps){
        let response = []
            aps.map( e => {
                let apEntityID = UtilityFunctions.generateId(20)
                let clients_2_4 = SimLokiDatabaseActions.getFilteredRecordHandler('host',{'$and': [
                        { 'connectedNetworkDeviceId':e.id }, { 'frequency':'2.4' } ]})
                let clients_5 = SimLokiDatabaseActions.getFilteredRecordHandler('host',{'$and': [
                        { 'connectedNetworkDeviceId':e.id }, { 'frequency':'5.0' } ]})
                let slot0 = [], slot1 = []
                if(clients_2_4.length>0){
                    slot0 = getAPUniqClientConctdData(clients_2_4,apEntityID )
                }
                if(clients_5.length>0){
                    slot1 = getAPUniqClientConctdData(clients_5,apEntityID )
                }
                response = [...response,...slot0, ...slot1]
            })
        return response
    }


    function getGivenDateAndTimeInIso(timeStamp){
                var ts = new Date(timeStamp);
                var time = ts.toISOString();
                time = time.split('T')[0]
                time = time+'T00:00:00'
                return time; //2020-12-09T00:00:00
    }

});