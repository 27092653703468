define({
    "Interface_Template": [{
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "105",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3046",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:f9",
        "speed": "1000000",
        "portName": "Vlan3046",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "002a9792-53ee-4daf-94e4-3300d9562293",
        "id": "002a9792-53ee-4daf-94e4-3300d9562293"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "460",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1710711",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "010b7380-84c6-4ec8-92e1-70cd460ac5d7",
        "id": "010b7380-84c6-4ec8-92e1-70cd460ac5d7"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "90",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3016",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:eb",
        "speed": "1000000",
        "portName": "Vlan3016",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "01455019-0315-4ec6-a25b-13cf638aabf1",
        "id": "01455019-0315-4ec6-a25b-13cf638aabf1"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "271",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1073075",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "0169f283-8f9b-48ad-abef-c18ce244c81d",
        "id": "0169f283-8f9b-48ad-abef-c18ce244c81d"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "407",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback646713",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "01b7739e-3cbf-44c7-ad60-385cbb6f5189",
        "id": "01b7739e-3cbf-44c7-ad60-385cbb6f5189"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "10",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "1",
        "portType": "Ethernet Port",
        "portMode": "dynamic_auto",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": "1",
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": "10/100/1000BaseTX",
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "DOWN",
        "macAddress": "00:af:1f:9e:58:88",
        "speed": "1000000",
        "portName": "GigabitEthernet1/0/8",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "AutoNegotiate",
        "instanceUuid": "034c96c2-940e-43fd-9470-0ae48e3ef608",
        "id": "034c96c2-940e-43fd-9470-0ae48e3ef608"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "332",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback746773",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "04d42b65-1244-43bd-8940-b266b3b7b505",
        "id": "04d42b65-1244-43bd-8940-b266b3b7b505"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "426",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback254268",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "054c2d00-7ca8-47cf-9a7d-9fd56dcc5105",
        "id": "054c2d00-7ca8-47cf-9a7d-9fd56dcc5105"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "204",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback423431",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "05c46cbd-9f08-498d-8d1d-849e275c1ac4",
        "id": "05c46cbd-9f08-498d-8d1d-849e275c1ac4"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "141",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "11",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:d4",
        "speed": "1000000",
        "portName": "Vlan11",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "07fbbc94-bc8e-4019-b47a-623a56c41b56",
        "id": "07fbbc94-bc8e-4019-b47a-623a56c41b56"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "80",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4117",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "08232daa-c3d2-4de6-8f0d-f3f892147c09",
        "id": "08232daa-c3d2-4de6-8f0d-f3f892147c09"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "276",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1113155",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "08f50da4-66b3-4338-a88f-ab7a840cad9a",
        "id": "08f50da4-66b3-4338-a88f-ab7a840cad9a"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "463",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1733741",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "0965debf-4d41-4edb-817f-03b6b274043b",
        "id": "0965debf-4d41-4edb-817f-03b6b274043b"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "183",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1300305",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "09927f65-5292-4b20-9823-7030185ba62b",
        "id": "09927f65-5292-4b20-9823-7030185ba62b"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "425",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback254266",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "0a29eb51-9dc8-4292-b603-26cae3183bc3",
        "id": "0a29eb51-9dc8-4292-b603-26cae3183bc3"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "461",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": "routed",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": "65.7.0.1",
        "mediaType": null,
        "ipv4Mask": "255.255.0.0",
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1710716",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "0b0ee462-4743-4b33-9631-ad3df03d2cf2",
        "id": "0b0ee462-4743-4b33-9631-ad3df03d2cf2"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "104",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3045",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:c4",
        "speed": "1000000",
        "portName": "Vlan3045",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "0b7c8ef7-a372-484e-a5e6-2ef1bc4b71e5",
        "id": "0b7c8ef7-a372-484e-a5e6-2ef1bc4b71e5"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "22",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "1",
        "portType": "Ethernet Port",
        "portMode": "dynamic_auto",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": "1",
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": "10/100/1000BaseTX",
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "DOWN",
        "macAddress": "00:af:1f:9e:58:94",
        "speed": "1000000",
        "portName": "GigabitEthernet1/0/20",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "AutoNegotiate",
        "instanceUuid": "0cc1af81-09d6-4711-967c-d1821c8935ff",
        "id": "0cc1af81-09d6-4711-967c-d1821c8935ff"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "443",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": "routed",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": "222.1.0.1",
        "mediaType": null,
        "ipv4Mask": "255.255.0.0",
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback722802",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "0cc40f51-49a5-4ac1-9795-abd475af5fec",
        "id": "0cc40f51-49a5-4ac1-9795-abd475af5fec"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "539",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4189",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "0d433eae-bc20-48d3-8181-2c88bd08a3c6",
        "id": "0d433eae-bc20-48d3-8181-2c88bd08a3c6"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "526",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4167",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "0d872834-5569-4d8f-a19a-4b104a90f05b",
        "id": "0d872834-5569-4d8f-a19a-4b104a90f05b"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "91",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3017",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:f1",
        "speed": "1000000",
        "portName": "Vlan3017",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "0e79ff13-b2ea-4e5f-a632-e93b085ea9f8",
        "id": "0e79ff13-b2ea-4e5f-a632-e93b085ea9f8"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "347",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback830859",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "0eaa7609-c1ef-4e6e-88b7-6d3043b22c47",
        "id": "0eaa7609-c1ef-4e6e-88b7-6d3043b22c47"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "255",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback868878",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "11adb35a-1761-414d-9dfe-1ff57044fc51",
        "id": "11adb35a-1761-414d-9dfe-1ff57044fc51"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "359",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1625630",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "121cca39-6e21-408e-8f5b-35dfdf2fbb2e",
        "id": "121cca39-6e21-408e-8f5b-35dfdf2fbb2e"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "544",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4143",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "1345f0fa-fc5f-44d2-9df8-02c64357a9b7",
        "id": "1345f0fa-fc5f-44d2-9df8-02c64357a9b7"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "530",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4171",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "14a20b0f-531e-4778-809a-0d25510bc86b",
        "id": "14a20b0f-531e-4778-809a-0d25510bc86b"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "531",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4172",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "15478632-a8b6-41de-9c52-69def9819d63",
        "id": "15478632-a8b6-41de-9c52-69def9819d63"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "222",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback548560",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "1652aa51-da26-4510-baed-88305563a10e",
        "id": "1652aa51-da26-4510-baed-88305563a10e"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "40",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3000",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:d7",
        "speed": "1000000",
        "portName": "Vlan3000",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "173f3c35-4000-4d12-a3b6-5df1e4f7c248",
        "id": "173f3c35-4000-4d12-a3b6-5df1e4f7c248"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "354",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1607609",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "19c5734c-6cc7-4703-8d6a-eec73e0ddfc1",
        "id": "19c5734c-6cc7-4703-8d6a-eec73e0ddfc1"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "451",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback722823",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "1a130ed2-bb34-4156-9537-88c37b7f6479",
        "id": "1a130ed2-bb34-4156-9537-88c37b7f6479"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "216",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback466474",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "1bf57e61-8f82-4c7a-9b42-e1d7c3e09119",
        "id": "1bf57e61-8f82-4c7a-9b42-e1d7c3e09119"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "311",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1763770",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "1c411653-bf4b-48d3-8696-412df666b414",
        "id": "1c411653-bf4b-48d3-8696-412df666b414"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "440",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback722794",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "1c6138cd-7f5e-4d98-b177-6a86453bab80",
        "id": "1c6138cd-7f5e-4d98-b177-6a86453bab80"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "416",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback722748",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "1c94b577-2937-4634-9e84-a4cf4583a4b6",
        "id": "1c94b577-2937-4634-9e84-a4cf4583a4b6"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "214",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback466471",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "1ccbd93d-dee1-428a-b558-29dbc00a0ab9",
        "id": "1ccbd93d-dee1-428a-b558-29dbc00a0ab9"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "355",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1607612",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "1ce374bb-0145-4fae-a6fb-e23dbc5f05c0",
        "id": "1ce374bb-0145-4fae-a6fb-e23dbc5f05c0"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "405",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback646703",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "1d0c1fd6-7ff2-47f1-bc79-15df3ca2e0b9",
        "id": "1d0c1fd6-7ff2-47f1-bc79-15df3ca2e0b9"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "450",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback722824",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "1d9eb76f-894f-43ff-9776-c3e79c9017b7",
        "id": "1d9eb76f-894f-43ff-9776-c3e79c9017b7"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "20",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "1",
        "portType": "Ethernet Port",
        "portMode": "dynamic_auto",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": "1",
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": "10/100/1000BaseTX",
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "DOWN",
        "macAddress": "00:af:1f:9e:58:92",
        "speed": "1000000",
        "portName": "GigabitEthernet1/0/18",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "AutoNegotiate",
        "instanceUuid": "1f24f9ec-7529-4ae6-b71c-d1766ffe6a0d",
        "id": "1f24f9ec-7529-4ae6-b71c-d1766ffe6a0d"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "277",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1113156",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "1fba0c6f-f65e-4c69-a79e-45dfdd2257d3",
        "id": "1fba0c6f-f65e-4c69-a79e-45dfdd2257d3"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "171",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1015077",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "23714e1c-305e-4208-9553-8fe7c41e13ee",
        "id": "23714e1c-305e-4208-9553-8fe7c41e13ee"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "444",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback722807",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "237eaf1a-10b5-4e74-a0e8-f62152a39c9f",
        "id": "237eaf1a-10b5-4e74-a0e8-f62152a39c9f"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "33",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "0",
        "portType": "Ethernet Port",
        "portMode": "dynamic_auto",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:9f",
        "speed": "10000000",
        "portName": "TenGigabitEthernet1/1/3",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "AutoNegotiate",
        "instanceUuid": "23dbddfd-b130-4c91-835f-f6d328142bd9",
        "id": "23dbddfd-b130-4c91-835f-f6d328142bd9"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "297",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1432444",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "23e8b081-5f4a-4693-b951-0a091f550990",
        "id": "23e8b081-5f4a-4693-b951-0a091f550990"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "481",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4116",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "2446aec6-ab4d-4b34-bfaa-9edd2db4858e",
        "id": "2446aec6-ab4d-4b34-bfaa-9edd2db4858e"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "133",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "31",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:d5",
        "speed": "1000000",
        "portName": "Vlan31",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "251c3048-c98e-43db-82b4-433825ba7300",
        "id": "251c3048-c98e-43db-82b4-433825ba7300"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "523",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4164",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "276b2232-5644-4ec4-98a9-6b32a7b02229",
        "id": "276b2232-5644-4ec4-98a9-6b32a7b02229"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "527",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4168",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "279e4461-7b5c-4345-8815-524a8531629f",
        "id": "279e4461-7b5c-4345-8815-524a8531629f"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "283",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1113167",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "2853caad-6481-4859-bcc4-9218dbf0eb90",
        "id": "2853caad-6481-4859-bcc4-9218dbf0eb90"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "263",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback868888",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "288e9836-ade9-4b87-ad91-45d8ddb9b7f9",
        "id": "288e9836-ade9-4b87-ad91-45d8ddb9b7f9"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "120",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3075",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:c3",
        "speed": "1000000",
        "portName": "Vlan3075",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "29668510-3033-463f-9392-bad1c100a01a",
        "id": "29668510-3033-463f-9392-bad1c100a01a"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "287",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1113176",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "29885f65-3a82-42fa-8fa8-b87b37313e75",
        "id": "29885f65-3a82-42fa-8fa8-b87b37313e75"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "25",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "30",
        "portType": "Ethernet Port",
        "portMode": "access",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": "1",
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": "10/100/1000BaseTX",
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "DOWN",
        "macAddress": "00:af:1f:9e:58:97",
        "speed": "1000000",
        "portName": "GigabitEthernet1/0/23",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "AutoNegotiate",
        "instanceUuid": "29fc36d5-a08b-4e6d-b66b-04c2b4bb6b2a",
        "id": "29fc36d5-a08b-4e6d-b66b-04c2b4bb6b2a"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "284",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1113171",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "2a2029fc-1f9a-42ec-a8c0-80810e1a6f4b",
        "id": "2a2029fc-1f9a-42ec-a8c0-80810e1a6f4b"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "301",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1596603",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "2a2f57c1-6237-43e1-adb8-e0fc2c8f36ad",
        "id": "2a2f57c1-6237-43e1-adb8-e0fc2c8f36ad"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "330",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback746762",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "2a747bd0-f4cc-4332-9088-6f0edaf300a8",
        "id": "2a747bd0-f4cc-4332-9088-6f0edaf300a8"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "298",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1454456",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "2ab30df7-2042-44a4-8f0f-a3b4c2bada00",
        "id": "2ab30df7-2042-44a4-8f0f-a3b4c2bada00"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "109",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3050",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:e6",
        "speed": "1000000",
        "portName": "Vlan3050",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "2c04b622-ce35-4fb6-9ad5-79a198286dc0",
        "id": "2c04b622-ce35-4fb6-9ad5-79a198286dc0"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "480",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4115",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "2c4bcbab-2795-4810-a4cf-23a25844ef80",
        "id": "2c4bcbab-2795-4810-a4cf-23a25844ef80"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "504",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4140",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "2e9b44b2-2a91-46da-9101-94ecd3d287cb",
        "id": "2e9b44b2-2a91-46da-9101-94ecd3d287cb"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "268",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback915932",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "2f266ef7-a79c-4e70-8706-cc5d0fcbf8b7",
        "id": "2f266ef7-a79c-4e70-8706-cc5d0fcbf8b7"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "15",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "1",
        "portType": "Ethernet Port",
        "portMode": "dynamic_auto",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": "1",
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": "10/100/1000BaseTX",
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "DOWN",
        "macAddress": "00:af:1f:9e:58:8d",
        "speed": "1000000",
        "portName": "GigabitEthernet1/0/13",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "AutoNegotiate",
        "instanceUuid": "31376d21-027f-4208-b039-8f655fc36908",
        "id": "31376d21-027f-4208-b039-8f655fc36908"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "422",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback254261",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "31779f0a-c6ee-4687-aa75-27a60c1ce472",
        "id": "31779f0a-c6ee-4687-aa75-27a60c1ce472"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "488",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4123",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "31a2cd0e-9946-4b5f-a9ea-39cfbe4950ff",
        "id": "31a2cd0e-9946-4b5f-a9ea-39cfbe4950ff"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "115",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3058",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:eb",
        "speed": "1000000",
        "portName": "Vlan3058",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "329ac32b-2632-40fb-a3d9-0bd512f9736c",
        "id": "329ac32b-2632-40fb-a3d9-0bd512f9736c"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "340",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1146162",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "32bedb3b-05b4-4e66-b4d4-3e2ea59a47e7",
        "id": "32bedb3b-05b4-4e66-b4d4-3e2ea59a47e7"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "398",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback646679",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "3316ebe6-01b3-4e8b-bf20-6e7405aa6298",
        "id": "3316ebe6-01b3-4e8b-bf20-6e7405aa6298"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "339",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback767770",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "33698972-24f7-4eca-86d3-4ad79abfc35a",
        "id": "33698972-24f7-4eca-86d3-4ad79abfc35a"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "515",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4155",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "33ca0df3-4290-49b4-80d4-8d8c8da32c74",
        "id": "33ca0df3-4290-49b4-80d4-8d8c8da32c74"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "394",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback646669",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "34d064c6-6bdb-49ca-8c4b-3b86eb736716",
        "id": "34d064c6-6bdb-49ca-8c4b-3b86eb736716"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "27",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "0",
        "portType": "Ethernet Port",
        "portMode": "dynamic_auto",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:99",
        "speed": "1000000",
        "portName": "GigabitEthernet1/1/1",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "AutoNegotiate",
        "instanceUuid": "35824561-1254-470f-bc06-fc7f5445e991",
        "id": "35824561-1254-470f-bc06-fc7f5445e991"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "522",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4162",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "35936941-ef64-4fec-8291-cad7b7a44e50",
        "id": "35936941-ef64-4fec-8291-cad7b7a44e50"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "395",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback646673",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "36932fb9-aa06-4c0c-acc9-0b58aea1f8f3",
        "id": "36932fb9-aa06-4c0c-acc9-0b58aea1f8f3"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "542",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4135",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "36e073b6-071e-4a08-bbc5-3c72e92086a1",
        "id": "36e073b6-071e-4a08-bbc5-3c72e92086a1"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "341",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3008",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:ce",
        "speed": "1000000",
        "portName": "Vlan3008",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "382fd8cc-04b4-4188-802f-6bda25b9a9bc",
        "id": "382fd8cc-04b4-4188-802f-6bda25b9a9bc"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "546",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": "routed",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": "101.56.0.1",
        "mediaType": null,
        "ipv4Mask": "255.255.0.0",
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4102",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "390928a7-313b-4d8b-a661-88dd755fee7b",
        "id": "390928a7-313b-4d8b-a661-88dd755fee7b"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "509",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4148",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "39a80024-4c08-466c-9fd6-f0f62e762bd9",
        "id": "39a80024-4c08-466c-9fd6-f0f62e762bd9"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "89",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3015",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:df",
        "speed": "1000000",
        "portName": "Vlan3015",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "3a97b748-48be-4e4c-9814-ec4a27a43c75",
        "id": "3a97b748-48be-4e4c-9814-ec4a27a43c75"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "528",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4169",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "3b2f53cf-d46f-4329-b35a-c6071d84beca",
        "id": "3b2f53cf-d46f-4329-b35a-c6071d84beca"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "161",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1015047",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "3b52c6aa-988f-4a80-afff-55390ff7240a",
        "id": "3b52c6aa-988f-4a80-afff-55390ff7240a"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "78",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3007",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:f0",
        "speed": "1000000",
        "portName": "Vlan3007",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "3d643d4a-e6a2-4791-871d-0a391c5c69b3",
        "id": "3d643d4a-e6a2-4791-871d-0a391c5c69b3"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "436",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback254285",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "3d83364e-6aa5-47d1-92aa-181e573f22a1",
        "id": "3d83364e-6aa5-47d1-92aa-181e573f22a1"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "468",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4191",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "3db1b1d7-1005-4749-a6b9-b180a825a01e",
        "id": "3db1b1d7-1005-4749-a6b9-b180a825a01e"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "469",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4192",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "3f5258d3-e267-4348-9dd6-d7515ef3f664",
        "id": "3f5258d3-e267-4348-9dd6-d7515ef3f664"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "427",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback254269",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "4077056b-56cc-42d6-aa07-23d484ae294a",
        "id": "4077056b-56cc-42d6-aa07-23d484ae294a"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "63",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": "routed",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": "45.67.0.4",
        "mediaType": null,
        "ipv4Mask": "255.255.0.0",
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4099",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "407cf663-4d5d-4738-8264-33eb6d132829",
        "id": "407cf663-4d5d-4738-8264-33eb6d132829"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "257",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback868879",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "4126c7f7-d77b-4e4f-89e0-8afc915cecf6",
        "id": "4126c7f7-d77b-4e4f-89e0-8afc915cecf6"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "7",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "1",
        "portType": "Ethernet Port",
        "portMode": "dynamic_auto",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": "1",
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": "10/100/1000BaseTX",
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "DOWN",
        "macAddress": "00:af:1f:9e:58:85",
        "speed": "1000000",
        "portName": "GigabitEthernet1/0/5",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "AutoNegotiate",
        "instanceUuid": "41531cb0-7df9-470b-a293-c32e69f215a5",
        "id": "41531cb0-7df9-470b-a293-c32e69f215a5"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "445",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback212219",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "4153c580-0483-46e9-a624-5a753f602fd3",
        "id": "4153c580-0483-46e9-a624-5a753f602fd3"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "464",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1733742",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "4191c8d9-3ccd-4343-b3bb-4f2adf2712af",
        "id": "4191c8d9-3ccd-4343-b3bb-4f2adf2712af"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "62",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3001",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:f2",
        "speed": "1000000",
        "portName": "Vlan3001",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "41ad39e6-69ae-4978-a580-9e28b0279c5c",
        "id": "41ad39e6-69ae-4978-a580-9e28b0279c5c"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "483",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4119",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "42315037-f4f9-4ac4-9a8c-e4f2531d3ef1",
        "id": "42315037-f4f9-4ac4-9a8c-e4f2531d3ef1"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "75",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4110",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "429a2811-8035-4e26-ba96-4a11e0db7899",
        "id": "429a2811-8035-4e26-ba96-4a11e0db7899"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "518",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4157",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "43e71a2a-fc26-41b9-aa2a-ffe23f7c9e5c",
        "id": "43e71a2a-fc26-41b9-aa2a-ffe23f7c9e5c"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "501",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": "routed",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": "99.8.1.2",
        "mediaType": null,
        "ipv4Mask": "255.255.255.0",
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4134",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "441e5947-86e1-40d3-957d-e5de9ed2a499",
        "id": "441e5947-86e1-40d3-957d-e5de9ed2a499"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "261",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback868884",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "44c75057-bd2d-4d7a-96e4-57f16fdc6b42",
        "id": "44c75057-bd2d-4d7a-96e4-57f16fdc6b42"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "374",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1625670",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "45424f1c-10f0-4cdf-8d8a-ff64f0fcb0de",
        "id": "45424f1c-10f0-4cdf-8d8a-ff64f0fcb0de"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "505",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4141",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "45555c08-f158-404b-900e-190a3b52e492",
        "id": "45555c08-f158-404b-900e-190a3b52e492"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "273",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1099101",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "45c28610-55c3-4e98-b30f-3aa92d0f8ad8",
        "id": "45c28610-55c3-4e98-b30f-3aa92d0f8ad8"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "471",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4193",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "464eaab5-fc23-4081-af6b-b5ecca9ff517",
        "id": "464eaab5-fc23-4081-af6b-b5ecca9ff517"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "70",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3002",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:cb",
        "speed": "1000000",
        "portName": "Vlan3002",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "467dd75c-c804-49a2-9fce-dd5469fdd0a3",
        "id": "467dd75c-c804-49a2-9fce-dd5469fdd0a3"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "281",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1113165",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "470fb445-b71d-4470-b1e7-52e36c785777",
        "id": "470fb445-b71d-4470-b1e7-52e36c785777"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "434",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback722774",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "489d33f5-1a50-4c94-87b1-6b07911a63dd",
        "id": "489d33f5-1a50-4c94-87b1-6b07911a63dd"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "295",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1432434",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "498a0708-a85d-44a0-8ba0-e3d4c722cc49",
        "id": "498a0708-a85d-44a0-8ba0-e3d4c722cc49"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "234",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback590593",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "4a9a6b62-8851-42a0-92eb-7140484c5906",
        "id": "4a9a6b62-8851-42a0-92eb-7140484c5906"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "223",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback548563",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "4b313c22-f898-4abe-8d69-c894d0a4672c",
        "id": "4b313c22-f898-4abe-8d69-c894d0a4672c"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "65",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": "routed",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": "45.67.0.2",
        "mediaType": null,
        "ipv4Mask": "255.255.0.0",
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4098",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "4b7beb7d-e486-416e-9837-9bed014f1198",
        "id": "4b7beb7d-e486-416e-9837-9bed014f1198"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "299",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1596598",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "4bcb23f7-778a-4edd-b5a4-2b7738056ef2",
        "id": "4bcb23f7-778a-4edd-b5a4-2b7738056ef2"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "21",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "1",
        "portType": "Ethernet Port",
        "portMode": "dynamic_auto",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": "1",
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": "10/100/1000BaseTX",
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "DOWN",
        "macAddress": "00:af:1f:9e:58:93",
        "speed": "1000000",
        "portName": "GigabitEthernet1/0/19",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "AutoNegotiate",
        "instanceUuid": "4c3a19a6-9be0-4379-9cbf-f732260c3275",
        "id": "4c3a19a6-9be0-4379-9cbf-f732260c3275"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "193",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1300344",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "4c7e3e87-91df-41fd-9f67-2b0dd1fa011f",
        "id": "4c7e3e87-91df-41fd-9f67-2b0dd1fa011f"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "357",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1625626",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "4c9dadfc-24d2-48f3-a237-7da7cf9341eb",
        "id": "4c9dadfc-24d2-48f3-a237-7da7cf9341eb"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "351",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1473484",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "4cc19890-01ad-42a2-be22-52b42afd01b0",
        "id": "4cc19890-01ad-42a2-be22-52b42afd01b0"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "380",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback274300",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "4dc0862e-02a1-433e-a221-f3215a2ca6b2",
        "id": "4dc0862e-02a1-433e-a221-f3215a2ca6b2"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "391",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback646662",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "4e856646-b308-49c1-b81c-7a5560d135b0",
        "id": "4e856646-b308-49c1-b81c-7a5560d135b0"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "270",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback915936",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "4f0e28cc-47e6-4fcd-93b7-ec2ef20a0f50",
        "id": "4f0e28cc-47e6-4fcd-93b7-ec2ef20a0f50"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "229",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback548581",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "50ca4977-bfe7-4792-b188-408a3177477a",
        "id": "50ca4977-bfe7-4792-b188-408a3177477a"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "12",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "1",
        "portType": "Ethernet Port",
        "portMode": "dynamic_auto",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": "1",
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": "10/100/1000BaseTX",
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "DOWN",
        "macAddress": "00:af:1f:9e:58:8a",
        "speed": "1000000",
        "portName": "GigabitEthernet1/0/10",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "AutoNegotiate",
        "instanceUuid": "50e0390f-1c71-4b14-a0cb-85d16f80728f",
        "id": "50e0390f-1c71-4b14-a0cb-85d16f80728f"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "310",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1763765",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "511c5eef-6476-49a6-a87d-cb4db89d3d5e",
        "id": "511c5eef-6476-49a6-a87d-cb4db89d3d5e"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "388",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback646649",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "52c4221d-f525-4210-b666-67c21f9b9a29",
        "id": "52c4221d-f525-4210-b666-67c21f9b9a29"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "282",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1113166",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "53117707-8e60-40cd-abc4-d141db7785e8",
        "id": "53117707-8e60-40cd-abc4-d141db7785e8"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "503",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4139",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "53556e8e-4f64-4899-9c3f-ec94f933e134",
        "id": "53556e8e-4f64-4899-9c3f-ec94f933e134"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "272",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1085087",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "539da033-2aa5-42c7-9616-8ed4901dfc1a",
        "id": "539da033-2aa5-42c7-9616-8ed4901dfc1a"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "545",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": "routed",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": "21.1.1.1",
        "mediaType": null,
        "ipv4Mask": "255.255.255.0",
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4097",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "53cf2372-b8a5-48d6-befb-0cf352dc76b6",
        "id": "53cf2372-b8a5-48d6-befb-0cf352dc76b6"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "227",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback548575",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "54ea6bc2-c1a3-4393-b4cf-91de6232e80c",
        "id": "54ea6bc2-c1a3-4393-b4cf-91de6232e80c"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "146",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback996999",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "55b6abd0-9c5e-488f-86af-6d69979c23b0",
        "id": "55b6abd0-9c5e-488f-86af-6d69979c23b0"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "293",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1418420",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "564a8fee-8d4e-46c8-b647-253f614e4bf8",
        "id": "564a8fee-8d4e-46c8-b647-253f614e4bf8"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "280",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1113160",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "58f79c89-328e-4031-9416-739c2092a001",
        "id": "58f79c89-328e-4031-9416-739c2092a001"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "5",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "1",
        "portType": "Ethernet Port",
        "portMode": "dynamic_auto",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": "1",
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": "10/100/1000BaseTX",
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "DOWN",
        "macAddress": "00:af:1f:9e:58:83",
        "speed": "1000000",
        "portName": "GigabitEthernet1/0/3",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "AutoNegotiate",
        "instanceUuid": "596bbea7-45c9-47a9-8c14-e450aefcb2b6",
        "id": "596bbea7-45c9-47a9-8c14-e450aefcb2b6"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "98",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3027",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:dd",
        "speed": "1000000",
        "portName": "Vlan3027",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "5986df93-8c4b-43a9-a8fd-22c49b7508f7",
        "id": "5986df93-8c4b-43a9-a8fd-22c49b7508f7"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "9",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "1",
        "portType": "Ethernet Port",
        "portMode": "dynamic_auto",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": "1",
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": "10/100/1000BaseTX",
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "DOWN",
        "macAddress": "00:af:1f:9e:58:87",
        "speed": "1000000",
        "portName": "GigabitEthernet1/0/7",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "AutoNegotiate",
        "instanceUuid": "5b5247c0-3b27-4aba-ad66-bd273c758b9c",
        "id": "5b5247c0-3b27-4aba-ad66-bd273c758b9c"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "93",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3019",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:fb",
        "speed": "1000000",
        "portName": "Vlan3019",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "5c193e87-4fca-4368-a9d4-bba758820fda",
        "id": "5c193e87-4fca-4368-a9d4-bba758820fda"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "520",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4160",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "5c40ce49-c095-4a91-8d6d-f3bf27d1c074",
        "id": "5c40ce49-c095-4a91-8d6d-f3bf27d1c074"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "352",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1473483",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "5dfb425a-5a3f-4d0a-b26b-5aa4ca87945c",
        "id": "5dfb425a-5a3f-4d0a-b26b-5aa4ca87945c"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "333",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3010",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:c8",
        "speed": "1000000",
        "portName": "Vlan3010",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "5f458960-1844-4455-b61a-55c4371cbc0d",
        "id": "5f458960-1844-4455-b61a-55c4371cbc0d"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "373",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1625666",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "5f52f7a1-2f40-42f6-b66d-e4b4fb2e98e6",
        "id": "5f52f7a1-2f40-42f6-b66d-e4b4fb2e98e6"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "up",
        "interfaceType": "Physical",
        "ifIndex": "3",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": null,
        "portType": "Ethernet Port",
        "portMode": "routed",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": "12.25.15.20",
        "mediaType": "10/100/1000BaseTX",
        "ipv4Mask": "255.255.0.0",
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:80",
        "speed": "1000000",
        "portName": "GigabitEthernet1/0/1",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "FullDuplex",
        "instanceUuid": "609f880b-b6d0-4200-b1b1-c88a7ab46ae0",
        "id": "609f880b-b6d0-4200-b1b1-c88a7ab46ae0"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "459",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": "routed",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": "131.1.0.1",
        "mediaType": null,
        "ipv4Mask": "255.255.0.0",
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1547567",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "60b7e0a2-86e3-4124-9f10-567b1312aab6",
        "id": "60b7e0a2-86e3-4124-9f10-567b1312aab6"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "99",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3028",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:e4",
        "speed": "1000000",
        "portName": "Vlan3028",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "60c4c2a2-4b33-49c7-af09-d79a8dd0d89c",
        "id": "60c4c2a2-4b33-49c7-af09-d79a8dd0d89c"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "219",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback548553",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "60cb1fc9-074a-44b8-9668-5bfc6a3b99a4",
        "id": "60cb1fc9-074a-44b8-9668-5bfc6a3b99a4"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "248",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": "routed",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": "101.1.1.101",
        "mediaType": null,
        "ipv4Mask": "255.255.255.0",
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback660673",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "6110c402-8d82-4bfd-811d-acaeab1a91a1",
        "id": "6110c402-8d82-4bfd-811d-acaeab1a91a1"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "231",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback548585",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "62ca187e-78ec-4dce-b741-9027d7efe4ac",
        "id": "62ca187e-78ec-4dce-b741-9027d7efe4ac"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "148",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback997010",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "63896385-d6f9-4923-a2c2-0d1eedba0ca5",
        "id": "63896385-d6f9-4923-a2c2-0d1eedba0ca5"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "516",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4156",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "63fbe2cf-06dc-4069-9a65-6f21da06af8d",
        "id": "63fbe2cf-06dc-4069-9a65-6f21da06af8d"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "525",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4166",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "6487e105-6645-440e-9aee-25c1baee7681",
        "id": "6487e105-6645-440e-9aee-25c1baee7681"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "482",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4118",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "6489727c-1aba-4052-804a-08c50b410926",
        "id": "6489727c-1aba-4052-804a-08c50b410926"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "68",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4101",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "65bd02e5-1d91-4513-934e-98b0f3c33626",
        "id": "65bd02e5-1d91-4513-934e-98b0f3c33626"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "356",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1607618",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "6620a68e-cda9-4db7-a5a0-1af337018daf",
        "id": "6620a68e-cda9-4db7-a5a0-1af337018daf"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "479",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4114",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "676e0e8b-4da5-4319-9a64-b8cc812dc124",
        "id": "676e0e8b-4da5-4319-9a64-b8cc812dc124"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "368",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1625652",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "69e2eb41-b264-4e1a-a4b0-ed6fdb4c0a00",
        "id": "69e2eb41-b264-4e1a-a4b0-ed6fdb4c0a00"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "119",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3069",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:d3",
        "speed": "1000000",
        "portName": "Vlan3069",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "6a6333c1-9c01-4f89-937d-f25583d84d7a",
        "id": "6a6333c1-9c01-4f89-937d-f25583d84d7a"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "421",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback254260",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "6a82ba9b-00e3-4725-94d0-d3c17f35102c",
        "id": "6a82ba9b-00e3-4725-94d0-d3c17f35102c"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "24",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "1",
        "portType": "Ethernet Port",
        "portMode": "dynamic_auto",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": "1",
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": "10/100/1000BaseTX",
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "DOWN",
        "macAddress": "00:af:1f:9e:58:96",
        "speed": "1000000",
        "portName": "GigabitEthernet1/0/22",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "AutoNegotiate",
        "instanceUuid": "6bbfc4bc-2881-4ef3-be84-ed80822ee229",
        "id": "6bbfc4bc-2881-4ef3-be84-ed80822ee229"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "472",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4194",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "6d29b722-2bac-4425-b85d-3037d917020a",
        "id": "6d29b722-2bac-4425-b85d-3037d917020a"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "192",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3044",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:c1",
        "speed": "1000000",
        "portName": "Vlan3044",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "6dcbcec8-62fa-488b-a798-3e52ed1c44c0",
        "id": "6dcbcec8-62fa-488b-a798-3e52ed1c44c0"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "312",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1763771",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "6fd6a537-acd0-4d1b-9b1d-c5b3f8da4851",
        "id": "6fd6a537-acd0-4d1b-9b1d-c5b3f8da4851"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "306",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1610624",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "7058c105-39f1-471b-b48c-1a5dd0b06d43",
        "id": "7058c105-39f1-471b-b48c-1a5dd0b06d43"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "209",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback437445",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "70fc5a6e-1893-4823-ac09-0f85ab2fe656",
        "id": "70fc5a6e-1893-4823-ac09-0f85ab2fe656"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "348",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3026",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:e9",
        "speed": "1000000",
        "portName": "Vlan3026",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "711528cc-8ffc-4104-a810-07921c0338f3",
        "id": "711528cc-8ffc-4104-a810-07921c0338f3"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "150",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1015017",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "71a4b258-8fe0-4c31-81eb-4a6e8d296121",
        "id": "71a4b258-8fe0-4c31-81eb-4a6e8d296121"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "511",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4151",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "72489782-d261-49ed-8f7d-61563f47ac1d",
        "id": "72489782-d261-49ed-8f7d-61563f47ac1d"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "74",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4109",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "72ce1766-0a19-4012-85e8-362c98ae122a",
        "id": "72ce1766-0a19-4012-85e8-362c98ae122a"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "389",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback646655",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "7344add0-2646-43d3-8faa-eb38dba7b859",
        "id": "7344add0-2646-43d3-8faa-eb38dba7b859"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "322",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback360404",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "7439266e-dbb0-411d-baac-1ffd1955af3f",
        "id": "7439266e-dbb0-411d-baac-1ffd1955af3f"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "541",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4124",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "76644156-1400-4f31-8b1a-3e134726371c",
        "id": "76644156-1400-4f31-8b1a-3e134726371c"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "82",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3079",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:dc",
        "speed": "1000000",
        "portName": "Vlan3079",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "77dc13ed-063b-4086-9999-a2cf8ffbbf2b",
        "id": "77dc13ed-063b-4086-9999-a2cf8ffbbf2b"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "401",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback646690",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "78dceece-7eb6-4aca-ab51-44ef23c1cee4",
        "id": "78dceece-7eb6-4aca-ab51-44ef23c1cee4"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "487",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4122",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "78e73566-191a-413b-a5c5-9b4ecc70242e",
        "id": "78e73566-191a-413b-a5c5-9b4ecc70242e"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "424",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback254264",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "792c58ab-ebda-4f50-a23e-d8b01239f984",
        "id": "792c58ab-ebda-4f50-a23e-d8b01239f984"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "349",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1442446",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "7bd397ba-5c3b-4f2a-827a-922da06d271a",
        "id": "7bd397ba-5c3b-4f2a-827a-922da06d271a"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "32",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "0",
        "portType": "Ethernet Port",
        "portMode": "dynamic_auto",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:9e",
        "speed": "10000000",
        "portName": "TenGigabitEthernet1/1/2",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "AutoNegotiate",
        "instanceUuid": "7c888b00-01e1-434f-b242-36ce83161a8f",
        "id": "7c888b00-01e1-434f-b242-36ce83161a8f"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "452",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback722831",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "7de7a11e-cf61-4e3a-8b35-9098e05c0b69",
        "id": "7de7a11e-cf61-4e3a-8b35-9098e05c0b69"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "369",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1625655",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "7eede781-a243-46ce-b654-2c484d2d8e1d",
        "id": "7eede781-a243-46ce-b654-2c484d2d8e1d"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "432",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": "routed",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": "143.0.0.1",
        "mediaType": null,
        "ipv4Mask": "255.255.0.0",
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback722765",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "7ef5dcd1-d06e-4884-8083-c89b3e1d0a2c",
        "id": "7ef5dcd1-d06e-4884-8083-c89b3e1d0a2c"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "519",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4159",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "7f1cd24a-204b-4dc0-aa68-17ce582b9bb8",
        "id": "7f1cd24a-204b-4dc0-aa68-17ce582b9bb8"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "419",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback254257",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "7f31f201-c82f-4fed-86ea-13945afb63b3",
        "id": "7f31f201-c82f-4fed-86ea-13945afb63b3"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "521",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4161",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "7f64bc76-204b-41ec-ae84-835dfcc8d9d2",
        "id": "7f64bc76-204b-41ec-ae84-835dfcc8d9d2"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "247",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": "routed",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": "10.10.1.2",
        "mediaType": null,
        "ipv4Mask": "255.255.255.0",
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback660667",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "8011da94-b946-44b8-9b99-925143523c70",
        "id": "8011da94-b946-44b8-9b99-925143523c70"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "31",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "0",
        "portType": "Ethernet Port",
        "portMode": "dynamic_auto",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:9d",
        "speed": "10000000",
        "portName": "TenGigabitEthernet1/1/1",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "AutoNegotiate",
        "instanceUuid": "80a4078b-1a3a-49c1-ad7c-7bd8a4c195e7",
        "id": "80a4078b-1a3a-49c1-ad7c-7bd8a4c195e7"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "411",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback646725",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "80dc9b9c-611a-4a62-acf0-c46a34d4ac27",
        "id": "80dc9b9c-611a-4a62-acf0-c46a34d4ac27"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "111",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3054",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:d2",
        "speed": "1000000",
        "portName": "Vlan3054",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "821006e0-1271-4ed2-a7f6-c2dd57f56a19",
        "id": "821006e0-1271-4ed2-a7f6-c2dd57f56a19"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "260",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback868883",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "82fa80c3-3ecb-4fe3-b426-73bc9f4e8da6",
        "id": "82fa80c3-3ecb-4fe3-b426-73bc9f4e8da6"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "467",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": "routed",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": "51.0.0.1",
        "mediaType": null,
        "ipv4Mask": "255.255.255.0",
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback308335",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "84b3543a-26c7-49fc-86d8-ee356732b984",
        "id": "84b3543a-26c7-49fc-86d8-ee356732b984"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "430",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback254271",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "8506d414-0b53-4968-94c0-57a9f228bb9f",
        "id": "8506d414-0b53-4968-94c0-57a9f228bb9f"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "11",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "1",
        "portType": "Ethernet Port",
        "portMode": "dynamic_auto",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": "1",
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": "10/100/1000BaseTX",
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "DOWN",
        "macAddress": "00:af:1f:9e:58:89",
        "speed": "1000000",
        "portName": "GigabitEthernet1/0/9",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "AutoNegotiate",
        "instanceUuid": "86366de2-f437-450b-a086-3a24fbd8cd66",
        "id": "86366de2-f437-450b-a086-3a24fbd8cd66"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "38",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "1",
        "portType": "Ethernet SVI",
        "portMode": "routed",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": "5.6.7.1",
        "mediaType": null,
        "ipv4Mask": "255.255.255.192",
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:c7",
        "speed": "1000000",
        "portName": "Vlan1",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "86c50958-6886-4731-8db2-ae652d9d35ae",
        "id": "86c50958-6886-4731-8db2-ae652d9d35ae"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "8",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "1",
        "portType": "Ethernet Port",
        "portMode": "dynamic_auto",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": "1",
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": "10/100/1000BaseTX",
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "DOWN",
        "macAddress": "00:af:1f:9e:58:86",
        "speed": "1000000",
        "portName": "GigabitEthernet1/0/6",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "AutoNegotiate",
        "instanceUuid": "8712a31f-c6a1-45da-b7af-587791589716",
        "id": "8712a31f-c6a1-45da-b7af-587791589716"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "412",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback646726",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "87462620-e8a8-4b3d-837a-3a1df979a571",
        "id": "87462620-e8a8-4b3d-837a-3a1df979a571"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "30",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "0",
        "portType": "Ethernet Port",
        "portMode": "dynamic_auto",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:9c",
        "speed": "1000000",
        "portName": "GigabitEthernet1/1/4",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "AutoNegotiate",
        "instanceUuid": "877242e3-03d9-4032-a74d-e85e8daeebf5",
        "id": "877242e3-03d9-4032-a74d-e85e8daeebf5"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "386",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback574587",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "881931e4-821c-4f0c-a613-800542e0c656",
        "id": "881931e4-821c-4f0c-a613-800542e0c656"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "308",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1750752",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "887da957-0f43-4a98-918b-0088da689658",
        "id": "887da957-0f43-4a98-918b-0088da689658"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "79",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4113",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "896f2e37-30d1-494a-8a06-ae572aa9ca81",
        "id": "896f2e37-30d1-494a-8a06-ae572aa9ca81"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "513",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4153",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "8982c1dd-3243-4821-bbf7-044aad7231d8",
        "id": "8982c1dd-3243-4821-bbf7-044aad7231d8"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "123",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3078",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:f0",
        "speed": "1000000",
        "portName": "Vlan3078",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "8aa4e363-1ce9-4a7e-a77e-374890e81b08",
        "id": "8aa4e363-1ce9-4a7e-a77e-374890e81b08"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "4",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "1",
        "portType": "Ethernet Port",
        "portMode": "dynamic_auto",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": "1",
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": "10/100/1000BaseTX",
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:82",
        "speed": "1000000",
        "portName": "GigabitEthernet1/0/2",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "AutoNegotiate",
        "instanceUuid": "8c95b69a-4bc5-4a82-a9fd-f70bc1241d24",
        "id": "8c95b69a-4bc5-4a82-a9fd-f70bc1241d24"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "383",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback574577",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "8cb33f1a-b852-4f9a-855c-d81c3a326fb1",
        "id": "8cb33f1a-b852-4f9a-855c-d81c3a326fb1"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "103",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3039",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:f2",
        "speed": "1000000",
        "portName": "Vlan3039",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "8cc776c6-b64a-43de-960a-469dfbe79222",
        "id": "8cc776c6-b64a-43de-960a-469dfbe79222"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "309",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1763766",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "8e03f4a2-cd8e-4a4f-84c7-a8a36bce960e",
        "id": "8e03f4a2-cd8e-4a4f-84c7-a8a36bce960e"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "23",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "1",
        "portType": "Ethernet Port",
        "portMode": "dynamic_auto",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": "1",
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": "10/100/1000BaseTX",
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "DOWN",
        "macAddress": "00:af:1f:9e:58:95",
        "speed": "1000000",
        "portName": "GigabitEthernet1/0/21",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "AutoNegotiate",
        "instanceUuid": "8e6b75a2-30d5-43cf-ae45-23d8f05ba1ba",
        "id": "8e6b75a2-30d5-43cf-ae45-23d8f05ba1ba"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "343",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback784787",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "90f8bc20-2c3c-4f73-87a6-e1fa5325f5a3",
        "id": "90f8bc20-2c3c-4f73-87a6-e1fa5325f5a3"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "166",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1015054",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "91143f3a-1ea5-4ff3-b08a-dd97635bf7ff",
        "id": "91143f3a-1ea5-4ff3-b08a-dd97635bf7ff"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "305",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1610625",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "913eab4c-7166-4bca-902b-8f64db24fb6a",
        "id": "913eab4c-7166-4bca-902b-8f64db24fb6a"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "403",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback646692",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "916b1f0d-9122-49e7-a5e5-d7f3d64453e9",
        "id": "916b1f0d-9122-49e7-a5e5-d7f3d64453e9"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "14",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "1",
        "portType": "Ethernet Port",
        "portMode": "dynamic_auto",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": "1",
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": "10/100/1000BaseTX",
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "DOWN",
        "macAddress": "00:af:1f:9e:58:8c",
        "speed": "1000000",
        "portName": "GigabitEthernet1/0/12",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "AutoNegotiate",
        "instanceUuid": "9257b78c-8a71-4448-8e03-c7b86efbe7a9",
        "id": "9257b78c-8a71-4448-8e03-c7b86efbe7a9"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "384",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback574578",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "926f4c56-1356-487e-91f1-07cc7e299c7d",
        "id": "926f4c56-1356-487e-91f1-07cc7e299c7d"
    }, {
        "className": "GRETunnelInterface",
        "description": "Pim Register Tunnel (Encap) for RP 101.56.0.3 on VRF VN_MCAST_6",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "64",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": null,
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": null,
        "speed": "0",
        "portName": "Tunnel0",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "931a4635-0b2c-4cd9-8c44-7319020c4767",
        "id": "931a4635-0b2c-4cd9-8c44-7319020c4767"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "372",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1625663",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "931ac3d4-5316-4afb-bbe9-7fe2c336b84a",
        "id": "931ac3d4-5316-4afb-bbe9-7fe2c336b84a"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "499",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4112",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "93794654-47e0-43fe-abe3-06058b595f5c",
        "id": "93794654-47e0-43fe-abe3-06058b595f5c"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "392",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback646667",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "9391443c-fbb6-4818-9cc0-7ebdf87142db",
        "id": "9391443c-fbb6-4818-9cc0-7ebdf87142db"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "492",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4128",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "944c84b7-77fc-4232-b907-ef0642017665",
        "id": "944c84b7-77fc-4232-b907-ef0642017665"
    }, {
        "className": "GRETunnelInterface",
        "description": "Pim Register Tunnel (Encap) for RP 109.9.1.9",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "66",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": null,
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": null,
        "speed": "0",
        "portName": "Tunnel1",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "9466a186-fabf-4c51-bea8-3f0e425da709",
        "id": "9466a186-fabf-4c51-bea8-3f0e425da709"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "396",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback646674",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "95664ada-1141-4bce-bb42-4c341dc64c30",
        "id": "95664ada-1141-4bce-bb42-4c341dc64c30"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "449",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback722821",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "9644d66b-b61f-4c7e-bdaf-9f21663f39e2",
        "id": "9644d66b-b61f-4c7e-bdaf-9f21663f39e2"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "431",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": "routed",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": "109.1.0.1",
        "mediaType": null,
        "ipv4Mask": "255.255.0.0",
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback722764",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "97209c3e-6fa1-4f32-81d0-ee9d8f6765a4",
        "id": "97209c3e-6fa1-4f32-81d0-ee9d8f6765a4"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "92",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3018",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:e4",
        "speed": "1000000",
        "portName": "Vlan3018",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "978734ea-6e3a-4f10-82a3-4fa3748a5181",
        "id": "978734ea-6e3a-4f10-82a3-4fa3748a5181"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "174",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3031",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:de",
        "speed": "1000000",
        "portName": "Vlan3031",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "97fcb31d-7323-460b-9097-5f14b38c14b9",
        "id": "97fcb31d-7323-460b-9097-5f14b38c14b9"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "29",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "0",
        "portType": "Ethernet Port",
        "portMode": "dynamic_auto",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:9b",
        "speed": "1000000",
        "portName": "GigabitEthernet1/1/3",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "AutoNegotiate",
        "instanceUuid": "980d8193-5c13-45be-9c3a-048a3cd2b9ad",
        "id": "980d8193-5c13-45be-9c3a-048a3cd2b9ad"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "83",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3081",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:f1",
        "speed": "1000000",
        "portName": "Vlan3081",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "98347fe7-1873-41a0-92c6-6c108cd84b4a",
        "id": "98347fe7-1873-41a0-92c6-6c108cd84b4a"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "346",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback830842",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "98b661f9-614c-4e63-9ee5-1bdc5a8d8397",
        "id": "98b661f9-614c-4e63-9ee5-1bdc5a8d8397"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "279",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1113161",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "9a55698e-74a6-401b-8942-8103505b5b54",
        "id": "9a55698e-74a6-401b-8942-8103505b5b54"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "95",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3021",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:d2",
        "speed": "1000000",
        "portName": "Vlan3021",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "9a5e2215-f737-4c33-84b5-22f1547d9b53",
        "id": "9a5e2215-f737-4c33-84b5-22f1547d9b53"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "94",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3020",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:ef",
        "speed": "1000000",
        "portName": "Vlan3020",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "9bcccf2f-df2a-4a5f-9c9c-bff31a50693a",
        "id": "9bcccf2f-df2a-4a5f-9c9c-bff31a50693a"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "361",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1625641",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "9bfd1abc-43ca-460c-9803-f8077bc4a387",
        "id": "9bfd1abc-43ca-460c-9803-f8077bc4a387"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "485",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4121",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "9c5e1b15-7169-48e7-af4a-fc405153d7f6",
        "id": "9c5e1b15-7169-48e7-af4a-fc405153d7f6"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "500",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4131",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "9d644742-cc95-4b9e-9cdd-702ab1c17f7b",
        "id": "9d644742-cc95-4b9e-9cdd-702ab1c17f7b"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "538",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4187",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "9dce8260-3183-4f19-a0a7-cab4869290bd",
        "id": "9dce8260-3183-4f19-a0a7-cab4869290bd"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "230",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback548582",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "9e5eaf3d-5e26-4410-bd8c-c3e6a6ee32c6",
        "id": "9e5eaf3d-5e26-4410-bd8c-c3e6a6ee32c6"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "502",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4138",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "a0722f78-20e6-47fd-9572-317730495142",
        "id": "a0722f78-20e6-47fd-9572-317730495142"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "316",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1763804",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "a10847b5-bb09-4618-9a1e-4ef20b9544f7",
        "id": "a10847b5-bb09-4618-9a1e-4ef20b9544f7"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "406",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback646708",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "a166010b-cbc5-4de0-84c7-00fd07ea7ac5",
        "id": "a166010b-cbc5-4de0-84c7-00fd07ea7ac5"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "71",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4107",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "a35f4912-c67a-435c-9a84-08fb8a64cf15",
        "id": "a35f4912-c67a-435c-9a84-08fb8a64cf15"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "409",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback646718",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "a422d449-69a4-433e-bd70-5cc51f3c7ee1",
        "id": "a422d449-69a4-433e-bd70-5cc51f3c7ee1"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "101",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3030",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:e4",
        "speed": "1000000",
        "portName": "Vlan3030",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "a48ccbf1-856d-402f-9c05-2da1a44c4233",
        "id": "a48ccbf1-856d-402f-9c05-2da1a44c4233"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "438",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback722785",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "a68d0946-ffdd-4453-b8cd-87e9b31784c2",
        "id": "a68d0946-ffdd-4453-b8cd-87e9b31784c2"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "390",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback646656",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "a6a8dd53-3e4c-4458-bbb3-3fd13ce746aa",
        "id": "a6a8dd53-3e4c-4458-bbb3-3fd13ce746aa"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "335",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback746794",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "a9048a99-88c7-4bc4-a79f-0a678f8d9fe5",
        "id": "a9048a99-88c7-4bc4-a79f-0a678f8d9fe5"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "456",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": "routed",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": "14.4.0.1",
        "mediaType": null,
        "ipv4Mask": "255.255.0.0",
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1547554",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "a91f1702-d634-485e-8d4a-60acf5f8983e",
        "id": "a91f1702-d634-485e-8d4a-60acf5f8983e"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "429",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback254272",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "aa2b15fb-5713-4205-aea6-1da4703e3e6c",
        "id": "aa2b15fb-5713-4205-aea6-1da4703e3e6c"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "26",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "30",
        "portType": "Ethernet Port",
        "portMode": "access",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": "1",
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": "10/100/1000BaseTX",
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "DOWN",
        "macAddress": "00:af:1f:9e:58:98",
        "speed": "1000000",
        "portName": "GigabitEthernet1/0/24",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "AutoNegotiate",
        "instanceUuid": "aa978d92-6aef-4b0e-a265-d5cec1190274",
        "id": "aa978d92-6aef-4b0e-a265-d5cec1190274"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "16",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "1",
        "portType": "Ethernet Port",
        "portMode": "dynamic_auto",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": "1",
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": "10/100/1000BaseTX",
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "DOWN",
        "macAddress": "00:af:1f:9e:58:8e",
        "speed": "1000000",
        "portName": "GigabitEthernet1/0/14",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "AutoNegotiate",
        "instanceUuid": "ab331c34-afa8-4998-a7d2-556e59696cd6",
        "id": "ab331c34-afa8-4998-a7d2-556e59696cd6"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "251",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback660685",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "acb3d5c8-30c0-4225-8dce-1be995333716",
        "id": "acb3d5c8-30c0-4225-8dce-1be995333716"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "493",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4129",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "acbf0f87-3f7a-44bd-8a40-26ed133054de",
        "id": "acbf0f87-3f7a-44bd-8a40-26ed133054de"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "186",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1300317",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "ad305f44-0189-4f2e-9d77-7f161659bd65",
        "id": "ad305f44-0189-4f2e-9d77-7f161659bd65"
    }, {
        "className": "EthrntPrtclEndpntExtndd",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "1",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Ethernet Port",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": "RJ45",
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:c0",
        "speed": "1000000",
        "portName": "GigabitEthernet0/0",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "FullDuplex",
        "instanceUuid": "ae1c3832-b57e-44a4-9774-8f7cd76a8b55",
        "id": "ae1c3832-b57e-44a4-9774-8f7cd76a8b55"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "73",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4108",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "af85123f-2469-4812-b8ab-ba358101c06a",
        "id": "af85123f-2469-4812-b8ab-ba358101c06a"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "302",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1596602",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "afc220b3-c6f2-4082-9ff8-39d21157d74f",
        "id": "afc220b3-c6f2-4082-9ff8-39d21157d74f"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "457",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": "routed",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": "44.1.0.2",
        "mediaType": null,
        "ipv4Mask": "255.255.0.0",
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1547560",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "b0402cb4-0b92-4811-8e2d-778717fc0bad",
        "id": "b0402cb4-0b92-4811-8e2d-778717fc0bad"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "18",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "1",
        "portType": "Ethernet Port",
        "portMode": "dynamic_auto",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": "1",
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": "10/100/1000BaseTX",
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "DOWN",
        "macAddress": "00:af:1f:9e:58:90",
        "speed": "1000000",
        "portName": "GigabitEthernet1/0/16",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "AutoNegotiate",
        "instanceUuid": "b053ef3e-e394-482a-bc9b-19915af7a2de",
        "id": "b053ef3e-e394-482a-bc9b-19915af7a2de"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "253",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback810813",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "b13d2941-ff72-4876-a049-692898ee8f4c",
        "id": "b13d2941-ff72-4876-a049-692898ee8f4c"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "252",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback660690",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "b28aeef8-a4ff-4923-b4f8-8992e823a17b",
        "id": "b28aeef8-a4ff-4923-b4f8-8992e823a17b"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "353",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1607608",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "b3af3b64-556c-49cc-be50-d6c927af4995",
        "id": "b3af3b64-556c-49cc-be50-d6c927af4995"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "84",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3082",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:ea",
        "speed": "1000000",
        "portName": "Vlan3082",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "b45f9d85-b84c-4079-8ef6-f3d23b1990ad",
        "id": "b45f9d85-b84c-4079-8ef6-f3d23b1990ad"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "473",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4195",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "b4d701c5-b247-4eaa-9680-4bf3faacbf99",
        "id": "b4d701c5-b247-4eaa-9680-4bf3faacbf99"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "362",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1625644",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "b4dc6abc-f139-44d4-aa33-51e727d06413",
        "id": "b4dc6abc-f139-44d4-aa33-51e727d06413"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "72",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3004",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:f2",
        "speed": "1000000",
        "portName": "Vlan3004",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "b5a6e328-c3da-4c5a-93aa-9cf41621a4d8",
        "id": "b5a6e328-c3da-4c5a-93aa-9cf41621a4d8"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "180",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3041",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:c8",
        "speed": "1000000",
        "portName": "Vlan3041",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "b7777493-6313-41c9-84db-695f2d2823c4",
        "id": "b7777493-6313-41c9-84db-695f2d2823c4"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "476",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4104",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "b8af2ebd-cfd4-4535-bed6-db6822a9815d",
        "id": "b8af2ebd-cfd4-4535-bed6-db6822a9815d"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "329",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback746761",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "b8c497bb-0761-4789-a8e0-86aa0b75c2f3",
        "id": "b8c497bb-0761-4789-a8e0-86aa0b75c2f3"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "478",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4106",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "b8f7b55c-3790-4335-90ce-c617e98987dd",
        "id": "b8f7b55c-3790-4335-90ce-c617e98987dd"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "331",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback746765",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "ba1ebfbd-ff62-4c77-aa9b-7b5d72351a8e",
        "id": "ba1ebfbd-ff62-4c77-aa9b-7b5d72351a8e"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "202",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback401412",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "ba4ef7d4-e498-40f4-9998-9e1eca8e8580",
        "id": "ba4ef7d4-e498-40f4-9998-9e1eca8e8580"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "210",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback453455",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "bb0033cc-30d1-4089-9f74-a84faf81b2b9",
        "id": "bb0033cc-30d1-4089-9f74-a84faf81b2b9"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "543",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4142",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "bb918d4a-e63d-45c5-98af-b19fc65d985e",
        "id": "bb918d4a-e63d-45c5-98af-b19fc65d985e"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "232",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback548589",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "bbc856eb-349c-42e8-bf56-0ee28fafb983",
        "id": "bbc856eb-349c-42e8-bf56-0ee28fafb983"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "342",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3009",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:ca",
        "speed": "1000000",
        "portName": "Vlan3009",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "bd07aade-da4d-4917-89b7-102b10827d1e",
        "id": "bd07aade-da4d-4917-89b7-102b10827d1e"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "496",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4133",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "be84bd21-a050-4514-b2de-36115a99328b",
        "id": "be84bd21-a050-4514-b2de-36115a99328b"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "237",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback602605",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "be89a9a8-bbe7-41d3-bb52-39312c755096",
        "id": "be89a9a8-bbe7-41d3-bb52-39312c755096"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "491",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4127",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "bf959b88-548a-4360-a071-b728fc1f7419",
        "id": "bf959b88-548a-4360-a071-b728fc1f7419"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "498",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4137",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "c0544fe5-33f5-43e3-936a-2c2d12e19cc5",
        "id": "c0544fe5-33f5-43e3-936a-2c2d12e19cc5"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "102",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3038",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:ee",
        "speed": "1000000",
        "portName": "Vlan3038",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "c24a7102-4a24-4dfc-8cbe-0925427f0662",
        "id": "c24a7102-4a24-4dfc-8cbe-0925427f0662"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "154",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1015034",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "c2879b70-8b55-4cf4-8d12-1793bf2b87df",
        "id": "c2879b70-8b55-4cf4-8d12-1793bf2b87df"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "540",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4100",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "c38298f5-b4a9-42e7-9507-7f6d57b8d2f6",
        "id": "c38298f5-b4a9-42e7-9507-7f6d57b8d2f6"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "28",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "0",
        "portType": "Ethernet Port",
        "portMode": "dynamic_auto",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:9a",
        "speed": "1000000",
        "portName": "GigabitEthernet1/1/2",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "AutoNegotiate",
        "instanceUuid": "c3e8fe09-a0ec-4536-9abd-7b87da1e1ebb",
        "id": "c3e8fe09-a0ec-4536-9abd-7b87da1e1ebb"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "212",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback466468",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "c3f080d7-a8d7-4e52-a032-f54acb9993ec",
        "id": "c3f080d7-a8d7-4e52-a032-f54acb9993ec"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "400",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": "routed",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": "190.1.0.1",
        "mediaType": null,
        "ipv4Mask": "255.255.0.0",
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback646684",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "c5294397-7b1d-44a4-b06f-bf8c86821ce4",
        "id": "c5294397-7b1d-44a4-b06f-bf8c86821ce4"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "226",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback548571",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "c5fe8fa7-8bda-4e09-957e-554fc5528f0c",
        "id": "c5fe8fa7-8bda-4e09-957e-554fc5528f0c"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "385",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback574582",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "c8f1f903-e1f2-4dfd-b948-930a2a784163",
        "id": "c8f1f903-e1f2-4dfd-b948-930a2a784163"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "418",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback224230",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "c98a34fc-cf5f-48db-8921-ec51c4f807bc",
        "id": "c98a34fc-cf5f-48db-8921-ec51c4f807bc"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "106",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3047",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:c1",
        "speed": "1000000",
        "portName": "Vlan3047",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "c98df40f-84dd-4757-bf52-03e74abafd52",
        "id": "c98df40f-84dd-4757-bf52-03e74abafd52"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "529",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4170",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "ca66a937-9026-4764-a05d-d4429e73f68e",
        "id": "ca66a937-9026-4764-a05d-d4429e73f68e"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "484",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4120",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "ca7e115b-c040-49fd-9c74-b1ba0b85034e",
        "id": "ca7e115b-c040-49fd-9c74-b1ba0b85034e"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "458",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1547564",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "cb2984ff-f9a1-4dd6-a079-ea9e62e77819",
        "id": "cb2984ff-f9a1-4dd6-a079-ea9e62e77819"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "477",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4105",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "cbfb3a10-099d-46b6-b018-1b3d9667c394",
        "id": "cbfb3a10-099d-46b6-b018-1b3d9667c394"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "495",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4132",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "cc08962f-ebd2-4a41-8af7-d16e931d0efe",
        "id": "cc08962f-ebd2-4a41-8af7-d16e931d0efe"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "494",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": "routed",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": "122.6.1.15",
        "mediaType": null,
        "ipv4Mask": "255.255.255.0",
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4130",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "cc161e22-910d-4c90-8862-16bd13fb973d",
        "id": "cc161e22-910d-4c90-8862-16bd13fb973d"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "314",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1763800",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "ce2de2ef-9ef5-4c56-a063-152b3b1ede1f",
        "id": "ce2de2ef-9ef5-4c56-a063-152b3b1ede1f"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "165",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3024",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:ef",
        "speed": "1000000",
        "portName": "Vlan3024",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "cfdc93df-4cbd-4416-adfa-d298b78a4660",
        "id": "cfdc93df-4cbd-4416-adfa-d298b78a4660"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "453",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback722832",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "d00c2a29-9c51-4410-a586-3254089c266a",
        "id": "d00c2a29-9c51-4410-a586-3254089c266a"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "417",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback224229",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "d1a931fd-a48b-4f34-ab84-14908bf495b9",
        "id": "d1a931fd-a48b-4f34-ab84-14908bf495b9"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "307",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1750753",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "d21e8cdb-1ec9-46ad-9176-db41a6af4b18",
        "id": "d21e8cdb-1ec9-46ad-9176-db41a6af4b18"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "218",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback534539",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "d2a3edf1-8da3-4f0e-aa7d-b906498a0c37",
        "id": "d2a3edf1-8da3-4f0e-aa7d-b906498a0c37"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "462",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1733735",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "d3fc3e3e-9902-4a45-810e-4eb5845e90cf",
        "id": "d3fc3e3e-9902-4a45-810e-4eb5845e90cf"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "325",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback722725",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "d418e6bb-af40-43b3-94dc-299d4d49cf38",
        "id": "d418e6bb-af40-43b3-94dc-299d4d49cf38"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "19",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "1",
        "portType": "Ethernet Port",
        "portMode": "dynamic_auto",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": "1",
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": "10/100/1000BaseTX",
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "DOWN",
        "macAddress": "00:af:1f:9e:58:91",
        "speed": "1000000",
        "portName": "GigabitEthernet1/0/17",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "AutoNegotiate",
        "instanceUuid": "d43da51a-389f-4100-b52d-9f3e31719d9e",
        "id": "d43da51a-389f-4100-b52d-9f3e31719d9e"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "114",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3057",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:eb",
        "speed": "1000000",
        "portName": "Vlan3057",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "d4b618c1-8921-4c4d-a7f8-b430ba9af75c",
        "id": "d4b618c1-8921-4c4d-a7f8-b430ba9af75c"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "537",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4188",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "d55114bd-9eec-459b-ad79-db3815031e28",
        "id": "d55114bd-9eec-459b-ad79-db3815031e28"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "497",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4136",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "d682ccad-70e6-48fd-94e7-063113e0b0d3",
        "id": "d682ccad-70e6-48fd-94e7-063113e0b0d3"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "475",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback206210",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "d8171feb-c09f-4441-b013-fddf822fbb02",
        "id": "d8171feb-c09f-4441-b013-fddf822fbb02"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "242",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback616623",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "d8299c4f-a454-40c7-9ad5-2d27f97d4e47",
        "id": "d8299c4f-a454-40c7-9ad5-2d27f97d4e47"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "490",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4126",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "d9d7b503-74c5-4761-a035-ca5382cedfd2",
        "id": "d9d7b503-74c5-4761-a035-ca5382cedfd2"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "336",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback746812",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "da6d5008-813a-43bf-901d-358974cca97d",
        "id": "da6d5008-813a-43bf-901d-358974cca97d"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "435",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback722775",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "dc1f267c-aa0c-46bf-bf39-a110d1e935f4",
        "id": "dc1f267c-aa0c-46bf-bf39-a110d1e935f4"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "402",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback646691",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "de421795-3de7-4f34-ae0b-c13e213262b3",
        "id": "de421795-3de7-4f34-ae0b-c13e213262b3"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "379",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": "routed",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": "31.1.0.26",
        "mediaType": null,
        "ipv4Mask": "255.255.0.0",
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback274285",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "de8fa0d9-d51b-40b1-a997-ac5124cde051",
        "id": "de8fa0d9-d51b-40b1-a997-ac5124cde051"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "474",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4196",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "dedcc3ad-bda2-4367-a9f6-1043595737f7",
        "id": "dedcc3ad-bda2-4367-a9f6-1043595737f7"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "288",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1113175",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "dee7e318-9a9c-4ea2-9dfe-6ba8e397780d",
        "id": "dee7e318-9a9c-4ea2-9dfe-6ba8e397780d"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "88",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3087",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:f0",
        "speed": "1000000",
        "portName": "Vlan3087",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "df7762b5-9daa-481d-baea-587f5ce0d843",
        "id": "df7762b5-9daa-481d-baea-587f5ce0d843"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "97",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3025",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:e5",
        "speed": "1000000",
        "portName": "Vlan3025",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "df9dad35-fa7f-416e-bd33-225a05880877",
        "id": "df9dad35-fa7f-416e-bd33-225a05880877"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "334",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback746789",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "e128a8d3-ec33-4921-b1c5-4b384822408a",
        "id": "e128a8d3-ec33-4921-b1c5-4b384822408a"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "364",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1625648",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "e159f7d3-daf0-4ca1-8247-2131875a2b69",
        "id": "e159f7d3-daf0-4ca1-8247-2131875a2b69"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "415",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback722749",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "e1623881-5e6e-4330-876e-63a249943da2",
        "id": "e1623881-5e6e-4330-876e-63a249943da2"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "6",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "1",
        "portType": "Ethernet Port",
        "portMode": "dynamic_auto",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": "1",
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": "10/100/1000BaseTX",
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "DOWN",
        "macAddress": "00:af:1f:9e:58:84",
        "speed": "1000000",
        "portName": "GigabitEthernet1/0/4",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "AutoNegotiate",
        "instanceUuid": "e1a66070-58b7-4ca4-b383-2e88a786d3cb",
        "id": "e1a66070-58b7-4ca4-b383-2e88a786d3cb"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "514",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4154",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "e1bf39bb-13fc-43c9-8cac-6aab4441816f",
        "id": "e1bf39bb-13fc-43c9-8cac-6aab4441816f"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "433",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback722773",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "e25e2ec1-4223-4ef0-a9d5-d880500b8bbf",
        "id": "e25e2ec1-4223-4ef0-a9d5-d880500b8bbf"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "360",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1625637",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "e2807efc-652d-4957-a005-041d01e15025",
        "id": "e2807efc-652d-4957-a005-041d01e15025"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "181",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1300301",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "e2e76e4f-08fb-4efd-abba-7780e90ddf2c",
        "id": "e2e76e4f-08fb-4efd-abba-7780e90ddf2c"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "34",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "0",
        "portType": "Ethernet Port",
        "portMode": "dynamic_auto",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:a0",
        "speed": "10000000",
        "portName": "TenGigabitEthernet1/1/4",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "AutoNegotiate",
        "instanceUuid": "e49993cd-8b77-4cf2-8263-f2253207431e",
        "id": "e49993cd-8b77-4cf2-8263-f2253207431e"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "524",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4163",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "e4cc3994-ded7-4a32-9b9d-9c627282f084",
        "id": "e4cc3994-ded7-4a32-9b9d-9c627282f084"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "486",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4103",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "e5610153-23a9-489b-8ba1-10f5dd4651b7",
        "id": "e5610153-23a9-489b-8ba1-10f5dd4651b7"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "423",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback722758",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "e801a223-affb-43f0-a591-010bb1560f2a",
        "id": "e801a223-affb-43f0-a591-010bb1560f2a"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "85",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3084",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:dc",
        "speed": "1000000",
        "portName": "Vlan3084",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "e802eb04-11c4-4d4d-8e45-2b8bc0a0b0e7",
        "id": "e802eb04-11c4-4d4d-8e45-2b8bc0a0b0e7"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "112",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3055",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:f5",
        "speed": "1000000",
        "portName": "Vlan3055",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "e8934444-f7ab-4c30-8332-b6eaa66abbe0",
        "id": "e8934444-f7ab-4c30-8332-b6eaa66abbe0"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "454",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3070",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:d3",
        "speed": "1000000",
        "portName": "Vlan3070",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "e8ecc55d-f275-4f1a-8784-84e69a92ec6e",
        "id": "e8ecc55d-f275-4f1a-8784-84e69a92ec6e"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "328",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": "routed",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": "29.29.0.26",
        "mediaType": null,
        "ipv4Mask": "255.255.0.0",
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback746755",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "e9016bf7-7659-4566-b842-2f38e87e298f",
        "id": "e9016bf7-7659-4566-b842-2f38e87e298f"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "338",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback746813",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "eaaf51ae-5ac5-4f84-b2ce-62fe1b7254e4",
        "id": "eaaf51ae-5ac5-4f84-b2ce-62fe1b7254e4"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "113",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3056",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:ed",
        "speed": "1000000",
        "portName": "Vlan3056",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "eae83f52-5362-4fd7-ba96-7be3cf690c5f",
        "id": "eae83f52-5362-4fd7-ba96-7be3cf690c5f"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "269",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback915931",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "eb3f2d56-6ad1-4c92-a021-a25b277ae18b",
        "id": "eb3f2d56-6ad1-4c92-a021-a25b277ae18b"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "508",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4147",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "eba299a4-6903-4d75-ada4-39416c6bb0e1",
        "id": "eba299a4-6903-4d75-ada4-39416c6bb0e1"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "157",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1015040",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "ec640d9b-d521-4233-8276-3469f1bf98a4",
        "id": "ec640d9b-d521-4233-8276-3469f1bf98a4"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "87",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3086",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:db",
        "speed": "1000000",
        "portName": "Vlan3086",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "ee2d1d67-13f4-4e1c-aa74-50466678156a",
        "id": "ee2d1d67-13f4-4e1c-aa74-50466678156a"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "413",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback646730",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "ee45c23c-f746-44ac-a87e-7de5299e0566",
        "id": "ee45c23c-f746-44ac-a87e-7de5299e0566"
    }, {
        "className": "LoopbackInterface",
        "description": "Fabric Node Router ID",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "39",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": "routed",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": "172.16.1.4",
        "mediaType": null,
        "ipv4Mask": "255.255.255.255",
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback0",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "eef3eda2-303f-4624-ba4f-6989ace0b580",
        "id": "eef3eda2-303f-4624-ba4f-6989ace0b580"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "195",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1300351",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "ef355b38-c7ff-452b-a6e1-d06386ebe144",
        "id": "ef355b38-c7ff-452b-a6e1-d06386ebe144"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "428",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": "routed",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": "155.0.0.2",
        "mediaType": null,
        "ipv4Mask": "255.255.0.0",
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback722759",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "f176dcbc-4015-46a7-b80a-8ab7280fb9c3",
        "id": "f176dcbc-4015-46a7-b80a-8ab7280fb9c3"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "465",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": "routed",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": "30.1.1.51",
        "mediaType": null,
        "ipv4Mask": "255.255.255.0",
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback308327",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "f27d6737-160c-4984-9cfd-91751356028a",
        "id": "f27d6737-160c-4984-9cfd-91751356028a"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "152",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1015023",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "f27f80cc-8898-444b-a476-78a3e83ca440",
        "id": "f27f80cc-8898-444b-a476-78a3e83ca440"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "344",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1171174",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "f2b91fc1-a811-40eb-9519-bb756834c982",
        "id": "f2b91fc1-a811-40eb-9519-bb756834c982"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "393",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback646668",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "f2baabb7-e560-4169-855a-9dd235de1869",
        "id": "f2baabb7-e560-4169-855a-9dd235de1869"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "397",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback646675",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "f33be018-2825-4196-b236-d39df2e45c88",
        "id": "f33be018-2825-4196-b236-d39df2e45c88"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "77",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3005",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:d7",
        "speed": "1000000",
        "portName": "Vlan3005",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "f36e3d0c-798d-4b64-91f8-9ad3a12dec55",
        "id": "f36e3d0c-798d-4b64-91f8-9ad3a12dec55"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "315",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1763799",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "f39ebc44-202e-4c3f-9edb-623e345cf260",
        "id": "f39ebc44-202e-4c3f-9edb-623e345cf260"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "371",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback1625658",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "f435ba36-e925-4718-b612-220b129c40a5",
        "id": "f435ba36-e925-4718-b612-220b129c40a5"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "176",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3032",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:d5",
        "speed": "1000000",
        "portName": "Vlan3032",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "f4a42e49-5697-4e8e-8a6a-cc0897986157",
        "id": "f4a42e49-5697-4e8e-8a6a-cc0897986157"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "510",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4150",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "f4af66b6-3620-481d-a437-93fc092c59e1",
        "id": "f4af66b6-3620-481d-a437-93fc092c59e1"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "507",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4146",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "f57ef8e4-03cd-4404-9224-cba0e2f2b488",
        "id": "f57ef8e4-03cd-4404-9224-cba0e2f2b488"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "414",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": "routed",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": "10.1.0.2",
        "mediaType": null,
        "ipv4Mask": "255.255.255.0",
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback224227",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "f6068c54-d15b-4c3f-b599-6fed7d838c04",
        "id": "f6068c54-d15b-4c3f-b599-6fed7d838c04"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "249",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": "routed",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": "201.1.1.1",
        "mediaType": null,
        "ipv4Mask": "255.255.255.0",
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback660681",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "f616055c-2e42-427f-8bc8-94654608ba35",
        "id": "f616055c-2e42-427f-8bc8-94654608ba35"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "207",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback437439",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "f693fb50-2a31-4eda-9576-6b61912c7de5",
        "id": "f693fb50-2a31-4eda-9576-6b61912c7de5"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "17",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "1",
        "portType": "Ethernet Port",
        "portMode": "dynamic_auto",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": "1",
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": "10/100/1000BaseTX",
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "DOWN",
        "macAddress": "00:af:1f:9e:58:8f",
        "speed": "1000000",
        "portName": "GigabitEthernet1/0/15",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "AutoNegotiate",
        "instanceUuid": "f6c84687-aa5d-4ec4-87bc-28905b3ab990",
        "id": "f6c84687-aa5d-4ec4-87bc-28905b3ab990"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "512",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4152",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "f781a9cd-0c0b-487a-aecb-68aa49db5152",
        "id": "f781a9cd-0c0b-487a-aecb-68aa49db5152"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "408",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback646714",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "f803affe-9b14-40ff-8cae-bb6393dbfe7a",
        "id": "f803affe-9b14-40ff-8cae-bb6393dbfe7a"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "420",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback254258",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "f817bfee-ce7a-4f2d-8ec1-7a5669e30ad4",
        "id": "f817bfee-ce7a-4f2d-8ec1-7a5669e30ad4"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "41",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3003",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:ff",
        "speed": "1000000",
        "portName": "Vlan3003",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "f82ec2b4-1032-42f3-9e73-fd417a85881e",
        "id": "f82ec2b4-1032-42f3-9e73-fd417a85881e"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "76",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4111",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "f9794c72-f423-486b-83ce-734612743df9",
        "id": "f9794c72-f423-486b-83ce-734612743df9"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "145",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3014",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:c7",
        "speed": "1000000",
        "portName": "Vlan3014",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "f9c66bd5-068b-4341-9a13-1e781a50604c",
        "id": "f9c66bd5-068b-4341-9a13-1e781a50604c"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "107",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3048",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:e9",
        "speed": "1000000",
        "portName": "Vlan3048",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "fa28d3da-1235-409d-a1f2-009d31d54831",
        "id": "fa28d3da-1235-409d-a1f2-009d31d54831"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "243",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback616627",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "fc47b2bb-3cfe-485f-a548-503125622cde",
        "id": "fc47b2bb-3cfe-485f-a548-503125622cde"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "108",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3049",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:f7",
        "speed": "1000000",
        "portName": "Vlan3049",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "fc4c76a3-e844-47f4-8cf8-dcd7afb94b9d",
        "id": "fc4c76a3-e844-47f4-8cf8-dcd7afb94b9d"
    }, {
        "className": "VLANInterfaceExtended",
        "description": "Configured from apic-em",
        "status": "down",
        "interfaceType": "Virtual",
        "ifIndex": "86",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "3085",
        "portType": "Ethernet SVI",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:af:1f:9e:58:ee",
        "speed": "1000000",
        "portName": "Vlan3085",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "fd070fb0-fbae-4cd4-97e5-707ffa5b30c1",
        "id": "fd070fb0-fbae-4cd4-97e5-707ffa5b30c1"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "489",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4125",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "fd176f9c-c013-4e6a-822c-0f9d87685b5b",
        "id": "fd176f9c-c013-4e6a-822c-0f9d87685b5b"
    }, {
        "className": "SwitchPort",
        "description": "",
        "status": "down",
        "interfaceType": "Physical",
        "ifIndex": "13",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "1",
        "portType": "Ethernet Port",
        "portMode": "dynamic_auto",
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": "1",
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": "10/100/1000BaseTX",
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "DOWN",
        "macAddress": "00:af:1f:9e:58:8b",
        "speed": "1000000",
        "portName": "GigabitEthernet1/0/11",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": "AutoNegotiate",
        "instanceUuid": "fd8d5e5c-fa5b-4643-9f18-97c53f23a96b",
        "id": "fd8d5e5c-fa5b-4643-9f18-97c53f23a96b"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "206",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback423435",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "fdba22c3-7b3a-463e-b0dc-758efd7ae7cd",
        "id": "fdba22c3-7b3a-463e-b0dc-758efd7ae7cd"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "404",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback646698",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "fe7cdb3d-73f0-4442-91a5-d0475af1253d",
        "id": "fe7cdb3d-73f0-4442-91a5-d0475af1253d"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "265",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback868892",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "ff000500-9642-4d5c-8065-c24edb0401f4",
        "id": "ff000500-9642-4d5c-8065-c24edb0401f4"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "337",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback746811",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "ff3ecad6-3d53-42b9-8f14-e69c77a66c4a",
        "id": "ff3ecad6-3d53-42b9-8f14-e69c77a66c4a"
    }, {
        "className": "LoopbackInterface",
        "description": "",
        "status": "up",
        "interfaceType": "Virtual",
        "ifIndex": "517",
        "voiceVlan": null,
        "deviceId": "f69ed3af-0f0e-48b6-8479-4f5bc3da242a",
        "vlanId": "",
        "portType": "Service Module Interface",
        "portMode": null,
        "mappedPhysicalInterfaceName": null,
        "nativeVlanId": null,
        "ospfSupport": "false",
        "mappedPhysicalInterfaceId": null,
        "serialNo": "FOC2002U09K",
        "ipv4Address": null,
        "mediaType": null,
        "ipv4Mask": null,
        "lastUpdated": "2017-05-31 12:55:31.404",
        "isisSupport": "false",
        "adminStatus": "UP",
        "macAddress": "00:00:00:00:00:00",
        "speed": "8000000",
        "portName": "Loopback4158",
        "pid": "WS-C3850-24P-E",
        "series": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        "duplex": null,
        "instanceUuid": "fff03bfa-d817-4804-8495-457721f38931",
        "id": "fff03bfa-d817-4804-8495-457721f38931"
    }]
});
