define({
    "Client_App_Template":
      [{
        applicationName: "ssh",
        hostNames:['Grace.Smith-iPad','Gordon.Thomson-iPad','ethan-Google-Pixel-C','Mario-Motorola_XOOM','Mario-James-PC',
        'John-PC',"Galaxy-Tab-S3",'Philip-PC','Mason-Davis','dnac-ise-01','Luther.Knight','Emily-PC', 'Grace.Smith-Galaxy-S20']
      },
	  {
       applicationName: "ms-office-365",
         hostNames:['Grace.Smith-iPad','Gordon.Thomson-iPad', 'Grace.Smith-Galaxy-S20']
      },
      {
        applicationName: "webex-meeting",
         hostNames:['Grace.Smith-iPad','Gordon.Thomson-iPad','Grace.Smith-PC','Shaggy-PC', 'Grace.Smith-Galaxy-S20']
       },

       {
        applicationName: "MedicalRecords",
         hostNames:['Grace.Smith-iPad', 'Grace.Smith-Galaxy-S20']
       },
	  {
       applicationName: "citrix-static",
        hostNames:['John-PC',"velma.dinkley-Galaxy-Tab-S2",'ethan-Google-Pixel-C','Mario-Motorola_XOOM',
        'Mario-James-PC','Scooby-PC','dwill-PC','William-PC','Amy-PC','emily-01','zac-ise-01']
      },
	  {
       applicationName: "snmp",
        hostNames:['velma.dinkley-Galaxy-Tab-S2',"Zayn-sensor",'Creed-PC','oliverp-Google_Nexus','lemccart-iphone',
        'Charles-iPad','Bender-PC','MAZIN-sensor1','dnac-ise-01','liam-iphone','William.Roy-iPad']
      },
	  {
       applicationName: "outlook-web-service",
        hostNames:['Grace.Smith-PC','velma.dinkley-Galaxy-Tab-S2',"Min-Ses-iPad",'George-PC','ethan-sensor1','Hakeem-PC',
        'mu5Y13Ij','Kylo-iPad','Lauren-O-Kelly','Mia.Smith','Liam-PC']
      },
	  {
       applicationName: "ms-lync",
        hostNames:['Shaggy-PC','Benjamin-PC','Adonis-PC','Grace.Smith-iPhone','Galaxy-Tab-S3','Jacob-PC','Scooby-PC',
        'Philip-PC','James-robert','Aaliya.Khan','Emily-PC','DR.Dogood-iPhone']
      },
      {
       applicationName: "dns",
        hostNames:['oliverp-Google_Nexus','Adonis-PC','Amy-PC','ethan-sensor1','SAMSUNG-SM-G930V','dwill-PC',
        'Bender-PC','Richard-Device','zac-ise-01','Grace.Smith-Galaxy-S20']
      },
	  {
       applicationName: "ms-office-web-apps",
        hostNames:['George-PC','ethan-Google-Pixel-C','Mario-Motorola_XOOM','James-PC','Jacob-PC','Hakeem-PC',
        'Creed-PC','Mason-Davis','adelina-iphone','Charlotte-iPhone']
      },
      {
       applicationName: "ntp",
        hostNames:['Shaggy-PC','Benjamin-PC','Grace.Smith-iPhone','Zayn-sensor','SAMSUNG-SM-G930V','Charles-iPad',
        'mu5Y13Ij','Jacob-Wilson','emily-01','Lauren-O-Kelly','Aaliya.Khan','DR.Dogood-iPhone']
      },
      {
       applicationName: "ms-update",
        hostNames:['Grace.Smith-PC','Shaggy-PC','William-PC','ethan-sensor1','Scotts-iPhone','Scooby-PC','Philip-PC',
        'Kylo-iPad','Galaxy-Tab-S5','liam-iphone','William.Roy-iPad']
      },
      {
       applicationName: "netbios-ns",
        hostNames:['Shaggy-PC','William-PC','Grace.Smith-iPhone','Zayn-sensor','lemccart-iphone','dwill-PC','Adonis-PC',
        'MAZIN-sensor1','dnac-ise-01','Mia.Smith','Emily-PC','DR.Dogood-iPhone']
      },
	  {
       applicationName: "disney-web-portal",
        hostNames:['Grace.Smith-PC','Shaggy-PC']
      },
	  {
       applicationName: "netflix",
        hostNames:['Grace.Smith-PC','Shaggy-PC','Grace.Smith-iPad','Gordon.Thomson-iPad','Jacob-PC','Benjamin-PC',
        'Galaxy-Tab-S5','adelina-iphone','James-robert']
      },
	  {
       applicationName: "espn-browsing",
        hostNames:['velma.dinkley-Galaxy-Tab-S2',"John-PC",'Galaxy-Tab-S3','Charles-iPad','Adonis-PC','mu5Y13Ij',
        'Mason-Davis','Amelia-johnson','Lauren-O-Kelly','William.Roy-iPad']
      },
	  {
       applicationName: "apple-services",
        hostNames:['velma.dinkley-Galaxy-Tab-S2','Grace.Smith-iPhone','Scotts-iPhone','Charles-iPad','Philip-PC',
        'MAZIN-sensor1','Liam-PC','DR.Dogood-iPhone']
      },
      {
       applicationName: "skype",
        hostNames:['Shaggy-PC','Adonis-PC','Amy-PC','ethan-sensor1','lemccart-iphone','James-PC','Kylo-iPad',
        'Richard-Device','emily-01','Lauren-O-Kelly']
      },
	  {
       applicationName: "ms-live-accounts",
        hostNames:['oliverp-Google_Nexus','Amy-PC','George-PC','SAMSUNG-SM-G930V','Scooby-PC','Bender-PC',
        'Creed-PC','dnac-ise-01','adelina-iphone']
      },
      {
       applicationName: "bing",
        hostNames:['John-PC','Benjamin-PC',,'Kylo-iPad','Mario-Motorola_XOOM','Jacob-PC','dwill-PC','William-PC',
        'Galaxy-Tab-S5','liam-iphone','Aaliya.Khan']
      },
	  {
       applicationName: "ssl",
        hostNames:['Shaggy-PC',"Min-Ses-iPad",'Philip-PC','Hakeem-PC','ethan-Google-Pixel-C','James-PC','Adonis-PC',
        'Richard-Device','James-robert','Mia.Smith','Liam-PC']
      },
	  {
       applicationName: "ms-services",
        hostNames:['Grace.Smith-iPad',"George-PC","Zayn-sensor",'mu5Y13Ij','Gordon.Thomson-iPad','Scooby-PC',
        'Creed-PC','MAZIN-sensor1','Charlotte-iPhone']
      },
	  {
       applicationName: "http",
        hostNames:['Grace.Smith-PC',"Min-Ses-iPad",'Hakeem-PC','mu5Y13Ij','Kylo-iPad','Scotts-iPhone',
        'dwill-PC','Bender-PC','Charles-iPad','Jacob-Wilson','emily-01']
      },
	  {
       applicationName: "binary-over-http",
        hostNames:['oliverp-Google_Nexus','Philip-PC','Grace.Smith-iPad','Galaxy-Tab-S3','lemccart-iphone','William-PC',
        'Amy-PC','Benjamin-PC','Mason-Davis','Amelia-johnson','Luther.Knight']
      },
      {
        applicationName: "microsoft-teams",
        hostNames:['Grace.Smith-Galaxy-S20']
      },
      ]
});
