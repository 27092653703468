define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/virtualnetwork/VirtualNetworkTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/segment/SegmentTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/DefaultConfig',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/provision/DeviceInfoTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimNetworkDeviceData'
], function(SimLokiDatabaseActions, VirtualNetworkTemplate, SegmentTemplate, UtilityFunctions,DefaultConfig,DeviceInfoTemplate,SimNetworkDeviceData){
  return {
      init: function(){

      },

      AssociateVnToSegment: function(jsonObj){
          console.log(jsonObj);
          var bVnFound = false;
          var i, len = jsonObj.segment.length;
          var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('VirtualNetwork', { 'id' : jsonObj.id });
          var bVnFound = recordObj && recordObj.length ? recordObj.length : 0;
          if(bVnFound){
              if(jsonObj.fabricOverride.length || (jsonObj.fabricOverride.length == 0 && recordObj[0].fabricOverride.length) ) {
                  recordObj[0].fabricOverride.length = 0;
                  recordObj[0].fabricOverride = jsonObj.fabricOverride;
              }

              // this case is handled for guest network. During guest enabled, the complete data will not come
			  //only reference of the segment id.
              if(jsonObj.segment.length >0 && jsonObj.segment[0].idRef!=undefined && jsonObj.name != "Employee_VN-Global/North_America/USA/California/San_Jose/SJC06_SanJose_Fabric") {
                 SimLokiDatabaseActions.update('VirtualNetwork', recordObj);
                 return;
              }
 
              //make a list of segments passed by UI for this rest call..
              var segIds = '';
              for(i=0; i < jsonObj.segment.length; i++) {
                  if(jsonObj.segment[i].id != undefined){
                      segIds += segIds == '' ? jsonObj.segment[i].id : ',' + jsonObj.segment[i].id;
                  }
              }

              //During Edit, all the segments will be sent(immaterial of selecting). During delete, the remaining segments will be sent.
              //iterate over actual segments associated with VN, delete the not passed segments
              for(i =0; i < recordObj[0].segment.length; i++){
                  var segId = recordObj[0].segment[i].idRef;
                  if(segIds == '' || segIds.indexOf(segId) == -1){
                     var tObj = SimLokiDatabaseActions.getFilteredRecordHandler('Segment', { 'id' : segId });
                     if(tObj && tObj.length) {
                         SimLokiDatabaseActions.delete( 'Segment', tObj );
                     }
                  }
              }

              //delete all segments, if rest call has passed, it will be added again back..
              recordObj[0].segment.length = 0;
          }

          for(i=0; i < len && bVnFound; i++) {
              var tJson =  JSON.parse(JSON.stringify( SegmentTemplate.Segment_Template));
              var segmentId = jsonObj.segment[i].id;
              var type = '';
              if(jsonObj.segment[i].id == undefined){
                  segmentId = UtilityFunctions.generate_uuid();
                  type = 'create';
                  tJson.id = segmentId;
                  tJson.ipPoolId = jsonObj.segment[i].ipPoolId ? jsonObj.segment[i].ipPoolId : "";
                  tJson.displayName = "1120"+tJson.ipPoolId
                  tJson.wiredAuthMode = jsonObj.segment[i].wiredAuthMode ? jsonObj.segment[i].wiredAuthMode : false;
                  tJson.isFloodAndLearn = jsonObj.segment[i].isFloodAndLearn ? jsonObj.segment[i].isFloodAndLearn : false;
                  tJson.name = jsonObj.segment[i].name;
                  tJson.trafficType = jsonObj.segment[i].trafficType;
                  tJson.isApProvisioning = jsonObj.segment[i].isApProvisioning ? jsonObj.segment[i].isApProvisioning : false;
                  tJson.isDefaultEnterprise = jsonObj.segment[i].isDefaultEnterprise ? jsonObj.segment[i].isDefaultEnterprise : false;
                  tJson.isExtendedProvisioning = jsonObj.segment[i].isExtendedProvisioning ? jsonObj.segment[i].isExtendedProvisioning : false;
                  tJson.connectivityDomain.idRef =  jsonObj.segment[i].connectivityDomain.idRef;
                  tJson.isWirelessPool = jsonObj.segment[i].isWirelessPool ? jsonObj.segment[i].isWirelessPool : false;
                  tJson.isCriticalPool = jsonObj.segment[i].isCriticalPool ? jsonObj.segment[i].isCriticalPool : false;
                  tJson.isCommonPool = jsonObj.segment[i].isCommonPool ? jsonObj.segment[i].isCommonPool : false;
                  tJson.isSelectiveFloodingEnabled = jsonObj.segment[i].isSelectiveFloodingEnabled;
                  tJson.isL2OnlyPool = jsonObj.segment[i].isL2OnlyPool ? jsonObj.segment[i].isL2OnlyPool : false;
                  tJson.isWakeOnLan = jsonObj.segment[i].isWakeOnLan ? jsonObj.segment[i].isWakeOnLan : false;
                  
                  tJson.l2Instance = UtilityFunctions.getRandomIntForRangeValues(8188, 8199);
                  tJson.connectivityDomainId = jsonObj.segment[i].connectivityDomain.idRef;
                //   tJson.gateway = jsonObj.segment[i].gateway;
                  tJson.ippools = jsonObj.segment[i].ippools ? jsonObj.segment[i].ippools : [];
                  tJson.vlanName = jsonObj.segment[i].name;
                  tJson.virtualNetworkId = jsonObj.id;
                  tJson.virtualNetworkContextId = jsonObj.virtualNetworkContextId;
                  tJson.associatedL3VNInstanceId = jsonObj.l3Instance,
                  tJson.associatedL3VNName = jsonObj.name
                  // need to review this code
                  tJson.l2SegmentSummary.id = segmentId;
                  tJson.l2SegmentSummary.connectivityDomainId = jsonObj.segment[i].connectivityDomain.idRef;
                  tJson.l2SegmentSummary.l2Instance = tJson.l2Instance
                  tJson.l2SegmentSummary.vlanId = jsonObj.segment[i].vlanId ? jsonObj.segment[i].vlanId: "";
                  tJson.l2SegmentSummary.vlanName = jsonObj.segment[i].name;
                  if(jsonObj.segment[i].l2Segment == undefined){
                      tJson.scalableGroupId = jsonObj.segment[i].scalableGroupId;
                      tJson.l2SegmentSummary.scalableGroupId = jsonObj.segment[i].scalableGroupId;
                  }else{
                    tJson.scalableGroupId = jsonObj.segment[i].l2Segment.scalableGroupId;
                    tJson.l2SegmentSummary.scalableGroupId = jsonObj.segment[i].l2Segment.scalableGroupId;
                    tJson.vlanId = jsonObj.segment[i].l2Segment.vlanId;
                  }
                  tJson.l2Segment.idRef = segmentId

                  tJson.segmentSummary.associatedL3VNInstanceId = jsonObj.l3Instance
                  tJson.segmentSummary.associatedL3VNName = jsonObj.name
                  tJson.segmentSummary.connectivityDomainId = jsonObj.segment[i].connectivityDomain.idRef;
                  tJson.segmentSummary.displayName = tJson.displayName
                  tJson.segmentSummary.externalVlan = 0
                  tJson.segmentSummary.id = segmentId
                  tJson.segmentSummary.instanceId = 83268185
                  tJson.segmentSummary.instanceVersion = 0
                  if(jsonObj.segment[i].ipPoolId != undefined ){
                    tJson.segmentSummary.ippoolid = jsonObj.segment[i].ipPoolId;
                    var ippoolData = SimLokiDatabaseActions.getFilteredRecordHandler('ippool',{'id':jsonObj.segment[i].ipPoolId});
                    tJson.segmentSummary.ippoolname = ippoolData[0].ipPoolName
                    tJson.segmentSummary.ippools = ippoolData[0].ipPoolCidr
                    tJson.segmentSummary.gateway = ippoolData[0].gateways
                    tJson.segmentInfos[0].addr = ippoolData[0].gateways[0]
                    tJson.segmentInfos[0].dhcpSvrAddr = ippoolData[0].dhcpServerIps
                    tJson.segmentInfos[0].dnsSvrAddr = ippoolData[0].dnsServerIps
                    tJson.segmentInfos[0].gateway = ippoolData[0].gateways
                    tJson.segmentInfos[0].ipPoolId = jsonObj.segment[i].ipPoolId
                  }
                  tJson.segmentSummary.isAENEnabled = false
                  tJson.segmentSummary.isApProvisioning = jsonObj.segment[i].isApProvisioning ? jsonObj.segment[i].isApProvisioning : false
                  tJson.segmentSummary.isCommonPool = jsonObj.segment[i].isCommonPool ? jsonObj.segment[i].isCommonPool : false;
                  tJson.segmentSummary.isDhcpConfigured = true
                  tJson.segmentSummary.isExtendedProvisioning = jsonObj.segment[i].isExtendedProvisioning ? jsonObj.segment[i].isExtendedProvisioning : false;
                  tJson.segmentSummary.isInfraPool = false
                  tJson.segmentSummary.isL2OnlyPool = jsonObj.segment[i].isL2OnlyPool ? jsonObj.segment[i].isL2OnlyPool : false;
                  tJson.segmentSummary.isWakeOnLan = jsonObj.segment[i].isWakeOnLan ? jsonObj.segment[i].isWakeOnLan : false;
                  tJson.segmentSummary.name = jsonObj.segment[i].name;
                  tJson.segmentSummary.siteCount = 1;
                  tJson.segmentSummary.isCommonPool = false
                  tJson.segmentSummary.virtualNetworkContextId = jsonObj.virtualNetworkContextId
                  tJson.segmentSummary.virtualNetworkId = jsonObj.id
                  tJson.segmentSummary.zoneCount = 0
                  tJson.segmentInfos[0].id = segmentId;
                  tJson.segmentInfos[0].instanceId = 83268185
                  tJson.segmentInfos[0].instanceCreatedOn = UtilityFunctions.getTimeStamp();
                  tJson.segmentInfos[0].instanceUpdatedOn = UtilityFunctions.getTimeStamp();
                  tJson.segmentInfos[0].instanceVersion = 1

                  tJson.instanceCreatedOn = UtilityFunctions.getTimeStamp();
                  tJson.instanceUpdatedOn = UtilityFunctions.getTimeStamp();
                  tJson.namespace = jsonObj.segment[i].connectivityDomain.idRef;
                  
                  SimLokiDatabaseActions.insert('Segment', tJson);
                 /* if(jsonObj.segment[i].isExtendedProvisioning==true) {
                        this.createExtendedNode(segmentId,jsonObj.segment[i].connectivityDomain.idRef);
                  } */
              } else {
                var tObj = SimLokiDatabaseActions.getFilteredRecordHandler('Segment', { 'id' : segmentId });
                tObj[0].ipPoolId = jsonObj.segment[i].ipPoolId;
                tObj[0].wiredAuthMode = jsonObj.segment[i].wiredAuthMode;
                tObj[0].isFloodAndLearn = jsonObj.segment[i].isFloodAndLearn ? jsonObj.segment[i].isFloodAndLearn : false;
                tObj[0].name = jsonObj.segment[i].name;
                tObj[0].trafficType = jsonObj.segment[i].trafficType;
                tObj[0].isApProvisioning = jsonObj.segment[i].isApProvisioning ? jsonObj.segment[i].isApProvisioning : false;
                tObj[0].isDefaultEnterprise = jsonObj.segment[i].isDefaultEnterprise ? jsonObj.segment[i].isDefaultEnterprise : false;
                tObj[0].isExtendedProvisioning = jsonObj.segment[i].isExtendedProvisioning ? jsonObj.segment[i].isExtendedProvisioning : false;
                tObj[0].connectivityDomain.idRef =  jsonObj.segment[i].connectivityDomain.idRef;
                tObj[0].scalableGroupId = jsonObj.segment[i].scalableGroupId;
                tObj[0].isWirelessPool = jsonObj.segment[i].isWirelessPool ? jsonObj.segment[i].isWirelessPool : false;
                tObj[0].isCriticalPool = jsonObj.segment[i].isCriticalPool ? jsonObj.segment[i].isCriticalPool : false;
                tObj[0].isCommonPool = jsonObj.segment[i].isCommonPool ? jsonObj.segment[i].isCommonPool : false;
                tObj[0].isSelectiveFloodingEnabled = jsonObj.segment[i].isSelectiveFloodingEnabled ? jsonObj.segment[i].isSelectiveFloodingEnabled : false;
                tObj[0].isL2OnlyPool = jsonObj.segment[i].isL2OnlyPool ? jsonObj.segment[i].isL2OnlyPool : false;
                tObj[0].isWakeOnLan = jsonObj.segment[i].isWakeOnLan ? jsonObj.segment[i].isWakeOnLan : false;
                tJson.vlanId = jsonObj.segment[i].vlanId;
                SimLokiDatabaseActions.update( 'Segment', tObj );
              }
              recordObj[0].segment.push({ idRef : segmentId});
          }
          if(bVnFound){
                var vncId = recordObj[0].virtualNetworkContextId;
                var vncRecord = SimLokiDatabaseActions.getFilteredRecordHandler('virtualnetworkcontext', { 'id' : vncId });
                vncRecord[0].vncSummaryPoolInfo = [];
                if(jsonObj.anchor) {
                    //when we select 'Use Border/CP for this site to be common for the Virtual Network' 
                    //in a Virtual Network in Host Onboarding. This will be the anchor VN.
                    recordObj[0].anchor = jsonObj.anchor;                    
                    recordObj[0].anchorDevice= [];
                    recordObj[0].anchor.anchorInfo= [];
                    recordObj[0].anchor.anchoredTo= [];
                    recordObj[0].anchor.displayName= "0";
                    recordObj[0].anchor.instanceId= 5472475;
                    recordObj[0].anchor.instanceVersion= 0;

                    vncRecord[0].isAnchored_sim = true;
                    for(i =0; i < recordObj[0].segment.length; i++){
                        //add each segment added thereafter to the pool info of vnc - applicable only for Anchor VN
                        var segId = recordObj[0].segment[i].idRef;
                        var tObj = SimLokiDatabaseActions.getFilteredRecordHandler('Segment', { 'id' : segId });
                        var ipPoolObj = SimLokiDatabaseActions.getFilteredRecordHandler('ippool', {'id':tObj[0].ipPoolId});
                        var obj = {
                            "id": UtilityFunctions.generate_uuid(),
                            "ipv4PoolCidr": ipPoolObj[0].ipPoolCidr,
                            "poolGroupName": ipPoolObj[0].ipPoolName,
                            "poolGroupUuid": ipPoolObj[0].id,
                            "poolType": "ANCHOR"
                        };
                        vncRecord[0].vncSummaryPoolInfo.push(obj);
                    }                    
                } else {
                    delete recordObj[0].anchor;//use case not used as of now
                }
                SimLokiDatabaseActions.update('virtualnetworkcontext', vncRecord);
              SimLokiDatabaseActions.update('VirtualNetwork', recordObj);
          }

         },

         createExtendedNode : function(segmentId,connectivityDomainId) {
               // Adding for creating extenedNode
            var deviceObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', {
                                                                                       '$and': [ {
                                                                                                      'roles':  { '$contains' : "EDGENODE" }
                                                                                                  },
                                                                                                  {
                                                                                                        'connectivityDomain': { '$contains' : connectivityDomainId }
                                                                                                   }
                                                                                                ]
                                                                             });
            if(deviceObj.length>0) {
                var deviceInterface = JSON.parse(JSON.stringify(DeviceInfoTemplate.Device_Interface_Info));
                deviceInterface.segment.push({'idRef' : segmentId});
                var recordFdObj = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'id': connectivityDomainId})

                var profileObject = SimLokiDatabaseActions.getFilteredRecordHandler('siteprofile', {'siteProfileUuid': recordFdObj[0].authenticationProfileId});

                deviceInterface.authenticationProfileId=profileObject[0].siteProfileUuid;


                deviceObj= JSON.parse(JSON.stringify(deviceObj)); // for clone
                deviceObj[0].deviceInterfaceInfo.push(deviceInterface);
                SimNetworkDeviceData.CreateOrUpdateDeviceInfo("DeviceInfo", "put", deviceObj[0]);
            }
         }
  };
});
