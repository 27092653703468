define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/data/SimLokiDatabaseDetails',
], function (SimLokiDb) {
    var dbFactory = {
        createLokiDB: function (tSimDbFactory) {
            SimLokiDb.init(tSimDbFactory.dbParameters, function () {
                //console.log(SimLokiDb.getAllServicesDbDetails());
                tSimDbFactory.onSuccess();
            });
        }
    };

    function SimDbFactory(dbEngine, dbParameters) {
        var self = this;
        this.dbEngine = dbEngine;
        this.dbParameters = dbParameters;

        //console.log(this.dbEngine);
        if (this.dbEngine == 'loki') {
            dbFactory.createLokiDB(this);
        }
    }

    //These success and failure function need to Override by client
    SimDbFactory.prototype.onSuccess = function () {
    };
    SimDbFactory.prototype.onFailure = function () {
    };

    return SimDbFactory;
});
