define({
    "SFO15_radios_data":[
        [
            {
                "attributes": {
                    "id": 425502,
                    "instanceUuid": "8021c42d-e317-47f5-9154-f81eac2641da",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "Internal-9120-5GHz",
                    "type": "internal",
                    "mode": "sector_a",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 16,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                  },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 425501,
                    "instanceUuid": "3ffb7a1e-4301-469a-b311-5595db95e6a8",
                    "slotId": 0,
                    "ifTypeString": "XOR(Unknown)",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "XorUnknown"
                },
                "antenna": {
                    "name": "Internal-9120-Dual-2.4GHz",
                    "type": "internal",
                    "mode": "sector_a",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 11.0
                },"metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 16,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                  },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 425506,
                    "instanceUuid": "5de424c7-bce4-4134-a34f-2a7308be9f7a",
                    "slotId": 0,
                    "ifTypeString": "XOR(Unknown)",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "XorUnknown"
                },
                "antenna": {
                    "name": "Internal-9120-Dual-2.4GHz",
                    "type": "internal",
                    "mode": "sector_a",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 11.0
                },"metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 16,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 425505,
                    "instanceUuid": "e96952dc-305a-466d-8791-f6f10ed18497",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "Internal-9120-5GHz",
                    "type": "internal",
                    "mode": "sector_a",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 11.0
                },"metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 16,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 425504,
                    "instanceUuid": "2a793878-8dcd-4913-83d2-0be8562ca57e",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "Internal-9120-5GHz",
                    "type": "internal",
                    "mode": "sector_a",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 16,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 425503,
                    "instanceUuid": "083e09f3-8271-4449-b040-84d366a85841",
                    "slotId": 0,
                    "ifTypeString": "XOR(Unknown)",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "XorUnknown"
                },
                "antenna": {
                    "name": "Internal-9120-Dual-2.4GHz",
                    "type": "internal",
                    "mode": "sector_a",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 11.0
                },"metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 16,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 425518,
                    "instanceUuid": "5c9bceb6-a736-4a07-b5ec-b44e1457e108",
                    "slotId": 0,
                    "ifTypeString": "XOR(Unknown)",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "XorUnknown"
                },
                "antenna": {
                    "name": "Internal-9120-Dual-2.4GHz",
                    "type": "internal",
                    "mode": "sector_a",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 16,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 425517,
                    "instanceUuid": "44c3ad45-8c50-4704-8326-b4738e451090",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "Internal-9120-5GHz",
                    "type": "internal",
                    "mode": "sector_a",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 16,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 425514,
                    "instanceUuid": "72cc277c-458e-4997-b10b-661715b60c3c",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "Internal-9120-5GHz",
                    "type": "internal",
                    "mode": "sector_a",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 16,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 425513,
                    "instanceUuid": "45f1faf0-ebbf-420f-a5aa-da7093f0089c",
                    "slotId": 0,
                    "ifTypeString": "XOR(Unknown)",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "XorUnknown"
                },
                "antenna": {
                    "name": "Internal-9120-Dual-2.4GHz",
                    "type": "internal",
                    "mode": "sector_a",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 16,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 425516,
                    "instanceUuid": "a9669bc9-7b03-48f6-9396-83da80ae1ce9",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "Internal-9120-5GHz",
                    "type": "internal",
                    "mode": "sector_a",
                    "diversity": null,
                    "azimuthAngle": 6.195919,
                    "elevationAngle": 0.9599311,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 16,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 425515,
                    "instanceUuid": "d754bd8c-ad19-40a0-ac5e-c396e38624ab",
                    "slotId": 0,
                    "ifTypeString": "XOR(Unknown)",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "XorUnknown"
                },
                "antenna": {
                    "name": "Internal-9120-Dual-2.4GHz",
                    "type": "internal",
                    "mode": "sector_a",
                    "diversity": null,
                    "azimuthAngle": 6.195919,
                    "elevationAngle": 0.9599311,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 16,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 425510,
                    "instanceUuid": "125430a9-22a2-4d68-8fea-3ace7c53e01b",
                    "slotId": 0,
                    "ifTypeString": "XOR(Unknown)",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "XorUnknown"
                },
                "antenna": {
                    "name": "Internal-9120-Dual-2.4GHz",
                    "type": "internal",
                    "mode": "sector_a",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 16,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 425509,
                    "instanceUuid": "02311a8e-b03a-4360-92f4-9844d6081f26",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "Internal-9120-5GHz",
                    "type": "internal",
                    "mode": "sector_a",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 16,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 425520,
                    "instanceUuid": "6d4c4e6a-4989-4184-b696-5877a9c8043e",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "Internal-9120-5GHz",
                    "type": "internal",
                    "mode": "sector_a",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 16,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 425519,
                    "instanceUuid": "a5aa118a-0290-4469-9efa-5a988c200c69",
                    "slotId": 0,
                    "ifTypeString": "XOR(Unknown)",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "XorUnknown"
                },
                "antenna": {
                    "name": "Internal-9120-Dual-2.4GHz",
                    "type": "internal",
                    "mode": "sector_a",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 16,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 425508,
                    "instanceUuid": "1e626a28-b4e4-4ef7-b966-fa8a20fdfd32",
                    "slotId": 0,
                    "ifTypeString": "XOR(Unknown)",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "XorUnknown"
                },
                "antenna": {
                    "name": "Internal-9120-Dual-2.4GHz",
                    "type": "internal",
                    "mode": "sector_a",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 16,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 425507,
                    "instanceUuid": "d50ef12e-2aa6-4d04-ae0d-b60fc490b6e3",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "Internal-9120-5GHz",
                    "type": "internal",
                    "mode": "sector_a",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 16,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 425511,
                    "instanceUuid": "f6babe92-aad2-4c71-be9b-fe2b47e838a9",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "Internal-9120-5GHz",
                    "type": "internal",
                    "mode": "sector_a",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 16,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 425512,
                    "instanceUuid": "1ebcc03a-2186-4db2-a112-60ef7bc5af15",
                    "slotId": 0,
                    "ifTypeString": "XOR(Unknown)",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "XorUnknown"
                },
                "antenna": {
                    "name": "Internal-9120-Dual-2.4GHz",
                    "type": "internal",
                    "mode": "sector_a",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 16,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 425500,
                    "instanceUuid": "028ef5df-f529-4a4e-afd4-82f6711ffa7b",
                    "slotId": 0,
                    "ifTypeString": "XOR(Unknown)",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "XorUnknown"
                },
                "antenna": {
                    "name": "Internal-9120-Dual-2.4GHz",
                    "type": "internal",
                    "mode": "sector_a",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 16,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 425499,
                    "instanceUuid": "b854ea8a-e935-46ab-9db3-2ed9f9f60d76",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "Internal-9120-5GHz",
                    "type": "internal",
                    "mode": "sector_a",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 16,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ]
    ],
    "SCJ01_radios_data":[
        [
            {
                "attributes": {
                    "id": 69580421,
                    "instanceUuid": "b431e794-a2c3-4621-a518-3a36d96e6713",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "C-ANT9104-DP20-5GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 1.2217305,
                    "elevationAngle": -0.43633232,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580420,
                    "instanceUuid": "79ba09dc-01d8-4dcc-999c-4c79ccbdd29a",
                    "slotId": 0,
                    "ifTypeString": "802.11b/g",
                    "ifTypeSubband": "B",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "B"
                },
                "antenna": {
                    "name": "C-ANT9104-2.4GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 1.2217305,
                    "elevationAngle": -0.43633232,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580422,
                    "instanceUuid": "82c6ba98-7d3a-4507-a615-acfeafff4e8b",
                    "slotId": 2,
                    "ifTypeString": "802.11a/b/g/n",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "ABGN"
                },
                "antenna": {
                    "name": "C-ANT9104",
                    "type": "external",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 0.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 69580423,
                    "instanceUuid": "bd173870-b04a-4b41-b62b-a7747fa8dd99",
                    "slotId": 0,
                    "ifTypeString": "802.11b/g",
                    "ifTypeSubband": "B",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "B"
                },
                "antenna": {
                    "name": "C-ANT9104-2.4GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 1.2217305,
                    "elevationAngle": -0.43633232,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580425,
                    "instanceUuid": "80528c6e-74d0-43b9-9b65-4f1f852fd1aa",
                    "slotId": 2,
                    "ifTypeString": "802.11a/b/g/n",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "ABGN"
                },
                "antenna": {
                    "name": "C-ANT9104",
                    "type": "external",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 0.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580424,
                    "instanceUuid": "169fded9-26f8-4120-a962-32aaa77123dd",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "C-ANT9104-DP20-5GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 1.2217305,
                    "elevationAngle": -0.43633232,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 69580428,
                    "instanceUuid": "811ea144-d996-46d7-8a47-9308826fc261",
                    "slotId": 2,
                    "ifTypeString": "802.11a/b/g/n",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "ABGN"
                },
                "antenna": {
                    "name": "C-ANT9104",
                    "type": "external",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 0.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580427,
                    "instanceUuid": "6df66fc3-dcad-41fe-ac13-aafe7199d9c9",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "C-ANT9104-DP20-5GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 1.2217305,
                    "elevationAngle": -0.43633232,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580426,
                    "instanceUuid": "c81115d9-c60a-46ba-9f00-98d077e98395",
                    "slotId": 0,
                    "ifTypeString": "802.11b/g",
                    "ifTypeSubband": "B",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "B"
                },
                "antenna": {
                    "name": "C-ANT9104-2.4GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 1.2217305,
                    "elevationAngle": -0.43633232,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 69580429,
                    "instanceUuid": "a59eb6e4-d1be-42bd-b34c-0cde191a08b5",
                    "slotId": 0,
                    "ifTypeString": "802.11b/g",
                    "ifTypeSubband": "B",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "B"
                },
                "antenna": {
                    "name": "C-ANT9104-2.4GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 1.2217305,
                    "elevationAngle": -0.43633232,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580431,
                    "instanceUuid": "5f835d83-f87e-4778-91c1-c117c3b52748",
                    "slotId": 2,
                    "ifTypeString": "802.11a/b/g/n",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "ABGN"
                },
                "antenna": {
                    "name": "C-ANT9104",
                    "type": "external",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 0.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580430,
                    "instanceUuid": "80750fd5-3fa6-457c-88ae-e2c946175b96",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "C-ANT9104-DP20-5GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 1.2217305,
                    "elevationAngle": -0.43633232,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 69580433,
                    "instanceUuid": "57812492-d52b-4096-9c6a-8bc399b35843",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "C-ANT9104-DP20-5GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 1.2217305,
                    "elevationAngle": -0.5235988,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580432,
                    "instanceUuid": "4b6d1701-b0ca-46c4-bcac-8c8ce4d28098",
                    "slotId": 0,
                    "ifTypeString": "802.11b/g",
                    "ifTypeSubband": "B",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "B"
                },
                "antenna": {
                    "name": "C-ANT9104-2.4GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 1.2217305,
                    "elevationAngle": -0.5235988,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580434,
                    "instanceUuid": "55d17bf1-1d3b-4b8f-98fe-a4d9e3a82a6b",
                    "slotId": 2,
                    "ifTypeString": "802.11a/b/g/n",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "ABGN"
                },
                "antenna": {
                    "name": "C-ANT9104",
                    "type": "external",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 0.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 69580437,
                    "instanceUuid": "81fdd2ee-ba7b-491a-9836-a8705d3d9593",
                    "slotId": 2,
                    "ifTypeString": "802.11a/b/g/n",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "ABGN"
                },
                "antenna": {
                    "name": "C-ANT9104",
                    "type": "external",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 0.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580436,
                    "instanceUuid": "1e6803e2-ccf9-42e2-84a0-044203b2e2ca",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "C-ANT9104-DP20-5GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 1.2217305,
                    "elevationAngle": -0.5235988,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580435,
                    "instanceUuid": "b688f9c4-333f-4102-b63d-3ce2d3476fa7",
                    "slotId": 0,
                    "ifTypeString": "802.11b/g",
                    "ifTypeSubband": "B",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "B"
                },
                "antenna": {
                    "name": "C-ANT9104-2.4GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 1.2217305,
                    "elevationAngle": -0.5235988,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 69580439,
                    "instanceUuid": "547ceb5b-c42f-458a-96b4-65d90f666b93",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "C-ANT9104-DP20-5GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 1.2217305,
                    "elevationAngle": -0.5235988,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580438,
                    "instanceUuid": "b306d1a1-f213-46e0-98be-db7a6bae5b1c",
                    "slotId": 0,
                    "ifTypeString": "802.11b/g",
                    "ifTypeSubband": "B",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "B"
                },
                "antenna": {
                    "name": "C-ANT9104-2.4GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 1.2217305,
                    "elevationAngle": -0.5235988,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580440,
                    "instanceUuid": "ade5c9a4-a9f8-4773-bc43-5d91a51e03f8",
                    "slotId": 2,
                    "ifTypeString": "802.11a/b/g/n",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "ABGN"
                },
                "antenna": {
                    "name": "C-ANT9104",
                    "type": "external",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 0.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 69580441,
                    "instanceUuid": "0a30ac59-8ac8-44ab-8d6b-f2a0fd9b42a8",
                    "slotId": 0,
                    "ifTypeString": "802.11b/g",
                    "ifTypeSubband": "B",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "B"
                },
                "antenna": {
                    "name": "C-ANT9104-2.4GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 1.2217305,
                    "elevationAngle": -0.43633232,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580443,
                    "instanceUuid": "2b4f7eb5-1d7c-45fc-8662-7b21ff031350",
                    "slotId": 2,
                    "ifTypeString": "802.11a/b/g/n",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "ABGN"
                },
                "antenna": {
                    "name": "C-ANT9104",
                    "type": "external",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 0.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580442,
                    "instanceUuid": "f5ca4e6b-d10f-49e5-922a-d36a7e611be5",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "C-ANT9104-DP20-5GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 1.2217305,
                    "elevationAngle": -0.43633232,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 69580445,
                    "instanceUuid": "11b2c951-c4f1-4417-bf78-071d0af15441",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "C-ANT9104-DP20-5GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 4.363323,
                    "elevationAngle": -0.43633232,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580444,
                    "instanceUuid": "0504b6e1-6a66-4cf4-b308-23325a04f9c6",
                    "slotId": 0,
                    "ifTypeString": "802.11b/g",
                    "ifTypeSubband": "B",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "B"
                },
                "antenna": {
                    "name": "C-ANT9104-2.4GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 4.363323,
                    "elevationAngle": -0.43633232,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580446,
                    "instanceUuid": "7c37722c-57fe-4a5e-9dd6-b77832685964",
                    "slotId": 2,
                    "ifTypeString": "802.11a/b/g/n",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "ABGN"
                },
                "antenna": {
                    "name": "C-ANT9104",
                    "type": "external",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 0.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 69580449,
                    "instanceUuid": "391c3284-a6de-47eb-a6ab-82158238a994",
                    "slotId": 2,
                    "ifTypeString": "802.11a/b/g/n",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "ABGN"
                },
                "antenna": {
                    "name": "C-ANT9104",
                    "type": "external",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 0.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580448,
                    "instanceUuid": "41caaeef-ba8f-4ee6-902d-f6121bf21bdb",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "C-ANT9104-DP20-5GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 4.363323,
                    "elevationAngle": -0.5235988,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580447,
                    "instanceUuid": "ef1ac933-0456-45aa-8541-ec11606a1d07",
                    "slotId": 0,
                    "ifTypeString": "802.11b/g",
                    "ifTypeSubband": "B",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "B"
                },
                "antenna": {
                    "name": "C-ANT9104-2.4GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 4.363323,
                    "elevationAngle": -0.5235988,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 69580452,
                    "instanceUuid": "54cd541f-a0e2-4ab1-a09f-50d850889edb",
                    "slotId": 2,
                    "ifTypeString": "802.11a/b/g/n",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "ABGN"
                },
                "antenna": {
                    "name": "C-ANT9104",
                    "type": "external",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 0.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580451,
                    "instanceUuid": "f342cd7d-e1c2-4746-93b3-2bcbc000f4dc",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "C-ANT9104-DP20-5GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 4.363323,
                    "elevationAngle": -0.5235988,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580450,
                    "instanceUuid": "d08b2fd5-1df2-49dc-ac3c-6ab18598a0a8",
                    "slotId": 0,
                    "ifTypeString": "802.11b/g",
                    "ifTypeSubband": "B",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "B"
                },
                "antenna": {
                    "name": "C-ANT9104-2.4GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 4.363323,
                    "elevationAngle": -0.5235988,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 69580453,
                    "instanceUuid": "6352d40a-bb91-4d15-ac4e-c17b43f6db33",
                    "slotId": 0,
                    "ifTypeString": "802.11b/g",
                    "ifTypeSubband": "B",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "B"
                },
                "antenna": {
                    "name": "C-ANT9104-2.4GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 4.363323,
                    "elevationAngle": -0.5235988,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580455,
                    "instanceUuid": "4aee1cd2-0af0-4e7c-879e-5f39ba34aeed",
                    "slotId": 2,
                    "ifTypeString": "802.11a/b/g/n",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "ABGN"
                },
                "antenna": {
                    "name": "C-ANT9104",
                    "type": "external",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 0.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580454,
                    "instanceUuid": "2ea32a32-aa92-4a52-9e3e-365f318ff7f9",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "C-ANT9104-DP20-5GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 4.363323,
                    "elevationAngle": -0.5235988,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 69580461,
                    "instanceUuid": "67ea6c1d-d404-42bc-bc94-dee89001ccb7",
                    "slotId": 2,
                    "ifTypeString": "802.11a/b/g/n",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "ABGN"
                },
                "antenna": {
                    "name": "C-ANT9104",
                    "type": "external",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 0.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580460,
                    "instanceUuid": "6d90a9b8-b361-4d47-ac9b-7824de1f3c6d",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "C-ANT9104-DP20-5GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 4.363323,
                    "elevationAngle": -0.5235988,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580459,
                    "instanceUuid": "1b7a3867-e985-4e33-aa85-1b582b4afdd5",
                    "slotId": 0,
                    "ifTypeString": "802.11b/g",
                    "ifTypeSubband": "B",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "B"
                },
                "antenna": {
                    "name": "C-ANT9104-2.4GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 4.363323,
                    "elevationAngle": -0.5235988,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 69580464,
                    "instanceUuid": "d9c07f3c-942b-4029-86cb-cc7d7aaafc89",
                    "slotId": 2,
                    "ifTypeString": "802.11a/b/g/n",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "ABGN"
                },
                "antenna": {
                    "name": "C-ANT9104",
                    "type": "external",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 0.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580463,
                    "instanceUuid": "33817275-b37e-4315-8fb5-0f1dd018f73d",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "C-ANT9104-DP20-5GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 4.363323,
                    "elevationAngle": -0.5235988,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580462,
                    "instanceUuid": "691f643d-3c32-40d8-a279-cf047e7f9768",
                    "slotId": 0,
                    "ifTypeString": "802.11b/g",
                    "ifTypeSubband": "B",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "B"
                },
                "antenna": {
                    "name": "C-ANT9104-2.4GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 4.363323,
                    "elevationAngle": -0.5235988,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 69580465,
                    "instanceUuid": "18c87407-1106-4384-9b03-ea88fbb22ed8",
                    "slotId": 0,
                    "ifTypeString": "802.11b/g",
                    "ifTypeSubband": "B",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "B"
                },
                "antenna": {
                    "name": "C-ANT9104-2.4GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 4.363323,
                    "elevationAngle": -0.5235988,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580467,
                    "instanceUuid": "80b5dca7-f8f8-4ba3-8673-07040db517e6",
                    "slotId": 2,
                    "ifTypeString": "802.11a/b/g/n",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "ABGN"
                },
                "antenna": {
                    "name": "C-ANT9104",
                    "type": "external",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 0.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580466,
                    "instanceUuid": "530b879c-8638-4d9f-8dca-1e6747af8b03",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "C-ANT9104-DP20-5GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 4.363323,
                    "elevationAngle": -0.5235988,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 69580469,
                    "instanceUuid": "cf5e19d9-b10f-452f-86ef-d946d936733f",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "C-ANT9104-DP20-5GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 4.363323,
                    "elevationAngle": -0.5235988,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580468,
                    "instanceUuid": "4e8eee90-d863-4774-b94e-42c68c077bbb",
                    "slotId": 0,
                    "ifTypeString": "802.11b/g",
                    "ifTypeSubband": "B",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "B"
                },
                "antenna": {
                    "name": "C-ANT9104-2.4GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 4.363323,
                    "elevationAngle": -0.5235988,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580470,
                    "instanceUuid": "80b35870-c9a0-43d7-947e-e00447db1e66",
                    "slotId": 2,
                    "ifTypeString": "802.11a/b/g/n",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "ABGN"
                },
                "antenna": {
                    "name": "C-ANT9104",
                    "type": "external",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 0.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 69580471,
                    "instanceUuid": "c74c2473-203d-48a4-a396-fc7e3dc79efb",
                    "slotId": 0,
                    "ifTypeString": "802.11b/g",
                    "ifTypeSubband": "B",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "B"
                },
                "antenna": {
                    "name": "C-ANT9104-2.4GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 4.363323,
                    "elevationAngle": -0.5235988,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580473,
                    "instanceUuid": "839ad861-25b3-4c16-af9b-7f3ecabe5553",
                    "slotId": 2,
                    "ifTypeString": "802.11a/b/g/n",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "ABGN"
                },
                "antenna": {
                    "name": "C-ANT9104",
                    "type": "external",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 0.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580472,
                    "instanceUuid": "b15e4afb-b2d6-429d-a369-c9d9492f0aa3",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "C-ANT9104-DP20-5GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 4.363323,
                    "elevationAngle": -0.5235988,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 69580476,
                    "instanceUuid": "e153b5ce-aec5-4b76-aa5e-7769b40a12a8",
                    "slotId": 2,
                    "ifTypeString": "802.11a/b/g/n",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "ABGN"
                },
                "antenna": {
                    "name": "C-ANT9104",
                    "type": "external",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 0.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580475,
                    "instanceUuid": "feb80aeb-3cbd-449e-8d64-fa6f96894b71",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "C-ANT9104-DP20-5GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 4.363323,
                    "elevationAngle": -0.5235988,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580474,
                    "instanceUuid": "f215e5c8-2195-4363-a8cf-cbbe7c9ee7ed",
                    "slotId": 0,
                    "ifTypeString": "802.11b/g",
                    "ifTypeSubband": "B",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "B"
                },
                "antenna": {
                    "name": "C-ANT9104-2.4GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 4.363323,
                    "elevationAngle": -0.5235988,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 69580477,
                    "instanceUuid": "f20a290e-a69e-43a1-8fa0-4059d873d65f",
                    "slotId": 0,
                    "ifTypeString": "802.11b/g",
                    "ifTypeSubband": "B",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "B"
                },
                "antenna": {
                    "name": "C-ANT9104-2.4GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 4.363323,
                    "elevationAngle": -0.43633232,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580479,
                    "instanceUuid": "f10ca3dd-77b2-4362-b536-9a60fa92f313",
                    "slotId": 2,
                    "ifTypeString": "802.11a/b/g/n",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "ABGN"
                },
                "antenna": {
                    "name": "C-ANT9104",
                    "type": "external",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 0.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580478,
                    "instanceUuid": "47f10331-caf3-46f9-a052-65917aa38cd0",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "C-ANT9104-DP20-5GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 4.363323,
                    "elevationAngle": -0.43633232,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 69580481,
                    "instanceUuid": "8063983d-8155-46ca-b369-d68a3f75c811",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "C-ANT9104-DP20-5GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 4.363323,
                    "elevationAngle": -0.43633232,
                    "gain": 10.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580480,
                    "instanceUuid": "7511ab42-94e3-489d-a232-ae720625f729",
                    "slotId": 0,
                    "ifTypeString": "802.11b/g",
                    "ifTypeSubband": "B",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "B"
                },
                "antenna": {
                    "name": "C-ANT9104-2.4GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 4.363323,
                    "elevationAngle": -0.43633232,
                    "gain": 4.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 69580482,
                    "instanceUuid": "18858811-fe21-4b93-9c1e-6077c3386fbb",
                    "slotId": 2,
                    "ifTypeString": "802.11a/b/g/n",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "ABGN"
                },
                "antenna": {
                    "name": "C-ANT9104",
                    "type": "external",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 0.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 85569793,
                    "instanceUuid": "3c61de63-80ca-4002-b20b-f322a8c54356",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "C-ANT9104-DP20-5GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 4.363323,
                    "elevationAngle": -0.5235988,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 85569792,
                    "instanceUuid": "0b05f62b-9269-439c-b794-065a04e1421c",
                    "slotId": 0,
                    "ifTypeString": "802.11b/g",
                    "ifTypeSubband": "B",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "B"
                },
                "antenna": {
                    "name": "C-ANT9104-2.4GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 4.363323,
                    "elevationAngle": -0.5235988,
                    "gain": 11.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 85569794,
                    "instanceUuid": "1aa1640d-c052-41ca-a077-3c8447429b7f",
                    "slotId": 2,
                    "ifTypeString": "802.11a/b/g/n",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "ABGN"
                },
                "antenna": {
                    "name": "C-ANT9104",
                    "type": "external",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 0.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 85569801,
                    "instanceUuid": "fd2d9687-7b8a-417b-a70e-e54e7c3dd5f7",
                    "slotId": 0,
                    "ifTypeString": "802.11b/g",
                    "ifTypeSubband": "B",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "B"
                },
                "antenna": {
                    "name": "Internal-9136-2.4GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 5.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 85569803,
                    "instanceUuid": "9d653d09-6cfa-4e7d-a3bd-34844ac2d77b",
                    "slotId": 2,
                    "ifTypeString": "802.11a/b/g/n",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "ABGN"
                },
                "antenna": {
                    "name": "Internal-Dual-Band",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 0.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 85569802,
                    "instanceUuid": "983f7185-5c3e-4389-8801-4d1659b277be",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "Internal-9136-5GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 6.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ],
        [
            {
                "attributes": {
                    "id": 85569801,
                    "instanceUuid": "fd2d9687-7b8a-417b-a70e-e54e7c3dd5f7",
                    "slotId": 0,
                    "ifTypeString": "802.11b/g",
                    "ifTypeSubband": "B",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "B"
                },
                "antenna": {
                    "name": "Internal-9136-2.4GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 5.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 85569803,
                    "instanceUuid": "9d653d09-6cfa-4e7d-a3bd-34844ac2d77b",
                    "slotId": 2,
                    "ifTypeString": "802.11a/b/g/n",
                    "ifTypeSubband": "ABGN",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "ABGN"
                },
                "antenna": {
                    "name": "Internal-Dual-Band",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 0.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            },
            {
                "attributes": {
                    "id": 85569802,
                    "instanceUuid": "983f7185-5c3e-4389-8801-4d1659b277be",
                    "slotId": 1,
                    "ifTypeString": "802.11a",
                    "ifTypeSubband": "A",
                    "channel": null,
                    "channelString": null,
                    "ifMode": "A"
                },
                "antenna": {
                    "name": "Internal-9136-5GHz",
                    "type": "internal",
                    "mode": "unknown",
                    "diversity": null,
                    "azimuthAngle": 0.0,
                    "elevationAngle": 0.0,
                    "gain": 6.0
                },
                "metrics": {
                    "status": "CLEARED",
                    "adminStatus": "up",
                    "opStatus": "up",
                    "dot11AdminStatus": 1,
                    "alarmCount": null,
                    "txPowerLevel": 20,
                    "txUtilization": 0,
                    "rxUtilization": 0,
                    "channelUtilization": 75,
                    "isCleanAirEnabled": false,
                    "isCleanAirCapable": false,
                    "avgAQ": 0,
                    "minAQ": 0,
                    "numberOfClients": 1,
                    "numberOfPoorClients": 0,
                    "loadProfileState": 0,
                    "coverageProfileState": 0,
                    "noiseProfileState": 0,
                    "interferenceProfileState": 0,
                    "xorRadioBand": 1,
                    "isBackhaul": false,
                    "ifType11nCapable": false,
                    "extensionChannelString": null,
                    "channelBandwidth": null,
                    "radioSubType": 1,
                    "channelWidth": "40",
                    "isAssociated": true,
                    "isReachable": true,
                    "framework": 2
                },
                "isSensor": false
            }
        ]
    ]
});