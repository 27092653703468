define({
	"getClientInsightHistoryTemplate":
		{
			"data": {
				"getClientInsightSummary36History": {
					"__typename": "GetClientInsightSummary36HistoryConnection",
					"nodes": [
						{
							"__typename": "ClientInsightAgg36HistoryType",
							"endTimestamp": "2021-10-27T00:00:00",
							"numberCards": 4
						},
						{
							"__typename": "ClientInsightAgg36HistoryType",
							"endTimestamp": "2021-11-24T00:00:00",
							"numberCards": 3
						}, 
						{
							"__typename": "ClientInsightAgg36HistoryType",
							"endTimestamp": "2021-12-01T00:00:00",
							"numberCards": 2
						},
						{
							"__typename": "ClientInsightAgg36HistoryType",
							"endTimestamp": "2022-01-19T00:00:00",
							"numberCards": 2
						},
					],
					"totalCount": 4
				}
			}
		},
	"getAllInsightQueryTemplate":
		{
			"data": { "getAllInsight36": { "__typename": "GetAllInsight36Connection", "nodes": [] } }
		},
	"getClientInsightSummaryTemplate":
		{
			"data": {
				"getClientInsightSummary36": {
					"__typename": "GetClientInsightSummary36Connection",
					"nodes": []

				}
			}
		},
	"getListPageSummaryDataTemplate":
		{
			"data": {
				"getRootcauseClientInsightSummary36": {
					"__typename": "GetRootcauseClientInsightSummary36Connection",
					"nodes": [{
						"__typename": "ClientInsightSummary36RcType",
						"endTimestamp": "2022-04-06T00:00:00",
						"frequencyBand": "5 GHz",
						"impactedAps": 30,
						"impactedBuildings": 13,
						"impactedClients": 1899,
						"rootCauseCategory": "Low density",
						"rootCauseCategoryId": "a70b30fe3321351b19dd",
						"startTimestamp": "2022-03-09T00:00:00",
						"timestamp": "2022-04-06T00:11:40.220123",
						"topImpactedApMacs": ["HB-TORjIgF4XluBsgKK-fMeVmWGTwi1qixzhYNFRv9bU", "3jUmxuO7nOS3Tv60db-3ZPtgEvumZ6hL3ZyFUymi4_fY", "PEs2VIXxb0q63p8liTPpadsvJPOoJpFdU9uAI-TxZkoj"],
						"topImpactedAps": ["aes:hjMy-M8a3XxcDqJ-5DjGmWk_V5HUiNje3V9cot0=", "aes:zshJ7sd3yXvzZGTfoxivu8fg7oI-MQ7oJh8wacE=", "aes:07VHSzvQr9-wi4n9D4VienXEHqehQBb4zYUaOazcD1q6"]
					}],
					"totalCount": 1
				}
			}
		},
	"getAllFeedbackByUserQueryTemplate":
		{ "data": { "allFeedback36S": { "__typename": "Feedback36SConnection", "nodes": [] } } },

	"getListPageAnalysisDataTemplate":
		{
			"data": {
				"getClientInsightAgg36Analysis": {
					"__typename": "GetClientInsightAgg36AnalysisConnection",
					"nodes": [{
						"__typename": "ClientInsightAgg36Type",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 12151,
							"key": 0
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 4,
							"key": null
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 495,
							"key": 2
						}],
						"apModel": [{
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-AP2802E-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 4,
							"key": "AIR-AP2802E-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 5,
							"key": "AIR-AP1562E-H-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP1562E-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-AP2802I-H-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 3,
							"key": "AIR-AP2802I-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP1602E-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 4,
							"key": "AIR-AP2802E-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-AP2802I-S-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP2702E-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-AP1542I-S-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "C9120AXE-B"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP2702I-H-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP3702I-H-K9"
						}],
						"clientCountBinSize": 1,
						"clientCountKde": [0.284229497473429, 0.285800859383655, 0.190144261208469, 0.0974421143735731, 0.046107340475484, 0.0237755060954875, 0.0139127322768802, 0.0092258289037943, 0.0070432451550051, 0.00539756398456518, 0.00367092572216547, 0.00246695141934588, 0.00199783775163734, 0.00184868489059438, 0.00175387018621976, 0.00182369484099929, 0.00218859853444004, 0.00266336724094495, 0.00326499906636185, 0.00380750899423743, 0.00395222046910431, 0.00345068915475677, 0.00246946656387258],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.396651594626176, 0.106746683143369, 0.0756486538154273, 0.0318636754601958, 0.124071521862265, 0.127657482256624, 0.0175316274229406, 0.00255249721582406, 0.0192097593576359, 0.0290685047950112, 0.0307012122614518, 0.00377192650362441, 0.00210928047304979, 0.0142522834795689, 0.00302959456568586, 0.00698641995706992, 0.00138234706579987, 0.00221540379221873, 0.00145881723064941, 0, 0.0017145841900541, 0],
						"frequencyBand": "5 GHz",
						"impactedAps": 30,
						"interferenceBinSize": 1,
						"interferenceKde": [0.642015588341319, 0.249844707670133, 0.0631265368782165, 0.0157570840876149, 0.00594783329371825, 0.00551689580568014, 0.00429436666572141, 0.00349028233658051, 0.00247944061294402, 0.00193641596149366, 0.00262880789829154, 0.00296204044828667],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 10970,
							"key": 0
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 1680,
							"key": 1
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 1724,
							"key": 0
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 10926,
							"key": 1
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00122254834745402, 0.0152762016486484, 0.0488550864115194, 0.00645312321584783, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0167884419796494, 0.128048992080252, 0.141954409678205, 0.08738985407245, 0.0601269295566183, 0.0503191374503824, 0.0410720972812698, 0.0193658710624769, 0.0144187483459895, 0.0325141374768097, 0.0694018323082372, 0.0414247575735784, 0.0202816907871815, 0.00985685453955092, 0.00577966714292982, 0.00688908924662415, 0.00532800524260125, 0.00384628414404573, 0.00509955406439894, 0.00604406969604167, 0.0137957506211475, 0.0874679313870212, 0.0135688050724946, 0.00533643206506858, 0.00325501006416309, 0.00199573643564554, 0.00538474804313723, 0.0268262472449287, 0.00461195571363098],
						"powerAnomaly": "high",
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0143007255613556, 0.0385138453226494, 0.024651128961994, 0.0733349369605514, 0.173577492004532, 0.176992573699704, 0.146470125399459, 0.0667209965376813, 0.0293148571041099, 0.0832336868432703, 0.172889631604694],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 12650,
							"key": 4
						}],
						"primaryImageVersion": [{
							"__typename": "CiStrKeyCountValue",
							"count": 7,
							"key": "8.10.162.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 10,
							"key": "8.5.182.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 11,
							"key": "8.5.161.6"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "8.5.171.0"
						}],
						"rootCauseCategory": "Low density",
						"rootCauseKpis": ["powerKde"],
						"trafficBinSize": 1,
						"trafficKde": [0.645367713206132, 0.305889261164699, 0.044063660477456, 0.00306661816489295],
						"utilizationBinSize": 1,
						"utilizationKde": [0.48267297776359, 0.378259536041801, 0.0879428568892446, 0.0188645492386252, 0.00762247060164856, 0.00629440489276352, 0.00451917951930112, 0.00382899805573264, 0.00281548107809463, 0.0018962608266439, 0.0022575613241133, 0.00302572376844193]
					}, {
						"__typename": "ClientInsightAgg36Type",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 12151,
							"key": 0
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 4,
							"key": null
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 495,
							"key": 2
						}],
						"apModel": [{
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-AP2802E-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 4,
							"key": "AIR-AP2802E-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 5,
							"key": "AIR-AP1562E-H-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP1562E-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-AP2802I-H-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 3,
							"key": "AIR-AP2802I-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP1602E-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 4,
							"key": "AIR-AP2802E-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-AP2802I-S-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP2702E-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-AP1542I-S-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "C9120AXE-B"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP2702I-H-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP3702I-H-K9"
						}],
						"clientCountBinSize": 1,
						"clientCountKde": [0.284229497473429, 0.285800859383655, 0.190144261208469, 0.0974421143735731, 0.046107340475484, 0.0237755060954875, 0.0139127322768802, 0.0092258289037943, 0.0070432451550051, 0.00539756398456518, 0.00367092572216547, 0.00246695141934588, 0.00199783775163734, 0.00184868489059438, 0.00175387018621976, 0.00182369484099929, 0.00218859853444004, 0.00266336724094495, 0.00326499906636185, 0.00380750899423743, 0.00395222046910431, 0.00345068915475677, 0.00246946656387258],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.396651594626176, 0.106746683143369, 0.0756486538154273, 0.0318636754601958, 0.124071521862265, 0.127657482256624, 0.0175316274229406, 0.00255249721582406, 0.0192097593576359, 0.0290685047950112, 0.0307012122614518, 0.00377192650362441, 0.00210928047304979, 0.0142522834795689, 0.00302959456568586, 0.00698641995706992, 0.00138234706579987, 0.00221540379221873, 0.00145881723064941, 0, 0.0017145841900541, 0],
						"frequencyBand": "5 GHz",
						"impactedAps": 30,
						"interferenceBinSize": 1,
						"interferenceKde": [0.642015588341319, 0.249844707670133, 0.0631265368782165, 0.0157570840876149, 0.00594783329371825, 0.00551689580568014, 0.00429436666572141, 0.00349028233658051, 0.00247944061294402, 0.00193641596149366, 0.00262880789829154, 0.00296204044828667],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 10970,
							"key": 0
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 1680,
							"key": 1
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 1724,
							"key": 0
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 10926,
							"key": 1
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00122254834745402, 0.0152762016486484, 0.0488550864115194, 0.00645312321584783, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0167884419796494, 0.128048992080252, 0.141954409678205, 0.08738985407245, 0.0601269295566183, 0.0503191374503824, 0.0410720972812698, 0.0193658710624769, 0.0144187483459895, 0.0325141374768097, 0.0694018323082372, 0.0414247575735784, 0.0202816907871815, 0.00985685453955092, 0.00577966714292982, 0.00688908924662415, 0.00532800524260125, 0.00384628414404573, 0.00509955406439894, 0.00604406969604167, 0.0137957506211475, 0.0874679313870212, 0.0135688050724946, 0.00533643206506858, 0.00325501006416309, 0.00199573643564554, 0.00538474804313723, 0.0268262472449287, 0.00461195571363098],
						"powerAnomaly": "high",
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0143007255613556, 0.0385138453226494, 0.024651128961994, 0.0733349369605514, 0.173577492004532, 0.176992573699704, 0.146470125399459, 0.0667209965376813, 0.0293148571041099, 0.0832336868432703, 0.172889631604694],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 12650,
							"key": 4
						}],
						"primaryImageVersion": [{
							"__typename": "CiStrKeyCountValue",
							"count": 7,
							"key": "8.10.162.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 10,
							"key": "8.5.182.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 11,
							"key": "8.5.161.6"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "8.5.171.0"
						}],
						"rootCauseCategory": "Low density",
						"rootCauseKpis": ["powerKde"],
						"trafficBinSize": 1,
						"trafficKde": [0.645367713206132, 0.305889261164699, 0.044063660477456, 0.00306661816489295],
						"utilizationBinSize": 1,
						"utilizationKde": [0.48267297776359, 0.378259536041801, 0.0879428568892446, 0.0188645492386252, 0.00762247060164856, 0.00629440489276352, 0.00451917951930112, 0.00382899805573264, 0.00281548107809463, 0.0018962608266439, 0.0022575613241133, 0.00302572376844193]
					}, {
						"__typename": "ClientInsightAgg36Type",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 4,
							"key": null
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 403,
							"key": 2
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 55404,
							"key": 0
						}],
						"apModel": [{
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "C9136I-B"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP2802E-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-AP2802I-K-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 3,
							"key": "AIR-AP2802E-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP2802I-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 4,
							"key": "AIR-AP1542I-S-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP2702E-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 16,
							"key": "AIR-AP2802I-S-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 3,
							"key": "AIR-AP2802E-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 3,
							"key": "AIR-AP3802I-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 3,
							"key": "AIR-AP2802E-S-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "C9120AXI-B"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "C9136I-B"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "C9136I-B"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 8,
							"key": "AIR-AP3802I-H-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 9,
							"key": "AIR-AP2802I-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 39,
							"key": "AIR-AP3802I-Z-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 4,
							"key": "AIR-CAP2702I-H-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 16,
							"key": "AIR-AP3802I-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 12,
							"key": "AIR-AP2802I-H-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP2702E-H-K9"
						}],
						"clientCountBinSize": 0.842105263157895,
						"clientCountKde": [0.332528100737908, 0.293580191377058, 0.179276025287229, 0.0861666785170802, 0.0397856141912669, 0.0211379149295948, 0.0134450542240992, 0.00945266606708025, 0.00684539716908055, 0.00513537603559487, 0.0038917914726267, 0.00298914225280714, 0.00239202598641637, 0.00191233261871683],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.355314348888475, 0.0544664608682159, 0.0278222920955946, 0.0223300676229551, 0.126938816290297, 0.16616738831126, 0.0259811033667442, 0.00457437513118152, 0.0255663275923121, 0.048600025581519, 0.0572209413937359, 0.00774507811933987, 0.00321735832125928, 0.0216496854298619, 0.00489076808164594, 0.0145164090731189, 0.00251295024963704, 0.00462688935612887, 0.00469321736879723, 0.00104755405850185, 0.00356459681637583, 0, 0.00216752394169741, 0, 0.00114401394879948, 0.00269733666658151, 0.0031528303077886, 0.00253389084234541, 0.00123326794929261, 0.00113669138448174, 0.00112128356206901],
						"frequencyBand": "5 GHz",
						"impactedAps": null,
						"interferenceBinSize": 1,
						"interferenceKde": [0.202292026884814, 0.272296154804639, 0.175926799159809, 0.115764600441824, 0.0801927968992466, 0.0445035078943416, 0.0238647119458158, 0.0156810191250762, 0.0128606388593957, 0.00901962741744507, 0.00679052872765148, 0.00549969089200579, 0.00524470416538848, 0.00564853958150183, 0.00578654760485037, 0.00527422005876382, 0.00525588503655091, 0.00367324238830401, 0.00175875072862725, 0.00136669558026299],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 2527,
							"key": 1
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 53284,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 52378,
							"key": 1
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 3433,
							"key": 0
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00322528673214352, 0.00609052037051494, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00598374427170423, 0.00243631214800629, 0, 0, 0, 0, 0, 0.0462412040321439, 0.363323544022566, 0.22152407771584, 0.0585758527855411, 0.0319947129661617, 0.0368492288442128, 0.00708468299889215, 0, 0.00148206846807142, 0.0229728588446294, 0.0993552199999691, 0.0481221238607288, 0.0108002121643001, 0.00331845306041535, 0.00204788343403858, 0, 0, 0, 0, 0, 0.00304311881610498, 0.022485775647911, 0.00304311881610497],
						"powerAnomaly": null,
						"powerBinSize": 1,
						"powerKde": [0.00723198097444969, 0.153266351807969, 0.430866470368206, 0.177772448808256, 0.0421573496890216, 0.0320060121899417, 0.00936568841806999, 0.00412159354724391, 0.010176655299623, 0.00376490584965971, 0.00226523788989356, 0.00590017458881264, 0.00212653241189893, 0, 0.00226291105177571, 0.00667234339480805, 0.0109231381059294, 0.0284489928128703, 0.0228501910444468, 0.00851111696927723, 0.00836033727655433, 0.0032845879835786, 0.00705679318183549, 0.0206081863358772],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 54133,
							"key": 4
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 1678,
							"key": 6
						}],
						"primaryImageVersion": [{
							"__typename": "CiStrKeyCountValue",
							"count": 30,
							"key": "8.5.182.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 39,
							"key": "8.10.162.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 60,
							"key": "8.5.161.6"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "17.3.2.32"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "17.3.4.40"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "8.5.171.0"
						}],
						"rootCauseCategory": "BASELINE",
						"rootCauseKpis": null,
						"trafficBinSize": 1,
						"trafficKde": [0.723437968944651, 0.22962299961761, 0.0249305667943175, 0.00940684887154029, 0.00561118887304035, 0.00323952383789553, 0.00220254185244396],
						"utilizationBinSize": 1,
						"utilizationKde": [0.184891776346028, 0.267479561672708, 0.18069834831254, 0.116680286945328, 0.0809717955629137, 0.0467670299419267, 0.0247936647907355, 0.0171680588877346, 0.0147323249588127, 0.0102774172436539, 0.00807579947980155, 0.00640516130859857, 0.00570674713532352, 0.00645831009554676, 0.00562245320260313, 0.00564775945779564, 0.00604084376364766, 0.00451989330284495, 0.00262400383047221, 0.00180175340525104, 0.00141438042904395, 0]
					}],
					"totalCount": 3
				}
			}
		},
	"getClientInsightRadiosTemplate":
		{
			"data": {
				"getClientInsightRadios36Table": {
					"__typename": "GetClientInsightRadios36TableConnection",
					"nodes": [{
						"__typename": "ClientInsightRadios36TableType",
						"aesApMac": "7Q4ZClJF8qM6ZxJviWgxVkZ29KTg2hR2_M2mAr60Mu-x",
						"aesApName": "aes:YY2mrzGpzWB25PrOiYKxI4BoLw1uZHNXXOd4MpzmBA==",
						"apEntityId": "AIR-AP1542I-S-K9",
						"errorFlags": ["speedDensity"],
						"frequencyBand": "5 GHz",
						"impactedClients": 16,
						"severity": "s2",
						"siteHierarchyArray": ["aes:S1DpNVvZ8zYO9k1YSicp7RjfnqM=", "aes:NvCkZqu6mqhETeG5alU4LZKKZ2Y8p8Ow", "aes:WkaRN2xzvsYWJLHoL1_dI2KTAtNDVKaLXs56gxNqdIquSW16H6fY2NdYjJtJ6nR3g3KqtA==", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
						"siteId": "a42456d0-5d2e-4a85-8cfc-3853cf9557f3",
						"uniqueClients": 21
					}, {
						"__typename": "ClientInsightRadios36TableType",
						"aesApMac": "bh-_DGhMW44vqZ6awPud_ctNdA19I2XFV8w3Xme6q4oF",
						"aesApName": "aes:Nnol8ZU4rN0TuVBf0Nb92LsU5UKe20Qx0qqkBzXsKK0=",
						"apEntityId": "AIR-AP1562E-H-K9",
						"errorFlags": ["speedDensity"],
						"frequencyBand": "5 GHz",
						"impactedClients": 7,
						"severity": "s2",
						"siteHierarchyArray": ["aes:S1DpNVvZ8zYO9k1YSicp7RjfnqM=", "aes:E6kXjwL_-7hiGXQg1LH9v6-fkhBm", "aes:QO_yEw3_kRbUYRTDOEKcEIY94zaRFqQWv38E-km4bm6e56jaaJpo9veaezhfzmWZ", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
						"siteId": "650861b8-067b-49ff-9920-6bda877dda54",
						"uniqueClients": 9
					}, {
						"__typename": "ClientInsightRadios36TableType",
						"aesApMac": "FVJLSiy0X0x4rHemiLirfii1hHribTkvykAbTZlTrJZ-",
						"aesApName": "aes:oOGoX_ElSc-d4HiHTfSyQ_VN4qwouYKTO15xUTbJ",
						"apEntityId": "AIR-AP1562E-H-K9",
						"errorFlags": ["rssiKde", "snrKde", "speedDensity"],
						"frequencyBand": "5 GHz",
						"impactedClients": 74,
						"severity": "s1",
						"siteHierarchyArray": ["aes:S1DpNVvZ8zYO9k1YSicp7RjfnqM=", "aes:E6kXjwL_-7hiGXQg1LH9v6-fkhBm", "aes:QO_yEw3_kRbUYRTDOEKcEIY94zaRFqQWv38E-km4bm6e56jaaJpo9veaezhfzmWZ", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
						"siteId": "650861b8-067b-49ff-9920-6bda877dda54",
						"uniqueClients": 102
					}, {
						"__typename": "ClientInsightRadios36TableType",
						"aesApMac": "sxp4QLNkz5D3MElvhvYrxjtq2WkqflJNhJLQwC-xVMja",
						"aesApName": "aes:-eY62RDnTv2Q4vhLSiIahbapyOZ2LAc_93Hvvip7a-K3",
						"apEntityId": "AIR-AP2802I-E-K9",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 44,
						"severity": "s1",
						"siteHierarchyArray": ["aes:xyO0HSf4wYs-ywtrqJiDGpLRFxU=", "aes:bH4cm2tPVaYWgJflArYXHa554xEa87iBtKWe", "aes:evw4N0CruaX8hMI4nkb3e4YfC7l7X0qoFPNm122Q1YRT9a5dhSkIxeXXBFamzSXF59dVCDwvmHA=", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
						"siteId": "63b6741e-8955-4bb7-ad47-379f2a3d5bd8",
						"uniqueClients": 53
					}, {
						"__typename": "ClientInsightRadios36TableType",
						"aesApMac": "lTfo7upgIiKDBc7xDcRNZb0iI9AbLhg4Q7bCvuOe2baY",
						"aesApName": "aes:gEKFYSaYB74EthUnj1cJKjEB4NuZmntFEz0uZQ==",
						"apEntityId": "AIR-AP2802E-E-K9",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 13,
						"severity": "s2",
						"siteHierarchyArray": ["aes:xyO0HSf4wYs-ywtrqJiDGpLRFxU=", "aes:p7cPGanqsRLIovrp2f8ys6kME5M0Aw==", "aes:hBByQZ3MZ6vX7p6FVypwuhNsUL3ArPZsub90w-Xl4S-lJWDycRs=", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
						"siteId": "182025b4-d197-4137-ad62-042a704b9a91",
						"uniqueClients": 17
					}, {
						"__typename": "ClientInsightRadios36TableType",
						"aesApMac": "LEtw5HoiRvRyR_l0LbqGoanTDQVWElHT4v6dZWpp3yEQ",
						"aesApName": "aes:H1ypLtQWxnImKSZ1c3oZ6RPx3DN3AZAAvWu3pz0F",
						"apEntityId": "AIR-CAP2702I-H-K9",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 13,
						"severity": "s2",
						"siteHierarchyArray": ["aes:S1DpNVvZ8zYO9k1YSicp7RjfnqM=", "aes:E6kXjwL_-7hiGXQg1LH9v6-fkhBm", "aes:QO_yEw3_kRbUYRTDOEKcEIY94zaRFqQWv38E-km4bm6e56jaaJpo9veaezhfzmWZ", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
						"siteId": "650861b8-067b-49ff-9920-6bda877dda54",
						"uniqueClients": 21
					}, {
						"__typename": "ClientInsightRadios36TableType",
						"aesApMac": "b1qY01YgoSktJwkiFoKZEqycmDha8qZhJYYjEBjSi1KL",
						"aesApName": "aes:FHCdStam4rfkX8RuIUPsYFQr8L-QRBsIFIJ25ifg2So=",
						"apEntityId": "AIR-AP2802E-A-K9",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 315,
						"severity": "s1",
						"siteHierarchyArray": ["aes:moMlBCB9-yo9HRTppAfPhB53", "aes:NFYNVO6gpWudr7SfUvCG6y5MqZlUPA==", "aes:GunvDwGEl4oTu7D-IcrEqjdcXGXjjPMgCs2ES6TYY-7RKGG0D9VfCjL7L1YdyQWbPQ==", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
						"siteId": "9cebf26a-edb4-43b5-a9b6-cf1fa30af5e5",
						"uniqueClients": 467
					}, {
						"__typename": "ClientInsightRadios36TableType",
						"aesApMac": "nhZqFUQIlx1lQPWKyRlocZAdU-ag30nPgURsjm8d-v_H",
						"aesApName": "aes:7IbctdNPjgqxq7pf-hKC4weIHhXHvb-rAJxPxv4_zFo=",
						"apEntityId": "AIR-AP2802E-A-K9",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 45,
						"severity": "s1",
						"siteHierarchyArray": ["aes:moMlBCB9-yo9HRTppAfPhB53", "aes:NFYNVO6gpWudr7SfUvCG6y5MqZlUPA==", "aes:GunvDwGEl4oTu7D-IcrEqjdcXGXjjPMgCs2ES6TYY-7RKGG0D9VfCjL7L1YdyQWbPQ==", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
						"siteId": "9cebf26a-edb4-43b5-a9b6-cf1fa30af5e5",
						"uniqueClients": 77
					}, {
						"__typename": "ClientInsightRadios36TableType",
						"aesApMac": "PEs2VIXxb0q63p8liTPpadsvJPOoJpFdU9uAI-TxZkoj",
						"aesApName": "aes:07VHSzvQr9-wi4n9D4VienXEHqehQBb4zYUaOazcD1q6",
						"apEntityId": "AIR-AP2802I-E-K9",
						"errorFlags": ["rssiKde", "snrKde", "speedDensity"],
						"frequencyBand": "5 GHz",
						"impactedClients": 81,
						"severity": "s0",
						"siteHierarchyArray": ["aes:xyO0HSf4wYs-ywtrqJiDGpLRFxU=", "aes:bH4cm2tPVaYWgJflArYXHa554xEa87iBtKWe", "aes:evw4N0CruaX8hMI4nkb3e4YfC7l7X0qoFPNm122Q1YRT9a5dhSkIxeXXBFamzSXF59dVCDwvmHA=", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
						"siteId": "63b6741e-8955-4bb7-ad47-379f2a3d5bd8",
						"uniqueClients": 98
					}, {
						"__typename": "ClientInsightRadios36TableType",
						"aesApMac": "_407k6y7cR7-An-i3jMHfLFYCyBjeus8A0gaGZS8P9rc",
						"aesApName": "aes:psNIjyg-3y63Vgx5YZu-Byv1JMGhO66VT6oOo88g",
						"apEntityId": "AIR-AP1562E-H-K9",
						"errorFlags": ["speedDensity"],
						"frequencyBand": "5 GHz",
						"impactedClients": 3,
						"severity": "s2",
						"siteHierarchyArray": ["aes:S1DpNVvZ8zYO9k1YSicp7RjfnqM=", "aes:E6kXjwL_-7hiGXQg1LH9v6-fkhBm", "aes:QO_yEw3_kRbUYRTDOEKcEIY94zaRFqQWv38E-km4bm6e56jaaJpo9veaezhfzmWZ", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
						"siteId": "650861b8-067b-49ff-9920-6bda877dda54",
						"uniqueClients": 5
					}, {
						"__typename": "ClientInsightRadios36TableType",
						"aesApMac": "0IxrnP6sm4YZHfSBtbQmwWt2u6VgFxDMXz75XR02N2jm",
						"aesApName": "aes:ZxqoMVaRlMLV9eYKeE3J81UaZrT4_ft7P_rIyA==",
						"apEntityId": "C9120AXE-B",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 15,
						"severity": "s2",
						"siteHierarchyArray": ["aes:moMlBCB9-yo9HRTppAfPhB53", "aes:WGepcqP5sd3GZxPRZ2AeIT-J7bIjzD0OjX0vBAk=", "aes:0-3wzWl_du5qQqfKix8C55mllGD6vH8DE9y1deoUvb0zzGanrl07H1aUnSlis5g=", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
						"siteId": "8d5f8b96-1f2e-4694-b740-4f42154ce0f6",
						"uniqueClients": 15
					}, {
						"__typename": "ClientInsightRadios36TableType",
						"aesApMac": "HB-TORjIgF4XluBsgKK-fMeVmWGTwi1qixzhYNFRv9bU",
						"aesApName": "aes:hjMy-M8a3XxcDqJ-5DjGmWk_V5HUiNje3V9cot0=",
						"apEntityId": "AIR-AP2802E-B-K9",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 472,
						"severity": "s0",
						"siteHierarchyArray": ["aes:moMlBCB9-yo9HRTppAfPhB53", "aes:WGepcqP5sd3GZxPRZ2AeIT-J7bIjzD0OjX0vBAk=", "aes:mnUBaVuNtxZiI-JLh-_ec8ue0w96lWEDj1AD-uR-o2tA_7rWWluOcsumFrYxFw==", "aes:fRfVrey0a08gGLqOLpQspJhZKYTDsLD1EWs2"],
						"siteId": "96157bba-db2e-4c02-b1b9-d2108876d4db",
						"uniqueClients": 539
					}, {
						"__typename": "ClientInsightRadios36TableType",
						"aesApMac": "IQgEmSjt_qQAsFgfCRKR00X8sMvJDyQp8F234FlhCPv4",
						"aesApName": "aes:5D9ElSFAyHB_3mWtMqpEXp1jtyyR82WqoBqyCdM=",
						"apEntityId": "AIR-AP2802E-B-K9",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 42,
						"severity": "s2",
						"siteHierarchyArray": ["aes:moMlBCB9-yo9HRTppAfPhB53", "aes:WGepcqP5sd3GZxPRZ2AeIT-J7bIjzD0OjX0vBAk=", "aes:mnUBaVuNtxZiI-JLh-_ec8ue0w96lWEDj1AD-uR-o2tA_7rWWluOcsumFrYxFw==", "aes:TdQmIPj3_jYa1zCY6B6BD7Dq-2AAHH9d"],
						"siteId": "96157bba-db2e-4c02-b1b9-d2108876d4db",
						"uniqueClients": 42
					}, {
						"__typename": "ClientInsightRadios36TableType",
						"aesApMac": "gcxrI0GuGLRBe1zesPBSZNH7oC4gW_Xn7dWC-3kvYuEG",
						"aesApName": "aes:4V2FIsNwSMbPUKyjFNKc4EyW1s3kLfYsuqi3-woKew==",
						"apEntityId": "AIR-AP1542I-S-K9",
						"errorFlags": ["speedDensity"],
						"frequencyBand": "5 GHz",
						"impactedClients": 12,
						"severity": "s2",
						"siteHierarchyArray": ["aes:S1DpNVvZ8zYO9k1YSicp7RjfnqM=", "aes:NvCkZqu6mqhETeG5alU4LZKKZ2Y8p8Ow", "aes:zY-NezjHfoz8kA2nAMO2S9xfhk_iAeOhd9NGi2X6LIT9ZIs36bN4QFysJItfGd-b3xurhA==", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
						"siteId": "8caadf37-1abf-45a5-b113-c5d9ca076493",
						"uniqueClients": 13
					}, {
						"__typename": "ClientInsightRadios36TableType",
						"aesApMac": "Xo_p0E7wqRp4qIXMAAVTLIc5ELo0e1S0UXsDcECgFcaw",
						"aesApName": "aes:JtgXPRWiBWbTrbUAaadNrpTbqHQ2WXX2oohRWeER",
						"apEntityId": "AIR-AP2802E-E-K9",
						"errorFlags": ["rssiKde", "snrKde", "speedDensity"],
						"frequencyBand": "5 GHz",
						"impactedClients": 48,
						"severity": "s1",
						"siteHierarchyArray": ["aes:xyO0HSf4wYs-ywtrqJiDGpLRFxU=", "aes:p7cPGanqsRLIovrp2f8ys6kME5M0Aw==", "aes:Vf6Ou5k3T1lasZkejQFfEiI7bsX4LPdqXzAgt1r6Obfd0tmYzapgndQ=", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
						"siteId": "e6e3f8d6-535f-4b84-87e6-9d595943173f",
						"uniqueClients": 55
					}, {
						"__typename": "ClientInsightRadios36TableType",
						"aesApMac": "NRU1Qw_iHEb_Cn82rM1owi8ZCmBcLMZxHtYKeYQSErsZ",
						"aesApName": "aes:Sn8gvaos4Xn1f1LtXyzK9-vHTfTaOaczm50k7gkn7w==",
						"apEntityId": "AIR-CAP2702E-E-K9",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 17,
						"severity": "s1",
						"siteHierarchyArray": ["aes:xyO0HSf4wYs-ywtrqJiDGpLRFxU=", "aes:efI5Z8muZ1JM8DW_PDA2gGsV7zRKLA==", "aes:2S4yXlK__tDxHUBwmADqV7WdaljmMiGbwss2HlfYu68Hxz8O5kI=", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
						"siteId": "e9e06d18-39af-49aa-a29d-a5f8a96429df",
						"uniqueClients": 20
					}, {
						"__typename": "ClientInsightRadios36TableType",
						"aesApMac": "vC6hW6AhP_JbMNeiElUyJJ5fVzTnqHx-WUqx1EjsSoub",
						"aesApName": "aes:OOdtcdZn1eO-EL5Eqdnelw7h9HnXNATyx3bcE4phkQ==",
						"apEntityId": "AIR-AP2802E-E-K9",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 26,
						"severity": "s1",
						"siteHierarchyArray": ["aes:xyO0HSf4wYs-ywtrqJiDGpLRFxU=", "aes:efI5Z8muZ1JM8DW_PDA2gGsV7zRKLA==", "aes:2S4yXlK__tDxHUBwmADqV7WdaljmMiGbwss2HlfYu68Hxz8O5kI=", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
						"siteId": "e9e06d18-39af-49aa-a29d-a5f8a96429df",
						"uniqueClients": 33
					}, {
						"__typename": "ClientInsightRadios36TableType",
						"aesApMac": "2-fx8um_SKghMuWuLm3MJJiQWF6ddi931lBAIUNS1cIj",
						"aesApName": "aes:K59nmAsNrC1SpnpbzkoFw0yh8cYddm4kbD9DIBU-k-M=",
						"apEntityId": "AIR-AP1562E-A-K9",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 105,
						"severity": "s2",
						"siteHierarchyArray": ["aes:moMlBCB9-yo9HRTppAfPhB53", "aes:NFYNVO6gpWudr7SfUvCG6y5MqZlUPA==", "aes:GunvDwGEl4oTu7D-IcrEqjdcXGXjjPMgCs2ES6TYY-7RKGG0D9VfCjL7L1YdyQWbPQ==", "aes:yLETh00wWEZGiA1Jz2Ef-DNUS2aZxX780KgJI6Sbzm76qJqKUw=="],
						"siteId": "9cebf26a-edb4-43b5-a9b6-cf1fa30af5e5",
						"uniqueClients": 152
					}, {
						"__typename": "ClientInsightRadios36TableType",
						"aesApMac": "7UQm1sKyTILt6jHmooRIKe8Qkx7Hm9Tdf1EySznLg08V",
						"aesApName": "aes:B65m82dS2BTbsEfECVWusupEUmlunEEEF-P374tX",
						"apEntityId": "AIR-AP1562E-H-K9",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 121,
						"severity": "s1",
						"siteHierarchyArray": ["aes:S1DpNVvZ8zYO9k1YSicp7RjfnqM=", "aes:E6kXjwL_-7hiGXQg1LH9v6-fkhBm", "aes:QO_yEw3_kRbUYRTDOEKcEIY94zaRFqQWv38E-km4bm6e56jaaJpo9veaezhfzmWZ", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
						"siteId": "650861b8-067b-49ff-9920-6bda877dda54",
						"uniqueClients": 170
					}, {
						"__typename": "ClientInsightRadios36TableType",
						"aesApMac": "wXumQNh3coxachsgNZ0FbPTuWxylPrOe7Ar-kzlTH4v0",
						"aesApName": "aes:djQ37fTJYaOv9nR8gmZEz0abUwqt226JBkB4DvAv",
						"apEntityId": "AIR-AP2802I-H-K9",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 5,
						"severity": "s2",
						"siteHierarchyArray": ["aes:S1DpNVvZ8zYO9k1YSicp7RjfnqM=", "aes:E6kXjwL_-7hiGXQg1LH9v6-fkhBm", "aes:QO_yEw3_kRbUYRTDOEKcEIY94zaRFqQWv38E-km4bm6e56jaaJpo9veaezhfzmWZ", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
						"siteId": "650861b8-067b-49ff-9920-6bda877dda54",
						"uniqueClients": 5
					}, {
						"__typename": "ClientInsightRadios36TableType",
						"aesApMac": "iMSI8i2G7dSOtVjdR21XbxEvU1MTxNtoew3kFRjrF3Km",
						"aesApName": "aes:dDEwe1x6cmEKuM9Jer1SN94Ae-EPVGTyEPYqiius",
						"apEntityId": "AIR-AP2802E-E-K9",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 25,
						"severity": "s2",
						"siteHierarchyArray": ["aes:xyO0HSf4wYs-ywtrqJiDGpLRFxU=", "aes:p7cPGanqsRLIovrp2f8ys6kME5M0Aw==", "aes:Vf6Ou5k3T1lasZkejQFfEiI7bsX4LPdqXzAgt1r6Obfd0tmYzapgndQ=", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
						"siteId": "e6e3f8d6-535f-4b84-87e6-9d595943173f",
						"uniqueClients": 28
					}, {
						"__typename": "ClientInsightRadios36TableType",
						"aesApMac": "W77b66filNaIR9YTQGpEVxsUGwfXsmetjoIWqJ3WsSGW",
						"aesApName": "aes:sB0i2zy4A1k92BQ2-XJf_00eLdHc_GxEjMn__2Jqkg==",
						"apEntityId": "AIR-AP2802I-S-K9",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 46,
						"severity": "s1",
						"siteHierarchyArray": ["aes:S1DpNVvZ8zYO9k1YSicp7RjfnqM=", "aes:NvCkZqu6mqhETeG5alU4LZKKZ2Y8p8Ow", "aes:zY-NezjHfoz8kA2nAMO2S9xfhk_iAeOhd9NGi2X6LIT9ZIs36bN4QFysJItfGd-b3xurhA==", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
						"siteId": "8caadf37-1abf-45a5-b113-c5d9ca076493",
						"uniqueClients": 68
					}, {
						"__typename": "ClientInsightRadios36TableType",
						"aesApMac": "0cnZuy8DwVH0EH7W7qQaULm-OWC3PjFEhr4RyRxKdH9M",
						"aesApName": "aes:bHNGYqt0u2BA-wBnQ_9QPBUfse3AxVA0f2sGlNCE",
						"apEntityId": "AIR-AP2802I-H-K9",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 83,
						"severity": "s1",
						"siteHierarchyArray": ["aes:S1DpNVvZ8zYO9k1YSicp7RjfnqM=", "aes:E6kXjwL_-7hiGXQg1LH9v6-fkhBm", "aes:QO_yEw3_kRbUYRTDOEKcEIY94zaRFqQWv38E-km4bm6e56jaaJpo9veaezhfzmWZ", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
						"siteId": "650861b8-067b-49ff-9920-6bda877dda54",
						"uniqueClients": 108
					}, {
						"__typename": "ClientInsightRadios36TableType",
						"aesApMac": "3b2LZcJinfqT_TqJs9DEUcMUCcnyE1CScL7H4lzSEAO6",
						"aesApName": "aes:IesdtXvGC_s0N_UXSkXAoQ2xLDpuE5BR6X0GdW-LOg==",
						"apEntityId": "AIR-AP2802I-S-K9",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 29,
						"severity": "s2",
						"siteHierarchyArray": ["aes:S1DpNVvZ8zYO9k1YSicp7RjfnqM=", "aes:NvCkZqu6mqhETeG5alU4LZKKZ2Y8p8Ow", "aes:zY-NezjHfoz8kA2nAMO2S9xfhk_iAeOhd9NGi2X6LIT9ZIs36bN4QFysJItfGd-b3xurhA==", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
						"siteId": "8caadf37-1abf-45a5-b113-c5d9ca076493",
						"uniqueClients": 52
					}, {
						"__typename": "ClientInsightRadios36TableType",
						"aesApMac": "BB0UD015S0H_wN5eXLKM4LA75VVasP0l3lG1l_EVz__2",
						"aesApName": "aes:GaFyVNLlAaUgOk4RbUhjaMFk4fYAHZYuCnMqqJ3GDW4=",
						"apEntityId": "AIR-AP1562E-H-K9",
						"errorFlags": ["speedDensity"],
						"frequencyBand": "5 GHz",
						"impactedClients": 9,
						"severity": "s2",
						"siteHierarchyArray": ["aes:S1DpNVvZ8zYO9k1YSicp7RjfnqM=", "aes:E6kXjwL_-7hiGXQg1LH9v6-fkhBm", "aes:QO_yEw3_kRbUYRTDOEKcEIY94zaRFqQWv38E-km4bm6e56jaaJpo9veaezhfzmWZ", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
						"siteId": "650861b8-067b-49ff-9920-6bda877dda54",
						"uniqueClients": 11
					}, {
						"__typename": "ClientInsightRadios36TableType",
						"aesApMac": "c2yu7DhaKBVTOYlgjgK_ZFZr_gm9p12t0m2HyCDkP5sQ",
						"aesApName": "aes:F9WpY3z2Ln3corGPOnHjyU2BWYGXVhVzUhcyamN6",
						"apEntityId": "AIR-AP2802I-E-K9",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 14,
						"severity": "s2",
						"siteHierarchyArray": ["aes:xyO0HSf4wYs-ywtrqJiDGpLRFxU=", "aes:6gLutd8MMm4r5HjcmNnumquvqyzxOgBxpWcM", "aes:CoaTsAx4onBHYikkCZMAvSgocYw_kD5i8S1H2jMbtSUb4rkgQc4stTtNozc6jHE5suuBDQ==", "aes:3WyvP07nWcRi7CiV7joUuigSGCE6KEqXat7qHLswVfi_r64V0M1rRw=="],
						"siteId": "07f44199-f1de-43c5-a678-e2e415b026e9",
						"uniqueClients": 15
					}, {
						"__typename": "ClientInsightRadios36TableType",
						"aesApMac": "ZLFM1xDQQq8tAGeWXEMu8sdPiCxG6giWhoxR2yrd65ws",
						"aesApName": "aes:rEFKq7jaubPtWZqg4nhst2hTiE6wv6eD_SuEJC0T",
						"apEntityId": "AIR-CAP3702I-H-K9",
						"errorFlags": ["rssiKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 7,
						"severity": "s2",
						"siteHierarchyArray": ["aes:S1DpNVvZ8zYO9k1YSicp7RjfnqM=", "aes:E6kXjwL_-7hiGXQg1LH9v6-fkhBm", "aes:JXzFOblEgfZS0sdwO4nr-pKeTr-eT16N1b-_23Qm_asUrbtuTAlmK0cSs5MTSh9-jPZYpxG7e5w=", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
						"siteId": "0f6be972-8778-4e77-9d3f-b9926255adfe",
						"uniqueClients": 7
					}, {
						"__typename": "ClientInsightRadios36TableType",
						"aesApMac": "l0aJB54eufe_CArqrbK-0n-X__phBO2V0vpPyUw_j7Yr",
						"aesApName": "aes:TQS_d0PT4LlQx4kl0ZbZAlPhXbnhktdrfOYbo48=",
						"apEntityId": "AIR-AP2802E-A-K9",
						"errorFlags": ["snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 38,
						"severity": "s2",
						"siteHierarchyArray": ["aes:moMlBCB9-yo9HRTppAfPhB53", "aes:NFYNVO6gpWudr7SfUvCG6y5MqZlUPA==", "aes:2ds9IlaEDQMvPTo42V9hmoAakMR-LKG6q1g1Vm9KQg0Gb9LgigmFX_pt46LHOL4=", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
						"siteId": "74688dd5-3379-4d18-a997-4320f0aa9fd8",
						"uniqueClients": 48
					}, {
						"__typename": "ClientInsightRadios36TableType",
						"aesApMac": "762I2D_bkQ2P-1kMybYDQ4F2dxWkJj3EksVtAocBZ1z8",
						"aesApName": "aes:6s3jmXjQsbZXjb-y-s1n--1reWQ2O450P0R86hU=",
						"apEntityId": "AIR-CAP1602E-A-K9",
						"errorFlags": ["rssiKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 79,
						"severity": "s2",
						"siteHierarchyArray": ["aes:moMlBCB9-yo9HRTppAfPhB53", "aes:NFYNVO6gpWudr7SfUvCG6y5MqZlUPA==", "aes:2ds9IlaEDQMvPTo42V9hmoAakMR-LKG6q1g1Vm9KQg0Gb9LgigmFX_pt46LHOL4=", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
						"siteId": "74688dd5-3379-4d18-a997-4320f0aa9fd8",
						"uniqueClients": 102
					}, {
						"__typename": "ClientInsightRadios36TableType",
						"aesApMac": "3jUmxuO7nOS3Tv60db-3ZPtgEvumZ6hL3ZyFUymi4_fY",
						"aesApName": "aes:zshJ7sd3yXvzZGTfoxivu8fg7oI-MQ7oJh8wacE=",
						"apEntityId": "AIR-AP2802E-A-K9",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 95,
						"severity": "s0",
						"siteHierarchyArray": ["aes:moMlBCB9-yo9HRTppAfPhB53", "aes:NFYNVO6gpWudr7SfUvCG6y5MqZlUPA==", "aes:2ds9IlaEDQMvPTo42V9hmoAakMR-LKG6q1g1Vm9KQg0Gb9LgigmFX_pt46LHOL4=", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
						"siteId": "74688dd5-3379-4d18-a997-4320f0aa9fd8",
						"uniqueClients": 126
					}],
					"totalCount": 30
				}
			}
		},
	"getWLCFirstTimeStampTemplate":
		{
			"data": { "allWlc36S": { "__typename": "Wlc36SConnection", "nodes": [{ "__typename": "Wlc36", "timestamp": "2020-12-21T00:00:00" }] } }
		},
	"getLowDensityExternalIntereferenceTemplate":
		{
			"data": {
				"getRootcauseClientInsightSummary36": {
					"__typename": "GetRootcauseClientInsightSummary36Connection",
					"nodes": [{
						"__typename": "ClientInsightSummary36RcType",
						"endTimestamp": "2022-04-13T00:00:00",
						"frequencyBand": "5 GHz",
						"impactedAps": 64,
						"impactedBuildings": 30,
						"impactedClients": 3133,
						"rootCauseCategory": "Low density",
						"rootCauseCategoryId": "a70b30fe3321351b19dd",
						"startTimestamp": "2022-03-16T00:00:00",
						"timestamp": "2022-04-13T01:19:22.420202",
						"topImpactedApMacs": ["b1qY01YgoSktJwkiFoKZEqycmDha8qZhJYYjEBjSi1KL", "3jUmxuO7nOS3Tv60db-3ZPtgEvumZ6hL3ZyFUymi4_fY", "PEs2VIXxb0q63p8liTPpadsvJPOoJpFdU9uAI-TxZkoj"],
						"topImpactedAps": ["aes:FHCdStam4rfkX8RuIUPsYFQr8L-QRBsIFIJ25ifg2So=", "aes:zshJ7sd3yXvzZGTfoxivu8fg7oI-MQ7oJh8wacE=", "aes:07VHSzvQr9-wi4n9D4VienXEHqehQBb4zYUaOazcD1q6"]
					}],
					"totalCount": 1
				}
			}
		},
	"getExternalRFLoadTemplate":
		{
			"data": {
				"getRootcauseClientInsightSummary36": {
					"__typename": "GetRootcauseClientInsightSummary36Connection",
					"nodes": [{
						"__typename": "ClientInsightSummary36RcType",
						"endTimestamp": "2021-11-17T00:00:00",
						"frequencyBand": "2.4 GHz",
						"impactedAps": 9,
						"impactedBuildings": 6,
						"impactedClients": 184,
						"rootCauseCategory": "External RF load",
						"rootCauseCategoryId": "a72edc5da049e7f555a3",
						"startTimestamp": "2021-10-20T00:00:00",
						"timestamp": "2021-11-17T02:08:10.717983",
						"topImpactedApMacs": ["a0:f8:49:7e:c0:60', 'a0:f8:49:7e:c0:61', 'a0:f8:49:7e:c0:62"],
						"topImpactedAps": ["SFO15-C9120-03', 'SFO15-C9124-01', 'SFO15-C9124-02"]
					}],
					"totalCount": 1
				}
			}
		},
	"getLowDensityTemplate":
		{
			"data": {
				"getRootcauseClientInsightSummary36": {
					"__typename": "GetRootcauseClientInsightSummary36Connection",
					"nodes": [{
						"__typename": "ClientInsightSummary36RcType",
						"endTimestamp": "2021-12-01T00:00:00",
						"frequencyBand": "5 GHz",
						"impactedAps": 18,
						"impactedBuildings": 9,
						"impactedClients": 1191,
						"rootCauseCategory": "Low density",
						"rootCauseCategoryId": "a70b30fe3321351b19dd",
						"startTimestamp": "2021-11-10T12:00:00",
						"timestamp": "2021-12-01T00:13:54.717113",
						"topImpactedApMacs": ["a0:f8:49:7e:c0:78', 'a0:f8:49:7e:c0:79', 'a0:f8:49:7e:c0:70"],
						"topImpactedAps": ["AP9124_2', 'AP9136_1', 'AP9105_2"]
					}],
					"totalCount": 1
				}
			}
		},
	"getHighClientActivityTemplate":
		{
			"data": {
				"getRootcauseClientInsightSummary36": {
					"__typename": "GetRootcauseClientInsightSummary36Connection",
					"nodes": [{
						"__typename": "ClientInsightSummary36RcType",
						"endTimestamp": "2021-10-27T00:00:00",
						"frequencyBand": "2.4 GHz",
						"impactedAps": 3,
						"impactedBuildings": 2,
						"impactedClients": 116,
						"rootCauseCategory": "High client activity",
						"rootCauseCategoryId": "a71d771533202605c099",
						"startTimestamp": "2021-09-29T00:00:00",
						"timestamp": "2021-10-27T02:14:56.929441",
						"topImpactedApMacs": ["a0:f8:49:7e:c0:78', 'a0:f8:49:7e:c0:79', 'a0:f8:49:7e:c0:70"],
						"topImpactedAps": ["AP9124_2', 'AP9136_1', 'AP9105_2"]
					}],
					"totalCount": 1
				}
			}
		},
	"getHighDensityTemplate":
		{
			"data": {
				"getRootcauseClientInsightSummary36": {
					"__typename": "GetRootcauseClientInsightSummary36Connection",
					"nodes": [{
						"__typename": "ClientInsightSummary36RcType",
						"endTimestamp": "2022-04-27T00:00:00",
						"frequencyBand": "2.4 GHz",
						"impactedAps": 2,
						"impactedBuildings": 2,
						"impactedClients": 54,
						"rootCauseCategory": "High density",
						"rootCauseCategoryId": "a7960cbabf5293d7cbe6",
						"startTimestamp": "2022-03-30T00:00:00",
						"timestamp": "2022-04-27T01:03:00.636313",
						"topImpactedApMacs": ["a0:f8:49:7e:c0:71', 'a0:f8:49:7e:c0:72"],
						"topImpactedAps": ["SCJ01_9136_2', 'SCJ01_9136_3"]
					}],
					"totalCount": 1
				}
			}
		},

	"getLowDensity5GHzTemplate":
		{
			"data": {
				"getClientInsightAgg36Analysis": {
					"__typename": "GetClientInsightAgg36AnalysisConnection",
					"nodes": [{
						"__typename": "ClientInsightAgg36Type",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 81131,
							"key": 0
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 2541,
							"key": 5
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 231,
							"key": 2
						}],
						"apModel": [{
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP1532E-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP1562D-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 6,
							"key": "AIR-AP1562E-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 11,
							"key": "AIR-AP2802E-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 16,
							"key": "AIR-AP1542I-S-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP2802I-H-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 3,
							"key": "AIR-AP2802I-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-AP2802I-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP2602I-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP2702I-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-AP2802I-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 12,
							"key": "AIR-CAP1532I-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP2802I-Z-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 3,
							"key": "C9120AXE-B"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-AP1562E-Z-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 8,
							"key": "AIR-AP1562E-H-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 12,
							"key": "AIR-AP2802E-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 6,
							"key": "AIR-CAP1602E-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP2602I-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 4,
							"key": "AIR-CAP2702E-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP2802I-S-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 4,
							"key": "AIR-AP1542I-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 6,
							"key": "AIR-CAP1532I-S-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 4,
							"key": "AIR-AP1542D-H-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP2702E-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 10,
							"key": "AIR-AP2802E-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP2802I-F-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 7,
							"key": "AIR-AP1542I-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-CAP2702E-B-K9"
						}],
						"clientCountBinSize": 1,
						"clientCountKde": [0.287384951250208, 0.27662688438024, 0.179822328466789, 0.0971941596520127, 0.0527988330612993, 0.0317851242102751, 0.0212969536927228, 0.0149147441722933, 0.010832202888789, 0.00802432703605506, 0.00571708458106822, 0.00382109450135135, 0.00264167706381115, 0.00214088762447224, 0.00197024887555829, 0.00173931461871589],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.230934853679091, 0.0843661814911944, 0.186431297218893, 0.0762785567687945, 0.138884677658713, 0.107692790206919, 0.0354643069258708, 0.0172523997032373, 0.0237228363441407, 0.0311688283883974, 0.0286066426150914, 0.00752939196200947, 0.00380712709589326, 0.0110513963553113, 0.00283232935157113, 0.00640648979205515, 0.00154781555902328, 0.00233817762413302, 0.00222916181496736, 0],
						"frequencyBand": "5 GHz",
						"impactedAps": 130,
						"interferenceBinSize": 1,
						"interferenceKde": [0.398398452704894, 0.243300236849477, 0.134578546156912, 0.0587313540060033, 0.0283247488358528, 0.0268755146512549, 0.0190940694601815, 0.0140811217055809, 0.00884230279865593, 0.00537212338504968, 0.00467531258214854, 0.00384028074865358, 0.00249990074199314, 0.00128674263256643, 0.00217421900335169, 0.002112683859634, 0.00281882097066454, 0.00186881438179951, 0.00114387210681523, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00208500793620038, 0.00195299434247918, 0.00358148233046033, 0.00553592645134017, 0.00955658748633608, 0.00783751162536297, 0.00134780443269279, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00160834512042555, 0.00477226819738749],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 74075,
							"key": 0
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 9828,
							"key": 1
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 9828,
							"key": 0
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 74075,
							"key": 1
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00298599507951486, 0.0440879303598128, 0.168722045853799, 0.0228527051778406, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0218177059161526, 0.161192379676434, 0.0823251926238097, 0.0350556048388775, 0.0240147804967173, 0.0198184443451154, 0.022362654286293, 0.0100194634702128, 0.0100055393395987, 0.0234791310021677, 0.0178470424341455, 0.0175932996429569, 0.0198141196137687, 0.00593511434042147, 0.00163659462291937, 0, 0.00115847298481225, 0, 0, 0.000992496492762465, 0.0069315791461873, 0.0439329858020215, 0.00742821335765145, 0.0169943177344038, 0.118216306175778, 0.0236654918405981, 0.0069296628103781, 0.03369851087251, 0.0053015718549448, 0, 0, 0.00140550496724856, 0.00100191441634754, 0.00118927741365969, 0.00215521444576639, 0, 0, 0, 0, 0, 0.00150838597130402, 0.0115292024580258, 0.00439514813504254],
						"powerAnomaly": "high",
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0.00818685951573429, 0.0197358785185987, 0.024182957368839, 0.0305373196065539, 0.0224593424474279, 0.0147395279835546, 0.0216999303719818, 0.0440113344748877, 0.0860795329071119, 0.0787968400699168, 0.0759070078056395, 0.0947110329733571, 0.0855019264566933, 0.0714831559660201, 0.136052338745043, 0.18591501478864],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 7837,
							"key": 6
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 76066,
							"key": 4
						}],
						"primaryImageVersion": [{
							"__typename": "CiStrKeyCountValue",
							"count": 14,
							"key": "8.10.151.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "8.5.135.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 7,
							"key": "8.5.171.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "8.5.151.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 106,
							"key": "8.5.161.6"
						}],
						"rootCauseCategory": "Low density",
						"rootCauseKpis": ["powerKde"],
						"trafficBinSize": 1,
						"trafficKde": [0.59204101184159, 0.278188840175737, 0.0605391547471048, 0.0397740798189746, 0.0209186326647991, 0.00542309693698079, 0.00189789931666364],
						"utilizationBinSize": 1,
						"utilizationKde": [0.305398225407275, 0.257551241561352, 0.144385302436451, 0.0846923777468445, 0.0428553120437022, 0.0276634707254809, 0.0136292563793887, 0.00955295755817226, 0.0168379111196969, 0.0159254353397507, 0.0144157850489639, 0.00717057353327394, 0.00411197608727841, 0.00188534638885732, 0.00221970756746845, 0.00210610820551265, 0.00293377949455795, 0.00220414772029575, 0.00127801769121991, 0, 0.0012310490957387, 0, 0, 0, 0, 0.0011380617682177, 0, 0, 0.00224671484953899, 0.00238201780993018, 0.00360247067993583, 0.00549484900051404, 0.0096133802038064, 0.00809640638978988, 0.00137729667142942, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00159193234937208, 0.00472240609262499]
					}, {
						"__typename": "ClientInsightAgg36Type",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 81131,
							"key": 0
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 2541,
							"key": 5
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 231,
							"key": 2
						}],
						"apModel": [{
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP1532E-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP1562D-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 6,
							"key": "AIR-AP1562E-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 11,
							"key": "AIR-AP2802E-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 16,
							"key": "AIR-AP1542I-S-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP2802I-H-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 3,
							"key": "AIR-AP2802I-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-AP2802I-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP2602I-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP2702I-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-AP2802I-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 12,
							"key": "AIR-CAP1532I-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP2802I-Z-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 3,
							"key": "C9120AXE-B"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-AP1562E-Z-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 8,
							"key": "AIR-AP1562E-H-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 12,
							"key": "AIR-AP2802E-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 6,
							"key": "AIR-CAP1602E-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP2602I-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 4,
							"key": "AIR-CAP2702E-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP2802I-S-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 4,
							"key": "AIR-AP1542I-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 6,
							"key": "AIR-CAP1532I-S-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 4,
							"key": "AIR-AP1542D-H-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP2702E-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 10,
							"key": "AIR-AP2802E-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP2802I-F-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 7,
							"key": "AIR-AP1542I-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-CAP2702E-B-K9"
						}],
						"clientCountBinSize": 1,
						"clientCountKde": [0.287384951250208, 0.27662688438024, 0.179822328466789, 0.0971941596520127, 0.0527988330612993, 0.0317851242102751, 0.0212969536927228, 0.0149147441722933, 0.010832202888789, 0.00802432703605506, 0.00571708458106822, 0.00382109450135135, 0.00264167706381115, 0.00214088762447224, 0.00197024887555829, 0.00173931461871589],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.230934853679091, 0.0843661814911944, 0.186431297218893, 0.0762785567687945, 0.138884677658713, 0.107692790206919, 0.0354643069258708, 0.0172523997032373, 0.0237228363441407, 0.0311688283883974, 0.0286066426150914, 0.00752939196200947, 0.00380712709589326, 0.0110513963553113, 0.00283232935157113, 0.00640648979205515, 0.00154781555902328, 0.00233817762413302, 0.00222916181496736, 0],
						"frequencyBand": "5 GHz",
						"impactedAps": 130,
						"interferenceBinSize": 1,
						"interferenceKde": [0.398398452704894, 0.243300236849477, 0.134578546156912, 0.0587313540060033, 0.0283247488358528, 0.0268755146512549, 0.0190940694601815, 0.0140811217055809, 0.00884230279865593, 0.00537212338504968, 0.00467531258214854, 0.00384028074865358, 0.00249990074199314, 0.00128674263256643, 0.00217421900335169, 0.002112683859634, 0.00281882097066454, 0.00186881438179951, 0.00114387210681523, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00208500793620038, 0.00195299434247918, 0.00358148233046033, 0.00553592645134017, 0.00955658748633608, 0.00783751162536297, 0.00134780443269279, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00160834512042555, 0.00477226819738749],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 74075,
							"key": 0
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 9828,
							"key": 1
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 9828,
							"key": 0
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 74075,
							"key": 1
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00298599507951486, 0.0440879303598128, 0.168722045853799, 0.0228527051778406, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0218177059161526, 0.161192379676434, 0.0823251926238097, 0.0350556048388775, 0.0240147804967173, 0.0198184443451154, 0.022362654286293, 0.0100194634702128, 0.0100055393395987, 0.0234791310021677, 0.0178470424341455, 0.0175932996429569, 0.0198141196137687, 0.00593511434042147, 0.00163659462291937, 0, 0.00115847298481225, 0, 0, 0.000992496492762465, 0.0069315791461873, 0.0439329858020215, 0.00742821335765145, 0.0169943177344038, 0.118216306175778, 0.0236654918405981, 0.0069296628103781, 0.03369851087251, 0.0053015718549448, 0, 0, 0.00140550496724856, 0.00100191441634754, 0.00118927741365969, 0.00215521444576639, 0, 0, 0, 0, 0, 0.00150838597130402, 0.0115292024580258, 0.00439514813504254],
						"powerAnomaly": "high",
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0.00818685951573429, 0.0197358785185987, 0.024182957368839, 0.0305373196065539, 0.0224593424474279, 0.0147395279835546, 0.0216999303719818, 0.0440113344748877, 0.0860795329071119, 0.0787968400699168, 0.0759070078056395, 0.0947110329733571, 0.0855019264566933, 0.0714831559660201, 0.136052338745043, 0.18591501478864],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 7837,
							"key": 6
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 76066,
							"key": 4
						}],
						"primaryImageVersion": [{
							"__typename": "CiStrKeyCountValue",
							"count": 14,
							"key": "8.10.151.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "8.5.135.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 7,
							"key": "8.5.171.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "8.5.151.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 106,
							"key": "8.5.161.6"
						}],
						"rootCauseCategory": "Low density",
						"rootCauseKpis": ["powerKde"],
						"trafficBinSize": 1,
						"trafficKde": [0.59204101184159, 0.278188840175737, 0.0605391547471048, 0.0397740798189746, 0.0209186326647991, 0.00542309693698079, 0.00189789931666364],
						"utilizationBinSize": 1,
						"utilizationKde": [0.305398225407275, 0.257551241561352, 0.144385302436451, 0.0846923777468445, 0.0428553120437022, 0.0276634707254809, 0.0136292563793887, 0.00955295755817226, 0.0168379111196969, 0.0159254353397507, 0.0144157850489639, 0.00717057353327394, 0.00411197608727841, 0.00188534638885732, 0.00221970756746845, 0.00210610820551265, 0.00293377949455795, 0.00220414772029575, 0.00127801769121991, 0, 0.0012310490957387, 0, 0, 0, 0, 0.0011380617682177, 0, 0, 0.00224671484953899, 0.00238201780993018, 0.00360247067993583, 0.00549484900051404, 0.0096133802038064, 0.00809640638978988, 0.00137729667142942, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00159193234937208, 0.00472240609262499]
					}, {
						"__typename": "ClientInsightAgg36Type",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 670,
							"key": 2
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 118270,
							"key": 0
						}],
						"apModel": [{
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP2702E-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP2802E-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 7,
							"key": "AIR-AP2802E-S-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP2802I-Q-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 4,
							"key": "AIR-AP2802I-K-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP2802E-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 11,
							"key": "AIR-AP3802I-H-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 17,
							"key": "AIR-AP2802I-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 50,
							"key": "AIR-AP3802I-Z-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 7,
							"key": "AIR-AP2802I-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-AP1542I-S-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP3802I-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 3,
							"key": "AIR-AP3802I-R-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 4,
							"key": "AIR-CAP2702I-H-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 20,
							"key": "AIR-AP3802I-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 17,
							"key": "AIR-AP2802I-H-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP2702E-H-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP2702E-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP1562D-F-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 3,
							"key": "AIR-AP3802I-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-AP2802E-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 23,
							"key": "AIR-AP2802I-S-K9"
						}],
						"clientCountBinSize": 0.894736842105263,
						"clientCountKde": [0.356645390406221, 0.302677156812721, 0.168198527841487, 0.0729716719248931, 0.0332613593083453, 0.0192343275677753, 0.0131650565397624, 0.00962040773056726, 0.00703121794172976, 0.00511937418122007, 0.00376599774231725, 0.00280260423586222, 0.00221410821920914, 0.00181153139119478],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.399332156286599, 0.058418210221798, 0.00633684201616897, 0.0185712519888698, 0.129319324057758, 0.177618504144793, 0.0241573219740098, 0.0033309239836268, 0.0253188547464493, 0.044517649972121, 0.0555527446471323, 0.00691332092650571, 0.00253491607423229, 0.0186532658650897, 0.00413637253469704, 0.0113310856093521, 0.0018139842171005, 0.00259999883042206, 0.00410949632179553, 0, 0.00291348376337629, 0, 0.00141624816369874, 0],
						"frequencyBand": "5 GHz",
						"impactedAps": null,
						"interferenceBinSize": 1,
						"interferenceKde": [0.203186050189624, 0.254133515343025, 0.206590412610278, 0.125047321269707, 0.0693635204130969, 0.0333002596497028, 0.0234196690055669, 0.0154600693489375, 0.0114981837510117, 0.00810980050820639, 0.00632567211672376, 0.00549632145231574, 0.00533497093802213, 0.0047336623174802, 0.0037294409396768, 0.00285508616162909, 0.00366619904349262, 0.00341568877665086, 0.0028203536500099, 0.00179482975551432, 0.00221193458339873, 0.00167947162536191, 0.00177315413904084, 0.00136793933215454, 0.00161070830110703, 0],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 4685,
							"key": 1
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 114255,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 114255,
							"key": 1
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 4685,
							"key": 0
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00174378269840691, 0.00372321502664868, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0658840456065962, 0.47599588856116, 0.296467673392041, 0.0865544827812884, 0.0251710463465655, 0.00884743075941589, 0.0064941459201588, 0.00323928241966618, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00275629479847285, 0.0203664168911065, 0.00275629479847284],
						"powerAnomaly": null,
						"powerBinSize": 1,
						"powerKde": [0.00667337519006774, 0.142090227081208, 0.401964404340008, 0.169414792840475, 0.0364415076784804, 0.0430621919256677, 0.0183132800490819, 0.0100562423127621, 0.0163042631423158, 0.00571942675272907, 0.00413348877739269, 0.0114404354901839, 0.00665212160090164, 0.00551949457268952, 0.00785827121057525, 0.00481884406826162, 0.017097418161903, 0.0359866575461318, 0.0144621878762888, 0.00712716267644512, 0.00523176794899786, 0.00461496154046245, 0.0099013501034248, 0.0151161271135456],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 118940,
							"key": 4
						}],
						"primaryImageVersion": [{
							"__typename": "CiStrKeyCountValue",
							"count": 170,
							"key": "8.5.161.6"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 3,
							"key": "8.5.151.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 3,
							"key": "8.5.171.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "8.5.135.0"
						}],
						"rootCauseCategory": "BASELINE",
						"rootCauseKpis": null,
						"trafficBinSize": 1,
						"trafficKde": [0.746285156799011, 0.221222832428139, 0.01878922996958, 0.00585921695897017, 0.00361778561155007, 0.00286874536830771],
						"utilizationBinSize": 1,
						"utilizationKde": [0.194980953010916, 0.252842559965792, 0.205407030730448, 0.126881690043725, 0.0711953593625115, 0.0338779505382143, 0.0237509879177382, 0.0166686331058894, 0.0109229517889478, 0.00833621201889729, 0.00696777346922408, 0.00594239043970388, 0.0063073737697712, 0.0051921044454944, 0.00437269564633769, 0.0031459592452173, 0.0034951967998307, 0.00369882689836632, 0.00333606287025686, 0.00176753757969134, 0.00218121781367594, 0.00202406016548307, 0.00141858596097868, 0.00129306039585393, 0.00151534615959329, 0.00141141143922658, 0]
					}],
					"totalCount": 3
				}
			}
		},
	"getExternalRFLoad24GHzTemplate":
		{
			"data": {
				"getClientInsightAgg36Analysis": {
					"__typename": "GetClientInsightAgg36AnalysisConnection",
					"nodes": [{
						"__typename": "ClientInsightAgg36Type",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 19120,
							"key": 0
						}],
						"apModel": [{
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP2702E-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 13,
							"key": "AIR-CAP1532I-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP1602E-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-AP2802I-Q-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP2702E-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP2802I-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 3,
							"key": "AIR-AP3802I-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 3,
							"key": "AIR-AP3802I-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-AP2802I-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-AP2802I-F-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP1562E-B-K9"
						}],
						"clientCountBinSize": 0.736842105263158,
						"clientCountKde": [0.237922494996265, 0.22683724699117, 0.167977116498474, 0.107892904777917, 0.0694196500841788, 0.048876742987343, 0.0370495155490089, 0.0287305175812349, 0.021914352387926, 0.0161379223804837, 0.0115161839520492, 0.00822138010146151, 0.0060168738029257, 0.00446463877141526, 0.00325834111180904, 0.00226749183546666],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.186776191322019, 0.0446598549062132, 0.0318541043149844, 0.0344182232680679, 0.090316670326278, 0.134966812509319, 0.0740204872079551, 0.0694507548570876, 0.0874977128517835, 0.0846270559250542, 0.0737142508675963, 0.0329214326923408, 0.0190418484773577, 0.0164302940454891, 0.00627867596455594, 0.00699404196447744, 0.00198313638273455, 0.00146137038562568, 0.00135804116914729, 0],
						"frequencyBand": "2.4 GHz",
						"impactedAps": 30,
						"interferenceBinSize": 1,
						"interferenceKde": [0, 0, 0, 0, 0, 0, 0.00128518583308297, 0.00204183376800793, 0, 0, 0, 0.00134697908839437, 0.00169351499497272, 0.00185536368684826, 0.00234083993660469, 0.00426189288428786, 0.00388466993906271, 0.00304439560096226, 0.00402234448053708, 0.00417915187493271, 0.00570260299101636, 0.00592905373708875, 0.00595359108412592, 0.00574634338438902, 0.00850682195709002, 0.0079822745308032, 0.00933278766829976, 0.00917846083897441, 0.00964639969662088, 0.0104314510565243, 0.00990369493351979, 0.0113219446075877, 0.012229455171509, 0.0128853584166929, 0.0117770627714548, 0.0118606531503443, 0.0119088633667086, 0.0149400326213379, 0.0152076949339597, 0.0144628575004935, 0.0139753205422519, 0.0146064056543184, 0.0138591036394074, 0.0140475991225429, 0.0143497374598644, 0.0144333116524486, 0.0119486150188005, 0.0127412714719555, 0.0132005829507183, 0.0146203378224061, 0.0149309362261038, 0.0170297962001383, 0.0154840826990164, 0.0150754736940256, 0.0152055214806138, 0.0126283808487032, 0.0125368170678403, 0.012238364464006, 0.0136611705681013, 0.013609655342208, 0.0153581431115136, 0.019857601642017, 0.0189313803271386, 0.0218520468305482, 0.0233250877254566, 0.0219346492666964, 0.0188482218620751, 0.019315897577033, 0.0172907498071369, 0.0145827027890383, 0.0128880455411551, 0.0122542578867534, 0.0108292083473144, 0.00994500454420936, 0.0104375999049532, 0.00894141254585932, 0.00877657878825667, 0.00895123264861981, 0.0108363642491985, 0.011318535535524, 0.0167395097648024, 0.018158976610113, 0.0175163041416827, 0.0248495236942306, 0.0229644170031202, 0.0246904852612027, 0.0175390019423491, 0.0163293594153709, 0.0101096450445019, 0.00620241272593383, 0.0054161448264361, 0.00274365897131117, 0.00232886422516168, 0.00128970994070007],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 17549,
							"key": 0
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 1571,
							"key": 1
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 1571,
							"key": 0
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 17549,
							"key": 1
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0242849662799419, 0.190257245737427, 0.12086419996267, 0.0439943651300812, 0.0353529345300329, 0.0318297958271548, 0.00994002639532246, 0.00354745616397319, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00526531106037375, 0.0389056845849013, 0.00543470233168822, 0.048416244573076, 0.357548008893651, 0.0489088498050977, 0.00393279879807561, 0.0277604638305024, 0.00375694609603076],
						"powerAnomaly": "",
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0.00169946391017766, 0.0206304908035498, 0.065983142577604, 0.0767439283778834, 0.118791030446887, 0.0971042349929204, 0.0293648047108627, 0.0235112968749158, 0.0503390552992162, 0.0288650552937997, 0.0271531642636277, 0.0745124147321329, 0.0683589679679897, 0.0261135139366752, 0.0231201130584991, 0.0284779965198385, 0.0966912165427364, 0.142540109690684],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 8509,
							"key": 6
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 10611,
							"key": 4
						}],
						"primaryImageVersion": [{
							"__typename": "CiStrKeyCountValue",
							"count": 30,
							"key": "8.5.161.6"
						}],
						"rootCauseCategory": "External RF load",
						"rootCauseKpis": ["interferenceKde", "utilizationKde"],
						"trafficBinSize": 1,
						"trafficKde": [0.476315207490176, 0.274216465026789, 0.0471550524682735, 0.00350241771905171, 0.00239636249553503, 0.00592007020311236, 0.016058330991211, 0.011258715791192, 0.0146003619749092, 0.0148711235279173, 0.0110445494189655, 0.0110691351791827, 0.0184046790777065, 0.0101075006637967, 0.00585457098168236, 0.00607924616216071, 0.0123638040741202, 0.0248490040795488, 0.0238746066264659, 0.00694491638995281, 0.00311387965825003],
						"utilizationBinSize": 1,
						"utilizationKde": [0, 0, 0, 0, 0, 0, 0.00129897185108637, 0.00201380787409468, 0, 0, 0, 0, 0.00169002260357656, 0.00113479230990194, 0.0010319872035356, 0.00176971392913336, 0.00203333786401228, 0.00177747714214659, 0.00184515855017652, 0.00147178329696934, 0.0036538589420914, 0.00332392465633664, 0.00379570495049847, 0.0042795986785426, 0.00465793352951583, 0.00670230539235465, 0.00487494756103329, 0.00674831137485792, 0.00523423688966387, 0.00646841693048209, 0.0057698874700486, 0.00673350009707593, 0.00875541548106455, 0.00890793675218648, 0.0101055410560903, 0.00894158811279342, 0.0110305963946603, 0.0121267675915553, 0.0153720375933293, 0.0137842348673525, 0.0161020203272919, 0.017803652499092, 0.0142797283920032, 0.0174768011632564, 0.0177568522003463, 0.0164247842485581, 0.0138276641572439, 0.0160967350046276, 0.0151290711422183, 0.0156749456073924, 0.0167368241556728, 0.0169366616581523, 0.0159610424086527, 0.014865223341297, 0.0170134931891661, 0.0146363936104843, 0.0147524865812348, 0.0148026506132739, 0.0158772637237489, 0.0138498764135478, 0.0165442596926347, 0.0205445810144194, 0.0164886763467926, 0.0181813869740594, 0.0191421730378082, 0.0207890278013705, 0.0175142677169384, 0.0175492490437957, 0.0174391819558653, 0.0154358786570544, 0.0149661882056354, 0.0125518872103982, 0.0145054425400531, 0.0134603983336881, 0.0158605589520902, 0.0111203783680993, 0.0143921879297689, 0.0117901450310202, 0.0144169546617611, 0.0108561360927197, 0.0204380316290529, 0.0192656309564605, 0.0182887330182163, 0.0261549448539715, 0.0217709804431536, 0.0258172647746285, 0.0194264685332567, 0.017926555710515, 0.0107669080589032, 0.00759816988374949, 0.00642854588920017, 0.00305688875247582, 0.00305409502062513, 0.00131179645669221]
					}, {
						"__typename": "ClientInsightAgg36Type",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 67984,
							"key": 0
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 1340,
							"key": 2
						}],
						"apModel": [{
							"__typename": "CiStrKeyCountValue",
							"count": 15,
							"key": "AIR-AP2802E-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 8,
							"key": "AIR-AP2802I-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 6,
							"key": "AIR-CAP2702E-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-AP2802I-H-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-AP2802E-S-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-CAP2602I-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 14,
							"key": "AIR-AP3802I-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP1562E-H-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP1562E-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP1562E-F-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP1562I-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP1702I-N-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-CAP2702I-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 3,
							"key": "AIR-AP2802E-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-CAP1532I-S-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 9,
							"key": "AIR-AP1562D-F-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 5,
							"key": "AIR-AP3802I-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP2702I-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-AP2802I-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 6,
							"key": "AIR-AP1542I-S-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-AP2802I-S-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP1602I-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-CAP1602E-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP2702I-N-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "C9120AXE-B"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 7,
							"key": "AIR-AP3802I-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 3,
							"key": "AIR-AP2802E-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 5,
							"key": "AIR-CAP1602I-A-K9"
						}],
						"clientCountBinSize": 1,
						"clientCountKde": [0.379202400461687, 0.320754540627082, 0.167045236210398, 0.0669110041095711, 0.0273675337782121, 0.0135643812745705, 0.00831082298701451, 0.00584946976165074, 0.00417457957422699, 0.00296535739846162, 0.00217995601551342],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.340263050429487, 0.13333190735484, 0.0850927339470109, 0.0395762783854851, 0.112243691594478, 0.133107091136222, 0.0196743586344039, 0.00287426949542847, 0.0181889909208276, 0.0352020458775679, 0.0368277774102773, 0.00520023784688874, 0.00221936607957982, 0.0155616090524656, 0.00341954706529498, 0.00855294861394768, 0.00150466227143874, 0.00229933856596894, 0.00252262297365639, 0, 0.00233747234473131],
						"frequencyBand": "2.4 GHz",
						"impactedAps": null,
						"interferenceBinSize": 1,
						"interferenceKde": [0.0907936624064803, 0.0897836115056359, 0.0732767260479256, 0.063693307600828, 0.0604883960076488, 0.0508916729576906, 0.0374096224640141, 0.0302323623859915, 0.0282673011282266, 0.0237153947933436, 0.0183829665440189, 0.0172734327012966, 0.0182021743072608, 0.0168550351676262, 0.015804740472333, 0.0121893138794737, 0.0106768625365644, 0.00983130383617317, 0.010112418594355, 0.00788316397065149, 0.0086444121033663, 0.00862482258124829, 0.00857304218876574, 0.00875424432308928, 0.0082234182733656, 0.0101952963471587, 0.0109769769274959, 0.00792619302567139, 0.00804718446398255, 0.00739661632022914, 0.00781264350814188, 0.00642186336295831, 0.00572446075335664, 0.00507497983248807, 0.00648859783338287, 0.00664368859248996, 0.00657247841882942, 0.00821090096737718, 0.00730085704624893, 0.00663271782249784, 0.00576103566208048, 0.0057523370990766, 0.00742952639034112, 0.0061507311567802, 0.00651973460395209, 0.00850683834957216, 0.00797916265171817, 0.00891950518276456, 0.00846578927266356, 0.00753147408311332, 0.00685346170257811, 0.00663579173186796, 0.00608595810777854, 0.00551569047591071, 0.00523524053552907, 0.00474150413955444, 0.00421126165829316, 0.00448044334307213, 0.00300983217221879, 0.0025345494186783, 0.00228292412556717, 0.00244593967951079, 0.00260636146587573, 0.00192102707796735, 0.00195428779735437, 0.00291356442863896, 0.00313801019913796, 0.00381024832797636, 0.00354562122227387, 0.00307543003809909, 0.00212868793473819, 0.00119121959592705, 0, 0.00123749522694961, 0.00181233162488832, 0.00217925271164936],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 56025,
							"key": 0
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 13299,
							"key": 1
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 13299,
							"key": 0
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 56025,
							"key": 1
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00142929944388681, 0.0150555849610071, 0.0352239156689629, 0.00457962825791907, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0051006778579459, 0.077292578244064, 0.319689419913076, 0.171722507332781, 0.0336132746268953, 0.0071251841004989, 0.0101580205914442, 0.00845188310380965, 0.00176453567180382, 0, 0.00997677810351822, 0.057895835434754, 0.01430616087458, 0.00799647430481936, 0.00288688071032457, 0, 0, 0, 0, 0, 0, 0.0102912672185974, 0.076042751026256, 0.0102968713736031, 0.00210838123841451, 0.0154450336167234, 0.00214428714962266, 0.00870979843656923, 0.0625998115719634, 0.0084677126386087, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00209024701958975, 0.0154449524883712, 0.00209024701958975],
						"powerAnomaly": null,
						"powerBinSize": 1,
						"powerKde": [0.00587239371715852, 0.0161714707342723, 0.0423040298089886, 0.0239856910015157, 0.0291155557269486, 0.0653067809049767, 0.0327851307758624, 0.0286253442181266, 0.0245114541602511, 0.0212192784142612, 0.0374584197305574, 0.0519269094865398, 0.0315034114743371, 0.0355000946338661, 0.0331748505471787, 0.0438111059536674, 0.080090266255799, 0.0473902443252373, 0.0121137713361805, 0.00715167621672501, 0.00367811457583947, 0.0267262392379209, 0.121460346228422, 0.178117420535367],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 68655,
							"key": 4
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 669,
							"key": 6
						}],
						"primaryImageVersion": [{
							"__typename": "CiStrKeyCountValue",
							"count": 6,
							"key": "8.5.171.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 3,
							"key": "8.10.151.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 12,
							"key": "8.5.151.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 10,
							"key": "8.5.135.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 7,
							"key": "8.10.162.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 68,
							"key": "8.5.161.6"
						}],
						"rootCauseCategory": "BASELINE",
						"rootCauseKpis": null,
						"trafficBinSize": 1,
						"trafficKde": [0.582067850066051, 0.230936111158887, 0.0310541715958019, 0.0126161859362066, 0.0113806244500682, 0.0125225194270106, 0.0254740791903894, 0.0206052514779866, 0.0311586199377216, 0.0178154549949728, 0.00842299402723483, 0.00524288385152095, 0.00398675841918612, 0.00197983907777971, 0, 0, 0.00230728019208562, 0.00242937619709676],
						"utilizationBinSize": 1,
						"utilizationKde": [0.0874095523122863, 0.0806631683149572, 0.069635243686598, 0.0595060821188181, 0.0532887499535481, 0.0511714454595047, 0.0380140887993447, 0.0379186106833309, 0.0299290000692717, 0.0308206953036401, 0.0219214561356778, 0.0173647794675068, 0.0185827486018024, 0.0160241017534554, 0.0154735027971571, 0.0115108979284619, 0.0111791109046322, 0.00941227349943249, 0.0102118264355467, 0.00830591690522311, 0.0085162247149273, 0.00875840729090354, 0.0082653288393286, 0.008031337528904, 0.00909679826963642, 0.00990557986765373, 0.0106833233558396, 0.00959736896308006, 0.00792131427165946, 0.0073917025937525, 0.00612525314316547, 0.0054483615672929, 0.00580111386374, 0.00511096373149487, 0.00637702784737524, 0.00626589355999928, 0.00651225293846663, 0.00797247639700067, 0.00766599990029332, 0.00727487416548048, 0.00647219088340193, 0.00546335683912654, 0.00629415293536319, 0.00660115506403018, 0.00740997604199781, 0.00795117316445231, 0.00703040943748346, 0.00675356171810616, 0.00671124356351089, 0.00593031093310329, 0.00511115098389574, 0.00455850268394754, 0.00461333198974563, 0.00510890863776461, 0.00624196687224854, 0.00551115064683073, 0.0047434799126425, 0.00513373265775867, 0.00502772296446055, 0.00386005341627153, 0.00395720094792777, 0.00410328289636945, 0.00428255255414033, 0.0031301474507922, 0.00237548543510867, 0.00196665126867106, 0.00187559953277704, 0.00211980998039425, 0.00226147564147146, 0.00240354376924944, 0.00175455563854195, 0.00247716989044618, 0.00224594288338778, 0.00270255810973847, 0.00354394774279753, 0.00337807528899798, 0.00191485619185841, 0.00161929269209085, 0.00138143237773111, 0, 0, 0, 0, 0, 0, 0.00148083398346695, 0]
					}],
					"totalCount": 2
				}
			}
		},
	"getHighClientActivity24Template":
		{
			"data": {
				"getClientInsightAgg36Analysis": {
					"__typename": "GetClientInsightAgg36AnalysisConnection",
					"nodes": [{
						"__typename": "ClientInsightAgg36Type",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 2010,
							"key": 0
						}],
						"apModel": [{
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP3802E-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-CAP2702E-A-K9"
						}],
						"clientCountBinSize": 0.315789473684211,
						"clientCountKde": [0.120371190341475, 0.124015110435856, 0.121289450900231, 0.113133101046574, 0.101149574304713, 0.0872281434573594, 0.0730249538402018, 0.0597263008962684, 0.0479882991105812, 0.0380306959936224, 0.029811923347165, 0.0231000072723121, 0.0176884018694924, 0.0133602916367761, 0.00993209110774879, 0.00724735610852138, 0.00518090116220813, 0.00362574249500474, 0.00246464686032669],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.353870322041495, 0.393821193231382, 0.0992522123714623, 0.0221800434493627, 0.0413795060961046, 0.0490274521478524, 0.0060503194177473, 0, 0.00733327199609361, 0.0104906748415694, 0.0100283861099661, 0.00130645190446958, 0, 0.00350578624244307, 0],
						"frequencyBand": "2.4 GHz",
						"impactedAps": 3,
						"interferenceBinSize": 1,
						"interferenceKde": [0.0364816750166306, 0.0283218061476527, 0.0251648389571966, 0.0347144885096076, 0.0129720417448418, 0.00943744547764334, 0.00861800488355847, 0.0134780202865171, 0.081224449737377, 0.18928193549197, 0.0539371093149873, 0.0224785726243683, 0.0183312525296469, 0.0258072925354255, 0.0344561034003077, 0.0445172542886881, 0.053355686026136, 0.0433701230366376, 0.0385541324949427, 0.025049574582946, 0.0243619057408309, 0.0175544454537203, 0.0168646691820484, 0.0141008839651569, 0.0110951234534985, 0.0100929810189677, 0.0104561401355701, 0.0120324891512131, 0.0157153866526394, 0.0170649975473799, 0.0139778258392369, 0.0110186116250648, 0.00702854109095332, 0.00535311165436559, 0.00368961706957849, 0.00313651346107814, 0.00266071892169283, 0.00216032852149275, 0.00105711590400632],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 670,
							"key": 0
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 1340,
							"key": 1
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 1340,
							"key": 0
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 670,
							"key": 1
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0113332716597803, 0.0970094257847323, 0.119126491543233, 0.0797397776810539, 0.0187599557962927, 0.00188258210810294, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0715885056329788, 0.528971484160845, 0.0715885056329808],
						"powerAnomaly": "",
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00183470347984973, 0.0383760412752414, 0.106480649495015, 0.0397160779082473, 0.0312333498992854, 0.0880626603521287, 0.168347695999112, 0.386963927677455, 0.138984893913665],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 2010,
							"key": 4
						}],
						"primaryImageVersion": [{
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "8.10.151.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "8.5.161.6"
						}],
						"rootCauseCategory": "High client activity",
						"rootCauseKpis": ["trafficKde", "utilizationKde"],
						"trafficBinSize": 1,
						"trafficKde": [0, 0, 0, 0, 0, 0, 0.0340800140621492, 0.151097436358823, 0.128941051864768, 0.16484007811328, 0.0837898100902592, 0.109845289016183, 0.228824517043315, 0.0812325288181555, 0.014020340845229, 0.00332893378783908],
						"utilizationBinSize": 1,
						"utilizationKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0102663051017506, 0.0761377238900545, 0.0309900378259411, 0.0236375390456314, 0.0553097329580207, 0.171196536035901, 0.0630441687123684, 0.0394788997306221, 0.0213072428783306, 0.0166894391879056, 0.0124023185299031, 0.0101857689027621, 0.012454715081607, 0.0186640278315403, 0.0542822504801679, 0.0447544108118549, 0.0420211365657034, 0.0254432946934958, 0.028539887448039, 0.0298253635066912, 0.0211115874558719, 0.0229561192423392, 0.0154163127190544, 0.0176625739804558, 0.0128969272138115, 0.0133987948271226, 0.0113713615439946, 0.0177897250399819, 0.0141666667902069, 0.0153355996884931, 0.0112141571189081, 0.00735082684817059, 0.00873582528655747, 0.00555563833219996, 0.00521806210316788, 0.00347265700654679, 0.00360993797893573, 0.00226385306873142, 0.00234943625050311]
					}, {
						"__typename": "ClientInsightAgg36Type",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 67984,
							"key": 0
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 1340,
							"key": 2
						}],
						"apModel": [{
							"__typename": "CiStrKeyCountValue",
							"count": 15,
							"key": "AIR-AP2802E-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 8,
							"key": "AIR-AP2802I-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 6,
							"key": "AIR-CAP2702E-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-AP2802I-H-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-AP2802E-S-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-CAP2602I-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 14,
							"key": "AIR-AP3802I-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP1562E-H-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP1562E-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP1562E-F-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP1562I-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP1702I-N-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-CAP2702I-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 3,
							"key": "AIR-AP2802E-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-CAP1532I-S-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 9,
							"key": "AIR-AP1562D-F-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 5,
							"key": "AIR-AP3802I-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP2702I-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-AP2802I-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 6,
							"key": "AIR-AP1542I-S-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-AP2802I-S-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP1602I-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-CAP1602E-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP2702I-N-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "C9120AXE-B"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 7,
							"key": "AIR-AP3802I-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 3,
							"key": "AIR-AP2802E-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 5,
							"key": "AIR-CAP1602I-A-K9"
						}],
						"clientCountBinSize": 1,
						"clientCountKde": [0.379202400461687, 0.320754540627082, 0.167045236210398, 0.0669110041095711, 0.0273675337782121, 0.0135643812745705, 0.00831082298701451, 0.00584946976165074, 0.00417457957422699, 0.00296535739846162, 0.00217995601551342],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.340263050429487, 0.13333190735484, 0.0850927339470109, 0.0395762783854851, 0.112243691594478, 0.133107091136222, 0.0196743586344039, 0.00287426949542847, 0.0181889909208276, 0.0352020458775679, 0.0368277774102773, 0.00520023784688874, 0.00221936607957982, 0.0155616090524656, 0.00341954706529498, 0.00855294861394768, 0.00150466227143874, 0.00229933856596894, 0.00252262297365639, 0, 0.00233747234473131],
						"frequencyBand": "2.4 GHz",
						"impactedAps": null,
						"interferenceBinSize": 1,
						"interferenceKde": [0.0907936624064803, 0.0897836115056359, 0.0732767260479256, 0.063693307600828, 0.0604883960076488, 0.0508916729576906, 0.0374096224640141, 0.0302323623859915, 0.0282673011282266, 0.0237153947933436, 0.0183829665440189, 0.0172734327012966, 0.0182021743072608, 0.0168550351676262, 0.015804740472333, 0.0121893138794737, 0.0106768625365644, 0.00983130383617317, 0.010112418594355, 0.00788316397065149, 0.0086444121033663, 0.00862482258124829, 0.00857304218876574, 0.00875424432308928, 0.0082234182733656, 0.0101952963471587, 0.0109769769274959, 0.00792619302567139, 0.00804718446398255, 0.00739661632022914, 0.00781264350814188, 0.00642186336295831, 0.00572446075335664, 0.00507497983248807, 0.00648859783338287, 0.00664368859248996, 0.00657247841882942, 0.00821090096737718, 0.00730085704624893, 0.00663271782249784, 0.00576103566208048, 0.0057523370990766, 0.00742952639034112, 0.0061507311567802, 0.00651973460395209, 0.00850683834957216, 0.00797916265171817, 0.00891950518276456, 0.00846578927266356, 0.00753147408311332, 0.00685346170257811, 0.00663579173186796, 0.00608595810777854, 0.00551569047591071, 0.00523524053552907, 0.00474150413955444, 0.00421126165829316, 0.00448044334307213, 0.00300983217221879, 0.0025345494186783, 0.00228292412556717, 0.00244593967951079, 0.00260636146587573, 0.00192102707796735, 0.00195428779735437, 0.00291356442863896, 0.00313801019913796, 0.00381024832797636, 0.00354562122227387, 0.00307543003809909, 0.00212868793473819, 0.00119121959592705, 0, 0.00123749522694961, 0.00181233162488832, 0.00217925271164936],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 56025,
							"key": 0
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 13299,
							"key": 1
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 13299,
							"key": 0
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 56025,
							"key": 1
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00142929944388681, 0.0150555849610071, 0.0352239156689629, 0.00457962825791907, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0051006778579459, 0.077292578244064, 0.319689419913076, 0.171722507332781, 0.0336132746268953, 0.0071251841004989, 0.0101580205914442, 0.00845188310380965, 0.00176453567180382, 0, 0.00997677810351822, 0.057895835434754, 0.01430616087458, 0.00799647430481936, 0.00288688071032457, 0, 0, 0, 0, 0, 0, 0.0102912672185974, 0.076042751026256, 0.0102968713736031, 0.00210838123841451, 0.0154450336167234, 0.00214428714962266, 0.00870979843656923, 0.0625998115719634, 0.0084677126386087, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00209024701958975, 0.0154449524883712, 0.00209024701958975],
						"powerAnomaly": null,
						"powerBinSize": 1,
						"powerKde": [0.00587239371715852, 0.0161714707342723, 0.0423040298089886, 0.0239856910015157, 0.0291155557269486, 0.0653067809049767, 0.0327851307758624, 0.0286253442181266, 0.0245114541602511, 0.0212192784142612, 0.0374584197305574, 0.0519269094865398, 0.0315034114743371, 0.0355000946338661, 0.0331748505471787, 0.0438111059536674, 0.080090266255799, 0.0473902443252373, 0.0121137713361805, 0.00715167621672501, 0.00367811457583947, 0.0267262392379209, 0.121460346228422, 0.178117420535367],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 68655,
							"key": 4
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 669,
							"key": 6
						}],
						"primaryImageVersion": [{
							"__typename": "CiStrKeyCountValue",
							"count": 6,
							"key": "8.5.171.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 3,
							"key": "8.10.151.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 12,
							"key": "8.5.151.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 10,
							"key": "8.5.135.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 7,
							"key": "8.10.162.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 68,
							"key": "8.5.161.6"
						}],
						"rootCauseCategory": "BASELINE",
						"rootCauseKpis": null,
						"trafficBinSize": 1,
						"trafficKde": [0.582067850066051, 0.230936111158887, 0.0310541715958019, 0.0126161859362066, 0.0113806244500682, 0.0125225194270106, 0.0254740791903894, 0.0206052514779866, 0.0311586199377216, 0.0178154549949728, 0.00842299402723483, 0.00524288385152095, 0.00398675841918612, 0.00197983907777971, 0, 0, 0.00230728019208562, 0.00242937619709676],
						"utilizationBinSize": 1,
						"utilizationKde": [0.0874095523122863, 0.0806631683149572, 0.069635243686598, 0.0595060821188181, 0.0532887499535481, 0.0511714454595047, 0.0380140887993447, 0.0379186106833309, 0.0299290000692717, 0.0308206953036401, 0.0219214561356778, 0.0173647794675068, 0.0185827486018024, 0.0160241017534554, 0.0154735027971571, 0.0115108979284619, 0.0111791109046322, 0.00941227349943249, 0.0102118264355467, 0.00830591690522311, 0.0085162247149273, 0.00875840729090354, 0.0082653288393286, 0.008031337528904, 0.00909679826963642, 0.00990557986765373, 0.0106833233558396, 0.00959736896308006, 0.00792131427165946, 0.0073917025937525, 0.00612525314316547, 0.0054483615672929, 0.00580111386374, 0.00511096373149487, 0.00637702784737524, 0.00626589355999928, 0.00651225293846663, 0.00797247639700067, 0.00766599990029332, 0.00727487416548048, 0.00647219088340193, 0.00546335683912654, 0.00629415293536319, 0.00660115506403018, 0.00740997604199781, 0.00795117316445231, 0.00703040943748346, 0.00675356171810616, 0.00671124356351089, 0.00593031093310329, 0.00511115098389574, 0.00455850268394754, 0.00461333198974563, 0.00510890863776461, 0.00624196687224854, 0.00551115064683073, 0.0047434799126425, 0.00513373265775867, 0.00502772296446055, 0.00386005341627153, 0.00395720094792777, 0.00410328289636945, 0.00428255255414033, 0.0031301474507922, 0.00237548543510867, 0.00196665126867106, 0.00187559953277704, 0.00211980998039425, 0.00226147564147146, 0.00240354376924944, 0.00175455563854195, 0.00247716989044618, 0.00224594288338778, 0.00270255810973847, 0.00354394774279753, 0.00337807528899798, 0.00191485619185841, 0.00161929269209085, 0.00138143237773111, 0, 0, 0, 0, 0, 0, 0.00148083398346695, 0]
					}],
					"totalCount": 2
				}
			}
		},
	"getHighAPDensityTemplate":
		{
			"data": {
				"getClientInsightAgg36Analysis": {
					"__typename": "GetClientInsightAgg36AnalysisConnection",
					"nodes": [{
						"__typename": "ClientInsightAgg36Type",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 526,
							"key": 0
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 523,
							"key": 5
						}],
						"apModel": [{
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP1542I-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP1532I-S-K9"
						}],
						"clientCountBinSize": 1,
						"clientCountKde": [0.304077015317561, 0.300854847180733, 0.200188932309596, 0.104413376442737, 0.047699388932433, 0.0197069070835639, 0.00740814466457057, 0.00289790565389389, 0.00181692012812356, 0.00197945655097134, 0.00290539767586349, 0.00349691114339137, 0.00255479691656234],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.012574223607385, 0.0291816157598205, 0.20322463438659, 0.0427134373497788, 0.113375364793222, 0.0325308149170477, 0.0914086983555936, 0.10476725690075, 0.122383220326012, 0.0936975542214321, 0.0611924769845637, 0.0346518334801764, 0.0182603460595606, 0.011213818444064, 0.00782211305906203, 0.00617340225658019, 0.00325004683231841, 0.00317822372805281, 0.00260657948978255, 0.00170354791268495, 0.00126113208604023, 0.00156225790268213],
						"frequencyBand": "2.4 GHz",
						"impactedAps": 2,
						"interferenceBinSize": 1,
						"interferenceKde": [0, 0, 0, 0, 0, 0, 0, 0, 0.00187275393848433, 0.00382245358729865, 0.00648511209576047, 0.01359944725654, 0.0254391663357913, 0.0418489232075798, 0.0711960835635293, 0.0877896067092941, 0.103828201002034, 0.0961442103409102, 0.077974870764788, 0.0501778038463877, 0.0434595453554277, 0.0346935473236734, 0.0275807669301384, 0.0301970325804804, 0.0277739500778919, 0.0338957560550857, 0.0253298081834744, 0.0287999825150247, 0.0237935443271012, 0.0206775407157604, 0.0175895312287222, 0.0125871520587635, 0.0155965251628573, 0.0103657959112966, 0.0120486067288992, 0.00844689926422404, 0.0102864846359664, 0.00683373681711238, 0.00790955511428654, 0.00323256675464733, 0.0041467741301207, 0.00447824922506665, 0.00265022185699041, 0.00273069974418837, 0.00155393160178474, 0.00162592341323343, 0],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 1049,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 1049,
							"key": 1
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00531385860453934, 0.0860376773483216, 0.356785180501696, 0.0502632716102244, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0534239018970526, 0.394752208141112, 0.0534239018970544],
						"powerAnomaly": "low",
						"powerBinSize": 1,
						"powerKde": [0, 0, 0.00481150024924975, 0.107250944202083, 0.387139620931786, 0.388096808581553, 0.107768293630029, 0.00493283240529956],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 1049,
							"key": 4
						}],
						"primaryImageVersion": [{
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "8.5.161.6"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "8.5.182.0"
						}],
						"rootCauseCategory": "High density",
						"rootCauseKpis": ["powerKde"],
						"trafficBinSize": 1,
						"trafficKde": [0.364289109960146, 0.0908362942021017, 0.00145256771900495, 0, 0, 0, 0, 0.00958452229648335, 0.0776401741113798, 0.19989029159305, 0.140477857487249, 0.0627528206520152, 0.0302155012556225, 0.0138234606317359, 0.00552690802876737, 0.00230588300629182],
						"utilizationBinSize": 1,
						"utilizationKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00157003162508944, 0.00424612143188795, 0.00709734730354178, 0.0113361582202231, 0.0109190196723494, 0.0196632717740374, 0.0162374462442175, 0.0241878920095392, 0.0184663888520376, 0.0337533366533093, 0.0418891480492855, 0.0522756655122833, 0.0775474969001034, 0.0968647432322943, 0.115649111427571, 0.10567199189001, 0.0941680948905931, 0.0659433759474397, 0.0465988703065027, 0.0320447323460106, 0.0193609578306817, 0.019924962421396, 0.013410623114372, 0.0141548028043592, 0.00948665494101093, 0.0106650645732851, 0.00699638223701789, 0.00792381224529747, 0.00323126254864903, 0.00414504512294653, 0.00447638200857621, 0.00264911684078743, 0.00272956117254206, 0.00155328368636823, 0.00162524548066272, 0]
					}, {
						"__typename": "ClientInsightAgg36Type",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 47889,
							"key": 0
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 843,
							"key": 2
						}],
						"apModel": [{
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP1702I-N-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-CAP2602I-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 3,
							"key": "AIR-AP2802I-Q-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 4,
							"key": "AIR-AP2802E-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 7,
							"key": "AIR-AP2802I-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 6,
							"key": "AIR-AP1542I-S-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-CAP1532I-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 9,
							"key": "AIR-AP3802I-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP1562E-H-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "C9136I-B"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 21,
							"key": "AIR-AP2802E-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 5,
							"key": "AIR-CAP2702E-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 9,
							"key": "AIR-AP2802I-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 5,
							"key": "AIR-AP1562D-F-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "C9136I-B"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 3,
							"key": "AIR-AP2802I-S-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP1562I-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP3802E-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "C9136I-B"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP1532I-S-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 4,
							"key": "AIR-AP3802I-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-CAP1602E-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-AP3802I-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP2802I-Z-K9"
						}],
						"clientCountBinSize": 1,
						"clientCountKde": [0.369649502633856, 0.322447208635756, 0.173251398545032, 0.0681199866245327, 0.0250690557035081, 0.0113751032719645, 0.00693822625738608, 0.00517480583179734, 0.00422997888979813, 0.00352851121288145, 0.00290673752781913, 0.00243973182345981, 0.00198665343103952, 0.00151259969227589, 0],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.302145406841199, 0.089738940567009, 0.0643465992095109, 0.0282364895113694, 0.111074939508295, 0.138030455326364, 0.0280301639929353, 0.00644324104263537, 0.024713377604974, 0.0427344967395962, 0.0576016673669985, 0.00811278038582279, 0.00405046730084415, 0.024149042368216, 0.00578449169527505, 0.0187090386847001, 0.00312268088731508, 0.00523650004888559, 0.00693707913136882, 0.00202056556639, 0.0085681216643707, 0.0016940446536852, 0.00395921412245381, 0, 0, 0.00341997482873394, 0.00233417816438751, 0.00289095534809979, 0.00148626183272831, 0.00198687227401169, 0.00134529802288938],
						"frequencyBand": "2.4 GHz",
						"impactedAps": null,
						"interferenceBinSize": 1,
						"interferenceKde": [0.08935974599204, 0.0930606410313987, 0.0723778096597521, 0.0582937530588732, 0.0471401691423699, 0.0391254410443921, 0.0291786496876561, 0.0291738086800601, 0.0237616144592907, 0.0220779735958119, 0.0220458140258339, 0.014425136450384, 0.0134692307230752, 0.0111296807558458, 0.0111869904061714, 0.0104860079405086, 0.00936282103728696, 0.00872590569110776, 0.00902622337578099, 0.0103313962330461, 0.0115407773003135, 0.0105695617532088, 0.00975936026407547, 0.0108148378106464, 0.0119651855875002, 0.0129512978994526, 0.011773843517271, 0.0105761409068672, 0.00992922774125439, 0.010721865965862, 0.0100044891738345, 0.00951991273824073, 0.00778692979978731, 0.00676028697351939, 0.00659276344410656, 0.00486385149656584, 0.0055563292390676, 0.00456754783268814, 0.00492632768838988, 0.00581881172880173, 0.00565138851686529, 0.00537782927800144, 0.00654975834999242, 0.00697037516306359, 0.00713092745323644, 0.00680224416168559, 0.0067096830817801, 0.00743874651552538, 0.00721913716225403, 0.00706526135582351, 0.00677538970006126, 0.00697979732400134, 0.00608064099645453, 0.00713287720068762, 0.00714197778998258, 0.00666845340557054, 0.00611535529182604, 0.00582938303642076, 0.00532565539896307, 0.00519397448104395, 0.00465184686732319, 0.00466966418369788, 0.00420240063646676, 0.0040458419149021, 0.00385568651899904, 0.00371286019779921, 0.00336070050667414, 0.003170947086032, 0.0032843832200711, 0.00361477277525025, 0.00319770791453313, 0.00370473123343861, 0.00337518265952046, 0.00310585359362384, 0.00282558124063312, 0.00368776681136464, 0.00304834204564427, 0.00348688177353955, 0.00234430242117502],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 43678,
							"key": 0
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 5054,
							"key": 1
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 9518,
							"key": 0
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 39214,
							"key": 1
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00328282170462051, 0.0275786343391652, 0.0289408141384827, 0.00348642170124499, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0043119816277413, 0.0424397251236071, 0.11102305656989, 0.128223112159057, 0.0467131416823302, 0.0134214878881277, 0.028409647699249, 0.00379693336516602, 0, 0.0142508800861806, 0.124650295006866, 0.180975799202809, 0.0790595714238042, 0.0170992171726148, 0.00288788635258626, 0.00360135563984231, 0.00258735403179108, 0, 0, 0, 0, 0.0070464309102397, 0.0519877308809605, 0.00704524370435064, 0.0032797470555559, 0.0239458535761284, 0.00324628088337661, 0.00217153882465915, 0.0159113890268868, 0.0021533722290229, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00171640732463851, 0.0127244900468225, 0.00203137862218356],
						"powerAnomaly": null,
						"powerBinSize": 1,
						"powerKde": [0.00565306073418578, 0.0280040231703781, 0.0777364834227755, 0.0484666456552868, 0.0525322379380783, 0.0723388236986066, 0.0290187246171891, 0.0336405550291428, 0.066544726466869, 0.0310481076779014, 0.0229815041340289, 0.0250840241256798, 0.0175209360393465, 0.0303979673254012, 0.0510450902406365, 0.0450002235336537, 0.0476349296918488, 0.030988246021046, 0.00863042960811593, 0.00767856190856501, 0.0128961230682573, 0.0258941860595296, 0.0964511876555407, 0.132813202177937],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 46109,
							"key": 4
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 2623,
							"key": 6
						}],
						"primaryImageVersion": [{
							"__typename": "CiStrKeyCountValue",
							"count": 8,
							"key": "17.3.4.40"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 7,
							"key": "8.5.171.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 13,
							"key": "8.5.161.6"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 21,
							"key": "8.5.182.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 5,
							"key": "8.5.135.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "17.3.2.32"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 39,
							"key": "8.10.162.0"
						}],
						"rootCauseCategory": "BASELINE",
						"rootCauseKpis": null,
						"trafficBinSize": 1,
						"trafficKde": [0.484109131319271, 0.216428927005032, 0.0489553460705625, 0.0267721784761539, 0.0534928509300823, 0.0271590939313594, 0.0179085146841867, 0.0196975088052947, 0.0264796661557139, 0.0214917573931773, 0.0100965902025387, 0.0055095297481127, 0.00320914290801648, 0.00245860404238066, 0.00225244751600822, 0.0026163430426228, 0.00460839951447274, 0.0110676834077946, 0.00793912822465402, 0.00432893499365039, 0.00202704170484233],
						"utilizationBinSize": 1,
						"utilizationKde": [0.0836023679013094, 0.0844625024803222, 0.0686376219033832, 0.0569114853114821, 0.0453745876153547, 0.0373904629160366, 0.0271409182359671, 0.025707196627017, 0.02209287358871, 0.0235831442510661, 0.0226667406670757, 0.0185657098487347, 0.019218887749373, 0.012428919384912, 0.0109858769476387, 0.00941359726315103, 0.00786542825596022, 0.00688749500101211, 0.00638246770443329, 0.00812379579027423, 0.00946149813428833, 0.00890652918051131, 0.00914392186443149, 0.0110440970586463, 0.0110478739102523, 0.0101739808822788, 0.00968530086910192, 0.00912874635014101, 0.00895377587984416, 0.0102752187259848, 0.00900519704342628, 0.00993232040025922, 0.00866057632232714, 0.00880266128254993, 0.00784126990836942, 0.00689285595826028, 0.00694222233639575, 0.00759319035097292, 0.00822158718192743, 0.00735469277801871, 0.0067249384011823, 0.00529355943441715, 0.00554919411816854, 0.00657167902120718, 0.00678865394534177, 0.00647601309834111, 0.00745363758218483, 0.00770455350158978, 0.00781016614680939, 0.0074771212560253, 0.0076437694723184, 0.00690896503741222, 0.00650895333152567, 0.00762231315313134, 0.00767702752941696, 0.00688212103603592, 0.0068042464601216, 0.00679938943494204, 0.00633331285726518, 0.00606495548500109, 0.00473791147552334, 0.00576404729014752, 0.00497540861961884, 0.00406375282464961, 0.00339722170223958, 0.00393298686464354, 0.00422559837225603, 0.00247561875894467, 0.00271878198481064, 0.00191318043089199, 0.00219601736408818, 0.00178305544689614, 0.00167725432265457, 0.00141216553687459, 0.00170160371472065, 0.00147169407869189, 0.00275253226373628, 0.00233577128199632, 0.00239439145290098, 0.0024552803860721, 0.0033290910107764, 0.0034342393886537, 0.00272725082598064, 0.0027043271788855, 0.00313095707052773, 0.00218759036464359, 0.00161623345450013, 0.00172275335139562, 0.00127766690173627, 0.00117015857835159, 0, 0.00106074457478273, 0.00216698599197234]
					}],
					"totalCount": 2
				}
			}

		},
	"getClientInsightRadiosDynamicTemplate": {
		"data": {
			"getClientInsightRadios36Table": {
				"__typename": "GetClientInsightRadios36TableConnection",
				"nodes": [],
				"totalCount": 0
			}
		}
	},

	 //"getClientInsightRadios2Template":
	// 	{
	// 		"data": {
	// 			"getClientInsightRadios36Table": {
	// 				"__typename": "GetClientInsightRadios36TableConnection",
	// 				"nodes": [{
	// 					"__typename": "ClientInsightRadios36TableType",
	// 					"aesApMac": "gLZeAg-PwLc5BurxPc7fW0tAkPhbjLbmJCv09uGJhBzL",
	// 					"aesApName": "aes:JtsXzvQnpVutv1ZGBnz0GW4aKC6yFm6OiTgS",
	// 					"apEntityId": "AIR-AP2802I-Q-K9",
	// 					"errorFlags": ["snrKde"],
	// 					"frequencyBand": "2.4 GHz",
	// 					"impactedClients": 17,
	// 					"severity": "s2",
	// 					"siteHierarchyArray": ["aes:S1DpNVvZ8zYO9k1YSicp7RjfnqM=", "aes:NGfrLSit_Z-US-zYS_2EZLbjN176", "aes:wJygRWVEUbPo4zzz24OroX_EHE1FayS_4VKSoELJ134f4MeHIFPSvtIjBANk9W9dyJ29", "aes:d_YN3OvKsZxMcJy_UPKAIduS"],
	// 					"siteId": "92a96071-f610-47bf-8012-701b51835737",
	// 					"uniqueClients": 26
	// 				}, {
	// 					"__typename": "ClientInsightRadios36TableType",
	// 					"aesApMac": "GGTJQBRDpOqm2B17dwe5IZ0Tpod-oJZgy2_Adxx8d2Dq",
	// 					"aesApName": "aes:Kj_cBcMz0NuIXGpdxAPsGsTdyX2rWJhrEtXobhbvjOUknTluXw==",
	// 					"apEntityId": "AIR-AP2802I-A-K9",
	// 					"errorFlags": ["rssiKde", "snrKde", "speedDensity"],
	// 					"frequencyBand": "2.4 GHz",
	// 					"impactedClients": 4,
	// 					"severity": "s2",
	// 					"siteHierarchyArray": ["aes:FIaqSVIGh4f8RRqCPZ9jut_x", "aes:z1m1U7w5e6Y10gAWjtKsQo7_nv4UZi6DPA==", "aes:Hiqo8BLfuCXcXy7hhudLUG7fs15EXagJeKGuCwLhmpHmnA59SecUpSEznkR88_8=", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
	// 					"siteId": "961b033b-5810-40aa-805a-7cd0022116e6",
	// 					"uniqueClients": 5
	// 				}, {
	// 					"__typename": "ClientInsightRadios36TableType",
	// 					"aesApMac": "UM_sQDPM7JemAiA1ZNHLayraHROPpIckJUelHATljsp8",
	// 					"aesApName": "aes:XcLJu4wu2TLm2sb-uXGZEfO4FhVXfScahmIkww==",
	// 					"apEntityId": "AIR-AP2802I-Q-K9",
	// 					"errorFlags": ["snrKde"],
	// 					"frequencyBand": "2.4 GHz",
	// 					"impactedClients": 2,
	// 					"severity": "s2",
	// 					"siteHierarchyArray": ["aes:S1DpNVvZ8zYO9k1YSicp7RjfnqM=", "aes:NGfrLSit_Z-US-zYS_2EZLbjN176", "aes:wJygRWVEUbPo4zzz24OroX_EHE1FayS_4VKSoELJ134f4MeHIFPSvtIjBANk9W9dyJ29", "aes:d_YN3OvKsZxMcJy_UPKAIduS"],
	// 					"siteId": "92a96071-f610-47bf-8012-701b51835737",
	// 					"uniqueClients": 10
	// 				}, {
	// 					"__typename": "ClientInsightRadios36TableType",
	// 					"aesApMac": "7wOyKVEgvxydPziV_EOK0PebU5tEYchQWU_xZzR5UdTT",
	// 					"aesApName": "aes:x9rXK8e_4QQwh0FjUw2TJhBrayA-XDbmJQ_gsysa9FC_",
	// 					"apEntityId": "AIR-AP3802I-A-K9",
	// 					"errorFlags": ["speedDensity"],
	// 					"frequencyBand": "2.4 GHz",
	// 					"impactedClients": 8,
	// 					"severity": "s2",
	// 					"siteHierarchyArray": ["aes:FIaqSVIGh4f8RRqCPZ9jut_x", "aes:8nSMBW3ZUyqKAJF2uZo7brkOqQ7teorZ", "aes:5RQrflNywpRhv43FgH2oykdo4fBz8_bWFJRTB3Mf3mf6luZ-mnYQHGxLri9golkueYSY_3VnHg==", "aes:09kMxYFymmmX7GVR_-pp7sklj7H99Tw="],
	// 					"siteId": "474dfc39-2ed6-428d-88ad-ec59a4c575f0",
	// 					"uniqueClients": 30
	// 				}, {
	// 					"__typename": "ClientInsightRadios36TableType",
	// 					"aesApMac": "ur48QFLqOFdYEAntulIcOLVpJ8QX3oOwJn1B2V60Xbyl",
	// 					"aesApName": "aes:5f5qDtop1BfDDHSbt0JpIUrIaYdPHpBo_u_B3XPV8w==",
	// 					"apEntityId": "AIR-CAP1532I-A-K9",
	// 					"errorFlags": ["packetFailureKde"],
	// 					"frequencyBand": "2.4 GHz",
	// 					"impactedClients": 65,
	// 					"severity": "s1",
	// 					"siteHierarchyArray": ["aes:FIaqSVIGh4f8RRqCPZ9jut_x", "aes:z1m1U7w5e6Y10gAWjtKsQo7_nv4UZi6DPA==", "aes:ostFDpMrnMQTieqii51PVpH83Z2T11X6xtLkkll3pdAi4qKBBc9Qgw_Z2XFRbTtxxY3lfRYh", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
	// 					"siteId": "f878dd7f-cf4e-47f3-aa22-83d61486ceea",
	// 					"uniqueClients": 74
	// 				}, {
	// 					"__typename": "ClientInsightRadios36TableType",
	// 					"aesApMac": "BsFnXMF9a9WSUoW9Fb5xxQ6vvAUrBKl-Kd4XuPGYVKHn",
	// 					"aesApName": "aes:BlWn7tlZldPvpN20TnSGeM4o6WCy5uiyFRzL",
	// 					"apEntityId": "AIR-AP2802I-Q-K9",
	// 					"errorFlags": ["snrKde"],
	// 					"frequencyBand": "2.4 GHz",
	// 					"impactedClients": 6,
	// 					"severity": "s2",
	// 					"siteHierarchyArray": ["aes:S1DpNVvZ8zYO9k1YSicp7RjfnqM=", "aes:NGfrLSit_Z-US-zYS_2EZLbjN176", "aes:wJygRWVEUbPo4zzz24OroX_EHE1FayS_4VKSoELJ134f4MeHIFPSvtIjBANk9W9dyJ29", "aes:d_YN3OvKsZxMcJy_UPKAIduS"],
	// 					"siteId": "92a96071-f610-47bf-8012-701b51835737",
	// 					"uniqueClients": 15
	// 				}, {
	// 					"__typename": "ClientInsightRadios36TableType",
	// 					"aesApMac": "nyfcmxVA0gLC08PEQqV-8CZ8Ib2KkjQm2s2MvyODEG8m",
	// 					"aesApName": "aes:VQkppEe72aR6TegnJSHAxVQGAsxOJ6vfJ5cTwY_WNg==",
	// 					"apEntityId": "AIR-AP2802E-B-K9",
	// 					"errorFlags": ["speedDensity"],
	// 					"frequencyBand": "2.4 GHz",
	// 					"impactedClients": 4,
	// 					"severity": "s2",
	// 					"siteHierarchyArray": ["aes:moMlBCB9-yo9HRTppAfPhB53", "aes:WGepcqP5sd3GZxPRZ2AeIT-J7bIjzD0OjX0vBAk=", "aes:u1sE0TUq23G0Y7plHz8jZwvkMGOtZNHwHhJcRcHkXbqA4B4hnU2jIiQ7mxWIyBBTZIHlzhw=", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
	// 					"siteId": "5b68362b-3c1e-4415-911a-adc13e06b0bc",
	// 					"uniqueClients": 13
	// 				}, {
	// 					"__typename": "ClientInsightRadios36TableType",
	// 					"aesApMac": "k869PAsKNp6zmBixZw98kOtMhdoJ1v2OW6n_aF4nkfcf",
	// 					"aesApName": "aes:qP-9wDCU0AjhWqtmV2O1frQ85En5RMvRzRuv8cD5bJc=",
	// 					"apEntityId": "AIR-AP1562E-Z-K9",
	// 					"errorFlags": ["rssiKde", "snrKde", "speedDensity"],
	// 					"frequencyBand": "2.4 GHz",
	// 					"impactedClients": 66,
	// 					"severity": "s1",
	// 					"siteHierarchyArray": ["aes:FIaqSVIGh4f8RRqCPZ9jut_x", "aes:Ym6ZW7DFn0pPctVichf2TcmeTGdqCQ==", "aes:4NitRQcoudWwRXBORZp0ODXPhRPYnGRFvooSpNAwI6_UJEFJpCWJ8pKil8fMASI=", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
	// 					"siteId": "ab2cd664-b803-4a2b-b7cd-5b7674eeda5c",
	// 					"uniqueClients": 90
	// 				}, {
	// 					"__typename": "ClientInsightRadios36TableType",
	// 					"aesApMac": "9trqW_xmnK1C0tapjaNnHi0DbF-mJ0dtfBY7aFWWI8r0",
	// 					"aesApName": "aes:IvZY5NEnph6AIwFcRBozrKiUfRuD9cNuyhcK3Q==",
	// 					"apEntityId": "AIR-AP2802I-Q-K9",
	// 					"errorFlags": ["snrKde"],
	// 					"frequencyBand": "2.4 GHz",
	// 					"impactedClients": 12,
	// 					"severity": "s1",
	// 					"siteHierarchyArray": ["aes:S1DpNVvZ8zYO9k1YSicp7RjfnqM=", "aes:NGfrLSit_Z-US-zYS_2EZLbjN176", "aes:wJygRWVEUbPo4zzz24OroX_EHE1FayS_4VKSoELJ134f4MeHIFPSvtIjBANk9W9dyJ29", "aes:d_YN3OvKsZxMcJy_UPKAIduS"],
	// 					"siteId": "92a96071-f610-47bf-8012-701b51835737",
	// 					"uniqueClients": 19
	// 				}],
	// 				"totalCount": 9
	// 			}
	// 		}
	// 	},
	// "getClientInsightRadios3Template":
	// 	{
	// 		"data": {
	// 			"getClientInsightRadios36Table": {
	// 				"__typename": "GetClientInsightRadios36TableConnection",
	// 				"nodes": [{
	// 					"__typename": "ClientInsightRadios36TableType",
	// 					"aesApMac": "ZqkwWpz3hIQMFJkTQjXb34qOdvyEdfbfpKTGbB6v2npJ",
	// 					"aesApName": "aes:rq66FfmqHgf7QU2j1FhBd0Q-IlAY7XkTlEGB_M-01iE=",
	// 					"apEntityId": "AIR-AP1562E-B-K9",
	// 					"errorFlags": ["speedDensity"],
	// 					"frequencyBand": "2.4 GHz",
	// 					"impactedClients": 47,
	// 					"severity": "s1",
	// 					"siteHierarchyArray": ["aes:moMlBCB9-yo9HRTppAfPhB53", "aes:WGepcqP5sd3GZxPRZ2AeIT-J7bIjzD0OjX0vBAk=", "aes:XKLxCcbDOWtQlv8LaT4cALtAefGvYDwtOAvHcBzTeWFF0EZvCOtX9izFaig9s4S4jKE_V0rESvo=", "aes:rzCOIP_6rh1TXAAHfqczLRDakA=="],
	// 					"siteId": null,
	// 					"uniqueClients": 52
	// 				}, {
	// 					"__typename": "ClientInsightRadios36TableType",
	// 					"aesApMac": "S9mYTB_VfwgAO-ETzM9PJ4Eh1t4pzQx7AT9ZmOaqGlTm",
	// 					"aesApName": "aes:uNNAB8DW0qm12aXroLiM78D74pKMlFy8x3KvSAIH",
	// 					"apEntityId": "C9120AXE-B",
	// 					"errorFlags": ["speedDensity"],
	// 					"frequencyBand": "2.4 GHz",
	// 					"impactedClients": 42,
	// 					"severity": "s2",
	// 					"siteHierarchyArray": ["aes:moMlBCB9-yo9HRTppAfPhB53", "aes:WGepcqP5sd3GZxPRZ2AeIT-J7bIjzD0OjX0vBAk=", "aes:tg53xm_TE2z5Z9hMIc1BlXef-bYgE3QiqpsEqRuyqNrHXFpWZ6HZsFOdNjBuJhAa2G4q", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
	// 					"siteId": "3a1670ea-43e2-4856-a8cf-477d616390b0",
	// 					"uniqueClients": 48
	// 				}, {
	// 					"__typename": "ClientInsightRadios36TableType",
	// 					"aesApMac": "E18IyYyEqLUdLmYpUWH0gcsd3r9oSLMbftZMktvsIE35",
	// 					"aesApName": "aes:t9RcB4KDnLpbWQdJosxtog7SPZ9QhC5qa-VrRwRB",
	// 					"apEntityId": "AIR-CAP2702E-A-K9",
	// 					"errorFlags": ["rssiKde", "snrKde", "packetRetryKde"],
	// 					"frequencyBand": "2.4 GHz",
	// 					"impactedClients": 65,
	// 					"severity": "s1",
	// 					"siteHierarchyArray": ["aes:moMlBCB9-yo9HRTppAfPhB53", "aes:WGepcqP5sd3GZxPRZ2AeIT-J7bIjzD0OjX0vBAk=", "aes:XKLxCcbDOWtQlv8LaT4cALtAefGvYDwtOAvHcBzTeWFF0EZvCOtX9izFaig9s4S4jKE_V0rESvo=", "aes:JpCWcqDzraD6CMIwIMQ3UQnfrDSB0jmIlA=="],
	// 					"siteId": "d2351ed6-6058-46ee-88e9-b79bda792fe9",
	// 					"uniqueClients": 66
	// 				}],
	// 				"totalCount": 3
	// 			}
	// 		}
	// 	},

	// "getClientInsightRadios4Template":
	// 	{
	// 		"data": {
	// 			"getClientInsightRadios36Table": {
	// 				"__typename": "GetClientInsightRadios36TableConnection",
	// 				"nodes": [{
	// 					"__typename": "ClientInsightRadios36TableType",
	// 					"aesApMac": "BHWeq62odiF0Mhj_Dxa5FdVLBrc_wSJwhsaavsOg3iwh",
	// 					"aesApName": "aes:GDXVm1P2egF3IJXAF51wtBZDA25LtYRfWDaIuX22",
	// 					"apEntityId": "AIR-AP1542I-B-K9",
	// 					"errorFlags": ["speedDensity"],
	// 					"frequencyBand": "2.4 GHz",
	// 					"impactedClients": 30,
	// 					"severity": "s1",
	// 					"siteHierarchyArray": ["aes:moMlBCB9-yo9HRTppAfPhB53", "aes:WGepcqP5sd3GZxPRZ2AeIT-J7bIjzD0OjX0vBAk=", "aes:IASzmfOunDepjcxWGdByUM_6qZyAcgj6yrusXs-ZPLwomw-SVqJfCA==", "aes:RzaPc0lILeQrL3k9bpoVPxzAip2r9Qc5lQ=="],
	// 					"siteId": "5bd272ab-3c49-4c69-b832-229efdb9ba59",
	// 					"uniqueClients": 40
	// 				}, {
	// 					"__typename": "ClientInsightRadios36TableType",
	// 					"aesApMac": "UiCzmEtcenRDm7IBvsM_NL-czQvXaAn8HrkZ80GDPMrY",
	// 					"aesApName": "aes:JU_3B2EcGmiSNqMmXrSXWFRXzQcOlIkuLyMmI_PA",
	// 					"apEntityId": "AIR-CAP1532I-S-K9",
	// 					"errorFlags": ["packetRetryKde"],
	// 					"frequencyBand": "2.4 GHz",
	// 					"impactedClients": 24,
	// 					"severity": "s2",
	// 					"siteHierarchyArray": ["aes:S1DpNVvZ8zYO9k1YSicp7RjfnqM=", "aes:NvCkZqu6mqhETeG5alU4LZKKZ2Y8p8Ow", "aes:WkaRN2xzvsYWJLHoL1_dI2KTAtNDVKaLXs56gxNqdIquSW16H6fY2NdYjJtJ6nR3g3KqtA==", "aes:lpNlnThvoxXbTEdb4r7LDkqGfkR3ERuX4A=="],
	// 					"siteId": "a42456d0-5d2e-4a85-8cfc-3853cf9557f3",
	// 					"uniqueClients": 24
	// 				}],
	// 				"totalCount": 2
	// 			}
	// 		}
	// 	},


	"getBaselineDetail1Template":
		{
			"data": {
				"getClientInsightAgg36Baseline": {
					"__typename": "GetClientInsightAgg36BaselineConnection",
					"nodes": [{
						"__typename": "ClientInsightAgg36BaselineType",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 486,
							"key": 2
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 85763,
							"key": 0
						}],
						"apModel": [{
							"__typename": "CiStrKeyCountValue",
							"count": 5,
							"key": "AIR-AP2802E-S-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "C9136I-B"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 5,
							"key": "AIR-AP2802I-K-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP2802E-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-AP2802E-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 9,
							"key": "AIR-AP2802I-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 3,
							"key": "AIR-AP1542I-S-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP3802I-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP3802I-R-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 25,
							"key": "AIR-AP3802I-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 23,
							"key": "AIR-AP2802I-H-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP2702E-H-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-CAP2702E-A-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 28,
							"key": "AIR-AP2802I-S-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP3802E-B-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 2,
							"key": "AIR-AP2802I-Q-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP2802E-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 9,
							"key": "AIR-AP3802I-H-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 42,
							"key": "AIR-AP3802I-Z-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 4,
							"key": "AIR-CAP2702I-H-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "AIR-AP3802I-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 13,
							"key": "AIR-AP2802I-E-K9"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "C9136I-B"
						}],
						"channelChangeCountBinSize": 2.70707070707071,
						"channelChangeCountKde": [0.992941530421143, 0.0026333376246888, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00236017494330165, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00206495701086632],
						"clientCountBinSize": 1,
						"clientCountKde": [0.364300486108175, 0.301264665271013, 0.154696489015004, 0.0670438451791951, 0.0355369548312727, 0.0234135503015118, 0.0161248562895463, 0.010825688752299, 0.007686180605664, 0.00589065813622728, 0.00442948695541482, 0.00316899715846615, 0.00239303759532971, 0.00187575133700125],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.402478626444456, 0.0577355726198513, 0.00978681542252158, 0.0197862055522195, 0.129841636853081, 0.173465387088157, 0.0243577697054681, 0.00364067969564981, 0.0212899682713645, 0.0449868668346055, 0.0508365574410671, 0.00651660948812655, 0.00276462380716949, 0.018294312218609, 0.00433200004669029, 0.0137633458565822, 0.00226860610925843, 0.00346115150752603, 0.00375353299001946, 0.00110110974424124, 0.00419193675192147, 0],
						"endTimestamp": "2021-12-01T00:00:00",
						"frequencyBand": "5 GHz",
						"impactedAps": null,
						"interferenceBinSize": 1,
						"interferenceKde": [0.178473214729092, 0.229650363505079, 0.214157609093238, 0.137497178298113, 0.0749989199720741, 0.0425405387361976, 0.0220759810021577, 0.0154245826663546, 0.0118216257326876, 0.010717592969602, 0.00892749849452453, 0.00679902711038751, 0.00590203167588298, 0.00467284333651894, 0.00417958006713348, 0.00348601217145993, 0.00342899622761841, 0.00412756115558497, 0.00351800710312523, 0.00331329969151682, 0.00199075517307222, 0.00208066384346596, 0.00176707381639584, 0.00154666350609033, 0.00187809546014723, 0.00111638421917112, 0.00143904476911974, 0.00120362484985625],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 2909,
							"key": 1
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 83340,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 2909,
							"key": 0
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 83340,
							"key": 1
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00224079637733263, 0.00856753288386793, 0.0011396846629914, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00143525559432928, 0.0563336194890672, 0.383743458754629, 0.326453123379972, 0.0938033730520737, 0.035182495844505, 0.0187297029701775, 0.00787946855327953, 0.00518031559888595, 0.00438752014697511, 0.0150405707169536, 0.014363084316567, 0.0023262986788104, 0, 0, 0, 0, 0, 0, 0, 0, 0.00247029080934739, 0.0182531173620867, 0.00247029080814912],
						"packetFailureKde": [0.499678984339543, 0.327777926740527, 0.0920828781282322, 0.0202201375206165, 0.0087783713749947, 0.00583551373376762, 0.00467290786574007, 0.00377017313319862, 0.00290720681590134, 0.00215677819481508, 0.00169712014512088, 0.00146664926523077, 0.00122116451933847, 0.00103866877295553, 0.00096874693800867, 0.000885275961228732, 0.00080425697727437, 0.000750479202624379, 0.000644887251469175, 0.000632553261337536, 0.000673695566988152, 0.000704612577201986, 0.000793498631800894, 0.000818587316475305, 0.000674016676611839, 0.000519606443467332, 0.000446262379412602, 0.000370915384105448, 0.000372697565213252, 0.000406749724680574, 0.000513434332958318, 0.000742528041063307, 0.000883534783255135, 0.000834423497304223, 0.000762097836531541, 0.000704188608706374, 0.000631534502943704, 0.000465030551070074, 0.000324370891516585, 0.000277503520793833, 0.000199271109396547, 0.000157568427683333, 0.000156064880615723, 0.000139841438617094, 0.000178711723738971, 0.000212561674487097, 0.000188877529278241, 0.000198275618588066, 0.000231874211524872, 0.000295327953088914, 0.00035401744860185, 0.000274687409851142, 0.00016494521121606, 0.000141295286614902, 0.000220317340323573, 0.000271261788650582, 0.000231941605128926, 0.000210281156972094, 0.000202205065384274, 0.000205504567446551, 0.000256570511295722, 0.00030088072702362, 0.00026162203067873, 0.000165680830573463, 0.000160939409061793, 0.000224542930916261, 0.00019053652500833, 0.0000950417042012877, 0.0000747860501464411, 0.000126329587226578, 0.000164145006654232, 0.000179647956923252, 0.000159438172651319, 0.000142851241936547, 0.000118857213579512, 0.000108483061755264, 0.000104740516634731, 0.000105895872459681, 0.000181746946996252, 0.000233965453985365, 0.000162111311232893, 0.00010650478440029, 0.000170333906239069, 0.000234732138431849],
						"packetRetryKde": [0.051211862397027, 0.0606789009377017, 0.0479641173422613, 0.0368522438006084, 0.0328516966532822, 0.0326677357401316, 0.0333595301014484, 0.034718673674485, 0.0373518990342657, 0.0398035886028863, 0.0405581839576649, 0.0409010172877479, 0.0406565360546336, 0.0381005205967556, 0.0348439326826901, 0.0326236491911962, 0.0307153936255902, 0.0284594781302545, 0.0279209934047498, 0.0277907232674049, 0.0258101566826035, 0.0231024582727109, 0.0210229640775134, 0.0195325994048743, 0.0184203421943897, 0.0177565784987865, 0.0166688331153067, 0.0149770892185142, 0.0138357991847487, 0.0130687087156109, 0.0118948260054633, 0.0105283996833325, 0.00870034042475961, 0.006772018898189, 0.00545774269190928, 0.00443482945635997, 0.00334114964895473, 0.00241237389095716, 0.00174475237321513, 0.00132241285623871, 0.00100950658536373, 0.000780413826954502, 0.000597535780953172, 0.00049916804208688, 0.000500671886333062, 0.000451443926391293, 0.000412564987404814, 0.000362606940440569, 0.000268813797332845, 0.000246618316482315, 0.000257350293819604, 0.000164190113600911, 0.000074366609336311, 0.0000604274805296976, 0.0000890224748838093, 0.000121031465745547, 0.000103228260105993, 0.000125287682761525, 0.000181576187947586, 0.0002098871171147, 0.000255132352832128, 0.000232272968181158, 0.000123837178713497, 0.000067321142935633],
						"powerBinSize": 1,
						"powerKde": [0.00673116584396825, 0.143167018381059, 0.402921736714156, 0.163678723271597, 0.0315505046220671, 0.0374189726358965, 0.0168911531380125, 0.0107092967089386, 0.0176414897353652, 0.00785883851871437, 0.00568295971110144, 0.0138805508544347, 0.00738329987640777, 0.00270564801123764, 0.00498404323878394, 0.00599190068646442, 0.0198122758576134, 0.0410207723441682, 0.0167160251321141, 0.00875481345425659, 0.00572917344500606, 0.00234273586118631, 0.00823270920164118, 0.0181941927558089],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 553,
							"key": 6
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 85696,
							"key": 4
						}],
						"primaryImageVersion": [{
							"__typename": "CiStrKeyCountValue",
							"count": 152,
							"key": "8.5.161.6"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 4,
							"key": "8.5.171.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 3,
							"key": "8.5.135.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 21,
							"key": "8.10.162.0"
						}, {
							"__typename": "CiStrKeyCountValue",
							"count": 1,
							"key": "8.5.151.0"
						}],
						"rootCauseCategory": "BASELINE",
						"rootCauseCategoryId": "a7057b7f6c41ae5b0849",
						"rssiKde": [0.00228722614533122, 0.00361204307057783, 0.00461960630168116, 0.00559792522670948, 0.00630999900027681, 0.0049412312296343, 0.00383599330656238, 0.00426347328737998, 0.0049188043702432, 0.00540932623139927, 0.00547448720621949, 0.00492946812526198, 0.00494358444861878, 0.00564207441009032, 0.00612375779972106, 0.00623811186395784, 0.00659264234674369, 0.00771516592138349, 0.00884836855067375, 0.0104544161287588, 0.0120708663951902, 0.0128317690341029, 0.01455871610839, 0.0166295188342482, 0.0179340695051863, 0.0199499481679265, 0.0215687515555645, 0.0220849553268265, 0.0204510788291135, 0.0208583017827073, 0.0244187798305614, 0.0264414345553293, 0.0288014702062535, 0.0320097876408568, 0.0355041828009518, 0.0365953573404945, 0.0356253042761225, 0.0379007010352962, 0.038662811612201, 0.0369907604846548, 0.0349554989179835, 0.0327275632343297, 0.0319152693470504, 0.0311701885383675, 0.0312487568883958, 0.0309744958201509, 0.0305416336816259, 0.029905696562893, 0.0262858900304192, 0.0203494729895549, 0.0172389378296699, 0.0150192428227865, 0.010851249909708, 0.0073648646245038, 0.00562332207604615, 0.00481087249809789, 0.00409028821106861, 0.00362738411990257, 0.00303269489038957, 0.00225023449409817],
						"snrKde": [0, 0.00129293962377622, 0.00240129895294887, 0.0034984490793113, 0.00514069786289425, 0.00663084868639282, 0.00652162195868112, 0.00619479934923927, 0.00492168590825457, 0.00439207225060574, 0.00479588193697869, 0.00488492089669398, 0.00505658668314779, 0.00554341755603592, 0.00645385153305614, 0.00603897209844038, 0.00643240265373993, 0.00739683197065101, 0.00826429654550959, 0.00843959198452282, 0.00900294633779294, 0.0103161358957401, 0.0112142808654075, 0.0122493686430978, 0.0134585230159707, 0.014588784815563, 0.0174780460174564, 0.0199902175925533, 0.0212244955234428, 0.0230776977959163, 0.0230909737985809, 0.0217171721421258, 0.0231335407763013, 0.0260425897131705, 0.0288105966387643, 0.0317617320167886, 0.0327516712718771, 0.0321881597907784, 0.0318178875773714, 0.0348026635715921, 0.0357010574500644, 0.0352191449679511, 0.0348320941975372, 0.0328690114338452, 0.0310774588168446, 0.0315871978023166, 0.0308554040089242, 0.028383698036073, 0.0265045303365147, 0.0264296932855973, 0.0253509286581166, 0.0220953246907385, 0.0185110076192601, 0.0151099833338152, 0.0120265157880425, 0.00967532919354217, 0.00898020599959569, 0.00732006276079099, 0.00541580163276731, 0.00432205376171252, 0.00353561355415503, 0.00326752240277695, 0.00279947671992844, 0.00179519757641423, 0.00117905749332709, 0.00110141534072164],
						"speedBins": [0, 54.25, 108.5, 162.75, 217, 271.25, 325.5, 379.75, 434, 488.25, 542.5, 596.75, 651, 705.25, 759.5, 813.75, 868],
						"speedDensity": [0.114497694004411, 0.0663725686785643, 0.060156406657309, 0.131943051935031, 0.0211550030078203, 0.0435131341487868, 0.0250651694405454, 0.146681371566072, 0.000100260677762182, 0.0115299779426509, 0.0184479647082414, 0.0536394626027672, 0, 0, 0.0607579707238821, 0.246139963906156],
						"startTimestamp": "2021-11-10T12:00:00",
						"timestamp": "2021-12-01T00:13:54.717113",
						"trafficBinSize": 1,
						"trafficKde": [0.727229538236032, 0.226235635520464, 0.0245956470460657, 0.00999071509536593, 0.00532301801299383, 0.00277921959232355, 0.00221692238736791],
						"utilizationBinSize": 1,
						"utilizationKde": [0.168851469983602, 0.226045897332897, 0.209340560711145, 0.14130623092901, 0.0787080551384433, 0.0437295338674193, 0.0239435113118954, 0.0165213191558942, 0.0129285391957828, 0.0110615983075716, 0.00925183805101472, 0.00656755464616397, 0.00625861214444207, 0.0051815960652415, 0.00410665360505295, 0.00364840371944304, 0.00415049372998468, 0.00416235879768484, 0.00412791093004873, 0.00325341392006253, 0.00201548473207653, 0.00184387986604262, 0.00167636561279663, 0.00174206747917507, 0.00226039886268742, 0.00134058833551764, 0.00107611769019568, 0.00185376519906597, 0.0018755567533401]
					}],
					"totalCount": 1
				}
			}
		},
	"getApDetailforLowAPDensity1Template":
		{
			"data": {
				"getClientInsightRadios36": {
					"__typename": "GetClientInsightRadios36Connection",
					"nodes": [{
						"__typename": "ClientInsightRadios36Type",
						"aesApMac": "aes:a0:f8:49:7e:c0:57",
						"aesApName": "aes:SFO15-C9124-03",
						"apEntityId": "033a4fcc168e34e007ff",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"apModel": "C9124AXI",
						"channelChangeCountBinSize": 0.0526315789473684,
						"channelChangeCountKde": [0.083822086617284, 0.0833589823476091, 0.0819849645572028, 0.0797450794734102, 0.0767116722166308, 0.0729805072186311, 0.0686657515323493, 0.0638941873315881, 0.0587990618058376, 0.0535139877826142, 0.0481672782345456, 0.0428770374651486, 0.0377472491444952, 0.0328650061081307, 0.0282989290177146, 0.0240987299537028, 0.0202958003987504, 0.0169046461281695, 0.0139249568323512, 0.011344085833834],
						"clientCountBinSize": 1,
						"clientCountKde": [0.11231986074631, 0.127544255895216, 0.108709890672446, 0.0866803254948043, 0.0743788709639047, 0.0631732892077653, 0.0465433909920114, 0.0305264882484436, 0.0223606262509795, 0.0206160940421111, 0.0213769254685013, 0.0234176589471303, 0.0235557473203755, 0.0195064000763603, 0.0154331431762263, 0.014008557837049, 0.0142890475477616, 0.0150108980189836, 0.0146848616307581, 0.0140016486482662, 0.0142100082951852, 0.0145862051106546, 0.0145226172674651, 0.0133897646989308, 0.0104964675264401, 0.00698600815447079, 0.00478829568137632, 0.00453131299452321, 0.004716263014095, 0.00394133746058997, 0.00283555966847108, 0.00216177339860342, 0.00212048749799105, 0.00269042588234176, 0.00328589741893577, 0.00347386739250027, 0.00334113314905193, 0.00305166100828977, 0.00313372528486764, 0.00345062829493951, 0.0034597509282098, 0.00307848583628741, 0.00235174303790303],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.469641044903892, 0.0635590058618685, 0, 0.0155767629991111, 0.134001548471666, 0.158308597916441, 0.0193908143492572, 0.00234248858188444, 0.0210331473803652, 0.0387769093951492, 0.0400056540708376, 0.00484777962757196, 0.00231407677344397, 0.0169581080667789, 0.00307981872997088, 0.00581295420476014, 0, 0.00129864870312348, 0.00149013981172607, 0],
						"endTimestamp": "2022-05-04T00:00:00",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 458,
						"insightUid": "a8f0e072547510c1c3b7",
						"interferenceBinSize": 0.105263157894737,
						"interferenceKde": [0.151739273784223, 0.148544155246536, 0.139175892226052, 0.12483232121787, 0.107226411560623, 0.0882521902037884, 0.069655437630672, 0.0527865490717094, 0.038478488341738, 0.0270511701376229, 0.0184102396268241, 0.0121922645769813, 0.00791056059379053, 0.00507037123669827, 0.00324054175951569, 0.00209227793077891, 0.00136769825374197],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 632,
							"key": 1
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 4,
							"key": 0
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00135132168706628, 0.00389311471046319, 0, 0, 0, 0.00251666779592877, 0.0177636285743175, 0.00941756085639048, 0.00999195119835675, 0.0614142397780459, 0.0542904883372523, 0.0761842390651603, 0.149929275733484, 0.128515482866976, 0.088571987640219, 0.131800666691148, 0.0765434456337567, 0.0516677557196663, 0.0318451343660347, 0.0246473248215052, 0.0140500070187527, 0.0165260877837791, 0.0139835137092238, 0.0046736789259411, 0.00315027738729535, 0.00251641203334827, 0.0026449313493509, 0.0027259512099855, 0.00303193206053272, 0.00210877587765974, 0.00131318964954871, 0.00364523214690352, 0.00238007710672879, 0.00390248360036377, 0.00300316466481488],
						"packetFailureKde": null,
						"packetRetryKde": null,
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00962005683460532, 0.205423697322439, 0.56991249168591, 0.205423697322434, 0.00962005683461132],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 4
						}],
						"primaryImageVersion": "8.5.182.0",
						"rootCauseCategory": "Low density",
						"rootCauseCategoryId": "a70b30fe3321351b19dd",
						"rootCauseFeatures": [{
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgPower",
							"kpi": "powerKde",
							"weight": 0.0944625407166124
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": null,
							"feature": "model",
							"kpi": "apModel",
							"weight": 0.0749185667752443
						}],
						"rootCauseId": "746392",
						"rssiKde": [0.0638122499804476, 0.185204914485372, 0.194405855895425, 0.118960718007234, 0.0678265634911907, 0.0463860193050237, 0.0386925550150975, 0.0390393549419514, 0.0471964549350915, 0.0333229143947736, 0.0181107525774139, 0.0164716013979747, 0.0166099515993335, 0.0124269230971927, 0.0102594617962993, 0.00846837862646318, 0.0068275173830783, 0.00493140225518164, 0.00451096098689541, 0.00542639317324911, 0.0043836175987792, 0.00214800661337922, 0.00224928506779028, 0.00212486567253136, 0.00247528636337413, 0.00333842630370973, 0.00349291237439805, 0.00231948017303434, 0.00224608378944086, 0.00240720047899263, 0.00119896272331677, 0, 0, 0.00150609946556604, 0.00264524833476691, 0.00212031436337849, 0.00164267559607408, 0.00198513536295184, 0.00197144413110178, 0.00253816695748544, 0.0050877280041983, 0.00468831158438852, 0.00220725740380672, 0.00166607429028022, 0.00111041318305844, 0.00202993878118383],
						"severity": "s0",
						"siteHierarchyArray": ["Global / North America / USA / California / San Francisco / SFO15 / Flr-SFO15-1"],
						"siteId": "9cebf26a-edb4-43b5-a9b6-cf1fa30af5e5",
						"snrKde": [0.0185895227176899, 0.026437317412647, 0.0511787638904145, 0.116878106776148, 0.188901732997888, 0.168640121455706, 0.108712151981975, 0.0627695132100989, 0.0406657817514037, 0.0314168476606176, 0.0287312127900033, 0.0232876653586749, 0.0136850441668234, 0.00867780399790457, 0.0093778999618918, 0.0100821798941026, 0.00943409170630676, 0.00821079793052716, 0.00600376388953167, 0.00424296594805001, 0.00261733294487072, 0.00307773019319264, 0.0036188237241854, 0.00250626806436921, 0.00223051201669, 0.00296722802576196, 0.00278850731042144, 0.00188399323069004, 0.00114620945603339, 0.00173236185028041, 0.00204290737880699, 0.00273043950514847, 0.00284068224737248, 0.00126601031556517, 0, 0, 0.00191315608472914, 0.00294947458419955, 0.00326840631812439, 0.002443680345673, 0.00135498371875663, 0.00164277329565418, 0.00307763510698171, 0.00459722047641202, 0.00362909298092257, 0.00200263566867418, 0.00108119257536572, 0, 0, 0, 0, 0.00153147456687218],
						"speedBins": [0, 12.2253521126761, 24.4507042253521, 36.6760563380282, 48.9014084507042, 61.1267605633803, 73.3521126760563, 85.5774647887324, 97.8028169014084, 110.028169014085, 122.253521126761, 134.478873239437, 146.704225352113, 158.929577464789, 171.154929577465, 183.380281690141, 195.605633802817, 207.830985915493, 220.056338028169, 232.281690140845, 244.507042253521, 256.732394366197, 268.957746478873, 281.183098591549, 293.408450704225, 305.633802816901, 317.859154929577, 330.084507042254, 342.30985915493, 354.535211267606, 366.760563380282, 378.985915492958, 391.211267605634, 403.43661971831, 415.661971830986, 427.887323943662, 440.112676056338, 452.338028169014, 464.56338028169, 476.788732394366, 489.014084507042, 501.239436619718, 513.464788732394, 525.69014084507, 537.915492957746, 550.140845070423, 562.366197183099, 574.591549295775, 586.816901408451, 599.042253521127, 611.267605633803, 623.492957746479, 635.718309859155, 647.943661971831, 660.169014084507, 672.394366197183, 684.619718309859, 696.845070422535, 709.070422535211, 721.295774647887, 733.521126760563, 745.746478873239, 757.971830985916, 770.197183098591, 782.422535211268, 794.647887323944, 806.87323943662, 819.098591549296, 831.323943661972, 843.549295774648, 855.774647887324, 868],
						"speedDensity": [0.19865571321882, 0.015932287776948, 0.380383370674633, 0.0019915359721185, 0.00124470998257406, 0.101319392581528, 0, 0.0283793876026886, 0, 0.000248941996514812, 0.0268857356235997, 0.00398307194423699, 0.000248941996514812, 0, 0, 0.0428180234005477, 0.000746825989544436, 0, 0, 0, 0, 0.0206621857107294, 0, 0.00448095593726662, 0, 0, 0.00896191187453325, 0, 0, 0, 0, 0.0194174757281554, 0, 0, 0, 0.00771720189195915, 0, 0, 0, 0, 0, 0, 0.00995767986059245, 0, 0, 0, 0, 0.0253920836445108, 0, 0, 0, 0, 0, 0.035598705501618, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0204132437142145, 0, 0, 0, 0, 0, 0, 0.0445606173761512],
						"startTimestamp": "2022-04-07T04:00:00",
						"sumRadioAppCount": 201,
						"timestamp": "2022-05-04T00:01:04.900162",
						"trafficBinSize": 0.421052631578947,
						"trafficKde": [0.0710729656726055, 0.178184023801038, 0.273961914466051, 0.258041184389155, 0.14927526516272, 0.0535852606368312, 0.0123569182273704, 0.00211376915806134],
						"uniqueClients": 515,
						"utilizationBinSize": 0.526315789473684,
						"utilizationKde": [0.0561381281401066, 0.264890641508818, 0.413460060426546, 0.216219922867042, 0.0417731319419598, 0.00506792951911567, 0.00112206484549827]
					}],
					"totalCount": 1
				}
			}
		},
		"getApDetailforLowAPDensity2Template":
		{
			"data": {
				"getClientInsightRadios36": {
					"__typename": "GetClientInsightRadios36Connection",
					"nodes": [{
						"__typename": "ClientInsightRadios36Type",
						"aesApMac": "aes:a0:f8:49:7e:c0:55",
						"aesApName": "aes:AP9120_3",
						"apEntityId": "033a4fcc168e34e007ff",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"apModel": "C9120AXP-B",
						"channelChangeCountBinSize": 0.0526315789473684,
						"channelChangeCountKde": [0.083822086617284, 0.0833589823476091, 0.0819849645572028, 0.0797450794734102, 0.0767116722166308, 0.0729805072186311, 0.0686657515323493, 0.0638941873315881, 0.0587990618058376, 0.0535139877826142, 0.0481672782345456, 0.0428770374651486, 0.0377472491444952, 0.0328650061081307, 0.0282989290177146, 0.0240987299537028, 0.0202958003987504, 0.0169046461281695, 0.0139249568323512, 0.011344085833834],
						"clientCountBinSize": 1,
						"clientCountKde": [0.11231986074631, 0.127544255895216, 0.108709890672446, 0.0866803254948043, 0.0743788709639047, 0.0631732892077653, 0.0465433909920114, 0.0305264882484436, 0.0223606262509795, 0.0206160940421111, 0.0213769254685013, 0.0234176589471303, 0.0235557473203755, 0.0195064000763603, 0.0154331431762263, 0.014008557837049, 0.0142890475477616, 0.0150108980189836, 0.0146848616307581, 0.0140016486482662, 0.0142100082951852, 0.0145862051106546, 0.0145226172674651, 0.0133897646989308, 0.0104964675264401, 0.00698600815447079, 0.00478829568137632, 0.00453131299452321, 0.004716263014095, 0.00394133746058997, 0.00283555966847108, 0.00216177339860342, 0.00212048749799105, 0.00269042588234176, 0.00328589741893577, 0.00347386739250027, 0.00334113314905193, 0.00305166100828977, 0.00313372528486764, 0.00345062829493951, 0.0034597509282098, 0.00307848583628741, 0.00235174303790303],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.469641044903892, 0.0635590058618685, 0, 0.0155767629991111, 0.134001548471666, 0.158308597916441, 0.0193908143492572, 0.00234248858188444, 0.0210331473803652, 0.0387769093951492, 0.0400056540708376, 0.00484777962757196, 0.00231407677344397, 0.0169581080667789, 0.00307981872997088, 0.00581295420476014, 0, 0.00129864870312348, 0.00149013981172607, 0],
						"endTimestamp": "2022-05-04T00:00:00",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 458,
						"insightUid": "a8f0e072547510c1c3b7",
						"interferenceBinSize": 0.105263157894737,
						"interferenceKde": [0.151739273784223, 0.148544155246536, 0.139175892226052, 0.12483232121787, 0.107226411560623, 0.0882521902037884, 0.069655437630672, 0.0527865490717094, 0.038478488341738, 0.0270511701376229, 0.0184102396268241, 0.0121922645769813, 0.00791056059379053, 0.00507037123669827, 0.00324054175951569, 0.00209227793077891, 0.00136769825374197],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 632,
							"key": 1
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 4,
							"key": 0
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00135132168706628, 0.00389311471046319, 0, 0, 0, 0.00251666779592877, 0.0177636285743175, 0.00941756085639048, 0.00999195119835675, 0.0614142397780459, 0.0542904883372523, 0.0761842390651603, 0.149929275733484, 0.128515482866976, 0.088571987640219, 0.131800666691148, 0.0765434456337567, 0.0516677557196663, 0.0318451343660347, 0.0246473248215052, 0.0140500070187527, 0.0165260877837791, 0.0139835137092238, 0.0046736789259411, 0.00315027738729535, 0.00251641203334827, 0.0026449313493509, 0.0027259512099855, 0.00303193206053272, 0.00210877587765974, 0.00131318964954871, 0.00364523214690352, 0.00238007710672879, 0.00390248360036377, 0.00300316466481488],
						"packetFailureKde": null,
						"packetRetryKde": null,
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00962005683460532, 0.205423697322439, 0.56991249168591, 0.205423697322434, 0.00962005683461132],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 4
						}],
						"primaryImageVersion": "8.5.182.0",
						"rootCauseCategory": "Low density",
						"rootCauseCategoryId": "a70b30fe3321351b19dd",
						"rootCauseFeatures": [{
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgPower",
							"kpi": "powerKde",
							"weight": 0.0944625407166124
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": null,
							"feature": "model",
							"kpi": "apModel",
							"weight": 0.0749185667752443
						}],
						"rootCauseId": "746392",
						"rssiKde": [0.0638122499804476, 0.185204914485372, 0.194405855895425, 0.118960718007234, 0.0678265634911907, 0.0463860193050237, 0.0386925550150975, 0.0390393549419514, 0.0471964549350915, 0.0333229143947736, 0.0181107525774139, 0.0164716013979747, 0.0166099515993335, 0.0124269230971927, 0.0102594617962993, 0.00846837862646318, 0.0068275173830783, 0.00493140225518164, 0.00451096098689541, 0.00542639317324911, 0.0043836175987792, 0.00214800661337922, 0.00224928506779028, 0.00212486567253136, 0.00247528636337413, 0.00333842630370973, 0.00349291237439805, 0.00231948017303434, 0.00224608378944086, 0.00240720047899263, 0.00119896272331677, 0, 0, 0.00150609946556604, 0.00264524833476691, 0.00212031436337849, 0.00164267559607408, 0.00198513536295184, 0.00197144413110178, 0.00253816695748544, 0.0050877280041983, 0.00468831158438852, 0.00220725740380672, 0.00166607429028022, 0.00111041318305844, 0.00202993878118383],
						"severity": "s0",
						"siteHierarchyArray": ["Global / North America / USA / California / San Jose / SJC01 / Flr-SJC1-1"],
						"siteId": "9cebf26a-edb4-43b5-a9b6-cf1fa30af5e5",
						"snrKde": [0.0185895227176899, 0.026437317412647, 0.0511787638904145, 0.116878106776148, 0.188901732997888, 0.168640121455706, 0.108712151981975, 0.0627695132100989, 0.0406657817514037, 0.0314168476606176, 0.0287312127900033, 0.0232876653586749, 0.0136850441668234, 0.00867780399790457, 0.0093778999618918, 0.0100821798941026, 0.00943409170630676, 0.00821079793052716, 0.00600376388953167, 0.00424296594805001, 0.00261733294487072, 0.00307773019319264, 0.0036188237241854, 0.00250626806436921, 0.00223051201669, 0.00296722802576196, 0.00278850731042144, 0.00188399323069004, 0.00114620945603339, 0.00173236185028041, 0.00204290737880699, 0.00273043950514847, 0.00284068224737248, 0.00126601031556517, 0, 0, 0.00191315608472914, 0.00294947458419955, 0.00326840631812439, 0.002443680345673, 0.00135498371875663, 0.00164277329565418, 0.00307763510698171, 0.00459722047641202, 0.00362909298092257, 0.00200263566867418, 0.00108119257536572, 0, 0, 0, 0, 0.00153147456687218],
						"speedBins": [0, 12.2253521126761, 24.4507042253521, 36.6760563380282, 48.9014084507042, 61.1267605633803, 73.3521126760563, 85.5774647887324, 97.8028169014084, 110.028169014085, 122.253521126761, 134.478873239437, 146.704225352113, 158.929577464789, 171.154929577465, 183.380281690141, 195.605633802817, 207.830985915493, 220.056338028169, 232.281690140845, 244.507042253521, 256.732394366197, 268.957746478873, 281.183098591549, 293.408450704225, 305.633802816901, 317.859154929577, 330.084507042254, 342.30985915493, 354.535211267606, 366.760563380282, 378.985915492958, 391.211267605634, 403.43661971831, 415.661971830986, 427.887323943662, 440.112676056338, 452.338028169014, 464.56338028169, 476.788732394366, 489.014084507042, 501.239436619718, 513.464788732394, 525.69014084507, 537.915492957746, 550.140845070423, 562.366197183099, 574.591549295775, 586.816901408451, 599.042253521127, 611.267605633803, 623.492957746479, 635.718309859155, 647.943661971831, 660.169014084507, 672.394366197183, 684.619718309859, 696.845070422535, 709.070422535211, 721.295774647887, 733.521126760563, 745.746478873239, 757.971830985916, 770.197183098591, 782.422535211268, 794.647887323944, 806.87323943662, 819.098591549296, 831.323943661972, 843.549295774648, 855.774647887324, 868],
						"speedDensity": [0.19865571321882, 0.015932287776948, 0.380383370674633, 0.0019915359721185, 0.00124470998257406, 0.101319392581528, 0, 0.0283793876026886, 0, 0.000248941996514812, 0.0268857356235997, 0.00398307194423699, 0.000248941996514812, 0, 0, 0.0428180234005477, 0.000746825989544436, 0, 0, 0, 0, 0.0206621857107294, 0, 0.00448095593726662, 0, 0, 0.00896191187453325, 0, 0, 0, 0, 0.0194174757281554, 0, 0, 0, 0.00771720189195915, 0, 0, 0, 0, 0, 0, 0.00995767986059245, 0, 0, 0, 0, 0.0253920836445108, 0, 0, 0, 0, 0, 0.035598705501618, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0204132437142145, 0, 0, 0, 0, 0, 0, 0.0445606173761512],
						"startTimestamp": "2022-04-07T04:00:00",
						"sumRadioAppCount": 201,
						"timestamp": "2022-05-04T00:01:04.900162",
						"trafficBinSize": 0.421052631578947,
						"trafficKde": [0.0710729656726055, 0.178184023801038, 0.273961914466051, 0.258041184389155, 0.14927526516272, 0.0535852606368312, 0.0123569182273704, 0.00211376915806134],
						"uniqueClients": 515,
						"utilizationBinSize": 0.526315789473684,
						"utilizationKde": [0.0561381281401066, 0.264890641508818, 0.413460060426546, 0.216219922867042, 0.0417731319419598, 0.00506792951911567, 0.00112206484549827]
					}],
					"totalCount": 1
				}
			}
		},
		"getApDetailforLowAPDensity3Template":
		{
			"data": {
				"getClientInsightRadios36": {
					"__typename": "GetClientInsightRadios36Connection",
					"nodes": [{
						"__typename": "ClientInsightRadios36Type",
						"aesApMac": "aes:a0:f8:49:7e:c0:56",
						"aesApName": "aes:AP4800",
						"apEntityId": "033a4fcc168e34e007ff",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"apModel": "AIR-AP4800-B-K9",
						"channelChangeCountBinSize": 0.0526315789473684,
						"channelChangeCountKde": [0.083822086617284, 0.0833589823476091, 0.0819849645572028, 0.0797450794734102, 0.0767116722166308, 0.0729805072186311, 0.0686657515323493, 0.0638941873315881, 0.0587990618058376, 0.0535139877826142, 0.0481672782345456, 0.0428770374651486, 0.0377472491444952, 0.0328650061081307, 0.0282989290177146, 0.0240987299537028, 0.0202958003987504, 0.0169046461281695, 0.0139249568323512, 0.011344085833834],
						"clientCountBinSize": 1,
						"clientCountKde": [0.11231986074631, 0.127544255895216, 0.108709890672446, 0.0866803254948043, 0.0743788709639047, 0.0631732892077653, 0.0465433909920114, 0.0305264882484436, 0.0223606262509795, 0.0206160940421111, 0.0213769254685013, 0.0234176589471303, 0.0235557473203755, 0.0195064000763603, 0.0154331431762263, 0.014008557837049, 0.0142890475477616, 0.0150108980189836, 0.0146848616307581, 0.0140016486482662, 0.0142100082951852, 0.0145862051106546, 0.0145226172674651, 0.0133897646989308, 0.0104964675264401, 0.00698600815447079, 0.00478829568137632, 0.00453131299452321, 0.004716263014095, 0.00394133746058997, 0.00283555966847108, 0.00216177339860342, 0.00212048749799105, 0.00269042588234176, 0.00328589741893577, 0.00347386739250027, 0.00334113314905193, 0.00305166100828977, 0.00313372528486764, 0.00345062829493951, 0.0034597509282098, 0.00307848583628741, 0.00235174303790303],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.469641044903892, 0.0635590058618685, 0, 0.0155767629991111, 0.134001548471666, 0.158308597916441, 0.0193908143492572, 0.00234248858188444, 0.0210331473803652, 0.0387769093951492, 0.0400056540708376, 0.00484777962757196, 0.00231407677344397, 0.0169581080667789, 0.00307981872997088, 0.00581295420476014, 0, 0.00129864870312348, 0.00149013981172607, 0],
						"endTimestamp": "2022-05-04T00:00:00",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 458,
						"insightUid": "a8f0e072547510c1c3b7",
						"interferenceBinSize": 0.105263157894737,
						"interferenceKde": [0.151739273784223, 0.148544155246536, 0.139175892226052, 0.12483232121787, 0.107226411560623, 0.0882521902037884, 0.069655437630672, 0.0527865490717094, 0.038478488341738, 0.0270511701376229, 0.0184102396268241, 0.0121922645769813, 0.00791056059379053, 0.00507037123669827, 0.00324054175951569, 0.00209227793077891, 0.00136769825374197],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 632,
							"key": 1
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 4,
							"key": 0
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00135132168706628, 0.00389311471046319, 0, 0, 0, 0.00251666779592877, 0.0177636285743175, 0.00941756085639048, 0.00999195119835675, 0.0614142397780459, 0.0542904883372523, 0.0761842390651603, 0.149929275733484, 0.128515482866976, 0.088571987640219, 0.131800666691148, 0.0765434456337567, 0.0516677557196663, 0.0318451343660347, 0.0246473248215052, 0.0140500070187527, 0.0165260877837791, 0.0139835137092238, 0.0046736789259411, 0.00315027738729535, 0.00251641203334827, 0.0026449313493509, 0.0027259512099855, 0.00303193206053272, 0.00210877587765974, 0.00131318964954871, 0.00364523214690352, 0.00238007710672879, 0.00390248360036377, 0.00300316466481488],
						"packetFailureKde": null,
						"packetRetryKde": null,
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00962005683460532, 0.205423697322439, 0.56991249168591, 0.205423697322434, 0.00962005683461132],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 4
						}],
						"primaryImageVersion": "8.5.182.0",
						"rootCauseCategory": "Low density",
						"rootCauseCategoryId": "a70b30fe3321351b19dd",
						"rootCauseFeatures": [{
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgPower",
							"kpi": "powerKde",
							"weight": 0.0944625407166124
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": null,
							"feature": "model",
							"kpi": "apModel",
							"weight": 0.0749185667752443
						}],
						"rootCauseId": "746392",
						"rssiKde": [0.0638122499804476, 0.185204914485372, 0.194405855895425, 0.118960718007234, 0.0678265634911907, 0.0463860193050237, 0.0386925550150975, 0.0390393549419514, 0.0471964549350915, 0.0333229143947736, 0.0181107525774139, 0.0164716013979747, 0.0166099515993335, 0.0124269230971927, 0.0102594617962993, 0.00846837862646318, 0.0068275173830783, 0.00493140225518164, 0.00451096098689541, 0.00542639317324911, 0.0043836175987792, 0.00214800661337922, 0.00224928506779028, 0.00212486567253136, 0.00247528636337413, 0.00333842630370973, 0.00349291237439805, 0.00231948017303434, 0.00224608378944086, 0.00240720047899263, 0.00119896272331677, 0, 0, 0.00150609946556604, 0.00264524833476691, 0.00212031436337849, 0.00164267559607408, 0.00198513536295184, 0.00197144413110178, 0.00253816695748544, 0.0050877280041983, 0.00468831158438852, 0.00220725740380672, 0.00166607429028022, 0.00111041318305844, 0.00202993878118383],
						"severity": "s0",
						"siteHierarchyArray": ["Global / North America / USA / California / San Jose / SJC01 / Flr-SJC1-1"],
						"siteId": "9cebf26a-edb4-43b5-a9b6-cf1fa30af5e5",
						"snrKde": [0.0185895227176899, 0.026437317412647, 0.0511787638904145, 0.116878106776148, 0.188901732997888, 0.168640121455706, 0.108712151981975, 0.0627695132100989, 0.0406657817514037, 0.0314168476606176, 0.0287312127900033, 0.0232876653586749, 0.0136850441668234, 0.00867780399790457, 0.0093778999618918, 0.0100821798941026, 0.00943409170630676, 0.00821079793052716, 0.00600376388953167, 0.00424296594805001, 0.00261733294487072, 0.00307773019319264, 0.0036188237241854, 0.00250626806436921, 0.00223051201669, 0.00296722802576196, 0.00278850731042144, 0.00188399323069004, 0.00114620945603339, 0.00173236185028041, 0.00204290737880699, 0.00273043950514847, 0.00284068224737248, 0.00126601031556517, 0, 0, 0.00191315608472914, 0.00294947458419955, 0.00326840631812439, 0.002443680345673, 0.00135498371875663, 0.00164277329565418, 0.00307763510698171, 0.00459722047641202, 0.00362909298092257, 0.00200263566867418, 0.00108119257536572, 0, 0, 0, 0, 0.00153147456687218],
						"speedBins": [0, 12.2253521126761, 24.4507042253521, 36.6760563380282, 48.9014084507042, 61.1267605633803, 73.3521126760563, 85.5774647887324, 97.8028169014084, 110.028169014085, 122.253521126761, 134.478873239437, 146.704225352113, 158.929577464789, 171.154929577465, 183.380281690141, 195.605633802817, 207.830985915493, 220.056338028169, 232.281690140845, 244.507042253521, 256.732394366197, 268.957746478873, 281.183098591549, 293.408450704225, 305.633802816901, 317.859154929577, 330.084507042254, 342.30985915493, 354.535211267606, 366.760563380282, 378.985915492958, 391.211267605634, 403.43661971831, 415.661971830986, 427.887323943662, 440.112676056338, 452.338028169014, 464.56338028169, 476.788732394366, 489.014084507042, 501.239436619718, 513.464788732394, 525.69014084507, 537.915492957746, 550.140845070423, 562.366197183099, 574.591549295775, 586.816901408451, 599.042253521127, 611.267605633803, 623.492957746479, 635.718309859155, 647.943661971831, 660.169014084507, 672.394366197183, 684.619718309859, 696.845070422535, 709.070422535211, 721.295774647887, 733.521126760563, 745.746478873239, 757.971830985916, 770.197183098591, 782.422535211268, 794.647887323944, 806.87323943662, 819.098591549296, 831.323943661972, 843.549295774648, 855.774647887324, 868],
						"speedDensity": [0.19865571321882, 0.015932287776948, 0.380383370674633, 0.0019915359721185, 0.00124470998257406, 0.101319392581528, 0, 0.0283793876026886, 0, 0.000248941996514812, 0.0268857356235997, 0.00398307194423699, 0.000248941996514812, 0, 0, 0.0428180234005477, 0.000746825989544436, 0, 0, 0, 0, 0.0206621857107294, 0, 0.00448095593726662, 0, 0, 0.00896191187453325, 0, 0, 0, 0, 0.0194174757281554, 0, 0, 0, 0.00771720189195915, 0, 0, 0, 0, 0, 0, 0.00995767986059245, 0, 0, 0, 0, 0.0253920836445108, 0, 0, 0, 0, 0, 0.035598705501618, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0204132437142145, 0, 0, 0, 0, 0, 0, 0.0445606173761512],
						"startTimestamp": "2022-04-07T04:00:00",
						"sumRadioAppCount": 201,
						"timestamp": "2022-05-04T00:01:04.900162",
						"trafficBinSize": 0.421052631578947,
						"trafficKde": [0.0710729656726055, 0.178184023801038, 0.273961914466051, 0.258041184389155, 0.14927526516272, 0.0535852606368312, 0.0123569182273704, 0.00211376915806134],
						"uniqueClients": 515,
						"utilizationBinSize": 0.526315789473684,
						"utilizationKde": [0.0561381281401066, 0.264890641508818, 0.413460060426546, 0.216219922867042, 0.0417731319419598, 0.00506792951911567, 0.00112206484549827]
					}],
					"totalCount": 1
				}
			}
		},
		"getApDetailforLowAPDensity4Template":
		{
			"data": {
				"getClientInsightRadios36": {
					"__typename": "GetClientInsightRadios36Connection",
					"nodes": [{
						"__typename": "ClientInsightRadios36Type",
						"aesApMac": "aes:a0:f8:49:7e:c0:53",
						"aesApName": "aes:AP9120_2",
						"apEntityId": "033a4fcc168e34e007ff",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"apModel": "C9120AXP-B",
						"channelChangeCountBinSize": 0.0526315789473684,
						"channelChangeCountKde": [0.083822086617284, 0.0833589823476091, 0.0819849645572028, 0.0797450794734102, 0.0767116722166308, 0.0729805072186311, 0.0686657515323493, 0.0638941873315881, 0.0587990618058376, 0.0535139877826142, 0.0481672782345456, 0.0428770374651486, 0.0377472491444952, 0.0328650061081307, 0.0282989290177146, 0.0240987299537028, 0.0202958003987504, 0.0169046461281695, 0.0139249568323512, 0.011344085833834],
						"clientCountBinSize": 1,
						"clientCountKde": [0.11231986074631, 0.127544255895216, 0.108709890672446, 0.0866803254948043, 0.0743788709639047, 0.0631732892077653, 0.0465433909920114, 0.0305264882484436, 0.0223606262509795, 0.0206160940421111, 0.0213769254685013, 0.0234176589471303, 0.0235557473203755, 0.0195064000763603, 0.0154331431762263, 0.014008557837049, 0.0142890475477616, 0.0150108980189836, 0.0146848616307581, 0.0140016486482662, 0.0142100082951852, 0.0145862051106546, 0.0145226172674651, 0.0133897646989308, 0.0104964675264401, 0.00698600815447079, 0.00478829568137632, 0.00453131299452321, 0.004716263014095, 0.00394133746058997, 0.00283555966847108, 0.00216177339860342, 0.00212048749799105, 0.00269042588234176, 0.00328589741893577, 0.00347386739250027, 0.00334113314905193, 0.00305166100828977, 0.00313372528486764, 0.00345062829493951, 0.0034597509282098, 0.00307848583628741, 0.00235174303790303],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.469641044903892, 0.0635590058618685, 0, 0.0155767629991111, 0.134001548471666, 0.158308597916441, 0.0193908143492572, 0.00234248858188444, 0.0210331473803652, 0.0387769093951492, 0.0400056540708376, 0.00484777962757196, 0.00231407677344397, 0.0169581080667789, 0.00307981872997088, 0.00581295420476014, 0, 0.00129864870312348, 0.00149013981172607, 0],
						"endTimestamp": "2022-05-04T00:00:00",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 458,
						"insightUid": "a8f0e072547510c1c3b7",
						"interferenceBinSize": 0.105263157894737,
						"interferenceKde": [0.151739273784223, 0.148544155246536, 0.139175892226052, 0.12483232121787, 0.107226411560623, 0.0882521902037884, 0.069655437630672, 0.0527865490717094, 0.038478488341738, 0.0270511701376229, 0.0184102396268241, 0.0121922645769813, 0.00791056059379053, 0.00507037123669827, 0.00324054175951569, 0.00209227793077891, 0.00136769825374197],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 632,
							"key": 1
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 4,
							"key": 0
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00135132168706628, 0.00389311471046319, 0, 0, 0, 0.00251666779592877, 0.0177636285743175, 0.00941756085639048, 0.00999195119835675, 0.0614142397780459, 0.0542904883372523, 0.0761842390651603, 0.149929275733484, 0.128515482866976, 0.088571987640219, 0.131800666691148, 0.0765434456337567, 0.0516677557196663, 0.0318451343660347, 0.0246473248215052, 0.0140500070187527, 0.0165260877837791, 0.0139835137092238, 0.0046736789259411, 0.00315027738729535, 0.00251641203334827, 0.0026449313493509, 0.0027259512099855, 0.00303193206053272, 0.00210877587765974, 0.00131318964954871, 0.00364523214690352, 0.00238007710672879, 0.00390248360036377, 0.00300316466481488],
						"packetFailureKde": null,
						"packetRetryKde": null,
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00962005683460532, 0.205423697322439, 0.56991249168591, 0.205423697322434, 0.00962005683461132],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 4
						}],
						"primaryImageVersion": "8.5.182.0",
						"rootCauseCategory": "Low density",
						"rootCauseCategoryId": "a70b30fe3321351b19dd",
						"rootCauseFeatures": [{
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgPower",
							"kpi": "powerKde",
							"weight": 0.0944625407166124
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": null,
							"feature": "model",
							"kpi": "apModel",
							"weight": 0.0749185667752443
						}],
						"rootCauseId": "746392",
						"rssiKde": [0.0638122499804476, 0.185204914485372, 0.194405855895425, 0.118960718007234, 0.0678265634911907, 0.0463860193050237, 0.0386925550150975, 0.0390393549419514, 0.0471964549350915, 0.0333229143947736, 0.0181107525774139, 0.0164716013979747, 0.0166099515993335, 0.0124269230971927, 0.0102594617962993, 0.00846837862646318, 0.0068275173830783, 0.00493140225518164, 0.00451096098689541, 0.00542639317324911, 0.0043836175987792, 0.00214800661337922, 0.00224928506779028, 0.00212486567253136, 0.00247528636337413, 0.00333842630370973, 0.00349291237439805, 0.00231948017303434, 0.00224608378944086, 0.00240720047899263, 0.00119896272331677, 0, 0, 0.00150609946556604, 0.00264524833476691, 0.00212031436337849, 0.00164267559607408, 0.00198513536295184, 0.00197144413110178, 0.00253816695748544, 0.0050877280041983, 0.00468831158438852, 0.00220725740380672, 0.00166607429028022, 0.00111041318305844, 0.00202993878118383],
						"severity": "s0",
						"siteHierarchyArray": ["Global / North America / USA / California / San Jose / SJC01 / Flr-SJC1-1"],
						"siteId": "9cebf26a-edb4-43b5-a9b6-cf1fa30af5e5",
						"snrKde": [0.0185895227176899, 0.026437317412647, 0.0511787638904145, 0.116878106776148, 0.188901732997888, 0.168640121455706, 0.108712151981975, 0.0627695132100989, 0.0406657817514037, 0.0314168476606176, 0.0287312127900033, 0.0232876653586749, 0.0136850441668234, 0.00867780399790457, 0.0093778999618918, 0.0100821798941026, 0.00943409170630676, 0.00821079793052716, 0.00600376388953167, 0.00424296594805001, 0.00261733294487072, 0.00307773019319264, 0.0036188237241854, 0.00250626806436921, 0.00223051201669, 0.00296722802576196, 0.00278850731042144, 0.00188399323069004, 0.00114620945603339, 0.00173236185028041, 0.00204290737880699, 0.00273043950514847, 0.00284068224737248, 0.00126601031556517, 0, 0, 0.00191315608472914, 0.00294947458419955, 0.00326840631812439, 0.002443680345673, 0.00135498371875663, 0.00164277329565418, 0.00307763510698171, 0.00459722047641202, 0.00362909298092257, 0.00200263566867418, 0.00108119257536572, 0, 0, 0, 0, 0.00153147456687218],
						"speedBins": [0, 12.2253521126761, 24.4507042253521, 36.6760563380282, 48.9014084507042, 61.1267605633803, 73.3521126760563, 85.5774647887324, 97.8028169014084, 110.028169014085, 122.253521126761, 134.478873239437, 146.704225352113, 158.929577464789, 171.154929577465, 183.380281690141, 195.605633802817, 207.830985915493, 220.056338028169, 232.281690140845, 244.507042253521, 256.732394366197, 268.957746478873, 281.183098591549, 293.408450704225, 305.633802816901, 317.859154929577, 330.084507042254, 342.30985915493, 354.535211267606, 366.760563380282, 378.985915492958, 391.211267605634, 403.43661971831, 415.661971830986, 427.887323943662, 440.112676056338, 452.338028169014, 464.56338028169, 476.788732394366, 489.014084507042, 501.239436619718, 513.464788732394, 525.69014084507, 537.915492957746, 550.140845070423, 562.366197183099, 574.591549295775, 586.816901408451, 599.042253521127, 611.267605633803, 623.492957746479, 635.718309859155, 647.943661971831, 660.169014084507, 672.394366197183, 684.619718309859, 696.845070422535, 709.070422535211, 721.295774647887, 733.521126760563, 745.746478873239, 757.971830985916, 770.197183098591, 782.422535211268, 794.647887323944, 806.87323943662, 819.098591549296, 831.323943661972, 843.549295774648, 855.774647887324, 868],
						"speedDensity": [0.19865571321882, 0.015932287776948, 0.380383370674633, 0.0019915359721185, 0.00124470998257406, 0.101319392581528, 0, 0.0283793876026886, 0, 0.000248941996514812, 0.0268857356235997, 0.00398307194423699, 0.000248941996514812, 0, 0, 0.0428180234005477, 0.000746825989544436, 0, 0, 0, 0, 0.0206621857107294, 0, 0.00448095593726662, 0, 0, 0.00896191187453325, 0, 0, 0, 0, 0.0194174757281554, 0, 0, 0, 0.00771720189195915, 0, 0, 0, 0, 0, 0, 0.00995767986059245, 0, 0, 0, 0, 0.0253920836445108, 0, 0, 0, 0, 0, 0.035598705501618, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0204132437142145, 0, 0, 0, 0, 0, 0, 0.0445606173761512],
						"startTimestamp": "2022-04-07T04:00:00",
						"sumRadioAppCount": 201,
						"timestamp": "2022-05-04T00:01:04.900162",
						"trafficBinSize": 0.421052631578947,
						"trafficKde": [0.0710729656726055, 0.178184023801038, 0.273961914466051, 0.258041184389155, 0.14927526516272, 0.0535852606368312, 0.0123569182273704, 0.00211376915806134],
						"uniqueClients": 515,
						"utilizationBinSize": 0.526315789473684,
						"utilizationKde": [0.0561381281401066, 0.264890641508818, 0.413460060426546, 0.216219922867042, 0.0417731319419598, 0.00506792951911567, 0.00112206484549827]
					}],
					"totalCount": 1
				}
			}
		},
		"getApDetailforLowAPDensity5Template":
		{
			"data": {
				"getClientInsightRadios36": {
					"__typename": "GetClientInsightRadios36Connection",
					"nodes": [{
						"__typename": "ClientInsightRadios36Type",
						"aesApMac": "aes:a0:f8:49:7e:c0:43",
						"aesApName": "aes:AP9115",
						"apEntityId": "033a4fcc168e34e007ff",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"apModel": "C9115AXE-B",
						"channelChangeCountBinSize": 0.0526315789473684,
						"channelChangeCountKde": [0.083822086617284, 0.0833589823476091, 0.0819849645572028, 0.0797450794734102, 0.0767116722166308, 0.0729805072186311, 0.0686657515323493, 0.0638941873315881, 0.0587990618058376, 0.0535139877826142, 0.0481672782345456, 0.0428770374651486, 0.0377472491444952, 0.0328650061081307, 0.0282989290177146, 0.0240987299537028, 0.0202958003987504, 0.0169046461281695, 0.0139249568323512, 0.011344085833834],
						"clientCountBinSize": 1,
						"clientCountKde": [0.11231986074631, 0.127544255895216, 0.108709890672446, 0.0866803254948043, 0.0743788709639047, 0.0631732892077653, 0.0465433909920114, 0.0305264882484436, 0.0223606262509795, 0.0206160940421111, 0.0213769254685013, 0.0234176589471303, 0.0235557473203755, 0.0195064000763603, 0.0154331431762263, 0.014008557837049, 0.0142890475477616, 0.0150108980189836, 0.0146848616307581, 0.0140016486482662, 0.0142100082951852, 0.0145862051106546, 0.0145226172674651, 0.0133897646989308, 0.0104964675264401, 0.00698600815447079, 0.00478829568137632, 0.00453131299452321, 0.004716263014095, 0.00394133746058997, 0.00283555966847108, 0.00216177339860342, 0.00212048749799105, 0.00269042588234176, 0.00328589741893577, 0.00347386739250027, 0.00334113314905193, 0.00305166100828977, 0.00313372528486764, 0.00345062829493951, 0.0034597509282098, 0.00307848583628741, 0.00235174303790303],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.469641044903892, 0.0635590058618685, 0, 0.0155767629991111, 0.134001548471666, 0.158308597916441, 0.0193908143492572, 0.00234248858188444, 0.0210331473803652, 0.0387769093951492, 0.0400056540708376, 0.00484777962757196, 0.00231407677344397, 0.0169581080667789, 0.00307981872997088, 0.00581295420476014, 0, 0.00129864870312348, 0.00149013981172607, 0],
						"endTimestamp": "2022-05-04T00:00:00",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 458,
						"insightUid": "a8f0e072547510c1c3b7",
						"interferenceBinSize": 0.105263157894737,
						"interferenceKde": [0.151739273784223, 0.148544155246536, 0.139175892226052, 0.12483232121787, 0.107226411560623, 0.0882521902037884, 0.069655437630672, 0.0527865490717094, 0.038478488341738, 0.0270511701376229, 0.0184102396268241, 0.0121922645769813, 0.00791056059379053, 0.00507037123669827, 0.00324054175951569, 0.00209227793077891, 0.00136769825374197],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 632,
							"key": 1
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 4,
							"key": 0
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00135132168706628, 0.00389311471046319, 0, 0, 0, 0.00251666779592877, 0.0177636285743175, 0.00941756085639048, 0.00999195119835675, 0.0614142397780459, 0.0542904883372523, 0.0761842390651603, 0.149929275733484, 0.128515482866976, 0.088571987640219, 0.131800666691148, 0.0765434456337567, 0.0516677557196663, 0.0318451343660347, 0.0246473248215052, 0.0140500070187527, 0.0165260877837791, 0.0139835137092238, 0.0046736789259411, 0.00315027738729535, 0.00251641203334827, 0.0026449313493509, 0.0027259512099855, 0.00303193206053272, 0.00210877587765974, 0.00131318964954871, 0.00364523214690352, 0.00238007710672879, 0.00390248360036377, 0.00300316466481488],
						"packetFailureKde": null,
						"packetRetryKde": null,
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00962005683460532, 0.205423697322439, 0.56991249168591, 0.205423697322434, 0.00962005683461132],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 4
						}],
						"primaryImageVersion": "8.5.182.0",
						"rootCauseCategory": "Low density",
						"rootCauseCategoryId": "a70b30fe3321351b19dd",
						"rootCauseFeatures": [{
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgPower",
							"kpi": "powerKde",
							"weight": 0.0944625407166124
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": null,
							"feature": "model",
							"kpi": "apModel",
							"weight": 0.0749185667752443
						}],
						"rootCauseId": "746392",
						"rssiKde": [0.0638122499804476, 0.185204914485372, 0.194405855895425, 0.118960718007234, 0.0678265634911907, 0.0463860193050237, 0.0386925550150975, 0.0390393549419514, 0.0471964549350915, 0.0333229143947736, 0.0181107525774139, 0.0164716013979747, 0.0166099515993335, 0.0124269230971927, 0.0102594617962993, 0.00846837862646318, 0.0068275173830783, 0.00493140225518164, 0.00451096098689541, 0.00542639317324911, 0.0043836175987792, 0.00214800661337922, 0.00224928506779028, 0.00212486567253136, 0.00247528636337413, 0.00333842630370973, 0.00349291237439805, 0.00231948017303434, 0.00224608378944086, 0.00240720047899263, 0.00119896272331677, 0, 0, 0.00150609946556604, 0.00264524833476691, 0.00212031436337849, 0.00164267559607408, 0.00198513536295184, 0.00197144413110178, 0.00253816695748544, 0.0050877280041983, 0.00468831158438852, 0.00220725740380672, 0.00166607429028022, 0.00111041318305844, 0.00202993878118383],
						"severity": "s0",
						"siteHierarchyArray": ["Global / North America / USA / California / San Jose / SJC01 / Flr-SJC1-1"],
						"siteId": "9cebf26a-edb4-43b5-a9b6-cf1fa30af5e5",
						"snrKde": [0.0185895227176899, 0.026437317412647, 0.0511787638904145, 0.116878106776148, 0.188901732997888, 0.168640121455706, 0.108712151981975, 0.0627695132100989, 0.0406657817514037, 0.0314168476606176, 0.0287312127900033, 0.0232876653586749, 0.0136850441668234, 0.00867780399790457, 0.0093778999618918, 0.0100821798941026, 0.00943409170630676, 0.00821079793052716, 0.00600376388953167, 0.00424296594805001, 0.00261733294487072, 0.00307773019319264, 0.0036188237241854, 0.00250626806436921, 0.00223051201669, 0.00296722802576196, 0.00278850731042144, 0.00188399323069004, 0.00114620945603339, 0.00173236185028041, 0.00204290737880699, 0.00273043950514847, 0.00284068224737248, 0.00126601031556517, 0, 0, 0.00191315608472914, 0.00294947458419955, 0.00326840631812439, 0.002443680345673, 0.00135498371875663, 0.00164277329565418, 0.00307763510698171, 0.00459722047641202, 0.00362909298092257, 0.00200263566867418, 0.00108119257536572, 0, 0, 0, 0, 0.00153147456687218],
						"speedBins": [0, 12.2253521126761, 24.4507042253521, 36.6760563380282, 48.9014084507042, 61.1267605633803, 73.3521126760563, 85.5774647887324, 97.8028169014084, 110.028169014085, 122.253521126761, 134.478873239437, 146.704225352113, 158.929577464789, 171.154929577465, 183.380281690141, 195.605633802817, 207.830985915493, 220.056338028169, 232.281690140845, 244.507042253521, 256.732394366197, 268.957746478873, 281.183098591549, 293.408450704225, 305.633802816901, 317.859154929577, 330.084507042254, 342.30985915493, 354.535211267606, 366.760563380282, 378.985915492958, 391.211267605634, 403.43661971831, 415.661971830986, 427.887323943662, 440.112676056338, 452.338028169014, 464.56338028169, 476.788732394366, 489.014084507042, 501.239436619718, 513.464788732394, 525.69014084507, 537.915492957746, 550.140845070423, 562.366197183099, 574.591549295775, 586.816901408451, 599.042253521127, 611.267605633803, 623.492957746479, 635.718309859155, 647.943661971831, 660.169014084507, 672.394366197183, 684.619718309859, 696.845070422535, 709.070422535211, 721.295774647887, 733.521126760563, 745.746478873239, 757.971830985916, 770.197183098591, 782.422535211268, 794.647887323944, 806.87323943662, 819.098591549296, 831.323943661972, 843.549295774648, 855.774647887324, 868],
						"speedDensity": [0.19865571321882, 0.015932287776948, 0.380383370674633, 0.0019915359721185, 0.00124470998257406, 0.101319392581528, 0, 0.0283793876026886, 0, 0.000248941996514812, 0.0268857356235997, 0.00398307194423699, 0.000248941996514812, 0, 0, 0.0428180234005477, 0.000746825989544436, 0, 0, 0, 0, 0.0206621857107294, 0, 0.00448095593726662, 0, 0, 0.00896191187453325, 0, 0, 0, 0, 0.0194174757281554, 0, 0, 0, 0.00771720189195915, 0, 0, 0, 0, 0, 0, 0.00995767986059245, 0, 0, 0, 0, 0.0253920836445108, 0, 0, 0, 0, 0, 0.035598705501618, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0204132437142145, 0, 0, 0, 0, 0, 0, 0.0445606173761512],
						"startTimestamp": "2022-04-07T04:00:00",
						"sumRadioAppCount": 201,
						"timestamp": "2022-05-04T00:01:04.900162",
						"trafficBinSize": 0.421052631578947,
						"trafficKde": [0.0710729656726055, 0.178184023801038, 0.273961914466051, 0.258041184389155, 0.14927526516272, 0.0535852606368312, 0.0123569182273704, 0.00211376915806134],
						"uniqueClients": 515,
						"utilizationBinSize": 0.526315789473684,
						"utilizationKde": [0.0561381281401066, 0.264890641508818, 0.413460060426546, 0.216219922867042, 0.0417731319419598, 0.00506792951911567, 0.00112206484549827]
					}],
					"totalCount": 1
				}
			}
		},
		"getApDetailforLowAPDensity6Template":
		{
			"data": {
				"getClientInsightRadios36": {
					"__typename": "GetClientInsightRadios36Connection",
					"nodes": [{
						"__typename": "ClientInsightRadios36Type",
						"aesApMac": "aes:a0:f8:49:7e:c0:50",
						"aesApName": "aes:SFO10-AP3800-01",
						"apEntityId": "033a4fcc168e34e007ff",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"apModel": "AIR-AP3802I-B-K9",
						"channelChangeCountBinSize": 0.0526315789473684,
						"channelChangeCountKde": [0.083822086617284, 0.0833589823476091, 0.0819849645572028, 0.0797450794734102, 0.0767116722166308, 0.0729805072186311, 0.0686657515323493, 0.0638941873315881, 0.0587990618058376, 0.0535139877826142, 0.0481672782345456, 0.0428770374651486, 0.0377472491444952, 0.0328650061081307, 0.0282989290177146, 0.0240987299537028, 0.0202958003987504, 0.0169046461281695, 0.0139249568323512, 0.011344085833834],
						"clientCountBinSize": 1,
						"clientCountKde": [0.11231986074631, 0.127544255895216, 0.108709890672446, 0.0866803254948043, 0.0743788709639047, 0.0631732892077653, 0.0465433909920114, 0.0305264882484436, 0.0223606262509795, 0.0206160940421111, 0.0213769254685013, 0.0234176589471303, 0.0235557473203755, 0.0195064000763603, 0.0154331431762263, 0.014008557837049, 0.0142890475477616, 0.0150108980189836, 0.0146848616307581, 0.0140016486482662, 0.0142100082951852, 0.0145862051106546, 0.0145226172674651, 0.0133897646989308, 0.0104964675264401, 0.00698600815447079, 0.00478829568137632, 0.00453131299452321, 0.004716263014095, 0.00394133746058997, 0.00283555966847108, 0.00216177339860342, 0.00212048749799105, 0.00269042588234176, 0.00328589741893577, 0.00347386739250027, 0.00334113314905193, 0.00305166100828977, 0.00313372528486764, 0.00345062829493951, 0.0034597509282098, 0.00307848583628741, 0.00235174303790303],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.469641044903892, 0.0635590058618685, 0, 0.0155767629991111, 0.134001548471666, 0.158308597916441, 0.0193908143492572, 0.00234248858188444, 0.0210331473803652, 0.0387769093951492, 0.0400056540708376, 0.00484777962757196, 0.00231407677344397, 0.0169581080667789, 0.00307981872997088, 0.00581295420476014, 0, 0.00129864870312348, 0.00149013981172607, 0],
						"endTimestamp": "2022-05-04T00:00:00",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 458,
						"insightUid": "a8f0e072547510c1c3b7",
						"interferenceBinSize": 0.105263157894737,
						"interferenceKde": [0.151739273784223, 0.148544155246536, 0.139175892226052, 0.12483232121787, 0.107226411560623, 0.0882521902037884, 0.069655437630672, 0.0527865490717094, 0.038478488341738, 0.0270511701376229, 0.0184102396268241, 0.0121922645769813, 0.00791056059379053, 0.00507037123669827, 0.00324054175951569, 0.00209227793077891, 0.00136769825374197],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 632,
							"key": 1
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 4,
							"key": 0
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00135132168706628, 0.00389311471046319, 0, 0, 0, 0.00251666779592877, 0.0177636285743175, 0.00941756085639048, 0.00999195119835675, 0.0614142397780459, 0.0542904883372523, 0.0761842390651603, 0.149929275733484, 0.128515482866976, 0.088571987640219, 0.131800666691148, 0.0765434456337567, 0.0516677557196663, 0.0318451343660347, 0.0246473248215052, 0.0140500070187527, 0.0165260877837791, 0.0139835137092238, 0.0046736789259411, 0.00315027738729535, 0.00251641203334827, 0.0026449313493509, 0.0027259512099855, 0.00303193206053272, 0.00210877587765974, 0.00131318964954871, 0.00364523214690352, 0.00238007710672879, 0.00390248360036377, 0.00300316466481488],
						"packetFailureKde": null,
						"packetRetryKde": null,
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00962005683460532, 0.205423697322439, 0.56991249168591, 0.205423697322434, 0.00962005683461132],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 4
						}],
						"primaryImageVersion": "8.5.182.0",
						"rootCauseCategory": "Low density",
						"rootCauseCategoryId": "a70b30fe3321351b19dd",
						"rootCauseFeatures": [{
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgPower",
							"kpi": "powerKde",
							"weight": 0.0944625407166124
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": null,
							"feature": "model",
							"kpi": "apModel",
							"weight": 0.0749185667752443
						}],
						"rootCauseId": "746392",
						"rssiKde": [0.0638122499804476, 0.185204914485372, 0.194405855895425, 0.118960718007234, 0.0678265634911907, 0.0463860193050237, 0.0386925550150975, 0.0390393549419514, 0.0471964549350915, 0.0333229143947736, 0.0181107525774139, 0.0164716013979747, 0.0166099515993335, 0.0124269230971927, 0.0102594617962993, 0.00846837862646318, 0.0068275173830783, 0.00493140225518164, 0.00451096098689541, 0.00542639317324911, 0.0043836175987792, 0.00214800661337922, 0.00224928506779028, 0.00212486567253136, 0.00247528636337413, 0.00333842630370973, 0.00349291237439805, 0.00231948017303434, 0.00224608378944086, 0.00240720047899263, 0.00119896272331677, 0, 0, 0.00150609946556604, 0.00264524833476691, 0.00212031436337849, 0.00164267559607408, 0.00198513536295184, 0.00197144413110178, 0.00253816695748544, 0.0050877280041983, 0.00468831158438852, 0.00220725740380672, 0.00166607429028022, 0.00111041318305844, 0.00202993878118383],
						"severity": "s0",
						"siteHierarchyArray": ["Global / North America / USA / California / San Francisco / SFO10 / Flr-SFO10-1"],
						"siteId": "9cebf26a-edb4-43b5-a9b6-cf1fa30af5e5",
						"snrKde": [0.0185895227176899, 0.026437317412647, 0.0511787638904145, 0.116878106776148, 0.188901732997888, 0.168640121455706, 0.108712151981975, 0.0627695132100989, 0.0406657817514037, 0.0314168476606176, 0.0287312127900033, 0.0232876653586749, 0.0136850441668234, 0.00867780399790457, 0.0093778999618918, 0.0100821798941026, 0.00943409170630676, 0.00821079793052716, 0.00600376388953167, 0.00424296594805001, 0.00261733294487072, 0.00307773019319264, 0.0036188237241854, 0.00250626806436921, 0.00223051201669, 0.00296722802576196, 0.00278850731042144, 0.00188399323069004, 0.00114620945603339, 0.00173236185028041, 0.00204290737880699, 0.00273043950514847, 0.00284068224737248, 0.00126601031556517, 0, 0, 0.00191315608472914, 0.00294947458419955, 0.00326840631812439, 0.002443680345673, 0.00135498371875663, 0.00164277329565418, 0.00307763510698171, 0.00459722047641202, 0.00362909298092257, 0.00200263566867418, 0.00108119257536572, 0, 0, 0, 0, 0.00153147456687218],
						"speedBins": [0, 12.2253521126761, 24.4507042253521, 36.6760563380282, 48.9014084507042, 61.1267605633803, 73.3521126760563, 85.5774647887324, 97.8028169014084, 110.028169014085, 122.253521126761, 134.478873239437, 146.704225352113, 158.929577464789, 171.154929577465, 183.380281690141, 195.605633802817, 207.830985915493, 220.056338028169, 232.281690140845, 244.507042253521, 256.732394366197, 268.957746478873, 281.183098591549, 293.408450704225, 305.633802816901, 317.859154929577, 330.084507042254, 342.30985915493, 354.535211267606, 366.760563380282, 378.985915492958, 391.211267605634, 403.43661971831, 415.661971830986, 427.887323943662, 440.112676056338, 452.338028169014, 464.56338028169, 476.788732394366, 489.014084507042, 501.239436619718, 513.464788732394, 525.69014084507, 537.915492957746, 550.140845070423, 562.366197183099, 574.591549295775, 586.816901408451, 599.042253521127, 611.267605633803, 623.492957746479, 635.718309859155, 647.943661971831, 660.169014084507, 672.394366197183, 684.619718309859, 696.845070422535, 709.070422535211, 721.295774647887, 733.521126760563, 745.746478873239, 757.971830985916, 770.197183098591, 782.422535211268, 794.647887323944, 806.87323943662, 819.098591549296, 831.323943661972, 843.549295774648, 855.774647887324, 868],
						"speedDensity": [0.19865571321882, 0.015932287776948, 0.380383370674633, 0.0019915359721185, 0.00124470998257406, 0.101319392581528, 0, 0.0283793876026886, 0, 0.000248941996514812, 0.0268857356235997, 0.00398307194423699, 0.000248941996514812, 0, 0, 0.0428180234005477, 0.000746825989544436, 0, 0, 0, 0, 0.0206621857107294, 0, 0.00448095593726662, 0, 0, 0.00896191187453325, 0, 0, 0, 0, 0.0194174757281554, 0, 0, 0, 0.00771720189195915, 0, 0, 0, 0, 0, 0, 0.00995767986059245, 0, 0, 0, 0, 0.0253920836445108, 0, 0, 0, 0, 0, 0.035598705501618, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0204132437142145, 0, 0, 0, 0, 0, 0, 0.0445606173761512],
						"startTimestamp": "2022-04-07T04:00:00",
						"sumRadioAppCount": 201,
						"timestamp": "2022-05-04T00:01:04.900162",
						"trafficBinSize": 0.421052631578947,
						"trafficKde": [0.0710729656726055, 0.178184023801038, 0.273961914466051, 0.258041184389155, 0.14927526516272, 0.0535852606368312, 0.0123569182273704, 0.00211376915806134],
						"uniqueClients": 515,
						"utilizationBinSize": 0.526315789473684,
						"utilizationKde": [0.0561381281401066, 0.264890641508818, 0.413460060426546, 0.216219922867042, 0.0417731319419598, 0.00506792951911567, 0.00112206484549827]
					}],
					"totalCount": 1
				}
			}
		},
"getApDetailforLowAPDensity7Template":
		{
			"data": {
				"getClientInsightRadios36": {
					"__typename": "GetClientInsightRadios36Connection",
					"nodes": [{
						"__typename": "ClientInsightRadios36Type",
						"aesApMac": "aes:a0:f8:49:7e:c0:41",
						"aesApName": "aes:AP0081.C424.3CE2",
						"apEntityId": "033a4fcc168e34e007ff",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"apModel": "AIR-AP1852I-B-K9",
						"channelChangeCountBinSize": 0.0526315789473684,
						"channelChangeCountKde": [0.083822086617284, 0.0833589823476091, 0.0819849645572028, 0.0797450794734102, 0.0767116722166308, 0.0729805072186311, 0.0686657515323493, 0.0638941873315881, 0.0587990618058376, 0.0535139877826142, 0.0481672782345456, 0.0428770374651486, 0.0377472491444952, 0.0328650061081307, 0.0282989290177146, 0.0240987299537028, 0.0202958003987504, 0.0169046461281695, 0.0139249568323512, 0.011344085833834],
						"clientCountBinSize": 1,
						"clientCountKde": [0.11231986074631, 0.127544255895216, 0.108709890672446, 0.0866803254948043, 0.0743788709639047, 0.0631732892077653, 0.0465433909920114, 0.0305264882484436, 0.0223606262509795, 0.0206160940421111, 0.0213769254685013, 0.0234176589471303, 0.0235557473203755, 0.0195064000763603, 0.0154331431762263, 0.014008557837049, 0.0142890475477616, 0.0150108980189836, 0.0146848616307581, 0.0140016486482662, 0.0142100082951852, 0.0145862051106546, 0.0145226172674651, 0.0133897646989308, 0.0104964675264401, 0.00698600815447079, 0.00478829568137632, 0.00453131299452321, 0.004716263014095, 0.00394133746058997, 0.00283555966847108, 0.00216177339860342, 0.00212048749799105, 0.00269042588234176, 0.00328589741893577, 0.00347386739250027, 0.00334113314905193, 0.00305166100828977, 0.00313372528486764, 0.00345062829493951, 0.0034597509282098, 0.00307848583628741, 0.00235174303790303],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.469641044903892, 0.0635590058618685, 0, 0.0155767629991111, 0.134001548471666, 0.158308597916441, 0.0193908143492572, 0.00234248858188444, 0.0210331473803652, 0.0387769093951492, 0.0400056540708376, 0.00484777962757196, 0.00231407677344397, 0.0169581080667789, 0.00307981872997088, 0.00581295420476014, 0, 0.00129864870312348, 0.00149013981172607, 0],
						"endTimestamp": "2022-05-04T00:00:00",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 458,
						"insightUid": "a8f0e072547510c1c3b7",
						"interferenceBinSize": 0.105263157894737,
						"interferenceKde": [0.151739273784223, 0.148544155246536, 0.139175892226052, 0.12483232121787, 0.107226411560623, 0.0882521902037884, 0.069655437630672, 0.0527865490717094, 0.038478488341738, 0.0270511701376229, 0.0184102396268241, 0.0121922645769813, 0.00791056059379053, 0.00507037123669827, 0.00324054175951569, 0.00209227793077891, 0.00136769825374197],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 632,
							"key": 1
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 4,
							"key": 0
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00135132168706628, 0.00389311471046319, 0, 0, 0, 0.00251666779592877, 0.0177636285743175, 0.00941756085639048, 0.00999195119835675, 0.0614142397780459, 0.0542904883372523, 0.0761842390651603, 0.149929275733484, 0.128515482866976, 0.088571987640219, 0.131800666691148, 0.0765434456337567, 0.0516677557196663, 0.0318451343660347, 0.0246473248215052, 0.0140500070187527, 0.0165260877837791, 0.0139835137092238, 0.0046736789259411, 0.00315027738729535, 0.00251641203334827, 0.0026449313493509, 0.0027259512099855, 0.00303193206053272, 0.00210877587765974, 0.00131318964954871, 0.00364523214690352, 0.00238007710672879, 0.00390248360036377, 0.00300316466481488],
						"packetFailureKde": null,
						"packetRetryKde": null,
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00962005683460532, 0.205423697322439, 0.56991249168591, 0.205423697322434, 0.00962005683461132],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 4
						}],
						"primaryImageVersion": "8.5.182.0",
						"rootCauseCategory": "Low density",
						"rootCauseCategoryId": "a70b30fe3321351b19dd",
						"rootCauseFeatures": [{
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgPower",
							"kpi": "powerKde",
							"weight": 0.0944625407166124
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": null,
							"feature": "model",
							"kpi": "apModel",
							"weight": 0.0749185667752443
						}],
						"rootCauseId": "746392",
						"rssiKde": [0.0638122499804476, 0.185204914485372, 0.194405855895425, 0.118960718007234, 0.0678265634911907, 0.0463860193050237, 0.0386925550150975, 0.0390393549419514, 0.0471964549350915, 0.0333229143947736, 0.0181107525774139, 0.0164716013979747, 0.0166099515993335, 0.0124269230971927, 0.0102594617962993, 0.00846837862646318, 0.0068275173830783, 0.00493140225518164, 0.00451096098689541, 0.00542639317324911, 0.0043836175987792, 0.00214800661337922, 0.00224928506779028, 0.00212486567253136, 0.00247528636337413, 0.00333842630370973, 0.00349291237439805, 0.00231948017303434, 0.00224608378944086, 0.00240720047899263, 0.00119896272331677, 0, 0, 0.00150609946556604, 0.00264524833476691, 0.00212031436337849, 0.00164267559607408, 0.00198513536295184, 0.00197144413110178, 0.00253816695748544, 0.0050877280041983, 0.00468831158438852, 0.00220725740380672, 0.00166607429028022, 0.00111041318305844, 0.00202993878118383],
						"severity": "s0",
						"siteHierarchyArray": ["Global / North America / USA / California / San Jose / SJC01 / Flr-SJC1-1"],
						"siteId": "9cebf26a-edb4-43b5-a9b6-cf1fa30af5e5",
						"snrKde": [0.0185895227176899, 0.026437317412647, 0.0511787638904145, 0.116878106776148, 0.188901732997888, 0.168640121455706, 0.108712151981975, 0.0627695132100989, 0.0406657817514037, 0.0314168476606176, 0.0287312127900033, 0.0232876653586749, 0.0136850441668234, 0.00867780399790457, 0.0093778999618918, 0.0100821798941026, 0.00943409170630676, 0.00821079793052716, 0.00600376388953167, 0.00424296594805001, 0.00261733294487072, 0.00307773019319264, 0.0036188237241854, 0.00250626806436921, 0.00223051201669, 0.00296722802576196, 0.00278850731042144, 0.00188399323069004, 0.00114620945603339, 0.00173236185028041, 0.00204290737880699, 0.00273043950514847, 0.00284068224737248, 0.00126601031556517, 0, 0, 0.00191315608472914, 0.00294947458419955, 0.00326840631812439, 0.002443680345673, 0.00135498371875663, 0.00164277329565418, 0.00307763510698171, 0.00459722047641202, 0.00362909298092257, 0.00200263566867418, 0.00108119257536572, 0, 0, 0, 0, 0.00153147456687218],
						"speedBins": [0, 12.2253521126761, 24.4507042253521, 36.6760563380282, 48.9014084507042, 61.1267605633803, 73.3521126760563, 85.5774647887324, 97.8028169014084, 110.028169014085, 122.253521126761, 134.478873239437, 146.704225352113, 158.929577464789, 171.154929577465, 183.380281690141, 195.605633802817, 207.830985915493, 220.056338028169, 232.281690140845, 244.507042253521, 256.732394366197, 268.957746478873, 281.183098591549, 293.408450704225, 305.633802816901, 317.859154929577, 330.084507042254, 342.30985915493, 354.535211267606, 366.760563380282, 378.985915492958, 391.211267605634, 403.43661971831, 415.661971830986, 427.887323943662, 440.112676056338, 452.338028169014, 464.56338028169, 476.788732394366, 489.014084507042, 501.239436619718, 513.464788732394, 525.69014084507, 537.915492957746, 550.140845070423, 562.366197183099, 574.591549295775, 586.816901408451, 599.042253521127, 611.267605633803, 623.492957746479, 635.718309859155, 647.943661971831, 660.169014084507, 672.394366197183, 684.619718309859, 696.845070422535, 709.070422535211, 721.295774647887, 733.521126760563, 745.746478873239, 757.971830985916, 770.197183098591, 782.422535211268, 794.647887323944, 806.87323943662, 819.098591549296, 831.323943661972, 843.549295774648, 855.774647887324, 868],
						"speedDensity": [0.19865571321882, 0.015932287776948, 0.380383370674633, 0.0019915359721185, 0.00124470998257406, 0.101319392581528, 0, 0.0283793876026886, 0, 0.000248941996514812, 0.0268857356235997, 0.00398307194423699, 0.000248941996514812, 0, 0, 0.0428180234005477, 0.000746825989544436, 0, 0, 0, 0, 0.0206621857107294, 0, 0.00448095593726662, 0, 0, 0.00896191187453325, 0, 0, 0, 0, 0.0194174757281554, 0, 0, 0, 0.00771720189195915, 0, 0, 0, 0, 0, 0, 0.00995767986059245, 0, 0, 0, 0, 0.0253920836445108, 0, 0, 0, 0, 0, 0.035598705501618, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0204132437142145, 0, 0, 0, 0, 0, 0, 0.0445606173761512],
						"startTimestamp": "2022-04-07T04:00:00",
						"sumRadioAppCount": 201,
						"timestamp": "2022-05-04T00:01:04.900162",
						"trafficBinSize": 0.421052631578947,
						"trafficKde": [0.0710729656726055, 0.178184023801038, 0.273961914466051, 0.258041184389155, 0.14927526516272, 0.0535852606368312, 0.0123569182273704, 0.00211376915806134],
						"uniqueClients": 515,
						"utilizationBinSize": 0.526315789473684,
						"utilizationKde": [0.0561381281401066, 0.264890641508818, 0.413460060426546, 0.216219922867042, 0.0417731319419598, 0.00506792951911567, 0.00112206484549827]
					}],
					"totalCount": 1
				}
			}
		},
"getApDetailforLowAPDensity8Template":
		{
			"data": {
				"getClientInsightRadios36": {
					"__typename": "GetClientInsightRadios36Connection",
					"nodes": [{
						"__typename": "ClientInsightRadios36Type",
						"aesApMac": "aes:a0:f8:49:7e:c0:44",
						"aesApName": "aes:AP9120",
						"apEntityId": "033a4fcc168e34e007ff",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"apModel": "C9120AXP-B",
						"channelChangeCountBinSize": 0.0526315789473684,
						"channelChangeCountKde": [0.083822086617284, 0.0833589823476091, 0.0819849645572028, 0.0797450794734102, 0.0767116722166308, 0.0729805072186311, 0.0686657515323493, 0.0638941873315881, 0.0587990618058376, 0.0535139877826142, 0.0481672782345456, 0.0428770374651486, 0.0377472491444952, 0.0328650061081307, 0.0282989290177146, 0.0240987299537028, 0.0202958003987504, 0.0169046461281695, 0.0139249568323512, 0.011344085833834],
						"clientCountBinSize": 1,
						"clientCountKde": [0.11231986074631, 0.127544255895216, 0.108709890672446, 0.0866803254948043, 0.0743788709639047, 0.0631732892077653, 0.0465433909920114, 0.0305264882484436, 0.0223606262509795, 0.0206160940421111, 0.0213769254685013, 0.0234176589471303, 0.0235557473203755, 0.0195064000763603, 0.0154331431762263, 0.014008557837049, 0.0142890475477616, 0.0150108980189836, 0.0146848616307581, 0.0140016486482662, 0.0142100082951852, 0.0145862051106546, 0.0145226172674651, 0.0133897646989308, 0.0104964675264401, 0.00698600815447079, 0.00478829568137632, 0.00453131299452321, 0.004716263014095, 0.00394133746058997, 0.00283555966847108, 0.00216177339860342, 0.00212048749799105, 0.00269042588234176, 0.00328589741893577, 0.00347386739250027, 0.00334113314905193, 0.00305166100828977, 0.00313372528486764, 0.00345062829493951, 0.0034597509282098, 0.00307848583628741, 0.00235174303790303],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.469641044903892, 0.0635590058618685, 0, 0.0155767629991111, 0.134001548471666, 0.158308597916441, 0.0193908143492572, 0.00234248858188444, 0.0210331473803652, 0.0387769093951492, 0.0400056540708376, 0.00484777962757196, 0.00231407677344397, 0.0169581080667789, 0.00307981872997088, 0.00581295420476014, 0, 0.00129864870312348, 0.00149013981172607, 0],
						"endTimestamp": "2022-05-04T00:00:00",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 458,
						"insightUid": "a8f0e072547510c1c3b7",
						"interferenceBinSize": 0.105263157894737,
						"interferenceKde": [0.151739273784223, 0.148544155246536, 0.139175892226052, 0.12483232121787, 0.107226411560623, 0.0882521902037884, 0.069655437630672, 0.0527865490717094, 0.038478488341738, 0.0270511701376229, 0.0184102396268241, 0.0121922645769813, 0.00791056059379053, 0.00507037123669827, 0.00324054175951569, 0.00209227793077891, 0.00136769825374197],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 632,
							"key": 1
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 4,
							"key": 0
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00135132168706628, 0.00389311471046319, 0, 0, 0, 0.00251666779592877, 0.0177636285743175, 0.00941756085639048, 0.00999195119835675, 0.0614142397780459, 0.0542904883372523, 0.0761842390651603, 0.149929275733484, 0.128515482866976, 0.088571987640219, 0.131800666691148, 0.0765434456337567, 0.0516677557196663, 0.0318451343660347, 0.0246473248215052, 0.0140500070187527, 0.0165260877837791, 0.0139835137092238, 0.0046736789259411, 0.00315027738729535, 0.00251641203334827, 0.0026449313493509, 0.0027259512099855, 0.00303193206053272, 0.00210877587765974, 0.00131318964954871, 0.00364523214690352, 0.00238007710672879, 0.00390248360036377, 0.00300316466481488],
						"packetFailureKde": null,
						"packetRetryKde": null,
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00962005683460532, 0.205423697322439, 0.56991249168591, 0.205423697322434, 0.00962005683461132],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 4
						}],
						"primaryImageVersion": "8.5.182.0",
						"rootCauseCategory": "Low density",
						"rootCauseCategoryId": "a70b30fe3321351b19dd",
						"rootCauseFeatures": [{
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgPower",
							"kpi": "powerKde",
							"weight": 0.0944625407166124
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": null,
							"feature": "model",
							"kpi": "apModel",
							"weight": 0.0749185667752443
						}],
						"rootCauseId": "746392",
						"rssiKde": [0.0638122499804476, 0.185204914485372, 0.194405855895425, 0.118960718007234, 0.0678265634911907, 0.0463860193050237, 0.0386925550150975, 0.0390393549419514, 0.0471964549350915, 0.0333229143947736, 0.0181107525774139, 0.0164716013979747, 0.0166099515993335, 0.0124269230971927, 0.0102594617962993, 0.00846837862646318, 0.0068275173830783, 0.00493140225518164, 0.00451096098689541, 0.00542639317324911, 0.0043836175987792, 0.00214800661337922, 0.00224928506779028, 0.00212486567253136, 0.00247528636337413, 0.00333842630370973, 0.00349291237439805, 0.00231948017303434, 0.00224608378944086, 0.00240720047899263, 0.00119896272331677, 0, 0, 0.00150609946556604, 0.00264524833476691, 0.00212031436337849, 0.00164267559607408, 0.00198513536295184, 0.00197144413110178, 0.00253816695748544, 0.0050877280041983, 0.00468831158438852, 0.00220725740380672, 0.00166607429028022, 0.00111041318305844, 0.00202993878118383],
						"severity": "s0",
						"siteHierarchyArray": ["Global / North America / USA / California / San Jose / SJC01 / Flr-SJC1-1"],
						"siteId": "9cebf26a-edb4-43b5-a9b6-cf1fa30af5e5",
						"snrKde": [0.0185895227176899, 0.026437317412647, 0.0511787638904145, 0.116878106776148, 0.188901732997888, 0.168640121455706, 0.108712151981975, 0.0627695132100989, 0.0406657817514037, 0.0314168476606176, 0.0287312127900033, 0.0232876653586749, 0.0136850441668234, 0.00867780399790457, 0.0093778999618918, 0.0100821798941026, 0.00943409170630676, 0.00821079793052716, 0.00600376388953167, 0.00424296594805001, 0.00261733294487072, 0.00307773019319264, 0.0036188237241854, 0.00250626806436921, 0.00223051201669, 0.00296722802576196, 0.00278850731042144, 0.00188399323069004, 0.00114620945603339, 0.00173236185028041, 0.00204290737880699, 0.00273043950514847, 0.00284068224737248, 0.00126601031556517, 0, 0, 0.00191315608472914, 0.00294947458419955, 0.00326840631812439, 0.002443680345673, 0.00135498371875663, 0.00164277329565418, 0.00307763510698171, 0.00459722047641202, 0.00362909298092257, 0.00200263566867418, 0.00108119257536572, 0, 0, 0, 0, 0.00153147456687218],
						"speedBins": [0, 12.2253521126761, 24.4507042253521, 36.6760563380282, 48.9014084507042, 61.1267605633803, 73.3521126760563, 85.5774647887324, 97.8028169014084, 110.028169014085, 122.253521126761, 134.478873239437, 146.704225352113, 158.929577464789, 171.154929577465, 183.380281690141, 195.605633802817, 207.830985915493, 220.056338028169, 232.281690140845, 244.507042253521, 256.732394366197, 268.957746478873, 281.183098591549, 293.408450704225, 305.633802816901, 317.859154929577, 330.084507042254, 342.30985915493, 354.535211267606, 366.760563380282, 378.985915492958, 391.211267605634, 403.43661971831, 415.661971830986, 427.887323943662, 440.112676056338, 452.338028169014, 464.56338028169, 476.788732394366, 489.014084507042, 501.239436619718, 513.464788732394, 525.69014084507, 537.915492957746, 550.140845070423, 562.366197183099, 574.591549295775, 586.816901408451, 599.042253521127, 611.267605633803, 623.492957746479, 635.718309859155, 647.943661971831, 660.169014084507, 672.394366197183, 684.619718309859, 696.845070422535, 709.070422535211, 721.295774647887, 733.521126760563, 745.746478873239, 757.971830985916, 770.197183098591, 782.422535211268, 794.647887323944, 806.87323943662, 819.098591549296, 831.323943661972, 843.549295774648, 855.774647887324, 868],
						"speedDensity": [0.19865571321882, 0.015932287776948, 0.380383370674633, 0.0019915359721185, 0.00124470998257406, 0.101319392581528, 0, 0.0283793876026886, 0, 0.000248941996514812, 0.0268857356235997, 0.00398307194423699, 0.000248941996514812, 0, 0, 0.0428180234005477, 0.000746825989544436, 0, 0, 0, 0, 0.0206621857107294, 0, 0.00448095593726662, 0, 0, 0.00896191187453325, 0, 0, 0, 0, 0.0194174757281554, 0, 0, 0, 0.00771720189195915, 0, 0, 0, 0, 0, 0, 0.00995767986059245, 0, 0, 0, 0, 0.0253920836445108, 0, 0, 0, 0, 0, 0.035598705501618, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0204132437142145, 0, 0, 0, 0, 0, 0, 0.0445606173761512],
						"startTimestamp": "2022-04-07T04:00:00",
						"sumRadioAppCount": 201,
						"timestamp": "2022-05-04T00:01:04.900162",
						"trafficBinSize": 0.421052631578947,
						"trafficKde": [0.0710729656726055, 0.178184023801038, 0.273961914466051, 0.258041184389155, 0.14927526516272, 0.0535852606368312, 0.0123569182273704, 0.00211376915806134],
						"uniqueClients": 515,
						"utilizationBinSize": 0.526315789473684,
						"utilizationKde": [0.0561381281401066, 0.264890641508818, 0.413460060426546, 0.216219922867042, 0.0417731319419598, 0.00506792951911567, 0.00112206484549827]
					}],
					"totalCount": 1
				}
			}
		},
"getApDetailforLowAPDensity9Template":
		{
			"data": {
				"getClientInsightRadios36": {
					"__typename": "GetClientInsightRadios36Connection",
					"nodes": [{
						"__typename": "ClientInsightRadios36Type",
						"aesApMac": "aes:a0:f8:49:7e:c0:42",
						"aesApName": "aes:LAB-AP00F2.8B27.B788",
						"apEntityId": "033a4fcc168e34e007ff",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"apModel": "AIR-AP4800-B-K9",
						"channelChangeCountBinSize": 0.0526315789473684,
						"channelChangeCountKde": [0.083822086617284, 0.0833589823476091, 0.0819849645572028, 0.0797450794734102, 0.0767116722166308, 0.0729805072186311, 0.0686657515323493, 0.0638941873315881, 0.0587990618058376, 0.0535139877826142, 0.0481672782345456, 0.0428770374651486, 0.0377472491444952, 0.0328650061081307, 0.0282989290177146, 0.0240987299537028, 0.0202958003987504, 0.0169046461281695, 0.0139249568323512, 0.011344085833834],
						"clientCountBinSize": 1,
						"clientCountKde": [0.11231986074631, 0.127544255895216, 0.108709890672446, 0.0866803254948043, 0.0743788709639047, 0.0631732892077653, 0.0465433909920114, 0.0305264882484436, 0.0223606262509795, 0.0206160940421111, 0.0213769254685013, 0.0234176589471303, 0.0235557473203755, 0.0195064000763603, 0.0154331431762263, 0.014008557837049, 0.0142890475477616, 0.0150108980189836, 0.0146848616307581, 0.0140016486482662, 0.0142100082951852, 0.0145862051106546, 0.0145226172674651, 0.0133897646989308, 0.0104964675264401, 0.00698600815447079, 0.00478829568137632, 0.00453131299452321, 0.004716263014095, 0.00394133746058997, 0.00283555966847108, 0.00216177339860342, 0.00212048749799105, 0.00269042588234176, 0.00328589741893577, 0.00347386739250027, 0.00334113314905193, 0.00305166100828977, 0.00313372528486764, 0.00345062829493951, 0.0034597509282098, 0.00307848583628741, 0.00235174303790303],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.469641044903892, 0.0635590058618685, 0, 0.0155767629991111, 0.134001548471666, 0.158308597916441, 0.0193908143492572, 0.00234248858188444, 0.0210331473803652, 0.0387769093951492, 0.0400056540708376, 0.00484777962757196, 0.00231407677344397, 0.0169581080667789, 0.00307981872997088, 0.00581295420476014, 0, 0.00129864870312348, 0.00149013981172607, 0],
						"endTimestamp": "2022-05-04T00:00:00",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 458,
						"insightUid": "a8f0e072547510c1c3b7",
						"interferenceBinSize": 0.105263157894737,
						"interferenceKde": [0.151739273784223, 0.148544155246536, 0.139175892226052, 0.12483232121787, 0.107226411560623, 0.0882521902037884, 0.069655437630672, 0.0527865490717094, 0.038478488341738, 0.0270511701376229, 0.0184102396268241, 0.0121922645769813, 0.00791056059379053, 0.00507037123669827, 0.00324054175951569, 0.00209227793077891, 0.00136769825374197],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 632,
							"key": 1
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 4,
							"key": 0
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00135132168706628, 0.00389311471046319, 0, 0, 0, 0.00251666779592877, 0.0177636285743175, 0.00941756085639048, 0.00999195119835675, 0.0614142397780459, 0.0542904883372523, 0.0761842390651603, 0.149929275733484, 0.128515482866976, 0.088571987640219, 0.131800666691148, 0.0765434456337567, 0.0516677557196663, 0.0318451343660347, 0.0246473248215052, 0.0140500070187527, 0.0165260877837791, 0.0139835137092238, 0.0046736789259411, 0.00315027738729535, 0.00251641203334827, 0.0026449313493509, 0.0027259512099855, 0.00303193206053272, 0.00210877587765974, 0.00131318964954871, 0.00364523214690352, 0.00238007710672879, 0.00390248360036377, 0.00300316466481488],
						"packetFailureKde": null,
						"packetRetryKde": null,
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00962005683460532, 0.205423697322439, 0.56991249168591, 0.205423697322434, 0.00962005683461132],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 4
						}],
						"primaryImageVersion": "8.5.182.0",
						"rootCauseCategory": "Low density",
						"rootCauseCategoryId": "a70b30fe3321351b19dd",
						"rootCauseFeatures": [{
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgPower",
							"kpi": "powerKde",
							"weight": 0.0944625407166124
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": null,
							"feature": "model",
							"kpi": "apModel",
							"weight": 0.0749185667752443
						}],
						"rootCauseId": "746392",
						"rssiKde": [0.0638122499804476, 0.185204914485372, 0.194405855895425, 0.118960718007234, 0.0678265634911907, 0.0463860193050237, 0.0386925550150975, 0.0390393549419514, 0.0471964549350915, 0.0333229143947736, 0.0181107525774139, 0.0164716013979747, 0.0166099515993335, 0.0124269230971927, 0.0102594617962993, 0.00846837862646318, 0.0068275173830783, 0.00493140225518164, 0.00451096098689541, 0.00542639317324911, 0.0043836175987792, 0.00214800661337922, 0.00224928506779028, 0.00212486567253136, 0.00247528636337413, 0.00333842630370973, 0.00349291237439805, 0.00231948017303434, 0.00224608378944086, 0.00240720047899263, 0.00119896272331677, 0, 0, 0.00150609946556604, 0.00264524833476691, 0.00212031436337849, 0.00164267559607408, 0.00198513536295184, 0.00197144413110178, 0.00253816695748544, 0.0050877280041983, 0.00468831158438852, 0.00220725740380672, 0.00166607429028022, 0.00111041318305844, 0.00202993878118383],
						"severity": "s0",
						"siteHierarchyArray": ["Global / North America / USA / California / San Jose / SJC01 / Flr-SJC1-1"],
						"siteId": "9cebf26a-edb4-43b5-a9b6-cf1fa30af5e5",
						"snrKde": [0.0185895227176899, 0.026437317412647, 0.0511787638904145, 0.116878106776148, 0.188901732997888, 0.168640121455706, 0.108712151981975, 0.0627695132100989, 0.0406657817514037, 0.0314168476606176, 0.0287312127900033, 0.0232876653586749, 0.0136850441668234, 0.00867780399790457, 0.0093778999618918, 0.0100821798941026, 0.00943409170630676, 0.00821079793052716, 0.00600376388953167, 0.00424296594805001, 0.00261733294487072, 0.00307773019319264, 0.0036188237241854, 0.00250626806436921, 0.00223051201669, 0.00296722802576196, 0.00278850731042144, 0.00188399323069004, 0.00114620945603339, 0.00173236185028041, 0.00204290737880699, 0.00273043950514847, 0.00284068224737248, 0.00126601031556517, 0, 0, 0.00191315608472914, 0.00294947458419955, 0.00326840631812439, 0.002443680345673, 0.00135498371875663, 0.00164277329565418, 0.00307763510698171, 0.00459722047641202, 0.00362909298092257, 0.00200263566867418, 0.00108119257536572, 0, 0, 0, 0, 0.00153147456687218],
						"speedBins": [0, 12.2253521126761, 24.4507042253521, 36.6760563380282, 48.9014084507042, 61.1267605633803, 73.3521126760563, 85.5774647887324, 97.8028169014084, 110.028169014085, 122.253521126761, 134.478873239437, 146.704225352113, 158.929577464789, 171.154929577465, 183.380281690141, 195.605633802817, 207.830985915493, 220.056338028169, 232.281690140845, 244.507042253521, 256.732394366197, 268.957746478873, 281.183098591549, 293.408450704225, 305.633802816901, 317.859154929577, 330.084507042254, 342.30985915493, 354.535211267606, 366.760563380282, 378.985915492958, 391.211267605634, 403.43661971831, 415.661971830986, 427.887323943662, 440.112676056338, 452.338028169014, 464.56338028169, 476.788732394366, 489.014084507042, 501.239436619718, 513.464788732394, 525.69014084507, 537.915492957746, 550.140845070423, 562.366197183099, 574.591549295775, 586.816901408451, 599.042253521127, 611.267605633803, 623.492957746479, 635.718309859155, 647.943661971831, 660.169014084507, 672.394366197183, 684.619718309859, 696.845070422535, 709.070422535211, 721.295774647887, 733.521126760563, 745.746478873239, 757.971830985916, 770.197183098591, 782.422535211268, 794.647887323944, 806.87323943662, 819.098591549296, 831.323943661972, 843.549295774648, 855.774647887324, 868],
						"speedDensity": [0.19865571321882, 0.015932287776948, 0.380383370674633, 0.0019915359721185, 0.00124470998257406, 0.101319392581528, 0, 0.0283793876026886, 0, 0.000248941996514812, 0.0268857356235997, 0.00398307194423699, 0.000248941996514812, 0, 0, 0.0428180234005477, 0.000746825989544436, 0, 0, 0, 0, 0.0206621857107294, 0, 0.00448095593726662, 0, 0, 0.00896191187453325, 0, 0, 0, 0, 0.0194174757281554, 0, 0, 0, 0.00771720189195915, 0, 0, 0, 0, 0, 0, 0.00995767986059245, 0, 0, 0, 0, 0.0253920836445108, 0, 0, 0, 0, 0, 0.035598705501618, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0204132437142145, 0, 0, 0, 0, 0, 0, 0.0445606173761512],
						"startTimestamp": "2022-04-07T04:00:00",
						"sumRadioAppCount": 201,
						"timestamp": "2022-05-04T00:01:04.900162",
						"trafficBinSize": 0.421052631578947,
						"trafficKde": [0.0710729656726055, 0.178184023801038, 0.273961914466051, 0.258041184389155, 0.14927526516272, 0.0535852606368312, 0.0123569182273704, 0.00211376915806134],
						"uniqueClients": 515,
						"utilizationBinSize": 0.526315789473684,
						"utilizationKde": [0.0561381281401066, 0.264890641508818, 0.413460060426546, 0.216219922867042, 0.0417731319419598, 0.00506792951911567, 0.00112206484549827]
					}],
					"totalCount": 1
				}
			}
		},
"getApDetailforLowAPDensity10Template":
		{
			"data": {
				"getClientInsightRadios36": {
					"__typename": "GetClientInsightRadios36Connection",
					"nodes": [{
						"__typename": "ClientInsightRadios36Type",
						"aesApMac": "aes:a0:f8:49:7e:c0:52",
						"aesApName": "aes:SJC01_9136_12",
						"apEntityId": "033a4fcc168e34e007ff",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"apModel": "C9120AXP-B",
						"channelChangeCountBinSize": 0.0526315789473684,
						"channelChangeCountKde": [0.083822086617284, 0.0833589823476091, 0.0819849645572028, 0.0797450794734102, 0.0767116722166308, 0.0729805072186311, 0.0686657515323493, 0.0638941873315881, 0.0587990618058376, 0.0535139877826142, 0.0481672782345456, 0.0428770374651486, 0.0377472491444952, 0.0328650061081307, 0.0282989290177146, 0.0240987299537028, 0.0202958003987504, 0.0169046461281695, 0.0139249568323512, 0.011344085833834],
						"clientCountBinSize": 1,
						"clientCountKde": [0.11231986074631, 0.127544255895216, 0.108709890672446, 0.0866803254948043, 0.0743788709639047, 0.0631732892077653, 0.0465433909920114, 0.0305264882484436, 0.0223606262509795, 0.0206160940421111, 0.0213769254685013, 0.0234176589471303, 0.0235557473203755, 0.0195064000763603, 0.0154331431762263, 0.014008557837049, 0.0142890475477616, 0.0150108980189836, 0.0146848616307581, 0.0140016486482662, 0.0142100082951852, 0.0145862051106546, 0.0145226172674651, 0.0133897646989308, 0.0104964675264401, 0.00698600815447079, 0.00478829568137632, 0.00453131299452321, 0.004716263014095, 0.00394133746058997, 0.00283555966847108, 0.00216177339860342, 0.00212048749799105, 0.00269042588234176, 0.00328589741893577, 0.00347386739250027, 0.00334113314905193, 0.00305166100828977, 0.00313372528486764, 0.00345062829493951, 0.0034597509282098, 0.00307848583628741, 0.00235174303790303],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.469641044903892, 0.0635590058618685, 0, 0.0155767629991111, 0.134001548471666, 0.158308597916441, 0.0193908143492572, 0.00234248858188444, 0.0210331473803652, 0.0387769093951492, 0.0400056540708376, 0.00484777962757196, 0.00231407677344397, 0.0169581080667789, 0.00307981872997088, 0.00581295420476014, 0, 0.00129864870312348, 0.00149013981172607, 0],
						"endTimestamp": "2022-05-04T00:00:00",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 458,
						"insightUid": "a8f0e072547510c1c3b7",
						"interferenceBinSize": 0.105263157894737,
						"interferenceKde": [0.151739273784223, 0.148544155246536, 0.139175892226052, 0.12483232121787, 0.107226411560623, 0.0882521902037884, 0.069655437630672, 0.0527865490717094, 0.038478488341738, 0.0270511701376229, 0.0184102396268241, 0.0121922645769813, 0.00791056059379053, 0.00507037123669827, 0.00324054175951569, 0.00209227793077891, 0.00136769825374197],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 632,
							"key": 1
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 4,
							"key": 0
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00135132168706628, 0.00389311471046319, 0, 0, 0, 0.00251666779592877, 0.0177636285743175, 0.00941756085639048, 0.00999195119835675, 0.0614142397780459, 0.0542904883372523, 0.0761842390651603, 0.149929275733484, 0.128515482866976, 0.088571987640219, 0.131800666691148, 0.0765434456337567, 0.0516677557196663, 0.0318451343660347, 0.0246473248215052, 0.0140500070187527, 0.0165260877837791, 0.0139835137092238, 0.0046736789259411, 0.00315027738729535, 0.00251641203334827, 0.0026449313493509, 0.0027259512099855, 0.00303193206053272, 0.00210877587765974, 0.00131318964954871, 0.00364523214690352, 0.00238007710672879, 0.00390248360036377, 0.00300316466481488],
						"packetFailureKde": null,
						"packetRetryKde": null,
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00962005683460532, 0.205423697322439, 0.56991249168591, 0.205423697322434, 0.00962005683461132],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 4
						}],
						"primaryImageVersion": "8.5.182.0",
						"rootCauseCategory": "Low density",
						"rootCauseCategoryId": "a70b30fe3321351b19dd",
						"rootCauseFeatures": [{
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgPower",
							"kpi": "powerKde",
							"weight": 0.0944625407166124
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": null,
							"feature": "model",
							"kpi": "apModel",
							"weight": 0.0749185667752443
						}],
						"rootCauseId": "746392",
						"rssiKde": [0.0638122499804476, 0.185204914485372, 0.194405855895425, 0.118960718007234, 0.0678265634911907, 0.0463860193050237, 0.0386925550150975, 0.0390393549419514, 0.0471964549350915, 0.0333229143947736, 0.0181107525774139, 0.0164716013979747, 0.0166099515993335, 0.0124269230971927, 0.0102594617962993, 0.00846837862646318, 0.0068275173830783, 0.00493140225518164, 0.00451096098689541, 0.00542639317324911, 0.0043836175987792, 0.00214800661337922, 0.00224928506779028, 0.00212486567253136, 0.00247528636337413, 0.00333842630370973, 0.00349291237439805, 0.00231948017303434, 0.00224608378944086, 0.00240720047899263, 0.00119896272331677, 0, 0, 0.00150609946556604, 0.00264524833476691, 0.00212031436337849, 0.00164267559607408, 0.00198513536295184, 0.00197144413110178, 0.00253816695748544, 0.0050877280041983, 0.00468831158438852, 0.00220725740380672, 0.00166607429028022, 0.00111041318305844, 0.00202993878118383],
						"severity": "s0",
						"siteHierarchyArray": ["Global / North America / USA / California / San Jose / SJC01 / Flr-SJC1-1"],
						"siteId": "9cebf26a-edb4-43b5-a9b6-cf1fa30af5e5",
						"snrKde": [0.0185895227176899, 0.026437317412647, 0.0511787638904145, 0.116878106776148, 0.188901732997888, 0.168640121455706, 0.108712151981975, 0.0627695132100989, 0.0406657817514037, 0.0314168476606176, 0.0287312127900033, 0.0232876653586749, 0.0136850441668234, 0.00867780399790457, 0.0093778999618918, 0.0100821798941026, 0.00943409170630676, 0.00821079793052716, 0.00600376388953167, 0.00424296594805001, 0.00261733294487072, 0.00307773019319264, 0.0036188237241854, 0.00250626806436921, 0.00223051201669, 0.00296722802576196, 0.00278850731042144, 0.00188399323069004, 0.00114620945603339, 0.00173236185028041, 0.00204290737880699, 0.00273043950514847, 0.00284068224737248, 0.00126601031556517, 0, 0, 0.00191315608472914, 0.00294947458419955, 0.00326840631812439, 0.002443680345673, 0.00135498371875663, 0.00164277329565418, 0.00307763510698171, 0.00459722047641202, 0.00362909298092257, 0.00200263566867418, 0.00108119257536572, 0, 0, 0, 0, 0.00153147456687218],
						"speedBins": [0, 12.2253521126761, 24.4507042253521, 36.6760563380282, 48.9014084507042, 61.1267605633803, 73.3521126760563, 85.5774647887324, 97.8028169014084, 110.028169014085, 122.253521126761, 134.478873239437, 146.704225352113, 158.929577464789, 171.154929577465, 183.380281690141, 195.605633802817, 207.830985915493, 220.056338028169, 232.281690140845, 244.507042253521, 256.732394366197, 268.957746478873, 281.183098591549, 293.408450704225, 305.633802816901, 317.859154929577, 330.084507042254, 342.30985915493, 354.535211267606, 366.760563380282, 378.985915492958, 391.211267605634, 403.43661971831, 415.661971830986, 427.887323943662, 440.112676056338, 452.338028169014, 464.56338028169, 476.788732394366, 489.014084507042, 501.239436619718, 513.464788732394, 525.69014084507, 537.915492957746, 550.140845070423, 562.366197183099, 574.591549295775, 586.816901408451, 599.042253521127, 611.267605633803, 623.492957746479, 635.718309859155, 647.943661971831, 660.169014084507, 672.394366197183, 684.619718309859, 696.845070422535, 709.070422535211, 721.295774647887, 733.521126760563, 745.746478873239, 757.971830985916, 770.197183098591, 782.422535211268, 794.647887323944, 806.87323943662, 819.098591549296, 831.323943661972, 843.549295774648, 855.774647887324, 868],
						"speedDensity": [0.19865571321882, 0.015932287776948, 0.380383370674633, 0.0019915359721185, 0.00124470998257406, 0.101319392581528, 0, 0.0283793876026886, 0, 0.000248941996514812, 0.0268857356235997, 0.00398307194423699, 0.000248941996514812, 0, 0, 0.0428180234005477, 0.000746825989544436, 0, 0, 0, 0, 0.0206621857107294, 0, 0.00448095593726662, 0, 0, 0.00896191187453325, 0, 0, 0, 0, 0.0194174757281554, 0, 0, 0, 0.00771720189195915, 0, 0, 0, 0, 0, 0, 0.00995767986059245, 0, 0, 0, 0, 0.0253920836445108, 0, 0, 0, 0, 0, 0.035598705501618, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0204132437142145, 0, 0, 0, 0, 0, 0, 0.0445606173761512],
						"startTimestamp": "2022-04-07T04:00:00",
						"sumRadioAppCount": 201,
						"timestamp": "2022-05-04T00:01:04.900162",
						"trafficBinSize": 0.421052631578947,
						"trafficKde": [0.0710729656726055, 0.178184023801038, 0.273961914466051, 0.258041184389155, 0.14927526516272, 0.0535852606368312, 0.0123569182273704, 0.00211376915806134],
						"uniqueClients": 515,
						"utilizationBinSize": 0.526315789473684,
						"utilizationKde": [0.0561381281401066, 0.264890641508818, 0.413460060426546, 0.216219922867042, 0.0417731319419598, 0.00506792951911567, 0.00112206484549827]
					}],
					"totalCount": 1
				}
			}
		},
"getApDetailforLowAPDensity11Template":
		{
			"data": {
				"getClientInsightRadios36": {
					"__typename": "GetClientInsightRadios36Connection",
					"nodes": [{
						"__typename": "ClientInsightRadios36Type",
						"aesApMac": "aes:a0:f8:49:7e:c0:59",
						"aesApName": "aes:SFO15-C9120-04",
						"apEntityId": "033a4fcc168e34e007ff",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"apModel": "C9120AXP-B",
						"channelChangeCountBinSize": 0.0526315789473684,
						"channelChangeCountKde": [0.083822086617284, 0.0833589823476091, 0.0819849645572028, 0.0797450794734102, 0.0767116722166308, 0.0729805072186311, 0.0686657515323493, 0.0638941873315881, 0.0587990618058376, 0.0535139877826142, 0.0481672782345456, 0.0428770374651486, 0.0377472491444952, 0.0328650061081307, 0.0282989290177146, 0.0240987299537028, 0.0202958003987504, 0.0169046461281695, 0.0139249568323512, 0.011344085833834],
						"clientCountBinSize": 1,
						"clientCountKde": [0.11231986074631, 0.127544255895216, 0.108709890672446, 0.0866803254948043, 0.0743788709639047, 0.0631732892077653, 0.0465433909920114, 0.0305264882484436, 0.0223606262509795, 0.0206160940421111, 0.0213769254685013, 0.0234176589471303, 0.0235557473203755, 0.0195064000763603, 0.0154331431762263, 0.014008557837049, 0.0142890475477616, 0.0150108980189836, 0.0146848616307581, 0.0140016486482662, 0.0142100082951852, 0.0145862051106546, 0.0145226172674651, 0.0133897646989308, 0.0104964675264401, 0.00698600815447079, 0.00478829568137632, 0.00453131299452321, 0.004716263014095, 0.00394133746058997, 0.00283555966847108, 0.00216177339860342, 0.00212048749799105, 0.00269042588234176, 0.00328589741893577, 0.00347386739250027, 0.00334113314905193, 0.00305166100828977, 0.00313372528486764, 0.00345062829493951, 0.0034597509282098, 0.00307848583628741, 0.00235174303790303],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.469641044903892, 0.0635590058618685, 0, 0.0155767629991111, 0.134001548471666, 0.158308597916441, 0.0193908143492572, 0.00234248858188444, 0.0210331473803652, 0.0387769093951492, 0.0400056540708376, 0.00484777962757196, 0.00231407677344397, 0.0169581080667789, 0.00307981872997088, 0.00581295420476014, 0, 0.00129864870312348, 0.00149013981172607, 0],
						"endTimestamp": "2022-05-04T00:00:00",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 458,
						"insightUid": "a8f0e072547510c1c3b7",
						"interferenceBinSize": 0.105263157894737,
						"interferenceKde": [0.151739273784223, 0.148544155246536, 0.139175892226052, 0.12483232121787, 0.107226411560623, 0.0882521902037884, 0.069655437630672, 0.0527865490717094, 0.038478488341738, 0.0270511701376229, 0.0184102396268241, 0.0121922645769813, 0.00791056059379053, 0.00507037123669827, 0.00324054175951569, 0.00209227793077891, 0.00136769825374197],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 632,
							"key": 1
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 4,
							"key": 0
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00135132168706628, 0.00389311471046319, 0, 0, 0, 0.00251666779592877, 0.0177636285743175, 0.00941756085639048, 0.00999195119835675, 0.0614142397780459, 0.0542904883372523, 0.0761842390651603, 0.149929275733484, 0.128515482866976, 0.088571987640219, 0.131800666691148, 0.0765434456337567, 0.0516677557196663, 0.0318451343660347, 0.0246473248215052, 0.0140500070187527, 0.0165260877837791, 0.0139835137092238, 0.0046736789259411, 0.00315027738729535, 0.00251641203334827, 0.0026449313493509, 0.0027259512099855, 0.00303193206053272, 0.00210877587765974, 0.00131318964954871, 0.00364523214690352, 0.00238007710672879, 0.00390248360036377, 0.00300316466481488],
						"packetFailureKde": null,
						"packetRetryKde": null,
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00962005683460532, 0.205423697322439, 0.56991249168591, 0.205423697322434, 0.00962005683461132],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 4
						}],
						"primaryImageVersion": "8.5.182.0",
						"rootCauseCategory": "Low density",
						"rootCauseCategoryId": "a70b30fe3321351b19dd",
						"rootCauseFeatures": [{
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgPower",
							"kpi": "powerKde",
							"weight": 0.0944625407166124
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": null,
							"feature": "model",
							"kpi": "apModel",
							"weight": 0.0749185667752443
						}],
						"rootCauseId": "746392",
						"rssiKde": [0.0638122499804476, 0.185204914485372, 0.194405855895425, 0.118960718007234, 0.0678265634911907, 0.0463860193050237, 0.0386925550150975, 0.0390393549419514, 0.0471964549350915, 0.0333229143947736, 0.0181107525774139, 0.0164716013979747, 0.0166099515993335, 0.0124269230971927, 0.0102594617962993, 0.00846837862646318, 0.0068275173830783, 0.00493140225518164, 0.00451096098689541, 0.00542639317324911, 0.0043836175987792, 0.00214800661337922, 0.00224928506779028, 0.00212486567253136, 0.00247528636337413, 0.00333842630370973, 0.00349291237439805, 0.00231948017303434, 0.00224608378944086, 0.00240720047899263, 0.00119896272331677, 0, 0, 0.00150609946556604, 0.00264524833476691, 0.00212031436337849, 0.00164267559607408, 0.00198513536295184, 0.00197144413110178, 0.00253816695748544, 0.0050877280041983, 0.00468831158438852, 0.00220725740380672, 0.00166607429028022, 0.00111041318305844, 0.00202993878118383],
						"severity": "s0",
						"siteHierarchyArray": ["Global / North America / USA / California / San Francisco / SFO15 / Flr-SFO15-1"],
						"siteId": "9cebf26a-edb4-43b5-a9b6-cf1fa30af5e5",
						"snrKde": [0.0185895227176899, 0.026437317412647, 0.0511787638904145, 0.116878106776148, 0.188901732997888, 0.168640121455706, 0.108712151981975, 0.0627695132100989, 0.0406657817514037, 0.0314168476606176, 0.0287312127900033, 0.0232876653586749, 0.0136850441668234, 0.00867780399790457, 0.0093778999618918, 0.0100821798941026, 0.00943409170630676, 0.00821079793052716, 0.00600376388953167, 0.00424296594805001, 0.00261733294487072, 0.00307773019319264, 0.0036188237241854, 0.00250626806436921, 0.00223051201669, 0.00296722802576196, 0.00278850731042144, 0.00188399323069004, 0.00114620945603339, 0.00173236185028041, 0.00204290737880699, 0.00273043950514847, 0.00284068224737248, 0.00126601031556517, 0, 0, 0.00191315608472914, 0.00294947458419955, 0.00326840631812439, 0.002443680345673, 0.00135498371875663, 0.00164277329565418, 0.00307763510698171, 0.00459722047641202, 0.00362909298092257, 0.00200263566867418, 0.00108119257536572, 0, 0, 0, 0, 0.00153147456687218],
						"speedBins": [0, 12.2253521126761, 24.4507042253521, 36.6760563380282, 48.9014084507042, 61.1267605633803, 73.3521126760563, 85.5774647887324, 97.8028169014084, 110.028169014085, 122.253521126761, 134.478873239437, 146.704225352113, 158.929577464789, 171.154929577465, 183.380281690141, 195.605633802817, 207.830985915493, 220.056338028169, 232.281690140845, 244.507042253521, 256.732394366197, 268.957746478873, 281.183098591549, 293.408450704225, 305.633802816901, 317.859154929577, 330.084507042254, 342.30985915493, 354.535211267606, 366.760563380282, 378.985915492958, 391.211267605634, 403.43661971831, 415.661971830986, 427.887323943662, 440.112676056338, 452.338028169014, 464.56338028169, 476.788732394366, 489.014084507042, 501.239436619718, 513.464788732394, 525.69014084507, 537.915492957746, 550.140845070423, 562.366197183099, 574.591549295775, 586.816901408451, 599.042253521127, 611.267605633803, 623.492957746479, 635.718309859155, 647.943661971831, 660.169014084507, 672.394366197183, 684.619718309859, 696.845070422535, 709.070422535211, 721.295774647887, 733.521126760563, 745.746478873239, 757.971830985916, 770.197183098591, 782.422535211268, 794.647887323944, 806.87323943662, 819.098591549296, 831.323943661972, 843.549295774648, 855.774647887324, 868],
						"speedDensity": [0.19865571321882, 0.015932287776948, 0.380383370674633, 0.0019915359721185, 0.00124470998257406, 0.101319392581528, 0, 0.0283793876026886, 0, 0.000248941996514812, 0.0268857356235997, 0.00398307194423699, 0.000248941996514812, 0, 0, 0.0428180234005477, 0.000746825989544436, 0, 0, 0, 0, 0.0206621857107294, 0, 0.00448095593726662, 0, 0, 0.00896191187453325, 0, 0, 0, 0, 0.0194174757281554, 0, 0, 0, 0.00771720189195915, 0, 0, 0, 0, 0, 0, 0.00995767986059245, 0, 0, 0, 0, 0.0253920836445108, 0, 0, 0, 0, 0, 0.035598705501618, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0204132437142145, 0, 0, 0, 0, 0, 0, 0.0445606173761512],
						"startTimestamp": "2022-04-07T04:00:00",
						"sumRadioAppCount": 201,
						"timestamp": "2022-05-04T00:01:04.900162",
						"trafficBinSize": 0.421052631578947,
						"trafficKde": [0.0710729656726055, 0.178184023801038, 0.273961914466051, 0.258041184389155, 0.14927526516272, 0.0535852606368312, 0.0123569182273704, 0.00211376915806134],
						"uniqueClients": 515,
						"utilizationBinSize": 0.526315789473684,
						"utilizationKde": [0.0561381281401066, 0.264890641508818, 0.413460060426546, 0.216219922867042, 0.0417731319419598, 0.00506792951911567, 0.00112206484549827]
					}],
					"totalCount": 1
				}
			}
		},
		"getApDetailforLowAPDensity12Template":
		{
			"data": {
				"getClientInsightRadios36": {
					"__typename": "GetClientInsightRadios36Connection",
					"nodes": [{
						"__typename": "ClientInsightRadios36Type",
						"aesApMac": "aes:a0:f8:49:7e:c0:51",
						"aesApName": "aes:SFO10-AP3800-02",
						"apEntityId": "033a4fcc168e34e007ff",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"apModel": "AIR-AP3802I-B-K9",
						"channelChangeCountBinSize": 0.0526315789473684,
						"channelChangeCountKde": [0.083822086617284, 0.0833589823476091, 0.0819849645572028, 0.0797450794734102, 0.0767116722166308, 0.0729805072186311, 0.0686657515323493, 0.0638941873315881, 0.0587990618058376, 0.0535139877826142, 0.0481672782345456, 0.0428770374651486, 0.0377472491444952, 0.0328650061081307, 0.0282989290177146, 0.0240987299537028, 0.0202958003987504, 0.0169046461281695, 0.0139249568323512, 0.011344085833834],
						"clientCountBinSize": 1,
						"clientCountKde": [0.11231986074631, 0.127544255895216, 0.108709890672446, 0.0866803254948043, 0.0743788709639047, 0.0631732892077653, 0.0465433909920114, 0.0305264882484436, 0.0223606262509795, 0.0206160940421111, 0.0213769254685013, 0.0234176589471303, 0.0235557473203755, 0.0195064000763603, 0.0154331431762263, 0.014008557837049, 0.0142890475477616, 0.0150108980189836, 0.0146848616307581, 0.0140016486482662, 0.0142100082951852, 0.0145862051106546, 0.0145226172674651, 0.0133897646989308, 0.0104964675264401, 0.00698600815447079, 0.00478829568137632, 0.00453131299452321, 0.004716263014095, 0.00394133746058997, 0.00283555966847108, 0.00216177339860342, 0.00212048749799105, 0.00269042588234176, 0.00328589741893577, 0.00347386739250027, 0.00334113314905193, 0.00305166100828977, 0.00313372528486764, 0.00345062829493951, 0.0034597509282098, 0.00307848583628741, 0.00235174303790303],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.469641044903892, 0.0635590058618685, 0, 0.0155767629991111, 0.134001548471666, 0.158308597916441, 0.0193908143492572, 0.00234248858188444, 0.0210331473803652, 0.0387769093951492, 0.0400056540708376, 0.00484777962757196, 0.00231407677344397, 0.0169581080667789, 0.00307981872997088, 0.00581295420476014, 0, 0.00129864870312348, 0.00149013981172607, 0],
						"endTimestamp": "2022-05-04T00:00:00",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 458,
						"insightUid": "a8f0e072547510c1c3b7",
						"interferenceBinSize": 0.105263157894737,
						"interferenceKde": [0.151739273784223, 0.148544155246536, 0.139175892226052, 0.12483232121787, 0.107226411560623, 0.0882521902037884, 0.069655437630672, 0.0527865490717094, 0.038478488341738, 0.0270511701376229, 0.0184102396268241, 0.0121922645769813, 0.00791056059379053, 0.00507037123669827, 0.00324054175951569, 0.00209227793077891, 0.00136769825374197],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 632,
							"key": 1
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 4,
							"key": 0
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00135132168706628, 0.00389311471046319, 0, 0, 0, 0.00251666779592877, 0.0177636285743175, 0.00941756085639048, 0.00999195119835675, 0.0614142397780459, 0.0542904883372523, 0.0761842390651603, 0.149929275733484, 0.128515482866976, 0.088571987640219, 0.131800666691148, 0.0765434456337567, 0.0516677557196663, 0.0318451343660347, 0.0246473248215052, 0.0140500070187527, 0.0165260877837791, 0.0139835137092238, 0.0046736789259411, 0.00315027738729535, 0.00251641203334827, 0.0026449313493509, 0.0027259512099855, 0.00303193206053272, 0.00210877587765974, 0.00131318964954871, 0.00364523214690352, 0.00238007710672879, 0.00390248360036377, 0.00300316466481488],
						"packetFailureKde": null,
						"packetRetryKde": null,
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00962005683460532, 0.205423697322439, 0.56991249168591, 0.205423697322434, 0.00962005683461132],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 4
						}],
						"primaryImageVersion": "8.5.182.0",
						"rootCauseCategory": "Low density",
						"rootCauseCategoryId": "a70b30fe3321351b19dd",
						"rootCauseFeatures": [{
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgPower",
							"kpi": "powerKde",
							"weight": 0.0944625407166124
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": null,
							"feature": "model",
							"kpi": "apModel",
							"weight": 0.0749185667752443
						}],
						"rootCauseId": "746392",
						"rssiKde": [0.0638122499804476, 0.185204914485372, 0.194405855895425, 0.118960718007234, 0.0678265634911907, 0.0463860193050237, 0.0386925550150975, 0.0390393549419514, 0.0471964549350915, 0.0333229143947736, 0.0181107525774139, 0.0164716013979747, 0.0166099515993335, 0.0124269230971927, 0.0102594617962993, 0.00846837862646318, 0.0068275173830783, 0.00493140225518164, 0.00451096098689541, 0.00542639317324911, 0.0043836175987792, 0.00214800661337922, 0.00224928506779028, 0.00212486567253136, 0.00247528636337413, 0.00333842630370973, 0.00349291237439805, 0.00231948017303434, 0.00224608378944086, 0.00240720047899263, 0.00119896272331677, 0, 0, 0.00150609946556604, 0.00264524833476691, 0.00212031436337849, 0.00164267559607408, 0.00198513536295184, 0.00197144413110178, 0.00253816695748544, 0.0050877280041983, 0.00468831158438852, 0.00220725740380672, 0.00166607429028022, 0.00111041318305844, 0.00202993878118383],
						"severity": "s0",
						"siteHierarchyArray": ["Global / North America / USA / California / San Francisco / SFO10 / Flr-SFO10-1"],
						"siteId": "9cebf26a-edb4-43b5-a9b6-cf1fa30af5e5",
						"snrKde": [0.0185895227176899, 0.026437317412647, 0.0511787638904145, 0.116878106776148, 0.188901732997888, 0.168640121455706, 0.108712151981975, 0.0627695132100989, 0.0406657817514037, 0.0314168476606176, 0.0287312127900033, 0.0232876653586749, 0.0136850441668234, 0.00867780399790457, 0.0093778999618918, 0.0100821798941026, 0.00943409170630676, 0.00821079793052716, 0.00600376388953167, 0.00424296594805001, 0.00261733294487072, 0.00307773019319264, 0.0036188237241854, 0.00250626806436921, 0.00223051201669, 0.00296722802576196, 0.00278850731042144, 0.00188399323069004, 0.00114620945603339, 0.00173236185028041, 0.00204290737880699, 0.00273043950514847, 0.00284068224737248, 0.00126601031556517, 0, 0, 0.00191315608472914, 0.00294947458419955, 0.00326840631812439, 0.002443680345673, 0.00135498371875663, 0.00164277329565418, 0.00307763510698171, 0.00459722047641202, 0.00362909298092257, 0.00200263566867418, 0.00108119257536572, 0, 0, 0, 0, 0.00153147456687218],
						"speedBins": [0, 12.2253521126761, 24.4507042253521, 36.6760563380282, 48.9014084507042, 61.1267605633803, 73.3521126760563, 85.5774647887324, 97.8028169014084, 110.028169014085, 122.253521126761, 134.478873239437, 146.704225352113, 158.929577464789, 171.154929577465, 183.380281690141, 195.605633802817, 207.830985915493, 220.056338028169, 232.281690140845, 244.507042253521, 256.732394366197, 268.957746478873, 281.183098591549, 293.408450704225, 305.633802816901, 317.859154929577, 330.084507042254, 342.30985915493, 354.535211267606, 366.760563380282, 378.985915492958, 391.211267605634, 403.43661971831, 415.661971830986, 427.887323943662, 440.112676056338, 452.338028169014, 464.56338028169, 476.788732394366, 489.014084507042, 501.239436619718, 513.464788732394, 525.69014084507, 537.915492957746, 550.140845070423, 562.366197183099, 574.591549295775, 586.816901408451, 599.042253521127, 611.267605633803, 623.492957746479, 635.718309859155, 647.943661971831, 660.169014084507, 672.394366197183, 684.619718309859, 696.845070422535, 709.070422535211, 721.295774647887, 733.521126760563, 745.746478873239, 757.971830985916, 770.197183098591, 782.422535211268, 794.647887323944, 806.87323943662, 819.098591549296, 831.323943661972, 843.549295774648, 855.774647887324, 868],
						"speedDensity": [0.19865571321882, 0.015932287776948, 0.380383370674633, 0.0019915359721185, 0.00124470998257406, 0.101319392581528, 0, 0.0283793876026886, 0, 0.000248941996514812, 0.0268857356235997, 0.00398307194423699, 0.000248941996514812, 0, 0, 0.0428180234005477, 0.000746825989544436, 0, 0, 0, 0, 0.0206621857107294, 0, 0.00448095593726662, 0, 0, 0.00896191187453325, 0, 0, 0, 0, 0.0194174757281554, 0, 0, 0, 0.00771720189195915, 0, 0, 0, 0, 0, 0, 0.00995767986059245, 0, 0, 0, 0, 0.0253920836445108, 0, 0, 0, 0, 0, 0.035598705501618, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0204132437142145, 0, 0, 0, 0, 0, 0, 0.0445606173761512],
						"startTimestamp": "2022-04-07T04:00:00",
						"sumRadioAppCount": 201,
						"timestamp": "2022-05-04T00:01:04.900162",
						"trafficBinSize": 0.421052631578947,
						"trafficKde": [0.0710729656726055, 0.178184023801038, 0.273961914466051, 0.258041184389155, 0.14927526516272, 0.0535852606368312, 0.0123569182273704, 0.00211376915806134],
						"uniqueClients": 515,
						"utilizationBinSize": 0.526315789473684,
						"utilizationKde": [0.0561381281401066, 0.264890641508818, 0.413460060426546, 0.216219922867042, 0.0417731319419598, 0.00506792951911567, 0.00112206484549827]
					}],
					"totalCount": 1
				}
			}
		},
		"getApDetailforLowAPDensity13Template":
		{
			"data": {
				"getClientInsightRadios36": {
					"__typename": "GetClientInsightRadios36Connection",
					"nodes": [{
						"__typename": "ClientInsightRadios36Type",
						"aesApMac": "aes:a0:f8:49:7e:c0:65",
						"aesApName": "aes:AP70B3.1733.D6D4",
						"apEntityId": "033a4fcc168e34e007ff",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"apModel": "AIR-AP4800-A-K9",
						"channelChangeCountBinSize": 0.0526315789473684,
						"channelChangeCountKde": [0.083822086617284, 0.0833589823476091, 0.0819849645572028, 0.0797450794734102, 0.0767116722166308, 0.0729805072186311, 0.0686657515323493, 0.0638941873315881, 0.0587990618058376, 0.0535139877826142, 0.0481672782345456, 0.0428770374651486, 0.0377472491444952, 0.0328650061081307, 0.0282989290177146, 0.0240987299537028, 0.0202958003987504, 0.0169046461281695, 0.0139249568323512, 0.011344085833834],
						"clientCountBinSize": 1,
						"clientCountKde": [0.11231986074631, 0.127544255895216, 0.108709890672446, 0.0866803254948043, 0.0743788709639047, 0.0631732892077653, 0.0465433909920114, 0.0305264882484436, 0.0223606262509795, 0.0206160940421111, 0.0213769254685013, 0.0234176589471303, 0.0235557473203755, 0.0195064000763603, 0.0154331431762263, 0.014008557837049, 0.0142890475477616, 0.0150108980189836, 0.0146848616307581, 0.0140016486482662, 0.0142100082951852, 0.0145862051106546, 0.0145226172674651, 0.0133897646989308, 0.0104964675264401, 0.00698600815447079, 0.00478829568137632, 0.00453131299452321, 0.004716263014095, 0.00394133746058997, 0.00283555966847108, 0.00216177339860342, 0.00212048749799105, 0.00269042588234176, 0.00328589741893577, 0.00347386739250027, 0.00334113314905193, 0.00305166100828977, 0.00313372528486764, 0.00345062829493951, 0.0034597509282098, 0.00307848583628741, 0.00235174303790303],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.469641044903892, 0.0635590058618685, 0, 0.0155767629991111, 0.134001548471666, 0.158308597916441, 0.0193908143492572, 0.00234248858188444, 0.0210331473803652, 0.0387769093951492, 0.0400056540708376, 0.00484777962757196, 0.00231407677344397, 0.0169581080667789, 0.00307981872997088, 0.00581295420476014, 0, 0.00129864870312348, 0.00149013981172607, 0],
						"endTimestamp": "2022-05-04T00:00:00",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 458,
						"insightUid": "a8f0e072547510c1c3b7",
						"interferenceBinSize": 0.105263157894737,
						"interferenceKde": [0.151739273784223, 0.148544155246536, 0.139175892226052, 0.12483232121787, 0.107226411560623, 0.0882521902037884, 0.069655437630672, 0.0527865490717094, 0.038478488341738, 0.0270511701376229, 0.0184102396268241, 0.0121922645769813, 0.00791056059379053, 0.00507037123669827, 0.00324054175951569, 0.00209227793077891, 0.00136769825374197],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 632,
							"key": 1
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 4,
							"key": 0
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00135132168706628, 0.00389311471046319, 0, 0, 0, 0.00251666779592877, 0.0177636285743175, 0.00941756085639048, 0.00999195119835675, 0.0614142397780459, 0.0542904883372523, 0.0761842390651603, 0.149929275733484, 0.128515482866976, 0.088571987640219, 0.131800666691148, 0.0765434456337567, 0.0516677557196663, 0.0318451343660347, 0.0246473248215052, 0.0140500070187527, 0.0165260877837791, 0.0139835137092238, 0.0046736789259411, 0.00315027738729535, 0.00251641203334827, 0.0026449313493509, 0.0027259512099855, 0.00303193206053272, 0.00210877587765974, 0.00131318964954871, 0.00364523214690352, 0.00238007710672879, 0.00390248360036377, 0.00300316466481488],
						"packetFailureKde": null,
						"packetRetryKde": null,
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00962005683460532, 0.205423697322439, 0.56991249168591, 0.205423697322434, 0.00962005683461132],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 4
						}],
						"primaryImageVersion": "8.5.182.0",
						"rootCauseCategory": "Low density",
						"rootCauseCategoryId": "a70b30fe3321351b19dd",
						"rootCauseFeatures": [{
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgPower",
							"kpi": "powerKde",
							"weight": 0.0944625407166124
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": null,
							"feature": "model",
							"kpi": "apModel",
							"weight": 0.0749185667752443
						}],
						"rootCauseId": "746392",
						"rssiKde": [0.0638122499804476, 0.185204914485372, 0.194405855895425, 0.118960718007234, 0.0678265634911907, 0.0463860193050237, 0.0386925550150975, 0.0390393549419514, 0.0471964549350915, 0.0333229143947736, 0.0181107525774139, 0.0164716013979747, 0.0166099515993335, 0.0124269230971927, 0.0102594617962993, 0.00846837862646318, 0.0068275173830783, 0.00493140225518164, 0.00451096098689541, 0.00542639317324911, 0.0043836175987792, 0.00214800661337922, 0.00224928506779028, 0.00212486567253136, 0.00247528636337413, 0.00333842630370973, 0.00349291237439805, 0.00231948017303434, 0.00224608378944086, 0.00240720047899263, 0.00119896272331677, 0, 0, 0.00150609946556604, 0.00264524833476691, 0.00212031436337849, 0.00164267559607408, 0.00198513536295184, 0.00197144413110178, 0.00253816695748544, 0.0050877280041983, 0.00468831158438852, 0.00220725740380672, 0.00166607429028022, 0.00111041318305844, 0.00202993878118383],
						"severity": "s0",
						"siteHierarchyArray": ["Global / North America / USA / California / San Jose / SJC22 / Flr-SJC22-1"],
						"siteId": "9cebf26a-edb4-43b5-a9b6-cf1fa30af5e5",
						"snrKde": [0.0185895227176899, 0.026437317412647, 0.0511787638904145, 0.116878106776148, 0.188901732997888, 0.168640121455706, 0.108712151981975, 0.0627695132100989, 0.0406657817514037, 0.0314168476606176, 0.0287312127900033, 0.0232876653586749, 0.0136850441668234, 0.00867780399790457, 0.0093778999618918, 0.0100821798941026, 0.00943409170630676, 0.00821079793052716, 0.00600376388953167, 0.00424296594805001, 0.00261733294487072, 0.00307773019319264, 0.0036188237241854, 0.00250626806436921, 0.00223051201669, 0.00296722802576196, 0.00278850731042144, 0.00188399323069004, 0.00114620945603339, 0.00173236185028041, 0.00204290737880699, 0.00273043950514847, 0.00284068224737248, 0.00126601031556517, 0, 0, 0.00191315608472914, 0.00294947458419955, 0.00326840631812439, 0.002443680345673, 0.00135498371875663, 0.00164277329565418, 0.00307763510698171, 0.00459722047641202, 0.00362909298092257, 0.00200263566867418, 0.00108119257536572, 0, 0, 0, 0, 0.00153147456687218],
						"speedBins": [0, 12.2253521126761, 24.4507042253521, 36.6760563380282, 48.9014084507042, 61.1267605633803, 73.3521126760563, 85.5774647887324, 97.8028169014084, 110.028169014085, 122.253521126761, 134.478873239437, 146.704225352113, 158.929577464789, 171.154929577465, 183.380281690141, 195.605633802817, 207.830985915493, 220.056338028169, 232.281690140845, 244.507042253521, 256.732394366197, 268.957746478873, 281.183098591549, 293.408450704225, 305.633802816901, 317.859154929577, 330.084507042254, 342.30985915493, 354.535211267606, 366.760563380282, 378.985915492958, 391.211267605634, 403.43661971831, 415.661971830986, 427.887323943662, 440.112676056338, 452.338028169014, 464.56338028169, 476.788732394366, 489.014084507042, 501.239436619718, 513.464788732394, 525.69014084507, 537.915492957746, 550.140845070423, 562.366197183099, 574.591549295775, 586.816901408451, 599.042253521127, 611.267605633803, 623.492957746479, 635.718309859155, 647.943661971831, 660.169014084507, 672.394366197183, 684.619718309859, 696.845070422535, 709.070422535211, 721.295774647887, 733.521126760563, 745.746478873239, 757.971830985916, 770.197183098591, 782.422535211268, 794.647887323944, 806.87323943662, 819.098591549296, 831.323943661972, 843.549295774648, 855.774647887324, 868],
						"speedDensity": [0.19865571321882, 0.015932287776948, 0.380383370674633, 0.0019915359721185, 0.00124470998257406, 0.101319392581528, 0, 0.0283793876026886, 0, 0.000248941996514812, 0.0268857356235997, 0.00398307194423699, 0.000248941996514812, 0, 0, 0.0428180234005477, 0.000746825989544436, 0, 0, 0, 0, 0.0206621857107294, 0, 0.00448095593726662, 0, 0, 0.00896191187453325, 0, 0, 0, 0, 0.0194174757281554, 0, 0, 0, 0.00771720189195915, 0, 0, 0, 0, 0, 0, 0.00995767986059245, 0, 0, 0, 0, 0.0253920836445108, 0, 0, 0, 0, 0, 0.035598705501618, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0204132437142145, 0, 0, 0, 0, 0, 0, 0.0445606173761512],
						"startTimestamp": "2022-04-07T04:00:00",
						"sumRadioAppCount": 201,
						"timestamp": "2022-05-04T00:01:04.900162",
						"trafficBinSize": 0.421052631578947,
						"trafficKde": [0.0710729656726055, 0.178184023801038, 0.273961914466051, 0.258041184389155, 0.14927526516272, 0.0535852606368312, 0.0123569182273704, 0.00211376915806134],
						"uniqueClients": 515,
						"utilizationBinSize": 0.526315789473684,
						"utilizationKde": [0.0561381281401066, 0.264890641508818, 0.413460060426546, 0.216219922867042, 0.0417731319419598, 0.00506792951911567, 0.00112206484549827]
					}],
					"totalCount": 1
				}
			}
		},
		"getApDetailforLowAPDensity14Template":
		{
			"data": {
				"getClientInsightRadios36": {
					"__typename": "GetClientInsightRadios36Connection",
					"nodes": [{
						"__typename": "ClientInsightRadios36Type",
						"aesApMac": "aes:a0:f8:49:7e:c0:67",
						"aesApName": "aes:PLS06-9120-01",
						"apEntityId": "033a4fcc168e34e007ff",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"apModel": "C9120AXP-B",
						"channelChangeCountBinSize": 0.0526315789473684,
						"channelChangeCountKde": [0.083822086617284, 0.0833589823476091, 0.0819849645572028, 0.0797450794734102, 0.0767116722166308, 0.0729805072186311, 0.0686657515323493, 0.0638941873315881, 0.0587990618058376, 0.0535139877826142, 0.0481672782345456, 0.0428770374651486, 0.0377472491444952, 0.0328650061081307, 0.0282989290177146, 0.0240987299537028, 0.0202958003987504, 0.0169046461281695, 0.0139249568323512, 0.011344085833834],
						"clientCountBinSize": 1,
						"clientCountKde": [0.11231986074631, 0.127544255895216, 0.108709890672446, 0.0866803254948043, 0.0743788709639047, 0.0631732892077653, 0.0465433909920114, 0.0305264882484436, 0.0223606262509795, 0.0206160940421111, 0.0213769254685013, 0.0234176589471303, 0.0235557473203755, 0.0195064000763603, 0.0154331431762263, 0.014008557837049, 0.0142890475477616, 0.0150108980189836, 0.0146848616307581, 0.0140016486482662, 0.0142100082951852, 0.0145862051106546, 0.0145226172674651, 0.0133897646989308, 0.0104964675264401, 0.00698600815447079, 0.00478829568137632, 0.00453131299452321, 0.004716263014095, 0.00394133746058997, 0.00283555966847108, 0.00216177339860342, 0.00212048749799105, 0.00269042588234176, 0.00328589741893577, 0.00347386739250027, 0.00334113314905193, 0.00305166100828977, 0.00313372528486764, 0.00345062829493951, 0.0034597509282098, 0.00307848583628741, 0.00235174303790303],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.469641044903892, 0.0635590058618685, 0, 0.0155767629991111, 0.134001548471666, 0.158308597916441, 0.0193908143492572, 0.00234248858188444, 0.0210331473803652, 0.0387769093951492, 0.0400056540708376, 0.00484777962757196, 0.00231407677344397, 0.0169581080667789, 0.00307981872997088, 0.00581295420476014, 0, 0.00129864870312348, 0.00149013981172607, 0],
						"endTimestamp": "2022-05-04T00:00:00",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 458,
						"insightUid": "a8f0e072547510c1c3b7",
						"interferenceBinSize": 0.105263157894737,
						"interferenceKde": [0.151739273784223, 0.148544155246536, 0.139175892226052, 0.12483232121787, 0.107226411560623, 0.0882521902037884, 0.069655437630672, 0.0527865490717094, 0.038478488341738, 0.0270511701376229, 0.0184102396268241, 0.0121922645769813, 0.00791056059379053, 0.00507037123669827, 0.00324054175951569, 0.00209227793077891, 0.00136769825374197],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 632,
							"key": 1
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 4,
							"key": 0
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00135132168706628, 0.00389311471046319, 0, 0, 0, 0.00251666779592877, 0.0177636285743175, 0.00941756085639048, 0.00999195119835675, 0.0614142397780459, 0.0542904883372523, 0.0761842390651603, 0.149929275733484, 0.128515482866976, 0.088571987640219, 0.131800666691148, 0.0765434456337567, 0.0516677557196663, 0.0318451343660347, 0.0246473248215052, 0.0140500070187527, 0.0165260877837791, 0.0139835137092238, 0.0046736789259411, 0.00315027738729535, 0.00251641203334827, 0.0026449313493509, 0.0027259512099855, 0.00303193206053272, 0.00210877587765974, 0.00131318964954871, 0.00364523214690352, 0.00238007710672879, 0.00390248360036377, 0.00300316466481488],
						"packetFailureKde": null,
						"packetRetryKde": null,
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00962005683460532, 0.205423697322439, 0.56991249168591, 0.205423697322434, 0.00962005683461132],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 4
						}],
						"primaryImageVersion": "8.5.182.0",
						"rootCauseCategory": "Low density",
						"rootCauseCategoryId": "a70b30fe3321351b19dd",
						"rootCauseFeatures": [{
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgPower",
							"kpi": "powerKde",
							"weight": 0.0944625407166124
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": null,
							"feature": "model",
							"kpi": "apModel",
							"weight": 0.0749185667752443
						}],
						"rootCauseId": "746392",
						"rssiKde": [0.0638122499804476, 0.185204914485372, 0.194405855895425, 0.118960718007234, 0.0678265634911907, 0.0463860193050237, 0.0386925550150975, 0.0390393549419514, 0.0471964549350915, 0.0333229143947736, 0.0181107525774139, 0.0164716013979747, 0.0166099515993335, 0.0124269230971927, 0.0102594617962993, 0.00846837862646318, 0.0068275173830783, 0.00493140225518164, 0.00451096098689541, 0.00542639317324911, 0.0043836175987792, 0.00214800661337922, 0.00224928506779028, 0.00212486567253136, 0.00247528636337413, 0.00333842630370973, 0.00349291237439805, 0.00231948017303434, 0.00224608378944086, 0.00240720047899263, 0.00119896272331677, 0, 0, 0.00150609946556604, 0.00264524833476691, 0.00212031436337849, 0.00164267559607408, 0.00198513536295184, 0.00197144413110178, 0.00253816695748544, 0.0050877280041983, 0.00468831158438852, 0.00220725740380672, 0.00166607429028022, 0.00111041318305844, 0.00202993878118383],
						"severity": "s0",
						"siteHierarchyArray": ["Global / North America / USA / California / Pleasanton / PLS06 / Flr-PLS06-1"],
						"siteId": "9cebf26a-edb4-43b5-a9b6-cf1fa30af5e5",
						"snrKde": [0.0185895227176899, 0.026437317412647, 0.0511787638904145, 0.116878106776148, 0.188901732997888, 0.168640121455706, 0.108712151981975, 0.0627695132100989, 0.0406657817514037, 0.0314168476606176, 0.0287312127900033, 0.0232876653586749, 0.0136850441668234, 0.00867780399790457, 0.0093778999618918, 0.0100821798941026, 0.00943409170630676, 0.00821079793052716, 0.00600376388953167, 0.00424296594805001, 0.00261733294487072, 0.00307773019319264, 0.0036188237241854, 0.00250626806436921, 0.00223051201669, 0.00296722802576196, 0.00278850731042144, 0.00188399323069004, 0.00114620945603339, 0.00173236185028041, 0.00204290737880699, 0.00273043950514847, 0.00284068224737248, 0.00126601031556517, 0, 0, 0.00191315608472914, 0.00294947458419955, 0.00326840631812439, 0.002443680345673, 0.00135498371875663, 0.00164277329565418, 0.00307763510698171, 0.00459722047641202, 0.00362909298092257, 0.00200263566867418, 0.00108119257536572, 0, 0, 0, 0, 0.00153147456687218],
						"speedBins": [0, 12.2253521126761, 24.4507042253521, 36.6760563380282, 48.9014084507042, 61.1267605633803, 73.3521126760563, 85.5774647887324, 97.8028169014084, 110.028169014085, 122.253521126761, 134.478873239437, 146.704225352113, 158.929577464789, 171.154929577465, 183.380281690141, 195.605633802817, 207.830985915493, 220.056338028169, 232.281690140845, 244.507042253521, 256.732394366197, 268.957746478873, 281.183098591549, 293.408450704225, 305.633802816901, 317.859154929577, 330.084507042254, 342.30985915493, 354.535211267606, 366.760563380282, 378.985915492958, 391.211267605634, 403.43661971831, 415.661971830986, 427.887323943662, 440.112676056338, 452.338028169014, 464.56338028169, 476.788732394366, 489.014084507042, 501.239436619718, 513.464788732394, 525.69014084507, 537.915492957746, 550.140845070423, 562.366197183099, 574.591549295775, 586.816901408451, 599.042253521127, 611.267605633803, 623.492957746479, 635.718309859155, 647.943661971831, 660.169014084507, 672.394366197183, 684.619718309859, 696.845070422535, 709.070422535211, 721.295774647887, 733.521126760563, 745.746478873239, 757.971830985916, 770.197183098591, 782.422535211268, 794.647887323944, 806.87323943662, 819.098591549296, 831.323943661972, 843.549295774648, 855.774647887324, 868],
						"speedDensity": [0.19865571321882, 0.015932287776948, 0.380383370674633, 0.0019915359721185, 0.00124470998257406, 0.101319392581528, 0, 0.0283793876026886, 0, 0.000248941996514812, 0.0268857356235997, 0.00398307194423699, 0.000248941996514812, 0, 0, 0.0428180234005477, 0.000746825989544436, 0, 0, 0, 0, 0.0206621857107294, 0, 0.00448095593726662, 0, 0, 0.00896191187453325, 0, 0, 0, 0, 0.0194174757281554, 0, 0, 0, 0.00771720189195915, 0, 0, 0, 0, 0, 0, 0.00995767986059245, 0, 0, 0, 0, 0.0253920836445108, 0, 0, 0, 0, 0, 0.035598705501618, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0204132437142145, 0, 0, 0, 0, 0, 0, 0.0445606173761512],
						"startTimestamp": "2022-04-07T04:00:00",
						"sumRadioAppCount": 201,
						"timestamp": "2022-05-04T00:01:04.900162",
						"trafficBinSize": 0.421052631578947,
						"trafficKde": [0.0710729656726055, 0.178184023801038, 0.273961914466051, 0.258041184389155, 0.14927526516272, 0.0535852606368312, 0.0123569182273704, 0.00211376915806134],
						"uniqueClients": 515,
						"utilizationBinSize": 0.526315789473684,
						"utilizationKde": [0.0561381281401066, 0.264890641508818, 0.413460060426546, 0.216219922867042, 0.0417731319419598, 0.00506792951911567, 0.00112206484549827]
					}],
					"totalCount": 1
				}
			}
		},
"getApDetailforLowAPDensity15Template":
		{
			"data": {
				"getClientInsightRadios36": {
					"__typename": "GetClientInsightRadios36Connection",
					"nodes": [{
						"__typename": "ClientInsightRadios36Type",
						"aesApMac": "aes:a0:f8:49:7e:c0:66",
						"aesApName": "aes:APF4DB.E6CE.4F24",
						"apEntityId": "033a4fcc168e34e007ff",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"apModel": "AIR-AP4800-A-K9",
						"channelChangeCountBinSize": 0.0526315789473684,
						"channelChangeCountKde": [0.083822086617284, 0.0833589823476091, 0.0819849645572028, 0.0797450794734102, 0.0767116722166308, 0.0729805072186311, 0.0686657515323493, 0.0638941873315881, 0.0587990618058376, 0.0535139877826142, 0.0481672782345456, 0.0428770374651486, 0.0377472491444952, 0.0328650061081307, 0.0282989290177146, 0.0240987299537028, 0.0202958003987504, 0.0169046461281695, 0.0139249568323512, 0.011344085833834],
						"clientCountBinSize": 1,
						"clientCountKde": [0.11231986074631, 0.127544255895216, 0.108709890672446, 0.0866803254948043, 0.0743788709639047, 0.0631732892077653, 0.0465433909920114, 0.0305264882484436, 0.0223606262509795, 0.0206160940421111, 0.0213769254685013, 0.0234176589471303, 0.0235557473203755, 0.0195064000763603, 0.0154331431762263, 0.014008557837049, 0.0142890475477616, 0.0150108980189836, 0.0146848616307581, 0.0140016486482662, 0.0142100082951852, 0.0145862051106546, 0.0145226172674651, 0.0133897646989308, 0.0104964675264401, 0.00698600815447079, 0.00478829568137632, 0.00453131299452321, 0.004716263014095, 0.00394133746058997, 0.00283555966847108, 0.00216177339860342, 0.00212048749799105, 0.00269042588234176, 0.00328589741893577, 0.00347386739250027, 0.00334113314905193, 0.00305166100828977, 0.00313372528486764, 0.00345062829493951, 0.0034597509282098, 0.00307848583628741, 0.00235174303790303],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.469641044903892, 0.0635590058618685, 0, 0.0155767629991111, 0.134001548471666, 0.158308597916441, 0.0193908143492572, 0.00234248858188444, 0.0210331473803652, 0.0387769093951492, 0.0400056540708376, 0.00484777962757196, 0.00231407677344397, 0.0169581080667789, 0.00307981872997088, 0.00581295420476014, 0, 0.00129864870312348, 0.00149013981172607, 0],
						"endTimestamp": "2022-05-04T00:00:00",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 458,
						"insightUid": "a8f0e072547510c1c3b7",
						"interferenceBinSize": 0.105263157894737,
						"interferenceKde": [0.151739273784223, 0.148544155246536, 0.139175892226052, 0.12483232121787, 0.107226411560623, 0.0882521902037884, 0.069655437630672, 0.0527865490717094, 0.038478488341738, 0.0270511701376229, 0.0184102396268241, 0.0121922645769813, 0.00791056059379053, 0.00507037123669827, 0.00324054175951569, 0.00209227793077891, 0.00136769825374197],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 632,
							"key": 1
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 4,
							"key": 0
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00135132168706628, 0.00389311471046319, 0, 0, 0, 0.00251666779592877, 0.0177636285743175, 0.00941756085639048, 0.00999195119835675, 0.0614142397780459, 0.0542904883372523, 0.0761842390651603, 0.149929275733484, 0.128515482866976, 0.088571987640219, 0.131800666691148, 0.0765434456337567, 0.0516677557196663, 0.0318451343660347, 0.0246473248215052, 0.0140500070187527, 0.0165260877837791, 0.0139835137092238, 0.0046736789259411, 0.00315027738729535, 0.00251641203334827, 0.0026449313493509, 0.0027259512099855, 0.00303193206053272, 0.00210877587765974, 0.00131318964954871, 0.00364523214690352, 0.00238007710672879, 0.00390248360036377, 0.00300316466481488],
						"packetFailureKde": null,
						"packetRetryKde": null,
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00962005683460532, 0.205423697322439, 0.56991249168591, 0.205423697322434, 0.00962005683461132],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 4
						}],
						"primaryImageVersion": "8.5.182.0",
						"rootCauseCategory": "Low density",
						"rootCauseCategoryId": "a70b30fe3321351b19dd",
						"rootCauseFeatures": [{
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgPower",
							"kpi": "powerKde",
							"weight": 0.0944625407166124
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": null,
							"feature": "model",
							"kpi": "apModel",
							"weight": 0.0749185667752443
						}],
						"rootCauseId": "746392",
						"rssiKde": [0.0638122499804476, 0.185204914485372, 0.194405855895425, 0.118960718007234, 0.0678265634911907, 0.0463860193050237, 0.0386925550150975, 0.0390393549419514, 0.0471964549350915, 0.0333229143947736, 0.0181107525774139, 0.0164716013979747, 0.0166099515993335, 0.0124269230971927, 0.0102594617962993, 0.00846837862646318, 0.0068275173830783, 0.00493140225518164, 0.00451096098689541, 0.00542639317324911, 0.0043836175987792, 0.00214800661337922, 0.00224928506779028, 0.00212486567253136, 0.00247528636337413, 0.00333842630370973, 0.00349291237439805, 0.00231948017303434, 0.00224608378944086, 0.00240720047899263, 0.00119896272331677, 0, 0, 0.00150609946556604, 0.00264524833476691, 0.00212031436337849, 0.00164267559607408, 0.00198513536295184, 0.00197144413110178, 0.00253816695748544, 0.0050877280041983, 0.00468831158438852, 0.00220725740380672, 0.00166607429028022, 0.00111041318305844, 0.00202993878118383],
						"severity": "s0",
						"siteHierarchyArray": ["Global / North America / USA / California / San Jose / SJC22 / Flr-SJC22-1"],
						"siteId": "9cebf26a-edb4-43b5-a9b6-cf1fa30af5e5",
						"snrKde": [0.0185895227176899, 0.026437317412647, 0.0511787638904145, 0.116878106776148, 0.188901732997888, 0.168640121455706, 0.108712151981975, 0.0627695132100989, 0.0406657817514037, 0.0314168476606176, 0.0287312127900033, 0.0232876653586749, 0.0136850441668234, 0.00867780399790457, 0.0093778999618918, 0.0100821798941026, 0.00943409170630676, 0.00821079793052716, 0.00600376388953167, 0.00424296594805001, 0.00261733294487072, 0.00307773019319264, 0.0036188237241854, 0.00250626806436921, 0.00223051201669, 0.00296722802576196, 0.00278850731042144, 0.00188399323069004, 0.00114620945603339, 0.00173236185028041, 0.00204290737880699, 0.00273043950514847, 0.00284068224737248, 0.00126601031556517, 0, 0, 0.00191315608472914, 0.00294947458419955, 0.00326840631812439, 0.002443680345673, 0.00135498371875663, 0.00164277329565418, 0.00307763510698171, 0.00459722047641202, 0.00362909298092257, 0.00200263566867418, 0.00108119257536572, 0, 0, 0, 0, 0.00153147456687218],
						"speedBins": [0, 12.2253521126761, 24.4507042253521, 36.6760563380282, 48.9014084507042, 61.1267605633803, 73.3521126760563, 85.5774647887324, 97.8028169014084, 110.028169014085, 122.253521126761, 134.478873239437, 146.704225352113, 158.929577464789, 171.154929577465, 183.380281690141, 195.605633802817, 207.830985915493, 220.056338028169, 232.281690140845, 244.507042253521, 256.732394366197, 268.957746478873, 281.183098591549, 293.408450704225, 305.633802816901, 317.859154929577, 330.084507042254, 342.30985915493, 354.535211267606, 366.760563380282, 378.985915492958, 391.211267605634, 403.43661971831, 415.661971830986, 427.887323943662, 440.112676056338, 452.338028169014, 464.56338028169, 476.788732394366, 489.014084507042, 501.239436619718, 513.464788732394, 525.69014084507, 537.915492957746, 550.140845070423, 562.366197183099, 574.591549295775, 586.816901408451, 599.042253521127, 611.267605633803, 623.492957746479, 635.718309859155, 647.943661971831, 660.169014084507, 672.394366197183, 684.619718309859, 696.845070422535, 709.070422535211, 721.295774647887, 733.521126760563, 745.746478873239, 757.971830985916, 770.197183098591, 782.422535211268, 794.647887323944, 806.87323943662, 819.098591549296, 831.323943661972, 843.549295774648, 855.774647887324, 868],
						"speedDensity": [0.19865571321882, 0.015932287776948, 0.380383370674633, 0.0019915359721185, 0.00124470998257406, 0.101319392581528, 0, 0.0283793876026886, 0, 0.000248941996514812, 0.0268857356235997, 0.00398307194423699, 0.000248941996514812, 0, 0, 0.0428180234005477, 0.000746825989544436, 0, 0, 0, 0, 0.0206621857107294, 0, 0.00448095593726662, 0, 0, 0.00896191187453325, 0, 0, 0, 0, 0.0194174757281554, 0, 0, 0, 0.00771720189195915, 0, 0, 0, 0, 0, 0, 0.00995767986059245, 0, 0, 0, 0, 0.0253920836445108, 0, 0, 0, 0, 0, 0.035598705501618, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0204132437142145, 0, 0, 0, 0, 0, 0, 0.0445606173761512],
						"startTimestamp": "2022-04-07T04:00:00",
						"sumRadioAppCount": 201,
						"timestamp": "2022-05-04T00:01:04.900162",
						"trafficBinSize": 0.421052631578947,
						"trafficKde": [0.0710729656726055, 0.178184023801038, 0.273961914466051, 0.258041184389155, 0.14927526516272, 0.0535852606368312, 0.0123569182273704, 0.00211376915806134],
						"uniqueClients": 515,
						"utilizationBinSize": 0.526315789473684,
						"utilizationKde": [0.0561381281401066, 0.264890641508818, 0.413460060426546, 0.216219922867042, 0.0417731319419598, 0.00506792951911567, 0.00112206484549827]
					}],
					"totalCount": 1
				}
			}
		},
"getApDetailforLowAPDensity16Template":
		{
			"data": {
				"getClientInsightRadios36": {
					"__typename": "GetClientInsightRadios36Connection",
					"nodes": [{
						"__typename": "ClientInsightRadios36Type",
						"aesApMac": "aes:a0:f8:49:7e:c0:58",
						"aesApName": "aes:SFO15-C9136-04",
						"apEntityId": "033a4fcc168e34e007ff",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"apModel": "C9136I-B",
						"channelChangeCountBinSize": 0.0526315789473684,
						"channelChangeCountKde": [0.083822086617284, 0.0833589823476091, 0.0819849645572028, 0.0797450794734102, 0.0767116722166308, 0.0729805072186311, 0.0686657515323493, 0.0638941873315881, 0.0587990618058376, 0.0535139877826142, 0.0481672782345456, 0.0428770374651486, 0.0377472491444952, 0.0328650061081307, 0.0282989290177146, 0.0240987299537028, 0.0202958003987504, 0.0169046461281695, 0.0139249568323512, 0.011344085833834],
						"clientCountBinSize": 1,
						"clientCountKde": [0.11231986074631, 0.127544255895216, 0.108709890672446, 0.0866803254948043, 0.0743788709639047, 0.0631732892077653, 0.0465433909920114, 0.0305264882484436, 0.0223606262509795, 0.0206160940421111, 0.0213769254685013, 0.0234176589471303, 0.0235557473203755, 0.0195064000763603, 0.0154331431762263, 0.014008557837049, 0.0142890475477616, 0.0150108980189836, 0.0146848616307581, 0.0140016486482662, 0.0142100082951852, 0.0145862051106546, 0.0145226172674651, 0.0133897646989308, 0.0104964675264401, 0.00698600815447079, 0.00478829568137632, 0.00453131299452321, 0.004716263014095, 0.00394133746058997, 0.00283555966847108, 0.00216177339860342, 0.00212048749799105, 0.00269042588234176, 0.00328589741893577, 0.00347386739250027, 0.00334113314905193, 0.00305166100828977, 0.00313372528486764, 0.00345062829493951, 0.0034597509282098, 0.00307848583628741, 0.00235174303790303],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.469641044903892, 0.0635590058618685, 0, 0.0155767629991111, 0.134001548471666, 0.158308597916441, 0.0193908143492572, 0.00234248858188444, 0.0210331473803652, 0.0387769093951492, 0.0400056540708376, 0.00484777962757196, 0.00231407677344397, 0.0169581080667789, 0.00307981872997088, 0.00581295420476014, 0, 0.00129864870312348, 0.00149013981172607, 0],
						"endTimestamp": "2022-05-04T00:00:00",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 458,
						"insightUid": "a8f0e072547510c1c3b7",
						"interferenceBinSize": 0.105263157894737,
						"interferenceKde": [0.151739273784223, 0.148544155246536, 0.139175892226052, 0.12483232121787, 0.107226411560623, 0.0882521902037884, 0.069655437630672, 0.0527865490717094, 0.038478488341738, 0.0270511701376229, 0.0184102396268241, 0.0121922645769813, 0.00791056059379053, 0.00507037123669827, 0.00324054175951569, 0.00209227793077891, 0.00136769825374197],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 632,
							"key": 1
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 4,
							"key": 0
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00135132168706628, 0.00389311471046319, 0, 0, 0, 0.00251666779592877, 0.0177636285743175, 0.00941756085639048, 0.00999195119835675, 0.0614142397780459, 0.0542904883372523, 0.0761842390651603, 0.149929275733484, 0.128515482866976, 0.088571987640219, 0.131800666691148, 0.0765434456337567, 0.0516677557196663, 0.0318451343660347, 0.0246473248215052, 0.0140500070187527, 0.0165260877837791, 0.0139835137092238, 0.0046736789259411, 0.00315027738729535, 0.00251641203334827, 0.0026449313493509, 0.0027259512099855, 0.00303193206053272, 0.00210877587765974, 0.00131318964954871, 0.00364523214690352, 0.00238007710672879, 0.00390248360036377, 0.00300316466481488],
						"packetFailureKde": null,
						"packetRetryKde": null,
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00962005683460532, 0.205423697322439, 0.56991249168591, 0.205423697322434, 0.00962005683461132],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 4
						}],
						"primaryImageVersion": "8.5.182.0",
						"rootCauseCategory": "Low density",
						"rootCauseCategoryId": "a70b30fe3321351b19dd",
						"rootCauseFeatures": [{
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgPower",
							"kpi": "powerKde",
							"weight": 0.0944625407166124
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": null,
							"feature": "model",
							"kpi": "apModel",
							"weight": 0.0749185667752443
						}],
						"rootCauseId": "746392",
						"rssiKde": [0.0638122499804476, 0.185204914485372, 0.194405855895425, 0.118960718007234, 0.0678265634911907, 0.0463860193050237, 0.0386925550150975, 0.0390393549419514, 0.0471964549350915, 0.0333229143947736, 0.0181107525774139, 0.0164716013979747, 0.0166099515993335, 0.0124269230971927, 0.0102594617962993, 0.00846837862646318, 0.0068275173830783, 0.00493140225518164, 0.00451096098689541, 0.00542639317324911, 0.0043836175987792, 0.00214800661337922, 0.00224928506779028, 0.00212486567253136, 0.00247528636337413, 0.00333842630370973, 0.00349291237439805, 0.00231948017303434, 0.00224608378944086, 0.00240720047899263, 0.00119896272331677, 0, 0, 0.00150609946556604, 0.00264524833476691, 0.00212031436337849, 0.00164267559607408, 0.00198513536295184, 0.00197144413110178, 0.00253816695748544, 0.0050877280041983, 0.00468831158438852, 0.00220725740380672, 0.00166607429028022, 0.00111041318305844, 0.00202993878118383],
						"severity": "s0",
						"siteHierarchyArray": ["Global / North America / USA / California / San Francisco / SFO15 / Flr-SFO15-1"],
						"siteId": "9cebf26a-edb4-43b5-a9b6-cf1fa30af5e5",
						"snrKde": [0.0185895227176899, 0.026437317412647, 0.0511787638904145, 0.116878106776148, 0.188901732997888, 0.168640121455706, 0.108712151981975, 0.0627695132100989, 0.0406657817514037, 0.0314168476606176, 0.0287312127900033, 0.0232876653586749, 0.0136850441668234, 0.00867780399790457, 0.0093778999618918, 0.0100821798941026, 0.00943409170630676, 0.00821079793052716, 0.00600376388953167, 0.00424296594805001, 0.00261733294487072, 0.00307773019319264, 0.0036188237241854, 0.00250626806436921, 0.00223051201669, 0.00296722802576196, 0.00278850731042144, 0.00188399323069004, 0.00114620945603339, 0.00173236185028041, 0.00204290737880699, 0.00273043950514847, 0.00284068224737248, 0.00126601031556517, 0, 0, 0.00191315608472914, 0.00294947458419955, 0.00326840631812439, 0.002443680345673, 0.00135498371875663, 0.00164277329565418, 0.00307763510698171, 0.00459722047641202, 0.00362909298092257, 0.00200263566867418, 0.00108119257536572, 0, 0, 0, 0, 0.00153147456687218],
						"speedBins": [0, 12.2253521126761, 24.4507042253521, 36.6760563380282, 48.9014084507042, 61.1267605633803, 73.3521126760563, 85.5774647887324, 97.8028169014084, 110.028169014085, 122.253521126761, 134.478873239437, 146.704225352113, 158.929577464789, 171.154929577465, 183.380281690141, 195.605633802817, 207.830985915493, 220.056338028169, 232.281690140845, 244.507042253521, 256.732394366197, 268.957746478873, 281.183098591549, 293.408450704225, 305.633802816901, 317.859154929577, 330.084507042254, 342.30985915493, 354.535211267606, 366.760563380282, 378.985915492958, 391.211267605634, 403.43661971831, 415.661971830986, 427.887323943662, 440.112676056338, 452.338028169014, 464.56338028169, 476.788732394366, 489.014084507042, 501.239436619718, 513.464788732394, 525.69014084507, 537.915492957746, 550.140845070423, 562.366197183099, 574.591549295775, 586.816901408451, 599.042253521127, 611.267605633803, 623.492957746479, 635.718309859155, 647.943661971831, 660.169014084507, 672.394366197183, 684.619718309859, 696.845070422535, 709.070422535211, 721.295774647887, 733.521126760563, 745.746478873239, 757.971830985916, 770.197183098591, 782.422535211268, 794.647887323944, 806.87323943662, 819.098591549296, 831.323943661972, 843.549295774648, 855.774647887324, 868],
						"speedDensity": [0.19865571321882, 0.015932287776948, 0.380383370674633, 0.0019915359721185, 0.00124470998257406, 0.101319392581528, 0, 0.0283793876026886, 0, 0.000248941996514812, 0.0268857356235997, 0.00398307194423699, 0.000248941996514812, 0, 0, 0.0428180234005477, 0.000746825989544436, 0, 0, 0, 0, 0.0206621857107294, 0, 0.00448095593726662, 0, 0, 0.00896191187453325, 0, 0, 0, 0, 0.0194174757281554, 0, 0, 0, 0.00771720189195915, 0, 0, 0, 0, 0, 0, 0.00995767986059245, 0, 0, 0, 0, 0.0253920836445108, 0, 0, 0, 0, 0, 0.035598705501618, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0204132437142145, 0, 0, 0, 0, 0, 0, 0.0445606173761512],
						"startTimestamp": "2022-04-07T04:00:00",
						"sumRadioAppCount": 201,
						"timestamp": "2022-05-04T00:01:04.900162",
						"trafficBinSize": 0.421052631578947,
						"trafficKde": [0.0710729656726055, 0.178184023801038, 0.273961914466051, 0.258041184389155, 0.14927526516272, 0.0535852606368312, 0.0123569182273704, 0.00211376915806134],
						"uniqueClients": 515,
						"utilizationBinSize": 0.526315789473684,
						"utilizationKde": [0.0561381281401066, 0.264890641508818, 0.413460060426546, 0.216219922867042, 0.0417731319419598, 0.00506792951911567, 0.00112206484549827]
					}],
					"totalCount": 1
				}
			}
		},
		"getApDetailforLowAPDensity17Template":
		{
			"data": {
				"getClientInsightRadios36": {
					"__typename": "GetClientInsightRadios36Connection",
					"nodes": [{
						"__typename": "ClientInsightRadios36Type",
						"aesApMac": "aes:a0:f8:49:7e:c0:40",
						"aesApName": "aes:SJC01_9136_1",
						"apEntityId": "033a4fcc168e34e007ff",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"apModel": "C9136I-B",
						"channelChangeCountBinSize": 0.0526315789473684,
						"channelChangeCountKde": [0.083822086617284, 0.0833589823476091, 0.0819849645572028, 0.0797450794734102, 0.0767116722166308, 0.0729805072186311, 0.0686657515323493, 0.0638941873315881, 0.0587990618058376, 0.0535139877826142, 0.0481672782345456, 0.0428770374651486, 0.0377472491444952, 0.0328650061081307, 0.0282989290177146, 0.0240987299537028, 0.0202958003987504, 0.0169046461281695, 0.0139249568323512, 0.011344085833834],
						"clientCountBinSize": 1,
						"clientCountKde": [0.11231986074631, 0.127544255895216, 0.108709890672446, 0.0866803254948043, 0.0743788709639047, 0.0631732892077653, 0.0465433909920114, 0.0305264882484436, 0.0223606262509795, 0.0206160940421111, 0.0213769254685013, 0.0234176589471303, 0.0235557473203755, 0.0195064000763603, 0.0154331431762263, 0.014008557837049, 0.0142890475477616, 0.0150108980189836, 0.0146848616307581, 0.0140016486482662, 0.0142100082951852, 0.0145862051106546, 0.0145226172674651, 0.0133897646989308, 0.0104964675264401, 0.00698600815447079, 0.00478829568137632, 0.00453131299452321, 0.004716263014095, 0.00394133746058997, 0.00283555966847108, 0.00216177339860342, 0.00212048749799105, 0.00269042588234176, 0.00328589741893577, 0.00347386739250027, 0.00334113314905193, 0.00305166100828977, 0.00313372528486764, 0.00345062829493951, 0.0034597509282098, 0.00307848583628741, 0.00235174303790303],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.469641044903892, 0.0635590058618685, 0, 0.0155767629991111, 0.134001548471666, 0.158308597916441, 0.0193908143492572, 0.00234248858188444, 0.0210331473803652, 0.0387769093951492, 0.0400056540708376, 0.00484777962757196, 0.00231407677344397, 0.0169581080667789, 0.00307981872997088, 0.00581295420476014, 0, 0.00129864870312348, 0.00149013981172607, 0],
						"endTimestamp": "2022-05-04T00:00:00",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 458,
						"insightUid": "a8f0e072547510c1c3b7",
						"interferenceBinSize": 0.105263157894737,
						"interferenceKde": [0.151739273784223, 0.148544155246536, 0.139175892226052, 0.12483232121787, 0.107226411560623, 0.0882521902037884, 0.069655437630672, 0.0527865490717094, 0.038478488341738, 0.0270511701376229, 0.0184102396268241, 0.0121922645769813, 0.00791056059379053, 0.00507037123669827, 0.00324054175951569, 0.00209227793077891, 0.00136769825374197],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 632,
							"key": 1
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 4,
							"key": 0
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00135132168706628, 0.00389311471046319, 0, 0, 0, 0.00251666779592877, 0.0177636285743175, 0.00941756085639048, 0.00999195119835675, 0.0614142397780459, 0.0542904883372523, 0.0761842390651603, 0.149929275733484, 0.128515482866976, 0.088571987640219, 0.131800666691148, 0.0765434456337567, 0.0516677557196663, 0.0318451343660347, 0.0246473248215052, 0.0140500070187527, 0.0165260877837791, 0.0139835137092238, 0.0046736789259411, 0.00315027738729535, 0.00251641203334827, 0.0026449313493509, 0.0027259512099855, 0.00303193206053272, 0.00210877587765974, 0.00131318964954871, 0.00364523214690352, 0.00238007710672879, 0.00390248360036377, 0.00300316466481488],
						"packetFailureKde": null,
						"packetRetryKde": null,
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00962005683460532, 0.205423697322439, 0.56991249168591, 0.205423697322434, 0.00962005683461132],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 4
						}],
						"primaryImageVersion": "8.5.182.0",
						"rootCauseCategory": "Low density",
						"rootCauseCategoryId": "a70b30fe3321351b19dd",
						"rootCauseFeatures": [{
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgPower",
							"kpi": "powerKde",
							"weight": 0.0944625407166124
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": null,
							"feature": "model",
							"kpi": "apModel",
							"weight": 0.0749185667752443
						}],
						"rootCauseId": "746392",
						"rssiKde": [0.0638122499804476, 0.185204914485372, 0.194405855895425, 0.118960718007234, 0.0678265634911907, 0.0463860193050237, 0.0386925550150975, 0.0390393549419514, 0.0471964549350915, 0.0333229143947736, 0.0181107525774139, 0.0164716013979747, 0.0166099515993335, 0.0124269230971927, 0.0102594617962993, 0.00846837862646318, 0.0068275173830783, 0.00493140225518164, 0.00451096098689541, 0.00542639317324911, 0.0043836175987792, 0.00214800661337922, 0.00224928506779028, 0.00212486567253136, 0.00247528636337413, 0.00333842630370973, 0.00349291237439805, 0.00231948017303434, 0.00224608378944086, 0.00240720047899263, 0.00119896272331677, 0, 0, 0.00150609946556604, 0.00264524833476691, 0.00212031436337849, 0.00164267559607408, 0.00198513536295184, 0.00197144413110178, 0.00253816695748544, 0.0050877280041983, 0.00468831158438852, 0.00220725740380672, 0.00166607429028022, 0.00111041318305844, 0.00202993878118383],
						"severity": "s0",
						"siteHierarchyArray": ["Global / North America / USA / California / San Jose / SJC01 / Flr-SJC1-1"],
						"siteId": "9cebf26a-edb4-43b5-a9b6-cf1fa30af5e5",
						"snrKde": [0.0185895227176899, 0.026437317412647, 0.0511787638904145, 0.116878106776148, 0.188901732997888, 0.168640121455706, 0.108712151981975, 0.0627695132100989, 0.0406657817514037, 0.0314168476606176, 0.0287312127900033, 0.0232876653586749, 0.0136850441668234, 0.00867780399790457, 0.0093778999618918, 0.0100821798941026, 0.00943409170630676, 0.00821079793052716, 0.00600376388953167, 0.00424296594805001, 0.00261733294487072, 0.00307773019319264, 0.0036188237241854, 0.00250626806436921, 0.00223051201669, 0.00296722802576196, 0.00278850731042144, 0.00188399323069004, 0.00114620945603339, 0.00173236185028041, 0.00204290737880699, 0.00273043950514847, 0.00284068224737248, 0.00126601031556517, 0, 0, 0.00191315608472914, 0.00294947458419955, 0.00326840631812439, 0.002443680345673, 0.00135498371875663, 0.00164277329565418, 0.00307763510698171, 0.00459722047641202, 0.00362909298092257, 0.00200263566867418, 0.00108119257536572, 0, 0, 0, 0, 0.00153147456687218],
						"speedBins": [0, 12.2253521126761, 24.4507042253521, 36.6760563380282, 48.9014084507042, 61.1267605633803, 73.3521126760563, 85.5774647887324, 97.8028169014084, 110.028169014085, 122.253521126761, 134.478873239437, 146.704225352113, 158.929577464789, 171.154929577465, 183.380281690141, 195.605633802817, 207.830985915493, 220.056338028169, 232.281690140845, 244.507042253521, 256.732394366197, 268.957746478873, 281.183098591549, 293.408450704225, 305.633802816901, 317.859154929577, 330.084507042254, 342.30985915493, 354.535211267606, 366.760563380282, 378.985915492958, 391.211267605634, 403.43661971831, 415.661971830986, 427.887323943662, 440.112676056338, 452.338028169014, 464.56338028169, 476.788732394366, 489.014084507042, 501.239436619718, 513.464788732394, 525.69014084507, 537.915492957746, 550.140845070423, 562.366197183099, 574.591549295775, 586.816901408451, 599.042253521127, 611.267605633803, 623.492957746479, 635.718309859155, 647.943661971831, 660.169014084507, 672.394366197183, 684.619718309859, 696.845070422535, 709.070422535211, 721.295774647887, 733.521126760563, 745.746478873239, 757.971830985916, 770.197183098591, 782.422535211268, 794.647887323944, 806.87323943662, 819.098591549296, 831.323943661972, 843.549295774648, 855.774647887324, 868],
						"speedDensity": [0.19865571321882, 0.015932287776948, 0.380383370674633, 0.0019915359721185, 0.00124470998257406, 0.101319392581528, 0, 0.0283793876026886, 0, 0.000248941996514812, 0.0268857356235997, 0.00398307194423699, 0.000248941996514812, 0, 0, 0.0428180234005477, 0.000746825989544436, 0, 0, 0, 0, 0.0206621857107294, 0, 0.00448095593726662, 0, 0, 0.00896191187453325, 0, 0, 0, 0, 0.0194174757281554, 0, 0, 0, 0.00771720189195915, 0, 0, 0, 0, 0, 0, 0.00995767986059245, 0, 0, 0, 0, 0.0253920836445108, 0, 0, 0, 0, 0, 0.035598705501618, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0204132437142145, 0, 0, 0, 0, 0, 0, 0.0445606173761512],
						"startTimestamp": "2022-04-07T04:00:00",
						"sumRadioAppCount": 201,
						"timestamp": "2022-05-04T00:01:04.900162",
						"trafficBinSize": 0.421052631578947,
						"trafficKde": [0.0710729656726055, 0.178184023801038, 0.273961914466051, 0.258041184389155, 0.14927526516272, 0.0535852606368312, 0.0123569182273704, 0.00211376915806134],
						"uniqueClients": 515,
						"utilizationBinSize": 0.526315789473684,
						"utilizationKde": [0.0561381281401066, 0.264890641508818, 0.413460060426546, 0.216219922867042, 0.0417731319419598, 0.00506792951911567, 0.00112206484549827]
					}],
					"totalCount": 1
				}
			}
		},
"getApDetailforLowAPDensity18Template":
		{
			"data": {
				"getClientInsightRadios36": {
					"__typename": "GetClientInsightRadios36Connection",
					"nodes": [{
						"__typename": "ClientInsightRadios36Type",
						"aesApMac": "aes:a0:f8:49:7e:c0:54",
						"aesApName": "aes:PLS06-AP3800-01",
						"apEntityId": "033a4fcc168e34e007ff",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"apModel": "AIR-AP3802I-B-K9",
						"channelChangeCountBinSize": 0.0526315789473684,
						"channelChangeCountKde": [0.083822086617284, 0.0833589823476091, 0.0819849645572028, 0.0797450794734102, 0.0767116722166308, 0.0729805072186311, 0.0686657515323493, 0.0638941873315881, 0.0587990618058376, 0.0535139877826142, 0.0481672782345456, 0.0428770374651486, 0.0377472491444952, 0.0328650061081307, 0.0282989290177146, 0.0240987299537028, 0.0202958003987504, 0.0169046461281695, 0.0139249568323512, 0.011344085833834],
						"clientCountBinSize": 1,
						"clientCountKde": [0.11231986074631, 0.127544255895216, 0.108709890672446, 0.0866803254948043, 0.0743788709639047, 0.0631732892077653, 0.0465433909920114, 0.0305264882484436, 0.0223606262509795, 0.0206160940421111, 0.0213769254685013, 0.0234176589471303, 0.0235557473203755, 0.0195064000763603, 0.0154331431762263, 0.014008557837049, 0.0142890475477616, 0.0150108980189836, 0.0146848616307581, 0.0140016486482662, 0.0142100082951852, 0.0145862051106546, 0.0145226172674651, 0.0133897646989308, 0.0104964675264401, 0.00698600815447079, 0.00478829568137632, 0.00453131299452321, 0.004716263014095, 0.00394133746058997, 0.00283555966847108, 0.00216177339860342, 0.00212048749799105, 0.00269042588234176, 0.00328589741893577, 0.00347386739250027, 0.00334113314905193, 0.00305166100828977, 0.00313372528486764, 0.00345062829493951, 0.0034597509282098, 0.00307848583628741, 0.00235174303790303],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.469641044903892, 0.0635590058618685, 0, 0.0155767629991111, 0.134001548471666, 0.158308597916441, 0.0193908143492572, 0.00234248858188444, 0.0210331473803652, 0.0387769093951492, 0.0400056540708376, 0.00484777962757196, 0.00231407677344397, 0.0169581080667789, 0.00307981872997088, 0.00581295420476014, 0, 0.00129864870312348, 0.00149013981172607, 0],
						"endTimestamp": "2022-05-04T00:00:00",
						"errorFlags": ["rssiKde", "snrKde"],
						"frequencyBand": "5 GHz",
						"impactedClients": 458,
						"insightUid": "a8f0e072547510c1c3b7",
						"interferenceBinSize": 0.105263157894737,
						"interferenceKde": [0.151739273784223, 0.148544155246536, 0.139175892226052, 0.12483232121787, 0.107226411560623, 0.0882521902037884, 0.069655437630672, 0.0527865490717094, 0.038478488341738, 0.0270511701376229, 0.0184102396268241, 0.0121922645769813, 0.00791056059379053, 0.00507037123669827, 0.00324054175951569, 0.00209227793077891, 0.00136769825374197],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 632,
							"key": 1
						}, {
							"__typename": "CiIntKeyCountValue",
							"count": 4,
							"key": 0
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00135132168706628, 0.00389311471046319, 0, 0, 0, 0.00251666779592877, 0.0177636285743175, 0.00941756085639048, 0.00999195119835675, 0.0614142397780459, 0.0542904883372523, 0.0761842390651603, 0.149929275733484, 0.128515482866976, 0.088571987640219, 0.131800666691148, 0.0765434456337567, 0.0516677557196663, 0.0318451343660347, 0.0246473248215052, 0.0140500070187527, 0.0165260877837791, 0.0139835137092238, 0.0046736789259411, 0.00315027738729535, 0.00251641203334827, 0.0026449313493509, 0.0027259512099855, 0.00303193206053272, 0.00210877587765974, 0.00131318964954871, 0.00364523214690352, 0.00238007710672879, 0.00390248360036377, 0.00300316466481488],
						"packetFailureKde": null,
						"packetRetryKde": null,
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00962005683460532, 0.205423697322439, 0.56991249168591, 0.205423697322434, 0.00962005683461132],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 636,
							"key": 4
						}],
						"primaryImageVersion": "8.5.182.0",
						"rootCauseCategory": "Low density",
						"rootCauseCategoryId": "a70b30fe3321351b19dd",
						"rootCauseFeatures": [{
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgPower",
							"kpi": "powerKde",
							"weight": 0.0944625407166124
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": null,
							"feature": "model",
							"kpi": "apModel",
							"weight": 0.0749185667752443
						}],
						"rootCauseId": "746392",
						"rssiKde": [0.0638122499804476, 0.185204914485372, 0.194405855895425, 0.118960718007234, 0.0678265634911907, 0.0463860193050237, 0.0386925550150975, 0.0390393549419514, 0.0471964549350915, 0.0333229143947736, 0.0181107525774139, 0.0164716013979747, 0.0166099515993335, 0.0124269230971927, 0.0102594617962993, 0.00846837862646318, 0.0068275173830783, 0.00493140225518164, 0.00451096098689541, 0.00542639317324911, 0.0043836175987792, 0.00214800661337922, 0.00224928506779028, 0.00212486567253136, 0.00247528636337413, 0.00333842630370973, 0.00349291237439805, 0.00231948017303434, 0.00224608378944086, 0.00240720047899263, 0.00119896272331677, 0, 0, 0.00150609946556604, 0.00264524833476691, 0.00212031436337849, 0.00164267559607408, 0.00198513536295184, 0.00197144413110178, 0.00253816695748544, 0.0050877280041983, 0.00468831158438852, 0.00220725740380672, 0.00166607429028022, 0.00111041318305844, 0.00202993878118383],
						"severity": "s0",
						"siteHierarchyArray": ["Global / North America / USA / California / Pleasanton / PLS06 / Flr-PLS06-1"],
						"siteId": "9cebf26a-edb4-43b5-a9b6-cf1fa30af5e5",
						"snrKde": [0.0185895227176899, 0.026437317412647, 0.0511787638904145, 0.116878106776148, 0.188901732997888, 0.168640121455706, 0.108712151981975, 0.0627695132100989, 0.0406657817514037, 0.0314168476606176, 0.0287312127900033, 0.0232876653586749, 0.0136850441668234, 0.00867780399790457, 0.0093778999618918, 0.0100821798941026, 0.00943409170630676, 0.00821079793052716, 0.00600376388953167, 0.00424296594805001, 0.00261733294487072, 0.00307773019319264, 0.0036188237241854, 0.00250626806436921, 0.00223051201669, 0.00296722802576196, 0.00278850731042144, 0.00188399323069004, 0.00114620945603339, 0.00173236185028041, 0.00204290737880699, 0.00273043950514847, 0.00284068224737248, 0.00126601031556517, 0, 0, 0.00191315608472914, 0.00294947458419955, 0.00326840631812439, 0.002443680345673, 0.00135498371875663, 0.00164277329565418, 0.00307763510698171, 0.00459722047641202, 0.00362909298092257, 0.00200263566867418, 0.00108119257536572, 0, 0, 0, 0, 0.00153147456687218],
						"speedBins": [0, 12.2253521126761, 24.4507042253521, 36.6760563380282, 48.9014084507042, 61.1267605633803, 73.3521126760563, 85.5774647887324, 97.8028169014084, 110.028169014085, 122.253521126761, 134.478873239437, 146.704225352113, 158.929577464789, 171.154929577465, 183.380281690141, 195.605633802817, 207.830985915493, 220.056338028169, 232.281690140845, 244.507042253521, 256.732394366197, 268.957746478873, 281.183098591549, 293.408450704225, 305.633802816901, 317.859154929577, 330.084507042254, 342.30985915493, 354.535211267606, 366.760563380282, 378.985915492958, 391.211267605634, 403.43661971831, 415.661971830986, 427.887323943662, 440.112676056338, 452.338028169014, 464.56338028169, 476.788732394366, 489.014084507042, 501.239436619718, 513.464788732394, 525.69014084507, 537.915492957746, 550.140845070423, 562.366197183099, 574.591549295775, 586.816901408451, 599.042253521127, 611.267605633803, 623.492957746479, 635.718309859155, 647.943661971831, 660.169014084507, 672.394366197183, 684.619718309859, 696.845070422535, 709.070422535211, 721.295774647887, 733.521126760563, 745.746478873239, 757.971830985916, 770.197183098591, 782.422535211268, 794.647887323944, 806.87323943662, 819.098591549296, 831.323943661972, 843.549295774648, 855.774647887324, 868],
						"speedDensity": [0.19865571321882, 0.015932287776948, 0.380383370674633, 0.0019915359721185, 0.00124470998257406, 0.101319392581528, 0, 0.0283793876026886, 0, 0.000248941996514812, 0.0268857356235997, 0.00398307194423699, 0.000248941996514812, 0, 0, 0.0428180234005477, 0.000746825989544436, 0, 0, 0, 0, 0.0206621857107294, 0, 0.00448095593726662, 0, 0, 0.00896191187453325, 0, 0, 0, 0, 0.0194174757281554, 0, 0, 0, 0.00771720189195915, 0, 0, 0, 0, 0, 0, 0.00995767986059245, 0, 0, 0, 0, 0.0253920836445108, 0, 0, 0, 0, 0, 0.035598705501618, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0204132437142145, 0, 0, 0, 0, 0, 0, 0.0445606173761512],
						"startTimestamp": "2022-04-07T04:00:00",
						"sumRadioAppCount": 201,
						"timestamp": "2022-05-04T00:01:04.900162",
						"trafficBinSize": 0.421052631578947,
						"trafficKde": [0.0710729656726055, 0.178184023801038, 0.273961914466051, 0.258041184389155, 0.14927526516272, 0.0535852606368312, 0.0123569182273704, 0.00211376915806134],
						"uniqueClients": 515,
						"utilizationBinSize": 0.526315789473684,
						"utilizationKde": [0.0561381281401066, 0.264890641508818, 0.413460060426546, 0.216219922867042, 0.0417731319419598, 0.00506792951911567, 0.00112206484549827]
					}],
					"totalCount": 1
				}
			}
		},


	"getApDetailforHighCoChannel1Template":
		{
			"data": {
				"getClientInsightRadios36": {
					"__typename": "GetClientInsightRadios36Connection",
					"nodes": [{
						"__typename": "ClientInsightRadios36Type",
						"aesApMac": "a0:f8:49:7e:c0:60",
						"aesApName": "aes:SFO15-C9120-03",
						"apEntityId": "03e3749ed721d0bbe638",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 437,
							"key": 0
						}],
						"apModel": "C9120AXE-C",
						"channelChangeCountBinSize": 0.0526315789473684,
						"channelChangeCountKde": [0.083822086617284, 0.083358982347609, 0.0819849645572029, 0.0797450794734103, 0.0767116722166308, 0.0729805072186312, 0.0686657515323493, 0.0638941873315881, 0.0587990618058377, 0.0535139877826142, 0.0481672782345456, 0.0428770374651486, 0.0377472491444952, 0.0328650061081307, 0.0282989290177146, 0.0240987299537028, 0.0202958003987504, 0.0169046461281695, 0.0139249568323512, 0.011344085833834],
						"clientCountBinSize": 0.315789473684211,
						"clientCountKde": [0.12351722131517, 0.128155113050654, 0.126203481660312, 0.118376543664061, 0.106170175345205, 0.0914202142297776, 0.0758778049889277, 0.0609257821238842, 0.0474720434323603, 0.0359818971860229, 0.0265809946933773, 0.0191702468209592, 0.0135379854596697, 0.00936847987279867, 0.00637163248536686, 0.00426842790905053, 0.00282985414072934, 0.00184894427840577],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.378441854176733, 0.0512165394445219, 0, 0.0136552778233295, 0.125809767833013, 0.203111664207715, 0.0257157655933768, 0.00244214060033352, 0.0233741571453983, 0.0533174249275884, 0.0620077159185132, 0.00762509045773503, 0.00319491463808324, 0.023529850791576, 0.00450305244865181, 0.00975332202694354, 0.00163893957900874, 0.002947237684534, 0.00468863463410192, 0, 0.00302665006884421],
						"endTimestamp": "2021-11-17T00:00:00",
						"errorFlags": ["rssiKde", "snrKde", "speedDensity"],
						"frequencyBand": "2.4 GHz",
						"impactedClients": 66,
						"insightUid": "a8194906b8bb327895de",
						"interferenceBinSize": 1,
						"interferenceKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00235687449202295, 0.0089958429512286, 0.0107692321613786, 0.0107537286531383, 0.0116409008286907, 0.00922757361670366, 0.00968306193471936, 0.0185467844616893, 0.0244716566086097, 0.028187708776189, 0.0329336362166502, 0.0354469557706904, 0.0372256404218287, 0.0489825541152409, 0.0530427265620135, 0.0576711855769278, 0.0571039009496937, 0.05535975901903, 0.0522075168871021, 0.0475609817542849, 0.038560918496915, 0.034500695603594, 0.0270056764682789, 0.0284982946934822, 0.0260499431889506, 0.0213707459800688, 0.020864199096571, 0.0165181495113328, 0.0140417783967897, 0.0136146314750608, 0.0121750928773714, 0.0108002990078655, 0.0113051846819313, 0.0111960570305226, 0.00742504922691702, 0.00693475455654831, 0.00707959414179303, 0.00580713925340436, 0.00544254927146859, 0.006276095954167, 0.00563107171488616, 0.00458809527218015, 0.00424291210769948, 0.00274327848053827, 0.00328623177658692, 0.00424173559127063, 0.00356158507934844, 0.00237708368723944, 0.00360419805356827, 0.00434524611596533, 0.00190700400478979, 0.00345202973753483, 0.00156150012713158, 0.00166501050615722, 0.00221571750984388, 0.00138858747239519, 0.0014917771770884, 0, 0, 0, 0, 0.0010860174083814, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00117295493419077, 0.00166415488256452, 0.00225907926153161, 0.00179349160779831, 0.00208616682044409],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 437,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 437,
							"key": 1
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0136180712940637, 0.187777649346858, 0.67562921092329, 0.118739476548017, 0.00423559188777044],
						"packetFailureKde": null,
						"packetRetryKde": null,
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0.00961839035197218, 0.205388111808527, 0.569813812871221, 0.205395859165831, 0.00978382580244955],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 437,
							"key": 4
						}],
						"primaryImageVersion": "8.5.161.6",
						"rootCauseCategory": "External RF load",
						"rootCauseCategoryId": "a72edc5da049e7f555a3",
						"rootCauseFeatures": [{
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "low",
							"feature": "avgPower",
							"kpi": "powerKde",
							"weight": 0.0476190476190476
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgUtilization",
							"kpi": "utilizationKde",
							"weight": 0.030952380952381
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgInterference",
							"kpi": "interferenceKde",
							"weight": 0.0547619047619048
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": null,
							"feature": "model",
							"kpi": "apModel",
							"weight": 0.0428571428571429
						}],
						"rootCauseId": "fc45e7",
						"rssiKde": [0.0169148223143578, 0.0397178191966773, 0.0811195999215789, 0.0597498102808124, 0.0538295502891663, 0.0405889876376867, 0.0501724887916081, 0.0783416459879256, 0.0872073620441804, 0.0430310826164112, 0.0405845610974094, 0.0470181656480592, 0.036855581329633, 0.0525161657697694, 0.0454520463769998, 0.0256753685018019, 0.0267693569848767, 0.0210228641208774, 0.0162780629254192, 0.0223352058050656, 0.0266541904955889, 0.0208145680953838, 0.0143442488652592, 0.0207229505454197, 0.0156818229813848, 0.00633622767697515, 0.00479964412935924, 0.00379239595727097],
						"severity": "s1",
						"siteHierarchyArray": ["Global / North America / USA / California / San Francisco / SFO15 / Flr-SFO15-1"],
						"siteId": "ab2cd664-b803-4a2b-b7cd-5b7674eeda5c",
						"snrKde": [0, 0.00481255213403953, 0.0132677406051605, 0.0217656149674444, 0.0338416137122175, 0.0455811596509248, 0.084068424055601, 0.0508422450975499, 0.0710908753066523, 0.0717534111294728, 0.026805993126962, 0.0321937490875429, 0.0707745002510792, 0.0605416415994473, 0.0428718985588496, 0.0348819990071015, 0.0155186344056346, 0.033202472112272, 0.0619724409887555, 0.0411538734523277, 0.0187065561332789, 0.0234068728585033, 0.0190274075944887, 0.0120117232906218, 0.0128728714945618, 0.0158118158149223, 0.0110795185148827, 0.0157186218002135, 0.0249921995147865, 0.0148206030176704, 0.00501184704781325, 0.00343840803715401, 0.00270445800718999, 0.00210429416666535],
						"speedBins": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73],
						"speedDensity": [0.0226850130159911, 0.0197099293417627, 0, 0, 0, 0, 0.00520639642989959, 0.351059873558944, 0, 0.000371885459278542, 0, 0.271104499814057, 0.0323540349572332, 0, 0.0676831535886947, 0, 0, 0, 0.000743770918557084, 0, 0, 0, 0.116772034213462, 0, 0, 0, 0, 0, 0, 0.0483451097062105, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0304946076608405, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0100409074005206, 0, 0, 0, 0, 0, 0, 0.00557828188917813, 0, 0, 0, 0, 0, 0, 0.01785050204537],
						"startTimestamp": "2021-10-20T00:00:00",
						"sumRadioAppCount": 33,
						"timestamp": "2021-11-17T02:08:10.717983",
						"trafficBinSize": 0.736842105263158,
						"trafficKde": [0.653425404121031, 0.30997351084825, 0.0346778057074678],
						"uniqueClients": 90,
						"utilizationBinSize": 1,
						"utilizationKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00235676174624898, 0.00895277998095982, 0.0104072364075602, 0.0103916259944842, 0.0115545766155111, 0.00895187724635696, 0.00995892568030333, 0.0185469527417165, 0.0240180375093931, 0.0271967435182352, 0.0328044330546502, 0.0354038277303205, 0.0369498751758527, 0.0492581072878734, 0.0531286777569199, 0.0580758398965215, 0.057784230335205, 0.0554457045140049, 0.0521213067751503, 0.0469664765724667, 0.0388364973069143, 0.0348623660825404, 0.0270486508743149, 0.0284982245872857, 0.0260495582622412, 0.0213706934077399, 0.0208207860864918, 0.0162424352060691, 0.0143606722553871, 0.014019287226553, 0.0125372460689285, 0.0108432063315226, 0.0113053707666822, 0.0112391772859815, 0.00770070463158263, 0.00670221161476977, 0.00735535734896892, 0.00585016580327778, 0.00544253588272256, 0.0062330396793689, 0.00535538419198357, 0.00486375765579151, 0.00428594251044882, 0.00274327173203549, 0.00328622369241159, 0.00424172515654277, 0.00356157631780019, 0.0023770778395809, 0.00360418918719152, 0.00434523542660016, 0.00190699931353372, 0.00345202124549422, 0.0015614962858198, 0.00166500641020848, 0.00221571205914835, 0.00138858405645092, 0.00149177350729604, 0, 0, 0, 0, 0.00108601473676365, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00117295204870551, 0.00166415078872064, 0.00225907370416557, 0.0017934871957843, 0.00208616168844503]
					}],
					"totalCount": 1
				}
			}
		},
	"getApDetailforHighCoChannel2Template":
		{
			"data": {
				"getClientInsightRadios36": {
					"__typename": "GetClientInsightRadios36Connection",
					"nodes": [{
						"__typename": "ClientInsightRadios36Type",
						"aesApMac": "aes:a0:f8:49:7e:c0:61",
						"aesApName": "aes:SFO15-C9124-01",
						"apEntityId": "038eaae1d68261f1d2e6",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 478,
							"key": 0
						}],
						"apModel": "C9124AXI",
						"channelChangeCountBinSize": 0.0526315789473684,
						"channelChangeCountKde": [0.083822086617284, 0.083358982347609, 0.0819849645572029, 0.0797450794734103, 0.0767116722166308, 0.0729805072186312, 0.0686657515323493, 0.0638941873315881, 0.0587990618058377, 0.0535139877826142, 0.0481672782345456, 0.0428770374651486, 0.0377472491444952, 0.0328650061081307, 0.0282989290177146, 0.0240987299537028, 0.0202958003987504, 0.0169046461281695, 0.0139249568323512, 0.011344085833834],
						"clientCountBinSize": 0.210526315789474,
						"clientCountKde": [0.0951835298133174, 0.0972540899338708, 0.0968956772946371, 0.0942080234233908, 0.0894602733788154, 0.0830496671071064, 0.0754482916295972, 0.0671475597391318, 0.0586090974766919, 0.0502281211297968, 0.0423120392714189, 0.0350738346757736, 0.02863743756877, 0.0230511006854323, 0.0183046747499549, 0.0143473610282818, 0.011103591971466, 0.00848580580096669, 0.00641557077573793, 0.00478425254584226],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0, 0, 0, 0.0132388290157906, 0.104933895069084, 0.267643952516822, 0.313566454071575, 0.190085784707639, 0.0733949694404493, 0.0257569146941692, 0.00906263433241805, 0.00231656615205416],
						"endTimestamp": "2021-11-17T00:00:00",
						"errorFlags": ["packetFailureKde"],
						"frequencyBand": "2.4 GHz",
						"impactedClients": 65,
						"insightUid": "a866297975218912414a",
						"interferenceBinSize": 1,
						"interferenceKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00120407689407531, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00140976700734095, 0, 0.000998954201332612, 0.00131852861651619, 0, 0, 0, 0.00216459685536342, 0.00126600818458397, 0.0014342096642245, 0.00186804666167472, 0.00263800303264937, 0.00210082718818665, 0.00335506042945322, 0.0034159514747628, 0.00439481302426382, 0.00377490594678134, 0.00430083264186495, 0.00491119244514229, 0.00511787703127644, 0.00821779469903967, 0.00846305397067457, 0.00577731158902476, 0.0095681840701159, 0.00668890144523012, 0.00935025546713454, 0.0104325060946922, 0.0110094403793766, 0.00866860765543225, 0.00966217616226744, 0.0151033517930788, 0.0144176900075631, 0.0171853583260328, 0.0114497637946476, 0.0146187513324118, 0.0185863428987024, 0.0249245095806801, 0.0315237202290633, 0.0286703038716933, 0.0342680650213851, 0.0567531957086554, 0.0682684766928119, 0.0733967187383965, 0.0888374450354707, 0.0783973993064796, 0.0751941708195754, 0.066175220061892, 0.060276833756418, 0.0361327023886093, 0.0231420843913414, 0.0195436407952891, 0.00678936128612787, 0.00283301133119403],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 478,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 478,
							"key": 1
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.106506978919201, 0.786986042161599, 0.106506978919201],
						"packetFailureKde": [0.0353109842819273, 0.0724225242023557, 0.108685683878536, 0.121186995434459, 0.103256790464538, 0.0816662410997725, 0.0726078573855106, 0.0664880804268731, 0.0576433264201692, 0.0518762886434616, 0.0465553209752618, 0.0400294571852672, 0.0294158525819203, 0.0164866023330848, 0.0103176171002131, 0.00960066934315995, 0.00850884817748965, 0.00862296746352891, 0.0122060648096868, 0.0113783385573747, 0.00567901288583737, 0.00239996977929958, 0.00178055833138581, 0.00244504137520843, 0.0024201231269251, 0.000988427185244857, 0.000174087309063457, 0.000265850811505686, 0.00122387006825588, 0.00238352544982484, 0.00196128804089086, 0.000674663118379218, 0.0000928882769981605, 0.00000489517582797903, 0, 0, 0.00000153350068248159, 0.0000378546492734441, 0.000336856118110135, 0.00108075649454211, 0.00125777656904609, 0.000657410098166596, 0.000947663655328204, 0.0022042886696219, 0.00227431658738341, 0.000990357235414336, 0.000454804794707997, 0.00103197623023454],
						"packetRetryKde": [0.00146868624733513, 0.00153331038341344, 0.00151449476166472, 0.00120678946484178, 0.00185139171980919, 0.00325174360012584, 0.00391369417730496, 0.00443852173706655, 0.0047866930132995, 0.0052389343802979, 0.00560932469226326, 0.0076720511883147, 0.0102811717836237, 0.0139988612762631, 0.0194523705290095, 0.0218565715962402, 0.0237767457309073, 0.0350425939568382, 0.0527131703135337, 0.0746811690641488, 0.0924022754459427, 0.0962767709763691, 0.0984827070374312, 0.0972687335205072, 0.092923401417199, 0.0836669530619669, 0.0610262806699695, 0.0335543476294541, 0.0165783014024738, 0.0102839137076355, 0.00691569174166284, 0.00378053940161456, 0.00321023494837756, 0.00383464852306985, 0.00329542122764394, 0.00172204256748436],
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0.00962005683460592, 0.205423697322451, 0.569912491685885, 0.205423697322451, 0.00962005683460592],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 478,
							"key": 6
						}],
						"primaryImageVersion": "8.5.161.6",
						"rootCauseCategory": "External RF load",
						"rootCauseCategoryId": "a72edc5da049e7f555a3",
						"rootCauseFeatures": [{
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgUtilization",
							"kpi": "utilizationKde",
							"weight": 0.0309597523219814
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgInterference",
							"kpi": "interferenceKde",
							"weight": 0.0526315789473684
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": null,
							"feature": "model",
							"kpi": "apModel",
							"weight": 0.0526315789473684
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": null,
							"feature": "powerMode_mode",
							"kpi": "powerMode",
							"weight": 0.0309597523219814
						}],
						"rootCauseId": "fc45e7",
						"rssiKde": [0, 0, 0, 0, 0, 0, 0, 0, 0.00141159985617381, 0.00157909382504818, 0.00136828519392782, 0.00165226701675113, 0.00239270366593371, 0.00295886342666537, 0.00394097397170026, 0.00551702520249339, 0.018352445209679, 0.0292974555872732, 0.0112336156892293, 0.00966641185793534, 0.0120390007040414, 0.00752102971088835, 0.00683901220145656, 0.00756785432237429, 0.00955886854162989, 0.011671425821027, 0.0133709104234783, 0.0132554006107894, 0.0123212930340603, 0.0135100792457436, 0.0145728921335734, 0.0170997409014763, 0.021734743105688, 0.0247283200791686, 0.0283531748142102, 0.0388976467093592, 0.0443903791864831, 0.0464666437456065, 0.0507715751696768, 0.0555395694552536, 0.0590096294601647, 0.0608805242798895, 0.0624558464090214, 0.058123577651005, 0.051132560090571, 0.0441962810124664, 0.035862365759731, 0.0290267525156988, 0.0221347560771541, 0.0125151524021232, 0.00805614219105028, 0.00688878331006891, 0.00555985642525879, 0.00304104012265484],
						"severity": "s1",
						"siteHierarchyArray": ["Global / North America / USA / California / San Francisco / SFO15 / Flr-SFO15-1"],
						"siteId": "f878dd7f-cf4e-47f3-aa22-83d61486ceea",
						"snrKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00119980888232366, 0, 0, 0.00147930663142936, 0.00188491309478127, 0.00193350075819637, 0.00267944787779545, 0.00395045433636098, 0.00488564799489403, 0.00650212574137759, 0.0202343762551058, 0.0296604450631629, 0.0161679315670475, 0.0122379187695394, 0.00737273880650367, 0.00707707566516944, 0.00901034174612861, 0.0110832801114386, 0.0127113989852921, 0.0136024467528391, 0.0141511670485803, 0.0146050036671238, 0.0130315809028372, 0.0140661765942578, 0.0169577023790708, 0.0239420401515642, 0.028755251578432, 0.0288732176395984, 0.0360973735624179, 0.0429638335341853, 0.0459077058549543, 0.0502656438856537, 0.057526842169392, 0.0615920017228638, 0.0612695561490701, 0.0590022529421771, 0.0577270277723676, 0.0507202796214073, 0.0454417620105299, 0.0385414655834994, 0.0273055649798425, 0.0171659094581457, 0.0134461273684503, 0.00740892538747347, 0.00369892291770024, 0.00256775009099661, 0.0019285445200248],
						"speedBins": [0, 2.60714285714286, 5.21428571428571, 7.82142857142857, 10.4285714285714, 13.0357142857143, 15.6428571428571, 18.25, 20.8571428571429, 23.4642857142857, 26.0714285714286, 28.6785714285714, 31.2857142857143, 33.8928571428571, 36.5, 39.1071428571429, 41.7142857142857, 44.3214285714286, 46.9285714285714, 49.5357142857143, 52.1428571428571, 54.75, 57.3571428571429, 59.9642857142857, 62.5714285714286, 65.1785714285714, 67.7857142857143, 70.3928571428571, 73],
						"speedDensity": [0.00208855472013367, 0, 0, 0, 0.000417710944026733, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.621136173767753, 0, 0, 0, 0, 0, 0, 0.376357560568086],
						"startTimestamp": "2021-10-20T00:00:00",
						"sumRadioAppCount": 1070,
						"timestamp": "2021-11-17T02:08:10.717983",
						"trafficBinSize": 0.526315789473684,
						"trafficKde": [0.341017246408036, 0.312288449702152, 0.207855217773873, 0.100201279947, 0.0286802339169307, 0.0056309785663775, 0.00210387192039865, 0.00136264733798523],
						"uniqueClients": 74,
						"utilizationBinSize": 1,
						"utilizationKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00116605530525333, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00116596074954939, 0, 0, 0.00131856751741508, 0.00103685168252995, 0, 0, 0.00192081287426801, 0.00150979881802143, 0.0014722145397068, 0.00183013920711439, 0.00239394933583774, 0.00219250854645742, 0.00245597681622397, 0.0045204581779007, 0.00345410940172247, 0.0034742483471116, 0.00328741625388541, 0.00559631300239387, 0.00504758785878812, 0.00692353472957362, 0.00923205309641041, 0.006459620546165, 0.00775832946783596, 0.00681820753713488, 0.0100268544031403, 0.00926444580190084, 0.0115153299473001, 0.00742338437841436, 0.00799669958085025, 0.0161404580780561, 0.0136393935252758, 0.0181605949925016, 0.0115691903441704, 0.0141824961769681, 0.0175002592852693, 0.0239553757235541, 0.0225550843056177, 0.0341587546540425, 0.0245306603425521, 0.0472334981576096, 0.0738229129184278, 0.0669336073168631, 0.090232850649472, 0.0773998864008214, 0.0848182663965767, 0.0637317594439971, 0.0701798862297076, 0.0377712664484219, 0.029155007326483, 0.0236912454178842, 0.00741095785380043, 0.00396516008902191]
					}],
					"totalCount": 1
				}
			}
		},
	"getApDetailforHighCoChannel3Template":
		{
			"data": {
				"getClientInsightRadios36": {
					"__typename": "GetClientInsightRadios36Connection",
					"nodes": [{
						"__typename": "ClientInsightRadios36Type",
						"aesApMac": "aes:a0:f8:49:7e:c0:62",
						"aesApName": "aes:SFO15-C9124-02",
						"apEntityId": "03ffd74571b56a73779a",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 477,
							"key": 0
						}],
						"apModel": "C9124AXI",
						"channelChangeCountBinSize": 0.0526315789473684,
						"channelChangeCountKde": [0.083822086617284, 0.083358982347609, 0.0819849645572029, 0.0797450794734103, 0.0767116722166308, 0.0729805072186312, 0.0686657515323493, 0.0638941873315881, 0.0587990618058377, 0.0535139877826142, 0.0481672782345456, 0.0428770374651486, 0.0377472491444952, 0.0328650061081307, 0.0282989290177146, 0.0240987299537028, 0.0202958003987504, 0.0169046461281695, 0.0139249568323512, 0.011344085833834],
						"clientCountBinSize": 0.157894736842105,
						"clientCountKde": [0.0833945634638049, 0.0843350323205065, 0.0839935628584049, 0.0824006098947089, 0.0796424255398104, 0.0758534928318411, 0.0712057459692337, 0.06589577109642, 0.0601312981790923, 0.0541182420256728, 0.0480493538041551, 0.042095247266312, 0.0363982193682029, 0.0310689452953583, 0.026185836089243, 0.0217966316289795, 0.017921674837573, 0.0145582719428481, 0.0116855744869597, 0.00926950110087342],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.359650783434855, 0.048673445006296, 0, 0.0110050480651456, 0.108868978944724, 0.220152606839172, 0.0283729865958434, 0.00243251896120014, 0.0233111446957624, 0.0537674590174084, 0.0714464826011598, 0.00892429264398427, 0.00341791972202319, 0.0249722304081282, 0.00583276771197975, 0.018148803974767, 0.00273676362513434, 0.00265380903172386, 0.00444303049740894, 0],
						"endTimestamp": "2021-11-17T00:00:00",
						"errorFlags": ["snrKde"],
						"frequencyBand": "2.4 GHz",
						"impactedClients": 12,
						"insightUid": "a8c4ef548414db617d73",
						"interferenceBinSize": 1,
						"interferenceKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00201303279323101, 0.00212886568672272, 0.00203757333689018, 0.00349693552701052, 0.00437761498034648, 0.0059199832567185, 0.0108975305932468, 0.0139310885203539, 0.0150764989786799, 0.0136855650181658, 0.0197900361937747, 0.0281759872275529, 0.0321548881158562, 0.0400709833628249, 0.0429262942040536, 0.0442967466201741, 0.0455034334122436, 0.0524578947848048, 0.0463918386177785, 0.0496695356689942, 0.0528820121289362, 0.0534956671355204, 0.0494902437793519, 0.0496052627153746, 0.0485334162940456, 0.0478450905106736, 0.0450109382471478, 0.036006947808313, 0.0307269720063878, 0.0280912676917785, 0.0204188481109107, 0.0162811402975771, 0.0151088990608844, 0.0114359554265126, 0.00883880245690265, 0.00544583723670735, 0.00434988173504881],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 477,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 477,
							"key": 1
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.100218460989479, 0.746648809189399, 0.146560526696442, 0.00657220312467908],
						"packetFailureKde": null,
						"packetRetryKde": null,
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00962005683460593, 0.205423697322451, 0.569912491685885, 0.205423697322451, 0.00962005683460593],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 477,
							"key": 4
						}],
						"primaryImageVersion": "8.5.161.6",
						"rootCauseCategory": "External RF load",
						"rootCauseCategoryId": "a72edc5da049e7f555a3",
						"rootCauseFeatures": [{
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgInterference",
							"kpi": "interferenceKde",
							"weight": 0.0542797494780793
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": null,
							"feature": "model",
							"kpi": "apModel",
							"weight": 0.0417536534446764
						}],
						"rootCauseId": "fc45e7",
						"rssiKde": [0, 0, 0, 0.00298047898952994, 0.00963331863042883, 0.0253535854967439, 0.0473440613730877, 0.0183000735977252, 0.0756350591574157, 0.164556639664448, 0.0509227028510559, 0.0491404163886902, 0.0879149905842121, 0.0370128166160455, 0.0205436964043105, 0.0366709754884617, 0.0536343444587254, 0.0242712021497566, 0.0248998675280725, 0.0499570269281923, 0.0450770622668603, 0.019297414035777, 0.0176665352268943, 0.0163435669264973, 0.0403231530110083, 0.038330862089113, 0.00714323898566989, 0.00159471900586631, 0.00476746436321332, 0.00657902675967472, 0.00324186452296463, 0.0027595807264906, 0.00222794677374972, 0.00122343111948912, 0.00102372063339776, 0, 0, 0, 0, 0.00122435524917921, 0.00663316144188826, 0.00577164055536395],
						"severity": "s1",
						"siteHierarchyArray": ["Global / North America / USA / California / San Francisco / SFO15 / Flr-SFO15-1"],
						"siteId": "92a96071-f610-47bf-8012-701b51835737",
						"snrKde": [0.0227927749176345, 0.0636935353091812, 0.0544032765806394, 0.0884679839488146, 0.0703088974469942, 0.0985689430937355, 0.0800631194709598, 0.0206978969688359, 0.0189357380768537, 0.0399307878184101, 0.012173176237017, 0.0024248322913194, 0.00906574008752246, 0.0374093067361248, 0.0592768675155866, 0.0402618911683247, 0.0402688439178631, 0.0428298911282414, 0.0144596637674775, 0.0158285098972813, 0.0392448640171384, 0.049354955598951, 0.0108123258925448, 0.00229730414345155, 0.00191580240835551, 0.0018209632509399, 0.00238069423860908, 0.00580525183221563, 0.0149450265098916, 0.0143531685707111, 0.00344493473618278, 0.00119403376979136, 0.0014007803008225, 0.00145025636385142, 0.00148676820694651, 0.00172313027958313, 0.00141888775920596, 0, 0, 0, 0, 0, 0, 0, 0.00107761391311888, 0.00671606415176203, 0.00529549767710981],
						"speedBins": [0, 6.30434782608696, 12.6086956521739, 18.9130434782609, 25.2173913043478, 31.5217391304348, 37.8260869565217, 44.1304347826087, 50.4347826086956, 56.7391304347826, 63.0434782608696, 69.3478260869565, 75.6521739130435, 81.9565217391304, 88.2608695652174, 94.5652173913043, 100.869565217391, 107.173913043478, 113.478260869565, 119.782608695652, 126.086956521739, 132.391304347826, 138.695652173913, 145],
						"speedDensity": [0.0782361308677098, 0.350640113798009, 0.255334281650071, 0.0298719772403983, 0.0220483641536273, 0, 0.0391180654338549, 0, 0, 0.00284495021337126, 0.000711237553342817, 0.000711237553342817, 0, 0.115220483641536, 0, 0, 0, 0, 0.0832147937411094, 0, 0.0113798008534851, 0, 0.0106685633001422],
						"startTimestamp": "2021-10-20T00:00:00",
						"sumRadioAppCount": 59,
						"timestamp": "2021-11-17T02:08:10.717983",
						"trafficBinSize": 0.0526315789473684,
						"trafficKde": [0.0738345864414074, 0.0735510656618388, 0.072707018765051, 0.071321743415114, 0.0694265859436527, 0.0670637622627352, 0.0642847950857869, 0.0611486482150122, 0.0577196521333837, 0.0540653225209409, 0.0502541743336026, 0.0463536289546175, 0.0424281013167325, 0.0385373388263206, 0.0347350656926724, 0.0310679663084645, 0.027575021091391, 0.024287189028589, 0.0212274142120828, 0.0184109197906044],
						"uniqueClients": 19,
						"utilizationBinSize": 1,
						"utilizationKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00201303279323101, 0.00212886568672272, 0.00203757333689018, 0.00349693552701052, 0.00437761498034647, 0.00591998325671849, 0.0108975305932468, 0.0139310885203539, 0.0150764989786799, 0.0136855650181658, 0.0197900361937747, 0.0281759872275529, 0.0321548881158562, 0.0400709833628248, 0.0429262942040535, 0.0442967466201741, 0.0455034334122436, 0.0524578947848047, 0.0463918386177784, 0.0496695356689941, 0.0528820121289361, 0.0534573103411204, 0.0492062143120819, 0.0495669059209747, 0.048571773088446, 0.048129119977944, 0.0450492950415476, 0.036006947808313, 0.0307269720063878, 0.0280912676917785, 0.0204188481109107, 0.0162811402975771, 0.0151088990608843, 0.0114359554265126, 0.00883880245690264, 0.00544583723670734, 0.0043498817350488]
					}],
					"totalCount": 1
				}
			}
		},
	"getApDetailforHighCoChannel4Template":
		{
			"data": {
				"getClientInsightRadios36": {
					"__typename": "GetClientInsightRadios36Connection",
					"nodes": [{
						"__typename": "ClientInsightRadios36Type",
						"aesApMac": "aes:a0:f8:49:7e:c0:63",
						"aesApName": "aes:SFO15-C9136-01",
						"apEntityId": "033242db92017bdb48ce",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 477,
							"key": 0
						}],
						"apModel": "C9136I-B",
						"channelChangeCountBinSize": 0.0526315789473684,
						"channelChangeCountKde": [0.083822086617284, 0.083358982347609, 0.0819849645572029, 0.0797450794734103, 0.0767116722166308, 0.0729805072186312, 0.0686657515323493, 0.0638941873315881, 0.0587990618058377, 0.0535139877826142, 0.0481672782345456, 0.0428770374651486, 0.0377472491444952, 0.0328650061081307, 0.0282989290177146, 0.0240987299537028, 0.0202958003987504, 0.0169046461281695, 0.0139249568323512, 0.011344085833834],
						"clientCountBinSize": 0.210526315789474,
						"clientCountKde": [0.0872217651089052, 0.0902084173071528, 0.0910577886596098, 0.0897737801674749, 0.086513503506567, 0.0815616066506627, 0.0752917145536332, 0.068121908612966, 0.0604712295908568, 0.052722950041417, 0.0451983069645017, 0.0381420806308822, 0.031719346152282, 0.0260212366126776, 0.0210767715227942, 0.016867685352535, 0.0133435886665503, 0.0104355068353871, 0.00807792535483424, 0.00617288770831019],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.399989824537824, 0.0541327395949113, 0, 0.0136757220267765, 0.120733407814072, 0.164122295853279, 0.0204549202508024, 0.0021406600274711, 0.0201489618600697, 0.0470305442875537, 0.0775595498993384, 0.00988522908423678, 0.0024321672655236, 0.0177022900643162, 0.0053386890690089, 0.0217690829992117, 0.00361302353556853, 0.00556636294292424, 0.00530952757513238, 0.00114005110961257, 0.00377299057303792, 0, 0.00203167016933554, 0],
						"endTimestamp": "2021-11-17T00:00:00",
						"errorFlags": ["snrKde"],
						"frequencyBand": "2.4 GHz",
						"impactedClients": 17,
						"insightUid": "a85fa762e95ec74764df",
						"interferenceBinSize": 1,
						"interferenceKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00549633068321574, 0.010055649385253, 0.0194237901917242, 0.036434947902797, 0.0504039967961684, 0.0829432166449103, 0.111136600242314, 0.133852701487262, 0.118311395946032, 0.0897925181919808, 0.053240626950852, 0.0467137310507258, 0.0320527857774097, 0.0284176914399188, 0.027000770534051, 0.0201438955991323, 0.0182192215099675, 0.0185381611496673, 0.0177741994996574, 0.0152353129022097, 0.0130834543245059, 0.0110572098193919, 0.00793913629269746, 0.00774859258969513, 0.00375973282864424, 0.00520528844642191, 0.00232970191288868, 0.00252074686305774, 0.0012833492068664, 0.00144798152560755, 0.00188993206778245, 0.00100565883853662, 0.00185230808103371, 0.00113257420399376, 0, 0, 0, 0, 0, 0.0010573262558376],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 477,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 477,
							"key": 1
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0696072493954784, 0.544480539149366, 0.299331370043224, 0.0559647132580444, 0.0123733628643498, 0.0032676431494358, 0.00236577261741911, 0.00786164651814311, 0.00334942654263169],
						"packetFailureKde": null,
						"packetRetryKde": null,
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00962005683460592, 0.205423697322451, 0.569912491685885, 0.205423697322451, 0.00962005683460592],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 477,
							"key": 4
						}],
						"primaryImageVersion": "8.5.161.6",
						"rootCauseCategory": "External RF load",
						"rootCauseCategoryId": "a72edc5da049e7f555a3",
						"rootCauseFeatures": [{
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgUtilization",
							"kpi": "utilizationKde",
							"weight": 0.0282186948853616
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgInterference",
							"kpi": "interferenceKde",
							"weight": 0.0423280423280423
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": null,
							"feature": "model",
							"kpi": "apModel",
							"weight": 0.0423280423280423
						}],
						"rootCauseId": "fc45e7",
						"rssiKde": [0, 0, 0.0127009533829861, 0.0480876858794959, 0.0628735646117276, 0.0496037586322501, 0.0462231136986927, 0.0555477413445883, 0.0532662740198079, 0.0379428590410446, 0.0888155825527685, 0.117073207000889, 0.0694734059586546, 0.0383992100020692, 0.0343416447161385, 0.0357559525421353, 0.0268276159026062, 0.032628868778585, 0.0272340277623216, 0.029852688442947, 0.0075352139448517, 0.00201766665447418, 0.00290598554699785, 0.00914737981470023, 0.0202478439752248, 0.0181089340779109, 0.0037569067238661, 0.00260310396696757, 0.00642156686679418, 0.0124272325602125, 0.0146227684412347, 0.00916575396098849, 0.00518179924259364, 0.00516596353256599, 0.00470043176274703, 0.00181170778664385, 0.00332672195353225, 0.00309482586870579],
						"severity": "s2",
						"siteHierarchyArray": ["Global / North America / USA / California / San Francisco / SFO15 / Flr-SFO15-1"],
						"siteId": "92a96071-f610-47bf-8012-701b51835737",
						"snrKde": [0.00464964697653641, 0.0102342412935118, 0.0391089950509521, 0.0891884659242459, 0.116220003125181, 0.0542241990195381, 0.0244045651440623, 0.0501630964345134, 0.0586259104929176, 0.0494391335468342, 0.063958063465108, 0.0838569402796513, 0.0509682191267082, 0.0150325073382417, 0.00758419550069331, 0.0273811216606406, 0.0442470130578091, 0.0198353196864072, 0.0142717769978893, 0.00941317496789693, 0.00851092168439933, 0.0205782153995868, 0.028104672503861, 0.00872634562133903, 0.0108303269568728, 0.0113727577891539, 0.00369684824320876, 0.00248218975454419, 0.00431609771987875, 0.0155730501814735, 0.0181564698462025, 0.00320056842059672, 0.00332258893584645, 0.00653559922525296, 0.00752371746345129, 0.00547943925109523, 0.0023687370375355, 0.00182824810538998, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00268991267280274],
						"speedBins": [0, 3.08510638297872, 6.17021276595745, 9.25531914893617, 12.3404255319149, 15.4255319148936, 18.5106382978723, 21.5957446808511, 24.6808510638298, 27.7659574468085, 30.8510638297872, 33.936170212766, 37.0212765957447, 40.1063829787234, 43.1914893617021, 46.2765957446809, 49.3617021276596, 52.4468085106383, 55.531914893617, 58.6170212765958, 61.7021276595745, 64.7872340425532, 67.8723404255319, 70.9574468085106, 74.0425531914894, 77.1276595744681, 80.2127659574468, 83.2978723404255, 86.3829787234043, 89.468085106383, 92.5531914893617, 95.6382978723404, 98.7234042553192, 101.808510638298, 104.893617021277, 107.978723404255, 111.063829787234, 114.148936170213, 117.234042553192, 120.31914893617, 123.404255319149, 126.489361702128, 129.574468085106, 132.659574468085, 135.744680851064, 138.829787234043, 141.914893617021, 145],
						"speedDensity": [0.098508498092265, 0, 0.333680194242109, 0.0974679153659382, 0.116892126257371, 0, 0, 0.00138744363510232, 0, 0.114117238987166, 0, 0, 0, 0.0138744363510232, 0, 0, 0, 0, 0.0721470690253207, 0, 0, 0.0523759972251127, 0, 0.00346860908775581, 0, 0, 0, 0, 0.0461325008671523, 0, 0, 0, 0, 0, 0, 0, 0, 0.00797780090183833, 0, 0, 0, 0, 0.0221990981616372, 0, 0, 0, 0.0197710718002081],
						"startTimestamp": "2021-10-20T00:00:00",
						"sumRadioAppCount": 30,
						"timestamp": "2021-11-17T02:08:10.717983",
						"trafficBinSize": 0.0526315789473684,
						"trafficKde": [0.0738152692002576, 0.0735328302987142, 0.0726901455986295, 0.0713065007096313, 0.0694132230072779, 0.0670525028522291, 0.0642758313506689, 0.0611421353990696, 0.0577157042259912, 0.054064009016845, 0.0502555182192743, 0.0463576059928483, 0.0424346406517964, 0.0385463248815361, 0.0347463412826292, 0.0310813368419293, 0.0275902597004296, 0.024304042425398, 0.0212456090505538, 0.0184301692942907],
						"uniqueClients": 26,
						"utilizationBinSize": 1,
						"utilizationKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00549633068319253, 0.0100556493852105, 0.0194237901916422, 0.0364349479026431, 0.0504039967959555, 0.08294321664456, 0.111136600241845, 0.133852701486697, 0.118311395945532, 0.0897925181916016, 0.0532406269506271, 0.0467137310505285, 0.0320527857772743, 0.0284176914397988, 0.0270007705339369, 0.0201438955990473, 0.0182192215098906, 0.018538161149589, 0.0177741994995823, 0.0152352198764803, 0.0130460164185972, 0.0108174225654432, 0.00817892354656586, 0.00778603049551589, 0.00375982585429347, 0.00520528845062346, 0.00232970191287883, 0.0025207468630471, 0.00128334920686098, 0.00144798152560144, 0.00188993206777446, 0.00100565883853238, 0.00185230808102589, 0.00113257420398897, 0, 0, 0, 0, 0, 0.00105732625583314]
					}],
					"totalCount": 1
				}
			}
		},
	"getApDetailforHighCoChannel5Template":
		{
			"data": {
				"getClientInsightRadios36": {
					"__typename": "GetClientInsightRadios36Connection",
					"nodes": [{
						"__typename": "ClientInsightRadios36Type",
						"aesApMac": "aes:a0:f8:49:7e:c0:64",
						"aesApName": "aes:SFO15-C9136-02",
						"apEntityId": "036ea851203c92b89eff",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 478,
							"key": 0
						}],
						"apModel": "C9136I-B",
						"channelChangeCountBinSize": 0.0526315789473684,
						"channelChangeCountKde": [0.083822086617284, 0.083358982347609, 0.0819849645572029, 0.0797450794734103, 0.0767116722166308, 0.0729805072186312, 0.0686657515323493, 0.0638941873315881, 0.0587990618058377, 0.0535139877826142, 0.0481672782345456, 0.0428770374651486, 0.0377472491444952, 0.0328650061081307, 0.0282989290177146, 0.0240987299537028, 0.0202958003987504, 0.0169046461281695, 0.0139249568323512, 0.011344085833834],
						"clientCountBinSize": 0.210526315789474,
						"clientCountKde": [0.0864749227887361, 0.0891827190075655, 0.0898057075919877, 0.0883822588709767, 0.0850954838070846, 0.0802424260696658, 0.0741925928140233, 0.0673434967987294, 0.0600802645979374, 0.0527444143898761, 0.0456143067210827, 0.0388972390511459, 0.0327312333530801, 0.027193519127308, 0.0223125267848469, 0.0180806659502189, 0.0144659729921677, 0.0114215916583719, 0.00890404979289828, 0.00683460783229691],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.412073448115941, 0.0557680800556566, 0, 0.0135762919100657, 0.123129213253159, 0.18699048937239, 0.0235715527042234, 0.00277342925285795, 0.0245182700316759, 0.0438585164992824, 0.0596659992826556, 0.00748649164225524, 0.00271025388875231, 0.0199331235955057, 0.00412509247967422, 0.0105581824129819, 0.00166668956741685, 0.00207934696634404, 0.00258362633297805, 0, 0.00293190263618373],
						"endTimestamp": "2021-11-17T00:00:00",
						"errorFlags": ["speedDensity"],
						"frequencyBand": "2.4 GHz",
						"impactedClients": 8,
						"insightUid": "a8e5183396bdb7d6839e",
						"interferenceBinSize": 1,
						"interferenceKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0014123802512796, 0.00462239985639912, 0.0109255137257865, 0.0192793809909069, 0.028648007251061, 0.0407786924996494, 0.0466675552501512, 0.0519151218522691, 0.0608248853514795, 0.0627524598229994, 0.0552925425754375, 0.0568539322394311, 0.0565783551533792, 0.0600351847545202, 0.0597634175366443, 0.0555330067291502, 0.0500533332802601, 0.0441899976106072, 0.0445901459116276, 0.0361324769032112, 0.0281415955870246, 0.027656414881891, 0.0239444932862039, 0.01596518038793, 0.0140396127428311, 0.00944255027404112, 0.00808797319171263, 0.00641233041357363, 0.00455608704511082, 0.00248254299549538, 0.00262286635829748, 0.00165261588568081, 0.00190995684370418, 0, 0.00106917603409459, 0.00138667179540104, 0.00131110406629299, 0, 0, 0, 0, 0.00119695346575243, 0],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 478,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 478,
							"key": 1
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00680351977138329, 0.145264030334611, 0.726949268285362, 0.117889084023281, 0.00309409758536283],
						"packetFailureKde": null,
						"packetRetryKde": null,
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00962005683460592, 0.205423697322451, 0.569912491685885, 0.205423697322451, 0.00962005683460592],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 478,
							"key": 4
						}],
						"primaryImageVersion": "8.5.161.6",
						"rootCauseCategory": "External RF load",
						"rootCauseCategoryId": "a72edc5da049e7f555a3",
						"rootCauseFeatures": [{
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgUtilization",
							"kpi": "utilizationKde",
							"weight": 0.0286225402504472
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgInterference",
							"kpi": "interferenceKde",
							"weight": 0.0518783542039356
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": null,
							"feature": "model",
							"kpi": "apModel",
							"weight": 0.0500894454382826
						}],
						"rootCauseId": "fc45e7",
						"rssiKde": [0, 0, 0.0104342185592453, 0.0364732092735883, 0.0392930031637987, 0.0219165960001433, 0.0193208012802256, 0.0434251007524932, 0.0447636664696149, 0.0555262642761931, 0.0709521689690838, 0.0741511669543969, 0.0834389937602724, 0.0499672106207333, 0.0727247922193049, 0.0527636841655463, 0.0432170006944232, 0.0415068374206798, 0.0439085966676604, 0.0299666806842653, 0.00547013519626704, 0, 0.00231392582233026, 0.0172140021096963, 0.0242011413058311, 0.00495598940924232, 0, 0.00220045784503798, 0.0176295237367803, 0.0225629324912696, 0.00421663587232409, 0.00140639401719043, 0.00807750617614443, 0.00964385030529329, 0.0022504892216097, 0.00255226961198305, 0.0194152062539143, 0.0196859960844435, 0.00245355260897351],
						"severity": "s2",
						"siteHierarchyArray": ["Global / North America / USA / California / San Francisco / SFO15 / Flr-SFO15-1"],
						"siteId": "474dfc39-2ed6-428d-88ad-ec59a4c575f0",
						"snrKde": [0, 0, 0.0109316906200363, 0.0278409533513714, 0.0119820310364011, 0.0186503576095867, 0.0490119765624168, 0.0492569659686636, 0.0485405472763146, 0.0408400882272901, 0.0106807147630148, 0.0143397818734327, 0.0388235926384128, 0.0545799859360074, 0.0717206383792318, 0.0805636662756172, 0.0880773379973215, 0.028812224745693, 0.0328443663317628, 0.0508024720531505, 0.0121673686176215, 0.0164458858481382, 0.0266276328873901, 0.0220549426171807, 0.0246445926161813, 0.00642912659468797, 0.0183982986360116, 0.0245704607668031, 0.0064209604488275, 0.0206471084933135, 0.0412621876932548, 0.0259858775825738, 0.00381440167811091, 0, 0, 0, 0.00181401996127625, 0.00915659815265714, 0.00938895985144162],
						"speedBins": [0, 3.04166666666667, 6.08333333333333, 9.125, 12.1666666666667, 15.2083333333333, 18.25, 21.2916666666667, 24.3333333333333, 27.375, 30.4166666666667, 33.4583333333333, 36.5, 39.5416666666667, 42.5833333333333, 45.625, 48.6666666666667, 51.7083333333333, 54.75, 57.7916666666667, 60.8333333333333, 63.875, 66.9166666666667, 69.9583333333333, 73],
						"speedDensity": [0.0915032679738562, 0.284469343292873, 0.106131341425459, 0, 0.0563336445689387, 0, 0, 0.1291627762216, 0, 0.172424525365702, 0, 0, 0, 0, 0.090258325552443, 0, 0, 0, 0, 0.0650482415188298, 0, 0.00186741363211952, 0, 0.00280112044817927],
						"startTimestamp": "2021-10-20T00:00:00",
						"sumRadioAppCount": 152,
						"timestamp": "2021-11-17T02:08:10.717983",
						"trafficBinSize": 0.157894736842105,
						"trafficKde": [0.189398285595411, 0.18298890105009, 0.164986128269113, 0.138827155790878, 0.109032728259122, 0.0799436413214602, 0.0547415700436334, 0.0350318690298463, 0.0209811113462217, 0.0117938608384654, 0.00625959394780569, 0.00317613185414346, 0.00157875527731506],
						"uniqueClients": 30,
						"utilizationBinSize": 1,
						"utilizationKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00141288520904952, 0.00462405246852042, 0.0108915754628664, 0.0190060387285729, 0.0286204051789788, 0.0407932718012264, 0.0466842399528757, 0.0519336826783155, 0.060808787238472, 0.0624946601660781, 0.055274466525208, 0.0569121031817946, 0.0568409738856515, 0.0598142579908242, 0.059746939924971, 0.0555528610305744, 0.0500712284749341, 0.0441679521503407, 0.0444015415787684, 0.036630176468459, 0.0279471105267315, 0.0276662086378169, 0.0242332890577062, 0.0159707942555064, 0.0138020532642291, 0.00964638196174711, 0.00785124828184044, 0.0064147169933414, 0.00483785699303241, 0.0024833365206223, 0.00238150742830573, 0.00189559743260145, 0.0019484840766193, 0, 0.00106955829289165, 0.00138716756181861, 0.00131157281554436, 0, 0, 0, 0, 0.00119747544353331, 0]
					}],
					"totalCount": 1
				}
			}
		},
	"getApDetailforHighCoChannel6Template":
		{
			"data": {
				"getClientInsightRadios36": {
					"__typename": "GetClientInsightRadios36Connection",
					"nodes": [{
						"__typename": "ClientInsightRadios36Type",
						"aesApMac": "aes:a0:f8:49:7e:c0:69",
						"aesApName": "aes:SFO15-C9120-02",
						"apEntityId": "03cc63d0d1597b02d8df",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 477,
							"key": 0
						}],
						"apModel": "C9120AXE-B",
						"channelChangeCountBinSize": 0.0526315789473684,
						"channelChangeCountKde": [0.083822086617284, 0.083358982347609, 0.0819849645572029, 0.0797450794734103, 0.0767116722166308, 0.0729805072186312, 0.0686657515323493, 0.0638941873315881, 0.0587990618058377, 0.0535139877826142, 0.0481672782345456, 0.0428770374651486, 0.0377472491444952, 0.0328650061081307, 0.0282989290177146, 0.0240987299537028, 0.0202958003987504, 0.0169046461281695, 0.0139249568323512, 0.011344085833834],
						"clientCountBinSize": 0.157894736842105,
						"clientCountKde": [0.0908151708028911, 0.0910404832099372, 0.089805444568627, 0.0871788466821231, 0.0832936095116213, 0.0783356624544027, 0.0725292404320008, 0.0661202087749365, 0.0593591270494046, 0.0524856364385182, 0.0457154465980565, 0.0392307690263427, 0.0331745688236362, 0.0276485562109757, 0.0227144698982254, 0.0183979514707836, 0.0146941851099584, 0.0115744709960451, 0.00899298932290097, 0.00689316261861292],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.399027315999435, 0.0540024782530102, 0, 0.0136436123199871, 0.122686846784895, 0.180315725355909, 0.0226383264328999, 0.00252362866802155, 0.0236231641870284, 0.0507646222360757, 0.0660289300555321, 0.00822322041796093, 0.0030128953672889, 0.0219326866640847, 0.00495901252468165, 0.0147197063180949, 0.0021096213918078, 0.00130002605519177, 0.0032919599831467, 0, 0.00317549526519288, 0, 0.00202072571975613],
						"endTimestamp": "2021-11-17T00:00:00",
						"errorFlags": ["snrKde"],
						"frequencyBand": "2.4 GHz",
						"impactedClients": 6,
						"insightUid": "a8b74b8fbbb1a81635e0",
						"interferenceBinSize": 1,
						"interferenceKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00157969159094689, 0.00406945965181375, 0.00611161308389423, 0.00986161783563607, 0.0193189072877636, 0.0447833099078391, 0.064864911895386, 0.0730021749758596, 0.0724030692497033, 0.0810486521905279, 0.0927999821234139, 0.087705017251453, 0.0776072050810598, 0.0521300294231587, 0.0321559119328077, 0.0225906812116662, 0.0230068115938577, 0.0176309446844898, 0.0156985765341648, 0.0168728277511738, 0.0190658357935989, 0.0183875891686991, 0.0186853537213964, 0.0161613080088377, 0.0166846522326958, 0.0171775807094359, 0.0158981397993521, 0.0155844688352622, 0.0123409056301622, 0.00987301049388303, 0.00813781620730941, 0.00506579153587023, 0.00339594332331673, 0.00357175298270294, 0.00209035481909772, 0, 0.00151921691220078],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 477,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 477,
							"key": 1
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0101244764443637, 0.121934963870754, 0.407903030110915, 0.366105856944875, 0.0876535013647716, 0.00627817126432008],
						"packetFailureKde": null,
						"packetRetryKde": null,
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00962005683460592, 0.205423697322451, 0.569912491685885, 0.205423697322451, 0.00962005683460592],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 477,
							"key": 4
						}],
						"primaryImageVersion": "8.5.161.6",
						"rootCauseCategory": "External RF load",
						"rootCauseCategoryId": "a72edc5da049e7f555a3",
						"rootCauseFeatures": [{
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgUtilization",
							"kpi": "utilizationKde",
							"weight": 0.0286885245901639
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgInterference",
							"kpi": "interferenceKde",
							"weight": 0.0491803278688525
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": null,
							"feature": "model",
							"kpi": "apModel",
							"weight": 0.0450819672131148
						}],
						"rootCauseId": "fc45e7",
						"rssiKde": [0, 0, 0.0063085204140125, 0.0149152497280324, 0.00853985033791247, 0.070051080115481, 0.167840180952737, 0.0526067120857462, 0.00880041945528252, 0.0694265023291743, 0.147961809543804, 0.0421007030688639, 0.0141427206182297, 0.0270791736861654, 0.0397450425013322, 0.0574235966286132, 0.0174227504092191, 0.0578428637506377, 0.0981146942084124, 0.0212132137705729, 0.00171579072092871, 0.00172043659003112, 0.00206922583383988, 0.00280517306299335, 0.00368133592980024, 0.00191292800205847, 0.00194617769985142, 0.0051259719137233, 0.00732904812821535, 0.00699493235061716, 0.00515867354207785, 0.00620835873706597, 0.00873308459614311, 0.00910841436911255, 0.00602591801753165, 0.00258786490062437, 0.00341744688428928],
						"severity": "s2",
						"siteHierarchyArray": ["Global / North America / USA / California / San Francisco / SFO15 / Flr-SFO15-1"],
						"siteId": "92a96071-f610-47bf-8012-701b51835737",
						"snrKde": [0.0100637518042021, 0.0294505498138667, 0.0384713562846578, 0.0767168583881832, 0.0519332449479368, 0.0659346694111157, 0.0232116654076823, 0.0475675885355612, 0.10247795373604, 0.0320443476921405, 0.00335394917500274, 0.00170610699697976, 0.0011802876308187, 0.0107167536389531, 0.132429998687977, 0.248103590854687, 0.0621505249512498, 0.00494273774002693, 0.00472501863117476, 0.00298668977415537, 0.00303274512980843, 0.00449759887039191, 0.00145993936049026, 0, 0, 0.00144999792616024, 0.00241781090376909, 0.00106525152331081, 0.0011510920692629, 0.00175869260475009, 0, 0, 0.00184198115565979, 0.00872717550657758, 0.0127495970163038, 0.00629786528532618, 0, 0, 0, 0, 0.00177444811805149],
						"speedBins": [0, 15.1, 30.2, 45.3, 60.4, 75.5, 90.6, 105.7, 120.8, 135.9, 151],
						"speedDensity": [0.754430379746835, 0.00253164556962025, 0.188607594936709, 0.00632911392405063, 0.00886075949367088, 0.0164556962025316, 0, 0.00126582278481013, 0.00632911392405063, 0.0151898734177215],
						"startTimestamp": "2021-10-20T00:00:00",
						"sumRadioAppCount": 12,
						"timestamp": "2021-11-17T02:08:10.717983",
						"trafficBinSize": 0.0526315789473684,
						"trafficKde": [0.0738346143277113, 0.0735510933868171, 0.0727070460887349, 0.0713217700917537, 0.0694266117205742, 0.0670637868765276, 0.0642848182547782, 0.0611486696270709, 0.057719671427607, 0.0540653392616206, 0.0502541879744962, 0.0463536387912306, 0.0424281064238016, 0.0385373379774335, 0.0347350572583267, 0.0310679481286332, 0.0275749903183093, 0.0242871419352918, 0.0212273459624473, 0.0184108241668346],
						"uniqueClients": 15,
						"utilizationBinSize": 1,
						"utilizationKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00157969159094689, 0.00406945965181375, 0.00611161308389423, 0.00986161783563607, 0.0193189072877636, 0.0447833099078391, 0.064864911895386, 0.0730021749758596, 0.0724030692497033, 0.0810486521905279, 0.0927999821234139, 0.087705017251453, 0.0776072050810598, 0.0521300294231587, 0.0321559119328077, 0.0225906812116662, 0.0230068115938577, 0.0176309446844898, 0.0156985765341648, 0.0168728277511738, 0.0190658357935989, 0.0183875891686991, 0.0186853537213964, 0.0161613080088377, 0.0166846522326958, 0.0171775807094359, 0.0158981397993521, 0.0155844688352622, 0.0123409056301622, 0.00987301049388303, 0.00813781620730941, 0.00506579153587023, 0.00339594332331673, 0.00357175298270294, 0.00209035481909772, 0, 0.00151921691220078]
					}],
					"totalCount": 1
				}
			}
		},
	"getApDetailforHighCoChannel7Template":
		{
			"data": {
				"getClientInsightRadios36": {
					"__typename": "GetClientInsightRadios36Connection",
					"nodes": [{
						"__typename": "ClientInsightRadios36Type",
						"aesApMac": "aes:a0:f8:49:7e:c0:75",
						"aesApName": "aes:SFO15-C9136-03",
						"apEntityId": "03519dd87f4f4451c603",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 479,
							"key": 0
						}],
						"apModel": "C9136I-B",
						"channelChangeCountBinSize": 0.0526315789473684,
						"channelChangeCountKde": [0.083822086617284, 0.083358982347609, 0.0819849645572029, 0.0797450794734103, 0.0767116722166308, 0.0729805072186312, 0.0686657515323493, 0.0638941873315881, 0.0587990618058377, 0.0535139877826142, 0.0481672782345456, 0.0428770374651486, 0.0377472491444952, 0.0328650061081307, 0.0282989290177146, 0.0240987299537028, 0.0202958003987504, 0.0169046461281695, 0.0139249568323512, 0.011344085833834],
						"clientCountBinSize": 0.105263157894737,
						"clientCountKde": [0.0707870163005052, 0.0707859116859164, 0.070261498416402, 0.0692266512052054, 0.0677053546535887, 0.0657317809011298, 0.0633490142309367, 0.0606074890667078, 0.0575632203560003, 0.0542759127733078, 0.0508070372217831, 0.0472179598927377, 0.0435682011680518, 0.0399138897452507, 0.0363064625823905, 0.0327916447799161, 0.0294087265463669, 0.026190138065894, 0.0231613083756554, 0.0203407820322537],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.434366789143963, 0.0587851551677898, 0, 0.0135476436228357, 0.121186109630866, 0.173575192744557, 0.021697619382587, 0.00284922859381105, 0.0256225981768208, 0.0474237823411685, 0.0560474388365835, 0.0069236758420328, 0.00199604934145164, 0.0146375532717358, 0.00301056041199157, 0.00761839871175723, 0.00142703713761651, 0.00332567926872703, 0.00332401011606116, 0, 0.00263547825764444],
						"endTimestamp": "2021-11-17T00:00:00",
						"errorFlags": ["rssiKde", "snrKde", "speedDensity"],
						"frequencyBand": "2.4 GHz",
						"impactedClients": 4,
						"insightUid": "a8eca9282d5aad75bee9",
						"interferenceBinSize": 1,
						"interferenceKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00140578915856594, 0.00225931157957795, 0.00455866667691129, 0.00960035530382134, 0.0158883561341718, 0.0233816987550517, 0.0350571808396598, 0.0482491713365072, 0.0550345220515738, 0.0637870478848436, 0.064545984768321, 0.0680545661116984, 0.0672421021117329, 0.0570197350657559, 0.0498183837284212, 0.0491490846843912, 0.0416303540955843, 0.0378176424285068, 0.0396239007589007, 0.0329205539471589, 0.0356537466383818, 0.0318192646742431, 0.0319058277909907, 0.0258805309158333, 0.0270975385116248, 0.0220350442963345, 0.0155235158886613, 0.0116136090715645, 0.00633435017508704, 0.0057118628112444, 0.00394355150304191, 0.00264769715620603, 0.0028481457538571, 0.00321358817370352, 0.00166732470298517, 0.00157013382628449, 0, 0.00125571766220303, 0.00105369285018885, 0],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 479,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 479,
							"key": 1
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0984044458334355, 0.735010957318018, 0.157482632691069, 0.00910196415747823],
						"packetFailureKde": null,
						"packetRetryKde": null,
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0122555325670098, 0.261700825250269, 0.726043642182721],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 479,
							"key": 4
						}],
						"primaryImageVersion": "8.5.161.6",
						"rootCauseCategory": "External RF load",
						"rootCauseCategoryId": "a72edc5da049e7f555a3",
						"rootCauseFeatures": [{
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgPower",
							"kpi": "powerKde",
							"weight": 0.0330188679245283
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgUtilization",
							"kpi": "utilizationKde",
							"weight": 0.0306603773584906
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgInterference",
							"kpi": "interferenceKde",
							"weight": 0.0589622641509434
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": null,
							"feature": "model",
							"kpi": "apModel",
							"weight": 0.035377358490566
						}],
						"rootCauseId": "fc45e7",
						"rssiKde": [0, 0, 0, 0.00377423338341263, 0.0761958234122484, 0.189555987856139, 0.0678827360675173, 0.167037407318329, 0.375134377444246, 0.105153797472084, 0.00362296851492954, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00106719788374897, 0.00114443906811787, 0, 0, 0, 0, 0, 0, 0, 0.00120581731493026, 0.00119403902450138, 0.00133206558108349, 0, 0, 0, 0.00255964242700442, 0.00180484714024914, 0],
						"severity": "s2",
						"siteHierarchyArray": ["Global / North America / USA / California / San Francisco / SFO15 / Flr-SFO15-1"],
						"siteId": "961b033b-5810-40aa-805a-7cd0022116e6",
						"snrKde": [0.0681030536157172, 0.188904930335642, 0.0644005801501862, 0.00276410635496608, 0.00459299782305992, 0.0798387372399696, 0.189580866681712, 0.0567025382156864, 0.003467858359896, 0, 0, 0.00576378331855092, 0.0930127526157513, 0.184480365244163, 0.0449703442614311, 0.00134731977547807, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00105442140308902, 0.00116278928300021, 0, 0, 0, 0, 0.00114793431921854, 0.00107045723121591, 0, 0, 0, 0, 0, 0, 0, 0.00127671789393426, 0.00115970275089505, 0.00161856951350678, 0.00219610319495006, 0],
						"speedBins": [0, 1.6551724137931, 3.31034482758621, 4.96551724137931, 6.62068965517241, 8.27586206896552, 9.93103448275862, 11.5862068965517, 13.2413793103448, 14.8965517241379, 16.551724137931, 18.2068965517241, 19.8620689655172, 21.5172413793103, 23.1724137931034, 24.8275862068966, 26.4827586206897, 28.1379310344828, 29.7931034482759, 31.448275862069, 33.1034482758621, 34.7586206896552, 36.4137931034483, 38.0689655172414, 39.7241379310345, 41.3793103448276, 43.0344827586207, 44.6896551724138, 46.3448275862069, 48],
						"speedDensity": [0, 0, 0, 0.661691542288557, 0, 0, 0, 0.00995024875621891, 0, 0, 0, 0, 0, 0.328358208955224, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
						"startTimestamp": "2021-10-20T00:00:00",
						"sumRadioAppCount": 27,
						"timestamp": "2021-11-17T02:08:10.717983",
						"trafficBinSize": 0.0526315789473684,
						"trafficKde": [0.0738346143277113, 0.073551093386817, 0.0727070460887348, 0.0713217700917537, 0.0694266117205741, 0.0670637868765276, 0.0642848182547782, 0.0611486696270709, 0.057719671427607, 0.0540653392616206, 0.0502541879744962, 0.0463536387912306, 0.0424281064238015, 0.0385373379774335, 0.0347350572583267, 0.0310679481286332, 0.0275749903183093, 0.0242871419352917, 0.0212273459624473, 0.0184108241668346],
						"uniqueClients": 5,
						"utilizationBinSize": 1,
						"utilizationKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00140578915856594, 0.00225931157957795, 0.00455866667691129, 0.00960035530382134, 0.0158883561341718, 0.0233816987550517, 0.0350571808396598, 0.0482491713365072, 0.0550345220515738, 0.0637870478848436, 0.064545984768321, 0.0680545661116984, 0.0672421021117329, 0.0570197350657559, 0.0498183837284212, 0.0491490846843912, 0.0416303540955843, 0.0378176424285068, 0.0396239007589007, 0.0329205539471589, 0.0356537466383818, 0.0318192646742431, 0.0319058277909907, 0.0258805309158333, 0.0270975385116248, 0.0220350442963345, 0.0155235158886613, 0.0116136090715645, 0.00633435017508704, 0.0057118628112444, 0.00394355150304191, 0.00264769715620603, 0.0028481457538571, 0.00321358817370352, 0.00166732470298517, 0.00157013382628449, 0, 0.00125571766220303, 0.00105369285018885, 0]
					}],
					"totalCount": 1
				}
			}
		},
	"getApDetailforHighCoChannel8Template":
		{
			"data": {
				"getClientInsightRadios36": {
					"__typename": "GetClientInsightRadios36Connection",
					"nodes": [{
						"__typename": "ClientInsightRadios36Type",
						"aesApMac": "aes:a0:f8:49:7e:c0:76",
						"aesApName": "aes:AP9105_1",
						"apEntityId": "03d625f9ea1c023792cd",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 465,
							"key": 0
						}],
						"apModel": "C9105AXI-B",
						"channelChangeCountBinSize": 0.0526315789473684,
						"channelChangeCountKde": [0.083822086617284, 0.083358982347609, 0.0819849645572029, 0.0797450794734103, 0.0767116722166308, 0.0729805072186312, 0.0686657515323493, 0.0638941873315881, 0.0587990618058377, 0.0535139877826142, 0.0481672782345456, 0.0428770374651486, 0.0377472491444952, 0.0328650061081307, 0.0282989290177146, 0.0240987299537028, 0.0202958003987504, 0.0169046461281695, 0.0139249568323512, 0.011344085833834],
						"clientCountBinSize": 0.157894736842105,
						"clientCountKde": [0.0866970224059495, 0.0873727951787928, 0.0866843671725566, 0.0846753173135653, 0.0814490385521136, 0.0771599691383121, 0.0720013888280988, 0.0661911173811317, 0.0599565724095014, 0.0535205760759264, 0.0470890734730044, 0.0408415887962195, 0.0349248578409323, 0.0294496950646243, 0.0244908282954856, 0.0200891957523238, 0.0162560617895909, 0.0129782664933623, 0.010223964173351, 0.00794830386515762],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.389803203097633, 0.0527541304438639, 0, 0.0118659100482347, 0.111498814399688, 0.193304264585529, 0.0246308448601477, 0.0027367285630677, 0.026299361402384, 0.0590671587938282, 0.0625252780214679, 0.00759510799080658, 0.00304486511534076, 0.0224283048141849, 0.00490542712484552, 0.0138282700648728, 0.00220431571063909, 0.0028777394509916, 0.00340554333033244, 0, 0.00368762801478956, 0],
						"endTimestamp": "2021-11-17T00:00:00",
						"errorFlags": ["speedDensity"],
						"frequencyBand": "2.4 GHz",
						"impactedClients": 4,
						"insightUid": "a85c70e2a5c821534393",
						"interferenceBinSize": 1,
						"interferenceKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00119384262997196, 0.00356611857146979, 0.0114982722014921, 0.028986339639642, 0.0528183508035933, 0.0718406548288844, 0.0802067452661398, 0.078607047456626, 0.0667179983629066, 0.0496597234045643, 0.0462697229544219, 0.0520998271397649, 0.0411546466778097, 0.0469490037295675, 0.0568325377283669, 0.0492294945043195, 0.045241481926054, 0.0508413601626076, 0.0449313136505695, 0.0392627228087259, 0.0285493653078548, 0.0202866990053656, 0.0163124231861028, 0.00805118097576482, 0.00399100318974846, 0.00255754027307934, 0.00127113899340233],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 465,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 465,
							"key": 1
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0442455490342901, 0.371116857194783, 0.519807296819843, 0.0648302969510843],
						"packetFailureKde": null,
						"packetRetryKde": null,
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0122555325670098, 0.261700825250269, 0.726043642182721],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 465,
							"key": 4
						}],
						"primaryImageVersion": "8.5.161.6",
						"rootCauseCategory": "External RF load",
						"rootCauseCategoryId": "a72edc5da049e7f555a3",
						"rootCauseFeatures": [{
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgPower",
							"kpi": "powerKde",
							"weight": 0.0372549019607843
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgUtilization",
							"kpi": "utilizationKde",
							"weight": 0.0313725490196078
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgInterference",
							"kpi": "interferenceKde",
							"weight": 0.0549019607843137
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": null,
							"feature": "model",
							"kpi": "apModel",
							"weight": 0.0352941176470588
						}],
						"rootCauseId": "fc45e7",
						"rssiKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00156985429915631, 0, 0, 0.00140389284900552, 0.0019060694840036, 0.00493000817951604, 0.00722311188318766, 0.00720152562226155, 0.0105735915646598, 0.00985755265021509, 0.0183322238240541, 0.13421508311255, 0.295652769521458, 0.260279151951594, 0.110269941225522, 0.0297666475030298, 0.0193084069342983, 0.0159803894208877, 0.0132260145086845, 0.010412493787772, 0.00808439530586339, 0.00886949474130855, 0.00538714045785788, 0.00528861861446553, 0.00524106791956758, 0.0024548870986065, 0, 0.00243678675074464, 0.00291692564148199, 0.00149989912349499, 0, 0.00111467392265366, 0.00190971339375858, 0.00166744675391778],
						"severity": "s2",
						"siteHierarchyArray": ["Global / North America / USA / California / San Jose / SJC01 / Flr-SJC1-1"],
						"siteId": "5b68362b-3c1e-4415-911a-adc13e06b0bc",
						"snrKde": [0, 0, 0, 0, 0, 0, 0, 0, 0.00109343131761341, 0, 0.00108700881267038, 0.00176427822733457, 0.00191133288545285, 0.00189782037364929, 0.00163747528967726, 0.00145372559267596, 0.00273441138170345, 0.00438131293850877, 0.00412552998652007, 0.00322108003584698, 0.00449124760083031, 0.00574055238385694, 0.00434767461609418, 0.00546723274168796, 0.010123564853871, 0.0226831925916833, 0.0257981699432553, 0.0123692725101794, 0.020946299000151, 0.0238328486208922, 0.0233320848625409, 0.128851444824442, 0.242705498267336, 0.205256084029062, 0.11390620859217, 0.0486801712536489, 0.0196084050243723, 0.0128875256696875, 0.0110023906373148, 0.00698265224687389, 0.00484384891752426, 0.00385776688920463, 0.00311394835300625, 0.00168328194018408, 0.00243304017593148, 0.00187982545348954, 0, 0.00199224479092347, 0.00199158295208508, 0.00114317142833933, 0.00144947052929398],
						"speedBins": [0, 13.1818181818182, 26.3636363636364, 39.5454545454545, 52.7272727272727, 65.9090909090909, 79.0909090909091, 92.2727272727273, 105.454545454545, 118.636363636364, 131.818181818182, 145],
						"speedDensity": [0.914553990610329, 0.00187793427230047, 0.0028169014084507, 0.00187793427230047, 0.0028169014084507, 0.0187793427230047, 0.00281690140845071, 0, 0.00657276995305164, 0.0215962441314554, 0.0262910798122066],
						"startTimestamp": "2021-10-20T00:00:00",
						"sumRadioAppCount": 164,
						"timestamp": "2021-11-17T02:08:10.717983",
						"trafficBinSize": 0.0526315789473684,
						"trafficKde": [0.0738346143277113, 0.0735510933868171, 0.0727070460887349, 0.0713217700917537, 0.0694266117205742, 0.0670637868765276, 0.0642848182547782, 0.0611486696270709, 0.057719671427607, 0.0540653392616206, 0.0502541879744962, 0.0463536387912306, 0.0424281064238016, 0.0385373379774335, 0.0347350572583267, 0.0310679481286332, 0.0275749903183093, 0.0242871419352918, 0.0212273459624473, 0.0184108241668346],
						"uniqueClients": 13,
						"utilizationBinSize": 1,
						"utilizationKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00119384262997196, 0.00356611857146979, 0.0114982722014921, 0.028986339639642, 0.0528183508035933, 0.0718406548288844, 0.0802067452661398, 0.078607047456626, 0.0667179983629066, 0.0496597234045643, 0.0462697229544219, 0.0520998271397649, 0.0411546466778097, 0.0469490037295675, 0.0568325377283669, 0.0492294945043195, 0.045241481926054, 0.0508413601626076, 0.0449313136505695, 0.0392627228087259, 0.0285493653078548, 0.0202866990053656, 0.0163124231861028, 0.00805118097576482, 0.00399100318974846, 0.00255754027307934, 0.00127113899340233]
					}],
					"totalCount": 1
				}
			}
		},
	"getApDetailforHighCoChannel9Template":
		{
			"data": {
				"getClientInsightRadios36": {
					"__typename": "GetClientInsightRadios36Connection",
					"nodes": [{
						"__typename": "ClientInsightRadios36Type",
						"aesApMac": "aes:a0:f8:49:7e:c0:77",
						"aesApName": "aes:AP9124_1",
						"apEntityId": "035e67bf14d47ea25c7f",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 477,
							"key": 0
						}],
						"apModel": "C9124AXI",
						"channelChangeCountBinSize": 0.0526315789473684,
						"channelChangeCountKde": [0.083822086617284, 0.083358982347609, 0.0819849645572029, 0.0797450794734103, 0.0767116722166308, 0.0729805072186312, 0.0686657515323493, 0.0638941873315881, 0.0587990618058377, 0.0535139877826142, 0.0481672782345456, 0.0428770374651486, 0.0377472491444952, 0.0328650061081307, 0.0282989290177146, 0.0240987299537028, 0.0202958003987504, 0.0169046461281695, 0.0139249568323512, 0.011344085833834],
						"clientCountBinSize": 0.157894736842105,
						"clientCountKde": [0.0923513710174314, 0.0924120657680556, 0.090976308647058, 0.0881220410206794, 0.083992968369684, 0.0787865901624761, 0.072738584007214, 0.0661052583162108, 0.0591458760295237, 0.0521065060744199, 0.0452067213832538, 0.0386300003771334, 0.0325181820110076, 0.0269698483136984, 0.0220421225861537, 0.0177551125160581, 0.0140981066135211, 0.0110366383520896, 0.00851963788297907, 0.00648606055135202],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.378894242020916, 0.0512777634562964, 0, 0.0125586919425315, 0.118050077815913, 0.204661332350183, 0.0260731391325001, 0.0029830981982836, 0.0275653864150769, 0.0554787888052343, 0.064131719052772, 0.0078869546922678, 0.0026055344984826, 0.0191662665012773, 0.00412579678605419, 0.0113297532466648, 0.00188734494549591, 0.00312767524877305, 0.00412903927659008, 0, 0.00290505996531194, 0],
						"endTimestamp": "2021-11-17T00:00:00",
						"errorFlags": ["snrKde"],
						"frequencyBand": "2.4 GHz",
						"impactedClients": 2,
						"insightUid": "a8e77f570728958fcc73",
						"interferenceBinSize": 1,
						"interferenceKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00100226473001476, 0.00299000644997447, 0.00189285228794577, 0.00371722896561671, 0.00514839988899796, 0.00849569158575559, 0.0144794132525982, 0.0171771874525639, 0.026980962249598, 0.0384763897017409, 0.0414207467538308, 0.0451322672536403, 0.0491487824993809, 0.0463431034188876, 0.0505455963922178, 0.0432311251130919, 0.0399976454084121, 0.0405222217634548, 0.0326644756256588, 0.0293454178917502, 0.0317879762341577, 0.0262266733544922, 0.0246722826488427, 0.0310059567128227, 0.0307509898397455, 0.0268236791370818, 0.0300539493612538, 0.0307339792953532, 0.0314579059329254, 0.0285119458724447, 0.0254044619629945, 0.0256212758269972, 0.0257110705523505, 0.0231065762524503, 0.0171702773031579, 0.0130421274556901, 0.00968357295684377, 0.00788218664118929, 0.00723205817898522, 0.00566028539261294, 0.0042174801517876, 0.00284894100585715],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 477,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 477,
							"key": 1
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0713166497646057, 0.561456017093186, 0.329840556365018, 0.0373867767771911],
						"packetFailureKde": null,
						"packetRetryKde": null,
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00962005683460593, 0.205423697322451, 0.569912491685885, 0.205423697322451, 0.00962005683460593],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 477,
							"key": 4
						}],
						"primaryImageVersion": "8.5.161.6",
						"rootCauseCategory": "External RF load",
						"rootCauseCategoryId": "a72edc5da049e7f555a3",
						"rootCauseFeatures": [{
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgUtilization",
							"kpi": "utilizationKde",
							"weight": 0.0327868852459016
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgInterference",
							"kpi": "interferenceKde",
							"weight": 0.0585480093676815
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": null,
							"feature": "model",
							"kpi": "apModel",
							"weight": 0.0327868852459016
						}],
						"rootCauseId": "fc45e7",
						"rssiKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00299715038510175, 0.0510036427685723, 0.107409586695221, 0.0326174376511315, 0.0655386438309955, 0.124339187295727, 0.0305426483715357, 0.00689770585451079, 0.0590666181506563, 0.103893078166342, 0.0589426990580523, 0.0712747767403242, 0.0303342675851205, 0.00659038591285044, 0.00219114110587445, 0, 0.00262136729971805, 0.00478010866699775, 0.00333556736296797, 0.011524238033474, 0.0771651143829025, 0.106621708055736, 0.0360093190247688, 0.00430360760142005],
						"severity": "s2",
						"siteHierarchyArray": ["Global / North America / USA / California / San Jose / SJC01 / Flr-SJC1-1"],
						"siteId": "92a96071-f610-47bf-8012-701b51835737",
						"snrKde": [0.00115401009896957, 0.0186112630363689, 0.0479543634242503, 0.0182232951792009, 0.0441902686215477, 0.127244560724839, 0.0901474984602655, 0.0186500172164523, 0.00107210370777394, 0, 0.00117573780978181, 0.00373337639957759, 0.0299865869009978, 0.0594136687437397, 0.0205552148888281, 0.0669088134904947, 0.120608327620614, 0.0338698362308649, 0.075580035260133, 0.122281079388784, 0.0297753364073897, 0.00707745858611388, 0.00123064413812748, 0, 0.0018863554914975, 0.00333190926086831, 0.0025741150120948, 0.00185153543986519, 0, 0, 0, 0, 0, 0.00366619123566236, 0.00540219939850867, 0.0188145869785611, 0.019831032606702, 0.00319857824112531],
						"speedBins": [0, 12.0833333333333, 24.1666666666667, 36.25, 48.3333333333333, 60.4166666666667, 72.5, 84.5833333333333, 96.6666666666667, 108.75, 120.833333333333, 132.916666666667, 145],
						"speedDensity": [0.417085427135678, 0.184254606365159, 0.108877721943049, 0.0100502512562814, 0.033500837520938, 0, 0, 0.229480737018426, 0, 0.0100502512562814, 0.0033500837520938, 0.00335008375209381],
						"startTimestamp": "2021-10-20T00:00:00",
						"sumRadioAppCount": 35,
						"timestamp": "2021-11-17T02:08:10.717983",
						"trafficBinSize": 0.0526315789473684,
						"trafficKde": [0.0738346143277113, 0.073551093386817, 0.0727070460887348, 0.0713217700917537, 0.0694266117205741, 0.0670637868765276, 0.0642848182547782, 0.0611486696270709, 0.057719671427607, 0.0540653392616206, 0.0502541879744962, 0.0463536387912306, 0.0424281064238015, 0.0385373379774335, 0.0347350572583267, 0.0310679481286332, 0.0275749903183093, 0.0242871419352917, 0.0212273459624473, 0.0184108241668346],
						"uniqueClients": 10,
						"utilizationBinSize": 1,
						"utilizationKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00100226473001476, 0.00299000644997447, 0.00189285228794577, 0.00371722896561671, 0.00514839988899796, 0.00849569158575559, 0.0144794132525982, 0.0171771874525639, 0.026980962249598, 0.0384763897017409, 0.0414207467538308, 0.0451322672536403, 0.0491487824993809, 0.0463431034188876, 0.0505455963922178, 0.0432311251130919, 0.0399976454084121, 0.0405222217634548, 0.0326644756256588, 0.0293454178917502, 0.0317879762341577, 0.0262266733544922, 0.0246722826488427, 0.0310059567128227, 0.0307509898397455, 0.0268236791370818, 0.0300539493612538, 0.0307339792953532, 0.0314579059329254, 0.0285119458724447, 0.0254044619629945, 0.0256212758269972, 0.0257110705523505, 0.0231065762524503, 0.0171702773031579, 0.0130421274556901, 0.00968357295684377, 0.00788218664118929, 0.00723205817898522, 0.00566028539261294, 0.0042174801517876, 0.00284894100585715]
					}],
					"totalCount": 1
				}
			}
		},
	"getApDetailforHighCLientActivity1Template":
		{
			"data": {
				"getClientInsightRadios36": {
					"__typename": "GetClientInsightRadios36Connection",
					"nodes": [{
						"__typename": "ClientInsightRadios36Type",
						"aesApMac": "aes:a0:f8:49:7e:c0:70",
						"aesApName": "aes:AP9105_2",
						"apEntityId": "03a183337efa45eac3e6",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 664,
							"key": 0
						}],
						"apModel": "C9105AXI-B",
						"channelChangeCountBinSize": 0.0526315789473684,
						"channelChangeCountKde": [0.083822086617284, 0.0833589823476091, 0.0819849645572029, 0.0797450794734102, 0.0767116722166308, 0.0729805072186311, 0.0686657515323493, 0.0638941873315881, 0.0587990618058376, 0.0535139877826142, 0.0481672782345456, 0.0428770374651486, 0.0377472491444952, 0.0328650061081307, 0.0282989290177146, 0.0240987299537028, 0.0202958003987504, 0.0169046461281695, 0.0139249568323512, 0.011344085833834],
						"clientCountBinSize": 0.263157894736842,
						"clientCountKde": [0.122463074364885, 0.124175381453238, 0.12095862556772, 0.11337330286853, 0.102440377973568, 0.0894212583327013, 0.0755849267561783, 0.0620196889924214, 0.049523025775554, 0.0385738640315779, 0.0293686644346769, 0.0218922265779497, 0.0159957289499046, 0.0114820752229214, 0.00808026272412257, 0.00558229064296766, 0.00378993476017953, 0.00253042604378241, 0.0016650720701031],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.357105867198558, 0.492706491511659, 0.131188987143842, 0.0178313817218801],
						"endTimestamp": "2022-02-23T00:00:00",
						"errorFlags": ["rssiKde", "snrKde", "packetRetryKde"],
						"frequencyBand": "2.4 GHz",
						"impactedClients": 65,
						"insightUid": "a8171d413e7306e24130",
						"interferenceBinSize": 1,
						"interferenceKde": [0.0145118776407979, 0.112995600519408, 0.190811702890781, 0.144383057906702, 0.0811478962488708, 0.047270572149569, 0.0365018428161449, 0.0273498512624868, 0.0271159552302712, 0.0138171649343402, 0.0102311456085144, 0.0113127317066139, 0.011672418854425, 0.0146361694070069, 0.0189674094538284, 0.0242019470512428, 0.0267150085520973, 0.0371275584325525, 0.036212637078579, 0.0315641617961201, 0.0289466360760649, 0.0178688488051394, 0.0131270896773146, 0.00701927707905626, 0.00450124036445503, 0.00399028519998333, 0.00199321195184838, 0.00152958838882724, 0.00144889902499678],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 664,
							"key": 1
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 664,
							"key": 0
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.106506978919201, 0.786986042161599, 0.106506978919201],
						"packetFailureKde": [0.351113856048721, 0.318505643307204, 0.166941275490866, 0.0742357919529922, 0.0365490373011728, 0.021372343851928, 0.0142539598277113, 0.0109631598102663, 0.00501945391066814],
						"packetRetryKde": [0, 0, 0, 0, 0, 0, 0.0000163627091505275, 0.000275466814867884, 0.00178382464367106, 0.00466564274592351, 0.00518276186848971, 0.00285584462105211, 0.00310685468510085, 0.00689412764360493, 0.00818515053952228, 0.00712424359191516, 0.0112824913930348, 0.0139377089207015, 0.0109914365298221, 0.0131324218851702, 0.0201621344919963, 0.0300391391408525, 0.039813072442784, 0.0395927262854732, 0.0387452959541567, 0.039054492702538, 0.0410029708210781, 0.0449082343323512, 0.0407960158349917, 0.0359730687984295, 0.0433250811849813, 0.0554039455069536, 0.0612298419457095, 0.0636711613223477, 0.0635534500558591, 0.0578606873837277, 0.0470647186374111, 0.0371659719170647, 0.0291018523279873, 0.0204739441348752, 0.012026285906981, 0.00601048531329525, 0.00385934113771107, 0.00213136680153392, 0.00051575267333025, 0.0000462893130431704, 0.0000018437446782187, 0.0000168941800535313, 0.000309388147412161, 0.00211392549774457, 0.00553414153461762, 0.00632972090912485, 0.00464681099653934, 0.00252701662648091, 0.000672431237247671, 0.0000720375690094437, 0.000082120306300345, 0.000724885648134273, 0.00238104083776291, 0.00281950493177809, 0.00120383536378432, 0.000198257051843031, 0.000229127056413526, 0.0013228127545975, 0.00288134914291119, 0.00226280946026524],
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00971350126887553, 0.20741908066705, 0.575448337397024, 0.20741908066705],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 664,
							"key": 4
						}],
						"primaryImageVersion": "8.10.162.0",
						"rootCauseCategory": "High client activity",
						"rootCauseCategoryId": "a71d771533202605c099",
						"rootCauseFeatures": [{
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgTraffic",
							"kpi": "trafficKde",
							"weight": 0.0327510917030568
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": null,
							"feature": "model",
							"kpi": "apModel",
							"weight": 0.0305676855895196
						}],
						"rootCauseId": "c8ab51",
						"rssiKde": [0.22038498681189, 0.146733110822464, 0.0674258580989303, 0.0458557958479304, 0.0431683805938893, 0.0441319418512982, 0.0434841143818806, 0.0358488819888895, 0.0528608019791671, 0.0851943650653269, 0.0612789883031562, 0.0337086976169609, 0.0251023944802233, 0.0205594431484324, 0.0123108429959995, 0.00610245679791627, 0.00399885313655133, 0.00486145627450523, 0.00457389407515728, 0.00348922065600203, 0, 0, 0, 0, 0, 0.0148983847557266, 0.0205448009744846, 0.00348232934321791],
						"severity": "s1",
						"siteHierarchyArray": ["Global / North America / USA / California / San Jose / SJC01 / Flr-SJC1-1"],
						"siteId": "d2351ed6-6058-46ee-88e9-b79bda792fe9",
						"snrKde": [0.00236432821110293, 0.0511086653562807, 0.157717082995504, 0.114978864735631, 0.0582516911768667, 0.0462100844736124, 0.0398570077914695, 0.0379672246567274, 0.049453009019596, 0.0606101220606863, 0.0378093438639097, 0.0263163948266801, 0.0243693340983047, 0.0231053327752103, 0.0202651492376662, 0.021323010934725, 0.0320648726399116, 0.0531038409740576, 0.0490063257205164, 0.0191430078851901, 0.0118504208282483, 0.0123999240017546, 0.0088453457986537, 0.00365722613854795, 0.00260804515833508, 0.00154697842156267, 0.0012975213958089, 0.00177088352271555, 0.00167706135574719, 0, 0, 0, 0, 0.00147109658961437, 0.0125812893087509, 0.0134913504395561],
						"speedBins": [0, 6.96, 13.92, 20.88, 27.84, 34.8, 41.76, 48.72, 55.68, 62.64, 69.6, 76.56, 83.52, 90.48, 97.44, 104.4, 111.36, 118.32, 125.28, 132.24, 139.2, 146.16, 153.12, 160.08, 167.04, 174],
						"speedDensity": [0.130962343096234, 0.155648535564854, 0.0794979079497908, 0.0912133891213389, 0.218410041841004, 0, 0.0493723849372385, 0, 0.0506276150627615, 0.00585774058577406, 0.0401673640167364, 0, 0.00209205020920502, 0, 0, 0, 0.000836820083682009, 0, 0.000836820083682007, 0, 0.164435146443514, 0, 0, 0, 0.0100418410041841],
						"startTimestamp": "2022-01-26T00:00:00",
						"sumRadioAppCount": 922,
						"timestamp": "2022-02-23T00:36:47.345289",
						"trafficBinSize": 1,
						"trafficKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00123080365955085, 0.0753705235470663, 0.378899206740937, 0.408190871757355, 0.110245437795734, 0.0172765145140255, 0.00663325626512137, 0.00215338572020971],
						"uniqueClients": 66,
						"utilizationBinSize": 1,
						"utilizationKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0226453006427796, 0.184124808150405, 0.172682849965342, 0.123109649797617, 0.0539137369801045, 0.0517006549577347, 0.0281396589901216, 0.0325859779366334, 0.0214751431049449, 0.00907400310387664, 0.0110078098692571, 0.0100684501917382, 0.0136695137185466, 0.0147991245760076, 0.0241911254075373, 0.0198111126539659, 0.0303535826366634, 0.043021512682184, 0.0313153925376054, 0.0358875193783547, 0.0179614966417814, 0.0192195245722732, 0.00972508187816572, 0.00677598519849007, 0.00365773997839159, 0.00312452541065765, 0.00213777322980455, 0.00200397380675213]
					}],
					"totalCount": 1
				}
			}
		},
	"getApDetailforHighCLientActivity2Template":
		{
			"data": {
				"getClientInsightRadios36": {
					"__typename": "GetClientInsightRadios36Connection",
					"nodes": [{
						"__typename": "ClientInsightRadios36Type",
						"aesApMac": "aes:a0:f8:49:7e:c0:78",
						"aesApName": "aes:AP9124_2",
						"apEntityId": "033211b7ad5df1278ebe",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 665,
							"key": 0
						}],
						"apModel": "C9124AXI",
						"channelChangeCountBinSize": 0.0526315789473684,
						"channelChangeCountKde": [0.083822086617284, 0.0833589823476091, 0.0819849645572029, 0.0797450794734102, 0.0767116722166308, 0.0729805072186311, 0.0686657515323493, 0.0638941873315881, 0.0587990618058376, 0.0535139877826142, 0.0481672782345456, 0.0428770374651486, 0.0377472491444952, 0.0328650061081307, 0.0282989290177146, 0.0240987299537028, 0.0202958003987504, 0.0169046461281695, 0.0139249568323512, 0.011344085833834],
						"clientCountBinSize": 0.210526315789474,
						"clientCountKde": [0.104576342254353, 0.10599203479961, 0.104595765535008, 0.100548754858645, 0.094211037225835, 0.0860895611082722, 0.0767722379178421, 0.0668596525465664, 0.0569050150704126, 0.0473698312760698, 0.0385987337270446, 0.030813020487286, 0.0241195084083648, 0.0185297145348147, 0.013984108003502, 0.0103769056535333, 0.00759556659861089, 0.00546185634763683, 0.00387825305883727, 0.002722100587755],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.355751347013665, 0.0481457137319383, 0, 0.0130785549141648, 0.122602536372539, 0.210750037293177, 0.026825831680251, 0.00305063128922838, 0.0277808713016374, 0.0528500527228397, 0.0658902641363612, 0.00819089136055653, 0.00388879403750845, 0.0284410520956886, 0.0055585632963531, 0.0126608791540953, 0.00219661663271103, 0.00426718051398367, 0.00554009805553129, 0, 0.00126589011997741, 0],
						"endTimestamp": "2022-02-23T00:00:00",
						"errorFlags": ["speedDensity"],
						"frequencyBand": "2.4 GHz",
						"impactedClients": 47,
						"insightUid": "a831bc554be40f0f8e80",
						"interferenceBinSize": 1,
						"interferenceKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00225265437076449, 0.00308873651106373, 0.00243411196066412, 0.0041401986340288, 0.00453696692881571, 0.00437763217746866, 0.00598969371124582, 0.00661188627771941, 0.00714134117723526, 0.0103150330545, 0.00937214579004575, 0.0121847902508946, 0.0135527327253503, 0.0191757203495148, 0.021730714974513, 0.0266876819196333, 0.0300227809913879, 0.0311663761067345, 0.0315533682294576, 0.0358820226811731, 0.0418278156703456, 0.0426966105654903, 0.0456482318155959, 0.0486227154742189, 0.051437465346809, 0.0576793928328116, 0.0570198690402806, 0.0562406777299324, 0.0571311707359714, 0.0515605014231681, 0.0412557244296503, 0.0418916049924552, 0.0356210879207525, 0.0309539663632887, 0.0211113636341378, 0.0147923065554448, 0.0086268739764231, 0.00435903248984018, 0.00524208776843408, 0.00243323248053169],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 665,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 665,
							"key": 1
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00284429141146714, 0.0492284710855012, 0.368395112222643, 0.45816446657824, 0.0818862549411288, 0.00749410825819058, 0.007394458985227, 0.0158416471360909, 0.00875118938151118],
						"packetFailureKde": null,
						"packetRetryKde": null,
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00134310112632249, 0.0286801996124841, 0.0796144130569813, 0.0356897783882224, 0.151024244021842, 0.415276277935144, 0.149686054053334, 0.00711429645303355, 0, 0.00136938090163687, 0.0270103108270605, 0.0749218627460717, 0.0270054090179307],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 665,
							"key": 4
						}],
						"primaryImageVersion": "8.10.162.0",
						"rootCauseCategory": "High client activity",
						"rootCauseCategoryId": "a71d771533202605c099",
						"rootCauseFeatures": [{
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgTraffic",
							"kpi": "trafficKde",
							"weight": 0.0343980343980344
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgUtilization",
							"kpi": "utilizationKde",
							"weight": 0.0343980343980344
						}, {
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": null,
							"feature": "model",
							"kpi": "apModel",
							"weight": 0.0319410319410319
						}],
						"rootCauseId": "c8ab51",
						"rssiKde": [0.0118713290972278, 0.0317338551240938, 0.0124682495611485, 0.00384656107094633, 0.00628163883208899, 0.00728334727381526, 0.00728665711581076, 0.0073504917591596, 0.0101600400592368, 0.0121676041926551, 0.0107602064756515, 0.0214562949721652, 0.0439156194108338, 0.101098921713366, 0.15017943645239, 0.0623548426399218, 0.0372240151811835, 0.062868307357285, 0.073602457072813, 0.0467242172303444, 0.0219384972418484, 0.0173884989907546, 0.0169153328596124, 0.0226999931495847, 0.0255602613247732, 0.0173070259879761, 0.028605179910331, 0.0290564703318665, 0.00885461302241838, 0.00354175013281487, 0.00215529626348161, 0.00557259998717238, 0.027468656350635, 0.0292868742121943, 0.00485767959793459, 0.00277258266087135, 0.003337506696027, 0.00203820352601333, 0.00206827779657095, 0.00240256016962419, 0.0022481730792325, 0.00195409594573415],
						"severity": "s1",
						"siteHierarchyArray": ["Global / North America / USA / California / San Jose / SJC01 / Flr-SJC1-1"],
						"siteId": null,
						"snrKde": [0.00270548712991519, 0.00661415624111321, 0.00947485455764517, 0.00905179236414814, 0.0120241340473156, 0.0130319082990256, 0.0172847957281952, 0.0239554971816338, 0.0213544971207078, 0.0438953184591984, 0.080938651875318, 0.0788330128112131, 0.0619545586221359, 0.0359690223782916, 0.0340891313556756, 0.0493377937455004, 0.0284307216959069, 0.0241684798813626, 0.0650387793830143, 0.0964243657336356, 0.0308954482117607, 0.0141820855614396, 0.0146409097936546, 0.0225690914257092, 0.0242510428486547, 0.011171872539185, 0.00953131105494409, 0.00755752665433072, 0.00684264920523448, 0.00577925453817766, 0.00597828088558988, 0.0173838969278407, 0.0215334243601669, 0.0287760208341124, 0.0292785790933756, 0.00670854434109304, 0.0115241077455787, 0.0115544400533963, 0.00265468067010876, 0.00153675668093772],
						"speedBins": [0, 4.24390243902439, 8.48780487804878, 12.7317073170732, 16.9756097560976, 21.219512195122, 25.4634146341463, 29.7073170731707, 33.9512195121951, 38.1951219512195, 42.4390243902439, 46.6829268292683, 50.9268292682927, 55.1707317073171, 59.4146341463415, 63.6585365853659, 67.9024390243902, 72.1463414634146, 76.390243902439, 80.6341463414634, 84.8780487804878, 89.1219512195122, 93.3658536585366, 97.609756097561, 101.853658536585, 106.09756097561, 110.341463414634, 114.585365853659, 118.829268292683, 123.073170731707, 127.317073170732, 131.560975609756, 135.80487804878, 140.048780487805, 144.292682926829, 148.536585365854, 152.780487804878, 157.024390243902, 161.268292682927, 165.512195121951, 169.756097560976, 174],
						"speedDensity": [0.532179316466933, 0.107412339103418, 0.0155348424323125, 0.0248557478917, 0.000887705281846427, 0.0661340434975588, 0.126498002663116, 0, 0.000443852640923214, 0, 0.0257434531735463, 0, 0.000443852640923214, 0.0474922325787838, 0, 0.00488237905015535, 0.0315135375055481, 0, 0, 0, 0.00798934753661783, 0, 0, 0, 0, 0, 0, 0.00310696848646249, 0, 0, 0.00177541056369286, 0, 0, 0.000887705281846426, 0, 0, 0, 0, 0, 0, 0.00221926320461606],
						"startTimestamp": "2022-01-26T00:00:00",
						"sumRadioAppCount": 42,
						"timestamp": "2022-02-23T00:36:47.345289",
						"trafficBinSize": 1,
						"trafficKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00309835840614914, 0.0184998659387108, 0.0573810973728184, 0.122233479215481, 0.149354657779918, 0.0906045915802117, 0.0440430109575859, 0.0454805664623229, 0.0496239465188693, 0.049403573546111, 0.0393714028801401, 0.0348475749911215, 0.0364716050733247, 0.0338245970914802, 0.0279972355146318, 0.023876000227772, 0.0217416070213849, 0.0215329509369139, 0.019051154273151, 0.015973452207398, 0.0110674693643563, 0.011951879798404, 0.00990484185366711, 0.00969661316052029, 0.00912843310328251, 0.00832525171513972, 0.00641192755318664, 0.00561799094553197, 0.00419929348511685, 0.00318035238943853, 0.00375758950017957, 0.00423173335375813, 0.00205808801478043, 0.0019318323727692, 0.00181511755550155, 0, 0.00115707762377965],
						"uniqueClients": 52,
						"utilizationBinSize": 1,
						"utilizationKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00122515881412502, 0.00122522638260883, 0, 0, 0.00242326748040303, 0.0031107328295765, 0.00207570249890973, 0.00132471594115507, 0.00254423315232235, 0.00254508130518887, 0.00548067679882977, 0.00667838554036566, 0.0123085921432877, 0.0117848858836258, 0.0138106393592577, 0.0151205347766514, 0.0215814635514131, 0.0274531719622956, 0.0340114704988426, 0.0435295738959991, 0.05082352105099, 0.0542938868918778, 0.0666211507486542, 0.0783284160680794, 0.0756650161458716, 0.0748951460058491, 0.0691574820539084, 0.0672943607958505, 0.0588097034525024, 0.0515821742263007, 0.0398469064012711, 0.0276641762924039, 0.0240870619446126, 0.0166531378361829, 0.0122952573984192, 0.00875023639389052, 0.00731826480650839, 0.00526670815153167, 0.00241388052043781]
					}],
					"totalCount": 1
				}
			}
		},
	"getApDetailforHighCLientActivity3Template":
		{
			"data": {
				"getClientInsightRadios36": {
					"__typename": "GetClientInsightRadios36Connection",
					"nodes": [{
						"__typename": "ClientInsightRadios36Type",
						"aesApMac": "aes:a0:f8:49:7e:c0:79",
						"aesApName": "aes:AP9136_1",
						"apEntityId": "0365450714d1191cfbf7",
						"apMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 666,
							"key": 0
						}],
						"apModel": "C9136I-B",
						"channelChangeCountBinSize": 0.105263157894737,
						"channelChangeCountKde": [0.139245763663119, 0.136855838002167, 0.12899684001815, 0.116753829316735, 0.101656167295644, 0.0853705295292726, 0.0694041394624681, 0.0548895463642382, 0.0424888832975688, 0.0324156168142971, 0.0245401470417998, 0.0185312365564379, 0.0139889176267061, 0.0105403703921208, 0.00788967642016514, 0.00583593333469722, 0.00422679659894441, 0.00298166502029194, 0.00203998728318137],
						"clientCountBinSize": 0.368421052631579,
						"clientCountKde": [0.10710900113435, 0.115274095165245, 0.11687715774099, 0.112475590860352, 0.103558925923608, 0.0918819049716574, 0.0790005269853521, 0.0660625268417394, 0.0538167906562185, 0.0427145380641393, 0.0330273103421306, 0.0248187436072952, 0.0181014358902623, 0.0127892080602242, 0.00874119375845046, 0.00575875535039295, 0.00366568529124524, 0.00225224555756396, 0.00132108077379945],
						"cpuUsageBinSize": 1,
						"cpuUsageKde": [0.253243559886731, 0.0938988346200811, 0.440914824488154, 0.0728914262719981, 0.0981517450588116, 0.0159098380841639, 0.0140447604739264, 0.00347610075929375, 0.00187479848132696, 0.0020324598965783, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00165112980006997],
						"endTimestamp": "2022-02-23T00:00:00",
						"errorFlags": ["speedDensity"],
						"frequencyBand": "2.4 GHz",
						"impactedClients": 42,
						"insightUid": "a83dc6b11db89c360506",
						"interferenceBinSize": 1,
						"interferenceKde": [0.00458811111683166, 0.0283117312636177, 0.0551115817061303, 0.071167113719964, 0.0620071761090195, 0.0525569064281024, 0.0473590246847158, 0.0377150127175526, 0.0325297609770426, 0.0239608132186505, 0.0265964854442933, 0.0186810864522462, 0.0243421714217018, 0.0201972089526634, 0.0196576112727613, 0.0237565460665092, 0.0268772934941436, 0.0362781786545839, 0.0411064066418069, 0.0590954421723933, 0.0525674242689101, 0.0493387090225835, 0.0268737021834108, 0.027442709906044, 0.0213484371330787, 0.0135233177103723, 0.0140494497043569, 0.0117757922464911, 0.0077990686571591, 0.00882464848036551, 0.00716494059729219, 0.00512521914992466, 0.00826414471288528, 0.00540614572873893, 0.00616356560460057, 0.00642167196227637, 0.00436309677131184, 0.00432641521486025, 0.00331389505735245, 0.00248069971820745],
						"legacyBeamFormingStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 666,
							"key": 0
						}],
						"legacyBeamGlobalStatus": [{
							"__typename": "CiIntKeyCountValue",
							"count": 666,
							"key": 1
						}],
						"memoryUsageBinSize": 1,
						"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0268118926400205, 0.276191377500222, 0.616994006643395, 0.0800027232163624],
						"packetFailureKde": null,
						"packetRetryKde": null,
						"powerBinSize": 1,
						"powerKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0012274722217744, 0.0100150967281194, 0.205102387617932, 0.568967793962278, 0.205083141000099, 0.00960410846979801],
						"powerMode": [{
							"__typename": "CiIntKeyCountValue",
							"count": 666,
							"key": 4
						}],
						"primaryImageVersion": "8.10.162.0",
						"rootCauseCategory": "High client activity",
						"rootCauseCategoryId": "a71d771533202605c099",
						"rootCauseFeatures": [{
							"__typename": "CiClientExperienceRootCauseFeature",
							"anomaly": "high",
							"feature": "avgTraffic",
							"kpi": "trafficKde",
							"weight": 0.0292887029288703
						}],
						"rootCauseId": "c8ab51",
						"rssiKde": [0.0333061256813188, 0.0133767606840088, 0.0200441350965954, 0.0293753341043501, 0.0147027732451861, 0.0255657566269816, 0.0605496573835975, 0.0523537451898532, 0.0423800564267184, 0.0437175230771704, 0.0522611934056485, 0.0590673090852721, 0.0414741338579476, 0.0218061036634208, 0.0206453855748473, 0.0189877166575755, 0.0120298966208861, 0.0123701224440539, 0.0214505901140834, 0.0181554392600633, 0.0262802125316889, 0.0329292464271172, 0.0188456940857432, 0.0188755892555931, 0.0232804565764499, 0.0237654387945593, 0.0290636335980209, 0.0178652787941115, 0.0195092899679039, 0.0247526254411679, 0.0175977975752806, 0.0212004777916101, 0.034491410278783, 0.0236211732782752, 0.00935161228800408, 0.00668970460383427, 0.00484177391311265, 0.00304123696109808, 0.002600952408173, 0.00401721676759354, 0.00816405065531372, 0.00705292640510185, 0.00311785968443949, 0.00221415441346096, 0.00179567100721863],
						"severity": "s2",
						"siteHierarchyArray": ["Global / North America / USA / California / San Jose / SJC01 / Flr-SJC1-1"],
						"siteId": "3a1670ea-43e2-4856-a8cf-477d616390b0",
						"snrKde": [0.00831946811166494, 0.00644523562244304, 0.00993403333248647, 0.0197654740618924, 0.0380203043598746, 0.031724472695147, 0.0226613198995549, 0.0135652806112996, 0.00782701860760353, 0.0234518362299932, 0.0444759491841719, 0.0425226673930881, 0.0411665178251791, 0.0476951132524062, 0.0648663289434087, 0.0415831680353641, 0.0455884954397779, 0.0325444770443659, 0.018220701656519, 0.021853711949472, 0.0247274428435486, 0.0264689609440911, 0.0143154311602063, 0.0125432991528211, 0.0172640518651245, 0.02384209303613, 0.0280568197631093, 0.0245597665344045, 0.0200641306652383, 0.0164325583379395, 0.0177027922803154, 0.0105974613289692, 0.0116264763312523, 0.0224572324009845, 0.0260603709000044, 0.0162229091844935, 0.0155733001921236, 0.0247657692350843, 0.0196278885025884, 0.00700988793856499, 0.00409963972556323, 0.00280058940444452, 0.00320181825759238, 0.00285440375819307, 0.00336151627098329, 0.00376303743657158, 0.0032778321419079, 0.00638554157550943, 0.00498345965031094, 0.00195486467822932],
						"speedBins": [0, 3.2, 6.4, 9.6, 12.8, 16, 19.2, 22.4, 25.6, 28.8, 32, 35.2, 38.4, 41.6, 44.8, 48, 51.2, 54.4, 57.6, 60.8, 64, 67.2, 70.4, 73.6, 76.8, 80, 83.2, 86.4, 89.6, 92.8, 96, 99.2, 102.4, 105.6, 108.8, 112, 115.2, 118.4, 121.6, 124.8, 128, 131.2, 134.4, 137.6, 140.8, 144],
						"speedDensity": [0.651188811188811, 0.0027972027972028, 0.0682517482517482, 0.00923076923076923, 0.012027972027972, 0.000559440559440559, 0.0041958041958042, 0, 0.0495104895104895, 0.00111888111888112, 0, 0, 0, 0.0620979020979021, 0, 0, 0, 0, 0.00503496503496503, 0, 0.0358041958041958, 0.000279720279720279, 0.081118881118881, 0, 0, 0, 0.0114685314685315, 0.000559440559440559, 0, 0, 0, 0, 0, 0, 0, 0.000559440559440559, 0, 0, 0, 0, 0.000559440559440557, 0, 0, 0, 0.00363636363636365],
						"startTimestamp": "2022-01-26T00:00:00",
						"sumRadioAppCount": 1107,
						"timestamp": "2022-02-23T00:36:47.345289",
						"trafficBinSize": 1,
						"trafficKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.0135385651627158, 0.0792479844568171, 0.197687724662713, 0.369298504724606, 0.140373672178611, 0.0598702140181951, 0.0719429430265408, 0.0286491203157885, 0.0135651672078566, 0.0120947587530069, 0.00744609396586293, 0.00355731019166758, 0.00159718107939771],
						"uniqueClients": 48,
						"utilizationBinSize": 1,
						"utilizationKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00253253504822144, 0.00961419992714624, 0.0335864472588179, 0.0433563890288486, 0.0600278139941854, 0.051625625184666, 0.0401414762941506, 0.0445458102121331, 0.0349626480565585, 0.0375523081431866, 0.0246990957156914, 0.0297387489139506, 0.0213673711106585, 0.0281156161720941, 0.0275422302399999, 0.0224139547197552, 0.0312197706845047, 0.0256789863004611, 0.0355545616032104, 0.0423903516532726, 0.0585190280915661, 0.044233098888179, 0.0477059221338683, 0.0274470922131671, 0.027138770625615, 0.022715046106592, 0.0161183982527521, 0.0178412471764547, 0.0126029041255781, 0.0101206740883927, 0.00728383433759694, 0.00640131668804099, 0.00673900651049346, 0.007107073784239, 0.00764442719632228, 0.00607390655166603, 0.00757534725763549, 0.00485974646592542, 0.00390322413585016, 0.0027191099303754, 0.00271517488971972, 0.00115245351670667, 0.00201497151774382, 0.00155614696434692]
					}],
					"totalCount": 1
				}
			}
		},
		"getApDetailforHighAPDensity1Template":
		{
			"data": {
			"getClientInsightRadios36": {
			"__typename": "GetClientInsightRadios36Connection",
			"nodes": [{
			"__typename": "ClientInsightRadios36Type",
			"aesApMac": "aes:a0:f8:49:7e:c0:71",
			"aesApName": "aes:SCJ01_9136_2",
			"apEntityId": "03f7420294ec97582626",
			"apMode": [{
			"__typename": "CiIntKeyCountValue",
			"count": 478,
			"key": 0
			}],
			"apModel": "C9136I-B",
			"channelChangeCountBinSize": 0.0526315789473684,
			"channelChangeCountKde": [0.083822086617284, 0.083358982347609, 0.0819849645572029, 0.0797450794734103, 0.0767116722166308, 0.0729805072186312, 0.0686657515323493, 0.0638941873315881, 0.0587990618058377, 0.0535139877826142, 0.0481672782345456, 0.0428770374651486, 0.0377472491444952, 0.0328650061081307, 0.0282989290177146, 0.0240987299537028, 0.0202958003987504, 0.0169046461281695, 0.0139249568323512, 0.011344085833834],
			"clientCountBinSize": 0.631578947368421,
			"clientCountKde": [0.150832835153872, 0.171424070092977, 0.166333189916273, 0.143060369788402, 0.112910685455929, 0.0835327730538734, 0.0581558845760614, 0.0379420546644576, 0.0233893614594881, 0.0140826933878646, 0.00875363377608146, 0.00596651690868153, 0.00450561799029749, 0.00383800119807671, 0.00355808516560193, 0.00336815661345165, 0.00305008798222163, 0.00247913241768074, 0.00175751995888782],
			"cpuUsageBinSize": 1,
			"cpuUsageKde": [0.501019319567883, 0.0678055927049301, 0, 0.0158243526674587, 0.134710538992171, 0.149370047087448, 0.0181487706738228, 0.00191237253337704, 0.0178085852030896, 0.0378902776680156, 0.03329527405519, 0.00393152674575194, 0.00151112005867025, 0.0111086624348541, 0.00185931319974712, 0.00263481032376453, 0],
			"endTimestamp": "2021-11-24T00:00:00",
			"errorFlags": ["speedDensity"],
			"frequencyBand": "2.4 GHz",
			"impactedClients": 107,
			"insightUid": "a84ad96b2394ceabb887",
			"interferenceBinSize": 1,
			"interferenceKde": [0, 0, 0, 0, 0, 0, 0, 0, 0.00285705222301608, 0.00381187392331153, 0.00764590272459025, 0.0201157438526245, 0.0416695939705002, 0.0602971149027617, 0.0702449320536316, 0.0784891163769649, 0.0914757780166661, 0.0868513160661196, 0.0768534514428121, 0.0533637518132693, 0.0425085208587098, 0.0427587176966239, 0.034718371955134, 0.0301548490899854, 0.0266372179856778, 0.0251146866286992, 0.0207809004317066, 0.0190092223198196, 0.0184711118564576, 0.0196027379262336, 0.0198277370517032, 0.0138613911254284, 0.00921625135645346, 0.0102573177598083, 0.00995193365691556, 0.00797528398669465, 0.00760186514009289, 0.00673580059555831, 0.00605723018408548, 0.00380502750751377, 0.00328277767749013, 0.00178055584690044, 0.00280511510336205, 0.00390732788060361, 0.00280483754460262, 0.00166844689463059, 0.00234197381954158, 0.00226704935144983, 0, 0.00105249640487587, 0.00143951088179934, 0.00191671062068397, 0.00123865066025572, 0.00164092614105804, 0.00136486414047444, 0],
			"legacyBeamFormingStatus": [{
			"__typename": "CiIntKeyCountValue",
			"count": 478,
			"key": 0
			}],
			"legacyBeamGlobalStatus": [{
			"__typename": "CiIntKeyCountValue",
			"count": 478,
			"key": 1
			}],
			"memoryUsageBinSize": 1,
			"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.023989471772107, 0.249448010018301, 0.575787461328983, 0.119667195719649, 0.0165727160531487, 0.0129434970637984],
			"packetFailureKde": null,
			"packetRetryKde": null,
			"powerBinSize": 1,
			"powerKde": [0, 0, 0, 0, 0.00962005683460593, 0.205423697322451, 0.569912491685885, 0.205423697322451, 0.00962005683460593],
			"powerMode": [{
			"__typename": "CiIntKeyCountValue",
			"count": 478,
			"key": 4
			}],
			"primaryImageVersion": "8.5.161.6",
			"rootCauseCategory": "High density",
			"rootCauseCategoryId": "a7960cbabf5293d7cbe6",
			"rootCauseFeatures": [{
			"__typename": "CiClientExperienceRootCauseFeature",
			"anomaly": "low",
			"feature": "avgPower",
			"kpi": "powerKde",
			"weight": 0.0666666666666667
			}, {
			"__typename": "CiClientExperienceRootCauseFeature",
			"anomaly": null,
			"feature": "model",
			"kpi": "apModel",
			"weight": 0.0451612903225806
			}],
			"rootCauseId": "c8398b",
			"rssiKde": [0.00122066205910327, 0.0118441410139647, 0.0339412102357654, 0.0219889123734039, 0.0216927765147046, 0.0414078191169869, 0.0432338766206927, 0.040695752195212, 0.0503479148988892, 0.0854121102489312, 0.0708358426038999, 0.0635888182591675, 0.0536622341506681, 0.0578179901409524, 0.0493813838327622, 0.0338942274364838, 0.0292613159881676, 0.0365352232257839, 0.0428529359491771, 0.0522350385692756, 0.0499815670341961, 0.0331960762740817, 0.0323359328059958, 0.0157222933201351, 0.0100878580448765, 0.00990488817618717, 0.00384600063286931, 0.00170720105132588],
			"severity": "s2",
			"siteHierarchyArray": ["Global / North America / USA / California / San Jose / SJC01 / Flr-SJC1-1"],
			"siteId": "ab2cd664-b803-4a2b-b7cd-5b7674eeda5c",
			"snrKde": [0.00394942735700046, 0.0195736015572326, 0.0425593730437334, 0.0426876121477262, 0.0600690472441739, 0.0568038888286957, 0.0426568797270808, 0.0406947380185006, 0.023373813318238, 0.0266812837235878, 0.041598159196951, 0.0538224513248655, 0.0692490468911928, 0.0642575748522952, 0.0444900084413058, 0.0566995909556743, 0.0561553373347951, 0.0233831443808065, 0.0168752585168779, 0.0197329936476105, 0.0294700179242428, 0.0339080893177991, 0.0370626135886586, 0.0294747338115237, 0.0207920453282967, 0.013595125911671, 0.00912170907520343, 0.00819578638609705, 0.00789313966990522, 0.00358411806116121],
			"speedBins": [0, 6.08333333333333, 12.1666666666667, 18.25, 24.3333333333333, 30.4166666666667, 36.5, 42.5833333333333, 48.6666666666667, 54.75, 60.8333333333333, 66.9166666666667, 73],
			"speedDensity": [0.0492866407263294, 0.749675745784695, 0.0526588845654993, 0.0443579766536965, 0.0640726329442283, 0, 0, 0.0132295719844358, 0, 0.0178988326848249, 0.00570687418936447, 0.00311284046692607],
			"startTimestamp": "2021-10-27T00:00:00",
			"sumRadioAppCount": 16,
			"timestamp": "2021-11-24T00:57:49.316824",
			"trafficBinSize": 1,
			"trafficKde": [0.793550165933278, 0.200900244503535, 0.00554958956318628],
			"uniqueClients": 134,
			"utilizationBinSize": 1,
			"utilizationKde": [0, 0, 0, 0, 0, 0, 0, 0, 0.00281573034536825, 0.0035270106192995, 0.00756486214224479, 0.0198119190682402, 0.0415734215879454, 0.0601372586035044, 0.0695203678604463, 0.0777528620855842, 0.0912345328973408, 0.0863413273085739, 0.0758813935179227, 0.0532509049549965, 0.0429977380925985, 0.0426237305144943, 0.0337166500547798, 0.0310645819839532, 0.0267475125023928, 0.025116252538397, 0.021059755506571, 0.0193304468724664, 0.018720960245791, 0.0192989937048365, 0.0194825235925697, 0.0137689501972822, 0.00900159128731072, 0.0107191114074957, 0.00977248478411077, 0.00824306378165135, 0.00782930137856049, 0.00628745861693124, 0.00656169562271283, 0.00414938158102311, 0.00331567036415564, 0.00181567104424207, 0.00311380137638814, 0.00421445122826916, 0.00283813078877713, 0.00162871824150552, 0.00210062234495991, 0.00253922814056497, 0.00123707906630719, 0.00108836681161634, 0.00143756207085911, 0.00191399276529476, 0.00123689428112783, 0.00163869172287703, 0.00140019923144409, 0.00105072681120927, 0]
			}],
			"totalCount": 1
			}
			}
			},
			"getApDetailforHighAPDensity2Template":
			{
				"data": {
				"getClientInsightRadios36": {
				"__typename": "GetClientInsightRadios36Connection",
				"nodes": [{
				"__typename": "ClientInsightRadios36Type",
				"aesApMac": "aes:a0:f8:49:7e:c0:72",
				"aesApName": "aes:SCJ01_9136_3",
				"apEntityId": "0369d756f41454ce2635",
				"apMode": [{
				"__typename": "CiIntKeyCountValue",
				"count": 478,
				"key": 0
				}],
				"apModel": "C9136I-B",
				"channelChangeCountBinSize": 0.0526315789473684,
				"channelChangeCountKde": [0.083822086617284, 0.083358982347609, 0.0819849645572029, 0.0797450794734103, 0.0767116722166308, 0.0729805072186312, 0.0686657515323493, 0.0638941873315881, 0.0587990618058377, 0.0535139877826142, 0.0481672782345456, 0.0428770374651486, 0.0377472491444952, 0.0328650061081307, 0.0282989290177146, 0.0240987299537028, 0.0202958003987504, 0.0169046461281695, 0.0139249568323512, 0.011344085833834],
				"clientCountBinSize": 0.368421052631579,
				"clientCountKde": [0.0793371885367201, 0.0905721765247812, 0.0978423096215821, 0.100617460004041, 0.0989958487061379, 0.0936083532448207, 0.0853783293642583, 0.0753173963509134, 0.0643837586674459, 0.0534003882322455, 0.0430344510876367, 0.0336998087536258, 0.0256712723227301, 0.019045372455112, 0.013748789027243, 0.00966626692785063, 0.00664217828407983, 0.0044287945549214, 0.00283565203902096],
				"cpuUsageBinSize": 1,
				"cpuUsageKde": [0, 0, 0, 0, 0.00280296601800896, 0.0174538470437716, 0.0730833428413005, 0.170358089624904, 0.227565629481331, 0.182665379492292, 0.124977206183579, 0.0765596947174009, 0.047694037343041, 0.0242416892974831, 0.0142324007096699, 0.00915298083691828, 0.00558155806216811, 0.00557126868157919, 0.00431386587927309, 0.00296762416126032, 0.00421191478549792, 0.00233023739387325, 0.00116621926128484, 0, 0.00116516654000435],
				"endTimestamp": "2021-11-24T00:00:00",
				"errorFlags": ["packetFailureKde"],
				"frequencyBand": "2.4 GHz",
				"impactedClients": 25,
				"insightUid": "a8c2c490f299011e154d",
				"interferenceBinSize": 1,
				"interferenceKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00156310786107232, 0.00403838998092815, 0.00899137854132218, 0.00990704165849558, 0.0176972946313336, 0.0183666011726563, 0.0321371564806068, 0.0246117869625808, 0.0371403251737837, 0.046881710713854, 0.0402577363806947, 0.0537228397762281, 0.039910290630668, 0.0528711799560483, 0.0434496588831133, 0.0569488465244931, 0.0402121562425081, 0.0478175430238226, 0.054694864473197, 0.0438998077352703, 0.0514168725166933, 0.0370420217739794, 0.0409897436078796, 0.0280390574272865, 0.0299992702562454, 0.0227174345279929, 0.0218941710114012, 0.0107084640077603, 0.0167107891991139, 0.017108871956268, 0.0100916580860459, 0.00720217585548804, 0.00523907899635723, 0.00672822859291917, 0.00517489858309312, 0.00382616249205064, 0.00175511777531034, 0.00192342850011212, 0.00298278999948336, 0.00216831436686933],
				"legacyBeamFormingStatus": [{
				"__typename": "CiIntKeyCountValue",
				"count": 478,
				"key": 0
				}],
				"legacyBeamGlobalStatus": [{
				"__typename": "CiIntKeyCountValue",
				"count": 478,
				"key": 1
				}],
				"memoryUsageBinSize": 1,
				"memoryUsageKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.106506978919201, 0.786986042161599, 0.106506978919201],
				"packetFailureKde": [0.0647706176813716, 0.0587957482016592, 0.0428722157027489, 0.0380509946911466, 0.0361760374162795, 0.0356830586435118, 0.0348544627167029, 0.0310579173190448, 0.0264195787687816, 0.0235585078756654, 0.022953178205462, 0.0237714380183887, 0.0246537491441499, 0.0257879638491639, 0.0268506315887805, 0.0260171567774667, 0.0228793736070509, 0.0176917104140256, 0.0115333106289226, 0.00764866792892048, 0.00920553282004494, 0.0122577256584352, 0.0118389941749935, 0.00924657483305555, 0.00738485079895056, 0.00623304849939058, 0.006037529288992, 0.00659996874369963, 0.00588837242025697, 0.00454703654249075, 0.00377207899461967, 0.0053675468337469, 0.00753982230931504, 0.00770940773528859, 0.0071039540182588, 0.0076348428887805, 0.00844579052264606, 0.00649899859546651, 0.00396745148895176, 0.00382631378698226, 0.00584048914195093, 0.00849881836207901, 0.00975197712359463, 0.00820723316112082, 0.00771688776570024, 0.00781018844744849, 0.00703911036589002, 0.00669520161251551, 0.00619626546347288, 0.00631893995565354, 0.00653562961022401, 0.00746573956731024, 0.00787506609878707, 0.00555831587930192, 0.00402807001945966, 0.00530838443664315, 0.0068375709031365, 0.00683429160360019, 0.00645170831042249, 0.00639717067996148, 0.00651455023459191, 0.00732584615267403, 0.00769360140228349, 0.00646084823067902, 0.0055958318077744, 0.00624113208258827, 0.00790017100905755, 0.00777544176970125, 0.00621019633796838, 0.00506496576949655, 0.00386811313477261, 0.00310349917272886, 0.00308886234102888, 0.00398691140833333, 0.00490278209479384, 0.00424568029812265, 0.00296943435558546, 0.00205169518247351, 0.00159225743964946, 0.00210129209376941, 0.00248472502707466, 0.00204422140189515, 0.00151626576692405, 0.0011834619789007, 0.00182562207806144, 0.00276495251095462, 0.00191769255458695, 0.00101079552134209, 0.000907961217660366, 0.000896728700442551, 0.000827923107178406, 0.00102593010098538, 0.0013420006359709],
				"packetRetryKde": [0.00269590728237789, 0.00199602648897236, 0.00175246596508682, 0.00197050120351623, 0.00139544871015905, 0.00128087673930873, 0.00127121049226232, 0.0011018606143471, 0.00185059991020866, 0.00341545342514893, 0.00432739433742198, 0.00445874565665802, 0.00607202596826932, 0.00968476041873072, 0.0125628642899721, 0.013532199163943, 0.0134999046751065, 0.0129472990125775, 0.0135562815827031, 0.017271150937294, 0.0267863007057668, 0.0413428576176963, 0.0559587110507818, 0.0675233726218185, 0.0728100479980367, 0.0747057441300527, 0.083326124229734, 0.0896987496424811, 0.0838914238374998, 0.0696459924400133, 0.0556372972999785, 0.0503722461394319, 0.041832588815947, 0.0253645263932864, 0.0127551391954789, 0.00856938096754834, 0.00712937514453004, 0.00395726990202763, 0.00149806520666285],
				"powerBinSize": 1,
				"powerKde": [0, 0, 0, 0.00962005683460593, 0.205423697322451, 0.569912491685885, 0.205423697322451, 0.00962005683460593],
				"powerMode": [{
				"__typename": "CiIntKeyCountValue",
				"count": 478,
				"key": 4
				}],
				"primaryImageVersion": "8.5.161.6",
				"rootCauseCategory": "High density",
				"rootCauseCategoryId": "a7960cbabf5293d7cbe6",
				"rootCauseFeatures": [{
				"__typename": "CiClientExperienceRootCauseFeature",
				"anomaly": "low",
				"feature": "avgPower",
				"kpi": "powerKde",
				"weight": 0.0720720720720721
				}, {
				"__typename": "CiClientExperienceRootCauseFeature",
				"anomaly": "high",
				"feature": "avgUtilization",
				"kpi": "utilizationKde",
				"weight": 0.033033033033033
				}, {
				"__typename": "CiClientExperienceRootCauseFeature",
				"anomaly": "high",
				"feature": "avgInterference",
				"kpi": "interferenceKde",
				"weight": 0.042042042042042
				}, {
				"__typename": "CiClientExperienceRootCauseFeature",
				"anomaly": null,
				"feature": "model",
				"kpi": "apModel",
				"weight": 0.045045045045045
				}],
				"rootCauseId": "c8398b",
				"rssiKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00110670690382288, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00111296371213911, 0.00116829306596597, 0, 0, 0.00134624653669099, 0, 0.00105460014422295, 0.00162830561030211, 0.00299371304547341, 0.00491692171302011, 0.00717264021473471, 0.0112531737167607, 0.0205513338887348, 0.033796198156654, 0.0446449424653156, 0.0564092543395727, 0.0750474600548751, 0.0908963304033101, 0.0971969234357312, 0.0991599486242149, 0.0931147081636737, 0.079638868439136, 0.0684001218235292, 0.055542185433157, 0.0421162592053567, 0.0366533847996257, 0.0285108377777625, 0.0174805357357241, 0.0106508578566287, 0.00775925404331431, 0.00573701110782515, 0.0029400195827255],
				"severity": "s2",
				"siteHierarchyArray": ["Global / North America / USA / California / San Jose / SJC01 / Flr-SJC1-1"],
				"siteId": "a42456d0-5d2e-4a85-8cfc-3853cf9557f3",
				"snrKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00104305386378031, 0, 0, 0, 0.00125208457663847, 0, 0, 0, 0, 0.00139471190273253, 0.00160585159759279, 0.00121485927377252, 0, 0, 0.0011752197800193, 0.00156961845444969, 0.00255053739674401, 0.0028311494707464, 0.00323961187255719, 0.00505260499573992, 0.00785668334934901, 0.0109317898398226, 0.0145091614843123, 0.0216870479775569, 0.0333003176275481, 0.0481420821481232, 0.0623878694944561, 0.0733446376304662, 0.0852683276519818, 0.0963737268364905, 0.0998957492982388, 0.0927087463412762, 0.0784817069262298, 0.0613731450948421, 0.0541687929920104, 0.0457677442579292, 0.0346069418596689, 0.0230481812463171, 0.0142241258895993, 0.00812043714497562, 0.00507374365882139, 0.00371345157231709, 0.00208628649289409],
				"speedBins": [0, 10.3571428571429, 20.7142857142857, 31.0714285714286, 41.4285714285714, 51.7857142857143, 62.1428571428571, 72.5, 82.8571428571429, 93.2142857142857, 103.571428571429, 113.928571428571, 124.285714285714, 134.642857142857, 145],
				"speedDensity": [0.0117534180858719, 0.000239865675221876, 0.000959462700887503, 0.000719597025665627, 0.000719597025665627, 0.00263852242744063, 0.970976253298153, 0, 0, 0, 0, 0, 0, 0.0119932837610938],
				"startTimestamp": "2021-10-27T00:00:00",
				"sumRadioAppCount": 2795,
				"timestamp": "2021-11-24T00:57:49.316824",
				"trafficBinSize": 0.0526315789473684,
				"trafficKde": [0.0737749761030002, 0.0734947909326308, 0.0726549443267369, 0.0712746967993061, 0.0693853362575984, 0.0670289998647447, 0.0642571129316665, 0.0611285255490226, 0.0577074411198941, 0.0540612383097354, 0.0502582889263826, 0.04636586909895, 0.0424482505018522, 0.0385650433005433, 0.0347698442701134, 0.0311092235916074, 0.0276220636107476, 0.0243392436972933, 0.0212836484166324, 0.0184704623915418],
				"uniqueClients": 25,
				"utilizationBinSize": 1,
				"utilizationKde": [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.00156310786107232, 0.00403838998092815, 0.00899137854132218, 0.00990704165849558, 0.0176972946313336, 0.0183666011726563, 0.0321371564806068, 0.0246117869625808, 0.0371403251737837, 0.0468433278416899, 0.0400118966815107, 0.0539686794754118, 0.0399486735028321, 0.0528711799560483, 0.0434496588831133, 0.0569488465244931, 0.0402121562425081, 0.0478175430238225, 0.0546564816010331, 0.0436539680360867, 0.0516627122158772, 0.0370804046461435, 0.0409513607357154, 0.0277932177281026, 0.0302451099554292, 0.022755817400157, 0.0218941710114012, 0.0107084640077603, 0.0167107891991139, 0.017108871956268, 0.0100916580860459, 0.00720217585548804, 0.00523907899635723, 0.00672822859291917, 0.00517489858309312, 0.00382616249205064, 0.00175511777531034, 0.00192342850011212, 0.00298278999948336, 0.00216831436686933]
				}],
				"totalCount": 1
				}
				}
				},
				"getsmartDashboardHistogramAggregatedWeekday1":
				{
						"data": {
							"data": {
								"__typename": "SmartDashboard36AggregatedHistogramConnection",
								"nodes": [{
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.00107689363893791,
									"binLowerBound": -95,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0161238314589535,
									"binLowerBound": -90,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0368914487698312,
									"binLowerBound": -85,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0447525563917039,
									"binLowerBound": -80,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0574267267264629,
									"binLowerBound": -75,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0856606065184438,
									"binLowerBound": -70,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.11611173868601,
									"binLowerBound": -65,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.127170074456931,
									"binLowerBound": -60,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.121507553005435,
									"binLowerBound": -55,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.119086802392671,
									"binLowerBound": -50,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.111001334304269,
									"binLowerBound": -45,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0831508071267132,
									"binLowerBound": -40,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0503780382360011,
									"binLowerBound": -35,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0182748737066516,
									"binLowerBound": -30,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.00621266209952206,
									"binLowerBound": -25,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.00135564230338923,
									"binLowerBound": -20,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.000773366502081977,
									"binLowerBound": -15,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.00304504367599216,
									"binLowerBound": -10,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.000950267069953602,
									"binLowerBound": -95,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0117563675002073,
									"binLowerBound": -90,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0280657125362454,
									"binLowerBound": -85,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0382166228902931,
									"binLowerBound": -80,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0546528356885559,
									"binLowerBound": -75,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0842538692975006,
									"binLowerBound": -70,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.1159339816452,
									"binLowerBound": -65,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.12987075059003,
									"binLowerBound": -60,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.128538751918192,
									"binLowerBound": -55,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.124229761263395,
									"binLowerBound": -50,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.116513348939287,
									"binLowerBound": -45,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0853741960359218,
									"binLowerBound": -40,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0504816800198259,
									"binLowerBound": -35,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0189769981209941,
									"binLowerBound": -30,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.00683194859720858,
									"binLowerBound": -25,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.00132229516103066,
									"binLowerBound": -20,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.000739949114789328,
									"binLowerBound": -15,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.00329066361136961,
									"binLowerBound": -10,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0010657618031584,
									"binLowerBound": -95,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0148364076107883,
									"binLowerBound": -90,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0330762912431134,
									"binLowerBound": -85,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.039612093417247,
									"binLowerBound": -80,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0516719407031018,
									"binLowerBound": -75,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0775790130308576,
									"binLowerBound": -70,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.106694530041532,
									"binLowerBound": -65,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.12497792460242,
									"binLowerBound": -60,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.122937364431464,
									"binLowerBound": -55,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.123077162485471,
									"binLowerBound": -50,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.118167286927751,
									"binLowerBound": -45,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0939271950572404,
									"binLowerBound": -40,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0568770251137913,
									"binLowerBound": -35,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.022338101209696,
									"binLowerBound": -30,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.00756521955460985,
									"binLowerBound": -25,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0014995913555968,
									"binLowerBound": -20,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.000702931848269509,
									"binLowerBound": -15,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0033941595638915,
									"binLowerBound": -10,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.000931000874346846,
									"binLowerBound": -95,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0116991593228583,
									"binLowerBound": -90,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0277084300938208,
									"binLowerBound": -85,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0339437540239856,
									"binLowerBound": -80,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0476715476041621,
									"binLowerBound": -75,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0771952116998662,
									"binLowerBound": -70,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.107135277782231,
									"binLowerBound": -65,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.125670877693327,
									"binLowerBound": -60,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.124428791905092,
									"binLowerBound": -55,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.1272507874577,
									"binLowerBound": -50,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.122831875687333,
									"binLowerBound": -45,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0969402163231115,
									"binLowerBound": -40,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0589508729151279,
									"binLowerBound": -35,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0235871312577025,
									"binLowerBound": -30,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.00793110296429142,
									"binLowerBound": -25,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.00156454248721737,
									"binLowerBound": -20,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.000664890858268064,
									"binLowerBound": -15,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.00389452904955798,
									"binLowerBound": -10,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.00115904229868886,
									"binLowerBound": -95,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0142812877962052,
									"binLowerBound": -90,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0348826842526258,
									"binLowerBound": -85,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0446498343739869,
									"binLowerBound": -80,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0584809059426081,
									"binLowerBound": -75,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0861206778590117,
									"binLowerBound": -70,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.118941646562262,
									"binLowerBound": -65,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.129722554617095,
									"binLowerBound": -60,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.123905045371517,
									"binLowerBound": -55,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.120218312258529,
									"binLowerBound": -50,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.111197053170221,
									"binLowerBound": -45,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0807814866208895,
									"binLowerBound": -40,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0480349049820048,
									"binLowerBound": -35,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0168102643128518,
									"binLowerBound": -30,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.00563809866663483,
									"binLowerBound": -25,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.00136883218737459,
									"binLowerBound": -20,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.000709046477221487,
									"binLowerBound": -15,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.00309832225027298,
									"binLowerBound": -10,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.00100014727571372,
									"binLowerBound": -95,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0131980234155187,
									"binLowerBound": -90,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0319555691608537,
									"binLowerBound": -85,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0411550608306148,
									"binLowerBound": -80,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0571474410010153,
									"binLowerBound": -75,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.087483637217916,
									"binLowerBound": -70,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.118989305081447,
									"binLowerBound": -65,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.129607130970499,
									"binLowerBound": -60,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.126254803443597,
									"binLowerBound": -55,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.12106735220393,
									"binLowerBound": -50,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.112020260231606,
									"binLowerBound": -45,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0831416821091373,
									"binLowerBound": -40,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0481683194490196,
									"binLowerBound": -35,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.017265923031709,
									"binLowerBound": -30,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.00629236845511037,
									"binLowerBound": -25,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.00147733766812004,
									"binLowerBound": -20,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.000754196057436131,
									"binLowerBound": -15,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.00302144239675736,
									"binLowerBound": -10,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.00107017541215375,
									"binLowerBound": -95,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.013811634657993,
									"binLowerBound": -90,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0345971383612725,
									"binLowerBound": -85,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0439518000922429,
									"binLowerBound": -80,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0582854283414674,
									"binLowerBound": -75,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0876126659090292,
									"binLowerBound": -70,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.119243751752162,
									"binLowerBound": -65,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.129019497608016,
									"binLowerBound": -60,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.124356334763198,
									"binLowerBound": -55,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.119807458106369,
									"binLowerBound": -50,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.110822219978115,
									"binLowerBound": -45,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0815506747896652,
									"binLowerBound": -40,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.0473397657151987,
									"binLowerBound": -35,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.017300811194545,
									"binLowerBound": -30,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.00610649226938532,
									"binLowerBound": -25,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.00142482809970669,
									"binLowerBound": -20,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.000787041084245462,
									"binLowerBound": -15,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "2.4 GHz",
									"binCatValue": null,
									"binFrequency": 0.00291228186523499,
									"binLowerBound": -10,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.000860045495051303,
									"binLowerBound": -95,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.00956945819202924,
									"binLowerBound": -90,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.017022051807967,
									"binLowerBound": -85,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0290112554127047,
									"binLowerBound": -80,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0645218171535885,
									"binLowerBound": -75,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.117985376227534,
									"binLowerBound": -70,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.16618101424752,
									"binLowerBound": -65,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.189581557926526,
									"binLowerBound": -60,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.17400482365088,
									"binLowerBound": -55,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.117794304947531,
									"binLowerBound": -50,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0710229523600155,
									"binLowerBound": -45,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0275191542448636,
									"binLowerBound": -40,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.00993577630549979,
									"binLowerBound": -35,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.00273762925054553,
									"binLowerBound": -30,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.000971994542379325,
									"binLowerBound": -25,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.000352748766809096,
									"binLowerBound": -20,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.000117582922269699,
									"binLowerBound": -15,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.000810456546286801,
									"binLowerBound": -10,
									"variable": "rssi",
									"weekday": "Friday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.000533542854344199,
									"binLowerBound": -95,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.00654979337718464,
									"binLowerBound": -90,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0140646320784422,
									"binLowerBound": -85,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.027255431319973,
									"binLowerBound": -80,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.062883292331934,
									"binLowerBound": -75,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.117508394668114,
									"binLowerBound": -70,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.167478702206933,
									"binLowerBound": -65,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.192388800182196,
									"binLowerBound": -60,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.177057097941344,
									"binLowerBound": -55,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.119855000828585,
									"binLowerBound": -50,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0713534688962192,
									"binLowerBound": -45,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0281597131367279,
									"binLowerBound": -40,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0101107912618724,
									"binLowerBound": -35,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.00264252686125283,
									"binLowerBound": -30,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.000995727354689437,
									"binLowerBound": -25,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.000365531173982871,
									"binLowerBound": -20,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.000104854920874243,
									"binLowerBound": -15,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.000692698605330954,
									"binLowerBound": -10,
									"variable": "rssi",
									"weekday": "Monday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.000690007183527386,
									"binLowerBound": -95,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.00821223914922172,
									"binLowerBound": -90,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0159182615252283,
									"binLowerBound": -85,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0287948384832315,
									"binLowerBound": -80,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0661187933487553,
									"binLowerBound": -75,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.119717642276949,
									"binLowerBound": -70,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.165411819210458,
									"binLowerBound": -65,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.186347405499732,
									"binLowerBound": -60,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.172259687693358,
									"binLowerBound": -55,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.118724601843301,
									"binLowerBound": -50,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0723164676357616,
									"binLowerBound": -45,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0289526368155554,
									"binLowerBound": -40,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0111908874474149,
									"binLowerBound": -35,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.00302770214444565,
									"binLowerBound": -30,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.00109702028225412,
									"binLowerBound": -25,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.000356067241471146,
									"binLowerBound": -20,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0000911857010299358,
									"binLowerBound": -15,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.000772736518305431,
									"binLowerBound": -10,
									"variable": "rssi",
									"weekday": "Saturday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.000498289967231857,
									"binLowerBound": -95,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.00737232306444096,
									"binLowerBound": -90,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0152951221016372,
									"binLowerBound": -85,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0284035807769227,
									"binLowerBound": -80,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0656611621357749,
									"binLowerBound": -75,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.120656481871456,
									"binLowerBound": -70,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.165371867910023,
									"binLowerBound": -65,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.186186177836772,
									"binLowerBound": -60,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.172208543561928,
									"binLowerBound": -55,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.119348650915669,
									"binLowerBound": -50,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.072816903551772,
									"binLowerBound": -45,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0294293716020028,
									"binLowerBound": -40,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0114221790637892,
									"binLowerBound": -35,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.00304337891628168,
									"binLowerBound": -30,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.00111428163120112,
									"binLowerBound": -25,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.000378677491515626,
									"binLowerBound": -20,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.000123045012803935,
									"binLowerBound": -15,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.000669962588778281,
									"binLowerBound": -10,
									"variable": "rssi",
									"weekday": "Sunday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.000798493403298911,
									"binLowerBound": -95,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.00873685096865488,
									"binLowerBound": -90,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0162512494594947,
									"binLowerBound": -85,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0278464614328014,
									"binLowerBound": -80,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0618078768774826,
									"binLowerBound": -75,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.115224284988738,
									"binLowerBound": -70,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.165812523843203,
									"binLowerBound": -65,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.192935629448988,
									"binLowerBound": -60,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.177103774698354,
									"binLowerBound": -55,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.12008969939807,
									"binLowerBound": -50,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0714444561738119,
									"binLowerBound": -45,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0275277818375631,
									"binLowerBound": -40,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.00968119757276861,
									"binLowerBound": -35,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.00255119557363342,
									"binLowerBound": -30,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.00096006909922671,
									"binLowerBound": -25,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.000373238362247213,
									"binLowerBound": -20,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.000127090168829904,
									"binLowerBound": -15,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.000728126692834035,
									"binLowerBound": -10,
									"variable": "rssi",
									"weekday": "Thursday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.000615522824379024,
									"binLowerBound": -95,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.00773609357894688,
									"binLowerBound": -90,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0152223394254262,
									"binLowerBound": -85,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0272666521396399,
									"binLowerBound": -80,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0620337400788796,
									"binLowerBound": -75,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.115816306217336,
									"binLowerBound": -70,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.166307467919149,
									"binLowerBound": -65,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.193749463068978,
									"binLowerBound": -60,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.178140268179509,
									"binLowerBound": -55,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.119974582358499,
									"binLowerBound": -50,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0717518713728846,
									"binLowerBound": -45,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0271479793211755,
									"binLowerBound": -40,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.00959677732124052,
									"binLowerBound": -35,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.00249053095092783,
									"binLowerBound": -30,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.000948419124582508,
									"binLowerBound": -25,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.000363337483844832,
									"binLowerBound": -20,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.000126021720501494,
									"binLowerBound": -15,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.000712626914100514,
									"binLowerBound": -10,
									"variable": "rssi",
									"weekday": "Tuesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.000775493563426756,
									"binLowerBound": -95,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.00851167837548985,
									"binLowerBound": -90,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0158574755943413,
									"binLowerBound": -85,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0277417283243712,
									"binLowerBound": -80,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0623197575447985,
									"binLowerBound": -75,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.115680291637222,
									"binLowerBound": -70,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.166543586703371,
									"binLowerBound": -65,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.19276549442588,
									"binLowerBound": -60,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.176678349541317,
									"binLowerBound": -55,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.120061288320915,
									"binLowerBound": -50,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0714070621224497,
									"binLowerBound": -45,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0274138364013954,
									"binLowerBound": -40,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0095815499489026,
									"binLowerBound": -35,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.0024771023966987,
									"binLowerBound": -30,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.000987925106840446,
									"binLowerBound": -25,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.000338483450751991,
									"binLowerBound": -20,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.000112971582648159,
									"binLowerBound": -15,
									"variable": "rssi",
									"weekday": "Wednesday"
								}, {
									"__typename": "SmartDashboardV236Bin",
									"band": "5 GHz",
									"binCatValue": null,
									"binFrequency": 0.000745924959181256,
									"binLowerBound": -10,
									"variable": "rssi",
									"weekday": "Wednesday"
								}],
								"totalCount": 252
							}
						}
					},
					"getsmartDashboardHistogramAggregated1":
					{
						"data": {
						"data": {
						"__typename": "SmartDashboard36AggregatedHistogramConnection",
						"nodes": [{
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "2.4 GHz",
						"binCatValue": null,
						"binFrequency": 0.0010424394808249,
						"binLowerBound": -95,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "2.4 GHz",
						"binCatValue": null,
						"binFrequency": 0.013772320723434,
						"binLowerBound": -90,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "2.4 GHz",
						"binCatValue": null,
						"binFrequency": 0.0327733747422039,
						"binLowerBound": -85,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "2.4 GHz",
						"binCatValue": null,
						"binFrequency": 0.0413432149058272,
						"binLowerBound": -80,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "2.4 GHz",
						"binCatValue": null,
						"binFrequency": 0.0555224411292968,
						"binLowerBound": -75,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "2.4 GHz",
						"binCatValue": null,
						"binFrequency": 0.0842145984524203,
						"binLowerBound": -70,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "2.4 GHz",
						"binCatValue": null,
						"binFrequency": 0.115344987512903,
						"binLowerBound": -65,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "2.4 GHz",
						"binCatValue": null,
						"binFrequency": 0.128176238112359,
						"binLowerBound": -60,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "2.4 GHz",
						"binCatValue": null,
						"binFrequency": 0.12450599025006,
						"binLowerBound": -55,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "2.4 GHz",
						"binCatValue": null,
						"binFrequency": 0.12177771695775,
						"binLowerBound": -50,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "2.4 GHz",
						"binCatValue": null,
						"binFrequency": 0.114094882467747,
						"binLowerBound": -45,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "2.4 GHz",
						"binCatValue": null,
						"binFrequency": 0.085660533909832,
						"binLowerBound": -40,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "2.4 GHz",
						"binCatValue": null,
						"binFrequency": 0.0509449387132805,
						"binLowerBound": -35,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "2.4 GHz",
						"binCatValue": null,
						"binFrequency": 0.0189111211437182,
						"binLowerBound": -30,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "2.4 GHz",
						"binCatValue": null,
						"binFrequency": 0.00655353249032182,
						"binLowerBound": -25,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "2.4 GHz",
						"binCatValue": null,
						"binFrequency": 0.00142396098952987,
						"binLowerBound": -20,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "2.4 GHz",
						"binCatValue": null,
						"binFrequency": 0.000736974741268492,
						"binLowerBound": -15,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "2.4 GHz",
						"binCatValue": null,
						"binFrequency": 0.00320073327722317,
						"binLowerBound": -10,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "5 GHz",
						"binCatValue": null,
						"binFrequency": 0.000696127965385469,
						"binLowerBound": -95,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "5 GHz",
						"binCatValue": null,
						"binFrequency": 0.00817434494909363,
						"binLowerBound": -90,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "5 GHz",
						"binCatValue": null,
						"binFrequency": 0.0157089277067712,
						"binLowerBound": -85,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "5 GHz",
						"binCatValue": null,
						"binFrequency": 0.0279943544347132,
						"binLowerBound": -80,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "5 GHz",
						"binCatValue": null,
						"binFrequency": 0.0633531466813765,
						"binLowerBound": -75,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "5 GHz",
						"binCatValue": null,
						"binFrequency": 0.117173991927562,
						"binLowerBound": -70,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "5 GHz",
						"binCatValue": null,
						"binFrequency": 0.166209727475439,
						"binLowerBound": -65,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "5 GHz",
						"binCatValue": null,
						"binFrequency": 0.191045240812188,
						"binLowerBound": -60,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "5 GHz",
						"binCatValue": null,
						"binFrequency": 0.175689833161126,
						"binLowerBound": -55,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "5 GHz",
						"binCatValue": null,
						"binFrequency": 0.119457621595321,
						"binLowerBound": -50,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "5 GHz",
						"binCatValue": null,
						"binFrequency": 0.0716453926600824,
						"binLowerBound": -45,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "5 GHz",
						"binCatValue": null,
						"binFrequency": 0.0278795432932849,
						"binLowerBound": -40,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "5 GHz",
						"binCatValue": null,
						"binFrequency": 0.0100886884247465,
						"binLowerBound": -35,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "5 GHz",
						"binCatValue": null,
						"binFrequency": 0.00267156958467386,
						"binLowerBound": -30,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "5 GHz",
						"binCatValue": null,
						"binFrequency": 0.00100010263943848,
						"binLowerBound": -25,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "5 GHz",
						"binCatValue": null,
						"binFrequency": 0.0003602838522529,
						"binLowerBound": -20,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "5 GHz",
						"binCatValue": null,
						"binFrequency": 0.000115875623561803,
						"binLowerBound": -15,
						"variable": "rssi",
						"weekday": null
						}, {
						"__typename": "SmartDashboardV236Bin",
						"aesApMac": "",
						"aesApName": "",
						"aesWlcId": "",
						"apEntityId": "",
						"band": "5 GHz",
						"binCatValue": null,
						"binFrequency": 0.000735227212982885,
						"binLowerBound": -10,
						"variable": "rssi",
						"weekday": null
						}],
						"totalCount": 36
						}
						}
						}
})