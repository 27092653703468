define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/login/Login',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/dnac_user/UserMgmt',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/dnac_user/UserRole',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/maglev/MaglevPackages',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/intent/Intent',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/dnac_user/SdAccessTemplate',
], function (SimLokiDatabaseActions, Login, UserMgmt, UserRole, MaglevPackages, Intents,SdAccessTemplate) {

    function getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
     }

     function setAcessToken(requestHeaders) {
        var header  =requestHeaders.Authorization;
        var tokenArray = header.split(" ");
        // getting the username
        var loginDetails = atob(tokenArray[1]);
        var loginArray = loginDetails.split(":");
        var userName = loginArray[0];
        // JWT consist of three parts(ALGORITHM,DATA,SIGNATURE)
        var algorithm = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9."; //{"typ":"JWT","alg":"HS256"}
        var dataString = '';
        var data =  btoa(JSON.stringify({"sub":"5a1d06965d074e008fa67f4c","authSource":"internal","tenantName":"TNT0","roles":["5a1d0659182cb264a098d321"],"tenantId":"5a1d06955d074e008fa67f4b","exp":1514121564,"username":userName}));
        var signature = ".GiRv0wijJee8I08RQovJJneNLriphr61fHdbIqIweBM";
        // setting the cookie
        document.cookie =  "X-JWT-ACCESS-TOKEN="+algorithm+data+signature;
    }

    return {
        init: function() {
        },

        doProcessRestRequest: function(urlAction){
            var data;
            switch(urlAction.method) {
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                case 'POST':
                    //data = processPostRequest(urlAction);
                    break;

                case 'PUT':
                    //data = processPutRequest(urlAction);
                    break;

                case 'DELETE':
                    //dddata = processDeleteRequest(urlAction);
                    break;

                default:
                    break;
            }

            return data;
        }

    };

    function processGetRequest(urlAction) {

        if(urlAction.service.indexOf("login") >= 0) {
            setAcessToken(urlAction.requestHeaders);
            return Login.LogIn_Template;
        } else if(urlAction.service.indexOf("logoff") >= 0) {
            document.cookie =  "X-JWT-ACCESS-TOKEN=";
            return Login.LogOff_Template;
        }
        else if(urlAction.service.indexOf("users") >= 0) {
            var webToken = getCookie("X-JWT-ACCESS-TOKEN");
            var accessToken= webToken.split(".");
            var decodedString = JSON.parse(atob(accessToken[1]));
            var userName = decodedString.username;
            var tJson = JSON.parse(JSON.stringify(UserMgmt.USER_MGMT_Template));
            // setting the current user name.
            tJson.response.users[0].username =userName;
            return tJson;
        }
        else if(urlAction.service.indexOf("roles") >= 0) {
            return UserRole.User_Role_Template;
        } // this call for policy app
        else if(urlAction.service.indexOf("maglev") >= 0 && urlAction.service.indexOf("packages") >= 0 && urlAction.service.indexOf("sd-access") >= 0) {
            return SdAccessTemplate.SD_ACCESS_Template;
        }
        else if(urlAction.service.indexOf("maglev") >= 0 && urlAction.service.indexOf("packages") >= 0) {
            return MaglevPackages.Maglev_Packages_Template;
        }
        else if(urlAction.service.indexOf("intents") >= 0) {
            return Intents.Intent_Template;
        }
        else if(urlAction.service.indexOf("checkSession") >= 0) {
            return '';
        }

        /*else {
            return {"response":[],"version":"1.0"};
        }*/

    }
 
});
