define({
    "ScalableGroup_IP_RENDERED_Template": [
        {
            "id": "05d00202-22a8-4945-9dcc-cccf7e98fe6f",
            "instanceId": 80144,
            "authEntityId": 80144,
            "displayName": "0",
            "authEntityClass": -1909073334,
            "deployPending": "NONE",
            "instanceVersion": 1,
            "createTime": 1497004092280,
            "deployed": true,
            "description": "IP Rendered Policy",
            "isStale": false,
            "lastUpdateTime": 1497004107054,
            "name": "fromIPACL",
            "namespace": "scalablegroup",
            "provisioningState": "DEPLOYED",
            "type": "scalablegroup",
            "cfsChangeInfo": [],
            "scalableGroupExternalHandle": "5fbd86f0-4cfe-11e7-a0f9-005056853f7e",
            "scalableGroupType": "IP_RENDERED",
            "securityGroupTag": 24,
            "state": "ACTIVE",
            "vnAgnostic": false,
            "isDeleted": false,
            "isSeeded": false,
            "pushed": false,
            "iseReserved": false,
            "propagateToAci": false,
            "scalableGroupExternalHandle": "test",
            "identitySource": {
                "id": "261ade99-bebe-45b4-86b0-a401a818f7c8",
                "instanceId": 253253,
                "authEntityId": 253253,
                "displayName": "253253",
                "authEntityClass": -28148598,
                "deployPending": "NONE",
                "instanceVersion": 0,
                "state": "INACTIVE",
                "type": "APIC_EM"
            },
            "indicativeNetworkIdentity": [
                {
                    "id": "d47dea2b-af06-40a5-bd5e-c46482a1cdd9",
                    "instanceId": 255279,
                    "authEntityId": 255279,
                    "displayName": "0",
                    "authEntityClass": 124090504,
                    "deployPending": "NONE",
                    "instanceVersion": 0,
                    "ipv4Subnet": [
                        "1.1.1.1"
                    ],
                    "ipv6Subnet": [],
                    "lowerPort": 0,
                    "upperPort": 0
                }
            ],
            "networkApplications": [],
            "networkIdentity": [],
            "user": [],
            "userGroup": []
        }
    ],
    "ScalableGroup_APPLICATION_Template":[
          {
  "id": "b5915c9c-5f13-44cc-a520-3c550e11ccea",
  "instanceId": 4705,
  "authEntityId": 4705,
  "displayName": "4705",
  "authEntityClass": -1909073334,
  "instanceTenantId": "SYS0",
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1525709748604,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1525709748604,
  "name": "login",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "login",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "e68ccd57-ab06-4c5a-8ed1-f7e423c3b88b",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "instanceTenantId": "SYS0",
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "b419fe8f-98df-4a55-a45b-8c244604e1e1",
      "instanceId": 13785,
      "authEntityId": 13785,
      "displayName": "13785",
      "authEntityClass": 124090504,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "513",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
                            {
                              "id": "19246227-f292-4107-a09c-c2eb0562cbf6",
                              "instanceId": 11655,
                              "authEntityId": 11655,
                              "displayName": "11655",
                              "authEntityClass": -217092956,
                              "instanceTenantId": "SYS0",
                              "deployPending": "NONE",
                              "instanceVersion": 0,
                              "appProtocol": "tcp",
                              "applicationGroup": "other",
                              "applicationType": "DEFAULT",
                              "categoryId": "5dfaf12b-17ff-468b-bc1a-99caf3a4cc5d",
                              "createTime": 1525709748604,
                              "encrypted": "false",
                              "engineId": "3",
                              "helpString": "Obtain a remote login Terminal",
                              "ignoreConflict": false,
                              "lastUpdateTime": 1525709748604,
                              "longDescription": "The rlogin facility provides a remote-echoed, locally flow-controlled virtual terminal with proper flushing of output.",
                              "name": "login",
                              "nbarId": "428",
                              "p2pTechnology": "false",
                              "popularity": 7,
                              "rank": 65535,
                              "references": "http://tools.ietf.org/html/rfc1282",
                              "selectorId": "513",
                              "subCategory": "595da177-cc84-491a-a0ee-9987cf4be786",
                              "trafficClass": "OPS_ADMIN_MGMT",
                              "tunnel": "false"
                            }
                          ],
                          "networkIdentity": [
                            {
                              "id": "5b43cf88-d24d-4583-90c1-94fd3d7eac8f",
                              "instanceId": 13784,
                              "authEntityId": 13784,
                              "displayName": "13784",
                              "authEntityClass": 124090504,
                              "instanceTenantId": "SYS0",
                              "deployPending": "NONE",
                              "instanceVersion": 0,
                              "ipv4Subnet": [

                              ],
                              "ipv6Subnet": [

                              ],
                              "lowerPort": 0,
                              "ports": "513",
                              "protocol": "TCP",
                              "upperPort": 0
                            }
                          ],
                          "parentScalableGroup": {
                            "idRef": "8d20eb85-91ff-481c-a92a-c1ad01453b0e"
                          },
                          "user": [

                          ],
                          "userGroup": [

                          ]
                        }
        ],
        //Added ScalableGroup_USER_DEVICE_1.3.1_Template for 1.3.1 by Karan
        "ScalableGroup_USER_DEVICE_1_3_1_Template": [
          {
            "id": "87b89e60-13de-4ff3-80ac-ae0a47d95c68",
            "instanceId": 1188189,
            "displayName": "1188189",
            "instanceVersion": 0,
            "description": "Auditor Scalable Group",
            "ipSgtRefCount": 0,
            "isDeleted": false,
            "iseInstanceUuid": "",
            "iseReserved": false,
            "iseRetryCount": 0,
            "iseTimeStamp": "",
            "name": "Auditors",
            "policyRefCount": 0,
            "propagateToAci": false,
            "pushed": false,
            "scalableGroupType": "USER-DEVICE",
            "securityGroupTag": "9/0x9",
            "syncMessage": "",
            "syncStatus": "CREATE",
            "tenantintsegment": 4104,
            "tenantlongsegment": 0,
            "virtualNetworkNames": "DEFAULT_VN",
            "virtualNetworksCount": "1",
            "vnAgnostic": false,
            "acaGBPListDestViewSummary": [],
            "acaGBPListSourceViewSummary": [],
            "acaScalableGroupVNInfo": [
              {
                "id": "677cfedb-4d3c-4dc7-91b0-8f8ab1e22dda",
                "instanceId": 0,
                "displayName": "0",
                "instanceVersion": 0,
                "name": "DEFAULT_VN"
              }
            ]
          }],
        "ACL_Template1.3.1":[
          {
              "generationId": 1,
              "aces": "com.cisco.epm.pap.api.vo.ByteObject@26d1bfca",
              "stagingAces": "",
              "ipVersion": "Agnostic",
              "dirty": true,
              "name": "Permit IP",
              "id": "fb69c6a0-9e91-11e8-8a9b-005056b82e84",
              "description": "Contract with access action as PERMIT"
          },
          {
              "generationId": 1,
              "aces": "com.cisco.epm.pap.api.vo.ByteObject@26d1bfca",
              "stagingAces": "",
              "ipVersion": "Agnostic",
              "dirty": true,
              "name": "Deny IP",
              "id": "fb69c6a0-9e91-11e8-8a9b-005056b82e84",
              "description": "Contract with access action as Deny"
          },
          {
              "generationId": 1,
              "aces": "com.cisco.epm.pap.api.vo.ByteObject@26d1bfca",
              "stagingAces": "",
              "ipVersion": "Agnostic",
              "dirty": true,
              "name": "Deny_TFTP_only",
              "id": "fb69c6a0-9e91-11e8-8a9b-005056b82e84",
              "description": "Access Contract for ISE SgAcls [Deny_TFTP_only]"
          },
          {
              "generationId": 1,
              "aces": "com.cisco.epm.pap.api.vo.ByteObject@26d1bfca",
              "stagingAces": "",
              "ipVersion": "Agnostic",
              "dirty": true,
              "name": "Allow_HTTPS_Deny_All",
              "id": "fb69c6a0-9e91-11e8-8a9b-005056b82e84",
              "description": ""
          },
          {
              "generationId": 1,
              "aces": "com.cisco.epm.pap.api.vo.ByteObject@26d1bfca",
              "stagingAces": "",
              "ipVersion": "Agnostic",
              "dirty": true,
              "name": "PCI_Allowed_Traffic",
              "id": "fb69c6a0-9e91-11e8-8a9b-005056b82e84",
              "description": "DNAC Created Sgacl"
          }
        ],
        "SecurityGroups1.3.1":[
          {
            "generationId": 0,
            "dirty": false,
            "value": 0,
            "isTagFromRange": "false",
            "numericRepresentation": "1/0001",
            "icon": 0,
            "readOnly": "false",
            "propogateToApic": "false",
            "name": "",
            "id": "",
            "description": ""
          }
        ],
        "ScalableGroup_USER_DEVICE_Template":[{
            "id": "093c69ba-c564-42da-b56e-d62d50cb3cdf",
            "instanceId": 83130,
            "authEntityId": 83130,
            "displayName": "0",
            "authEntityClass": -1909073334,
            "deployPending": "NONE",
            "instanceVersion": 2,
            "createTime": 1495067320574,
            "deployed": true,
            "description": "Lights Security Group",
            "isStale": false,
            "lastUpdateTime": 1495067320574,
            "name": "Lights",
            "namespace": "scalablegroup",
            "provisioningState": "DEPLOYED",
            "type": "scalablegroup",
            "version": 0,
            "cfsChangeInfo": [],
            "scalableGroupExternalHandle": "92adf9f0-8c01-11e6-996c-525400b48521",
            "scalableGroupType": "USER_DEVICE",
            "securityGroupTag": 9,
            "state": "ACTIVE",
            "vnAgnostic": false,
            "identitySource": {
                "id": "0e9a902d-18a8-4521-9db4-3c862871ca69",
                "instanceId": 203203,
                "authEntityId": 203203,
                "displayName": "efbba5b0[172.28.28.105]",
                "authEntityClass": -272915024,
                "deployPending": "NONE",
                "instanceVersion": 1,
                "ipAddress": {
                    "paddedAddress": "172. 28. 28.105",
                    "addressType": "IP_V4",
                    "address": "172.28.28.105"
                },
                "password": "DppILCXdCD1ePS89HE8EMm9SDuz4oLdvUqvbLSXwb9mwfJ9yxoAmh8QoEzxUbGWQH3p+6xDRBA9eXGsAcpDkc0F+zokxfXNR",
                "state": "ACTIVE",
                "type": "ISE",
                "userName": "ersadmin",
                "keystoreFileId": "71e4f929-6753-4e20-b3cc-5026c2007839",
                "keystoreFilePassPhrase": "dy1btpHFGumn4Or4O8ig22UaD8UCLTyZ1iVj1Q8lxWmkvb26TKrSiUrvNHXSYLOYVWMzUH211Qi+lkRACKRwPQd9S5gMz/mR",
                "role": "PRIMARY",
                "subscriberName": "dnac-ise",
                "trustState": "UNTRUSTED",
                "truststoreFileId": "c874662c-c701-4ceb-9770-82196ee70e59",
                "truststoreFilePassPhrase": "k8pXz3/oERiUFkWAPt1zldsSxEm2w76VdL1ZObvPyxQ52wYetplG0G5eRAg2JTnzuoJSNPx5yYvRNwCONjK9lgYBpKlwqHqA",
                "isetrustCertificate": []
            },
            "indicativeNetworkIdentity": [{  
            "id":"a1425794-3f95-4dc4-876d-8e84226043b6",
            "instanceId":12521,
            "authEntityId":12521,
            "displayName":"12521",
            "authEntityClass":124090504,
            "deployPending":"NONE",
            "instanceVersion":3,
            "ipv4Subnet":[  

            ],
            "ipv6Subnet":[  

            ],
            "lowerPort":0,
            "ports":"8905,8906",
            "protocol":"UDP",
            "upperPort":0
         }],
            "networkApplications": [],
            "networkIdentity": [],
            "user": [],
            "userGroup": []
        }],

        "ScalableGroup_APPLICATION_GROUP_Template":[
    {
      "id": "02bdcf7e-fd06-40c2-b69d-b2922f6f01c1",
      "instanceId": 4163,
      "authEntityId": 4163,
      "displayName": "4163",
      "authEntityClass": -1909073334,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1525709746881,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1525709746881,
      "name": "enterprise-ipc",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "enterprise-ipc",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "e68ccd57-ab06-4c5a-8ed1-f7e423c3b88b",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "0aabbdf2-3ccd-4804-ba83-4a9f02f6da16",
      "instanceId": 4055,
      "authEntityId": 4055,
      "displayName": "4055",
      "authEntityClass": -1909073334,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1525709746147,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1525709746147,
      "name": "authentication-services",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "authentication-services",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "e68ccd57-ab06-4c5a-8ed1-f7e423c3b88b",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "1a38d908-439c-407c-96d9-2aaa84c60374",
      "instanceId": 4065,
      "authEntityId": 4065,
      "displayName": "4065",
      "authEntityClass": -1909073334,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1525709746327,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1525709746327,
      "name": "backup-and-storage",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "backup-and-storage",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "e68ccd57-ab06-4c5a-8ed1-f7e423c3b88b",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "1a686204-7dc3-48c0-85c5-dfbd0dfed5aa",
      "instanceId": 4263,
      "authEntityId": 4263,
      "displayName": "4263",
      "authEntityClass": -1909073334,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1525709747189,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1525709747189,
      "name": "software-development-tools",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "software-development-tools",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "e68ccd57-ab06-4c5a-8ed1-f7e423c3b88b",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "25e97b17-60d5-4149-95f0-397c12d2537d",
      "instanceId": 4069,
      "authEntityId": 4069,
      "displayName": "4069",
      "authEntityClass": -1909073334,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1525709746349,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1525709746349,
      "name": "consumer-media",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "consumer-media",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "e68ccd57-ab06-4c5a-8ed1-f7e423c3b88b",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "3204d091-7460-41d1-93b0-68717601cbfd",
      "instanceId": 4195,
      "authEntityId": 4195,
      "displayName": "4195",
      "authEntityClass": -1909073334,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1525709747003,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1525709747003,
      "name": "email",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "email",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "e68ccd57-ab06-4c5a-8ed1-f7e423c3b88b",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "32b638a5-02d9-44fb-ad88-77b6d9b3a92a",
      "instanceId": 4047,
      "authEntityId": 4047,
      "displayName": "4047",
      "authEntityClass": -1909073334,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1525709746052,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1525709746052,
      "name": "network-management",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "network-management",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "e68ccd57-ab06-4c5a-8ed1-f7e423c3b88b",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "4e92705f-5246-489c-ae15-85a0c90540de",
      "instanceId": 4190,
      "authEntityId": 4190,
      "displayName": "4190",
      "authEntityClass": -1909073334,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1525709746987,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1525709746987,
      "name": "consumer-gaming",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "consumer-gaming",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "e68ccd57-ab06-4c5a-8ed1-f7e423c3b88b",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "52fb75ce-1253-4228-aa46-964ecb24742f",
      "instanceId": 4078,
      "authEntityId": 4078,
      "displayName": "4078",
      "authEntityClass": -1909073334,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1525709746457,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1525709746457,
      "name": "general-browsing",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "general-browsing",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "e68ccd57-ab06-4c5a-8ed1-f7e423c3b88b",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "56370512-cec7-4526-a313-17793fefd92f",
      "instanceId": 4061,
      "authEntityId": 4061,
      "displayName": "4061",
      "authEntityClass": -1909073334,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1525709746237,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1525709746237,
      "name": "collaboration-apps",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "collaboration-apps",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "e68ccd57-ab06-4c5a-8ed1-f7e423c3b88b",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "62c70096-dcc4-467f-bfef-e6385a3d0b8e",
      "instanceId": 4043,
      "authEntityId": 4043,
      "displayName": "4043",
      "authEntityClass": -1909073334,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1525709746026,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1525709746026,
      "name": "consumer-file-sharing",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "consumer-file-sharing",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "e68ccd57-ab06-4c5a-8ed1-f7e423c3b88b",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "665e6969-a5bd-470c-b6cf-86b675b867cd",
      "instanceId": 4309,
      "authEntityId": 4309,
      "displayName": "4309",
      "authEntityClass": -1909073334,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1525709747393,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1525709747393,
      "name": "naming-services",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "naming-services",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "e68ccd57-ab06-4c5a-8ed1-f7e423c3b88b",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "71520356-c2c0-44cf-be7f-fce2a7d31aa0",
      "instanceId": 4102,
      "authEntityId": 4102,
      "displayName": "4102",
      "authEntityClass": -1909073334,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1525709746623,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1525709746623,
      "name": "consumer-misc",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "consumer-misc",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "e68ccd57-ab06-4c5a-8ed1-f7e423c3b88b",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "78e14c39-1473-4d8a-ae9a-9e62be72705e",
      "instanceId": 4771,
      "authEntityId": 4771,
      "displayName": "4771",
      "authEntityClass": -1909073334,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1525709748814,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1525709748814,
      "name": "saas-apps",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "saas-apps",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "e68ccd57-ab06-4c5a-8ed1-f7e423c3b88b",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "7f7ce645-6561-46aa-9dd3-f376f6759c2d",
      "instanceId": 4034,
      "authEntityId": 4034,
      "displayName": "4034",
      "authEntityClass": -1909073334,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1525709745556,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1525709745556,
      "name": "general-misc",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "general-misc",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "e68ccd57-ab06-4c5a-8ed1-f7e423c3b88b",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "80aa0753-4fed-4600-8c0f-9c2921c07af9",
      "instanceId": 4153,
      "authEntityId": 4153,
      "displayName": "4153",
      "authEntityClass": -1909073334,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1525709746848,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1525709746848,
      "name": "tunneling",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "tunneling",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "e68ccd57-ab06-4c5a-8ed1-f7e423c3b88b",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "8d20eb85-91ff-481c-a92a-c1ad01453b0e",
      "instanceId": 4073,
      "authEntityId": 4073,
      "displayName": "4073",
      "authEntityClass": -1909073334,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1525709746369,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1525709746369,
      "name": "remote-access",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "remote-access",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "e68ccd57-ab06-4c5a-8ed1-f7e423c3b88b",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "9b381b7f-256f-4534-95d7-122c30164482",
      "instanceId": 4057,
      "authEntityId": 4057,
      "displayName": "4057",
      "authEntityClass": -1909073334,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1525709746161,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1525709746161,
      "name": "file-sharing",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "file-sharing",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "e68ccd57-ab06-4c5a-8ed1-f7e423c3b88b",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "a3013931-e99f-46fa-aa2f-73d65fa41974",
      "instanceId": 4327,
      "authEntityId": 4327,
      "displayName": "4327",
      "authEntityClass": -1909073334,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1525709747450,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1525709747450,
      "name": "signaling",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "signaling",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "e68ccd57-ab06-4c5a-8ed1-f7e423c3b88b",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "aad0bf6c-72d6-4ec4-a1e4-a4c58eb3f930",
      "instanceId": 4085,
      "authEntityId": 4085,
      "displayName": "4085",
      "authEntityClass": -1909073334,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1525709746546,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1525709746546,
      "name": "software-updates",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "software-updates",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "e68ccd57-ab06-4c5a-8ed1-f7e423c3b88b",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "abcb1830-d50e-4745-a23f-b6a059f8ae9c",
      "instanceId": 4038,
      "authEntityId": 4038,
      "displayName": "4038",
      "authEntityClass": -1909073334,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1525709745947,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1525709745947,
      "name": "consumer-browsing",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "consumer-browsing",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "e68ccd57-ab06-4c5a-8ed1-f7e423c3b88b",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "b7e5042c-11a4-4c4c-9f10-d1464c66bf48",
      "instanceId": 4235,
      "authEntityId": 4235,
      "displayName": "4235",
      "authEntityClass": -1909073334,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1525709747122,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1525709747122,
      "name": "streaming-media",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "streaming-media",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "e68ccd57-ab06-4c5a-8ed1-f7e423c3b88b",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "bc8f6f63-76d6-43c4-b9c3-ef80d3f35758",
      "instanceId": 4091,
      "authEntityId": 4091,
      "displayName": "4091",
      "authEntityClass": -1909073334,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1525709746566,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1525709746566,
      "name": "network-control",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "network-control",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "e68ccd57-ab06-4c5a-8ed1-f7e423c3b88b",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c2723301-e531-46eb-a7c3-8867ca5664bd",
      "instanceId": 4140,
      "authEntityId": 4140,
      "displayName": "4140",
      "authEntityClass": -1909073334,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1525709746786,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1525709746786,
      "name": "general-media",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "general-media",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "e68ccd57-ab06-4c5a-8ed1-f7e423c3b88b",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "cf7cd9c7-cd02-490b-8bdb-566fd9e8713e",
      "instanceId": 4192,
      "authEntityId": 4192,
      "displayName": "4192",
      "authEntityClass": -1909073334,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1525709746994,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1525709746994,
      "name": "consumer-social-networking",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "consumer-social-networking",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "e68ccd57-ab06-4c5a-8ed1-f7e423c3b88b",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "df8723a6-b7da-4554-919f-98e8810ec8a6",
      "instanceId": 4104,
      "authEntityId": 4104,
      "displayName": "4104",
      "authEntityClass": -1909073334,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1525709746633,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1525709746633,
      "name": "desktop-virtualization-apps",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "desktop-virtualization-apps",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "e68ccd57-ab06-4c5a-8ed1-f7e423c3b88b",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ea17c2de-c102-4a36-ac63-974d1e71fc55",
      "instanceId": 4213,
      "authEntityId": 4213,
      "displayName": "4213",
      "authEntityClass": -1909073334,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1525709747057,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1525709747057,
      "name": "database-apps",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "database-apps",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "e68ccd57-ab06-4c5a-8ed1-f7e423c3b88b",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f371466f-d9e7-4389-b8b2-3bc3b0e582c8",
      "instanceId": 4559,
      "authEntityId": 4559,
      "displayName": "4559",
      "authEntityClass": -1909073334,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1525709748139,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1525709748139,
      "name": "local-services",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "local-services",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "e68ccd57-ab06-4c5a-8ed1-f7e423c3b88b",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    }
  ]
});
