define([
    'core/../../../public/libs/node_modules/moment',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/reports/ReportsROITemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/CloudConnectivitySenseROI'


], function ( moment, SimLokiDatabaseActions, UtilityFunctions, ReportsROITemplate, CloudConnectivitySenseROI) {

    return {
        init: function () {
        },

        doProcessRestRequest: function (urlAction) {           
            var data;
            switch (urlAction.method) {
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                case 'POST':
                    data = processPostRequest(urlAction);
                    break;

                case 'PUT':
                    data = processPutRequest(urlAction);
                    break;

                case 'DELETE':
                    data = processDeleteRequest(urlAction);
                    break;

                default:
                    break;
            }

            return data;
        }
    };
    function processGetRequest(urlAction) {
        if (urlAction.service.indexOf('assumptions') > -1 ) {
            var data = JSON.parse(JSON.stringify(ReportsROITemplate.Assumptions_Template))
            return data
        }else if(urlAction.service.indexOf('report') > -1){
            //need to append data based on the date filter
            var data = JSON.parse(JSON.stringify(ReportsROITemplate.Report_Template))
            var fromDate = urlAction.filter['fromDate']
            var toDate = urlAction.filter['toDate']
            //time date Difference calculator
            var d1 = new Date(fromDate);
            var d2 = new Date(toDate);
            var diffTime = d2.getTime() - d1.getTime();
            var diffDays = diffTime / (1000 * 3600 * 24);
            console.log('ROI TimeDiff between fromDate to ToDate:   '+diffDays)
            for(var i=0;i<data.rois.length;i++){
                data.fromDate = fromDate;
                data.toDate = toDate;
                var roiIntervalsData = data.rois[i].roiIntervals;
                data.rois[i].roiIntervals[0].fromDate = fromDate;
                data.rois[i].roiIntervals[0].toDate = toDate
                if(diffDays>31){
                    let toDate1  = new Date(toDate);
                    let toDateLast = toDate1.setMonth(toDate1.getMonth() - 1);
                    let toDateLast1 = moment(new Date(toDateLast)).utc().format("YYYY-MM-DD");
                    let fromDateLast = toDate1.setMonth(toDate1.getMonth() - 1);
                    let fromDateLast1 = moment(new Date(fromDateLast)).utc().format("YYYY-MM-DD");
                    let roiInterData = {};
                    roiInterData.fromDate = fromDateLast1;
                    roiInterData.toDate = toDateLast1;
                    roiInterData.timeSaved = "180.0";
                    roiInterData.costSaved = "2000.0";
                    roiInterData.kpiUsage = "1";
                    roiInterData.dataFound = true;
                    data.rois[i].roiIntervals.push(roiInterData)
                    let totalTime = parseFloat(data.rois[i].totalTimeSaved)+180;
                    let totalCost = parseFloat(data.rois[i].totalCostSaved)+2000;
                    let kpiVal = parseFloat(data.rois[i].kpiUsage)+1
                    data.rois[i].totalTimeSaved = totalTime.toString();
                    data.rois[i].totalCost = totalCost.toString();
                    data.rois[i].kpiUsage = kpiVal.toString();
                }
            }
            
            return data
        }else {
            var data = JSON.parse(JSON.stringify(CloudConnectivitySenseROI.CloudConnectivitySense_Template))
            return data
        }
    }
});