define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/interface/InterfaceTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/interface/InterfaceErrorTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
], function (SimLokiDatabaseActions, InterfaceTemplate, InterfaceErrorTemplate, SimTaskDataGenerator) {

    return {
        init: function() {
        },

        doProcessRestRequest: function(urlAction){
            var data;
            switch(urlAction.method) {
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                case 'POST':
                    break;

                case 'PUT':
                    data = processPutRequest(urlAction);
                    break;

                case 'DELETE':
                    break;

                default:
                    break;
            }

            return data;
        }
     };

    function processGetRequest(urlAction){
        if(urlAction.action.id != '') {
            //var data = JSON.parse(JSON.stringify(InterfaceTemplate.Interface_Template));
            if(urlAction.service.indexOf('legit-operation') != -1) {
                return {"interfaceUuid":urlAction.action.id,"properties":[{"name":"description","applicable":true,"failureReason":null},{"name":"adminStatus","applicable":true,"failureReason":null},{"name":"vlanId","applicable":true,"failureReason":null}],"operations":[{"name":"ClearMacAddress","applicable":true,"failureReason":null}]}
            }
            var interfaceType = urlAction.filter["interfaceType"];
            var data = SimLokiDatabaseActions.getFilteredRecordHandler('device-interface',{'deviceId': urlAction.action.id });
            if(data && data.length == 0) {
                data = JSON.parse(JSON.stringify(InterfaceTemplate.Interface_Template));
            }
            var deviceInfo = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', { 'networkDeviceId' : urlAction.action.id });
            if(deviceInfo.length>0) {
                var deviceInterfaceInfo = deviceInfo[0].deviceInterfaceInfo;
                for(var i=0;i<deviceInterfaceInfo.length;i++) {
                    for(var j=0;j<data.length;j++) {
                        if(deviceInterfaceInfo[i].interfaceId ==data[j].id) {
                            //data[j].status="up";
                            data[j].status = data[j].status;
                        }
                    }
                }
            }
            if(interfaceType){
                data = data.filter(e => e.interfaceType == 'Physical')
            }
            if(urlAction.action.count) return data.length;
            
            return data;
        } else if (urlAction.action.count) {
            //api/v1/interface/count
            return SimLokiDatabaseActions.getCount('device-interface');
        } else {
            return InterfaceErrorTemplate.Error_Message;
        }
    }
    function processPutRequest(urlAction){
        if(urlAction.service.indexOf('interface') >-1){
            var resultObj = SimTaskDataGenerator.createTask('NCIM');

            var recObj = SimLokiDatabaseActions.getFilteredRecordHandler('device-interface',{'id':urlAction.action.id});
            recObj[0].adminStatus = urlAction.restPayload.adminStatus;
            recObj[0].status = urlAction.restPayload.adminStatus;
            SimLokiDatabaseActions.update('device-interface', recObj);
            return resultObj
        }
    }

});
