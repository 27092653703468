define({
"Template":
{
  "version": "1.0",
  "response": {
    "id": "677e7e1f-7ca3-4227-aef9-ad324afffc4b",
    "name": "network_device_poe_power_deny_trigger",
    "enabled": true,
    "flattened": true,
    "entityType": "network_device",
    "entityName": "Unknown",
    "entity": "172.25.33.73",
    "groupBy": "Unknown",
    "category": "Device",
    "severity": "HIGH",
    "summary": "PoE powered device connected on DeviceNamePlaceHolder InterfaceDownPlaceHolder got power denied",
    "groupId": "PoE_port_failure_grouping",
    "groupName": "PoE failures on network device",
    "scope": "GLOBAL",
    "priority": "P3",
    "rootCause": "ILPOWER-5-ILPOWER_POWER_DENY",
    "timestamp": 1588062624289,
    "description": "PoE power denied to device connected on DeviceNamePlaceHolder InterfaceDownPlaceHolder due to insufficient power.",
    "suggestions": [
      {
        "message": "Check operational details of the affected interface Gi4/0/21. Compare actual consumption and negotiated values",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Detailed inline power status",
            "command": "sh power inline InterfaceDownPlaceHolder detail",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Check inline power status.",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Inline power status of all interfaces",
            "command": "show power inline",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Check power supply status.",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Built-in power supply status",
            "command": "show env power",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Check overall PoE power usage, PoE port power status and allocation."
      },
      {
        "message": "Verify the device logs for power controller related event messages."
      },
      {
        "message": "Contact Cisco TAC World Wide – https://www.cisco.com/c/en/us/support/web/tsd-cisco-worldwide-contacts.html"
      }
    ],
    "additionalParams": [
      {
        "key": "managementIpAddr",
        "value": "172.25.33.73"
      },
      {
        "key": "description",
        "value": "PoE power denied to device connected on DeviceNamePlaceHolder InterfaceDownPlaceHolder due to insufficient power."
      },
      {
        "key": "_name",
        "value": "network_device_poe_power_deny_trigger"
      },
      {
        "key": "_entity_type",
        "value": "NetworkDevice"
      },
      {
        "key": "eventSource",
        "value": "Network Device"
      },
      {
        "key": "type",
        "value": "Issue"
      },
      {
        "key": "_key",
        "value": "NetworkDevice:172.25.33.73:Gi4/0/21:insufficient power"
      },
      {
        "key": "floorId",
        "value": ""
      },
      {
        "key": "_count",
        "value": "2"
      },
      {
        "key": "messageType",
        "value": "ILPOWER-5-ILPOWER_POWER_DENY"
      },
      {
        "key": "areaName",
        "value": "Global"
      },
      {
        "key": "scope",
        "value": "global"
      },
      {
        "key": "rootCause",
        "value": "ILPOWER-5-ILPOWER_POWER_DENY"
      },
      {
        "key": "_primary_key",
        "value": "NetworkDevice:172.25.33.73"
      },
      {
        "key": "floorName",
        "value": ""
      },
      {
        "key": "siteType",
        "value": "building"
      },
      {
        "key": "deviceType",
        "value": "Cisco Catalyst 9300L Switch Stack"
      },
      {
        "key": "severity",
        "value": "HIGH"
      },
      {
        "key": "deviceUuid",
        "value": "ce0213f1-7b9b-4ee3-873b-6535ca98a432"
      },
      {
        "key": "messageText",
        "value": "1031792: Apr 28 08:30:23 UTC:  Interface Gi4/0/21: inline power denied. Reason: insufficient power"
      },
      {
        "key": "param5",
        "value": ""
      },
      {
        "key": "deviceRole",
        "value": "ACCESS"
      },
      {
        "key": "_suppression",
        "value": "1800"
      },
      {
        "key": "deviceOS",
        "value": "16.12.20200224:012235"
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "nwDeviceName",
        "value": "frnk4"
      },
      {
        "key": "_eventTime",
        "value": "1588062624288"
      },
      {
        "key": "_mostRecent",
        "value": "1588062624289"
      },
      {
        "key": "entityId",
        "value": "172.25.33.73"
      },
      {
        "key": "eventUniqueId",
        "value": "172.25.33.73"
      },
      {
        "key": "deviceFamily",
        "value": "Switches and Hubs"
      },
      {
        "key": "param3",
        "value": ""
      },
      {
        "key": "priority",
        "value": "P3"
      },
      {
        "key": "param4",
        "value": ""
      },
      {
        "key": "param1",
        "value": "Gi4/0/21"
      },
      {
        "key": "param2",
        "value": "insufficient power"
      },
      {
        "key": "buildingId",
        "value": "be25e753-6bea-466b-ae89-814728c899ec"
      },
      {
        "key": "buildingName",
        "value": "Global/RAJ-SJC20-frnk"
      },
      {
        "key": "areaId",
        "value": "Global"
      },
      {
        "key": "siteHierarchy",
        "value": "Global/RAJ-SJC20-frnk"
      },
      {
        "key": "deviceModel",
        "value": "FOC2328L022"
      },
      {
        "key": "category",
        "value": "Device"
      },
      {
        "key": "timestring",
        "value": "2020-04-28T08:30:24.288+0000"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}
})