define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimDefaultDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/PolicyAnalytics/PolicyAnalyticsTemplate'

], function (SimLokiDatabaseActions, UtilityFunctions, SimDefaultDataGenerator, PolicyAnalyticsTemplate) {

    return {
        init: function () {

        },
    
        processGet: function (urlAction) {
            var yestdate = (UtilityFunctions.getTimeStamp())-86400000;
            if(urlAction.url.includes('appstacks')){
                var tempObj  = PolicyAnalyticsTemplate.magellanService_Template;
                return tempObj
            }else if(urlAction.service.indexOf('scalablegroups') > -1 && urlAction.service.indexOf('counts') > -1 ||
            urlAction.service.indexOf('endpointclassification') > -1 && urlAction.service.indexOf('counts') > -1 ||
            urlAction.service.indexOf('hostgroups') > -1 && urlAction.service.indexOf('counts') > -1){
                if (urlAction.service.indexOf('scalablegroups') > -1){
                    var tempObj = PolicyAnalyticsTemplate.SGSummary_Template.groupViewList.groupList;    
                }else if(urlAction.service.indexOf('endpointclassification')> -1){
                    var tempObj = PolicyAnalyticsTemplate.endpoint_summary_Template.groupViewList.groupList;
                }else if(urlAction.service.indexOf('hostgroups')> -1){
                    var tempObj = PolicyAnalyticsTemplate.hostgroups_summary_Template.groupViewList.groupList;
                }
                var source = urlAction.filter.source
                source = source.replace(/[+]/g, ' ')
                var filRecord = tempObj.filter(e=>e.sourcegroup == source);
                var flow_count = filRecord[0].children.map(v=>v.uniqueflowcount).reduce((a,b)=>{return a+b;},0)
                var resp = {
                    "summaryResponse": {
                      "group_name": source,
                      "uniqueFlowCounts": flow_count,
                      "numOfCommDevices": 0,
                      "commGroupsCount": filRecord[0].children.length
                    },
                    "pageNo": 1,
                    "pageSize": 10,
                    "startTime": urlAction.filter.st,
                    "endTime": (urlAction.filter.st)+86400000,
                    "eventTime": UtilityFunctions.getTimeStamp()
                  }
                return resp;
            }else if(urlAction.service.indexOf('scalablegroups') > -1 && urlAction.service.indexOf('activitiesvisuals') > -1 ||
            urlAction.service.indexOf('endpointclassification') > -1 && urlAction.service.indexOf('activitiesvisuals') > -1 ||
            urlAction.service.indexOf('hostgroups') > -1 && urlAction.service.indexOf('activitiesvisuals') > -1){
                if (urlAction.service.indexOf('scalablegroups') > -1){
                    var tempObj = PolicyAnalyticsTemplate.SGSummary_Template.groupViewList.groupList;    
                }else if(urlAction.service.indexOf('endpointclassification')> -1){
                    var tempObj = PolicyAnalyticsTemplate.endpoint_summary_Template.groupViewList.groupList;
                }else if(urlAction.service.indexOf('hostgroups')> -1){
                    var tempObj = PolicyAnalyticsTemplate.hostgroups_summary_Template.groupViewList.groupList;
                }
                var source = urlAction.filter.source
                source = source.replace(/[+]/g, ' ')
                var filRecord = tempObj.filter(e=>e.sourcegroup == source);
                var flow_count = filRecord[0].children.map(v=>v.uniqueflowcount).reduce((a,b)=>{return a+b;},0)
                var activTemp = PolicyAnalyticsTemplate.SGActivitiesvisuals_Template;
                var childRecord = filRecord[0].children;
                var destGroup = []
                for(var i=0;i<childRecord.length;i++){
                    var destObj = {};
                    destObj.id = UtilityFunctions.generate_uuid();
                    destObj.name = childRecord[i].destination;
                    destObj.direction = urlAction.filter.direction;
                    destObj.numOfFlows = childRecord[i].uniqueflowcount;
                    destGroup.push(destObj);
                }
                activTemp.activitiesVisuals.source = source;
                activTemp.activitiesVisuals.destGroups = destGroup;
                activTemp.activitiesVisuals.totalNumOfGroups = childRecord.length;
                activTemp.startTime = urlAction.filter.st;
                activTemp.endTime = (urlAction.filter.st)+86400000;
                activTemp.eventTime = UtilityFunctions.getTimeStamp();
                return activTemp;
            
            }else if(urlAction.service.indexOf('scalablegroups') > -1 && urlAction.service.indexOf('activitiesdetails') > -1 ||
            urlAction.service.indexOf('endpointclassification') > -1 && urlAction.service.indexOf('activitiesdetails') > -1 ||
            urlAction.service.indexOf('hostgroups') > -1 && urlAction.service.indexOf('activitiesdetails') > -1){
                var tempObj = PolicyAnalyticsTemplate.SGActivityDetail_Template;
                // Commenting below code, it should be static not dynamic
                // var data = []
                // var random_num = UtilityFunctions.getRandomIntForRangeValues(1,9);
                // var randomObj = tempObj.activitiesDetails.activitiesList;
                // Array.prototype.random = function (length) {
                //     return this[Math.floor((Math.random()*length))];
                // }
                // for(var i=0;i<random_num;i++){
                //     var random_value = randomObj.random(randomObj.length);
                //     data.push(random_value)
                // }

                // tempObj.activitiesDetails.activitiesList = 
                var prevtime = (UtilityFunctions.getTimeStamp())-120000;
                tempObj.startTime = prevtime;
                tempObj.endTime = prevtime+10000;
                tempObj.eventTime = UtilityFunctions.getTimeStamp()
                tempObj.activitiesDetails.totalCount = tempObj.activitiesDetails.activitiesList.length;
                return tempObj
            }else if(urlAction.service.indexOf('scalablegroups') > -1 && urlAction.action.summary == true && urlAction.filter.type == "Classification"){
                var tempObj = PolicyAnalyticsTemplate.ScalableGroup_Filter_ISE_Classification;
                var prevtime = (UtilityFunctions.getTimeStamp())-120000;
                tempObj.startTime = prevtime;
                tempObj.endTime = prevtime+10000;
                tempObj.eventTime = UtilityFunctions.getTimeStamp()
                return tempObj
            }else if(urlAction.service.indexOf('scalablegroups') > -1 && urlAction.action.summary == true && urlAction.filter.type == "HostGroup"){
                var tempObj = PolicyAnalyticsTemplate.ScalableGroup_Filter_SW_HostGroup;
                var prevtime = (UtilityFunctions.getTimeStamp())-120000;
                tempObj.startTime = prevtime;
                tempObj.endTime = prevtime+10000;
                tempObj.eventTime = UtilityFunctions.getTimeStamp()
                return tempObj
            }else if(urlAction.service.indexOf('scalablegroups') > -1 && urlAction.action.summary == true){
                var tempObj = PolicyAnalyticsTemplate.SGSummary_Template;
                var prevtime = (UtilityFunctions.getTimeStamp())-120000;
                tempObj.startTime = prevtime;
                tempObj.endTime = prevtime+10000;
                tempObj.eventTime = UtilityFunctions.getTimeStamp()
                return tempObj

            }else if(urlAction.service.indexOf('scalablegroups') > -1){
                var tempObj  = PolicyAnalyticsTemplate.ScalableGroups_Template;
                var sgGroups = SimLokiDatabaseActions.getAll('scalablegroup');
                var sgnames = []
                for(var i=0;i<sgGroups.length;i++){
                    sgnames.push(sgGroups[i].name);
                } 
                tempObj.groups.data = sgnames;
                tempObj.groups.total = sgnames.length;
                tempObj.startTime = yestdate;
                tempObj.endTime = yestdate;
                tempObj.eventTime = yestdate;
                return tempObj
            }else if(urlAction.service.indexOf('endpointclassification') > -1 && urlAction.action.summary == true){
                var tempObj = PolicyAnalyticsTemplate.endpoint_summary_Template;
                var prevtime = (UtilityFunctions.getTimeStamp())-120000;
                tempObj.startTime = prevtime;
                tempObj.endTime = prevtime+10000;
                tempObj.eventTime = UtilityFunctions.getTimeStamp()
                return tempObj
            }else if(urlAction.service.indexOf('endpointclassification') > -1){
                var tempObj = PolicyAnalyticsTemplate.endpointclassification_Template;
                tempObj.startTime = yestdate;
                tempObj.endTime = yestdate;
                tempObj.eventTime = yestdate;
                return tempObj
            }else if(urlAction.service.indexOf('hostgroups') > -1 && urlAction.action.summary == true){
                var tempObj = PolicyAnalyticsTemplate.hostgroups_summary_Template;
                var prevtime = (UtilityFunctions.getTimeStamp())-120000;
                tempObj.startTime = prevtime;
                tempObj.endTime = prevtime+10000;
                tempObj.eventTime = UtilityFunctions.getTimeStamp()
                return tempObj
            }else if(urlAction.service.indexOf('hostgroups') > -1){
                var tempObj = PolicyAnalyticsTemplate.hostgroups_Template;
                tempObj.startTime = yestdate;
                tempObj.endTime = yestdate;
                tempObj.eventTime = yestdate;
                return tempObj
            // }else if(urlAction.service.indexOf('search') >-1 && urlAction.service.indexOf('groups') >-1){
            //     retrun 
            }else if((urlAction.service.indexOf('search') >-1 && urlAction.service.indexOf('allgroup') >-1) ||
            (urlAction.service.indexOf('search') >-1 && urlAction.service.indexOf('groups') >-1)){
                var resultObj = [];
                var tempObj1 = PolicyAnalyticsTemplate.SGSummary_Template.groupViewList.groupList;
                var tempObj2 = PolicyAnalyticsTemplate.endpoint_summary_Template.groupViewList.groupList;
                var tempObj3 = PolicyAnalyticsTemplate.hostgroups_summary_Template.groupViewList.groupList;
                var name = urlAction.url.split('==')[1].split('&')[0].toUpperCase()
                var scalablegroups = [];
                for(var i=0;i<tempObj1.length;i++){
                    if(tempObj1[i].sourcegroup.toUpperCase().indexOf(name) >-1){
                        scalablegroups.push(tempObj1[i].sourcegroup)
                    }
                }
                var endpointgroup = [];
                for(var i=0;i<tempObj2.length;i++){
                    if(tempObj2[i].sourcegroup.toUpperCase().indexOf(name) >-1){
                        endpointgroup.push(tempObj2[i].sourcegroup)
                    }
                }
                var hostgroups = [];
                for(var i=0;i<tempObj3.length;i++){
                    if(tempObj3[i].sourcegroup.toUpperCase().indexOf(name) >-1){
                        hostgroups.push(tempObj3[i].sourcegroup)
                    }
                }
                var result = {
                    "groupsSearchResponse": {scalablegroups,endpointgroup, hostgroups},
                    "pageNo": 1,
                    "pageSize": 3,
                    "startTime": urlAction.filter.st,
                    "endTime": (urlAction.filter.st)+86400000,
                    "eventTime": UtilityFunctions.getTimeStamp()
                }
                if(urlAction.service.indexOf('groups') >-1){
                    if(urlAction.filter.sortAsc == 'scalablegroups'){
                        result.groupsSearchResponse.scalablegroups = scalablegroups;
                    }else if(urlAction.filter.sortAsc == 'endpointclassification'){
                        result.groupsSearchResponse.endpointclassification = endpointgroup;
                    }else if(urlAction.filter.sortAsc == 'hostgroups'){
                        result.groupsSearchResponse.hostgroups = hostgroups;
                    }
                    result.startTime = yestdate;
                    result.endTime = UtilityFunctions.getTimeStamp();
                    result.eventTime = UtilityFunctions.getTimeStamp();
                }
                return result
            }
            if(urlAction.service.indexOf('groups')> -1 && urlAction.service.indexOf('policyassurance')>-1){
                var result = {
                    "policyAssuranceList": {
                      "groupList": [],
                      "totalCount": 0
                    },
                    "pageNo": 1,
                    "pageSize": 10,
                    "startTime": yestdate,
                    "endTime": UtilityFunctions.getTimeStamp(),
                    "eventTime": UtilityFunctions.getTimeStamp()
                  }
                  return result
            }
            //need to handle the call
            if(urlAction.service.indexOf('groups')> -1 && urlAction.service.indexOf('policyassurancetrend')>-1){
                var result = {
                    "policyAssuranceTrendingDataList": {
                      "groupList": [],
                      "totalCount": 0
                    },
                    "pageNo": 1,
                    "pageSize": 10,
                    "startTime": yestdate,
                    "endTime": UtilityFunctions.getTimeStamp(),
                    "eventTime": UtilityFunctions.getTimeStamp()
                  }
                  return result
            }
            if(urlAction.service.indexOf('pxgridsecuritygroup')> -1){
                return {"response":"session,securitygroup,deviceprofile,session-group-unmanaged,probe-data,upn","version":"1.0"}
            }
            if(urlAction.service.indexOf('trustsec')> -1){
                var tempObj  = PolicyAnalyticsTemplate.Overview_MOST_LEAST_Active_Policies;
                if(urlAction.service.indexOf('denycounts')>-1){
                    tempObj.policyAssuranceTopCountList.mostActiveGroupList[0].totalCount = 0;
                    tempObj.policyAssuranceTopCountList.leastActiveGroupList[0].totalCount = 0;
                }
                tempObj.startTime = yestdate;
                tempObj.endTime = UtilityFunctions.getTimeStamp();
                tempObj.eventTime = UtilityFunctions.getTimeStamp();
                return tempObj
            }
        },
        processPost: function (urlAction) {
            var yestdate = (UtilityFunctions.getTimeStamp())-86400000;
            var address = urlAction.restPayload.filter.valueFilter[0].values[0].name;
            if(urlAction.service.indexOf('search') > -1 && urlAction.service.indexOf('filter') > -1){
                var tempObj = PolicyAnalyticsTemplate.Search_Template;
                if(tempObj.endpointsList.data[0].destipaddress.indexOf(address) >-1){
                    tempObj.startTime = (urlAction.restPayload.filter.endts)-86400000;
                    tempObj.endTime = urlAction.restPayload.filter.endts;
                    tempObj.eventTime = UtilityFunctions.getTimeStamp();
                    return tempObj    
                }
                else{
                    return {}
                }
            }
        }
    }

});
