define({
"Template":

 {
  "request": {
    "key": "ms-sql-m",
    "type": "Application",
    "label": "ms-sql-m",
    "collection": false,
    "componentContent": [
      {
        "component": "fusion",
        "additional": "cf2cc9bb-0f41-47fe-85fd-42dde6cba939",
        "origType": "Application"
      }
    ]
  },
  "data": {
    "response": {
      "id": "cf2cc9bb-0f41-47fe-85fd-42dde6cba939",
      "instanceId": 4791,
      "displayName": "4791",
      "instanceVersion": 0,
      "createTime": 1565642661560,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1565642661560,
      "name": "ms-sql-m",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [],
      "type": "scalablegroup",
      "cfsChangeInfo": [],
      "customProvisions": [],
      "isDeleted": false,
      "iseReserved": false,
      "propagateToAci": false,
      "pushed": false,
      "scalableGroupExternalHandle": "ms-sql-m",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "50c060fe-8fc5-4057-b0e2-ca08b59af73a",
        "instanceId": 11011,
        "displayName": "11011",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "471cca21-6746-4f8d-8435-081ebd51b045",
          "instanceId": 15015,
          "displayName": "15015",
          "instanceVersion": 0,
          "ipv4Subnet": [],
          "ipv6Subnet": [],
          "lowerPort": 0,
          "ports": "1434",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "a0971608-70fd-4756-b2e3-4e6e59d87b6d",
          "instanceId": 15016,
          "displayName": "15016",
          "instanceVersion": 0,
          "ipv4Subnet": [],
          "ipv6Subnet": [],
          "lowerPort": 0,
          "ports": "1434",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "ce449a94-511d-4b65-ba9f-5d575a074ac4",
          "instanceId": 12740,
          "displayName": "12740",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "sqlsvr-group",
          "applicationSubType": "NONE",
          "applicationType": "DEFAULT",
          "categoryId": "5a6442b0-2f7c-4e56-93f7-6b0fd4c9e82e",
          "createTime": 1565642661560,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Microsoft-SQL-Monitor",
          "ignoreConflict": false,
          "lastUpdateTime": 1565642661560,
          "longDescription": "Microsoft SQL Monitor uses the SQL Server Resolution Protocol. It is an application-layer request/response protocol that facilitates connectivity to a database server and provides for Communication endpoint information and Database instance enumeration.",
          "name": "ms-sql-m",
          "nbarId": "685",
          "p2pTechnology": "false",
          "popularity": 7,
          "rank": 65535,
          "references": "http://msdn.microsoft.com/en-us/library/cc219703(v=prot.10)",
          "selectorId": "1434",
          "serverNames": [],
          "subCategory": "8433522e-cc49-428b-ac4e-208a335cfe05",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "c5182abb-76bb-48d9-9ae7-489e1fa0b1f1",
          "instanceId": 15013,
          "displayName": "15013",
          "instanceVersion": 0,
          "ipv4Subnet": [],
          "ipv6Subnet": [],
          "lowerPort": 0,
          "ports": "1434",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "5f381d25-1618-4657-82d8-2bd38dc3721b",
          "instanceId": 15014,
          "displayName": "15014",
          "instanceVersion": 0,
          "ipv4Subnet": [],
          "ipv6Subnet": [],
          "lowerPort": 0,
          "ports": "1434",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "777b58d8-f3d9-47dc-874d-2f03f8d015c7"
      },
      "user": [],
      "userGroup": []
    }
  },
  "result": [
    {
      "bookID": {
        "namespace": "fusion",
        "name": "core",
        "version": "1.0.1"
      },
      "response": {
        "id": "cf2cc9bb-0f41-47fe-85fd-42dde6cba939",
        "instanceId": 4791,
        "displayName": "4791",
        "instanceVersion": 0,
        "createTime": 1565642661560,
        "deployed": false,
        "isSeeded": true,
        "isStale": false,
        "lastUpdateTime": 1565642661560,
        "name": "ms-sql-m",
        "namespace": "scalablegroup:application",
        "provisioningState": "DEFINED",
        "qualifier": "application",
        "resourceVersion": 0,
        "targetIdList": [],
        "type": "scalablegroup",
        "cfsChangeInfo": [],
        "customProvisions": [],
        "isDeleted": false,
        "iseReserved": false,
        "propagateToAci": false,
        "pushed": false,
        "scalableGroupExternalHandle": "ms-sql-m",
        "scalableGroupType": "APPLICATION",
        "securityGroupTag": 0,
        "state": "ACTIVE",
        "vnAgnostic": true,
        "identitySource": {
          "id": "50c060fe-8fc5-4057-b0e2-ca08b59af73a",
          "instanceId": 11011,
          "displayName": "11011",
          "instanceVersion": 0,
          "state": "INACTIVE",
          "type": "NBAR"
        },
        "indicativeNetworkIdentity": [
          {
            "id": "471cca21-6746-4f8d-8435-081ebd51b045",
            "instanceId": 15015,
            "displayName": "15015",
            "instanceVersion": 0,
            "ipv4Subnet": [],
            "ipv6Subnet": [],
            "lowerPort": 0,
            "ports": "1434",
            "protocol": "TCP",
            "upperPort": 0
          },
          {
            "id": "a0971608-70fd-4756-b2e3-4e6e59d87b6d",
            "instanceId": 15016,
            "displayName": "15016",
            "instanceVersion": 0,
            "ipv4Subnet": [],
            "ipv6Subnet": [],
            "lowerPort": 0,
            "ports": "1434",
            "protocol": "UDP",
            "upperPort": 0
          }
        ],
        "networkApplications": [
          {
            "id": "ce449a94-511d-4b65-ba9f-5d575a074ac4",
            "instanceId": 12740,
            "displayName": "12740",
            "instanceVersion": 0,
            "appProtocol": "tcp/udp",
            "applicationGroup": "sqlsvr-group",
            "applicationSubType": "NONE",
            "applicationType": "DEFAULT",
            "categoryId": "5a6442b0-2f7c-4e56-93f7-6b0fd4c9e82e",
            "createTime": 1565642661560,
            "encrypted": "false",
            "engineId": "3",
            "helpString": "Microsoft-SQL-Monitor",
            "ignoreConflict": false,
            "lastUpdateTime": 1565642661560,
            "longDescription": "Microsoft SQL Monitor uses the SQL Server Resolution Protocol. It is an application-layer request/response protocol that facilitates connectivity to a database server and provides for Communication endpoint information and Database instance enumeration.",
            "name": "ms-sql-m",
            "nbarId": "685",
            "p2pTechnology": "false",
            "popularity": 7,
            "rank": 65535,
            "references": "http://msdn.microsoft.com/en-us/library/cc219703(v=prot.10)",
            "selectorId": "1434",
            "serverNames": [],
            "subCategory": "8433522e-cc49-428b-ac4e-208a335cfe05",
            "trafficClass": "TRANSACTIONAL_DATA",
            "tunnel": "false"
          }
        ],
        "networkIdentity": [
          {
            "id": "c5182abb-76bb-48d9-9ae7-489e1fa0b1f1",
            "instanceId": 15013,
            "displayName": "15013",
            "instanceVersion": 0,
            "ipv4Subnet": [],
            "ipv6Subnet": [],
            "lowerPort": 0,
            "ports": "1434",
            "protocol": "UDP",
            "upperPort": 0
          },
          {
            "id": "5f381d25-1618-4657-82d8-2bd38dc3721b",
            "instanceId": 15014,
            "displayName": "15014",
            "instanceVersion": 0,
            "ipv4Subnet": [],
            "ipv6Subnet": [],
            "lowerPort": 0,
            "ports": "1434",
            "protocol": "TCP",
            "upperPort": 0
          }
        ],
        "parentScalableGroup": {
          "idRef": "777b58d8-f3d9-47dc-874d-2f03f8d015c7"
        },
        "user": [],
        "userGroup": []
      }
    }
  ]


}
})