define({
    "Function_Capability_Template": [
        {
          "deviceId": "16732659-4d39-4108-9dac-7fd3a75e52af",
          "functionalCapability": [
            {
              "functionName": "etaCompatible",
              "functionOpState": "ENABLED",
              "functionDetails": [],
              "id": "02da8600-65a3-4342-878a-18b3d8946bac"
            },
            {
              "functionName": "etaNetConfOnlyDevice",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "2761588b-9412-4925-83b9-40c6d7421315"
            },
            {
              "functionName": "etaNbarEnabled",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "4379d9b9-ca60-480c-8e5e-4f838e4359a2"
            },
            {
              "functionName": "etaLoopback0Absent",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "042f5dc4-2d83-4576-9188-878804f9f1d2"
            },
            {
              "functionName": "etaSoftware",
              "functionOpState": "ENABLED",
              "functionDetails": [],
              "id": "6f841e2e-3e35-4968-bee4-82f66b934de4"
            },
            {
              "functionName": "etaCpuCapable",
              "functionOpState": "ENABLED",
              "functionDetails": [],
              "id": "a4e5f888-4b47-4f92-ac05-da097cf90724"
            },
            {
              "functionName": "etaEnabled",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "cd41d8a1-72a3-441e-9f84-91cbd1e668b8"
            },
            {
              "functionName": "etaNaaSOnly",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "d2bff1be-4394-4924-80d9-bbad24802d12"
            },
            {
              "functionName": "etaFnfBrownFieldConflict",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "e274c4d7-a24b-4899-a10a-4662421facfe"
            },
            {
              "functionName": "etaHardware",
              "functionOpState": "ENABLED",
              "functionDetails": [],
              "id": "faa65f0f-f4e8-4d0a-8815-5622438b9fdd"
            },
            {
              "functionName": "etaLicense",
              "functionOpState": "ENABLED",
              "functionDetails": [],
              "id": "ff85b787-41a9-4a73-ab52-54e8762fde70"
            }
          ]
        },
        {
          "deviceId": "2905730d-e48a-437c-b6c2-98635805433b",
          "functionalCapability": [
            {
              "functionName": "etaNbarEnabled",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "06d5f156-3b16-46fa-a3f5-97716b2f7058"
            },
            {
              "functionName": "etaLicense",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "459aaea4-79f6-4249-963e-a11b614ebfa1"
            },
            {
              "functionName": "etaNetConfOnlyDevice",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "766e998c-a16c-4e68-8b7e-55f759ecac08"
            },
            {
              "functionName": "etaCompatible",
              "functionOpState": "ENABLED",
              "functionDetails": [],
              "id": "81f0e9ad-14e1-483b-9298-8fd4ce9e6a9d"
            },
            {
              "functionName": "etaLoopback0Absent",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "042f5dc4-2d83-4576-9188-878804f9f1d2"
            },
            {
              "functionName": "etaHardware",
              "functionOpState": "ENABLED",
              "functionDetails": [],
              "id": "88ab7e02-d2f1-4ed0-a516-8c1a5728e11c"
            },
            {
              "functionName": "etaEnabled",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "a5644259-0355-49df-99c5-97e2edff54bb"
            },
            {
              "functionName": "etaSoftware",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "a7c91329-e13b-4617-9768-a9c64847c47d"
            },
            {
              "functionName": "etaNaaSOnly",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "b077a510-5b6e-4de2-9219-2b9274f82f4c"
            },
            {
              "functionName": "etaFnfBrownFieldConflict",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "b39a59aa-f1f2-4fdd-a2ab-881948f04564"
            },
            {
              "functionName": "etaCpuCapable",
              "functionOpState": "ENABLED",
              "functionDetails": [],
              "id": "f4adf457-16ec-4e51-9343-3e769c7157de"
            }
          ]
        },
        {
          "deviceId": "7b5b90a7-2a62-436c-9a77-a5ba835d0289",
          "functionalCapability": [
            
            {
              "functionName": "etaFnfBrownFieldConflict",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "38c40fee-3572-47a6-8020-fa91a4640361"
            },
            {
              "functionName": "etaLoopback0Absent",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "042f5dc4-2d83-4576-9188-878804f9f1d2"
            },
            {
              "functionName": "etaNaaSOnly",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "3b2cdaa7-346c-43a2-8e9b-8c6f42ffb460"
            },
            {
              "functionName": "etaHardware",
              "functionOpState": "ENABLED",
              "functionDetails": [],
              "id": "4fb7ed7a-3e81-4001-974d-36a3c15faec9"
            },
            {
              "functionName": "etaCompatible",
              "functionOpState": "ENABLED",
              "functionDetails": [],
              "id": "98b040d5-ec1e-4879-9180-76e3b1658b01"
            },
            {
              "functionName": "etaNetConfOnlyDevice",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "aeedbbd0-5f7f-4a5e-b5bc-edcd958a5a71"
            },
            {
              "functionName": "etaSoftware",
              "functionOpState": "ENABLED",
              "functionDetails": [],
              "id": "b074905d-e7a0-498e-8d82-ed11dd4779ff"
            },
            {
              "functionName": "etaLicense",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "b2e85050-5e10-4637-864e-42245d860161"
            },
            {
              "functionName": "etaEnabled",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "b5adef86-06f7-4fc8-9e21-1df4208bc108"
            },
            {
              "functionName": "etaNbarEnabled",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "c82ccd74-ff5d-4681-8158-0316731838f7"
            },
            {
              "functionName": "etaCpuCapable",
              "functionOpState": "ENABLED",
              "functionDetails": [],
              "id": "eb97941b-1324-4d75-8d4d-db6fbb992921"
            }
          ]
        },
        {
          "deviceId": "8252aa4c-71f6-4747-a093-d235bef1c854",
          "functionalCapability": [
            {
              "functionName": "etaLicense",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "23664c88-3beb-4366-9ff6-467d423a6354"
            },
            {
              "functionName": "etaLoopback0Absent",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "042f5dc4-2d83-4576-9188-878804f9f1d2"
            },
            {
              "functionName": "etaCompatible",
              "functionOpState": "ENABLED",
              "functionDetails": [],
              "id": "3a854dad-45b2-48fd-9d97-1da9bbf99fa8"
            },
            {
              "functionName": "etaEnabled",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "582953e4-5c63-4689-96f1-17a431ca1da2"
            },
            {
              "functionName": "etaNbarEnabled",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "a165eeb8-01e3-4b54-9e86-5f104bc15d8d"
            },
            {
              "functionName": "etaSoftware",
              "functionOpState": "ENABLED",
              "functionDetails": [],
              "id": "ac610069-8923-40ed-97f3-f07ae84a09df"
            },
            {
              "functionName": "etaCpuCapable",
              "functionOpState": "ENABLED",
              "functionDetails": [],
              "id": "bcff212f-2a97-48b9-b5e4-bcc9416235e8"
            },
            {
              "functionName": "etaNetConfOnlyDevice",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "c6d814a5-6d99-4957-97e7-7c278c85a496"
            },
            {
              "functionName": "etaHardware",
              "functionOpState": "ENABLED",
              "functionDetails": [],
              "id": "c705c68f-4da4-4323-a5df-d6ed8ee45974"
            },
            
            {
              "functionName": "etaNaaSOnly",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "e0d5efdd-0696-4510-8997-3af5312a0c16"
            },
            {
              "functionName": "etaFnfBrownFieldConflict",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "fdb1b2d4-e993-4075-b7b8-1f4a3ed95ff2"
            }
          ]
        },
        {
          "deviceId": "9ac3b915-4b38-466c-8757-5747267bdb52",
          "functionalCapability": [
            
            {
              "functionName": "etaNaaSOnly",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "231922a1-773b-4fb8-8f87-176fa5eee47a"
            },
            {
              "functionName": "etaLoopback0Absent",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "042f5dc4-2d83-4576-9188-878804f9f1d2"
            },
            {
              "functionName": "etaCpuCapable",
              "functionOpState": "ENABLED",
              "functionDetails": [],
              "id": "634f624b-5a1b-4301-bd84-46c262e87a5b"
            },
            {
              "functionName": "etaLicense",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "7f41ac1c-57d9-4e71-a8ad-446346fa9cad"
            },
            {
              "functionName": "etaEnabled",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "81160c2d-8e31-4c8e-a5d7-5a43a634eed0"
            },
            {
              "functionName": "etaSoftware",
              "functionOpState": "ENABLED",
              "functionDetails": [],
              "id": "9e50f5f0-0fd6-44ab-8646-09845f4f6abb"
            },
            {
              "functionName": "etaNetConfOnlyDevice",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "b329ec18-57a8-4b1c-8395-4c4a2ace8b28"
            },
            {
              "functionName": "etaFnfBrownFieldConflict",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "b666b6be-52fe-450e-82b1-421b51842f4e"
            },
            {
              "functionName": "etaHardware",
              "functionOpState": "ENABLED",
              "functionDetails": [],
              "id": "cdfd7a60-2269-44ef-8e05-c2fc95649982"
            },
            {
              "functionName": "etaNbarEnabled",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "dca060f1-6ac0-4988-b202-222b6fe807ee"
            },
            {
              "functionName": "etaCompatible",
              "functionOpState": "ENABLED",
              "functionDetails": [],
              "id": "f96060e8-270b-473e-ab33-28fd3fa67552"
            }
          ]
        },
        {
          "deviceId": "faec7f9d-612d-4750-b521-533c8db52361",
          "functionalCapability": [
            {
              "functionName": "etaNetConfOnlyDevice",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "387b31f9-a26f-46c6-b6c1-7d4dd4788c30"
            },
            {
              "functionName": "etaLoopback0Absent",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "042f5dc4-2d83-4576-9188-878804f9f1d2"
            },
            {
              "functionName": "etaCpuCapable",
              "functionOpState": "ENABLED",
              "functionDetails": [],
              "id": "64644d59-ec57-4ba4-9d83-a61946d379ac"
            },
            {
              "functionName": "etaCompatible",
              "functionOpState": "ENABLED",
              "functionDetails": [],
              "id": "6c2aa354-4e47-4d93-bf40-6042bd47e0da"
            },
            {
              "functionName": "etaLicense",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "7ad7c8a8-f48c-45f8-8d58-ef10e7eddabf"
            },
            {
              "functionName": "etaHardware",
              "functionOpState": "ENABLED",
              "functionDetails": [],
              "id": "91fdd95d-f041-4bef-9d11-cdcf76494e32"
            },
            {
              "functionName": "etaEnabled",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "99939799-3dbd-4375-956f-bf59da66e627"
            },
            {
              "functionName": "etaNaaSOnly",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "a62c58a9-1699-473d-8515-3ad086cb8c40"
            },
            {
              "functionName": "etaFnfBrownFieldConflict",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "c29ef330-c441-4584-aa0d-ac0b5f0029d9"
            },
            {
              "functionName": "etaNbarEnabled",
              "functionOpState": "DISABLED",
              "functionDetails": [],
              "id": "deb0f12f-5c98-46c4-9f4e-78fb113f2b37"
            },
            {
              "functionName": "etaSoftware",
              "functionOpState": "ENABLED",
              "functionDetails": [],
              "id": "eeecc9ce-2255-40b9-b94f-2f026003174f"
            }
          ]
        }
      ]
    })