define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/servicedepolyment/ServiceDeploymentStatusTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions'
], function (SimLokiDatabaseActions, ServiceDeploymentStatusTemplate,UtilityFunctions) {

    return {
        init: function() {
        },

        doProcessRestRequest: function(urlAction){
            console.log(urlAction.url);
            console.log(urlAction);
            var data;
            switch(urlAction.method) {
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                case 'POST':
                    break;

                case 'PUT':
                    break;

                case 'DELETE':
                    break;

                default:
                    break;
            }

            return data;
        }
     };
     //show task status in fabric page
    function processGetRequest(urlAction){
        if(urlAction.service.indexOf('service-deployment-status') >= 0 ){
            if(urlAction.action.id == '') {
                var domainInfo=[];
                if(urlAction.filter.namespace){
                var namespace = urlAction.filter.namespace;
                domainInfo = SimLokiDatabaseActions.getFilteredRecordHandler("ConnectivityDomain", {'id':namespace});
                }
                else if(urlAction.filter.indexOf('taskId')>-1){
                    var namespace="";
                    var taskId=urlAction.filter.taskId;
                    var jobList=SimLokiDatabaseActions.getAll("scheduled-job");
                    for(let i=0;i<jobList.length;i++){
                        if(jobList[i].taskId===taskId){
                            namespace=jobList[i].externalSchedule.notificationBody[0].id;
                        }
                        else if(jobList[i].triggeredJobs.length>0){
                            for(let j=0;j<jobList[i].triggeredJobs.length;j++){
                                if(jobList[i].triggeredJobs[j].taskId===taskId){
                                    namespace=jobList[i].externalSchedule.notificationBody[0].id;
                                }
                            }
                        }
                    }
                    domainInfo=SimLokiDatabaseActions.getFilteredRecordHandler("ConnectivityDomain", {'id':namespace});
                }
                var data = [], tjson = [];
                var siteName = domainInfo.length>0 ? domainInfo[0].name:'';
                //show task status for SJC01 only. For other fabrics, show empty task status
                var fabricSite = 'SJC01_SanJose_Fabric', fabricDevice = 'BLD1-FLR2-ACCESS';
                if(siteName.indexOf(fabricSite) > -1) {
                    tjson = JSON.parse(JSON.stringify(ServiceDeploymentStatusTemplate.Service_Deployment_Status_Template));
                    for(let i=0; i<tjson.length;i++){
                        var provisionDesc = tjson[i].provisioningDescription;
                        provisionDesc = provisionDesc.replace(/Site@ToChange/g, siteName);
                        provisionDesc = provisionDesc.replace(/Device@ToChange/g, fabricDevice);
                        tjson[i].id = UtilityFunctions.generate_uuid();
                        tjson[i].provisioningDescription = provisionDesc;
                        tjson[i].createTime = new Date().getTime()-(50000);
                        tjson[i].lastUpdateTime = new Date().getTime();
                        tjson[i].namespace = urlAction.filter.namespace;
                        tjson[i].taskStarttime = new Date().getTime()-(60000);
                        tjson[i].taskEndtime = new Date().getTime();
                        tjson[i].workflowId = UtilityFunctions.generate_uuid();
                    }
                } else {
                    tjson = [];
                }
                return tjson;
            }
        }
    }


});
