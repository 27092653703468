define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/activityLog/activityLogTemplate'

    ],function(UtilityFunctions,SimLokiDatabaseActions, activityLogTemplate) {

    
    return {
        init: function() {
            createActivityLogData();
        }
    };
    // added by Karan for Activity Log 2.0/ 1.4
    function createActivityLogData() {
        var tempObj = JSON.parse(JSON.stringify(activityLogTemplate.Activity_admin_Log_Template));
        SimLokiDatabaseActions.insert('sim-activity-log', tempObj);
    }
});
