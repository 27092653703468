define([
    'core/../../../public/libs/node_modules/moment',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/kairos/SimEnhancedRRMTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/kairos/SimEnhancedRRMTemplate24ghz',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/kairos/SimEnhancedRRMTemplate_6ghz',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/kairos/SimEnhancedRRMTemplate_Simulation'

], function (moment,SimLokiDatabaseActions, UtilityFunctions, SimTaskDataGenerator, SimEnhancedRRMTemplate,
    SimEnhancedRRMTemplate24ghz, SimEnhancedRRMTemplate_6ghz, SimEnhancedRRMTemplate_Simulation) {
    return{

    getRfCoverageSummaryLatest01: function(variables){
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'name':'Flr-SJC1-1'});
        var siteId = siteData[0].id;
        var deviceData = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and':[{'siteId':siteId},{'family':"Unified AP"}]});
        var tempList = [];
        if(variables.frequencyBand == 3){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate_6ghz.getRfCoverageSummaryLatest01_6ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getRfCoverageSummaryLatest01":{"nodes":tempList}}};
        }
        else if(variables.frequencyBand == 2){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getRfCoverageSummaryLatest01_5ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getRfCoverageSummaryLatest01":{"nodes":tempList}}};
        }else if(variables.frequencyBand == 1){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate24ghz.getRfCoverageSummaryLatest01_24ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getRfCoverageSummaryLatest01":{"nodes":tempList}}};
        }
    },
    getRfPerformanceSummaryLatest01: function(variables){
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'name':'Flr-SJC1-1'});
        var siteId = siteData[0].id;
        var deviceData = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and':[{'siteId':siteId},{'family':"Unified AP"}]});
        var tempList = [];
        if(variables.frequencyBand == 3){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate_6ghz.getRfPerformanceSummaryLatest01_6ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getRfPerformanceSummaryLatest01":{"nodes":tempList}}};
        }
        else if(variables.frequencyBand == 2){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getRfPerformanceSummaryLatest01_5ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getRfPerformanceSummaryLatest01":{"nodes":tempList}}};
        }else if(variables.frequencyBand == 1){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate24ghz.getRfPerformanceSummaryLatest01_24ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getRfPerformanceSummaryLatest01":{"nodes":tempList}}};
        }   
    },
    getCurrentInsights01: function(variables){
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'name':'Flr-SJC1-1'});
        var siteId = siteData[0].id;
        var deviceData = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and':[{'siteId':siteId},{'family':"Unified AP"}]});
        var tempList = [];
        if(variables.frequencyBand == 3){
            var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate_6ghz.getCurrentInsights01_6ghz_Template));
            for(var i=0;i<tempObj.length;i++){
                tempObj[i].buildingId = variables.buildingId;
                tempObj[i].siteId = siteId;
                tempObj[i].timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj[i].timestampMs = new Date().getTime().toString();
                tempList.push(tempObj[i]);
            }
            return {"data":{"getCurrentInsights01":{"nodes":tempList}}}; 
        }
        else{
            var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getCurrentInsights01_5ghz_Template));
            for(var i=0;i<tempObj.length;i++){
                tempObj[i].frequencyBand = variables.frequencyBand;
                tempObj[i].buildingId = variables.buildingId;
                tempObj[i].siteId = siteId;
                tempObj[i].timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj[i].timestampMs = new Date().getTime().toString();
                tempList.push(tempObj[i]);
            }
            return {"data":{"getCurrentInsights01":{"nodes":tempList}}}; 
        }
    },
    getRrmChangesLatestSummaryV201: function(variables){
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'name':'Flr-SJC1-1'});
        var siteId = siteData[0].id;
        var deviceData = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and':[{'siteId':siteId},{'family':"Unified AP"}]});
        var tempList = [];
        if(variables.isSimulation == 1 && variables.baseRequestId != 0){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate_Simulation.getRrmChangesLatestSummaryV201_Sim_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getRrmChangesLatestSummaryV201":{"nodes":tempList}}};
        }
        else if(variables.frequencyBand == 3){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate_6ghz.getRrmChangesLatestSummaryV201_6ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getRrmChangesLatestSummaryV201":{"nodes":tempList}}};
        }
        else if(variables.frequencyBand == 2){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getRrmChangesLatestSummaryV201_5ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getRrmChangesLatestSummaryV201":{"nodes":tempList}}};
        }else if(variables.frequencyBand == 1){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate24ghz.getRrmChangesLatestSummaryV201_24ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getRrmChangesLatestSummaryV201":{"nodes":tempList}}};
        }   
    },
    // need to review below code and remove in upcoming releases
    getRrmChangesLatestSummary01: function(variables){
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'name':'Flr-SJC1-1'});
        var siteId = siteData[0].id;
        var deviceData = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and':[{'siteId':siteId},{'family':"Unified AP"}]});
        var tempList = [];
        if(variables.frequencyBand == 2){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getRrmChangesLatestSummaryV201_5ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getRrmChangesLatestSummary01":{"nodes":tempList}}};
        }else{
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate24ghz.getRrmChangesLatestSummaryV201_24ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getRrmChangesLatestSummary01":{"nodes":tempList}}};
        }
    },
    getRrmHealthLatestSummary01: function(variables){
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'name':'Flr-SJC1-1'});
        var siteId = siteData[0].id;
        var deviceData = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and':[{'siteId':siteId},{'family':"Unified AP"}]});
        var tempList = [];
        if(variables.isSimulation == 1 && variables.baseRequestId != 0){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate_Simulation.getRrmHealthLatestSummary01_Sim_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getRrmHealthLatestSummary01":{"nodes":tempList}}};
        }
        else if(variables.frequencyBand == 3){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate_6ghz.getRrmHealthLatestSummary01_6ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getRrmHealthLatestSummary01":{"nodes":tempList}}};
        }
        else if(variables.frequencyBand == 2){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getRrmHealthLatestSummary01_5ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getRrmHealthLatestSummary01":{"nodes":tempList}}};
        }else if(variables.frequencyBand == 1){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate24ghz.getRrmHealthLatestSummary01_24ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getRrmHealthLatestSummary01":{"nodes":tempList}}};
        }
    },
    getFraLatestDetail01: function(variables){
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'name':'Flr-SJC1-1'});
        var siteId = siteData[0].id;
        var deviceData = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and':[{'siteId':siteId},{'family':"Unified AP"}]});
        var tempList = [];
        if (variables.radioType == "5 GHz") {
            for (var i=0;i<10;i++) {
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getFraLatestDetail01_5GHZ_Template));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.radioType = variables.radioType;
                tempObj.memberId = variables.memberId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return { "data": { "getFraLatestDetail01": { "nodes": tempList } } };
        }
        else if (variables.radioType == "2.4 GHz") {
            for (var i=11;i<deviceData.length;i++) {
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getFraLatestDetail01_5GHZ_Template));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.radioType = variables.radioType;
                tempObj.memberId = variables.memberId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return { "data": { "getFraLatestDetail01": { "nodes": tempList } } };
        }
    },
    getFraLatestSummary01: function(variables){
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'name':'Flr-SJC1-1'});
        var siteId = siteData[0].id;
        var deviceData = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and':[{'siteId':siteId},{'family':"Unified AP"}]});
        var tempList = [];
        if(variables.frequencyBand == 3){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate_6ghz.getFraLatestSummary01_6ghz_Template));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getFraLatestSummary01":{"nodes":tempList}}};
        }
        else if(variables.frequencyBand == 2){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getFraLatestSummary01_5ghz_Template));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getFraLatestSummary01":{"nodes":tempList}}};
        }else if(variables.frequencyBand == 1){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate24ghz.getFraLatestSummary01_24ghz_Template));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getFraLatestSummary01":{"nodes":tempList}}};
        }
    },
    getFraTrendSummary01: function(variables){
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'name':'Flr-SJC1-1'});
        var siteId = siteData[0].id;
        var deviceData = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and':[{'siteId':siteId},{'family':"Unified AP"}]});
        var tempList = [];
        if(variables.frequencyBand == 3){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate_6ghz.getFraLatestSummary01_6ghz_Template));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getFraTrendSummary01":{"nodes":tempList}}};
        }
        else if(variables.frequencyBand == 2){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getFraLatestSummary01_5ghz_Template));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getFraTrendSummary01":{"nodes":tempList}}};
        }else if(variables.frequencyBand == 1){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate24ghz.getFraLatestSummary01_24ghz_Template));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getFraTrendSummary01":{"nodes":tempList}}};
        }
    },
    getCoChannelInterferenceLatestSummary01: function(variables){
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'name':'Flr-SJC1-1'});
        var siteId = siteData[0].id;
        var deviceData = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and':[{'siteId':siteId},{'family':"Unified AP"}]});
        var tempList = [];
        if(variables.isSimulation == 1 && variables.baseRequestId != 0){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate_Simulation.getCoChannelInterferenceLatestSummary01_Sim_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getCoChannelInterferenceLatestSummary01":{"nodes":tempList}}};
        }
        if(variables.frequencyBand == 3){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate_6ghz.getCoChannelInterferenceLatestSummary01_6ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getCoChannelInterferenceLatestSummary01":{"nodes":tempList}}};
        }
        else if(variables.frequencyBand == 2){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getCoChannelInterferenceLatestSummary01_5ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getCoChannelInterferenceLatestSummary01":{"nodes":tempList}}};
        }else if(variables.frequencyBand == 1){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate24ghz.getCoChannelInterferenceLatestSummary01_24ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getCoChannelInterferenceLatestSummary01":{"nodes":tempList}}};
        }
    },
    getUtilizationPerChannelLatestSummary01: function(variables){
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'name':'Flr-SJC1-1'});
        var siteId = siteData[0].id;
        var deviceData = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and':[{'siteId':siteId},{'family':"Unified AP"}]});
        var tempList = [];
        if(variables.frequencyBand == 3){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate_6ghz.getUtilizationPerChannelLatestSummary01_6ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getUtilizationPerChannelLatestSummary01":{"nodes":tempList}}};
        }
        else if(variables.frequencyBand == 2){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getUtilizationPerChannelLatestSummary01_5ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getUtilizationPerChannelLatestSummary01":{"nodes":tempList}}};
        }else if(variables.frequencyBand == 1){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate24ghz.getUtilizationPerChannelLatestSummary01_24ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getUtilizationPerChannelLatestSummary01":{"nodes":tempList}}};
        }
    },
    getApRogueAndRadarPerChannelLatestSummary01: function(variables){
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'name':'Flr-SJC1-1'});
        var siteId = siteData[0].id;
        var deviceData = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and':[{'siteId':siteId},{'family':"Unified AP"}]});
        var tempList = [];
        if(variables.frequencyBand == 3){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate_6ghz.getApRogueAndRadarPerChannelLatestSummary01_6ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getApRogueAndRadarPerChannelLatestSummary01":{"nodes":tempList}}};
        }
        else if(variables.frequencyBand == 2){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getApRogueAndRadarPerChannelLatestSummary01_5ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getApRogueAndRadarPerChannelLatestSummary01":{"nodes":tempList}}};
        }else if(variables.frequencyBand == 1){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate24ghz.getApRogueAndRadarPerChannelLatestSummary01_24ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getApRogueAndRadarPerChannelLatestSummary01":{"nodes":tempList}}};
        }
    },
    getApSpatialDensityLatestSummary01: function(variables){
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'name':'Flr-SJC1-1'});
        var siteId = siteData[0].id;
        var deviceData = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and':[{'siteId':siteId},{'family':"Unified AP"}]});
        var tempList = [];
        if(variables.frequencyBand == 3){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate_6ghz.getApSpatialDensityLatestSummary01_6ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getApSpatialDensityLatestSummary01":{"nodes":tempList}}};
        }
        else if(variables.frequencyBand == 2){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getApSpatialDensityLatestSummary01_5ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getApSpatialDensityLatestSummary01":{"nodes":tempList}}};
        }else if(variables.frequencyBand == 1){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate24ghz.getApSpatialDensityLatestSummary01_24ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }   
            return {"data":{"getApSpatialDensityLatestSummary01":{"nodes":tempList}}};
        }
    },
    getPowerDistributionLatestSummary01: function(variables){   
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'name':'Flr-SJC1-1'});
        var siteId = siteData[0].id;
        var deviceData = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and':[{'siteId':siteId},{'family':"Unified AP"}]});
        var tempList = [];
        if(variables.frequencyBand == 3){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate_6ghz.getPowerDistributionLatestSummary01_6ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getPowerDistributionLatestSummary01":{"nodes":tempList}}}; 
        }
        else if(variables.frequencyBand == 2){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getPowerDistributionLatestSummary01_5ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getPowerDistributionLatestSummary01":{"nodes":tempList}}}; 
        }else if(variables.frequencyBand == 1){     
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate24ghz.getPowerDistributionLatestSummary01_24ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getPowerDistributionLatestSummary01":{"nodes":tempList}}};
        }
    },
    getApSpatialDensityTrendSummary01: function(variables){
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'id':variables.buildingId});
        var curTime = new Date().getTime()
        var lastOccurenceTime = curTime-(30*30*1000)
        var incrementTime = 0;
        if(variables.frequencyBand == 2){
            var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getApSpatialDensityTrendSummary01_5ghz_Template));
            for (var i=0;i<tempObj.length;i++){
                tempObj[i].buildingId = variables.buildingId;
                tempObj[i].siteId = siteData[0].parentId;
                var t1 = UtilityFunctions.incrementDateByMinutes(lastOccurenceTime, incrementTime);
                incrementTime -= 30;
                tempObj[i].timestamp = moment(t1).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj[i].timestampMs = new Date(t1).getTime().toString();
            }
            tempObj = tempObj.reverse()
            return {"data":{"getApSpatialDensityTrendSummary01":{"nodes":tempObj}}};  
        }else{
            var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate24ghz.getApSpatialDensityTrendSummary01_24ghz_Template));
            for (var i=0;i<tempObj.length;i++){
                tempObj[i].buildingId = variables.buildingId;
                tempObj[i].siteId = siteData[0].parentId;
                var t1 = UtilityFunctions.incrementDateByMinutes(lastOccurenceTime, incrementTime);
                incrementTime -= 30;
                tempObj[i].timestamp = moment(t1).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj[i].timestampMs = new Date(t1).getTime().toString();
            }       
            tempObj = tempObj.reverse()
            return {"data":{"getApSpatialDensityTrendSummary01":{"nodes":tempObj}}};
        } 
    },
    getCoChannelInterferenceTrendSummary01: function(variables){
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'id':variables.buildingId});
        var curTime = new Date().getTime()
        var lastOccurenceTime = curTime-(30*30*1000)
        var incrementTime = 0;
        if(variables.frequencyBand == 3){
            var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate_6ghz.getCoChannelInterferenceTrendSummary01_6ghz_Template));
            for (var i=0;i<tempObj.length;i++){
                tempObj[i].buildingId = variables.buildingId;
                tempObj[i].siteId = siteData[0].parentId;
                var t1 = UtilityFunctions.incrementDateByMinutes(lastOccurenceTime, incrementTime);
                incrementTime -= 30;
                tempObj[i].timestamp = moment(t1).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj[i].timestampMs = new Date(t1).getTime().toString();
            }
            tempObj = tempObj.reverse()
            return {"data":{"getCoChannelInterferenceTrendSummary01":{"nodes":tempObj}}}; 
        }
        else if(variables.frequencyBand == 2){
            var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getCoChannelInterferenceTrendSummary01_5ghz_Template));
            for (var i=0;i<tempObj.length;i++){
                tempObj[i].buildingId = variables.buildingId;
                tempObj[i].siteId = siteData[0].parentId;
                var t1 = UtilityFunctions.incrementDateByMinutes(lastOccurenceTime, incrementTime);
                incrementTime -= 30;
                tempObj[i].timestamp = moment(t1).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj[i].timestampMs = new Date(t1).getTime().toString();
            }
            tempObj = tempObj.reverse()
            return {"data":{"getCoChannelInterferenceTrendSummary01":{"nodes":tempObj}}}; 
        }else if(variables.frequencyBand == 1){
            var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate24ghz.getCoChannelInterferenceTrendSummary01_24ghz_Template));
            for (var i=0;i<tempObj.length;i++){
                tempObj[i].buildingId = variables.buildingId;
                tempObj[i].siteId = siteData[0].parentId;
                var t1 = UtilityFunctions.incrementDateByMinutes(lastOccurenceTime, incrementTime);
                incrementTime -= 30;
                tempObj[i].timestamp = moment(t1).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj[i].timestampMs = new Date(t1).getTime().toString();
            }
            tempObj = tempObj.reverse()
            return {"data":{"getCoChannelInterferenceTrendSummary01":{"nodes":tempObj}}};
        }   
    },
    getPowerDistributionTrendSummary01: function(variables){
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'id':variables.buildingId});
        var curTime = new Date().getTime()
        var lastOccurenceTime = curTime-(30*30*1000)
        var incrementTime = 0;
        if(variables.frequencyBand == 2){
            var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getPowerDistributionTrendSummary01_5ghz_Template));
            for (var i=0;i<tempObj.length;i++){
                tempObj[i].buildingId = variables.buildingId;
                tempObj[i].siteId = siteData[0].parentId;
                var t1 = UtilityFunctions.incrementDateByMinutes(lastOccurenceTime, incrementTime);
                incrementTime -= 30;
                tempObj[i].timestamp = moment(t1).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj[i].timestampMs = new Date(t1).getTime().toString();
            }
            return {"data":{"getPowerDistributionTrendSummary01":{"nodes":tempObj}}};
        }else{
            var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate24ghz.getPowerDistributionTrendSummary01_24ghz_Template));
            for (var i=0;i<tempObj.length;i++){
                tempObj[i].buildingId = variables.buildingId;
                tempObj[i].siteId = siteData[0].parentId;
                var t1 = UtilityFunctions.incrementDateByMinutes(lastOccurenceTime, incrementTime);
                incrementTime -= 30;
                tempObj[i].timestamp = moment(t1).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj[i].timestampMs = new Date(t1).getTime().toString();
            }
            tempObj = tempObj.reverse()
            return {"data":{"getPowerDistributionTrendSummary01":{"nodes":tempObj}}};
        }   
    },
    getRrmChangesTrendSummaryV201: function(variables){
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'id':variables.buildingId});
        var curTime = new Date().getTime()
        var lastOccurenceTime = curTime-(30*30*1000)
        var incrementTime = 0;
        if(variables.frequencyBand == 2){
            var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getRrmChangesTrendSummaryV201_5ghz_Template));
            for (var i=0;i<tempObj.length;i++){
                tempObj[i].buildingId = variables.buildingId;
                tempObj[i].siteId = siteData[0].parentId;
                var t1 = UtilityFunctions.incrementDateByMinutes(lastOccurenceTime, incrementTime);
                incrementTime -= 30;
                tempObj[i].timestamp = moment(t1).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj[i].timestampMs = new Date(t1).getTime().toString();
            }
            tempObj = tempObj.reverse()
            return {"data":{"getRrmChangesTrendSummaryV201":{"nodes":tempObj}}};
        }else{
            var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate24ghz.getRrmChangesTrendSummaryV201_24ghz_Template));
            for (var i=0;i<tempObj.length;i++){
                tempObj[i].buildingId = variables.buildingId;
                tempObj[i].siteId = siteData[0].parentId;
                var t1 = UtilityFunctions.incrementDateByMinutes(lastOccurenceTime, incrementTime);
                incrementTime -= 30;
                tempObj[i].timestamp = moment(t1).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj[i].timestampMs = new Date(t1).getTime().toString();
            }
            tempObj = tempObj.reverse()
            return {"data":{"getRrmChangesTrendSummaryV201":{"nodes":tempObj}}};
        }
    },
    getRrmChangesTrendSummary01: function(variables){
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'id':variables.buildingId});
        var curTime = new Date().getTime()
        var lastOccurenceTime = curTime-(30*30*1000)
        var incrementTime = 0;
        if(variables.frequencyBand == 2){
            var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getRrmChangesTrendSummaryV201_5ghz_Template));
            for (var i=0;i<tempObj.length;i++){
                tempObj[i].buildingId = variables.buildingId;
                tempObj[i].siteId = siteData[0].parentId;
                var t1 = UtilityFunctions.incrementDateByMinutes(lastOccurenceTime, incrementTime);
                incrementTime -= 30;
                tempObj[i].timestamp = moment(t1).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj[i].timestampMs = new Date(t1).getTime().toString();
            }
            return {"data":{"getRrmChangesTrendSummary01":{"nodes":tempObj}}};  
        }else{
            var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate24ghz.getRrmChangesTrendSummaryV201_24ghz_Template));
            for (var i=0;i<tempObj.length;i++){
                tempObj[i].buildingId = variables.buildingId;
                tempObj[i].siteId = siteData[0].parentId;
                var t1 = UtilityFunctions.incrementDateByMinutes(lastOccurenceTime, incrementTime);
                incrementTime -= 30;
                tempObj[i].timestamp = moment(t1).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj[i].timestampMs = new Date(t1).getTime().toString();
            }
            return {"data":{"getRrmChangesTrendSummary01":{"nodes":tempObj}}}; 
        }
    },
    getRrmHealthTrendSummary01: function(variables){
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'id':variables.buildingId});
        var curTime = new Date().getTime()
        var lastOccurenceTime = curTime-(30*30*1000)
        var incrementTime = 0;
        if(variables.frequencyBand == 3){
            var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate_6ghz.getRrmHealthTrendSummary01_6ghz_Template));
            for (var i=0;i<tempObj.length;i++){
                tempObj[i].buildingId = variables.buildingId;
                tempObj[i].siteId = siteData[0].parentId;
                var t1 = UtilityFunctions.incrementDateByMinutes(lastOccurenceTime, incrementTime);
                incrementTime -= 30;
                tempObj[i].timestamp = moment(t1).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj[i].timestampMs = new Date(t1).getTime().toString();
            }
            tempObj = tempObj.reverse()
            return {"data":{"getRrmHealthTrendSummary01":{"nodes":tempObj}}}; 
        }
        else if(variables.frequencyBand == 2){
            var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getRrmHealthTrendSummary01_5ghz_Template));
            for (var i=0;i<tempObj.length;i++){
                tempObj[i].buildingId = variables.buildingId;
                tempObj[i].siteId = siteData[0].parentId;
                var t1 = UtilityFunctions.incrementDateByMinutes(lastOccurenceTime, incrementTime);
                incrementTime -= 30;
                tempObj[i].timestamp = moment(t1).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj[i].timestampMs = new Date(t1).getTime().toString();
            }
            tempObj = tempObj.reverse()
            return {"data":{"getRrmHealthTrendSummary01":{"nodes":tempObj}}}; 
        }else if(variables.frequencyBand == 1){
            var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate24ghz.getRrmHealthTrendSummary01_24ghz_Template));
            for (var i=0;i<tempObj.length;i++){
                tempObj[i].buildingId = variables.buildingId;
                tempObj[i].siteId = siteData[0].parentId;
                var t1 = UtilityFunctions.incrementDateByMinutes(lastOccurenceTime, incrementTime);
                incrementTime -= 30;
                tempObj[i].timestamp = moment(t1).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj[i].timestampMs = new Date(t1).getTime().toString();
            }
            tempObj = tempObj.reverse()
            return {"data":{"getRrmHealthTrendSummary01":{"nodes":tempObj}}}; 
        } 
    },
    getUtilizationPerChannelTrendSummary01: function(variables){
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'id':variables.buildingId});
        var curTime = new Date().getTime()
        var lastOccurenceTime = curTime-(30*30*1000)
        var incrementTime = 0;
        if(variables.frequencyBand == 3){
            var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate_6ghz.getUtilizationPerChannelTrendSummary01_6ghz_Template));
            for (var i=0;i<tempObj.length;i++){
                tempObj[i].buildingId = variables.buildingId;
                tempObj[i].siteId = siteData[0].parentId;
                var t1 = UtilityFunctions.incrementDateByMinutes(lastOccurenceTime, incrementTime);
                incrementTime -= 30;
                tempObj[i].timestamp = moment(t1).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj[i].timestampMs = new Date(t1).getTime().toString();
            }
            return {"data":{"getUtilizationPerChannelTrendSummary01":{"nodes":tempObj.reverse()}}}; 
        }
        else if(variables.frequencyBand == 2){
            var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getUtilizationPerChannelTrendSummary01_5ghz_Template));
            for (var i=0;i<tempObj.length;i++){
                tempObj[i].buildingId = variables.buildingId;
                tempObj[i].siteId = siteData[0].parentId;
                for(var j=0;j<tempObj[i].utilByChannel.length;j++){
                    tempObj[i].utilByChannel[j].txRxMaxUtilization = 0;
                    tempObj[i].utilByChannel[j].txRxMinUtilization = 0;
                }
                var t1 = UtilityFunctions.incrementDateByMinutes(lastOccurenceTime, incrementTime);
                incrementTime -= 30;
                tempObj[i].timestamp = moment(t1).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj[i].timestampMs = new Date(t1).getTime().toString();
            }
            return {"data":{"getUtilizationPerChannelTrendSummary01":{"nodes":tempObj.reverse()}}}; 
        }else if(variables.frequencyBand == 1){
            var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate24ghz.getUtilizationPerChannelTrendSummary01_24ghz_Template));
            for (var i=0;i<tempObj.length;i++){
                tempObj[i].buildingId = variables.buildingId;
                tempObj[i].siteId = siteData[0].parentId;
                var t1 = UtilityFunctions.incrementDateByMinutes(lastOccurenceTime, incrementTime);
                incrementTime -= 30;
                tempObj[i].timestamp = moment(t1).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj[i].timestampMs = new Date(t1).getTime().toString();
            }
            return {"data":{"getUtilizationPerChannelTrendSummary01":{"nodes":tempObj.reverse()}}}; 
        } 
    },
    getApRogueRadarPerChannelTrendSummary01: function(variables){
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'id':variables.buildingId});
        var curTime = new Date().getTime()
        var lastOccurenceTime = curTime-(30*30*1000)
        var incrementTime = 0;
        if(variables.frequencyBand == 2){
            var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getApRogueRadarPerChannelTrendSummary01_5ghz_Template));
            for (var i=0;i<tempObj.length;i++){
                tempObj[i].buildingId = variables.buildingId;
                tempObj[i].siteId = siteData[0].parentId;
                var t1 = UtilityFunctions.incrementDateByMinutes(lastOccurenceTime, incrementTime);
                incrementTime -= 30;
                tempObj[i].timestamp = moment(t1).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj[i].timestampMs = new Date(t1).getTime().toString();
            }
            return {"data":{"getApRogueRadarPerChannelTrendSummary01":{"nodes":tempObj.reverse()}}}; 
        }else{
            var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate24ghz.getApRogueRadarPerChannelTrendSummary01_24ghz_Template));
            for (var i=0;i<tempObj.length;i++){
                tempObj[i].buildingId = variables.buildingId;
                tempObj[i].siteId = siteData[0].parentId;
                var t1 = UtilityFunctions.incrementDateByMinutes(lastOccurenceTime, incrementTime);
                incrementTime -= 30;
                tempObj[i].timestamp = moment(t1).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj[i].timestampMs = new Date(t1).getTime().toString();
            }
            return {"data":{"getApRogueRadarPerChannelTrendSummary01":{"nodes":tempObj.reverse()}}}; 
        } 
    },
    getRrmChangesLatestDetail01: function(variables) {
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler("site",{'name':'Flr-SJC1-1'});
        var siteId = siteData[0].id;
        var siteData1 = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and':[{'siteId':siteId},{'family':"Unified AP"}]});
        var tempList = [];
        if(variables.frequencyBand == 2 || variables.frequencyBand == 3){
            for(var i =0; i< 2; i++) {
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getRrmChangesLatestDetail01_5ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = siteData1[i].macAddress;
                tempObj.apName = siteData1[i].name;
                tempObj.siteId = siteData1[i].siteId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getRrmChangesLatestDetail01":{"nodes":tempList}}};
        }else{
            var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate24ghz.getRrmChangesLatestDetail01_24ghz_Template[0]));
            tempObj.buildingId = variables.buildingId;
            tempObj.apMac = siteData1[0].macAddress;
            tempObj.apName = siteData1[0].name;
            tempObj.siteId = siteData1[0].siteId;
            tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
            tempObj.timestampMs = new Date().getTime().toString();
            tempList.push(tempObj);
            return {"data":{"getRrmChangesLatestDetail01":{"nodes":tempList}}};
        }
    },
    getRrmChangesTrendDetail01: function(variables) {
        console.log("getRrmChangesTrend Call invoked");
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler("site",{'name':'Flr-SJC1-1'});
        var siteId = siteData[0].id;
        var deviceData = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and':[{'siteId':siteId},{'family':"Unified AP"}]});
        var tempList = [];
        if(variables.frequencyBand == 2){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getRrmChangesTrendDetail01_5ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getRrmChangesTrendDetail01":{"nodes":tempList}}};
        }else{
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate24ghz.getRrmChangesTrendDetail01_24ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getRrmChangesTrendDetail01":{"nodes":tempList}}};
        }
    },
    getRrmHealthLatestDetail01: function(variables) {
        console.log("getRrmHealthLatest Call invoked");
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler("site",{'name':'Flr-SJC1-1'});
        var siteId = siteData[0].id;
        var deviceData = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and':[{'siteId':siteId},{'family':"Unified AP"}]});
        var tempList = [];
        if(variables.frequencyBand == 3 || variables.frequencyBand == 2 || variables.frequencyBand == 1){
            if(variables.healthState == "Good"){
                for(var i=0;i<18;i++){
                    var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getRrmHealthLatestDetail01_5ghz_Template_Good[0]));
                    tempObj.frequencyBand = variables.frequencyBand;
                    tempObj.buildingId = variables.buildingId;
                    tempObj.apMac = deviceData[i].macAddress;
                    tempObj.apName = deviceData[i].name;
                    tempObj.siteId = siteData[0].parentId;
                    tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                    tempObj.timestampMs = new Date().getTime().toString();
                    tempObj.healthScore = UtilityFunctions.getRandomIntForRangeValues(97, 100);
                    for(j=0;j<tempObj.healthScoreReason.length;j++){
                        tempObj.healthScoreReason[j].score = tempObj.healthScore;
                    }
                    tempList.push(tempObj);
                }
            }
            if(variables.healthState == "Fair"){
                for(var i=0;i<deviceData.length;i++){
                    if(i==19 || i==20){
                        var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getRrmHealthLatestDetail01_5ghz_Template_Fair[0]));
                        tempObj.frequencyBand = variables.frequencyBand;
                        tempObj.buildingId = variables.buildingId;
                        tempObj.apMac = deviceData[i].macAddress;
                        tempObj.apName = deviceData[i].name;
                        tempObj.siteId = siteData[0].parentId;
                        tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                        tempObj.timestampMs = new Date().getTime().toString();
                        tempObj.healthScore = UtilityFunctions.getRandomIntForRangeValues(40, 59);
                        for(j=0;j<tempObj.healthScoreReason.length;j++){
                            tempObj.healthScoreReason[j].score = tempObj.healthScore;
                        }
                        tempList.push(tempObj);
                    }
                }
            }
            if(variables.healthState == "Poor"){
                for(var i=0;i<deviceData.length;i++){
                    if(i==20 || i==21){
                        var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getRrmHealthLatestDetail01_5ghz_Template_Poor[0]));
                        tempObj.frequencyBand = variables.frequencyBand;
                        tempObj.buildingId = variables.buildingId;
                        tempObj.apMac = deviceData[i].macAddress;
                        tempObj.apName = deviceData[i].name;
                        tempObj.siteId = siteData[0].parentId;
                        tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                        tempObj.timestampMs = new Date().getTime().toString();
                        tempObj.healthScore = UtilityFunctions.getRandomIntForRangeValues(20, 29);
                        for(j=0;j<tempObj.healthScoreReason.length;j++){
                            tempObj.healthScoreReason[j].score = tempObj.healthScore;
                        }
                        tempList.push(tempObj);
                    }
                }
            }
            return {"data":{"getRrmHealthLatestDetail01":{"nodes":tempList}}};
        }
        // else{
        //     for(var i=0;i<deviceData.length;i++){
        //         var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate24ghz.getRrmHealthLatestDetail01_24ghz_Template[0]));
        //         tempObj.buildingId = variables.buildingId;
        //         tempObj.apMac = deviceData[i].macAddress;
        //         tempObj.apName = deviceData[i].name;
        //         tempObj.siteId = siteData[0].parentId;
        //         tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
        //         tempObj.timestampMs = new Date().getTime().toString();
        //         tempList.push(tempObj);
        //     }
        //     return {"data":{"getRrmHealthLatestDetail01":{"nodes":tempList}}};
        // }
    },
    getRrmHealthTrendDetail01: function(variables) {
        console.log("getRrmHealthTrendDetail01 Call invoked");
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler("site",{'name':'Flr-SJC1-1'});
        var siteId = siteData[0].id;
        var deviceData = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and':[{'siteId':siteId},{'family':"Unified AP"}]});
        var tempList = [];
        if(variables.frequencyBand == 2){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getRrmHealthTrendDetail01_5ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getRrmHealthTrendDetail01":{"nodes":tempList}}};
        }else{
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate24ghz.getRrmHealthTrendDetail01_24ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getRrmHealthTrendDetail01":{"nodes":tempList}}};
        }
    },
    getCoChannelInterferenceLatestDetail01 : function(variables) {
        console.log("getCoChannelInterferenceLatestDetail01 Call invoked");
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler("site",{'name':'Flr-SJC1-1'});
        var siteId = siteData[0].id;
        var deviceData = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and':[{'siteId':siteId},{'family':"Unified AP"}]});
        var tempList = [];
        var channleList_5ghz = [36,40,44,56,60,100,108,116,116,120,132,136,149,149,153];
        var channleList_24ghz = [7,7,7,7,7,7,7,7,7,7,8,8,8,8,8,8,8]
        if(variables.frequencyBand == 2){
            if(variables.impactState == 'Low'){
                for(var i=0;i<14;i++){
                    var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getCoChannelInterferenceLatestDetail01_5ghz_Template[0]));
                    tempObj.buildingId = variables.buildingId;
                    tempObj.apMac = deviceData[i].macAddress;
                    tempObj.apName = deviceData[i].name;
                    tempObj.siteId = siteData[0].parentId;
                    tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                    tempObj.timestampMs = new Date().getTime().toString();
                    tempObj.channel = channleList_5ghz[i]
                    // tempObj.channel = UtilityFunctions.getRandomIntForRangeValues(35, 149);
                    tempObj.impactScore = UtilityFunctions.getRandomIntForRangeValues(0, 20);
                    tempObj.coChannelInterference = UtilityFunctions.getRandomIntForRangeValues(-70, -85);
                    tempList.push(tempObj);
                }
            }if(variables.impactState == 'Medium'){
                for(var i=0;i<deviceData.length;i++){
                    if(i==15 ||i==16 ||i==17 || i==18){
                        var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getCoChannelInterferenceLatestDetail01_5ghz_Template[0]));
                        tempObj.buildingId = variables.buildingId;
                        tempObj.apMac = deviceData[i].macAddress;
                        tempObj.apName = deviceData[i].name;
                        tempObj.siteId = siteData[0].parentId;
                        tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                        tempObj.timestampMs = new Date().getTime().toString();
                        if(i==15 ||i==16){
                            tempObj.channel = 64;
                        }
                        else{
                            tempObj.channel = 124;
                        }
                        tempObj.impactScore = 45;
                        tempObj.impactScoreState = "Medium";
                        tempObj.coChannelInterference = UtilityFunctions.getRandomIntForRangeValues(-70, -85);
                        tempList.push(tempObj);
                    }
                }
            }if(variables.impactState == 'High'){
                for(var i=0;i<deviceData.length;i++){
                    if(i==19 || i==20 || i == 21){
                        var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getCoChannelInterferenceLatestDetail01_5ghz_Template[0]));
                        tempObj.buildingId = variables.buildingId;
                        tempObj.apMac = deviceData[i].macAddress;
                        tempObj.apName = deviceData[i].name;
                        tempObj.siteId = siteData[0].parentId;
                        tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                        tempObj.timestampMs = new Date().getTime().toString();
                        tempObj.channel = 48;
                        tempObj.impactScore = 74;
                        tempObj.impactScoreState = "High";
                        tempObj.coChannelInterference = UtilityFunctions.getRandomIntForRangeValues(-70, -85);
                        tempList.push(tempObj);
                    }
                }
            }
            return {"data":{"getCoChannelInterferenceLatestDetail01":{"nodes":tempList}}};
        }
        else{
            if(variables.impactState == 'Low'){
                for(var i=0;i<16;i++){
                    var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getCoChannelInterferenceLatestDetail01_5ghz_Template[0]));
                    tempObj.frequencyBand = 1;
                    tempObj.buildingId = variables.buildingId;
                    tempObj.apMac = deviceData[i].macAddress;
                    tempObj.apName = deviceData[i].name;
                    tempObj.siteId = siteData[0].parentId;
                    tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                    tempObj.timestampMs = new Date().getTime().toString();
                    tempObj.channel = channleList_24ghz[i]
                    // tempObj.channel = UtilityFunctions.getRandomIntForRangeValues(35, 149);
                    tempObj.impactScore = UtilityFunctions.getRandomIntForRangeValues(0, 20);
                    tempObj.coChannelInterference = UtilityFunctions.getRandomIntForRangeValues(-70, -85);
                    tempList.push(tempObj);
                }
            }if(variables.impactState == 'Medium'){
                for(var i=0;i<deviceData.length;i++){
                    if(i==17 || i==18 || i==19){
                        var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getCoChannelInterferenceLatestDetail01_5ghz_Template[0]));
                        tempObj.frequencyBand = 1;
                        tempObj.buildingId = variables.buildingId;
                        tempObj.apMac = deviceData[i].macAddress;
                        tempObj.apName = deviceData[i].name;
                        tempObj.siteId = siteData[0].parentId;
                        tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                        tempObj.timestampMs = new Date().getTime().toString();
                        tempObj.channel = 11
                        tempObj.impactScore = UtilityFunctions.getRandomIntForRangeValues(46, 48);
                        tempObj.coChannelInterference = UtilityFunctions.getRandomIntForRangeValues(-70, -85);
                        tempObj.impactScoreState = "Medium";
                        tempList.push(tempObj);
                    }
                }
            }if(variables.impactState == 'High'){
                for(var i=0;i<deviceData.length;i++){
                    if(i==20 || i == 21){
                        var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getCoChannelInterferenceLatestDetail01_5ghz_Template[0]));
                        tempObj.frequencyBand = 1;
                        tempObj.buildingId = variables.buildingId;
                        tempObj.apMac = deviceData[i].macAddress;
                        tempObj.apName = deviceData[i].name;
                        tempObj.siteId = siteData[0].parentId;
                        tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                        tempObj.timestampMs = new Date().getTime().toString();
                        tempObj.channel = 1;
                        tempObj.impactScore = 80;
                        tempObj.impactScoreState = "High";
                        tempObj.coChannelInterference = UtilityFunctions.getRandomIntForRangeValues(-70, -85);
                        tempList.push(tempObj);
                    }
                }
            }
            return {"data":{"getCoChannelInterferenceLatestDetail01":{"nodes":tempList}}};
        }
        // else{
        //     for(var i=0;i<deviceData.length;i++){
        //         var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate24ghz.getCoChannelInterferenceLatestDetail01_24ghz_Template[0]));
        //         tempObj.buildingId = variables.buildingId;
        //         tempObj.apMac = deviceData[i].macAddress;
        //         tempObj.apName = deviceData[i].name;
        //         tempObj.siteId = siteData[0].parentId;
        //         tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
        //         tempObj.timestampMs = new Date().getTime().toString();
        //         tempList.push(tempObj);
        //     }
        //     return {"data":{"getCoChannelInterferenceLatestDetail01":{"nodes":tempList}}};
        // }
    },
    getCoChannelInterferenceTrendDetail01 : function(variables) {
        console.log("getCoChannelInterferenceTrendDetail01 Call invoked");
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler("site",{'name':'Flr-SJC1-1'});
        var siteId = siteData[0].id;
        var deviceData = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and':[{'siteId':siteId},{'family':"Unified AP"}]});
        var tempList = [];
        if(variables.frequencyBand == 2){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getCoChannelInterferenceTrendDetail01_5ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getCoChannelInterferenceTrendDetail01":{"nodes":tempList}}};
        }else{
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate24ghz.getCoChannelInterferenceTrendDetail01_24ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getCoChannelInterferenceTrendDetail01":{"nodes":tempList}}};
        }
    },
    getApSpatialDensityLatestDetail01 : function(variables) {
        console.log("getApSpatialDensityLatestDetail01 Call invoked");
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler("site",{'name':'Flr-SJC1-1'});
        var siteId = siteData[0].id;
        var deviceData = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and':[{'siteId':siteId},{'family':"Unified AP"}]});
        var tempList = [];
        var deviceLength = deviceData.length
        if(variables.minNeighbourCount == 15 && variables.maxNeighbourCount == 20){
            deviceLength = 2
        }if(variables.minNeighbourCount == 10 && variables.maxNeighbourCount == 15){
            deviceLength = 8
        }if(variables.minNeighbourCount == 5 && variables.maxNeighbourCount == 10){
            deviceLength = 10
        }if(variables.minNeighbourCount == 0 && variables.maxNeighbourCount == 5){
            deviceLength = 2
        }
        if(variables.frequencyBand == 2){
            for(var i=0;i<deviceLength;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getApSpatialDensityLatestDetail01_5ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getApSpatialDensityLatestDetail01":{"nodes":tempList}}};
        }else{
            for(var i=0;i<deviceLength;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate24ghz.getApSpatialDensityLatestDetail01_24ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getApSpatialDensityLatestDetail01":{"nodes":tempList}}};
        }
    },
    getApSpatialDensityTrendDetail01 : function(variables) {
        console.log("getApSpatialDensityTrendDetail01 Call invoked");
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler("site",{'name':'Flr-SJC1-1'});
        var siteId = siteData[0].id;
        var deviceData = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and':[{'siteId':siteId},{'family':"Unified AP"}]});
        var tempList = [];
        if(variables.frequencyBand == 2){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getApSpatialDensityTrendDetail01_5ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getApSpatialDensityTrendDetail01":{"nodes":tempList}}};
        }else{
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate24ghz.getApSpatialDensityTrendDetail01_24ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getApSpatialDensityTrendDetail01":{"nodes":tempList}}};
        }
    },
     getPowerDistributionLatestDetail01 : function(variables) {
        console.log("getPowerDistributionLatestDetail01 Call invoked");
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler("site",{'name':'Flr-SJC1-1'});
        var siteId = siteData[0].id;
        var deviceData = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and':[{'siteId':siteId},{'family':"Unified AP"}]});
        var tempList = [];
        var deviceLength = deviceData.length;
        if(variables.frequencyBand == 2){
            if(variables.powerLevel == 8){
                deviceLength = 1
            }
            if(variables.powerLevel == 7){
                deviceLength = 1
            }
            if(variables.powerLevel == 6){
                deviceLength = 2
            }
            if(variables.powerLevel == 5){
                deviceLength = 3
            }if(variables.powerLevel == 4){
                deviceLength = 6
            }if(variables.powerLevel == 3){
                deviceLength = 4
            }if(variables.powerLevel == 2){
                deviceLength = 3
            }if(variables.powerLevel == 1){
                deviceLength = 2
            }
            for(var i=0;i<deviceLength;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getPowerDistributionLatestDetail01_5ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getPowerDistributionLatestDetail01":{"nodes":tempList}}};
        }else{
            if(variables.powerLevel == 8){
                deviceLength = 3
            }if(variables.powerLevel == 7){
                deviceLength = 7
            }if(variables.powerLevel == 6){
                deviceLength = 2
            }if(variables.powerLevel == 5){
                deviceLength = 1
            }if(variables.powerLevel == 4){
                deviceLength = 0
            }if(variables.powerLevel == 3){
                deviceLength = 1
            }if(variables.powerLevel == 2){
                deviceLength = 2
            }if(variables.powerLevel == 1){
                deviceLength = 6
            }
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate24ghz.getPowerDistributionLatestDetail01_24ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getPowerDistributionLatestDetail01":{"nodes":tempList}}};
        }
    },
     getPowerDistributionTrendDetail01 : function(variables) {
        console.log("getPowerDistributionTrendDetail01 Call invoked");
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler("site",{'name':'Flr-SJC1-1'});
        var siteId = siteData[0].id;
        var deviceData = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and':[{'siteId':siteId},{'family':"Unified AP"}]});
        var tempList = [];
        if(variables.frequencyBand == 2){
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getPowerDistributionTrendDetail01_5ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getPowerDistributionTrendDetail01":{"nodes":tempList}}};
        }else{
            for(var i=0;i<deviceData.length;i++){
                var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate24ghz.getPowerDistributionTrendDetail01_24ghz_Template[0]));
                tempObj.buildingId = variables.buildingId;
                tempObj.apMac = deviceData[i].macAddress;
                tempObj.apName = deviceData[i].name;
                tempObj.siteId = siteData[0].parentId;
                tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
                tempObj.timestampMs = new Date().getTime().toString();
                tempList.push(tempObj);
            }
            return {"data":{"getPowerDistributionTrendDetail01":{"nodes":tempList}}};
        }
    },
    getServiceabilityRadioLevel01 : function(variables){
        console.log("getServiceabilityRadioLevel01 Call invoked for Download the file");
        var siteData = SimLokiDatabaseActions.getFilteredRecordHandler("site",{'name':'Flr-SJC1-1'});
        var siteId = siteData[0].id;
        var deviceData = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and':[{'siteId':siteId},{'family':"Unified AP"}]});
        var tempList = [];
        for(var i=0;i<deviceData.length;i++){
            var tempObj = JSON.parse(JSON.stringify(SimEnhancedRRMTemplate.getServiceabilityRadioLevel01_5ghz_Template[0]));
            tempObj.buildingId = variables.buildingId;
            tempObj.apMac = deviceData[i].macAddress;
            tempObj.apName = deviceData[i].name;
            tempObj.siteId = siteData[0].parentId;
            tempObj.timestamp = moment(new Date()).utc().format("YYYY-MM-DDTHH:mm:ss");
            tempObj.timestampMs = new Date().getTime().toString();
            tempList.push(tempObj);
        }
        return {"data":{"getServiceabilityRadioLevel01":{"nodes":tempList}}};
    },
    getServiceabilityBuildingLevel01 : function(variables){
        return {"data":{"getServiceabilityBuildingLevel01":{"nodes":[]}}}
    },
    getScheduleJobRRMData: function(urlAction){
        var resultObj = SimTaskDataGenerator.createTask('Ai_RRM');
        var urlNotification = urlAction.restPayload[0].externalSchedule.notificationBody
        var recordObj = SimLokiDatabaseActions.getAll('enhancedRRM-sim')
        for(var i=0;recordObj.length>i;i++){
            SimLokiDatabaseActions.delete('enhancedRRM-sim', recordObj[i])
        }
        SimLokiDatabaseActions.insert('enhancedRRM-sim', urlNotification)
        return resultObj
    }
}
});