define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/assurance/issue/ClientOnboardingHelper',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/ImpactedHosts_Wireless',
     'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/ImpactedHosts_Wired',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/AssuranceIssueTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/mapping/impacted_hosts_column_mappings',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/DefaultConfig',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/IssueCatalogueDeviceTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/IssueGlobalCategoryTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/SimulationUtility',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/NetworkDeviceDetailsTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/InteraceConnectivityIssueTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/assuranceReasonerTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/AssuranceFabricTemplate'
 ], function(SimLokiDatabaseActions, UtilityFunctions, ClientOnboardingHelper, ImpactedHosts_Wireless,
             ImpactedHosts_Wired, AssuranceIssueTemplate,impacted_hosts_column_mappings,DefaultConfig,IssueCatalogueDeviceTemplate,IssueGlobalCategoryTemplate,
             SimulationUtility, NetworkDeviceDetailsTemplate, SimTaskDataGenerator, InteraceConnectivityIssueTemplate, assuranceReasonerTemplate,
             AssuranceFabricTemplate) {

  /*function getStartDate(endTime) {
      //MetricClienthealthAggregation_Error
      //var iLen = MetricClienthealthAggregation_Error.MetricData.values.length;
      var iLen = 146;
      var i, tWindowTime = 5;
      var toTime = UtilityFunctions.getTimeStamp();

      var fromTime = toTime - UtilityFunctions.getMinuteToMilliSeconds(tWindowTime * iLen);
      //var timeToSet = UtilityFunctions.getGivenDateAndTimeInValidFormat(fromTime);
      //metricData.request.timeRange.start = timeToSet;
      return fromTime; //original
  }*/

  function getIssueId(urlAction) {
      var id = '';
      var i;
      var t = urlAction.url.split('/');
      for(i = 0; i <  t.length; i++) {
          if(t[i] == 'issue') {
              if(i + 1 <= t.length) {
                  id = t[i + 1];
                  if(id == '') {
                      if(i + 2 <= t.length) {
                          id = t[i + 2];
                      }
                  }
                  break;
              }
          }
      }

      return id;
  }

  function getImpactedHostsDetail(issueId) {

      var issue =  SimLokiDatabaseActions.getFilteredRecordHandler('issue', { 'id': issueId });
      if(issue.length==0) {
          issue = SimLokiDatabaseActions.getFilteredRecordHandler('customIssue', { 'id': issueId });
      }
      var recRootCause = SimLokiDatabaseActions.getFilteredRecordHandler('issue', { 'rootCause': issue[0].rootCause })
      if(recRootCause.length==0) {
        recRootCause = SimLokiDatabaseActions.getFilteredRecordHandler('customIssue', { 'rootCause': issue[0].rootCause });
      }
      var host
      if(issue[0].entityType == "Device"){
        host =  SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'id': issue[0].deviceId })
      }else {
        host =  SimLokiDatabaseActions.getFilteredRecordHandler('host', { 'id': issue[0].entity })
      }
      if(issue.length && host.length){
        if(host[0].hostType == 'WIRELESS'){
            var hostList = []
            for(var i=0; i<recRootCause.length; i++){
              var record = SimLokiDatabaseActions.getFilteredRecordHandler('host', { 'id': recRootCause[i].entity })
                var tjsonArr = JSON.parse(JSON.stringify(ImpactedHosts_Wireless.Host_Template));
                tjsonArr.hostName = record[0].hostName;
                tjsonArr.location.siteId = issue[0].groupBy;
                tjsonArr.macAddress = record[0].hostMac;
                tjsonArr.hostType = record[0].hostType;
                tjsonArr.ssid = record[0].ssid;
                tjsonArr.apName = record[0].clientConnection;
                tjsonArr.frequency = record[0].frequency;
                tjsonArr.siteQualifiedName = record[0].location;
                tjsonArr.hostOs = record[0].subType;
                tjsonArr.wlcName = DefaultConfig.Wlc_Provision_list[0];
                tjsonArr.vlanId = record[0].vlanId;
                tjsonArr.apName = record[0].clientConnection;
                tjsonArr.timestamp = new Date().getTime();
                tjsonArr.deviceCategory = record[0].subType;
                tjsonArr.osCategory = record[0].hostOs;
               hostList.push(tjsonArr);
              }
            var tJson = JSON.parse(JSON.stringify(ImpactedHosts_Wireless.Template));
            tJson.impactedLocations[0].siteId = issue[0].groupBy;
            tJson.impactedLocations[0].clientCount = hostList.length;
            tJson.count.wireless =hostList.length;
            tJson.count.totalCount =hostList.length;

            var devCount = hostList.map(function(value){return value.deviceCategory;})
            var totDevicesCount = {};devCount.forEach(function(i) {totDevicesCount[i] = (totDevicesCount[i]||0) + 1;});
            tJson.count.topDeviceTypeCount = totDevicesCount;

            var osCount = hostList.map(function(value){return value.osCategory;})
            var totOsCount = {}; osCount.forEach(function(i) {totOsCount[i] = (totOsCount[i]||0) + 1;});
            tJson.count.topOSsCount = totOsCount;

            tJson.response = hostList;
            tJson.timeRange.endTimestamp = new Date().getTime();
            tJson.timeRange.startTimestamp = new Date().getTime()-(6000*300);
            return tJson;
        }else if(issue[0].entityType == "Device"){
            var tJson = JSON.parse(JSON.stringify(ImpactedHosts_Wireless.ImpactedApsTemplate));
            let siteInfo = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'id': host[0].siteId })
            tJson.timeRange.endTimestamp = new Date().getTime();
            tJson.timeRange.startTimestamp = new Date().getTime()-(6000*300);
            tJson.impactedLocations[0].siteId = issue[0].groupBy;
            tJson.impactedLocations[0].apsImpacted[0] = host[0].macAddress
            tJson.impactedLocations[0].siteQualifiedName = siteInfo[0].groupNameHierarchy
            tJson.impactedLocations[0].area = siteInfo[0].groupNameHierarchy.split('/',5).join('/')
            tJson.impactedLocations[0].building = siteInfo[0].name
            tJson.impactedLocations[0].floor  = siteInfo[0].name
            return tJson
        }else if (host[0].hostType == 'WIRED'){
              var tJson = JSON.parse(JSON.stringify(ImpactedHosts_Wired.Template));
              for(var i = 0; i < tJson.response.length; i++) {
                  //tJson.response[i].timestamp = issue[0].timestamp;
                  tJson.response[i].timestamp = new Date().getTime();
                  tJson.response[i].vlanId = host[0].vlanId;
              }
              return tJson;
        }
   }else return {"version":"1.0","response":[],"count": {},"impactedLocations": []};
  }

  function getIssueDetailInfo(tIssueId) {
      var issues =  SimLokiDatabaseActions.getFilteredRecordHandler('assuranceissue-detail', { 'id': tIssueId });
      if(issues.length == 0) {
        issues =  SimLokiDatabaseActions.getFilteredRecordHandler('customAssuranceissue-detail', { 'id': tIssueId });
      }
      if(issues != null && issues.length >0){
        var device_role="WLC";
          var deviceIssue =  SimLokiDatabaseActions.getFilteredRecordHandler('issue', { 'id': tIssueId });
          if(deviceIssue.length == 0) {
            deviceIssue =  SimLokiDatabaseActions.getFilteredRecordHandler('customIssue', { 'id': tIssueId });
          }
          var deviceRec = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',
                                        {'id': deviceIssue[0].deviceId });
          if(deviceRec.length>0){
            var siteRec = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'id': deviceRec[0].siteId });
            device_role = (deviceRec[0].family == "Unified AP") ? "Access Point" : (deviceRec[0].family == "Wireless Controller") ? "WLC": deviceRec[0].role; //Access Point
          }
	  if(deviceIssue != undefined && deviceIssue.length > 0){
              issues[0].entity = deviceIssue[0].entity;
              issues[0].entityName = deviceIssue[0].deviceName;
              /*issues[0].timestamp = deviceIssue[0].totalOccurrences != undefined ?
                                             deviceIssue[0].totalOccurrences.endTime:0;*/

            issues[0].timestamp = new Date().getTime();
            issues[0].status.status = deviceIssue[0].status.status;
            //issues[0].timestamp = deviceIssue[0].lastOccurence;

              for(var paramIndex in issues[0].additionalParams){

                  if(issues[0].additionalParams[paramIndex].key == "status"){
                    issues[0].additionalParams[paramIndex].value = deviceIssue[0].status.status;
                  }
                  if (issues[0].additionalParams[paramIndex].key == "_primary_key"){
                      issues[0].additionalParams[paramIndex].value = "NetworkDevice"+":"+issues[0].entity;
                  }
                  if(issues[0].additionalParams[paramIndex].key == "apname" || issues[0].additionalParams[paramIndex].key == "nwDeviceName" ||
                    issues[0].additionalParams[paramIndex].key == "eventUniqueId" || 
                     issues[0].additionalParams[paramIndex].key == "entityName"){
                      issues[0].additionalParams[paramIndex].value = deviceIssue[0].deviceName;
                  }
                  if (issues[0].additionalParams[paramIndex].key == "deviceUuid"){
                      issues[0].additionalParams[paramIndex].value = deviceIssue[0].deviceId;
                  }
                  if (issues[0].additionalParams[paramIndex].key == "siteHierarchy"){
                      issues[0].additionalParams[paramIndex].value =
                        (deviceRec.length>0 ? siteRec[0].groupNameHierarchy:"Global/North America/USA/California/San Jose/SJC01");
                  }
                  if (issues[0].additionalParams[paramIndex].key == "managementIpAddr"){
                      issues[0].additionalParams[paramIndex].value = issues[0].entity;
                  }
                  if (issues[0].additionalParams[paramIndex].key == "deviceRole"){
                    issues[0].additionalParams[paramIndex].value = device_role;
                }
                  else if (issues[0].additionalParams[paramIndex].key == "macAddress"){
                      if(deviceRec.length>0) {
                         issues[0].additionalParams[paramIndex].value = deviceRec[0].macAddress;
                      }
                  }
              }
              if(! JSON.stringify(issues[0].additionalParams).includes("fabricName")) {
                let fabricNameVal = deviceRec.length>0 && siteRec.length>0 ? siteRec[0].groupNameHierarchy.replace(" ","_")+"_SanJose_Fabric":
                    "Global/North_America/USA/California/San_Jose/SJC01_SanJose_Fabric";
                    issues[0].additionalParams.push({"key":"fabricName", "value":fabricNameVal});
              }
              if(! JSON.stringify(issues[0].additionalParams).includes("impactedClientCount")) {
                  issues[0].additionalParams.push({"key":"impactedClientCount", "value":10});
              }
          }     
          return {"version":"1.0","response": issues[0]};
      }
  }
     function getIssueBasedOnFilter(urlAction) {
      if(urlAction.action.id != '') {
          filter = {"id": urlAction.action.id};
      }

      if((urlAction.filter['entityId'] != undefined && urlAction.filter['entityId'] != '') &&
         (urlAction.filter['entityName'] != undefined && urlAction.filter['entityName'] == 'managementIpAddr')) {
          // Getting the id value
          filter = {"managementIpAddress": urlAction.filter['entityId']};
      }

      var networkDevice =  SimLokiDatabaseActions.getFilteredRecordHandler('network-device',filter);

      var tJson = JSON.parse(JSON.stringify(AssuranceIssueTemplate.Assurance_Issue_Template));

      tJson.response[0].id =networkDevice[0].id; // Setting device id as issue id.
      tJson.response[0].entity =networkDevice[0].managementIpAddress;
      //tJson.response[0].summary = "Secondary power supply has failed on this \""+networkDevice[0].hostname + "\".";
      //tJson.response[0].description ="The \"secondary\" power supply has failed on this  \""+networkDevice[0].hostname + "\". This device is now operating with a single power supply.";
      //tJson.response[0].timestamp =1509859800000;
      //tJson.response[0].occurrences = 1;
      //tJson.response[0].severity = 'HIGH';
      tJson.response[0].name = networkDevice[0].hostname;
      //tJson.response[0].category = "Availability";
      //tJson.response[0].scope = "GLOBAL";
      return tJson;
  }

  function getGlobalIssuesWithUniqueIssues(urlAction, issues) {
      //Assurance home page, need to show unique issue
      var obj = {}, tIssues = [];
      var limit = urlAction.filter['limit'], offset = urlAction.filter['offset'], i, count = 0, offsetCount = 0;

      // This logic is moved to top bring bring devices issue top
      issues
              .map(
                function(e){
                  if(e.entityType==='Device') e.priority='P1';
                  if(e.entityType==='Sensor') e.priority='P3';
                  if( e.priority==='P1' && e.entityType!=='Device' && e.entityType!=='Sensor')  e.priority='P2';
                  return e;
                  }
                )

      issues.sort(function(a, b){
            if ( a.priority <  b.priority)
                return -1
            if (a.priority > b.priority)
                return 1
            return 0
        });
      for(i = 0; i < issues.length; i++) {
          if(obj[issues[i].rootCause] == undefined) {
              obj[issues[i].rootCause] = i;
              if(offset + offsetCount < limit + offset  && issues[i].scope==="GLOBAL") {
                  tIssues.push(issues[i]);
                  offsetCount++;
              }
              count++;
          }
      }

    /*   tIssues //Ram changes start: Custom logic for special requirement to show device issues on top
              .map(
                function(e){
                  if(e.entityType==='Device') e.priority='P1';
                  if( e.priority==='P1' && e.entityType!=='Device') e.priority='P2';
                  return e;
                  }
                ) */
       return   tIssues.sort(function (a, b) {
                      return b.timestamp - a.timestamp
                  });

  }
    //sync between issue and issue-setting to get priority,groupname and get data in expected format
  function getGlobalIssuesData(urlAction){

             var startDt = urlAction.filter['startTime'];
             var endDt = urlAction.filter['endTime'];
             var hours = UtilityFunctions.getHours(urlAction.filter["startTime"], urlAction.filter["endTime"]);
             var issueStatus = urlAction.restPayload.issueStatus; //active,resolved,ignored
             hours = (Math.round(hours));
             var increment = 0;
             //var obj = JSON.parse(JSON.stringify(IssueGlobalCategoryTemplate.GlobalTemplate));
             var issueList = [];
             //var allIssues = SimLokiDatabaseActions.getAll("issue");
             var allIssues = SimLokiDatabaseActions.getFilteredRecordHandler("issue",{"status.status" : issueStatus });

             var obj = {} ,uniqueIssues = [];
             for(let l = 0; l < allIssues.length; l++){
                 if(obj[allIssues[l].name] == undefined ){
                    obj[allIssues[l].name] = l;
                    uniqueIssues.push(allIssues[l]);

                 }
             }
             var issueSetting =  SimLokiDatabaseActions.getAll("issueSettings");
             var issueSettingData = issueSetting[0].value.response.categories;
             var IssueSettingIssues = []; issueSettingData.forEach(function(i) {IssueSettingIssues[i] = IssueSettingIssues.push(i.issues)})
             var allIssueSettingIssues = []; IssueSettingIssues.forEach(function(i) {allIssueSettingIssues = allIssueSettingIssues.concat(i)})

             /*code to have less issues during 24 hrs selection dnac_fury_executive demo ask*/
             let listToDisplay = ["mac_flap_trigger","infra_link_down","global_wireless_kairos_excessive_onboarding_failure_trigger",
                "global_wireless_kairos_excessive_onboarding_time_trigger","global_wireless_kairos_excessive_dhcp_time_trigger","switch_issue_power",
                "global_wireless_kairos_radio_cloud_app_throughput_drop_trigger","global_wireless_kairos_radio_throughput_drop_trigger",
                "network_device_poe_err_disabled_trigger","network_device_poe_power_deny_trigger","global_wireless_client_auth_fail_aaa_rej_trigger",
                "global_wireless_client_auth_fail_sec_param_mismatch_trigger","global_sensor_webauth_fail_trigger",
                "global_wireless_client_excess_auth_wlc_trigger","global_wireless_client_excess_auth_aaa_fail_trigger"]
             if(hours == 24){
                uniqueIssues = uniqueIssues.filter( issue => listToDisplay.indexOf(issue.name)>-1)
             }

             for(var s=0; s<uniqueIssues.length ;s++){

                var issueSettingRecord = [];
                issueSettingRecord.push(allIssueSettingIssues.find((obj) => { if(uniqueIssues[s].name === obj.name && obj.isGlobal == "Yes") return obj }))
                if(issueSettingRecord[0] != undefined){
                    var issueName = issueSettingRecord[0].name
                    var issueRecord = SimLokiDatabaseActions.getFilteredRecordHandler('issue', {'name': issueName });

                    var customIssuesrec = SimLokiDatabaseActions.getFilteredRecordHandler('customIssue', { 'name': issueName });
                    issueRecord = issueRecord.concat(getCustomIssueAfter5Min(customIssuesrec));


                    var site_count, siteList = [], issue_count_List = [] , issue_count, device_role , device_Names_List = [], issueLastOccurence = [];
                    for(l=0; l<issueRecord.length; l++){
                        if(issueRecord[l].entityType=="Device") {
                            if(issueRecord[l].entity.indexOf(":")>=0) {
                                var deviceRecord = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'macAddress': issueRecord[l].entity.toLowerCase() });
                            }else{
                                var deviceRecord = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'managementIpAddress': issueRecord[l].entity });
                            }
                            var siteRecord = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'id': deviceRecord[0].siteId });
                            siteList.push(siteRecord[0].groupNameHierarchy);
                            device_role = (deviceRecord[0].family == "Unified AP") ? "Access Point" : (deviceRecord[0].family == "Wireless Controller") ? "WLC": deviceRecord[0].role; //Access Point

                        }else if (issueRecord[l].entityType=="Client" || issueRecord[l].entityType == "Sensor") {
                            var hostRecord = SimLokiDatabaseActions.getFilteredRecordHandler('host', { 'id': issueRecord[l].entity });
                            siteList.push(hostRecord[0].location);
                            device_role = (issueRecord[l].entityType == "Sensor")? "Sensor":hostRecord[0].hostType;

                        }
                        issue_count_List.push(issueRecord[l].totalOccurrences.count);
                        device_Names_List.push(issueRecord[l].deviceName);
                        issueLastOccurence.push(issueRecord[l].lastOccurence);
                    }
                    site_count = [...new Set(siteList)];
                    //issueLastOccurence = Math.max.apply(Math,issueLastOccurence)
                    if(hours == 24){
                        issue_count_List = issue_count_List.map(function(val){return Math.round((val/7)) })//(Math.round(issue_count*7));
                    }else if(hours == 3){
                        issue_count_List = issue_count_List.map(function(val){return Math.round((val/56)) })//(Math.round(issue_count*3/8));
                    }
                    issue_count = issue_count_List.reduce(function(a, b) { return a + b; }, 0);
                    if(issue_count == 0){issue_count = 1 }// to handle 0 case

                    var Json = JSON.parse(JSON.stringify(IssueGlobalCategoryTemplate.IssueGlobalBaseTemplate));
                    Json.priority = issueSettingRecord[0].priority;
                    Json.displayName = issueSettingRecord[0].displayName;
                    Json.name = issueSettingRecord[0].name;
                    // for device issues entity type expecting is Network
                    Json.issueCategory = (uniqueIssues[s].entityType === "Device") ? "Network" : uniqueIssues[s].entityType;
                    var devCount = issueRecord.map(function(value){return value.deviceName;});
                    Json.deviceCount = devCount.length;
                    Json.areaCount = site_count.length;
                    Json.buildingCount = site_count.length;
                    Json.floorCount = site_count.length;
                    Json.isAIDriven = uniqueIssues[s].isAiDriven;

                    //Json.issueCount = (uniqueIssues[s].isAiDriven == true)? 1 : issue_count; //changing according to richard ask
                    Json.issueCount = (listToDisplay.indexOf(Json.name)>-1)? issueRecord.length : issue_count;

                    Json.deviceRole = device_role; //issueSettingRecord[0].role;
                    Json.deviceRoleDisplay = device_role;
                    Json.category = uniqueIssues[s].category;
                    //to show vary in timestamp for issue , taking data from AssuranceDataGenerator lastOccurence
                    Json.lastOccurrence = UtilityFunctions.incrementDateByMinutes(issueLastOccurence[0], increment);//new Date().getTime();
                    increment-= 2;
                    Json.deviceNames = device_Names_List;
                    issueList.push(Json)
                }
             }
             issueList.sort(function(a, b){
                    if ( a.priority <  b.priority)
                        return -1
                    if (a.priority > b.priority)
                        return 1
                    return 0
             });

             return issueList;
  }

  function getCustomIssueAfter5Min(customIssues) {
    var matchingIssues=[];
    var currDate = UtilityFunctions.getTimeStamp();
    for(var i=0;i<customIssues.length; i++) {
        var delayedTime = UtilityFunctions.incrementMinutes(customIssues[i].timestamp, 5);
        if(new Date(currDate) >= new Date(delayedTime)) {
            matchingIssues.push(customIssues[i]);
        }
    }
    return matchingIssues;
  }

  function getParentSiteName(siteId) {
     var obj = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'id' : siteId});
     return obj.length ? obj[0].groupNameHierarchy : '';
  }
    //get issues based on site
  function IssuesBasedOnSiteData(urlAction,siteId,siteType) {
        siteId = siteId!=undefined ? siteId.replaceAll("/",""): siteId;
        var startDt = urlAction.filter['startTime'];
        var endDt = urlAction.filter['endTime'];
        var hours = UtilityFunctions.getHours(urlAction.filter["startTime"], urlAction.filter["endTime"]);
        hours = (Math.round(hours));

        var issueTotalData = getGlobalIssuesData(urlAction);

        var childrenSites=[],siteIdList = [], LocationNamesList = [];
        var siteList =SimulationUtility.getChildrenSites(siteId,childrenSites);
         siteIdList.push(siteId);
         for(var i=0;i<childrenSites.length;i++) {
             siteIdList.push(childrenSites[i].id);
             LocationNamesList.push(childrenSites[i].groupNameHierarchy);
         }
         if(siteType == "Floor" || siteType == "floor"){
            var siteRec =SimLokiDatabaseActions.getFilteredRecordHandler("site", {"id": siteId});
            LocationNamesList.push(siteRec[0].groupNameHierarchy);
         }
         var deviceList =   SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'siteId': { '$containsAny' :  siteIdList}});
         var deviceListNames = deviceList.map(a => a.hostname);

         //to get host connected to sites
         var hostRec = SimLokiDatabaseActions.getFilteredRecordHandler('host',{'location': { '$containsAny' : LocationNamesList}});
         var hostNames = hostRec.map(a => a.hostName);

        //check if the two records can be performed in one filter
         var issueDevRec = SimLokiDatabaseActions.getFilteredRecordHandler('issue',{'deviceName': { '$containsAny' : deviceListNames}});
         var issueHostRec = SimLokiDatabaseActions.getFilteredRecordHandler('issue',{'deviceName': { '$containsAny' : hostNames}});
         var allDevHostRec = issueDevRec.concat(issueHostRec); // issues of Appliedsite

         //uniqueIssue Rec from allDevHostRec
         var uniq = {};
         var uniqDevHostRec = allDevHostRec.filter(obj => !uniq[obj.name] && (uniq[obj.name] = true));
         var UniqDevHostByDevice = allDevHostRec.filter(obj => !uniq[obj.deviceName] && (uniq[obj.deviceName] = true));
         var issueList =[], issuesInTotaData = [];
         for (var l=0; l<uniqDevHostRec.length; l++){
               issuesInTotaData.push(issueTotalData.find(function(obj){if(uniqDevHostRec[l].name === obj.name) return uniqDevHostRec[l]}));
         }
         issuesInTotaData = issuesInTotaData.filter(obj => obj!= undefined);
         /*code to have less issues during 24 hrs selection dnac_fury_executive demo ask*/
         let listToDisplay = ["mac_flap_trigger","infra_link_down","global_wireless_kairos_excessive_onboarding_failure_trigger",
                "global_wireless_kairos_excessive_onboarding_time_trigger","global_wireless_kairos_excessive_dhcp_time_trigger","switch_issue_power",
                "global_wireless_kairos_radio_cloud_app_throughput_drop_trigger","global_wireless_kairos_radio_throughput_drop_trigger",
                "network_device_poe_err_disabled_trigger","network_device_poe_power_deny_trigger","global_wireless_client_auth_fail_aaa_rej_trigger",
                "global_wireless_client_auth_fail_sec_param_mismatch_trigger","global_sensor_webauth_fail_trigger",
                "global_wireless_client_excess_auth_wlc_trigger","global_wireless_client_excess_auth_aaa_fail_trigger"]
         if(hours == 24){
             issuesInTotaData = issuesInTotaData.filter( issue => listToDisplay.indexOf(issue.name)>-1)
         }

         for(var x=0; x<issuesInTotaData.length; x++){
                var issue_count_List = [], device_Names_List = [] , issue_count;
                if(issuesInTotaData[x] != undefined) {
                    var name = issuesInTotaData[x].name;
                    var siteIssueRecords =[];
                    for(let e=0; e<allDevHostRec.length; e++){
                        if(allDevHostRec[e].name == name){
                            siteIssueRecords.push(allDevHostRec[e]);
                        }
                    }
                     //siteIssueRecords.push(allDevHostRec.find(function(obj) {if(obj.name === name) return obj }));
                     siteIssueRecords.filter(obj => obj!= undefined);
                     for (k=0; k<siteIssueRecords.length; k++){
                        if(siteIssueRecords[k] != undefined){
                        issue_count_List.push(siteIssueRecords[k].totalOccurrences.count);
                        device_Names_List.push(siteIssueRecords[k].deviceName);
                        }
                     }
                    if(hours == 24){
                            issue_count_List = issue_count_List.map(function(val){return Math.round((val/7)) });
                    }else if(hours == 3){
                            issue_count_List = issue_count_List.map(function(val){return Math.round((val/56)) });
                    }
                    issue_count = issue_count_List.reduce(function(a, b) { return a + b; }, 0);
                    if(issue_count == 0){issue_count = 1} // to handle 0 case

                    var Json = JSON.parse(JSON.stringify(IssueGlobalCategoryTemplate.IssueGlobalBaseTemplate));
                    Json.priority    = issuesInTotaData[x].priority;
                    Json.name = issuesInTotaData[x].name;
                    Json.displayName = issuesInTotaData[x].displayName;
                    Json.issueCategory = issuesInTotaData[x].issueCategory;
                    Json.issueCount = (listToDisplay.indexOf(Json.name)>-1)? 1 : issue_count;
                    Json.deviceCount = device_Names_List.length;
                    Json.areaCount = 1;
                    Json.buildingCount = 1;
                    Json.floorCount = 1;
                    Json.deviceRole = issuesInTotaData[x].deviceRole;
                    Json.category = issuesInTotaData[x].category;
                    //to show vary in timestamp for issue , taking data from AssuranceDataGenerator lastOccurence
                    Json.lastOccurrence = issuesInTotaData[x].lastOccurrence;//new Date().getTime();
                    issueList.push(Json);

                }
         }
         return issueList;
  }
//to get the issueName, to device assigned, site
 function getGlobalCategoryDetail(urlAction) {

        var issueName = urlAction.restPayload.filters['issueName'];
        var status = urlAction.restPayload.issueStatus;
        var startDt = urlAction.filter['startTime'];
        var endDt = urlAction.filter['endTime'];
        var issueMessage = urlAction.restPayload.filters['issueMessage'];
        var site = urlAction.restPayload.filters['site'];
        var deviceName = urlAction.restPayload.filters['deviceName'];
        var deviceType = urlAction.restPayload.filters['deviceType'];
        var hours = UtilityFunctions.getHours(urlAction.filter["startTime"], urlAction.filter["endTime"]);
            hours = (Math.round(hours));
        var incrementTime = -75
        var issuesList = [],site_count = [],siteList = [];


        /*code to have less issues during 24 hrs selection dnac_fury_executive demo ask*/
        let listToDisplay = ["mac_flap_trigger","infra_link_down","global_wireless_kairos_excessive_onboarding_failure_trigger",
                "global_wireless_kairos_excessive_onboarding_time_trigger","global_wireless_kairos_excessive_dhcp_time_trigger","switch_issue_power",
                "global_wireless_kairos_radio_cloud_app_throughput_drop_trigger","global_wireless_kairos_radio_throughput_drop_trigger",
                "network_device_poe_err_disabled_trigger","network_device_poe_power_deny_trigger","global_wireless_client_auth_fail_aaa_rej_trigger",
                "global_wireless_client_auth_fail_sec_param_mismatch_trigger","global_sensor_webauth_fail_trigger",
                "global_wireless_client_excess_auth_wlc_trigger","global_wireless_client_excess_auth_aaa_fail_trigger"]


        if(status=="active" || status == "resolved"){
            var issueRecords = SimLokiDatabaseActions.getFilteredRecordHandler('issue', {'$and' : [ { 'name': issueName } ,{'status.status': status}]} );

            var customIssuesRec = SimLokiDatabaseActions.getFilteredRecordHandler('customIssue', { 'name':issueName });
            issueRecords = issueRecords.concat(getCustomIssueAfter5Min(customIssuesRec));

            if(issueRecords.length > 0){
                for(var s=0; s<issueRecords.length; s++){
                    var device_Type;
                    var impacted_Location
                    if(issueRecords[s].entityType=="Device") {
                        if(issueRecords[s].entity.indexOf(":")>=0) {
                            var deviceRecord = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'macAddress': issueRecords[s].entity.toLowerCase() });
                        }else{
                            var deviceRecord = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'managementIpAddress': issueRecords[s].entity });
                        }
                        var siteRecord = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'id': deviceRecord[0].siteId });
                        device_Type = deviceRecord[0].type;
                        impacted_Location = siteRecord[0].groupNameHierarchy;
                        siteList.push(impacted_Location);
                    }else if(issueRecords[s].entityType=="Client" || issueRecords[s].entityType == "Sensor"){
                        var hostRecord = SimLokiDatabaseActions.getFilteredRecordHandler('host', { 'id': issueRecords[s].entity });
                        device_Type = hostRecord[0].subType;
                        impacted_Location = hostRecord[0].location;
                        siteList.push(impacted_Location);
                    }
                    site_count = [...new Set(siteList)];
                    var issue_count = issueRecords[s].occurrences;

                    if(hours == 24){
                        issue_count = Math.round(issue_count/7) ;
                    }else if(hours == 3){
                        issue_count = Math.round((issue_count/56));
                        if(issue_count == 0){issue_count = 1} //to handle 0 case and still issue exists
                    }

                    var Json = JSON.parse(JSON.stringify(IssueGlobalCategoryTemplate.GlobalDetailBaseTemplate));
                    Json.issueId = issueRecords[s].id;
                    Json.issueMessage = issueRecords[s].summary;
                    Json.impactedLocation = impacted_Location;
                    Json.startTime = UtilityFunctions.getTimeStamp()

                    //Json.occurrenceCount = (issueRecords[s].isAiDriven == true) ? 1 : issue_count;
                    Json.occurrenceCount = (listToDisplay.indexOf(issueRecords[s].name)>-1) ? 1 : issue_count;

                    Json.deviceName = issueRecords[s].deviceName;
                    Json.deviceId = issueRecords[s].deviceId;
                    Json.deviceType = device_Type;s
                    Json.lastOccurrence = UtilityFunctions.incrementDateByMinutes(issueRecords[s].lastOccurence, incrementTime);//new Date().getTime();
                    Json.lastUpdateTime = UtilityFunctions.incrementDateByMinutes(issueRecords[s].lastOccurence, incrementTime);
                    Json.lastOccurrence = new Date(Json.lastOccurrence).getTime()
                    Json.lastUpdateTime = new Date(Json.lastUpdateTime).getTime()

                    incrementTime-= 10
                    issuesList.push(Json);
                }
            }

            if(issueMessage != undefined) {
                issuesList = issuesList.filter(issue =>  issue.issueMessage.toUpperCase().indexOf(issueMessage.toUpperCase())>-1 );
            }
            if(site != undefined) {
                issuesList = issuesList.filter(issue =>  issue.impactedLocation.toUpperCase().indexOf(site.toUpperCase())>-1 );
            }
            if(deviceType != undefined) {
                issuesList = issuesList.filter(issue =>  issue.deviceType == deviceType );
            }
            if(deviceName != undefined) {
                issuesList = issuesList.filter(issue =>  issue.deviceName == deviceName );
            }

            var tJson = JSON.parse(JSON.stringify(IssueGlobalCategoryTemplate.GlobalDetailTemplate));
            tJson.response = issuesList;
            tJson.page.pageSize =issuesList.length;
            tJson.page.offset = 1;
            tJson.page.totalCount = issuesList.length;
            tJson.impactedInfo.issueCount = issueRecords.length;
            tJson.impactedInfo.deviceCount = issueRecords.length;
            tJson.impactedInfo.areaCount = site_count.length; // its coming wrong check it
            tJson.impactedInfo.buildingCount = site_count.length;
            tJson.impactedInfo.floorCount = site_count.length;
            return tJson
        }
 }

 function getSiteCategoryDetail(urlAction){

        var siteId = urlAction.restPayload.filters.siteId;
        var siteType = urlAction.restPayload.filters.siteType; //remove
        var Name = urlAction.restPayload.filters.issueName;
        var startDt = urlAction.filter['startTime'];
        var endDt = urlAction.filter['endTime'];
        var hours = UtilityFunctions.getHours(urlAction.filter["startTime"], urlAction.filter["endTime"]);
        hours = (Math.round(hours));
        var incrementTime = -75

        var childrenSites=[],siteIdList = [], LocationNamesList = [];
        var siteList =SimulationUtility.getChildrenSites(siteId,childrenSites);
         siteIdList.push(siteId);
         for(var i=0;i<childrenSites.length;i++) {
             siteIdList.push(childrenSites[i].id);
             LocationNamesList.push(childrenSites[i].groupNameHierarchy);
         }
         if(siteType == "Floor"){
            var siteRec =SimLokiDatabaseActions.getFilteredRecordHandler("site", {"id": siteId});
            LocationNamesList.push(siteRec[0].groupNameHierarchy);
         }
         var deviceList =   SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'siteId': { '$containsAny' :  siteIdList}});
         var deviceListNames = deviceList.map(a => a.hostname);

         //to get host connected to sites
         var hostRec = SimLokiDatabaseActions.getFilteredRecordHandler('host',{'location': { '$containsAny' : LocationNamesList}});
         var hostNames = hostRec.map(a => a.hostName);

        //check if the two records can be performed in one filter
         var issueDevRec = SimLokiDatabaseActions.getFilteredRecordHandler('issue',{'deviceName': { '$containsAny' : deviceListNames}});
         var issueHostRec = SimLokiDatabaseActions.getFilteredRecordHandler('issue',{'deviceName': { '$containsAny' : hostNames}});
         var allDevHostRec = issueDevRec.concat(issueHostRec); // issues of Appliedsite

         //get the record of issuename from issueCollection

         //var issues = SimLokiDatabaseActions.getFilteredRecordHandler('issue', { 'name': name });

         var issueList =[], issueRecords= [],site_count,siteList = [];
         for (var l=0; l<allDevHostRec.length; l++){
               //issueRecords.push(allDevHostRec.find(function(obj){if(issues[l].name === obj.name) return issues[l]}));
               if(allDevHostRec[l].name == Name){
                issueRecords.push(allDevHostRec[l]);
               }
         }
         issueRecords = issueRecords.filter(obj => obj!= undefined);

         /*code to have less issues during 24 hrs selection dnac_fury_executive demo ask*/
         let listToDisplay = ["mac_flap_trigger","infra_link_down","global_wireless_kairos_excessive_onboarding_failure_trigger",
                "global_wireless_kairos_excessive_onboarding_time_trigger","global_wireless_kairos_excessive_dhcp_time_trigger","switch_issue_power",
                "global_wireless_kairos_radio_cloud_app_throughput_drop_trigger","global_wireless_kairos_radio_throughput_drop_trigger",
                "network_device_poe_err_disabled_trigger","network_device_poe_power_deny_trigger","global_wireless_client_auth_fail_aaa_rej_trigger",
                "global_wireless_client_auth_fail_sec_param_mismatch_trigger","global_sensor_webauth_fail_trigger",
                "global_wireless_client_excess_auth_wlc_trigger","global_wireless_client_excess_auth_aaa_fail_trigger"]

         for(var s=0; s<issueRecords.length; s++){
                    var device_Type;
                    var impacted_Location;
                    if(issueRecords[s].entityType=="Device") {
                        if(issueRecords[s].entity.indexOf(":")>=0) {
                            var deviceRecord = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'macAddress': issueRecords[s].entity.toLowerCase() });
                        }else{
                            var deviceRecord = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'managementIpAddress': issueRecords[s].entity });
                        }
                        device_Type = deviceRecord[0].type;
                    }else if(issueRecords[s].entityType=="Client" || issueRecords[s].entityType == "Sensor"){
                        var hostRecord = SimLokiDatabaseActions.getFilteredRecordHandler('host', { 'id': issueRecords[s].entity });
                        device_Type = hostRecord[0].subType;
                    }
                    var siteRecord = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'id': siteId });
                    impacted_Location = siteRecord[0].groupNameHierarchy;
                    siteList.push(impacted_Location);

                    site_count = [...new Set(siteList)];
                    var issue_count = issueRecords[s].occurrences;

                    if(hours == 24){
                        issue_count = Math.round(issue_count/7) ;
                    }else if(hours == 3){
                        issue_count = Math.round((issue_count/56));
                        if(issue_count == 0){issue_count = 1} //to handle 0 case and still issue exists
                    }

                    var Json = JSON.parse(JSON.stringify(IssueGlobalCategoryTemplate.GlobalDetailBaseTemplate));
                    Json.issueId = issueRecords[s].id;
                    Json.issueMessage = issueRecords[s].summary;
                    Json.impactedLocation = impacted_Location;

                    //Json.occurrenceCount = issue_count;
                    Json.occurrenceCount = (listToDisplay.indexOf(issueRecords[s].name)>-1) ? 1 : issue_count;

                    Json.deviceName = issueRecords[s].deviceName;
                    Json.deviceId = issueRecords[s].deviceId;
                    Json.deviceType = device_Type;
                    Json.lastOccurrence = UtilityFunctions.incrementDateByMinutes(issueRecords[s].lastOccurence, incrementTime);//new Date().getTime();
                    incrementTime-= 5
                    issueList.push(Json);
         }
         var tJson = JSON.parse(JSON.stringify(IssueGlobalCategoryTemplate.GlobalDetailTemplate));
            tJson.response = issueList;
            tJson.page.pageSize =issueList.length;
            tJson.page.offset = 1;
            tJson.page.totalCount = issueList.length;
            tJson.impactedInfo.issueCount = issueRecords.length;
            tJson.impactedInfo.deviceCount = issueRecords.length;
            tJson.impactedInfo.areaCount = site_count.length;
            tJson.impactedInfo.buildingCount = site_count.length;
            tJson.impactedInfo.floorCount = site_count.length;
            return tJson
 }

 function priorityCountCalculation(fullIssueData){
            //global issue count start

            var Priority_List = [],countsData = [], valObj = {Total:0,P1:0,P2:0,P3:0,P4:0,AIDriven:0};
            fullIssueData.forEach(function(i) {
                    var obj = {};
                    var Priority = i.priority;

                    obj[Priority] = i.issueCount;
                    Priority_List.push(obj);
            });
            var p1count =0, p2count=0, p3count=0, p4count=0;
            Priority_List.forEach(function(a){
                if(Object.keys(a) == "P1"){
                     var value = parseInt(Object.values(a))
                     p1count += value
                }else if(Object.keys(a) == "P2"){
                     var value = parseInt(Object.values(a))
                      p2count += value
                }else if(Object.keys(a) == "P3"){
                     var value = parseInt(Object.values(a))
                      p3count += value
                }else if(Object.keys(a) == "P4"){
                     var value = parseInt(Object.values(a))
                      p4count += value
                }
            });
            var AiIssueCount =  fullIssueData.filter(obj => obj.isAIDriven == true).map(obj => obj.issueCount).reduce(
                                                                            (total, Ai) => { return total + Ai; }, 0);
            totcount = p1count+p2count+p3count+p4count;
            valObj.Total = totcount;
            valObj.P1 = p1count;
            valObj.P2 = p2count;
            valObj.P3 = p3count;
            valObj.P4 = p4count;
            valObj.AIDriven = (fullIssueData.length>0 && fullIssueData[0].isAIDriven == true)? totcount:AiIssueCount ;

            countsData.push(valObj);
            return countsData;
 }

 function getGlblOrSite_CountByPriority(urlAction,siteId,siteType){
    let issue_Data, calcCount
    if(siteId && siteType){
        issue_Data = IssuesBasedOnSiteData(urlAction,siteId,siteType);
    }else {
        issue_Data = getGlobalIssuesData(urlAction);
        if(urlAction.restPayload.filters.isAIDriven != undefined){
            issue_Data = issue_Data.filter(obj=> obj.isAIDriven)
        }
    }
    if(issue_Data.length>0){
        calcCount = priorityCountCalculation(issue_Data);
     }

     var Json = JSON.parse(JSON.stringify(IssueGlobalCategoryTemplate.IssueTopCountPriority));

     Json.response.PRIORITY[0].time = UtilityFunctions.getGivenDateAndTimeInValidFormat(UtilityFunctions.getTimeStamp());
     Json.response.PRIORITY[0].entries[0].count = (issue_Data.length>0)?calcCount[0].P1 :0;
     Json.response.PRIORITY[0].entries[1].count = (issue_Data.length>0)?calcCount[0].P2 :0;
     Json.response.PRIORITY[0].entries[2].count = (issue_Data.length>0)?calcCount[0].P3 :0;
     Json.response.PRIORITY[0].entries[3].count = (issue_Data.length>0)?calcCount[0].P4 :0;
     Json.response.PRIORITY[0].entries[4].count = (issue_Data.length>0)?calcCount[0].Total :0;
     Json.response.PRIORITY[0].entries[5].count = (issue_Data.length>0)?calcCount[0].AIDriven :0;
     var fabricIssueEntity = [
         {"id":"fabricSiteDriven","name":"fabricSiteDriven","itemType":"fabricSiteDriven","count":0,"reoccurredCount":null},
         {"id":"fabricVnDriven","name":"fabricVnDriven","itemType":"fabricVnDriven","count":0,"reoccurredCount":null}
     ];
     Json.response.PRIORITY[0].entries = Json.response.PRIORITY[0].entries.concat(fabricIssueEntity);
    return  Json;
 }

 function getGlobalTopcountBySite(urlAction){

    var siteHierachyData = issueSiteHierarchy(urlAction);
    var topTotalImpactedAreasInfo = siteHierachyData.response.filter(obj => obj.siteType != "Floor"
                               && obj.siteName != " All Sites" && obj.siteName != " All Buildings"
                               && obj.siteType != "Building" && obj.issues.total>0)
         var topAreas = topTotalImpactedAreasInfo.filter(function(e) {
            var Area = e.hierarchy.split('/');
            if(Area.length>4)
            return e
         })
    var entriesList= []
    var Json = JSON.parse(JSON.stringify(IssueGlobalCategoryTemplate.TopCountSiteTemplate));

    Json.response.SITE[0].time = UtilityFunctions.getGivenDateAndTimeInValidFormat(UtilityFunctions.getTimeStamp());
    topAreas.forEach(function(a){
            var tJson = JSON.parse(JSON.stringify(IssueGlobalCategoryTemplate.TopCountSiteEntriesTemplate));
            tJson.id = a.siteId;
            tJson.name = "Global/"+a.siteName;
            tJson.itemType = a.siteType;
            tJson.count = a.issues.total;
            tJson.nestedCounts[0].count = a.issues.P1;
            tJson.nestedCounts[1].count = a.issues.P2;
            tJson.nestedCounts[2].count = a.issues.P3;
            tJson.nestedCounts[3].count = a.issues.P4;
            entriesList.push(tJson)
    })
    Json.response.SITE[0].entries = entriesList;
    return Json;

 }

 function getSiteTopCountBySite(urlAction,siteId,siteType){

     var siteIssueData = IssuesBasedOnSiteData(urlAction,siteId,siteType);
     var siteHierachyData = issueSiteHierarchy(urlAction);
     var topImpactedData, entriesList = [];
     var timeformat = UtilityFunctions.getGivenDateAndTimeInValidFormat(UtilityFunctions.getTimeStamp());

     if(siteIssueData.length == 0){
         return {"version":"1.0","response":{"SITE":[{"time":timeformat}]}};
     }
     else{

         if(siteType == 'Area'){
             var topTotalImpactedBldngInfo = siteHierachyData.response.filter(obj => obj.siteType != "Floor"
                               && obj.siteName != " All Sites" && obj.siteName != " All Buildings"
                               && obj.siteType != "Area" && obj.issues.total>0)
             topImpactedData = topTotalImpactedBldngInfo.filter(obj => obj.parentSiteId == siteId)
         }
         if(siteType == "Building" || siteType == "Floor"){
            var totalImpactedFloorInfo = siteHierachyData.response.filter(obj => obj.siteType != "Building"
                                   && obj.siteName != " All Sites" && obj.siteName != " All Buildings"
                                   && obj.siteType != "Area" && obj.issues.total>0);
            if(siteType == 'Building'){
                 topImpactedData = totalImpactedFloorInfo.filter(obj => obj.parentSiteId == siteId)
            }else {
                topImpactedData = totalImpactedFloorInfo.filter(obj => obj.siteId == siteId)
            }
         }
         var Json = JSON.parse(JSON.stringify(IssueGlobalCategoryTemplate.TopCountSiteTemplate));
         Json.response.SITE[0].time = timeformat;
         topImpactedData.forEach(function(a){
                var tJson = JSON.parse(JSON.stringify(IssueGlobalCategoryTemplate.TopCountSiteEntriesTemplate));
                tJson.id = a.siteId;
                tJson.name = "Global/"+a.siteName;
                tJson.itemType = a.siteType;
                tJson.count = a.issues.total;
                tJson.nestedCounts[0].count = a.issues.P1;
                tJson.nestedCounts[1].count = a.issues.P2;
                tJson.nestedCounts[2].count = a.issues.P3;
                tJson.nestedCounts[3].count = a.issues.P4;
                entriesList.push(tJson)
         });
         Json.response.SITE[0].entries = entriesList;
         return Json;
     }
 }

 function filterSiteDataWithPriority(urlAction,siteId,siteType){

    var siteIssueData = IssuesBasedOnSiteData(urlAction,siteId,siteType);
    var priorityType = urlAction.restPayload.filters.priority;

    siteIssueData = siteIssueData.filter(function(obj){
        return (obj.priority == priorityType )
    }) ;

    return siteIssueData;

 }

 function filterGlobalDataWithPriority(urlAction){
    var priorityType = urlAction.restPayload.filters.priority;
    var globalData = getGlobalIssuesData(urlAction);
    var filterdata;

    if(urlAction.restPayload.filters.isAIDriven == undefined){
        filterdata = globalData.filter(obj => obj.priority == priorityType);
    }else if(priorityType == undefined && urlAction.restPayload.filters.isAIDriven != undefined) {
        filterdata = globalData.filter(obj => obj.isAIDriven == true);
    }else{
        filterdata = globalData.filter(obj => obj.isAIDriven == true && obj.priority == priorityType );
    }
    return filterdata;

 }


 function issuePriorityIntervals(urlAction, priotityData) {

    var tWindowTime = 15, DataList = [];
    var fromTime = urlAction.filter["startTime"];
    var modificationTime = urlAction.filter["modificationTime"];
    var hours = UtilityFunctions.getHours(urlAction.filter["startTime"], urlAction.filter["endTime"]);
    if(hours > 24){
       tWindowTime = 240;
    }else if (hours > 3){
       tWindowTime = 30;
    }else{
       tWindowTime = 15;
    }
    var incrementBy = UtilityFunctions.getMinuteToMilliSeconds(tWindowTime); // modificationTime
    var noOfIntervals = (hours * 60)/tWindowTime;
    var p1Value = 0,p2Value = 0,p3Value = 0,p4Value = 0;
    var p1Count = priotityData[0].P1;
    var p2Count = priotityData[0].P2;
    var p3Count = priotityData[0].P3;
    var p4Count = priotityData[0].P4;


    for(let i = noOfIntervals; i >= 1; i--){

        var tJson = JSON.parse(JSON.stringify(IssueGlobalCategoryTemplate.TopCountProrityInterval));

        tJson.time = UtilityFunctions.getGivenDateAndTimeInValidFormat(fromTime);
            if(tJson.entries[0].id == "P1"){
                var p1Value = Math.round((p1Count)/i);
                p1Count -= p1Value;
            }
            if(tJson.entries[1].id == "P2"){
                var p2Value = Math.round((p2Count)/i);
                p2Count -= p2Value;
            }
            if(tJson.entries[2].id == "P3"){
                var p3Value = Math.round((p3Count)/i);
                p3Count -= p3Value;
            }
            if(tJson.entries[3].id == "P4"){
                var p4Value = Math.round((p4Count)/i);
                p4Count -= p4Value;
            }
            tJson.entries[0].count = p1Value;
            tJson.entries[1].count = p2Value;
            tJson.entries[2].count = p3Value;
            tJson.entries[3].count = p4Value;
            tJson.entries[4].count = p1Value+p2Value+p3Value+p4Value;
            DataList.push(tJson)
            fromTime = fromTime + incrementBy;
    }
    return DataList;

 }

 function issueSiteHierarchy(urlAction) {

            var siteData = SimLokiDatabaseActions.getAll("site");
            var sitesInfo = [];

            var fullIssueData = getGlobalIssuesData(urlAction);
            //global issue count start
            var issues_count_total_List = fullIssueData.map(obj => obj.issueCount)
            var issuesCount_total = issues_count_total_List.reduce(function(a, b) { return a + b; }, 0);

            var Priority_List = [];
            fullIssueData.forEach(function(i) {
                    var obj = {};
                    var Priority = i.priority;
                    obj[Priority] = i.issueCount;
                    Priority_List.push(obj);
            });
            var p1count =0, p2count=0, p3count=0, p4count=0;
            Priority_List.forEach(function(a){
                if(Object.keys(a) == "P1"){
                     var value = parseInt(Object.values(a))
                     p1count += value
                }else if(Object.keys(a) == "P2"){
                     var value = parseInt(Object.values(a))
                      p2count += value
                }else if(Object.keys(a) == "P3"){
                     var value = parseInt(Object.values(a))
                      p3count += value
                }else if(Object.keys(a) == "P4"){
                     var value = parseInt(Object.values(a))
                      p4count += value
                }
            }); // global issue count end

            if(urlAction.restPayload.issueStatus == "active" || urlAction.restPayload.issueStatus == "resolved"  ||
             urlAction.restPayload.issueStatus == "ignored"){
                for (var i = 0; i < siteData.length; i++) {
                    for (var j = 0; j < siteData[i].additionalInfo.length; j++) {
                        if (siteData[i].additionalInfo[j].nameSpace == "Location") {
                            var siteType = siteData[i].additionalInfo[j].attributes.type;

                                var Json = JSON.parse(JSON.stringify(IssueGlobalCategoryTemplate.IssueSiteHierachy));

                                Json.hierarchy = siteData[i].groupNameHierarchy;
                                Json.siteName  = siteData[i].name;
                                Json.siteId    = siteData[i].id;
                                Json.parentSiteId = (siteType == 'area')? " All Sites" : siteData[i].parentId;
                                Json.parentSiteName = (siteType == 'area')? " All Sites" : getParentSiteName(siteData[i].parentId).split("/").slice(1).join("/");//siteData[i].name;
                                Json.siteType  = siteType.charAt(0).toUpperCase()+siteType.slice(1);
                                Json.latitude  = (siteType == 'building')? siteData[i].additionalInfo[1].attributes.latitude : null ;
                                Json.longitude = (siteType == 'building')? siteData[i].additionalInfo[1].attributes.longitude : null;
                                var siteIssueData = IssuesBasedOnSiteData(urlAction,siteData[i].id,siteType);
                                if(siteIssueData.length>0){
                                  var calcSiteCount = priorityCountCalculation(siteIssueData);
                                }
                                Json.issues.total = (siteIssueData.length>0)?calcSiteCount[0].Total :0;
                                Json.issues.P1 = (siteIssueData.length>0)?calcSiteCount[0].P1:0;
                                Json.issues.P2 = (siteIssueData.length>0)?calcSiteCount[0].P2:0;
                                Json.issues.P3 = (siteIssueData.length>0)?calcSiteCount[0].P3:0;
                                Json.issues.P4 = (siteIssueData.length>0)?calcSiteCount[0].P4:0;
                                sitesInfo.push(Json);

                        }
                    }
                 }
                var tJson = JSON.parse(JSON.stringify(IssueGlobalCategoryTemplate.AllBuildingTemplate));
                for(let i= 0; i<tJson.length;i++){
                    tJson[i].issues.total = issuesCount_total;
                    tJson[i].issues.P1 = p1count;
                    tJson[i].issues.P2 = p2count;
                    tJson[i].issues.P3 = p3count;
                    tJson[i].issues.P4 = p4count;

                }
                tJson = tJson.concat(sitesInfo)
                return {"version": "1.0", "response" : tJson}

            }else if(urlAction.restPayload.buildingId != undefined){

               var buildingid = urlAction.restPayload.buildingId;
               //in Floorlevel Api issueStatus is missing,its required parameter to frame the data
               urlAction.restPayload['issueStatus'] = "active";
               var recFloor = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'parentId': buildingid });
               var floorInfo = [];
               for(f=0; f<recFloor.length; f++){
                        var Json = JSON.parse(JSON.stringify(IssueGlobalCategoryTemplate.IssueSiteHierachy));
                        var siteType = "floor"
                        Json.hierarchy = recFloor[f].groupNameHierarchy;
                        Json.siteName  = recFloor[f].name;
                        Json.siteId    = recFloor[f].id;
                        Json.parentSiteId = recFloor[f].parentId;
                        Json.parentSiteName = recFloor[f].name;
                        Json.siteType  = siteType;
                        Json.latitude  =  null ;
                        Json.longitude =  null;
                        var siteIssueData = IssuesBasedOnSiteData(urlAction,recFloor[f].id,siteType);
                        if(siteIssueData.length>0){
                          var calcSiteCount = priorityCountCalculation(siteIssueData);
                        }
                        Json.issues.total = (siteIssueData.length>0)?calcSiteCount[0].Total :0;
                        Json.issues.P1 = (siteIssueData.length>0)?calcSiteCount[0].P1:0;
                        Json.issues.P2 = (siteIssueData.length>0)?calcSiteCount[0].P2:0;
                        Json.issues.P3 = (siteIssueData.length>0)?calcSiteCount[0].P3:0;
                        Json.issues.P4 = (siteIssueData.length>0)?calcSiteCount[0].P4:0;
                        floorInfo.push(Json);

               }
                return {"version": "1.0", "response" : floorInfo};
            }
       }
       function getReasonerToolData(issueNames){
        var tdata=[]
        var issuedata=SimLokiDatabaseActions.getFilteredRecordHandler('issue',{"issueNamekey": {'$in':issueNames}});
        for(i=0;i<issuedata.length;i++){
            var deviceDetails=SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'id':issuedata[i].deviceId})
            if(issuedata[i].status.status=="active"){
                deviceDetails[0].deviceUuid = deviceDetails[0].id;
                tdata.push(deviceDetails[0]);
            }
        }
        var date = new Date();
        var count=tdata.length
         return {"data": tdata ,"metadata":{"_appId":"assurance_issue_triggers",
         "timeRange":{"start": date ,"end":date},
         "pagination":{"count":count,"pageSize":100,"page":count,"pageCount":10}}}
        }



  return {

      init: function(){
      },

      getIssueDetail: function(urlAction){
          var tIssues = SimLokiDatabaseActions.getAll('issue');

          var customTIssues = SimLokiDatabaseActions.getAll('customIssue');
          tIssues = tIssues.concat(getCustomIssueAfter5Min(customTIssues));

          var i;
          var issues = [];
          var startDt, endDt;

          startDt = new Date(parseInt(urlAction.filter['startTime']));
          endDt = new Date(parseInt(urlAction.filter['endTime']));

          for(i = 0; i < tIssues.length; i++) {
              var issueDate = new Date(tIssues[i].timestamp);
              if(issueDate < startDt) { //&& tIssues[i].timestamp < endDt) {
                  continue;
              } else {
                    var time=new Date().getTime();

                  tIssues[i].timestamp = (time - Math.abs(UtilityFunctions.getRandomIntForRangeValues(2400000,3600000)));
                 //tIssues[i].timestamp=new Date().getTime();
                  issues.push(tIssues[i]);
              }
          }

          // SimLokiDatabaseActions.update('issue', tIssues);

          if(urlAction.filter['scope'] != undefined && urlAction.filter['scope'] == 'global') {
              var t = getGlobalIssuesWithUniqueIssues(urlAction, issues);
              if(urlAction.filter['status'] != undefined) {
                var filterData= t.filter(function (issue) {
                                     return issue.status==urlAction.filter['status'];
                                });
                return {"version":"1.0","response": filterData};
              }
              return {"version":"1.0","response": t};
          }

          return {"version":"1.0","response": issues};
          //return {"version":"1.0","response": tIssues}; //original swarup
      },

      getIssueDetailDescription :function(urlAction) {


          if(urlAction.filter['startTime'] != undefined && urlAction.filter['endTime'] != undefined) {
              if(urlAction.filter['entityName'] !=undefined && urlAction.filter['entityName']=="applicationName") {

              var entityId = urlAction.filter["entityId"];
              if(entityId == "MedicalRecords"){
                var rootcouseList = ["latency_issue_medical_records","ILPOWER-3-SHUT_OVERDRAWN"];
                var appIssues = SimLokiDatabaseActions.getFilteredRecordHandler('issue',{ 'rootCause' : { '$containsAny' :  rootcouseList }});
                var ipopIssue = SimLokiDatabaseActions.getFilteredRecordHandler('issue', {'$and': [ { 'name' : "switch_interface_input_output_errors"},{ 'deviceName':"p1.edge1-sda1.local" }]});
                appIssues = appIssues.concat(ipopIssue);
                return {"version":"1.0","response": appIssues};

              }else {
                    var entId = entityId.toLowerCase();
                    entId = entId=="webex"? "webx" : entId.replaceAll("-","_").replaceAll(" ","_");
                    var appIssues = SimLokiDatabaseActions.getFilteredRecordHandler('issue',{'rootCause':"latency_issue_"+entId});
                    /* since we have only clients and devices mapped to issues, when taking application issue,
                    there are chances that we have one issue, but on 2 diff clients. theyerby 2 diff issuesIds. 
                    To avoid, till we have ref, keeping only one to show in App360 page */
                    appIssues = [appIssues[0]];
                    return {"version":"1.0","response": appIssues};
                    //return {"version":"1.0"};
                }
              }
          }
          if(urlAction.filter['startTime'] != undefined && urlAction.filter['endTime'] != undefined) {

                //to get issues based on status
               var issueStatus = (urlAction.filter.status != undefined) ? urlAction.filter.status : "active";

               var hostIssueTime =  SimLokiDatabaseActions.getFilteredRecordHandler('sim-issue-timing', { 'hostId': urlAction.filter['entityId'] });

              if(hostIssueTime.length>0) {
                  console.log('time related call recvd..');
                  var hostIssues =  SimLokiDatabaseActions.getFilteredRecordHandler('issue', {"$and": [{ 'entity': urlAction.filter['entityId'] },{"status.status": issueStatus }]});

                  var customHostIssues =  SimLokiDatabaseActions.getFilteredRecordHandler('customIssue', { 'entity': urlAction.filter['entityId'] });
                  hostIssues = hostIssues.concat(getCustomIssueAfter5Min(customHostIssues));

                  if(hostIssues.length){
                        var d = new Date();
                        var n = d.getTime();

                      /*  for(var iT = 0; iT < hostIssues.length; iT++) {
                            hostIssues[iT].timestamp = n;
                        } */

                        var start = hostIssueTime[0].start;
                        var end =hostIssueTime[0].end;
                        var graphStartTime = hostIssueTime[0].graphStartDateTime;
                        var dayDiff = UtilityFunctions.date_diff_indays(new Date(graphStartTime),d) ;
                        var issueStartTime =dayDiff==1? graphStartTime +start*60*60*1000 : graphStartTime +(start+144)*60*60*1000 ;
                        var issueEndTime  = dayDiff==1? graphStartTime + end*60*60*1000 : graphStartTime + (end+144)*60*60*1000;

                        var inputStartTime = new Date(urlAction.filter['startTime']).getTime();
                        var inputEndTime = new Date(urlAction.filter['endTime']).getTime();
                        var hours = UtilityFunctions.getHours(urlAction.filter["startTime"],urlAction.filter["endTime"]);

                         if(urlAction.filter['entityId']== '6C:19:C0:BD:93:AB' || urlAction.filter['entityId']== '6C:18:C0:BD:93:00' ||urlAction.filter['entityId']== '6C:18:C0:BD:81:00' ) {

                          return {"version":"1.0","response": hostIssues};
                         }

                         // this case for default loading
                        if(hours>=24 || hours==3 ) {
                          //if(inputStartTime<issueStartTime && inputEndTime>issueEndTime) {

                                 this.updateIssueTimeStamp(urlAction,hostIssues,issueStartTime,graphStartTime,dayDiff);
                                return {"version":"1.0","response": hostIssues};

                        //   } else {

                        //          return {"version":"1.0","response": [] };
                        //   }
                        } else {

                             if( inputStartTime<=issueEndTime && inputEndTime>=issueStartTime )  {

                                      // for special case

                                    if(urlAction.filter['entityId']== '6C:19:C0:BD:87:C9' || urlAction.filter['entityId']== '6C:19:C0:BD:87:C7' ) {

                                      hostIssues =  SimLokiDatabaseActions.getFilteredRecordHandler('issue', {'$and': [ { 'entity': urlAction.filter['entityId'] },{ 'rootCause' : {'$nin':['latency_issue_ms_office_365','latency_issue_webx','latency_issue_MedicalRecords'] } }]});
                                    }
                                  return {"version":"1.0","response":hostIssues};

                             } else if(urlAction.filter['entityId']== '6C:19:C0:BD:87:C9' || urlAction.filter['entityId']== '6C:19:C0:BD:87:C7') {
                                      var startTime =  dayDiff==1? graphStartTime+3*60*60*1000+180000 :graphStartTime+(4+144)*60*60*1000+180000;
                                      var endTime  =  dayDiff==1? graphStartTime +3*60*60*1000+420000 :graphStartTime +(4+144)*60*60*1000+420000;
                                        if(inputStartTime<=endTime && inputEndTime>=startTime ) {
                                           hostIssues =  SimLokiDatabaseActions.getFilteredRecordHandler('issue', {'$and': [ { 'entity': urlAction.filter['entityId'] },{ 'rootCause':{ '$in':['latency_issue_ms_office_365','latency_issue_webx','latency_issue_MedicalRecords',"EXCESSIVE_DHCP","EXCESSIVE_AUTH_FAIL_CLIENT"] }}]});

                                           return {"version":"1.0","response":hostIssues};
                                        } else  {
                                           return {"version":"1.0","response": [] };
                                        }
                              } else {

                                return {"version":"1.0","response": [] };
                             }

                        }
                  }
              }

          }

		// Device Time Travel issue
        if(urlAction.filter['startTime'] != undefined && urlAction.filter['endTime'] != undefined) {
            var deviceRecord;
            var hostIssueTime=[];
            //to get issues based on status "active"/"resolved"
            var issueStatus = (urlAction.filter.status != undefined) ? urlAction.filter.status : "active";

           if(urlAction.filter['entityId']!=undefined) {
             if(urlAction.filter['entityName'] !=undefined && urlAction.filter['entityName']=="macAddress") {
                 deviceRecord=SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {"macAddress":urlAction.filter['entityId'].toLowerCase()});
             } else if(urlAction.filter['entityName'] !=undefined && urlAction.filter['entityName']=="deviceUuid") {
                 deviceRecord=SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {"id":urlAction.filter['entityId']});
             } else {
                 deviceRecord=SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {"managementIpAddress":urlAction.filter['entityId']});

             }
             if(deviceRecord.length>0) {
                 hostIssueTime =  SimLokiDatabaseActions.getFilteredRecordHandler('sim-issue-timing-device', { 'hostName': deviceRecord[0].hostname });
             }

            if(hostIssueTime.length>0 && urlAction.filter['entityType']!=undefined) {
                console.log('time related call recvd..');
                var hostIssues =  SimLokiDatabaseActions.getFilteredRecordHandler('issue', {"$and" :[{ 'entity': urlAction.filter['entityId'].toUpperCase() },{'status.status': issueStatus}]});

                if(hostIssues.length){
                        var d = new Date();
                        var n = d.getTime();

                        var start = hostIssueTime[0].start;
                        var end =hostIssueTime[0].end;
                        var graphStartTime = hostIssueTime[0].graphStartDateTime;
                        var dayDiff = UtilityFunctions.date_diff_indays(new Date(graphStartTime),d) ;
                        var issueStartTime =dayDiff==1? graphStartTime +start*60*60*1000 : graphStartTime +(start+144)*60*60*1000 ;
                        var issueEndTime  = dayDiff==1? graphStartTime + end*60*60*1000 : graphStartTime + (end+144)*60*60*1000;

                        var inputStartTime = new Date(urlAction.filter['startTime']).getTime();
                        var inputEndTime = new Date(urlAction.filter['endTime']).getTime();
                        var hours = UtilityFunctions.getHours(urlAction.filter["startTime"],urlAction.filter["endTime"]);

                        // this case for default loading
                        if(hours>=24 || hours==3 ) {
                            if(inputStartTime<issueStartTime && inputEndTime>issueEndTime) {
                                return {"version":"1.0","response": hostIssues};
                            }
                            else {
                                 
                                // this is temporary solution we need to analyse why for the device AP4800 why issue are not being showed 
                                // graphStartTime = 0 is where one point we an start analysing
                                return {"version":"1.0","response": hostIssues};

                                //return {"version":"1.0","response": [] };
                        }
                        } else {

                            if( inputStartTime<=issueEndTime && inputEndTime>=issueStartTime )  {
                                return {"version":"1.0","response":hostIssues};

                            } else {
                                 // this is temporary solution we need to analyse why for the device AP4800 why issue are not being showed 
                                // graphStartTime = 0 is where one point we an start analysing
                                return {"version":"1.0","response": hostIssues};
                                //return {"version":"1.0","response": [] };
                            }

                        }
                }
            }

           }

        }

          var filter;
          if(urlAction.filter['entityId'] != undefined && urlAction.filter['entityId'] != '') {
            //to get issues based on status "active"/"resolved"
            var issueStatus = (urlAction.filter.status != undefined) ? urlAction.filter.status : "active";
              var entityId = urlAction.filter['entityId'];

              if( urlAction.filter['entityName']=="macAddress") {
                  entityId =entityId.toUpperCase();
              } else if( urlAction.filter['entityName']=="deviceUuid") {
                  entityId = deviceRecord[0].managementIpAddress;
              }
              var issues = SimLokiDatabaseActions.getFilteredRecordHandler('issue', {'$and':[{ 'entity': entityId  },{'status.status':issueStatus }]});
              
              var customIssues = SimLokiDatabaseActions.getFilteredRecordHandler('customIssue', { 'entity': entityId  });
              issues = issues.concat(getCustomIssueAfter5Min(customIssues));

              var tIssues = [];
              var startDt, endDt;

              startDt = new Date(parseInt(urlAction.filter['startTime']));
              endDt = new Date(parseInt(urlAction.filter['endTime']));

              for(i = 0; i < issues.length; i++) {
                  var issueDate = new Date(issues[i].timestamp);
                  if(issueDate > startDt) { //&& tIssues[i].timestamp < endDt) {//org swarup..
                     var time =new Date().getTime();
                      issues[i].timestamp = (time - Math.abs(UtilityFunctions.getRandomIntForRangeValues(2400000,3600000)));
                  }
                  tIssues.push(issues[i]);
                  tIssues.sort(function(a, b){
                    if ( a.priority <  b.priority)
                        return -1
                    if (a.priority > b.priority)
                        return 1
                    return 0
                  });
              }
              return {"version":"1.0","response": tIssues};
              //return {"version":"1.0","response": issues};
          }
          else if(urlAction.filter['status'] != undefined  && ['resolved','ignored'].indexOf(urlAction.filter['status'] > -1)) {
              return {"version":"1.0","response": [] };
          }

          var tIssueId = getIssueId(urlAction);

          if(urlAction.url.indexOf('impacted-hosts') >= 0) {
              return getImpactedHostsDetail(tIssueId);
          }
          if(urlAction.url.indexOf('onboarding') >= 0) {
              return ClientOnboardingHelper.getClientOnboatrdingResponse(urlAction, tIssueId);
              //return getOnboarding(tIssueId);
          }


          if(tIssueId != '') {
            if(urlAction.service.indexOf('history') >= 0){
                var startDt = urlAction.filter['startTime'];
                var endDt = urlAction.filter['endTime'];
                response = []
                for(i=0;i<=1;i++){
                    var count = 1;
                    var startTime = UtilityFunctions.getRandomIntForRangeValues(startDt,endDt)
                    if(startTime > startDt && startTime < endDt){
                        var endTime = startTime + (600*3000)
                        response.push({count,startTime,endTime})
                    }else { continue ; }
                }
                return {"version":"1.0","timeRange":{"startTimestamp":startDt,"endTimestamp":endDt},"response": response}

            }else {
                return getIssueDetailInfo(tIssueId);
            }

          }



          return getIssueBasedOnFilter(urlAction);

      },
      updateIssueTimeStamp(urlAction,issueList,issueStartTime,graphStartTime,dayDiff) {

         if(urlAction.filter['entityId']== '6C:19:C0:BD:87:C9' || urlAction.filter['entityId']== '6C:19:C0:BD:87:C7' ) {

                for(var i = 0; i < issueList.length; i++) {



                      if(issueList[i].rootCause== 'latency_issue_MedicalRecords'||issueList[i].rootCause== 'latency_issue_ms_office_365' || issueList[i].rootCause=='latency_issue_webx') {
                        var startTime =  dayDiff==1? graphStartTime+3*60*60*1000 :graphStartTime+(3+144)*60*60*1000;

                         issueList[i].timestamp = (startTime + Math.abs(UtilityFunctions.getRandomIntForRangeValues(60000,300000)));

                      } else {

                        issueList[i].timestamp = (issueStartTime + Math.abs(UtilityFunctions.getRandomIntForRangeValues(60000,300000)));
                      }

               }

         }
      },
      getImpactedHostsColumnMappings :function() {

       return impacted_hosts_column_mappings.mappings;
      },


      getGlobalCategoryIssue : function (urlAction) {

         if(urlAction.restPayload.issueStatus=="active" && urlAction.restPayload.issueStatus != undefined  ){

            var startDt = urlAction.filter['startTime'];
            var endDt = urlAction.filter['endTime'];
            var filterData = urlAction.restPayload.filters;
            if(urlAction.restPayload.filters.isFabricDriven) {
                return AssuranceFabricTemplate.GlobalFabricIssues_Latest;
            }
            if(Object.keys(filterData).length == 0 ){
                var TotData = getGlobalIssuesData(urlAction);
                 var t = {"version":"1.0","response": TotData };
            }else if(Object.keys(filterData).length > 0 && filterData.priority == undefined &&
                filterData.isAIDriven == undefined ) {

                var siteId = urlAction.restPayload.filters.siteId;
                var siteType = urlAction.restPayload.filters.siteType;

                var TotData = IssuesBasedOnSiteData(urlAction,siteId,siteType)
                var t =  {"version":"1.0","response": TotData };
            }else if(Object.keys(filterData).length > 0 && (filterData.priority != undefined ||
                filterData.isAIDriven != undefined)){

                if(filterData.siteId != undefined && filterData.siteType != undefined ){
                    var siteId = urlAction.restPayload.filters.siteId;
                    var siteType = urlAction.restPayload.filters.siteType;
                    var data = filterSiteDataWithPriority(urlAction,siteId,siteType);
                    var t =  {"version":"1.0","response": data };
                }else{
                    var data = filterGlobalDataWithPriority(urlAction);
                    var t =  {"version":"1.0","response": data };
                }
            }
            var hours = UtilityFunctions.getHours(urlAction.filter["startTime"], urlAction.filter["endTime"]);
            hours = Math.round(hours);
            if(hours >= 24 || hours < 24 ){
                return t;
            }else{
                return {"version":"1.0","response": [] };
            }
         }
      },

      getCategoryDetail : function(urlAction) {

        if((urlAction.restPayload.issueStatus=="active" && urlAction.restPayload.issueStatus != undefined) || urlAction.restPayload.issueStatus=="resolved") {

             var startDt = urlAction.filter['startTime'];
             var endDt = urlAction.filter['endTime'];
             var filterData = urlAction.restPayload.filters.siteType;
             var hours = UtilityFunctions.getHours(urlAction.filter["startTime"], urlAction.filter["endTime"]);
             hours = (Math.round(hours));
             if(filterData == "Global"){
                var gblCategoryDetailData = getGlobalCategoryDetail(urlAction);
                if(hours >= 24 || hours < 24) {
                    return gblCategoryDetailData;
                }
             }else if(filterData == "Area" || filterData == "Building" || filterData == "Floor" ) {
                var siteCategoryDetailData = getSiteCategoryDetail(urlAction);
                if(hours >= 24 || hours < 24) {
                    return siteCategoryDetailData;
                }
             }
        }
      },

      getcountByPriority : function(urlAction){
          if((urlAction.restPayload.issueStatus=="active" && urlAction.restPayload.issueStatus != undefined) || urlAction.restPayload.issueStatus=="resolved")
            var startDt = urlAction.filter['startTime'];
            var endDt = urlAction.filter['endTime'];
            var filterData = urlAction.restPayload.filters;
            var hours = UtilityFunctions.getHours(urlAction.filter["startTime"], urlAction.filter["endTime"]);
             hours = (Math.round(hours));

            if(Object.keys(filterData).length == 0 ){
                var TotData = getGlblOrSite_CountByPriority(urlAction);
                if(hours >= 24 || hours < 24) {
                    return TotData;
                }
            }else if(Object.keys(filterData).length > 0) {

                if(filterData.isAIDriven != undefined){
                    var TotData = getGlblOrSite_CountByPriority(urlAction);

                }else{
                    var siteId = urlAction.restPayload.filters.siteId;
                    var siteType = urlAction.restPayload.filters.siteType;
                    var TotData = getGlblOrSite_CountByPriority(urlAction,siteId,siteType);
                }

                if(hours >= 24 || hours < 24) {
                    return TotData;
                }
            }
      },


      getTopCountBySite : function(urlAction){

            var startDt = urlAction.filter['startTime'];
            var endDt = urlAction.filter['endTime'];
            var filterData = urlAction.restPayload.filters;
            var hours = UtilityFunctions.getHours(urlAction.filter["startTime"], urlAction.filter["endTime"]);
            hours = (Math.round(hours));


            if(Object.keys(filterData).length == 0 ){
                var TotData = getGlobalTopcountBySite(urlAction);
                if(hours >= 24 || hours < 24) {
                    return TotData;;
                }
            }else if(Object.keys(filterData).length > 0){

                var siteId = urlAction.restPayload.filters.siteId;
                var siteType = urlAction.restPayload.filters.siteType;
                var TotData = getSiteTopCountBySite(urlAction,siteId,siteType);
                 if(hours >= 24 || hours < 24) {
                    return TotData;;
                }
            }
      },

      getPriorityIntervals : function(urlAction) {

            var startDt = urlAction.filter['startTime'];
            var endDt = urlAction.filter['endTime'];
            var filterData = urlAction.restPayload.filters;
            var hours = UtilityFunctions.getHours(urlAction.filter["startTime"], urlAction.filter["endTime"]);
            hours = (Math.round(hours));
            var calculatedPriorityCounts;

            if(Object.keys(filterData).length == 0 ){
                var GlobalIssueData = getGlobalIssuesData(urlAction);
                if(GlobalIssueData.length>=0){
                    calculatedPriorityCounts = priorityCountCalculation(GlobalIssueData);
                }
                //var data = issuePriorityIntervals(urlAction);
            }else if(Object.keys(filterData).length > 0 ){

                var siteId = urlAction.restPayload.filters.siteId;
                var siteType = urlAction.restPayload.filters.siteType;

                var siteIssueData = IssuesBasedOnSiteData(urlAction,siteId,siteType);
                 if(siteIssueData.length>=0){
                     calculatedPriorityCounts = priorityCountCalculation(siteIssueData);
                 }

            }
            var totalData = issuePriorityIntervals(urlAction,calculatedPriorityCounts);

            return {"version": "1.0", "response": {"PRIORITY": totalData }};
      },

      getIssueSiteHierarchy : function (urlAction) {

        var siteData = issueSiteHierarchy(urlAction);
        return siteData;

      },

       getResolvedIssueData: function(urlAction){

         var status = urlAction.restPayload.issueStatus;
         var issues = SimLokiDatabaseActions.getFilteredRecordHandler("issue",{"status.status" : status});
         var resolvedIssueRec = []

           if(issues.length>0) {
                console.log("resolved issues are present")
                var globalData = getGlobalIssuesData(urlAction);
               issues.forEach(function(issuesObj){
                    var issueRec = globalData.find(globalDataObj => (globalDataObj.name == issuesObj.name))
                    resolvedIssueRec.push(issueRec)
                })

                return {"version": "1.0","response": resolvedIssueRec };

           }else {
                return { "version": "1.0", "response": [] };
           }
       },

        updateIssueStatus: function(issueIds, status, updatedBy){
            var issues = [], result = {"successful": [], "failures": []};
            status = status!=undefined ? status : "resolved";
            updatedBy = updatedBy!=undefined ? updatedBy : "admin";
            var issues = SimLokiDatabaseActions.getFilteredRecordHandler("issue",{"id" : {'$in': issueIds}} );
            issues.forEach(issue => {
                issue.status.status = status;
                issue.status.updatedBy = updatedBy;
                issue.status.updatedAt = new Date().getTime();
                result.successful.push(issue.id);
            });        
            SimLokiDatabaseActions.update('issue', issues);
            result.failures = issueIds.filter(e => result.successful.indexOf(e)===-1)
            return {"response": result} ;
        },

       getDeviceDetailInfoForIssue: function(urlAction){
           let query = urlAction.filter.query,
           preString = "has('",
           finalString = ".toList",
           pstrIndex = query.lastIndexOf(preString),
           sstrIndex = query.lastIndexOf(finalString);
           let ipAddr = query.substring(pstrIndex+4,sstrIndex-1).replace(/['']/g,"").split(',')[1].trim();
           var Json = JSON.parse(JSON.stringify(NetworkDeviceDetailsTemplate.Template));
           var deviceDetails = SimLokiDatabaseActions.getFilteredRecordHandler("network-device",{"managementIpAddress" : ipAddr});
           if(deviceDetails.length>0){
               Json[0].id = deviceDetails[0].id;
               Json[0].timestamp = new Date()
               Json[0].properties.managementIpAddr[0].value = deviceDetails[0].managementIpAddress
               Json[0].properties.serialNumber[0].value = deviceDetails[0].serialNumber
               Json[0].properties.communicationState[0].value = deviceDetails[0].reachabilityStatus
               Json[0].properties.nwDeviceName[0].value = deviceDetails[0].hostname
               Json[0].properties.platformId[0].value = deviceDetails[0].platformId
               Json[0].properties.nwDeviceId[0].value = deviceDetails[0].id
               Json[0].properties.nwDeviceRole[0].value = deviceDetails[0].role
               Json[0].properties.nwDeviceFamily[0].value = deviceDetails[0].family
               Json[0].properties.macAddress[0].value =  deviceDetails[0].macAddress
               Json[0].properties.deviceSeries[0].value = deviceDetails[0].series
               Json[0].properties.lastBootTime[0].value = new Date().getTime()
               Json[0].properties.softwareVersion[0].value = deviceDetails[0].softwareVersion
               Json[0].properties.nwDeviceType[0].value = deviceDetails[0].type
               return Json;
           }
       },

       getTopTenIssues : function(urlAction){
        var globalIssuedata = getGlobalIssuesData(urlAction);
        var topTenIssues = [];
        globalIssuedata.map(obj =>{
            if(obj.name === 'fabric_reachability_ise_trigger' || obj.name === 'infra_link_down' ||
            obj.name === 'wlc_unreachable' || obj.name === 'global_wireless_client_auth_fail_aaa_rej_trigger' ||
            obj.name === 'global_wireless_client_auth_fail_client_timeout_trigger' ||
            obj.name === 'global_wireless_client_auth_fail_sec_param_mismatch_trigger' ||
            obj.name === 'mac_flap_trigger' || obj.name === 'global_sensor_webauth_fail_trigger' ||
            obj.name === 'network_device_poe_err_disabled_trigger' || obj.name === 'network_device_controller_poe_trigger'){
            topTenIssues.push(obj)
            }
            return topTenIssues
        })
        return { "version": "1.0", "response": topTenIssues };
       },

       createTaskId : function(urlAction){
            window.issue_Id = urlAction.action.id;
            var resultObj = SimTaskDataGenerator.createTask('MLCN Service',{'id':UtilityFunctions.generate_uuid()});
            //For the page to stay on MRE's action, task shouldnt be completed. hence commenting..
            //SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });
            resultObj.statusCode = 202;
            return {"response":resultObj, "version":"1.0", statusCode:202};
       },

       getInterfaceConnectivityMetricData : function(urlAction){

            var query = urlAction.restPayload.query.split(' ');
            var deviceIp , eventName, deviceIpLen, eventNameLen, tData = [], DeviceRec;
            deviceIpLen = query[7].length;
            eventNameLen = query[11].length;
            deviceIp = query[7].slice(1,deviceIpLen-1);
            eventName = query[11].slice(1,eventNameLen-1);
            let issueNames=urlAction.restPayload.query.substring(urlAction.restPayload.query.indexOf('(')+1,urlAction.restPayload.query.indexOf(')'));
            issueNames = issueNames.replaceAll("'","").replaceAll(" ","").split(",");

            DeviceRec = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'managementIpAddress': deviceIp});
            
            if(issueNames.length>0) {
                return getReasonerToolData(issueNames);
            } else if(DeviceRec.length>0){
                for(let i=0; i< 60; i++){
                    var tJson = JSON.parse(JSON.stringify(InteraceConnectivityIssueTemplate.Template));
                    tJson.managementIpAddr = deviceIp
                    tJson._name = eventName
                    tJson._key = "NetworkDevice"+":"+deviceIp;
                    tJson.deviceRole = DeviceRec[0].role
                    tJson.nwDeviceName = DeviceRec[0].hostname
                    tJson.entityId = deviceIp
                    tJson._eventTime = UtilityFunctions.getTimeStamp();
                    tJson._mostRecent =  UtilityFunctions.getTimeStamp();
                    tJson.eventStatus = 'Down'
                    tData.push(tJson)
                }
            }
            console.log(tData)
            var date = new Date();
            return {"data": tData ,"metadata":{"_appId":"assurance_issue_triggers",
            "timeRange":{"start": date ,"end":date},
            "pagination":{"count":10,"pageSize":100,"page":1,"pageCount":10}}}
       },

       getAssuranceReasonerData : function(urlAction){

            let id = urlAction.action.id;
            let currTime = UtilityFunctions.getTimeStamp(), delayFactor = 5000;//to show in progress type in MRE
            var issueId = window.issue_Id;
            let issueObj = SimLokiDatabaseActions.getFilteredRecordHandler("issue",{'id':issueId});
            let issueNameKey = issueObj[0].name
            let issuesAssignedtoDev = SimLokiDatabaseActions.getFilteredRecordHandler("issue",{'name':issueObj[0].name})
            let otherDeviceWthIssue = issuesAssignedtoDev.filter(e => e.issueId !== issueId )
            let deviceName = issueObj[0].deviceName;
            let device2Name = otherDeviceWthIssue[0].deviceName
            let peerDeviceIp = otherDeviceWthIssue[0].entity
            let timeStamp = UtilityFunctions.getTimeStamp();
            let interfaceRec = SimLokiDatabaseActions.getFilteredRecordHandler('device-interface',{'deviceId': issueObj[0].deviceId });
            let interfaceDown = interfaceRec.filter(obj => obj.status === 'down');
            let intf1 = interfaceDown.length>0 ? interfaceDown[0].portName : 'GigabitEthernet1/10';
            let entity = issueObj[0].entity;
            if (!window.nextStep || window.nextStep === 1) {
                window.nextStep = 2, window.simStartTime = currTime;
                window.X = JSON.parse(JSON.stringify(assuranceReasonerTemplate[issueNameKey].partialTemplate1.response));
                window.X.map(obj=>{
                    obj.workflowExecutionId = id;
                    obj.instanceUuid = UtilityFunctions.generate_uuid;
                    obj.timestamp = timeStamp;
                    if(obj.targetOutput){
                        obj.targetOutput = obj.targetOutput.replace(/DeviceNamePlaceHolder/g,deviceName)
                        obj.targetOutput = obj.targetOutput.replace(/PeerDevNamePlaceHolder/g,device2Name)
                        obj.targetOutput = obj.targetOutput.replace(/IpPlaceHolder/g,entity);
                        obj.targetOutput = obj.targetOutput.replace(/PeerIP/g,peerDeviceIp);
                        obj.targetOutput = obj.targetOutput.replace(/InterfaceDownPlaceHolder/g,intf1);
                    }
                })
               window.T = window.X
            }
            else if (window.nextStep === 2 && currTime > (window.simStartTime + delayFactor) ) {
                window.nextStep = 3
                window.Y = JSON.parse(JSON.stringify(assuranceReasonerTemplate[issueNameKey].partialTemplate2.response));
                Y.map(obj=>{
                    obj.workflowExecutionId = id;
                    obj.instanceUuid = UtilityFunctions.generate_uuid;
                    obj.timestamp = timeStamp;
                    if(obj.targetOutput){
                        obj.targetOutput = obj.targetOutput.replace(/DeviceNamePlaceHolder/g,deviceName)
                        obj.targetOutput = obj.targetOutput.replace(/PeerDevNamePlaceHolder/g,device2Name)
                        obj.targetOutput = obj.targetOutput.replace(/IpPlaceHolder/g,entity);
                        obj.targetOutput = obj.targetOutput.replace(/PeerIP/g,peerDeviceIp);
                        obj.targetOutput = obj.targetOutput.replace(/InterfaceDownPlaceHolder/g,intf1);
                    }
                    if(obj.metadata){
                        obj.metadata = obj.metadata.replace(/DeviceNamePlaceHolder/g,deviceName)
                        obj.metadata = obj.metadata.replace(/IpPlaceHolder/g,entity);
                        obj.metadata = obj.metadata.replace(/PeerIP/g,peerDeviceIp);
                        obj.metadata = obj.metadata.replace(/InterfaceDownPlaceHolder/g,intf1);
                    }
                    if(obj.message){
                        obj.message = obj.message.replace(/DeviceNamePlaceHolder/g,deviceName);
                        obj.message = obj.message.replace(/IpPlaceHolder/g,entity);
                        obj.message = obj.message.replace(/InterfaceDownPlaceHolder/g,intf1);
                    }
                    if(obj.targetCommand){
                        obj.targetCommand = obj.targetCommand.replace(/DeviceNamePlaceHolder/g,deviceName);
                        obj.targetCommand = obj.targetCommand.replace(/IpPlaceHolder/g,entity);
                        obj.targetCommand = obj.targetCommand.replace(/PeerIP/g,peerDeviceIp);
                        obj.targetCommand = obj.targetCommand.replace(/InterfaceDownPlaceHolder/g,intf1);
                    }
                })
               window.T = window.T.concat(window.Y)
            }
            else if (window.nextStep === 3 && currTime > (window.simStartTime+(2*delayFactor)) ) {
                window.nextStep = 1; delete window.simStartTime;
                window.Z = JSON.parse(JSON.stringify(assuranceReasonerTemplate[issueNameKey].FinalTemplate.response));
                window.Z.map(obj=>{
                    obj.workflowExecutionId = id;
                    obj.instanceUuid = UtilityFunctions.generate_uuid;
                    obj.timestamp = timeStamp;
                    if(obj.targetOutput){
                        obj.targetOutput = obj.targetOutput.replace(/DeviceNamePlaceHolder/g,deviceName)
                        obj.targetOutput = obj.targetOutput.replace(/PeerDevNamePlaceHolder/g,device2Name)
                        obj.targetOutput = obj.targetOutput.replace(/IpPlaceHolder/g,entity);
                        obj.targetOutput = obj.targetOutput.replace(/PeerIP/g,peerDeviceIp);
                        obj.targetOutput = obj.targetOutput.replace(/InterfaceDownPlaceHolder/g,intf1);
                    }
                    if(obj.metadata){
                        obj.metadata = obj.metadata.replace(/DeviceNamePlaceHolder/g,deviceName)
                        obj.metadata = obj.metadata.replace(/PeerDevNamePlaceHolder/g,device2Name)
                        obj.metadata = obj.metadata.replace(/IpPlaceHolder/g,entity);
                        obj.metadata = obj.metadata.replace(/PeerIP/g,peerDeviceIp);
                        obj.metadata = obj.metadata.replace(/InterfaceDownPlaceHolder/g,intf1);
                    }
                    if(obj.message){
                        obj.message = obj.message.replace(/DeviceNamePlaceHolder/g,deviceName);
                        obj.message = obj.message.replace(/PeerDevNamePlaceHolder/g,device2Name)
                        obj.message = obj.message.replace(/IpPlaceHolder/g,entity);
                        obj.message = obj.message.replace(/PeerIP/g,peerDeviceIp);
                        obj.message = obj.message.replace(/InterfaceDownPlaceHolder/g,intf1);
                    }
                    if(obj.targetCommand){
                        obj.targetCommand = obj.targetCommand.replace(/DeviceNamePlaceHolder/g,deviceName);
                        obj.targetCommand = obj.targetCommand.replace(/PeerDevNamePlaceHolder/g,device2Name)
                        obj.targetCommand = obj.targetCommand.replace(/IpPlaceHolder/g,entity);
                        obj.targetCommand = obj.targetCommand.replace(/PeerIP/g,peerDeviceIp);
                        obj.targetCommand = obj.targetCommand.replace(/InterfaceDownPlaceHolder/g,intf1);
                    }
                })
                window.T = window.T.concat(window.Z)
            }
            return {"response": window.T,"version":"1.0"};
       },

        reasonerToolTroubleshoot: function(urlAction) {
            let reasonerIssueName = urlAction.service[urlAction.service.length-1], assuranceIssueName=[];
            switch (reasonerIssueName) {
                case "power_supply":
                    assuranceIssueName = ["switch_issue_power"];
                    break;
                case "cpu_utilization":
                    assuranceIssueName = ["router_cpu_high","switch_cpu_high","snmp_cpu_high_80"];
                    break;
                case "interface_flap":
                    assuranceIssueName = ["infra_link_down"];
                    break;
                default:
                    break;
            }
            let issue = SimLokiDatabaseActions.getFilteredRecordHandler('issue',{'$and':
                [{'name':{'$in':assuranceIssueName}},{"deviceId":urlAction.restPayload.devices[0]}]});
            urlAction.action.id = issue.length>0 ? issue[0].id : "";
            return this.createTaskId(urlAction);
        }

  };


});
