define({
    "CCO_CRED": {"username": "demoLicense", "password": "*******"},

    "SMART_CRED": {"username": "demoLicense", "password": "*******"},

    "DAY0_REG": {"default_va_account": "EFT FIELD SEVT", "day0_flag_enabled": true, "version": "1.0.0"},

    "SMART_ACCOUNT": {"username": "demoLicense", "id": "4395615917926549748", "domain": "pnp.test.com", "name": "PnP Test Account - KB"},

    "SMART_AGENT": {"domain": null, "authorization_status": {"auth_last_attempt_status": "Success", "auth_expiry_date": "", "auth_next_attempt_date": "", "status": "Evaluation mode (27 days remaining)", "mode": "warn", "auth_last_attempt_fail_reason": "", "auth_last_attempt_date": ""}, "export_control": "NOT ALLOWED", "user": "NA", "registration_status": {"reg_last_attempt_date": "", "status": "Unregistered", "mode": "warn", "reg_expiry_date": "", "reg_next_attempt_date": "", "reg_last_attempt_fail_reason": "", "reg_last_attempt_status": ""}, "virtual_account": null},

    "SMART_AGENT_ENTITLEMENT": {"entitlementMode": "warn", "entitlementStatus": "In Evaluation Period", "entitlementTag": "regid.2019-05.com.cisco.DNAC_44CoreAppliance,1.0_72c4ba1a-096d-439d-abe7-4a9a7fae64b3", "entitlementDescription": "Cisco DNAC Gen2 Regular (44 Core) Appliance", "entitlementUsageCount": 1},

    "SWIM_CCO": {"username": "dnacDemo", "encrypted_password": "AgV4DgeH+L2EW0AyxHSgWQadM/pWq2rwUQZ9C51h6ix0pSIJlvsmpjJdY3UpGRp5zXRPI+GqmQxDYRYZFJ/Z4xL8dJoFU+uB", "eula": false}

});