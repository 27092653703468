define([
        'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
        'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/DefaultConfig',
        'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
        'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    ],
    function (SimLokiDatabaseActions,DefaultConfig, UtilityFunctions, SimTaskDataGenerator) {

    //This function gives the children sites for the given siteId
    //@Param siteParentId  (id of the site, whose children are to be found)
    //@childrenSites Empty array, which will be populated with the children sites details
    function getChildren(siteId,childrenSites) {
        var siteAllData = SimLokiDatabaseActions.getAll('site');
        var flag = false;
        for(var site in siteAllData) {
            var siteInfoObj = siteAllData[site];
            if(siteInfoObj.parentId == siteId) {
                childrenSites.push(siteInfoObj);
                flag = true;
                getChildren(siteInfoObj.id,childrenSites);
                //break;
             }
         }
         if(flag == false){
             return;
         }
    }

    // this file contains helper routines for the simulation code
    return {
        //picked from ClientDataRateUtils.js from nsa
        PROTOCOLS_24: ['PROTOCOL_802_11_B', 'PROTOCOL_802_11_G', 'PROTOCOL_802_11_N_24', 'PROTOCOL_802_11_AX_24'],
        PROTOCOLS_5: ['PROTOCOL_802_11_A', 'PROTOCOL_802_11_N_5', 'PROTOCOL_802_11_AC', 'PROTOCOL_802_11_AX_5'],
        PROTOCOLS_6: ['PROTOCOL_802_11_AX_6'],
        DATARATE_N_AC_AX: ['PROTOCOL_802_11_N_24', 'PROTOCOL_802_11_N_5', 'PROTOCOL_802_11_AC', 'PROTOCOL_802_11_AX_24', 'PROTOCOL_802_11_AX_5', 'PROTOCOL_802_11_AX_6'],
        DATARATE_A_B_G: ['PROTOCOL_802_11_A', 'PROTOCOL_802_11_B', 'PROTOCOL_802_11_G'],
        PROTOCOLS: [
            { text: "802.11n2.4", value: 4, dnacVal: 'PROTOCOL_802_11_N_24', capability: "802.11n", capabilityVal: 2},
            { text: "802.11n5", value: 5, dnacVal: 'PROTOCOL_802_11_N_5', capability: "802.11n", capabilityVal: 2},
            { text: "802.11ac", value: 6, dnacVal: 'PROTOCOL_802_11_AC', capability: "802.11ac", capabilityVal: 4},
            { text: "802.11ax2.4", value: 7, dnacVal: 'PROTOCOL_802_11_AX_24', capability: "802.11ax", capabilityVal: 5},
            { text: "802.11ax5", value: 8, dnacVal: 'PROTOCOL_802_11_AX_5', capability: "802.11ax", capabilityVal: 5},
            { text: "802.11a", value: 1, dnacVal: 'PROTOCOL_802_11_A', capability: "802.11abg", capabilityVal: 1},
            { text: "802.11b", value: 2, dnacVal: 'PROTOCOL_802_11_B', capability: "802.11abg", capabilityVal: 1},
            { text: "802.11g", value: 3, dnacVal: 'PROTOCOL_802_11_G', capability: "802.11abg", capabilityVal: 1},
            { text: "802.11ax6", value: 10, dnacVal: 'PROTOCOL_802_11_AX_6', capability: "802.11ax6", capabilityVal: 7},
        ],
        WIFI_6_PROTOCOL_MAPPING_INFO: {//used in wifi6 dashlets - this is for info only
            '0.0' : {
                'name':'unclassified',
                'protocolsCovered':['Unknown']
            },
            '1.0' : {
                'name':'protocols_11abg',
                'protocolsCovered':['PROTOCOL_802_11_A','PROTOCOL_802_11_B','PROTOCOL_802_11_G']
            },
            '2.0' : {
                'name':'protocols_11n',
                'protocolsCovered':['PROTOCOL_802_11_N_24','PROTOCOL_802_11_N_5']
            },
            '4.0' : {
                'name':'protocols_11ac',
                'protocolsCovered':['PROTOCOL_802_11_AC']
            },
            '5.0' : {
                'name':'wifi6',
                'protocolsCovered':['PROTOCOL_802_11_AX_24','PROTOCOL_802_11_AX_5']
            },
            '7.0' : {
                'name':'wifi6E',
                'protocolsCovered':['PROTOCOL_802_11_AX_6']
            },

        },

        //picked from nsa-web-application/ui-plugin/app/nsa/components/common360/models/DeviceInfoModel.js
        PROTOCOL_FOR_AP_INFO_SECTION: {
            '1': 'Legacy',
            '2': '11n',
            '3': '11ac',
            '4': 'Wi-Fi 6',
            '5': 'Wi-Fi 6E',
            '-1': 'Unknown'
          },

        getChildrenSites: function(siteId,childrenSites) {
            if(siteId == '-1'){
                siteId = DefaultConfig.DEFAULT_UUID.GlobalGroup;
            }
            getChildren(siteId,childrenSites);
            return childrenSites;
         },

         updateTaskError: function(errorCode,failureError,taskObj){
             taskObj.isError = true;
             taskObj.failureReason = failureError;
             taskObj.progress = '';
             taskObj.errorCode = errorCode;
             SimTaskDataGenerator.updateTaskCompletion(taskObj.taskId, {isError: true, failureReason: failureError });
         },

         getSiteName: function(siteName) {
             var obj = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'name' : siteName});
             return obj.length ? obj[0].id : '';
         },

         getSiteId: function(siteName) {
            //var obj = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'groupNameHierarchy' : siteName});
            var obj = SimLokiDatabaseActions.filterByRegx('site', 'groupNameHierarchy', siteName);
            return obj.length ? obj[0].id : '';
        },

         isBadDevice: function(networkDevice){
             return DefaultConfig.BAD_DEVICE.indexOf(networkDevice.hostname) >= 0 ? true: false; 
         },

         createNetworkDevice(jsonObj, devicePrefix) {
             var roleArr = ["ACCESS", "BORDER ROUTER", "CORE", "DISTRIBUTION"];
             var roleIdx = UtilityFunctions.getRandomIntForRangeValues(1, roleArr.length) - 1;
             jsonObj.role = roleArr[roleIdx];
             var roleObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'role':roleArr[roleIdx]});
             jsonObj.family = roleObj[0].family;
             jsonObj.type   = roleObj[0].type;
             jsonObj.platformId = roleObj[0].platformId;
             jsonObj.softwareVersion = roleObj[0].softwareVersion;

             return jsonObj;
             //var deviceDetails = SimDefaultDataGenerator.inventoryDevice(jsonObj,jsonObj.ipAddress,jsonObj.id,devicePrefix);
             //       return deviceDetails;
         },

         createRandomHostName: function(serialNumber, devicePrefix) {
             var count = SimLokiDatabaseActions.getCount('network-device');
             var randnum = count + 1;//UtilityFunctions.getRandomIntForRangeValues(count + 1, count + 10);
             var randstr = serialNumber.substr(serialNumber.length - 3, 3);
             var hostname = devicePrefix + randstr + '.' + randnum.toString() + '.cisco.com';
             return hostname;
         },

         readRawFile: function(fileName, callback) {
             var rawFile = new XMLHttpRequest();
             rawFile.open('GET', fileName);
             rawFile.onreadystatechange = function() {
                 if (rawFile.readyState === 4 && rawFile.status === 200) {
                     callback(rawFile.responseText, fileName);
                 }
             }
             rawFile.setRequestHeader("Access-Control-Allow-Origin", "*");
             //rawFile.setRequestHeader("Content-Type", "text/plain;charset=UTF-8"); 
             rawFile.send();
         },
        
        validSiteId: function(siteId) {
            if(['global','__global__'].indexOf(siteId) == -1) {
                var siteRecord = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id':siteId} );
                return siteRecord.length>0 ? siteId : 'global';
            }
            return 'global';
        },

        validateFields(obj, fields) {
            var invalidKeys = [];
            //valid typeof : boolean, number, string, object
            Object.keys(fields).forEach(function(key) {
                if(obj[key] == undefined || obj[key] == null) invalidKeys.push(key);
                if(typeof(obj[key]) != fields[key]) {
                    if(fields[key] == 'array') {
                        if(!Array.isArray(obj[key]) || obj[key].length==0) invalidKeys.push(key);
                    } else {
                        invalidKeys.push(key);
                    }
                }
            });
            return invalidKeys;
        },

        validateWifi6Config: function(family, softwareVersion, pid) { //might need to update the versions if required
            if([DefaultConfig.NetworkDevice.WLC_FAMILY, DefaultConfig.NetworkDevice.AP_FAMILY].indexOf(family) == -1) return false;
            if(softwareVersion && (softwareVersion.startsWith('8.10') || softwareVersion.startsWith('17.3') || 
                softwareVersion.startsWith('15.3.3') || softwareVersion.startsWith('17.9'))) {
                if(DefaultConfig.NetworkDevice.AP_FAMILY == family) {
                    if(pid!= undefined && pid.startsWith('C9')) 
                        return true
                    else 
                        return false;
                } else {//WLC
                    return true;
                }
            } else {
                return false;
            }
        },

        getAllBuildingsOnly(isWithParentInfo) {
            var records = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'additionalInfo.attributes.type' : 'building'});
            var result = []
            records.forEach(site => {
                var obj = {'siteId':site.id, 'siteName':site.name};
                if(isWithParentInfo) {
                    var parentName = site.groupNameHierarchy.split('/');
                    parentName = parentName[parentName.length - 2];
                    obj = {...obj, 'parentSiteId':site.parentId, 'parentSiteName':parentName};
                }
                result.push(obj);
            });
            return result;            
        }
 
    };
});
