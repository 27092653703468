define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/analytics_Template',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/SummaryAnalyticsTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/siteAnalytics/DnsNetworkServiceTemplate',  
], function (analytics_Template, SummaryAnalyticsTemplate, SimLokiDatabaseActions, UtilityFunctions, DnsNetworkServiceTemplate) {
    return {
        init: function () {
        },
 
        getTrendAnalyticsData: function (urlAction) {
            tJson = JSON.parse(JSON.stringify(analytics_Template.TrendAnalyticsTemplate));
            var data = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'family': "Unified AP" });
            var currentTime = urlAction.restPayload.currentTime;
            var startTime = urlAction.restPayload.startTime;
            var endTime = urlAction.restPayload.endTime;
            var timediffereceObj = UtilityFunctions.getHours(startTime, endTime)
            console.log(timediffereceObj,"timediffereceObj")
            var obj = tJson.data[0];
            tJson.data = [];
            while(startTime <= endTime){
                startTime += 300000;
                obj.time = startTime;
                    obj.values.successTransactionCount = UtilityFunctions.getRandomIntForRangeValues(50, 100);
                obj.values.failureTransactionCount = UtilityFunctions.getRandomIntForRangeValues(1, 50);
                obj.values.totalTransactionCount = obj.values.successTransactionCount + obj.values.failureTransactionCount;
                tJson.data.push(JSON.parse(JSON.stringify(obj)))
            }
            return tJson

        },
        getTrendAverageLatencyData: function (urlAction) {
            tJson = JSON.parse(JSON.stringify(analytics_Template.TrendAverageLatencyTemplate));
            var data = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'family': "Unified AP" });
            var startTime = urlAction.restPayload.startTime;
            var endTime = urlAction.restPayload.endTime;
            var timediffereceObj = UtilityFunctions.getHours(startTime, endTime)
            tJson.scope.startTime=startTime;
            tJson.scope.endTime = urlAction.restPayload.endTime;
            console.log(timediffereceObj,"timediffereceObj")
            var obj = tJson.data[0];
            tJson.data = [];
            for (var i = 0; i < data.length; i++) {
                startTime += 300000
                obj.time = startTime;
                let updatedTime = new Date(parseInt(startTime));
                updatedTime = updatedTime.toISOString().split('.')[0] + "+0000";
                obj.values.time = updatedTime;
                obj.values.nestedData[0].values.averageLatency = UtilityFunctions.getRandomIntForRangeValues(1, 30);
                tJson.data.push(JSON.parse(JSON.stringify(obj)))
            }
            return tJson
        },
        getTrendTotalTransactionData: function (urlAction) {
            tJson = JSON.parse(JSON.stringify(analytics_Template.TrendTotalTransactionAnalyticsTemplate));
            var data = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'family': "Unified AP" });
            var startTime = urlAction.restPayload.startTime;
            tJson.scope.startTime=startTime;
            tJson.scope.endTime = urlAction.restPayload.endTime;
            var obj = tJson.data[0];
            tJson.data = [];
            for (var i = 0; i < data.length; i++) {
                startTime += 300000;
                obj.time = startTime;
                let updatedTime = new Date(parseInt(startTime));
                updatedTime = updatedTime.toISOString().split('.')[0] + "+0000";
                obj.values.time = updatedTime;
                obj.values.nestedData[0].values.totalTransactionCount = UtilityFunctions.getRandomIntForRangeValues(1, 300);
                tJson.data.push(JSON.parse(JSON.stringify(obj)))
            }
            return tJson
        },
        getfailureTransactionData: function (urlAction) {
            tJson = JSON.parse(JSON.stringify(analytics_Template.TrendfailureTransactionTemplate));
            var data = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'family': "Unified AP" });
            var startTime = urlAction.restPayload.startTime;
            tJson.scope.startTime=startTime;
            tJson.scope.endTime= urlAction.restPayload.endTime;
            var obj = tJson.data[0];
            tJson.data = [];
            for (var i = 0; i < data.length; i++) {
                startTime += 300000
                obj.time = startTime;
                let updatedTime = new Date(parseInt(startTime));
                updatedTime = updatedTime.toISOString().split('.')[0] + "+0000";
                obj.values.time = updatedTime;
                obj.values.nestedData[0].values.failureTransactionCount = UtilityFunctions.getRandomIntForRangeValues(1, 10);
                tJson.data.push(JSON.parse(JSON.stringify(obj)))
            }
            return tJson
        },
        getSuccessTransactionData: function (urlAction) {
            tJson = JSON.parse(JSON.stringify(analytics_Template.TrendSuccessTransactionTemplate));
            var data = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'family': "Unified AP" });
            var startTime = urlAction.restPayload.startTime;
            tJson.scope.startTime=startTime;
            tJson.scope.endTime = urlAction.restPayload.endTime;
            var obj = tJson.data[0];
            tJson.data = [];
            for (var i = 0; i < data.length; i++) {
                startTime += 300000
                obj.time = startTime;
                let updatedTime = new Date(parseInt(startTime));
                updatedTime = updatedTime.toISOString().split('.')[0] + "+0000";
                obj.values.time = updatedTime;
                obj.values.nestedData[0].values.successTransactionCount = UtilityFunctions.getRandomIntForRangeValues(50, 200);
                tJson.data.push(JSON.parse(JSON.stringify(obj)))
            }
            return tJson
        },
        getSummaryAnalyticsData: function (urlAction) {
            tJson = JSON.parse(JSON.stringify(SummaryAnalyticsTemplate.SummaryAnalyticsTemplate));
            var data = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'family': "Unified AP" });
            if(startTime != undefined) {
            var startTime = urlAction.restPayload.startTime;
            var obj = tJson.data[0];
            tJson.data = [];
            for (var i = 0; i < data.length; i++) {
                startTime += 300000
                let updatedTime = new Date(parseInt(startTime));
                updatedTime = updatedTime.toISOString().split('.')[0] + "+0000";
                obj.values.time = updatedTime;
                obj.values.averageLatency = UtilityFunctions.getRandomIntForRangeValues(1, 20);
                obj.values.failureTransactionCount = UtilityFunctions.getRandomIntForRangeValues(1, 10);
                obj.values.successTransactionCount = UtilityFunctions.getRandomIntForRangeValues(50, 200);
                obj.values.totalTransactionCount = obj.values.failureTransactionCount + obj.values.successTransactionCount;
                obj.values.totalServerCount = UtilityFunctions.getRandomIntForRangeValues(1, 1);
                obj.values.insight.averageLatenc = UtilityFunctions.getRandomIntForRangeValues(1, 20);
                obj.values.insight.failureTransactionCount = UtilityFunctions.getRandomIntForRangeValues(1, 10);
                obj.values.insight.failureTransactionPercent = UtilityFunctions.getRandomIntForRangeValues(1, 100);
                obj.values.insight.successTransactionCount = UtilityFunctions.getRandomIntForRangeValues(50, 200);
                obj.values.insight.successTransactionPercent = UtilityFunctions.getRandomIntForRangeValues(1.000, 12.000);
                obj.values.insight.totalTransactionCount = obj.values.insight.failureTransactionCount + obj.values.insight.successTransactionCount;
                obj.values.insight.totalTransactionPercent = UtilityFunctions.getRandomIntForRangeValues(1.000, 12.000);
                obj.values.insight.averageLatencyPercent = UtilityFunctions.getRandomIntForRangeValues(0, 0);
                obj.values.insight.totalLatency = UtilityFunctions.getRandomIntForRangeValues(0, 0);
                obj.values.insight.totalServerCount = UtilityFunctions.getRandomIntForRangeValues(0, 1);

                tJson.data.push(JSON.parse(JSON.stringify(obj)))
            }
        }
            return tJson;
        },
        getListAnalyticsData: function (urlAction) {
            tJson = JSON.parse(JSON.stringify(analytics_Template.ListAnalyticsTemplate));
            var data = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'family': "Unified AP" });
            console.log(data, "data")
            var startTime = urlAction.restPayload.startTime;
            var obj = tJson.data[0];
            tJson.data = [];
            for (var i = 0; i < data.length; i++) {
                let siteRec = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'site': data[i].siteName});
                startTime += 300000
                let updatedTime = new Date(parseInt(startTime));
                updatedTime = updatedTime.toISOString().split('.')[0] + "+0000";
                obj.values.time = updatedTime;
                obj.values.successTransactionCount = UtilityFunctions.getRandomIntForRangeValues(50, 200);
                obj.values.failureTransactionCount = UtilityFunctions.getRandomIntForRangeValues(1, 10);
                obj.values.totalTransactionCount = obj.values.successTransactionCount + obj.values.failureTransactionCount; 
                obj.values.averageLatency = UtilityFunctions.getRandomIntForRangeValues(1, 20);
                obj.deviceFamily = data[i].family;
                obj.deviceName = data[i].name;
                obj.siteUUID = data[i].siteid;
                obj.siteName = siteRec[1].groupNameHierarchy;
                
                //   siteObj=SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id' : siteId });
                //   var siteName = siteObj[0].groupNameHierarchy;
                tJson.data.push(JSON.parse(JSON.stringify(obj)));
                
            
       // }
    } 
            return tJson;
        },
        
        getSummarySiteData: function (urlAction) {
            tJson = JSON.parse(JSON.stringify(SummaryAnalyticsTemplate.SummaryAnalyticsTemplate));
            var data = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'family': "Unified AP" });
            var startTime = urlAction.restPayload.startTime;
            var obj = tJson.data[0];
            tJson.data = [];
            for (var i = 0; i < data.length; i++) {
                startTime += 300000
                let updatedTime = new Date(parseInt(startTime));
                updatedTime = updatedTime.toISOString().split('.')[0] + "+0000";
                obj.values.time = updatedTime;
                obj.values.averageLatency = UtilityFunctions.getRandomIntForRangeValues(0, 0);
                obj.values.failureTransactionCount = UtilityFunctions.getRandomIntForRangeValues(0, 0);
                obj.values.successTransactionCount = UtilityFunctions.getRandomIntForRangeValues(0, 0);
                obj.values.totalTransactionCount = obj.values.failureTransactionCount + obj.values.successTransactionCount;
                obj.values.totalServerCount = UtilityFunctions.getRandomIntForRangeValues(0, 0);
                tJson.data.push(JSON.parse(JSON.stringify(obj)))
            }
            return tJson
        },
        getTopNSiteData: function (urlAction) {
            tJson = JSON.parse(JSON.stringify(analytics_Template.TopNAnalyticsTemplate));
            var data = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'family': "Unified AP" });
            
            for (var i = 0; i < data.length; i++) {
                let siteRec = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'site': data[i].siteName});
                var startTime = urlAction.restPayload.startTime;
                var obj = tJson.data[0];
                tJson.data = [];
                startTime += 300000
                let updatedTime = new Date(parseInt(startTime));
                updatedTime = updatedTime.toISOString().split('.')[0] + "+0000";
                obj.values.time = updatedTime;
                obj.values.failureTransactionCount = UtilityFunctions.getRandomIntForRangeValues(1, 10);
                obj.siteName = siteRec[0].groupNameHierarchy;
                obj.siteUUID = data[i].siteid;
                obj.serverIpAddress = "8.8.8.8";
                tJson.data.push(JSON.parse(JSON.stringify(obj)))

            }
            return tJson
        },
        getTopNFailureTransactionData: function (urlAction) {
            tJson = JSON.parse(JSON.stringify(analytics_Template.TopNFailureTransactionTemplate));
            var data = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'family': "Unified AP" });
            for (var i = 0; i < data.length; i++) {
                let siteRec = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'site': data[i].siteName});
                var startTime = urlAction.restPayload.startTime;
                var obj = tJson.data[0];
                tJson.data = [];
                startTime += 300000
                let updatedTime = new Date(parseInt(startTime));
                updatedTime = updatedTime.toISOString().split('.')[0] + "+0000";
                obj.values.time = updatedTime;
                obj.values.failureTransactionCount = UtilityFunctions.getRandomIntForRangeValues(1, 10);
                obj.siteName = siteRec[29].groupNameHierarchy;
                obj.siteUUID = data[i].siteid;
                obj.serverIpAddress = "8.8.8.8";
                tJson.data.push(JSON.parse(JSON.stringify(obj)))
            }
            return tJson
        },
        getTopNResponseCodedata: function (urlAction) {
            tJson = JSON.parse(JSON.stringify(analytics_Template.TopNResponseCodeTemplate));
            var data = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'family': "Unified AP" });
            for (var i = 0; i < data.length; i++) {    
                
                var startTime = urlAction.restPayload.startTime;
                var obj = tJson.data[0];
                tJson.data = [];
                startTime += 300000
                let updatedTime = new Date(parseInt(startTime));
                updatedTime = updatedTime.toISOString().split('.')[0] + "+0000";
                obj.values.time = updatedTime;
                obj.values.failureTransactionCount = UtilityFunctions.getRandomIntForRangeValues(1, 10);
                obj.values.responseCodeCount = UtilityFunctions.getRandomIntForRangeValues(0, 20);
                obj.responseCode = UtilityFunctions.getRandomIntForRangeValues(0, 20);
                obj.serverIpAddress = "8.8.8.8";
                tJson.data.push(JSON.parse(JSON.stringify(obj)))
            }
            return tJson
        },

        getTopNAverageLatencyData: function (urlAction) {
            tJson = JSON.parse(JSON.stringify(analytics_Template.TopNAverageLatencyTemplate));
            var data = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'family': "Unified AP" });
            for (var i = 0; i < data.length; i++) {
                let siteRec = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'site': data[i].siteName});
                var startTime = urlAction.restPayload.startTime;
                var obj = tJson.data[0];
                tJson.data = [];
                startTime += 300000
                let updatedTime = new Date(parseInt(startTime));
                updatedTime = updatedTime.toISOString().split('.')[0] + "+0000";
                obj.values.time = updatedTime;
                obj.values.averageLatency = UtilityFunctions.getRandomIntForRangeValues(1, 20);
                obj.siteName = siteRec[0].groupNameHierarchy;
                obj.serverIpAddress = "8.8.8.8";
                tJson.data.push(JSON.parse(JSON.stringify(obj)))
            }
            return tJson
        },

        getTopNTotalTransactionData: function (urlAction) {
            tJson = JSON.parse(JSON.stringify(analytics_Template.TopNTotalTransactionTemplate));
            var data = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'family': "Unified AP" });
            for (var i = 0; i < data.length; i++) {
                let siteRec = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'site': data[i].siteName});
                var startTime = urlAction.restPayload.startTime;
                var obj = tJson.data[0];
                tJson.data = [];
                startTime += 300000
                let updatedTime = new Date(parseInt(startTime));
                updatedTime = updatedTime.toISOString().split('.')[0] + "+0000";
                obj.values.time = updatedTime;
                obj.values.totalTransactionCount = UtilityFunctions.getRandomIntForRangeValues(1, 200);
                obj.siteName = siteRec[0].groupNameHierarchy;
                obj.serverIpAddress = "8.8.8.8";
                tJson.data.push(JSON.parse(JSON.stringify(obj)))
            }
            return tJson
        },

        getTopNSuccessTransactionData: function (urlAction) {
            tJson = JSON.parse(JSON.stringify(analytics_Template.TopNSuccessTransactionTemplate));
            var data = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'family': "Unified AP" });
            for (var i = 0; i < data.length; i++) {
                let siteRec = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'site': data[i].siteName});
                var startTime = urlAction.restPayload.startTime;
                var obj = tJson.data[0];
                tJson.data = [];
                startTime += 300000
                let updatedTime = new Date(parseInt(startTime));
                updatedTime = updatedTime.toISOString().split('.')[0] + "+0000";
                obj.values.time = updatedTime;
                obj.values.successTransactionCount = UtilityFunctions.getRandomIntForRangeValues(50, 200);
                obj.siteName = siteRec[0].groupNameHierarchy;
                obj.serverIpAddress = "8.8.8.8";
                tJson.data.push(JSON.parse(JSON.stringify(obj)))
            }
            return tJson
        },
        /* code for events page*/
        getTrendWindowSizeRoutersData: function (urlAction) {
            tJson = JSON.parse(JSON.stringify(DnsNetworkServiceTemplate.TrendWindowSizeRoutersData));
            var startTime = urlAction.restPayload.startTime;
            var endTime = urlAction.restPayload.endTime;
            var trendWindowSize = urlAction.restPayload.query.filters[0].value;
            switch (trendWindowSize) {
                case "30m" :
                    trendWindowSize = 1800000;
                    break;
                case "15m" :
                    trendWindowSize = 900000;
                    break;
                case "4h" :
                    trendWindowSize = 14400000;
            }
            //var timediffereceObj = UtilityFunctions.getHours(startTime, endTime)
            var obj = tJson.data[0];
                tJson.data = [];
                while(startTime<endTime){                
                obj.values[0].count = UtilityFunctions.getRandomIntForRangeValues(100, 200);
                obj.values[1].count = UtilityFunctions.getRandomIntForRangeValues(1, 10);
                obj.values[2].count = UtilityFunctions.getRandomIntForRangeValues(100, 200);
                obj.time = new Date(startTime).getTime();
                tJson.data.push(JSON.parse(JSON.stringify(obj)))
                startTime += trendWindowSize;
            }
            return tJson
        }, 
        getTrendWindowSizeAPData: function (urlAction) {
            tJson = JSON.parse(JSON.stringify(DnsNetworkServiceTemplate.TrendWindowSizeAPData));
            var startTime = urlAction.restPayload.startTime;
            var endTime = urlAction.restPayload.endTime;
            var trendWindowSize = urlAction.restPayload.query.filters[0].value;
            switch (trendWindowSize) {
                case "30m" :
                    trendWindowSize = 1800000;
                    break;
                case "15m" :
                    trendWindowSize = 900000;
                    break;
                case "4h" :
                    trendWindowSize = 14400000;
            }
            var obj = tJson.data[0];
                tJson.data = [];
                while(startTime<endTime){                    
                    obj.values[0].count = UtilityFunctions.getRandomIntForRangeValues(100, 200);
                    obj.time = startTime;
                    tJson.data.push(JSON.parse(JSON.stringify(obj)))
                    startTime += trendWindowSize;
                }
                return tJson
            },
            getTrendWindowSizeWiredClientData: function (urlAction) {
             tJson = JSON.parse(JSON.stringify(DnsNetworkServiceTemplate.TrendWindowSizeWiredClientData));
             var startTime = urlAction.restPayload.startTime;
             var endTime = urlAction.restPayload.endTime;
             var trendWindowSize = urlAction.restPayload.query.filters[0].value;
             switch (trendWindowSize) {
                case "30m" :
                    trendWindowSize = 1800000;
                    break;
                case "15m" :
                    trendWindowSize = 900000;
                    break;
                case "4h" :
                    trendWindowSize = 14400000;
            }
             var obj = tJson.data[0];
                 tJson.data = [];
                 while(startTime<endTime){                     
                     obj.values[0].count = UtilityFunctions.getRandomIntForRangeValues(20, 30);
                     obj.time = startTime;
                     tJson.data.push(JSON.parse(JSON.stringify(obj)))
                     startTime += trendWindowSize;
                 }
                 return tJson
             },
             getTrendWindowSizeWireslessClientData: function (urlAction) {
                tJson = JSON.parse(JSON.stringify(DnsNetworkServiceTemplate.TrendWindowSizeWirelessClientData));
                var startTime = urlAction.restPayload.startTime;
             var endTime = urlAction.restPayload.endTime;
             var trendWindowSize = urlAction.restPayload.query.filters[0].value;
             switch (trendWindowSize) {
                case "30m" :
                    trendWindowSize = 1800000;
                    break;
                case "15m" :
                    trendWindowSize = 900000;
                    break;
                case "4h" :
                    trendWindowSize = 14400000;
            }
             var obj = tJson.data[0];
                 tJson.data = [];
                 while(startTime<endTime){
                     obj.values[0].count = UtilityFunctions.getRandomIntForRangeValues(200, 300);
                     obj.time = startTime;
                     tJson.data.push(JSON.parse(JSON.stringify(obj)))
                     startTime += trendWindowSize;
                 }
                 return tJson
            },
        getDeviceFamilyData: function (urlAction) {
            tJson = JSON.parse(JSON.stringify(DnsNetworkServiceTemplate.DeviceFamilyData));
            var startTime = urlAction.restPayload.startTime;
            var endTime = urlAction.restPayload.endTime;
            var trendWindowSize = urlAction.restPayload.query.filters[0].value;
             switch (trendWindowSize) {
                case "30m" :
                    trendWindowSize = 1800000;
                    break;
                case "15m" :
                    trendWindowSize = 900000;
                    break;
                case "4h" :
                    trendWindowSize = 14400000;
            }
            var obj = tJson.data[0];
                tJson.data = [];
                while(startTime<endTime){                
                obj.time = startTime;
                obj.values[0].count = UtilityFunctions.getRandomIntForRangeValues(30, 40);
                obj.values[1].count = UtilityFunctions.getRandomIntForRangeValues(30, 40);
                obj.values[2].count = UtilityFunctions.getRandomIntForRangeValues(30, 40);
                obj.values[3].count = UtilityFunctions.getRandomIntForRangeValues(30, 40);
                obj.values[4].count = UtilityFunctions.getRandomIntForRangeValues(10, 50);
                obj.values[5].count = UtilityFunctions.getRandomIntForRangeValues(30, 40);
                tJson.data.push(JSON.parse(JSON.stringify(obj)))
                startTime += trendWindowSize;
            }
            return tJson
        }, 
        getEventAPData: function (urlAction) {
            var tJson = JSON.parse(JSON.stringify(DnsNetworkServiceTemplate.EventDataAPTemplate));
            var apdata = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'family': "Unified AP" });
            var wlcDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {"$and": [{"family":"Wireless Controller"}]} );
            var switches = SimLokiDatabaseActions.getFilteredRecordHandler("network-device", {"$and": [{"family":"Switches and Hubs"}]})
            let siteRec = SimLokiDatabaseActions.getFilteredRecordHandler('site');
            var startTime = new Date().getTime();;
            var obj = tJson.data[0];
                tJson.data = [];
                for (var i = 0; i < apdata.length; i++) {
                    startTime -= 1800000;
                    obj.id=UtilityFunctions.generateId(20);
                    obj.values.eventTime = new Date(startTime).getTime();
                    obj.values.additionalDetails["WLC UUID"] = wlcDevice[0].id; 
                    obj.values.apMac = apdata[i].macAddress;
                    obj.values.deviceName = apdata[i].hostname;
                    obj.values.deviceUUID = apdata[i].id;
                    obj.values.managementIpAddress = wlcDevice[0].managementIpAddress;        
                    obj.values.location = siteRec[35].groupNameHierarchy;
                    obj.values.siteHierarchyGraphId = siteRec[35].groupHierarchy; 
                    obj.values.switchName = switches[0].hostname;
                    obj.values.switchUUID = switches[0].id;
                    obj.values.wlcName = wlcDevice[0].hostname;
                    tJson.data.push(JSON.parse(JSON.stringify(obj))); 
                }
                tJson.page.count = tJson.data.length;
                tJson.page.pages = tJson.data.length % 10;
            return tJson
        }, 
        getEventDeviceFamilyRoutersData: function (urlAction) {
            var tJson = JSON.parse(JSON.stringify(DnsNetworkServiceTemplate.EventDataRoutersTemplate));
            var routerDevices = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {  "family": "Routers" });
            let siteRec = SimLokiDatabaseActions.getFilteredRecordHandler('site');
            var startTime = urlAction.restPayload.startTime ? urlAction.restPayload.startTime : UtilityFunctions.getTimeStamp();
            var obj = tJson.data[0];
            tJson.data = [];
            for (var i = 0; i < routerDevices.length; i++) {
                obj.id = UtilityFunctions.generateId(20)
                obj.values.severity = UtilityFunctions.getRandomIntForRangeValues(1, 3);
                obj.values.eventTime = startTime;
                obj.values.additionalDetails["Color Level"] = UtilityFunctions.getRandomIntForRangeValues(0, 10);
                obj.values.deviceName = routerDevices[i].hostname;
                obj.values.deviceFamily = routerDevices[i].family;
                obj.values.managementIpAddress = routerDevices[i].managementIpAddress;
                obj.values.location = siteRec[35].groupNameHierarchy;
                obj.values.deviceUUID = routerDevices[i].id;
                obj.values.siteHierarchyGraphId = siteRec[35].groupHierarchy;
                tJson.data.push(JSON.parse(JSON.stringify(obj)));
                startTime -= 1800000;
            }
            tJson.page.count = tJson.data.length;
            tJson.page.pages = tJson.data.length % 10;
            return tJson;
        }, 

        getEventDeviceFamilyWLCData: function (urlAction) {
            tJson = JSON.parse(JSON.stringify(DnsNetworkServiceTemplate.EventDeviceFamilyWLCDataTemplate));
            var wlcDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { "$and": [{ "family": "Wireless Controller" }] });
            let siteRec = SimLokiDatabaseActions.getFilteredRecordHandler('site');
            var startTime = urlAction.restPayload.startTime;
            var endTime = urlAction.restPayload.endTime;
            var timediffereceObj = UtilityFunctions.getHours(startTime, endTime)
            var obj = tJson.data[0];
            tJson.data = [];
            for (var i = 0; i < wlcDevice.length; i++) {
                startTime += 500000;
                obj.id = wlcDevice[i].id;
                obj.values.eventTime = endTime;
                obj.values.additionalDetails["Color Level"] = UtilityFunctions.getRandomIntForRangeValues(0, 10);
                obj.values.deviceName = wlcDevice[i].hostname;
                obj.values.deviceFamily = wlcDevice[i].family;
                obj.values.managementIpAddress = wlcDevice[i].managementIpAddress;
                obj.values.location = siteRec[35].groupNameHierarchy;
                obj.values.siteHierarchyGraphId = siteRec[35].groupHierarchy;
                tJson.data.push(JSON.parse(JSON.stringify(obj)));
            }
            tJson.page.count = tJson.data.length;
            tJson.page.pages = tJson.data.length % 10;
            return tJson
        }, 
        getEventDeviceFamilySwitchandHubsData: function (urlAction) {
            tJson = JSON.parse(JSON.stringify(DnsNetworkServiceTemplate.EventDeviceFamilySwitchandHubsData));
            let switches = SimLokiDatabaseActions.getFilteredRecordHandler("network-device", { "$and": [{ "family": "Switches and Hubs" }] })
            let siteRec = SimLokiDatabaseActions.getFilteredRecordHandler('site');
            var startTime = urlAction.restPayload.startTime;
            var endTime = urlAction.restPayload.endTime;
            var timediffereceObj = UtilityFunctions.getHours(startTime, endTime)
            var obj = tJson.data[0];
            tJson.data = [];
            for (var i = 0; i < switches.length; i++) {
                startTime += 500000;
                obj.id = switches[i].id;
                obj.values.eventTime = endTime;
                obj.values.additionalDetails["Color Level"] = UtilityFunctions.getRandomIntForRangeValues(0, 10);
                obj.values.deviceName = switches[i].hostname;
                obj.values.deviceFamily = switches[i].family;
                obj.values.deviceUUID = switches[i].id;
                obj.values.managementIpAddress = switches[i].managementIpAddress;
                obj.values.location = siteRec[35].groupNameHierarchy;
                obj.values.siteHierarchyGraphId = siteRec[35].groupHierarchy;
                tJson.data.push(JSON.parse(JSON.stringify(obj)));
            }
            tJson.page.count = tJson.data.length;
            tJson.page.pages = tJson.data.length % 10;
            return tJson
        }, 
        getEventDeviceFamilyWirelessClientData: function (urlAction) {
            const connectionType = urlAction.restPayload.query.filters[0].value
            tJson = JSON.parse(JSON.stringify(DnsNetworkServiceTemplate.EventDeviceFamilyWirelessClientData));
            var wlcDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { "$and": [{ "family": "Wireless Controller" }] });
            var apdata = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'family': "Unified AP" });
            let siteRec = SimLokiDatabaseActions.getFilteredRecordHandler("site", {'name' : 'SJC01' });
            var startTime = urlAction.restPayload.startTime;
            var endTime = urlAction.restPayload.endTime;
            var obj = tJson.data[0];
            var hostDetails = (connectionType[0] == 'Wireless Client') ? SimLokiDatabaseActions.getFilteredRecordHandler("host", { "userId": "Grace.Smith" }) : SimLokiDatabaseActions.getFilteredRecordHandler("host", { "userId": "roger" })
            tJson.data = [];
            while (startTime <= endTime) {               
                obj.id = UtilityFunctions.generateId(20);
                obj.values.severity = 4;
                obj.values.identifier = hostDetails[0].userId;
                obj.values.eventTime = startTime;
                obj.values.apMac = apdata[0].macAddress;
                obj.values.apName = hostDetails[0].clientConnection;
                obj.values.apUuid = apdata[0].id;                
                obj.values.id = hostDetails[0].id;
                obj.values.location = siteRec[0].groupNameHierarchy;
                obj.values.macAddr = hostDetails[0].hostMac;
                obj.values.siteHierarchyGraphId = siteRec[0].groupHierarchy;
                obj.values.wlcName = wlcDevice[0].hostname;
                obj.values.wlcUUID = wlcDevice[0].id;
                obj.values.timestamp = new Date().getTime();
                obj.values.username = hostDetails[0].userId;
                obj.values.ipv4 = hostDetails[0].hostIpV4;
                obj.values.ipv6 = hostDetails[0].hostIpV6[0];
                obj.values.children[0].apMac = apdata[0].macAddress;
                obj.values.children[0].apName = apdata[0].hostname;
                obj.values.children[0].apUuid = apdata[0].id;
                obj.values.children[0].id = apdata[0].id;
                obj.values.children[0].location = siteRec[0].groupNameHierarchy;
                obj.values.children[0].macAddr = apdata[0].macAddress;
                obj.values.children[0].wlcName = wlcDevice[0].hostname;
                obj.values.children[0].wlcUUID = wlcDevice[0].id;
                tJson.data.push(JSON.parse(JSON.stringify(obj)));
                startTime += 1800000;
            }
            tJson.page.count = tJson.data.length;
            tJson.page.pages = tJson.data.length % 10;
            return tJson
        },
    }
})