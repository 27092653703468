define({
  "License_Usage_Template":
    {
      "connectedAps": 3,
      "deployedNetwork": false,
      "dnaDeployedLicenses": 0,
      "dnaEntitlementLicenses": 0,
      "dnaUsage": "",
      "dnaUsageCount": 0,
      "entitledNetwork": false,
      "featureLicenses": 0,
      "id": "Cisco 2800 Series Integrated Services Routers",
      "model": "Cisco 2800 Series Integrated Services Routers",
      "nwAndLegacyDeployedLicenses": 0,
      "nwAndLegacyEntitlementLicenses": 0,
      "nwUsage": "",
      "nwUsageCount": 0,
      "totalDevices": 1
    }

});