define({
"Template":
{
  "version": "1.0",
  "response": {
    "id": "AWi8VHL7sIPgh8_7NoUU",
    "name": "wlc_unreachable",
    "enabled": true,
    "flattened": true,
    "entityType": "network_device",
    "entity": "10.13.4.7",
    "groupBy": "Unknown",
    "category": "Availability",
    "severity": "HIGH",
    "summary": "Network Device DeviceIPPlaceHolder Is Unreachable From Controller",
    "scope": "GLOBAL",
    "priority": "P1",
    "groupId": "device_unreachable_grouping",
    "groupName": "WLC unreachable",
    "rootCause": "Unknown",
    "timestamp": 1549347877481,
    "description": "This network device DeviceNamePlaceHolder is unreachable from controller. The device role is ACCESS.",
    "suggestions": [
      {
        "message": "From the controller, verify whether the last hop is reachable.",
        "steps": [

        ]
      },
      {
        "message": "Verify that the physical port(s) on the network device associated with the network device discovery(IP) is UP.",
        "steps": [

        ]
      },
      {
        "message": "Verify access to the device.",
        "steps": [

        ]
      }
    ],
    "additionalParams": [
      {
        "key": "__key__",
        "value": "NetworkDevice:10.13.4.7"
      },
      {
        "key": "managementIpAddr",
        "value": "10.13.4.7"
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "__entity_type__",
        "value": "NetworkDevice"
      },
      {
        "key": "entityId",
        "value": "10.13.4.7"
      },
      {
        "key": "deviceFamily",
        "value": "Wireless Controller"
      },
      {
        "key": "type",
        "value": "Issue"
      },
      {
        "key": "priority",
        "value": "P1"
      },
      {
        "key": "scope",
        "value": "global"
      },
      {
        "key": "category",
        "value": "Availability"
      },
      {
        "key": "timestring",
        "value": "2019-02-05T06:24:37.481+0000"
      },
      {
        "key": "__suppression__",
        "value": "3600"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}
})