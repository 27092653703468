define({
    "runningImageList": [
        {
            "name": "AIR-CT5500-AP_BUNDLE-K9-8-5-135-0.aes",
            "imageUuid": "db16966b-4049-4a95-9ffc-59223249790e",
            "version": "8.5.135.0",
            "softwareType": null,
            "imageType": "WLC_SW",
            "isTaggedGolden": "false",
            "md5Checksum": "41c7a155a133c9cf7c3f42ccaa6f5f99",
            "shaCheckSum": "2e7b054681ebb93921d2c986557930bb64f7f70ce8873c9a185f1606e5089962ea2a9672908483cfeb7e5439971b35d9aea83c0866d4d419161f32b8a634febf",
            "createdTime": "2018-11-06 04:01:20.406",
            "applicationType": "UNKNOWN",
            "feature": null,
            "fileServiceId": null,
            "imageSeries": null,
            "imageSource": "CCO",
            "imageSourceType": "Imported from CCO",
            "imageFamily": "CT5500",
            "vendor": "CISCO",
            "filesize": 72997022,
            "deviceCount": 1,
            "deviceUuidList": [
              "11347c2b-f071-41c8-9c8a-d5b96c0c9b6d"
            ],
            "smuCount": 0,
            "imageAttributes": [
              {
                "propertyName": "image_size",
                "propertyValue": "72997022"
              },
              {
                "propertyName": "image_guid",
                "propertyValue": "8C88DF503ADAEE78B13F4BCDC0B834FE5A932456"
              },
              {
                "propertyName": "image_description",
                "propertyValue": "Supplementary AP Bundle Images for Cisco 5508 Series WLC Release 8.5. This bundle is mandatory to support AP802,AP803,AP1530, AP1550 , AP1570 and AP1600."
              },
              {
                "propertyName": "deviceMNEId",
                "propertyValue": "431431"
              },
              {
                "propertyName": "metadata_trans_id",
                "propertyValue": "147784482"
              },
              {
                "propertyName": "encryption_software_indicator",
                "propertyValue": "N"
              },
              {
                "propertyName": "mdf_id",
                "propertyValue": "282600534"
              },
              {
                "propertyName": "udi",
                "propertyValue": "PID: AIR-CT5508-K9 VID: V03, SN: FCW1826L03L"
              },
              {
                "propertyName": "release_fcs_date",
                "propertyValue": "23/Jul/2018"
              }
            ],
            "isSMUApplicable": false,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": true,
            "reasonForNotSupportingImport": null,
            "tagList": [
              {
                "taggedGolden": false,
                "role": "ALL",
                "inheritedSiteName": "",
                "inheritedSiteUuid": ""
              },
              {
                "taggedGolden": false,
                "role": "CORE",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "DISTRIBUTION",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "BORDER ROUTER",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "UNKNOWN",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "ACCESS",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": null,
            "imageImported": true,
            "applicableDevicesForImage": null,
            "ccoreverseSync": false,
            "ccorecommended": true,
            "ccolatest": true
          },
          {
            "name": "AIR-CT5500-LDPE-K9-8-3-143-0.aes",
            "imageUuid": "086a46f1-ee04-4e7d-b92c-b2a03b10e56d",
            "version": "8.3.143.0",
            "softwareType": null,
            "imageType": "WLC_SW",
            "isTaggedGolden": "false",
            "md5Checksum": "6ea5b5f3ba3e6bb4e9f6136199fefe19",
            "shaCheckSum": "c0acf088630c84fd6f97f0295504fc6912bcb754b8afd1d4644c0fbde399cd5fb9cc6fac577c1d6f6069d709cfa294b6e11c4ed969588c2c4f9ecc51342065f6",
            "createdTime": "2018-11-06 04:01:20.406",
            "applicationType": "UNKNOWN",
            "feature": null,
            "fileServiceId": null,
            "imageSeries": null,
            "imageSource": "CCO",
            "imageSourceType": "Imported from CCO",
            "imageFamily": "CT5500",
            "vendor": "CISCO",
            "filesize": 183722046,
            "deviceCount": 0,
            "deviceUuidList": [],
            "smuCount": 0,
            "imageAttributes": [
              {
                "propertyName": "image_guid",
                "propertyValue": "BB78C949174D1A6EAD7A39DC1A7980CE77724F62"
              },
              {
                "propertyName": "image_description",
                "propertyValue": "Cisco Unified Wireless Network Software Release 8.3 for Cisco 5500 Series Wireless LAN Controllers with Licensed Payload Encryption.Only Recommended for Russia Where Data DTLS Payload Encryption is Regulated by the Government."
              },
              {
                "propertyName": "deviceMNEId",
                "propertyValue": "431431"
              },
              {
                "propertyName": "metadata_trans_id",
                "propertyValue": "147784482"
              },
              {
                "propertyName": "encryption_software_indicator",
                "propertyValue": "N"
              },
              {
                "propertyName": "mdf_id",
                "propertyValue": "282600534"
              },
              {
                "propertyName": "release_fcs_date",
                "propertyValue": "05/Jun/2018"
              },
              {
                "propertyName": "image_size",
                "propertyValue": "183722046"
              },
              {
                "propertyName": "udi",
                "propertyValue": "PID: AIR-CT5508-K9 VID: V03, SN: FCW1826L03L"
              }
            ],
            "isSMUApplicable": false,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": true,
            "reasonForNotSupportingImport": null,
            "tagList": [
              {
                "taggedGolden": false,
                "role": "ALL",
                "inheritedSiteName": "",
                "inheritedSiteUuid": ""
              },
              {
                "taggedGolden": false,
                "role": "CORE",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "DISTRIBUTION",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "BORDER ROUTER",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "UNKNOWN",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "ACCESS",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": "",
            "imageImported": true,
            "applicableDevicesForImage": null,
            "ccoreverseSync": false,
            "ccorecommended": true,
            "ccolatest": true
          },
          {
            "name": "AIR-CT5500-LDPE-AP_BUNDLE-K9-8-5-135-0.aes",
            "imageUuid": "1bcae8f4-7bfd-4a4b-bf3c-ff6096f1e373",
            "version": "8.5.135.0",
            "softwareType": null,
            "imageType": "WLC_SW",
            "isTaggedGolden": "false",
            "md5Checksum": "3b50e95e2db5cffc501f29456dd8a90a",
            "shaCheckSum": "3bf9fc9d7c1bee89c3539c7d7522167cb7d0283162b949cfecf0e4e0a171e70310128fe4b29b691c327c50a25606dace4b4f968087dfb8060faca7fad88bb7ed",
            "createdTime": "2018-11-06 04:01:20.406",
            "applicationType": "UNKNOWN",
            "feature": null,
            "fileServiceId": null,
            "imageSeries": null,
            "imageSource": "CCO",
            "imageSourceType": "Imported from CCO",
            "imageFamily": "CT5500",
            "vendor": "CISCO",
            "filesize": 72997754,
            "deviceCount": 0,
            "deviceUuidList": [],
            "smuCount": 0,
            "imageAttributes": [
              {
                "propertyName": "image_guid",
                "propertyValue": "A29FBBFCAD65A3EEF6C45AC8E50A15B2525E6745"
              },
              {
                "propertyName": "image_description",
                "propertyValue": "Supplementary AP Bundle Images for Cisco 5508 Series WLC Release 8.5. This bundle is mandatory to support AP802,AP803,AP1530, AP1550 , AP1570 and AP1600."
              },
              {
                "propertyName": "image_size",
                "propertyValue": "72997754"
              },
              {
                "propertyName": "deviceMNEId",
                "propertyValue": "431431"
              },
              {
                "propertyName": "metadata_trans_id",
                "propertyValue": "147784482"
              },
              {
                "propertyName": "encryption_software_indicator",
                "propertyValue": "N"
              },
              {
                "propertyName": "mdf_id",
                "propertyValue": "282600534"
              },
              {
                "propertyName": "udi",
                "propertyValue": "PID: AIR-CT5508-K9 VID: V03, SN: FCW1826L03L"
              },
              {
                "propertyName": "release_fcs_date",
                "propertyValue": "23/Jul/2018"
              }
            ],
            "isSMUApplicable": false,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": true,
            "reasonForNotSupportingImport": null,
            "tagList": [
              {
                "taggedGolden": false,
                "role": "ALL",
                "inheritedSiteName": "",
                "inheritedSiteUuid": ""
              },
              {
                "taggedGolden": false,
                "role": "CORE",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "DISTRIBUTION",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "BORDER ROUTER",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "UNKNOWN",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "ACCESS",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": "",
            "imageImported": true,
            "applicableDevicesForImage": null,
            "ccoreverseSync": false,
            "ccorecommended": true,
            "ccolatest": true
          },
          {
            "name": "AIR-CT5500-K9-8-0-152-0.aes",
            "imageUuid": "2d017707-6053-4aa0-9280-afb7233e063a",
            "version": "8.0.152.0",
            "softwareType": null,
            "imageType": "WLC_SW",
            "isTaggedGolden": "false",
            "md5Checksum": "3a5f938d76511eecaec85ed33b1014af",
            "shaCheckSum": "1c95fd5d2a972f342236a9b0e83aec0036e49c5c65dbe9b8e8f45bf6728328ca7666903571bef939899eb5f77d61a0d15ca18747a476d4e593335990a6f067dd",
            "createdTime": "2018-11-06 04:01:20.406",
            "applicationType": "UNKNOWN",
            "feature": null,
            "fileServiceId": null,
            "imageSeries": null,
            "imageSource": "CCO",
            "imageSourceType": "Imported from CCO",
            "imageFamily": "CT5500",
            "vendor": "CISCO",
            "filesize": 176720314,
            "deviceCount": 0,
            "deviceUuidList": [],
            "smuCount": 0,
            "imageAttributes": [
              {
                "propertyName": "image_size",
                "propertyValue": "176720314"
              },
              {
                "propertyName": "image_guid",
                "propertyValue": "F3B8BBD782B3EFBB943089FF2032787986ED80CC"
              },
              {
                "propertyName": "image_description",
                "propertyValue": "Cisco Unified Wireless Network Software Release 8.0 for Cisco 5500 Series Wireless LAN Controllers."
              },
              {
                "propertyName": "release_fcs_date",
                "propertyValue": "21/Oct/2017"
              },
              {
                "propertyName": "deviceMNEId",
                "propertyValue": "431431"
              },
              {
                "propertyName": "metadata_trans_id",
                "propertyValue": "147784482"
              },
              {
                "propertyName": "encryption_software_indicator",
                "propertyValue": "N"
              },
              {
                "propertyName": "mdf_id",
                "propertyValue": "282600534"
              },
              {
                "propertyName": "udi",
                "propertyValue": "PID: AIR-CT5508-K9 VID: V03, SN: FCW1826L03L"
              }
            ],
            "isSMUApplicable": false,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": true,
            "reasonForNotSupportingImport": null,
            "tagList": [
              {
                "taggedGolden": false,
                "role": "ALL",
                "inheritedSiteName": "",
                "inheritedSiteUuid": ""
              },
              {
                "taggedGolden": false,
                "role": "CORE",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "DISTRIBUTION",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "BORDER ROUTER",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "UNKNOWN",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "ACCESS",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": "",
            "imageImported": true,
            "applicableDevicesForImage": null,
            "ccoreverseSync": false,
            "ccorecommended": true,
            "ccolatest": true
          },
          {
            "name": "AIR-CT5500-LDPE-K9-8-0-152-0.aes",
            "imageUuid": "49e36bbc-b215-4cfc-8c25-7c806f74a19a",
            "version": "8.0.152.0",
            "softwareType": null,
            "imageType": "WLC_SW",
            "isTaggedGolden": "false",
            "md5Checksum": "3e7d5ac2fef5ab068822b6e962220d26",
            "shaCheckSum": "9c491c81de9ee123bd1d11efcaa74a18949161e84bbe0eaa8ed34b58d3dd2c2f7a9bed2e22bf686481426115cce88c329a489c80d33ad034b0cfae8af4fe6388",
            "createdTime": "2018-11-06 04:01:20.406",
            "applicationType": "UNKNOWN",
            "feature": null,
            "fileServiceId": null,
            "imageSeries": null,
            "imageSource": "CCO",
            "imageSourceType": "Imported from CCO",
            "imageFamily": "CT5500",
            "vendor": "CISCO",
            "filesize": 176714946,
            "deviceCount": 0,
            "deviceUuidList": [],
            "smuCount": 0,
            "imageAttributes": [
              {
                "propertyName": "image_description",
                "propertyValue": "Cisco Unified Wireless Network Software Release 8.0 for Cisco 5500 Series Wireless LAN Controllers with Licensed Payload Encryption.Only Recommended for Russia Where Data DTLS Payload Encryption is Regulated by the Government."
              },
              {
                "propertyName": "image_guid",
                "propertyValue": "D6F9A568DD21693D2555A881943953A066A6A802"
              },
              {
                "propertyName": "release_fcs_date",
                "propertyValue": "21/Oct/2017"
              },
              {
                "propertyName": "deviceMNEId",
                "propertyValue": "431431"
              },
              {
                "propertyName": "metadata_trans_id",
                "propertyValue": "147784482"
              },
              {
                "propertyName": "encryption_software_indicator",
                "propertyValue": "N"
              },
              {
                "propertyName": "mdf_id",
                "propertyValue": "282600534"
              },
              {
                "propertyName": "udi",
                "propertyValue": "PID: AIR-CT5508-K9 VID: V03, SN: FCW1826L03L"
              },
              {
                "propertyName": "image_size",
                "propertyValue": "176714946"
              }
            ],
            "isSMUApplicable": false,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": true,
            "reasonForNotSupportingImport": null,
            "tagList": [
              {
                "taggedGolden": false,
                "role": "ALL",
                "inheritedSiteName": "",
                "inheritedSiteUuid": ""
              },
              {
                "taggedGolden": false,
                "role": "CORE",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "DISTRIBUTION",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "BORDER ROUTER",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "UNKNOWN",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "ACCESS",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": "",
            "imageImported": true,
            "applicableDevicesForImage": null,
            "ccoreverseSync": false,
            "ccorecommended": true,
            "ccolatest": true
          },
          {
            "name": "AIR-CT5500-LDPE-K9-8-2-170-0.aes",
            "imageUuid": "88ff7ddb-9cd3-42fb-817f-47a45fc0d4d4",
            "version": "8.2.170.0",
            "softwareType": null,
            "imageType": "WLC_SW",
            "isTaggedGolden": "false",
            "md5Checksum": "e916e4766239ee7bb271974ffeb1a517",
            "shaCheckSum": "4e5d68d18f0e0fd5a33f4741b5428b08a6132eb100a4203b20e89c8c13f4fdf2e3a20864e5efbc60f920dbc364e3822eba4f3d674a68f9e28215b6b614f44083",
            "createdTime": "2018-11-06 04:01:20.406",
            "applicationType": "UNKNOWN",
            "feature": null,
            "fileServiceId": null,
            "imageSeries": null,
            "imageSource": "CCO",
            "imageSourceType": "Imported from CCO",
            "imageFamily": "CT5500",
            "vendor": "CISCO",
            "filesize": 205501322,
            "deviceCount": 0,
            "deviceUuidList": [],
            "smuCount": 0,
            "imageAttributes": [
              {
                "propertyName": "image_guid",
                "propertyValue": "846DB4BF07F6C0F2C882622C5E73A8F2C4125537"
              },
              {
                "propertyName": "release_fcs_date",
                "propertyValue": "08/Jun/2018"
              },
              {
                "propertyName": "deviceMNEId",
                "propertyValue": "431431"
              },
              {
                "propertyName": "metadata_trans_id",
                "propertyValue": "147784482"
              },
              {
                "propertyName": "encryption_software_indicator",
                "propertyValue": "N"
              },
              {
                "propertyName": "mdf_id",
                "propertyValue": "282600534"
              },
              {
                "propertyName": "udi",
                "propertyValue": "PID: AIR-CT5508-K9 VID: V03, SN: FCW1826L03L"
              },
              {
                "propertyName": "image_description",
                "propertyValue": "Cisco Unified Wireless Network Software Release 8.2 for Cisco 5500 Series Wireless LAN Controllers with Licensed Payload Encryption.Only Recommended for Russia Where Data DTLS Payload Encryption is Regulated by the Government."
              },
              {
                "propertyName": "image_size",
                "propertyValue": "205501322"
              }
            ],
            "isSMUApplicable": false,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": true,
            "reasonForNotSupportingImport": null,
            "tagList": [
              {
                "taggedGolden": false,
                "role": "ALL",
                "inheritedSiteName": "",
                "inheritedSiteUuid": ""
              },
              {
                "taggedGolden": false,
                "role": "CORE",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "DISTRIBUTION",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "BORDER ROUTER",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "UNKNOWN",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "ACCESS",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": "",
            "imageImported": true,
            "applicableDevicesForImage": null,
            "ccoreverseSync": false,
            "ccorecommended": false,
            "ccolatest": true
          },
          {
            "name": "AIR-CT5500-LDPE-K9-8-5-135-0.aes",
            "imageUuid": "96874cb6-186d-43ae-8ed1-c5aec40b6384",
            "version": "8.5.135.0",
            "softwareType": null,
            "imageType": "WLC_SW",
            "isTaggedGolden": "false",
            "md5Checksum": "efc30ddce3ba38d42d8f50d676449071",
            "shaCheckSum": "91c5dce3c57f1542edfbb636fa489468e2181023b75ffc97795a1d796c02658b61bef82595ad1c3592fa22b48dc8e6d671d93072d281aa7b34c9199811009299",
            "createdTime": "2018-11-06 04:01:20.406",
            "applicationType": "UNKNOWN",
            "feature": null,
            "fileServiceId": null,
            "imageSeries": null,
            "imageSource": "CCO",
            "imageSourceType": "Imported from CCO",
            "imageFamily": "CT5500",
            "vendor": "CISCO",
            "filesize": 192113566,
            "deviceCount": 0,
            "deviceUuidList": [],
            "smuCount": 0,
            "imageAttributes": [
              {
                "propertyName": "image_guid",
                "propertyValue": "C86F88426F2355B80401A445197AF8600D0EC5B8"
              },
              {
                "propertyName": "image_description",
                "propertyValue": "Cisco Unified Wireless Network Software Release 8.5 for Cisco 5500 Series Wireless LAN Controllers with Licensed Payload Encryption.Only Recommended for Russia Where Data DTLS Payload Encryption is Regulated by the Government."
              },
              {
                "propertyName": "image_size",
                "propertyValue": "192113566"
              },
              {
                "propertyName": "deviceMNEId",
                "propertyValue": "431431"
              },
              {
                "propertyName": "metadata_trans_id",
                "propertyValue": "147784482"
              },
              {
                "propertyName": "encryption_software_indicator",
                "propertyValue": "N"
              },
              {
                "propertyName": "mdf_id",
                "propertyValue": "282600534"
              },
              {
                "propertyName": "udi",
                "propertyValue": "PID: AIR-CT5508-K9 VID: V03, SN: FCW1826L03L"
              },
              {
                "propertyName": "release_fcs_date",
                "propertyValue": "23/Jul/2018"
              }
            ],
            "isSMUApplicable": false,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": true,
            "reasonForNotSupportingImport": null,
            "tagList": [
              {
                "taggedGolden": false,
                "role": "ALL",
                "inheritedSiteName": "",
                "inheritedSiteUuid": ""
              },
              {
                "taggedGolden": false,
                "role": "CORE",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "DISTRIBUTION",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "BORDER ROUTER",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "UNKNOWN",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "ACCESS",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": "",
            "imageImported": true,
            "applicableDevicesForImage": null,
            "ccoreverseSync": false,
            "ccorecommended": true,
            "ccolatest": true
          },
          {
            "name": "AIR-CT5500-K9-8-3-143-0.aes",
            "imageUuid": "9cf29f34-e620-4ed5-9f25-8a91dc7d6135",
            "version": "8.3.143.0",
            "softwareType": null,
            "imageType": "WLC_SW",
            "isTaggedGolden": "false",
            "md5Checksum": "eabd4d36c73a0b80940ff99a8e238917",
            "shaCheckSum": "0e8f215fe549d107553c5c83a76a8f8e698f9e862c1466b50d4e786b70b069fc866cf915607c85f3541b80435f3e9e9611d57a9c0c287017429b7464c850687f",
            "createdTime": "2018-11-06 04:01:20.406",
            "applicationType": "UNKNOWN",
            "feature": null,
            "fileServiceId": null,
            "imageSeries": null,
            "imageSource": "CCO",
            "imageSourceType": "Imported from CCO",
            "imageFamily": "CT5500",
            "vendor": "CISCO",
            "filesize": 183729386,
            "deviceCount": 0,
            "deviceUuidList": [],
            "smuCount": 0,
            "imageAttributes": [
              {
                "propertyName": "image_guid",
                "propertyValue": "16EE52D7F4461701DCEF276799E171F68DD3EF4D"
              },
              {
                "propertyName": "image_description",
                "propertyValue": "Cisco Unified Wireless Network Software Release 8.3 for Cisco 5500 Series Wireless LAN Controllers."
              },
              {
                "propertyName": "image_size",
                "propertyValue": "183729386"
              },
              {
                "propertyName": "deviceMNEId",
                "propertyValue": "431431"
              },
              {
                "propertyName": "metadata_trans_id",
                "propertyValue": "147784482"
              },
              {
                "propertyName": "encryption_software_indicator",
                "propertyValue": "N"
              },
              {
                "propertyName": "mdf_id",
                "propertyValue": "282600534"
              },
              {
                "propertyName": "release_fcs_date",
                "propertyValue": "05/Jun/2018"
              },
              {
                "propertyName": "udi",
                "propertyValue": "PID: AIR-CT5508-K9 VID: V03, SN: FCW1826L03L"
              }
            ],
            "isSMUApplicable": false,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": true,
            "reasonForNotSupportingImport": null,
            "tagList": [
              {
                "taggedGolden": false,
                "role": "ALL",
                "inheritedSiteName": "",
                "inheritedSiteUuid": ""
              },
              {
                "taggedGolden": false,
                "role": "CORE",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "DISTRIBUTION",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "BORDER ROUTER",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "UNKNOWN",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "ACCESS",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": "",
            "imageImported": true,
            "applicableDevicesForImage": null,
            "ccoreverseSync": false,
            "ccorecommended": true,
            "ccolatest": true
          },
          {
            "name": "AIR-CT5500-K9-8-2-170-0.aes",
            "imageUuid": "a7214f67-a690-4a9f-86f9-e8c55fbf163e",
            "version": "8.2.170.0",
            "softwareType": null,
            "imageType": "WLC_SW",
            "isTaggedGolden": "false",
            "md5Checksum": "eab1b7643ace61cf9eb88f0c24ff8b92",
            "shaCheckSum": "951413f832a1b3cd674eb318ba2beaba78522297e70a0161a1d04e08b6f57e4f8741236a55e86c0fffd69a7215922d8c91b5100f367fe6f585c427e216bc4d15",
            "createdTime": "2018-11-06 04:01:20.406",
            "applicationType": "UNKNOWN",
            "feature": null,
            "fileServiceId": null,
            "imageSeries": null,
            "imageSource": "CCO",
            "imageSourceType": "Imported from CCO",
            "imageFamily": "CT5500",
            "vendor": "CISCO",
            "filesize": 205507690,
            "deviceCount": 0,
            "deviceUuidList": [],
            "smuCount": 0,
            "imageAttributes": [
              {
                "propertyName": "image_size",
                "propertyValue": "205507690"
              },
              {
                "propertyName": "release_fcs_date",
                "propertyValue": "08/Jun/2018"
              },
              {
                "propertyName": "deviceMNEId",
                "propertyValue": "431431"
              },
              {
                "propertyName": "metadata_trans_id",
                "propertyValue": "147784482"
              },
              {
                "propertyName": "encryption_software_indicator",
                "propertyValue": "N"
              },
              {
                "propertyName": "mdf_id",
                "propertyValue": "282600534"
              },
              {
                "propertyName": "image_description",
                "propertyValue": "Cisco Unified Wireless Network Software Release 8.2 for Cisco 5500 Series Wireless LAN Controllers."
              },
              {
                "propertyName": "udi",
                "propertyValue": "PID: AIR-CT5508-K9 VID: V03, SN: FCW1826L03L"
              },
              {
                "propertyName": "image_guid",
                "propertyValue": "67C414185C1502B06E355DE5378BA59D2D9D11D8"
              }
            ],
            "isSMUApplicable": false,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": true,
            "reasonForNotSupportingImport": null,
            "tagList": [
              {
                "taggedGolden": false,
                "role": "ALL",
                "inheritedSiteName": "",
                "inheritedSiteUuid": ""
              },
              {
                "taggedGolden": false,
                "role": "CORE",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "DISTRIBUTION",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "BORDER ROUTER",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "UNKNOWN",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "ACCESS",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": "",
            "imageImported": true,
            "applicableDevicesForImage": null,
            "ccoreverseSync": false,
            "ccorecommended": false,
            "ccolatest": true
          },
          {
            "name": "AIR-CT5500-K9-8-5-135-0.aes",
            "imageUuid": "adb39509-a504-4108-821d-fab58d5a6c15",
            "version": "8.5.135.0",
            "softwareType": null,
            "imageType": "WLC_SW",
            "isTaggedGolden": "false",
            "md5Checksum": "f83d018b22729e7f22d7c68b074efdd1",
            "shaCheckSum": "640001233037c15cb08792899234b235349af2948a7f926ace07d0dd6fcd493a1a5ba0da3fde0e39e05aaba5dd745d635836d058863bf66613f5e3d7b57cd176",
            "createdTime": "2018-11-06 04:01:20.406",
            "applicationType": "UNKNOWN",
            "feature": null,
            "fileServiceId": null,
            "imageSeries": null,
            "imageSource": "CCO",
            "imageSourceType": "Imported from CCO",
            "imageFamily": "CT5500",
            "vendor": "CISCO",
            "filesize": 192123370,
            "deviceCount": 0,
            "deviceUuidList": [],
            "smuCount": 0,
            "imageAttributes": [
              {
                "propertyName": "image_size",
                "propertyValue": "192123370"
              },
              {
                "propertyName": "image_guid",
                "propertyValue": "5C6D96BEBE6CA01BB8895BB3617756C2A011C0FD"
              },
              {
                "propertyName": "image_description",
                "propertyValue": "Cisco Unified Wireless Network Software Release 8.5 for Cisco 5500 Series Wireless LAN Controllers."
              },
              {
                "propertyName": "deviceMNEId",
                "propertyValue": "431431"
              },
              {
                "propertyName": "metadata_trans_id",
                "propertyValue": "147784482"
              },
              {
                "propertyName": "encryption_software_indicator",
                "propertyValue": "N"
              },
              {
                "propertyName": "mdf_id",
                "propertyValue": "282600534"
              },
              {
                "propertyName": "udi",
                "propertyValue": "PID: AIR-CT5508-K9 VID: V03, SN: FCW1826L03L"
              },
              {
                "propertyName": "release_fcs_date",
                "propertyValue": "23/Jul/2018"
              }
            ],
            "isSMUApplicable": false,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": true,
            "reasonForNotSupportingImport": null,
            "tagList": [
              {
                "taggedGolden": false,
                "role": "ALL",
                "inheritedSiteName": "",
                "inheritedSiteUuid": ""
              },
              {
                "taggedGolden": false,
                "role": "CORE",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "DISTRIBUTION",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "BORDER ROUTER",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "UNKNOWN",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "ACCESS",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": "",
            "imageImported": true,
            "applicableDevicesForImage": null,
            "ccoreverseSync": false,
            "ccorecommended": true,
            "ccolatest": true
          },
          {
            "name": "AIR-CT5500-AP_BUNDLE-K9-8-3-143-0.aes",
            "imageUuid": "cf96b98f-3cc4-44d1-b6d9-47a53405ec8d",
            "version": "8.3.143.0",
            "softwareType": null,
            "imageType": "WLC_SW",
            "isTaggedGolden": "false",
            "md5Checksum": "4a86003cc2b23d622b97aa9ee98ce49c",
            "shaCheckSum": "c65b199a1bf8c6ffef922ad587427d8dddd8de0ac2fe6a0d1bd1a8ccb46be2b8b79db4e62bb45e13c6e0baac1431b41c603659e64f712a25e359eb552b8efb4d",
            "createdTime": "2018-11-06 04:01:20.406",
            "applicationType": "UNKNOWN",
            "feature": null,
            "fileServiceId": null,
            "imageSeries": null,
            "imageSource": "CCO",
            "imageSourceType": "Imported from CCO",
            "imageFamily": "CT5500",
            "vendor": "CISCO",
            "filesize": 66166962,
            "deviceCount": 0,
            "deviceUuidList": [],
            "smuCount": 0,
            "imageAttributes": [
              {
                "propertyName": "image_guid",
                "propertyValue": "5094A20B1D1B40F73C4D7717A85E159E36440E6D"
              },
              {
                "propertyName": "deviceMNEId",
                "propertyValue": "431431"
              },
              {
                "propertyName": "metadata_trans_id",
                "propertyValue": "147784482"
              },
              {
                "propertyName": "encryption_software_indicator",
                "propertyValue": "N"
              },
              {
                "propertyName": "mdf_id",
                "propertyValue": "282600534"
              },
              {
                "propertyName": "release_fcs_date",
                "propertyValue": "05/Jun/2018"
              },
              {
                "propertyName": "image_size",
                "propertyValue": "66166962"
              },
              {
                "propertyName": "image_description",
                "propertyValue": "Supplementary AP Bundle Images for Cisco 5508 Series WLC Release 8.3. This bundle is mandatory to support AP802,AP1530, AP1550 and  AP1570."
              },
              {
                "propertyName": "udi",
                "propertyValue": "PID: AIR-CT5508-K9 VID: V03, SN: FCW1826L03L"
              }
            ],
            "isSMUApplicable": false,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": true,
            "reasonForNotSupportingImport": null,
            "tagList": [
              {
                "taggedGolden": false,
                "role": "ALL",
                "inheritedSiteName": "",
                "inheritedSiteUuid": ""
              },
              {
                "taggedGolden": false,
                "role": "CORE",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "DISTRIBUTION",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "BORDER ROUTER",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "UNKNOWN",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "ACCESS",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": "",
            "imageImported": true,
            "applicableDevicesForImage": null,
            "ccoreverseSync": false,
            "ccorecommended": true,
            "ccolatest": true
          },
          {
            "name": "AIR-CT5500-LDPE-AP_BUNDLE-K9-8-3-143-0.aes",
            "imageUuid": "d551e5fd-6fdc-469b-a7d8-92b8f7e1df81",
            "version": "8.3.143.0",
            "softwareType": null,
            "imageType": "WLC_SW",
            "isTaggedGolden": "false",
            "md5Checksum": "9ce8ec3b43c70d9652c264e63d627416",
            "shaCheckSum": "2e26f78b677d2086adcaabd7e556b03fa038a08c10ed9e31b5814698b1d66e8c189967921be370f91915429945c0d4a824851ed32e512d5f4fd4fa101ec89402",
            "createdTime": "2018-11-06 04:01:20.406",
            "applicationType": "UNKNOWN",
            "feature": null,
            "fileServiceId": null,
            "imageSeries": null,
            "imageSource": "CCO",
            "imageSourceType": "Imported from CCO",
            "imageFamily": "CT5500",
            "vendor": "CISCO",
            "filesize": 66167694,
            "deviceCount": 0,
            "deviceUuidList": [],
            "smuCount": 0,
            "imageAttributes": [
              {
                "propertyName": "image_size",
                "propertyValue": "66167694"
              },
              {
                "propertyName": "deviceMNEId",
                "propertyValue": "431431"
              },
              {
                "propertyName": "metadata_trans_id",
                "propertyValue": "147784482"
              },
              {
                "propertyName": "encryption_software_indicator",
                "propertyValue": "N"
              },
              {
                "propertyName": "mdf_id",
                "propertyValue": "282600534"
              },
              {
                "propertyName": "release_fcs_date",
                "propertyValue": "05/Jun/2018"
              },
              {
                "propertyName": "image_description",
                "propertyValue": "Supplementary AP Bundle Images for Cisco 5508 Series WLC Release 8.3. This bundle is mandatory to support AP802,AP1530, AP1550 and  AP1570."
              },
              {
                "propertyName": "udi",
                "propertyValue": "PID: AIR-CT5508-K9 VID: V03, SN: FCW1826L03L"
              },
              {
                "propertyName": "image_guid",
                "propertyValue": "03AD3AD04366F3DFDA635590B7C5925FF4F9148E"
              }
            ],
            "isSMUApplicable": false,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": true,
            "reasonForNotSupportingImport": null,
            "tagList": [
              {
                "taggedGolden": false,
                "role": "ALL",
                "inheritedSiteName": "",
                "inheritedSiteUuid": ""
              },
              {
                "taggedGolden": false,
                "role": "CORE",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "DISTRIBUTION",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "BORDER ROUTER",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "UNKNOWN",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "ACCESS",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": "",
            "imageImported": true,
            "applicableDevicesForImage": null,
            "ccoreverseSync": false,
            "ccorecommended": true,
            "ccolatest": true
          }
    ]
    });