define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/scalablegroup/ScalableGroupIseTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator'
], function (SimLokiDatabaseActions, UtilityFunctions, ScalableGroupIseTemplate, SimTaskDataGenerator) {
    return {
        init: function () {

        },
        CUDScalableGroup: function (operation, jsonObj) {

            if(operation == 'DELETE'){
                var sgObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup', {'id': jsonObj.id});
                if (sgObj && sgObj.length) {

                    SimLokiDatabaseActions.delete('scalablegroup', sgObj);
                }else{
                    var sgObj  = SimLokiDatabaseActions.getFilteredRecordHandler('ip-network-group', {'id': jsonObj.id})
                    if (sgObj && sgObj.length) {

                        SimLokiDatabaseActions.delete('ip-network-group', sgObj);
                    }
                }
            }

            if(operation =='PUT'){

                if(jsonObj.scalableGroupType == 'APPLICATION'){

                    var sgObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup', {'id': jsonObj.id});
                    if (sgObj && sgObj.length) {
                        for(var i =0 ;i< sgObj.length;i++){
                            sgObj[i]["$loki"] = i;
                        }
                        SimLokiDatabaseActions.delete('scalablegroup', sgObj);
                    }
                    SimLokiDatabaseActions.insert('scalablegroup', jsonObj);

                // } else if (jsonObj.scalableGroupType == 'IP_RENDERED'){

                    /*  var sgObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup', {'id': jsonObj.id});
                    console.log("to be deleted = " + JSON.stringify(sgObj));
                  if (sgObj && sgObj.length) {
                        SimLokiDatabaseActions.delete('scalablegroup', sgObj);
                        console.log("deleted one is = " + JSON.stringify(sgObj));
                    }

                    var oldScalableGroup = JSON.parse(JSON.stringify(ScalableGroupIseTemplate.ScalableGroup_IP_RENDERED_Template[0]));
                    oldScalableGroup.id = jsonObj.id;
                    oldScalableGroup.name = jsonObj.name;
                    oldScalableGroup.scalableGroupType = jsonObj.scalableGroupType;
                    oldScalableGroup.createTime = UtilityFunctions.getTimeStamp();
                    oldScalableGroup.lastUpdateTime = UtilityFunctions.getTimeStamp();

                    var newScalableGroup = JSON.parse(JSON.stringify(ScalableGroupIseTemplate.ScalableGroup_IP_RENDERED_Template[0]));
                    oldScalableGroup.indicativeNetworkIdentity[0].protocol = jsonObj.indicativeNetworkIdentity[0].protocol;
                    oldScalableGroup.indicativeNetworkIdentity[0].ports = jsonObj.indicativeNetworkIdentity[0].ports;
                    oldScalableGroup.indicativeNetworkIdentity[0].lowerPort = jsonObj.indicativeNetworkIdentity[0].lowerPort;
                    oldScalableGroup.indicativeNetworkIdentity[0].upperPort = jsonObj.indicativeNetworkIdentity[0].upperPort;
                    oldScalableGroup.indicativeNetworkIdentity[0].ipv4Subnet = jsonObj.indicativeNetworkIdentity[0].ipv4Subnet;

                    console.log("created AccessPolicy = " + JSON.stringify(oldScalableGroup)); */
                    // jsonObj.lastUpdateTime = UtilityFunctions.getTimeStamp();
                    // SimLokiDatabaseActions.update('scalablegroup', jsonObj);
                } else if(jsonObj.scalableGroupType == 'USER_DEVICE'){
                    sgObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup', {'id': jsonObj.id})
                    sgObj[0].name = jsonObj.name;
                    sgObj[0].description = jsonObj.description;
                    sgObj[0].vnAgnostic = jsonObj.vnAgnostic
                    sgObj[0].isDeleted = jsonObj.isDeleted;
                    SimLokiDatabaseActions.update('scalablegroup', sgObj);
                }else if(jsonObj.scalableGroupType == 'IP_RENDERED'){
                    sgObj = SimLokiDatabaseActions.getFilteredRecordHandler('ip-network-group', {'id': jsonObj.id})
                    sgObj[0].name = jsonObj.name;
                    sgObj[0].description = jsonObj.description
                    sgObj[0].scalableGroupType = jsonObj.scalableGroupType;
                    sgObj[0].lastUpdateTime = UtilityFunctions.getTimeStamp();
                    sgObj[0].vnAgnostic = jsonObj.vnAgnostic
                    sgObj[0].scalableGroupExternalHandle = jsonObj.name

                    sgObj[0].indicativeNetworkIdentity[0].ipv4Subnet = jsonObj.indicativeNetworkIdentity[0].ipv4Subnet;

                    console.log("Updated AccessPolicy = " + JSON.stringify(sgObj[0]));
                    SimLokiDatabaseActions.update('ip-network-group', sgObj);
                }
            }


            if (operation == 'post' || operation =='POST') {
                console.log("post jsonObj = " + JSON.stringify(jsonObj));

                if (jsonObj.scalableGroupType == 'USER_DEVICE') {
                    var oldScalableGroup = JSON.parse(JSON.stringify(ScalableGroupIseTemplate.ScalableGroup_USER_DEVICE_1_3_1_Template[0]));
                    console.log("oldScalableGroup = " + JSON.stringify(oldScalableGroup));
                    oldScalableGroup.id = UtilityFunctions.generate_uuid();
                    
                    var obj = {'wrkFlwId':UtilityFunctions.generate_uuid(), 'cfsId':oldScalableGroup.id};
                    var resultObj = SimTaskDataGenerator.createTask('TASK_DeviceInterfaceInfoContainer_MSG',obj);
                    oldScalableGroup.name = jsonObj.name;
                    oldScalableGroup.description = jsonObj.description;
                    oldScalableGroup.scalableGroupType = 'USER_DEVICE';
                    oldScalableGroup.propagateToAci = jsonObj.propagateToAci
                    oldScalableGroup.securityGroupTag = jsonObj.securityGroupTag+"/0x"+jsonObj.securityGroupTag
                    oldScalableGroup.instanceId = UtilityFunctions.getRandomIntForRangeValues(12345678, 87654321)
                    oldScalableGroup.displayName = oldScalableGroup.instanceId
                    // oldScalableGroup.indicativeNetworkIdentity[0].ipv4Subnet = jsonObj.indicativeNetworkIdentity == undefined ? []:jsonObj.indicativeNetworkIdentity[0].ipv4Subnet
                    //oldScalableGroup.indicativeNetworkIdentity[0].ipv4Subnet = jsonObj.indicativeNetworkIdentity[0].ipv4Subnet
                    oldScalableGroup.lastUpdateTime = UtilityFunctions.getTimeStamp();
                    console.log("created AccessPolicy = " + JSON.stringify(oldScalableGroup));
                    // SimLokiDatabaseActions.insert('scalablegroupsummary', oldScalableGroup);
                    SimLokiDatabaseActions.insert('scalablegroup', oldScalableGroup);
                    let postJson = ScalableGroupIseTemplate['SecurityGroups1.3.1'][0];
                    postJson.id = oldScalableGroup.id;
                    postJson.description = oldScalableGroup.description == undefined ? "" : oldScalableGroup.description;
                    postJson.generationId = Math.floor(Math.random(10)*10);
                    let values = oldScalableGroup['securityGroupTag'].split("0x");
                    let text = "0000";
                    let trimLength = text.length - values[0].length;
                    text = text.slice(values[1].length);
                    let representation = parseInt(values[1]);
                    let numericSplit = values[0].split("/");
				    postJson.value = parseInt(numericSplit[0]);
					let hexRep = values[1];
                    postJson.generationId = Math.floor(Math.random(10)*10);
                    postJson.numericRepresentation = numericSplit[0]+"/"+text+hexRep;
                    SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                    postJson.name = oldScalableGroup.name;
                    postJson.description = jsonObj.description==undefined ? "" : jsonObj.description;
                    let status = undefined;
                    let synchResponse =
                        fetch("https://171.71.186.80:7027/user/simulation/securityGroups/post", {
                                method: 'POST',
                                mode: 'cors',
                                cache: 'no-cache',
                                headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(postJson)
                        }).then(function(response) {
                            status = response.statusText;
                            if(status=="OK" && oldScalableGroup.syncStatus=="CREATE") {
                                oldScalableGroup.syncStatus = "SYNCED";
                                SimLokiDatabaseActions.update('scalablegroup', oldScalableGroup);
                            }
                        })
                        .catch(error => console.error(error));
                    console.log("Synch status from DNAC...", synchResponse);
                    return resultObj;

                } else if (jsonObj.scalableGroupType == 'APPLICATION_GROUP') {
                    var oldScalableGroup = JSON.parse(JSON.stringify(ScalableGroupIseTemplate.ScalableGroup_APPLICATION_GROUP_Template[0]));
                    console.log("oldScalableGroup = " + JSON.stringify(oldScalableGroup));
                    oldScalableGroup.id = UtilityFunctions.generate_uuid();
                    oldScalableGroup.name = jsonObj.name;
                    oldScalableGroup.scalableGroupType = jsonObj.scalableGroupType;
                    oldScalableGroup.lastUpdateTime = UtilityFunctions.getTimeStamp();
                    console.log("created AccessPolicy = " + JSON.stringify(oldScalableGroup));
                    SimLokiDatabaseActions.insert('scalablegroup', oldScalableGroup);

                    // Using new collection named "applicationSet" for application set
                    let record = {};
                    record.id = UtilityFunctions.generate_uuid();
                    record.name = jsonObj.name;
                    record.scalableGroupType = jsonObj.scalableGroupType;
                    record.lastUpdateTime = UtilityFunctions.getTimeStamp();
                    record.instanceId = "";
                    record.displayName = "";
                    record.identitySource = {};
                    record.type = "scalablegroup";
                    record.scalableGroupExternalHandle = "";
                    record.instanceVersion = 0;
                    SimLokiDatabaseActions.insert('applicationSet', record);

                } else if (jsonObj.scalableGroupType == 'IP_RENDERED') {

                    var oldScalableGroup = JSON.parse(JSON.stringify(ScalableGroupIseTemplate.ScalableGroup_IP_RENDERED_Template[0]));
                    console.log("oldScalableGroup = " + JSON.stringify(oldScalableGroup));
                    oldScalableGroup.id = UtilityFunctions.generate_uuid();
                    oldScalableGroup.name = jsonObj.name;
                    oldScalableGroup.description = jsonObj.description
                    oldScalableGroup.scalableGroupType = jsonObj.scalableGroupType;
                    oldScalableGroup.createTime = UtilityFunctions.getTimeStamp();
                    oldScalableGroup.lastUpdateTime = UtilityFunctions.getTimeStamp();
                    oldScalableGroup.vnAgnostic = jsonObj.vnAgnostic
                    oldScalableGroup.scalableGroupExternalHandle = jsonObj.name

                    var newScalableGroup = JSON.parse(JSON.stringify(ScalableGroupIseTemplate.ScalableGroup_IP_RENDERED_Template[0]));
                    oldScalableGroup.indicativeNetworkIdentity[0].protocol = jsonObj.indicativeNetworkIdentity[0].protocol;
                    oldScalableGroup.indicativeNetworkIdentity[0].ports = jsonObj.indicativeNetworkIdentity[0].ports;
                    oldScalableGroup.indicativeNetworkIdentity[0].lowerPort = jsonObj.indicativeNetworkIdentity[0].lowerPort;
                    oldScalableGroup.indicativeNetworkIdentity[0].upperPort = jsonObj.indicativeNetworkIdentity[0].upperPort;
                    oldScalableGroup.indicativeNetworkIdentity[0].ipv4Subnet = jsonObj.indicativeNetworkIdentity[0].ipv4Subnet;

                    console.log("created AccessPolicy = " + JSON.stringify(oldScalableGroup));
                    SimLokiDatabaseActions.insert('ip-network-group', oldScalableGroup);
                } else if (jsonObj.scalableGroupType == 'APPLICATION') {
                    var oldScalableGroup = JSON.parse(JSON.stringify(ScalableGroupIseTemplate.ScalableGroup_APPLICATION_Template[0]));
                    console.log("oldScalableGroup = " + JSON.stringify(oldScalableGroup));
                    oldScalableGroup.id = UtilityFunctions.generate_uuid();
                    oldScalableGroup.name = jsonObj.name;
                    oldScalableGroup.description = jsonObj.description;
                    oldScalableGroup.scalableGroupType = jsonObj.scalableGroupType;
                    oldScalableGroup.parentScalableGroup.idRef = jsonObj.parentScalableGroup.idRef;
                    if(jsonObj.networkApplications != undefined && jsonObj.networkApplications[0] != undefined){
                        oldScalableGroup.networkApplications[0].trafficClass = jsonObj.networkApplications[0].trafficClass;
                        oldScalableGroup.networkApplications[0].categoryId = jsonObj.networkApplications[0].categoryId;
                        oldScalableGroup.networkApplications[0].appProtocol = jsonObj.networkApplications[0].appProtocol;
                        oldScalableGroup.networkApplications[0].ignoreConflict = jsonObj.networkApplications[0].ignoreConflict;
                    }


                    if (oldScalableGroup.networkApplications[0].appProtocol == "tcp/udp" || oldScalableGroup.networkApplications[0].appProtocol == "TCP_OR_UDP") {

                        if(jsonObj.networkIdentity != undefined && jsonObj.networkIdentity[0] != undefined){
                            oldScalableGroup.networkIdentity[0].protocol = jsonObj.networkIdentity[0].protocol;
                        oldScalableGroup.networkIdentity[0].ports = jsonObj.networkIdentity[0].ports;
                        oldScalableGroup.networkIdentity[0].lowerPort = jsonObj.networkIdentity[0].lowerPort;
                        oldScalableGroup.networkIdentity[0].upperPort = jsonObj.networkIdentity[0].upperPort;
                        oldScalableGroup.networkIdentity[0].ipv4Subnet = jsonObj.networkIdentity[0].ipv4Subnet;
                        }

                        if(jsonObj.indicativeNetworkIdentity != undefined && jsonObj.indicativeNetworkIdentity[0] != undefined){
                            oldScalableGroup.indicativeNetworkIdentity[0].protocol = jsonObj.indicativeNetworkIdentity[0].protocol;
                        oldScalableGroup.indicativeNetworkIdentity[0].ports = jsonObj.indicativeNetworkIdentity[0].ports;
                        oldScalableGroup.indicativeNetworkIdentity[0].lowerPort = jsonObj.indicativeNetworkIdentity[0].lowerPort;
                        oldScalableGroup.indicativeNetworkIdentity[0].upperPort = jsonObj.indicativeNetworkIdentity[0].upperPort;
                        oldScalableGroup.indicativeNetworkIdentity[0].ipv4Subnet = jsonObj.indicativeNetworkIdentity[0].ipv4Subnet;

                        }


                        var newScalableGroup = JSON.parse(JSON.stringify(ScalableGroupIseTemplate.ScalableGroup_APPLICATION_Template[0]));
                        if(jsonObj.networkIdentity != undefined && jsonObj.networkIdentity[1] != undefined){
                            newScalableGroup.networkIdentity[0].protocol = jsonObj.networkIdentity[1].protocol;
                        newScalableGroup.networkIdentity[0].ports = jsonObj.networkIdentity[1].ports;
                        newScalableGroup.networkIdentity[0].lowerPort = jsonObj.networkIdentity[1].lowerPort;
                        newScalableGroup.networkIdentity[0].upperPort = jsonObj.networkIdentity[1].upperPort;
                        newScalableGroup.networkIdentity[0].ipv4Subnet = jsonObj.networkIdentity[1].ipv4Subnet;
                        }

                        if(jsonObj.indicativeNetworkIdentity != undefined && jsonObj.indicativeNetworkIdentity[1] != undefined){
                            newScalableGroup.indicativeNetworkIdentity[0].protocol = jsonObj.indicativeNetworkIdentity[1].protocol;
                        newScalableGroup.indicativeNetworkIdentity[0].ports = jsonObj.indicativeNetworkIdentity[1].ports;
                        newScalableGroup.indicativeNetworkIdentity[0].lowerPort = jsonObj.indicativeNetworkIdentity[1].lowerPort;
                        newScalableGroup.indicativeNetworkIdentity[0].upperPort = jsonObj.indicativeNetworkIdentity[1].upperPort;
                        newScalableGroup.indicativeNetworkIdentity[0].ipv4Subnet = jsonObj.indicativeNetworkIdentity[1].ipv4Subnet;
                        }


                        oldScalableGroup.indicativeNetworkIdentity.push(newScalableGroup.indicativeNetworkIdentity[0]);
                        oldScalableGroup.networkIdentity.push(newScalableGroup.networkIdentity[0]);

                    } else {
                        if(jsonObj.networkIdentity != undefined && jsonObj.networkIdentity[0] != undefined){
                            oldScalableGroup.networkIdentity[0].protocol = jsonObj.networkIdentity[0].protocol;
                            oldScalableGroup.networkIdentity[0].ports = jsonObj.networkIdentity[0].ports;
                            oldScalableGroup.networkIdentity[0].lowerPort = jsonObj.networkIdentity[0].lowerPort;
                            oldScalableGroup.networkIdentity[0].upperPort = jsonObj.networkIdentity[0].upperPort;
                            oldScalableGroup.networkIdentity[0].ipv4Subnet = jsonObj.networkIdentity[0].ipv4Subnet;
                        }


                        if(jsonObj.indicativeNetworkIdentity != undefined && jsonObj.indicativeNetworkIdentity[0] != undefined){
                            oldScalableGroup.indicativeNetworkIdentity[0].protocol = jsonObj.indicativeNetworkIdentity[0].protocol;
                            oldScalableGroup.indicativeNetworkIdentity[0].ports = jsonObj.indicativeNetworkIdentity[0].ports;
                            oldScalableGroup.indicativeNetworkIdentity[0].lowerPort = jsonObj.indicativeNetworkIdentity[0].lowerPort;
                            oldScalableGroup.indicativeNetworkIdentity[0].upperPort = jsonObj.indicativeNetworkIdentity[0].upperPort;
                            oldScalableGroup.indicativeNetworkIdentity[0].ipv4Subnet = jsonObj.indicativeNetworkIdentity[0].ipv4Subnet;
                        }

                    }
                    oldScalableGroup.virtualNetworkCount = "1";
                    oldScalableGroup.lastUpdateTime = UtilityFunctions.getTimeStamp();
                    console.log("created AccessPolicy = " + JSON.stringify(oldScalableGroup));
                    SimLokiDatabaseActions.insert('scalablegroup', oldScalableGroup);
                }

            }
            return undefined;
        }
    };
});
