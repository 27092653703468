define({
    "Chassis_Series_View_Template":{
    "cevPowerSupplyCat3kCT57xxC1Pwr1100wAc-down": "resources/GenericComponents/PWR-C1-1100WAC-Down.png",
    "cevPowerSupplyCat3kCT57xxC1Pwr1100wAc-up": "resources/GenericComponents/PWR-C1-1100WAC-Up.png",
    "cevFanCat3kCT57xxFan-up": "resources/GenericComponents/9300-Fan-Normal.png",
    "cevFanCat3kCT57xxFan-down": "resources/GenericComponents/9300-Fan-Abnormal.png",
    "C9300-NM-8X": "resources/GenericComponents/C9300-NM-8-10G.png",
    "C9300-NM-4G": "resources/GenericComponents/C9300-NM-4-1G.png",
    "cevPowerSupplyCat3kCT57xxC1Pwr715wAc-up": "resources/GenericComponents/PWR-C1-715WAC-up.png",
    "cevPowerSupplyCat3kCT57xxC1Pwr715wAc-down": "resources/GenericComponents/PWR-C1-715WAC-down.png",
    "cevPowerSupplyCat3kCT57xxC1Pwr350wAc-up": "resources/GenericComponents/PWR-C1-350WAC-Up.png",
    "cevPowerSupplyCat3kCT57xxC1Pwr350wAc-down": "resources/GenericComponents/PWR-C1-350WAC-Down.png",
    "cevPowerSupplyUnknown-down": "resources/GenericComponents/PWR-Generic-Down.png",
    "cevPowerSupplyC9300PWR1100ACP-up": "resources/GenericComponents/PWR-C1-1100WAC-Up.png",
    "cevPowerSupplyC9300PWR1100ACP-down": "resources/GenericComponents/PWR-C1-1100WAC-Down.png"
}
});