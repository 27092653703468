define({
    "Template":{
  "version": "1.0",
  "response": {
    "switch_port_flapping": {
      "Switches and Hubs": [
        {
          "name": "LINK_UP_1234567890123",
          "id": "f7eb3995-5278-45a5-98e3-efefc6aa",
          "eventName": "LINK_UP",
          "timestamp": 1234567890123,
          "details": [
            {
              "key": "Severity",
              "value": "Notice"
            },
            {
              "key": "Mnemonic",
              "value": "UP"
            },
            {
              "key": "Facility",
              "value": "LINK"
            },
            {
              "key": "Message Text",
              "value": "GigabitEthernet1/0/2 LINK UP"
            },
            {
              "key": "additionalInfo",
              "value": "LINK:UP"
            },
            {
              "key": "Message Type",
              "value": "Trap"
            },
            {
              "key": "Color Level",
              "value": "3"
            }
          ]
        },
        {
          "name": "LINK_DOWN_1234567890123",
          "id": "a56db64b-cde3-4d0a-ab9c-9d0b9498",
          "eventName": "LINK_DOWN",
          "timestamp": 1234567890123,
          "details": [
            {
              "key": "Severity",
              "value": "Error"
            },
            {
              "key": "Mnemonic",
              "value": "DOWN"
            },
            {
              "key": "Facility",
              "value": "LINK"
            },
            {
              "key": "Message Text",
              "value": "GigabitEthernet1/0/2 LINK DOWN"
            },
            {
              "key": "additionalInfo",
              "value": "LINK:DOWN"
            },
            {
              "key": "Message Type",
              "value": "Trap"
            },
            {
              "key": "Color Level",
              "value": "1"
            }
          ]
        }
      ]
    },
      "mac_flap_trigger": {
        "Switches and Hubs": [
        {
          "name": "SW_MATM_MACFLAP_NOTIF_1582603737577",
          "id": "0eb747c5-995b-4acf-b31a-7547c68e6f73",
          "eventName": "SW_MATM_MACFLAP_NOTIF",
          "timestamp": 1582603737577,
          "details": [
            {
              "key": "Severity",
              "value": "Warning"
            },
            {
              "key": "Mnemonic",
              "value": "MACFLAP_NOTIF"
            },
            {
              "key": "Facility",
              "value": "SW_MATM"
            },
            {
              "key": "Message Text",
              "value": "Host 54ab.3ab5.4bcd in vlan 31 is flapping between port Gi1/0/23 and port Gi1/0/13"
            },
            {
              "key": "additionalInfo",
              "value": "SW_MATM:MACFLAP_NOTIF"
            },
            {
              "key": "Message Type",
              "value": "Syslog"
            },
            {
              "key": "Color Level",
              "value": "2"
            }
          ]
        },
        {
          "name": "SW_MATM_MACFLAP_NOTIF_1582603557568",
          "id": "e4b7b3af-6ae6-405d-ae61-8f1e8e09ca96",
          "eventName": "SW_MATM_MACFLAP_NOTIF",
          "timestamp": 1582603557568,
          "details": [
            {
              "key": "Severity",
              "value": "Warning"
            },
            {
              "key": "Mnemonic",
              "value": "MACFLAP_NOTIF"
            },
            {
              "key": "Facility",
              "value": "SW_MATM"
            },
            {
              "key": "Message Text",
              "value": "Host 54ab.3ab5.4bcd in vlan 31 is flapping between port Gi1/0/13 and port Gi1/0/23"
            },
            {
              "key": "additionalInfo",
              "value": "SW_MATM:MACFLAP_NOTIF"
            },
            {
              "key": "Message Type",
              "value": "Syslog"
            },
            {
              "key": "Color Level",
              "value": "2"
            }
          ]
        },
        {
          "name": "SW_MATM_MACFLAP_NOTIF_1582603557568",
          "id": "e4b7b3af-6ae6-405d-ae61-8f1e8e09ca96",
          "eventName": "SW_MATM_MACFLAP_NOTIF",
          "timestamp": 1582603557568,
          "details": [
            {
              "key": "Severity",
              "value": "Warning"
            },
            {
              "key": "Mnemonic",
              "value": "MACFLAP_NOTIF"
            },
            {
              "key": "Facility",
              "value": "SW_MATM"
            },
            {
              "key": "Message Text",
              "value": "Host 54ab.3ab5.4bcd in vlan 31 is flapping between port Gi1/0/13 and port Gi1/0/23"
            },
            {
              "key": "additionalInfo",
              "value": "SW_MATM:MACFLAP_NOTIF"
            },
            {
              "key": "Message Type",
              "value": "Syslog"
            },
            {
              "key": "Color Level",
              "value": "2"
            }
          ]
        }
        ]
    },
    "ap_down": {
        "Unified AP": [
           {
                "name": "Radio Reset Normal_1552530109667",
                "id": "c4b83bfb-8e61-4f13-9597-278bbabea4e1",
                "eventName": "Radio Reset Normal",
                "timestamp": 1552530109667,
                "details": [{
                    "key": "WLC UUID",
                    "value": "a6c94e25-08b5-49ff-9434-d8f28127eb0b"
                }, {
                    "key": "WLC Name",
                    "value": "CT5520-MK"
                }, {
                    "key": "AP Mac",
                    "value": "F4:DB:E6:86:F1:E0"
                }, {
                    "key": "Radio",
                    "value": "2"
                }, {
                    "key": "EventType",
                    "value": "Radio Reset Normal"
                }, {
                    "key": "additionalInfo",
                    "value": "Radio : 2 | Interface Reset"
                }, {
                    "key": "ReasonCode",
                    "value": "Interface Reset"
                }, {
                    "key": "Radio Operation State",
                    "value": "2"
                }, {
                    "key": "Color Level",
                    "value": "2"
                }, {
                    "key": "Wlc Type",
                    "value": "1"
                }]
          },
          {
                "name": "AP Up_1551994932130",
                "id": "c8a39132-dd13-4be2-af8b-4fb2520f7e14",
                "eventName": "AP Up",
                "timestamp": 1551994932130,
                "details": [{
                    "key": "WLC UUID",
                    "value": "c238a5fe-3d3d-4d50-99cf-d42ebb861f5b"
                }, {
                    "key": "WLC Name",
                    "value": "CT5520-MK"
                }, {
                    "key": "AP Mac",
                    "value": "F4:DB:E6:86:F1:E0"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "EventType",
                    "value": "AP Up"
                }, {
                    "key": "additionalInfo",
                    "value": "Radio : 0 | Configuration Changes"
                }, {
                    "key": "Last Failure Reason",
                    "value": "Configuration Changes"
                }, {
                    "key": "Color Level",
                    "value": "3"
                }, {
                    "key": "Wlc Type",
                    "value": "1"
                }]
          },
          {
                "name": "AP Down_1551994730510",
                "id": "cbf44f52-1fea-4aeb-b5a3-a4b7a938661c",
                "eventName": "AP Down",
                "timestamp": 1551994730510,
                "details": [{
                    "key": "WLC UUID",
                    "value": "c238a5fe-3d3d-4d50-99cf-d42ebb861f5b"
                }, {
                    "key": "WLC Name",
                    "value": "CT5520-MK"
                }, {
                    "key": "AP Mac",
                    "value": "F4:DB:E6:86:F1:E0"
                }, {
                    "key": "Radio",
                    "value": "0"
                }, {
                    "key": "EventType",
                    "value": "AP Down"
                }, {
                    "key": "additionalInfo",
                    "value": "Radio : 0 | AP Echo Timeout - No HeartBeat Reply"
                }, {
                    "key": "ReasonCode",
                    "value": "AP Echo Timeout - No HeartBeat Reply"
                }, {
                    "key": "Color Level",
                    "value": "1"
                }, {
                    "key": "Wlc Type",
                    "value": "1"
                }]
          }
      ]
    },
    "network_device_poe_err_disabled_trigger":{
      "Switches and Hubs":[
        {
          "name": "ILPOWER_CONTROLLER_PORT_ERR_1588123982495",
          "id": "81afd75c-3bb7-42d2-a6aa-d65ea7c3f2b7",
          "eventName": "ILPOWER_CONTROLLER_PORT_ERR",
          "timestamp": 1588123982495,
          "details": [
            {
              "key": "Severity",
              "value": "Error"
            },
            {
              "key": "Mnemonic",
              "value": "CONTROLLER_PORT_ERR"
            },
            {
              "key": "Facility",
              "value": "ILPOWER"
            },
            {
              "key": "Message Text",
              "value": "2575: Apr 29 01:33:02.416:  Controller port error, Interface Tw1/0/18: Power Controller reports power Tstart error detected"
            },
            {
              "key": "additionalInfo",
              "value": "ILPOWER:CONTROLLER_PORT_ERR"
            },
            {
              "key": "Message Type",
              "value": "Syslog"
            },
            {
              "key": "Color Level",
              "value": "2"
            }
          ]
        },
        {
          "name": "DEVICE_AVAILABILITY_REACHABLE_1588123980196",
          "id": "6a131c69-842a-4d10-82b0-4a279a9f93d3",
          "eventName": "DEVICE_AVAILABILITY_REACHABLE",
          "timestamp": 1588123980196,
          "details": [
            {
              "key": "Severity",
              "value": "Warning"
            },
            {
              "key": "Mnemonic",
              "value": "REACHABLE"
            },
            {
              "key": "Facility",
              "value": "DEVICE_AVAILABILITY"
            },
            {
              "key": "Message Text",
              "value": "DEVICE IS REACHABLE"
            },
            {
              "key": "additionalInfo",
              "value": "DEVICE_AVAILABILITY:REACHABLE"
            },
            {
              "key": "Message Type",
              "value": "Event"
            },
            {
              "key": "Color Level",
              "value": "3"
            }
          ]
        },
        {
          "name": "LINK_UPDOWN_1588123925487",
          "id": "af96edbe-1493-4649-b2d0-b7c13fd83241",
          "eventName": "LINK_UPDOWN",
          "timestamp": 1588123925487,
          "details": [
            {
              "key": "Severity",
              "value": "Error"
            },
            {
              "key": "Mnemonic",
              "value": "UPDOWN"
            },
            {
              "key": "Facility",
              "value": "LINK"
            },
            {
              "key": "Message Text",
              "value": "2527: Apr 29 01:32:05.431:  Interface TwoGigabitEthernet1/0/20, changed state to down"
            },
            {
              "key": "additionalInfo",
              "value": "LINK:UPDOWN"
            },
            {
              "key": "Message Type",
              "value": "Syslog"
            },
            {
              "key": "Color Level",
              "value": "2"
            }
          ]
        },
        {
          "name": "LINK_UP_1588123921137",
          "id": "f91828eb-d8f0-4c71-a6d6-0bf14fd9f35a",
          "eventName": "LINK_UP",
          "timestamp": 1588123921137,
          "details": [
            {
              "key": "Severity",
              "value": "Notice"
            },
            {
              "key": "Mnemonic",
              "value": "UP"
            },
            {
              "key": "Facility",
              "value": "LINK"
            },
            {
              "key": "Message Text",
              "value": "TwoGigabitEthernet1/0/20 LINK UP"
            },
            {
              "key": "additionalInfo",
              "value": "LINK:UP"
            },
            {
              "key": "Message Type",
              "value": "Trap"
            },
            {
              "key": "Color Level",
              "value": "3"
            }
          ]
        },
        {
          "name": "PM_ERR_DISABLE_1588123688289",
          "id": "38e706ca-7019-4829-9085-02366833c211",
          "eventName": "PM_ERR_DISABLE",
          "timestamp": 1588123688289,
          "details": [
            {
              "key": "Severity",
              "value": "Warning"
            },
            {
              "key": "Mnemonic",
              "value": "ERR_DISABLE"
            },
            {
              "key": "Facility",
              "value": "PM"
            },
            {
              "key": "Message Text",
              "value": "2468: Apr 29 01:28:07.261:  inline-power error detected on Tw1/0/16, putting Tw1/0/16 in err-disable state"
            },
            {
              "key": "additionalInfo",
              "value": "PM:ERR_DISABLE"
            },
            {
              "key": "Message Type",
              "value": "Syslog"
            },
            {
              "key": "Color Level",
              "value": "2"
            }
          ]
        },
        {
          "name": "ILPOWER_SHUT_OVERDRAWN_1588123676337",
          "id": "ee94e380-0e64-465b-b9a1-4248bf21b9d9",
          "eventName": "ILPOWER_SHUT_OVERDRAWN",
          "timestamp": 1588123676337,
          "details": [
            {
              "key": "Severity",
              "value": "Error"
            },
            {
              "key": "Mnemonic",
              "value": "SHUT_OVERDRAWN"
            },
            {
              "key": "Facility",
              "value": "ILPOWER"
            },
            {
              "key": "Message Text",
              "value": "2463: Apr 29 01:27:56.271:  Interface Tw1/0/15 is shutdown as it is consuming more than the maximum configured power (7000) milliwatts."
            },
            {
              "key": "additionalInfo",
              "value": "ILPOWER:SHUT_OVERDRAWN"
            },
            {
              "key": "Message Type",
              "value": "Syslog"
            },
            {
              "key": "Color Level",
              "value": "2"
            }
          ]
        }
      ]
    },
    "network_device_controller_poe_trigger": {
     "Switches and Hubs": [
       {
          "name": "ILPOWER_CONTROLLER_PORT_ERR_1588156551279",
          "id": "9bac1193-836f-42f7-b2c3-61d1a2b08fea",
          "eventName": "ILPOWER_CONTROLLER_PORT_ERR",
          "timestamp": 1588156551279,
          "details": [
            {
              "key": "Severity",
              "value": "Error"
            },
            {
              "key": "Mnemonic",
              "value": "CONTROLLER_PORT_ERR"
            },
            {
              "key": "Facility",
              "value": "ILPOWER"
            },
            {
              "key": "Message Text",
              "value": "54808: Apr 29 10:35:50.232:  Controller port error, Interface Tw1/0/20: Power Controller reports power Tstart error detected"
            },
            {
              "key": "additionalInfo",
              "value": "ILPOWER:CONTROLLER_PORT_ERR"
            },
            {
              "key": "Message Type",
              "value": "Syslog"
            },
            {
              "key": "Color Level",
              "value": "2"
            }
          ]
       },
       {
          "name": "ILPOWER_CONTROLLER_PORT_ERR_1588156550254",
          "id": "3d1eb940-a602-4e8e-9284-c1f02e44afdd",
          "eventName": "ILPOWER_CONTROLLER_PORT_ERR",
          "timestamp": 1588156550254,
          "details": [
            {
              "key": "Severity",
              "value": "Error"
            },
            {
              "key": "Mnemonic",
              "value": "CONTROLLER_PORT_ERR"
            },
            {
              "key": "Facility",
              "value": "ILPOWER"
            },
            {
              "key": "Message Text",
              "value": "54806: Apr 29 10:35:50.230:  Controller port error, Interface Tw1/0/19: Power Controller reports power Tstart error detected"
            },
            {
              "key": "additionalInfo",
              "value": "ILPOWER:CONTROLLER_PORT_ERR"
            },
            {
              "key": "Message Type",
              "value": "Syslog"
            },
            {
              "key": "Color Level",
              "value": "2"
            }
          ]
       },
       {
          "name": "ILPOWER_CONTROLLER_PORT_ERR_1588156550252",
          "id": "9aecd9dc-b169-49f2-ac87-e90653ac7f6b",
          "eventName": "ILPOWER_CONTROLLER_PORT_ERR",
          "timestamp": 1588156550252,
          "details": [
            {
              "key": "Severity",
              "value": "Error"
            },
            {
              "key": "Mnemonic",
              "value": "CONTROLLER_PORT_ERR"
            },
            {
              "key": "Facility",
              "value": "ILPOWER"
            },
            {
              "key": "Message Text",
              "value": "54804: Apr 29 10:35:50.228:  Controller port error, Interface Tw1/0/18: Power Controller reports power Tstart error detected"
            },
            {
              "key": "additionalInfo",
              "value": "ILPOWER:CONTROLLER_PORT_ERR"
            },
            {
              "key": "Message Type",
              "value": "Syslog"
            },
            {
              "key": "Color Level",
              "value": "2"
            }
          ]
       }
     ]
    },
    "network_device_poe_power_deny_trigger":{
      "Switches and Hubs": [
        {
          "name": "ILPOWER_CONTROLLER_PORT_ERR_1588155664079",
          "id": "0437a194-6c9f-49dc-b73a-4b53d2af6e4f",
          "eventName": "ILPOWER_CONTROLLER_PORT_ERR",
          "timestamp": 1588155664079,
          "details": [
            {
              "key": "Severity",
              "value": "Error"
            },
            {
              "key": "Mnemonic",
              "value": "CONTROLLER_PORT_ERR"
            },
            {
              "key": "Facility",
              "value": "ILPOWER"
            },
            {
              "key": "Message Text",
              "value": "53376: Apr 29 10:21:03.031:  Controller port error, Interface Tw1/0/20: Power Controller reports power Tstart error detected"
            },
            {
              "key": "additionalInfo",
              "value": "ILPOWER:CONTROLLER_PORT_ERR"
            },
            {
              "key": "Message Type",
              "value": "Syslog"
            },
            {
              "key": "Color Level",
              "value": "2"
            }
          ]
        },
        {
          "name": "ILPOWER_CONTROLLER_PORT_ERR_1588155663054",
          "id": "25ddd75b-4400-416f-a470-f217c481dae3",
          "eventName": "ILPOWER_CONTROLLER_PORT_ERR",
          "timestamp": 1588155663054,
          "details": [
            {
              "key": "Severity",
              "value": "Error"
            },
            {
              "key": "Mnemonic",
              "value": "CONTROLLER_PORT_ERR"
            },
            {
              "key": "Facility",
              "value": "ILPOWER"
            },
            {
              "key": "Message Text",
              "value": "53374: Apr 29 10:21:03.030:  Controller port error, Interface Tw1/0/18: Power Controller reports power Tstart error detected"
            },
            {
              "key": "additionalInfo",
              "value": "ILPOWER:CONTROLLER_PORT_ERR"
            },
            {
              "key": "Message Type",
              "value": "Syslog"
            },
            {
              "key": "Color Level",
              "value": "2"
            }
          ]
        },
        {
          "name": "ILPOWER_CONTROLLER_PORT_ERR_1588155655753",
          "id": "ba524241-14ce-4af9-99e7-342567499e71",
          "eventName": "ILPOWER_CONTROLLER_PORT_ERR",
          "timestamp": 1588155655753,
          "details": [
            {
              "key": "Severity",
              "value": "Error"
            },
            {
              "key": "Mnemonic",
              "value": "CONTROLLER_PORT_ERR"
            },
            {
              "key": "Facility",
              "value": "ILPOWER"
            },
            {
              "key": "Message Text",
              "value": "53360: Apr 29 10:20:55.280:  Controller port error, Interface Tw1/0/19: Power Controller reports power Tstart error detected"
            },
            {
              "key": "additionalInfo",
              "value": "ILPOWER:CONTROLLER_PORT_ERR"
            },
            {
              "key": "Message Type",
              "value": "Syslog"
            },
            {
              "key": "Color Level",
              "value": "2"
            }
          ]
        },
        {
          "name": "ILPOWER_CONTROLLER_PORT_ERR_1588155655323",
          "id": "1f017f6c-78f8-4cc6-94c0-ec3c86671d49",
          "eventName": "ILPOWER_CONTROLLER_PORT_ERR",
          "timestamp": 1588155655323,
          "details": [
            {
              "key": "Severity",
              "value": "Error"
            },
            {
              "key": "Mnemonic",
              "value": "CONTROLLER_PORT_ERR"
            },
            {
              "key": "Facility",
              "value": "ILPOWER"
            },
            {
              "key": "Message Text",
              "value": "53358: Apr 29 10:20:55.278:  Controller port error, Interface Tw1/0/17: Power Controller reports power Tstart error detected"
            },
            {
              "key": "additionalInfo",
              "value": "ILPOWER:CONTROLLER_PORT_ERR"
            },
            {
              "key": "Message Type",
              "value": "Syslog"
            },
            {
              "key": "Color Level",
              "value": "2"
            }
          ]
        }
      ]
    },
    "default_trap_event_trigger":{
        "Switches and Hubs": [
        {
          "name": "RADIUS_ALLDEADSERVER_1617299457260",
          "id": "90ff1861-a73b-4474-9ccb-3dc3a1cd50f3",
          "eventName": "RADIUS_ALLDEADSERVER",
          "timestamp": 1617299457260,
          "details": [
            {
              "key": "Severity",
              "value": "Error"
            },
            {
              "key": "Mnemonic",
              "value": "ALLDEADSERVER"
            },
            {
              "key": "Facility",
              "value": "RADIUS"
            },
            {
              "key": "Message Text",
              "value": "181041: 238897: *Apr  1 17:50:07.000:  Group dnac-network-radius-group: No active radius servers found. Id 243."
            },
            {
              "key": "additionalInfo",
              "value": "RADIUS:ALLDEADSERVER"
            },
            {
              "key": "Message Type",
              "value": "Syslog"
            },
            {
              "key": "Color Level",
              "value": "2"
            }
          ]
        },
        {
          "name": "FMANRP_PEER_IPC_RESUME_1617299409420",
          "id": "45dc0295-346c-45ca-8bbc-074558e91728",
          "eventName": "FMANRP_PEER_IPC_RESUME",
          "timestamp": 1617299409420,
          "details": [
            {
              "key": "Severity",
              "value": "Error"
            },
            {
              "key": "Mnemonic",
              "value": "PEER_IPC_RESUME"
            },
            {
              "key": "Facility",
              "value": "FMANRP"
            },
            {
              "key": "Message Text",
              "value": "181021: 238871: *Apr  1 17:49:17.848:  Switch 1 R0/0: fman_rp: IPC to fman-log-bay0-peer2 has returned to normal after previous stuck"
            },
            {
              "key": "additionalInfo",
              "value": "FMANRP:PEER_IPC_RESUME"
            },
            {
              "key": "Message Type",
              "value": "Syslog"
            },
            {
              "key": "Color Level",
              "value": "2"
            }
          ]
        },
        {
          "name": "FMANRP_PEER_IPC_STUCK_1617299349460",
          "id": "c0b39e1a-4a75-4330-9999-00c31ae5fb35",
          "eventName": "FMANRP_PEER_IPC_STUCK",
          "timestamp": 1617299349460,
          "details": [
            {
              "key": "Severity",
              "value": "Error"
            },
            {
              "key": "Mnemonic",
              "value": "PEER_IPC_STUCK"
            },
            {
              "key": "Facility",
              "value": "FMANRP"
            },
            {
              "key": "Message Text",
              "value": "181001: 238843: *Apr  1 17:48:17.846:  Switch 1 R0/0: fman_rp: IPC to fman-log-bay0-peer2 is stuck for more than 30 seconds"
            },
            {
              "key": "additionalInfo",
              "value": "FMANRP:PEER_IPC_STUCK"
            },
            {
              "key": "Message Type",
              "value": "Syslog"
            },
            {
              "key": "Color Level",
              "value": "2"
            }
          ]
        },
        {
          "name": "LINK_DOWN_1617299308661",
          "id": "6382f3da-9157-4e5b-ac3b-686c54abc947",
          "eventName": "LINK_DOWN",
          "timestamp": 1617299308661,
          "details": [
            {
              "key": "Severity",
              "value": "Error"
            },
            {
              "key": "Mnemonic",
              "value": "DOWN"
            },
            {
              "key": "Facility",
              "value": "LINK"
            },
            {
              "key": "Message Text",
              "value": "TenGigabitEthernet2/0/16 LINK DOWN"
            },
            {
              "key": "additionalInfo",
              "value": "LINK:DOWN"
            },
            {
              "key": "Message Type",
              "value": "Trap"
            },
            {
              "key": "Color Level",
              "value": "1"
            }
          ]
        },
        {
          "name": "STACKMGR_DUAL_ACTIVE_CFG_MSG_1617299306336",
          "id": "96d76e1c-7781-45ef-9e8c-c491a9195e53",
          "eventName": "STACKMGR_DUAL_ACTIVE_CFG_MSG",
          "timestamp": 1617299306336,
          "details": [
            {
              "key": "Severity",
              "value": "Alert"
            },
            {
              "key": "Mnemonic",
              "value": "DUAL_ACTIVE_CFG_MSG"
            },
            {
              "key": "Facility",
              "value": "STACKMGR"
            },
            {
              "key": "Message Text",
              "value": "180995: 238837: *Apr  1 17:47:35.427:  Switch 1 R0/0: stack_mgr: Dual Active Detection links are not available anymore"
            },
            {
              "key": "additionalInfo",
              "value": "STACKMGR:DUAL_ACTIVE_CFG_MSG"
            },
            {
              "key": "Message Type",
              "value": "Syslog"
            },
            {
              "key": "Color Level",
              "value": "1"
            }
          ]
        },
        {
          "name": "REDUNDANCY_STANDBY_LOST_1617299303788",
          "id": "9c2ec801-7b58-40fd-9be8-517a2961f069",
          "eventName": "REDUNDANCY_STANDBY_LOST",
          "timestamp": 1617299303788,
          "details": [
            {
              "key": "Severity",
              "value": "Error"
            },
            {
              "key": "Mnemonic",
              "value": "STANDBY_LOST"
            },
            {
              "key": "Facility",
              "value": "REDUNDANCY"
            },
            {
              "key": "Message Text",
              "value": "180953: 238795: *Apr  1 17:47:33.165:  Standby processor fault (PEER_DOWN)"
            },
            {
              "key": "additionalInfo",
              "value": "REDUNDANCY:STANDBY_LOST"
            },
            {
              "key": "Message Type",
              "value": "Syslog"
            },
            {
              "key": "Color Level",
              "value": "2"
            }
          ]
        },
        {
          "name": "LINK_UP_1617299276754",
          "id": "69285cd7-bca5-4271-9182-d12d4573be27",
          "eventName": "LINK_UP",
          "timestamp": 1617299276754,
          "details": [
            {
              "key": "Severity",
              "value": "Notice"
            },
            {
              "key": "Mnemonic",
              "value": "UP"
            },
            {
              "key": "Facility",
              "value": "LINK"
            },
            {
              "key": "Message Text",
              "value": "TenGigabitEthernet2/0/13 LINK UP"
            },
            {
              "key": "additionalInfo",
              "value": "LINK:UP"
            },
            {
              "key": "Message Type",
              "value": "Trap"
            },
            {
              "key": "Color Level",
              "value": "3"
            }
          ]
        },
        {
          "name": "STACKMGR_DUAL_ACTIVE_CFG_MSG_1617299276255",
          "id": "c8bd0861-eb00-4b3a-937e-0292a8c63a15",
          "eventName": "STACKMGR_DUAL_ACTIVE_CFG_MSG",
          "timestamp": 1617299276255,
          "details": [
            {
              "key": "Severity",
              "value": "Alert"
            },
            {
              "key": "Mnemonic",
              "value": "DUAL_ACTIVE_CFG_MSG"
            },
            {
              "key": "Facility",
              "value": "STACKMGR"
            },
            {
              "key": "Message Text",
              "value": "180943: 238784: *Apr  1 17:47:05.024:  Switch 2 R0/0: stack_mgr: Dual Active Detection link is available now"
            },
            {
              "key": "additionalInfo",
              "value": "STACKMGR:DUAL_ACTIVE_CFG_MSG"
            },
            {
              "key": "Message Type",
              "value": "Syslog"
            },
            {
              "key": "Color Level",
              "value": "1"
            }
          ]
        }
    ]
    }

  }
}
})