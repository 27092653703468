define([

], function () {
    return {
        init: function () {},

        doProcessRestRequest: function(urlAction){
            var data;
            switch(urlAction.method){
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                case 'POST':
                    data = processPostRequest(urlAction);
                    break;

                case 'DELETE':
                    data = processDeleteRequest(urlAction);
                    break;

                default:
                    break;
            }
            return data;
        }
    };

    function processGetRequest(urlAction){
        //need to write code here
        return {"response":[{"password":"$5$lXjKtNEoxpjv+xm+97sD2IXWkDOzaI4W8KL2408+DhbgB0+KDrR9CcTj0YV6oMpARvO9a5i8o2cEZT3wJagufjcsW1VmSUCxH3coyZw7NNiEjXHS2GdBcA==","sftpServerType":"LOCAL","scpSupport":true,"servername":"10.1.24.22","username":"sftpuser","port":22,"rootlocation":"/var/tmp/file-service","insecure_access_expiry_duration":-1,"id":"b5b4ecd8-142c-4df4-a1e5-0d374c72cb9c"}],"version":"1.0"}
    }
    function processPostRequest(urlAction){

    }
    function processDeleteRequest(urlAction){

    }
});