define({
    "server":{
        "version":"1.0",
        "response":[
            {
              "serverIp":"192.168.152.1",
              "wlcName":"WLC-9800",
              "wlcUuid":"32d8b0d6-0363-1474-8282-834c465c20cb",
              "wlcLocation":"Global/North America/USA/California/San Jose/SJC01",
              "totalTransaction":14,
              "failedTransaction":0,
              "eapTransaction":14,
              "mabTransaction":0,
              "eapFailedTransaction":0,
              "mabFailedTransaction":0,
              "totalAvgLatency":45,
              "eapAvgLatency":45,
              "mabAvgLatency":null,
              "doAvgLatency":45,
              "raAvgLatency":1
            },{
                "serverIp":"100.30.189.51",
                "wlcName":"SWLC-FABRIC-01",
                "wlcUuid":"dd0c8391-e48d-983f-fa9a-586e19abdaa1",
                "wlcLocation":"Global/North America/USA/California/San Jose/SJC01",
                "totalTransaction":7,
                "failedTransaction":1,
                "eapTransaction":7,
                "mabTransaction":0,
                "eapFailedTransaction":1,
                "mabFailedTransaction":0,
                "totalAvgLatency":36,
                "eapAvgLatency":36,
                "mabAvgLatency":null,
                "doAvgLatency":36,
                "raAvgLatency":9
              },
              {
                "serverIp":"104.194.73.167",
                "wlcName":"SJC06-vWLC-9800",
                "wlcUuid":"855c5cb7-363d-166f-d8d8-d06bc54fcc53",
                "wlcLocation":"Global/North America/USA/California/San Jose/SJC06",
                "totalTransaction":15,
                "failedTransaction":2,
                "eapTransaction":15,
                "mabTransaction":0,
                "eapFailedTransaction":2,
                "mabFailedTransaction":0,
                "totalAvgLatency":28,
                "eapAvgLatency":28,
                "mabAvgLatency":null,
                "doAvgLatency":28,
                "raAvgLatency":4
              },
             {
                "serverIp":"140.102.148.249",
                "wlcName":"Campus_WLC3",
                "wlcUuid":"ca6fcfa9-66ea-dc59-31b3-f7bbbf664d05",
                "wlcLocation":"Global/North America/USA/California/San Jose/SJC05",
                "totalTransaction":10,
                "failedTransaction":1,
                "eapTransaction":10,
                "mabTransaction":0,
                "eapFailedTransaction":1,
                "mabFailedTransaction":0,
                "totalAvgLatency":43,
                "eapAvgLatency":43,
                "mabAvgLatency":null,
                "doAvgLatency":43,
                "raAvgLatency":6
              },
              {
                "serverIp":"116.140.161.52",
                "wlcName":"Campus_WLC4",
                "wlcUuid":"82e06d214-e186-b3cc-b2ac-bb8f7be968d8",
                "wlcLocation":"Global/North America/USA/Washington/Seattle/SE1",
                "totalTransaction":3,
                "failedTransaction":0,
                "eapTransaction":3,
                "mabTransaction":0,
                "eapFailedTransaction":0,
                "mabFailedTransaction":0,
                "totalAvgLatency":54,
                "eapAvgLatency":54,
                "mabAvgLatency":null,
                "doAvgLatency":54,
                "raAvgLatency":7
              },
              {
                "serverIp":"118.130.12.121",
                "wlcName":"SJC06-WLC-ISSU",
                "wlcUuid":"273f236c-245d-0ef6-6304-2c25bdd93f8b",
                "wlcLocation":"Global/North America/USA/California/San Jose/SJC06",
                "totalTransaction":4,
                "failedTransaction":2,
                "eapTransaction":4,
                "mabTransaction":0,
                "eapFailedTransaction":2,
                "mabFailedTransaction":0,
                "totalAvgLatency":4,
                "eapAvgLatency":4,
                "mabAvgLatency":null,
                "doAvgLatency":4,
                "raAvgLatency":3
              }
        ],
        "page":{
            "pageSize":1,
            "offset":0,
            "totalCount":1,
            "randomMacCount":null,
            "duidCount":null,
            "nextKey":null,
            "topN":null
        }
    },
    "ALL_TYPE_OF_LATENCY" :{
        
            "detailList":[
               {
                  "rankVar":null,
                  "rankValue":null,
                  "varName":"avgDOLatency",
                  "varValue":null,
                  "detailList":[
                    {
                       "rankVar":"serverIp",
                       "rankValue":"192.168.152.1",
                       "varName":"avgDOLatency",
                       "varValue":null,
                       "detailList":[
                          {
                             "rankVar":null,
                             "rankValue":"2021-08-09T17:25:00.000+0000",
                             "varName":"avgDOLatency",
                             "varValue":7.0
                           }
                        ]
                    },
                    {
                        "rankVar":"serverIp",
                        "rankValue":"100.30.189.51",
                        "varName":"avgDOLatency",
                        "varValue":null,
                        "detailList":[
                           {
                              "rankVar":null,
                              "rankValue":"2021-08-09T17:25:00.000+0000",
                              "varName":"avgDOLatency",
                              "varValue":7.0
                            }
                         ]
                     },{
                        "rankVar":"serverIp",
                        "rankValue":"104.194.73.167",
                        "varName":"avgDOLatency",
                        "varValue":null,
                        "detailList":[
                           {
                              "rankVar":null,
                              "rankValue":"2021-08-09T17:25:00.000+0000",
                              "varName":"avgDOLatency",
                              "varValue":7.0
                            }
                         ]
                     },{
                        "rankVar":"serverIp",
                        "rankValue":"140.102.148.249",
                        "varName":"avgDOLatency",
                        "varValue":null,
                        "detailList":[
                           {
                              "rankVar":null,
                              "rankValue":"2021-08-09T17:25:00.000+0000",
                              "varName":"avgDOLatency",
                              "varValue":7.0
                            }
                         ]
                     }
                    ]
                },
                {
                  "rankVar":null,
                  "rankValue":null,
                  "varName":"avgRALatency",
                  "varValue":null,
                  "detailList":[
                    {
                       "rankVar":"serverIp",
                       "rankValue":"116.140.161.52",
                       "varName":"avgRALatency",
                       "varValue":null,
                       "detailList":[
                          {
                             "rankVar":null,
                             "rankValue":"2021-08-09T17:25:00.000+0000",
                             "varName":"avgRALatency",
                             "varValue":7.0
                           }
                        ]
                    },
                    {
                        "rankVar":"serverIp",
                        "rankValue":"118.130.12.121",
                        "varName":"avgRALatency",
                        "varValue":null,
                        "detailList":[
                           {
                              "rankVar":null,
                              "rankValue":"2021-08-09T17:25:00.000+0000",
                              "varName":"avgRALatency",
                              "varValue":7.0
                            }
                         ]
                     }
                    ]
                },
                {
                    "rankVar":null,
                    "rankValue":null,
                    "varName":"totalAvgLatency",
                    "varValue":null,
                    "detailList":[
                        {
                        "rankVar":"serverIp",
                        "rankValue":"192.168.152.1",
                        "varName":"totalAvgLatency",
                        "varValue":null,
                        "detailList":[
                            {
                                "rankVar":null,
                                "rankValue":"2021-08-09T17:25:00.000+0000",
                                "varName":"totalAvgLatency",
                                "varValue":8.0
                            }
                            ]
                        },
                        {
                            "rankVar":"serverIp",
                            "rankValue":"100.30.189.51",
                            "varName":"totalAvgLatency",
                            "varValue":null,
                            "detailList":[
                                {
                                    "rankVar":null,
                                    "rankValue":"2021-08-09T17:25:00.000+0000",
                                    "varName":"totalAvgLatency",
                                    "varValue":8.0
                                }
                                ]
                            },
                            {
                                "rankVar":"serverIp",
                                "rankValue":"104.194.73.167",
                                "varName":"totalAvgLatency",
                                "varValue":null,
                                "detailList":[
                                    {
                                        "rankVar":null,
                                        "rankValue":"2021-08-09T17:25:00.000+0000",
                                        "varName":"totalAvgLatency",
                                        "varValue":8.0
                                    }
                                    ]
                            },
                            {
                                "rankVar":"serverIp",
                                "rankValue":"140.102.148.249",
                                "varName":"eapAvgLatency",
                                "varValue":null,
                                "detailList":[
                                   {
                                      "rankVar":null,
                                      "rankValue":"2021-08-09T17:25:00.000+0000",
                                      "varName":"eapAvgLatency",
                                      "varValue":7.0
                                    }
                                 ]
                             }
                    ]
                }
            ]
        },
        "TREND":
        {
                "totalTransactions":3,
                "successfulTransactions":3,
                "failedTransactions":0,
                "time":"2021-08-08T10:05:00.000+0000",
                "value":0.0
        },
        "TotalAvgLatency":{
            
                "rankVar":"fdcf51fe-c2b6-4e64-81a8-11a7bd04ef0d",
                "rankValue":"San Jose/SJC14/Floor 1",
                "varName":"aaaAvgLatency",
                "varValue":9
            
        }
    

})