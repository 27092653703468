define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/inventory/DeviceCredentialTemplate'
], function (SimLokiDatabaseActions, DeviceCredentialTemplate) {

    return {
        init: function() {
        },

        doProcessRestRequest: function(urlAction){
            console.log(urlAction.url);
            console.log(urlAction);
            var data;
            switch(urlAction.method) {
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                case 'POST':
                    break;

                case 'PUT':
                    break;

                case 'DELETE':
                    break;

                default:
                    break;
            }

            return data;
        }
     };

    function processGetRequest(urlAction){
        var deviceIp = urlAction.filter['deviceIps'];
        var data = SimLokiDatabaseActions.getFilteredRecordHandler('device-credential', { 'ipAddress' : { '$in' : deviceIp } });
        if(data != undefined && data.length > 0){
            return data;
        }
        var response = [];
        var t_Json = JSON.parse(JSON.stringify(DeviceCredentialTemplate.DeviceCredential_Template));
        t_Json.ipAddress = deviceIp;
        response.push(t_Json);
        return response;
    }

});
