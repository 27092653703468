define({
  "Assurance_HostHealthDetails_Template":{
      'version': '1.0',
      'response':[
      {
        'siteId':"global",
        'scoreDetail':
        [{
            'scoreCategory':{'scoreCategory':"CLIENT_TYPE", 'value':"ALL"},
            'scoreValue':95,'clientCount':306,'clientUniqueCount':306,'starttime':1508472900000,'endtime':1508473200000,
            'scoreList':[]
        },
        {
            'scoreCategory':{
              'scoreCategory':"CLIENT_TYPE",'value':"WIRED"},
              'scoreValue':90,'clientCount':56,'clientUniqueCount':56,'starttime':1508472900000,'endtime':1508473200000,
              'scoreList':[
                  { 'scoreCategory':{'scoreCategory':"SCORE_TYPE",'value':"TOTAL"},      'scoreValue':100,'clientCount':56,'clientUniqueCount':56,'starttime':1508472900000,'endtime':1508473200000,  'scoreList':[]},
                  { 'scoreCategory':{    'scoreCategory':"SCORE_TYPE",value:"FAIR"},'scoreValue':0,'clientCount':0,
                        'clientUniqueCount':0,'starttime':1508472900000,'endtime':1508473200000,'scoreList':[]
                  },
                  {'scoreCategory':{ 'scoreCategory':"SCORE_TYPE",value:"POOR"},'scoreValue':0,'clientCount':0,
                        'clientUniqueCount':0,'starttime':1508472900000,'endtime':1508473200000,'scoreList':[]},
                  {'scoreCategory':{   'scoreCategory':"SCORE_TYPE",value:"GOOD"},scoreValue:100,clientCount:56,
                    'clientUniqueCount':56,'starttime':1508472900000,'endtime':1508473200000,'scoreList':[{'scoreCategory':{     'scoreCategory':"DEVICE_TYPE",'value':""},'scoreValue':100,'clientCount':56,
                    'clientUniqueCount':56,'starttime':1508472900000,'endtime':1508473200000,'scoreList':[]}]
                  }
              ]
        },
        {"scoreCategory":{ "scoreCategory":"CLIENT_TYPE","value":"WIRELESS"},"scoreValue":90,"clientCount":250,
                           "clientUniqueCount":250,"starttime":1508472900000,"endtime":1508473200000,
         scoreList:[{"scoreCategory":    {"scoreCategory":"SCORE_TYPE","value":"TOTAL"},"scoreValue":90,"clientCount":250,"clientUniqueCount":250,"starttime":1508472900000,"endtime":1507522799900,"scoreList":[]},{"scoreCategory":{"scoreCategory":"SCORE_TYPE","value":"FAIR"},"scoreValue":0,"clientCount":0,"clientUniqueCount":0,"starttime":null,"endtime":1507522799900,"scoreList":[]},{"scoreCategory":{"scoreCategory":"SCORE_TYPE","value":"POOR"},"scoreValue":4,"clientCount":4,"clientUniqueCount":4,"starttime":1508472900000,"endtime":1507522799900,"scoreList":[]},{"scoreCategory":{"scoreCategory":"SCORE_TYPE","value":"GOOD"},"scoreValue":90,"clientCount":246,"clientUniqueCount":246,"starttime":1508472900000,"endtime":1507522799900,"scoreList":[]
         }]
        }
        ]
        }
     ]
  }
});
