define({
    "Template":{
    "testResultDetails": [
      {
        "id": "AWWAXkC1i1ozWvQF41MT",
        "desc": "Sensor onboarding failed",
        "ip": "",
        "ipType": "External",
        "domain": "",
        "failureReason": "",
        "sensors": [
          {
            "name": "AP1832i.8a:20",
            "ssid": "dot1x_232",
            "band": "5 GHz",
            "sensorId": "7cfab4c6-0805-4557-8ef7-41a56eeab7bf"
          }
        ],
        "status": "failed",
        "testName": "ONBOARDING"
      },
      {
        "id": "AWWAXkC1i1ozWvQF41MT",
        "desc": "DHCPv4 addressing time slow, above 3 seconds",
        "ip": "",
        "ipType": "External",
        "domain": "",
        "failureReason": "",
        "sensors": [
          {
            "name": "AP1832i.8a:20",
            "ssid": "dot1x_232",
            "band": "5 GHz",
            "sensorId": "7cfab4c6-0805-4557-8ef7-41a56eeab7bf"
          }
        ],
        "status": "failed",
        "testName": "ONBOARDING"
      },
      {
        "id": "AWWAXkC1i1ozWvQF41MT",
        "desc": "Mail server is not reachable",
        "ip": "mail.cisco.com",
        "ipType": "External",
        "domain": "mail.cisco.com",
        "failureReason": "",
        "sensors": [
          {
            "name": "AP1832i.8a:20",
            "ssid": "dot1x_232",
            "band": "5 GHz",
            "sensorId": "7cfab4c6-0805-4557-8ef7-41a56eeab7bf"
          }
        ],
        "status": "failed",
        "testName": "MailServer"
      },
      {
        "id": "AWWAXkC1i1ozWvQF41MT",
        "desc": "Mail Server test failed",
        "ip": "mail.cisco.com",
        "ipType": "External",
        "domain": "mail.cisco.com",
        "failureReason": "",
        "sensors": [
          {
            "name": "AP1832i.8a:20",
            "ssid": "dot1x_232",
            "band": "5 GHz",
            "sensorId": "7cfab4c6-0805-4557-8ef7-41a56eeab7bf"
          }
        ],
        "status": "failed",
        "testName": "MailServer"
      },

    ]
  }
});