define({
  "Template": [
    {
      "_id": "5ad7498764825e0020f152a1",
      "name": "Sensor_Test_02",
      "version": 7,
      "startTime": 1527213144928,
      "lastModifiedTime": 1527213144929,
      "numAssociatedSensor": 1,
      "location": "99ee5379-de75-4cd2-a02b-bd95e29f11a3",
      "siteHierarchy": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "status": "NOTRUNNING",
      "connection": "WIRELESS",
      "encryptionMode": "CBC",
      "modelVersion": 2,
      "runNow": "NO",
      "schedule": {
        "testScheduleMode": "DEDICATED",
        "scheduleRange": [],
        "startTime": 0,
        "frequency": {
        "value": 30,
        "unit": "MINUTES"
      },
      },
      "locationInfoList": [
      {
        "locationId": "99ee5379-de75-4cd2-a02b-bd95e29f11a3",
        "locationType": "floor",
        "allSensors": false,
        "siteHierarchy": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "macAddressList": []
      }
      ],
        "frequency": {
          "value": 30,
          "unit": "MINUTES"
        },
        "rssiThreshold": -75,
        "numNeighborAPThreshold": 5,
        "scheduleInDays": 0,
        "wlans": [

        ],
        "apCoverage": [
        {
          "bands": "2.4GHz",
          "numberOfApsToTest": 1,
          "rssiThreshold": -70
        },
        {
          "bands": "5GHz",
          "numberOfApsToTest": 1,
          "rssiThreshold": -70
        }
      ],
      "ssids": [
        {
          "bands": "BOTH",
          "ssid": "DEMO-NF-ASSURANCE",
          "profileName": "DEMO-NF-ASSURANCE",
          "authType": "WPA2_EAP",
          "authTypeRcvd": null,
          "psk": null,
          "username": "andy",
          "password": "r5M4NjkS6xhEgy+PwGQBIA==",
          "eapMethod": "EAP-FAST",
          "authProtocol": null,
          "certfilename": null,
          "certxferprotocol": "HTTP",
          "certstatus": "ACTIVE",
          "certpassphrase": null,
          "certdownloadurl": null,
          "qosPolicy": null,
          "numAps": 0,
          "numSensors": 0,
          "layer3webAuthsecurity": null,
          "layer3webAuthuserName": null,
          "layer3webAuthpassword": null,
          "layer3webAuthEmailAddress": null,
          "id": 0,
          "wlanId": 0,
          "wlc": "10.16.30.21",
          "validFrom": 0,
          "validTo": 0,
          "status": "ENABLED",
          "tests":[
          {
            "name": "Onboarding",
            "config": [
              {}
            ]
          },
          {
            "name": "DNS",
            "config": [
              {
                "domains": [
                  "www.cisco.com"
                ]
              }
            ]
          },
          {
            "name": "WebServer",
            "config": [
              {
                "url": "http://www.cisco.com",
                "port": 80
              }
            ]
          },
          {
            "name": "IpslaSender",
            "config": [
              {
                "probeType": "udp_jitter_v1",
                "numPackets": 2
              }
            ]
          }
        ]
        }
      ],
      "testScheduleMode": "DEDICATED",
       "showWlcUpgradeBanner": false,
       "radioAsSensorRemoved": true,
      "tests": [
        {
          "name": "DNS",
          "connection": "WIRELESS",
          "wlans": [
            "DEMO-NF-ASSURANCE"
          ],
          "frequency": {
            "value": 30,
            "unit": "MINUTES"
          },
          "scheduleInDays": 0,
          "bands": "BOTH",
          "numberOfApsToTest": 5,
          "repeatCountOnFailure": 2,
          "config": [
            {
              "domains": [
                "www.cisco.com"
              ]
            }
          ],
          "ssids": null,
          "testSuiteId": null,
          "type": null,
          "startTime": 0
        },
        {
          "name": "Ping",
          "connection": "WIRELESS",
          "wlans": [
            "DEMO-NF-ASSURANCE"
          ],
          "frequency": {
            "value": 30,
            "unit": "MINUTES"
          },
          "scheduleInDays": 0,
          "bands": "BOTH",
          "numberOfApsToTest": 5,
          "repeatCountOnFailure": 2,
          "config": [
            {
              "server": "1I_10.16.0.1"
            },
            {
              "server": "1E_10.16.1.66"
            }
          ],
          "ssids": null,
          "testSuiteId": null,
          "type": null,
          "startTime": 0
        },
        {
          "name": "Radius",
          "connection": "WIRELESS",
          "wlans": [
            "DEMO-NF-ASSURANCE"
          ],
          "frequency": {
            "value": 30,
            "unit": "MINUTES"
          },
          "scheduleInDays": 0,
          "bands": "BOTH",
          "numberOfApsToTest": 5,
          "repeatCountOnFailure": 2,
          "config": [
            {
              "server": "10.16.81.86",
              "userName": "andy",
              "password": "r5M4NjkS6xhEgy+PwGQBIA==",
              "port": 1812,
              "protocol": "PAP",
              "sharedSecret": "JRYerl1SqpQ6QHR/fc9j8A=="
            }
          ],
          "ssids": null,
          "testSuiteId": null,
          "type": null,
          "startTime": 0
        },
        {
          "name": "FileTransfer",
          "connection": "WIRELESS",
          "wlans": [
            "DEMO-NF-ASSURANCE"
          ],
          "frequency": {
            "value": 30,
            "unit": "MINUTES"
          },
          "scheduleInDays": 0,
          "bands": "BOTH",
          "numberOfApsToTest": 5,
          "repeatCountOnFailure": 2,
          "config": [
            {
              "server": "10.16.1.70",
              "userName": "ftpuser",
              "password": "JRYerl1SqpQ6QHR/fc9j8A==",
              "protocol": "ftp",
              "pathToDownload": "myfile.pdf",
              "transferType": "DOWNLOAD"
            }
          ],
          "ssids": null,
          "testSuiteId": null,
          "type": null,
          "startTime": 0
        },
        {
          "name": "WebServer",
          "connection": "WIRELESS",
          "wlans": [
            "DEMO-NF-ASSURANCE"
          ],
          "frequency": {
            "value": 30,
            "unit": "MINUTES"
          },
          "scheduleInDays": 0,
          "bands": "BOTH",
          "numberOfApsToTest": 5,
          "repeatCountOnFailure": 2,
          "config": [
            {
              "url": "http://10.16.81.170",
              "port": 80
            }
          ],
          "ssids": null,
          "testSuiteId": null,
          "type": null,
          "startTime": 0
        },
        {
          "name": "MailServer",
          "connection": "WIRELESS",
          "wlans": [
            "DEMO-NF-ASSURANCE"
          ],
          "frequency": {
            "value": 30,
            "unit": "MINUTES"
          },
          "scheduleInDays": 0,
          "bands": "BOTH",
          "numberOfApsToTest": 5,
          "repeatCountOnFailure": 2,
          "config": [
            {
              "server": "demo-ad-81-10.cisco.local",
              "protocol": "pop3"
            },
            {
              "server": "demo-ad-81-10.cisco.local",
              "protocol": "imap"
            }
          ],
          "ssids": null,
          "testSuiteId": null,
          "type": null,
          "startTime": 0
        }
      ],
      "sensors": [
        {
          "name": "AP1800S_Sensor_02",
          "macAddress": "70:f3:5a:78:6b:01",
          "assigned": false,
          "status": "UP",
          "ssid":"DEMO-NF-ASSURANCE",
          "band":"BOTH",
          "xorSensor": false
        }
      ],
      "testDurationEstimate": 657,
      "testTemplate": true,
      "legacyTestSuite": false,
      "tenantId": "5acb35000cec81008eac3384"
    },
    {
      "_id": "5adf649d64825e0081e87d46",
      "name": "Sensor_Test_03",
      "version": 2,
      "startTime": 1525246399614,
      "lastModifiedTime": 1525246399614,
      "numAssociatedSensor": 1,
      "location": "d9568fb0-faf3-4f43-9c7f-cb54f3784588",
      "siteHierarchy": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "status": "NOTRUNNING",
      "connection": "WIRELESS",
      "encryptionMode": "CBC",
      "runNow": "NO",
      "schedule": {
        "testScheduleMode": "DEDICATED",
        "scheduleRange": [],
        "startTime": 0,
        "frequency": {
        "value": 30,
        "unit": "MINUTES"
      },
      },
      "locationInfoList": [
        {
          "locationId": "d9568fb0-faf3-4f43-9c7f-cb54f3784588",
          "locationType": "floor",
          "allSensors": false,
          "siteHierarchy": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
          "macAddressList": []
        }
      ],
      "frequency": {
        "value": 1,
        "unit": "HOURS"
      },
      "rssiThreshold": -75,
      "numNeighborAPThreshold": 5,
      "scheduleInDays": 0,
      "wlans": [

      ],
      "apCoverage": [
      {
        "bands": "2.4GHz",
        "numberOfApsToTest": 1,
        "rssiThreshold": -70
      },
      {
        "bands": "5GHz",
        "numberOfApsToTest": 1,
        "rssiThreshold": -70
      }
      ],
      "ssids": [
        {
          "bands": "BOTH",
          "ssid": "DEMO-FEW-ASSURANCE",
          "profileName": "DEMO-FEW-ASSURANCE",
          "authType": "WPA2_EAP",
          "authTypeRcvd": null,
          "psk": null,
          "username": "adam",
          "password": "JRYerl1SqpQ6QHR/fc9j8A==",
          "eapMethod": "PEAP-MSCHAPv2",
          "authProtocol": null,
          "certfilename": null,
          "certxferprotocol": "HTTP",
          "certstatus": "ACTIVE",
          "certpassphrase": null,
          "certdownloadurl": null,
          "qosPolicy": null,
          "numAps": 0,
          "numSensors": 0,
          "layer3webAuthsecurity": null,
          "layer3webAuthuserName": null,
          "layer3webAuthpassword": null,
          "layer3webAuthEmailAddress": null,
          "id": 0,
          "wlanId": 0,
          "wlc": "10.16.31.68",
          "validFrom": 0,
          "validTo": 0,
          "status": "ENABLED",
          "tests": [
          {
            "name": "Onboarding",
            "config": [
              {}
            ]
          },
          {
            "name": "DNS",
            "config": [
              {
                "domains": [
                  "www.cisco.com"
                ]
              }
            ]
          },
          {
            "name": "WebServer",
            "config": [
              {
                "url": "http://www.cisco.com",
                "port": 80
              }
            ]
          },
          {
            "name": "IpslaSender",
            "config": [
              {
                "probeType": "udp_jitter_v1",
                "numPackets": 2
              }
            ]
          }
        ]
        }
      ],
      "testScheduleMode": "DEDICATED",
      "showWlcUpgradeBanner": false,
      "radioAsSensorRemoved": true,
      "tests": [
        {
          "name": "DNS",
          "connection": "WIRELESS",
          "wlans": [
            "DEMO-FEW-ASSURANCE"
          ],
          "frequency": {
            "value": 1,
            "unit": "HOURS"
          },
          "scheduleInDays": 0,
          "bands": "BOTH",
          "numberOfApsToTest": 5,
          "repeatCountOnFailure": 2,
          "config": [
            {
              "domains": [
                "www.cisco.com"
              ]
            }
          ],
          "ssids": null,
          "testSuiteId": null,
          "type": null,
          "startTime": 0
        },
        {
          "name": "WebServer",
          "connection": "WIRELESS",
          "wlans": [
            "DEMO-FEW-ASSURANCE"
          ],
          "frequency": {
            "value": 1,
            "unit": "HOURS"
          },
          "scheduleInDays": 0,
          "bands": "BOTH",
          "numberOfApsToTest": 5,
          "repeatCountOnFailure": 2,
          "config": [
            {
              "url": "http://10.16.81.170/fs36",
              "port": 80
            }
          ],
          "ssids": null,
          "testSuiteId": null,
          "type": null,
          "startTime": 0
        }
      ],
      "sensors": [

        {
          "name": "AP1800S_Sensor_03",
          "macAddress": "70:f3:5a:78:6b:02",
          "assigned": false,
          "status": "UP",
          "ssid":"DEMO-FEW-ASSURANCE",
          "band":"BOTH",
          "xorSensor": false
        }
      ],
      "testDurationEstimate": 657,
      "testTemplate": true,
      "legacyTestSuite": false,
      "tenantId": "5acb35000cec81008eac3384"
    },
    {
      "_id": "5b60b6f236fe7400cf82c7b7",
      "name": "Sensor_Test_04",
      "version": 4,
      "startTime": 1534187624117,
      "lastModifiedTime": 1534187624117,
      "numAssociatedSensor": 1,
      "location": "33bbd24d-7df6-4dd6-bd4e-ce8397345889",
      "siteHierarchy": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "status": "NOTRUNNING",
      "connection": "WIRELESS",
      "encryptionMode": "CBC",
      "runNow": "NO",
      "schedule": {
          "testScheduleMode": "DEDICATED",
          "scheduleRange": [],
          "startTime": 0,
          "frequency": {
          "value": 30,
          "unit": "MINUTES"
        },
        },
      "locationInfoList": [
          {
            "locationId": "33bbd24d-7df6-4dd6-bd4e-ce8397345889",
            "locationType": "floor",
            "allSensors": false,
            "siteHierarchy": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
            "macAddressList": []
          }
        ],
      "frequency": {
        "value": 30,
        "unit": "MINUTES"
      },
      "rssiThreshold": -67,
      "numNeighborAPThreshold": 3,
      "scheduleInDays": 0,
      "wlans": [

      ],
      "apCoverage": [
        {
          "bands": "2.4GHz",
          "numberOfApsToTest": 1,
          "rssiThreshold": -70
        },
        {
          "bands": "5GHz",
          "numberOfApsToTest": 1,
          "rssiThreshold": -70
        }
      ],
      "ssids": [
        {
          "bands": "BOTH",
          "ssid": "@CorpSSID",
          "profileName": "@CorpSSID",
          "authType": "WPA2_EAP",
          "authTypeRcvd": null,
          "psk": null,
          "username": "Sensor1",
          "password": "kQJhIaakjeoOC/R1l9haxw==",
          "eapMethod": "PEAP-MSCHAPv2",
          "authProtocol": null,
          "certfilename": null,
          "certxferprotocol": "HTTP",
          "certstatus": "ACTIVE",
          "certpassphrase": null,
          "certdownloadurl": null,
          "qosPolicy": null,
          "numAps": 0,
          "numSensors": 0,
          "layer3webAuthsecurity": null,
          "layer3webAuthuserName": null,
          "layer3webAuthpassword": null,
          "layer3webAuthEmailAddress": null,
          "qosPolicy": null,
          "id": 0,
          "wlanId": 0,
          "wlc": "10.13.4.7",
          "validFrom": 0,
          "validTo": 0,
          "status": "ENABLED",
          "tests": [
            {
              "name": "Onboarding",
              "config": [
                {}
              ]
            },
            {
              "name": "DNS",
              "config": [
                {
                  "domains": [
                    "www.cisco.com"
                  ]
                }
              ]
            },
            {
              "name": "WebServer",
              "config": [
                {
                  "url": "http://www.cisco.com",
                  "port": 80
                }
              ]
            },
            {
              "name": "IpslaSender",
              "config": [
                {
                  "probeType": "udp_jitter_v1",
                  "numPackets": 2
                }
              ]
            }
          ]
        }
      ],
      "testScheduleMode": "DEDICATED",
      "showWlcUpgradeBanner": false,
      "radioAsSensorRemoved": true,
      "tests": [
        {
          "name": "DNS",
          "connection": "WIRELESS",
          "wlans": [
            "@CorpSSID"
          ],
          "frequency": {
            "value": 30,
            "unit": "MINUTES"
          },
          "scheduleInDays": 0,
          "bands": "BOTH",
          "numberOfApsToTest": 5,
          "repeatCountOnFailure": 2,
          "config": [
            {
              "domains": [
                "www.cisco.com"
              ]
            }
          ],
          "ssids": null,
          "testSuiteId": null,
          "type": null,
          "startTime": 0
        },
        {
          "name": "Ping",
          "connection": "WIRELESS",
          "wlans": [
            "@CorpSSID"
          ],
          "frequency": {
            "value": 30,
            "unit": "MINUTES"
          },
          "scheduleInDays": 0,
          "bands": "BOTH",
          "numberOfApsToTest": 5,
          "repeatCountOnFailure": 2,
          "config": [
            {
              "server": "1E_10.13.1.1"
            },
            {
              "server": "1E_8.8.8.8"
            }
          ],
          "ssids": null,
          "testSuiteId": null,
          "type": null,
          "startTime": 0
        },
        {
          "name": "WebServer",
          "connection": "WIRELESS",
          "wlans": [
            "@CorpSSID"
          ],
          "frequency": {
            "value": 30,
            "unit": "MINUTES"
          },
          "scheduleInDays": 0,
          "bands": "BOTH",
          "numberOfApsToTest": 5,
          "repeatCountOnFailure": 2,
          "config": [
            {
              "url": "http://www.cisco.com",
              "port": 80
            }
          ],
          "ssids": null,
          "testSuiteId": null,
          "type": null,
          "startTime": 0
        }
      ],
      "sensors": [
        {
          "name": "AP1800S_Sensor_04",
          "macAddress": "70:f3:5a:78:6b:03",
          "assigned": false,
          "status": "UP",
          "xorSensor": false
        }
      ],
      "testDurationEstimate": 657,
      "testTemplate": true,
      "legacyTestSuite": false,
      "tenantId": "5b161c0f04a351008902742f"
    },
    {
      "_id": "5b994c2c3ecd34001e3aa2c3",
      "name": "Sensor_Test_01",
      "version": 3,
      "startTime": 1537190619607,
      "lastModifiedTime": 1537190619607,
      "numAssociatedSensor": 1,
      "location": "ce9324d6-74d0-4370-b0f7-ee9488b502d2",
      "siteHierarchy": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
      "status": "NOTRUNNING",
      "connection": "WIRELESS",
      "encryptionMode": "CBC",
      "runNow": "NO",
      "schedule": {
        "testScheduleMode": "DEDICATED",
        "scheduleRange": [],
        "startTime": 0,
        "frequency": {
          "value": 30,
          "unit": "MINUTES"
        },
      },
      "locationInfoList": [
        {
          "locationId": "ce9324d6-74d0-4370-b0f7-ee9488b502d2",
          "locationType": "floor",
          "allSensors": false,
          "siteHierarchy": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
          "macAddressList": []
        }
      ],
      "frequency": {
        "value": 30,
        "unit": "MINUTES"
      },
      "rssiThreshold": -75,
      "numNeighborAPThreshold": 5,
      "scheduleInDays": 0,
      "wlans": [

      ],
      "apCoverage": [
        {
          "bands": "2.4GHz",
          "numberOfApsToTest": 1,
          "rssiThreshold": -70
        },
        {
          "bands": "5GHz",
          "numberOfApsToTest": 1,
          "rssiThreshold": -70
        }
      ],
      "ssids": [
        {
          "bands": "5GHz",
          "ssid": "c9800AP11AX",
          "profileName": "c9800AP11AX",
          "authType": "WPA2_EAP",
          "authTypeRcvd": null,
          "psk": null,
          "username": "sensortest",
          "password": "SkkWI7W9x/CsVKDOnOhf4Q==",
          "eapMethod": "PEAP-MSCHAPv2",
          "authProtocol": null,
          "certfilename": null,
          "certxferprotocol": "HTTP",
          "certstatus": "ACTIVE",
          "certpassphrase": null,
          "certdownloadurl": null,
          "qosPolicy": "PLATINUM",
          "numAps": 0,
          "numSensors": 0,
          "layer3webAuthsecurity": null,
          "layer3webAuthuserName": null,
          "layer3webAuthpassword": null,
          "layer3webAuthEmailAddress": null,
          "id": 0,
          "wlanId": 0,
          "wlc": "10.30.200.1",
          "validFrom": 0,
          "validTo": 0,
          "status": "ENABLED",
          "tests": [
            {
              "name": "Onboarding",
              "config": [
                {}
              ]
            },
            {
              "name": "DNS",
              "config": [
                {
                  "domains": [
                    "www.cisco.com"
                  ]
                }
              ]
            },
            {
              "name": "WebServer",
              "config": [
                {
                  "url": "http://www.cisco.com",
                  "port": 80
                }
              ]
            },
            {
              "name": "IpslaSender",
              "config": [
                {
                  "probeType": "udp_jitter_v1",
                  "numPackets": 2
                }
              ]
            }
          ]
        }
      ],
      "testScheduleMode": "DEDICATED",
      "showWlcUpgradeBanner": false,
      "radioAsSensorRemoved": true,
      "tests": [
        {
          "name": "DNS",
          "connection": "WIRELESS",
          "wlans": [
            "c9800AP11AX"
          ],
          "frequency": {
            "value": 30,
            "unit": "MINUTES"
          },
          "scheduleInDays": 0,
          "bands": "BOTH",
          "numberOfApsToTest": 5,
          "repeatCountOnFailure": 2,
          "config": [
            {
              "domains": [
                "cisco.com"
              ]
            }
          ],
          "ssids": null,
          "testSuiteId": null,
          "type": null,
          "startTime": 0
        },
        {
          "name": "Ping",
          "connection": "WIRELESS",
          "wlans": [
            "c9800AP11AX"
          ],
          "frequency": {
            "value": 30,
            "unit": "MINUTES"
          },
          "scheduleInDays": 0,
          "bands": "BOTH",
          "numberOfApsToTest": 5,
          "repeatCountOnFailure": 2,
          "config": [
            {
              "server": "1E_192.168.139.160"
            }
          ],
          "ssids": null,
          "testSuiteId": null,
          "type": null,
          "startTime": 0
        },
        {
          "name": "WebServer",
          "connection": "WIRELESS",
          "wlans": [
            "c9800AP11AX"
          ],
          "frequency": {
            "value": 30,
            "unit": "MINUTES"
          },
          "scheduleInDays": 0,
          "bands": "BOTH",
          "numberOfApsToTest": 5,
          "repeatCountOnFailure": 2,
          "config": [
            {
              "url": "http://192.168.139.160/index.html",
              "port": 80
            },
            {
              "url": "http://www.google.com",
              "port": 80
            }
          ],
          "ssids": null,
          "testSuiteId": null,
          "type": null,
          "startTime": 0
        },{
          "name": "Speed",
          "connection": "WIRELESS",
          "wlans": [
            "c9800AP11AX"
          ],
          "frequency": {
            "value": 30,
            "unit": "MINUTES"
          },
          "scheduleInDays": 0,
          "bands": "BOTH",
          "numberOfApsToTest": 5,
          "repeatCountOnFailure": 2,
          "config": [
            {
              "ndtServer": "",
              "ndtServerPort": "3001",
              "ndtServerPath": "/ndt_protocol",
              "uplinkTest": true,
              "downlinkTest": true,
              "proxyServer": null,
              "proxyPort": "80"
            }
          ],
          "ssids": null,
          "testSuiteId": null,
          "type": null,
          "startTime": 1538678704212
        },
        {
          "name": "IpslaSender",
          "connection": "WIRELESS",
          "wlans": [
            "c9800AP11AX"
          ],
          "frequency": {
            "value": 30,
            "unit": "MINUTES"
          },
          "scheduleInDays": 0,
          "bands": "BOTH",
          "numberOfApsToTest": 5,
          "repeatCountOnFailure": 2,
          "config": [
            {
              "probeType": "udp_jitter_v1",
              "numPackets": 2
            }
          ],
          "ssids": null,
          "testSuiteId": null,
          "type": null,
          "startTime": 1538678704212
        }
      ],
      "sensors": [
        {
          "name": "AP1800S_Sensor_01",
          "macAddress": "70:f3:5a:78:6b:00",
          "assigned": false,
          "status": "UP",
          "xorSensor": false
        }
      ],
      "testDurationEstimate": 657,
      "testTemplate": true,
      "legacyTestSuite": false,
      "tenantId": "5b9611a1ab53ed008e4bb74c"
    }
  ]
})
