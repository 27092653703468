define({
    "Template":
{
    "request": {
      "key": "google-services",
      "type": "Application",
      "label": "google-services",
      "collection": false,
      "componentContent": [
        {
          "component": "fusion",
          "additional": "ce974774-f793-4eaa-ad9e-1c731e76e695"
        }
      ]
    },
    "data": {
      "response": {
        "id": "ce974774-f793-4eaa-ad9e-1c731e76e695",
        "instanceId": 4501,
        "authEntityId": 4501,
        "displayName": "4501",
        "authEntityClass": -1909073334,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "createTime": 1528178809796,
        "deployed": false,
        "isSeeded": true,
        "isStale": false,
        "lastUpdateTime": 1528178809796,
        "name": "google-services",
        "namespace": "scalablegroup:application",
        "provisioningState": "DEFINED",
        "qualifier": "application",
        "resourceVersion": 0,
        "targetIdList": [],
        "type": "scalablegroup",
        "cfsChangeInfo": [],
        "customProvisions": [],
        "scalableGroupExternalHandle": "google-services",
        "scalableGroupType": "APPLICATION",
        "securityGroupTag": 0,
        "state": "ACTIVE",
        "vnAgnostic": true,
        "identitySource": {
          "id": "2689858e-a1e6-4c66-85d1-8bd282f8cf29",
          "instanceId": 10010,
          "authEntityId": 10010,
          "displayName": "10010",
          "authEntityClass": 1738953278,
          "instanceTenantId": "SYS0",
          "deployPending": "NONE",
          "instanceVersion": 0,
          "state": "INACTIVE",
          "type": "NBAR"
        },
        "indicativeNetworkIdentity": [],
        "networkApplications": [
          {
            "id": "0eace332-8baf-4910-b022-afd76c9423b0",
            "instanceId": 11451,
            "authEntityId": 11451,
            "displayName": "11451",
            "authEntityClass": -217092956,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "appProtocol": "tcp/udp",
            "applicationGroup": "google-group",
            "applicationType": "DEFAULT",
            "categoryId": "d5074a75-0664-4149-81c7-97d3364f0905",
            "createTime": 1528178809796,
            "encrypted": "true",
            "engineId": "13",
            "helpString": "google-services, set of tools and apis used by google applications",
            "ignoreConflict": false,
            "lastUpdateTime": 1528178809796,
            "longDescription": "google-services is a set of tools and APIs used by Google applications.",
            "name": "google-services",
            "nbarId": "1456",
            "p2pTechnology": "true",
            "popularity": 10,
            "rank": 65535,
            "references": "https://www.google.com/",
            "selectorId": "520",
            "subCategory": "c6dc8c97-9fb3-4aea-b6e6-a229367402ac",
            "trafficClass": "TRANSACTIONAL_DATA",
            "tunnel": "false"
          }
        ],
        "networkIdentity": [
          {
            "id": "91ef9698-0b1e-42d3-98f7-a247cab100f7",
            "instanceId": 13243,
            "authEntityId": 13243,
            "displayName": "13243",
            "authEntityClass": 124090504,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "ipv4Subnet": [],
            "ipv6Subnet": [],
            "lowerPort": 0,
            "ports": "53,80,443,5353",
            "protocol": "TCP",
            "upperPort": 0
          },
          {
            "id": "bbe29b1a-b495-4212-9bd2-3a7f2aab2d81",
            "instanceId": 13244,
            "authEntityId": 13244,
            "displayName": "13244",
            "authEntityClass": 124090504,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "ipv4Subnet": [],
            "ipv6Subnet": [],
            "lowerPort": 0,
            "ports": "53,5353",
            "protocol": "UDP",
            "upperPort": 0
          }
        ],
        "parentScalableGroup": {
          "idRef": "14637712-7235-4fac-a051-27f6a06925be"
        },
        "user": [],
        "userGroup": []
      }
    },
    "result": [
      {
        "bookID": {
          "namespace": "fusion",
          "name": "core",
          "version": "1.0.1"
        },
        "response": {
          "id": "ce974774-f793-4eaa-ad9e-1c731e76e695",
          "instanceId": 4501,
          "authEntityId": 4501,
          "displayName": "4501",
          "authEntityClass": -1909073334,
          "instanceTenantId": "SYS0",
          "deployPending": "NONE",
          "instanceVersion": 0,
          "createTime": 1528178809796,
          "deployed": false,
          "isSeeded": true,
          "isStale": false,
          "lastUpdateTime": 1528178809796,
          "name": "google-services",
          "namespace": "scalablegroup:application",
          "provisioningState": "DEFINED",
          "qualifier": "application",
          "resourceVersion": 0,
          "targetIdList": [],
          "type": "scalablegroup",
          "cfsChangeInfo": [],
          "customProvisions": [],
          "scalableGroupExternalHandle": "google-services",
          "scalableGroupType": "APPLICATION",
          "securityGroupTag": 0,
          "state": "ACTIVE",
          "vnAgnostic": true,
          "identitySource": {
            "id": "2689858e-a1e6-4c66-85d1-8bd282f8cf29",
            "instanceId": 10010,
            "authEntityId": 10010,
            "displayName": "10010",
            "authEntityClass": 1738953278,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "state": "INACTIVE",
            "type": "NBAR"
          },
          "indicativeNetworkIdentity": [],
          "networkApplications": [
            {
              "id": "0eace332-8baf-4910-b022-afd76c9423b0",
              "instanceId": 11451,
              "authEntityId": 11451,
              "displayName": "11451",
              "authEntityClass": -217092956,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "appProtocol": "tcp/udp",
              "applicationGroup": "google-group",
              "applicationType": "DEFAULT",
              "categoryId": "d5074a75-0664-4149-81c7-97d3364f0905",
              "createTime": 1528178809796,
              "encrypted": "true",
              "engineId": "13",
              "helpString": "google-services, set of tools and apis used by google applications",
              "ignoreConflict": false,
              "lastUpdateTime": 1528178809796,
              "longDescription": "google-services is a set of tools and APIs used by Google applications.",
              "name": "google-services",
              "nbarId": "1456",
              "p2pTechnology": "true",
              "popularity": 10,
              "rank": 65535,
              "references": "https://www.google.com/",
              "selectorId": "520",
              "subCategory": "c6dc8c97-9fb3-4aea-b6e6-a229367402ac",
              "trafficClass": "TRANSACTIONAL_DATA",
              "tunnel": "false"
            }
          ],
          "networkIdentity": [
            {
              "id": "91ef9698-0b1e-42d3-98f7-a247cab100f7",
              "instanceId": 13243,
              "authEntityId": 13243,
              "displayName": "13243",
              "authEntityClass": 124090504,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "ipv4Subnet": [],
              "ipv6Subnet": [],
              "lowerPort": 0,
              "ports": "53,80,443,5353",
              "protocol": "TCP",
              "upperPort": 0
            },
            {
              "id": "bbe29b1a-b495-4212-9bd2-3a7f2aab2d81",
              "instanceId": 13244,
              "authEntityId": 13244,
              "displayName": "13244",
              "authEntityClass": 124090504,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "ipv4Subnet": [],
              "ipv6Subnet": [],
              "lowerPort": 0,
              "ports": "53,5353",
              "protocol": "UDP",
              "upperPort": 0
            }
          ],
          "parentScalableGroup": {
            "idRef": "14637712-7235-4fac-a051-27f6a06925be"
          },
          "user": [],
          "userGroup": []
        }
      }
    ]
  }
});