define({
  "epLabels_Template": {
    "data": {
      "deviceType": [
        "Workstation",
        "Coffee Machine",
        "PLC",
        "CT-Scanner",
        "Storage",
        "Unknown"
      ],
      "hardwareModel": [
        "Microsoft-Workstation",
        "Bosch-Coffee",
        "Xerox-Device",
        "Magnetom Vida",
        "PACS Scan",
        "Unknown"
      ],
      "hardwareManufacturer": [
        "VMware, Inc.",
        "Joff Ind",
        "Microsoft",
        "Xerox Corporation",
        "Siemens Healthcare",
        "Unknown"
      ],
      "operatingSystem": [
        "Windows 7",
        "Windows",
        "Linux",
        "Cisco IP Phone",
        "Unknown"
      ],
      "trustScore": [
        "Low (1-3)",
        "Medium (4-6)",
        "High (7-10)",
        "Unknown (-)"
      ]
    }
  },
  "DictionaryAttributes_Template":{
    "Dictionaries": [
      {
        "dictName": "ACIDEX",
        "attributes": [
          {
            "internalName": "devicePlatform",
            "multipleAllowed": false,
            "associatedClassificationCategories": [
              "hardwareModel"
            ],
            "discoveryMethod": "Radius v/ACIDEX",
            "defaultValue": "",
            "dataType": "String",
            "name": "devicePlatform",
            "description": "Captures the device platform e.g: intel based plaform",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "devicePlatformVersion",
            "multipleAllowed": false,
            "associatedClassificationCategories": [
              "operatingSystem"
            ],
            "discoveryMethod": "Radius v/ACIDEX",
            "defaultValue": "",
            "dataType": "String",
            "name": "devicePlatformVersion",
            "description": "Captures OS information of the platform",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "acidexDeviceType",
            "multipleAllowed": false,
            "associatedClassificationCategories": [
              "deviceType",
              "hardwareModel",
              "hardwareManufacturer"
            ],
            "discoveryMethod": "Radius v/ACIDEX",
            "defaultValue": "",
            "dataType": "String",
            "name": "acidexDeviceType",
            "description": "Captures information of the device type: eg: MACBookPro 11,1",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "acUserAgent",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "Radius v/ACIDEX",
            "defaultValue": "",
            "dataType": "String",
            "name": "acUserAgent",
            "description": "Anyconnect client version",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "deviceMac",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "Radius v/ACIDEX",
            "defaultValue": "",
            "dataType": "String",
            "name": "deviceMac",
            "description": "deviceMac",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "devicePublicMac",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "Radius v/ACIDEX",
            "defaultValue": "",
            "dataType": "String",
            "name": "devicePublicMac",
            "description": "MAC address used for VPN connection",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "phoneId",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "Radius v/ACIDEX",
            "defaultValue": "",
            "dataType": "String",
            "name": "phoneId",
            "description": "Value of UDID. This is received via ACIDEX with mdm-tlv=device-uid",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "phoneIdType",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "Radius v/ACIDEX",
            "defaultValue": "",
            "dataType": "String",
            "name": "phoneIdType",
            "description": "Displays ID type e.g: UDID",
            "source": "Identity Service Engine",
            "isActive": false
          }
        ]
      },
      {
        "dictName": "ActiveDirectory_Probe",
        "attributes": [
          {
            "internalName": "adHostDnsDomain",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "AD Probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "adHostDnsDomain",
            "description": "adHostDnsDomain ",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "adHostExists",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "AD Probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "adHostExists",
            "description": "adHostExists",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "adHostJoinPoint",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "AD Probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "adHostJoinPoint",
            "description": "adHostJoinPoint",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "adJoinPoint",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "AD Probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "adJoinPoint",
            "description": "adJoinPoint",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "adOperatingSystem",
            "multipleAllowed": false,
            "associatedClassificationCategories": [
              "operatingSystem"
            ],
            "discoveryMethod": "AD Probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "adOperatingSystem",
            "description": "Endpoint OS Name",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "adOsVersion",
            "multipleAllowed": false,
            "associatedClassificationCategories": [
              "operatingSystem"
            ],
            "discoveryMethod": "AD Probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "adOsVersion",
            "description": "Endpoint OS Version",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "adServicePack",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "AD Probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "adServicePack",
            "description": "adServicePack",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "adUserDnsDomain",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "AD Probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "adUserDnsDomain",
            "description": "adUserDnsDomain",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "adUserJoinPoint",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "AD Probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "adUserJoinPoint",
            "description": "adUserJoinPoint",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "adFetchHostName",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "AD Probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "adFetchHostName",
            "description": "Host Name",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "adLastFetchTime",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "AD Probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "adLastFetchTime",
            "description": "Epoch time of the last scan",
            "source": "Identity Service Engine",
            "isActive": false
          }
        ]
      },
      {
        "dictName": "CDP",
        "attributes": [
          {
            "internalName": "cdpCacheAddress",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS Acct/SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "cdpCacheAddress",
            "description": "Endpoint IP Address gathered from SNMP query or RADIUS accounting.",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "cdpCacheCapabilities",
            "multipleAllowed": false,
            "associatedClassificationCategories": [
              "deviceType"
            ],
            "discoveryMethod": "RADIUS Acct/SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "cdpCacheCapabilities",
            "description": "Identifies device capabilities",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "cdpCacheDeviceId",
            "multipleAllowed": false,
            "associatedClassificationCategories": [
              "deviceType"
            ],
            "discoveryMethod": "RADIUS Acct/SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "cdpCacheDeviceId",
            "description": "Identifies Device Type using FQDN",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "cdpCachePlatform",
            "multipleAllowed": false,
            "associatedClassificationCategories": [
              "hardwareModel",
              "hardwareManufacturer"
            ],
            "discoveryMethod": "RADIUS Acct/SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "cdpCachePlatform",
            "description": "Identifies hardware model and manufacturer.",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "cdpCacheVersion",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS Acct/SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "cdpCacheVersion",
            "description": "cdpCacheVersion",
            "source": "Identity Service Engine",
            "isActive": false
          }
        ]
      },
      {
        "dictName": "CMDB",
        "attributes": [
          {
            "internalName": "cmdbSerialNumber",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "cmdbSerialNumber",
            "description": "Serial Number in CMDB System",
            "source": "DNAC Platform",
            "isActive": false
          },
          {
            "internalName": "cmdbModelCategory",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "cmdbModelCategory",
            "description": "Serial Number in CMDB System",
            "source": "DNAC Platform",
            "isActive": false
          },
          {
            "internalName": "cmdbModel",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "cmdbModel",
            "description": "Serial Model in CMDB System",
            "source": "DNAC Platform",
            "isActive": false
          },
          {
            "internalName": "cmdbAssetTag",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "cmdbAssetTag",
            "description": "Asset Tag",
            "source": "DNAC Platform",
            "isActive": false
          },
          {
            "internalName": "cmdbDisplayName",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "cmdbDisplayName",
            "description": "Display Name",
            "source": "DNAC Platform",
            "isActive": false
          },
          {
            "internalName": "cmdbManagedBy",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "cmdbManagedBy",
            "description": "Managed By",
            "source": "DNAC Platform",
            "isActive": false
          },
          {
            "internalName": "cmdbLocation",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "cmdbLocation",
            "description": "Location",
            "source": "DNAC Platform",
            "isActive": false
          },
          {
            "internalName": "cmdbDepartment",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "cmdbDepartment",
            "description": "Department",
            "source": "DNAC Platform",
            "isActive": false
          }
        ]
      },
      {
        "dictName": "DHCP",
        "attributes": [
          {
            "internalName": "bootFile",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DHCP",
            "defaultValue": "",
            "dataType": "String",
            "name": "bootFile",
            "description": "bootFile",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "clientFqdn",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DHCP",
            "defaultValue": "",
            "dataType": "String",
            "name": "clientFqdn",
            "description": "clientFqdn",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "clientIdentifier",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DHCP",
            "defaultValue": "",
            "dataType": "String",
            "name": "clientIdentifier",
            "description": "clientIdentifier",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "deviceClass",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DHCP",
            "defaultValue": "",
            "dataType": "String",
            "name": "deviceClass",
            "description": "deviceClass",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "dhcpClassIdentifier",
            "multipleAllowed": false,
            "associatedClassificationCategories": [
              "deviceType",
              "operatingSystem"
            ],
            "discoveryMethod": "DHCP",
            "defaultValue": "",
            "dataType": "String",
            "name": "dhcpClassIdentifier",
            "description": "Platform or OS of endpoint.",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "dhcpClientIdentifier",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DHCP",
            "defaultValue": "",
            "dataType": "String",
            "name": "dhcpClientIdentifier",
            "description": "Endpoint MAC Address",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "dhcpMessageType",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DHCP",
            "defaultValue": "",
            "dataType": "String",
            "name": "dhcpMessageType",
            "description": "DHCP message type ( DHCP Request)",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "dhcpParameterRequestList",
            "multipleAllowed": false,
            "associatedClassificationCategories": [
              "deviceType",
              "operatingSystem"
            ],
            "discoveryMethod": "DHCP",
            "defaultValue": "",
            "dataType": "String",
            "name": "dhcpParameterRequestList",
            "description": "Unique indicator for device type using comma separated value.",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "dhcpRequestedAddress",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DHCP",
            "defaultValue": "",
            "dataType": "String",
            "name": "dhcpRequestedAddress",
            "description": "Endpoint IP Address",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "dhcpUserClassId",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DHCP",
            "defaultValue": "",
            "dataType": "String",
            "name": "dhcpUserClassId",
            "description": "Admins can use this to customize OS to provide unique ID's",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "dhcpv6ClientIdentifier",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DHCP",
            "defaultValue": "",
            "dataType": "String",
            "name": "dhcpv6ClientIdentifier",
            "description": "dhcpv6ClientIdentifier",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "dhcpv6IaNa",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DHCP",
            "defaultValue": "",
            "dataType": "String",
            "name": "dhcpv6IaNa",
            "description": "dhcpv6IaNa",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "dhcpv6IaTa",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DHCP",
            "defaultValue": "",
            "dataType": "String",
            "name": "dhcpv6IaTa",
            "description": "dhcpv6IaTa",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "dhcpv6InterfaceId",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DHCP",
            "defaultValue": "",
            "dataType": "String",
            "name": "dhcpv6InterfaceId",
            "description": "dhcpv6InterfaceId",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "dhcpv6ServerIdentifier",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DHCP",
            "defaultValue": "",
            "dataType": "String",
            "name": "dhcpv6ServerIdentifier",
            "description": "dhcpv6ServerIdentifier",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "dhcpv6UserClass",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DHCP",
            "defaultValue": "",
            "dataType": "String",
            "name": "dhcpv6UserClass",
            "description": "dhcpv6UserClass",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "dhcpv6VendorClass",
            "multipleAllowed": false,
            "associatedClassificationCategories": [
              "deviceType",
              "hardwareModel",
              "hardwareManufacturer"
            ],
            "discoveryMethod": "DHCP",
            "defaultValue": "",
            "dataType": "String",
            "name": "dhcpv6VendorClass",
            "description": "dhcpv6VendorClass",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "dhcpv6VendorOpts",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DHCP",
            "defaultValue": "",
            "dataType": "String",
            "name": "dhcpv6VendorOpts",
            "description": "dhcpv6VendorOpts",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "domainName",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DHCP",
            "defaultValue": "",
            "dataType": "String",
            "name": "domainName",
            "description": "domainName",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "dhcpHostName",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DHCP",
            "defaultValue": "",
            "dataType": "String",
            "name": "dhcpHostName",
            "description": "dhcpHostName",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "nameServers",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DHCP",
            "defaultValue": "",
            "dataType": "String",
            "name": "nameServers",
            "description": "nameServers",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "pxeClientArch",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DHCP",
            "defaultValue": "",
            "dataType": "String",
            "name": "pxeClientArch",
            "description": "pxeClientArch",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "pxeClientMachineId",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DHCP",
            "defaultValue": "",
            "dataType": "String",
            "name": "pxeClientMachineId",
            "description": "pxeClientMachineId",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "pxeClientNetworkId",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DHCP",
            "defaultValue": "",
            "dataType": "String",
            "name": "pxeClientNetworkId",
            "description": "pxeClientNetworkId",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "serverIdentifier",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DHCP",
            "defaultValue": "",
            "dataType": "String",
            "name": "serverIdentifier",
            "description": "serverIdentifier",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "vendorClass",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DHCP",
            "defaultValue": "",
            "dataType": "String",
            "name": "vendorClass",
            "description": "vendorClass",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "enterpriseId",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DHCP",
            "defaultValue": "",
            "dataType": "String",
            "name": "enterpriseId",
            "description": "Enterprise ID",
            "source": "SDAVC",
            "isActive": false
          }
        ]
      },
      {
        "dictName": "IOTAsset",
        "attributes": [
          {
            "internalName": "assetDeviceType",
            "multipleAllowed": false,
            "associatedClassificationCategories": [
              "deviceType"
            ],
            "discoveryMethod": "IOTAsset/PxGrid",
            "defaultValue": "",
            "dataType": "String",
            "name": "assetDeviceType",
            "description": "Device Type",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "assetHwRevision",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "IOTAsset/PxGrid",
            "defaultValue": "",
            "dataType": "String",
            "name": "assetHwRevision",
            "description": "H/W Revision number",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "assetId",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "IOTAsset/PxGrid",
            "defaultValue": "",
            "dataType": "String",
            "name": "assetId",
            "description": "Asset ID",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "assetIpAddress",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "IOTAsset/PxGrid",
            "defaultValue": "",
            "dataType": "String",
            "name": "assetIpAddress",
            "description": "IP address",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "assetMacAddress",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "IOTAsset/PxGrid",
            "defaultValue": "",
            "dataType": "String",
            "name": "assetMacAddress",
            "description": "assetMacAddress",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "assetName",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "IOTAsset/PxGrid",
            "defaultValue": "",
            "dataType": "String",
            "name": "assetName",
            "description": "Asset name",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "assetProductId",
            "multipleAllowed": false,
            "associatedClassificationCategories": [
              "hardwareModel"
            ],
            "discoveryMethod": "IOTAsset/PxGrid",
            "defaultValue": "",
            "dataType": "String",
            "name": "assetProductId",
            "description": "Product Code",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "assetProtocol",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "IOTAsset/PxGrid",
            "defaultValue": "",
            "dataType": "String",
            "name": "assetProtocol",
            "description": "Protocol",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "assetSerialNumber",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "IOTAsset/PxGrid",
            "defaultValue": "",
            "dataType": "String",
            "name": "assetSerialNumber",
            "description": "Serial Number",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "assetSwRevision",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "IOTAsset/PxGrid",
            "defaultValue": "",
            "dataType": "String",
            "name": "assetSwRevision",
            "description": "S/W Revision number",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "assetVendor",
            "multipleAllowed": false,
            "associatedClassificationCategories": [
              "hardwareManufacturer"
            ],
            "discoveryMethod": "IOTAsset/PxGrid",
            "defaultValue": "",
            "dataType": "String",
            "name": "assetVendor",
            "description": "Manufacturer",
            "source": "Identity Service Engine",
            "isActive": false
          }
        ]
      },
      {
        "dictName": "IP",
        "attributes": [
          {
            "internalName": "endpointSource",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "System generated",
            "defaultValue": "",
            "dataType": "String",
            "name": "endpointSource",
            "description": "System generated attributes: Discovery Method or probe last used to gather endpoint information.",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "fqdn",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "DNS, DHCP",
            "defaultValue": "",
            "dataType": "String",
            "name": "fqdn",
            "description": "Systen Generated attribute: Full qualified Domain Name of the endpoint",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "host",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "",
            "defaultValue": "",
            "dataType": "String",
            "name": "host",
            "description": "host",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "ip",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS/DHCP/SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "ip",
            "description": "Systen Generated attribute: ISE System calculated value based on RADIUS, DHCP  typically.",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "ipv6",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS/DHCP/SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "ipv6",
            "description": "Systen Generated attribute: ISE System calculated value based on RADIUS, DHCP  typically.",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "mask",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS/DHCP",
            "defaultValue": "",
            "dataType": "String",
            "name": "mask",
            "description": "Systen Generated attribute: ISE System calculated value based on RADIUS, DHCP  typically.",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "operatingSystemResult",
            "multipleAllowed": false,
            "associatedClassificationCategories": [
              "operatingSystem"
            ],
            "discoveryMethod": "AD/ Posture/MDM/ NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "operatingSystemResult",
            "description": "Systen Generated attribute: ISE profiler generated attribute based on information from AD, Posture, MDM, and NMAP scan.",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "portalUser",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "HTTP",
            "defaultValue": "",
            "dataType": "String",
            "name": "portalUser",
            "description": "User information gathered from URL-redirection to ISE guest services portal during endpoint registration",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "userAgent",
            "multipleAllowed": false,
            "associatedClassificationCategories": [
              "deviceType",
              "operatingSystem"
            ],
            "discoveryMethod": "HTTP, HTTP SPAN, RADIUS w/Device Sensor",
            "defaultValue": "",
            "dataType": "String",
            "name": "userAgent",
            "description": "HTTP header information gathered from different sources via RADIUS Accounting, via URL-Redirection to ISE portal",
            "source": "Identity Service Engine",
            "isActive": false
          }
        ]
      },
      {
        "dictName": "LLDP",
        "attributes": [
          {
            "internalName": "lldpCacheCapabilities",
            "multipleAllowed": false,
            "associatedClassificationCategories": [
              "deviceType"
            ],
            "discoveryMethod": "RADIUS Acct/SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "lldpCacheCapabilities",
            "description": "Identifies device capabilities",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "lldpCapabilitiesMapSupported",
            "multipleAllowed": false,
            "associatedClassificationCategories": [
              "hardwareModel"
            ],
            "discoveryMethod": "RADIUS Acct/SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "lldpCapabilitiesMapSupported",
            "description": "Identifies Hardware model if used",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "lldpChassisId",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "RADIUS Acct/SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "lldpChassisId",
            "description": "lldpChassisId",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "lldpManAddress",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "RADIUS Acct/SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "lldpManAddress",
            "description": "lldpManAddress",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "lldpPortDescription",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "RADIUS Acct/SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "lldpPortDescription",
            "description": "lldpPortDescription",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "lldpPortId",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "RADIUS Acct/SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "lldpPortId",
            "description": "lldpPortId",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "lldpSystemCapabilitiesMapEnabled",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "RADIUS Acct/SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "lldpSystemCapabilitiesMapEnabled",
            "description": "lldpSystemCapabilitiesMapEnabled",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "lldpSystemDescription",
            "multipleAllowed": false,
            "associatedClassificationCategories": [
              "hardwareModel",
              "hardwareManufacturer"
            ],
            "discoveryMethod": "RADIUS Acct/SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "lldpSystemDescription",
            "description": "Identifies Manufacturer, Hardware model if used",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "lldpSystemName",
            "multipleAllowed": false,
            "associatedClassificationCategories": [
              "hardwareModel"
            ],
            "discoveryMethod": "RADIUS Acct/SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "lldpSystemName",
            "description": "Identifies Hardware model if used. Displays only the model number",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "lldpTimeToLive",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "RADIUS Acct/SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "lldpTimeToLive",
            "description": "lldpTimeToLive",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "lldpMedSwRevision",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "LLDP",
            "defaultValue": "",
            "dataType": "String",
            "name": "lldpMedSwRevision",
            "description": "LLDP-MED Software Revision",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "lldpMedManufacture",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "LLDP",
            "defaultValue": "",
            "dataType": "String",
            "name": "lldpMedManufacture",
            "description": "LLDP-MED Manufacturer",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "lldpMedModel",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "LLDP",
            "defaultValue": "",
            "dataType": "String",
            "name": "lldpMedModel",
            "description": "LLDP-MED Model",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "lldpMedAssertId",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "LLDP",
            "defaultValue": "",
            "dataType": "String",
            "name": "lldpMedAssertId",
            "description": "LLDP-MED Assert ID",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "lldpMedNetworkPolicy",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "LLDP",
            "defaultValue": "",
            "dataType": "String",
            "name": "lldpMedNetworkPolicy",
            "description": "LLDP-MED Network Policy",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "lldpMedCapabilities",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "LLDP",
            "defaultValue": "",
            "dataType": "String",
            "name": "lldpMedCapabilities",
            "description": "LLDP-MED Capabilities",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "lldpMedHwRevision",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "LLDP",
            "defaultValue": "",
            "dataType": "String",
            "name": "lldpMedHwRevision",
            "description": "LLDP-MED Hardware Revision",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "lldpMedDeviceType",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "LLDP",
            "defaultValue": "",
            "dataType": "String",
            "name": "lldpMedDeviceType",
            "description": "LLDP-MED Device Type",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "lldpMedSnNumber",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "LLDP",
            "defaultValue": "",
            "dataType": "String",
            "name": "lldpMedSnNumber",
            "description": "LLDP-MED Serial Number",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "lldpMedFwRevision",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "LLDP",
            "defaultValue": "",
            "dataType": "String",
            "name": "lldpMedFwRevision",
            "description": "LLDP-MED Firmware Revision",
            "source": "SDAVC",
            "isActive": false
          }
        ]
      },
      {
        "dictName": "MAC",
        "attributes": [
          {
            "internalName": "macAddress",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "DHCP/RADIUS/SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "macAddress",
            "description": "MAC Address",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "oui",
            "multipleAllowed": false,
            "associatedClassificationCategories": [
              "hardwareManufacturer"
            ],
            "discoveryMethod": "DHCP/RADIUS/SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "oui",
            "description": "OUI {Organizationally Unique Identifier} is a 24-bit number derived from MAC address that uniquely identifies a vendor or manufacturer ",
            "source": "Identity Service Engine",
            "isActive": false
          }
        ]
      },
      {
        "dictName": "Multimedia",
        "attributes": [
          {
            "internalName": "h323DeviceName",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS Acct",
            "defaultValue": "",
            "dataType": "String",
            "name": "h323DeviceName",
            "description": "Attributes gathered from multimedia",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "h323DeviceVendor",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS Acct",
            "defaultValue": "",
            "dataType": "String",
            "name": "h323DeviceVendor",
            "description": "Attributes gathered from multimedia",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "h323DeviceVersion",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS Acct",
            "defaultValue": "",
            "dataType": "String",
            "name": "h323DeviceVersion",
            "description": "Attributes gathered from multimedia",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "mdnsVsmClassIdentifier",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS Acct",
            "defaultValue": "",
            "dataType": "String",
            "name": "mdnsVsmClassIdentifier",
            "description": "Attributes gathered from multimedia",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "mdnsVsmSrvIdentifier",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS Acct",
            "defaultValue": "",
            "dataType": "String",
            "name": "mdnsVsmSrvIdentifier",
            "description": "Attributes gathered from multimedia",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "mdnsVsmTxtIdentifier",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS Acct",
            "defaultValue": "",
            "dataType": "String",
            "name": "mdnsVsmTxtIdentifier",
            "description": "Attributes gathered from multimedia",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "sipDeviceName",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS Acct",
            "defaultValue": "",
            "dataType": "String",
            "name": "sipDeviceName",
            "description": "Attributes gathered from multimedia",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "sipDeviceVendor",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS Acct",
            "defaultValue": "",
            "dataType": "String",
            "name": "sipDeviceVendor",
            "description": "Attributes gathered from multimedia",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "sipDeviceVersion",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS Acct",
            "defaultValue": "",
            "dataType": "String",
            "name": "sipDeviceVersion",
            "description": "Attributes gathered from multimedia",
            "source": "Identity Service Engine",
            "isActive": false
          }
        ]
      },
      {
        "dictName": "NMAP",
        "attributes": [
          {
            "internalName": "tcp110",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "tcp110",
            "description": "pop3",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "udp123",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "udp123",
            "description": "ntp",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "tcp135",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "tcp135",
            "description": "msrpc",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "udp135",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "udp135",
            "description": "msrpc",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "udp137",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "udp137",
            "description": "netbios-ns",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "udp138",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "udp138",
            "description": "netbios-dgm",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "tcp139",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "tcp139",
            "description": "netbios-ssn",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "udp139",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "udp139",
            "description": "netbios-ssn",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "tcp143",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "tcp143",
            "description": "imap",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "udp1434",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "udp1434",
            "description": "ms-sql-m",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "udp161",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "udp161",
            "description": "SNMP deamon on the endpoint",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "udp162",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "udp162",
            "description": "SNMP trap on endpoint",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "udp1900",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "udp1900",
            "description": "upnp",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "tcp21",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "tcp21",
            "description": "ftp",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "tcp22",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "tcp22",
            "description": "ssh",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "tcp23",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "tcp23",
            "description": "telnet",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "tcp25",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "tcp25",
            "description": "smtp",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "tcp3306",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "tcp3306",
            "description": "mysql",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "tcp3389",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "tcp3389",
            "description": "ms-term-serv",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "tcp443",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "tcp443",
            "description": "https",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "tcp445",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "tcp445",
            "description": "microsoft-ds",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "udp445",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "udp445",
            "description": "microsoft-ds",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "udp500",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "udp500",
            "description": "isakmp",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "tcp515",
            "multipleAllowed": false,
            "associatedClassificationCategories": [
              "deviceType"
            ],
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "tcp515",
            "description": "printer: Can be used by well known LPR based printers",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "udp520",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "udp520",
            "description": "route",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "tcp53",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "tcp53",
            "description": "domain",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "udp53",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "udp53",
            "description": "domain",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "tcp631",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "tcp631",
            "description": "Internet printing protocol",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "udp631",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "udp631",
            "description": "ipp",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "udp67",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "udp67",
            "description": "dhcps  - Server",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "udp68",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "udp68",
            "description": "dhcspc - Client",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "tcp80",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "tcp80",
            "description": "http",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "tcp8080",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "tcp8080",
            "description": "http-proxy",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "tcp9100",
            "multipleAllowed": false,
            "associatedClassificationCategories": [
              "deviceType"
            ],
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "tcp9100",
            "description": "pdl-datastream/jetdirect: Commonly associated with HP Jet Direct",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "nmapOperatingSystem",
            "multipleAllowed": false,
            "associatedClassificationCategories": [
              "operatingSystem"
            ],
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "nmapOperatingSystem",
            "description": "Operating System",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "smbCpe",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "smbCpe",
            "description": "smbCpe",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "smbDomain",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "smbDomain",
            "description": "smbDomain",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "smbFqdn",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "smbFqdn",
            "description": "smbFqdn",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "smbLanmanager",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "smbLanmanager",
            "description": "smbLanmanager",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "smbOperatingSystem",
            "multipleAllowed": false,
            "associatedClassificationCategories": [
              "operatingSystem"
            ],
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "smbOperatingSystem",
            "description": "Operating System",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "smbServer",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "smbServer",
            "description": "smbServer",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "smbWorkgroup",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "NMAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "smbWorkgroup",
            "description": "smbWorkgroup",
            "source": "Identity Service Engine",
            "isActive": false
          }
        ]
      },
      {
        "dictName": "Profiler",
        "attributes": [
          {
            "internalName": "avgDhcpPacketsReceivedPerSecond",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "avgDhcpPacketsReceivedPerSecond",
            "description": "avgDhcpPacketsReceivedPerSecond",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "avgEndpointsCachedPerSecond",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "avgEndpointsCachedPerSecond",
            "description": "avgEndpointsCachedPerSecond",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "avgEndpointsDetectedPerSecond",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "avgEndpointsDetectedPerSecond",
            "description": "avgEndpointsDetectedPerSecond",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "avgEndpointsProfiledPerSecond",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "avgEndpointsProfiledPerSecond",
            "description": "avgEndpointsProfiledPerSecond",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "avgEndpointsSavedPerSecond",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "avgEndpointsSavedPerSecond",
            "description": "avgEndpointsSavedPerSecond",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "avgEndpointsUpdateAvertedPerSecond",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "avgEndpointsUpdateAvertedPerSecond",
            "description": "avgEndpointsUpdateAvertedPerSecond",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "avgEndpointsUpdatedPerSecond",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "avgEndpointsUpdatedPerSecond",
            "description": "avgEndpointsUpdatedPerSecond",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "avgHttpPacketsReceivedPerSecond",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "avgHttpPacketsReceivedPerSecond",
            "description": "avgHttpPacketsReceivedPerSecond",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "avgNetflowPacketsReceivedPerSecond",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "avgNetflowPacketsReceivedPerSecond",
            "description": "avgNetflowPacketsReceivedPerSecond",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "avgProbeDhcpEndpointsDetectedPerSecond",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "avgProbeDhcpEndpointsDetectedPerSecond",
            "description": "avgProbeDhcpEndpointsDetectedPerSecond",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "avgProbeDnsEndpointsDetectedPerSecond",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "avgProbeDnsEndpointsDetectedPerSecond",
            "description": "avgProbeDnsEndpointsDetectedPerSecond",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "avgProbeDummyEndpointsDetectedPerSecond",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "avgProbeDummyEndpointsDetectedPerSecond",
            "description": "avgProbeDummyEndpointsDetectedPerSecond",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "avgProbeHttpEndpointsDetectedPerSecond",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "avgProbeHttpEndpointsDetectedPerSecond",
            "description": "avgProbeHttpEndpointsDetectedPerSecond",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "avgProbeNetflowEndpointsDetectedPerSecond",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "avgProbeNetflowEndpointsDetectedPerSecond",
            "description": "avgProbeNetflowEndpointsDetectedPerSecond",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "avgProbeRadiusEndpointsDetectedPerSecond",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "avgProbeRadiusEndpointsDetectedPerSecond",
            "description": "avgProbeRadiusEndpointsDetectedPerSecond",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "avgProbeSnmpQueryEndpointsDetectedPerSecond",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "avgProbeSnmpQueryEndpointsDetectedPerSecond",
            "description": "avgProbeSnmpQueryEndpointsDetectedPerSecond",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "avgProbeSnmpTrapEndpointsDetectedPerSecond",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "avgProbeSnmpTrapEndpointsDetectedPerSecond",
            "description": "avgProbeSnmpTrapEndpointsDetectedPerSecond",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "avgProbeSpanEndpointsDetectedPerSecond",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "avgProbeSpanEndpointsDetectedPerSecond",
            "description": "avgProbeSpanEndpointsDetectedPerSecond",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "avgProfilerCacheHitsPerSecond",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "avgProfilerCacheHitsPerSecond",
            "description": "avgProfilerCacheHitsPerSecond",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "avgProfilerCacheMissesPerSecond",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "avgProfilerCacheMissesPerSecond",
            "description": "avgProfilerCacheMissesPerSecond",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "avgRadiusPacketsReceivedPerSecond",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "avgRadiusPacketsReceivedPerSecond",
            "description": "avgRadiusPacketsReceivedPerSecond",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "avgSnmpQueriesPerformedPerSecond",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "avgSnmpQueriesPerformedPerSecond",
            "description": "avgSnmpQueriesPerformedPerSecond",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "avgSnmpTrapsReceivedPerSecond",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "avgSnmpTrapsReceivedPerSecond",
            "description": "avgSnmpTrapsReceivedPerSecond",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "dateTimeStamp",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "dateTimeStamp",
            "description": "dateTimeStamp",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "dhcpPacketsReceived",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "dhcpPacketsReceived",
            "description": "dhcpPacketsReceived",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "dnsServer",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "dnsServer",
            "description": "dnsServer",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "endpointActionName",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "endpointActionName",
            "description": "endpointActionName",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "endpointCertainityMetric",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "endpointCertainityMetric",
            "description": "endpointCertainityMetric",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "endpointCoA",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "endpointCoA",
            "description": "endpointCoA",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "endpointHostName",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "endpointHostName",
            "description": "endpointHostName",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "endpointIdentityGroup",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "endpointIdentityGroup",
            "description": "endpointIdentityGroup",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "endpointIPAddress",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "endpointIPAddress",
            "description": "endpointIPAddress",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "endpointMacAddress",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "endpointMacAddress",
            "description": "endpointMacAddress",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "endpointMatchedPolicy",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "endpointMatchedPolicy",
            "description": "endpointMatchedPolicy",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "endpointMatchedRuleResult",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "endpointMatchedRuleResult",
            "description": "endpointMatchedRuleResult",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "endpointNADAddress",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "endpointNADAddress",
            "description": "endpointNADAddress",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "endpointNameServer",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "endpointNameServer",
            "description": "endpointNameServer",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "endpointOldPolicy",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "endpointOldPolicy",
            "description": "endpointOldPolicy",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "endpointOUI",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "endpointOUI",
            "description": "endpointOUI",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "endpointPolicy",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "endpointPolicy",
            "description": "endpointPolicy",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "endpointProperty",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "endpointProperty",
            "description": "endpointProperty",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "endPointPurgeId",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "endPointPurgeId",
            "description": "endPointPurgeId",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "endPointPurgeRule",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "endPointPurgeRule",
            "description": "endPointPurgeRule",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "endPointPurgeScheduleType",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "endPointPurgeScheduleType",
            "description": "endPointPurgeScheduleType",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "endpointsCached",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "endpointsCached",
            "description": "endpointsCached",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "endpointsDetected",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "endpointsDetected",
            "description": "endpointsDetected",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "endpointSourceEvent",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "endpointSourceEvent",
            "description": "endpointSourceEvent",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "endpointsProfiled",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "endpointsProfiled",
            "description": "endpointsProfiled",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "endpointsSaved",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "endpointsSaved",
            "description": "endpointsSaved",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "endpointStaticAssignment",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "endpointStaticAssignment",
            "description": "endpointStaticAssignment",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "endpointSubnet",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "endpointSubnet",
            "description": "endpointSubnet ",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "endpointsUpdateAverted",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "endpointsUpdateAverted",
            "description": "endpointsUpdateAverted",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "endpointsUpdated",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "endpointsUpdated",
            "description": "endpointsUpdated",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "endpointUserAgent",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "endpointUserAgent",
            "description": "endpointUserAgent ",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "endpointVlan",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "endpointVlan",
            "description": "endpointVlan",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "feedService",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "feedService",
            "description": "feedService",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "feedServiceContent",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "feedServiceContent",
            "description": "feedServiceContent",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "feedServicefeed",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "feedServicefeed",
            "description": "feedServicefeed",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "feedServicefeedVersion",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "feedServicefeedVersion",
            "description": "feedServicefeedVersion",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "feedServiceHost",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "feedServiceHost",
            "description": "feedServiceHost",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "feedServicePort",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "feedServicePort",
            "description": "feedServicePort",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "feedServiceQueryFromTime",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "feedServiceQueryFromTime",
            "description": "feedServiceQueryFromTime",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "feedServiceQueryToTime",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "feedServiceQueryToTime",
            "description": "feedServiceQueryToTime",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "httpPacketsReceived",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "httpPacketsReceived",
            "description": "httpPacketsReceived",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "netflowPacketsReceived",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "netflowPacketsReceived",
            "description": "netflowPacketsReceived",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "objectsPurged",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "objectsPurged",
            "description": "objectsPurged ",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "probeDhcpEndpointsDetected",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "probeDhcpEndpointsDetected",
            "description": "probeDhcpEndpointsDetected",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "probeDnsEndpointsDetected",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "probeDnsEndpointsDetected",
            "description": "probeDnsEndpointsDetected",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "probeDummyEndpointsDetected",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "probeDummyEndpointsDetected",
            "description": "probeDummyEndpointsDetected",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "probeHttpEndpointsDetected",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "probeHttpEndpointsDetected",
            "description": "probeHttpEndpointsDetected",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "probeNetflowEndpointsDetected",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "probeNetflowEndpointsDetected",
            "description": "probeNetflowEndpointsDetected",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "probeRadiusEndpointsDetected",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "probeRadiusEndpointsDetected",
            "description": "probeRadiusEndpointsDetected",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "probeSnmpQueryEndpointsDetected",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "probeSnmpQueryEndpointsDetected",
            "description": "probeSnmpQueryEndpointsDetected",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "probeSnmpTrapEndpointsDetected",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "probeSnmpTrapEndpointsDetected",
            "description": "probeSnmpTrapEndpointsDetected",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "probeSpanEndpointsDetected",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "probeSpanEndpointsDetected",
            "description": "probeSpanEndpointsDetected",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "profilerCacheHits",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "profilerCacheHits",
            "description": "profilerCacheHits",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "profilerCacheMisses",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "profilerCacheMisses",
            "description": "profilerCacheMisses",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "profilerErrorMessage",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "profilerErrorMessage",
            "description": "profilerErrorMessage",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "profilerProbeLoadFailMsg",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "profilerProbeLoadFailMsg",
            "description": "profilerProbeLoadFailMsg",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "profilerProbeName",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "profilerProbeName",
            "description": "profilerProbeName",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "profilerServer",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "profilerServer",
            "description": "profilerServer",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "purgedCount",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "purgedCount",
            "description": "purgedCount",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "radiusPacketsReceived",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "radiusPacketsReceived",
            "description": "radiusPacketsReceived",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "scheduledAt",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "scheduledAt",
            "description": "scheduledAt",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "snmpOIDValue",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "snmpOIDValue",
            "description": "snmpOIDValue",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "snmpQueriesPerformed",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "snmpQueriesPerformed",
            "description": "snmpQueriesPerformed",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "snmpTrapsReceived",
            "multipleAllowed": false,
            "defaultValue": "",
            "dataType": "String",
            "name": "snmpTrapsReceived",
            "description": "snmpTrapsReceived",
            "source": "Identity Service Engine",
            "isActive": false
          }
        ]
      },
      {
        "dictName": "RADIUS",
        "attributes": [
          {
            "internalName": "acctAuthentic",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "acctAuthentic",
            "description": "acctAuthentic",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "acctDelayTime",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "acctDelayTime",
            "description": "acctDelayTime",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "acctInputOctets",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "acctInputOctets",
            "description": "acctInputOctets",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "acctInputPackets",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "acctInputPackets",
            "description": "acctInputPackets",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "acctInterimInterval",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "acctInterimInterval",
            "description": "acctInterimInterval",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "acctLinkCount",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "acctLinkCount",
            "description": "acctLinkCount",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "acctMultiSessionId",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "acctMultiSessionId",
            "description": "acctMultiSessionId",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "acctOutputOctets",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "acctOutputOctets",
            "description": "acctOutputOctets",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "acctOutputPackets",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "acctOutputPackets",
            "description": "acctOutputPackets",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "acctSessionId",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "acctSessionId",
            "description": "acctSessionId",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "acctSessionTime",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "acctSessionTime",
            "description": "acctSessionTime",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "acctStatusType",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "acctStatusType",
            "description": "acctStatusType",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "acctTerminateCause",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "acctTerminateCause",
            "description": "acctTerminateCause",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "acctTunnelConnection",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "acctTunnelConnection",
            "description": "acctTunnelConnection",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "acctTunnelPacketsLost",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "acctTunnelPacketsLost",
            "description": "acctTunnelPacketsLost",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "callbackId",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "callbackId",
            "description": "callbackId",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "callbackNumber",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "callbackNumber",
            "description": "callbackNumber",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "calledStationId",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "calledStationId",
            "description": "MAC address of the AP/Switch the endpoint is talking to.",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "callingStationId",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "callingStationId",
            "description": "Endpoint MAC address typically.",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "class",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "class",
            "description": "class",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "connectInfo",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "connectInfo",
            "description": "connectInfo",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "delegatedIpv6Prefix",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "delegatedIpv6Prefix",
            "description": "delegatedIpv6Prefix",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "delegatedIpv6PrefixPool",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "delegatedIpv6PrefixPool",
            "description": "delegatedIpv6PrefixPool",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "digestAttributes",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "digestAttributes",
            "description": "digestAttributes",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "digestResponse",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "digestResponse",
            "description": "digestResponse",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "dnsServerIpv6Address",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "dnsServerIpv6Address",
            "description": "dnsServerIpv6Address",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "eapKeyName",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "eapKeyName",
            "description": "eapKeyName",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "eapMessage",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "eapMessage",
            "description": "eapMessage",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "egressVlanName",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "egressVlanName",
            "description": "egressVlanName",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "egressVlanId",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "egressVlanId",
            "description": "egressVlanId",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "errorCause",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "errorCause",
            "description": "errorCause",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "eventTimestamp",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "eventTimestamp",
            "description": "eventTimestamp",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "filterId",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "filterId",
            "description": "filterId",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "framedAppleTalkLink",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "framedAppleTalkLink",
            "description": "framedAppleTalkLink",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "framedAppleTalkNetwork",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "framedAppleTalkNetwork",
            "description": "framedAppleTalkNetwork",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "framedAppleTalkZone",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "framedAppleTalkZone",
            "description": "framedAppleTalkZone",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "framedCompression",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "framedCompression",
            "description": "framedCompression",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "framedInterfaceId",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "framedInterfaceId",
            "description": "framedInterfaceId",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "framedIpAddress",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "framedIpAddress",
            "description": "IP address of the connecting endpoint gathered from Radius accounting packet",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "framedIpNetmask",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "framedIpNetmask",
            "description": "Endpoint NetMask",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "framedIpv6Address",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "framedIpv6Address",
            "description": "framedIpv6Address",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "framedIpv6Pool",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "framedIpv6Pool",
            "description": "framedIpv6Pool",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "framedIpv6Prefix",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "framedIpv6Prefix",
            "description": "framedIpv6Prefix",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "framedIpv6Route",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "framedIpv6Route",
            "description": "framedIpv6Route",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "framedIpxNetwork",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "framedIpxNetwork",
            "description": "framedIpxNetwork",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "framedMtu",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "framedMtu",
            "description": "framedMtu",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "framedPool",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "framedPool",
            "description": "framedPool",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "framedProtocol",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "framedProtocol",
            "description": "framedProtocol",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "framedRoute",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "framedRoute",
            "description": "framedRoute",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "framedRouting",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "framedRouting",
            "description": "framedRouting",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "idleTimeout",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "idleTimeout",
            "description": "idleTimeout",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "ingressFilters",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "ingressFilters",
            "description": "ingressFilters",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "loginIpHost",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "loginIpHost",
            "description": "loginIpHost",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "loginIpv6Host",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "loginIpv6Host",
            "description": "loginIpv6Host",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "loginLatGroup",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "loginLatGroup",
            "description": "loginLatGroup",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "loginLatNode",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "loginLatNode",
            "description": "loginLatNode",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "loginLatPort",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "loginLatPort",
            "description": "loginLatPort",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "loginLatService",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "loginLatService",
            "description": "loginLatService",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "loginService",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "loginService",
            "description": "loginService",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "loginTcpPort",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "loginTcpPort",
            "description": "loginTcpPort",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "messageAuthenticator",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "messageAuthenticator",
            "description": "messageAuthenticator",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "msChapv2AuthChallenge",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "msChapv2AuthChallenge",
            "description": "msChapv2AuthChallenge",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "msChapv2NtResponse",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "msChapv2NtResponse",
            "description": "msChapv2NtResponse",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "msChapv2PeerChallenge",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "msChapv2PeerChallenge",
            "description": "msChapv2PeerChallenge",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "nasFilterRule",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "nasFilterRule",
            "description": "nasFilterRule",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "nasIdentifier",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "nasIdentifier",
            "description": "nasIdentifier",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "nasIpAddress",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "nasIpAddress",
            "description": "Network Device IP address",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "nasIpv6Address",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "nasIpv6Address",
            "description": "nasIpv6Address",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "nasPort",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "nasPort",
            "description": "Port identifier on the Network device for connected endpoints",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "nasPortId",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "nasPortId",
            "description": "Port identifier on the Network device for connected endpoints",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "nasPortType",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "nasPortType",
            "description": "Identifies if it is Wired, Wireless or VPN connection",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "passwordRetry",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "passwordRetry",
            "description": "passwordRetry",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "portLimit",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "portLimit",
            "description": "portLimit",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "proxyState",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "proxyState",
            "description": "proxyState",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "replyMessage",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "replyMessage",
            "description": "replyMessage",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "routeIpv6Information",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "routeIpv6Information",
            "description": "routeIpv6Information",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "serviceType",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "serviceType",
            "description": "Identifies the service used ( Framed for dot1x, Call check for MAB)",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "sessionTimeout",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "sessionTimeout",
            "description": "sessionTimeout",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "state",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "state",
            "description": "state",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "statefulIpv6AddressPool",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "statefulIpv6AddressPool",
            "description": "statefulIpv6AddressPool",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "terminationAction",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "terminationAction",
            "description": "terminationAction",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "tunnelAssignmentId",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "tunnelAssignmentId",
            "description": "tunnelAssignmentId",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "tunnelClientAuthId",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "tunnelClientAuthId",
            "description": "tunnelClientAuthId",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "tunnelClientEndpoint",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "tunnelClientEndpoint",
            "description": "tunnelClientEndpoint",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "tunnelMediumType",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "tunnelMediumType",
            "description": "tunnelMediumType",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "tunnelPreference",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "tunnelPreference",
            "description": "tunnelPreference",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "tunnelPrivateGroupId",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "tunnelPrivateGroupId",
            "description": "tunnelPrivateGroupId",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "tunnelServerAuthId",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "tunnelServerAuthId",
            "description": "tunnelServerAuthId",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "tunnelServerEndpoint",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "tunnelServerEndpoint",
            "description": "tunnelServerEndpoint",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "tunnelType",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "tunnelType",
            "description": "tunnelType",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "userName",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "userName",
            "description": "Radius User name",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "userPriorityTable",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "userPriorityTable",
            "description": "userPriorityTable",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "vendorSpecific",
            "multipleAllowed": false,
            "associatedClassificationCategories": null,
            "discoveryMethod": "RADIUS  probe",
            "defaultValue": "",
            "dataType": "String",
            "name": "vendorSpecific",
            "description": "vendorSpecific",
            "source": "Identity Service Engine",
            "isActive": false
          }
        ]
      },
      {
        "dictName": "SNMP",
        "attributes": [
          {
            "internalName": "cafSessionAuthorizedBy",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "cafSessionAuthorizedBy",
            "description": "cafSessionAuthorizedBy",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "cafSessionAuthUserName",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "cafSessionAuthUserName",
            "description": "cafSessionAuthUserName",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "cafSessionAuthVlan",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "cafSessionAuthVlan",
            "description": "cafSessionAuthVlan",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "cafSessionClientMacAddress",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "cafSessionClientMacAddress",
            "description": "cafSessionClientMacAddress",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "cafSessionDomain",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "cafSessionDomain",
            "description": "cafSessionDomain",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "cafSessionStatus",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "cafSessionStatus",
            "description": "cafSessionStatus",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "cLApIfMacAddress",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "cLApIfMacAddress",
            "description": "cLApIfMacAddress",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "cLApName",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "cLApName",
            "description": "cLApName",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "cLApNameServerAddress",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "cLApNameServerAddress",
            "description": "cLApNameServerAddress",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "cLApNameServerAddressType",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "cLApNameServerAddressType",
            "description": "cLApNameServerAddressType",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "cLApSshEnable",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "cLApSshEnable",
            "description": "cLApSshEnable",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "cLApSysMacAddress",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "cLApSysMacAddress",
            "description": "cLApSysMacAddress",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "cLApTelnetEnable",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "cLApTelnetEnable",
            "description": "cLApTelnetEnable",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "cLApTertiaryControllerAddress",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "cLApTertiaryControllerAddress",
            "description": "cLApTertiaryControllerAddress",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "cLApTertiaryControllerAddressType",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "cLApTertiaryControllerAddressType",
            "description": "cLApTertiaryControllerAddressType",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "cLApUpTime",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "cLApUpTime",
            "description": "cLApUpTime",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "cLApWipsEnable",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "cLApWipsEnable",
            "description": "cLApWipsEnable",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "cldcAssociationMode",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "cldcAssociationMode",
            "description": "cldcAssociationMode",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "cldcClientAccessVLAN",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "cldcClientAccessVLAN",
            "description": "cldcClientAccessVLAN",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "cldcClientIPAddress",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "cldcClientIPAddress",
            "description": "cldcClientIPAddress",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "cldcClientStatus",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "cldcClientStatus",
            "description": "cldcClientStatus",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "dot1xAuthAuthControlledPortControl",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "dot1xAuthAuthControlledPortControl",
            "description": "dot1xAuthAuthControlledPortControl",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "dot1xAuthAuthControlledPortStatus",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "dot1xAuthAuthControlledPortStatus",
            "description": "dot1xAuthAuthControlledPortStatus",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "dot1xAuthSessionUserName",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "dot1xAuthSessionUserName",
            "description": "dot1xAuthSessionUserName",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "hrDeviceDescr",
            "multipleAllowed": false,
            "associatedClassificationCategories": [
              "hardwareModel",
              "hardwareManufacturer"
            ],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "hrDeviceDescr",
            "description": "Hardware Description",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "hrDeviceStatus",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "hrDeviceStatus",
            "description": "hrDeviceStatus",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "ifDescr",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "ifDescr",
            "description": "Varies for SNMP Link up trap ( points to VLAN number). For MAC notification trap it points to the Port ID",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "ifIndex",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "ifIndex",
            "description": "Varies for SNMP Link up trap ( points to VLAN number). For MAC notification trap it points to the Port ID",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "ifOperStatus",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "ifOperStatus",
            "description": "ifOperStatus",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "port",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "port",
            "description": "Port number",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "portIfIndex",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "portIfIndex",
            "description": "portIfIndex",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "sysContact",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "sysContact",
            "description": "System Contact",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "sysDescr",
            "multipleAllowed": false,
            "associatedClassificationCategories": [
              "deviceType",
              "hardwareManufacturer"
            ],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "sysDescr",
            "description": "System Description given by Manufacturers",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "sysLocation",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "sysLocation",
            "description": "sysLocation",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "sysName",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "sysName",
            "description": "Administratively assigned name",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "sysObjectId",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "sysObjectId",
            "description": "Unique ID for Network Management",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "vlan",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "vlan",
            "description": "VLAN Identifier",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "vlanName",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "vlanName",
            "description": "VLAN Name",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "vlanPortVlan",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "vlanPortVlan",
            "description": "vlanPortVlan",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "vtpVlanIfIndex",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "vtpVlanIfIndex",
            "description": "vtpVlanIfIndex",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "vtpVlanName",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "vtpVlanName",
            "description": "vtpVlanName",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "vtpVlanState",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "vtpVlanState",
            "description": "vtpVlanState",
            "source": "Identity Service Engine",
            "isActive": false
          },
          {
            "internalName": "snmpHpModelNameMib",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "SNMP",
            "defaultValue": "",
            "dataType": "String",
            "name": "snmpHpModelNameMib",
            "description": "SNMP HP DEVICE MODEL NAME",
            "source": "SDAVC",
            "isActive": false
          }
        ]
      },
      {
        "dictName": "MQTT",
        "attributes": [
          {
            "internalName": "mqttSubscribedTopic",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "MQTT",
            "defaultValue": "",
            "dataType": "String",
            "name": "mqttSubscribedTopic",
            "description": "MQTT Subscribed Topic",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "mqttPublishedTopic",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "MQTT",
            "defaultValue": "",
            "dataType": "String",
            "name": "mqttPublishedTopic",
            "description": "MQTT Published Topic",
            "source": "SDAVC",
            "isActive": false
          }
        ]
      },
      {
        "dictName": "HL7",
        "attributes": [
          {
            "internalName": "hl7ProductType",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "HL7",
            "defaultValue": "",
            "dataType": "String",
            "name": "hl7ProductType",
            "description": "HL7 Product Type",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "hl7di",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "HL7",
            "defaultValue": "",
            "dataType": "String",
            "name": "hl7di",
            "description": "HL7 DI",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "hl7udi",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "HL7",
            "defaultValue": "",
            "dataType": "String",
            "name": "hl7udi",
            "description": "HL7 UDI",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "hl7oid",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "HL7",
            "defaultValue": "",
            "dataType": "String",
            "name": "hl7oid",
            "description": "HL7 OID",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "hl7SymbologyIdentifier",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "HL7",
            "defaultValue": "",
            "dataType": "String",
            "name": "hl7SymbologyIdentifier",
            "description": "HL7 Symbology Identifier",
            "source": "SDAVC",
            "isActive": false
          }
        ]
      },
      {
        "dictName": "LDAP",
        "attributes": [
          {
            "internalName": "ldapOs",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "LDAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "ldapOs",
            "description": "LDAP Operating System",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "ldapOsVersion",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "LDAP",
            "defaultValue": "",
            "dataType": "String",
            "name": "ldapOsVersion",
            "description": "LDAP Operating System Version",
            "source": "SDAVC",
            "isActive": false
          }
        ]
      },
      {
        "dictName": "MDNS",
        "attributes": [
          {
            "internalName": "mdnsModelName",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "MDNS",
            "defaultValue": "",
            "dataType": "String",
            "name": "mdnsModelName",
            "description": "MDNS Model Name",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "mdnsOs",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "MDNS",
            "defaultValue": "",
            "dataType": "String",
            "name": "mdnsOs",
            "description": "MDNS Operating System",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "mdnsHostName",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "MDNS",
            "defaultValue": "",
            "dataType": "String",
            "name": "mdnsHostName",
            "description": "MDNS Host Name",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "mdnsAm",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "MDNS",
            "defaultValue": "",
            "dataType": "String",
            "name": "mdnsAm",
            "description": "MDNS 'am' Field",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "mdnsHw",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "MDNS",
            "defaultValue": "",
            "dataType": "String",
            "name": "mdnsHw",
            "description": "MDNS Hardware",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "mdnsMd",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "MDNS",
            "defaultValue": "",
            "dataType": "String",
            "name": "mdnsMd",
            "description": "MDNS 'md' Field",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "mdnsTy",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "MDNS",
            "defaultValue": "",
            "dataType": "String",
            "name": "mdnsTy",
            "description": "MDNS 'ty' Field",
            "source": "SDAVC",
            "isActive": false
          }
        ]
      },
      {
        "dictName": "RTSP",
        "attributes": [
          {
            "internalName": "rtspUserAgent",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "RTSP",
            "defaultValue": "",
            "dataType": "String",
            "name": "rtspUserAgent",
            "description": "RTSP User Agent",
            "source": "SDAVC",
            "isActive": false
          }
        ]
      },
      {
        "dictName": "DICOM",
        "attributes": [
          {
            "internalName": "dicomImageType",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DICOM",
            "defaultValue": "",
            "dataType": "String",
            "name": "dicomImageType",
            "description": "Dicom Image Type",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "dicomImplementationUid",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DICOM",
            "defaultValue": "",
            "dataType": "String",
            "name": "dicomImplementationUid",
            "description": "Dicom Implementation UID",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "dicomProductName",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DICOM",
            "defaultValue": "",
            "dataType": "String",
            "name": "dicomProductName",
            "description": "Dicom Product Name",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "dicomModalityDescription",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DICOM",
            "defaultValue": "",
            "dataType": "String",
            "name": "dicomModalityDescription",
            "description": "Dicom Modality Description",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "dicomDeviceId",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DICOM",
            "defaultValue": "",
            "dataType": "String",
            "name": "dicomDeviceId",
            "description": "Dicom Device ID",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "dicomPresentIntentType",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DICOM",
            "defaultValue": "",
            "dataType": "String",
            "name": "dicomPresentIntentType",
            "description": "Dicom Presentation Intent Type",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "dicomManufacturer",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DICOM",
            "defaultValue": "",
            "dataType": "String",
            "name": "dicomManufacturer",
            "description": "Dicom Manufacturer",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "dicomManufacturerModelName",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DICOM",
            "defaultValue": "",
            "dataType": "String",
            "name": "dicomManufacturerModelName",
            "description": "Dicom Model Name",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "dicomApplicationEntity",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DICOM",
            "defaultValue": "",
            "dataType": "String",
            "name": "dicomApplicationEntity",
            "description": "Dicom Application Entity",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "dicomModality",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DICOM",
            "defaultValue": "",
            "dataType": "String",
            "name": "dicomModality",
            "description": "Dicom Modality",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "dicomProductType",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DICOM",
            "defaultValue": "",
            "dataType": "String",
            "name": "dicomProductType",
            "description": "Dicom Product Type",
            "source": "SDAVC",
            "isActive": false
          },
          {
            "internalName": "dicomImplementationVer",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "DICOM",
            "defaultValue": "",
            "dataType": "String",
            "name": "dicomImplementationVer",
            "description": "Dicom Implementation Version",
            "source": "SDAVC",
            "isActive": false
          }
        ]
      },
      {
        "dictName": "CIFS",
        "attributes": [
          {
            "internalName": "cifsNativeOs",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "CIFS",
            "defaultValue": "",
            "dataType": "String",
            "name": "cifsNativeOs",
            "description": "CIFS Native OS",
            "source": "SDAVC",
            "isActive": false
          }
        ]
      },
      {
        "dictName": "ORACLE_SQLNET",
        "attributes": [
          {
            "internalName": "oracleSQLNetPlatform",
            "multipleAllowed": false,
            "associatedClassificationCategories": [],
            "discoveryMethod": "ORACLE_SQLNET",
            "defaultValue": "",
            "dataType": "String",
            "name": "oracleSQLNetPlatform",
            "description": "oracle SQLNet Platform",
            "source": "SDAVC",
            "isActive": false
          }
        ]
      }
    ]
  },
  "activeEndpointsFilter_Template": [
    {
      "oui": "Apple, Inc.",
      "matchedRules": {
        "hardwareModel": {
          "ruleType": "Apple-Ipad",
          "ruleName": "macAddress Oui Manufacturer Resolver Rule",
          "ruleId": "macAddressOuiManufacturerResolverRule"
        },
        "hardwareManufacturer": {
          "ruleType": "Apple",
          "ruleName": "macAddress Oui Manufacturer Resolver Rule",
          "ruleId": "macAddressOuiManufacturerResolverRule"
        }
      },
      "pluginId": "BuiltIn",
      "session": {
        "radiusFlowType": "Wireless802_1x",
        "selectedAuthzProfiles": [
          "PermitAccess"
        ],
        "ctsSecurityGroup": "Employees",
        "lastSessionCollectionTime": 1672551998000,
        "state": "DISCONNECTED",
        "authMethod": "dot1x",
        "authProtocol": "PEAP (EAP-MSCHAPv2)",
        "lastSessionOnSwitches": [
          {
            "firstAuthorized": 1672551998000,
            "radiusFlowType": "Wireless802_1x",
            "selectedAuthzProfiles": [
              "PermitAccess"
            ],
            "ip": "10.30.100.27",
            "nasPortId": "GigabitEthernet1/0/1",
            "activeLastWeek": [
              66782000,
              86400000,
              22487000,
              69532086
            ],
            "postureStatus": "Compliant",
            "userName": "Grace.Smith",
            "authProtocol": "PEAP (EAP-MSCHAPv2)",
            "authAttemptsPass": [
              0,
              1,
              0,
              32
            ],
            "nwDeviceFamily": "SWITCH_HUB",
            "ipv6": [],
            "state": "DISCONNECTED",
            "authMethod": "dot1x",
            "timestamp": 1672551998000,
            "nasIpAddress": "10.16.64.225"
          }
        ]
      },
      "classificationSource": {
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/1",
      "lastClassificationTimeStamp": 1672551998000,
      "connectedDeviceId": "4ce856c2-5db5-4faf-bf7d-424f9b52dee3",
      "operatingSystem": "Apple-OS",
      "apName": "CAT9K-ETA.ibng.local",
      "trustScore": {
        "isPostureDisable": false,
        "isNatDisable": false,
        "isMfcDisable": false,
        "authTs": 9,
        "trustValue": 10,
        "isAiDisable": false,
        "isConcurrentMacDisable": false
      },
      "trustScoreTimeStamp": 1672551998000,
      "calledStationId": "2C:AB:EB:4E:DC:81",
      "nasIpAddress": "10.16.64.225",
      "deviceType": null,
      "hardwareModel": "Apple-Device",
      "ip": "10.30.100.27",
      "derivedHostName": "Grace.Smith-iPad",
      "clientHealthAvailable": true,
      "isRandomMac": false,
      "userName": "Grace.Smith",
      "lastProbeCollectionTimeStamp": 1672551998000,
      "macAddress": "6C:19:C0:BD:87:C9",
      "hardwareManufacturer": "Apple, Inc.",
      "trustScoreData": {
        "authData": {
          "authTs": 9
        }
      },
      "location": "San Jose/SJC01"
    },

    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "ifIndex": "9",
      "matchedRules": {
        "deviceType": {
          "ruleType": "System",
          "ruleName": "dicomManufacturerModelName OptimaCt Rule",
          "ruleId": "dicomManufacturerModelNameOptimaCtRule"
        },
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "dicomManufacturerModelName OptimaCt Rule",
          "ruleId": "dicomManufacturerModelNameOptimaCtRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "dicomManufacturerModelName OptimaCt Rule",
          "ruleId": "dicomManufacturerModelNameOptimaCtRule"
        }
      },
      "assetHwRevision": "General-Electric-Device=>GE Hangwei Medical Systems Device=>Optima CT=>Optima CT 540",
      "dot1xAuthSessionUserName": "00-00-00-FF-00-01",
      "pluginId": "58.1",
      
      "framedIpv6Address": "",
      "operatingSystem": null,
      "ifDescr": "GigabitEthernet1/0/1",
      "calledStationId": "DC:F7:19:50:97:01",
      "deviceType": "Full-body CT system",
      "endpointSource": "pxGrid Probe",
      "dicomImageType": "DERIVED\\SECONDARY\\SCREEN SAVE ",
      "hardwareModel": "Optima CT 540",
      "ip": "192.158.24.4",
      "phoneId": "",
      "assetSwRevision": "",
      "dicomManufacturer": "GE MEDICAL SYSTEMS",
      "nasPort": "50101",
      "macAddress": "00:17:A4:12:F2:91",
      "assetIpAddress": "192.158.24.4",
      "hardwareManufacturer": "GE Hangwei Medical Systems Co., Ltd.",
      "trustScoreData": {
        "authData": {
          "authTs": 2
        }
      },
      "dicomManufacturerModelName": "Optima CT540",
      "dot1xAuthAuthControlledPortControl": "2",
      "oui": "Hewlett Packard",
      "dicomApplicationEntity": "shark-out-2",
      "session": {
        "radiusFlowType": "WiredMAB",
        "selectedAuthzProfiles": [
          "Demo-Wired"
        ],
        "ctsSecurityGroup": "Quarantined_Systems",
        "lastSessionCollectionTime": {
          "$numberLong": "1632928128107"
        },
        "state": "DISCONNECTED",
        "authMethod": "mab",
        "authProtocol": "Lookup"
      },
      "classificationSource": {
        "deviceType": "RULE ENGINE",
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/1",
      "assetVendor": "General Electric Company=>GE Hangwei Medical Systems Co., Ltd.",
      "framedIpAddress": "",
      "portalUser": "",
      "trustScore": {
        "isPostureDisable": false,
        "isNatDisable": false,
        "isMfcDisable": false,
        "authTs": 2,
        "trustValue": 2,
        "isAiDisable": false,
        "isConcurrentMacDisable": false
      },
      "mdmServerId": "",
      "ipv6": "",
      "nasIpAddress": "192.168.1.3",
      "derivedHostName": null,
      "dicomModality": "CT",
      "clientHealthAvailable": false,
      "isRandomMac": false,
      "userName": "00-17-A4-12-F2-91",
      
      "assetMacAddress": "00:17:A4:12:F2:91",
      "dhcpHostName": "",
      "assetDeviceType": "Medical Device=>Full-body CT system",
      "dot1xAuthAuthControlledPortStatus": "2",
      "ifOperStatus": "1",
      "callingStationId": "00:17:A4:12:F2:91",
      "dicomImplementationVer": "AcuoMed"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "ifIndex": "9",
      "matchedRules": {
        "deviceType": {
          "ruleType": "System",
          "ruleName": "cdpCachePlatform Cisco Normalized Ip Phone Rule 1",
          "ruleId": "cdpCachePlatformCiscoNormalizedIpPhoneRule1"
        },
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "cdpCachePlatform Cisco Normalized Ip Phone Rule 1",
          "ruleId": "cdpCachePlatformCiscoNormalizedIpPhoneRule1"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "cdpCachePlatform Cisco Normalized Ip Phone Rule 1",
          "ruleId": "cdpCachePlatformCiscoNormalizedIpPhoneRule1"
        },
        "operatingSystem": {
          "ruleType": "System",
          "ruleName": "cdpCacheVersion Cisco IP Phone SIP Firmware Extraction Rule",
          "ruleId": "cdpCacheVersionCiscoIPPhoneSIPFirmwareExtractionRule"
        }
      },
      "assetHwRevision": "Cisco-Device=>Cisco-IP-Phone=>Cisco-IP-Phone 9971",
      "dot1xAuthSessionUserName": "00-AF-1F-9D-B4-A0",
      "pluginId": "58.2",
      "framedIpv6Address": "",
      "connectedDeviceId": "022666ba-e982-46de-9843-68a5114058a0",
      "operatingSystem": "Cisco IP Phone SIP Firmware 9.4(2)SR4",
      "ifDescr": "GigabitEthernet1/0/1",
      "apName": "Edge.demo.local",
      "cdpCachePortId": "Port 1",
      "calledStationId": "EC:1D:8B:99:93:81",
      "cdpCacheCapabilities": "1168",
      "deviceType": "IP Phone",
      "endpointSource": "pxGrid Probe",
      "hardwareModel": "Cisco-IP-Phone 9971",
      "ip": "10.56.97.218",
      "phoneId": "",
      "assetSwRevision": "Cisco IP Phone SIP Firmware=>Cisco IP Phone SIP Firmware 9.4(2)SR4",
      "nasPort": "50101",
      "cdpCacheAddress": "172.16.7.200",
      "macAddress": "00:1A:E3:1B:9B:C0",
      "dhcpParameterRequestList": "1, 66, 6, 3, 15, 150, 35",
      "assetIpAddress": "10.56.97.218",
      "hardwareManufacturer": "Cisco Systems, Inc.",
      "trustScoreData": {
        "currentNatAnomalyData": {
          "decisionType": [
            {
              "detectedAnomalies": [
                {
                  "severity": 100,
                  "reason": "Detected an abnormal network usage",
                  "detectionType": "natRate",
                  "sources": [
                    "fifRate"
                  ],
                  "confidence": 50,
                  "detectionTime": {
                    "$numberLong": "1636092445861"
                  },
                  "detectionID": "-100|8"
                },
                {
                  "severity": 100,
                  "reason": "IP traffic analysis indicates a possible NAT device",
                  "detectionType": "natTtl",
                  "sources": [
                    "ttlHeaderSample",
                    "ttlHeaderSampleTcp"
                  ],
                  "confidence": 70,
                  "detectionTime": {
                    "$numberLong": "1635978598045"
                  },
                  "detectionID": "74|78|7"
                }
              ],
              "name": "natBehaviorDecision"
            }
          ],
          "threatLevel": 60,
          "detectionTime": 1635978598045
        },
        "currentSpoofingData": {
          "spoofingAck": null,
          "likelyEndpointType": null,
          "spoofingAckComment": null,
          "detectionLevel": "high",
          "applicationsUsed": [
            "client-iana-http",
            "client-https",
            "client-ldap",
            "client-ssh",
            "client-iana-http-alt",
            "client-kerberos",
            "client-ms-wbt",
            "client-iana-telnet",
            "client-sunrpc",
            "client-iana-tacacs",
            "client-iana-netbios-ssn",
            "client-iana-dynamid",
            "client-iana-tcpmux"
          ],
          "trustScoreTimeStamp": {
            "$numberLong": "1636092301455"
          },
          "alertId": "1159d8f9-d2c7-441a-957a-194c9a4de7e1",
          "expectedEndpointType": "IP Phone"
        },
        "authData": {
          "authTs": 2
        },
        "concurrentMacAnomalyData": {
          "anomalyDetails": [
            {
              "vlan": "20",
              "port": "GigabitEthernet1/0/1",
              "nadId": "FIAB.demo.local"
            },
            {
              "vlan": "70",
              "port": "GigabitEthernet1/0/4",
              "nadId": "Edge.demo.local"
            }
          ],
          "decisionType": [
            {
              "detectedAnomalies": [
                {
                  "severity": 80,
                  "anomalyProbes": [
                    {
                      "concurrentPorts": [
                        "GigabitEthernet1/0/1"
                      ],
                      "concurrentVlanIds": [
                        "20"
                      ],
                      "concurrentDeviceIds": [
                        "232a960d-c9f6-4299-8aec-0feb62fa056c"
                      ]
                    },
                    {
                      "concurrentPorts": [
                        "GigabitEthernet1/0/4"
                      ],
                      "concurrentVlanIds": [
                        "70"
                      ],
                      "concurrentDeviceIds": [
                        "022666ba-e982-46de-9843-68a5114058a0"
                      ]
                    }
                  ],
                  "reason": "MAC address detected on multiple devices simultaneously",
                  "detectionType": "macOnMultipleDevices",
                  "sources": [
                    "concurrentPorts",
                    "concurrentVlanIds",
                    "concurrentDeviceIds"
                  ],
                  "confidence": 100,
                  "detectionTime": {
                    "$numberLong": "1636091544019"
                  },
                  "detectionID": "-2113299301|-1299967861|1567154334|18"
                }
              ],
              "name": "macOnMultipleDevicesDecision"
            }
          ],
          "threatLevel": 80,
          "detectionTime": 1636091544019
        }
      },
      "cdpCachePlatform": "Cisco IP Phone 9971",
      "cdpCacheDeviceId": "SEP00AF1F9DB4A1",
      "dot1xAuthAuthControlledPortControl": "2",
      "oui": "Cisco Systems, Inc",
      "session": {
        "radiusFlowType": "WiredMAB",
        "selectedAuthzProfiles": [
          "PermitAccess"
        ],
        "ctsSecurityGroup": "IP_Phones",
        "lastSessionCollectionTime": {
          "$numberLong": "1636059888164"
        },
        "state": "STARTED",
        "authMethod": "mab",
        "authProtocol": "Lookup"
      },
      "classificationSource": {
        "deviceType": "RULE ENGINE",
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE",
        "operatingSystem": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/1",
      "assetVendor": "Cisco Systems, Inc.",
      "dhcpClassIdentifier": "Cisco Systems, Inc. IP Phone CP-9971",
      "framedIpAddress": "10.56.97.218",
      "portalUser": "",
      "trustScore": {
        "concurrentMacThreatLevel": 3,
        "isPostureDisable": false,
        "isNatDisable": false,
        "isMfcDisable": false,
        "hydraThreatLevel": 1,
        "natDetectionThreatLevel": 5,
        "authTs": 2,
        "trustValue": 3,
        "isAiDisable": false,
        "isConcurrentMacDisable": false,
        "concurrentMacFeedback": "thumbsup",
        "natFeedback": null
      },
      "mdmServerId": "",
      "ipv6": "",
      "nasIpAddress": "192.168.1.1",
      "derivedHostName": "SEP00AF1F9DB4A1",
      "cdpCacheVersion": "sip9971.9-4-2SR4-1",
      "clientHealthAvailable": true,
      "userAgent": "Debian APT-HTTP/1.3 (2.1.7)",
      "isRandomMac": false,
      "userName": "00-AF-1F-9D-B4-A0",
      "assetMacAddress": "00:1A:E3:1B:9B:C0",
      "dhcpHostName": "SEP00AF1F9DB4A1",
      "assetDeviceType": "Collaboration Endpoint=>IP Phone",
      "location": "San Jose/SJC04",
      "dot1xAuthAuthControlledPortStatus": "2",
      "ifOperStatus": "1",
      "callingStationId": "00:1A:E3:1B:9B:C0"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "ifIndex": "9",
      "matchedRules": {
        "deviceType": {
          "ruleType": "System",
          "ruleName": "cdpCachePlatform Cisco Normalized Ip Phone Rule 1",
          "ruleId": "cdpCachePlatformCiscoNormalizedIpPhoneRule1"
        },
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "cdpCachePlatform Cisco Normalized Ip Phone Rule 1",
          "ruleId": "cdpCachePlatformCiscoNormalizedIpPhoneRule1"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "cdpCachePlatform Cisco Normalized Ip Phone Rule 1",
          "ruleId": "cdpCachePlatformCiscoNormalizedIpPhoneRule1"
        },
        "operatingSystem": {
          "ruleType": "System",
          "ruleName": "cdpCacheVersion Cisco IP Phone SIP Firmware Extraction Rule",
          "ruleId": "cdpCacheVersionCiscoIPPhoneSIPFirmwareExtractionRule"
        }
      },
      "assetHwRevision": "Cisco-Device=>Cisco-IP-Phone=>Cisco-IP-Phone 9971",
      "dot1xAuthSessionUserName": "00-AF-1F-9D-B4-A0",
      "pluginId": "58.2",
      
      "framedIpv6Address": "",
      "connectedDeviceId": "022666ba-e982-46de-9843-68a5114058a0",
      "operatingSystem": "Cisco IP Phone SIP Firmware 9.4(2)SR4",
      "ifDescr": "GigabitEthernet1/0/1",
      "apName": "Edge.demo.local",
      "cdpCachePortId": "Port 1",
      "calledStationId": "EC:1D:8B:99:93:81",
      "cdpCacheCapabilities": "1168",
      "deviceType": "IP Phone",
      "endpointSource": "pxGrid Probe",
      "hardwareModel": "Cisco-IP-Phone 9971",
      "ip": "172.16.1.199",
      "phoneId": "",
      "assetSwRevision": "Cisco IP Phone SIP Firmware=>Cisco IP Phone SIP Firmware 9.4(2)SR4",
      "nasPort": "50101",
      "cdpCacheAddress": "172.16.7.200",
      "macAddress": "00:AF:1F:9D:B4:A0",
      "dhcpParameterRequestList": "1, 66, 6, 3, 15, 150, 35",
      "assetIpAddress": "172.16.1.199",
      "hardwareManufacturer": "Cisco Systems, Inc.",
      "trustScoreData": {
        "currentNatAnomalyData": {
          "decisionType": [
            {
              "detectedAnomalies": [
                {
                  "severity": 100,
                  "reason": "Detected an abnormal network usage",
                  "detectionType": "natRate",
                  "sources": [
                    "fifRate"
                  ],
                  "confidence": 50,
                  "detectionTime": {
                    "$numberLong": "1636092445861"
                  },
                  "detectionID": "-100|8"
                },
                {
                  "severity": 100,
                  "reason": "IP traffic analysis indicates a possible NAT device",
                  "detectionType": "natTtl",
                  "sources": [
                    "ttlHeaderSample",
                    "ttlHeaderSampleTcp"
                  ],
                  "confidence": 70,
                  "detectionTime": {
                    "$numberLong": "1635978598045"
                  },
                  "detectionID": "74|78|7"
                }
              ],
              "name": "natBehaviorDecision"
            }
          ],
          "threatLevel": 60,
          "detectionTime": 1635978598045
        },
        "currentSpoofingData": {
          "spoofingAck": null,
          "likelyEndpointType": null,
          "spoofingAckComment": null,
          "detectionLevel": "high",
          "applicationsUsed": [
            "client-iana-http",
            "client-https",
            "client-ldap",
            "client-ssh",
            "client-iana-http-alt",
            "client-kerberos",
            "client-ms-wbt",
            "client-iana-telnet",
            "client-sunrpc",
            "client-iana-tacacs",
            "client-iana-netbios-ssn",
            "client-iana-dynamid",
            "client-iana-tcpmux"
          ],
          "trustScoreTimeStamp": {
            "$numberLong": "1636092301455"
          },
          "alertId": "1159d8f9-d2c7-441a-957a-194c9a4de7e1",
          "expectedEndpointType": "IP Phone"
        },
        "authData": {
          "authTs": 2
        },
        "concurrentMacAnomalyData": {
          "anomalyDetails": [
            {
              "vlan": "20",
              "port": "GigabitEthernet1/0/1",
              "nadId": "FIAB.demo.local"
            },
            {
              "vlan": "70",
              "port": "GigabitEthernet1/0/4",
              "nadId": "Edge.demo.local"
            }
          ],
          "decisionType": [
            {
              "detectedAnomalies": [
                {
                  "severity": 80,
                  "anomalyProbes": [
                    {
                      "concurrentPorts": [
                        "GigabitEthernet1/0/1"
                      ],
                      "concurrentVlanIds": [
                        "20"
                      ],
                      "concurrentDeviceIds": [
                        "232a960d-c9f6-4299-8aec-0feb62fa056c"
                      ]
                    },
                    {
                      "concurrentPorts": [
                        "GigabitEthernet1/0/4"
                      ],
                      "concurrentVlanIds": [
                        "70"
                      ],
                      "concurrentDeviceIds": [
                        "022666ba-e982-46de-9843-68a5114058a0"
                      ]
                    }
                  ],
                  "reason": "MAC address detected on multiple devices simultaneously",
                  "detectionType": "macOnMultipleDevices",
                  "sources": [
                    "concurrentPorts",
                    "concurrentVlanIds",
                    "concurrentDeviceIds"
                  ],
                  "confidence": 100,
                  "detectionTime": {
                    "$numberLong": "1636091544019"
                  },
                  "detectionID": "-2113299301|-1299967861|1567154334|18"
                }
              ],
              "name": "macOnMultipleDevicesDecision"
            }
          ],
          "threatLevel": 80,
          "detectionTime": 1636091544019
        }
      },
      "cdpCachePlatform": "Cisco IP Phone 9971",
      "cdpCacheDeviceId": "SEP00AF1F9DB4A0",
      "dot1xAuthAuthControlledPortControl": "2",
      "oui": "Cisco Systems, Inc",
      "session": {
        "radiusFlowType": "WiredMAB",
        "selectedAuthzProfiles": [
          "PermitAccess"
        ],
        "ctsSecurityGroup": "IP_Phones",
        "lastSessionCollectionTime": {
          "$numberLong": "1636059888164"
        },
        "state": "STARTED",
        "authMethod": "mab",
        "authProtocol": "Lookup"
      },
      "classificationSource": {
        "deviceType": "RULE ENGINE",
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE",
        "operatingSystem": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/1",
      "assetVendor": "Cisco Systems, Inc.",
      "dhcpClassIdentifier": "Cisco Systems, Inc. IP Phone CP-9971",
      "framedIpAddress": "172.16.1.199",
      "portalUser": "",
      "trustScore": {
        "concurrentMacThreatLevel": 3,
        "isPostureDisable": false,
        "isNatDisable": false,
        "isMfcDisable": false,
        "hydraThreatLevel": 1,
        "natDetectionThreatLevel": 5,
        "authTs": 2,
        "trustValue": 3,
        "isAiDisable": false,
        "isConcurrentMacDisable": false,
        "concurrentMacFeedback": "thumbsup",
        "natFeedback": null
      },
      "mdmServerId": "",
      "ipv6": "",
      "nasIpAddress": "192.168.1.1",
      "derivedHostName": "SEP00AF1F9DB4A0",
      "cdpCacheVersion": "sip9971.9-4-2SR4-1",
      "clientHealthAvailable": true,
      "userAgent": "Debian APT-HTTP/1.3 (2.1.7)",
      "isRandomMac": false,
      "userName": "00-AF-1F-9D-B4-A0",
      
      "assetMacAddress": "00:AF:1F:9D:B4:A0",
      "dhcpHostName": "SEP00AF1F9DB4A0",
      "assetDeviceType": "Collaboration Endpoint=>IP Phone",
      "location": "San Jose/SJC04",
      "dot1xAuthAuthControlledPortStatus": "2",
      "ifOperStatus": "1",
      "callingStationId": "00:AF:1F:9D:B4:A0"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "9",
      "oui": "ecobee inc",
      "matchedRules": {
        "deviceType": {
          "ruleType": "System",
          "ruleName": "mdnsMd Ecobee Model Name Rule",
          "ruleId": "mdnsMdEcobeeModelNameRule"
        },
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "mdnsMd Ecobee Model Name Rule",
          "ruleId": "mdnsMdEcobeeModelNameRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "mdnsMd Ecobee Model Name Rule",
          "ruleId": "mdnsMdEcobeeModelNameRule"
        }
      },
      "assetHwRevision": "Ecobee-Device=>Ecobee-Thermostat=>ecobee3 lite",
      "dot1xAuthSessionUserName": "00-00-00-FF-00-01",
      "pluginId": "58.1",
      "session": {
        "radiusFlowType": "WiredMAB",
        "selectedAuthzProfiles": [
          "Demo-Wired"
        ],
        "ctsSecurityGroup": "Quarantined_Systems",
        "lastSessionCollectionTime": {
          "$numberLong": "1632928090084"
        },
        "state": "DISCONNECTED",
        "authMethod": "mab",
        "authProtocol": "Lookup"
      },
      "classificationSource": {
        "deviceType": "RULE ENGINE",
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/1",
      
      "assetVendor": "ecobee Inc.",
      "framedIpv6Address": "",
      "framedIpAddress": "",
      "operatingSystem": null,
      "ifDescr": "GigabitEthernet1/0/1",
      "portalUser": "",
      "trustScore": {
        "isPostureDisable": false,
        "isNatDisable": false,
        "isMfcDisable": false,
        "authTs": 2,
        "trustValue": 2,
        "isAiDisable": false,
        "isConcurrentMacDisable": false
      },
      "mdmServerId": "",
      "ipv6": "",
      "calledStationId": "DC:F7:19:50:97:01",
      "nasIpAddress": "192.168.1.3",
      "deviceType": "Thermostat",
      "endpointSource": "pxGrid Probe",
      "hardwareModel": "ecobee3 lite",
      "mdnsMd": "ecobee3 lite",
      "ip": "",
      "derivedHostName": null,
      "phoneId": "",
      "assetSwRevision": "",
      "clientHealthAvailable": false,
      "isRandomMac": false,
      "userName": "44-61-32-EA-0D-71",
      "nasPort": "50101",
     
      "macAddress": "44:61:32:EA:0D:71",
      "assetMacAddress": "44:61:32:EA:0D:71",
      "dhcpHostName": "",
      "assetIpAddress": "",
      "hardwareManufacturer": "ecobee Inc.",
      "trustScoreData": {
        "authData": {
          "authTs": 2
        }
      },
      "assetDeviceType": "IOT Device=>Thermostat",
      "dot1xAuthAuthControlledPortStatus": "2",
      "ifOperStatus": "1",
      "mdnsHostName": "1-404-STUDIO",
      "callingStationId": "44:61:32:EA:0D:71"
    },


    {
      "nasPortType": "Ethernet",
      "serviceType": "Framed",
      "ifIndex": "9",
      "matchedRules": {
        "deviceType": {
          "ruleType": "Custom Rule",
          "ruleName": "Workstation - other devices",
          "ruleId": "custom-33d7e2e2-51ec-41c6-9164-1cfb639263a6"
        },
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "macAddress Oui Manufacturer Resolver Rule",
          "ruleId": "macAddressOuiManufacturerResolverRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "macAddress Oui Manufacturer Resolver Rule",
          "ruleId": "macAddressOuiManufacturerResolverRule"
        },
        "operatingSystem": {
          "ruleType": "System",
          "ruleName": "tlsFingerprint Tls Fingerprint Normalization Rule",
          "ruleId": "tlsFingerprintTlsFingerprintNormalizationRule"
        }
      },
      "assetHwRevision": "VMWare-Device",
      "operatingSystemResult": "Windows 10 Professional 64-bit",
      "dot1xAuthSessionUserName": "00-00-00-FF-00-01",
      "pluginId": "58.2",
      
      "framedIpv6Address": "",
      "connectedDeviceId": "022666ba-e982-46de-9843-68a5114058a0",
      "operatingSystem": "Windows 10 Enterprise (10.0.19041)",
      "ifDescr": "GigabitEthernet1/0/1",
      "apName": "Edge.demo.local",
      "calledStationId": "DC:F7:19:50:97:01",
      "deviceType": "Workstation",
      "endpointSource": "RADIUS Probe",
      "hardwareModel": "VMWare-Device",
      "ip": "172.16.1.202",
      "phoneId": "66bf0fb982df0147e6bea8ee7d338870cc3ff2de",
      "assetSwRevision": "Windows=>Windows 10=>Windows 10 Enterprise=>Windows 10 Enterprise (10.0.19041)",
      "nasPort": "50101",
      "macAddress": "00:50:56:AE:C0:5E",
      "dhcpParameterRequestList": "1, 3, 6, 15, 31, 33, 43, 44, 46, 47, 119, 121, 249, 252",
      "assetIpAddress": "172.16.1.202",
      "hardwareManufacturer": "VMware, Inc.",
      "trustScoreData": {
        "authData": {
          "authTs": 8
        },
        "postureData": {
          "postureTs": 3
        }
      },
      "mdnsHostName": "wx-emp1",
      "dot1xAuthAuthControlledPortControl": "2",
      "oui": "VMware, Inc.",
      "session": {
        "radiusFlowType": "Wired802_1x",
        "selectedAuthzProfiles": [
          "Demo-Wired"
        ],
        "ctsSecurityGroup": "Employees",
        "lastSessionCollectionTime": {
          "$numberLong": "1636458713597"
        },
        "state": "STARTED",
        "postureStatus": "Compliant",
        "authMethod": "dot1x",
        "authProtocol": "PEAP (EAP-MSCHAPv2)"
      },
      "classificationSource": {
        "deviceType": "RULE ENGINE",
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE",
        "operatingSystem": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/1",
      "assetVendor": "VMware, Inc.",
      "dhcpClassIdentifier": "MSFT 5.0",
      "framedIpAddress": "172.16.1.202",
      "netbiosNsName": "WX-EMP1",
      "portalUser": "",
      "trustScore": {
        "postureTs": 3,
        "isPostureDisable": false,
        "isNatDisable": false,
        "isMfcDisable": false,
        "authTs": 8,
        "trustValue": 10,
        "isAiDisable": false,
        "isConcurrentMacDisable": false
      },
      "mdmServerId": "",
      "ipv6": "",
      "nasIpAddress": "192.168.1.3",
      "phoneIdType": "UDID",
      "derivedHostName": "wx-emp1",
      "clientHealthAvailable": true,
      "userAgent": "Microsoft NCSI",
      "isRandomMac": false,
      "userName": "employee1",
      
      "assetMacAddress": "00:50:56:AE:C0:5E",
      "dhcpHostName": "wx-emp1",
      "assetDeviceType": "Workstation",
      "location": "San Jose/SJC04",
      "dot1xAuthAuthControlledPortStatus": "2",
      "ifOperStatus": "1",
      "callingStationId": "00:50:56:AE:C0:5E"
    },

    {
      "nasPortType": "Ethernet",
      "serviceType": "Framed",
      "ifIndex": "10",
      "matchedRules": {
        "deviceType": {
          "ruleType": "Custom Rule",
          "ruleName": "Workstation - other devices",
          "ruleId": "custom-33d7e2e2-51ec-41c6-9164-1cfb639263a6"
        },
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "macAddress Oui Manufacturer Resolver Rule",
          "ruleId": "macAddressOuiManufacturerResolverRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "macAddress Oui Manufacturer Resolver Rule",
          "ruleId": "macAddressOuiManufacturerResolverRule"
        },
        "operatingSystem": {
          "ruleType": "System",
          "ruleName": "tlsFingerprint Tls Fingerprint Normalization Rule",
          "ruleId": "tlsFingerprintTlsFingerprintNormalizationRule"
        }
      },
      "assetHwRevision": "VMWare-Device",
      "operatingSystemResult": "Windows 10 Professional 64-bit",
      "dot1xAuthSessionUserName": "00-00-00-FF-00-02",
      "pluginId": "58.2",
      
      "framedIpv6Address": "",
      "connectedDeviceId": "022666ba-e982-46de-9843-68a5114058a0",
      "operatingSystem": "Windows 10 Enterprise (10.0.19041)",
      "ifDescr": "GigabitEthernet1/0/2",
      "apName": "Edge.demo.local",
      "calledStationId": "DC:F7:19:50:97:02",
      "deviceType": "Workstation",
      "endpointSource": "pxGrid Probe",
      "hardwareModel": "VMWare-Device",
      "ip": "172.16.1.201",
      "phoneId": "12bb2cfd33e52221df5715e85ffa975098848873",
      "assetSwRevision": "Windows=>Windows 10=>Windows 10 Enterprise=>Windows 10 Enterprise (10.0.19041)",
      "nasPort": "50102",
      "macAddress": "00:50:56:AE:18:87",
      "dhcpParameterRequestList": "1, 3, 6, 15, 31, 33, 43, 44, 46, 47, 119, 121, 249, 252",
      "assetIpAddress": "172.16.1.201",
      "hardwareManufacturer": "VMware, Inc.",
      "trustScoreData": {
        "authData": {
          "authTs": 8
        },
        "postureData": {
          "postureTs": 2
        }
      },
      "mdnsHostName": "wx-emp2",
      "dot1xAuthAuthControlledPortControl": "2",
      "oui": "VMware, Inc.",
      "session": {
        "radiusFlowType": "Wired802_1x",
        "selectedAuthzProfiles": [
          "Demo Temporal"
        ],
        "ctsSecurityGroup": "Quarantined_Systems",
        "lastSessionCollectionTime": {
          "$numberLong": "1636412413452"
        },
        "state": "STARTED",
        "postureStatus": "Pending",
        "authMethod": "dot1x",
        "authProtocol": "PEAP (EAP-MSCHAPv2)"
      },
      "classificationSource": {
        "deviceType": "RULE ENGINE",
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE",
        "operatingSystem": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/2",
      "assetVendor": "VMware, Inc.",
      "dhcpClassIdentifier": "MSFT 5.0",
      "framedIpAddress": "172.16.1.201",
      "netbiosNsName": "WX-EMP2",
      "portalUser": "",
      "trustScore": {
        "postureTs": 2,
        "isPostureDisable": false,
        "isNatDisable": false,
        "isMfcDisable": false,
        "authTs": 8,
        "trustValue": 8,
        "isAiDisable": false,
        "isConcurrentMacDisable": false
      },
      "mdmServerId": "",
      "ipv6": "",
      "nasIpAddress": "192.168.1.3",
      "phoneIdType": "UDID",
      "derivedHostName": "wx-emp2",
      "clientHealthAvailable": true,
      "userAgent": "Microsoft NCSI",
      "isRandomMac": false,
      "userName": "employee2",
      
      "assetMacAddress": "00:50:56:AE:18:87",
      "dhcpHostName": "wx-emp2",
      "assetDeviceType": "Workstation",
      "location": "San Jose/SJC04",
      "dot1xAuthAuthControlledPortStatus": "2",
      "ifOperStatus": "1",
      "callingStationId": "00:50:56:AE:18:87"
    },

    {
      "nasPortType": "Ethernet",
      "serviceType": "Framed",
      "ifIndex": "9",
      "matchedRules": {
        "deviceType": {
          "ruleType": "Custom Rule",
          "ruleName": "Workstation - other devices",
          "ruleId": "custom-33d7e2e2-51ec-41c6-9164-1cfb639263a6"
        },
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "macAddress Oui Manufacturer Resolver Rule",
          "ruleId": "macAddressOuiManufacturerResolverRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "macAddress Oui Manufacturer Resolver Rule",
          "ruleId": "macAddressOuiManufacturerResolverRule"
        },
        "operatingSystem": {
          "ruleType": "System",
          "ruleName": "tlsFingerprint Tls Fingerprint Normalization Rule",
          "ruleId": "tlsFingerprintTlsFingerprintNormalizationRule"
        }
      },
      "assetHwRevision": "VMWare-Device",
      "operatingSystemResult": "Windows 10 Professional 64-bit",
      "dot1xAuthSessionUserName": "00-00-00-FF-00-01",
      "pluginId": "58.2",
      
      "framedIpv6Address": "",
      "connectedDeviceId": "022666ba-e982-46de-9843-68a5114058a0",
      "operatingSystem": "Windows 10 Enterprise (10.0.19041)",
      "ifDescr": "GigabitEthernet1/0/1",
      "apName": "Edge.demo.local",
      "calledStationId": "DC:F7:19:50:97:01",
      "deviceType": "Workstation",
      "endpointSource": "RADIUS Probe",
      "hardwareModel": "VMWare-Device",
      "ip": "172.16.1.202",
      "phoneId": "66bf0fb982df0147e6bea8ee7d338870cc3ff2de",
      "assetSwRevision": "Windows=>Windows 10=>Windows 10 Enterprise=>Windows 10 Enterprise (10.0.19041)",
      "nasPort": "50101",
      "macAddress": "00:50:56:8F:57:E2",
      "dhcpParameterRequestList": "1, 3, 6, 15, 31, 33, 43, 44, 46, 47, 119, 121, 249, 252",
      "assetIpAddress": "172.16.1.202",
      "hardwareManufacturer": "VMware, Inc.",
      "trustScoreData": {
        "authData": {
          "authTs": 8
        },
        "postureData": {
          "postureTs": 3
        }
      },
      "mdnsHostName": "wx-emp1",
      "dot1xAuthAuthControlledPortControl": "2",
      "oui": "VMware, Inc.",
      "session": {
        "radiusFlowType": "Wired802_1x",
        "selectedAuthzProfiles": [
          "Demo-Wired"
        ],
        "ctsSecurityGroup": "Employees",
        "lastSessionCollectionTime": {
          "$numberLong": "1636458713597"
        },
        "state": "STARTED",
        "postureStatus": "Compliant",
        "authMethod": "dot1x",
        "authProtocol": "PEAP (EAP-MSCHAPv2)"
      },
      "classificationSource": {
        "deviceType": "RULE ENGINE",
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE",
        "operatingSystem": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/1",
      "assetVendor": "VMware, Inc.",
      "dhcpClassIdentifier": "MSFT 5.0",
      "framedIpAddress": "172.16.1.202",
      "netbiosNsName": "WX-EMP1",
      "portalUser": "",
      "trustScore": {
        "postureTs": 3,
        "isPostureDisable": false,
        "isNatDisable": false,
        "isMfcDisable": false,
        "authTs": 8,
        "trustValue": 10,
        "isAiDisable": false,
        "isConcurrentMacDisable": false
      },
      "mdmServerId": "",
      "ipv6": "",
      "nasIpAddress": "192.168.1.3",
      "phoneIdType": "UDID",
      "derivedHostName": "wx-emp1",
      "clientHealthAvailable": true,
      "userAgent": "Microsoft NCSI",
      "isRandomMac": false,
      "userName": "employee1",
      
      "assetMacAddress": "00:50:56:8F:57:E2",
      "dhcpHostName": "wx-emp1",
      "assetDeviceType": "Workstation",
      "location": "San Jose/SJC04",
      "dot1xAuthAuthControlledPortStatus": "2",
      "ifOperStatus": "1",
      "callingStationId": "00:50:56:8F:57:E2"
    },
  
    {
      "nasPortType": "Ethernet",
      "serviceType": "Framed",
      "ifIndex": "9",
      
      "matchedRules": {
        "deviceType": {
          "ruleType": "System",
          "ruleName": "Windows Dhcp Class Identifier Rule",
          "ruleId": "windowsDhcpClassIdentifierRule"
        },
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Microsoft Workstation Os Rule",
          "ruleId": "microsoftWorkstationOsRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        },
        "operatingSystem": {
          "ruleType": "System",
          "ruleName": null,
          "ruleId": "adWindowsRule"
        }
      },
      "assetHwRevision": "Microsoft-Workstation",
      "operatingSystemResult": "Windows 7 Enterprise",
      "dot1xAuthSessionUserName": "KERNOW\\water1",
      
      "adHostJoinPoint": "kernow.com",
      "framedIpv6Address": "",
      "operatingSystem": "Windows 7",
      "ifDescr": "GigabitEthernet1/0/2",
      "calledStationId": "00-08-2F-07-10-02",
      "deviceType": "Workstation",
      "adJoinPoint": "KERNOW.COM",
      "endpointSource": "SNMPQuery Probe",
      "hardwareModel": "Microsoft-Workstation",
      "ip": "10.4.1.121",
      "phoneId": "",
      "assetSwRevision": "Windows=>Windows 7",
      "nasPort": "50102",
      "macAddress": "00:50:56:94:D0:54",
      "dhcpParameterRequestList": "1, 15, 3, 6, 44, 46, 47, 31, 33, 121, 249, 43",
      "adUserJoinPoint": "KERNOW.COM",
      "assetIpAddress": "10.4.1.121",
      "hardwareManufacturer": "VMware, Inc.",
      "adFetchHostName": "kernow-w103$",
      "adOsVersion": "6.1 (7601)",
      "dot1xAuthAuthControlledPortControl": "2",
      "oui": "VMware, Inc.",
      "classificationSource": {
        "deviceType": "RULE ENGINE",
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE",
        "operatingSystem": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/2",
      "assetVendor": "VMware, Inc.",
      "adServicePack": "Service Pack 1",
      "dhcpClassIdentifier": "MSFT 5.0",
      "framedIpAddress": "10.4.1.121",
      "portalUser": "",
      "adHostExists": "true",
      "nasIdentifier": "Kernow-3850",
      "nasIpAddress": "1.1.1.4",
      "adOperatingSystem": "Windows 7 Enterprise",
      "adUserDnsDomain": "kernow.com",
      "userName": "KERNOW\\\\water1",
      
      "assetMacAddress": "00:50:56:94:D0:54",
      "dhcpHostName": "kernow-w103",
      "assetDeviceType": "Workstation",
      "location": "Global/North America/USA/California/San Jose/SJC01",
      "dot1xAuthAuthControlledPortStatus": "1",
      "ifOperStatus": "1",
      "adHostDnsDomain": "kernow.com",
      "adLastFetchTime": "1593694626747",
      "callingStationId": "00-50-56-94-D0-54",
      "trustScoreData": {
        "authData": {
          "authTs": 8
        }
      },
      "trustScore": {
        "isPostureDisable": false,
        "isNatDisable": false,
        "isMfcDisable": false,
        "authTs": 8,
        "trustValue": 8,
        "isAiDisable": false,
        "isConcurrentMacDisable": false
      }
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "ifIndex": "44",
      "matchedRules": {
        "deviceType": {
          "ruleType": "Custom Rule",
          "ruleName": "Type-CT-Scanner",
          "ruleId": "custom-c9614f73-790c-4a6a-b668-7dbe08342ad7"
        },
        "hardwareModel": {
          "ruleType": "Custom Rule",
          "ruleName": "Scanner-HW",
          "ruleId": "custom-c6253fd3-8794-4a25-8f7f-650732f28922"
        },
        "hardwareManufacturer": {
          "ruleType": "Custom Rule",
          "ruleName": "Scanner-Siemens",
          "ruleId": "custom-2ada07c2-8914-42f7-9f78-014765c88fbe"
        },
        "operatingSystem": {
          "ruleType": "Custom Rule",
          "ruleName": "Scanner-Linux",
          "ruleId": "custom-7cee7314-8b9e-47e7-ace8-12babf2ef52b"
        }
      },
      "assetHwRevision": "Magnetom Vida",
      "operatingSystemResult": "Windows 7 Enterprise",
      "dot1xAuthSessionUserName": "energy1",
      
      "framedIpv6Address": "fe80::3995:3f99:aa01:274a",
      "operatingSystem": "Linux",
      "ifDescr": "GigabitEthernet1/0/37",
      "calledStationId": "00-08-2F-07-10-25",
      "deviceType": "CT-Scanner",
      "adJoinPoint": "KERNOW.COM",
      "endpointSource": "RADIUS Probe",
      "hardwareModel": "Magnetom Vida",
      "ip": "10.4.1.124",
      "phoneId": "",
      "assetSwRevision": "Linux",
      "nasPort": "50137",
      "macAddress": "00:50:56:A0:C8:67",
      "dhcpParameterRequestList": "1, 15, 3, 6, 44, 46, 47, 31, 33, 121, 249, 43, 252",
      "adUserJoinPoint": "KERNOW.COM",
      "assetIpAddress": "10.4.1.124",
      "hardwareManufacturer": "Siemens Healthcare",
      "adFetchHostName": "kernow-w7-1$",
      "adOsVersion": "6.1 (7601)",
      "dot1xAuthAuthControlledPortControl": "2",
      "oui": "VMware, Inc.",
      "classificationSource": {
        "deviceType": "RULE ENGINE",
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE",
        "operatingSystem": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/37",
      "assetVendor": "Siemens Healthcare",
      "adServicePack": "Service Pack 1",
      "dhcpClassIdentifier": "MSFT 5.0",
      "framedIpAddress": "10.4.1.124",
      "portalUser": "",
      "adHostExists": "true",
      "nasIdentifier": "Kernow-3850",
      "nasIpAddress": "1.1.1.4",
      "adOperatingSystem": "Windows 7 Enterprise",
      "adUserDnsDomain": "kernow.com",
      "userName": "00-50-56-A0-C8-67",
      
      "assetMacAddress": "00:50:56:A0:C8:67",
      "dhcpHostName": "CT-Scanner-1",
      "assetDeviceType": "CT-Scanner",
      "location": "Global/North America/USA/California/San Jose/SJC01",
      "dot1xAuthAuthControlledPortStatus": "1",
      "ifOperStatus": "1",
      "adLastFetchTime": "1593694636781",
      "callingStationId": "00-50-56-A0-C8-67",
      "trustScoreData": {
        "authData": {
          "authTs": 8
        }
      },
      "trustScore": {
        "isPostureDisable": false,
        "isNatDisable": false,
        "isMfcDisable": false,
        "authTs": 8,
        "trustValue": 8,
        "isAiDisable": false,
        "isConcurrentMacDisable": false
      }
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "ifIndex": "8",
      "matchedRules": {
        "deviceType": {
          "ruleType": "Custom Rule",
          "ruleName": "Storage-Type",
          "ruleId": "custom-51da1180-6172-4d22-b382-48b4833dede8"
        },
        "hardwareModel": {
          "ruleType": "Custom Rule",
          "ruleName": "Storage-HW",
          "ruleId": "custom-f7958d1e-33dc-4971-82f8-7df363bdac67"
        },
        "hardwareManufacturer": {
          "ruleType": "Custom Rule",
          "ruleName": "Storage-Siemens",
          "ruleId": "custom-2a30beb7-36d3-423b-aa25-d65cb1e79ffb"
        },
        "operatingSystem": {
          "ruleType": "Custom Rule",
          "ruleName": "Storage-Linux",
          "ruleId": "custom-54ab632a-2124-4585-acb6-ccb645abb196"
        }
      },
      "assetHwRevision": "PACS Scan",
      "operatingSystemResult": "Windows 7 Enterprise",
      "dot1xAuthSessionUserName": "doctor1",
      
      "framedIpv6Address": "fe80::30ce:253e:bc73:79df",
      "operatingSystem": "Linux",
      "ifDescr": "GigabitEthernet1/0/1",
      "calledStationId": "20-BB-C0-A2-02-81",
      "deviceType": "Storage",
      "adJoinPoint": "KERNOW.COM",
      "endpointSource": "RADIUS Probe",
      "hardwareModel": "PACS Scan",
      "ip": "10.5.1.118",
      "phoneId": "",
      "assetSwRevision": "Linux",
      "nasPort": "50101",
      "macAddress": "00:50:56:A0:D7:3F",
      "dhcpParameterRequestList": "1, 15, 3, 6, 44, 46, 47, 31, 33, 121, 249, 43, 252",
      "adUserJoinPoint": "KERNOW.COM",
      "assetIpAddress": "10.5.1.118",
      "hardwareManufacturer": "Siemens Healthcare",
      "adFetchHostName": "kernow-w7-1$",
      "adOsVersion": "6.1 (7601)",
      "dot1xAuthAuthControlledPortControl": "2",
      "oui": "VMware, Inc.",
      "classificationSource": {
        "deviceType": "RULE ENGINE",
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE",
        "operatingSystem": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/1",
      "assetVendor": "Siemens Healthcare",
      "adServicePack": "Service Pack 1",
      "dhcpClassIdentifier": "MSFT 5.0",
      "framedIpAddress": "10.5.1.118",
      "portalUser": "",
      "adHostExists": "true",
      "nasIdentifier": "Kernow-3850-orig",
      "nasIpAddress": "1.1.1.9",
      "adOperatingSystem": "Windows 7 Enterprise",
      "adUserDnsDomain": "kernow.com",
      "userName": "00-50-56-A0-D7-3F",
      
      "assetMacAddress": "00:50:56:A0:D7:3F",
      "dhcpHostName": "PACS-System-1",
      "assetDeviceType": "Storage",
      "location": "Global/North America/USA/California/San Jose/SJC01",
      "dot1xAuthAuthControlledPortStatus": "1",
      "ifOperStatus": "1",
      "adLastFetchTime": "1593694671870",
      "callingStationId": "00-50-56-A0-D7-3F",
      "trustScoreData": {
        "authData": {
          "authTs": 8
        }
      },
      "trustScore": {
        "isPostureDisable": false,
        "isNatDisable": false,
        "isMfcDisable": false,
        "authTs": 8,
        "trustValue": 8,
        "isAiDisable": false,
        "isConcurrentMacDisable": false
      }
    },
    {
      "nasPortType": "Async",
      "serviceType": "Framed",
      "deviceType": null,
      "endpointSource": "RADIUS Probe",
      "hardwareModel": null,
      "ip": "",
      "phoneId": "",
      "userName": "CTS client",
      "operatingSystem": null,
      
      "trustScore": null,
      "portalUser": "",
      "macAddress": "04:6C:9D:1F:88:00",
      "dhcpHostName": "",
      "hardwareManufacturer": null,
      "callingStationId": "046c9d1f8800",
      "nasIpAddress": "1.1.1.6"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "33",
      "lldpCacheCapabilities": "B;R",
      "dot1xAuthSessionUserName": "70-DB-98-76-1E-8A",
      "nasPortId": "GigabitEthernet1/0/26",
      "framedIpv6Address": "",
      "framedIpAddress": "",
      "operatingSystem": null,
      "ifDescr": "GigabitEthernet1/0/26",
      "portalUser": "",
      "nasIdentifier": "Kernow-3850",
      "calledStationId": "00-08-2F-07-10-1A",
      "cdpCacheCapabilities": "R;S;I",
      "nasIpAddress": "1.1.1.4",
      "deviceType": null,
      "endpointSource": "RADIUS Probe",
      "hardwareModel": null,
      "ip": "",
      "phoneId": "",
      "userName": "70-DB-98-76-1E-8A",
      "lldpSystemName": "Kernow-IE4k-2.kernow.com",
      "nasPort": "50126",
      
      "macAddress": "70:DB:98:76:1E:8A",
      "dhcpHostName": "",
      "hardwareManufacturer": null,
      "cdpCachePlatform": "cisco IE-4000-4GS8GP4G-E",
      "cdpCacheDeviceId": "Kernow-IE4k-2.kernow.com",
      "lldpCapabilitiesMapSupported": "B;R",
      "dot1xAuthAuthControlledPortStatus": "2",
      "ifOperStatus": "1",
      "callingStationId": "70-DB-98-76-1E-8A",
      "trustScoreData": {
        "authData": {
          "authTs": 8
        }
      },
      "trustScore": {
        "isPostureDisable": false,
        "isNatDisable": false,
        "isMfcDisable": false,
        "authTs": 8,
        "trustValue": 8,
        "isAiDisable": false,
        "isConcurrentMacDisable": false
      }
    },
    {
      "deviceType": "Medical Device",
      "oui": "VMware, Inc.",
      
      "matchedRules": {
        "deviceType": {
          "ruleType": "System",
          "ruleName": "Medical Protocol Rule",
          "ruleId": "MedicalProtocolRule"
        },
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        }
      },
      "dicomApplicationEntity": "ECHOSCU",
      "hardwareModel": "VMWare-Device",
      "classificationSource": {
        "deviceType": "RULE ENGINE",
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE"
      },
      "ip": "192.168.1.68",
      "dicomImplementationUid": "1.2.276.0.7230010.3.",
      "operatingSystem": null,
      
      "macAddress": "00:0C:29:A2:75:26",
      "hardwareManufacturer": "VMware, Inc.",
      "dicomImplementationVer": "OFFIS_DCMTK_362",
      "trustScoreData": {
        "authData": {
          "authTs": 8
        }
      },
      "trustScore": {
        "isPostureDisable": false,
        "isNatDisable": false,
        "isMfcDisable": false,
        "authTs": 8,
        "trustValue": 8,
        "isAiDisable": false,
        "isConcurrentMacDisable": false
      }
    },
    {
      "deviceType": "IOT Device",
      "oui": "Arcadyan Corporation",
      
      "matchedRules": {
        "deviceType": {
          "ruleType": "System",
          "ruleName": "Lg Smart Tv Manufacturer And Model Name Rule",
          "ruleId": "lgSmartTvManufacturerAndModelNameRule"
        },
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Lg Smart Tv Manufacturer And Model Name Rule",
          "ruleId": "lgSmartTvManufacturerAndModelNameRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Lg Smart Tv Manufacturer And Model Name Rule",
          "ruleId": "lgSmartTvManufacturerAndModelNameRule"
        },
        "operatingSystem": {
          "ruleType": "System",
          "ruleName": "Lg Smart Tv Manufacturer And Model Name Rule",
          "ruleId": "lgSmartTvManufacturerAndModelNameRule"
        }
      },
      "hardwareModel": "43UK6300YVB",
      "classificationSource": {
        "deviceType": "RULE ENGINE",
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE",
        "operatingSystem": "RULE ENGINE"
      },
      "ip": "192.168.1.26",
      
      "ssdpManufacturer": "LG Electronics",
      "operatingSystem": "Linux",
      
      "macAddress": "94:6A:B0:54:35:6E",
      "hardwareManufacturer": "LG Corporation",
      "ssdpModel": "43UK6300YVB",
      "trustScoreData": {
        "authData": {
          "authTs": 8
        }
      },
      "trustScore": {
        "isPostureDisable": false,
        "isNatDisable": false,
        "isMfcDisable": false,
        "authTs": 8,
        "trustValue": 8,
        "isAiDisable": false,
        "isConcurrentMacDisable": false
      }
    },
    {
      "deviceType": "Thermostat",
      "oui": "ecobee inc",
      
      "matchedRules": {
        "deviceType": {
          "ruleType": "Custom Rule",
          "ruleName": "Ecobee Thermostat",
          "ruleId": "custom-bafc493c-d9aa-44b2-a0fe-7d0708533d20"
        },
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": null,
          "ruleId": "ecobeeModelNameRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": null,
          "ruleId": "ecobeeModelNameRule"
        }
      },
      "hardwareModel": "ecobee3 lite",
      "mdnsMd": "ecobee3 lite",
      "classificationSource": {
        "deviceType": "RULE ENGINE",
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE"
      },
      
      "operatingSystem": null,
      
      "macAddress": "44:61:32:EA:0D:71",
      "ipv6": "FE80:0:0:0:4661:32FF:FEEA:D71",
      "hardwareManufacturer": "ecobee Inc.",
      "mdnsHostName": "1-404-STUDIO",
      "trustScoreData": {
        "authData": {
          "authTs": 8
        }
      },
      "trustScore": {
        "isPostureDisable": false,
        "isNatDisable": false,
        "isMfcDisable": false,
        "authTs": 8,
        "trustValue": 8,
        "isAiDisable": false,
        "isConcurrentMacDisable": false
      }
    },
    {
      "deviceType": "General-purpose ultrasound imaging system",
      "oui": "Philips",
      "trustScore": null,
      "matchedRules": {
        "deviceType": {
          "ruleType": "System",
          "ruleName": "Medical GMDN Rule",
          "ruleId": "MedicalGMDNRule"
        },
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Medical Brand And Model Rule",
          "ruleId": "MedicalBrandAndModelRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Medical Company Name Rule",
          "ruleId": "MedicalCompanyNameRule"
        }
      },
      "hardwareModel": "EPIQ 7 DIAGNOSTIC ULTRASOUND SYSTEM 989605386721",
      "classificationSource": {
        "deviceType": "RULE ENGINE",
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE"
      },
      "ip": "10.0.0.155",
      
      "operatingSystem": null,
      
      "macAddress": "E8:C1:D7:17:06:90",
      "hl7di": "(01)00884838047693",
      "hl7oid": "2.16.840.1.113883.3.326",
      "hl7SymbologyIdentifier": "GS1",
      "hardwareManufacturer": "PHILIPS ULTRASOUND, INC."
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Framed",
      
      "ifIndex": "12",
      "lldpCacheCapabilities": "36",
      "matchedRules": {
        "deviceType": {
          "ruleType": "System",
          "ruleName": "Cisco Ip Phone Dhcp Class Identifier Rule 2",
          "ruleId": "ciscoIpPhoneDhcpIdentifierRule2"
        },
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Cisco Ip Phone Dhcp Class Identifier Rule 2",
          "ruleId": "ciscoIpPhoneDhcpIdentifierRule2"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Cisco Ip Phone Dhcp Class Identifier Rule 2",
          "ruleId": "ciscoIpPhoneDhcpIdentifierRule2"
        },
        "operatingSystem": {
          "ruleType": "System",
          "ruleName": "Cisco Ip Phone Dhcp Class Identifier Rule 2",
          "ruleId": "ciscoIpPhoneDhcpIdentifierRule2"
        }
      },
      "assetHwRevision": "Cisco-Device=>Cisco-IP-Phone=>Cisco IP Phone 8945",
      "dot1xAuthSessionUserName": "CP-8945-SEP580a20fa4f64",
      
      "framedIpv6Address": "",
      "operatingSystem": "Cisco IP Phone",
      "lldpMedNetworkPolicy": "Voice",
      "ifDescr": "GigabitEthernet1/0/4",
      "lldpMedCapabilities": "51",
      "lldpSystemDescription": "Cisco IP Phone 8945, V3, SIP 9-3-4-17",
      "calledStationId": "CC-5A-53-D9-3A-84",
      "cdpCacheCapabilities": "1168",
      "deviceType": "IP Phone",
      "endpointSource": "RADIUS Probe",
      "hardwareModel": "Cisco IP Phone 8945",
      "ip": "172.16.102.205",
      "phoneId": "",
      "assetSwRevision": "Cisco IP Phone",
      "lldpMedSwRevision": "SIP 9-3-4-17",
      "nasPort": "50104",
      "cdpCacheAddress": "172.16.102.205",
      "macAddress": "58:0A:20:FA:4F:64",
      "dhcpParameterRequestList": "1, 3, 15, 6, 12, 35, 66, 150",
      "assetIpAddress": "172.16.102.205",
      "hardwareManufacturer": "Cisco Systems, Inc.",
      "cdpCachePlatform": "Cisco IP Phone 8945 ",
      "cdpCacheDeviceId": "SEP580A20FA4F64",
      "lldpCapabilitiesMapSupported": "B;T",
      "dot1xAuthAuthControlledPortControl": "2",
      "oui": "Cisco Systems, Inc",
      "lldpMedHwRevision": "3",
      "classificationSource": {
        "deviceType": "RULE ENGINE",
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE",
        "operatingSystem": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/4",
      "assetVendor": "Cisco Systems, Inc.",
      "dhcpClassIdentifier": "Cisco Systems, Inc. IP Phone CP-8945",
      "framedIpAddress": "172.16.102.205",
      "portalUser": "",
      "lldpMedManufacture": "Cisco Systems , Inc.",
      "lldpMedDeviceType": "Endpoint Class III",
      "lldpMedModel": "CP-8945",
      "nasIdentifier": "Edge",
      "nasIpAddress": "192.168.120.1",
      "cdpCacheVersion": "SIP 9-3-4-17",
      "userName": "CP-8945-SEP580a20fa4f64",
      "lldpSystemName": "SEP580A20FA4F64.demo.local",
      
      "lldpMedSnNumber": "PUC18070A2Z",
      "assetMacAddress": "58:0A:20:FA:4F:64",
      "dhcpHostName": "SEP580a20fa4f64",
      "lldpMedFwRevision": "0.0.1.0",
      "assetDeviceType": "IP Phone",
      "location": "SJC04/SJC04-2",
      "dot1xAuthAuthControlledPortStatus": "1",
      "ifOperStatus": "1",
      "callingStationId": "58-0A-20-FA-4F-64",
      "trustScoreData": {
        "authData": {
          "authTs": 8
        }
      },
      "trustScore": {
        "isPostureDisable": false,
        "isNatDisable": false,
        "isMfcDisable": false,
        "authTs": 8,
        "trustValue": 8,
        "isAiDisable": false,
        "isConcurrentMacDisable": false
      }
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "9",
      "oui": "Dell Inc.",
      "matchedRules": {
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Dell  Device Mac Oui Rule",
          "ruleId": "dellDeviceMacOuiRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Dell  Device Mac Oui Rule",
          "ruleId": "dellDeviceMacOuiRule"
        }
      },
      "assetHwRevision": "Dell-Device",
      "dot1xAuthSessionUserName": "00-08-74-AD-F1-9B",
      "classificationSource": {
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/1",
      
      "assetVendor": "Dell Inc.",
      "framedIpv6Address": "",
      "framedIpAddress": "",
      "operatingSystem": null,
      "ifDescr": "GigabitEthernet1/0/1",
      "portalUser": "",
      
      "nasIdentifier": "Edge",
      "calledStationId": "EC-1D-8B-99-7E-01",
      "nasIpAddress": "192.168.120.1",
      "deviceType": null,
      "endpointSource": "pxGrid Probe",
      "hardwareModel": "Dell-Device",
      "ip": "192.168.0.1",
      "phoneId": "",
      "assetSwRevision": "",
      "clientHealthAvailable": false,
      "userName": "00-08-74-AD-F1-9B",
      "nasPort": "50101",
      
      "macAddress": "00:08:74:AD:F1:9B",
      "assetMacAddress": "00:08:74:AD:F1:9B",
      "dhcpHostName": "",
      "assetIpAddress": "192.168.0.1",
      "hardwareManufacturer": "Dell Inc.",
      "assetDeviceType": "",
      "dot1xAuthAuthControlledPortStatus": "2",
      "ifOperStatus": "1",
      "callingStationId": "00-08-74-AD-F1-9B",
      "trustScoreData": {
        "authData": {
          "authTs": 8
        }
      },
      "trustScore": {
        "isPostureDisable": false,
        "isNatDisable": false,
        "isMfcDisable": false,
        "authTs": 8,
        "trustValue": 8,
        "isAiDisable": false,
        "isConcurrentMacDisable": false
      }
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "9",
      "oui": "Apple, Inc.",
      "matchedRules": {
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Apple Mac Oui Rule",
          "ruleId": "appleMacOuiRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Apple Mac Oui Rule",
          "ruleId": "appleMacOuiRule"
        }
      },
      "assetHwRevision": "Apple-Device",
      "dot1xAuthSessionUserName": "00-08-74-AD-F1-9B",
      "classificationSource": {
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/1",
      
      "assetVendor": "Apple, Inc.",
      "framedIpv6Address": "",
      "framedIpAddress": "",
      "operatingSystem": null,
      "ifDescr": "GigabitEthernet1/0/1",
      "portalUser": "",
      "nasIdentifier": "Edge",
      "calledStationId": "EC-1D-8B-99-7E-01",
      "nasIpAddress": "192.168.120.1",
      "deviceType": null,
      "endpointSource": "pxGrid Probe",
      "hardwareModel": "Apple-Device",
      "ip": "",
      "phoneId": "",
      "assetSwRevision": "",
      "clientHealthAvailable": false,
      "userName": "000a95170690",
      "nasPort": "50101",
      
      "macAddress": "00:0A:95:17:06:90",
      "assetMacAddress": "00:0A:95:17:06:90",
      "dhcpHostName": "",
      "assetIpAddress": "",
      "hardwareManufacturer": "Apple, Inc.",
      "assetDeviceType": "",
      "dot1xAuthAuthControlledPortStatus": "2",
      "ifOperStatus": "1",
      "callingStationId": "00-0A-95-17-06-90",
      "trustScoreData": {
        "authData": {
          "authTs": 8
        }
      },
      "trustScore": {
        "isPostureDisable": false,
        "isNatDisable": false,
        "isMfcDisable": false,
        "authTs": 8,
        "trustValue": 8,
        "isAiDisable": false,
        "isConcurrentMacDisable": false
      }
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "9",
      "oui": "Hewlett Packard",
      "matchedRules": {
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "HP Device Mac Oui Rule",
          "ruleId": "hpDeviceMacOuiRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "HP Device Mac Oui Rule",
          "ruleId": "hpDeviceMacOuiRule"
        }
      },
      "assetHwRevision": "HP-Device",
      "dot1xAuthSessionUserName": "00-08-74-AD-F1-9B",
      "classificationSource": {
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/1",
      
      "assetVendor": "HP Inc.",
      "framedIpv6Address": "",
      "framedIpAddress": "",
      "operatingSystem": null,
      "ifDescr": "GigabitEthernet1/0/1",
      "udp161": "snmp",
      "portalUser": "",
      "trustScore": null,
      "udp162": "snmptrap",
      "nasIdentifier": "Edge",
      "calledStationId": "EC-1D-8B-99-7E-01",
      "nasIpAddress": "192.168.120.1",
      "deviceType": null,
      "endpointSource": "pxGrid Probe",
      "hardwareModel": "HP-Device",
      "ip": "",
      "phoneId": "",
      "assetSwRevision": "",
      "clientHealthAvailable": false,
      "userName": "00-17-A4-12-A2-11",
      "nasPort": "50101",
      
      "macAddress": "00:17:A4:12:A2:11",
      "assetMacAddress": "00:17:A4:12:A2:11",
      "dhcpHostName": "",
      "assetIpAddress": "",
      "hardwareManufacturer": "HP Inc.",
      "assetDeviceType": "",
      "dot1xAuthAuthControlledPortStatus": "2",
      "ifOperStatus": "1",
      "callingStationId": "00-17-A4-12-A2-11"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "9",
      "oui": "LEXMARK INTERNATIONAL, INC.",
      "matchedRules": {
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Lexmark Device Mac Oui Rule",
          "ruleId": "lexmarkDeviceMacOuiRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Lexmark Device Mac Oui Rule",
          "ruleId": "lexmarkDeviceMacOuiRule"
        }
      },
      "assetHwRevision": "Lexmark-Device",
      "dot1xAuthSessionUserName": "00-08-74-AD-F1-9B",
      "classificationSource": {
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/1",
      
      "assetVendor": "Lexmark International, Inc.",
      "framedIpv6Address": "",
      "framedIpAddress": "",
      "operatingSystem": null,
      "ifDescr": "GigabitEthernet1/0/1",
      "portalUser": "",
      "trustScore": null,
      "nasIdentifier": "Edge",
      "calledStationId": "EC-1D-8B-99-7E-01",
      "nasIpAddress": "192.168.120.1",
      "deviceType": null,
      "endpointSource": "pxGrid Probe",
      "hardwareModel": "Lexmark-Device",
      "ip": "",
      "phoneId": "",
      "assetSwRevision": "",
      "clientHealthAvailable": false,
      "userName": "00-21-B7-07-AB-24",
      "nasPort": "50101",
      
      "macAddress": "00:21:B7:07:AB:24",
      "assetMacAddress": "00:21:B7:07:AB:24",
      "dhcpHostName": "",
      "assetIpAddress": "",
      "hardwareManufacturer": "Lexmark International, Inc.",
      "assetDeviceType": "",
      "dot1xAuthAuthControlledPortStatus": "2",
      "ifOperStatus": "1",
      "callingStationId": "00-21-B7-07-AB-24"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "9",
      "oui": "NEXO COMMUNICATIONS, INC.",
      "matchedRules": {},
      "assetHwRevision": "",
      "dot1xAuthSessionUserName": "00-50-00-00-01-11",
      "classificationSource": {
        "deviceType": "ERT",
        "hardwareManufacturer": "ERT"
      },
      "nasPortId": "GigabitEthernet1/0/1",
      
      "assetVendor": "Siemens",
      "framedIpv6Address": "fe80::250:ff:fe00:111",
      "connectedDeviceId": "5e332de1-446d-4331-9c6a-cfee30915a2e",
      "framedIpAddress": "172.16.102.111",
      "operatingSystem": [],
      "ifDescr": "GigabitEthernet1/0/1",
      "apName": "Edge.demo.local",
      "udp161": "snmp",
      "portalUser": "",
      "trustScore": null,
      "udp162": "snmptrap",
      "nasIdentifier": "Edge",
      "calledStationId": "DC-F7-19-50-97-01",
      "nasIpAddress": "192.168.120.1",
      "deviceType": "MRI",
      "endpointSource": "RADIUS Probe",
      "hardwareModel": [],
      "ip": "172.16.102.111",
      "phoneId": "",
      "assetSwRevision": "",
      "clientHealthAvailable": true,
      "userAgent": "Debian APT-HTTP/1.3 (1.6.12ubuntu0.1)",
      "userName": "005000000111",
      "nasPort": "50101",
      
      "macAddress": "00:50:00:00:01:11",
      "assetMacAddress": "00:50:00:00:01:11",
      "dhcpHostName": "",
      "assetIpAddress": "172.16.102.111",
      "hardwareManufacturer": "Siemens",
      "assetDeviceType": "MRI",
      "location": "SJC04/SJC04-2",
      "dot1xAuthAuthControlledPortStatus": "1",
      "ifOperStatus": "1",
      "callingStationId": "00-50-00-00-01-11"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "9",
      "oui": "NEXO COMMUNICATIONS, INC.",
      "matchedRules": {},
      "assetHwRevision": "",
      "dot1xAuthSessionUserName": "00-50-00-00-01-11",
      "classificationSource": {},
      "nasPortId": "GigabitEthernet1/0/1",
      
      "assetVendor": "",
      "framedIpv6Address": "fe80::250:ff:fe00:112",
      "connectedDeviceId": "5e332de1-446d-4331-9c6a-cfee30915a2e",
      "framedIpAddress": "172.16.102.112",
      "operatingSystem": [],
      "ifDescr": "GigabitEthernet1/0/1",
      "apName": "Edge.demo.local",
      "portalUser": "",
      "trustScore": null,
      "nasIdentifier": "Edge",
      "calledStationId": "DC-F7-19-50-97-01",
      "nasIpAddress": "192.168.120.1",
      "deviceType": [],
      "endpointSource": "RADIUS Probe",
      "hardwareModel": [],
      "ip": "172.16.102.112",
      "phoneId": "",
      "assetSwRevision": "",
      "clientHealthAvailable": true,
      "userAgent": "Debian APT-HTTP/1.3 (1.6.12ubuntu0.1)",
      "userName": "005000000112",
      "nasPort": "50101",
      
      "macAddress": "00:50:00:00:01:12",
      "assetMacAddress": "00:50:00:00:01:12",
      "dhcpHostName": "",
      "assetIpAddress": "172.16.102.112",
      "hardwareManufacturer": null,
      "assetDeviceType": "",
      "location": "SJC04/SJC04-2",
      "dot1xAuthAuthControlledPortStatus": "1",
      "ifOperStatus": "1",
      "callingStationId": "00-50-00-00-01-12"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "9",
      "oui": "NEXO COMMUNICATIONS, INC.",
      "matchedRules": {},
      "assetHwRevision": "",
      "dot1xAuthSessionUserName": "00-50-00-00-01-11",
      "classificationSource": {},
      "nasPortId": "GigabitEthernet1/0/1",
      
      "assetVendor": "",
      "framedIpv6Address": "fe80::250:ff:fe00:113",
      "connectedDeviceId": "5e332de1-446d-4331-9c6a-cfee30915a2e",
      "framedIpAddress": "172.16.102.113",
      "operatingSystem": [],
      "ifDescr": "GigabitEthernet1/0/1",
      "apName": "Edge.demo.local",
      "portalUser": "",
      "trustScore": null,
      "nasIdentifier": "Edge",
      "calledStationId": "DC-F7-19-50-97-01",
      "nasIpAddress": "192.168.120.1",
      "deviceType": [],
      "endpointSource": "RADIUS Probe",
      "hardwareModel": [],
      "ip": "172.16.102.113",
      "phoneId": "",
      "assetSwRevision": "",
      "clientHealthAvailable": true,
      "userAgent": "Debian APT-HTTP/1.3 (1.6.12ubuntu0.1)",
      "userName": "00-50-00-00-01-13",
      "nasPort": "50101",
      
      "macAddress": "00:50:00:00:01:13",
      "assetMacAddress": "00:50:00:00:01:13",
      "dhcpHostName": "",
      "assetIpAddress": "172.16.102.113",
      "hardwareManufacturer": null,
      "assetDeviceType": "",
      "location": "SJC04/SJC04-2",
      "dot1xAuthAuthControlledPortStatus": "1",
      "ifOperStatus": "1",
      "callingStationId": "00-50-00-00-01-13"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "9",
      "oui": "NEXO COMMUNICATIONS, INC.",
      "matchedRules": {},
      "assetHwRevision": "",
      "dot1xAuthSessionUserName": "00-50-00-00-01-11",
      "classificationSource": {},
      "nasPortId": "GigabitEthernet1/0/1",
      
      "assetVendor": "",
      "framedIpv6Address": "fe80::250:ff:fe00:114",
      "connectedDeviceId": "5e332de1-446d-4331-9c6a-cfee30915a2e",
      "framedIpAddress": "172.16.102.114",
      "operatingSystem": [],
      "ifDescr": "GigabitEthernet1/0/1",
      "apName": "Edge.demo.local",
      "portalUser": "",
      "trustScore": null,
      "nasIdentifier": "Edge",
      "calledStationId": "DC-F7-19-50-97-01",
      "nasIpAddress": "192.168.120.1",
      "deviceType": [],
      "endpointSource": "RADIUS Probe",
      "hardwareModel": [],
      "ip": "172.16.102.114",
      "phoneId": "",
      "assetSwRevision": "",
      "clientHealthAvailable": true,
      "userAgent": "Debian APT-HTTP/1.3 (1.6.12ubuntu0.1)",
      "userName": "00-50-00-00-01-14",
      "nasPort": "50101",
      
      "macAddress": "00:50:00:00:01:14",
      "assetMacAddress": "00:50:00:00:01:14",
      "dhcpHostName": "",
      "assetIpAddress": "172.16.102.114",
      "hardwareManufacturer": null,
      "assetDeviceType": "",
      "location": "SJC04/SJC04-2",
      "dot1xAuthAuthControlledPortStatus": "1",
      "ifOperStatus": "1",
      "callingStationId": "00-50-00-00-01-14"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "9",
      "oui": "NEXO COMMUNICATIONS, INC.",
      "matchedRules": {},
      "assetHwRevision": "",
      "dot1xAuthSessionUserName": "00-50-00-00-01-11",
      "classificationSource": {},
      "nasPortId": "GigabitEthernet1/0/1",
      
      "assetVendor": "",
      "framedIpv6Address": "fe80::250:ff:fe00:115",
      "connectedDeviceId": "5e332de1-446d-4331-9c6a-cfee30915a2e",
      "framedIpAddress": "172.16.102.115",
      "operatingSystem": [],
      "ifDescr": "GigabitEthernet1/0/1",
      "apName": "Edge.demo.local",
      "portalUser": "",
      "trustScore": null,
      "nasIdentifier": "Edge",
      "calledStationId": "DC-F7-19-50-97-01",
      "nasIpAddress": "192.168.120.1",
      "deviceType": [],
      "endpointSource": "RADIUS Probe",
      "hardwareModel": [],
      "ip": "172.16.102.115",
      "phoneId": "",
      "assetSwRevision": "",
      "clientHealthAvailable": true,
      "userAgent": "Debian APT-HTTP/1.3 (1.6.12ubuntu0.1)",
      "userName": "00-50-00-00-01-15",
      "nasPort": "50101",
      
      "macAddress": "00:50:00:00:01:15",
      "assetMacAddress": "00:50:00:00:01:15",
      "dhcpHostName": "",
      "assetIpAddress": "172.16.102.115",
      "hardwareManufacturer": null,
      "assetDeviceType": "",
      "location": "SJC04/SJC04-2",
      "dot1xAuthAuthControlledPortStatus": "1",
      "ifOperStatus": "1",
      "callingStationId": "00-50-00-00-01-15"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "ifIndex": "10",
      "matchedRules": {},
      "assetHwRevision": "",
      "operatingSystemResult": "Linux 2.6.32 (accuracy 96%)",
      "dot1xAuthSessionUserName": "00-50-00-00-01-21",
      
      "framedIpv6Address": "",
      "connectedDeviceId": "5e332de1-446d-4331-9c6a-cfee30915a2e",
      "operatingSystem": [],
      "ifDescr": "GigabitEthernet1/0/2",
      "apName": "Edge.demo.local",
      "udp161": "snmp",
      "udp162": "snmptrap",
      "calledStationId": "DC-F7-19-50-97-02",
      "deviceType": [],
      "endpointSource": "RADIUS Probe",
      "hardwareModel": [],
      "ip": "172.16.102.121",
      "phoneId": "",
      "assetSwRevision": "",
      "nasPort": "50102",
      "macAddress": "00:50:00:00:01:21",
      "nmapOperatingSystem": "Linux 2.6.32 (accuracy 96%)",
      "assetIpAddress": "172.16.102.121",
      "hardwareManufacturer": null,
      "dot1xAuthAuthControlledPortControl": "2",
      "oui": "NEXO COMMUNICATIONS, INC.",
      "classificationSource": {},
      "nasPortId": "GigabitEthernet1/0/2",
      "assetVendor": "",
      "framedIpAddress": "172.16.102.121",
      "portalUser": "",
      "trustScore": null,
      "nasIdentifier": "Edge",
      "nasIpAddress": "192.168.120.1",
      "clientHealthAvailable": true,
      "userAgent": "Debian APT-HTTP/1.3 (1.6.12ubuntu0.1)",
      "userName": "005000000121",
      
      "tcp22": "ssh",
      "assetMacAddress": "00:50:00:00:01:21",
      "dhcpHostName": "",
      "assetDeviceType": "",
      "location": "SJC04/SJC04-2",
      "dot1xAuthAuthControlledPortStatus": "1",
      "ifOperStatus": "1",
      "callingStationId": "00-50-00-00-01-21"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "ifIndex": "10",
      "matchedRules": {},
      "assetHwRevision": "",
      "operatingSystemResult": "Linux 2.6.32 (accuracy 96%)",
      "dot1xAuthSessionUserName": "00-50-00-00-01-21",
      
      "framedIpv6Address": "fe80::250:ff:fe00:122",
      "connectedDeviceId": "5e332de1-446d-4331-9c6a-cfee30915a2e",
      "operatingSystem": [],
      "ifDescr": "GigabitEthernet1/0/2",
      "apName": "Edge.demo.local",
      "udp161": "snmp",
      "udp162": "snmptrap",
      "calledStationId": "DC-F7-19-50-97-02",
      "deviceType": [],
      "endpointSource": "RADIUS Probe",
      "hardwareModel": [],
      "ip": "172.16.102.122",
      "phoneId": "",
      "assetSwRevision": "",
      "nasPort": "50102",
      "macAddress": "00:50:00:00:01:22",
      "nmapOperatingSystem": "Linux 2.6.32 (accuracy 96%)",
      "assetIpAddress": "172.16.102.122",
      "hardwareManufacturer": null,
      "dot1xAuthAuthControlledPortControl": "2",
      "oui": "NEXO COMMUNICATIONS, INC.",
      "classificationSource": {},
      "nasPortId": "GigabitEthernet1/0/2",
      "assetVendor": "",
      "framedIpAddress": "172.16.102.122",
      "portalUser": "",
      "trustScore": null,
      "nasIdentifier": "Edge",
      "nasIpAddress": "192.168.120.1",
      "clientHealthAvailable": true,
      "userAgent": "Debian APT-HTTP/1.3 (1.6.12ubuntu0.1)",
      "userName": "00-50-00-00-01-22",
      
      "tcp22": "ssh",
      "assetMacAddress": "00:50:00:00:01:22",
      "dhcpHostName": "",
      "assetDeviceType": "",
      "location": "SJC04/SJC04-2",
      "dot1xAuthAuthControlledPortStatus": "1",
      "ifOperStatus": "1",
      "callingStationId": "00-50-00-00-01-22"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "ifIndex": "10",
      "matchedRules": {},
      "assetHwRevision": "",
      "operatingSystemResult": "Linux 2.6.32 (accuracy 96%)",
      "dot1xAuthSessionUserName": "00-50-00-00-01-21",
      
      "framedIpv6Address": "fe80::250:ff:fe00:123",
      "connectedDeviceId": "5e332de1-446d-4331-9c6a-cfee30915a2e",
      "operatingSystem": [],
      "ifDescr": "GigabitEthernet1/0/2",
      "apName": "Edge.demo.local",
      "udp161": "snmp",
      "udp162": "snmptrap",
      "calledStationId": "DC-F7-19-50-97-02",
      "deviceType": [],
      "endpointSource": "RADIUS Probe",
      "hardwareModel": [],
      "ip": "172.16.102.123",
      "phoneId": "",
      "assetSwRevision": "",
      "nasPort": "50102",
      "macAddress": "00:50:00:00:01:23",
      "nmapOperatingSystem": "Linux 2.6.32 (accuracy 96%)",
      "assetIpAddress": "172.16.102.123",
      "hardwareManufacturer": null,
      "dot1xAuthAuthControlledPortControl": "2",
      "oui": "NEXO COMMUNICATIONS, INC.",
      "classificationSource": {},
      "nasPortId": "GigabitEthernet1/0/2",
      "assetVendor": "",
      "framedIpAddress": "172.16.102.123",
      "portalUser": "",
      "trustScore": null,
      "nasIdentifier": "Edge",
      "nasIpAddress": "192.168.120.1",
      "clientHealthAvailable": true,
      "userAgent": "Debian APT-HTTP/1.3 (1.6.12ubuntu0.1)",
      "userName": "00-50-00-00-01-23",
      
      "tcp22": "ssh",
      "assetMacAddress": "00:50:00:00:01:23",
      "dhcpHostName": "",
      "assetDeviceType": "",
      "location": "SJC04/SJC04-2",
      "dot1xAuthAuthControlledPortStatus": "1",
      "ifOperStatus": "1",
      "callingStationId": "00-50-00-00-01-23"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "ifIndex": "10",
      "matchedRules": {},
      "assetHwRevision": "",
      "operatingSystemResult": "Linux 2.6.32 (accuracy 96%)",
      "dot1xAuthSessionUserName": "00-50-00-00-01-21",
      
      "framedIpv6Address": "fe80::250:ff:fe00:124",
      "connectedDeviceId": "5e332de1-446d-4331-9c6a-cfee30915a2e",
      "operatingSystem": [],
      "ifDescr": "GigabitEthernet1/0/2",
      "apName": "Edge.demo.local",
      "udp161": "snmp",
      "udp162": "snmptrap",
      "calledStationId": "DC-F7-19-50-97-02",
      "deviceType": [],
      "endpointSource": "RADIUS Probe",
      "hardwareModel": [],
      "ip": "172.16.102.124",
      "phoneId": "",
      "assetSwRevision": "",
      "nasPort": "50102",
      "macAddress": "00:50:00:00:01:24",
      "nmapOperatingSystem": "Linux 2.6.32 (accuracy 96%)",
      "assetIpAddress": "172.16.102.124",
      "hardwareManufacturer": null,
      "dot1xAuthAuthControlledPortControl": "2",
      "oui": "NEXO COMMUNICATIONS, INC.",
      "classificationSource": {},
      "nasPortId": "GigabitEthernet1/0/2",
      "assetVendor": "",
      "framedIpAddress": "172.16.102.124",
      "portalUser": "",
      "trustScore": null,
      "nasIdentifier": "Edge",
      "nasIpAddress": "192.168.120.1",
      "clientHealthAvailable": true,
      "userAgent": "Debian APT-HTTP/1.3 (1.6.12ubuntu0.1)",
      "userName": "00-50-00-00-01-24",
      
      "tcp22": "ssh",
      "assetMacAddress": "00:50:00:00:01:24",
      "dhcpHostName": "",
      "assetDeviceType": "",
      "location": "SJC04/SJC04-2",
      "dot1xAuthAuthControlledPortStatus": "2",
      "ifOperStatus": "1",
      "callingStationId": "00-50-00-00-01-24"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "10",
      "oui": "NEXO COMMUNICATIONS, INC.",
      "matchedRules": {},
      "assetHwRevision": "",
      "dot1xAuthSessionUserName": "00-50-00-00-01-21",
      "classificationSource": {},
      "nasPortId": "GigabitEthernet1/0/2",
      
      "assetVendor": "",
      "framedIpv6Address": "",
      "connectedDeviceId": "5e332de1-446d-4331-9c6a-cfee30915a2e",
      "framedIpAddress": "172.16.102.125",
      "operatingSystem": [],
      "ifDescr": "GigabitEthernet1/0/2",
      "apName": "Edge.demo.local",
      "portalUser": "",
      "trustScore": null,
      "nasIdentifier": "Edge",
      "calledStationId": "DC-F7-19-50-97-02",
      "nasIpAddress": "192.168.120.1",
      "deviceType": [],
      "endpointSource": "RADIUS Probe",
      "hardwareModel": [],
      "ip": "172.16.102.125",
      "phoneId": "",
      "assetSwRevision": "",
      "clientHealthAvailable": true,
      "userAgent": "Debian APT-HTTP/1.3 (1.6.12ubuntu0.1)",
      "userName": "00-50-00-00-01-25",
      "nasPort": "50102",
      
      "macAddress": "00:50:00:00:01:25",
      "assetMacAddress": "00:50:00:00:01:25",
      "dhcpHostName": "",
      "assetIpAddress": "172.16.102.125",
      "hardwareManufacturer": null,
      "assetDeviceType": "",
      "location": "SJC04/SJC04-2",
      "dot1xAuthAuthControlledPortStatus": "1",
      "ifOperStatus": "1",
      "callingStationId": "00-50-00-00-01-25"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "10",
      "oui": "VMware, Inc.",
      "matchedRules": {
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        }
      },
      "assetHwRevision": "VMWare-Device",
      "dot1xAuthSessionUserName": "00-50-56-A3-70-46",
      "classificationSource": {
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/2",
      
      "assetVendor": "VMware, Inc.",
      "framedIpv6Address": "",
      "framedIpAddress": "",
      "operatingSystem": null,
      "ifDescr": "GigabitEthernet1/0/2",
      "portalUser": "",
      "trustScore": null,
      "nasIdentifier": "Edge",
      "calledStationId": "EC-1D-8B-99-7E-02",
      "nasIpAddress": "192.168.120.1",
      "deviceType": null,
      "endpointSource": "pxGrid Probe",
      "hardwareModel": "VMWare-Device",
      "ip": "",
      "phoneId": "",
      "assetSwRevision": "",
      "clientHealthAvailable": false,
      "userName": "00-50-56-A3-70-46",
      "nasPort": "50102",
      
      "macAddress": "00:50:56:A3:70:46",
      "assetMacAddress": "00:50:56:A3:70:46",
      "dhcpHostName": "",
      "assetIpAddress": "",
      "hardwareManufacturer": "VMware, Inc.",
      "assetDeviceType": "",
      "dot1xAuthAuthControlledPortStatus": "2",
      "ifOperStatus": "1",
      "callingStationId": "00-50-56-A3-70-46"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "9",
      "oui": "VMware, Inc.",
      "matchedRules": {
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        }
      },
      "assetHwRevision": "VMWare-Device",
      "dot1xAuthSessionUserName": "00-50-00-00-01-11",
      "classificationSource": {
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/1",
      
      "assetVendor": "VMware, Inc.",
      "framedIpv6Address": "",
      "framedIpAddress": "",
      "operatingSystem": null,
      "ifDescr": "GigabitEthernet1/0/1",
      "portalUser": "",
      "trustScore": null,
      "nasIdentifier": "Edge",
      "calledStationId": "EC-1D-8B-99-7E-01",
      "nasIpAddress": "192.168.120.1",
      "deviceType": null,
      "endpointSource": "pxGrid Probe",
      "hardwareModel": "VMWare-Device",
      "ip": "",
      "phoneId": "",
      "assetSwRevision": "",
      "clientHealthAvailable": false,
      "userName": "00-50-56-A3-9C-5F",
      "nasPort": "50101",
      
      "macAddress": "00:50:56:A3:9C:5F",
      "assetMacAddress": "00:50:56:A3:9C:5F",
      "dhcpHostName": "",
      "assetIpAddress": "",
      "hardwareManufacturer": "VMware, Inc.",
      "assetDeviceType": "",
      "dot1xAuthAuthControlledPortStatus": "2",
      "ifOperStatus": "1",
      "callingStationId": "00-50-56-A3-9C-5F"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "9",
      "oui": "VMware, Inc.",
      "matchedRules": {
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        }
      },
      "assetHwRevision": "VMWare-Device",
      "dot1xAuthSessionUserName": "00-08-74-AD-F1-9B",
      "classificationSource": {
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/1",
      
      "assetVendor": "VMware, Inc.",
      "framedIpv6Address": "",
      "framedIpAddress": "",
      "operatingSystem": null,
      "ifDescr": "GigabitEthernet1/0/1",
      "portalUser": "",
      "trustScore": null,
      "nasIdentifier": "Edge",
      "calledStationId": "EC-1D-8B-99-7E-01",
      "nasIpAddress": "192.168.120.1",
      "deviceType": null,
      "endpointSource": "pxGrid Probe",
      "hardwareModel": "VMWare-Device",
      "ip": "",
      "phoneId": "",
      "assetSwRevision": "",
      "clientHealthAvailable": false,
      "userName": "00-50-56-A7-D5-3C",
      "nasPort": "50101",
      
      "macAddress": "00:50:56:A7:D5:3C",
      "assetMacAddress": "00:50:56:A7:D5:3C",
      "dhcpHostName": "",
      "assetIpAddress": "",
      "hardwareManufacturer": "VMware, Inc.",
      "assetDeviceType": "",
      "dot1xAuthAuthControlledPortStatus": "2",
      "ifOperStatus": "1",
      "callingStationId": "00-50-56-A7-D5-3C"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Framed",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "10",
      "oui": "VMware, Inc.",
      "matchedRules": {
        "deviceType": {
          "ruleType": "System",
          "ruleName": "Windows Dhcp Class Identifier Rule",
          "ruleId": "windowsDhcpClassIdentifierRule"
        },
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Microsoft Workstation Os Rule",
          "ruleId": "microsoftWorkstationOsRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        },
        "operatingSystem": {
          "ruleType": "System",
          "ruleName": "Windows Dhcp Class Identifier Rule",
          "ruleId": "windowsDhcpClassIdentifierRule"
        }
      },
      "assetHwRevision": "Microsoft-Workstation",
      "dot1xAuthSessionUserName": "00-50-00-00-01-21",
      "classificationSource": {
        "deviceType": "RULE ENGINE",
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE",
        "operatingSystem": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/2",
      
      "assetVendor": "VMware, Inc.",
      "framedIpv6Address": "",
      "dhcpClassIdentifier": "MSFT 5.0",
      "connectedDeviceId": "5e332de1-446d-4331-9c6a-cfee30915a2e",
      "framedIpAddress": "172.16.101.203",
      "operatingSystem": "Windows",
      "ifDescr": "GigabitEthernet1/0/2",
      "apName": "Edge.demo.local",
      "portalUser": "",
      "trustScore": null,
      "nasIdentifier": "Edge",
      "calledStationId": "DC-F7-19-50-97-02",
      "nasIpAddress": "192.168.120.1",
      "deviceType": "Workstation",
      "endpointSource": "RADIUS Probe",
      "hardwareModel": "Microsoft-Workstation",
      "ip": "172.16.101.203",
      "phoneId": "",
      "assetSwRevision": "Windows",
      "clientHealthAvailable": true,
      "userAgent": "Microsoft-CryptoAPI/10.0",
      "userName": "employee2",
      "nasPort": "50102",
      
      "macAddress": "00:50:56:AE:18:87",
      "dhcpParameterRequestList": "1, 3, 6, 15, 31, 33, 43, 44, 46, 47, 119, 121, 249, 252",
      "assetMacAddress": "00:50:56:AE:18:87",
      "dhcpHostName": "wx-emp2",
      "assetIpAddress": "172.16.101.203",
      "hardwareManufacturer": "VMware, Inc.",
      "assetDeviceType": "Workstation",
      "location": "SJC04/SJC04-2",
      "dot1xAuthAuthControlledPortStatus": "1",
      "ifOperStatus": "1",
      "mdnsHostName": "wx-emp2",
      "callingStationId": "00-50-56-AE-18-87"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "9",
      "oui": "VMware, Inc.",
      "matchedRules": {
        "deviceType": {
          "ruleType": "System",
          "ruleName": "Windows Dhcp Class Identifier Rule",
          "ruleId": "windowsDhcpClassIdentifierRule"
        },
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Microsoft Workstation Os Rule",
          "ruleId": "microsoftWorkstationOsRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        },
        "operatingSystem": {
          "ruleType": "System",
          "ruleName": "Windows Dhcp Class Identifier Rule",
          "ruleId": "windowsDhcpClassIdentifierRule"
        }
      },
      "assetHwRevision": "Microsoft-Workstation",
      "dot1xAuthSessionUserName": "00-50-00-00-01-11",
      "classificationSource": {
        "deviceType": "RULE ENGINE",
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE",
        "operatingSystem": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/1",
      
      "assetVendor": "VMware, Inc.",
      "framedIpv6Address": "",
      "dhcpClassIdentifier": "MSFT 5.0",
      "framedIpAddress": "172.16.102.204",
      "operatingSystem": "Windows",
      "ifDescr": "GigabitEthernet1/0/1",
      "portalUser": "",
      "trustScore": null,
      "nasIdentifier": "Edge",
      "calledStationId": "DC-F7-19-50-97-01",
      "nasIpAddress": "192.168.120.1",
      "deviceType": "Workstation",
      "endpointSource": "RADIUS Probe",
      "hardwareModel": "Microsoft-Workstation",
      "ip": "172.16.102.204",
      "phoneId": "",
      "assetSwRevision": "Windows",
      "clientHealthAvailable": false,
      "userAgent": "Microsoft NCSI",
      "userName": "00-50-56-AE-20-E0",
      "nasPort": "50101",
      
      "macAddress": "00:50:56:AE:20:E0",
      "dhcpParameterRequestList": "1, 3, 6, 15, 31, 33, 43, 44, 46, 47, 119, 121, 249, 252",
      "assetMacAddress": "00:50:56:AE:20:E0",
      "dhcpHostName": "DESKTOP-FFKLA27",
      "assetIpAddress": "172.16.102.204",
      "hardwareManufacturer": "VMware, Inc.",
      "assetDeviceType": "Workstation",
      "dot1xAuthAuthControlledPortStatus": "1",
      "ifOperStatus": "1",
      "mdnsHostName": "DESKTOP-FFKLA27",
      "callingStationId": "00-50-56-AE-20-E0"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "10",
      "oui": "VMware, Inc.",
      "matchedRules": {
        "deviceType": {
          "ruleType": "System",
          "ruleName": "Windows Dhcp Class Identifier Rule",
          "ruleId": "windowsDhcpClassIdentifierRule"
        },
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Microsoft Workstation Os Rule",
          "ruleId": "microsoftWorkstationOsRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        },
        "operatingSystem": {
          "ruleType": "System",
          "ruleName": "Windows Dhcp Class Identifier Rule",
          "ruleId": "windowsDhcpClassIdentifierRule"
        }
      },
      "assetHwRevision": "Microsoft-Workstation",
      "dot1xAuthSessionUserName": "00-50-00-00-01-21",
      "classificationSource": {
        "deviceType": "RULE ENGINE",
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE",
        "operatingSystem": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/2",
      
      "assetVendor": "VMware, Inc.",
      "framedIpv6Address": "",
      "dhcpClassIdentifier": "MSFT 5.0",
      "framedIpAddress": "172.16.102.203",
      "operatingSystem": "Windows",
      "ifDescr": "GigabitEthernet1/0/2",
      "portalUser": "",
      "trustScore": null,
      "nasIdentifier": "Edge",
      "calledStationId": "DC-F7-19-50-97-02",
      "nasIpAddress": "192.168.120.1",
      "deviceType": "Workstation",
      "endpointSource": "RADIUS Probe",
      "hardwareModel": "Microsoft-Workstation",
      "ip": "172.16.102.203",
      "phoneId": "",
      "assetSwRevision": "Windows",
      "clientHealthAvailable": false,
      "userAgent": "Microsoft NCSI",
      "userName": "005056ae3121",
      "nasPort": "50102",
      
      "macAddress": "00:50:56:AE:31:21",
      "dhcpParameterRequestList": "1, 3, 6, 15, 31, 33, 43, 44, 46, 47, 119, 121, 249, 252",
      "assetMacAddress": "00:50:56:AE:31:21",
      "dhcpHostName": "DESKTOP-3QQGRCT",
      "assetIpAddress": "172.16.102.203",
      "hardwareManufacturer": "VMware, Inc.",
      "assetDeviceType": "Workstation",
      "dot1xAuthAuthControlledPortStatus": "1",
      "ifOperStatus": "1",
      "mdnsHostName": "DESKTOP-3QQGRCT",
      "callingStationId": "00-50-56-AE-31-21"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "9",
      "oui": "VMware, Inc.",
      "matchedRules": {
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        }
      },
      "dot1xAuthSessionUserName": "employee1",
      "classificationSource": {
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/1",
      
      "framedIpv6Address": "",
      "framedIpAddress": "",
      "operatingSystem": null,
      "ifDescr": "GigabitEthernet1/0/1",
      "portalUser": "",
      "trustScore": null,
      "nasIdentifier": "Edge",
      "calledStationId": "DC-F7-19-50-97-01",
      "nasIpAddress": "192.168.120.1",
      "deviceType": null,
      "endpointSource": "RADIUS Probe",
      "hardwareModel": "VMWare-Device",
      "ip": "",
      "phoneId": "",
      "clientHealthAvailable": false,
      "userName": "005056ae5966",
      "nasPort": "50101",
      
      "macAddress": "00:50:56:AE:59:66",
      "dhcpHostName": "",
      "hardwareManufacturer": "VMware, Inc.",
      "dot1xAuthAuthControlledPortStatus": "2",
      "ifOperStatus": "1",
      "callingStationId": "00-50-56-AE-59-66"
    },
    {
      "deviceType": null,
      "oui": "VMware, Inc.",
      "matchedRules": {
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        }
      },
      "hardwareModel": "VMWare-Device",
      "classificationSource": {
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE"
      },
      "ip": "172.16.102.113",
      
      "clientHealthAvailable": false,
      "operatingSystem": null,
      
      "macAddress": "00:50:56:AE:5E:6C",
      "trustScore": null,
      "dhcpHostName": "",
      "hardwareManufacturer": "VMware, Inc."
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "10",
      "oui": "VMware, Inc.",
      "matchedRules": {
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        }
      },
      "assetHwRevision": "VMWare-Device",
      "dot1xAuthSessionUserName": "employee2",
      "classificationSource": {
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/2",
      
      "assetVendor": "VMware, Inc.",
      "framedIpv6Address": "",
      "framedIpAddress": "",
      "operatingSystem": null,
      "ifDescr": "GigabitEthernet1/0/2",
      "portalUser": "",
      "trustScore": null,
      "nasIdentifier": "Edge",
      "calledStationId": "DC-F7-19-50-97-02",
      "nasIpAddress": "192.168.120.1",
      "deviceType": null,
      "endpointSource": "pxGrid Probe",
      "hardwareModel": "VMWare-Device",
      "ip": "",
      "phoneId": "",
      "assetSwRevision": "",
      "clientHealthAvailable": false,
      "userName": "00-50-56-AE-69-42",
      "nasPort": "50102",
      
      "macAddress": "00:50:56:AE:69:42",
      "assetMacAddress": "00:50:56:AE:69:42",
      "dhcpHostName": "",
      "assetIpAddress": "",
      "hardwareManufacturer": "VMware, Inc.",
      "assetDeviceType": "",
      "dot1xAuthAuthControlledPortStatus": "2",
      "ifOperStatus": "1",
      "callingStationId": "00-50-56-AE-69-42"
    },
    {
      "deviceType": null,
      "oui": "VMware, Inc.",
      "matchedRules": {
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        }
      },
      "hardwareModel": "VMWare-Device",
      "classificationSource": {
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE"
      },
      "ip": "172.16.102.115",
      
      "clientHealthAvailable": false,
      "operatingSystem": null,
      
      "macAddress": "00:50:56:AE:80:90",
      "trustScore": null,
      "dhcpHostName": "",
      "hardwareManufacturer": "VMware, Inc."
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "11",
      "oui": "VMware, Inc.",
      "matchedRules": {
        "deviceType": {
          "ruleType": "System",
          "ruleName": "Windows Dhcp Class Identifier Rule",
          "ruleId": "windowsDhcpClassIdentifierRule"
        },
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Microsoft Workstation Os Rule",
          "ruleId": "microsoftWorkstationOsRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        },
        "operatingSystem": {
          "ruleType": "System",
          "ruleName": "Windows Dhcp Class Identifier Rule",
          "ruleId": "windowsDhcpClassIdentifierRule"
        }
      },
      "assetHwRevision": "Microsoft-Workstation",
      "dot1xAuthSessionUserName": "00-50-56-AE-99-BB",
      "classificationSource": {
        "deviceType": "RULE ENGINE",
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE",
        "operatingSystem": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/3",
      
      "assetVendor": "VMware, Inc.",
      "framedIpv6Address": "",
      "dhcpClassIdentifier": "MSFT 5.0",
      "framedIpAddress": "",
      "operatingSystem": "Windows",
      "ifDescr": "GigabitEthernet1/0/3",
      "portalUser": "",
      "trustScore": null,
      "nasIdentifier": "Edge",
      "calledStationId": "DC-F7-19-50-97-03",
      "nasIpAddress": "192.168.120.1",
      "deviceType": "Workstation",
      "endpointSource": "pxGrid Probe",
      "hardwareModel": "Microsoft-Workstation",
      "ip": "169.254.199.230",
      "phoneId": "",
      "assetSwRevision": "Windows",
      "clientHealthAvailable": false,
      "userAgent": "Microsoft NCSI",
      "userName": "00-50-56-AE-99-BB",
      "nasPort": "50103",
      
      "macAddress": "00:50:56:AE:99:BB",
      "dhcpParameterRequestList": "1, 3, 6, 15, 31, 33, 43, 44, 46, 47, 119, 121, 249, 252",
      "assetMacAddress": "00:50:56:AE:99:BB",
      "dhcpHostName": "DESKTOP-3QQGRCT",
      "assetIpAddress": "169.254.199.230",
      "hardwareManufacturer": "VMware, Inc.",
      "assetDeviceType": "Workstation",
      "dot1xAuthAuthControlledPortStatus": "2",
      "ifOperStatus": "1",
      "mdnsHostName": "DESKTOP-3QQGRCT",
      "callingStationId": "00-50-56-AE-99-BB"
    },
    {
      "deviceType": null,
      "oui": "VMware, Inc.",
      "matchedRules": {
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        }
      },
      "hardwareModel": "VMWare-Device",
      "classificationSource": {
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE"
      },
      "ip": "172.16.102.123",
      
      "clientHealthAvailable": false,
      "operatingSystem": null,
      
      "macAddress": "00:50:56:AE:B3:B4",
      "trustScore": null,
      "dhcpHostName": "",
      "hardwareManufacturer": "VMware, Inc."
    },
    {
      "deviceType": null,
      "oui": "VMware, Inc.",
      "matchedRules": {
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        }
      },
      "hardwareModel": "VMWare-Device",
      "classificationSource": {
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE"
      },
      "ip": "172.16.102.125",
      
      "clientHealthAvailable": false,
      "operatingSystem": null,
      
      "macAddress": "00:50:56:AE:B7:4E",
      "trustScore": null,
      "dhcpHostName": "",
      "hardwareManufacturer": "VMware, Inc."
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Framed",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "9",
      "oui": "VMware, Inc.",
      "matchedRules": {
        "deviceType": {
          "ruleType": "System",
          "ruleName": "Windows Dhcp Class Identifier Rule",
          "ruleId": "windowsDhcpClassIdentifierRule"
        },
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Microsoft Workstation Os Rule",
          "ruleId": "microsoftWorkstationOsRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        },
        "operatingSystem": {
          "ruleType": "System",
          "ruleName": "Windows Dhcp Class Identifier Rule",
          "ruleId": "windowsDhcpClassIdentifierRule"
        }
      },
      "assetHwRevision": "Microsoft-Workstation",
      "dot1xAuthSessionUserName": "00-50-00-00-01-11",
      "classificationSource": {
        "deviceType": "RULE ENGINE",
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE",
        "operatingSystem": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/1",
      
      "assetVendor": "VMware, Inc.",
      "framedIpv6Address": "",
      "dhcpClassIdentifier": "MSFT 5.0",
      "connectedDeviceId": "5e332de1-446d-4331-9c6a-cfee30915a2e",
      "framedIpAddress": "172.16.101.204",
      "operatingSystem": "Windows",
      "ifDescr": "GigabitEthernet1/0/1",
      "apName": "Edge.demo.local",
      "portalUser": "",
      "trustScore": null,
      "nasIdentifier": "Edge",
      "calledStationId": "DC-F7-19-50-97-01",
      "nasIpAddress": "192.168.120.1",
      "deviceType": "Workstation",
      "endpointSource": "RADIUS Probe",
      "hardwareModel": "Microsoft-Workstation",
      "ip": "172.16.101.204",
      "phoneId": "",
      "assetSwRevision": "Windows",
      "clientHealthAvailable": true,
      "userAgent": "Microsoft NCSI",
      "userName": "employee1",
      "nasPort": "50101",
      
      "macAddress": "00:50:56:AE:C0:5E",
      "dhcpParameterRequestList": "1, 3, 6, 15, 31, 33, 43, 44, 46, 47, 119, 121, 249, 252",
      "assetMacAddress": "00:50:56:AE:C0:5E",
      "dhcpHostName": "wx-emp1",
      "assetIpAddress": "172.16.101.204",
      "hardwareManufacturer": "VMware, Inc.",
      "assetDeviceType": "Workstation",
      "location": "SJC04/SJC04-2",
      "dot1xAuthAuthControlledPortStatus": "2",
      "ifOperStatus": "1",
      "mdnsHostName": "wx-emp1",
      "callingStationId": "00-50-56-AE-C0-5E"
    },
    {
      "deviceType": null,
      "oui": "VMware, Inc.",
      "matchedRules": {
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        }
      },
      "hardwareModel": "VMWare-Device",
      "classificationSource": {
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE"
      },
      "ip": "172.16.102.122",
      
      "clientHealthAvailable": false,
      "operatingSystem": null,
      
      "macAddress": "00:50:56:AE:C1:CB",
      "trustScore": null,
      "dhcpHostName": "",
      "hardwareManufacturer": "VMware, Inc."
    },
    {
      "deviceType": null,
      "oui": "VMware, Inc.",
      "matchedRules": {
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        }
      },
      "hardwareModel": "VMWare-Device",
      "classificationSource": {
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE"
      },
      "ip": "172.16.102.124",
      
      "clientHealthAvailable": false,
      "operatingSystem": null,
      
      "macAddress": "00:50:56:AE:D5:0F",
      "trustScore": null,
      "dhcpHostName": "",
      "hardwareManufacturer": "VMware, Inc."
    },
    {
      "deviceType": null,
      "oui": "VMware, Inc.",
      "matchedRules": {
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        }
      },
      "hardwareModel": "VMWare-Device",
      "classificationSource": {
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE"
      },
      "ip": "172.16.102.114",
      
      "clientHealthAvailable": false,
      "operatingSystem": null,
      
      "macAddress": "00:50:56:AE:DD:08",
      "trustScore": null,
      "dhcpHostName": "",
      "hardwareManufacturer": "VMware, Inc."
    },
    {
      "deviceType": null,
      "oui": "VMware, Inc.",
      "matchedRules": {
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        }
      },
      "hardwareModel": "VMWare-Device",
      "classificationSource": {
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE"
      },
      "ip": "172.16.102.111",
      
      "clientHealthAvailable": false,
      "operatingSystem": null,
      
      "macAddress": "00:50:56:AE:DF:71",
      "trustScore": null,
      "dhcpHostName": "",
      "hardwareManufacturer": "VMware, Inc."
    },
    {
      "deviceType": null,
      "oui": "VMware, Inc.",
      "matchedRules": {
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Vmware Device Mac Oui Rule",
          "ruleId": "vmwareDeviceMacOuiRule"
        }
      },
      "hardwareModel": "VMWare-Device",
      "classificationSource": {
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE"
      },
      "ip": "172.16.102.121",
      
      "clientHealthAvailable": false,
      "operatingSystem": null,
      
      "macAddress": "00:50:56:AE:F8:F4",
      "trustScore": null,
      "dhcpHostName": "",
      "hardwareManufacturer": "VMware, Inc."
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Framed",
      "ifIndex": "12",
      "matchedRules": {
        "deviceType": {
          "ruleType": "System",
          "ruleName": "Cisco Ip Phone Dhcp Class Identifier Rule 2",
          "ruleId": "ciscoIpPhoneDhcpIdentifierRule2"
        },
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Cisco Ip Phone Dhcp Class Identifier Rule 2",
          "ruleId": "ciscoIpPhoneDhcpIdentifierRule2"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Cisco Ip Phone Dhcp Class Identifier Rule 2",
          "ruleId": "ciscoIpPhoneDhcpIdentifierRule2"
        },
        "operatingSystem": {
          "ruleType": "System",
          "ruleName": "Cisco Ip Phone Dhcp Class Identifier Rule 2",
          "ruleId": "ciscoIpPhoneDhcpIdentifierRule2"
        }
      },
      "assetHwRevision": "Cisco-Device=>Cisco-IP-Phone=>Cisco IP Phone 9971",
      "dot1xAuthSessionUserName": "CP-9971-SEP00af1f9db4a0",
      
      "framedIpv6Address": "fe80::2af:1fff:fe9d:b4a0",
      "connectedDeviceId": "5e332de1-446d-4331-9c6a-cfee30915a2e",
      "operatingSystem": "Cisco IP Phone",
      "ifDescr": "GigabitEthernet1/0/4",
      "apName": "Edge.demo.local",
      "calledStationId": "DC-F7-19-50-97-04",
      "cdpCacheCapabilities": "1168",
      "deviceType": "IP Phone",
      "endpointSource": "RADIUS Probe",
      "hardwareModel": "Cisco IP Phone 9971",
      "ip": "172.16.103.201",
      "phoneId": "",
      "assetSwRevision": "Cisco IP Phone",
      "nasPort": "50104",
      "cdpCacheAddress": "172.16.103.201",
      "macAddress": "00:AF:1F:9E:B5:A0",
      "dhcpParameterRequestList": "1, 66, 6, 3, 15, 150, 35",
      "assetIpAddress": "172.16.103.201",
      "hardwareManufacturer": "Cisco Systems, Inc.",
      "cdpCachePlatform": "Cisco IP Phone 9971",
      "cdpCacheDeviceId": "SEP00AF1F9DA4B0",
      "dot1xAuthAuthControlledPortControl": "2",
      "oui": "Cisco Systems, Inc",
      "classificationSource": {
        "deviceType": "RULE ENGINE",
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE",
        "operatingSystem": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/4",
      "assetVendor": "Cisco Systems, Inc.",
      "dhcpClassIdentifier": "Cisco Systems, Inc. IP Phone CP-9971",
      "framedIpAddress": "172.16.103.201",
      "portalUser": "",
      "trustScore": null,
      "nasIdentifier": "Edge",
      "nasIpAddress": "192.168.120.1",
      "cdpCacheVersion": "sip9971.9-4-2SR4-1",
      "clientHealthAvailable": true,
      "userName": "CP-9971-SEP00af1f9db4a0",
      
      "assetMacAddress": "00:AF:1F:9D:B4:A0",
      "dhcpHostName": "SEP00AF1F9DB4A0",
      "assetDeviceType": "IP Phone",
      "location": "SJC04/SJC04-2",
      "dot1xAuthAuthControlledPortStatus": "1",
      "ifOperStatus": "1",
      "callingStationId": "00-AF-1F-9D-B4-A0"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "9",
      "oui": "PCS Systemtechnik GmbH",
      "matchedRules": {},
      "assetHwRevision": "",
      "dot1xAuthSessionUserName": "00-08-74-AD-F1-9B",
      "classificationSource": {},
      "nasPortId": "GigabitEthernet1/0/1",
      
      "assetVendor": "",
      "framedIpv6Address": "",
      "framedIpAddress": "",
      "operatingSystem": null,
      "ifDescr": "GigabitEthernet1/0/1",
      "udp161": "snmp",
      "portalUser": "",
      "trustScore": null,
      "udp162": "snmptrap",
      "nasIdentifier": "Edge",
      "calledStationId": "EC-1D-8B-99-7E-01",
      "nasIpAddress": "192.168.120.1",
      "deviceType": null,
      "endpointSource": "pxGrid Probe",
      "hardwareModel": null,
      "ip": "",
      "phoneId": "",
      "assetSwRevision": "",
      "clientHealthAvailable": false,
      "userName": "08-00-27-1D-A6-C2",
      "nasPort": "50101",
      
      "macAddress": "08:00:27:1D:A6:C2",
      "assetMacAddress": "08:00:27:1D:A6:C2",
      "dhcpHostName": "",
      "assetIpAddress": "",
      "hardwareManufacturer": null,
      "assetDeviceType": "",
      "dot1xAuthAuthControlledPortStatus": "2",
      "ifOperStatus": "1",
      "callingStationId": "08-00-27-1D-A6-C2"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "9",
      "oui": "Universal Global Scientific Industrial Co., Ltd.",
      "matchedRules": {},
      "assetHwRevision": "",
      "dot1xAuthSessionUserName": "00-08-74-AD-F1-9B",
      "classificationSource": {},
      "nasPortId": "GigabitEthernet1/0/1",
      
      "assetVendor": "",
      "framedIpv6Address": "",
      "framedIpAddress": "",
      "operatingSystem": null,
      "ifDescr": "GigabitEthernet1/0/1",
      "portalUser": "",
      "trustScore": null,
      "nasIdentifier": "Edge",
      "calledStationId": "EC-1D-8B-99-7E-01",
      "nasIpAddress": "192.168.120.1",
      "deviceType": null,
      "endpointSource": "pxGrid Probe",
      "hardwareModel": null,
      "ip": "",
      "phoneId": "",
      "assetSwRevision": "",
      "clientHealthAvailable": false,
      "userName": "40-2C-F4-E9-CA-F8",
      "nasPort": "50101",
      
      "macAddress": "40:2C:F4:E9:CA:F8",
      "assetMacAddress": "40:2C:F4:E9:CA:F8",
      "dhcpHostName": "",
      "assetIpAddress": "",
      "hardwareManufacturer": null,
      "assetDeviceType": "",
      "dot1xAuthAuthControlledPortStatus": "2",
      "ifOperStatus": "1",
      "callingStationId": "40-2C-F4-E9-CA-F8"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "9",
      "oui": "ecobee inc",
      "matchedRules": {
        "deviceType": {
          "ruleType": "System",
          "ruleName": "Ecobee Model Name Rule",
          "ruleId": "ecobeeModelNameRule"
        },
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Ecobee Model Name Rule",
          "ruleId": "ecobeeModelNameRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Ecobee Model Name Rule",
          "ruleId": "ecobeeModelNameRule"
        }
      },
      "assetHwRevision": "Ecobee-Device",
      "dot1xAuthSessionUserName": "00-50-00-00-01-11",
      "classificationSource": {
        "deviceType": "RULE ENGINE",
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/1",
      
      "assetVendor": "ecobee Inc.",
      "framedIpv6Address": "",
      "framedIpAddress": "",
      "operatingSystem": null,
      "ifDescr": "GigabitEthernet1/0/1",
      "portalUser": "",
      "trustScore": null,
      "ipv6": "FE80:0:0:0:4661:32FF:FEEA:D71",
      "nasIdentifier": "Edge",
      "calledStationId": "EC-1D-8B-99-7E-01",
      "nasIpAddress": "192.168.120.1",
      "deviceType": "Thermostat",
      "endpointSource": "pxGrid Probe",
      "hardwareModel": "ecobee3 lite",
      "mdnsMd": "ecobee3 lite",
      "ip": "",
      "phoneId": "",
      "assetSwRevision": "",
      "clientHealthAvailable": false,
      "userName": "44-61-32-EA-0D-71",
      "nasPort": "50101",
      
      "macAddress": "44:61:32:EA:0D:71",
      "assetMacAddress": "44:61:32:EA:0D:71",
      "dhcpHostName": "",
      "assetIpAddress": "",
      "hardwareManufacturer": "ecobee Inc.",
      "assetDeviceType": "IOT Device",
      "dot1xAuthAuthControlledPortStatus": "1",
      "ifOperStatus": "1",
      "mdnsHostName": "1-404-STUDIO",
      "callingStationId": "44-61-32-EA-0D-71"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "9",
      "oui": "Apple, Inc.",
      "matchedRules": {
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Apple Mac Oui Rule",
          "ruleId": "appleMacOuiRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Apple Mac Oui Rule",
          "ruleId": "appleMacOuiRule"
        }
      },
      "assetHwRevision": "Apple-Device",
      "dot1xAuthSessionUserName": "00-08-74-AD-F1-9B",
      "classificationSource": {
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/1",
      
      "assetVendor": "Apple, Inc.",
      "framedIpv6Address": "",
      "framedIpAddress": "",
      "operatingSystem": [],
      "ifDescr": "GigabitEthernet1/0/1",
      "portalUser": "",
      "trustScore": null,
      "nasIdentifier": "Edge",
      "calledStationId": "EC-1D-8B-99-7E-01",
      "nasIpAddress": "192.168.120.1",
      "deviceType": [],
      "endpointSource": "pxGrid Probe",
      "hardwareModel": "Apple-Device",
      "ip": "172.16.9.112",
      "phoneId": "",
      "assetSwRevision": "",
      "clientHealthAvailable": false,
      "userAgent": "itunesstored/1.0 iOS/7.0.6 model/iPhone3,1 (4; dt:27)",
      "userName": "48-60-BC-57-9A-BC",
      "nasPort": "50101",
      
      "macAddress": "48:60:BC:57:9A:BC",
      "assetMacAddress": "48:60:BC:57:9A:BC",
      "dhcpHostName": "",
      "assetIpAddress": "172.16.9.112",
      "hardwareManufacturer": "Apple, Inc.",
      "assetDeviceType": "",
      "dot1xAuthAuthControlledPortStatus": "2",
      "ifOperStatus": "1",
      "callingStationId": "48-60-BC-57-9A-BC"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "9",
      "oui": "Apple, Inc.",
      "matchedRules": {
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Apple Mac Oui Rule",
          "ruleId": "appleMacOuiRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Apple Mac Oui Rule",
          "ruleId": "appleMacOuiRule"
        }
      },
      "assetHwRevision": "Apple-Device",
      "dot1xAuthSessionUserName": "00-08-74-AD-F1-9B",
      "classificationSource": {
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/1",
      
      "assetVendor": "Apple, Inc.",
      "framedIpv6Address": "",
      "framedIpAddress": "",
      "operatingSystem": null,
      "ifDescr": "GigabitEthernet1/0/1",
      "portalUser": "",
      "trustScore": null,
      "nasIdentifier": "Edge",
      "calledStationId": "EC-1D-8B-99-7E-01",
      "nasIpAddress": "192.168.120.1",
      "deviceType": null,
      "endpointSource": "pxGrid Probe",
      "hardwareModel": "Apple-Device",
      "ip": "",
      "phoneId": "",
      "assetSwRevision": "",
      "clientHealthAvailable": false,
      "userName": "48746e01fc42",
      "nasPort": "50101",
      
      "macAddress": "48:74:6E:01:FC:42",
      "assetMacAddress": "48:74:6E:01:FC:42",
      "dhcpHostName": "",
      "assetIpAddress": "",
      "hardwareManufacturer": "Apple, Inc.",
      "assetDeviceType": "",
      "dot1xAuthAuthControlledPortStatus": "2",
      "ifOperStatus": "1",
      "callingStationId": "48-74-6E-01-FC-42"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "9",
      "dot1xAuthSessionUserName": "00-08-74-AD-F1-9B",
      "nasPortId": "GigabitEthernet1/0/1",
      "framedIpv6Address": "",
      "framedIpAddress": "",
      "operatingSystem": null,
      "ifDescr": "GigabitEthernet1/0/1",
      "portalUser": "",
      "trustScore": null,
      "nasIdentifier": "Edge",
      "calledStationId": "EC-1D-8B-99-7E-01",
      "nasIpAddress": "192.168.120.1",
      "deviceType": null,
      "endpointSource": "RADIUS Probe",
      "hardwareModel": null,
      "ip": "",
      "phoneId": "",
      "clientHealthAvailable": false,
      "userName": "84-8A-8D-A1-42-C6",
      "nasPort": "50101",
      
      "macAddress": "84:8A:8D:A1:42:C6",
      "dhcpHostName": "",
      "hardwareManufacturer": null,
      "dot1xAuthAuthControlledPortStatus": "2",
      "ifOperStatus": "1",
      "callingStationId": "84-8A-8D-A1-42-C6"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "9",
      "dot1xAuthSessionUserName": "00-08-74-AD-F1-9B",
      "nasPortId": "GigabitEthernet1/0/1",
      "framedIpv6Address": "",
      "framedIpAddress": "",
      "operatingSystem": null,
      "ifDescr": "GigabitEthernet1/0/1",
      "portalUser": "",
      "trustScore": null,
      "nasIdentifier": "Edge",
      "calledStationId": "EC-1D-8B-99-7E-01",
      "nasIpAddress": "192.168.120.1",
      "deviceType": null,
      "endpointSource": "RADIUS Probe",
      "hardwareModel": null,
      "ip": "",
      "phoneId": "",
      "clientHealthAvailable": false,
      "userName": "848a8da14646",
      "nasPort": "50101",
      
      "macAddress": "84:8A:8D:A1:46:46",
      "dhcpHostName": "",
      "hardwareManufacturer": null,
      "dot1xAuthAuthControlledPortStatus": "2",
      "ifOperStatus": "1",
      "callingStationId": "84-8A-8D-A1-46-46"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "9",
      "oui": "D-Link International",
      "matchedRules": {
        "deviceType": {
          "ruleType": "System",
          "ruleName": "Apple Iphone Model Name Rule",
          "ruleId": "appleIphoneModelNameRule"
        },
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Model Name Rule 1",
          "ruleId": "modelNameRule1"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Apple Iphone Model Name Rule",
          "ruleId": "appleIphoneModelNameRule"
        },
        "operatingSystem": {
          "ruleType": "System",
          "ruleName": "Mac iOS Model Name Rule",
          "ruleId": "macIOsModelNameRule"
        }
      },
      "assetHwRevision": "Apple-Device=>Apple-iPhone=>iPhone XR",
      "dot1xAuthSessionUserName": "00-08-74-AD-F1-9B",
      "classificationSource": {
        "deviceType": "RULE ENGINE",
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE",
        "operatingSystem": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/1",
      
      "assetVendor": "Apple, Inc.",
      "framedIpv6Address": "",
      "framedIpAddress": "",
      "operatingSystem": "iOS",
      "ifDescr": "GigabitEthernet1/0/1",
      "mdnsModelName": "N841AP  ",
      "portalUser": "",
      "trustScore": null,
      "nasIdentifier": "Edge",
      "calledStationId": "EC-1D-8B-99-7E-01",
      "nasIpAddress": "192.168.120.1",
      "deviceType": "Mobile Device",
      "endpointSource": "pxGrid Probe",
      "hardwareModel": "iPhone XR",
      "ip": "10.10.10.72",
      "phoneId": "",
      "assetSwRevision": "iOS",
      "clientHealthAvailable": false,
      "userName": "908d78a8e143",
      "nasPort": "50101",
      
      "macAddress": "90:8D:78:A8:E1:43",
      "assetMacAddress": "90:8D:78:A8:E1:43",
      "dhcpHostName": "",
      "assetIpAddress": "10.10.10.72",
      "hardwareManufacturer": "Apple, Inc.",
      "assetDeviceType": "Mobile Device",
      "dot1xAuthAuthControlledPortStatus": "2",
      "ifOperStatus": "1",
      "mdnsHostName": "iPhone2",
      "callingStationId": "90-8D-78-A8-E1-43"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "9",
      "oui": "Arcadyan Corporation",
      "matchedRules": {},
      "assetHwRevision": "",
      "dot1xAuthSessionUserName": "00-50-00-00-01-11",
      "classificationSource": {},
      "nasPortId": "GigabitEthernet1/0/1",
      
      "assetVendor": "",
      "framedIpv6Address": "",
      "framedIpAddress": "",
      "operatingSystem": [],
      "ifDescr": "GigabitEthernet1/0/1",
      "udp161": "snmp",
      "portalUser": "",
      "trustScore": null,
      "udp162": "snmptrap",
      "nasIdentifier": "Edge",
      "calledStationId": "EC-1D-8B-99-7E-01",
      "nasIpAddress": "192.168.120.1",
      "deviceType": [],
      "endpointSource": "pxGrid Probe",
      "hardwareModel": [],
      "ip": "192.168.1.26",
      "phoneId": "",
      "assetSwRevision": "",
      "clientHealthAvailable": false,
      "ssdpManufacturer": "LG Electronics",
      "userName": "946ab054356e",
      "nasPort": "50101",
      
      "macAddress": "94:6A:B0:54:35:6E",
      "assetMacAddress": "94:6A:B0:54:35:6E",
      "dhcpHostName": "",
      "assetIpAddress": "192.168.1.26",
      "hardwareManufacturer": [],
      "assetDeviceType": "",
      "dot1xAuthAuthControlledPortStatus": "1",
      "ifOperStatus": "1",
      "ssdpModel": "43UK6300YVB",
      "callingStationId": "94-6A-B0-54-35-6E"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "9",
      "oui": "GE Medical System(China) Co., Ltd.",
      "matchedRules": {
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "General Electric Device Mac Oui Rule 2",
          "ruleId": "General-Electric-Device-Rule2"
        }
      },
      "assetHwRevision": "General-Electric-Device",
      "dot1xAuthSessionUserName": "00-08-74-AD-F1-9B",
      "classificationSource": {
        "hardwareModel": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/1",
      
      "assetVendor": "",
      "framedIpv6Address": "",
      "framedIpAddress": "",
      "operatingSystem": null,
      "ifDescr": "GigabitEthernet1/0/1",
      "portalUser": "",
      "trustScore": null,
      "nasIdentifier": "Edge",
      "calledStationId": "EC-1D-8B-99-7E-01",
      "nasIpAddress": "192.168.120.1",
      "deviceType": null,
      "endpointSource": "pxGrid Probe",
      "hardwareModel": "General-Electric-Device",
      "ip": "",
      "phoneId": "",
      "assetSwRevision": "",
      "clientHealthAvailable": false,
      "userName": "a0f217170690",
      "nasPort": "50101",
      
      "macAddress": "A0:F2:17:17:06:90",
      "assetMacAddress": "A0:F2:17:17:06:90",
      "dhcpHostName": "",
      "assetIpAddress": "",
      "hardwareManufacturer": null,
      "assetDeviceType": "",
      "dot1xAuthAuthControlledPortStatus": "2",
      "ifOperStatus": "1",
      "callingStationId": "A0-F2-17-17-06-90"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "9",
      "oui": "Intel Corporate",
      "matchedRules": {
        "deviceType": {
          "ruleType": "System",
          "ruleName": "Microsoft Workstation Os Rule",
          "ruleId": "microsoftWorkstationOsRule"
        },
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Microsoft Workstation Os Rule",
          "ruleId": "microsoftWorkstationOsRule"
        },
        "operatingSystem": {
          "ruleType": "System",
          "ruleName": "Windows Os User Agent Rule",
          "ruleId": "windowsOsUserAgentRule"
        }
      },
      "assetHwRevision": "Microsoft-Workstation",
      "dot1xAuthSessionUserName": "00-50-00-00-01-11",
      "classificationSource": {
        "deviceType": "RULE ENGINE",
        "hardwareModel": "RULE ENGINE",
        "operatingSystem": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/1",
      
      "assetVendor": "",
      "framedIpv6Address": "",
      "framedIpAddress": "",
      "operatingSystem": "Windows 10 | Windows Server 2019 | Windows Server 2016",
      "ifDescr": "GigabitEthernet1/0/1",
      "portalUser": "",
      "trustScore": null,
      "nasIdentifier": "Edge",
      "calledStationId": "EC-1D-8B-99-7E-01",
      "nasIpAddress": "192.168.120.1",
      "deviceType": "Workstation",
      "endpointSource": "pxGrid Probe",
      "hardwareModel": "Microsoft-Workstation",
      "ip": "192.168.1.29",
      "phoneId": "",
      "assetSwRevision": "Windows=>Windows 10 | Windows Server 2019 | Windows Server 2016",
      "clientHealthAvailable": false,
      "userAgent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/79.0.3945.117 Safari/537.36",
      "userName": "D4-3B-04-C7-86-A7",
      "nasPort": "50101",
      
      "macAddress": "D4:3B:04:C7:86:A7",
      "assetMacAddress": "D4:3B:04:C7:86:A7",
      "dhcpHostName": "",
      "assetIpAddress": "192.168.1.29",
      "hardwareManufacturer": null,
      "assetDeviceType": "Workstation",
      "dot1xAuthAuthControlledPortStatus": "2",
      "ifOperStatus": "1",
      "callingStationId": "D4-3B-04-C7-86-A7"
    },
    {
      "nasPortType": "Ethernet",
      "serviceType": "Call Check",
      "dot1xAuthAuthControlledPortControl": "2",
      "ifIndex": "9",
      "oui": "Philips",
      "matchedRules": {
        "deviceType": {
          "ruleType": "System",
          "ruleName": "Medical GMDN Rule",
          "ruleId": "MedicalGMDNRule"
        },
        "hardwareModel": {
          "ruleType": "System",
          "ruleName": "Medical Brand And Model Rule",
          "ruleId": "MedicalBrandAndModelRule"
        },
        "hardwareManufacturer": {
          "ruleType": "System",
          "ruleName": "Medical Company Name Rule",
          "ruleId": "MedicalCompanyNameRule"
        }
      },
      "assetHwRevision": "EPIQ 7 DIAGNOSTIC ULTRASOUND SYSTEM 989605386721",
      "dot1xAuthSessionUserName": "00-08-74-AD-F1-9B",
      "classificationSource": {
        "deviceType": "RULE ENGINE",
        "hardwareModel": "RULE ENGINE",
        "hardwareManufacturer": "RULE ENGINE"
      },
      "nasPortId": "GigabitEthernet1/0/1",
      
      "assetVendor": "PHILIPS ULTRASOUND, INC.",
      "framedIpv6Address": "",
      "framedIpAddress": "",
      "operatingSystem": null,
      "ifDescr": "GigabitEthernet1/0/1",
      "portalUser": "",
      "hl7di": "(01)00884838047693",
      "trustScore": null,
      "nasIdentifier": "Edge",
      "calledStationId": "EC-1D-8B-99-7E-01",
      "nasIpAddress": "192.168.120.1",
      "deviceType": "General-purpose ultrasound imaging system",
      "endpointSource": "pxGrid Probe",
      "hardwareModel": "EPIQ 7 DIAGNOSTIC ULTRASOUND SYSTEM 989605386721",
      "ip": "10.0.0.155",
      "phoneId": "",
      "assetSwRevision": "",
      "clientHealthAvailable": false,
      "userName": "E8-C1-D7-17-06-90",
      "nasPort": "50101",
      
      "macAddress": "E8:C1:D7:17:06:90",
      "hl7oid": "2.16.840.1.113883.3.326",
      "hl7SymbologyIdentifier": "GS1",
      "assetMacAddress": "E8:C1:D7:17:06:90",
      "dhcpHostName": "",
      "assetIpAddress": "10.0.0.155",
      "hardwareManufacturer": "PHILIPS ULTRASOUND, INC.",
      "assetDeviceType": "Medical Device=>General-purpose ultrasound imaging system",
      "dot1xAuthAuthControlledPortStatus": "2",
      "ifOperStatus": "1",
      "callingStationId": "E8-C1-D7-17-06-90"
    }
  
  
  ],
  "Endpoints_Labels_Template":{
    "data": {
      "deviceType": [
        "CD Burner",
        "CT system workstation",
        "CT-SCanner",
        "CT-Scanner",
        "Camera",
        "Computed radiography digital imaging scanner",
        "Diagnostic x-ray digital imaging system workstation application software",
        "Gaming Device",
        "General-purpose ultrasound imaging system",
        "IOT Device",
        "IP Phone",
        "Indexed-immobilization patient positioning system",
        "Medical Device",
        "Medical image management system",
        "Medical video image recorder",
        "Mobile Device",
        "Network Device",
        "Nuclear medicine system workstation",
        "Picture Archiving and Communication Systems",
        "Printer",
        "Radiography digital imaging scanner",
        "Radiology DICOM image processing application software",
        "Radiology PACS software",
        "Stationary mammographic x-ray system, digital",
        "Storage",
        "Wireless Speaker",
        "Workstation",
        "stationary mammographic x-ray system, digital"
      ],
      "hardwareModel": [
        "802automation-Device",
        "AAEON-Technology-Device",
        "ABB-AB-Cewe-Control-Device",
        "ABB-AG-Device",
        "ABB-AS-Automation-Device",
        "ABB-Australia-Device",
        "ABB-Automation-Products-Device",
        "ABB-Bomem-Device",
        "ABB-Device",
        "ABB-Genway-Xiamen-Electrical-Device",
        "ABB-Global-Industries-Device",
        "ABB-Industrial-Systems-Device",
        "ABB-Ltd-Device",
        "ABB-Oy-Device",
        "ABB-Oy-Drives-Device",
        "ABB-Oy-Medium-Voltage-Device",
        "ABB-Power-Quality-Device",
        "ABB-Power-Systems-Generation-Device",
        "ABB-Power-Technologies-Device",
        "ABB-Power-Transmission-Distribution-Device",
        "ABB-SPA-SACE-Device",
        "ABB-Stotz-Kontakt-Device",
        "ABB-Switzerland-Device",
        "ABB-Switzerland-Power-Systems-Device",
        "ABB-Totalflow-Device",
        "ABB-Xiamen-Low-Voltage-Device",
        "ABB-Xiamen-Transmission-Distribution-Device",
        "ACTi-Corporation-Device",
        "AMSC-Device",
        "APG-Cash-Drawer-Device",
        "ATI-Industrial-Automation-Device",
        "AUMA-Riester-Device",
        "AVAT-Automation-Device",
        "AVTECH-Device",
        "Aastra-Device",
        "Abb-Tropos-Device",
        "Access-Control-Systems-JSC-Device",
        "Accu-Sort-Systems-Device",
        "Accu-Time-Systems-Device",
        "Acroloop-Motion-Control-Device",
        "Active-Control-Technology-Device",
        "Advanced-Control-Systems-Doo-Device",
        "Advanced-Control-Systems-Inc-Device",
        "Advanced-Illumination-Device",
        "Advanced-Micro-Controls-Device",
        "Advanced-Motion-Controls-Device",
        "Advantage-Controls-Device",
        "Advantech-Co-Device",
        "Advantech-Technologies-Device",
        "Advantech-Technology-Device",
        "Advatek-Lighting-Device",
        "Aerohive-Device",
        "Aiphone-Device",
        "Alfa-Laval-Automation-Device",
        "Alstom-Device",
        "Alstom-Grid-Device",
        "Alstom-Hydro-France-Device",
        "Alstom-Signalling-Device",
        "Alstom-Strongwish-Device",
        "Alstom-Transport-Device",
        "Altech-Controls-Device",
        "Amatis-Controls-Device",
        "Amazing-Controls-Device",
        "Amazon-Device",
        "Amazon-Echo",
        "American-Power-Conversion-Device",
        "Andover-Controls-Device",
        "Android-Motorola",
        "Anviz-Biometric-Tech-Device",
        "Apple-Airport",
        "Apple-Device",
        "Apple-MacBook",
        "Apple-TV",
        "Apple-Watch",
        "Apple-iMac",
        "Apple-iPad",
        "Apple-iPhone",
        "Apple-iPod",
        "Applera-Device",
        "Applied-Automation-Device",
        "Applied-Materials-Device",
        "Aquilion",
        "Arco-Electronic-Control-Device",
        "Arcom-Control-Systems-Device",
        "Arecont-Vision-Device",
        "Armour-Home-Electronics-Device",
        "Arris-Device",
        "Aruba-Device",
        "Assa-Abloy-Cote-Picarde-Device",
        "Assa-Abloy-Device",
        "Asus-Device",
        "Atrie-Device",
        "Audio-Code-Device",
        "AudioControl-Device",
        "August-Home-Device",
        "Autec-Device",
        "Automated-Logic-Device",
        "Automatic-Bar-Controls-Device",
        "Automation-Components-Device",
        "Automation-Electronics-Device",
        "Automation-Products-Group-Device",
        "Autronica-Fire-And-Security-Device",
        "Avaya-Device",
        "Aventura-Device",
        "Avigilon-Device",
        "Axis-Communications-Device",
        "Axis-Device",
        "BC-Illumination-Device",
        "BR-Controls-Nederland-Device",
        "BSD Workstation",
        "Baldor-Electric-Device",
        "Balogh-Device",
        "Balogh-TAG-Device",
        "Barco-Control-Controls-Device",
        "Basler-Device",
        "Basler-Electric-Device",
        "Bay-Controls-Device",
        "Beckhoff-Automation-Device",
        "Beckmann-Device",
        "Beckmann-Egle-Device",
        "Beijing-E3Control-Technology-Device",
        "Beijing-General-Research-Institute-Device",
        "Belkin-Device",
        "Berghof-Automation-Device",
        "Berkeley-Process-Control-Device",
        "Bharat-Electronics-Device",
        "BioControl-Device",
        "Biometric-Access-Company-Device",
        "Bioscrypt-Device",
        "Bitwise-Controls-Device",
        "BlackBerry",
        "Bonfiglioli-Vectron-Device",
        "Bosch-Access-Systems-Device",
        "Bosch-Automotive-Device",
        "Bosch-Coffee",
        "Bosch-Device",
        "Bosch-Rexroth-Device",
        "Bosch-Security-Systems-Device",
        "Bosch-Security-Systems-Netherlands-Device",
        "Bosch-Security-Systems-USA-Device",
        "Bosch-Siemens-Home-Device",
        "Bosch-Software-Innovations-Device",
        "Brilliant-Home-Technology-Device",
        "Brooks-Automation-Device",
        "Brooks-Automation-RFID-Device",
        "Brother-Device",
        "Brother-Printer",
        "Building-Block-Video-Device",
        "Burroughs-Payment-Systems-Device",
        "CA-Traffic-Device",
        "CAREL-Device",
        "CCV-Deutschland-Device",
        "CONTROLtronic-Device",
        "CR Console",
        "CS-7",
        "CSI-Control-Systems-Device",
        "CSIRO-Exploration-Mining-Device",
        "Canon-Device",
        "Carestream PACS",
        "Carlo-Gavazzi-Controls-Device",
        "Carrier-Corporation-Device",
        "Cash-Systemes-Industrie-Device",
        "CashGuard-Device",
        "Cashmaster-Device",
        "Castles-Technology-Device",
        "Caterpillar-Device",
        "Caterpillar-Trimble-Control-Device",
        "CentraLite-Systems-Device",
        "Centricity Enterprise Archive product",
        "Centricity PACS",
        "Centricity Radiology",
        "Chubb-Electronic-Security-Systems-Device",
        "Circontrol-Device",
        "Cisco-Access-Point",
        "Cisco-DMP",
        "Cisco-Device",
        "Cisco-IP-Phone",
        "Cisco-Meraki-Access-Point",
        "Cisco-Meraki-Device",
        "Cisco-Meraki-Security-Appliance",
        "Cisco-Meraki-Switch",
        "Cisco-Router",
        "Cisco-Switch",
        "Cisco-Tandberg-Device",
        "Cisco-TelePresence",
        "Cisco-WLC",
        "Clare-Controls-Device",
        "Colorado-vNet-Device",
        "Companytec-Automation-Control-Device",
        "Compex-Device",
        "Compressor-Controls-Corp-Device",
        "Computerized-Elevator-Contol-Device",
        "Comtrol-Device",
        "Connected-Home-Device",
        "Contemporary-Control-Device",
        "Control-Applications-Device",
        "Control-Dynamics-Device",
        "Control-Express-Finland-Device",
        "Control-Microsystems-Device",
        "Control-Module-Device",
        "Control-Products-Device",
        "Control-Resources-Corp-Device",
        "Control-Robotics-Solutions-Device",
        "Control-Sistematizado-Device",
        "Control-Solutions-Device",
        "Control-Solutions-Inc-Device",
        "Control-Techniques-Device",
        "Control-Technology-Corp-Device",
        "Control-Technology-Inc-Device",
        "Control-iD-Device",
        "Control4-Device",
        "ControlNet-Device",
        "ControlThings-Device",
        "Controlled-Electronic-Device",
        "Controlled-Environments-Device",
        "Cool-Control-Device",
        "Cooper-Industries-Device",
        "Copley-Controls-Corp-Device",
        "Creative-Lighting-Sound-Device",
        "Cree-Device",
        "Crestron-Device",
        "Crestron-Electronics-Device",
        "Crisp-Automation-Device",
        "Ctek-Device",
        "Cummins-Allison-Device",
        "Curtiss-Wright-Avionics-Electronics-Device",
        "Curtiss-Wright-Embedded-Computing-Device",
        "Custom-Control-Concepts-Device",
        "Cyber-Power-System-Device",
        "Cylon-Controls-Device",
        "DASA-Robot-Device",
        "DEOS-Control-Systems-Device",
        "DLink-Device",
        "DRS-Consolidated-Controls-Device",
        "DVTel-Device",
        "Daeyeon-Control-Instrument-Device",
        "Dai-ichi-Dentsu-Device",
        "Dalian-Seasky-Automation-Device",
        "DanControl-Engineering-Device",
        "Darfon-Lighting-Device",
        "Dassault-Electronique-Device",
        "Data-Control-Equipment-Device",
        "Datasym-POS-Device",
        "DeLaval-International-Device",
        "Dell-Device",
        "Delta-Control-Device",
        "Delta-Controls-Device",
        "Delta-Danish-Electronics-Device",
        "Delta-Electronics-Device",
        "Delta-Elektronika-Device",
        "Denon-Device",
        "Deutschmann-Automation-Device",
        "Digi-International-Device",
        "DigiBoard-Device",
        "Digital-Control-Device",
        "Digital-Lighting-Systems-Device",
        "Digital-Payment-Technologies-Device",
        "Digitronic-Device",
        "Direct-Payment-Solutions-Device",
        "Draeger-Device",
        "Dynacolor-Device",
        "E-Control-Systems-Device",
        "EControls-Device",
        "EIS V3",
        "EKE-Building-Technology-Device",
        "ELAN-Systems-Device",
        "ELC-Lighting-Device",
        "ELPRO-Technologies-Device",
        "EMC-Device",
        "Eagle-Traffic-Control-Device",
        "Eaton-Automation-Device",
        "Eaton-Corporation-Device",
        "Eaton-Device",
        "Eaton-FHF-Device",
        "Eaton-Industries-Device",
        "Eaton-Pulizzi-Electrical-Device",
        "Ecobee-Device",
        "Econolite-Control-Device",
        "Eddy-Smart-Home-Device",
        "Edge",
        "Edwards-Ltd-Device",
        "Electric-Controller-Manufacturing-Device",
        "Electronic-Theatre-Controls-Device",
        "Eliwell-Controls-Device",
        "Elk-Products-Device",
        "Elmo-Motion-Control-Device",
        "Embedded-Automation-Device",
        "Emerson-Appliance-Device",
        "Emerson-Climate-Device",
        "Emerson-Climate-Retail-Device",
        "Emerson-Climate-Transportation-Device",
        "Emerson-Device",
        "Emerson-Energy-Systems-Device",
        "Emerson-Network-Power-Avocent-Device",
        "Emerson-Network-Power-Device",
        "Emerson-Power-Water-Device",
        "Enovation-Controls-Device",
        "Enterasys-Device",
        "Epson-Device",
        "Equinox-Payments-Device",
        "EquipTrans-Device",
        "Estun-Automation-Device",
        "Eurotherm-Controls-Device",
        "Exceptional-Innovation-Device",
        "Extreme-Networks-Device",
        "F-Robotics-Acquisitions-Device",
        "F5-Networks-Device",
        "FAG-Industrial-Device",
        "FANUC-Robotics-Device",
        "FATEK-Automation-Device",
        "FS-Forth-Systeme-Device",
        "FUJI-Automation-Device",
        "FUJI-Electric-Device",
        "Fagor-Automation-Device",
        "Fairmount-Automation-Device",
        "Fisher-Controls-Device",
        "Flytech-Technology-Device",
        "Fuji-Device",
        "GE-Consumer-Industrial-Device",
        "GE-Corp-Device",
        "GE-Digital-Energy-Device",
        "GE-Energy-Device",
        "GE-Fanuc-Automation-Device",
        "GE-Global-Research-Device",
        "GE-Water-Process-Device",
        "GKB-CCTV-Device",
        "Galil-Motion-Control-Device",
        "Gemalto-ePayment-Device",
        "General-Dynamics-Robotic-Systems-Device",
        "General-Electric-Device",
        "Genetec-Device",
        "Genie-Industries-Device",
        "Genmark-Automation-Device",
        "GeoVision-Device",
        "German-Light-Products-Device",
        "Global-Traffic-Technologies-Device",
        "Golden-River-Traffic-Device",
        "Goldline-Controls-Device",
        "Google-Chromecast",
        "Google-Device",
        "Grandeye-Device",
        "Grundfos-Holding-Device",
        "Gunnebo-Cash-Automation-Device",
        "HESS-Cash-Systems-Device",
        "HID-Corp-Device",
        "HMS-Industrial-Networks-Device",
        "HP-Device",
        "HP-Printer",
        "HTC-Device",
        "Haas-Automation-Device",
        "Hangzhou-Hikvision-Device",
        "Hangzhou-Hollysys-Automation-Device",
        "Hanwha-Techwin-Device",
        "Hanwha-Techwin-Security-Device",
        "Hectronic-Device",
        "Highlight-Parking-Systems-Device",
        "Hills-Sound-Vision-Lighting-Device",
        "Hirschmann-Austria-Device",
        "Hirschmann-Automation-Control-Device",
        "Hirschmann-Automation-Device",
        "Hirschmann-Device",
        "Hirschmann-Multimedia-Device",
        "Hitachi-Device",
        "Hitachi-High-Tech-Control-Device",
        "Hitachi-Industrial-Equipment-Device",
        "Hitachi-Industry-Control-Device",
        "Hitachi-Johnson-Controls-Device",
        "Homag-Device",
        "Home-Automation-Europe-Device",
        "Home-Control-Singapore-Device",
        "Home-Director-Device",
        "Home-Network-System-Device",
        "HomeLogic-Device",
        "HomeScenario-Device",
        "Homenet-Communications-Device",
        "Homerider-Systems-Device",
        "Honeywell-ACS-Device",
        "Honeywell-Analytics-Device",
        "Honeywell-Automation-Device",
        "Honeywell-CMSS-Device",
        "Honeywell-China-Device",
        "Honeywell-Dating-Device",
        "Honeywell-Device",
        "Honeywell-ECC-Device",
        "Honeywell-Environmental-Device",
        "Honeywell-Fire-System-Device",
        "Honeywell-Germany-Device",
        "Honeywell-International-Device",
        "Honeywell-Korea-Device",
        "Honeywell-Life-Safety-Device",
        "Honeywell-Safety-Products-Device",
        "Honeywell-Security-Device",
        "Honeywell-Video-Systems-Device",
        "Honeywld-Technology-Device",
        "Horner-Electric-Device",
        "Hospira-Device",
        "Huawei-Device",
        "Hubbell-Building-Automation-Device",
        "Hubbell-Device",
        "Hubbell-Power-Systems-Device",
        "Hurco-Automation-Device",
        "Hypercom-Device",
        "IBASE-Technology-Device",
        "ICC-Device",
        "ICM-Controls-Device",
        "ICP-ePayment-Device",
        "IDEC-Corp-Device",
        "IDIS-Co-Device",
        "IQinVision-Device",
        "Igor-Device",
        "Impinj-Device",
        "In-Home-Displays-Device",
        "Industrea-Mining-Technology-Device",
        "Industrial-Control-Device",
        "Industrial-Control-Systems-Device",
        "Inex-Technologies-Device",
        "Infinia",
        "Infinova-Device",
        "Ingenico-Device",
        "Ingenico-POS-Payment-Device",
        "Ingenico-Terminals-Device",
        "Ingersoll-Rand-Device",
        "Intec-Automation-Device",
        "Integrated-Control-Corp-Device",
        "Integrated-Control-Technology-Device",
        "Intel-Device",
        "IntelliSpace Portal",
        "Intelligent-Control-Systems-Device",
        "Intelligent-Distributed-Controls-Device",
        "International-Mining-Technologies-Device",
        "Intrepid-Control-Systems-Device",
        "Invensys-Controls-Device",
        "Invisua-Lighting-Device",
        "Isilon-Systems-Device",
        "JK-microsystems-Device",
        "Japan-Cash-Machine-Device",
        "Johnson-Controls-Device",
        "Juniper-Device",
        "KMC-CONTROLS-Device",
        "KUKA-Welding-Robots-Device",
        "Kaba-Benzing-Device",
        "Kinco-Automation-Device",
        "Kingtek-CCTV-Alliance-Device",
        "Knapp-Logistik-Device",
        "Kone-Device",
        "Kongsberg-Norcontrol-Device",
        "Konica-Device",
        "Kontron-Device",
        "Kontron-Electronik-Device",
        "Kronos-Device",
        "Kyocera-Device",
        "LACROIX-Traffic-Device",
        "LARsys-Automation-Device",
        "LED-Roadway-Lighting-Device",
        "LEGRAND-Device",
        "LG Smart TV",
        "LG-Device",
        "LNT-Automation-Device",
        "LOGIQ",
        "LOYTEC-electronics-Device",
        "Lagotek-Corp-Device",
        "Laser-Light-Engines-Device",
        "Leadtek Thin Client",
        "Leadtek-Device",
        "Leedarson-Lighting-Device",
        "Legrand-Home-Systems-Device",
        "Lenovo-Device",
        "Leroy-Automation-Device",
        "Leviton-Manufacturing-Device",
        "Lexmark-Device",
        "Liebert-Device",
        "Lifesize-Device",
        "LightHaus-Logic-Device",
        "LightStar-Device",
        "Lighting-Science-Group-Device",
        "Linksys-Device",
        "Linux-Workstation",
        "Lipman-TransAction-Device",
        "Lippert-Automation-Device",
        "Liquid-Robotics-Device",
        "Listman-Home-Technologies-Device",
        "LiteTouch-Device",
        "Logic-Controls-Device",
        "Logic-Product-Device",
        "Longdhua-Controls-Device",
        "Lorex-Technology-Device",
        "Luninex-Lighting-Control-Device",
        "Lupine-Lighting-Systems-Device",
        "Lutron-Electronics-Device",
        "MA-Lighting-Device",
        "MCS-Logic-Device",
        "MDR Video",
        "Maas-International-Device",
        "Macintosh-Workstation",
        "Magal-Senstar-Device",
        "Magnetic-Autocontrol-Device",
        "Magnetom Vida",
        "March-Networks-Device",
        "Masimo-Device",
        "Masterclock-Device",
        "McCain-Traffic-Supply-Device",
        "Media Center R.2.3",
        "Mercury-Security-Device",
        "Merten-Device",
        "Meteocontrol-Device",
        "Metso-Automation-Device",
        "Micro-Control-Designs-Device",
        "Micro-Control-Systems-Inc-Device",
        "Micro-Control-Systems-Ltd-Device",
        "Micro-Robotics-Limited-Device",
        "Microrobot-Device",
        "Microsoft-Device",
        "Microsoft-Workstation",
        "Mine-Site-Technologies-Device",
        "MitelCorp-Device",
        "Mitsubishi-Device",
        "Mitsubishi-Electric-Device",
        "Mitsubishi-Electric-Service-Device",
        "Mitsubishi-Electronics-Logistic-Device",
        "Mitsubishi-Heavy-Industries-Device",
        "Mitsubishi-Precision-Device",
        "Mobotix-Device",
        "Mode-Lighting-Device",
        "Modular-Mining-Systems-Device",
        "Molex-Canada-Device",
        "Molex-Device",
        "Moog-Broad-Reach-Device",
        "Moog-Device",
        "Moog-Germany-Device",
        "Moog-USA-Device",
        "Motion-Control-Engineering-Device",
        "Motion-Control-Systems-Device",
        "MotorolaMobile-Device",
        "Multivision-Intelligent-Surveillance-Device",
        "NBS-Device",
        "NBS-Payment-Solutions-Device",
        "NCR-Device",
        "NCTech-Device",
        "NEWPOS-Device",
        "Nanjing-SAC-Power-Device",
        "Nanjing-Shining-Electric-Device",
        "Navionics-Research-Device",
        "Nedap-Device",
        "Nest-Labs-Device",
        "NetSys-Device",
        "Netatmo-Device",
        "Netcontrol-Device",
        "Netgear-Device",
        "Network-Controls-International-Device",
        "Neuberger-Building-Automation-Device",
        "Neuron-Robotics-Device",
        "New-Light-LED-Device",
        "Nexans-Germany-Device",
        "Nidec-Copal-Device",
        "Nidec-Device",
        "Nidec-Sanyo-Device",
        "Nintendo-Device",
        "Nixdorf-Computer-Device",
        "Nokia-Device",
        "Nortel-Device",
        "Novus-Security-Device",
        "NuLEDs-Device",
        "Nuki-Home-Solutions-Device",
        "Omnex-Control-Systems-Device",
        "OnePlus-Device",
        "Open-Source-Robotics-Foundation-Device",
        "Orient-Power-Home-Network-Device",
        "Otis-Elevator-Device",
        "Ouya-Device",
        "PACS Scan",
        "PChome-Online-Device",
        "POSBANK-Device",
        "POSDATA-Device",
        "PROBA-Building-Automation-Device",
        "Panasonic-Device",
        "Panasonic-Electric-Works-Automation-Device",
        "Panasonic-Electric-Works-Device",
        "Panasonic-Industrial-Device",
        "Park-Assist-Device",
        "Parker-Hannifin-Compumotor-Device",
        "Parker-Hannifin-Device",
        "Parker-Hannifin-Electromechanical-Device",
        "Parker-Hannifin-Manufacturing-Germany-Device",
        "Parker-Hannifin-Manufacturing-UK-Device",
        "Parking-BOXX-Device",
        "Peek-Traffic-Device",
        "Pelco-Device",
        "Peperoni-Light-Device",
        "Phase-Motion-Control-Device",
        "Philips-CFT-Device",
        "Philips-Device",
        "Philips-Lighting-Device",
        "Phorus-Device",
        "Plast-Control-Device",
        "Plugin-Validity-Device",
        "Pneu-Logic-Device",
        "Polycom-Device",
        "Portwell-Device",
        "Posiflex-Device",
        "Power-Automation-Device",
        "Precise-Automation-Device",
        "Process-Control-Corp-Device",
        "Prolon-Control-Systems-Device",
        "Q-Light-Device",
        "QSR-Automations-Device",
        "Quatech-Device",
        "Quest-Controls-Device",
        "RBZ-Embedded-Logics-Device",
        "REGIUS CONSOLE CS-3",
        "RICOH-Device",
        "ROBE-Show-Lighting-Device",
        "ROBOTIS-Device",
        "ROTEC-Industry-Automation-Device",
        "RS-Automation-Device",
        "Radiation-Safety-Device",
        "Radisys-Device",
        "Rainforest-Automation-Device",
        "RaspberryPi-Device",
        "Real-Time-Automation-Device",
        "Realand-Bio-Co-Device",
        "Recardo-Test-Automation-Device",
        "Red-Lion-Controls-Device",
        "Reliable-Controls-Device",
        "Remote-Technologies-Device",
        "Residential-Control-Systems-Device",
        "Robert-Bosch-ATMO-Device",
        "Robert-Bosch-Automotive-Device",
        "Robert-Bosch-Device",
        "Robert-Bosch-Smart-Home-Device",
        "RoboTech-Device",
        "Robot-Visual-Systems-Device",
        "Robotous-Device",
        "Rockwell-Automation-Device",
        "Roku-Device",
        "Roku-Stick",
        "Rosemont-Controls-Device",
        "Ross-Controls-Device",
        "S2-Security-Corp-Device",
        "SE-Controls-Device",
        "SEGGER-Microcontroller-Device",
        "SEL-Schweitzer-Device",
        "SGM-Technology-Device",
        "SHI-Control-Systems-Device",
        "SPG-Controls-Device",
        "SSI-Schaefer-Peem-Device",
        "SWAC-Device",
        "Safetran-Traffic-Systems-Device",
        "Saia-Burgess-Controls-Device",
        "Samsung-Device",
        "Samsung-Galaxy",
        "Samsung-Printer",
        "Samsung-Smart-Tv",
        "Samsung-Techwin-Device",
        "Sanritz-Automation-Device",
        "Satchwell-Control-Systems-Device",
        "Savant-Systems-Device",
        "Schilling-Robotics-Device",
        "Schindler-Elevator-Device",
        "Schlumberger-Device",
        "Schlumberger-Kabushiki-Kaisha-Device",
        "Schlumberger-Retail-Petroleum-Device",
        "Schlumberger-Well-Services-Device",
        "Schneider-Electric-Asia-Pacific-Device",
        "Schneider-Electric-Australia-Device",
        "Schneider-Electric-Canada-Device",
        "Schneider-Electric-Device",
        "Schneider-Electric-Fire-Security-Device",
        "Schneider-Electric-France-Device",
        "Schneider-Electric-Germany-Device",
        "Schneider-Electric-Motion-Device",
        "Schneider-Electric-Ultra-Terminal-Device",
        "Scientific-Atlanta-Device",
        "Se-Kure-Controls-Device",
        "Securite-Communications-Honeywell-Device",
        "Selenia Dimensions",
        "Seno Iris",
        "Sensys-Traffic-Device",
        "Sentry-360-Security-Device",
        "Sepro-Robotique-Device",
        "Servo-Robot-Device",
        "ShangHai-Sun-Up-Lighting-Device",
        "Shanghai-iComhome-Device",
        "Shenzhen-HT-Intelligent-Control-Device",
        "Shenzhen-Heguang-Device",
        "Shenzhen-Shidean-Legrand-Device",
        "Shibaura-Vending-Device",
        "ShopperTrak-Device",
        "Shoretel-Device",
        "Shure-Device",
        "SiboTech-Automation-Device",
        "Siemens-Asia-Device",
        "Siemens-Austria-SIMEA-Device",
        "Siemens-Automation-Device",
        "Siemens-Automation-Drives-Device",
        "Siemens-Belgium-Device",
        "Siemens-Building-Device",
        "Siemens-Building-Technologies-Device",
        "Siemens-Convergence-Device",
        "Siemens-Device",
        "Siemens-Digital-Factory-Device",
        "Siemens-Energy-Automation-Device",
        "Siemens-Energy-Management-Device",
        "Siemens-Home-Office-Device",
        "Siemens-ICM-Device",
        "Siemens-Industrial-Automation-China-Device",
        "Siemens-Industrial-Automation-Device",
        "Siemens-Industrial-Automation-EWA-Device",
        "Siemens-Industrial-Solutions-Device",
        "Siemens-Industry-Device",
        "Siemens-Low-Voltage-Device",
        "Siemens-Numerical-Control-Device",
        "Siemens-Sector-Industry-Device",
        "Siemens-Switzerland-BT-HVP-Device",
        "Siemens-Transportation-Device",
        "SightLogix-Device",
        "Silex-Technology-America-Device",
        "Silex-Technology-Device",
        "Siqura-Device",
        "Sistemas-Avanzados-Control-Device",
        "SmarteBuilding-Device",
        "Smarthome-Device",
        "Soenen-Controls-Device",
        "Softlink-Automation-Device",
        "Solaris-Workstation",
        "Solberg-Andersen-Device",
        "Somat-Corp-Device",
        "Sonicwall-Device",
        "Sonos Beam",
        "Sonos One",
        "Sonos-Device",
        "Sony-Device",
        "Spectra-Technologies-Device",
        "Spectronix-Device",
        "Spectrum-Control-Device",
        "Spectrum-Controls-Device",
        "Spire-Payments-Device",
        "Sprecher-Automation-Device",
        "Square-D-Device",
        "Star-Micronics-Device",
        "StarDot-Technologies-Device",
        "Strand-Lighting-Device",
        "Super-Micro-Device",
        "Sur-Gard-Security-Device",
        "Swarco-Device",
        "Symbol-Device",
        "Symbol-POS-Device",
        "Synergy-Lighting-Controls-Device",
        "Synology-Device",
        "Syscor-Controls-Automation-Device",
        "TF-Payment-Service-Device",
        "TP-LINK-Device",
        "TPA-Traffic-Parking-Automation-Device",
        "TST-Biometrics-Device",
        "TTC-Next-Gen-Home-Network-Device",
        "TVT-Device",
        "TagMaster-Device",
        "Takagi-Industrial-Device",
        "Tesco-Controls-Device",
        "Thermo-Fisher-Scientific-Device",
        "Thomas-Betts-Device",
        "ThyssenKrupp-Elevator-Device",
        "Time-Management-Device",
        "Time-O-Matic-Device",
        "TimeKeeping-Systems-Device",
        "Tivo-Device",
        "TopControl-Device",
        "Toshiba-Mitsubishi-Electric-Industrial-Device",
        "Touchless-Biometric-Systems-Device",
        "TrafficCast-Device",
        "Trane-Device",
        "Trebing-Himstedt-Proze-Automation-Device",
        "Trendnet-Device",
        "Tridium-Device",
        "TrippLite-Device",
        "Triton-Electronics-Device",
        "Triton-Systems-ATM-Device",
        "Truen-Device",
        "TurControlSystme-Device",
        "Tyco-Traffic-Transportation-Device",
        "US-Traffic-Corp-Device",
        "UTC-Climate-Control-Security-Device",
        "UTC-Fire-Security-Device",
        "Ultra-Electronics-Command-Control-Device",
        "Ultra-Electronics-Device",
        "UniControls-Device",
        "United-Technologies-Corp-Device",
        "United-Western-Technologies-Device",
        "Universal-Control-Solution-Device",
        "Universal-Remote-Control-Device",
        "Uwe-Schneider-Device",
        "VG-Controls-Device",
        "VMWare-Device",
        "Vanderbilt-International-Device",
        "Vantage-Controls-Device",
        "Vectron-Systems-Device",
        "VendNovation-Device",
        "Verifone-Device",
        "Verint-Loronix-Video-Device",
        "Vertiv WatchDog 100",
        "Vertiv-Device",
        "Vialis-Device",
        "Vicon-Industries-Device",
        "VideoIQ-Device",
        "Visonic-Device",
        "Vista-Control-Systems-Device",
        "Vista-Controls-Corp-Device",
        "Vivid E80",
        "Vivid E90",
        "Vivid E95",
        "Vivid S60N",
        "Vivid S70N",
        "Vivid T8",
        "Vivid T9",
        "Vivid iq",
        "Vivotek-Device",
        "Vizio-Device",
        "Vizio-Sound-Bar",
        "WPS-Parking-Systems-Device",
        "WYSE-Device",
        "Wachendorff-Automation-Device",
        "Wayne-Dalton-Device",
        "Weiss-Robotics-Device",
        "Welch-Allyn-Device",
        "Westcontrol-Device",
        "Wincor-Nixdorf-Diebold-Device",
        "Windows-Workstation",
        "Winpresa-Building-Automation-Device",
        "Wintech-Automation-Device",
        "Wuhan-FiberHome-Device",
        "XAC-Automation-Device",
        "Xanboo-Device",
        "Xantech-Corp-Device",
        "Xeleris",
        "Xerox-Device",
        "Xiaomi-Device",
        "Xin-Hua-Control-Engineering-Device",
        "Xipher-Technologies-Device",
        "YORK-Refrigeration-Device",
        "Yacoub-Automation-Device",
        "Yamatake-Honeywell-Device",
        "Yarg-Biometrics-Device",
        "Z-Com-Device",
        "ZIEHL-industrie-electronik-Device",
        "ZTE-Device",
        "Zebra-Device",
        "Zhejiang-Dahua-Device",
        "Zhejiang-Shenghui-Lighting-Device",
        "Zhejiang-Uniview-Device",
        "Ziehl-Abegg-Device",
        "adp-Gauselmann-Device",
        "automationNEXT-Device",
        "home2net-Device",
        "i-Controls-Device",
        "iControl-Device",
        "iRZ-Automation-Device",
        "iU22 - iE33  Ultrasound System",
        "ioIMAGE-Device",
        "miControl-Device",
        "uControl-Device",
        "visago-Systems-Controls-Device"
      ],
      "hardwareManufacturer": [
        "ABB Power Technologies",
        "APG Cash Drawer, LLC",
        "ARKRAY, Inc. Kyoto Laboratory",
        "Abbott Medical Optics",
        "Access Control Systems JSC",
        "Advanced Medical Information",
        "Advatek Lighting Pty Ltd",
        "Amazon.com, Inc.",
        "Apple, Inc.",
        "Applied Materials",
        "Applied Medical Technologies, Inc DBA AirClean Systems",
        "Armour Home Electronics LTD",
        "Axis Communications AB",
        "Axis-Shield PoC AS",
        "BALOGH T.A.G",
        "BC Illumination, Inc.",
        "BIOTAGE",
        "BL Healthcare, Inc.",
        "Balogh S.A.",
        "Beckmann+Egle GmbH",
        "BioFocus Inc.",
        "Biotage Sweden AB",
        "Brother Industries, Ltd.",
        "CA Traffic Ltd",
        "Canon Inc.",
        "CardioMEMS Inc.",
        "Care Everywhere LLC",
        "Carestream Health",
        "Carl Zeiss Meditec AG",
        "Cisco Systems, Inc.",
        "ClearCanvas Inc.",
        "Colby Dillion",
        "Computerized Elevator Contol",
        "Control and Robotics Solutions",
        "Convergent Bioscience Ltd.",
        "Creative Lighting And Sound Systems Pty Ltd",
        "Crestron Electronics",
        "DASA ROBOT Co., Ltd.",
        "Dell Inc.",
        "Denon",
        "Dornier MedTech Systems GmbH",
        "EATON FHF Funke + Huster Fernsig GmbH",
        "ELC Lighting",
        "Elan Systems",
        "Elk Products",
        "Ellex Medical Pty Ltd",
        "Fluke Biomedical, Radiation Management Services",
        "Fuji Photo Film Co., Ltd.",
        "Fujifilm",
        "GE Healthcare",
        "GE Healthcare GmbH",
        "General Electric",
        "General Electric (GE) Medical Systems Nederland BV",
        "Getinge IT Solutions ApS",
        "Golden River Traffic",
        "Google, Inc.",
        "HORIBA MEDICAL",
        "HP Inc.",
        "HTC Corporation",
        "Haag-Streit",
        "Heart Force Medical",
        "Heraeus Noblelight",
        "Highlight Parking",
        "Hitachi",
        "Hitachi Medical Corporation",
        "Hologic, Inc.",
        "Home Automation Europe",
        "Home Director",
        "HomeScenario",
        "Huawei Technologies Co., Ltd.",
        "Hyland",
        "ICU Scandinavia",
        "INTEGRA Biosciences AG",
        "Igor, Inc.",
        "Imricor Medical Systems",
        "Industrea Mining Technology",
        "Industrial Control and Communication",
        "Innomed Medical",
        "Integra LifeSciences",
        "Integrated Control Corp.",
        "International Mining",
        "Intuitive Surgical",
        "Invisua Lighting",
        "Joff Ind",
        "Joff Ind.",
        "Jostra AB",
        "Konica Minolta",
        "Konica Minolta Medical & Graphic, Inc.",
        "Konica Minolta, Inc.",
        "Kyocera Corporation",
        "LG Corporation",
        "LUMINEX Lighting Control Equipment",
        "Leadtek Research, Inc.",
        "Leica Biosystems",
        "Lenovo Group Limited",
        "Lexmark International, Inc.",
        "Liebert Corporation",
        "Lightstar",
        "Liquid Robotics, Inc",
        "MDS SCIEX",
        "MEDAV GmbH",
        "Magal Senstar",
        "McCain Traffic",
        "MeVis Medical Solutions AG",
        "MedAvant Healthcare",
        "Mediana",
        "Merge Technologies",
        "Moog Broad Reach",
        "MultiTech Solutions Inc.",
        "Networked Robotics",
        "Nintendo Co., Ltd.",
        "Novasonics",
        "One Plus Technology (Shenzhen) Co., Ltd.",
        "PACSGEAR, Inc.",
        "Peperoni-Light",
        "Philips",
        "Philips Medical Systems",
        "Philips Personal Health Solutions",
        "Philips Ultrasound, Inc.",
        "Phorus, Inc.",
        "Pneu-Logic",
        "Pointe Conception Medical",
        "Power Medical Interventions",
        "Quantum Medical Imaging",
        "Quatech, LLC",
        "Radiometer Medical",
        "Residential Control Systems",
        "Resurgent Health And Medical",
        "Ricoh Company, Ltd.",
        "Robot Visual Systems",
        "Roku, Inc.",
        "SAIA ",
        "SSI Schaefer Peem",
        "Samsung Electronics Co., Ltd",
        "Schneider Electric GmbH",
        "Schneider Electric Motion",
        "Seiko Epson Corporation",
        "Servo-Robot Inc.",
        "Shure Inc.",
        "Siemens Healthcare",
        "SonoSite, Inc.",
        "Sonos, Inc.",
        "Sony Interactive Entertainment Inc.",
        "Soredex Instrumentarium Oyj",
        "St Jude Medical, Inc.",
        "SuperSonic Imagine",
        "TOSHIBA",
        "TPA Traffic",
        "The Trane Company",
        "Toshiba Medical Systems Co., Ltd.",
        "Touchless Biometric Systems AG",
        "Triton Electronics LTD",
        "Tyco Traffic And Transportation",
        "United Western Technologies",
        "Universidad de Chile Facultad de Medicina",
        "Uwe Schneider",
        "VMware, Inc.",
        "Valtronic SA",
        "VendNovation LLC",
        "Vertiv",
        "Vizio, Inc",
        "Wayne Dalton",
        "Weiss Robotics GmbH And Co. KG",
        "Welch Allyn Inc.",
        "West-Com Nurse Call Systems, Inc.",
        "Xerox Corporation",
        "Xiaomi Corporation",
        "Yarg Biometrics Limited",
        "Zebra Technologies Corporation",
        "Ziehl-Abegg AG",
        "Zoe Medical",
        "ecobee Inc.",
        "rbz robot design s.l.",
        "vitalcare"
      ],
      "ruleType": [
        "Cisco Default - Dynamic",
        "Cisco Default - Static",
        "Custom Rule"
      ],
      "operatingSystem": [
        "Android",
        "Bada OS",
        "BlackBerry OS",
        "CellOS",
        "CentOS",
        "Chrome OS",
        "Cisco IP Phone",
        "Debian",
        "Fedora",
        "Fire OS",
        "FreeBSD",
        "Gentoo",
        "Kubuntu",
        "Linux",
        "Mint",
        "OpenBSD",
        "Orbis OS",
        "Red Hat",
        "Roku OS",
        "SUNW",
        "SUSE",
        "SunOS",
        "SunOs",
        "Symbian",
        "Ubuntu",
        "Windows",
        "Windows 10",
        "Windows 3.11",
        "Windows 7",
        "Windows 8",
        "Windows 95",
        "Windows 98",
        "Windows NT",
        "Windows XP",
        "iOS",
        "macOS",
        "tvOS",
        "webOS",
        "xandros"
      ]
    }
  },
  "logicalGroup_deviceTypeLables_Template":{
    "data": [
      {
        "deviceType": "Medical Device",
        "used": true,
        "tenantId": null
      },
      {
        "deviceType": "Workstation",
        "used": true,
        "tenantId": null
      },
      {
        "deviceType": "CD Burner",
        "used": false,
        "tenantId": null
      },
      {
        "deviceType": "CT system workstation",
        "used": false,
        "tenantId": null
      },
      {
        "deviceType": "CT-Scanner",
        "used": false,
        "tenantId": null
      },
      {
        "deviceType": "Camera",
        "used": false,
        "tenantId": null
      },
      {
        "deviceType": "Computed radiography digital imaging scanner",
        "used": false,
        "tenantId": null
      },
      {
        "deviceType": "Diagnostic x-ray digital imaging system workstation application software",
        "used": false,
        "tenantId": null
      },
      {
        "deviceType": "Gaming Device",
        "used": false,
        "tenantId": null
      },
      {
        "deviceType": "General-purpose ultrasound imaging system",
        "used": false,
        "tenantId": null
      },
      {
        "deviceType": "IOT Device",
        "used": false,
        "tenantId": null
      },
      {
        "deviceType": "IP Phone",
        "used": false,
        "tenantId": null
      },
      {
        "deviceType": "Indexed-immobilization patient positioning system",
        "used": false,
        "tenantId": null
      },
      {
        "deviceType": "Medical image management system",
        "used": false,
        "tenantId": null
      },
      {
        "deviceType": "Medical video image recorder",
        "used": false,
        "tenantId": null
      },
      {
        "deviceType": "Mobile Device",
        "used": false,
        "tenantId": null
      },
      {
        "deviceType": "Network Device",
        "used": false,
        "tenantId": null
      },
      {
        "deviceType": "Nuclear medicine system workstation",
        "used": false,
        "tenantId": null
      },
      {
        "deviceType": "Printer",
        "used": false,
        "tenantId": null
      },
      {
        "deviceType": "Radiography digital imaging scanner",
        "used": false,
        "tenantId": null
      },
      {
        "deviceType": "Radiology DICOM image processing application software",
        "used": false,
        "tenantId": null
      },
      {
        "deviceType": "Radiology PACS software",
        "used": false,
        "tenantId": null
      },
      {
        "deviceType": "Stationary mammographic x-ray system, digital",
        "used": false,
        "tenantId": null
      },
      {
        "deviceType": "Storage",
        "used": false,
        "tenantId": null
      },
      {
        "deviceType": "Wireless Speaker",
        "used": false,
        "tenantId": null
      },
      {
        "deviceType": "stationary mammographic x-ray system, digital",
        "used": false,
        "tenantId": null
      }
    ]
  },
  "logicalgroup_tag_Template":[
    {
      "name": "Windows Machines",
      "lgID": "b8e0a2c2-540b-4987-ac5d-f30ac1119a8b",
      "parentlgID": "0",
      "deviceTypes": [
        "Workstation"
      ],
      "dtCount": 1,
      "epCount": 7,
      "level": 1,
      "deviceTypesCounts": [
        {
          "dtEpCount": 7,
          "name": "Workstation"
        }
      ]
    },
    {
      "name": "Medical",
      "lgID": "1439d440-11d3-4c04-aab8-bfd80a5458e4",
      "parentlgID": "0",
      "deviceTypes": [
        "Medical Device"
      ],
      "dtCount": 1,
      "epCount": 0,
      "level": 1,
      "deviceTypesCounts": [
        {
          "dtEpCount": 0,
          "name": "Medical Device"
        }
      ]
    },
    {
      "name": "All Endpoints",
      "lgID": "0",
      "parentlgID": "-1",
      "deviceTypes": [],
      "dtCount": 26,
      "epCount": 14,
      "level": 0,
      "deviceTypesCounts": []
    }
  ],
  "clusterInfo_Template":{
    "data": {
      "charts": [],
      "rejected": null,
      "labelHardwareModel": null,
      "suggestedOperatingSystem": null,
      "rule": {
        "result": {},
        "conditionGroups": {
          "conditionGroup": [
            {
              "condition": {
                "attribute": "dhcpParameterRequestList",
                "value": "1, 3, 6, 15, 31, 33, 43, 44, 46, 47, 121, 249, 252",
                "attributeDictionary": "DHCP",
                "operator": "equals"
              },
              "type": "attr"
            }
          ],
          "operator": "and"
        },
        "ruleType": "ML Rule",
        "ruleVersion": "1",
        "ruleName": "3a3c-9baf-5478-9d99-1dda397fe919",
        "ruleId": "ml-c0293a3c-9baf-5478-9d99-1dda397fe919",
        "sourcePriority": 100,
        "rulePriority": 100
      },
      "clusterId": "a9abad50-fd2e-5b7c-ae5c-c02203a7f64a",
      "suggestedHardwareModel": null,
      "suggestedDeviceType": null,
      "commonAttributes": [
        {
          "value": "Hewlett Packard (94%)",
          "key": "OUI"
        },
        {
          "value": "Device Type#All Device Types#Switches#Access Switches (94%)",
          "key": "Network Element Type"
        },
        {
          "value": "1, 3, 6, 15, 31, 33, 43, 44, 46, 47, 121, 249, 252 (82%)",
          "key": "DHCP Fingerprint"
        }
      ],
      "suggestionNumCustomers": null,
      "labelOperatingSystem": null,
      "suggestedHardwareManufacturer": null,
      "numDevices": 65,
      "labelDeviceType": null,
      "suggestionNumEndpoints": null,
      "tokenizedAttributes": [
        {
          "keys": [
            "msft"
          ],
          "values": [
            100
          ],
          "name": "DHCP Class Identifier",
          "units": "percent"
        }
      ],
      "labelHardwareManufacturer": null
    }
  },
  "profileRuleCreate_Template":{
    "deviceType": [
      "Workstation"
    ],
    "usedAttributes": null,
    "conditionGroups": {
      "conditionGroup": [
        {
          "condition": {
            "attribute": "dhcpParameterRequestList",
            "value": "1, 3, 6, 15, 31, 33, 43, 44, 46, 47, 121, 249, 252",
            "attributeDictionary": "DHCP",
            "operator": "equals"
          },
          "type": "attr"
        }
      ],
      "operator": "and"
    },
    "hardwareModel": [
      "Microsoft-Workstation"
    ],
    "hardwareManufacturer": [
      "Microsoft"
    ],
    "ruleType": "Cisco AI",
    "ruleVersion": 1,
    "lastModifiedBy": "satrana",
    "ruleName": "Endpoint_Profiling",
    "ruleId": "ml-c0293a3c-9baf-5478-9d99-1dda397fe919",
    "operatingSystem": [
      "Windows 7"
    ],
    "lastModifiedOn": 1594302914099
  },
  "AncPolicyDetails_Template":{
      "ancFailureReason": null,
      "trustScore": 2,
      "ancPolicyStatus": "SUCCESS",
      "ancPolicyName": "ancQuarantine",
      "ancPolicyByDcs": true,
      "endpointId": null,
      "trustScoreTimeStamp": {
        "$numberLong": "1637046416561"
      },
      "operationId": "ise-1.demo.local:17",
      "alertId": null,
      "oldPolicyLinked": null,
      "ancPolicyApplyTime": {
        "$numberLong": "1637046416561"
      }
  },
  "GlobalAnomalyConfig_Template":{
      "mfcDetectionEnabled": "true",
      "concurrentMacDetectionEnabled": "true",
      "natDetectionEnabled": "true",
      "spoofingDetectionEnabled": "true",
      "postureDetectionEnabled": "true"
  },
  "tsResetDetails_Template":{
        "tsResetDescription": null,
        "tsResetTimeStamp": {
          "$numberLong": "1635539712163"
        },
        "tsAtReset": 1
  },
  "day_zero_Template":{
    "ai": {
      "hydra-detection": true,
      "hydra-collection": true,
      "kronos": true,
      "errorNotice": false,
      "isOnboarded": true
    },
    "ise": {
      "iseAuthzEnable": false,
      "isePublisherCheckbox": null,
      "globalIsePublisherEnable": true,
      "iseVersionInDB": null,
      "endpointAnalyticsSubscriber": false,
      "probeDataPublisher": false,
      "iseAssetEnable": true,
      "iseCompatibility": true,
      "pxgridEnabled": true,
      "version": "3.2.0.542",
      "enabled": true
    },
    "cbar": {
      "isNbarEnabled": "false",
      "isSdAvcEnabled": "true",
      "totalDevices": "17",
      "isProtocolPackAutoUpdateEnabled": "false",
      "totalEnabledDevices": "3"
    }
  },
  "day_n_Template":{
    "cmdb": {
      "cmdb": false
    },
    "ipBlocklist": {
      "totalDevices": 28,
      "totalEnabledDevices": 3,
      "netFlowEnabled": true,
      "wiredClientEnabled": true,
      "wirelessTelemetry": true,
      "dragonflyAuthEnabled": false,
      "totalDisabledDevices": 25,
      "ipBlocklistEnabled": "false"
    },
    "security-sensor": {
      "openportEnabled": false,
      "activeProbe": false,
      "weakcredEnabled": false
    }
  },
  "purge_config_Template":[
    {
      "creationTimestamp": 0,
      "updateTimestamp": 0,
      "ruleId": "698d4909-d036-44e6-b49c-33f7b962e000",
      "ruleName": "Default",
      "purgeEnabled": true,
      "randomMac": false,
      "deviceType": null,
      "hardwareManufacturer": null,
      "hardwareModel": null,
      "operatingSystem": null,
      "purgeStatus": {
        "status": "SUCCESS",
        "purgeStartTimestamp": 1685066427687,
        "purgeCompleteTimestamp": 1685066427774,
        "macDeleted": [],
        "macDeletedCount": 0
      },
      "purgeInterval": 30
    },
    {
      "creationTimestamp": 0,
      "updateTimestamp": 0,
      "ruleId": "68524bda-022a-40de-b468-b1eae852d4e4",
      "ruleName": "Random MAC Default",
      "purgeEnabled": true,
      "randomMac": true,
      "deviceType": null,
      "hardwareManufacturer": null,
      "hardwareModel": null,
      "operatingSystem": null,
      "purgeStatus": {
        "status": "SUCCESS",
        "purgeStartTimestamp": 1685066427779,
        "purgeCompleteTimestamp": 1685066427783,
        "macDeleted": [],
        "macDeletedCount": 0
      },
      "purgeInterval": 7
    }
  ],
  "trust_reset_config_Template":{
    "Endpoint Context": [
      {
        "anomalyType": "authMethod",
        "anomalyLabel": "Authentication Method",
        "resetPeriod": 0,
        "anomalyWeight": "systemDefault",
        "removeAnc": false,
        "toggleInProgress": false,
        "toggleErrorCode": null,
        "toggleErrorMessage": null,
        "enabled": true
      },
      {
        "anomalyType": "posture",
        "anomalyLabel": "Posture",
        "resetPeriod": 0,
        "anomalyWeight": "systemDefault",
        "removeAnc": false,
        "toggleInProgress": false,
        "toggleErrorCode": null,
        "toggleErrorMessage": null,
        "enabled": true
      }
    ],
    "Network Context": [
      {
        "anomalyType": "nat",
        "anomalyLabel": "NAT Mode Detection",
        "resetPeriod": -1,
        "anomalyWeight": "systemDefault",
        "removeAnc": false,
        "toggleInProgress": false,
        "toggleErrorCode": null,
        "toggleErrorMessage": null,
        "enabled": true
      },
      {
        "anomalyType": "concurrentMac",
        "anomalyLabel": "Concurrent MAC Detection",
        "resetPeriod": -1,
        "anomalyWeight": "systemDefault",
        "removeAnc": false,
        "toggleInProgress": false,
        "toggleErrorCode": null,
        "toggleErrorMessage": null,
        "enabled": true
      },
      {
        "anomalyType": "ipBlocklist",
        "anomalyLabel": "Talos IP Reputation",
        "resetPeriod": -1,
        "anomalyWeight": "systemDefault",
        "removeAnc": false,
        "toggleInProgress": false,
        "toggleErrorCode": null,
        "toggleErrorMessage": null,
        "enabled": false
      }
    ],
    "Threat and Vulnerability Context": [
      {
        "anomalyType": "mfc",
        "anomalyLabel": "Endpoint Attribute Conflict",
        "resetPeriod": -1,
        "anomalyWeight": "systemDefault",
        "removeAnc": false,
        "toggleInProgress": false,
        "toggleErrorCode": null,
        "toggleErrorMessage": null,
        "enabled": true
      },
      {
        "anomalyType": "ai",
        "anomalyLabel": "AI Spoofing Detection",
        "resetPeriod": -1,
        "anomalyWeight": "systemDefault",
        "removeAnc": false,
        "toggleInProgress": false,
        "toggleErrorCode": null,
        "toggleErrorMessage": null,
        "enabled": true
      },
      {
        "anomalyType": "openport",
        "anomalyLabel": "Open Port Scan",
        "resetPeriod": -1,
        "anomalyWeight": "systemDefault",
        "removeAnc": false,
        "toggleInProgress": false,
        "toggleErrorCode": null,
        "toggleErrorMessage": null,
        "enabled": false
      },
      {
        "anomalyType": "weakcred",
        "anomalyLabel": "Credential Vulnerabilities",
        "resetPeriod": -1,
        "anomalyWeight": "systemDefault",
        "removeAnc": false,
        "toggleInProgress": false,
        "toggleErrorCode": null,
        "toggleErrorMessage": null,
        "enabled": false
      }
    ]
  }
  
})