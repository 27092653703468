define({
    "UpgradeImageData": {
      "response":[  
        "ROUTER",
        "FIREWALL",
        "LOADBALANCER",
        "UNKNOWN",
        "NAM",
        "WAAS",
        "WLC",
        "THIRDPARTY",
        "WINDOWS",
        "LINUX"
     ],
     "version":"1.0"
    }
});
