define({
"Template":{
	"upnConfig":{
		"structuredSSIDinfo":[
			{
				"siteId":"231a20bb-8bf6-c4fd-82d3-9e58f1a09cef",
				"siteName":"Global/NorthAmerica/USA/California/SanJose/SJC01",
				"devices":[{"deviceName":"WLC-9800","deviceId":"3892f4c1-0d29-757a-552f-3525e4ce1e5f","isGuestAnchor":false}],
				"wlans":["@CorpSSID"],
				"selectedWlans":["@CorpSSID"],
				"unicast":false,
				"applyUnicast":"all",
				"unicastWlans":[]
		    }
		],
		"scheduleInfo":{
            "scheduleOption":"now",
            "scheduleTime":"WedJul15202013: 11: 00GMT+0530(IndiaStandardTime)",
            "scheduleTask":"",
            "disableConfig":[]
		}
	}
}
})