define({
"Template":
{
  "version": "1.0",
  "response": {
    "id": "944b489e-c7c7-4589-bb5d-6450947cde30",
    "name": "global_wireless_kairos_radio_cloud_app_throughput_drop_trigger",
    "enabled": true,
    "flattened": true,
    "entityType": "network_device",
    "entityName": "Unknown",
    "entity": "a1483ce0fd8abf62ec50",
    "groupBy": "a1483ce0fd8abf62ec50",
    "category": "Application",
    "severity": "HIGH",
    "summary": "At least 78% drop in radio throughput for Cloud Applications from baseline for AP(s) in SiteNamePlaceHolder.",
    "groupId": "global_wireless_cloud_throughput_issues_grouping",
    "groupName": "Drop in radio throughput for Cloud Applications",
    "scope": "GLOBAL",
    "priority": "P2",
    "rootCause": "Unknown",
    "timestamp": 1611292822894,
    "description": "Error: issue details are missing. Please check connectivity to AI Network Analytics cloud.",
    "suggestions": null,
    "additionalParams": [
      {
        "key": "groupType",
        "value": "ap_band"
      },
      {
        "key": "_name",
        "value": "global_wireless_kairos_radio_cloud_app_throughput_drop_trigger"
      },
      {
        "key": "_entity_type",
        "value": "global"
      },
      {
        "key": "eventSource",
        "value": "Network Device"
      },
      {
        "key": "groupBy",
        "value": "a1483ce0fd8abf62ec50"
      },
      {
        "key": "type",
        "value": "issue"
      },
      {
        "key": "_key",
        "value": "no_suppression"
      },
      {
        "key": "floorId",
        "value": ""
      },
      {
        "key": "_count",
        "value": "1"
      },
      {
        "key": "areaName",
        "value": "Global/SITE-RjbY"
      },
      {
        "key": "scope",
        "value": "global"
      },
      {
        "key": "_primary_key",
        "value": "no_suppression"
      },
      {
        "key": "band",
        "value": "5 GHz"
      },
      {
        "key": "floorName",
        "value": ""
      },
      {
        "key": "triggerSource",
        "value": "kairos"
      },
      {
        "key": "clientCount",
        "value": "11"
      },
      {
        "key": "siteType",
        "value": "building"
      },
      {
        "key": "deviceType",
        "value": "Access Point"
      },
      {
        "key": "severity",
        "value": "HIGH"
      },
      {
        "key": "deviceUuid",
        "value": "aes:LKiOpCT6I0fV5mc7Xh0xBjS7Xo7KbupkyJ3h7Cy6LPwm"
      },
      {
        "key": "deviceRole",
        "value": "Access Point"
      },
      {
        "key": "isAIDriven",
        "value": "Yes"
      },
      {
        "key": "numAps",
        "value": "0"
      },
      {
        "key": "kairosIssueId",
        "value": "a1483ce0fd8abf62ec50"
      },
      {
        "key": "deviceOS",
        "value": ""
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "nwDeviceName",
        "value": "aes:pUGlZ30jkzBQawp14xn5i6vJ5VZhoXLzN1x5bA=="
      },
      {
        "key": "_eventTime",
        "value": "1611292822894"
      },
      {
        "key": "_mostRecent",
        "value": "1611292822894"
      },
      {
        "key": "entityId",
        "value": "a1483ce0fd8abf62ec50"
      },
      {
        "key": "priority",
        "value": "P2"
      },
      {
        "key": "pctDeviation",
        "value": "78"
      },
      {
        "key": "buildingId",
        "value": "e92f6e81-f64d-4353-b254-e28e6bfb34ad"
      },
      {
        "key": "orgTriggerTime",
        "value": "1611338659278885"
      },
      {
        "key": "buildingName",
        "value": "Global/SITE-RjbY/BLD-LIDQ"
      },
      {
        "key": "areaId",
        "value": "f7ecf0c1-e59b-4155-a958-fd1d9533989c"
      },
      {
        "key": "siteHierarchy",
        "value": "Global/SITE-RjbY/BLD-LIDQ"
      },
      {
        "key": "deviceModel",
        "value": ""
      },
      {
        "key": "category",
        "value": "Application"
      },
      {
        "key": "timestring",
        "value": "2021-01-22T05:20:22.894+0000"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}
})