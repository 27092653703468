define({
  "getRfCoverageSummaryLatest01_5ghz_Template":[
          {
            "apDensity": "High",
            "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
            "connectivitySnr": 35,
            "connectivitySnrDensity": "High",
            "frequencyBand": 2,
            "totalApCount":23,
            "totalClients":38,
            "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
            "timestamp": "2022-03-18T09:00:00",
            "timestampMs": "1647594000000"
          }
  ],
  "getRfPerformanceSummaryLatest01_5ghz_Template":[
    {
      "apPercentageWithHighCci": 40,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "rrmHealthScore": 85,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-18T14:30:00",
      "timestampMs": "1647613800000",
      "totalRrmChangesV2": 16
    }
  ],
  "getCurrentInsights01_5ghz_Template":[
    {
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "description": "Consider changing the configured Busy Hours for RRM to be more effective.",
      "frequencyBand": 0,
      "insightType": "busy-hours",
      "insightValue": "[\"11:00\",\"15:00\"]",
      "reason": "Based on analyzing the wireless client association and usage patterns, we recommend this busy hour interval.",
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-09T00:00:00",
      "timestampMs": "1646784000000"
    },
    {
      "buildingId": "17ed4995-50f9-4e14-86aa-942d208fbd76",
      "description": "Consider expanding the configured Channel List for reduced neighbor contention and improved performance.",
      "frequencyBand": 2,
      "insightType": "channel-list",
      "insightValue": "[165]",
      "reason": "Expanding the channel list can reduce co-channel interference. It can improve performance by allowing DCA to choose from an additional 1 20MHz channels.",
      "siteId": "5ee9a76f-6496-45fd-8c6a-c2b57a275780",
      "timestamp": "2022-06-01T00:00:00",
      "timestampMs": "1654041600000"
    },
    {
      "buildingId": "17ed4995-50f9-4e14-86aa-942d208fbd76",
      "description": "Consider changing the configured Channel Width for improved performance.",
      "frequencyBand": 2,
      "insightType": "channel-width",
      "insightValue": "\"Best(40)\"",
      "reason": "Based on analyzing your neighbor density and client types, we recommend using Dynamic Bandwidth Selection (Auto Channel Width) with a max bandwidth of 40 MHz",
      "siteId": "5ee9a76f-6496-45fd-8c6a-c2b57a275780",
      "timestamp": "2022-06-01T00:00:00",
      "timestampMs": "1654041600000"
    }
  ],
  "getRrmChangesLatestSummaryV201_5ghz_Template":[
    {
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "rrmChanges": {
        "channelChangeCount": 2,
        "channelWidthChangeCount": 0,
        "fraChangeCount": 0,
        "totalImpactedRadios": 2,
        "totalRadios": 22,
        "txPowerChangeCount": 0
      },
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-21T04:30:00",
      "timestampMs": "1647837000000"
    }
  ],
  "getRrmHealthLatestSummary01_5ghz_Template":[
    {
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "rrmHealth": [
        {
          "apCount": 2,
          "healthScoreRange": "0-30",
          "healthScoreState": "Poor"
        },
        {
          "apCount": 2,
          "healthScoreRange": "31-60",
          "healthScoreState": "Fair"
        },
        {
          "apCount": 18,
          "healthScoreRange": "61-100",
          "healthScoreState": "Good"
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2023-03-28T17:00:00",
      "timestampMs": "1680022800000"
    }
  ],
  "getFraLatestDetail01_5GHZ_Template":
  {
    "apMac": "a0:f8:49:7e:c0:40",
    "apName": "SJC01_9136_1",
    "buildingId": "8d8ca37b-351f-90a0-de69-bde4101587ba",
    "radioType": 5,
    "suggestedMode": 30,
    "cofPercent": 10,
    "isClientServing": 5,
    "fraCapable": 20,
    "suggestedChannel": 50,
    "frequencyBand": 2,
    "memberId": "2a0c0421-0bbe-461c-a549-708847848397",
    "siteId": "8d8ca37b-351f-90a0-de69-bde4101587ba",
    "slot": 2,
    "timestamp": "2023-05-24T14:26:34",
    "timestampMs": 1684938394550
  },
  "getFraLatestSummary01_5ghz_Template": 
      {
        "buildingId": "624b038c-e463-4365-836c-9581e844b059",
        "siteId": "972ffb67-2cd3-48ab-94c5-6aba2ba90fd2",
        "timestamp": "2023-03-28T19:00:00",
        "timestampMs": "1680030000000",
        "total24Radios": 10,
        "total5Radios": 10,
        "total6Radios": 0,
        "totalFraCapableRadios": 20,
        "totalFraEnabledRadios": 18,
        "totalMonitorRadios": 0,
        "totalRadios": 21
      
  },
  "getCoChannelInterferenceLatestSummary01_5ghz_Template":[
    {
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelInterference": [
        {
          "apCount": 15,
          "impactScoreRange": "0-30",
          "impactScoreState": "Low"
        },
        {
          "apCount": 4,
          "impactScoreRange": "31-60",
          "impactScoreState": "Medium"
        },
        {
          "apCount": 3,
          "impactScoreRange": "61-100",
          "impactScoreState": "High"
        }
      ],
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-21T04:30:00",
      "timestampMs": "1647837000000"
    }
  ],
  "getUtilizationPerChannelLatestSummary01_5ghz_Template":[
    {
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-21T04:30:00",
      "timestampMs": "1647837000000",
      "utilByChannelV2": [
        {
          "channel": 36,
          "interference": 41,
          "txRxUtil": 1,
          "txRxMaxUtilization": 0,
          "txRxMinUtilization": 0
        },
        {
          "channel": 40,
          "interference": 1,
          "txRxUtil": 0,
          "txRxMaxUtilization": 0,
          "txRxMinUtilization": 0
        },
        {
          "channel": 44,
          "interference": 0,
          "txRxUtil": 0,
          "txRxMaxUtilization": 0,
          "txRxMinUtilization": 0
        },
        {
          "channel": 48,
          "interference": 0,
          "txRxUtil": 0,
          "txRxMaxUtilization": 0,
          "txRxMinUtilization": 0
        },
        {
          "channel": 52,
          "interference": 1,
          "txRxUtil": 0,
          "txRxMaxUtilization": 0,
          "txRxMinUtilization": 0
        },
        {
          "channel": 56,
          "interference": 10,
          "txRxUtil": 0,
          "txRxMaxUtilization": 0,
          "txRxMinUtilization": 0
        },
        {
          "channel": 60,
          "interference": 0,
          "txRxUtil": 0,
          "txRxMaxUtilization": 0,
          "txRxMinUtilization": 0
        },
        {
          "channel": 64,
          "interference": 15,
          "txRxUtil": 0,
          "txRxMaxUtilization": 0,
          "txRxMinUtilization": 0
        },
        {
          "channel": 100,
          "interference": 0,
          "txRxUtil": 0,
          "txRxMaxUtilization": 0,
          "txRxMinUtilization": 0
        },
        {
          "channel": 104,
          "interference": 0,
          "txRxUtil": 0,
          "txRxMaxUtilization": 0,
          "txRxMinUtilization": 0
        },
        {
          "channel": 108,
          "interference": 1,
          "txRxUtil": 0,
          "txRxMaxUtilization": 0,
          "txRxMinUtilization": 0
        },
        {
          "channel": 112,
          "interference": 1,
          "txRxUtil": 0,
          "txRxMaxUtilization": 0,
          "txRxMinUtilization": 0
        },
        {
          "channel": 116,
          "interference": 15,
          "txRxUtil": 0,
          "txRxMaxUtilization": 0,
          "txRxMinUtilization": 0
        },
        {
          "channel": 120,
          "interference": 5,
          "txRxUtil": 0,
          "txRxMaxUtilization": 0,
          "txRxMinUtilization": 0
        },
        {
          "channel": 124,
          "interference": 10,
          "txRxUtil": 0,
          "txRxMaxUtilization": 0,
          "txRxMinUtilization": 0
        },
        {
          "channel": 128,
          "interference": 6,
          "txRxUtil": 0,
          "txRxMaxUtilization": 0,
          "txRxMinUtilization": 0
        },
        {
          "channel": 132,
          "interference": 0,
          "txRxUtil": 0,
          "txRxMaxUtilization": 0,
          "txRxMinUtilization": 0
        },
        {
          "channel": 136,
          "interference": 10,
          "txRxUtil": 0,
          "txRxMaxUtilization": 0,
          "txRxMinUtilization": 0
        },
        {
          "channel": 140,
          "interference": 0,
          "txRxUtil": 0,
          "txRxMaxUtilization": 0,
          "txRxMinUtilization": 0
        },
        {
          "channel": 144,
          "interference": 5,
          "txRxUtil": 0,
          "txRxMaxUtilization": 0,
          "txRxMinUtilization": 0
        },
        {
          "channel": 149,
          "interference": 0,
          "txRxUtil": 0,
          "txRxMaxUtilization": 0,
          "txRxMinUtilization": 0
        },
        {
          "channel": 153,
          "interference": 0,
          "txRxUtil": 0,
          "txRxMaxUtilization": 0,
          "txRxMinUtilization": 0
        },
        {
          "channel": 157,
          "interference": 4,
          "txRxUtil": 0,
          "txRxMaxUtilization": 0,
          "txRxMinUtilization": 0
        },
        {
          "channel": 161,
          "interference": 4,
          "txRxUtil": 0,
          "txRxMaxUtilization": 0,
          "txRxMinUtilization": 0
        },
        {
          "channel": 165,
          "interference": 25,
          "txRxUtil": 0,
          "txRxMaxUtilization": 0,
          "txRxMinUtilization": 0
        }
      ]
    }
  ],
  "getApRogueAndRadarPerChannelLatestSummary01_5ghz_Template":[
    {
      "apByChannel": [
        {
          "apCount": 1,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 0,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 0,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 60,
          "radarCount": 3
        },
        {
          "apCount": 2,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 100,
          "radarCount": 1
        },
        {
          "apCount": 0,
          "channel": 104,
          "radarCount": 3
        },
        {
          "apCount": 1,
          "channel": 108,
          "radarCount": 0
        },
        {
          "apCount": 0,
          "channel": 112,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 116,
          "radarCount": 3
        },
        {
          "apCount": 1,
          "channel": 120,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 0,
          "channel": 128,
          "radarCount": 2
        },
        {
          "apCount": 2,
          "channel": 132,
          "radarCount": 3
        },
        {
          "apCount": 1,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 0,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 0,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 0,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 0,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 0,
          "channel": 165,
          "radarCount": 2
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-21T04:30:00",
      "timestampMs": "1647837000000"
    }
  ],
  "getApSpatialDensityLatestSummary01_5ghz_Template": [
    {
      "apSpatialDensity": [
        {
          "nbrApCount": 2,
          "nbrClientCount": 0,
          "nbrCountBin": "<5"
        },
        {
          "nbrApCount": 10,
          "nbrClientCount": 2,
          "nbrCountBin": "5-10"
        },
        {
          "nbrApCount": 8,
          "nbrClientCount": 1,
          "nbrCountBin": "10-15"
        },
        {
          "nbrApCount": 2,
          "nbrClientCount": 0,
          "nbrCountBin": "15-20"
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-21T04:30:00",
      "timestampMs": "1647837000000"
    }
  ],
  "getPowerDistributionLatestSummary01_5ghz_Template":[
    {
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "powerDistribution": [
        {
          "powerLevel": 1,
          "powerNeighbourCount": 5,
          "powerRadioCount": 2
        },
        {
          "powerLevel": 2,
          "powerNeighbourCount": 6,
          "powerRadioCount": 3
        },
        {
          "powerLevel": 3,
          "powerNeighbourCount": 8,
          "powerRadioCount": 4
        },
        {
          "powerLevel": 4,
          "powerNeighbourCount": 9,
          "powerRadioCount": 6
        },
        {
          "powerLevel": 5,
          "powerNeighbourCount": 10,
          "powerRadioCount": 3
        },
        {
          "powerLevel": 6,
          "powerNeighbourCount": 13,
          "powerRadioCount": 2
        },
        {
          "powerLevel": 7,
          "powerNeighbourCount": 10,
          "powerRadioCount": 1
        },
        {
          "powerLevel": 8,
          "powerNeighbourCount": 9,
          "powerRadioCount": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-21T04:30:00",
      "timestampMs": "1647837000000"
    }
  ],
  "getApSpatialDensityTrendSummary01_5ghz_Template": [
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T04:30:00",
          "timestampMs": "1647750600000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T05:00:00",
          "timestampMs": "1647752400000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T05:30:00",
          "timestampMs": "1647754200000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T06:00:00",
          "timestampMs": "1647756000000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T06:30:00",
          "timestampMs": "1647757800000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T07:00:00",
          "timestampMs": "1647759600000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T07:30:00",
          "timestampMs": "1647761400000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T08:00:00",
          "timestampMs": "1647763200000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T08:30:00",
          "timestampMs": "1647765000000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T09:00:00",
          "timestampMs": "1647766800000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T09:30:00",
          "timestampMs": "1647768600000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T10:00:00",
          "timestampMs": "1647770400000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T10:30:00",
          "timestampMs": "1647772200000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T11:00:00",
          "timestampMs": "1647774000000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T11:30:00",
          "timestampMs": "1647775800000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T12:00:00",
          "timestampMs": "1647777600000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T12:30:00",
          "timestampMs": "1647779400000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T13:00:00",
          "timestampMs": "1647781200000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T13:30:00",
          "timestampMs": "1647783000000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T14:00:00",
          "timestampMs": "1647784800000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T14:30:00",
          "timestampMs": "1647786600000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T15:00:00",
          "timestampMs": "1647788400000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T15:30:00",
          "timestampMs": "1647790200000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T16:00:00",
          "timestampMs": "1647792000000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T16:30:00",
          "timestampMs": "1647793800000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T17:00:00",
          "timestampMs": "1647795600000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T17:30:00",
          "timestampMs": "1647797400000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T18:00:00",
          "timestampMs": "1647799200000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T18:30:00",
          "timestampMs": "1647801000000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T19:00:00",
          "timestampMs": "1647802800000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T19:30:00",
          "timestampMs": "1647804600000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T20:00:00",
          "timestampMs": "1647806400000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T20:30:00",
          "timestampMs": "1647808200000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T21:00:00",
          "timestampMs": "1647810000000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T21:30:00",
          "timestampMs": "1647811800000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T22:00:00",
          "timestampMs": "1647813600000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T22:30:00",
          "timestampMs": "1647815400000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T23:00:00",
          "timestampMs": "1647817200000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-20T23:30:00",
          "timestampMs": "1647819000000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-21T00:00:00",
          "timestampMs": "1647820800000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-21T00:30:00",
          "timestampMs": "1647822600000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-21T01:00:00",
          "timestampMs": "1647824400000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-21T01:30:00",
          "timestampMs": "1647826200000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-21T02:00:00",
          "timestampMs": "1647828000000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-21T02:30:00",
          "timestampMs": "1647829800000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-21T03:00:00",
          "timestampMs": "1647831600000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-21T03:30:00",
          "timestampMs": "1647833400000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-21T04:00:00",
          "timestampMs": "1647835200000"
        },
        {
          "apSpatialDensity": [
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "<5"
            },
            {
              "nbrApCount": 10,
              "nbrClientCount": 0,
              "nbrCountBin": "5-10"
            },
            {
              "nbrApCount": 8,
              "nbrClientCount": 0,
              "nbrCountBin": "10-15"
            },
            {
              "nbrApCount": 2,
              "nbrClientCount": 0,
              "nbrCountBin": "15-20"
            }
          ],
          "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
          "frequencyBand": 2,
          "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
          "timestamp": "2022-03-21T04:30:00",
          "timestampMs": "1647837000000"
        }
  ],
  "getCoChannelInterferenceTrendSummary01_5ghz_Template":[
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T06:00:00",
        "timestampMs": "1647756000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T06:30:00",
        "timestampMs": "1647757800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T07:00:00",
        "timestampMs": "1647759600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T07:30:00",
        "timestampMs": "1647761400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T08:00:00",
        "timestampMs": "1647763200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T08:30:00",
        "timestampMs": "1647765000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T09:00:00",
        "timestampMs": "1647766800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T09:30:00",
        "timestampMs": "1647768600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T10:00:00",
        "timestampMs": "1647770400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T10:30:00",
        "timestampMs": "1647772200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T11:00:00",
        "timestampMs": "1647774000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T11:30:00",
        "timestampMs": "1647775800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T12:00:00",
        "timestampMs": "1647777600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T12:30:00",
        "timestampMs": "1647779400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T13:00:00",
        "timestampMs": "1647781200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T13:30:00",
        "timestampMs": "1647783000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T14:00:00",
        "timestampMs": "1647784800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T14:30:00",
        "timestampMs": "1647786600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T15:00:00",
        "timestampMs": "1647788400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T15:30:00",
        "timestampMs": "1647790200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T16:00:00",
        "timestampMs": "1647792000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T16:30:00",
        "timestampMs": "1647793800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T17:00:00",
        "timestampMs": "1647795600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T17:30:00",
        "timestampMs": "1647797400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T18:00:00",
        "timestampMs": "1647799200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T18:30:00",
        "timestampMs": "1647801000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T19:00:00",
        "timestampMs": "1647802800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T19:30:00",
        "timestampMs": "1647804600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T20:00:00",
        "timestampMs": "1647806400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T20:30:00",
        "timestampMs": "1647808200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T21:00:00",
        "timestampMs": "1647810000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T21:30:00",
        "timestampMs": "1647811800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T22:00:00",
        "timestampMs": "1647813600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T22:30:00",
        "timestampMs": "1647815400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T23:00:00",
        "timestampMs": "1647817200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-20T23:30:00",
        "timestampMs": "1647819000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 262,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 1,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T00:00:00",
        "timestampMs": "1647820800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T00:30:00",
        "timestampMs": "1647822600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T01:00:00",
        "timestampMs": "1647824400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T01:30:00",
        "timestampMs": "1647826200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T02:00:00",
        "timestampMs": "1647828000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T02:30:00",
        "timestampMs": "1647829800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T03:00:00",
        "timestampMs": "1647831600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T03:30:00",
        "timestampMs": "1647833400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T04:00:00",
        "timestampMs": "1647835200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "coChannelInterference": [
          {
            "apCount": 263,
            "impactScoreRange": "0-30",
            "impactScoreState": "Low"
          },
          {
            "apCount": 0,
            "impactScoreRange": "31-60",
            "impactScoreState": "Medium"
          },
          {
            "apCount": 0,
            "impactScoreRange": "61-100",
            "impactScoreState": "High"
          }
        ],
        "frequencyBand": 2,
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T04:30:00",
        "timestampMs": "1647837000000"
      }
  ],
  "getPowerDistributionTrendSummary01_5ghz_Template":[
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T05:00:00",
        "timestampMs": "1647838800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T05:30:00",
        "timestampMs": "1647840600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T06:00:00",
        "timestampMs": "1647842400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T06:30:00",
        "timestampMs": "1647844200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T07:00:00",
        "timestampMs": "1647846000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T07:30:00",
        "timestampMs": "1647847800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T08:00:00",
        "timestampMs": "1647849600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T08:30:00",
        "timestampMs": "1647851400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T09:00:00",
        "timestampMs": "1647853200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T09:30:00",
        "timestampMs": "1647855000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T10:00:00",
        "timestampMs": "1647856800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T10:30:00",
        "timestampMs": "1647858600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T11:00:00",
        "timestampMs": "1647860400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T11:30:00",
        "timestampMs": "1647862200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T12:00:00",
        "timestampMs": "1647864000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T12:30:00",
        "timestampMs": "1647865800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T13:00:00",
        "timestampMs": "1647867600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T13:30:00",
        "timestampMs": "1647869400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T14:00:00",
        "timestampMs": "1647871200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T15:00:00",
        "timestampMs": "1647874800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T15:30:00",
        "timestampMs": "1647876600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T16:00:00",
        "timestampMs": "1647878400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T16:30:00",
        "timestampMs": "1647880200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T17:00:00",
        "timestampMs": "1647882000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T17:30:00",
        "timestampMs": "1647883800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T18:00:00",
        "timestampMs": "1647885600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T18:30:00",
        "timestampMs": "1647887400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T19:00:00",
        "timestampMs": "1647889200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T19:30:00",
        "timestampMs": "1647891000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T20:00:00",
        "timestampMs": "1647892800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T22:00:00",
        "timestampMs": "1647900000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T22:30:00",
        "timestampMs": "1647901800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T23:00:00",
        "timestampMs": "1647903600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T23:30:00",
        "timestampMs": "1647905400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T00:00:00",
        "timestampMs": "1647907200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T00:30:00",
        "timestampMs": "1647909000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T01:00:00",
        "timestampMs": "1647910800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T01:30:00",
        "timestampMs": "1647912600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T02:00:00",
        "timestampMs": "1647914400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T02:30:00",
        "timestampMs": "1647916200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T03:00:00",
        "timestampMs": "1647918000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T03:30:00",
        "timestampMs": "1647919800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T04:00:00",
        "timestampMs": "1647921600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T04:30:00",
        "timestampMs": "1647923400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "powerDistribution": [
          {
            "powerLevel": 1,
            "powerNeighbourCount": 5,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 2,
            "powerNeighbourCount": 6,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 3,
            "powerNeighbourCount": 8,
            "powerRadioCount": 4
          },
          {
            "powerLevel": 4,
            "powerNeighbourCount": 9,
            "powerRadioCount": 6
          },
          {
            "powerLevel": 5,
            "powerNeighbourCount": 10,
            "powerRadioCount": 3
          },
          {
            "powerLevel": 6,
            "powerNeighbourCount": 13,
            "powerRadioCount": 2
          },
          {
            "powerLevel": 7,
            "powerNeighbourCount": 10,
            "powerRadioCount": 1
          },
          {
            "powerLevel": 8,
            "powerNeighbourCount": 9,
            "powerRadioCount": 1
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T05:00:00",
        "timestampMs": "1647925200000"
      }
  ],
  "getRrmChangesTrendSummaryV201_5ghz_Template":[
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 12,
          "channelWidthChangeCount": 2,
          "fraChangeCount": 0,
          "totalImpactedRadios": 12,
          "totalRadios": 263,
          "txPowerChangeCount": 1
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T05:30:00",
        "timestampMs": "1647840600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 8,
          "channelWidthChangeCount": 1,
          "fraChangeCount": 0,
          "totalImpactedRadios": 14,
          "totalRadios": 263,
          "txPowerChangeCount": 7
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T06:00:00",
        "timestampMs": "1647842400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 7,
          "channelWidthChangeCount": 1,
          "fraChangeCount": 0,
          "totalImpactedRadios": 8,
          "totalRadios": 263,
          "txPowerChangeCount": 3
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T06:30:00",
        "timestampMs": "1647844200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 4,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 4,
          "totalRadios": 263,
          "txPowerChangeCount": 1
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T07:00:00",
        "timestampMs": "1647846000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 3,
          "channelWidthChangeCount": 1,
          "fraChangeCount": 0,
          "totalImpactedRadios": 3,
          "totalRadios": 263,
          "txPowerChangeCount": 0
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T07:30:00",
        "timestampMs": "1647847800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 1,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 3,
          "totalRadios": 263,
          "txPowerChangeCount": 3
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T08:00:00",
        "timestampMs": "1647849600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 11,
          "channelWidthChangeCount": 3,
          "fraChangeCount": 0,
          "totalImpactedRadios": 13,
          "totalRadios": 263,
          "txPowerChangeCount": 3
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T08:30:00",
        "timestampMs": "1647851400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 10,
          "channelWidthChangeCount": 4,
          "fraChangeCount": 0,
          "totalImpactedRadios": 12,
          "totalRadios": 263,
          "txPowerChangeCount": 0
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T09:00:00",
        "timestampMs": "1647853200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 9,
          "channelWidthChangeCount": 3,
          "fraChangeCount": 0,
          "totalImpactedRadios": 12,
          "totalRadios": 263,
          "txPowerChangeCount": 2
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T09:30:00",
        "timestampMs": "1647855000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 11,
          "channelWidthChangeCount": 2,
          "fraChangeCount": 0,
          "totalImpactedRadios": 12,
          "totalRadios": 263,
          "txPowerChangeCount": 0
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T10:00:00",
        "timestampMs": "1647856800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 4,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 5,
          "totalRadios": 263,
          "txPowerChangeCount": 2
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T10:30:00",
        "timestampMs": "1647858600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 3,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 3,
          "totalRadios": 263,
          "txPowerChangeCount": 1
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T11:00:00",
        "timestampMs": "1647860400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 8,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 9,
          "totalRadios": 263,
          "txPowerChangeCount": 2
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T12:00:00",
        "timestampMs": "1647864000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 48,
          "channelWidthChangeCount": 28,
          "fraChangeCount": 0,
          "totalImpactedRadios": 81,
          "totalRadios": 292,
          "txPowerChangeCount": 28
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T12:30:00",
        "timestampMs": "1647865800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 14,
          "channelWidthChangeCount": 2,
          "fraChangeCount": 0,
          "totalImpactedRadios": 28,
          "totalRadios": 283,
          "txPowerChangeCount": 17
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T13:00:00",
        "timestampMs": "1647867600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 26,
          "channelWidthChangeCount": 3,
          "fraChangeCount": 0,
          "totalImpactedRadios": 32,
          "totalRadios": 283,
          "txPowerChangeCount": 13
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T13:30:00",
        "timestampMs": "1647869400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 13,
          "channelWidthChangeCount": 2,
          "fraChangeCount": 0,
          "totalImpactedRadios": 22,
          "totalRadios": 283,
          "txPowerChangeCount": 10
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T14:00:00",
        "timestampMs": "1647871200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 50,
          "channelWidthChangeCount": 14,
          "fraChangeCount": 0,
          "totalImpactedRadios": 61,
          "totalRadios": 293,
          "txPowerChangeCount": 24
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T15:00:00",
        "timestampMs": "1647874800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 4,
          "channelWidthChangeCount": 2,
          "fraChangeCount": 0,
          "totalImpactedRadios": 11,
          "totalRadios": 293,
          "txPowerChangeCount": 7
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T15:30:00",
        "timestampMs": "1647876600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 18,
          "channelWidthChangeCount": 2,
          "fraChangeCount": 0,
          "totalImpactedRadios": 24,
          "totalRadios": 293,
          "txPowerChangeCount": 7
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T16:00:00",
        "timestampMs": "1647878400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 10,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 14,
          "totalRadios": 293,
          "txPowerChangeCount": 4
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T16:30:00",
        "timestampMs": "1647880200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 63,
          "channelWidthChangeCount": 154,
          "fraChangeCount": 0,
          "totalImpactedRadios": 161,
          "totalRadios": 293,
          "txPowerChangeCount": 14
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T17:30:00",
        "timestampMs": "1647883800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 78,
          "channelWidthChangeCount": 208,
          "fraChangeCount": 0,
          "totalImpactedRadios": 216,
          "totalRadios": 293,
          "txPowerChangeCount": 21
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T18:30:00",
        "timestampMs": "1647887400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 19,
          "channelWidthChangeCount": 3,
          "fraChangeCount": 0,
          "totalImpactedRadios": 34,
          "totalRadios": 293,
          "txPowerChangeCount": 17
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T22:00:00",
        "timestampMs": "1647900000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 22,
          "channelWidthChangeCount": 3,
          "fraChangeCount": 0,
          "totalImpactedRadios": 30,
          "totalRadios": 293,
          "txPowerChangeCount": 14
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T22:30:00",
        "timestampMs": "1647901800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 14,
          "channelWidthChangeCount": 3,
          "fraChangeCount": 0,
          "totalImpactedRadios": 16,
          "totalRadios": 293,
          "txPowerChangeCount": 3
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T23:00:00",
        "timestampMs": "1647903600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 16,
          "channelWidthChangeCount": 2,
          "fraChangeCount": 0,
          "totalImpactedRadios": 18,
          "totalRadios": 293,
          "txPowerChangeCount": 3
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T23:30:00",
        "timestampMs": "1647905400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 12,
          "channelWidthChangeCount": 2,
          "fraChangeCount": 0,
          "totalImpactedRadios": 15,
          "totalRadios": 293,
          "txPowerChangeCount": 5
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T00:00:00",
        "timestampMs": "1647907200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 14,
          "channelWidthChangeCount": 2,
          "fraChangeCount": 0,
          "totalImpactedRadios": 18,
          "totalRadios": 293,
          "txPowerChangeCount": 6
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T00:30:00",
        "timestampMs": "1647909000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 4,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 9,
          "totalRadios": 293,
          "txPowerChangeCount": 6
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T01:00:00",
        "timestampMs": "1647910800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 13,
          "channelWidthChangeCount": 3,
          "fraChangeCount": 0,
          "totalImpactedRadios": 18,
          "totalRadios": 293,
          "txPowerChangeCount": 7
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T01:30:00",
        "timestampMs": "1647912600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 9,
          "channelWidthChangeCount": 2,
          "fraChangeCount": 0,
          "totalImpactedRadios": 9,
          "totalRadios": 293,
          "txPowerChangeCount": 1
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T02:00:00",
        "timestampMs": "1647914400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 6,
          "channelWidthChangeCount": 2,
          "fraChangeCount": 0,
          "totalImpactedRadios": 7,
          "totalRadios": 293,
          "txPowerChangeCount": 2
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T02:30:00",
        "timestampMs": "1647916200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 13,
          "channelWidthChangeCount": 3,
          "fraChangeCount": 0,
          "totalImpactedRadios": 14,
          "totalRadios": 293,
          "txPowerChangeCount": 2
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T03:00:00",
        "timestampMs": "1647918000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 12,
          "channelWidthChangeCount": 0,
          "fraChangeCount": 0,
          "totalImpactedRadios": 12,
          "totalRadios": 293,
          "txPowerChangeCount": 4
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T03:30:00",
        "timestampMs": "1647919800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 16,
          "channelWidthChangeCount": 4,
          "fraChangeCount": 0,
          "totalImpactedRadios": 18,
          "totalRadios": 293,
          "txPowerChangeCount": 3
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T04:00:00",
        "timestampMs": "1647921600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 13,
          "channelWidthChangeCount": 5,
          "fraChangeCount": 0,
          "totalImpactedRadios": 18,
          "totalRadios": 293,
          "txPowerChangeCount": 3
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T04:30:00",
        "timestampMs": "1647923400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmChanges": {
          "channelChangeCount": 14,
          "channelWidthChangeCount": 4,
          "fraChangeCount": 0,
          "totalImpactedRadios": 18,
          "totalRadios": 293,
          "txPowerChangeCount": 7
        },
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T05:00:00",
        "timestampMs": "1647925200000"
      }
  ],
  "getRrmHealthTrendSummary01_5ghz_Template":[
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 1,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 1,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 261,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T06:30:00",
        "timestampMs": "1647844200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 1,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 1,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 261,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T07:00:00",
        "timestampMs": "1647846000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 1,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 2,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 260,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T07:30:00",
        "timestampMs": "1647847800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 2,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 2,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 259,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T08:00:00",
        "timestampMs": "1647849600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 3,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 1,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 259,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T08:30:00",
        "timestampMs": "1647851400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 1,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 1,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 261,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T09:00:00",
        "timestampMs": "1647853200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 1,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 1,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 261,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T09:30:00",
        "timestampMs": "1647855000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 2,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 1,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 260,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T10:00:00",
        "timestampMs": "1647856800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 2,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 1,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 260,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T10:30:00",
        "timestampMs": "1647858600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 2,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 1,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 260,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T11:00:00",
        "timestampMs": "1647860400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 3,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 0,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 260,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T12:00:00",
        "timestampMs": "1647864000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 5,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 1,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 286,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T12:30:00",
        "timestampMs": "1647865800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 3,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 6,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 274,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T13:00:00",
        "timestampMs": "1647867600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 2,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 2,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 279,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T13:30:00",
        "timestampMs": "1647869400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 2,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 2,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 279,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T14:00:00",
        "timestampMs": "1647871200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 2,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 3,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 288,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T15:00:00",
        "timestampMs": "1647874800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 2,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 2,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 289,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T15:30:00",
        "timestampMs": "1647876600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 2,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 2,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 289,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T16:00:00",
        "timestampMs": "1647878400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 2,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 2,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 289,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T16:30:00",
        "timestampMs": "1647880200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 3,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 2,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 288,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T17:30:00",
        "timestampMs": "1647883800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 3,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 2,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 288,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T18:30:00",
        "timestampMs": "1647887400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 2,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 2,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 289,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T22:00:00",
        "timestampMs": "1647900000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 2,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 3,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 288,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T22:30:00",
        "timestampMs": "1647901800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 2,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 3,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 288,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T23:00:00",
        "timestampMs": "1647903600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 3,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 2,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 288,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-21T23:30:00",
        "timestampMs": "1647905400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 2,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 4,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 287,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T00:00:00",
        "timestampMs": "1647907200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 2,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 4,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 287,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T00:30:00",
        "timestampMs": "1647909000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 4,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 4,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 285,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T01:00:00",
        "timestampMs": "1647910800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 3,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 4,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 286,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T01:30:00",
        "timestampMs": "1647912600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 4,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 2,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 287,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T02:00:00",
        "timestampMs": "1647914400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 4,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 2,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 287,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T02:30:00",
        "timestampMs": "1647916200000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 3,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 3,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 287,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T03:00:00",
        "timestampMs": "1647918000000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 5,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 2,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 286,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T03:30:00",
        "timestampMs": "1647919800000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 4,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 2,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 287,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T04:00:00",
        "timestampMs": "1647921600000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 4,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 2,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 287,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T04:30:00",
        "timestampMs": "1647923400000"
      },
      {
        "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
        "frequencyBand": 2,
        "rrmHealth": [
          {
            "apCount": 4,
            "healthScoreRange": "0-30",
            "healthScoreState": "Poor"
          },
          {
            "apCount": 2,
            "healthScoreRange": "31-60",
            "healthScoreState": "Fair"
          },
          {
            "apCount": 287,
            "healthScoreRange": "61-100",
            "healthScoreState": "Good"
          }
        ],
        "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
        "timestamp": "2022-03-22T05:00:00",
        "timestampMs": "1647925200000"
      }
  ],
  "getUtilizationPerChannelTrendSummary01_5ghz_Template":[
    {
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T08:30:00",
      "timestampMs": "1648024200000",
      "utilByChannel": [
        {
          "channel": 36,
          "interference": 2,
          "txRxUtil": 30
        },
        {
          "channel": 40,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 44,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 48,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 52,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 56,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 60,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 64,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 100,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 104,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 108,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 112,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 116,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 120,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 124,
          "interference": 3,
          "txRxUtil": 0
        },
        {
          "channel": 128,
          "interference": 3,
          "txRxUtil": 0
        },
        {
          "channel": 132,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 136,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 140,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 144,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 149,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 153,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 157,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 161,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 165,
          "interference": 1,
          "txRxUtil": 0
        }
      ]
    },
    {
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T09:00:00",
      "timestampMs": "1648026000000",
      "utilByChannel": [
        {
          "channel": 36,
          "interference": 2,
          "txRxUtil": 29
        },
        {
          "channel": 40,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 44,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 48,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 52,
          "interference": 5,
          "txRxUtil": 0
        },
        {
          "channel": 56,
          "interference": 5,
          "txRxUtil": 0
        },
        {
          "channel": 60,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 64,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 100,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 104,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 108,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 112,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 116,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 120,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 124,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 128,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 132,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 136,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 140,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 144,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 149,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 153,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 157,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 161,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 165,
          "interference": 1,
          "txRxUtil": 0
        }
      ]
    },
    {
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T09:30:00",
      "timestampMs": "1648027800000",
      "utilByChannel": [
        {
          "channel": 36,
          "interference": 1,
          "txRxUtil":29
        },
        {
          "channel": 40,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 44,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 48,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 52,
          "interference": 5,
          "txRxUtil": 0
        },
        {
          "channel": 56,
          "interference": 5,
          "txRxUtil": 0
        },
        {
          "channel": 60,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 64,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 100,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 104,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 108,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 112,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 116,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 120,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 124,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 128,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 132,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 136,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 140,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 144,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 149,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 153,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 157,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 161,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 165,
          "interference": 1,
          "txRxUtil": 0
        }
      ]
    },
    {
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T10:00:00",
      "timestampMs": "1648029600000",
      "utilByChannel": [
        {
          "channel": 36,
          "interference": 2,
          "txRxUtil": 29
        },
        {
          "channel": 40,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 44,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 48,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 52,
          "interference": 5,
          "txRxUtil": 0
        },
        {
          "channel": 56,
          "interference": 5,
          "txRxUtil": 0
        },
        {
          "channel": 60,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 64,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 100,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 104,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 108,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 112,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 116,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 120,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 124,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 128,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 132,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 136,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 140,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 144,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 149,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 153,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 157,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 161,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 165,
          "interference": 1,
          "txRxUtil": 0
        }
      ]
    },
    {
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T10:30:00",
      "timestampMs": "1648031400000",
      "utilByChannel": [
        {
          "channel": 36,
          "interference": 2,
          "txRxUtil": 47
        },
        {
          "channel": 40,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 44,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 48,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 52,
          "interference": 4,
          "txRxUtil": 0
        },
        {
          "channel": 56,
          "interference": 4,
          "txRxUtil": 0
        },
        {
          "channel": 60,
          "interference": 3,
          "txRxUtil": 0
        },
        {
          "channel": 64,
          "interference": 3,
          "txRxUtil": 0
        },
        {
          "channel": 100,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 104,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 108,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 112,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 116,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 120,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 124,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 128,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 132,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 136,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 140,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 144,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 149,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 153,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 157,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 161,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 165,
          "interference": 1,
          "txRxUtil": 0
        }
      ]
    },
    {
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T11:00:00",
      "timestampMs": "1648033200000",
      "utilByChannel": [
        {
          "channel": 36,
          "interference": 1,
          "txRxUtil": 55
        },
        {
          "channel": 40,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 44,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 48,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 52,
          "interference": 4,
          "txRxUtil": 0
        },
        {
          "channel": 56,
          "interference": 4,
          "txRxUtil": 0
        },
        {
          "channel": 60,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 64,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 100,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 104,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 108,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 112,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 116,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 120,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 124,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 128,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 132,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 136,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 140,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 144,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 149,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 153,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 157,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 161,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 165,
          "interference": 1,
          "txRxUtil": 0
        }
      ]
    },
    {
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T11:30:00",
      "timestampMs": "1648035000000",
      "utilByChannel": [
        {
          "channel": 36,
          "interference": 1,
          "txRxUtil": 23
        },
        {
          "channel": 40,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 44,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 48,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 52,
          "interference": 5,
          "txRxUtil": 0
        },
        {
          "channel": 56,
          "interference": 5,
          "txRxUtil": 0
        },
        {
          "channel": 60,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 64,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 100,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 104,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 108,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 112,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 116,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 120,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 124,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 128,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 132,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 136,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 140,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 144,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 149,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 153,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 157,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 161,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 165,
          "interference": 1,
          "txRxUtil": 0
        }
      ]
    },
    {
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T12:00:00",
      "timestampMs": "1648036800000",
      "utilByChannel": [
        {
          "channel": 36,
          "interference": 1,
          "txRxUtil": 74
        },
        {
          "channel": 40,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 44,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 48,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 52,
          "interference": 5,
          "txRxUtil": 0
        },
        {
          "channel": 56,
          "interference": 5,
          "txRxUtil": 0
        },
        {
          "channel": 60,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 64,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 100,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 104,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 108,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 112,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 116,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 120,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 124,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 128,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 132,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 136,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 140,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 144,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 149,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 153,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 157,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 161,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 165,
          "interference": 1,
          "txRxUtil": 0
        }
      ]
    },
    {
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T12:30:00",
      "timestampMs": "1648038600000",
      "utilByChannel": [
        {
          "channel": 36,
          "interference": 1,
          "txRxUtil": 44
        },
        {
          "channel": 40,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 44,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 48,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 52,
          "interference": 5,
          "txRxUtil": 0
        },
        {
          "channel": 56,
          "interference": 5,
          "txRxUtil": 0
        },
        {
          "channel": 60,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 64,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 100,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 104,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 108,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 112,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 116,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 120,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 124,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 128,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 132,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 136,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 140,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 144,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 149,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 153,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 157,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 161,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 165,
          "interference": 1,
          "txRxUtil": 0
        }
      ]
    },
    {
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T13:00:00",
      "timestampMs": "1648040400000",
      "utilByChannel": [
        {
          "channel": 36,
          "interference": 1,
          "txRxUtil": 24
        },
        {
          "channel": 40,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 44,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 48,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 52,
          "interference": 5,
          "txRxUtil": 0
        },
        {
          "channel": 56,
          "interference": 5,
          "txRxUtil": 0
        },
        {
          "channel": 60,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 64,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 100,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 104,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 108,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 112,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 116,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 120,
          "interference": 2,
          "txRxUtil": 0
        },
        {
          "channel": 124,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 128,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 132,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 136,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 140,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 144,
          "interference": 0,
          "txRxUtil": 0
        },
        {
          "channel": 149,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 153,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 157,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 161,
          "interference": 1,
          "txRxUtil": 0
        },
        {
          "channel": 165,
          "interference": 1,
          "txRxUtil": 0
        }
      ]
    }
  ],
  "getApRogueRadarPerChannelTrendSummary01_5ghz_Template":[
    {
      "apByChannel": [
        {
          "apCount": 20,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 18,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 29,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 108,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 112,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 116,
          "radarCount": 0
        },
        {
          "apCount": 16,
          "channel": 120,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 22,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 16,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 24,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T10:00:00",
      "timestampMs": "1648029600000"
    },
    {
      "apByChannel": [
        {
          "apCount": 23,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 28,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 108,
          "radarCount": 6
        },
        {
          "apCount": 8,
          "channel": 112,
          "radarCount": 6
        },
        {
          "apCount": 7,
          "channel": 116,
          "radarCount": 6
        },
        {
          "apCount": 16,
          "channel": 120,
          "radarCount": 6
        },
        {
          "apCount": 22,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 29,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 15,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 25,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T10:30:00",
      "timestampMs": "1648031400000"
    },
    {
      "apByChannel": [
        {
          "apCount": 19,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 17,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 28,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 108,
          "radarCount": 1
        },
        {
          "apCount": 13,
          "channel": 112,
          "radarCount": 1
        },
        {
          "apCount": 7,
          "channel": 116,
          "radarCount": 1
        },
        {
          "apCount": 15,
          "channel": 120,
          "radarCount": 1
        },
        {
          "apCount": 14,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 22,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 16,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T11:00:00",
      "timestampMs": "1648033200000"
    },
    {
      "apByChannel": [
        {
          "apCount": 20,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 17,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 29,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 108,
          "radarCount": 1
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 1
        },
        {
          "apCount": 7,
          "channel": 116,
          "radarCount": 0
        },
        {
          "apCount": 16,
          "channel": 120,
          "radarCount": 0
        },
        {
          "apCount": 15,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 24,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 18,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 5,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 24,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T11:30:00",
      "timestampMs": "1648035000000"
    },
    {
      "apByChannel": [
        {
          "apCount": 21,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 17,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 28,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 104,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 108,
          "radarCount": 1
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 1
        },
        {
          "apCount": 8,
          "channel": 116,
          "radarCount": 3
        },
        {
          "apCount": 16,
          "channel": 120,
          "radarCount": 3
        },
        {
          "apCount": 14,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 24,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 17,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T12:00:00",
      "timestampMs": "1648036800000"
    },
    {
      "apByChannel": [
        {
          "apCount": 19,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 17,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 28,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 108,
          "radarCount": 2
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 2
        },
        {
          "apCount": 9,
          "channel": 116,
          "radarCount": 1
        },
        {
          "apCount": 16,
          "channel": 120,
          "radarCount": 1
        },
        {
          "apCount": 14,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 24,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 15,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T12:30:00",
      "timestampMs": "1648038600000"
    },
    {
      "apByChannel": [
        {
          "apCount": 19,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 18,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 28,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 104,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 108,
          "radarCount": 1
        },
        {
          "apCount": 15,
          "channel": 112,
          "radarCount": 1
        },
        {
          "apCount": 8,
          "channel": 116,
          "radarCount": 1
        },
        {
          "apCount": 17,
          "channel": 120,
          "radarCount": 1
        },
        {
          "apCount": 13,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 16,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 5,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T13:00:00",
      "timestampMs": "1648040400000"
    },
    {
      "apByChannel": [
        {
          "apCount": 21,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 19,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 28,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 108,
          "radarCount": 1
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 1
        },
        {
          "apCount": 8,
          "channel": 116,
          "radarCount": 0
        },
        {
          "apCount": 18,
          "channel": 120,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 15,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 5,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T13:30:00",
      "timestampMs": "1648042200000"
    },
    {
      "apByChannel": [
        {
          "apCount": 19,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 19,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 28,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 108,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 116,
          "radarCount": 0
        },
        {
          "apCount": 17,
          "channel": 120,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 24,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 17,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T14:00:00",
      "timestampMs": "1648044000000"
    },
    {
      "apByChannel": [
        {
          "apCount": 0,
          "channel": -116,
          "radarCount": 1
        },
        {
          "apCount": 0,
          "channel": -112,
          "radarCount": 1
        },
        {
          "apCount": 21,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 18,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 28,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 104,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 108,
          "radarCount": 1
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 1
        },
        {
          "apCount": 7,
          "channel": 116,
          "radarCount": 1
        },
        {
          "apCount": 17,
          "channel": 120,
          "radarCount": 1
        },
        {
          "apCount": 14,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 17,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T14:30:00",
      "timestampMs": "1648045800000"
    },
    {
      "apByChannel": [
        {
          "apCount": 21,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 18,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 28,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 104,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 108,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 116,
          "radarCount": 0
        },
        {
          "apCount": 17,
          "channel": 120,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 24,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 17,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T15:00:00",
      "timestampMs": "1648047600000"
    },
    {
      "apByChannel": [
        {
          "apCount": 24,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 21,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 28,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 108,
          "radarCount": 1
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 1
        },
        {
          "apCount": 7,
          "channel": 116,
          "radarCount": 2
        },
        {
          "apCount": 17,
          "channel": 120,
          "radarCount": 2
        },
        {
          "apCount": 14,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 24,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 17,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T15:30:00",
      "timestampMs": "1648049400000"
    },
    {
      "apByChannel": [
        {
          "apCount": 23,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 15,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 21,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 29,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 108,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 116,
          "radarCount": 0
        },
        {
          "apCount": 17,
          "channel": 120,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 15,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T16:00:00",
      "timestampMs": "1648051200000"
    },
    {
      "apByChannel": [
        {
          "apCount": 0,
          "channel": -112,
          "radarCount": 1
        },
        {
          "apCount": 23,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 16,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 15,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 21,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 28,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 108,
          "radarCount": 1
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 1
        },
        {
          "apCount": 7,
          "channel": 116,
          "radarCount": 1
        },
        {
          "apCount": 17,
          "channel": 120,
          "radarCount": 1
        },
        {
          "apCount": 14,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 22,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 15,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 15,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T16:30:00",
      "timestampMs": "1648053000000"
    },
    {
      "apByChannel": [
        {
          "apCount": 0,
          "channel": -112,
          "radarCount": 1
        },
        {
          "apCount": 22,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 15,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 21,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 29,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 104,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 108,
          "radarCount": 2
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 2
        },
        {
          "apCount": 7,
          "channel": 116,
          "radarCount": 2
        },
        {
          "apCount": 17,
          "channel": 120,
          "radarCount": 2
        },
        {
          "apCount": 14,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 22,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 17,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T17:00:00",
      "timestampMs": "1648054800000"
    },
    {
      "apByChannel": [
        {
          "apCount": 0,
          "channel": -116,
          "radarCount": 1
        },
        {
          "apCount": 21,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 17,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 5,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 21,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 27,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 104,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 108,
          "radarCount": 1
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 116,
          "radarCount": 0
        },
        {
          "apCount": 18,
          "channel": 120,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 5,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 22,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 18,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T17:30:00",
      "timestampMs": "1648056600000"
    },
    {
      "apByChannel": [
        {
          "apCount": 0,
          "channel": -112,
          "radarCount": 1
        },
        {
          "apCount": 22,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 16,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 5,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 21,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 27,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 104,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 108,
          "radarCount": 1
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 1
        },
        {
          "apCount": 7,
          "channel": 116,
          "radarCount": 1
        },
        {
          "apCount": 17,
          "channel": 120,
          "radarCount": 1
        },
        {
          "apCount": 14,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 5,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 22,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 17,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 24,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T18:00:00",
      "timestampMs": "1648058400000"
    },
    {
      "apByChannel": [
        {
          "apCount": 21,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 16,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 5,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 21,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 27,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 104,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 108,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 116,
          "radarCount": 0
        },
        {
          "apCount": 18,
          "channel": 120,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 5,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 22,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 17,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 24,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T18:30:00",
      "timestampMs": "1648060200000"
    },
    {
      "apByChannel": [
        {
          "apCount": 0,
          "channel": -116,
          "radarCount": 1
        },
        {
          "apCount": 0,
          "channel": -112,
          "radarCount": 1
        },
        {
          "apCount": 22,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 16,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 5,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 21,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 28,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 104,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 108,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 116,
          "radarCount": 1
        },
        {
          "apCount": 17,
          "channel": 120,
          "radarCount": 1
        },
        {
          "apCount": 14,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 21,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 18,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 24,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T19:00:00",
      "timestampMs": "1648062000000"
    },
    {
      "apByChannel": [
        {
          "apCount": 21,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 16,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 5,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 21,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 28,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 104,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 108,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 116,
          "radarCount": 0
        },
        {
          "apCount": 17,
          "channel": 120,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 21,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 18,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 24,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T19:30:00",
      "timestampMs": "1648063800000"
    },
    {
      "apByChannel": [
        {
          "apCount": 22,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 16,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 5,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 21,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 28,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 104,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 108,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 116,
          "radarCount": 1
        },
        {
          "apCount": 16,
          "channel": 120,
          "radarCount": 1
        },
        {
          "apCount": 13,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 21,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 18,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 24,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T20:00:00",
      "timestampMs": "1648065600000"
    },
    {
      "apByChannel": [
        {
          "apCount": 22,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 16,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 21,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 28,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 104,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 108,
          "radarCount": 2
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 2
        },
        {
          "apCount": 8,
          "channel": 116,
          "radarCount": 2
        },
        {
          "apCount": 16,
          "channel": 120,
          "radarCount": 2
        },
        {
          "apCount": 13,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 22,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 17,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T20:30:00",
      "timestampMs": "1648067400000"
    },
    {
      "apByChannel": [
        {
          "apCount": 0,
          "channel": -112,
          "radarCount": 1
        },
        {
          "apCount": 23,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 15,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 19,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 28,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 104,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 108,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 116,
          "radarCount": 0
        },
        {
          "apCount": 16,
          "channel": 120,
          "radarCount": 0
        },
        {
          "apCount": 15,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 21,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 16,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T21:00:00",
      "timestampMs": "1648069200000"
    },
    {
      "apByChannel": [
        {
          "apCount": 22,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 104,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 108,
          "radarCount": 1
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 1
        },
        {
          "apCount": 9,
          "channel": 116,
          "radarCount": 2
        },
        {
          "apCount": 21,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 15,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 19,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 28,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 16,
          "channel": 120,
          "radarCount": 2
        },
        {
          "apCount": 13,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 5,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T21:30:00",
      "timestampMs": "1648071000000"
    },
    {
      "apByChannel": [
        {
          "apCount": 22,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 19,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 28,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 104,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 108,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 116,
          "radarCount": 0
        },
        {
          "apCount": 17,
          "channel": 120,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 21,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 16,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T22:00:00",
      "timestampMs": "1648072800000"
    },
    {
      "apByChannel": [
        {
          "apCount": 24,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 19,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 28,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 104,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 108,
          "radarCount": 1
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 1
        },
        {
          "apCount": 9,
          "channel": 116,
          "radarCount": 2
        },
        {
          "apCount": 16,
          "channel": 120,
          "radarCount": 2
        },
        {
          "apCount": 13,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 21,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 17,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 24,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T22:30:00",
      "timestampMs": "1648074600000"
    },
    {
      "apByChannel": [
        {
          "apCount": 22,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 20,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 28,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 104,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 108,
          "radarCount": 1
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 1
        },
        {
          "apCount": 9,
          "channel": 116,
          "radarCount": 1
        },
        {
          "apCount": 16,
          "channel": 120,
          "radarCount": 1
        },
        {
          "apCount": 14,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 21,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 17,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 24,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T23:00:00",
      "timestampMs": "1648076400000"
    },
    {
      "apByChannel": [
        {
          "apCount": 0,
          "channel": -116,
          "radarCount": 1
        },
        {
          "apCount": 0,
          "channel": -112,
          "radarCount": 1
        },
        {
          "apCount": 22,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 15,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 19,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 28,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 104,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 108,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 116,
          "radarCount": 1
        },
        {
          "apCount": 17,
          "channel": 120,
          "radarCount": 1
        },
        {
          "apCount": 13,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 22,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 18,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 24,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-23T23:30:00",
      "timestampMs": "1648078200000"
    },
    {
      "apByChannel": [
        {
          "apCount": 0,
          "channel": -112,
          "radarCount": 1
        },
        {
          "apCount": 24,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 19,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 27,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 104,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 108,
          "radarCount": 1
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 1
        },
        {
          "apCount": 9,
          "channel": 116,
          "radarCount": 1
        },
        {
          "apCount": 16,
          "channel": 120,
          "radarCount": 1
        },
        {
          "apCount": 15,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 17,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-24T00:00:00",
      "timestampMs": "1648080000000"
    },
    {
      "apByChannel": [
        {
          "apCount": 22,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 20,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 27,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 104,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 108,
          "radarCount": 1
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 1
        },
        {
          "apCount": 9,
          "channel": 116,
          "radarCount": 1
        },
        {
          "apCount": 18,
          "channel": 120,
          "radarCount": 1
        },
        {
          "apCount": 15,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 15,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 17,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-24T00:30:00",
      "timestampMs": "1648081800000"
    },
    {
      "apByChannel": [
        {
          "apCount": 0,
          "channel": -116,
          "radarCount": 1
        },
        {
          "apCount": 0,
          "channel": -112,
          "radarCount": 1
        },
        {
          "apCount": 22,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 5,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 18,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 27,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 108,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 116,
          "radarCount": 0
        },
        {
          "apCount": 18,
          "channel": 120,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 5,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 24,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 17,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-24T01:00:00",
      "timestampMs": "1648083600000"
    },
    {
      "apByChannel": [
        {
          "apCount": 22,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 0
        },
        {
          "apCount": 18,
          "channel": 120,
          "radarCount": 0
        },
        {
          "apCount": 5,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 24,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 17,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 27,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 108,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 116,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 16,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 161,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-24T01:30:00",
      "timestampMs": "1648085400000"
    },
    {
      "apByChannel": [
        {
          "apCount": 21,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 18,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 27,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 108,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 116,
          "radarCount": 0
        },
        {
          "apCount": 15,
          "channel": 120,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 24,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 18,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-24T02:00:00",
      "timestampMs": "1648087200000"
    },
    {
      "apByChannel": [
        {
          "apCount": 21,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 15,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 16,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 30,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 108,
          "radarCount": 1
        },
        {
          "apCount": 17,
          "channel": 112,
          "radarCount": 1
        },
        {
          "apCount": 8,
          "channel": 116,
          "radarCount": 1
        },
        {
          "apCount": 16,
          "channel": 120,
          "radarCount": 1
        },
        {
          "apCount": 13,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 22,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 15,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 18,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 22,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-24T02:30:00",
      "timestampMs": "1648089000000"
    },
    {
      "apByChannel": [
        {
          "apCount": 21,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 20,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 31,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 108,
          "radarCount": 0
        },
        {
          "apCount": 16,
          "channel": 112,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 116,
          "radarCount": 0
        },
        {
          "apCount": 16,
          "channel": 120,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 21,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 15,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 16,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-24T03:00:00",
      "timestampMs": "1648090800000"
    },
    {
      "apByChannel": [
        {
          "apCount": 21,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 15,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 21,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 29,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 108,
          "radarCount": 0
        },
        {
          "apCount": 16,
          "channel": 112,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 116,
          "radarCount": 1
        },
        {
          "apCount": 16,
          "channel": 120,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 20,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 15,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 17,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-24T03:30:00",
      "timestampMs": "1648092600000"
    },
    {
      "apByChannel": [
        {
          "apCount": 20,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 15,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 20,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 30,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 108,
          "radarCount": 1
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 1
        },
        {
          "apCount": 6,
          "channel": 116,
          "radarCount": 0
        },
        {
          "apCount": 16,
          "channel": 120,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 20,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 15,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 18,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-24T04:00:00",
      "timestampMs": "1648094400000"
    },
    {
      "apByChannel": [
        {
          "apCount": 22,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 15,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 21,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 29,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 108,
          "radarCount": 1
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 1
        },
        {
          "apCount": 6,
          "channel": 116,
          "radarCount": 1
        },
        {
          "apCount": 17,
          "channel": 120,
          "radarCount": 1
        },
        {
          "apCount": 14,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 20,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 15,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 18,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-24T04:30:00",
      "timestampMs": "1648096200000"
    },
    {
      "apByChannel": [
        {
          "apCount": 20,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 16,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 5,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 21,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 30,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 108,
          "radarCount": 1
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 1
        },
        {
          "apCount": 6,
          "channel": 116,
          "radarCount": 1
        },
        {
          "apCount": 17,
          "channel": 120,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 20,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 15,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 18,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-24T05:00:00",
      "timestampMs": "1648098000000"
    },
    {
      "apByChannel": [
        {
          "apCount": 20,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 15,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 5,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 21,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 30,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 104,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 108,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 0
        },
        {
          "apCount": 5,
          "channel": 116,
          "radarCount": 2
        },
        {
          "apCount": 17,
          "channel": 120,
          "radarCount": 2
        },
        {
          "apCount": 12,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 20,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 18,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-24T05:30:00",
      "timestampMs": "1648099800000"
    },
    {
      "apByChannel": [
        {
          "apCount": 20,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 15,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 19,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 31,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 104,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 108,
          "radarCount": 2
        },
        {
          "apCount": 15,
          "channel": 112,
          "radarCount": 2
        },
        {
          "apCount": 6,
          "channel": 116,
          "radarCount": 2
        },
        {
          "apCount": 19,
          "channel": 120,
          "radarCount": 1
        },
        {
          "apCount": 12,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 20,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 19,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-24T06:00:00",
      "timestampMs": "1648101600000"
    },
    {
      "apByChannel": [
        {
          "apCount": 21,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 15,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 11,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 5,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 19,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 30,
          "channel": 100,
          "radarCount": 1
        },
        {
          "apCount": 0,
          "channel": 104,
          "radarCount": 1
        },
        {
          "apCount": 8,
          "channel": 108,
          "radarCount": 1
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 1
        },
        {
          "apCount": 7,
          "channel": 116,
          "radarCount": 1
        },
        {
          "apCount": 18,
          "channel": 120,
          "radarCount": 1
        },
        {
          "apCount": 13,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 20,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 19,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-24T06:30:00",
      "timestampMs": "1648103400000"
    },
    {
      "apByChannel": [
        {
          "apCount": 21,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 10,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 19,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 30,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 104,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 108,
          "radarCount": 2
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 2
        },
        {
          "apCount": 7,
          "channel": 116,
          "radarCount": 2
        },
        {
          "apCount": 18,
          "channel": 120,
          "radarCount": 2
        },
        {
          "apCount": 11,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 20,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 19,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 23,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-24T07:00:00",
      "timestampMs": "1648105200000"
    },
    {
      "apByChannel": [
        {
          "apCount": 19,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 14,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 5,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 20,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 31,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 104,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 108,
          "radarCount": 1
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 1
        },
        {
          "apCount": 7,
          "channel": 116,
          "radarCount": 1
        },
        {
          "apCount": 18,
          "channel": 120,
          "radarCount": 1
        },
        {
          "apCount": 12,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 20,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 19,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 24,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-24T07:30:00",
      "timestampMs": "1648107000000"
    },
    {
      "apByChannel": [
        {
          "apCount": 19,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 15,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 5,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 20,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 32,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 104,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 108,
          "radarCount": 1
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 1
        },
        {
          "apCount": 7,
          "channel": 116,
          "radarCount": 1
        },
        {
          "apCount": 18,
          "channel": 120,
          "radarCount": 1
        },
        {
          "apCount": 13,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 21,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 19,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 5,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 24,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-24T08:00:00",
      "timestampMs": "1648108800000"
    },
    {
      "apByChannel": [
        {
          "apCount": 19,
          "channel": 36,
          "radarCount": 0
        },
        {
          "apCount": 3,
          "channel": 40,
          "radarCount": 0
        },
        {
          "apCount": 15,
          "channel": 44,
          "radarCount": 0
        },
        {
          "apCount": 7,
          "channel": 48,
          "radarCount": 0
        },
        {
          "apCount": 9,
          "channel": 52,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 56,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 60,
          "radarCount": 0
        },
        {
          "apCount": 20,
          "channel": 64,
          "radarCount": 0
        },
        {
          "apCount": 29,
          "channel": 100,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 104,
          "radarCount": 0
        },
        {
          "apCount": 8,
          "channel": 108,
          "radarCount": 1
        },
        {
          "apCount": 14,
          "channel": 112,
          "radarCount": 1
        },
        {
          "apCount": 7,
          "channel": 116,
          "radarCount": 2
        },
        {
          "apCount": 19,
          "channel": 120,
          "radarCount": 2
        },
        {
          "apCount": 12,
          "channel": 124,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 128,
          "radarCount": 0
        },
        {
          "apCount": 6,
          "channel": 132,
          "radarCount": 0
        },
        {
          "apCount": 22,
          "channel": 136,
          "radarCount": 0
        },
        {
          "apCount": 12,
          "channel": 140,
          "radarCount": 0
        },
        {
          "apCount": 13,
          "channel": 144,
          "radarCount": 0
        },
        {
          "apCount": 19,
          "channel": 149,
          "radarCount": 0
        },
        {
          "apCount": 2,
          "channel": 153,
          "radarCount": 0
        },
        {
          "apCount": 4,
          "channel": 157,
          "radarCount": 0
        },
        {
          "apCount": 24,
          "channel": 161,
          "radarCount": 0
        },
        {
          "apCount": 1,
          "channel": 165,
          "radarCount": 0
        }
      ],
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-24T08:30:00",
      "timestampMs": "1648110600000"
    }
  ],
  "getRrmChangesLatestDetail01_5ghz_Template":[
    {
      "apMac": "70:69:5A:78:51:C0",
      "apName": "csg-bgl18-72a-cap25",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "8df71aaa-fa34-4f4a-82c8-a224efbc9380",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 20 MHz.",
            " -Channel bandwidth quality is improved  19 percentage.",
            " -DBS selects the best channel width to increase channel bandwidth."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-31T04:00:00",
      "timestampMs": "1648699200000"
    },
    {
      "apMac": "70:69:5A:78:64:80",
      "apName": "csg-bgl18-72a-cap31",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "30bbfea2-1f67-4eb9-b761-250e0180cd56",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 20 MHz.",
            " -Channel bandwidth quality is improved  100 percentage.",
            " -DBS selects the best channel width to increase channel bandwidth."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-31T04:00:00",
      "timestampMs": "1648699200000"
    },
    {
      "apMac": "70:69:5A:78:73:40",
      "apName": "csg-bgl18-72a-cap45",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "00e899fc-54d1-40ed-a021-fbb8b1610698",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 20 MHz.",
            " -Channel bandwidth quality is improved  17 percentage.",
            " -DBS selects the best channel width to increase channel bandwidth."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-31T04:00:00",
      "timestampMs": "1648699200000"
    }
  ],
  "getRrmChangesTrendDetail01_5ghz_Template":[
    {
      "apMac": "D4:E8:80:19:AD:A0",
      "apName": "csg-bgl18-82a-cap45",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "8e08e161-5e08-40cb-b2d2-42a9e42a1b98",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 112 from 140.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  71 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "0C:D0:F8:94:AC:A0",
      "apName": "csg-bgl18-71a-cap06",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "83c44163-2ade-4ffe-b580-d542b51c7450",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 64 from 56.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  54 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:86:E0",
      "apName": "csg-bgl18-81a-cap19",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "f2fc2efa-d54b-44c0-8b53-a277932cfbde",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  73 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "F4:DB:E6:85:6D:00",
      "apName": "csg-bgl18-42a-cap27",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "73be2359-6848-461e-983f-301ae7a908c2",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  68 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "00:A7:42:AC:C8:20",
      "apName": "csg-bgl18-51a-cap03",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "70ca82ab-55ab-4559-9256-1869459d1417",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 64 from 108.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Tx Power Change",
          "changeReasons": [
            "[System Driven Tx Power Change]",
            " -Current Tx Power Level 2 (15 dBm) is adjusted to Tx Power Level 3 (14 dBm) by channel changing from 108 to 64.",
            " -Channel Max Power is 20 dBm and threshold is -60."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:B2:80",
      "apName": "csg-bgl18-82a-cap49",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "5ea672d0-1f92-4dfd-894d-2d416655a502",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  70 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:27:18:20",
      "apName": "csg-bgl18-02a-cap30",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "c166bed0-6f0e-4bcf-bf5c-a1bac026d398",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  91 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "F8:0B:CB:E8:65:80",
      "apName": "csg-bgl18-32a-cap33",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "587f32c4-376c-4244-801c-090387d4dcca",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 149 from 60.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  63 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Tx Power Change",
          "changeReasons": [
            "[System Driven Tx Power Change]",
            " -Current Tx Power Level 4 (10 dBm) is adjusted to Tx Power Level 6 (8 dBm) by channel changing from 60 to 149.",
            " -Channel Max Power is 23 dBm and threshold is -60."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:D5:E0",
      "apName": "csg-bgl18-61a-cap19",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "a737db48-de6a-4569-aed4-2f2814108ee7",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  58 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:2C:64:60",
      "apName": "csg-bgl18-02a-cap38",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "01426385-7b38-4f91-9521-f55d40c2c4c7",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  88 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:8E:80",
      "apName": "csg-bgl18-82a-cap38",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "5433f8d7-2726-4808-a2d6-1dab372589b6",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  71 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "00:A7:42:AC:C6:60",
      "apName": "csg-bgl18-51a-cap13",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "2f75a5e4-0c20-44bd-8ce5-a52ceb8c6c69",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  72 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:B5:00",
      "apName": "csg-bgl18-82a-cap48",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "0956ff15-f06e-4015-a7e0-d74b203eedb0",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  69 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:4C:40",
      "apName": "csg-bgl18-62a-cap28",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "60d12f58-77d6-4520-83fa-bd899b688a8d",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  61 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "F4:DB:E6:61:9E:20",
      "apName": "csg-bgl18-41a-cap08",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "2ae4cf9d-abd7-4cbb-8f76-0f1c593f731b",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 124 from 120.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  76 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:7D:B9:A5:4D:40",
      "apName": "csg-bgl18-01a-cap02",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "ad008064-4e75-4879-9797-1d73efc5ee9a",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  77 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "50:0F:80:5C:2A:E0",
      "apName": "csg-bgl18-32a-cap49",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "f14afd10-3e63-4c11-94b3-42ce3990c0b9",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 64 from 60.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Tx Power Change",
          "changeReasons": [
            "[System Driven Tx Power Change]",
            " -Current Tx Power Level 2 (16 dBm) is adjusted to Tx Power Level 3 (14 dBm) by channel changing from 60 to 64.",
            " -Channel Max Power is 20 dBm and threshold is -60."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:14:FD:C0",
      "apName": "csg-bgl18-32a-cap36",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "05c21720-6db9-4340-8ef2-a5693e12b3b0",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  74 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:8F:C0",
      "apName": "csg-bgl18-82a-cap43",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "5113853b-099e-4d92-a165-df0c4d8ae9bc",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  69 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:20:9A:40",
      "apName": "csg-bgl18-52a-cap38",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "24f22ff1-ff0b-40be-8e96-3ca778bb2ca8",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 100 from 108.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  70 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:07:A4:60",
      "apName": "csg-bgl18-52a-cap42",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "4026991e-a10a-4fa7-a866-c19d6569016f",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  70 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:66:20",
      "apName": "csg-bgl18-62a-cap45",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "536e65e6-d5b5-48f1-8945-1ac9424b1446",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  60 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:60:00:00",
      "apName": "csg-bgl18-61a-cap18",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "ffe7cc3f-37b4-4185-8507-0c3f7442e665",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  65 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "B4:DE:31:DB:84:A0",
      "apName": "csg-bgl18-51a-cap15",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "650dcecf-15fe-4186-af3a-8267c1bb3fb7",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 140 from 136."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  69 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "0C:D0:F8:95:26:E0",
      "apName": "csg-bgl18-71a-cap18",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "e3229207-5a95-4303-8d1e-db780a27f96f",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  41 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "F8:0B:CB:3A:25:20",
      "apName": "csg-bgl18-52a-cap36",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "fb71e6da-f21d-4939-9b2b-66427f30e9e0",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  64 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:A7:40",
      "apName": "csg-bgl18-82a-cap32",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "60cb8dae-e77e-4f11-a456-f339cb6e7376",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  72 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:4B:E0",
      "apName": "csg-bgl18-62a-cap44",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "3219779a-a9f1-463f-bfb3-d19980412da7",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  58 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "F4:DB:E6:44:83:40",
      "apName": "csg-bgl18-41a-cap14",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "6c2eb5f1-883d-4218-9cc5-3995825de004",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 108 from 64.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:14:E6:20",
      "apName": "csg-bgl18-32a-cap35",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "b334ee16-100c-4147-b109-c4c628e23f2e",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  61 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:60:01:80",
      "apName": "csg-bgl18-61a-cap21",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "45c899bb-2ea1-4283-a24a-bae37b2ed9fc",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  65 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "F4:DB:E6:84:D3:60",
      "apName": "csg-bgl18-42a-cap25",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "240a047a-6ae2-444e-9a69-4d01737a7f19",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  71 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:70:8B:C6:F5:40",
      "apName": "csg-bgl18-32a-cap26",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "718b3c5b-7f0d-4090-b1fd-7e2d4d00bdda",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  81 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:10:D0:60",
      "apName": "csg-bgl18-51a-cap05",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "788280c3-ceac-4f94-8da0-253a9b81822a",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 44 from 36.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  77 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "0C:D0:F8:94:72:60",
      "apName": "csg-bgl18-71a-cap10",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "57af2916-df8b-4716-ad59-a8430b5895ed",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  52 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:B5:C0",
      "apName": "csg-bgl18-81a-cap01",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "52f50e37-a7ff-460a-a698-51235d8a4562",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  72 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "00:5D:73:AE:54:C0",
      "apName": "csg-bgl18-12a-cap43",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "f5553469-5d0f-4230-a3b6-da65380feed8",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 52 from 64.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  72 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:7D:B9:A6:F4:40",
      "apName": "csg-bgl18-01a-cap11",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "ce777d4b-d4f0-4340-ad8b-44b2c9d7c2d1",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 149 from 52.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "00:5D:73:AE:3F:C0",
      "apName": "csg-bgl18-12a-cap36",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "e7d02dbd-376f-425d-88e0-dc2159446de0",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 136 from 157.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "0C:D0:F8:95:23:60",
      "apName": "csg-bgl18-71a-cap15",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "fc236b7c-3ba2-4b98-9ef9-a6b496a842ce",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  39 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:8C:E0",
      "apName": "csg-bgl18-82a-cap30",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "af027d36-42b2-4c94-8232-3e2db6c82364",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 48 from 140.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  84 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:10:CE:C0",
      "apName": "csg-bgl18-02a-cap33",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "b8c58aa0-6898-4842-aa3e-0fd2ef98c3e9",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 144 from 136.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  87 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:BB:40",
      "apName": "csg-bgl18-61a-cap15",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "db0ec60b-5c16-4b02-809b-d2493794e44d",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  68 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:7D:30",
      "apName": "csg-bgl18-62a-cap41",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "0034581f-f6bb-4d68-a4b4-d68c950f3be1",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 120 from 44.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  67 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Tx Power Change",
          "changeReasons": [
            "[System Driven Tx Power Change]",
            " -Current Tx Power Level 5 (12 dBm) is adjusted to Tx Power Level 4 (10 dBm) by channel changing from 44 to 120.",
            " -Channel Max Power is 19 dBm and threshold is -60."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:7D:B9:A6:EE:C0",
      "apName": "csg-bgl18-01a-cap17",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "3ba6bbf6-4655-48e6-aeb9-2c5004f21c9b",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  71 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:10:CF:60",
      "apName": "csg-bgl18-51a-cap23",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "ee6c04a5-111a-4049-a4ba-171320a955a0",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 161 from 149.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  78 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "B4:DE:31:D0:8C:80",
      "apName": "csg-bgl18-51a-cap09",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "48e093e9-2c83-4585-8139-83fcb00b47b3",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  84 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "B4:DE:31:D0:8C:C0",
      "apName": "csg-bgl18-51a-cap11",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "d6339662-e7d1-418e-93a5-92bd97ce68c2",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  59 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "B4:DE:31:C6:0B:80",
      "apName": "csg-bgl18-31a-cap11",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "df58b378-2d3d-4b44-9d16-c975581c4dac",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 116 from 60.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "B4:DE:31:BF:67:A0",
      "apName": "csg-bgl18-31a-cap03",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "ec1ab407-f9e1-4c1c-bbcd-63ffb82e03af",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  60 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:7D:B9:A5:4F:00",
      "apName": "csg-bgl18-01a-cap21",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "2461ac4f-a229-4a55-97dc-1416d7232fce",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 108 from 100.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  82 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:14:B5:00",
      "apName": "csg-bgl18-52a-cap39",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "040530f7-67a0-4ed6-89a8-ef9d7d5e1881",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 48 from 56.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  63 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Tx Power Change",
          "changeReasons": [
            "[System Driven Tx Power Change]",
            " -Current Tx Power Level 3 (12 dBm) is adjusted to Tx Power Level 5 (10 dBm) by channel changing from 56 to 48.",
            " -Channel Max Power is 22 dBm and threshold is -60."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "F4:DB:E6:84:5C:40",
      "apName": "csg-bgl18-42a-cap32",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "3f792bae-d366-4e2d-9805-ac0681b9913d",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  92 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:69:5A:78:4E:A0",
      "apName": "csg-bgl18-72a-cap43",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "cdb44a40-d0dc-4522-b25c-9464fa162788",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 64 from 44.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  77 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "F8:B7:E2:C3:02:00",
      "apName": "csg-bgl18-11a-cap02",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "77b46025-444c-41f8-a1c7-82529bce8a22",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 149 from 120.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  75 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:14:B3:C0",
      "apName": "csg-bgl18-52a-cap50",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "76b437f0-b2b8-451a-8913-b653291e2f13",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  71 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:37:81:40",
      "apName": "csg-bgl18-52a-cap48",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "4f959337-4fae-41da-8f7e-53507e52f466",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 120 from 136.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  69 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "B4:DE:31:DB:84:00",
      "apName": "csg-bgl18-51a-cap24",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "9884524b-806f-464c-8381-77e37c95c85d",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  68 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:B2:C0",
      "apName": "csg-bgl18-82a-cap50",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "79718ccb-9158-45f5-8a56-fdea6e9553f9",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  65 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "B4:DE:31:C4:BA:A0",
      "apName": "csg-bgl18-31a-cap13",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "2a3f793c-9691-4118-a21f-493ecbdc31af",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 44 from 36.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  75 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "0C:D0:F8:94:74:E0",
      "apName": "csg-bgl18-71a-cap22",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "6a2b3af5-534b-4aae-8baa-e82f1c4f05d5",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  56 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:5E:10",
      "apName": "csg-bgl18-62a-cap39",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "20871b7c-d507-4bf2-bfab-ab14b29b107e",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  62 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:37:82:C0",
      "apName": "csg-bgl18-32a-cap45",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "b17551ea-c67f-40a6-9a32-1e60a20cb844",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 20 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  73 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:69:5A:78:73:00",
      "apName": "csg-bgl18-72a-cap36",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "8ef83bb2-2130-4af0-b305-606b49079388",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 108 from 100.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:EA:20",
      "apName": "csg-bgl18-61a-cap23",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "6f33bb5f-40f5-4d90-aa1d-8aef3b40b665",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  61 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:70:8B:B5:E0:80",
      "apName": "csg-bgl18-32a-cap29",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "4b3cc406-f0c0-4307-a4fe-fbfcbe168dfc",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 52 from 44."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  63 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Tx Power Change",
          "changeReasons": [
            "[System Driven Tx Power Change]",
            " -Current Tx Power Level 4 (13 dBm) is adjusted to Tx Power Level 3 (11 dBm) by channel changing from 44 to 52.",
            " -Channel Max Power is 17 dBm and threshold is -60."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:7B:20",
      "apName": "csg-bgl18-62a-cap25",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "39f204d6-23d9-4b0e-8417-19a30bdec619",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  69 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:69:5A:78:5F:20",
      "apName": "csg-bgl18-72a-cap49",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "ff5c616a-ffec-4973-9f2d-8549679b7bb1",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 108 from 124.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "B4:DE:31:C4:B9:40",
      "apName": "csg-bgl18-31a-cap08",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "ddbf9611-6558-42bb-b434-a4e27a7237f7",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  65 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "B4:DE:31:D4:14:80",
      "apName": "csg-bgl18-51a-cap01",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "b2a9eedf-ac2f-401f-856c-19c28cc8a050",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  66 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:63:80",
      "apName": "csg-bgl18-61a-cap10",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "2980b56a-1d47-4a7f-b56d-1a1c2d3cdedd",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  65 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:9F:E0",
      "apName": "csg-bgl18-82a-cap36",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "841a9686-1d16-4f1e-8d92-68b481e79b86",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 64 from 56.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  83 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "00:5D:73:AE:21:A0",
      "apName": "csg-bgl18-12a-cap34",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "f9b78908-9824-44b1-b63b-d83832b4471c",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  84 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:6C:B0",
      "apName": "csg-bgl18-62a-cap48",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "406bcfff-30ee-43cc-a190-55d36340bc72",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 60 from 52.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  67 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:79:60",
      "apName": "csg-bgl18-82a-cap41",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "5adaaef7-8bbe-4780-ba58-9757496eb918",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  70 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:50:F0",
      "apName": "csg-bgl18-62a-cap38",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "de8ed355-4fdd-417e-b51c-817aaedc5ee3",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 149 from 161.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  66 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "A0:E0:AF:4D:78:00",
      "apName": "csg-bgl18-02a-cap42",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "9a34c1e4-06c0-4b6e-9ed4-a3bee5eb20e3",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  71 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:B0:F0",
      "apName": "csg-bgl18-61a-cap03",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "34b13169-daea-4191-979a-5227e3ac5365",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  64 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:AB:00",
      "apName": "csg-bgl18-81a-cap06",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "49f2ed23-6cb7-4fa9-873f-2338ebab7e1f",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  73 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:37:6E:40",
      "apName": "csg-bgl18-32a-cap48",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "64faa989-2944-4609-9149-0d04adb088d5",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 157 from 100.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  74 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "F4:DB:E6:84:C1:E0",
      "apName": "csg-bgl18-42a-cap30",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "309f6d0f-797f-47af-a9f3-584dd9b5869d",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 44 from 136.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  83 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "F4:DB:E6:44:83:C0",
      "apName": "csg-bgl18-41a-cap06",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "e1eb78cf-5705-452e-9875-4b1957b1ee88",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 64 from 56.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  69 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "B4:DE:31:BE:EE:00",
      "apName": "csg-bgl18-31a-cap09",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "24d6e6f2-d634-48fe-b097-1720e3562320",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 136 from 140.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  69 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:70:8B:CF:13:40",
      "apName": "csg-bgl18-31a-cap17",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "28872589-9857-48e7-93f7-5f332265c8c4",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  71 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:B5:A0",
      "apName": "csg-bgl18-81a-cap24",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "c22a14ec-35ec-4c67-b75e-337d718f6c15",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  61 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:20:9D:00",
      "apName": "csg-bgl18-52a-cap47",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "8434dbd6-7601-4bdb-a742-1212cb2ad816",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  74 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:F1:10",
      "apName": "csg-bgl18-61a-cap06",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "a017d2f8-6395-4d63-b89f-db0ed8887cea",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  64 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:37:6B:20",
      "apName": "csg-bgl18-52a-cap45",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "b3acc3fc-0690-4811-9873-55d62d6fdb6d",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 56 from 149.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  66 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Tx Power Change",
          "changeReasons": [
            "[System Driven Tx Power Change]",
            " -Current Tx Power Level 4 (14 dBm) is adjusted to Tx Power Level 3 (12 dBm) by channel changing from 149 to 56.",
            " -Channel Max Power is 18 dBm and threshold is -60."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:A8:C0",
      "apName": "csg-bgl18-82a-cap39",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "d1db1eec-c3e4-4e29-89e6-a2c6010ccb83",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  69 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:69:5A:78:72:00",
      "apName": "csg-bgl18-72a-cap48",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "d1e54c94-7baa-4ded-b519-fd4ac3c2cc4f",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  69 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:14:FD:A0",
      "apName": "csg-bgl18-52a-cap43",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "9b8f1467-e192-48a1-94bf-1f21fb0824c3",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 140 from 136.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  79 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:8A:60",
      "apName": "csg-bgl18-81a-cap14",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "e20569ee-bf8c-418b-a0fe-88b6037f894c",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  73 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:83:80",
      "apName": "csg-bgl18-81a-cap07",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "bc429ae7-c8be-4252-8367-62704104c536",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  71 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "2C:D0:2D:E3:42:40",
      "apName": "csg-bgl18-52a-cap41",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "3919ade3-013a-4cf0-9e9a-39147ce53c51",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 149 from 108.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  82 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Tx Power Change",
          "changeReasons": [
            "[System Driven Tx Power Change]",
            " -Current Tx Power Level 1 (18 dBm) is adjusted to Tx Power Level 3 (17 dBm) by channel changing from 108 to 149.",
            " -Channel Max Power is 23 dBm and threshold is -60."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:7A:C0",
      "apName": "csg-bgl18-62a-cap26",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "d94cfee5-62ec-4316-9e85-eaff6fdd236d",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 120 from 36.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  70 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "00:A7:42:AC:B2:C0",
      "apName": "csg-bgl18-51a-cap16",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "9824e778-db49-44eb-b1fd-aa8a73152f9f",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  70 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "0C:D0:F8:94:97:60",
      "apName": "csg-bgl18-71a-cap03",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "20af7458-e7eb-4f01-8935-994ffe0c538d",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  64 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "F4:DB:E6:44:8D:C0",
      "apName": "csg-bgl18-42a-cap36",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "150092dd-7c84-4f07-9030-2051cd5ea896",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 161 from 149.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  75 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:69:5A:76:C7:C0",
      "apName": "csg-bgl18-72a-cap29",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "37822e26-2fd5-45fe-beea-badab5bf43e0",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  77 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:69:5A:78:74:C0",
      "apName": "csg-bgl18-72a-cap30",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "831dbd35-5e1d-46ff-9710-5e527a7c19dc",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 100 from 44.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  64 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:07:98:C0",
      "apName": "csg-bgl18-32a-cap39",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "48ad9f0d-0bc0-4823-8cb0-2287aef1c2f2",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 116 from 124.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  68 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "0C:D0:F8:94:8E:C0",
      "apName": "csg-bgl18-71a-cap24",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "6ce44655-7a09-4076-bfa8-3f77e2e70972",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 36 from 64."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  54 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:EA:1A:C6:FD:E0",
      "apName": "csg-bgl18-11a-cap18",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "b5b3238f-731c-4bd0-b874-ab48d70976ea",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 128 from 100.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:89:E0",
      "apName": "csg-bgl18-82a-cap37",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "b081296d-f68d-484e-9562-9c27c742c1eb",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  80 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:69:5A:78:51:C0",
      "apName": "csg-bgl18-72a-cap25",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "8df71aaa-fa34-4f4a-82c8-a224efbc9380",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 124 from 64.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  75 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:6C:50",
      "apName": "csg-bgl18-62a-cap37",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "02676456-c479-4a53-a4f9-1c4b49ad0b1a",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 124 from 120.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  82 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:13:C9:C0",
      "apName": "csg-bgl18-02a-cap27",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "68ebfe5b-4dbb-4c5c-ab73-acf6077d1f29",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  92 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "0C:D0:F8:94:6D:20",
      "apName": "csg-bgl18-71a-cap02",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "dd770860-991d-4458-809c-4630e6c18609",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 149 from 161.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  69 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:79:70",
      "apName": "csg-bgl18-62a-cap31",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "b6ef4619-3a83-4976-9a16-e028dc1b7f2b",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 108 from 161."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  61 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Tx Power Change",
          "changeReasons": [
            "[System Driven Tx Power Change]",
            " -Current Tx Power Level 7 (8 dBm) is adjusted to Tx Power Level 5 (7 dBm) by channel changing from 161 to 108.",
            " -Channel Max Power is 19 dBm and threshold is -60."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:9E:80",
      "apName": "csg-bgl18-82a-cap33",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "6b90ec9e-9cf3-44cb-8d30-c654deba501b",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 56 from 36.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  69 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:07:A5:60",
      "apName": "csg-bgl18-52a-cap37",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "768aef1c-7027-4de0-a296-047736fbe24a",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 64 from 124.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  87 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Tx Power Change",
          "changeReasons": [
            "[System Driven Tx Power Change]",
            " -Current Tx Power Level 2 (15 dBm) is adjusted to Tx Power Level 3 (14 dBm) by channel changing from 124 to 64.",
            " -Channel Max Power is 20 dBm and threshold is -60."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "B4:DE:31:C4:BB:00",
      "apName": "csg-bgl18-31a-cap06",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "8fb4cdd7-c8e3-4d2b-bb8a-05b4ab91de05",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  59 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:7D:B9:99:36:C0",
      "apName": "csg-bgl18-01a-cap08",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "f376fb7c-8c1d-4f65-a2ff-de2c2e17e2a2",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 157 from 136.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  74 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:10:D7:E0",
      "apName": "csg-bgl18-01a-cap13",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "56c8ecdc-fcd1-41d2-8d08-41b503702e96",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 64 from 157.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "00:A7:42:AC:B3:A0",
      "apName": "csg-bgl18-51a-cap06",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "7de397bf-943e-4bc0-babc-12d0d1c61a2e",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  72 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "B4:DE:31:BE:BA:00",
      "apName": "csg-bgl18-31a-cap05",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "752af579-398a-4ca8-a1cb-1eb66fa12566",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  63 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "F4:DB:E6:61:53:00",
      "apName": "csg-bgl18-41a-cap17",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "69c9f614-4ab2-4f9d-813b-bc20a4f96e35",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  86 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:69:5A:78:64:60",
      "apName": "csg-bgl18-72a-cap28",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "b927e787-879e-463f-81cd-4dd234945edb",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 136 from 140.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  81 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "B4:DE:31:0E:68:00",
      "apName": "csg-bgl18-12a-cap44",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "67272642-ee03-4e98-a2e6-04ce785ef82a",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  69 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:9A:C0",
      "apName": "csg-bgl18-81a-cap11",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "19ea7307-3ef6-4856-a500-ee3defce6c57",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  66 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "F8:B7:E2:C2:E4:C0",
      "apName": "csg-bgl18-11a-cap13",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "49aba360-d4f9-431d-8213-3840fef4fe63",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 56 from 140."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  80 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:8F:20",
      "apName": "csg-bgl18-82a-cap28",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "5f63eebc-7ebc-4945-889a-fd8d4358c2bf",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 120 from 124.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  79 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "F4:DB:E6:85:71:20",
      "apName": "csg-bgl18-41a-cap18",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "6fa7def9-b19d-4390-bedc-7d70723a16ae",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  84 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:B4:C0",
      "apName": "csg-bgl18-82a-cap44",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "40589e03-115a-473b-bf2b-148b0827f1cf",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 36 from 44.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  60 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:27:0D:80",
      "apName": "csg-bgl18-01a-cap18",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "04676eb2-ce78-4e7a-a40b-d7efcd246636",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 52 from 44.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  65 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Tx Power Change",
          "changeReasons": [
            "[System Driven Tx Power Change]",
            " -Current Tx Power Level 4 (13 dBm) is adjusted to Tx Power Level 3 (11 dBm) by channel changing from 44 to 52.",
            " -Channel Max Power is 17 dBm and threshold is -60."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:88:60",
      "apName": "csg-bgl18-81a-cap13",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "1799dad3-cb3c-4fd1-9452-4ffc0a26d930",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 108 from 100.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  77 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:37:6A:20",
      "apName": "csg-bgl18-32a-cap31",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "deaa53a5-0cee-41e4-9e7b-f80816771072",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  75 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "B4:DE:31:95:8A:00",
      "apName": "csg-bgl18-31a-cap23",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "4b4b3d44-f6dd-490a-85d1-895d771b0606",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  67 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:10:CF:00",
      "apName": "csg-bgl18-01a-cap14",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "6486c826-71b3-4aef-a5c9-7632f656d07d",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  72 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:F5:60",
      "apName": "csg-bgl18-61a-cap07",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "1b004bb2-3e4e-4de7-957c-8832ce2451b2",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 120 from 100.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  65 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Tx Power Change",
          "changeReasons": [
            "[System Driven Tx Power Change]",
            " -Current Tx Power Level 5 (6 dBm) is adjusted to Tx Power Level 6 (4 dBm) by channel changing from 100 to 120.",
            " -Channel Max Power is 19 dBm and threshold is -60."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "B4:DE:31:C6:02:40",
      "apName": "csg-bgl18-31a-cap22",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "adb249b0-ca6a-4406-bfe7-8a92cfbe061a",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  71 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "F4:DB:E6:61:59:A0",
      "apName": "csg-bgl18-41a-cap11",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "76c94d86-f9f0-42df-a42f-90401a2a3cae",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  74 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:7D:B9:A5:4B:C0",
      "apName": "csg-bgl18-01a-cap23",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "38eb00dd-d8c1-4a38-94d4-e3def6337ba2",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  74 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:CD:60",
      "apName": "csg-bgl18-82a-cap26",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "b76f908b-9a9d-41b2-96e9-2ef18aa6ac1d",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  81 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:50:00",
      "apName": "csg-bgl18-62a-cap29",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "057fd9b2-707b-475b-a9fd-14bf6a256d12",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 132 from 140.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  79 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Tx Power Change",
          "changeReasons": [
            "[System Driven Tx Power Change]",
            " -Current Tx Power Level 5 (6 dBm) is adjusted to Tx Power Level 6 (4 dBm) by channel changing from 140 to 132.",
            " -Channel Max Power is 19 dBm and threshold is -60."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:70:8B:B5:EA:A0",
      "apName": "csg-bgl18-52a-cap28",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "ed02bd88-057c-4c68-b4e3-c674ae9b3b35",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 64 from 56.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  86 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Tx Power Change",
          "changeReasons": [
            "[System Driven Tx Power Change]",
            " -Current Tx Power Level 2 (15 dBm) is adjusted to Tx Power Level 3 (14 dBm) by channel changing from 56 to 64.",
            " -Channel Max Power is 20 dBm and threshold is -60."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "F4:DB:E6:85:73:40",
      "apName": "csg-bgl18-41a-cap10",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "abc853b3-1b2c-428d-a06c-e757465999eb",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 36 from 44."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  67 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "0C:D0:F8:95:23:80",
      "apName": "csg-bgl18-71a-cap11",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "975a8013-edba-4352-906a-11ecc5a614b6",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  54 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "A0:F8:49:8B:C2:E0",
      "apName": "csg-bgl18-11a-cap06",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "a1daf83e-7bc7-4e59-ac9b-280cc71e38f3",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  94 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:7B:60",
      "apName": "csg-bgl18-81a-cap05",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "d006cbe5-f921-4385-84f6-3789aca95de9",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  62 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:13:C5:60",
      "apName": "csg-bgl18-51a-cap08",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "5084fb4d-af92-49c3-9337-54d358d5cd0d",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  70 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "0C:D0:F8:94:9B:E0",
      "apName": "csg-bgl18-71a-cap08",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "3c269472-cd34-4fea-9dd5-16a1b31c0526",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  51 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:69:5A:78:5B:A0",
      "apName": "csg-bgl18-72a-cap38",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "32113b03-da2a-400a-b3f0-30625a6b8d48",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  67 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "B4:DE:31:9D:33:60",
      "apName": "csg-bgl18-51a-cap17",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "7f572061-56c3-497b-9fde-11e24ec03d75",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  70 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:60:04:20",
      "apName": "csg-bgl18-61a-cap09",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "f11f8d63-ca3f-49ea-8e36-a3b8000c3ea5",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  64 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:8D:60",
      "apName": "csg-bgl18-82a-cap29",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "3668ea9d-23c8-4064-80f6-792953728224",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  77 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:EA:1A:C7:03:80",
      "apName": "csg-bgl18-11a-cap22",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "24b024cf-af6a-4d36-a898-d1c7fae51e7a",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  65 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:2C:7F:00",
      "apName": "csg-bgl18-02a-cap36",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "cbaf3ec6-6afa-4180-899b-f7512a9924c1",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  77 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:69:5A:78:4B:80",
      "apName": "csg-bgl18-72a-cap47",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "a443991f-314f-42bf-aa7d-cad5dfdc4dcc",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 149 from 136.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  63 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "00:A7:42:AC:C3:40",
      "apName": "csg-bgl18-51a-cap18",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "272200a9-7a07-49f0-be20-c19210d87b66",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  71 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:8F:C0",
      "apName": "csg-bgl18-62a-cap35",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "f9af3630-0805-4433-914e-a7a0ad5b7f5f",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  71 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:7D:B9:A3:10:20",
      "apName": "csg-bgl18-01a-cap25",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "a86c4838-fb2f-4e36-ab04-9ecb5c7fbf01",
      "rrmChanges": [
        {
          "changeCategory": "Tx Power Change",
          "changeReasons": [
            "[System Driven Tx Power Change]",
            " -Current Tx Power Level 4 (13 dBm) is higher than optimal Tx Power Level 5 (10 dBm).",
            " -Channel Max Power is 22 dBm and threshold is -60."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:FE:30",
      "apName": "csg-bgl18-61a-cap05",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "5dea85ac-ab68-41a7-9f91-5c72b928a2de",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 52 from 120.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  61 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:2C:64:40",
      "apName": "csg-bgl18-02a-cap31",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "8a05c4bc-8af6-4170-82ac-0012528eeedd",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 140 from 100.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  87 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "50:0F:80:5C:2B:40",
      "apName": "csg-bgl18-32a-cap28",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "16c0de9a-5fd4-4bba-bb1d-c13b182434ac",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 52 from 60.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  68 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "F8:0B:CB:D1:32:40",
      "apName": "csg-bgl18-32a-cap32",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "daa15596-cf53-4372-9e81-3d68c0280e02",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 128 from 100.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  69 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Tx Power Change",
          "changeReasons": [
            "[System Driven Tx Power Change]",
            " -Current Tx Power Level 3 (11 dBm) is adjusted to Tx Power Level 4 (9 dBm) by channel changing from 100 to 128.",
            " -Channel Max Power is 18 dBm and threshold is -60."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:EA:1A:C6:FA:40",
      "apName": "csg-bgl18-11a-cap24",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "516d7a6b-3896-4225-a262-2f4fe869391c",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 44 from 36.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  77 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:CB:00",
      "apName": "csg-bgl18-61a-cap16",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "80b206f0-c940-4d77-812f-c5afc3d29ad5",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  71 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:10:CD:00",
      "apName": "csg-bgl18-02a-cap41",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "bd6bead0-a3db-40d3-9d93-51072d988082",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  77 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "00:A3:8E:1A:A4:40",
      "apName": "csg-bgl18-51a-cap07",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "80ff63ea-56c6-4f35-9428-d5ae30b027e1",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 144 from 136."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  85 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "F4:DB:E6:61:6A:A0",
      "apName": "csg-bgl18-41a-cap03",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "37f675bb-99e0-4ea4-90e7-fd208d2b19cb",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  70 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:69:5A:78:5B:C0",
      "apName": "csg-bgl18-72a-cap39",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "aa2bc8b4-95ec-4d43-912c-420d0b5d52db",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 56 from 64.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  69 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:E5:10",
      "apName": "csg-bgl18-61a-cap14",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "a0bb66e3-c131-451b-b412-2cbf1a236a8a",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  75 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:AB:A0",
      "apName": "csg-bgl18-82a-cap47",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "29f02e41-fb4f-4760-a787-379f30f4530c",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  68 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:B0:80",
      "apName": "csg-bgl18-81a-cap09",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "64fbc620-aa8c-44e0-bd84-55bb1c2fe063",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 48 from 36.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  76 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:69:5A:78:4F:A0",
      "apName": "csg-bgl18-72a-cap50",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "0a18bdcc-6591-4f31-bf1e-bbf4b8456785",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  82 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "B4:DE:31:C6:0A:60",
      "apName": "csg-bgl18-31a-cap21",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "68dee68b-da49-473b-9b18-6dd50c63689d",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  63 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:72:B0",
      "apName": "csg-bgl18-62a-cap42",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "7ed74837-632c-4819-920d-0a03f1c70298",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  61 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:90:E0",
      "apName": "csg-bgl18-81a-cap21",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "87c25018-20f0-4953-93ac-5c078046d50f",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 108 from 100."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  76 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:6E:30",
      "apName": "csg-bgl18-62a-cap47",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "226435c8-7f36-47fc-8c7d-668842dff04a",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  63 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "F4:DB:E6:61:8E:C0",
      "apName": "csg-bgl18-42a-cap35",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "0e0e9cf5-28ab-4804-a42b-74229914f52d",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 64 from 60.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:69:5A:78:56:C0",
      "apName": "csg-bgl18-72a-cap33",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "51e62423-144d-466c-a172-940d2c1e2f6a",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 52 from 56."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  75 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:07:AA:A0",
      "apName": "csg-bgl18-32a-cap44",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "e0e102f6-7df0-487f-a890-7fe03e4a6ce9",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  74 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:20:9C:40",
      "apName": "csg-bgl18-52a-cap35",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "9610038e-7517-457b-9c6d-89ecef38826a",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  64 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "F4:DB:E6:84:CD:00",
      "apName": "csg-bgl18-41a-cap09",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "ebe29bb8-c94b-4673-80b4-c1155a0e3bfc",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  91 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:F9:B0",
      "apName": "csg-bgl18-61a-cap22",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "8e0139ca-a431-4713-9977-8556cd5d530d",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  65 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "0C:D0:F8:94:AA:00",
      "apName": "csg-bgl18-71a-cap05",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "9021f51c-6b7d-40ee-bf85-b40573dbb5be",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  56 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:87:00",
      "apName": "csg-bgl18-82a-cap42",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "7902c917-ef86-44ca-8688-800fc4a22918",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 120 from 124.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  68 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:6D:A0",
      "apName": "csg-bgl18-62a-cap36",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "766e8a2e-f640-4f36-81d4-1e116edf3d80",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 108 from 100.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  63 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Tx Power Change",
          "changeReasons": [
            "[System Driven Tx Power Change]",
            " -Current Tx Power Level 5 (6 dBm) is adjusted to Tx Power Level 6 (4 dBm) by channel changing from 100 to 108.",
            " -Channel Max Power is 19 dBm and threshold is -60."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:2C:71:A0",
      "apName": "csg-bgl18-51a-cap02",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "f093a28e-db79-4f30-a192-fd9d569be79c",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  83 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:81:80",
      "apName": "csg-bgl18-62a-cap50",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "c2acde97-15cb-4dd8-8666-ddf1d16fb5f3",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 124 from 120.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  69 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "0C:D0:F8:94:A7:C0",
      "apName": "csg-bgl18-71a-cap23",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "a8dc6871-398d-420e-ba39-b9ae98ca8d56",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  53 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:14:B3:A0",
      "apName": "csg-bgl18-52a-cap33",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "51f11b6f-9e39-4c08-970e-cc7791b06e82",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 120 from 124.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  75 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:70:8B:B5:E1:E0",
      "apName": "csg-bgl18-32a-cap30",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "fbea6f66-ad63-4f39-af4f-ace02fed7303",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  80 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:93:00",
      "apName": "csg-bgl18-82a-cap25",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "b1ad8649-9c9c-48e5-afc0-8dc0e00b04e3",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  79 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:F9:20",
      "apName": "csg-bgl18-61a-cap13",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "1a0c4b2d-0df8-4815-97d3-0195c57eb38f",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  67 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:7E:80",
      "apName": "csg-bgl18-62a-cap43",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "71392017-a562-40d7-b864-b0e9cf02853a",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  64 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:20:9C:60",
      "apName": "csg-bgl18-52a-cap29",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "ac0198e6-35c2-469f-86fb-3180aee1e0b3",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  61 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:5E:70",
      "apName": "csg-bgl18-62a-cap32",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "1265d8c6-a0df-4b1a-8d72-856c76f3d2c2",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 124 from 120.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  64 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "B4:DE:31:9D:41:40",
      "apName": "csg-bgl18-51a-cap19",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "2e8b7ff2-3018-41d4-8bd3-ada095be71e4",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  63 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:2C:79:C0",
      "apName": "csg-bgl18-02a-cap32",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "7dbad6ca-0926-4464-a8d2-7834cd6dc7fb",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 120 from 124."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  85 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "00:A7:42:F9:20:80",
      "apName": "csg-bgl18-51a-cap14",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "8751506e-1316-41d6-90ba-fa9db2fd8f21",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  70 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "B4:DE:31:9D:3C:20",
      "apName": "csg-bgl18-51a-cap21",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "3292bcae-c854-4767-bdb4-cd9057e3f99f",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 100 from 108.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  69 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "B4:DE:31:9A:F9:A0",
      "apName": "csg-bgl18-31a-cap01",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "a2e9a330-aec1-4dbe-98f0-72cd9c944693",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 108 from 149.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  63 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Tx Power Change",
          "changeReasons": [
            "[System Driven Tx Power Change]",
            " -Current Tx Power Level 5 (11 dBm) is adjusted to Tx Power Level 4 (9 dBm) by channel changing from 149 to 108.",
            " -Channel Max Power is 18 dBm and threshold is -60."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:10:CD:80",
      "apName": "csg-bgl18-02a-cap37",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "6b02c3e5-fd2c-4285-891d-aae4235c0e51",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  92 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:B4:80",
      "apName": "csg-bgl18-61a-cap24",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "231cf06b-1dc2-44de-984f-10b1711f5148",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 132 from 161.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  70 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Tx Power Change",
          "changeReasons": [
            "[System Driven Tx Power Change]",
            " -Current Tx Power Level 6 (11 dBm) is adjusted to Tx Power Level 4 (10 dBm) by channel changing from 161 to 132.",
            " -Channel Max Power is 19 dBm and threshold is -60."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:EA:1A:C6:FC:00",
      "apName": "csg-bgl18-11a-cap21",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "ebae19e3-c213-4747-9730-2715ebc5a492",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  69 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "F8:B7:E2:C3:06:40",
      "apName": "csg-bgl18-12a-cap45",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "1f1ce81f-29ca-4f69-bb19-42d7ae3d03df",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 64 from 128."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  89 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:B5:40",
      "apName": "csg-bgl18-81a-cap18",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "815303cc-ff5e-4044-982c-6339e0e966b9",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 36 from 44.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  62 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:7D:B9:99:BE:40",
      "apName": "csg-bgl18-01a-cap10",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "cf3fa704-0758-493b-8990-3d2ff9ea1383",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  83 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "B4:DE:31:BE:EC:E0",
      "apName": "csg-bgl18-31a-cap07",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "e9d8dade-fc3f-4d87-8a87-35460c838822",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  72 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:80:00",
      "apName": "csg-bgl18-62a-cap33",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "89a50a0a-0df5-48fa-a2ee-3195b823a153",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  71 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:EA:50",
      "apName": "csg-bgl18-61a-cap11",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "b052895f-f083-4b78-8717-fdf0ffa10cef",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 36 from 120.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  58 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Tx Power Change",
          "changeReasons": [
            "[System Driven Tx Power Change]",
            " -Current Tx Power Level 5 (7 dBm) is adjusted to Tx Power Level 6 (6 dBm) by channel changing from 120 to 36.",
            " -Channel Max Power is 21 dBm and threshold is -60."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "B4:DE:31:C4:BA:C0",
      "apName": "csg-bgl18-31a-cap02",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "af846553-f95b-47da-bc84-37d30b184748",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  69 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:EA:1A:C7:04:E0",
      "apName": "csg-bgl18-11a-cap15",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "ec50026a-6daf-4e03-9150-06ef37c43c49",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 149 from 132."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  55 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:69:5A:78:72:C0",
      "apName": "csg-bgl18-72a-cap42",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "0b1d2a49-b42a-4499-b01f-d7ccfa0adbff",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 120 from 161.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  62 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "B4:DE:31:BE:E8:00",
      "apName": "csg-bgl18-31a-cap16",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "5486334f-a55e-4e48-ac70-d09b44f58c8d",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 140 from 136."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  73 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "F4:DB:E6:85:6E:40",
      "apName": "csg-bgl18-41a-cap13",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "d49d7e72-be08-41cf-86ed-7903967f8409",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 120 from 56.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "F8:0B:CB:E8:A1:E0",
      "apName": "csg-bgl18-32a-cap42",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "d4f332b4-3118-417a-abc1-b9bb5ac69acb",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  75 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "F4:DB:E6:85:6B:A0",
      "apName": "csg-bgl18-42a-cap29",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "cf4202b1-7b1a-4cc9-b57c-34e73b712e82",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  78 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:AA:80",
      "apName": "csg-bgl18-81a-cap02",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "3835738f-e68d-4e28-877c-b319003e0309",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  89 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "B4:DE:31:9D:3A:60",
      "apName": "csg-bgl18-52a-cap25",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "9eafd522-80a9-4875-83ca-be0dee7c0482",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  75 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:EA:1A:C6:FE:C0",
      "apName": "csg-bgl18-11a-cap19",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "0e7ce213-785a-420f-94db-5faf48c5811f",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  71 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "F4:DB:E6:84:F7:C0",
      "apName": "csg-bgl18-42a-cap31",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "d10d66f1-89a4-4842-ae15-ed2b426f74ba",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  92 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:A6:C0",
      "apName": "csg-bgl18-81a-cap16",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "3d768e07-8138-452b-8494-cf7f13ae668d",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  85 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:B0:00",
      "apName": "csg-bgl18-61a-cap08",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "add092d9-279a-4e32-a596-ef104098b8b4",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 60 from 64."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:7E:20",
      "apName": "csg-bgl18-62a-cap49",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "c4b140e0-68fd-4909-ad8b-f8d0e341f984",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 48 from 36.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  63 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:55:D0",
      "apName": "csg-bgl18-62a-cap30",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "3ecfab74-9286-4050-a5e1-901514a9f2b2",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 60 from 120.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  62 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "F4:DB:E6:61:57:C0",
      "apName": "csg-bgl18-42a-cap19",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "a726f3ab-3384-48d8-af08-b9e483510501",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  79 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "B4:DE:31:BE:E9:40",
      "apName": "csg-bgl18-31a-cap10",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "c3b4f033-6eec-4de6-ad95-3d24fac276f9",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  74 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:37:6C:00",
      "apName": "csg-bgl18-52a-cap26",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "7c3acbc7-67bc-45d6-8fce-f23d7047b831",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  73 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "68:7D:B4:5F:FF:50",
      "apName": "csg-bgl18-61a-cap17",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "d3da90fb-7935-40e9-8c85-8e69d08985b7",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  75 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:8B:E0",
      "apName": "csg-bgl18-81a-cap10",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "a8a052ce-9b2e-49bc-89c5-546d16b3bf7a",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 140 from 136.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  73 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:B5:80",
      "apName": "csg-bgl18-81a-cap23",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "1011dcac-0790-4abe-8c29-ce56c2c53860",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  60 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:14:E4:80",
      "apName": "csg-bgl18-32a-cap47",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "e8c24270-c9c6-4105-8d43-93db522d15b1",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 144 from 136.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  83 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "D4:E8:80:19:92:40",
      "apName": "csg-bgl18-81a-cap17",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "6a02b2ae-3eb3-4509-a470-aed0cbc3f5d1",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 120 from 100.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  69 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:07:A5:A0",
      "apName": "csg-bgl18-52a-cap31",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "5b758d5a-0c75-4589-a745-2ee5d20d1ba4",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  69 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "0C:D0:F8:95:1A:C0",
      "apName": "csg-bgl18-71a-cap14",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "5ab2f715-7be3-4ff3-97f1-fba5d744572c",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  52 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "A0:F8:49:8B:C2:A0",
      "apName": "csg-bgl18-11a-cap10",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "6570c8d0-0bc4-49d0-b296-548ba6b38dad",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 108 from 64.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  88 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:DB:98:13:CB:80",
      "apName": "csg-bgl18-52a-cap30",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "ae829066-ca06-4780-b3b3-cbd78b99efbf",
      "rrmChanges": [
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  74 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "F4:DB:E6:85:69:00",
      "apName": "csg-bgl18-42a-cap26",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "6a7446d7-8a8a-447d-9fc8-bf117550f2d0",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 64 from 60.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  68 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "70:69:5A:78:6F:C0",
      "apName": "csg-bgl18-72a-cap32",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "d62f1950-3421-4482-bc0b-4cbfa0fcefef",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 120 from 124.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "00:A7:42:AC:C7:A0",
      "apName": "csg-bgl18-51a-cap22",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "68430d0b-6715-4fbf-92f5-615f5c681172",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 64 from 56.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  86 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Tx Power Change",
          "changeReasons": [
            "[System Driven Tx Power Change]",
            " -Current Tx Power Level 4 (9 dBm) is adjusted to Tx Power Level 5 (8 dBm) by channel changing from 56 to 64.",
            " -Channel Max Power is 20 dBm and threshold is -60."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    },
    {
      "apMac": "F4:DB:E6:84:CE:A0",
      "apName": "csg-bgl18-41a-cap15",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "memberId": "40b8ff5e-da7f-4ec7-9aaa-72fb3624b67d",
      "rrmChanges": [
        {
          "changeCategory": "Channel Change",
          "changeReasons": [
            "[System Driven Channel Change - Slot 1] Channel is changed to 136 from 140.",
            " -Channel is optimized by Neighbor AP."
          ],
          "isChanged": 1
        },
        {
          "changeCategory": "Channel Width Change",
          "changeReasons": [
            "Slot - 1, Channel Width is changed to 40 MHz from 80 MHz.",
            " -Channel bandwidth quality is improved  81 percentage.",
            " -DBS selects the best channel width to reduce co-channel interference."
          ],
          "isChanged": 1
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T19:00:00",
      "timestampMs": "1648666800000"
    }
  ],
  "getRrmHealthLatestDetail01_5ghz_Template_Good":[
    {
      "apMac": "70:DB:98:07:A4:60",
      "apName": "csg-bgl18-52a-cap42",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 2,
      "healthScore": 100,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 1 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 27 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] 1 out of 27 neighbors is on Side Channel (Good)",
            " -[Co Channel Utilization] 1 out of 27 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 1 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 0% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 1 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -98. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 1 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "4026991e-a10a-4fa7-a866-c19d6569016f",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-31T06:00:00",
      "timestampMs": "1648706400000"
    }
  ],
  "getRrmHealthLatestDetail01_5ghz_Template_Fair":[
    {
      "apMac": "0C:D0:F8:94:74:E0",
      "apName": "csg-bgl18-71a-cap22",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 50,
      "frequencyBand": 2,
      "healthScore": 50,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Fair",
          "healthReasons": [
            "Slot 1 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 2 out of 15 neighbors are on Co Channel (Fair)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 2 out of 15 neighbors are on Co Channel with utilization (Good)"
          ],
          "score": 50
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 1 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 1 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -98. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 1 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Fair",
      "lowRssiClientScore": 100,
      "memberId": "6a2b3af5-534b-4aae-8baa-e82f1c4f05d5",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-04-26T07:30:00",
      "timestampMs": "1650958200000"
    }
  ],
  "getRrmHealthLatestDetail01_5ghz_Template_Poor":[
    {
      "apMac": "A0:F8:49:8B:C2:A0",
      "apName": "csg-bgl18-11a-cap10",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 2,
      "healthScore": 0,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 1 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] There is no Co-Channel Neighbor (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] There is no Co-Channel neighbor with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Poor",
          "healthReasons": [
            "Slot 1 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 50% (Poor)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 0
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 1 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -90. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 1 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Poor",
      "lowRssiClientScore": 100,
      "memberId": "6570c8d0-0bc4-49d0-b296-548ba6b38dad",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-04-26T08:00:00",
      "timestampMs": "1650960000000"
    }
  ],
  "getRrmHealthTrendDetail01_5ghz_Template":[
    {
      "apMac": "A0:E0:AF:4D:78:00",
      "apName": "csg-bgl18-02a-cap42",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelNeighbourUtil": 100,
      "coChannelOverlapping": 100,
      "frequencyBand": 2,
      "healthScore": 98,
      "healthScoreReason": [
        {
          "healthCategory": "neighbor",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 1 RRM Performance Score Reasons",
            " -[Co Channel Neighbor] 1 out of 25 neighbors is on Co Channel (Good)",
            " -[Side Channel Neighbor] There is no Side-Channel neighbor (Good)",
            " -[Co Channel Utilization] 1 out of 25 neighbors is on Co Channel with utilization (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "interference",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 1 RRM Performance Score Reasons",
            " -[CCA Busy] Interference on the current Channel is 1% (Good)",
            " -[Channel Interference] Interference RSSI is -100 and Duty Cycle is 0% on the current Channel (Good)",
            " -[Side Channel Interference] There is no Side Channel Interference. (Good)"
          ],
          "score": 98
        },
        {
          "healthCategory": "noise",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 1 RRM Performance Score Reasons",
            " -[Channel Noise] Noise on the current Channel is -98. (Good)",
            " -[Side Channel Noise] There is no Same Channel Noise. (Good)"
          ],
          "score": 100
        },
        {
          "healthCategory": "client",
          "healthLevel": "Good",
          "healthReasons": [
            "Slot 1 RRM Performance Score Reasons",
            " -[Low RSSI Client] Total client count 0 is less then minimum client count 2 (Good)"
          ],
          "score": 100
        }
      ],
      "healthScoreState": "Good",
      "lowRssiClientScore": 100,
      "memberId": "9a34c1e4-06c0-4b6e-9ed4-a3bee5eb20e3",
      "noiseSameChannel": 100,
      "sameChannelInterference": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    }
  ],
  "getCoChannelInterferenceLatestDetail01_5ghz_Template": [
    {
      "apMac": "B4:DE:31:C4:BB:00",
      "apName": "csg-bgl18-31a-cap06",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "channel": 36,
      "coChannelInterference": -100,
      "dutyCycle": 0,
      "frequencyBand": 2,
      "impactScore": 0,
      "impactScoreState": "Low",
      "memberId": "8fb4cdd7-c8e3-4d2b-bb8a-05b4ab91de05",
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-31T07:00:00",
      "timestampMs": "1648710000000"
    }
  ],
  "getCoChannelInterferenceTrendDetail01_5ghz_Template": [
    {
      "apMac": "A0:E0:AF:4D:78:00",
      "apName": "csg-bgl18-02a-cap42",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "channel": 44,
      "coChannelInterference": -100,
      "dutyCycle": 0,
      "frequencyBand": 2,
      "impactScore": 0,
      "impactScoreState": "Low",
      "memberId": "9a34c1e4-06c0-4b6e-9ed4-a3bee5eb20e3",
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-31T00:00:00",
      "timestampMs": "1648684800000"
    }
  ],
  "getApSpatialDensityLatestDetail01_5ghz_Template": [
    {
      "apMac": "70:DB:98:14:B5:00",
      "apName": "csg-bgl18-52a-cap39",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "last24HourNeighbourRange": [
        {
          "maxTotalNeighbourCount": 12,
          "minTotalNeighbourCount": 10
        }
      ],
      "maxOnChannelNeighbourRssi": 0,
      "memberId": "040530f7-67a0-4ed6-89a8-ef9d7d5e1881",
      "nbrCoChannel": 100,
      "onChannelNeighbourCount": 0,
      "onChannelNeighbourList": [],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "strongNeighbourCount": 10,
      "strongNeighbourList": [
        {
          "neighbourMac": "70:DB:98:07:A5:60",
          "neighbourName": "csg-bgl18-52a-cap37",
          "neighbourRssi": -66
        },
        {
          "neighbourMac": "70:DB:98:13:CB:80",
          "neighbourName": "csg-bgl18-52a-cap30",
          "neighbourRssi": -63
        },
        {
          "neighbourMac": "F8:0B:CB:3A:25:20",
          "neighbourName": "csg-bgl18-52a-cap36",
          "neighbourRssi": -50
        },
        {
          "neighbourMac": "70:DB:98:14:FD:A0",
          "neighbourName": "csg-bgl18-52a-cap43",
          "neighbourRssi": -59
        },
        {
          "neighbourMac": "2C:D0:2D:E3:42:40",
          "neighbourName": "csg-bgl18-52a-cap41",
          "neighbourRssi": -63
        },
        {
          "neighbourMac": "70:DB:98:20:9A:40",
          "neighbourName": "csg-bgl18-52a-cap38",
          "neighbourRssi": -48
        },
        {
          "neighbourMac": "70:DB:98:37:6B:E0",
          "neighbourName": "csg-bgl18-52a-cap49",
          "neighbourRssi": -69
        },
        {
          "neighbourMac": "70:DB:98:37:6B:20",
          "neighbourName": "csg-bgl18-52a-cap45",
          "neighbourRssi": -55
        },
        {
          "neighbourMac": "70:DB:98:14:B3:C0",
          "neighbourName": "csg-bgl18-52a-cap50",
          "neighbourRssi": -63
        },
        {
          "neighbourMac": "70:DB:98:07:A4:60",
          "neighbourName": "csg-bgl18-52a-cap42",
          "neighbourRssi": -52
        }
      ],
      "timestamp": "2022-03-31T08:30:00",
      "timestampMs": "1648715400000",
      "totalNeighbourCount": 20
    }
  ],
  "getApSpatialDensityTrendDetail01_5ghz_Template":  [
    {
      "apMac": "F8:B7:E2:C3:06:40",
      "apName": "csg-bgl18-12a-cap45",
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "maxOnChannelNeighbourRssi": 0,
      "memberId": "1f1ce81f-29ca-4f69-bb19-42d7ae3d03df",
      "nbrCoChannel": 100,
      "onChannelNeighbourCount": 0,
      "onChannelNeighbourList": null,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "strongNeighbourCount": 0,
      "strongNeighbourList": [
        {
          "neighbourMac": null,
          "neighbourName": null,
          "neighbourRssi": null
        }
      ],
      "timestamp": "2022-03-30T19:30:00",
      "timestampMs": "1648668600000",
      "totalNeighbourCount": 0
    }
  ],
  "getPowerDistributionLatestDetail01_5ghz_Template": [
    {
      "apMac": "D4:E8:80:19:CC:A0",
      "apName": "csg-bgl18-72a-cap26",
      "avgOnChannelNeighbourPowerDbm": 0,
      "avgStrongNeighbourPowerDbm": 11,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 2,
      "memberId": "3587a6b5-a154-4250-96ad-3ef0a0d22969",
      "powerDbm": 2,
      "powerLevel": 7,
      "powerPercentage": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 0,
      "timestamp": "2022-03-31T09:00:00",
      "timestampMs": "1648717200000",
      "totalNeighbourCount": 6
    }
  ],
  "getPowerDistributionTrendDetail01_5ghz_Template":[
    {
      "apMac": "D4:E8:80:19:B5:C0",
      "apName": "csg-bgl18-81a-cap01",
      "avgOnChannelNeighbourPowerDbm": 11,
      "avgStrongNeighbourPowerDbm": 13,
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coverageHoleCount": 0,
      "frequencyBand": 2,
      "memberId": "52f50e37-a7ff-460a-a698-51235d8a4562",
      "powerDbm": 7,
      "powerLevel": 1,
      "powerPercentage": 100,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "slot": 1,
      "timestamp": "2022-03-30T20:00:00",
      "timestampMs": "1648670400000",
      "totalNeighbourCount": 34
    }
  ],
  "getServiceabilityRadioLevel01_5ghz_Template":[
    {"adminState":1,"apMac":"70:69:5A:78:4B:80","apName":"csg-bgl18-72a-cap47","bias":[0,0,0,0,0,0,0,0,0,0,0],
    "buildingId":"f8ffb07d-4cc4-4912-9a3c-6905d4153941","ccaUtil":3275,"ccaUtilPercentage":5,"channel":11,
    "channelList":[1,2,3,4,5,6,7,8,9,10,11],"channelWidth":20,"currentActiveBand":1,"customerId":"5xrwgkje",
    "decision":null,"events":null,"granularity":"30 minutes","htEnable":1,
    "interferenceDc":[3202,405,558,2,192,20409,20,645,2008,0,1],
    "interferenceRssi":[-74,-96,-93,-100,-100,-73,-100,-94,-84,-100,-100],
    "neighbours":[{"neighbourMac":"70:69:5A:78:5B:A0","neighbourRssi":-71,"neighbourSlot":0},
    {"neighbourMac":"0C:D0:F8:94:A5:A0","neighbourRssi":-71,"neighbourSlot":0},
    {"neighbourMac":"70:69:5A:78:5F:20","neighbourRssi":-50,"neighbourSlot":0},
    {"neighbourMac":"68:7D:B4:5F:4B:E0","neighbourRssi":-85,"neighbourSlot":0},
    {"neighbourMac":"70:69:5A:78:73:40","neighbourRssi":-55,"neighbourSlot":0},
    {"neighbourMac":"D4:E8:80:19:AA:80","neighbourRssi":-81,"neighbourSlot":0},
    {"neighbourMac":"70:69:5A:78:4F:A0","neighbourRssi":-58,"neighbourSlot":0},
    {"neighbourMac":"0C:D0:F8:94:AC:A0","neighbourRssi":-84,"neighbourSlot":0},
    {"neighbourMac":"70:69:5A:78:5B:C0","neighbourRssi":-71,"neighbourSlot":0},
    {"neighbourMac":"0C:D0:F8:95:26:E0","neighbourRssi":-80,"neighbourSlot":0},
    {"neighbourMac":"0C:D0:F8:94:A7:C0","neighbourRssi":-62,"neighbourSlot":0},
    {"neighbourMac":"70:69:5A:78:72:C0","neighbourRssi":-48,"neighbourSlot":0},
    {"neighbourMac":"70:69:5A:78:72:00","neighbourRssi":-46,"neighbourSlot":0},
    {"neighbourMac":"70:69:5A:78:70:C0","neighbourRssi":-42,"neighbourSlot":0},
    {"neighbourMac":"0C:D0:F8:95:23:60","neighbourRssi":-79,"neighbourSlot":0},
    {"neighbourMac":"0C:D0:F8:95:3F:60","neighbourRssi":-76,"neighbourSlot":0},
    {"neighbourMac":"0C:D0:F8:95:24:80","neighbourRssi":-80,"neighbourSlot":0},
    {"neighbourMac":"0C:D0:F8:94:74:E0","neighbourRssi":-71,"neighbourSlot":0},
    {"neighbourMac":"0C:D0:F8:94:6D:20","neighbourRssi":-74,"neighbourSlot":0},
    {"neighbourMac":"70:69:5A:78:73:00","neighbourRssi":-73,"neighbourSlot":0},
    {"neighbourMac":"0C:D0:F8:94:8E:C0","neighbourRssi":-63,"neighbourSlot":0}],
    "noiseRssi":[-96,-96,-96,-92,-95,-95,-91,-95,-96,-95,-95],"num11VideoClients":0,
    "num11VoiceClients":0,"num11aClients":0,"num11ac160Clients":0,"num11acClients":0,
    "num11nClients":0,"nwDeviceType":"Cisco Catalyst 9115AXI Unified Access Point","opState":2,
    "phyHtCfgConfigType":1,"powerLevel":6,"radioMode":1,"radioProtocol":"radio80211ax","radioSubMode":1,
    "radioSubtype":0,"radioType":1,"rogue20Count":[4,0,0,0,0,1,0,0,0,0,0],
    "rogue40PrimaryCount":[0,0,0,0,0,0,0,0,0,0,0],"rogue40SecondaryCount":[0,0,0,0,0,0,0,0,0,0,0],
    "rogue80Chan3Count":[0,0,0,0,0,0,0,0,0,0,0],"rogue80Chan4Count":[0,0,0,0,0,0,0,0,0,0,0],
    "rogue80PrimaryCount":[0,0,0,0,0,0,0,0,0,0,0],"rogue80Secondary20Count":[0,0,0,0,0,0,0,0,0,0,0],
    "rrmChanges":null,"rssiBins":[0,0,0,0,0,0,0],"rxUtil":0,"rxUtilPercentage":0,
    "siteId":"c924360c-d1b7-4147-9147-b4c6bb1c3ecb","slot":0,"snrBins":[0,0,0,0,0,0,0,0,0,0],
    "timestamp":"2022-06-07T04:00:00","timestampMs":"1654574400000","timestampOffset":584,"totalClients":0,
    "txPowerChangeReason":0,"txPowerListDbm":"[[36,30,24,18,12,6,0,-6],[40,34,28,22,16,10,4,-2],[40,34,28,22,16,10,4,-2],[40,34,28,22,16,10,4,-2],[40,34,28,22,16,10,4,-2],[40,34,28,22,16,10,4,-2],[40,34,28,22,16,10,4,-2],[40,34,28,22,16,10,4,-2],[40,34,28,22,16,10,4,-2],[40,34,28,22,16,10,4,-2],[36,30,24,18,12,6,0,-6]]",
    "txUtil":0,"vhtEnable":0,"wlcId":"10.127.2.22","xorRadioBand":0,"xorRadioMode":1}
  ]

});