define({
  "Template": {
  "version": "1.0",
  "response": [
    {
    "id": "AWZBKinWK2_2PamPvEHH",
    "name": "connected_poor_rssi",
    "entityType": "Client",
    "entity": "7C:46:85:20:80:A3",
    "groupBy": "33bbd24d-7df6-4dd6-bd4e-ce8397345889",
    "category": "Connected",
    "severity": "MEDIUM",
    "summary": "Wireless client experiencing poor RF conditions on SSID \"SSID@ToChange\"",
    "scope": "GLOBAL",
    "priority": "P2",
    "rootCause": "connected_poor_rssi",
    "timestamp": 1538691280000,
    "description": "This client was connected to AP \"AP@ToChange\" at below -72dBm for more than 5 minutes. The client was experiencing poor RF condition because the client did not have better neighboring APs to which it could roam. The client was connected to SSID \"SSID@ToChange\" on Band@ToChange GHz radio on AP \"AP@ToChange\" in location Site@ToChange. The AP is connected to WLC \"Wlc@ToChange\".",
    "suggestions": [
      {
        "message": "Make sure that the Wi-Fi coverage is consistent with the site survey that was done for your deployment. If this location is important, do the following: 1) Add or move another AP to this location. 2) Check if you must enable lower data rates.",
        "steps": [

        ]
      },
      {
        "message": "Check if there is a temporary obstacle or interference that is preventing the client from roaming to a better AP.",
        "steps": [

        ]
      }
    ],
    "additionalParams": [
      {
        "key": "hostName",
        "value": "0dBKK4SD"
      },
      {
        "key": "rssiThreshold",
        "value": "-72"
      },
      {
        "key": "hostOSType",
        "value": "Android-Motorola"
      },
      {
        "key": "entityType",
        "value": "interface_host"
      },
      {
        "key": "__entity_type__",
        "value": "Interface"
      },
      {
        "key": "nearestApMaxRssi",
        "value": "-128.0"
      },
      {
        "key": "entityId",
        "value": "7C:46:85:20:80:A3"
      },
      {
        "key": "groupBy",
        "value": "33bbd24d-7df6-4dd6-bd4e-ce8397345889"
      },
      {
        "key": "clientMaxRssi",
        "value": "-72.0"
      },
      {
        "key": "type",
        "value": "issue"
      },
      {
        "key": "ssid",
        "value": "@CorpSSID"
      },
      {
        "key": "apName",
        "value": "AP3800-5"
      },
      {
        "key": "frequency",
        "value": "5.0"
      },
      {
        "key": "duration",
        "value": "5"
      },
      {
        "key": "wlcName",
        "value": "CT5520-MK"
      },
      {
        "key": "vlan",
        "value": "0"
      },
      {
        "key": "numSamples",
        "value": "3.0"
      },
      {
        "key": "hostType",
        "value": "Wireless"
      },
      {
        "key": "siteId",
        "value": "33bbd24d-7df6-4dd6-bd4e-ce8397345889"
      },
      {
        "key": "_id",
        "value": "7C:46:85:20:80:A3"
      },
      {
        "key": "category",
        "value": "connected"
      },
      {
        "key": "timestring",
        "value": "2018-10-04T22:14:40.000+0000"
      },
      {
        "key": "macAddr",
        "value": "7C:46:85:20:80:A3"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null
    },
    "siteQulaifiedName": "SJ/SJC23/Floor1"
  }
 ]
}
})