define({
  "Template": {
  "version": "1.0",
  "response": [
 {
    "id": "fuhZ22gB05goupzQKm0x",
    "name": "wireless_client_dhcp_fail_dhcp_timeout_trigger",
    "enabled": true,
    "flattened": true,
    "entityType": "Client",
    "entityName": "Unknown",
    "entity": "B8:27:EB:A2:2B:F1",
    "groupBy": "79284156-3bd0-4c10-ac36-23ff5336acce",
    "category": "Onboarding",
    "severity": "HIGH",
    "summary": "Wireless client failed to connect (SSID: SSID@ToChange, AP: AP@ToChange, Band: Band@ToChange GHz) - DHCP Timeout",
    "scope": "GLOBAL",
    "priority": "P3",
    "rootCause": "DHCP_SERVER_OR_CLIENT_TIMEOUT",
    "timestamp": 1549868146250,
    "description": "This client failed to get an IPv4 address during onboarding. This happened because the DHCP messages timed out during the DHCP message exchange.  In this instance, either the DHCP server or the client failed to respond to the DHCP messages. This client was connecting to 'SSID@ToChange' SSID on VLAN 'VlanId@ToChange' on Band@ToChange GHz radio on 'AP@ToChange' AP in location 'Site@ToChange'. The AP was connected to 'Wlc@ToChange' WLC.",
    "suggestions": [
      {
        "message": "Verify that the DHCP server is responsive since the client may not be getting DHCP responses in time because of DHCP server or network issues."
      },
      {
        "message": "Verify that the client's DHCP function is responsive since the client may not be processing the DHCP server responses in time."
      },
      {
        "message": "Check the AP location for RF issues. Make sure the AP is in the client's line of sight. If the AP is not in the client's line of sight, remove it as it is not recommended in the design guideline."
      }
    ],
    "additionalParams": [
      {
        "key": "hostName",
        "value": "nam-raspberry90"
      },
      {
        "key": "groupType",
        "value": "Site"
      },
      {
        "key": "assocTime",
        "value": "0"
      },
      {
        "key": "authTime",
        "value": "0"
      },
      {
        "key": "groupBy",
        "value": "79284156-3bd0-4c10-ac36-23ff5336acce"
      },
      {
        "key": "slot",
        "value": "1"
      },
      {
        "key": "type",
        "value": "issue"
      },
      {
        "key": "reasonType",
        "value": "cl_DHCP_TIMEOUT"
      },
      {
        "key": "previousApName",
        "value": ""
      },
      {
        "key": "ssid",
        "value": "Scalability"
      },
      {
        "key": "apName",
        "value": "Icap-row11-rack16"
      },
      {
        "key": "frequency",
        "value": "5.0"
      },
      {
        "key": "reasonCodes",
        "value": "0,0,1,0,0,89,0,147,181,0,0,1,0,0,89,174,0,1,0,0,0,0,89,0,147,181,0,0,1,0,0,89,0,147,181,0,0,1,0,0,89,0,147,181,0,0,1,0,0,89,0,147"
      },
      {
        "key": "vlan",
        "value": "0"
      },
      {
        "key": "hostType",
        "value": "WIRELESS"
      },
      {
        "key": "rootCause",
        "value": "DHCP_SERVER_OR_CLIENT_TIMEOUT"
      },
      {
        "key": "aaaServerIp",
        "value": "172.20.124.240"
      },
      {
        "key": "resultType",
        "value": "cl_FAIL_PEM_TIMEOUT"
      },
      {
        "key": "dhcpTime",
        "value": "0"
      },
      {
        "key": "hostOSType",
        "value": "Linux-Workstation"
      },
      {
        "key": "isRoaming",
        "value": "false"
      },
      {
        "key": "entityType",
        "value": "interface_host"
      },
      {
        "key": "totalTime",
        "value": "0"
      },
      {
        "key": "__entity_type__",
        "value": "Interface"
      },
      {
        "key": "previousWlcName",
        "value": ""
      },
      {
        "key": "apGroup",
        "value": "default-group"
      },
      {
        "key": "dhcpServerIp",
        "value": "128.19.10.1"
      },
      {
        "key": "apMac",
        "value": "70:69:5A:51:58:60"
      },
      {
        "key": "entityId",
        "value": "B8:27:EB:A2:2B:F1"
      },
      {
        "key": "eventType",
        "value": "cl_EVENT_DHCP_DONE"
      },
      {
        "key": "priority",
        "value": "P3"
      },
      {
        "key": "eventTypes",
        "value": "14,11,1,2,3,4,5,6,7,14,11,1,2,3,4,5,14,1,2,3,12,3,4,5,6,7,14,11,1,2,3,4,5,6,7,14,11,1,2,3,4,5,6,7,14,11,1,2,3,4,5,6"
      },
      {
        "key": "wlcName",
        "value": "namlab-3504-215"
      },
      {
        "key": "osCategory",
        "value": "Unknown"
      },
      {
        "key": "deviceCategory",
        "value": "Unclassified"
      },
      {
        "key": "eventTimestamps",
        "value": "1549868056791,9917,74454,1,2,185,14,0,4886,19836,0,371,1,9,2357,832,1,3312,1,9,2113,0,94,6,0,4779,19836,0,273,1,3,154,10,0,4915,19836,0,205,0,3,199,9,0,4939,19837,0,2534,1,3,122,8,0"
      },
      {
        "key": "resultTypes",
        "value": "0,0,0,0,0,0,0,0,3,0,0,0,0,0,0,5,0,0,0,0,0,0,0,0,0,3,0,0,0,0,0,0,0,0,3,0,0,0,0,0,0,0,0,3,0,0,0,0,0,0,0,0"
      },
      {
        "key": "siteId",
        "value": "79284156-3bd0-4c10-ac36-23ff5336acce"
      },
      {
        "key": "_id",
        "value": "B8:27:EB:A2:2B:F1"
      },
      {
        "key": "category",
        "value": "onboarding"
      },
      {
        "key": "timestring",
        "value": "2019-02-11T06:55:46.250+0000"
      },
      {
        "key": "status",
        "value": "active"
      },
      {
        "key": "macAddr",
        "value": "B8:27:EB:A2:2B:F1"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1"
  }
 ]
}
})