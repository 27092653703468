define({
"Template":
{
  "version": "1.0",
  "response": {
    "id": "AWXMpscZCj-ipzc8r5Pj",
    "name": "fabric_reachability_dhcp_overlay_trigger",
    "entityType": "network_device",
    "entity": "192.168.130.2",
    "enabled": true,
    "flattened": true,
    "groupBy": "Unknown",
    "category": "Connected",
    "severity": "HIGH",
    "summary": "Fabric Border DeviceIPPlaceHolder Lost Connectivity to the DHCP Server 192.168.101.105 in the Virtual Network Campus",
    "scope": "GLOBAL",
    "groupId": "fabric_reachability_grouping",
    "groupName": "Fabric Devices Connectivity - DHCP Overlay",
    "priority": "P1",
    "rootCause": "7.1.1.9_192.168.101.105_DhcpOverlay_Campus",
    "timestamp": 1536736806546,
    "description": "The Fabric Border DeviceNamePlaceHolder cannot reach the DHCP server 192.168.101.105 in the Virtual Network VRF 'Campus'.",
    "suggestions": [
      {
        "message": "Verify whether the DHCP server is up.",
        "steps": [

        ]
      },
      {
        "message": "Verify if configuration is compliant on network device",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Verify Fabric configuration for the Virtual Network VRF Campus",
            "command": "show run | sec lisp",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Check the route from Fabric Border to DHCP server",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check ip route to 192.168.101.105 in vrf Campus",
            "command": "show ip route vrf Campus 192.168.101.105",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Verify IGP adjacencies from this node to the upstream switches.",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check ISIS adjacencies",
            "command": "show isis neighbors",
            "stepType": "command-Runner"
          },
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check OSPF adjacencies",
            "command": "show ospf neighbor",
            "stepType": "command-Runner"
          },
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check EIGRP adjacencies",
            "command": "sh eigrp address-family ipv4 neighbors",
            "stepType": "command-Runner"
          },
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check BGP adjacencies",
            "command": "sh ip bgp neighbors",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Contact Cisco TAC World Wide – https://www.cisco.com/c/en/us/support/web/tsd-cisco-worldwide-contacts.html",
        "steps": [

        ]
      }
    ],
    "additionalParams": [
      {
        "key": "destIp",
        "value": "10.16.1.10"
      },
      {
        "key": "_name",
        "value": "fabric_reachability_dhcp_overlay_trigger"
      },
      {
        "key": "_entity_type",
        "value": "NetworkDevice"
      },
      {
        "key": "ctProfileName",
        "value": ""
      },
      {
        "key": "_key",
        "value": "NetworkDevice:42b00d34-7db5-4adf-bc22-bb5f5b3042c1:10.16.1.10:10.16.51.101_10.16.1.10_DhcpOverlay_VN_Employees:Global/North_America/United_States/California/San Jose/SJC01/Flr-SJC1-1:/00d38152-9d4f-41f9-a8e9-af3f811d4920/3d0243ca-266f-4a31-8a4e-3c672bb57d14/185634f7-b7fd-4720-b7c9-db62ffe87c0c/830e5d99-4709-4e4f-be34-46591612677b/7a3005fe-286f-4dc6-a316-a3ff18fb4e4a/728c427c-cf2e-4ae6-bd3b-6b4223ac2f5a/371cdce0-e48e-4c40-90b5-0c6a58c06172/:SJ01-C9500-01.ibng.local:"
      },
      {
        "key": "type",
        "value": "issue"
      },
      {
        "key": "uuid",
        "value": "42b00d34-7db5-4adf-bc22-bb5f5b3042c1"
      },
      {
        "key": "ctProfileId",
        "value": ""
      },
      {
        "key": "sourceRole",
        "value": "Co-located Fabric Border, Control Plane and NA"
      },
      {
        "key": "l3VN",
        "value": "VN_Employees"
      },
      {
        "key": "floorId",
        "value": "371cdce0-e48e-4c40-90b5-0c6a58c06172"
      },
      {
        "key": "areaName",
        "value": "Global/North_America/United_States/California/San Jose"
      },
      {
        "key": "rootCause",
        "value": "10.16.51.101_10.16.1.10_DhcpOverlay_VN_Employees"
      },
      {
        "key": "needState",
        "value": "true"
      },
      {
        "key": "siteType",
        "value": "floor"
      },
      {
        "key": "deviceType",
        "value": "Cisco Catalyst 9500 Switch"
      },
      {
        "key": "deviceUuid",
        "value": "42b00d34-7db5-4adf-bc22-bb5f5b3042c1"
      },
      {
        "key": "kpi",
        "value": "FABRIC_SITE"
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "deviceOS",
        "value": "17.9.2"
      },
      {
        "key": "_eventTime",
        "value": "1674009823677"
      },
      {
        "key": "nwDeviceName",
        "value": "SJ01-C9500-01.ibng.local"
      },
      {
        "key": "deviceGroupHierarchyId",
        "value": "/94a3fe0a-98f5-4a3c-9291-095ab263094f/7cd7d0dc-65d7-4f01-9625-7a176a4a0c6d/"
      },
      {
        "key": "_mostRecent",
        "value": "1674216823677"
      },
      {
        "key": "eventUniqueId",
        "value": "10.16.51.3"
      },
      {
        "key": "vrf",
        "value": "VN_Employees"
      },
      {
        "key": "deviceFamily",
        "value": "Switches and Hubs"
      },
      {
        "key": "priority",
        "value": "P2"
      },
      {
        "key": "sessionIdentifier",
        "value": "10.16.51.101_10.16.1.10_DhcpOverlay_VN_Employees"
      },
      {
        "key": "buildingName",
        "value": "Global/North_America/United_States/California/San Jose/SJC01"
      },
      {
        "key": "siteHierarchyGraphId",
        "value": "/00d38152-9d4f-41f9-a8e9-af3f811d4920/3d0243ca-266f-4a31-8a4e-3c672bb57d14/185634f7-b7fd-4720-b7c9-db62ffe87c0c/830e5d99-4709-4e4f-be34-46591612677b/7a3005fe-286f-4dc6-a316-a3ff18fb4e4a/728c427c-cf2e-4ae6-bd3b-6b4223ac2f5a/371cdce0-e48e-4c40-90b5-0c6a58c06172/"
      },
      {
        "key": "sourceIp",
        "value": "10.16.51.101"
      },
      {
        "key": "l2VNs",
        "value": "10_16_51_224-VN_Employees"
      },
      {
        "key": "tagIdList",
        "value": "[]"
      },
      {
        "key": "status",
        "value": "active"
      },
      {
        "key": "managementIpAddr",
        "value": "10.16.51.3"
      },
      {
        "key": "fabricDriven",
        "value": "true"
      },
      {
        "key": "eventSource",
        "value": "Network Device"
      },
      {
        "key": "fabricSiteId",
        "value": "0a4583e0-33a8-44ee-94c9-c295fdf49e20"
      },
      {
        "key": "_count",
        "value": "346"
      },
      {
        "key": "scope",
        "value": "global"
      },
      {
        "key": "_primary_key",
        "value": "NetworkDevice:42b00d34-7db5-4adf-bc22-bb5f5b3042c1"
      },
      {
        "key": "sessionType",
        "value": "DhcpOverlay"
      },
      {
        "key": "floorName",
        "value": "Global/North_America/United_States/California/San Jose/SJC01/Flr-SJC1-1"
      },
      {
        "key": "severity",
        "value": "HIGH"
      },
      {
        "key": "deviceRole",
        "value": "DISTRIBUTION"
      },
      {
        "key": "_suppression",
        "value": "0"
      },
      {
        "key": "destMgmtIp",
        "value": "NA"
      },
      {
        "key": "entityId",
        "value": "42b00d34-7db5-4adf-bc22-bb5f5b3042c1"
      },
      {
        "key": "buildingId",
        "value": "728c427c-cf2e-4ae6-bd3b-6b4223ac2f5a"
      },
      {
        "key": "areaId",
        "value": "7a3005fe-286f-4dc6-a316-a3ff18fb4e4a"
      },
      {
        "key": "siteHierarchy",
        "value": "Global/North_America/United_States/California/San Jose/SJC01/Flr-SJC1-1"
      },
      {
        "key": "deviceModel",
        "value": "FCW2245F0FN"
      },
      {
        "key": "category",
        "value": "connected"
      },
      {
        "key": "timestring",
        "value": "2023-01-18T02:43:43.677+0000"
      },
      {
        "key": "fabricName",
        "value": "Global/North_America/United_States/California/San Jose/SJC01"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}
})