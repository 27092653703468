define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/inventory/ScheduledJobTemplate'
], function(SimLokiDatabaseActions, SimTaskDataGenerator, UtilityFunctions, ScheduledJobTemplate) {
    
    return {
        scheduledSSA: function(urlAction){
            var taskObj = SimTaskDataGenerator.createTask('NCSS');
            if(typeof(urlAction.restPayload)=="string") {
                urlAction.restPayload = JSON.parse(urlAction.restPayload);
            }
            var deviceId = urlAction.restPayload[0].externalSchedule.notificationBody[0].deviceIds[0]
            var record = SimLokiDatabaseActions.getAll('functional-capability');
            if(record && record.length > 0){
                if(urlAction.restPayload[0].externalSchedule.notificationBody[0].etaEnabled == true){
                    record[0].current_status = "ENABLED"
                }else if(urlAction.restPayload[0].externalSchedule.notificationBody[0].etaEnabled == false){
                    record[0].current_status = "DISABLED"
                }
            record[0].deviceId = deviceId
            }
            var manipulatedStartTime = UtilityFunctions.getTimeStamp() - UtilityFunctions.getMinuteToMilliSeconds(18);
            var device = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id':deviceId});
            var tJson = JSON.parse(JSON.stringify(ScheduledJobTemplate.Scheduled_Job_Template));
            tJson[0].createTime = manipulatedStartTime
            tJson[0].externalSchedule.notificationBody[0]= urlAction.restPayload[0].externalSchedule.notificationBody[0]
            tJson[0].timeZone = urlAction.restPayload[0].timeZone
            tJson[0].startTime = urlAction.restPayload[0].startTime
            tJson[0].description = urlAction.restPayload[0].description
            tJson[0].taskId = taskObj.taskId
            tJson[0].id = UtilityFunctions.generate_uuid();
            tJson[0].scheduledDevices[0].deviceId = deviceId
            tJson[0].scheduledDevices[0].hostname = device[0].name
            tJson[0].scheduledDevices[0].managementIpAddress = device[0].managementIpAddress
            tJson[0].lastUpdateTime = manipulatedStartTime + UtilityFunctions.getMinuteToMilliSeconds(8)

            SimLokiDatabaseActions.insert('scheduled-job', tJson)
            SimLokiDatabaseActions.update('functional-capability', record);
            SimTaskDataGenerator.updateTaskCompletion(taskObj.taskId, {isError: false });
            taskObj.statusCode= 202;
            return taskObj;
        }
        
    }

});