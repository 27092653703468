define({
    "Template":
{
    "request": { 
      "key": "http",
      "type": "Application",
      "label": "http",
      "collection": false,
      "componentContent": [
        {
          "component": "fusion",
          "additional": "9df85327-5fae-44dc-b89e-b4743ecd0f8f"
        }
      ]
    },
    "data": {
      "response": {
        "id": "9df85327-5fae-44dc-b89e-b4743ecd0f8f",
        "instanceId": 4558,
        "authEntityId": 4558,
        "displayName": "4558",
        "authEntityClass": -1909073334,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "createTime": 1528265512808,
        "deployed": false,
        "isSeeded": true,
        "isStale": false,
        "lastUpdateTime": 1528265512808,
        "name": "http",
        "namespace": "scalablegroup:application",
        "provisioningState": "DEFINED",
        "qualifier": "application",
        "resourceVersion": 0,
        "targetIdList": [],
        "type": "scalablegroup",
        "cfsChangeInfo": [],
        "customProvisions": [],
        "scalableGroupExternalHandle": "http",
        "scalableGroupType": "APPLICATION",
        "securityGroupTag": 0,
        "state": "ACTIVE",
        "vnAgnostic": true,
        "identitySource": {
          "id": "607e5f6a-d4bd-41e6-9861-bcd939c316b6",
          "instanceId": 10010,
          "authEntityId": 10010,
          "displayName": "10010",
          "authEntityClass": 1738953278,
          "instanceTenantId": "SYS0",
          "deployPending": "NONE",
          "instanceVersion": 0,
          "state": "INACTIVE",
          "type": "NBAR"
        },
        "indicativeNetworkIdentity": [
          {
            "id": "1535c714-393c-4ec1-a6fd-c519238c4d3a",
            "instanceId": 13384,
            "authEntityId": 13384,
            "displayName": "13384",
            "authEntityClass": 124090504,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "ipv4Subnet": [],
            "ipv6Subnet": [],
            "lowerPort": 0,
            "ports": "80",
            "protocol": "TCP",
            "upperPort": 0
          }
        ],
        "networkApplications": [
          {
            "id": "d494b034-44aa-4694-9fea-2e53b52ed7de",
            "instanceId": 11508,
            "authEntityId": 11508,
            "displayName": "11508",
            "authEntityClass": -217092956,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "appProtocol": "tcp",
            "applicationGroup": "other",
            "applicationType": "DEFAULT",
            "categoryId": "853dcc3d-9711-4e87-a294-dd3e71f60006",
            "createTime": 1528265512807,
            "encrypted": "false",
            "engineId": "3",
            "helpString": "World Wide Web traffic",
            "ignoreConflict": false,
            "lastUpdateTime": 1528265512807,
            "longDescription": "Hypertext Transfer Protocol (HTTP) is a standard for communication between web browsers and servers over the World Wide Web. The protocol works in a request-response manner over a client server computing model. The server usually listens on port 80.",
            "name": "http",
            "nbarId": "3",
            "p2pTechnology": "false",
            "popularity": 10,
            "rank": 65535,
            "references": "http://www.w3.org/Protocols/rfc2616/rfc2616.html",
            "selectorId": "80",
            "subCategory": "8ae37593-d893-48ca-aff7-ea48ea5639d1",
            "trafficClass": "TRANSACTIONAL_DATA",
            "tunnel": "false"
          }
        ],
        "networkIdentity": [
          {
            "id": "3c371dff-bfb0-43b4-a370-ea01ccf9fad9",
            "instanceId": 13383,
            "authEntityId": 13383,
            "displayName": "13383",
            "authEntityClass": 124090504,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "ipv4Subnet": [],
            "ipv6Subnet": [],
            "lowerPort": 0,
            "ports": "80",
            "protocol": "TCP",
            "upperPort": 0
          }
        ],
        "parentScalableGroup": {
          "idRef": "9c9435ee-036b-4526-ad5f-eb35158aeedd"
        },
        "user": [],
        "userGroup": []
      }
    },
    "result": [
      {
        "bookID": {
          "namespace": "fusion",
          "name": "core",
          "version": "1.0.1"
        },
        "response": {
          "id": "9df85327-5fae-44dc-b89e-b4743ecd0f8f",
          "instanceId": 4558,
          "authEntityId": 4558,
          "displayName": "4558",
          "authEntityClass": -1909073334,
          "instanceTenantId": "SYS0",
          "deployPending": "NONE",
          "instanceVersion": 0,
          "createTime": 1528265512808,
          "deployed": false,
          "isSeeded": true,
          "isStale": false,
          "lastUpdateTime": 1528265512808,
          "name": "http",
          "namespace": "scalablegroup:application",
          "provisioningState": "DEFINED",
          "qualifier": "application",
          "resourceVersion": 0,
          "targetIdList": [],
          "type": "scalablegroup",
          "cfsChangeInfo": [],
          "customProvisions": [],
          "scalableGroupExternalHandle": "http",
          "scalableGroupType": "APPLICATION",
          "securityGroupTag": 0,
          "state": "ACTIVE",
          "vnAgnostic": true,
          "identitySource": {
            "id": "607e5f6a-d4bd-41e6-9861-bcd939c316b6",
            "instanceId": 10010,
            "authEntityId": 10010,
            "displayName": "10010",
            "authEntityClass": 1738953278,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "state": "INACTIVE",
            "type": "NBAR"
          },
          "indicativeNetworkIdentity": [
            {
              "id": "1535c714-393c-4ec1-a6fd-c519238c4d3a",
              "instanceId": 13384,
              "authEntityId": 13384,
              "displayName": "13384",
              "authEntityClass": 124090504,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "ipv4Subnet": [],
              "ipv6Subnet": [],
              "lowerPort": 0,
              "ports": "80",
              "protocol": "TCP",
              "upperPort": 0
            }
          ],
          "networkApplications": [
            {
              "id": "d494b034-44aa-4694-9fea-2e53b52ed7de",
              "instanceId": 11508,
              "authEntityId": 11508,
              "displayName": "11508",
              "authEntityClass": -217092956,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "appProtocol": "tcp",
              "applicationGroup": "other",
              "applicationType": "DEFAULT",
              "categoryId": "853dcc3d-9711-4e87-a294-dd3e71f60006",
              "createTime": 1528265512807,
              "encrypted": "false",
              "engineId": "3",
              "helpString": "World Wide Web traffic",
              "ignoreConflict": false,
              "lastUpdateTime": 1528265512807,
              "longDescription": "Hypertext Transfer Protocol (HTTP) is a standard for communication between web browsers and servers over the World Wide Web. The protocol works in a request-response manner over a client server computing model. The server usually listens on port 80.",
              "name": "http",
              "nbarId": "3",
              "p2pTechnology": "false",
              "popularity": 10,
              "rank": 65535,
              "references": "http://www.w3.org/Protocols/rfc2616/rfc2616.html",
              "selectorId": "80",
              "subCategory": "8ae37593-d893-48ca-aff7-ea48ea5639d1",
              "trafficClass": "TRANSACTIONAL_DATA",
              "tunnel": "false"
            }
          ],
          "networkIdentity": [
            {
              "id": "3c371dff-bfb0-43b4-a370-ea01ccf9fad9",
              "instanceId": 13383,
              "authEntityId": 13383,
              "displayName": "13383",
              "authEntityClass": 124090504,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "ipv4Subnet": [],
              "ipv6Subnet": [],
              "lowerPort": 0,
              "ports": "80",
              "protocol": "TCP",
              "upperPort": 0
            }
          ],
          "parentScalableGroup": {
            "idRef": "9c9435ee-036b-4526-ad5f-eb35158aeedd"
          },
          "user": [],
          "userGroup": []
        }
      }
    ]
  }
});