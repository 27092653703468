define({
"Template":
{
  "version": "1.0",
  "response": {
    "id": "AWWsIqhHi1ozWvQF974P",
    "name": "network_interface_connectivity",
    "entityType": "network_device",
    "entity": "10.31.255.1",
    "enabled": true,
    "flattened": true,
    "groupBy": "Unknown",
    "category": "Connectivity",
    "severity": "HIGH",
    "summary": "Interface InterfaceDownPlaceHolder State Changed to Down on Device 10.31.255.1",
    "scope": "GLOBAL",
    "priority": "P1",
    "groupId": "interface_connectivity_failure_grouping",
    "groupName": "Network Device Interface Connectivity - Interface Down",
    "rootCause": "503",
    "timestamp": 1536191273624,
    "description": "Device name:'DeviceNamePlaceHolder' at site:'SJCFloor1' - interface 'InterfaceDownPlaceHolder' state changed to Down",
    "suggestions": [
      {
        "message": "Verify the configuration on the device interface InterfaceDownPlaceHolder",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Use the < sh run int InterfaceDownPlaceHolder > command to check the running configuration on the affected interface on the device",
            "command": "sh run int InterfaceDownPlaceHolder",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Use the <sh int InterfaceDownPlaceHolder > command to check for errors on the interface InterfaceDownPlaceHolder",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check for errors on the interface InterfaceDownPlaceHolder",
            "command": "sh int InterfaceDownPlaceHolder",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Check the cable connected to the interface InterfaceDownPlaceHolder",
        "steps": [

        ]
      },
      {
        "message": "Contact Cisco TAC World Wide – https://www.cisco.com/c/en/us/support/web/tsd-cisco-worldwide-contacts.html",
        "steps": [

        ]
      }
    ],
    "additionalParams": [
      {
        "key": "managementIpAddr",
        "value": "10.31.255.1"
      },
      {
        "key": "remoteIP",
        "value": "NA"
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "__entity_type__",
        "value": "NetworkDevice"
      },
      {
        "key": "entityId",
        "value": "10.31.255.1"
      },
      {
        "key": "type",
        "value": "issue"
      },
      {
        "key": "priority",
        "value": "P1"
      },
      {
        "key": "scope",
        "value": "global"
      },
      {
        "key": "failureReason",
        "value": "Issue"
      },
      {
        "key": "eventStatus",
        "value": "Down"
      },
      {
        "key": "rootCause",
        "value": "503"
      },
      {
        "key": "siteId",
        "value": "9517c1a1-72e1-4372-a8e7-afb75c0d31f8"
      },
      {
        "key": "syslogMsg",
        "value": "SIP0/0: Interface GigabitEthernet0/0/1, changed state to down"
      },
      {
        "key": "category",
        "value": "connectivity"
      },
      {
        "key": "timestring",
        "value": "2018-09-05T23:47:53.624+0000"
      },
      {
        "key": "intfName",
        "value": "GigabitEthernet0/0/1"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}
})