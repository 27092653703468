window.define("apicem-widgets/e2eComponents/DeviceSelector/DeviceMultiSelector", () => require("apicem-widgets/e2eComponents/DeviceSelector/DeviceMultiSelector"));
window.define("apicem-widgets/e2eComponents/InterfaceSelector/InterfaceSelector", () => require("apicem-widgets/e2eComponents/InterfaceSelector/InterfaceSelector"));
window.define("apicem-widgets/e2eComponents/SiteSelector/SiteMultiSelector", () => require("apicem-widgets/e2eComponents/SiteSelector/SiteMultiSelector"));
window.define("apicem-widgets/e2eComponents/SiteSelector/SiteSelector", () => require("apicem-widgets/e2eComponents/SiteSelector/SiteSelector"));
window.define("apicem-widgets/libs/progressbar", () => require("apicem-widgets/libs/progressbar"));
window.define("apicem-widgets/utils/ErrorHandler", () => require("apicem-widgets/utils/ErrorHandler"));
window.define("apicem-widgets/utils/Form", () => require("apicem-widgets/utils/Form"));
window.define("apicem-widgets/utils/Poll", () => require("apicem-widgets/utils/Poll"));
window.define("apicem-widgets/widgets/charts/BarChart", () => require("apicem-widgets/widgets/charts/BarChart"));
window.define("apicem-widgets/widgets/charts/BubbleChart", () => require("apicem-widgets/widgets/charts/BubbleChart"));
window.define("apicem-widgets/widgets/charts/DonutChart", () => require("apicem-widgets/widgets/charts/DonutChart"));
window.define("apicem-widgets/widgets/downloadProgressBar/ProgressBar", () => require("apicem-widgets/widgets/downloadProgressBar/ProgressBar"));
window.define("apicem-widgets/widgets/dTable/dTable", () => require("apicem-widgets/widgets/dTable/dTable"));
window.define("apicem-widgets/widgets/dTable/libs/ellipsisRenderer", () => require("apicem-widgets/widgets/dTable/libs/ellipsisRenderer"));
window.define("apicem-widgets/widgets/form/button/Button", () => require("apicem-widgets/widgets/form/button/Button"));
window.define("apicem-widgets/widgets/form/checkbox/Checkbox", () => require("apicem-widgets/widgets/form/checkbox/Checkbox"));
window.define("apicem-widgets/widgets/form/dndFileField/DnDFileField", () => require("apicem-widgets/widgets/form/dndFileField/DnDFileField"));
window.define("apicem-widgets/widgets/form/dropdown/Dropdown", () => require("apicem-widgets/widgets/form/dropdown/Dropdown"));
window.define("apicem-widgets/widgets/form/radio/Radio", () => require("apicem-widgets/widgets/form/radio/Radio"));
window.define("apicem-widgets/widgets/form/regex", () => require("apicem-widgets/widgets/form/regex"));
window.define("apicem-widgets/widgets/form/searchField/SearchField", () => require("apicem-widgets/widgets/form/searchField/SearchField"));
window.define("apicem-widgets/widgets/form/searchField/SearchUtil", () => require("apicem-widgets/widgets/form/searchField/SearchUtil"));
window.define("apicem-widgets/widgets/form/selectors/Selector", () => require("apicem-widgets/widgets/form/selectors/Selector"));
window.define("apicem-widgets/widgets/form/selectors/MultiSelector", () => require("apicem-widgets/widgets/form/selectors/MultiSelector"));
window.define("apicem-widgets/widgets/form/sliderPro/SliderPro", () => require("apicem-widgets/widgets/form/sliderPro/SliderPro"));
window.define("apicem-widgets/widgets/form/switch/Switch", () => require("apicem-widgets/widgets/form/switch/Switch"));
window.define("apicem-widgets/widgets/form/TextArea", () => require("apicem-widgets/widgets/form/TextArea"));
window.define("apicem-widgets/widgets/form/textField/TextField", () => require("apicem-widgets/widgets/form/textField/TextField"));
window.define("apicem-widgets/widgets/form/toggle/Toggle", () => require("apicem-widgets/widgets/form/toggle/Toggle"));
window.define("apicem-widgets/widgets/hopscotch/hopscotch", () => require("apicem-widgets/widgets/hopscotch/hopscotch"));
window.define("apicem-widgets/widgets/Informer", () => require("apicem-widgets/widgets/Informer"));
window.define("apicem-widgets/widgets/loader/Loader", () => require("apicem-widgets/widgets/loader/Loader"));
window.define("apicem-widgets/widgets/map/HeaderView", () => require("apicem-widgets/widgets/map/HeaderView"));
window.define("apicem-widgets/widgets/map/Map", () => require("apicem-widgets/widgets/map/Map"));
window.define("apicem-widgets/widgets/map/models/MarkerModel", () => require("apicem-widgets/widgets/map/models/MarkerModel"));
window.define("apicem-widgets/widgets/message/Message", () => require("apicem-widgets/widgets/message/Message"));
window.define("apicem-widgets/widgets/modal/confirm/confirm", () => require("apicem-widgets/widgets/modal/confirm/confirm"));
window.define("apicem-widgets/widgets/modal/dialog/Dialog", () => require("apicem-widgets/widgets/modal/dialog/Dialog"));
window.define("apicem-widgets/widgets/modal/toaster", () => require("apicem-widgets/widgets/modal/toaster"));
window.define("apicem-widgets/widgets/navTab/navTab", () => require("apicem-widgets/widgets/navTab/navTab"));
window.define("apicem-widgets/widgets/popover/Popover", () => require("apicem-widgets/widgets/popover/Popover"));
window.define("apicem-widgets/widgets/progressbar/ProgressBar", () => require("apicem-widgets/widgets/progressbar/ProgressBar"));
window.define("apicem-widgets/widgets/sidebar/Sidebar", () => require("apicem-widgets/widgets/sidebar/Sidebar"));
window.define("apicem-widgets/widgets/stepper/Stepper", () => require("apicem-widgets/widgets/stepper/Stepper"));
window.define("apicem-widgets/widgets/toastr/Toastr", () => require("apicem-widgets/widgets/toastr/Toastr"));
window.define("apicem-widgets/widgets/Tooltip", () => require("apicem-widgets/widgets/Tooltip"));
window.define("apicem-widgets/widgets/treeList/TreeList", () => require("apicem-widgets/widgets/treeList/TreeList"));