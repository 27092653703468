define({
    "issuesTemplate": {
      "snmp_cpu_high_80": {
        devices: [{hostName:'C9404R-border_cp_sda.local', daysOld: 7}],
        issueDetailFileName: 'snmp_cpu_high_80.json',
        triggerExists:true,
        triggerFileName: 'snmp_cpu_high_80',
        identifier:"managementIpAddress"
      },
      "switch_interface_input_output_errors": {
        devices: [{hostName: 'p1.edge1-sda1.local', daysOld: 1},{hostName: 'SJC06-C9300-01', daysOld: 1}],
        issueDetailFileName: 'switch_interface_input_output_errors.json',
        triggerExists:false,
        triggerFileName: '',
        identifier:"managementIpAddress"
      },
      "fabric_reachability_session": {
        devices: [{hostName: 'WAN_EDGE1', daysOld: 1}],
        issueDetailFileName: 'fabric_reachability_session.json',
        triggerExists:true,
        triggerFileName: 'fabric_reachability_session',
        identifier:"managementIpAddress"
      },
      "radio_util_trigger": {
        devices: [{hostName: 'AP0081.C424.3CE2', daysOld: 1},{hostName: 'AP4800', daysOld: 1}, {hostName: 'AP9120_3', daysOld: 1}],
        issueDetailFileName: 'radio_util_trigger.json',
        triggerExists:true,
        triggerFileName: 'radio_util_trigger',
        identifier:"macAddress",
        defThresholdVals: [{'key':'radioChannelUtilizationAP', 'value':'70'}, {'key':'currVal', 'value':'80.0'}]
      },
      "radio_util_trigger_ap": {
        devices: [{hostName: 'AP4800', daysOld: 1},{hostName: 'SJC01_9136_12', daysOld: 1}, {hostName: 'AP9120_3', daysOld: 1}],
        issueDetailFileName: 'radio_util_trigger_ap.json',
        triggerExists:true,
        triggerFileName: 'radio_util_trigger_ap',
        identifier:"macAddress",
        defThresholdVals: [{'key':'radioChannelUtilizationAP', 'value':'20.0'}, {'key':'currVal', 'value':'85.0'}],
      },
      "snmp_device_down": {
        devices: [{hostName: 'WLC-FABRIC-01', daysOld: 1}],
        issueDetailFileName: 'snmp_device_down.json',
        triggerExists:false,
        triggerFileName: '',
        identifier:"managementIpAddress"
      },
      "fabric_reachability_session_edge": {
        devices: [{hostName: 'WAN_EDGE1', daysOld: 1}],
        issueDetailFileName: 'fabric_reachability_session_edge.json',
        triggerExists:true,
        triggerFileName: 'fabric_reachability_session_edge',
        identifier:"managementIpAddress"
      },
      "snmp_device_down_edge": {
        devices: [{hostName: 'WAN_EDGE1', daysOld: 1}],
        issueDetailFileName: 'snmp_device_down_edge.json',
        triggerExists:false,
        triggerFileName: '',
        identifier:"managementIpAddress"
      },
      "switch_port_flaps": {
        devices: [{hostName: 'WAN_EDGE1', daysOld: 1}],
        issueDetailFileName: 'switch_port_flaps.json',
        triggerExists:false,
        triggerFileName: '',
        identifier:"managementIpAddress"
      },
      "switch_port_flapping": {
        devices: [{hostName: 'BLD1-FLR1-DST1', daysOld: 1}],
        issueDetailFileName: 'switch_port_flapping.json',
        triggerExists:false,
        triggerFileName: '',
        identifier:"managementIpAddress"
      },
      "network_connectivity_trigger": {
        devices: [{hostName: 'WAN_EDGE1', daysOld: 1}],
        issueDetailFileName: 'network_connectivity_trigger.json',
        triggerExists:true,
        triggerFileName: 'network_connectivity_trigger',
        identifier:"managementIpAddress"
      },
      "snmp_map_cache_limit_reached": {
        devices: [{hostName: 'p1.edge1-sda1.local', daysOld: 1}],
        issueDetailFileName: 'snmp_map_cache_limit_reached.json',
        triggerExists:false,
        triggerFileName: '',
        identifier:"managementIpAddress"
      },
      "default_syslog_event_trigger": {
        devices: [{hostName: 'p1.edge1-sda1.local', daysOld: 1},{hostName: 'SJC06-C9300-01', daysOld: 1}],
        issueDetailFileName: 'default_syslog_event_trigger.json',
        triggerExists:false,
        triggerFileName: '',
        identifier:"managementIpAddress"
      },
      "default_syslog_event_trigger_edge": {
        devices: [{hostName: 'p1.edge1-sda1.local', daysOld: 1}],
        issueDetailFileName: 'default_syslog_event_trigger_edge.json',
        triggerExists:false,
        triggerFileName: '',
        identifier:"managementIpAddress"
      },
      "snmp_memory_high_80": {
        devices: [{hostName: 'p1.edge1-sda1.local', daysOld: 1},{hostName: 'SJC06-C9300-02', daysOld: 1}],
        issueDetailFileName: 'snmp_memory_high_80.json',
        triggerExists:true,
        triggerFileName: 'snmp_memory_high_80',
        identifier:"managementIpAddress"
      },
      "network_connectivity_trigger_ospf": {
        devices: [{hostName: 'p1.edge1-sda1.local', daysOld: 1},{hostName: 'SJC06-C9300-01', daysOld: 1}],
        issueDetailFileName: 'network_connectivity_trigger_ospf.json',
        triggerExists:true,
        triggerFileName: 'network_connectivity_trigger_ospf',
        identifier:"managementIpAddress"
      },
     /* "network_connectivity_trigger_isis": {
        devices: [{hostName: 'p1.edge1-sda1.local', daysOld: 1}],
        issueDetailFileName: 'network_connectivity_trigger_isis.json',
        triggerExists:false,
        triggerFileName: '',
        identifier:"managementIpAddress"
      }, */
      "fabric_reachability_session_cp": {
        devices: [{hostName: 'WAN_EDGE1', daysOld: 1}],
        issueDetailFileName: 'fabric_reachability_session_cp.json',
        triggerExists:true,
        triggerFileName: 'fabric_reachability_session_cp',
        identifier:"managementIpAddress"
      },
      "fabric_reachability_session_dhcp": {
        devices: [{hostName: 'WAN_EDGE1', daysOld: 1}],
        issueDetailFileName: 'fabric_reachability_session_dhcp.json',
        triggerExists:true,
        triggerFileName: 'fabric_reachability_session_dhcp',
        identifier:"managementIpAddress"
      },
      "fabric_reachability_session_dns_pn": {
        devices: [{hostName: 'WAN_EDGE1', daysOld: 1}],
        issueDetailFileName: 'fabric_reachability_session_dns_pn.json',
        triggerExists:true,
        triggerFileName: 'fabric_reachability_session_dns_pn',
        identifier:"managementIpAddress"
      },
      "fabric_reachability_session_dns_vn": {
        devices: [{hostName: 'WAN_EDGE1', daysOld: 1}],
        issueDetailFileName: 'fabric_reachability_session_dns_vn.json',
        triggerExists:true,
        triggerFileName: 'fabric_reachability_session_dns_vn',
        identifier:"managementIpAddress"
      },
      "tcam_util_high": {
        devices: [{hostName: 'WAN_EDGE1', daysOld: 1}],
        issueDetailFileName: 'tcam_util_high.json',
        triggerExists:false,
        triggerFileName: '',
        identifier:"managementIpAddress",
        defThresholdVals: [{'key':'currVal', 'value':'97.0'}]
      },
      "fabric_wlc_reachability": {
        devices: [{hostName: 'WLC-FABRIC-01', daysOld: 1}],
        issueDetailFileName: 'fabric_wlc_reachability.json',
        triggerExists:true,
        triggerFileName: 'fabric_wlc_reachability',
        identifier:"managementIpAddress"
      },
      "ap_ilm_event": {
        devices: [{hostName: 'AP0081.C424.3CE2', daysOld: 1},{hostName: 'SJC01_9136_12', daysOld: 1}],
        issueDetailFileName: 'ap_ilm_event.json',
        triggerExists:false,
        triggerFileName: '',
        identifier:"macAddress"
      },
       "wlc_monitor": {
        devices: [{hostName: 'WLC-FABRIC-01', daysOld: 1}],
        issueDetailFileName: 'wlc_monitor.json',
        triggerExists:false,
        triggerFileName: '',
        identifier:"managementIpAddress"
      },
    /*  "wlc_reboot_crash_trigger": {
        devices: [{hostName: 'WLC-FABRIC-01', daysOld: 1}],
        issueDetailFileName: 'wlc_reboot_crash_trigger.json',
        triggerExists:true,
        triggerFileName: 'wlc_reboot_crash_trigger',
        identifier:"managementIpAddress"
      }, */
      "ap_down": {
        devices: [{hostName: 'AP0081.C424.3CE2', daysOld: 1},{hostName: 'AP4800_1', daysOld: 1}],
        issueDetailFileName: 'ap_down.json',
        triggerExists:false,
        triggerFileName: '',
        identifier:"macAddress"

      },
      "network_connectivity_trigger_eigrp": {
        devices: [{hostName: 'p1.edge1-sda1.local', daysOld: 1}],
        issueDetailFileName: 'network_connectivity_trigger_eigrp.json',
        triggerExists:true,
        triggerFileName: 'network_connectivity_trigger_eigrp',
        identifier:"managementIpAddress"
      },
      "device_time_drift": {
        devices: [{hostName: 'p1.edge1-sda1.local', daysOld: 1}],
        issueDetailFileName: 'device_time_drift.json',
        triggerExists:false,
        triggerFileName: '',
        identifier:"managementIpAddress"
      },
      "nwDevice_ntp_not_synched": {
        devices: [{hostName: 'BLD1-FLR1-DST1', daysOld: 1}],
        issueDetailFileName: 'nwDevice_ntp_not_synched.json',
        triggerExists:false,
        triggerFileName: '',
        identifier:"managementIpAddress"
      },
      "infra_link_down": {
        devices: [{hostName: 'SFO13-D9300-1', daysOld: 1},{hostName: 'SFO13-D9300-2', daysOld: 1}],
        issueDetailFileName: 'infra_link_down.json',
        triggerExists:false,
        triggerFileName: '',
        identifier:"managementIpAddress"
      },
      "mac_flap_trigger":{
        devices: [{hostName: 'SFO13-D9300-1', daysOld: 1},{hostName: 'SFO13-D9300-2', daysOld: 1}],
        issueDetailFileName: 'mac_flap_trigger.json',
        triggerExists:false,
        triggerFileName: '',
        identifier:"managementIpAddress"
      },
      "network_device_poe_err_disabled_trigger":{
        devices: [{hostName: 'p1.edge1-sda1.local', daysOld: 1}],
        issueDetailFileName: 'network_device_poe_err_disabled_trigger.json',
        triggerExists:false,
        triggerFileName: '',
        identifier:"managementIpAddress"
      },
      "network_device_poe_power_deny_trigger":{
        devices: [{hostName: 'p1.edge1-sda1.local', daysOld: 1}],
        issueDetailFileName: 'network_device_poe_power_deny_trigger.json',
        triggerExists:false,
        triggerFileName: '',
        identifier:"managementIpAddress"
      },
      "network_device_controller_poe_trigger":{
        devices: [{hostName: 'C9404R-border_cp_sda.local', daysOld: 1}],
        issueDetailFileName: 'network_device_controller_poe_trigger.json',
        triggerExists:false,
        triggerFileName: '',
        identifier:"managementIpAddress"
      },
      "switch_svl_down": {
        devices: [{hostName: 'SFO15-C9500-Core-02', daysOld: 1}],
        issueDetailFileName: 'switch_svl_down.json',
        triggerExists:false,
        triggerFileName: '',
        identifier:"managementIpAddress"
      },
      "default_trap_event_trigger": {
        devices: [{hostName: 'SFO15-C9500-Core-02', daysOld: 1}],
        issueDetailFileName: 'default_trap_event_trigger.json',
        triggerExists:false,
        triggerFileName: '',
        identifier:"managementIpAddress"
      },
      "ap_flap": {
        devices: [{hostName: 'AP0081.C424.3CE2', daysOld: 1}],
        issueDetailFileName: 'ap_flap.json',
        triggerExists:false,
        triggerFileName: '',
        identifier:"macAddress"
      },
      /*"global_wireless_kairos_radio_media_app_throughput_drop_trigger": {
        devices: [{hostName: 'AP0081.C424.3CE2', daysOld: 1}],
        issueDetailFileName: 'global_wireless_kairos_radio_media_app_throughput_drop_trigger.json',
        triggerExists:false,
        triggerFileName: '',
        identifier:"macAddress",
        AiDriven:true
      },*/
      "global_wireless_kairos_radio_cloud_app_throughput_drop_trigger":{
        devices: [{hostName: 'AP0081.C424.3CE2', daysOld: 1}],
        issueDetailFileName: 'global_wireless_kairos_radio_cloud_app_throughput_drop_trigger.json',
        triggerExists:false,
        triggerFileName: '',
        identifier:"macAddress",
        AiDriven:true
      },
      /*"global_wireless_kairos_radio_social_app_throughput_drop_trigger":{
        devices: [{hostName: 'AP0081.C424.3CE2', daysOld: 1}],
        issueDetailFileName: 'global_wireless_kairos_radio_social_app_throughput_drop_trigger.json',
        triggerExists:false,
        triggerFileName: '',
        identifier:"macAddress",
        AiDriven:true
      },*/
      "global_wireless_kairos_radio_throughput_drop_trigger":{
         devices: [{hostName: 'AP0081.C424.3CE2', daysOld: 1}],
        issueDetailFileName: 'global_wireless_kairos_radio_throughput_drop_trigger.json',
        triggerExists:false,
        triggerFileName: '',
        identifier:"macAddress",
        AiDriven:true
      },
      "switch_issue_power": {
        devices: [{hostName: 'SFO15-C9300-03', daysOld: 1}],
        issueDetailFileName: 'switch_issue_power.json',
        triggerExists:false,
        triggerFileName: '',
        identifier:"managementIpAddress"
      }
    }
})
