define({
    "DeviceCredential_Template": {
        "ipAddress":"",
         "snmpVersion":"V2",
         "snmpTimeout":10,
         "snmpRetry":3,
         "snmpROCommunity":"read",
         "snmpRWCommunity":"write",
         "cliTransport":"telnet",
         "userName":"cisco",
         "password":"cisco",
         "enablePassword":"cisco",
         "computeDevice":false,
         "httpSecure":false,
         "type":"NETWORK_DEVICE",
         "snmpAuthPassphrase":"cisco",
         "snmpAuthProtocol":"",
         "snmpMode":"",
         "snmpPrivPassphrase":"",
         "snmpPrivProtocol":"",
         "snmpUserName":"",
         "port":"2",
         "httpUserName":"cisco",
         "httpPassword":"cisco",
         "httpPort":"2"
    }
});

