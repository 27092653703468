define({
"Template":
{
  "request": {
    "entity": {
      "intfName": "GigabitEthernet0/0/1",
      "managementIpAddr": "10.31.255.1",
      "rootCause": "503",
      "type": "event"
    },
    "dimensions": [

    ],
    "timeRange": {
      "start": "2018-09-04T23:47:53.624Z",
      "end": "2018-09-06T06:47:55.170Z"
    },
    "values": [

    ]
  },
  "pagination": {
    "links": [
      {
        "rel": "self",
        "url": "http://queryengine.ndp.svc.cluster.local:8006/api/ndp/v1/data/triggers?page=1&pageSize=300&order=DESC",
        "method": "POST",
        "body": "{\"entity\":{\"intfName\":\"GigabitEthernet0/0/1\",\"managementIpAddr\":\"10.31.255.1\",\"rootCause\":\"503\",\"type\":\"event\"},\"dimensions\":[],\"timeRange\":{\"start\":\"2018-09-04T23:47:53.624Z\",\"end\":\"2018-09-06T06:47:55.170Z\"},\"values\":[]}"
      },
      {
        "rel": "first",
        "url": "http://queryengine.ndp.svc.cluster.local:8006/api/ndp/v1/data/triggers?page=1&pageSize=300&order=DESC",
        "method": "POST",
        "body": "{\"entity\":{\"intfName\":\"GigabitEthernet0/0/1\",\"managementIpAddr\":\"10.31.255.1\",\"rootCause\":\"503\",\"type\":\"event\"},\"dimensions\":[],\"timeRange\":{\"start\":\"2018-09-04T23:47:53.624Z\",\"end\":\"2018-09-06T06:47:55.170Z\"},\"values\":[]}"
      },
      {
        "rel": "last",
        "url": "http://queryengine.ndp.svc.cluster.local:8006/api/ndp/v1/data/triggers?page=1&pageSize=300&order=DESC",
        "method": "POST",
        "body": "{\"entity\":{\"intfName\":\"GigabitEthernet0/0/1\",\"managementIpAddr\":\"10.31.255.1\",\"rootCause\":\"503\",\"type\":\"event\"},\"dimensions\":[],\"timeRange\":{\"start\":\"2018-09-04T23:47:53.624Z\",\"end\":\"2018-09-06T06:47:55.170Z\"},\"values\":[]}"
      }
    ],
    "page": 1,
    "pageSize": 300,
    "order": "DESC",
    "totalResults": 8
  },
  "warnings": null,
  "values": [
    {
      "name": "network_connectivity_trigger",
      "severity": "HIGH",
      "id": "AWWsKN9oi1ozWvQF98CU",
      "time": "2018-09-05T23:54:43.335+0000",
      "endTime": "2018-09-05T23:54:43.335+0000",
      "count": 1,
      "attributes": [
        {
          "key": "managementIpAddr",
          "value": "10.31.255.1"
        },
        {
          "key": "remoteIP",
          "value": "NA"
        },
        {
          "key": "type",
          "value": "event"
        },
        {
          "key": "scope",
          "value": "global"
        },
        {
          "key": "rootCause",
          "value": "503"
        },
        {
          "key": "syslogMsg",
          "value": "Interface GigabitEthernet0/0/1, changed state to up"
        },
        {
          "key": "entityType",
          "value": "network_device"
        },
        {
          "key": "__entity_type__",
          "value": "NetworkDevice"
        },
        {
          "key": "entityId",
          "value": "10.31.255.1"
        },
        {
          "key": "priority",
          "value": "P1"
        },
        {
          "key": "failureReason",
          "value": "Issue"
        },
        {
          "key": "eventStatus",
          "value": "Up"
        },
        {
          "key": "siteId",
          "value": "9517c1a1-72e1-4372-a8e7-afb75c0d31f8"
        },
        {
          "key": "category",
          "value": "connectivity"
        },
        {
          "key": "intfName",
          "value": "GigabitEthernet0/0/1"
        },
        {
          "key": "status",
          "value": "active"
        }
      ],
      "source": "internal",
      "isActive": false
    },
    {
      "name": "network_connectivity_trigger",
      "severity": "HIGH",
      "id": "AWWsKN9oi1ozWvQF98CS",
      "time": "2018-09-05T23:54:43.335+0000",
      "endTime": "2018-09-05T23:54:43.335+0000",
      "count": 1,
      "attributes": [
        {
          "key": "managementIpAddr",
          "value": "10.31.255.1"
        },
        {
          "key": "remoteIP",
          "value": "NA"
        },
        {
          "key": "type",
          "value": "event"
        },
        {
          "key": "scope",
          "value": "global"
        },
        {
          "key": "rootCause",
          "value": "503"
        },
        {
          "key": "syslogMsg",
          "value": "Interface GigabitEthernet0/0/1, changed state to up"
        },
        {
          "key": "entityType",
          "value": "network_device"
        },
        {
          "key": "__entity_type__",
          "value": "NetworkDevice"
        },
        {
          "key": "entityId",
          "value": "10.31.255.1"
        },
        {
          "key": "priority",
          "value": "P1"
        },
        {
          "key": "failureReason",
          "value": "Issue"
        },
        {
          "key": "eventStatus",
          "value": "Up"
        },
        {
          "key": "siteId",
          "value": "9517c1a1-72e1-4372-a8e7-afb75c0d31f8"
        },
        {
          "key": "category",
          "value": "connectivity"
        },
        {
          "key": "intfName",
          "value": "GigabitEthernet0/0/1"
        },
        {
          "key": "status",
          "value": "active"
        }
      ],
      "source": "internal",
      "isActive": false
    },
    {
      "name": "network_connectivity_trigger",
      "severity": "HIGH",
      "id": "AWWsKN9oi1ozWvQF98CT",
      "time": "2018-09-05T23:54:43.334+0000",
      "endTime": "2018-09-05T23:54:43.334+0000",
      "count": 1,
      "attributes": [
        {
          "key": "managementIpAddr",
          "value": "10.31.255.1"
        },
        {
          "key": "remoteIP",
          "value": "NA"
        },
        {
          "key": "type",
          "value": "event"
        },
        {
          "key": "scope",
          "value": "global"
        },
        {
          "key": "rootCause",
          "value": "503"
        },
        {
          "key": "syslogMsg",
          "value": "SIP0/0: Interface GigabitEthernet0/0/1, changed state to up"
        },
        {
          "key": "entityType",
          "value": "network_device"
        },
        {
          "key": "__entity_type__",
          "value": "NetworkDevice"
        },
        {
          "key": "entityId",
          "value": "10.31.255.1"
        },
        {
          "key": "priority",
          "value": "P1"
        },
        {
          "key": "failureReason",
          "value": "Issue"
        },
        {
          "key": "eventStatus",
          "value": "Up"
        },
        {
          "key": "siteId",
          "value": "9517c1a1-72e1-4372-a8e7-afb75c0d31f8"
        },
        {
          "key": "category",
          "value": "connectivity"
        },
        {
          "key": "intfName",
          "value": "GigabitEthernet0/0/1"
        },
        {
          "key": "status",
          "value": "active"
        }
      ],
      "source": "internal",
      "isActive": false
    },
    {
      "name": "network_connectivity_trigger",
      "severity": "HIGH",
      "id": "AWWsKN9oi1ozWvQF98CV",
      "time": "2018-09-05T23:54:43.334+0000",
      "endTime": "2018-09-05T23:54:43.334+0000",
      "count": 1,
      "attributes": [
        {
          "key": "managementIpAddr",
          "value": "10.31.255.1"
        },
        {
          "key": "remoteIP",
          "value": "NA"
        },
        {
          "key": "type",
          "value": "event"
        },
        {
          "key": "scope",
          "value": "global"
        },
        {
          "key": "rootCause",
          "value": "503"
        },
        {
          "key": "syslogMsg",
          "value": "SIP0/0: Interface GigabitEthernet0/0/1, changed state to up"
        },
        {
          "key": "entityType",
          "value": "network_device"
        },
        {
          "key": "__entity_type__",
          "value": "NetworkDevice"
        },
        {
          "key": "entityId",
          "value": "10.31.255.1"
        },
        {
          "key": "priority",
          "value": "P1"
        },
        {
          "key": "failureReason",
          "value": "Issue"
        },
        {
          "key": "eventStatus",
          "value": "Up"
        },
        {
          "key": "siteId",
          "value": "9517c1a1-72e1-4372-a8e7-afb75c0d31f8"
        },
        {
          "key": "category",
          "value": "connectivity"
        },
        {
          "key": "intfName",
          "value": "GigabitEthernet0/0/1"
        },
        {
          "key": "status",
          "value": "active"
        }
      ],
      "source": "internal",
      "isActive": false
    },
    {
      "name": "network_connectivity_trigger",
      "severity": "HIGH",
      "id": "AWWsIqhHi1ozWvQF974Q",
      "time": "2018-09-05T23:47:53.624+0000",
      "endTime": "2018-09-05T23:47:53.624+0000",
      "count": 1,
      "attributes": [
        {
          "key": "managementIpAddr",
          "value": "10.31.255.1"
        },
        {
          "key": "remoteIP",
          "value": "NA"
        },
        {
          "key": "type",
          "value": "event"
        },
        {
          "key": "scope",
          "value": "global"
        },
        {
          "key": "rootCause",
          "value": "503"
        },
        {
          "key": "syslogMsg",
          "value": "SIP0/0: Interface GigabitEthernet0/0/1, changed state to down"
        },
        {
          "key": "entityType",
          "value": "network_device"
        },
        {
          "key": "__entity_type__",
          "value": "NetworkDevice"
        },
        {
          "key": "entityId",
          "value": "10.31.255.1"
        },
        {
          "key": "priority",
          "value": "P1"
        },
        {
          "key": "failureReason",
          "value": "Issue"
        },
        {
          "key": "eventStatus",
          "value": "Down"
        },
        {
          "key": "siteId",
          "value": "9517c1a1-72e1-4372-a8e7-afb75c0d31f8"
        },
        {
          "key": "category",
          "value": "connectivity"
        },
        {
          "key": "intfName",
          "value": "GigabitEthernet0/0/1"
        },
        {
          "key": "status",
          "value": "active"
        }
      ],
      "source": "internal",
      "isActive": false
    }
  ]
}
})