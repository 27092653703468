define({
  "Template": {
  "version": "1.0",
  "response":[
  {
    "id": "AWjEie2asIPgh8_7Qz1B",
    "name": "global_wireless_client_excess_auth_aaa_fail_trigger",
    "enabled": true,
    "flattened": true,
    "entityType": "Client",
    "entity": "5C:5F:67:CE:3D:7B",
    "groupBy": "CT5520-MK",
    "category": "Onboarding",
    "severity": "HIGH",
    "summary": "Wireless client took a long time to connect (SSID: SSID@ToChange, AP: AP@ToChange, Band: Band@ToChangeGHz, WLC:  Wlc@ToChange) - Excessive time due to failed credentials",
    "scope": "LOCALIZED",
    "priority": null,
    "rootCause": "EXCESSIVE_AUTH_FAIL_SERVER",
    "timestamp": 1549485470373,
    "description": "This client is taking longer than expected time to connect to 'SSID@ToChange' SSID due to excessive authentication time<ul><li>Onboarding time took 74.3 seconds (expected time should be less than 10.0 seconds).</li><li>Association took 0 seconds (expected time should be less than 2.0 seconds)</li><li>Authentication and Key Exchange took less than a second (expected time should be less than 3.0 seconds)</li><li>IP Addressing took 0 seconds (expected time should be less than 5.0 seconds)</li><li>This client tried to onboard 4 times which took 74.1 seconds before successfully connecting.</li></ul><br>The client is taking longer to authenticate because the AAA Server  initially rejected the client credentials.The client was connecting to 'SSID@ToChange' SSID on Band@ToChange GHz radio on 'AP@ToChange' AP  in 'Site@ToChange'. The AP was connected to 'Wlc@ToChange' WLC.",
    "suggestions": [
      {
        "message": "Verify whether the client provided the correct credentials.",
        "steps": [

        ]
      },
      {
        "message": "Verify if there has been any failure on the AAA Server .",
        "steps": [

        ]
      }
    ],
    "additionalParams": [
      {
        "key": "__key__",
        "value": "Interface:5C:5F:67:CE:3D:7B:5C:5F:67:CE:3D:7B:EXCESSIVE_AUTH_FAIL_SERVER:CT5520-MK:true"
      },
      {
        "key": "hostName",
        "value": "CAGGQ0MMOJVH1"
      },
      {
        "key": "groupType",
        "value": "WLC"
      },
      {
        "key": "assocTime",
        "value": "0"
      },
      {
        "key": "authTime",
        "value": "177"
      },
      {
        "key": "groupBy",
        "value": "CT5520-MK"
      },
      {
        "key": "slot",
        "value": "0"
      },
      {
        "key": "type",
        "value": "issue"
      },
      {
        "key": "reasonType",
        "value": "cl_AAA_AUTH_FAIL"
      },
      {
        "key": "previousApName",
        "value": "AP4800.922C"
      },
      {
        "key": "ssid",
        "value": "@CorpSSID"
      },
      {
        "key": "apName",
        "value": "AP4800.8DEC"
      },
      {
        "key": "frequency",
        "value": "2.4"
      },
      {
        "key": "reasonCodes",
        "value": "1,0,0,1,0,0,1,122,0,0,0,0,1,0,0,0,0,51,0,0,1,0,0,0,0,89,0,0,191"
      },
      {
        "key": "vlan",
        "value": "0"
      },
      {
        "key": "hostType",
        "value": "WIRELESS"
      },
      {
        "key": "rootCause",
        "value": "EXCESSIVE_AUTH_FAIL_SERVER"
      },
      {
        "key": "aaaServerIp",
        "value": ""
      },
      {
        "key": "resultType",
        "value": "cl_FAIL"
      },
      {
        "key": "__suppression__",
        "value": "0"
      },
      {
        "key": "dhcpTime",
        "value": "0"
      },
      {
        "key": "hostOSType",
        "value": "Microsoft-Workstation"
      },
      {
        "key": "isRoaming",
        "value": "true"
      },
      {
        "key": "entityType",
        "value": "interface_host"
      },
      {
        "key": "totalTime",
        "value": "74282"
      },
      {
        "key": "__entity_type__",
        "value": "Interface"
      },
      {
        "key": "previousWlcName",
        "value": ""
      },
      {
        "key": "apGroup",
        "value": "iCap_AP"
      },
      {
        "key": "dhcpServerIp",
        "value": ""
      },
      {
        "key": "apMac",
        "value": "F4:DB:E6:86:F5:E0"
      },
      {
        "key": "entityId",
        "value": "5C:5F:67:CE:3D:7B"
      },
      {
        "key": "eventType",
        "value": "cl_EVENT_AUTH_DONE"
      },
      {
        "key": "priority",
        "value": "P4"
      },
      {
        "key": "eventTypes",
        "value": "1,2,3,1,2,3,1,2,3,12,3,13,1,2,3,12,3,4,3,3,1,2,3,12,3,4,5,9,7"
      },
      {
        "key": "wlcName",
        "value": "CT5520-MK"
      },
      {
        "key": "osCategory",
        "value": "Windows"
      },
      {
        "key": "deviceCategory",
        "value": "Microsoft-Workstation"
      },
      {
        "key": "eventTimestamps",
        "value": "1549485447576,0,3,2308,0,3,5495,1,2,83,0,10349,4376,0,8,115,0,54,5071,30664,22724,1,2,358,0,449,25,0,5466"
      },
      {
        "key": "resultTypes",
        "value": "0,0,0,0,0,0,0,0,0,0,0,5,0,0,0,0,0,5,0,0,0,0,0,0,0,0,0,0,0"
      },
      {
        "key": "siteId",
        "value": "2572a60e-c5d0-4e9a-a332-28881db6bdc8"
      },
      {
        "key": "_id",
        "value": "5C:5F:67:CE:3D:7B"
      },
      {
        "key": "category",
        "value": "onboarding"
      },
      {
        "key": "timestring",
        "value": "2019-02-06T20:37:50.373+0000"
      },
      {
        "key": "status",
        "value": "active"
      },
      {
        "key": "macAddr",
        "value": "5C:5F:67:CE:3D:7B"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": "SAJO/SJ23/F1"
  }
 ]
}
})