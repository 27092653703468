define({
    "runningImageList": [
        {
            "name": "ie3x00-universalk9.17.09.01.SPA.bin",
            "imageUuid": "c56af3d1-afff-4444-fefe-343b51b3150c",
            "version": "17.9.1",
            "softwareType": null,
            "imageType": "SYSTEM_SW",
            "isTaggedGolden": "true",
            "md5Checksum": "2c3ebe52ecc3adc7f1f9b99df09b649e",
            "shaCheckSum": "db4d42b0e2a026c13b1871f2665df6c3dc8a6d2f0c97fa8487c17edac13a9ed86b4766b6823b9aa2d7d4d2be5d3db59f8105936183cf6f6a0f8737db4c2de1b7",
            "createdTime": "2022-08-01 19:31:00.00",
            "applicationType": "UNKNOWN",
            "feature": null,
            "fileServiceId": "d15d786c-9402-5454-ace0-f2619cebe23c",
            "imageSeries": null,
            "imageSource": "CCO",
            "imageSourceType": "Imported from CCO",
            "imageFamily": "IE3K",
            "vendor": "CISCO",
            "filesize": 398498492 ,
            "deviceCount": 0,
            "deviceUuidList": [],
            "smuCount": 0,
            "imageAttributes": [
                {
                    "propertyName": "min_flash",
                    "propertyValue": "2048"
                },
                {
                    "propertyName": "image_guid",
                    "propertyValue": "2FCBCEB5E46F7293EE8E239AD3E4A374EEF98D81"
                },
                {
                    "propertyName": "release_fcs_date",
                    "propertyValue": "01/Aug/2022"
                },
                {
                    "propertyName": "image_size",
                    "propertyValue": "343483250"
                },
                {
                    "propertyName": "min_dram",
                    "propertyValue": "4096"
                },
                {
                    "propertyName": "image_description",
                    "propertyValue": "Cisco Industrial Ethernet 3x00 series Switches"
                },
                {
                    "propertyName": "metadata_trans_id",
                    "propertyValue": "153426088"
                },
                {
                    "propertyName": "udi",
                    "propertyValue": "PID: IE-3300-8P2S VID: V01, SN: FCW2146L02A"
                },
                {
                    "propertyName": "encryption_software_indicator",
                    "propertyValue": "Y"
                },
                {
                    "propertyName": "mdf_id",
                    "propertyValue": "286320755"
                }
            ],
            "isSMUApplicable": true,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": true,
            "reasonForNotSupportingImport": null,
            "tagList": [
                {
                    "taggedGolden": true,
                    "role": "ALL",
                    "inheritedSiteName": "",
                    "inheritedSiteUuid": ""
                }
            ],
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": "",
            "imageImported": true,
            "applicableDevicesForImage": [
                {
                    "mdfId": "286320755",
                    "productName": "Catalyst IE-3300-8P2S Rugged Switch",
                    "productId": [
                        "IE-3300-8P2S"
                    ]
                }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "ccoreverseSync": false,
            "ccorecommended": false,
            "ccolatest": true
        },{
            "name": "ie3x00-universalk9.17.06.01.SPA.bin",
            "imageUuid": "c56af3d1-afff-1212-fefe-343b51b3150c",
            "version": "17.6.1",
            "softwareType": null,
            "imageType": "SYSTEM_SW",
            "isTaggedGolden": "false",
            "md5Checksum": "2c3ebe52ecc3adc7f1f9b99df09b649e",
            "shaCheckSum": "db4d42b0e2a026c13b1871f2665df6c3dc8a6d2f0c97fa8487c17edac13a9ed86b4766b6823b9aa2d7d4d2be5d3db59f8105936183cf6f6a0f8737db4c2de1b7",
            "createdTime": "2021-09-14 19:31:00.00",
            "applicationType": "UNKNOWN",
            "feature": null,
            "fileServiceId": "d15d786c-9402-5454-ace0-f2619cebe23c",
            "imageSeries": null,
            "imageSource": "CCO",
            "imageSourceType": "Imported from CCO",
            "imageFamily": "NFVIS",
            "vendor": "CISCO",
            "filesize": 398498492 ,
            "deviceCount": 0,
            "deviceUuidList": [],
            "smuCount": 0,
            "imageAttributes": [
                {
                    "propertyName": "min_flash",
                    "propertyValue": "2048"
                },
                {
                    "propertyName": "image_guid",
                    "propertyValue": "2FCBCEB5E46F7293EE8E239AD3E4A374EEF98D81"
                },
                {
                    "propertyName": "release_fcs_date",
                    "propertyValue": "05/Mar/2021"
                },
                {
                    "propertyName": "image_size",
                    "propertyValue": "343483250"
                },
                {
                    "propertyName": "min_dram",
                    "propertyValue": "4096"
                },
                {
                    "propertyName": "image_description",
                    "propertyValue": "Cisco Industrial Ethernet 3x00 series Switches"
                },
                {
                    "propertyName": "metadata_trans_id",
                    "propertyValue": "153426088"
                },
                {
                    "propertyName": "udi",
                    "propertyValue": "PID: IE-3300-8P2S VID: V01, SN: FCW2146L02A"
                },
                {
                    "propertyName": "encryption_software_indicator",
                    "propertyValue": "Y"
                },
                {
                    "propertyName": "mdf_id",
                    "propertyValue": "286320755"
                }
            ],
            "isSMUApplicable": true,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": true,
            "reasonForNotSupportingImport": null,
            "tagList": [
                {
                    "taggedGolden": false,
                    "role": "ALL",
                    "inheritedSiteName": "",
                    "inheritedSiteUuid": ""
                },
                {
                    "taggedGolden": false,
                    "role": "CORE",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "DISTRIBUTION",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "BORDER ROUTER",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "UNKNOWN",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "ACCESS",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                }
            ],
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": "",
            "imageImported": true,
            "applicableDevicesForImage": [
                {
                    "mdfId": "286320755",
                    "productName": "Catalyst IE-3300-8P2S Rugged Switch",
                    "productId": [
                        "IE-3300-8P2S"
                    ]
                }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "ccoreverseSync": false,
            "ccorecommended": false,
            "ccolatest": true
        },
        {
            "name": "ie3x00-universalk9.17.03.03.SPA.bin",
            "imageUuid": "c56af3d1-aadd-1212-fefe-343b51b3150c",
            "version": "17.3.3",
            "softwareType": null,
            "imageType": "SYSTEM_SW",
            "isTaggedGolden": "false",
            "md5Checksum": "9e743586c307e82e294f5a75dd5216fd",
            "shaCheckSum": "2a0d65006ea437ab4bbce345a9089c9a0417ec5caf2f0dd114bae980a5322aeba21070cb3179919bdc8ea73414c294f499866987afabc0b8a5294df5f2fb05c1",
            "createdTime": "2021-03-15 19:31:00.00",
            "applicationType": "UNKNOWN",
            "feature": null,
            "fileServiceId": "d15d786c-9402-5454-ace0-f2619cebe23c",
            "imageSeries": null,
            "imageSource": "CCO",
            "imageSourceType": "Imported from CCO",
            "imageFamily": "NFVIS",
            "vendor": "CISCO",
            "filesize": 343483250,
            "deviceCount": 0,
            "deviceUuidList": [],
            "smuCount": 0,
            "imageAttributes": [
                {
                    "propertyName": "min_flash",
                    "propertyValue": "2048"
                },
                {
                    "propertyName": "image_guid",
                    "propertyValue": "2FCBCEB5E46F7293EE8E239AD3E4A374EEF98D81"
                },
                {
                    "propertyName": "release_fcs_date",
                    "propertyValue": "05/Mar/2021"
                },
                {
                    "propertyName": "image_size",
                    "propertyValue": "343483250"
                },
                {
                    "propertyName": "min_dram",
                    "propertyValue": "4096"
                },
                {
                    "propertyName": "image_description",
                    "propertyValue": "Cisco Industrial Ethernet 3x00 series Switches"
                },
                {
                    "propertyName": "metadata_trans_id",
                    "propertyValue": "153426088"
                },
                {
                    "propertyName": "udi",
                    "propertyValue": "PID: IE-3300-8P2S VID: V01, SN: FCW2146L02A"
                },
                {
                    "propertyName": "encryption_software_indicator",
                    "propertyValue": "Y"
                },
                {
                    "propertyName": "mdf_id",
                    "propertyValue": "286320755"
                }
            ],
            "isSMUApplicable": true,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": true,
            "reasonForNotSupportingImport": null,
            "tagList": [
                {
                    "taggedGolden": false,
                    "role": "ALL",
                    "inheritedSiteName": "",
                    "inheritedSiteUuid": ""
                },
                {
                    "taggedGolden": false,
                    "role": "CORE",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "DISTRIBUTION",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "BORDER ROUTER",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "UNKNOWN",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "ACCESS",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                }
            ],
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": "",
            "imageImported": true,
            "applicableDevicesForImage": [
                {
                    "mdfId": "286320755",
                    "productName": "Catalyst IE-3300-8P2S Rugged Switch",
                    "productId": [
                        "IE-3300-8P2S"
                    ]
                }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "ccoreverseSync": false,
            "ccorecommended": false,
            "ccolatest": true
        },
        {
            "name": "ie3x00-universalk9.17.03.01.SPA.bin",
            "imageUuid": "c56af3d1-aadd-1212-cced-343b51b3150c",
            "version": "17.3.1",//Amsterdam-17.3.1
            "softwareType": null,
            "imageType": "SYSTEM_SW",
            "isTaggedGolden": "false",
            "md5Checksum": "37be5583d68f257855142889053e569d",
            "shaCheckSum": "ca8849611cd777569ba4706cb2c6de2324d959839ae7db516fee2d4b41d37b796d734aeb1ff0ad514b70435841fcf641ca37b1a9763bd910fcc162b4fcd696d8",
            "createdTime": "2020-10-16 14:56:00.00",
            "applicationType": "UNKNOWN",
            "feature": null,
            "fileServiceId": "d15d786c-9402-43e8-ace0-f2619cebe23c",
            "imageSeries": null,
            "imageSource": "CCO",
            "imageSourceType": "Imported from CCO",
            "imageFamily": "NFVIS",
            "vendor": "CISCO",
            "filesize": 344339501,
            "deviceCount": 0,
            "deviceUuidList": [],
            "smuCount": 0,
            "imageAttributes": [
                {
                    "propertyName": "min_flash",
                    "propertyValue": "2048"
                },
                {
                    "propertyName": "image_guid",
                    "propertyValue": "2FCBCEB5E46F7293EE8E239AD3E4A374EEF98D81"
                },
                {
                    "propertyName": "release_fcs_date",
                    "propertyValue": "09/Aug/2020"
                },
                {
                    "propertyName": "image_size",
                    "propertyValue": "344339501"
                },
                {
                    "propertyName": "min_dram",
                    "propertyValue": "4096"
                },
                {
                    "propertyName": "image_description",
                    "propertyValue": "Cisco Industrial Ethernet 3x00 series Switches"
                },
                {
                    "propertyName": "metadata_trans_id",
                    "propertyValue": "153426088"
                },
                {
                    "propertyName": "udi",
                    "propertyValue": "PID: IE-3300-8P2S VID: V01, SN: FCW2146L02A"
                },
                {
                    "propertyName": "encryption_software_indicator",
                    "propertyValue": "Y"
                },
                {
                    "propertyName": "mdf_id",
                    "propertyValue": "286320755"
                }
            ],
            "isSMUApplicable": true,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": true,
            "reasonForNotSupportingImport": null,
            "tagList": [
                {
                    "taggedGolden": false,
                    "role": "ALL",
                    "inheritedSiteName": "",
                    "inheritedSiteUuid": ""
                },
                {
                    "taggedGolden": false,
                    "role": "CORE",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "DISTRIBUTION",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "BORDER ROUTER",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "UNKNOWN",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "ACCESS",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                }
            ],
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": "",
            "imageImported": true,
            "applicableDevicesForImage": [
                {
                    "mdfId": "286320755",
                    "productName": "Catalyst IE-3300-8P2S Rugged Switch",
                    "productId": [
                        "IE-3300-8P2S"
                    ]
                }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "ccoreverseSync": false,
            "ccorecommended": false,
            "ccolatest": true
        },
        {
            "name": "ie3x00-universalk9.17.02.01.SPA.bin",
            "imageUuid": "c56af3d1-4576-1212-cced-343b51b3150c",
            "version": "17.2.1",//Amsterdam-17.2.1
            "softwareType": null,
            "imageType": "SYSTEM_SW",
            "isTaggedGolden": "false",
            "md5Checksum": "117c00c3d203fa840410310b1a10102f",
            "shaCheckSum": "ded2367fb925601c24347869b569579eb7c7048a3bf3a08f5aaf2b9dac6f1e7bdc90931e56dc63cca87b6ded12e62403840eb9ed0617e5a888fb25c33547266b",
            "createdTime": "2020-04-28 21:56:00.00",
            "applicationType": "UNKNOWN",
            "feature": null,
            "fileServiceId": "d15d786c-1357-43e8-ace0-f2619cebe23c",
            "imageSeries": null,
            "imageSource": "CCO",
            "imageSourceType": "Imported from CCO",
            "imageFamily": "NFVIS",
            "vendor": "CISCO",
            "filesize": 268274013,
            "deviceCount": 0,
            "deviceUuidList": [],
            "smuCount": 0,
            "imageAttributes": [
                {
                    "propertyName": "min_flash",
                    "propertyValue": "16384"
                },
                {
                    "propertyName": "image_guid",
                    "propertyValue": "2FCBCEB5E46F7293EE8E239AD3E4A374EEF98D81"
                },
                {
                    "propertyName": "release_fcs_date",
                    "propertyValue": "28/Mar/2020"
                },
                {
                    "propertyName": "image_size",
                    "propertyValue": "268274013"
                },
                {
                    "propertyName": "min_dram",
                    "propertyValue": "8192"
                },
                {
                    "propertyName": "image_description",
                    "propertyValue": "Cisco Industrial Ethernet 3x00 series Switches"
                },
                {
                    "propertyName": "metadata_trans_id",
                    "propertyValue": "153426088"
                },
                {
                    "propertyName": "udi",
                    "propertyValue": "PID: IE-3300-8P2S VID: V01, SN: FCW2146L02A"
                },
                {
                    "propertyName": "encryption_software_indicator",
                    "propertyValue": "Y"
                },
                {
                    "propertyName": "mdf_id",
                    "propertyValue": "286320755"
                }
            ],
            "isSMUApplicable": true,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": true,
            "reasonForNotSupportingImport": null,
            "tagList": [
                {
                    "taggedGolden": false,
                    "role": "ALL",
                    "inheritedSiteName": "",
                    "inheritedSiteUuid": ""
                },
                {
                    "taggedGolden": false,
                    "role": "CORE",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "DISTRIBUTION",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "BORDER ROUTER",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "UNKNOWN",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "ACCESS",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                }
            ],
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": "",
            "imageImported": true,
            "applicableDevicesForImage": [
                {
                    "mdfId": "286320755",
                    "productName": "Catalyst IE-3300-8P2S Rugged Switch",
                    "productId": [
                        "IE-3300-8P2S"
                    ]
                }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "ccoreverseSync": false,
            "ccorecommended": false,
            "ccolatest": true
        },
        {
            "name": "ie3x00-universalk9.16.12.02t.SPA.bin",
            "imageUuid": "c56af3d1-4576-4d1a-cced-343b51b3150c",
            "version": "16.12.2t",//Gibraltar-16.12.2t
            "softwareType": null,
            "imageType": "SYSTEM_SW",
            "isTaggedGolden": "false",
            "md5Checksum": "117c00c3d203fa840410310b1a10102f",
            "shaCheckSum": "ded2367fb925601c24347869b569579eb7c7048a3bf3a08f5aaf2b9dac6f1e7bdc90931e56dc63cca87b6ded12e62403840eb9ed0617e5a888fb25c33547266b",
            "createdTime": "2020-02-12 13:45:00.64",
            "applicationType": "UNKNOWN",
            "feature": null,
            "fileServiceId": "d15d786c-1357-43e8-ace0-f2619cebe23c",
            "imageSeries": null,
            "imageSource": "CCO",
            "imageSourceType": "Imported from CCO",
            "imageFamily": "CAT9K",
            "vendor": "CISCO",
            "filesize": 268274013,
            "deviceCount": 0,
            "deviceUuidList": [],
            "smuCount": 0,
            "imageAttributes": [
                {
                    "propertyName": "min_flash",
                    "propertyValue": "16384"
                },
                {
                    "propertyName": "image_guid",
                    "propertyValue": "2FCBCEB5E46F7293EE8E239AD3E4A374EEF98D81"
                },
                {
                    "propertyName": "release_fcs_date",
                    "propertyValue": "12/Feb/2020"
                },
                {
                    "propertyName": "image_size",
                    "propertyValue": "268274013"
                },
                {
                    "propertyName": "min_dram",
                    "propertyValue": "8192"
                },
                {
                    "propertyName": "image_description",
                    "propertyValue": "Cisco Industrial Ethernet 3x00 series Switches"
                },
                {
                    "propertyName": "metadata_trans_id",
                    "propertyValue": "153426088"
                },
                {
                    "propertyName": "udi",
                    "propertyValue": "PID: IE-3300-8P2S VID: V01, SN: FCW2146L02A"
                },
                {
                    "propertyName": "encryption_software_indicator",
                    "propertyValue": "Y"
                },
                {
                    "propertyName": "mdf_id",
                    "propertyValue": "286320755"
                }
            ],
            "isSMUApplicable": true,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": true,
            "reasonForNotSupportingImport": null,
            "tagList": [
                {
                    "taggedGolden": false,
                    "role": "ALL",
                    "inheritedSiteName": "",
                    "inheritedSiteUuid": ""
                },
                {
                    "taggedGolden": false,
                    "role": "CORE",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "DISTRIBUTION",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "BORDER ROUTER",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "UNKNOWN",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "ACCESS",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                }
            ],
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": "",
            "imageImported": true,
            "applicableDevicesForImage": [
                {
                    "mdfId": "286320755",
                    "productName": "Catalyst IE-3300-8P2S Rugged Switch",
                    "productId": [
                        "IE-3300-8P2S"
                    ]
                }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "ccoreverseSync": false,
            "ccorecommended": false,
            "ccolatest": true
        },
        {
            "name": "ie3x00-universalk9.17.01.01.SPA.bin",
            "imageUuid": "c56af3d1-4576-4d1a-bbed-343b51b3150c",
            "version": "17.1.1",//Amsterdam-17.1.1
            "softwareType": null,
            "imageType": "SYSTEM_SW",
            "isTaggedGolden": "false",
            "md5Checksum": "eb30ee1571697662944e3c9b7813c64e",
            "shaCheckSum": "fd90516438d3807bb41cad2cc64d6c00bd9e0c76e940688b09af3b3f9d63230816698af4c4d8349a5e388c19e2ca3e7bfc8b8fd85443f0efcd9ff1efd7563ee0",
            "createdTime": "22019-11-23 13:45:00.64",
            "applicationType": "UNKNOWN",
            "feature": null,
            "fileServiceId": "d15d786c-1357-43e8-ace0-f2619cebe23c",
            "imageSeries": null,
            "imageSource": "CCO",
            "imageSourceType": "Imported from CCO",
            "imageFamily": "CAT9K",
            "vendor": "CISCO",
            "filesize": 274785773,
            "deviceCount": 0,
            "deviceUuidList": [],
            "smuCount": 0,
            "imageAttributes": [
                {
                    "propertyName": "min_flash",
                    "propertyValue": "16384"
                },
                {
                    "propertyName": "image_guid",
                    "propertyValue": "2FCBCEB5E46F7293EE8E239AD3E4A374EEF98D81"
                },
                {
                    "propertyName": "release_fcs_date",
                    "propertyValue": "23/Nov/2019"
                },
                {
                    "propertyName": "image_size",
                    "propertyValue": "274785773"
                },
                {
                    "propertyName": "min_dram",
                    "propertyValue": "8192"
                },
                {
                    "propertyName": "image_description",
                    "propertyValue": "Cisco Industrial Ethernet 3x00 series Switches"
                },
                {
                    "propertyName": "metadata_trans_id",
                    "propertyValue": "153426088"
                },
                {
                    "propertyName": "udi",
                    "propertyValue": "PID: IE-3300-8P2S VID: V01, SN: FCW2146L02A"
                },
                {
                    "propertyName": "encryption_software_indicator",
                    "propertyValue": "Y"
                },
                {
                    "propertyName": "mdf_id",
                    "propertyValue": "286320755"
                }
            ],
            "isSMUApplicable": true,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": true,
            "reasonForNotSupportingImport": null,
            "tagList": [
                {
                    "taggedGolden": false,
                    "role": "ALL",
                    "inheritedSiteName": "",
                    "inheritedSiteUuid": ""
                },
                {
                    "taggedGolden": false,
                    "role": "CORE",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "DISTRIBUTION",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "BORDER ROUTER",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "UNKNOWN",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "ACCESS",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                }
            ],
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": "",
            "imageImported": true,
            "applicableDevicesForImage": [
                {
                    "mdfId": "286320755",
                    "productName": "Catalyst IE-3300-8P2S Rugged Switch",
                    "productId": [
                        "IE-3300-8P2S"
                    ]
                }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "ccoreverseSync": false,
            "ccorecommended": false,
            "ccolatest": true
        },
        {
            "name": "ie3x00-universalk9.16.12.01s.SPA.bin",
            "imageUuid": "c56af3d1-4576-4d1a-abfe-343b51b3150c",
            "version": "16.12.1s",//Gibraltar-16.12.1
            "softwareType": null,
            "imageType": "SYSTEM_SW",
            "isTaggedGolden": "false",
            "md5Checksum": "0a04628b109d0cdaf7d1d82c1e2d0191",
            "shaCheckSum": "32297d5249f2a4af819cc9480ec04ef0b4f76ecfc11a3c3049795f5407314db70f97bc5ec620a5dd0d2a9939af152589da8ad018b5c64cef892e1bd5cca7ba3d",
            "createdTime": "22019-09-06 13:45:00.64",
            "applicationType": "UNKNOWN",
            "feature": null,
            "fileServiceId": "d15d786c-1357-43e8-ace0-f2619cebe23c",
            "imageSeries": null,
            "imageSource": "CCO",
            "imageSourceType": "Imported from CCO",
            "imageFamily": "CAT9K",
            "vendor": "CISCO",
            "filesize": 265938065,
            "deviceCount": 0,
            "deviceUuidList": [],
            "smuCount": 0,
            "imageAttributes": [
                {
                    "propertyName": "min_flash",
                    "propertyValue": "16384"
                },
                {
                    "propertyName": "image_guid",
                    "propertyValue": "2FCBCEB5E46F7293EE8E239AD3E4A374EEF98D81"
                },
                {
                    "propertyName": "release_fcs_date",
                    "propertyValue": "01/Aug/2019"
                },
                {
                    "propertyName": "image_size",
                    "propertyValue": "265938065"
                },
                {
                    "propertyName": "min_dram",
                    "propertyValue": "8192"
                },
                {
                    "propertyName": "image_description",
                    "propertyValue": "Cisco Industrial Ethernet 3x00 series Switches"
                },
                {
                    "propertyName": "metadata_trans_id",
                    "propertyValue": "153426088"
                },
                {
                    "propertyName": "udi",
                    "propertyValue": "PID: IE-3300-8P2S VID: V01, SN: FCW2146L02A"
                },
                {
                    "propertyName": "encryption_software_indicator",
                    "propertyValue": "Y"
                },
                {
                    "propertyName": "mdf_id",
                    "propertyValue": "286320755"
                }
            ],
            "isSMUApplicable": true,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": true,
            "reasonForNotSupportingImport": null,
            "tagList": [
                {
                    "taggedGolden": false,
                    "role": "ALL",
                    "inheritedSiteName": "",
                    "inheritedSiteUuid": ""
                },
                {
                    "taggedGolden": false,
                    "role": "CORE",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "DISTRIBUTION",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "BORDER ROUTER",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "UNKNOWN",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                },
                {
                    "taggedGolden": false,
                    "role": "ACCESS",
                    "inheritedSiteName": null,
                    "inheritedSiteUuid": null
                }
            ],
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": "",
            "imageImported": true,
            "applicableDevicesForImage": [
                {
                    "mdfId": "286320755",
                    "productName": "Catalyst IE-3300-8P2S Rugged Switch",
                    "productId": [
                        "IE-3300-8P2S"
                    ]
                }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "ccoreverseSync": false,
            "ccorecommended": false,
            "ccolatest": true
        }
    ]
});
