define({
"Template": {
  "version": "1.0",
  "response": {
    "id": "AWYSgw5xdB8ZSOsITWi6",
    "name": "wlc_reboot_crash_trigger",
    "entityType": "network_device",
    "entity": "128.17.10.2",
    "enabled": true,
    "flattened": true,
    "groupBy": "Unknown",
    "category": "Availability",
    "severity": "HIGH",
    "summary": "WLC DeviceNamePlaceHolder has rebooted due to a hardware or software crash.",
    "scope": "GLOBAL",
    "priority": "P2",
    "groupId": "ap_down_grouping",
    "groupName": "WLC Reboot Crash",
    "rootCause": "Unknown",
    "timestamp": 1537908870505,
    "description": "This WLC DeviceNamePlaceHolder has rebooted due to a hardware or software crash.",
    "suggestions": [
      {
        "message": "Capture this WLC's crash log.",
        "steps": [

        ]
      },
      {
        "message": "Contact Cisco TAC World Wide – https://www.cisco.com/c/en/us/support/web/tsd-cisco-worldwide-contacts.html",
        "steps": [

        ]
      }
    ],
    "additionalParams": [
      {
        "key": "wlcName",
        "value": "namlab-5520"
      },
      {
        "key": "managementIpAddr",
        "value": "128.17.10.2"
      },
      {
        "key": "upUntilLastCrash",
        "value": "1"
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "__entity_type__",
        "value": "NetworkDevice"
      },
      {
        "key": "scope",
        "value": "global"
      },
      {
        "key": "entityId",
        "value": "128.17.10.2"
      },
      {
        "key": "type",
        "value": "Issue"
      },
      {
        "key": "priority",
        "value": "P1"
      },
      {
        "key": "category",
        "value": "Availability"
      },
      {
        "key": "timestring",
        "value": "2018-09-25T20:54:30.505+0000"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}
})