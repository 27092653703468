define({
    "Discovery_Template": {
        "discoveryType":"CDP",
        "discoveryStatus":"Inactive",
        "discoveryCondition":"Complete",
        "preferredMgmtIPMethod":"None",
        "ipAddressList":"10.41.54.172-10.41.54.176 10.41.54.187-10.41.54.190 10.0.255.31-10.0.255.33 10.201.80.68-10.201.80.72 10.201.80.83-10.201.80.83 10.32.168.29-10.32.168.31 10.30.200.3-10.30.200.5 10.85.0.21-10.85.0.23 10.225.117.130-10.225.117.132",
        "ipFilterList":"",
        "protocolOrder":"ssh",
        "cdpLevel":"16",
        "globalCredentialIdList":[],
        "timeout":"5",
        "retry":"3",
        "name":"DiscoveryAtAllSites",
        "deviceIds":{},
        "userNameList":"",
        "passwordList":"",
        "enablePasswordList":"",
        "snmpROCommunity":"",
        "snmpROCommunityDesc":"",
        "numDevices":"0",
        "isAutoCdp":false,
        "id":1
    },

    "Discovery_Response_Template":{
        "cliStatus":"SUCCESS",
        "hostname": "",
        "httpStatus":"NOT-PROVIDED",
        "id":"",
        "managementIpAddress":"",
        "netconfStatus":"NOT-PROVIDED",
        "pingStatus":"SUCCESS",
        "reachabilityStatus":"Success",
        "snmpStatus":"SUCCESS"
 }
});

