define({
  "Template": {
    request: {
      key: "ms-teams-audio",
      type: "Application",
      label: "ms-teams-audio",
      collection: false,
      componentContent: [
        {
          component: "fusion",
          additional: "5bad46a7-2cb5-4af3-a57f-ca5a74f40c88",
          origType: "Application",
        },
      ],
    },
    data: {
      response: {
        id: "5bad46a7-2cb5-4af3-a57f-ca5a74f40c88",
        instanceId: 5846,
        instanceCreatedOn: 1657037696506,
        instanceUpdatedOn: 1657037696506,
        instanceVersion: 0,
        createTime: 1657037684634,
        deployed: false,
        isSeeded: true,
        isStale: false,
        lastUpdateTime: 1657037684634,
        name: "ms-teams-audio",
        namespace: "scalablegroup:application",
        provisioningState: "DEFINED",
        qualifier: "application",
        resourceVersion: 0,
        targetIdList: [],
        type: "scalablegroup",
        cfsChangeInfo: [],
        customProvisions: [],
        isDeleted: false,
        iseReserved: false,
        mdnacDeletionStatus: "ACTIVE",
        propagateToAci: false,
        pushed: false,
        scalableGroupExternalHandle: "ms-teams-audio",
        scalableGroupType: "APPLICATION",
        securityGroupTag: 0,
        state: "ACTIVE",
        vnAgnostic: true,
        defaultContracts: [],
        identitySource: {
          id: "551bc1e2-bcde-46b3-9dff-9053ef4d272b",
          instanceId: 14014,
          instanceCreatedOn: 1657037668944,
          instanceUpdatedOn: 1657037668944,
          instanceVersion: 0,
          state: "INACTIVE",
          type: "NBAR",
          displayName: "14014",
        },
        indicativeNetworkIdentity: [],
        networkApplications: [
          {
            id: "57f81e7b-40c0-46e8-8eb3-67767b424a90",
            instanceId: 16796,
            instanceCreatedOn: 1657037696506,
            instanceUpdatedOn: 1657037696506,
            instanceVersion: 0,
            appProtocol: "tcp/udp",
            applicationGroup: "ms-cloud-group",
            applicationSubType: "NONE",
            applicationType: "DEFAULT",
            categoryId: "0adcf602-f1ac-4f02-b84c-25cc1aa528ed",
            createTime: 1657037684634,
            encrypted: "true",
            engineId: "13",
            helpString: "Microsoft Teams Conference Calls",
            ignoreConflict: false,
            lastUpdateTime: 1657037684634,
            longDescription:
              "Audio portion of Microsoft Teams' video chat or voice call",
            name: "ms-teams-audio",
            nbarId: "1214",
            p2pTechnology: "true",
            popularity: 7,
            rank: 65535,
            references:
              "https://products.office.com/en-us/microsoft-teams/group-chat-software",
            selectorId: "1214",
            serverNames: [],
            subCategory: "5aee80d3-0ef6-40c3-9c4b-9d2f46b904ea",
            trafficClass: "MULTIMEDIA_CONFERENCING",
            tunnel: "false",
            displayName: "16796",
          },
        ],
        networkIdentity: [
          {
            id: "7c6c8f7b-2f9e-4e78-be7a-6a379de7f6f6",
            instanceId: 19088,
            instanceCreatedOn: 1657037696506,
            instanceUpdatedOn: 1657037696506,
            instanceVersion: 0,
            ipv4Subnet: [],
            ipv6Subnet: [],
            lowerPort: 0,
            ports: "3478",
            protocol: "TCP",
            upperPort: 0,
            displayName: "19088",
          },
          {
            id: "fe6d38e9-d001-4ff9-92df-6a50d9eb0fb2",
            instanceId: 19087,
            instanceCreatedOn: 1657037696506,
            instanceUpdatedOn: 1657037696506,
            instanceVersion: 0,
            ipv4Subnet: [],
            ipv6Subnet: [],
            lowerPort: 0,
            ports: "3478",
            protocol: "UDP",
            upperPort: 0,
            displayName: "19087",
          },
        ],
        parentScalableGroup: {
          idRef: "579eb909-1c37-4515-abce-59b77638b6eb",
        },
        user: [],
        userGroup: [],
        displayName: "5846",
      },
    },
    result: [
      {
        bookID: {
          namespace: "fusion",
          name: "core",
          version: "1.0.1",
        },
        response: {
          id: "5bad46a7-2cb5-4af3-a57f-ca5a74f40c88",
          instanceId: 5846,
          instanceCreatedOn: 1657037696506,
          instanceUpdatedOn: 1657037696506,
          instanceVersion: 0,
          createTime: 1657037684634,
          deployed: false,
          isSeeded: true,
          isStale: false,
          lastUpdateTime: 1657037684634,
          name: "ms-teams-audio",
          namespace: "scalablegroup:application",
          provisioningState: "DEFINED",
          qualifier: "application",
          resourceVersion: 0,
          targetIdList: [],
          type: "scalablegroup",
          cfsChangeInfo: [],
          customProvisions: [],
          isDeleted: false,
          iseReserved: false,
          mdnacDeletionStatus: "ACTIVE",
          propagateToAci: false,
          pushed: false,
          scalableGroupExternalHandle: "ms-teams-audio",
          scalableGroupType: "APPLICATION",
          securityGroupTag: 0,
          state: "ACTIVE",
          vnAgnostic: true,
          defaultContracts: [],
          identitySource: {
            id: "551bc1e2-bcde-46b3-9dff-9053ef4d272b",
            instanceId: 14014,
            instanceCreatedOn: 1657037668944,
            instanceUpdatedOn: 1657037668944,
            instanceVersion: 0,
            state: "INACTIVE",
            type: "NBAR",
            displayName: "14014",
          },
          indicativeNetworkIdentity: [],
          networkApplications: [
            {
              id: "57f81e7b-40c0-46e8-8eb3-67767b424a90",
              instanceId: 16796,
              instanceCreatedOn: 1657037696506,
              instanceUpdatedOn: 1657037696506,
              instanceVersion: 0,
              appProtocol: "tcp/udp",
              applicationGroup: "ms-cloud-group",
              applicationSubType: "NONE",
              applicationType: "DEFAULT",
              categoryId: "0adcf602-f1ac-4f02-b84c-25cc1aa528ed",
              createTime: 1657037684634,
              encrypted: "true",
              engineId: "13",
              helpString: "Microsoft Teams Conference Calls",
              ignoreConflict: false,
              lastUpdateTime: 1657037684634,
              longDescription:
                "Audio portion of Microsoft Teams' video chat or voice call",
              name: "ms-teams-audio",
              nbarId: "1214",
              p2pTechnology: "true",
              popularity: 7,
              rank: 65535,
              references:
                "https://products.office.com/en-us/microsoft-teams/group-chat-software",
              selectorId: "1214",
              serverNames: [],
              subCategory: "5aee80d3-0ef6-40c3-9c4b-9d2f46b904ea",
              trafficClass: "MULTIMEDIA_CONFERENCING",
              tunnel: "false",
              displayName: "16796",
            },
          ],
          networkIdentity: [
            {
              id: "7c6c8f7b-2f9e-4e78-be7a-6a379de7f6f6",
              instanceId: 19088,
              instanceCreatedOn: 1657037696506,
              instanceUpdatedOn: 1657037696506,
              instanceVersion: 0,
              ipv4Subnet: [],
              ipv6Subnet: [],
              lowerPort: 0,
              ports: "3478",
              protocol: "TCP",
              upperPort: 0,
              displayName: "19088",
            },
            {
              id: "fe6d38e9-d001-4ff9-92df-6a50d9eb0fb2",
              instanceId: 19087,
              instanceCreatedOn: 1657037696506,
              instanceUpdatedOn: 1657037696506,
              instanceVersion: 0,
              ipv4Subnet: [],
              ipv6Subnet: [],
              lowerPort: 0,
              ports: "3478",
              protocol: "UDP",
              upperPort: 0,
              displayName: "19087",
            },
          ],
          parentScalableGroup: {
            idRef: "579eb909-1c37-4515-abce-59b77638b6eb",
          },
          user: [],
          userGroup: [],
          displayName: "5846",
        },
      },
    ],
  },
});
