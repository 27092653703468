define({
    "SiteProfile_Template": {
        "siteProfileUuid": "3d067067-b6fa-4612-b85b-ac40f559f759",
        "version": 1,
        "name": "NP-1",
        "namespace": "nfv",
        "status": "final",
        "lastUpdatedBy": "admin",
        "lastUpdatedDatetime": 1547620719528,
        "sites": []
    },

    'SiteTemplete': {
        "name": "SJC22",
        "uuid": "d1687e1f-8015-4c07-800f-4628a3b9879f",
        "isInherited": false
    }
});
