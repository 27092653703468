define({
  "Assurance_HostHealthDetails_Template":{
      'version': '1.0',
      'response':[
      {
        'siteId':"global",
        'scoreDetail':
        [{
            'scoreCategory':{'scoreCategory':"CLIENT_TYPE", 'value':"ALL"},
            'scoreValue':100,'clientCount':2,'clientUniqueCount':2,'starttime':1508472900000,'endtime':1508473200000,
            'scoreList':[]
        },
        {
            'scoreCategory':
              {'scoreCategory':"CLIENT_TYPE",'value':"NETWORKDEVICE"},
              'scoreValue': 100,'clientCount':2,'clientUniqueCount':2,'starttime':1508472900000,'endtime':1508473200000,
              'scoreList':[]
        }
        ]
        }
     ]
  }
});
