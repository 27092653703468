define({
    "Image_Template":
    {
        "groupUuid": "-1",
        "imageDetails":
        [
          {
            "deviceFamily": "Cisco Catalyst 36xx stack-able ethernet switch",
            "deviceTypeOrdinal":"999901008",
            "runningImageList": [
              {
                "applicationType": "null",
                "createdTime": "null",
                "deviceCount": "6",
                "deviceUuidList": ["b1dbe9ec-73d8-4aa1-9627-e97a3de07875","e3418434-df9b-4290-a0dd-24659f4d809e","f73c6c89-9367-48e3-b899-fdcfee60db87","2eddf8e2-fcf0-4fa6-be01-eccae68b46b5","9ff94f08-ae00-4512-9779-0a6541e34784","c801c6a5-1a0b-4411-b004-69cee54a16c5"],
                "feature": "null",
                "fileServiceId": "null",
                "goldenTagInheritedFromSiteName":"Global",
                "goldenTagInheritedFromSiteUuid":"-1",
                "imageAttributes": [],
                "imageFamily": "CAT3K_CAA",
                "imageSeries": "null",
                "imageSource": "null",
                "imageSourceType": "null",
                "imageType": "SYSTEM_SW",
                "imageUuid": "28718677-2180-48dd-b77c-d153fb64c2f1",
                "importSupported": "false",
                "isSMUApplicable": "false",
                "isTaggedGolden": "false",
                "md5Checksum": "null",
                "name": "Install Mode (16.5.20170425:052249)",
                "reasonForNotSupportingImport": "Import from devices in install mode is not supported.",
                "shaCheckSum": "null",
                "smuCount": "0",
                "softwareType": "null",
                "tagList": [{"taggedGolden": false, "role": "ALL", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "CORE", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "DISTRIBUTION", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "BORDER ROUTER", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "UNKNOWN", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "ACCESS", "inheritedSiteName": null, "inheritedSiteUuid": null}],
                "version": "16.5.2"
              },
              {
                "applicationType": "null",
                "createdTime": "null",
                "deviceCount": "2",
                "deviceUuidList": ["c911c7a5-1a0b-4411-b004-69ce154a16c6","b0dbe91c-73d8-4aa1-9627-e97a3de07873"],
                "feature": "null",
                "fileServiceId": "null",
                "goldenTagInheritedFromSiteName": "null",
                "goldenTagInheritedFromSiteUuid": "null",
                "imageAttributes": [],
                "imageFamily": "CAT3K_CAA",
                "imageSeries": "null",
                "imageSource": "null",
                "imageSourceType": "null",
                "imageType": "SYSTEM_SW",
                "imageUuid": "289dfd73-f389-4068-99cc-c20127cbf2ca",
                "importSupported": "false",
                "isSMUApplicable": "false",
                "isTaggedGolden": "false",
                "md5Checksum": "null",
                "name": "Install Mode (16.5.20170601:052330)",
                "reasonForNotSupportingImport": "Import from devices in install mode is not supported.",
                "shaCheckSum": "null",
                "smuCount": "0",
                "softwareType": "null",
                "tagList": [{"taggedGolden": false, "role": "ALL", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "CORE", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "DISTRIBUTION", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "BORDER ROUTER", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "UNKNOWN", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "ACCESS", "inheritedSiteName": null, "inheritedSiteUuid": null}],
                "version": "16.5.20170601:052330"
              },
              {
                "applicationType": "null",
                "createdTime": "2017-10-12 23:31:39.0",
                "deviceCount": "1",
                "deviceUuidList": ["c911c7a5-1a0b-4211-b004-69cee54a16d7"],
                "feature": "",
                "fileServiceId": "1b0a786f-81cd-4554-a972-23966b5f4bc7",
                "goldenTagInheritedFromSiteName": "Global",
                "goldenTagInheritedFromSiteUuid": "-1",
                "imageAttributes": [
                 {
                  "propertyName": "DEFAULT_BOOTROM",
                  "propertyValue": "0"
                 },
                 {
                 "propertyName": "DEFAULT_RAM",
                 "propertyValue": "0"
                 },
                 {
                 "propertyName": "MEDIA",
                  "propertyValue": ""
                  },
                 {
                 "propertyName": "FEATURE",
                  "propertyValue": ""
                 },
                 {"propertyName": "DEFAULT_MINFLASHSIZE",
                 "propertyValue": "0"
                 }
                 ],
                 "imageFamily": "CAT3K_CAA",
                 "imageSeries": ["Switches and Hubs/Cisco Catalyst 3850 Series Ethernet Stackable Switch","Switches and Hubs/Cisco Catalyst 3650 Series Switches"],
                 "imageSource": "ftp://dnac:cisco@172.20.50.50/cat3k_caa-universalk9.BLD_V166_THROTTLE_LATEST_20170926_091316.SSA.bin",
                 "imageSourceType":"null",
                 "imageType":"SYSTEM_SW",
                 "imageUuid":"f433f321-78ff-4484-a4cc-64a5de6dfb5e",
                 "importSupported": "true",
                 "isSMUApplicable": "false",
                 "isTaggedGolden": "false",
                 "md5Checksum": "954e2ce64dae07631f970770c8ff998d",
                 "name": "cat3k_caa-universalk9.BLD_V166_THROTTLE_LATEST_20170926_091316.SSA.bin",
                 "reasonForNotSupportingImport": "null",
                 "shaCheckSum": "834776084ce6ffb4047bc36e7f24b8c62f5191e8bb9819b451651f2f235c4aa051e1fbfa39c608aef1a3dcb95cfeca46c5a76ebeb4ba18e4fab893c14b0fde63",
                 "smuCount": "0",
                 "softwareType": "null",
                 "tagList": [{"taggedGolden": false, "role": "ALL", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "CORE", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "DISTRIBUTION", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "BORDER ROUTER", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "UNKNOWN", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "ACCESS", "inheritedSiteName": null, "inheritedSiteUuid": null}],
                 "version": "16.6.2"
              },
              {
                  "applicationType": "null",
                  "createdTime" : "null",
                  "deviceCount": "1",
                  "deviceUuidList": ["c911c7a5-1a0b-4411-b004-6998e54116c6"],
                  "feature": "null",
                  "fileServiceId":"null",
                  "goldenTagInheritedFromSiteName": "null",
                  "goldenTagInheritedFromSiteUuid":"null",
                  "imageAttributes":[],
                  "imageFamily":"CAT3K_CAA",
                  "imageSeries":"null",
                  "imageSource":"null",
                  "imageSourceType":"null",
                  "imageType":"SYSTEM_SW",
                  "imageUuid":"725503cc-9f21-4f51-ba26-643bb17147b8",
                  "importSupported":"true",
                  "isSMUApplicable":"false",
                  "isTaggedGolden":"false",
                  "md5Checksum":"null",
                  "name": "cat3k_caa-universalk9.16.06.02s.SPA.bin",
                  "reasonForNotSupportingImport": "null",
                  "shaCheckSum":"null",
                  "smuCount":"0",
                  "softwareType":"null",
                  "tagList": [{"taggedGolden": false, "role": "ALL", "inheritedSiteName": null, "inheritedSiteUuid": null}],
                           "version":"16.5.20170425:052249",
               },
               {
                  "applicationType": null,
                  "ccorecommended": false,
                  "createdTime": null,
                  "deviceCount": 1,
                  "deviceUuidList": ["e49777b2-3b73-423b-9529-8faf17946b2e"],
                  "feature": null,
                  "fileServiceId": null,
                  "goldenTagInheritedFromSiteName": null,
                  "goldenTagInheritedFromSiteUuid": null,
                  "imageAttributes": [],
                  "imageCategory": "PHYSICAL",
                  "imageFamily": "CAT3K_CAA",
                  "imageSeries": null,
                  "imageSource": null,
                  "imageSourceType": null,
                  "imageType": "RELEASE",
                  "imageUuid": "0e2cde5f-0f1a-4a75-b3e2-d820c0d2d1151",
                  "importSupported": true,
                  "isSMUApplicable": false,
                  "isTaggedGolden": "false",
                  "md5Checksum": null,
                  "name": "cat3k_caa-universalk9.16.06.02.SPA.bin",
                  "reasonForNotSupportingImport": null,
                  "shaCheckSum": null,
                  "smuCount": 0,
                  "softwareType": null,
                  "tagList": [{"taggedGolden": false, "role": "ALL", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "CORE", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "DISTRIBUTION", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "BORDER ROUTER", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "UNKNOWN", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "ACCESS", "inheritedSiteName": null, "inheritedSiteUuid": null}],
                  "vendor": null,
                  "version": "16.6.2"
               },
            ],
            "softwareType":"IOS-XE"
           },
           {
            "deviceFamily": "Cisco Catalyst38xx stack-able ethernet switch",
            "deviceTypeOrdinal": "999919705",
            "runningImageList": [
              {
                "applicationType": "null",
                "createdTime": "null",
                "deviceCount": "6",
                "deviceUuidList": ["b1dbe9ec-73d8-4aa1-9627-e87a3de07874","e3418434-df9b-4290-a0da-24659f4d809e","f74c6c89-9367-48e3-b899-fdcfee60db87","4eddf8e2-fcf0-4fa6-be01-eccae68b46b5","9ff94f09-ae00-4512-9779-0a6541e34784","c801c7a5-1a0b-4411-b004-69cee54a16c5"],
                "feature": "null",
                "fileServiceId": "null",
                "goldenTagInheritedFromSiteName":"Global",
                "goldenTagInheritedFromSiteUuid":"-1",
                "imageAttributes": [],
                "imageFamily": "CAT3K_CAA",
                "imageSeries": "null",
                "imageSource": "null",
                "imageSourceType": "null",
                "imageType": "SYSTEM_SW",
                "imageUuid": "28718677-2180-48dd-b77c-d153fb64c2f1",
                "importSupported": "false",
                "isSMUApplicable": "false",
                "isTaggedGolden": "false",
                "md5Checksum": "null",
                "name": "Install Mode (16.5.20170425:052249)",
                "reasonForNotSupportingImport": "Import from devices in install mode is not supported.",
                "shaCheckSum": "null",
                "smuCount": "0",
                "softwareType": "null",
                "tagList": [{"taggedGolden": false, "role": "ALL", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "CORE", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "DISTRIBUTION", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "BORDER ROUTER", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "UNKNOWN", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "ACCESS", "inheritedSiteName": null, "inheritedSiteUuid": null}],
                "version": "16.5.2"
              },
              {
                "applicationType": "null",
                "createdTime": "null",
                "deviceCount": "2",
                "deviceUuidList": ["c911c7a5-1a0b-4411-b004-69cee54a16c6","b0dbe9ec-73d8-4aa1-9627-e97a3de07873"],
                "feature": "null",
                "fileServiceId": "null",
                "goldenTagInheritedFromSiteName": "null",
                "goldenTagInheritedFromSiteUuid": "null",
                "imageAttributes": [],
                "imageFamily": "CAT3K_CAA",
                "imageSeries": "null",
                "imageSource": "null",
                "imageSourceType": "null",
                "imageType": "SYSTEM_SW",
                "imageUuid": "289dfd73-f389-4068-99cc-c20127cbf2ca",
                "importSupported": "false",
                "isSMUApplicable": "false",
                "isTaggedGolden": "false",
                "md5Checksum": "null",
                "name": "Install Mode (16.5.20170601:052330)",
                "reasonForNotSupportingImport": "Import from devices in install mode is not supported.",
                "shaCheckSum": "null",
                "smuCount": "0",
                "softwareType": "null",
                "tagList": [{"taggedGolden": false, "role": "ALL", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "CORE", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "DISTRIBUTION", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "BORDER ROUTER", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "UNKNOWN", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "ACCESS", "inheritedSiteName": null, "inheritedSiteUuid": null}],
                "version": "16.5.20170601:052330"
              },
              {
                "applicationType": "null",
                "createdTime": "2017-10-12 23:31:39.0",
                "deviceCount": "1",
                "deviceUuidList": ["b0dbe9ec-73d8-4aa1-9627-e97a3de07855"],
                "feature": "",
                "fileServiceId": "1b0a786f-81cd-4554-a972-23966b5f4bc7",
                "goldenTagInheritedFromSiteName": "Global",
                "goldenTagInheritedFromSiteUuid": "-1",
                "imageAttributes": [
                 {
                  "propertyName": "DEFAULT_BOOTROM",
                  "propertyValue": "0"
                 },
                 {
                 "propertyName": "DEFAULT_RAM",
                 "propertyValue": "0"
                 },
                 {
                 "propertyName": "MEDIA",
                  "propertyValue": ""
                  },
                 {
                 "propertyName": "FEATURE",
                  "propertyValue": ""
                 },
                 {"propertyName": "DEFAULT_MINFLASHSIZE",
                 "propertyValue": "0"
                 }
                 ],
                 "imageFamily": "CAT3K_CAA",
                 "imageSeries": ["Switches and Hubs/Cisco Catalyst 3850 Series Ethernet Stackable Switch","Switches and Hubs/Cisco Catalyst 3650 Series Switches"],
                 "imageSource": "ftp://dnac:cisco@172.20.50.50/cat3k_caa-universalk9.BLD_V166_THROTTLE_LATEST_20170926_091316.SSA.bin",
                 "imageSourceType":"null",
                 "imageType":"SYSTEM_SW",
                 "imageUuid":"f433f321-78ff-4484-a4cc-64a5de6dfb5e",
                 "importSupported": "true",
                 "isSMUApplicable": "false",
                 "isTaggedGolden": "false",
                 "md5Checksum": "954e2ce64dae07631f970770c8ff998d",
                 "name": "cat3k_caa-universalk9.BLD_V166_THROTTLE_LATEST_20170926_091316.SSA.bin",
                 "reasonForNotSupportingImport": "null",
                 "shaCheckSum": "834776084ce6ffb4047bc36e7f24b8c62f5191e8bb9819b451651f2f235c4aa051e1fbfa39c608aef1a3dcb95cfeca46c5a76ebeb4ba18e4fab893c14b0fde63",
                 "smuCount": "0",
                 "softwareType": "null",
                 "tagList": [{"taggedGolden": false, "role": "ALL", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "CORE", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "DISTRIBUTION", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "BORDER ROUTER", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "UNKNOWN", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "ACCESS", "inheritedSiteName": null, "inheritedSiteUuid": null}],
                 "version": "16.6.2"
              },
              {
                  "applicationType": "null",
                  "createdTime" : "null",
                  "deviceCount": "1",
                  "deviceUuidList": ["b0dbe9ec-73d8-4aa1-9627-e97a3de07899"],
                  "feature": "null",
                  "fileServiceId":"null",
                  "goldenTagInheritedFromSiteName": "null",
                  "goldenTagInheritedFromSiteUuid":"null",
                  "imageAttributes":[],
                  "imageFamily":"CAT3K_CAA",
                  "imageSeries":"null",
                  "imageSource":"null",
                  "imageSourceType":"null",
                  "imageType":"SYSTEM_SW",
                  "imageUuid":"725503cc-9f21-4f51-ba26-643bb17147b8",
                  "importSupported":"true",
                  "isSMUApplicable":"false",
                  "isTaggedGolden":"false",
                  "md5Checksum":"null",
                  "name": "cat3k_caa-universalk9.16.06.02s.SPA.bin",
                  "reasonForNotSupportingImport": "null",
                  "shaCheckSum":"null",
                  "smuCount":"0",
                  "softwareType":"null",
                  "tagList": [{"taggedGolden": false, "role": "ALL", "inheritedSiteName": null, "inheritedSiteUuid": null}],
                           "version":"16.5.20170425:052249",
               },
               {
                  "applicationType": null,
                  "ccorecommended": false,
                  "createdTime": null,
                  "deviceCount": 1,
                  "deviceUuidList": ["1b8be8f2-c338-46ff-9a92-3b6917a3c3bd"],
                  "feature": null,
                  "fileServiceId": null,
                  "goldenTagInheritedFromSiteName": null,
                  "goldenTagInheritedFromSiteUuid": null,
                  "imageAttributes": [],
                  "imageCategory": "PHYSICAL",
                  "imageFamily": "CAT3K_CAA",
                  "imageSeries": null,
                  "imageSource": null,
                  "imageSourceType": null,
                  "imageType": "RELEASE",
                  "imageUuid": "0e2cde5f-0f1a-4a75-b3e2-d820c0d2d151",
                  "importSupported": true,
                  "isSMUApplicable": false,
                  "isTaggedGolden": "false",
                  "md5Checksum": null,
                  "name": "cat3k_caa-universalk9.16.06.02.SPA.bin",
                  "reasonForNotSupportingImport": null,
                  "shaCheckSum": null,
                  "smuCount": 0,
                  "softwareType": null,
                  "tagList": [{"taggedGolden": false, "role": "ALL", "inheritedSiteName": null, "inheritedSiteUuid": null}],
                  "vendor": null,
                  "version": "16.6.2",
               },
            ],
            "softwareType":"IOS-XE"
           },
           {
           "deviceFamily": "Cisco 5520 Series Wireless Controllers",
           "deviceTypeOrdinal": "286284588",
           "runningImageList": [
           {
           "applicationType": null,
           "ccorecommended": false,
           "createdTime": null,
           "deviceCount": 1,
           "deviceUuidList": ["e1d45937-58ed-4383-9745-7b783728c9e3"],
           "feature": null,
           "fileServiceId": null,
           "goldenTagInheritedFromSiteName": null,
           "goldenTagInheritedFromSiteUuid": null,
           "imageAttributes": [],
           "imageCategory": "PHYSICAL",
           "imageFamily": "AIR-CT5520",
           "imageSeries": null,
           "imageSource": null,
           "imageSourceType": null,
           "imageType": "WLC_RELEASE",
           "imageUuid": "1a8b201c-facb-4c80-ba40-1e11e8551d7b",
           "importSupported": false,
           "isSMUApplicable": false,
           "isTaggedGolden": "false",
           "md5Checksum": null,
           "name": "Wireless Controller (8.5.103.0)",
           "reasonForNotSupportingImport": "Import from Cisco 5520 Series Wireless Controllers is not supported.",
           "shaCheckSum": null,
           "smuCount": 0,
           "softwareType": null,
           "tagList": [{"taggedGolden": false, "role": "ALL", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "CORE", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "DISTRIBUTION", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "BORDER ROUTER", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "UNKNOWN", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "ACCESS", "inheritedSiteName": null, "inheritedSiteUuid": null}],
           "vendor": null,
           "version": "8.5.103.0"
           },
           {
           "applicationType": null,
           "ccorecommended": false,
           "createdTime": null,
           "deviceCount": 1,
           "deviceUuidList": ["00aabb9b-0954-44bb-8fcd-cea943af6fa3"],
           "feature": null,
           "fileServiceId": null,
           "goldenTagInheritedFromSiteName": null,
           "goldenTagInheritedFromSiteUuid": null,
           "imageAttributes": [],
           "imageCategory": "PHYSICAL",
           "imageFamily": "AIR-CT5520",
           "imageSeries": null,
           "imageSource": null,
           "imageSourceType": null,
           "imageType": "WLC_RELEASE",
           "imageUuid": "68c961d2-c4df-41ee-9060-71f33d114045",
           "importSupported": false,
           "isSMUApplicable": false,
           "isTaggedGolden": "false",
           "md5Checksum": null,
           "name": "Wireless Controller (8.5.107.71)",
           "reasonForNotSupportingImport": "Import from Cisco 5520 Series Wireless Controllers is not supported.",
           "shaCheckSum": null,
           "smuCount": 0,
           "softwareType": null,
           "tagList": [{"taggedGolden": false, "role": "ALL", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "CORE", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "DISTRIBUTION", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "BORDER ROUTER", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "UNKNOWN", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "ACCESS", "inheritedSiteName": null, "inheritedSiteUuid": null}],
           "vendor": null,
           "version": "8.5.107.71"
           }],
           "softwareType": "Cisco Controller"
           },
           {
            "deviceFamily":"Cisco 891,891W Integrated Services Router G2",
            "deviceTypeOrdinal":"999990982",
            "runningImageList":
             [
               {
               "applicationType":"null",
               "createdTime":"null",
               "deviceCount":"1",
               "deviceUuidList":["885ce28b-9260-4e35-b468-22221b62b779"],
               "feature":"null",
               "fileServiceId":"null",
               "goldenTagInheritedFromSiteName":"null",
               "goldenTagInheritedFromSiteUuid":"null",
               "imageAttributes":[],
               "imageFamily":"C890",
               "imageSeries":"null",
               "imageSource":"null",
               "imageSourceType":"null",
               "imageType":"RELEASE",
               "imageUuid":"2630a629-8c89-4232-8ad6-81432553d571",
               "importSupported":"true",
               "isSMUApplicable":"false",
               "isTaggedGolden":"false",
               "md5Checksum":"null",
               "name":"c890-universalk9-mz.151-4.M2.bin",
               "reasonForNotSupportingImport":"null",
               "shaCheckSum":"null",
               "smuCount":"0",
               "softwareType":"null",
               "tagList": [{"taggedGolden": false, "role": "ALL", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "CORE", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "DISTRIBUTION", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "BORDER ROUTER", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "UNKNOWN", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "ACCESS", "inheritedSiteName": null, "inheritedSiteUuid": null}],

               "version":"15.1(4)M2"
               }
             ],
             "softwareType":"IOS"
           },
           {
             "deviceFamily":"Cisco Catalyst 29xx Stack-able Ethernet Switch",
             "deviceTypeOrdinal":"279963399",
             "runningImageList":[
              {
                "applicationType":"null",
                "createdTime":"null",
                "deviceCount":"1",
                "deviceUuidList":["b0dc1697-87b9-41d6-83e3-39479208f9c5"],
                "feature":"null",
                "fileServiceId":"null",
                "goldenTagInheritedFromSiteName":"null",
                "goldenTagInheritedFromSiteUuid":"null",
                "imageAttributes":[],
                "imageFamily":"C2960X",
                "imageSeries":"null",
                "imageSource":"null",
                "imageSourceType":"null",
                "imageType":"RELEASE",
                "imageUuid":"53c707a9-f999-4331-89c4-64c990e09b67",
                "importSupported":"true",
                "isSMUApplicable":"false",
                "isTaggedGolden":"false",
                "md5Checksum":"null",
                "name":"c2960x-universalk9-mz.152-5.E2.bin",
                "reasonForNotSupportingImport":"null",
                "shaCheckSum":"null",
                "smuCount":"0",
                "softwareType":"null",
                "tagList": [{"taggedGolden": false, "role": "ALL", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "CORE", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "DISTRIBUTION", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "BORDER ROUTER", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "UNKNOWN", "inheritedSiteName": null, "inheritedSiteUuid": null},
                            {"taggedGolden": false, "role": "ACCESS", "inheritedSiteName": null, "inheritedSiteUuid": null}],
                "version":"15.2(5)E2"
                }
               ],
             "softwareType":"IOS"
            },
         ],
        "version": "1.0"
     }
    }
);
