//NFVIS - Cisco 5400 Enterprise Network Compute System
define({
    "runningImageList":[
      {
        "name": "Cisco_NFVIS-4.9.1-FC3.iso",
        "imageUuid": "02ffdaed-40ef-2222-1111-5095ef27951e",
        "version": "4.9.1",
        "softwareType": null,
        "imageType": "RELEASE",
        "isTaggedGolden": "true",
        "md5Checksum": "2a8a86b13b22a22314b9023e426285b7",
        "shaCheckSum": "65e5d5bf6dadc4830877ee83c1a7bc57fcc844ad7d07ae9f5b0b93c6edb7b8984a33d5a6516b0fa6c94631999770f9e2fe780562e2195c0ead22211a7f0db1a4",
        "createdTime": "2022-08-18 13:33:00.00",
        "applicationType": null,
        "feature": null,
        "fileServiceId": "abcd8765-4321-ef01-aa32-f2619cebe23c",
        "imageSeries": null,
        "imageSource": "CCO",
        "imageSourceType": "Imported from CCO",
        "imageFamily": "NFVIS",
        "vendor": null,
        "filesize": 1399830528,
        "deviceCount": 0,
        "deviceUuidList": [],
        "smuCount": 0,
        "imageAttributes": [],
        "isSMUApplicable": false,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": true,
        "reasonForNotSupportingImport": null,
        "tagList": [
          {
            "taggedGolden": true,
            "role": "ALL",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "CORE",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "DISTRIBUTION",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "BORDER ROUTER",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "ACCESS",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": null,
        "imageImported": true,
        "applicableDevicesForImage": null,
        "ccorecommended": false,
        "ccolatest": true
    },{
        "name": "Cisco_NFVIS_BRANCH-3.12.2-FC2.iso",
        "imageUuid": "02ffdaed-40ef-404d-1111-5095ef27951e",
        "version": "3.12.2",
        "softwareType": null,
        "imageType": "RELEASE",
        "isTaggedGolden": "false",
        "md5Checksum": "2a8a86b13b22a22314b9023e426285b7",
        "shaCheckSum": "65e5d5bf6dadc4830877ee83c1a7bc57fcc844ad7d07ae9f5b0b93c6edb7b8984a33d5a6516b0fa6c94631999770f9e2fe780562e2195c0ead22211a7f0db1a4",
        "createdTime": "2019-09-06 13:33:00.00",
        "applicationType": null,
        "feature": null,
        "fileServiceId": "abcd8765-4321-ef01-aa32-f2619cebe23c",
        "imageSeries": null,
        "imageSource": "CCO",
        "imageSourceType": "Imported from CCO",
        "imageFamily": "NFVIS",
        "vendor": null,
        "filesize": 1399830528,
        "deviceCount": 0,
        "deviceUuidList": [],
        "smuCount": 0,
        "imageAttributes": [],
        "isSMUApplicable": false,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": true,
        "reasonForNotSupportingImport": null,
        "tagList": [
          {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "CORE",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "DISTRIBUTION",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "BORDER ROUTER",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "UNKNOWN",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "ACCESS",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": null,
        "imageImported": true,
        "applicableDevicesForImage": null,
        "ccorecommended": false,
        "ccolatest": true
    },
    {
            "name": "Cisco NFV Infrastructure Software-3.9.1-FC1",
            "imageUuid": "02ffdaed-40ef-404d-8f30-5095ef27951e",
            "version": "3.9.1-FC1",
            "softwareType": null,
            "imageType": "RELEASE",
            "isTaggedGolden": "false",
            "md5Checksum": null,
            "shaCheckSum": null,
            "createdTime": null,
            "applicationType": null,
            "feature": null,
            "fileServiceId": null,
            "imageSeries": null,
            "imageSource": null,
            "imageSourceType": null,
            "imageFamily": "NFVIS",
            "vendor": null,
            "filesize": 0,
            "deviceCount": 0,
            "deviceUuidList": [],
            "smuCount": 0,
            "imageAttributes": [],
            "isSMUApplicable": false,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": false,
            "reasonForNotSupportingImport": "Import from NFVIS is not supported. Please import from url or local file.",
            "tagList": [
              {
                "taggedGolden": false,
                "role": "ALL",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "CORE",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "DISTRIBUTION",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "BORDER ROUTER",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "UNKNOWN",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "ACCESS",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": null,
            "imageImported": false,
            "applicableDevicesForImage": null,
            "ccorecommended": false,
            "ccolatest": false
        }
    ]
});