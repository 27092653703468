define({
    "Taskresponse": [
        {
           "taskUuid":"2f72e920-1310-438a-ba01-3c57ce34fd97",
           "deviceTaskUuid":"",
           "unitTaskUuid":"2f72e920-1310-438a-ba01-3c57ce34fd97",
           "taskType":"import",
           "startTime":"1525129722852",
           "completionTime":"1525129873507",
           "taskStatus":"SUCCESS",
           "operation":"",
           "deviceId":"",
           "imageName":"cat9k_iosxe.16.10.01e.SPA.bin",
           "logDetails":""
        },
        {
           "taskUuid":"63e34edc-7144-4887-aa93-f666a5e38b81",
           "deviceTaskUuid":"",
           "taskType":"delete-image-repo",
           "startTime":"1524720414518",
           "completionTime":"1524720415033",
           "taskStatus":"SUCCESS",
           "operation":"",
           "deviceId":"",
           "imageName":"AIR-CT5520-K9-8-7-102-0.aes",
           "logDetails":""
        },
        {
           "taskUuid":"0d371f5d-a024-4ded-b947-7023ce14a84e",
           "deviceTaskUuid":"",
           "unitTaskUuid":"0d371f5d-a024-4ded-b947-7023ce14a84e",
           "taskType":"import",
           "startTime":"1524719905543",
           "completionTime":"1524719969024",
           "taskStatus":"SUCCESS",
           "operation":"",
           "deviceId":"",
           "imageName":"AIR-CT5520-K9-8-7-102-0.aes",
           "logDetails":""
        }
    ]
});
