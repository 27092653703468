define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/SimDataGenerationInit',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/GuestPolicyTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimDNAWirelessData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/provision/WLCMobilityTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/udn/UpnSupportedDevicesTemplate'
], function (SimLokiDatabaseActions, SimTaskDataGenerator, SimDataGenerationInit, UtilityFunctions,
    GuestPolicyTemplate, SimDNAWirelessData, WLCMobilityTemplate, UpnSupportedDevicesTemplate ) {

        return {
            init: function () {
            },

            doProcessRestRequest: function (urlAction) {
                var data;
                switch (urlAction.method) {
                    case 'GET':
                        data = processGetRequest(urlAction);
                        break;

                    case 'POST':
                        data = processPostRequest(urlAction);
                        break;

                    case 'PUT':
                        data = processPutRequest(urlAction);
                        break;

                    case 'DELETE':
                        data = processDeleteRequest(urlAction);
                        break;


                    default:
                        break;
                }

                return data;
            }
        };


        function processGetRequest(urlAction) {
            var id;
            if (urlAction.action.id == undefined || urlAction.action.id == "")
                id = "1";
            else
                id = urlAction.action.id;
            if (urlAction.service.indexOf("policy") >= 0) {
                var recordObj = SimLokiDatabaseActions.getAll('guest-policy');
                return JSON.parse(JSON.stringify(recordObj));
            }

            if (urlAction.service.indexOf("portal") >= 0 && id != undefined) {
                var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('guest-portal', { 'id': id });
                return recordObj[0];
            }

            if (urlAction.service.indexOf("dna-wireless-service") > -1 && urlAction.service.indexOf("getMobility") > -1) {
                var Json = JSON.parse(JSON.stringify(WLCMobilityTemplate.Template))
                return Json
            }

            if (urlAction.service.indexOf("dna-wireless-service") >= 0 && urlAction.service.indexOf("getMobilityDetailsForProvision") >= 0) {
                var Json = JSON.parse(JSON.stringify(WLCMobilityTemplate.Template))
                return Json
            }

            if (urlAction.service.indexOf("dna-wireless-service") >= 0 && urlAction.service.indexOf("getVirtualIP") >= 0) {
                var Json = { "virtualIP": "" }
                return Json
            }

            if (urlAction.service.indexOf("dna-wireless-service") >= 0 && urlAction.service.indexOf("getMobilityGroupNames") >= 0) {
                var Json = []
                return Json
            }

            if (urlAction.service.indexOf("dna-wireless-service") >= 0 && urlAction.service.indexOf("getWirelessManagementIP") >= 0) {
                var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', { 'networkDeviceId': id });
                if (recordObj.length != 0) {
                    recordObj = { "wirelessManagementIP": recordObj[0].managementIpAddress }
                }
                return recordObj
            }
            if (urlAction.service.indexOf("dna-wireless-service") > -1 && urlAction.service.indexOf("getSitesWithUpnSupportedDevices") > -1) {
                let devices = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceConfigStatus',{'$and': [
                                    { 'family': 'Wireless Controller' },
									{'type':'Cisco Catalyst 9800-40 Wireless Controller'},
									{ 'aggregatedStatus':'Success' } ]});
				let totalRec = [];
				//for(let k=0; k<devices.length; k++){
				    let deviceSiteInfo = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo',{'networkDeviceId': devices[0].deviceId });
				    if(deviceSiteInfo.length>0){
				     let siteRec = [];
				     let tJson = JSON.parse(JSON.stringify(UpnSupportedDevicesTemplate.Template));
				     tJson.upn[0].deviceId = devices[0].deviceId;
                     tJson.upn[0].deviceName = devices[0].hostname;
                     for(let i=0; i<deviceSiteInfo[0].managedSites.length; i++){
                        let siteInfo = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'id': deviceSiteInfo[0].managedSites[i] });
                        let obj = {"siteHierarchy": "", "siteId": ""};
                        obj.siteHierarchy = siteInfo[0].groupNameHierarchy;
                        obj.siteId = siteInfo[0].id;
                        siteRec.push(obj);
                     }
                     tJson.upn[0].sites = siteRec;
                     totalRec.push(tJson.upn[0])
				    }
				//}
                return {"upn":totalRec};
            }
            if(urlAction.service.indexOf("dna-wireless-service") > -1 && urlAction.filter["isUpnEnabled"] == "true"){

                let udnInfo = SimLokiDatabaseActions.getAll('udn-data');
                if(udnInfo.length>0){
                    let obj = [{"policyProfileName": "udn-psk_Global_NF_f8695253","siteIds": [],"networkDeviceId": "","isUnicastFilteringEnabled": false,
                    "isUpnCreatedOnSite": true,"ssidList": [],"instanceTenantId": "5efd081080e40d00c8147f7a","instanceUuid": null,"id": null}];
                    let siteNames = [];
                    for(let i=0; i<udnInfo[0].upnConfig.structuredSSIDinfo.length; i++){
                        obj[0].networkDeviceId = udnInfo[0].upnConfig.structuredSSIDinfo[i].devices[0].deviceId;
                        obj[0].ssidList = udnInfo[0].upnConfig.structuredSSIDinfo[i].selectedWlans
                       siteNames.push(udnInfo[0].upnConfig.structuredSSIDinfo[i].siteName);
                    }
                    obj[0].siteIds = siteNames;
                    return obj;
                }else return [];

            }
            if(urlAction.service.indexOf("dna-wireless-service") > -1 && urlAction.service.indexOf("rolling-ap-status") > -1){
                return []
            }
            if(urlAction.service.indexOf("dna-wireless-service") > -1 && urlAction.service.indexOf("udnCloudDetails") > -1){
                return {"info":{"host":"udn.cisco.com/","region":"us-west-2"}}
            }

            if(urlAction.service.indexOf("dna-wireless-service") > -1 && urlAction.service.indexOf("getNATInventoryDetailsByDeviceId") > -1){
                return {
                    "managedNetworkElement": {
                        "type": "ManagedNetworkElement",
                        "id": 69927859,
                        "longType": "com.cisco.xmp.model.foundation.encapsulatedFunctionality.ManagedNetworkElement",
                        "url": "../../ManagedNetworkElement/69927859"
                    },
                    "natIPEnable": false,
                    "managedNetworkElementUrl": "../../WLCProperty/70079013/related/managedNetworkElement",
                    "deployPending": "NONE",
                    "publicAddress": {
                        "address": "0.0.0.0"
                    },
                    "description": null,
                    "name": null,
                    "instanceTenantId": "5f35ea5f15de5300c6a0162a",
                    "instanceUuid": "97eee9d6-a503-4390-932f-7a6738549d7e",
                    "id": "97eee9d6-a503-4390-932f-7a6738549d7e"
                }
            }

            if(urlAction.service.indexOf("dna-wireless-service") > -1 && urlAction.service.indexOf("getRemoteWorkerSites") > -1){
                return []
            }
            if(urlAction.service.indexOf("dna-wireless-service") > -1 && urlAction.service.indexOf("getMeshEnabledSites") > -1){
                return []
            }
            if(urlAction.service.indexOf("dna-wireless-service") > -1 && urlAction.service.indexOf("getAllWLCDetail") > -1){
                var wlcDevices = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {"family": "Wireless Controller"});
                var response = []
                wlcDevices.map(device => {
                    var resObj = {
                        "deviceIp": device.managementIpAddress,
                        "deviceName": device.hostname,
                        "wirelessMgmtIP": device.managementIpAddress
                    }
                    response.push(JSON.parse(JSON.stringify(resObj)))
                })
                return response;
            }
            if(urlAction.service.indexOf("dna-wireless-service") > -1 && urlAction.service.indexOf("getAllAnchorManagedSites") > -1){
                return []
            }
            if(urlAction.service.indexOf("dna-wireless-service") > -1 && urlAction.service.indexOf("getPreProvisionGroupSettingsSites") > -1){
                return {"SITETAG": [],"APGROUP": [],"FLEXGROUP": [],"POLICYTAG": []}
            }          
            

            var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', { 'networkDeviceId': id });
            console.log(recordObj);
            return recordObj;
        }

        function processPostRequest(urlAction) {
            if (urlAction.service.indexOf('DeviceInfo') >= 0) {
                var resultObj = SimTaskDataGenerator.createTask('SPFService');
                if (urlAction.restPayload.length > 0 && urlAction.restPayload[0].networkDeviceId != undefined
                    && urlAction.restPayload[0].networkDeviceId != '') {
                    var restJson = urlAction.restPayload[0];
                    //Do this post call to create an entry in deviceinfo and create wlan
                    SimDataGenerationInit.cfsCUDoperation('DeviceInfo', 'post', restJson);
                }
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });
                return resultObj;
            }
            if (urlAction.service.indexOf("portal") >= 0) {
                return SimDNAWirelessData.createPortal(urlAction);
            }

            if (urlAction.service.indexOf("policy") >= 0) {
                return {"response":SimDNAWirelessData.createPolicy(urlAction)};
            }
        }

        function processPutRequest(urlAction) {
            if (urlAction.service.indexOf('DeviceInfo') >= 0) {
                var resultObj = SimTaskDataGenerator.createTask('SPFService');
                if (urlAction.restPayload.length > 0 && urlAction.restPayload[0].networkDeviceId != undefined
                    && urlAction.restPayload[0].networkDeviceId != '') {
                    var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', { 'networkDeviceId': urlAction.restPayload[0].networkDeviceId });
                    if (recordObj != undefined && recordObj.length > 0) {
                        var restJson = urlAction.restPayload[0];
                        SimDataGenerationInit.cfsCUDoperation('DeviceInfo', 'put', restJson);
                    }
                }
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });
                return resultObj;
            }
            if (urlAction.service.indexOf("portal") >= 0) {
                return SimDNAWirelessData.updatePortal(urlAction);
            }
        }

        function processDeleteRequest(urlAction) {
            return SimDNAWirelessData.deletePortal(urlAction);
        }

    });
