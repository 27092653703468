define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/application_metric/ApplicationDelay',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/application_metric/ApplicationLatency',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/application_metric/ApplicationExperienceGraph',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/application_metric/ApplicationAvgScore',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/application_metric/skypeDelay'

 ], function(UtilityFunctions, ApplicationDelay, ApplicationLatency, ApplicationExperienceGraph, ApplicationAvgScore,SimLokiDatabaseActions,skypeDelay) {

 return {

      init: function(){
      },
      getLatencyMetric: function(urlAction) {
          //return ApplicationLatency.Template;
          return generateData(urlAction, ApplicationLatency.Template);
      },
       getDelayMetric: function(urlAction) {
          //return ApplicationDelay.Template;
          return generateData(urlAction, ApplicationDelay.Template)
      },
      getMetricAggregation: function(urlAction) {
          //return ApplicationExperienceGraph.Template;
          return generateData(urlAction, ApplicationExperienceGraph.Template);
      },
      getApplicationAvgScore: function(urlAction) {
          //return ApplicationAvgScore.Template;
          return generateDataAvgScore(urlAction, ApplicationAvgScore.Template);
      },
      getSkypeDetails: function (urlAction) {
            var tObjs = skypeDelay.Template;
            return { "response": tObjs };
      },
      getSummaryData : function (urlAction) {
        var summary = [{
                             "destIp": "192.168.101.105",
                             "start": "1544431860000",
                             "end": "1512731280417",
                             "mosAvg": 0,
                             "jitterAvg": 0.243652645432874,
                             "maxJitter": null,
                             "latencyAvg": 2.7554347826086953,
                             "maxLatency": 9,
                             "packetLossAvg": 0,
                             "maxPacketLoss": 0
                         }];
        return { "response": summary };
   },
      getApplicationInterfaces : function(urlAction) {
        var obj = {},interfacename = [];
        var exporterIp = urlAction.filter.query.split(")")[6].split(",")[1].trim().replace(/'/g,"");
        var device = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'managementIpAddress': exporterIp })
        var interfaceRec = SimLokiDatabaseActions.getFilteredRecordHandler('device-interface',{'deviceId': device[0].id});
        var interfacesUp = interfaceRec.filter(rec => rec.status =="up");
        var interfaceName = interfacesUp[0].portName;
        obj[interfaceName] = [117];
        interfacename.push(obj);
        return interfacename
      }
  };

  function generateData(urlAction, data) {
      var i = 0, timeWindow = urlAction.filter['metricTimeWindow'];
      var tquery = urlAction.filter['query'];
          tquery = tquery.replace(/%27/g, '');
      var query=tquery.substr(tquery.indexOf('applicationName'),tquery.length)
      var applicationName=query.substring(query.indexOf(',')+2,query.indexOf(')')-1)
      applicationName = ["ms-teams-audio","ms-teams-video", "ms-teams-app-sharing"].indexOf(applicationName) != -1 ? "microsoft-teams" : applicationName
     var appData = SimLokiDatabaseActions.getFilteredRecordHandler('application', { 'applicationName': applicationName });
      query=tquery.substr(tquery.indexOf('clientMacAddress'),tquery.length);
      var clientMac=query.substring(query.indexOf(',')+2,query.indexOf(')')-1);
      var clientData = SimLokiDatabaseActions.getFilteredRecordHandler('host', { 'id': clientMac });
      var userId = clientData.length>0 ? clientData[0].userId : '';
      var currTime = UtilityFunctions.getTimeStamp();

     var hours = UtilityFunctions.getHours(urlAction.filter["startTime"], urlAction.filter["endTime"]);
     //Math.floor as application latency issues showing hours= 24.17
       hours = Math.floor(hours)
     if(hours > 24){
      timeWindow = 60;
     }
     else{
        timeWindow = 5;
     }

     data = JSON.parse(JSON.stringify(data));

      var recordLen = data.length;
      var incrementBy = 0, tOrder = 'asc';

       var averagePacketLoss =0;
       var avgNetworklatentcy =0;
       var avgApplicationDelay= 0;
       var averageClientNetworkDelay = 0;
       var averageServerNetworkDelay = 0 ;
       var averageJitter = 0;
       var usage = 1000;
       var splHandling = false;

      var startDate = UtilityFunctions.getAdjustStartDateForDataGeneration(urlAction.filter['startTime'],urlAction.filter['endTime'], tOrder, timeWindow);
      for(; i < recordLen; i++) {
          var tTime = UtilityFunctions.incrementDateByMinutes(startDate, incrementBy);
          var tTimeInMs = new Date(tTime).getTime();
          data[i].modificationtime[0] = tTimeInMs;
          incrementBy += timeWindow;

          /*if(data[i].latency_avg != undefined) {
              if(data[i].latency_avg[0] < 3.8) {
                  var  t = 3.87 - data[i].latency_avg[0];
                  var x = (data[i].latency_avg[0] / 3.87);
                  //if(t < 0) {
                  //    t = t * -1;
                  //}
                  data[i].latency_avg[0] += (t + x);
              } else {
                  var  t = data[i].latency_avg[0] - 3.87;
                  var x = (data[i].latency_avg[0] / 3.87);
                  data[i].latency_avg[0] += (t + x);
              }
          }*/
          /*if(data[i].latency_avg != undefined) {
              var t = 6 - data[i].latency_avg[0];
              if(t < 0) {
                  t = t * -1;
              }
              var x = t / data[i].latency_avg[0];
              data[i].latency_avg[0] += t + x;
          }*/

          if(appData.length>0) {

            if(/* userId == 'Grace.Smith' */applicationName=='webex-meeting') {
                if( 
                    (tTimeInMs>currTime-(165*60*1000) && tTimeInMs<currTime-(105*60*1000)) ||
                    (tTimeInMs>currTime-(220*60*1000) && tTimeInMs<currTime-(190*60*1000)) ||
                    (tTimeInMs>currTime-(240*60*1000) && tTimeInMs<currTime-(225*60*1000))
                ) {
                    averagePacketLoss = UtilityFunctions.getRandomIntForRangeValues(appData[0].averagePacketLoss, appData[0].maxPacketLoss);
                    avgNetworklatentcy = UtilityFunctions.getRandomIntForRangeValues(appData[0].averageLatency, appData[0].maxLatency);
                    avgApplicationDelay = UtilityFunctions.getRandomIntForRangeValues(appData[0].averageApplicationDelay, appData[0].maxApplicationDelay);
                    averageClientNetworkDelay = UtilityFunctions.getRandomIntForRangeValues(appData[0].averageClientNetworkDelay, appData[0].maxClientNetworkDelay);
                    averageServerNetworkDelay = UtilityFunctions.getRandomIntForRangeValues(appData[0].averageServerNetworkDelay, appData[0].maxServerNetworkDelay);
                    averageJitter = UtilityFunctions.getRandomIntForRangeValues(appData[0].averageJitter, appData[0].maxJitter);
                    usage = UtilityFunctions.getRandomIntForRangeValues(appData[0].usage-(appData[0].usage*40/100), appData[0].usage);
                    splHandling = false;
                } else {
                    /* averagePacketLoss = undefined;
                    avgNetworklatentcy = undefined;
                    avgApplicationDelay = undefined;
                    averageClientNetworkDelay = undefined;
                    averageServerNetworkDelay = undefined;
                    averageJitter = undefined;
                    usage = undefined; */
                    splHandling = true;
                }
            } else {
              if(i%50==0) {

                   averagePacketLoss = UtilityFunctions.getRandomIntForRangeValues(appData[0].averagePacketLoss, appData[0].maxPacketLoss);
                   avgNetworklatentcy = UtilityFunctions.getRandomIntForRangeValues(appData[0].averageLatency, appData[0].maxLatency);
                   avgApplicationDelay = UtilityFunctions.getRandomIntForRangeValues(appData[0].averageApplicationDelay, appData[0].maxApplicationDelay);
                   averageClientNetworkDelay = UtilityFunctions.getRandomIntForRangeValues(appData[0].averageClientNetworkDelay, appData[0].maxClientNetworkDelay);
                   averageServerNetworkDelay = UtilityFunctions.getRandomIntForRangeValues(appData[0].averageServerNetworkDelay, appData[0].maxServerNetworkDelay);
                   averageJitter = UtilityFunctions.getRandomIntForRangeValues(appData[0].averageJitter, appData[0].averageJitter);
                   usage = UtilityFunctions.getRandomIntForRangeValues(appData[0].usage-(appData[0].usage*40/100), appData[0].usage);
              } else {
                   averagePacketLoss =appData[0].averagePacketLoss;
                   avgNetworklatentcy =appData[0].averageLatency;
                   avgApplicationDelay= appData[0].averageApplicationDelay;
                   averageClientNetworkDelay = appData[0].averageClientNetworkDelay;
                   averageServerNetworkDelay = appData[0].averageServerNetworkDelay;
                   averageJitter = appData[0].averageJitter;
                   usage = UtilityFunctions.getRandomIntForRangeValues(appData[0].usage-(appData[0].usage*15/100), appData[0].usage);
              }
              }

              data[i].rate_avg[0] =appData[0].usage*8/(86400*1000*1000);

              if(data[i].latency_avg != undefined &&(applicationName=="webex-meeting"||applicationName=="disney-web-portal"||applicationName=="ms-office-365" || applicationName=="MedicalRecords")) {
              //   data[i].latency_avg[0] += 2.25;
                //data[i].latency_avg[0] +=  (appData[0].maxLatency-appData[0].averageLatency)/100;
               // data[i].latency_avg[0] +=  (appData[0].maxLatency-appData[0].averageLatency);
                data[i].latency_avg[0] +=  (appData[0].averageLatency);
              } else {
                data[i].latency_avg[0] = avgNetworklatentcy;
              }
              if(data[i].packetLoss_avg != undefined) {
                    data[i].packetLoss_avg[0] = averagePacketLoss;
              }
              if(data[i].applicationDelay_avg != undefined) {
                    data[i].applicationDelay_avg[0] = avgApplicationDelay;
              }
              if(data[i].clientNetworkDelay_avg != undefined) {
                    data[i].clientNetworkDelay_avg[0] = averageClientNetworkDelay;
              }
              if(data[i].serverNetworkDelay_avg != undefined) {
                    data[i].serverNetworkDelay_avg[0] = averageServerNetworkDelay;
              }
              if(data[i].jitter_avg == undefined || averageJitter != undefined){
                  data[i].jitter_avg = [averageJitter];
              }
              if(data[i].totalBits_avg == undefined){
                  data[i].totalBits_avg = [usage];
              }
              if(splHandling) { 
                data[i].rate_avg[0] = 0;
                data[i].packetLoss_avg = null;
                data[i].applicationDelay_avg = null;
                data[i].clientNetworkDelay_avg = null;
                data[i].serverNetworkDelay_avg = null;
                data[i].jitter_avg = null;
                data[i].totalBits_avg = null;
                data[i].latency_avg = null;
              }

          }

      }
      return data;
  }

  function generateDataAvgScore(urlAction, metricData) {

        var i = 0, timeWindow = urlAction.filter['metricTimeWindow'];
        //var recordLen = data.length;
        var incrementBy = 0, tOrder = 'asc';
        var hours = UtilityFunctions.getHours(urlAction.filter["startTime"],urlAction.filter["endTime"]);
        var appName = urlAction.filter['appName'];
        var data =[];
        if(hours>24) {
            timeWindow =60;
         }
        var recordLen = hours*60/timeWindow;
        var templateLength = metricData.length;

         var startDate = UtilityFunctions.getAdjustStartDateForDataGeneration(urlAction.filter['startTime'],urlAction.filter['endTime'], tOrder, timeWindow);
        for(; i < recordLen; i++) {
            var record = {};
            if(i<templateLength){
              record = metricData[i];
            } else {
              record = metricData[templateLength-1];
            }
            var tTime = UtilityFunctions.incrementDateByMinutes(startDate, incrementBy);
            var tTimeInMs = new Date(tTime).getTime();
            record.modificationtime[0] = tTimeInMs;
            record.applicationName[0] = appName;
            data.push(record);
            incrementBy += timeWindow;
        }

        return data;
    }


});
