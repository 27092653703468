define({
  "Template":
 [
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336258E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336261E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336264E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336267E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.533627E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336273E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336276E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336279E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336282E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336285E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.4
    ],
    "modificationtime": [
      1.5336288E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336291E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336294E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336297E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.53363E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.6
    ],
    "modificationtime": [
      1.5336303E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.6
    ],
    "modificationtime": [
      1.5336306E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336309E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336312E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.4
    ],
    "modificationtime": [
      1.5336315E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336318E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336321E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.4
    ],
    "modificationtime": [
      1.5336324E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336327E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.533633E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336333E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336336E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336339E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.4
    ],
    "modificationtime": [
      1.5336342E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336345E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336348E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336351E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336354E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336357E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.533636E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336363E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.4
    ],
    "modificationtime": [
      1.5336366E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336369E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336372E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336375E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.4
    ],
    "modificationtime": [
      1.5336378E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.6
    ],
    "modificationtime": [
      1.5336381E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336384E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336387E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.533639E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336393E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336396E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336399E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.4
    ],
    "modificationtime": [
      1.5336402E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336405E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336408E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336411E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336414E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336417E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.533642E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.6
    ],
    "modificationtime": [
      1.5336423E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336426E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336429E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336432E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336435E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336438E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336441E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336444E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336447E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.6
    ],
    "modificationtime": [
      1.533645E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336453E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336456E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.6
    ],
    "modificationtime": [
      1.5336459E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336462E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336465E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336468E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336471E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.4
    ],
    "modificationtime": [
      1.5336474E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      1.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336477E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.533648E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0013901866666666666
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336483E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336486E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336489E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.4
    ],
    "modificationtime": [
      1.5336492E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336495E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336498E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336501E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.6
    ],
    "modificationtime": [
      1.5336504E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.6
    ],
    "modificationtime": [
      1.5336507E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.533651E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336513E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336516E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.4
    ],
    "modificationtime": [
      1.5336519E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336522E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.4
    ],
    "modificationtime": [
      1.5336525E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336528E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.6
    ],
    "modificationtime": [
      1.5336531E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336534E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336537E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.533654E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336543E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336546E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336549E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336552E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336555E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336558E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336561E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336564E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336567E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.6
    ],
    "modificationtime": [
      1.533657E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336573E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336576E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336579E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336582E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336585E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.4
    ],
    "modificationtime": [
      1.5336588E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336591E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336594E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336597E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.53366E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336603E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.6
    ],
    "modificationtime": [
      1.5336606E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336609E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336612E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.4
    ],
    "modificationtime": [
      1.5336615E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336618E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336621E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.4
    ],
    "modificationtime": [
      1.5336624E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336627E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.533663E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336633E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336636E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336639E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336642E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336645E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336648E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336651E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336654E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336657E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.533666E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336663E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336666E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.4
    ],
    "modificationtime": [
      1.5336669E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336672E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.4
    ],
    "modificationtime": [
      1.5336675E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336678E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336681E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336684E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336687E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.533669E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336693E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336696E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336699E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336702E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336705E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336708E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336711E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.4
    ],
    "modificationtime": [
      1.5336714E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336717E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.533672E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.6
    ],
    "modificationtime": [
      1.5336723E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336726E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336729E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.6
    ],
    "modificationtime": [
      1.5336732E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336735E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336738E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336741E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336744E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.6
    ],
    "modificationtime": [
      1.5336747E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.16779328
    ],
    "applicationDelay_avg": [
      28.166666666666668
    ],
    "modificationtime": [
      1.533675E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.1666666666666665
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336753E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.4
    ],
    "modificationtime": [
      1.5336756E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336759E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336762E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336765E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336768E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336771E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336774E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.6
    ],
    "modificationtime": [
      1.5336777E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.533678E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336783E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336786E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336789E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336792E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336795E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.4
    ],
    "modificationtime": [
      1.5336798E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336801E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336804E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336807E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.533681E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.4
    ],
    "modificationtime": [
      1.5336813E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.6
    ],
    "modificationtime": [
      1.5336816E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.4
    ],
    "modificationtime": [
      1.5336819E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.6
    ],
    "modificationtime": [
      1.5336822E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336825E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.6
    ],
    "modificationtime": [
      1.5336828E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.6
    ],
    "modificationtime": [
      1.5336831E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336834E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.4
    ],
    "modificationtime": [
      1.5336837E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.4
    ],
    "modificationtime": [
      1.533684E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336843E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336846E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336849E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336852E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336855E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336858E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336861E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336864E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336867E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.533687E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336873E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.6
    ],
    "modificationtime": [
      1.5336876E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.4
    ],
    "modificationtime": [
      1.5336879E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336882E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336885E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336888E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.4
    ],
    "modificationtime": [
      1.5336891E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.4
    ],
    "modificationtime": [
      1.5336894E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.6
    ],
    "modificationtime": [
      1.5336897E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.53369E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.6
    ],
    "modificationtime": [
      1.5336903E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336906E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336909E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336912E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336915E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336918E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336921E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336924E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336927E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.533693E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336933E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336936E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336939E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336942E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.6
    ],
    "modificationtime": [
      1.5336945E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336948E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336951E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.6
    ],
    "modificationtime": [
      1.5336954E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336957E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.533696E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336963E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336966E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336969E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.6
    ],
    "modificationtime": [
      1.5336972E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336975E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336978E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336981E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5336984E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336987E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.533699E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336993E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5336996E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5336999E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5337002E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.6
    ],
    "modificationtime": [
      1.5337005E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5337008E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.6
    ],
    "modificationtime": [
      1.5337011E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5337014E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017363466666666667
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5337017E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.533702E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5337023E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5337026E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5337029E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5337032E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.4
    ],
    "modificationtime": [
      1.5337035E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5337038E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5337041E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.4
    ],
    "modificationtime": [
      1.5337044E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5337047E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.6
    ],
    "modificationtime": [
      1.533705E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.6
    ],
    "modificationtime": [
      1.5337053E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5337056E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.6
    ],
    "modificationtime": [
      1.5337059E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5337062E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5337065E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.4
    ],
    "modificationtime": [
      1.5337068E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5337071E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5337074E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5337077E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.533708E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.4
    ],
    "modificationtime": [
      1.5337083E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.2
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5337086E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5337089E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5337092E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5337095E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5337098E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5337101E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5337104E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5337107E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.533711E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      0.8
    ],
    "modificationtime": [
      1.5337113E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.4
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5337116E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.6
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5337119E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.4
    ],
    "modificationtime": [
      1.5337122E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.8
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.2
    ],
    "modificationtime": [
      1.5337125E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      2.0
    ]
  },
  {
    "packetLoss_avg": [
      0.0
    ],
    "rate_avg": [
      0.0017377333333333334
    ],
    "applicationDelay_avg": [
      1.0
    ],
    "modificationtime": [
      1.5337128E12
    ],
    "clientNetworkDelay_avg": [
      0.0
    ],
    "serverNetworkDelay_avg": [
      0.0
    ],
    "latency_avg": [
      3.0
    ]
  }
]
});
