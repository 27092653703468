define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalysis/KpiAAA',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalysis/KpiASSOC',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalysis/KpiClientCount_Frequency',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalysis/KpiClientCount_SSID',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalysis/KpiDHCP',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalysis/KpiRoaming',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalysis/KpiRoamingFast',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalysis/KpiRoamingSlow',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalysis/KpiRoamingClientCount',    
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalysis/KpiOnboarding',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalysis/KpiRssi',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalysis/KpiDataRate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalysis/CombinedDNS',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalysis/KpiLink',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalysis/KpiSnr',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalysis/TimestampAAA',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalysis/TimestampASSOC',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalysis/TimestampClientCount_Frequency',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalysis/TimestampClientCount_SSID',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalysis/TimestampDHCP',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalysis/TimestampFrequency',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalysis/TimestampOnboarding',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalysis/TimestampRssi',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalysis/TimestampSnr',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalysis/TimestampLink',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalysis/TimestampRoaming',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalysis/TimestampDataRate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalysis/NshealthanalysisAAA',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/healthanalysis/NshealthanalysisDHCP',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/SimulationUtility'
], function (SimLokiDatabaseActions, KpiAAA, KpiASSOC, KpiClientCount_Frequency, KpiClientCount_SSID, KpiDHCP, KpiRoaming, KpiRoamingFast, KpiRoamingSlow,
    KpiRoamingClientCount, KpiOnboarding, KpiRssi, KpiDataRate, CombinedDNS, KpiLink, KpiSnr,TimestampAAA, TimestampASSOC, TimestampClientCount_Frequency, 
    TimestampClientCount_SSID, TimestampDHCP, TimestampFrequency, TimestampOnboarding, TimestampRssi, TimestampSnr, TimestampLink, TimestampRoaming,TimestampDataRate,
    NshealthanalysisAAA, NshealthanalysisDHCP, UtilityFunctions, SimulationUtility) {

        return {
            init: function () {
            },

            getNsHealthAnalysis: function (urlAction) {
                var hosts=SimLokiDatabaseActions.getAll('host');
                if (urlAction.restPayload.kpi == 'AAA') {
                    var aaaAllIps=hosts.map(function( obj ) {
                        return obj.onboarding.aaaServerIp});
                    var aaaIps = [...new Set(aaaAllIps)];
                    var detailList=aaaIps.map(function(obj){ return {  "serverIp": obj,
                                        "avgDuration": 0,
                                        "maxDuration": 0,
                                        "totalAttemptCount": 0,
                                        "totalFailureCount": 0,
                                        "totalClientCount": 0,
                                        "totalNonOnboardedClientCount": 0,
                                        "totalOnboardedClientCount": 0}})
                    
                    for(var i=0;i<hosts.length;i++){
                        for(var j=0;j<aaaIps.length;j++){
                            if(hosts[i].onboarding.aaaServerIp===aaaIps[j]){
                                detailList[j]["totalClientCount"]+=1;
                                if(detailList[j]["maxDuration"]<hosts[i].onboarding.maxAuthDuration){
                                    detailList[j]["maxDuration"]=hosts[i].onboarding.maxAuthDuration;  
                                }
                                detailList[j]["avgDuration"]=hosts[i].onboarding.averageAuthDuration;  
                                if(hosts[i].healthScore[0].score<3){
                                    detailList[j]["totalFailureCount"]+=1;
                                }
                                else{
                                    detailList[j]["totalAttemptCount"]+=1;
                                }
                                if(hosts[i].onboarding.maxRunDuration===0){
                                    detailList[j]["totalNonOnboardedClientCount"]+=1;
                                }
                                else{
                                    detailList[j]["totalOnboardedClientCount"]+=1;
                                }
                            }
                        }
                    }
                    for(var j=0;j<aaaIps.length;j++){
                        detailList[j]["avgDuration"]=detailList[j]["avgDuration"]/detailList[j]["totalClientCount"];
                    }

                    return  {"version":"1.0","response":{"detailList":detailList}};
                }
                else if (urlAction.restPayload.kpi == 'DHCP') {
                       var dhcpAllIps=hosts.map(function( obj ) {
                            return obj.onboarding.dhcpServerIp});
                        var dhcpIps = [...new Set(dhcpAllIps)];
                        var detailList=dhcpIps.map(function(obj){ return {  "serverIp": obj,
                                            "avgDuration": 0,
                                            "maxDuration": 0,
                                            "totalAttemptCount": 0,
                                            "totalFailureCount": 0,
                                            "totalClientCount": 0,
                                            "totalNonOnboardedClientCount": 0,
                                            "totalOnboardedClientCount": 0}})
                        
                        for(var i=0;i<hosts.length;i++){
                            for(var j=0;j<dhcpIps.length;j++){
                                if(hosts[i].onboarding.dhcpServerIp===dhcpIps[j]){
                                    detailList[j]["totalClientCount"]+=1;
                                    if(detailList[j]["maxDuration"]<hosts[i].onboarding.maxDhcpDuration){
                                        detailList[j]["maxDuration"]=hosts[i].onboarding.maxDhcpDuration;  
                                    }
                                    detailList[j]["avgDuration"]=hosts[i].onboarding.averageDhcpDuration;  
                                    if(hosts[i].healthScore[0].score<3){
                                        detailList[j]["totalFailureCount"]+=1;
                                    }
                                    else{
                                        detailList[j]["totalAttemptCount"]+=1;
                                    }
                                    if(hosts[i].onboarding.maxRunDuration===0){
                                        detailList[j]["totalNonOnboardedClientCount"]+=1;
                                    }
                                    else{
                                        detailList[j]["totalOnboardedClientCount"]+=1;
                                    }
                                }
                            }
                        }
                        for(var j=0;j<dhcpIps.length;j++){
                            detailList[j]["avgDuration"]=detailList[j]["avgDuration"]/detailList[j]["totalClientCount"];
                        }
    
                        return  {"version":"1.0","response":{"detailList":detailList}};

                   // return NshealthanalysisDHCP.Template;
                }
            },

            getHealthAnalysis: function (urlAction) {
                var hosts=[];
                var siteId = 'global';
                if(urlAction.restPayload.typeList.typeIdList && urlAction.restPayload.typeList.typeIdList.length>0) {
                    siteId = SimulationUtility.validSiteId(urlAction.restPayload.typeList.typeIdList[0]);
                }

                if(siteId == 'global') {
                    if (urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.frequency != undefined && urlAction.restPayload.typeList.filters.frequency != "") {
                        var frequency = urlAction.restPayload.typeList.filters.frequency;
                        if(urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.ssid != undefined && urlAction.restPayload.typeList.filters.ssid.length > 0){
                            ssids=urlAction.restPayload.typeList.filters.ssid;
                            hosts = SimLokiDatabaseActions.getFilteredRecordHandler('host', { 'frequency': frequency });
                            hosts=filterClientWithSSID(ssids,hosts);
                           }
                        else{
                            hosts = SimLokiDatabaseActions.getFilteredRecordHandler('host', { 'frequency': frequency });
                        }
                    }
                    else {
                        if(urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.ssid != undefined && urlAction.restPayload.typeList.filters.ssid.length > 0){
                            ssids=urlAction.restPayload.typeList.filters.ssid;
                            hosts = SimLokiDatabaseActions.getAll('host');
                             hosts=filterClientWithSSID(ssids,hosts);
                        }
                        else{
                            hosts = SimLokiDatabaseActions.getAll('host');
                        }
                    }
                }
                else {
                    var hostsList=[];
                    var siteObj = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'id': siteId });
                    var siteLocation = siteObj[0].groupNameHierarchy;
                    if (urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.frequency != undefined && urlAction.restPayload.typeList.filters.frequency != "") {
                        var frequency = urlAction.restPayload.typeList.filters.frequency;
                         hostsList = SimLokiDatabaseActions.getFilteredRecordHandler('host', { 'frequency': frequency });
                        if(urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.ssid != undefined && urlAction.restPayload.typeList.filters.ssid.length > 0){
                           ssids=urlAction.restPayload.typeList.filters.ssid;
                            hostsList=filterClientWithSSID(ssids,hostsList);
                        }
                    }
                    else {
                        if(urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.ssid != undefined && urlAction.restPayload.typeList.filters.ssid.length > 0){
                            ssids=urlAction.restPayload.typeList.filters.ssid;
                          hostsList = SimLokiDatabaseActions.getAll('host');
                          hostsList=filterClientWithSSID(ssids,hostsList);
                        }
                        else{
                            hostsList = SimLokiDatabaseActions.getAll('host');
                        }
                    }
                    var hosts = [];
                    for (var i = 0; i < hostsList.length; i++) {
                        var hostLocation = hostsList[i].location;
                        if (hostLocation.indexOf(siteLocation) == 0) {
                            hosts.push(hostsList[i]);
                        }
                    }
                }

                //var wiredHosts = SimLokiDatabaseActions.getFilteredRecordHandler("host", { 'hostType': 'WIRED' });
                var wiredHosts = hosts.filter(function (itm) { return itm.hostType == 'WIRED' });
                var wirelessHosts = hosts.filter(function (itm) { return itm.hostType == 'WIRELESS'; });
                var wiredObjDetails = {}, wirelessObjDetails = {};

                for (var i = 0; i < wiredHosts.length; i++) {
                    wiredObjDetails["poorObjs"] = wiredHosts.filter(function (itm) {
                        return parseInt(itm.healthScore[0].score) >= 1 &&
                            parseInt(itm.healthScore[0].score) <= 3;
                    });
                    wiredObjDetails["fairObjs"] = wiredHosts.filter(function (itm) {
                        return parseInt(itm.healthScore[0].score) >= 4 &&
                            parseInt(itm.healthScore[0].score) <= 7;
                    });
                    wiredObjDetails["goodObjs"] = wiredHosts.filter(function (itm) {
                        return parseInt(itm.healthScore[0].score) >= 8 &&
                            parseInt(itm.healthScore[0].score) <= 10;
                    });
                    wiredObjDetails["idleObjs"] = wiredHosts.filter(function (itm) { return parseInt(itm.healthScore[0].score) == 0; });
                }
                for (var i = 0; i < wirelessHosts.length; i++) {
                    wirelessObjDetails["poorObjs"] = wirelessHosts.filter(function (itm) {
                        return parseInt(itm.healthScore[0].score) >= 1 &&
                            parseInt(itm.healthScore[0].score) <= 3;
                    });
                    wirelessObjDetails["fairObjs"] = wirelessHosts.filter(function (itm) {
                        return parseInt(itm.healthScore[0].score) >= 4 &&
                            parseInt(itm.healthScore[0].score) <= 7;
                    });
                    wirelessObjDetails["goodObjs"] = wirelessHosts.filter(function (itm) {
                        return parseInt(itm.healthScore[0].score) >= 8 &&
                            parseInt(itm.healthScore[0].score) <= 10;
                    });
                    wirelessObjDetails["idleObjs"] = wirelessHosts.filter(function (itm) { return parseInt(itm.healthScore[0].score) == 0; });
                }

                var wiredObjs = wiredHosts.filter(function (itm) { return itm.healthScore[0].score > 0; });
                var wirelessObjs = wirelessHosts.filter(function (itm) { return itm.healthScore[0].score > 0; });
                hosts = hosts.filter(function (itm) { return itm.healthScore[0].score > 0; });

                var hostsList = SimLokiDatabaseActions.getFilteredRecordHandler('host', { 'hostType': "WIRELESS" });;
                var tempWireless=250; var tempWired=56; var allSsids=[];
                for (var i = 0; i < hostsList.length; i++) {
                    if(hostsList[i].ssid!=undefined || hostsList[i].ssid!=null)
                    allSsids.push(hostsList[i].ssid);
                }

                var ssids = [...new Set(allSsids)];

                if(urlAction.restPayload.typeList.filters!=undefined && urlAction.restPayload.typeList.filters.frequency!=undefined && urlAction.restPayload.typeList.filters.frequency !=""){
                    var frequency=urlAction.restPayload.typeList.filters.frequency;
                    tempWired=0;
                    if(frequency==="2.4"){
                        hostsList=hostsList.filter(function(itm){return itm.frequency==="2.4"})
                       tempWireless=34;
                    }
                    else if(frequency==="5.0"){
                        hostsList=hostsList.filter(function(itm){return itm.frequency==="5.0" || itm.frequency==="5"})
                        tempWireless=216;
                    }
                }

                if(urlAction.restPayload.typeList.filters!=undefined && urlAction.restPayload.typeList.filters.ssid!=undefined && urlAction.restPayload.typeList.filters.ssid.length >0){
                    tempWired=0;
                    var s = [], totalsum = 0, index = -1, count=0;
                    for(var k=0;k<ssids.length;k++){s[k]=0};
                    for(var j=0;j<hostsList.length;j++){
                        for(var k=0;k<ssids.length;k++){
                            if(hostsList[j].ssid===ssids[k]){
                                s[k]++;
                            }
                        }
                    }

                    for (var i = 0; i < ssids.length; i++) {
                        if (ssids[i] === "c9800AP11AX") {
                            index = i;
                            continue;
                        }
                        else{
                        s[i] += Math.round(tempWireless * 0.06);
                        totalsum += s[i];
                        }


                    }
                    if (index >= 0) {
                        s[index] = tempWireless - totalsum;
                    }
                    else {
                        s[s.length - 1] = tempWireless - totalsum;
                    }
                    for(var k=0;k<urlAction.restPayload.typeList.filters.ssid.length;k++){
                    for(var z=0;z<ssids.length;z++){
                        if(urlAction.restPayload.typeList.filters.ssid[k]===ssids[z]){
                            count=count+s[z];
                        }
                    }
                    }
                    tempWireless=count;
                 }

                 if (urlAction.restPayload.byvar == 'COMBINED') {
                    if (urlAction.restPayload.kpi == 'DNSCOUNT') {
                        var dnsTemplate = CombinedDNS.COMBINED_DNS;
                        var incrementBy = 0;
                        var startTime = new Date(urlAction.restPayload.typeList.startTime);
                        var endTime = new Date(urlAction.restPayload.typeList.endTime);
                        var record=UtilityFunctions.calculateRecordlen(startTime,endTime);
                        var timeWindow=record.timeWindow;
                        var recordLen=record.recordLen;
                        dnsTemplate.response.detailList[0].scoreList[0].bucket = endTime;
                        dnsTemplate.response.detailList[0].scoreList[1].bucket = endTime;
                        var scorelist=dnsTemplate.response.detailList[1].scoreList;
                        for(var i=0;i<scorelist.length;i=i+2){
                            var tTime = UtilityFunctions.incrementDateByMinutes(startTime, incrementBy);
                            incrementBy += timeWindow;
                            dnsTemplate.response.detailList[1].scoreList[i].bucket=tTime;
                            dnsTemplate.response.detailList[1].scoreList[i+1].bucket=tTime;
                         }
                        return dnsTemplate;
                    }
                 }
               else if (urlAction.restPayload.byvar == 'KPI') {
                    if (urlAction.restPayload.kpi == 'RSSI') {
                        var rssiTemplate = KpiRssi.KPI_RSSI;
                        for (var i = 0; i < rssiTemplate.response.detailList.length; i++) {
                            rssiTemplate.response.detailList[i].value1 = 0;
                        }
                        for (var i = 0; i < wirelessObjs.length; i++) {
                            if (parseInt(wirelessObjs[i].avgRssi) <= -72) {
                                rssiTemplate.response.detailList[0].value1 += 1;
                            }
                            else if (parseInt(wirelessObjs[i].avgRssi) <= -65 && parseInt(wirelessObjs[i].avgRssi) >= -71) {
                                rssiTemplate.response.detailList[1].value1 += 1;
                            }
                            else if (parseInt(wirelessObjs[i].avgRssi) <= -55 && parseInt(wirelessObjs[i].avgRssi) >= -65) {
                                rssiTemplate.response.detailList[2].value1 += 1;
                            }
                            else if (parseInt(wirelessObjs[i].avgRssi) <= -45 && parseInt(wirelessObjs[i].avgRssi) >= -55) {
                                rssiTemplate.response.detailList[3].value1 += 1;
                            }
                            else if (parseInt(wirelessObjs[i].avgRssi) >= -45) {
                                rssiTemplate.response.detailList[4].value1 += 1;
                            }
                        }

                        // commented by Ram due to count mismatch in RSSI view details 
                        // if(siteId == 'global') {
                        //     var detailList = rssiTemplate.response.detailList;
                        //     var totalRssiObjs = detailList[0].value1 + detailList[1].value1 + detailList[2].value1 + detailList[3].value1 + detailList[4].value1;
                        //     var temp1=tempWireless-totalRssiObjs; var s=[];
                        //     s[0] = parseInt(temp1 * 0.02);
                        //     s[1] = parseInt(temp1 * 0.03);
                        //     s[2] = parseInt(temp1 * 0.33);
                        //     s[3] = parseInt(temp1 * 0.37);
                        //     s[4] = temp1 - (s[0] + s[1] + s[2] + s[3]);
                        //     for(var j=0;j< 5;j++){
                        //         rssiTemplate.response.detailList[j].value1 += s[j];
                        //     }
                        //    }
                        return rssiTemplate;
                    }

                    else if (urlAction.restPayload.kpi == 'SNR') {
                        var snrTemplate = KpiSnr.KPI_SNR;
                        for (var i = 0; i < snrTemplate.response.detailList.length; i++) {
                            snrTemplate.response.detailList[i].value1 = 0;
                        }
                        for (var i = 0; i < wirelessObjs.length; i++) {
                            if (wirelessObjs[i].snr <= 10) {
                                snrTemplate.response.detailList[0].value1 += 1;
                            }
                            else if (wirelessObjs[i].snr <= 15 && wirelessObjs[i].snr >= 10) {
                                snrTemplate.response.detailList[1].value1 += 1;
                            }
                            else if (wirelessObjs[i].snr <= 20 && wirelessObjs[i].snr >= 15) {
                                snrTemplate.response.detailList[2].value1 += 1;
                            }
                            else if (wirelessObjs[i].snr <= 40 && wirelessObjs[i].snr >= 20) {
                                snrTemplate.response.detailList[3].value1 += 1;
                            }
                            else if (wirelessObjs[i].snr >= 40) {
                                snrTemplate.response.detailList[4].value1 += 1;
                            }
                        }
                        if (siteId == "global") {
                            var detailList = snrTemplate.response.detailList;
                            var totalSnrObjs = detailList[0].value1 + detailList[1].value1 + detailList[2].value1 + detailList[3].value1 + detailList[4].value1;
                            var temp1=tempWireless-totalSnrObjs; var s=[];
                            s[0] = parseInt(temp1 * 0.01)
                            s[1] = parseInt(temp1 * 0.02);
                            s[2] = parseInt(temp1 * 0.05);
                            s[3] = parseInt(temp1 * 0.50);
                            s[4] = temp1 - (s[0] + s[1] + s[2] + s[3]);
                            for(var j=0;j<5;j++){
                                snrTemplate.response.detailList[j].value1 += s[j];
                            }
                          }
                        return snrTemplate;
                    }
                    else if (urlAction.restPayload.kpi == 'DATARATE_N_AC_AX' || urlAction.restPayload.kpi == 'DATARATE_A_B_G') {
                        var datarateTemplate = KpiDataRate.KPI_DATARATE;
                        for (var i = 0; i < datarateTemplate.response.detailList.length; i++) {
                            datarateTemplate.response.detailList[i].value1 = 0;
                        }
                        var kpi = urlAction.restPayload.kpi;
                        var wirelessObjs = wirelessObjs.filter(obj => kpi=='DATARATE_N_AC_AX' ? 
                        (SimulationUtility.DATARATE_N_AC_AX.indexOf(obj.dot11Protocol)>-1) : (SimulationUtility.DATARATE_A_B_G.indexOf(obj.dot11Protocol)>-1));

                        for (var i = 0; i < wirelessObjs.length; i++) {
                            if (wirelessObjs[i].dataRate <= 29 && wirelessObjs[i].dataRate > 0) {
                                datarateTemplate.response.detailList[0].value1 += 1;
                            }
                            else if (wirelessObjs[i].dataRate <= 150 && wirelessObjs[i].dataRate >= 30) {
                                datarateTemplate.response.detailList[1].value1 += 1;
                            }
                            else if (wirelessObjs[i].dataRate <= 300 && wirelessObjs[i].dataRate >= 151) {
                                datarateTemplate.response.detailList[2].value1 += 1;
                            }
                            else if (wirelessObjs[i].dataRate <= 500 && wirelessObjs[i].dataRate >= 301) {
                                datarateTemplate.response.detailList[3].value1 += 1;
                            }
                            else if (wirelessObjs[i].dataRate <= 1000 && wirelessObjs[i].dataRate >= 501) {
                                datarateTemplate.response.detailList[4].value1 += 1;
                            }
                            else if (wirelessObjs[i].dataRate >= 1001) {
                                datarateTemplate.response.detailList[5].value1 += 1;
                            }
                        }
                        if (siteId == "global") {
                            var detailList = datarateTemplate.response.detailList;
                            var totalDataRateObjs = detailList[0].value1 + detailList[1].value1 + detailList[2].value1 + detailList[3].value1 + detailList[4].value1 + detailList[5].value1;
                            var temp1=tempWireless-totalDataRateObjs; var s=[];
                            s[0] = parseInt(temp1 * 0.03);
                            s[1] = parseInt(temp1 * 0.06);
                            s[2] = parseInt(temp1 * 0.18);
                            s[3] = parseInt(temp1 * 0.15);
                            s[4] = parseInt(temp1 * 0.15);
                            s[5] = temp1 - (s[1] + s[2] + s[3] + s[4] + s[0]);
                            for(var j=0;j<datarateTemplate.response.detailList.length;j++){
                                datarateTemplate.response.detailList[j].value1 += s[j];
                            }
                          }
                        return datarateTemplate;
                    }
                    else if (urlAction.restPayload.kpi == 'ONBOARDING') {
                        var onBoardingTemplate = KpiOnboarding.KPI_ONBOARDING;
                        for (var m = 0; m < onBoardingTemplate.response.detailList.length; m++) {
                            onBoardingTemplate.response.detailList[m].value1 = 0;
                            for (j = 0; j < onBoardingTemplate.response.detailList[m].scoreList.length; j++) {
                                onBoardingTemplate.response.detailList[m].scoreList[j].value1 = 0;
                            }
                        } 
                       
                        var wirelessDetailList=onBoardingTemplate.response.detailList.slice(0,7);
                        var response= filterWithOnboardingKPI(wirelessDetailList,wirelessObjs,"wireless",true,"");
                        var detailList1 = response.detailList;

                        var wiredDetailList=onBoardingTemplate.response.detailList.slice(7);
                        var response = filterWithOnboardingKPI(wiredDetailList,wiredObjs,"wired",true,"");
                        var detailList2 = response.detailList;
                        onBoardingTemplate.response.detailList=detailList1.concat(detailList2);

                        document.cookie = "onBoardingFailList="+JSON.stringify(onBoardingTemplate.response.detailList[6].scoreList)+"; path=/";

                        if (siteId == "global") {
                            var detailList = onBoardingTemplate.response.detailList;
                            var totalWirelessObjs = detailList[0].value1 + detailList[1].value1 + detailList[2].value1 + detailList[3].value1 + detailList[4].value1 + detailList[5].value1 + detailList[6].value1;
                            var detailList = onBoardingTemplate.response.detailList;
                            var totalFlag=false;
                            var s=manageGlobalCount(detailList,tempWireless,tempWired,totalFlag);
                            for(var j=0;j<14;j++){
                                onBoardingTemplate.response.detailList[j].value1 = s[j];
                            }

                            if((urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.frequency != undefined && urlAction.restPayload.typeList.filters.frequency != "")||
                            (urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.ssid != undefined && urlAction.restPayload.typeList.filters.ssid.length > 0)){
                                for(var j=7;j<14;j++){
                                    onBoardingTemplate.response.detailList[j].value1 = 0;
                                }
                           }
                        }
                     return onBoardingTemplate;
                    }

                    if (urlAction.restPayload.kpi == 'CLIENTCOUNT') {
                        if (urlAction.restPayload.breakdownvar == 'FREQUENCY') {
                            var frequencyTemplate = KpiClientCount_Frequency.KPI_CLIENTCOUNT_FREQUENCY;
                            var band_5 = 0, band_2point4 = 0;
                            for (var i = 0; i < wirelessObjs.length; i++) {
                                if (wirelessObjs[i].frequency === "5.0") {
                                    band_5 += 1;
                                }
                                else {
                                    band_2point4 += 1;
                                }
                            }
                            if (siteId == "global") {
                                var totalCount = band_5 + band_2point4;
                               if (urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.frequency != undefined && urlAction.restPayload.typeList.filters.frequency != "") {
                                    var frequency = urlAction.restPayload.typeList.filters.frequency;
                                    if (frequency === "2.4") {
                                        band_2point4 = tempWireless;
                                        band_5 = 0;
                                    }
                                    else if (frequency === "5.0") {
                                        band_2point4 = 0;
                                        band_5 = tempWireless;
                                    }
                                }
                                else {
                                    if(urlAction.restPayload.typeList.filters!=undefined && urlAction.restPayload.typeList.filters.ssid!=undefined && urlAction.restPayload.typeList.filters.ssid.length >0){
                                    var temp1 = tempWireless - totalCount;
                                    console.log("===="+temp1);
                                    band_5 += Math.round(temp1*0.878);
                                    band_2point4 += temp1-Math.round(temp1*0.878);
                                    }
                                    else{
                                        band_5 = 216;
                                    band_2point4 = 34;
                                    }
                                   }

                                                          }
                            frequencyTemplate.response.detailList[0].bucket = new Date(urlAction.restPayload.endTime);
                            frequencyTemplate.response.detailList[0].scoreList[0].bucket = new Date(urlAction.restPayload.endTime);
                            frequencyTemplate.response.detailList[0].scoreList[1].bucket = new Date(urlAction.restPayload.endTime);
                            frequencyTemplate.response.detailList[0].scoreList[0].value1 = band_5;
                            frequencyTemplate.response.detailList[0].scoreList[1].value1 = band_2point4;
                            return frequencyTemplate;
                        }
                        else if (urlAction.restPayload.breakdownvar == 'SSID') {
                            var ssidTemplate = KpiClientCount_SSID.KPI_CLIENTCOUNT_SSID;
                            var allSsids = [];
                            for (var i = 0; i < wirelessObjs.length; i++) {
                                allSsids.push(wirelessObjs[i].ssid);
                            }

                            var ssids = [...new Set(allSsids)];
                            ssidTemplate.response.detailList[0].scoreList = [];

                            for (var i = 0; i < ssids.length; i++) {
                                var obj = {};
                                obj["breakdown"] = ssids[i];
                                obj["value1"] = null;
                                obj["value2"] = null;
                                obj["bucket"] = new Date(urlAction.restPayload.typeList.endTime);
                                obj["scoreList"] = [];
                                ssidTemplate.response.detailList[0].scoreList.push(obj);
                            }
                            for (var i = 0; i < wirelessObjs.length; i++) {
                                for (var j = 0; j < ssids.length; j++) {
                                    if (wirelessObjs[i].ssid === ssids[j]) {
                                        ssidTemplate.response.detailList[0].scoreList[j].value1 += 1;
                                    }
                                }
                            }

                            if (siteId == "global") {
                                var s = [];
                                 var totalCount = 0, unknownIndex = 0,temp=0;
                                  if (urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.frequency != undefined && urlAction.restPayload.typeList.filters.frequency != "") {
                                    var frequency = urlAction.restPayload.typeList.filters.frequency;
                                    if (frequency === "2.4") {
                                       temp = 34;
                                    }
                                    else if (frequency === "5.0") {
                                     temp= 216;
                                    }
                                }
                                else {
                                    temp = 250;
                                }
                                    for (var i = 0; i < ssids.length; i++) {
                                        totalCount += ssidTemplate.response.detailList[0].scoreList[i].value1;
                                        s[i]=ssidTemplate.response.detailList[0].scoreList[i].value1;
                                    }

                                    var temp1=tempWireless-parseInt(totalCount);
                                    var totalsum = 0, index = -1;
                                    if(ssids.length===1){
                                        s[0]+=temp1;
                                    }
                                    else if(ssids.length>1){
                                        for (var i = 0; i < ssids.length; i++) {
                                            if (ssids[i] === "c9800AP11AX") {
                                                index = i;
                                                continue;
                                            }
                                            else{

                                                s[i] += Math.round(temp1 * 0.06);
                                             }
                                            totalsum += s[i];
                                    }

                                    if (index >= 0) {
                                        s[index] = tempWireless - totalsum;
                                    }
                                    else {
                                        s[s.length - 1] += tempWireless - totalsum;
                                    }
                                }

                                for (var i = 0; i < ssids.length; i++) {
                                    ssidTemplate.response.detailList[0].scoreList[i].value1 = s[i];
                                }
                            }
                            return ssidTemplate;
                        }
                    }

                    else if (urlAction.restPayload.kpi == 'AAA') {
                        //here
                        var deviceRootCause = [];
                        var aaaTemplate = KpiAAA.KPI_AAA;
                        var aaaFailList = aaaTemplate.response.detailList[6].scoreList;

                        for (var m = 0; m < aaaTemplate.response.detailList.length; m++) {
                            aaaTemplate.response.detailList[m].value1 = 0;
                            for (j = 0; j < aaaTemplate.response.detailList[m].scoreList.length; j++) {
                                aaaTemplate.response.detailList[m].scoreList[j].value1 = 0;
                            }
                        }

                        var wirelessDetailList=aaaTemplate.response.detailList.slice(0,7);
                        var response= filterWithOnboardingKPI(wirelessDetailList,wirelessObjs,"wireless",false,"maxAuthDuration");
                        var detailList1 = response.detailList;
                        deviceRootCause=response.deviceRootCause;
                        var rootCause = [].concat.apply([],deviceRootCause);

                        var  rootCauseCount = {}; rootCause.forEach(function(i) { rootCauseCount[i] = (rootCauseCount[i]||0) + 1;});

                        var rootcauseCount = 0;
                        for (const [key, value] of Object.entries(rootCauseCount)) {
                           aaaTemplate.response.detailList[6].scoreList[rootcauseCount].breakdown = key;
                           aaaTemplate.response.detailList[6].scoreList[rootcauseCount].value1 = value;
                           rootcauseCount++;
                         }

                         var wiredDetailList=aaaTemplate.response.detailList.slice(7);
                         var response = filterWithOnboardingKPI(wiredDetailList,wiredObjs,"wired",false,"maxAuthDuration");
                         var detailList2 = response.detailList;
                         aaaTemplate.response.detailList=detailList1.concat(detailList2);

                         if (siteId == "global") {
                            var detailList = aaaTemplate.response.detailList;
                            var totalWirelessObjs = detailList[0].value1 + detailList[1].value1 + detailList[2].value1 + detailList[3].value1 + detailList[4].value1 + detailList[5].value1 + detailList[6].value1;
                            var detailList = aaaTemplate.response.detailList;
                            var totalFlag=false;
                            var s=manageGlobalCount(detailList,tempWireless,tempWired,totalFlag);

                            for(var j=0;j<14;j++){
                                aaaTemplate.response.detailList[j].value1 = s[j];
                            }
                           
                           if((urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.frequency != undefined && urlAction.restPayload.typeList.filters.frequency != "")||
                            (urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.ssid != undefined && urlAction.restPayload.typeList.filters.ssid.length > 0)){
                                for(var j=7;j<14;j++){
                                    aaaTemplate.response.detailList[j].value1 = 0;
                                }
                            }

                        }
                        return aaaTemplate;
                    }
                    if (urlAction.restPayload.kpi == 'ASSOC') {
                        var Assoctemplate = KpiASSOC.KPI_ASSOC;
                       var assocDeviceRootCause = [];
                        var assocFailList = Assoctemplate.response.detailList[6].scoreList;

                        for (var m = 0; m < Assoctemplate.response.detailList.length; m++) {
                            Assoctemplate.response.detailList[m].value1 = 0;
                            for (j = 0; j < Assoctemplate.response.detailList[m].scoreList.length; j++) {
                                Assoctemplate.response.detailList[m].scoreList[j].value1 = 0;
                            }
                        }

                        var wirelessDetailList=Assoctemplate.response.detailList.slice(0,7);
                        var response= filterWithOnboardingKPI(wirelessDetailList,wirelessObjs,"wireless",false,"maxAssocDuration");
                        var detailList1 = response.detailList;
                        assocDeviceRootCause=response.deviceRootCause;

                        var assocRootCause = [].concat.apply([],assocDeviceRootCause);

                        var  assocRootCauseCount = {}; assocRootCause.forEach(function(i) { assocRootCauseCount[i] = (assocRootCauseCount[i]||0) + 1;});

                        var assocRootcauseLength = 0;
                        for (const [key, value] of Object.entries(assocRootCauseCount)) {
                           Assoctemplate.response.detailList[6].scoreList[assocRootcauseLength].breakdown = key;
                           Assoctemplate.response.detailList[6].scoreList[assocRootcauseLength].value1 = value;
                           assocRootcauseLength++;
                        }

                         var wiredDetailList=Assoctemplate.response.detailList.slice(7);
                         var response = filterWithOnboardingKPI(wiredDetailList,wiredObjs,"wired",false,"maxAssocDuration");
                         var detailList2 = response.detailList;
                         Assoctemplate.response.detailList=detailList1.concat(detailList2);

                         if (siteId == "global") {
                            var detailList = Assoctemplate.response.detailList;
                            var totalWirelessObjs = detailList[0].value1 + detailList[1].value1 + detailList[2].value1 + detailList[3].value1 + detailList[4].value1 + detailList[5].value1 + detailList[6].value1;
                            var totalWiredObjs = detailList[7].value1 + detailList[8].value1 + detailList[9].value1 + detailList[10].value1 + detailList[11].value1 + detailList[12].value1 + detailList[13].value1;

                            var detailList = Assoctemplate.response.detailList;
                            var totalFlag=false;
                            var s=manageGlobalCount(detailList,tempWireless,tempWired,totalFlag);
                            for(var j=0;j<14;j++){
                                Assoctemplate.response.detailList[j].value1 = s[j];
                            }

                           if((urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.frequency != undefined && urlAction.restPayload.typeList.filters.frequency != "")||
                            (urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.ssid != undefined && urlAction.restPayload.typeList.filters.ssid.length > 0)){
                                for(var j=7;j<14;j++){
                                    Assoctemplate.response.detailList[j].value1 = 0;
                                }
                            }
                        }
                        return Assoctemplate;
                    }

                    if (urlAction.restPayload.kpi == 'DHCP') {
                        var dhcpTemplate = KpiDHCP.KPI_DHCP;
                        var dhcpDeviceRootCause = [];
                        var dhcpFailList = dhcpTemplate.response.detailList[6].scoreList;

                        for (var m = 0; m < dhcpTemplate.response.detailList.length; m++) {
                            dhcpTemplate.response.detailList[m].value1 = 0;
                            for (j = 0; j < dhcpTemplate.response.detailList[m].scoreList.length; j++) {
                                dhcpTemplate.response.detailList[m].scoreList[j].value1 = 0;
                            }
                        }
                        
                        var wirelessDetailList=dhcpTemplate.response.detailList.slice(0,7);
                        var response= filterWithOnboardingKPI(wirelessDetailList,wirelessObjs,"wireless",false,"maxDhcpDuration");
                        var detailList1 = response.detailList;
                        deviceRootCause=response.deviceRootCause;

                        var dhcpRootCause = [].concat.apply([],dhcpDeviceRootCause);

                        var  dhcpRootCauseCount = {}; dhcpRootCause.forEach(function(i) { dhcpRootCauseCount[i] = (dhcpRootCauseCount[i]||0) + 1;});

                        var dhcpRootcauseLength = 0;
                        for (const [key, value] of Object.entries(dhcpRootCauseCount)) {
                           dhcpTemplate.response.detailList[6].scoreList[dhcpRootcauseLength].breakdown = key;
                           dhcpTemplate.response.detailList[6].scoreList[dhcpRootcauseLength].value1 = value;
                           dhcpRootcauseLength++;
                        }

                        var wiredDetailList=dhcpTemplate.response.detailList.slice(7);
                         var response = filterWithOnboardingKPI(wiredDetailList,wiredObjs,"wired",false,"maxDhcpDuration");
                         var detailList2 = response.detailList;
                         dhcpTemplate.response.detailList=detailList1.concat(detailList2);

                         if (siteId == "global") {
                            var detailList = dhcpTemplate.response.detailList;
                            var totalWirelessObjs = detailList[0].value1 + detailList[1].value1 + detailList[2].value1 + detailList[3].value1 + detailList[4].value1 + detailList[5].value1 + detailList[6].value1;
                         
                            var detailList = dhcpTemplate.response.detailList;
                            var totalFlag=false;
                            var s=manageGlobalCount(detailList,tempWireless,tempWired,totalFlag);
                            for(var j=0;j<14;j++){
                                dhcpTemplate.response.detailList[j].value1 = s[j];
                            }

                           if((urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.frequency != undefined && urlAction.restPayload.typeList.filters.frequency != "")||
                            (urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.ssid != undefined && urlAction.restPayload.typeList.filters.ssid.length > 0)){
                                for(var j=7;j<14;j++){
                                    dhcpTemplate.response.detailList[j].value1 = 0;
                                }
                            }
                        }
                        return dhcpTemplate;
                    }

                    else if (urlAction.restPayload.kpi == 'LINK') {
                        var linkTemplate = KpiLink.KPI_LINK;
                        for (var i = 0; i < linkTemplate.response.detailList[0].scoreList.length; i++) {
                            linkTemplate.response.detailList[0].scoreList[i].value1 = 0;
                        }
                        for (var i = 0; i < wiredObjs.length; i++) {
                            if (wiredObjs[i].healthScore[0].score <= 1 && wiredObjs[i].healthScore[0].score >= 0)
                                linkTemplate.response.detailList[0].scoreList[0].value1 += 1;
                           else if (wiredObjs[i].healthScore[0].score <= 10 && wiredObjs[i].healthScore[0].score > 1)
                                linkTemplate.response.detailList[0].scoreList[3].value1 += 1;
                        }

                        var detailsScores = linkTemplate.response.detailList[0].scoreList;
                        var totalCount = detailsScores[0].value1 + detailsScores[1].value1 + detailsScores[2].value1 + detailsScores[3].value1;

                        if (siteId == "global") {
                            var temp1 = 56 - totalCount;
                            totalCount = 56;
                            linkTemplate.response.detailList[0].scoreList[3].value1 += temp1;
                        }
                        linkTemplate.response.detailList[0].value1 = totalCount;
                        return linkTemplate;
                    }

                    /****               ROAMING DETAILS            *****/

                    if(urlAction.restPayload.kpi.indexOf('ROAMING')>=0){
                   
                        if(urlAction.restPayload.kpi==="ROAMING_SUCCESS"){
                        var roamingTemplate = KpiRoaming.KPI_ROAMING;
                        for (var m = 0; m < roamingTemplate.response.detailList.length; m++) {
                            roamingTemplate.response.detailList[m].value1 = 0;
                            for (j = 0; j < roamingTemplate.response.detailList[m].scoreList.length; j++) {
                                roamingTemplate.response.detailList[m].scoreList[j].value1 = 0;
                            }
                        }
                        for (var i = 0; i < wirelessObjs.length; i++) {
                            /*** Onboarding values*/
                            if (wirelessObjs[i].onboarding.maxRoamingDuration <= 300 && wirelessObjs[i].onboarding.maxRoamingDuration > 0) {
                                roamingTemplate.response.detailList[0].value1 += 1;
                            }
                            else if (wirelessObjs[i].onboarding.maxRoamingDuration <= 500 && wirelessObjs[i].onboarding.maxRoamingDuration > 300) {
                                roamingTemplate.response.detailList[1].value1 += 1;
                            }
                            else if (wirelessObjs[i].onboarding.maxRoamingDuration <= 1000 && wirelessObjs[i].onboarding.maxRoamingDuration > 500) {
                                roamingTemplate.response.detailList[2].value1 += 1;
                            }
                            else if (wirelessObjs[i].onboarding.maxRoamingDuration <= 3000 && wirelessObjs[i].onboarding.maxRoamingDuration > 1000) {
                                roamingTemplate.response.detailList[3].value1 += 1;
                            }
                            else if (wirelessObjs[i].onboarding.maxRoamingDuration > 3000) {
                                roamingTemplate.response.detailList[4].value1 += 1;
                            }
                            else if (wirelessObjs[i].onboarding.maxRoamingDuration === "0"  ) {
                                roamingTemplate.response.detailList[5].value1 += 1;
                            }
                        }
                    }
                   else if(urlAction.restPayload.kpi==="ROAMING_FAST"){

                        var roamingTemplate = KpiRoamingFast.KPI_ROAMING_FAST;
                        for (var m = 0; m < roamingTemplate.response.detailList.length; m++) {
                            roamingTemplate.response.detailList[m].value1 = 0;
                            for (j = 0; j < roamingTemplate.response.detailList[m].scoreList.length; j++) {
                                roamingTemplate.response.detailList[m].scoreList[j].value1 = 0;
                            }
                        }
                        wirelessObjs=wirelessObjs.filter(function(itm){return itm.onboarding.maxRoamingDuration <= 150})
                        for (var i = 0; i < wirelessObjs.length; i++) {
                            /*** Onboarding values*/
                            if (wirelessObjs[i].onboarding.maxRoamingDuration <= 10 && wirelessObjs[i].onboarding.maxRoamingDuration > 0) {
                                roamingTemplate.response.detailList[0].value1 += 1;
                            }
                            else if (wirelessObjs[i].onboarding.maxRoamingDuration <= 20 && wirelessObjs[i].onboarding.maxRoamingDuration > 10) {
                                roamingTemplate.response.detailList[1].value1 += 1;
                            }
                            else if (wirelessObjs[i].onboarding.maxRoamingDuration <= 50 && wirelessObjs[i].onboarding.maxRoamingDuration > 20) {
                                roamingTemplate.response.detailList[2].value1 += 1;
                            }
                            else if (wirelessObjs[i].onboarding.maxRoamingDuration < 100 && wirelessObjs[i].onboarding.maxRoamingDuration > 50) {
                                roamingTemplate.response.detailList[3].value1 += 1;
                            }
                            else if (wirelessObjs[i].onboarding.maxRoamingDuration < 150 && wirelessObjs[i].onboarding.maxRoamingDuration > 100) {
                                roamingTemplate.response.detailList[4].value1 += 1;
                            }
                            else if (wirelessObjs[i].onboarding.maxRoamingDuration >= 150) {
                                roamingTemplate.response.detailList[5].value1 += 1;
                            }
                        }
                    }
                   else if(urlAction.restPayload.kpi==="ROAMING_SLOW"){
                        var roamingTemplate = KpiRoamingSlow.KPI_ROAMING_SLOW;
                        for (var m = 0; m < roamingTemplate.response.detailList.length; m++) {
                            roamingTemplate.response.detailList[m].value1 = 0;
                            for (j = 0; j < roamingTemplate.response.detailList[m].scoreList.length; j++) {
                                roamingTemplate.response.detailList[m].scoreList[j].value1 = 0;
                            }
                        }
                        wirelessObjs=wirelessObjs.filter(function(itm){return itm.onboarding.maxRoamingDuration >= 300})
                        for (var i = 0; i < wirelessObjs.length; i++) {
                            /*** Onboarding values*/
                            if (wirelessObjs[i].onboarding.maxRoamingDuration <= 300 && wirelessObjs[i].onboarding.maxRoamingDuration > 0) {
                                roamingTemplate.response.detailList[0].value1 += 1;
                            }
                            else if (wirelessObjs[i].onboarding.maxRoamingDuration <= 500 && wirelessObjs[i].onboarding.maxRoamingDuration > 300) {
                                roamingTemplate.response.detailList[1].value1 += 1;
                            }
                            else if (wirelessObjs[i].onboarding.maxRoamingDuration <= 1000 && wirelessObjs[i].onboarding.maxRoamingDuration > 500) {
                                roamingTemplate.response.detailList[2].value1 += 1;
                            }
                            else if (wirelessObjs[i].onboarding.maxRoamingDuration <= 2000 && wirelessObjs[i].onboarding.maxRoamingDuration > 1000) {
                                roamingTemplate.response.detailList[3].value1 += 1;
                            }
                            else if (wirelessObjs[i].onboarding.maxRoamingDuration <= 3000 && wirelessObjs[i].onboarding.maxRoamingDuration > 2000) {
                                roamingTemplate.response.detailList[4].value1 += 1;
                            }
                            else if (wirelessObjs[i].onboarding.maxRoamingDuration > 3000) {
                                roamingTemplate.response.detailList[5].value1 += 1;
                            }
                        }
                    }
                    else if(urlAction.restPayload.kpi==="ROAMING_CLIENTCOUNT"){
                        var roamingTemplate = KpiRoamingClientCount.KPI_ROAMING_CLIENTCOUNT;
                        for (var m = 0; m < roamingTemplate.response.detailList[0].scoreList.length; m++) {
                            roamingTemplate.response.detailList[0].scoreList[m].value1 = 0;
                            for (j = 0; j < roamingTemplate.response.detailList[0].scoreList[m].scoreList.length; j++) {
                                roamingTemplate.response.detailList[0].scoreList[m].scoreList[j].value1 = 0;
                            }
                        }
                       var successWirelessObjs=wirelessObjs.filter(function(itm){return (itm.healthScore[0].score > 3 && itm.onboarding.maxRoamingDuration >0)});
                       var failureWirelessObjs=wirelessObjs.filter(function(itm){return (itm.healthScore[0].score <= 3 && itm.onboarding.maxRoamingDuration >0)});
                       
                        roamingTemplate.response.detailList[0].scoreList[0].value1=successWirelessObjs.length;
                        roamingTemplate.response.detailList[0].scoreList[1].value1=failureWirelessObjs.length;
                     }
                       
                     if (siteId == "global") {                        
                          if(urlAction.restPayload.kpi==="ROAMING_CLIENTCOUNT"){
                            var scoreList = roamingTemplate.response.detailList[0].scoreList;
                            var totalWirelessObjs = scoreList[0].value1 + scoreList[1].value1; 
                            var temp1=tempWireless-totalWirelessObjs;
                            roamingTemplate.response.detailList[0].scoreList[0].value1+=temp1;
                          }
                        else{
                        var detailList = roamingTemplate.response.detailList;
                        var totalWirelessObjs = detailList[0].value1 + detailList[1].value1 + detailList[2].value1 + detailList[3].value1 + detailList[4].value1 + detailList[5].value1;
                      
                        var temp1=tempWireless;

                        if(urlAction.restPayload.kpi==="ROAMING_FAST"){
                            temp1=parseInt(temp1/3);
                        }
                        else if(urlAction.restPayload.kpi==="ROAMING_SLOW"){
                            temp1=temp1-parseInt(temp1/3);
                        }
                        var detailList = roamingTemplate.response.detailList;
                        var s=[];
                        s[1] = (detailList[1].value1 + Math.floor(temp1 * 0.04)) < 10 ? (detailList[1].value1 + Math.floor(temp1 * 0.04)) : 10;
                        s[2] = (detailList[2].value1 + Math.floor(temp1 * 0.02)) < 4 ? (detailList[2].value1 + Math.floor(temp1 * 0.02)) : 4;
                        s[3] = (detailList[3].value1 + Math.floor(temp1 * 0.02)) < 4 ? (detailList[3].value1 + Math.floor(temp1 * 0.02)) : 4;
                        s[4] = (detailList[4].value1 + Math.floor(temp1 * 0.03)) < 5 ? (detailList[4].value1 + Math.floor(temp1 * 0.03)) : 5;
                        s[5] = detailList[5].value1;
                      
                        s[1]=detailList[1].value1>s[1]?detailList[1].value1:s[1];
                        s[2]=detailList[2].value1>s[2]?detailList[2].value1:s[2];
                        s[3]=detailList[3].value1>s[3]?detailList[3].value1:s[3];
                        s[4]=detailList[4].value1>s[4]?detailList[4].value1:s[4];
                       
                        s[0] = temp1 - (s[1] + s[2] + s[3] + s[4] + s[5]);
                        for(var j=0;j<6;j++){
                            roamingTemplate.response.detailList[j].value1 = s[j];
                        }
                      }
                    }
                    return roamingTemplate;
                    } else if(urlAction.restPayload.kpi==="CLIENTDISTRIBUTION") {
                        //WI-FI 6 addition
                        var finalResponse = {"version":"1.0","response":{"detailList":[]} };
                        finalResponse.response.detailList = getClientsByProtocolKpi(urlAction, wirelessHosts);
                        return finalResponse;
                    }
                 }
                else if (urlAction.restPayload.byvar == 'TIMESTAMP') {
                    if (urlAction.restPayload.kpi == 'AAA') {
                        //return TimestampAAA.TIMESTAMP_AAA;
                        var aaaTimestampTemplate = TimestampAAA.TIMESTAMP_AAA;
                        var incrementBy = 0;
                        var startTime = new Date(urlAction.restPayload.typeList.startTime);
                        var endTime = new Date(urlAction.restPayload.typeList.endTime);
                        var record=UtilityFunctions.calculateRecordlen(startTime,endTime);
                        var timeWindow=record.timeWindow;
                        var recordLen=record.recordLen;

                        var score = { "<1": 0, "1 to 2": 0, "2 to 3": 0, "3 to 4": 0, "4 to 5": 0, ">=5": 0, "fail": 0 };

                        if((urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.frequency != undefined && urlAction.restPayload.typeList.filters.frequency != "")||
                        (urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.ssid != undefined && urlAction.restPayload.typeList.filters.ssid.length > 0)){
                            hosts=hosts.filter(function(itm){return itm.hostType==="WIRELESS"})
                        }

                        score=onboardingTimestampFilter(hosts,score,false,"maxAuthDuration")

                        aaaTimestampTemplate.response.detailList = [];
                        for (var i = 0; i < recordLen; i++) {
                            var tTime = UtilityFunctions.incrementDateByMinutes(startTime, incrementBy);
                            incrementBy += timeWindow;
                            aaaTimestampTemplate.response.detailList[i] = {};
                            aaaTimestampTemplate.response.detailList[i]["scoreList"] = [];
                            aaaTimestampTemplate.response.detailList[i]["bucket"] = tTime;
                            aaaTimestampTemplate.response.detailList[i]["breakdown"] = null;
                            var j = 0, totalScore = 0;
                            for (key in score) {
                                aaaTimestampTemplate.response.detailList[i].scoreList[j] = {};
                                aaaTimestampTemplate.response.detailList[i].scoreList[j]["breakdown"] = key;
                                aaaTimestampTemplate.response.detailList[i].scoreList[j]["value1"] = score[key];
                                aaaTimestampTemplate.response.detailList[i].scoreList[j]["scoreList"] = [];
                                totalScore += parseInt(score[key]);
                                j++;
                            }
                            aaaTimestampTemplate.response.detailList[i].value2 = totalScore;
                        }

                        if (siteId == "global") {
                            for (var i = 0; i < recordLen; i++) {
                                var scoreList = aaaTimestampTemplate.response.detailList[i].scoreList;
                                var totalOnboardingObjs = 0;
                                for (var j = 0; j < 7; j++) {
                                    totalOnboardingObjs += scoreList[j].value1;
                                }
                         
                                var temp1=tempWired+tempWireless;
                                var totalCount=tempWired+tempWireless;
                                var detailList = aaaTimestampTemplate.response.detailList[i].scoreList;
                                var totalFlag=true;
                                var s=manageGlobalCount(detailList,tempWireless,tempWired,totalFlag);
                           
                                  aaaTimestampTemplate.response.detailList[i].value2 = totalCount;
                                  for(var j=0;j<7;j++){
                                    aaaTimestampTemplate.response.detailList[i].scoreList[j].value1 = s[j];
                                  }
                                }
                        }

                        for (var i = 0; i < aaaTimestampTemplate.response.detailList.length; i++) {
                            var detailList = aaaTimestampTemplate.response.detailList[i];
                            var min = detailList.scoreList[0]["value1"];
                            var max = detailList.scoreList[0]["value1"] - 10;
                            var randNum = UtilityFunctions.getRandomIntForRangeValues(min, max);
                            detailList.scoreList[0]["value1"] = randNum;
                            var totalSum = detailList.scoreList[0]["value1"] + detailList.scoreList[1]["value1"] + detailList.scoreList[2]["value1"] + detailList.scoreList[3]["value1"] + detailList.scoreList[4]["value1"] + detailList.scoreList[5]["value1"];
                            var diffValue = detailList.value2 - totalSum;
                            if (diffValue > 0) {

                                detailList.scoreList[1]["value1"] = detailList.scoreList[1]["value1"] + diffValue;
                            }
                        }

                        return aaaTimestampTemplate;
                    }
                    if (urlAction.restPayload.kpi == 'ASSOC') {
                      
                        var assocTimestampTemplate = TimestampAAA.TIMESTAMP_AAA;
                        var incrementBy = 0;
                        var startTime = new Date(urlAction.restPayload.typeList.startTime);
                        var endTime = new Date(urlAction.restPayload.typeList.endTime);
                        var record=UtilityFunctions.calculateRecordlen(startTime,endTime);
                        var timeWindow=record.timeWindow;
                        var recordLen=record.recordLen;

                        var score = { "<1": 0, "1 to 2": 0, "2 to 3": 0, "3 to 4": 0, "4 to 5": 0, ">=5": 0, "fail": 0 };

                        if((urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.frequency != undefined && urlAction.restPayload.typeList.filters.frequency != "")||
                        (urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.ssid != undefined && urlAction.restPayload.typeList.filters.ssid.length > 0)){
                            hosts=hosts.filter(function(itm){return itm.hostType==="WIRELESS"})
                        }

                        score=onboardingTimestampFilter(hosts,score,false,"maxAssocDuration")

                        assocTimestampTemplate.response.detailList = [];
                        for (var i = 0; i < recordLen; i++) {
                            var tTime = UtilityFunctions.incrementDateByMinutes(startTime, incrementBy);
                            incrementBy += timeWindow;
                            assocTimestampTemplate.response.detailList[i] = {};
                            assocTimestampTemplate.response.detailList[i]["scoreList"] = [];
                            assocTimestampTemplate.response.detailList[i]["bucket"] = tTime;
                            assocTimestampTemplate.response.detailList[i]["breakdown"] = null;
                            var j = 0, totalScore = 0;
                            for (key in score) {
                                assocTimestampTemplate.response.detailList[i].scoreList[j] = {};
                                assocTimestampTemplate.response.detailList[i].scoreList[j]["breakdown"] = key;
                                assocTimestampTemplate.response.detailList[i].scoreList[j]["value1"] = score[key];
                                assocTimestampTemplate.response.detailList[i].scoreList[j]["scoreList"] = [];
                                totalScore += parseInt(score[key]);
                                j++;
                            }
                            assocTimestampTemplate.response.detailList[i].value2 = totalScore;
                        }

                        if (siteId == "global") {
                            for (var i = 0; i < recordLen; i++) {
                                var scoreList = assocTimestampTemplate.response.detailList[i].scoreList;
                                var totalOnboardingObjs = 0;
                                for (var j = 0; j < 7; j++) {
                                    totalOnboardingObjs += scoreList[j].value1;
                                }
                         
                               var temp1=tempWired+tempWireless;
                               var totalCount=tempWired+tempWireless;

                            var detailList = assocTimestampTemplate.response.detailList[i].scoreList;
                            var totalFlag=true;
                            var s=manageGlobalCount(detailList,tempWireless,tempWired,totalFlag);
                           
                            assocTimestampTemplate.response.detailList[i].value2 = totalCount;
                            for(var j=0;j<7;j++){
                                assocTimestampTemplate.response.detailList[i].scoreList[j].value1 = s[j];
                              }
                           }
                        }

                        for (var i = 0; i < assocTimestampTemplate.response.detailList.length; i++) {
                            var detailList = assocTimestampTemplate.response.detailList[i];
                            var min = detailList.scoreList[0]["value1"];
                            var max = detailList.scoreList[0]["value1"] - 10;
                            var randNum = UtilityFunctions.getRandomIntForRangeValues(min, max);
                            detailList.scoreList[0]["value1"] = randNum;
                            var totalSum = detailList.scoreList[0]["value1"] + detailList.scoreList[1]["value1"] + detailList.scoreList[2]["value1"] + detailList.scoreList[3]["value1"] + detailList.scoreList[4]["value1"] + detailList.scoreList[5]["value1"];
                            var diffValue = detailList.value2 - totalSum;
                            if (diffValue > 0) {

                                detailList.scoreList[1]["value1"] = detailList.scoreList[1]["value1"] + diffValue;
                            }
                        }

                        return assocTimestampTemplate;
                    }
                    if (urlAction.restPayload.kpi == 'CLIENTCOUNT') {
                        if (urlAction.restPayload.breakdownvar == 'FREQUENCY') {
                            var clientCountTemplate = TimestampClientCount_Frequency.TIMESTAMP_CLIENTCOUNT_FREQUENCY;
                            var incrementBy = 0;
                            var startTime = new Date(urlAction.restPayload.typeList.startTime);
                            var endTime = new Date(urlAction.restPayload.typeList.endTime);
                            var record=UtilityFunctions.calculateRecordlen(startTime,endTime);
                            var timeWindow=record.timeWindow;
                            var recordLen=record.recordLen;
                            var band_5 = 0, band_2point4 = 0;
                            for (var i = 0; i < wirelessObjs.length; i++) {
                                if (wirelessObjs[i].frequency === "5.0") {
                                    band_5 += 1;
                                }
                                else {
                                    band_2point4 += 1;
                                }
                            }

                            clientCountTemplate.response.detailList = [];
                            for (var i = 0; i < recordLen; i++) {
                                clientCountTemplate.response.detailList[i] = {};
                                var tTime = UtilityFunctions.incrementDateByMinutes(startTime, incrementBy);
                                incrementBy += timeWindow;
                                clientCountTemplate.response.detailList[i]["scoreList"] = [];
                                clientCountTemplate.response.detailList[i]["bucket"] = tTime;
                                clientCountTemplate.response.detailList[i]["breakdown"] = null;

                              var min = 210; var max=220;
                                var rand1 = UtilityFunctions.getRandomIntForRangeValues(min, max);
                                var rand2 = 250- rand1;

                                clientCountTemplate.response.detailList[i].scoreList[0] = {};
                                clientCountTemplate.response.detailList[i].scoreList[1] = {};
                                clientCountTemplate.response.detailList[i].scoreList[0]["value1"] = rand1;
                                clientCountTemplate.response.detailList[i].scoreList[0]["breakdown"] = TimestampClientCount_Frequency.NAMES[0].name;
                                clientCountTemplate.response.detailList[i].scoreList[0]["scoreList"] = [];
                                clientCountTemplate.response.detailList[i].scoreList[0]["bucket"] = null;

                                clientCountTemplate.response.detailList[i].scoreList[1]["value1"] = rand2;
                                clientCountTemplate.response.detailList[i].scoreList[1]["breakdown"] = TimestampClientCount_Frequency.NAMES[1].name;
                                clientCountTemplate.response.detailList[i].scoreList[1]["scoreList"] = [];
                                clientCountTemplate.response.detailList[i].scoreList[1]["bucket"] = null;
                            }
                            return clientCountTemplate;
                        }
                        else if (urlAction.restPayload.breakdownvar == 'SSID') {
                            var ssidTemplate = TimestampClientCount_SSID.TIMESTAMP_CLIENTCOUNT_SSID;
                            var incrementBy = 0;
                            var startTime = new Date(urlAction.restPayload.typeList.startTime);
                            var endTime = new Date(urlAction.restPayload.typeList.endTime);
                            var record=UtilityFunctions.calculateRecordlen(startTime,endTime);
                            var timeWindow=record.timeWindow;
                            var recordLen=record.recordLen;
                            var allSsids = [];
                            for (var i = 0; i < wirelessObjs.length; i++) {
                                allSsids.push(wirelessObjs[i].ssid);
                            }
                            var ssids = [...new Set(allSsids)];
                            ssidTemplate.response.detailList = [];
                            var obj = {};
                            for (var i = 0; i < ssids.length; i++) {
                                obj[ssids[i]] = 0;

                            }
                            for (var i = 0; i < wirelessObjs.length; i++) {
                                for (var j = 0; j < ssids.length; j++) {
                                    if (wirelessObjs[i].ssid === ssids[j]) {
                                        obj[ssids[j]] += 1;
                                    }
                                }
                            }

                            for (var i = 0; i < recordLen; i++) {
                                ssidTemplate.response.detailList[i] = {};
                                var tTime = UtilityFunctions.incrementDateByMinutes(startTime, incrementBy);
                                incrementBy += timeWindow;
                                ssidTemplate.response.detailList[i]["scoreList"] = [];
                                ssidTemplate.response.detailList[i]["bucket"] = tTime;
                                ssidTemplate.response.detailList[i]["breakdown"] = null;
                                var totalCount = 0;
                                for (var j = 0; j < ssids.length; j++) {
                                    ssidTemplate.response.detailList[i].scoreList[j] = {};
                                    ssidTemplate.response.detailList[i].scoreList[j]["breakdown"] = ssids[j];
                                    ssidTemplate.response.detailList[i].scoreList[j]["value1"] = obj[ssids[j]];
                                    ssidTemplate.response.detailList[i].scoreList[j]["scoreList"] = [];
                                    totalCount += parseInt(obj[ssids[j]]);
                                }
                                ssidTemplate.response.detailList[i].value1 = totalCount;
                            }

                            if (siteId == "global") {
                                 for (var i = 0; i < recordLen; i++) {
                                    var scoreList = ssidTemplate.response.detailList[i].scoreList;
                                    var totalSsidObjs = 0;
                                    var totalsum = 0, index = -1;
                                    for (var j = 0; j < ssids.length; j++) {
                                        totalSsidObjs += scoreList[j].value1;
                                    }
                                
                                    var temp1=tempWireless-totalSsidObjs;
                                   
                                    ssidTemplate.response.detailList[i].value1 = totalCount;

                                    if(ssids.length===1){
                                        ssidTemplate.response.detailList[i].scoreList[0].value1+=temp1;
                                    }
                                    else if(ssids.length>1){
                                        for (var j = 0; j < ssids.length; j++) {
                                            if (ssids[j] === "c9800AP11AX") {
                                                index = j;
                                                continue;
                                            }
                                            else{

                                                ssidTemplate.response.detailList[i].scoreList[j].value1 += Math.round(temp1 * 0.06);
                                             }
                                            totalsum +=  ssidTemplate.response.detailList[i].scoreList[j].value1;
                                    }

                                    if (index >= 0) {
                                        ssidTemplate.response.detailList[i].scoreList[index].value1= tempWireless - totalsum;
                                    }
                                    else {
                                        var slen=ssidTemplate.response.detailList[i].scoreList.length;
                                        ssidTemplate.response.detailList[i].scoreList[slen-1].value1 += tempWireless - totalsum;
                                    }
                                    
                                    }
                                }
                            }

                            for (var i = 0; i < ssidTemplate.response.detailList.length; i++) {
                                var detailList = ssidTemplate.response.detailList[i];
                                var len=detailList.scoreList.length;
                                for(var j=0;j<len-1;j++){
                                    var min = detailList.scoreList[j]["value1"];
                                    var max = detailList.scoreList[j]["value1"] + 3;
                                    var randNum = UtilityFunctions.getRandomIntForRangeValues(min, max);
                                    detailList.scoreList[j]["value1"] = randNum;
                                    detailList.scoreList[j+1]["value1"]-=randNum-min;
                                }
                            }
                            return ssidTemplate;
                        }
                        else if (urlAction.restPayload.breakdownvar == 'DOT11_PROTOCOL_CAPABILITY') {
                            var clientCountTemplate = {"version": 1.0, "response": { "detailList": [] } };
                            var tJson = { "breakdown":null, "bucket":"timeToBeUpdated", "value1":null, "value2":null, "scoreList": [] };
                            var detailList = getClientsByProtocolKpi(urlAction, wirelessHosts);
                            detailList.forEach(dtl => {
                                var obj = dtl;
                                obj.bucket = "timeToBeUpdated";
                                delete obj.scoreList;
                                tJson.scoreList.push(obj);
                            });
                            var incrementBy = 5;
                            var startTime = urlAction.restPayload.typeList.startTime;
                            var endTime = urlAction.restPayload.typeList.endTime;
                            var timeWindow = 5, timeWindowMils = timeWindow*60*1000;
                            var customTime = startTime + (300000 - (startTime%300000));//nearest 5min
                            for(; customTime<endTime; customTime += timeWindowMils){
                                var obj = JSON.parse(JSON.stringify(tJson));
                                obj = JSON.stringify(obj);
                                var tTime = UtilityFunctions.getGivenTimeInGivenFormat(customTime,"YYYY-MM-DDTHH:mm:ss.SSSZZ");
                                obj = obj.replace(/timeToBeUpdated/g, tTime);
                                obj = JSON.parse(obj);
                                clientCountTemplate.response.detailList.push(obj);
                            }
                            return clientCountTemplate;
                        }                            
                    }
                    if (urlAction.restPayload.kpi == 'DHCP') {
                        // return generateData(urlAction, TimestampDHCP.TIMESTAMP_DHCP);
                        var dhcpTimestampTemplate = TimestampDHCP.TIMESTAMP_DHCP;
                        var incrementBy = 0;
                        var startTime = new Date(urlAction.restPayload.typeList.startTime);
                        var endTime = new Date(urlAction.restPayload.typeList.endTime);
                        var record=UtilityFunctions.calculateRecordlen(startTime,endTime);
                        var timeWindow=record.timeWindow;
                        var recordLen=record.recordLen;

                        var score = { "<1": 0, "1 to 2": 0, "2 to 3": 0, "3 to 4": 0, "4 to 5": 0, ">=5": 0, "fail": 0 };

                        if((urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.frequency != undefined && urlAction.restPayload.typeList.filters.frequency != "")||
                        (urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.ssid != undefined && urlAction.restPayload.typeList.filters.ssid.length > 0)){
                            hosts=hosts.filter(function(itm){return itm.hostType==="WIRELESS"})
                        }

                        score=onboardingTimestampFilter(hosts,score,false,"maxDhcpDuration");

                        dhcpTimestampTemplate.response.detailList = [];
                        for (var i = 0; i < recordLen; i++) {
                            var tTime = UtilityFunctions.incrementDateByMinutes(startTime, incrementBy);
                            incrementBy += timeWindow;
                            dhcpTimestampTemplate.response.detailList[i] = {};
                            dhcpTimestampTemplate.response.detailList[i]["scoreList"] = [];
                            dhcpTimestampTemplate.response.detailList[i]["bucket"] = tTime;
                            dhcpTimestampTemplate.response.detailList[i]["breakdown"] = null;
                            var j = 0, totalScore = 0;
                            for (key in score) {
                                dhcpTimestampTemplate.response.detailList[i].scoreList[j] = {};
                                dhcpTimestampTemplate.response.detailList[i].scoreList[j]["breakdown"] = key;
                                dhcpTimestampTemplate.response.detailList[i].scoreList[j]["value1"] = score[key];
                                dhcpTimestampTemplate.response.detailList[i].scoreList[j]["scoreList"] = [];
                                totalScore += parseInt(score[key]);
                                j++;
                            }
                            dhcpTimestampTemplate.response.detailList[i].value2 = totalScore;
                        }

                        if (siteId == "global") {
                            for (var i = 0; i < recordLen; i++) {
                                var scoreList = dhcpTimestampTemplate.response.detailList[i].scoreList;
                                var totalOnboardingObjs = 0;
                                for (var j = 0; j < 6; j++) {
                                    totalOnboardingObjs += scoreList[j].value1;
                                }
                            
                                var temp1=tempWired+tempWireless;
                                var totalCount=tempWired+tempWireless;

                                var detailList = dhcpTimestampTemplate.response.detailList[i].scoreList;
                                var totalFlag=true;
                                var s=manageGlobalCount(detailList,tempWireless,tempWired,totalFlag);
                           
                                dhcpTimestampTemplate.response.detailList[i].value2 = totalCount;
                                for(var j=0;j<7;j++){
                                    dhcpTimestampTemplate.response.detailList[i].scoreList[j].value1 = s[j];
                                  }
                               }
                        }
                        for (var i = 0; i < dhcpTimestampTemplate.response.detailList.length; i++) {
                            var detailList = dhcpTimestampTemplate.response.detailList[i];
                            var min = detailList.scoreList[0]["value1"];
                            var max = detailList.scoreList[0]["value1"] - 10;
                            var randNum = UtilityFunctions.getRandomIntForRangeValues(min, max);
                            detailList.scoreList[0]["value1"] = randNum;
                            var totalSum = detailList.scoreList[0]["value1"] + detailList.scoreList[1]["value1"] + detailList.scoreList[2]["value1"] + detailList.scoreList[3]["value1"] + detailList.scoreList[4]["value1"] + detailList.scoreList[5]["value1"];
                            var diffValue = detailList.value2 - totalSum;
                            if (diffValue > 0) {

                                detailList.scoreList[1]["value1"] = detailList.scoreList[1]["value1"] + diffValue;
                            }
                        }

                        return dhcpTimestampTemplate;
                    }

                    if (urlAction.restPayload.kpi == 'ONBOARDING') {
                        var onBoardingTemplate = TimestampOnboarding.TIMESTAMP_ONBOARDING;
                        var incrementBy = 0;
                        var startTime = new Date(urlAction.restPayload.typeList.startTime);
                        var endTime = new Date(urlAction.restPayload.typeList.endTime);
                        var record=UtilityFunctions.calculateRecordlen(startTime,endTime);
                        var timeWindow=record.timeWindow;
                        var recordLen=record.recordLen;

                        var score = { "<2": 0, "2 to 4": 0, "4 to 6": 0, "6 to 8": 0, "8 to 10": 0, ">=10": 0, "fail": 0 };
                        if((urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.frequency != undefined && urlAction.restPayload.typeList.filters.frequency != "")||
                        (urlAction.restPayload.typeList.filters != undefined && urlAction.restPayload.typeList.filters.ssid != undefined && urlAction.restPayload.typeList.filters.ssid.length > 0)){
                            hosts=hosts.filter(function(itm){return itm.hostType==="WIRELESS"})
                        }

                        score=onboardingTimestampFilter(hosts,score,true,"")
  
                        onBoardingTemplate.response.detailList = [];
                        for (var i = 0; i < recordLen; i++) {
                            var tTime = UtilityFunctions.incrementDateByMinutes(startTime, incrementBy);
                            incrementBy += timeWindow;
                            onBoardingTemplate.response.detailList[i] = {};
                            onBoardingTemplate.response.detailList[i]["scoreList"] = [];
                            onBoardingTemplate.response.detailList[i]["bucket"] = tTime;
                            onBoardingTemplate.response.detailList[i]["breakdown"] = null;
                            var j = 0, totalScore = 0;
                            for (key in score) {
                                onBoardingTemplate.response.detailList[i].scoreList[j] = {};
                                onBoardingTemplate.response.detailList[i].scoreList[j]["breakdown"] = key;
                                onBoardingTemplate.response.detailList[i].scoreList[j]["value1"] = score[key];
                                onBoardingTemplate.response.detailList[i].scoreList[j]["scoreList"] = [];
                                totalScore += parseInt(score[key]);
                                j++;
                            }
                            onBoardingTemplate.response.detailList[i].value2 = totalScore;
                        }

                        if (siteId == "global") {
                            for (var i = 0; i < recordLen; i++) {
                                var scoreList = onBoardingTemplate.response.detailList[i].scoreList;
                                var totalOnboardingObjs = 0;
                                for (var j = 0; j < 6; j++) {
                                    totalOnboardingObjs += scoreList[j].value1;
                                }
                         
                                var temp1=tempWired+tempWireless;
                                var totalCount=tempWired+tempWireless;


                            var detailList = onBoardingTemplate.response.detailList[i].scoreList;
                            var totalFlag=true;
                            var s=manageGlobalCount(detailList,tempWireless,tempWired,totalFlag);
                           
                            onBoardingTemplate.response.detailList[i].value2 = totalCount;
                            for(var j=0;j<7;j++){
                                onBoardingTemplate.response.detailList[i].scoreList[j].value1 = s[j];
                              }
                          
                            }
                        }

                        for (var i = 0; i < onBoardingTemplate.response.detailList.length; i++) {
                            var detailList = onBoardingTemplate.response.detailList[i];
                            var min = detailList.scoreList[0]["value1"];
                            var max = detailList.scoreList[0]["value1"] - 10;
                            var randNum = UtilityFunctions.getRandomIntForRangeValues(min, max);
                            detailList.scoreList[0]["value1"] = randNum;
                            var totalSum = detailList.scoreList[0]["value1"] + detailList.scoreList[1]["value1"] + detailList.scoreList[2]["value1"] + detailList.scoreList[3]["value1"] + detailList.scoreList[4]["value1"] + detailList.scoreList[5]["value1"]+detailList.scoreList[6]["value1"];
                            var diffValue = detailList.value2 - totalSum;
                            if (diffValue > 0) {

                                detailList.scoreList[1]["value1"] = detailList.scoreList[1]["value1"] + diffValue;
                            }
                        }

                        return onBoardingTemplate;
                        //return generateData(urlAction, TimestampOnboarding.TIMESTAMP_ONBOARDING);
                    }
                    else if (urlAction.restPayload.kpi == 'RSSI') {
                        //return generateData(urlAction, TimestampRssi.TIMESTAMP_RSSI);
                        var rssiTemplate = TimestampRssi.TIMESTAMP_RSSI;
                        var incrementBy = 0;
                        var startTime = new Date(urlAction.restPayload.typeList.startTime);
                        var endTime = new Date(urlAction.restPayload.typeList.endTime);
                        var record=UtilityFunctions.calculateRecordlen(startTime,endTime);
                        var timeWindow=record.timeWindow;
                        var recordLen=record.recordLen;

                        rssiTemplate.response.detailList = [];  

                        for (var i = 0; i < recordLen; i++) {
                            rssiTemplate.response.detailList[i] = {};
                            var value1 = 0, value2 = 0;
                            var tTime = UtilityFunctions.incrementDateByMinutes(startTime, incrementBy);
                            incrementBy += timeWindow;
                            rssiTemplate.response.detailList[i]["scoreList"] = [];
                            rssiTemplate.response.detailList[i]["bucket"] = tTime;
                            rssiTemplate.response.detailList[i]["breakdown"] = null;

                            for(var j=0;j<5;j++){
                                rssiTemplate.response.detailList[i].scoreList[j] = {};
                                rssiTemplate.response.detailList[i].scoreList[j]["value1"] = 0;
                                rssiTemplate.response.detailList[i].scoreList[j]["value2"] = null;
                                rssiTemplate.response.detailList[i].scoreList[j]["bucket"] = tTime;
                                rssiTemplate.response.detailList[i].scoreList[j]["scoreList"] = [];
                            }

                            rssiTemplate.response.detailList[i].scoreList[0]["breakdown"] = "<=-72";
                            rssiTemplate.response.detailList[i].scoreList[1]["breakdown"] = "-71 to -65";
                            rssiTemplate.response.detailList[i].scoreList[2]["breakdown"] = "-65 to -55";
                            rssiTemplate.response.detailList[i].scoreList[3]["breakdown"] = "-55 to -45";
                            rssiTemplate.response.detailList[i].scoreList[4]["breakdown"] = ">=-45";
                         
                            var totalRssi = 0;
                            for (var j = 0; j < wirelessObjs.length; j++) {

                                if (wirelessObjs[j].avgRssi <= -72) {
                                    totalRssi = parseFloat(wirelessObjs[j].avgRssi) + parseFloat(totalRssi);
                                    rssiTemplate.response.detailList[i].scoreList[0]["value1"] += 1;
                                }
                                else if (wirelessObjs[j].avgRssi <= -65 && wirelessObjs[j].avgRssi >= -71) {
                                    totalRssi = parseFloat(wirelessObjs[j].avgRssi) + parseFloat(totalRssi);
                                    rssiTemplate.response.detailList[i].scoreList[1]["value1"] += 1;
                                }
                                else if (wirelessObjs[j].avgRssi <= -55 && wirelessObjs[j].avgRssi >= -65) {
                                    totalRssi = parseFloat(wirelessObjs[j].avgRssi) + parseFloat(totalRssi);
                                    rssiTemplate.response.detailList[i].scoreList[2]["value1"] += 1;
                                }
                                else if (wirelessObjs[j].avgRssi <= -45 && wirelessObjs[j].avgRssi >= -55) {
                                    totalRssi = parseFloat(wirelessObjs[j].avgRssi) + parseFloat(totalRssi);
                                    rssiTemplate.response.detailList[i].scoreList[3]["value1"] += 1;
                                }
                                else if (wirelessObjs[j].avgRssi >= -45) {
                                    totalRssi = parseFloat(wirelessObjs[j].avgRssi) + parseFloat(totalRssi);
                                    rssiTemplate.response.detailList[i].scoreList[4]["value1"] += 1;
                                }
                            }
                            rssiTemplate.response.detailList[i]["value2"] = wirelessObjs.length;
                            rssiTemplate.response.detailList[i]["value1"] = parseFloat(totalRssi / 5);
                        }
                        if (siteId == "global") {
                            for (var i = 0; i < recordLen; i++) {
                                var scoreList = rssiTemplate.response.detailList[i].scoreList;
                                var totalRssiObjs = scoreList[0].value1 + scoreList[1].value1 + scoreList[2].value1 + scoreList[3].value1 + scoreList[4].value1;
                             
                                var temp1=tempWireless-totalRssiObjs;
                                var totalCount=tempWireless; var s=[];

                                s[0] = parseInt(temp1 * 0.02);
                                s[1] = parseInt(temp1 * 0.03);
                                s[2] = parseInt(temp1 * 0.33);
                                s[3] = parseInt(temp1 * 0.37);
                                s[4] = temp1 - (s[0] + s[1] + s[2] + s[3]);

                                rssiTemplate.response.detailList[i].value2 = totalCount;
                                for(var j=0;j<5;j++){
                                    rssiTemplate.response.detailList[i].scoreList[j].value1 += s[j];
                                }
                            }
                        }

                        for (var i = 0; i < rssiTemplate.response.detailList.length; i++) {
                            var detailList = rssiTemplate.response.detailList[i];
                            var min = detailList.scoreList[0]["value1"];
                            var max = detailList.scoreList[0]["value1"] + 3;
                            var randNum = UtilityFunctions.getRandomIntForRangeValues(min, max);
                            detailList.scoreList[0]["value1"] = randNum;
                            var totalSum = detailList.scoreList[0]["value1"] + detailList.scoreList[1]["value1"] + detailList.scoreList[2]["value1"] + detailList.scoreList[3]["value1"];
                            if (detailList.value2 - totalSum > 0) {
                                detailList.scoreList[3]["value1"] = detailList.scoreList[3]["value1"] + (detailList.value2 - totalSum);
                            }
                            detailList.scoreList[4]["value1"] = detailList.value2 - (detailList.scoreList[0]["value1"] + detailList.scoreList[1]["value1"] + detailList.scoreList[2]["value1"] + detailList.scoreList[3]["value1"]);
                        }
                     return rssiTemplate;
                    }
                    else if (urlAction.restPayload.kpi == 'SNR') {
                        var snrTemplate = TimestampSnr.TIMESTAMP_SNR;
                        var incrementBy = 0;
                        var startTime = new Date(urlAction.restPayload.typeList.startTime);
                        var endTime = new Date(urlAction.restPayload.typeList.endTime);
                        var record=UtilityFunctions.calculateRecordlen(startTime,endTime);
                        var timeWindow=record.timeWindow;
                        var recordLen=record.recordLen;

                        var score = { "<10": 0, "10 to 15": 0, "15 to 20": 0, "20 to 40": 0, ">=40": 0 };

                        for (var i = 0; i < wirelessObjs.length; i++) {
                            if (wirelessObjs[i].snr <= 10) {
                                score["<10"] += 1;
                            }
                            else if (wirelessObjs[i].snr <= 15 && wirelessObjs[i].snr >= 10) {
                                score["10 to 15"] += 1;
                            }
                            else if (wirelessObjs[i].snr <= 20 && wirelessObjs[i].snr >= 15) {
                                score["15 to 20"] += 1;
                            }
                            else if (wirelessObjs[i].snr <= 40 && wirelessObjs[i].snr >= 20) {
                                score["20 to 40"] += 1;
                            }
                            else if (wirelessObjs[i].snr >= 40) {
                                score[">=40"] += 1;
                            }
                        }

                        snrTemplate.response.detailList = [];
                        for (var i = 0; i < recordLen; i++) {
                            var tTime = UtilityFunctions.incrementDateByMinutes(startTime, incrementBy);
                            incrementBy += timeWindow;
                            snrTemplate.response.detailList[i] = {};
                            snrTemplate.response.detailList[i]["scoreList"] = [];
                            snrTemplate.response.detailList[i]["bucket"] = tTime;
                            snrTemplate.response.detailList[i]["breakdown"] = null;
                            var j = 0, totalScore = 0;
                            for (key in score) {
                                snrTemplate.response.detailList[i].scoreList[j] = {};
                                snrTemplate.response.detailList[i].scoreList[j]["breakdown"] = key;
                                snrTemplate.response.detailList[i].scoreList[j]["value1"] = score[key];
                                snrTemplate.response.detailList[i].scoreList[j]["scoreList"] = [];
                                totalScore += parseInt(score[key]);
                                j++;
                            }
                            snrTemplate.response.detailList[i].value2 = totalScore;
                        }

                        if (siteId == "global") {
                            for (var i = 0; i < recordLen; i++) {
                                var scoreList = snrTemplate.response.detailList[i].scoreList;
                                var totalSNRObjs = 0;
                                for (var j = 0; j < 5; j++) {
                                    totalSNRObjs += scoreList[j].value1;
                                }
                             
                                var temp1=tempWireless-totalSNRObjs;
                                var totalCount=tempWireless;

                                var s=[];
                                snrTemplate.response.detailList[i].value2 = totalCount;
                                s[0] = parseInt(temp1 * 0.01)
                                s[1] = parseInt(temp1 * 0.02);
                                s[2] = parseInt(temp1 * 0.05);
                                s[3] = parseInt(temp1 * 0.50);
                                s[4] = temp1 - (s[0] + s[1] + s[2] + s[3]);
                                for(var j=0;j<5;j++){
                                    snrTemplate.response.detailList[i].scoreList[j].value1 += s[j]; 
                                }
                               }
                        }

                        for (var i = 0; i < snrTemplate.response.detailList.length; i++) {
                            var detailList = snrTemplate.response.detailList[i];
                            var min = detailList.scoreList[0]["value1"];
                            var max = detailList.scoreList[0]["value1"] + 3;
                            var randNum = UtilityFunctions.getRandomIntForRangeValues(min, max);
                            detailList.scoreList[0]["value1"] = randNum;
                            var totalSum = detailList.scoreList[0]["value1"] + detailList.scoreList[1]["value1"] + detailList.scoreList[2]["value1"] + detailList.scoreList[3]["value1"];
                            if (detailList.value2 - totalSum > 0) {
                                detailList.scoreList[3]["value1"] = detailList.scoreList[3]["value1"] + (detailList.value2 - totalSum);
                            }
                            detailList.scoreList[4]["value1"] = detailList.value2 - (detailList.scoreList[0]["value1"] + detailList.scoreList[1]["value1"] + detailList.scoreList[2]["value1"] + detailList.scoreList[3]["value1"]);
                        }
                        return snrTemplate;
                        //return generateData(urlAction, TimestampSnr.TIMESTAMP_SNR);
                    }
                    else if (urlAction.restPayload.kpi == 'DATARATE_N_AC_AX' || urlAction.restPayload.kpi == 'DATARATE_A_B_G') {
                        var datarateTemplate = TimestampDataRate.TIMESTAMP_DATARATE;
                        var incrementBy = 0;
                        var startTime = new Date(urlAction.restPayload.typeList.startTime);
                        var endTime = new Date(urlAction.restPayload.typeList.endTime);
                        var record=UtilityFunctions.calculateRecordlen(startTime,endTime);
                        var timeWindow=record.timeWindow;
                        var recordLen=record.recordLen;
                        var kpi = urlAction.restPayload.kpi;

                        var score = { "<=29": 0, "30 to 150": 0, "151 to 300": 0, "301 to 500": 0, "501 to 1000": 0 , ">=1001" :0 };
                        
                        var wirelessObjs = wirelessObjs.filter(obj => kpi=='DATARATE_N_AC_AX' ? 
                        (SimulationUtility.DATARATE_N_AC_AX.indexOf(obj.dot11Protocol)>-1) : (SimulationUtility.DATARATE_A_B_G.indexOf(obj.dot11Protocol)>-1));

                        for (var i = 0; i < wirelessObjs.length; i++) {
                            if (wirelessObjs[i].dataRate <= 29 && wirelessObjs[i].dataRate > 0) {
                                score["<=29"] += 1;
                            }
                            else if (wirelessObjs[i].dataRate <= 150 && wirelessObjs[i].dataRate >= 30) {
                                score["30 to 150"] += 1;
                            }
                            else if (wirelessObjs[i].dataRate <= 300 && wirelessObjs[i].dataRate >= 151) {
                                score["151 to 300"] += 1;
                            }
                            else if (wirelessObjs[i].dataRate <= 500 && wirelessObjs[i].dataRate >= 301) {
                                score["301 to 500"] += 1;
                            }
                            else if (wirelessObjs[i].dataRate <= 1000 && wirelessObjs[i].dataRate >= 501) {
                                score["501 to 1000"] += 1;
                            }
                            else if (wirelessObjs[i].dataRate >= 1001) {
                                score[">=1001"] += 1;
                            }
                        }

                        datarateTemplate.response.detailList = [];
                        for (var i = 0; i < recordLen; i++) {
                            var tTime = UtilityFunctions.incrementDateByMinutes(startTime, incrementBy);
                            incrementBy += timeWindow;
                            datarateTemplate.response.detailList[i] = {};
                            datarateTemplate.response.detailList[i]["scoreList"] = [];
                            datarateTemplate.response.detailList[i]["bucket"] = tTime;
                            datarateTemplate.response.detailList[i]["breakdown"] = null;
                            var j = 0, totalScore = 0;
                            for (key in score) {
                                datarateTemplate.response.detailList[i].scoreList[j] = {};
                                datarateTemplate.response.detailList[i].scoreList[j]["breakdown"] = key;
                                datarateTemplate.response.detailList[i].scoreList[j]["value1"] = score[key];
                                datarateTemplate.response.detailList[i].scoreList[j]["scoreList"] = [];
                                totalScore += parseInt(score[key]);
                                j++;
                            }
                            datarateTemplate.response.detailList[i].value2 = totalScore;
                        }

                        if (siteId == "global") {
                            for (var i = 0; i < recordLen; i++) {
                                var scoreList = datarateTemplate.response.detailList[i].scoreList;
                                var totalDaterateObjs = 0;
                                for (var j = 0; j < 6; j++) {
                                    totalDaterateObjs += scoreList[j].value1;
                                }
                             
                                var temp1=tempWireless-totalDaterateObjs;
                                var totalCount=tempWireless;

                                var s=[];
                                datarateTemplate.response.detailList[i].value2 = totalCount;
                                s[0] = parseInt(temp1 * 0.03);
                                s[1] = parseInt(temp1 * 0.06);
                                s[2] = parseInt(temp1 * 0.18);
                                s[3] = parseInt(temp1 * 0.15);
                                s[4] = parseInt(temp1 * 0.15);
                                s[5] = temp1 - (s[0] + s[1] + s[2] + s[3] + s[4]);
                                for(var j=0;j<6;j++){
                                    datarateTemplate.response.detailList[i].scoreList[j].value1 += s[j]; 
                                }
                               }
                        }

                        for (var i = 0; i < datarateTemplate.response.detailList.length; i++) {
                            var detailList = datarateTemplate.response.detailList[i];
                            var min = detailList.scoreList[0]["value1"];
                            var max = detailList.scoreList[0]["value1"] + 3;
                            var randNum = UtilityFunctions.getRandomIntForRangeValues(min, max);
                            detailList.scoreList[0]["value1"] = randNum;
                            var totalSum = detailList.scoreList[0]["value1"] + detailList.scoreList[1]["value1"] + detailList.scoreList[2]["value1"] + detailList.scoreList[3]["value1"] + detailList.scoreList[4]["value1"]+detailList.scoreList[5]["value1"];
                            if (detailList.value2 - totalSum > 0) {
                                detailList.scoreList[4]["value1"] = detailList.scoreList[4]["value1"] + (detailList.value2 - totalSum);
                            }
                            detailList.scoreList[5]["value1"] = detailList.value2 - (detailList.scoreList[0]["value1"] + detailList.scoreList[1]["value1"] + detailList.scoreList[2]["value1"] + detailList.scoreList[3]["value1"] + detailList.scoreList[4]["value1"]);
                        }
                        return datarateTemplate;
                        //return generateData(urlAction, TimestampSnr.TIMESTAMP_SNR);
                    }
                    else if (urlAction.restPayload.kpi == 'LINK') {
                        var linkTemplate = TimestampLink.TIMESTAMP_LINK;
                        var incrementBy = 0;
                        var startTime = new Date(urlAction.restPayload.typeList.startTime);
                        var endTime = new Date(urlAction.restPayload.typeList.endTime);
                        var record=UtilityFunctions.calculateRecordlen(startTime,endTime);
                        var timeWindow=record.timeWindow;
                        var recordLen=record.recordLen;

                        var score = { "0": 0, "3": 0, "6": 0, "9": 0 };

                        for (var i = 0; i < wiredObjs.length; i++) {
                            if (wiredObjs[i].healthScore[0].score <= 1 && wiredObjs[i].healthScore[0].score > 0)
                                score["0"] += 1;
                         
                            else if (wiredObjs[i].healthScore[0].score <= 10 && wiredObjs[i].healthScore[0].score > 1)
                                score["9"] += 1;
                        }
                        linkTemplate.response.detailList = [];
                        for (var i = 0; i < recordLen; i++) {
                            var tTime = UtilityFunctions.incrementDateByMinutes(startTime, incrementBy);
                            incrementBy += timeWindow;
                            linkTemplate.response.detailList[i] = {};
                            linkTemplate.response.detailList[i]["scoreList"] = [];
                            linkTemplate.response.detailList[i]["bucket"] = tTime;
                            linkTemplate.response.detailList[i]["breakdown"] = null;
                            var j = 0, totalScore = 0;
                            for (key in score) {
                                linkTemplate.response.detailList[i].scoreList[j] = {};
                                linkTemplate.response.detailList[i].scoreList[j]["breakdown"] = key;
                                linkTemplate.response.detailList[i].scoreList[j]["bucket"] = tTime;
                                linkTemplate.response.detailList[i].scoreList[j]["value1"] = score[key];
                                linkTemplate.response.detailList[i].scoreList[j]["scoreList"] = [];
                                totalScore += parseInt(score[key]);
                                j++;
                            }
                            linkTemplate.response.detailList[i].value2 = totalScore;
                        }

                        if (siteId == "global") {
                            for (var i = 0; i < recordLen; i++) {
                                var scoreList = linkTemplate.response.detailList[i].scoreList;
                                var totalLinkObjs = 0;
                                for (var j = 0; j < 4; j++) {
                                    totalLinkObjs += scoreList[j].value1;
                                }

                                var temp1 = 56 - totalLinkObjs;
                                var totalCount = 56;

                                linkTemplate.response.detailList[i].value2 = totalCount;
                                var temp2 = parseInt(temp1 / 4);
                                var temp3 = temp1 - temp2;
                                //linkTemplate.response.detailList[i].scoreList[2].value1 += temp2;
                                linkTemplate.response.detailList[i].scoreList[3].value1 += temp1;
                            }
                        }
                    for (var i = 0; i < linkTemplate.response.detailList.length; i++) {
                            var detailList = linkTemplate.response.detailList[i];
                            var min = detailList.scoreList[3]["value1"];
                            var max = detailList.scoreList[3]["value1"] + 1;
                            var randNum = UtilityFunctions.getRandomIntForRangeValues(min, max);
                            detailList.scoreList[3]["value1"] = randNum;
                            var totalSum = detailList.scoreList[0]["value1"] + detailList.scoreList[1]["value1"] + detailList.scoreList[2]["value1"] + detailList.scoreList[3]["value1"];
                            if (detailList.value2 - totalSum < 0) {
                                if ((detailList.scoreList[2]["value1"] + (detailList.value2 - totalSum) >= 0)) {
                                    detailList.scoreList[2]["value1"] = detailList.scoreList[2]["value1"] + (detailList.value2 - totalSum);
                                }
                                else {
                                    detailList.scoreList[3]["value1"] = detailList.value2 - (detailList.scoreList[0]["value1"] + detailList.scoreList[1]["value1"] + detailList.scoreList[2]["value1"])
                                }
                             }
                            else {
                                detailList.scoreList[2]["value1"] = detailList.value2 - (detailList.scoreList[0]["value1"] + detailList.scoreList[1]["value1"] + detailList.scoreList[3]["value1"]);
                            }
                        }
                        return linkTemplate;
                    }
                    if(urlAction.restPayload.kpi.indexOf('ROAMING')>=0){
                   
                            var roamingTimestampTemplate = TimestampRoaming.TIMESTAMP_ROAMING;
                            var incrementBy = 0;
                            var startTime = new Date(urlAction.restPayload.typeList.startTime);
                            var endTime = new Date(urlAction.restPayload.typeList.endTime);
                            var record=UtilityFunctions.calculateRecordlen(startTime,endTime);
                        var timeWindow=record.timeWindow;
                        var recordLen=record.recordLen;
                            hosts=hosts.filter(function(itm){return itm.hostType==="WIRELESS"});
                             var score={};

                            if(urlAction.restPayload.kpi==="ROAMING_SUCCESS"){
    
                            score = { "<=300": 0, "301 to 500": 0, "501 to 1000": 0, "1001 to 3000": 0, ">3000": 0, "fail": 0 };
                            
                            for (var i = 0; i < hosts.length; i++) {
                                if (hosts[i].onboarding.maxRoamingDuration <= 300 && hosts[i].onboarding.maxRoamingDuration > 0) {
                                    score["<=300"] += 1;
                                }
                                else if (hosts[i].onboarding.maxRoamingDuration <= 500 && hosts[i].onboarding.maxRoamingDuration > 300) {
                                    score["301 to 500"] += 1;
                                }
                                else if (hosts[i].onboarding.maxRoamingDuration <= 1000 && hosts[i].onboarding.maxRoamingDuration > 500) {
                                    score["501 to 1000"] += 1;
                                }
                                else if (hosts[i].onboarding.maxRoamingDuration <= 3000 && hosts[i].onboarding.maxRoamingDuration > 1000) {
                                    score["1001 to 3000"] += 1;
                                }
                                else if (hosts[i].onboarding.maxRoamingDuration > 3000) {
                                    score[">3000"] += 1;
                                }
                               else if (hosts[i].onboarding.maxRoamingDuration === "0") {
                                    score["fail"] += 1;
                                }
                            }
                        }
                        else if(urlAction.restPayload.kpi==="ROAMING_FAST"){

                            hosts=hosts.filter(function(itm){return itm.onboarding.maxRoamingDuration <= 150})
                            score = { "<=10": 0, "11 to 20": 0, "21 to 50": 0, "51 to 100": 0, "101 to 150": 0, ">150": 0 };
                            
                            for (var i = 0; i < hosts.length; i++) {
                                if (hosts[i].onboarding.maxRoamingDuration <= 10 && hosts[i].onboarding.maxRoamingDuration > 0) {
                                    score["<=10"] += 1;
                                }
                                else if (hosts[i].onboarding.maxRoamingDuration <= 20 && hosts[i].onboarding.maxRoamingDuration > 10) {
                                    score["11 to 20"] += 1;
                                }
                                else if (hosts[i].onboarding.maxRoamingDuration <= 50 && hosts[i].onboarding.maxRoamingDuration > 20) {
                                    score["21 to 50"] += 1;
                                }
                                else if (hosts[i].onboarding.maxRoamingDuration <= 100 && hosts[i].onboarding.maxRoamingDuration > 50) {
                                    score["51 to 100"] += 1;
                                }
                                else if (hosts[i].onboarding.maxRoamingDuration <= 150 && hosts[i].onboarding.maxRoamingDuration > 100) {
                                    score["101 to 150"] += 1;
                                }
                               else if (hosts[i].onboarding.maxRoamingDuration > 150) {
                                    score[">150"] += 1;
                                }
                            }
                         }

                        else if(urlAction.restPayload.kpi==="ROAMING_SLOW"){

                            hosts=hosts.filter(function(itm){return itm.onboarding.maxRoamingDuration >= 300})
                            score = { "<=300": 0, "301 to 500": 0, "501 to 1000": 0, "1001 to 2000": 0, "2001 to 3000": 0, ">3000": 0 };
                            
                            for (var i = 0; i < hosts.length; i++) {
                                if (hosts[i].onboarding.maxRoamingDuration <= 300 && hosts[i].onboarding.maxRoamingDuration > 0) {
                                    score["<=300"] += 1;
                                }
                                else if (hosts[i].onboarding.maxRoamingDuration <= 500 && hosts[i].onboarding.maxRoamingDuration > 300) {
                                    score["301 to 500"] += 1;
                                }
                                else if (hosts[i].onboarding.maxRoamingDuration <= 1000 && hosts[i].onboarding.maxRoamingDuration > 500) {
                                    score["501 to 1000"] += 1;
                                }
                                else if (hosts[i].onboarding.maxRoamingDuration <= 2000 && hosts[i].onboarding.maxRoamingDuration > 1000) {
                                    score["1001 to 2000"] += 1;
                                }
                                else if (hosts[i].onboarding.maxRoamingDuration <= 3000 && hosts[i].onboarding.maxRoamingDuration > 2000) {
                                    score["2001 to 3000"] += 1;
                                }
                                else if (hosts[i].onboarding.maxRoamingDuration > 3000) {
                                    score[">3000"] += 1;
                                }
                           }
                          }

                        else if(urlAction.restPayload.kpi==="ROAMING_CLIENTCOUNT"){
                           var successWirelessObjs=hosts.filter(function(itm){return (itm.healthScore[0].score > 3 && itm.onboarding.maxRoamingDuration >0)});
                            var failureWirelessObjs=hosts.filter(function(itm){return (itm.healthScore[0].score <= 3 && itm.onboarding.maxRoamingDuration >0)});
                             
                            score={"success":successWirelessObjs.length,"fail":failureWirelessObjs.length}
                            roamingTimestampTemplate.response.detailList = [];
                            for (var i = 0; i < recordLen; i++) {
                                var tTime = UtilityFunctions.incrementDateByMinutes(startTime, incrementBy);
                                incrementBy += timeWindow;
                                roamingTimestampTemplate.response.detailList[i] = {};
                                roamingTimestampTemplate.response.detailList[i]["scoreList"] = [];
                                roamingTimestampTemplate.response.detailList[i]["bucket"] = tTime;
                                roamingTimestampTemplate.response.detailList[i]["breakdown"] = null;
                                    var totalScore = 0, j=0;
                                for (key in score) {
                                   roamingTimestampTemplate.response.detailList[i].scoreList[j] = {};
                                    roamingTimestampTemplate.response.detailList[i].scoreList[j]["breakdown"] = key;
                                    roamingTimestampTemplate.response.detailList[i].scoreList[j]["bucket"] = tTime;
                                    roamingTimestampTemplate.response.detailList[i].scoreList[j]["value1"] = score[key];
                                    roamingTimestampTemplate.response.detailList[i].scoreList[j]["value2"] = null;
                                    roamingTimestampTemplate.response.detailList[i].scoreList[j]["scoreList"] = [];
                                    totalScore += parseInt(score[key]);
                                    j++;
                                }
                                 roamingTimestampTemplate.response.detailList[i].value2 = totalScore;
                            }
                        }
                        if(urlAction.restPayload.kpi!="ROAMING_CLIENTCOUNT"){
                            roamingTimestampTemplate.response.detailList = [];
                            for (var i = 0; i < recordLen; i++) {
                                var tTime = UtilityFunctions.incrementDateByMinutes(startTime, incrementBy);
                                incrementBy += timeWindow;
                                roamingTimestampTemplate.response.detailList[i] = {};
                                roamingTimestampTemplate.response.detailList[i]["scoreList"] = [];
                                roamingTimestampTemplate.response.detailList[i]["bucket"] = tTime;
                                roamingTimestampTemplate.response.detailList[i]["breakdown"] = null;
                                var j = 0, totalScore = 0;
                                for (key in score) {
                                    roamingTimestampTemplate.response.detailList[i].scoreList[j] = {};
                                    roamingTimestampTemplate.response.detailList[i].scoreList[j]["breakdown"] = key;
                                    roamingTimestampTemplate.response.detailList[i].scoreList[j]["bucket"] = tTime;
                                    roamingTimestampTemplate.response.detailList[i].scoreList[j]["value1"] = score[key];
                                    roamingTimestampTemplate.response.detailList[i].scoreList[j]["value2"] = null;
                                    roamingTimestampTemplate.response.detailList[i].scoreList[j]["scoreList"] = [];
                                    totalScore += parseInt(score[key]);
                                    j++;
                                }
                                roamingTimestampTemplate.response.detailList[i].value2 = totalScore;
                            }
                        }
                        if (siteId == "global") {

                                for (var i = 0; i < recordLen; i++) {
                                    var totalRoamingObjs = 0;
                                    if(urlAction.restPayload.kpi==="ROAMING_CLIENTCOUNT"){
                                        var scoreList = roamingTimestampTemplate.response.detailList[i].scoreList;
                                        for (var j = 0; j < 2; j++) {
                                            totalRoamingObjs += scoreList[j].value1;
                                        }
                                        roamingTimestampTemplate.response.detailList[i].scoreList[0].value1+=tempWireless-totalRoamingObjs;
                                        roamingTimestampTemplate.response.detailList[i].value2 = tempWireless;
                                    }
                                    else{
                                    var scoreList = roamingTimestampTemplate.response.detailList[i].scoreList;
                                    for (var j = 0; j < 6; j++) {
                                        totalRoamingObjs += scoreList[j].value1;
                                    }
                             
                                   var temp1=tempWireless;
                                   var s=[];  
                                var detailList = roamingTimestampTemplate.response.detailList[i].scoreList;
                                s[1] = (detailList[1].value1 + Math.floor(temp1 * 0.04)) < 10 ? (detailList[1].value1 + Math.floor(temp1 * 0.04)) : 10;
                                s[2] = (detailList[2].value1 + Math.floor(temp1 * 0.02)) < 4 ? (detailList[2].value1 + Math.floor(temp1 * 0.02)) : 4;
                                s[3] = (detailList[3].value1 + Math.floor(temp1 * 0.02)) < 4 ? (detailList[3].value1 + Math.floor(temp1 * 0.02)) : 4;
                                s[4] = (detailList[4].value1 + Math.floor(temp1 * 0.03)) < 5 ? (detailList[4].value1 + Math.floor(temp1 * 0.03)) : 5;
                                s[5] =detailList[5].value1;
    
                                s[1]=detailList[1].value1>s[1]?detailList[1].value1:s[1];
                                s[2]=detailList[2].value1>s[2]?detailList[2].value1:s[2];
                                s[3]=detailList[3].value1>s[3]?detailList[3].value1:s[3];
                                s[4]=detailList[4].value1>s[4]?detailList[4].value1:s[4];
                              
                                s[0] = temp1 - (s[1] + s[2] + s[3] + s[4] + s[5] );
    
                                    roamingTimestampTemplate.response.detailList[i].value2 = tempWireless;
                                    for(var j=0;j<6;j++){
                                        roamingTimestampTemplate.response.detailList[i].scoreList[j].value1 = s[j];
                                    }
                                  }  
                                }
                            }
    
                            for (var i = 0; i < roamingTimestampTemplate.response.detailList.length; i++) {
                                var detailList = roamingTimestampTemplate.response.detailList[i];
                                var min = detailList.scoreList[0]["value1"];
                                var max=0, totalSum=0;
                                if(urlAction.restPayload.kpi!="ROAMING_CLIENTCOUNT"){
                                    max=detailList.scoreList[0]["value1"] - 10;
                                    totalSum = detailList.scoreList[0]["value1"] + detailList.scoreList[1]["value1"] + detailList.scoreList[2]["value1"] + detailList.scoreList[3]["value1"] + detailList.scoreList[4]["value1"] + detailList.scoreList[5]["value1"];
                               
                                var randNum = UtilityFunctions.getRandomIntForRangeValues(min, max);
                                detailList.scoreList[0]["value1"] = randNum;
                                var diffValue = detailList.value2 - totalSum;
                                if (diffValue > 0) {
    
                                    detailList.scoreList[1]["value1"] = detailList.scoreList[1]["value1"] + diffValue;
                                }
                            }
                            }
                          return roamingTimestampTemplate;
                        }   
                     }
                  return [];
            },
         };

        function manageGlobalCount(detailList,tempWireless,tempWired,totalCountflag){
            var s=[];
            var temp1=(totalCountflag===true)?tempWireless+tempWired:tempWireless;
           
            s[1] = (detailList[1].value1 + Math.floor(temp1 * 0.04)) < 10 ? (detailList[1].value1 + Math.floor(temp1 * 0.04)) : 10;
            s[2] = (detailList[2].value1 + Math.floor(temp1 * 0.02)) < 4 ? (detailList[2].value1 + Math.floor(temp1 * 0.02)) : 4;
            s[3] = (detailList[3].value1 + Math.floor(temp1 * 0.02)) < 4 ? (detailList[3].value1 + Math.floor(temp1 * 0.02)) : 4;
            s[4] = (detailList[4].value1 + Math.floor(temp1 * 0.03)) < 5 ? (detailList[4].value1 + Math.floor(temp1 * 0.03)) : 5;
            s[5] = (detailList[5].value1 + Math.floor(temp1 * 0.03)) < 5 ? (detailList[5].value1 + Math.floor(temp1 * 0.03)) : 5;
            s[6] = detailList[6].value1;

           s[1]=detailList[1].value1>s[1]?detailList[1].value1:s[1];
            s[2]=detailList[2].value1>s[2]?detailList[2].value1:s[2];
            s[3]=detailList[3].value1>s[3]?detailList[3].value1:s[3];
            s[4]=detailList[4].value1>s[4]?detailList[4].value1:s[4];
            s[5]=detailList[5].value1>s[5]?detailList[5].value1:s[5];

            s[0] = temp1 - (s[1] + s[2] + s[3] + s[4] + s[5] + s[6]);
                      
            if(totalCountflag===false){
               var temp1=tempWired;
               s[8] = (detailList[8].value1 + Math.floor(temp1 * 0.04)) < 3 ? (detailList[8].value1 + Math.floor(temp1 * 0.04)) : 3;
               s[9] = (detailList[9].value1 + Math.floor(temp1 * 0.02)) < 1 ? (detailList[9].value1 + Math.floor(temp1 * 0.02)) : 1;
               s[10] = (detailList[10].value1 + Math.floor(temp1 * 0.02)) < 1 ? (detailList[10].value1 + Math.floor(temp1 * 0.02)) : 1;
               s[11] = (detailList[11].value1 + Math.floor(temp1 * 0.02)) < 4 ? (detailList[11].value1 + Math.floor(temp1 * 0.02)) : 4;
               s[12] = (detailList[12].value1 + Math.floor(temp1 * 0.02)) < 3 ? (detailList[12].value1 + Math.floor(temp1 * 0.02)) : 3;
               s[13] = detailList[13].value1;
               s[8]=detailList[8].value1>s[8]?detailList[8].value1:s[8];
               s[9]=detailList[9].value1>s[9]?detailList[9].value1:s[9];
               s[10]=detailList[10].value1>s[10]?detailList[10].value1:s[10];
               s[11]=detailList[11].value1>s[11]?detailList[11].value1:s[11];
               s[12]=detailList[12].value1>s[12]?detailList[12].value1:s[12];
               s[7] = temp1 - (s[8] + s[9] + s[10] + s[11] + s[12] + s[13]);
              }
            return s;

        }

        function filterClientWithSSID(ssids,hosts){
            var tempList=[];
            for(var i=0;i<ssids.length;i++){
                for(var j=0;j<hosts.length;j++){
                    if(hosts[j].ssid===ssids[i]){
                        tempList.push(hosts[j]);
                    }
                }
            }
            return tempList;
        }

        function onboardingTimestampFilter(hosts,score,onboarding,onboardingKey){
            if(onboarding===false){
            for (var i = 0; i < hosts.length; i++) {
                if (hosts[i].onboarding[onboardingKey] < 1000 && hosts[i].onboarding[onboardingKey] > 0) {
                    score["<1"] += 1;
                }
                else if (hosts[i].onboarding[onboardingKey] >= 1000 && hosts[i].onboarding[onboardingKey] < 2000) {
                    score["1 to 2"] += 1;
                }
                else if (hosts[i].onboarding[onboardingKey] >= 2000 && hosts[i].onboarding[onboardingKey] < 3000) {
                    score["2 to 3"] += 1;
                }
                else if (hosts[i].onboarding[onboardingKey] >= 3000 && hosts[i].onboarding[onboardingKey] < 4000) {
                    score["3 to 4"] += 1;
                }
                else if (hosts[i].onboarding[onboardingKey] >= 4000 && hosts[i].onboarding[onboardingKey] < 50000) {
                    score["4 to 5"] += 1;
                }
                else if (hosts[i].onboarding[onboardingKey] >= 5000) {
                    score[">=5"] += 1;
                }
                else if (hosts[i].onboarding[onboardingKey] === "0") {
                    score["fail"] += 1;
                }
            }
        }
        else if(onboarding===true){
            for (var i = 0; i < hosts.length; i++) {
                if (hosts[i].onboarding.maxRunDuration < 2000 && hosts[i].onboarding.maxRunDuration > 0) {
                    score["<2"] += 1;
                }
                else if (hosts[i].onboarding.maxRunDuration >= 2000 && hosts[i].onboarding.maxRunDuration < 4000) {
                    score["2 to 4"] += 1;
                }
                else if (hosts[i].onboarding.maxRunDuration >= 4000 && hosts[i].onboarding.maxRunDuration < 6000) {
                    score["4 to 6"] += 1;
                }
                else if (hosts[i].onboarding.maxRunDuration >= 6000 && hosts[i].onboarding.maxRunDuration < 8000) {
                    score["6 to 8"] += 1;
                }
                else if (hosts[i].onboarding.maxRunDuration >= 8000 && hosts[i].onboarding.maxRunDuration < 10000) {
                    score["8 to 10"] += 1;
                }
                else if (hosts[i].onboarding.maxRunDuration >= 10000) {
                    score[">=10"] += 1;
                }
                else if (hosts[i].onboarding.maxRunDuration === "0") {
                    score["fail"] += 1;
                }
            }
        }
        return score;
       }

        function filterWithOnboardingKPI(detailList,wobjs,deviceType,onboarding,onboardingKey){
            var deviceRootCause = [];
            var deviceIssues = SimLokiDatabaseActions.getAll('issue');
            for (var i = 0; i < wobjs.length; i++) {
                if ((onboarding===true && wobjs[i].onboarding.maxRunDuration < 2000 && wobjs[i].onboarding.maxRunDuration > 0)||
                    (onboarding===false && wobjs[i].onboarding[onboardingKey] < 1000 && wobjs[i].onboarding[onboardingKey] > 0)) {
                    detailList[0].value1 += 1;
                    if(onboarding===true){
                    detailList[0].scoreList[0].value1 += parseFloat(wobjs[i].onboarding.maxAssocDuration);
                    detailList[0].scoreList[1].value1 += parseFloat(wobjs[i].onboarding.maxAuthDuration);
                    detailList[0].scoreList[2].value1 += parseFloat(wobjs[i].onboarding.maxDhcpDuration);
                    }
                }
                else if ((onboarding===true && wobjs[i].onboarding.maxRunDuration < 4000 && wobjs[i].onboarding.maxRunDuration >= 2000)||
                (onboarding===false && wobjs[i].onboarding[onboardingKey] < 2000 && wobjs[i].onboarding[onboardingKey] >= 1000)) {
                    detailList[1].value1 += 1;
                    if(onboarding===true){
                    detailList[1].scoreList[0].value1 += parseFloat(wobjs[i].onboarding.maxAssocDuration);
                    detailList[1].scoreList[1].value1 += parseFloat(wobjs[i].onboarding.maxAuthDuration);
                    detailList[1].scoreList[2].value1 += parseFloat(wobjs[i].onboarding.maxDhcpDuration);
                    }

                }
                else if ((onboarding===true && wobjs[i].onboarding.maxRunDuration < 6000 && wobjs[i].onboarding.maxRunDuration >= 4000)||
                (onboarding===false && wobjs[i].onboarding[onboardingKey] < 3000 && wobjs[i].onboarding[onboardingKey] >= 2000)) {
                    detailList[2].value1 += 1;
                    if(onboarding===true){
                    detailList[2].scoreList[0].value1 += parseFloat(wobjs[i].onboarding.maxAssocDuration);
                    detailList[2].scoreList[1].value1 += parseFloat(wobjs[i].onboarding.maxAuthDuration);
                    detailList[2].scoreList[2].value1 += parseFloat(wobjs[i].onboarding.maxDhcpDuration);
                    }

                }
                else if ((onboarding===true && wobjs[i].onboarding.maxRunDuration < 8000 && wobjs[i].onboarding.maxRunDuration >= 6000)||
                (onboarding===false && wobjs[i].onboarding[onboardingKey] < 4000 && wobjs[i].onboarding[onboardingKey] >= 3000)) {
                    detailList[3].value1 += 1;
                    if(onboarding===true){
                    detailList[3].scoreList[0].value1 += parseFloat(wobjs[i].onboarding.maxAssocDuration);
                    detailList[3].scoreList[1].value1 += parseFloat(wobjs[i].onboarding.maxAuthDuration);
                    detailList[3].scoreList[2].value1 += parseFloat(wobjs[i].onboarding.maxDhcpDuration);
                    }

                }
                else if ((onboarding===true && wobjs[i].onboarding.maxRunDuration < 10000 && wobjs[i].onboarding.maxRunDuration >= 8000)||
                (onboarding===false && wobjs[i].onboarding[onboardingKey] < 5000 && wobjs[i].onboarding[onboardingKey] >= 4000)) {
                    detailList[4].value1 += 1;
                    if(onboarding===true){
                    detailList[4].scoreList[0].value1 += parseFloat(wobjs[i].onboarding.maxAssocDuration);
                    detailList[4].scoreList[1].value1 += parseFloat(wobjs[i].onboarding.maxAuthDuration);
                    detailList[4].scoreList[2].value1 += parseFloat(wobjs[i].onboarding.maxDhcpDuration);
                    }
                }
                else if ((onboarding===true && wobjs[i].onboarding.maxRunDuration >= 10000 )||
                (onboarding===false && wobjs[i].onboarding[onboardingKey] >= 5000 )) {
                    detailList[5].value1 += 1;
                    if(onboarding===true){
                    detailList[5].scoreList[0].value1 += parseFloat(wobjs[i].onboarding.maxAssocDuration);
                    detailList[5].scoreList[1].value1 += parseFloat(wobjs[i].onboarding.maxAuthDuration);
                    detailList[5].scoreList[2].value1 += parseFloat(wobjs[i].onboarding.maxDhcpDuration);
                    }

                }
                else if ((onboarding===true && wobjs[i].onboarding.maxRunDuration === "0")||
                (onboarding===false && wobjs[i].onboarding[onboardingKey] === "0")) {
                    detailList[6].value1 += 1;
                    detailList[6].scoreList[0].value1 =0;
                    if(onboarding===true){
                    if(wobjs[i].onboarding.maxAuthDuration=== "0") 
                    detailList[6].scoreList[1].value1+=1;

                    if(wobjs[i].onboarding.maxDhcpDuration=== "0") 
                    detailList[6].scoreList[2].value1+=1;

                    if(wobjs[i].onboarding.maxAssocDuration=== "0")
                    detailList[6].scoreList[3].value1+=1;
                    }
                    else if(onboarding===false && deviceType==="wireless"){
                        deviceRootCause.push (deviceIssues.filter(function( obj ) {
                            return obj.entity === wobjs[i].id;
                        }).map(function( obj ) {
                            return obj.rootCause;
                        }));
                    }
                }
              }
              
              return { detailList:detailList,deviceRootCause:deviceRootCause};
            }

        function getClientsByProtocolKpi(urlAction, filteredHostList) {
            var timestamp = UtilityFunctions.getGivenTimeInGivenFormat(urlAction.restPayload.endTime, 'YYYY-MM-DDTHH:mm:ss.SSSZZ');
            var detailList = [], counts = {};
            var protocols = SimulationUtility.PROTOCOLS.map(p => p.dnacVal);
            for(var i=0; i<protocols.length; i++) {
                var hosts = filteredHostList.filter(e => e.dot11Protocol == protocols[i]);
                counts[protocols[i]] = hosts.length;
            }

            if(counts['PROTOCOL_802_11_A']!=0 || counts['PROTOCOL_802_11_B']!=0 || counts['PROTOCOL_802_11_G']!=0) {
                var obj = {"scoreList":[], "bucket":timestamp, "breakdown":"1.0", "value1":0, "value2":null};
                if(counts['PROTOCOL_802_11_A']!=0) {
                    var obj2 = {"scoreList":[], "bucket":timestamp, "breakdown":"1.0", "value1":counts['PROTOCOL_802_11_A'], "value2":null};
                    obj.scoreList.push(obj2);
                    obj.value1 += counts['PROTOCOL_802_11_A']
                }
                if(counts['PROTOCOL_802_11_B']!=0) {
                    var obj2 = {"scoreList":[], "bucket":timestamp, "breakdown":"2.0", "value1":counts['PROTOCOL_802_11_B'], "value2":null};
                    obj.scoreList.push(obj2);
                    obj.value1 += counts['PROTOCOL_802_11_B']
                }
                if(counts['PROTOCOL_802_11_G']!=0) {
                    var obj2 = {"scoreList":[], "bucket":timestamp, "breakdown":"3.0", "value1":counts['PROTOCOL_802_11_G'], "value2":null};
                    obj.scoreList.push(obj2);
                    obj.value1 += counts['PROTOCOL_802_11_G']
                }
                detailList.push(obj);
            }
            if(counts['PROTOCOL_802_11_N_24']!=0 || counts['PROTOCOL_802_11_N_5']!=0) {
                var obj = {"scoreList":[], "bucket":timestamp, "breakdown":"2.0", "value1":0, "value2":null};
                if(counts['PROTOCOL_802_11_N_24']!=0) {
                    var obj2 = {"scoreList":[], "bucket":timestamp, "breakdown":"4.0", "value1":counts['PROTOCOL_802_11_N_24'], "value2":null};
                    obj.scoreList.push(obj2);
                    obj.value1 += counts['PROTOCOL_802_11_N_24']
                }
                if(counts['PROTOCOL_802_11_N_5']!=0) {
                    var obj2 = {"scoreList":[], "bucket":timestamp, "breakdown":"5.0", "value1":counts['PROTOCOL_802_11_N_5'], "value2":null};
                    obj.scoreList.push(obj2);
                    obj.value1 += counts['PROTOCOL_802_11_N_5']
                }
                detailList.push(obj);
            }
            if(counts['PROTOCOL_802_11_AC']!=0) {//3.0 & 4.0 seemed same. so we choose only 4.0.
                var obj = {"scoreList":[], "bucket":timestamp, "breakdown":"4.0", "value1":0, "value2":null};
                var obj2 = {"scoreList":[], "bucket":timestamp, "breakdown":"6.0", "value1":counts['PROTOCOL_802_11_AC'], "value2":null};
                obj.scoreList.push(obj2);
                obj.value1 += counts['PROTOCOL_802_11_AC']
                detailList.push(obj);
            }
            if(counts['PROTOCOL_802_11_AX_24']!=0 || counts['PROTOCOL_802_11_AX_5']!=0) {
                var obj = {"scoreList":[], "bucket":timestamp, "breakdown":"5.0", "value1":0, "value2":null};
                if(counts['PROTOCOL_802_11_AX_24']!=0) {
                    var obj2 = {"scoreList":[], "bucket":timestamp, "breakdown":"7.0", "value1":counts['PROTOCOL_802_11_AX_24'], "value2":null};
                    obj.scoreList.push(obj2);
                    obj.value1 += counts['PROTOCOL_802_11_AX_24']
                }
                if(counts['PROTOCOL_802_11_AX_5']!=0) {
                    var obj2 = {"scoreList":[], "bucket":timestamp, "breakdown":"8.0", "value1":counts['PROTOCOL_802_11_AX_5'], "value2":null};
                    obj.scoreList.push(obj2);
                    obj.value1 += counts['PROTOCOL_802_11_AX_5']
                }
                /* NEED FIX in next release: to have wifi6 clients mapped to non-wifi6 APs in the donut of Client distribution by 
                Capability, we are temporarily adding 11ac clients too here. This is a temporariry last min fix added. For the 
                solution to work E2E, we need to look into other way of handling, may be in next release. 
                Comment added in Wolverine release. */
                if(counts['PROTOCOL_802_11_AC']!=0) {
                    var obj2 = {"scoreList":[], "bucket":timestamp, "breakdown":"6.0", "value1":counts['PROTOCOL_802_11_AC'], "value2":null};
                    obj.scoreList.push(obj2);
                    obj.value1 += counts['PROTOCOL_802_11_AC']
                }
                //NEED FIX: ends.
                detailList.push(obj);
            }
            if(counts['PROTOCOL_802_11_AX_6']!=0) {
                var obj = {"scoreList":[], "bucket":timestamp, "breakdown":"7.0", "value1":0, "value2":null};
                if(counts['PROTOCOL_802_11_AX_6']!=0) {
                    var obj2 = {"scoreList":[], "bucket":timestamp, "breakdown":"8.0", "value1":counts['PROTOCOL_802_11_AX_6'], "value2":null};
                    obj.scoreList.push(obj2);
                    obj.value1 += counts['PROTOCOL_802_11_AX_6']
                }
                detailList.push(obj);
            }
            return detailList;
        }

        function generateData(urlAction, data) {
            var i = 0, timeWindow = 30;
            var recordLen = data.response.detailList.length;
            var incrementBy = 0, tOrder = 'asc';
            var startTime = urlAction.restPayload.typeList.startTime;
            var endTime = urlAction.restPayload.typeList.endTime;

            var startDate = UtilityFunctions.getAdjustStartDateForDataGeneration(startTime, endTime, tOrder, timeWindow);
            for (; i < recordLen; i++) {
                var tTime = UtilityFunctions.incrementDateByMinutes(startDate, incrementBy);
                var tTimeInMs = new Date(tTime).getTime();
                var formattedTime = UtilityFunctions.getGivenDateAndTimeInValidFormat(tTimeInMs);
                data.response.detailList[i].bucket = formattedTime;
                for (var j = 0; j < data.response.detailList[i].scoreList.length; j++) {
                    data.response.detailList[i].scoreList[j].bucket = formattedTime;
                }
                incrementBy += timeWindow;
            }
            return data;
        }
        
    });
