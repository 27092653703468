define({

    "REASONER_METADATA": [
      {
        "ontologyVersionIri": "http://www.cisco.com/CNSR/FabricDataCollection/1.2.0",
        "validated": true,
        "reasonerTool": true,
        "displayName": "Fabric Data Collection",
        "uiConfigs": {
          "iconClass": "dnac-icon-farbric-filled",
          "element_type": "device",
          "view": "all",
          "multiSelect": true,
          "bootstrapDevices": true,
          "reasonerProps": []
        },
        "description": "Collect show command outputs from network devices to troubleshoot issues in a Fabric network",
        "id": "fabric_data_collection",
        "deviceLimit": 100,
        "continueOnError": true,
        "networkImpact": "XS"
      },
      {
        "ontologyVersionIri": "http://www.cisco.com/CNSR/TestPingNFLoop/0.0.0",
        "displayName": "PingTestNF",
        "description": "Algorithm to start to ping",
        "id": "test_loop_ping",
        "networkImpact": "S"
      },
      {
        "ontologyVersionIri": "http://www.cisco.com/CNSR/ClientCountSimple/2.1.0",
        "validated": true,
        "reasonerTool": true,
        "displayName": "Client Count",
        "uiConfigs": {
          "iconClass": "dnac-icon-clients2",
          "element_type": "generic",
          "view": "all",
          "reasonerProps": [
            {
              "dataKey": "managementIpAddress",
              "inputKey": "deviceIP"
            }
          ]
        },
        "description": "Check client endpoints total is within Cisco DNA Center/SDA scale limits",
        "id": "client_count_simple",
        "networkImpact": "S",
        "tags": [
          "CX"
        ]
      },
      {
        "ontologyVersionIri": "http://www.cisco.com/CNSR/PingTest/1.0.0",
        "validated": true,
        "reasonerTool": true,
        "uiConfigs": {
          "iconClass": "dnac-icon-commandRunner",
          "element_type": "device",
          "view": "all",
          "multiSelect": false,
          "bootstrapDevices": true,
          "reasonerProps": []
        },
        "displayName": "Ping Device",
        "description": "Ping a target IP Address",
        "userInputFields": [
          {
            "inputKey": "IPAddress",
            "type": "textfield",
            "validator": "ipv4v6",
            "label": "Target IP Address"
          }
        ],
        "id": "ping",
        "continueOnError": true,
        "networkImpact": "XS"
      },
      {
        "ontologyVersionIri": "http://www.cisco.com/CNSR/DSigAnalyzerUber/1.0.0",
        "id": "psirt",
        "continueOnError": true,
        "noLimitEventFiredCheck": true
      },
      {
        "ontologyVersionIri": "http://www.cisco.com/CNSR/SDASwReadinessCheck/1.3.0",
        "validated": true,
        "reasonerTool": true,
        "displayName": "SDA Software Readiness Check",
        "uiConfigs": {
          "iconClass": "dnac-icon-clients2",
          "element_type": "generic",
          "view": "all",
          "reasonerProps": [
            {
              "dataKey": "managementIpAddress",
              "inputKey": "deviceIP"
            }
          ]
        },
        "description": "Check Sw is ready for SDA",
        "id": "sda_sw_readiness_check",
        "continueOnError": true,
        "networkImpact": "S",
        "tags": [
          "CX"
        ]
      },
      {
        "ontologyVersionIri": "http://www.cisco.com/CNSR/dnac-bug-detection-uber/1.0.0",
        "validated": false,
        "reasonerTool": true,
        "displayName": "System Bug Identifier",
        "uiConfigs": {
          "route": "/dna/system-bug-identifier/overview"
        },
        "description": "Identify bugs that apply to your Cisco DNA Center",
        "id": "dnac_cfd_detection",
        "continueOnError": true,
        "networkImpact": "S",
        "noLimitEventFiredCheck": true,
        "tags": []
      },
      {
        "ontologyVersionIri": "http://www.cisco.com/CNSR/DSigAnalyzerUber/1.0.0",
        "id": "psirt_default",
        "continueOnError": true,
        "noLimitEventFiredCheck": true
      },
      {
        "ontologyVersionIri": "http://www.cisco.com/CNSR/FindNeighboringDevice/1.4.0",
        "validated": true,
        "reasonerTool": true,
        "uiConfigs": {
          "timeInterval": 24,
          "countLabel": "Affected Devices (Last 24 hours)",
          "filterTitle": "Interface Down",
          "filterHelpText": "Interface Down is for the last 24 hours",
          "iconClass": "dnac-icon-interface",
          "element_type": "device",
          "bootstrapDevices": true,
          "apiProps": {
            "identifier": "deviceUuid",
            "payloadItems": "data",
            "url": "/api/ndp/v1/data/store/app/assurance_issue_triggers/query",
            "version": "none",
            "type": "POST",
            "data": {
              "query": "SELECT * from assurance_issue_triggers where _name in ('infra_link_down') and severity = 'HIGH' and status = 'active' and __timestamp > {startTimestamp} and __timestamp < {endTimestamp} ORDER BY __timestamp DESC"
            }
          },
          "affectedObject": {
            "label": "Affected Devices",
            "timeIntervalLabel": "last 24 hrs",
            "timeInterval": 24,
            "apiProps": {
              "identifier": "deviceUuid",
              "payloadItems": "data",
              "url": "/api/ndp/v1/data/store/app/assurance_issue_triggers/query",
              "version": "none",
              "type": "POST",
              "data": {
                "query": "SELECT * from assurance_issue_triggers where _name in ('infra_link_down') and severity = 'HIGH' and status = 'active' and __timestamp > {startTimestamp} and __timestamp < {endTimestamp} ORDER BY __timestamp DESC"
              }
            }
          },
          "reasonerProps": [
            {
              "dataKey": "managementIpAddress",
              "inputKey": "inputDeviceIp"
            }
          ],
          "userInputFields": [
            {
              "inputKey": "inputInterfaceName",
              "type": "interface"
            },
            {
              "label": "Time Range",
              "type": "interface_flap_time_range"
            }
          ]
        },
        "displayName": "Interface Down",
        "description": "Troubleshoot causes for Interface down on a network device",
        "id": "interface_flap",
        "continueOnError": true,
        "networkImpact": "L"
      },
      {
        "ontologyVersionIri": "http://www.cisco.com/CNSR/MTULinkCheck/1.0.0",
        "validated": true,
        "reasonerTool": true,
        "displayName": "MTU Link Check",
        "uiConfigs": {
          "iconClass": "dnac-icon-switch-monochrome",
          "element_type": "device",
          "view": "all",
          "reasonerProps": [
            {
              "dataKey": "managementIpAddress",
              "inputKey": "ipAddress"
            }
          ]
        },
        "description": "Checks the MTU settings for connections between fabric capable devices (primarily the Switches within the network)",
        "id": "mtu_link_check",
        "continueOnError": true,
        "networkImpact": "XS",
        "tags": [
          "CX"
        ]
      },
      {
        "ontologyVersionIri": "http://www.cisco.com/CNSR/topLevelCPUTroubleshoot/1.2.0",
        "validated": true,
        "reasonerTool": true,
        "displayName": "CPU Utilization",
        "uiConfigs": {
          "timeInterval": 24,
          "countLabel": "Affected Devices (Last 24 hours)",
          "filterTitle": "High CPU Utilization",
          "filterHelpText": "High CPU Utilization is for the last 24 hours",
          "iconClass": "dnac-icon-ipPool-sm",
          "element_type": "device",
          "apiProps": {
            "identifier": "deviceUuid",
            "payloadItems": "data",
            "url": "/api/ndp/v1/data/store/app/assurance_issue_triggers/query",
            "version": "none",
            "type": "POST",
            "data": {
              "query": "SELECT * from assurance_issue_triggers where _name in ('snmp_cpu_high_80', 'switch_cpu_high', 'router_cpu_high') and severity = 'HIGH' and status = 'active' and __timestamp > {startTimestamp} and __timestamp < {endTimestamp} ORDER BY __timestamp DESC"
            }
          },
          "affectedObject": {
            "label": "Affected Devices",
            "timeIntervalLabel": "last 24 hrs",
            "timeInterval": 24,
            "apiProps": {
              "identifier": "deviceUuid",
              "payloadItems": "data",
              "url": "/api/ndp/v1/data/store/app/assurance_issue_triggers/query",
              "version": "none",
              "type": "POST",
              "data": {
                "query": "SELECT * from assurance_issue_triggers where _name in ('snmp_cpu_high_80', 'switch_cpu_high', 'router_cpu_high') and severity = 'HIGH' and status = 'active' and __timestamp > {startTimestamp} and __timestamp < {endTimestamp} ORDER BY __timestamp DESC"
              }
            }
          },
          "reasonerProps": [
            {
              "dataKey": "managementIpAddress",
              "inputKey": "deviceIP"
            }
          ],
          "userInputFields": [
            {
              "inputKey": "procCpuUtilThreshold",
              "type": "percentage",
              "label": "CPU Utilization Threshold"
            }
          ]
        },
        "description": "Troubleshoot causes for high CPU Utilization on a device",
        "id": "cpu_utilization",
        "continueOnError": true,
        "networkImpact": "XS"
      },
      {
        "ontologyVersionIri": "http://www.cisco.com/CNSR/PSUTroubleshooting/1.2.0",
        "validated": true,
        "reasonerTool": true,
        "displayName": "Power Supply",
        "uiConfigs": {
          "timeInterval": 24,
          "countLabel": "Affected Devices (Last 24 hours)",
          "filterTitle": "Power Supply Failure",
          "filterHelpText": "Power Supply Failure is for the last 24 hours",
          "iconClass": "dnac-icon-wiredDevice_disconnected",
          "element_type": "device",
          "apiProps": {
            "identifier": "deviceUuid",
            "payloadItems": "data",
            "url": "/api/ndp/v1/data/store/app/assurance_issue_triggers/query",
            "version": "none",
            "type": "POST",
            "data": {
              "query": "SELECT * from assurance_issue_triggers where _name in ('switch_issue_power') and severity = 'HIGH' and status = 'active' and __timestamp > {startTimestamp} and __timestamp < {endTimestamp} ORDER BY __timestamp DESC"
            }
          },
          "affectedObject": {
            "label": "Affected Devices",
            "timeIntervalLabel": "last 24 hrs",
            "timeInterval": 24,
            "apiProps": {
              "identifier": "deviceUuid",
              "payloadItems": "data",
              "url": "/api/ndp/v1/data/store/app/assurance_issue_triggers/query",
              "version": "none",
              "type": "POST",
              "data": {
                "query": "SELECT * from assurance_issue_triggers where _name in ('switch_issue_power') and severity = 'HIGH' and status = 'active' and __timestamp > {startTimestamp} and __timestamp < {endTimestamp} ORDER BY __timestamp DESC"
              }
            }
          },
          "reasonerProps": [
            {
              "dataKey": "managementIpAddress",
              "inputKey": "deviceIP"
            }
          ]
        },
        "description": "Determine the root cause for power supply problems on a device. ",
        "id": "power_supply",
        "continueOnError": true,
        "networkImpact": "XS"
      },
      {
        "ontologyVersionIri": "http://www.cisco.com/CNSR/ConnectivityValidationSDAMain/1.0.0",
        "validated": true,
        "reasonerTool": true,
        "uiConfigs": {
          "timeInterval": 24,
          "filterTitle": "Connectivity failures",
          "filterHelpText": "Connectivity failures for the last 24 hours",
          "element_type": "device",
          "view": "all",
          "iconClass": "dnac-icon-network-cloud-fill",
          "reasonerProps": [
            {
              "inputKey": "deviceIP",
              "dataKey": "managementIpAddress"
            }
          ],
          "userInputFields": [
            {
              "inputKey": "destIP",
              "validator": "ipv4v6",
              "label": "Destination IP address",
              "type": "textfield"
            },
            {
              "inputKey": "vrf",
              "label": "Enter VRF name",
              "required": false,
              "type": "textfield"
            }
          ]
        },
        "displayName": "Network Connectivity",
        "description": "Check any network connectivity failures to the destination",
        "id": "connectivity",
        "continueOnError": true,
        "networkImpact": "S"
      },
      {
        "ontologyVersionIri": "http://www.cisco.com/CNSR/network-bug-detection-uber/1.0.0",
        "validated": false,
        "reasonerTool": true,
        "displayName": "Network Bug Identifier",
        "uiConfigs": {
          "route": "/dna/bug-identifier/overview"
        },
        "description": "Identify bugs in the network",
        "id": "cfd_detection",
        "continueOnError": true,
        "networkImpact": "M",
        "noLimitEventFiredCheck": true,
        "tags": []
      },
      {
        "ontologyVersionIri": "http://www.cisco.com/CNSR/DeviceCountSimple/2.2.0",
        "validated": true,
        "reasonerTool": true,
        "displayName": "Device Count",
        "uiConfigs": {
          "iconClass": "dnac-icon-generic-network-device",
          "element_type": "generic",
          "view": "all",
          "reasonerProps": [
            {
              "dataKey": "managementIpAddress",
              "inputKey": "deviceIP"
            }
          ]
        },
        "description": "Checks number of network devices is within Cisco DNA Center/SDA scale limits",
        "id": "device_count_simple",
        "continueOnError": true,
        "networkImpact": "S",
        "tags": [
          "CX"
        ]
      },
      {
        "ontologyVersionIri": "http://www.cisco.com/CNSR/SDAHwReadinessCheck/1.2.0",
        "validated": true,
        "reasonerTool": true,
        "displayName": "SDA Hardware Readiness Check",
        "uiConfigs": {
          "iconClass": "dnac-icon-clients2",
          "element_type": "generic",
          "view": "all",
          "reasonerProps": [
            {
              "dataKey": "managementIpAddress",
              "inputKey": "deviceIP"
            }
          ]
        },
        "description": "Check Hw is ready for SDA",
        "id": "sda_hw_readiness_check",
        "continueOnError": true,
        "networkImpact": "S",
        "tags": [
          "CX"
        ]
      },
      {
        "ontologyVersionIri": "http://www.cisco.com/CNSR/FabricCount/1.3.0",
        "validated": true,
        "reasonerTool": true,
        "displayName": "Fabric Count",
        "uiConfigs": {
          "iconClass": "dnac-icon-farbric-filled",
          "element_type": "generic",
          "view": "all",
          "reasonerProps": [
            {
              "dataKey": "managementIpAddress",
              "inputKey": "deviceIP"
            }
          ]
        },
        "description": "Check the number of fabrics within Cisco DNA Center is within Cisco DNA Center/SDA scale limits",
        "id": "fabric_count",
        "continueOnError": true,
        "networkImpact": "S",
        "tags": [
          "CX"
        ]
      },
      {
        "ontologyVersionIri": "http://www.cisco.com/CNSR/SDAScaleLimitsCheck/1.4.0",
        "validated": true,
        "reasonerTool": true,
        "displayName": "SDA Scale Limits Check",
        "uiConfigs": {
          "iconClass": "dnac-icon-generic-network-device",
          "element_type": "generic",
          "view": "all",
          "reasonerProps": [
            {
              "dataKey": "managementIpAddress",
              "inputKey": "deviceIP"
            }
          ]
        },
        "description": "Check number of clients, network devices and fabrics within Cisco DNA Center",
        "id": "sda_scale_limits_check",
        "continueOnError": true,
        "networkImpact": "M",
        "tags": [
          "CX"
        ]
      },
      {
        "ontologyVersionIri": "http://www.cisco.com/CNSR/RedundantLinkCheck/2.0.0",
        "validated": true,
        "reasonerTool": true,
        "displayName": "Redundant Link Check",
        "uiConfigs": {
          "iconClass": "dnac-icon-check-mark-circle-outlined",
          "element_type": "device",
          "view": "all",
          "deviceFilters": {
            "role": "ACCESS"
          },
          "reasonerProps": [
            {
              "dataKey": "managementIpAddress",
              "inputKey": "ipAddress"
            }
          ]
        },
        "description": "Detects if there are two links associated with a device with role ACCESS",
        "id": "redundant_link_check",
        "continueOnError": true,
        "networkImpact": "XS",
        "tags": [
          "CX"
        ]
      },
      {
        "ontologyVersionIri": "http://www.cisco.com/CNSR/L3AccessCheck/1.1.0",
        "validated": true,
        "reasonerTool": true,
        "displayName": "L3 Access Check",
        "uiConfigs": {
          "iconClass": "dnac-icon-topology-filled",
          "element_type": "device",
          "deviceFilters": {
            "role": "ACCESS"
          },
          "view": "all",
          "reasonerProps": [
            {
              "dataKey": "managementIpAddress",
              "inputKey": "deviceIP"
            }
          ]
        },
        "description": "Detects if access switches are running L3 routing protocols (potentially ready for SDA)",
        "id": "l3_access_check",
        "continueOnError": true,
        "networkImpact": "M",
        "tags": [
          "CX"
        ]
      }
    ]

});
