define({
    "sensorListSummaryTemplate":{
  "version": null,
  "response": {
    "count": 4,
    "details": [
      {
        "status": "RUNNING",
        "count": 3,
        "percentage": 90
      },
      {
        "status": "UNREACHABLE",
        "count": 1,
        "percentage": 10
      },
      {
        "status": "IDLE",
        "count": 0,
        "percentage": 0
      }
    ]
  }
}
});