define({
"Template":
{
  "upn": [
    {
      "isGuestAnchor": false,
      "sites": [
        {
          "siteHierarchy": "Global/San Jose/SJC14",
          "siteId": "d6f3881c-c790-450c-9e93-ec78aa675379"
        },
        {
          "siteHierarchy":"Global/San Jose/SJC14/Floor1",
          "siteId":"f2815a32-e120-494e-a406-3b84cfa5a3d6"
        }
      ],
      "deviceId": "3bc8c1aa-5ab5-4a8d-92b2-66e9f2e3c41b",
      "deviceName": "WLC.cisco.com"
    }
  ]
}
})