define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimDefaultDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/PolicyAnalytics/PolicyEndPointAnalyticsTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/PolicyAnalytics/PolicyEndPointProfileRulesTemplate'

], function (SimLokiDatabaseActions, UtilityFunctions, SimDefaultDataGenerator, PolicyEndPointAnalyticsTemplate, PolicyEndPointProfileRulesTemplate) {

    return {
        init: function () {

        },
    
        processGet: function (urlAction) {
            var yestdate = (UtilityFunctions.getTimeStamp())-86400000;
            if(urlAction.service.indexOf('initialization') > -1){
                var data  = {"initialized":true};
                return JSON.parse(JSON.stringify(data))
            }else if(urlAction.service.indexOf('ai-network-analytics') > -1 ){
                var data = {"ai-network-analytics":{"mfcDetectionEnabled":true,"concurrentMacDetectionEnabled":true,"spoofingDetectionDeployed":true,"spoofingCollectionEnabled":true,"natDetectionEnabled":true,"serviceReachable":true,"spoofingDetectionEnabled":true,"deployed":true,"postureDetectionEnabled":true,"weakPasswordDetectionEnabled":false,"enabled":true,"openPortDetectionEnabled":false}}
                return JSON.parse(JSON.stringify(data))
            }else if(urlAction.service.indexOf('ise') > -1 && urlAction.service.indexOf('config') > -1 && urlAction.service.indexOf('publisher') > -1){
                var data = {"iseAuthzEnable":true,"globalIsePublisherEnable":true,"iseAssetEnable":true,"iseCompatibility":true}
                return JSON.parse(JSON.stringify(data))
            }else if(urlAction.service.indexOf('ise') > -1 ){
                var data = {"aaa":{"ise":{"compatible":true,"pxgridEnabled":true,"enabled":true, "version":"3.2.0.542"}}};
                return JSON.parse(JSON.stringify(data))
            }else if(urlAction.service.indexOf('endpoints') > -1 && urlAction.action.count == true){
                var data = {"deviceType":3,"hardwareModel":3,"hardwareManufacturer":4,"fullyClassified":9,"totalEndpoints":14,"missingClassifications":5,"operatingSystem":5};
                return JSON.parse(JSON.stringify(data))
            }else if(urlAction.service.indexOf('endpoint') > -1 && urlAction.action.count == true){ //duplicating in Cyclops
                var data = {"totalEndpoints":63,"trustScoreSummary":{"high":12,"low":4,"medium":0},"deviceSummary":{"deviceType":20,"fullyUnclassified":6,"hardwareModel":15,"hardwareManufacturer":16,"fullyClassified":14,"missingClassifications":23,"operatingSystem":27,"nonRandomMac":63,"randomMac":0}}
                return JSON.parse(JSON.stringify(data))
            }else if(urlAction.service.indexOf('getUnknownClusters') > -1 ){
                var data = {"data":{"pagination":{"high":10,"low":0},"totalCount":1,"clusters":[{"numCommonAttributes":3,"numDevices":53,"clusterId":"21dd79c3-c545-57a9-a1b1-40119c19601c","value":10,"relevance":0.981132075471698}]}};
                // var data = {"data":{"pagination":{"high":10,"low":0},"totalCount":0,"clusters":[]}}
                return JSON.parse(JSON.stringify(data))
            }else if(urlAction.service.indexOf('getUnknownCluster') > -1 ){ //duplicating in Cyclops
                var data = {"data":{"pagination":{"high":10,"low":0},"totalCount":1,"clusters":[{"numCommonAttributes":3,"numDevices":53,"clusterId":"21dd79c3-c545-57a9-a1b1-40119c19601c","value":10,"relevance":0.981132075471698}]}};
                // var data = {"data":{"pagination":{"high":10,"low":0},"totalCount":0,"clusters":[]}}
                return JSON.parse(JSON.stringify(data))
            }else if(urlAction.service.indexOf('proposals') > -1 && urlAction.service.indexOf('count') > -1){
                var data = {"replaceRule":0,"removeRule":0,"totalCount":0};
                return JSON.parse(JSON.stringify(data))
            }else if(urlAction.service.indexOf('proposals') > -1 && urlAction.action.count == true){
                var data = {"replaceRule":0,"removeRule":0,"totalCount":0};
                return JSON.parse(JSON.stringify(data))
            }else if(urlAction.service.indexOf('proposal') > -1 && urlAction.action.count == true){
                var data = {"replaceRule":0,"removeRule":0,"totalCount":0};
                return JSON.parse(JSON.stringify(data))
            }else if(urlAction.service.indexOf('epLabel') >-1 && urlAction.filter['fetchFor'] == 'TRUST_SCORE'){
                var reObj = SimLokiDatabaseActions.getFilteredRecordHandler('policy-endpoint-inventory',{'ancPolicyDetails':{'$exists':true}});
                if(reObj.length !=0){
                    var data = {"data":{"trustScore":["High (7-10)","Low (1-3)"],"ancPolicyStatus":["SUCCESS"],"ancPolicyName":["ancQuarantine"]}};
                }
                else{
                    var data = {"data":{"trustScore":["High (7-10)","Low (1-3)"],"ancPolicyStatus":[],"ancPolicyName":[]}};
                }
                return JSON.parse(JSON.stringify(data))
            }
            else if(urlAction.service.indexOf('epLabels') > -1){
                var data = JSON.parse(JSON.stringify( PolicyEndPointAnalyticsTemplate.epLabels_Template ));
                return data
            }else if(urlAction.service.indexOf('epLabel') > -1){  //duplicating in Cyclops
                var data = JSON.parse(JSON.stringify( PolicyEndPointAnalyticsTemplate.epLabels_Template ));
                return data
            }else if(urlAction.service.indexOf('dictionary') > -1 ){
                var data = JSON.parse(JSON.stringify( PolicyEndPointAnalyticsTemplate.DictionaryAttributes_Template ));
                return data
            }else if(urlAction.service.indexOf('classification') > -1 && urlAction.service.indexOf('rule') > -1){
                var tempObj = JSON.parse(JSON.stringify( PolicyEndPointProfileRulesTemplate.profileRules_Template ));
                var tempObj1 = SimLokiDatabaseActions.getAll('policy-endpoint-profile')
                tempObj = tempObj.concat(tempObj1)
                var totalCount = tempObj.length;
                //filtering
                if(urlAction.filter["ruleType"] && urlAction.filter["ruleType"] != "all"){
                    var ruleType = urlAction.filter["ruleType"]
                    if(ruleType == "Custom Rule"){
                        ruleType = "Custom";
                    }
                    tempObj = tempObj.filter(e=>e.ruleType == ruleType);
                }if(urlAction.filter["ruleName"]){
                    tempObj = tempObj.filter(e=>e.ruleName == urlAction.filter["ruleName"]);
                }if(urlAction.filter["deviceType"]){
                    tempObj = tempObj.filter(e=>e.deviceType == urlAction.filter["deviceType"]);
                }if(urlAction.filter["operatingSystem"]){
                    tempObj = tempObj.filter(e=>e.operatingSystem == urlAction.filter["operatingSystem"]);
                }if(urlAction.filter["hardwareModel"]){
                    tempObj = tempObj.filter(e=>e.hardwareModel == urlAction.filter["hardwareModel"]);
                }if(urlAction.filter["hardwareManufacturer"]){
                    tempObj = tempObj.filter(e=>e.hardwareManufacturer == urlAction.filter["hardwareManufacturer"]);
                }
                //Sorting
                tempObj = UtilityFunctions.decorateData({'sortBy':urlAction.filter["sortDir"], 'order':urlAction.filter["orderBy"]}, tempObj);
                //pagination
                tempObj = UtilityFunctions.filterBasedOnOffset(urlAction.filter.low, urlAction.filter.high, tempObj);
                var data = {"rules":tempObj,"pagination":{"high":urlAction.filter["high"],"low":urlAction.filter["low"],
                "orderBy":urlAction.filter["orderBy"],"totalCount":totalCount,"sortDir":urlAction.filter["sortDir"]}};
                return JSON.parse(JSON.stringify(data))
            }else if(urlAction.service.indexOf('endpoints') > -1 && urlAction.service.indexOf('labels') > -1 ){
                var data = JSON.parse(JSON.stringify( PolicyEndPointAnalyticsTemplate.Endpoints_Labels_Template ));
                return data
            }else if(urlAction.service.indexOf('classification') > -1 && urlAction.service.indexOf('label') > -1 ){
                var data = JSON.parse(JSON.stringify( PolicyEndPointAnalyticsTemplate.Endpoints_Labels_Template ));
                return data
            }else if(urlAction.service.indexOf('logicalgroup') > -1 && urlAction.service.indexOf('deviceTypeLabel') > -1 ){
                // var data = JSON.parse(JSON.stringify( PolicyEndPointAnalyticsTemplate.logicalGroup_deviceTypeLables_Template ));
                var data = SimLokiDatabaseActions.getAll("policy-endpoint-lable");
                if(urlAction.filter.filter == 'used'){
                    data = data.filter(obj=>obj.used === true);
                    return {'data':data}
                }else if(urlAction.filter.filter == 'available'){
                    data = data.filter(obj=>obj.used === false);
                    return {'data':data}
                }
                return {"data":data}
            }else if(urlAction.service.indexOf('logicalgroup') > -1 && urlAction.service.indexOf('tag') > -1 ){
                var data = SimLokiDatabaseActions.getAll('policy-endpoint-tag');
                return data
            }else if(urlAction.service.indexOf('cluster') > -1 && urlAction.service.indexOf('getClusterInfoById') > -1 ){
                var data = JSON.parse(JSON.stringify( PolicyEndPointAnalyticsTemplate.clusterInfo_Template ));
                data.data.clusterId = urlAction.filter.clusterId;
                return data
            }else if(urlAction.service.indexOf('policy') > -1){
                var data = ["ancQuarantine","ancReAuth"]
                return JSON.parse(JSON.stringify(data))
            }else if(urlAction.service.indexOf('system-rule') > -1 && urlAction.service.indexOf('config') > -1){
                var data = {"reviewEnabled":false,"scheduledDays":["Saturday"],"pluginId":"BuiltIn","scheduledStartTime":"02:00","updatedTimestamp":1620629820338,"enabled":"true","cloudConnectorEnabled":false,"status":"complete"}
                return JSON.parse(JSON.stringify(data))
            }else if(urlAction.service.indexOf('setup') > -1 && urlAction.service.indexOf('anomaly-detection') > -1 ){
                var data = {"mfcDetectionEnabled":"true","concurrentMacDetectionEnabled":"true","natDetectionEnabled":"true","postureDetectionEnabled":"true"}
                return JSON.parse(JSON.stringify(data))
            }else if(urlAction.service.indexOf('setup') > -1 && urlAction.service.indexOf('ai-network-analytics') > -1 ){
                var data = {"ai-network-analytics":{"mfcDetectionEnabled":true,"concurrentMacDetectionEnabled":true,"spoofingDetectionDeployed":true,"spoofingCollectionEnabled":true,"natDetectionEnabled":true,"serviceReachable":true,"spoofingDetectionEnabled":true,"deployed":true,"postureDetectionEnabled":true,"enabled":true}}
                return JSON.parse(JSON.stringify(data))
            }else if(urlAction.service.indexOf("anomaly-alert") > -1){
                return {"pagination":{"totalCount":0,"low":0,"high":10,"orderBy":"lastDetected","sortDir":"desc"},"alertDetail":[]}
            }else if(urlAction.service.indexOf("day-zero") > -1){
                var dayZeroTemp = JSON.parse(JSON.stringify(PolicyEndPointAnalyticsTemplate.day_zero_Template))
                return dayZeroTemp
            }else if(urlAction.service.indexOf("day-n") > -1){
                var daynTemp = JSON.parse(JSON.stringify(PolicyEndPointAnalyticsTemplate.day_n_Template))
                return daynTemp
            }else if(urlAction.service.indexOf("purge") > -1){
                var purgeTemp = JSON.parse(JSON.stringify(PolicyEndPointAnalyticsTemplate.purge_config_Template))
                return purgeTemp
            }else if(urlAction.service.indexOf("tta-subnet-configuration") > -1){
                return {"deviceTypes":[],"rules":[]}
            }else if(urlAction.service.indexOf("trust") > -1 && urlAction.service.indexOf("reset-config") > -1){
                var resetconfigTemp = JSON.parse(JSON.stringify(PolicyEndPointAnalyticsTemplate.trust_reset_config_Template))
                return resetconfigTemp
            }else{
                return {}
            }
        },
        processPost: function (urlAction) {
            var yestdate = (UtilityFunctions.getTimeStamp())-86400000;
            if(urlAction.service.indexOf('services') > -1 && urlAction.service.indexOf('endpoints') > -1 || urlAction.service.indexOf('endpoint') > -1){
                var tempObj = SimLokiDatabaseActions.getAll('policy-endpoint-inventory');
                if(urlAction.restPayload.knownAssetTypes !=undefined ){
                    var dataTypes = urlAction.restPayload.knownAssetTypes.split(',');
                    for(var i=0;i<dataTypes.length;i++){
                        if(dataTypes[i] == "deviceType"){
                            tempObj = tempObj.filter(a=>a.deviceType != null)
                        }else if(dataTypes[i] == "operatingSystem"){
                            tempObj = tempObj.filter(a=>a.operatingSystem != null)
                        }else if(dataTypes[i] == "hardwareModel"){
                            tempObj = tempObj.filter(a=>a.hardwareModel != null)
                        }else if(dataTypes[i] == "hardwareManufacturer"){
                            tempObj = tempObj.filter(a=>a.hardwareManufacturer != null)
                        }
                    }
                }
                if(urlAction.restPayload.activeEndpointsFilter == "active"){
                    tempObj = tempObj.filter(a=>a.location != null)
                }else if(urlAction.restPayload.activeEndpointsFilter == "inActive"){
                    tempObj = tempObj.filter(a=>a.location == null)
                // }else if(urlAction.restPayload.activeEndpointsFilter == "all"){
                //     tempObj = tempObj.filter(a=>a.location == null)
                }
                if(urlAction.restPayload.deviceType != undefined){
                    if(urlAction.restPayload.deviceType == "Unknown"){
                        urlAction.restPayload.deviceType = null;
                    }
                    tempObj = tempObj.filter(a=>a.deviceType == urlAction.restPayload.deviceType)
                }
                if(urlAction.restPayload.operatingSystem != undefined){
                    if(urlAction.restPayload.operatingSystem == "Unknown"){
                        urlAction.restPayload.operatingSystem = null;
                    }
                    tempObj = tempObj.filter(a=>a.operatingSystem == urlAction.restPayload.operatingSystem)
                }
                if(urlAction.restPayload.hardwareModel != undefined){
                    if(urlAction.restPayload.hardwareModel == "Unknown"){
                        urlAction.restPayload.hardwareModel = null;
                    }
                    tempObj = tempObj.filter(a=>a.hardwareModel == urlAction.restPayload.hardwareModel)
                }
                if(urlAction.restPayload.hardwareManufacturer != undefined){
                    if(urlAction.restPayload.hardwareManufacturer == "Unknown"){
                        urlAction.restPayload.hardwareManufacturer = null;
                    }
                    tempObj = tempObj.filter(a=>a.hardwareManufacturer == urlAction.restPayload.hardwareManufacturer)
                }
                if(urlAction.restPayload.macAddress != undefined){
                    if(urlAction.restPayload.macAddress == "Unknown"){
                        urlAction.restPayload.macAddress = null;
                    }
                    tempObj = tempObj.filter(a=>a.macAddress == urlAction.restPayload.macAddress)
                }

                // if(urlAction.service.indexOf('ert')>-1 && urlAction.action.summary == false && urlAction.restPayload.insert == true){
                if(urlAction.service.indexOf('eps')>-1 && urlAction.action.summary == false && urlAction.restPayload.insert == true){
                    var tempObj = {}
                    tempObj.macAddress = urlAction.restPayload.macAddress;
                    tempObj.deviceType = urlAction.restPayload.deviceType[0];
                    tempObj.hardwareManufacturer = urlAction.restPayload.hardwareManufacturer[0];
                    tempObj.hardwareModel = urlAction.restPayload.hardwareModel[0];
                    tempObj.location = null;
                    SimLokiDatabaseActions.insert('policy-endpoint-inventory', tempObj);
                }
                if(urlAction.restPayload.view == 'trustScore' && urlAction.service.indexOf('query')){
                    if(urlAction.restPayload.trustScore){
                        var tScore = urlAction.restPayload.trustScore;
                        tScore = tScore.match(/\d/g);
                        var tScoreSttart = parseInt(tScore[0]);
                        var tScoreEnd = parseInt(tScore[1]);
                        if(tScoreSttart == 7){
                            tScoreEnd = 10;
                        }
                        tempObj = SimLokiDatabaseActions.getFilteredRecordHandler('policy-endpoint-inventory',{'$and':[{'trustScore':{'$ne':null}},{'trustScore.trustValue':{'$between':[tScoreSttart,tScoreEnd]}}]});
                        for (var i=0;i<tempObj.length;i++){
                            if(tempObj[i].trustScoreResetDetails != null){ 
                                var tempObjTime = tempObj[i].trustScoreResetDetails.tsResetTimeStamp.$numberLong
                                var endTime = new Date().getTime()
                                var diffTime = (endTime-tempObjTime)/1000;
                                console.log(tempObjTime);
                                console.log(endTime);
                                console.log(diffTime);
                                if(diffTime<300){
                                    tempObj[i].trustScore.concurrentMacThreatLevel = 0;
                                    tempObj[i].trustScore.hydraThreatLevel = 0;
                                }else if(diffTime>301 && diffTime<360){
                                    tempObj[i].trustScore.concurrentMacThreatLevel = 0;
                                }else if(diffTime>361){
                                    tempObj[i].trustScore.concurrentMacThreatLevel = 2;
                                    tempObj[i].trustScore.hydraThreatLevel = 2;
                                }
                            }
                        }
                    }else{
                        tempObj = SimLokiDatabaseActions.getFilteredRecordHandler('policy-endpoint-inventory',{'trustScore':{'$ne':null}});
                        for (var i=0;i<tempObj.length;i++){
                            if(tempObj[i].trustScoreResetDetails != null){ 
                                var tempObjTime = tempObj[i].trustScoreResetDetails.tsResetTimeStamp.$numberLong
                                var endTime = new Date().getTime()
                                var diffTime = (endTime-tempObjTime)/1000;
                                console.log(tempObjTime);
                                console.log(endTime);
                                console.log(diffTime);
                                if(diffTime<300){
                                    tempObj[i].trustScore.concurrentMacThreatLevel = 0;
                                    tempObj[i].trustScore.hydraThreatLevel = 0;
                                }else if(diffTime>301 && diffTime<360){
                                    tempObj[i].trustScore.concurrentMacThreatLevel = 0;
                                }else if(diffTime>361){
                                    tempObj[i].trustScore.concurrentMacThreatLevel = 2;
                                    tempObj[i].trustScore.hydraThreatLevel = 2;
                                }
                            }
                        }
                    }
                }
                if(urlAction.service.indexOf('policy') >-1 && urlAction.service.indexOf('assignment') >-1){
                    var macAd = urlAction.restPayload.listEndpoints[0].macAddress;
                    var tempObj = SimLokiDatabaseActions.getFilteredRecordHandler('policy-endpoint-inventory',{'macAddress':macAd});
                    if(tempObj.length !=0 && urlAction.restPayload.operation == 'assign' || tempObj.length !=0 && urlAction.restPayload.operation == 'update'){
                        var ancTemp = JSON.parse(JSON.stringify( PolicyEndPointAnalyticsTemplate.AncPolicyDetails_Template));
                        ancTemp.ancPolicyName = urlAction.restPayload.policyName;
                        ancTemp.trustScore = urlAction.restPayload.listEndpoints[0].trustScore;
                        ancTemp.trustScoreTimeStamp.$numberLong = UtilityFunctions.getTimeStamp();
                        ancTemp.ancPolicyApplyTime.$numberLong = UtilityFunctions.getTimeStamp();
                        tempObj[0].ancPolicyDetails = ancTemp;
                        SimLokiDatabaseActions.update('policy-endpoint-inventory', tempObj);
                        return "ANC Policy is successfully changed."
                    }else if(tempObj.length !=0 && urlAction.restPayload.operation == 'revoke'){
                        delete(tempObj[0].ancPolicyDetails);
                        SimLokiDatabaseActions.update('policy-endpoint-inventory', tempObj);
                        return "ANC Policy is successfully removed."
                    }
                }
                if(urlAction.service.indexOf('trust') >-1 && urlAction.service.indexOf('action') >-1){
                    //reset Trust Score
                    var macAd = urlAction.restPayload.aiReset.macAddress;
                    var tempObj = SimLokiDatabaseActions.getFilteredRecordHandler('policy-endpoint-inventory',{'macAddress':macAd});
                    var tsResetDetails = JSON.parse(JSON.stringify( PolicyEndPointAnalyticsTemplate.tsResetDetails_Template));
                    tsResetDetails.tsAtReset = urlAction.restPayload.aiReset.trustScore;
                    tsResetDetails.tsResetDescription = null;
                    tsResetDetails.tsResetTimeStamp.$numberLong = UtilityFunctions.getTimeStamp();
                    tempObj[0].trustScoreResetDetails = tsResetDetails;
                    tempObj[0].trustScoreData.currentSpoofingData.trustScoreTimeStamp.$numberLong = UtilityFunctions.getTimeStamp();
                    tempObj[0].trustScoreData.concurrentMacAnomalyData.detectionTime = UtilityFunctions.getTimeStamp();
                    tempObj[0].trustScoreData.currentNatAnomalyData.detectionTime = UtilityFunctions.getTimeStamp();
                    tempObj[0].session.lastSessionCollectionTime.$numberLong = yestdate;
                    SimLokiDatabaseActions.update('policy-endpoint-inventory', tempObj);
                }
                urlAction.restPayload.totalCount = tempObj.length;
                var data = {"deviceDetail":tempObj,"pagination":urlAction.restPayload,"globalAnomalyConfig":PolicyEndPointAnalyticsTemplate.GlobalAnomalyConfig_Template};
                return JSON.parse(JSON.stringify(data))
            }else if(urlAction.service.indexOf('logicalgroup') > -1 && urlAction.service.indexOf('tag') > -1 && urlAction.service.indexOf('validate') > -1) {
                return []
            }else if(urlAction.service.indexOf('logicalgroup') > -1 && urlAction.service.indexOf('tag') > -1) {
                // first checking the record, if the record present returning the same record
                var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('policy-endpoint-tag',{'name':urlAction.restPayload.name});
                if(recordObj.length >0){
                    return recordObj
                }
                var data = {};                
                data.name = urlAction.restPayload.name;
                data.lgID = UtilityFunctions.generate_uuid();
                data.parentlgID = urlAction.restPayload.parentlgID;
                data.deviceTypes = urlAction.restPayload.deviceTypes;
                // data.deviceTypesCounts = urlAction.restPayload.deviceTypes.length;
                data.dtCount = 0;
                data.epCount = 0;
                data.level = 1;
                SimLokiDatabaseActions.insert('policy-endpoint-tag', data);
                return data;
            }else if(urlAction.service.indexOf('services') > -1 && urlAction.service.indexOf('rules') > -1 || 
            urlAction.service.indexOf('classification') > -1 && urlAction.service.indexOf('rule') > -1){
                var tempObj = JSON.parse(JSON.stringify( PolicyEndPointAnalyticsTemplate.profileRuleCreate_Template ));
                tempObj.clusterId = urlAction.restPayload.clusterId;
                tempObj.conditionGroups = urlAction.restPayload.conditionGroups;
                tempObj.deviceType = urlAction.restPayload.result.deviceType;
                tempObj.hardwareManufacturer = urlAction.restPayload.result.hardwareManufacturer;
                tempObj.hardwareModel = urlAction.restPayload.result.hardwareModel;
                tempObj.lastModifiedOn = UtilityFunctions.getTimeStamp();
                tempObj.operatingSystem = urlAction.restPayload.result.operatingSystem;
                tempObj.ruleId = "ml-"+UtilityFunctions.generate_uuid();
                tempObj.ruleName = urlAction.restPayload.ruleName;
                tempObj.ruleType = urlAction.restPayload.ruleType;
                SimLokiDatabaseActions.insert('policy-endpoint-profile', tempObj)
                return {"CreateComplexRuleStatus":"Success","ruleName":urlAction.restPayload.ruleName,"ruleId":tempObj.ruleId}
            }
        },
        processPut: function(urlAction){
            if(urlAction.service.indexOf('logicalgroup') > -1 && urlAction.service.indexOf('tag') > -1) {
                var data = SimLokiDatabaseActions.getFilteredRecordHandler('policy-endpoint-tag',{'lgID':urlAction.restPayload.lgID})
                for(var i = 0;i<urlAction.restPayload.action.length;i++){
                    if(urlAction.restPayload.action[i] == "LgNameChange"){
                        data[0].name = urlAction.restPayload.updatedNameOfLg;
                    }else if(urlAction.restPayload.action[i] == "ParentlgIDChange"){
                        data[0].parentlgID = urlAction.restPayload.updatedParentlgIDOfLg;
                    }else if(urlAction.restPayload.action[i] == "AddDTToCurrentLg"){
                        data[0].deviceTypesCounts = [];
                        data[0].deviceTypes = urlAction.restPayload.deviceTypeListToBeReassigned;
                        var deviceTypes = urlAction.restPayload.deviceTypeListToBeReassigned;
                        for(var i = 0; i<deviceTypes.length;i++){
                            var dtEp = {'dtEpCount':0,'name':deviceTypes[i]};
                            data[0].deviceTypesCounts.push(dtEp)
                            var recObj = SimLokiDatabaseActions.getFilteredRecordHandler('policy-endpoint-lable',{'deviceType':deviceTypes[i]})
                            recObj[0].used = true;
                            SimLokiDatabaseActions.update('policy-endpoint-lable',recObj)
                        }
                        data[0].dtCount = data[0].deviceTypesCounts.length
                    }else if(urlAction.restPayload.action[i] == "RemoveFromTree"){
                        SimLokiDatabaseActions.delete('policy-endpoint-tag', data);
                        return {}
                    }
                }
                SimLokiDatabaseActions.update('policy-endpoint-tag', data);
                return data;
            }
        }
    }

});
