define({
"Template":{
    "mobilityConfigInstanceId": 0,
    "mobilityConfigInstanceUuid": null,
    "mobilityConfigInstance_version": 0,
    "secureMobility": false,
    "mobilityPeers": null,
    "deviceID": null,
    "rfMobilityGroup": "default",
    "rfNetworkName": "default",
    "instanceTenantId": "5de073f29e164d00d1e84d76",
    "instanceUuid": null,
    "id": null,
    "dataLinkEncryption":false,
    "virtualIpAddress":{"address":"192.0.2.1"},
    "memberMacAddress":"00:00:00:00:00:00"
  }

})