define({
  "Template":
    {
  "request": {
    "key": "netflix",
    "type": "Application",
    "label": "netflix",
    "collection": false,
    "componentContent": [
      {
        "component": "fusion",
        "additional": "b850b1dd-acae-4350-b39e-57ad2f4b5f6e"
      }
    ]
  },
  "data": {
    "response": {
      "id": "b850b1dd-acae-4350-b39e-57ad2f4b5f6e",
      "instanceId": 6841,
      "authEntityId": 6841,
      "displayName": "6841",
      "authEntityClass": -1909073334,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1527289128537,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1527289128537,
      "name": "netflix",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [],
      "type": "scalablegroup",
      "cfsChangeInfo": [],
      "customProvisions": [],
      "scalableGroupExternalHandle": "netflix",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "0a8456b9-7016-4c96-a0b2-f9216fc7a411",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [],
      "networkApplications": [
        {
          "id": "6ed70c0e-6238-412c-b717-ab49e7b7bbce",
          "instanceId": 11788,
          "authEntityId": 11788,
          "displayName": "11788",
          "authEntityClass": -217092956,
          "instanceTenantId": "SYS0",
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "68506561-b112-4831-a9a9-e4e6b9831a51",
          "createTime": 1527289128537,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "Netflix online video streaming and browsing",
          "ignoreConflict": false,
          "lastUpdateTime": 1527289128537,
          "longDescription": "Netflix is an American provider of on-demand internet streaming media in the United States, Canada, and Latin America. Netflix on-demand Internet video streaming (\"Watch Instantly\") works on Windows or Mac OS X and compatible devices. The services underlying protocols are HTTP and SSL.",
          "name": "netflix",
          "nbarId": "1316",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "http://www.netflix.com/",
          "selectorId": "457",
          "subCategory": "d654f045-8726-4b7b-a671-0652cbe6cfcf",
          "trafficClass": "MULTIMEDIA_STREAMING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "a1471012-f568-4026-91c9-86cb96d84a9a",
          "instanceId": 14146,
          "authEntityId": 14146,
          "displayName": "14146",
          "authEntityClass": 124090504,
          "instanceTenantId": "SYS0",
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [],
          "ipv6Subnet": [],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "914dd381-b81d-4d0b-88b8-2af3b9bd7434",
          "instanceId": 14147,
          "authEntityId": 14147,
          "displayName": "14147",
          "authEntityClass": 124090504,
          "instanceTenantId": "SYS0",
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [],
          "ipv6Subnet": [],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "abd1cb65-18e4-4252-a603-4d36bada2042"
      },
      "user": [],
      "userGroup": []
    }
  },
  "result": [
    {
      "bookID": {
        "namespace": "fusion",
        "name": "core",
        "version": "1.0.1"
      },
      "response": {
        "id": "b850b1dd-acae-4350-b39e-57ad2f4b5f6e",
        "instanceId": 6841,
        "authEntityId": 6841,
        "displayName": "6841",
        "authEntityClass": -1909073334,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "createTime": 1527289128537,
        "deployed": false,
        "isSeeded": true,
        "isStale": false,
        "lastUpdateTime": 1527289128537,
        "name": "netflix",
        "namespace": "scalablegroup:application",
        "provisioningState": "DEFINED",
        "qualifier": "application",
        "resourceVersion": 0,
        "targetIdList": [],
        "type": "scalablegroup",
        "cfsChangeInfo": [],
        "customProvisions": [],
        "scalableGroupExternalHandle": "netflix",
        "scalableGroupType": "APPLICATION",
        "securityGroupTag": 0,
        "state": "ACTIVE",
        "vnAgnostic": true,
        "identitySource": {
          "id": "0a8456b9-7016-4c96-a0b2-f9216fc7a411",
          "instanceId": 10010,
          "authEntityId": 10010,
          "displayName": "10010",
          "authEntityClass": 1738953278,
          "instanceTenantId": "SYS0",
          "deployPending": "NONE",
          "instanceVersion": 0,
          "state": "INACTIVE",
          "type": "NBAR"
        },
        "indicativeNetworkIdentity": [],
        "networkApplications": [
          {
            "id": "6ed70c0e-6238-412c-b717-ab49e7b7bbce",
            "instanceId": 11788,
            "authEntityId": 11788,
            "displayName": "11788",
            "authEntityClass": -217092956,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "appProtocol": "tcp/udp",
            "applicationGroup": "other",
            "applicationType": "DEFAULT",
            "categoryId": "68506561-b112-4831-a9a9-e4e6b9831a51",
            "createTime": 1527289128537,
            "encrypted": "true",
            "engineId": "13",
            "helpString": "Netflix online video streaming and browsing",
            "ignoreConflict": false,
            "lastUpdateTime": 1527289128537,
            "longDescription": "Netflix is an American provider of on-demand internet streaming media in the United States, Canada, and Latin America. Netflix on-demand Internet video streaming (\"Watch Instantly\") works on Windows or Mac OS X and compatible devices. The services underlying protocols are HTTP and SSL.",
            "name": "netflix",
            "nbarId": "1316",
            "p2pTechnology": "false",
            "popularity": 10,
            "rank": 65535,
            "references": "http://www.netflix.com/",
            "selectorId": "457",
            "subCategory": "d654f045-8726-4b7b-a671-0652cbe6cfcf",
            "trafficClass": "MULTIMEDIA_STREAMING",
            "tunnel": "false"
          }
        ],
        "networkIdentity": [
          {
            "id": "a1471012-f568-4026-91c9-86cb96d84a9a",
            "instanceId": 14146,
            "authEntityId": 14146,
            "displayName": "14146",
            "authEntityClass": 124090504,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "ipv4Subnet": [],
            "ipv6Subnet": [],
            "lowerPort": 0,
            "ports": "53,80,443,5353",
            "protocol": "TCP",
            "upperPort": 0
          },
          {
            "id": "914dd381-b81d-4d0b-88b8-2af3b9bd7434",
            "instanceId": 14147,
            "authEntityId": 14147,
            "displayName": "14147",
            "authEntityClass": 124090504,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "ipv4Subnet": [],
            "ipv6Subnet": [],
            "lowerPort": 0,
            "ports": "53,5353",
            "protocol": "UDP",
            "upperPort": 0
          }
        ],
        "parentScalableGroup": {
          "idRef": "abd1cb65-18e4-4252-a603-4d36bada2042"
        },
        "user": [],
        "userGroup": []
      }
    }
  ]
} 
});
