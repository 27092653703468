define({
"Template":
{
  "version": "1.0",
  "response": {
    "id": "AWWtPqlpAAQ7MfF7gU4F",
    "name": "default_syslog_event_trigger",
    "entityType": "network_device",
    "entity": "192.168.120.1",
    "enabled": true,
    "flattened": true,
    "groupBy": "Unknown",
    "category": "Device",
    "severity": "HIGH",
    "summary": "Interface InterfaceUpPlaceHolder is drawing power in excess of allocated power 10 milliwatts on switch",
    "scope": "GLOBAL",
    "priority": "P3",
    "groupId": "syslog_events_issue_grouping",
    "groupName": "Device Experiencing High Temperature",
    "rootCause": "ILPOWER-3-SHUT_OVERDRAWN",
    "timestamp": 1536184689000,
    "description": "Interface InterfaceUpPlaceHolder is shutdown as it is consuming more than the maximum configured power 10 milliwatts.\DeviceNamePlaceHolder",
    "suggestions": [
      {
        "message": "Verify there is adequate remaining system power",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Verify there is adequate remaining system power",
            "command": "show power inline",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Verify the end device is not exceeding a configured power policing setting",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Verify the end device is not exceeding a configured power policing setting",
            "command": "show power inline police",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Verify the switch has the correct power supplies installed and operational",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Verify the switch has the correct power supplies installed and operational",
            "command": "show environment power",
            "stepType": "command-Runner"
          },
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Verify stack power",
            "command": "show stack-power detail",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "If the above actions did not resolve the issue, collect tech-support output and contact the Cisco TAC",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Run tech-support command to collect output",
            "command": "show tech-support",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Verify the device logs for power controller related event messages",
        "steps": [

        ]
      }
    ],
    "additionalParams": [
      {
        "key": "messageText",
        "value": "Interface GigabitEthernet1/0/3 is shutdown as it is consuming more than the maximum configured power 10 milliwatts.\u0000"
      },
      {
        "key": "managementIpAddr",
        "value": "192.168.120.1"
      },
      {
        "key": "param5",
        "value": ""
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "__entity_type__",
        "value": "NetworkDevice"
      },
      {
        "key": "entityId",
        "value": "192.168.120.1"
      },
      {
        "key": "type",
        "value": "Issue"
      },
      {
        "key": "param3",
        "value": ""
      },
      {
        "key": "priority",
        "value": "P1"
      },
      {
        "key": "param4",
        "value": ""
      },
      {
        "key": "param1",
        "value": "GigabitEthernet1/0/3"
      },
      {
        "key": "param2",
        "value": "10"
      },
      {
        "key": "messageType",
        "value": "ILPOWER-3-SHUT_OVERDRAWN"
      },
      {
        "key": "rootCause",
        "value": "ILPOWER-3-SHUT_OVERDRAWN"
      },
      {
        "key": "category",
        "value": "Device"
      },
      {
        "key": "timestring",
        "value": "2018-09-05T21:58:09.000+0000"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}
})