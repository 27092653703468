define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
], function (SimLokiDatabaseActions) {

    return {
        init: function() {
        },

        doProcessRestRequest: function(urlAction){
            console.log(urlAction.url);
            console.log(urlAction);
            var data;
            switch(urlAction.method) {
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                case 'POST':
                    break;

                case 'DELETE':
                    break;

                default:
                    break;
            }

            return data;
        }
    };

    function processGetRequest(urlAction){

        if(urlAction.service.indexOf('psn')>=0 && urlAction.service.indexOf('aaa')>=0) {
            // var data =[{"name":"DNAC-58-83","ipAddresses":["dcloud-ise-ctf-inst-xxx.cisco.com"],"nodeType":"PROFILER,SESSION"}];
            var data =SimLokiDatabaseActions.getAll('psn-server');
            return data;
        } else if(urlAction.service.indexOf('psn')>=0) {
            var data =SimLokiDatabaseActions.getAll('psn-server');
            return data;
         }else if(urlAction.service.indexOf('aaa')>=0 && urlAction.service.indexOf('ise')>=0 && urlAction.service.indexOf('information')>=0) {
            var data = [{"aaaServerInstanceUuid":"ac6ca684-20b3-4f9d-8e12-778fb65db5b5","version":"3.1.0.518","patches":["0"],"deploymentId":"ba58b872-3920-4d4a-aa7c-68242f8ac5f5"}]
            return data;
         }else if(urlAction.service.indexOf('aaa')>=0) {
            if(urlAction.filter['isIseEnabled']){
                var ISEValue = urlAction.filter['isIseEnabled']
                var data =SimLokiDatabaseActions.getFilteredRecordHandler('aaa',{'isIseEnabled':JSON.parse(ISEValue)});
            }else{
                var data =SimLokiDatabaseActions.getAll('aaa');
            }
            if(urlAction.filter.limit == -1){
                urlAction.filter.limit = data.length;
            }
            return data;
         }
    }

});
