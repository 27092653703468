define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/ios/ios_disconnect_macaddr_events',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/ios/ios_neighbor_aps',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
 ], function(ios_disconnect_macaddr_events, ios_neighbor_aps,SimLokiDatabaseActions) {

 return {

      init: function(){
      },
      processIosGet: function(urlAction) {
          if(urlAction.service.indexOf('ios-disconnect-events') >=0 && urlAction.filter['entityName'] != undefined &&
             urlAction.filter['entityName'] == 'macAddr' ) {

              var clientList=SimLokiDatabaseActions.getFilteredRecordHandler('host',{'id':urlAction.service[2]});

                      if( clientList.length > 0 && (clientList[0].subType.indexOf('iPhone') >= 0 || clientList[0].subType.indexOf('iPad') >= 0)) {

                          //return ios_disconnect_macaddr_events.template;
                          var tJson = JSON.parse(JSON.stringify(ios_disconnect_macaddr_events.template));
                          var site;
                          var apName;
                          var location;
                          var apDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': clientList[0].connectedNetworkDeviceId  });
                          if(apDevice.length>0) {
                              var apList = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and': [ {'name': { '$nin': apDevice[0].name }},{ 'family':"Unified AP" }]});
                               if(apList.length>0) {
                                   site =  SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id': apList[0].siteId  });
                                   if(site.length>0) {
                                        location=site[0].groupNameHierarchy;
                                   }
                                   apName = apList[0].name;
                               }
                          }

                          var d = new Date(), i;
                          var subTract5Minute = 5 * 60000;
                          var timeStamp = d.getTime() - subTract5Minute;
                          for(i = 0; i < tJson.response.length; i++) {
                              timeStamp -= subTract5Minute;
                              tJson.response[i].timestamp = timeStamp;
                              if(location!=undefined) {
                                tJson.response[i].location = location;
                              }
                              if(apName!=undefined) {
                                tJson.response[i].apName = apName;
                              }

                          }
                        return tJson;
                       }
            return []
          }
          else if(urlAction.service.indexOf('ios-neighbor-aps') >=0) {
              var clientList=SimLokiDatabaseActions.getFilteredRecordHandler('host',{'id':urlAction.service[2]});
              if( clientList.length > 0 && (clientList[0].subType.indexOf('iPhone') >= 0 || clientList[0].subType.indexOf('iPad') >= 0)) {
                  var tJson = JSON.parse(JSON.stringify(ios_neighbor_aps.template));
                  var d = new Date();
                  tJson.timestamp = urlAction.filter['endTime'];
                  var apDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': clientList[0].connectedNetworkDeviceId  });

                  if(apDevice.length>0) {
                   var apList = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and': [ {'name': { '$nin': apDevice[0].name }},{ 'family':"Unified AP" }]});
                     if(apList.length>0) {
                          for(var i=0;i<tJson.response.length;i++)
                          {
                           var site =  SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id': apList[i].siteId  });
                           //tJson.response[i].apName= apList[i].name;
                           if(site.length>0) {
                                tJson.response[i].location= site[0].groupNameHierarchy;
                           }

                          }
                      }
                  }
                  return tJson;
              } else {
                  //Assurance UI and backend, for other than iphone/ios device response is not returned, with status code 204, hence added here..
                  return {response:{statusCode:204}};
              }
          }
      }

  };

});
