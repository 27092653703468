define(['core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions'], 
  function (SimLokiDatabaseActions) {
    return {
        init: function() {
          return true
        },
        //database table to use for specific schema keyword
        collectionNames:{          
          'allDevices':'network-device',
          'allDeviceStore':'network-device',
          'allDeviceInterfaces':'device-interface',
          'allSiteStore': 'site',
          'provisionStatus':'DeviceConfigStatus',
          'deviceImage':'device-image',
          'deviceTags':'tag-device-mapping',
          'apFlexGroupName':'network-device',
          'RmaSchema':'device-replacement',
          'allManagedDevices':'network-device',
          'allUncalimedDevices':'onboarding-pnp-device',
          'getAllInsight36':'kairos-network-insight',
          'metrics: monthlyHeatmapRadio36PaginatedInTimeRange':'kairos-heatmap',
          'insight36EntityDetails':'kairos-network-insight-entity-details',
          'insight36DetailsV2':'kairos-network-insight',
          'allFeedback36S':'kairos-feedback',
          'deviceHealth': 'network-device',
          'complianceStoreStatus': 'device-compliance',
          'allImageUpgradeTask': 'image-activation',
          'softwareImage': 'device-image',
          'rmaStoreStatus':'device-replacement',
          'blueBeaconStoreStatus':'network-device',
          'deviceEoX':'deviceEox'
        },
        //how tables are inter-related
        //It reads as: {'table1': {'table2':[<table1.fieldName>, <table2.fieldName>]}}
        collectionRelations:{
          'network-device':
            {
              'DeviceConfigStatus':['id', 'deviceId'],
              'site':['siteId', 'id'],
              'device-image':['id', 'deviceId'],
              'device-interface':['id', 'deviceId'],
              'tag-device-mapping':['id', 'device'],
              'network-device':['id','id'],
              'device-replacement':['hostname', 'faultyDeviceName'],
              'device-compliance':['id','id'],
              'rmaStoreStatus':['hostname', 'faultyDeviceName'],
              'deviceEox':['id', 'deviceId']
            },
          'kairos-network-insight':
            {
              'kairos-network-insight-entity-details':['insightId','insightId']
            },
          'image-activation':
            {
              'device-image':['deviceId','deviceId']
            }
        },
        //field in query against field to pick from table. used when it is second level of table onwards.
        columnNames:{
          'provisionStatus.aggregatedStatus':'DeviceConfigStatus.aggregatedStatus',
          'provisionStatus.telemetryStatus' : 'DeviceConfigStatus.credentialStatus',
          'provisionStatus.credentialStatus':'DeviceConfigStatus.credentialStatus',
          'provisionStatus.lastUpdateTime':'DeviceConfigStatus.lastUpdateTime',
          'provisionStatus.lastProvisionedTime':'DeviceConfigStatus.lastProvisionedTime',
          'latestProvisionStatus.appName':"DeviceConfigStatus.appName",
          'latestProvisionStatus.provisioningFeature':"DeviceConfigStatus.provisioningFeature",
          'latestProvisionStatus.status':"DeviceConfigStatus.aggregatedStatus",
          'latestProvisionStatus.__typename':"DeviceConfigStatus.__typename",
          'site.name':'site.name',
          'site.id':'site.id',
          'site.groupNameHierarchy':'site.groupNameHierarchy',
          'allDeviceInterfaces.deviceId':'device-interface.id',
          "allDeviceInterfaces.id":"device-interface.id",
          "allDeviceInterfaces.portName":"device-interface.portName",
          "allDeviceInterfaces.macAddress":"device-interface.macAddress",
          "allDeviceInterfaces.status":"device-interface.status",
          "allDeviceInterfaces.description":"device-interface.description",
          'deviceImage.runningImage.systemImage.imageName':'device-image.deviceInstalledInfo.name',
          'deviceImage.runningImage.systemImage.imageFamily':'device-image.deviceInstalledInfo.family',
          'deviceImage.runningImage.systemImage.imageVersion':'device-image.deviceInstalledInfo.version',
          'deviceImage.runningImage.systemImage.imageUuid':'device-image.deviceInstalledInfo.imageUuid',
          'deviceImage.deviceInstalledInfo.name':'device-image.deviceInstalledInfo.name',
          'deviceImage.deviceInstalledInfo.type':'device-image.deviceInstalledInfo.imageType',
          'deviceImage.deviceInstalledInfo.version':'device-image.deviceInstalledInfo.version',
          'deviceImage.deviceInstalledInfo.golden':'device-image.deviceInstalledInfo.golden',
          'deviceImage.deviceInstalledInfo.subpackageType':'device-image.deviceInstalledInfo.subpackageType',
          'deviceImage.deviceInstalledInfo.imageType':'device-image.deviceInstalledInfo.imageType',
          'deviceImage.deviceInstalledInfo.isTaggedGolden':'device-image.deviceInstalledInfo.golden',
          'deviceImage.deviceImageUpgradeStatus':'device-image.deviceImageUpgradeStatus',
          'deviceImage.readinessCheckStatus.isReadyForUpgrade':'device-image.readinessCheckStatus.isReadyForUpgrade',
          'deviceImage.upgradeStatus':'device-image.upgradeStatus',
          'deviceImage.deviceId':'device-image.deviceId',
          'deviceImage.imageName':'device-image.deviceInstalledInfo.name',
          'deviceTags.name':'tag-device-mapping.tagName',
          'deviceTags.id':'tag-device-mapping.tag',
          'apFlexGroupName.apGroupName':'network-device.apFlexGroupName.apGroupName',
          'apFlexGroupName.flexGroupName':'network-device.apFlexGroupName.flexGroupName',
          'apFlexGroupName.ledFlashDuration':'network-device.apFlexGroupName.ledFlashDuration',
          'apFlexGroupName.ledFlashEnabled':'network-device.apFlexGroupName.ledFlashEnabled',
          'apFlexGroupName.ledFlashRemainingTime':'network-device.apFlexGroupName.ledFlashRemainingTime',
          'RmaSchema.deviceName':'device-replacement.deviceName',
          'RmaSchema.faultyDeviceName':'device-replacement.faultyDeviceName',
          'RmaSchema.platform':'device-replacement.platform',
          'RmaSchema.faultyDevicePlatform':'device-replacement.faultyDevicePlatform',
          'RmaSchema.deviceSerialNumber':'device-replacement.deviceSerialNumber',
          'RmaSchema.faultyDeviceSerialNumber':'device-replacement.faultyDeviceSerialNumber',
          'RmaSchema.replacementDeviceSerialNumber':'device-replacement.replacementDeviceSerialNumber',
          'RmaSchema.instanceUUId':'device-replacement.instanceUUId',
          'RmaSchema.faultyDeviceId':'device-replacement.faultyDeviceId',
          'RmaSchema.id':'device-replacement.id',
          'RmaSchema.workflowId':'device-replacement.workflowId',
          'RmaSchema.status':'device-replacement.status',
          'RmaSchema.networkReadinessTaskId':'device-replacement.networkReadinessTaskId',
          'RmaSchema.replacementStatus':'device-replacement.replacementStatus',
          'deviceInfo.hostname':'deviceInfo.name',//since this is directly queried from allUncalimedDevices, we are not mentioning the table name
          'deviceInfo.status':'deviceInfo.state',
          'deviceInfo.platform':'deviceInfo.pid',
          'deviceInfo.source':'deviceInfo.source',
          'deviceInfo.ipaddress':'deviceInfo.ipAddress',
          'deviceInfo.serialNumber':'deviceInfo.serialNumber',
          'allUncalimedDevices.pid':'onboarding-pnp-device.deviceInfo.pid',
          'allUncalimedDevices.state':'onboarding-pnp-device.deviceInfo.state',
          'ncubes.ncubeId':'ncubes.ncubeId',
          'ncubes.score':'ncubes.score',
          'ncubes.isOutlier':'ncubes.isOutlier',
          'ap.entityId':'ap.entityId',
          'ap.aesApMac':'ap.aesApMac',
          'ap.aesApName':'ap.aesApName',
          'ap.siteHierarchyArray':'ap.siteHierarchyArray',
          'deviceHealth.score':'network-device.assuranceHealthScore',
          'complianceStoreStatus.complianceStatus':'device-compliance.complianceStatus',
          'softwareImage.deviceImageUpgradeStatus':'device-image.deviceImageUpgradeStatus',
          'rmaStoreStatus.faultyDeviceName':'device-replacement.faultyDeviceName',
          'rmaStoreStatus.faultyDevicePlatform':'device-replacement.faultyDevicePlatform',
          'rmaStoreStatus.faultyDeviceSerialNumber':'device-replacement.faultyDeviceSerialNumber',
          'rmaStoreStatus.replacementDeviceSerialNumber':'device-replacement.replacementDeviceSerialNumber',
          'rmaStoreStatus.id':'device-replacement.id',
          'rmaStoreStatus.workflowId':'device-replacement.workflowId',
          'rmaStoreStatus.status':'device-replacement.status',
          'rmaStoreStatus.networkReadinessTaskId':'device-replacement.networkReadinessTaskId',
          'rmaStoreStatus.replacementStatus':'device-replacement.replacementStatus',
          'blueBeaconStoreStatus.beaconLedStatus':'network-device.blueBeaconStoreStatus.beaconLedStatus',
          'deviceEoX.alertCount':'deviceEox.alertCount',
          'deviceEoX.comments':'deviceEox.comments',
          'deviceEoX.scanStatus':'deviceEox.scanStatus',
          'deviceEoX.summary':'deviceEox.summary',
          'deviceEoX.deviceId':'deviceEox.deviceId',
          'deviceEoX.eoxDetails':'deviceEox.eoxDetails'
        },

        //if the response field is not directly the value from table
        specialHandling:{
          deviceInstalledInfo:x=>[x],
          configStatus:x=>null,
          deviceTags:x=>{
            if(x.id==null) return []
            //console.log(x, SimLokiDatabaseActions.getFilteredRecordHandler('tag-device-mapping',{'tag':x.id}))
            let deviceId = SimLokiDatabaseActions.getFilteredRecordHandler('tag-device-mapping',{'tag':x.id})[0].device            
            return SimLokiDatabaseActions.getFilteredRecordHandler('tag-device-mapping',{'device':deviceId})
                    .map(e=>{return {id:e.tag, name:e.tagName}})
          },
          ncubes:x=>[x],
          deviceSupportLevel:x=>'Supported',
          blueBeaconStoreStatus:x=>{ return {"beaconLedStatus":"Not Supported"} },
          rmaStoreStatus:x=>{
            return x.replacementStatus==null ? null : x;
          }
        },

        //if filter sends different key/value that table field/value doesnt match with, respectively
        filterExceptions: {
          'updateValue':{
            'family': {'Switches':'Switches and Hubs', 'switches and hubs': 'Switches and Hubs', 'routers': 'Routers',
                        'unified ap': 'Unified AP','wireless controller':'Wireless Controller','nfvis':'NFVIS',
                        'router': 'Routers', 'sensor': 'Wireless Sensor', 'sensors': 'Wireless Sensor'},
            'reachabilityStatus': {'Unreachable':'UnReachable','reachable':'Reachable','unreachable':'UnReachable'},
            'role':{'access':'ACCESS','border router':'BORDER ROUTER','core':'CORE','unknown':'UNKNOWN'},
            'deviceSupportLevel':{'supported':'Supported'},
            'collectionStatus': {'managed':'Managed','in progress': 'In Progress','could not synchronize':'Could Not Synchronize',
                                    'partial collection failure':'Partial Collection Failure'},
            /*'provisionStatus.aggregatedStatus': {'not provisioned':'Not Provisioned','success':'Success'},
            'provisionStatus.credentialStatus': {'not provisioned':'Not Provisioned'} ,*/
            'deviceImage.deviceImageUpgradeStatus':{'outdated': 'OUTDATED' }
          },
          'updateKey':
            {'graphIds': 'id', 'site.name': 'site.groupNameHierarchy', 'lastUpdated':'lastUpdateTime'}
        },

        arrayTypes: ['heatmapData','heatmapKpiStats', 'impactedEntityHeatmapData', 'ncubeDetails','entityStats'],
        arrayFiltersBasedOnVariables:{
          'bandId':(d, v)=>{
            let translation = 'frequencyBand'
            if(v['bandId']==='all') return true
            return d[translation]==v['bandId']
          }},

        //in some cases, __typename in the outermost object is checked in UI. such additions can be updated here.
        typeNames: {
          'getAllInsight36':'GetAllInsight36Connection',
          'insight36EntityDetails':'Insight36EntityDetailsConnection',
          'insight36DetailsV2':'Insight36DetailsV2Connection',
          'allFeedback36S':'Feedback36SConnection',
          'allImageUpgradeTask':'ImageUpgradeTaskSchema'
        },

        //filters to handle form variables section of restpayload. key used is operation name from restpayload.
        variableFiltersToHandle: {
          'allFeedbackByUserQuery':['resourceId','userId'],
          'getAllInsightQuery':['insightTypeFilter'],
          'insightEntityDetails':['insightId'],
          'insightDetails':['insightId','kpi']
        }

      }
    });
