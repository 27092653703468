define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimDeviceImageData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions'
], function (SimDeviceImageData, SimLokiDatabaseActions) {

    return {
        init: function() {
        },
        /* Handles the requests to display the image data in Network-->Devices tab.
           So that the image version and name are displayed for the devices.
           @ urlAction  has the details of url
        */
        doProcessRestRequest: function(urlAction){
            var data;
            switch(urlAction.method) {
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                case 'POST':
                    data = processPostRequest(urlAction);
                    break;

                default:
                    break;
            }
            return data;
        }
    };


    function processGetRequest(urlAction){
        if(urlAction.service.indexOf('deviceUpgradeStatus')>=0 ) {
            var data = ['Tag Golden', 'Distribution Pending', 'Activation Success'];
            return data;
        }
        if(urlAction.service.indexOf('device-image')>=0 ) {
            var data = SimDeviceImageData.fetchRunningImage(urlAction);
            return data;
        }
    }

    function processPostRequest(urlAction){
        var data =SimLokiDatabaseActions.getAll('servers');
        if(urlAction.url.indexOf('global')>=0) {
            var data =SimDesignData.updateServers(urlAction);
            return data;
        }
    }
});
