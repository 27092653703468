
define({
  "License_ViewAllDetails_Template":
    {
      "stackedDevices": [],
      "hasSupCards": false,
      "networkLicense": "NA",
      "reservationStatus": "Unknown",
      "status": "Inactive",
      "virtualAccount": "NA",
      "model": "Cisco Catalyst 6800 Series Switches",
      "isStackedDevice": false,
      "isLicenseExpired": null,
      "licenseExpiry": "NA",
      "hasModules": true,
      "macAddress": "18:9c:5d:5e:38:00",
      "apps": {
        "essentialApps": [],
        "advantageApps": []
      },
      "accessPoints": [],
      "deviceName": "6807",
      "udi": "C6807-XL:SMC1803008B",
      "sntcStatus": "Invalid",
      "softwareVersion": "15.1",
      "operationInProgress": "NA",
      "licenseMode": "NA",
      "licenseTerm": null,
      "dnaLevel": "",
      "registrationStatus": "Unknown",
      "featureLicense": [],
      "authorizationStatus": "Unknown",
      "chassisDetails": {
        "supCards": [],
        "modules": [
          {
            "id": 1,
            "moduleName": "1",
            "moduleType": "WS-X6848-GE-TX CEF720 48 port 10/100/1000mb Ethernet Rev. 1.0",
            "serialNo": "SAL1810N3XV"
          },
          {
            "id": 1,
            "moduleName": "CPU of Module 1",
            "moduleType": "CPU of Module 1",
            "serialNo": "NA"
          },
          {
            "id": 1,
            "moduleName": "WS-F6K-DFC4-A Distributed Forwarding Card 4 EARL sub-module of 1",
            "moduleType": "WS-F6K-DFC4-A Distributed Forwarding Card 4 Rev. 2.0",
            "serialNo": "SAL1812NTRY"
          },
          {
            "id": 1,
            "moduleName": "2",
            "moduleType": "WS-SVC-WISM2-K9 4 ports WiSM 2 WLAN Service Module Rev. 1.1",
            "serialNo": "SAL18495VU2"
          },
          {
            "id": 1,
            "moduleName": "CPU of Module 2",
            "moduleType": "CPU of Module 2",
            "serialNo": "NA"
          },
          {
            "id": 1,
            "moduleName": "3",
            "moduleType": "VS-SUP2T-10G 5 ports Supervisor Engine 2T 10GE w/ CTS Rev. 1.7",
            "serialNo": "SAL1812NWXR"
          },
          {
            "id": 1,
            "moduleName": "msfc sub-module of 3",
            "moduleType": "VS-F6K-MSFC5 CPU Daughterboard Rev. 2.1",
            "serialNo": "SAL1812NVJ3"
          },
          {
            "id": 1,
            "moduleName": "CPU of Routing Processor 3",
            "moduleType": "CPU of Routing Processor 3",
            "serialNo": "NA"
          },
          {
            "id": 1,
            "moduleName": "VS-F6K-PFC4 Policy Feature Card 4 EARL sub-module of 3",
            "moduleType": "VS-F6K-PFC4 Policy Feature Card 4 Rev. 2.1",
            "serialNo": "SAL1813NXLE"
          },
          {
            "id": 1,
            "moduleName": "Transceiver Gi3/1",
            "moduleType": "SFP Transceiver 10/100/1000BaseT Gi3/1",
            "serialNo": "AGM155125KR     "
          },
          {
            "id": 1,
            "moduleName": "Transceiver Gi3/2",
            "moduleType": "SFP Transceiver 10/100/1000BaseT Gi3/2",
            "serialNo": "AGM1751263X     "
          },
          {
            "id": 1,
            "moduleName": "Transceiver Gi3/3",
            "moduleType": "SFP Transceiver 10/100/1000BaseT Gi3/3",
            "serialNo": "AGM17112978     "
          },
          {
            "id": 1,
            "moduleName": "Transceiver Te3/4",
            "moduleType": "X2 Transceiver 10Gbase-SR Te3/4",
            "serialNo": "AGA1734X115"
          },
          {
            "id": 1,
            "moduleName": "Transceiver Te3/5",
            "moduleType": "X2 Transceiver 10Gbase-LR Te3/5",
            "serialNo": "ONT173000SU"
          },
          {
            "id": 1,
            "moduleName": "WS-C6K-VTT-E 1",
            "moduleType": "VTT-E FRU 1",
            "serialNo": "SMT1750B357"
          },
          {
            "id": 1,
            "moduleName": "WS-C6K-VTT-E 2",
            "moduleType": "VTT-E FRU 2",
            "serialNo": "SMT1750B297"
          },
          {
            "id": 1,
            "moduleName": "WS-C6K-VTT-E 3",
            "moduleType": "VTT-E FRU 3",
            "serialNo": "SMT1750B291"
          },
          {
            "id": 1,
            "moduleName": "CLK-7600 1",
            "moduleType": "OSR-7600 Clock FRU 1",
            "serialNo": "SMT1747E115"
          },
          {
            "id": 1,
            "moduleName": "CLK-7600 2",
            "moduleType": "OSR-7600 Clock FRU 2",
            "serialNo": "SMT1747E115"
          },
          {
            "id": 1,
            "moduleName": "C6800-XL-PS-CONV 1",
            "moduleType": "C6807 power converter 1",
            "serialNo": "SMC1803607A"
          },
          {
            "id": 1,
            "moduleName": "C6800-XL-PS-CONV 2",
            "moduleType": "C6807 power converter 2",
            "serialNo": "SMC1803607J"
          }
        ],
        "boardSerialNo": "SMC1803008B",
        "port": 0
      },
      "daysToExpiry": null,
      "lastUpdatedTime": "2018-10-29T09:21:27.693000",
      "id": "2fdce05f-00ca-408c-8df8-b4b570be1b52",
      "location": "Unassigned",
      "deviceType": "Switches and Hubs",
      "auditInfo": [],
      "ipAddress": "10.13.0.2"
    },

    "LICENSE_HISTORY_TEMPLATE": [
      {
        "log": "eventSource=\"INFRA_SL\" eventName=\"INFRA_SL_EVLOG_ERM_RESET\" MSG=\"ERM-Reset: Client 0, AP-GROUP group, 2 features air-network-advantage,air-dna-advantage\"",
        "log_date": "YEST_DATE 15:28:56.292 UTC",
        "event_type": "SAEVT_PLATFORM"
      },
      {
        "log": "eventSource=\"INFRA_SL\" eventName=\"INFRA_SL_EVLOG_ERM_RESET\" MSG=\"ERM-Reset: Client 0, AP-GROUP group, 2 features air-network-advantage,air-dna-advantage\"",
        "log_date": "YEST_DATE 07:28:56.312 UTC",
        "event_type": "SAEVT_PLATFORM"
      },
      {
        "log": null,
        "log_date": "YEST_DATE 06:29:30.327 UTC",
        "event_type": "SAEVT_COMM_RESTORED"
      },
      {
        "log": "count=\"0\" type=\"destroy\" entitlementTag=\"regid.CURR_YR_PREV_MON.com.cisco.PID_PART_dna_advantage,1.0_411773c3-2116-4c10-94a4-5d357fe6ff18\"",
        "log_date": "YEST_DATE 06:28:58.441 UTC",
        "event_type": "SAEVT_LICENSE_USAGE"
      },
      {
        "log": "count=\"0\" type=\"destroy\" entitlementTag=\"regid.CURR_YR_CURR_MON.com.cisco.advantage_PID_PART,1.0_bd1da96e-ec1d-412b-a50e-53846b347d53\"",
        "log_date": "YEST_DATE 06:28:58.440 UTC",
        "event_type": "SAEVT_LICENSE_USAGE"
      },
      {
        "log": "eventSource=\"INFRA_SL\" eventName=\"INFRA_SL_EVLOG_OIR_ADD\" MSG=\"OIR Add evt 100 with slot-id 1\"",
        "log_date": "YEST_DATE 06:28:58.434 UTC",
        "event_type": "SAEVT_PLATFORM"
      },
    {
        "log": "eventSource=\"INFRA_SL\" eventName=\"INFRA_SL_EVLOG_LICENSE_REQUEST\" MSG=\"License dna-advantage, device id PID, count req 1, inuse 0, reslt 0, hndl 2\"",
        "log_date": "YEST_DATE 06:28:50.054 UTC",
        "event_type": "SAEVT_PLATFORM"
      },
      {
        "log": "count=\"1\" type=\"total\" entitlementTag=\"regid.CURR_YR_PREV_MON.com.cisco.PID_PART_dna_advantage,1.0_411773c3-2116-4c10-94a4-5d357fe6ff18\"",
        "log_date": "YEST_DATE 06:28:50.054 UTC",
        "event_type": "SAEVT_LICENSE_USAGE"
      },
      {
        "log": "eventSource=\"INFRA_SL\" eventName=\"INFRA_SL_EVLOG_LICENSE_REQUEST\" MSG=\"License network-advantage, device id PID, count req 1, inuse 0, reslt 0, hndl 1\"",
        "log_date": "YEST_DATE 06:28:50.053 UTC",
        "event_type": "SAEVT_PLATFORM"
      },
      {
        "log": "count=\"1\" type=\"total\" entitlementTag=\"regid.CURR_YR_CURR_MON.com.cisco.advantage_PID_PART,1.0_bd1da96e-ec1d-412b-a50e-53846b347d53\"",
        "log_date": "YEST_DATE 06:28:50.052 UTC",
        "event_type": "SAEVT_LICENSE_USAGE"
      }
    ]

});