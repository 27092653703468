define({
    "runningImageList":[ 
      {  
        "name":"ie3x00-universalk9.17.09.01.SPA.bin",
        "imageUuid":"9703955e-12bd-1a2b-fdef-57e5c1b66a8d",
        "version":"17.9.1",
        "softwareType":null,
        "imageType":"RELEASE",
        "isTaggedGolden":"true",
        "md5Checksum":"2c3ebe52ecc3adc7f1f9b99df09b649e",
        "shaCheckSum":"db4d42b0e2a026c13b1871f2665df6c3dc8a6d2f0c97fa8487c17edac13a9ed86b4766b6823b9aa2d7d4d2be5d3db59f8105936183cf6f6a0f8737db4c2de1b7",
        "createdTime":"2022-08-01 19:35:00.00",
        "applicationType":null,
        "feature":null,
        "fileServiceId":"ff12386c-3457-4532-cd12-f2619cebe23c",
        "imageSeries":null,
        "imageSource":"CCO",
        "imageSourceType":"Imported from CCO",
        "imageFamily":"IE3400",
        "vendor":null,
        "filesize":343483250,
        "deviceCount":0,
        "deviceUuidList":[  
        ],
        "smuCount":0,
        "imageAttributes":[    
        ],
        "isSMUApplicable":false,
        "goldenTagInheritedFromSiteUuid":null,
        "goldenTagInheritedFromSiteName":null,
        "importSupported":true,
        "reasonForNotSupportingImport":null,
        "tagList":[  
          {
            "taggedGolden": true,
            "role": "ALL",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "imageCategory":"PHYSICAL",
        "imageIntegrityStatus":null,
        "ccorecommended":false,
        "ccolatest":true
      },{  
        "name":"ie3x00-universalk9.17.06.01.SPA.bin",
        "imageUuid":"9703955e-12bd-1d24-fdef-57e5c1b66a8d",
        "version":"17.6.1",
        "softwareType":null,
        "imageType":"RELEASE",
        "isTaggedGolden":"false",
        "md5Checksum":"2c3ebe52ecc3adc7f1f9b99df09b649e",
        "shaCheckSum":"db4d42b0e2a026c13b1871f2665df6c3dc8a6d2f0c97fa8487c17edac13a9ed86b4766b6823b9aa2d7d4d2be5d3db59f8105936183cf6f6a0f8737db4c2de1b7",
        "createdTime":"2021-09-16 19:35:00.00",
        "applicationType":null,
        "feature":null,
        "fileServiceId":"ff12386c-3457-8758-cd12-f2619cebe23c",
        "imageSeries":null,
        "imageSource":"CCO",
        "imageSourceType":"Imported from CCO",
        "imageFamily":"IE3400",
        "vendor":null,
        "filesize":343483250,
        "deviceCount":0,
        "deviceUuidList":[  
        ],
        "smuCount":0,
        "imageAttributes":[    
        ],
        "isSMUApplicable":false,
        "goldenTagInheritedFromSiteUuid":null,
        "goldenTagInheritedFromSiteName":null,
        "importSupported":true,
        "reasonForNotSupportingImport":null,
        "tagList":[  
          {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "imageCategory":"PHYSICAL",
        "imageIntegrityStatus":null,
        "ccorecommended":false,
        "ccolatest":true
      }, 
      {  
        "name":"ie3x00-universalk9.17.03.03.SPA.bin",
        "imageUuid":"9703955e-1254-1d24-fdef-57e5c1b66a8d",
        "version":"17.3.3",
        "softwareType":null,
        "imageType":"RELEASE",
        "isTaggedGolden":"false",
        "md5Checksum":"9e743586c307e82e294f5a75dd5216fd",
        "shaCheckSum":"2a0d65006ea437ab4bbce345a9089c9a0417ec5caf2f0dd114bae980a5322aeba21070cb3179919bdc8ea73414c294f499866987afabc0b8a5294df5f2fb05c1",
        "createdTime":"2021-03-15 19:35:00.00",
        "applicationType":null,
        "feature":null,
        "fileServiceId":"ff12386c-3457-8758-cd12-f2619cebe23c",
        "imageSeries":null,
        "imageSource":"CCO",
        "imageSourceType":"Imported from CCO",
        "imageFamily":"IE3400",
        "vendor":null,
        "filesize":343483250,
        "deviceCount":0,
        "deviceUuidList":[  
        ],
        "smuCount":0,
        "imageAttributes":[    
        ],
        "isSMUApplicable":false,
        "goldenTagInheritedFromSiteUuid":null,
        "goldenTagInheritedFromSiteName":null,
        "importSupported":true,
        "reasonForNotSupportingImport":null,
        "tagList":[  
          {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "imageCategory":"PHYSICAL",
        "imageIntegrityStatus":null,
        "ccorecommended":false,
        "ccolatest":true
      },
      {  
        "name":"ie3x00-universalk9.17.03.01.SPA.bin",
        "imageUuid":"9703955e-1254-4229-fdef-57e5c1b66a8d",
        "version":"17.3.1",//Amsterdam-17.3.1
        "softwareType":null,
        "imageType":"RELEASE",
        "isTaggedGolden":"false",
        "md5Checksum":"37be5583d68f257855142889053e569d",
        "shaCheckSum":"ca8849611cd777569ba4706cb2c6de2324d959839ae7db516fee2d4b41d37b796d734aeb1ff0ad514b70435841fcf641ca37b1a9763bd910fcc162b4fcd696d8",
        "createdTime":"2020-10-16 14:59:00.00",//09 Aug 2020 Release
        "applicationType":null,
        "feature":null,
        "fileServiceId":"ff12386c-3457-1212-cd12-f2619cebe23c",
        "imageSeries":null,
        "imageSource":"CCO",
        "imageSourceType":"Imported from CCO",
        "imageFamily":"IE3400",
        "vendor":null,
        "filesize":344339501,
        "deviceCount":0,
        "deviceUuidList":[  
        ],
        "smuCount":0,
        "imageAttributes":[    
        ],
        "isSMUApplicable":false,
        "goldenTagInheritedFromSiteUuid":null,
        "goldenTagInheritedFromSiteName":null,
        "importSupported":true,
        "reasonForNotSupportingImport":null,
        "tagList":[  
          {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "CORE",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "DISTRIBUTION",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "BORDER ROUTER",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "UNKNOWN",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "ACCESS",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "imageCategory":"PHYSICAL",
        "imageIntegrityStatus":null,
        "ccorecommended":false,
        "ccolatest":true
      },
      {  
        "name":"ie3x00-universalk9.17.02.01.SPA.bin",
        "imageUuid":"9703955e-6806-4229-fdef-57e5c1b66a8d",
        "version":"17.2.1",//Amsterdam-17.2.1
        "softwareType":null,
        "imageType":"RELEASE",
        "isTaggedGolden":"false",
        "md5Checksum":"eb30ee1571697662944e3c9b7813c64e",
        "shaCheckSum":"fd90516438d3807bb41cad2cc64d6c00bd9e0c76e940688b09af3b3f9d63230816698af4c4d8349a5e388c19e2ca3e7bfc8b8fd85443f0efcd9ff1efd7563ee0",
        "createdTime":"2020-04-28 22:06:00.00",//28 Mar 2020 Release
        "applicationType":null,
        "feature":null,
        "fileServiceId":"cc12386c-3457-1212-cd12-f2619cebe23c",
        "imageSeries":null,
        "imageSource":"CCO",
        "imageSourceType":"Imported from CCO",
        "imageFamily":"IE3400",
        "vendor":null,
        "filesize":274785773,
        "deviceCount":0,
        "deviceUuidList":[  
        ],
        "smuCount":0,
        "imageAttributes":[    
        ],
        "isSMUApplicable":false,
        "goldenTagInheritedFromSiteUuid":null,
        "goldenTagInheritedFromSiteName":null,
        "importSupported":true,
        "reasonForNotSupportingImport":null,
        "tagList":[  
          {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "CORE",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "DISTRIBUTION",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "BORDER ROUTER",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "UNKNOWN",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "ACCESS",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "imageCategory":"PHYSICAL",
        "imageIntegrityStatus":null,
        "ccorecommended":false,
        "ccolatest":true
      },
      {  
        "name":"ie3x00-universalk9.17.01.01.SPA.bin",
        "imageUuid":"9703955e-6806-4229-cdef-57e5c1b66a8d",
        "version":"17.1.1",//Amsterdam-17.1.1
        "softwareType":null,
        "imageType":"RELEASE",
        "isTaggedGolden":"false",
        "md5Checksum":"eb30ee1571697662944e3c9b7813c64e",
        "shaCheckSum":"fd90516438d3807bb41cad2cc64d6c00bd9e0c76e940688b09af3b3f9d63230816698af4c4d8349a5e388c19e2ca3e7bfc8b8fd85443f0efcd9ff1efd7563ee0",
        "createdTime":"2019-11-23 10:00:00.00",
        "applicationType":null,
        "feature":null,
        "fileServiceId":"bb12386c-3457-1212-cd12-f2619cebe23c",
        "imageSeries":null,
        "imageSource":"CCO",
        "imageSourceType":"Imported from CCO",
        "imageFamily":"IE3400",
        "vendor":null,
        "filesize":274785773,
        "deviceCount":0,
        "deviceUuidList":[  
        ],
        "smuCount":0,
        "imageAttributes":[    
        ],
        "isSMUApplicable":false,
        "goldenTagInheritedFromSiteUuid":null,
        "goldenTagInheritedFromSiteName":null,
        "importSupported":true,
        "reasonForNotSupportingImport":null,
        "tagList":[  
          {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "CORE",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "DISTRIBUTION",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "BORDER ROUTER",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "UNKNOWN",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "ACCESS",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "imageCategory":"PHYSICAL",
        "imageIntegrityStatus":null,
        "ccorecommended":false,
        "ccolatest":true
      }
 ]
});
