define({
    "WIFI6_AT_BE": {
        "records": [
            {
                "modificationtime": 1588617300000,
                "axATE": 69.65721292633829,
                "nonAxBytes": 29390067,
                "axBytes": 82909637,
                "axAirtime": 1190252,
                "nonAxAirtime": 588648,
                "nonAxATE": 49.928084356015816,
                "timestamp": "2020-05-04T18:35:00.000+0000"
            },
            {
                "modificationtime": 1588617600000,
                "axATE": 68.87106345631892,
                "nonAxBytes": 28224921,
                "axBytes": 88202620,
                "axAirtime": 1280692,
                "nonAxAirtime": 563336,
                "nonAxATE": 50.10317288438871,
                "timestamp": "2020-05-04T18:40:00.000+0000"
            },
            {
                "modificationtime": 1588617900000,
                "axATE": 69.22293313794984,
                "nonAxBytes": 33022020,
                "axBytes": 81251110,
                "axAirtime": 1173760,
                "nonAxAirtime": 658936,
                "nonAxATE": 50.114153726613814,
                "timestamp": "2020-05-04T18:45:00.000+0000"
            },
            {
                "modificationtime": 1588618200000,
                "axATE": 69.86610412495277,
                "nonAxBytes": 35012254,
                "axBytes": 79138454,
                "axAirtime": 1132716,
                "nonAxAirtime": 699440,
                "nonAxATE": 50.05755175569027,
                "timestamp": "2020-05-04T18:50:00.000+0000"
            },
            {
                "modificationtime": 1588618500000,
                "axATE": 68.70291871140708,
                "nonAxBytes": 39244534,
                "axBytes": 82823567,
                "axAirtime": 1205532,
                "nonAxAirtime": 771060,
                "nonAxATE": 50.89686146343994,
                "timestamp": "2020-05-04T18:55:00.000+0000"
            },
            {
                "modificationtime": 1588618800000,
                "axATE": 68.12773065150853,
                "nonAxBytes": 21484148,
                "axBytes": 77904060,
                "axAirtime": 1143500,
                "nonAxAirtime": 426916,
                "nonAxATE": 50.32406375024595,
                "timestamp": "2020-05-04T19:00:00.000+0000"
            },
            {
                "modificationtime": 1588619100000,
                "axATE": 69.37650230205413,
                "nonAxBytes": 25569961,
                "axBytes": 72087181,
                "axAirtime": 1039072,
                "nonAxAirtime": 505108,
                "nonAxATE": 50.62275988501469,
                "timestamp": "2020-05-04T19:05:00.000+0000"
            },
            {
                "modificationtime": 1588619400000,
                "axATE": 68.7744307076276,
                "nonAxBytes": 23107880,
                "axBytes": 69457911,
                "axAirtime": 1009938,
                "nonAxAirtime": 465336,
                "nonAxATE": 49.658483332473736,
                "timestamp": "2020-05-04T19:10:00.000+0000"
            },
            {
                "modificationtime": 1588619700000,
                "axATE": 68.77291423225539,
                "nonAxBytes": 30856940,
                "axBytes": 76746721,
                "axAirtime": 1115944,
                "nonAxAirtime": 608292,
                "nonAxATE": 50.72718365521822,
                "timestamp": "2020-05-04T19:15:00.000+0000"
            },
            {
                "modificationtime": 1588620000000,
                "axATE": 69.47634471716334,
                "nonAxBytes": 24768497,
                "axBytes": 77411377,
                "axAirtime": 1114212,
                "nonAxAirtime": 495548,
                "nonAxATE": 49.982034031012134,
                "timestamp": "2020-05-04T19:20:00.000+0000"
            },
            {
                "modificationtime": 1588620300000,
                "axATE": 69.58717034656395,
                "nonAxBytes": 31669505,
                "axBytes": 95962378,
                "axAirtime": 1379024,
                "nonAxAirtime": 633888,
                "nonAxATE": 49.96072650058054,
                "timestamp": "2020-05-04T19:25:00.000+0000"
            },
            {
                "modificationtime": 1588620600000,
                "axATE": 68.2353600216337,
                "nonAxBytes": 4996790,
                "axBytes": 188742827,
                "axAirtime": 2766056,
                "nonAxAirtime": 109960,
                "nonAxATE": 45.44188795925791,
                "timestamp": "2020-05-04T19:30:00.000+0000"
            },
            {
                "modificationtime": 1588620900000,
                "axATE": 68.67724567171052,
                "nonAxBytes": 25755495,
                "axBytes": 72782222,
                "axAirtime": 1059772,
                "nonAxAirtime": 515812,
                "nonAxATE": 49.931942258031995,
                "timestamp": "2020-05-04T19:35:00.000+0000"
            },
            {
                "modificationtime": 1588621200000,
                "axATE": 69.70893730078004,
                "nonAxBytes": 28567857,
                "axBytes": 81966278,
                "axAirtime": 1175836,
                "nonAxAirtime": 563860,
                "nonAxATE": 50.66480509346292,
                "timestamp": "2020-05-04T19:40:00.000+0000"
            },
            {
                "modificationtime": 1588621500000,
                "axATE": 68.00286517402677,
                "nonAxBytes": 29204020,
                "axBytes": 75190224,
                "axAirtime": 1105692,
                "nonAxAirtime": 568144,
                "nonAxATE": 51.402496550170376,
                "timestamp": "2020-05-04T19:45:00.000+0000"
            },
            {
                "modificationtime": 1588621800000,
                "axATE": 68.63449437547956,
                "nonAxBytes": 30698014,
                "axBytes": 80147519,
                "axAirtime": 1167744,
                "nonAxAirtime": 604460,
                "nonAxATE": 50.78584852595705,
                "timestamp": "2020-05-04T19:50:00.000+0000"
            },
            {
                "modificationtime": 1588622100000,
                "axATE": 68.92291492543879,
                "nonAxBytes": 24032106,
                "axBytes": 75207582,
                "axAirtime": 1091184,
                "nonAxAirtime": 473224,
                "nonAxATE": 50.783785268709956,
                "timestamp": "2020-05-04T19:55:00.000+0000"
            },
            {
                "modificationtime": 1588622400000,
                "axATE": 68.95980136802483,
                "nonAxBytes": 23215444,
                "axBytes": 80169355,
                "axAirtime": 1162552,
                "nonAxAirtime": 521952,
                "nonAxATE": 44.47812059346453,
                "timestamp": "2020-05-04T20:00:00.000+0000"
            },
            {
                "modificationtime": 1588622700000,
                "axATE": 68.61552382885625,
                "nonAxBytes": 3990,
                "axBytes": 304220648,
                "axAirtime": 4433700,
                "nonAxAirtime": 2984,
                "nonAxATE": 1.3371313672922251,
                "timestamp": "2020-05-04T20:05:00.000+0000"
            },
            {
                "modificationtime": 1588623000000,
                "axATE": 69.04666129780121,
                "nonAxBytes": 4720,
                "axBytes": 282932504,
                "axAirtime": 4097700,
                "nonAxAirtime": 3384,
                "nonAxATE": 1.3947990543735225,
                "timestamp": "2020-05-04T20:10:00.000+0000"
            },
            {
                "modificationtime": 1588623300000,
                "axATE": 68.12437301096345,
                "nonAxBytes": 23299817,
                "axBytes": 73123612,
                "axAirtime": 1073384,
                "nonAxAirtime": 486676,
                "nonAxATE": 47.87541814266576,
                "timestamp": "2020-05-04T20:15:00.000+0000"
            },
            {
                "modificationtime": 1588623600000,
                "axATE": 17.065217391304348,
                "nonAxBytes": 2585036,
                "axBytes": 122460,
                "axAirtime": 7176,
                "nonAxAirtime": 110884,
                "nonAxATE": 23.31297572237654,
                "timestamp": "2020-05-04T20:20:00.000+0000"
            },
            {
                "modificationtime": 1588623900000,
                "axATE": 69.28928562942546,
                "nonAxBytes": 5644628,
                "axBytes": 69986613,
                "axAirtime": 1010064,
                "nonAxAirtime": 143172,
                "nonAxATE": 39.42550219316626,
                "timestamp": "2020-05-04T20:25:00.000+0000"
            },
            {
                "modificationtime": 1588624200000,
                "axATE": 70.04829310608767,
                "nonAxBytes": 755408,
                "axBytes": 54909456,
                "axAirtime": 783880,
                "nonAxAirtime": 60108,
                "nonAxATE": 12.567511812071604,
                "timestamp": "2020-05-04T20:30:00.000+0000"
            },
            {
                "modificationtime": 1588624500000,
                "axATE": 68.79790037862026,
                "nonAxBytes": 32410858,
                "axBytes": 83948574,
                "axAirtime": 1220220,
                "nonAxAirtime": 639664,
                "nonAxATE": 50.6685666224768,
                "timestamp": "2020-05-04T20:35:00.000+0000"
            },
            {
                "modificationtime": 1588624800000,
                "axATE": 68.90543145561794,
                "nonAxBytes": 10211806,
                "axBytes": 135972466,
                "axAirtime": 1973320,
                "nonAxAirtime": 263160,
                "nonAxATE": 38.80455236358109,
                "timestamp": "2020-05-04T20:40:00.000+0000"
            },
            {
                "modificationtime": 1588625100000,
                "axATE": 69.27647627615498,
                "nonAxBytes": 34305298,
                "axBytes": 90558764,
                "axAirtime": 1307208,
                "nonAxAirtime": 680792,
                "nonAxATE": 50.39027779409864,
                "timestamp": "2020-05-04T20:45:00.000+0000"
            },
            {
                "modificationtime": 1588625400000,
                "axATE": 69.63205912971763,
                "nonAxBytes": 33279035,
                "axBytes": 82404807,
                "axAirtime": 1183432,
                "nonAxAirtime": 660856,
                "nonAxATE": 50.35746819276817,
                "timestamp": "2020-05-04T20:50:00.000+0000"
            },
            {
                "modificationtime": 1588625700000,
                "axATE": 68.993794977236,
                "nonAxBytes": 35383565,
                "axBytes": 97713980,
                "axAirtime": 1416272,
                "nonAxAirtime": 694076,
                "nonAxATE": 50.97938122050035,
                "timestamp": "2020-05-04T20:55:00.000+0000"
            },
            {
                "modificationtime": 1588626000000,
                "axATE": 68.88431433534991,
                "nonAxBytes": 37218158,
                "axBytes": 85488465,
                "axAirtime": 1241044,
                "nonAxAirtime": 725480,
                "nonAxATE": 51.30142526327397,
                "timestamp": "2020-05-04T21:00:00.000+0000"
            },
            {
                "modificationtime": 1588626300000,
                "axATE": 67.06754136032464,
                "nonAxBytes": 8912046,
                "axBytes": 60588951,
                "axAirtime": 903402,
                "nonAxAirtime": 215768,
                "nonAxATE": 41.30383560120129,
                "timestamp": "2020-05-04T21:05:00.000+0000"
            },
            {
                "modificationtime": 1588626600000,
                "axATE": 68.8951388888889,
                "nonAxBytes": 25681190,
                "axBytes": 67660538,
                "axAirtime": 982080,
                "nonAxAirtime": 506178,
                "nonAxATE": 25.511885983206547,
                "timestamp": "2020-05-04T21:10:00.000+0000"
            },
            {
                "modificationtime": 1588626900000,
                "axATE": 69.13865266514316,
                "nonAxBytes": 32070055,
                "axBytes": 82048775,
                "axAirtime": 1186728,
                "nonAxAirtime": 638123,
                "nonAxATE": 25.237788598959266,
                "timestamp": "2020-05-04T21:15:00.000+0000"
            },
            {
                "modificationtime": 1588627200000,
                "axATE": 68.58761089737318,
                "nonAxBytes": 27517851,
                "axBytes": 86927115,
                "axAirtime": 1267388,
                "nonAxAirtime": 571722,
                "nonAxATE": 24.204949808335794,
                "timestamp": "2020-05-04T21:20:00.000+0000"
            },
            {
                "modificationtime": 1588627500000,
                "axATE": 69.36301810659256,
                "nonAxBytes": 29658234,
                "axBytes": 89457207,
                "axAirtime": 1289696,
                "nonAxAirtime": 581440,
                "nonAxATE": 51.00824504678041,
                "timestamp": "2020-05-04T21:25:00.000+0000"
            },
            {
                "modificationtime": 1588627800000,
                "axATE": 69.0101372126659,
                "nonAxBytes": 32693530,
                "axBytes": 80350159,
                "axAirtime": 1164324,
                "nonAxAirtime": 638264,
                "nonAxATE": 51.22258187834501,
                "timestamp": "2020-05-04T21:30:00.000+0000"
            },
            {
                "modificationtime": 1588628100000,
                "axATE": 69.06496056253167,
                "nonAxBytes": 15442323,
                "axBytes": 80697710,
                "axAirtime": 1168432,
                "nonAxAirtime": 345212,
                "nonAxATE": 44.73286849819821,
                "timestamp": "2020-05-04T21:35:00.000+0000"
            },
            {
                "modificationtime": 1588628400000,
                "axATE": 68.89684943389115,
                "nonAxBytes": 26642234,
                "axBytes": 114059561,
                "axAirtime": 1655512,
                "nonAxAirtime": 582256,
                "nonAxATE": 45.756907614520074,
                "timestamp": "2020-05-04T21:40:00.000+0000"
            },
            {
                "modificationtime": 1588628700000,
                "axATE": 68.62298389549098,
                "nonAxBytes": 34927613,
                "axBytes": 90267222,
                "axAirtime": 1315408,
                "nonAxAirtime": 693824,
                "nonAxATE": 50.34073914998616,
                "timestamp": "2020-05-04T21:45:00.000+0000"
            },
            {
                "modificationtime": 1588629000000,
                "axATE": 63.15161851179249,
                "nonAxBytes": 36887639,
                "axBytes": 86519233,
                "axAirtime": 1370024,
                "nonAxAirtime": 717976,
                "nonAxATE": 51.37725912843884,
                "timestamp": "2020-05-04T21:50:00.000+0000"
            },
            {
                "modificationtime": 1588629300000,
                "axATE": 68.10758635126649,
                "nonAxBytes": 18434846,
                "axBytes": 97604982,
                "axAirtime": 1433100,
                "nonAxAirtime": 361644,
                "nonAxATE": 50.97511917797613,
                "timestamp": "2020-05-04T21:55:00.000+0000"
            },
            {
                "modificationtime": 1588629600000,
                "axATE": 69.01100162938532,
                "nonAxBytes": 31827397,
                "axBytes": 84199771,
                "axAirtime": 1220092,
                "nonAxAirtime": 620800,
                "nonAxATE": 51.268358569587626,
                "timestamp": "2020-05-04T22:00:00.000+0000"
            },
            {
                "modificationtime": 1588629900000,
                "axATE": 27.01925264220629,
                "nonAxBytes": 43174783,
                "axBytes": 25370754,
                "axAirtime": 938988,
                "nonAxAirtime": 845812,
                "nonAxATE": 51.04536587326734,
                "timestamp": "2020-05-04T22:05:00.000+0000"
            },
            {
                "modificationtime": 1588630200000,
                "axATE": 69.06197792982049,
                "nonAxBytes": 25445313,
                "axBytes": 66539282,
                "axAirtime": 963472,
                "nonAxAirtime": 496132,
                "nonAxATE": 51.28738521200003,
                "timestamp": "2020-05-04T22:10:00.000+0000"
            },
            {
                "modificationtime": 1588630500000,
                "axATE": 69.5355556471542,
                "nonAxBytes": 28192355,
                "axBytes": 70852420,
                "axAirtime": 1018938,
                "nonAxAirtime": 579792,
                "nonAxATE": 48.62494653254961,
                "timestamp": "2020-05-04T22:15:00.000+0000"
            },
            {
                "modificationtime": 1588630800000,
                "axATE": 27.27306965758573,
                "nonAxBytes": 35884927,
                "axBytes": 43832623,
                "axAirtime": 1607176,
                "nonAxAirtime": 700560,
                "nonAxATE": 51.223202866278406,
                "timestamp": "2020-05-04T22:20:00.000+0000"
            },
            {
                "modificationtime": 1588631100000,
                "axATE": 68.76380571907745,
                "nonAxBytes": 34853741,
                "axBytes": 81346757,
                "axAirtime": 1182988,
                "nonAxAirtime": 684632,
                "nonAxATE": 50.908723226492484,
                "timestamp": "2020-05-04T22:25:00.000+0000"
            },
            {
                "modificationtime": 1588631400000,
                "axATE": 68.35633026403445,
                "nonAxBytes": 32967976,
                "axBytes": 82907479,
                "axAirtime": 1212872,
                "nonAxAirtime": 644016,
                "nonAxATE": 51.19123748478299,
                "timestamp": "2020-05-04T22:30:00.000+0000"
            },
            {
                "modificationtime": 1588631700000,
                "axATE": 68.45565508739523,
                "nonAxBytes": 28256061,
                "axBytes": 70856258,
                "axAirtime": 1035068,
                "nonAxAirtime": 558176,
                "nonAxATE": 50.62213531215961,
                "timestamp": "2020-05-04T22:35:00.000+0000"
            },
            {
                "modificationtime": 1588632000000,
                "axATE": 69.49300652735873,
                "nonAxBytes": 3662,
                "axBytes": 157588318,
                "axAirtime": 2267686,
                "nonAxAirtime": 2916,
                "nonAxATE": 1.255829903978052,
                "timestamp": "2020-05-04T22:40:00.000+0000"
            },
            {
                "modificationtime": 1588632300000,
                "axATE": 69.01954219972633,
                "nonAxBytes": 30146730,
                "axBytes": 83124928,
                "axAirtime": 1204368,
                "nonAxAirtime": 600192,
                "nonAxATE": 50.228476887396035,
                "timestamp": "2020-05-04T22:45:00.000+0000"
            },
            {
                "modificationtime": 1588632600000,
                "axATE": 69.45504352290831,
                "nonAxBytes": 80970786,
                "axBytes": 57896613,
                "axAirtime": 833584,
                "nonAxAirtime": 1595092,
                "nonAxATE": 50.76245508096085,
                "timestamp": "2020-05-04T22:50:00.000+0000"
            },
            {
                "modificationtime": 1588632900000,
                "axATE": 69.78411054580083,
                "nonAxBytes": 33995059,
                "axBytes": 104351251,
                "axAirtime": 1495344,
                "nonAxAirtime": 666384,
                "nonAxATE": 51.01421852865615,
                "timestamp": "2020-05-04T22:55:00.000+0000"
            },
            {
                "modificationtime": 1588633200000,
                "axATE": 68.2319462971156,
                "nonAxBytes": 25195345,
                "axBytes": 82046732,
                "axAirtime": 1202468,
                "nonAxAirtime": 507604,
                "nonAxATE": 49.635828322865855,
                "timestamp": "2020-05-04T23:00:00.000+0000"
            },
            {
                "modificationtime": 1588633500000,
                "axATE": 69.34483409485884,
                "nonAxBytes": 20879939,
                "axBytes": 120503292,
                "axAirtime": 1737740,
                "nonAxAirtime": 455276,
                "nonAxATE": 45.862156142647535,
                "timestamp": "2020-05-04T23:05:00.000+0000"
            },
            {
                "modificationtime": 1588633800000,
                "axATE": 17.065217391304348,
                "nonAxBytes": 13495308,
                "axBytes": 1166510,
                "axAirtime": 68356,
                "nonAxAirtime": 342144,
                "nonAxATE": 39.44335718294052,
                "timestamp": "2020-05-04T23:10:00.000+0000"
            },
            {
                "modificationtime": 1588634100000,
                "axATE": 68.85871443346336,
                "nonAxBytes": 3750,
                "axBytes": 155261803,
                "axAirtime": 2254788,
                "nonAxAirtime": 1440,
                "nonAxATE": 2.6041666666666665,
                "timestamp": "2020-05-04T23:15:00.000+0000"
            },
            {
                "modificationtime": 1588634400000,
                "axATE": 68.43851348332437,
                "nonAxBytes": 5028,
                "axBytes": 177683080,
                "axAirtime": 2596244,
                "nonAxAirtime": 1568,
                "nonAxATE": 3.2066326530612246,
                "timestamp": "2020-05-04T23:20:00.000+0000"
            },
            {
                "modificationtime": 1588634700000,
                "axATE": 69.18209859195876,
                "nonAxBytes": 4346,
                "axBytes": 142782166,
                "axAirtime": 2063860,
                "nonAxAirtime": 2848,
                "nonAxATE": 1.5259831460674158,
                "timestamp": "2020-05-04T23:25:00.000+0000"
            },
            {
                "modificationtime": 1588635000000,
                "axATE": 69.05482347279431,
                "nonAxBytes": 29084796,
                "axBytes": 72893443,
                "axAirtime": 1055588,
                "nonAxAirtime": 568576,
                "nonAxATE": 51.153752532642955,
                "timestamp": "2020-05-04T23:30:00.000+0000"
            },
            {
                "modificationtime": 1588635300000,
                "axATE": 69.00258818419309,
                "nonAxBytes": 37864483,
                "axBytes": 88326625,
                "axAirtime": 1280048,
                "nonAxAirtime": 766644,
                "nonAxATE": 49.389916310569184,
                "timestamp": "2020-05-04T23:35:00.000+0000"
            },
            {
                "modificationtime": 1588635600000,
                "axATE": 69.35262808447202,
                "nonAxBytes": 37890460,
                "axBytes": 88577454,
                "axAirtime": 1277204,
                "nonAxAirtime": 737068,
                "nonAxATE": 51.40700722321414,
                "timestamp": "2020-05-04T23:40:00.000+0000"
            },
            {
                "modificationtime": 1588635900000,
                "axATE": 69.65799516065879,
                "nonAxBytes": 42872362,
                "axBytes": 91085909,
                "axAirtime": 1307616,
                "nonAxAirtime": 835840,
                "nonAxATE": 51.292546420367536,
                "timestamp": "2020-05-04T23:45:00.000+0000"
            },
            {
                "modificationtime": 1588636200000,
                "axATE": 69.2493766475528,
                "nonAxBytes": 33674442,
                "axBytes": 81541418,
                "axAirtime": 1177504,
                "nonAxAirtime": 660824,
                "nonAxATE": 50.958261201167026,
                "timestamp": "2020-05-04T23:50:00.000+0000"
            },
            {
                "modificationtime": 1588636500000,
                "axATE": 69.52454450619615,
                "nonAxBytes": 33023321,
                "axBytes": 77309903,
                "axAirtime": 1111980,
                "nonAxAirtime": 647732,
                "nonAxATE": 50.98300068546868,
                "timestamp": "2020-05-04T23:55:00.000+0000"
            },
            {
                "modificationtime": 1588636800000,
                "axATE": 69.76651027735264,
                "nonAxBytes": 34688234,
                "axBytes": 97548930,
                "axAirtime": 1398220,
                "nonAxAirtime": 674928,
                "nonAxATE": 51.395458478534,
                "timestamp": "2020-05-05T00:00:00.000+0000"
            },
            {
                "modificationtime": 1588637100000,
                "axATE": 69.30657802042973,
                "nonAxBytes": 30732637,
                "axBytes": 78704550,
                "axAirtime": 1135600,
                "nonAxAirtime": 603176,
                "nonAxATE": 50.951359138957784,
                "timestamp": "2020-05-05T00:05:00.000+0000"
            },
            {
                "modificationtime": 1588637400000,
                "axATE": 69.19266002020541,
                "nonAxBytes": 40501980,
                "axBytes": 87392267,
                "axAirtime": 1263028,
                "nonAxAirtime": 788740,
                "nonAxATE": 51.350229479930015,
                "timestamp": "2020-05-05T00:10:00.000+0000"
            },
            {
                "modificationtime": 1588637700000,
                "axATE": 69.12972727147206,
                "nonAxBytes": 30566309,
                "axBytes": 80108081,
                "axAirtime": 1158808,
                "nonAxAirtime": 603128,
                "nonAxATE": 50.679638484699765,
                "timestamp": "2020-05-05T00:15:00.000+0000"
            },
            {
                "modificationtime": 1588638000000,
                "axATE": 69.31232747481866,
                "nonAxBytes": 25309274,
                "axBytes": 75529366,
                "axAirtime": 1089696,
                "nonAxAirtime": 523212,
                "nonAxATE": 48.3728851784745,
                "timestamp": "2020-05-05T00:20:00.000+0000"
            },
            {
                "modificationtime": 1588638300000,
                "axATE": 68.97929284709548,
                "nonAxBytes": 23286970,
                "axBytes": 71204013,
                "axAirtime": 1032252,
                "nonAxAirtime": 461444,
                "nonAxATE": 50.465430258059484,
                "timestamp": "2020-05-05T00:25:00.000+0000"
            },
            {
                "modificationtime": 1588638600000,
                "axATE": 69.35213672745127,
                "nonAxBytes": 28608865,
                "axBytes": 76839116,
                "axAirtime": 1107956,
                "nonAxAirtime": 606296,
                "nonAxATE": 47.186300091044636,
                "timestamp": "2020-05-05T00:30:00.000+0000"
            },
            {
                "modificationtime": 1588638900000,
                "axATE": 69.500523383816,
                "nonAxBytes": 24728127,
                "axBytes": 77151419,
                "axAirtime": 1110084,
                "nonAxAirtime": 516752,
                "nonAxATE": 47.85298750657956,
                "timestamp": "2020-05-05T00:35:00.000+0000"
            },
            {
                "modificationtime": 1588639200000,
                "axATE": 69.25503335476611,
                "nonAxBytes": 28624723,
                "axBytes": 75827059,
                "axAirtime": 1094896,
                "nonAxAirtime": 564044,
                "nonAxATE": 50.74909581522009,
                "timestamp": "2020-05-05T00:40:00.000+0000"
            },
            {
                "modificationtime": 1588639500000,
                "axATE": 43.969670220489895,
                "nonAxBytes": 9993314,
                "axBytes": 69038538,
                "axAirtime": 1570140,
                "nonAxAirtime": 236324,
                "nonAxATE": 42.2864965047985,
                "timestamp": "2020-05-05T00:45:00.000+0000"
            },
            {
                "modificationtime": 1588639800000,
                "axATE": 69.54676599196354,
                "nonAxBytes": 34468560,
                "axBytes": 81623701,
                "axAirtime": 1173652,
                "nonAxAirtime": 676672,
                "nonAxATE": 50.93835713610139,
                "timestamp": "2020-05-05T00:50:00.000+0000"
            },
            {
                "modificationtime": 1588640100000,
                "axATE": 69.5572563473945,
                "nonAxBytes": 5081873,
                "axBytes": 148442419,
                "axAirtime": 2134104,
                "nonAxAirtime": 103152,
                "nonAxATE": 49.265869784395846,
                "timestamp": "2020-05-05T00:55:00.000+0000"
            },
            {
                "modificationtime": 1588640400000,
                "axATE": 69.58595532574557,
                "nonAxBytes": 32764259,
                "axBytes": 82953139,
                "axAirtime": 1192096,
                "nonAxAirtime": 634688,
                "nonAxATE": 51.622622453867095,
                "timestamp": "2020-05-05T01:00:00.000+0000"
            },
            {
                "modificationtime": 1588640700000,
                "axATE": 69.21785021321575,
                "nonAxBytes": 35302867,
                "axBytes": 76484617,
                "axAirtime": 1104984,
                "nonAxAirtime": 683664,
                "nonAxATE": 51.63774456458143,
                "timestamp": "2020-05-05T01:05:00.000+0000"
            },
            {
                "modificationtime": 1588641000000,
                "axATE": 70.29024105623525,
                "nonAxBytes": 24476698,
                "axBytes": 83605462,
                "axAirtime": 1189432,
                "nonAxAirtime": 493468,
                "nonAxATE": 49.60138853988506,
                "timestamp": "2020-05-05T01:10:00.000+0000"
            },
            {
                "modificationtime": 1588641300000,
                "axATE": 58.66026818021423,
                "nonAxBytes": 3439925,
                "axBytes": 78464444,
                "axAirtime": 1337608,
                "nonAxAirtime": 125440,
                "nonAxATE": 27.42287149234694,
                "timestamp": "2020-05-05T01:15:00.000+0000"
            },
            {
                "modificationtime": 1588641600000,
                "axATE": 69.06080911978445,
                "nonAxBytes": 41422419,
                "axBytes": 89405571,
                "axAirtime": 1294592,
                "nonAxAirtime": 810304,
                "nonAxATE": 51.119603260011054,
                "timestamp": "2020-05-05T01:20:00.000+0000"
            },
            {
                "modificationtime": 1588641900000,
                "axATE": 54.64063486168157,
                "nonAxBytes": 39671213,
                "axBytes": 90036401,
                "axAirtime": 1647792,
                "nonAxAirtime": 766932,
                "nonAxATE": 51.727158339983205,
                "timestamp": "2020-05-05T01:25:00.000+0000"
            },
            {
                "modificationtime": 1588642200000,
                "axATE": 69.5679504907261,
                "nonAxBytes": 34526073,
                "axBytes": 80295885,
                "axAirtime": 1154208,
                "nonAxAirtime": 672440,
                "nonAxATE": 51.34446642079591,
                "timestamp": "2020-05-05T01:30:00.000+0000"
            },
            {
                "modificationtime": 1588642500000,
                "axATE": 69.9183437363776,
                "nonAxBytes": 33090350,
                "axBytes": 88537319,
                "axAirtime": 1266296,
                "nonAxAirtime": 642252,
                "nonAxATE": 51.522377509139716,
                "timestamp": "2020-05-05T01:35:00.000+0000"
            },
            {
                "modificationtime": 1588642800000,
                "axATE": 69.03744180199239,
                "nonAxBytes": 27349801,
                "axBytes": 79627095,
                "axAirtime": 1153390,
                "nonAxAirtime": 539564,
                "nonAxATE": 50.688706066379524,
                "timestamp": "2020-05-05T01:40:00.000+0000"
            },
            {
                "modificationtime": 1588643100000,
                "axATE": 69.10417375628106,
                "nonAxBytes": 20644013,
                "axBytes": 83257676,
                "axAirtime": 1204814,
                "nonAxAirtime": 448520,
                "nonAxATE": 46.02696200838313,
                "timestamp": "2020-05-05T01:45:00.000+0000"
            },
            {
                "modificationtime": 1588643400000,
                "axATE": 70.0004302862691,
                "nonAxBytes": 38743749,
                "axBytes": 95169785,
                "axAirtime": 1359560,
                "nonAxAirtime": 753228,
                "nonAxATE": 51.43694737848301,
                "timestamp": "2020-05-05T01:50:00.000+0000"
            },
            {
                "modificationtime": 1588643700000,
                "axATE": 69.15221903284575,
                "nonAxBytes": 34304490,
                "axBytes": 57240611,
                "axAirtime": 827748,
                "nonAxAirtime": 697592,
                "nonAxATE": 49.17557827498022,
                "timestamp": "2020-05-05T01:55:00.000+0000"
            },
            {
                "modificationtime": 1588644000000,
                "axATE": 70.03652003498021,
                "nonAxBytes": 32234230,
                "axBytes": 89056758,
                "axAirtime": 1271576,
                "nonAxAirtime": 640028,
                "nonAxATE": 50.36378095958302,
                "timestamp": "2020-05-05T02:00:00.000+0000"
            },
            {
                "modificationtime": 1588644300000,
                "axATE": 69.45051836009715,
                "nonAxBytes": 26768086,
                "axBytes": 76637258,
                "axAirtime": 1103480,
                "nonAxAirtime": 524888,
                "nonAxATE": 50.99770998765451,
                "timestamp": "2020-05-05T02:05:00.000+0000"
            },
            {
                "modificationtime": 1588644600000,
                "axATE": 69.88987908923428,
                "nonAxBytes": 32259745,
                "axBytes": 78149465,
                "axAirtime": 1118180,
                "nonAxAirtime": 629752,
                "nonAxATE": 51.22610964316112,
                "timestamp": "2020-05-05T02:10:00.000+0000"
            },
            {
                "modificationtime": 1588644900000,
                "axATE": 69.70995248299911,
                "nonAxBytes": 26164585,
                "axBytes": 94595569,
                "axAirtime": 1356988,
                "nonAxAirtime": 511708,
                "nonAxATE": 51.13186622057892,
                "timestamp": "2020-05-05T02:15:00.000+0000"
            },
            {
                "modificationtime": 1588645200000,
                "axATE": 68.84155079244925,
                "nonAxBytes": 29447781,
                "axBytes": 81303386,
                "axAirtime": 1181022,
                "nonAxAirtime": 596288,
                "nonAxATE": 49.38516455135773,
                "timestamp": "2020-05-05T02:20:00.000+0000"
            },
            {
                "modificationtime": 1588645500000,
                "axATE": 69.08971509763917,
                "nonAxBytes": 34824352,
                "axBytes": 41720239,
                "axAirtime": 603856,
                "nonAxAirtime": 685528,
                "nonAxATE": 50.799313813586025,
                "timestamp": "2020-05-05T02:25:00.000+0000"
            },
            {
                "modificationtime": 1588645800000,
                "axATE": 68.61326761394365,
                "nonAxBytes": 34564400,
                "axBytes": 83731515,
                "axAirtime": 1220340,
                "nonAxAirtime": 671144,
                "nonAxATE": 51.50072115671153,
                "timestamp": "2020-05-05T02:30:00.000+0000"
            },
            {
                "modificationtime": 1588646100000,
                "axATE": 69.11553182106425,
                "nonAxBytes": 36175907,
                "axBytes": 85885448,
                "axAirtime": 1242636,
                "nonAxAirtime": 706580,
                "nonAxATE": 51.198600300036794,
                "timestamp": "2020-05-05T02:35:00.000+0000"
            },
            {
                "modificationtime": 1588646400000,
                "axATE": 69.51310884420116,
                "nonAxBytes": 34728821,
                "axBytes": 82643579,
                "axAirtime": 1188892,
                "nonAxAirtime": 674184,
                "nonAxATE": 51.512377926500776,
                "timestamp": "2020-05-05T02:40:00.000+0000"
            },
            {
                "modificationtime": 1588646700000,
                "axATE": 69.14989916297966,
                "nonAxBytes": 32809249,
                "axBytes": 75707799,
                "axAirtime": 1094836,
                "nonAxAirtime": 640220,
                "nonAxATE": 51.246835462809656,
                "timestamp": "2020-05-05T02:45:00.000+0000"
            },
            {
                "modificationtime": 1588647000000,
                "axATE": 68.67948017438717,
                "nonAxBytes": 11334078,
                "axBytes": 71646159,
                "axAirtime": 1043196,
                "nonAxAirtime": 266928,
                "nonAxATE": 42.46118054306779,
                "timestamp": "2020-05-05T02:50:00.000+0000"
            },
            {
                "modificationtime": 1588647300000,
                "axATE": 69.89079778256314,
                "nonAxBytes": 39679749,
                "axBytes": 76855276,
                "axAirtime": 1099648,
                "nonAxAirtime": 771036,
                "nonAxATE": 51.46290056495417,
                "timestamp": "2020-05-05T02:55:00.000+0000"
            },
            {
                "modificationtime": 1588647600000,
                "axATE": 69.5087461917333,
                "nonAxBytes": 4760,
                "axBytes": 154503763,
                "axAirtime": 2222796,
                "nonAxAirtime": 2504,
                "nonAxATE": 1.900958466453674,
                "timestamp": "2020-05-05T03:00:00.000+0000"
            },
            {
                "modificationtime": 1588647900000,
                "axATE": 69.92047081920956,
                "nonAxBytes": 3840,
                "axBytes": 197444502,
                "axAirtime": 2823844,
                "nonAxAirtime": 2608,
                "nonAxATE": 1.4723926380368098,
                "timestamp": "2020-05-05T03:05:00.000+0000"
            },
            {
                "modificationtime": 1588648200000,
                "axATE": 70.31678866983684,
                "nonAxBytes": 4214,
                "axBytes": 156921477,
                "axAirtime": 2231636,
                "nonAxAirtime": 3592,
                "nonAxATE": 1.173162583518931,
                "timestamp": "2020-05-05T03:10:00.000+0000"
            },
            {
                "modificationtime": 1588648500000,
                "axATE": 69.5472202252576,
                "nonAxBytes": 32181958,
                "axBytes": 81261754,
                "axAirtime": 1168440,
                "nonAxAirtime": 625368,
                "nonAxATE": 51.46083266172877,
                "timestamp": "2020-05-05T03:15:00.000+0000"
            },
            {
                "modificationtime": 1588648800000,
                "axATE": 68.64225397990701,
                "nonAxBytes": 36488504,
                "axBytes": 83028023,
                "axAirtime": 1209576,
                "nonAxAirtime": 709088,
                "nonAxATE": 51.458357777878064,
                "timestamp": "2020-05-05T03:20:00.000+0000"
            },
            {
                "modificationtime": 1588649100000,
                "axATE": 68.01826051577747,
                "nonAxBytes": 41255264,
                "axBytes": 89274239,
                "axAirtime": 1312504,
                "nonAxAirtime": 797384,
                "nonAxATE": 51.738264123684445,
                "timestamp": "2020-05-05T03:25:00.000+0000"
            },
            {
                "modificationtime": 1588649400000,
                "axATE": 68.37055271514332,
                "nonAxBytes": 29882892,
                "axBytes": 70018830,
                "axAirtime": 1024108,
                "nonAxAirtime": 584272,
                "nonAxATE": 51.14551441794233,
                "timestamp": "2020-05-05T03:30:00.000+0000"
            },
            {
                "modificationtime": 1588649700000,
                "axATE": 69.35551446291261,
                "nonAxBytes": 27585482,
                "axBytes": 75350189,
                "axAirtime": 1086434,
                "nonAxAirtime": 540776,
                "nonAxATE": 51.01092134266314,
                "timestamp": "2020-05-05T03:35:00.000+0000"
            },
            {
                "modificationtime": 1588650000000,
                "axATE": 69.22543451254315,
                "nonAxBytes": 38329638,
                "axBytes": 94507117,
                "axAirtime": 1365208,
                "nonAxAirtime": 747480,
                "nonAxATE": 51.278479691764325,
                "timestamp": "2020-05-05T03:40:00.000+0000"
            },
            {
                "modificationtime": 1588650300000,
                "axATE": 68.13361086910065,
                "nonAxBytes": 39594555,
                "axBytes": 82895439,
                "axAirtime": 1216660,
                "nonAxAirtime": 765592,
                "nonAxATE": 51.71756627550967,
                "timestamp": "2020-05-05T03:45:00.000+0000"
            },
            {
                "modificationtime": 1588650600000,
                "axATE": 68.45968797522411,
                "nonAxBytes": 37675435,
                "axBytes": 78871585,
                "axAirtime": 1152088,
                "nonAxAirtime": 736976,
                "nonAxATE": 51.12165796443846,
                "timestamp": "2020-05-05T03:50:00.000+0000"
            },
            {
                "modificationtime": 1588650900000,
                "axATE": 68.61986776373787,
                "nonAxBytes": 3316266,
                "axBytes": 46702682,
                "axAirtime": 680600,
                "nonAxAirtime": 121948,
                "nonAxATE": 27.19409912421688,
                "timestamp": "2020-05-05T03:55:00.000+0000"
            },
            {
                "modificationtime": 1588651200000,
                "axATE": 68.76620022415612,
                "nonAxBytes": 33704179,
                "axBytes": 83443658,
                "axAirtime": 1213440,
                "nonAxAirtime": 655184,
                "nonAxATE": 51.44231086229212,
                "timestamp": "2020-05-05T04:00:00.000+0000"
            },
            {
                "modificationtime": 1588651500000,
                "axATE": 69.19808011186457,
                "nonAxBytes": 33993705,
                "axBytes": 89472564,
                "axAirtime": 1292992,
                "nonAxAirtime": 664572,
                "nonAxATE": 51.15127480543869,
                "timestamp": "2020-05-05T04:05:00.000+0000"
            },
            {
                "modificationtime": 1588651800000,
                "axATE": 68.76446989591454,
                "nonAxBytes": 32732441,
                "axBytes": 80441227,
                "axAirtime": 1169808,
                "nonAxAirtime": 640068,
                "nonAxATE": 51.13900554316104,
                "timestamp": "2020-05-05T04:10:00.000+0000"
            },
            {
                "modificationtime": 1588652100000,
                "axATE": 69.11873566613386,
                "nonAxBytes": 31452848,
                "axBytes": 79563959,
                "axAirtime": 1151120,
                "nonAxAirtime": 612800,
                "nonAxATE": 51.32644908616188,
                "timestamp": "2020-05-05T04:15:00.000+0000"
            },
            {
                "modificationtime": 1588652400000,
                "axATE": 69.54430960347192,
                "nonAxBytes": 36940283,
                "axBytes": 83391138,
                "axAirtime": 1199108,
                "nonAxAirtime": 722040,
                "nonAxATE": 51.16099246579137,
                "timestamp": "2020-05-05T04:20:00.000+0000"
            },
            {
                "modificationtime": 1588652700000,
                "axATE": 69.71951838022403,
                "nonAxBytes": 15575421,
                "axBytes": 139664928,
                "axAirtime": 2003240,
                "nonAxAirtime": 305060,
                "nonAxATE": 51.056910116042744,
                "timestamp": "2020-05-05T04:25:00.000+0000"
            },
            {
                "modificationtime": 1588653000000,
                "axATE": 27.99167525651826,
                "nonAxBytes": 35488231,
                "axBytes": 64196220,
                "axAirtime": 2293404,
                "nonAxAirtime": 695036,
                "nonAxATE": 51.05955806605701,
                "timestamp": "2020-05-05T04:30:00.000+0000"
            },
            {
                "modificationtime": 1588653300000,
                "axATE": 69.45131995104468,
                "nonAxBytes": 21428649,
                "axBytes": 63329325,
                "axAirtime": 911852,
                "nonAxAirtime": 522300,
                "nonAxATE": 41.02747271682941,
                "timestamp": "2020-05-05T04:35:00.000+0000"
            },
            {
                "modificationtime": 1588653600000,
                "axATE": 68.93145016634506,
                "nonAxBytes": 22050825,
                "axBytes": 73015225,
                "axAirtime": 1059244,
                "nonAxAirtime": 465316,
                "nonAxATE": 47.38892494562835,
                "timestamp": "2020-05-05T04:40:00.000+0000"
            },
            {
                "modificationtime": 1588653900000,
                "axATE": 69.50170618418404,
                "nonAxBytes": 32020922,
                "axBytes": 82896075,
                "axAirtime": 1192720,
                "nonAxAirtime": 627704,
                "nonAxATE": 51.01277353657138,
                "timestamp": "2020-05-05T04:45:00.000+0000"
            },
            {
                "modificationtime": 1588654200000,
                "axATE": 69.78304486577264,
                "nonAxBytes": 26762291,
                "axBytes": 95820355,
                "axAirtime": 1373118,
                "nonAxAirtime": 534292,
                "nonAxATE": 50.08926017982676,
                "timestamp": "2020-05-05T04:50:00.000+0000"
            },
            {
                "modificationtime": 1588654500000,
                "axATE": 70.10876707682108,
                "nonAxBytes": 3442,
                "axBytes": 400141862,
                "axAirtime": 5707444,
                "nonAxAirtime": 2220,
                "nonAxATE": 1.5504504504504504,
                "timestamp": "2020-05-05T04:55:00.000+0000"
            },
            {
                "modificationtime": 1588654800000,
                "axATE": 52.23119812258676,
                "nonAxBytes": 1923599,
                "axBytes": 45181031,
                "axAirtime": 865020,
                "nonAxAirtime": 103016,
                "nonAxATE": 18.672817814708395,
                "timestamp": "2020-05-05T05:00:00.000+0000"
            },
            {
                "modificationtime": 1588655100000,
                "axATE": 17.065217391304348,
                "nonAxBytes": 555084,
                "axBytes": 1066030,
                "axAirtime": 62468,
                "nonAxAirtime": 27976,
                "nonAxATE": 19.841435516156707,
                "timestamp": "2020-05-05T05:05:00.000+0000"
            },
            {
                "modificationtime": 1588655400000,
                "axATE": 63.87833850218422,
                "nonAxBytes": 434020,
                "axBytes": 80658667,
                "axAirtime": 1262692,
                "nonAxAirtime": 21684,
                "nonAxATE": 20.015679763881202,
                "timestamp": "2020-05-05T05:10:00.000+0000"
            },
            {
                "modificationtime": 1588655700000,
                "axATE": 69.12828545860448,
                "nonAxBytes": 34143520,
                "axBytes": 87445069,
                "axAirtime": 1264968,
                "nonAxAirtime": 666260,
                "nonAxATE": 51.24654038963768,
                "timestamp": "2020-05-05T05:15:00.000+0000"
            },
            {
                "modificationtime": 1588656000000,
                "axATE": 70.01760548802044,
                "nonAxBytes": 37858925,
                "axBytes": 87940152,
                "axAirtime": 1255972,
                "nonAxAirtime": 734164,
                "nonAxATE": 51.56739502345525,
                "timestamp": "2020-05-05T05:20:00.000+0000"
            },
            {
                "modificationtime": 1588656300000,
                "axATE": 69.1912223162422,
                "nonAxBytes": 39720018,
                "axBytes": 87002150,
                "axAirtime": 1257416,
                "nonAxAirtime": 765896,
                "nonAxATE": 51.860850559344875,
                "timestamp": "2020-05-05T05:25:00.000+0000"
            },
            {
                "modificationtime": 1588656600000,
                "axATE": 68.472993146502,
                "nonAxBytes": 41549824,
                "axBytes": 88799612,
                "axAirtime": 1296856,
                "nonAxAirtime": 804484,
                "nonAxATE": 51.64779411399108,
                "timestamp": "2020-05-05T05:30:00.000+0000"
            },
            {
                "modificationtime": 1588656900000,
                "axATE": 69.53222811784775,
                "nonAxBytes": 34889495,
                "axBytes": 81115602,
                "axAirtime": 1166590,
                "nonAxAirtime": 686832,
                "nonAxATE": 50.797713269038134,
                "timestamp": "2020-05-05T05:35:00.000+0000"
            },
            {
                "modificationtime": 1588657200000,
                "axATE": 69.1938589317971,
                "nonAxBytes": 41139226,
                "axBytes": 90707060,
                "axAirtime": 1310912,
                "nonAxAirtime": 799828,
                "nonAxATE": 51.435091044574584,
                "timestamp": "2020-05-05T05:40:00.000+0000"
            },
            {
                "modificationtime": 1588657500000,
                "axATE": 68.79683440899497,
                "nonAxBytes": 36974111,
                "axBytes": 81354183,
                "axAirtime": 1182528,
                "nonAxAirtime": 712292,
                "nonAxATE": 51.90864280379395,
                "timestamp": "2020-05-05T05:45:00.000+0000"
            },
            {
                "modificationtime": 1588657800000,
                "axATE": 69.27696192112467,
                "nonAxBytes": 35708867,
                "axBytes": 84837676,
                "axAirtime": 1224616,
                "nonAxAirtime": 692912,
                "nonAxATE": 51.53449067125407,
                "timestamp": "2020-05-05T05:50:00.000+0000"
            },
            {
                "modificationtime": 1588658100000,
                "axATE": 69.37040478874059,
                "nonAxBytes": 38945862,
                "axBytes": 85920241,
                "axAirtime": 1238572,
                "nonAxAirtime": 756916,
                "nonAxATE": 51.45334753129806,
                "timestamp": "2020-05-05T05:55:00.000+0000"
            },
            {
                "modificationtime": 1588658400000,
                "axATE": 67.93088606159723,
                "nonAxBytes": 33828352,
                "axBytes": 79085681,
                "axAirtime": 1164208,
                "nonAxAirtime": 675072,
                "nonAxATE": 50.11073189230186,
                "timestamp": "2020-05-05T06:00:00.000+0000"
            },
            {
                "modificationtime": 1588658700000,
                "axATE": 69.10278537944345,
                "nonAxBytes": 27990875,
                "axBytes": 78481139,
                "axAirtime": 1135716,
                "nonAxAirtime": 549324,
                "nonAxATE": 50.95512848519271,
                "timestamp": "2020-05-05T06:05:00.000+0000"
            },
            {
                "modificationtime": 1588659000000,
                "axATE": 68.46687330965493,
                "nonAxBytes": 34509224,
                "axBytes": 84047195,
                "axAirtime": 1227560,
                "nonAxAirtime": 670588,
                "nonAxATE": 51.46114156531283,
                "timestamp": "2020-05-05T06:10:00.000+0000"
            },
            {
                "modificationtime": 1588659300000,
                "axATE": 69.55311995795125,
                "nonAxBytes": 5472,
                "axBytes": 151913195,
                "axAirtime": 2184132,
                "nonAxAirtime": 4196,
                "nonAxATE": 1.304099142040038,
                "timestamp": "2020-05-05T06:15:00.000+0000"
            },
            {
                "modificationtime": 1588659600000,
                "axATE": 69.48845659481907,
                "nonAxBytes": 40744707,
                "axBytes": 92770425,
                "axAirtime": 1335048,
                "nonAxAirtime": 790640,
                "nonAxATE": 51.533829555802896,
                "timestamp": "2020-05-05T06:20:00.000+0000"
            },
            {
                "modificationtime": 1588659900000,
                "axATE": 68.13166749849654,
                "nonAxBytes": 38199272,
                "axBytes": 77264445,
                "axAirtime": 1134046,
                "nonAxAirtime": 739492,
                "nonAxATE": 51.656099051781496,
                "timestamp": "2020-05-05T06:25:00.000+0000"
            },
            {
                "modificationtime": 1588660200000,
                "axATE": 68.70524360647678,
                "nonAxBytes": 32482487,
                "axBytes": 79381214,
                "axAirtime": 1155388,
                "nonAxAirtime": 631376,
                "nonAxATE": 51.44713609639898,
                "timestamp": "2020-05-05T06:30:00.000+0000"
            },
            {
                "modificationtime": 1588660500000,
                "axATE": 69.05179631450527,
                "nonAxBytes": 31815054,
                "axBytes": 75843731,
                "axAirtime": 1098360,
                "nonAxAirtime": 621040,
                "nonAxATE": 51.22867126111039,
                "timestamp": "2020-05-05T06:35:00.000+0000"
            },
            {
                "modificationtime": 1588660800000,
                "axATE": 67.51061430327009,
                "nonAxBytes": 34255257,
                "axBytes": 72075412,
                "axAirtime": 1067616,
                "nonAxAirtime": 662632,
                "nonAxATE": 51.69574816791221,
                "timestamp": "2020-05-05T06:40:00.000+0000"
            },
            {
                "modificationtime": 1588661100000,
                "axATE": 67.76581997732418,
                "nonAxBytes": 31041726,
                "axBytes": 79134485,
                "axAirtime": 1167764,
                "nonAxAirtime": 611948,
                "nonAxATE": 50.72608456927713,
                "timestamp": "2020-05-05T06:45:00.000+0000"
            },
            {
                "modificationtime": 1588661400000,
                "axATE": 68.4670317433877,
                "nonAxBytes": 28945752,
                "axBytes": 75629231,
                "axAirtime": 1104608,
                "nonAxAirtime": 571040,
                "nonAxATE": 50.68953488372093,
                "timestamp": "2020-05-05T06:50:00.000+0000"
            },
            {
                "modificationtime": 1588661700000,
                "axATE": 69.37641488180486,
                "nonAxBytes": 30654655,
                "axBytes": 86225171,
                "axAirtime": 1242860,
                "nonAxAirtime": 600176,
                "nonAxATE": 51.076109341259894,
                "timestamp": "2020-05-05T06:55:00.000+0000"
            },
            {
                "modificationtime": 1588662000000,
                "axATE": 68.61291495010528,
                "nonAxBytes": 33748361,
                "axBytes": 74945887,
                "axAirtime": 1092300,
                "nonAxAirtime": 657728,
                "nonAxATE": 51.310512856378324,
                "timestamp": "2020-05-05T07:00:00.000+0000"
            },
            {
                "modificationtime": 1588662300000,
                "axATE": 68.88573630749306,
                "nonAxBytes": 37878911,
                "axBytes": 85530459,
                "axAirtime": 1241628,
                "nonAxAirtime": 742596,
                "nonAxATE": 51.0087732764518,
                "timestamp": "2020-05-05T07:05:00.000+0000"
            },
            {
                "modificationtime": 1588662600000,
                "axATE": 69.57084002859793,
                "nonAxBytes": 33018890,
                "axBytes": 78625624,
                "axAirtime": 1130152,
                "nonAxAirtime": 641536,
                "nonAxATE": 51.46849124600958,
                "timestamp": "2020-05-05T07:10:00.000+0000"
            },
            {
                "modificationtime": 1588662900000,
                "axATE": 17.065217391304348,
                "nonAxBytes": 11681097,
                "axBytes": 2155610,
                "axAirtime": 126316,
                "nonAxAirtime": 289236,
                "nonAxATE": 40.38604115670248,
                "timestamp": "2020-05-05T07:15:00.000+0000"
            },
            {
                "modificationtime": 1588663200000,
                "axATE": 69.58132324178624,
                "nonAxBytes": 34333291,
                "axBytes": 84670729,
                "axAirtime": 1216860,
                "nonAxAirtime": 663952,
                "nonAxATE": 51.7105016627708,
                "timestamp": "2020-05-05T07:20:00.000+0000"
            },
            {
                "modificationtime": 1588663500000,
                "axATE": 69.35717154601176,
                "nonAxBytes": 35160594,
                "axBytes": 91522059,
                "axAirtime": 1319576,
                "nonAxAirtime": 680208,
                "nonAxATE": 51.69094453461294,
                "timestamp": "2020-05-05T07:25:00.000+0000"
            },
            {
                "modificationtime": 1588663800000,
                "axATE": 69.29295200980137,
                "nonAxBytes": 34665369,
                "axBytes": 106328649,
                "axAirtime": 1534480,
                "nonAxAirtime": 673032,
                "nonAxATE": 51.50627161858574,
                "timestamp": "2020-05-05T07:30:00.000+0000"
            },
            {
                "modificationtime": 1588664100000,
                "axATE": 69.22161573912376,
                "nonAxBytes": 39922246,
                "axBytes": 97510275,
                "axAirtime": 1408668,
                "nonAxAirtime": 775716,
                "nonAxATE": 51.46502843824286,
                "timestamp": "2020-05-05T07:35:00.000+0000"
            },
            {
                "modificationtime": 1588664400000,
                "axATE": 69.62351714866614,
                "nonAxBytes": 31325565,
                "axBytes": 83692759,
                "axAirtime": 1202076,
                "nonAxAirtime": 611480,
                "nonAxATE": 51.22909171191208,
                "timestamp": "2020-05-05T07:40:00.000+0000"
            },
            {
                "modificationtime": 1588664700000,
                "axATE": 69.88918723165465,
                "nonAxBytes": 28738929,
                "axBytes": 192794355,
                "axAirtime": 2758572,
                "nonAxAirtime": 566740,
                "nonAxATE": 50.70919469245157,
                "timestamp": "2020-05-05T07:45:00.000+0000"
            },
            {
                "modificationtime": 1588665000000,
                "axATE": 70.13893710452612,
                "nonAxBytes": 28806859,
                "axBytes": 81139528,
                "axAirtime": 1156840,
                "nonAxAirtime": 557420,
                "nonAxATE": 51.67891177209285,
                "timestamp": "2020-05-05T07:50:00.000+0000"
            },
            {
                "modificationtime": 1588665300000,
                "axATE": 69.72325335182184,
                "nonAxBytes": 38229397,
                "axBytes": 89301264,
                "axAirtime": 1280796,
                "nonAxAirtime": 752588,
                "nonAxATE": 50.79724497334531,
                "timestamp": "2020-05-05T07:55:00.000+0000"
            },
            {
                "modificationtime": 1588665600000,
                "axATE": 18.99458032420637,
                "nonAxBytes": 92168800,
                "axBytes": 5849419,
                "axAirtime": 307952,
                "nonAxAirtime": 1779768,
                "nonAxATE": 51.78697448206733,
                "timestamp": "2020-05-05T08:00:00.000+0000"
            },
            {
                "modificationtime": 1588665900000,
                "axATE": 70.06725121736339,
                "nonAxBytes": 31211981,
                "axBytes": 82075657,
                "axAirtime": 1171384,
                "nonAxAirtime": 641312,
                "nonAxATE": 48.66894896711741,
                "timestamp": "2020-05-05T08:05:00.000+0000"
            },
            {
                "modificationtime": 1588666200000,
                "axATE": 70.20595431574452,
                "nonAxBytes": 41310625,
                "axBytes": 94689298,
                "axAirtime": 1348736,
                "nonAxAirtime": 797200,
                "nonAxATE": 51.81965002508781,
                "timestamp": "2020-05-05T08:10:00.000+0000"
            },
            {
                "modificationtime": 1588666500000,
                "axATE": 69.45491989768225,
                "nonAxBytes": 35937391,
                "axBytes": 85476503,
                "axAirtime": 1230676,
                "nonAxAirtime": 698012,
                "nonAxATE": 51.48534838942597,
                "timestamp": "2020-05-05T08:15:00.000+0000"
            },
            {
                "modificationtime": 1588666800000,
                "axATE": 70.61547717322405,
                "nonAxBytes": 20963531,
                "axBytes": 76645474,
                "axAirtime": 1085392,
                "nonAxAirtime": 429036,
                "nonAxATE": 48.86193932443898,
                "timestamp": "2020-05-05T08:20:00.000+0000"
            },
            {
                "modificationtime": 1588667100000,
                "axATE": 70.15807017390136,
                "nonAxBytes": 34050211,
                "axBytes": 96082319,
                "axAirtime": 1369512,
                "nonAxAirtime": 669224,
                "nonAxATE": 50.88014028187872,
                "timestamp": "2020-05-05T08:25:00.000+0000"
            },
            {
                "modificationtime": 1588667400000,
                "axATE": 70.37374107880945,
                "nonAxBytes": 44319390,
                "axBytes": 65039693,
                "axAirtime": 924204,
                "nonAxAirtime": 881432,
                "nonAxATE": 50.281122083155594,
                "timestamp": "2020-05-05T08:30:00.000+0000"
            },
            {
                "modificationtime": 1588667700000,
                "axATE": 69.76801260408988,
                "nonAxBytes": 37149270,
                "axBytes": 97289261,
                "axAirtime": 1394468,
                "nonAxAirtime": 719704,
                "nonAxATE": 51.6174288318531,
                "timestamp": "2020-05-05T08:35:00.000+0000"
            },
            {
                "modificationtime": 1588668000000,
                "axATE": 70.27449316154238,
                "nonAxBytes": 39643185,
                "axBytes": 90082624,
                "axAirtime": 1281868,
                "nonAxAirtime": 783972,
                "nonAxATE": 50.56709295740154,
                "timestamp": "2020-05-05T08:40:00.000+0000"
            },
            {
                "modificationtime": 1588668300000,
                "axATE": 70.01185820686011,
                "nonAxBytes": 37148888,
                "axBytes": 84788561,
                "axAirtime": 1211060,
                "nonAxAirtime": 717328,
                "nonAxATE": 51.78786831128856,
                "timestamp": "2020-05-05T08:45:00.000+0000"
            },
            {
                "modificationtime": 1588668600000,
                "axATE": 69.91845244227709,
                "nonAxBytes": 2400270,
                "axBytes": 48862930,
                "axAirtime": 698856,
                "nonAxAirtime": 94332,
                "nonAxATE": 25.44491794937031,
                "timestamp": "2020-05-05T08:50:00.000+0000"
            },
            {
                "modificationtime": 1588668900000,
                "axATE": 70.11825859320561,
                "nonAxBytes": 1701027,
                "axBytes": 58766393,
                "axAirtime": 838104,
                "nonAxAirtime": 73184,
                "nonAxATE": 23.243154241364234,
                "timestamp": "2020-05-05T08:55:00.000+0000"
            },
            {
                "modificationtime": 1588669200000,
                "axATE": 50.51523066973268,
                "nonAxBytes": 14035997,
                "axBytes": 56237596,
                "axAirtime": 1113280,
                "nonAxAirtime": 351452,
                "nonAxATE": 39.93716638402968,
                "timestamp": "2020-05-05T09:00:00.000+0000"
            },
            {
                "modificationtime": 1588669500000,
                "axATE": 44.14410889907527,
                "nonAxBytes": 5177316,
                "axBytes": 28852413,
                "axAirtime": 653596,
                "nonAxAirtime": 167628,
                "nonAxATE": 30.88574701123917,
                "timestamp": "2020-05-05T09:05:00.000+0000"
            },
            {
                "modificationtime": 1588669800000,
                "axATE": 17.065217391304348,
                "nonAxBytes": 38484374,
                "axBytes": 1455390,
                "axAirtime": 85284,
                "nonAxAirtime": 772728,
                "nonAxATE": 49.80326065575468,
                "timestamp": "2020-05-05T09:10:00.000+0000"
            },
            {
                "modificationtime": 1588670100000,
                "axATE": 69.49118837801075,
                "nonAxBytes": 36248326,
                "axBytes": 94817113,
                "axAirtime": 1364448,
                "nonAxAirtime": 709672,
                "nonAxATE": 51.077576683312856,
                "timestamp": "2020-05-05T09:15:00.000+0000"
            },
            {
                "modificationtime": 1588670400000,
                "axATE": 68.76568453554067,
                "nonAxBytes": 40990817,
                "axBytes": 79553095,
                "axAirtime": 1156872,
                "nonAxAirtime": 789664,
                "nonAxATE": 51.90918795842282,
                "timestamp": "2020-05-05T09:20:00.000+0000"
            },
            {
                "modificationtime": 1588670700000,
                "axATE": 69.62697304363103,
                "nonAxBytes": 39352053,
                "axBytes": 93533812,
                "axAirtime": 1343356,
                "nonAxAirtime": 766780,
                "nonAxATE": 51.32117817366129,
                "timestamp": "2020-05-05T09:25:00.000+0000"
            },
            {
                "modificationtime": 1588671000000,
                "axATE": 69.07291946247317,
                "nonAxBytes": 44668004,
                "axBytes": 90588305,
                "axAirtime": 1311488,
                "nonAxAirtime": 871404,
                "nonAxATE": 51.25981060449573,
                "timestamp": "2020-05-05T09:30:00.000+0000"
            },
            {
                "modificationtime": 1588671300000,
                "axATE": 69.60653721128084,
                "nonAxBytes": 31924365,
                "axBytes": 87707578,
                "axAirtime": 1260048,
                "nonAxAirtime": 623432,
                "nonAxATE": 51.20745325873551,
                "timestamp": "2020-05-05T09:35:00.000+0000"
            },
            {
                "modificationtime": 1588671600000,
                "axATE": 70.3413346580002,
                "nonAxBytes": 31839319,
                "axBytes": 83545810,
                "axAirtime": 1187720,
                "nonAxAirtime": 623372,
                "nonAxATE": 51.07595304248507,
                "timestamp": "2020-05-05T09:40:00.000+0000"
            },
            {
                "modificationtime": 1588671900000,
                "axATE": 69.21827922885176,
                "nonAxBytes": 33826472,
                "axBytes": 86858973,
                "axAirtime": 1254856,
                "nonAxAirtime": 656400,
                "nonAxATE": 51.5333211456429,
                "timestamp": "2020-05-05T09:45:00.000+0000"
            },
            {
                "modificationtime": 1588672200000,
                "axATE": 68.52897388372212,
                "nonAxBytes": 35138028,
                "axBytes": 75277159,
                "axAirtime": 1098472,
                "nonAxAirtime": 680636,
                "nonAxATE": 51.625285762140116,
                "timestamp": "2020-05-05T09:50:00.000+0000"
            },
            {
                "modificationtime": 1588672500000,
                "axATE": 70.26997677138183,
                "nonAxBytes": 39902002,
                "axBytes": 87971265,
                "axAirtime": 1251904,
                "nonAxAirtime": 776012,
                "nonAxATE": 51.419310526125884,
                "timestamp": "2020-05-05T09:55:00.000+0000"
            },
            {
                "modificationtime": 1588672800000,
                "axATE": 71.0126686042179,
                "nonAxBytes": 25664246,
                "axBytes": 76233520,
                "axAirtime": 1073520,
                "nonAxAirtime": 508336,
                "nonAxATE": 50.486776462811996,
                "timestamp": "2020-05-05T10:00:00.000+0000"
            },
            {
                "modificationtime": 1588673100000,
                "axATE": 70.44129369786556,
                "nonAxBytes": 34959294,
                "axBytes": 82994214,
                "axAirtime": 1178204,
                "nonAxAirtime": 684156,
                "nonAxATE": 51.098424920632134,
                "timestamp": "2020-05-05T10:05:00.000+0000"
            },
            {
                "modificationtime": 1588673400000,
                "axATE": 70.54336909726894,
                "nonAxBytes": 36291827,
                "axBytes": 83895818,
                "axAirtime": 1189280,
                "nonAxAirtime": 707416,
                "nonAxATE": 51.301959525936645,
                "timestamp": "2020-05-05T10:10:00.000+0000"
            },
            {
                "modificationtime": 1588673700000,
                "axATE": 69.78918785172374,
                "nonAxBytes": 31649589,
                "axBytes": 82642123,
                "axAirtime": 1184168,
                "nonAxAirtime": 622980,
                "nonAxATE": 50.80353943946836,
                "timestamp": "2020-05-05T10:15:00.000+0000"
            },
            {
                "modificationtime": 1588674000000,
                "axATE": 70.32267668439799,
                "nonAxBytes": 47884640,
                "axBytes": 90583765,
                "axAirtime": 1288116,
                "nonAxAirtime": 927924,
                "nonAxATE": 51.60405378026649,
                "timestamp": "2020-05-05T10:20:00.000+0000"
            },
            {
                "modificationtime": 1588674300000,
                "axATE": 70.14681838267786,
                "nonAxBytes": 36360969,
                "axBytes": 88260691,
                "axAirtime": 1258228,
                "nonAxAirtime": 706708,
                "nonAxATE": 51.451192005750606,
                "timestamp": "2020-05-05T10:25:00.000+0000"
            },
            {
                "modificationtime": 1588674600000,
                "axATE": 69.69940705584357,
                "nonAxBytes": 37882193,
                "axBytes": 87173606,
                "axAirtime": 1250708,
                "nonAxAirtime": 741392,
                "nonAxATE": 51.09603691434491,
                "timestamp": "2020-05-05T10:30:00.000+0000"
            },
            {
                "modificationtime": 1588674900000,
                "axATE": 70.68768109194981,
                "nonAxBytes": 28814769,
                "axBytes": 127544045,
                "axAirtime": 1804332,
                "nonAxAirtime": 564180,
                "nonAxATE": 51.07371583537169,
                "timestamp": "2020-05-05T10:35:00.000+0000"
            },
            {
                "modificationtime": 1588675200000,
                "axATE": 70.79064982526378,
                "nonAxBytes": 1303158,
                "axBytes": 67332385,
                "axAirtime": 951148,
                "nonAxAirtime": 70136,
                "nonAxATE": 18.580443709364662,
                "timestamp": "2020-05-05T10:40:00.000+0000"
            },
            {
                "modificationtime": 1588675500000,
                "axATE": 17.06222007552472,
                "nonAxBytes": 6868845,
                "axBytes": 777150,
                "axAirtime": 45548,
                "nonAxAirtime": 191036,
                "nonAxATE": 35.955762264704035,
                "timestamp": "2020-05-05T10:45:00.000+0000"
            },
            {
                "modificationtime": 1588675800000,
                "axATE": 17.065217391304348,
                "nonAxBytes": 9437540,
                "axBytes": 913740,
                "axAirtime": 53544,
                "nonAxAirtime": 237120,
                "nonAxATE": 39.800691632928476,
                "timestamp": "2020-05-05T10:50:00.000+0000"
            },
            {
                "modificationtime": 1588676100000,
                "axATE": 69.95767289322235,
                "nonAxBytes": 2887617,
                "axBytes": 66468464,
                "axAirtime": 950124,
                "nonAxAirtime": 106124,
                "nonAxATE": 27.209839433115977,
                "timestamp": "2020-05-05T10:55:00.000+0000"
            },
            {
                "modificationtime": 1588676400000,
                "axATE": 70.61381557241835,
                "nonAxBytes": 44701966,
                "axBytes": 94173409,
                "axAirtime": 1333640,
                "nonAxAirtime": 870828,
                "nonAxATE": 51.3327155305066,
                "timestamp": "2020-05-05T11:00:00.000+0000"
            },
            {
                "modificationtime": 1588676700000,
                "axATE": 69.58269608131133,
                "nonAxBytes": 35173643,
                "axBytes": 118436707,
                "axAirtime": 1702100,
                "nonAxAirtime": 677704,
                "nonAxATE": 51.90118842444489,
                "timestamp": "2020-05-05T11:05:00.000+0000"
            },
            {
                "modificationtime": 1588677000000,
                "axATE": 68.95353822967832,
                "nonAxBytes": 27506796,
                "axBytes": 70276205,
                "axAirtime": 1019182,
                "nonAxAirtime": 534016,
                "nonAxATE": 51.50931058245446,
                "timestamp": "2020-05-05T11:10:00.000+0000"
            },
            {
                "modificationtime": 1588677300000,
                "axATE": 68.60980290767651,
                "nonAxBytes": 32711210,
                "axBytes": 77753843,
                "axAirtime": 1133276,
                "nonAxAirtime": 633096,
                "nonAxATE": 51.66864109076665,
                "timestamp": "2020-05-05T11:15:00.000+0000"
            },
            {
                "modificationtime": 1588677600000,
                "axATE": 69.64070534856076,
                "nonAxBytes": 1942048,
                "axBytes": 168958658,
                "axAirtime": 2426148,
                "nonAxAirtime": 130256,
                "nonAxATE": 14.909470581009703,
                "timestamp": "2020-05-05T11:20:00.000+0000"
            },
            {
                "modificationtime": 1588677900000,
                "axATE": 69.15341743200946,
                "nonAxBytes": 24494579,
                "axBytes": 69905530,
                "axAirtime": 1010876,
                "nonAxAirtime": 489584,
                "nonAxATE": 50.03141238275761,
                "timestamp": "2020-05-05T11:25:00.000+0000"
            },
            {
                "modificationtime": 1588678200000,
                "axATE": 51.54530041214134,
                "nonAxBytes": 8476290,
                "axBytes": 63083819,
                "axAirtime": 1223852,
                "nonAxAirtime": 205640,
                "nonAxATE": 41.21907216494845,
                "timestamp": "2020-05-05T11:30:00.000+0000"
            },
            {
                "modificationtime": 1588678500000,
                "axATE": 31.252799470094004,
                "nonAxBytes": 37611287,
                "axBytes": 70867973,
                "axAirtime": 2267572,
                "nonAxAirtime": 728448,
                "nonAxATE": 51.632082180196804,
                "timestamp": "2020-05-05T11:35:00.000+0000"
            },
            {
                "modificationtime": 1588678800000,
                "axATE": 69.09114949904777,
                "nonAxBytes": 32284818,
                "axBytes": 80103726,
                "axAirtime": 1159392,
                "nonAxAirtime": 646192,
                "nonAxATE": 49.96164916928715,
                "timestamp": "2020-05-05T11:40:00.000+0000"
            },
            {
                "modificationtime": 1588679100000,
                "axATE": 68.88358857545744,
                "nonAxBytes": 35788779,
                "axBytes": 81286492,
                "axAirtime": 1180056,
                "nonAxAirtime": 715292,
                "nonAxATE": 50.03380297836408,
                "timestamp": "2020-05-05T11:45:00.000+0000"
            },
            {
                "modificationtime": 1588679400000,
                "axATE": 68.57035814062286,
                "nonAxBytes": 2258,
                "axBytes": 186438141,
                "axAirtime": 2718932,
                "nonAxAirtime": 2128,
                "nonAxATE": 1.0610902255639099,
                "timestamp": "2020-05-05T11:50:00.000+0000"
            },
            {
                "modificationtime": 1588679700000,
                "axATE": 69.18434361672018,
                "nonAxBytes": 4368,
                "axBytes": 141776708,
                "axAirtime": 2049260,
                "nonAxAirtime": 1808,
                "nonAxATE": 2.415929203539823,
                "timestamp": "2020-05-05T11:55:00.000+0000"
            },
            {
                "modificationtime": 1588680000000,
                "axATE": 68.91062236464833,
                "nonAxBytes": 3288,
                "axBytes": 163428432,
                "axAirtime": 2371600,
                "nonAxAirtime": 2804,
                "nonAxATE": 1.1726105563480742,
                "timestamp": "2020-05-05T12:00:00.000+0000"
            },
            {
                "modificationtime": 1588680300000,
                "axATE": 69.32241272981504,
                "nonAxBytes": 34874686,
                "axBytes": 90825116,
                "axAirtime": 1310184,
                "nonAxAirtime": 704112,
                "nonAxATE": 49.530026473061106,
                "timestamp": "2020-05-05T12:05:00.000+0000"
            },
            {
                "modificationtime": 1588680600000,
                "axATE": 69.3492934360844,
                "nonAxBytes": 30747593,
                "axBytes": 76851500,
                "axAirtime": 1108180,
                "nonAxAirtime": 599204,
                "nonAxATE": 51.31406499289057,
                "timestamp": "2020-05-05T12:10:00.000+0000"
            },
            {
                "modificationtime": 1588680900000,
                "axATE": 69.78035213832784,
                "nonAxBytes": 35048783,
                "axBytes": 75095382,
                "axAirtime": 1076168,
                "nonAxAirtime": 688944,
                "nonAxATE": 50.87319578949813,
                "timestamp": "2020-05-05T12:15:00.000+0000"
            },
            {
                "modificationtime": 1588681200000,
                "axATE": 70.0506386146998,
                "nonAxBytes": 37920077,
                "axBytes": 87687428,
                "axAirtime": 1251772,
                "nonAxAirtime": 744012,
                "nonAxATE": 50.96702338134331,
                "timestamp": "2020-05-05T12:20:00.000+0000"
            },
            {
                "modificationtime": 1588681500000,
                "axATE": 69.47220045999579,
                "nonAxBytes": 38252792,
                "axBytes": 88321120,
                "axAirtime": 1271316,
                "nonAxAirtime": 785656,
                "nonAxATE": 48.68898347368314,
                "timestamp": "2020-05-05T12:25:00.000+0000"
            },
            {
                "modificationtime": 1588681800000,
                "axATE": 69.23689124329759,
                "nonAxBytes": 32076617,
                "axBytes": 83725957,
                "axAirtime": 1209268,
                "nonAxAirtime": 621036,
                "nonAxATE": 51.6501732588771,
                "timestamp": "2020-05-05T12:30:00.000+0000"
            },
            {
                "modificationtime": 1588682100000,
                "axATE": 71.42302271144634,
                "nonAxBytes": 69730563,
                "axBytes": 34051926,
                "axAirtime": 476764,
                "nonAxAirtime": 1582252,
                "nonAxATE": 44.070453379107754,
                "timestamp": "2020-05-05T12:35:00.000+0000"
            },
            {
                "modificationtime": 1588682400000,
                "axATE": 0,
                "nonAxBytes": 74865298,
                "axBytes": 0,
                "axAirtime": 0,
                "nonAxAirtime": 1454032,
                "nonAxATE": 51.488067662885,
                "timestamp": "2020-05-05T12:40:00.000+0000"
            },
            {
                "modificationtime": 1588682700000,
                "axATE": 69.96561654677859,
                "nonAxBytes": 27628228,
                "axBytes": 88044452,
                "axAirtime": 1258396,
                "nonAxAirtime": 561208,
                "nonAxATE": 49.229925446536754,
                "timestamp": "2020-05-05T12:45:00.000+0000"
            },
            {
                "modificationtime": 1588683000000,
                "axATE": 69.57242622173509,
                "nonAxBytes": 38823208,
                "axBytes": 80993714,
                "axAirtime": 1164164,
                "nonAxAirtime": 771120,
                "nonAxATE": 50.346519348480136,
                "timestamp": "2020-05-05T12:50:00.000+0000"
            },
            {
                "modificationtime": 1588683300000,
                "axATE": 69.70558591983199,
                "nonAxBytes": 35272799,
                "axBytes": 88152751,
                "axAirtime": 1264644,
                "nonAxAirtime": 692004,
                "nonAxATE": 50.97195825457656,
                "timestamp": "2020-05-05T12:55:00.000+0000"
            },
            {
                "modificationtime": 1588683600000,
                "axATE": 69.69406388673663,
                "nonAxBytes": 30528412,
                "axBytes": 79155730,
                "axAirtime": 1135760,
                "nonAxAirtime": 609084,
                "nonAxATE": 50.121841979103046,
                "timestamp": "2020-05-05T13:00:00.000+0000"
            },
            {
                "modificationtime": 1588683900000,
                "axATE": 69.26130655000138,
                "nonAxBytes": 21735052,
                "axBytes": 120444858,
                "axAirtime": 1738992,
                "nonAxAirtime": 433424,
                "nonAxATE": 50.147319945365275,
                "timestamp": "2020-05-05T13:05:00.000+0000"
            },
            {
                "modificationtime": 1588684200000,
                "axATE": 69.79180792696735,
                "nonAxBytes": 32729039,
                "axBytes": 86296733,
                "axAirtime": 1236488,
                "nonAxAirtime": 641268,
                "nonAxATE": 51.03800439129974,
                "timestamp": "2020-05-05T13:10:00.000+0000"
            },
            {
                "modificationtime": 1588684500000,
                "axATE": 68.45916555703506,
                "nonAxBytes": 84721165,
                "axBytes": 4935632,
                "axAirtime": 72096,
                "nonAxAirtime": 1637964,
                "nonAxATE": 51.72345973415777,
                "timestamp": "2020-05-05T13:15:00.000+0000"
            },
            {
                "modificationtime": 1588684800000,
                "axATE": 69.41804185167597,
                "nonAxBytes": 40251297,
                "axBytes": 83610144,
                "axAirtime": 1204444,
                "nonAxAirtime": 787652,
                "nonAxATE": 51.10289442545693,
                "timestamp": "2020-05-05T13:20:00.000+0000"
            },
            {
                "modificationtime": 1588685100000,
                "axATE": 70.32521101387383,
                "nonAxBytes": 41864702,
                "axBytes": 97748949,
                "axAirtime": 1389956,
                "nonAxAirtime": 811388,
                "nonAxATE": 51.59640270745932,
                "timestamp": "2020-05-05T13:25:00.000+0000"
            },
            {
                "modificationtime": 1588685400000,
                "axATE": 69.72464643362846,
                "nonAxBytes": 23562831,
                "axBytes": 81740992,
                "axAirtime": 1172340,
                "nonAxAirtime": 506920,
                "nonAxATE": 46.482346326836584,
                "timestamp": "2020-05-05T13:30:00.000+0000"
            },
            {
                "modificationtime": 1588685700000,
                "axATE": 38.53627336642195,
                "nonAxBytes": 7110533,
                "axBytes": 74412619,
                "axAirtime": 1930976,
                "nonAxAirtime": 198068,
                "nonAxATE": 35.899453722963834,
                "timestamp": "2020-05-05T13:35:00.000+0000"
            },
            {
                "modificationtime": 1588686000000,
                "axATE": 70.35842365055598,
                "nonAxBytes": 36916217,
                "axBytes": 90028106,
                "axAirtime": 1279564,
                "nonAxAirtime": 717120,
                "nonAxATE": 51.47843736055332,
                "timestamp": "2020-05-05T13:40:00.000+0000"
            },
            {
                "modificationtime": 1588686300000,
                "axATE": 70.37971451088062,
                "nonAxBytes": 31738866,
                "axBytes": 110738258,
                "axAirtime": 1573440,
                "nonAxAirtime": 614036,
                "nonAxATE": 51.68893354787016,
                "timestamp": "2020-05-05T13:45:00.000+0000"
            },
            {
                "modificationtime": 1588686600000,
                "axATE": 69.97640211760651,
                "nonAxBytes": 38630133,
                "axBytes": 86075733,
                "axAirtime": 1230068,
                "nonAxAirtime": 752348,
                "nonAxATE": 51.34609648726387,
                "timestamp": "2020-05-05T13:50:00.000+0000"
            },
            {
                "modificationtime": 1588686900000,
                "axATE": 70.9543847275121,
                "nonAxBytes": 37017355,
                "axBytes": 90539214,
                "axAirtime": 1276020,
                "nonAxAirtime": 723728,
                "nonAxATE": 51.1481592532001,
                "timestamp": "2020-05-05T13:55:00.000+0000"
            },
            {
                "modificationtime": 1588687200000,
                "axATE": 69.82290722856133,
                "nonAxBytes": 38387137,
                "axBytes": 94015986,
                "axAirtime": 1346492,
                "nonAxAirtime": 749644,
                "nonAxATE": 51.20715566322148,
                "timestamp": "2020-05-05T14:00:00.000+0000"
            },
            {
                "modificationtime": 1588687500000,
                "axATE": 68.49192188817084,
                "nonAxBytes": 24618115,
                "axBytes": 85804488,
                "axAirtime": 1252768,
                "nonAxAirtime": 503208,
                "nonAxATE": 48.922344239360264,
                "timestamp": "2020-05-05T14:05:00.000+0000"
            },
            {
                "modificationtime": 1588687800000,
                "axATE": 69.47739321381871,
                "nonAxBytes": 4318,
                "axBytes": 175980123,
                "axAirtime": 2532912,
                "nonAxAirtime": 2580,
                "nonAxATE": 1.6736434108527132,
                "timestamp": "2020-05-05T14:10:00.000+0000"
            },
            {
                "modificationtime": 1588688100000,
                "axATE": 69.82514614872107,
                "nonAxBytes": 3796,
                "axBytes": 159717760,
                "axAirtime": 2287396,
                "nonAxAirtime": 2788,
                "nonAxATE": 1.3615494978479197,
                "timestamp": "2020-05-05T14:15:00.000+0000"
            },
            {
                "modificationtime": 1588688400000,
                "axATE": 69.85561490321642,
                "nonAxBytes": 6198,
                "axBytes": 191991172,
                "axAirtime": 2748400,
                "nonAxAirtime": 5796,
                "nonAxATE": 1.0693581780538302,
                "timestamp": "2020-05-05T14:20:00.000+0000"
            },
            {
                "modificationtime": 1588688700000,
                "axATE": 69.83594241143877,
                "nonAxBytes": 34037841,
                "axBytes": 76427338,
                "axAirtime": 1094384,
                "nonAxAirtime": 664636,
                "nonAxATE": 51.212755553415704,
                "timestamp": "2020-05-05T14:25:00.000+0000"
            },
            {
                "modificationtime": 1588689000000,
                "axATE": 70.2194272811443,
                "nonAxBytes": 33039981,
                "axBytes": 89189346,
                "axAirtime": 1270152,
                "nonAxAirtime": 644800,
                "nonAxATE": 51.24066532258065,
                "timestamp": "2020-05-05T14:30:00.000+0000"
            },
            {
                "modificationtime": 1588689300000,
                "axATE": 68.82408943933649,
                "nonAxBytes": 35690655,
                "axBytes": 83777637,
                "axAirtime": 1217272,
                "nonAxAirtime": 691120,
                "nonAxATE": 51.6417626461396,
                "timestamp": "2020-05-05T14:35:00.000+0000"
            },
            {
                "modificationtime": 1588689600000,
                "axATE": 69.25516338999418,
                "nonAxBytes": 33369300,
                "axBytes": 89503157,
                "axAirtime": 1292368,
                "nonAxAirtime": 670664,
                "nonAxATE": 49.75561533047845,
                "timestamp": "2020-05-05T14:40:00.000+0000"
            },
            {
                "modificationtime": 1588689900000,
                "axATE": 69.17013782593631,
                "nonAxBytes": 23860230,
                "axBytes": 82245784,
                "axAirtime": 1189036,
                "nonAxAirtime": 495192,
                "nonAxATE": 48.18379537633887,
                "timestamp": "2020-05-05T14:45:00.000+0000"
            },
            {
                "modificationtime": 1588690200000,
                "axATE": 69.84179752257927,
                "nonAxBytes": 26861497,
                "axBytes": 84258262,
                "axAirtime": 1206416,
                "nonAxAirtime": 549860,
                "nonAxATE": 48.851520387007604,
                "timestamp": "2020-05-05T14:50:00.000+0000"
            },
            {
                "modificationtime": 1588690500000,
                "axATE": 69.49445511439785,
                "nonAxBytes": 43600219,
                "axBytes": 92920202,
                "axAirtime": 1337088,
                "nonAxAirtime": 842144,
                "nonAxATE": 51.77287851008854,
                "timestamp": "2020-05-05T14:55:00.000+0000"
            },
            {
                "modificationtime": 1588690800000,
                "axATE": 69.74707868542441,
                "nonAxBytes": 37226730,
                "axBytes": 87335618,
                "axAirtime": 1252176,
                "nonAxAirtime": 727396,
                "nonAxATE": 51.17807906559838,
                "timestamp": "2020-05-05T15:00:00.000+0000"
            },
            {
                "modificationtime": 1588691100000,
                "axATE": 69.55270102615863,
                "nonAxBytes": 35155067,
                "axBytes": 79383558,
                "axAirtime": 1141344,
                "nonAxAirtime": 684768,
                "nonAxATE": 51.33865338333567,
                "timestamp": "2020-05-05T15:05:00.000+0000"
            },
            {
                "modificationtime": 1588691400000,
                "axATE": 18.90158127117774,
                "nonAxBytes": 5198058,
                "axBytes": 736330,
                "axAirtime": 38956,
                "nonAxAirtime": 196704,
                "nonAxATE": 26.425786969253295,
                "timestamp": "2020-05-05T15:10:00.000+0000"
            },
            {
                "modificationtime": 1588691700000,
                "axATE": 70.65614674533688,
                "nonAxBytes": 27308580,
                "axBytes": 74245479,
                "axAirtime": 1050800,
                "nonAxAirtime": 544360,
                "nonAxATE": 50.166397237122496,
                "timestamp": "2020-05-05T15:15:00.000+0000"
            },
            {
                "modificationtime": 1588692000000,
                "axATE": 69.81173203686222,
                "nonAxBytes": 33061279,
                "axBytes": 69815083,
                "axAirtime": 1000048,
                "nonAxAirtime": 641840,
                "nonAxATE": 51.51015673688146,
                "timestamp": "2020-05-05T15:20:00.000+0000"
            },
            {
                "modificationtime": 1588692300000,
                "axATE": 69.79978921354848,
                "nonAxBytes": 26773449,
                "axBytes": 74903829,
                "axAirtime": 1073124,
                "nonAxAirtime": 571172,
                "nonAxATE": 46.87458243751444,
                "timestamp": "2020-05-05T15:25:00.000+0000"
            },
            {
                "modificationtime": 1588692600000,
                "axATE": 69.86909437671035,
                "nonAxBytes": 27599681,
                "axBytes": 76492964,
                "axAirtime": 1094804,
                "nonAxAirtime": 535904,
                "nonAxATE": 51.501166253657374,
                "timestamp": "2020-05-05T15:30:00.000+0000"
            },
            {
                "modificationtime": 1588692900000,
                "axATE": 68.11304078723337,
                "nonAxBytes": 17879753,
                "axBytes": 60966893,
                "axAirtime": 895084,
                "nonAxAirtime": 350708,
                "nonAxATE": 50.98187951230083,
                "timestamp": "2020-05-05T15:35:00.000+0000"
            },
            {
                "modificationtime": 1588693200000,
                "axATE": 68.74688313162183,
                "nonAxBytes": 25304631,
                "axBytes": 67845474,
                "axAirtime": 986888,
                "nonAxAirtime": 496864,
                "nonAxATE": 50.928686723127456,
                "timestamp": "2020-05-05T15:40:00.000+0000"
            },
            {
                "modificationtime": 1588693500000,
                "axATE": 69.91768498957514,
                "nonAxBytes": 16395404,
                "axBytes": 101272970,
                "axAirtime": 1448460,
                "nonAxAirtime": 322620,
                "nonAxATE": 50.81955241460542,
                "timestamp": "2020-05-05T15:45:00.000+0000"
            },
            {
                "modificationtime": 1588693800000,
                "axATE": 68.5023592027782,
                "nonAxBytes": 23972091,
                "axBytes": 72590580,
                "axAirtime": 1059680,
                "nonAxAirtime": 470916,
                "nonAxATE": 50.905237876818795,
                "timestamp": "2020-05-05T15:50:00.000+0000"
            },
            {
                "modificationtime": 1588694100000,
                "axATE": 70.44964288119412,
                "nonAxBytes": 30970992,
                "axBytes": 71136386,
                "axAirtime": 1009748,
                "nonAxAirtime": 598280,
                "nonAxATE": 51.766717924717526,
                "timestamp": "2020-05-05T15:55:00.000+0000"
            },
            {
                "modificationtime": 1588694400000,
                "axATE": 69.57655033729208,
                "nonAxBytes": 38428347,
                "axBytes": 91134427,
                "axAirtime": 1309844,
                "nonAxAirtime": 767888,
                "nonAxATE": 50.04420826995604,
                "timestamp": "2020-05-05T16:00:00.000+0000"
            },
            {
                "modificationtime": 1588694700000,
                "axATE": 70.0625137202587,
                "nonAxBytes": 30170786,
                "axBytes": 82980640,
                "axAirtime": 1184380,
                "nonAxAirtime": 607808,
                "nonAxATE": 49.63867866168264,
                "timestamp": "2020-05-05T16:05:00.000+0000"
            },
            {
                "modificationtime": 1588695000000,
                "axATE": 68.64055318068645,
                "nonAxBytes": 16019733,
                "axBytes": 87061893,
                "axAirtime": 1268374,
                "nonAxAirtime": 346152,
                "nonAxATE": 46.27947549053595,
                "timestamp": "2020-05-05T16:10:00.000+0000"
            },
            {
                "modificationtime": 1588695300000,
                "axATE": 69.31829920280529,
                "nonAxBytes": 37980602,
                "axBytes": 96308904,
                "axAirtime": 1389372,
                "nonAxAirtime": 745984,
                "nonAxATE": 50.91342709763212,
                "timestamp": "2020-05-05T16:15:00.000+0000"
            },
            {
                "modificationtime": 1588695600000,
                "axATE": 69.68681013486378,
                "nonAxBytes": 29918743,
                "axBytes": 86064883,
                "axAirtime": 1235024,
                "nonAxAirtime": 599316,
                "nonAxATE": 49.92148215632488,
                "timestamp": "2020-05-05T16:20:00.000+0000"
            },
            {
                "modificationtime": 1588695900000,
                "axATE": 68.51626288831704,
                "nonAxBytes": 30585273,
                "axBytes": 78067704,
                "axAirtime": 1139404,
                "nonAxAirtime": 597728,
                "nonAxATE": 51.169215763691845,
                "timestamp": "2020-05-05T16:25:00.000+0000"
            },
            {
                "modificationtime": 1588696200000,
                "axATE": 70.68333393474123,
                "nonAxBytes": 35669635,
                "axBytes": 94023819,
                "axAirtime": 1330212,
                "nonAxAirtime": 709204,
                "nonAxATE": 50.295309953130555,
                "timestamp": "2020-05-05T16:30:00.000+0000"
            },
            {
                "modificationtime": 1588696500000,
                "axATE": 68.71064033797619,
                "nonAxBytes": 37721342,
                "axBytes": 76245724,
                "axAirtime": 1109664,
                "nonAxAirtime": 742800,
                "nonAxATE": 50.78263597199785,
                "timestamp": "2020-05-05T16:35:00.000+0000"
            },
            {
                "modificationtime": 1588696800000,
                "axATE": 17.065217391304348,
                "nonAxBytes": 38072308,
                "axBytes": 1362760,
                "axAirtime": 79856,
                "nonAxAirtime": 781088,
                "nonAxATE": 48.74266151829244,
                "timestamp": "2020-05-05T16:40:00.000+0000"
            },
            {
                "modificationtime": 1588697100000,
                "axATE": 70.05707743156685,
                "nonAxBytes": 37964238,
                "axBytes": 95636878,
                "axAirtime": 1365128,
                "nonAxAirtime": 732956,
                "nonAxATE": 51.79606688532463,
                "timestamp": "2020-05-05T16:45:00.000+0000"
            },
            {
                "modificationtime": 1588697400000,
                "axATE": 69.77328694818888,
                "nonAxBytes": 32925494,
                "axBytes": 108925311,
                "axAirtime": 1561132,
                "nonAxAirtime": 641804,
                "nonAxATE": 51.301478332949,
                "timestamp": "2020-05-05T16:50:00.000+0000"
            },
            {
                "modificationtime": 1588697700000,
                "axATE": 69.68641457167516,
                "nonAxBytes": 44288116,
                "axBytes": 100122653,
                "axAirtime": 1436760,
                "nonAxAirtime": 864656,
                "nonAxATE": 51.22050387668622,
                "timestamp": "2020-05-05T16:55:00.000+0000"
            },
            {
                "modificationtime": 1588698000000,
                "axATE": 69.68973249363256,
                "nonAxBytes": 41590410,
                "axBytes": 94015073,
                "axAirtime": 1349052,
                "nonAxAirtime": 807432,
                "nonAxATE": 51.5094893439943,
                "timestamp": "2020-05-05T17:00:00.000+0000"
            },
            {
                "modificationtime": 1588698300000,
                "axATE": 70.11842605785907,
                "nonAxBytes": 41213611,
                "axBytes": 87003504,
                "axAirtime": 1240808,
                "nonAxAirtime": 812820,
                "nonAxATE": 50.70447454540981,
                "timestamp": "2020-05-05T17:05:00.000+0000"
            },
            {
                "modificationtime": 1588698600000,
                "axATE": 69.996099166234,
                "nonAxBytes": 43422893,
                "axBytes": 91675291,
                "axAirtime": 1309720,
                "nonAxAirtime": 836716,
                "nonAxATE": 51.89681206048408,
                "timestamp": "2020-05-05T17:10:00.000+0000"
            },
            {
                "modificationtime": 1588698900000,
                "axATE": 70.02523843726613,
                "nonAxBytes": 38723387,
                "axBytes": 102172705,
                "axAirtime": 1459084,
                "nonAxAirtime": 774812,
                "nonAxATE": 49.97778428831768,
                "timestamp": "2020-05-05T17:15:00.000+0000"
            },
            {
                "modificationtime": 1588699200000,
                "axATE": 69.769665549099,
                "nonAxBytes": 25925939,
                "axBytes": 81741582,
                "axAirtime": 1171592,
                "nonAxAirtime": 523396,
                "nonAxATE": 49.53407935865005,
                "timestamp": "2020-05-05T17:20:00.000+0000"
            },
            {
                "modificationtime": 1588699500000,
                "axATE": 69.91258179765089,
                "nonAxBytes": 29757563,
                "axBytes": 90641103,
                "axAirtime": 1296492,
                "nonAxAirtime": 600116,
                "nonAxATE": 49.58635163868319,
                "timestamp": "2020-05-05T17:25:00.000+0000"
            },
            {
                "modificationtime": 1588699800000,
                "axATE": 69.79477902518782,
                "nonAxBytes": 27527472,
                "axBytes": 79227683,
                "axAirtime": 1135152,
                "nonAxAirtime": 546324,
                "nonAxATE": 50.386715575372854,
                "timestamp": "2020-05-05T17:30:00.000+0000"
            },
            {
                "modificationtime": 1588700100000,
                "axATE": 69.24498236234182,
                "nonAxBytes": 34177832,
                "axBytes": 85272149,
                "axAirtime": 1231456,
                "nonAxAirtime": 668840,
                "nonAxATE": 51.100161473596074,
                "timestamp": "2020-05-05T17:35:00.000+0000"
            },
            {
                "modificationtime": 1588700400000,
                "axATE": 69.8494173653751,
                "nonAxBytes": 39178948,
                "axBytes": 96603700,
                "axAirtime": 1383028,
                "nonAxAirtime": 764936,
                "nonAxATE": 51.2185960655532,
                "timestamp": "2020-05-05T17:40:00.000+0000"
            },
            {
                "modificationtime": 1588700700000,
                "axATE": 69.08397691066988,
                "nonAxBytes": 24825503,
                "axBytes": 69558722,
                "axAirtime": 1006872,
                "nonAxAirtime": 490932,
                "nonAxATE": 50.568109228976724,
                "timestamp": "2020-05-05T17:45:00.000+0000"
            },
            {
                "modificationtime": 1588701000000,
                "axATE": 68.68775527488191,
                "nonAxBytes": 37895569,
                "axBytes": 85969045,
                "axAirtime": 1251592,
                "nonAxAirtime": 754408,
                "nonAxATE": 50.23219398521755,
                "timestamp": "2020-05-05T17:50:00.000+0000"
            },
            {
                "modificationtime": 1588701300000,
                "axATE": 69.00598808110678,
                "nonAxBytes": 24815968,
                "axBytes": 79526365,
                "axAirtime": 1152456,
                "nonAxAirtime": 509096,
                "nonAxATE": 48.74516397693166,
                "timestamp": "2020-05-05T17:55:00.000+0000"
            },
            {
                "modificationtime": 1588701600000,
                "axATE": 69.41240318553109,
                "nonAxBytes": 24087031,
                "axBytes": 78722550,
                "axAirtime": 1134128,
                "nonAxAirtime": 482068,
                "nonAxATE": 49.96604420953061,
                "timestamp": "2020-05-05T18:00:00.000+0000"
            },
            {
                "modificationtime": 1588701900000,
                "axATE": 69.9140622307687,
                "nonAxBytes": 34366806,
                "axBytes": 81150091,
                "axAirtime": 1160712,
                "nonAxAirtime": 684852,
                "nonAxATE": 50.181361812479196,
                "timestamp": "2020-05-05T18:05:00.000+0000"
            },
            {
                "modificationtime": 1588702200000,
                "axATE": 70.2361576630431,
                "nonAxBytes": 28260526,
                "axBytes": 81305938,
                "axAirtime": 1157608,
                "nonAxAirtime": 592884,
                "nonAxATE": 47.666197772245496,
                "timestamp": "2020-05-05T18:10:00.000+0000"
            },
            {
                "modificationtime": 1588702500000,
                "axATE": 29.144192361204613,
                "nonAxBytes": 29771693,
                "axBytes": 67533622,
                "axAirtime": 2317224,
                "nonAxAirtime": 589936,
                "nonAxATE": 50.46597088497735,
                "timestamp": "2020-05-05T18:15:00.000+0000"
            },
            {
                "modificationtime": 1588702800000,
                "axATE": 69.31571649784527,
                "nonAxBytes": 33533773,
                "axBytes": 80036917,
                "axAirtime": 1154672,
                "nonAxAirtime": 659156,
                "nonAxATE": 50.873803773310115,
                "timestamp": "2020-05-05T18:20:00.000+0000"
            },
            {
                "modificationtime": 1588703100000,
                "axATE": 69.27241532437124,
                "nonAxBytes": 29541673,
                "axBytes": 68637880,
                "axAirtime": 990840,
                "nonAxAirtime": 574364,
                "nonAxATE": 51.433712767513285,
                "timestamp": "2020-05-05T18:25:00.000+0000"
            }
        ],
        "insights": 34.68
    }
})