define([
  'core/../../../public/libs/node_modules/moment',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/license-manage/LicenseOverviewTemplate',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/license-manage/LicenseUsageTemplate',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/license-manage/LicensePurchaseTemplate',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/license-manage/AllLicenseTemplate',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/license-manage/licenseViewAllTemplate',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/license-manage/licenseviewdetails',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/license-manage/LicenseSettingTemplate',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/license-manage/LicenseSmartDetails',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimLicenseTaskDataGenerator',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/license-manage/LicenseTaskStatusTemplate',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/license-manage/Licensenotificationdetails'
], function (moment, SimLokiDatabaseActions, LicenseOverviewTemplate, LicenseUsageTemplate, LicensePurchaseTemplate,
  AllLicenseTemplate, UtilityFunctions, licenseViewAllTemplate, licenseviewdetails, LicenseSettingTemplate, LicenseSmartDetails,
  SimTaskDataGenerator, SimLicenseTaskDataGenerator, LicenseTaskStatusTemplate, Licensenotificationdetails) {

    var validFilters = ['deviceName', 'deviceType', 'model', 'site', 'macAddress', 'dnaLevel', 
      'licenseTerm', 'reservationStatus', 'authorizationStatus', 'virtualAccount', 'licenseMode', 
      'registrationStatus', 'ipAddress', 'licenseExpiry', 'networkLicense'];

    function createLicenseDetails() {
      var licenses = [];
      var objs = SimLokiDatabaseActions.getAll('network-device');
      for (i = 0; i < objs.length; i++) {
        if (objs[i].family == 'ECA Switch') { continue; }
        var tJson = JSON.parse(JSON.stringify(AllLicenseTemplate.All_License_Template));
        var site = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'id': objs[i].siteId });
        tJson.deviceType = objs[i].family;
        tJson.totalApCount = 1;//need to update this
        tJson.deviceName = objs[i].hostname;
        tJson.ipAddress = objs[i].managementIpAddress;
        tJson.macAddress = objs[i].macAddress;
        tJson.id = objs[i].id;
        tJson.model = objs[i].series;
        tJson.licenseTerm = "90";//"CSSM-Managed";
        tJson.dnaLevel = "Essentials";
        //console.log('i::' + i + '::deviceName::' + tJson.deviceName + '::obj::' + objs[i]);
        tJson.site = site.length>0 ? site[0].groupNameHierarchy : "";
        tJson.softwareVersion = objs[i].softwareVersion;
        tJson.lastUpdatedTime = UtilityFunctions.getGivenDateAndTimeInValidFormat(UtilityFunctions.getTimeStamp());
        if (tJson.deviceType == "Wireless Controller") {
          tJson.isWireless = true;
          tJson.licenseMode = "RTU";
          tJson.networkLicense = "Legacy";
        } else if (tJson.deviceType == "Switches and Hubs") {
          tJson.authorizationStatus = "Unknown";//Evaluation Mode
          tJson.registrationStatus = "Unregistered";
          tJson.sntcStatus = "Unknown";
          tJson.licenseMode = "SL";
          if (objs[i].series == "Cisco Catalyst 9300 Series Switches") {
            tJson.dnaLevel = "Advantage";
            //tJson.licenseMode = "RTU";
            tJson.licenseTerm = "90";//"CSSM-Managed";//90
            tJson.networkLicense = "Advantage";
          }
        } else if (tJson.deviceType == "Routers") {
          tJson.authorizationStatus = "Unknown";
          tJson.registrationStatus = "Unknown";
          tJson.reservationStatus = "Unknown";
          tJson.sntcStatus = "Unknown";
          tJson.isFeatureLicense = true;
          tJson.licenseMode = "RTU";
          tJson.networkLicense = "Legacy";
        } else if (tJson.deviceType == "Wireless Sensor") {
          tJson.registrationStatus = "Unknown";
          tJson.reservationStatus = "Unknown";
        }
        licenses.push(tJson);
      }
      SimLokiDatabaseActions.insert('licenseInfo', licenses);
    }

    function createUsage(family, devType) {
      var objs = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'family': family });
      var seriesCount = {}, usageArray = [];
      for (i = 0; i < objs.length; i++) {
        var field = objs[i].series;
        if (seriesCount[field] == undefined) {
          var tJson = JSON.parse(JSON.stringify(LicenseUsageTemplate.License_Usage_Template));
          tJson.id = objs[i].series;
          tJson.model = objs[i].series;
          if (objs[i].series == "Cisco Catalyst 9300 Series Switches") {
            tJson.entitledNetwork = true;
            tJson.deployedNetwork = true;
            tJson.dnaUsage = "underused";
          }
          seriesCount[field] = 1;
          //to update dynamically - need to check which table to refer to..
          if(devType == 'controller') {
            tJson.connectedAps = 20;
          } else {
            tJson.connectedAps = 0;
          }
          usageArray.push(tJson);
        }
        else {
          seriesCount[field] = seriesCount[field] + 1;
        }
      }
      for (var j = 0; j < usageArray.length; j++) {
        usageArray[j].totalDevices = seriesCount[usageArray[j].id];
        usageArray[j].nwAndLegacyDeployedLicenses = usageArray[j].totalDevices;
        usageArray[j].dnaDeployedLicenses = usageArray[j].totalDevices;
        var diffLicense = UtilityFunctions.getRandomIntForRangeValues(1, 3);
        if (diffLicense > usageArray[j].totalDevices) {
          diffLicense = usageArray[j].totalDevices;
        }
        usageArray[j].dnaEntitlementLicenses = usageArray[j].dnaDeployedLicenses + diffLicense;
        usageArray[j].nwAndLegacyEntitlementLicenses = usageArray[j].dnaDeployedLicenses + diffLicense;
        usageArray[j].dnaUsageCount = diffLicense;
      }
      SimLokiDatabaseActions.insert('licenseUsage', { deviceType: devType, data: usageArray });
      //console.log('Inserted usage info for ' + family +'! :: ' + usageArray.length);
    }

    function createLicenseUsage() {
      createUsage("Switches and Hubs", "switch");
      createUsage("Routers", "router");
      createUsage("Unified AP", "wireless");
      createUsage("Wireless Controller", "wireless");
    }

    function getFamilyFromSeries(series) {
      var records = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'series': series });
      if (records && records.length) {
        return records[0].family;
      }
      return undefined;
    }

    function getDevTypeFromSeries(url) {
      var fields = (url.split('?'))[0].split('/');
      var seriesCall = fields.length > 5 ? fields[6] : undefined;
      if (seriesCall) {
        var records = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'series': seriesCall });
        var family = getFamilyFromSeries(seriesCall);
        var devType;
        if (records[0].family == "Switches and Hubs") {
          devType = "switch";
        } else if (records[0].family == "Routers") {
          devType = "router";
        } else if (records[0].family == "Unified AP" || records[0].family == "Wireless Controller") {
          devType = "wireless";
        }// below condition i changed
        else if (records[0].family == "Wireless Controller") {
          devType = "wireless";
        } else {
          return {};
        }
        var obj = {};
        obj[seriesCall] = devType;
        return obj;
      } else {
        return {};
      }
    }

    function getExpiresIn(endDate) {
      var expiresInResult, daysDiff, monthsDiff, yearsDiff;
      var currDate = moment(new Date()).utc().format("YYYY-MM-DD");
      var timeDiff = new Date(endDate) - new Date(currDate);
      yearsDiff = timeDiff/(1000*60*60*24*365);
      monthsDiff = (yearsDiff - Math.floor(yearsDiff))*12;
      daysDiff = Math.floor((monthsDiff - Math.floor(monthsDiff))*30);
      monthsDiff = Math.floor(monthsDiff);
      yearsDiff = Math.floor(yearsDiff);
      if(yearsDiff >=1 ) {
        expiresInResult = yearsDiff + ' year';
      } if(monthsDiff >=1 ) {
        expiresInResult = expiresInResult != undefined ? expiresInResult + ' ' + monthsDiff + ' month' : monthsDiff + ' month';
      } if(daysDiff >=1 ) {
        expiresInResult = expiresInResult != undefined ? expiresInResult + ' ' + daysDiff + ' days' : daysDiff + ' days';
      }
      return expiresInResult;
    }

    function getDetails(filterName, filterValue) {
      var records, filterObj = {'$and':[]}, filterObjTemp = {};
      if (filterName && filterName.length>0) {
        for(var i=0; i<filterName.length; i++) {
          var name = filterName[i], val = filterValue[i];
          if(filterObjTemp[name]) {
            filterObjTemp[name]['$contains'].push(val);
          } else {
            filterObjTemp[name] = {'$contains' : [val]};
          }
          /*
          if(filterObjTemp[name]) {
            filterObjTemp[name]['$in'].push(val);
          } else {
            filterObjTemp[name] = {'$in' : [val]};
          } */
        }
        Object.keys(filterObjTemp).forEach(function (key) {
          var obj = {}; obj[key] = filterObjTemp[key];
          filterObj['$and'].push(obj);
        });
        records = SimLokiDatabaseActions.getFilteredRecordHandler('licenseInfo', filterObj);
      } else {
        records = SimLokiDatabaseActions.getAll('licenseInfo');
      }
      return records;
    }

    function getLimitDetails(data, limit, offset) {
      var result = [];
      var len = data.length > (offset - 1 + limit) ? (offset - 1 + limit) : data.length;
      for (var i = (offset - 1); i < len; i++) {
        result.push(data[i]);
      }
      return result;
    }

    function getSpecificUsage(usageArray, specificSeries, devType, virtualAccount) {
      for (var j = 0; j < usageArray.length; j++) {
        if (usageArray[j].id == specificSeries) {
          var tJson = JSON.parse(JSON.stringify(licenseViewAllTemplate.License_ViewAll_Template));
          tJson.deployedDNA = usageArray[j].dnaDeployedLicenses;
          if(['EFT FIELD SEVT',"All"].indexOf(virtualAccount) == -1){
            tJson.deployedDNAAdvantage = 0;
            tJson.deployedDNAEssential = 0;
            tJson.deployedNetworkAdvantage = 0;
            tJson.deployedNetworkEssential = 0;
          } else {
            tJson.deployedDNAAdvantage = usageArray[j].dnaUsageCount;
            tJson.deployedDNAEssential = usageArray[j].nwAndLegacyDeployedLicenses - usageArray[j].dnaUsageCount;
            tJson.deployedNetworkAdvantage = usageArray[j].dnaUsageCount;
            tJson.deployedNetworkEssential = usageArray[j].nwAndLegacyDeployedLicenses - usageArray[j].dnaUsageCount;
          }
          tJson.deployedNetworkOrLegacy = usageArray[j].nwAndLegacyDeployedLicenses;
          tJson.deviceType = usageArray[j].family;
          tJson.dnaAdvUsage = usageArray[j].dnaUsageCount;
          tJson.dnaAdvUsageCount = usageArray[j].dnaUsageCount;
          tJson.dnaEssUsage = usageArray[j].dnaUsageCount;
          tJson.dnaEssUsageCount = usageArray[j].dnaUsageCount;
          tJson.entitledDNA = usageArray[j].nwAndLegacyEntitlementLicenses; //this is for the dna 1st block
          tJson.entitledDNAAdvantage = usageArray[j].nwAndLegacyEntitlementLicenses - usageArray[j].dnaUsageCount;
          tJson.entitledDNAEssential = usageArray[j].dnaUsageCount;
          tJson.entitledNetworkAdvantage = usageArray[j].nwAndLegacyEntitlementLicenses - usageArray[j].dnaUsageCount;
          tJson.entitledNetworkEssential = usageArray[j].dnaUsageCount;
          tJson.entitledNetworkOrLegacy = usageArray[j].nwAndLegacyEntitlementLicenses;
          if (devType == 'router') {
            for (k = 0; k < tJson.featureLicense.length; k++) {
              tJson.featureLicense[k].value = usageArray[j].nwAndLegacyDeployedLicenses;
            }
            tJson.isNetwork = true;
          } else if (devType == 'wireless') {
            tJson.deployedAIRDNA = usageArray[j].dnaUsageCount;
            tJson.deployedAIRDNAAdvantage = usageArray[j].dnaUsageCount;
            tJson.deployedAIRDNAEssential = usageArray[j].dnaUsageCount;
            tJson.deployedAIRNW = usageArray[j].dnaUsageCount;
            tJson.deployedAIRNWAdvantage = usageArray[j].dnaUsageCount;
            tJson.deployedAIRNWEssential = usageArray[j].dnaUsageCount;
            tJson.isNetwork = true;
          } else {
            delete tJson.featureLicense;
            tJson.isNetwork = false;
          }
          tJson.nwAdvUsage = usageArray[j].dnaUsageCount;
          tJson.nwAdvUsageCount = usageArray[j].dnaUsageCount;
          tJson.nwEssUsage = usageArray[j].dnaUsageCount;
          tJson.nwEssUsageCount = usageArray[j].dnaUsageCount;
          tJson.id = usageArray[j].series;
          tJson.model = usageArray[j].series;
          tJson.isCSSMEnabled = true;
          tJson.model = usageArray[j].id;
          tJson.totalDevices = usageArray[j].totalDevices;
          tJson.type = "success";
          tJson.isNetwork = true;
          return tJson;
        }
      }
    }

    function getDetailsAutoComplete(filterValue, fieldName) {
      var tObjs = [];
      var records = SimLokiDatabaseActions.getAll('licenseInfo');
      if (filterValue && filterValue.length > 0) {
        for (var i = 0; i < records.length; i++) {
          if ((records[i])[fieldName].indexOf(filterValue) > -1 && tObjs.indexOf((records[i])[fieldName]) == -1) {
            tObjs.push((records[i])[fieldName]);
          }
        }
      } else {
        for (var i = 0; i < records.length; i++) {
          if (tObjs.indexOf((records[i])[fieldName]) == -1) {
            tObjs.push((records[i])[fieldName]);
          }
        }
      }
      return tObjs;
    }

    function createVirtualAccounts() {
      var records = JSON.parse(JSON.stringify(LicenseSmartDetails.VIRTUAL_ACCOUNTS));
      SimLokiDatabaseActions.insert('licenseVirtualAccount', records);
    }

    function updateTaskStatus(operation, licenseRec) {
      var tJson;
      switch (operation) {
        case 'register':
          tJson = JSON.parse(JSON.stringify(LicenseTaskStatusTemplate.REGISTER_TEMPLATE));
          break;
        case 'deregister':
          tJson = JSON.parse(JSON.stringify(LicenseTaskStatusTemplate.DEREGISTER_TEMPLATE));
          break;
        case 'enableLicReservation':
          tJson = JSON.parse(JSON.stringify(LicenseTaskStatusTemplate.ENABLE_REG_TEMPLATE));
          break;
        case 'cancelReservation':
          tJson = JSON.parse(JSON.stringify(LicenseTaskStatusTemplate.CANCEL_TEMPLATE));
          break;
      }
      tJson.table_store[0].device_name = licenseRec.deviceName;
      tJson.table_store[0].device_uuid = licenseRec.id;
      tJson.table_store[0].device_ipaddress = licenseRec.ipAddress;
      tJson.createdDate = UtilityFunctions.getGivenDateAndTimeInValidFormat(UtilityFunctions.getTimeStamp());
      tJson.device_type = licenseRec.model;
      tJson.device_family = licenseRec.deviceType;
      SimLokiDatabaseActions.insert('licenseTaskStatus', tJson);
    }

    function processRequestCodes(record, reqCode) {
      var progress = JSON.parse(JSON.stringify(LicenseTaskStatusTemplate.PROGRESS_REQUESTCODES));
      progress.deviceFamily = record.family;
      progress.deviceIP = record.managementIpAddress;
      progress.deviceId = record.id;
      progress.deviceType = record.type;
      progress.deviceName = record.name;
      progress.deviceUUId = record.id;
      progress = JSON.stringify(progress);

      var resultObj = SimTaskDataGenerator.createTask('License Manager Reservation', { progress: progress });
      SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });

      var obj = {
        "device_name": record.name,
        "request_count": "1, 1",
        "device_uuid": record.id,
        "request_code": reqCode,
        "isError": false,
        "device_ip": record.managementIpAddress,
        "errorMessage": "NA",
        "authorization_code": "NA",
        "entitlement_tags": "C9300-48 DNA Advantage, C9300-48 Network Advantage"
      }
      resultObj = SimLicenseTaskDataGenerator.createTask(false, obj);
      return { 'response': resultObj };
    }

    function processAuthorizationCodes(record, reqCode, virtual_account) {
      var progress = JSON.parse(JSON.stringify(LicenseTaskStatusTemplate.PROCESS_AUTORIZATIONCODES));
      progress.deviceFamily = record.family;
      progress.deviceIP = record.managementIpAddress;
      progress.request_code = reqCode;
      progress.deviceId = record.id;
      progress.deviceType = record.type;
      progress.deviceName = record.name;
      progress.vaname = virtual_account;
      progress.deviceUUId = record.id;
      progress = JSON.stringify(progress);

      var resultObj = SimTaskDataGenerator.createTask('License Manager Reservation', { progress: progress });
      SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });

      //update table data
      var licenseRec = SimLokiDatabaseActions.getFilteredRecordHandler('licenseInfo', { 'id': record.id });
      licenseRec[0].registrationStatus = 'Registered - SLR';
      licenseRec[0].virtualAccount = virtual_account;
      licenseRec[0].authorizationStatus = 'Authorized - Reserved';
      licenseRec[0].reservationStatus = 'Installed';
      licenseRec[0].daysToExpiry = 'NA';
      licenseRec[0].licenseExpiry = null;
      licenseRec[0].licenseTerm = null;
      licenseRec[0].lastUpdatedTime = UtilityFunctions.getGivenDateAndTimeInValidFormat(UtilityFunctions.getTimeStamp());
      SimLokiDatabaseActions.update('licenseInfo', licenseRec);
      updateTaskStatus('enableLicReservation', licenseRec[0]);

      var obj = {
        "device_name": record.name,
        "device_uuid": record.id,
        "request_code": reqCode,
        "isError": false,
        "device_ip": record.managementIpAddress,
        "errorMessage": "NA",
        "authorization_code": "<specificPLR><authorizationCode><flag>A</flag><version>C</version><piid>496270a5-3c9f-47e9-aa1f-0b991740769d</piid><timestamp>1545198414651</timestamp><entitlements><entitlement><tag>regid.2017-05.com.cisco.C9300_48P_NW_Advantagek9,1.0_6a224fe3-c92e-4440-a9c1-5d0e53a54015</tag><count>1</count><startDate></startDate><endDate></endDate><licenseType>PERPETUAL</licenseType><displayName>C9300 48P NW Advantage</displayName><tagDescription>C9300 48P NW Advantage</tagDescription><subscriptionID></subscriptionID></entitlement><entitlement><tag>regid.2017-05.com.cisco.C9300_48P_Dna_Advantage,1.0_60783b06-53ee-484c-b21e-615d3cf6837a</tag><count>1</count><startDate>2018-Apr-18 UTC</startDate><endDate>2019-Apr-18 UTC</endDate><licenseType>TERM</licenseType><displayName>C9300 48P DNA Advantage</displayName><tagDescription>C9300 48P DNA Advantage</tagDescription><subscriptionID></subscriptionID></entitlement></entitlements></authorizationCode><signature>MEUCIQDvEfbE17nyaMmeiSexZ0qW90BI01Z8OPn2jridDYoL2AIgR0qjrhlF9rdfvJg+clYc7BqnhyIremj9zffVzD7S6JU=</signature><udi>P:C9300-48UXM,S:FCW2152L03D</udi></specificPLR>"
      };
      resultObj = SimLicenseTaskDataGenerator.createTask(false, obj);
      return { 'response': resultObj };
    }

    function processInstallLicense(record) {
      var progress = JSON.parse(JSON.stringify(LicenseTaskStatusTemplate.PROCESS_INSTALL_LICENSE));
      progress.deviceFamily = record[0].family;
      progress.deviceIP = record[0].managementIpAddress;
      progress.deviceId = record[0].id;
      progress.deviceType = record[0].type;
      progress.deviceName = record[0].name;
      progress.deviceUUId = record[0].id;
      progress = JSON.stringify(progress);

      var resultObj = SimTaskDataGenerator.createTask('License Manager Reservation', { progress: progress });

      SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });
      return { 'response': resultObj };
    }

    function processCancel(record, daysIncremented, incTimeStamp) {
      var progress = JSON.parse(JSON.stringify(LicenseTaskStatusTemplate.PROCESS_CANCEL));
      progress.deviceFamily = record.family;
      progress.deviceIP = record.managementIpAddress;
      progress.deviceId = record.id;
      progress.deviceType = record.type;
      progress.deviceName = record.name;
      progress.deviceUUId = record.id;
      progress = JSON.stringify(progress);

      var resultObj = SimTaskDataGenerator.createTask('License Manager', { progress: progress });

      //update table data
      var licenseRec = SimLokiDatabaseActions.getFilteredRecordHandler('licenseInfo', { 'id': record.id });
      licenseRec[0].registrationStatus = 'Unregistered';
      licenseRec[0].virtualAccount = 'NA';
      licenseRec[0].authorizationStatus = 'Evaluation Mode';
      licenseRec[0].reservationStatus = 'NA';
      licenseRec[0].daysToExpiry = daysIncremented;
      licenseRec[0].licenseExpiry = UtilityFunctions.getGivenDateAndTimeInValidFormat(incTimeStamp);
      licenseRec[0].licenseTerm = 90;
      licenseRec[0].lastUpdatedTime = UtilityFunctions.getGivenDateAndTimeInValidFormat(UtilityFunctions.getTimeStamp());
      SimLokiDatabaseActions.update('licenseInfo', licenseRec);

      SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });
      updateTaskStatus('cancelReservation', licenseRec[0]);

      resultObj = SimLicenseTaskDataGenerator.createTask(false);
      return { "response": resultObj };
    }

    function sortFormattedTime(data, sortBy) {
      if (sortBy != undefined) {
        return data.sort(function (a, b) {
          if (a[sortBy] == undefined) {
            return 1;
          }
          if (b[sortBy] == undefined) {
            return 1;
          }
          if (new Date(a[sortBy]) > new Date(b[sortBy])) return -1;
          if (new Date(a[sortBy]) < new Date(b[sortBy])) return 1;
          return 0;

        });
      }
    }

    return {

      init: function () {
        createLicenseDetails();
        createLicenseUsage();
        createVirtualAccounts();
      },

      getOverviewDetails: function (urlAction, isDashboardOverview) {
        var family = "Wireless Controller";
        var advDeployed = 1, essDeployed = 2, advEntitledInc = 3, essEntitledInc = 4;
        var virtualAccount = urlAction.filter['virtualAccount'];
        if (urlAction.filter.deviceType == "switch") {
          family = "Switches and Hubs";
          advEntitledInc = 6;
          essEntitledInc = 7;
        } else if (urlAction.filter.deviceType == "router") {
          family = "Routers";
          advEntitledInc = 3;
          essEntitledInc = 0;
        } else if (urlAction.filter.deviceType == "wireless") {
          //Unified AP or Wireless Controller?
          family = "Unified AP";
          advEntitledInc = 5;
          essEntitledInc = 8;
        }
        var objs = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'family': family });
        essDeployed = Math.floor(objs.length * 3 / 4);
        advDeployed = objs.length - essDeployed
        advEntitled = advDeployed + advEntitledInc;
        essEntitled = essDeployed + essEntitledInc;
        var dnacVersion = (SimLokiDatabaseActions.getFilteredRecordHandler('dnacConstants', {'key':'version'}))[0].value;
        var versionCmpOutput = UtilityFunctions.versionCompare(dnacVersion, '1.3');
        if(versionCmpOutput >= 0 && isDashboardOverview) {
          var obj = {"deviceType":urlAction.filter.deviceType, "deployed":objs.length, "entitled":advEntitled+essEntitled};
          return obj;
        }

        var tJson = JSON.parse(JSON.stringify(LicenseOverviewTemplate.License_Overview_Template));
        tJson.totalDevices = objs.length;

        if(['All','EFT FIELD SEVT'].indexOf(virtualAccount) == -1){
          essDeployed = 0;
          advDeployed = 0
        }
        for (var i = 0; i < tJson.deployedDNALicenseData.length; i++) {
          if (tJson.deployedDNALicenseData[i].title == "Essentials") {
            tJson.deployedDNALicenseData[i].value = (virtualAccount == 'All' || virtualAccount == 'EFT FIELD SEVT') ? essDeployed : 0;
          } else if (tJson.deployedDNALicenseData[i].title == "Advantage") {
            tJson.deployedDNALicenseData[i].value = (virtualAccount == 'All' || virtualAccount == 'EFT FIELD SEVT') ? advDeployed : 0;
          }
        }
        for (var i = 0; i < tJson.entitledDNALicenseData.length; i++) {
          if (tJson.entitledDNALicenseData[i].title == "Essentials") {
            tJson.entitledDNALicenseData[i].value = essEntitled;
          } else if (tJson.entitledDNALicenseData[i].title == "Advantage") {
            tJson.entitledDNALicenseData[i].value = advEntitled;
          }
        }
        tJson.essentialLicense = essEntitledInc + " Essentials";
        tJson.advantageLicense = advEntitledInc + " Advantage";
        var deployedLicencesCount = (essEntitled+advEntitled)-(essDeployed+advDeployed)
        tJson.deployedLicenseDescription = `You have ${deployedLicencesCount} licenses which are not deployed`

        return tJson;
      },

      getUsageDetails: function (urlAction) {
        var seriesDevType = getDevTypeFromSeries(urlAction.url);
        var virtualAccount = urlAction.filter['virtualAccount'];//when clicking from drop down
        let idx = urlAction.service.indexOf('virtualaccount');//when selecting a series
        virtualAccount = (virtualAccount==undefined && idx>-1 && urlAction.service.length>idx) ? urlAction.service[idx+1]: virtualAccount;
        var isSpecificUsage = false, specificSeries;
        Object.keys(seriesDevType).forEach(function (key) {
          urlAction.filter.deviceType = seriesDevType[key];
          isSpecificUsage = true;
          specificSeries = key;
        });
        var objs = SimLokiDatabaseActions.getFilteredRecordHandler('licenseUsage', { 'deviceType': urlAction.filter.deviceType });
        objs = JSON.parse(JSON.stringify(objs));
        if(['EFT FIELD SEVT',"All"].indexOf(virtualAccount) == -1){
          objs = objs.map(e => {
            e.data = e.data.map(y => {
              y.dnaDeployedLicenses = 0;
              y.nwAndLegacyDeployedLicenses = 0;
              return y;
            })
            return e;
          });
        }
        var usageArray = [];
        if (objs && objs.length) {
          for (var i = 0; i < objs.length; i++) {
            usageArray = usageArray.concat(objs[i].data);
          }
          if (!isSpecificUsage) {
            return { "usageTable": usageArray, "isCSSMEnabled": true };
          } else {
            return getSpecificUsage(usageArray, specificSeries, urlAction.filter.deviceType, virtualAccount);
          }
        }
      },

      getPurchaseDetails: function (urlAction) {
        var records = SimLokiDatabaseActions.getFilteredRecordHandler('licenseUsage', { 'deviceType': urlAction.filter.deviceType });
        var virtualAccount = urlAction.filter['virtualAccount'];
        var usageData = records[0].data;
        var tJson = JSON.parse(JSON.stringify(LicensePurchaseTemplate.License_Purchase_Template));
        var tenureList = [], virAccList = [];
        if(virtualAccount == 'All' || virtualAccount == 'EFT FIELD SEVT'){
              for (var i = 0; i < usageData.length; i++) {
                  var deviceModelObj = { "id": (i + 1).toString(), "value": usageData[i].model };
                  tJson.deviceModelList.push(deviceModelObj);
                  var virtualAccountObj = { "id": (i + 1).toString(), "value": 'EFT FIELD SEVT' };
                  if(virAccList.indexOf(virtualAccountObj.value) == -1) {
                    virAccList.push(virtualAccountObj.value);
                    tJson.virtualAccounts.push(virtualAccountObj);
                  }
                  var term = UtilityFunctions.getRandomIntForRangeValues(2, 5);
                  var licenseTenureObj = { "id": (i + 1).toString(), "value": term + " year" };
                  if(tenureList.indexOf(licenseTenureObj.value) == -1) {
                    tenureList.push(licenseTenureObj.value);
                    tJson.licenseTenure.push(licenseTenureObj);
                  }
                  var timelineSegObj = JSON.parse(JSON.stringify(LicensePurchaseTemplate.SEGMENT_TEMPLATE));
                  timelineSegObj.category = usageData[i].model;
                  timelineSegObj.segments[0].model = usageData[i].model;
                  timelineSegObj.segments[0].dnacLicense = usageData[i].totalDevices;
                  timelineSegObj.segments[0].term = term + " year";
                  var stDate = UtilityFunctions.getDateInTimestamp_SubtractedByDays_FromCurrentDate(UtilityFunctions.getRandomIntForRangeValues(1, 120));
                  timelineSegObj.segments[0].start = moment(stDate).utc().format("YYYY-MM-DD");
                  var endDate = new Date(stDate).setDate(new Date(stDate).getDate() + (term * 365));
                  timelineSegObj.segments[0].end = moment(endDate).utc().format("YYYY-MM-DD");
                  timelineSegObj.segments[0].expiresIn = getExpiresIn(timelineSegObj.segments[0].end);
                  tJson.timelineData.push(timelineSegObj);
                  if (usageData[i].id == "Cisco Catalyst 9300 Series Switches") {
                    var anotherObj = JSON.parse(JSON.stringify(timelineSegObj));
                    anotherObj.segments[0].dnacLicense = usageData[i].dnaEntitlementLicenses - usageData[i].totalDevices;
                    anotherObj.segments[0].dnaLevel = "Advantage";
                    tJson.timelineData.push(anotherObj);
                  }
              }
        }
        return tJson;
      },

      getAllLicenseDetails: function (urlAction) {
        /* var records = SimLokiDatabaseActions.getAll('licenseInfo');
        var devType = urlAction.filter.model;
        if (devType != undefined) {
          records = records.filter(function (v) { return v.model == devType; });
          return records;
        } */
        if (urlAction.action.id != '') {
          var id = urlAction.action.id;
          var objs = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'id': id });
          var licenseRecord = SimLokiDatabaseActions.getFilteredRecordHandler('licenseInfo', {'id':id});
          var tJson = JSON.parse(JSON.stringify(licenseviewdetails.License_ViewAllDetails_Template));
          var site = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'id': objs[0].siteId });
          tJson.deviceName = objs[0].hostname;
          tJson.model = objs[0].series;
          tJson.deviceType = objs[0].family;
          tJson.macAddress = objs[0].macAddress;
          tJson.ipAddress = objs[0].managementIpAddress;
          tJson.udi = objs[0].platformId+":"+objs[0].serialNumber;
          tJson.location = site.length>0 ? site[0].groupNameHierarchy : "";
          tJson.chassisDetails.boardSerialNo = objs[0].serialNumber;
          tJson.licenseMode = licenseRecord[0].licenseMode;
          tJson.networkLicense = licenseRecord[0].networkLicense;
          tJson.dnaLevel = licenseRecord[0].dnaLevel;
          tJson.virtualAccount = licenseRecord[0].virtualAccount;
          tJson.registrationStatus = licenseRecord[0].registrationStatus;
          tJson.authorizationStatus = licenseRecord[0].authorizationStatus;
          tJson.reservationStatus = licenseRecord[0].reservationStatus;
          tJson.licenseTerm = licenseRecord[0].licenseTerm;
          return tJson;
        }

        var records = SimLokiDatabaseActions.getAll('licenseInfo');
        if (urlAction.filter != undefined) {
          var filterName = [], filterValue = [];
          for (var i = 0; i < urlAction.filter.length; i++) {
            var name = urlAction.filter[i];
            if (validFilters.indexOf(name) > -1) {
              filterName.push(name);
              filterValue.push(urlAction.filter[name]);
            }
          }
          records = getDetails(filterName, filterValue);
        }
        //records = getLimitDetails(records, urlAction.filter['limit'], urlAction.filter['offset']);
        return records;
      },

      getSmartDetails: function (urlAction) {
        //return JSON.parse(JSON.stringify(LicenseSettingTemplate.SMART_ACCOUNT));
        var response = {};
        if(urlAction.service.indexOf('licensemanager') > -1) {
          response = {"responseJSON": {}, "failureReason": "", "errorCode": ""};
          response.responseJSON = JSON.parse(JSON.stringify(LicenseSettingTemplate.SMART_ACCOUNT));
        }
        return response;
      },

      getDay0registrationDetails: function (urlAction) {
        //return { "default_va_account": null, "version": "1.0.0", "day0_flag_enabled": false };
        var record = SimLokiDatabaseActions.getFilteredRecordHandler('licenseVirtualAccount', { 'isSelected': true });
        var obj = { 'default_va_account': record[0].name, 'version': '1.0.0', 'day0_flag_enabled': true };
        return JSON.parse(JSON.stringify(obj));
      },

      getCountDetails: function (urlAction) {
        var tObjs = [], filterName = [], filterValue = [];
        if (urlAction.filter != undefined) {
          for (var i = 0; i < urlAction.filter.length; i++) {
            var name = urlAction.filter[i];
            if (validFilters.indexOf(name) > -1) {
              filterName.push(name);
              filterValue.push(urlAction.filter[name]);
            }
          }
          tObjs = getDetails(filterName, filterValue);
        } else {
          tObjs = getDetails(undefined, undefined);
        }
        return tObjs.length;
      },

      getAutoCompleteData: function (urlAction) {
        var filter = urlAction.filter;
        var tObjs = [];
        if (filter['autocompleteField'] == 'deviceName') {
          tObjs = getDetailsAutoComplete(filter['deviceName'], filter['autocompleteField']);
        } else {
          tObjs = getDetailsAutoComplete(filter['criteria'].toString(), filter['autocompleteField']);
        }
        return tObjs;
      },

      getCcoCred: function (urlAction) {
        var response = {};
        if(urlAction.service.indexOf('licensemanager') > -1) {
          //api/v1/licensemanager/config/credentials/cco - Settings page
          response = {"responseJSON": {}, "failureReason": "", "errorCode": ""};
          response.responseJSON = JSON.parse(JSON.stringify(LicenseSettingTemplate.CCO_CRED));
        } else {
          //api/system/v1/license/credentials/cco - SWIM page
          response = JSON.parse(JSON.stringify(LicenseSettingTemplate.SWIM_CCO));
        }
        return response;
      },

      getSmartCred: function () {
        var response = {"responseJSON": [{}], "failureReason": "", "errorCode": ""};
        response.responseJSON[0] = JSON.parse(JSON.stringify(LicenseSettingTemplate.SMART_CRED));
        return response;
      },

      getSmartAgent: function () {
        var response = {"responseJSON": {}, "failureReason": "", "errorCode": ""};
        response.responseJSON = JSON.parse(JSON.stringify(LicenseSettingTemplate.SMART_AGENT));
        return response;
      },

      getSmartAgentEntitlement: function () {
        var response = {"responseJSON": [{}], "failureReason": "", "errorCode": ""};
        response.responseJSON[0] = JSON.parse(JSON.stringify(LicenseSettingTemplate.SMART_AGENT_ENTITLEMENT));
        return response;
      },

      getSsmConnectionStatus: function() {
        var response = {"response": {"status": "READY", "message": ""}};
        return response;
      },

      getSsmConnectionMode: function(){
        var response = "direct"
        return response;
      },

      getSatelliteSetting: function() {
        var response = {"errorCode": "", "responseJSON": {}, "failureReason": "Satellite settings not found"}
        return response;
      },

      getVirtualAcc: function (urlAction) {
        var parentId = (urlAction.url.split('/'))[5], data = [];
        var records = SimLokiDatabaseActions.getAll('licenseVirtualAccount');
        //since we have only 1 parent, ignoring this check
        //var records = SimLokiDatabaseActions.getFilteredRecordHandler('licenseVirtualAccount', {'parentSmartAccount': parentId});
        if (urlAction.action.count) {
          return records.length;
        }
        for (var i = 0; i < records.length; i++) {
          var obj = JSON.parse(JSON.stringify(records[i]));
          delete obj.isSelected; delete obj.parentSmartAccount;
          data.push(obj);
        }
        return data;
      },

      registerDeregister: function (restPayload) {
        var generatedUuid = UtilityFunctions.generate_uuid();
        var progress = "", deviceIds = restPayload.deviceUUIds.split(','), resultObj;
        var records = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { id: { '$in': deviceIds} });
        var daysIncremented = 43;
        var incTimeStamp = UtilityFunctions.getDateInTimestamp_SubtractedByDays_FromCurrentDate(-daysIncremented);
        for(var i=0; i<records.length; i++) {
          if (restPayload.operation == 'register') {
            progress = JSON.parse(JSON.stringify(LicenseTaskStatusTemplate.PROCESS_REGISTER));
            progress.deviceFamily = records[i].family;
            progress.deviceIP = records[i].managementIpAddress;
            progress.deviceId = records[i].id;
            progress.deviceType = records[i].type;
            progress.virtual_account_name = restPayload.va_name;
            progress.deviceName = records[i].name;
            progress.deviceUUId = records[i].id;
            progress = JSON.stringify(progress);
          } else {
            progress = JSON.parse(JSON.stringify(LicenseTaskStatusTemplate.PROCESS_DEREGISTER));
            progress.deviceFamily = records[i].family;
            progress.deviceIP = records[i].managementIpAddress;
            progress.deviceId = records[i].id;
            progress.deviceType = records[i].type;
            progress.deviceName = records[i].name;
            progress.deviceUUId = records[i].id;
            progress = JSON.stringify(progress);
          }        

          //not sure of the working of register on multiple devices. 
          resultObj = SimTaskDataGenerator.createTask('License Manager', { progress: progress });

          //update table data
          var licenseRec = SimLokiDatabaseActions.getFilteredRecordHandler('licenseInfo', { 'id': records[i].id });
          if (restPayload.operation == 'register') {
            licenseRec[0].registrationStatus = 'Registered';
            licenseRec[0].virtualAccount = restPayload.va_name;
            licenseRec[0].authorizationStatus = 'Authorized';
            licenseRec[0].reservationStatus = 'NA';
            licenseRec[0].daysToExpiry = 'NA';
            licenseRec[0].licenseExpiry = null;
            licenseRec[0].licenseTerm = null;
          } else {
            licenseRec[0].registrationStatus = 'Unregistered';
            licenseRec[0].virtualAccount = 'NA';
            licenseRec[0].authorizationStatus = 'Evaluation Mode';
            licenseRec[0].reservationStatus = 'NA';
            licenseRec[0].daysToExpiry = daysIncremented;
            licenseRec[0].licenseExpiry = UtilityFunctions.getGivenDateAndTimeInValidFormat(incTimeStamp);
            licenseRec[0].licenseTerm = 90;
          }
          licenseRec[0].lastUpdatedTime = UtilityFunctions.getGivenDateAndTimeInValidFormat(UtilityFunctions.getTimeStamp());
          SimLokiDatabaseActions.update('licenseInfo', licenseRec);

          SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });

          //update task status
          updateTaskStatus(restPayload.operation, licenseRec[0]);
          progress = "";
        }
        return resultObj;
      },

      postDay0Registration: function (urlAction) {
        var virtualAccSel = urlAction.restPayload.default_va_account;
        var record = SimLokiDatabaseActions.getFilteredRecordHandler('licenseVirtualAccount', { 'isSelected': true });
        record[0].isSelected = false;
        SimLokiDatabaseActions.update('licenseVirtualAccount', record);

        record = SimLokiDatabaseActions.getFilteredRecordHandler('licenseVirtualAccount', { 'name': virtualAccSel });
        record[0].isSelected = true;
        SimLokiDatabaseActions.update('licenseVirtualAccount', record);
      },

      requestCode: function (urlAction) {
        var restPayload = urlAction.restPayload;
        var serviceType = (urlAction.url.split('/'))[5];
        var record = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'id': restPayload.device_uuids });

        if (serviceType == 'requestcodes') {
          var reqCode = record.length > 0 ? ('CM-Z' + record[0].platformId + ':' + record[0].serialNumber + '-AK9A6sMTr-77') : 'CM-ZC9300-48UXM:FCW2152L03D-AK9A6sMTr-77';
          var progress = '{\"deviceFamily\": \"' + record[0].family +
            '\", \"progress\": \"Request code fetch completed\", \"deviceIP\": \"' + record[0].managementIpAddress +
            '\", \"request_code\": \"' + reqCode + '\", \"licenseLevel\": \"REQUEST_CODES\", \"deviceId\": \"' +
            record[0].id + '\", \"mode\": null, \"deviceType\": \"' + record[0].type + '\", \"deviceName\": \"' +
            record[0].name + '\", \"operation\": \"REQUEST_CODES\", \"parentTask\": null, \"deviceUUId\": \"' +
            record[0].id + '\"}';
          var resultObj = SimTaskDataGenerator.createTask('License Manager Reservation', { progress: progress });
          SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });

          var obj = {
            "device_name": record[0].name,
            "request_count": "1, 1",
            "device_uuid": record[0].id,
            "request_code": reqCode,
            "isError": false,
            "device_ip": record[0].managementIpAddress,
            "errorMessage": "NA",
            "authorization_code": "NA",
            "entitlement_tags": "C9300-48 DNA Advantage, C9300-48 Network Advantage"
          }
          resultObj = SimLicenseTaskDataGenerator.createTask(false, obj);
          return { 'response': resultObj };
        }
      },

      processOtherPutRequest: function (urlAction) {
        var restPayload = urlAction.restPayload;
        var serviceType = (urlAction.url.split('/'))[5];
        var deviceIds = restPayload.device_uuids ? restPayload.device_uuids.split(','):[], resultObj;//deviceIds will not match for installlicense condition. handled seperately later.
        var record = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { id: { '$in': deviceIds} });
        var daysIncremented = 43;
        var incTimeStamp = UtilityFunctions.getDateInTimestamp_SubtractedByDays_FromCurrentDate(-daysIncremented);

        for(var i=0; i<record.length; i++) {
          /* var reqCode = record.length > 0 ? ('CM-Z' + record[i].platformId + ':' + record[i].serialNumber + '-AK9A6sMTr-77') :
            'CM-ZC9300-48UXM:FCW2152L03D-AK9A6sMTr-77'; */
          var reqCode = 'CM-Z' + record[i].platformId + ':' + record[i].serialNumber + '-AK9A6sMTr-77';
          if (serviceType == 'requestcodes') {
            resultObj = processRequestCodes(record[i], reqCode);
          } else if (serviceType == 'authorizationcodes') {
            resultObj = processAuthorizationCodes(record[i], reqCode, restPayload.virtual_account);
          } else if (serviceType == 'returnlicensereservation') {
            resultObj = processCancel(record[i], daysIncremented, incTimeStamp);
          }
        }
        if (serviceType == 'installlicense') {
          record = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'id': restPayload.reservation_map[0].deviceuuid });
          resultObj = processInstallLicense(record);
        } 
        return resultObj;
      },

      getTaskInfo: function (urlAction) {
        var records = [], taskStatus = urlAction.filter['taskstatus'];
        if (taskStatus == 'All') {
          records = SimLokiDatabaseActions.getAll('licenseTaskStatus');
        } else {
          records = SimLokiDatabaseActions.getFilteredRecordHandler('licenseTaskStatus', { 'table_store.status': taskStatus });
        }
        records = sortFormattedTime(records, 'createdDate');
        return { 'data': records, 'status': 200 };
      },

      getDashboardSummary: function(urlAction) {
        var deviceTypes = ["router", "switch", "wireless"];
        var tJson = JSON.parse(JSON.stringify(LicenseOverviewTemplate.License_Overview_Template));
        for(var i=0; i<deviceTypes.length; i++) {
          urlAction.filter['deviceType'] = deviceTypes[i];
          var detail = this.getOverviewDetails(urlAction, true);
          switch (detail.deviceType) {
            case "router":
              tJson.routerDeployedDNALicenses = detail.deployed;
              tJson.routerEntitledDNALicenses = detail.entitled;
              break;
            case "switch":
              tJson.switchDeployedDNALicenses = detail.deployed;
              tJson.switchEntitledDNALicenses = detail.entitled;
              break;
            case "wireless":
              tJson.wlcDeployedDNALicenses = detail.deployed;
              tJson.wlcEntitledDNALicenses = detail.entitled;
              break;
            default:
              break;
          }
          tJson.totalDeployedDNALicenses = tJson.routerDeployedDNALicenses + tJson.switchDeployedDNALicenses + tJson.wlcDeployedDNALicenses;
          tJson.totalEntitledDNALicenses = tJson.routerEntitledDNALicenses + tJson.switchEntitledDNALicenses + tJson.wlcEntitledDNALicenses;
        }
        return tJson;
      },

      getLicenseHistory: function(urlAction) {
        var deviceId = urlAction.filter.deviceuuid;
        var response = {"response": []}, responseCount = {"response": 0};
        var yestDate = moment().subtract(1, 'day').format('YYYY-MM-DD');
        var currYrPrevMonth = moment().subtract(1, 'month').format('YYYY-MM');
        var currYrCurrMonth = moment().format('YYYY-MM');
        var deviceData = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'id':deviceId});
        var licenseRecord = SimLokiDatabaseActions.getFilteredRecordHandler('licenseInfo', {'id':deviceId});
        var pid = deviceData.length>0?deviceData[0].platformId:'PID';
        var tJson = JSON.stringify(licenseviewdetails.LICENSE_HISTORY_TEMPLATE);
        tJson = tJson.replace(/YEST_DATE/g,yestDate);
        tJson = tJson.replace(/PID_PART/g,(pid.split('-')[0]).split('/')[0]);
        tJson = tJson.replace(/PID/g,pid);
        tJson = tJson.replace(/CURR_YR_PREV_MON/g,currYrPrevMonth);
        tJson = tJson.replace(/CURR_YR_CURR_MON/g,currYrCurrMonth);
        if(licenseRecord[0].dnaLevel.toLowerCase() != 'advantage') {
          tJson = tJson.replace(/advantage/g,licenseRecord[0].dnaLevel.toLowerCase());
        }
        tJson = JSON.parse(tJson);
        if (urlAction.service.indexOf('devicelicenselogscount') > -1) {
          responseCount.response = tJson.length;
          return responseCount;
        }
        response.response = tJson;
        return response;
      },

      getLicensePurchaseInfo:function(urlAction) {
        let licenceData = SimLokiDatabaseActions.getAll('licenseInfo')
        let tJson = JSON.parse(JSON.stringify(AllLicenseTemplate.AllLicence_PurchaseTemplate))
        return tJson
      },

      getLicenseViewInfo:function(urlAction) {
        let tJson = JSON.parse(JSON.stringify(AllLicenseTemplate.AllLicence_LicenceViewTemplate))
        let count = tJson.response.length;
        if(urlAction.action.count){
           return {"version": "1.0.0", "response": count}
        }
        return tJson
      },

      getLicenceUsagestats:function(urlAction) {
        let tJson = JSON.parse(JSON.stringify(AllLicenseTemplate.AllLicence_Usage_stats))
        tJson.lastUpdateTime = UtilityFunctions.getCurrentDateAndTimeInValidFormat()
        return tJson
      },

      getLicenceNotifications:function(urlAction){
        let tJson = JSON.parse(JSON.stringify(Licensenotificationdetails.LicenseNotificationsData))
        for(var i=0; i<tJson.length; i++) {
          tJson[i].notificationCreationTime = UtilityFunctions.getGivenDateAndTimeInValidFormat(UtilityFunctions.getTimeStamp());
        }
        return tJson
      }
    };
  });
