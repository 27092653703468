define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/provision/verify/VerifyTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/VerifyConfig',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
], function(UtilityFunctions, SimLokiDatabaseActions, VerifyTemplate,VerifyConfig,SimTaskDataGenerator){

    function createValidationOptions() {

       var tJsonArr = JSON.parse(JSON.stringify( VerifyConfig.VERIFY_OPTIONS_DATA ));
      SimLokiDatabaseActions.insert( 'validation-options', tJsonArr );

    }

    function createValidationBaseConfig() {

       var tJsonArr = JSON.parse(JSON.stringify( VerifyConfig.VERIFY_BASE_CONFIG_DATA ));
      SimLokiDatabaseActions.insert( 'validation-base-config', tJsonArr );

    }

    function createValidationBaseOperations() {

      var tJsonArr = JSON.parse(JSON.stringify( VerifyConfig.VERIFY_BASE_OPERATION_DATA ));
      SimLokiDatabaseActions.insert( 'validation-base-operation', tJsonArr );

    }
    function getResultObject(resultList,validatorUuid) {
      var resultObject;
      for(var i=0;i<resultList.length;i++) {
         if(resultList[i].validatorUuid==validatorUuid) {
            resultObject= resultList[i];
         }

      }

    return resultObject
    }
  return {
      init: function(){
        createValidationOptions();
        createValidationBaseConfig();
        createValidationBaseOperations();
      },

      verifyProcess :function(urlAction) {
         var resultDetail= JSON.parse(JSON.stringify(VerifyConfig.VERIFY_RESULT_DATA));
         var resultObj = SimTaskDataGenerator.createTask('SPFService');
         var restPayload = urlAction.restPayload;
         var validationBindingUuids = restPayload.validationBindingUuids;
         var deviceUuids = restPayload.deviceUuids;
         var operationName =restPayload.operationName;
         var parentTaskUuid = resultObj.taskId;
         var i , j , K;

         /* if(urlAction.action.id=="30529838-bdeb-45b6-a6c0-84bb6ce21853") {
           var href =window.location.href;
           var domainId = href.substr(href.lastIndexOf("/")+1);
           var connectivityDomain = SimLokiDatabaseActions.getFilteredRecordHandler("ConnectivityDomain", {'id' : domainId});

           var cdDeviceInfo =connectivityDomain[0].deviceInfo;
                deviceUuids=[];
               for(k=0; k<cdDeviceInfo.length;k++)  {
                   var deviceDetails =SimLokiDatabaseActions.getFilteredRecordHandler("DeviceInfo", {'id' : cdDeviceInfo[k].idRef});
                   if(deviceDetails[0].roles.indexOf("EDGENODE")>=0) {

                      deviceUuids.push(deviceDetails[0].networkDeviceId);
                   }

               }
          } */


         for(i=0;i<deviceUuids.length;i++) {
            var deviceID = deviceUuids[i];
               for(j=0;j<validationBindingUuids.length;j++) {
                 var validationBindingUuid = validationBindingUuids[j];

                 var valiationBindatingData = SimLokiDatabaseActions.getFilteredRecordHandler("validation-options", {'bindingUuid' : validationBindingUuid});
                 var resultObject =getResultObject(resultDetail,valiationBindatingData[0].validatorUuid);
                 var time =UtilityFunctions.getTimeInVerifyFormat();
                 var tJsonArr = JSON.parse(JSON.stringify( VerifyTemplate.Verify_Template ));
                     tJsonArr.resultTaskUuid= UtilityFunctions.generate_uuid();
                     tJsonArr.parentTaskUuid= parentTaskUuid;
                     tJsonArr.validationBindingUuid =valiationBindatingData[0].bindingUuid;
                     tJsonArr.validatorUuid = valiationBindatingData[0].validatorUuid;
                     tJsonArr.validatorName =valiationBindatingData[0].validatorName;
                     tJsonArr.operationName=operationName;
                     tJsonArr.deviceUuid=deviceID;
                     tJsonArr.resultDetail =resultObject.resultDetail;
                     tJsonArr.resultStatus =resultObject.resultStatus;
                     tJsonArr.createdTime=time;
                     tJsonArr.updatedTime=time;


                 SimLokiDatabaseActions.insert('validation-result', tJsonArr);
                 SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
               }
         }
         return resultObj;
      }
  };

});
