define({

    "getEventIdTemplate":
    [{
              "version": null,
              "eventId": "NETWORK-CLIENTS-4-370",
              "namespace": "ASSURANCE",
              "name": "Clients detected connecting on network",
              "description": "Wireless or Wired Clients have been detected connecting to the network the first time or every time depending on configuration chosen",
              "category": "INFO",
              "domain": "Know Your Network",
              "subDomain": "Clients",
              "type": "NETWORK",
              "source": "",
              "severity": 4,
              "details": {
                    "Type": "$eventSource$",
                    "Assurance Issue Details": "Client $mac$ has connected to Device $name$ at time $timestamp$ in location $location$",
                    "Assurance Issue Name": "Client $mac$ has connected to Device $name$"
              },
              "additionalDetails": {},
              "subscriptionTypes": ["WEBEX"],
              "tags": ["ASSURANCE", "client_tracking"],
              "tenantId": "SYS0"
        }]
})