define({
    "WIFI6_LATENCY_BG": {
        "records": [
            {
                "modificationtime": 1588617300000,
                "axLatency": 2165471,
                "nonAxLatency": 19550334,
                "timestamp": "2020-05-04T18:35:00.000+0000"
            },
            {
                "modificationtime": 1588617600000,
                "axLatency": 2173519,
                "nonAxLatency": 13686379,
                "timestamp": "2020-05-04T18:40:00.000+0000"
            },
            {
                "modificationtime": 1588617900000,
                "axLatency": 1981014,
                "nonAxLatency": 40662127,
                "timestamp": "2020-05-04T18:45:00.000+0000"
            },
            {
                "modificationtime": 1588618200000,
                "axLatency": 1576295,
                "nonAxLatency": 6202931,
                "timestamp": "2020-05-04T18:50:00.000+0000"
            },
            {
                "modificationtime": 1588618500000,
                "axLatency": 1036999,
                "nonAxLatency": 8482730,
                "timestamp": "2020-05-04T18:55:00.000+0000"
            },
            {
                "modificationtime": 1588618800000,
                "axLatency": 2051714,
                "nonAxLatency": 14994409,
                "timestamp": "2020-05-04T19:00:00.000+0000"
            },
            {
                "modificationtime": 1588619100000,
                "axLatency": 6325436,
                "nonAxLatency": 20739501,
                "timestamp": "2020-05-04T19:05:00.000+0000"
            },
            {
                "modificationtime": 1588619400000,
                "axLatency": 1379724,
                "nonAxLatency": 20640764,
                "timestamp": "2020-05-04T19:10:00.000+0000"
            },
            {
                "modificationtime": 1588619700000,
                "axLatency": 2512197,
                "nonAxLatency": 33991146,
                "timestamp": "2020-05-04T19:15:00.000+0000"
            },
            {
                "modificationtime": 1588620000000,
                "axLatency": 8484710,
                "nonAxLatency": 26073909,
                "timestamp": "2020-05-04T19:20:00.000+0000"
            },
            {
                "modificationtime": 1588620300000,
                "axLatency": 3018949,
                "nonAxLatency": 9601025,
                "timestamp": "2020-05-04T19:25:00.000+0000"
            },
            {
                "modificationtime": 1588620600000,
                "axLatency": 614994,
                "nonAxLatency": 1725132,
                "timestamp": "2020-05-04T19:30:00.000+0000"
            },
            {
                "modificationtime": 1588620900000,
                "axLatency": 1634098,
                "nonAxLatency": 17423805,
                "timestamp": "2020-05-04T19:35:00.000+0000"
            },
            {
                "modificationtime": 1588621200000,
                "axLatency": 2635052,
                "nonAxLatency": 28900840,
                "timestamp": "2020-05-04T19:40:00.000+0000"
            },
            {
                "modificationtime": 1588621500000,
                "axLatency": 2856666,
                "nonAxLatency": 4878408,
                "timestamp": "2020-05-04T19:45:00.000+0000"
            },
            {
                "modificationtime": 1588621800000,
                "axLatency": 3107749,
                "nonAxLatency": 10597544,
                "timestamp": "2020-05-04T19:50:00.000+0000"
            },
            {
                "modificationtime": 1588622100000,
                "axLatency": 4951021,
                "nonAxLatency": 11187691,
                "timestamp": "2020-05-04T19:55:00.000+0000"
            },
            {
                "modificationtime": 1588622400000,
                "axLatency": 2079019,
                "nonAxLatency": 24622393,
                "timestamp": "2020-05-04T20:00:00.000+0000"
            },
            {
                "modificationtime": 1588622700000,
                "axLatency": 0,
                "nonAxLatency": 2652068,
                "timestamp": "2020-05-04T20:05:00.000+0000"
            },
            {
                "modificationtime": 1588623000000,
                "axLatency": 0,
                "nonAxLatency": 2063591,
                "timestamp": "2020-05-04T20:10:00.000+0000"
            },
            {
                "modificationtime": 1588623300000,
                "axLatency": 3782910,
                "nonAxLatency": 23764464,
                "timestamp": "2020-05-04T20:15:00.000+0000"
            },
            {
                "modificationtime": 1588623600000,
                "axLatency": 2630790,
                "nonAxLatency": 6923651,
                "timestamp": "2020-05-04T20:20:00.000+0000"
            },
            {
                "modificationtime": 1588623900000,
                "axLatency": 3727138,
                "nonAxLatency": 0,
                "timestamp": "2020-05-04T20:25:00.000+0000"
            },
            {
                "modificationtime": 1588624200000,
                "axLatency": 55732094,
                "nonAxLatency": 0,
                "timestamp": "2020-05-04T20:30:00.000+0000"
            },
            {
                "modificationtime": 1588624500000,
                "axLatency": 3358649,
                "nonAxLatency": 3751938,
                "timestamp": "2020-05-04T20:35:00.000+0000"
            },
            {
                "modificationtime": 1588624800000,
                "axLatency": 1832121,
                "nonAxLatency": 4636390,
                "timestamp": "2020-05-04T20:40:00.000+0000"
            },
            {
                "modificationtime": 1588625100000,
                "axLatency": 2033365,
                "nonAxLatency": 15715387,
                "timestamp": "2020-05-04T20:45:00.000+0000"
            },
            {
                "modificationtime": 1588625400000,
                "axLatency": 2920612,
                "nonAxLatency": 22423466,
                "timestamp": "2020-05-04T20:50:00.000+0000"
            },
            {
                "modificationtime": 1588625700000,
                "axLatency": 3075542,
                "nonAxLatency": 4778051,
                "timestamp": "2020-05-04T20:55:00.000+0000"
            },
            {
                "modificationtime": 1588626000000,
                "axLatency": 3731413,
                "nonAxLatency": 3480621,
                "timestamp": "2020-05-04T21:00:00.000+0000"
            },
            {
                "modificationtime": 1588626300000,
                "axLatency": 6777873,
                "nonAxLatency": 14143560,
                "timestamp": "2020-05-04T21:05:00.000+0000"
            },
            {
                "modificationtime": 1588626600000,
                "axLatency": 5922582,
                "nonAxLatency": 76006,
                "timestamp": "2020-05-04T21:10:00.000+0000"
            },
            {
                "modificationtime": 1588626900000,
                "axLatency": 2015550,
                "nonAxLatency": 8761310,
                "timestamp": "2020-05-04T21:15:00.000+0000"
            },
            {
                "modificationtime": 1588627200000,
                "axLatency": 1898999,
                "nonAxLatency": 9369045,
                "timestamp": "2020-05-04T21:20:00.000+0000"
            },
            {
                "modificationtime": 1588627500000,
                "axLatency": 2458394,
                "nonAxLatency": 22867535,
                "timestamp": "2020-05-04T21:25:00.000+0000"
            },
            {
                "modificationtime": 1588627800000,
                "axLatency": 6722828,
                "nonAxLatency": 56898012,
                "timestamp": "2020-05-04T21:30:00.000+0000"
            },
            {
                "modificationtime": 1588628100000,
                "axLatency": 3966268,
                "nonAxLatency": 3927116,
                "timestamp": "2020-05-04T21:35:00.000+0000"
            },
            {
                "modificationtime": 1588628400000,
                "axLatency": 1351658,
                "nonAxLatency": 4505237,
                "timestamp": "2020-05-04T21:40:00.000+0000"
            },
            {
                "modificationtime": 1588628700000,
                "axLatency": 985487,
                "nonAxLatency": 2592783,
                "timestamp": "2020-05-04T21:45:00.000+0000"
            },
            {
                "modificationtime": 1588629000000,
                "axLatency": 1347935,
                "nonAxLatency": 67689033,
                "timestamp": "2020-05-04T21:50:00.000+0000"
            },
            {
                "modificationtime": 1588629300000,
                "axLatency": 1311073,
                "nonAxLatency": 20043369,
                "timestamp": "2020-05-04T21:55:00.000+0000"
            },
            {
                "modificationtime": 1588629600000,
                "axLatency": 3593160,
                "nonAxLatency": 4955239,
                "timestamp": "2020-05-04T22:00:00.000+0000"
            },
            {
                "modificationtime": 1588629900000,
                "axLatency": 675820,
                "nonAxLatency": 10314872,
                "timestamp": "2020-05-04T22:05:00.000+0000"
            },
            {
                "modificationtime": 1588630200000,
                "axLatency": 5277333,
                "nonAxLatency": 12186693,
                "timestamp": "2020-05-04T22:10:00.000+0000"
            },
            {
                "modificationtime": 1588630500000,
                "axLatency": 2113706,
                "nonAxLatency": 7950470,
                "timestamp": "2020-05-04T22:15:00.000+0000"
            },
            {
                "modificationtime": 1588630800000,
                "axLatency": 2159707,
                "nonAxLatency": 9341691,
                "timestamp": "2020-05-04T22:20:00.000+0000"
            },
            {
                "modificationtime": 1588631100000,
                "axLatency": 1471299,
                "nonAxLatency": 10331487,
                "timestamp": "2020-05-04T22:25:00.000+0000"
            },
            {
                "modificationtime": 1588631400000,
                "axLatency": 1951999,
                "nonAxLatency": 17877839,
                "timestamp": "2020-05-04T22:30:00.000+0000"
            },
            {
                "modificationtime": 1588631700000,
                "axLatency": 1704681,
                "nonAxLatency": 6654784,
                "timestamp": "2020-05-04T22:35:00.000+0000"
            },
            {
                "modificationtime": 1588632000000,
                "axLatency": 6618304,
                "nonAxLatency": 8583193,
                "timestamp": "2020-05-04T22:40:00.000+0000"
            },
            {
                "modificationtime": 1588632300000,
                "axLatency": 1411702,
                "nonAxLatency": 10721714,
                "timestamp": "2020-05-04T22:45:00.000+0000"
            },
            {
                "modificationtime": 1588632600000,
                "axLatency": 4187413,
                "nonAxLatency": 8054478,
                "timestamp": "2020-05-04T22:50:00.000+0000"
            },
            {
                "modificationtime": 1588632900000,
                "axLatency": 1900278,
                "nonAxLatency": 12321450,
                "timestamp": "2020-05-04T22:55:00.000+0000"
            },
            {
                "modificationtime": 1588633200000,
                "axLatency": 3268076,
                "nonAxLatency": 36089158,
                "timestamp": "2020-05-04T23:00:00.000+0000"
            },
            {
                "modificationtime": 1588633500000,
                "axLatency": 6961649,
                "nonAxLatency": 12612701,
                "timestamp": "2020-05-04T23:05:00.000+0000"
            },
            {
                "modificationtime": 1588633800000,
                "axLatency": 4137575,
                "nonAxLatency": 0,
                "timestamp": "2020-05-04T23:10:00.000+0000"
            },
            {
                "modificationtime": 1588634100000,
                "axLatency": 3553611,
                "nonAxLatency": 9515087,
                "timestamp": "2020-05-04T23:15:00.000+0000"
            },
            {
                "modificationtime": 1588634400000,
                "axLatency": 1720654,
                "nonAxLatency": 4474609,
                "timestamp": "2020-05-04T23:20:00.000+0000"
            },
            {
                "modificationtime": 1588634700000,
                "axLatency": 2709244,
                "nonAxLatency": 8741330,
                "timestamp": "2020-05-04T23:25:00.000+0000"
            },
            {
                "modificationtime": 1588635000000,
                "axLatency": 1731307,
                "nonAxLatency": 27176611,
                "timestamp": "2020-05-04T23:30:00.000+0000"
            },
            {
                "modificationtime": 1588635300000,
                "axLatency": 2370191,
                "nonAxLatency": 47991300,
                "timestamp": "2020-05-04T23:35:00.000+0000"
            },
            {
                "modificationtime": 1588635600000,
                "axLatency": 1540780,
                "nonAxLatency": 6009264,
                "timestamp": "2020-05-04T23:40:00.000+0000"
            },
            {
                "modificationtime": 1588635900000,
                "axLatency": 1863983,
                "nonAxLatency": 52229745,
                "timestamp": "2020-05-04T23:45:00.000+0000"
            },
            {
                "modificationtime": 1588636200000,
                "axLatency": 1255020,
                "nonAxLatency": 32625976,
                "timestamp": "2020-05-04T23:50:00.000+0000"
            },
            {
                "modificationtime": 1588636500000,
                "axLatency": 2269409,
                "nonAxLatency": 17286550,
                "timestamp": "2020-05-04T23:55:00.000+0000"
            },
            {
                "modificationtime": 1588636800000,
                "axLatency": 3074445,
                "nonAxLatency": 13338955,
                "timestamp": "2020-05-05T00:00:00.000+0000"
            },
            {
                "modificationtime": 1588637100000,
                "axLatency": 2205333,
                "nonAxLatency": 10516051,
                "timestamp": "2020-05-05T00:05:00.000+0000"
            },
            {
                "modificationtime": 1588637400000,
                "axLatency": 1256343,
                "nonAxLatency": 44994297,
                "timestamp": "2020-05-05T00:10:00.000+0000"
            },
            {
                "modificationtime": 1588637700000,
                "axLatency": 1982245,
                "nonAxLatency": 10431206,
                "timestamp": "2020-05-05T00:15:00.000+0000"
            },
            {
                "modificationtime": 1588638000000,
                "axLatency": 3709890,
                "nonAxLatency": 0,
                "timestamp": "2020-05-05T00:20:00.000+0000"
            },
            {
                "modificationtime": 1588638300000,
                "axLatency": 2899714,
                "nonAxLatency": 0,
                "timestamp": "2020-05-05T00:25:00.000+0000"
            },
            {
                "modificationtime": 1588638600000,
                "axLatency": 2774975,
                "nonAxLatency": 0,
                "timestamp": "2020-05-05T00:30:00.000+0000"
            },
            {
                "modificationtime": 1588638900000,
                "axLatency": 2254486,
                "nonAxLatency": 0,
                "timestamp": "2020-05-05T00:35:00.000+0000"
            },
            {
                "modificationtime": 1588639200000,
                "axLatency": 1557761,
                "nonAxLatency": 0,
                "timestamp": "2020-05-05T00:40:00.000+0000"
            },
            {
                "modificationtime": 1588639500000,
                "axLatency": 4973190,
                "nonAxLatency": 0,
                "timestamp": "2020-05-05T00:45:00.000+0000"
            },
            {
                "modificationtime": 1588639800000,
                "axLatency": 1809674,
                "nonAxLatency": 24590007,
                "timestamp": "2020-05-05T00:50:00.000+0000"
            },
            {
                "modificationtime": 1588640100000,
                "axLatency": 1591413,
                "nonAxLatency": 7979852,
                "timestamp": "2020-05-05T00:55:00.000+0000"
            },
            {
                "modificationtime": 1588640400000,
                "axLatency": 4195586,
                "nonAxLatency": 29555015,
                "timestamp": "2020-05-05T01:00:00.000+0000"
            },
            {
                "modificationtime": 1588640700000,
                "axLatency": 1899831,
                "nonAxLatency": 23455980,
                "timestamp": "2020-05-05T01:05:00.000+0000"
            },
            {
                "modificationtime": 1588641000000,
                "axLatency": 1079640,
                "nonAxLatency": 19367988,
                "timestamp": "2020-05-05T01:10:00.000+0000"
            },
            {
                "modificationtime": 1588641300000,
                "axLatency": 6591504,
                "nonAxLatency": 16432626,
                "timestamp": "2020-05-05T01:15:00.000+0000"
            },
            {
                "modificationtime": 1588641600000,
                "axLatency": 3452611,
                "nonAxLatency": 24195209,
                "timestamp": "2020-05-05T01:20:00.000+0000"
            },
            {
                "modificationtime": 1588641900000,
                "axLatency": 2281280,
                "nonAxLatency": 6429672,
                "timestamp": "2020-05-05T01:25:00.000+0000"
            },
            {
                "modificationtime": 1588642200000,
                "axLatency": 2711479,
                "nonAxLatency": 5425008,
                "timestamp": "2020-05-05T01:30:00.000+0000"
            },
            {
                "modificationtime": 1588642500000,
                "axLatency": 3921593,
                "nonAxLatency": 20020577,
                "timestamp": "2020-05-05T01:35:00.000+0000"
            },
            {
                "modificationtime": 1588642800000,
                "axLatency": 2227152,
                "nonAxLatency": 1449538,
                "timestamp": "2020-05-05T01:40:00.000+0000"
            },
            {
                "modificationtime": 1588643100000,
                "axLatency": 2348120,
                "nonAxLatency": 23022481,
                "timestamp": "2020-05-05T01:45:00.000+0000"
            },
            {
                "modificationtime": 1588643400000,
                "axLatency": 1463681,
                "nonAxLatency": 28430787,
                "timestamp": "2020-05-05T01:50:00.000+0000"
            },
            {
                "modificationtime": 1588643700000,
                "axLatency": 1445698,
                "nonAxLatency": 12727743,
                "timestamp": "2020-05-05T01:55:00.000+0000"
            },
            {
                "modificationtime": 1588644000000,
                "axLatency": 4955345,
                "nonAxLatency": 2966566,
                "timestamp": "2020-05-05T02:00:00.000+0000"
            },
            {
                "modificationtime": 1588644300000,
                "axLatency": 2225425,
                "nonAxLatency": 28413673,
                "timestamp": "2020-05-05T02:05:00.000+0000"
            },
            {
                "modificationtime": 1588644600000,
                "axLatency": 2709176,
                "nonAxLatency": 8305211,
                "timestamp": "2020-05-05T02:10:00.000+0000"
            },
            {
                "modificationtime": 1588644900000,
                "axLatency": 2920575,
                "nonAxLatency": 26951005,
                "timestamp": "2020-05-05T02:15:00.000+0000"
            },
            {
                "modificationtime": 1588645200000,
                "axLatency": 2197240,
                "nonAxLatency": 0,
                "timestamp": "2020-05-05T02:20:00.000+0000"
            },
            {
                "modificationtime": 1588645500000,
                "axLatency": 5295379,
                "nonAxLatency": 2973328,
                "timestamp": "2020-05-05T02:25:00.000+0000"
            },
            {
                "modificationtime": 1588645800000,
                "axLatency": 1966329,
                "nonAxLatency": 17052046,
                "timestamp": "2020-05-05T02:30:00.000+0000"
            },
            {
                "modificationtime": 1588646100000,
                "axLatency": 4559984,
                "nonAxLatency": 5210796,
                "timestamp": "2020-05-05T02:35:00.000+0000"
            },
            {
                "modificationtime": 1588646400000,
                "axLatency": 1497168,
                "nonAxLatency": 17719447,
                "timestamp": "2020-05-05T02:40:00.000+0000"
            },
            {
                "modificationtime": 1588646700000,
                "axLatency": 2851802,
                "nonAxLatency": 9027392,
                "timestamp": "2020-05-05T02:45:00.000+0000"
            },
            {
                "modificationtime": 1588647000000,
                "axLatency": 3216937,
                "nonAxLatency": 19025700,
                "timestamp": "2020-05-05T02:50:00.000+0000"
            },
            {
                "modificationtime": 1588647300000,
                "axLatency": 1764211,
                "nonAxLatency": 6310990,
                "timestamp": "2020-05-05T02:55:00.000+0000"
            },
            {
                "modificationtime": 1588647600000,
                "axLatency": 4871109,
                "nonAxLatency": 10034596,
                "timestamp": "2020-05-05T03:00:00.000+0000"
            },
            {
                "modificationtime": 1588647900000,
                "axLatency": 4467146,
                "nonAxLatency": 4345913,
                "timestamp": "2020-05-05T03:05:00.000+0000"
            },
            {
                "modificationtime": 1588648200000,
                "axLatency": 2422628,
                "nonAxLatency": 11611375,
                "timestamp": "2020-05-05T03:10:00.000+0000"
            },
            {
                "modificationtime": 1588648500000,
                "axLatency": 2487428,
                "nonAxLatency": 26166142,
                "timestamp": "2020-05-05T03:15:00.000+0000"
            },
            {
                "modificationtime": 1588648800000,
                "axLatency": 1850424,
                "nonAxLatency": 5536057,
                "timestamp": "2020-05-05T03:20:00.000+0000"
            },
            {
                "modificationtime": 1588649100000,
                "axLatency": 3404467,
                "nonAxLatency": 4932361,
                "timestamp": "2020-05-05T03:25:00.000+0000"
            },
            {
                "modificationtime": 1588649400000,
                "axLatency": 1945847,
                "nonAxLatency": 7347587,
                "timestamp": "2020-05-05T03:30:00.000+0000"
            },
            {
                "modificationtime": 1588649700000,
                "axLatency": 2304122,
                "nonAxLatency": 28391550,
                "timestamp": "2020-05-05T03:35:00.000+0000"
            },
            {
                "modificationtime": 1588650000000,
                "axLatency": 2432721,
                "nonAxLatency": 8646258,
                "timestamp": "2020-05-05T03:40:00.000+0000"
            },
            {
                "modificationtime": 1588650300000,
                "axLatency": 1288857,
                "nonAxLatency": 11005113,
                "timestamp": "2020-05-05T03:45:00.000+0000"
            },
            {
                "modificationtime": 1588650600000,
                "axLatency": 2980949,
                "nonAxLatency": 18924513,
                "timestamp": "2020-05-05T03:50:00.000+0000"
            },
            {
                "modificationtime": 1588650900000,
                "axLatency": 5201455,
                "nonAxLatency": 5615067,
                "timestamp": "2020-05-05T03:55:00.000+0000"
            },
            {
                "modificationtime": 1588651200000,
                "axLatency": 2225149,
                "nonAxLatency": 99544657,
                "timestamp": "2020-05-05T04:00:00.000+0000"
            },
            {
                "modificationtime": 1588651500000,
                "axLatency": 1453126,
                "nonAxLatency": 5182449,
                "timestamp": "2020-05-05T04:05:00.000+0000"
            },
            {
                "modificationtime": 1588651800000,
                "axLatency": 3283863,
                "nonAxLatency": 60133725,
                "timestamp": "2020-05-05T04:10:00.000+0000"
            },
            {
                "modificationtime": 1588652100000,
                "axLatency": 3277719,
                "nonAxLatency": 10269324,
                "timestamp": "2020-05-05T04:15:00.000+0000"
            },
            {
                "modificationtime": 1588652400000,
                "axLatency": 1774373,
                "nonAxLatency": 41362648,
                "timestamp": "2020-05-05T04:20:00.000+0000"
            },
            {
                "modificationtime": 1588652700000,
                "axLatency": 1253886,
                "nonAxLatency": 13990925,
                "timestamp": "2020-05-05T04:25:00.000+0000"
            },
            {
                "modificationtime": 1588653000000,
                "axLatency": 1727766,
                "nonAxLatency": 5933587,
                "timestamp": "2020-05-05T04:30:00.000+0000"
            },
            {
                "modificationtime": 1588653300000,
                "axLatency": 3566070,
                "nonAxLatency": 15652164,
                "timestamp": "2020-05-05T04:35:00.000+0000"
            },
            {
                "modificationtime": 1588653600000,
                "axLatency": 2473683,
                "nonAxLatency": 15652164,
                "timestamp": "2020-05-05T04:40:00.000+0000"
            },
            {
                "modificationtime": 1588653900000,
                "axLatency": 3353092,
                "nonAxLatency": 0,
                "timestamp": "2020-05-05T04:45:00.000+0000"
            },
            {
                "modificationtime": 1588654200000,
                "axLatency": 1487157,
                "nonAxLatency": 0,
                "timestamp": "2020-05-05T04:50:00.000+0000"
            },
            {
                "modificationtime": 1588654500000,
                "axLatency": 604607,
                "nonAxLatency": 0,
                "timestamp": "2020-05-05T04:55:00.000+0000"
            },
            {
                "modificationtime": 1588654800000,
                "axLatency": 10587362,
                "nonAxLatency": 0,
                "timestamp": "2020-05-05T05:00:00.000+0000"
            },
            {
                "modificationtime": 1588655100000,
                "axLatency": 7882774,
                "nonAxLatency": 0,
                "timestamp": "2020-05-05T05:05:00.000+0000"
            },
            {
                "modificationtime": 1588655400000,
                "axLatency": 20590252,
                "nonAxLatency": 0,
                "timestamp": "2020-05-05T05:10:00.000+0000"
            },
            {
                "modificationtime": 1588655700000,
                "axLatency": 7855095,
                "nonAxLatency": 8361422,
                "timestamp": "2020-05-05T05:15:00.000+0000"
            },
            {
                "modificationtime": 1588656000000,
                "axLatency": 1764620,
                "nonAxLatency": 12050209,
                "timestamp": "2020-05-05T05:20:00.000+0000"
            },
            {
                "modificationtime": 1588656300000,
                "axLatency": 1302715,
                "nonAxLatency": 15040262,
                "timestamp": "2020-05-05T05:25:00.000+0000"
            },
            {
                "modificationtime": 1588656600000,
                "axLatency": 2842695,
                "nonAxLatency": 6669503,
                "timestamp": "2020-05-05T05:30:00.000+0000"
            },
            {
                "modificationtime": 1588656900000,
                "axLatency": 1752137,
                "nonAxLatency": 17905514,
                "timestamp": "2020-05-05T05:35:00.000+0000"
            },
            {
                "modificationtime": 1588657200000,
                "axLatency": 2169933,
                "nonAxLatency": 12085318,
                "timestamp": "2020-05-05T05:40:00.000+0000"
            },
            {
                "modificationtime": 1588657500000,
                "axLatency": 3393829,
                "nonAxLatency": 3254377,
                "timestamp": "2020-05-05T05:45:00.000+0000"
            },
            {
                "modificationtime": 1588657800000,
                "axLatency": 2161901,
                "nonAxLatency": 28989717,
                "timestamp": "2020-05-05T05:50:00.000+0000"
            },
            {
                "modificationtime": 1588658100000,
                "axLatency": 1946406,
                "nonAxLatency": 20156423,
                "timestamp": "2020-05-05T05:55:00.000+0000"
            },
            {
                "modificationtime": 1588658400000,
                "axLatency": 1756893,
                "nonAxLatency": 12337977,
                "timestamp": "2020-05-05T06:00:00.000+0000"
            },
            {
                "modificationtime": 1588658700000,
                "axLatency": 1676459,
                "nonAxLatency": 27226438,
                "timestamp": "2020-05-05T06:05:00.000+0000"
            },
            {
                "modificationtime": 1588659000000,
                "axLatency": 3127962,
                "nonAxLatency": 10091199,
                "timestamp": "2020-05-05T06:10:00.000+0000"
            },
            {
                "modificationtime": 1588659300000,
                "axLatency": 5265605,
                "nonAxLatency": 6446366,
                "timestamp": "2020-05-05T06:15:00.000+0000"
            },
            {
                "modificationtime": 1588659600000,
                "axLatency": 2079177,
                "nonAxLatency": 30039116,
                "timestamp": "2020-05-05T06:20:00.000+0000"
            },
            {
                "modificationtime": 1588659900000,
                "axLatency": 4909428,
                "nonAxLatency": 11529662,
                "timestamp": "2020-05-05T06:25:00.000+0000"
            },
            {
                "modificationtime": 1588660200000,
                "axLatency": 2847057,
                "nonAxLatency": 32277456,
                "timestamp": "2020-05-05T06:30:00.000+0000"
            },
            {
                "modificationtime": 1588660500000,
                "axLatency": 3269704,
                "nonAxLatency": 34073369,
                "timestamp": "2020-05-05T06:35:00.000+0000"
            },
            {
                "modificationtime": 1588660800000,
                "axLatency": 2430931,
                "nonAxLatency": 76,
                "timestamp": "2020-05-05T06:40:00.000+0000"
            },
            {
                "modificationtime": 1588661100000,
                "axLatency": 3922271,
                "nonAxLatency": 4506696,
                "timestamp": "2020-05-05T06:45:00.000+0000"
            },
            {
                "modificationtime": 1588661400000,
                "axLatency": 6254946,
                "nonAxLatency": 5048557,
                "timestamp": "2020-05-05T06:50:00.000+0000"
            },
            {
                "modificationtime": 1588661700000,
                "axLatency": 5281885,
                "nonAxLatency": 4046691,
                "timestamp": "2020-05-05T06:55:00.000+0000"
            },
            {
                "modificationtime": 1588662000000,
                "axLatency": 1407956,
                "nonAxLatency": 22926585,
                "timestamp": "2020-05-05T07:00:00.000+0000"
            },
            {
                "modificationtime": 1588662300000,
                "axLatency": 5716859,
                "nonAxLatency": 22604321,
                "timestamp": "2020-05-05T07:05:00.000+0000"
            },
            {
                "modificationtime": 1588662600000,
                "axLatency": 2701380,
                "nonAxLatency": 42619221,
                "timestamp": "2020-05-05T07:10:00.000+0000"
            },
            {
                "modificationtime": 1588662900000,
                "axLatency": 3047581,
                "nonAxLatency": 27788631,
                "timestamp": "2020-05-05T07:15:00.000+0000"
            },
            {
                "modificationtime": 1588663200000,
                "axLatency": 1901987,
                "nonAxLatency": 23263895,
                "timestamp": "2020-05-05T07:20:00.000+0000"
            },
            {
                "modificationtime": 1588663500000,
                "axLatency": 2785397,
                "nonAxLatency": 22786310,
                "timestamp": "2020-05-05T07:25:00.000+0000"
            },
            {
                "modificationtime": 1588663800000,
                "axLatency": 3083262,
                "nonAxLatency": 9601082,
                "timestamp": "2020-05-05T07:30:00.000+0000"
            },
            {
                "modificationtime": 1588664100000,
                "axLatency": 1436762,
                "nonAxLatency": 8735246,
                "timestamp": "2020-05-05T07:35:00.000+0000"
            },
            {
                "modificationtime": 1588664400000,
                "axLatency": 2125227,
                "nonAxLatency": 4962339,
                "timestamp": "2020-05-05T07:40:00.000+0000"
            },
            {
                "modificationtime": 1588664700000,
                "axLatency": 1538423,
                "nonAxLatency": 6823323,
                "timestamp": "2020-05-05T07:45:00.000+0000"
            },
            {
                "modificationtime": 1588665000000,
                "axLatency": 4583007,
                "nonAxLatency": 8445099,
                "timestamp": "2020-05-05T07:50:00.000+0000"
            },
            {
                "modificationtime": 1588665300000,
                "axLatency": 1590328,
                "nonAxLatency": 30894236,
                "timestamp": "2020-05-05T07:55:00.000+0000"
            },
            {
                "modificationtime": 1588665600000,
                "axLatency": 2007462,
                "nonAxLatency": 1210328,
                "timestamp": "2020-05-05T08:00:00.000+0000"
            },
            {
                "modificationtime": 1588665900000,
                "axLatency": 3393082,
                "nonAxLatency": 59115122,
                "timestamp": "2020-05-05T08:05:00.000+0000"
            },
            {
                "modificationtime": 1588666200000,
                "axLatency": 2101372,
                "nonAxLatency": 10099437,
                "timestamp": "2020-05-05T08:10:00.000+0000"
            },
            {
                "modificationtime": 1588666500000,
                "axLatency": 3966130,
                "nonAxLatency": 49765312,
                "timestamp": "2020-05-05T08:15:00.000+0000"
            },
            {
                "modificationtime": 1588666800000,
                "axLatency": 2282336,
                "nonAxLatency": 6726364,
                "timestamp": "2020-05-05T08:20:00.000+0000"
            },
            {
                "modificationtime": 1588667100000,
                "axLatency": 1203058,
                "nonAxLatency": 13226923,
                "timestamp": "2020-05-05T08:25:00.000+0000"
            },
            {
                "modificationtime": 1588667400000,
                "axLatency": 2058253,
                "nonAxLatency": 8444169,
                "timestamp": "2020-05-05T08:30:00.000+0000"
            },
            {
                "modificationtime": 1588667700000,
                "axLatency": 3115618,
                "nonAxLatency": 16947594,
                "timestamp": "2020-05-05T08:35:00.000+0000"
            },
            {
                "modificationtime": 1588668000000,
                "axLatency": 4512253,
                "nonAxLatency": 6703710,
                "timestamp": "2020-05-05T08:40:00.000+0000"
            },
            {
                "modificationtime": 1588668300000,
                "axLatency": 4453320,
                "nonAxLatency": 9235265,
                "timestamp": "2020-05-05T08:45:00.000+0000"
            },
            {
                "modificationtime": 1588668600000,
                "axLatency": 10649401,
                "nonAxLatency": 9764336,
                "timestamp": "2020-05-05T08:50:00.000+0000"
            },
            {
                "modificationtime": 1588668900000,
                "axLatency": 11117495,
                "nonAxLatency": 0,
                "timestamp": "2020-05-05T08:55:00.000+0000"
            },
            {
                "modificationtime": 1588669200000,
                "axLatency": 2195425,
                "nonAxLatency": 0,
                "timestamp": "2020-05-05T09:00:00.000+0000"
            },
            {
                "modificationtime": 1588669500000,
                "axLatency": 2462947,
                "nonAxLatency": 0,
                "timestamp": "2020-05-05T09:05:00.000+0000"
            },
            {
                "modificationtime": 1588669800000,
                "axLatency": 3277766,
                "nonAxLatency": 51246357,
                "timestamp": "2020-05-05T09:10:00.000+0000"
            },
            {
                "modificationtime": 1588670100000,
                "axLatency": 1190358,
                "nonAxLatency": 22512418,
                "timestamp": "2020-05-05T09:15:00.000+0000"
            },
            {
                "modificationtime": 1588670400000,
                "axLatency": 1924109,
                "nonAxLatency": 12394547,
                "timestamp": "2020-05-05T09:20:00.000+0000"
            },
            {
                "modificationtime": 1588670700000,
                "axLatency": 3814342,
                "nonAxLatency": 33111142,
                "timestamp": "2020-05-05T09:25:00.000+0000"
            },
            {
                "modificationtime": 1588671000000,
                "axLatency": 1315006,
                "nonAxLatency": 39008409,
                "timestamp": "2020-05-05T09:30:00.000+0000"
            },
            {
                "modificationtime": 1588671300000,
                "axLatency": 2979263,
                "nonAxLatency": 44899936,
                "timestamp": "2020-05-05T09:35:00.000+0000"
            },
            {
                "modificationtime": 1588671600000,
                "axLatency": 2791652,
                "nonAxLatency": 42409603,
                "timestamp": "2020-05-05T09:40:00.000+0000"
            },
            {
                "modificationtime": 1588671900000,
                "axLatency": 4065033,
                "nonAxLatency": 31758787,
                "timestamp": "2020-05-05T09:45:00.000+0000"
            },
            {
                "modificationtime": 1588672200000,
                "axLatency": 4068646,
                "nonAxLatency": 14389526,
                "timestamp": "2020-05-05T09:50:00.000+0000"
            },
            {
                "modificationtime": 1588672500000,
                "axLatency": 2354523,
                "nonAxLatency": 8026696,
                "timestamp": "2020-05-05T09:55:00.000+0000"
            },
            {
                "modificationtime": 1588672800000,
                "axLatency": 3059725,
                "nonAxLatency": 9230357,
                "timestamp": "2020-05-05T10:00:00.000+0000"
            },
            {
                "modificationtime": 1588673100000,
                "axLatency": 2573880,
                "nonAxLatency": 14546039,
                "timestamp": "2020-05-05T10:05:00.000+0000"
            },
            {
                "modificationtime": 1588673400000,
                "axLatency": 5693460,
                "nonAxLatency": 8555050,
                "timestamp": "2020-05-05T10:10:00.000+0000"
            },
            {
                "modificationtime": 1588673700000,
                "axLatency": 6089828,
                "nonAxLatency": 3429743,
                "timestamp": "2020-05-05T10:15:00.000+0000"
            },
            {
                "modificationtime": 1588674000000,
                "axLatency": 1827698,
                "nonAxLatency": 23354074,
                "timestamp": "2020-05-05T10:20:00.000+0000"
            },
            {
                "modificationtime": 1588674300000,
                "axLatency": 1842233,
                "nonAxLatency": 20057664,
                "timestamp": "2020-05-05T10:25:00.000+0000"
            },
            {
                "modificationtime": 1588674600000,
                "axLatency": 5041711,
                "nonAxLatency": 13759837,
                "timestamp": "2020-05-05T10:30:00.000+0000"
            },
            {
                "modificationtime": 1588674900000,
                "axLatency": 1217001,
                "nonAxLatency": 16671874,
                "timestamp": "2020-05-05T10:35:00.000+0000"
            },
            {
                "modificationtime": 1588675200000,
                "axLatency": 19266141,
                "nonAxLatency": 16130820,
                "timestamp": "2020-05-05T10:40:00.000+0000"
            },
            {
                "modificationtime": 1588675500000,
                "axLatency": 16669096,
                "nonAxLatency": 0,
                "timestamp": "2020-05-05T10:45:00.000+0000"
            },
            {
                "modificationtime": 1588675800000,
                "axLatency": 14277798,
                "nonAxLatency": 0,
                "timestamp": "2020-05-05T10:50:00.000+0000"
            },
            {
                "modificationtime": 1588676100000,
                "axLatency": 16462518,
                "nonAxLatency": 0,
                "timestamp": "2020-05-05T10:55:00.000+0000"
            },
            {
                "modificationtime": 1588676400000,
                "axLatency": 1649143,
                "nonAxLatency": 16282912,
                "timestamp": "2020-05-05T11:00:00.000+0000"
            },
            {
                "modificationtime": 1588676700000,
                "axLatency": 2843508,
                "nonAxLatency": 6063651,
                "timestamp": "2020-05-05T11:05:00.000+0000"
            },
            {
                "modificationtime": 1588677000000,
                "axLatency": 5357504,
                "nonAxLatency": 19150738,
                "timestamp": "2020-05-05T11:10:00.000+0000"
            },
            {
                "modificationtime": 1588677300000,
                "axLatency": 4187108,
                "nonAxLatency": 9698863,
                "timestamp": "2020-05-05T11:15:00.000+0000"
            },
            {
                "modificationtime": 1588677600000,
                "axLatency": 1581989,
                "nonAxLatency": 5474873,
                "timestamp": "2020-05-05T11:20:00.000+0000"
            },
            {
                "modificationtime": 1588677900000,
                "axLatency": 4939489,
                "nonAxLatency": 4340649,
                "timestamp": "2020-05-05T11:25:00.000+0000"
            },
            {
                "modificationtime": 1588678200000,
                "axLatency": 4553642,
                "nonAxLatency": 22683287,
                "timestamp": "2020-05-05T11:30:00.000+0000"
            },
            {
                "modificationtime": 1588678500000,
                "axLatency": 6250063,
                "nonAxLatency": 4495204,
                "timestamp": "2020-05-05T11:35:00.000+0000"
            },
            {
                "modificationtime": 1588678800000,
                "axLatency": 2145628,
                "nonAxLatency": 13709360,
                "timestamp": "2020-05-05T11:40:00.000+0000"
            },
            {
                "modificationtime": 1588679100000,
                "axLatency": 1306134,
                "nonAxLatency": 7052504,
                "timestamp": "2020-05-05T11:45:00.000+0000"
            },
            {
                "modificationtime": 1588679400000,
                "axLatency": 2007807,
                "nonAxLatency": 4803234,
                "timestamp": "2020-05-05T11:50:00.000+0000"
            },
            {
                "modificationtime": 1588679700000,
                "axLatency": 2812211,
                "nonAxLatency": 8662010,
                "timestamp": "2020-05-05T11:55:00.000+0000"
            },
            {
                "modificationtime": 1588680000000,
                "axLatency": 1788162,
                "nonAxLatency": 3110329,
                "timestamp": "2020-05-05T12:00:00.000+0000"
            },
            {
                "modificationtime": 1588680300000,
                "axLatency": 6721506,
                "nonAxLatency": 14272856,
                "timestamp": "2020-05-05T12:05:00.000+0000"
            },
            {
                "modificationtime": 1588680600000,
                "axLatency": 8043484,
                "nonAxLatency": 17233894,
                "timestamp": "2020-05-05T12:10:00.000+0000"
            },
            {
                "modificationtime": 1588680900000,
                "axLatency": 3488036,
                "nonAxLatency": 7858747,
                "timestamp": "2020-05-05T12:15:00.000+0000"
            },
            {
                "modificationtime": 1588681200000,
                "axLatency": 2094073,
                "nonAxLatency": 12874105,
                "timestamp": "2020-05-05T12:20:00.000+0000"
            },
            {
                "modificationtime": 1588681500000,
                "axLatency": 1621450,
                "nonAxLatency": 0,
                "timestamp": "2020-05-05T12:25:00.000+0000"
            },
            {
                "modificationtime": 1588681800000,
                "axLatency": 1972840,
                "nonAxLatency": 0,
                "timestamp": "2020-05-05T12:30:00.000+0000"
            },
            {
                "modificationtime": 1588682100000,
                "axLatency": 2804592,
                "nonAxLatency": 919475,
                "timestamp": "2020-05-05T12:35:00.000+0000"
            },
            {
                "modificationtime": 1588682400000,
                "axLatency": 5206436,
                "nonAxLatency": 8574260,
                "timestamp": "2020-05-05T12:40:00.000+0000"
            },
            {
                "modificationtime": 1588682700000,
                "axLatency": 4683852,
                "nonAxLatency": 49479268,
                "timestamp": "2020-05-05T12:45:00.000+0000"
            },
            {
                "modificationtime": 1588683000000,
                "axLatency": 1454513,
                "nonAxLatency": 31042658,
                "timestamp": "2020-05-05T12:50:00.000+0000"
            },
            {
                "modificationtime": 1588683300000,
                "axLatency": 1684719,
                "nonAxLatency": 2113612,
                "timestamp": "2020-05-05T12:55:00.000+0000"
            },
            {
                "modificationtime": 1588683600000,
                "axLatency": 1068100,
                "nonAxLatency": 60161828,
                "timestamp": "2020-05-05T13:00:00.000+0000"
            },
            {
                "modificationtime": 1588683900000,
                "axLatency": 1528129,
                "nonAxLatency": 8482809,
                "timestamp": "2020-05-05T13:05:00.000+0000"
            },
            {
                "modificationtime": 1588684200000,
                "axLatency": 1629332,
                "nonAxLatency": 29050561,
                "timestamp": "2020-05-05T13:10:00.000+0000"
            },
            {
                "modificationtime": 1588684500000,
                "axLatency": 2848535,
                "nonAxLatency": 22275206,
                "timestamp": "2020-05-05T13:15:00.000+0000"
            },
            {
                "modificationtime": 1588684800000,
                "axLatency": 2359351,
                "nonAxLatency": 6856373,
                "timestamp": "2020-05-05T13:20:00.000+0000"
            },
            {
                "modificationtime": 1588685100000,
                "axLatency": 1379105,
                "nonAxLatency": 19769143,
                "timestamp": "2020-05-05T13:25:00.000+0000"
            },
            {
                "modificationtime": 1588685400000,
                "axLatency": 1913726,
                "nonAxLatency": 19214544,
                "timestamp": "2020-05-05T13:30:00.000+0000"
            },
            {
                "modificationtime": 1588685700000,
                "axLatency": 2271814,
                "nonAxLatency": 8288494,
                "timestamp": "2020-05-05T13:35:00.000+0000"
            },
            {
                "modificationtime": 1588686000000,
                "axLatency": 2374479,
                "nonAxLatency": 22455480,
                "timestamp": "2020-05-05T13:40:00.000+0000"
            },
            {
                "modificationtime": 1588686300000,
                "axLatency": 2038189,
                "nonAxLatency": 7299612,
                "timestamp": "2020-05-05T13:45:00.000+0000"
            },
            {
                "modificationtime": 1588686600000,
                "axLatency": 1775695,
                "nonAxLatency": 24473346,
                "timestamp": "2020-05-05T13:50:00.000+0000"
            },
            {
                "modificationtime": 1588686900000,
                "axLatency": 2979954,
                "nonAxLatency": 56491631,
                "timestamp": "2020-05-05T13:55:00.000+0000"
            },
            {
                "modificationtime": 1588687200000,
                "axLatency": 1658522,
                "nonAxLatency": 18279641,
                "timestamp": "2020-05-05T14:00:00.000+0000"
            },
            {
                "modificationtime": 1588687500000,
                "axLatency": 1274395,
                "nonAxLatency": 2363545,
                "timestamp": "2020-05-05T14:05:00.000+0000"
            },
            {
                "modificationtime": 1588687800000,
                "axLatency": 1764808,
                "nonAxLatency": 1755929,
                "timestamp": "2020-05-05T14:10:00.000+0000"
            },
            {
                "modificationtime": 1588688100000,
                "axLatency": 3210927,
                "nonAxLatency": 4367666,
                "timestamp": "2020-05-05T14:15:00.000+0000"
            },
            {
                "modificationtime": 1588688400000,
                "axLatency": 3310681,
                "nonAxLatency": 10262115,
                "timestamp": "2020-05-05T14:20:00.000+0000"
            },
            {
                "modificationtime": 1588688700000,
                "axLatency": 2032082,
                "nonAxLatency": 2573816,
                "timestamp": "2020-05-05T14:25:00.000+0000"
            },
            {
                "modificationtime": 1588689000000,
                "axLatency": 2495538,
                "nonAxLatency": 18227641,
                "timestamp": "2020-05-05T14:30:00.000+0000"
            },
            {
                "modificationtime": 1588689300000,
                "axLatency": 2550706,
                "nonAxLatency": 10769296,
                "timestamp": "2020-05-05T14:35:00.000+0000"
            },
            {
                "modificationtime": 1588689600000,
                "axLatency": 2911222,
                "nonAxLatency": 5804585,
                "timestamp": "2020-05-05T14:40:00.000+0000"
            },
            {
                "modificationtime": 1588689900000,
                "axLatency": 2307641,
                "nonAxLatency": 94537074,
                "timestamp": "2020-05-05T14:45:00.000+0000"
            },
            {
                "modificationtime": 1588690200000,
                "axLatency": 5742176,
                "nonAxLatency": 35116537,
                "timestamp": "2020-05-05T14:50:00.000+0000"
            },
            {
                "modificationtime": 1588690500000,
                "axLatency": 1811648,
                "nonAxLatency": 1221398,
                "timestamp": "2020-05-05T14:55:00.000+0000"
            },
            {
                "modificationtime": 1588690800000,
                "axLatency": 1811685,
                "nonAxLatency": 1957292,
                "timestamp": "2020-05-05T15:00:00.000+0000"
            },
            {
                "modificationtime": 1588691100000,
                "axLatency": 4873795,
                "nonAxLatency": 36429383,
                "timestamp": "2020-05-05T15:05:00.000+0000"
            },
            {
                "modificationtime": 1588691400000,
                "axLatency": 1713073,
                "nonAxLatency": 5492725,
                "timestamp": "2020-05-05T15:10:00.000+0000"
            },
            {
                "modificationtime": 1588691700000,
                "axLatency": 3532229,
                "nonAxLatency": 4330103,
                "timestamp": "2020-05-05T15:15:00.000+0000"
            },
            {
                "modificationtime": 1588692000000,
                "axLatency": 3147581,
                "nonAxLatency": 24759284,
                "timestamp": "2020-05-05T15:20:00.000+0000"
            },
            {
                "modificationtime": 1588692300000,
                "axLatency": 2550131,
                "nonAxLatency": 13297433,
                "timestamp": "2020-05-05T15:25:00.000+0000"
            },
            {
                "modificationtime": 1588692600000,
                "axLatency": 4798695,
                "nonAxLatency": 7526566,
                "timestamp": "2020-05-05T15:30:00.000+0000"
            },
            {
                "modificationtime": 1588692900000,
                "axLatency": 4442088,
                "nonAxLatency": 43090104,
                "timestamp": "2020-05-05T15:35:00.000+0000"
            },
            {
                "modificationtime": 1588693200000,
                "axLatency": 993958,
                "nonAxLatency": 14364986,
                "timestamp": "2020-05-05T15:40:00.000+0000"
            },
            {
                "modificationtime": 1588693500000,
                "axLatency": 4781920,
                "nonAxLatency": 19461230,
                "timestamp": "2020-05-05T15:45:00.000+0000"
            },
            {
                "modificationtime": 1588693800000,
                "axLatency": 4787463,
                "nonAxLatency": 3301532,
                "timestamp": "2020-05-05T15:50:00.000+0000"
            },
            {
                "modificationtime": 1588694100000,
                "axLatency": 9002782,
                "nonAxLatency": 12340593,
                "timestamp": "2020-05-05T15:55:00.000+0000"
            },
            {
                "modificationtime": 1588694400000,
                "axLatency": 3757962,
                "nonAxLatency": 33999478,
                "timestamp": "2020-05-05T16:00:00.000+0000"
            },
            {
                "modificationtime": 1588694700000,
                "axLatency": 1539573,
                "nonAxLatency": 16998910,
                "timestamp": "2020-05-05T16:05:00.000+0000"
            },
            {
                "modificationtime": 1588695000000,
                "axLatency": 3059368,
                "nonAxLatency": 44568222,
                "timestamp": "2020-05-05T16:10:00.000+0000"
            },
            {
                "modificationtime": 1588695300000,
                "axLatency": 4035468,
                "nonAxLatency": 25965865,
                "timestamp": "2020-05-05T16:15:00.000+0000"
            },
            {
                "modificationtime": 1588695600000,
                "axLatency": 1884429,
                "nonAxLatency": 18392921,
                "timestamp": "2020-05-05T16:20:00.000+0000"
            },
            {
                "modificationtime": 1588695900000,
                "axLatency": 3461586,
                "nonAxLatency": 36682169,
                "timestamp": "2020-05-05T16:25:00.000+0000"
            },
            {
                "modificationtime": 1588696200000,
                "axLatency": 2847295,
                "nonAxLatency": 33869796,
                "timestamp": "2020-05-05T16:30:00.000+0000"
            },
            {
                "modificationtime": 1588696500000,
                "axLatency": 1985619,
                "nonAxLatency": 21833582,
                "timestamp": "2020-05-05T16:35:00.000+0000"
            },
            {
                "modificationtime": 1588696800000,
                "axLatency": 2621264,
                "nonAxLatency": 42551305,
                "timestamp": "2020-05-05T16:40:00.000+0000"
            },
            {
                "modificationtime": 1588697100000,
                "axLatency": 3191490,
                "nonAxLatency": 18160174,
                "timestamp": "2020-05-05T16:45:00.000+0000"
            },
            {
                "modificationtime": 1588697400000,
                "axLatency": 2124197,
                "nonAxLatency": 41932844,
                "timestamp": "2020-05-05T16:50:00.000+0000"
            },
            {
                "modificationtime": 1588697700000,
                "axLatency": 1315714,
                "nonAxLatency": 5545978,
                "timestamp": "2020-05-05T16:55:00.000+0000"
            },
            {
                "modificationtime": 1588698000000,
                "axLatency": 721842,
                "nonAxLatency": 35385956,
                "timestamp": "2020-05-05T17:00:00.000+0000"
            },
            {
                "modificationtime": 1588698300000,
                "axLatency": 3209264,
                "nonAxLatency": 11691875,
                "timestamp": "2020-05-05T17:05:00.000+0000"
            },
            {
                "modificationtime": 1588698600000,
                "axLatency": 1475387,
                "nonAxLatency": 39551960,
                "timestamp": "2020-05-05T17:10:00.000+0000"
            },
            {
                "modificationtime": 1588698900000,
                "axLatency": 1963204,
                "nonAxLatency": 9079496,
                "timestamp": "2020-05-05T17:15:00.000+0000"
            },
            {
                "modificationtime": 1588699200000,
                "axLatency": 2495319,
                "nonAxLatency": 12612762,
                "timestamp": "2020-05-05T17:20:00.000+0000"
            },
            {
                "modificationtime": 1588699500000,
                "axLatency": 2174739,
                "nonAxLatency": 6104492,
                "timestamp": "2020-05-05T17:25:00.000+0000"
            },
            {
                "modificationtime": 1588699800000,
                "axLatency": 3577660,
                "nonAxLatency": 24618947,
                "timestamp": "2020-05-05T17:30:00.000+0000"
            },
            {
                "modificationtime": 1588700100000,
                "axLatency": 2201752,
                "nonAxLatency": 12170690,
                "timestamp": "2020-05-05T17:35:00.000+0000"
            },
            {
                "modificationtime": 1588700400000,
                "axLatency": 2011956,
                "nonAxLatency": 18441148,
                "timestamp": "2020-05-05T17:40:00.000+0000"
            },
            {
                "modificationtime": 1588700700000,
                "axLatency": 6942891,
                "nonAxLatency": 16921626,
                "timestamp": "2020-05-05T17:45:00.000+0000"
            },
            {
                "modificationtime": 1588701000000,
                "axLatency": 16046630,
                "nonAxLatency": 5316770,
                "timestamp": "2020-05-05T17:50:00.000+0000"
            },
            {
                "modificationtime": 1588701300000,
                "axLatency": 4728176,
                "nonAxLatency": 61264950,
                "timestamp": "2020-05-05T17:55:00.000+0000"
            },
            {
                "modificationtime": 1588701600000,
                "axLatency": 6047842,
                "nonAxLatency": 7618199,
                "timestamp": "2020-05-05T18:00:00.000+0000"
            },
            {
                "modificationtime": 1588701900000,
                "axLatency": 1910198,
                "nonAxLatency": 10606563,
                "timestamp": "2020-05-05T18:05:00.000+0000"
            },
            {
                "modificationtime": 1588702200000,
                "axLatency": 1982830,
                "nonAxLatency": 35363079,
                "timestamp": "2020-05-05T18:10:00.000+0000"
            },
            {
                "modificationtime": 1588702500000,
                "axLatency": 3123883,
                "nonAxLatency": 2378787,
                "timestamp": "2020-05-05T18:15:00.000+0000"
            },
            {
                "modificationtime": 1588702800000,
                "axLatency": 2301653,
                "nonAxLatency": 15623624,
                "timestamp": "2020-05-05T18:20:00.000+0000"
            },
            {
                "modificationtime": 1588703100000,
                "axLatency": 5087907,
                "nonAxLatency": 7455301,
                "timestamp": "2020-05-05T18:25:00.000+0000"
            }
        ],
        "insights": null
    }
})