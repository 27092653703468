define({
    "Application_Policy_Preview_Template":{
      "id": "f17d118e-c028-446c-8496-0e8844f9308f",
      "qualifier": "application",
      "namespace": "policy:application:p1",
      "generateCLI": false,
      "validate": true,
      "scratchPadDeviceConfig": [],
      "cfsList": []
    },
     "Application_Policy_Preview_Device_Summary_Template":[{
      "instanceId": 0,
      "displayName": "0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "count": 0,
      "applicationPolicyScopeResolutionDeviceSummary": [
        {
          "instanceId": 0,
          "displayName": "0",
          "deployPending": "NONE",
          "instanceVersion": 0,
          "deviceId": "8301db96-42ff-4e57-b4ac-b05b74f730c1",
          "deviceName": "aems-asr-1001-01.cisco.com",
          "deviceRole": "BORDER ROUTER",
          "deviceType": "Cisco ASR 1001-X Router",
          "excluded": false,
          "groupNameHierarchy": "Global/a1/a11",
          "isStale": false,
          "managementIpAddress": "10.56.196.63"
        }
      ]
    }],

    });