define({
    "Template":
        {
            "version": "1.0",
            "response": [
                {
                    "id": "38e6b476-b44c-4108-9c55-b2ca9b9e498f",
                    "mgmtIp": "10.30.255.100",
                    "deviceFamily": "Switches and Hubs",
                    "name": "LA1-3850-CSW-1.corp.local",
                    "inFabric": false,
                    "linkStatus": "up",
                    "virtualNetwork": null,
                    "port": "ge1/0/3",
                    "healthScore": [
                           {
                                "healthType": "",
                                 "reason": "",
                                 "score": 10.0
                           }
                    ],
                    "topologyInterfaceSummary": [{
                        "interface": "GigabitEthernet1/0/3",
                        "linkStatus": "up",
                        "duplex": null,
                        "interfaceShortName": "GigE 1/0/3",
                        "inputError": 0.0,
                        "outputError": 0.0,
                        "inputPacketDrop": 0.0,
                        "outputPacketDrop": 0.0
                    }]
                }
            ]
        }
});