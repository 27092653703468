define({
"Template":{success:true,result:"show cable-diagnostics tdr interface CmdIfNamePlaceHolder\n"
+"TDR test last run on: June 20 07:03:53\n"
+"Interface Speed Local pair Pair length        Remote pair Pair status\n"
+"--------- ----- ---------- ------------------ ----------- --------------------\n"
+"Tw2/0/16  auto  Pair A     N/A                N/A         Not Completed\n"
+"Pair B     N/A                N/A         Not Completed\n"
+"Pair C     N/A                N/A         Not Completed\n"
+"Pair D     N/A                N/A         Not Completed\n"
+"DeviceNamePlaceHolder#"}
})