define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/swim/SimSwimData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/UpgradeImageTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/ImageImportAppTypes',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/SwimTaskList',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/nfv/ApplicationTypeTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/DefaultConfig',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/Security-Advisories/advisoriesTemplate'

], function (SimSwimData, SimLokiDatabaseActions,SimTaskDataGenerator,UpgradeImageTemplate,
    ImageImportAppTypes,SwimTaskList,ApplicationTypeTemplate, UtilityFunctions, DefaultConfig, advisoriesTemplate) {

    return {
        init: function() {},

        doProcessRestRequest: function(urlAction){
            var data;
            switch(urlAction.method) {
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                case 'POST':
                    data = processPostRequest(urlAction);
                    break;

                case 'DELETE':
                    data = processDeleteRequest(urlAction);
                    break;

                default:
                    break;
            }
            return data;
        }
    };

    function processGetRequest(urlAction) {
        //Loads the default images for the group Id
        var id = urlAction.service[3] != undefined ? urlAction.service[3]: urlAction.action.id;
        if (urlAction.service.indexOf('image') >= 0 && urlAction.service.indexOf('task') >= 0 && (urlAction.filter['taskType'] == "distribute,activate") && urlAction.action.count) {
            if (urlAction.filter['taskStatus'] == "success") {
                return 120;
            }
            else if (urlAction.filter['taskStatus'] == "failure") {
                return 0;
            }
            else if (urlAction.filter['taskStatus'] == "in+progress") {
                return 0;
            }
            else {
                return 120;
            }
        }

        if(urlAction.filter['name'] && urlAction.service.indexOf('importation') >= 0) {
            var record = SimSwimData.fetchRunningImageForName(urlAction.filter['groupUuid'],urlAction.filter['name']);
            if(record.length == 0) {
                //invoked from enable embedded wireless import of image, from Fabric
                record = SimSwimData.fetchRunningImageForName(urlAction.filter['groupUuid'], DefaultConfig.EMBEDDED_WIRELESS_IMAGENAME);
            }
            return record;
        }
        else if(urlAction.service.indexOf('importation') >= 0 && urlAction.filter['imageType']=='SUBPACKAGE') {
            //invoked from embedded wireless, from Fabric
            record = SimSwimData.fetchRunningImageForName(undefined, DefaultConfig.EMBEDDED_WIRELESS_IMAGENAME);
            return record;
        }
        else if (urlAction.service.indexOf('importation') >= 0 && urlAction.service.indexOf('site') > -1) {
            return SimSwimData.getSwimImageList(urlAction);
        }
        else if (urlAction.service.indexOf('upgrade-analysis') >= 0) {
            return UpgradeImageTemplate.UpgradeImageData;
        }
        //commenting, as there is diff in 1.3 usage
        /* else if (urlAction.service.indexOf('importation') >= 0 && urlAction.service.indexOf('applicationTypes') >= 0) {
            if(urlAction.filter[0]==('/api/v1/image/importation/applicationTypes')){
                return ImageImportAppTypes.UpgradeImageData; 
            }
        } */
        else if (urlAction.service.indexOf('image') >= 0 && urlAction.service.indexOf('task') >= 0) {
            if (urlAction.filter['taskType'] &&
              "import,delete-image-repo,recommend".includes(urlAction.filter['taskType'])) {
                //show task of image repository
                var records =  SimLokiDatabaseActions.getAll('image-task-list');
                if(urlAction.filter.taskStatus) {
                    records = records.filter(e => e.taskStatus==urlAction.filter.taskStatus.toUpperCase());
                }
                var sortFilter = {};
                sortFilter.sortBy = "startTime";
                sortFilter.order = "desc";
                records = decorateData(sortFilter, records);
                return records;
            } else if (urlAction.filter['taskType'] && (urlAction.filter['taskType'] == "activate" ||
                urlAction.filter['taskType'] == "distribute,activate")) {
                var records = SimSwimData.getActivateUpdate(urlAction);
                var sortFilter = {};
                sortFilter.sortBy = "startTime";
                sortFilter.order = "desc";
                records = decorateData(sortFilter, records);
                return records;
            } else if(urlAction.filter.deviceId) {
               let records = SimLokiDatabaseActions.getFilteredRecordHandler('image-activation', {'deviceId': urlAction.filter.deviceId});
                if(urlAction.filter.deviceTaskUuid) {
                    records = records.filter(e => e.deviceTaskUuid==urlAction.filter.deviceTaskUuid);
                }
                return records;
            } else if (urlAction.service.indexOf('targetImageVersions') > -1) {
                return {"isExcludeAppend":true, "data":["17.3.3","17.6.1","17.9.1"]};//to update the handling - Lavanya
            } else {
                return [];
            }
        } else if (urlAction.service.indexOf('importation') >= 0 && urlAction.action.count) {
            return SimSwimData.getCountDetails(urlAction);
        } else if(urlAction.service.indexOf('importation') >= 0 && urlAction.service.indexOf('complianceForm') >= 0 ) {
            return {'accepted':true};
        } else if (urlAction.service.indexOf('importation') >= 0 && id) {
            return SimSwimData.getImageDtlsFromUuid(id);
        } else if (urlAction.service.indexOf('importation') > -1 && urlAction.service.indexOf('advisory') > -1) {
            if(urlAction.filter.version=="16.12.3") {
                if(urlAction.filter.sir=="MEDIUM") return advisoriesTemplate.ImageAdvisoryMedium;
                if(urlAction.filter.sir=="HIGH") return advisoriesTemplate.ImageAdvisoryHigh;
            }
            return [];
        } else if(urlAction.service.indexOf('software-image-upgrade.checks-configuration-alert.do-not-show')>-1) {
            return false;//not sure of expected response..
        } else if(urlAction.service.indexOf('repository')>-1) {
            // if(id==-1 || id=='e56379f6-dbb2-fc6f-3aa8-e73e396793c5' || id=='c0bc07f5-1606-9a21-d671-fce1eb4f3005')
               //  var records = SimLokiDatabaseActions.getAll('images', {});
            // else 
                console.log(id);
                var siteData = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'id':id});
                var records =[];
                if (id=='-1' || (siteData.length !=0 && ( siteData[0].groupNameHierarchy.includes("Global/North America/USA") || siteData[0].name=='North America' )))
                 records = SimLokiDatabaseActions.getAll('images', {});
               
                var devTypeOrd = urlAction.service.indexOf('deviceTypeOrdinal');
           if(devTypeOrd>-1) {
                //on clicking a series from image repository

               //return records;
               records = SimLokiDatabaseActions.getAll('images', {});
                 return (records.filter(e=>e.deviceTypeOrdinal==urlAction.service[devTypeOrd+1]))[0].runningImageList;
               // return records[0].runningImageList;
          }
            records.forEach(rec => {
                if(rec.goldenImageUuids == undefined) rec.goldenImageUuids=[];
                if( rec.deviceUuids ==undefined)rec.deviceUuids= [];
                if(rec.baseImages ==undefined)rec.baseImages = {};
                if( rec.virtualVendors== undefined)rec.virtualVendors=[] ;
                if(rec.runningImageList==undefined)rec.runningImageList=[];
                rec.runningImageList.forEach(img => {
                    if(img.isTaggedGolden=="true") rec.goldenImageUuids.push(img.imageUuid);
                    rec.deviceUuids = rec.deviceUuids.concat(img.deviceUuidList);
                    rec.baseImages[img.name] = img.version;
                });
            });

            
            return records; //on loading of image repository page
        }
        //commenting, as there is diff in 1.3 usage
        /* else if(urlAction.service.indexOf('image')>=0 && urlAction.service.indexOf('importation')>=0 && 
          (urlAction.filter['applicationType'] || urlAction.filter['family'])) {
            var applicationType = urlAction.filter['applicationType'];
            var family= urlAction.filter['family'];
            var data =JSON.parse(JSON.stringify(ApplicationTypeTemplate.Application_Type_Template));

            return data.filter(function(type){

                      if(applicationType!=undefined && family==undefined) {

                      return (type.applicationType ==applicationType);

                      } else if(applicationType==undefined && family!=undefined) {
                       return (type.family==family);
                      }
                     return (type.applicationType ==applicationType && type.family==family);
                     });
        } */

        var record = SimLokiDatabaseActions.getFilteredRecordHandler('images', {'groupUuid': id});
        return record;
        ////return record.length && record[0] != undefined ? record[0].imageDetails : {};
    }

    function processPostRequest(urlAction){
        if(urlAction.url.indexOf('importation') >= 0 &&
          ((urlAction.url.indexOf('source') >= 0 && (urlAction.url.indexOf('device') >= 0 || urlAction.url.indexOf('cco') >= 0)) ||
            urlAction.url.indexOf('golden') >= 0)) {
            var taskObj = SimSwimData.markGoldenImages(urlAction);
            return taskObj;
        }
        else if (urlAction.service.indexOf('importation') >= 0 && urlAction.service.indexOf('source') >= 0&& urlAction.service.indexOf('url') >= 0) {
            if(urlAction.filter[0]==('/api/v1/image/importation/source/url')){
                //Import Image rest call Hit
                var startTime = UtilityFunctions.getTimeStamp();
                var imageiso = urlAction.restPayload[0].sourceURL;
                //var imageiso = "ftp://cisco:cisco123@10.16.0.70/ios/switch/cat3k_caa-universalk9.16.06.03.SPA.bin";
                var isoFields = imageiso.split("/");
                var imageName = isoFields[isoFields.length-1];
                SimSwimData.updateImageTaskList("import", null, imageName, startTime);
                return imageiso;
            }
        } else if(urlAction.url.indexOf('activation') > -1 || urlAction.service.indexOf('distribution') >=0) {
            //Upgrade OS - image activation - Distribute and activate Now
            //api/v1/image/activation/device
            //[{"deviceUuid":"0741042b-b84d-4dbc-a0ea-76e151d25671","deviceUpgradeMode":"","distributionLocation":"flash","activateLowerImageVersion":true,"distributeIfNeeded":true}]
            var taskObj = SimSwimData.activateImage(urlAction);
            return taskObj;
        } /* else if(urlAction.service.indexOf('distribution') >=0) {

          var id =UtilityFunctions.generate_uuid();
            var resultObj = SimTaskDataGenerator.createTask("Swim Service",{ id: id });
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
            resultObj.statusCode= 202;
            return resultObj;
        } */

        else if(urlAction.service.indexOf('upgrade-analysis') >=0) {
            let tJson = JSON.parse(JSON.stringify(UpgradeImageTemplate.VALIDATOR_DETAIL));
            if(urlAction.restPayload.criteriaMap.deviceFamily && urlAction.restPayload.criteriaMap.deviceFamily[0]=="Wireless Controller") {
                tJson = tJson.filter(e=> ["Flash check","Config register check", "Startup config check"].indexOf(e.validatorName) > -1);
                //selectionCriterias section is also different. But if it works with this, can skip handling it.
            }
            return tJson;
        }
    }

    function processDeleteRequest(urlAction) {
        var startTime = UtilityFunctions.getTimeStamp();
        if(urlAction.url.indexOf('importation') >= 0 && urlAction.url.indexOf('golden') >= 0){
            //untag golden image
            var taskObj = SimSwimData.unmarkGoldenImages(urlAction);
            return taskObj;
        } else if (urlAction.url.indexOf('importation') >= 0) {
            //delete an image
            var urlFields = urlAction.url.split("/");
            var imageId = urlFields[urlFields.length-1];
            var taskObj = SimSwimData.deleteImage(imageId, startTime);
            return taskObj;
        }
    }

    function decorateData (filter, data) {
        var sortBy = filter['sortBy'];
        var orderBy = filter['order'];
        if(sortBy != undefined) {
            return data.sort(function(a, b) {
                if(a[sortBy] == undefined) {
                    return 1;
                }
                if(b[sortBy] == undefined) {
                    return 1;
                }
                if(orderBy == undefined || orderBy == 'asc') {
                    if (a[sortBy] < b[sortBy]) return -1;
                    if (a[sortBy] > b[sortBy]) return 1;
                    return 0;
                } else {
                    if (a[sortBy] > b[sortBy]) return -1;
                    if (a[sortBy] < b[sortBy]) return 1;
                    return 0;
                }
           });
        }
        return data;
    }

});
