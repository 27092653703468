define({
  "Template": {
    "version": "1.0",
    "response": {
      "id": "AWKkotCCUVFRaeYF-N3t",
      "name": "router_cpu_high",
      "entityType": "NetworkDevice",
      "entity": "10.0.255.31",
      "enabled": true,
      "flattened": true,
      "groupBy": "Unknown",
      "category": "Device",
      "severity": "HIGH",
      "priority": "P1",
      "groupId": "high_cpu_utilization_grouping",
      "groupName": "Router experiencing high CPU utilization",
      "summary": "Device experiencing high CPU utilization",
      "scope": "LOCALIZED",
      "rootCause": "Unknown",
      "timestamp": 1523180100000,
      "description": "Network Device DeviceNamePlaceHolder is Experiencing High CPU Utilization 99 %",
      "suggestions": [
        {
          "message": "Run embedded wireshark to see what (if any) traffic is being sent to the CPU.",
          "steps": [

          ]
        },
        {
          "message": "Verify configuration is compliant.",
          "steps": [

          ]
        },
        {
          "message": "Check the CPU history for the last 60sec, 60min, 72hours",
          "steps": [
            {
              "entityId": "DeviceIdPlaceHolder",
              "description": "Check the CPU history for the last 60sec, 60min, 72hours",
              "command": "show proc cpu history",
              "stepType": "command-Runner"
            }
          ]
        },
        {
          "message": "Check for the CPU queue with the highest hits/drops to determine type of traffic sent to CPU (if applicable)",
          "steps": [
            {
              "entityId": "DeviceIdPlaceHolder",
              "description": "Check for the CPU queue with the highest hits/drops to determine type of traffic sent to CPU (if applicable)",
              "command": "show controllers cpu-interface",
              "stepType": "command-Runner"
            }
          ]
        },
        {
          "message": "Check for the CoPP class with the highest hits/drops in case the conform rate is set too high",
          "steps": [
            {
              "entityId": "DeviceIdPlaceHolder",
              "description": "Check for the CoPP class with the highest hits/drops in case the conform rate is set too high",
              "command": "show platform qos queue stats internal cpu policer",
              "stepType": "command-Runner"
            }
          ]
        },
        {
          "message": "Verify the device logs for CPU related event messages",
          "steps": [

          ]
        },
        {
          "message": "Check the release notes for known bugs, if it is known issue, check if SMU is available and apply on the switch",
          "steps": [

          ]
        },
        {
          "message": "Contact Cisco TAC World Wide – https://www.cisco.com/c/en/us/support/web/tsd-cisco-worldwide-contacts.html",
          "steps": [

          ]
        }
      ],
      "additionalParams": [
        {
          "key": "managementIpAddr",
          "value": "10.0.255.31"
        },
        {
          "key": "__entity_type__",
          "value": "NetworkDevice"
        },
        {
          "key": "cpu",
          "value": "99.0"
        },
        {
          "key": "entityId",
          "value": "10.0.255.31"
        },
        {
          "key": "threshold",
          "value": "95"
        },
        {
          "key": "message",
          "value": "This Device is experiencing high CPU utilization"
        },
        {
          "key": "type",
          "value": "Issue"
        },
        {
          "key": "priority",
          "value": "P1"
        },
        {
          "key": "tenantid",
          "value": "TNT0"
        },
        {
          "key": "category",
          "value": "Device"
        },
        {
          "key": "status",
          "value": "active"
        },
        {
          "key": "timestring",
          "value": "2018-04-08T09:35:00.000+0000"
        },
        {
          "key": "platformType",
          "value": "Switches and Hubs"
        },
        {
          "key": "nwDeviceName",
          "value": "C9404R-border_cp_sda.loca"
        },
        {
          "key": "deviceRole",
          "value": "Router"
        },
        {
          "key": "siteHierarchy",
          "value": ""
        }],
      "status": {
        "status": "ACTIVE",
        "updatedBy": "Unknown",
        "notes": "Unknown",
        "source": "Unknown",
        "updatedAt": null,
        "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null


      },
      "siteQulaifiedName": null
    }
  }
})