define({
"Template": {success:true,result:"show stack-power detail\n"
+"Power Stack           Stack   Stack    Total   Rsvd    Alloc   Unused  Num  Num\n"
+"Name                  Mode    Topolgy  Pwr(W)  Pwr(W)  Pwr(W)  Pwr(W)  SW   PS\n"
+"--------------------  ------  -------  ------  ------  ------  ------  ---  ---\n"
+"Powerstack-1          SP-PS   Stndaln  1100    30      710     360     1    1\n"
+"Powerstack-11         SP-PS   Stndaln  1100    30      833     237     1    1\n"
+"\n"
+"Power stack name: Powerstack-1\n"
+"Stack mode: Power sharing\n"
+"Stack topology: Standalone\n"
+"Switch 1:\n"
+"Power budget: 1070\n"
+"Power allocated: 710\n"
+"Low port priority value: 22\n"
+"High port priority value: 13\n"
+"Switch priority value: 4\n"
+"Port 1 status: Not connected\n"
+"Port 2 status: Not connected\n"
+"Neighbor on port 1: 0000.0000.0000\n"
+"Neighbor on port 2: 0000.0000.0000\n"
+"\n"
+"Power stack name: Powerstack-11\n"
+"Stack mode: Power sharing\n"
+"Stack topology: Standalone\n"
+"Switch 2:\n"
+"Power budget: 1070\n"
+"Power allocated: 833\n"
+"Low port priority value: 22\n"
+"High port priority value: 13\n"
+"Switch priority value: 4\n"
+"Port 1 status: Not connected\n"
+"Port 2 status: Not connected\n"
+"Neighbor on port 1: 0000.0000.0000\n"
+"Neighbor on port 2: 0000.0000.0000\n"
+"DeviceNamePlaceHolder#"}
})