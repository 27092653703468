define({
  "Template": {
  "version": "1.0",
  "response": [
  {
    "id": "AWjDxC0AsIPgh8_7QmxO",
    "name": "global_wireless_client_excess_auth_wlc_trigger",
    "enabled": true,
    "flattened": true,
    "entityType": "Client",
    "entity": "7C:46:85:20:80:EA",
    "groupBy": "CT5520-MK",
    "category": "Onboarding",
    "severity": "HIGH",
    "summary": "Wireless client took a long time to connect (SSID: SSID@ToChange, AP: AP@ToChange, Band: Band@ToChange GHz) - Excessive time due to WLC failures",
    "scope": "LOCALIZED",
    "priority": null,
    "rootCause": "EXCESSIVE_AUTH_FAIL_WLC",
    "timestamp": 1549472531856,
    "description": "This client is taking longer than expected time to connect to 'SSID@ToChange' SSID due to excessive authentication time.<ul><li>Onboarding took 26.3 seconds (expected time should be less than 10.0 seconds).</li><li>Association took 0 seconds (expected time should be less than 2.0 seconds)</li><li>Authentication and Key Exchange took 8.7 seconds (expected time should be less than 3.0 seconds)</li><li>IP Addressing took 0 seconds (expected time should be less than 5.0 seconds)</li></ul><br>The authentication delay is due to the WLC. The client was connecting to 'SSID@ToChange' SSID on Band@ToChange GHz radio on 'AP@ToChange' AP  in 'Site@ToChange'. The AP was connected to 'Wlc@ToChange' WLC.",
    "suggestions": [
      {
        "message": "Check ARP resolution path for the AAA server or gateway, since failure to resolve the AAA server IP will result in a AAA response failure.",
        "steps": [

        ]
      },
      {
        "message": "Check if the AAA server is responsive as this may cause the WLC to mark the AAA server as down.",
        "steps": [

        ]
      },
      {
        "message": "Check if AAA server is overloaded. An overloaded AAA server will not respond to authentication messages causing the WLC to mark that AAA server as down.",
        "steps": [

        ]
      },
      {
        "message": "Check if there is a client spike joining the WLC.",
        "steps": [

        ]
      },
      {
        "message": "Check if client exclusion feature is disabled.",
        "steps": [

        ]
      }
    ],
    "additionalParams": [
      {
        "key": "__key__",
        "value": "Interface:7C:46:85:20:80:EA:7C:46:85:20:80:EA:EXCESSIVE_AUTH_FAIL_WLC:CT5520-MK:false"
      },
      {
        "key": "hostName",
        "value": "android-52b4ae5b798ca7ef"
      },
      {
        "key": "groupType",
        "value": "WLC"
      },
      {
        "key": "assocTime",
        "value": "0"
      },
      {
        "key": "authTime",
        "value": "8656"
      },
      {
        "key": "groupBy",
        "value": "CT5520-MK"
      },
      {
        "key": "slot",
        "value": "1"
      },
      {
        "key": "type",
        "value": "issue"
      },
      {
        "key": "reasonType",
        "value": "cl_INVALID_EAPOL_KEY"
      },
      {
        "key": "previousApName",
        "value": "AP4800.8DEC"
      },
      {
        "key": "ssid",
        "value": "@CorpSSID"
      },
      {
        "key": "apName",
        "value": "AP4800.922C"
      },
      {
        "key": "frequency",
        "value": "5.0"
      },
      {
        "key": "reasonCodes",
        "value": "1,0,0,89,0,0,233"
      },
      {
        "key": "vlan",
        "value": "0"
      },
      {
        "key": "hostType",
        "value": "WIRELESS"
      },
      {
        "key": "rootCause",
        "value": "EXCESSIVE_AUTH_FAIL_WLC"
      },
      {
        "key": "aaaServerIp",
        "value": ""
      },
      {
        "key": "resultType",
        "value": "cl_FAIL"
      },
      {
        "key": "__suppression__",
        "value": "0"
      },
      {
        "key": "dhcpTime",
        "value": "0"
      },
      {
        "key": "hostOSType",
        "value": "Android-Motorola"
      },
      {
        "key": "isRoaming",
        "value": "false"
      },
      {
        "key": "entityType",
        "value": "interface_host"
      },
      {
        "key": "totalTime",
        "value": "26284"
      },
      {
        "key": "__entity_type__",
        "value": "Interface"
      },
      {
        "key": "previousWlcName",
        "value": ""
      },
      {
        "key": "apGroup",
        "value": "iCap_AP"
      },
      {
        "key": "dhcpServerIp",
        "value": "10.13.4.1"
      },
      {
        "key": "apMac",
        "value": "F4:DB:E6:87:39:E0"
      },
      {
        "key": "entityId",
        "value": "7C:46:85:20:80:EA"
      },
      {
        "key": "eventType",
        "value": "cl_EVENT_L2KEY_DONE"
      },
      {
        "key": "priority",
        "value": "P4"
      },
      {
        "key": "eventTypes",
        "value": "1,2,3,4,5,9,7"
      },
      {
        "key": "wlcName",
        "value": "CT5520-MK"
      },
      {
        "key": "osCategory",
        "value": "Android"
      },
      {
        "key": "deviceCategory",
        "value": "Motorola"
      },
      {
        "key": "eventTimestamps",
        "value": "1549472532336,0,3,143,8,0,116"
      },
      {
        "key": "resultTypes",
        "value": "0,0,0,0,0,0,0"
      },
      {
        "key": "siteId",
        "value": "2572a60e-c5d0-4e9a-a332-28881db6bdc8"
      },
      {
        "key": "_id",
        "value": "7C:46:85:20:80:EA"
      },
      {
        "key": "category",
        "value": "onboarding"
      },
      {
        "key": "timestring",
        "value": "2019-02-06T17:02:11.856+0000"
      },
      {
        "key": "status",
        "value": "active"
      },
      {
        "key": "macAddr",
        "value": "7C:46:85:20:80:EA"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": "SAJO/SJ23/F1"
  }
 ]
}
})