define({
"Template":
{
  "version": "1.0",
  "response": {
    "id": "AWWwXbdsAAQ7MfF7gVwT",
    "name": "snmp_map_cache_limit_reached",
    "entityType": "network_device",
    "entity": "192.168.120.1",
    "enabled": true,
    "flattened": true,
    "groupBy": "Unknown",
    "category": "Utilization",
    "severity": "HIGH",
    "summary": "Map cache entries 11.0 have exceeded limit 2.0.",
    "scope": "LOCALIZED",
    "groupId": "snmp_map_cache_grouping",
    "groupName": "Map cache limit reached",
    "priority" : "P2",
    "rootCause": "Unknown",
    "timestamp": 1536261600000,
    "description": "Map cache entries 11 have exceeded limit 2 on the Fabric DeviceNamePlaceHolder",
    "suggestions": [
      {
        "message": "Verify cache size on the node",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check cache size on the node p1-edge1.sda-pod1.local",
            "command": "show lisp service ipv4 summary",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Ensure that the device has correct cache size configured and since you have exceeded the limit consider moving hosts to different fabric edge nodes",
        "steps": [

        ]
      },
      {
        "message": "Else, upgrade the device to accommodate more hosts",
        "steps": [

        ]
      },
      {
        "message": "Contact Cisco TAC World Wide – https://www.cisco.com/c/en/us/support/web/tsd-cisco-worldwide-contacts.html",
        "steps": [

        ]
      }
    ],
    "additionalParams": [
      {
        "key": "mapCacheLimit",
        "value": "2.0"
      },
      {
        "key": "managementIpAddr",
        "value": "192.168.120.1"
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "__entity_type__",
        "value": "NetworkDevice"
      },
      {
        "key": "scope",
        "value": ""
      },
      {
        "key": "mapCacheSize",
        "value": "11.0"
      },
      {
        "key": "entityId",
        "value": "192.168.120.1"
      },
      {
        "key": "type",
        "value": "Issue"
      },
      {
        "key": "priority",
        "value": "P1"
      },
      {
        "key": "category",
        "value": "Utilization"
      },
      {
        "key": "timestring",
        "value": "2018-09-06T19:20:00.000+0000"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}
})