define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/site/GlobalGroupSummaryTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/site/SiteBaseTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/DefaultConfig',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimDesignData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimNetworkDeviceData'
], function(UtilityFunctions, SimLokiDatabaseActions, GlobalGroupSummaryTemplate, SiteBaseTemplate, SimTaskDataGenerator, DefaultConfig, SimDesignData,SimNetworkDeviceData){
    return {
        init: function(){
     //   SimNetworkDeviceData.assignSiteToDevicesDuringInit();
        },

        getGlobalSummary: function(urlAction){
            var serviceName = 'site';

            var tJson = JSON.parse(JSON.stringify(GlobalGroupSummaryTemplate.GlobalGroupSummary_Template));

            var tObj = SimLokiDatabaseActions.getAll(serviceName);
            var siteTotalCount = tObj && tObj.length ? tObj.length: "0";
            var iDirectCount = "0";
            for(var i =0; i < siteTotalCount; i++){
                if(tObj[i].parentId == DefaultConfig.DEFAULT_UUID.GlobalGroup) {
                    iDirectCount++;
                }
            }
            tJson.additionalInfo[0].attributes['group.count.total'] = JSON.stringify(siteTotalCount);
            tJson.additionalInfo[0].attributes['group.count.direct'] = JSON.stringify(iDirectCount);
            tJson.additionalInfo[0].attributes['member.count.total'] = (SimLokiDatabaseActions.getAll('network-device')).length;
            tJson.additionalInfo[0].attributes.hasChild = iDirectCount ? 'TRUE' : 'FALSE';
            tJson.groupHierarchy=tJson.id;
            //tJson.systemGroup = false;
            tJson.systemGroup = true;
            return [tJson];
        },

        getSiteDetails: function(urlAction) {
            var data, tObj;
            var serviceName = 'site';//FIXME -- check if this is correct..
            if(urlAction.action.id != '' && urlAction.action.child){
                var id = DefaultConfig.DEFAULT_UUID.GlobalGroup;
                if(urlAction.action.id != id){
                    id = urlAction.action.id;
                }

                tObj = SimLokiDatabaseActions.getFilteredRecordHandler(serviceName, {'parentId' : id });

                if(tObj.length){
                    //FIXME..
                    var tObj1 = SimLokiDatabaseActions.getAll(serviceName);
                    var siteTotal = tObj1 && tObj1.length ? tObj1.length : 1;

                    for(i=0;i<tObj.length;i++) {
                        var child = SimLokiDatabaseActions.getFilteredRecordHandler(serviceName, {'parentId' : tObj[i].id });
                        var siteDirectCount = child && child.length ? child.length : 0;

                        tObj[i].additionalInfo[0].attributes['group.count.total'] = siteTotal;
                        tObj[i].additionalInfo[0].attributes['group.count.direct'] = siteDirectCount;
                        tObj[i].additionalInfo[0].attributes.hasChild = siteDirectCount ? 'TRUE' : 'FALSE';
                        tObj[i].groupHierarchy=tObj[i].parentId+ '/' +tObj[i].id;

                    }
                }
            }  else if(urlAction.action.id != '' && urlAction.action.child == false && urlAction.filter['memberType'] == 'networkdevice' && urlAction.filter['level'] != '-1') {
                var siteId = urlAction.action.id;
                var tObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'siteId' : siteId });
                if(urlAction.action.count) { return tObj.length; }
                return tObj && tObj.length ? tObj : [];
            }else if(urlAction.filter['memberType'] == 'networkdevice'){
                var tObj = [];
                if(urlAction.filter['groupNameHierarchy']) {
                    //Called from Fabric Site Domain List View
                    var siteObj = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'groupNameHierarchy':urlAction.filter['groupNameHierarchy']})
                    if(siteObj.length > 0)
                        tObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'siteId':siteObj[0].id});
                } else {
                    tObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'type': 'Cisco Catalyst 9300 Switch'});
                }
                // written for loop below to fix the issue in 1.3.1 where when we open Provision -->Inventory -->Select any Device 
                // Graphql call is appending the same data so we are removing that -- Fix needed by Karan 
                for(i=0;i<tObj.length;i++){
                    delete tObj[i]["network-device"]
                }
                tObj = tObj && tObj.length ? tObj : [];
                if(urlAction.action.count) return tObj.length;
                return tObj;
            }
            //else if(urlAction.action.id != '' && urlAction.service.length >= 2 && urlAction.service[1] == 'member'){
                //This is the case -- Inventory or Device passed the device id,
                //we have to return associated site information for this device..
              //  tDeviceObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id' : urlAction.action.id });
                //var tSiteId = tDeviceObj[0].siteId;
            //}
            else if(urlAction.action.id != '' && urlAction.action.child == false) {
                var siteId = urlAction.action.id;
                if(urlAction.service.length >= 2 && urlAction.service[1] == 'member'){
                    //This is the case -- Inventory or Devices plugin passed the device id,
                    //we have to return associated site information for this device..
                    tDeviceObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id' : urlAction.action.id });
                    siteId = tDeviceObj[0].siteId;
                    tObj = SimLokiDatabaseActions.getFilteredRecordHandler(serviceName, {'id' : siteId});
                    return tObj && tObj.length ? tObj : [];
                }
                // fix is added by Asha
                if(urlAction.service[1] == 'group'){
                    return tObj;
                }

                tObj = SimLokiDatabaseActions.getFilteredRecordHandler(serviceName, {'id' : siteId});
                if(tObj.length>0){
                    tObj[0].additionalInfo[0].attributes['hasChild']='false'
                    tObj[0].additionalInfo[0].attributes['group.count.total']=0;
                    tObj[0].additionalInfo[0].attributes['group.count.direct']=0;
                    tObj[0].additionalInfo[0].attributes['member.count.direct']=0;
                    return tObj[0];
                }
                return {};
            } else if(urlAction.filter.indexOf("groupName")>=0) {
                 var siteName =urlAction.filter["groupName"];
                 siteName = siteName.replace(/%25/g, ".*");
                 tObj = SimLokiDatabaseActions.filterByRegx("site",'name',siteName);
            }
            /* else if(urlAction.filter['additionalInfo.attributes.type'] == 'floor'){
                tObj = SimLokiDatabaseActions.getFilteredRecordHandler(serviceName, {'additionalInfo.attributes.type' : urlAction.filter['additionalInfo.attributes.type']});
                return tObj && tObj.length ? tObj : [];
            } */

            // this below  else if condition is previously used for get only buildings. but in 1.3 it is not necessary as 
            // we are showing all area, buildings and floors
            else if(urlAction.filter['additionalInfo.attributes.type']){
                var types = (urlAction.filter['additionalInfo.attributes.type']).split(',');
                tObj = SimLokiDatabaseActions.getFilteredRecordHandler(serviceName, {'additionalInfo.attributes.type' : {'$in' : types}});
                
                if(!urlAction.action.count) {
                    return tObj && tObj.length ? tObj : [];
                }
            }
            else if(urlAction.filter['additionalInfo.nameSpace'] == 'ETA'){
                // For StealthWatch
                tObj = SimLokiDatabaseActions.getFilteredRecordHandler(serviceName, {'additionalInfo.attributes.type' : {'$in' : 'floor'}});
                var siteTotal = tObj && tObj.length ? tObj.length : 1;
                var dbObj = SimLokiDatabaseActions.getAll('functional-capability')
                for (var i=0;i<tObj.length; i++){
                    var eJson ={};
                    var attributes = {};
                    eJson['nameSpace'] = "ETA";
                    attributes['member.etaCapable.direct'] = "0";
                    attributes['member.etaEnabled.direct'] =  "0";
                    attributes['member.etaNotReady.direct'] = "0";
                    attributes['member.etaReady.direct'] = "0";
                    attributes['member.etaReadyNotEnabled.direct'] = "0";
                    if(tObj[i].name == "Flr-SJC1-1"){
                        
                        attributes['ETAReady'] = "true",
                        attributes['member.etaCapable.direct'] =  "5";
                        attributes['member.etaReady.direct'] = "5";
                        attributes['member.etaReadyNotEnabled.direct'] = "5"; //2
                        attributes['member.etaNotReady.direct'] = "20";
                        if(dbObj[0].current_status == "ENABLED"){
                            attributes['member.etaEnabled.direct'] =  "5";
                            attributes['member.etaReady.direct'] = "0";
                            attributes['member.etaReadyNotEnabled.direct'] = "0";
                        }
                    } 
                    eJson['attributes'] = attributes;
                    tObj[i].additionalInfo.push(eJson)  
                    // var eJson1 ={};
                    // var attributes1 = {};
                    // if(tObj[i].name == "Flr-SJC1-1"){
                    //     eJson1['nameSpace'] = "UMBRELLA";
                    //     attributes1['umbrellaReady'] = "true";
                    //     attributes1['member.umbrellaNotReady.direct'] =  "0";
                    //     attributes1['member.umbrellaReady.direct'] = "1";
                    //     attributes1['member.umbrellaReadyNotEnabled.direct'] = "1";
                    //     attributes1['member.umbrellaEnabled.direct'] = "0";
                    // } 
                    // eJson1['attributes'] = attributes1;
                    // tObj[i].additionalInfo.push(eJson1)    
                }
            }
            else {
                tObj = SimLokiDatabaseActions.getAll(serviceName);
                // Added by Karan
                var siteTotal = tObj && tObj.length ? tObj.length : 1;
                for (var i=0;i<tObj.length; i++){
                    var child = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'parentId' : tObj[i].id });
                    var siteDirectCount = child && child.length ? child.length : 0;
                    for (var j=0;j<tObj[i].additionalInfo.length ;j++) {
                        if(tObj[i].additionalInfo[j].nameSpace== "System Settings"){
                            tObj[i].additionalInfo[j].attributes['group.count.total'] = JSON.stringify(siteTotal);
                            tObj[i].additionalInfo[j].attributes['group.count.direct'] = JSON.stringify(siteDirectCount);
                            tObj[i].additionalInfo[j].attributes.hasChild = siteDirectCount ? 'TRUE' : 'FALSE';
                        }
                    }
                }                
            }

            if(urlAction.action.count){
                return tObj && tObj.length ? tObj.length : 0;
            }

            data = tObj && tObj.length ? tObj : [];
            //console.log(data);
            return data;
        },

        postSiteCreate: function(urlAction){
            var siteId = UtilityFunctions.generate_uuid();
            var resultObj = SimTaskDataGenerator.createTask('Grouping Service', { id: siteId });
            var wlcAttr = {}
            wlcAttr['nameSpace'] = 'com.wireless.managingwlc'
            wlcAttr['attributes'] = {
                "primaryWlcInheritedFrom": siteId,
                "secondaryWlcInheritedFrom": siteId,
                "anchorWlcInheritedFrom": siteId,
                "tertiaryWlcInheritedFrom": siteId
            }
            var tSite = JSON.parse(JSON.stringify( SiteBaseTemplate.SITE_BASIC_DATA ));
            tSite.id = siteId;
            tSite.parentId = urlAction.restPayload.parentId;
            tSite.name = urlAction.restPayload.name;
            tSite.additionalInfo.push(urlAction.restPayload.additionalInfo[0]);
            tSite.additionalInfo[1].attributes.addressInheritedFrom = tSite.id
            tSite.additionalInfo.push(wlcAttr)

            tSite.groupNameHierarchy  = getGroupNameHierarchy(tSite.name, tSite.parentId);
            tSite.groupHierarchy = getGroupHierarchy(tSite.id, tSite.parentId)           
        
            SimLokiDatabaseActions.insert( 'site', tSite );

            SimDesignData.createServers(siteId);
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
            resultObj.statusCode = 202;
            return resultObj;
        },

        /*getSiteDetailsForNetworkDevice: function(urlAction){
           if(urlAction.filter['id'] != undefined && urlAction.filter['id'] != '') {
               var tArr = urlAction.filter['id'].split(',');
               console.log('group related information received..');
               var siteList={};
               var i =0, len = tArr.length;
               for(; i < len; i++){
                   var tDeviceObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id' : tArr[i] });
                   var siteId = tDeviceObj[0].siteId;
                   var siteValue =SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id': siteId});
                   var id =tArr[i];
                   if(siteValue.length>0) {
                   siteList[id] = siteValue;
                   } else {

                   siteList[id] =[{}];
                   }
               }
            return   siteList;
           }
           return [];
        },*/
        getSiteDetailsForNetworkDevice: function(urlAction){
            if(urlAction.filter['id'] != undefined && urlAction.filter['id'] != '') {
                var tArr = urlAction.filter['id'].split(',');
                console.log('group related information received..');
                var siteList={};
                var i =0, len = tArr.length;
                for(; i < len; i++){
                    var tDeviceObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id' : tArr[i] });
                    if(tDeviceObj.length>0) {
                        var siteId = tDeviceObj[0].siteId;
                        var siteValue =SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id': siteId});
                        var id =tArr[i];
                        if(siteValue.length>0) {
                            siteList[id] = siteValue;
                        } else {
                            siteList[id] =[{}];
                        }
                    } else {
                        siteList[id] =[{}];
                    }
                }
                return   siteList;
           }
           return [];
        },

        updateSite :function (urlAction) {
            var siteId = urlAction.restPayload.id;
            var resultObj = SimTaskDataGenerator.createTask('Grouping Service', { id: siteId });
            var reqObject = urlAction.restPayload;
            var record = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id': siteId});

            var tSite = JSON.parse(JSON.stringify( record[0] ));
            var sysSettings = tSite.additionalInfo[0];
            tSite.id = siteId;
            tSite.parentId = urlAction.restPayload.parentId;
            tSite.name = urlAction.restPayload.name;
            tSite.additionalInfo = urlAction.restPayload.additionalInfo;
            tSite.groupNameHierarchy  = urlAction.restPayload.groupNameHierarchy;
            if(tSite.additionalInfo && tSite.additionalInfo.length>0 && tSite.additionalInfo[0].nameSpace!="System Settings") {
                tSite.additionalInfo.splice(0, 0, sysSettings);
            }
            SimLokiDatabaseActions.update('site', tSite);
            SimNetworkDeviceData.updateMemberInfoForAllSites();
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });

            return resultObj;
        },

        deleteSubChild : function (parentId) {
            var parentIdArray =[];
            var i, j;
            for(i=0;i< parentId.length;i++)  {
                var deleteObj = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'parentId': parentId[i]});

                for(j=0;j<deleteObj.length;j++)   {
                    parentIdArray.push(deleteObj[j].id);
                    SimLokiDatabaseActions.delete('site', deleteObj[j]);
                 }
            }
            if(parentIdArray.length>0 ) {
                this.deleteSubChild(parentIdArray);
            }
           return;
        },

        childIsAvailable :function(id) {
            var childList = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'parentId': id});
            if(childList.length>0) {
                return true;
            }

            return false;
        },

        deleteSite : function (urlAction) {
            var id = urlAction.action.id;
            var resultObj = SimTaskDataGenerator.createTask('Grouping Service', { id: id });
            var deleteObj = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id': id});
            var idlist =[deleteObj[0].id];
            var childIsAvailable =this.childIsAvailable(id);
            if(childIsAvailable==true) {
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: true ,errorCode:"NCGR10012",failureReason:"NCGR10012: Group cannot be deleted as there are child groups.Please delete them first."})
            } else {
                SimLokiDatabaseActions.delete('site', deleteObj);
                // this.deleteSubChild(idlist);
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
            }
            resultObj.statusCode = 202;
            return resultObj;
        },

        getCount : function(){
            return SimLokiDatabaseActions.getAll("site").length;
        },

        getWirelessSites : function(urlAction) {
            var wirelessSites=[];
            var serviceName = 'site';
            var tObjs = SimLokiDatabaseActions.getAll(serviceName);
           if(tObjs && tObjs.length > 0) {
                for(var index = 0; index < tObjs.length; index++) {
                    if(tObjs[index].additionalInfo && tObjs[index].additionalInfo.length > 0) {
                        for(var secondIndex = 0; secondIndex < tObjs[index].additionalInfo.length; secondIndex++) {
                            if(tObjs[index].additionalInfo[secondIndex].nameSpace ==
                                   urlAction.filter['additionalInfo.nameSpace']) {
                                if(tObjs[index].additionalInfo[secondIndex].attributes.primaryWlc ==
                                    urlAction.filter['additionalInfo.attributes.primaryWlc']) {
                                    wirelessSites.push(tObjs[index]);
                                    break;
                                }
                            }
                        }
                    }
                }
            }
            return urlAction.action.count ? wirelessSites.length : wirelessSites;
        },

        getFloorParent :function(urlAction) {
            var siteId = urlAction.action.id;
            var record = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id': siteId});
            var parentGroupId;
            if(record.length == 0) {
                //swarup -- FIXME remove this code asap.. ,static floor parent id
                //Assurance floor related data is static, issues in assurance to work this code, need to fix..
                var obj = SimLokiDatabaseActions.getFilteredRecordHandler("site", {'name' : 'Flr-SJC1-1' });
                parentGroupId = obj[0].parentId;
            } else {
                parentGroupId =record[0].parentId;
            }

            var parentObject = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id': parentGroupId});
            return parentObject;
        }
    };

    function getGroupNameHierarchy(siteName, parentId){
        var globalParentId = DefaultConfig.DEFAULT_UUID.GlobalGroup;
        var groupNameHierarchy = 'Global';
        var serviceName = 'site';
        if(parentId == globalParentId){
            groupNameHierarchy = groupNameHierarchy + '/' + siteName;
        } else {
            var tObj = SimLokiDatabaseActions.getAll(serviceName);
            var siteMap = new Object();
            for(var i =0, len = tObj.length; i < len; i++){
                 siteMap[tObj[i].id] = { name: tObj[i].name, parentId: tObj[i].parentId};
            }

            var tParentId = parentId;
            var tGroup = [siteName];
            while(tParentId != globalParentId){
                if(siteMap[tParentId] != undefined){
                    tGroup.push(siteMap[tParentId].name);
                    tParentId = siteMap[tParentId].parentId;
                }
            }

            for(i = tGroup.length - 1; i >= 0; i--){
                groupNameHierarchy = groupNameHierarchy + '/' + tGroup[i];
            }
        }
        return groupNameHierarchy;
    }
    function getGroupHierarchy(siteId, parentId){
        var globalParentId = DefaultConfig.DEFAULT_UUID.GlobalGroup;
        var groupHierarchy = globalParentId;
        var serviceName = 'site';
        if(parentId == globalParentId){
            groupHierarchy = parentId + '/' + siteId;
        } else {
            var tObj = SimLokiDatabaseActions.getAll(serviceName);
            var siteMap = new Object();
            for(var i =0, len = tObj.length; i < len; i++){
                siteMap[tObj[i].id] = {id: tObj[i].id, parentId: tObj[i].parentId};   
            }
            var tParentId = parentId;
            var tGroup = [siteId];
            while(tParentId != globalParentId){
                if(siteMap[tParentId] != undefined){
                    tGroup.push(siteMap[tParentId].id);
                    tParentId = siteMap[tParentId].parentId;
                }
            }
            for(i = tGroup.length - 1; i >= 0; i--){
                groupHierarchy = groupHierarchy + '/' + tGroup[i];
            }
        }
        return groupHierarchy
    }


    
});
