define({
    "Job_Template": [{
      "name":"Campus",
      "taskId":"1512731154810",
      "startTime":"1512731154812",
      "endTime":"1512731280417",
      "jobStatus":"SUCCESS",
      "discoveryid":0
    }]
});
