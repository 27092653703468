var Handlebars = require("../../../node_modules/.pnpm/engci-maven.cisco.com+handlebars@4.7.7/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul class='dropdown-menu dropdown-menu-right settings'>\n    <li class='dropdown-header apic-em menu-system'><"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"tools") || (depth0 != null ? lookupProperty(depth0,"tools") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tools","hash":{},"data":data,"loc":{"start":{"line":2,"column":53},"end":{"line":2,"column":62}}}) : helper)))
    + "</li>\n</ul>";
},"useData":true});