define({
  "HOST_LISTING":{
     "version":"1.0",
     "response":[
      {
        "id": "18:9C:5D:21:F5:15",
        "connectionStatus": "CONNECTED",
        "hostType": "WIRED",
        "userId": null,
        "hostName": "SEP189C5D21F515",
        "hostOs": null,
        "hostVersion": null,
        "subType": "IP_PHONE",
        "lastUpdated": 1549446660000,
        "healthScore": [
          {
            "healthType": "OVERALL",
            "reason": "",
            "score": 10.0
          },
          {
            "healthType": "ONBOARDED",
            "reason": "",
            "score": 4.0
          },
          {
            "healthType": "CONNECTED",
            "reason": "",
            "score": 6.0
          }
        ],
        "hostMac": "18:9C:5D:21:F5:15",
        "hostIpV4": "10.16.32.41",
        "hostIpV6": [
          "2001:db8:abcd:0012::0"
        ],
        "authType": null,
        "vlanId": "1023",
        "vnid": null,
        "ssid": null,
        "frequency": null,
        "channel": null,
        "apGroup": null,
        "location": "San Jose/Campus_Bldg_23/DNA-DEMO-SUIT2",
        "clientConnection": "Cat9K-Edge-01.cisco.local",
        "connectedDevice": [

        ],
        "issueCount": 0,
        "rssi": "0.0",
        "avgRssi": "0.0",
        "snr": "0.0",
        "avgSnr": "0.0",
        "rssiIsInclude": "true",
        "rssiThreshold": "-72.0",
	    "snrIsInclude": "true",
        "snrThreshold": "9.0",
        "dataRate": "0.0",
        "txBytes": "61748.0",
        "rxBytes": "10307.0",
        "dnsSuccess": "",
        "dnsFailure": "",
        "onboarding": {
          "averageRunDuration": "0.0",
          "maxRunDuration": "0.0",
          "averageAssocDuration": "0.0",
          "maxAssocDuration": "0.0",
          "averageAuthDuration": "0.0",
          "maxAuthDuration": "0.0",
          "averageDhcpDuration": "0.0",
          "maxDhcpDuration": "0.0",
          "aaaServerIp": "10.42.3.31",
          "dhcpServerIp": "10.13.4.2",
          "authDoneTime": 0,
          "assocDoneTime": 0,
          "dhcpDoneTime": 0,
          "assocRootcauseList": [

          ],
          "aaaRootcauseList": [

          ],
          "dhcpRootcauseList": [

          ],
          "otherRootcauseList": [

          ]
        },
        "clientType": "OLD",
        "onboardingTime": 0,
        "port": "GigabitEthernet1/0/22",
        "iosCapable": false,
        "usage": 72055.0,
        "linkSpeed": 1.0E+9,
        "linkThreshold": "I_1.0",
        "remoteEndDuplexMode": "Full Duplex",
        "txLinkError": 0.0,
        "rxLinkError": 0.0,
        "txRate": 284.89434345298514,
        "rxRate": 47.55467380271293,
        "versionTime": 1553064540000
      },
      {
        "id": "18:9C:5D:21:F5:17",
        "connectionStatus": "CONNECTED",
        "hostType": "WIRED",
        "userId": "clientIpPhone",
        "hostName": "SEP190C5D21F515",
        "hostOs": null,
        "hostVersion": null,
        "subType": "IP_PHONE",
        "lastUpdated": 1549446660000,
        "healthScore": [
          {
            "healthType": "OVERALL",
            "reason": "",
            "score": 10.0
          },
          {
            "healthType": "ONBOARDED",
            "reason": "",
            "score": 4.0
          },
          {
            "healthType": "CONNECTED",
            "reason": "",
            "score": 6.0
          }
        ],
        "hostMac": "18:9C:5D:21:F5:17",
        "hostIpV4": "10.16.32.43",
        "hostIpV6": [
          "2001:db8:abcd:0014::0"
        ],
        "authType": null,
        "vlanId": "1023",
        "vnid": null,
        "ssid": null,
        "frequency": null,
        "channel": null,
        "apGroup": null,
        "location": "San Jose/Campus_Bldg_23/DNA-DEMO-SUIT2",
        "clientConnection": "Cat9K-Edge-01.cisco.local",
        "connectedDevice": [

        ],
        "issueCount": 0,
        "rssi": "0.0",
        "avgRssi": "0.0",
        "snr": "0.0",
        "avgSnr": "0.0",
        "rssiIsInclude": "true",
        "rssiThreshold": "-72.0",
	    "snrIsInclude": "true",
        "snrThreshold": "9.0",
        "dataRate": "0.0",
        "txBytes": "61748.0",
        "rxBytes": "10307.0",
        "dnsSuccess": "",
        "dnsFailure": "",
        "onboarding": {
          "averageRunDuration": "1000.0",
           "maxRunDuration": "1000.0",
          "averageAssocDuration": "440.0",
          "maxAssocDuration": "443.0",
          "averageAuthDuration": "443.0",
          "maxAuthDuration": "443.0",
          "averageDhcpDuration": "370.0",
          "maxDhcpDuration": "370.0",
          "aaaServerIp": "10.42.3.31",
          "dhcpServerIp": "10.13.4.2",
          "authDoneTime": 0,
          "assocDoneTime": 0,
          "dhcpDoneTime": 0,
          "assocRootcauseList": [

          ],
          "aaaRootcauseList": [

          ],
          "dhcpRootcauseList": [

          ],
          "otherRootcauseList": [

          ]
        },
        "clientType": "OLD",
        "onboardingTime": 0,
        "port": "GigabitEthernet1/0/22",
        "iosCapable": false,
        "usage": 72055.0,
        "linkSpeed": 1.0E+9,
        "linkThreshold": "I_1.0",
        "remoteEndDuplexMode": "Full Duplex",
        "txLinkError": 0.0,
        "rxLinkError": 0.0,
        "txRate": 284.89434345298514,
        "rxRate": 47.55467380271293,
        "versionTime": 1553064540000
      },
      {
        "id": "18:9C:5D:21:F5:19",
        "connectionStatus": "CONNECTED",
        "hostType": "WIRED",
        "userId": "ipPhone",
        "hostName": "SEP191C5D21F520",
        "hostOs": null,
        "hostVersion": null,
        "subType": "IP_PHONE",
        "lastUpdated": 1549446660000,
        "healthScore": [
          {
            "healthType": "OVERALL",
            "reason": "",
            "score": 10.0
          },
          {
            "healthType": "ONBOARDED",
            "reason": "",
            "score": 4.0
          },
          {
            "healthType": "CONNECTED",
            "reason": "",
            "score": 6.0
          }
        ],
        "hostMac": "18:9C:5D:21:F5:19",
        "hostIpV4": "10.16.32.45",
        "hostIpV6": [
          "2001:db8:abcd:0014::1"
        ],
        "authType": null,
        "vlanId": "1023",
        "vnid": null,
        "ssid": null,
        "frequency": null,
        "channel": null,
        "apGroup": null,
        "location": "San Jose/Campus_Bldg_23/DNA-DEMO-SUIT2",
        "clientConnection": "Cat9K-Edge-01.cisco.local",
        "connectedDevice": [

        ],
        "issueCount": 0,
        "rssi": "0.0",
        "avgRssi": "0.0",
        "snr": "0.0",
        "avgSnr": "0.0",
        "dataRate": "0.0",
        "rssiIsInclude": "true",
        "rssiThreshold": "-72.0",
	    "snrIsInclude": "true",
        "snrThreshold": "9.0",
        "txBytes": "61748.0",
        "rxBytes": "10307.0",
        "dnsSuccess": "",
        "dnsFailure": "",
        "onboarding": {
          "averageRunDuration": "1000.0",
           "maxRunDuration": "1000.0",
          "averageAssocDuration": "440.0",
          "maxAssocDuration": "443.0",
          "averageAuthDuration": "443.0",
          "maxAuthDuration": "443.0",
          "averageDhcpDuration": "370.0",
          "maxDhcpDuration": "370.0",
          "aaaServerIp": "10.42.3.31",
          "dhcpServerIp": "10.13.4.2",
          "authDoneTime": 0,
          "assocDoneTime": 0,
          "dhcpDoneTime": 0,
          "assocRootcauseList": [

          ],
          "aaaRootcauseList": [

          ],
          "dhcpRootcauseList": [

          ],
          "otherRootcauseList": [

          ]
        },
        "clientType": "OLD",
        "onboardingTime": 0,
        "port": "GigabitEthernet1/0/22",
        "iosCapable": false,
        "usage": 72055.0,
        "linkSpeed": 1.0E+9,
        "linkThreshold": "I_1.0",
        "remoteEndDuplexMode": "Full Duplex",
        "txLinkError": 0.0,
        "rxLinkError": 0.0,
        "txRate": 284.89434345298514,
        "rxRate": 47.55467380271293,
        "versionTime": 1553064540000
      },
      {
           "id":"00:0C:29:2F:AC:34",
           "connectionStatus":"CONNECTED",
           "hostType":"WIRED",
           "userId":"becky",
           "hostName":"Becky-PC",
           "hostOs":"Windows",
           "hostVersion":null,
           "subType":"Microsoft-Workstation",
           "lastUpdated":1525152861987,
           "healthScore":[
              {
                 "healthType":"OVERALL",
                 "reason":"",
                 "score":10.0
              },
              {
                 "healthType":"ONBOARDED",
                 "reason":"",
                 "score":4.0
              },
              {
                 "healthType":"CONNECTED",
                 "reason":"",
                 "score":4.0
              }
           ],
           "hostMac":"00:0C:29:2F:AC:34",
           "hostIpV4":"10.31.100.12",
           "hostIpV6":[
             "2001:db8:abce:0012::0"
           ],
           "authType":null,
           "vlanId":"100",
           "ssid":null,
           "frequency":"0.0",
           "channel":null,
           "apGroup":"",
           "location":"Global/North America/USA/California/San Jose/SJC05",
           "clientConnection":"TO-3850-ACC-1.corp.local",
           "connectedDevice":[ ],
           "issueCount":0,
           "rssi":"0.0",
           "avgRssi":"0.0",
           "snr":"0.0",
           "avgSnr":"0.0",
           "rssiIsInclude": "true",
        "rssiThreshold": "-72.0",
	    "snrIsInclude": "true",
        "snrThreshold": "9.0",
           "dataRate":"0.0",
           "txBytes":"1397451.0",
           "rxBytes":"152703.0",
           "dnsSuccess":"-1.0",
           "dnsFailure":"-1.0",
           "onboarding": {
            "averageRunDuration": "1000.0",
            "maxRunDuration": "1000.0",
            "averageAssocDuration": "440.0",
            "maxAssocDuration": "443.0",
            "averageAuthDuration": "443.0",
            "maxAuthDuration": "443.0",
            "averageDhcpDuration": "370.0",
            "maxDhcpDuration": "370.0",
            "aaaServerIp": "10.13.1.113",
            "dhcpServerIp": "10.13.4.1",
            "authDoneTime": 1527204768355,
            "assocDoneTime": 1527204732979,
            "dhcpDoneTime": 1527204738372
          },
           "onboardingTime":1527165218208,
           "port":"GigabitEthernet1/0/1",
            "iosCapable": false,
            "usage": 1550155.0,
            "linkSpeed": 1.0E+9,
            "linkThreshold": "I_1.0",
            "remoteEndDuplexMode": "Full Duplex",
            "txLinkError": 0.0,
            "rxLinkError": 0.0,
            "txRate": 244.59389129833,
            "rxRate": 64.5469225800498,
             "versionTime": 1553064540000
        },
        {
           "id":"00:50:56:9A:3B:BE",
           "connectionStatus":"CONNECTED",
           "hostType":"WIRED",
           "userId":"brian.lara",
           "hostName":"DESKTOP-9GPLJB7",
           "hostOs":"IOS-X",
           "hostVersion":null,
           "subType":"Apple-Macbook-Pro",
           "lastUpdated":1524241464371,
           "healthScore":[
              {
                 "healthType":"OVERALL",
                 "reason":"",
                 "score":1.0
              },
              {
                 "healthType":"ONBOARDED",
                 "reason":"",
                 "score":1.0
              },
              {
                 "healthType":"CONNECTED",
                 "reason":"",
                 "score":1.0
              }
           ],
           "hostMac":"00:50:56:9A:3B:BE",
           "hostIpV4":"169.254.74.121",
           "hostIpV6":[
             "2001:db8:abcf:0012::0"
           ],
           "authType":null,
           "vlanId":"3999",
           "ssid":null,
           "frequency":"0.0",
           "channel":null,
           "apGroup":"",
           "location":"Global/North America/USA/California/San Jose/SJC05",
           "clientConnection":"LA1-9300-ACC-2.corp.local",
           "connectedDevice":[ ],
           "issueCount":0,
           "rssi":"0.0",
           "avgRssi":"0.0",
           "snr":"0.0",
           "avgSnr":"0.0",
           "rssiIsInclude": "true",
        "rssiThreshold": "-72.0",
	    "snrIsInclude": "true",
        "snrThreshold": "9.0",
           "dataRate":"0.0",
           "txBytes":"61748.0",
           "rxBytes":"10307.0",
           "dnsSuccess":"-1.0",
           "dnsFailure":"-1.0",
           "onboarding": {
             "averageRunDuration": "0.0",
             "maxRunDuration": "0.0",
             "averageAssocDuration": "6000.0",
             "maxAssocDuration": "6421.0",
             "averageAuthDuration": "6115.0",
             "maxAuthDuration": "6226.0",
             "averageDhcpDuration": "6105.0",
             "maxDhcpDuration": "6340.0",
             "aaaServerIp": "10.42.3.31",
             "dhcpServerIp": "10.13.4.1",
             "authDoneTime": 1527204768355,
             "assocDoneTime": 1527204732979,
             "dhcpDoneTime": 1527204738372,
              "maxAuthDurationDuration": "1.0"
           },
           "onboardingTime":1527169633409,
           "port":"GigabitEthernet1/0/22",
            "iosCapable": false,
            "usage": 72055.0,
            "linkSpeed": 1.0E+9,
            "linkThreshold": "I_1.0",
            "remoteEndDuplexMode": "Full Duplex",
            "txLinkError": 0.0,
            "rxLinkError": 0.0,
            "txRate": 290.6777117474038,
            "rxRate": 48.30421994117294,
             "versionTime": 1553064540000
        },
        {
           "id":"00:0C:29:A7:17:CA",
           "connectionStatus":"CONNECTED",
           "hostType":"WIRED",
           "userId":"dnac.ise",
           "hostName":"dnac-ise-01",
           "hostOs": "Windows",
           "hostVersion":null,
           "subType":"Microsoft-Workstation",
           "lastUpdated":1524242364841,
           "healthScore":[
              {
                 "healthType":"OVERALL",
                 "reason":"",
                 "score":10.0
              },
              {
                 "healthType":"ONBOARDED",
                 "reason":"",
                 "score":4.0
              },
              {
                 "healthType":"CONNECTED",
                 "reason":"",
                 "score":2.0
              }
           ],
           "hostMac":"00:0C:29:A7:17:CA",
           "hostIpV4":"192.168.139.168",
           "hostIpV6":[
             "2001:db8:abca:0012::0"
           ],
           "authType":null,
           "vlanId":"139",
           "ssid":null,
           "frequency":"0.0",
           "channel":null,
           "apGroup":"",
           "location":"Global/North America/USA/California/San Jose/SJC05",
           "clientConnection":"DC-SERVICES",
           "connectedDevice":[ ],
           "issueCount":0,
           "rssi":"0.0",
           "avgRssi":"0.0",
           "snr":"0.0",
           "avgSnr":"0.0",
           "rssiIsInclude": "true",
        "rssiThreshold": "-72.0",
	    "snrIsInclude": "true",
        "snrThreshold": "9.0",
           "dataRate":"0.0",
           "txBytes":"709890.0",
           "rxBytes":"278690.0",
           "dnsSuccess":"-1.0",
           "dnsFailure":"-1.0",
           "onboarding": {
             "averageRunDuration": "1261.0",
             "maxRunDuration": "618.0",
             "averageAssocDuration": "600.0",
             "maxAssocDuration": "618.0",
             "averageAuthDuration": "698.0",
             "maxAuthDuration": "605.0",
             "averageDhcpDuration": "614.0",
             "maxDhcpDuration": "612.0",
             "aaaServerIp": "10.13.1.113",
             "dhcpServerIp": "10.13.4.1",
             "authDoneTime": 1527204768355,
             "assocDoneTime": 1527204732979,
             "dhcpDoneTime": 1527204738372,
             "maxAuthDurationDuration": "1.0"
           },
           "onboardingTime":1527165218208,
           "port":"GigabitEthernet1/0/9",
            "iosCapable": false,
            "usage": 988580.0,
            "linkSpeed": 1.0E+9,
            "linkThreshold": "I_1.0",
            "remoteEndDuplexMode": "Full Duplex",
            "txLinkError": 0.0,
            "rxLinkError": 0.0,
            "txRate": 235.48347663094412,
            "rxRate": 0.2607880689458457,
             "versionTime": 1553064540000
        },
        {
           "id":"00:0C:29:BD:B6:89",
           "connectionStatus":"CONNECTED",
           "hostType":"WIRED",
           "userId":"la.print",
           "hostName":"la-print-01",
           "hostOs":"OS-X",
           "hostVersion":null,
           "subType":"Apple-Macbook-Pro",
           "lastUpdated":1525152861987,
           "healthScore":[
              {
                 "healthType":"OVERALL",
                 "reason":"",
                 "score":10.0
              },
              {
                 "healthType":"ONBOARDED",
                 "reason":"",
                 "score":4.0
              },
              {
                 "healthType":"CONNECTED",
                 "reason":"",
                 "score":6.0
              }
           ],
           "hostMac":"00:0C:29:BD:B6:89",
           "hostIpV4":"10.30.120.10",
           "hostIpV6":[
             "2001:db8:abcb:0012::0"
           ],
           "authType":null,
           "vlanId":"120",
           "ssid":null,
           "frequency":"0.0",
           "channel":null,
           "apGroup":"",
           "location":"Global/North America/USA/California/San Jose/SJC05",
           "clientConnection":"LA1-9300-ACC-3.corp.local",
           "connectedDevice":[

           ],
           "issueCount":0,
           "rssi":"0.0",
           "avgRssi":"0.0",
           "snr":"0.0",
           "avgSnr":"0.0",
           "rssiIsInclude": "true",
        "rssiThreshold": "-72.0",
	    "snrIsInclude": "true",
        "snrThreshold": "9.0",
           "dataRate":"0.0",
           "txBytes":"1.39362716E8",
           "rxBytes":"160.0",
           "dnsSuccess":"-1.0",
           "dnsFailure":"-1.0",
           "onboarding": {
             "averageRunDuration": "933.0",
             "maxRunDuration": "933.0",
             "averageAssocDuration": "411.0",
             "maxAssocDuration": "411.0",
             "averageAuthDuration": "490.0",
             "maxAuthDuration": "490.0",
             "averageDhcpDuration": "450.0",
             "maxDhcpDuration": "450.0",
             "aaaServerIp": "192.168.139.168",
             "dhcpServerIp": "10.13.4.2",
             "authDoneTime": 1527204768355,
             "assocDoneTime": 1527204732979,
             "dhcpDoneTime": 1527204738372,
             "maxAuthDurationDuration": "1.0"
           },
           "onboardingTime":1527169633409,
           "port":"GigabitEthernet1/0/37",
            "iosCapable": false,
            "usage": 139362876,
            "linkSpeed": 1.0E+9,
            "linkThreshold": "I_1.0",
            "remoteEndDuplexMode": "Full Duplex",
            "txLinkError": 0.0,
            "rxLinkError": 0.0,
            "txRate": 464.90410319661234,
            "rxRate": 0.5333155561481284,
             "versionTime": 1553064540000
        },
        {
           "id":"00:0C:29:84:08:FE",
           "connectionStatus":"CONNECTED",
           "hostType":"WIRED",
           "userId":"ingrid",
           "hostName":"Ingrid-PC",
           "hostOs":"Windows",
           "hostVersion":null,
           "subType":"Microsoft-Workstation",
           "lastUpdated":1525152861987,
           "healthScore":[
              {
                 "healthType":"OVERALL",
                 "reason":"",
                 "score":10.0
              },
              {
                 "healthType":"ONBOARDED",
                 "reason":"",
                 "score":4.0
              },
              {
                 "healthType":"CONNECTED",
                 "reason":"",
                 "score":6.0
              }
           ],
           "hostMac":"00:0C:29:84:08:FE",
           "hostIpV4":"10.31.100.11",
           "hostIpV6":[
             "2001:db8:abab:0012::0"
           ],
           "authType":null,
           "vlanId":"100",
           "ssid":null,
           "frequency":"0.0",
           "channel":null,
           "apGroup":"",
           "location":"Global/North America/USA/California/San Jose/SJC05",
           "clientConnection":"TO-3850-ACC-1.corp.local",
           "connectedDevice":[

           ],
           "issueCount":0,
           "rssi":"0.0",
           "avgRssi":"0.0",
           "snr":"0.0",
           "avgSnr":"0.0",
           "rssiIsInclude": "true",
        "rssiThreshold": "-72.0",
	    "snrIsInclude": "true",
        "snrThreshold": "9.0",
           "dataRate":"0.0",
           "txBytes":"1.6524067E7",
           "rxBytes":"3.3397059E7",
           "dnsSuccess":"-1.0",
           "dnsFailure":"-1.0",
           "onboarding": {
             "averageRunDuration": "989.0",
             "maxRunDuration": "989.0",
             "averageAssocDuration": "100.0",
             "maxAssocDuration": "100.0",
             "averageAuthDuration": "488.0",
             "maxAuthDuration": "488.0",
             "averageDhcpDuration": "300.0",
             "maxDhcpDuration": "200.0",
             "aaaServerIp": "192.168.139.168",
             "dhcpServerIp": "10.13.4.2",
             "authDoneTime": 1527204768355,
             "assocDoneTime": 1527204732979,
             "dhcpDoneTime": 1527204738372,
              "maxAuthDurationDuration": "1.0"
           },
           "onboardingTime":1527165218208,
           "port":"GigabitEthernet1/0/32",
           "iosCapable": false,
            "usage": 49921126,
            "linkSpeed": 1.0E+9,
            "linkThreshold": "I_1.0",
            "remoteEndDuplexMode": "Full Duplex",
            "txLinkError": 0.0,
            "rxLinkError": 0.0,
            "txRate": 55078.387387087096,
            "rxRate": 111319.82100596647,
             "versionTime": 1553064540000
        },
        {
           "id":"00:0C:29:77:FC:99",
           "connectionStatus":"CONNECTED",
           "hostType":"WIRED",
           "userId":"roger",
           "hostName":"Roger-PC",
           "hostOs":"OS-X",
           "hostVersion":null,
           "subType":"Apple-Macbook-Pro",
           "lastUpdated":1524241464371,
           "healthScore":[
              {
                 "healthType":"OVERALL",
                 "reason":"",
                 "score":10.0
              },
              {
                 "healthType":"ONBOARDED",
                 "reason":"",
                 "score":4.0
              },
              {
                 "healthType":"CONNECTED",
                 "reason":"",
                 "score":6.0
              }
           ],
           "hostMac":"00:0C:29:77:FC:99",
           "hostIpV4":"10.30.100.23",
           "hostIpV6":[
             "2001:db8:abac:0012::0"
           ],
           "authType":null,
           "vlanId":"100",
           "ssid":null,
           "frequency":"0.0",
           "channel":null,
           "apGroup":"",
           "location":"USA/SM",
           "clientConnection":"LA2-3850-ACC-1.corp.local",
           "connectedDevice":[

           ],
           "issueCount":0,
           "rssi":"0.0",
           "avgRssi":"0.0",
           "snr":"0.0",
           "avgSnr":"0.0",
           "dataRate":"0.0",
           "rssiIsInclude": "true",
        "rssiThreshold": "-72.0",
	    "snrIsInclude": "true",
        "snrThreshold": "9.0",
           "txBytes":"709890.0",
           "rxBytes":"278690.0",
           "dnsSuccess":"-1.0",
           "dnsFailure":"-1.0",
           "onboarding": {
             "averageRunDuration": "608.0",
             "maxRunDuration": "608.0",
             "averageAssocDuration": "2.0",
             "maxAssocDuration": "2.0",
             "averageAuthDuration": "405.0",
             "maxAuthDuration": "405.0",
             "averageDhcpDuration": "400.0",
             "maxDhcpDuration": "400.0",
             "aaaServerIp": "10.42.3.31",
             "dhcpServerIp": "10.13.4.2",
             "authDoneTime": 1527204768355,
             "assocDoneTime": 1527204732979,
             "dhcpDoneTime": 1527204738372
           },

           "onboardingTime":1527169633409,
           "port":"GigabitEthernet1/0/2",
           "iosCapable": false,
            "usage": 324923506,
            "linkSpeed": 1.0E+9,
            "linkThreshold": "I_1.0",
            "remoteEndDuplexMode": "Full Duplex",
            "txLinkError": 5.0,
            "rxLinkError": 3.0,
            "txRate": 195.85209196230267,
            "rxRate": 0.3841767212917942,
             "versionTime": 1553064540000
        },
        {
          "id": "00:22:BD:DC:3D:49",
          "connectionStatus": "CONNECTED",
          "hostType": "WIRED",
          "userId": null,
          "hostName": "0022BDDC3D49",
          "hostOs": "VIDEO_SURVEILLANCE_IP_CAMERA",
          "hostVersion": null,
          "subType": "VIDEO_SURVEILLANCE_IP_CAMERA",
          "firmwareVersion": null,
          "deviceVendor": null,
          "deviceForm": null,
          "salesCode": null,
          "countryCode": null,
          "lastUpdated": 1589274660000,
          "healthScore": [
            {
              "healthType": "OVERALL",
              "reason": "",
              "score": 10.0
            },
            {
              "healthType": "ONBOARDED",
              "reason": "",
              "score": 4.0
            },
            {
              "healthType": "CONNECTED",
              "reason": "",
              "score": 6.0
            }
          ],
          "hostMac": "00:22:BD:DC:3D:49",
          "hostIpV4": "192.168.0.100",
          "authType": null,
          "vlanId": 1,
          "vnid": null,
          "upnId": null,
          "upnName": null,
          "ssid": null,
          "frequency": null,
          "channel": "0.0",
          "apGroup": null,
          "location": "RAJ-SJC20-nyq1",
          "clientConnection": "stack-nyq1",
          "connectedDevice": [
            {
              "type": null,
              "name": null,
              "id": "214752f0-f9bf-4071-847d-f0507ca166a0",
              "ip address": null,
              "band": null
            }
          ],
          "issueCount": 0,
          "rssi": "0.0",
          "rssiThreshold": "-72.0",
          "rssiIsInclude": "true",
          "avgRssi": "0.0",
          "snr": "0.0",
          "snrThreshold": "9.0",
          "snrIsInclude": "true",
          "avgSnr": "0.0",
          "dataRate": "0.0",
          "txBytes": "19741.0",
          "rxBytes": "143941.0",
          "dnsResponse": "0.0",
          "dnsRequest": "0.0",
          "onboarding": {
            "averageRunDuration": "608.0",
             "maxRunDuration": "608.0",
             "averageAssocDuration": "2.0",
             "maxAssocDuration": "2.0",
             "averageAuthDuration": "405.0",
             "maxAuthDuration": "405.0",
             "averageDhcpDuration": "400.0",
             "maxDhcpDuration": "400.0",
             "aaaServerIp": "10.42.3.31",
             "dhcpServerIp": "10.13.4.2",
             "authDoneTime": 1527204768355,
             "assocDoneTime": 1527204732979,
             "dhcpDoneTime": 1527204738372
          },
          "clientType": "OLD",
          "onboardingTime": 0,
          "port": "GigabitEthernet3/0/22",
          "iosCapable": false,
          "usage": 163682.0,
          "linkSpeed": 1.0E+8,
          "linkThreshold": "I_1.0",
          "remoteEndDuplexMode": "Full Duplex",
          "txLinkError": 0.0,
          "rxLinkError": 0.0,
          "txRate": 526.4266666666666,
          "rxRate": 3838.4266666666667,
          "rxRetryPct": "0.0",
          "versionTime": 1588788720000,
          "dot11Protocol": "Unclassified",
          "slotId": 0,
          "dot11ProtocolCapability": "Unclassified"
        },
        {
          "id": "F0:25:72:78:8A:69",
          "connectionStatus": "CONNECTED",
          "hostType": "WIRED",
          "userId": null,
          "hostName": "SEPF02572788A69",
          "hostOs": "IP_PHONE",
          "hostVersion": null,
          "subType": "IP_PHONE",
          "firmwareVersion": null,
          "deviceVendor": null,
          "deviceForm": null,
          "salesCode": null,
          "countryCode": null,
          "lastUpdated": 1589277000000,
          "healthScore": [
            {
              "healthType": "OVERALL",
              "reason": "",
              "score": -2.0
            },
            {
              "healthType": "ONBOARDED",
              "reason": "",
              "score": 0.0
            },
            {
              "healthType": "CONNECTED",
              "reason": "",
              "score": 0.0
            }
          ],
          "hostMac": "F0:25:72:78:8A:69",
          "hostIpV4": null,
          "authType": null,
          "vlanId": null,
          "vnid": null,
          "upnId": null,
          "upnName": null,
          "ssid": null,
          "frequency": null,
          "channel": "0.0",
          "apGroup": null,
          "location": "lema-24",
          "clientConnection": "9410-poe",
          "connectedDevice": [
            {
              "type": null,
              "name": null,
              "id": "862674d1-8f8b-4796-9127-cdfce3225485",
              "ip address": null,
              "band": null
            }
          ],
          "issueCount": 0,
          "rssi": "0.0",
          "rssiThreshold": "-72.0",
          "rssiIsInclude": "true",
          "avgRssi": "0.0",
          "snr": "0.0",
          "snrThreshold": "9.0",
          "snrIsInclude": "true",
          "avgSnr": "0.0",
          "dataRate": "0.0",
          "txBytes": "0.0",
          "rxBytes": "0.0",
          "dnsResponse": "0.0",
          "dnsRequest": "0.0",
          "onboarding": {
            "averageRunDuration": "989.0",
             "maxRunDuration": "989.0",
             "averageAssocDuration": "100.0",
             "maxAssocDuration": "100.0",
             "averageAuthDuration": "488.0",
             "maxAuthDuration": "488.0",
             "averageDhcpDuration": "300.0",
             "maxDhcpDuration": "200.0",
             "aaaServerIp": "192.168.139.168",
             "dhcpServerIp": "10.13.4.2",
             "authDoneTime": 1527204768355,
             "assocDoneTime": 1527204732979,
             "dhcpDoneTime": 1527204738372,
            "dhcpRootcauseList": [
              "dhcpRootCause"
            ],
            "latestRootCauseList": [
              "dhcpRootCause"
            ]
          },
          "clientType": "OLD",
          "onboardingTime": 0,
          "port": "GigabitEthernet3/0/17",
          "iosCapable": false,
          "usage": 0.0,
          "linkSpeed": 1.0E+9,
          "linkThreshold": "I_1.0",
          "remoteEndDuplexMode": "Full Duplex",
          "txLinkError": 0.0,
          "rxLinkError": 0.0,
          "txRate": 39081.50896925414,
          "rxRate": 79525.3265465293,
          "rxRetryPct": "0.0",
          "versionTime": 1588788720000,
          "dot11Protocol": "Unclassified",
          "slotId": 0,
          "dot11ProtocolCapability": "Unclassified"
    }

     ],
     "page":{
        "pageSize":6,
        "offset":0,
        "totalCount":6
     }
  }
});
