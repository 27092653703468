define({
  "Template":{
        "dnsResponseCount": [
          0.0
        ],
        "modificationtime": [
          1.5372435E12
        ],
        "__timestamp": [
          1.53724368E12
        ],
        "NEW": [
          0.0
        ],
        "channel": [
          11.0
        ],
        "averageHealthScore": [
          1.0
        ],
        "rxBytes": [
          0.0
        ],
        "connectedDeviceId": [
          "8870764e-d52a-4a14-8c8b-bd905d2aa693"
        ],
        "ssid": [
          "AMS-Corporate1"
        ],
        "dnsRequestCount": [
          0.0
        ],
        "apName": [
          "AMS-AP3802-14"
        ],
        "frequency": [
          2.4
        ],
        "disconnected": [
          0.0
        ],
        "channelWidth": [
          "20"
        ],
        "rssi": [
          0.0
        ],
        "dataRate": [
          0.0
        ],
        "txBytes": [
          0.0
        ],
        "snr": [
          0.0
        ],
        "port": [
           "GigabitEthernet1/0/10"
        ],
        "txLinkError": [
           0.0
        ],
        "rxLinkError": [
           0.0
        ],
        "linkSpeed": [
            1.0E8
        ],
         "rxRate": [
               7.870497662908991
        ],
         "txRate": [
                137.53856963920202
        ],
        "remoteEndDuplexMode": [
           "Full Duplex"
        ],
        "vlan": [
            "119"
        ],
        "linkThreshold": ["I_1.0"],
        "rxRetries": [-1],
        "rxRetryPct": [-1],
        "rxPackets": [70]

      }
});