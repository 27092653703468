define({
    "WorkFlow_Template": {
        "workflow": [
            {
                "id": "92449859-a90f-4ea8-b715-55c74ab116bc",
                "name": "rmaWorkflowDesign",
                "workflowStatus": "SUCCESS",
                "startTime": "2019-07-29, 22:38:19.907",
                "endTime": "2019-07-29, 22:45:44.898",
                "duration": "0 hrs 7 min 24 secs 991 msc",
                "owner": "config-archive-service",
                "beginSteps": [
                    "pnpClaimDeviceStep"
                ],
                "steps": [
                    {
                        "name": "pnpClaimDeviceStep",
                        "stepStatus": "SUCCESS",
                        "beginTasks": [
                            "Claiming(PnP) Replacement Device"
                        ],
                        "step.next": [
                            {
                                "name": "swimDistributionStep"
                            }
                        ],
                        "tasks": [
                            {
                                "name": "Claiming(PnP) Replacement Device",
                                "type": "pnpClaimDeviceAdaptor",
                                "targets": [
                                    {
                                        "id": "88a02158-7ed8-4354-a03d-aea2c584c694",
                                        "targetStatus": "SUCCESS",
                                        "startTime": "2019-07-29, 22:38:20.809",
                                        "endTime": "2019-07-29, 22:41:22.332",
                                        "duration": "0 hrs 3 min 1 secs 523 msc",
                                        "type": "MANAGED_DEVICE_UUID",
                                        "onFailure": "ABORT_ON_ERROR",
                                        "statusMessage": "PnP device claim executed successfully",
                                        "childWorkflows": [],
                                        "displayName": "0"
                                    }
                                ],
                                "task.next": []
                            }
                        ]
                    },
                    {
                        "name": "swimDistributionStep",
                        "stepStatus": "SUCCESS",
                        "beginTasks": [
                            "Distributing Device Software Image to Replacement Device"
                        ],
                        "step.next": [
                            {
                                "name": "swimActivationStep"
                            }
                        ],
                        "tasks": [
                            {
                                "name": "Distributing Device Software Image to Replacement Device",
                                "type": "rmaSwimImageDistributionAdapter",
                                "targets": [
                                    {
                                        "id": "88a02158-7ed8-4354-a03d-aea2c584c694",
                                        "targetStatus": "SUCCESS",
                                        "startTime": "2019-07-29, 22:41:23.055",
                                        "endTime": "2019-07-29, 22:41:24.914",
                                        "duration": "0 hrs 0 min 1 secs 859 msc",
                                        "type": "MANAGED_DEVICE_UUID",
                                        "onFailure": "ABORT_ON_ERROR",
                                        "statusMessage": "Faulty and Replacement device have same Software Image and hence no distribution required",
                                        "childWorkflows": [],
                                        "displayName": "0"
                                    }
                                ],
                                "task.next": []
                            }
                        ]
                    },
                    {
                        "name": "swimActivationStep",
                        "stepStatus": "SUCCESS",
                        "beginTasks": [
                            "Activating Device Software Image to Replacement Device"
                        ],
                        "step.next": [
                            {
                                "name": "licenseStep"
                            }
                        ],
                        "tasks": [
                            {
                                "name": "Activating Device Software Image to Replacement Device",
                                "type": "rmaSwimImageActivationAdapter",
                                "targets": [
                                    {
                                        "id": "88a02158-7ed8-4354-a03d-aea2c584c694",
                                        "targetStatus": "SUCCESS",
                                        "startTime": "2019-07-29, 22:41:25.055",
                                        "endTime": "2019-07-29, 22:41:32.046",
                                        "duration": "0 hrs 0 min 6 secs 991 msc",
                                        "type": "MANAGED_DEVICE_UUID",
                                        "onFailure": "ABORT_ON_ERROR",
                                        "statusMessage": "Activation skipped for device IP10.254.10.102 as running image and upgarde image version is same",
                                        "childWorkflows": [],
                                        "displayName": "0"
                                    }
                                ],
                                "task.next": []
                            }
                        ]
                    },
                    {
                        "name": "licenseStep",
                        "stepStatus": "SUCCESS",
                        "beginTasks": [
                            "Deploying License to Replacement Device"
                        ],
                        "step.next": [
                            {
                                "name": "provisionConfigArchiveVlanStep"
                            }
                        ],
                        "tasks": [
                            {
                                "name": "Deploying License to Replacement Device",
                                "type": "provisionLicenseAdaptor",
                                "targets": [
                                    {
                                        "id": "88a02158-7ed8-4354-a03d-aea2c584c694",
                                        "targetStatus": "SUCCESS",
                                        "startTime": "2019-07-29, 22:41:32.241",
                                        "endTime": "2019-07-29, 22:41:42.643",
                                        "duration": "0 hrs 0 min 10 secs 402 msc",
                                        "type": "MANAGED_DEVICE_UUID",
                                        "onFailure": "ABORT_ON_ERROR",
                                        "statusMessage": "License activated on device successfully.",
                                        "childWorkflows": [],
                                        "displayName": "0"
                                    }
                                ],
                                "task.next": []
                            }
                        ]
                    },
                    {
                        "name": "provisionConfigArchiveVlanStep",
                        "stepStatus": "SUCCESS",
                        "beginTasks": [
                            "Provisioning VLAN Configuration to Replacement Device"
                        ],
                        "step.next": [
                            {
                                "name": "provisionConfigArchiveStartupStep"
                            }
                        ],
                        "tasks": [
                            {
                                "name": "Provisioning VLAN Configuration to Replacement Device",
                                "type": "provisionConfigArchiveAdaptor",
                                "targets": [
                                    {
                                        "id": "88a02158-7ed8-4354-a03d-aea2c584c694",
                                        "targetStatus": "SUCCESS",
                                        "startTime": "2019-07-29, 22:41:42.934",
                                        "endTime": "2019-07-29, 22:41:44.926",
                                        "duration": "0 hrs 0 min 1 secs 992 msc",
                                        "type": "MANAGED_DEVICE_UUID",
                                        "onFailure": "ABORT_ON_ERROR",
                                        "statusMessage": "Config Archive Provisioning Successful",
                                        "childWorkflows": [],
                                        "displayName": "0"
                                    }
                                ],
                                "task.next": []
                            }
                        ]
                    },
                    {
                        "name": "provisionConfigArchiveStartupStep",
                        "stepStatus": "SUCCESS",
                        "beginTasks": [
                            "Provisioning Startup Configuration to Replacement Device"
                        ],
                        "step.next": [
                            {
                                "name": "deviceReloadStep"
                            }
                        ],
                        "tasks": [
                            {
                                "name": "Provisioning Startup Configuration to Replacement Device",
                                "type": "provisionConfigArchiveAdaptor",
                                "targets": [
                                    {
                                        "id": "88a02158-7ed8-4354-a03d-aea2c584c694",
                                        "targetStatus": "SUCCESS",
                                        "startTime": "2019-07-29, 22:41:45.047",
                                        "endTime": "2019-07-29, 22:41:47.926",
                                        "duration": "0 hrs 0 min 2 secs 879 msc",
                                        "type": "MANAGED_DEVICE_UUID",
                                        "onFailure": "ABORT_ON_ERROR",
                                        "statusMessage": "Config Archive Provisioning Successful",
                                        "childWorkflows": [],
                                        "displayName": "0"
                                    }
                                ],
                                "task.next": []
                            }
                        ]
                    },
                    {
                        "name": "deviceReloadStep",
                        "stepStatus": "SUCCESS",
                        "beginTasks": [
                            "Getting Reload Command From Replacement Device"
                        ],
                        "step.next": [
                            {
                                "name": "deviceReachabilityCheckStep"
                            }
                        ],
                        "tasks": [
                            {
                                "name": "Reloading Replacement Device",
                                "type": "reloadDeviceAdaptor",
                                "targets": [
                                    {
                                        "id": "88a02158-7ed8-4354-a03d-aea2c584c694",
                                        "targetStatus": "SUCCESS",
                                        "startTime": "2019-07-29, 22:41:48.348",
                                        "endTime": "2019-07-29, 22:42:22.775",
                                        "duration": "0 hrs 0 min 34 secs 427 msc",
                                        "type": "MANAGED_DEVICE_UUID",
                                        "onFailure": "ABORT_ON_ERROR",
                                        "statusMessage": "Device Reload started Successfully.",
                                        "childWorkflows": [],
                                        "displayName": "0"
                                    }
                                ],
                                "task.next": []
                            },
                            {
                                "name": "Getting Reload Command From Replacement Device",
                                "type": "getReloadCommandAdaptor",
                                "targets": [
                                    {
                                        "id": "88a02158-7ed8-4354-a03d-aea2c584c694",
                                        "targetStatus": "SUCCESS",
                                        "startTime": "2019-07-29, 22:41:48.147",
                                        "endTime": "2019-07-29, 22:41:48.283",
                                        "duration": "0 hrs 0 min 0 secs 136 msc",
                                        "type": "MANAGED_DEVICE_UUID",
                                        "onFailure": "ABORT_ON_ERROR",
                                        "statusMessage": "Get reload command Successful.",
                                        "childWorkflows": [],
                                        "displayName": "0"
                                    }
                                ],
                                "task.next": [
                                    {
                                        "name": "Reloading Replacement Device"
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "name": "deviceReachabilityCheckStep",
                        "stepStatus": "SUCCESS",
                        "beginTasks": [
                            "Checking Replacement Device Reachability"
                        ],
                        "step.next": [
                            {
                                "name": "deleteReplacementDeviceFromInventoryStep"
                            }
                        ],
                        "tasks": [
                            {
                                "name": "Checking Replacement Device Reachability",
                                "type": "deviceReachabilityCheckAdaptor",
                                "targets": [
                                    {
                                        "id": "88a02158-7ed8-4354-a03d-aea2c584c694",
                                        "targetStatus": "SUCCESS",
                                        "startTime": "2019-07-29, 22:42:23.010",
                                        "endTime": "2019-07-29, 22:44:39.299",
                                        "duration": "0 hrs 2 min 16 secs 289 msc",
                                        "type": "MANAGED_DEVICE_UUID",
                                        "onFailure": "ABORT_ON_ERROR",
                                        "statusMessage": "Device is reachable",
                                        "childWorkflows": [],
                                        "displayName": "0"
                                    }
                                ],
                                "task.next": []
                            }
                        ]
                    },
                    {
                        "name": "deleteReplacementDeviceFromInventoryStep",
                        "stepStatus": "SUCCESS",
                        "beginTasks": [
                            "Deleting Replacement Device from Inventory"
                        ],
                        "step.next": [
                            {
                                "name": "inventorySyncStep"
                            }
                        ],
                        "tasks": [
                            {
                                "name": "Deleting Replacement Device from Inventory",
                                "type": "deleteReplacementDeviceFromInventoryAdaptor",
                                "targets": [
                                    {
                                        "id": "88a02158-7ed8-4354-a03d-aea2c584c694",
                                        "targetStatus": "SUCCESS",
                                        "startTime": "2019-07-29, 22:44:39.532",
                                        "endTime": "2019-07-29, 22:44:58.182",
                                        "duration": "0 hrs 0 min 18 secs 650 msc",
                                        "type": "MANAGED_DEVICE_UUID",
                                        "onFailure": "ABORT_ON_ERROR",
                                        "statusMessage": "Deleted Replacement Device from Inventory successfully",
                                        "childWorkflows": [],
                                        "displayName": "0"
                                    }
                                ],
                                "task.next": []
                            }
                        ]
                    },
                    {
                        "name": "inventorySyncStep",
                        "stepStatus": "SUCCESS",
                        "beginTasks": [
                            "Syncing Replacement Device"
                        ],
                        "step.next": [
                            {
                                "name": "deletePkiTrustPointStep"
                            }
                        ],
                        "tasks": [
                            {
                                "name": "Syncing Replacement Device",
                                "type": "inventorySyncAdaptor",
                                "targets": [
                                    {
                                        "id": "88a02158-7ed8-4354-a03d-aea2c584c694",
                                        "targetStatus": "SUCCESS",
                                        "startTime": "2019-07-29, 22:44:58.337",
                                        "endTime": "2019-07-29, 22:45:43.957",
                                        "duration": "0 hrs 0 min 45 secs 620 msc",
                                        "type": "MANAGED_DEVICE_UUID",
                                        "onFailure": "ABORT_ON_ERROR",
                                        "statusMessage": "Inventory Sync completed successfully",
                                        "childWorkflows": [],
                                        "displayName": "0"
                                    }
                                ],
                                "task.next": []
                            }
                        ]
                    },
                    {
                        "name": "deletePkiTrustPointStep",
                        "stepStatus": "SUCCESS",
                        "beginTasks": [
                            "Revoking PKI Trust Point"
                        ],
                        "step.next": [
                            {
                                "name": "IseUpdateStep"
                            }
                        ],
                        "tasks": [
                            {
                                "name": "Revoking PKI Trust Point",
                                "type": "deletePkiTrustPointAdaptor",
                                "targets": [
                                    {
                                        "id": "88a02158-7ed8-4354-a03d-aea2c584c694",
                                        "targetStatus": "SUCCESS",
                                        "startTime": "2019-07-29, 22:45:44.120",
                                        "endTime": "2019-07-29, 22:45:44.314",
                                        "duration": "0 hrs 0 min 0 secs 194 msc",
                                        "type": "MANAGED_DEVICE_UUID",
                                        "onFailure": "ABORT_ON_ERROR",
                                        "statusMessage": "No PKI trustpoint certificate exists for the faulty device and hence Revoking PKI Trust Point step is not applicable.",
                                        "childWorkflows": [],
                                        "displayName": "0"
                                    }
                                ],
                                "task.next": []
                            }
                        ]
                    },
                    {
                        "name": "IseUpdateStep",
                        "stepStatus": "SUCCESS",
                        "beginTasks": [
                            "Updating Cisco Identity Services Engine"
                        ],
                        "step.next": [],
                        "tasks": [
                            {
                                "name": "Updating Cisco Identity Services Engine",
                                "type": "iseSyncAdapter",
                                "targets": [
                                    {
                                        "id": "88a02158-7ed8-4354-a03d-aea2c584c694",
                                        "targetStatus": "SUCCESS",
                                        "startTime": "2019-07-29, 22:45:44.623",
                                        "endTime": "2019-07-29, 22:45:44.746",
                                        "duration": "0 hrs 0 min 0 secs 123 msc",
                                        "type": "MANAGED_DEVICE_UUID",
                                        "onFailure": "ABORT_ON_ERROR",
                                        "statusMessage": "ISE server is not active, skipping ISE Sync",
                                        "childWorkflows": [],
                                        "displayName": "0"
                                    }
                                ],
                                "task.next": []
                            }
                        ]
                    }
                ]
            }
        ]
    }
});