define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/DeviceImageTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/swim/SimSwimData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/swim/SimSwimHelper',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/image/DeviceImageTemplate_1_2_8',
], function (SimLokiDatabaseActions, DeviceImageTemplate, UtilityFunctions, SimSwimData, SimSwimHelper, DeviceImageTemplate_1_2_8) {
        
    var DISTRIBUTION_TIME = 2;
    var ACTIVATION_TIME_IN_MIN = 3;
    var DISTRIBUTION_ERROR_TIME = 1;

        //This function sets the required params to show the image name in the PROVISION tab
        function formRunningImageTemplate(tJsonArr, runningImage, deviceId, deviceRole, runningImageList, devPlatformId, record) {
            //console.log("deviceId: " + deviceId + " ## deviceRole: " + deviceRole + " ## devPlatformId: " + devPlatformId);
            tJsonArr.deviceId = deviceId;
            tJsonArr.deviceImageUpgradeStatus = "UNKNOWN";
            tJsonArr.readyForUpgrade = "UNKNOWN";
            tJsonArr.upgradeStatus = "Tag Golden";
            tJsonArr.readinessCheckStatus.isReadyForUpgrade = true;
            tJsonArr.readinessCheckStatus.status = "UNKNOWN";
            //commenting sections of tJsonArr.deviceInstalledInfo[0] as this was used in 1.2.10. But might need for reference for now.
            tJsonArr.deviceInstalledInfo[0].name = runningImage.name
            tJsonArr.deviceInstalledInfo[0].version = runningImage.version
            tJsonArr.deviceInstalledInfo[0].displayVersion = runningImage.version
            tJsonArr.deviceInstalledInfo[0].family = runningImage.imageFamily
            tJsonArr.deviceInstalledInfo[0].imageUuid = runningImage.imageUuid
            tJsonArr.deviceInstalledInfo[0].imageType = runningImage.imageType
            tJsonArr.rebootRequired = true;
            var isGoldenUpdated = false;

            // This setting is done to tag the image as golden in the Provision tab
            if (runningImage.isTaggedGolden === "true") {
                for (var i = 0; i < runningImage.tagList.length && !isGoldenUpdated; i++) {
                    var tag = runningImage.tagList[i];
                    //check the role
                    if ((tag.role == "ALL" || tag.role == deviceRole) && tag.taggedGolden) {
                        tJsonArr.deviceImageUpgradeStatus = "UPTODATE";
                        tJsonArr.error = null;
                        isGoldenUpdated = true;
                        tJsonArr.upgradeStatus = "Activation Success";
                        tJsonArr.readyForUpgrade = "Success";
                        tJsonArr.rebootRequired = false;
                        tJsonArr.readinessCheckStatus.isReadyForUpgrade = true;
                        tJsonArr.readinessCheckStatus.status = "SUCCESS";
                        tJsonArr.targetImageInfo[0].extendedAttributes.release_version = runningImage.version;
                        tJsonArr.targetImageInfo[0].version = runningImage.version;
                        tJsonArr.targetImageInfo[0].displayVersion = runningImage.version;
                        tJsonArr.deviceInstalledInfo[0].golden = true;
                    }
                }
            }
            if (!isGoldenUpdated) {
                //running image not golden! check the golden image for the device
                for (var i = 0; i < runningImageList.length && !isGoldenUpdated; i++) {
                    //check the list of images valid for the device
                    var isValidImage = SimSwimHelper.imageInApplicableDevices(runningImageList[i].applicableDevicesForImage, devPlatformId, record[0]);
                    if (isValidImage) {
                        if (runningImageList[i].isTaggedGolden === "true") {
                            for (var j = 0; j < runningImageList[i].tagList.length; j++) {
                                var tag = runningImageList[i].tagList[j];
                                //check the role
                                if ((tag.role == "ALL" || tag.role == deviceRole) && tag.taggedGolden) {
                                    //Found a golden image for outdated condition!
                                    tJsonArr.rebootRequired = false;
                                    tJsonArr.deviceImageUpgradeStatus = "OUTDATED";
                                    tJsonArr.error = null;
                                    isGoldenUpdated = true;
                                    tJsonArr.upgradeStatus = "Distribution Pending";
                                    tJsonArr.readyForUpgrade = "Success";
                                    tJsonArr.readinessCheckStatus.isReadyForUpgrade = true;
                                    tJsonArr.readinessCheckStatus.status = "SUCCESS";
                                    tJsonArr.targetImageInfo[0].name = runningImageList[i].name;
                                    tJsonArr.targetImageInfo[0].imageName = runningImageList[i].name;
                                    tJsonArr.targetImageInfo[0].family = runningImageList[i].imageFamily;
                                    tJsonArr.targetImageInfo[0].extendedAttributes.release_version = runningImageList[i].version;
                                    tJsonArr.targetImageInfo[0].version = runningImageList[i].version;
                                    tJsonArr.targetImageInfo[0].displayVersion = runningImageList[i].version;
                                    var modifications = upgradeOsStatus(record[0].managementIpAddress, runningImageList[i].name);
                                    if(modifications.status != '') tJsonArr.deviceImageUpgradeStatus = modifications.status;
                                    if(modifications.actionInProgress != '') tJsonArr.upgradeStatus = modifications.actionInProgress;
                                    if(modifications.imageDtl) {
                                        tJsonArr.deviceInstalledInfo[0].name = runningImageList[i].name
                                        tJsonArr.deviceInstalledInfo[0].version = runningImageList[i].version
                                        tJsonArr.deviceInstalledInfo[0].displayVersion = runningImageList[i].version
                                        tJsonArr.deviceInstalledInfo[0].family = runningImageList[i].imageFamily
                                        tJsonArr.deviceInstalledInfo[0].imageUuid = runningImageList[i].imageUuid
                                        tJsonArr.deviceInstalledInfo[0].imageType = runningImageList[i].imageType
                                        tJsonArr.deviceInstalledInfo[0].golden = true;
                                        tJsonArr.deviceImageUpgradeStatus = "UPTODATE";
                                        tJsonArr.error = null;
                                        isGoldenUpdated = true;
                                        tJsonArr.upgradeStatus = "Activation Success";
                                        tJsonArr.rebootRequired = false;
                                        tJsonArr.targetImageInfo[0].extendedAttributes.release_version = runningImageList[i].version;
                                        tJsonArr.targetImageInfo[0].version = runningImageList[i].version;
                                        tJsonArr.targetImageInfo[0].displayVersion = runningImageList[i].version;
                                        tJsonArr.targetImageInfo[0].name = runningImageList[i].name;
                                        tJsonArr.targetImageInfo[0].imageName = runningImageList[i].name;
                                        tJsonArr.targetImageInfo[0].family = runningImageList[i].imageFamily;
                                        tJsonArr.deviceInstalledInfo[0].golden = true;
                                    }
                                    
                                    break;
                                }
                            }
                        }
                    }
                }
            }

            if (!isGoldenUpdated) {
                //Golden not present for this device! This is to disable the golden tagging.
                tJsonArr.targetImageInfo = [];
            }
            tJsonArr.readinessCheckStatus.lastChecked = UtilityFunctions.getDateInTimestamp_SubtractedByDays_FromCurrentDate(1);
            return tJsonArr;
        }

        function upgradeOsStatus(deviceIp, goldenImageName) {
            var modifications = {'status':'','actionInProgress':''};
            var records = SimLokiDatabaseActions.getFilteredRecordHandler('image-activation', {'deviceIp': deviceIp});
            UtilityFunctions.sortData('completionTime','desc',records);
            for(var i=0; i<records.length; i++) {
                if(records[i].taskStatus == 'failed' || records[i].taskStatus == 'failure') {
                    //failed record doesnt have to update existing data. Hence skipping..
                    if(Number(records[i].completionTime)>UtilityFunctions.getTimeStamp()) {
                        modifications.status = "INPROGRESS";
                        modifications.actionInProgress = "Distribution In Progress";
                        break;
                    }
                    continue;
                } else if(Number(records[i].startTime)>UtilityFunctions.getTimeStamp()) {
                    //skip this as this is future time..
                    continue;                    
                } else {
                    if(records[i].imageName == goldenImageName) {
                        //current golden image and latest image in task are the same - so Uptodate
                        modifications.status = 'UPTODATE';                        
                        if(Number(records[i].completionTime)>UtilityFunctions.getTimeStamp()) {
                            //completion time is future time - show in progress
                            modifications.status = "INPROGRESS";
                            var diff = (Number(records[i].completionTime)-Number(records[i].startTime))/(60*1000);
                            switch (diff) {
                                case DISTRIBUTION_TIME: //Scheduled distribute in progress
                                    modifications.actionInProgress = "Distribution In Progress";
                                    break;
                                case ACTIVATION_TIME_IN_MIN: //Scheduled distribute in progress
                                    modifications.actionInProgress = "Activation In Progress";
                                    break;                            
                                default: //Now scenario handling
                                    if( ((Number(records[i].completionTime) - UtilityFunctions.getTimeStamp())/60000) > DISTRIBUTION_TIME ) {
                                        modifications.actionInProgress = "Distribution In Progress";
                                    } else {
                                        modifications.actionInProgress = "Activation In Progress";
                                    }
                                    break;
                            }
                            break;
                        } else {
                            //completed activation. Hack: Scheduled distribution will be later in tasks as it is sorted in desc order of time.
                            modifications.actionInProgress = "Activation Success";
                            var devRecord = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'managementIpAddress':deviceIp});
                            if(devRecord && devRecord.length>0) {
                                var imageDtl = SimSwimData.updateImageVersionAfterUpgrade(devRecord[0]);
                                modifications.imageDtl = imageDtl;
                            }
                            break;
                        }
                    } else {
                        //current golden image and latest image in task are different - so Outdated
                        break;
                    }
                }
            }
            return modifications;
        }


        return {
            init: function () {
                this.fetchRunningImage();
                this.initialActivateSuccess();
            },

            //Fetches the RunningImage of the device
            fetchRunningImage: function (urlAction) {
                var finalRunningImageList = [];
                var deviceIdList = [];
                if(urlAction && urlAction.filter['id']) {
                    deviceIdList = urlAction.filter['id'].split(',');
                } else {
                    var records = SimLokiDatabaseActions.getAll('network-device');
                    for(var i=0; i<records.length; i++) {
                        deviceIdList.push(records[i].id);
                    }
                }

                //Fetching the details of all images
                var imageRecord = SimLokiDatabaseActions.getFilteredRecordHandler('images', {'deviceAvailable':true});//physical device images only
                var jsonRunningImage = undefined;

                for (var i = 0; i < deviceIdList.length; i++) {
                    var record = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'id': deviceIdList[i] });

                    //template creation
                    var tJsonArr = JSON.parse(JSON.stringify(DeviceImageTemplate_1_2_8.Device_Image_Template));

                    //Fetch the device Type of the device, to match with the images table and fetch the image name
                    if (record && record.length >= 1) {
                        var deviceType = record[0].type != undefined ? record[0].type : '';
                        var deviceRole = record[0].role ? record[0].role : "";

                        //update basic details
                        tJsonArr.deviceId = deviceIdList[i];
                        tJsonArr.deviceInstalledInfo.version = record[0].softwareVersion;
                        tJsonArr.productType = deviceType;
                        tJsonArr.productSeries = record[0].series;
                        tJsonArr.productFamily = record[0].family;
                        if(record[0].platformId=="C9800-CL-K9") tJsonArr.issuEnabled=true;

                        //Iterating the images to match the deviceFamily of images with the deviceType of DeviceConfigStatus
                        var isFound = false;
                        for (var j = 0; j < imageRecord.length; j++) {
                            if (imageRecord[j].deviceFamily == deviceType) {
                                //Found matching family
                                for (var k = 0; k < imageRecord[j].runningImageList.length; k++) {
                                    var runningImage = imageRecord[j].runningImageList[k];
                                    if (runningImage.deviceUuidList.indexOf(deviceIdList[i]) > -1) {
                                        //Device present in deviceUuidList
                                        tJsonArr = formRunningImageTemplate(tJsonArr, runningImage, deviceIdList[i], deviceRole,
                                            imageRecord[j].runningImageList, record[0].platformId, record);
                                        isFound = true; //goto next device
                                        break;
                                    }
                                }
                                if (isFound) {
                                    break;
                                }
                            } else if (j == imageRecord.length - 1) {
                                //no matchingfamily found in image list
                                tJsonArr.deviceInstalledInfo[0].name = "";
                                tJsonArr.deviceImageUpgradeStatus = "UNKNOWN";
                                tJsonArr.readinessCheckStatus.isReadyForUpgrade = false;
                                tJsonArr.upgradeStatus = null;
                                tJsonArr.targetImageInfo = [];
                            }
                        }
                    } else {
                        //DeviceConfigStatus not found
                        tJsonArr.deviceInstalledInfo[0].name = "";
                        tJsonArr.deviceImageUpgradeStatus = "UNKNOWN"; //null or "UNKNOWN" not sure
                        tJsonArr.readinessCheckStatus.isReadyForUpgrade = false;
                        tJsonArr.upgradeStatus = null;
                        tJsonArr.targetImageInfo = [];
                    }
                    var records = SimLokiDatabaseActions.getFilteredRecordHandler('device-image',{'deviceId':deviceIdList[i]});
                    if(urlAction!= undefined && records && records.length>0) {
                        SimLokiDatabaseActions.delete('device-image',records);
                    }
                    var dbTJsonArr = JSON.parse(JSON.stringify(tJsonArr));
                    dbTJsonArr.deviceInstalledInfo = dbTJsonArr.deviceInstalledInfo[0];
                    dbTJsonArr.targetImageInfo = dbTJsonArr.targetImageInfo.length>0 ? dbTJsonArr.targetImageInfo[0] : {};
                    SimLokiDatabaseActions.insert('device-image',dbTJsonArr);

                    //device compliance entry - based on image
                    //compliance check is not applicable for unified ap
                    let complianceEntry = SimLokiDatabaseActions.getFilteredRecordHandler('device-compliance', {'id':tJsonArr.deviceId});
                    let syncTime = UtilityFunctions.getTimeStamp();
                    let complianceStatus = ["UPTODATE","UNKNOWN"].indexOf(tJsonArr.deviceImageUpgradeStatus)>-1 ? "COMPLIANT" : "NON_COMPLIANT";
                    if(complianceEntry.length > 0 && record[0].family != 'Unified AP' && record[0].reachabilityStatus.toLowerCase() != 'unreachable') {
                        complianceEntry[0].lastSyncTime = syncTime;
                        complianceEntry[0].complianceStatus = complianceStatus;
                        SimLokiDatabaseActions.update('device-compliance', complianceEntry);
                    } else {
                        complianceEntry = {'id':tJsonArr.deviceId, 'lastSyncTime':syncTime, 'complianceStatus':complianceStatus};
                        if(record[0].family == 'Unified AP')  {
                            complianceEntry.complianceStatus = 'NOT_APPLICABLE';
                        } /* else if(record[0].reachabilityStatus == 'Ping Reachable') {
                            complianceEntry.complianceStatus = 'ERROR';
                            complianceEntry.message = "Failed in spf compliance workflow"
                        } */ else if(record[0].reachabilityStatus.toLowerCase() != 'unreachable') {
                            SimLokiDatabaseActions.insert('device-compliance', complianceEntry);
                        }
                    }

                    finalRunningImageList.push(tJsonArr);
                }

                //get devices satisfying some image condition - called from filter of Provision page
                //api/v2/device-image/device?deviceImageUpgradeStatus=OUTDATED
                //api/v2/device-image/device?runningImage.systemImageName=.*CAT3K_CAA[03.06.05E].*
                if(urlAction && urlAction.filter['deviceImageUpgradeStatus']) {
                    finalRunningImageList = finalRunningImageList.filter(function(v) { 
                        return v.deviceImageUpgradeStatus == urlAction.filter['deviceImageUpgradeStatus'] && v.deviceInstalledInfo[0].name != null;
                    });
                }
                if(urlAction && urlAction.filter['runningImage.systemImageName']) {
                    var filterVal = urlAction.filter['runningImage.systemImageName'].replace('.*','').replace('.*','');
                    finalRunningImageList = finalRunningImageList.filter(function(v) { 
                        return v.deviceInstalledInfo[0].name == filterVal;
                    });
                } else if(urlAction && urlAction.filter['runningImageName']) {
                    var runningImgNames = [];
                    for(var i=0; i<finalRunningImageList.length; i++) {
                        if(finalRunningImageList[i].deviceInstalledInfo[0].name != ""){
                            runningImgNames.push(finalRunningImageList[i].deviceInstalledInfo[0].name);
                        }
                    }
                    finalRunningImageList = runningImgNames;
                    let uniqueValues = [...new Set(finalRunningImageList)]
                    return uniqueValues
                }
                if(urlAction && urlAction.filter['upgradeStatus']) {
                    var filterVal = urlAction.filter['upgradeStatus'];
                    finalRunningImageList = finalRunningImageList.filter(function(v) { 
                        return v.upgradeStatus == filterVal && v.deviceInstalledInfo[0].name != null;
                    });
                }
                
                return finalRunningImageList;
            },

            initialActivateSuccess() {
                let devices = SimLokiDatabaseActions.getFilteredRecordHandler("device-image", {"deviceImageUpgradeStatus":"UPTODATE"});
                devices = devices.map(e => {return {"deviceUuid":e.deviceId}});
                SimSwimData.activateImage({"restPayload":devices, "isInit":true});
            }

        }
    }
)

