define({
    "Application_Type_Template":   [
    {
      "imageUuid": "d62fe288-d39c-4390-a382-7ac7eaea69a3",
      "name": "isrv-universalk9.16.06.03.tar.gz",
      "family": "ISR",
      "version": "16.06.03",
      "shaCheckSum": "b01c0f63836e2d77cf67afefa6c8a195c8f523a2df84b9bb6fa2dca3323839bd6af60faf10ba0189184a24ccce0ce4e4ad078ae6ef84ffca826a06e2011ff306",
      "createdTime": "2018-05-11 19:53:09.0",
      "imageType": "QCOW_SW",
      "fileSize": "1530815221 bytes",
      "imageName": "TGZ",
      "applicationType": "ROUTER",
      "fileServiceId": "a731f100-3d5d-4666-b8e6-5f813d5d02f2",
      "isTaggedGolden": true,
      "imageSeries": [
        "Routers/Network Functions Virtualization"
      ],
      "imageSource": "file:/opt/maglev/services/swim-service/7.1.15.60299//localdisk/Staging/isrv-universalk9.16.06.03.tar.gz",
      "extendedAttributes": {
        "MINDISKSIZE": "0",
        "LOW_LATENCY": "true",
        "VERSION": "16.06.03",
        "DEFAULT_BOOTROM": "0",
        "IMAGENAME": "isrv-universalk9.16.06.03",
        "DEFAULT_RAM": "0",
        "IMAGETYPE_VALUE": "TGZ",
        "MD5_CHECKSUM": "UNKNOWN",
        "DEFAULT_MINFLASHSIZE": "0",
        "NAME": "isrv-universalk9.16.06.03.tar.gz"
      },
      "vendor": "CISCO",
      "imageIntegrityStatus": "VERIFIED",
      "profileInfo": [
        {
          "profileName": "ISRv-small",
          "memory": 4096,
          "vCpu": 2,
          "shares": 8192,
          "description": "ISRv-small",
          "productType": null,
          "extendedAttributes": {
            "throughput": "50 Mbps"
          }
        },
        {
          "profileName": "ISRv-mini",
          "memory": 4096,
          "vCpu": 1,
          "shares": 8192,
          "description": "ISRv-mini",
          "productType": null,
          "extendedAttributes": null
        },
        {
          "profileName": "ISRv-medium",
          "memory": 4096,
          "vCpu": 4,
          "shares": 8192,
          "description": "ISRv-medium",
          "productType": null,
          "extendedAttributes": {
            "throughput": "250 Mbps"
          }
        }
      ],
      "importSourceType": "FILESYSTEM"
    },  {
      "imageUuid": "128f8487-cd5a-4ad5-b8a4-b8e83f2febd8",
      "name": "vEdge_17_2_5.tar.gz",
      "family": "VEDGE",
      "version": "17.2.5",
      "shaCheckSum": "bf0c2c890ac2706d26d2c1f58ecc0e3febb5d50779d22d43b3682bebb8a896c333932a48e03553ad45d6f1f517c09707432d5786c6e6fbfff7faffe8ff5071ed",
      "createdTime": "2018-05-21 21:15:00.0",
      "imageType": "QCOW_SW",
      "fileSize": "217027887 bytes",
      "imageName": "TGZ",
      "applicationType": "ROUTER",
      "fileServiceId": "010eedc1-df1b-4f9a-80fb-b2eca3cd3e60",
      "isTaggedGolden": true,
      "imageSource": "file:/opt/maglev/services/swim-service/7.1.15.60299//localdisk/Staging/vEdge_17_2_5.tar.gz",
      "extendedAttributes": {
        "MINDISKSIZE": "0",
        "LOW_LATENCY": "true",
        "VERSION": "17.2.5",
        "DEFAULT_BOOTROM": "0",
        "IMAGENAME": "vEdge_17_2_5",
        "DEFAULT_RAM": "0",
        "IMAGETYPE_VALUE": "TGZ",
        "MD5_CHECKSUM": "UNKNOWN",
        "DEFAULT_MINFLASHSIZE": "0",
        "NAME": "vEdge_17_2_5.tar.gz"
      },
      "vendor": "CISCO",
      "imageIntegrityStatus": "UNKNOWN",
      "profileInfo": [
        {
          "profileName": "Vedge-Standard",
          "memory": 2048,
          "vCpu": 2,
          "shares": 8192,
          "description": "Vedge Standard Profile",
          "productType": null,
          "extendedAttributes": null
        }
      ],
      "importSourceType": "FILESYSTEM"
    }, {
      "imageUuid": "81007b94-07a1-4d00-bf9b-af0447520614",
      "name": "asav982.tar.gz",
      "family": "ASA",
      "version": "9.8.2",
      "shaCheckSum": "a5eb8becfb851b3bf68997d170d27dd0da7cb47a869fcc19f2d85febeb7ada96210f1095621dd3a63a9fee373fab6e5ac241cd46b616f7e67b8205fdbaf43390",
      "createdTime": "2018-05-11 19:58:37.0",
      "imageType": "QCOW_SW",
      "fileSize": "194727883 bytes",
      "imageName": "TGZ",
      "applicationType": "FIREWALL",
      "fileServiceId": "8ca3f5a1-bccc-4b3e-a12c-403ec53b1f75",
      "isTaggedGolden": true,
      "imageSeries": [
        "Security and VPN/Adaptive Security Appliances (ASA)"
      ],
      "imageSource": "file:/opt/maglev/services/swim-service/7.1.15.60299//localdisk/Staging/asav982.tar.gz",
      "extendedAttributes": {
        "MINDISKSIZE": "0",
        "LOW_LATENCY": "true",
        "VERSION": "9.8.2",
        "DEFAULT_BOOTROM": "0",
        "IMAGENAME": "asav982",
        "DEFAULT_RAM": "0",
        "IMAGETYPE_VALUE": "TGZ",
        "MD5_CHECKSUM": "UNKNOWN",
        "DEFAULT_MINFLASHSIZE": "0",
        "NAME": "asav982.tar.gz"
      },
      "vendor": "CISCO",
      "imageIntegrityStatus": "UNKNOWN",
      "profileInfo": [
        {
          "profileName": "ASAv5",
          "memory": 1024,
          "vCpu": 1,
          "shares": 8192,
          "description": "ASAv5 profile",
          "productType": null,
          "extendedAttributes": {
            "throughput": "100 Mbps"
          }
        },
        {
          "profileName": "ASAv10",
          "memory": 2048,
          "vCpu": 1,
          "shares": 8192,
          "description": "ASAv10 profile",
          "productType": null,
          "extendedAttributes": {
            "throughput": "1 Gbps"
          }
        },
        {
          "profileName": "ASAv30",
          "memory": 8192,
          "vCpu": 4,
          "shares": 16384,
          "description": "ASAv30 profile",
          "productType": null,
          "extendedAttributes": {
            "throughput": "2 Gbps"
          }
        }
      ],
      "importSourceType": "FILESYSTEM"
    },
    {
      "imageUuid": "a0a8a076-95ef-44fd-bf75-ad0fbeb60b93",
      "name": "pafwUnMon.tar.gz",
      "family": "PALOALTO",
      "version": "7.1.4",
      "shaCheckSum": "7822d8831dca4e047042bebff1196c0040939f21cc387c643134c4ab462e27dacb3c4f37ebdeed4a058aebcfa5ab810911c73868a8ec923c4c565b05097fb8af",
      "createdTime": "2018-05-11 20:00:24.0",
      "imageType": "QCOW_SW",
      "fileSize": "2118201297 bytes",
      "imageName": "TGZ",
      "applicationType": "FIREWALL",
      "fileServiceId": "e6719d85-4dc2-45c3-ae37-b2a795c9ba40",
      "isTaggedGolden": true,
      "imageSource": "file:/opt/maglev/services/swim-service/7.1.15.60299//localdisk/Staging/pafwUnMon.tar.gz",
      "extendedAttributes": {
        "MINDISKSIZE": "0",
        "LOW_LATENCY": "true",
        "VERSION": "7.1.4",
        "DEFAULT_BOOTROM": "0",
        "IMAGENAME": "pafwUnMon",
        "DEFAULT_RAM": "0",
        "IMAGETYPE_VALUE": "TGZ",
        "MD5_CHECKSUM": "UNKNOWN",
        "DEFAULT_MINFLASHSIZE": "0",
        "NAME": "pafwUnMon.tar.gz"
      },
      "vendor": "PALOALTO",
      "imageIntegrityStatus": "UNKNOWN",
      "profileInfo": [
        {
          "profileName": "pa-small",
          "memory": 4096,
          "vCpu": 2,
          "shares": 8192,
          "description": "pa small  profile",
          "productType": null,
          "extendedAttributes": null
        }
      ],
      "importSourceType": "FILESYSTEM"
    },
    {
      "imageUuid": "e45599dc-07c7-4c4c-961f-9429c281bb4a",
      "name": "Cisco_FTDv_6.2.2_81.tar.gz",
      "family": "NGFW",
      "version": "1.0",
      "shaCheckSum": "30b002292892e243a0cb2c61d272332640482a72bfeb2f952893985f47926f21df26c75c0efca2d454db4b209952f7bf028a9e9b332d692f495b0f80dd439b96",
      "createdTime": "2018-05-11 20:08:25.0",
      "imageType": "QCOW_SW",
      "fileSize": "1017822511 bytes",
      "imageName": "TGZ",
      "applicationType": "FIREWALL",
      "fileServiceId": "292a373d-4497-4849-9f77-6f3ceda5861b",
      "isTaggedGolden": true,
      "imageSource": "file:/opt/maglev/services/swim-service/7.1.15.60299//localdisk/Staging/Cisco_FTDv_6.2.2_81.tar.gz",
      "extendedAttributes": {
        "MINDISKSIZE": "0",
        "LOW_LATENCY": "true",
        "VERSION": "1.0",
        "DEFAULT_BOOTROM": "0",
        "IMAGENAME": "Cisco_FTDv_6.2.2_81",
        "DEFAULT_RAM": "0",
        "IMAGETYPE_VALUE": "TGZ",
        "MD5_CHECKSUM": "UNKNOWN",
        "DEFAULT_MINFLASHSIZE": "0",
        "NAME": "Cisco_FTDv_6.2.2_81.tar.gz"
      },
      "vendor": "CISCO",
      "imageIntegrityStatus": "UNKNOWN",
      "profileInfo": [
        {
          "profileName": "FTDv622",
          "memory": 8192,
          "vCpu": 4,
          "shares": 51200,
          "description": "FTDv622 profile",
          "productType": null,
          "extendedAttributes": null
        }
      ],
      "importSourceType": "FILESYSTEM"
    },
    {
      "imageUuid": "3be3971a-ec26-43f0-bc6d-90d537824c1c",
      "name": "ubuntu-root-cisco123.tar.gz",
      "family": "LINUX",
      "version": "16.04",
      "shaCheckSum": "e1c0bcb75fdbf2cc4d73ad4d06cb8d50fe88f0543f80b661dea43557c1bd21e8d727955d1f2731c7a64cabd4c0ee7e460d441694e5d85d4cdbebcdfa79675cf1",
      "createdTime": "2018-05-11 20:12:07.0",
      "imageType": "TGZ_SW",
      "fileSize": "281779172 bytes",
      "imageName": "TGZ",
      "applicationType": "LINUX",
      "fileServiceId": "f8c63b79-e7cd-419a-bf67-1200eb8f3db0",
      "isTaggedGolden": true,
      "imageSource": "file:/opt/maglev/services/swim-service/7.1.15.60299//localdisk/Staging/ubuntu-root-cisco123.tar.gz",
      "extendedAttributes": {
        "MINDISKSIZE": "0",
        "LOW_LATENCY": "false",
        "VERSION": "16.04",
        "DEFAULT_BOOTROM": "0",
        "IMAGENAME": "ubuntu-root-cisco123",
        "DEFAULT_RAM": "0",
        "IMAGETYPE_VALUE": "TGZ",
        "MD5_CHECKSUM": "UNKNOWN",
        "DEFAULT_MINFLASHSIZE": "0",
        "NAME": "ubuntu-root-cisco123.tar.gz"
      },
      "vendor": "LINUX",
      "imageIntegrityStatus": "UNKNOWN",
      "profileInfo": [
        {
          "profileName": "linux_small",
          "memory": 1024,
          "vCpu": 1,
          "shares": 20480,
          "description": "Linux small with 1G RAM and 1vcpu",
          "productType": null,
          "extendedAttributes": null
        },
        {
          "profileName": "linux_lg",
          "memory": 4096,
          "vCpu": 2,
          "shares": 20480,
          "description": "Linux Large with 4G RAM and 2vcpu",
          "productType": null,
          "extendedAttributes": null
        }
      ],
      "importSourceType": "FILESYSTEM"
    }
  ]


});