define({
"Template":
{
      "instanceType": "wlan",
      "instanceUuid": "78be6bac-ea8a-4a40-91f4-c1e4d53848f9",
      "namespace": "wlan",
      "type": "wlan.setting",
      "key": "wlan.info.udn-psk",
      "version": 1,
      "value": [
        {
          "profileName": "",
          "ssid": "udn-psk",
          "wlanType": "Enterprise",
          "isFastLaneEnabled": false,
          "authType": "wpa2_enterprise",
          "l3AuthType": "open",
          "authServer": "auth_ise",
          "passphrase": "",
          "peerIp": "",
          "scalableGroupTag": "",
          "trafficType": "voicedata",
          "isMacFilteringEnabled": false,
          "isEnabled": true,
          "isEmailReqd": false,
          "isFabric": true,
          "fabricId": null,
          "redirectUrl": "",
          "authSecServer": "",
          "portalType": "",
          "redirectUrlType": "",
          "externalAuthIpAddress": "",
          "portalName": "",
          "fastTransition": "Adaptive",
          "radioPolicy": 0,
          "wlanBandSelectEnable": false,
          "isBroadcastSSID": true,
          "webPassthrough": false,
          "sleepingClientEnable": false,
          "sleepingClientTimeout": 720,
          "isUpnEnabled": true,
          "isUnicastFilteringEnabled": false,
          "sessionTimeOutEnable": true,
          "sessionTimeOut": 1800,
          "clientExclusionEnable": true,
          "clientExclusionTimeout": 180,
          "basicServiceSetMaxIdleEnable": true,
          "basicServiceSetClientIdleTimeout": 300,
          "directedMulticastServiceEnable": true,
          "neighborListEnable": true,
          "managementFrameProtectionClientprotection": "Optional",
          "fastTransitionOverTheDistributedSystemEnable": true,
          "passiveClient": false,
          "policyProfileName": null,
          "openSsid": ""
        }
      ],
      "groupUuid": "f2815a32-e120-494e-a406-3b84cfa5a3d6",
      "inheritedGroupUuid": "d6f3881c-c790-450c-9e93-ec78aa675379",
      "inheritedGroupName": "SJC14"
}


})