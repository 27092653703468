define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/interface/DeviceInterfaceTemplate'
], function (SimLokiDatabaseActions, UtilityFunctions, SimTaskDataGenerator, DeviceInterfaceTemplate) {

    return {
        init: function () {
            associateInterfaceToDevice();
            //createInterfacePortChannel();
        },

        DeviceInterfaceInfoContainer_Put: function(jsonObj) {
            DeviceInterfaceInfoContainer_PutOperation(jsonObj);
        },

        associateInterfacesToSpecificDevice(device, isFromPnp) {
            associateInterfaceToDevice(device, isFromPnp)
        }
    };

    function associateInterfaceToDevice(devices, isFromPnp) {
        if(devices == undefined || devices.length == 0) {
            devices = SimLokiDatabaseActions.getAll('network-device');
        }
        //var deviceInfoList = SimLokiDatabaseActions.getAll('DeviceInfo');
        var interfaceType = ["Virtual","Physical"],
        portMode = ["access", "dynamic_auto", "routed", "trunk"],
        portType = ["Others", "Service Module Interface", "Ethernet SVI"],
        duplex = ["AutoNegotiate","FullDuplex"]
        devices.forEach(dev => {
            var interfaces = [], count = 1;
            //changes in picking the interface template to be updated in SimOnboardingData too
            if(dev.family == "Switches and Hubs") {
                interfaces = JSON.parse(JSON.stringify(DeviceInterfaceTemplate.Interfaces.SwitchesAndHubs))
                if(dev.platformId.startsWith("IE")) {
                    interfaces  = JSON.parse(JSON.stringify(DeviceInterfaceTemplate.Interfaces.IotDevices));
                } else if(dev.platformId.indexOf('-48') > -1) {
                    let data = JSON.parse(JSON.stringify(DeviceInterfaceTemplate.Interfaces.SwitchesAndHubsExtraPorts))
                    interfaces = [...interfaces,...data]
                }
            } else if (dev.family == "Routers") {
                interfaces = JSON.parse(JSON.stringify(DeviceInterfaceTemplate.Interfaces.Routers))
            } else if(dev.family == "Wireless Controller") {
                interfaces = JSON.parse(JSON.stringify(DeviceInterfaceTemplate.Interfaces.WirelessController))
            }            
            var deviceId = dev.id;
            var instanceId = UtilityFunctions.generateId(24)
            var ownEntityId = UtilityFunctions.getRandomIntForRangeValues(200000,300000);
            var time = UtilityFunctions.getTimeStamp()
            var lastUpadatedTime = UtilityFunctions.getGivenTimeInVerifyFormat(time)
            //roles are not assigned at this stage. so moving this to later section..
            /* var tDeviceList = deviceInfoList.filter(function (itm) { return (itm.networkDeviceId == deviceId && itm.siteId != "" && ( itm.roles.indexOf("EDGENODE") >= 0 ||  itm.roles.indexOf("SUBTENDED_NODE") >= 0) ) })
            if(tDeviceList && tDeviceList.length) {
                var tPortChannelInterfaceObj = getPortChannelInterfaceTemplate(dev);
                SimLokiDatabaseActions.insert('device-interface', tPortChannelInterfaceObj);
            } */
            if(interfaces.length != 0){
                interfaces.forEach((iface, idx) => {
                    var tJson = JSON.parse(JSON.stringify(DeviceInterfaceTemplate.InterfaceTemplate));
                    tJson.id = UtilityFunctions.generate_uuid();
                    tJson.instanceUuid = tJson.id; // used in lan automation
                    tJson.portName = iface;
                    tJson.macAddress = UtilityFunctions.incrementMacAddrres(tJson.macAddress, count);
                    tJson.deviceId = dev.id;
                    // tJson.interfaceType = "Physical";
                    // tJson.portType =  "Ethernet Port";
                    // tJson.portMode =  "dynamic_auto";
                    if(dev.family == "Wireless Controller") {
                        tJson = updateWirelessCustomFields(isFromPnp, tJson, dev, idx);
                    } else if(dev.family != "Switches and Hubs"){
                        tJson.adminStatus=  (count%4 == 0) ? "DOWN":"UP";
                        tJson.status = tJson.adminStatus == 'UP' ? ((count%8 == 0) ? "down":"up") : "down" ;
                        tJson.interfaceType = (count%2 == 0) ? "Physical":"Virtual" ;
                    }else {
                        tJson.interfaceType = "Physical";
                        tJson.adminStatus = (count%6 == 0) ? "DOWN":"UP";
                        tJson.status = tJson.adminStatus == 'UP' ? ((count%6 == 0) ? "down":"up") : "down" ;
                    }
                    tJson.portType = (tJson.interfaceType == "Physical")? "Ethernet Port": portType[Math.floor((Math.random() * 2) + 0)];
                    //tJson.portMode = (dev.family == "Routers") ? "routed" : (idx < 4 ? "access" : portMode[Math.floor((Math.random() * 3) + 0)]);
                    tJson.portMode = (dev.family == "Routers") ? "routed" : 
                        (dev.family == "Wireless Controller" ? tJson.portMode : (idx < 4 ? "access" : portMode[Math.floor((Math.random() * 3) + 0)]));
                    tJson.className = "SwitchPort";
                    tJson.series = dev.series;
                    tJson.pid = dev.platformId;
                    //tJson.duplex =  duplex[Math.floor(Math.random() * (duplex.length))];
                    tJson.duplex =  dev.family == "Wireless Controller" ? tJson.duplex : duplex[Math.floor(Math.random() * (duplex.length))];
                    tJson.ifIndex	= count
                    tJson.instanceTenantId = instanceId;
                    tJson.lastUpdated =  lastUpadatedTime;
                    tJson.owningEntityId =  `ownEntityId`+'_'+`ownEntityId`;
                    tJson.vlanId = '1';
                    tJson.poweroverethernet= 0;
                    tJson.speed = (iface.startsWith('Forty')) ? "40000000" : (iface.startsWith('Ten')) ? "10000000" : "1000000"
                    tJson.mtu = ((iface.startsWith('Forty')) || (iface.startsWith('Ten'))) ? "9100" : "1500"
                    SimLokiDatabaseActions.insert('device-interface', tJson);
                    count++;
                });
            }            
        });
        console.log("Added interfaces against devices..");
    }

    function updateWirelessCustomFields(isFromPnp, tJson, dev, idx) {
        let idxCheck = isFromPnp ? 2 : 3;
        tJson.interfaceType = tJson.portName.includes("GigabitEthernet") ? "Physical":"Virtual";
        tJson.adminStatus = ["GigabitEthernet0","Vlan1"].indexOf(tJson.portName)>-1 ? "DOWN" : "UP";
        tJson.status = ["GigabitEthernet0","Vlan1"].indexOf(tJson.portName)>-1 ? "down" : (idx<idxCheck ? "up" : "down");
        tJson.portMode = tJson.portName=="GigabitEthernet0"||tJson.interfaceType=="Virtual" ? "routed" : 
            (idx<3 ? "trunk" : "dynamic_auto");
        tJson.duplex = tJson.portName=="GigabitEthernet0" ? "AutoNegotiate" : (tJson.interfaceType=="Virtual" ? null : "FullDuplex");
        return tJson;
    }

    /* function getPortChannelInterface(deviceInfoObj, ifaceArr, indx) {
        var arr = []
        var obj = {};
        obj.id = UtilityFunctions.generate_uuid();
        obj.instanceId = 596596;
        obj.instanceVersion = 1;
        obj.interfaceName = "Port-channel1"
        obj.interfaceType = "PORTCHANNEL";
        obj.pagpMode = "DESIRABLE";
        obj.portChannelType =  "L2_LAYER";
        obj.protocol = "PAGP";
        obj.segment = [];
        obj.status = ifaceArr[1].status;
 
        obj.memberInterfaces = [{"id":ifaceArr[1].id,"displayName":"ed1b3da0[e2d743df-6642-4690-95c8-fc0fad71220b,"+ ifaceArr[1].portName,"interfaceName":ifaceArr[1].portName,"instanceVersion":0,"instanceId":597597}];

        arr.push(obj);
        return arr;
    } */

    /* function createInterfacePortChannel() {
        var devices = SimLokiDatabaseActions.getAll('DeviceInfo');

        var deviceInterfaceList = SimLokiDatabaseActions.getAll('device-interface');

        for(var i = 0, len = devices.length; i < len; i++) {

            if( (devices[i].roles.indexOf("EDGENODE") >= 0 || devices[i].roles.indexOf("SUBTENDED_NODE") >= 0) 
                   && devices[i].siteId != "") {
                var id = devices[i].deviceInterfaceInfoContainer.idRef;
                var portChannelObj = {};
                portChannelObj.id = id;
                portChannelObj.name = "DeviceIntfContainer_" + devices[i].networkDeviceId;
                portChannelObj.networkDeviceId = devices[i].networkDeviceId;

                var ifaceArr = deviceInterfaceList.filter(function (itm) { return (itm.deviceId == devices[i].networkDeviceId) })

                portChannelObj.interfaceInfo = getPortChannelInterface(devices[i], ifaceArr, i);
                SimLokiDatabaseActions.insert('interface-port-channel', portChannelObj);
            }
        }
    } */

    function DeviceInterfaceInfoContainer_PutOperation(jsonObj) {
        var obj = {'wrkFlwId':UtilityFunctions.generate_uuid(), 'cfsId':UtilityFunctions.generate_uuid()};
        var resultObj = SimTaskDataGenerator.createTask('TASK_DeviceInterfaceInfoContainer_MSG',obj);

        var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('interface-port-channel', { 'networkDeviceId' : jsonObj.networkDeviceId });

        //var deviceInfoObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', { 'networkDeviceId' : jsonObj.networkDeviceId });

        if(recordObj && recordObj.length){
            recordObj[0].interfaceInfo.length = 0;
            for(var i = 0, len = jsonObj.interfaceInfo.length; i < len; i++) {
                if(jsonObj.interfaceInfo[i].interfaceName == undefined) {
                    jsonObj.interfaceInfo[i].interfaceName = "Port-channel" + (i + 1).toString();
                }
                jsonObj.interfaceInfo[i].status = "up"; //added as a hack, need correct status FIXME
                recordObj[0].interfaceInfo.push( jsonObj.interfaceInfo[i] );
            }
            //SimLokiDatabaseActions.update( 'DeviceInterfaceInfoContainer', recordObj);
            SimLokiDatabaseActions.update( 'interface-port-channel', recordObj);
        }
        SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
        return resultObj;
    }
 
    //moved to diff section..
    /* function getPortChannelInterfaceTemplate(nwDeviceObj) {
        var t = {"description":null,"duplex":"AutoNegotiate","interfaceType":"Virtual","ipv4Address":null,"ipv4Mask":null,"isisSupport":"false","mappedPhysicalInterfaceId":null,"mappedPhysicalInterfaceName":null,"nativeVlanId":null,"ospfSupport":"false","pid":"","vlanId":"0","portName":"Port-channel1","deviceId":"","mediaType":null,"speed":null,"ifIndex":null,"macAddress":"00:00:00:00:00:00","portMode":"trunk","portType":"Others","serialNo":"","voiceVlan":null,"lastUpdated":"2019-06-09 07:41:52.845","status":"up","adminStatus":"NOT_APPLICABLE","className":"SwitchPort","series":"","instanceTenantId":"5cec9a5a0bc3a300b385d500","instanceUuid":"","id":""}
        t.series = nwDeviceObj.series;
        t.pid = nwDeviceObj.platformId;
        t.deviceId = nwDeviceObj.id;
        var id =  UtilityFunctions.generate_uuid();
        t.id = id;
        t.instanceUuid = id;
        return t;
    } */

});
