define({
    "SITE_DUMMY_DATA": {
        parentId: "90763407-02b2-4ec7-be5f-17588eac8ae7",
        additionalInfo: [
            {
                nameSpace: "com.wireless.managingwlc",
                attributes: {
                    primaryWlc: "bfb1df07-806b-4c11-8be4-cbd78cebeff6",
                    primaryWlcInheritedFrom: "bfb1df07-806b-4c11-8be4-cbd78cebeff6"
                }
            },
            {
                nameSpace: "System Settings",
                attributes: {
                    "group.count.total": 0,
                    hasChild: false,
                    "group.count.direct": 0,
                    "group.hierarchy.groupType": "SITE",
                    "member.count.total": 8,
                    "member.count.direct": 8
                }
            }],
        groupNameHierarchy: "Global/san jose",
        groupTypeList: ["SITE"],
        name: "san jose",
        id: "2f8ae348-2e33-4ff0-8c62-d81bdef48444"
    },
    "WLAN_SITE_TEMPLATE":[{
        "siteProfileUuid":"",
        "version":1,
        "name":"",
        "namespace":"wlan",
        "status":"final",
        "lastUpdatedBy":"admin",
        "lastUpdatedDatetime":0,
        "siteCount":0
    }]
});
