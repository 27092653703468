define({
    SummaryAnalyticsTemplate: {
        "version": "v2",
        "data": [{
            "values": {
                "totalServerCount": 1,
                "averageLatency": 15,
                "failureTransactionCount": 0,
                "responseCodeCount": 2,
                "insight": {
                    "totalServerCount": 1,
                    "averageLatency": 18,
                    "failureTransactionCount": 12,
                    "failureTransactionPercent": -100,
                    "averageLatencyPercent": -16.666666666666668,
                    "totalLatency": 0,
                    "successTransactionCount": 3552,
                    "successTransactionPercent": 1.632882882882883,
                    "totalTransactionCount": 3564,
                    "totalTransactionPercent": 1.2906846240179572
                },
                "successTransactionCount": 3610,
                "totalTransactionCount": 3610
            }
        }],
        "page": null,
        "scope": {
            "startTime": 1678362120000,
            "endTime": 1678448520000,
            "selectedTimeZone": null,
            "defaultTimeZone": null,
            "currentTime": 1678448746502,
            "timeApiTime": 1678448520000,
            "instrumentation": null,
            "query": {
                "fields": [
                    "totalTransactionCount",
                    "failureTransactionCount",
                    "successTransactionCount",
                    "totalServerCount",
                    "averageLatency"
                ],
                "filters": [{
                        "key": "networkServiceType",
                        "value": "dns",
                        "operator": "eq"
                    },
                    {
                        "key": "insight",
                        "value": true,
                        "operator": "eq"
                    }
                ],
                "target": "networkService",
                "queryType": "summary",
                "page": {
                    "limit": 5,
                    "offset": 0,
                    "cursor": null,
                    "pages": 1,
                    "count": 5
                },
                "includeMetadata": false
            }
        },
        "instrumentation": null,
        "message": "success"
    },
})