define({
  // Adding "acaGBPListDestViewSummary","acaGBPListSourceViewSummary" for to get the grid list and producer and consumer count by Karan 1_3_1
    "ScalableGroupSummaryTemplate_Template": [
      {
        "instanceId": 0,
        "displayName": "0",
        "instanceVersion": 0,
        "totalSGCount": 18,
        "acaScalableGroupSummary": [
          {
            "id": "356a1413-b079-4e9d-a1b9-58ebc2144876",
            "instanceId": 124126,
            "displayName": "124126",
            "instanceVersion": 1,
            "description": "Auditor Security Group",
            "ipSgtRefCount": 0,
            "isDeleted": false,
            "iseInstanceUuid": "934557f0-8c01-11e6-996c-525400b48521",
            "mdnacDeletionStatus": "ACTIVE",
            "defaultContractsCount": 0,
            "iseReserved": false,
            "iseRetryCount": 0,
            "iseTimeStamp": "2019-08-23 20:27:19.712714000",
            "learnedFrom": "",
            "name": "Auditors",
            "policyRefCount": 2,
            "propagateToAci": true,
            "pushed": true,
            "scalableGroupType": "USER-DEVICE",
            "securityGroupTag": "9/0x09",
            "syncMessage": "",
            "syncStatus": "SYNCED",
            "tenantintsegment": 4104,
            "tenantlongsegment": 0,
            "virtualNetworkNames": "DEFAULT_VN",
            "virtualNetworksCount": "1",
            "vnAgnostic": false,
            "acaScalableGroupVNInfo": [
              {
                "id": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
                "instanceId": 0,
                "displayName": "0",
                "instanceVersion": 0,
                "name": "DEFAULT_VN"
              }
            ],
            "acaGBPListDestViewSummary":[
              {
                "id": "356a1413-b079-4e9d-a1b9-58ebc2144876",
                "instanceId": 124126,
                "displayName": "124126",
                "instanceVersion": 1,
                "contractRefCount": 1,
                "sgName": "Auditors",
                "sgRefCount": 1,
                "tenantintsegment": 4104,
                "tenantlongsegment": 0
              }
            ],
            "acaGBPListSourceViewSummary": [
              {
                "id": "356a1413-b079-4e9d-a1b9-58ebc2144876",
                "instanceId": 124126,
                "displayName": "124126",
                "instanceVersion": 1,
                "contractRefCount": 2,
                "sgName": "Auditors",
                "sgRefCount": 2,
                "tenantintsegment": 4104,
                "tenantlongsegment": 0
              }
            ]
          },
          {
            "id": "51698bcd-e633-4460-ac34-d15ffaf7b69c",
            "instanceId": 201204,
            "displayName": "201204",
            "instanceVersion": 0,
            "description": "",
            "ipSgtRefCount": 0,
            "isDeleted": false,
            "iseInstanceUuid": "6aecbda0-685c-11e8-bf4d-005056b83f27",
            "mdnacDeletionStatus": "ACTIVE",
            "defaultContractsCount": 0,
            "iseReserved": false,
            "iseRetryCount": 0,
            "iseTimeStamp": "2019-08-23 20:27:19.712443000",
            "learnedFrom": "",
            "name": "Auditor_SDA",
            "policyRefCount": 2,
            "propagateToAci": false,
            "pushed": true,
            "scalableGroupType": "USER-DEVICE",
            "securityGroupTag": "112/0x70",
            "syncMessage": "",
            "syncStatus": "SYNCED",
            "tenantintsegment": -932881005,
            "tenantlongsegment": 6707744713420772000,
            "virtualNetworkNames": "DEFAULT_VN",
            "virtualNetworksCount": "1",
            "vnAgnostic": false,
            "acaScalableGroupVNInfo": [
              {
                "id": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
                "instanceId": 0,
                "displayName": "0",
                "instanceVersion": 0,
                "name": "DEFAULT_VN"
              }
            ],
            "acaGBPListDestViewSummary":[],
            "acaGBPListSourceViewSummary": [
              {
                "id": "51698bcd-e633-4460-ac34-d15ffaf7b69c",
                "instanceId": 201204,
                "displayName": "201204",
                "instanceVersion": 0,
                "contractRefCount": 1,
                "sgName": "Auditor_SDA",
                "sgRefCount": 2,
                "tenantintsegment": -932881005,
                "tenantlongsegment": 6707744713420772000
              }
            ]
          },
          {
            "id": "b0b122bc-8fa7-42e6-8f75-021e23a4145a",
            "instanceId": 124127,
            "displayName": "124127",
            "instanceVersion": 1,
            "description": "BYOD Security Group",
            "ipSgtRefCount": 0,
            "isDeleted": false,
            "iseInstanceUuid": "935d4cc0-8c01-11e6-996c-525400b48521",
            "mdnacDeletionStatus": "ACTIVE",
            "defaultContractsCount": 0,
            "iseReserved": false,
            "iseRetryCount": 0,
            "iseTimeStamp": "2019-08-23 20:27:19.712847000",
            "learnedFrom": "",
            "name": "BYOD",
            "policyRefCount": 2,
            "propagateToAci": true,
            "pushed": true,
            "scalableGroupType": "USER-DEVICE",
            "securityGroupTag": "15/0x0F",
            "syncMessage": "",
            "syncStatus": "SYNCED",
            "tenantintsegment": 4104,
            "tenantlongsegment": 0,
            "virtualNetworkNames": "DEFAULT_VN",
            "virtualNetworksCount": "1",
            "vnAgnostic": false,
            "acaScalableGroupVNInfo": [
              {
                "id": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
                "instanceId": 0,
                "displayName": "0",
                "instanceVersion": 0,
                "name": "DEFAULT_VN"
              }
            ],
            "acaGBPListDestViewSummary":[
              {
                "id": "b0b122bc-8fa7-42e6-8f75-021e23a4145a",
                "instanceId": 124127,
                "displayName": "124127",
                "instanceVersion": 1,
                "contractRefCount": 1,
                "sgName": "BYOD",
                "sgRefCount": 1,
                "tenantintsegment": 4104,
                "tenantlongsegment": 0
              }
            ],
            "acaGBPListSourceViewSummary": [
              {
                "id": "b0b122bc-8fa7-42e6-8f75-021e23a4145a",
                "instanceId": 124127,
                "displayName": "124127",
                "instanceVersion": 1,
                "contractRefCount": 1,
                "sgName": "BYOD",
                "sgRefCount": 1,
                "tenantintsegment": 4104,
                "tenantlongsegment": 0
              }
            ]
          },
          {
            "id": "7856fcd8-951e-4b89-a870-f858aaf889e0",
            "instanceId": 201201,
            "displayName": "201201",
            "instanceVersion": 1,
            "description": "",
            "ipSgtRefCount": 0,
            "isDeleted": false,
            "iseInstanceUuid": "b59eb480-7df4-11e9-bbce-feb756a02146",
            "mdnacDeletionStatus": "ACTIVE",
            "defaultContractsCount": 0,
            "iseReserved": false,
            "iseRetryCount": 0,
            "iseTimeStamp": "2019-08-23 20:27:19.713226000",
            "name": "Cameras",
            "policyRefCount": 2,
            "propagateToAci": false,
            "pushed": true,
            "scalableGroupType": "USER-DEVICE",
            "securityGroupTag": "53/0x35",
            "syncMessage": "",
            "syncStatus": "SYNCED",
            "tenantintsegment": -932881005,
            "tenantlongsegment": 6707744713420772000,
            "virtualNetworkNames": "DEFAULT_VN",
            "virtualNetworksCount": "2",
            "vnAgnostic": false,
            "acaScalableGroupVNInfo": [
              {
                "id": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
                "instanceId": 0,
                "displayName": "0",
                "instanceVersion": 0,
                "name": "DEFAULT_VN"
              }
            ],
            "acaGBPListDestViewSummary":[
              {
                "id": "7856fcd8-951e-4b89-a870-f858aaf889e0",
                "instanceId": 201201,
                "displayName": "201201",
                "instanceVersion": 1,
                "contractRefCount": 1,
                "sgName": "Cameras",
                "sgRefCount": 1,
                "tenantintsegment": -932881005,
                "tenantlongsegment": 6707744713420772000
              }
            ],
            "acaGBPListSourceViewSummary": [
              {
                "id": "7856fcd8-951e-4b89-a870-f858aaf889e0",
                "instanceId": 201201,
                "displayName": "201201",
                "instanceVersion": 1,
                "contractRefCount": 1,
                "sgName": "Cameras",
                "sgRefCount": 1,
                "tenantintsegment": -932881005,
                "tenantlongsegment": 6707744713420772000
              }
            ]
          },
          {
            "id": "76f33aca-b809-481f-ba8f-8d05663d9f3c",
            "instanceId": 201206,
            "displayName": "201206",
            "instanceVersion": 0,
            "description": "",
            "ipSgtRefCount": 0,
            "isDeleted": false,
            "iseInstanceUuid": "91ec1bf0-2060-11e9-8171-005056b83f27",
            "mdnacDeletionStatus": "ACTIVE",
            "defaultContractsCount": 0,
            "iseReserved": false,
            "iseRetryCount": 0,
            "iseTimeStamp": "2019-08-23 20:27:19.712636000",
            "learnedFrom": "",
            "name": "Contractor_Nurses",
            "policyRefCount": 0,
            "propagateToAci": false,
            "pushed": true,
            "scalableGroupType": "USER-DEVICE",
            "securityGroupTag": "31/0x1F",
            "syncMessage": "",
            "syncStatus": "SYNCED",
            "tenantintsegment": -932881005,
            "tenantlongsegment": 6707744713420772000,
            "virtualNetworkNames": "DEFAULT_VN",
            "virtualNetworksCount": "1",
            "vnAgnostic": false,
            "acaScalableGroupVNInfo": [
              {
                "id": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
                "instanceId": 0,
                "displayName": "0",
                "instanceVersion": 0,
                "name": "DEFAULT_VN"
              }
            ],
            "acaGBPListDestViewSummary":[],
            "acaGBPListSourceViewSummary": []
          },
          {
            "id": "34375a16-be39-4436-9919-9ca7aee57dea",
            "instanceId": 124128,
            "displayName": "124128",
            "instanceVersion": 1,
            "description": "Contractor Security Group",
            "ipSgtRefCount": 0,
            "isDeleted": false,
            "iseInstanceUuid": "9370d4c0-8c01-11e6-996c-525400b48521",
            "mdnacDeletionStatus": "ACTIVE",
            "defaultContractsCount": 0,
            "iseReserved": false,
            "iseRetryCount": 0,
            "iseTimeStamp": "2019-08-23 20:27:19.711531000",
            "learnedFrom": "",
            "name": "Contractors",
            "policyRefCount": 1,
            "propagateToAci": true,
            "pushed": true,
            "scalableGroupType": "USER-DEVICE",
            "securityGroupTag": "5/0x05",
            "syncMessage": "",
            "syncStatus": "SYNCED",
            "tenantintsegment": 4104,
            "tenantlongsegment": 0,
            "virtualNetworkNames": "DEFAULT_VN",
            "virtualNetworksCount": "2",
            "vnAgnostic": false,
            "acaScalableGroupVNInfo": [
              {
                "id": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
                "instanceId": 0,
                "displayName": "0",
                "instanceVersion": 0,
                "name": "DEFAULT_VN"
              }
            ],
            "acaGBPListDestViewSummary":[
              {
                "id": "34375a16-be39-4436-9919-9ca7aee57dea",
                "instanceId": 124128,
                "displayName": "124128",
                "instanceVersion": 1,
                "contractRefCount": 1,
                "sgName": "Contractors",
                "sgRefCount": 1,
                "tenantintsegment": 4104,
                "tenantlongsegment": 0
              }
            ],
            "acaGBPListSourceViewSummary": []
          },
          {
            "id": "799e9b7a-0314-4757-8af5-7b165f0116da",
            "instanceId": 124129,
            "displayName": "124129",
            "instanceVersion": 1,
            "description": "Developer Security Group",
            "ipSgtRefCount": 0,
            "isDeleted": false,
            "iseInstanceUuid": "93837260-8c01-11e6-996c-525400b48521",
            "mdnacDeletionStatus": "ACTIVE",
            "defaultContractsCount": 0,
            "iseReserved": false,
            "iseRetryCount": 0,
            "iseTimeStamp": "2019-08-23 20:27:19.711595000",
            "learnedFrom": "",
            "name": "Developers",
            "policyRefCount": 0,
            "propagateToAci": true,
            "pushed": true,
            "scalableGroupType": "USER-DEVICE",
            "securityGroupTag": "8/0x08",
            "syncMessage": "",
            "syncStatus": "SYNCED",
            "tenantintsegment": 4104,
            "tenantlongsegment": 0,
            "virtualNetworkNames": "DEFAULT_VN",
            "virtualNetworksCount": "1",
            "vnAgnostic": false,
            "acaScalableGroupVNInfo": [
              {
                "id": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
                "instanceId": 0,
                "displayName": "0",
                "instanceVersion": 0,
                "name": "DEFAULT_VN"
              }
            ],
            "acaGBPListDestViewSummary":[],
            "acaGBPListSourceViewSummary": []
          },
          {
            "id": "579f67ba-0412-4d56-8253-33be1e8b1935",
            "instanceId": 124130,
            "displayName": "124130",
            "instanceVersion": 1,
            "description": "Development Servers Security Group",
            "ipSgtRefCount": 0,
            "isDeleted": false,
            "iseInstanceUuid": "9396d350-8c01-11e6-996c-525400b48521",
            "mdnacDeletionStatus": "ACTIVE",
            "defaultContractsCount": 0,
            "iseReserved": false,
            "iseRetryCount": 0,
            "iseTimeStamp": "2019-08-23 20:27:19.712329000",
            "learnedFrom": "",
            "name": "Development_Servers",
            "policyRefCount": 1,
            "propagateToAci": true,
            "pushed": true,
            "scalableGroupType": "USER-DEVICE",
            "securityGroupTag": "12/0x0C",
            "syncMessage": "",
            "syncStatus": "SYNCED",
            "tenantintsegment": 4104,
            "tenantlongsegment": 0,
            "virtualNetworkNames": "DEFAULT_VN",
            "virtualNetworksCount": "1",
            "vnAgnostic": false,
            "acaScalableGroupVNInfo": [
              {
                "id": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
                "instanceId": 0,
                "displayName": "0",
                "instanceVersion": 0,
                "name": "DEFAULT_VN"
              }
            ],
            "acaGBPListDestViewSummary":[
              {
                "id": "579f67ba-0412-4d56-8253-33be1e8b1935",
                "instanceId": 124130,
                "displayName": "124130",
                "instanceVersion": 1,
                "contractRefCount": 1,
                "sgName": "Development_Servers",
                "sgRefCount": 1,
                "tenantintsegment": 4104,
                "tenantlongsegment": 0
              }
            ],
            "acaGBPListSourceViewSummary": []
          },
          {
            "id": "01fc1527-b7e8-460c-8a64-f87abc076132",
            "instanceId": 260260,
            "displayName": "260260",
            "instanceVersion": 0,
            "description": "",
            "ipSgtRefCount": 0,
            "isDeleted": false,
            "iseInstanceUuid": "6bf31bd0-a597-11e9-a2a7-9274da4574f2",
            "mdnacDeletionStatus": "ACTIVE",
            "defaultContractsCount": 0,
            "iseReserved": false,
            "iseRetryCount": 0,
            "iseTimeStamp": "2019-08-23 20:27:19.713040000",
            "name": "Doctors",
            "policyRefCount": 0,
            "propagateToAci": false,
            "pushed": true,
            "scalableGroupType": "USER-DEVICE",
            "securityGroupTag": "18/0x12",
            "syncMessage": "",
            "syncStatus": "SYNCED",
            "tenantintsegment": -932881005,
            "tenantlongsegment": 6707744713420772000,
            "virtualNetworkNames": "DEFAULT_VN",
            "virtualNetworksCount": "1",
            "vnAgnostic": false,
            "acaScalableGroupVNInfo": [
              {
                "id": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
                "instanceId": 0,
                "displayName": "0",
                "instanceVersion": 0,
                "name": "DEFAULT_VN"
              }
            ],
            "acaGBPListDestViewSummary":[],
            "acaGBPListSourceViewSummary": []
          },
          {
            "id": "21263b36-48a8-46e5-bcfe-6fd50f96bdf9",
            "instanceId": 124131,
            "displayName": "124131",
            "instanceVersion": 1,
            "description": "Employee Security Group",
            "ipSgtRefCount": 0,
            "isDeleted": false,
            "iseInstanceUuid": "93ad6890-8c01-11e6-996c-525400b48521",
            "mdnacDeletionStatus": "ACTIVE",
            "defaultContractsCount": 0,
            "iseReserved": false,
            "iseRetryCount": 0,
            "iseTimeStamp": "2019-08-23 20:27:19.711702000",
            "learnedFrom": "",
            "name": "Employees",
            "policyRefCount": 3,
            "propagateToAci": true,
            "pushed": true,
            "scalableGroupType": "USER-DEVICE",
            "securityGroupTag": "4/0x04",
            "syncMessage": "",
            "syncStatus": "SYNCED",
            "tenantintsegment": 4104,
            "tenantlongsegment": 0,
            "virtualNetworkNames": "DEFAULT_VN",
            "virtualNetworksCount": "2",
            "vnAgnostic": false,
            "acaScalableGroupVNInfo": [
              {
                "id": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
                "instanceId": 0,
                "displayName": "0",
                "instanceVersion": 0,
                "name": "DEFAULT_VN"
              }
            ],
            "acaGBPListDestViewSummary":[
              {
                "id": "21263b36-48a8-46e5-bcfe-6fd50f96bdf9",
                "instanceId": 124131,
                "displayName": "124131",
                "instanceVersion": 1,
                "contractRefCount": 1,
                "sgName": "Employees",
                "sgRefCount": 1,
                "tenantintsegment": 4104,
                "tenantlongsegment": 0
              }
            ],
            "acaGBPListSourceViewSummary": [
              {
                "id": "21263b36-48a8-46e5-bcfe-6fd50f96bdf9",
                "instanceId": 124131,
                "displayName": "124131",
                "instanceVersion": 1,
                "contractRefCount": 2,
                "sgName": "Employees",
                "sgRefCount": 3,
                "tenantintsegment": 4104,
                "tenantlongsegment": 0
              }
            ]
          },
          {
            "id": "25d872d4-dd6d-4aeb-bfdf-e16832a48eca",
            "instanceId": 124139,
            "displayName": "124139",
            "instanceVersion": 1,
            "description": "",
            "ipSgtRefCount": 0,
            "isDeleted": false,
            "iseInstanceUuid": "7e8aa410-2e1b-11e8-bd6e-005056b83f27",
            "mdnacDeletionStatus": "ACTIVE",
            "defaultContractsCount": 0,
            "iseReserved": false,
            "iseRetryCount": 0,
            "iseTimeStamp": "2019-08-23 20:27:19.713099000",
            "learnedFrom": "",
            "name": "Finance_Server_DNA",
            "policyRefCount": 0,
            "propagateToAci": false,
            "pushed": true,
            "scalableGroupType": "USER-DEVICE",
            "securityGroupTag": "13/0x0D",
            "syncMessage": "",
            "syncStatus": "SYNCED",
            "tenantintsegment": 4104,
            "tenantlongsegment": 0,
            "virtualNetworkNames": "DEFAULT_VN",
            "virtualNetworksCount": "1",
            "vnAgnostic": false,
            "acaScalableGroupVNInfo": [
              {
                "id": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
                "instanceId": 0,
                "displayName": "0",
                "instanceVersion": 0,
                "name": "DEFAULT_VN"
              }
            ],
            "acaGBPListDestViewSummary":[],
            "acaGBPListSourceViewSummary": []
          },
          {
            "id": "4f078c76-6f62-4cc4-82d5-649c860b2c26",
            "instanceId": 124132,
            "displayName": "124132",
            "instanceVersion": 1,
            "description": "Guest Security Group",
            "ipSgtRefCount": 0,
            "isDeleted": false,
            "iseInstanceUuid": "93c66ed0-8c01-11e6-996c-525400b48521",
            "mdnacDeletionStatus": "ACTIVE",
            "defaultContractsCount": 0,
            "iseReserved": false,
            "iseRetryCount": 0,
            "iseTimeStamp": "2019-08-23 20:27:19.712386000",
            "learnedFrom": "",
            "name": "Guests",
            "policyRefCount": 0,
            "propagateToAci": true,
            "pushed": true,
            "scalableGroupType": "USER-DEVICE",
            "securityGroupTag": "6/0x06",
            "syncMessage": "",
            "syncStatus": "SYNCED",
            "tenantintsegment": 4104,
            "tenantlongsegment": 0,
            "virtualNetworkNames": "DEFAULT_VN",
            "virtualNetworksCount": "2",
            "vnAgnostic": false,
            "acaScalableGroupVNInfo": [
              {
                "id": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
                "instanceId": 0,
                "displayName": "0",
                "instanceVersion": 0,
                "name": "DEFAULT_VN"
              }
            ],
            "acaGBPListDestViewSummary":[],
            "acaGBPListSourceViewSummary": []
          },
          {
            "id": "4c4a3930-9112-4e67-9c67-2a2e09ad1c32",
            "instanceId": 124142,
            "displayName": "124142",
            "instanceVersion": 2,
            "description": "",
            "ipSgtRefCount": 0,
            "isDeleted": false,
            "iseInstanceUuid": "32047010-5e01-11e8-a04e-005056b83f27",
            "mdnacDeletionStatus": "ACTIVE",
            "defaultContractsCount": 0,
            "iseReserved": false,
            "iseRetryCount": 0,
            "iseTimeStamp": "2019-08-23 20:27:19.712272000",
            "learnedFrom": "",
            "name": "HVAC",
            "policyRefCount": 3,
            "propagateToAci": false,
            "pushed": true,
            "scalableGroupType": "USER-DEVICE",
            "securityGroupTag": "17/0x11",
            "syncMessage": "",
            "syncStatus": "SYNCED",
            "tenantintsegment": 4104,
            "tenantlongsegment": 0,
            "virtualNetworkNames": "DEFAULT_VN",
            "virtualNetworksCount": "2",
            "vnAgnostic": false,
            "acaScalableGroupVNInfo": [
              {
                "id": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
                "instanceId": 0,
                "displayName": "0",
                "instanceVersion": 0,
                "name": "DEFAULT_VN"
              }
            ],
            "acaGBPListDestViewSummary":[
              {
                "id": "4c4a3930-9112-4e67-9c67-2a2e09ad1c32",
                "instanceId": 124142,
                "displayName": "124142",
                "instanceVersion": 2,
                "contractRefCount": 1,
                "sgName": "HVAC",
                "sgRefCount": 2,
                "tenantintsegment": 4104,
                "tenantlongsegment": 0
              }
            ],
            "acaGBPListSourceViewSummary": [
              {
                "id": "4c4a3930-9112-4e67-9c67-2a2e09ad1c32",
                "instanceId": 124142,
                "displayName": "124142",
                "instanceVersion": 2,
                "contractRefCount": 1,
                "sgName": "HVAC",
                "sgRefCount": 1,
                "tenantintsegment": 4104,
                "tenantlongsegment": 0
              }
            ]
          },
          {
            "id": "06f2a2ed-a8fd-4e9f-a79f-f0ee7111ab9e",
            "instanceId": 201205,
            "displayName": "201205",
            "instanceVersion": 1,
            "description": "",
            "ipSgtRefCount": 0,
            "isDeleted": false,
            "iseInstanceUuid": "a8165980-7df4-11e9-bbce-feb756a02146",
            "mdnacDeletionStatus": "ACTIVE",
            "defaultContractsCount": 0,
            "iseReserved": false,
            "iseRetryCount": 0,
            "iseTimeStamp": "2019-08-23 20:27:19.712081000",
            "name": "Lights",
            "policyRefCount": 0,
            "propagateToAci": false,
            "pushed": true,
            "scalableGroupType": "USER-DEVICE",
            "securityGroupTag": "52/0x34",
            "syncMessage": "",
            "syncStatus": "SYNCED",
            "tenantintsegment": -932881005,
            "tenantlongsegment": 6707744713420772000,
            "virtualNetworkNames": "DEFAULT_VN",
            "virtualNetworksCount": "2",
            "vnAgnostic": false,
            "acaScalableGroupVNInfo": [
              {
                "id": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
                "instanceId": 0,
                "displayName": "0",
                "instanceVersion": 0,
                "name": "DEFAULT_VN"
              }
            ],
            "acaGBPListDestViewSummary":[],
            "acaGBPListSourceViewSummary": []
          },
          {
            "id": "f8e0f254-7fd5-4e6c-94cd-759ed8e4960a",
            "instanceId": 124133,
            "displayName": "124133",
            "instanceVersion": 1,
            "description": "Network Services Security Group",
            "ipSgtRefCount": 0,
            "isDeleted": false,
            "iseInstanceUuid": "93e1bf00-8c01-11e6-996c-525400b48521",
            "mdnacDeletionStatus": "ACTIVE",
            "defaultContractsCount": 0,
            "iseReserved": false,
            "iseRetryCount": 0,
            "iseTimeStamp": "2019-08-23 20:27:19.711766000",
            "learnedFrom": "",
            "name": "Network_Services",
            "policyRefCount": 0,
            "propagateToAci": true,
            "pushed": true,
            "scalableGroupType": "USER-DEVICE",
            "securityGroupTag": "3/0x03",
            "syncMessage": "",
            "syncStatus": "SYNCED",
            "tenantintsegment": 4104,
            "tenantlongsegment": 0,
            "virtualNetworkNames": "DEFAULT_VN",
            "virtualNetworksCount": "1",
            "vnAgnostic": false,
            "acaScalableGroupVNInfo": [
              {
                "id": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
                "instanceId": 0,
                "displayName": "0",
                "instanceVersion": 0,
                "name": "DEFAULT_VN"
              }
            ],
            "acaGBPListDestViewSummary":[],
            "acaGBPListSourceViewSummary": []
          },
          {
            "id": "2b5c9171-9f7e-42a9-b83a-f206fc416969",
            "instanceId": 124134,
            "displayName": "124134",
            "instanceVersion": 1,
            "description": "PCI Servers Security Group",
            "ipSgtRefCount": 0,
            "isDeleted": false,
            "iseInstanceUuid": "93f91790-8c01-11e6-996c-525400b48521",
            "mdnacDeletionStatus": "ACTIVE",
            "defaultContractsCount": 0,
            "iseReserved": false,
            "iseRetryCount": 0,
            "iseTimeStamp": "2019-08-23 20:27:19.711946000",
            "learnedFrom": "",
            "name": "PCI_Servers",
            "policyRefCount": 2,
            "propagateToAci": true,
            "pushed": true,
            "scalableGroupType": "USER-DEVICE",
            "securityGroupTag": "14/0x0E",
            "syncMessage": "",
            "syncStatus": "SYNCED",
            "tenantintsegment": 4104,
            "tenantlongsegment": 0,
            "virtualNetworkNames": "DEFAULT_VN",
            "virtualNetworksCount": "1",
            "vnAgnostic": false,
            "acaScalableGroupVNInfo": [
              {
                "id": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
                "instanceId": 0,
                "displayName": "0",
                "instanceVersion": 0,
                "name": "DEFAULT_VN"
              }
            ],
            "acaGBPListDestViewSummary":[
              {
                "id": "2b5c9171-9f7e-42a9-b83a-f206fc416969",
                "instanceId": 124134,
                "displayName": "124134",
                "instanceVersion": 1,
                "contractRefCount": 2,
                "sgName": "PCI_Servers",
                "sgRefCount": 2,
                "tenantintsegment": 4104,
                "tenantlongsegment": 0
              }
            ],
            "acaGBPListSourceViewSummary": []
          },
          {
            "id": "0698682c-ef79-40a8-90aa-41acf17b6754",
            "instanceId": 124135,
            "displayName": "124135",
            "instanceVersion": 2,
            "description": "Point of Sale Security Group",
            "ipSgtRefCount": 0,
            "isDeleted": false,
            "iseInstanceUuid": "940facd0-8c01-11e6-996c-525400b48521",
            "mdnacDeletionStatus": "ACTIVE",
            "defaultContractsCount": 0,
            "iseReserved": false,
            "iseRetryCount": 0,
            "iseTimeStamp": "2019-08-23 20:27:19.712016000",
            "learnedFrom": "",
            "name": "Point_of_Sale_Systems",
            "policyRefCount": 0,
            "propagateToAci": true,
            "pushed": true,
            "scalableGroupType": "USER-DEVICE",
            "securityGroupTag": "10/0x0A",
            "syncMessage": "",
            "syncStatus": "SYNCED",
            "tenantintsegment": 4104,
            "tenantlongsegment": 0,
            "virtualNetworkNames": "DEFAULT_VN",
            "virtualNetworksCount": "2",
            "vnAgnostic": false,
            "acaScalableGroupVNInfo": [
              {
                "id": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
                "instanceId": 0,
                "displayName": "0",
                "instanceVersion": 0,
                "name": "DEFAULT_VN"
              }
            ],
            "acaGBPListDestViewSummary":[],
            "acaGBPListSourceViewSummary": []
          },
          {
            "id": "d8710a37-9fc5-43e1-bc56-81d96efe956f",
            "instanceId": 201203,
            "displayName": "201203",
            "instanceVersion": 5,
            "description": "",
            "ipSgtRefCount": 0,
            "isDeleted": false,
            "iseInstanceUuid": "a8f860b0-2060-11e9-8171-005056b83f27",
            "mdnacDeletionStatus": "ACTIVE",
            "defaultContractsCount": 0,
            "iseReserved": false,
            "iseRetryCount": 0,
            "iseTimeStamp": "2019-08-23 20:27:19.712974000",
            "name": "Printer_EPG",
            "policyRefCount": 0,
            "propagateToAci": false,
            "pushed": true,
            "scalableGroupType": "USER-DEVICE",
            "securityGroupTag": "32/0x20",
            "syncMessage": "",
            "syncStatus": "SYNCED",
            "tenantintsegment": -932881005,
            "tenantlongsegment": 6707744713420772000,
            "virtualNetworkNames": "DEFAULT_VN",
            "virtualNetworksCount": "1",
            "vnAgnostic": false,
            "acaScalableGroupVNInfo": [
              {
                "id": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
                "instanceId": 0,
                "displayName": "0",
                "instanceVersion": 0,
                "name": "DEFAULT_VN"
              }
            ],
            "acaGBPListDestViewSummary":[],
            "acaGBPListSourceViewSummary": []
          },
          {
            "id": "4dafa2e0-4a3d-4fb8-a36b-01ee6134660f",
            "instanceId": 124136,
            "displayName": "124136",
            "instanceVersion": 1,
            "description": "Production Servers Security Group",
            "ipSgtRefCount": 0,
            "isDeleted": false,
            "iseInstanceUuid": "9423aa00-8c01-11e6-996c-525400b48521",
            "mdnacDeletionStatus": "ACTIVE",
            "defaultContractsCount": 0,
            "iseReserved": false,
            "iseRetryCount": 0,
            "iseTimeStamp": "2019-08-23 20:27:19.712909000",
            "learnedFrom": "",
            "name": "Production_Servers",
            "policyRefCount": 0,
            "propagateToAci": true,
            "pushed": true,
            "scalableGroupType": "USER-DEVICE",
            "securityGroupTag": "11/0x0B",
            "syncMessage": "",
            "syncStatus": "SYNCED",
            "tenantintsegment": 4104,
            "tenantlongsegment": 0,
            "virtualNetworkNames": "DEFAULT_VN",
            "virtualNetworksCount": "1",
            "vnAgnostic": false,
            "acaScalableGroupVNInfo": [
              {
                "id": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
                "instanceId": 0,
                "displayName": "0",
                "instanceVersion": 0,
                "name": "DEFAULT_VN"
              }
            ],
            "acaGBPListDestViewSummary":[],
            "acaGBPListSourceViewSummary": []
          },
          {
            "id": "ecbfdf65-23a0-47d6-9b37-7cf31d9ed49e",
            "instanceId": 124137,
            "displayName": "124137",
            "instanceVersion": 1,
            "description": "Production User Security Group",
            "ipSgtRefCount": 0,
            "isDeleted": false,
            "iseInstanceUuid": "9437a730-8c01-11e6-996c-525400b48521",
            "mdnacDeletionStatus": "ACTIVE",
            "defaultContractsCount": 0,
            "iseReserved": false,
            "iseRetryCount": 0,
            "iseTimeStamp": "2019-08-23 20:27:19.711882000",
            "learnedFrom": "",
            "name": "Production_Users",
            "policyRefCount": 0,
            "propagateToAci": true,
            "pushed": true,
            "scalableGroupType": "USER-DEVICE",
            "securityGroupTag": "7/0x07",
            "syncMessage": "",
            "syncStatus": "SYNCED",
            "tenantintsegment": 4104,
            "tenantlongsegment": 0,
            "virtualNetworkNames": "DEFAULT_VN",
            "virtualNetworksCount": "1",
            "vnAgnostic": false,
            "acaScalableGroupVNInfo": [
              {
                "id": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
                "instanceId": 0,
                "displayName": "0",
                "instanceVersion": 0,
                "name": "DEFAULT_VN"
              }
            ],
            "acaGBPListDestViewSummary":[],
            "acaGBPListSourceViewSummary": []
          },
          {
            "id": "5294317d-c3a1-415b-ae69-c8e3e2f985b7",
            "instanceId": 124138,
            "displayName": "124138",
            "instanceVersion": 1,
            "description": "Quarantine Security Group",
            "ipSgtRefCount": 0,
            "isDeleted": false,
            "iseInstanceUuid": "944b2f30-8c01-11e6-996c-525400b48521",
            "mdnacDeletionStatus": "ACTIVE",
            "defaultContractsCount": 0,
            "iseReserved": false,
            "iseRetryCount": 0,
            "iseTimeStamp": "2019-08-23 20:27:19.712571000",
            "learnedFrom": "",
            "name": "Quarantined_Systems",
            "policyRefCount": 1,
            "propagateToAci": true,
            "pushed": true,
            "scalableGroupType": "USER-DEVICE",
            "securityGroupTag": "255/0xFF",
            "syncMessage": "",
            "syncStatus": "SYNCED",
            "tenantintsegment": 4104,
            "tenantlongsegment": 0,
            "virtualNetworkNames": "DEFAULT_VN",
            "virtualNetworksCount": "1",
            "vnAgnostic": false,
            "acaScalableGroupVNInfo": [
              {
                "id": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
                "instanceId": 0,
                "displayName": "0",
                "instanceVersion": 0,
                "name": "DEFAULT_VN"
              }
            ],
            "acaGBPListDestViewSummary":[],
            "acaGBPListSourceViewSummary": [
              {
                "id": "5294317d-c3a1-415b-ae69-c8e3e2f985b7",
                "instanceId": 124138,
                "displayName": "124138",
                "instanceVersion": 1,
                "contractRefCount": 1,
                "sgName": "Quarantined_Systems",
                "sgRefCount": 1,
                "tenantintsegment": 4104,
                "tenantlongsegment": 0
              }
            ]
          },
          {
            "id": "44a9eeda-e528-4c6b-9045-4f90dc873d40",
            "instanceId": 124141,
            "displayName": "124141",
            "instanceVersion": 1,
            "description": "Trusted Devices Group",
            "ipSgtRefCount": 0,
            "isDeleted": false,
            "iseInstanceUuid": "be280760-32f9-11e8-bd6e-005056b83f27",
            "mdnacDeletionStatus": "ACTIVE",
            "defaultContractsCount": 0,
            "iseReserved": false,
            "iseRetryCount": 0,
            "iseTimeStamp": "2019-08-23 20:27:19.712212000",
            "learnedFrom": "",
            "name": "TrustedDevices",
            "policyRefCount": 0,
            "propagateToAci": false,
            "pushed": true,
            "scalableGroupType": "USER-DEVICE",
            "securityGroupTag": "19/0x13",
            "syncMessage": "",
            "syncStatus": "SYNCED",
            "tenantintsegment": 4104,
            "tenantlongsegment": 0,
            "virtualNetworkNames": "DEFAULT_VN",
            "virtualNetworksCount": "1",
            "vnAgnostic": false,
            "acaScalableGroupVNInfo": [
              {
                "id": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
                "instanceId": 0,
                "displayName": "0",
                "instanceVersion": 0,
                "name": "DEFAULT_VN"
              }
            ],
            "acaGBPListDestViewSummary":[],
            "acaGBPListSourceViewSummary": []
          },
          {
            "id": "a4937cb5-299a-3294-5a48-1c3e3546959a",
            "instanceId": 124141,
            "displayName": "124141",
            "instanceVersion": 1,
            "description": "Badge Readers Group",
            "ipSgtRefCount": 0,
            "isDeleted": false,
            "iseInstanceUuid": "be280760-32f9-11e8-bd6e-005056b83f27",
            "mdnacDeletionStatus": "ACTIVE",
            "defaultContractsCount": 0,
            "iseReserved": false,
            "iseRetryCount": 0,
            "iseTimeStamp": "2019-08-23 20:27:19.712212000",
            "learnedFrom": "",
            "name": "Badge-Readers",
            "policyRefCount": 0,
            "propagateToAci": false,
            "pushed": true,
            "scalableGroupType": "USER-DEVICE",
            "securityGroupTag": "16/0x10",
            "syncMessage": "",
            "syncStatus": "SYNCED",
            "tenantintsegment": 4104,
            "tenantlongsegment": 0,
            "virtualNetworkNames": "DEFAULT_VN",
            "virtualNetworksCount": "1",
            "vnAgnostic": false,
            "acaScalableGroupVNInfo": [
              {
                "id": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
                "instanceId": 0,
                "displayName": "0",
                "instanceVersion": 0,
                "name": "DEFAULT_VN"
              }
            ],
            "acaGBPListDestViewSummary":[],
            "acaGBPListSourceViewSummary": []
          },{
            "id": "1146a728-655e-43e2-3785-b70f74f695b6",
            "instanceId": 124141,
            "displayName": "124141",
            "instanceVersion": 1,
            "description": "Untrusted Devices Security Group",
            "ipSgtRefCount": 0,
            "isDeleted": false,
            "iseInstanceUuid": "be280760-32f9-11e8-bd6e-005056b83f27",
            "mdnacDeletionStatus": "ACTIVE",
            "defaultContractsCount": 0,
            "iseReserved": false,
            "iseRetryCount": 0,
            "iseTimeStamp": "2019-08-23 20:27:19.712212000",
            "learnedFrom": "",
            "name": "UntrustedDevices",
            "policyRefCount": 0,
            "propagateToAci": false,
            "pushed": true,
            "scalableGroupType": "USER-DEVICE",
            "securityGroupTag": "20/0x14",
            "syncMessage": "",
            "syncStatus": "SYNCED",
            "tenantintsegment": 4104,
            "tenantlongsegment": 0,
            "virtualNetworkNames": "DEFAULT_VN",
            "virtualNetworksCount": "1",
            "vnAgnostic": false,
            "acaScalableGroupVNInfo": [
              {
                "id": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
                "instanceId": 0,
                "displayName": "0",
                "instanceVersion": 0,
                "name": "DEFAULT_VN"
              }
            ],
            "acaGBPListDestViewSummary":[],
            "acaGBPListSourceViewSummary": []
          },
          {
            "id": "b4270c75-9931-4458-a3cc-3426f6c3c2ef",
            "instanceId": 124140,
            "displayName": "124140",
            "instanceVersion": 1,
            "description": "TrustSec Devices Security Group",
            "ipSgtRefCount": 0,
            "isDeleted": false,
            "iseInstanceUuid": "947832a0-8c01-11e6-996c-525400b48521",
            "mdnacDeletionStatus": "ACTIVE",
            "defaultContractsCount": 0,
            "iseReserved": false,
            "iseRetryCount": 0,
            "iseTimeStamp": "2019-08-23 20:27:19.713160000",
            "learnedFrom": "",
            "name": "TrustSec_Devices",
            "policyRefCount": 0,
            "propagateToAci": true,
            "pushed": true,
            "scalableGroupType": "USER-DEVICE",
            "securityGroupTag": "2/0x02",
            "syncMessage": "",
            "syncStatus": "SYNCED",
            "tenantintsegment": 4104,
            "tenantlongsegment": 0,
            "virtualNetworkNames": "DEFAULT_VN",
            "virtualNetworksCount": "1",
            "vnAgnostic": false,
            "acaScalableGroupVNInfo": [
              {
                "id": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
                "instanceId": 0,
                "displayName": "0",
                "instanceVersion": 0,
                "name": "DEFAULT_VN"
              }
            ],
            "acaGBPListDestViewSummary":[],
            "acaGBPListSourceViewSummary": []
          },
          {
            "id": "b4270c75-9931-4458-a3cc-3426f6c4d3fg",
            "instanceId": 124140,
            "displayName": "124140",
            "instanceVersion": 1,
            "description": "No Auth Devices Group",
            "ipSgtRefCount": 0,
            "isDeleted": false,
            "iseInstanceUuid": "947832a0-8c01-11e6-996c-525400b59632",
            "mdnacDeletionStatus": "ACTIVE",
            "defaultContractsCount": 0,
            "iseReserved": false,
            "iseRetryCount": 0,
            "iseTimeStamp": "2019-08-23 20:27:19.713160000",
            "learnedFrom": "",
            "name": "No-Auth-Devices",
            "policyRefCount": 0,
            "propagateToAci": true,
            "pushed": true,
            "scalableGroupType": "USER-DEVICE",
            "securityGroupTag": "100/0x64",
            "syncMessage": "",
            "syncStatus": "SYNCED",
            "tenantintsegment": 4104,
            "tenantlongsegment": 0,
            "virtualNetworkNames": "DEFAULT_VN",
            "virtualNetworksCount": "1",
            "vnAgnostic": false,
            "acaScalableGroupVNInfo": [
              {
                "id": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
                "instanceId": 0,
                "displayName": "0",
                "instanceVersion": 0,
                "name": "DEFAULT_VN"
              }
            ],
            "acaGBPListDestViewSummary":[],
            "acaGBPListSourceViewSummary": []
          },
          {
            "id": "ef9dc6a6-105e-4845-a85b-c8ae1567c66d",
            "instanceId": 124124,
            "displayName": "124124",
            "instanceVersion": 1,
            "description": "Unknown Security Group",
            "ipSgtRefCount": 0,
            "isDeleted": false,
            "iseInstanceUuid": "92adf9f0-8c01-11e6-996c-525400b48521",
            "mdnacDeletionStatus": "ACTIVE",
            "defaultContractsCount": 0,
            "iseReserved": true,
            "iseRetryCount": 0,
            "iseTimeStamp": "2019-08-23 20:27:19.711439000",
            "learnedFrom": "",
            "name": "Unknown",
            "policyRefCount": 1,
            "propagateToAci": false,
            "pushed": true,
            "scalableGroupType": "USER-DEVICE",
            "securityGroupTag": "0/0x00",
            "syncMessage": "",
            "syncStatus": "SYNCED",
            "tenantintsegment": 4104,
            "tenantlongsegment": 0,
            "virtualNetworkNames": "DEFAULT_VN",
            "virtualNetworksCount": "1",
            "vnAgnostic": false,
            "acaScalableGroupVNInfo": [
              {
                "id": "9a92ee44-5c95-4c53-b2c0-2a91abe90e99",
                "instanceId": 0,
                "displayName": "0",
                "instanceVersion": 0,
                "name": "DEFAULT_VN"
              }
            ],
            "acaGBPListDestViewSummary":[
              {
                "id": "ef9dc6a6-105e-4845-a85b-c8ae1567c66d",
                "instanceId": 124124,
                "displayName": "124124",
                "instanceVersion": 1,
                "contractRefCount": 1,
                "sgName": "Unknown",
                "sgRefCount": 1,
                "tenantintsegment": 4104,
                "tenantlongsegment": 0
              }
            ],
            "acaGBPListSourceViewSummary": []
          }
        ]
      }
    ],
    "defaultPolicy_Template":[
      {
        "id": "e76f4e66-5cf5-4bdc-90cb-9abe9e29a415",
        "instanceId": 1188213,
        "displayName": "1188213",
        "instanceVersion": 0,
        "consumerId": "657847c4-7d69-4e41-864a-7ff8866e9b6f",
        "consumerName": "ANY",
        "consumerSgIseInstanceUuid": "935d4cc0-8c01-11e6-996c-525400b48521",
        "contractId": "ac3a6914-fa79-43d0-ae64-d0249026cffa",
        "contractIseInstanceUuid": "92919850-8c01-11e6-996c-525400b48521",
        "contractName": "permit",
        "isDeleted": false,
        "iseInstanceUuid": "d8b2b331-685c-11e8-bf4d-005056b83f27",
        "iseReserved": false,
        "iseRetryCount": 0,
        "iseTimeStamp": "2019-06-29 16:44:03.148128000",
        "isenabled": true,
        "isseeded": false,
        "name": "ANY-ANY",
        "policyStatus": "ENABLED",
        "policyscope": "2827e5bf-d291-3d54-aeda-3e21b29a9d5d",
        "priority": 1,
        "producerId": "657847c4-7d69-4e41-864a-7ff8866e9b6f",
        "producerName": "ANY",
        "producerSgIseInstanceUuid": "934557f0-8c01-11e6-996c-525400b48521",
        "provisioningstate": "0",
        "pushed": true,
        "reverseContractId": "ac3a6914-fa79-43d0-ae64-d0249026cffa",
        "reverseContractName": "permit",
        "syncMessage": "",
        "syncStatus": "CREATE",
        "tenantintsegment": 4104,
        "tenantlongsegment": 0
      }
    ],
    "GetNextSgt_Template": [
      {
        "instanceId": 0,
        "displayName": "0",
        "instanceVersion": 0,
        "securityGroupTag": 18
      }
    ],
    "ContractSummary_Template": [
      {
        "instanceId": 0,
        "displayName": "0",
        "instanceVersion": 0,
        "totalContractCount": 7,
        "acaContractSummary": [
          {
            "id": "d5fbf8e0-6793-4002-862d-9d35b795265d",
            "instanceId": 1188208,
            "displayName": "1188208",
            "instanceVersion": 0,
            "contractStatus": "deployed",
            "dateDeployed": 1566315839813,
            "description": "Sample contract to allow DHCP and DNS",
            "isDeleted": false,
            "iseInstanceUuid": "",
            "iseReserved": false,
            "iseRetryCount": 0,
            "iseTimeStamp": "",
            "lastupdatetime": 1566315839813,
            "name": "AllowDHCPDNS",
            "policyRefCount": 0,
            "policyStatus": "N/A",
            "pushed": true,
            "ruleCount": 2,
            "syncMessage": "",
            "syncStatus": "CREATE",
            "tenantintsegment": 4104,
            "tenantlongsegment": 0
          },
          {
            "id": "fd85c98f-8b31-4042-a169-32151c69ef41",
            "instanceId": 1188206,
            "displayName": "1188206",
            "instanceVersion": 0,
            "contractStatus": "deployed",
            "dateDeployed": 1566315839801,
            "description": "Sample contract to allow access to Web",
            "isDeleted": false,
            "iseInstanceUuid": "",
            "iseReserved": false,
            "iseRetryCount": 0,
            "iseTimeStamp": "",
            "lastupdatetime": 1566315839801,
            "name": "AllowWeb",
            "policyRefCount": 0,
            "policyStatus": "N/A",
            "pushed": true,
            "ruleCount": 2,
            "syncMessage": "",
            "syncStatus": "CREATE",
            "tenantintsegment": 4104,
            "tenantlongsegment": 0
          },
          {
            "id": "7bd6721d-5c4e-4390-ba2b-e70a108121be",
            "instanceId": 1188210,
            "displayName": "1188210",
            "instanceVersion": 0,
            "contractStatus": "deployed",
            "dateDeployed": 1566315839819,
            "description": "Contract with access action as Deny IP",
            "isDeleted": false,
            "iseInstanceUuid": "",
            "iseReserved": true,
            "iseRetryCount": 0,
            "iseTimeStamp": "",
            "lastupdatetime": 1566315839819,
            "name": "Deny",
            "policyRefCount": 0,
            "policyStatus": "N/A",
            "pushed": true,
            "ruleCount": 0,
            "syncMessage": "",
            "syncStatus": "CREATE",
            "tenantintsegment": 4104,
            "tenantlongsegment": 0
          },
          {
            "id": "88d667fd-1284-4db0-8798-7c6b58057aaf",
            "instanceId": 1188212,
            "displayName": "1188212",
            "instanceVersion": 0,
            "contractStatus": "deployed",
            "dateDeployed": 1566315839825,
            "description": "Contract with access action as Deny IP with logging",
            "isDeleted": false,
            "iseInstanceUuid": "",
            "iseReserved": true,
            "iseRetryCount": 0,
            "iseTimeStamp": "",
            "lastupdatetime": 1566315839825,
            "name": "Deny_IP_Log",
            "policyRefCount": 0,
            "policyStatus": "N/A",
            "pushed": true,
            "ruleCount": 0,
            "syncMessage": "",
            "syncStatus": "CREATE",
            "tenantintsegment": 4104,
            "tenantlongsegment": 0
          },
          {
            "id": "352c0574-a7e7-4d93-a101-64c1f08dcfb9",
            "instanceId": 1188207,
            "displayName": "1188207",
            "instanceVersion": 0,
            "contractStatus": "deployed",
            "dateDeployed": 1566315839807,
            "description": "Sample contract to block Remote Access and telnet services ",
            "isDeleted": false,
            "iseInstanceUuid": "",
            "iseReserved": false,
            "iseRetryCount": 0,
            "iseTimeStamp": "",
            "lastupdatetime": 1566315839807,
            "name": "DenyRemoteServices",
            "policyRefCount": 0,
            "policyStatus": "N/A",
            "pushed": true,
            "ruleCount": 4,
            "syncMessage": "",
            "syncStatus": "CREATE",
            "tenantintsegment": 4104,
            "tenantlongsegment": 0
          },
          {
            "id": "ac3a6914-fa79-43d0-ae64-d0249026cffa",
            "instanceId": 1188209,
            "displayName": "1188209",
            "instanceVersion": 0,
            "contractStatus": "deployed",
            "dateDeployed": 1566315839817,
            "description": "Contract with access action as Permit IP",
            "isDeleted": false,
            "iseInstanceUuid": "",
            "iseReserved": true,
            "iseRetryCount": 0,
            "iseTimeStamp": "",
            "lastupdatetime": 1566315839817,
            "name": "permit",
            "policyRefCount": 0,
            "policyStatus": "N/A",
            "pushed": true,
            "ruleCount": 0,
            "syncMessage": "",
            "syncStatus": "CREATE",
            "tenantintsegment": 4104,
            "tenantlongsegment": 0
          },
          {
            "id": "a89cdf03-d91e-4267-8b19-e8c5c97b7e78",
            "instanceId": 1188211,
            "displayName": "1188211",
            "instanceVersion": 0,
            "contractStatus": "deployed",
            "dateDeployed": 1566315839822,
            "description": "Contract with access action as Permit IP with logging",
            "isDeleted": false,
            "iseInstanceUuid": "",
            "iseReserved": true,
            "iseRetryCount": 0,
            "iseTimeStamp": "",
            "lastupdatetime": 1566315839822,
            "name": "Permit_IP_Log",
            "policyRefCount": 0,
            "policyStatus": "N/A",
            "pushed": true,
            "ruleCount": 0,
            "syncMessage": "",
            "syncStatus": "CREATE",
            "tenantintsegment": 4104,
            "tenantlongsegment": 0
          }
        ]
      }
    ],
    "GbpSourceListView_Template": [
    {
      "instanceId": 0,
      "displayName": "0",
      "instanceVersion": 0,
      "totalPolicyCount": 11,
      "acaGBPListDestViewSummary": [],
      "acaGBPListSourceViewSummary": []
    }
  ],
  "GbpDestListView_Template":[
    {
      "instanceId": 0,
      "displayName": "0",
      "instanceVersion": 0,
      "totalPolicyCount": 11,
      "acaGBPListDestViewSummary": [],
      "acaGBPListSourceViewSummary": []
    }
  ]
});
