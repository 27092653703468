define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/SimulationInit'
], function (SimulationInit) {
  var SimAppRegister = (function () {
      var INSTANCE;
      var registerApps = {};
      var bSimulationStarted = false;
      function SimAppRegister() {
          if (!(this instanceof SimAppRegister)) {
              return new SimAppRegister();
          }
      }
      function publishSimulationStarted() {
          if(bSimulationStarted == false) {
              return;
          }
          for(registerAppName in registerApps) {
              if(registerApps[registerAppName].bSent == false) {
                  registerApps[registerAppName].callbackMethod();
                  registerApps[registerAppName].bSent = true;
              }
          }
      }
      SimAppRegister.prototype.registerApp = function (appName, appCallback) {
          if(registerApps[appName] == undefined) {
              registerApps[appName] = { callbackMethod : appCallback, bSent : false };
          }
          publishSimulationStarted();
      };
      return {
          init: function (dbParameters) {
              if (!INSTANCE) {
                  registerApps = new Object();
                  SimulationInit.init(dbParameters, function() {
                      bSimulationStarted = true;
                      publishSimulationStarted();
                  });
                  return INSTANCE = SimAppRegister.apply(null, arguments);
              }
              return INSTANCE;
          },
          getInstance: function(dbParameters) {
              if (!INSTANCE) {
                  return this.init.apply(this, arguments);
              }
              return INSTANCE;
          },
          isSimulationStarted: function() {
              return bSimulationStarted;
          }
      };
  }());
  return SimAppRegister;
});
