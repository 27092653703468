define({
"Template":{
id: "",
label: "Service",
"timestamp": "",
isDeleted: false,
type: "vertex",
properties: {
  businessRelevance: [
    {
      value: ""
    }
  ],
  trafficClass: [
    {
      value: ""
    }
  ]
}
}
});
