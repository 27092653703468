define({
  "getRrmChangesLatestSummaryV201_Sim_Template":[
    {
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "rrmChanges": {
        "channelChangeCount": 2,
        "channelWidthChangeCount": 2,
        "fraChangeCount": 0,
        "totalImpactedRadios": 4,
        "totalRadios": 22,
        "txPowerChangeCount": 0
      },
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-21T04:30:00",
      "timestampMs": "1647837000000"
    }
  ],
  "getRrmHealthLatestSummary01_Sim_Template":[
    {
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "frequencyBand": 2,
      "rrmHealth": [
        {
          "apCount": 0,
          "healthScoreRange": "0-30",
          "healthScoreState": "Poor"
        },
        {
          "apCount": 2,
          "healthScoreRange": "31-60",
          "healthScoreState": "Fair"
        },
        {
          "apCount": 20,
          "healthScoreRange": "61-100",
          "healthScoreState": "Good"
        }
      ],
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2023-03-28T17:00:00",
      "timestampMs": "1680022800000"
    }
  ],
  "getCoChannelInterferenceLatestSummary01_Sim_Template":[
    {
      "buildingId": "f8ffb07d-4cc4-4912-9a3c-6905d4153941",
      "coChannelInterference": [
        {
          "apCount": 17,
          "impactScoreRange": "0-30",
          "impactScoreState": "Low"
        },
        {
          "apCount": 5,
          "impactScoreRange": "31-60",
          "impactScoreState": "Medium"
        },
        {
          "apCount": 0,
          "impactScoreRange": "61-100",
          "impactScoreState": "High"
        }
      ],
      "frequencyBand": 2,
      "siteId": "c924360c-d1b7-4147-9147-b4c6bb1c3ecb",
      "timestamp": "2022-03-21T04:30:00",
      "timestampMs": "1647837000000"
    }
  ]
});