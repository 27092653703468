define({
    "Assumptions_Template":{
        "costAssumption": {
          "annualSalary": "50000",
          "currency": "USD",
          "averageAnnualHours": "2087",
          "numberOfEmployees": "40"
        },
        "timeAssumptions": [
          {
            "traditional": "240",
            "dnac": "60",
            "kpiDesc": "Virtual Networks",
            "kpiName": "virtual_networks"
          },
          {
            "traditional": "240",
            "dnac": "30",
            "kpiDesc": "Images Activated",
            "kpiName": "image_activation_passed_count_with_pid_serialnumber_device_family_goldenimage"
          },
          {
            "traditional": "240",
            "dnac": "60",
            "kpiDesc": "Campus Network Assurance",
            "kpiName": "issues_resolved",
            "units": "minutes"
          },
          {
            "traditional": "240",
            "dnac": "60",
            "kpiDesc": "Network Device Onboarding",
            "kpiName": "pnp_provisioned",
            "units": "minutes"
          }
        ],
        "memberId": "604f9c1ae82dff000cf39473"
    },
    "Report_Template":{
        "fromDate": "2020-10-04",
        "toDate": "2021-10-04",
        "costAssumption": {
          "annualSalary": "50000",
          "numberOfEmployees": "40",
          "averageAnnualHours": "2087",
          "currency": "USD",
          "costFactor": 23.957834211787254
        },
        "memberId": "604f9c1ae82dff000cf39473",
        "rois": [
          {
            "kpiName": "issues_resolved",
            "kpiTitle": "Issues Resolved",
            "roiDesc": "Assurance feature that gives a quick overview of the health of every network device and client of the network, wired or wireless.",
            "roiTitle": "Campus Network Assurance",
            "totalTimeSaved": "2520.0",
            "totalCostSaved": "1006.2",
            "totalKpiUsage": "14",
            "timeAssumption": {
              "traditional": "240",
              "dnac": "60",
              "unit": "minutes",
              "timeFactor": 180
            },
            "roiIntervals": [
              {
                "fromDate": "2021-10-04",
                "toDate": "2021-09-04",
                "timeSaved": "2520.0",
                "costSaved": "1006.2",
                "kpiUsage": "14",
                "dataFound": true
              },
            ],
            "dataFound": true
          },
          {
            "kpiName": "pnp_provisioned",
            "kpiTitle": "Devices Onboarded",
            "roiDesc": "Zero-touch provisioning for new device installation. Allows off-the-shelf Cisco devices to be provisioned simply by connecting to the network.",
            "roiTitle": "Network Device Onboarding",
            "totalTimeSaved": "3960.0",
            "totalCostSaved": "1581.2",
            "totalKpiUsage": "22",
            "timeAssumption": {
              "traditional": "240",
              "dnac": "60",
              "unit": "minutes",
              "timeFactor": 180
            },
            "roiIntervals": [
              {
                "fromDate": "2021-09-04",
                "toDate": "2021-10-04",
                "timeSaved": "3960.0",
                "costSaved": "1581.2",
                "kpiUsage": "22",
                "dataFound": true
              }
            ],
            "dataFound": true
          },
          {
            "kpiName": "virtual_networks",
            "kpiTitle": "Virtual Networks",
            "roiDesc": "Set up compartmentalization across your network for security and policy enablement between and within segments.",
            "roiTitle": "Campus Network Segmentation",
            "totalTimeSaved": "180.0",
            "totalCostSaved": "71.8",
            "totalKpiUsage": "10",
            "timeAssumption": {
              "traditional": "240",
              "dnac": "60",
              "unit": "minutes",
              "timeFactor": 180
            },
            "roiIntervals": [
              {
                "fromDate": "2021-09-04",
                "toDate": "2021-10-04",
                "timeSaved": "180.0",
                "costSaved": "71.8",
                "kpiUsage": "10",
                "dataFound": true
              }
            ],
            "dataFound": true
          },
          {
            "kpiName": "image_activation_passed_count_with_pid_serialnumber_device_family_goldenimage",
            "kpiTitle": "Images Activated",
            "roiDesc": "Manages software upgrades and controls the consistency of image versions and configurations across your network.",
            "roiTitle": "Campus Software Image Management",
            "totalTimeSaved": "420.0",
            "totalCostSaved": "167.7",
            "totalKpiUsage": "2",
            "timeAssumption": {
              "traditional": "240",
              "dnac": "30",
              "unit": "minutes",
              "timeFactor": 210
            },
            "roiIntervals": [
              {
                "fromDate": "2021-09-04",
                "toDate": "2021-10-04",
                "timeSaved": "420.0",
                "costSaved": "167.7",
                "kpiUsage": "2",
                "dataFound": true
              }
            ],
            "dataFound": true
          }
        ]
      }
});