define({
  "Device_Image_Template" : {
      "runningImage": {
          "systemImage":{
              "imageName":"cat3k_polaris_16_5_1a_cco.bin",
               "imageUuid":null,
               "imageVersion":"16.5.1a",
               "imageFamily":"CAT3K_CAA",
               "imageSizeInBytes":null,
               "golden":false
          },
          "kickStartImage":null,
          "smuImages":[]
      },
      "goldenImage":{
          "systemImage": {
            "imageName": "cat3k_caa-universalk9.16.08.01a.SPA.bin",
            "imageUuid": "dab2803b-917f-4c32-b0ca-406b8b36229a",
            "imageVersion": "16.8.1a",
            "imageFamily": "CAT3K_CAA",
            "imageSizeInBytes": 425928073,
            "golden": true
         },
          "kickStartImage":null,
          "smuImages": []
      },
      "error":{
            "reason":"No golden",
            "detail":"Unable to find imported golden image."
      },
      "deviceUpgradeStatus":"UNKNOWN",
      "readinessCheckStatus": {
        "preCheckPassCount": 6,
        "preCheckFailCount": 0,
        "lastChecked": 1531702873803,
        "isReadyForUpgrade": true
      },
      "rebootRequired": false,
     "deviceInstalledInfo": [{
          "name": "CAT9K[16.10.1.0.167]",
          "type": "SUBPACKAGE",
          "version": "16.10.1.0.167",
          "family": "CAT9K",
          "golden": false,
          "subpackageType": null
        }

      ],
      "deviceId" :"bea0bb64-8b56-4f36-bfc7-f86ea0fff1b3"
  }
});
