define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimNfvisData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/nfv/NfvisTemplate'
], function (SimLokiDatabaseActions, UtilityFunctions, SimNfvisData, NfvisTemplate) {

    return {
        init: function() {
        },

        doProcessRestRequest: function(urlAction){
            var data;
            switch(urlAction.method) {
                case 'GET':
                    return processGetRequest(urlAction);
                    break;
                case 'POST':
                    return processPostRequest(urlAction);
                    break;
                case 'PUT':
                    return processPutRequest(urlAction);
                    break;
                case 'DELETE':
                    return processDeleteRequest(urlAction);
                    break;
                default:
                    console.log(urlAction);
                    break;
            }
            console.log('Something wrong, It should not have reached here..');
            return data;
        }
    };
    
    function processGetRequest(urlAction){
        if(urlAction.service.indexOf('vmanage-credentials')>-1) {
            return NfvisTemplate.CRED_TEMPLATE;
        } else if(urlAction.service.indexOf('ports')>-1) {
            if(urlAction.filter['network']=='lan') {
                return formatResponse(NfvisTemplate.LAN_PORT_TEMPLATE);
            } else if(urlAction.filter['network']=='wan') {
                return formatResponse(NfvisTemplate.WAN_PORT_TEMPLATE);
            }
        } else if(urlAction.service.indexOf('provision-logs')>-1) {
            return SimNfvisData.getProvisionLog(urlAction);
        } else if(urlAction.service.indexOf('workflow')>-1) {
            return SimNfvisData.getWorkFlowLevel1(urlAction);
        } else if(urlAction.service.indexOf('provision-log-status-detail')>-1) {
            return SimNfvisData.getWorkFlowLevel2(urlAction);
        } else if(urlAction.service.indexOf('nfv-service-chaining')>-1) {
            if(urlAction.service.indexOf('definition')>-1) {
                return JSON.parse(JSON.stringify(NfvisTemplate.NFV_SERVICE_DEFINITION));
            } else if(urlAction.service.indexOf('networkfunctions')>-1) {
                return SimNfvisData.getNetworkFunctions();
            }
        }
        return [];
    }

    function processPutRequest(urlAction) {
        
    }

    function processDeleteRequest(urlAction){
        
    }

    function processPostRequest(urlAction){
        return SimNfvisData.getProfileDetails(urlAction.restPayload);
    }

    function formatResponse(data) {
        return {"response":data, "version":"1.0", "message":null}
    }

});
