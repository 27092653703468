define({
    "Template":
    {
        "request": {
          "key": "ms-live-accounts",
          "type": "Application",
          "label": "ms-live-accounts",
          "collection": false,
          "componentContent": [
            {
              "component": "fusion",
              "additional": "4dec1fac-aef4-421e-9906-ba0977939f31"
            }
          ]
        },
        "data": {
          "response": {
            "id": "4dec1fac-aef4-421e-9906-ba0977939f31",
            "instanceId": 4775,
            "authEntityId": 4775,
            "displayName": "4775",
            "authEntityClass": -1909073334,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "createTime": 1528265513399,
            "deployed": false,
            "isSeeded": true,
            "isStale": false,
            "lastUpdateTime": 1528265513399,
            "name": "ms-live-accounts",
            "namespace": "scalablegroup:application",
            "provisioningState": "DEFINED",
            "qualifier": "application",
            "resourceVersion": 0,
            "targetIdList": [],
            "type": "scalablegroup",
            "cfsChangeInfo": [],
            "customProvisions": [],
            "scalableGroupExternalHandle": "ms-live-accounts",
            "scalableGroupType": "APPLICATION",
            "securityGroupTag": 0,
            "state": "ACTIVE",
            "vnAgnostic": true,
            "identitySource": {
              "id": "607e5f6a-d4bd-41e6-9861-bcd939c316b6",
              "instanceId": 10010,
              "authEntityId": 10010,
              "displayName": "10010",
              "authEntityClass": 1738953278,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "state": "INACTIVE",
              "type": "NBAR"
            },
            "indicativeNetworkIdentity": [],
            "networkApplications": [
              {
                "id": "381e8e12-fd08-421a-9201-5d81b77cde86",
                "instanceId": 11723,
                "authEntityId": 11723,
                "displayName": "11723",
                "authEntityClass": -217092956,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "appProtocol": "tcp/udp",
                "applicationGroup": "ms-cloud-group",
                "applicationType": "DEFAULT",
                "categoryId": "fb8d9520-2bd7-43aa-97b5-c202d09feec2",
                "createTime": 1528265513399,
                "encrypted": "true",
                "engineId": "13",
                "helpString": "Windows Live Services Authentication",
                "ignoreConflict": false,
                "lastUpdateTime": 1528265513399,
                "longDescription": "Ms-live-accounts is used for login to various microsoft services.",
                "name": "ms-live-accounts",
                "nbarId": "1434",
                "p2pTechnology": "false",
                "popularity": 10,
                "rank": 65535,
                "references": "http://login.live.com/",
                "selectorId": "498",
                "subCategory": "b57a227c-5cb5-43e7-a30c-527dc6d57358",
                "trafficClass": "TRANSACTIONAL_DATA",
                "tunnel": "false"
              }
            ],
            "networkIdentity": [
              {
                "id": "d9a36b22-e9f6-4f72-8328-385373cfafb6",
                "instanceId": 13977,
                "authEntityId": 13977,
                "displayName": "13977",
                "authEntityClass": 124090504,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "ipv4Subnet": [],
                "ipv6Subnet": [],
                "lowerPort": 0,
                "ports": "53,80,443,5353",
                "protocol": "TCP",
                "upperPort": 0
              },
              {
                "id": "803e043a-bd1a-4690-8bf5-408f3ff712fa",
                "instanceId": 13978,
                "authEntityId": 13978,
                "displayName": "13978",
                "authEntityClass": 124090504,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "ipv4Subnet": [],
                "ipv6Subnet": [],
                "lowerPort": 0,
                "ports": "53,5353",
                "protocol": "UDP",
                "upperPort": 0
              }
            ],
            "parentScalableGroup": {
              "idRef": "a431ac87-190b-4e81-bad0-3f8b1e0e1471"
            },
            "user": [],
            "userGroup": []
          }
        },
        "result": [
          {
            "bookID": {
              "namespace": "fusion",
              "name": "core",
              "version": "1.0.1"
            },
            "response": {
              "id": "4dec1fac-aef4-421e-9906-ba0977939f31",
              "instanceId": 4775,
              "authEntityId": 4775,
              "displayName": "4775",
              "authEntityClass": -1909073334,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "createTime": 1528265513399,
              "deployed": false,
              "isSeeded": true,
              "isStale": false,
              "lastUpdateTime": 1528265513399,
              "name": "ms-live-accounts",
              "namespace": "scalablegroup:application",
              "provisioningState": "DEFINED",
              "qualifier": "application",
              "resourceVersion": 0,
              "targetIdList": [],
              "type": "scalablegroup",
              "cfsChangeInfo": [],
              "customProvisions": [],
              "scalableGroupExternalHandle": "ms-live-accounts",
              "scalableGroupType": "APPLICATION",
              "securityGroupTag": 0,
              "state": "ACTIVE",
              "vnAgnostic": true,
              "identitySource": {
                "id": "607e5f6a-d4bd-41e6-9861-bcd939c316b6",
                "instanceId": 10010,
                "authEntityId": 10010,
                "displayName": "10010",
                "authEntityClass": 1738953278,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "state": "INACTIVE",
                "type": "NBAR"
              },
              "indicativeNetworkIdentity": [],
              "networkApplications": [
                {
                  "id": "381e8e12-fd08-421a-9201-5d81b77cde86",
                  "instanceId": 11723,
                  "authEntityId": 11723,
                  "displayName": "11723",
                  "authEntityClass": -217092956,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "appProtocol": "tcp/udp",
                  "applicationGroup": "ms-cloud-group",
                  "applicationType": "DEFAULT",
                  "categoryId": "fb8d9520-2bd7-43aa-97b5-c202d09feec2",
                  "createTime": 1528265513399,
                  "encrypted": "true",
                  "engineId": "13",
                  "helpString": "Windows Live Services Authentication",
                  "ignoreConflict": false,
                  "lastUpdateTime": 1528265513399,
                  "longDescription": "Ms-live-accounts is used for login to various microsoft services.",
                  "name": "ms-live-accounts",
                  "nbarId": "1434",
                  "p2pTechnology": "false",
                  "popularity": 10,
                  "rank": 65535,
                  "references": "http://login.live.com/",
                  "selectorId": "498",
                  "subCategory": "b57a227c-5cb5-43e7-a30c-527dc6d57358",
                  "trafficClass": "TRANSACTIONAL_DATA",
                  "tunnel": "false"
                }
              ],
              "networkIdentity": [
                {
                  "id": "d9a36b22-e9f6-4f72-8328-385373cfafb6",
                  "instanceId": 13977,
                  "authEntityId": 13977,
                  "displayName": "13977",
                  "authEntityClass": 124090504,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "ipv4Subnet": [],
                  "ipv6Subnet": [],
                  "lowerPort": 0,
                  "ports": "53,80,443,5353",
                  "protocol": "TCP",
                  "upperPort": 0
                },
                {
                  "id": "803e043a-bd1a-4690-8bf5-408f3ff712fa",
                  "instanceId": 13978,
                  "authEntityId": 13978,
                  "displayName": "13978",
                  "authEntityClass": 124090504,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "ipv4Subnet": [],
                  "ipv6Subnet": [],
                  "lowerPort": 0,
                  "ports": "53,5353",
                  "protocol": "UDP",
                  "upperPort": 0
                }
              ],
              "parentScalableGroup": {
                "idRef": "a431ac87-190b-4e81-bad0-3f8b1e0e1471"
              },
              "user": [],
              "userGroup": []
            }
          }
        ]
      }
    });