define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimDefaultDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimScalablegroupDafaultConfig',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimDeviceImageData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimConnectivityDomainData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimNetworkDeviceData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimVirtualNetworkData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimVirtualNetworkContextData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimPolicyData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimSegmentData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimSiteProfileData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimTopologyData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimScalableGroupData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimDesignData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimWirelessData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimVerifyData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimDiscoveryData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimAuditData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimDnaMapsData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/swim/SimSwimData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimIpPoolData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/assurance/SimAssuranceSensor',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimGroupData',
    //'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimAssuranceDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimTagMemberData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/assurance/SimScheduledCaptureData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimNfvisData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimOnboardingData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/license-manage/SimLicenseManagerData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimLicenseTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimTemplateProgrammerData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/assurance/SimAssuranceIssueSettingsData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimInterfaceData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimRogueManagementData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/bonjour/SimBonjourData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/kairos/models/SimKairosModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimHealthScoreThresholdSettingData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/assurance/SimPoeAnalyticsData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/audit-log/SimauditLogData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimPolicyEndpointAnalyticsData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/workflow-status/models/SimWorkFlowStatusModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/assurance/SimAssuranceCacheData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/inventory/InventoryInsightTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/EoxTemplate'
], function (SimDefaultDataGenerator, SimScalablegroupDafaultConfig, SimLokiDatabaseActions, SimDeviceImageData,
             SimTaskDataGenerator, SimConnectivityDomainData, SimNetworkDeviceData, SimVirtualNetworkData,
             SimVirtualNetworkContextData, SimPolicyData, SimSegmentData, SimSiteProfileData, SimTopologyData,
             SimScalableGroupData, SimDesignData, SimWirelessData, SimVerifyData,
             SimDiscoveryData, SimAuditData, SimDnaMapsData, SimSwimData,SimIpPoolData, SimAssuranceSensor,SimGroupData,
             SimTagMemberData, SimScheduledCaptureData, SimNfvisData,SimOnboardingData,SimLicenseManagerData, SimLicenseTaskDataGenerator,
             SimTemplateProgrammerData, SimAssuranceIssueSettingsData, SimInterfaceData, SimRogueManagementData,SimBonjourData,
             SimKairosModels, SimHealthScoreThresholdSettingData, SimPoeAnalyticsData, SimauditLogData, SimPolicyEndpointAnalyticsData,
             SimWorkFlowStatusModels,SimAssuranceCacheData, InventoryInsightTemplate, UtilityFunctions, EoxTemplate) {

    return {

        init: function (dbParameters) {

            var tObjs = SimLokiDatabaseActions.getAll('network-device');
            if( (dbParameters.KEEP_EXISTING_DATA != undefined && dbParameters.KEEP_EXISTING_DATA) && tObjs.length) {
                console.log('data generation init exit without creating data as data already there...');
                return;
            }

            SimDefaultDataGenerator.init(dbParameters);
            SimScalablegroupDafaultConfig.init();
            SimTaskDataGenerator.init();
            SimVirtualNetworkData.init();
            SimVirtualNetworkContextData.init();
            SimNetworkDeviceData.init();
            SimTopologyData.init();
            SimGroupData.init();
            SimDesignData.init();
            SimIpPoolData.init();
            SimSiteProfileData.init();
            SimPolicyData.init();
            SimSegmentData.init();
            SimScalableGroupData.init();
            SimWirelessData.init();
            SimVerifyData.init();
            SimDiscoveryData.init();
            SimAuditData.init();
            //SimAssuranceDataGenerator.init();
            SimDnaMapsData.init();
            SimSwimData.init();
            SimDeviceImageData.init();
            SimConnectivityDomainData.init();
            SimAssuranceSensor.init();
            SimTagMemberData.init();
            SimScheduledCaptureData.init();
            SimNfvisData.init();
            SimOnboardingData.init();
            SimLicenseManagerData.init();
            SimLicenseTaskDataGenerator.init();
            SimTemplateProgrammerData.init();
            SimAssuranceIssueSettingsData.init();
            //SimInterfaceData.init();
            SimRogueManagementData.init();
            SimBonjourData.init();
            SimAssuranceCacheData.init();
            SimKairosModels.init();
            SimHealthScoreThresholdSettingData.init();
            SimPoeAnalyticsData.init();
            SimauditLogData.init();
            SimPolicyEndpointAnalyticsData.init();
            SimWorkFlowStatusModels.init();
            SimTopologyData.initCustomFabricTopology();
            this.initInventoryInsight();
            this.deviceEox();

            console.log('data generation init exit...');
        },

        cfsCUDoperation: function (type, operation, jsonObj, paramNamesAndValues) {
            if (type == 'ConnectivityDomain') {
                var isError = SimConnectivityDomainData.CUDonCD(operation, jsonObj, paramNamesAndValues);
                return isError;
            }
            else if (type == 'DeviceInfo') {
                SimNetworkDeviceData.CreateOrUpdateDeviceInfo(type, operation, jsonObj);
            }
            else if (type == 'virtualnetworkcontext') {
                SimVirtualNetworkContextData.CreateOrUpdateOrDeleteVNContext(operation, jsonObj);
            }
            else if (type == 'VirtualNetwork') {
                SimVirtualNetworkData.AssociateVnToSegment(jsonObj);
            }
            else if(type == "DeviceInterfaceInfoContainer") {
                SimInterfaceData.DeviceInterfaceInfoContainer_Put(jsonObj);
            }
            else if (type == 'AccessPolicy') {
                SimPolicyData.CUDPolicyAccess(operation, jsonObj);
            } else if (type == 'CopyPolicy') {
                SimPolicyData.CUDCopyPolicy(operation, jsonObj);
            }
            else if (type == 'ContractAccess') {
                SimPolicyData.CUDContractAccess(operation, jsonObj);
            }
            else if (type == 'IPAccessContract') {
                SimPolicyData.CUDIPAccessContract(operation, jsonObj);
            }
            else if (type == 'IPAccessControl') {
                SimPolicyData.CUDIPAccessControl(operation, jsonObj);
            }
            else if (type == 'scalablegroup') {
                return SimScalableGroupData.CUDScalableGroup(operation, jsonObj);
            } else if (type == 'copyContract') {
                SimPolicyData.CUDCopyContract(operation, jsonObj);
            } else if (type == 'CopyDestination') {
                SimPolicyData.CUDCopyDestination(operation, jsonObj);
            }
            else if (type == 'Segment') {
                SimSegmentData.segmentUpdate(operation, jsonObj);
            }
            //Handle the AP Wlaninfo into the table
            else if (type == 'ApWirelessConfiguration') {
              SimWirelessData.apWirelessCUD(type,operation,jsonObj);
            }
            else if (type == 'ApplicationPolicy') {
                SimPolicyData.CUDApplicationPolicy(operation, jsonObj);
            }
             else if (type == 'ApplicationPolicyPreview') {
                SimPolicyData.CUDApplicationPreviewPolicy(operation, jsonObj);
            }
            //queueProfile handling
            else if (type == 'ApplicationContract') {
                SimPolicyData.CUDApplicationContract(operation, jsonObj);
            }
        },
        createDeviceConfigStatus: function(jsonObj) {
            SimNetworkDeviceData.createDeviceConfigStatus(jsonObj);
        },

        initInventoryInsight() {
            var tJson;
            var devices = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': {'$in':
                ["CAMPUS-CORE2","BLD2-FLR1-DST1","BLD2-FLR2-DST1", "CAMPUS-CORE3","BLD3-FLR1-DST1","BLD3-FLR2-DST1"] }} );
            var inputs = {
                "duplex":[
                    {"stDev":"CAMPUS-CORE2","stPort":"","stDuplex":"FullDuplex","endDev":"BLD2-FLR1-DST1","endPort":"","endDuplex":"AutoNegotiate"},
                    {"stDev":"CAMPUS-CORE3","stPort":"","stDuplex":"FullDuplex","endDev":"BLD3-FLR1-DST1","endPort":"","endDuplex":"AutoNegotiate"}
                ],"vlan":[
                    {"stDev":"CAMPUS-CORE2","stPort":"","stAllowedVlanIds":"ALL","stNativeVlanId":"1","endDev":"BLD2-FLR2-DST1","endPort":"","endAllowedVlanIds":"1,1871-1875","endNativeVlanId":"1"},
                    {"stDev":"CAMPUS-CORE3","stPort":"","stAllowedVlanIds":"ALL","stNativeVlanId":"1","endDev":"BLD3-FLR2-DST1","endPort":"","endAllowedVlanIds":"1,1871-1875","endNativeVlanId":"1"}
                ]
            };
            
            //deviceId, portName, id, interfaceType="Physical", adminStatus "UP", status "UP", duplex
            inputs.duplex.forEach( inpt => {
                var stDev = devices.filter(e=>e.hostname==inpt.stDev), endDev = devices.filter(e=>e.hostname==inpt.endDev), temp;
                var stDevPort = SimLokiDatabaseActions.getFilteredRecordHandler('device-interface', 
                    {'$and': [ {'deviceId':stDev[0].id},{'interfaceType':'Physical'},{'duplex':inpt.stDuplex} ]});
                var endDevPort = SimLokiDatabaseActions.getFilteredRecordHandler('device-interface', 
                    {'$and': [ {'deviceId':endDev[0].id},{'interfaceType':'Physical'},{'duplex':inpt.endDuplex} ]});
                if(stDevPort.length>0) {
                    temp = stDevPort.filter(e=>e.adminStatus=="UP" && e.status=="UP");
                    stDevPort = temp.length>0 ? temp : stDevPort;
                }
                if(endDevPort.length>0) {
                    temp = endDevPort.filter(e=>e.adminStatus=="UP" && e.status=="UP");
                    endDevPort = temp.length>0 ? temp : endDevPort;
                }
                tJson = JSON.parse(JSON.stringify(InventoryInsightTemplate.INV_INSIGHT_TEMPLATE));
                tJson.endDeviceHostName = inpt.endDev;
                tJson.endDeviceId = endDev[0].id;
                tJson.endDeviceIpAddress = endDev[0].managementIpAddress;
                tJson.endPortDuplex = inpt.endDuplex;
                // tJson.endPortName = endDevPort.length>0 ? endDevPort[0].portName : tJson.endPortName ;
                tJson.endPortName = tJson.endPortName ;
                tJson.startDeviceHostName = inpt.stDev;
                tJson.startDeviceId = stDev[0].id;
                tJson.startDeviceIpAddress = stDev[0].managementIpAddress;
                tJson.startPortDuplex = inpt.stDuplex;
               // tJson.startPortName = stDevPort.length>0 ? stDevPort[0].portName : tJson.startPortName ;
                tJson.startPortName = tJson.startPortName ;
                tJson.id = UtilityFunctions.generate_uuid();
                tJson.simSiteId = stDev[0].siteId;
                SimLokiDatabaseActions.insert('inventory-insight', tJson);
            })
            inputs.vlan.forEach( inpt => {
                var stDev = devices.filter(e=>e.hostname==inpt.stDev), endDev = devices.filter(e=>e.hostname==inpt.endDev);
                var stDevPort = SimLokiDatabaseActions.getFilteredRecordHandler('device-interface', 
                    {'$and': [ {'deviceId':stDev[0].id},{'interfaceType':'Physical'} ]});
                var endDevPort = SimLokiDatabaseActions.getFilteredRecordHandler('device-interface', 
                    {'$and': [ {'deviceId':endDev[0].id},{'interfaceType':'Physical'} ]});
                if(stDevPort.length>0) {
                    temp = stDevPort.filter(e=>e.adminStatus=="UP" && e.status=="UP");
                    stDevPort = temp.length>0 ? temp : stDevPort;
                }
                if(endDevPort.length>0) {
                    temp = endDevPort.filter(e=>e.adminStatus=="UP" && e.status=="UP");
                    endDevPort = temp.length>0 ? temp : endDevPort;
                }
                tJson = JSON.parse(JSON.stringify(InventoryInsightTemplate.INV_INSIGHT_TEMPLATE));
                tJson.endDeviceHostName = inpt.endDev;
                tJson.endDeviceId = endDev[0].id;
                tJson.endDeviceIpAddress = endDev[0].managementIpAddress;
                tJson.endPortAllowedVlanIds = inpt.endAllowedVlanIds;
                tJson.endPortNativeVlanId = inpt.endNativeVlanId;
                // tJson.endPortName = endDevPort.length>0 ? endDevPort[0].portName : tJson.endPortName ;
                tJson.endPortName = tJson.endPortName ;
                tJson.startDeviceHostName = inpt.stDev;
                tJson.startDeviceId = stDev[0].id;
                tJson.startDeviceIpAddress = stDev[0].managementIpAddress;
                tJson.startPortAllowedVlanIds = inpt.stAllowedVlanIds;
                tJson.startPortNativeVlanId = inpt.stNativeVlanId;
                // tJson.startPortName = stDevPort.length>0 ? stDevPort[0].portName : tJson.startPortName ;
                tJson.startPortName = tJson.startPortName ;
                tJson.id = UtilityFunctions.generate_uuid();
                tJson.simSiteId = stDev[0].siteId;
                tJson.instanceTenantId = "5f35ea5f15de5300c6a0162b";
                SimLokiDatabaseActions.insert('inventory-insight', tJson);
            })
        },

        deviceEox() {
            let devices = SimLokiDatabaseActions.getAll('network-device');
            devices.forEach(dev => {
                var obj = {
                    "alertCount": -10,
                    "comments": ["MLCN10314: Unified Access Points are currently not supported for identifying EOX alerts."],
                    "scanStatus": "NOT_SCANNED",
                    "summary": [],
                    "deviceId": dev.id,
                    "eoxDetails": []
                }
 
                if(["Switches and Hubs","Routers","Wireless Controller","NFVIS","Wireless Sensor"].indexOf(dev.family) > -1) {
                    //NFVIS and Sensor - need to verify as ref cluster doesnt have these data
                    obj.comments = [];
                    obj.scanStatus = "SUCCESS";
                    switch (dev.softwareVersion) {
                        case "17.6.1":
                        case "17.6.1a":
                        case "17.7.1":
                        case "17.3.2":
                        case "17.3.2a":
                            obj.summary = ["SOFTWARE"];
                            if(dev.platformId=="9500-24Y4C") obj.summary.push("MODULE");
                            break;
                        case "17.3.3":
                        case "17.8.1":
                        case "17.7.2":
                            obj.summary = ["MODULE","SOFTWARE"]; break;
                        case "16.12.6":
                            obj.summary = ["HARDWARE","MODULE","SOFTWARE"]; break;
                        case "15.5.1-SY6":
                            obj.summary = ["MODULE","HARDWARE"]; break;
                        default:
                            //obj.summary = ["SOFTWARE"]; //not sure of the default clause. temporarily adding.
                            break;
                    }
                    obj.alertCount = obj.summary.length;
                    addEoxDetails(obj,dev.softwareVersion);
                }
                SimLokiDatabaseActions.insert('deviceEox', obj);
            });
        }
    };

    function addEoxDetails(obj,version) {
        if(obj.summary.indexOf("SOFTWARE")>-1) {
            obj.eoxDetails.push(EoxTemplate.SOFTWARE_17_3);
            if(version.startsWith("17.2")) {
                obj.eoxDetails[0].bulletinURL = EoxTemplate.SOFTWARE_LINKS["17.2"];
            } else if(version.startsWith("17.4")) {
                obj.eoxDetails[0].bulletinURL = EoxTemplate.SOFTWARE_LINKS["17.4"];
            } else if(version.startsWith("17.5")) {
                obj.eoxDetails[0].bulletinURL = EoxTemplate.SOFTWARE_LINKS["17.5"];
            } else if(version.startsWith("17.6")) {
                obj.eoxDetails[0].bulletinURL = EoxTemplate.SOFTWARE_LINKS["17.6"];
            } else if(version.startsWith("17.7")) {
                obj.eoxDetails[0].bulletinURL = EoxTemplate.SOFTWARE_LINKS["17.7"];
            } else if(version.startsWith("17.8")) {
                obj.eoxDetails[0].bulletinURL = EoxTemplate.SOFTWARE_LINKS["17.8"];
            }
        }
        if(obj.summary.indexOf("HARDWARE")>-1) {
            obj.eoxDetails.push(EoxTemplate.HARDWARE);
        }
        if(obj.summary.indexOf("MODULE")>-1) {
            obj.eoxDetails.push(EoxTemplate.MODULE_17_3);
        }
    }
});
