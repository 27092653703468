define({
"Template":
{
  "name": "fabric_reachability_session",
  "severity": "HIGH",
  "id": "AWWClkvGDK-gCyB5vfsN",
  "time": "2018-08-28T22:10:12.332+0000",
  "endTime": "2018-08-28T22:10:12.332+0000",
  "count": 1,
  "attributes": [
    {
      "key": "destIp",
      "value": "192.168.130.1"
    },
    {
      "key": "managementIpAddr",
      "value": "192.168.120.5"
    },
    {
      "key": "type",
      "value": "issue"
    },
    {
      "key": "uuid",
      "value": "124a8a0f-4627-4f0b-8b21-dcc9358f3957"
    },
    {
      "key": "sourceRole",
      "value": "Edge"
    },
    {
      "key": "scope",
      "value": ""
    },
    {
      "key": "rootCause",
      "value": "192.168.120.5_192.168.130.1_MapServerBorderUnderlay"
    },
    {
      "key": "sessionType",
      "value": "MapServerBorderUnderlay"
    },
    {
      "key": "entityType",
      "value": "network_device"
    },
    {
      "key": "__entity_type__",
      "value": "NetworkDevice"
    },
    {
      "key": "destMgmtIp",
      "value": "192.168.130.1"
    },
    {
      "key": "entityId",
      "value": "192.168.120.5"
    },
    {
      "key": "vrf",
      "value": ""
    },
    {
      "key": "sessionIdentifier",
      "value": "192.168.120.5_192.168.130.1_MapServerBorderUnderlay"
    },
    {
      "key": "sourceIp",
      "value": "192.168.120.5"
    },
    {
      "key": "category",
      "value": "connected"
    },
    {
      "key": "status",
      "value": "active"
    }
  ],
  "source": "internal",
  "isActive": false
}
})