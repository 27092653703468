define({
  "Template": {
    "version": "1.0",
    "response": [

      {
        "name": "Re-Authentication_1532593793608_1532593793792",
        "id": "AWTVuS3cpSLeWrHL-1EW",
        "eventName": "Re-Authentication",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532593793608"
          },
          {
            "key": "endTime",
            "value": "1532593793792"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Authentication Start",
            "id": "1532593793608_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532593793608,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532593793768_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532593793768,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532593793792_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532593793792,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532593793792_#_9",
            "eventName": "Run",
            "timestamp": 1532593793792,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532593951437_1532594120499",
        "id": "AWTVvNdjpSLeWrHL-1En",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532593951437"
          },
          {
            "key": "endTime",
            "value": "1532594120499"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532593951437_#_7",
            "eventName": "DHCP",
            "timestamp": 1532593951437,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532594101889_#_7",
            "eventName": "DHCP",
            "timestamp": 1532594101889,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532594120137_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532594120137,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532594120470_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532594120470,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532594120499_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532594120499,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532594120499_#_9",
            "eventName": "Run",
            "timestamp": 1532594120499,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532594402687_1532594419679",
        "id": "AWTVwLs1pSLeWrHL-1FB",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532594402687"
          },
          {
            "key": "endTime",
            "value": "1532594419679"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532594402687_#_7",
            "eventName": "DHCP",
            "timestamp": 1532594402687,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532594419429_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532594419429,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532594419661_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532594419661,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532594419679_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532594419679,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532594419679_#_9",
            "eventName": "Run",
            "timestamp": 1532594419679,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532594496225_1532594499505",
        "id": "AWTVwLs1pSLeWrHL-1FC",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_FAIL",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "4 way Key Timeout/WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532594496225"
          },
          {
            "key": "endTime",
            "value": "1532594499505"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532594496225_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532594496225,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532594499505_#_5",
            "eventName": "KeyExchange ",
            "timestamp": 1532594499505,
            "index": 1,
            "resultStatus": "CL_FAIL",
            "details": [
              {
                "key": "additionalInfo",
                "value": "4 way Key Timeout"
              },
              {
                "key": "VLAN",
                "value": "120"
              },
              {
                "key": "WLC_Name",
                "value": "WLC-9800"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532594499505_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532594499505,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Delete_1532594519937_1532594530149",
        "id": "AWTVxCo1pSLeWrHL-1FU",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532594519937"
          },
          {
            "key": "endTime",
            "value": "1532594530149"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Client Deauthenticated",
            "id": "1532594519937_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532594519937,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Deleted",
            "id": "1532594530149_#_11",
            "eventName": "Deleted",
            "timestamp": 1532594530149,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532594556920_1532594562294",
        "id": "AWTVxCo1pSLeWrHL-1FV",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_FAIL_PEM_TIMEOUT",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532594556920"
          },
          {
            "key": "endTime",
            "value": "1532594562294"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532594556920_#_1",
            "eventName": "Association Start",
            "timestamp": 1532594556920,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532594556920_#_2",
            "eventName": "Association Done",
            "timestamp": 1532594556920,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532594556925_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532594556925,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532594557154_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532594557154,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532594557188_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532594557188,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532594557188_#_6",
            "eventName": "Mobility",
            "timestamp": 1532594557188,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532594562241_#_7",
            "eventName": "DHCP ",
            "timestamp": 1532594562241,
            "index": 6,
            "resultStatus": "CL_FAIL_PEM_TIMEOUT",
            "details": [
              {
                "key": "additionalInfo",
                "value": "DHCP Timeout"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532594562294_#_7",
            "eventName": "DHCP",
            "timestamp": 1532594562294,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532594562294_#_9",
            "eventName": "Run",
            "timestamp": 1532594562294,
            "index": 8,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532594582681_1532594587143",
        "id": "AWTVxGTVpSLeWrHL-1Fm",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532594582681"
          },
          {
            "key": "endTime",
            "value": "1532594587143"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Client Deauthenticated",
            "id": "1532594582681_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532594582681,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Start",
            "id": "1532594586911_#_1",
            "eventName": "Association Start",
            "timestamp": 1532594586911,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532594586912_#_2",
            "eventName": "Association Done",
            "timestamp": 1532594586912,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532594586914_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532594586914,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532594587123_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532594587123,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532594587143_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532594587143,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532594587143_#_9",
            "eventName": "Run",
            "timestamp": 1532594587143,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532594804637_1532594888793",
        "id": "AWTVyahopSLeWrHL-1GP",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532594804637"
          },
          {
            "key": "endTime",
            "value": "1532594888793"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532594804637_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532594804637,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532594804817_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532594804817,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532594887875_#_7",
            "eventName": "DHCP",
            "timestamp": 1532594887875,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532594888397_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532594888397,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532594888769_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532594888769,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532594888793_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532594888793,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532594888793_#_9",
            "eventName": "Run",
            "timestamp": 1532594888793,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532595038329_1532595189840",
        "id": "AWTVzVGYpSLeWrHL-1Gq",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532595038329"
          },
          {
            "key": "endTime",
            "value": "1532595189840"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532595038329_#_7",
            "eventName": "DHCP",
            "timestamp": 1532595038329,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532595115153_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532595115153,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532595115405_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532595115405,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532595188630_#_7",
            "eventName": "DHCP",
            "timestamp": 1532595188630,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532595189645_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532595189645,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532595189819_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532595189819,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532595189840_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532595189840,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532595189840_#_9",
            "eventName": "Run",
            "timestamp": 1532595189840,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532595423605_1532595526528",
        "id": "AWTV0TYvpSLeWrHL-1HX",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532595423605"
          },
          {
            "key": "endTime",
            "value": "1532595526528"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532595423605_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532595423605,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532595423821_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532595423821,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532595489230_#_7",
            "eventName": "DHCP",
            "timestamp": 1532595489230,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532595526133_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532595526133,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532595526507_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532595526507,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532595526528_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532595526528,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532595526528_#_9",
            "eventName": "Run",
            "timestamp": 1532595526528,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532595731037_1532595839897",
        "id": "AWTV1rPrpSLeWrHL-1Hn",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532595731037"
          },
          {
            "key": "endTime",
            "value": "1532595839897"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532595731037_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532595731037,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532595731327_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532595731327,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532595789849_#_7",
            "eventName": "DHCP",
            "timestamp": 1532595789849,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532595839461_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532595839461,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532595839874_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532595839874,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532595839897_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532595839897,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532595839897_#_9",
            "eventName": "Run",
            "timestamp": 1532595839897,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532596040513_1532596170413",
        "id": "AWTV2iOHpSLeWrHL-1H1",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532596040513"
          },
          {
            "key": "endTime",
            "value": "1532596170413"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532596040513_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532596040513,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532596040667_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532596040667,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532596090448_#_7",
            "eventName": "DHCP",
            "timestamp": 1532596090448,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532596169873_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532596169873,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532596170391_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532596170391,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532596170413_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532596170413,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532596170413_#_9",
            "eventName": "Run",
            "timestamp": 1532596170413,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532596346885_1532596471464",
        "id": "AWTV4XZapSLeWrHL-1Ix",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532596346885"
          },
          {
            "key": "endTime",
            "value": "1532596471464"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532596346885_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532596346885,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532596346949_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532596346949,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532596391112_#_7",
            "eventName": "DHCP",
            "timestamp": 1532596391112,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532596471165_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532596471165,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532596471445_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532596471445,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532596471464_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532596471464,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532596471464_#_9",
            "eventName": "Run",
            "timestamp": 1532596471464,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532596653221_1532596765040",
        "id": "AWTV40shpSLeWrHL-1JS",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532596653221"
          },
          {
            "key": "endTime",
            "value": "1532596765040"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532596653221_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532596653221,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532596653524_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532596653524,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532596691711_#_7",
            "eventName": "DHCP",
            "timestamp": 1532596691711,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532596764425_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532596764425,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532596765019_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532596765019,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532596765039_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532596765039,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532596765040_#_9",
            "eventName": "Run",
            "timestamp": 1532596765040,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532596960613_1532597049036",
        "id": "AWTV6QQfpSLeWrHL-1J4",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532596960613"
          },
          {
            "key": "endTime",
            "value": "1532597049036"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532596960613_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532596960613,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532596960725_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532596960725,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532596992350_#_7",
            "eventName": "DHCP",
            "timestamp": 1532596992350,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532597048621_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532597048621,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532597049004_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532597049004,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532597049036_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532597049036,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532597049036_#_9",
            "eventName": "Run",
            "timestamp": 1532597049036,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532597270049_1532597371544",
        "id": "AWTV7ka6pSLeWrHL-1Kn",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532597270049"
          },
          {
            "key": "endTime",
            "value": "1532597371544"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532597270049_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532597270049,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532597270373_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532597270373,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532597292964_#_7",
            "eventName": "DHCP",
            "timestamp": 1532597292964,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532597370977_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532597370977,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532597371525_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532597371525,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532597371544_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532597371544,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532597371544_#_9",
            "eventName": "Run",
            "timestamp": 1532597371544,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532597576441_1532597685518",
        "id": "AWTV88UipSLeWrHL-1K7",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532597576441"
          },
          {
            "key": "endTime",
            "value": "1532597685518"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532597576441_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532597576441,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532597576651_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532597576651,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532597593462_#_7",
            "eventName": "DHCP",
            "timestamp": 1532597593462,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532597685341_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532597685341,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532597685492_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532597685492,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532597685518_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532597685518,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532597685518_#_9",
            "eventName": "Run",
            "timestamp": 1532597685518,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532597883841_1532597985928",
        "id": "AWTV928YpSLeWrHL-1LO",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532597883841"
          },
          {
            "key": "endTime",
            "value": "1532597985928"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532597883841_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532597883841,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532597884158_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532597884158,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532597894041_#_7",
            "eventName": "DHCP",
            "timestamp": 1532597894041,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532597985593_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532597985593,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532597985906_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532597985906,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532597985928_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532597985928,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532597985928_#_9",
            "eventName": "Run",
            "timestamp": 1532597985928,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532598190273_1532598307886",
        "id": "AWTV_O0dpSLeWrHL-1L4",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532598190273"
          },
          {
            "key": "endTime",
            "value": "1532598307886"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532598190273_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532598190273,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532598190436_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532598190436,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532598194676_#_7",
            "eventName": "DHCP",
            "timestamp": 1532598194676,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532598306965_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532598306965,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532598307861_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532598307861,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532598307886_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532598307886,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532598307886_#_9",
            "eventName": "Run",
            "timestamp": 1532598307886,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532598495290_1532598592336",
        "id": "AWTWAJcUpSLeWrHL-1Mf",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532598495290"
          },
          {
            "key": "endTime",
            "value": "1532598592336"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532598495290_#_7",
            "eventName": "DHCP",
            "timestamp": 1532598495290,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532598497721_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532598497721,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532598497932_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532598497932,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532598592165_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532598592165,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532598592312_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532598592312,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532598592336_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532598592336,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532598592336_#_9",
            "eventName": "Run",
            "timestamp": 1532598592336,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532598795931_1532598918271",
        "id": "AWTWBhVvpSLeWrHL-1ND",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532598795931"
          },
          {
            "key": "endTime",
            "value": "1532598918271"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532598795931_#_7",
            "eventName": "DHCP",
            "timestamp": 1532598795931,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532598807221_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532598807221,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532598807274_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532598807274,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532598917553_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532598917553,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532598918247_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532598918247,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532598918271_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532598918271,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532598918271_#_9",
            "eventName": "Run",
            "timestamp": 1532598918271,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532599096567_1532599225171",
        "id": "AWTWCb66pSLeWrHL-1Ng",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532599096567"
          },
          {
            "key": "endTime",
            "value": "1532599225171"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532599096567_#_7",
            "eventName": "DHCP",
            "timestamp": 1532599096567,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532599114673_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532599114673,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532599114787_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532599114787,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532599224841_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532599224841,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532599225150_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532599225150,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532599225171_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532599225171,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532599225171_#_9",
            "eventName": "Run",
            "timestamp": 1532599225171,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532599547431_1532599561621",
        "id": "AWTWERF-pSLeWrHL-1N6",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532599547431"
          },
          {
            "key": "endTime",
            "value": "1532599561621"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532599547431_#_7",
            "eventName": "DHCP",
            "timestamp": 1532599547431,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532599561285_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532599561285,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532599561595_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532599561595,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532599561621_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532599561621,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532599561621_#_9",
            "eventName": "Run",
            "timestamp": 1532599561621,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532599697767_1532599863888",
        "id": "AWTWFLt2pSLeWrHL-1OQ",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532599697767"
          },
          {
            "key": "endTime",
            "value": "1532599863888"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532599697767_#_7",
            "eventName": "DHCP",
            "timestamp": 1532599697767,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532599733649_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532599733649,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532599733777_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532599733777,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532599848107_#_7",
            "eventName": "DHCP",
            "timestamp": 1532599848107,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532599863589_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532599863589,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532599863865_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532599863865,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532599863888_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532599863888,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532599863888_#_9",
            "eventName": "Run",
            "timestamp": 1532599863888,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532600042097_1532600200288",
        "id": "AWTWGjjIpSLeWrHL-1PD",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532600042097"
          },
          {
            "key": "endTime",
            "value": "1532600200288"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532600042097_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532600042097,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532600042199_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532600042199,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532600148666_#_7",
            "eventName": "DHCP",
            "timestamp": 1532600148666,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532600200009_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532600200009,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532600200266_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532600200266,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532600200288_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532600200288,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532600200288_#_9",
            "eventName": "Run",
            "timestamp": 1532600200288,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532600449121_1532600512011",
        "id": "AWTWH7eXpSLeWrHL-1QC",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532600449121"
          },
          {
            "key": "endTime",
            "value": "1532600512011"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532600449121_#_7",
            "eventName": "DHCP",
            "timestamp": 1532600449121,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532600511301_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532600511301,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532600511990_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532600511990,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532600512011_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532600512011,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532600512011_#_9",
            "eventName": "Run",
            "timestamp": 1532600512011,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Delete_1532600661069_1532600798565",
        "id": "AWTWI2DGpSLeWrHL-1Qh",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532600661069"
          },
          {
            "key": "endTime",
            "value": "1532600798565"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532600661069_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532600661069,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532600661204_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532600661204,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532600749854_#_7",
            "eventName": "DHCP",
            "timestamp": 1532600749854,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532600798565_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532600798565,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Spam Client Idle Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Deleted",
            "id": "1532600798565_#_11",
            "eventName": "Deleted",
            "timestamp": 1532600798565,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532600904474_1532600904813",
        "id": "AWTWI2DGpSLeWrHL-1Qr",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532600904474"
          },
          {
            "key": "endTime",
            "value": "1532600904813"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532600904474_#_1",
            "eventName": "Association Start",
            "timestamp": 1532600904474,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532600904475_#_2",
            "eventName": "Association Done",
            "timestamp": 1532600904475,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532600904477_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532600904477,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532600904692_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532600904692,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532600904714_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532600904714,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532600904714_#_6",
            "eventName": "Mobility",
            "timestamp": 1532600904714,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532600904813_#_7",
            "eventName": "DHCP",
            "timestamp": 1532600904813,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532600904813_#_9",
            "eventName": "Run",
            "timestamp": 1532600904813,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Delete_1532601055161_1532601113745",
        "id": "AWTWKN85pSLeWrHL-1RF",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532601055161"
          },
          {
            "key": "endTime",
            "value": "1532601113745"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532601055161_#_7",
            "eventName": "DHCP",
            "timestamp": 1532601055161,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532601113745_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532601113745,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Spam Client Idle Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Deleted",
            "id": "1532601113745_#_11",
            "eventName": "Deleted",
            "timestamp": 1532601113745,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532601187392_1532601187776",
        "id": "AWTWKrRzpSLeWrHL-1RM",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532601187392"
          },
          {
            "key": "endTime",
            "value": "1532601187776"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532601187392_#_1",
            "eventName": "Association Start",
            "timestamp": 1532601187392,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532601187392_#_2",
            "eventName": "Association Done",
            "timestamp": 1532601187392,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532601187395_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532601187395,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532601187630_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532601187630,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532601187663_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532601187663,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532601187663_#_6",
            "eventName": "Mobility",
            "timestamp": 1532601187663,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532601187776_#_7",
            "eventName": "DHCP",
            "timestamp": 1532601187776,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532601187776_#_9",
            "eventName": "Run",
            "timestamp": 1532601187776,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532601488351_1532601523914",
        "id": "AWTWLMONpSLeWrHL-1Rc",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532601488351"
          },
          {
            "key": "endTime",
            "value": "1532601523914"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532601488351_#_7",
            "eventName": "DHCP",
            "timestamp": 1532601488351,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532601523597_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532601523597,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532601523888_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532601523888,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532601523914_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532601523914,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532601523914_#_9",
            "eventName": "Run",
            "timestamp": 1532601523914,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532601638739_1532601828086",
        "id": "AWTWMgbWpSLeWrHL-1R3",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532601638739"
          },
          {
            "key": "endTime",
            "value": "1532601828086"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532601638739_#_7",
            "eventName": "DHCP",
            "timestamp": 1532601638739,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532601789148_#_7",
            "eventName": "DHCP",
            "timestamp": 1532601789148,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532601827869_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532601827869,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532601828017_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532601828017,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532601828086_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532601828086,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532601828086_#_9",
            "eventName": "Run",
            "timestamp": 1532601828086,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532602090147_1532602145386",
        "id": "AWTWN4UwpSLeWrHL-1S0",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532602090147"
          },
          {
            "key": "endTime",
            "value": "1532602145386"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532602090147_#_7",
            "eventName": "DHCP",
            "timestamp": 1532602090147,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532602145205_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532602145205,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532602145343_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532602145343,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532602145386_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532602145386,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532602145386_#_9",
            "eventName": "Run",
            "timestamp": 1532602145386,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532602391023_1532602447680",
        "id": "AWTWPQPqpSLeWrHL-1TZ",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532602391023"
          },
          {
            "key": "endTime",
            "value": "1532602447680"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532602391023_#_7",
            "eventName": "DHCP",
            "timestamp": 1532602391023,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532602447497_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532602447497,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532602447651_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532602447651,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532602447680_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532602447680,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532602447680_#_9",
            "eventName": "Run",
            "timestamp": 1532602447680,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Delete_1532602508701_1532602629453",
        "id": "AWTWPQPqpSLeWrHL-1Tf",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532602508701"
          },
          {
            "key": "endTime",
            "value": "1532602629453"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532602508701_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532602508701,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532602509295_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532602509295,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532602541344_#_7",
            "eventName": "DHCP",
            "timestamp": 1532602541344,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532602629453_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532602629453,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Spam Client Idle Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Deleted",
            "id": "1532602629453_#_11",
            "eventName": "Deleted",
            "timestamp": 1532602629453,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532602695712_1532602698040",
        "id": "AWTWQK0bpSLeWrHL-1Ty",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "Radio",
            "value": "1"
          },
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "5.0"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:3A:7D:87:C3:80"
          },
          {
            "key": "AP_Name",
            "value": "AP3800-7"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:AP3800-7/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532602695712"
          },
          {
            "key": "endTime",
            "value": "1532602698040"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532602695712_#_1",
            "eventName": "Association Start",
            "timestamp": 1532602695712,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:3A:7D:87:C3:80"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "1"
              },
              {
                "key": "FREQUENCY",
                "value": "5.0"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532602695712_#_2",
            "eventName": "Association Done",
            "timestamp": 1532602695712,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532602695714_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532602695714,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "EAP Start",
            "id": "1532602697866_#_12",
            "eventName": "EAP Start",
            "timestamp": 1532602697866,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532602697866_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532602697866,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532602697935_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532602697935,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532602697952_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532602697952,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532602697952_#_6",
            "eventName": "Mobility",
            "timestamp": 1532602697952,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532602698040_#_7",
            "eventName": "DHCP",
            "timestamp": 1532602698040,
            "index": 8,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532602698040_#_9",
            "eventName": "Run",
            "timestamp": 1532602698040,
            "index": 9,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532602826581_1532602982039",
        "id": "AWTWRFZWpSLeWrHL-1UE",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532602826581"
          },
          {
            "key": "endTime",
            "value": "1532602982039"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532602826581_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532602826581,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532602826585_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532602826585,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532602848515_#_7",
            "eventName": "DHCP",
            "timestamp": 1532602848515,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532602981785_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532602981785,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532602982020_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532602982020,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532602982039_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532602982039,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532602982039_#_9",
            "eventName": "Run",
            "timestamp": 1532602982039,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Re-Authentication_1532603292085_1532603292314",
        "id": "AWTWSDompSLeWrHL-1UX",
        "eventName": "Re-Authentication",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532603292085"
          },
          {
            "key": "endTime",
            "value": "1532603292314"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Authentication Start",
            "id": "1532603292085_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532603292085,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532603292295_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532603292295,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532603292314_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532603292314,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532603292314_#_9",
            "eventName": "Run",
            "timestamp": 1532603292314,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532603444517_1532603610555",
        "id": "AWTWTX3tpSLeWrHL-1U1",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532603444517"
          },
          {
            "key": "endTime",
            "value": "1532603610555"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532603444517_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532603444517,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532603444597_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532603444597,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532603450093_#_7",
            "eventName": "DHCP",
            "timestamp": 1532603450093,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532603600368_#_7",
            "eventName": "DHCP",
            "timestamp": 1532603600368,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532603610437_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532603610437,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532603610537_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532603610537,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532603610555_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532603610555,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532603610555_#_9",
            "eventName": "Run",
            "timestamp": 1532603610555,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532603900937_1532603940190",
        "id": "AWTWUvy3pSLeWrHL-1Vw",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532603900937"
          },
          {
            "key": "endTime",
            "value": "1532603940190"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532603900937_#_7",
            "eventName": "DHCP",
            "timestamp": 1532603900937,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532603939849_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532603939849,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532603940171_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532603940171,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532603940190_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532603940190,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532603940190_#_9",
            "eventName": "Run",
            "timestamp": 1532603940190,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532604201523_1532604238471",
        "id": "AWTWVuCLpSLeWrHL-1WM",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532604201523"
          },
          {
            "key": "endTime",
            "value": "1532604238471"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532604201523_#_7",
            "eventName": "DHCP",
            "timestamp": 1532604201523,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532604238133_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532604238133,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532604238452_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532604238452,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532604238471_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532604238471,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532604238471_#_9",
            "eventName": "Run",
            "timestamp": 1532604238471,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532604373069_1532604554575",
        "id": "AWTWWom9pSLeWrHL-1Wt",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532604373069"
          },
          {
            "key": "endTime",
            "value": "1532604554575"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532604373069_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532604373069,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532604373251_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532604373251,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532604502132_#_7",
            "eventName": "DHCP",
            "timestamp": 1532604502132,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532604554473_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532604554473,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532604554556_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532604554556,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532604554575_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532604554575,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532604554575_#_9",
            "eventName": "Run",
            "timestamp": 1532604554575,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Delete_1532604652633_1532604736285",
        "id": "AWTWX82fpSLeWrHL-1XC",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532604652633"
          },
          {
            "key": "endTime",
            "value": "1532604736285"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532604652633_#_7",
            "eventName": "DHCP",
            "timestamp": 1532604652633,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532604680489_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532604680489,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532604680754_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532604680754,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532604736285_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532604736285,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Spam Client Idle Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Deleted",
            "id": "1532604736285_#_11",
            "eventName": "Deleted",
            "timestamp": 1532604736285,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532604742837_1532604743189",
        "id": "AWTWX82fpSLeWrHL-1XD",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532604742837"
          },
          {
            "key": "endTime",
            "value": "1532604743189"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532604742837_#_1",
            "eventName": "Association Start",
            "timestamp": 1532604742837,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532604742838_#_2",
            "eventName": "Association Done",
            "timestamp": 1532604742838,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532604742840_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532604742840,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532604743047_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532604743047,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532604743073_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532604743073,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532604743073_#_6",
            "eventName": "Mobility",
            "timestamp": 1532604743073,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532604743189_#_7",
            "eventName": "DHCP",
            "timestamp": 1532604743189,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532604743189_#_9",
            "eventName": "Run",
            "timestamp": 1532604743189,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532604884744_1532604884907",
        "id": "AWTWYaJopSLeWrHL-1XJ",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532604884744"
          },
          {
            "key": "endTime",
            "value": "1532604884907"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532604884744_#_1",
            "eventName": "Association Start",
            "timestamp": 1532604884744,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532604884745_#_2",
            "eventName": "Association Done",
            "timestamp": 1532604884745,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532604884907_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532604884907,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532604884907_#_9",
            "eventName": "Run",
            "timestamp": 1532604884907,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532604885033_1532605033937",
        "id": "AWTWYd0IpSLeWrHL-1XS",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532604885033"
          },
          {
            "key": "endTime",
            "value": "1532605033937"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532604885033_#_7",
            "eventName": "DHCP",
            "timestamp": 1532604885033,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532604979629_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532604979629,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532604979907_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532604979907,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532605033541_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532605033541,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532605033913_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532605033913,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532605033937_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532605033937,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532605033937_#_9",
            "eventName": "Run",
            "timestamp": 1532605033937,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532605289149_1532605370332",
        "id": "AWTWZ1vbpSLeWrHL-1X4",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532605289149"
          },
          {
            "key": "endTime",
            "value": "1532605370332"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532605289149_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532605289149,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532605289423_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532605289423,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532605336244_#_7",
            "eventName": "DHCP",
            "timestamp": 1532605336244,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532605369957_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532605369957,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532605370305_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532605370305,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532605370332_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532605370332,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532605370332_#_9",
            "eventName": "Run",
            "timestamp": 1532605370332,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532605486664_1532605652311",
        "id": "AWTWawUipSLeWrHL-1YT",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532605486664"
          },
          {
            "key": "endTime",
            "value": "1532605652311"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532605486664_#_7",
            "eventName": "DHCP",
            "timestamp": 1532605486664,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532605596625_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532605596625,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532605596925_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532605596925,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532605636976_#_7",
            "eventName": "DHCP",
            "timestamp": 1532605636976,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532605652185_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532605652185,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532605652286_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532605652286,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532605652311_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532605652311,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532605652311_#_9",
            "eventName": "Run",
            "timestamp": 1532605652311,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532605903110_1532605950750",
        "id": "AWTWchzxpSLeWrHL-1ZJ",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532605903110"
          },
          {
            "key": "endTime",
            "value": "1532605950750"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532605903110_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532605903110,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532605903339_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532605903339,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532605938063_#_7",
            "eventName": "DHCP",
            "timestamp": 1532605938063,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532605950622_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532605950622,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532605950730_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532605950730,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532605950750_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532605950750,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532605950750_#_9",
            "eventName": "Run",
            "timestamp": 1532605950750,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532606088612_1532606235529",
        "id": "AWTWdcYipSLeWrHL-1Zp",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532606088612"
          },
          {
            "key": "endTime",
            "value": "1532606235529"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532606088612_#_7",
            "eventName": "DHCP",
            "timestamp": 1532606088612,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532606209526_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532606209526,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532606209616_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532606209616,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532606234854_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532606234854,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532606235505_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532606235505,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532606235529_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532606235529,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532606235529_#_9",
            "eventName": "Run",
            "timestamp": 1532606235529,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532606517990_1532606528283",
        "id": "AWTWe0S5pSLeWrHL-1aD",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532606517990"
          },
          {
            "key": "endTime",
            "value": "1532606528283"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532606517990_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532606517990,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532606518361_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532606518361,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532606528110_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532606528110,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532606528269_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532606528269,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532606528283_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532606528283,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532606528283_#_9",
            "eventName": "Run",
            "timestamp": 1532606528283,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532606689883_1532606852605",
        "id": "AWTWfu6wpSLeWrHL-1aQ",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532606689883"
          },
          {
            "key": "endTime",
            "value": "1532606852605"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532606689883_#_7",
            "eventName": "DHCP",
            "timestamp": 1532606689883,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532606828470_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532606828470,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532606828613_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532606828613,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532606840390_#_7",
            "eventName": "DHCP",
            "timestamp": 1532606840390,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532606852482_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532606852482,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532606852582_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532606852582,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532606852605_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532606852605,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532606852605_#_9",
            "eventName": "Run",
            "timestamp": 1532606852605,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Re-Authentication_1532607130666_1532607130997",
        "id": "AWTWhG17pSLeWrHL-1ax",
        "eventName": "Re-Authentication",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532607130666"
          },
          {
            "key": "endTime",
            "value": "1532607130997"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Authentication Start",
            "id": "1532607130666_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532607130666,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532607130972_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532607130972,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532607130997_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532607130997,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532607130997_#_9",
            "eventName": "Run",
            "timestamp": 1532607130997,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532607291169_1532607434508",
        "id": "AWTWiBatpSLeWrHL-1ba",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532607291169"
          },
          {
            "key": "endTime",
            "value": "1532607434508"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532607291169_#_7",
            "eventName": "DHCP",
            "timestamp": 1532607291169,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532607433950_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532607433950,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532607434482_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532607434482,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532607434508_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532607434508,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532607434508_#_9",
            "eventName": "Run",
            "timestamp": 1532607434508,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532607742094_1532607760741",
        "id": "AWTWi_orpSLeWrHL-1cM",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532607742094"
          },
          {
            "key": "endTime",
            "value": "1532607760741"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532607742094_#_7",
            "eventName": "DHCP",
            "timestamp": 1532607742094,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532607753850_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532607753850,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532607753855_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532607753855,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532607760358_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532607760358,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532607760720_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532607760720,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532607760741_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532607760741,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532607760741_#_9",
            "eventName": "Run",
            "timestamp": 1532607760741,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Delete_1532607826730_1532607826730",
        "id": "AWTWi_orpSLeWrHL-1cN",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532607826730"
          },
          {
            "key": "endTime",
            "value": "1532607826730"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Client Deauthenticated",
            "id": "1532607826730_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532607826730,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Spam Client Idle Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Deleted",
            "id": "1532607826730_#_11",
            "eventName": "Deleted",
            "timestamp": 1532607826730,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532607860511_1532607861021",
        "id": "AWTWj2kWpSLeWrHL-1cX",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532607860511"
          },
          {
            "key": "endTime",
            "value": "1532607861021"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532607860511_#_1",
            "eventName": "Association Start",
            "timestamp": 1532607860511,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532607860512_#_2",
            "eventName": "Association Done",
            "timestamp": 1532607860512,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532607860514_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532607860514,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532607860880_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532607860880,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532607860901_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532607860901,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532607860901_#_6",
            "eventName": "Mobility",
            "timestamp": 1532607860901,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532607861021_#_7",
            "eventName": "DHCP",
            "timestamp": 1532607861021,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532607861021_#_9",
            "eventName": "Run",
            "timestamp": 1532607861021,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532608011549_1532608186837",
        "id": "AWTWkxMOpSLeWrHL-1cx",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532608011549"
          },
          {
            "key": "endTime",
            "value": "1532608186837"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532608011549_#_7",
            "eventName": "DHCP",
            "timestamp": 1532608011549,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532608062262_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532608062262,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532608062624_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532608062624,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532608161943_#_7",
            "eventName": "DHCP",
            "timestamp": 1532608161943,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532608186182_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532608186182,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532608186816_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532608186816,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532608186836_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532608186836,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532608186837_#_9",
            "eventName": "Run",
            "timestamp": 1532608186837,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532608371754_1532608482678",
        "id": "AWTWlvcCpSLeWrHL-1dH",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532608371754"
          },
          {
            "key": "endTime",
            "value": "1532608482678"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532608371754_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532608371754,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532608372278_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532608372278,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532608462777_#_7",
            "eventName": "DHCP",
            "timestamp": 1532608462777,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532608482394_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532608482394,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532608482658_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532608482658,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532608482678_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532608482678,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532608482678_#_9",
            "eventName": "Run",
            "timestamp": 1532608482678,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532608613116_1532608788976",
        "id": "AWTWnDsBpSLeWrHL-1de",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532608613116"
          },
          {
            "key": "endTime",
            "value": "1532608788976"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532608613116_#_7",
            "eventName": "DHCP",
            "timestamp": 1532608613116,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532608681170_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532608681170,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532608681315_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532608681315,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532608763435_#_7",
            "eventName": "DHCP",
            "timestamp": 1532608763435,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532608788694_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532608788694,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532608788954_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532608788954,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532608788976_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532608788976,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532608788976_#_9",
            "eventName": "Run",
            "timestamp": 1532608788976,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532608990594_1532609117684",
        "id": "AWTWobibpSLeWrHL-1eH",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532608990594"
          },
          {
            "key": "endTime",
            "value": "1532609117684"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532608990594_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532608990594,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532608990662_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532608990662,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532609064112_#_7",
            "eventName": "DHCP",
            "timestamp": 1532609064112,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532609117058_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532609117058,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532609117607_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532609117607,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532609117684_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532609117684,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532609117684_#_9",
            "eventName": "Run",
            "timestamp": 1532609117684,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532609297042_1532609439583",
        "id": "AWTWpzalpSLeWrHL-1e3",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532609297042"
          },
          {
            "key": "endTime",
            "value": "1532609439583"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532609297042_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532609297042,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532609297245_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532609297245,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532609364712_#_7",
            "eventName": "DHCP",
            "timestamp": 1532609364712,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532609439438_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532609439438,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532609439562_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532609439562,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532609439583_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532609439583,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532609439583_#_9",
            "eventName": "Run",
            "timestamp": 1532609439583,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532609607606_1532609721967",
        "id": "AWTWqt_5pSLeWrHL-1fa",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532609607606"
          },
          {
            "key": "endTime",
            "value": "1532609721967"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532609607606_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532609607606,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532609607816_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532609607816,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532609665538_#_7",
            "eventName": "DHCP",
            "timestamp": 1532609665538,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532609721610_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532609721610,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532609721939_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532609721939,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532609721967_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532609721967,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532609721967_#_9",
            "eventName": "Run",
            "timestamp": 1532609721967,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532609917062_1532610018029",
        "id": "AWTWrsRgpSLeWrHL-1f4",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532609917062"
          },
          {
            "key": "endTime",
            "value": "1532610018029"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532609917062_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532609917062,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532609917168_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532609917168,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532609966189_#_7",
            "eventName": "DHCP",
            "timestamp": 1532609966189,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532610017878_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532610017878,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532610018007_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532610018007,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532610018029_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532610018029,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532610018029_#_9",
            "eventName": "Run",
            "timestamp": 1532610018029,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532610223538_1532610271064",
        "id": "AWTWsjPFpSLeWrHL-1gG",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532610223538"
          },
          {
            "key": "endTime",
            "value": "1532610271064"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532610223538_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532610223538,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532610223752_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532610223752,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Start",
            "id": "1532610270891_#_1",
            "eventName": "Association Start",
            "timestamp": 1532610270891,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532610270892_#_2",
            "eventName": "Association Done",
            "timestamp": 1532610270892,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532610271064_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532610271064,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532610271064_#_9",
            "eventName": "Run",
            "timestamp": 1532610271064,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532610271181_1532610316418",
        "id": "AWTWsm5opSLeWrHL-1gH",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532610271181"
          },
          {
            "key": "endTime",
            "value": "1532610316418"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532610271181_#_7",
            "eventName": "DHCP",
            "timestamp": 1532610271181,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532610316130_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532610316130,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532610316406_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532610316406,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532610316418_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532610316418,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532610316418_#_9",
            "eventName": "Run",
            "timestamp": 1532610316418,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532610530906_1532610610555",
        "id": "AWTWt-yxpSLeWrHL-1gm",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532610530906"
          },
          {
            "key": "endTime",
            "value": "1532610610555"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532610530906_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532610530906,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532610530947_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532610530947,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532610590406_#_7",
            "eventName": "DHCP",
            "timestamp": 1532610590406,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532610610362_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532610610362,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532610610533_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532610610533,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532610610555_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532610610555,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532610610555_#_9",
            "eventName": "Run",
            "timestamp": 1532610610555,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532610740725_1532610924155",
        "id": "AWTWu5W8pSLeWrHL-1g8",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532610740725"
          },
          {
            "key": "endTime",
            "value": "1532610924155"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532610740725_#_7",
            "eventName": "DHCP",
            "timestamp": 1532610740725,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532610837298_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532610837298,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532610837527_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532610837527,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532610891029_#_7",
            "eventName": "DHCP",
            "timestamp": 1532610891029,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532610923686_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532610923686,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532610924073_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532610924073,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532610924155_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532610924155,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532610924155_#_9",
            "eventName": "Run",
            "timestamp": 1532610924155,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532611146794_1532611202262",
        "id": "AWTWwq5WpSLeWrHL-1h8",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532611146794"
          },
          {
            "key": "endTime",
            "value": "1532611202262"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532611146794_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532611146794,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532611146873_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532611146873,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532611191601_#_7",
            "eventName": "DHCP",
            "timestamp": 1532611191601,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532611201858_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532611201858,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532611202240_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532611202240,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532611202262_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532611202262,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532611202262_#_9",
            "eventName": "Run",
            "timestamp": 1532611202262,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532611341872_1532611503360",
        "id": "AWTWxL4npSLeWrHL-1iO",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532611341872"
          },
          {
            "key": "endTime",
            "value": "1532611503360"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532611341872_#_7",
            "eventName": "DHCP",
            "timestamp": 1532611341872,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532611453210_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532611453210,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532611453457_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532611453457,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532611492417_#_7",
            "eventName": "DHCP",
            "timestamp": 1532611492417,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532611503134_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532611503134,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532611503338_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532611503338,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532611503360_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532611503360,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532611503360_#_9",
            "eventName": "Run",
            "timestamp": 1532611503360,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532611760666_1532611810856",
        "id": "AWTWyjtUpSLeWrHL-1i4",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532611760666"
          },
          {
            "key": "endTime",
            "value": "1532611810856"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532611760666_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532611760666,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532611760983_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532611760983,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532611793107_#_7",
            "eventName": "DHCP",
            "timestamp": 1532611793107,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532611810430_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532611810430,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532611810831_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532611810831,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532611810856_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532611810856,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532611810856_#_9",
            "eventName": "Run",
            "timestamp": 1532611810856,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532611943447_1532612139235",
        "id": "AWTWz39zpSLeWrHL-1jG",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532611943447"
          },
          {
            "key": "endTime",
            "value": "1532612139235"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532611943447_#_7",
            "eventName": "DHCP",
            "timestamp": 1532611943447,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532612070138_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532612070138,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532612070305_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532612070305,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532612093725_#_7",
            "eventName": "DHCP",
            "timestamp": 1532612093725,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532612138810_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532612138810,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532612139207_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532612139207,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532612139235_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532612139235,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532612139235_#_9",
            "eventName": "Run",
            "timestamp": 1532612139235,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532612379590_1532612467870",
        "id": "AWTW02QEpSLeWrHL-1je",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532612379590"
          },
          {
            "key": "endTime",
            "value": "1532612467870"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532612379590_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532612379590,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532612379653_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532612379653,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532612394366_#_7",
            "eventName": "DHCP",
            "timestamp": 1532612394366,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532612467190_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532612467190,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532612467825_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532612467825,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532612467870_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532612467870,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532612467870_#_9",
            "eventName": "Run",
            "timestamp": 1532612467870,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532612544665_1532612749613",
        "id": "AWTW2KcNpSLeWrHL-1kA",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532612544665"
          },
          {
            "key": "endTime",
            "value": "1532612749613"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532612544665_#_7",
            "eventName": "DHCP",
            "timestamp": 1532612544665,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532612690086_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532612690086,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532612690230_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532612690230,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532612695140_#_7",
            "eventName": "DHCP",
            "timestamp": 1532612695140,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532612749398_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532612749398,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532612749591_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532612749591,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532612749613_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532612749613,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532612749613_#_9",
            "eventName": "Run",
            "timestamp": 1532612749613,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Delete_1532612845607_1532612954062",
        "id": "AWTW2rbXpSLeWrHL-1kk",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532612845607"
          },
          {
            "key": "endTime",
            "value": "1532612954062"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532612845607_#_7",
            "eventName": "DHCP",
            "timestamp": 1532612845607,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532612954062_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532612954062,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Spam Client Idle Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Deleted",
            "id": "1532612954062_#_11",
            "eventName": "Deleted",
            "timestamp": 1532612954062,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532612999882_1532613000230",
        "id": "AWTW3IuYpSLeWrHL-1k7",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532612999882"
          },
          {
            "key": "endTime",
            "value": "1532613000230"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532612999882_#_1",
            "eventName": "Association Start",
            "timestamp": 1532612999882,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532612999882_#_2",
            "eventName": "Association Done",
            "timestamp": 1532612999882,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532612999884_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532612999884,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532613000112_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532613000112,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532613000123_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532613000123,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532613000123_#_6",
            "eventName": "Mobility",
            "timestamp": 1532613000123,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532613000230_#_7",
            "eventName": "DHCP",
            "timestamp": 1532613000230,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532613000230_#_9",
            "eventName": "Run",
            "timestamp": 1532613000230,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532613150750_1532613318233",
        "id": "AWTW4c6hpSLeWrHL-1lg",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532613150750"
          },
          {
            "key": "endTime",
            "value": "1532613318233"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532613150750_#_7",
            "eventName": "DHCP",
            "timestamp": 1532613150750,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532613301324_#_7",
            "eventName": "DHCP",
            "timestamp": 1532613301324,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532613309026_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532613309026,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532613309267_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532613309267,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532613317802_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532613317802,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532613318204_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532613318204,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532613318233_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532613318233,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532613318233_#_9",
            "eventName": "Run",
            "timestamp": 1532613318233,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532613602019_1532613625781",
        "id": "AWTW501qpSLeWrHL-1mP",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532613602019"
          },
          {
            "key": "endTime",
            "value": "1532613625781"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532613602019_#_7",
            "eventName": "DHCP",
            "timestamp": 1532613602019,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532613618530_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532613618530,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532613618574_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532613618574,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532613625078_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532613625078,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532613625759_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532613625759,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532613625781_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532613625781,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532613625781_#_9",
            "eventName": "Run",
            "timestamp": 1532613625781,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Delete_1532613752474_1532613809486",
        "id": "AWTW501qpSLeWrHL-1mU",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532613752474"
          },
          {
            "key": "endTime",
            "value": "1532613809486"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532613752474_#_7",
            "eventName": "DHCP",
            "timestamp": 1532613752474,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532613809486_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532613809486,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Spam Client Idle Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Deleted",
            "id": "1532613809486_#_11",
            "eventName": "Deleted",
            "timestamp": 1532613809486,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532613813635_1532613813967",
        "id": "AWTW6vaYpSLeWrHL-1mh",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532613813635"
          },
          {
            "key": "endTime",
            "value": "1532613813967"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532613813635_#_1",
            "eventName": "Association Start",
            "timestamp": 1532613813635,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532613813636_#_2",
            "eventName": "Association Done",
            "timestamp": 1532613813636,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532613813639_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532613813639,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532613813840_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532613813840,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532613813856_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532613813856,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532613813856_#_6",
            "eventName": "Mobility",
            "timestamp": 1532613813856,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532613813966_#_7",
            "eventName": "DHCP",
            "timestamp": 1532613813966,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532613813967_#_9",
            "eventName": "Run",
            "timestamp": 1532613813967,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532613964307_1532614102501",
        "id": "AWTW7qCTpSLeWrHL-1mv",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532613964307"
          },
          {
            "key": "endTime",
            "value": "1532614102501"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532613964307_#_7",
            "eventName": "DHCP",
            "timestamp": 1532613964307,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532614102086_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532614102086,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532614102465_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532614102465,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532614102501_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532614102501,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532614102501_#_9",
            "eventName": "Run",
            "timestamp": 1532614102501,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532614415244_1532614436786",
        "id": "AWTW9fLwpSLeWrHL-1nb",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532614415244"
          },
          {
            "key": "endTime",
            "value": "1532614436786"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532614415244_#_7",
            "eventName": "DHCP",
            "timestamp": 1532614415244,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532614436566_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532614436566,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532614436765_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532614436765,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532614436786_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532614436786,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532614436786_#_9",
            "eventName": "Run",
            "timestamp": 1532614436786,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532614565581_1532614763303",
        "id": "AWTW9kSppSLeWrHL-1nj",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532614565581"
          },
          {
            "key": "endTime",
            "value": "1532614763303"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532614565581_#_7",
            "eventName": "DHCP",
            "timestamp": 1532614565581,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532614715798_#_7",
            "eventName": "DHCP",
            "timestamp": 1532614715798,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532614762978_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532614762978,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532614763276_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532614763276,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532614763303_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532614763303,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532614763303_#_9",
            "eventName": "Run",
            "timestamp": 1532614763303,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532615016834_1532615069579",
        "id": "AWTW-3HDpSLeWrHL-1oa",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532615016834"
          },
          {
            "key": "endTime",
            "value": "1532615069579"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532615016834_#_7",
            "eventName": "DHCP",
            "timestamp": 1532615016834,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532615069286_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532615069286,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532615069544_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532615069544,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532615069579_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532615069579,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532615069579_#_9",
            "eventName": "Run",
            "timestamp": 1532615069579,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Delete_1532615162718_1532615280158",
        "id": "AWTXAPA8pSLeWrHL-1pC",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532615162718"
          },
          {
            "key": "endTime",
            "value": "1532615280158"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532615162718_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532615162718,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532615163463_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532615163463,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532615167181_#_7",
            "eventName": "DHCP",
            "timestamp": 1532615167181,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532615280158_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532615280158,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Spam Client Idle Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Deleted",
            "id": "1532615280158_#_11",
            "eventName": "Deleted",
            "timestamp": 1532615280158,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532615288912_1532615289258",
        "id": "AWTXAPA8pSLeWrHL-1pD",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532615288912"
          },
          {
            "key": "endTime",
            "value": "1532615289258"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532615288912_#_1",
            "eventName": "Association Start",
            "timestamp": 1532615288912,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532615288913_#_2",
            "eventName": "Association Done",
            "timestamp": 1532615288913,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532615288915_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532615288915,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532615289146_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532615289146,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532615289174_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532615289174,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532615289174_#_6",
            "eventName": "Mobility",
            "timestamp": 1532615289174,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532615289258_#_7",
            "eventName": "DHCP",
            "timestamp": 1532615289258,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532615289258_#_9",
            "eventName": "Run",
            "timestamp": 1532615289258,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532615439762_1532615595813",
        "id": "AWTXBJkxpSLeWrHL-1pY",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532615439762"
          },
          {
            "key": "endTime",
            "value": "1532615595813"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532615439762_#_7",
            "eventName": "DHCP",
            "timestamp": 1532615439762,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532615469150_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532615469150,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532615469420_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532615469420,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532615590160_#_7",
            "eventName": "DHCP",
            "timestamp": 1532615590160,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532615595518_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532615595518,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532615595788_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532615595788,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532615595813_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532615595813,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532615595813_#_9",
            "eventName": "Run",
            "timestamp": 1532615595813,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532615775550_1532615884077",
        "id": "AWTXChfrpSLeWrHL-1py",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532615775550"
          },
          {
            "key": "endTime",
            "value": "1532615884077"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532615775550_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532615775550,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532615775725_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532615775725,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532615883734_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532615883734,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532615884047_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532615884047,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532615884077_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532615884077,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532615884077_#_9",
            "eventName": "Run",
            "timestamp": 1532615884077,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532616041374_1532616220328",
        "id": "AWTXDcEupSLeWrHL-1qG",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532616041374"
          },
          {
            "key": "endTime",
            "value": "1532616220328"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532616041374_#_7",
            "eventName": "DHCP",
            "timestamp": 1532616041374,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532616086118_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532616086118,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532616086279_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532616086279,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532616191692_#_7",
            "eventName": "DHCP",
            "timestamp": 1532616191692,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532616220166_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532616220166,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532616220303_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532616220303,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532616220328_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532616220328,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532616220328_#_9",
            "eventName": "Run",
            "timestamp": 1532616220328,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532616393450_1532616518637",
        "id": "AWTXEaSzpSLeWrHL-1qo",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532616393450"
          },
          {
            "key": "endTime",
            "value": "1532616518637"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532616393450_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532616393450,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532616393787_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532616393787,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532616492353_#_7",
            "eventName": "DHCP",
            "timestamp": 1532616492353,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532616518418_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532616518418,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532616518609_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532616518609,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532616518637_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532616518637,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532616518637_#_9",
            "eventName": "Run",
            "timestamp": 1532616518637,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532616642647_1532616797099",
        "id": "AWTXFU8cpSLeWrHL-1rb",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532616642647"
          },
          {
            "key": "endTime",
            "value": "1532616797099"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532616642647_#_7",
            "eventName": "DHCP",
            "timestamp": 1532616642647,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532616700882_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532616700882,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532616700975_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532616700975,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532616792980_#_7",
            "eventName": "DHCP",
            "timestamp": 1532616792980,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532616796598_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532616796598,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532616797079_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532616797079,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532616797099_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532616797099,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532616797099_#_9",
            "eventName": "Run",
            "timestamp": 1532616797099,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532617009322_1532617127202",
        "id": "AWTXHGdMpSLeWrHL-1sQ",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532617009322"
          },
          {
            "key": "endTime",
            "value": "1532617127202"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532617009322_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532617009322,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532617009402_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532617009402,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532617093677_#_7",
            "eventName": "DHCP",
            "timestamp": 1532617093677,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532617126990_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532617126990,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532617127169_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532617127169,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532617127202_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532617127202,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532617127202_#_9",
            "eventName": "Run",
            "timestamp": 1532617127202,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532617243989_1532617425746",
        "id": "AWTXHjwhpSLeWrHL-1sp",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532617243989"
          },
          {
            "key": "endTime",
            "value": "1532617425746"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532617243989_#_7",
            "eventName": "DHCP",
            "timestamp": 1532617243989,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532617319996_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532617319996,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532617320427_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532617320427,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532617394474_#_7",
            "eventName": "DHCP",
            "timestamp": 1532617394474,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532617425387_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532617425387,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532617425713_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532617425713,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532617425746_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532617425746,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532617425746_#_9",
            "eventName": "Run",
            "timestamp": 1532617425746,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532617630512_1532617733086",
        "id": "AWTXJY6EpSLeWrHL-1tE",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532617630512"
          },
          {
            "key": "endTime",
            "value": "1532617733086"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532617630512_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532617630512,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532617630702_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532617630702,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532617695166_#_7",
            "eventName": "DHCP",
            "timestamp": 1532617695166,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532617732659_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532617732659,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532617733059_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532617733059,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532617733086_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532617733086,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532617733086_#_9",
            "eventName": "Run",
            "timestamp": 1532617733086,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532617845340_1532618013252",
        "id": "AWTXJ2PSpSLeWrHL-1tP",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532617845340"
          },
          {
            "key": "endTime",
            "value": "1532618013252"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532617845340_#_7",
            "eventName": "DHCP",
            "timestamp": 1532617845340,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532617936936_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532617936936,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532617936969_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532617936969,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532617995661_#_7",
            "eventName": "DHCP",
            "timestamp": 1532617995661,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532618012831_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532618012831,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532618013224_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532618013224,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532618013252_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532618013252,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532618013252_#_9",
            "eventName": "Run",
            "timestamp": 1532618013252,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532618247468_1532618303234",
        "id": "AWTXLRxzpSLeWrHL-1tm",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532618247468"
          },
          {
            "key": "endTime",
            "value": "1532618303234"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532618247468_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532618247468,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532618247541_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532618247541,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532618296348_#_7",
            "eventName": "DHCP",
            "timestamp": 1532618296348,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532618303091_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532618303091,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532618303211_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532618303211,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532618303234_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532618303234,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532618303234_#_9",
            "eventName": "Run",
            "timestamp": 1532618303234,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532618446831_1532618599695",
        "id": "AWTXMMXIpSLeWrHL-1uY",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532618446831"
          },
          {
            "key": "endTime",
            "value": "1532618599695"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532618446831_#_7",
            "eventName": "DHCP",
            "timestamp": 1532618446831,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532618554864_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532618554864,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532618555047_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532618555047,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532618597159_#_7",
            "eventName": "DHCP",
            "timestamp": 1532618597159,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532618599319_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532618599319,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532618599671_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532618599671,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532618599695_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532618599695,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532618599695_#_9",
            "eventName": "Run",
            "timestamp": 1532618599695,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Delete_1532618701747_1532618701748",
        "id": "AWTXMprspSLeWrHL-1ug",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532618701747"
          },
          {
            "key": "endTime",
            "value": "1532618701748"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Client Deauthenticated",
            "id": "1532618701747_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532618701747,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Spam Client Idle Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Deleted",
            "id": "1532618701748_#_11",
            "eventName": "Deleted",
            "timestamp": 1532618701748,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532618744755_1532618747125",
        "id": "AWTXNgmWpSLeWrHL-1u3",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532618744755"
          },
          {
            "key": "endTime",
            "value": "1532618747125"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532618744755_#_1",
            "eventName": "Association Start",
            "timestamp": 1532618744755,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532618744756_#_2",
            "eventName": "Association Done",
            "timestamp": 1532618744756,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532618744758_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532618744758,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "EAP Start",
            "id": "1532618746907_#_12",
            "eventName": "EAP Start",
            "timestamp": 1532618746907,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532618746907_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532618746907,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532618746999_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532618746999,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532618747023_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532618747023,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532618747024_#_6",
            "eventName": "Mobility",
            "timestamp": 1532618747024,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532618747125_#_7",
            "eventName": "DHCP",
            "timestamp": 1532618747125,
            "index": 8,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532618747125_#_9",
            "eventName": "Run",
            "timestamp": 1532618747125,
            "index": 9,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532618897483_1532619073693",
        "id": "AWTXObLFpSLeWrHL-1vb",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532618897483"
          },
          {
            "key": "endTime",
            "value": "1532619073693"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532618897483_#_7",
            "eventName": "DHCP",
            "timestamp": 1532618897483,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532619047805_#_7",
            "eventName": "DHCP",
            "timestamp": 1532619047805,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532619073319_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532619073319,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532619073672_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532619073672,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532619073693_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532619073693,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532619073693_#_9",
            "eventName": "Run",
            "timestamp": 1532619073693,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532619348779_1532619355727",
        "id": "AWTXPzEgpSLeWrHL-1v7",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532619348779"
          },
          {
            "key": "endTime",
            "value": "1532619355727"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532619348779_#_7",
            "eventName": "DHCP",
            "timestamp": 1532619348779,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532619355491_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532619355491,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532619355694_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532619355694,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532619355727_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532619355727,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532619355727_#_9",
            "eventName": "Run",
            "timestamp": 1532619355727,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532619481248_1532619676132",
        "id": "AWTXQQWapSLeWrHL-1wE",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532619481248"
          },
          {
            "key": "endTime",
            "value": "1532619676132"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532619481248_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532619481248,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532619481549_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532619481549,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532619499217_#_7",
            "eventName": "DHCP",
            "timestamp": 1532619499217,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532619649559_#_7",
            "eventName": "DHCP",
            "timestamp": 1532619649559,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532619675855_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532619675855,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532619676101_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532619676101,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532619676132_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532619676132,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532619676132_#_9",
            "eventName": "Run",
            "timestamp": 1532619676132,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532619950203_1532620012501",
        "id": "AWTXRoQVpSLeWrHL-1wb",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532619950203"
          },
          {
            "key": "endTime",
            "value": "1532620012501"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532619950203_#_7",
            "eventName": "DHCP",
            "timestamp": 1532619950203,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532620012283_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532620012283,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532620012462_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532620012462,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532620012501_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532620012501,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532620012501_#_9",
            "eventName": "Run",
            "timestamp": 1532620012501,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Delete_1532620097491_1532620097492",
        "id": "AWTXSKqUpSLeWrHL-1wq",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532620097491"
          },
          {
            "key": "endTime",
            "value": "1532620097492"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Client Deauthenticated",
            "id": "1532620097491_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532620097491,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Spam Client Idle Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Deleted",
            "id": "1532620097492_#_11",
            "eventName": "Deleted",
            "timestamp": 1532620097492,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532620104571_1532620105154",
        "id": "AWTXSKqUpSLeWrHL-1wr",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532620104571"
          },
          {
            "key": "endTime",
            "value": "1532620105154"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532620104571_#_1",
            "eventName": "Association Start",
            "timestamp": 1532620104571,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532620104571_#_2",
            "eventName": "Association Done",
            "timestamp": 1532620104571,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532620104573_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532620104573,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532620105011_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532620105011,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532620105033_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532620105033,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532620105033_#_6",
            "eventName": "Mobility",
            "timestamp": 1532620105033,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532620105154_#_7",
            "eventName": "DHCP",
            "timestamp": 1532620105154,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532620105154_#_9",
            "eventName": "Run",
            "timestamp": 1532620105154,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532620255665_1532620397104",
        "id": "AWTXTdc7pSLeWrHL-1xh",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532620255665"
          },
          {
            "key": "endTime",
            "value": "1532620397104"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532620255665_#_7",
            "eventName": "DHCP",
            "timestamp": 1532620255665,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532620396943_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532620396943,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532620397083_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532620397083,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532620397103_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532620397103,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532620397104_#_9",
            "eventName": "Run",
            "timestamp": 1532620397104,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Re-Authentication_1532620695195_1532620695380",
        "id": "AWTXU1VUpSLeWrHL-1yU",
        "eventName": "Re-Authentication",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532620695195"
          },
          {
            "key": "endTime",
            "value": "1532620695380"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Authentication Start",
            "id": "1532620695195_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532620695195,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532620695356_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532620695356,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532620695380_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532620695380,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532620695380_#_9",
            "eventName": "Run",
            "timestamp": 1532620695380,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Delete_1532620706989_1532620782836",
        "id": "AWTXU1VUpSLeWrHL-1yV",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532620706989"
          },
          {
            "key": "endTime",
            "value": "1532620782836"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532620706989_#_7",
            "eventName": "DHCP",
            "timestamp": 1532620706989,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532620715020_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532620715020,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532620715556_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532620715556,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532620782835_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532620782835,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Spam Client Idle Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Deleted",
            "id": "1532620782836_#_11",
            "eventName": "Deleted",
            "timestamp": 1532620782836,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532620799756_1532620800197",
        "id": "AWTXU1VUpSLeWrHL-1yY",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532620799756"
          },
          {
            "key": "endTime",
            "value": "1532620800197"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532620799756_#_1",
            "eventName": "Association Start",
            "timestamp": 1532620799756,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532620799756_#_2",
            "eventName": "Association Done",
            "timestamp": 1532620799756,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532620799758_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532620799758,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532620800008_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532620800008,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532620800038_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532620800038,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532620800038_#_6",
            "eventName": "Mobility",
            "timestamp": 1532620800038,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532620800197_#_7",
            "eventName": "DHCP",
            "timestamp": 1532620800197,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532620800197_#_9",
            "eventName": "Run",
            "timestamp": 1532620800197,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532620950886_1532621116354",
        "id": "AWTXWNOspSLeWrHL-1y5",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532620950886"
          },
          {
            "key": "endTime",
            "value": "1532621116354"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532620950886_#_7",
            "eventName": "DHCP",
            "timestamp": 1532620950886,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532621024536_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532621024536,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532621024902_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532621024902,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532621101376_#_7",
            "eventName": "DHCP",
            "timestamp": 1532621101376,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532621115975_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532621115975,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532621116333_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532621116333,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532621116354_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532621116354,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532621116354_#_9",
            "eventName": "Run",
            "timestamp": 1532621116354,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532621332972_1532621398565",
        "id": "AWTXXlIOpSLeWrHL-1zP",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532621332972"
          },
          {
            "key": "endTime",
            "value": "1532621398565"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532621332972_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532621332972,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532621333026_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532621333026,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532621398191_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532621398191,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532621398544_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532621398544,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532621398565_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532621398565,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532621398565_#_9",
            "eventName": "Run",
            "timestamp": 1532621398565,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532621552496_1532621733618",
        "id": "AWTXYftDpSLeWrHL-1zd",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532621552496"
          },
          {
            "key": "endTime",
            "value": "1532621733618"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532621552496_#_7",
            "eventName": "DHCP",
            "timestamp": 1532621552496,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532621642488_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532621642488,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532621642683_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532621642683,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532621702826_#_7",
            "eventName": "DHCP",
            "timestamp": 1532621702826,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532621732615_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532621732615,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532621733403_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532621733403,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532621733618_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532621733618,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532621733618_#_9",
            "eventName": "Run",
            "timestamp": 1532621733618,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532621950920_1532622015955",
        "id": "AWTXZ3mwpSLeWrHL-10I",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532621950920"
          },
          {
            "key": "endTime",
            "value": "1532622015955"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532621950920_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532621950920,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532621951104_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532621951104,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532622003730_#_7",
            "eventName": "DHCP",
            "timestamp": 1532622003730,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532622015835_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532622015835,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532622015928_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532622015928,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532622015955_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532622015955,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532622015955_#_9",
            "eventName": "Run",
            "timestamp": 1532622015955,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532622154152_1532622296178",
        "id": "AWTXayLqpSLeWrHL-10o",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532622154152"
          },
          {
            "key": "endTime",
            "value": "1532622296178"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532622154152_#_7",
            "eventName": "DHCP",
            "timestamp": 1532622154152,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532622258372_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532622258372,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532622258602_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532622258602,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532622295999_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532622295999,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532622296151_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532622296151,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532622296178_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532622296178,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532622296178_#_9",
            "eventName": "Run",
            "timestamp": 1532622296178,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532622568908_1532622598383",
        "id": "AWTXbwflpSLeWrHL-11a",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532622568908"
          },
          {
            "key": "endTime",
            "value": "1532622598383"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532622568908_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532622568908,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532622569180_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532622569180,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532622598307_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532622598307,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532622598370_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532622598370,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532622598383_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532622598383,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532622598383_#_9",
            "eventName": "Run",
            "timestamp": 1532622598383,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532622605246_1532622633868",
        "id": "AWTXbwflpSLeWrHL-11b",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532622605246"
          },
          {
            "key": "endTime",
            "value": "1532622633868"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532622605246_#_7",
            "eventName": "DHCP",
            "timestamp": 1532622605246,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Start",
            "id": "1532622633705_#_1",
            "eventName": "Association Start",
            "timestamp": 1532622633705,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532622633705_#_2",
            "eventName": "Association Done",
            "timestamp": 1532622633705,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532622633868_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532622633868,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532622633868_#_9",
            "eventName": "Run",
            "timestamp": 1532622633868,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532622784648_1532622902904",
        "id": "AWTXcrELpSLeWrHL-111",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532622784648"
          },
          {
            "key": "endTime",
            "value": "1532622902904"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532622784648_#_7",
            "eventName": "DHCP",
            "timestamp": 1532622784648,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532622876364_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532622876364,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532622876685_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532622876685,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532622902595_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532622902595,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532622902872_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532622902872,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532622902904_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532622902904,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532622902904_#_9",
            "eventName": "Run",
            "timestamp": 1532622902904,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532623085239_1532623199030",
        "id": "AWTXecjrpSLeWrHL-12N",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532623085239"
          },
          {
            "key": "endTime",
            "value": "1532623199030"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532623085239_#_7",
            "eventName": "DHCP",
            "timestamp": 1532623085239,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532623186812_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532623186812,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532623186956_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532623186956,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532623198831_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532623198831,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532623199006_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532623199006,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532623199030_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532623199030,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532623199030_#_9",
            "eventName": "Run",
            "timestamp": 1532623199030,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532623385869_1532623516375",
        "id": "AWTXfXIkpSLeWrHL-12X",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532623385869"
          },
          {
            "key": "endTime",
            "value": "1532623516375"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532623385869_#_7",
            "eventName": "DHCP",
            "timestamp": 1532623385869,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532623497352_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532623497352,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532623497533_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532623497533,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532623516203_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532623516203,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532623516346_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532623516346,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532623516375_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532623516375,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532623516375_#_9",
            "eventName": "Run",
            "timestamp": 1532623516375,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532623686464_1532623808830",
        "id": "AWTXgvDopSLeWrHL-12_",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532623686464"
          },
          {
            "key": "endTime",
            "value": "1532623808830"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532623686464_#_7",
            "eventName": "DHCP",
            "timestamp": 1532623686464,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532623806780_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532623806780,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532623807180_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532623807180,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532623808459_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532623808459,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532623808803_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532623808803,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532623808830_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532623808830,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532623808830_#_9",
            "eventName": "Run",
            "timestamp": 1532623808830,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532623987271_1532624134198",
        "id": "AWTXhpsEpSLeWrHL-13p",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532623987271"
          },
          {
            "key": "endTime",
            "value": "1532624134198"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532623987271_#_7",
            "eventName": "DHCP",
            "timestamp": 1532623987271,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532624116199_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532624116199,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532624116523_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532624116523,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532624133807_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532624133807,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532624134137_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532624134137,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532624134198_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532624134198,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532624134198_#_9",
            "eventName": "Run",
            "timestamp": 1532624134198,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532624287955_1532624460597",
        "id": "AWTXikQ2pSLeWrHL-14F",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532624287955"
          },
          {
            "key": "endTime",
            "value": "1532624460597"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532624287955_#_7",
            "eventName": "DHCP",
            "timestamp": 1532624287955,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532624426647_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532624426647,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532624426819_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532624426819,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532624438311_#_7",
            "eventName": "DHCP",
            "timestamp": 1532624438311,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532624460179_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532624460179,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532624460370_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532624460370,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532624460597_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532624460597,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532624460597_#_9",
            "eventName": "Run",
            "timestamp": 1532624460597,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532624735031_1532624782973",
        "id": "AWTXj_00pSLeWrHL-14y",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532624735031"
          },
          {
            "key": "endTime",
            "value": "1532624782973"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532624735031_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532624735031,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532624735217_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532624735217,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532624738895_#_7",
            "eventName": "DHCP",
            "timestamp": 1532624738895,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532624782575_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532624782575,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532624782945_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532624782945,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532624782973_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532624782973,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532624782973_#_9",
            "eventName": "Run",
            "timestamp": 1532624782973,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532624889191_1532625077244",
        "id": "AWTXlUBDpSLeWrHL-149",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532624889191"
          },
          {
            "key": "endTime",
            "value": "1532625077244"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532624889191_#_7",
            "eventName": "DHCP",
            "timestamp": 1532624889191,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532625039655_#_7",
            "eventName": "DHCP",
            "timestamp": 1532625039655,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532625045624_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532625045624,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532625045791_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532625045791,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532625076823_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532625076823,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532625077216_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532625077216,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532625077244_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532625077244,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532625077244_#_9",
            "eventName": "Run",
            "timestamp": 1532625077244,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532625340250_1532625355552",
        "id": "AWTXmr6epSLeWrHL-15V",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532625340250"
          },
          {
            "key": "endTime",
            "value": "1532625355552"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532625340250_#_7",
            "eventName": "DHCP",
            "timestamp": 1532625340250,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532625355031_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532625355031,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532625355532_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532625355532,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532625355552_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532625355552,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532625355552_#_9",
            "eventName": "Run",
            "timestamp": 1532625355552,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532625490371_1532625633568",
        "id": "AWTXnmiapSLeWrHL-153",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532625490371"
          },
          {
            "key": "endTime",
            "value": "1532625633568"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532625490371_#_7",
            "eventName": "DHCP",
            "timestamp": 1532625490371,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532625633215_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532625633215,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532625633546_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532625633546,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532625633568_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532625633568,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532625633568_#_9",
            "eventName": "Run",
            "timestamp": 1532625633568,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532625941769_1532625967789",
        "id": "AWTXokx0pSLeWrHL-16i",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532625941769"
          },
          {
            "key": "endTime",
            "value": "1532625967789"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532625941769_#_7",
            "eventName": "DHCP",
            "timestamp": 1532625941769,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532625967611_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532625967611,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532625967763_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532625967763,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532625967789_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532625967789,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532625967789_#_9",
            "eventName": "Run",
            "timestamp": 1532625967789,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532626092060_1532626245203",
        "id": "AWTXpfXLpSLeWrHL-17D",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532626092060"
          },
          {
            "key": "endTime",
            "value": "1532626245203"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532626092060_#_7",
            "eventName": "DHCP",
            "timestamp": 1532626092060,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532626242376_#_7",
            "eventName": "DHCP",
            "timestamp": 1532626242376,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532626244775_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532626244775,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532626245176_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532626245176,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532626245203_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532626245203,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532626245203_#_9",
            "eventName": "Run",
            "timestamp": 1532626245203,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532626542985_1532626579435",
        "id": "AWTXrQ6ipSLeWrHL-17r",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532626542985"
          },
          {
            "key": "endTime",
            "value": "1532626579435"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532626542985_#_7",
            "eventName": "DHCP",
            "timestamp": 1532626542985,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532626579191_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532626579191,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532626579404_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532626579404,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532626579435_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532626579435,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532626579435_#_9",
            "eventName": "Run",
            "timestamp": 1532626579435,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532626693261_1532626870043",
        "id": "AWTXruKipSLeWrHL-172",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532626693261"
          },
          {
            "key": "endTime",
            "value": "1532626870043"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532626693261_#_7",
            "eventName": "DHCP",
            "timestamp": 1532626693261,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532626843708_#_7",
            "eventName": "DHCP",
            "timestamp": 1532626843708,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532626869435_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532626869435,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532626870009_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532626870009,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532626870043_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532626870043,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532626870043_#_9",
            "eventName": "Run",
            "timestamp": 1532626870043,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532627144289_1532627187969",
        "id": "AWTXtGFxpSLeWrHL-18F",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532627144289"
          },
          {
            "key": "endTime",
            "value": "1532627187969"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532627144289_#_7",
            "eventName": "DHCP",
            "timestamp": 1532627144289,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532627187775_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532627187775,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532627187947_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532627187947,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532627187969_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532627187969,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532627187969_#_9",
            "eventName": "Run",
            "timestamp": 1532627187969,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532627295030_1532627488427",
        "id": "AWTXuAqlpSLeWrHL-18o",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532627295030"
          },
          {
            "key": "endTime",
            "value": "1532627488427"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532627295030_#_7",
            "eventName": "DHCP",
            "timestamp": 1532627295030,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532627445307_#_7",
            "eventName": "DHCP",
            "timestamp": 1532627445307,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532627488039_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532627488039,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532627488400_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532627488400,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532627488427_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532627488427,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532627488427_#_9",
            "eventName": "Run",
            "timestamp": 1532627488427,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532627746109_1532627780861",
        "id": "AWTXvcOjpSLeWrHL-19d",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532627746109"
          },
          {
            "key": "endTime",
            "value": "1532627780861"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532627746109_#_7",
            "eventName": "DHCP",
            "timestamp": 1532627746109,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532627780307_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532627780307,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532627780840_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532627780840,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532627780861_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532627780861,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532627780861_#_9",
            "eventName": "Run",
            "timestamp": 1532627780861,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532627896405_1532628101889",
        "id": "AWTXwW06pSLeWrHL-198",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532627896405"
          },
          {
            "key": "endTime",
            "value": "1532628101889"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532627896405_#_7",
            "eventName": "DHCP",
            "timestamp": 1532627896405,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532628046605_#_7",
            "eventName": "DHCP",
            "timestamp": 1532628046605,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532628101655_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532628101655,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532628101865_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532628101865,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532628101889_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532628101889,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532628101889_#_9",
            "eventName": "Run",
            "timestamp": 1532628101889,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532628347210_1532628412161",
        "id": "AWTXxutFpSLeWrHL-1-j",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532628347210"
          },
          {
            "key": "endTime",
            "value": "1532628412161"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532628347210_#_7",
            "eventName": "DHCP",
            "timestamp": 1532628347210,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532628411939_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532628411939,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532628412137_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532628412137,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532628412161_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532628412161,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532628412161_#_9",
            "eventName": "Run",
            "timestamp": 1532628412161,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532628648056_1532628733489",
        "id": "AWTXzgRPpSLeWrHL-1-2",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532628648056"
          },
          {
            "key": "endTime",
            "value": "1532628733489"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532628648056_#_7",
            "eventName": "DHCP",
            "timestamp": 1532628648056,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532628733311_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532628733311,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532628733460_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532628733460,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532628733489_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532628733489,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532628733489_#_9",
            "eventName": "Run",
            "timestamp": 1532628733489,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532628948822_1532629017025",
        "id": "AWTX0a2CpSLeWrHL-1_I",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532628948822"
          },
          {
            "key": "endTime",
            "value": "1532629017025"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532628948822_#_7",
            "eventName": "DHCP",
            "timestamp": 1532628948822,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532629016633_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532629016633,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532629017000_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532629017000,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532629017025_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532629017025,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532629017025_#_9",
            "eventName": "Run",
            "timestamp": 1532629017025,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532629249446_1532629337329",
        "id": "AWTX1ZFXpSLeWrHL-1_8",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532629249446"
          },
          {
            "key": "endTime",
            "value": "1532629337329"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532629249446_#_7",
            "eventName": "DHCP",
            "timestamp": 1532629249446,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532629336997_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532629336997,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532629337281_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532629337281,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532629337329_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532629337329,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532629337329_#_9",
            "eventName": "Run",
            "timestamp": 1532629337329,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532629550013_1532629643538",
        "id": "AWTX2TrupSLeWrHL-2AZ",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532629550013"
          },
          {
            "key": "endTime",
            "value": "1532629643538"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532629550013_#_7",
            "eventName": "DHCP",
            "timestamp": 1532629550013,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532629643317_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532629643317,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532629643514_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532629643514,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532629643538_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532629643538,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532629643538_#_9",
            "eventName": "Run",
            "timestamp": 1532629643538,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532629850663_1532629955061",
        "id": "AWTX4FOvpSLeWrHL-2BM",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532629850663"
          },
          {
            "key": "endTime",
            "value": "1532629955061"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532629850663_#_7",
            "eventName": "DHCP",
            "timestamp": 1532629850663,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532629954689_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532629954689,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532629955025_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532629955025,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532629955061_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532629955061,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532629955061_#_9",
            "eventName": "Run",
            "timestamp": 1532629955061,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532630151229_1532630243198",
        "id": "AWTX4mKypSLeWrHL-2Bc",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532630151229"
          },
          {
            "key": "endTime",
            "value": "1532630243198"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532630151229_#_7",
            "eventName": "DHCP",
            "timestamp": 1532630151229,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532630242909_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532630242909,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532630243170_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532630243170,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532630243198_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532630243198,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532630243198_#_9",
            "eventName": "Run",
            "timestamp": 1532630243198,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Delete_1532630451792_1532630552413",
        "id": "AWTX6XuYpSLeWrHL-2Bx",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532630451792"
          },
          {
            "key": "endTime",
            "value": "1532630552413"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532630451792_#_7",
            "eventName": "DHCP",
            "timestamp": 1532630451792,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532630552413_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532630552413,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Spam Client Idle Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Deleted",
            "id": "1532630552413_#_11",
            "eventName": "Deleted",
            "timestamp": 1532630552413,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },

      {
        "name": "Onboarding_1532630605990_1532630606356",
        "id": "AWTX6XuYpSLeWrHL-2B2",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532630605990"
          },
          {
            "key": "endTime",
            "value": "1532630606356"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532630605990_#_1",
            "eventName": "Association Start",
            "timestamp": 1532630605990,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532630605991_#_2",
            "eventName": "Association Done",
            "timestamp": 1532630605991,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532630605994_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532630605994,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532630606228_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532630606228,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532630606248_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532630606248,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532630606248_#_6",
            "eventName": "Mobility",
            "timestamp": 1532630606248,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532630606356_#_7",
            "eventName": "DHCP",
            "timestamp": 1532630606356,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532630606356_#_9",
            "eventName": "Run",
            "timestamp": 1532630606356,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532630873437_1532630922272",
        "id": "AWTX7V9vpSLeWrHL-2CK",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532630873437"
          },
          {
            "key": "endTime",
            "value": "1532630922272"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532630873437_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532630873437,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532630873844_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532630873844,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532630907324_#_7",
            "eventName": "DHCP",
            "timestamp": 1532630907324,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532630921753_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532630921753,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532630922130_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532630922130,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532630922272_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532630922272,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532630922272_#_9",
            "eventName": "Run",
            "timestamp": 1532630922272,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "INTRA-Roaming_1532631018857_1532631019069",
        "id": "AWTX8M4MpSLeWrHL-2Cu",
        "eventName": "INTRA-Roaming",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "Radio",
            "value": "1"
          },
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "5.0"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:3A:7D:87:C3:80"
          },
          {
            "key": "AP_Name",
            "value": "AP3800-7"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:AP3800-7/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532631018857"
          },
          {
            "key": "endTime",
            "value": "1532631019069"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532631018857_#_1",
            "eventName": "Association Start",
            "timestamp": 1532631018857,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:3A:7D:87:C3:80"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "1"
              },
              {
                "key": "FREQUENCY",
                "value": "5.0"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532631018857_#_2",
            "eventName": "Association Done",
            "timestamp": 1532631018857,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Intra Roam"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532631018859_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532631018859,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532631019051_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532631019051,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532631019069_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532631019069,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532631019069_#_9",
            "eventName": "Run",
            "timestamp": 1532631019069,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },

      {
        "name": "INTRA-Roaming_1532631309659_1532631310075",
        "id": "AWTX9LHTpSLeWrHL-2Dt",
        "eventName": "INTRA-Roaming",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "Radio",
            "value": "1"
          },
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "5.0"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532631309659"
          },
          {
            "key": "endTime",
            "value": "1532631310075"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532631309659_#_1",
            "eventName": "Association Start",
            "timestamp": 1532631309659,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "1"
              },
              {
                "key": "FREQUENCY",
                "value": "5.0"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532631309659_#_2",
            "eventName": "Association Done",
            "timestamp": 1532631309659,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Intra Roam"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532631309665_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532631309665,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532631309874_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532631309874,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532631310075_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532631310075,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532631310075_#_9",
            "eventName": "Run",
            "timestamp": 1532631310075,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532631481353_1532631639961",
        "id": "AWTX-fXKpSLeWrHL-2Ed",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532631481353"
          },
          {
            "key": "endTime",
            "value": "1532631639961"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532631481353_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532631481353,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532631481495_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532631481495,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532631611053_#_7",
            "eventName": "DHCP",
            "timestamp": 1532631611053,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532631634809_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532631634809,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Start",
            "id": "1532631639718_#_1",
            "eventName": "Association Start",
            "timestamp": 1532631639718,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532631639718_#_2",
            "eventName": "Association Done",
            "timestamp": 1532631639718,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532631639722_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532631639722,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532631639937_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532631639937,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532631639961_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532631639961,
            "index": 8,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532631639961_#_9",
            "eventName": "Run",
            "timestamp": 1532631639961,
            "index": 9,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532631940878_1532631951504",
        "id": "AWTX_3PBpSLeWrHL-2FF",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532631940878"
          },
          {
            "key": "endTime",
            "value": "1532631951504"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532631940878_#_7",
            "eventName": "DHCP",
            "timestamp": 1532631940878,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532631951173_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532631951173,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532631951482_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532631951482,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532631951504_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532631951504,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532631951504_#_9",
            "eventName": "Run",
            "timestamp": 1532631951504,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532632084169_1532632260892",
        "id": "AWTYAUjtpSLeWrHL-2FR",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532632084169"
          },
          {
            "key": "endTime",
            "value": "1532632260892"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532632084169_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532632084169,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532632084422_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532632084422,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532632091245_#_7",
            "eventName": "DHCP",
            "timestamp": 1532632091245,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532632241595_#_7",
            "eventName": "DHCP",
            "timestamp": 1532632241595,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532632260465_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532632260465,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532632260859_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532632260859,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532632260892_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532632260892,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532632260892_#_9",
            "eventName": "Run",
            "timestamp": 1532632260892,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532632388561_1532632535815",
        "id": "AWTYBS0dpSLeWrHL-2Fh",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532632388561"
          },
          {
            "key": "endTime",
            "value": "1532632535815"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532632388561_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532632388561,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532632389183_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532632389183,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532632392037_#_7",
            "eventName": "DHCP",
            "timestamp": 1532632392037,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532632535629_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532632535629,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532632535792_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532632535792,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532632535815_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532632535815,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532632535815_#_9",
            "eventName": "Run",
            "timestamp": 1532632535815,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532632692431_1532632817250",
        "id": "AWTYCnDqpSLeWrHL-2GG",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532632692431"
          },
          {
            "key": "endTime",
            "value": "1532632817250"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532632692431_#_7",
            "eventName": "DHCP",
            "timestamp": 1532632692431,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532632692901_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532632692901,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532632693288_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532632693288,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532632816853_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532632816853,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532632817227_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532632817227,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532632817250_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532632817250,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532632817250_#_9",
            "eventName": "Run",
            "timestamp": 1532632817250,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Delete_1532632842929_1532632898605",
        "id": "AWTYDET5pSLeWrHL-2GT",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532632842929"
          },
          {
            "key": "endTime",
            "value": "1532632898605"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532632842929_#_7",
            "eventName": "DHCP",
            "timestamp": 1532632842929,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532632898605_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532632898605,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Spam Client Idle Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Deleted",
            "id": "1532632898605_#_11",
            "eventName": "Deleted",
            "timestamp": 1532632898605,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532632965113_1532632967506",
        "id": "AWTYDhpDpSLeWrHL-2Gn",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532632965113"
          },
          {
            "key": "endTime",
            "value": "1532632967506"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532632965113_#_1",
            "eventName": "Association Start",
            "timestamp": 1532632965113,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532632965113_#_2",
            "eventName": "Association Done",
            "timestamp": 1532632965113,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532632965116_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532632965116,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "EAP Start",
            "id": "1532632967271_#_12",
            "eventName": "EAP Start",
            "timestamp": 1532632967271,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532632967271_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532632967271,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532632967357_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532632967357,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532632967385_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532632967385,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532632967385_#_6",
            "eventName": "Mobility",
            "timestamp": 1532632967385,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532632967505_#_7",
            "eventName": "DHCP",
            "timestamp": 1532632967505,
            "index": 8,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532632967506_#_9",
            "eventName": "Run",
            "timestamp": 1532632967506,
            "index": 9,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532633268378_1532633293042",
        "id": "AWTYE5inpSLeWrHL-2HV",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532633268378"
          },
          {
            "key": "endTime",
            "value": "1532633293042"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532633268378_#_7",
            "eventName": "DHCP",
            "timestamp": 1532633268378,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532633292861_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532633292861,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532633293008_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532633293008,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532633293042_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532633293042,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532633293042_#_9",
            "eventName": "Run",
            "timestamp": 1532633293042,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532633418696_1532633568295",
        "id": "AWTYFWyopSLeWrHL-2Hz",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532633418696"
          },
          {
            "key": "endTime",
            "value": "1532633568295"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532633418696_#_7",
            "eventName": "DHCP",
            "timestamp": 1532633418696,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532633568057_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532633568057,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532633568270_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532633568270,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532633568295_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532633568295,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532633568295_#_9",
            "eventName": "Run",
            "timestamp": 1532633568295,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532633869648_1532633872738",
        "id": "AWTYHMBSpSLeWrHL-2IP",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532633869648"
          },
          {
            "key": "endTime",
            "value": "1532633872738"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532633869648_#_7",
            "eventName": "DHCP",
            "timestamp": 1532633869648,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532633872333_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532633872333,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532633872693_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532633872693,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532633872738_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532633872738,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532633872738_#_9",
            "eventName": "Run",
            "timestamp": 1532633872738,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532634019983_1532634190366",
        "id": "AWTYHpUdpSLeWrHL-2Ia",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532634019983"
          },
          {
            "key": "endTime",
            "value": "1532634190366"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532634019983_#_7",
            "eventName": "DHCP",
            "timestamp": 1532634019983,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532634170558_#_7",
            "eventName": "DHCP",
            "timestamp": 1532634170558,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532634189669_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532634189669,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532634190331_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532634190331,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532634190366_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532634190366,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532634190366_#_9",
            "eventName": "Run",
            "timestamp": 1532634190366,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532634514637_1532634516288",
        "id": "AWTYJ7zrpSLeWrHL-2JI",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532634514637"
          },
          {
            "key": "endTime",
            "value": "1532634516288"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532634514637_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532634514637,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532634515286_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532634515286,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532634516089_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532634516089,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532634516264_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532634516264,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532634516288_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532634516288,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532634516288_#_9",
            "eventName": "Run",
            "timestamp": 1532634516288,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Delete_1532634621576_1532634699497",
        "id": "AWTYJ7zrpSLeWrHL-2JJ",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532634621576"
          },
          {
            "key": "endTime",
            "value": "1532634699497"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532634621576_#_7",
            "eventName": "DHCP",
            "timestamp": 1532634621576,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532634699497_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532634699497,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Spam Client Idle Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Deleted",
            "id": "1532634699497_#_11",
            "eventName": "Deleted",
            "timestamp": 1532634699497,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532634704809_1532634705281",
        "id": "AWTYJ7zrpSLeWrHL-2JV",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532634704809"
          },
          {
            "key": "endTime",
            "value": "1532634705281"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532634704809_#_1",
            "eventName": "Association Start",
            "timestamp": 1532634704809,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532634704810_#_2",
            "eventName": "Association Done",
            "timestamp": 1532634704810,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532634704812_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532634704812,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532634705049_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532634705049,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532634705156_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532634705156,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532634705156_#_6",
            "eventName": "Mobility",
            "timestamp": 1532634705156,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532634705281_#_7",
            "eventName": "DHCP",
            "timestamp": 1532634705281,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532634705281_#_9",
            "eventName": "Run",
            "timestamp": 1532634705281,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "EAP Start_1532634794048_1532634794206",
        "id": "AWTYKZDspSLeWrHL-2Jf",
        "eventName": "EAP Start",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532634794048"
          },
          {
            "key": "endTime",
            "value": "1532634794206"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "EAP Start",
            "id": "1532634794048_#_12",
            "eventName": "EAP Start",
            "timestamp": 1532634794048,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532634794048_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532634794048,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532634794048_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532634794048,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532634794162_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532634794162,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532634794206_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532634794206,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532634794206_#_9",
            "eventName": "Run",
            "timestamp": 1532634794206,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Delete_1532634815049_1532634899733",
        "id": "AWTYK2YfpSLeWrHL-2Js",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532634815049"
          },
          {
            "key": "endTime",
            "value": "1532634899733"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532634815049_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532634815049,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532634815207_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532634815207,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532634855774_#_7",
            "eventName": "DHCP",
            "timestamp": 1532634855774,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532634899733_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532634899733,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Spam Client Idle Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Deleted",
            "id": "1532634899733_#_11",
            "eventName": "Deleted",
            "timestamp": 1532634899733,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532634931232_1532634931623",
        "id": "AWTYK2YfpSLeWrHL-2J4",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532634931232"
          },
          {
            "key": "endTime",
            "value": "1532634931623"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532634931232_#_1",
            "eventName": "Association Start",
            "timestamp": 1532634931232,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532634931233_#_2",
            "eventName": "Association Done",
            "timestamp": 1532634931233,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532634931235_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532634931235,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532634931448_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532634931448,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532634931470_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532634931470,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532634931470_#_6",
            "eventName": "Mobility",
            "timestamp": 1532634931470,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532634931623_#_7",
            "eventName": "DHCP",
            "timestamp": 1532634931623,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532634931623_#_9",
            "eventName": "Run",
            "timestamp": 1532634931623,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532635081930_1532635212523",
        "id": "AWTYL0nzpSLeWrHL-2KW",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532635081930"
          },
          {
            "key": "endTime",
            "value": "1532635212523"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532635081930_#_7",
            "eventName": "DHCP",
            "timestamp": 1532635081930,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532635119493_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532635119493,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532635119817_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532635119817,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532635212121_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532635212121,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532635212476_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532635212476,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532635212523_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532635212523,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532635212523_#_9",
            "eventName": "Run",
            "timestamp": 1532635212523,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532635382635_1532635495021",
        "id": "AWTYMvQOpSLeWrHL-2K6",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532635382635"
          },
          {
            "key": "endTime",
            "value": "1532635495021"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532635382635_#_7",
            "eventName": "DHCP",
            "timestamp": 1532635382635,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532635423993_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532635423993,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532635424253_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532635424253,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532635494341_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532635494341,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532635494997_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532635494997,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532635495021_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532635495021,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532635495021_#_9",
            "eventName": "Run",
            "timestamp": 1532635495021,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532635683284_1532635784719",
        "id": "AWTYODcUpSLeWrHL-2LE",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532635683284"
          },
          {
            "key": "endTime",
            "value": "1532635784719"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532635683284_#_7",
            "eventName": "DHCP",
            "timestamp": 1532635683284,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532635724429_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532635724429,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532635724691_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532635724691,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532635784545_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532635784545,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532635784685_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532635784685,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532635784719_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532635784719,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532635784719_#_9",
            "eventName": "Run",
            "timestamp": 1532635784719,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532635984045_1532636087656",
        "id": "AWTYPbXRpSLeWrHL-2Lh",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532635984045"
          },
          {
            "key": "endTime",
            "value": "1532636087656"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532635984045_#_7",
            "eventName": "DHCP",
            "timestamp": 1532635984045,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532636027829_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532636027829,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532636027921_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532636027921,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532636086849_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532636086849,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532636087602_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532636087602,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532636087656_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532636087656,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532636087656_#_9",
            "eventName": "Run",
            "timestamp": 1532636087656,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532636284873_1532636376465",
        "id": "AWTYP8VDpSLeWrHL-2L3",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532636284873"
          },
          {
            "key": "endTime",
            "value": "1532636376465"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532636284873_#_7",
            "eventName": "DHCP",
            "timestamp": 1532636284873,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532636329301_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532636329301,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532636329623_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532636329623,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532636376053_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532636376053,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532636376435_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532636376435,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532636376465_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532636376465,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532636376465_#_9",
            "eventName": "Run",
            "timestamp": 1532636376465,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Delete_1532636435261_1532636545689",
        "id": "AWTYQ255pSLeWrHL-2Me",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532636435261"
          },
          {
            "key": "endTime",
            "value": "1532636545689"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532636435261_#_7",
            "eventName": "DHCP",
            "timestamp": 1532636435261,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532636545689_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532636545689,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Spam Client Idle Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Deleted",
            "id": "1532636545689_#_11",
            "eventName": "Deleted",
            "timestamp": 1532636545689,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532636589596_1532636590071",
        "id": "AWTYRt16pSLeWrHL-2Mx",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532636589596"
          },
          {
            "key": "endTime",
            "value": "1532636590071"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532636589596_#_1",
            "eventName": "Association Start",
            "timestamp": 1532636589596,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532636589597_#_2",
            "eventName": "Association Done",
            "timestamp": 1532636589597,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532636589599_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532636589599,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532636589937_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532636589937,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532636589967_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532636589967,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532636589967_#_6",
            "eventName": "Mobility",
            "timestamp": 1532636589967,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532636590070_#_7",
            "eventName": "DHCP",
            "timestamp": 1532636590070,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532636590071_#_9",
            "eventName": "Run",
            "timestamp": 1532636590071,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Re-Authentication_1532636890277_1532636890778",
        "id": "AWTYSOzwpSLeWrHL-2NM",
        "eventName": "Re-Authentication",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532636890277"
          },
          {
            "key": "endTime",
            "value": "1532636890778"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Authentication Start",
            "id": "1532636890277_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532636890277,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532636890693_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532636890693,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532636890746_#_7",
            "eventName": "DHCP",
            "timestamp": 1532636890746,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532636890778_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532636890778,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532636890778_#_9",
            "eventName": "Run",
            "timestamp": 1532636890778,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532637042949_1532637201011",
        "id": "AWTYTjCupSLeWrHL-2Ns",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532637042949"
          },
          {
            "key": "endTime",
            "value": "1532637201011"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532637042949_#_7",
            "eventName": "DHCP",
            "timestamp": 1532637042949,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532637193260_#_7",
            "eventName": "DHCP",
            "timestamp": 1532637193260,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532637200613_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532637200613,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532637200938_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532637200938,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532637201011_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532637201011,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532637201011_#_9",
            "eventName": "Run",
            "timestamp": 1532637201011,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532637494399_1532637529589",
        "id": "AWTYUhR3pSLeWrHL-2OH",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532637494399"
          },
          {
            "key": "endTime",
            "value": "1532637529589"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532637494399_#_7",
            "eventName": "DHCP",
            "timestamp": 1532637494399,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532637528993_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532637528993,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532637529559_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532637529559,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532637529589_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532637529589,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532637529589_#_9",
            "eventName": "Run",
            "timestamp": 1532637529589,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532637795103_1532637843668",
        "id": "AWTYWSxopSLeWrHL-2Og",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532637795103"
          },
          {
            "key": "endTime",
            "value": "1532637843668"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532637795103_#_7",
            "eventName": "DHCP",
            "timestamp": 1532637795103,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532637838545_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532637838545,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532637838825_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532637838825,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532637843325_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532637843325,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532637843531_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532637843531,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532637843668_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532637843668,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532637843668_#_9",
            "eventName": "Run",
            "timestamp": 1532637843668,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532638095747_1532638137833",
        "id": "AWTYWzvOpSLeWrHL-2O7",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532638095747"
          },
          {
            "key": "endTime",
            "value": "1532638137833"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532638095747_#_7",
            "eventName": "DHCP",
            "timestamp": 1532638095747,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532638137573_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532638137573,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532638137810_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532638137810,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532638137833_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532638137833,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532638137833_#_9",
            "eventName": "Run",
            "timestamp": 1532638137833,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532638396450_1532638465304",
        "id": "AWTYYlTcpSLeWrHL-2P6",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532638396450"
          },
          {
            "key": "endTime",
            "value": "1532638465304"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532638396450_#_7",
            "eventName": "DHCP",
            "timestamp": 1532638396450,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532638443445_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532638443445,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532638443679_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532638443679,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532638465021_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532638465021,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532638465280_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532638465280,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532638465304_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532638465304,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532638465304_#_9",
            "eventName": "Run",
            "timestamp": 1532638465304,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532638697087_1532638786622",
        "id": "AWTYZf4YpSLeWrHL-2QX",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532638697087"
          },
          {
            "key": "endTime",
            "value": "1532638786622"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532638697087_#_7",
            "eventName": "DHCP",
            "timestamp": 1532638697087,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532638745917_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532638745917,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532638745960_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532638745960,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532638786389_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532638786389,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532638786596_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532638786596,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532638786622_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532638786622,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532638786622_#_9",
            "eventName": "Run",
            "timestamp": 1532638786622,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532638997792_1532639102427",
        "id": "AWTYaeJPpSLeWrHL-2Q3",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532638997792"
          },
          {
            "key": "endTime",
            "value": "1532639102427"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532638997792_#_7",
            "eventName": "DHCP",
            "timestamp": 1532638997792,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532639050349_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532639050349,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532639050715_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532639050715,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532639101753_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532639101753,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532639102399_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532639102399,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532639102427_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532639102427,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532639102427_#_9",
            "eventName": "Run",
            "timestamp": 1532639102427,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532639157455_1532639157677",
        "id": "AWTYbVD7pSLeWrHL-2RB",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532639157455"
          },
          {
            "key": "endTime",
            "value": "1532639157677"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532639157455_#_1",
            "eventName": "Association Start",
            "timestamp": 1532639157455,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532639157456_#_2",
            "eventName": "Association Done",
            "timestamp": 1532639157456,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532639157677_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532639157677,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532639157677_#_9",
            "eventName": "Run",
            "timestamp": 1532639157677,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532639308185_1532639384306",
        "id": "AWTYbYuapSLeWrHL-2RQ",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532639308185"
          },
          {
            "key": "endTime",
            "value": "1532639384306"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532639308185_#_7",
            "eventName": "DHCP",
            "timestamp": 1532639308185,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532639354893_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532639354893,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532639355131_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532639355131,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532639383937_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532639383937,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532639384269_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532639384269,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532639384306_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532639384306,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532639384306_#_9",
            "eventName": "Run",
            "timestamp": 1532639384306,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532639608871_1532639671355",
        "id": "AWTYcwmRpSLeWrHL-2Rq",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532639608871"
          },
          {
            "key": "endTime",
            "value": "1532639671355"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532639608871_#_7",
            "eventName": "DHCP",
            "timestamp": 1532639608871,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532639658389_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532639658389,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532639658642_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532639658642,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532639671165_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532639671165,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532639671330_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532639671330,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532639671355_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532639671355,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532639671355_#_9",
            "eventName": "Run",
            "timestamp": 1532639671355,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532639909740_1532639985053",
        "id": "AWTYeE3CpSLeWrHL-2SV",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_FAIL",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "4 way Key Timeout/WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532639909740"
          },
          {
            "key": "endTime",
            "value": "1532639985053"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532639909740_#_7",
            "eventName": "DHCP",
            "timestamp": 1532639909740,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532639961829_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532639961829,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532639965029_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532639965029,
            "index": 2,
            "resultStatus": "CL_FAIL",
            "details": [
              {
                "key": "additionalInfo",
                "value": "4 way Key Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532639965029_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532639965029,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532639985053_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532639985053,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Delete_1532639995073_1532639995073",
        "id": "AWTYeiInpSLeWrHL-2Sl",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532639995073"
          },
          {
            "key": "endTime",
            "value": "1532639995073"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Deleted",
            "id": "1532639995073_#_11",
            "eventName": "Deleted",
            "timestamp": 1532639995073,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532640062489_1532640062883",
        "id": "AWTYe_b3pSLeWrHL-2TB",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532640062489"
          },
          {
            "key": "endTime",
            "value": "1532640062883"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532640062489_#_1",
            "eventName": "Association Start",
            "timestamp": 1532640062489,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532640062490_#_2",
            "eventName": "Association Done",
            "timestamp": 1532640062490,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532640062492_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532640062492,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532640062733_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532640062733,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532640062757_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532640062757,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532640062757_#_6",
            "eventName": "Mobility",
            "timestamp": 1532640062757,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532640062883_#_7",
            "eventName": "DHCP",
            "timestamp": 1532640062883,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532640062883_#_9",
            "eventName": "Run",
            "timestamp": 1532640062883,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532640213490_1532640343499",
        "id": "AWTYfcvHpSLeWrHL-2TR",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532640213490"
          },
          {
            "key": "endTime",
            "value": "1532640343499"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532640213490_#_7",
            "eventName": "DHCP",
            "timestamp": 1532640213490,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532640264233_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532640264233,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532640264431_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532640264431,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532640343053_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532640343053,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532640343470_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532640343470,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532640343499_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532640343499,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532640343499_#_9",
            "eventName": "Run",
            "timestamp": 1532640343499,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532640514604_1532640677858",
        "id": "AWTYg0qTpSLeWrHL-2T9",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532640514604"
          },
          {
            "key": "endTime",
            "value": "1532640677858"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532640514604_#_7",
            "eventName": "DHCP",
            "timestamp": 1532640514604,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532640567159_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532640567159,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532640567182_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532640567182,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532640664908_#_7",
            "eventName": "DHCP",
            "timestamp": 1532640664908,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532640677603_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532640677603,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532640677843_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532640677843,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532640677858_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532640677858,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532640677858_#_9",
            "eventName": "Run",
            "timestamp": 1532640677858,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532640876687_1532640970005",
        "id": "AWTYiMgqpSLeWrHL-2Ug",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532640876687"
          },
          {
            "key": "endTime",
            "value": "1532640970005"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532640876687_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532640876687,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532640876821_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532640876821,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532640965621_#_7",
            "eventName": "DHCP",
            "timestamp": 1532640965621,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532640969847_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532640969847,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532640969978_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532640969978,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532640970005_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532640970005,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532640970005_#_9",
            "eventName": "Run",
            "timestamp": 1532640970005,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532641116012_1532641292559",
        "id": "AWTYitf9pSLeWrHL-2U7",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532641116012"
          },
          {
            "key": "endTime",
            "value": "1532641292559"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532641116012_#_7",
            "eventName": "DHCP",
            "timestamp": 1532641116012,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532641184195_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532641184195,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532641184392_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532641184392,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532641266314_#_7",
            "eventName": "DHCP",
            "timestamp": 1532641266314,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532641292199_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532641292199,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532641292535_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532641292535,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532641292559_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532641292559,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532641292559_#_9",
            "eventName": "Run",
            "timestamp": 1532641292559,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532641490627_1532641585631",
        "id": "AWTYke_ApSLeWrHL-2VV",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532641490627"
          },
          {
            "key": "endTime",
            "value": "1532641585631"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532641490627_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532641490627,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532641490900_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532641490900,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532641566937_#_7",
            "eventName": "DHCP",
            "timestamp": 1532641566937,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532641585439_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532641585439,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532641585601_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532641585601,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532641585631_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532641585631,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532641585631_#_9",
            "eventName": "Run",
            "timestamp": 1532641585631,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532641800067_1532641918141",
        "id": "AWTYl24dpSLeWrHL-2WK",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532641800067"
          },
          {
            "key": "endTime",
            "value": "1532641918141"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532641800067_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532641800067,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532641800247_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532641800247,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532641867654_#_7",
            "eventName": "DHCP",
            "timestamp": 1532641867654,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532641917843_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532641917843,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532641918106_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532641918106,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532641918141_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532641918141,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532641918141_#_9",
            "eventName": "Run",
            "timestamp": 1532641918141,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532642107495_1532642248562",
        "id": "AWTYmxdipSLeWrHL-2Wz",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532642107495"
          },
          {
            "key": "endTime",
            "value": "1532642248562"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532642107495_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532642107495,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532642107752_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532642107752,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532642168297_#_7",
            "eventName": "DHCP",
            "timestamp": 1532642168297,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532642248235_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532642248235,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532642248538_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532642248538,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532642248562_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532642248562,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532642248562_#_9",
            "eventName": "Run",
            "timestamp": 1532642248562,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532642418043_1532642580951",
        "id": "AWTYnvxipSLeWrHL-2XT",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532642418043"
          },
          {
            "key": "endTime",
            "value": "1532642580951"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532642418043_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532642418043,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532642418339_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532642418339,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532642469020_#_7",
            "eventName": "DHCP",
            "timestamp": 1532642469020,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532642580631_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532642580631,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532642580929_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532642580929,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532642580951_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532642580951,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532642580951_#_9",
            "eventName": "Run",
            "timestamp": 1532642580951,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532642769663_1532642879227",
        "id": "AWTYpD8OpSLeWrHL-2Xv",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532642769663"
          },
          {
            "key": "endTime",
            "value": "1532642879227"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532642769663_#_7",
            "eventName": "DHCP",
            "timestamp": 1532642769663,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532642878883_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532642878883,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532642879203_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532642879203,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532642879227_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532642879227,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532642879227_#_9",
            "eventName": "Run",
            "timestamp": 1532642879227,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532643031895_1532643205517",
        "id": "AWTYqb12pSLeWrHL-2YJ",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532643031895"
          },
          {
            "key": "endTime",
            "value": "1532643205517"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532643031895_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532643031895,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532643032121_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532643032121,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532643070277_#_7",
            "eventName": "DHCP",
            "timestamp": 1532643070277,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532643205271_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532643205271,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532643205475_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532643205475,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532643205517_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532643205517,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532643205517_#_9",
            "eventName": "Run",
            "timestamp": 1532643205517,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Delete_1532643370905_1532643474407",
        "id": "AWTYrzvYpSLeWrHL-2Yr",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532643370905"
          },
          {
            "key": "endTime",
            "value": "1532643474407"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532643370905_#_7",
            "eventName": "DHCP",
            "timestamp": 1532643370905,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532643474407_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532643474407,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Spam Client Idle Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Deleted",
            "id": "1532643474407_#_11",
            "eventName": "Deleted",
            "timestamp": 1532643474407,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532643525497_1532643525887",
        "id": "AWTYrzvYpSLeWrHL-2Ys",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532643525497"
          },
          {
            "key": "endTime",
            "value": "1532643525887"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532643525497_#_1",
            "eventName": "Association Start",
            "timestamp": 1532643525497,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532643525497_#_2",
            "eventName": "Association Done",
            "timestamp": 1532643525497,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532643525499_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532643525499,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532643525736_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532643525736,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532643525760_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532643525760,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532643525760_#_6",
            "eventName": "Mobility",
            "timestamp": 1532643525760,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532643525887_#_7",
            "eventName": "DHCP",
            "timestamp": 1532643525887,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532643525887_#_9",
            "eventName": "Run",
            "timestamp": 1532643525887,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532643645823_1532643824233",
        "id": "AWTYsuWEpSLeWrHL-2ZU",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532643645823"
          },
          {
            "key": "endTime",
            "value": "1532643824233"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532643645823_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532643645823,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532643645883_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532643645883,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532643676227_#_7",
            "eventName": "DHCP",
            "timestamp": 1532643676227,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532643823895_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532643823895,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532643824193_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532643824193,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532643824233_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532643824233,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532643824233_#_9",
            "eventName": "Run",
            "timestamp": 1532643824233,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532644127276_1532644171196",
        "id": "AWTYuJ7ypSLeWrHL-2aI",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532644127276"
          },
          {
            "key": "endTime",
            "value": "1532644171196"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532644127276_#_7",
            "eventName": "DHCP",
            "timestamp": 1532644127276,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532644152307_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532644152307,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Start",
            "id": "1532644171023_#_1",
            "eventName": "Association Start",
            "timestamp": 1532644171023,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532644171024_#_2",
            "eventName": "Association Done",
            "timestamp": 1532644171024,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532644171026_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532644171026,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532644171185_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532644171185,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532644171196_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532644171196,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532644171196_#_9",
            "eventName": "Run",
            "timestamp": 1532644171196,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532644171301_1532644193245",
        "id": "AWTYuJ7ypSLeWrHL-2aJ",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532644171301"
          },
          {
            "key": "endTime",
            "value": "1532644193245"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532644171301_#_7",
            "eventName": "DHCP",
            "timestamp": 1532644171301,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Start",
            "id": "1532644192134_#_1",
            "eventName": "Association Start",
            "timestamp": 1532644192134,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532644192134_#_2",
            "eventName": "Association Done",
            "timestamp": 1532644192134,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532644193245_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532644193245,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532644193245_#_9",
            "eventName": "Run",
            "timestamp": 1532644193245,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532644343943_1532644429648",
        "id": "AWTYvEgnpSLeWrHL-2a3",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532644343943"
          },
          {
            "key": "endTime",
            "value": "1532644429648"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532644343943_#_7",
            "eventName": "DHCP",
            "timestamp": 1532644343943,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Start",
            "id": "1532644429481_#_1",
            "eventName": "Association Start",
            "timestamp": 1532644429481,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532644429481_#_2",
            "eventName": "Association Done",
            "timestamp": 1532644429481,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532644429648_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532644429648,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532644429648_#_9",
            "eventName": "Run",
            "timestamp": 1532644429648,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532644429829_1532644457817",
        "id": "AWTYvEgnpSLeWrHL-2a4",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532644429829"
          },
          {
            "key": "endTime",
            "value": "1532644457817"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532644429829_#_7",
            "eventName": "DHCP",
            "timestamp": 1532644429829,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532644457607_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532644457607,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532644457798_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532644457798,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532644457817_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532644457817,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532644457817_#_9",
            "eventName": "Run",
            "timestamp": 1532644457817,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },

      {
        "name": "Broadcast Rekey_1532644572131_1532644575403",
        "id": "AWTYvhyJpSLeWrHL-2bJ",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_FAIL",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "4 way Key Timeout/WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532644572131"
          },
          {
            "key": "endTime",
            "value": "1532644575403"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532644572131_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532644572131,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532644575403_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532644575403,
            "index": 1,
            "resultStatus": "CL_FAIL",
            "details": [
              {
                "key": "additionalInfo",
                "value": "4 way Key Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532644575403_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532644575403,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "INTRA-Roaming_1532644575986_1532644576199",
        "id": "AWTYvhyJpSLeWrHL-2bK",
        "eventName": "INTRA-Roaming",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "Radio",
            "value": "1"
          },
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "5.0"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:3A:7D:87:C3:80"
          },
          {
            "key": "AP_Name",
            "value": "AP3800-7"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:AP3800-7/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532644575986"
          },
          {
            "key": "endTime",
            "value": "1532644576199"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532644575986_#_1",
            "eventName": "Association Start",
            "timestamp": 1532644575986,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:3A:7D:87:C3:80"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "1"
              },
              {
                "key": "FREQUENCY",
                "value": "5.0"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532644575986_#_2",
            "eventName": "Association Done",
            "timestamp": 1532644575986,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Intra Roam"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532644575988_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532644575988,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532644576181_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532644576181,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532644576199_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532644576199,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532644576199_#_9",
            "eventName": "Run",
            "timestamp": 1532644576199,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "INTRA-Roaming_1532644576280_1532644586851",
        "id": "AWTYvhyJpSLeWrHL-2bL",
        "eventName": "INTRA-Roaming",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "Radio",
            "value": "1"
          },
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "5.0"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532644576280"
          },
          {
            "key": "endTime",
            "value": "1532644586851"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532644576280_#_7",
            "eventName": "DHCP",
            "timestamp": 1532644576280,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Start",
            "id": "1532644586612_#_1",
            "eventName": "Association Start",
            "timestamp": 1532644586612,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "1"
              },
              {
                "key": "FREQUENCY",
                "value": "5.0"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532644586612_#_2",
            "eventName": "Association Done",
            "timestamp": 1532644586612,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Intra Roam"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532644586614_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532644586614,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532644586828_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532644586828,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532644586851_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532644586851,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532644586851_#_9",
            "eventName": "Run",
            "timestamp": 1532644586851,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532644737228_1532644886416",
        "id": "AWTYw1_zpSLeWrHL-2bz",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532644737228"
          },
          {
            "key": "endTime",
            "value": "1532644886416"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532644737228_#_7",
            "eventName": "DHCP",
            "timestamp": 1532644737228,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532644880631_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532644880631,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532644880811_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532644880811,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532644885459_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532644885459,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532644886392_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532644886392,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532644886415_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532644886415,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532644886416_#_9",
            "eventName": "Run",
            "timestamp": 1532644886416,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532644887623_1532644918895",
        "id": "AWTYxTUepSLeWrHL-2cB",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532644887623"
          },
          {
            "key": "endTime",
            "value": "1532644918895"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532644887623_#_7",
            "eventName": "DHCP",
            "timestamp": 1532644887623,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Start",
            "id": "1532644918745_#_1",
            "eventName": "Association Start",
            "timestamp": 1532644918745,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532644918746_#_2",
            "eventName": "Association Done",
            "timestamp": 1532644918746,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532644918895_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532644918895,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532644918895_#_9",
            "eventName": "Run",
            "timestamp": 1532644918895,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532645069551_1532645172967",
        "id": "AWTYx0O4pSLeWrHL-2cY",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532645069551"
          },
          {
            "key": "endTime",
            "value": "1532645172967"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532645069551_#_7",
            "eventName": "DHCP",
            "timestamp": 1532645069551,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532645172679_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532645172679,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532645172937_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532645172937,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532645172967_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532645172967,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532645172967_#_9",
            "eventName": "Run",
            "timestamp": 1532645172967,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532645370620_1532645507347",
        "id": "AWTYzIf_pSLeWrHL-2dA",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532645370620"
          },
          {
            "key": "endTime",
            "value": "1532645507347"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532645370620_#_7",
            "eventName": "DHCP",
            "timestamp": 1532645370620,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532645493499_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532645493499,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532645493671_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532645493671,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532645507135_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532645507135,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532645507324_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532645507324,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532645507347_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532645507347,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532645507347_#_9",
            "eventName": "Run",
            "timestamp": 1532645507347,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532645671363_1532645844018",
        "id": "AWTY0gZopSLeWrHL-2dp",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532645671363"
          },
          {
            "key": "endTime",
            "value": "1532645844018"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532645671363_#_7",
            "eventName": "DHCP",
            "timestamp": 1532645671363,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532645800995_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532645800995,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532645801188_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532645801188,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532645821675_#_7",
            "eventName": "DHCP",
            "timestamp": 1532645821675,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532645843551_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532645843551,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532645843987_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532645843987,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532645844018_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532645844018,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532645844018_#_9",
            "eventName": "Run",
            "timestamp": 1532645844018,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Delete_1532645972176_1532646051507",
        "id": "AWTY1a-VpSLeWrHL-2eL",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532645972176"
          },
          {
            "key": "endTime",
            "value": "1532646051507"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532645972176_#_7",
            "eventName": "DHCP",
            "timestamp": 1532645972176,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532646051507_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532646051507,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Spam Client Idle Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Deleted",
            "id": "1532646051507_#_11",
            "eventName": "Deleted",
            "timestamp": 1532646051507,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532646093708_1532646094265",
        "id": "AWTY14RgpSLeWrHL-2eP",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532646093708"
          },
          {
            "key": "endTime",
            "value": "1532646094265"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532646093708_#_1",
            "eventName": "Association Start",
            "timestamp": 1532646093708,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532646093708_#_2",
            "eventName": "Association Done",
            "timestamp": 1532646093708,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532646093710_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532646093710,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532646094023_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532646094023,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532646094087_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532646094087,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532646094087_#_6",
            "eventName": "Mobility",
            "timestamp": 1532646094087,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532646094265_#_7",
            "eventName": "DHCP",
            "timestamp": 1532646094265,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532646094265_#_9",
            "eventName": "Run",
            "timestamp": 1532646094265,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532646244581_1532646378101",
        "id": "AWTY2ZQlpSLeWrHL-2eh",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532646244581"
          },
          {
            "key": "endTime",
            "value": "1532646378101"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532646244581_#_7",
            "eventName": "DHCP",
            "timestamp": 1532646244581,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532646377823_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532646377823,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532646378075_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532646378075,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532646378101_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532646378101,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532646378101_#_9",
            "eventName": "Run",
            "timestamp": 1532646378101,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532646545066_1532646685440",
        "id": "AWTY3T1vpSLeWrHL-2fF",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532646545066"
          },
          {
            "key": "endTime",
            "value": "1532646685440"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532646545066_#_7",
            "eventName": "DHCP",
            "timestamp": 1532646545066,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532646685103_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532646685103,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532646685403_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532646685403,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532646685440_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532646685440,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532646685440_#_9",
            "eventName": "Run",
            "timestamp": 1532646685440,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Delete_1532646845696_1532646951839",
        "id": "AWTY4oE6pSLeWrHL-2fU",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532646845696"
          },
          {
            "key": "endTime",
            "value": "1532646951839"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532646845696_#_7",
            "eventName": "DHCP",
            "timestamp": 1532646845696,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532646951839_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532646951839,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Spam Client Idle Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Deleted",
            "id": "1532646951839_#_11",
            "eventName": "Deleted",
            "timestamp": 1532646951839,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532647000192_1532647000553",
        "id": "AWTY5FWdpSLeWrHL-2fV",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532647000192"
          },
          {
            "key": "endTime",
            "value": "1532647000553"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532647000192_#_1",
            "eventName": "Association Start",
            "timestamp": 1532647000192,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532647000192_#_2",
            "eventName": "Association Done",
            "timestamp": 1532647000192,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532647000194_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532647000194,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532647000430_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532647000430,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532647000456_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532647000456,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532647000457_#_6",
            "eventName": "Mobility",
            "timestamp": 1532647000457,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532647000553_#_7",
            "eventName": "DHCP",
            "timestamp": 1532647000553,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532647000553_#_9",
            "eventName": "Run",
            "timestamp": 1532647000553,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532647301275_1532647316075",
        "id": "AWTY6dQ1pSLeWrHL-2gP",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532647301275"
          },
          {
            "key": "endTime",
            "value": "1532647316075"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532647301275_#_7",
            "eventName": "DHCP",
            "timestamp": 1532647301275,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532647315803_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532647315803,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532647316050_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532647316050,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532647316075_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532647316075,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532647316075_#_9",
            "eventName": "Run",
            "timestamp": 1532647316075,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532647451694_1532647613610",
        "id": "AWTY7X2FpSLeWrHL-2gj",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532647451694"
          },
          {
            "key": "endTime",
            "value": "1532647613610"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532647451694_#_7",
            "eventName": "DHCP",
            "timestamp": 1532647451694,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532647602250_#_7",
            "eventName": "DHCP",
            "timestamp": 1532647602250,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532647613075_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532647613075,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532647613553_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532647613553,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532647613610_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532647613610,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532647613610_#_9",
            "eventName": "Run",
            "timestamp": 1532647613610,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532647752643_1532647901588",
        "id": "AWTY741QpSLeWrHL-2g8",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532647752643"
          },
          {
            "key": "endTime",
            "value": "1532647901588"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532647752643_#_7",
            "eventName": "DHCP",
            "timestamp": 1532647752643,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532647901307_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532647901307,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532647901560_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532647901560,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532647901588_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532647901588,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532647901588_#_9",
            "eventName": "Run",
            "timestamp": 1532647901588,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532648053517_1532648202087",
        "id": "AWTY9qU1pSLeWrHL-2hn",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532648053517"
          },
          {
            "key": "endTime",
            "value": "1532648202087"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532648053517_#_7",
            "eventName": "DHCP",
            "timestamp": 1532648053517,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532648201583_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532648201583,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532648202061_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532648202061,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532648202087_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532648202087,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532648202087_#_9",
            "eventName": "Run",
            "timestamp": 1532648202087,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532648504804_1532648520263",
        "id": "AWTY_CO_pSLeWrHL-2iO",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532648504804"
          },
          {
            "key": "endTime",
            "value": "1532648520263"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532648504804_#_7",
            "eventName": "DHCP",
            "timestamp": 1532648504804,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532648519951_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532648519951,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532648520240_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532648520240,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532648520263_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532648520263,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532648520263_#_9",
            "eventName": "Run",
            "timestamp": 1532648520263,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532648655123_1532648852750",
        "id": "AWTY_80CpSLeWrHL-2iY",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532648655123"
          },
          {
            "key": "endTime",
            "value": "1532648852750"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532648655123_#_7",
            "eventName": "DHCP",
            "timestamp": 1532648655123,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532648805469_#_7",
            "eventName": "DHCP",
            "timestamp": 1532648805469,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532648852379_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532648852379,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532648852723_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532648852723,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532648852749_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532648852749,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532648852750_#_9",
            "eventName": "Run",
            "timestamp": 1532648852750,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532649106002_1532649179209",
        "id": "AWTZBUr_pSLeWrHL-2jR",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532649106002"
          },
          {
            "key": "endTime",
            "value": "1532649179209"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532649106002_#_7",
            "eventName": "DHCP",
            "timestamp": 1532649106002,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532649178775_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532649178775,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532649179181_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532649179181,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532649179209_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532649179209,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532649179209_#_9",
            "eventName": "Run",
            "timestamp": 1532649179209,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532649256275_1532649470199",
        "id": "AWTZByAypSLeWrHL-2ji",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532649256275"
          },
          {
            "key": "endTime",
            "value": "1532649470199"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532649256275_#_7",
            "eventName": "DHCP",
            "timestamp": 1532649256275,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532649406571_#_7",
            "eventName": "DHCP",
            "timestamp": 1532649406571,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532649469987_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532649469987,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532649470140_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532649470140,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532649470198_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532649470198,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532649470199_#_9",
            "eventName": "Run",
            "timestamp": 1532649470199,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532649497735_1532649500999",
        "id": "AWTZCsnBpSLeWrHL-2j7",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_FAIL",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "4 way Key Timeout/WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532649497735"
          },
          {
            "key": "endTime",
            "value": "1532649500999"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532649497735_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532649497735,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532649500999_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532649500999,
            "index": 1,
            "resultStatus": "CL_FAIL",
            "details": [
              {
                "key": "additionalInfo",
                "value": "4 way Key Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532649500999_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532649500999,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532649511676_1532649511929",
        "id": "AWTZCsnBpSLeWrHL-2j8",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532649511676"
          },
          {
            "key": "endTime",
            "value": "1532649511929"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532649511676_#_1",
            "eventName": "Association Start",
            "timestamp": 1532649511676,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532649511676_#_2",
            "eventName": "Association Done",
            "timestamp": 1532649511676,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532649511682_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532649511682,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532649511909_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532649511909,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532649511929_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532649511929,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532649511929_#_9",
            "eventName": "Run",
            "timestamp": 1532649511929,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532649512058_1532649553121",
        "id": "AWTZDJ7ypSLeWrHL-2kP",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532649512058"
          },
          {
            "key": "endTime",
            "value": "1532649553121"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532649512058_#_7",
            "eventName": "DHCP",
            "timestamp": 1532649512058,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Start",
            "id": "1532649551885_#_1",
            "eventName": "Association Start",
            "timestamp": 1532649551885,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532649551885_#_2",
            "eventName": "Association Done",
            "timestamp": 1532649551885,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532649553121_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532649553121,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532649553121_#_9",
            "eventName": "Run",
            "timestamp": 1532649553121,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532649703733_1532649822091",
        "id": "AWTZDnMDpSLeWrHL-2kh",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532649703733"
          },
          {
            "key": "endTime",
            "value": "1532649822091"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532649703733_#_7",
            "eventName": "DHCP",
            "timestamp": 1532649703733,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532649807259_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532649807259,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532649807319_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532649807319,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532649821479_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532649821479,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532649822055_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532649822055,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532649822091_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532649822091,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532649822091_#_9",
            "eventName": "Run",
            "timestamp": 1532649822091,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532650004501_1532650158226",
        "id": "AWTZE_GGpSLeWrHL-2lI",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532650004501"
          },
          {
            "key": "endTime",
            "value": "1532650158226"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532650004501_#_7",
            "eventName": "DHCP",
            "timestamp": 1532650004501,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532650113655_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532650113655,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532650113911_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532650113911,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532650154788_#_7",
            "eventName": "DHCP",
            "timestamp": 1532650154788,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532650157927_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532650157927,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532650158203_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532650158203,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532650158226_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532650158226,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532650158226_#_9",
            "eventName": "Run",
            "timestamp": 1532650158226,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },


      {
        "name": "Delete_1532650305069_1532650388807",
        "id": "AWTZFcZMpSLeWrHL-2la",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532650305069"
          },
          {
            "key": "endTime",
            "value": "1532650388807"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532650305069_#_7",
            "eventName": "DHCP",
            "timestamp": 1532650305069,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532650388807_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532650388807,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Spam Client Idle Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Deleted",
            "id": "1532650388807_#_11",
            "eventName": "Deleted",
            "timestamp": 1532650388807,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532650427992_1532650428427",
        "id": "AWTZGW_fpSLeWrHL-2lm",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532650427992"
          },
          {
            "key": "endTime",
            "value": "1532650428427"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532650427992_#_1",
            "eventName": "Association Start",
            "timestamp": 1532650427992,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532650427992_#_2",
            "eventName": "Association Done",
            "timestamp": 1532650427992,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532650427994_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532650427994,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532650428236_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532650428236,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532650428266_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532650428266,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532650428267_#_6",
            "eventName": "Mobility",
            "timestamp": 1532650428267,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532650428427_#_7",
            "eventName": "DHCP",
            "timestamp": 1532650428427,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532650428427_#_9",
            "eventName": "Run",
            "timestamp": 1532650428427,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Delete_1532650578828_1532650633991",
        "id": "AWTZG0RIpSLeWrHL-2l4",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532650578828"
          },
          {
            "key": "endTime",
            "value": "1532650633991"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532650578828_#_7",
            "eventName": "DHCP",
            "timestamp": 1532650578828,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532650633991_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532650633991,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Spam Client Idle Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Deleted",
            "id": "1532650633991_#_11",
            "eventName": "Deleted",
            "timestamp": 1532650633991,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532650687573_1532650688015",
        "id": "AWTZG0RIpSLeWrHL-2l5",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532650687573"
          },
          {
            "key": "endTime",
            "value": "1532650688015"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532650687573_#_1",
            "eventName": "Association Start",
            "timestamp": 1532650687573,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532650687573_#_2",
            "eventName": "Association Done",
            "timestamp": 1532650687573,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532650687575_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532650687575,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532650687855_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532650687855,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532650687903_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532650687903,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532650687903_#_6",
            "eventName": "Mobility",
            "timestamp": 1532650687903,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532650688015_#_7",
            "eventName": "DHCP",
            "timestamp": 1532650688015,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532650688015_#_9",
            "eventName": "Run",
            "timestamp": 1532650688015,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Delete_1532650838558_1532650954259",
        "id": "AWTZHykBpSLeWrHL-2ms",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532650838558"
          },
          {
            "key": "endTime",
            "value": "1532650954259"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532650838558_#_7",
            "eventName": "DHCP",
            "timestamp": 1532650838558,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532650954259_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532650954259,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Spam Client Idle Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Deleted",
            "id": "1532650954259_#_11",
            "eventName": "Deleted",
            "timestamp": 1532650954259,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532650962503_1532650962858",
        "id": "AWTZHykBpSLeWrHL-2mt",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532650962503"
          },
          {
            "key": "endTime",
            "value": "1532650962858"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532650962503_#_1",
            "eventName": "Association Start",
            "timestamp": 1532650962503,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532650962503_#_2",
            "eventName": "Association Done",
            "timestamp": 1532650962503,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532650962505_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532650962505,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532650962743_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532650962743,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532650962761_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532650962761,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532650962761_#_6",
            "eventName": "Mobility",
            "timestamp": 1532650962761,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532650962858_#_7",
            "eventName": "DHCP",
            "timestamp": 1532650962858,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532650962858_#_9",
            "eventName": "Run",
            "timestamp": 1532650962858,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532651263907_1532651285047",
        "id": "AWTZJkC2pSLeWrHL-2nm",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532651263907"
          },
          {
            "key": "endTime",
            "value": "1532651285047"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532651263907_#_7",
            "eventName": "DHCP",
            "timestamp": 1532651263907,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532651284703_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532651284703,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532651285019_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532651285019,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532651285047_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532651285047,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532651285047_#_9",
            "eventName": "Run",
            "timestamp": 1532651285047,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532651414221_1532651620910",
        "id": "AWTZKFCOpSLeWrHL-2oD",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532651414221"
          },
          {
            "key": "endTime",
            "value": "1532651620910"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532651414221_#_7",
            "eventName": "DHCP",
            "timestamp": 1532651414221,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532651564750_#_7",
            "eventName": "DHCP",
            "timestamp": 1532651564750,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532651620131_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532651620131,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532651620868_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532651620868,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532651620910_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532651620910,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532651620910_#_9",
            "eventName": "Run",
            "timestamp": 1532651620910,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532651865440_1532651944947",
        "id": "AWTZL2hPpSLeWrHL-2oz",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532651865440"
          },
          {
            "key": "endTime",
            "value": "1532651944947"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532651865440_#_7",
            "eventName": "DHCP",
            "timestamp": 1532651865440,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532651944519_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532651944519,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532651944913_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532651944913,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532651944947_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532651944947,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532651944947_#_9",
            "eventName": "Run",
            "timestamp": 1532651944947,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532652166393_1532652266372",
        "id": "AWTZM0w1pSLeWrHL-2pB",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532652166393"
          },
          {
            "key": "endTime",
            "value": "1532652266372"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532652166393_#_7",
            "eventName": "DHCP",
            "timestamp": 1532652166393,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532652266033_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532652266033,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532652266342_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532652266342,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532652266372_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532652266372,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532652266372_#_9",
            "eventName": "Run",
            "timestamp": 1532652266372,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532652466845_1532652546852",
        "id": "AWTZNvZZpSLeWrHL-2pS",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532652466845"
          },
          {
            "key": "endTime",
            "value": "1532652546852"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532652466845_#_7",
            "eventName": "DHCP",
            "timestamp": 1532652466845,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532652546241_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532652546241,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532652546825_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532652546825,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532652546852_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532652546852,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532652546852_#_9",
            "eventName": "Run",
            "timestamp": 1532652546852,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532652767503_1532652858943",
        "id": "AWTZPHRYpSLeWrHL-2qK",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532652767503"
          },
          {
            "key": "endTime",
            "value": "1532652858943"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532652767503_#_7",
            "eventName": "DHCP",
            "timestamp": 1532652767503,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532652858545_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532652858545,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532652858919_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532652858919,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532652858943_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532652858943,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532652858943_#_9",
            "eventName": "Run",
            "timestamp": 1532652858943,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532653068198_1532653144921",
        "id": "AWTZP-OspSLeWrHL-2qp",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532653068198"
          },
          {
            "key": "endTime",
            "value": "1532653144921"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532653068198_#_7",
            "eventName": "DHCP",
            "timestamp": 1532653068198,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532653144761_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532653144761,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532653144900_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532653144900,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532653144921_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532653144921,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532653144921_#_9",
            "eventName": "Run",
            "timestamp": 1532653144921,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },

      {
        "name": "Delete_1532653218503_1532653355317",
        "id": "AWTZQ4zwpSLeWrHL-2rN",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532653218503"
          },
          {
            "key": "endTime",
            "value": "1532653355317"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532653218503_#_7",
            "eventName": "DHCP",
            "timestamp": 1532653218503,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532653355317_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532653355317,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Spam Client Idle Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Deleted",
            "id": "1532653355317_#_11",
            "eventName": "Deleted",
            "timestamp": 1532653355317,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532653372997_1532653373441",
        "id": "AWTZQ8eRpSLeWrHL-2rO",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532653372997"
          },
          {
            "key": "endTime",
            "value": "1532653373441"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532653372997_#_1",
            "eventName": "Association Start",
            "timestamp": 1532653372997,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532653372997_#_2",
            "eventName": "Association Done",
            "timestamp": 1532653372997,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532653372999_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532653372999,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532653373298_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532653373298,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532653373339_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532653373339,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532653373339_#_6",
            "eventName": "Mobility",
            "timestamp": 1532653373339,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532653373441_#_7",
            "eventName": "DHCP",
            "timestamp": 1532653373441,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532653373441_#_9",
            "eventName": "Run",
            "timestamp": 1532653373441,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532653674182_1532653691536",
        "id": "AWTZSt9spSLeWrHL-2r1",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532653674182"
          },
          {
            "key": "endTime",
            "value": "1532653691536"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532653674182_#_7",
            "eventName": "DHCP",
            "timestamp": 1532653674182,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532653691121_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532653691121,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532653691506_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532653691506,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532653691536_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532653691536,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532653691536_#_9",
            "eventName": "Run",
            "timestamp": 1532653691536,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532653814141_1532654004803",
        "id": "AWTZTonPpSLeWrHL-2sB",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532653814141"
          },
          {
            "key": "endTime",
            "value": "1532654004803"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532653814141_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532653814141,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532653814526_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532653814526,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532653824498_#_7",
            "eventName": "DHCP",
            "timestamp": 1532653824498,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532653974825_#_7",
            "eventName": "DHCP",
            "timestamp": 1532653974825,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532654004417_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532654004417,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532654004770_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532654004770,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532654004803_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532654004803,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532654004803_#_9",
            "eventName": "Run",
            "timestamp": 1532654004803,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532654275449_1532654290797",
        "id": "AWTZVAdupSLeWrHL-2sq",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532654275449"
          },
          {
            "key": "endTime",
            "value": "1532654290797"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532654275449_#_7",
            "eventName": "DHCP",
            "timestamp": 1532654275449,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532654290613_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532654290613,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532654290773_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532654290773,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532654290797_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532654290797,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532654290797_#_9",
            "eventName": "Run",
            "timestamp": 1532654290797,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532654425595_1532654604196",
        "id": "AWTZVhbapSLeWrHL-2tE",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532654425595"
          },
          {
            "key": "endTime",
            "value": "1532654604196"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532654425595_#_7",
            "eventName": "DHCP",
            "timestamp": 1532654425595,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532654433101_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532654433101,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532654433215_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532654433215,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532654576224_#_7",
            "eventName": "DHCP",
            "timestamp": 1532654576224,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532654603933_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532654603933,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532654604132_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532654604132,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532654604196_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532654604196,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532654604196_#_9",
            "eventName": "Run",
            "timestamp": 1532654604196,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532654876880_1532654931493",
        "id": "AWTZXS8rpSLeWrHL-2uA",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532654876880"
          },
          {
            "key": "endTime",
            "value": "1532654931493"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532654876880_#_7",
            "eventName": "DHCP",
            "timestamp": 1532654876880,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532654930301_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532654930301,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Start",
            "id": "1532654931258_#_1",
            "eventName": "Association Start",
            "timestamp": 1532654931258,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532654931258_#_2",
            "eventName": "Association Done",
            "timestamp": 1532654931258,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532654931261_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532654931261,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532654931478_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532654931478,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532654931493_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532654931493,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532654931493_#_9",
            "eventName": "Run",
            "timestamp": 1532654931493,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532655047981_1532655221900",
        "id": "AWTZYNkjpSLeWrHL-2ue",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532655047981"
          },
          {
            "key": "endTime",
            "value": "1532655221900"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532655047981_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532655047981,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532655048225_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532655048225,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532655081945_#_7",
            "eventName": "DHCP",
            "timestamp": 1532655081945,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532655221561_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532655221561,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532655221870_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532655221870,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532655221900_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532655221900,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532655221900_#_9",
            "eventName": "Run",
            "timestamp": 1532655221900,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532655382822_1532655521199",
        "id": "AWTZZIJRpSLeWrHL-2uw",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532655382822"
          },
          {
            "key": "endTime",
            "value": "1532655521199"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532655382822_#_7",
            "eventName": "DHCP",
            "timestamp": 1532655382822,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532655520821_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532655520821,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532655521142_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532655521142,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532655521199_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532655521199,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532655521199_#_9",
            "eventName": "Run",
            "timestamp": 1532655521199,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532655665957_1532655801369",
        "id": "AWTZagEjpSLeWrHL-2vD",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532655665957"
          },
          {
            "key": "endTime",
            "value": "1532655801369"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532655665957_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532655665957,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532655665996_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532655665996,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532655683483_#_7",
            "eventName": "DHCP",
            "timestamp": 1532655683483,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532655800985_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532655800985,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532655801308_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532655801308,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532655801369_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532655801369,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532655801369_#_9",
            "eventName": "Run",
            "timestamp": 1532655801369,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
       {
        "name": "DHCP_1532639909740_1532639985053",
        "id": "AWTYeE3CpSLeWrHL-2SV",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_FAIL",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "4 way Key Timeout/WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532639909740"
          },
          {
            "key": "endTime",
            "value": "1532639985053"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532639909740_#_7",
            "eventName": "DHCP",
            "timestamp": 1532639909740,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532639961829_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532639961829,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532639965029_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532639965029,
            "index": 2,
            "resultStatus": "CL_FAIL",
            "details": [
              {
                "key": "additionalInfo",
                "value": "4 way Key Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532639965029_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532639965029,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532639985053_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532639985053,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },

      {
        "name": "Delete_1532655833674_1532655886549",
        "id": "AWTZa9WOpSLeWrHL-2vM",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532655833674"
          },
          {
            "key": "endTime",
            "value": "1532655886549"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532655833674_#_7",
            "eventName": "DHCP",
            "timestamp": 1532655833674,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532655886549_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532655886549,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Spam Client Idle Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Deleted",
            "id": "1532655886549_#_11",
            "eventName": "Deleted",
            "timestamp": 1532655886549,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532655937492_1532655937891",
        "id": "AWTZa9WOpSLeWrHL-2vO",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532655937492"
          },
          {
            "key": "endTime",
            "value": "1532655937891"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532655937492_#_1",
            "eventName": "Association Start",
            "timestamp": 1532655937492,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532655937493_#_2",
            "eventName": "Association Done",
            "timestamp": 1532655937493,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532655937495_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532655937495,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532655937718_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532655937718,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532655937752_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532655937752,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532655937752_#_6",
            "eventName": "Mobility",
            "timestamp": 1532655937752,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532655937891_#_7",
            "eventName": "DHCP",
            "timestamp": 1532655937891,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532655937891_#_9",
            "eventName": "Run",
            "timestamp": 1532655937891,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532656088208_1532656231974",
        "id": "AWTZb37gpSLeWrHL-2vo",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532656088208"
          },
          {
            "key": "endTime",
            "value": "1532656231974"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532656088208_#_7",
            "eventName": "DHCP",
            "timestamp": 1532656088208,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532656231781_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532656231781,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532656231954_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532656231954,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532656231974_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532656231974,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532656231974_#_9",
            "eventName": "Run",
            "timestamp": 1532656231974,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532656388899_1532656548396",
        "id": "AWTZcyjapSLeWrHL-2wF",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532656388899"
          },
          {
            "key": "endTime",
            "value": "1532656548396"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532656388899_#_7",
            "eventName": "DHCP",
            "timestamp": 1532656388899,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532656539237_#_7",
            "eventName": "DHCP",
            "timestamp": 1532656539237,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532656548097_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532656548097,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532656548374_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532656548374,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532656548396_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532656548396,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532656548396_#_9",
            "eventName": "Run",
            "timestamp": 1532656548396,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532656689470_1532656861740",
        "id": "AWTZeKZ2pSLeWrHL-2w4",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532656689470"
          },
          {
            "key": "endTime",
            "value": "1532656861740"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532656689470_#_7",
            "eventName": "DHCP",
            "timestamp": 1532656689470,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532656839825_#_7",
            "eventName": "DHCP",
            "timestamp": 1532656839825,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532656861461_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532656861461,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532656861712_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532656861712,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532656861740_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532656861740,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532656861740_#_9",
            "eventName": "Run",
            "timestamp": 1532656861740,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532657140598_1532657164011",
        "id": "AWTZf_nTpSLeWrHL-2xn",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532657140598"
          },
          {
            "key": "endTime",
            "value": "1532657164011"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532657140598_#_7",
            "eventName": "DHCP",
            "timestamp": 1532657140598,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532657163745_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532657163745,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532657163979_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532657163979,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532657164011_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532657164011,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532657164011_#_9",
            "eventName": "Run",
            "timestamp": 1532657164011,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532657290878_1532657475530",
        "id": "AWTZg6MLpSLeWrHL-2x5",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532657290878"
          },
          {
            "key": "endTime",
            "value": "1532657475530"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532657290878_#_7",
            "eventName": "DHCP",
            "timestamp": 1532657290878,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532657441295_#_7",
            "eventName": "DHCP",
            "timestamp": 1532657441295,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532657475057_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532657475057,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532657475507_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532657475507,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532657475530_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532657475530,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532657475530_#_9",
            "eventName": "Run",
            "timestamp": 1532657475530,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "EAP Start_1532657507504_1532657507639",
        "id": "AWTZg92spSLeWrHL-2x_",
        "eventName": "EAP Start",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532657507504"
          },
          {
            "key": "endTime",
            "value": "1532657507639"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "EAP Start",
            "id": "1532657507504_#_12",
            "eventName": "EAP Start",
            "timestamp": 1532657507504,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532657507504_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532657507504,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532657507504_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532657507504,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532657507610_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532657507610,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532657507639_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532657507639,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532657507639_#_9",
            "eventName": "Run",
            "timestamp": 1532657507639,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532657742114_1532657805740",
        "id": "AWTZiSEhpSLeWrHL-2yP",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532657742114"
          },
          {
            "key": "endTime",
            "value": "1532657805740"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532657742114_#_7",
            "eventName": "DHCP",
            "timestamp": 1532657742114,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532657805461_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532657805461,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532657805717_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532657805717,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532657805740_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532657805740,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532657805740_#_9",
            "eventName": "Run",
            "timestamp": 1532657805740,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532657892564_1532658089884",
        "id": "AWTZjMq3pSLeWrHL-2yr",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532657892564"
          },
          {
            "key": "endTime",
            "value": "1532658089884"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532657892564_#_7",
            "eventName": "DHCP",
            "timestamp": 1532657892564,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532658042877_#_7",
            "eventName": "DHCP",
            "timestamp": 1532658042877,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532658089685_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532658089685,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532658089860_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532658089860,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532658089884_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532658089884,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532658089884_#_9",
            "eventName": "Run",
            "timestamp": 1532658089884,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532658343480_1532658410330",
        "id": "AWTZkkmPpSLeWrHL-2ze",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532658343480"
          },
          {
            "key": "endTime",
            "value": "1532658410330"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532658343480_#_7",
            "eventName": "DHCP",
            "timestamp": 1532658343480,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532658410077_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532658410077,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532658410298_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532658410298,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532658410330_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532658410330,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532658410330_#_9",
            "eventName": "Run",
            "timestamp": 1532658410330,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532658644018_1532658712556",
        "id": "AWTZl8egpSLeWrHL-20Q",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532658644018"
          },
          {
            "key": "endTime",
            "value": "1532658712556"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532658644018_#_7",
            "eventName": "DHCP",
            "timestamp": 1532658644018,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532658712361_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532658712361,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532658712536_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532658712536,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532658712556_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532658712556,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532658712556_#_9",
            "eventName": "Run",
            "timestamp": 1532658712556,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532658944603_1532659037899",
        "id": "AWTZmdaqpSLeWrHL-20q",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532658944603"
          },
          {
            "key": "endTime",
            "value": "1532659037899"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532658944603_#_7",
            "eventName": "DHCP",
            "timestamp": 1532658944603,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532659037721_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532659037721,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532659037867_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532659037867,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532659037899_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532659037899,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532659037899_#_9",
            "eventName": "Run",
            "timestamp": 1532659037899,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532659245210_1532659330462",
        "id": "AWTZn1SwpSLeWrHL-20_",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532659245210"
          },
          {
            "key": "endTime",
            "value": "1532659330462"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532659245210_#_7",
            "eventName": "DHCP",
            "timestamp": 1532659245210,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532659329957_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532659329957,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532659330420_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532659330420,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532659330462_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532659330462,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532659330462_#_9",
            "eventName": "Run",
            "timestamp": 1532659330462,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532659545816_1532659618492",
        "id": "AWTZov5hpSLeWrHL-21I",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532659545816"
          },
          {
            "key": "endTime",
            "value": "1532659618492"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532659545816_#_7",
            "eventName": "DHCP",
            "timestamp": 1532659545816,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532659618197_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532659618197,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532659618464_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532659618464,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532659618492_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532659618492,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532659618492_#_9",
            "eventName": "Run",
            "timestamp": 1532659618492,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532659846547_1532659953184",
        "id": "AWTZqhcDpSLeWrHL-216",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532659846547"
          },
          {
            "key": "endTime",
            "value": "1532659953184"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532659846547_#_7",
            "eventName": "DHCP",
            "timestamp": 1532659846547,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532659952613_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532659952613,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532659953135_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532659953135,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532659953183_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532659953183,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532659953184_#_9",
            "eventName": "Run",
            "timestamp": 1532659953184,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532660147140_1532660269242",
        "id": "AWTZrcA0pSLeWrHL-22Z",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532660147140"
          },
          {
            "key": "endTime",
            "value": "1532660269242"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532660147140_#_7",
            "eventName": "DHCP",
            "timestamp": 1532660147140,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532660268941_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532660268941,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532660269202_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532660269202,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532660269242_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532660269242,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532660269242_#_9",
            "eventName": "Run",
            "timestamp": 1532660269242,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532660286853_1532660301899",
        "id": "AWTZrfrTpSLeWrHL-22o",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532660286853"
          },
          {
            "key": "endTime",
            "value": "1532660301899"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532660286853_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532660286853,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532660287153_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532660287153,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Start",
            "id": "1532660301729_#_1",
            "eventName": "Association Start",
            "timestamp": 1532660301729,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532660301730_#_2",
            "eventName": "Association Done",
            "timestamp": 1532660301730,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532660301898_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532660301898,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532660301899_#_9",
            "eventName": "Run",
            "timestamp": 1532660301899,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "INTRA-Roaming_1532660302038_1532660356933",
        "id": "AWTZsWnQpSLeWrHL-227",
        "eventName": "INTRA-Roaming",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_FAIL",
        "details": [
          {
            "key": "Radio",
            "value": "1"
          },
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "5.0"
          },
          {
            "key": "AP_Mac",
            "value": "00:3A:7D:87:C3:80"
          },
          {
            "key": "AP_Name",
            "value": "AP3800-7"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "4 way Key Timeout/AP:AP3800-7/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532660302038"
          },
          {
            "key": "endTime",
            "value": "1532660356933"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532660302038_#_7",
            "eventName": "DHCP",
            "timestamp": 1532660302038,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Start",
            "id": "1532660351468_#_1",
            "eventName": "Association Start",
            "timestamp": 1532660351468,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:3A:7D:87:C3:80"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "1"
              },
              {
                "key": "FREQUENCY",
                "value": "5.0"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532660351469_#_2",
            "eventName": "Association Done",
            "timestamp": 1532660351469,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Intra Roam"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532660351471_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532660351471,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "EAP Start",
            "id": "1532660353623_#_12",
            "eventName": "EAP Start",
            "timestamp": 1532660353623,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532660353623_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532660353623,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532660353693_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532660353693,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532660356933_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532660356933,
            "index": 7,
            "resultStatus": "CL_FAIL",
            "details": [
              {
                "key": "additionalInfo",
                "value": "4 way Key Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532660356933_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532660356933,
            "index": 8,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "INTRA-Roaming_1532660361286_1532660361559",
        "id": "AWTZsWnQpSLeWrHL-228",
        "eventName": "INTRA-Roaming",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "Radio",
            "value": "1"
          },
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "5.0"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532660361286"
          },
          {
            "key": "endTime",
            "value": "1532660361559"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532660361286_#_1",
            "eventName": "Association Start",
            "timestamp": 1532660361286,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "1"
              },
              {
                "key": "FREQUENCY",
                "value": "5.0"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532660361286_#_2",
            "eventName": "Association Done",
            "timestamp": 1532660361286,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Intra Roam"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532660361288_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532660361288,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532660361534_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532660361534,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532660361559_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532660361559,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532660361559_#_9",
            "eventName": "Run",
            "timestamp": 1532660361559,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532660597293_1532660662959",
        "id": "AWTZs3lBpSLeWrHL-23a",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532660597293"
          },
          {
            "key": "endTime",
            "value": "1532660662959"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532660597293_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532660597293,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532660597420_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532660597420,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532660662430_#_7",
            "eventName": "DHCP",
            "timestamp": 1532660662430,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532660662573_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532660662573,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532660662931_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532660662931,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532660662959_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532660662959,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532660662959_#_9",
            "eventName": "Run",
            "timestamp": 1532660662959,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532660812748_1532660973321",
        "id": "AWTZtufgpSLeWrHL-23t",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532660812748"
          },
          {
            "key": "endTime",
            "value": "1532660973321"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532660812748_#_7",
            "eventName": "DHCP",
            "timestamp": 1532660812748,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532660905793_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532660905793,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532660906152_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532660906152,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532660963054_#_7",
            "eventName": "DHCP",
            "timestamp": 1532660963054,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532660972925_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532660972925,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532660973220_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532660973220,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532660973321_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532660973321,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532660973321_#_9",
            "eventName": "Run",
            "timestamp": 1532660973321,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Delete_1532661078649_1532661078649",
        "id": "AWTZuPewpSLeWrHL-235",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532661078649"
          },
          {
            "key": "endTime",
            "value": "1532661078649"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Client Deauthenticated",
            "id": "1532661078649_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532661078649,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Spam Client Idle Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Deleted",
            "id": "1532661078649_#_11",
            "eventName": "Deleted",
            "timestamp": 1532661078649,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532661117533_1532661117891",
        "id": "AWTZusvFpSLeWrHL-24A",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532661117533"
          },
          {
            "key": "endTime",
            "value": "1532661117891"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532661117533_#_1",
            "eventName": "Association Start",
            "timestamp": 1532661117533,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532661117534_#_2",
            "eventName": "Association Done",
            "timestamp": 1532661117534,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532661117536_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532661117536,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532661117776_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532661117776,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532661117795_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532661117795,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532661117795_#_6",
            "eventName": "Mobility",
            "timestamp": 1532661117795,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532661117891_#_7",
            "eventName": "DHCP",
            "timestamp": 1532661117891,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532661117891_#_9",
            "eventName": "Run",
            "timestamp": 1532661117891,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532661268405_1532661440167",
        "id": "AWTZwA-cpSLeWrHL-24O",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532661268405"
          },
          {
            "key": "endTime",
            "value": "1532661440167"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532661268405_#_7",
            "eventName": "DHCP",
            "timestamp": 1532661268405,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532661418873_#_7",
            "eventName": "DHCP",
            "timestamp": 1532661418873,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532661439909_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532661439909,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532661440139_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532661440139,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532661440167_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532661440167,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532661440167_#_9",
            "eventName": "Run",
            "timestamp": 1532661440167,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532661719734_1532661758724",
        "id": "AWTZxY5dpSLeWrHL-24_",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532661719734"
          },
          {
            "key": "endTime",
            "value": "1532661758724"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532661719734_#_7",
            "eventName": "DHCP",
            "timestamp": 1532661719734,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532661758289_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532661758289,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532661758697_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532661758697,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532661758724_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532661758724,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532661758724_#_9",
            "eventName": "Run",
            "timestamp": 1532661758724,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532661870274_1532662070836",
        "id": "AWTZx51qpSLeWrHL-25Y",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532661870274"
          },
          {
            "key": "endTime",
            "value": "1532662070836"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532661870274_#_7",
            "eventName": "DHCP",
            "timestamp": 1532661870274,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532662020827_#_7",
            "eventName": "DHCP",
            "timestamp": 1532662020827,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532662070625_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532662070625,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532662070815_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532662070815,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532662070836_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532662070836,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532662070836_#_9",
            "eventName": "Run",
            "timestamp": 1532662070836,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532662321447_1532662390334",
        "id": "AWTZzRvRpSLeWrHL-26S",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532662321447"
          },
          {
            "key": "endTime",
            "value": "1532662390334"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532662321447_#_7",
            "eventName": "DHCP",
            "timestamp": 1532662321447,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532662389949_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532662389949,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532662390304_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532662390304,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532662390334_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532662390334,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532662390334_#_9",
            "eventName": "Run",
            "timestamp": 1532662390334,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532662446037_1532662668330",
        "id": "AWTZ0MVkpSLeWrHL-26l",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532662446037"
          },
          {
            "key": "endTime",
            "value": "1532662668330"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532662446037_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532662446037,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532662446122_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532662446122,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532662471742_#_7",
            "eventName": "DHCP",
            "timestamp": 1532662471742,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532662622059_#_7",
            "eventName": "DHCP",
            "timestamp": 1532662622059,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532662668105_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532662668105,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532662668303_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532662668303,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532662668330_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532662668330,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532662668330_#_9",
            "eventName": "Run",
            "timestamp": 1532662668330,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Delete_1532662753493_1532662829313",
        "id": "AWTZ1gj3pSLeWrHL-27A",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532662753493"
          },
          {
            "key": "endTime",
            "value": "1532662829313"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532662753493_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532662753493,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532662753499_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532662753499,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532662772511_#_7",
            "eventName": "DHCP",
            "timestamp": 1532662772511,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532662829313_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532662829313,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Spam Client Idle Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Deleted",
            "id": "1532662829313_#_11",
            "eventName": "Deleted",
            "timestamp": 1532662829313,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532662927051_1532662927458",
        "id": "AWTZ191rpSLeWrHL-27G",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532662927051"
          },
          {
            "key": "endTime",
            "value": "1532662927458"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532662927051_#_1",
            "eventName": "Association Start",
            "timestamp": 1532662927051,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532662927051_#_2",
            "eventName": "Association Done",
            "timestamp": 1532662927051,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532662927053_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532662927053,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532662927329_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532662927329,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532662927358_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532662927358,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532662927358_#_6",
            "eventName": "Mobility",
            "timestamp": 1532662927358,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532662927458_#_7",
            "eventName": "DHCP",
            "timestamp": 1532662927458,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532662927458_#_9",
            "eventName": "Run",
            "timestamp": 1532662927458,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Delete_1532663063085_1532663129473",
        "id": "AWTZ2bI4pSLeWrHL-27Q",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532663063085"
          },
          {
            "key": "endTime",
            "value": "1532663129473"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532663063085_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532663063085,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532663063186_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532663063186,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532663077896_#_7",
            "eventName": "DHCP",
            "timestamp": 1532663077896,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532663129473_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532663129473,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Spam Client Idle Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Deleted",
            "id": "1532663129473_#_11",
            "eventName": "Deleted",
            "timestamp": 1532663129473,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532663165507_1532663165892",
        "id": "AWTZ2ezYpSLeWrHL-27W",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532663165507"
          },
          {
            "key": "endTime",
            "value": "1532663165892"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532663165507_#_1",
            "eventName": "Association Start",
            "timestamp": 1532663165507,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532663165507_#_2",
            "eventName": "Association Done",
            "timestamp": 1532663165507,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532663165509_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532663165509,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532663165752_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532663165752,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532663165772_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532663165772,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532663165772_#_6",
            "eventName": "Mobility",
            "timestamp": 1532663165772,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532663165892_#_7",
            "eventName": "DHCP",
            "timestamp": 1532663165892,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532663165892_#_9",
            "eventName": "Run",
            "timestamp": 1532663165892,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532663316312_1532663443752",
        "id": "AWTZ3zCgpSLeWrHL-272",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532663316312"
          },
          {
            "key": "endTime",
            "value": "1532663443752"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532663316312_#_7",
            "eventName": "DHCP",
            "timestamp": 1532663316312,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532663371529_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532663371529,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532663371701_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532663371701,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532663443365_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532663443365,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532663443685_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532663443685,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532663443752_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532663443752,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532663443752_#_9",
            "eventName": "Run",
            "timestamp": 1532663443752,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532663617291_1532663730160",
        "id": "AWTZ4UB2pSLeWrHL-28P",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532663617291"
          },
          {
            "key": "endTime",
            "value": "1532663730160"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532663617291_#_7",
            "eventName": "DHCP",
            "timestamp": 1532663617291,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532663677631_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532663677631,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532663677818_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532663677818,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532663729755_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532663729755,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532663730135_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532663730135,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532663730160_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532663730160,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532663730160_#_9",
            "eventName": "Run",
            "timestamp": 1532663730160,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532663918044_1532664042659",
        "id": "AWTZ5r29pSLeWrHL-287",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532663918044"
          },
          {
            "key": "endTime",
            "value": "1532664042659"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532663918044_#_7",
            "eventName": "DHCP",
            "timestamp": 1532663918044,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532663979063_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532663979063,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532663979174_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532663979174,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532664042027_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532664042027,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532664042636_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532664042636,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532664042659_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532664042659,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532664042659_#_9",
            "eventName": "Run",
            "timestamp": 1532664042659,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532664223658_1532664262828",
        "id": "AWTZ6mfSpSLeWrHL-29g",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532664223658"
          },
          {
            "key": "endTime",
            "value": "1532664262828"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532664223658_#_7",
            "eventName": "DHCP",
            "timestamp": 1532664223658,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Start",
            "id": "1532664262664_#_1",
            "eventName": "Association Start",
            "timestamp": 1532664262664,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532664262664_#_2",
            "eventName": "Association Done",
            "timestamp": 1532664262664,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532664262828_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532664262828,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532664262828_#_9",
            "eventName": "Run",
            "timestamp": 1532664262828,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532664262996_1532664358668",
        "id": "AWTZ7dZipSLeWrHL-29o",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532664262996"
          },
          {
            "key": "endTime",
            "value": "1532664358668"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532664262996_#_7",
            "eventName": "DHCP",
            "timestamp": 1532664262996,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532664281455_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532664281455,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532664281462_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532664281462,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532664358411_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532664358411,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532664358633_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532664358633,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532664358668_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532664358668,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532664358668_#_9",
            "eventName": "Run",
            "timestamp": 1532664358668,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532664563742_1532664663142",
        "id": "AWTZ76tHpSLeWrHL-29-",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532664563742"
          },
          {
            "key": "endTime",
            "value": "1532664663142"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532664563742_#_7",
            "eventName": "DHCP",
            "timestamp": 1532664563742,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532664582903_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532664582903,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532664583148_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532664583148,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532664662699_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532664662699,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532664663106_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532664663106,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532664663142_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532664663142,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532664663142_#_9",
            "eventName": "Run",
            "timestamp": 1532664663142,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532664864364_1532664973392",
        "id": "AWTZ9v6GpSLeWrHL-2-j",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532664864364"
          },
          {
            "key": "endTime",
            "value": "1532664973392"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532664864364_#_7",
            "eventName": "DHCP",
            "timestamp": 1532664864364,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532664884291_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532664884291,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532664884486_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532664884486,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532664973031_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532664973031,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532664973360_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532664973360,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532664973392_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532664973392,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532664973392_#_9",
            "eventName": "Run",
            "timestamp": 1532664973392,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532665165275_1532665253554",
        "id": "AWTZ-Q2GpSLeWrHL-2-9",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532665165275"
          },
          {
            "key": "endTime",
            "value": "1532665253554"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532665165275_#_7",
            "eventName": "DHCP",
            "timestamp": 1532665165275,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532665184731_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532665184731,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532665184918_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532665184918,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532665253203_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532665253203,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532665253530_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532665253530,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532665253554_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532665253554,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532665253554_#_9",
            "eventName": "Run",
            "timestamp": 1532665253554,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532665465928_1532665556732",
        "id": "AWTaACYmpSLeWrHL-2_u",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532665465928"
          },
          {
            "key": "endTime",
            "value": "1532665556732"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532665465928_#_7",
            "eventName": "DHCP",
            "timestamp": 1532665465928,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532665488179_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532665488179,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532665488452_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532665488452,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532665556527_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532665556527,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532665556706_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532665556706,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532665556732_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532665556732,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532665556732_#_9",
            "eventName": "Run",
            "timestamp": 1532665556732,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Delete_1532665616474_1532665661027",
        "id": "AWTaACYmpSLeWrHL-2_v",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532665616474"
          },
          {
            "key": "endTime",
            "value": "1532665661027"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532665616474_#_7",
            "eventName": "DHCP",
            "timestamp": 1532665616474,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532665661027_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532665661027,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Spam Client Idle Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Deleted",
            "id": "1532665661027_#_11",
            "eventName": "Deleted",
            "timestamp": 1532665661027,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532665767611_1532665768118",
        "id": "AWTaA8_FpSLeWrHL-3AO",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532665767611"
          },
          {
            "key": "endTime",
            "value": "1532665768118"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532665767611_#_1",
            "eventName": "Association Start",
            "timestamp": 1532665767611,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532665767611_#_2",
            "eventName": "Association Done",
            "timestamp": 1532665767611,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532665767613_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532665767613,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532665767891_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532665767891,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532665767950_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532665767950,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532665767950_#_6",
            "eventName": "Mobility",
            "timestamp": 1532665767950,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532665768118_#_7",
            "eventName": "DHCP",
            "timestamp": 1532665768118,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532665768118_#_9",
            "eventName": "Run",
            "timestamp": 1532665768118,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532666069026_1532666074046",
        "id": "AWTaBd7HpSLeWrHL-3Am",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532666069026"
          },
          {
            "key": "endTime",
            "value": "1532666074046"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532666069026_#_7",
            "eventName": "DHCP",
            "timestamp": 1532666069026,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532666073787_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532666073787,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532666073994_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532666073994,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532666074046_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532666074046,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532666074046_#_9",
            "eventName": "Run",
            "timestamp": 1532666074046,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532666219520_1532666380476",
        "id": "AWTaCyI1pSLeWrHL-3A6",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532666219520"
          },
          {
            "key": "endTime",
            "value": "1532666380476"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532666219520_#_7",
            "eventName": "DHCP",
            "timestamp": 1532666219520,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532666369863_#_7",
            "eventName": "DHCP",
            "timestamp": 1532666369863,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532666380075_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532666380075,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532666380403_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532666380403,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532666380476_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532666380476,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532666380476_#_9",
            "eventName": "Run",
            "timestamp": 1532666380476,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532666520253_1532666675626",
        "id": "AWTaDwZppSLeWrHL-3BW",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532666520253"
          },
          {
            "key": "endTime",
            "value": "1532666675626"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532666520253_#_7",
            "eventName": "DHCP",
            "timestamp": 1532666520253,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532666670601_#_7",
            "eventName": "DHCP",
            "timestamp": 1532666670601,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532666675347_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532666675347,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532666675596_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532666675596,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532666675626_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532666675626,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532666675626_#_9",
            "eventName": "Run",
            "timestamp": 1532666675626,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Re-Authentication_1532666998755_1532666999018",
        "id": "AWTaFITZpSLeWrHL-3B7",
        "eventName": "Re-Authentication",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532666998755"
          },
          {
            "key": "endTime",
            "value": "1532666999018"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Authentication Start",
            "id": "1532666998755_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532666998755,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532666998995_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532666998995,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532666999018_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532666999018,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532666999018_#_9",
            "eventName": "Run",
            "timestamp": 1532666999018,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532667157330_1532667312368",
        "id": "AWTaGchQpSLeWrHL-3CZ",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532667157330"
          },
          {
            "key": "endTime",
            "value": "1532667312368"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532667157330_#_7",
            "eventName": "DHCP",
            "timestamp": 1532667157330,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532667303747_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532667303747,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532667303955_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532667303955,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532667307653_#_7",
            "eventName": "DHCP",
            "timestamp": 1532667307653,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532667312119_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532667312119,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532667312340_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532667312340,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532667312368_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532667312368,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532667312368_#_9",
            "eventName": "Run",
            "timestamp": 1532667312368,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Re-Authentication_1532667596351_1532667596598",
        "id": "AWTaHa1JpSLeWrHL-3C-",
        "eventName": "Re-Authentication",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532667596351"
          },
          {
            "key": "endTime",
            "value": "1532667596598"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Authentication Start",
            "id": "1532667596351_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532667596351,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532667596557_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532667596557,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532667596598_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532667596598,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532667596598_#_9",
            "eventName": "Run",
            "timestamp": 1532667596598,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532667758561_1532667918164",
        "id": "AWTaIVZzpSLeWrHL-3DZ",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532667758561"
          },
          {
            "key": "endTime",
            "value": "1532667918164"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532667758561_#_7",
            "eventName": "DHCP",
            "timestamp": 1532667758561,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532667908844_#_7",
            "eventName": "DHCP",
            "timestamp": 1532667908844,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532667911655_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532667911655,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532667911897_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532667911897,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532667917759_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532667917759,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532667918141_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532667918141,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532667918164_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532667918164,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532667918164_#_9",
            "eventName": "Run",
            "timestamp": 1532667918164,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Re-Authentication_1532668209003_1532668209537",
        "id": "AWTaJtR1pSLeWrHL-3D5",
        "eventName": "Re-Authentication",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532668209003"
          },
          {
            "key": "endTime",
            "value": "1532668209537"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Authentication Start",
            "id": "1532668209003_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532668209003,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532668209251_#_7",
            "eventName": "DHCP",
            "timestamp": 1532668209251,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532668209475_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532668209475,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532668209537_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532668209537,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532668209537_#_9",
            "eventName": "Run",
            "timestamp": 1532668209537,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532668359529_1532668482521",
        "id": "AWTaLBfZpSLeWrHL-3EJ",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532668359529"
          },
          {
            "key": "endTime",
            "value": "1532668482521"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532668359529_#_7",
            "eventName": "DHCP",
            "timestamp": 1532668359529,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532668482167_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532668482167,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532668482463_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532668482463,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532668482521_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532668482521,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532668482521_#_9",
            "eventName": "Run",
            "timestamp": 1532668482521,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532668665156_1532668765802",
        "id": "AWTaL8FxpSLeWrHL-3El",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532668665156"
          },
          {
            "key": "endTime",
            "value": "1532668765802"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532668665156_#_7",
            "eventName": "DHCP",
            "timestamp": 1532668665156,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532668765395_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532668765395,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532668765760_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532668765760,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532668765802_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532668765802,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532668765802_#_9",
            "eventName": "Run",
            "timestamp": 1532668765802,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532668965830_1532669048028",
        "id": "AWTaM6YnpSLeWrHL-3FL",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532668965830"
          },
          {
            "key": "endTime",
            "value": "1532669048028"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532668965830_#_7",
            "eventName": "DHCP",
            "timestamp": 1532668965830,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532669047631_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532669047631,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532669047988_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532669047988,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532669048028_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532669048028,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532669048028_#_9",
            "eventName": "Run",
            "timestamp": 1532669048028,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532669266465_1532669365359",
        "id": "AWTaN09VpSLeWrHL-3Fm",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532669266465"
          },
          {
            "key": "endTime",
            "value": "1532669365359"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532669266465_#_7",
            "eventName": "DHCP",
            "timestamp": 1532669266465,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532669364979_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532669364979,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532669365333_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532669365333,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532669365359_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532669365359,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532669365359_#_9",
            "eventName": "Run",
            "timestamp": 1532669365359,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532669567026_1532669613344",
        "id": "AWTaPmdJpSLeWrHL-3GO",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532669567026"
          },
          {
            "key": "endTime",
            "value": "1532669613344"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532669567026_#_7",
            "eventName": "DHCP",
            "timestamp": 1532669567026,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Start",
            "id": "1532669613166_#_1",
            "eventName": "Association Start",
            "timestamp": 1532669613166,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532669613166_#_2",
            "eventName": "Association Done",
            "timestamp": 1532669613166,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532669613344_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532669613344,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532669613344_#_9",
            "eventName": "Run",
            "timestamp": 1532669613344,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532669613439_1532669643562",
        "id": "AWTaPmdJpSLeWrHL-3GP",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532669613439"
          },
          {
            "key": "endTime",
            "value": "1532669643562"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532669613439_#_7",
            "eventName": "DHCP",
            "timestamp": 1532669613439,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532669643199_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532669643199,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532669643540_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532669643540,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532669643562_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532669643562,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532669643562_#_9",
            "eventName": "Run",
            "timestamp": 1532669643562,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532669763888_1532669933932",
        "id": "AWTaQDyYpSLeWrHL-3Gf",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532669763888"
          },
          {
            "key": "endTime",
            "value": "1532669933932"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532669763888_#_7",
            "eventName": "DHCP",
            "timestamp": 1532669763888,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532669914291_#_7",
            "eventName": "DHCP",
            "timestamp": 1532669914291,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532669933451_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532669933451,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532669933872_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532669933872,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532669933932_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532669933932,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532669933932_#_9",
            "eventName": "Run",
            "timestamp": 1532669933932,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532670215066_1532670236151",
        "id": "AWTaR491pSLeWrHL-3HK",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532670215066"
          },
          {
            "key": "endTime",
            "value": "1532670236151"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532670215066_#_7",
            "eventName": "DHCP",
            "timestamp": 1532670215066,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532670235731_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532670235731,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532670236126_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532670236126,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532670236151_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532670236151,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532670236151_#_9",
            "eventName": "Run",
            "timestamp": 1532670236151,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532670365430_1532670564367",
        "id": "AWTaSWQ8pSLeWrHL-3HS",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532670365430"
          },
          {
            "key": "endTime",
            "value": "1532670564367"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532670365430_#_7",
            "eventName": "DHCP",
            "timestamp": 1532670365430,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532670515545_#_7",
            "eventName": "DHCP",
            "timestamp": 1532670515545,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532670564131_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532670564131,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532670564345_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532670564345,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532670564367_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532670564367,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532670564367_#_9",
            "eventName": "Run",
            "timestamp": 1532670564367,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532670816390_1532670846794",
        "id": "AWTaULa-pSLeWrHL-3H-",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532670816390"
          },
          {
            "key": "endTime",
            "value": "1532670846794"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532670816390_#_7",
            "eventName": "DHCP",
            "timestamp": 1532670816390,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532670846327_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532670846327,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532670846708_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532670846708,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532670846794_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532670846794,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532670846794_#_9",
            "eventName": "Run",
            "timestamp": 1532670846794,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532670935967_1532670977063",
        "id": "AWTaULa-pSLeWrHL-3IK",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532670935967"
          },
          {
            "key": "endTime",
            "value": "1532670977063"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532670935967_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532670935967,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532670936248_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532670936248,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532670966736_#_7",
            "eventName": "DHCP",
            "timestamp": 1532670966736,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Start",
            "id": "1532670975917_#_1",
            "eventName": "Association Start",
            "timestamp": 1532670975917,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532670975917_#_2",
            "eventName": "Association Done",
            "timestamp": 1532670975917,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532670977063_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532670977063,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532670977063_#_9",
            "eventName": "Run",
            "timestamp": 1532670977063,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532670977179_1532671170351",
        "id": "AWTaUsaDpSLeWrHL-3Ik",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532670977179"
          },
          {
            "key": "endTime",
            "value": "1532671170351"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532670977179_#_7",
            "eventName": "DHCP",
            "timestamp": 1532670977179,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532671127704_#_7",
            "eventName": "DHCP",
            "timestamp": 1532671127704,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532671169763_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532671169763,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532671170304_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532671170304,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532671170351_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532671170351,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532671170351_#_9",
            "eventName": "Run",
            "timestamp": 1532671170351,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532671428169_1532671451248",
        "id": "AWTaWEU_pSLeWrHL-3JS",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532671428169"
          },
          {
            "key": "endTime",
            "value": "1532671451248"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532671428169_#_7",
            "eventName": "DHCP",
            "timestamp": 1532671428169,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532671450967_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532671450967,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532671451228_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532671451228,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532671451248_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532671451248,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532671451248_#_9",
            "eventName": "Run",
            "timestamp": 1532671451248,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532671578495_1532671733167",
        "id": "AWTaW-54pSLeWrHL-3Jf",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532671578495"
          },
          {
            "key": "endTime",
            "value": "1532671733167"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532671578495_#_7",
            "eventName": "DHCP",
            "timestamp": 1532671578495,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Start",
            "id": "1532671732994_#_1",
            "eventName": "Association Start",
            "timestamp": 1532671732994,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532671732995_#_2",
            "eventName": "Association Done",
            "timestamp": 1532671732995,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532671733167_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532671733167,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532671733167_#_9",
            "eventName": "Run",
            "timestamp": 1532671733167,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532671733277_1532671766818",
        "id": "AWTaW-54pSLeWrHL-3Jg",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532671733277"
          },
          {
            "key": "endTime",
            "value": "1532671766818"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532671733277_#_7",
            "eventName": "DHCP",
            "timestamp": 1532671733277,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532671766295_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532671766295,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532671766688_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532671766688,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532671766818_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532671766818,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532671766818_#_9",
            "eventName": "Run",
            "timestamp": 1532671766818,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532672034067_1532672042022",
        "id": "AWTaYWzrpSLeWrHL-3KJ",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532672034067"
          },
          {
            "key": "endTime",
            "value": "1532672042022"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532672034067_#_7",
            "eventName": "DHCP",
            "timestamp": 1532672034067,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532672041463_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532672041463,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532672041980_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532672041980,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532672042022_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532672042022,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532672042022_#_9",
            "eventName": "Run",
            "timestamp": 1532672042022,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532672184493_1532672315092",
        "id": "AWTaZq--pSLeWrHL-3Kk",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532672184493"
          },
          {
            "key": "endTime",
            "value": "1532672315092"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532672184493_#_7",
            "eventName": "DHCP",
            "timestamp": 1532672184493,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532672314627_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532672314627,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532672315072_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532672315072,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532672315092_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532672315092,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532672315092_#_9",
            "eventName": "Run",
            "timestamp": 1532672315092,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532672448147_1532672621344",
        "id": "AWTaaL-PpSLeWrHL-3K5",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532672448147"
          },
          {
            "key": "endTime",
            "value": "1532672621344"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532672448147_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532672448147,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532672448300_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532672448300,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532672485049_#_7",
            "eventName": "DHCP",
            "timestamp": 1532672485049,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532672620931_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532672620931,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532672621305_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532672621305,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532672621343_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532672621343,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532672621344_#_9",
            "eventName": "Run",
            "timestamp": 1532672621344,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532672785693_1532672917457",
        "id": "AWTab9eDpSLeWrHL-3Ly",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532672785693"
          },
          {
            "key": "endTime",
            "value": "1532672917457"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532672785693_#_7",
            "eventName": "DHCP",
            "timestamp": 1532672785693,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532672917183_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532672917183,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532672917433_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532672917433,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532672917457_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532672917457,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532672917457_#_9",
            "eventName": "Run",
            "timestamp": 1532672917457,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532673052023_1532673195839",
        "id": "AWTac4DYpSLeWrHL-3MY",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532673052023"
          },
          {
            "key": "endTime",
            "value": "1532673195839"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532673052023_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532673052023,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532673052211_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532673052211,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532673086497_#_7",
            "eventName": "DHCP",
            "timestamp": 1532673086497,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532673195371_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532673195371,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532673195802_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532673195802,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532673195839_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532673195839,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532673195839_#_9",
            "eventName": "Run",
            "timestamp": 1532673195839,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532673387361_1532673479010",
        "id": "AWTaeP9XpSLeWrHL-3Mu",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532673387361"
          },
          {
            "key": "endTime",
            "value": "1532673479010"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532673387361_#_7",
            "eventName": "DHCP",
            "timestamp": 1532673387361,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532673478575_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532673478575,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532673478982_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532673478982,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532673479010_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532673479010,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532673479010_#_9",
            "eventName": "Run",
            "timestamp": 1532673479010,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532673657939_1532673803136",
        "id": "AWTaew85pSLeWrHL-3NC",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532673657939"
          },
          {
            "key": "endTime",
            "value": "1532673803136"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532673657939_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532673657939,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532673658030_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532673658030,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532673687957_#_7",
            "eventName": "DHCP",
            "timestamp": 1532673687957,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532673802947_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532673802947,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532673803080_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532673803080,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532673803136_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532673803136,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532673803136_#_9",
            "eventName": "Run",
            "timestamp": 1532673803136,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Delete_1532673959283_1532673992527",
        "id": "AWTafriWpSLeWrHL-3NS",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_FAIL",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "4 way Key Timeout/WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532673959283"
          },
          {
            "key": "endTime",
            "value": "1532673992527"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532673959283_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532673959283,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532673962491_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532673962491,
            "index": 1,
            "resultStatus": "CL_FAIL",
            "details": [
              {
                "key": "additionalInfo",
                "value": "4 way Key Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532673962491_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532673962491,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532673982511_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532673982511,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Deleted",
            "id": "1532673992527_#_11",
            "eventName": "Deleted",
            "timestamp": 1532673992527,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532673992744_1532673993046",
        "id": "AWTafriWpSLeWrHL-3NT",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "Radio",
            "value": "1"
          },
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "5.0"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:3A:7D:87:C3:80"
          },
          {
            "key": "AP_Name",
            "value": "AP3800-7"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:AP3800-7/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532673992744"
          },
          {
            "key": "endTime",
            "value": "1532673993046"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532673992744_#_1",
            "eventName": "Association Start",
            "timestamp": 1532673992744,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:3A:7D:87:C3:80"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "1"
              },
              {
                "key": "FREQUENCY",
                "value": "5.0"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532673992745_#_2",
            "eventName": "Association Done",
            "timestamp": 1532673992745,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532673992750_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532673992750,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532673992942_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532673992942,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532673992959_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532673992959,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532673992959_#_6",
            "eventName": "Mobility",
            "timestamp": 1532673992959,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532673993046_#_7",
            "eventName": "DHCP",
            "timestamp": 1532673993046,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532673993046_#_9",
            "eventName": "Run",
            "timestamp": 1532673993046,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532674143395_1532674293768",
        "id": "AWTag_wqpSLeWrHL-3Nx",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_FAIL",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532674143395"
          },
          {
            "key": "endTime",
            "value": "1532674293768"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532674143395_#_7",
            "eventName": "DHCP",
            "timestamp": 1532674143395,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532674274911_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532674274911,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532674291923_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532674291923,
            "index": 2,
            "resultStatus": "CL_FAIL",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532674293768_#_7",
            "eventName": "DHCP",
            "timestamp": 1532674293768,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Authentication Done_1532674335462_1532674340367",
        "id": "AWTahdCVpSLeWrHL-3OD",
        "eventName": "Authentication Done",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_FAIL",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AAA Auth Failure/WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532674335462"
          },
          {
            "key": "endTime",
            "value": "1532674340367"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Authentication Done",
            "id": "1532674335462_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532674335462,
            "index": 0,
            "resultStatus": "CL_FAIL",
            "details": [
              {
                "key": "additionalInfo",
                "value": "AAA Auth Failure"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532674340367_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532674340367,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "INTRA-Roaming_1532674341840_1532674342215",
        "id": "AWTahdCVpSLeWrHL-3OE",
        "eventName": "INTRA-Roaming",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "Radio",
            "value": "1"
          },
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "5.0"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532674341840"
          },
          {
            "key": "endTime",
            "value": "1532674342215"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532674341840_#_1",
            "eventName": "Association Start",
            "timestamp": 1532674341840,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "1"
              },
              {
                "key": "FREQUENCY",
                "value": "5.0"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532674341840_#_2",
            "eventName": "Association Done",
            "timestamp": 1532674341840,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Intra Roam"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532674341842_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532674341842,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532674342173_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532674342173,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532674342215_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532674342215,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532674342215_#_9",
            "eventName": "Run",
            "timestamp": 1532674342215,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532674492834_1532674634801",
        "id": "AWTaiXqgpSLeWrHL-3Os",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532674492834"
          },
          {
            "key": "endTime",
            "value": "1532674634801"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532674492834_#_7",
            "eventName": "DHCP",
            "timestamp": 1532674492834,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532674563099_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532674563099,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532674563305_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532674563305,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532674634455_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532674634455,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532674634749_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532674634749,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532674634801_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532674634801,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532674634801_#_9",
            "eventName": "Run",
            "timestamp": 1532674634801,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532674793535_1532674816359",
        "id": "AWTajSPgpSLeWrHL-3PK",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532674793535"
          },
          {
            "key": "endTime",
            "value": "1532674816359"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532674793535_#_7",
            "eventName": "DHCP",
            "timestamp": 1532674793535,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "EAP Start",
            "id": "1532674816092_#_12",
            "eventName": "EAP Start",
            "timestamp": 1532674816092,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532674816092_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532674816092,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532674816092_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532674816092,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532674816333_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532674816333,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532674816359_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532674816359,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532674816359_#_9",
            "eventName": "Run",
            "timestamp": 1532674816359,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532674943837_1532675112683",
        "id": "AWTajzLwpSLeWrHL-3Pl",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532674943837"
          },
          {
            "key": "endTime",
            "value": "1532675112683"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532674943837_#_7",
            "eventName": "DHCP",
            "timestamp": 1532674943837,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532675094448_#_7",
            "eventName": "DHCP",
            "timestamp": 1532675094448,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532675112471_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532675112471,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532675112657_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532675112657,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532675112683_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532675112683,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532675112683_#_9",
            "eventName": "Run",
            "timestamp": 1532675112683,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532675395383_1532675414177",
        "id": "AWTalktVpSLeWrHL-3QC",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532675395383"
          },
          {
            "key": "endTime",
            "value": "1532675414177"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532675395383_#_7",
            "eventName": "DHCP",
            "timestamp": 1532675395383,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532675413875_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532675413875,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532675414149_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532675414149,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532675414177_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532675414177,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532675414177_#_9",
            "eventName": "Run",
            "timestamp": 1532675414177,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532675545728_1532675723535",
        "id": "AWTamFsqpSLeWrHL-3QT",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532675545728"
          },
          {
            "key": "endTime",
            "value": "1532675723535"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532675545728_#_7",
            "eventName": "DHCP",
            "timestamp": 1532675545728,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532675696108_#_7",
            "eventName": "DHCP",
            "timestamp": 1532675696108,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532675723195_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532675723195,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532675723510_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532675723510,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532675723535_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532675723535,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532675723535_#_9",
            "eventName": "Run",
            "timestamp": 1532675723535,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532675996819_1532676013829",
        "id": "AWTan3N_pSLeWrHL-3Q3",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532675996819"
          },
          {
            "key": "endTime",
            "value": "1532676013829"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532675996819_#_7",
            "eventName": "DHCP",
            "timestamp": 1532675996819,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532676013399_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532676013399,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532676013809_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532676013809,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532676013829_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532676013829,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532676013829_#_9",
            "eventName": "Run",
            "timestamp": 1532676013829,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532676147167_1532676342324",
        "id": "AWTaoxzBpSLeWrHL-3RZ",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532676147167"
          },
          {
            "key": "endTime",
            "value": "1532676342324"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532676147167_#_7",
            "eventName": "DHCP",
            "timestamp": 1532676147167,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532676297506_#_7",
            "eventName": "DHCP",
            "timestamp": 1532676297506,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532676341795_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532676341795,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532676342288_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532676342288,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532676342324_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532676342324,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532676342324_#_9",
            "eventName": "Run",
            "timestamp": 1532676342324,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532676598214_1532676647706",
        "id": "AWTaqJrgpSLeWrHL-3SU",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532676598214"
          },
          {
            "key": "endTime",
            "value": "1532676647706"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532676598214_#_7",
            "eventName": "DHCP",
            "timestamp": 1532676598214,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532676647135_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532676647135,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532676647664_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532676647664,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532676647706_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532676647706,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532676647706_#_9",
            "eventName": "Run",
            "timestamp": 1532676647706,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532676898895_1532676957855",
        "id": "AWTarhnQpSLeWrHL-3S2",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532676898895"
          },
          {
            "key": "endTime",
            "value": "1532676957855"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532676898895_#_7",
            "eventName": "DHCP",
            "timestamp": 1532676898895,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532676957447_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532676957447,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532676957812_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532676957812,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532676957855_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532676957855,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532676957855_#_9",
            "eventName": "Run",
            "timestamp": 1532676957855,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532677199540_1532677282253",
        "id": "AWTascMcpSLeWrHL-3TG",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532677199540"
          },
          {
            "key": "endTime",
            "value": "1532677282253"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532677199540_#_7",
            "eventName": "DHCP",
            "timestamp": 1532677199540,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532677281843_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532677281843,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532677282230_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532677282230,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532677282253_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532677282253,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532677282253_#_9",
            "eventName": "Run",
            "timestamp": 1532677282253,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532677500217_1532677596980",
        "id": "AWTat0FDpSLeWrHL-3Ta",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532677500217"
          },
          {
            "key": "endTime",
            "value": "1532677596980"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532677500217_#_7",
            "eventName": "DHCP",
            "timestamp": 1532677500217,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532677596163_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532677596163,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532677596914_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532677596914,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532677596980_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532677596980,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532677596980_#_9",
            "eventName": "Run",
            "timestamp": 1532677596980,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532677804951_1532677805126",
        "id": "AWTauup_pSLeWrHL-3T4",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532677804951"
          },
          {
            "key": "endTime",
            "value": "1532677805126"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532677804951_#_1",
            "eventName": "Association Start",
            "timestamp": 1532677804951,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532677804952_#_2",
            "eventName": "Association Done",
            "timestamp": 1532677804952,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532677805126_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532677805126,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532677805126_#_9",
            "eventName": "Run",
            "timestamp": 1532677805126,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532677805230_1532677932897",
        "id": "AWTauup_pSLeWrHL-3T-",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532677805230"
          },
          {
            "key": "endTime",
            "value": "1532677932897"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532677805230_#_7",
            "eventName": "DHCP",
            "timestamp": 1532677805230,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532677932587_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532677932587,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532677932871_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532677932871,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532677932897_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532677932897,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532677932897_#_9",
            "eventName": "Run",
            "timestamp": 1532677932897,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532678106011_1532678247144",
        "id": "AWTawGjwpSLeWrHL-3Uo",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532678106011"
          },
          {
            "key": "endTime",
            "value": "1532678247144"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532678106011_#_7",
            "eventName": "DHCP",
            "timestamp": 1532678106011,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532678246903_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532678246903,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532678247124_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532678247124,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532678247144_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532678247144,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532678247144_#_9",
            "eventName": "Run",
            "timestamp": 1532678247144,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532678406862_1532678559410",
        "id": "AWTaxed7pSLeWrHL-3Vf",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532678406862"
          },
          {
            "key": "endTime",
            "value": "1532678559410"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532678406862_#_7",
            "eventName": "DHCP",
            "timestamp": 1532678406862,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532678557034_#_7",
            "eventName": "DHCP",
            "timestamp": 1532678557034,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532678559251_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532678559251,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532678559390_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532678559390,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532678559410_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532678559410,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532678559410_#_9",
            "eventName": "Run",
            "timestamp": 1532678559410,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Delete_1532678561683_1532678641740",
        "id": "AWTaxiIcpSLeWrHL-3Vn",
        "eventName": "Delete",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532678561683"
          },
          {
            "key": "endTime",
            "value": "1532678641740"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532678561683_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532678561683,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532678561916_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532678561916,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Client Deauthenticated",
            "id": "1532678641739_#_14",
            "eventName": "Client Deauthenticated",
            "timestamp": 1532678641739,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Spam Client Idle Timeout"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Deleted",
            "id": "1532678641740_#_11",
            "eventName": "Deleted",
            "timestamp": 1532678641740,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Onboarding_1532678711454_1532678712052",
        "id": "AWTayZDEpSLeWrHL-3Vx",
        "eventName": "Onboarding",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "Frequency(GHz)",
            "value": "2.4"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "AP_Mac",
            "value": "00:81:C4:CD:AB:90"
          },
          {
            "key": "AP_Name",
            "value": "APDeviceNamePlaceHolder"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "WLAN",
            "value": "ssidPlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "AP:APDeviceNamePlaceHolder/WLC:WLCDeviceNamePlaceHolder/WLAN:ssidPlaceHolder"
          },
          {
            "key": "startTime",
            "value": "1532678711454"
          },
          {
            "key": "endTime",
            "value": "1532678712052"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Association Start",
            "id": "1532678711454_#_1",
            "eventName": "Association Start",
            "timestamp": 1532678711454,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client Association with AP"
              },
              {
                "key": "AP_MAC",
                "value": "00:81:C4:CD:AB:90"
              },
              {
                "key": "WLC_NAME",
                "value": "WLCDeviceNamePlaceHolder"
              },
              {
                "key": "SSID",
                "value": "ssidPlaceHolder"
              },
              {
                "key": "SLOT",
                "value": "0"
              },
              {
                "key": "FREQUENCY",
                "value": "2.4"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Association Done",
            "id": "1532678711454_#_2",
            "eventName": "Association Done",
            "timestamp": 1532678711454,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532678711456_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532678711456,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532678711892_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532678711892,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532678711934_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532678711934,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Mobility",
            "id": "1532678711935_#_6",
            "eventName": "Mobility",
            "timestamp": 1532678711935,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "MM Handoff Timeout"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532678712052_#_7",
            "eventName": "DHCP",
            "timestamp": 1532678712052,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "IP Learn DHCP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532678712052_#_9",
            "eventName": "Run",
            "timestamp": 1532678712052,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532679012962_1532679026481",
        "id": "AWTazTn_pSLeWrHL-3WF",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532679012962"
          },
          {
            "key": "endTime",
            "value": "1532679026481"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532679012962_#_7",
            "eventName": "DHCP",
            "timestamp": 1532679012962,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532679026211_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532679026211,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532679026436_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532679026436,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532679026481_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532679026481,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532679026481_#_9",
            "eventName": "Run",
            "timestamp": 1532679026481,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "DHCP_1532679163448_1532679336778",
        "id": "AWTa0OOepSLeWrHL-3WM",
        "eventName": "DHCP",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532679163448"
          },
          {
            "key": "endTime",
            "value": "1532679336778"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "DHCP",
            "id": "1532679163448_#_7",
            "eventName": "DHCP",
            "timestamp": 1532679163448,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Broadcast Rekey",
            "id": "1532679181599_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532679181599,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532679181835_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532679181835,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532679313773_#_7",
            "eventName": "DHCP",
            "timestamp": 1532679313773,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532679336555_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532679336555,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532679336748_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532679336748,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532679336777_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532679336777,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532679336778_#_9",
            "eventName": "Run",
            "timestamp": 1532679336778,
            "index": 7,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      },
      {
        "name": "Broadcast Rekey_1532679490115_1532679668549",
        "id": "AWTa1Md2pSLeWrHL-3Wp",
        "eventName": "Broadcast Rekey",
        "timestamp": null,
        "index": null,
        "resultStatus": "CL_SUCCESS",
        "details": [
          {
            "key": "VLAN",
            "value": "VlanPlaceHolder"
          },
          {
            "key": "ROLE",
            "value": "LOCAL"
          },
          {
            "key": "WLC_Name",
            "value": "WLCDeviceNamePlaceHolder"
          },
          {
            "key": "IPv4",
            "value": "ipAddressPlaceHolder"
          },
          {
            "key": "IPv6",
            "value": "ipv6PlaceHolder"
          },
          {
            "key": "DHCP-Server",
            "value": "192.168.101.105"
          },
          {
            "key": "USERNAME",
            "value": "UserNamePlaceHolder"
          },
          {
            "key": "additionalInfo",
            "value": "WLC:WLCDeviceNamePlaceHolder/"
          },
          {
            "key": "startTime",
            "value": "1532679490115"
          },
          {
            "key": "endTime",
            "value": "1532679668549"
          }
        ],
        "issues": null,
        "healthScore": 0,
        "source": null,
        "children": [
          {
            "name": "Broadcast Rekey",
            "id": "1532679490115_#_13",
            "eventName": "Broadcast Rekey",
            "timestamp": 1532679490115,
            "index": 0,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532679490263_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532679490263,
            "index": 1,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "DHCP",
            "id": "1532679614340_#_7",
            "eventName": "DHCP",
            "timestamp": 1532679614340,
            "index": 2,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Client already has IP"
              },
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "IPv6",
                "value": "ipv6PlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "SERVER",
                "value": "192.168.101.105"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Start",
            "id": "1532679668003_#_3",
            "eventName": "Authentication Start",
            "timestamp": 1532679668003,
            "index": 3,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Session time out triggered.  PMK(Pairwise Master Key) Cache expired."
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Authentication Done",
            "id": "1532679668530_#_4",
            "eventName": "Authentication Done",
            "timestamp": 1532679668530,
            "index": 4,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "additionalInfo",
                "value": "Dot1x Full Auth"
              },
              {
                "key": "USER",
                "value": "UserNamePlaceHolder"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "KeyExchange",
            "id": "1532679668549_#_5",
            "eventName": "KeyExchange",
            "timestamp": 1532679668549,
            "index": 5,
            "resultStatus": "CL_SUCCESS",
            "details": [

            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          },
          {
            "name": "Run",
            "id": "1532679668549_#_9",
            "eventName": "Run",
            "timestamp": 1532679668549,
            "index": 6,
            "resultStatus": "CL_SUCCESS",
            "details": [
              {
                "key": "IPv4",
                "value": "ipAddressPlaceHolder"
              },
              {
                "key": "VLAN",
                "value": "VlanPlaceHolder"
              },
              {
                "key": "ROLE",
                "value": "1"
              }
            ],
            "issues": null,
            "healthScore": 0,
            "source": null,
            "children": [

            ]
          }
        ]
      }
    ],
    "totalCount": 350
  }
});