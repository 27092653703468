define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/host/Unknown',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/host/Unknown_connected_poor_rssi',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/host/latency_issue',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/host/excessive_auth_fail_client',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/host/auth_aaa_rejected',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/host/auth_cl_timeout_or_rf',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/host/auth_sec_param_mismatch',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/host/excessive_assoc_fail',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/host/excessive_total',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/host/excessive_auth_server',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/host/excessive_auth_fail_wlc',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/host/excessive_auth_fail_server',
    //'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/host/excessive_dhcp',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/host/dhcp_server_or_client_timeout',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/host/ios_client_disassoc_single',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/host/client_poor_band_selection',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/host/sensor_connected_dns_global_server_availability_failed',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/host/sensor_connected_global_mailserver_not_reachable',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/host/sensor_connected_global_mailserver_test_failed',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/host/sensor_connected_global_onboarding_wireless_status_failed',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/host/sensor_connected_global_webserver_not_reachable',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/host/sensor_connected_global_webserver_test_failed',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/host/sensor_connected_server_global_ping_failed',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/host/sensor_dhcp_global_multiple_failures',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/host/sensor_global_webauth_failed',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/host/latency_issue_webx',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/host/latency_issue_MedicalRecords',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/host/latency_issue_ms_office_365',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/host/global_wireless_kairos_excessive_onboarding_failure_trigger',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/host/global_wireless_kairos_excessive_onboarding_time_trigger',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/host/global_wireless_kairos_excessive_dhcp_time_trigger',

], function (Unknown, Unknown_connected_poor_rssi, latency_issue, excessive_auth_fail_client, auth_aaa_rejected,
             auth_cl_timeout_or_rf,
             auth_sec_param_mismatch, excessive_assoc_fail, excessive_total, excessive_auth_server,
             excessive_auth_fail_wlc, excessive_auth_fail_server, dhcp_server_or_client_timeout,
             ios_client_disassoc_single,
             client_poor_band_selection, sensor_connected_dns_global_server_availability_failed,
             sensor_connected_global_mailserver_not_reachable, sensor_connected_global_mailserver_test_failed,
             sensor_connected_global_onboarding_wireless_status_failed, sensor_connected_global_webserver_not_reachable,
             sensor_connected_global_webserver_test_failed, sensor_connected_server_global_ping_failed,
             sensor_dhcp_global_multiple_failures, sensor_global_webauth_failed,latency_issue_webx,latency_issue_MedicalRecords,
             latency_issue_ms_office_365,global_wireless_kairos_excessive_onboarding_failure_trigger,
             global_wireless_kairos_excessive_onboarding_time_trigger,
             global_wireless_kairos_excessive_dhcp_time_trigger) {

    return {
        init: function() {
        },

        getHostIssue: function(issueName) {
            return eval(issueName);
        }
    };

});
