//simulation/lib/infrastructure/data-generation/services
define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/site/DNAMapsTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/DefaultConfig',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/site/SiteBaseTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimDesignData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/site/AccessPointTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/site/HostTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/site/RadiosData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/dnamaps/ElementTypeTemplate'
], function(SimLokiDatabaseActions, DNAMapsTemplate,UtilityFunctions,SimTaskDataGenerator,DefaultConfig,SiteBaseTemplate,SimDesignData,AccessPointTemplate,HostTemplate,RadiosData,ElementTypeTemplate){

    var self = this;

    function postDefaultFloorImage() {
        /*self.addEventListener('message', function(event){*/
        //var imageFile = 'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/image/floor/cubicle_floor_plan_thumb.png';
        var imageFile = 'core/../../../public/libs/node_modules/sinon/Heatmap.png';

        readImageFile(imageFile, function(base64data) {
            //console.log(base64data); //tBase64 is the base64 string
            navigator.serviceWorker.controller.postMessage({id: 'Heatmap', img: base64data});
        });
        /*});*/
         var imageFile = 'core/../../../public/libs/node_modules/sinon/download.png';

        readImageFile(imageFile, function(base64data) {
            //console.log(base64data); //tBase64 is the base64 string
              navigator.serviceWorker.controller.postMessage({id: '3ff42b4b-734f-4b07-85c9-3a229bc8b11e', img: base64data});
        });
    }

    function getGroupNameHierarchy(siteName, parentId){
        var globalParentId = DefaultConfig.DEFAULT_UUID.GlobalGroup;
        var groupNameHierarchy = 'Global';
        var serviceName = 'site';
        if(parentId == globalParentId){
            groupNameHierarchy = groupNameHierarchy + '/' + siteName;
        } else {
            var tObj = SimLokiDatabaseActions.getAll(serviceName);
            var siteMap = new Object();
            for(var i =0, len = tObj.length; i < len; i++){
                 siteMap[tObj[i].id] = { name: tObj[i].name, parentId: tObj[i].parentId};
            }

            var tParentId = parentId;
            var tGroup = [siteName];
            while(tParentId != globalParentId){
                if(siteMap[tParentId] != undefined){
                    tGroup.push(siteMap[tParentId].name);
                    tParentId = siteMap[tParentId].parentId;
                }
            }

            for(i = tGroup.length - 1; i >= 0; i--){
                groupNameHierarchy = groupNameHierarchy + '/' + tGroup[i];
            }
        }
        return groupNameHierarchy;
    }
    function getGroupHierarchy(siteId, parentId){
        var globalParentId = DefaultConfig.DEFAULT_UUID.GlobalGroup;
        var groupHierarchy = globalParentId;
        var serviceName = 'site';
        if(parentId == globalParentId){
            groupHierarchy = parentId + '/' + siteId;
        } else {
            var tObj = SimLokiDatabaseActions.getAll(serviceName);
            var siteMap = new Object();
            for(var i =0, len = tObj.length; i < len; i++){
                siteMap[tObj[i].id] = {id: tObj[i].id, parentId: tObj[i].parentId};   
            }
            var tParentId = parentId;
            var tGroup = [siteId];
            while(tParentId != globalParentId){
                if(siteMap[tParentId] != undefined){
                    tGroup.push(siteMap[tParentId].id);
                    tParentId = siteMap[tParentId].parentId;
                }
            }
            for(i = tGroup.length - 1; i >= 0; i--){
                groupHierarchy = groupHierarchy + '/' + tGroup[i];
            }
        }
        return groupHierarchy
    }

    function getFloorCount(parentId) {
        var record = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'parentId': parentId});
        return record.length;
    }

    function getSiteName(parentId) {
        var buildingRecord = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id': parentId});
        var siteRecord = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id': buildingRecord[0].parentId});
        if(siteRecord.length==0) {
         return "Global"
        }
        return siteRecord[0].name;
    }

    function createFilter(){
      var tJson = JSON.parse(JSON.stringify( DNAMapsTemplate.Display_Filter_template));
      SimLokiDatabaseActions.insert( 'display-filter', tJson);
      console.log(tJson);
    }

    function createPreferences() {
     var tJson = JSON.parse(JSON.stringify( DNAMapsTemplate.Preferences_Info_Template));
       SimLokiDatabaseActions.insert( 'preferences', tJson);
    }

    function readImageFile(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
            var reader = new FileReader();
            reader.onloadend = function() {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    return {
        init: function(){
            createFilter();
            createPreferences();

          //  postDefaultFloorImage();
        },

        getRfModel: function(){
            //var tJson = JSON.parse(JSON.stringify( DNAMapsTemplate.RF_Models_Template));
            var tJson = JSON.parse(JSON.stringify( DNAMapsTemplate.RF_Models_template_new));
            return tJson;
        },

        getPreference: function(){
            var tJson = SimLokiDatabaseActions.getAll('preferences');
            return tJson;
        },

        getDisplayFliter :function() {
            var tJson = SimLokiDatabaseActions.getAll('display-filter');
            return tJson;
        },

        createSite :function(restPayload) {
          /*
            step 1: creating DNA map entry for Building
            Step 2: Creating DNA map entry for floor
            Step 3: Creating site object for floor
            Step 4: Setting global property for that site.
          */
            var siteObj = restPayload;
            var record = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id': siteObj.parentGroupUuid});
            var floorCount =getFloorCount(siteObj.parentGroupUuid);
            var floorIndex =1;
            var siteName =getSiteName(siteObj.parentGroupUuid);

            if(floorCount>0) {
                floorIndex =floorIndex+1;
            } else if(floorCount==0) {
                floorCount=1;
            }

            var tJson = JSON.parse(JSON.stringify( DNAMapsTemplate.Floor_Template));
            tJson.id = 42046,
            tJson.instanceUuid= UtilityFunctions.generate_uuid();
            tJson.groupInstanceUuid= record[0].id,
            tJson.parentGroupUuid=record[0].parentId,
            tJson.isRootDomain=false,
            tJson.name=record[0].name ,
            tJson.type=4,
            tJson.contact=siteObj.name,
            tJson.siteName=siteName,
            tJson.buildingName=record[0].name,
            tJson.status= siteObj.status,
            tJson.incomplete= siteObj.incomplete,
            tJson.apCount=siteObj.apCount,
            tJson.dot11aRadioCount= siteObj.dot11aRadioCount,
            tJson.dot11bRadioCount= siteObj.dot11bRadioCount,
            tJson.dot11gRadioCount= siteObj.dot11gRadioCount,
            tJson.criticalRadioCount= siteObj.criticalRadioCount,
            tJson.wirelessClientsCount= siteObj.wirelessClientsCount,
            tJson.buildingCount= siteObj.buildingCount,
            tJson.floorCount= floorCount,
            tJson.outdoorAreaCount= siteObj.outdoorAreaCount,
            tJson.floorIndex= null,
            tJson.floorTotalCount= floorCount,
            tJson.basementTotalCount= siteObj.basementTotalCount,
            tJson.lastUpdated= UtilityFunctions.getTimeStamp(),
            tJson.location= siteObj.location,
            tJson.geometry= siteObj.geometry;
            if (siteObj.name == "Flr-SJC1-1") {
                tJson.geometry.height = 43.00 // 42
                tJson.geometry.width = 356 //Present 336.00
                tJson.geometry.length = 200 //present 190
            } else if (siteObj.name == 'Flr-SFO15-1') {
                tJson.geometry.height = 30.0
                tJson.geometry.width = 196.85 //196.85039,111.10944, Present (185)
                tJson.geometry.length = 121.39 //121.391075 77.77661,  present (120)
                        }            
            tJson.metrics= siteObj.metrics,
            tJson.maintainAspectRatio= siteObj.maintainAspectRatio,
            //tJson.rfModel=siteObj.rfModel,
            tJson.rfModel= "Outdoor Open Space",
            tJson.imageInfo=siteObj.imageInfo || {image: "/simulation/image/3ff42b4b-734f-4b07-85c9-3a229bc8b11e", isCadFile: false, enteredImageName: "", "extract": "",
            "generatedRasterImage": null,} //changed by Ram
            SimLokiDatabaseActions.insert('floor-map', tJson);

            var groupId =UtilityFunctions.generate_uuid();
            var serviceId =UtilityFunctions.generate_uuid();

            var resultObj = SimTaskDataGenerator.createTask('DNA Maps Service',{ groupId: groupId,serviceId: serviceId});
            var tJson = JSON.parse(JSON.stringify( DNAMapsTemplate.Floor_Template));
            tJson.id = 42044,
            tJson.instanceUuid= serviceId,
            tJson.groupInstanceUuid= groupId,
            tJson.parentGroupUuid=siteObj.parentGroupUuid,
            tJson.isRootDomain=false,
            tJson.name=siteObj.name ,
            tJson.type= 4,
            tJson.contact=siteObj.name,
            tJson.siteName=siteName,
            tJson.buildingName=record[0].name,
            tJson.status= siteObj.status,
            tJson.incomplete= siteObj.incomplete,
            tJson.apCount=siteObj.apCount,
            tJson.dot11aRadioCount= siteObj.dot11aRadioCount,
            tJson.dot11bRadioCount= siteObj.dot11bRadioCount,
            tJson.dot11gRadioCount= siteObj.dot11gRadioCount,
            tJson.criticalRadioCount= siteObj.criticalRadioCount,
            tJson.wirelessClientsCount= siteObj.wirelessClientsCount,
            tJson.buildingCount= siteObj.buildingCount,
            tJson.floorCount= siteObj.floorCount,
            tJson.outdoorAreaCount= siteObj.outdoorAreaCount,
            tJson.floorIndex= floorCount,
            tJson.floorTotalCount= siteObj.floorTotalCount,
            tJson.basementTotalCount= siteObj.basementTotalCount,
            tJson.lastUpdated= UtilityFunctions.getTimeStamp(),
            tJson.location= siteObj.location,
            tJson.geometry= siteObj.geometry,
            tJson.metrics= siteObj.metrics,
            tJson.maintainAspectRatio= siteObj.maintainAspectRatio,
            //tJson.rfModel=siteObj.rfModel,
            tJson.rfModel= "Outdoor Open Space"
            tJson.imageInfo=siteObj.imageInfo || {image: "/simulation/image/3ff42b4b-734f-4b07-85c9-3a229bc8b11e", isCadFile: false, enteredImageName: "", "extract": "",
            "generatedRasterImage": null,};
            SimLokiDatabaseActions.insert('floor-map', tJson);
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });

            var tSite = JSON.parse(JSON.stringify( SiteBaseTemplate.SITE_FLOOR_BASIC_DATA ));

            tSite.id =groupId;
            tSite.parentId = siteObj.parentGroupUuid;
            tSite.name = siteObj.name;
            
            tSite.groupNameHierarchy =getGroupNameHierarchy(siteObj.name,siteObj.parentGroupUuid);
            // tSite.groupHierarchy =siteObj.parentGroupUuid+'/'+groupId;
            tSite.groupHierarchy = getGroupHierarchy(tSite.id, siteObj.parentGroupUuid)
            var additionalInfo = tSite.additionalInfo;

            for (var i=0;i<additionalInfo.length ;i++) {
                if(additionalInfo[i].nameSpace== "Location") {
                    for(j=0;j<record[0].additionalInfo.length ;j++)  {
                        // reading the address details from parent building object
                        if(record[0].additionalInfo[j].nameSpace== "Location") {
                            additionalInfo[i].attributes.address=record[0].additionalInfo[j].attributes.address;
                            additionalInfo[i].attributes.addressInheritedFrom=siteObj.parentGroupUuid;
                        }
                    }
                } else if(additionalInfo[i].nameSpace== "mapsSummary") {
                    additionalInfo[i].attributes.rfModel=JSON.stringify(siteObj.rfModel);
                    additionalInfo[i].attributes.floorIndex="1";
                } else if(additionalInfo[i].nameSpace== "mapGeometry") {
                    additionalInfo[i].attributes =siteObj.geometry;
                } else if(additionalInfo[i].nameSpace=="com.wireless.managingwlc"){
                    additionalInfo[i].attributes.secondaryWlcInheritedFrom = tSite.id;
                    additionalInfo[i].attributes.anchorWlcInheritedFrom = tSite.id;
                    additionalInfo[i].attributes.tertiaryWlcInheritedFrom = tSite.id;
                    additionalInfo[i].attributes.primaryWlcInheritedFrom = tSite.id;
                }
            }

            SimLokiDatabaseActions.insert('site', tSite);
            SimDesignData.createServers(groupId);
            return {"response":resultObj,"version":"1.0"};
        },

        updateImage :function (urlAction) {
            var siteId = urlAction.action.id;
            var record = SimLokiDatabaseActions.getFilteredRecordHandler('floor-map', {'groupInstanceUuid': siteId});
            var oldImagePath  = record[0].imageInfo.image;
            var oldImageId;
            var imageId;
            var thumbnailId;
            if(oldImagePath.lastIndexOf("/")>-1) {
              oldImageId = oldImagePath.substr(oldImagePath.lastIndexOf("/")+1);
            }

            // This code is commented due to image is not refelecting due to same id

          /*  if(oldImageId==undefined) {
                imageId =UtilityFunctions.generate_uuid();
                thumbnailId = UtilityFunctions.generate_uuid();
            } else {

               imageId =oldImageId;
               thumbnailId = oldImageId;
            } */

            imageId =UtilityFunctions.generate_uuid();
            thumbnailId = UtilityFunctions.generate_uuid();
            var payload = urlAction.restPayload;
            var imagePath ="/simulation/image/"+imageId;
            var thumbnailPath ="/simulation/image/"+thumbnailId;


            var data =payload.get("image");

            record[0].imageInfo.image= imagePath;
            record[0].imageInfo.thumbnail= thumbnailPath;
            record[0].imageInfo.enteredImageName =data.name;
            record[0].extract= "";
            record[0].generatedRasterImage= null;
            SimLokiDatabaseActions.update('floor-map', record);


           // SimLokiDatabaseActions.insert('image', {id :imageId,image :data});
           // SimLokiDatabaseActions.insert('image', {id :thumbnailId,image :data});

            var reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onloadend = function() {
                var base64data = reader.result;
                console.log(base64data);

           //     navigator.serviceWorker.controller.postMessage({id: imageId, img: base64data});

                //changed by Ram for floor map image issue: START
                $.ajax({
                  type: "POST",
                  url: '/simulation/floor-map',
                  data: JSON.stringify({id: imageId, img: base64data}),
                  dataType: 'json',
                  contentType: "application/json",
                  success: function(){console.log('Hit sim api')}
                });
                //changed by Ram for floor map image issue: END
            }

            return  record;
      },

      updatePreferences :function (urlAction) {

        var payload = urlAction.restPayload;
          var filterName =payload.filterName;
          var flag=false;
          var tJson = SimLokiDatabaseActions.getAll('preferences');
           for(var i=0;i<tJson.length;i++){
              if(tJson[i].filterName==filterName) {
                tJson[i].value= payload.value;

                SimLokiDatabaseActions.update('preferences', tJson[i]);
              }
           }


         return {"response":tJson,"version":"1.0"};;
      },

      updateFilter: function(urlAction) {
          var payload = urlAction.restPayload;
          var filterName =payload.filterName;
          var flag=false;
          var tJson = SimLokiDatabaseActions.getAll('display-filter');
           for(var i=0;i<tJson.length;i++){
              if(tJson[i].filterName==filterName) {
                tJson[i].value= payload.value;
                flag=true;
                SimLokiDatabaseActions.update('display-filter', tJson[i]);
              }
           }
           if(!flag) {
             SimLokiDatabaseActions.insert('display-filter', payload);
           }
      var data = SimLokiDatabaseActions.getAll('display-filter');
         return {"response":data,"version":"1.0"};;

      },

      syncCMXdata: function(urlAction){
        var groupId = urlAction.action.id;
        var resultObj = SimTaskDataGenerator.createTask('DNA Maps Service',{ groupId: groupId});
        SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
        return {"response":resultObj,"version":"1.0"};
      },

      exportArchive: function(urlAction){
        var groupId = urlAction.action.id;
        var resultObj = SimTaskDataGenerator.createTask('DNA Maps Service',{ groupId: groupId});
        SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
        return {"response":resultObj,"version":"1.0"};
      },
      

      updateFloor :function(urlAction) {

        var siteObj = urlAction.restPayload;
        var groupId = urlAction.action.id;
        var parentGroupId=siteObj.parentGroupUuid;
        var resultObj = SimTaskDataGenerator.createTask('DNA Maps Service',{ groupId: groupId});
        // updating the floor entry
        var record = SimLokiDatabaseActions.getFilteredRecordHandler('floor-map', {'groupInstanceUuid' : groupId });

        record[0].geometry= siteObj.geometry;
        record[0].name=siteObj.name;
        record[0].rfModel= siteObj.rfModel;
        SimLokiDatabaseActions.update('floor-map', record);
        //updating the building entry
        var parentRecord = SimLokiDatabaseActions.getFilteredRecordHandler('floor-map', {'groupInstanceUuid' : parentGroupId });

        parentRecord[0].geometry= siteObj.geometry;

        SimLokiDatabaseActions.update('floor-map', parentRecord);
        // updating the floor group object.
        var siteRecord = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id' : groupId });

            siteRecord[0].name = siteObj.name;
            siteRecord[0].groupNameHierarchy =getGroupNameHierarchy(siteObj.name,siteObj.parentGroupUuid);


            for (var i=0;i<siteRecord[0].additionalInfo.length ;i++) {
                if(siteRecord[0].additionalInfo[i].nameSpace== "mapGeometry") {
                    siteRecord[0].additionalInfo[i].attributes =siteObj.geometry;
                }
            }

        SimLokiDatabaseActions.update('site', siteRecord);
        SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
       return {"response":resultObj,"version":"1.0"};
      },

      getAccessPoints:function(urlAction) {

          var siteId =urlAction.action.id;
          var siteObj = SimLokiDatabaseActions.getFilteredRecordHandler('site', {"id": siteId});
          var list = SimLokiDatabaseActions.getAll('access-point');

          var accessPointList=[];

          for(var i=0;i<list.length;i++) {
            if(urlAction.action.id == "f4fa7637-85d5-4301-9336-5c3d81c7c058" ||
               urlAction.action.id == "76599253-72e4-448d-b5ee-7ed967d726e7" ||
               urlAction.action.id =="33bbd24d-7df6-4dd6-bd4e-ce8397345889" ||
               urlAction.action.id == "2572a60e-c5d0-4e9a-a332-28881db6bdc8"
            ) {
                accessPointList.push(list[i]);
            } else if (urlAction.action.id =="3ff42b4b-734f-4b07-85c9-3a229bc8b11e" && list[i].attributes.floorName == "Flr-SJC1-1") {
                accessPointList.push(list[i]);
            } else if (list[i].attributes.floorName == "SJC01") {
                accessPointList.push(list[i]);
            }
            else if(list[i].attributes.locationGroup==siteId) {
               accessPointList.push(list[i]);
            }
          }          

          //var realisticPositions = [{ "x": 69.11717908529049, "y": 51.37831499999999, "z": 4 }, { "x": 30.8127214882571, "y": 79.6344936341162, "z": 4 }, { "x": 102.02241479604447, "y": 22.3476191829419, "z": 4 }, { "x": 267.73361147589617, "y": 83.71360364647715, "z": 4 }, { "x": 229.5302843016069, "y": 50.19530284301609, "z": 15 }, { "x": 184.7051813152039, "y": 81.8594627317676, "z": 4 }, { "x": 30.024721878862785, "y": 23.49567367119901, "z": 15 }, { "x": 147.32570047466007, "y": 49.59741081582202, "z": 4 }, { "x": 103.1600638862793, "y": 79.63449363411623, "z": 4 }, { "x": 185.02719406674905, "y": 23.0892755488257, "z": 4 }, { "x": 265.08899876390603, "y": 23.089275548825718, "z": 4 }]
          var realisticPositions = [
            { "x": 33.21, "y": 23.72, "z": 32 },
            { "x": 52.75, "y": 187.03, "z": 43 },
            { "x": 135.61, "y": 187.03, "z": 10 },
            { "x": 153.25, "y": 24.53, "z": 40 }, 
            { "x": 194.16, "y": 25.18, "z": 40 }, 
            { "x": 234.48, "y": 26.29, "z": 40 }, 
            { "x": 219.29, "y": 187.3, "z": 10 }, 
            { "x": 314.85, "y": 26.84, "z": 40 }, 
            { "x": 52.75, "y": 152.78, "z": 40 }, 
            { "x": 94.18, "y": 153.61, "z": 40 }, 
            { "x": 133.4, "y": 153.61, "z": 40 }, 
            { "x": 174, "y": 154.16, "z": 40 }, 
            { "x": 215.08, "y": 154, "z": 40 }, 
            { "x": 255.19, "y": 153.88, "z": 40 }, 
            { "x": 294.69, "y": 154.99, "z": 40 }, 
            { "x": 114.34, "y": 24.35, "z": 40 }, 
            { "x": 274.8, "y": 26.84, "z": 40 },
            { "x": 302.42, "y": 187.03, "z": 10 }, 
            { "x": 336.2, "y": 154.47, "z": 40 }, 
            { "x": 14.23, "y": 153.65, "z": 40 }, 
            { "x": 74, "y": 24.01, "z": 40 },                   
            { "x": 135.61, "y": 15.83, "z": 20 }]

          if (siteObj.length > 0 && siteObj[0].name == 'Flr-SJC1-1') {
              realisticPositions = [
                  { "x": 33.21, "y": 23.72, "z": 43 },
                  { "x": 52.75, "y": 187.03, "z": 43 },
                  { "x": 135.61, "y": 187.03, "z": 43 },
                  { "x": 153.25, "y": 24.53, "z": 43 }, 
                  { "x": 194.16, "y": 25.18, "z": 43 }, 
                  { "x": 234.48, "y": 26.29, "z": 43 }, 
                  { "x": 219.29, "y": 187.3, "z": 43 }, 
                  { "x": 314.85, "y": 26.84, "z": 43 }, 
                  { "x": 52.75, "y": 152.78, "z": 43 }, 
                  { "x": 94.18, "y": 153.61, "z": 43 }, 
                  { "x": 133.4, "y": 153.61, "z": 43 }, 
                  { "x": 174, "y": 154.16, "z": 43 }, 
                  { "x": 215.08, "y": 154, "z": 43 }, 
                  { "x": 255.19, "y": 153.88, "z": 43 }, 
                  { "x": 294.69, "y": 154.99, "z": 43 }, 
                  { "x": 114.34, "y": 24.35, "z": 43 }, 
                  { "x": 274.8, "y": 26.84, "z": 43 },
                  { "x": 302.42, "y": 187.03, "z": 43 }, 
                  { "x": 336.2, "y": 154.47, "z": 43 }, 
                  { "x": 14.23, "y": 153.65, "z": 43 }, 
                  { "x": 74, "y": 24.01, "z": 43 },                   
                  { "x": 135.61, "y": 15.83, "z": 43 }]
          } else if (siteObj.length > 0 && siteObj[0].name == 'Flr-SFO15-1') {
              realisticPositions = [{ "x": 77.307274, "y": 48.849304, "z":  29 }, 
              { "x": 150, "y": 48.96, "z": 29 }, 
              { "x": 98.55, "y": 70.74, "z": 29 }, 
              { "x": 77.83093, "y": 74.504265, "z": 29 }, 
              { "x": 72.20247, "y": 105.02785, "z": 29 }, 
              { "x": 99.47887, "y": 105.677284, "z": 29 }, 
              { "x": 140.82642, "y": 99.18291, "z": 29 }, 
              { "x": 141.0429, "y": 75.1537, "z": 29 }, 
              { "x": 185.1, "y": 97.23459, "z": 29 }, 
              { "x": 184.1, "y": 72.77242, "z": 29 }, 
              { "x": 128.05414, "y": 50.258575, "z": 29 }]
          }

          accessPointList.forEach((ap,i)=>{
            ap.attributes.baseRadioMacaddress=ap.attributes.macaddress
            ap.position = realisticPositions[i]?realisticPositions[i]:ap.position
            ap.metrics.healthScore = UtilityFunctions.getRandomIntForRangeValues(6,10);
            if(siteObj.length > 0 && siteObj[0].name == 'Flr-SFO15-1') {
                ap.radios = []
                ap.radios.push(RadiosData.SFO15_radios_data[i][0]);
                ap.radios.push(RadiosData.SFO15_radios_data[i][1]);
            } else if (siteObj.length > 0 && siteObj[0].name == 'Flr-SJC1-1') {
                ap.radios = []
                ap.radios.push(RadiosData.SCJ01_radios_data[i][0]);
                ap.radios.push(RadiosData.SCJ01_radios_data[i][1]);
                ap.radios.push(RadiosData.SCJ01_radios_data[i][2]);
            }            
        })

        //workaround for apphosting workflow; fix later if necessary
        if(window.location.href.includes('workflows')) {                  
            let tableApNamesId = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'$and': 
                        [ {'family':'Unified AP'}, {'siteId':{'$in':window.tempSites}} ]})
                            .filter(e => e.platformId.includes("91") && !e.platformId.includes("9105AXI") && !e.platformId.includes("9124AXI"))
                            .reduce((acc, curr)=>{return {...acc, ...{[curr.hostname]:curr.instanceUuid}}},{})                                        
            accessPointList = accessPointList.filter(e=>e.attributes.name in tableApNamesId)
            for(let ap of accessPointList)
                ap.attributes.instanceUuid = tableApNamesId[ap.attributes.name]            
        }
        
        var result = {"items":accessPointList, "total": accessPointList.length};

       return result;
      },

       getSensor:function(urlAction) {

          var siteId =urlAction.action.id;

          var list = SimLokiDatabaseActions.getAll('sim-sensor');

          var sensorList=[];

          for(var i=0;i<list.length;i++) {
            if(urlAction.action.id == "f4fa7637-85d5-4301-9336-5c3d81c7c058" ||
               urlAction.action.id == "76599253-72e4-448d-b5ee-7ed967d726e7"||
               urlAction.action.id =="33bbd24d-7df6-4dd6-bd4e-ce8397345889"
            ) {
                sensorList.push(list[i]);
            }
            else if(list[i].attributes.locationGroup==siteId) {
               sensorList.push(list[i]);
            }
          }
          var result = {"items":sensorList, "total": sensorList.length};

       return result;
      },

        getSwitches : function(domainId) {
            let result = {"items":[], "total":0}, apIds=[];
            let switches = SimLokiDatabaseActions.getFilteredRecordHandler("network-device", {"$and": 
                [{"family":"Switches and Hubs"}, {"siteId":domainId}] });
            switches.forEach(swDevice => {
                let itemObj = {
                    "type":swDevice.type,
                    "series":swDevice.series,
                    "name":swDevice.name,
                    "ipAddress":swDevice.managementIpAddress,
                    "softwareVersion":swDevice.softwareVersion,
                    "platformId":swDevice.platformId,
                    "role":swDevice.role,
                    "roleSourceSwitch":"AUTO",
                    "macaddress":swDevice.macAddress,
                    "reachability":swDevice.reachabilityStatus,
                    "lastSyncStatus":swDevice.collectionStatus,
                    "devices":{},
                    "instanceUuid":swDevice.instanceUuid
                };
                let topolinks = SimLokiDatabaseActions.getFilteredRecordHandler('physical-topology', {'$and': 
                    [{'topology_type':'link'}, {'source':swDevice.id}] });
                if(topolinks.length > 0) {
                    apIds = topolinks.map(e => e.target);
                    let aps = SimLokiDatabaseActions.getFilteredRecordHandler("network-device", {"id":{'$in':apIds}} );
                    aps = aps.filter(e => ["Unified AP", "Wireless Sensor"].indexOf(e.family) > -1 );
                    aps = aps.map(e => {return {"name":e.name, "macAddr":e.macAddress, "portId":"GigabitEthernet1/0/3"} });
                    itemObj.devices.aps = aps;
                    itemObj.devices.total = aps.length;
                } else {
                    itemObj.devices.aps = [];
                }
                itemObj.devices.total = itemObj.devices.aps.length;
                result.items.push(itemObj);
            });
            result.total = switches.length;
            return result;
        },

      updateAccessPoints :function(urlAction) {
           var siteId =urlAction.action.id;
           var accessPointArray = urlAction.restPayload;
           var list = SimLokiDatabaseActions.getAll('access-point');

            for(var i=0;i<accessPointArray.length;i++){

              var instanceUuid =accessPointArray[i].attributes.instanceUuid;
              var position = accessPointArray[i].position;

              for(var j=0;j<list.length;j++) {

                if(list[j].attributes.instanceUuid==instanceUuid) {
                  list[j].position =position;
                   SimLokiDatabaseActions.update('access-point', list[j]);
                }
              }
            }

            return this.getAccessPoints(urlAction);
      },

      deleteAccessPoints: function(urlAction) {

          var siteId =urlAction.action.id;
          var resultObj = SimTaskDataGenerator.createTask('DNA Maps Service',{ groupId: siteId});
          var accessPointArray = urlAction.restPayload;
          var list = SimLokiDatabaseActions.getAll('access-point');

            for(var i=0;i<accessPointArray.length;i++){

              for(var j=0;j<list.length;j++) {

                if(list[j].attributes.instanceUuid==accessPointArray[i]) {

                    var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'name': list[j].attributes.name });
                        if (recordObj && recordObj.length) {
                            recordObj[0].siteId = "";
                            SimLokiDatabaseActions.update('network-device', recordObj);
                        }
                   SimLokiDatabaseActions.delete('access-point', list[j]);
                }
              }
            }
          SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
       return {"response":resultObj,"version":"1.0"};
      },

      addGeometryElements : function(urlAction) {

          var siteId =urlAction.action.id;
          var payLoad = urlAction.restPayload;
          var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('geometry-elements', {'id':  siteId});

          if (recordObj && recordObj.length) {
               recordObj[0].items =payLoad;
               SimLokiDatabaseActions.update('geometry-elements', recordObj);

           } else {
               var data ={"id":siteId,items:payLoad};
               SimLokiDatabaseActions.insert('geometry-elements', data);
           }
        //return SimLokiDatabaseActions.getFilteredRecordHandler('geometry-elements', {'id':  siteId});
        return {statusCode:201};
      },

      getAntennas : function(urlAction) {

          if(urlAction.filter.indexOf('slot')>=0) {
              var list = SimLokiDatabaseActions.getAll('access-point');
              var radioList;
              var resultRadioList=[];

              for(var i=0;i<list.length;i++) {

                if(list[i].attributes.baseRadioMacaddress==urlAction.filter["macaddress"]) {
                   radioList = list[i].radios;
                    break;
                 }
              }

              for(var j=0;j<radioList.length;j++) {

                if(radioList[j].attributes.slotId==urlAction.filter["slot"]) {
                  resultRadioList.push(radioList[j].antenna.name);
                 }
              }

          return resultRadioList;
         } else {
             var tJson = JSON.parse(JSON.stringify( AccessPointTemplate.Access_Point_Template));
              for(var i=0;i<tJson.radios.length;i++) {
                    if(urlAction.service.indexOf(tJson.radios[i].antenna.name)>=0) {
                      return tJson.radios[i].antenna;
                    }
              }
              if (urlAction.filter.indexOf("apmodel") > -1 && urlAction.filter.indexOf("band")){
                var apTypes = ElementTypeTemplate.ApTypeTemplate.items;
                var apModel = apTypes.filter(e=>e.ordinal == urlAction.filter.apmodel)
                if(apModel[0] && apModel[0].name.indexOf('9120') > -1) {
                    return ["Internal-9120-Dual-5GHz"]    
                } else if (apModel[0] && apModel[0].name.indexOf('9136') > -1) {
                    return ["Internal-9136-2.4GHz"]    
                } else
                return ["Internal-9120-Dual-2.4GHz"]
              }                
              else if (urlAction.url.indexOf("2.4GHz") > -1)
                  return { "name": "Internal-9120-2.4GHz", "description": "Integrated (9120i) omni antenna", "type": "internal", "mode": null, "diversity": null, "azimuthAngle": null, "elevationAngle": null, "gain": 5.0, "image": "ap9120i.png" }
              else if (urlAction.url.indexOf("5GHz") > -1)
                  return { "name": "Internal-9120-5GHz", "description": "Integrated (9120i) omni antenna", "type": "internal", "mode": null, "diversity": null, "azimuthAngle": null, "elevationAngle": null, "gain": 5.0, "image": "ap9120i.png" }
         }

      },

      getHeatMapData :function(urlAction) {

          var tJson = JSON.parse(JSON.stringify(AccessPointTemplate.Heat_Map_Template));

          return tJson;
      },
      deleteFloor :function(urlAction) {
        var groupId = urlAction.action.id;

        var resultObj = SimTaskDataGenerator.createTask('DNA Maps Service',{ groupId: groupId});
        // Deleting the floor entry
        var record = SimLokiDatabaseActions.getFilteredRecordHandler('floor-map', {'groupInstanceUuid' : groupId });
        var parentGroupId=record[0].parentGroupUuid;


        //Delete the building entry
        var parentRecord = SimLokiDatabaseActions.getFilteredRecordHandler('floor-map', {'groupInstanceUuid' : parentGroupId });

        SimLokiDatabaseActions.delete('floor-map', record);
        SimLokiDatabaseActions.delete('floor-map', parentRecord);
        // deleting the floor group object.
        var siteRecord = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id' : groupId });

        SimLokiDatabaseActions.delete('site', siteRecord);

        SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
       return {"response":resultObj,"version":"1.0"};
      },

      getHostDetails :function(urlAction) {

       var clientList =[];

       var data = SimLokiDatabaseActions.getFilteredRecordHandler('host', {'hostType' : "WIRELESS" });

       for(var i=0;i<data.length;i++){
            var connectedDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id' : data[i].connectedNetworkDeviceId });
            var accessPoint= SimLokiDatabaseActions.getFilteredRecordHandler('access-point', {'attributes.name' : connectedDevice[0].name });
            var tJson = JSON.parse(JSON.stringify( HostTemplate.Host_Template));
           if(urlAction.action.id == "f4fa7637-85d5-4301-9336-5c3d81c7c058" ||
               urlAction.action.id == "76599253-72e4-448d-b5ee-7ed967d726e7" ||
               urlAction.action.id =="33bbd24d-7df6-4dd6-bd4e-ce8397345889" || urlAction.action.id ==connectedDevice[0].siteId
            ) {
            tJson.hostMac=data[i].id;
            tJson.userId =data[i].userId;
            tJson.ssid =data[i].ssid;
            tJson.healthScore =data[i].healthScore[0].score;
            tJson.apGroup =data[i].apGroup;
            tJson.frequency =data[i].frequency;
            tJson.snr =data[i].snr;
            tJson.rssi =data[i].rssi;
            tJson.band =data[i].band;
            tJson.dataRate =data[i].dataRate;
            var hostIssues =  SimLokiDatabaseActions.getFilteredRecordHandler('issue', { 'entity': data[i].id});

            tJson.issueCount =hostIssues.length;

            tJson.apName =connectedDevice[0].name;
            tJson.position.x=accessPoint[0].position.x+UtilityFunctions.getRandomIntForRangeValues(1, 15);;
            tJson.position.y=accessPoint[0].position.y-UtilityFunctions.getRandomIntForRangeValues(5, 15);
            tJson.position.z=8;

            clientList.push(tJson);
            }
       }
        return clientList;
      }

  };
});
