define({
    "NetworkDevice_Detail_Template": {
        "managementIpAddr": "10.13.4.7",
        "HAPrimaryPowerStatus": "Present, OK",
        "redundancyMode": "Disabled",
        "communicationState": "REACHABLE",
        "nwDeviceRole": "ACCESS",
        "osType": "Cisco Controller",
        "clientCount": "15",
        "nwDeviceType": "Cisco 5520 Series Wireless Controllers",
        "siteType": "floor",
        "HALastResetReason": "Planned Reset",
        "serialNumber": "FCH2034V4F2",
        "nwDeviceName": "CT5520-MK",
        "platformId": "AIR-CT5520-K9",
        "redundancyUnit": "Primary",
        "redundancyPeerState": "N/A",
        "nwDeviceId": "c2853ef4-4068-46d6-a914-01777aede8d8",
        "redundancyState": "ACTIVE",
        "nwDeviceFamily": "Wireless Controller",
        "macAddress": "00:A2:EE:9C:47:93",
        "collectionStatus": "SUCCESS",
        "deviceSeries": "Cisco 5500 Series Wireless LAN Controllers",
        "siteHierarchy": "Global/SJ/SJC23/Floor1",
        "name": "Floor1",
        "ids": [
          "c2853ef4-4068-46d6-a914-01777aede8d8",
          "33bbd24d-7df6-4dd6-bd4e-ce8397345889"
        ],
        "HASecondaryPowerStatus": "Present, OK",
        "softwareVersion": "8.8.104.79"
      },

      NetworkDevice_Detail_Template_1_2_6: {
        "id": "53d2f176-34ab-43da-acc9-749f6e745cab",
        "label": "NetworkDevice",
        "timestamp": "2018-12-08T08:14:11.000+0000",
        "isDeleted": false,
        "type": "vertex",
        "properties": {
          "HALastResetReason": [
            {
              "value": "Planned Reset"
            }
          ],
          "managementIpAddr": [
            {
              "value": "172.20.228.35"
            }
          ],
          "serialNumber": [
            {
              "value": "FCH2121V2KR"
            }
          ],
          "HAPrimaryPowerStatus": [
            {
              "value": "Present, OK"
            }
          ],
          "redundancyMode": [
            {
              "value": "Disabled"
            }
          ],
          "communicationState": [
            {
              "value": "REACHABLE"
            }
          ],
          "nwDeviceName": [
            {
              "value": "WLC"
            }
          ],
          "platformId": [
            {
              "value": "AIR-CT5520-K9"
            }
          ],
          "redundancyUnit": [
            {
              "value": "Primary"
            }
          ],
          "redundancyPeerState": [
            {
              "value": "N/A"
            }
          ],
          "nwDeviceId": [
            {
              "value": "53d2f176-34ab-43da-acc9-749f6e745cab"
            }
          ],
          "redundancyState": [
            {
              "value": "ACTIVE"
            }
          ],
          "nwDeviceRole": [
            {
              "value": "ACCESS"
            }
          ],
          "nwDeviceFamily": [
            {
              "value": "Wireless Controller"
            }
          ],
          "macAddress": [
            {
              "value": "40:CE:24:81:DB:BD"
            }
          ],
          "collectionStatus": [
            {
              "value": "SUCCESS"
            }
          ],
          "deviceSeries": [
            {
              "value": "Cisco 5500 Series Wireless LAN Controllers"
            }
          ],
          "osType": [
            {
              "value": "Cisco Controller"
            }
          ],
          "clientCount": [
            {
              "value": "7"
            }
          ],
          "HASecondaryPowerStatus": [
            {
              "value": "Absent/Failed"
            }
          ],
          "softwareVersion": [
            {
              "value": "8.8.104.83"
            }
          ],
          "nwDeviceType": [
            {
              "value": "Cisco 5520 Series Wireless Controllers"
            }
          ]
        }
      }
});