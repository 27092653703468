define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/SimulationUtility',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/inventory/NetworkDeviceTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/inventory/DeviceCredentialTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimNetworkDeviceData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimDefaultDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimTopologyData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimInterfaceData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/swim/SimSwimData'
], function(SimLokiDatabaseActions, UtilityFunctions, SimulationUtility, NetworkDeviceTemplate,
            DeviceCredentialTemplate, SimNetworkDeviceData, SimDefaultDataGenerator, SimTopologyData, SimInterfaceData, SimSwimData){

      return {

          init: function(){
          },

          createNetworkDevice :function (jsonObj,validateOnly){
              var returnObj = createNetworkDevice(jsonObj,validateOnly);
              if(returnObj == undefined) {
                  createDeviceCrential(jsonObj,validateOnly);
              }
              return returnObj;
          },

          syncNetworkDevice :function (jsonObj) {
              syncNetworkDevice(jsonObj);
          },

          updateCollectionInterval(jsonObj){
              updateCollectionInterval(jsonObj);
          },

          updateDeviceCrential(jsonObj){
              updateDeviceCrential(jsonObj);
          }
    };

    function updateCollectionInterval(jsonObj){
        var ids = jsonObj.ids;
        for (var i = 0; i < ids.length; i++) {
            var deviceObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'id' : ids[i]});
            var collectionInterval = jsonObj.collectionInterval;
            var returnTime = "NA";
            collectionInterval = Number(collectionInterval);
            if (collectionInterval == -1) {
                returnTime = "Global Default"
            } else if (collectionInterval == 0) {
                returnTime = "00:00:00";
            } else {
                var h = Math.floor(collectionInterval / 3600);
                var m = Math.floor(collectionInterval % 3600 / 60);
                var s = Math.floor(collectionInterval % 3600 % 60);
                h = ("0" + h).slice(-2);
                m = ("0" + m).slice(-2);
                s = ("0" + s).slice(-2);
                returnTime = h + ":" + m + ":" + s;
            }
            deviceObj[0].collectionInterval = returnTime;
            SimLokiDatabaseActions.update('network-device', deviceObj[0]);
        }
    }

    function deleteNetworkDevice(jsonObj){
        var deviceObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'id' : jsonObj });
        if(deviceObj && deviceObj.length)  {
            SimLokiDatabaseActions.delete('DeviceInfo', deviceObj);
        }
    }

    function syncNetworkDevice(jsonObj) {
        var ids = jsonObj;
        for (var i = 0; i < ids.length; i++) {
            var obj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': ids[i]});
            var randomId = (Math.floor(Math.random() * 1000));
            //obj[0].hostname = "DEVICE" + randomId;
            /*obj[0].serialNumber = "KQ2GHP8";
            obj[0].platformId = "AIR-CT8510-K9";
            obj[0].family = "Wireless Controller";
            obj[0].softwareVersion = "8.3.112.0";*/
            /*obj[0].serialNumber = obj[0].serialNumber;
            obj[0].platformId = obj[0].platformId;
            obj[0].family = obj[0].family;
            obj[0].softwareVersion = obj[0].softwareVersion;*/
            obj[0].lastUpdated= UtilityFunctions.getGivenTimeInGivenFormat();
            obj[0].lastUpdateTime = UtilityFunctions.getTimeStamp();
            SimLokiDatabaseActions.update('network-device', obj[0]);
        }
    }

    function createNetworkDevice(jsonObj,validateOnly) {
        // var t_Json = JSON.parse(JSON.stringify(NetworkDeviceTemplate.Network_Device_Template));
        jsonObj.id = UtilityFunctions.generate_uuid();
        jsonObj.instanceUuid = jsonObj.id;
        var index = 0;
        if(jsonObj.ipAddress != undefined && jsonObj.ipAddress.length > 0) {
            jsonObj.ipAddress = jsonObj.ipAddress[0];
        }
        var addressParts = jsonObj.ipAddress.split('.')
        if(addressParts.length == 4) {
            //Check if the IP address is valid
            var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'managementIpAddress': jsonObj.ipAddress });
            if(recordObj != undefined && recordObj.length > 0) {
                //Check if the device with the ip is already existing
                return {errorCode:"ResourceConflict",failureReason:"Device already exists in inventory with ip-address "+jsonObj.ipAddress,isError:true,progress:"Inventory service adding device has failed."}
            }
            else {
                //Check if the formed deviceName is already existing..
                //jsonObj.role = "CORE";
                /*var roleArr = ["ACCESS", "BORDER ROUTER", "CORE", "DISTRIBUTION"];
                var roleIdx = UtilityFunctions.getRandomIntForRangeValues(1, roleArr.length) - 1;
                jsonObj.role = roleArr[roleIdx];
                var roleObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'role':roleArr[roleIdx]});
                jsonObj.family = roleObj[0].family;
                jsonObj.type   = roleObj[0].type;
                jsonObj.platformId = roleObj[0].platformId; 
                jsonObj.softwareVersion = roleObj[0].softwareVersion;

                var deviceDetails =SimDefaultDataGenerator.inventoryDevice(jsonObj,jsonObj.ipAddress,jsonObj.id,"");*/
                //var deviceDetails = SimulationUtility.createNetworkDevice(jsonObj, '');
                var tJsonObj = SimulationUtility.createNetworkDevice(jsonObj, '');
                var deviceDetails =SimDefaultDataGenerator.inventoryDevice(tJsonObj,tJsonObj.ipAddress,tJsonObj.id,""); 

                if(deviceDetails != undefined && deviceDetails.length > 0 && !validateOnly) {
                    /*var count = SimLokiDatabaseActions.getCount('network-device');
                    var randnum = UtilityFunctions.getRandomIntForRangeValues(1, count + 1);
                    var randstr = deviceDetails[0].serialnumber.substr(deviceDetails[0].serialnumber.length - 3, 3);
                    var hostname = 'Inventory' + randstr + '.' + randnum.toString() + '.cisco.com';
                    deviceDetails[0].hostname = hostname;*/
                    deviceDetails[0].hostname = SimulationUtility.createRandomHostName(deviceDetails[0].serialnumber, 'Inventory');  

                    SimNetworkDeviceData.addDevice(deviceDetails);
                    addMoreDeviceDetails(jsonObj.id)
                    //Passing empty string as seed device, so that the device added is not linked to any other device.
                    SimTopologyData.addDevice("",deviceDetails);
                    var deviceRecord = { type: deviceDetails[0].type, id: deviceDetails[0].id, softwareVersion: deviceDetails[0].swversion, platformId: deviceDetails[0].platformid, name: deviceDetails[0].hostname };
                    SimSwimData.manualAssignmentDeviceToimage(deviceRecord);
                    deviceDetails[0].deviceId = deviceDetails[0].id;
                    SimNetworkDeviceData.createDeviceConfigStatus(deviceDetails[0],true);
                }
            }
        }
        else {
            return {detail:"Ip Filter Address is invalid",errorCode:"Bad Request",isError:true,message:"Invalid parameters specified for required operation"};
        }
    }


     function addMoreDeviceDetails (deviceId) {
      var record = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id':deviceId});
      if(record && record.length) {
        SimInterfaceData.associateInterfacesToSpecificDevice(record);
      }
    }

    function createDeviceCrential(jsonObj,validateOnly){
        var t_Json = JSON.parse(JSON.stringify(DeviceCredentialTemplate.DeviceCredential_Template));

        if(jsonObj.type =='NETWORK_DEVICE'){
            t_Json.userName = jsonObj.userName;
            t_Json.password = jsonObj.password;
        } else {
            t_Json.httpUserName = jsonObj.httpUserName;
            t_Json.httpPassword = jsonObj.httpPassword;
            t_Json.httpPort = jsonObj.httpPort;
        }

        t_Json.ipAddress = jsonObj.ipAddress[0];
        t_Json.type = jsonObj.type;

        t_Json.computeDevice = jsonObj.computeDevice;
        t_Json.snmpVersion = jsonObj.snmpVersion;
        t_Json.snmpROCommunity = jsonObj.snmpROCommunity;
        t_Json.snmpRWCommunity = jsonObj.snmpRWCommunity;
        t_Json.snmpRetry = jsonObj.snmpRetry;
        t_Json.snmpTimeout = jsonObj.snmpTimeout;
        t_Json.cliTransport = jsonObj.cliTransport;
        t_Json.enablePassword = jsonObj.enablePassword;

        if(jsonObj.snmpVersion == "v3"){
            t_Json.snmpAuthPassphrase = jsonObj.snmpAuthPassphrase;
            t_Json.snmpAuthProtocol = jsonObj.snmpAuthProtocol;
            t_Json.snmpMode = jsonObj.snmpMode;
            t_Json.snmpPrivPassphrase = jsonObj.snmpPrivPassphrase;
            t_Json.snmpPrivProtocol = jsonObj.snmpPrivProtocol;
            t_Json.snmpUserName = jsonObj.snmpUserName;
        }
        if(!validateOnly){
        SimLokiDatabaseActions.insert('device-credential', t_Json);
        }

    }

    function updateDeviceCrential(jsonObj){

        var deviceIp = jsonObj.ipAddress[0];
        var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('device-credential', { 'ipAddress' : { '$in' : deviceIp } });
        if (recordObj && recordObj.length) {
            recordObj[0].ipAddress = jsonObj.ipAddress[0];
            recordObj[0].type = jsonObj.type;
            recordObj[0].computeDevice = jsonObj.computeDevice;
            recordObj[0].snmpVersion = jsonObj.snmpVersion;
            /* recordObj[0].snmpROCommunity = jsonObj.snmpROCommunity;
            recordObj[0].snmpRWCommunity = jsonObj.snmpRWCommunity; */
            recordObj[0].snmpRetry = jsonObj.snmpRetry;
            recordObj[0].snmpTimeout = jsonObj.snmpTimeout;
            recordObj[0].cliTransport = jsonObj.cliTransport;
            recordObj[0].enablePassword = jsonObj.enablePassword;

            if(jsonObj.type =='NETWORK_DEVICE'){
                //CLI
                if(jsonObj.cliUuid) { //from global
                    var credObj = SimLokiDatabaseActions.getFilteredRecordHandler('global-credential', 
                        {'credentialSubType' : 'CLI'}, {'id':jsonObj.cliUuid} );
                    recordObj[0].userName = credObj[0].username;
                    recordObj[0].password = credObj[0].password;
                    recordObj[0].enablePassword = credObj[0].enablePassword;
                } else {
                    recordObj[0].userName = jsonObj.userName;
                    recordObj[0].password = jsonObj.password;
                    recordObj[0].enablePassword = jsonObj.enablePassword;
                }
                //SNMP
                if(jsonObj.snmpVersion == 'v3') { //SNMPv3
                    if(jsonObj.snmpV3Uuid) { //from global
                        var credObj = SimLokiDatabaseActions.getFilteredRecordHandler('global-credential', 
                            {'credentialSubType' : 'SNMPV3'}, {'id':jsonObj.snmpV3Uuid} );
                        recordObj[0].snmpMode = credObj[0].snmpMode;
                        recordObj[0].snmpAuthProtocol = credObj[0].authType;
                        recordObj[0].snmpAuthPassphrase = credObj[0].authPassword;
                        recordObj[0].snmpPrivProtocol = credObj[0].privacyType;
                        recordObj[0].snmpPrivPassphrase = credObj[0].privacyPassword;
                        recordObj[0].snmpUserName = credObj[0].userName;
                    } else {
                        recordObj[0].snmpMode = jsonObj.snmpMode;
                        recordObj[0].snmpAuthProtocol = jsonObj.snmpAuthProtocol;
                        recordObj[0].snmpAuthPassphrase = jsonObj.snmpAuthPassphrase;
                        recordObj[0].snmpPrivProtocol = jsonObj.snmpPrivProtocol;
                        recordObj[0].snmpPrivPassphrase = jsonObj.snmpPrivPassphrase;
                        recordObj[0].snmpUserName = jsonObj.snmpUserName;
                    }
                } else { //SNMPv2C
                    if(jsonObj.snmpV2WriteUuid) { //from global
                        var credObj = SimLokiDatabaseActions.getFilteredRecordHandler('global-credential', 
                            {'credentialSubType' : 'SNMPV2_WRITE_COMMUNITY'}, {'id':jsonObj.snmpV2WriteUuid} );
                        recordObj[0].snmpRWCommunity = credObj[0].writeCommunity;
                    } else if(jsonObj.snmpV2ReadUuid) { //from global
                        var credObj = SimLokiDatabaseActions.getFilteredRecordHandler('global-credential', 
                            {'credentialSubType' : 'SNMPV2_READ_COMMUNITY'}, {'id':jsonObj.snmpV2ReadUuid} );
                        recordObj[0].snmpROCommunity = credObj[0].readCommunity;
                    } else {
                        recordObj[0].snmpROCommunity = jsonObj.snmpROCommunity;
                        recordObj[0].snmpRWCommunity = jsonObj.snmpRWCommunity;
                    }
                }
                //HTTP(S)
                if(jsonObj.httpReadUuid) { //from global
                    var credObj = SimLokiDatabaseActions.getFilteredRecordHandler('global-credential', 
                        {'credentialSubType' : 'HTTP_READ'}, {'id':jsonObj.httpReadUuid} );
                    recordObj[0].httpUserName = credObj[0].userName;
                    recordObj[0].httpPassword = credObj[0].password;
                    recordObj[0].httpPort = credObj[0].port;
                } else if(jsonObj.httpWriteUuid) { //from global
                    var credObj = SimLokiDatabaseActions.getFilteredRecordHandler('global-credential', 
                        {'credentialSubType' : 'HTTP_WRITE'}, {'id':jsonObj.httpWriteUuid} );
                    recordObj[0].httpUserName = credObj[0].userName;
                    recordObj[0].httpPassword = credObj[0].password;
                    recordObj[0].httpPort = credObj[0].port;
                } else {
                    recordObj[0].httpUserName = jsonObj.httpUserName;
                    recordObj[0].httpPassword = jsonObj.httpPassword;
                    recordObj[0].httpPort = jsonObj.httpPort;
                }                
            } else {
                recordObj[0].snmpROCommunity = jsonObj.snmpROCommunity;
                recordObj[0].snmpRWCommunity = jsonObj.snmpRWCommunity;
                recordObj[0].httpUserName = jsonObj.httpUserName;
                recordObj[0].httpPassword = jsonObj.httpPassword;
                recordObj[0].httpPort = jsonObj.httpPort;

                if(jsonObj.snmpVersion == "v3"){
                    recordObj[0].snmpAuthPassphrase = jsonObj.snmpAuthPassphrase;
                    recordObj[0].snmpAuthProtocol = jsonObj.snmpAuthProtocol;
                    recordObj[0].snmpMode = jsonObj.snmpMode;
                    recordObj[0].snmpPrivPassphrase = jsonObj.snmpPrivPassphrase;
                    recordObj[0].snmpPrivProtocol = jsonObj.snmpPrivProtocol;
                    recordObj[0].snmpUserName = jsonObj.snmpUserName;
                }

            }            
            SimLokiDatabaseActions.update('device-credential', recordObj[0]);
        } else {
            createDeviceCrential(jsonObj);
        }
    }
});
