define({
"WlcVlans_template":[
    {
      "vlanName": "default",
      "vlanId": "1",
      "vlanType": "ETHERNET",
      "adminStatus": "DOWN",
      "operStatus": "DOWN",
      "ipAddress": null,
      "associatedPhysicalPorts": [
        "Ca1",
        "Port-channel1",
        "TenGigabitEthernet0/0/0",
        "TenGigabitEthernet0/0/1",
        "TenGigabitEthernet0/0/2",
        "TenGigabitEthernet0/0/3"
      ]
    },
    {
      "vlanName": "trnet-default",
      "vlanId": "1005",
      "vlanType": "TOKEN_RING_BRIDGE_RELAY_FUNCTION",
      "adminStatus": null,
      "operStatus": null,
      "ipAddress": null,
      "associatedPhysicalPorts": null
    },
    {
      "vlanName": "fddinet-default",
      "vlanId": "1004",
      "vlanType": "FDDI_NETWORK_ENTITY_TITLE",
      "adminStatus": null,
      "operStatus": null,
      "ipAddress": null,
      "associatedPhysicalPorts": null
    },
    {
      "vlanName": "token-ring-default",
      "vlanId": "1003",
      "vlanType": "TOKEN_RING_CONCENTRATOR_RELAY_FUNCTION",
      "adminStatus": null,
      "operStatus": null,
      "ipAddress": null,
      "associatedPhysicalPorts": null
    },
    {
      "vlanName": "fddi-default",
      "vlanId": "1002",
      "vlanType": "FDDI",
      "adminStatus": null,
      "operStatus": null,
      "ipAddress": null,
      "associatedPhysicalPorts": null
    },
    {
      "vlanName": "VLAN0311",
      "vlanId": "311",
      "vlanType": "ETHERNET",
      "adminStatus": "UP",
      "operStatus": "UP",
      "ipAddress": "10.16.31.42",
      "associatedPhysicalPorts": null
    },
    {
      "vlanName": "VLAN-320",
      "vlanId": "320",
      "vlanType": "ETHERNET",
      "adminStatus": null,
      "operStatus": null,
      "ipAddress": null,
      "associatedPhysicalPorts": null
    }
  ],
  "RoutersVlans_Template":[
    {
      "vlanName": "trnet-default",
      "vlanId": "1005",
      "vlanType": "TOKEN_RING_NETWORK_ENTITY_TITLE",
      "adminStatus": null,
      "operStatus": null,
      "ipAddress": null,
      "associatedPhysicalPorts": null
    },
    {
      "vlanName": "fddinet-default",
      "vlanId": "1004",
      "vlanType": "FDDI_NETWORK_ENTITY_TITLE",
      "adminStatus": null,
      "operStatus": null,
      "ipAddress": null,
      "associatedPhysicalPorts": null
    },
    {
      "vlanName": "token-ring-default",
      "vlanId": "1003",
      "vlanType": "TOKEN_RING",
      "adminStatus": null,
      "operStatus": null,
      "ipAddress": null,
      "associatedPhysicalPorts": null
    },
    {
      "vlanName": "fddi-default",
      "vlanId": "1002",
      "vlanType": "FDDI",
      "adminStatus": null,
      "operStatus": null,
      "ipAddress": null,
      "associatedPhysicalPorts": null
    },
    {
      "vlanName": "default",
      "vlanId": "1",
      "vlanType": "ETHERNET",
      "adminStatus": null,
      "operStatus": null,
      "ipAddress": null,
      "associatedPhysicalPorts": null
    }
  ],
  "SwitchVlan_Template":[
    {
      "vlanName": "default",
      "vlanId": "1",
      "vlanType": "ETHERNET",
      "adminStatus": "DOWN",
      "operStatus": "DOWN",
      "ipAddress": null,
      "associatedPhysicalPorts": [
        "GigabitEthernet1/0/12",
        "GigabitEthernet1/0/17",
        "GigabitEthernet1/0/16",
        "GigabitEthernet1/0/15",
        "GigabitEthernet1/0/14",
        "GigabitEthernet1/0/13",
        "GigabitEthernet1/0/11",
        "GigabitEthernet1/0/10",
        "GigabitEthernet1/0/19",
        "GigabitEthernet1/0/18",
        "GigabitEthernet1/1/1",
        "GigabitEthernet1/1/2",
        "GigabitEthernet1/1/3",
        "GigabitEthernet1/1/4",
        "TwentyFiveGigE1/1/2",
        "TwentyFiveGigE1/1/1",
        "TenGigabitEthernet1/1/2",
        "TenGigabitEthernet1/1/1",
        "TenGigabitEthernet1/1/6",
        "TenGigabitEthernet1/1/5",
        "TenGigabitEthernet1/1/4",
        "TenGigabitEthernet1/1/3",
        "TenGigabitEthernet1/1/8",
        "TenGigabitEthernet1/1/7",
        "GigabitEthernet1/0/20",
        "GigabitEthernet1/0/24",
        "GigabitEthernet1/0/23",
        "GigabitEthernet1/0/22",
        "GigabitEthernet1/0/21",
        "AppGigabitEthernet1/0/1",
        "GigabitEthernet1/0/9",
        "GigabitEthernet1/0/1",
        "GigabitEthernet1/0/2",
        "GigabitEthernet1/0/3",
        "GigabitEthernet1/0/4",
        "GigabitEthernet1/0/5",
        "GigabitEthernet1/0/6",
        "GigabitEthernet1/0/7",
        "GigabitEthernet1/0/8",
        "FortyGigabitEthernet1/1/2"
      ]
    },
    {
      "vlanName": "fddi-default",
      "vlanId": "1002",
      "vlanType": "FDDI",
      "adminStatus": null,
      "operStatus": null,
      "ipAddress": null,
      "associatedPhysicalPorts": null
    },
    {
      "vlanName": "token-ring-default",
      "vlanId": "1003",
      "vlanType": "TOKEN_RING_CONCENTRATOR_RELAY_FUNCTION",
      "adminStatus": null,
      "operStatus": null,
      "ipAddress": null,
      "associatedPhysicalPorts": null
    },
    {
      "vlanName": "fddinet-default",
      "vlanId": "1004",
      "vlanType": "FDDI_NETWORK_ENTITY_TITLE",
      "adminStatus": null,
      "operStatus": null,
      "ipAddress": null,
      "associatedPhysicalPorts": null
    },
    {
      "vlanName": "VLAN0318",
      "vlanId": "318",
      "vlanType": "ETHERNET",
      "adminStatus": "UP",
      "operStatus": "UP",
      "ipAddress": "10.16.51.193",
      "associatedPhysicalPorts": null
    },
    {
      "vlanName": "AP_VLAN",
      "vlanId": "2045",
      "vlanType": "ETHERNET",
      "adminStatus": "UP",
      "operStatus": "UP",
      "ipAddress": "10.16.51.129",
      "associatedPhysicalPorts": [
        "GigabitEthernet1/0/11",
        "GigabitEthernet1/0/12"
      ]
    },
    {
      "vlanName": "VOICE_VLAN",
      "vlanId": "2046",
      "vlanType": "ETHERNET",
      "adminStatus": null,
      "operStatus": null,
      "ipAddress": null,
      "associatedPhysicalPorts": null
    },
    {
      "vlanName": "10_16_52_0-VN_Employees",
      "vlanId": "1021",
      "vlanType": "ETHERNET",
      "adminStatus": "UP",
      "operStatus": "UP",
      "ipAddress": "10.16.52.1",
      "associatedPhysicalPorts": [
        "GigabitEthernet1/0/13"
      ]
    },
    {
      "vlanName": "trnet-default",
      "vlanId": "1005",
      "vlanType": "TOKEN_RING_BRIDGE_RELAY_FUNCTION",
      "adminStatus": null,
      "operStatus": null,
      "ipAddress": null,
      "associatedPhysicalPorts": null
    }
  ]

})