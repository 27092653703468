define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/client/ClientHealthTrendLine',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/client/ClientHealthDetail_BaseTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/client/ClientHealthDetail_Score_Site',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/client/ClientHealthTrendLineStatic',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/client/ClientHealthDetail_Static',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/client/ClientHealthDetailLanding_Static',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/AssuranceHostHealthDetailTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/AssuranceHealthTrendLine1',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/AssuranceZeroScoreHostHealthDetailTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/AssuranceHostHealthDetail_Client_Template',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/AssuranceZeroScoreHostHealthDetail_Client_Template',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/TimeTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/assurance/SimAssuranceData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/SimulationUtility'
], function(SimLokiDatabaseActions, ClientHealthTrendLine, ClientHealthDetail_BaseTemplate,
             ClientHealthDetail_Score_Site, ClientHealthTrendLineStatic, ClientHealthDetail_Static,
             ClientHealthDetailLanding_Static,
             AssuranceHostHealthDetailTemplate, AssuranceHealthTrendLine,
             AssuranceZeroScoreHostHealthDetail, AssuranceHostHealthDetail_Client_Template,
             AssuranceZeroScoreHostHealthDetail_Client_Template,  TimeTemplate, UtilityFunctions,SimAssuranceData, SimulationUtility){

  return {
      init: function(){
      },

      getHealthTrendline: function(urlAction){
            // return generateData(urlAction, ClientHealthTrendLineStatic.Template);
//have to work here shabeena
         var tHosts = getHostCount(urlAction);
     var trend = getNoOfTrendLineRecords(urlAction);
         var timeWindow = trend.timeWindow, recordLen = trend.recordLen;

         var tOrder = urlAction.filter['order'] != undefined ? urlAction.filter['order'].toLowerCase():'asc';
         var startDate = UtilityFunctions.getAdjustStartDateForDataGeneration(urlAction.restPayload.startTime, urlAction.restPayload.endTime, tOrder, timeWindow);

         var statsToChanged = {good_pct_to_bad_pct: false, good_pct_to_fair_pct: false,fair_pct_to_bad_pct: false,
                         fair_pct_to_good_pct: false, bad_pct_to_good_pct: false, bad_pct_to_fair_pct: false};
         var tGoodCount = tHosts.wiredGoodCount + tHosts.wirelessGoodCount;
         var tFairCount = tHosts.wiredFairCount + tHosts.wirelessFairCount;
         var tPoorCount = tHosts.wiredPoorCount + tHosts.wirelessPoorCount;
         var hostStats = {goodPct:tGoodCount, fairPct:tFairCount, badPct: tPoorCount};
         var wirelessHostStats={goodPct:tHosts.wirelessGoodCount, fairPct:tHosts.wirelessFairCount, badPct: tHosts.wirelessPoorCount};
         var wiredHostStats={goodPct:tHosts.wiredGoodCount, fairPct:tHosts.wiredFairCount, badPct: tHosts.wiredPoorCount};
         var t, incrementBy = 0;
         var tTrendLineResult = JSON.parse(JSON.stringify(ClientHealthTrendLine.Template));
         var startTime=new Date(urlAction.restPayload.startTime);
         var endTime=new Date(urlAction.restPayload.endTime);
         /* var MS_PER_DAY = 1000 * 60 * 60 * 24;
         var utc1 = Date.UTC(startTime.getFullYear(), startTime.getMonth(), startTime.getDate());
         var utc2 = Date.UTC(endTime.getFullYear(), endTime.getMonth(), endTime.getDate());
         var numberOfDays=Math.floor((utc2 - utc1) / MS_PER_DAY); */
         var numberOfDays = UtilityFunctions.date_diff_indays(endTime, startTime);

         if(numberOfDays===7){
           timeWindow=35;
           var recordLen=(numberOfDays*24*60)/timeWindow;
         }
         else if(numberOfDays===1){
           timeWindow=5;
           var recordLen=(numberOfDays*24*60)/timeWindow;
         }

         var hours = Math.abs(endTime - startTime) / 36e5;
         if(hours===3){
           timeWindow=1;
           var recordLen=hours*60;
         }


         for(var i = 0; i < recordLen; i++) {
             var tTime = UtilityFunctions.incrementDateByMinutes(startTime, incrementBy);

             if(i >= 0 && i <= 5) {
                 //take default value only..
                 t = getRandomRangeForClientHealth(hostStats, statsToChanged);
                 tWired = getRandomRangeForClientHealth(wiredHostStats, statsToChanged);
                 tWireless = getRandomRangeForClientHealth(wirelessHostStats, statsToChanged);
             }
             else if(i <= recordLen - 20) {
                 //take default value only..
                 statsToChanged.good_pct_to_fair_pct=true;
                 t = getRandomRangeForClientHealth(hostStats, statsToChanged);
                 tWired = getRandomRangeForClientHealth(wiredHostStats, statsToChanged);
                 tWireless = getRandomRangeForClientHealth(wirelessHostStats, statsToChanged);
             }
             else if( i % 9 == 0) {
                 //some good device will become fair
                 statsToChanged.good_pct_to_fair_pct = true;
                 t = getRandomRangeForClientHealth(hostStats, statsToChanged);
                 tWired = getRandomRangeForClientHealth(wiredHostStats, statsToChanged);
                 tWireless = getRandomRangeForClientHealth(wirelessHostStats, statsToChanged);
             }
             else if( i % 15 == 0) {
                 //fair device will be bad
                 statsToChanged.fair_pct_to_bad_pct = true;
                 t = getRandomRangeForClientHealth(hostStats, statsToChanged);
                 tWired = getRandomRangeForClientHealth(wiredHostStats, statsToChanged);
                 tWireless = getRandomRangeForClientHealth(wirelessHostStats, statsToChanged);
             }
             else if(i % 18  == 0) {
                 //fair device will be good
                 statsToChanged.fair_pct_to_good_pct = true;
                 t = getRandomRangeForClientHealth(hostStats, statsToChanged);
                 tWired = getRandomRangeForClientHealth(wiredHostStats, statsToChanged);
                 tWireless = getRandomRangeForClientHealth(wirelessHostStats, statsToChanged);
             }
             else if(i % 22 == 0) {
                 //some bad device will be good
                 statsToChanged.bad_pct_to_good_pct = true;
                 t = getRandomRangeForClientHealth(hostStats, statsToChanged);
                 tWired = getRandomRangeForClientHealth(wiredHostStats, statsToChanged);
                 tWireless = getRandomRangeForClientHealth(wirelessHostStats, statsToChanged);
             }
           // rndInt =Math.floor(Math.random() * (5 - 1 + 1) + 1);
            var tOverallObj = {"value": t.value,"good_pct":t.goodPct,"fair_pct":t.fairPct,"poor_pct":t.poorPct,
                         "idle_pct":0.0,"nodata_pct": 0.0,"aaa_fail_cnt": null,"dhcp_fail_cnt": null,"assoc_fail_cnt": null,"other_fail_cnt": null,"time":tTime};
             tTrendLineResult.response[0].values.push(tOverallObj);

             var tWiredObj = {"value": tWired.value,"good_pct":tWired.goodPct,"fair_pct":tWired.fairPct,"poor_pct":tWired.poorPct,
             "idle_pct":0.0,"nodata_pct": 0.0,"aaa_fail_cnt": null,"dhcp_fail_cnt": null,"assoc_fail_cnt": null,"other_fail_cnt": null,"time":tTime};
            tTrendLineResult.response[2].values.push(tWiredObj);
            var ranInt = UtilityFunctions.getRandomIntForRangeValues(1,6); 
            const acn= {"totalCount": 1,"detailedCountInfo": [{"failReasonCode": "cl_CO_CLIENT_CONNECT_TIMEOUT","failReasonDescription": "Client Connect Timeout","count": ranInt}]};
            var tWirelessObj = {"value": tWireless.value,"good_pct":tWireless.goodPct,"fair_pct":tWireless.fairPct,"poor_pct":tWireless.poorPct,
             "idle_pct":0.0, "nodata_pct": 0.0, "aaa_fail_cnt": acn,"dhcp_fail_cnt": acn,"other_fail_cnt": null,  "time":tTime};
            tTrendLineResult.response[1].values.push(tWirelessObj);
            
            /* var wiredTotalPct = Math.round((tHosts.wiredGoodCount / tHosts.wiredCount) * 100);
             var wirelessTotalPct = Math.round((tHosts.wirelessGoodCount / tHosts.wirelessCount) * 100);

             var tWiredObj = {"value": wiredTotalPct,"good_pct":tHosts.wiredGoodCount,"fair_pct":tHosts.wiredFairCount,
                              "poor_pct":tHosts.wiredPoorCount,"idle_pct":0,"time":tTime};
             tTrendLineResult.response[2].values.push(tWiredObj);

             var tWirelessObj = {"value": wirelessTotalPct,"good_pct":tHosts.wirelessGoodCount,
                                 "fair_pct":tHosts.wirelessFairCount,"poor_pct":tHosts.wirelessPoorCount,"idle_pct":0,
                                 "time":tTime};
             tTrendLineResult.response[1].values.push(tWirelessObj);*/

             incrementBy += timeWindow;
         }
   
       return tTrendLineResult;
     
     },

     getHostHealthDetail: function(urlAction) {
          return SimAssuranceData.getHostHealthDetail(urlAction);
            },

  };

    /*function isSiteHasDevices(siteId){
        var obj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'siteId' : siteId });
        return obj && obj.length ? true : false;
    }

    function getCountOfWiredWirelessHosts(devices) {
       var count = 0;
       for(var i =0; i < devices.length; i++){
           if(devices[i].role == 'ACCESS') {
               count++;
           }
       }
       return {wiredCount: count * 2, wirelessCount: 0};
    }*/

    function getHostStatsGivenAListOfHostsAndHealthType(hostStats, hostType, scoreType) {
        /*var scores = [
          {minRange: 1, maxRange: 3, scoreType: "poor"},
          {minRange: 4, maxRange: 7, scoreType: "fair"},
          {minRange: 8, maxRange: 10, scoreType: "good"}
        ];
        scoreType = scoreType.toLowerCase();
        var min = 0, max = 0;
        for(var i =0; i < scores.length; i++) {
            if(scores[i].scoreType == scoreType) {
                min = scores[i].minRange;
                max = scores[i].maxRange;
                break;
            }
        }
        var tObjs = tHosts.filter(function(itm){ return parseInt(itm.hostType == hostType &&
                                                         itm.healthScore[0].score) >= min &&
                                                         parseInt(itm.healthScore[0].score) <= max;});

        return {clientCount: tObjs.length, clientUniqueCount: tObjs.length };*/
        var s = scoreType.toLowerCase();
        var scoreType = s[0].toUpperCase() + s.slice(1);
        var hostType = hostType.toLowerCase();
        var clientCountType = hostType + scoreType + 'Count';
        var tCount = hostStats[clientCountType];
        return {clientCount: tCount, clientUniqueCount: tCount};
    }

    function getHostStatsGivenAListOfHosts(hostType, tHosts) {
        if(hostType == 'ALL') {
            var overallScore = Math.round( ((tHosts.wiredGoodCount+tHosts.wirelessGoodCount) / (tHosts.wiredCount+tHosts.wirelessCount)) * 100);
            clientCount = tHosts.wiredCount+tHosts.wirelessCount;
            clientUniqueCount = tHosts.wiredCount+tHosts.wirelessCount;
        } else if(hostType == 'WIRED') {
            var overallScore = Math.round( ((tHosts.wiredGoodCount) / (tHosts.wiredCount)) * 100);
            clientCount = tHosts.wiredCount;
            clientUniqueCount = tHosts.wiredCount;
        } else if(hostType == 'WIRELESS') {
            var overallScore = Math.round( ((tHosts.wirelessGoodCount) / (tHosts.wirelessCount)) * 100);
            clientCount = tHosts.wirelessCount;
            clientUniqueCount = tHosts.wirelessCount;
        }
        return {overallScore:overallScore, clientCount:clientCount, clientUniqueCount: clientCount};
    }

    function getScoreType(hostScore) {
        var scores = [
        {minRange: 0, maxRange: 0, scoreType: "Idle"},
        {minRange: 1, maxRange: 3, scoreType: "Poor"},
        {minRange: 4, maxRange: 7, scoreType: "Fair"},
        {minRange: 8, maxRange: 10, scoreType: "Good"}
      ];

      for(var i =0; i < scores.length; i++) {
          if(hostScore >= scores[i].minRange && hostScore <= scores[i].maxRange) {
              return scores[i].scoreType;
          }
      }
    }

    function getNoOfTrendLineRecords(urlAction) {
          //calculate dynamically based on startDate and endDate..
          return {timeWindow: 5, recordLen: 288};
    }

    function getRandomRangeForClientHealth(hostStats, changeValues) {
        var good_pct = hostStats.goodPct,fair_pct = hostStats.fairPct,bad_pct = hostStats.badPct;
        var obj = {"value":0.0,"good_pct":0.0,"fair_pct":0.0,"poor_pct":0.0,"idle_pct":0.0};

        if(changeValues.good_pct_to_bad_pct) {
            if(hostStats.goodPct>6)
            var num = UtilityFunctions.getRandomIntForRangeValues(1,6);
            else
            var num=0
            good_pct -= num;
            bad_pct += num;
        }
        if(changeValues.good_pct_to_fair_pct) {
            if(hostStats.goodPct>6)
            var num = UtilityFunctions.getRandomIntForRangeValues(1,6);
            else
            var num=0

            good_pct -= num;
            fair_pct += num;
        }
        if(changeValues.fair_pct_to_bad_pct) {
            if(hostStats.fairPct>6)
            var num = UtilityFunctions.getRandomIntForRangeValues(1,6);
            else
            var num=0

            fair_pct -= num;
            bad_pct += num;
        }
        if(changeValues.fair_pct_to_good_pct) {
            var num = UtilityFunctions.getRandomIntForRangeValues(1,fair_pct);
            good_pct += num;
            fair_pct -= num;
        }
        if(changeValues.bad_pct_to_good_pct) {
            var num = UtilityFunctions.getRandomIntForRangeValues(1,bad_pct);
            good_pct += num;
            bad_pct -= num;
        }
        if(changeValues.bad_pct_to_fair_pct) {
            var num = UtilityFunctions.getRandomIntForRangeValues(1,bad_pct);
            bad_pct -= num;
            fair_pct += num;
        }

        if(good_pct<0) good_pct=0;
        if(fair_pct<0) fair_pct=0;
        if(bad_pct<0) bad_pct=0;

        if(good_pct != 0 || fair_pct != 0 || bad_pct != 0){

           var totalValue =Math.round((good_pct / (good_pct + fair_pct + bad_pct) ) * 100);
        }
        else {
            var totalValue=0;
        }
        if(totalValue<0) totalValue=0;
        if(good_pct<0) good_pct=0;
        if(fair_pct<0) fair_pct=0;
        if(bad_pct<0) bad_pct=0;

        return {"value": totalValue, "goodPct":good_pct, "fairPct":fair_pct ,"poorPct": bad_pct};
    }

    function getHostCount(urlAction) {
        var result = { wiredCount: 0, wirelessCount: 0,
                 wiredPoorCount: 0, wiredFairCount: 0, wiredGoodCount: 0, wiredIdleCount: 0,
                 wirelessPoorCount: 0, wirelessFairCount: 0, wirelessGoodCount: 0, wirelessIdleCount: 0
              };
        var siteId = 'global';
        if(urlAction.restPayload.typeIdList.length>0) {
            siteId = SimulationUtility.validSiteId(urlAction.restPayload.typeIdList[0]);
        }
        if(siteId == 'global') {
            if(urlAction.restPayload.filters!=undefined && urlAction.restPayload.filters.frequency!=undefined && 
                 urlAction.restPayload.filters.frequency !="") {
                var frequency=urlAction.restPayload.filters.frequency;
                //var hosts=SimLokiDatabaseActions.getFilteredRecordHandler('host', {'frequency' :frequency });
                var hosts=SimLokiDatabaseActions.getFilteredRecordHandler('host',  {'$and': [ {'hostType':"WIRELESS"}, {'frequency':frequency} ]});
            }
            else{
                var hosts = SimLokiDatabaseActions.getAll('host');
            }

            if(urlAction.restPayload.filters!=undefined && urlAction.restPayload.filters.ssid!=undefined && 
                 urlAction.restPayload.filters.ssid.length >0){
                var hostslist=[];var ssids=urlAction.restPayload.filters.ssid;
              for(var i=0;i<hosts.length;i++){
                for(var j=0;j<ssids.length;j++){
                    if(hosts[i].ssid===ssids[j]){
                        hostslist.push(hosts[i]);
                    }
                }
              }
              hosts=hostslist;
            }


        }
        else{
          var siteObj=SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id' : siteId });
          var siteLocation=siteObj[0].groupNameHierarchy;
          if(urlAction.restPayload.filters!=undefined && urlAction.restPayload.filters.frequency!=undefined && urlAction.restPayload.filters.frequency !=""){
            var frequency=urlAction.restPayload.filters.frequency;
            var hostsList=SimLokiDatabaseActions.getFilteredRecordHandler('host',  {'$and': [ {'hostType':"WIRELESS"}, {'frequency':frequency} ]});
          }
           else{
             var hostsList = SimLokiDatabaseActions.getAll('host');
          }
          var hosts=[];
          for(var i=0;i<hostsList.length;i++){
             var hostLocation=hostsList[i].location;
              if(hostLocation.indexOf(siteLocation)==0)
              {
                  hosts.push(hostsList[i]);
              }
          }

          if(urlAction.restPayload.filters!=undefined && urlAction.restPayload.filters.ssid!=undefined && urlAction.restPayload.filters.ssid.length >0){
            var hostslist=[];var ssids=urlAction.restPayload.filters.ssid;
          for(var i=0;i<hosts.length;i++){
            for(var j=0;j<ssids.length;j++){
                if(hosts[i].ssid===ssids[j]){
                    hostslist.push(hosts[i]);
                }
            }
          }
          hosts=hostslist;
        }


        }

        for(var i =0; i < hosts.length; i++){
            var type = hosts[i].hostType == 'WIRED' ? 'wired' : 'wireless';
            var hostType = type + "Count";
            result[hostType]++; //increment wiredCount or wirelessCount
            var countType = type + getScoreType(hosts[i].healthScore[0].score) + 'Count';
            result[countType]++;//increment host count based on score e.g. fair, good,
        }

        //inflated clients for demo purpose..
        if(siteId == 'global') {
            var hostsList = SimLokiDatabaseActions.getAll('host');
            var tempWireless=250; var tempWired=56; var allSsids=[];
            for (var i = 0; i < hostsList.length; i++) {
                if(hostsList[i].ssid!=undefined || hostsList[i].ssid!=null)
                allSsids.push(hostsList[i].ssid);
            }

            var ssids = [...new Set(allSsids)];

            if(urlAction.restPayload.filters!=undefined && urlAction.restPayload.filters.frequency!=undefined && urlAction.restPayload.filters.frequency !=""){
                var frequency=urlAction.restPayload.filters.frequency;
                if(frequency==="2.4"){
                    hostsList=hostsList.filter(function(itm){return itm.frequency==="2.4"})
                   tempWireless=34;
                }
                else if(frequency==="5.0"){
                    hostsList=hostsList.filter(function(itm){return itm.frequency==="5.0" || itm.frequency==="5"})
                    tempWireless=216;
                }
            }

            if(urlAction.restPayload.filters!=undefined && urlAction.restPayload.filters.ssid!=undefined && urlAction.restPayload.filters.ssid.length >0){
                var s = [], totalsum = 0, index = -1, count=0;
                for(var k=0;k<ssids.length;k++){s[k]=0};
                for(var j=0;j<hostsList.length;j++){
                    for(var k=0;k<ssids.length;k++){
                        if(hostsList[j].ssid===ssids[k]){
                            s[k]++;
                        }
                    }
                }

                for (var i = 0; i < ssids.length; i++) {
                    if (ssids[i] === "c9800AP11AX") {
                        index = i;
                        continue;
                    }
                    s[i] += Math.round(tempWireless * 0.06);
                    totalsum += s[i];

                }
                if (index >= 0) {
                    s[index] = tempWireless - totalsum;
                }
                else {
                    s[s.length - 1] = tempWireless - totalsum;
                }

                for(var k=0;k<urlAction.restPayload.filters.ssid.length;k++){
                for(var z=0;z<ssids.length;z++){
                    if(urlAction.restPayload.filters.ssid[k]===ssids[z]){
                        count=count+s[z];
                    }
                }
                }
                tempWireless=count;
             }

            if(urlAction.restPayload.filters!=undefined && urlAction.restPayload.filters.frequency!=undefined && urlAction.restPayload.filters.frequency !=""){

                var frequency=urlAction.restPayload.filters.frequency;
                if(frequency==="2.4"){
                    var iWireless = tempWireless - result['wirelessCount'];
                    result['wirelessGoodCount'] += iWireless;
                    result['wirelessCount'] = tempWireless;
                }
                else if(frequency==="5.0"){
                    var iWireless = tempWireless - result['wirelessCount'];
                    result['wirelessGoodCount'] += iWireless;
                    result['wirelessCount'] = tempWireless;
                }
                var iWired = tempWired - result['wiredCount'];
              //  result['wiredGoodCount'] += iWired;
               // result['wiredCount'] = 56;

            }


        else {
        var iWired = tempWired - result['wiredCount'];
        result['wiredGoodCount'] += iWired;
        result['wiredCount'] = tempWired;
        var iWireless = tempWireless - result['wirelessCount'];
        result['wirelessGoodCount'] += iWireless;
        result['wirelessCount'] = tempWireless;
        }

    }
        return result;
    }

    function generateData(urlAction, data) {
      var i = 0, timeWindow = 5;
      var recordLen = data.response.length;

      var startDate = UtilityFunctions.getAdjustStartDateForDataGeneration(urlAction.restPayload.startTime,urlAction.restPayload.endTime, 'asc', timeWindow);

      for(; i < recordLen; i++) {
          var incrementBy = 0;
          data.response[i].time = new Date(startDate).getTime();
          for(var j=0; j<data.response[i].values.length; j++){
            var tTime = UtilityFunctions.incrementDateByMinutes(startDate, incrementBy);
            data.response[i].values[j].time = tTime;
            incrementBy += timeWindow;
          }
      }
      return data;
  }


});
