define({
"Template":
{
  "version": "1.0",
  "response": {
    "id": "e4342587-6fdd-4929-8981-cd0bc9e617df",
    "name": "mac_flap_trigger",
    "enabled": true,
    "flattened": true,
    "entityType": "network_device",
    "entityName": "SFO13-D9300-1",
    "entity": "10.0.100.101",
    "groupBy": "Unknown",
    "category": "Connectivity",
    "severity": "HIGH",
    "summary": "Host flaps observed in 1 VLAN(s)",
    "groupId": "stp_l2storm_grouping",
    "groupName": "Layer 2 loop symptoms",
    "scope": "GLOBAL",
    "priority": "P1",
    "rootCause": "MAC_FLAPPING",
    "timestamp": 1582542131346,
    "description": "Host MAC Address flaps are detected along with other events that are indicative of a Layer 2 loop. Go to Root Cause Analysis for detailed troubleshooting and see the exact impacted devices involved in the loop",
    "suggestions": [
      {
        "message": "Confirm Loop condition: Check traffic utilization of impacted ports",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Traffic utilization of impacted ports",
            "command": "show interfaces",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Confirm Loop condition: Check spanning tree status of looped vlans",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "STP status of looped vlans 31:DeviceIdPlaceHolder",
            "command": "show spanning-tree vlan 31:DeviceIdPlaceHolder",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Confirm Loop condition: Check spanning tree details",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check STP details",
            "command": "show spanning-tree detail",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Confirming there is a loop condition. Shutdown one of the ports involved in the flap on a vlan"
      },
      {
        "message": "Contact Cisco TAC World Wide – https://www.cisco.com/c/en/us/support/web/tsd-cisco-worldwide-contacts.html"
      }
    ],
    "additionalParams": [
      {
        "key": "macFlapData",
        "value": "[{\"vlanId\":\"31\",\"ports\":[\"Gi1/0/1\",\"Gi1/0/3\",\"Gi1/0/13\"]}]"
      },
      {
        "key": "managementIpAddr",
        "value": "10.0.100.101"
      },
      {
        "key": "_name",
        "value": "mac_flap_trigger"
      },
      {
        "key": "_entity_type",
        "value": "NetworkDevice"
      },
      {
        "key": "eventSource",
        "value": "Network Device"
      },
      {
        "key": "isMacFlap",
        "value": "True"
      },
      {
        "key": "type",
        "value": "issue"
      },
      {
        "key": "vlanCount",
        "value": "1"
      },
      {
        "key": "_key",
        "value": "NetworkDevice:10.0.100.101:31:[Gi1/0/1, Gi1/0/13, Gi1/0/13]"
      },
      {
        "key": "floorId",
        "value": ""
      },
      {
        "key": "_count",
        "value": "1"
      },
      {
        "key": "areaName",
        "value": "Global/USA/SFO"
      },
      {
        "key": "entityName",
        "value": "SFO13-D9300-1"
      },
      {
        "key": "scope",
        "value": "global"
      },
      {
        "key": "rootCause",
        "value": "MAC_FLAPPING"
      },
      {
        "key": "_primary_key",
        "value": "NetworkDevice:10.0.100.101"
      },
      {
        "key": "floorName",
        "value": ""
      },
      {
        "key": "deviceType",
        "value": "Cisco Catalyst 9300 Switch"
      },
      {
        "key": "severity",
        "value": "HIGH"
      },
      {
        "key": "deviceUuid",
        "value": "a977ab34-2b6d-442e-880c-ff09c3971ba8"
      },
      {
        "key": "deviceRole",
        "value": "DISTRIBUTION"
      },
      {
        "key": "_suppression",
        "value": "3600"
      },
      {
        "key": "deviceOS",
        "value": "16.8.1a"
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "nwDeviceName",
        "value": "SF-D9300-1"
      },
      {
        "key": "_eventTime",
        "value": "1582542131346"
      },
      {
        "key": "_mostRecent",
        "value": "1582542131346"
      },
      {
        "key": "entityId",
        "value": "10.0.100.101"
      },
      {
        "key": "eventUniqueId",
        "value": "SFO13-D9300-1"
      },
      {
        "key": "deviceFamily",
        "value": "Switches and Hubs"
      },
      {
        "key": "priority",
        "value": "P1"
      },
      {
        "key": "buildingId",
        "value": "75920165-893f-45c9-8120-1c5d71c7a66c"
      },
      {
        "key": "buildingName",
        "value": "Global/USA/SFO/Bldg1"
      },
      {
        "key": "areaId",
        "value": "9aff66ef-e08f-4e9c-82ed-a0688b5b2fc6"
      },
      {
        "key": "siteHierarchy",
        "value": "Global/North_America/USA/California/San_Francisco/SFO13"
      },
      {
        "key": "eventStatus",
        "value": "Down"
      },
      {
        "key": "siteId",
        "value": "75920165-893f-45c9-8120-1c5d71c7a66c"
      },
      {
        "key": "_source",
        "value": "internal"
      },
      {
        "key": "deviceModel",
        "value": "FCW2239G0XC"
      },
      {
        "key": "category",
        "value": "connectivity"
      },
      {
        "key": "timestring",
        "value": "2020-02-24T11:02:11.346+0000"
      },
      {
        "key": "uniqueId",
        "value": "31:[Gi1/0/1,Gi1/0/3, Gi1/0/13]"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}
})