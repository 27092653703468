define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/DefaultConfig',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/site/SiteBaseTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SiteConfig',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/ippool/IpPoolTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimDnaMapsData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/site/DNAMapsTemplate',
], function (DefaultConfig, UtilityFunctions, SiteBaseTemplate,SimLokiDatabaseActions,SiteConfig,
             IpPoolTemplate,SimDnaMapsData, DNAMapsTemplate) {
  var devicesCollection = [];
  var networkType ="Small";

  function nodesInNetwork(){
      console.log(networkType);
      //FixMe it should read from local sotrage, for time being hardcoded to small network
      if(networkType=="Large") {
          return DefaultConfig.DiscoveredDevice.large;
      }else if(networkType=="Medium") {
          return DefaultConfig.DiscoveredDevice.medium;
      }
      return DefaultConfig.DiscoveredDevice.small;
  }

  function setDeviceAttributes(fromCount, toCount, deviceType, deviceTypeAttribute) {
      var i;
      var tillCount = toCount, excessCount = 0;
      if( !toCount && deviceTypeAttribute.names != undefined &&  deviceTypeAttribute.names.length ) {
          tillCount =  deviceTypeAttribute.names.length;
      }

      for(i = fromCount; i < tillCount; i++) {
          var ipAddr = '', serialNumber = '', macAddr = '', hostname = '';
          var incrementBy = 0; var slIncrementedBy=0;
          var indx = i;
          if(i >= deviceTypeAttribute.deviceinfo.length && deviceTypeAttribute.random) {
              indx = UtilityFunctions.getRandomIntForRangeValues(1,  deviceTypeAttribute.deviceinfo.length) - 1;
          } else if(i >= deviceTypeAttribute.deviceinfo.length && !deviceTypeAttribute.random) {
             indx =deviceTypeAttribute.names.length-1;
          }

          if(deviceTypeAttribute.names.length > i) {
              hostname = deviceTypeAttribute.names[i];
          } else {
             excessCount++;
             hostname = deviceTypeAttribute.prefix + "_" + excessCount;
          }

          incrementBy = i;
          ipAddr = UtilityFunctions.incrementIpAddrres(deviceTypeAttribute.ipAddr, incrementBy);
          macAddr =  UtilityFunctions.incrementMacAddrres(deviceTypeAttribute.macAddr, incrementBy);

          if (['AP70B3.1733.D6D4'].indexOf(hostname) > -1) {
              ipAddr = "2001:420:81:450:10:16:30:101"
          }
          if (['APF4DB.E6CE.4F24'].indexOf(hostname) > -1) {
              ipAddr = "2001:420:81:450:10:16:30:102"
          }
            
          var id1 = UtilityFunctions.generate_uuid();
          var id2 = UtilityFunctions.generate_uuid();
          var platid = deviceTypeAttribute.deviceinfo[indx].platformid;
          var isStack = deviceTypeAttribute.deviceinfo[indx].isStack;
          var devType;
          if(isStack){
            devType = DefaultConfig.DeviceConfigDetails[(platid.split(','))[0]];
          }else {
            devType = DefaultConfig.DeviceConfigDetails[platid];
          }
          var type = devType.type;
          var family = devType.family;
          var series = devType.series;
          var counter = 1;
          if(isStack){
            counter = (platid.split(',')).length;
          }
          while(counter > 0){
             var uniqueSerialNumber=false;
             while(!uniqueSerialNumber){
                slIncrementedBy+=1;
                 var srNumber = UtilityFunctions.incrementSerialNo(devType.serialno, slIncrementedBy);
                 //var devicesArr1= SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'serialnumber':serialNumber});
                 var devicesArr1= SimLokiDatabaseActions.filterByRegx('network-device', {'serialnumber':srNumber});
                 var deviceArr2=devicesCollection.filter(function(v) {
                    return v.serialnumber === srNumber || (v.serialnumber.split(',')).indexOf(srNumber) > -1;
                 });
                 if(devicesArr1.length==0 && deviceArr2.length==0){
                    uniqueSerialNumber=true;
                 }
             }
             if(isStack){
                serialNumber += srNumber+',';
             }else {
                serialNumber = srNumber;
             }
             counter--;
          }
          if(isStack){
            serialNumber = serialNumber.substring(0,serialNumber.length-1);
          }
          var swVersion = deviceTypeAttribute.deviceinfo[indx].swversion;  
          var role =  deviceTypeAttribute.role;
          var score = 10;
          // hack
          if(hostname=="AP0081.C424.3CE2") {
            id1= "6bb52750-35ce-5c91-cede-0a3bdef4eb51";
          }
          if(hostname=="p1.edge1-sda1.local") {
            id1= "e5df3334-c405-25ae-8ea1-cb153f36f660";
          }

          var vrf = deviceTypeAttribute.deviceinfo[indx].vrf==undefined ? "" : deviceTypeAttribute.deviceinfo[indx].vrf;
          var vlan = deviceTypeAttribute.deviceinfo[indx].vlan==undefined ? "" : deviceTypeAttribute.deviceinfo[indx].vlan;
          var routing = deviceTypeAttribute.deviceinfo[indx].routing==undefined ? "" : deviceTypeAttribute.deviceinfo[indx].routing;

          devicesCollection.push( { id : id1, isDefaultFD : true,
                    deviceDefinedAs: deviceType, deviceInfoId: id2, hostname: hostname, type: type, platformid : platid,
                    macAddress: macAddr, managementIpAddress: ipAddr, serialnumber: serialNumber, swversion: swVersion,
                    family: family, role: role , healthScore:[{healthType: "OVERALL", reason: "", score: score}],series:series,
                    vrf: vrf, vlan: vlan, routing: routing});
      }
  }

  function getGroupNameHierarchy(siteName, parentId, siteId){
        var globalParentId = DefaultConfig.DEFAULT_UUID.GlobalGroup;
        var groupNameHierarchy = 'Global';
        var groupHierarchy = globalParentId;
        var serviceName = 'site';
        if(parentId == globalParentId){
            groupNameHierarchy = groupNameHierarchy + '/' + siteName;
            groupHierarchy += '/' + siteId;
        } else {
            var tObj = SimLokiDatabaseActions.getAll(serviceName);
            var siteMap = new Object();
            for(var i =0, len = tObj.length; i < len; i++){
                 siteMap[tObj[i].id] = { name: tObj[i].name, parentId: tObj[i].parentId};
            }

            var tParentId = parentId;
            var tGroup = [ {'name':siteName, 'id':siteId} ];
            while(tParentId != globalParentId){
                if(siteMap[tParentId] != undefined){
                    tGroup.push({'name':siteMap[tParentId].name, 'id':tParentId});
                    tParentId = siteMap[tParentId].parentId;
                }
            }

            for(i = tGroup.length - 1; i >= 0; i--){
                groupNameHierarchy = groupNameHierarchy + '/' + tGroup[i].name;
                groupHierarchy += '/' + tGroup[i].id;
            }
        }
        return {'name':groupNameHierarchy, 'id':groupHierarchy};
  }

  function setDnacVersion() {
    var versionToSet = '2.3.3';//Guardian
    let simInitialisedTime = UtilityFunctions.getTimeStamp();
    SimLokiDatabaseActions.insert('dnacConstants', {'key':'version','value':versionToSet});
    console.log('DNAC version set in simulation is: '+
        (SimLokiDatabaseActions.getFilteredRecordHandler('dnacConstants', {'key':'version'}))[0].value);
  }

  function setmachineReasoningSettings(){
    var obj = { 'key': 'machineReasoningSettings', 'value': 0 };
    SimLokiDatabaseActions.insert('dnacConstants', obj);
  }

   //code to make preview 2.0 as default in provision->Inventory (Magneto)
  function enablePreview2_0AsDefault() {
    window.localStorage.setItem("DNAC/PROVISION/DEVICES_2_ENABLED", true);
  }

  return {
      init: function(dbParameters) {

          var tObjs = SimLokiDatabaseActions.getAll('network-device');
         /* if(tObjs.length) {
              console.log('network device creation happned exit without creating data as data already there...');
              return;
          } */

          if( (dbParameters.KEEP_EXISTING_DATA != undefined && dbParameters.KEEP_EXISTING_DATA) && tObjs.length) {
              console.log('network device creation happned exit without creating data as data already there...');
              return;
          }

          var len = nodesInNetwork();
          var totalDevice = 0;
          for(var deviceType in DefaultConfig.NetworkDevice1) {
              if(DefaultConfig.NetworkDevice1[deviceType] == undefined) {
                  continue;
              }
              console.log( DefaultConfig.NetworkDevice1[deviceType] );

              //totalDevice += count;
              var count = 0;
              if(DefaultConfig.NetworkDevice1[deviceType].count != undefined) {
                  count = DefaultConfig.NetworkDevice1[deviceType].count;
              }
              totalDevice += count;
              setDeviceAttributes(0, count, deviceType, DefaultConfig.NetworkDevice1[deviceType]);
          }

          this.generateSiteData();
          this.generateIpPoolData();
          setDnacVersion();
          setmachineReasoningSettings();
          enablePreview2_0AsDefault();
      },

      getNetworkDevices: function(Ignore_Device_In_Topology){
          if(Ignore_Device_In_Topology == undefined || ! Array.isArray(Ignore_Device_In_Topology) ) {
              return devicesCollection;
          } else {
              //Some device should not be shown initally in topology, hence this filtering is done..
              return devicesCollection.filter(function(v) { return Ignore_Device_In_Topology.indexOf(v.hostname) == -1 });
          }
      },

      setNetworkType(type) {
          networkType=type;
      },

      generateSiteData :function() {
          var parentId = "", parentName = "";
          var defaultData = SiteConfig.SITE_DEFAULT_DATA;
          var childArray = {};
          var record;
          var i ;
          var defaultDataLength = defaultData.length;
          for(i=0;i<defaultDataLength; i++) {
              if(defaultData[i].type==undefined) {
                  if(defaultData[i].parent=="global") {
                      parentId = DefaultConfig.DEFAULT_UUID.GlobalGroup;
                      parentName = "Global"
                  } else {
                      record = SimLokiDatabaseActions.getFilteredRecordHandler("site", {'name' : defaultData[i].parent });
                      parentId =record[0].id;
                      parentName = record[0].name;
                  }
                  var siteId = UtilityFunctions.generate_uuid();
                  var tSite = JSON.parse(JSON.stringify( SiteBaseTemplate.SITE_BASIC_DATA ));
                  tSite.id = siteId;
                  tSite.parentId = parentId;
                  tSite.parentName = parentName;
                  tSite.name = defaultData[i].name;
                  // updating location
                  tSite.additionalInfo.push(defaultData[i].location);
                  var groupHierarchyInfo = getGroupNameHierarchy(tSite.name, tSite.parentId, tSite.id);
                  tSite.groupNameHierarchy  = groupHierarchyInfo.name;
                  tSite.groupHierarchy  = groupHierarchyInfo.id;
                  SimLokiDatabaseActions.insert( 'site', tSite );
                  if(defaultData[i].parent!="global") {
                      record[0].additionalInfo[0].attributes.hasChild="TRUE";
                      SimLokiDatabaseActions.update( 'site', record );
                  }
              } else {
                  var tJson = JSON.parse(JSON.stringify ( DNAMapsTemplate.Floor_Input_Template));
                  tJson.location = defaultData[i].location;
                  tJson.name = defaultData[i].name;
                  var parentName = defaultData[i].parent;
                  var record = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'name': parentName});
                  tJson.parentGroupUuid =record[0].id;
                  var result = SimDnaMapsData.createSite(tJson);
              }
          }
      },

      generateIpPoolData :function() {
          var defaultData = DefaultConfig.POOL_DEFAULT_DATA;
          for(i=0;i<defaultData.length; i++) {
              var tJson = JSON.parse(JSON.stringify(IpPoolTemplate.IPPool_Template));
              tJson.id = UtilityFunctions.generate_uuid();;
              tJson.lastUpdateTime = UtilityFunctions.getTimeStamp();
              tJson.createTime = UtilityFunctions.getTimeStamp();
              tJson.ipPoolCidr = defaultData[i].ipPoolCidr;
              tJson.ipPoolName = defaultData[i].ipPoolName;
              //   Changed for IPV6 by Karan
              if(defaultData[i].ipPoolCidr.indexOf(":") >=0){
                  tJson.ipv6 = true;
              }
              tJson.gateways   = defaultData[i].gateways;
              tJson.dhcpServerIps    = defaultData[i].dhcpServerIps;
              tJson.dnsServerIps     = defaultData[i].dnsServerIps;
              tJson.totalIpAddressCount =defaultData[i].totalIpAddressCount;
              tJson.usedIpAddressCount=defaultData[i].usedIpAddressCount;
              tJson.totalAssignableIpAddressCount =defaultData[i].totalIpAddressCount -2;
              tJson.availableIpAddressCount =defaultData[i].totalIpAddressCount -4;
              SimLokiDatabaseActions.insert('ippool', tJson);
         }
      },

      inventoryDevice :function(deviceRecord, ipAddress, networkDeviceId, devicePrefix) {
          var deviceDetails = [];
          var deviceTypeJson =  {"ACCESS": {'ECA Switch':'eca', 'Unified AP':'ap', 'Wireless Controller': 'wlc','Wireless Sensor':'sensor' },
                               "BORDER ROUTER": "edge", "CORE" : "core", "DISTRIBUTION": "distribution" };
          var deviceType = '';
          if(deviceRecord.role != 'ACCESS') {
              deviceType = deviceTypeJson[deviceRecord.role];
          } else {
              deviceType = deviceTypeJson.ACCESS[deviceRecord.family];
              if(deviceType == undefined || deviceType == '') {
                  deviceType = 'access';
              }
          }

          var deviceList = SimLokiDatabaseActions.getAll('network-device');
          var deviceSuffix = parseInt(deviceList.length) +1;
          if(devicePrefix == ""){
            devicePrefix = deviceRecord.role;
          }
          var hostname = devicePrefix + "_" + deviceSuffix;

          var deviceInfoId = UtilityFunctions.generate_uuid();
          var score = 10;//Math.floor(Math.random() * 10) + 1;//assurance all device is shown good,hence hardcode to 10

          var incrementBy = deviceSuffix;
          var macAddr = deviceRecord.macAddress, serialNum = deviceRecord.serialNumber,series = deviceRecord.series;
          if(macAddr == undefined || serialNum == undefined || series == undefined) {
              for(var i = 0; i < deviceList.length; i++) {
                  if(deviceList[i].role == deviceRecord.role) {
                      macAddr = deviceList[i].macAddress;
                      serialNum = deviceList[i].serialNumber;
                      series = deviceList[i].series;
                      break;
                  }
              }
          }
          var ipaddr="",stopWhile=false;
          var macaddr = UtilityFunctions.incrementMacAddrres(macAddr, incrementBy);
          var serialno = UtilityFunctions.incrementSerialNo(serialNum, incrementBy);

          do{
            ipaddr =  UtilityFunctions.incrementIpAddrres(ipAddress, incrementBy);
            var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'managementIpAddress': ipaddr});
            if(recordObj != undefined && recordObj.length > 0) {
                //Check if the device with the ip is already existing
                ipAddress=ipaddr;
            }
            else{
                stopWhile=true;
            }
          }while(!stopWhile);

          deviceDetails.push( { id : networkDeviceId, isDefaultFD : false,
                    deviceDefinedAs: deviceType, deviceInfoId: deviceInfoId, hostname: hostname, type: deviceRecord.type,
                    platformid : deviceRecord.platformId, macAddress: macaddr, managementIpAddress: ipaddr,
                    serialnumber: serialno, swversion: deviceRecord.softwareVersion, family: deviceRecord.family,
                    role: deviceRecord.role, healthScore:[{healthType: "OVERALL", reason: "", score: score}],series:series});

          return  deviceDetails;
      }

  };
});
