define({
    "WIFI6_AT_VIDEO": {
        "records": [
            {
                "modificationtime": 1588617300000,
                "nonAxATE": 41.48864037515308,
                "nonAxBytes": 74962388,
                "axBytes": 76157544,
                "axAirtime": 1835624,
                "nonAxAirtime": 1803000,
                "axATE": 41.57647698280643,
                "timestamp": "2020-05-04T18:35:00.000+0000"
            },
            {
                "modificationtime": 1588617600000,
                "nonAxATE": 40.81427886285081,
                "nonAxBytes": 71531843,
                "axBytes": 78973344,
                "axAirtime": 1934944,
                "nonAxAirtime": 1668336,
                "axATE": 42.876161037105234,
                "timestamp": "2020-05-04T18:40:00.000+0000"
            },
            {
                "modificationtime": 1588617900000,
                "nonAxATE": 39.45244133696683,
                "nonAxBytes": 78197116,
                "axBytes": 71402133,
                "axAirtime": 1809828,
                "nonAxAirtime": 1795300,
                "axATE": 43.556573274661616,
                "timestamp": "2020-05-04T18:45:00.000+0000"
            },
            {
                "modificationtime": 1588618200000,
                "nonAxATE": 39.00941843583518,
                "nonAxBytes": 81219107,
                "axBytes": 76416486,
                "axAirtime": 1958924,
                "nonAxAirtime": 1898224,
                "axATE": 42.78689290621128,
                "timestamp": "2020-05-04T18:50:00.000+0000"
            },
            {
                "modificationtime": 1588618500000,
                "nonAxATE": 40.434012899446714,
                "nonAxBytes": 79289468,
                "axBytes": 76207278,
                "axAirtime": 1884732,
                "nonAxAirtime": 1853124,
                "axATE": 42.78691981756213,
                "timestamp": "2020-05-04T18:55:00.000+0000"
            },
            {
                "modificationtime": 1588618800000,
                "nonAxATE": 40.822794291118704,
                "nonAxBytes": 61558561,
                "axBytes": 78212065,
                "axAirtime": 1915892,
                "nonAxAirtime": 1426504,
                "axATE": 43.153444364684574,
                "timestamp": "2020-05-04T19:00:00.000+0000"
            },
            {
                "modificationtime": 1588619100000,
                "nonAxATE": 39.925523564166575,
                "nonAxBytes": 80574701,
                "axBytes": 73954207,
                "axAirtime": 1852304,
                "nonAxAirtime": 1854620,
                "axATE": 43.4453963615188,
                "timestamp": "2020-05-04T19:05:00.000+0000"
            },
            {
                "modificationtime": 1588619400000,
                "nonAxATE": 42.011170002633655,
                "nonAxBytes": 67287743,
                "axBytes": 76567878,
                "axAirtime": 1822560,
                "nonAxAirtime": 1703672,
                "axATE": 39.49571455068816,
                "timestamp": "2020-05-04T19:10:00.000+0000"
            },
            {
                "modificationtime": 1588619700000,
                "nonAxATE": 39.03381774779361,
                "nonAxBytes": 76532679,
                "axBytes": 72994176,
                "axAirtime": 1870024,
                "nonAxAirtime": 1717472,
                "axATE": 44.561238261817365,
                "timestamp": "2020-05-04T19:15:00.000+0000"
            },
            {
                "modificationtime": 1588620000000,
                "nonAxATE": 40.5239991502916,
                "nonAxBytes": 73892765,
                "axBytes": 71346715,
                "axAirtime": 1760604,
                "nonAxAirtime": 1752804,
                "axATE": 42.15688976063496,
                "timestamp": "2020-05-04T19:20:00.000+0000"
            },
            {
                "modificationtime": 1588620300000,
                "nonAxATE": 38.07521858674732,
                "nonAxBytes": 77052005,
                "axBytes": 69239785,
                "axAirtime": 1818500,
                "nonAxAirtime": 1909928,
                "axATE": 40.34288465324347,
                "timestamp": "2020-05-04T19:25:00.000+0000"
            },
            {
                "modificationtime": 1588620600000,
                "nonAxATE": 32.74710880462591,
                "nonAxBytes": 10922123,
                "axBytes": 62975310,
                "axAirtime": 1923080,
                "nonAxAirtime": 353728,
                "axATE": 30.87717964085399,
                "timestamp": "2020-05-04T19:30:00.000+0000"
            },
            {
                "modificationtime": 1588620900000,
                "nonAxATE": 40.463660038127585,
                "nonAxBytes": 77637084,
                "axBytes": 77005744,
                "axAirtime": 1903084,
                "nonAxAirtime": 1808628,
                "axATE": 42.92595492273701,
                "timestamp": "2020-05-04T19:35:00.000+0000"
            },
            {
                "modificationtime": 1588621200000,
                "nonAxATE": 40.01368378024853,
                "nonAxBytes": 71596744,
                "axBytes": 73417107,
                "axAirtime": 1834800,
                "nonAxAirtime": 1708788,
                "axATE": 41.89913786847754,
                "timestamp": "2020-05-04T19:40:00.000+0000"
            },
            {
                "modificationtime": 1588621500000,
                "nonAxATE": 37.59753155864144,
                "nonAxBytes": 76770553,
                "axBytes": 65369625,
                "axAirtime": 1738668,
                "nonAxAirtime": 1756772,
                "axATE": 43.69978175881674,
                "timestamp": "2020-05-04T19:45:00.000+0000"
            },
            {
                "modificationtime": 1588621800000,
                "nonAxATE": 40.501201842753694,
                "nonAxBytes": 76784585,
                "axBytes": 75149332,
                "axAirtime": 1855484,
                "nonAxAirtime": 1777724,
                "axATE": 43.1926356397281,
                "timestamp": "2020-05-04T19:50:00.000+0000"
            },
            {
                "modificationtime": 1588622100000,
                "nonAxATE": 40.01429402706362,
                "nonAxBytes": 74670159,
                "axBytes": 72341362,
                "axAirtime": 1807888,
                "nonAxAirtime": 1755556,
                "axATE": 42.53362410541162,
                "timestamp": "2020-05-04T19:55:00.000+0000"
            },
            {
                "modificationtime": 1588622400000,
                "nonAxATE": 40.92075389042326,
                "nonAxBytes": 66453640,
                "axBytes": 73607270,
                "axAirtime": 1798776,
                "nonAxAirtime": 1809436,
                "axATE": 36.72616218534394,
                "timestamp": "2020-05-04T20:00:00.000+0000"
            },
            {
                "modificationtime": 1588622700000,
                "nonAxATE": 24.998183845349303,
                "nonAxBytes": 0,
                "axBytes": 52868859,
                "axAirtime": 2114908,
                "nonAxAirtime": 0,
                "axATE": 0,
                "timestamp": "2020-05-04T20:05:00.000+0000"
            },
            {
                "modificationtime": 1588623000000,
                "nonAxATE": 20.425694596993548,
                "nonAxBytes": 0,
                "axBytes": 45747755,
                "axAirtime": 2239716,
                "nonAxAirtime": 0,
                "axATE": 0,
                "timestamp": "2020-05-04T20:10:00.000+0000"
            },
            {
                "modificationtime": 1588623300000,
                "nonAxATE": 39.997410060818474,
                "nonAxBytes": 70640106,
                "axBytes": 77602975,
                "axAirtime": 1940200,
                "nonAxAirtime": 1930548,
                "axATE": 36.59070170749445,
                "timestamp": "2020-05-04T20:15:00.000+0000"
            },
            {
                "modificationtime": 1588623600000,
                "nonAxATE": 38.993255411741295,
                "nonAxBytes": 73426132,
                "axBytes": 30572116,
                "axAirtime": 784036,
                "nonAxAirtime": 2660048,
                "axATE": 27.603310917697726,
                "timestamp": "2020-05-04T20:20:00.000+0000"
            },
            {
                "modificationtime": 1588623900000,
                "nonAxATE": 0,
                "nonAxBytes": 73816453,
                "axBytes": 0,
                "axAirtime": 0,
                "nonAxAirtime": 2313996,
                "axATE": 31.899991616234427,
                "timestamp": "2020-05-04T20:25:00.000+0000"
            },
            {
                "modificationtime": 1588624200000,
                "nonAxATE": 0,
                "nonAxBytes": 63288251,
                "axBytes": 0,
                "axAirtime": 0,
                "nonAxAirtime": 2301004,
                "axATE": 27.50462450304302,
                "timestamp": "2020-05-04T20:30:00.000+0000"
            },
            {
                "modificationtime": 1588624500000,
                "nonAxATE": 38.22808644970215,
                "nonAxBytes": 78822725,
                "axBytes": 72489015,
                "axAirtime": 1896224,
                "nonAxAirtime": 1822208,
                "axATE": 43.2567110889646,
                "timestamp": "2020-05-04T20:35:00.000+0000"
            },
            {
                "modificationtime": 1588624800000,
                "nonAxATE": 30.577185535692678,
                "nonAxBytes": 27152870,
                "axBytes": 57615123,
                "axAirtime": 1884252,
                "nonAxAirtime": 760012,
                "axATE": 35.72689641742499,
                "timestamp": "2020-05-04T20:40:00.000+0000"
            },
            {
                "modificationtime": 1588625100000,
                "nonAxATE": 39.32108748652745,
                "nonAxBytes": 84327405,
                "axBytes": 81866976,
                "axAirtime": 2082012,
                "nonAxAirtime": 2055452,
                "axATE": 41.02620980689406,
                "timestamp": "2020-05-04T20:45:00.000+0000"
            },
            {
                "modificationtime": 1588625400000,
                "nonAxATE": 40.41334965047418,
                "nonAxBytes": 86317087,
                "axBytes": 81699466,
                "axAirtime": 2021596,
                "nonAxAirtime": 2022952,
                "axATE": 42.66887548493489,
                "timestamp": "2020-05-04T20:50:00.000+0000"
            },
            {
                "modificationtime": 1588625700000,
                "nonAxATE": 38.00061822034538,
                "nonAxBytes": 70984514,
                "axBytes": 70442050,
                "axAirtime": 1853708,
                "nonAxAirtime": 1658232,
                "axATE": 42.8073478258772,
                "timestamp": "2020-05-04T20:55:00.000+0000"
            },
            {
                "modificationtime": 1588626000000,
                "nonAxATE": 38.56350622037014,
                "nonAxBytes": 72461051,
                "axBytes": 70997266,
                "axAirtime": 1841048,
                "nonAxAirtime": 1695516,
                "axATE": 42.736872432934874,
                "timestamp": "2020-05-04T21:00:00.000+0000"
            },
            {
                "modificationtime": 1588626300000,
                "nonAxATE": 34.85891476916649,
                "nonAxBytes": 72285418,
                "axBytes": 1334957,
                "axAirtime": 38296,
                "nonAxAirtime": 2275212,
                "axATE": 31.7708494856743,
                "timestamp": "2020-05-04T21:05:00.000+0000"
            },
            {
                "modificationtime": 1588626600000,
                "nonAxATE": 40.436693247502475,
                "nonAxBytes": 81719284,
                "axBytes": 72615405,
                "axAirtime": 1795780,
                "nonAxAirtime": 1896480,
                "axATE": 43.08997933012739,
                "timestamp": "2020-05-04T21:10:00.000+0000"
            },
            {
                "modificationtime": 1588626900000,
                "nonAxATE": 40.153967137215744,
                "nonAxBytes": 73318194,
                "axBytes": 70770564,
                "axAirtime": 1762480,
                "nonAxAirtime": 1767140,
                "axATE": 41.48974840703057,
                "timestamp": "2020-05-04T21:15:00.000+0000"
            },
            {
                "modificationtime": 1588627200000,
                "nonAxATE": 38.91516465021752,
                "nonAxBytes": 75428592,
                "axBytes": 73528647,
                "axAirtime": 1889460,
                "nonAxAirtime": 1840636,
                "axATE": 40.9796352999724,
                "timestamp": "2020-05-04T21:20:00.000+0000"
            },
            {
                "modificationtime": 1588627500000,
                "nonAxATE": 39.300562087054644,
                "nonAxBytes": 71474817,
                "axBytes": 70212655,
                "axAirtime": 1786556,
                "nonAxAirtime": 1741864,
                "axATE": 41.03352328310362,
                "timestamp": "2020-05-04T21:25:00.000+0000"
            },
            {
                "modificationtime": 1588627800000,
                "nonAxATE": 39.48209257275544,
                "nonAxBytes": 76092699,
                "axBytes": 73862625,
                "axAirtime": 1870788,
                "nonAxAirtime": 1774688,
                "axATE": 42.87666282749419,
                "timestamp": "2020-05-04T21:30:00.000+0000"
            },
            {
                "modificationtime": 1588628100000,
                "nonAxATE": 39.800856953586376,
                "nonAxBytes": 61391180,
                "axBytes": 33885972,
                "axAirtime": 851388,
                "nonAxAirtime": 2011492,
                "axATE": 30.52022081121874,
                "timestamp": "2020-05-04T21:35:00.000+0000"
            },
            {
                "modificationtime": 1588628400000,
                "nonAxATE": 38.39966980344698,
                "nonAxBytes": 62792467,
                "axBytes": 73613703,
                "axAirtime": 1917040,
                "nonAxAirtime": 1535436,
                "axATE": 40.895528696735,
                "timestamp": "2020-05-04T21:40:00.000+0000"
            },
            {
                "modificationtime": 1588628700000,
                "nonAxATE": 40.621005066365385,
                "nonAxBytes": 84485427,
                "axBytes": 73058015,
                "axAirtime": 1798528,
                "nonAxAirtime": 2082468,
                "axATE": 40.56985605541117,
                "timestamp": "2020-05-04T21:45:00.000+0000"
            },
            {
                "modificationtime": 1588629000000,
                "nonAxATE": 40.40919627990349,
                "nonAxBytes": 83967684,
                "axBytes": 76106357,
                "axAirtime": 1883392,
                "nonAxAirtime": 1940532,
                "axATE": 43.270445424244485,
                "timestamp": "2020-05-04T21:50:00.000+0000"
            },
            {
                "modificationtime": 1588629300000,
                "nonAxATE": 39.942800091869906,
                "nonAxBytes": 49922702,
                "axBytes": 80694362,
                "axAirtime": 2020248,
                "nonAxAirtime": 1143368,
                "axATE": 43.66284695741004,
                "timestamp": "2020-05-04T21:55:00.000+0000"
            },
            {
                "modificationtime": 1588629600000,
                "nonAxATE": 39.30140434846761,
                "nonAxBytes": 80521133,
                "axBytes": 77777322,
                "axAirtime": 1978996,
                "nonAxAirtime": 1850388,
                "axATE": 43.51581019764503,
                "timestamp": "2020-05-04T22:00:00.000+0000"
            },
            {
                "modificationtime": 1588629900000,
                "nonAxATE": 39.180896218518015,
                "nonAxBytes": 80019968,
                "axBytes": 79570288,
                "axAirtime": 2030844,
                "nonAxAirtime": 1849812,
                "axATE": 43.25843274884151,
                "timestamp": "2020-05-04T22:05:00.000+0000"
            },
            {
                "modificationtime": 1588630200000,
                "nonAxATE": 39.91010370929609,
                "nonAxBytes": 80364502,
                "axBytes": 72316629,
                "axAirtime": 1811988,
                "nonAxAirtime": 1810312,
                "axATE": 44.39262513865013,
                "timestamp": "2020-05-04T22:10:00.000+0000"
            },
            {
                "modificationtime": 1588630500000,
                "nonAxATE": 40.33974700491088,
                "nonAxBytes": 85324133,
                "axBytes": 82077831,
                "axAirtime": 2034664,
                "nonAxAirtime": 1920888,
                "axATE": 44.41910876636222,
                "timestamp": "2020-05-04T22:15:00.000+0000"
            },
            {
                "modificationtime": 1588630800000,
                "nonAxATE": 43.41291550912062,
                "nonAxBytes": 91154445,
                "axBytes": 89885226,
                "axAirtime": 2070472,
                "nonAxAirtime": 2047156,
                "axATE": 44.527356488709216,
                "timestamp": "2020-05-04T22:20:00.000+0000"
            },
            {
                "modificationtime": 1588631100000,
                "nonAxATE": 39.35889678119113,
                "nonAxBytes": 86620387,
                "axBytes": 77220896,
                "axAirtime": 1961968,
                "nonAxAirtime": 1980952,
                "axATE": 43.72664607723963,
                "timestamp": "2020-05-04T22:25:00.000+0000"
            },
            {
                "modificationtime": 1588631400000,
                "nonAxATE": 39.207157581172474,
                "nonAxBytes": 82361471,
                "axBytes": 78286343,
                "axAirtime": 1996736,
                "nonAxAirtime": 1888716,
                "axATE": 43.60712304020297,
                "timestamp": "2020-05-04T22:30:00.000+0000"
            },
            {
                "modificationtime": 1588631700000,
                "nonAxATE": 37.24003099163811,
                "nonAxBytes": 86171499,
                "axBytes": 78970167,
                "axAirtime": 2120572,
                "nonAxAirtime": 1980792,
                "axATE": 43.50355766784195,
                "timestamp": "2020-05-04T22:35:00.000+0000"
            },
            {
                "modificationtime": 1588632000000,
                "nonAxATE": 36.81300262522751,
                "nonAxBytes": 75595116,
                "axBytes": 77910597,
                "axAirtime": 2116388,
                "nonAxAirtime": 1724360,
                "axATE": 43.839520749727434,
                "timestamp": "2020-05-04T22:40:00.000+0000"
            },
            {
                "modificationtime": 1588632300000,
                "nonAxATE": 40.6819234319031,
                "nonAxBytes": 81269334,
                "axBytes": 80046078,
                "axAirtime": 1967608,
                "nonAxAirtime": 1985632,
                "axATE": 40.92869877197789,
                "timestamp": "2020-05-04T22:45:00.000+0000"
            },
            {
                "modificationtime": 1588632600000,
                "nonAxATE": 46.04070169073362,
                "nonAxBytes": 125296762,
                "axBytes": 126897382,
                "axAirtime": 2756200,
                "nonAxAirtime": 2953092,
                "axATE": 42.42900729134074,
                "timestamp": "2020-05-04T22:50:00.000+0000"
            },
            {
                "modificationtime": 1588632900000,
                "nonAxATE": 38.16938762885733,
                "nonAxBytes": 72096046,
                "axBytes": 72809481,
                "axAirtime": 1907536,
                "nonAxAirtime": 1749652,
                "axATE": 41.20593466586498,
                "timestamp": "2020-05-04T22:55:00.000+0000"
            },
            {
                "modificationtime": 1588633200000,
                "nonAxATE": 40.13288188930063,
                "nonAxBytes": 86442001,
                "axBytes": 81875895,
                "axAirtime": 2040120,
                "nonAxAirtime": 2099040,
                "axATE": 41.18168353151917,
                "timestamp": "2020-05-04T23:00:00.000+0000"
            },
            {
                "modificationtime": 1588633500000,
                "nonAxATE": 40.332699095814036,
                "nonAxBytes": 64352130,
                "axBytes": 30439572,
                "axAirtime": 754712,
                "nonAxAirtime": 2521816,
                "axATE": 25.518170239224432,
                "timestamp": "2020-05-04T23:05:00.000+0000"
            },
            {
                "modificationtime": 1588633800000,
                "nonAxATE": 0,
                "nonAxBytes": 68618005,
                "axBytes": 0,
                "axAirtime": 0,
                "nonAxAirtime": 2859088,
                "axATE": 23.999962575478616,
                "timestamp": "2020-05-04T23:10:00.000+0000"
            },
            {
                "modificationtime": 1588634100000,
                "nonAxATE": 33.63313099584816,
                "nonAxBytes": 4069481,
                "axBytes": 70412036,
                "axAirtime": 2093532,
                "nonAxAirtime": 202048,
                "axATE": 20.14115952644916,
                "timestamp": "2020-05-04T23:15:00.000+0000"
            },
            {
                "modificationtime": 1588634400000,
                "nonAxATE": 31.464109191628562,
                "nonAxBytes": 0,
                "axBytes": 63251166,
                "axAirtime": 2010264,
                "nonAxAirtime": 0,
                "axATE": 0,
                "timestamp": "2020-05-04T23:20:00.000+0000"
            },
            {
                "modificationtime": 1588634700000,
                "nonAxATE": 38.1255942123715,
                "nonAxBytes": 0,
                "axBytes": 82126800,
                "axAirtime": 2154112,
                "nonAxAirtime": 0,
                "axATE": 0,
                "timestamp": "2020-05-04T23:25:00.000+0000"
            },
            {
                "modificationtime": 1588635000000,
                "nonAxATE": 39.67675504970782,
                "nonAxBytes": 84066469,
                "axBytes": 77920227,
                "axAirtime": 1963876,
                "nonAxAirtime": 1937344,
                "axATE": 43.39263909765122,
                "timestamp": "2020-05-04T23:30:00.000+0000"
            },
            {
                "modificationtime": 1588635300000,
                "nonAxATE": 40.37560152269712,
                "nonAxBytes": 80656081,
                "axBytes": 78868731,
                "axAirtime": 1953376,
                "nonAxAirtime": 1883604,
                "axATE": 42.82008373309889,
                "timestamp": "2020-05-04T23:35:00.000+0000"
            },
            {
                "modificationtime": 1588635600000,
                "nonAxATE": 38.34090665230621,
                "nonAxBytes": 84087521,
                "axBytes": 75753810,
                "axAirtime": 1975796,
                "nonAxAirtime": 1909528,
                "axATE": 44.03576224072127,
                "timestamp": "2020-05-04T23:40:00.000+0000"
            },
            {
                "modificationtime": 1588635900000,
                "nonAxATE": 38.651560452203,
                "nonAxBytes": 78999270,
                "axBytes": 78212051,
                "axAirtime": 2023516,
                "nonAxAirtime": 1818640,
                "axATE": 43.438651959706156,
                "timestamp": "2020-05-04T23:45:00.000+0000"
            },
            {
                "modificationtime": 1588636200000,
                "nonAxATE": 39.9397202521588,
                "nonAxBytes": 80188550,
                "axBytes": 76205146,
                "axAirtime": 1908004,
                "nonAxAirtime": 1832900,
                "axATE": 43.74954989361122,
                "timestamp": "2020-05-04T23:50:00.000+0000"
            },
            {
                "modificationtime": 1588636500000,
                "nonAxATE": 39.0655845944457,
                "nonAxBytes": 80129119,
                "axBytes": 77153592,
                "axAirtime": 1974976,
                "nonAxAirtime": 1886024,
                "axATE": 42.48573666082722,
                "timestamp": "2020-05-04T23:55:00.000+0000"
            },
            {
                "modificationtime": 1588636800000,
                "nonAxATE": 37.61811867473038,
                "nonAxBytes": 72782922,
                "axBytes": 68869446,
                "axAirtime": 1830752,
                "nonAxAirtime": 1730088,
                "axATE": 42.06891325759152,
                "timestamp": "2020-05-05T00:00:00.000+0000"
            },
            {
                "modificationtime": 1588637100000,
                "nonAxATE": 39.205829806442075,
                "nonAxBytes": 79964701,
                "axBytes": 78517829,
                "axAirtime": 2002708,
                "nonAxAirtime": 1840964,
                "axATE": 43.436319775943474,
                "timestamp": "2020-05-05T00:05:00.000+0000"
            },
            {
                "modificationtime": 1588637400000,
                "nonAxATE": 39.862882950741515,
                "nonAxBytes": 81209295,
                "axBytes": 79595494,
                "axAirtime": 1996732,
                "nonAxAirtime": 1826264,
                "axATE": 44.467445560992275,
                "timestamp": "2020-05-05T00:10:00.000+0000"
            },
            {
                "modificationtime": 1588637700000,
                "nonAxATE": 41.69419764523391,
                "nonAxBytes": 82011870,
                "axBytes": 77411949,
                "axAirtime": 1856660,
                "nonAxAirtime": 1943244,
                "axATE": 42.20358843253858,
                "timestamp": "2020-05-05T00:15:00.000+0000"
            },
            {
                "modificationtime": 1588638000000,
                "nonAxATE": 40.73923170659929,
                "nonAxBytes": 76446937,
                "axBytes": 74753394,
                "axAirtime": 1834924,
                "nonAxAirtime": 1938016,
                "axATE": 39.44597825817743,
                "timestamp": "2020-05-05T00:20:00.000+0000"
            },
            {
                "modificationtime": 1588638300000,
                "nonAxATE": 40.44567735557501,
                "nonAxBytes": 81230771,
                "axBytes": 70042368,
                "axAirtime": 1731764,
                "nonAxAirtime": 1959604,
                "axATE": 41.452646044813136,
                "timestamp": "2020-05-05T00:25:00.000+0000"
            },
            {
                "modificationtime": 1588638600000,
                "nonAxATE": 41.06368511167521,
                "nonAxBytes": 71975316,
                "axBytes": 77130084,
                "axAirtime": 1878304,
                "nonAxAirtime": 1852952,
                "axATE": 38.84359443741662,
                "timestamp": "2020-05-05T00:30:00.000+0000"
            },
            {
                "modificationtime": 1588638900000,
                "nonAxATE": 43.29992287326362,
                "nonAxBytes": 74717692,
                "axBytes": 84324175,
                "axAirtime": 1947444,
                "nonAxAirtime": 1973372,
                "axATE": 37.86295336104901,
                "timestamp": "2020-05-05T00:35:00.000+0000"
            },
            {
                "modificationtime": 1588639200000,
                "nonAxATE": 40.49924335790663,
                "nonAxBytes": 84131934,
                "axBytes": 78735227,
                "axAirtime": 1944116,
                "nonAxAirtime": 1964908,
                "axATE": 42.81723826255479,
                "timestamp": "2020-05-05T00:40:00.000+0000"
            },
            {
                "modificationtime": 1588639500000,
                "nonAxATE": 38.050562136064684,
                "nonAxBytes": 74002121,
                "axBytes": 29065759,
                "axAirtime": 763872,
                "nonAxAirtime": 2239252,
                "axATE": 33.04769673087263,
                "timestamp": "2020-05-05T00:45:00.000+0000"
            },
            {
                "modificationtime": 1588639800000,
                "nonAxATE": 38.24268475918936,
                "nonAxBytes": 81489863,
                "axBytes": 75541846,
                "axAirtime": 1975328,
                "nonAxAirtime": 1855876,
                "axATE": 43.90910976810951,
                "timestamp": "2020-05-05T00:50:00.000+0000"
            },
            {
                "modificationtime": 1588640100000,
                "nonAxATE": 37.7649719159597,
                "nonAxBytes": 9909890,
                "axBytes": 82726739,
                "axAirtime": 2190568,
                "nonAxAirtime": 218256,
                "axATE": 45.404891503555454,
                "timestamp": "2020-05-05T00:55:00.000+0000"
            },
            {
                "modificationtime": 1588640400000,
                "nonAxATE": 39.677417977375484,
                "nonAxBytes": 79138414,
                "axBytes": 77122267,
                "axAirtime": 1943732,
                "nonAxAirtime": 1813036,
                "axATE": 43.64966498183158,
                "timestamp": "2020-05-05T01:00:00.000+0000"
            },
            {
                "modificationtime": 1588640700000,
                "nonAxATE": 39.69797208673197,
                "nonAxBytes": 80807144,
                "axBytes": 77480914,
                "axAirtime": 1951760,
                "nonAxAirtime": 1856336,
                "axATE": 43.53045138380121,
                "timestamp": "2020-05-05T01:05:00.000+0000"
            },
            {
                "modificationtime": 1588641000000,
                "nonAxATE": 39.80571430234182,
                "nonAxBytes": 55383307,
                "axBytes": 82078746,
                "axAirtime": 2061984,
                "nonAxAirtime": 1320652,
                "axATE": 41.93633674881801,
                "timestamp": "2020-05-05T01:10:00.000+0000"
            },
            {
                "modificationtime": 1588641300000,
                "nonAxATE": 39.04513961079463,
                "nonAxBytes": 64108196,
                "axBytes": 45071835,
                "axAirtime": 1154352,
                "nonAxAirtime": 2488708,
                "axATE": 25.75962949450076,
                "timestamp": "2020-05-05T01:15:00.000+0000"
            },
            {
                "modificationtime": 1588641600000,
                "nonAxATE": 37.94353045077401,
                "nonAxBytes": 72086916,
                "axBytes": 77248323,
                "axAirtime": 2035876,
                "nonAxAirtime": 1631584,
                "axATE": 44.182166532645574,
                "timestamp": "2020-05-05T01:20:00.000+0000"
            },
            {
                "modificationtime": 1588641900000,
                "nonAxATE": 38.30340574086909,
                "nonAxBytes": 81287241,
                "axBytes": 77593354,
                "axAirtime": 2025756,
                "nonAxAirtime": 1852408,
                "axATE": 43.88193151832642,
                "timestamp": "2020-05-05T01:25:00.000+0000"
            },
            {
                "modificationtime": 1588642200000,
                "nonAxATE": 39.345382324423035,
                "nonAxBytes": 84619689,
                "axBytes": 79371125,
                "axAirtime": 2017292,
                "nonAxAirtime": 1929252,
                "axATE": 43.861397577921394,
                "timestamp": "2020-05-05T01:30:00.000+0000"
            },
            {
                "modificationtime": 1588642500000,
                "nonAxATE": 39.13560578757998,
                "nonAxBytes": 81715139,
                "axBytes": 79488642,
                "axAirtime": 2031108,
                "nonAxAirtime": 1866580,
                "axATE": 43.7779998714226,
                "timestamp": "2020-05-05T01:35:00.000+0000"
            },
            {
                "modificationtime": 1588642800000,
                "nonAxATE": 39.94729931938952,
                "nonAxBytes": 62879567,
                "axBytes": 77099566,
                "axAirtime": 1930032,
                "nonAxAirtime": 1433932,
                "axATE": 43.85114984532042,
                "timestamp": "2020-05-05T01:40:00.000+0000"
            },
            {
                "modificationtime": 1588643100000,
                "nonAxATE": 37.1777423862777,
                "nonAxBytes": 74773363,
                "axBytes": 42672464,
                "axAirtime": 1147796,
                "nonAxAirtime": 2367224,
                "axATE": 31.586940230413344,
                "timestamp": "2020-05-05T01:45:00.000+0000"
            },
            {
                "modificationtime": 1588643400000,
                "nonAxATE": 39.37309605533505,
                "nonAxBytes": 81082045,
                "axBytes": 78645082,
                "axAirtime": 1997432,
                "nonAxAirtime": 1958832,
                "axATE": 41.39305718918212,
                "timestamp": "2020-05-05T01:50:00.000+0000"
            },
            {
                "modificationtime": 1588643700000,
                "nonAxATE": 29.13924035018468,
                "nonAxBytes": 91158531,
                "axBytes": 82678515,
                "axAirtime": 2837360,
                "nonAxAirtime": 2238028,
                "axATE": 40.73163115028051,
                "timestamp": "2020-05-05T01:55:00.000+0000"
            },
            {
                "modificationtime": 1588644000000,
                "nonAxATE": 39.85698392316893,
                "nonAxBytes": 83737326,
                "axBytes": 81574173,
                "axAirtime": 2046672,
                "nonAxAirtime": 2028444,
                "axATE": 41.28155670060401,
                "timestamp": "2020-05-05T02:00:00.000+0000"
            },
            {
                "modificationtime": 1588644300000,
                "nonAxATE": 39.63878203905393,
                "nonAxBytes": 84463597,
                "axBytes": 70650421,
                "axAirtime": 1782356,
                "nonAxAirtime": 1951592,
                "axATE": 43.27933143812846,
                "timestamp": "2020-05-05T02:05:00.000+0000"
            },
            {
                "modificationtime": 1588644600000,
                "nonAxATE": 40.55487600311334,
                "nonAxBytes": 80240661,
                "axBytes": 72529638,
                "axAirtime": 1788432,
                "nonAxAirtime": 1870352,
                "axATE": 42.90136883324636,
                "timestamp": "2020-05-05T02:10:00.000+0000"
            },
            {
                "modificationtime": 1588644900000,
                "nonAxATE": 40.06704531405744,
                "nonAxBytes": 57746361,
                "axBytes": 82966991,
                "axAirtime": 2070704,
                "nonAxAirtime": 1315824,
                "axATE": 43.88608278918761,
                "timestamp": "2020-05-05T02:15:00.000+0000"
            },
            {
                "modificationtime": 1588645200000,
                "nonAxATE": 41.01812295169478,
                "nonAxBytes": 79300507,
                "axBytes": 50714315,
                "axAirtime": 1236388,
                "nonAxAirtime": 2064084,
                "axATE": 38.419224702095455,
                "timestamp": "2020-05-05T02:20:00.000+0000"
            },
            {
                "modificationtime": 1588645500000,
                "nonAxATE": 43.73598960386794,
                "nonAxBytes": 87054925,
                "axBytes": 84307243,
                "axAirtime": 1927640,
                "nonAxAirtime": 2042948,
                "axATE": 42.61240374204336,
                "timestamp": "2020-05-05T02:25:00.000+0000"
            },
            {
                "modificationtime": 1588645800000,
                "nonAxATE": 39.61591363276612,
                "nonAxBytes": 78216553,
                "axBytes": 80087531,
                "axAirtime": 2021600,
                "nonAxAirtime": 1781456,
                "axATE": 43.90596961137407,
                "timestamp": "2020-05-05T02:30:00.000+0000"
            },
            {
                "modificationtime": 1588646100000,
                "nonAxATE": 38.86080435165383,
                "nonAxBytes": 78529138,
                "axBytes": 78570795,
                "axAirtime": 2021852,
                "nonAxAirtime": 1851820,
                "axATE": 42.40646391117927,
                "timestamp": "2020-05-05T02:35:00.000+0000"
            },
            {
                "modificationtime": 1588646400000,
                "nonAxATE": 40.746083250993166,
                "nonAxBytes": 80475294,
                "axBytes": 80165800,
                "axAirtime": 1967448,
                "nonAxAirtime": 1825860,
                "axATE": 44.07528178502185,
                "timestamp": "2020-05-05T02:40:00.000+0000"
            },
            {
                "modificationtime": 1588646700000,
                "nonAxATE": 40.59478748002712,
                "nonAxBytes": 83215294,
                "axBytes": 75913714,
                "axAirtime": 1870036,
                "nonAxAirtime": 1978932,
                "axATE": 42.05060810578635,
                "timestamp": "2020-05-05T02:45:00.000+0000"
            },
            {
                "modificationtime": 1588647000000,
                "nonAxATE": 43.71537024406157,
                "nonAxBytes": 65698905,
                "axBytes": 5896504,
                "axAirtime": 134884,
                "nonAxAirtime": 2436800,
                "axATE": 26.961139609323702,
                "timestamp": "2020-05-05T02:50:00.000+0000"
            },
            {
                "modificationtime": 1588647300000,
                "nonAxATE": 38.95368780786821,
                "nonAxBytes": 80924627,
                "axBytes": 77782568,
                "axAirtime": 1996796,
                "nonAxAirtime": 1864176,
                "axATE": 43.41040062740857,
                "timestamp": "2020-05-05T02:55:00.000+0000"
            },
            {
                "modificationtime": 1588647600000,
                "nonAxATE": 34.937687453867895,
                "nonAxBytes": 80726619,
                "axBytes": 77627349,
                "axAirtime": 2221880,
                "nonAxAirtime": 1820752,
                "axATE": 44.3369657152649,
                "timestamp": "2020-05-05T03:00:00.000+0000"
            },
            {
                "modificationtime": 1588647900000,
                "nonAxATE": 33.58282679365748,
                "nonAxBytes": 0,
                "axBytes": 68884154,
                "axAirtime": 2051172,
                "nonAxAirtime": 0,
                "axATE": 0,
                "timestamp": "2020-05-05T03:05:00.000+0000"
            },
            {
                "modificationtime": 1588648200000,
                "nonAxATE": 35.19481838717008,
                "nonAxBytes": 0,
                "axBytes": 72481476,
                "axAirtime": 2059436,
                "nonAxAirtime": 0,
                "axATE": 0,
                "timestamp": "2020-05-05T03:10:00.000+0000"
            },
            {
                "modificationtime": 1588648500000,
                "nonAxATE": 40.420913937878964,
                "nonAxBytes": 82245495,
                "axBytes": 81756634,
                "axAirtime": 2022632,
                "nonAxAirtime": 1889832,
                "axATE": 43.52000336537851,
                "timestamp": "2020-05-05T03:15:00.000+0000"
            },
            {
                "modificationtime": 1588648800000,
                "nonAxATE": 39.64010256420319,
                "nonAxBytes": 82997572,
                "axBytes": 80807459,
                "axAirtime": 2038528,
                "nonAxAirtime": 1894748,
                "axATE": 43.80401615412709,
                "timestamp": "2020-05-05T03:20:00.000+0000"
            },
            {
                "modificationtime": 1588649100000,
                "nonAxATE": 39.75289172159576,
                "nonAxBytes": 83208645,
                "axBytes": 78812334,
                "axAirtime": 1982556,
                "nonAxAirtime": 1879008,
                "axATE": 44.28328405201042,
                "timestamp": "2020-05-05T03:25:00.000+0000"
            },
            {
                "modificationtime": 1588649400000,
                "nonAxATE": 40.87433458008302,
                "nonAxBytes": 81709351,
                "axBytes": 81174957,
                "axAirtime": 1985964,
                "nonAxAirtime": 1875260,
                "axATE": 43.57227851071318,
                "timestamp": "2020-05-05T03:30:00.000+0000"
            },
            {
                "modificationtime": 1588649700000,
                "nonAxATE": 41.3856416064576,
                "nonAxBytes": 83924895,
                "axBytes": 81704858,
                "axAirtime": 1974232,
                "nonAxAirtime": 1930292,
                "axATE": 43.47782356244547,
                "timestamp": "2020-05-05T03:35:00.000+0000"
            },
            {
                "modificationtime": 1588650000000,
                "nonAxATE": 39.84035342954289,
                "nonAxBytes": 79148829,
                "axBytes": 77663032,
                "axAirtime": 1949356,
                "nonAxAirtime": 1824172,
                "axATE": 43.38890685746739,
                "timestamp": "2020-05-05T03:40:00.000+0000"
            },
            {
                "modificationtime": 1588650300000,
                "nonAxATE": 39.18885067584614,
                "nonAxBytes": 80218099,
                "axBytes": 75183183,
                "axAirtime": 1918484,
                "nonAxAirtime": 1829908,
                "axATE": 43.83723061487244,
                "timestamp": "2020-05-05T03:45:00.000+0000"
            },
            {
                "modificationtime": 1588650600000,
                "nonAxATE": 40.17136,
                "nonAxBytes": 80575317,
                "axBytes": 80844862,
                "axAirtime": 2012500,
                "nonAxAirtime": 1839364,
                "axATE": 43.80607481716506,
                "timestamp": "2020-05-05T03:50:00.000+0000"
            },
            {
                "modificationtime": 1588650900000,
                "nonAxATE": 39.10783792656047,
                "nonAxBytes": 75675894,
                "axBytes": 10007070,
                "axAirtime": 255884,
                "nonAxAirtime": 2522332,
                "axATE": 30.002352584830227,
                "timestamp": "2020-05-05T03:55:00.000+0000"
            },
            {
                "modificationtime": 1588651200000,
                "nonAxATE": 39.22346187239311,
                "nonAxBytes": 84207679,
                "axBytes": 79330393,
                "axAirtime": 2022524,
                "nonAxAirtime": 1910592,
                "axATE": 44.07412937979433,
                "timestamp": "2020-05-05T04:00:00.000+0000"
            },
            {
                "modificationtime": 1588651500000,
                "nonAxATE": 38.15235204180088,
                "nonAxBytes": 69607136,
                "axBytes": 71965866,
                "axAirtime": 1886276,
                "nonAxAirtime": 1656312,
                "axATE": 42.025376861364286,
                "timestamp": "2020-05-05T04:05:00.000+0000"
            },
            {
                "modificationtime": 1588651800000,
                "nonAxATE": 39.35051412975608,
                "nonAxBytes": 83009230,
                "axBytes": 78145399,
                "axAirtime": 1985880,
                "nonAxAirtime": 1909620,
                "axATE": 43.46897812130162,
                "timestamp": "2020-05-05T04:10:00.000+0000"
            },
            {
                "modificationtime": 1588652100000,
                "nonAxATE": 39.97661247932066,
                "nonAxBytes": 79819912,
                "axBytes": 78486563,
                "axAirtime": 1963312,
                "nonAxAirtime": 1836452,
                "axATE": 43.464197267339415,
                "timestamp": "2020-05-05T04:15:00.000+0000"
            },
            {
                "modificationtime": 1588652400000,
                "nonAxATE": 40.19322455472027,
                "nonAxBytes": 86332025,
                "axBytes": 79343033,
                "axAirtime": 1974040,
                "nonAxAirtime": 1995312,
                "axATE": 43.26743135910574,
                "timestamp": "2020-05-05T04:20:00.000+0000"
            },
            {
                "modificationtime": 1588652700000,
                "nonAxATE": 37.01413714395937,
                "nonAxBytes": 29304403,
                "axBytes": 78292599,
                "axAirtime": 2115208,
                "nonAxAirtime": 695404,
                "axATE": 42.14011279774059,
                "timestamp": "2020-05-05T04:25:00.000+0000"
            },
            {
                "modificationtime": 1588653000000,
                "nonAxATE": 41.023055645128785,
                "nonAxBytes": 86781364,
                "axBytes": 81419279,
                "axAirtime": 1984720,
                "nonAxAirtime": 1942384,
                "axATE": 44.67775887775023,
                "timestamp": "2020-05-05T04:30:00.000+0000"
            },
            {
                "modificationtime": 1588653300000,
                "nonAxATE": 42.499172829274514,
                "nonAxBytes": 78071708,
                "axBytes": 79997043,
                "axAirtime": 1882320,
                "nonAxAirtime": 1871460,
                "axATE": 41.717005973945476,
                "timestamp": "2020-05-05T04:35:00.000+0000"
            },
            {
                "modificationtime": 1588653600000,
                "nonAxATE": 43.16009066334126,
                "nonAxBytes": 82299554,
                "axBytes": 85003108,
                "axAirtime": 1969484,
                "nonAxAirtime": 2071672,
                "axATE": 39.72615066477705,
                "timestamp": "2020-05-05T04:40:00.000+0000"
            },
            {
                "modificationtime": 1588653900000,
                "nonAxATE": 42.15326186817565,
                "nonAxBytes": 80403733,
                "axBytes": 82955596,
                "axAirtime": 1967952,
                "nonAxAirtime": 1896428,
                "axATE": 42.397461438029815,
                "timestamp": "2020-05-05T04:45:00.000+0000"
            },
            {
                "modificationtime": 1588654200000,
                "nonAxATE": 40.39375128113841,
                "nonAxBytes": 72968512,
                "axBytes": 74882744,
                "axAirtime": 1853820,
                "nonAxAirtime": 1754504,
                "axATE": 41.58925371501005,
                "timestamp": "2020-05-05T04:50:00.000+0000"
            },
            {
                "modificationtime": 1588654500000,
                "nonAxATE": 41.14761933140475,
                "nonAxBytes": 57202056,
                "axBytes": 81473603,
                "axAirtime": 1980032,
                "nonAxAirtime": 1391624,
                "axATE": 41.10453398331733,
                "timestamp": "2020-05-05T04:55:00.000+0000"
            },
            {
                "modificationtime": 1588654800000,
                "nonAxATE": 43.42276233770706,
                "nonAxBytes": 90359316,
                "axBytes": 65565071,
                "axAirtime": 1509924,
                "nonAxAirtime": 2788084,
                "axATE": 32.409108190427546,
                "timestamp": "2020-05-05T05:00:00.000+0000"
            },
            {
                "modificationtime": 1588655100000,
                "nonAxATE": 0,
                "nonAxBytes": 56621992,
                "axBytes": 0,
                "axAirtime": 0,
                "nonAxAirtime": 2444308,
                "axATE": 23.164835200801207,
                "timestamp": "2020-05-05T05:05:00.000+0000"
            },
            {
                "modificationtime": 1588655400000,
                "nonAxATE": 0,
                "nonAxBytes": 59054820,
                "axBytes": 0,
                "axAirtime": 0,
                "nonAxAirtime": 2516068,
                "axATE": 23.471074708632678,
                "timestamp": "2020-05-05T05:10:00.000+0000"
            },
            {
                "modificationtime": 1588655700000,
                "nonAxATE": 39.58380650786274,
                "nonAxBytes": 82327538,
                "axBytes": 79724003,
                "axAirtime": 2014056,
                "nonAxAirtime": 1949892,
                "axATE": 42.221588682860386,
                "timestamp": "2020-05-05T05:15:00.000+0000"
            },
            {
                "modificationtime": 1588656000000,
                "nonAxATE": 40.2364953293645,
                "nonAxBytes": 87221586,
                "axBytes": 83132623,
                "axAirtime": 2066100,
                "nonAxAirtime": 1972272,
                "axATE": 44.22391333446908,
                "timestamp": "2020-05-05T05:20:00.000+0000"
            },
            {
                "modificationtime": 1588656300000,
                "nonAxATE": 38.66116894357167,
                "nonAxBytes": 81247211,
                "axBytes": 77902874,
                "axAirtime": 2015016,
                "nonAxAirtime": 1853744,
                "axATE": 43.82871151572169,
                "timestamp": "2020-05-05T05:25:00.000+0000"
            },
            {
                "modificationtime": 1588656600000,
                "nonAxATE": 38.17985443416857,
                "nonAxBytes": 79369081,
                "axBytes": 75003255,
                "axAirtime": 1964472,
                "nonAxAirtime": 1824040,
                "axATE": 43.512796320256136,
                "timestamp": "2020-05-05T05:30:00.000+0000"
            },
            {
                "modificationtime": 1588656900000,
                "nonAxATE": 40.00325213478405,
                "nonAxBytes": 82773571,
                "axBytes": 78871532,
                "axAirtime": 1971628,
                "nonAxAirtime": 1893784,
                "axATE": 43.70803164458037,
                "timestamp": "2020-05-05T05:35:00.000+0000"
            },
            {
                "modificationtime": 1588657200000,
                "nonAxATE": 39.12853590825837,
                "nonAxBytes": 87020419,
                "axBytes": 78873268,
                "axAirtime": 2015748,
                "nonAxAirtime": 1996044,
                "axATE": 43.59644326477773,
                "timestamp": "2020-05-05T05:40:00.000+0000"
            },
            {
                "modificationtime": 1588657500000,
                "nonAxATE": 39.36080890996594,
                "nonAxBytes": 83692503,
                "axBytes": 78350839,
                "axAirtime": 1990580,
                "nonAxAirtime": 1878808,
                "axATE": 44.54553259300578,
                "timestamp": "2020-05-05T05:45:00.000+0000"
            },
            {
                "modificationtime": 1588657800000,
                "nonAxATE": 39.40231931110399,
                "nonAxBytes": 83206902,
                "axBytes": 79178015,
                "axAirtime": 2009476,
                "nonAxAirtime": 1898956,
                "axATE": 43.8171827046019,
                "timestamp": "2020-05-05T05:50:00.000+0000"
            },
            {
                "modificationtime": 1588658100000,
                "nonAxATE": 36.626895040905744,
                "nonAxBytes": 80559739,
                "axBytes": 71792963,
                "axAirtime": 1960116,
                "nonAxAirtime": 1843628,
                "axATE": 43.69630912526822,
                "timestamp": "2020-05-05T05:55:00.000+0000"
            },
            {
                "modificationtime": 1588658400000,
                "nonAxATE": 39.27812651558573,
                "nonAxBytes": 80531037,
                "axBytes": 71269375,
                "axAirtime": 1814480,
                "nonAxAirtime": 1939932,
                "axATE": 41.51229888470318,
                "timestamp": "2020-05-05T06:00:00.000+0000"
            },
            {
                "modificationtime": 1588658700000,
                "nonAxATE": 39.8121256051051,
                "nonAxBytes": 85450633,
                "axBytes": 80630224,
                "axAirtime": 2025268,
                "nonAxAirtime": 1919676,
                "axATE": 44.513049597952985,
                "timestamp": "2020-05-05T06:05:00.000+0000"
            },
            {
                "modificationtime": 1588659000000,
                "nonAxATE": 39.76208274299771,
                "nonAxBytes": 85970463,
                "axBytes": 81724122,
                "axAirtime": 2055328,
                "nonAxAirtime": 1951692,
                "axATE": 44.04919577474315,
                "timestamp": "2020-05-05T06:10:00.000+0000"
            },
            {
                "modificationtime": 1588659300000,
                "nonAxATE": 34.456936789267274,
                "nonAxBytes": 39255511,
                "axBytes": 72818258,
                "axAirtime": 2113312,
                "nonAxAirtime": 899524,
                "axATE": 43.64031532232603,
                "timestamp": "2020-05-05T06:15:00.000+0000"
            },
            {
                "modificationtime": 1588659600000,
                "nonAxATE": 38.88426169011524,
                "nonAxBytes": 77782680,
                "axBytes": 83831513,
                "axAirtime": 2155924,
                "nonAxAirtime": 1776508,
                "axATE": 43.78403024360149,
                "timestamp": "2020-05-05T06:20:00.000+0000"
            },
            {
                "modificationtime": 1588659900000,
                "nonAxATE": 37.285870407396914,
                "nonAxBytes": 83309466,
                "axBytes": 75264214,
                "axAirtime": 2018572,
                "nonAxAirtime": 1914624,
                "axATE": 43.5121809817489,
                "timestamp": "2020-05-05T06:25:00.000+0000"
            },
            {
                "modificationtime": 1588660200000,
                "nonAxATE": 39.224057137237544,
                "nonAxBytes": 82206279,
                "axBytes": 79769965,
                "axAirtime": 2033700,
                "nonAxAirtime": 1891464,
                "axATE": 43.461720127900925,
                "timestamp": "2020-05-05T06:30:00.000+0000"
            },
            {
                "modificationtime": 1588660500000,
                "nonAxATE": 39.5456608328919,
                "nonAxBytes": 81535710,
                "axBytes": 77639205,
                "axAirtime": 1963280,
                "nonAxAirtime": 1862112,
                "axATE": 43.786684152188485,
                "timestamp": "2020-05-05T06:35:00.000+0000"
            },
            {
                "modificationtime": 1588660800000,
                "nonAxATE": 38.95549674837224,
                "nonAxBytes": 82225956,
                "axBytes": 80124289,
                "axAirtime": 2056816,
                "nonAxAirtime": 1880216,
                "axATE": 43.73218608925783,
                "timestamp": "2020-05-05T06:40:00.000+0000"
            },
            {
                "modificationtime": 1588661100000,
                "nonAxATE": 38.89807898976162,
                "nonAxBytes": 77581044,
                "axBytes": 72535115,
                "axAirtime": 1864748,
                "nonAxAirtime": 1823452,
                "axATE": 42.5462496407912,
                "timestamp": "2020-05-05T06:45:00.000+0000"
            },
            {
                "modificationtime": 1588661400000,
                "nonAxATE": 40.19545275501825,
                "nonAxBytes": 83881058,
                "axBytes": 82044868,
                "axAirtime": 2041148,
                "nonAxAirtime": 1920480,
                "axATE": 43.677131758726986,
                "timestamp": "2020-05-05T06:50:00.000+0000"
            },
            {
                "modificationtime": 1588661700000,
                "nonAxATE": 39.4638171321943,
                "nonAxBytes": 77985399,
                "axBytes": 81211800,
                "axAirtime": 2057880,
                "nonAxAirtime": 1775436,
                "axATE": 43.92464667833704,
                "timestamp": "2020-05-05T06:55:00.000+0000"
            },
            {
                "modificationtime": 1588662000000,
                "nonAxATE": 40.70594427868392,
                "nonAxBytes": 79463142,
                "axBytes": 80001509,
                "axAirtime": 1965352,
                "nonAxAirtime": 1832004,
                "axATE": 43.374982805714396,
                "timestamp": "2020-05-05T07:00:00.000+0000"
            },
            {
                "modificationtime": 1588662300000,
                "nonAxATE": 39.940671712670046,
                "nonAxBytes": 82572805,
                "axBytes": 76535753,
                "axAirtime": 1916236,
                "nonAxAirtime": 1932184,
                "axATE": 42.73547705601537,
                "timestamp": "2020-05-05T07:05:00.000+0000"
            },
            {
                "modificationtime": 1588662600000,
                "nonAxATE": 40.03943979799844,
                "nonAxBytes": 78327013,
                "axBytes": 76367704,
                "axAirtime": 1907312,
                "nonAxAirtime": 1796344,
                "axATE": 43.60357091960115,
                "timestamp": "2020-05-05T07:10:00.000+0000"
            },
            {
                "modificationtime": 1588662900000,
                "nonAxATE": 39.564903303902476,
                "nonAxBytes": 75803913,
                "axBytes": 49812055,
                "axAirtime": 1258996,
                "nonAxAirtime": 2696052,
                "axATE": 28.116636103457946,
                "timestamp": "2020-05-05T07:15:00.000+0000"
            },
            {
                "modificationtime": 1588663200000,
                "nonAxATE": 39.282712807983565,
                "nonAxBytes": 83107690,
                "axBytes": 77561988,
                "axAirtime": 1974456,
                "nonAxAirtime": 1849436,
                "axATE": 44.93677531961095,
                "timestamp": "2020-05-05T07:20:00.000+0000"
            },
            {
                "modificationtime": 1588663500000,
                "nonAxATE": 39.62215419169661,
                "nonAxBytes": 84004109,
                "axBytes": 80231217,
                "axAirtime": 2024908,
                "nonAxAirtime": 1925928,
                "axATE": 43.617471161954136,
                "timestamp": "2020-05-05T07:25:00.000+0000"
            },
            {
                "modificationtime": 1588663800000,
                "nonAxATE": 39.24235137417568,
                "nonAxBytes": 71517816,
                "axBytes": 75836001,
                "axAirtime": 1932504,
                "nonAxAirtime": 1639996,
                "axATE": 43.60853075251403,
                "timestamp": "2020-05-05T07:30:00.000+0000"
            },
            {
                "modificationtime": 1588664100000,
                "nonAxATE": 39.05236008371778,
                "nonAxBytes": 81229874,
                "axBytes": 77099200,
                "axAirtime": 1974252,
                "nonAxAirtime": 1857216,
                "axATE": 43.73744034081119,
                "timestamp": "2020-05-05T07:35:00.000+0000"
            },
            {
                "modificationtime": 1588664400000,
                "nonAxATE": 39.30610677017694,
                "nonAxBytes": 83088621,
                "axBytes": 77971524,
                "axAirtime": 1983700,
                "nonAxAirtime": 1903668,
                "axATE": 43.64659226293661,
                "timestamp": "2020-05-05T07:40:00.000+0000"
            },
            {
                "modificationtime": 1588664700000,
                "nonAxATE": 39.65284388528786,
                "nonAxBytes": 73081436,
                "axBytes": 72945213,
                "axAirtime": 1839596,
                "nonAxAirtime": 1758760,
                "axATE": 41.55281903159044,
                "timestamp": "2020-05-05T07:45:00.000+0000"
            },
            {
                "modificationtime": 1588665000000,
                "nonAxATE": 39.39595451653291,
                "nonAxBytes": 83034193,
                "axBytes": 76776412,
                "axAirtime": 1948840,
                "nonAxAirtime": 1858636,
                "axATE": 44.674800767874935,
                "timestamp": "2020-05-05T07:50:00.000+0000"
            },
            {
                "modificationtime": 1588665300000,
                "nonAxATE": 39.652131158755225,
                "nonAxBytes": 86231517,
                "axBytes": 83469798,
                "axAirtime": 2105052,
                "nonAxAirtime": 1968156,
                "axATE": 43.81335473407596,
                "timestamp": "2020-05-05T07:55:00.000+0000"
            },
            {
                "modificationtime": 1588665600000,
                "nonAxATE": 43.71802641548104,
                "nonAxBytes": 116621538,
                "axBytes": 104093845,
                "axAirtime": 2381028,
                "nonAxAirtime": 2605320,
                "axATE": 44.76284602275344,
                "timestamp": "2020-05-05T08:00:00.000+0000"
            },
            {
                "modificationtime": 1588665900000,
                "nonAxATE": 38.82996804819228,
                "nonAxBytes": 80652144,
                "axBytes": 76318749,
                "axAirtime": 1965460,
                "nonAxAirtime": 1839052,
                "axATE": 43.85528196048834,
                "timestamp": "2020-05-05T08:05:00.000+0000"
            },
            {
                "modificationtime": 1588666200000,
                "nonAxATE": 39.30153021565082,
                "nonAxBytes": 79407396,
                "axBytes": 76244497,
                "axAirtime": 1939988,
                "nonAxAirtime": 1841668,
                "axATE": 43.117106883542526,
                "timestamp": "2020-05-05T08:10:00.000+0000"
            },
            {
                "modificationtime": 1588666500000,
                "nonAxATE": 38.58826422714039,
                "nonAxBytes": 81522371,
                "axBytes": 77512092,
                "axAirtime": 2008696,
                "nonAxAirtime": 1862196,
                "axATE": 43.777545972604386,
                "timestamp": "2020-05-05T08:15:00.000+0000"
            },
            {
                "modificationtime": 1588666800000,
                "nonAxATE": 41.774187577000774,
                "nonAxBytes": 82526246,
                "axBytes": 80563693,
                "axAirtime": 1928552,
                "nonAxAirtime": 1992836,
                "axATE": 41.41145884558488,
                "timestamp": "2020-05-05T08:20:00.000+0000"
            },
            {
                "modificationtime": 1588667100000,
                "nonAxATE": 40.097779585963856,
                "nonAxBytes": 86318921,
                "axBytes": 83380606,
                "axAirtime": 2079432,
                "nonAxAirtime": 2060640,
                "axATE": 41.889374660299715,
                "timestamp": "2020-05-05T08:25:00.000+0000"
            },
            {
                "modificationtime": 1588667400000,
                "nonAxATE": 39.623083030994835,
                "nonAxBytes": 92513830,
                "axBytes": 88724483,
                "axAirtime": 2239212,
                "nonAxAirtime": 2186076,
                "axATE": 42.31958541240103,
                "timestamp": "2020-05-05T08:30:00.000+0000"
            },
            {
                "modificationtime": 1588667700000,
                "nonAxATE": 36.44448696860344,
                "nonAxBytes": 65711580,
                "axBytes": 69038541,
                "axAirtime": 1894348,
                "nonAxAirtime": 1531176,
                "axATE": 42.91575886769385,
                "timestamp": "2020-05-05T08:35:00.000+0000"
            },
            {
                "modificationtime": 1588668000000,
                "nonAxATE": 40.13357706606595,
                "nonAxBytes": 83103922,
                "axBytes": 78675617,
                "axAirtime": 1960344,
                "nonAxAirtime": 1896452,
                "axATE": 43.82073577396106,
                "timestamp": "2020-05-05T08:40:00.000+0000"
            },
            {
                "modificationtime": 1588668300000,
                "nonAxATE": 38.444570937830456,
                "nonAxBytes": 80197154,
                "axBytes": 75474843,
                "axAirtime": 1963212,
                "nonAxAirtime": 1819836,
                "axATE": 44.068341323064274,
                "timestamp": "2020-05-05T08:45:00.000+0000"
            },
            {
                "modificationtime": 1588668600000,
                "nonAxATE": 41.13351779811152,
                "nonAxBytes": 83333534,
                "axBytes": 18017468,
                "axAirtime": 438024,
                "nonAxAirtime": 2521100,
                "axATE": 33.054434175558285,
                "timestamp": "2020-05-05T08:50:00.000+0000"
            },
            {
                "modificationtime": 1588668900000,
                "nonAxATE": 0,
                "nonAxBytes": 73810358,
                "axBytes": 0,
                "axAirtime": 0,
                "nonAxAirtime": 2373784,
                "axATE": 31.093965584063252,
                "timestamp": "2020-05-05T08:55:00.000+0000"
            },
            {
                "modificationtime": 1588669200000,
                "nonAxATE": 0,
                "nonAxBytes": 79762853,
                "axBytes": 0,
                "axAirtime": 0,
                "nonAxAirtime": 2661992,
                "axATE": 29.963596058891238,
                "timestamp": "2020-05-05T09:00:00.000+0000"
            },
            {
                "modificationtime": 1588669500000,
                "nonAxATE": 0,
                "nonAxBytes": 61451890,
                "axBytes": 0,
                "axAirtime": 0,
                "nonAxAirtime": 2531432,
                "axATE": 24.27554443492853,
                "timestamp": "2020-05-05T09:05:00.000+0000"
            },
            {
                "modificationtime": 1588669800000,
                "nonAxATE": 39.83703371596109,
                "nonAxBytes": 73793598,
                "axBytes": 80056981,
                "axAirtime": 2009612,
                "nonAxAirtime": 1960192,
                "axATE": 37.64610711603761,
                "timestamp": "2020-05-05T09:10:00.000+0000"
            },
            {
                "modificationtime": 1588670100000,
                "nonAxATE": 39.49970043381992,
                "nonAxBytes": 82597997,
                "axBytes": 78850092,
                "axAirtime": 1996220,
                "nonAxAirtime": 1999924,
                "axATE": 41.30056792158102,
                "timestamp": "2020-05-05T09:15:00.000+0000"
            },
            {
                "modificationtime": 1588670400000,
                "nonAxATE": 40.270840446113574,
                "nonAxBytes": 83998171,
                "axBytes": 82095491,
                "axAirtime": 2038584,
                "nonAxAirtime": 1910708,
                "axATE": 43.96180421079516,
                "timestamp": "2020-05-05T09:20:00.000+0000"
            },
            {
                "modificationtime": 1588670700000,
                "nonAxATE": 40.317090674171936,
                "nonAxBytes": 83791028,
                "axBytes": 80505973,
                "axAirtime": 1996820,
                "nonAxAirtime": 1921696,
                "axATE": 43.602644747140026,
                "timestamp": "2020-05-05T09:25:00.000+0000"
            },
            {
                "modificationtime": 1588671000000,
                "nonAxATE": 39.6280475708502,
                "nonAxBytes": 81871286,
                "axBytes": 78305022,
                "axAirtime": 1976000,
                "nonAxAirtime": 1904176,
                "axATE": 42.995650612128294,
                "timestamp": "2020-05-05T09:30:00.000+0000"
            },
            {
                "modificationtime": 1588671300000,
                "nonAxATE": 39.66158957261544,
                "nonAxBytes": 80690773,
                "axBytes": 70710427,
                "axAirtime": 1782844,
                "nonAxAirtime": 1925808,
                "axATE": 41.89969768533519,
                "timestamp": "2020-05-05T09:35:00.000+0000"
            },
            {
                "modificationtime": 1588671600000,
                "nonAxATE": 39.381448222555974,
                "nonAxBytes": 82626464,
                "axBytes": 75202656,
                "axAirtime": 1909596,
                "nonAxAirtime": 1879796,
                "axATE": 43.95501639539609,
                "timestamp": "2020-05-05T09:40:00.000+0000"
            },
            {
                "modificationtime": 1588671900000,
                "nonAxATE": 40.12370123495668,
                "nonAxBytes": 86002812,
                "axBytes": 82563503,
                "axAirtime": 2057724,
                "nonAxAirtime": 1952572,
                "axATE": 44.04591072697959,
                "timestamp": "2020-05-05T09:45:00.000+0000"
            },
            {
                "modificationtime": 1588672200000,
                "nonAxATE": 41.510121104855344,
                "nonAxBytes": 83599662,
                "axBytes": 83400807,
                "axAirtime": 2009168,
                "nonAxAirtime": 1875284,
                "axATE": 44.57973405628161,
                "timestamp": "2020-05-05T09:50:00.000+0000"
            },
            {
                "modificationtime": 1588672500000,
                "nonAxATE": 39.26873603782845,
                "nonAxBytes": 81112019,
                "axBytes": 77133065,
                "axAirtime": 1964236,
                "nonAxAirtime": 1888432,
                "axATE": 42.95204645970837,
                "timestamp": "2020-05-05T09:55:00.000+0000"
            },
            {
                "modificationtime": 1588672800000,
                "nonAxATE": 42.69055483752902,
                "nonAxBytes": 82985711,
                "axBytes": 74649558,
                "axAirtime": 1748620,
                "nonAxAirtime": 1961680,
                "axATE": 42.30338842216875,
                "timestamp": "2020-05-05T10:00:00.000+0000"
            },
            {
                "modificationtime": 1588673100000,
                "nonAxATE": 39.33854157937958,
                "nonAxBytes": 80205502,
                "axBytes": 75113326,
                "axAirtime": 1909408,
                "nonAxAirtime": 1859276,
                "axATE": 43.13802899623294,
                "timestamp": "2020-05-05T10:05:00.000+0000"
            },
            {
                "modificationtime": 1588673400000,
                "nonAxATE": 37.59969813860411,
                "nonAxBytes": 81896235,
                "axBytes": 75757075,
                "axAirtime": 2014832,
                "nonAxAirtime": 1851384,
                "axATE": 44.23514246639271,
                "timestamp": "2020-05-05T10:10:00.000+0000"
            },
            {
                "modificationtime": 1588673700000,
                "nonAxATE": 39.10440985986977,
                "nonAxBytes": 81294536,
                "axBytes": 79821642,
                "axAirtime": 2041244,
                "nonAxAirtime": 1868668,
                "axATE": 43.50400178094771,
                "timestamp": "2020-05-05T10:15:00.000+0000"
            },
            {
                "modificationtime": 1588674000000,
                "nonAxATE": 37.86733054573006,
                "nonAxBytes": 86186202,
                "axBytes": 74767378,
                "axAirtime": 1974456,
                "nonAxAirtime": 1979100,
                "axATE": 43.54817947551918,
                "timestamp": "2020-05-05T10:20:00.000+0000"
            },
            {
                "modificationtime": 1588674300000,
                "nonAxATE": 39.59875042450821,
                "nonAxBytes": 84219375,
                "axBytes": 80688473,
                "axAirtime": 2037652,
                "nonAxAirtime": 1921444,
                "axATE": 43.831293027535544,
                "timestamp": "2020-05-05T10:25:00.000+0000"
            },
            {
                "modificationtime": 1588674600000,
                "nonAxATE": 40.19478060426443,
                "nonAxBytes": 81681126,
                "axBytes": 80072344,
                "axAirtime": 1992108,
                "nonAxAirtime": 1866488,
                "axATE": 43.761934713751174,
                "timestamp": "2020-05-05T10:30:00.000+0000"
            },
            {
                "modificationtime": 1588674900000,
                "nonAxATE": 38.95353037336553,
                "nonAxBytes": 51501248,
                "axBytes": 77300788,
                "axAirtime": 1984436,
                "nonAxAirtime": 1240012,
                "axATE": 41.53286258520079,
                "timestamp": "2020-05-05T10:35:00.000+0000"
            },
            {
                "modificationtime": 1588675200000,
                "nonAxATE": 40.30630628769158,
                "nonAxBytes": 76357164,
                "axBytes": 58521371,
                "axAirtime": 1451916,
                "nonAxAirtime": 2460108,
                "axATE": 31.03813491115024,
                "timestamp": "2020-05-05T10:40:00.000+0000"
            },
            {
                "modificationtime": 1588675500000,
                "nonAxATE": 40.30630628769158,
                "nonAxBytes": 81209302,
                "axBytes": 58521371,
                "axAirtime": 1451916,
                "nonAxAirtime": 2666220,
                "axATE": 30.458590063835693,
                "timestamp": "2020-05-05T10:45:00.000+0000"
            },
            {
                "modificationtime": 1588675800000,
                "nonAxATE": 0,
                "nonAxBytes": 78060620,
                "axBytes": 0,
                "axAirtime": 0,
                "nonAxAirtime": 2507192,
                "axATE": 31.13467975328575,
                "timestamp": "2020-05-05T10:50:00.000+0000"
            },
            {
                "modificationtime": 1588676100000,
                "nonAxATE": 0,
                "nonAxBytes": 73179008,
                "axBytes": 0,
                "axAirtime": 0,
                "nonAxAirtime": 2454060,
                "axATE": 29.819567573734954,
                "timestamp": "2020-05-05T10:55:00.000+0000"
            },
            {
                "modificationtime": 1588676400000,
                "nonAxATE": 39.01766660631496,
                "nonAxBytes": 81123566,
                "axBytes": 76718565,
                "axAirtime": 1966252,
                "nonAxAirtime": 1877112,
                "axATE": 43.217221987819585,
                "timestamp": "2020-05-05T11:00:00.000+0000"
            },
            {
                "modificationtime": 1588676700000,
                "nonAxATE": 33.15880037748418,
                "nonAxBytes": 63299451,
                "axBytes": 59732263,
                "axAirtime": 1801400,
                "nonAxAirtime": 1430240,
                "axATE": 44.25792244658239,
                "timestamp": "2020-05-05T11:05:00.000+0000"
            },
            {
                "modificationtime": 1588677000000,
                "nonAxATE": 40.451688091611246,
                "nonAxBytes": 81766032,
                "axBytes": 78392459,
                "axAirtime": 1937928,
                "nonAxAirtime": 1826608,
                "axATE": 44.763863948915144,
                "timestamp": "2020-05-05T11:10:00.000+0000"
            },
            {
                "modificationtime": 1588677300000,
                "nonAxATE": 40.003727315758304,
                "nonAxBytes": 78560080,
                "axBytes": 80794888,
                "axAirtime": 2019684,
                "nonAxAirtime": 1781440,
                "axATE": 44.09920064666787,
                "timestamp": "2020-05-05T11:15:00.000+0000"
            },
            {
                "modificationtime": 1588677600000,
                "nonAxATE": 40.80423041133446,
                "nonAxBytes": 8425955,
                "axBytes": 81561944,
                "axAirtime": 1998860,
                "nonAxAirtime": 703196,
                "axATE": 11.982370491299722,
                "timestamp": "2020-05-05T11:20:00.000+0000"
            },
            {
                "modificationtime": 1588677900000,
                "nonAxATE": 42.425049582683585,
                "nonAxBytes": 82294029,
                "axBytes": 78462414,
                "axAirtime": 1849436,
                "nonAxAirtime": 1901532,
                "axATE": 43.27775130789279,
                "timestamp": "2020-05-05T11:25:00.000+0000"
            },
            {
                "modificationtime": 1588678200000,
                "nonAxATE": 41.36594830470045,
                "nonAxBytes": 69654438,
                "axBytes": 22258024,
                "axAirtime": 538076,
                "nonAxAirtime": 2356180,
                "axATE": 29.56244344659576,
                "timestamp": "2020-05-05T11:30:00.000+0000"
            },
            {
                "modificationtime": 1588678500000,
                "nonAxATE": 40.5955260843257,
                "nonAxBytes": 86884292,
                "axBytes": 78092964,
                "axAirtime": 1923684,
                "nonAxAirtime": 1936740,
                "axATE": 44.861102677695506,
                "timestamp": "2020-05-05T11:35:00.000+0000"
            },
            {
                "modificationtime": 1588678800000,
                "nonAxATE": 40.08175108752102,
                "nonAxBytes": 72483992,
                "axBytes": 67299184,
                "axAirtime": 1679048,
                "nonAxAirtime": 1895392,
                "axATE": 38.2422169134406,
                "timestamp": "2020-05-05T11:40:00.000+0000"
            },
            {
                "modificationtime": 1588679100000,
                "nonAxATE": 40.979266775443605,
                "nonAxBytes": 83790372,
                "axBytes": 80368538,
                "axAirtime": 1961200,
                "nonAxAirtime": 1961724,
                "axATE": 42.712620123931806,
                "timestamp": "2020-05-05T11:45:00.000+0000"
            },
            {
                "modificationtime": 1588679400000,
                "nonAxATE": 34.4720759307381,
                "nonAxBytes": 65714629,
                "axBytes": 71582093,
                "axAirtime": 2076524,
                "nonAxAirtime": 1498324,
                "axATE": 43.85875751840056,
                "timestamp": "2020-05-05T11:50:00.000+0000"
            },
            {
                "modificationtime": 1588679700000,
                "nonAxATE": 37.604716259788994,
                "nonAxBytes": 0,
                "axBytes": 78885820,
                "axAirtime": 2097764,
                "nonAxAirtime": 0,
                "axATE": 0,
                "timestamp": "2020-05-05T11:55:00.000+0000"
            },
            {
                "modificationtime": 1588680000000,
                "nonAxATE": 35.26495604987266,
                "nonAxBytes": 0,
                "axBytes": 69951990,
                "axAirtime": 1983612,
                "nonAxAirtime": 0,
                "axATE": 0,
                "timestamp": "2020-05-05T12:00:00.000+0000"
            },
            {
                "modificationtime": 1588680300000,
                "nonAxATE": 39.39238629763207,
                "nonAxBytes": 75587665,
                "axBytes": 81655217,
                "axAirtime": 2072868,
                "nonAxAirtime": 1874936,
                "axATE": 40.314797411751655,
                "timestamp": "2020-05-05T12:05:00.000+0000"
            },
            {
                "modificationtime": 1588680600000,
                "nonAxATE": 42.284305378101166,
                "nonAxBytes": 77965120,
                "axBytes": 81148487,
                "axAirtime": 1919116,
                "nonAxAirtime": 1790856,
                "axATE": 43.535113934342014,
                "timestamp": "2020-05-05T12:10:00.000+0000"
            },
            {
                "modificationtime": 1588680900000,
                "nonAxATE": 40.64901524591058,
                "nonAxBytes": 81587578,
                "axBytes": 77512469,
                "axAirtime": 1906872,
                "nonAxAirtime": 1860672,
                "axATE": 43.84844722766828,
                "timestamp": "2020-05-05T12:15:00.000+0000"
            },
            {
                "modificationtime": 1588681200000,
                "nonAxATE": 38.438035466339514,
                "nonAxBytes": 83470282,
                "axBytes": 76073331,
                "axAirtime": 1979116,
                "nonAxAirtime": 1910292,
                "axATE": 43.69503824546195,
                "timestamp": "2020-05-05T12:20:00.000+0000"
            },
            {
                "modificationtime": 1588681500000,
                "nonAxATE": 40.42846017875478,
                "nonAxBytes": 75755983,
                "axBytes": 74653254,
                "axAirtime": 1846552,
                "nonAxAirtime": 1870748,
                "axATE": 40.49502284647638,
                "timestamp": "2020-05-05T12:25:00.000+0000"
            },
            {
                "modificationtime": 1588681800000,
                "nonAxATE": 39.317944141867976,
                "nonAxBytes": 81176859,
                "axBytes": 79179105,
                "axAirtime": 2013816,
                "nonAxAirtime": 1853628,
                "axATE": 43.7935006376684,
                "timestamp": "2020-05-05T12:30:00.000+0000"
            },
            {
                "modificationtime": 1588682100000,
                "nonAxATE": 45.30741893416326,
                "nonAxBytes": 105711194,
                "axBytes": 113260392,
                "axAirtime": 2499820,
                "nonAxAirtime": 3164712,
                "axATE": 33.40310081928466,
                "timestamp": "2020-05-05T12:35:00.000+0000"
            },
            {
                "modificationtime": 1588682400000,
                "nonAxATE": 46.24419657985007,
                "nonAxBytes": 121730058,
                "axBytes": 124459041,
                "axAirtime": 2691344,
                "nonAxAirtime": 2675928,
                "axATE": 45.490782263199904,
                "timestamp": "2020-05-05T12:40:00.000+0000"
            },
            {
                "modificationtime": 1588682700000,
                "nonAxATE": 39.680757279965654,
                "nonAxBytes": 69372040,
                "axBytes": 77644925,
                "axAirtime": 1956740,
                "nonAxAirtime": 1748456,
                "axATE": 39.676171433539075,
                "timestamp": "2020-05-05T12:45:00.000+0000"
            },
            {
                "modificationtime": 1588683000000,
                "nonAxATE": 39.072739703022435,
                "nonAxBytes": 81825287,
                "axBytes": 75383193,
                "axAirtime": 1929304,
                "nonAxAirtime": 1939036,
                "axATE": 42.198951953444904,
                "timestamp": "2020-05-05T12:50:00.000+0000"
            },
            {
                "modificationtime": 1588683300000,
                "nonAxATE": 39.364465070442364,
                "nonAxBytes": 72540711,
                "axBytes": 78290410,
                "axAirtime": 1988860,
                "nonAxAirtime": 1692068,
                "axATE": 42.87103768879265,
                "timestamp": "2020-05-05T12:55:00.000+0000"
            },
            {
                "modificationtime": 1588683600000,
                "nonAxATE": 40.30473377572462,
                "nonAxBytes": 83378794,
                "axBytes": 79639252,
                "axAirtime": 1975928,
                "nonAxAirtime": 2001884,
                "axATE": 41.650162546880836,
                "timestamp": "2020-05-05T13:00:00.000+0000"
            },
            {
                "modificationtime": 1588683900000,
                "nonAxATE": 39.55441712615626,
                "nonAxBytes": 57626880,
                "axBytes": 78766293,
                "axAirtime": 1991340,
                "nonAxAirtime": 1314200,
                "axATE": 43.8493988738396,
                "timestamp": "2020-05-05T13:05:00.000+0000"
            },
            {
                "modificationtime": 1588684200000,
                "nonAxATE": 39.36144469154922,
                "nonAxBytes": 82703612,
                "axBytes": 79503663,
                "axAirtime": 2019836,
                "nonAxAirtime": 1874528,
                "axATE": 44.11969946567883,
                "timestamp": "2020-05-05T13:10:00.000+0000"
            },
            {
                "modificationtime": 1588684500000,
                "nonAxATE": 45.74153072952148,
                "nonAxBytes": 110614971,
                "axBytes": 122740445,
                "axAirtime": 2683348,
                "nonAxAirtime": 2481632,
                "axATE": 44.5734786624286,
                "timestamp": "2020-05-05T13:15:00.000+0000"
            },
            {
                "modificationtime": 1588684800000,
                "nonAxATE": 40.0318131369489,
                "nonAxBytes": 82051867,
                "axBytes": 79928639,
                "axAirtime": 1996628,
                "nonAxAirtime": 1891512,
                "axATE": 43.37898305694069,
                "timestamp": "2020-05-05T13:20:00.000+0000"
            },
            {
                "modificationtime": 1588685100000,
                "nonAxATE": 39.473852361357245,
                "nonAxBytes": 81732766,
                "axBytes": 78852178,
                "axAirtime": 1997580,
                "nonAxAirtime": 1847848,
                "axATE": 44.231325303812866,
                "timestamp": "2020-05-05T13:25:00.000+0000"
            },
            {
                "modificationtime": 1588685400000,
                "nonAxATE": 40.07598699732606,
                "nonAxBytes": 71112964,
                "axBytes": 76436930,
                "axAirtime": 1907300,
                "nonAxAirtime": 1863884,
                "axATE": 38.15310609458528,
                "timestamp": "2020-05-05T13:30:00.000+0000"
            },
            {
                "modificationtime": 1588685700000,
                "nonAxATE": 48.43056139929074,
                "nonAxBytes": 62497827,
                "axBytes": 3222957,
                "axAirtime": 66548,
                "nonAxAirtime": 2533356,
                "axATE": 24.66997413707351,
                "timestamp": "2020-05-05T13:35:00.000+0000"
            },
            {
                "modificationtime": 1588686000000,
                "nonAxATE": 39.82051992921885,
                "nonAxBytes": 85079874,
                "axBytes": 79572230,
                "axAirtime": 1998272,
                "nonAxAirtime": 1952372,
                "axATE": 43.5776962587048,
                "timestamp": "2020-05-05T13:40:00.000+0000"
            },
            {
                "modificationtime": 1588686300000,
                "nonAxATE": 39.98144431754692,
                "nonAxBytes": 64365093,
                "axBytes": 77856986,
                "axAirtime": 1947328,
                "nonAxAirtime": 1474488,
                "axATE": 43.65250378436447,
                "timestamp": "2020-05-05T13:45:00.000+0000"
            },
            {
                "modificationtime": 1588686600000,
                "nonAxATE": 38.297939764250096,
                "nonAxBytes": 79763496,
                "axBytes": 74337833,
                "axAirtime": 1941040,
                "nonAxAirtime": 1841068,
                "axATE": 43.32457899436631,
                "timestamp": "2020-05-05T13:50:00.000+0000"
            },
            {
                "modificationtime": 1588686900000,
                "nonAxATE": 40.251555748306465,
                "nonAxBytes": 80294609,
                "axBytes": 71279548,
                "axAirtime": 1770852,
                "nonAxAirtime": 1925672,
                "axATE": 41.69692917589288,
                "timestamp": "2020-05-05T13:55:00.000+0000"
            },
            {
                "modificationtime": 1588687200000,
                "nonAxATE": 38.95551372150033,
                "nonAxBytes": 79439781,
                "axBytes": 74433235,
                "axAirtime": 1910724,
                "nonAxAirtime": 1826040,
                "axATE": 43.50385588486561,
                "timestamp": "2020-05-05T14:00:00.000+0000"
            },
            {
                "modificationtime": 1588687500000,
                "nonAxATE": 41.10437380843208,
                "nonAxBytes": 82266906,
                "axBytes": 54503742,
                "axAirtime": 1325984,
                "nonAxAirtime": 2160308,
                "axATE": 38.081100472710375,
                "timestamp": "2020-05-05T14:05:00.000+0000"
            },
            {
                "modificationtime": 1588687800000,
                "nonAxATE": 37.19728543510563,
                "nonAxBytes": 35619869,
                "axBytes": 73699428,
                "axAirtime": 1981312,
                "nonAxAirtime": 1348528,
                "axATE": 26.413889070156497,
                "timestamp": "2020-05-05T14:10:00.000+0000"
            },
            {
                "modificationtime": 1588688100000,
                "nonAxATE": 38.52378064040378,
                "nonAxBytes": 0,
                "axBytes": 80661246,
                "axAirtime": 2093804,
                "nonAxAirtime": 0,
                "axATE": 0,
                "timestamp": "2020-05-05T14:15:00.000+0000"
            },
            {
                "modificationtime": 1588688400000,
                "nonAxATE": 35.75964448634801,
                "nonAxBytes": 0,
                "axBytes": 75097399,
                "axAirtime": 2100060,
                "nonAxAirtime": 0,
                "axATE": 0,
                "timestamp": "2020-05-05T14:20:00.000+0000"
            },
            {
                "modificationtime": 1588688700000,
                "nonAxATE": 40.74303193972128,
                "nonAxBytes": 85840954,
                "axBytes": 78210976,
                "axAirtime": 1919616,
                "nonAxAirtime": 1929144,
                "axATE": 44.496913657041674,
                "timestamp": "2020-05-05T14:25:00.000+0000"
            },
            {
                "modificationtime": 1588689000000,
                "nonAxATE": 40.528957088998155,
                "nonAxBytes": 79613467,
                "axBytes": 78604129,
                "axAirtime": 1939456,
                "nonAxAirtime": 1848744,
                "axATE": 43.06354314064035,
                "timestamp": "2020-05-05T14:30:00.000+0000"
            },
            {
                "modificationtime": 1588689300000,
                "nonAxATE": 39.9339422717738,
                "nonAxBytes": 85614762,
                "axBytes": 77825503,
                "axAirtime": 1948856,
                "nonAxAirtime": 1910792,
                "axATE": 44.805903520634374,
                "timestamp": "2020-05-05T14:35:00.000+0000"
            },
            {
                "modificationtime": 1588689600000,
                "nonAxATE": 40.92366814385694,
                "nonAxBytes": 72702741,
                "axBytes": 75501876,
                "axAirtime": 1844944,
                "nonAxAirtime": 1920548,
                "axATE": 37.85520643066458,
                "timestamp": "2020-05-05T14:40:00.000+0000"
            },
            {
                "modificationtime": 1588689900000,
                "nonAxATE": 40.35981244526093,
                "nonAxBytes": 73375887,
                "axBytes": 75574556,
                "axAirtime": 1872520,
                "nonAxAirtime": 1883944,
                "axATE": 38.94801915555876,
                "timestamp": "2020-05-05T14:45:00.000+0000"
            },
            {
                "modificationtime": 1588690200000,
                "nonAxATE": 41.39230012946091,
                "nonAxBytes": 77256319,
                "axBytes": 81338850,
                "axAirtime": 1965072,
                "nonAxAirtime": 1966496,
                "axATE": 39.28628331814557,
                "timestamp": "2020-05-05T14:50:00.000+0000"
            },
            {
                "modificationtime": 1588690500000,
                "nonAxATE": 39.96709937986218,
                "nonAxBytes": 84096108,
                "axBytes": 82056132,
                "axAirtime": 2053092,
                "nonAxAirtime": 1904704,
                "axATE": 44.15179891468701,
                "timestamp": "2020-05-05T14:55:00.000+0000"
            },
            {
                "modificationtime": 1588690800000,
                "nonAxATE": 38.242942726838415,
                "nonAxBytes": 76440528,
                "axBytes": 76206559,
                "axAirtime": 1992696,
                "nonAxAirtime": 1717284,
                "axATE": 44.51245571495454,
                "timestamp": "2020-05-05T15:00:00.000+0000"
            },
            {
                "modificationtime": 1588691100000,
                "nonAxATE": 39.682752088055175,
                "nonAxBytes": 79026574,
                "axBytes": 78508198,
                "axAirtime": 1978396,
                "nonAxAirtime": 1860464,
                "axATE": 42.47680901108541,
                "timestamp": "2020-05-05T15:05:00.000+0000"
            },
            {
                "modificationtime": 1588691400000,
                "nonAxATE": 41.98024092645807,
                "nonAxBytes": 69333535,
                "axBytes": 52475637,
                "axAirtime": 1250008,
                "nonAxAirtime": 2402396,
                "axATE": 28.860160856078682,
                "timestamp": "2020-05-05T15:10:00.000+0000"
            },
            {
                "modificationtime": 1588691700000,
                "nonAxATE": 41.71350066585488,
                "nonAxBytes": 74114737,
                "axBytes": 81941667,
                "axAirtime": 1964392,
                "nonAxAirtime": 1654056,
                "axATE": 44.8078765168773,
                "timestamp": "2020-05-05T15:15:00.000+0000"
            },
            {
                "modificationtime": 1588692000000,
                "nonAxATE": 39.786276813947524,
                "nonAxBytes": 79826738,
                "axBytes": 74486276,
                "axAirtime": 1872160,
                "nonAxAirtime": 1791708,
                "axATE": 44.5534305813224,
                "timestamp": "2020-05-05T15:20:00.000+0000"
            },
            {
                "modificationtime": 1588692300000,
                "nonAxATE": 41.28874629197238,
                "nonAxBytes": 80364312,
                "axBytes": 79503628,
                "axAirtime": 1925552,
                "nonAxAirtime": 1896164,
                "axATE": 42.38257450304931,
                "timestamp": "2020-05-05T15:25:00.000+0000"
            },
            {
                "modificationtime": 1588692600000,
                "nonAxATE": 40.90580429256565,
                "nonAxBytes": 82579201,
                "axBytes": 78400719,
                "axAirtime": 1916616,
                "nonAxAirtime": 1843620,
                "axATE": 44.79187739338909,
                "timestamp": "2020-05-05T15:30:00.000+0000"
            },
            {
                "modificationtime": 1588692900000,
                "nonAxATE": 41.365125610431456,
                "nonAxBytes": 85874245,
                "axBytes": 82163549,
                "axAirtime": 1986300,
                "nonAxAirtime": 1915472,
                "axATE": 44.83189782988214,
                "timestamp": "2020-05-05T15:35:00.000+0000"
            },
            {
                "modificationtime": 1588693200000,
                "nonAxATE": 41.077898857761014,
                "nonAxBytes": 80898534,
                "axBytes": 78527962,
                "axAirtime": 1911684,
                "nonAxAirtime": 1838396,
                "axATE": 44.00495540677852,
                "timestamp": "2020-05-05T15:40:00.000+0000"
            },
            {
                "modificationtime": 1588693500000,
                "nonAxATE": 38.937918029348474,
                "nonAxBytes": 54088957,
                "axBytes": 80199028,
                "axAirtime": 2059664,
                "nonAxAirtime": 1187600,
                "axATE": 45.54476002020883,
                "timestamp": "2020-05-05T15:45:00.000+0000"
            },
            {
                "modificationtime": 1588693800000,
                "nonAxATE": 41.56845246367719,
                "nonAxBytes": 88009087,
                "axBytes": 83748460,
                "axAirtime": 2014712,
                "nonAxAirtime": 1951180,
                "axATE": 45.10557047530212,
                "timestamp": "2020-05-05T15:50:00.000+0000"
            },
            {
                "modificationtime": 1588694100000,
                "nonAxATE": 40.53911426125607,
                "nonAxBytes": 79480799,
                "axBytes": 77854396,
                "axAirtime": 1920476,
                "nonAxAirtime": 1788936,
                "axATE": 44.429090252529996,
                "timestamp": "2020-05-05T15:55:00.000+0000"
            },
            {
                "modificationtime": 1588694400000,
                "nonAxATE": 39.41351899550541,
                "nonAxBytes": 80899076,
                "axBytes": 79202570,
                "axAirtime": 2009528,
                "nonAxAirtime": 2007960,
                "axATE": 40.28918703559832,
                "timestamp": "2020-05-05T16:00:00.000+0000"
            },
            {
                "modificationtime": 1588694700000,
                "nonAxATE": 41.45732429086486,
                "nonAxBytes": 79523701,
                "axBytes": 76351125,
                "axAirtime": 1841680,
                "nonAxAirtime": 2020996,
                "axATE": 39.34876714253764,
                "timestamp": "2020-05-05T16:05:00.000+0000"
            },
            {
                "modificationtime": 1588695000000,
                "nonAxATE": 37.23846707716754,
                "nonAxBytes": 78710415,
                "axBytes": 32995963,
                "axAirtime": 886072,
                "nonAxAirtime": 2458748,
                "axATE": 32.01239614633138,
                "timestamp": "2020-05-05T16:10:00.000+0000"
            },
            {
                "modificationtime": 1588695300000,
                "nonAxATE": 39.09901494004163,
                "nonAxBytes": 76829254,
                "axBytes": 75748242,
                "axAirtime": 1937344,
                "nonAxAirtime": 1882708,
                "axATE": 40.807843807961724,
                "timestamp": "2020-05-05T16:15:00.000+0000"
            },
            {
                "modificationtime": 1588695600000,
                "nonAxATE": 40.317996139852426,
                "nonAxBytes": 85741230,
                "axBytes": 80716467,
                "axAirtime": 2001996,
                "nonAxAirtime": 2055016,
                "axATE": 41.72290142753146,
                "timestamp": "2020-05-05T16:20:00.000+0000"
            },
            {
                "modificationtime": 1588695900000,
                "nonAxATE": 40.035164623010694,
                "nonAxBytes": 80672951,
                "axBytes": 76727393,
                "axAirtime": 1916500,
                "nonAxAirtime": 1940568,
                "axATE": 41.57182381653207,
                "timestamp": "2020-05-05T16:25:00.000+0000"
            },
            {
                "modificationtime": 1588696200000,
                "nonAxATE": 40.172746466357324,
                "nonAxBytes": 73139689,
                "axBytes": 75044458,
                "axAirtime": 1868044,
                "nonAxAirtime": 1939372,
                "axATE": 37.713078769828584,
                "timestamp": "2020-05-05T16:30:00.000+0000"
            },
            {
                "modificationtime": 1588696500000,
                "nonAxATE": 39.390934177704175,
                "nonAxBytes": 77617350,
                "axBytes": 76682174,
                "axAirtime": 1946696,
                "nonAxAirtime": 1901424,
                "axATE": 40.82064284452074,
                "timestamp": "2020-05-05T16:35:00.000+0000"
            },
            {
                "modificationtime": 1588696800000,
                "nonAxATE": 40.178968849996735,
                "nonAxBytes": 77898828,
                "axBytes": 73831266,
                "axAirtime": 1837560,
                "nonAxAirtime": 2124960,
                "axATE": 36.65896205105037,
                "timestamp": "2020-05-05T16:40:00.000+0000"
            },
            {
                "modificationtime": 1588697100000,
                "nonAxATE": 36.31469517461196,
                "nonAxBytes": 75800640,
                "axBytes": 60886380,
                "axAirtime": 1676632,
                "nonAxAirtime": 1766876,
                "axATE": 42.90093928493001,
                "timestamp": "2020-05-05T16:45:00.000+0000"
            },
            {
                "modificationtime": 1588697400000,
                "nonAxATE": 36.968917056270605,
                "nonAxBytes": 66147693,
                "axBytes": 71451009,
                "axAirtime": 1932732,
                "nonAxAirtime": 1521720,
                "axATE": 43.46903043923981,
                "timestamp": "2020-05-05T16:50:00.000+0000"
            },
            {
                "modificationtime": 1588697700000,
                "nonAxATE": 40.36217034406474,
                "nonAxBytes": 82422809,
                "axBytes": 83196120,
                "axAirtime": 2061240,
                "nonAxAirtime": 1912420,
                "axATE": 43.098696416059234,
                "timestamp": "2020-05-05T16:55:00.000+0000"
            },
            {
                "modificationtime": 1588698000000,
                "nonAxATE": 39.208445052203935,
                "nonAxBytes": 82646286,
                "axBytes": 78906682,
                "axAirtime": 2012492,
                "nonAxAirtime": 1914708,
                "axATE": 43.163911155121305,
                "timestamp": "2020-05-05T17:00:00.000+0000"
            },
            {
                "modificationtime": 1588698300000,
                "nonAxATE": 40.07533731543368,
                "nonAxBytes": 80518127,
                "axBytes": 75572949,
                "axAirtime": 1885772,
                "nonAxAirtime": 1865244,
                "axATE": 43.167610779072334,
                "timestamp": "2020-05-05T17:05:00.000+0000"
            },
            {
                "modificationtime": 1588698600000,
                "nonAxATE": 39.73242864905374,
                "nonAxBytes": 78885265,
                "axBytes": 78678632,
                "axAirtime": 1980212,
                "nonAxAirtime": 1805072,
                "axATE": 43.70200468457768,
                "timestamp": "2020-05-05T17:10:00.000+0000"
            },
            {
                "modificationtime": 1588698900000,
                "nonAxATE": 41.04081544028951,
                "nonAxBytes": 87555770,
                "axBytes": 85057090,
                "axAirtime": 2072500,
                "nonAxAirtime": 2096560,
                "axATE": 41.761633342236806,
                "timestamp": "2020-05-05T17:15:00.000+0000"
            },
            {
                "modificationtime": 1588699200000,
                "nonAxATE": 40.405790224252556,
                "nonAxBytes": 84230788,
                "axBytes": 80807055,
                "axAirtime": 1999888,
                "nonAxAirtime": 2034112,
                "axATE": 41.40912004845357,
                "timestamp": "2020-05-05T17:20:00.000+0000"
            },
            {
                "modificationtime": 1588699500000,
                "nonAxATE": 40.78493047860743,
                "nonAxBytes": 78700928,
                "axBytes": 73026560,
                "axAirtime": 1790528,
                "nonAxAirtime": 2075816,
                "axATE": 37.91324857309126,
                "timestamp": "2020-05-05T17:25:00.000+0000"
            },
            {
                "modificationtime": 1588699800000,
                "nonAxATE": 40.794519842637506,
                "nonAxBytes": 79046108,
                "axBytes": 77647310,
                "axAirtime": 1903376,
                "nonAxAirtime": 1933604,
                "axATE": 40.880194703775956,
                "timestamp": "2020-05-05T17:30:00.000+0000"
            },
            {
                "modificationtime": 1588700100000,
                "nonAxATE": 38.53620887318901,
                "nonAxBytes": 82901120,
                "axBytes": 74487563,
                "axAirtime": 1932924,
                "nonAxAirtime": 1992332,
                "axATE": 41.610093096933646,
                "timestamp": "2020-05-05T17:35:00.000+0000"
            },
            {
                "modificationtime": 1588700400000,
                "nonAxATE": 39.439113075576095,
                "nonAxBytes": 84606080,
                "axBytes": 80090900,
                "axAirtime": 2030748,
                "nonAxAirtime": 2012748,
                "axATE": 42.035108220204414,
                "timestamp": "2020-05-05T17:40:00.000+0000"
            },
            {
                "modificationtime": 1588700700000,
                "nonAxATE": 40.467807929616086,
                "nonAxBytes": 79271887,
                "axBytes": 70834851,
                "axAirtime": 1750400,
                "nonAxAirtime": 1938608,
                "axATE": 40.89113786799601,
                "timestamp": "2020-05-05T17:45:00.000+0000"
            },
            {
                "modificationtime": 1588701000000,
                "nonAxATE": 40.97864317173953,
                "nonAxBytes": 74189084,
                "axBytes": 65017207,
                "axAirtime": 1586612,
                "nonAxAirtime": 1869464,
                "axATE": 39.68468181254092,
                "timestamp": "2020-05-05T17:50:00.000+0000"
            },
            {
                "modificationtime": 1588701300000,
                "nonAxATE": 42.851721005401444,
                "nonAxBytes": 83233497,
                "axBytes": 83681355,
                "axAirtime": 1952812,
                "nonAxAirtime": 2080308,
                "axATE": 40.01017974261504,
                "timestamp": "2020-05-05T17:55:00.000+0000"
            },
            {
                "modificationtime": 1588701600000,
                "nonAxATE": 40.63486612955392,
                "nonAxBytes": 86874855,
                "axBytes": 78397985,
                "axAirtime": 1929328,
                "nonAxAirtime": 2042800,
                "axATE": 42.52734237321324,
                "timestamp": "2020-05-05T18:00:00.000+0000"
            },
            {
                "modificationtime": 1588701900000,
                "nonAxATE": 41.99563942692765,
                "nonAxBytes": 83077208,
                "axBytes": 83681687,
                "axAirtime": 1992628,
                "nonAxAirtime": 2003360,
                "axATE": 41.46893618720549,
                "timestamp": "2020-05-05T18:05:00.000+0000"
            },
            {
                "modificationtime": 1588702200000,
                "nonAxATE": 40.10108408765733,
                "nonAxBytes": 76016264,
                "axBytes": 77243831,
                "axAirtime": 1926228,
                "nonAxAirtime": 1962336,
                "axATE": 38.73763922182542,
                "timestamp": "2020-05-05T18:10:00.000+0000"
            },
            {
                "modificationtime": 1588702500000,
                "nonAxATE": 40.29819053374376,
                "nonAxBytes": 82758830,
                "axBytes": 76820763,
                "axAirtime": 1906308,
                "nonAxAirtime": 1952832,
                "axATE": 42.37887846983253,
                "timestamp": "2020-05-05T18:15:00.000+0000"
            },
            {
                "modificationtime": 1588702800000,
                "nonAxATE": 39.97664664563808,
                "nonAxBytes": 81170748,
                "axBytes": 77766091,
                "axAirtime": 1945288,
                "nonAxAirtime": 1825752,
                "axATE": 44.458802728957714,
                "timestamp": "2020-05-05T18:20:00.000+0000"
            },
            {
                "modificationtime": 1588703100000,
                "nonAxATE": 41.78811999483337,
                "nonAxBytes": 83487541,
                "axBytes": 77645670,
                "axAirtime": 1858080,
                "nonAxAirtime": 1827960,
                "axATE": 45.67252073349526,
                "timestamp": "2020-05-05T18:25:00.000+0000"
            }
        ],
        "insights": -8.5
    }
})