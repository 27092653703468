define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/site/AssuranceSiteTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/site/SiteHierarchy1',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/site/SiteDetails1',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/site/GroupSiteDetail',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/DefaultConfig',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/ApplicationHealthTrend',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/SimulationUtility'
], function(SimLokiDatabaseActions, AssuranceSiteTemplate, SiteHierarchyTemplate, SiteDetailsTemplate,
            GroupSiteDetail, DefaultConfig,ApplicationHealthTrend, UtilityFunctions, SimulationUtility){

  function getSiteName(siteId) {
     var obj = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'id' : siteId});
     return obj.length ? obj[0].name : (siteId==DefaultConfig.DEFAULT_UUID.GlobalGroup ? "Global" : '');
  }

  function getHostCountFromNetwotkDevices(siteId, hostCount) {

      var networkDevices = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'siteId' : siteId });
      var i, j, iWired = 0, iWireless = 0;
      for(i = 0; i < networkDevices.length; i++) {
          var id = networkDevices[i].id;
          var tHosts = SimLokiDatabaseActions.getFilteredRecordHandler('host',{'connectedNetworkDeviceId' : id });
          for(j = 0; j < tHosts.length; j++) {
              if(tHosts[j].hostType == 'WIRED') {
                  iWired++;
              } else if(tHosts[j].hostType == 'WIRELESS') {
                  iWireless++;
              }
          }
      }

      hostCount.wiredHostCount += iWired;
      hostCount.wirelessHostCount += iWireless;
      return hostCount;
  }

  function countOfHostsAssociatedWithSite(siteId) {
      var bKeepCounting = true;
      var  i, j;
      var hostCount = { wiredHostCount : 0,  wirelessHostCount : 0 ,wiredHealthyHostCount : 0,  wirelessHealthyHostCount : 0 };
      var siteList =[];
       siteList.push(siteId);
       getSiteWithChild(siteId,siteList);

      var networkDevices = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'siteId' : { '$containsAny' :  siteList} });
      var i, j, iWired = 0, iWireless = 0,iwiredHealthyHostCount=0,iwirelessHealthyHostCount=0;
      for(i = 0; i < networkDevices.length; i++) {
          var id = networkDevices[i].id;
          var tHosts = SimLokiDatabaseActions.getFilteredRecordHandler('host',{'connectedNetworkDeviceId' : id });
          for(j = 0; j < tHosts.length; j++) {
              if(tHosts[j].hostType == 'WIRED') {
                  iWired++;
                  if(tHosts[j].healthScore[0].score>7){
                    iwiredHealthyHostCount++;
                  }
              } else if(tHosts[j].hostType == 'WIRELESS') {
                  iWireless++;
                  if(tHosts[j].healthScore[0].score>7){
                    iwirelessHealthyHostCount++;
                  }
              }
          }
      }

      hostCount.wiredHostCount = iWired;
      hostCount.wirelessHostCount = iWireless;
      hostCount.wiredHealthyHostCount = iwiredHealthyHostCount;
      hostCount.wirelessHealthyHostCount = iwirelessHealthyHostCount;
      return hostCount;
  }

  function countOfDeviceAssociatedWithSite(siteId) {
       //search till lowest child to count no of devices..
      var bKeepCounting = true;
      var deviceCount = 0, i;
      var objs = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'siteId' : siteId });
      deviceCount += objs.length;

      var tSites = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'parentId' : siteId });
      for(i = 0; i < tSites.length; i++) {
          var objs = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'siteId' : tSites[i].id });
          deviceCount += objs.length;

          var t = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'parentId' : tSites[i].id });
          if(t.length) {
              var objs = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'siteId' : t[0].id });
              deviceCount += objs.length;
          }
      }
      return deviceCount;
  }

  function getAssociatedDeviceHealthScore(siteId, rolename,type) {
            var percentage = null;
            var successDeviceCount = 0;
            var deviceCount = 0;
            var siteList=[];
            siteList.push(siteId);
            getSiteWithChild(siteId,siteList);
            var allDeviceObj;
            var successDeviceObj;
            if(type=="ALL") {
                allDeviceObj=SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'siteId': { '$containsAny' :  siteList} });
                successDeviceObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { '$and': [{'siteId': { '$containsAny' :  siteList}}, { 'assuranceHealthScore': {'$gte': 8} }] });

            } else {
               if(rolename=="WIRELESS") {
                 allDeviceObj=SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { '$and': [{'siteId': { '$containsAny' :  siteList}}, { 'family': { '$containsAny' :  ["Unified AP","Wireless Controller"]} }] });
                successDeviceObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { '$and': [{'siteId': { '$containsAny' :  siteList}}, { 'family': { '$containsAny' :  ["Unified AP","Wireless Controller"]} }, { 'assuranceHealthScore': {'$gte': 8} }] });

               } else {
                allDeviceObj=SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { '$and': [{'siteId': { '$containsAny' :  siteList}}, { 'role': rolename },{ 'family': { '$containsNone' :  ["Unified AP","Wireless Controller"]} }] });
                successDeviceObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { '$and': [{'siteId': { '$containsAny' :  siteList}}, { 'role': rolename }, { 'assuranceHealthScore': {'$gte': 8} },{ 'family': { '$containsNone' :  ["Unified AP","Wireless Controller"]} }] });
               }
            }
            deviceCount = allDeviceObj.length;
            successDeviceCount = successDeviceObj.length;
            if (deviceCount > 0) {
                percentage = (successDeviceCount/deviceCount)*100;
            }
            return percentage;
        }

  function getAllBuilding(isValidateSiteType, validSiteTypes) {
      var networkDevices =  SimLokiDatabaseActions.getAll('network-device');
      var i, siteAssociatedDeviceCount = 0, iWiredHostCount = 0, iWirelessHostCount = 0;

      var networkDevices =  SimLokiDatabaseActions.getAll('network-device');
      for(i=0; i < networkDevices.length; i++){
          if(networkDevices[i].siteId != '') {
              siteAssociatedDeviceCount++;
          }
      }

      var hostDevices =  SimLokiDatabaseActions.getAll('host');
      for(i=0; i < hostDevices.length; i++){
          if(hostDevices[i].connectedNetworkDeviceId != '') {
              if(hostDevices[i].hostType == 'WIRED') {
                  iWiredHostCount++;
              } else if(hostDevices[i].hostType == 'WIRELESS') {
                  iWirelessHostCount++;
              }
          }
      }

      var applicationScoreTemplate=ApplicationHealthTrend.Template.response;

      var tResult = [];
      if(!isValidateSiteType || (isValidateSiteType && validSiteTypes.indexOf('area')>-1)) {
        var tSite =  JSON.parse(JSON.stringify( SiteHierarchyTemplate.Area_Template  ));

        var  networkHealthAccess = roundN(getAssociatedDeviceHealthScore(DefaultConfig.DEFAULT_UUID.GlobalGroup, "ACCESS",""));
        var  networkHealthAverage= roundN(getAssociatedDeviceHealthScore(DefaultConfig.DEFAULT_UUID.GlobalGroup, "","ALL"));
        var  networkHealthCore = roundN(getAssociatedDeviceHealthScore(DefaultConfig.DEFAULT_UUID.GlobalGroup, "CORE",""));
        var  networkHealthDistribution = roundN(getAssociatedDeviceHealthScore(DefaultConfig.DEFAULT_UUID.GlobalGroup, "DISTRIBUTION",""));
        var  networkHealthRouter = roundN(getAssociatedDeviceHealthScore(DefaultConfig.DEFAULT_UUID.GlobalGroup, "BORDER ROUTER",""));
        var  networkHealthWireless = roundN(getAssociatedDeviceHealthScore(DefaultConfig.DEFAULT_UUID.GlobalGroup, "WIRELESS",""));
        tSite.siteName = ' All Sites';
        tSite.siteId = '80b4fcb8-6f56-4986-b520-3b6ce972464a';
        tSite.parentSiteId = null;
        tSite.parentSiteName = null;
        tSite.networkHealthAccess =  networkHealthAccess;
        tSite.networkHealthAverage = networkHealthAverage;
        tSite.networkHealthCore = networkHealthCore;
        tSite.networkHealthDistribution =networkHealthDistribution;
        tSite.networkHealthRouter =networkHealthRouter;
        tSite.networkHealthWireless=networkHealthWireless;
        tSite.healthyNetworkDevicePercentage = networkHealthAverage;


        tSite.clientHealthWired=95;
        tSite.clientHealthWireless=90;
        tSite.healthyClientsPercentage=90;
        tSite.numberOfNetworkDevice = siteAssociatedDeviceCount;
        tSite.numberOfClients = 306
        tSite.clientIssueCount = iWiredHostCount + iWirelessHostCount;
        tSite.numberOfWiredClients = iWiredHostCount;
        tSite.numberOfWirelessClients = iWirelessHostCount;

        tSite.applicationHealth=applicationScoreTemplate.trend[0].healthyAppsPercent;
        tSite.applicationGoodCount=applicationScoreTemplate.trend[0].numberOfGoodApps;
        var totalAps=applicationScoreTemplate.trend[0].numberOfGoodApps+applicationScoreTemplate.trend[0].numberOfFairApps+applicationScoreTemplate.trend[0].numberOfPoorApps;
        tSite.applicationTotalCount=totalAps;
        tSite.applicationBytesTotalCount=90;

        tResult.push(tSite);
      }

      if(!isValidateSiteType || (isValidateSiteType && validSiteTypes.indexOf('building')>-1)) {
        var tBldg =  JSON.parse(JSON.stringify( SiteHierarchyTemplate.Building_Template ));
        tBldg.siteName = ' All Buildings';
        tBldg.siteId = ' All Buildings';
        if(isValidateSiteType) {
            //for type application
            tBldg.siteName = 'All Buildings';
            tBldg.siteId = '__global__';
        }
        tBldg.parentSiteId = null;
        tBldg.parentSiteName = null;
        tBldg.networkHealthAccess = networkHealthAccess;
        tBldg.networkHealthAverage = networkHealthAverage;
        tBldg.networkHealthCore = networkHealthCore;
        tBldg.networkHealthDistribution = networkHealthDistribution;
        tBldg.networkHealthRouter = networkHealthRouter;
        tBldg.networkHealthWireless=networkHealthWireless;
        tBldg.healthyNetworkDevicePercentage = networkHealthAverage;

        tBldg.clientHealthWired=89;
        tBldg.clientHealthWireless=99;
        tBldg.healthyClientsPercentage=91;
        tBldg.numberOfNetworkDevice = siteAssociatedDeviceCount;

        tBldg.numberOfClients = 306;
        tBldg.clientIssueCount = iWiredHostCount + iWirelessHostCount;
        tBldg.numberOfWiredClients = iWiredHostCount;
        tBldg.numberOfWirelessClients = iWirelessHostCount;

        var appLocationData = getAppData(tBldg.siteId, true);
        var totalApp = appLocationData.unknown + appLocationData.good + appLocationData.fair + appLocationData.poor;
        tBldg.applicationHealth = appLocationData.brHealth;
        tBldg.applicationGoodCount = appLocationData.good;
        tBldg.applicationTotalCount = totalApp;
        tBldg.applicationBytesTotalCount = appLocationData.totalUsage;

        tResult.push(tBldg);
      }

      return tResult;
  }

  function roundN(num){
            if(num == null){
                return num;
            }
            else{
                var flooring = Math.round(num);
                return flooring;
            }
        }


    function getSiteWithChild(siteId,siteList) {

            var siteAllData = SimLokiDatabaseActions.getAll('site');
            var flag = false;

            for(var site in siteAllData) {
                var siteInfoObj = siteAllData[site];
                if(siteInfoObj.parentId == siteId) {
                    siteList.push(siteInfoObj.id);
                    getSiteWithChild(siteInfoObj.id,siteList);

                }
             }
       return ;
     }

    //to get counts on application based on site/global and bussiness relevance and health details
    function getAppData(siteId, isGlobal) {
        var childrenSites = [], siteIdList = [], appHealthCount = {'unknown':0, 'poor':0, 'fair':0, 'good':0, 'totalUsage':0};
        var appData = [], brGoodFair = 0, brTotal = 0;
        if(!isGlobal) {
            SimulationUtility.getChildrenSites(siteId, childrenSites);
            siteIdList.push(siteId);
            for (var i = 0; i < childrenSites.length; i++) {
                siteIdList.push(childrenSites[i].id);
            }
            appData = SimLokiDatabaseActions.getFilteredRecordHandler('application', { 'clientSiteId': { '$in': siteIdList } });
        } else {
            appData = SimLokiDatabaseActions.getAll('application');
        }
        for (var i = 0; i < appData.length; i++) {
            var healthScore = appData[i].healthScore;
            if(healthScore == null){
                appHealthCount.unknown ++;
            }else if (healthScore >= 0 && healthScore <= 3) {
                appHealthCount.poor++;
            } else if (healthScore >= 4 && healthScore <= 7) {
                appHealthCount.fair++;
            } else if (healthScore >= 8 && healthScore <= 10){
                appHealthCount.good++;
            }
            appHealthCount.totalUsage += appData[i].usage;
            if(appData[i].businessRelevance == 'business-relevant') {
                brTotal++;
                if (healthScore >= 2 && healthScore <= 8) {
                    brGoodFair++;
                }
            }
        }
        appHealthCount.brHealth = Math.round(brGoodFair*100/brTotal);
        return appHealthCount;
    }

  return {

      init: function(){
      },

      getSiteHierarchyData: function(urlAction) {


          if (urlAction.restPayload.skipGlobal) {

              var sites = urlAction.restPayload.sites;
              var result = []
              sites.forEach(siteId => {
                  var siteIds = siteId.split('/')
                  var requieredsiteId = siteIds[siteIds.length - 2]
                  recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'id': requieredsiteId });
                  recordObj[0].siteName = recordObj[0].name
                  result.push(recordObj[0])
              })
              return result;
          }
         


          var tResult = [], reqSiteType = urlAction.restPayload.type, isValidateSiteType = false, validSiteTypes;
          if(reqSiteType && reqSiteType=='application') {
              isValidateSiteType = true;
              validSiteTypes = ['building'];
          }
          tResult = getAllBuilding(isValidateSiteType, validSiteTypes);

          var applicationScoreTemplate=ApplicationHealthTrend.Template.response;

          recordObj = SimLokiDatabaseActions.getAll('site');
                for (var i = 0, len = recordObj.length; i < len; i++) {
                    if (recordObj[i].id == DefaultConfig.DEFAULT_UUID.GlobalGroup) {
                        continue;
                    }

                    var tSite = undefined;
                    var siteType = "";
                    for (var j = 0; j < recordObj[i].additionalInfo.length; j++) {
                        if (recordObj[i].additionalInfo[j].nameSpace == "Location") {
                            siteType = recordObj[i].additionalInfo[j].attributes.type;
                            var deviceCount = 0, hostCount = 0;

                            if(isValidateSiteType && validSiteTypes.indexOf(siteType)==-1) {continue;}

                            if (siteType == 'building') {
                                tSite = JSON.parse(JSON.stringify(SiteHierarchyTemplate.Building_Template));
                                tSite.areaId = recordObj[i].parentId;
                                deviceCount = countOfDeviceAssociatedWithSite(recordObj[i].id);
                                hostCount = countOfHostsAssociatedWithSite(recordObj[i].id);
                            }
                            else if (siteType == 'floor') {
                                tSite = JSON.parse(JSON.stringify(SiteHierarchyTemplate.Floor_Template));
                                deviceCount = countOfDeviceAssociatedWithSite(recordObj[i].id);
                                hostCount = countOfHostsAssociatedWithSite(recordObj[i].id);
                                if(hostCount.wirelessHostCount===0){
                                    hostCount = countOfHostsAssociatedWithSite(recordObj[i].parentId);
                                    hostCount.wiredHostCount=0;
                                    hostCount.wiredHealthyHostCount=0;
                                 }
                            }
                            else if (siteType == 'area') {
                                var id = recordObj[i].id;
                                var tObj = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'parentId': id });
                                if (tObj.length == 0) {
                                    continue; //site doesn't have child, hence skip it..
                                }
                                tSite = JSON.parse(JSON.stringify(SiteHierarchyTemplate.Area_Template));
                                deviceCount = countOfDeviceAssociatedWithSite(recordObj[i].id);
                                hostCount = countOfHostsAssociatedWithSite(recordObj[i].id);

                            }
                            tSite.networkHealthCore = roundN(getAssociatedDeviceHealthScore(recordObj[i].id, "CORE",""));
                            tSite.networkHealthAccess = roundN(getAssociatedDeviceHealthScore(recordObj[i].id, "ACCESS",""));
                            tSite.networkHealthDistribution = roundN(getAssociatedDeviceHealthScore(recordObj[i].id, "DISTRIBUTION",""));
                            tSite.networkHealthRouter = roundN(getAssociatedDeviceHealthScore(recordObj[i].id, "BORDER ROUTER",""));
                            tSite.networkHealthWireless = roundN(getAssociatedDeviceHealthScore(recordObj[i].id, "WIRELESS",""));
                            tSite.networkHealthOthers = roundN(getAssociatedDeviceHealthScore(recordObj[i].id, "OTHERS",""));

                            tSite.networkHealthAverage = roundN(getAssociatedDeviceHealthScore(recordObj[i].id, "","ALL"));
                            tSite.healthyNetworkDevicePercentage = roundN(getAssociatedDeviceHealthScore(recordObj[i].id, "","ALL"));
                            tSite.siteName = recordObj[i].name;
                            tSite.siteId = recordObj[i].id;
                            tSite.parentSiteId = recordObj[i].parentId;
                            tSite.parentSiteName = getSiteName(recordObj[i].parentId);
                            tSite.numberOfNetworkDevice = deviceCount;
                            tSite.numberOfClients = hostCount.wiredHostCount + hostCount.wirelessHostCount;
                            tSite.clientIssueCount = hostCount.wiredHostCount + hostCount.wirelessHostCount;
                            var totalHealthyClient=hostCount.wiredHealthyHostCount+hostCount.wirelessHealthyHostCount;
                            var totalClientCount=hostCount.wiredHostCount+hostCount.wirelessHostCount;

                          /*  tSite.clientHealthWired=roundN(hostCount.wiredHealthyHostCount/hostCount.wiredHostCount*100);
                            tSite.clientHealthWireless=roundN(hostCount.wirelessHealthyHostCount/hostCount.wirelessHostCount*100);
                            tSite.healthyClientsPercentage=roundN(totalHealthyClient/totalClientCount*100); */
                           // For client alone is static Data
                            if(siteType=="floor" &&  hostCount.wirelessHostCount>0) {
                              //  tSite.numberOfClients =250;
                              //  tSite.clientHealthWireless=99;
                              //  tSite.healthyClientsPercentage=99;
                            }

                            if(hostCount.wiredHostCount>0 &&  hostCount.wirelessHostCount>0) {
                                tSite.clientHealthWired=parseInt(hostCount.wiredHealthyHostCount/hostCount.wiredHostCount*100);
                                tSite.clientHealthWireless=parseInt(hostCount.wirelessHealthyHostCount/hostCount.wirelessHostCount*100);
                                tSite.healthyClientsPercentage=parseInt(totalHealthyClient/totalClientCount*100);
                            }

                            if(hostCount.wiredHostCount>0 && hostCount.wirelessHostCount<=0){
                                tSite.clientHealthWired=parseInt(hostCount.wiredHealthyHostCount/hostCount.wiredHostCount*100);
                                tSite.healthyClientsPercentage=parseInt(hostCount.wiredHealthyHostCount/hostCount.wiredHostCount*100);
                            }

                            if(hostCount.wiredHostCount<=0 && hostCount.wirelessHostCount>0){
                                tSite.clientHealthWireless=parseInt(hostCount.wirelessHealthyHostCount/hostCount.wirelessHostCount*100);
                                tSite.healthyClientsPercentage=parseInt(hostCount.wirelessHealthyHostCount/hostCount.wirelessHostCount*100);
                            }


                            tSite.numberOfWiredClients = hostCount.wiredHostCount;
                            tSite.numberOfWirelessClients = hostCount.wirelessHostCount;
                             // Added for default red not to  come
                            tSite.networkHealthCore = tSite.networkHealthCore==0?null:tSite.networkHealthCore;
                            tSite.networkHealthAccess = tSite.networkHealthAccess==0?null:tSite.networkHealthAccess;
                            tSite.networkHealthDistribution = tSite.networkHealthDistribution==0?null:tSite.networkHealthDistribution;
                            tSite.networkHealthRouter = tSite.networkHealthRouter==0?null:tSite.networkHealthRouter;
                            tSite.networkHealthWireless = tSite.networkHealthWireless==0?null:tSite.networkHealthWireless;
                            tSite.networkHealthWired = tSite.networkHealthWired==0?null:tSite.networkHealthWired;
                            tSite.clientHealthWireless=tSite.clientHealthWireless==0?null:tSite.clientHealthWireless;
                            tSite.clientHealthWired=tSite.clientHealthWired==0?null:tSite.clientHealthWired;
                            tSite.networkHealthOthers = tSite.networkHealthOthers==0?null:tSite.networkHealthOthers;

                            var appLocationData = getAppData(recordObj[i].id, false);
                            var totalApp = appLocationData.unknown + appLocationData.good + appLocationData.fair + appLocationData.poor;
                            tSite.applicationHealth = appLocationData.brHealth;
                            tSite.applicationGoodCount = appLocationData.good;
                            tSite.applicationTotalCount = totalApp;
                            tSite.applicationBytesTotalCount = appLocationData.totalUsage;
                        }
                    }

                    if(tSite) {
                        tResult.push(tSite);
                    }
                }
                return { "response": tResult, "version": "1.0" };
      },

      getSitesDetailForNetworkDeviceId: function(urlAction){
          var tId = urlAction.action.id, siteId = urlAction.action.id;
          //var data = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id' : tId });
          //if(data && data.length){
            //  siteId = data[0].siteId;
              //var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'id' : siteId });
              //if(recordObj && recordObj.length){
                //  urlAction.action.id = siteId;//recordObj[0].id;
                  return this.getSiteDetail(urlAction);
              //}
          //}
          return [];
      },

      getSitesDetail: function(urlAction){
          var recordObj;
          var tResult = [];

          if(urlAction.filter['siteType'] == 'floor'){
              return [];
          }

          if(urlAction.action.bAllSite){
              recordObj = SimLokiDatabaseActions.getAll('site'); //corection code commented for time being
              //return SiteDetailsTemplate.Site_Details_Template;
          }else if(urlAction.action.id != '') {
              recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'id' : urlAction.action.id});//correct code commented for time being..
              /*var t = SiteDetailsTemplate.Site_Details_Template; //Demo static data for Cisco live
              recordObj = [];
              for(var i = 0; i < SiteDetailsTemplate.Site_Details_Template.length; i++) {
                  if(SiteDetailsTemplate.Site_Details_Template[i].id == urlAction.action.id) {
                      recordObj.push(SiteDetailsTemplate.Site_Details_Template[i]);
                      break;
                  }
              }*/
          } else {
              return [];
          }

          if (!recordObj || recordObj.length == 0) {
              return [];
          }

          for(var i = 0, len = recordObj.length; i < len; i++){
              var tSite =  JSON.parse(JSON.stringify( AssuranceSiteTemplate.Assurance_Site_Template ));
             // var siteType = recordObj[i].additionalInfo.length == 2 ? 'area' : 'building';
              var siteType ="";
              for(var j=0;j<recordObj[i].additionalInfo.length ;j++)  {
                  if(recordObj[i].additionalInfo[j].nameSpace== "Location") {
                      siteType =recordObj[i].additionalInfo[j].attributes.type;

                      if(siteType == 'building'){
                          tSite.properties.latitude[0].value = recordObj[i].additionalInfo[j].attributes.latitude;
                          tSite.properties.longitude[0].value = recordObj[i].additionalInfo[j].attributes.longitude;
                      }
                      else {
                        delete tSite.properties.latitude;
                        delete tSite.properties.longitude;
                      }
                  }
              }

              tSite.id = recordObj[i].id;
              tSite.properties.siteHierarchy[0].value = recordObj[i].groupNameHierarchy;
              tSite.properties.name[0].value  = recordObj[i].name;
              tSite.properties.siteType[0].value = siteType;
              tResult.push(tSite);
          }

          if(urlAction.action.count){
              return tResult.length;
          }

          if(urlAction.action.metricAction == 2 || urlAction.action.metricAction == 4){
              return getOverallMetricScore(urlAction, tResult);
          }

          //console.log(tResult);

          return tResult;
      },

      processPostRequest: function(urlAction){
          alert(urlAction.url);
          return [];
      }
  };

    function  getOverallMetricScore(urlAction, siteArray){
        var i = 0, len = siteArray.length;
        var buildingArray = [];
        for(i=0; i < len; i++){
           if(siteArray[i].properties.siteType[0].value == 'building'){
               t = { metrics: [], site: { id: siteArray[i].id, isDeleted:false, label:'site',
                             timestamp: siteArray[i].timestamp, properties: siteArray[i].properties }};
               if(urlAction.action.metricAction == 2){
                   t.metrics.push(5);
               }
               else if(urlAction.action.metricAction == 4) {
                 var arr = [5, 0, 0, 5, 10, 0];
                 var ii, len1;
                 for(ii=0, len1 = arr.length; ii < len1; ii++){
                     t.metrics.push(arr[ii]);
                 }
               }
               buildingArray.push(t);
           }
        }
        return buildingArray;
    }

});
