define({
"switchTemplate":[
    {
      "name": "Switch 1 Fixed Module 0",
      "description": "Switch 1 - C9300-24U - Fixed Module 0",
      "vendorEquipmentType": "cevContainerFixedLinkModuleSlot",
      "isReportingAlarmsAllowed": "UNKNOWN",
      "serialNumber": "",
      "manufacturer": "",
      "partNumber": "",
      "entityPhysicalIndex": "1060",
      "containmentEntity": "1000",
      "isFieldReplaceable": "FALSE",
      "id": "6c13f09e-41c8-4e63-85f8-3a89e7030732"
    },
    {
      "name": "Switch 1 FRU Uplink Module 1",
      "description": "2x40G Uplink Module",
      "vendorEquipmentType": "cevModuleC9300NM2X40G",
      "isReportingAlarmsAllowed": "UNKNOWN",
      "serialNumber": "FOC21317YW9",
      "manufacturer": "",
      "partNumber": "C9300-NM-2Q",
      "entityPhysicalIndex": "1086",
      "containmentEntity": "1061",
      "isFieldReplaceable": "TRUE",
      "id": "6e3ad4fd-416a-487b-b3e3-9aade17f2500"
    }
  ],
"routerTemplate":[
    {
      "name": "module F0",
      "description": "Cisco ISR4431 Forwarding Processor",
      "vendorEquipmentType": "cevModuleISR4431FP",
      "assemblyNumber": "800-40617-06",
      "assemblyRevision": "",
      "isReportingAlarmsAllowed": "UNKNOWN",
      "serialNumber": "",
      "manufacturer": "Cisco Systems Inc",
      "partNumber": "ISR4431/K9",
      "entityPhysicalIndex": "9000",
      "containmentEntity": "1",
      "operationalStateCode": "enabled",
      "isFieldReplaceable": "FALSE",
      "id": "234172eb-2cb5-4ad2-a152-77e267b157a7"
    },
    {
      "name": "NIM subslot 0/0",
      "description": "Front Panel 4 ports Gigabitethernet Module",
      "vendorEquipmentType": "cevModuleISR44000FP4xGE",
      "assemblyNumber": "",
      "assemblyRevision": "",
      "isReportingAlarmsAllowed": "UNKNOWN",
      "serialNumber": "",
      "manufacturer": "Cisco Systems Inc",
      "partNumber": "ISR4431-X-4x1GE",
      "entityPhysicalIndex": "1015",
      "containmentEntity": "1000",
      "operationalStateCode": "enabled",
      "isFieldReplaceable": "FALSE",
      "id": "29bddeb9-b13f-4345-adcc-0381eca94d5f"
    },
    {
      "name": "module R0",
      "description": "Cisco ISR4431 Route Processor",
      "vendorEquipmentType": "cevModuleISR4431RP",
      "assemblyNumber": "800-40617-06",
      "assemblyRevision": "",
      "isReportingAlarmsAllowed": "UNKNOWN",
      "serialNumber": "FOC22467AHU",
      "manufacturer": "Cisco Systems Inc",
      "partNumber": "ISR4431/K9",
      "entityPhysicalIndex": "7000",
      "containmentEntity": "1",
      "operationalStateCode": "enabled",
      "isFieldReplaceable": "FALSE",
      "id": "7cb2a687-630e-4279-87de-402cde327ac1"
    },
    {
      "name": "module 0",
      "description": "Cisco ISR4431 Built-In NIM controller",
      "vendorEquipmentType": "cevModuleISR4431NIM",
      "assemblyNumber": "800-40617-06",
      "assemblyRevision": "",
      "isReportingAlarmsAllowed": "UNKNOWN",
      "serialNumber": "",
      "manufacturer": "Cisco Systems Inc",
      "partNumber": "ISR4431/K9",
      "entityPhysicalIndex": "1000",
      "containmentEntity": "1",
      "operationalStateCode": "enabled",
      "isFieldReplaceable": "FALSE",
      "id": "8f8bd13a-14d1-42d3-9eee-529e7ad5bf33"
    }
  ],
"wlcTemplate":[
    {
      "name": "subslot 0/0 transceiver 2",
      "description": "10GE CU1M",
      "vendorEquipmentType": "cevSFPH10GBCU1M",
      "isReportingAlarmsAllowed": "UNKNOWN",
      "serialNumber": "LRM192140VG",
      "manufacturer": "CISCO-LOROM",
      "partNumber": "SFP-H10GB-CU1M",
      "entityPhysicalIndex": "1116",
      "containmentEntity": "1115",
      "operationalStateCode": "Enabled",
      "isFieldReplaceable": "TRUE",
      "id": "0fdc56b3-9355-416a-8101-d6d329f44220"
    },
    {
      "name": "module 0",
      "description": "Cisco C9800-40-K9 Modular Interface Processor",
      "vendorEquipmentType": "cevModuleC980040K9MIP",
      "isReportingAlarmsAllowed": "UNKNOWN",
      "serialNumber": "",
      "manufacturer": "Cisco Systems Inc",
      "partNumber": "C9800-40-K9",
      "entityPhysicalIndex": "1000",
      "containmentEntity": "2",
      "operationalStateCode": "Disabled",
      "isFieldReplaceable": "FALSE",
      "id": "38281b4d-c9a8-49a4-8ada-e10de3063066"
    },
    {
      "name": "module R0",
      "description": "Cisco C9800-40-K9 Route Processor",
      "vendorEquipmentType": "cevModuleC980040K9RP",
      "isReportingAlarmsAllowed": "UNKNOWN",
      "serialNumber": "TTM224105LK",
      "manufacturer": "Cisco Systems Inc",
      "partNumber": "C9800-40-K9",
      "entityPhysicalIndex": "2000",
      "containmentEntity": "2",
      "operationalStateCode": "Disabled",
      "isFieldReplaceable": "FALSE",
      "id": "3cf0d5d9-e4d2-4fc8-a24b-168530687924"
    },
    {
      "name": "harddisk",
      "description": "Hard Disk",
      "vendorEquipmentType": "cevHardDisk",
      "isReportingAlarmsAllowed": "UNKNOWN",
      "serialNumber": "",
      "manufacturer": "",
      "partNumber": "",
      "entityPhysicalIndex": "2057",
      "containmentEntity": "2000",
      "operationalStateCode": "Disabled",
      "isFieldReplaceable": "FALSE",
      "id": "464b2d7f-4677-4313-961b-0a2ddb32377f"
    },
    {
      "name": "SPA subslot 0/0",
      "description": "4-port 10G/1G multirate Ethernet Port Adapter",
      "vendorEquipmentType": "cevModuleASR1000BUILTIN4X10G1G",
      "isReportingAlarmsAllowed": "UNKNOWN",
      "serialNumber": "JAE87654321",
      "manufacturer": "CISCO",
      "partNumber": "BUILT-IN-4X10G/1G",
      "entityPhysicalIndex": "1040",
      "containmentEntity": "1000",
      "operationalStateCode": "Disabled",
      "isFieldReplaceable": "FALSE",
      "id": "59e2ab89-5273-45b4-a731-8ce272279e5c"
    },
    {
      "name": "module F0",
      "description": "Cisco C9800-40-K9 Embedded Services Processor",
      "vendorEquipmentType": "cevModuleC980040K9ESP",
      "isReportingAlarmsAllowed": "UNKNOWN",
      "serialNumber": "",
      "manufacturer": "Cisco Systems Inc",
      "partNumber": "C9800-40-K9",
      "entityPhysicalIndex": "3000",
      "containmentEntity": "2",
      "operationalStateCode": "Disabled",
      "isFieldReplaceable": "FALSE",
      "id": "7f5693e9-27ef-45b9-b300-5453dfff139a"
    },
    {
      "name": "subslot 0/0 transceiver 3",
      "description": "10GE CU1M",
      "vendorEquipmentType": "cevSFPH10GBCU1M",
      "isReportingAlarmsAllowed": "UNKNOWN",
      "serialNumber": "LRM19214152",
      "manufacturer": "CISCO-LOROM",
      "partNumber": "SFP-H10GB-CU1M",
      "entityPhysicalIndex": "1128",
      "containmentEntity": "1127",
      "operationalStateCode": "Enabled",
      "isFieldReplaceable": "TRUE",
      "id": "8d522e3f-02a3-43e2-9ba0-67927a096849"
    },
    {
      "name": "subslot 0/0 transceiver 0",
      "description": "10GE SR",
      "vendorEquipmentType": "cevSFP10GSR",
      "isReportingAlarmsAllowed": "UNKNOWN",
      "serialNumber": "FNS18081H7K",
      "manufacturer": "CISCO-FINISAR",
      "partNumber": "SFP-10G-SR",
      "entityPhysicalIndex": "1092",
      "containmentEntity": "1091",
      "operationalStateCode": "Enabled",
      "isFieldReplaceable": "TRUE",
      "id": "d2564543-b27a-45dc-a7b7-dae30972e6d7"
    },
    {
      "name": "subslot 0/0 transceiver 1",
      "description": "10GE SR",
      "vendorEquipmentType": "cevSFP10GSR",
      "isReportingAlarmsAllowed": "UNKNOWN",
      "serialNumber": "SPC160605RW",
      "manufacturer": "CISCO-SUMITOMO",
      "partNumber": "SFP-10G-SR",
      "entityPhysicalIndex": "1104",
      "containmentEntity": "1103",
      "operationalStateCode": "Enabled",
      "isFieldReplaceable": "TRUE",
      "id": "de24abc5-4ccb-4733-b0b5-20027ba6e1fc"
    }
  ]
})