define({
    "Sensor_Template":  {

          "attributes": {
            "id": null,
            "instanceUuid": "075a8b36-a557-468c-b429-44f618dd0c5f",
            "name": "SENSOR_70:f3:5a:78:6b:60",
            "ipAddress": "10.13.1.204",
            "macaddress": "70:f3:5a:78:6b:60",
            "baseRadioMacaddress": null,
            "type": 1,
            "typeString": "FARALLON",
            "status": null,
            "adminStatus": null,
            "mode": null,
            "model": "AIR-AP1800S-B-K9",
            "wlc": null,
            "locationGroup": null,
            "domain": null,
            "mneId": null,
            "generatesCoverageHeatmap": null,
            "generatesIdsHeatmap": null,
            "generatesAqHeatmap": null,
            "gpsAware": null,
            "lastUpdated": null,
            "siteName": null,
            "buildingName": null,
            "floorName": null,
            "outdoorAreaName": null,
            "heirarchyName": null,
            "controllerIpAddress": null,
            "reachabilityStatus": "Reachable"
          },
          "location": null,
          "position": {
            "x": 93.238434,
            "y": 28.825623,
            "z": 10.0
          },
          "metrics": null,
          "radios": null,
          "rxNeighbors": null,
          "tests": {
            "items": [

            ],
            "total": 4
          },
          "isAvailable": false,
          "isAutoAp": null,
          "isSensor": true,
          "isRadioSensor": false
        }

    });