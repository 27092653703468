define({
    "WLC_TEMPLATE": {
        "icapFeatureResponseCode": "SUCCESS",
        "icapFeature": "AP_STATISTIC",
        "wlcDeviceId": "53d2f176-34ab-43da-acc9-749f6e745cab",
        "detailStatus": []
    },

    "AP_DETAIL_TEMPLATE": {
        "wlcDeviceId": "53d2f176-34ab-43da-acc9-749f6e745cab",
        "target": {
            "feature": "AP-STATISTIC",
            "isAllProtocol": null,
            "protocolType": null,
            "protocolSubType": null,
            "apStatisticType": "RADIO",
            "adTimeoutType": null,
            "adPacketTrace": null,
            "parentChannelName": null,
            "filterChannelName": null,
            "slot": null,
            "scope": "AP4800-5",
            "isFilter": null,
            "macAddress": null,
            "operation": "CREATE"
        },
        "lastUpdatedTime": 1544166091442,
        "state": "ENABLE",
        "status": "SUCCESS",
        "errorString": "",
        "errorCodeString": null
      } 
});