define({
    "getEventTemplate":
    {
        "version": "1.0",
        "response": [{
            "id": "6cbc70bc-71ad-46bd-983a-03550653b088",
            "name": "connected_misbehaving_dual_band_capable_client_preferring_2_4ghz",
            "flattened": true,
            "enabled": true,
            "entityType": "Client",
            "entityName": "Unknown",
            "entity": "E8:84:A5:2C:40:4D",
            "groupBy": "Unknown",
            "severity": "MEDIUM",
            "category": "Connected",
            "summary": "Insufficient 5 GHz Coverage - Dual Band capable client prefers 2.4 GHz over 5 GHz",
            "rootCause": "poor_5G_coverage",
            "timestamp": 1668653487139,
            "startTime": 1668036076089,
            "occurrences": 20,
            "instances": 1,
            "scope": "LOCALIZED",
            "priority": "P4",
            "status": {
                "status": "ACTIVE",
                "updatedBy": "Unknown",
                "notes": "Unknown",
                "source": "Unknown",
                "updatedAt": null,
                "ignoreSource": "Unknown",
                "ignoreValue": "Unknown",
                "ignoreStartTime": null,
                "ignoreEndTime": null
            },
            "totalOccurrences": {
                "count": 20,
                "startTime": 1668036076089,
                "endTime": 1668653487139
            }
        }]
    }
})