define({
"Template":
[
  {
    "id": "a977ab34-2b6d-442e-880c-ff09c3971ba8",
    "label": "NetworkDevice",
    "timestamp": "2020-02-25T01:08:51.596+0000",
    "isDeleted": false,
    "type": "vertex",
    "properties": {
      "managementIpAddr": [{"value": "10.0.100.101"}],
      "haStatus": [{"value": "Non-redundant"}],
      "serialNumber": [{"value": "FCW2239G0XC"}],
      "communicationState": [{"value": "REACHABLE"}],
      "nwDeviceName": [{"value": "SF-D9300-1"}],
      "platformId": [{ "value": "C9300-24U"}],
      "nwDeviceId": [ {  "value": "a977ab34-2b6d-442e-880c-ff09c3971ba8" } ],
      "nwDeviceRole": [  { "value": "DISTRIBUTION" }],
      "nwDeviceFamily": [{"value": "Switches and Hubs"}],
      "macAddress": [ {"value": "0C:D0:F8:C3:D2:00" }],
      "collectionStatus": [{ "value": "SUCCESS" }],
      "deviceSeries": [{"value": "Cisco Catalyst 9300 Series Switches"}],
      "osType": [{"value": "IOS-XE" }],
      "ringStatus": [{"value": false}],
      "lastBootTime": [{"value": 1.557418371496E12}],
      "stackType": [{"value": "STACK"}],
      "softwareVersion": [{"value": "16.8.1a"}],
      "nwDeviceType": [{"value": "Cisco Catalyst 9300 Switch"}]
    }
  }
]
})

