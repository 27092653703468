
define({
  "LicenseNotificationsData":[
    {
      "notificationMessage": "Smart account credentials are not configured or smart account access is revoked for the configured smart credentials",
      "notificationCreationTime": "2021-08-18T08:34:49",
      "notificationType": "CSSM Connectivity",
      "notificationId": 48786
    },
    {
      "notificationMessage": "Cisco DNA Center cannot upload usage report for device '10.16.50.8' as it is not associated to any smart account and virtual account. Trigger manual sync of usage report using 'Smart License Compliance' workflow in SLE tab.",
      "notificationCreationTime": "2021-08-07T23:23:21",
      "notificationType": "Upload Usage Report",
      "notificationId": 44386
    },
    {
      "notificationMessage": "Cisco DNA Center cannot upload usage report for device '10.16.50.9' as it is not associated to any smart account and virtual account. Trigger manual sync of usage report using 'Smart License Compliance' workflow in SLE tab.",
      "notificationCreationTime": "2021-08-07T23:23:21",
      "notificationType": "Upload Usage Report",
      "notificationId": 44389
    },
    {
      "notificationMessage": "Cisco DNA Center cannot upload usage report for device '10.16.86.49' as it is not associated to any smart account and virtual account. Trigger manual sync of usage report using 'Smart License Compliance' workflow in SLE tab.",
      "notificationCreationTime": "2021-08-07T23:23:21",
      "notificationType": "Upload Usage Report",
      "notificationId": 44392
    },
    {
      "notificationMessage": "Cisco DNA Center cannot upload usage report for device '10.16.0.41' as it is not associated to any smart account and virtual account. Trigger manual sync of usage report using 'Smart License Compliance' workflow in SLE tab.",
      "notificationCreationTime": "2021-08-07T23:23:21",
      "notificationType": "Upload Usage Report",
      "notificationId": 44385
    },
    {
      "notificationMessage": "Cisco DNA Center cannot upload usage report for device '10.16.50.4' as it is not associated to any smart account and virtual account. Trigger manual sync of usage report using 'Smart License Compliance' workflow in SLE tab.",
      "notificationCreationTime": "2021-08-07T23:23:21",
      "notificationType": "Upload Usage Report",
      "notificationId": 44388
    },
    {
      "notificationMessage": "Cisco DNA Center cannot upload usage report for device '10.16.50.12' as it is not associated to any smart account and virtual account. Trigger manual sync of usage report using 'Smart License Compliance' workflow in SLE tab.",
      "notificationCreationTime": "2021-08-07T23:23:21",
      "notificationType": "Upload Usage Report",
      "notificationId": 44391
    },
    {
      "notificationMessage": "Cisco DNA Center cannot upload usage report for device '10.16.50.7' as it is not associated to any smart account and virtual account. Trigger manual sync of usage report using 'Smart License Compliance' workflow in SLE tab.",
      "notificationCreationTime": "2021-08-07T23:23:21",
      "notificationType": "Upload Usage Report",
      "notificationId": 44387
    },
    {
      "notificationMessage": "Cisco DNA Center cannot upload usage report for device '10.16.50.10' as it is not associated to any smart account and virtual account. Trigger manual sync of usage report using 'Smart License Compliance' workflow in SLE tab.",
      "notificationCreationTime": "2021-08-07T23:23:21",
      "notificationType": "Upload Usage Report",
      "notificationId": 44390
    },
    {
      "notificationMessage": "Cisco DNA Center cannot upload usage report for device '10.16.50.13' as it is not associated to any smart account and virtual account. Trigger manual sync of usage report using 'Smart License Compliance' workflow in SLE tab.",
      "notificationCreationTime": "2021-08-07T23:23:21",
      "notificationType": "Upload Usage Report",
      "notificationId": 44393
    },
    {
      "notificationMessage": "Your cisco.com credentials have changed recently but they have not been updated in Cisco DNA Center. Please update your latest credentials in System - Settings to be able to connect to Cisco and access software and services.",
      "notificationCreationTime": "2021-08-07T23:23:20",
      "notificationType": "Invalid Cisco.com Credentials",
      "notificationId": 44384
    }
  ]
});