define({
  "Template":
      {
      "issueCount": 0,
      "testType": "radius",
      "testResultDetails": {
         "passCount": 371,
        "failureCount": 46,
        "slowCount": 0
      },
      "worstPerformingSite": {
        "tagName": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "count": 417
      },
      "KpiPerformanceInterval": [
        {
          "kpi": "radius_authTimeMillis",
          "performanceThreshold": 2000,
          "averagePerformanceValue": 0.0,
          "minPerformanceList": [
             {
          "intervalIndex": "interval-0",
          "value": 9.0,
          "endTime": 1527332400000
        },
        {
          "intervalIndex": "interval-1",
          "value": 0.0,
          "endTime": 1527328800000
        },
        {
          "intervalIndex": "interval-2",
          "value": 9.0,
          "endTime": 1527325200000
        },
        {
          "intervalIndex": "interval-3",
          "value": 8.0,
          "endTime": 1527321600000
        },
        {
          "intervalIndex": "interval-4",
          "value": 9.0,
          "endTime": 1527318000000
        },
        {
          "intervalIndex": "interval-5",
          "value": 9.0,
          "endTime": 1527314400000
        },
        {
          "intervalIndex": "interval-6",
          "value": 8.0,
          "endTime": 1527310800000
        },
        {
          "intervalIndex": "interval-7",
          "value": 8.0,
          "endTime": 1527307200000
        },
        {
          "intervalIndex": "interval-8",
          "value": 9.0,
          "endTime": 1527303600000
        },
        {
          "intervalIndex": "interval-9",
          "value": 9.0,
          "endTime": 1527300000000
        },
        {
          "intervalIndex": "interval-10",
          "value": 9.0,
          "endTime": 1527296400000
        },
        {
          "intervalIndex": "interval-11",
          "value": 8.0,
          "endTime": 1527292800000
        },
        {
          "intervalIndex": "interval-12",
          "value": 9.0,
          "endTime": 1527289200000
        },
        {
          "intervalIndex": "interval-13",
          "value": 9.0,
          "endTime": 1527285600000
        },
        {
          "intervalIndex": "interval-14",
          "value": 9.0,
          "endTime": 1527282000000
        },
        {
          "intervalIndex": "interval-15",
          "value": 9.0,
          "endTime": 1527278400000
        },
        {
          "intervalIndex": "interval-16",
          "value": 9.0,
          "endTime": 1527274800000
        },
        {
          "intervalIndex": "interval-17",
          "value": 9.0,
          "endTime": 1527271200000
        },
        {
          "intervalIndex": "interval-18",
          "value": 9.0,
          "endTime": 1527267600000
        },
        {
          "intervalIndex": "interval-19",
          "value": 9.0,
          "endTime": 1527264000000
        },
        {
          "intervalIndex": "interval-20",
          "value": 9.0,
          "endTime": 1527260400000
        },
        {
          "intervalIndex": "interval-21",
          "value": 9.0,
          "endTime": 1527256800000
        },
        {
          "intervalIndex": "interval-22",
          "value": 0.0,
          "endTime": 1527253200000
        },
        {
          "intervalIndex": "interval-23",
          "value": 9.0,
          "endTime": 1527249600000
        },
        {
          "intervalIndex": "interval-24",
          "value": 8.0,
          "endTime": 1527246000000
        },
        {
          "intervalIndex": "interval-25",
          "value": 8.0,
          "endTime": 1527242400000
        },
        {
          "intervalIndex": "interval-26",
          "value": 9.0,
          "endTime": 1527238800000
        },
        {
          "intervalIndex": "interval-27",
          "value": 0.0,
          "endTime": 1527235200000
        },
        {
          "intervalIndex": "interval-28",
          "value": 9.0,
          "endTime": 1527231600000
        },
        {
          "intervalIndex": "interval-29",
          "value": 9.0,
          "endTime": 1527228000000
        },
        {
          "intervalIndex": "interval-30",
          "value": 9.0,
          "endTime": 1527224400000
        },
        {
          "intervalIndex": "interval-31",
          "value": 9.0,
          "endTime": 1527220800000
        },
        {
          "intervalIndex": "interval-32",
          "value": 0.0,
          "endTime": 1527217200000
        },
        {
          "intervalIndex": "interval-33",
          "value": 9.0,
          "endTime": 1527213600000
        },
        {
          "intervalIndex": "interval-34",
          "value": 9.0,
          "endTime": 1527210000000
        },
        {
          "intervalIndex": "interval-35",
          "value": 8.0,
          "endTime": 1527206400000
        },
        {
          "intervalIndex": "interval-36",
          "value": 9.0,
          "endTime": 1527202800000
        },
        {
          "intervalIndex": "interval-37",
          "value": 9.0,
          "endTime": 1527199200000
        },
        {
          "intervalIndex": "interval-38",
          "value": 9.0,
          "endTime": 1527195600000
        },
        {
          "intervalIndex": "interval-39",
          "value": 9.0,
          "endTime": 1527192000000
        },
        {
          "intervalIndex": "interval-40",
          "value": 9.0,
          "endTime": 1527188400000
        },
        {
          "intervalIndex": "interval-41",
          "value": 8.0,
          "endTime": 1527184800000
        }

          ],
          "averagePerformanceList": [
            {
          "intervalIndex": "interval-0",
          "value": 9.0,
          "endTime": 1527332400000
        },
        {
          "intervalIndex": "interval-1",
          "value": 8.1,
          "endTime": 1527328800000
        },
        {
          "intervalIndex": "interval-2",
          "value": 9.0,
          "endTime": 1527325200000
        },
        {
          "intervalIndex": "interval-3",
          "value": 8.8,
          "endTime": 1527321600000
        },
        {
          "intervalIndex": "interval-4",
          "value": 9.1,
          "endTime": 1527318000000
        },
        {
          "intervalIndex": "interval-5",
          "value": 9.0,
          "endTime": 1527314400000
        },
        {
          "intervalIndex": "interval-6",
          "value": 9.0,
          "endTime": 1527310800000
        },
        {
          "intervalIndex": "interval-7",
          "value": 9.0,
          "endTime": 1527307200000
        },
        {
          "intervalIndex": "interval-8",
          "value": 9.0,
          "endTime": 1527303600000
        },
        {
          "intervalIndex": "interval-9",
          "value": 9.0,
          "endTime": 1527300000000
        },
        {
          "intervalIndex": "interval-10",
          "value": 9.0,
          "endTime": 1527296400000
        },
        {
          "intervalIndex": "interval-11",
          "value": 8.9,
          "endTime": 1527292800000
        },
        {
          "intervalIndex": "interval-12",
          "value": 9.0,
          "endTime": 1527289200000
        },
        {
          "intervalIndex": "interval-13",
          "value": 9.0,
          "endTime": 1527285600000
        },
        {
          "intervalIndex": "interval-14",
          "value": 9.0,
          "endTime": 1527282000000
        },
        {
          "intervalIndex": "interval-15",
          "value": 9.0,
          "endTime": 1527278400000
        },
        {
          "intervalIndex": "interval-16",
          "value": 9.0,
          "endTime": 1527274800000
        },
        {
          "intervalIndex": "interval-17",
          "value": 9.0,
          "endTime": 1527271200000
        },
        {
          "intervalIndex": "interval-18",
          "value": 9.0,
          "endTime": 1527267600000
        },
        {
          "intervalIndex": "interval-19",
          "value": 9.0,
          "endTime": 1527264000000
        },
        {
          "intervalIndex": "interval-20",
          "value": 9.0,
          "endTime": 1527260400000
        },
        {
          "intervalIndex": "interval-21",
          "value": 9.0,
          "endTime": 1527256800000
        },
        {
          "intervalIndex": "interval-22",
          "value": 8.181818181818182,
          "endTime": 1527253200000
        },
        {
          "intervalIndex": "interval-23",
          "value": 9.0,
          "endTime": 1527249600000
        },
        {
          "intervalIndex": "interval-24",
          "value": 9.0,
          "endTime": 1527246000000
        },
        {
          "intervalIndex": "interval-25",
          "value": 9.0,
          "endTime": 1527242400000
        },
        {
          "intervalIndex": "interval-26",
          "value": 9.1,
          "endTime": 1527238800000
        },
        {
          "intervalIndex": "interval-27",
          "value": 8.0,
          "endTime": 1527235200000
        },
        {
          "intervalIndex": "interval-28",
          "value": 9.0,
          "endTime": 1527231600000
        },
        {
          "intervalIndex": "interval-29",
          "value": 9.0,
          "endTime": 1527228000000
        },
        {
          "intervalIndex": "interval-30",
          "value": 9.1,
          "endTime": 1527224400000
        },
        {
          "intervalIndex": "interval-31",
          "value": 9.1,
          "endTime": 1527220800000
        },
        {
          "intervalIndex": "interval-32",
          "value": 8.1,
          "endTime": 1527217200000
        },
        {
          "intervalIndex": "interval-33",
          "value": 9.0,
          "endTime": 1527213600000
        },
        {
          "intervalIndex": "interval-34",
          "value": 9.0,
          "endTime": 1527210000000
        },
        {
          "intervalIndex": "interval-35",
          "value": 8.9,
          "endTime": 1527206400000
        },
        {
          "intervalIndex": "interval-36",
          "value": 9.0,
          "endTime": 1527202800000
        },
        {
          "intervalIndex": "interval-37",
          "value": 9.0,
          "endTime": 1527199200000
        },
        {
          "intervalIndex": "interval-38",
          "value": 9.0,
          "endTime": 1527195600000
        },
        {
          "intervalIndex": "interval-39",
          "value": 9.0,
          "endTime": 1527192000000
        },
        {
          "intervalIndex": "interval-40",
          "value": 9.0,
          "endTime": 1527188400000
        },
        {
          "intervalIndex": "interval-41",
          "value": 16.4,
          "endTime": 1527184800000
        }
          ],
          "maxPerformanceList": [
            {
          "intervalIndex": "interval-0",
          "value": 9.0,
          "endTime": 1527332400000
        },
        {
          "intervalIndex": "interval-1",
          "value": 9.0,
          "endTime": 1527328800000
        },
        {
          "intervalIndex": "interval-2",
          "value": 9.0,
          "endTime": 1527325200000
        },
        {
          "intervalIndex": "interval-3",
          "value": 9.0,
          "endTime": 1527321600000
        },
        {
          "intervalIndex": "interval-4",
          "value": 10.0,
          "endTime": 1527318000000
        },
        {
          "intervalIndex": "interval-5",
          "value": 9.0,
          "endTime": 1527314400000
        },
        {
          "intervalIndex": "interval-6",
          "value": 10.0,
          "endTime": 1527310800000
        },
        {
          "intervalIndex": "interval-7",
          "value": 10.0,
          "endTime": 1527307200000
        },
        {
          "intervalIndex": "interval-8",
          "value": 9.0,
          "endTime": 1527303600000
        },
        {
          "intervalIndex": "interval-9",
          "value": 9.0,
          "endTime": 1527300000000
        },
        {
          "intervalIndex": "interval-10",
          "value": 9.0,
          "endTime": 1527296400000
        },
        {
          "intervalIndex": "interval-11",
          "value": 9.0,
          "endTime": 1527292800000
        },
        {
          "intervalIndex": "interval-12",
          "value": 9.0,
          "endTime": 1527289200000
        },
        {
          "intervalIndex": "interval-13",
          "value": 9.0,
          "endTime": 1527285600000
        },
        {
          "intervalIndex": "interval-14",
          "value": 9.0,
          "endTime": 1527282000000
        },
        {
          "intervalIndex": "interval-15",
          "value": 9.0,
          "endTime": 1527278400000
        },
        {
          "intervalIndex": "interval-16",
          "value": 9.0,
          "endTime": 1527274800000
        },
        {
          "intervalIndex": "interval-17",
          "value": 9.0,
          "endTime": 1527271200000
        },
        {
          "intervalIndex": "interval-18",
          "value": 9.0,
          "endTime": 1527267600000
        },
        {
          "intervalIndex": "interval-19",
          "value": 9.0,
          "endTime": 1527264000000
        },
        {
          "intervalIndex": "interval-20",
          "value": 9.0,
          "endTime": 1527260400000
        },
        {
          "intervalIndex": "interval-21",
          "value": 9.0,
          "endTime": 1527256800000
        },
        {
          "intervalIndex": "interval-22",
          "value": 10.0,
          "endTime": 1527253200000
        },
        {
          "intervalIndex": "interval-23",
          "value": 9.0,
          "endTime": 1527249600000
        },
        {
          "intervalIndex": "interval-24",
          "value": 10.0,
          "endTime": 1527246000000
        },
        {
          "intervalIndex": "interval-25",
          "value": 10.0,
          "endTime": 1527242400000
        },
        {
          "intervalIndex": "interval-26",
          "value": 10.0,
          "endTime": 1527238800000
        },
        {
          "intervalIndex": "interval-27",
          "value": 9.0,
          "endTime": 1527235200000
        },
        {
          "intervalIndex": "interval-28",
          "value": 9.0,
          "endTime": 1527231600000
        },
        {
          "intervalIndex": "interval-29",
          "value": 9.0,
          "endTime": 1527228000000
        },
        {
          "intervalIndex": "interval-30",
          "value": 10.0,
          "endTime": 1527224400000
        },
        {
          "intervalIndex": "interval-31",
          "value": 10.0,
          "endTime": 1527220800000
        },
        {
          "intervalIndex": "interval-32",
          "value": 9.0,
          "endTime": 1527217200000
        },
        {
          "intervalIndex": "interval-33",
          "value": 9.0,
          "endTime": 1527213600000
        },
        {
          "intervalIndex": "interval-34",
          "value": 9.0,
          "endTime": 1527210000000
        },
        {
          "intervalIndex": "interval-35",
          "value": 9.0,
          "endTime": 1527206400000
        },
        {
          "intervalIndex": "interval-36",
          "value": 9.0,
          "endTime": 1527202800000
        },
        {
          "intervalIndex": "interval-37",
          "value": 9.0,
          "endTime": 1527199200000
        },
        {
          "intervalIndex": "interval-38",
          "value": 9.0,
          "endTime": 1527195600000
        },
        {
          "intervalIndex": "interval-39",
          "value": 9.0,
          "endTime": 1527192000000
        },
        {
          "intervalIndex": "interval-40",
          "value": 9.0,
          "endTime": 1527188400000
        },
        {
          "intervalIndex": "interval-41",
          "value": 24.0,
          "endTime": 1527184800000
        }

          ]
        }
      ]
    }
    });
