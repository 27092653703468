define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/SimulationUtility',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/command-runner/models/SimCommandResponses'
], function (SimLokiDatabaseActions, SimulationUtility, SimCommandResponses) {

    var COMMAND_PATH = 'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/';
    /*var commandHandler = {
       show_controller_cpu_interface: function(deviceId) {
           return show_controller_cpu_interface.response;
       },
    };*/

    return {
        init: function () {
            SimCommandResponses.init();
        },

        getCommandResponse: function (cmd, deviceId, cmdParams) {
            var data = SimCommandResponses.getCommandResponse(cmd);
            if (data != undefined) {
                var obj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'id': deviceId });
                if (obj && obj.length) {
                    //common replacements

                    data.Template.result = data.Template.result.replace(/DeviceNamePlaceHolder/g, obj[0].hostname);
                    data.Template.result = data.Template.result.replace(/DeviceIpPlaceHolder/g, obj[0].managementIpAddress);
                    data.Template.result = data.Template.result.replace(/DevicePidPlaceHolder/g, obj[0].platformId);
                    //cmd specific replacements
                    data.Template.result = updateCommandResponse(data.Template.result, cmd, obj[0], cmdParams);
                    if(obj[0].macAddress){
                        data.Template.result = data.Template.result.replace(/DeviceMacAddressPlaceHolder/g, obj[0].macAddress);
                    }
                }                
            } else {
                data = {'Template' : {'success':false, 'result':'Command is blacklisted, execution not supported'} };
            }
            return data;
        }
    };

    function updateCommandResponse(cmdResponse, cmd, devObj, cmdParams) {
        var replacements = {}, key, val;
        var deviceId = devObj.id;
        var date = new Date();

        //specific replacements
        switch (cmd) {
            case 'show_clock':
                var record = SimLokiDatabaseActions.getFilteredRecordHandler('issue', {'$and' : [{'deviceId':deviceId},
                    {'issueNamekey':'device_time_drift'}] });
                key = new RegExp('NewDatePlaceHolder','g'); 
                val = date;
                if(record && record.length>0) {
                    //show lag/lead in time
                    date.setHours( date.getHours() + 3 );
                    val = date;
                }
                cmdResponse = cmdResponse.replace(key, val);
                break;
            case 'show_ip_cef':
            case 'show_ip_route':
                key = new RegExp('Cmd2OctetReplacement','g');
                val = cmdParams['ipAddr'] ? cmdParams['ipAddr'] : '';
                var tempArr = val.split('.');
                if(tempArr.length==4) { 
                    val = tempArr[0]+'.'+tempArr[1]+'.0.0'; 
                } else {
                    val = '192.168.0.0';
                }
                cmdResponse = cmdResponse.replace(key, val);
                break;
            default:
                break;
        }

        key = new RegExp('CmdIpPlaceHolder','g');
        val = cmdParams['ipAddr'] ? cmdParams['ipAddr'] : '';
        cmdResponse = cmdResponse.replace(key, val);

        key = new RegExp('CmdIfNamePlaceHolder','g');
        val = cmdParams['ifName'] ? cmdParams['ifName'] : '';
        cmdResponse = cmdResponse.replace(key, val);

        return cmdResponse;
    }

});
