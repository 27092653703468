define({
    "Template":{
  "version": "1.0",
  "response": [
    {
      "time": "2018-05-21T06:30:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 2.0,
      "latency": 1.0,
      "applicationDelay": 17.0
    },
    {
      "time": "2018-05-21T06:25:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 36.5
    },
    {
      "time": "2018-05-21T06:20:00.000+0000",
      "healthScore": 4.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 17.0
    },
    {
      "time": "2018-05-21T06:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 35.0
    },
    {
      "time": "2018-05-21T06:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-21T06:00:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 34.5
    },
    {
      "time": "2018-05-21T05:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 3.0,
      "applicationDelay": 1.0
    },
    {
      "time": "2018-05-21T05:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 20.5
    },
    {
      "time": "2018-05-21T05:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.04,
      "latency": 8.0,
      "applicationDelay": 142.0
    },
    {
      "time": "2018-05-21T05:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 74.0
    },
    {
      "time": "2018-05-21T05:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 74.0
    },
    {
      "time": "2018-05-21T05:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 90.0
    },
    {
      "time": "2018-05-21T05:00:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-21T04:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 7.0,
      "applicationDelay": 147.0
    },
    {
      "time": "2018-05-21T04:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-21T04:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-21T04:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 76.0
    },
    {
      "time": "2018-05-21T04:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 72.0
    },
    {
      "time": "2018-05-21T04:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 39.0
    },
    {
      "time": "2018-05-21T04:00:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 36.0
    },
    {
      "time": "2018-05-21T03:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-21T03:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 80.0
    },
    {
      "time": "2018-05-21T03:30:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 37.5
    },
    {
      "time": "2018-05-21T03:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 34.0
    },
    {
      "time": "2018-05-21T03:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-21T02:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 75.0
    },
    {
      "time": "2018-05-21T02:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 49.0
    },
    {
      "time": "2018-05-21T02:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 1.5
    },
    {
      "time": "2018-05-21T02:30:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 0.25,
      "applicationDelay": 11.75
    },
    {
      "time": "2018-05-21T02:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 20.5
    },
    {
      "time": "2018-05-21T02:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 3.0,
      "applicationDelay": 81.0
    },
    {
      "time": "2018-05-21T02:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 72.0
    },
    {
      "time": "2018-05-21T01:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-21T01:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 5.0,
      "applicationDelay": 147.0
    },
    {
      "time": "2018-05-21T01:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 101.0
    },
    {
      "time": "2018-05-21T01:30:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 36.0
    },
    {
      "time": "2018-05-21T01:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 147.0
    },
    {
      "time": "2018-05-21T01:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 83.0
    },
    {
      "time": "2018-05-21T01:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-21T01:00:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 49.5
    },
    {
      "time": "2018-05-21T00:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-21T00:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 77.0
    },
    {
      "time": "2018-05-21T00:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-21T00:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 5.0,
      "applicationDelay": 153.0
    },
    {
      "time": "2018-05-21T00:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-21T00:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 36.5
    },
    {
      "time": "2018-05-21T00:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-21T00:10:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.72,
      "latency": 1.0,
      "applicationDelay": 41.0
    },
    {
      "time": "2018-05-21T00:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-21T00:00:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 36.75
    },
    {
      "time": "2018-05-20T23:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 2.0
    },
    {
      "time": "2018-05-20T23:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 53.0
    },
    {
      "time": "2018-05-20T23:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 98.0
    },
    {
      "time": "2018-05-20T23:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T23:30:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 73.5
    },
    {
      "time": "2018-05-20T23:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 49.0
    },
    {
      "time": "2018-05-20T23:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 3.0,
      "applicationDelay": 50.0
    },
    {
      "time": "2018-05-20T23:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 3.0,
      "applicationDelay": 10.0
    },
    {
      "time": "2018-05-20T23:00:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 25.5
    },
    {
      "time": "2018-05-20T22:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 3.0,
      "applicationDelay": 98.0
    },
    {
      "time": "2018-05-20T22:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 72.0
    },
    {
      "time": "2018-05-20T22:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 149.0
    },
    {
      "time": "2018-05-20T22:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 74.0
    },
    {
      "time": "2018-05-20T22:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 60.0
    },
    {
      "time": "2018-05-20T22:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 77.0
    },
    {
      "time": "2018-05-20T22:00:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 73.5
    },
    {
      "time": "2018-05-20T21:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 43.0
    },
    {
      "time": "2018-05-20T21:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T21:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T21:30:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 1.5,
      "applicationDelay": 49.0
    },
    {
      "time": "2018-05-20T21:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 113.0
    },
    {
      "time": "2018-05-20T21:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 3.0,
      "applicationDelay": 113.0
    },
    {
      "time": "2018-05-20T21:00:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T20:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.5,
      "applicationDelay": 74.0
    },
    {
      "time": "2018-05-20T20:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T20:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 56.0
    },
    {
      "time": "2018-05-20T20:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 100.0
    },
    {
      "time": "2018-05-20T20:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T20:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 88.0
    },
    {
      "time": "2018-05-20T20:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 3.0,
      "applicationDelay": 79.0
    },
    {
      "time": "2018-05-20T20:00:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 37.0
    },
    {
      "time": "2018-05-20T19:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T19:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 36.5
    },
    {
      "time": "2018-05-20T19:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 8.0
    },
    {
      "time": "2018-05-20T19:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T19:30:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 36.5
    },
    {
      "time": "2018-05-20T19:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 147.0
    },
    {
      "time": "2018-05-20T19:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T19:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 149.0
    },
    {
      "time": "2018-05-20T19:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T19:00:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T18:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 74.0
    },
    {
      "time": "2018-05-20T18:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T18:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T18:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 100.0
    },
    {
      "time": "2018-05-20T18:30:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 40.0
    },
    {
      "time": "2018-05-20T18:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 74.0
    },
    {
      "time": "2018-05-20T18:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T18:00:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 49.5
    },
    {
      "time": "2018-05-20T17:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T17:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 41.0
    },
    {
      "time": "2018-05-20T17:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.5,
      "applicationDelay": 73.5
    },
    {
      "time": "2018-05-20T17:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 30.0
    },
    {
      "time": "2018-05-20T17:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 63.0
    },
    {
      "time": "2018-05-20T17:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T17:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T17:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T16:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 102.0
    },
    {
      "time": "2018-05-20T16:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T16:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T16:30:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 38.5
    },
    {
      "time": "2018-05-20T16:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T16:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 22.0
    },
    {
      "time": "2018-05-20T16:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 73.5
    },
    {
      "time": "2018-05-20T16:00:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 34.0
    },
    {
      "time": "2018-05-20T15:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 50.0
    },
    {
      "time": "2018-05-20T15:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 48.0
    },
    {
      "time": "2018-05-20T15:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.5,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T15:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T15:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T15:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 41.0
    },
    {
      "time": "2018-05-20T15:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 57.0
    },
    {
      "time": "2018-05-20T15:00:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 74.0
    },
    {
      "time": "2018-05-20T14:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T14:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 45.5
    },
    {
      "time": "2018-05-20T14:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T14:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 23.0
    },
    {
      "time": "2018-05-20T14:30:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 2.5,
      "applicationDelay": 72.5
    },
    {
      "time": "2018-05-20T14:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T14:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.01,
      "latency": 1.5,
      "applicationDelay": 41.5
    },
    {
      "time": "2018-05-20T14:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 24.5
    },
    {
      "time": "2018-05-20T14:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 199.0
    },
    {
      "time": "2018-05-20T14:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 40.0
    },
    {
      "time": "2018-05-20T14:00:00.000+0000",
      "healthScore": 3.0,
      "packetLoss": 0.23,
      "latency": 1.5,
      "applicationDelay": 66.0
    },
    {
      "time": "2018-05-20T13:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 37.0
    },
    {
      "time": "2018-05-20T13:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 36.5
    },
    {
      "time": "2018-05-20T13:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T13:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 48.0
    },
    {
      "time": "2018-05-20T13:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 26.0
    },
    {
      "time": "2018-05-20T13:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T13:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T13:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T13:00:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T12:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 12.0
    },
    {
      "time": "2018-05-20T12:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 24.0
    },
    {
      "time": "2018-05-20T12:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 145.0
    },
    {
      "time": "2018-05-20T12:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T12:30:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 26.0
    },
    {
      "time": "2018-05-20T12:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 42.0
    },
    {
      "time": "2018-05-20T12:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.03,
      "latency": 5.0,
      "applicationDelay": 140.0
    },
    {
      "time": "2018-05-20T12:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T12:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T12:00:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T11:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.5,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T11:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 40.0
    },
    {
      "time": "2018-05-20T11:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 62.0
    },
    {
      "time": "2018-05-20T11:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 51.0
    },
    {
      "time": "2018-05-20T11:30:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T11:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T11:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 48.5
    },
    {
      "time": "2018-05-20T11:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T11:00:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 1.5,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T10:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T10:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T10:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.5,
      "applicationDelay": 61.5
    },
    {
      "time": "2018-05-20T10:30:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T10:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T10:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T10:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 97.0
    },
    {
      "time": "2018-05-20T10:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T10:00:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T09:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T09:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 51.0
    },
    {
      "time": "2018-05-20T09:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T09:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 72.0
    },
    {
      "time": "2018-05-20T09:30:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 0.25,
      "applicationDelay": 13.0
    },
    {
      "time": "2018-05-20T09:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T09:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T09:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 97.0
    },
    {
      "time": "2018-05-20T09:00:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 1.5,
      "applicationDelay": 26.0
    },
    {
      "time": "2018-05-20T08:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 3.0,
      "applicationDelay": 105.0
    },
    {
      "time": "2018-05-20T08:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 23.5
    },
    {
      "time": "2018-05-20T08:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 36.0
    },
    {
      "time": "2018-05-20T08:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.5,
      "applicationDelay": 74.0
    },
    {
      "time": "2018-05-20T08:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 3.0,
      "applicationDelay": 62.0
    },
    {
      "time": "2018-05-20T08:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 36.5
    },
    {
      "time": "2018-05-20T08:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T08:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 36.5
    },
    {
      "time": "2018-05-20T08:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 33.0
    },
    {
      "time": "2018-05-20T08:00:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 18.25
    },
    {
      "time": "2018-05-20T07:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-20T07:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 75.0
    },
    {
      "time": "2018-05-20T07:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 36.5
    },
    {
      "time": "2018-05-20T07:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T07:30:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 36.5
    },
    {
      "time": "2018-05-20T07:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T07:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 80.0
    },
    {
      "time": "2018-05-20T07:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 103.0
    },
    {
      "time": "2018-05-20T07:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 36.0
    },
    {
      "time": "2018-05-20T07:00:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T06:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T06:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T06:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 146.0
    },{
      "time": "2018-05-20T13:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T13:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 48.0
    },
    {
      "time": "2018-05-20T13:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 26.0
    },
    {
      "time": "2018-05-20T13:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T13:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T13:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T13:00:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T12:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 12.0
    },
    {
      "time": "2018-05-20T12:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 24.0
    },
    {
      "time": "2018-05-20T12:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 145.0
    },
    {
      "time": "2018-05-20T12:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T12:30:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 26.0
    },
    {
      "time": "2018-05-20T12:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 42.0
    },
    {
      "time": "2018-05-20T12:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.03,
      "latency": 5.0,
      "applicationDelay": 140.0
    },
    {
      "time": "2018-05-20T12:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T12:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T12:00:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T11:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.5,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T11:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 40.0
    },
    {
      "time": "2018-05-20T11:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 62.0
    },
    {
      "time": "2018-05-20T11:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 51.0
    },
    {
      "time": "2018-05-20T11:30:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T11:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T11:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 48.5
    },
    {
      "time": "2018-05-20T11:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T11:00:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 1.5,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T10:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T10:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T10:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.5,
      "applicationDelay": 61.5
    },
    {
      "time": "2018-05-20T10:30:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T10:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T10:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T10:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 97.0
    },
    {
      "time": "2018-05-20T10:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T10:00:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T09:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T09:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 51.0
    },
    {
      "time": "2018-05-20T09:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T09:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 72.0
    },
    {
      "time": "2018-05-20T09:30:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 0.25,
      "applicationDelay": 13.0
    },
    {
      "time": "2018-05-20T09:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T09:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T09:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 97.0
    },
    {
      "time": "2018-05-20T09:00:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 1.5,
      "applicationDelay": 26.0
    },
    {
      "time": "2018-05-20T08:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 3.0,
      "applicationDelay": 105.0
    },
    {
      "time": "2018-05-20T08:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 23.5
    },
    {
      "time": "2018-05-20T08:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 36.0
    },
    {
      "time": "2018-05-20T08:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.5,
      "applicationDelay": 74.0
    },
    {
      "time": "2018-05-20T08:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 3.0,
      "applicationDelay": 62.0
    },
    {
      "time": "2018-05-20T08:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 36.5
    },
    {
      "time": "2018-05-20T08:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T08:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 36.5
    },
    {
      "time": "2018-05-20T08:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 33.0
    },
    {
      "time": "2018-05-20T08:00:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 18.25
    },
    {
      "time": "2018-05-20T07:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-20T07:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 75.0
    },
    {
      "time": "2018-05-20T07:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 36.5
    },
    {
      "time": "2018-05-20T07:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T07:30:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 36.5
    },
    {
      "time": "2018-05-20T07:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T07:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 80.0
    },
    {
      "time": "2018-05-20T07:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 103.0
    },
    {
      "time": "2018-05-20T07:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 36.0
    },
    {
      "time": "2018-05-20T07:00:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T06:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T06:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T06:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 146.0
    },  {
      "time": "2018-05-20T08:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 33.0
    },
    {
      "time": "2018-05-20T08:00:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 18.25
    },
    {
      "time": "2018-05-20T07:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.5
    },
    {
      "time": "2018-05-20T07:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 75.0
    },
    {
      "time": "2018-05-20T07:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.5,
      "applicationDelay": 36.5
    },
    {
      "time": "2018-05-20T07:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T07:30:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 36.5
    },
    {
      "time": "2018-05-20T07:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 2.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T07:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 80.0
    },
    {
      "time": "2018-05-20T07:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 103.0
    },
    {
      "time": "2018-05-20T07:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 36.0
    },
    {
      "time": "2018-05-20T07:00:00.000+0000",
      "healthScore": 5.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T06:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 73.0
    },
    {
      "time": "2018-05-20T06:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T06:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 146.0
    },
    {
      "time": "2018-05-20T06:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-20T06:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 1.0,
      "applicationDelay": 146.0
    }

  ]
}
});
