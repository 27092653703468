define({
"Template":
{
  "version": "1.0",
  "response": {
    "detailList": [
      {
        "itemType": "ALL",
        "timestamp": "2020-05-07T08:40:00.000+0000",
        "count": 539,
        "nestedItems": [
          /* {
            "itemType": "<0",
            "timestamp": "2020-05-07T08:40:00.000+0000",
            "count": 0
          } */
        ]
      }
    ]
  }
}/* ,
"Power_Usage_Template":
{
  "version": "1.0",
  "response": {
    "detailList": [
      {
        "itemType": "Total Power Budget",
        "timestamp": "2021-07-21T12:20:00.000+0000",
        "count": 2201,
        "nestedItems": [
          {
            "itemType": "Consumed Power",
            "timestamp": "2021-07-21T12:20:00.000+0000",
            "count": 129
          },
          {
            "itemType": "Remaining Power",
            "timestamp": "2021-07-21T12:20:00.000+0000",
            "count": 2072
          }
        ]
      }
    ]
  }
} */
})