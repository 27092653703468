define({
    "Template":
    {
        "version": "1.0",
        "response": [
          {
            "healthScore": 10,
            "appHealthScore": 9,
            "clientName": "Grace.Smith-PC",
            "utilization": 191097629,
            "location": null,
            "operatingSystem": null,
            "clientMacAddress":"B8:27:EB:CA:AA:88"
          },
          {
            "healthScore": 9,
            "appHealthScore": 9,
            "clientName": "oliverp-Google_Nexus",
            "utilization": 23408,
            "location": null,
            "operatingSystem": null,
            "clientMacAddress": "7C:46:85:20:80:A3"
          },
          {
            "healthScore": 9,
            "appHealthScore": 9,
            "clientName": "velma.dinkley-Galaxy-Tab-S2",
            "utilization": 46048795,
            "location": null,
            "operatingSystem": null,
            "clientMacAddress": "C0:D3:C0:4D:B3:17"
          },
          {
            "healthScore": 1,
            "appHealthScore": 9,
            "clientName": "Gordon.Thomson-iPad",
            "utilization": 194676232,
            "location": "North America/USA/Nevada/Las Vegas/LV01/Flr-LV1-1",
            "operatingSystem": null,
            "clientMacAddress": "6C:19:C0:BD:87:C7"
          },
          {
            "healthScore": 1,
            "appHealthScore": 9,
            "clientName": "Grace.Smith-iPad",
            "utilization": 31545342,
            "location": "USA/DC",
            "operatingSystem": null,
            "clientMacAddress": "6C:19:C0:BD:87:C9"
          },
          {
            "healthScore": 9,
            "appHealthScore": 9,
            "clientName": "Grace.Smith-Galaxy-S20",
            "utilization": 46048795,
            "location": null,
            "operatingSystem": null,
            "clientMacAddress":"A8:B5:27:36:70:09"
          }
        ],
        "page": {
          "pageSize": 6,
          "offset": 1,
          "totalCount": 6
        }
      }
    });

