define({
 "MetricData":
   {"version":"1.0","response":{"entityId":"0e33f2f2-1038-4674-8987-21f8cc6ed72b","entityType":"Cisco Catalyst38xx stack-able ethernet switch","startTime":1516429825105,"endTime":1516516225105,"windowInSecs":5,"sortInterfacesBy":"max","data":[]}},
  "apInterfaceUtilDetails":{
  "version":"1.0",
  "response":[
    {
      "totalRxErrors": "0.0",
      "totalTxErrors": "0.0",
      "totalErrors": "0.0",
      "avgRxErrors": "0.0",
      "avgTxErrors": "0.0",
      "avgTotalErrors": "0.0",
      "avgRxUtil": "0.0",
      "avgTxUtil": "0.0",
      "avgTotalUtil": "0.0",
      "avgRxRate": "",
      "avgTxRate": "",
      "avgTotalRate": ""
    }
  ]}
})
