//asr1001x type Cisco ASR 1001-X Router
define({
  "runningImageList": [
    {
      "name": "asr1001x-universalk9.17.09.01a.SPA.bin",
      "imageUuid": "f04c7bc4-315e-5555-5678-9cebcd1e38a6",
      "version": "17.9.1",//Cupertino-17.9.1a
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "true",
      "md5Checksum": "4468fb112c9450a85b0702636ead8e58",
      "shaCheckSum": "a3825b23d9ac5f7bdf09d7c4d89a900ebfaf4bc41a4b89fdf6c255b490d141b74bf6f6a1e70b0505959619a08eb56ea7c6e86f6315b43ebe4cd4dead4db839dc",
      "createdTime": "2022-08-24 12:27:00.0",
      "applicationType": null,
      "feature": "",
      "fileServiceId": "987676d8-abcd-6666-bcda-c11781bb5667",
      "imageSeries": null,
      "imageSource": "99994125-aaaa-1234-9586-8313b8810a3b",
      "imageSourceType": "Imported from Device",
      "imageFamily": "asr1001x",
      "vendor": "CISCO",
      "filesize": 725957510,
      "deviceCount": 0,
      "deviceUuidList": [
      ],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "MINFLASHSIZE",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "MEDIA",
          "propertyValue": "5"
        },
        {
          "propertyName": "COMPRESSION_CODE",
          "propertyValue": "5003"
        },
        {
          "propertyName": "DEFAULT_RAM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "FEATURE",
          "propertyValue": ""
        },
        {
          "propertyName": "DEFAULT_MINFLASHSIZE",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "DEFAULT_BOOTROM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "BOOTROM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "RAM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "GAIA_FEATURE",
          "propertyValue": "UNKNOWN"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": true,
          "role": "ALL",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "VERIFIED",
      "ccorecommended": false,
      "ccolatest": true
    },{
      "name": "	asr1001x-universalk9.17.06.01a.SPA.bin",
      "imageUuid": "f04c7bc4-315e-2422-5678-9cebcd1e38a6",
      "version": "17.6.1",//Amsterdam-17.3.3
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "4468fb112c9450a85b0702636ead8e58",
      "shaCheckSum": "a3825b23d9ac5f7bdf09d7c4d89a900ebfaf4bc41a4b89fdf6c255b490d141b74bf6f6a1e70b0505959619a08eb56ea7c6e86f6315b43ebe4cd4dead4db839dc",
      "createdTime": "2021-09-15 12:27:00.0",//5 march release date
      "applicationType": null,
      "feature": "",
      "fileServiceId": "987676d8-abcd-1122-bcda-c11781bb5667",
      "imageSeries": null,
      "imageSource": "99994125-aaaa-1234-9586-8313b8810a3b",
      "imageSourceType": "Imported from Device",
      "imageFamily": "asr1001x",
      "vendor": "CISCO",
      "filesize": 725957510 ,
      "deviceCount": 0,
      "deviceUuidList": [
      ],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "MINFLASHSIZE",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "MEDIA",
          "propertyValue": "5"
        },
        {
          "propertyName": "COMPRESSION_CODE",
          "propertyValue": "5003"
        },
        {
          "propertyName": "DEFAULT_RAM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "FEATURE",
          "propertyValue": ""
        },
        {
          "propertyName": "DEFAULT_MINFLASHSIZE",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "DEFAULT_BOOTROM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "BOOTROM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "RAM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "GAIA_FEATURE",
          "propertyValue": "UNKNOWN"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "VERIFIED",
      "ccorecommended": false,
      "ccolatest": true
    },
    {
      "name": "asr1001x-universalk9.17.03.03.SPA.bin",
      "imageUuid": "f04c7bc4-315e-1122-5678-9cebcd1e38a6",
      "version": "17.3.3",//Amsterdam-17.3.3
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "eaafa018305ae4df43ff21cc5fd6fc84",
      "shaCheckSum": "9cf2e09b59207a4d8ea40886fbbe5b4b68e19e58a8f96b34240e4cea9971f6ae6facab9a1855a34e1ed8755f3ffe4c969cf6e6ef1df95d42a91540a44d4b9e14",
      "createdTime": "05-Mar-2021 12:27:00.0",//5 march release date
      "applicationType": null,
      "feature": "",
      "fileServiceId": "987676d8-abcd-1122-bcda-c11781bb5667",
      "imageSeries": null,
      "imageSource": "99994125-aaaa-1234-9586-8313b8810a3b",
      "imageSourceType": "Imported from Device",
      "imageFamily": "asr1001x",
      "vendor": "CISCO",
      "filesize": 904167865 ,
      "deviceCount": 0,
      "deviceUuidList": [
      ],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "MINFLASHSIZE",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "MEDIA",
          "propertyValue": "5"
        },
        {
          "propertyName": "COMPRESSION_CODE",
          "propertyValue": "5003"
        },
        {
          "propertyName": "DEFAULT_RAM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "FEATURE",
          "propertyValue": ""
        },
        {
          "propertyName": "DEFAULT_MINFLASHSIZE",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "DEFAULT_BOOTROM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "BOOTROM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "RAM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "GAIA_FEATURE",
          "propertyValue": "UNKNOWN"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "VERIFIED",
      "ccorecommended": false,
      "ccolatest": true
    },
    {
      "name": "asr1001x-universalk9.17.03.01a.SPA.bin",
      "imageUuid": "f04c7bc4-315e-1122-aaaa-9cebcd1e38a6",
      "version": "17.3.1a",//Amsterdam-17.3.1a
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "1ea47a8277778ae8127775992402f71b",
      "shaCheckSum": "78e48d6aabfa0613b032af430ae33bcd2097576eedd2bbbd520ded5cd8e9835f1d1765db77ead80d7335b87a406333851d0d023a48318afd4f62ffbdd1643a35",
      "createdTime": "2020-10-16 12:27:00.0",//16Aug release date
      "applicationType": null,
      "feature": "",
      "fileServiceId": "804976d8-abcd-1122-bcda-c11781bb5667",
      "imageSeries": null,
      "imageSource": "99e64125-aaaa-1234-9586-8313b8810a3b",
      "imageSourceType": "Imported from Device",
      "imageFamily": "asr1001x",
      "vendor": "CISCO",
      "filesize": 906952032 ,
      "deviceCount": 0,
      "deviceUuidList": [
      ],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "MINFLASHSIZE",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "MEDIA",
          "propertyValue": "5"
        },
        {
          "propertyName": "COMPRESSION_CODE",
          "propertyValue": "5003"
        },
        {
          "propertyName": "DEFAULT_RAM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "FEATURE",
          "propertyValue": ""
        },
        {
          "propertyName": "DEFAULT_MINFLASHSIZE",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "DEFAULT_BOOTROM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "BOOTROM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "RAM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "GAIA_FEATURE",
          "propertyValue": "UNKNOWN"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "VERIFIED",
      "ccorecommended": false,
      "ccolatest": true
    },
    {
      "name": "asr1001x-universalk9.17.02.01r.SPA.bin",
      "imageUuid": "f04c7bc4-315e-555e-aaaa-9cebcd1e38a6",
      "version": "17.2.1r",//Amsterdam-17.2.1r
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "10a5a7eb4afbe5b6cd586981b15bdb2c",
      "shaCheckSum": "96431a8dcc5817b5b7851039bf22df6b189c1eec3ac60ef04951903a64133848f3953b6e21a832dee379c21a75abd29e30380a6d87aa00501f49d5b1b296035c",
      "createdTime": "2020-04-28 17:27:00.0",//14Apr release date
      "applicationType": null,
      "feature": "",
      "fileServiceId": "804976d8-abcd-4322-bcda-c11781bb5667",
      "imageSeries": null,
      "imageSource": "99e64125-cb8a-1234-9586-8313b8810a3b",
      "imageSourceType": "Imported from Device",
      "imageFamily": "asr1001x",
      "vendor": "CISCO",
      "filesize": 901332619,
      "deviceCount": 0,
      "deviceUuidList": [
      ],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "MINFLASHSIZE",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "MEDIA",
          "propertyValue": "5"
        },
        {
          "propertyName": "COMPRESSION_CODE",
          "propertyValue": "5003"
        },
        {
          "propertyName": "DEFAULT_RAM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "FEATURE",
          "propertyValue": ""
        },
        {
          "propertyName": "DEFAULT_MINFLASHSIZE",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "DEFAULT_BOOTROM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "BOOTROM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "RAM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "GAIA_FEATURE",
          "propertyValue": "UNKNOWN"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "VERIFIED",
      "ccorecommended": false,
      "ccolatest": true
    },
    {
      "name": "asr1001x-universalk9.16.12.02t.SPA.bin",
      "imageUuid": "f04c7bc4-315e-555e-ffff-9cebcd1e38a6",
      "version": "16.12.2t",//Gibraltar-16.12.2t
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "60769bd3804a42affd5d5d5ab5ef1a67",
      "shaCheckSum": "5eedc2dfedf3bf5bd783d9f65281876c5abd66aca741841edcb55ee30f85084a0ab156863374694f98e6992184f837c97388b42ca4cebd978bfe28565eea126d",
      "createdTime": "2020-02-12 16:55:00.0",
      "applicationType": null,
      "feature": "",
      "fileServiceId": "804976d8-abcd-4322-a782-c11781bb5667",
      "imageSeries": null,
      "imageSource": "99e64125-cb8a-1234-9586-8313b8810a3b",
      "imageSourceType": "Imported from Device",
      "imageFamily": "asr1001x",
      "vendor": "CISCO",
      "filesize": 710086213,
      "deviceCount": 0,
      "deviceUuidList": [
      ],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "MINFLASHSIZE",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "MEDIA",
          "propertyValue": "5"
        },
        {
          "propertyName": "COMPRESSION_CODE",
          "propertyValue": "5003"
        },
        {
          "propertyName": "DEFAULT_RAM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "FEATURE",
          "propertyValue": ""
        },
        {
          "propertyName": "DEFAULT_MINFLASHSIZE",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "DEFAULT_BOOTROM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "BOOTROM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "RAM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "GAIA_FEATURE",
          "propertyValue": "UNKNOWN"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "VERIFIED",
      "ccorecommended": false,
      "ccolatest": true
    },
    {
      "name": "asr1001x-universalk9.17.01.01.SPA.bin",
      "imageUuid": "f04c7bc4-315e-235a-ffff-9cebcd1e38a6",
      "version": "17.1.1",//Amsterdam-17.1.1
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "f10378786f25b6763bacabff07802240",
      "shaCheckSum": "20a5709e8a5a1d04fd466d1e94b1a56ccbcd5c08f91035022201bf1407a684bb887fa1ebaf34ccdd2a8aea8872efed9e69ae72d5c1e4677e54ee1b59df9901e9",
      "createdTime": "2019-11-23 16:55:00.0",
      "applicationType": null,
      "feature": "",
      "fileServiceId": "804976d8-abcd-4322-a782-c11781bb5667",
      "imageSeries": null,
      "imageSource": "99e64125-cb8a-1234-9586-8313b8810a3b",
      "imageSourceType": "Imported from Device",
      "imageFamily": "asr1001x",
      "vendor": "CISCO",
      "filesize": 710086213,
      "deviceCount": 0,
      "deviceUuidList": [
      ],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "MINFLASHSIZE",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "MEDIA",
          "propertyValue": "5"
        },
        {
          "propertyName": "COMPRESSION_CODE",
          "propertyValue": "5003"
        },
        {
          "propertyName": "DEFAULT_RAM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "FEATURE",
          "propertyValue": ""
        },
        {
          "propertyName": "DEFAULT_MINFLASHSIZE",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "DEFAULT_BOOTROM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "BOOTROM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "RAM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "GAIA_FEATURE",
          "propertyValue": "UNKNOWN"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "VERIFIED",
      "ccorecommended": false,
      "ccolatest": true
    },
    {
      "name": "asr1001x-universalk9.16.12.01s.SPA.bin",
      "imageUuid": "f04c7bc4-315e-45dc-ffff-9cebcd1e38a6",
      "version": "16.12.1s",//Gibraltar-16.12.1a
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "5c250415c0356ff7e9229aca1382b8a3",
      "shaCheckSum": "157776e29cc146dd6b235a3956ab9b14d78ab2db795137510718f1af1854a16e88f16802cc53ad49726c2040cb61aa4644f4bdaa01b3258a9ee12748c0e470e9",
      "createdTime": "2018-09-05 16:55:00.0",
      "applicationType": null,
      "feature": "",
      "fileServiceId": "804976d8-abcd-4322-a782-c11781bb5667",
      "imageSeries": null,
      "imageSource": "99e64125-cb8a-1234-9586-8313b8810a3b",
      "imageSourceType": "Imported from Device",
      "imageFamily": "asr1001x",
      "vendor": "CISCO",
      "filesize": 710086213,
      "deviceCount": 0,
      "deviceUuidList": [
      ],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "MINFLASHSIZE",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "MEDIA",
          "propertyValue": "5"
        },
        {
          "propertyName": "COMPRESSION_CODE",
          "propertyValue": "5003"
        },
        {
          "propertyName": "DEFAULT_RAM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "FEATURE",
          "propertyValue": ""
        },
        {
          "propertyName": "DEFAULT_MINFLASHSIZE",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "DEFAULT_BOOTROM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "BOOTROM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "RAM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "GAIA_FEATURE",
          "propertyValue": "UNKNOWN"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "VERIFIED",
      "ccorecommended": false,
      "ccolatest": true
    }
  ]
});