define([    
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/services/graphql/SimLokiGraphQLMappings',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimDeviceImageData',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/provision/DeviceInterfaceInfoContainer_GraphQL',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/provision/AllDeviceInterfacesResponse_GraphQL',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/SimulationUtility',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimDeviceReplacementData',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/provision/AllDeviceStack_GraphQL',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/DefaultConfig',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimNetworkDeviceData',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimDefaultDataGenerator',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimInterfaceData',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimTopologyData',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/swim/SimSwimData',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/provision/DeviceConfigStatusTemplate',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/assurance/SimPoeAnalyticsData',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/Security-Advisories/SimAdvisoriesTemplate',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/Security-Advisories/AllDevice_GraphQL',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/Security-Advisories/AllDeviceStore_GraphQL',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/Security-Advisories/SimSecurityAdvisoryCount'  
 ], 
  function (
            SimLokiDatabaseActions,
            Mappings,
            SimDeviceImageData,
            DeviceInterfaceInfoContainer_GraphQL,
            AllDeviceInterfacesResponse_GraphQL,
            SimulationUtility,
            UtilityFunctions,
            SimDeviceReplacementData,
            AllDeviceStack_GraphQL,
            DefaultConfig,
            SimNetworkDeviceData,
            SimDefaultDataGenerator,
            SimInterfaceData,
            SimTopologyData,
            SimSwimData,
            DeviceConfigStatusTemplate,
            SimPoeAnalyticsData,
            SimAdvisoriesTemplate,
            AllDevice_GraphQL,
            AllDeviceStore_GraphQL,
            SimSecurityAdvisoryCount
          ) {

    const publicInterface = {
      init: function() {
        return true
      },

      getData: function (restPayload, payloadVariables) {
        let queryString = restPayload.query || restPayload;
        //isAnotherFilterField introduced for handling map view in Provision for selected site
        let isAnotherFilterField = false;
        if (restPayload.operationName && (queryString.match(/\(/g) || []).length > 1) {
          queryString = queryString.substring(queryString.indexOf(')') + 1).trim();
          isAnotherFilterField = true;
        }

        if (queryString.indexOf("allDevicePortChannel") >= 0) {
          //FIXME -- Temp has to be removed asap -- as a quick workaround..
          var result = JSON.parse(JSON.stringify(DeviceInterfaceInfoContainer_GraphQL.Template));
          var objArr = SimLokiDatabaseActions.getAll('interface-port-channel');
          return getDeviceSpecificPortChannel(queryString, restPayload.variables, objArr, result);

        } else if (queryString.indexOf("allDeviceInterfaces") >= 0) {
          //FIXME -- Temp has to be removed asap -- as a quick workaround..
          var result = JSON.parse(JSON.stringify(AllDeviceInterfacesResponse_GraphQL.Template));
          var objArr = SimLokiDatabaseActions.getAll('device-interface');
          return getDeviceSpecificInterfaces(queryString, restPayload.variables, objArr, result);
        } else if (queryString.indexOf("getWLCFirstTimeStamp") > -1) {
          var tJson = { "data": { "allWlc36S": { "__typename": "Wlc36SConnection", "nodes": [{ "__typename": "Wlc36", "timestamp": "2019-08-06T23:00:00" }] } } };
          return tJson;
        } else if (queryString.indexOf("allDeviceStack") > -1) {
          var Json = JSON.parse(JSON.stringify(AllDeviceStack_GraphQL.Template))
          var objArr = SimLokiDatabaseActions.getAll('network-device');
          return getDeviceStackInfo(queryString, restPayload.variables, Json, objArr);
        }
        if (queryString.indexOf("allDevices") > -1 && queryString.indexOf("advisory.advisoryCount") > -1) {
          let tJson = SimAdvisoriesTemplate.getSecurityAdvisoriesTemplate;
          let tempJson = [], tempCount = 0;
          var SJC01SiteDetails = SimLokiDatabaseActions.getFilteredRecordHandler('site', { "name": "SJC01" });
          var itemsArr = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { "siteId": SJC01SiteDetails[0].id });
          var advisoryCountObject = AllDevice_GraphQL.advisoryCountObject;
          for (let i = 0; i < itemsArr.length; i++) {
            var Device_SD_CountObj = advisoryCountObject.advisoryCountList.filter(function (obj) {
              return obj.deviceName == itemsArr[i].hostname
            })
            itemsArr[i].advisory = {};
            itemsArr[i].advisory.advisoryCount = Device_SD_CountObj[0].advisoryCount;
            itemsArr[i].advisory.lastScanTime = UtilityFunctions.getTimeStamp() - 100000;
            itemsArr[i].advisory.scanMode = "ESSENTIALS";
            itemsArr[i].advisory.scanStatus = "SUCCESS";
            itemsArr[i].site = {};
            itemsArr[i].site.groupNameHierarchy = SJC01SiteDetails[0].groupNameHierarchy;
            itemsArr[i].site.id = SJC01SiteDetails[0].id;
            itemsArr[i].site.name = SJC01SiteDetails[0].name;
            itemsArr[i].site.__typename = "DevicesSiteSchema"
            tempJson.push(itemsArr[i]);
            tempCount += 1;
          }
          tJson.data.allDevices.items = [...tempJson];
          tJson.data.allDevices.totalCount = [tempCount];
          return tJson;
        }
        //Security Advisories
        /*else if (queryString.indexOf("allDevices") > -1 && queryString.indexOf("value") > -1) {
          var result = JSON.parse(JSON.stringify(AllDevice_GraphQL.AllDevices_GraphQl_Template))
          var objArr = SimLokiDatabaseActions.getAll('network-device');
          return getDeviceSpecificResponse(queryString, restPayload.variables, objArr, result);
        }*/
        // commented to do causing breakage in appHosting flow need fix
        // else if (queryString.indexOf("allDeviceStore") > -1 && queryString.indexOf("value") > -1) {
        //   var result = JSON.parse(JSON.stringify(AllDeviceStore_GraphQL.AllDeviceStore_GraphQl_Template));
        //   var objArr = SimLokiDatabaseActions.getAll('network-device');
        //   return getDeviceSpecificResponse1(queryString, restPayload.variables, objArr, result);
        // }

        let variables = restPayload.variables;
        handleExceptionalRequests(queryString);
        let qComps = parse(queryString, variables, isAnotherFilterField)
        qComps.filters = filterBasedOnVariables(qComps.filters, restPayload.operationName, variables, payloadVariables)
        appendDeepFilterSampleObj (qComps);
        let sampleObjectMarker = queryString.indexOf('nodes') > -1 ? 'nodes' : 'items'
        qComps.filters = modifyFilter(qComps.filters);
        let tqComps = translateQueryComponents(qComps)
        let partiallyFilteredLokiDbData = getRawLokiDbData(tqComps)
        let response = formatAndDeepFilter(partiallyFilteredLokiDbData, tqComps, sampleObjectMarker, variables)
        if (queryString.indexOf("allDevices") > -1 && queryString.indexOf("advisoryCount") > -1) {
          let tJson = JSON.parse(JSON.stringify(SimAdvisoriesTemplate.getSecurityAdvisoriesTemplate));
          let tempJson = [], tempCount = 0;
          var itemsArr = response.data.allDevices.items;
          for (let i = 0; i < itemsArr.length; i++) {
            if (itemsArr[i].site && itemsArr[i].site.name === "SJC01") {
              tempJson.push(itemsArr[i]);
              tempCount += 1;
            }
          }
          tJson.data.allDevices.items = [...tempJson];
          tJson.data.allDevices.totalCount = tempCount;
          return tJson;
        }
        if (queryString.indexOf("allDeviceStore") > -1 && queryString.indexOf("unreachable") > -1) {
          response.data.allDeviceStore.items.map(device => {
            var filteredDevice = partiallyFilteredLokiDbData.filter(fd => {
              return fd.hostname == device.hostname;
            })
            device.serialNumber = filteredDevice[0].serialNumber
          })
        }
        return response;
      }
    }

    window.testGraphQL2 = str=>publicInterface.getData(extract("query", str))

    const modifyFilter = filters => {
      //To handle displaying devices belonging to parent location from left pane
      let idx1=-1, idx2=-1, idx3=-1, idx4=-1, siteIdList=[], childrenSites=[];
      for(let i=0; i<filters.length; i++) {
        if(filters[i].field == "site.groupNameHierarchy") idx1 = i;
        if(filters[i].field == "site.id") idx2 = i;
        if(filters[i].field == "site.groupHierarchy") idx3 = i;
        if(filters[i].field == "__rd_site") idx4 = i;
      }
      if(idx1 > -1 && idx2 > -1) {
        SimulationUtility.getChildrenSites(filters[idx2].value, childrenSites);
        siteIdList.push(filters[idx2].value);
        for (let i = 0; i < childrenSites.length; i++) {
          siteIdList.push(childrenSites[i].id);
        }
        filters.splice(idx2,1);
        //it is usually site.groupNameHierarchy followed by site.id in filters
        /* for(let i=0; i<filters.length; i++) {
          if(filters[i].field == "site.groupNameHierarchy") idx1 = i;
        } */
        filters.splice(idx1,1);
        filters.push({'field':'siteId','value':UtilityFunctions.convertArrayToCommaSeperated(siteIdList)});
      }
      else if(idx1 > -1){
        var sites=filters[idx1].value.split(',');
        for(var k=0;k<sites.length;k++){
          sites[k]=sites[k].replace(/\.\*/g, '');
         }
         var siteList=[...new Set(sites)];
         for(var k=0;k<siteList.length;k++){
          var site_id=SimulationUtility.getSiteId(siteList[k]);
          SimulationUtility.getChildrenSites(site_id, childrenSites);
          siteIdList.push(site_id);
          for (let i = 0; i < childrenSites.length; i++) {
            siteIdList.push(childrenSites[i].id);
          }
         }
         filters.splice(idx1,1); 
         filters.push({'field':'siteId','value':UtilityFunctions.convertArrayToCommaSeperated(siteIdList)});
      }
      else if (idx3 > -1){
        var site_id=filters[idx3].value.replace(/\.\*/g, '');
        if(site_id.indexOf('/')>-1) site_id = site_id.split('/')[site_id.split('/').length-1];
        SimulationUtility.getChildrenSites(site_id, childrenSites);
        siteIdList.push(site_id);
        for (let i = 0; i < childrenSites.length; i++) {
          siteIdList.push(childrenSites[i].id);
        }
        filters.splice(idx3,1);
        filters.push({'field':'siteId','value':UtilityFunctions.convertArrayToCommaSeperated(siteIdList)});
      }
      else if(idx4 > -1){
        if(filters[idx4].value==""){
          filters.push({'field':'siteId','value':UtilityFunctions.convertArrayToCommaSeperated(siteIdList)});
        }
      }
      return filters;
    }

    const translateQueryComponents = c=>{      
      const lokiDBCollectionMapping = Mappings.collectionNames
      const columnMapping = Mappings.columnNames

      let translation = {collection:'', filters:{}}

      if(lokiDBCollectionMapping[c.collection]) translation.collection = lokiDBCollectionMapping[c.collection]
      else translation.collection = c.collection

      let translatedSubcollections = c.subcollections.map(s=>{
        if(lokiDBCollectionMapping[s]) return lokiDBCollectionMapping[s]
        else return s
      })

      c.filters.forEach(e=>{
        handleFilterExceptions(e);
       translation.filters[e.field]=e.value        
      })

      // categorize filters
      let availableFilters = SimLokiDatabaseActions.getAll(translation.collection).length>0?Object.keys(SimLokiDatabaseActions.getAll(translation.collection)[0]):[] 
      let topLevelFilters = {}
      let deepFilters = {}
      let skippedFilters = {}
      Object.keys(translation.filters).forEach(k=>{ 
        if(k!='upTime' && translation.filters[k].indexOf(',')>-1) translation.filters[k]={'$in':translation.filters[k].split(',')};
        //upTime has a string with ',' which should not be split
        if(k=='lastUpdateTime') translation.filters[k]={'$lt':new Date().getTime()-(parseInt(translation.filters[k])*60*1000)};
        if(k=='upTime'){
         translation.filters[k]=translation.filters[k].replace(',',', ');
        }
       if(k=='platformId'){ 
         if(translation.filters[k].$in){translation.filters[k].$in=translation.filters[k].$in.map(e=>e.replace(' ','+'))}
         else translation.filters[k]=translation.filters[k].replace(' ','+');
       }
       // Checking serires in filter and instead of $in converting into $containAny
       if(k=='series'){ 
        if(translation.filters[k].$in){
          translation.filters[k]['$containsAny'] = translation.filters[k].$in
          delete translation.filters[k].$in
          }
        }
        if(availableFilters.indexOf(k)>-1) topLevelFilters[k]=translation.filters[k]
        else if(columnMapping[`${c.gqlCollection}.${k}`] && columnMapping[`${c.gqlCollection}.${k}`].indexOf(translation.collection)>-1) {
          let translatedFilterName = columnMapping[`${c.gqlCollection}.${k}`]
                                      .substring(columnMapping[`${c.gqlCollection}.${k}`].indexOf('.')+1)
          topLevelFilters[translatedFilterName]=translation.filters[k]
        }
        else if(k.indexOf('.')>-1) deepFilters[k]=translation.filters[k]
        else skippedFilters[k]=translation.filters[k]
      })

      c.collection=translation.collection
      c.translatedFilters=translation.filters
      c.subcollections=translatedSubcollections
      c.topLevelFilters = topLevelFilters
      c.deepFilters = deepFilters
      c.skippedFilters = skippedFilters

      return c
    }

    const getRawLokiDbData = function(lokiDBQuery) {
      let mappings = Mappings.collectionRelations      
      let data = SimLokiDatabaseActions.getFilteredRecordHandler(lokiDBQuery.collection, lokiDBQuery.topLevelFilters)
      data = JSON.parse(JSON.stringify(data));
      data.forEach(e=>{
        delete e.meta; delete e.$loki;
      })
      lokiDBQuery.subcollections.forEach(s=>{
        if(mappings[lokiDBQuery.collection] && mappings[lokiDBQuery.collection][s]) {
          let dataKey = mappings[lokiDBQuery.collection][s][0]
          let joinKey = mappings[lokiDBQuery.collection][s][1]

          SimLokiDatabaseActions.merge(s).to(data).using(dataKey).as(joinKey)
        }
      })

      console.log('raw data for gql', data)

      return data || []
    }

    const formatAndDeepFilter = function(data, q, sampleObjectMarker, variables) {    
      let columnMappings = Mappings.columnNames
      let results = data.map(e=>{
        let newEntry = JSON.parse(JSON.stringify(q.sampleObject))
        let included = true
        let deepFilters = q.deepFilters

        const transform = o=> {
          o.__typename = o.__typename || '__typename'
          Object.keys(o).forEach(k=>{
            
            if(Mappings.arrayTypes.indexOf(k)>-1) {
              if(!e[k]) return null
              o[k] = e[k].filter(eachEK=>{
                for(variableName in variables) {
                  if(Mappings.arrayFiltersBasedOnVariables[variableName])
                    return Mappings.arrayFiltersBasedOnVariables[variableName](eachEK, variables)
                }
                return true
              })

              return 
            }


            if(typeof o[k]==='object' && o[k]!==null) transform(o[k])
            else {
              let uiKey = k, dbKey = k;
              if(uiKey.indexOf(':')>-1) {
                var fields = uiKey.split(':');
                uiKey = fields[0].trim();//uiKey
                dbKey = fields[1].trim();//dbKey
                if(o[k].indexOf('.')>-1) {
                  dbKey = o[k].substring(0, o[k].lastIndexOf('.')+1)+uiKey;
                }
                o[uiKey] = dbKey;
                delete o[k];
              }
              
              let val = getByAddress(e, columnMappings[o[uiKey]] || o[uiKey])
              if(deepFilters[o[uiKey]]) {
                if(deepFilters[o[uiKey]] != "GT/:/0"){
                  if(val==null || deepFilters[o[uiKey]].toLowerCase()!==val.toLowerCase()){included=false; return}
                }
              }
              //o[uiKey] = val || null
              o[uiKey] = val==undefined ? null : val;
            }
          })          
        }

        const reFormat = o=> {
          Object.keys(o).forEach(k=>{
            if(Mappings.specialHandling[k]) {
                o[k] = Mappings.specialHandling[k](o[k])
              }
            else if(typeof o[k]==='object' && o[k]!==null) reFormat(o[k])            
          })
        }

        transform(newEntry)
        reFormat(newEntry)
        return included?newEntry:null
      })

    let finalResultsObj = results.filter(e=>e!==null)
    finalResultsObj = handleResultExceptions(finalResultsObj);
    let response = {data:{}}      
    response.data[q.gqlCollection]= {};
    response.data[q.gqlCollection].totalCount = finalResultsObj.length;
    response.data[q.gqlCollection][sampleObjectMarker] = finalResultsObj;
    updateMandatoryTypeName(response, q.gqlCollection);

    console.log('Parsed GraphQL Query:', q, variables)
    //console.log(data)

    if(q.gqlCollection==='metrics: monthlyHeatmapRadio36PaginatedInTimeRange') {
      response.data.metrics = response.data['metrics: monthlyHeatmapRadio36PaginatedInTimeRange']
      response.data.metrics.__typename="MonthlyHeatmapRadio36PaginatedInTimeRangeConnection"
      delete(response.data['metrics: monthlyHeatmapRadio36PaginatedInTimeRange'])
      response.data.__typename = "MonthlyHeatmapRadio36PaginatedInTimeRangeConnection"
    }

    return response

    }

    handleExceptionalRequests = function(queryString) {
      //if there are some actions where table has to be updated before GraphQL reads from it, handle them here
      if(queryString.indexOf('deviceImage') > -1) {
        //as part of handling swim image upgrade with delay
        var records = SimLokiDatabaseActions.getAll('network-device');
        var ids = "";
        for(var i=0; i<records.length; i++) {
          ids += records[i].id + ",";
        }
        ids = ids.substring(0, ids.lastIndexOf(','));
        var createdUrlAction = {"filter": {"id": ids}};
        SimDeviceImageData.fetchRunningImage(createdUrlAction);
      } else if(queryString.indexOf('RmaSchema') > -1 || queryString.indexOf('rmaStatusStore') > -1 || queryString.indexOf('rmaStoreStatus') > -1) {
        //as part of handling status updation of Replace Device with delay
        SimDeviceImageData.fetchRunningImage();//for device-compliance table to add entry for replaced
        SimDeviceReplacementData.checkReplacementStatus();
      }
      if(!window.localStorage.getItem('isSdwanDevAttached')) {
          //fetchAPI()
          let getSdwanDevices = JSON.parse(localStorage.getItem(`sdwan_devices`));
          console.log('getSdwanDevices', getSdwanDevices);
          addData(getSdwanDevices);
      } 
    }    

    fetchAPI()
    async function fetchAPI() {
      var DNACHostName = window.location.host;
      var url = 'https://172.29.58.90/dataservice/partner/dnac/map/bd0d405e-0d6c-48f5-8326-2ef6e6bf4365';
      if (DNACHostName.toLowerCase().indexOf('dcloud') > -1) {
        var availableRegions = ['lon', 'rtp', 'sjc', 'sng'];
        var region = "";
        for (var i = 0; i < availableRegions.length; i++) {
          if (DNACHostName.toLowerCase().indexOf(availableRegions[i]) != -1) {
            region = availableRegions[i];
            break;
          }
        }
        url = 'https://dcloud-sdwan-sim-inst-xxx.cisco.com/dataservice/partner/dnac/map/bd0d405e-0d6c-48f5-8326-2ef6e6bf4365';
        url = url.replace("xxx", region)
      }
      
      try {  
        const response = await fetch(url, 
        {
          credentials: 'include',             
          referrerPolicy:'origin-when-cross-origin',
          headers: {              
          "Content-Type": "application/json"              
        },  
          
      });
        const data = await response.json();
        console.log('DATA',data) 
        window.localStorage.setItem(`sdwan_devices`, JSON.stringify(data));       
      } 
      catch (error) {
        console.log('API ERROR', error);
      }
    }

    function addData(data) {                 
      console.log('NO DATA IN FETCH')
      
      var user_id = UtilityFunctions.generate_uuid();      
      let getLocalStorage = JSON.parse(localStorage.getItem(`sdwan_devices`));

      if(getLocalStorage && getLocalStorage.devices && getLocalStorage.devices.length) {
        let len = getLocalStorage.devices.length;                     
        for (let i = 0; i < len; i++) {
          var id = UtilityFunctions.generate_uuid();
          let devicesAttach = JSON.parse(JSON.stringify(DefaultConfig.SDWAN_DEVICES))
          devicesAttach.id = id;
          devicesAttach.deviceId = id;
          devicesAttach.hostname = getLocalStorage.devices[i]['host-name'];
          devicesAttach.managementIpAddress = getLocalStorage.devices[i]['system-ip'];
          devicesAttach.serialnumber = getLocalStorage.devices[i].serialNumber;
          devicesAttach.macAddress = UtilityFunctions.incrementMacAddrres(devicesAttach.macAddress,i+1);
          devicesAttach.deviceInfoId = id;
          devicesAttach.reachabilityStatus = 'Ping Reachable';
          devicesAttach.collectionStatus = 'Partial Collection Failure';//'SNMP Authentication Failure';
          devicesAttach.errorCode = "SNMP-AUTH-ERROR";
          devicesAttach.errorDescription = "NCIM12006: SNMP for the device could not be discovered. Please ensure correct credentials are available in global credentials.";
          SimNetworkDeviceData.addDevice(JSON.parse(JSON.stringify([devicesAttach])));     

          var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'id':id });
          if(recordObj && recordObj.length) {
            console.log('added SDWAN Device :', recordObj);
            SimInterfaceData.associateInterfacesToSpecificDevice(recordObj,  true);
          }

          var validRecords = [];
          validTypes= ['Cisco Catalyst 9300 Switch','Cisco Catalyst WS-C3850-12X48U-S Switch',
'Cisco Catalyst 9404R Switch','Cisco Catalyst 9300L Switch Stack'];
          if(validTypes.indexOf(devicesAttach.type) > -1) {
            validRecords.push(devicesAttach);
          }
          if(validRecords.length>0) SimPoeAnalyticsData.createPoeDetails(validRecords);
          
          SimNetworkDeviceData.addDeviceInfo({ 'networkDeviceId': devicesAttach.id, 'name': devicesAttach.hostname})
          createDeviceConfigStatus(devicesAttach);
          
          var deviceRecord = {type:devicesAttach.type, id:devicesAttach.id, softwareVersion:devicesAttach.swversion, platformId:devicesAttach.platformid, name:devicesAttach.hostname}
          console.log('Device Record', deviceRecord);
          if (["Cisco 4331 Integrated Services Router","Cisco 4461 Series Integrated Services Router", 
            "Cisco 4321 Integrated Services Router"].indexOf(devicesAttach.type) == -1) {
            SimSwimData.manualAssignmentDeviceToimage(deviceRecord);
          }
          var sdwanObj = {"vmanageDevice":true,"deviceuuid":id,"isVmanageDevice":true};
          sdwanObj['deviceManagementAddress'] = devicesAttach.managementIpAddress;
          SimLokiDatabaseActions.insert('vmanage-devices', sdwanObj);
        }
        window.localStorage.setItem("isSdwanDevAttached", true);            
      }
    }

    createDeviceConfigStatus = function(jsonObj) {
      var tJson = JSON.parse(JSON.stringify(DeviceConfigStatusTemplate.Device_Config_Status_Template));
      var id = UtilityFunctions.generate_uuid();
      tJson.id = id;
      tJson.instanceUuid = id;
      tJson.deviceId = jsonObj.deviceId;
      tJson.status = "Success";
      tJson.aggregatedStatus = 'Not Provisioned';
      tJson.role = jsonObj.role;
      tJson.family = jsonObj.family;
      tJson.hostname = jsonObj.hostname;
      tJson.managementIpAddress = jsonObj.managementIpAddress;
      tJson.type = jsonObj.type;
      tJson.lastUpdateTime = UtilityFunctions.getTimeStamp();
      tJson.lastProvisionedTime = "";
      tJson.workflowId = null;
      SimLokiDatabaseActions.insert('DeviceConfigStatus', tJson);
    },


    handleFilterExceptions = function(filterObj) {  
      if(filterObj.value.includes('.*')) filterObj.value = filterObj.value.replace(/.\*/g,'');
      var field = filterObj.field, value = filterObj.value;
      valueArr = value.split(','), modifiedValueArr=[];
      var updateValObj=Mappings.filterExceptions.updateValue;
      Object.keys(updateValObj).forEach(function(key) {
        /* if(filterObj.field===key && updateValObj[key][value]!=undefined){
          filterObj.value=updateValObj[key][value];
        } */
        if(filterObj.field===key) {
          valueArr.forEach(val => {
            if(updateValObj[key][val]!=undefined) {
              modifiedValueArr.push(updateValObj[key][val]);
            } else {
              modifiedValueArr.push(val);
            }
          });
          filterObj.value = UtilityFunctions.convertArrayToCommaSeperated(modifiedValueArr);
        }
      });
      var updateKeyObj=Mappings.filterExceptions.updateKey;
      Object.keys(updateKeyObj).forEach(function(key) {
        if(key===field){
          filterObj.field=updateKeyObj[key];
        }
      });
    }

    //after response is formed, if there is additional handling, it is done here
    handleResultExceptions = function(finalResultsObj) {
      getGroupNameHierarchy(finalResultsObj);
      updateToEmptyArray(finalResultsObj);
      updateApInInsights(finalResultsObj);
      finalResultsObj = updateRma(finalResultsObj);
      return finalResultsObj;
    }

    getGroupNameHierarchy = function(finalResults){
      for(var j=0;j<finalResults.length;j++){
        if(finalResults[j].site){
          if(finalResults[j].site.groupNameHierarchy==undefined || finalResults[j].site.groupNameHierarchy=="" || finalResults[j].site.groupNameHierarchy==null){
            recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'id': finalResults[j].site.id });
            if (recordObj && recordObj.length){
              finalResults[j].site.groupNameHierarchy= recordObj[0].groupNameHierarchy;
            }
          }
        }
      }
      return finalResults;
    }

    updateToEmptyArray = function(finalResults) {
      
      //var isImagePresent = finalResults[0].deviceImage && finalResults[0].deviceImage.deviceInstalledInfo;
      var isImagePresent = finalResults.length>0 && finalResults[0].deviceImage && finalResults[0].deviceImage.deviceInstalledInfo && finalResults[0].deviceImage.deviceInstalledInfo.length>0;
      for(var j=0;j<finalResults.length;j++) {        
        if(isImagePresent) {
          //update only for calls image related info - Software Image focus
          if(finalResults[j].deviceImage.deviceInstalledInfo[0].name == "") {
            finalResults[j].deviceImage.deviceInstalledInfo = [];
          }
        }
      }
    }

    updateApInInsights = function(finalResults) {
      for(var j=0;j<finalResults.length;j++) {
        if(finalResults[j].ap) {
          if(finalResults[j].ap.aesApName == null) {
            finalResults[j].ap = null;
          }
        } else { break; }
      }
    }

    updateRma = function(finalResults) {
      if(finalResults.length>0 && finalResults[0].RmaSchema!=undefined) {
        var newData = [];
        for(var j=0; j<finalResults.length; j++) {
          if(finalResults[j].RmaSchema.deviceName != null || finalResults[j].RmaSchema.faultyDeviceName != null) {
            newData.push(finalResults[j]);
          }
        }
        return newData;
      } else { return finalResults; }
    }

    /*** This filter will be performed on the outer object based on filter conditiones in variable only.
      If filter needs to be performed on inner object, it should be handled in transform of formatAndDeepFilter.
      Filter conditions present in filter of query would already have been handled in translateQueryComponents. */
    const filterBasedOnVariables = function(filters, operationName, variables, payloadVariables) {
      if(operationName && Mappings.variableFiltersToHandle[operationName]) {
        Mappings.variableFiltersToHandle[operationName].forEach(filterField => {
          if(variables[filterField]) {
            filters.push({'field':filterField, 'value':variables[filterField]});
          }
        });
      }
      if(payloadVariables && payloadVariables.filters) {
        payloadVariables.filters.forEach(e => {
          if(e.field && e.value)
            filters.push({'field':e.field, 'value':e.value});
        });
      }
      return filters;
    }

    /** if second level filter has to be applied but the response doesnt have that field, formatAndDeepFilter cant handle. 
    hence adding the field to the response additionally */
    appendDeepFilterSampleObj = function(qComps) {
      var addOnFields = [];
      qComps.filters.forEach(filterObj => {
        if(filterObj.field.indexOf('.')>-1 && (!qComps.sampleObject[filterObj.field[0]] && !qComps.sampleObject[filterObj.field[1]])) {
          addOnFields.push(filterObj.field)
        }
      });
      stringToObject(addOnFields, qComps); 
    }

    const stringToObject = (arr, qComps) => {
      arr.forEach(str => {
         let curr = qComps.sampleObject;
         let splitted = str.split('.');
         qComps.subcollections.push(splitted[0]);
         let last = splitted.pop();
         splitted.forEach( sub => {
            if(!curr.hasOwnProperty(sub)){
               curr[sub] = {};
            };
            curr = curr[sub];
         });
         if(!curr[last]){
            curr[last] = [];
         };
         curr[last] = str;
      });
      return qComps;
   };

    updateMandatoryTypeName = function(response, gqlCollection) {
      var typename = Mappings.typeNames[gqlCollection];
      if(typename) {
        response.data[gqlCollection]["__typename"] = typename;
      }
    }

     getDeviceStackInfo = function(queryString,variables,Json,objArr){
        var qComps = parse(queryString,variables);
        var deviceId = qComps.filters[0].value;
        var deviceInfo = objArr.filter(itm => itm.id == deviceId);
        var platformId = deviceInfo[0].platformId.split(',');
        var serialNumber = deviceInfo[0].serialNumber.split(',');
        var priority = 10;
        var len = platformId.length
        Json.data.allDeviceStack.totalCount = len;
        for(var i=0; i<len; i++){
            var obj = {};
            obj.stackMemberNumber = i+1;
            obj.role = (i == 0)? 'MASTER': (i == 1)? "STANDBY" : "MEMBER";
            obj.macAddress = (i== 0) ? deviceInfo[0].macAddress: UtilityFunctions.incrementMacAddrres(deviceInfo[0].macAddress,i);
            obj.state = "READY";
            obj.switchPriority = priority;
            obj.switchPort =
            `${i+1}/1<span style=\'font-weight: bold\'> -> </span>${i==0?len:i}/2,
             </br>${i+1}/2<span style=\"font-weight: bold\"> -> </span>${(i+2)>len?1:(i+2)}/1`
            obj.__typename = "DeviceStackSchema";
            Json.data.allDeviceStack.items.push(obj);
            priority-= 1
        }
        return Json;
    }

   return publicInterface
});

//todo: handle errors
const extract = (component, str)=> {

  const findClosingParantheses = str=>{
    let tally = 0;
    for(let i=0; i<str.length;i++){    
      if(str[i]==='{') tally+=1
      if(str[i]==='}') {
        tally-=1
        if(tally===0) return i      
      }
    }

    return -1  
  }

  let queryStrStart = str.indexOf(component)
  if(queryStrStart>-1) {
    let queryStrTemp = str.substring(str.indexOf('{', queryStrStart))        
    let queryStr = queryStrTemp.substring(0,findClosingParantheses(queryStrTemp)+1) 
    return queryStr
  }

  else return ""
}

const getCollection = q=> q.substring(1,q.indexOf('(')).trim()

//written explicitly to handle mapView in provision-inventory
const handleParseError = filterString => {
    if(filterString){
        try{
            filterString = JSON.parse(filterString)
        } catch (e) {
            if(filterString.includes("$groupNameHierarchy")) {
                filterString = filterString.replace("$groupNameHierarchy", "\"$groupNameHierarchy\"")
            }
            filterString = JSON.parse(filterString)
        }
        return filterString
    }
    return [];
}

const getFilters = q=> {
  let filters = []

  if(q.indexOf('filters:')>-1) {
    let filterString = q.substring(q.indexOf('[', q.indexOf('filters:')),q.indexOf(']', q.indexOf('filters:'))+1).replace(/field/g,'"field"').replace(/value/g,'"value"')
    filters = handleParseError(filterString)
    for(var i=filters.length-1; i>=0; i--) {
      if(filters[i].value.startsWith("RX/:/")) filters.splice(i,1);
    }
  }
  return filters
}

const parse = (str, variables, isAnotherFilterField)=> {
  let query = str.substring(str.indexOf('{'))
  let collection = getCollection(query)  
  let filters = getFilters(query)
  let sampleObjectMarker = query.indexOf('nodes')>-1?'nodes':'items'
  let items = extract(sampleObjectMarker, query)

  //Provision uses \n, while Rough management uses spaces
  let lines = items.split('\n').map(e=>e.trim()).slice(1,-1)
  if(lines.length ==0) {
    lines = items.split(' ').map(e=>e.trim()).slice(1,-1);
  }
  let sampleObject = {}
  let parentStack = [sampleObject]
  let prefix = ""
  let subcollections = []

  // populate a sample object in the required output format with values being the flattened hierarchy of the key
  // also, identify subcollections
  lines.forEach(line=>{    
    if(line.indexOf('{')>-1) {
      let newParent = line.replace('{',"").trim()
      if(parentStack[parentStack.length-1][newParent] == undefined) {
        parentStack[parentStack.length-1][newParent] = {}
      }
      parentStack.push(parentStack[parentStack.length-1][newParent])
      if(parentStack.length===2) subcollections.push(newParent)
      prefix=prefix+line.replace('{',"").trim()+"."
      return
    }
    if(line.indexOf('}')>-1) {
      parentStack.pop()
      prefix=prefix.substring(0, prefix.slice(0,-1).lastIndexOf('.')+1)
      return
    }
    if(line.indexOf('@include(if:') > -1) {
      var conditionField = line.substring(line.indexOf('$')+1,line.indexOf(')'));
      if(variables && variables[conditionField]){
        line = line.substring(0,line.indexOf('@include(if:')-1);
      } else {
        return;//continue to next iteration
      }
    }
    parentStack[parentStack.length-1][line]=prefix+line
  })
  
  if(isAnotherFilterField) {
    filters.forEach(x => {
      if(variables[x.value.replace('$','')]) x.value = variables[x.value.replace('$','')];
    })
  }

  return {sampleObject:sampleObject, gqlCollection:collection, collection:collection, subcollections: subcollections, filters:filters}

}

const getByAddress = (obj, addr)=>{  
  let nodes = addr.split('.')
  let branches = nodes.slice(0,-1)
  let leaf = nodes[nodes.length-1]

  let location = branches.reduce((current, next)=>{    
    if(current !== null && current[next]) return current[next]
    else return null
  }, obj)

  if(location !== null) return location[leaf]
  else return null
}

const getDeviceSpecificPortChannel = (queryString, variables, objArr, result) => {
    let qComps = parse(queryString, variables)

    if(qComps.filters.length) {
        var deviceId = qComps.filters[0].value, idRef = qComps.filters[1].value;
        objArr = objArr.filter(function (itm) { return (itm.networkDeviceId == deviceId && itm.id == idRef) }) 
    }
    var len = objArr.length;
    result.data.allDevicePortChannel.totalCount = len;
    for(var i = 0; i < len; i++) {
        result.data.allDevicePortChannel.items.push({ id: objArr[i].id, interfaceInfo: objArr[i].interfaceInfo, __typename: "DevicePortChannelSchema"});   
    }
    return result;
}

const getDeviceSpecificResponse = (queryString, variables, objArr, result) => {
  let qComps = parse(queryString, variables)

  if (qComps.filters.length) {
    var deviceId = qComps.filters[0].value;
    var objArr = objArr.filter(function (itm) { return (itm.id == deviceId) })
  }
  var len = objArr.length;
  result.data.allDevices.totalCount = len;
  for (var i = 0; i < len; i++) {
    result.data.allDevices.items.push({
      "description": null, "family": objArr[i].family,
      "hostname": objArr[i].hostname,
      "role": objArr[i].role,
      "managementIpAddress": objArr[i].managementIpAddress,
      "macAddress": objArr[i].macAddress,
      "reachabilityStatus": objArr[i].reachabilityStatus,
      "series": objArr[i].series,
      "siteId": objArr[i].siteId,
      "stackCount": {
        "stackSupported": true,
        "svlSupported": true,
        "__typename": "DeviceStackSchema"
      },
      "upTime": objArr[i].upTime,
      "upTimeSeconds": objArr[i].upTimeSeconds,
      __typename: "DevicesSchema",

      __typename: "allDevicesResponse"
    });
  }
  return result;
}


const getDeviceSpecificResponse1 = (queryString, variables, objArr, result) => {
  let qComps = parse(queryString, variables)

  if (qComps.filters.length) {
    var deviceId = qComps.filters[0].value;
    var objArr = objArr.filter(function (itm) { return (itm.id == deviceId) })
  }
  var len = objArr.length;
  result.data.allDeviceStore.totalCount = len;
  for (var i = 0; i < len; i++) {
    result.data.allDeviceStore.items.push(
      {
        "family": objArr[i].family,
        "upTime": objArr[i].upTime,
        "hostname": objArr[i].hostname,
        "role": objArr[i].role,
        "errorCode": objArr[i].errorCode,
        "macAddress": objArr[i].macAddress,
        "id": objArr[i].id,
        "managementIpAddress": objArr[i].managementIpAddress,
        "managementState": objArr[i].managementState,
        "reachabilityStatus": objArr[i].reachabilityStatus,
        "role": objArr[i].role,
        "series": objArr[i].series,
        "deviceId": objArr[i].deviceId,
        "description": objArr[i].description,
        // "site": {
        //   "groupNameHierarchy": Global/North_America/USA/NewYork/NY1/SJC01,
        //     "id": objArr[i].id,
        //     "name": objArr[i].sitename,
        //   // "groupNameHierarchy": objArr[i].siteId,
        //    "__typename": "SiteStore"
        // },
        "__typename": "DeviceStore"
      }
    );
  }
  return result;
}

const getDeviceSpecificInterfaces = (queryString, variables, objArr, result) => {
    let qComps = parse(queryString, variables)

    if(qComps.filters.length == 2){
      var deviceId = qComps.filters[0].value, f1 = qComps.filters[1].value.replace('.*','').replace('.*','');
      var field = qComps.filters[1].field;
      if (field == "portName"){
        objArr = objArr.filter(function (itm) { return (itm.deviceId == deviceId && itm.portName == f1) });
      }else if(field == 'macAddress'){
        objArr = objArr.filter(function (itm) { return (itm.deviceId == deviceId && itm.macAddress == f1) });
      }else if(field == 'status'){
        objArr = objArr.filter(function (itm) { return (itm.deviceId == deviceId && itm.status == f1) });
      }
      // objArr = objArr.filter(function (itm) { return (itm.field == f1) })
    }else if(qComps.filters.length) {
        var deviceId = qComps.filters[0].value;
        objArr = objArr.filter(function (itm) { return (itm.deviceId == deviceId ) })
    }
    var len = objArr.length;
    result.data.allDeviceInterfaces.totalCount = len;
    for(var i = 0; i < len; i++) {
        var obj = {};
        obj.id = objArr[i].id;
        obj.interfaceType = objArr[i].interfaceType;
        obj.portMode = objArr[i].portMode;
        obj.portName =  objArr[i].portName;
        obj.macAddress = objArr[i].macAddress;
        obj["status"] = objArr[i].status;
        obj.description = objArr[i].description;
        obj["__typename"] = "DeviceInterfacesSchema" 
        result.data.allDeviceInterfaces.items.push(obj);
    }
    return result;
}


