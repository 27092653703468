define([

], function () {
    return {
        init: function () {},

        doProcessRestRequest: function(urlAction){
            var data;
            switch(urlAction.method){
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                case 'POST':
                    data = processPostRequest(urlAction);
                    break;

                case 'DELETE':
                    data = processDeleteRequest(urlAction);
                    break;

                default:
                    break;
            }
            return data;
        }
    };

    function processGetRequest(urlAction){
        if(urlAction.url.endsWith('verify-vmanage-cert?iphostname=172.29.58.90&port=8443')){
           return {"statusMessage":"Certificate valid.","detailsMessage":""}
        }

        if(urlAction.url.indexOf('vmanage-credentials')> -1){
            return {"ipHostName":"172.29.58.90","userName":"dcnadmin","password":"*******","port":"8443","vbondIp":"","orgName":"","fileId":"a6e777de-828c-465f-ba86-1fe29ee59cda","fileName":"vmanage.pem","vmanagePartnerId":"5f35f5bd9962950017a47388","certSerialno":"1671658671","certIssuer":"CN=vmanage, OU=Cisco SDWAN, O=Cisco Systems, L=San Jose, ST=CA, C=US","certificateDelete":false}
        }
    }
    function processPostRequest(urlAction){

    }

    function processDeleteRequest(urlAction){

    }


    
});