define({
    "headerTemplate":{
        "generationId": 0,
        "dirty": false,
        "value": 0,
        "isTagFromRange": "false",
        "numericRepresentation": "0/0000",
        "icon": 0,
        "readOnly": "false",
        "propogateToApic": "false",
        "name": "",
        "id": "",
        "description": ""
    },
    "contentTemplate":{
        "type": "SgtsPair",
        "sourceSgtName": "",
        "destinationSgtName": "",
        "sourceSgtId": "",
        "destinationSgtId": "",
        "sgaclNameList": "",
        "status": "",
        "cellContent": "SgtsPairContent",
        "isCellDefault": false,
        "colorPattern": "color:#EBF1DE,pattern:P0",
        "isCellDeltaReport": false
    },
    "headerTemplate":{
        "generationId": 0,
        "dirty": false,
        "value": 0,
        "isTagFromRange": "false",
        "numericRepresentation": "0/0000",
        "icon": 0,
        "readOnly": "false",
        "propogateToApic": "false",
        "name": "",
        "id": "",
        "description": ""
    },
    "contentSettings": {
        "allowMultipleSGACLS": false,
        "allowMonitoring": true,
        "showSGTNumbers": true,
        "theme": "customTheme",
        "defaultTheme": {
          "colorPalette": "#EBF1DE,#F2DCDB,#DCE6F1,#C0D39A,#DFABA8,#A3BDDA,#8AA949,#C9706B,#5586BB,#FFFFFF,#000000,#FF0000,#FF8000,#990000,#FFF000,#008000,#0000FF,#4B0082,#9400D3",
          "patternPalette": "P0,P1,P11,P2,P21,P3,P31,P4,P41,P5,P51,P6,P61,P7,P71,P8,P81",
          "defaultMx": {
            "permit": "color:#EBF1DE,pattern:P0",
            "deny": "color:#F2DCDB,pattern:P0",
            "sgacl": "color:#DCE6F1,pattern:P0",
            "mId": "defaultTheme-defaultMx",
            "mEDFVersion": 0
          },
          "setInCell": {
            "permit": "color:#8AA949,pattern:P0",
            "deny": "color:#C9706B,pattern:P0",
            "sgacl": "color:#5586BB,pattern:P0",
            "mId": "defaultTheme-inCell",
            "mEDFVersion": 0
          },
          "mId": "defaultTheme",
          "mEDFVersion": 0
        },
        "accessibilityTheme": {
          "colorPalette": "#EBF1DE,#F2DCDB,#DCE6F1,#C0D39A,#DFABA8,#A3BDDA,#8AA949,#C9706B,#5586BB,#FFFFFF,#000000,#FF0000,#FF8000,#990000,#FFF000,#008000,#0000FF,#4B0082,#9400D3",
          "patternPalette": "P0,P1,P11,P2,P21,P3,P31,P4,P41,P5,P51,P6,P61,P7,P71,P8,P81",
          "defaultMx": {
            "permit": "color:#EBF1DE,pattern:P31",
            "deny": "color:#F2DCDB,pattern:P41",
            "sgacl": "color:#DCE6F1,pattern:P51",
            "mId": "accessibilityTheme-defaultMx",
            "mEDFVersion": 0
          },
          "setInCell": {
            "permit": "color:#008000,pattern:P61",
            "deny": "color:#990000,pattern:P71",
            "sgacl": "color:#5586BB,pattern:P81",
            "mId": "accessibilityTheme-inCell",
            "mEDFVersion": 0
          },
          "mId": "accessibilityTheme",
          "mEDFVersion": 0
        },
        "customTheme": {
          "colorPalette": "#EBF1DE,#F2DCDB,#DCE6F1,#C0D39A,#DFABA8,#A3BDDA,#8AA949,#C9706B,#5586BB,#FFFFFF,#000000,#FF0000,#FF8000,#990000,#FFF000,#008000,#0000FF,#4B0082,#9400D3",
          "patternPalette": "P0,P1,P11,P2,P21,P3,P31,P4,P41,P5,P51,P6,P61,P7,P71,P8,P81",
          "defaultMx": {
            "permit": "color:#EBF1DE,pattern:P0",
            "deny": "color:#F2DCDB,pattern:P0",
            "sgacl": "color:#DCE6F1,pattern:P0",
            "mId": "customTheme-defaultMx",
            "mEDFVersion": 0
          },
          "setInCell": {
            "permit": "color:#8AA949,pattern:P0",
            "deny": "color:#C9706B,pattern:P0",
            "sgacl": "color:#5586BB,pattern:P0",
            "mId": "customTheme-inCell",
            "mEDFVersion": 0
          },
          "mId": "customTheme",
          "mEDFVersion": 0
        },
        "mId": "mxSettings",
        "mEDFVersion": 0
      }
})