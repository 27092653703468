const platform = require("../harbor-platform").platform;
//simulation addition - start
const SimulationAppRegister = require("../../../../public/app/devices/dnac_sinon_sim/lib/SimulationAppRegister");
//simulation addition - end

define(function(require) {
  const PluginContributions = require("core/utils/PluginContributions");
  // const whitelist = ["cisco.dna.core.shell"];

  //
  // Load & run startup code from all plugins contributing to the "startup"
  // extension point of "cisco.dna.core".
  //
  function loadStartupPlugins() {
    //simulation additions - start
    if (getCookie('simInit')) {
      console.log('Sim session alive; reusing existing data');
      var simulationApp = SimulationAppRegister.getInstance({ KEEP_EXISTING_DATA: true });
    }
    else {
      console.log('New sim session; generating new data');
      document.cookie = "simInit=true"
      var simulationApp = SimulationAppRegister.getInstance({ KEEP_EXISTING_DATA: false });
    }
    //simulation additions - end
    const startupPlugins = new PluginContributions("cisco.dna.core").forExtensionPoint(
      "startup"
    );
    // .filter(p => whitelist.indexOf(p.sourcePluginId) !== -1);
    if (startupPlugins.length) {
      startupPlugins.forEach(entry => entry.loadModule());
    } else {
      console.info("No startup plugins found...");
    }
  }
 //simulation additions - start
  function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1);
        if (c.indexOf(name) != -1) return c.substring(name.length, c.length);
    }
    return false;
}
//simulation additions - end

  return function() {
    return platform.init().then(loadStartupPlugins);
  };
});
