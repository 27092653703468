define({
    "Device_Info_Template": {
        "id": "",
        "instanceId": 81100,
        "authEntityId": 81100,
        "displayName": "",
        "authEntityClass": -172350690,
        "instanceVersion": 0,
        "createTime": 1492627021532,
        "deployed": true,
        "isStale": false,
        "lastUpdateTime": 1492627021532,
        "name": "",
        "namespace": "",
        "provisioningState": "DEPLOYED",
        "type": "DeviceInfo",
        "version": 1,
        "cfsChangeInfo": [],
        "configs": [],
        "networkDeviceId": "",
        "roles": [],
        "managedSites": [],
        "siteId": "",
        "akcSettingsCfs": [],
        "connectivityDomain": [],
        "deviceInterfaceInfo": [],
        "networkWideSettings": {
            "id": "aeaa1cd1-f509-4717-98d2-1d595bbdd24f",
            "instanceId": 351352,
            "displayName": "0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "aaa": [],
            "dhcp": [],
            "dns": [],
            "ldap": [],
            "netflow": [],
            "ntp": [],
            "snmp": [],
            "syslogs": []
        },
        "wlan": [],
        "apgroupConfiguration": [],
        "otherDevice": [],
        "virtualNetwork": [],
        "transitNetworks": [],
        "deviceSettings":""
    },

    "Device_Settings_Template" : {
              "externalDomainRoutingProtocol": "BGP",
              "externalConnectivityIpPool": "351473ff-a17e-dcf0-c451-ba0288ead5ae",
              "extConnectivitySettings": [],
              "internalDomainProtocolNumber": "65001",
              "nodeType": ["INTERNAL","EXTERNAL"],
              "connectedTo": []
    },

    "Device_Interface_Info":
      {
        "interfaceId": "8c95b69a-4bc5-4a82-a9fd-f70bc1241d24",
        "role": "LAN",
        "segment": [

        ],
        "connectedDeviceType": "EXTENDED_NODE",
        "authenticationProfileId": "06546b5d-7cb2-4cf9-8380-5eb862fe7fa4"
      },
    
      "PortChannel_Interface_Template": {
          "description":null,
          "duplex":"AutoNegotiate",
          "interfaceType":"Virtual",
          "ipv4Address":null,
          "ipv4Mask":null,
          "isisSupport":"false",
          "mappedPhysicalInterfaceId":null,
          "mappedPhysicalInterfaceName":null,
          "nativeVlanId":null,
          "ospfSupport":"false",
          "pid":"",
          "vlanId":"0",
          "portName":"Port-channel1",
          "deviceId":"",
          "mediaType":null,
          "speed":null,
          "ifIndex":null,
          "macAddress":"00:00:00:00:00:00",
          "portMode":"trunk",
          "portType":"Others",
          "serialNo":"",
          "voiceVlan":null,
          "lastUpdated":"2019-06-09 07:41:52.845",
          "status":"up",
          "adminStatus":"NOT_APPLICABLE",
          "className":"SwitchPort",
          "series":"",
          "instanceTenantId":"5cec9a5a0bc3a300b385d500",
          "instanceUuid":"",
          "id":""
        }


});
