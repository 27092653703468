define({
    "Template":
    {
        "request": {
          "key": "apple-services",
          "type": "Application",
          "label": "apple-services",
          "collection": false,
          "componentContent": [
            {
              "component": "fusion",
              "additional": "66aa91e2-bcb8-41f8-a06a-8a0ab7cfb4e6"
            }
          ]
        },
        "data": {
          "response": {
            "id": "66aa91e2-bcb8-41f8-a06a-8a0ab7cfb4e6",
            "instanceId": 4107,
            "authEntityId": 4107,
            "displayName": "4107",
            "authEntityClass": -1909073334,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "createTime": 1528265509421,
            "deployed": false,
            "isSeeded": true,
            "isStale": false,
            "lastUpdateTime": 1528265509421,
            "name": "apple-services",
            "namespace": "scalablegroup:application",
            "provisioningState": "DEFINED",
            "qualifier": "application",
            "resourceVersion": 0,
            "targetIdList": [],
            "type": "scalablegroup",
            "cfsChangeInfo": [],
            "customProvisions": [],
            "scalableGroupExternalHandle": "apple-services",
            "scalableGroupType": "APPLICATION",
            "securityGroupTag": 0,
            "state": "ACTIVE",
            "vnAgnostic": true,
            "identitySource": {
              "id": "607e5f6a-d4bd-41e6-9861-bcd939c316b6",
              "instanceId": 10010,
              "authEntityId": 10010,
              "displayName": "10010",
              "authEntityClass": 1738953278,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "state": "INACTIVE",
              "type": "NBAR"
            },
            "indicativeNetworkIdentity": [],
            "networkApplications": [
              {
                "id": "fa3810f9-f81d-482a-a756-8e0729cc4425",
                "instanceId": 11068,
                "authEntityId": 11068,
                "displayName": "11068",
                "authEntityClass": -217092956,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "appProtocol": "tcp/udp",
                "applicationGroup": "apple-group",
                "applicationType": "DEFAULT",
                "categoryId": "fb8d9520-2bd7-43aa-97b5-c202d09feec2",
                "createTime": 1528265509421,
                "encrypted": "true",
                "engineId": "13",
                "helpString": "Set of tools and APIs used by apple applications",
                "ignoreConflict": false,
                "lastUpdateTime": 1528265509421,
                "longDescription": "apple-services is a set of tools and APIs used by Apple applications. such as: AppStore, apple website and etc.",
                "name": "apple-services",
                "nbarId": "1516",
                "p2pTechnology": "false",
                "popularity": 8,
                "rank": 65535,
                "references": "http://www.apple.com",
                "selectorId": "577",
                "subCategory": "3e937526-a8b3-4856-a80c-c1facca48b6c",
                "trafficClass": "BULK_DATA",
                "tunnel": "false"
              }
            ],
            "networkIdentity": [
              {
                "id": "a98e1bba-0ef0-49ff-a393-d0b65139dfd7",
                "instanceId": 12170,
                "authEntityId": 12170,
                "displayName": "12170",
                "authEntityClass": 124090504,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "ipv4Subnet": [],
                "ipv6Subnet": [],
                "lowerPort": 0,
                "ports": "53,5353",
                "protocol": "UDP",
                "upperPort": 0
              },
              {
                "id": "97793af6-1498-4f9f-b830-2868bd2e5507",
                "instanceId": 12171,
                "authEntityId": 12171,
                "displayName": "12171",
                "authEntityClass": 124090504,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "ipv4Subnet": [],
                "ipv6Subnet": [],
                "lowerPort": 0,
                "ports": "53,80,443,5353",
                "protocol": "TCP",
                "upperPort": 0
              }
            ],
            "parentScalableGroup": {
              "idRef": "a431ac87-190b-4e81-bad0-3f8b1e0e1471"
            },
            "user": [],
            "userGroup": []
          }
        },
        "result": [
          {
            "bookID": {
              "namespace": "fusion",
              "name": "core",
              "version": "1.0.1"
            },
            "response": {
              "id": "66aa91e2-bcb8-41f8-a06a-8a0ab7cfb4e6",
              "instanceId": 4107,
              "authEntityId": 4107,
              "displayName": "4107",
              "authEntityClass": -1909073334,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "createTime": 1528265509421,
              "deployed": false,
              "isSeeded": true,
              "isStale": false,
              "lastUpdateTime": 1528265509421,
              "name": "apple-services",
              "namespace": "scalablegroup:application",
              "provisioningState": "DEFINED",
              "qualifier": "application",
              "resourceVersion": 0,
              "targetIdList": [],
              "type": "scalablegroup",
              "cfsChangeInfo": [],
              "customProvisions": [],
              "scalableGroupExternalHandle": "apple-services",
              "scalableGroupType": "APPLICATION",
              "securityGroupTag": 0,
              "state": "ACTIVE",
              "vnAgnostic": true,
              "identitySource": {
                "id": "607e5f6a-d4bd-41e6-9861-bcd939c316b6",
                "instanceId": 10010,
                "authEntityId": 10010,
                "displayName": "10010",
                "authEntityClass": 1738953278,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "state": "INACTIVE",
                "type": "NBAR"
              },
              "indicativeNetworkIdentity": [],
              "networkApplications": [
                {
                  "id": "fa3810f9-f81d-482a-a756-8e0729cc4425",
                  "instanceId": 11068,
                  "authEntityId": 11068,
                  "displayName": "11068",
                  "authEntityClass": -217092956,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "appProtocol": "tcp/udp",
                  "applicationGroup": "apple-group",
                  "applicationType": "DEFAULT",
                  "categoryId": "fb8d9520-2bd7-43aa-97b5-c202d09feec2",
                  "createTime": 1528265509421,
                  "encrypted": "true",
                  "engineId": "13",
                  "helpString": "Set of tools and APIs used by apple applications",
                  "ignoreConflict": false,
                  "lastUpdateTime": 1528265509421,
                  "longDescription": "apple-services is a set of tools and APIs used by Apple applications. such as: AppStore, apple website and etc.",
                  "name": "apple-services",
                  "nbarId": "1516",
                  "p2pTechnology": "false",
                  "popularity": 8,
                  "rank": 65535,
                  "references": "http://www.apple.com",
                  "selectorId": "577",
                  "subCategory": "3e937526-a8b3-4856-a80c-c1facca48b6c",
                  "trafficClass": "BULK_DATA",
                  "tunnel": "false"
                }
              ],
              "networkIdentity": [
                {
                  "id": "a98e1bba-0ef0-49ff-a393-d0b65139dfd7",
                  "instanceId": 12170,
                  "authEntityId": 12170,
                  "displayName": "12170",
                  "authEntityClass": 124090504,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "ipv4Subnet": [],
                  "ipv6Subnet": [],
                  "lowerPort": 0,
                  "ports": "53,5353",
                  "protocol": "UDP",
                  "upperPort": 0
                },
                {
                  "id": "97793af6-1498-4f9f-b830-2868bd2e5507",
                  "instanceId": 12171,
                  "authEntityId": 12171,
                  "displayName": "12171",
                  "authEntityClass": 124090504,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "ipv4Subnet": [],
                  "ipv6Subnet": [],
                  "lowerPort": 0,
                  "ports": "53,80,443,5353",
                  "protocol": "TCP",
                  "upperPort": 0
                }
              ],
              "parentScalableGroup": {
                "idRef": "a431ac87-190b-4e81-bad0-3f8b1e0e1471"
              },
              "user": [],
              "userGroup": []
            }
          }
        ]
      }
    });