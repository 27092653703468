define({
    "runningImageList": [
        {
            "name": "ME-WLC (16.12.1.0.4)",
            "imageUuid": "a7184c07-79aa-47f2-ad84-1856921734f0",
            "version": "16.12.1.0.4",
            "softwareType": null,
            "imageType": "SYSTEM_SW",
            "isTaggedGolden": "false",
            "md5Checksum": null,
            "shaCheckSum": null,
            "createdTime": null,
            "applicationType": null,
            "feature": null,
            "fileServiceId": null,
            "imageSeries": null,
            "imageSource": null,
            "imageSourceType": null,
            "imageFamily": "MEWLC",
            "vendor": null,
            "filesize": 0,
            "deviceCount": 1,
            "deviceUuidList": [
              "0b3a96fa-791d-49a1-9d4a-444833753627"
            ],
            "smuCount": 0,
            "imageAttributes": [],
            "isSMUApplicable": false,
            "goldenTagInheritedFromSiteUuid": null,
            "goldenTagInheritedFromSiteName": null,
            "importSupported": false,
            "reasonForNotSupportingImport": "Import from Cisco Mobility Express for Catalyst 9115AX Access Points is not supported. Please import from url or local file.",
            "tagList": [
              {
                "taggedGolden": false,
                "role": "ALL",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "CORE",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "DISTRIBUTION",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "BORDER ROUTER",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "UNKNOWN",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              },
              {
                "taggedGolden": false,
                "role": "ACCESS",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
              }
            ],
            "profileInfo": null,
            "physicallyAvailable": true,
            "assignCount": 0,
            "showUnassign": false,
            "disableUnassign": false,
            "imageCategory": "PHYSICAL",
            "imageIntegrityStatus": null,
            "imageImported": false,
            "applicableDevicesForImage": null,
            "profileInfo": null,
            "ccorecommended": false,
            "ccolatest": false,
            "ccoreverseSync": false
        }
    ]
});