define({
  "runningImageList": [
    {
        "name": "C9800-L-universalk9_wlc.17.09.01.SPA.bin",
        "imageUuid": "53212f21-3808-4321-a607-cc1646bcab10",
        "version": "17.9.1",
        "softwareType": null,
        "imageType": "SYSTEM_SW",
        "isTaggedGolden": "true",
        "md5Checksum": "119f4d37e35d0a8d9140c201ac359cfd",
        "shaCheckSum": "e3290c29e42cbc5c6ab6ce3f97e3d18ec1d5fc2b0900f217cbeca4d32638305ddb1a6c0ca261fedcd2571adb76e4a8ac4deb740cc87226c3966536ba3bf0bf8b",
        "createdTime": "2022-10-23 00:04:32.057",
        "applicationType": "UNKNOWN",
        "feature": null,
        "fileServiceId": null,
        "imageSeries": null,
        "imageSource": "CCO",
        "imageSourceType": "Imported from CCO",
        "imageFamily": "C9800-L",
        "vendor": "CISCO",
        "filesize": 1146960149,
        "deviceCount": 0,
        "deviceUuidList": [],
        "smuCount": 0,
        "imageAttributes": [
            {
                "propertyName": "release_doc_url",
                "propertyValue": "https://www.cisco.com/content/en/us/td/docs/wireless/controller/9800/17-9/release-notes/rn-17-9-9800.html"
            },
            {
                "propertyName": "field_notices",
                "propertyValue": "Field Notices"
            },
            {
                "propertyName": "field_notice_url",
                "propertyValue": "https://www.cisco.com/c/en/us/support/wireless/catalyst-9800-series-wireless-controllers/products-field-notices-list.html"
            },
            {
                "propertyName": "metadata_trans_id",
                "propertyValue": "336972849"
            },
            {
                "propertyName": "mdf_id",
                "propertyValue": "286321399"
            },
            {
                "propertyName": "deviceMNEId",
                "propertyValue": "18839868"
            },
            {
                "propertyName": "release_doc_name",
                "propertyValue": "Release Notes for C9800L"
            },
            {
                "propertyName": "udi",
                "propertyValue": "PID: C9800-L-F-K9 VID: 01, SN: FCL241200E4"
            },
            {
                "propertyName": "image_guid",
                "propertyValue": "A1F4E04A940B123A62E54EC0634086FB6F317154"
            },
            {
                "propertyName": "release_fcs_date",
                "propertyValue": "01/Aug/2022"
            },
            {
                "propertyName": "min_flash",
                "propertyValue": "32768"
            },
            {
                "propertyName": "image_description",
                "propertyValue": "Cisco Catalyst 9800-L Wireless Controller"
            },
            {
                "propertyName": "min_dram",
                "propertyValue": "16384"
            },
            {
                "propertyName": "encryption_software_indicator",
                "propertyValue": "Y"
            },
            {
                "propertyName": "image_size",
                "propertyValue": "1131196133"
            }
        ],
        "isSMUApplicable": false,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": true,
        "reasonForNotSupportingImport": null,
        "tagList": [
            {
                "taggedGolden": true,
                "role": "ALL",
                "inheritedSiteName": "",
                "inheritedSiteUuid": ""
            },
            {
                "taggedGolden": false,
                "role": "CORE",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
            },
            {
                "taggedGolden": false,
                "role": "DISTRIBUTION",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
            },
            {
                "taggedGolden": false,
                "role": "BORDER ROUTER",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
            },
            {
                "taggedGolden": false,
                "role": "UNKNOWN",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
            },
            {
                "taggedGolden": false,
                "role": "ACCESS",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
            }
        ],
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": "",
        "imageImported": true,
        "applicableDevicesForImage": [
          {
            "mdfId": "286323158",
            "productName": "Cisco Catalyst 9800-L-C Wireless Controller",
            "productId": [
              "C9800-L-C-K9-RF",
              "EDU-C9800-L-C-K9",
              "C9800-L-F-K9",
              "C9800-L-C-K9"
            ],
            "sites": [],
            "show": false,
            "userDefined": false
          },
          {
            "mdfId": "286321399",
            "productName": "Cisco Catalyst 9800-L-F Wireless Controller",
            "productId": [
              "EDU-C9800-L-F-K9",
              "C9800-L-F-K9"
            ],
            "sites": [],
            "show": false,
            "userDefined": false
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "ccoreverseSync": false,
        "ccorecommended": false,
        "ccolatest": true
      },{
        "name": "C9800-L-universalk9_wlc.17.08.01.SPA.bin",
        "imageUuid": "53212f21-3808-4930-a607-cc1646bcab10",
        "version": "17.8.1",
        "softwareType": null,
        "imageType": "SYSTEM_SW",
        "isTaggedGolden": "false",
        "md5Checksum": "119f4d37e35d0a8d9140c201ac359cfd",
        "shaCheckSum": "e3290c29e42cbc5c6ab6ce3f97e3d18ec1d5fc2b0900f217cbeca4d32638305ddb1a6c0ca261fedcd2571adb76e4a8ac4deb740cc87226c3966536ba3bf0bf8b",
        "createdTime": "2022-08-05 00:04:32.057",
        "applicationType": "UNKNOWN",
        "feature": null,
        "fileServiceId": null,
        "imageSeries": null,
        "imageSource": "CCO",
        "imageSourceType": "Imported from CCO",
        "imageFamily": "C9800-L",
        "vendor": "CISCO",
        "filesize": 1146960149,
        "deviceCount": 0,
        "deviceUuidList": [],
        "smuCount": 0,
        "imageAttributes": [
            {
                "propertyName": "release_doc_url",
                "propertyValue": "https://www.cisco.com/content/en/us/td/docs/wireless/controller/9800/17-3/release-notes/rn-17-3-9800.html"
            },
            {
                "propertyName": "field_notices",
                "propertyValue": "Field Notices"
            },
            {
                "propertyName": "field_notice_url",
                "propertyValue": "https://www.cisco.com/c/en/us/support/wireless/catalyst-9800-series-wireless-controllers/products-field-notices-list.html"
            },
            {
                "propertyName": "metadata_trans_id",
                "propertyValue": "336972849"
            },
            {
                "propertyName": "mdf_id",
                "propertyValue": "286321399"
            },
            {
                "propertyName": "deviceMNEId",
                "propertyValue": "18839868"
            },
            {
                "propertyName": "release_doc_name",
                "propertyValue": "Release Notes for C9800L"
            },
            {
                "propertyName": "udi",
                "propertyValue": "PID: C9800-L-F-K9 VID: 01, SN: FCL241200E4"
            },
            {
                "propertyName": "image_guid",
                "propertyValue": "A1F4E04A940B123A62E54EC0634086FB6F317154"
            },
            {
                "propertyName": "release_fcs_date",
                "propertyValue": "05/Aug/2021"
            },
            {
                "propertyName": "min_flash",
                "propertyValue": "32768"
            },
            {
                "propertyName": "image_description",
                "propertyValue": "Cisco Catalyst 9800-L Wireless Controller"
            },
            {
                "propertyName": "min_dram",
                "propertyValue": "16384"
            },
            {
                "propertyName": "encryption_software_indicator",
                "propertyValue": "Y"
            },
            {
                "propertyName": "image_size",
                "propertyValue": "1131196133"
            }
        ],
        "isSMUApplicable": false,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": true,
        "reasonForNotSupportingImport": null,
        "tagList": [
            {
                "taggedGolden": false,
                "role": "ALL",
                "inheritedSiteName": "",
                "inheritedSiteUuid": ""
            },
            {
                "taggedGolden": false,
                "role": "CORE",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
            },
            {
                "taggedGolden": false,
                "role": "DISTRIBUTION",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
            },
            {
                "taggedGolden": false,
                "role": "BORDER ROUTER",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
            },
            {
                "taggedGolden": false,
                "role": "UNKNOWN",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
            },
            {
                "taggedGolden": false,
                "role": "ACCESS",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
            }
        ],
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": "",
        "imageImported": true,
        "applicableDevicesForImage": [
          {
            "mdfId": "286323158",
            "productName": "Cisco Catalyst 9800-L-C Wireless Controller",
            "productId": [
              "C9800-L-C-K9-RF",
              "EDU-C9800-L-C-K9",
              "C9800-L-F-K9",
              "C9800-L-C-K9"
            ],
            "sites": [],
            "show": false,
            "userDefined": false
          },
          {
            "mdfId": "286321399",
            "productName": "Cisco Catalyst 9800-L-F Wireless Controller",
            "productId": [
              "EDU-C9800-L-F-K9",
              "C9800-L-F-K9"
            ],
            "sites": [],
            "show": false,
            "userDefined": false
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "ccoreverseSync": false,
        "ccorecommended": false,
        "ccolatest": true
      },{
        "name": "C9800-L-universalk9_wlc.17.06.01.SPA.bin",
        "imageUuid": "53212f21-2797-4930-a607-cc1646bcab10",
        "version": "17.6.1",//Amsterdam-17.3.3
        "softwareType": null,
        "imageType": "SYSTEM_SW",
        "isTaggedGolden": "false",
        "md5Checksum": "119f4d37e35d0a8d9140c201ac359cfd",
        "shaCheckSum": "e3290c29e42cbc5c6ab6ce3f97e3d18ec1d5fc2b0900f217cbeca4d32638305ddb1a6c0ca261fedcd2571adb76e4a8ac4deb740cc87226c3966536ba3bf0bf8b",
        "createdTime": "2021-04-02 00:04:32.057",
        "applicationType": "UNKNOWN",
        "feature": null,
        "fileServiceId": null,
        "imageSeries": null,
        "imageSource": "CCO",
        "imageSourceType": "Imported from CCO",
        "imageFamily": "C9800-L",
        "vendor": "CISCO",
        "filesize": 1146960149,
        "deviceCount": 0,
        "deviceUuidList": [],
        "smuCount": 0,
        "imageAttributes": [
            {
                "propertyName": "release_doc_url",
                "propertyValue": "https://www.cisco.com/content/en/us/td/docs/wireless/controller/9800/17-3/release-notes/rn-17-3-9800.html"
            },
            {
                "propertyName": "field_notices",
                "propertyValue": "Field Notices"
            },
            {
                "propertyName": "field_notice_url",
                "propertyValue": "https://www.cisco.com/c/en/us/support/wireless/catalyst-9800-series-wireless-controllers/products-field-notices-list.html"
            },
            {
                "propertyName": "metadata_trans_id",
                "propertyValue": "336972849"
            },
            {
                "propertyName": "mdf_id",
                "propertyValue": "286321399"
            },
            {
                "propertyName": "deviceMNEId",
                "propertyValue": "18839868"
            },
            {
                "propertyName": "release_doc_name",
                "propertyValue": "Release Notes for C9800L"
            },
            {
                "propertyName": "udi",
                "propertyValue": "PID: C9800-L-F-K9 VID: 01, SN: FCL241200E4"
            },
            {
                "propertyName": "image_guid",
                "propertyValue": "A1F4E04A940B123A62E54EC0634086FB6F317154"
            },
            {
                "propertyName": "release_fcs_date",
                "propertyValue": "05/Mar/2021"
            },
            {
                "propertyName": "min_flash",
                "propertyValue": "32768"
            },
            {
                "propertyName": "image_description",
                "propertyValue": "Cisco Catalyst 9800-L Wireless Controller"
            },
            {
                "propertyName": "min_dram",
                "propertyValue": "16384"
            },
            {
                "propertyName": "encryption_software_indicator",
                "propertyValue": "Y"
            },
            {
                "propertyName": "image_size",
                "propertyValue": "1131196133"
            }
        ],
        "isSMUApplicable": false,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": true,
        "reasonForNotSupportingImport": null,
        "tagList": [
            {
                "taggedGolden": false,
                "role": "ALL",
                "inheritedSiteName": "",
                "inheritedSiteUuid": ""
            },
            {
                "taggedGolden": false,
                "role": "CORE",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
            },
            {
                "taggedGolden": false,
                "role": "DISTRIBUTION",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
            },
            {
                "taggedGolden": false,
                "role": "BORDER ROUTER",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
            },
            {
                "taggedGolden": false,
                "role": "UNKNOWN",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
            },
            {
                "taggedGolden": false,
                "role": "ACCESS",
                "inheritedSiteName": null,
                "inheritedSiteUuid": null
            }
        ],
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": "",
        "imageImported": true,
        "applicableDevicesForImage": [
          {
            "mdfId": "286323158",
            "productName": "Cisco Catalyst 9800-L-C Wireless Controller",
            "productId": [
              "C9800-L-C-K9-RF",
              "EDU-C9800-L-C-K9",
              "C9800-L-F-K9",
              "C9800-L-C-K9"
            ],
            "sites": [],
            "show": false,
            "userDefined": false
          },
          {
            "mdfId": "286321399",
            "productName": "Cisco Catalyst 9800-L-F Wireless Controller",
            "productId": [
              "EDU-C9800-L-F-K9",
              "C9800-L-F-K9"
            ],
            "sites": [],
            "show": false,
            "userDefined": false
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "ccoreverseSync": false,
        "ccorecommended": false,
        "ccolatest": true
      },
      {
      "name": "C9800-L-universalk9_wlc.17.03.03.SPA.bin",
      "imageUuid": "53212f21-2797-4930-a607-cc1646a3ab10",
      "version": "17.3.3",//Amsterdam-17.3.3
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "fe0992e16e2a5b24aecb2b60f626c65f",
      "shaCheckSum": "709f852c978263ad0aae14de12461c9152c302b7a0445ef08ad4abf51593e9fe5196b3dcf7b8bc010527c2aa629e00201e1fb042e42f253eaf34a6eb5ab375a4",
      "createdTime": "2021-04-02 00:04:32.057",
      "applicationType": "UNKNOWN",
      "feature": null,
      "fileServiceId": null,
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "C9800-L",
      "vendor": "CISCO",
      "filesize": 1131196133,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
          {
              "propertyName": "release_doc_url",
              "propertyValue": "https://www.cisco.com/content/en/us/td/docs/wireless/controller/9800/17-3/release-notes/rn-17-3-9800.html"
          },
          {
              "propertyName": "field_notices",
              "propertyValue": "Field Notices"
          },
          {
              "propertyName": "field_notice_url",
              "propertyValue": "https://www.cisco.com/c/en/us/support/wireless/catalyst-9800-series-wireless-controllers/products-field-notices-list.html"
          },
          {
              "propertyName": "metadata_trans_id",
              "propertyValue": "336972849"
          },
          {
              "propertyName": "mdf_id",
              "propertyValue": "286321399"
          },
          {
              "propertyName": "deviceMNEId",
              "propertyValue": "18839868"
          },
          {
              "propertyName": "release_doc_name",
              "propertyValue": "Release Notes for C9800L"
          },
          {
              "propertyName": "udi",
              "propertyValue": "PID: C9800-L-F-K9 VID: 01, SN: FCL241200E4"
          },
          {
              "propertyName": "image_guid",
              "propertyValue": "A1F4E04A940B123A62E54EC0634086FB6F317154"
          },
          {
              "propertyName": "release_fcs_date",
              "propertyValue": "05/Mar/2021"
          },
          {
              "propertyName": "min_flash",
              "propertyValue": "32768"
          },
          {
              "propertyName": "image_description",
              "propertyValue": "Cisco Catalyst 9800-L Wireless Controller"
          },
          {
              "propertyName": "min_dram",
              "propertyValue": "16384"
          },
          {
              "propertyName": "encryption_software_indicator",
              "propertyValue": "Y"
          },
          {
              "propertyName": "image_size",
              "propertyValue": "1131196133"
          }
      ],
      "isSMUApplicable": false,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
          {
              "taggedGolden": false,
              "role": "ALL",
              "inheritedSiteName": "",
              "inheritedSiteUuid": ""
          },
          {
              "taggedGolden": false,
              "role": "CORE",
              "inheritedSiteName": null,
              "inheritedSiteUuid": null
          },
          {
              "taggedGolden": false,
              "role": "DISTRIBUTION",
              "inheritedSiteName": null,
              "inheritedSiteUuid": null
          },
          {
              "taggedGolden": false,
              "role": "BORDER ROUTER",
              "inheritedSiteName": null,
              "inheritedSiteUuid": null
          },
          {
              "taggedGolden": false,
              "role": "UNKNOWN",
              "inheritedSiteName": null,
              "inheritedSiteUuid": null
          },
          {
              "taggedGolden": false,
              "role": "ACCESS",
              "inheritedSiteName": null,
              "inheritedSiteUuid": null
          }
      ],
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "",
      "imageImported": true,
      "applicableDevicesForImage": [
        {
          "mdfId": "286323158",
          "productName": "Cisco Catalyst 9800-L-C Wireless Controller",
          "productId": [
            "C9800-L-C-K9-RF",
            "EDU-C9800-L-C-K9",
            "C9800-L-F-K9",
            "C9800-L-C-K9"
          ],
          "sites": [],
          "show": false,
          "userDefined": false
        },
        {
          "mdfId": "286321399",
          "productName": "Cisco Catalyst 9800-L-F Wireless Controller",
          "productId": [
            "EDU-C9800-L-F-K9",
            "C9800-L-F-K9"
          ],
          "sites": [],
          "show": false,
          "userDefined": false
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "ccoreverseSync": false,
      "ccorecommended": false,
      "ccolatest": true
    }
  ]
});
