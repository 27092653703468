define({
    "MissingDeviceDetailsforUDNFlow": {
        "instanceCreatedOn": 1643924585591,
        "instanceUpdatedOn": 1643924585591,
        "isSeeded": false,
        "resourceVersion": 15,
        "targetIdList": [],
        "customProvisions": [
            {
                "id": "52291d39-b17c-4aba-91a9-b9b269add8d3",
                "instanceId": 67327261,
                "instanceCreatedOn": 1643997623820,
                "instanceUpdatedOn": 1643997623820,
                "instanceVersion": 1,
                "payload": "W3sidGFyZ2V0SW5mbyI6W3siaWQiOiIyYTAwZWQ1Yi1mNzNkLTQ2MjEtODMxOS00ZTgyZTAxYWZkZmYiLCJ0eXBlIjoiTUFOQUdFRF9ERVZJQ0VfVVVJRCIsInBhcmFtcyI6eyJmZWF0dXJlc1N0cmluZyI6IiIsImJ1bGtDb25maWdTcGVjUGF5bG9hZCI6WyJqYXZhLnV0aWwuTGlua2VkTGlzdCIsW3siQGNsYXNzIjoiY29tLmNpc2NvLnhtcC5jb25maWcuaW50Zi5zZXJ2aWNlLkJ1bGtDb25maWdTcGVjaWZpY2F0aW9uIiwiaW5zdGFuY2VVdWlkIjoiMjM4MzVhYjgtMTBlYy00MjZiLWIwOTktMWJmMThjMWU1OTVmIiwidHJhbnNhY3Rpb25JZCI6LTEsImRldmljZUlkIjoiMmEwMGVkNWItZjczZC00NjIxLTgzMTktNGU4MmUwMWFmZGZmIiwiZmVhdHVyZUNvbmZpZ1NwZWNpZmljYXRpb25zIjpbIltMY29tLmNpc2NvLnhtcC5jb25maWcuaW50Zi5zZXJ2aWNlLkZlYXR1cmVDb25maWdTcGVjaWZpY2F0aW9uOyIsW11dfV1dfX1dfV0=",
                "type": "NAMED_CAPABILITY_PROVISION",
                "displayName": "0"
            }
        ],
        "anchoredManagedSites": [],
        "primaryManagedSites": [
            "29f01208-db31-4db8-ba8d-e80c04a34edd",
            "31ed38c8-8699-4271-a577-1a9b7139d421"
        ],
        "rapUpgradeCfsProperty": {
            "id": "ee2848d8-66f1-4c96-929f-e2d0ad982fad",
            "enableRapUpgrade": false,
            "rapUpgradePercentage": 25
        },
        "rlanList": [],
        "saveWanConnectivityDetailsOnly": false,
        "secondaryManagedSites": [],
        "tertiaryManagedSites": [],
        "deviceInterfaceGroupInfo": [],
        "migrationStatus": [],
        "networkWideSettings": {
            "instanceCreatedOn": 1643924585591,
            "instanceUpdatedOn": 1643924585591,
            "instanceVersion": 15,
            "acl": [],
            "apjoinprofile": [
                {
                    "id": "13af5ba1-b62c-4e38-a766-0bd1596b3007",
                    "apJoinProfileName": "default-ap-profile",
                    "awipsEnable": false,
                    "forensicEnable": false,
                    "siteId": "29f01208-db31-4db8-ba8d-e80c04a34edd"
                }
            ],
            "cmx": [],
            "ipdtEnabled": false,
            "isBridgeModeVm": false,
            "mesh": [],
            "nativeVlan": []
        },
        "rlan": [],
        "wirelessAAAPolicy": [],
        "wirelessSetting": {
            "idRef": "6b75f3ba-006c-47e8-ad06-a4165317b0ba"
        }
    }
})