define({
    "DetailTemplate":
    {
        "instanceUuid": null,
        "macAddress": "2c:d0:2d:8b:d3:60",
        "model": "AIR-AP2802I-B-K9",
        "managementIpAddress": "10.16.32.9",
        "hostname": "AP28-FEW-DemoLab-03",
        "family": "Unified AP",
        "series": "Cisco 2800I Series Unified Access Points",
        "registerTime": "Wed May 02 06:54:33 UTC 2018",
        "lastUpdatedTime": "Thu May 31 09:22:11 UTC 2018",
        "testConfigVersion": "4",
        "softwareVersion": "8.5.120.0",
        "sensorType": null,
        "rawServiceDomain": null,
        "xcoordinate": null,
        "ycoordinate": null,
        "zcoordinate": null
    }
});