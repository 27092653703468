define({
"Onboarding_FailurePercentByTest":
{"categoryPercent":{"testCategory":"ONBOARDING","failPercent":1.09,"testTypeFailurePercents":[{"testType":"ASSOC","failPercent":0.15,"successPercent":99.85},{"testType":"AUTH","failPercent":0.13,"successPercent":99.87},{"testType":"DHCP","failPercent":1.72,"successPercent":98.28},{"testType":"WEBAUTH","failPercent":1.0,"successPercent":99.00}]},"errorCodePercent":{"errorCodePercents":[{"errorCode":["assoc_1802"],"title":"Failed to discover eligible target AP","errorCodeFailPercent":0.11},{"errorCode":["assoc_1064"],"title":"Deauthenticated because Sensor is leaving","errorCodeFailPercent":0.78},{"errorCode":["dhcp_1806"],"title":"No DHCP OFFER Received","errorCodeFailPercent":0.08},{"errorCode":["auth_1064","assoc_1012"],"title":"Undefined Error","errorCodeFailPercent":0.07},{"errorCode":["dhcp_1808"],"title":"No DHCP ACK Received","errorCodeFailPercent":0.06}]}},
"RF_FailurePercentByTest":
{"categoryPercent":{"testCategory":"RF_ASSESSMENT","failPercent":3.66,"testTypeFailurePercents":[{"testType":"DATA_RATE","failPercent":7.33,"successPercent":92.67},{"testType":"SNR","failPercent":0.0,"successPercent":100.0}]},"errorCodePercent":{"errorCodePercents":[{"errorCode":["dataRate_9006","dataRate_8004","dataRate_9004","dataRate_6003","dataRate_6004","dataRate_6005","dataRate_10006","dataRate_0","dataRate_11006","dataRate_3002","dataRate_3001"],"title":"Undefined Error","errorCodeFailPercent":3.66}]}},
"NetorkServices__FailurePercentByTest":
{"categoryPercent":{"testCategory":"NETWORK_SERVICES","failPercent":27,"testTypeFailurePercents":[{"testType":"DNS","failPercent":30,"successPercent":70},{"testType":"RADIUS","failPercent":0.11,"successPercent":99.89}]},"errorCodePercent":{"errorCodePercents":[{"errorCode":["dns_5003"],"title":"Undefined Error","errorCodeFailPercent":12},{"errorCode":["dns_5002"],"title":"DNS: Name resolution failure","errorCodeFailPercent":14.5},{"errorCode":["radius_13007"],"title":"RADIUS: Server Unreachable","errorCodeFailPercent":0.05}]}},
"Performance_FailurePercentByTest":
{"categoryPercent":{"testCategory":"PERFORMANCE","failPercent":1.45,"testTypeFailurePercents":[{"testType":"SPEED","failPercent":4.27,"successPercent":95.73},{"testType":"IPSLASENDER","failPercent":0.04,"successPercent":99.96},{"testType":"IPERF","failPercent":null,"successPercent":null}]},"errorCodePercent":{"errorCodePercents":[{"errorCode":["ipslasender_11006"],"title":"No response from IP SLA responder AP","errorCodeFailPercent":0.04}]}},
"APPConnectivity_FailurePercentByTest":
{"categoryPercent":{"testCategory":"APP_CONNECTIVITY","failPercent":0.62,"testTypeFailurePercents":[{"testType":"HOST_REACHABILITY","failPercent":0.02,"successPercent":99.98},{"testType":"WEBSERVER","failPercent":0.02,"successPercent":99.98},{"testType":"FILETRANSFER","failPercent":3.01,"successPercent":96.99}]},"errorCodePercent":{"errorCodePercents":[{"errorCode":["filetransfer_3002"],"title":"FTP: Connection failure","errorCodeFailPercent":0.59},{"errorCode":["filetransfer_3001"],"title":"FTP: Server not reachable","errorCodeFailPercent":0.02},{"errorCode":["webserver_8004"],"title":"DNS Resolve Fail","errorCodeFailPercent":0.01},{"errorCode":["ping_14002"],"title":"Ping: Target Address Unreachable","errorCodeFailPercent":0.01}]}},
"Email_FailurePercentByTest":
{"categoryPercent":{"testCategory":"EMAIL","failPercent":50,"testTypeFailurePercents":[{"testType":"MAILSERVER","failPercent":0.02,"successPercent":99.98},{"testType":"OUTLOOKSERVER","failPercent":100,"successPercent":0}]},"errorCodePercent":{"errorCodePercents":[{"errorCode":["outlookserver_9006"],"title":"OWA: Connection Fail","errorCodeFailPercent":28},{"errorCode":["mailserver_6004"],"title":"Connection Failure","errorCodeFailPercent":0.85},{"errorCode":["mailserver_6003"],"title":"Server Unreachable","errorCodeFailPercent":0.13},{"errorCode":["outlookserver_9004"],"title":"OWA: DNS Resolve Fail","errorCodeFailPercent":20},{"errorCode":["mailserver_6005"],"title":"Exception Error","errorCodeFailPercent":0.02}]}}
})