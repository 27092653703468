define({
"Template" :{
  id:"",
  "instanceId":540556,
  "authEntityId":540556,
  "displayName":"540556",
  "authEntityClass":-2043446297,
  "deployPending":"NONE",
  "instanceVersion":3,
  "cfsVersion":67,
  "createTime":0,
  "deviceConfig":"MBC CONFIG PUSH SUCCESS FOR THE DEVICE WITH DEVICEID 97098",
  "deviceId":"",
  "family":"",
  "groupId":"",
  "groupNameHierarchy":"",
  "hostname":"",
  "isLatest":true,
  "lastSuccessfulVersion":0,
  "lastUpdateTime":0,
  managementIpAddress:"",
  namespace:"",
  provisioningData:{},
  role:"",
  "rollbackStatus":"NOT_SUPPORTED",
  "serviceType":"VirtualNetwork",
  "status":"SUCCESS",
  "templateProvisioningStatus":"SUCCESS",
  "templateProvisioningStatusMessage":"Provisioning success for template..",
  "type":""
 }
});
