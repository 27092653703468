define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/GuestPolicyTemplate',
 ], function(SimLokiDatabaseActions,SimTaskDataGenerator,UtilityFunctions,GuestPolicyTemplate){

 return {
      init: function(){
      },
      createPortal:function (urlAction) {

            var portalObj = urlAction.restPayload;
            var portalID =UtilityFunctions.generate_uuid();
            var resultObj = SimTaskDataGenerator.createTask('DNA Wireless Service',{ id: portalID });
            //  portalObj.id =portalID;
            var portalJson = JSON.parse(JSON.stringify(GuestPolicyTemplate.Guest_Portal_Template));

            portalJson.dnaPortalString = JSON.stringify({"DnaPortal":portalObj});
            portalJson.wlans.push( portalObj.name);
            portalJson.instanceUuid =portalID;
            portalJson.id =portalID;
            portalJson.name = portalObj.name
            SimLokiDatabaseActions.insert('guest-portal', portalJson);
            var tJson = JSON.parse(JSON.stringify(GuestPolicyTemplate.Guest_Policy_Template));
            var redirectRule ="_RedirectPolicy";
            var permitRule ="_GuestAccessPolicy";
            var name = portalObj.name;
                tJson.portal =portalObj.name;
                tJson.portalInstanceUuid= portalID;
                tJson.redirectRuleId = name.concat(redirectRule);
                tJson.permitRuleId = name.concat(permitRule);
                tJson.authzProfileId = UtilityFunctions.generate_uuid();
            SimLokiDatabaseActions.insert('guest-policy', tJson);
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
            return resultObj;
      },

      createPolicy:function(urlAction){

            var policyObj = urlAction.restPayload;
            var taskID =UtilityFunctions.generate_uuid();
            var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('guest-policy', {'portal': policyObj.portalName});
            var resultObj = SimTaskDataGenerator.createTask('DNA Wireless Service',{ id: taskID });

            // SimLokiDatabaseActions.update('guest-policy', policyObj);
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
            return resultObj;
      },

      updatePortal:function(urlAction) {
          var portalObj = urlAction.restPayload;
          var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('guest-portal', {'id': urlAction.action.id});
          recordObj[0].dnaPortalString = JSON.stringify({"DnaPortal":portalObj});
          var portalNames =[];
          portalNames.push(portalObj.name);
          recordObj[0].wlans =portalNames;
          SimLokiDatabaseActions.update('guest-portal', recordObj);

          var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('guest-policy', {'portalInstanceUuid':urlAction.action.id});

          var redirectRule ="_RedirectPolicy";
          var permitRule ="_GuestAccessPolicy";
          var name = portalObj.name;
                recordObj[0].portal =portalObj.name;
                recordObj[0].redirectRuleId = name.concat(redirectRule);
                recordObj[0].permitRuleId = name.concat(permitRule);
           SimLokiDatabaseActions.update('guest-policy', recordObj);
      },

      deletePortal :function(urlAction) {
            if(urlAction.service.indexOf("policy")>=0) {
               var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('guest-policy', {'portalInstanceUuid':urlAction.action.id});
               var resultObj = SimTaskDataGenerator.createTask('DNA Wireless Service',{ id: urlAction.action.id });
               SimLokiDatabaseActions.delete('guest-policy', recordObj);
               SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
               return resultObj;
            }
            if(urlAction.service.indexOf("portal")>=0) {
               var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('guest-portal', {'id':urlAction.action.id});
               var resultObj = SimTaskDataGenerator.createTask('DNA Wireless Service',{ id: urlAction.action.id });
               SimLokiDatabaseActions.delete('guest-portal', recordObj);
               SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
               return resultObj;
            }
      }
     };
});
