define({
  "License_ViewAll_Template": {
    "deployedDNAAdvantage": 0,
    "deployedNetworkAdvantage": 0,
    "dnaEssUsageCount": 0,
    "deployedNetworkEssential": 0,
    "deviceType": "Routers",
    "nwEssUsageCount": 0,
    "nwEssUsage": null,
    "deployedDNAEssential": 0,
    "entitledDNA": 0,
    "type": null,
    "dnaAdvUsageCount": "0",
    "nwAdvUsageCount": 0,
    "dnaAdvUsage": null,
    "entitledDNAAdvantage": 0,
    "isCSSMEnabled": true,
    "entitledDNAEssential": 0,
    "id": "Cisco ASR 1000 Series Aggregation Services Routers",
    "deployedDNA": 0,
    "entitledNetworkAdvantage": 0,
    "deployedNetworkOrLegacy": 3,
    "entitledNetworkOrLegacy": 0,
    "featureLicense": [
      {
        "name": "throughput_36g",
        "value": "1"
      },
      {
        "name": "adventerprise",
        "value": "1"
      },
      {
        "name": "avc",
        "value": "1"
      }
    ],
    "dnaEssUsage": null,
    "nwAdvUsage": null,
    "isNetwork": true,
    "totalDevices": 1,
    "model": "Cisco ASR 1000 Series Aggregation Services Routers",
    "entitledNetworkEssential": 0,
    "description": null
  }

});