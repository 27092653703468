define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/graphql/SimGraphQL',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimGroupData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimNetworkDeviceData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/Security-Advisories/SimAdvisoriesTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/Security-Advisories/Journey_Map_Template'
], function (SimGraphQL, SimGroupData, SimNetworkDeviceData, SimAdvisoriesTemplate, Journey_Map_Template) {

    return {
        init: function() {},

        doProcessRestRequest: function(urlAction){
            var data;
            switch(urlAction.method) {
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                case 'POST':
                    data = processPostRequest(urlAction);
                    break;

                case 'DELETE':
                    data = processDeleteRequest(urlAction);
                    break;

                default:
                    break;
            }
            return data;
        }
    };

    function processGetRequest(urlAction) {
        if(urlAction.service.indexOf('store')>-1 && urlAction.filter['name']=='SiteStore') {
            var result = {'items':[]};
            result.items = SimGroupData.getSiteDetails(urlAction);
            result.totalCount = result.items.length;
            result.metadata = {"stores":{"SiteStore":Date()},"query":{"query":{"name":"SiteStore"}}}
            return result;
        }
        // if(urlAction.service.indexOf('store')>-1 && urlAction.filter['name']=='ProvisionStatusStore') {
        //     var result = {'items':[]};
        //     result.items = SimGroupData.getNetworkDeviceProvisionStatus(urlAction);
        //     result.totalCount = result.items.length;
        //     return result;
        // }
        if(urlAction.service.indexOf('store')>-1 && (urlAction.filter['name']=='DeviceStore' || urlAction.filter['ids']=='DeviceStore')) {
            var json = {"DeviceStore":"Refresh Triggered","SoftwareImageStore":"Refresh Triggered","ProvisionStatusStore":"Refresh Triggered","DeviceConfigStore":"Refresh Triggered"};
            return json;
        }

        if(urlAction.service.indexOf('graphql')>-1 && urlAction.service.indexOf('isActive'>-1) ){
            return true;
        }
        if(urlAction.service.indexOf('store')>-1 && urlAction.filter['name']=='ProvisionStatusStore'){
            return SimNetworkDeviceData.getProvisionStatusStore(urlAction);
        }
        if(urlAction.service.indexOf('aggregation')>-1 && urlAction.filter['name']=='allDeviceStore'){
            return SimNetworkDeviceData.getallDeviceStoreData(urlAction);
        }
        if(urlAction.service.indexOf('store')>-1 && urlAction.service.indexOf('refresh')>-1 && urlAction.filter['ids']=='SiteStore') {
            return {};//unable to collect response
        }
        if(urlAction.service.indexOf('ncp-node')>-1 && urlAction.service.indexOf('store')>-1 && urlAction.service.indexOf('aggregation')>-1){
            let tJson = JSON.parse(JSON.stringify(advisoriesTemplate.AdvisorieStoreAggregationTemplate));
            return tJson.response ;
        }
        if(urlAction.service.indexOf('ncp-node/store/aggregation')>-1 && urlAction.filter['name']=='allDeviceStore'>-1 && urlAction.filter['fields']=='softwareVersion'>-1){
            let tJson = JSON.parse(JSON.stringify(advisoriesTemplate.getSoftwareVersionTemplate));
            return tJson.response ;
        }
        if(urlAction.service.indexOf('ncp-node/store/aggregation')>-1 && urlAction.filter['name']=='allDeviceStore'>-1 && urlAction.filter['fields']=='family'>-1){
            let tJson = JSON.parse(JSON.stringify(advisoriesTemplate.getFamilyTemplate));
            return tJson.response ;
        }
        if(urlAction.service.indexOf('ncp-node/store/aggregation')>-1 && urlAction.filter['name']=='allDeviceStore'>-1 && urlAction.filter['fields']=='site.groupNameHierarchy'>-1){
            let tJson = JSON.parse(JSON.stringify(advisoriesTemplate.getGroupNameHierarchyTemplate));
            return tJson.response ;
        }
        /*Explore Dashboard Changed*/ 
        if(urlAction.service.indexOf('journey_map') && urlAction.service[2] == 'meta'){
            let tJson = JSON.parse(JSON.stringify(Journey_Map_Template.JourneyMapTemplate));
            return tJson;   
        }
        else {
            let tData = JSON.parse(JSON.stringify(Journey_Map_Template.JourneyMapDataTemplate));
            return tData;
        }

    }

    function processPostRequest(urlAction) {
        // if(urlAction.service.indexOf('ncp-node')>-1 && urlAction.service.indexOf('graphql')>-1){
        //     let tJson = JSON.parse(JSON.stringify(advisoriesTemplate.getSecurityAdvisoriesTemplate));
        //     return tJson.response ;
        // }  
        if(urlAction.service.indexOf('graphql')>-1) {
            return SimGraphQL.getData(urlAction.restPayload.query, urlAction.restPayload.variables);
        }
    }

    function processDeleteRequest(urlAction) {
    }

});