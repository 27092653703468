define({
"Template":
{
  "version": "1.0",
  "response": {
    "id": "AWXMpscZCj-ipzc8r5Pi",
    "name": "fabric_reachability_dns_overlay_trigger",
    "entityType": "network_device",
    "entity": "192.168.130.2",
    "enabled": true,
    "flattened": true,
    "groupBy": "Unknown",
    "category": "Connected",
    "severity": "HIGH",
    "priority": "P2",
    "summary": "Fabric Border DeviceIPPlaceHolder Lost Connectivity to the DNS Server 192.168.101.105 in the Virtual Network Campus",
    "scope": "GLOBAL",
    "groupId": "fabric_reachability_grouping",
    "groupName": "Fabric Devices Connectivity - DNS Overlay",
    "rootCause": "7.1.1.9_192.168.101.105_DnsOverlay_Campus",
    "timestamp": 1536736806546,
    "description": "The Fabric Border DeviceNamePlaceHolder cannot reach the DNS server 192.168.101.105 in the Virtual Network VRF 'Campus'.",
    "suggestions": [
      {
        "message": "Verify DNS server is up",
        "steps": [

        ]
      },
      {
        "message": "Verify if configuration is compliant on network device",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Verify DNS configuration",
            "command": "show run | sec domain",
            "stepType": "command-Runner"
          },
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Verify Fabric configuration for the Virtual Network VRF Campus",
            "command": "show run | sec lisp",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Check the route to DNS server",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check ip route to destination 192.168.101.105 in vrf Campus",
            "command": "show ip route vrf Campus 192.168.101.105",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Verify IGP adjacencies from this node to upstream switches",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check ISIS adjacencies",
            "command": "show isis neighbors",
            "stepType": "command-Runner"
          },
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check OSPF adjacencies",
            "command": "show ospf neighbor",
            "stepType": "command-Runner"
          },
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check EIGRP adjacencies",
            "command": "sh eigrp address-family ipv4 neighbors",
            "stepType": "command-Runner"
          },
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check BGP adjacencies",
            "command": "sh ip bgp neighbors",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Contact Cisco TAC World Wide – https://www.cisco.com/c/en/us/support/web/tsd-cisco-worldwide-contacts.html",
        "steps": [

        ]
      }
    ],
    "additionalParams": [
      {
        "key": "destIp",
        "value": "192.168.101.105"
      },
      {
        "key": "managementIpAddr",
        "value": "192.168.130.2"
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "__entity_type__",
        "value": "NetworkDevice"
      },
      {
        "key": "destMgmtIp",
        "value": "NA"
      },
      {
        "key": "entityId",
        "value": "192.168.130.2"
      },
      {
        "key": "vrf",
        "value": "Campus"
      },
      {
        "key": "type",
        "value": "issue"
      },
      {
        "key": "uuid",
        "value": "8d65b376-c222-4d4f-89c2-5acd33b4a9e3"
      },
      {
        "key": "sourceRole",
        "value": "Co-located Fabric Border and Control Plane"
      },
      {
        "key": "sessionIdentifier",
        "value": "7.1.1.9_192.168.101.105_DnsOverlay_Campus"
      },
      {
        "key": "sourceIp",
        "value": "7.1.1.9"
      },
      {
        "key": "scope",
        "value": "global"
      },
      {
        "key": "rootCause",
        "value": "7.1.1.9_192.168.101.105_DnsOverlay_Campus"
      },
      {
        "key": "sessionType",
        "value": "DnsOverlay"
      },
      {
        "key": "category",
        "value": "connected"
      },
      {
        "key": "timestring",
        "value": "2018-09-12T07:20:06.546+0000"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}
})