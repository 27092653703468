//family : CAT3K_CAA Cisco Catalyst38xx stack-able ethernet switch
define({"runningImageList":[  
    {  
       "name":"cat3k_caa-universalk9.16.06.02.SPA.bin",
       "imageUuid":"f074e99b-ac14-4710-a0be-13150d7882bc",
       "version":"16.6.2",
       "softwareType":null,
       "imageType":"SYSTEM_SW",
       "isTaggedGolden":"true",
       "md5Checksum":"ebec919da12d0ac5a49886531b8b82cc",
       "shaCheckSum":"d5d9dd2686adb8bf59aaba444191b3947a9c41b15b99c181ec80a7e52d817f27c48fa16394d2253516bfdc9f1716cbc11ea09b4bf7a9961111a9fc233879d535",
       "createdTime":"2018-06-04 06:23:37.0",
       "applicationType":null,
       "feature":"",
       "fileServiceId":"2c0e98cd-61fd-44d8-b2fd-f74391f26b10",
       "imageSeries":[  
          "Switches and Hubs/Cisco Catalyst 3850 Series Ethernet Stackable Switch",
          "Switches and Hubs/Cisco Catalyst 3650 Series Switches"
       ],
       "imageSource":"ftp://cisco:cisco123@10.16.0.70/ios/switch/cat3k_caa-universalk9.16.06.02.SPA.bin",
       "imageSourceType":"Imported from Remote server URL",
       "imageFamily":"CAT3K_CAA",
       "vendor":"CISCO",
       "filesize":409853870,
       "deviceCount":0,
       "deviceUuidList":[ ],
       "smuCount":0,
       "imageAttributes":[  
          {  
             "propertyName":"DEFAULT_BOOTROM",
             "propertyValue":"0"
          },
          {  
             "propertyName":"DEFAULT_RAM",
             "propertyValue":"0"
          },
          {  
             "propertyName":"MEDIA",
             "propertyValue":""
          },
          {  
             "propertyName":"FEATURE",
             "propertyValue":""
          },
          {  
             "propertyName":"DEFAULT_MINFLASHSIZE",
             "propertyValue":"0"
          }
       ],
       "isSMUApplicable":false,
       "goldenTagInheritedFromSiteUuid":null,
       "goldenTagInheritedFromSiteName":null,
       "importSupported":true,
       "reasonForNotSupportingImport":null,
       "tagList":[  
          {  
             "taggedGolden":false,
             "role":"ALL",
             "inheritedSiteName":"",
             "inheritedSiteUuid":""
          },
          {  
             "taggedGolden":true,
             "role":"CORE",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":true,
             "role":"DISTRIBUTION",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":true,
             "role":"BORDER ROUTER",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":true,
             "role":"UNKNOWN",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"ACCESS",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          }
       ],
       "imageCategory":"PHYSICAL",
       "imageIntegrityStatus":"VERIFIED",
       "imageImported":true,
       "applicableDevicesForImage":[  
          {  
             "mdfId":"284850737",
             "productName":"Cisco Catalyst 3650-48PQ-S Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "WS-C3650-48FWD-S",
                "PWR-C2-640WAC=",
                "C1FACAT36502K9",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WDC-RF",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "C3650-48PQ-S++",
                "C3650-RTU=",
                "C3560-NEBS-KIT=",
                "4PT-KIT-T1-RF",
                "PWR-C2-250WAC-RF",
                "C1AUCAT36502K9",
                "STACK-T2-3M",
                "C1-WS3650-48PQ/K9",
                "WS-C3650-48PQM-S",
                "WS-C3650-48PQ-S-WS",
                "WS-C3650-48PQ",
                "C1-C3650-48-DNAA-O",
                "PWR-C2-640WDC",
                "C1-C3650-48-DNAE-O",
                "C1AACAT36502K9",
                "WS-C3650-48PQM-L",
                "WS-C3650-48PQM-E",
                "C1AUCAT36502K9=",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "C1FUCAT36502K9=",
                "C1FUCAT36502K9",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "C1AACAT36502K9=",
                "PWR-C2-640WAC-WS",
                "NAL-C3650-48PQ-S",
                "WS-C3650-48PWQ-S",
                "RACK-KIT-T1-RF",
                "WS-C3650-48FWDS-RF",
                "PWR-C2-1025WAC",
                "C1FACAT36502K9=",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "WS-C3650-48PQ-HC",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "WS-C3650-48PQ-S-RF",
                "L-C3650-SLIC-U=",
                "WS-C3650-48PQ-S",
                "C1-WS3650-48XFD/K9",
                "RACK-KIT-T1-WS",
                "PWR-C2-250WAC-WS",
                "PWR-C2-640WDC-WS",
                "ACC-KIT-T1=",
                "WSC365012X48URS-WS",
                "4PT-KIT-T1=",
                "RACK-KIT-T1="
             ]
          },
          {  
             "mdfId":"286289939",
             "productName":"Cisco Catalyst 3650-12X48UZ-S Switch",
             "productId":[  
                "PWR-C2-250WAC",
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "WS-C3650-12X48UZ-S",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-48FWD-S",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "C3650-12X48UZ-S++",
                "WSC365012X48UZS-RF",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "WS-C3650-48FWDS-RF",
                "RACK-KIT-T1-RF",
                "PWR-C2-640WAC",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WDC-RF",
                "PWR-C2-1025WAC",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "STACK-T2-3M=",
                "WSC365012X48URS-RF",
                "PWR-C2-BLANK",
                "NAL-C3650-12X48UZS",
                "C3560-NEBS-KIT=",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "PWR-C2-250WAC-RF",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "STACK-T2-3M",
                "PWR-C2-640WDC",
                "WSC365012X48UZS-WS",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "PWR-C2-250WAC-WS",
                "RACK-KIT-T1-WS",
                "PWR-C2-640WDC-WS",
                "RACK-KIT-T1=",
                "WSC365012X48URS-WS"
             ]
          },
          {  
             "mdfId":"284846024",
             "productName":"Cisco Catalyst 3650-24TS-S Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "C1FUCAT36501K9",
                "C1-WS3650-24TS/K9",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WDC-RF",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "4PT-KIT-T1-RF",
                "C3650-RTU=",
                "C3560-NEBS-KIT=",
                "C1-C3650-24-DNAA-O",
                "PWR-C2-250WAC-RF",
                "NAL-C3650-24TS-S",
                "STACK-T2-3M",
                "C3650-48TS-S++",
                "C1FUCAT36501K9=",
                "PWR-C2-640WDC",
                "C3650-24TS-S++",
                "WS-C3650-24TS-S-RF",
                "C1-C3650-24-DNAE-O",
                "C1AUCAT36501K9=",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "WS-C3650-24TS",
                "C1FACAT36501K9",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "WS-C3650-24TS-S",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "WS-C3650-24TS-S-WS",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "C1AUCAT36501K9",
                "C1AACAT36501K9=",
                "C1FACAT36501K9=",
                "C1AACAT36501K9",
                "L-C3650-SLIC-U=",
                "RACK-KIT-T1-WS",
                "PWR-C2-250WAC-WS",
                "PWR-C2-640WDC-WS",
                "4PT-KIT-T1=",
                "WSC365012X48URS-WS",
                "RACK-KIT-T1=",
                "ACC-KIT-T1=",
                "C1-WS3650-24XPD/K9"
             ]
          },
          {  
             "mdfId":"286285429",
             "productName":"Cisco Catalyst 3850-24XU-L Switch",
             "productId":[  
                "FAN-T3-F=",
                "CAB-TA-TW",
                "C1FACAT38501K9=",
                "CAB-TA-CN=",
                "C3850-RTU=",
                "PWR-C1-440WDC",
                "CAB-AC-C15-KOR=",
                "C3850-4PT-KIT=",
                "CAB-TA-AR=",
                "C3850-SPWR-NONE",
                "C1AACAT38504K9",
                "PWR-C1-440WDC-RF",
                "CAB-TA-CN",
                "LL-C3850-24XU-L=",
                "C1FUCAT38501K9=",
                "C3850-NEBS-KIT=",
                "CAB-TA-250V-JP=",
                "C1AUCAT38501K9",
                "CAB-AC-C15-TW",
                "C1-WSC3850-24XUL",
                "CAB-TA-SW",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "FAN-T3-F",
                "C3850-4PT-KIT-RF",
                "L-C3850-RTU=",
                "CAB-AC-C15-KOR",
                "C3850-RACK-KIT-RF",
                "L-C3850-SLIC-U=",
                "C3850-ACC-KIT=",
                "C1FACAT38504K9",
                "C1AACAT38501K9=",
                "FAN-T3-R",
                "CAB-TA-IN=",
                "CAB-TA-250V-JP",
                "CAB-C15-CBN-JP=",
                "C1AACAT38501K9",
                "CAB-AC-C15-TW=",
                "CAB-TA-DN=",
                "CAB-TA-IT=",
                "C3850-FAN-T1-WS",
                "CAB-TA-AP=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "WS-C3850-24XU-L",
                "PWR-C1-440WDC-WS",
                "WS-C3850-24XU",
                "C3850-FAN-T1=",
                "CAB-TA-UK=",
                "CAB-TA-AR",
                "C3850-STACK-NONE",
                "CAB-TA-NA",
                "WS-C3850-24XU-L++",
                "C1AACAT38504K9=",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "FAN-T3-F-WS",
                "CAB-TA-SW=",
                "PWR-C1-440WDC=",
                "CAB-TA-AP",
                "CAB-TA-EU",
                "CAB-TA-JP-RA",
                "CAB-C15-CBN-JP",
                "C3850-FAN-T1-RF",
                "WS-C3850-24XU-L-RF",
                "C1FACAT38504K9=",
                "CAB-TA-IN",
                "WS-C3850-24XU-LJ",
                "C1AUCAT38501K9=",
                "CAB-TA-EU=",
                "C1FUCAT38501K9",
                "WS-C3850E-24XU-L",
                "CAB-TA-UK",
                "FAN-T3-R=",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "C1FACAT38501K9",
                "CAB-TA-JP-RA="
             ]
          },
          {  
             "mdfId":"286280308",
             "productName":"Cisco Catalyst 3850-32XS-E Switch",
             "productId":[  
                "STACK-T1-50CM-RF",
                "FAN-T3-F=",
                "CAB-TA-TW",
                "C3850-RTU=",
                "CAB-TA-CN=",
                "STACK-T1-1M",
                "PWR-C1-440WDC",
                "CAB-AC-C15-KOR=",
                "C3850-4PT-KIT=",
                "CAB-TA-AR=",
                "C3850-SPWR-NONE",
                "PWR-C1-440WDC-RF",
                "CAB-TA-CN",
                "STACK-T1-1M-WS",
                "C3850-NEBS-KIT=",
                "CAB-TA-250V-JP=",
                "CAB-AC-C15-TW",
                "CAB-TA-IS=",
                "CAB-TA-SW",
                "STACK-T1-3M-RF",
                "C3850-FAN-T1",
                "LL-C3850-32XS-E=",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "FAN-T3-F",
                "C3850-4PT-KIT-RF",
                "PWR-C1-1100WAC=",
                "CAB-AC-C15-KOR",
                "L-C3850-RTU=",
                "C3850-RACK-KIT-RF",
                "C3850-ACC-KIT=",
                "PWR-C1-1100WAC-RF",
                "L-C3850-SLIC-U=",
                "FAN-T3-R",
                "STACK-T1-50CM",
                "PWR-C1-1100WAC",
                "CAB-TA-IN=",
                "CAB-TA-250V-JP",
                "CAB-C15-CBN-JP=",
                "WS-C3850-32XS-E",
                "PWR-C1-715WAC-RF",
                "CAB-AC-C15-TW=",
                "CAB-TA-DN=",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "C3850-FAN-T1-WS",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "CAB-TA-UK=",
                "CAB-TA-AR",
                "C3850-STACK-NONE",
                "PWR-C1-1100WAC-WS",
                "CAB-TA-NA",
                "STACK-T1-1M=",
                "STACK-T1-3M=",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "CAB-TA-SW=",
                "FAN-T3-F-WS",
                "PWR-C1-440WDC=",
                "CAB-TA-AP",
                "CAB-TA-EU",
                "CAB-TA-JP-RA",
                "C3850-FAN-T1-RF",
                "CAB-C15-CBN-JP",
                "CAB-TA-IN",
                "PWR-C1-715WAC",
                "PWR-C1-715WAC-WS",
                "CAB-TA-EU=",
                "C3850-32XS-E++",
                "CAB-TA-UK",
                "FAN-T3-R=",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "CAB-TA-JP-RA="
             ]
          },
          {  
             "mdfId":"284455437",
             "productName":"Cisco Catalyst 3850-48F-E Switch",
             "productId":[  
                "FAN-T3-F=",
                "C3850-NM-4-1G",
                "C1-WS385048F/K9-RF",
                "C3850-RTU=",
                "CAB-TA-CN=",
                "PWR-C1-440WDC",
                "C1AUCAT38502K9=",
                "C3850-4PT-KIT=",
                "CAB-TA-AR=",
                "C3850-SPWR-NONE",
                "CAB-SPWR-150CM-WS",
                "C1AACAT38502K9",
                "PWR-C1-440WDC-RF",
                "C3850-NEBS-KIT=",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "WS-C3850-48F-E",
                "PWR-C3-750WAC-R/2",
                "FAN-T3-F",
                "L-C3850-RTU=",
                "PWR-C1-1100WAC=",
                "CAB-AC-C15-KOR",
                "C3850-NM-4-1G-RF",
                "STACK-T1-50CM",
                "FAN-T3-R",
                "CAB-SPWR-30CM=",
                "CAB-C15-CBN-JP=",
                "CAB-AC-C15-TW=",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "C3850-FAN-T1-WS",
                "CAB-TA-UK=",
                "C3850-STACK-NONE",
                "PWR-C1-1100WAC-WS",
                "CAB-TA-NA",
                "C1AUCAT38502K9",
                "PWR-C3-750WAC-F/2",
                "STACK-T1-3M=",
                "FAN-T3-F-WS",
                "CAB-TA-SW=",
                "CAB-TA-JP-RA",
                "CAB-TA-EU",
                "CAB-C15-CBN-JP",
                "WS-C3850-48F-E-RF",
                "PWR-C1-715WAC-WS",
                "CAB-TA-EU=",
                "WS-C3850-48F-E-RL",
                "CAB-TA-UK",
                "FAN-T3-R=",
                "WS-C3850-48F-E-BR",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "CAB-TA-JP-RA=",
                "C3850-NM-4-1G-WS",
                "STACK-T1-50CM-RF",
                "CAB-SPWR-150CM=",
                "CAB-TA-TW",
                "STACK-T1-1M",
                "CAB-AC-C15-KOR=",
                "C3850-NM-4-1G=",
                "WS-C3850-48F-E++",
                "CAB-TA-CN",
                "PWR-C3-750WDC-F/2",
                "STACK-T1-1M-WS",
                "C1AACAT38502K9=",
                "CAB-TA-250V-JP=",
                "CAB-AC-C15-TW",
                "C1FACAT38502K9=",
                "PWR-C3-750WDC-R/2",
                "CAB-TA-SW",
                "STACK-T1-3M-RF",
                "C1FUCAT38502K9",
                "C3850-4PT-KIT-RF",
                "C3850-RACK-KIT-RF",
                "C3850-ACC-KIT=",
                "L-C3850-SLIC-U=",
                "PWR-C1-1100WAC-RF",
                "C1FACAT38502K9",
                "PWR-C1-1100WAC",
                "CAB-TA-IN=",
                "CAB-TA-JP=",
                "CAB-TA-250V-JP",
                "PWR-C1-715WAC-RF",
                "CAB-SPWR-30CM",
                "CAB-TA-DN=",
                "CAB-TA-JP",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "C1-C3850-48-DNAA-O",
                "CAB-TA-NA=",
                "WS-C3850-48F-E-WS",
                "UA-C3850-48F-E",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "CAB-TA-AR",
                "C1-C3850-48-DNAE-O",
                "STACK-T1-1M=",
                "CAB-SPWR-30CM-RF",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "PWR-C1-440WDC=",
                "CAB-TA-AP",
                "C3850-FAN-T1-RF",
                "CAB-TA-IN",
                "CAB-SPWR-150CM-RF",
                "PWR-C1-715WAC",
                "CAB-SPWR-150CM",
                "C1-WS3850-48F/K9",
                "C1FUCAT38502K9="
             ]
          },
          {  
             "mdfId":"286285439",
             "productName":"Cisco Catalyst 3850-24XU-S Switch",
             "productId":[  
                "FAN-T3-F=",
                "CAB-TA-TW",
                "C1FACAT38501K9=",
                "WS-C3850-24U-S++",
                "C3850-RTU=",
                "CAB-TA-CN=",
                "PWR-C1-440WDC",
                "CAB-AC-C15-KOR=",
                "CAB-TA-AR=",
                "C3850-4PT-KIT=",
                "WS-C3850-24XU-S-RF",
                "C1AACAT38504K9",
                "C3850-SPWR-NONE",
                "WS-C3850-24XU-SJ",
                "PWR-C1-440WDC-RF",
                "CAB-TA-CN",
                "C1FUCAT38501K9=",
                "C3850-NEBS-KIT=",
                "C1AUCAT38501K9",
                "CAB-TA-250V-JP=",
                "CAB-AC-C15-TW",
                "CAB-TA-SW",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "WS-C3850-24XU-S-WS",
                "FAN-T3-F-RF",
                "FAN-T3-F",
                "C3850-4PT-KIT-RF",
                "CAB-AC-C15-KOR",
                "LL-C3850-24XU-S=",
                "L-C3850-RTU=",
                "C3850-RACK-KIT-RF",
                "L-C3850-SLIC-U=",
                "C3850-ACC-KIT=",
                "C1FACAT38504K9",
                "FAN-T3-R",
                "C1AACAT38501K9=",
                "CAB-TA-IN=",
                "CAB-TA-250V-JP",
                "CAB-C15-CBN-JP=",
                "C1AACAT38501K9",
                "CAB-AC-C15-TW=",
                "CAB-TA-DN=",
                "CAB-TA-IT=",
                "CAB-TA-AP=",
                "C3850-FAN-T1-WS",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "WS-C3850-24XU",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "CAB-TA-UK=",
                "CAB-TA-AR",
                "C3850-STACK-NONE",
                "CAB-TA-NA",
                "C1AACAT38504K9=",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "CAB-TA-SW=",
                "FAN-T3-F-WS",
                "CAB-TA-AP",
                "PWR-C1-440WDC=",
                "CAB-TA-JP-RA",
                "CAB-TA-EU",
                "C3850-FAN-T1-RF",
                "CAB-C15-CBN-JP",
                "C1FACAT38504K9=",
                "CAB-TA-IN",
                "C1AUCAT38501K9=",
                "CAB-TA-EU=",
                "C1FUCAT38501K9",
                "WS-C3850-24XU-S++",
                "WS-C3850E-24XU-S",
                "WS-C3850-24XU-S",
                "FAN-T3-R=",
                "CAB-TA-UK",
                "CAB-TA-DN",
                "WS-C3850-24XUW-S",
                "C3850-RACK-KIT=",
                "C1FACAT38501K9",
                "CAB-TA-JP-RA="
             ]
          },
          {  
             "mdfId":"286289818",
             "productName":"Cisco Catalyst 3650-12X48UQ-S Switch",
             "productId":[  
                "PWR-C2-250WAC",
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "WSC365012X48UQS-RF",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "C3650-12X48UQ-S++",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-48FWD-S",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "WS-C3650-12X48UQ-S",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "WS-C3650-48FWDS-RF",
                "PWR-C2-640WAC",
                "RACK-KIT-T1-RF",
                "PWR-C2-640WDC-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-1025WAC",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "STACK-T2-3M=",
                "WSC365012X48URS-RF",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "C3560-NEBS-KIT=",
                "WSC365012X48UQS-WS",
                "PWR-C2-BLANK=",
                "PWR-C2-250WAC-RF",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "STACK-T2-3M",
                "PWR-C2-640WDC",
                "L-C3650-SLIC-U=",
                "NAL-C3650-12X48UQS",
                "C1-WS3650-48XFD/K9",
                "PWR-C2-250WAC-WS",
                "RACK-KIT-T1-WS",
                "PWR-C2-640WDC-WS",
                "RACK-KIT-T1=",
                "WSC365012X48URS-WS"
             ]
          },
          {  
             "mdfId":"286280278",
             "productName":"Cisco Catalyst 3850-24XS-S Switch",
             "productId":[  
                "FAN-T3-F=",
                "C1FACAT38501K9=",
                "CAB-TA-CN=",
                "C3850-RTU=",
                "PWR-C1-440WDC",
                "C3850-4PT-KIT=",
                "CAB-TA-AR=",
                "C3850-SPWR-NONE",
                "PWR-C1-440WDC-RF",
                "C1-WSC3850-24XS-S",
                "C3850-NEBS-KIT=",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "FAN-T3-F",
                "L-C3850-RTU=",
                "PWR-C1-1100WAC=",
                "CAB-AC-C15-KOR",
                "C1FACAT38504K9",
                "FAN-T3-R",
                "C1AACAT38501K9=",
                "STACK-T1-50CM",
                "CAB-C15-CBN-JP=",
                "CAB-AC-C15-TW=",
                "C1AACAT38501K9",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "C3850-FAN-T1-WS",
                "WS-C3850-24XS-S-WS",
                "CAB-TA-UK=",
                "C3850-STACK-NONE",
                "CAB-TA-NA",
                "PWR-C1-1100WAC-WS",
                "C3850-24XS-S++",
                "C1AACAT38504K9=",
                "STACK-T1-3M=",
                "FAN-T3-F-WS",
                "CAB-TA-SW=",
                "CAB-TA-JP-RA",
                "CAB-TA-EU",
                "CAB-C15-CBN-JP",
                "C1FACAT38504K9=",
                "PWR-C1-715WAC-WS",
                "CAB-TA-EU=",
                "C1AUCAT38501K9=",
                "FAN-T3-R=",
                "CAB-TA-UK",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "CAB-TA-JP-RA=",
                "STACK-T1-50CM-RF",
                "CAB-TA-TW",
                "STACK-T1-1M",
                "CAB-AC-C15-KOR=",
                "C1AACAT38504K9",
                "CAB-TA-CN",
                "C1FUCAT38501K9=",
                "STACK-T1-1M-WS",
                "C1AUCAT38501K9",
                "CAB-TA-250V-JP=",
                "CAB-AC-C15-TW",
                "CAB-TA-SW",
                "STACK-T1-3M-RF",
                "C3850-4PT-KIT-RF",
                "C3850-RACK-KIT-RF",
                "L-C3850-SLIC-U=",
                "C3850-ACC-KIT=",
                "PWR-C1-1100WAC-RF",
                "PWR-C1-1100WAC",
                "CAB-TA-IN=",
                "CAB-TA-250V-JP",
                "PWR-C1-715WAC-RF",
                "CAB-TA-DN=",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "WS-C3850-24XS-S",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "WS-C3850-24XS",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "CAB-TA-AR",
                "STACK-T1-1M=",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "PWR-C1-440WDC=",
                "CAB-TA-AP",
                "C3850-FAN-T1-RF",
                "WS-C3850-24XS-S-RF",
                "CAB-TA-IN",
                "PWR-C1-715WAC",
                "C1FUCAT38501K9",
                "LL-C3850-24XS-S=",
                "C1FACAT38501K9",
                "C1-WSC385024XSS-RF"
             ]
          },
          {  
             "mdfId":"284850604",
             "productName":"Cisco Catalyst 3650-24PD-S Switch",
             "productId":[  
                "WS-C3650-24PD-HC",
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "C1FUCAT36501K9",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "PWR-C2-640WDC-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "4PT-KIT-T1-RF",
                "C3650-RTU=",
                "C3560-NEBS-KIT=",
                "EDU-C3650-24PD-S",
                "C1-C3650-24-DNAA-O",
                "PWR-C2-250WAC-RF",
                "WS-C3650-24PD-S",
                "WS-C3650-24PWD-S",
                "STACK-T2-3M",
                "C1FUCAT36501K9=",
                "PWR-C2-640WDC",
                "C1-WS3650-24PD/K9",
                "NAL-C3650-24PWD-S",
                "WS-C3650-24PD-S-RF",
                "C1-C3650-24-DNAE-O",
                "C1AUCAT36501K9=",
                "WS-C3650-24PD",
                "PWR-C2-250WAC",
                "WS-C3650-24PD-S-WS",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "WS-C3650-24PWDS-RF",
                "C1FACAT36501K9",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "NAL-C3650-24PD-S",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "C1AUCAT36501K9",
                "C1AACAT36501K9=",
                "C3650-24PD-S++",
                "C1FACAT36501K9=",
                "C1AACAT36501K9",
                "L-C3650-SLIC-U=",
                "RACK-KIT-T1-WS",
                "PWR-C2-250WAC-WS",
                "PWR-C2-640WDC-WS",
                "RACK-KIT-T1=",
                "4PT-KIT-T1=",
                "ACC-KIT-T1=",
                "WSC365012X48URS-WS",
                "C1-WS3650-24XPD/K9"
             ]
          },
          {  
             "mdfId":"284850623",
             "productName":"Cisco Catalyst 3650-48FD-E Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "C3650-48FD-E++",
                "PWR-C2-250WAC=",
                "WS-C3650-48FWD-S",
                "WS-C3650-48FD-E-WS",
                "PWR-C2-640WAC=",
                "C1FACAT36502K9",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "PWR-C2-640WDC-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "C3560-NEBS-KIT=",
                "C3650-RTU=",
                "4PT-KIT-T1-RF",
                "PWR-C2-250WAC-RF",
                "C1AUCAT36502K9",
                "STACK-T2-3M",
                "C1-C3650-48-DNAA-O",
                "PWR-C2-640WDC",
                "C1-C3650-48-DNAE-O",
                "C1AACAT36502K9",
                "C1-WS3650-48FD/K9",
                "C1AUCAT36502K9=",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "C1FUCAT36502K9=",
                "C1FUCAT36502K9",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "WS-C3650-48FD-E",
                "WS-C3650-48FD",
                "C1AACAT36502K9=",
                "PWR-C2-640WAC-WS",
                "WS-C3650-48FWDS-RF",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "C1FACAT36502K9=",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "WS-C3650-48FD-E-RF",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "PWR-C2-250WAC-WS",
                "RACK-KIT-T1-WS",
                "NAL-C3650-48FD-E",
                "PWR-C2-640WDC-WS",
                "RACK-KIT-T1=",
                "WSC365012X48URS-WS",
                "ACC-KIT-T1=",
                "4PT-KIT-T1="
             ]
          },
          {  
             "mdfId":"284846124",
             "productName":"Cisco Catalyst 3650-48PS-E Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "WS-C3650-48FWD-S",
                "PWR-C2-640WAC=",
                "C1FACAT36502K9",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WDC-RF",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "4PT-KIT-T1-RF",
                "C3650-RTU=",
                "C3560-NEBS-KIT=",
                "LL-C3650-48PS-E=",
                "PWR-C2-250WAC-RF",
                "C1AUCAT36502K9",
                "STACK-T2-3M",
                "C1-C3650-48-DNAA-O",
                "PWR-C2-640WDC",
                "C1-C3650-48-DNAE-O",
                "C1AACAT36502K9",
                "WS-C3650-48PS",
                "C1AUCAT36502K9=",
                "WS-C3650-48PS-E",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "C1FUCAT36502K9",
                "C1FUCAT36502K9=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "C1AACAT36502K9=",
                "WS-C3650-48FWDS-RF",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "C1FACAT36502K9=",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "WS-C3650-48PS-E-WS",
                "PWR-C2-BLANK=",
                "C3650-48PS-E++",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "C1-WS3650-48PS/K9",
                "WS-C3650-48PS-E-RF",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "RACK-KIT-T1-WS",
                "PWR-C2-250WAC-WS",
                "NAL-C3650-48PS-E",
                "PWR-C2-640WDC-WS",
                "ACC-KIT-T1=",
                "4PT-KIT-T1=",
                "WSC365012X48URS-WS",
                "RACK-KIT-T1="
             ]
          },
          {  
             "mdfId":"284846028",
             "productName":"Cisco Catalyst 3650-48TS-S Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "WS-C3650-48FWD-S",
                "PWR-C2-640WAC=",
                "C1FACAT36502K9",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "PWR-C2-640WDC-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "4PT-KIT-T1-RF",
                "C3650-RTU=",
                "C3560-NEBS-KIT=",
                "C1-WS3650-48TS/K9",
                "PWR-C2-250WAC-RF",
                "C1AUCAT36502K9",
                "STACK-T2-3M",
                "WS-C3650-48TS-S-RF",
                "C1-C3650-48-DNAA-O",
                "PWR-C2-640WDC",
                "WS-C3650-48TS",
                "C1-C3650-48-DNAE-O",
                "C1AACAT36502K9",
                "C1AUCAT36502K9=",
                "PWR-C2-250WAC",
                "WS-C3650-48TS-S",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "C1FUCAT36502K9",
                "C1FUCAT36502K9=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "C1AACAT36502K9=",
                "PWR-C2-640WAC-WS",
                "NAL-C3650-48TS-S",
                "WS-C3650-48FWDS-RF",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "C1FACAT36502K9=",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "WS-C3650-48TS-S-WS",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "PWR-C2-250WAC-WS",
                "RACK-KIT-T1-WS",
                "PWR-C2-640WDC-WS",
                "RACK-KIT-T1=",
                "ACC-KIT-T1=",
                "4PT-KIT-T1=",
                "WSC365012X48URS-WS"
             ]
          },
          {  
             "mdfId":"286257124",
             "productName":"Cisco Catalyst 3850-12S-E Switch",
             "productId":[  
                "FAN-T3-F=",
                "C3850-NM-4-1G",
                "C3850-RTU=",
                "CAB-TA-CN=",
                "PWR-C1-440WDC",
                "WS-C3850-24P-E-WS",
                "C3850-4PT-KIT=",
                "CAB-TA-AR=",
                "C3850-SPWR-NONE",
                "PWR-C3-750WAC-R-RF",
                "CAB-SPWR-150CM-WS",
                "WS-C3850-12S-E-WS",
                "PWR-C1-440WDC-RF",
                "PWR-C3-750WDC-R-RF",
                "C3850-NEBS-KIT=",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "PWR-C3-750WAC-R",
                "LL-C3850-12S-E=",
                "STACK-T1-50CM-WS",
                "PWR-C3-750WAC-R/2",
                "FAN-T3-F",
                "CAB-AC-C15-KOR",
                "L-C3850-RTU=",
                "PWR-C1-1100WAC=",
                "C3850-NM-4-1G-RF",
                "STACK-T1-50CM",
                "FAN-T3-R",
                "CAB-SPWR-30CM=",
                "PWR-C3-750WDC-R",
                "CAB-C15-CBN-JP=",
                "CAB-AC-C15-TW=",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "C3850-FAN-T1-WS",
                "CAB-TA-UK=",
                "PWR-C3-750WDC-R-WS",
                "C3850-STACK-NONE",
                "NAL-3850-12S-E",
                "CAB-TA-NA",
                "PWR-C1-1100WAC-WS",
                "STACK-T1-3M=",
                "PWR-C3-750WAC-F/2",
                "CAB-TA-SW=",
                "FAN-T3-F-WS",
                "CAB-TA-JP-RA",
                "CAB-TA-EU",
                "CAB-C15-CBN-JP",
                "C1-C3850-12-DNAA-O",
                "PWR-C1-715WAC-WS",
                "CAB-TA-EU=",
                "WS-C3850-24P-E-RF",
                "C1-WS3850-12S/K9",
                "FAN-T3-R=",
                "CAB-TA-UK",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "C1FACAT38503K9",
                "STACK-T1-3M-WS",
                "WS-C3850-12S-E-RF",
                "C3850-12S-E++",
                "C1-C3850-12-DNAE-O",
                "CAB-TA-JP-RA=",
                "STACK-T1-50CM-RF",
                "C3850-NM-4-1G-WS",
                "CAB-SPWR-150CM=",
                "CAB-TA-TW",
                "STACK-T1-1M",
                "CAB-AC-C15-KOR=",
                "C3850-NM-4-1G=",
                "CAB-TA-CN",
                "PWR-C3-750WDC-F/2",
                "STACK-T1-1M-WS",
                "CAB-TA-250V-JP=",
                "CAB-AC-C15-TW",
                "PWR-C3-750WDC-R/2",
                "CAB-TA-SW",
                "PWR-C3-750WAC-R=",
                "STACK-T1-3M-RF",
                "WS-C3850-12S-E",
                "C3850-4PT-KIT-RF",
                "C3850-RACK-KIT-RF",
                "PWR-C1-1100WAC-RF",
                "C3850-ACC-KIT=",
                "L-C3850-SLIC-U=",
                "WS-C3850-24P-E",
                "PWR-C1-1100WAC",
                "CAB-TA-IN=",
                "PWR-C1-715WAC-RF",
                "CAB-TA-250V-JP",
                "CAB-SPWR-30CM",
                "C1FACAT38503K9=",
                "CAB-TA-DN=",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "C1AACAT38503K9",
                "C3850-FAN-T1=",
                "CAB-TA-AR",
                "PWR-C3-750WAC-R-WS",
                "CAB-SPWR-30CM-RF",
                "STACK-T1-1M=",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "PWR-C1-440WDC=",
                "CAB-TA-AP",
                "C3850-FAN-T1-RF",
                "CAB-TA-IN",
                "CAB-SPWR-150CM-RF",
                "PWR-C1-715WAC",
                "WS-C3850-12S",
                "CAB-SPWR-150CM",
                "PWR-C3-750WDC-R=",
                "C1AACAT38503K9="
             ]
          },
          {  
             "mdfId":"286290845",
             "productName":"Cisco Catalyst 3650-48FQM-E Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "WS-C3650-48FQM-E",
                "WS-C3650-48FWD-S",
                "PWR-C2-640WAC=",
                "WS-C3650-48FQ-E-HC",
                "L-C3650-ADD-12T=",
                "C1FACAT36502K9",
                "PWR-C2-640WAC",
                "WS-C3650-48FQME-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WDC-RF",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "C3650-RTU=",
                "C3560-NEBS-KIT=",
                "C1WS365048FQ/K9-RF",
                "4PT-KIT-T1-RF",
                "PWR-C2-250WAC-RF",
                "C1AUCAT36502K9",
                "STACK-T2-3M",
                "WS-C3650-48FQM",
                "WS-C3650-48FQ-HC",
                "PWR-C2-640WDC",
                "C1AACAT36502K9",
                "C1AUCAT36502K9=",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "C1FUCAT36502K9=",
                "C1FUCAT36502K9",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "C1AACAT36502K9=",
                "C3650-48FQM-E++",
                "RACK-KIT-T1-RF",
                "WS-C3650-48FWDS-RF",
                "PWR-C2-1025WAC",
                "C1FACAT36502K9=",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "C1-WS3650-48FQ/K9",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "RACK-KIT-T1-WS",
                "PWR-C2-250WAC-WS",
                "PWR-C2-640WDC-WS",
                "RACK-KIT-T1=",
                "ACC-KIT-T1=",
                "WSC365012X48URS-WS",
                "4PT-KIT-T1="
             ]
          },
          {  
             "mdfId":"286309950",
             "productName":"Cisco Catalyst 3650-8X24PD-S Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "STACK-T2-3M",
                "WS-C3650-12X48UR-S",
                "NAL-C3650-8X24PD-S",
                "RACK-KIT-T1-RF",
                "C3650-NEBS-KIT=",
                "STACK-T2-3M=",
                "WSC365012X48URS-RF",
                "C3560-NEBS-KIT=",
                "STACK-T2-3M-WS",
                "C1-WS3650-24XPD/K9",
                "WSC365012X48URS-WS",
                "RACK-KIT-T1="
             ]
          },
          {  
             "mdfId":"286309960",
             "productName":"Cisco Catalyst 3650-8X24PD-L Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "STACK-T2-3M",
                "NAL-C3650-8X24PD-L",
                "WS-C3650-12X48UR-S",
                "RACK-KIT-T1-RF",
                "STACK-T2-3M=",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "STACK-T2-3M-WS",
                "C3560-NEBS-KIT=",
                "C1-WS3650-24XPD/K9",
                "RACK-KIT-T1=",
                "WSC365012X48URS-WS"
             ]
          },
          {  
             "mdfId":"284846122",
             "productName":"Cisco Catalyst 3650-24PS-E Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "WS-C3560-24PS-E",
                "PWR-C2-250WAC=",
                "C3650-24PS-E++",
                "C1FUCAT36501K9",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "PWR-C2-640WDC-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "WS-C3560-24PS-E-WS",
                "C3560-NEBS-KIT=",
                "4PT-KIT-T1-RF",
                "C3650-RTU=",
                "NAL-C3650-24PS-E",
                "C1-C3650-24-DNAA-O",
                "PWR-C2-250WAC-RF",
                "STACK-T2-3M",
                "C1FUCAT36501K9=",
                "PWR-C2-640WDC",
                "C1-C3650-24-DNAE-O",
                "C1AUCAT36501K9=",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "WS-C3650-24PS-E-WS",
                "C1-WS3650-24PS/K9",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "C1FACAT36501K9",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "WS-C3650-24PS-E-RF",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "WS-C3650-24PS-E",
                "C1AUCAT36501K9",
                "C1AACAT36501K9=",
                "WS-C3560-24PS-E-RF",
                "WS-C3650-24PS",
                "C1FACAT36501K9=",
                "C1AACAT36501K9",
                "L-C3650-SLIC-U=",
                "RACK-KIT-T1-WS",
                "PWR-C2-250WAC-WS",
                "PWR-C2-640WDC-WS",
                "LL-C3650-24PS-E=",
                "WSC365012X48URS-WS",
                "C1-WS3650-24XPD/K9",
                "ACC-KIT-T1=",
                "RACK-KIT-T1=",
                "4PT-KIT-T1="
             ]
          },
          {  
             "mdfId":"284455365",
             "productName":"Cisco Catalyst 3850-24T-L Switch",
             "productId":[  
                "FAN-T3-F=",
                "C1FACAT38501K9=",
                "C3850-NM-4-1G",
                "CAB-TA-CN=",
                "C3850-RTU=",
                "PWR-C1-440WDC",
                "C3850-4PT-KIT=",
                "CAB-TA-AR=",
                "C3850-SPWR-NONE",
                "CAB-SPWR-150CM-WS",
                "PWR-C1-440WDC-RF",
                "C3850-NEBS-KIT=",
                "C1-C3850-24-DNAE-O",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "C1-WS3850-24T/K9",
                "STACK-T1-50CM-WS",
                "PWR-C3-750WAC-R/2",
                "FAN-T3-F",
                "L-C3850-RTU=",
                "PWR-C1-1100WAC=",
                "CAB-AC-C15-KOR",
                "C3850-NM-4-1G-RF",
                "C1FACAT38504K9",
                "FAN-T3-R",
                "STACK-T1-50CM",
                "C1AACAT38501K9=",
                "CAB-SPWR-30CM=",
                "C1-C3850-24-DNAA-O",
                "CAB-C15-CBN-JP=",
                "CAB-AC-C15-TW=",
                "C1AACAT38501K9",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "C3850-FAN-T1-WS",
                "CAB-TA-UK=",
                "C3850-STACK-NONE",
                "PWR-C1-1100WAC-WS",
                "CAB-TA-NA",
                "WS-C3850-24T-L-BR",
                "C1AACAT38504K9=",
                "WS-C3850-24T-L++",
                "STACK-T1-3M=",
                "PWR-C3-750WAC-F/2",
                "CAB-TA-SW=",
                "FAN-T3-F-WS",
                "CAB-TA-EU",
                "CAB-TA-JP-RA",
                "CAB-C15-CBN-JP",
                "WS-C3850-24T-L-RL",
                "C1FACAT38504K9=",
                "PWR-C1-715WAC-WS",
                "C1AUCAT38501K9=",
                "CAB-TA-EU=",
                "CAB-TA-UK",
                "FAN-T3-R=",
                "WS-C3850-24T-L-RF",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "CAB-TA-JP-RA=",
                "STACK-T1-50CM-RF",
                "C3850-NM-4-1G-WS",
                "CAB-SPWR-150CM=",
                "CAB-TA-TW",
                "C1-WS385024T/K9-RF",
                "STACK-T1-1M",
                "CAB-AC-C15-KOR=",
                "C1AACAT38504K9",
                "C3850-NM-4-1G=",
                "CAB-TA-CN",
                "PWR-C3-750WDC-F/2",
                "C1FUCAT38501K9=",
                "STACK-T1-1M-WS",
                "C1AUCAT38501K9",
                "CAB-TA-250V-JP=",
                "CAB-AC-C15-TW",
                "WS-C3850-24T-L",
                "PWR-C3-750WDC-R/2",
                "CAB-TA-SW",
                "STACK-T1-3M-RF",
                "C3850-4PT-KIT-RF",
                "C3850-RACK-KIT-RF",
                "L-C3850-SLIC-U=",
                "C3850-ACC-KIT=",
                "PWR-C1-1100WAC-RF",
                "PWR-C1-1100WAC",
                "CAB-TA-IN=",
                "CAB-TA-JP=",
                "CAB-TA-250V-JP",
                "PWR-C1-715WAC-RF",
                "WS-C3850-24T-L-WS",
                "CAB-SPWR-30CM",
                "CAB-TA-DN=",
                "CAB-TA-JP",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "CAB-TA-AR",
                "STACK-T1-1M=",
                "CAB-SPWR-30CM-RF",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "CAB-TA-AP",
                "PWR-C1-440WDC=",
                "C3850-FAN-T1-RF",
                "CAB-TA-IN",
                "CAB-SPWR-150CM-RF",
                "PWR-C1-715WAC",
                "C1FUCAT38501K9",
                "WS-C3850-24T",
                "CAB-SPWR-150CM",
                "C1FACAT38501K9"
             ]
          },
          {  
             "mdfId":"284850601",
             "productName":"Cisco Catalyst 3650-24TD-S Switch",
             "productId":[  
                "WS-C3650-24TD-S-RF",
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "C1-WS3650-24TD/K9",
                "C3650-24TD-S++",
                "C1FUCAT36501K9",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WDC-RF",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "C3560-NEBS-KIT=",
                "C3650-RTU=",
                "4PT-KIT-T1-RF",
                "C1-C3650-24-DNAA-O",
                "PWR-C2-250WAC-RF",
                "STACK-T2-3M",
                "WS-C3650-24TD-S",
                "C1FUCAT36501K9=",
                "PWR-C2-640WDC",
                "C1-C3650-24-DNAE-O",
                "C1AUCAT36501K9=",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "C1FACAT36501K9",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "NAL-C3650-24TD-S",
                "WS-C3650-24TD",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "C1AUCAT36501K9",
                "C1AACAT36501K9=",
                "C3650-48TD-S++",
                "C1FACAT36501K9=",
                "C1AACAT36501K9",
                "L-C3650-SLIC-U=",
                "WS-C3650-24TD-S-WS",
                "PWR-C2-250WAC-WS",
                "RACK-KIT-T1-WS",
                "PWR-C2-640WDC-WS",
                "C1-WS3650-24XPD/K9",
                "RACK-KIT-T1=",
                "4PT-KIT-T1=",
                "WSC365012X48URS-WS",
                "ACC-KIT-T1="
             ]
          },
          {  
             "mdfId":"286289979",
             "productName":"Cisco Catalyst 3650-8X24UQ-E Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC",
                "PWR-C2-250WAC=",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "WS-C3650-8X24UQ-E",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "RACK-KIT-T1-RF",
                "PWR-C2-640WDC-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-1025WAC",
                "PWR-C2-640WAC-RF",
                "STACK-T2-3M=",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "PWR-C2-BLANK",
                "C3650-8X24UQ-E++",
                "C3560-NEBS-KIT=",
                "STACK-T2-3M-WS",
                "WS-C36508X24UQE-RF",
                "PWR-C2-BLANK=",
                "PWR-C2-250WAC-RF",
                "WSC36508X24UQE-WS",
                "PWR-C2-1025WAC-WS",
                "NAL-C3650-8X24UQ-E",
                "PWR-C2-1025WAC=",
                "STACK-T2-3M",
                "PWR-C2-640WDC",
                "L-C3650-SLIC-U=",
                "PWR-C2-250WAC-WS",
                "RACK-KIT-T1-WS",
                "PWR-C2-640WDC-WS",
                "WSC365012X48URS-WS",
                "C1-WS3650-24XPD/K9",
                "RACK-KIT-T1="
             ]
          },
          {  
             "mdfId":"286289899",
             "productName":"Cisco Catalyst 3650-12X48UR-L Switch",
             "productId":[  
                "PWR-C2-250WAC",
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-48FWD-S",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "WS-C3650-48FWDS-RF",
                "PWR-C2-640WAC",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "PWR-C2-640WDC-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "STACK-T2-3M=",
                "WSC365012X48URS-RF",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "C3560-NEBS-KIT=",
                "NAL-C3650-12X48URL",
                "PWR-C2-BLANK=",
                "PWR-C2-250WAC-RF",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "STACK-T2-3M",
                "C3650-12X48UR-L++",
                "PWR-C2-640WDC",
                "L-C3650-SLIC-U=",
                "WS-C3650-12X48UR-L",
                "C1-WS3650-48XFD/K9",
                "PWR-C2-250WAC-WS",
                "RACK-KIT-T1-WS",
                "WSC365012X48URL-RF",
                "PWR-C2-640WDC-WS",
                "WSC365012X48URS-WS",
                "RACK-KIT-T1="
             ]
          },
          {  
             "mdfId":"284846017",
             "productName":"Cisco Catalyst 3650-24PS-L Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "C1FUCAT36501K9",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "PWR-C2-640WDC-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "LL-C3650-24PS-L=",
                "4PT-KIT-T1-RF",
                "C3650-RTU=",
                "C3560-NEBS-KIT=",
                "WS-C3650-24PS-L-WS",
                "C1-C3650-24-DNAA-O",
                "PWR-C2-250WAC-RF",
                "STACK-T2-3M",
                "NAL-C3650-24PS-L",
                "C1FUCAT36501K9=",
                "PWR-C2-640WDC",
                "C1-C3650-24-DNAE-O",
                "C1AUCAT36501K9=",
                "PWR-C2-250WAC",
                "WS-C3650-24PS-L-RF",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "C1-WS3650-24PS/K9",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "C1FACAT36501K9",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "WS-C3650-24PS-L",
                "C3650-24PS-L++",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "EDU-C3650-24PS-L",
                "C1AUCAT36501K9",
                "C1AACAT36501K9=",
                "WS-C3650-24PS",
                "C1FACAT36501K9=",
                "C1AACAT36501K9",
                "L-C3650-SLIC-U=",
                "RACK-KIT-T1-WS",
                "PWR-C2-250WAC-WS",
                "PWR-C2-640WDC-WS",
                "ACC-KIT-T1=",
                "WSC365012X48URS-WS",
                "C1-WS3650-24XPD/K9",
                "RACK-KIT-T1=",
                "4PT-KIT-T1="
             ]
          },
          {  
             "mdfId":"284455379",
             "productName":"Cisco Catalyst 3850-48T-L Switch",
             "productId":[  
                "WS-C3850-48T-L-RF",
                "FAN-T3-F=",
                "C3850-NM-4-1G",
                "CAB-TA-CN=",
                "C3850-RTU=",
                "PWR-C1-440WDC",
                "CAB-TA-AR=",
                "C3850-4PT-KIT=",
                "C1AUCAT38502K9=",
                "C3850-SPWR-NONE",
                "CAB-SPWR-150CM-WS",
                "C1AACAT38502K9",
                "PWR-C1-440WDC-RF",
                "WS-C3850-48T-L-RL",
                "C3850-NEBS-KIT=",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "PWR-C3-750WAC-R/2",
                "FAN-T3-F",
                "L-C3850-RTU=",
                "PWR-C1-1100WAC=",
                "CAB-AC-C15-KOR",
                "C3850-NM-4-1G-RF",
                "WS-C3850-48T-L++",
                "STACK-T1-50CM",
                "FAN-T3-R",
                "CAB-SPWR-30CM=",
                "CAB-C15-CBN-JP=",
                "CAB-AC-C15-TW=",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "C3850-FAN-T1-WS",
                "CAB-TA-UK=",
                "C3850-STACK-NONE",
                "CAB-TA-NA",
                "PWR-C1-1100WAC-WS",
                "C1AUCAT38502K9",
                "STACK-T1-3M=",
                "PWR-C3-750WAC-F/2",
                "CAB-TA-SW=",
                "FAN-T3-F-WS",
                "CAB-TA-EU",
                "CAB-TA-JP-RA",
                "CAB-C15-CBN-JP",
                "PWR-C1-715WAC-WS",
                "CAB-TA-EU=",
                "FAN-T3-R=",
                "CAB-TA-UK",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "CAB-TA-JP-RA=",
                "C3850-NM-4-1G-WS",
                "STACK-T1-50CM-RF",
                "CAB-TA-TW",
                "CAB-SPWR-150CM=",
                "STACK-T1-1M",
                "CAB-AC-C15-KOR=",
                "C3850-NM-4-1G=",
                "CAB-TA-CN",
                "PWR-C3-750WDC-F/2",
                "STACK-T1-1M-WS",
                "C1AACAT38502K9=",
                "CAB-TA-250V-JP=",
                "CAB-AC-C15-TW",
                "C1FACAT38502K9=",
                "PWR-C3-750WDC-R/2",
                "CAB-TA-SW",
                "STACK-T1-3M-RF",
                "UA-C3850-48T-L",
                "C1FUCAT38502K9",
                "C3850-4PT-KIT-RF",
                "C3850-RACK-KIT-RF",
                "WS-C3850-48T-L-WS",
                "C3850-ACC-KIT=",
                "PWR-C1-1100WAC-RF",
                "L-C3850-SLIC-U=",
                "PWR-C1-1100WAC",
                "C1FACAT38502K9",
                "CAB-TA-IN=",
                "CAB-TA-JP=",
                "PWR-C1-715WAC-RF",
                "WS-C3850-48T-L",
                "CAB-TA-250V-JP",
                "CAB-SPWR-30CM",
                "CAB-TA-DN=",
                "C1-WS3850-48T/K9",
                "CAB-TA-JP",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "C1-C3850-48-DNAA-O",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "C1-C3850-48-DNAE-O",
                "CAB-TA-AR",
                "CAB-SPWR-30CM-RF",
                "STACK-T1-1M=",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "CAB-TA-AP",
                "PWR-C1-440WDC=",
                "C3850-FAN-T1-RF",
                "CAB-TA-IN",
                "CAB-SPWR-150CM-RF",
                "PWR-C1-715WAC",
                "WS-C3850-48T",
                "CAB-SPWR-150CM",
                "C1FUCAT38502K9="
             ]
          },
          {  
             "mdfId":"284850620",
             "productName":"Cisco Catalyst 3650-48TD-E Switch",
             "productId":[  
                "WS-C3650-48TD",
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "WS-C3650-48FWD-S",
                "PWR-C2-640WAC=",
                "C1FACAT36502K9",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "WS-C3650-48TD-E-WS",
                "PWR-C2-640WDC-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "C3560-NEBS-KIT=",
                "4PT-KIT-T1-RF",
                "C3650-RTU=",
                "PWR-C2-250WAC-RF",
                "C1AUCAT36502K9",
                "STACK-T2-3M",
                "WS-C3650-48TD-E",
                "NAL-C3650-48TD-E",
                "C1-C3650-48-DNAA-O",
                "PWR-C2-640WDC",
                "C1-C3650-48-DNAE-O",
                "C1AACAT36502K9",
                "C1AUCAT36502K9=",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "C1FUCAT36502K9",
                "C1FUCAT36502K9=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-48TD-E-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "C1AACAT36502K9=",
                "C3650-48TD-E++",
                "WS-C3650-48FWDS-RF",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "C1FACAT36502K9=",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "C1-WS3650-48TD/K9",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "PWR-C2-250WAC-WS",
                "RACK-KIT-T1-WS",
                "PWR-C2-640WDC-WS",
                "ACC-KIT-T1=",
                "4PT-KIT-T1=",
                "WSC365012X48URS-WS",
                "RACK-KIT-T1="
             ]
          },
          {  
             "mdfId":"284455439",
             "productName":"Cisco Catalyst 3850-48U-E Switch",
             "productId":[  
                "FAN-T3-F=",
                "C3850-NM-4-1G",
                "CAB-TA-CN=",
                "WS-C3850-48U-E-WS",
                "C3850-RTU=",
                "PWR-C1-440WDC",
                "WS-C385012X48UE-RF",
                "C1AUCAT38502K9=",
                "C3850-4PT-KIT=",
                "CAB-TA-AR=",
                "C3850-SPWR-NONE",
                "C1AACAT38502K9",
                "CAB-SPWR-150CM-WS",
                "WS-C3850E-12X48U-E",
                "PWR-C1-440WDC-RF",
                "UA-C3850-48U-E",
                "C3850-NEBS-KIT=",
                "WS-C3850-12X48U-E",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "PWR-C3-750WAC-R/2",
                "FAN-T3-F",
                "CAB-AC-C15-KOR",
                "L-C3850-RTU=",
                "PWR-C1-1100WAC=",
                "C3850-NM-4-1G-RF",
                "FAN-T3-R",
                "STACK-T1-50CM",
                "CAB-SPWR-30CM=",
                "CAB-C15-CBN-JP=",
                "CAB-AC-C15-TW=",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "C3850-FAN-T1-WS",
                "CAB-TA-UK=",
                "C3850-STACK-NONE",
                "PWR-C1-1100WAC-WS",
                "CAB-TA-NA",
                "WS-C3850E-12X48U",
                "C1AUCAT38502K9",
                "PWR-C3-750WAC-F/2",
                "STACK-T1-3M=",
                "FAN-T3-F-WS",
                "CAB-TA-SW=",
                "CAB-TA-JP-RA",
                "CAB-TA-EU",
                "CAB-C15-CBN-JP",
                "C1-WS3850-48U/K9",
                "PWR-C1-715WAC-WS",
                "CAB-TA-EU=",
                "WS-C3850-48U-E++",
                "CAB-TA-UK",
                "FAN-T3-R=",
                "WS-C3850-12X48U",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "CAB-TA-JP-RA=",
                "C3850-NM-4-1G-WS",
                "STACK-T1-50CM-RF",
                "CAB-TA-TW",
                "CAB-SPWR-150CM=",
                "STACK-T1-1M",
                "CAB-AC-C15-KOR=",
                "C3850-NM-4-1G=",
                "WS-C3850-48U-E",
                "CAB-TA-CN",
                "PWR-C3-750WDC-F/2",
                "STACK-T1-1M-WS",
                "C1AACAT38502K9=",
                "CAB-TA-250V-JP=",
                "CAB-AC-C15-TW",
                "C1FACAT38502K9=",
                "PWR-C3-750WDC-R/2",
                "CAB-TA-SW",
                "STACK-T1-3M-RF",
                "C1FUCAT38502K9",
                "WS-C3850-48U-E-RF",
                "C3850-4PT-KIT-RF",
                "C3850-RACK-KIT-RF",
                "C3850-ACC-KIT=",
                "L-C3850-SLIC-U=",
                "PWR-C1-1100WAC-RF",
                "PWR-C1-1100WAC",
                "C1FACAT38502K9",
                "CAB-TA-IN=",
                "CAB-TA-JP=",
                "PWR-C1-715WAC-RF",
                "CAB-TA-250V-JP",
                "CAB-SPWR-30CM",
                "CAB-TA-DN=",
                "CAB-TA-JP",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "C1-C3850-48-DNAA-O",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "C1-C3850-48-DNAE-O",
                "CAB-TA-AR",
                "CAB-SPWR-30CM-RF",
                "STACK-T1-1M=",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "CAB-TA-AP",
                "PWR-C1-440WDC=",
                "C3850-FAN-T1-RF",
                "CAB-TA-IN",
                "CAB-SPWR-150CM-RF",
                "PWR-C1-715WAC",
                "WS-C3850-48U",
                "CAB-SPWR-150CM",
                "C1FUCAT38502K9=",
                "WS-C385012X48UE-WS"
             ]
          },
          {  
             "mdfId":"286280328",
             "productName":"Cisco Catalyst 3850-48XS-E Switch",
             "productId":[  
                "FAN-T3-F=",
                "C3850-RTU=",
                "CAB-TA-CN=",
                "PWR-C1-440WDC",
                "CAB-TA-AR=",
                "C3850-4PT-KIT=",
                "C1AUCAT38502K9=",
                "C3850-SPWR-NONE",
                "C1AACAT38502K9",
                "PWR-C1-440WDC-RF",
                "PWR-C3-750WAC-F",
                "C3850-NEBS-KIT=",
                "CAB-TA-IS=",
                "WS-C3850-48XS-E",
                "C3850-FAN-T1",
                "WS-C3850-48XS-E-RF",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "FAN-T3-F",
                "C3850-48XS-E++",
                "CAB-AC-C15-KOR",
                "L-C3850-RTU=",
                "PWR-C1-1100WAC=",
                "FAN-T3-R",
                "STACK-T1-50CM",
                "CAB-C15-CBN-JP=",
                "CAB-AC-C15-TW=",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "C3850-FAN-T1-WS",
                "CAB-TA-UK=",
                "C3850-STACK-NONE",
                "CAB-TA-NA",
                "PWR-C1-1100WAC-WS",
                "C1AUCAT38502K9",
                "STACK-T1-3M=",
                "PWR-C3-750WAC-F-WS",
                "CAB-TA-SW=",
                "FAN-T3-F-WS",
                "CAB-TA-JP-RA",
                "CAB-TA-EU",
                "CAB-C15-CBN-JP",
                "PWR-C1-715WAC-WS",
                "CAB-TA-EU=",
                "FAN-T3-R=",
                "CAB-TA-UK",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "CAB-TA-JP-RA=",
                "STACK-T1-50CM-RF",
                "CAB-TA-TW",
                "STACK-T1-1M",
                "CAB-AC-C15-KOR=",
                "WS-C3850-48XS",
                "CAB-TA-CN",
                "WS-C3850-48XS-E-WS",
                "STACK-T1-1M-WS",
                "C1AACAT38502K9=",
                "CAB-TA-250V-JP=",
                "CAB-AC-C15-TW",
                "C1FACAT38502K9=",
                "CAB-TA-SW",
                "STACK-T1-3M-RF",
                "C1FUCAT38502K9",
                "C3850-4PT-KIT-RF",
                "PWR-C3-750WAC-F-RF",
                "C3850-RACK-KIT-RF",
                "C3850-ACC-KIT=",
                "L-C3850-SLIC-U=",
                "PWR-C1-1100WAC-RF",
                "C1FACAT38502K9",
                "PWR-C1-1100WAC",
                "CAB-TA-IN=",
                "PWR-C1-715WAC-RF",
                "CAB-TA-250V-JP",
                "CAB-TA-DN=",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "CAB-TA-AR",
                "PWR-C3-750WAC-F=",
                "STACK-T1-1M=",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "LL-C3850-48XS-E=",
                "PWR-C1-440WDC=",
                "CAB-TA-AP",
                "C3850-FAN-T1-RF",
                "CAB-TA-IN",
                "PWR-C1-715WAC",
                "C1FUCAT38502K9="
             ]
          },
          {  
             "mdfId":"286280298",
             "productName":"Cisco Catalyst 3850-32XS-S Switch",
             "productId":[  
                "STACK-T1-50CM-RF",
                "FAN-T3-F=",
                "CAB-TA-TW",
                "CAB-TA-CN=",
                "C3850-RTU=",
                "STACK-T1-1M",
                "PWR-C1-440WDC",
                "C3850-32XS-S++",
                "CAB-AC-C15-KOR=",
                "C3850-4PT-KIT=",
                "CAB-TA-AR=",
                "WS-C3850-32XS-S-WS",
                "C3850-SPWR-NONE",
                "PWR-C1-440WDC-RF",
                "CAB-TA-CN",
                "C3850-NEBS-KIT=",
                "STACK-T1-1M-WS",
                "CAB-TA-250V-JP=",
                "CAB-AC-C15-TW",
                "CAB-TA-SW",
                "WS-C3850-32XS-S-RF",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "STACK-T1-3M-RF",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "FAN-T3-F",
                "C3850-4PT-KIT-RF",
                "CAB-AC-C15-KOR",
                "L-C3850-RTU=",
                "PWR-C1-1100WAC=",
                "C3850-RACK-KIT-RF",
                "L-C3850-SLIC-U=",
                "C3850-ACC-KIT=",
                "PWR-C1-1100WAC-RF",
                "STACK-T1-50CM",
                "FAN-T3-R",
                "PWR-C1-1100WAC",
                "CAB-TA-IN=",
                "CAB-TA-250V-JP",
                "CAB-C15-CBN-JP=",
                "PWR-C1-715WAC-RF",
                "CAB-AC-C15-TW=",
                "CAB-TA-DN=",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "C3850-FAN-T1-WS",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "CAB-TA-UK=",
                "C3850-FAN-T1=",
                "CAB-TA-AR",
                "C3850-STACK-NONE",
                "PWR-C1-1100WAC-WS",
                "CAB-TA-NA",
                "WS-C3850-32XS-S",
                "STACK-T1-1M=",
                "STACK-T1-3M=",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "FAN-T3-F-WS",
                "CAB-TA-SW=",
                "CAB-TA-AP",
                "PWR-C1-440WDC=",
                "CAB-TA-EU",
                "CAB-TA-JP-RA",
                "C3850-FAN-T1-RF",
                "CAB-C15-CBN-JP",
                "CAB-TA-IN",
                "PWR-C1-715WAC",
                "PWR-C1-715WAC-WS",
                "CAB-TA-EU=",
                "CAB-TA-UK",
                "FAN-T3-R=",
                "LL-C3850-32XS-S=",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "CAB-TA-JP-RA="
             ]
          },
          {  
             "mdfId":"286285459",
             "productName":"Cisco Catalyst C3850-12X48U-L Switch",
             "productId":[  
                "FAN-T3-F=",
                "CAB-TA-TW",
                "WS-C3850-12X48U-LJ",
                "C3850-RTU=",
                "CAB-TA-CN=",
                "PWR-C1-440WDC",
                "LL-C3850-12X48U-L=",
                "WS-C3850-12X48U-L",
                "CAB-AC-C15-KOR=",
                "CAB-TA-AR=",
                "C3850-4PT-KIT=",
                "C3850-SPWR-NONE",
                "PWR-C1-440WDC-RF",
                "CAB-TA-CN",
                "WS-C3850-12X48UW-S",
                "C3850-NEBS-KIT=",
                "CAB-TA-250V-JP=",
                "CAB-AC-C15-TW",
                "CAB-TA-IS=",
                "CAB-TA-SW",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "FAN-T3-F",
                "C3850-4PT-KIT-RF",
                "CAB-AC-C15-KOR",
                "L-C3850-RTU=",
                "C3850-RACK-KIT-RF",
                "C1-WSC3850-12X48UL",
                "C3850-ACC-KIT=",
                "L-C3850-SLIC-U=",
                "FAN-T3-R",
                "CAB-TA-IN=",
                "CAB-TA-250V-JP",
                "CAB-C15-CBN-JP=",
                "CAB-AC-C15-TW=",
                "CAB-TA-DN=",
                "C1FACAT38503K9=",
                "CAB-TA-IT=",
                "CAB-TA-AP=",
                "C3850-FAN-T1-WS",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "C1AACAT38503K9",
                "C3850-FAN-T1=",
                "CAB-TA-UK=",
                "CAB-TA-AR",
                "C3850-STACK-NONE",
                "CAB-TA-NA",
                "WS-C385012X48UL-WS",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "WSC385012X48UWS-RF",
                "CAB-TA-SW=",
                "FAN-T3-F-WS",
                "CAB-TA-AP",
                "PWR-C1-440WDC=",
                "CAB-TA-JP-RA",
                "CAB-TA-EU",
                "C3850-FAN-T1-RF",
                "CAB-C15-CBN-JP",
                "CAB-TA-IN",
                "CAB-TA-EU=",
                "WS-C385012X48UL-RF",
                "CAB-TA-UK",
                "FAN-T3-R=",
                "C3850-12X48U-L++",
                "CAB-TA-DN",
                "C1FACAT38503K9",
                "C3850-RACK-KIT=",
                "CAB-TA-JP-RA=",
                "C1AACAT38503K9="
             ]
          },
          {  
             "mdfId":"284455436",
             "productName":"Cisco Catalyst 3850-48P-E Switch",
             "productId":[  
                "FAN-T3-F=",
                "C3850-NM-4-1G",
                "CAB-TA-CN=",
                "C3850-RTU=",
                "PWR-C1-440WDC",
                "C1AUCAT38502K9=",
                "C3850-4PT-KIT=",
                "CAB-TA-AR=",
                "C3850-SPWR-NONE",
                "C1AACAT38502K9",
                "CAB-SPWR-150CM-WS",
                "PWR-C1-440WDC-RF",
                "C3850-NEBS-KIT=",
                "WS-C3850-48P-E-BR",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "PWR-C3-750WAC-R/2",
                "FAN-T3-F",
                "L-C3850-RTU=",
                "PWR-C1-1100WAC=",
                "CAB-AC-C15-KOR",
                "C3850-NM-4-1G-RF",
                "FAN-T3-R",
                "STACK-T1-50CM",
                "CAB-SPWR-30CM=",
                "WS-C3850-48P-E++",
                "CAB-C15-CBN-JP=",
                "CAB-AC-C15-TW=",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "WS-C3850-48P-E-RL",
                "C3850-FAN-T1-WS",
                "WS-C3850-48P-E-RF",
                "CAB-TA-UK=",
                "C3850-STACK-NONE",
                "CAB-TA-NA",
                "PWR-C1-1100WAC-WS",
                "C1AUCAT38502K9",
                "STACK-T1-3M=",
                "PWR-C3-750WAC-F/2",
                "UA-C3850-48P-E",
                "CAB-TA-SW=",
                "FAN-T3-F-WS",
                "CAB-TA-EU",
                "CAB-TA-JP-RA",
                "CAB-C15-CBN-JP",
                "PWR-C1-715WAC-WS",
                "CAB-TA-EU=",
                "CAB-TA-UK",
                "FAN-T3-R=",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "CAB-TA-JP-RA=",
                "STACK-T1-50CM-RF",
                "C3850-NM-4-1G-WS",
                "CAB-TA-TW",
                "CAB-SPWR-150CM=",
                "LL-C3850-48P-E=",
                "STACK-T1-1M",
                "CAB-AC-C15-KOR=",
                "C3850-NM-4-1G=",
                "WS-C3850-48P-E-WS",
                "CAB-TA-CN",
                "PWR-C3-750WDC-F/2",
                "STACK-T1-1M-WS",
                "C1AACAT38502K9=",
                "CAB-TA-250V-JP=",
                "CAB-AC-C15-TW",
                "C1FACAT38502K9=",
                "PWR-C3-750WDC-R/2",
                "CAB-TA-SW",
                "STACK-T1-3M-RF",
                "C1FUCAT38502K9",
                "C3850-4PT-KIT-RF",
                "C3850-RACK-KIT-RF",
                "C1-WS3850-48P/K9",
                "L-C3850-SLIC-U=",
                "C3850-ACC-KIT=",
                "PWR-C1-1100WAC-RF",
                "C1FACAT38502K9",
                "PWR-C1-1100WAC",
                "CAB-TA-IN=",
                "CAB-TA-JP=",
                "CAB-TA-250V-JP",
                "PWR-C1-715WAC-RF",
                "CAB-TA-DN=",
                "CAB-SPWR-30CM",
                "CAB-TA-JP",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "C1-C3850-48-DNAA-O",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "CAB-TA-AR",
                "C1-C3850-48-DNAE-O",
                "STACK-T1-1M=",
                "CAB-SPWR-30CM-RF",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "WS-C3850-48P-E",
                "PWR-C1-440WDC=",
                "CAB-TA-AP",
                "C3850-FAN-T1-RF",
                "CAB-TA-IN",
                "CAB-SPWR-150CM-RF",
                "PWR-C1-715WAC",
                "WS-C3850-48P",
                "CAB-SPWR-150CM",
                "C1FUCAT38502K9="
             ]
          },
          {  
             "mdfId":"284455428",
             "productName":"Cisco Catalyst 3850-48P-S Switch",
             "productId":[  
                "FAN-T3-F=",
                "C3850-NM-4-1G",
                "WS-C3850-48P-L-WS",
                "C3850-RTU=",
                "CAB-TA-CN=",
                "PWR-C1-440WDC",
                "WS-C3850-48W-S",
                "C1AUCAT38502K9=",
                "C3850-4PT-KIT=",
                "CAB-TA-AR=",
                "C3850-SPWR-NONE",
                "WS-C3850-48P-S-RL",
                "CAB-SPWR-150CM-WS",
                "C1AACAT38502K9",
                "PWR-C1-440WDC-RF",
                "WS-C3850-48P-S-RF",
                "C3850-NEBS-KIT=",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "PWR-C3-750WAC-R/2",
                "FAN-T3-F",
                "L-C3850-RTU=",
                "CAB-AC-C15-KOR",
                "PWR-C1-1100WAC=",
                "C3850-NM-4-1G-RF",
                "STACK-T1-50CM",
                "FAN-T3-R",
                "CAB-SPWR-30CM=",
                "CAB-C15-CBN-JP=",
                "CAB-AC-C15-TW=",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "C3850-FAN-T1-WS",
                "CAB-TA-UK=",
                "C3850-STACK-NONE",
                "CAB-TA-NA",
                "PWR-C1-1100WAC-WS",
                "EDU-C3850-48P-S",
                "C1AUCAT38502K9",
                "STACK-T1-3M=",
                "PWR-C3-750WAC-F/2",
                "FAN-T3-F-WS",
                "CAB-TA-SW=",
                "CAB-TA-EU",
                "CAB-TA-JP-RA",
                "CAB-C15-CBN-JP",
                "PWR-C1-715WAC-WS",
                "CAB-TA-EU=",
                "WS-C3850-48P-S-WS",
                "CAB-TA-UK",
                "FAN-T3-R=",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "CAB-TA-JP-RA=",
                "C3850-NM-4-1G-WS",
                "STACK-T1-50CM-RF",
                "CAB-TA-TW",
                "CAB-SPWR-150CM=",
                "STACK-T1-1M",
                "CAB-AC-C15-KOR=",
                "C3850-NM-4-1G=",
                "CAB-TA-CN",
                "PWR-C3-750WDC-F/2",
                "STACK-T1-1M-WS",
                "CAB-TA-250V-JP=",
                "C1AACAT38502K9=",
                "C1FACAT38502K9=",
                "CAB-AC-C15-TW",
                "WS-C3850-48P-S++",
                "PWR-C3-750WDC-R/2",
                "CAB-TA-SW",
                "STACK-T1-3M-RF",
                "UA-C3850-48P-S",
                "WS-C3850-48P-L-RF",
                "C1FUCAT38502K9",
                "C3850-4PT-KIT-RF",
                "C3850-RACK-KIT-RF",
                "C1-WS3850-48P/K9",
                "L-C3850-SLIC-U=",
                "C3850-ACC-KIT=",
                "PWR-C1-1100WAC-RF",
                "PWR-C1-1100WAC",
                "C1FACAT38502K9",
                "CAB-TA-IN=",
                "CAB-TA-JP=",
                "CAB-TA-250V-JP",
                "PWR-C1-715WAC-RF",
                "WS-C3850-48P-S",
                "CAB-SPWR-30CM",
                "CAB-TA-DN=",
                "WS-C3850-48P-L",
                "CAB-TA-JP",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "C1-C3850-48-DNAA-O",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "C1-C3850-48-DNAE-O",
                "CAB-TA-AR",
                "CAB-SPWR-30CM-RF",
                "STACK-T1-1M=",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "PWR-C1-440WDC=",
                "CAB-TA-AP",
                "C3850-FAN-T1-RF",
                "CAB-TA-IN",
                "CAB-SPWR-150CM-RF",
                "PWR-C1-715WAC",
                "WS-C3850-48P",
                "CAB-SPWR-150CM",
                "WS-C3850-48P-S-BR",
                "C1FUCAT38502K9="
             ]
          },
          {  
             "mdfId":"284455430",
             "productName":"Cisco Catalyst 3850-24U-S Switch",
             "productId":[  
                "FAN-T3-F=",
                "C1FACAT38501K9=",
                "C3850-NM-4-1G",
                "C3850-RTU=",
                "CAB-TA-CN=",
                "PWR-C1-440WDC",
                "CAB-TA-AR=",
                "C3850-4PT-KIT=",
                "C3850-SPWR-NONE",
                "CAB-SPWR-150CM-WS",
                "PWR-C1-440WDC-RF",
                "C3850-NEBS-KIT=",
                "C1-C3850-24-DNAE-O",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "PWR-C3-750WAC-R/2",
                "FAN-T3-F",
                "PWR-C1-1100WAC=",
                "CAB-AC-C15-KOR",
                "L-C3850-RTU=",
                "WS-C3850-24UW-S-RF",
                "C3850-NM-4-1G-RF",
                "C1FACAT38504K9",
                "STACK-T1-50CM",
                "C1AACAT38501K9=",
                "FAN-T3-R",
                "C1-C3850-24-DNAA-O",
                "CAB-SPWR-30CM=",
                "CAB-C15-CBN-JP=",
                "C1AACAT38501K9",
                "CAB-AC-C15-TW=",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "C3850-FAN-T1-WS",
                "CAB-TA-UK=",
                "C3850-STACK-NONE",
                "PWR-C1-1100WAC-WS",
                "CAB-TA-NA",
                "C1AACAT38504K9=",
                "PWR-C3-750WAC-F/2",
                "STACK-T1-3M=",
                "CAB-TA-SW=",
                "FAN-T3-F-WS",
                "CAB-TA-EU",
                "CAB-TA-JP-RA",
                "WS-C3850-24UW-S-WS",
                "CAB-C15-CBN-JP",
                "C1FACAT38504K9=",
                "PWR-C1-715WAC-WS",
                "C1AUCAT38501K9=",
                "CAB-TA-EU=",
                "WS-C3850-24U-S-RF",
                "CAB-TA-UK",
                "FAN-T3-R=",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "CAB-TA-JP-RA=",
                "STACK-T1-50CM-RF",
                "C3850-NM-4-1G-WS",
                "CAB-SPWR-150CM=",
                "CAB-TA-TW",
                "STACK-T1-1M",
                "C1-WS3850-24U/K9",
                "CAB-AC-C15-KOR=",
                "C1AACAT38504K9",
                "C3850-NM-4-1G=",
                "CAB-TA-CN",
                "C1FUCAT38501K9=",
                "PWR-C3-750WDC-F/2",
                "STACK-T1-1M-WS",
                "C1AUCAT38501K9",
                "CAB-TA-250V-JP=",
                "CAB-AC-C15-TW",
                "PWR-C3-750WDC-R/2",
                "CAB-TA-SW",
                "STACK-T1-3M-RF",
                "C3850-4PT-KIT-RF",
                "C3850-RACK-KIT-RF",
                "L-C3850-SLIC-U=",
                "C3850-ACC-KIT=",
                "PWR-C1-1100WAC-RF",
                "PWR-C1-1100WAC",
                "CAB-TA-IN=",
                "CAB-TA-JP=",
                "CAB-TA-250V-JP",
                "PWR-C1-715WAC-RF",
                "WS-C3850-24U-S-WS",
                "CAB-SPWR-30CM",
                "CAB-TA-DN=",
                "CAB-TA-JP",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "STACK-T1-50CM=",
                "UA-C3850-24U-S",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "CAB-TA-AR",
                "WS-C3850-24UW-S",
                "CAB-SPWR-30CM-RF",
                "STACK-T1-1M=",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "PWR-C1-440WDC=",
                "CAB-TA-AP",
                "C3850-FAN-T1-RF",
                "WS-C3850-24U",
                "CAB-TA-IN",
                "CAB-SPWR-150CM-RF",
                "PWR-C1-715WAC",
                "WS-C3850-24U-S",
                "C1FUCAT38501K9",
                "CAB-SPWR-150CM",
                "C1FACAT38501K9"
             ]
          },
          {  
             "mdfId":"284455425",
             "productName":"Cisco Catalyst 3850-24T-S Switch",
             "productId":[  
                "FAN-T3-F=",
                "C1FACAT38501K9=",
                "C3850-NM-4-1G",
                "CAB-TA-CN=",
                "C3850-RTU=",
                "PWR-C1-440WDC",
                "UA-C3850-24T-S",
                "CAB-TA-AR=",
                "C3850-4PT-KIT=",
                "C3850-SPWR-NONE",
                "CAB-SPWR-150CM-WS",
                "WS-C3850-24T-S++",
                "PWR-C1-440WDC-RF",
                "C3850-NEBS-KIT=",
                "C1-C3850-24-DNAE-O",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "C1-WS3850-24T/K9",
                "STACK-T1-50CM-WS",
                "PWR-C3-750WAC-R/2",
                "FAN-T3-F",
                "PWR-C1-1100WAC=",
                "L-C3850-RTU=",
                "CAB-AC-C15-KOR",
                "C3850-NM-4-1G-RF",
                "WS-C3850-24T-S-WS",
                "C1FACAT38504K9",
                "FAN-T3-R",
                "STACK-T1-50CM",
                "C1AACAT38501K9=",
                "CAB-SPWR-30CM=",
                "C1-C3850-24-DNAA-O",
                "CAB-C15-CBN-JP=",
                "CAB-AC-C15-TW=",
                "C1AACAT38501K9",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "C3850-FAN-T1-WS",
                "CAB-TA-UK=",
                "C3850-STACK-NONE",
                "PWR-C1-1100WAC-WS",
                "CAB-TA-NA",
                "C1AACAT38504K9=",
                "STACK-T1-3M=",
                "PWR-C3-750WAC-F/2",
                "FAN-T3-F-WS",
                "CAB-TA-SW=",
                "CAB-TA-EU",
                "CAB-TA-JP-RA",
                "CAB-C15-CBN-JP",
                "C1FACAT38504K9=",
                "PWR-C1-715WAC-WS",
                "CAB-TA-EU=",
                "C1AUCAT38501K9=",
                "FAN-T3-R=",
                "CAB-TA-UK",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "CAB-TA-JP-RA=",
                "STACK-T1-50CM-RF",
                "C3850-NM-4-1G-WS",
                "CAB-SPWR-150CM=",
                "C1-WS385024T/K9-RF",
                "CAB-TA-TW",
                "STACK-T1-1M",
                "CAB-AC-C15-KOR=",
                "C1AACAT38504K9",
                "C3850-NM-4-1G=",
                "CAB-TA-CN",
                "C1FUCAT38501K9=",
                "PWR-C3-750WDC-F/2",
                "STACK-T1-1M-WS",
                "CAB-TA-250V-JP=",
                "C1AUCAT38501K9",
                "CAB-AC-C15-TW",
                "PWR-C3-750WDC-R/2",
                "CAB-TA-SW",
                "STACK-T1-3M-RF",
                "WS-C3850-24T-S",
                "C3850-4PT-KIT-RF",
                "WS-C3850-24T-S-BR",
                "C3850-RACK-KIT-RF",
                "PWR-C1-1100WAC-RF",
                "C3850-ACC-KIT=",
                "L-C3850-SLIC-U=",
                "PWR-C1-1100WAC",
                "CAB-TA-IN=",
                "CAB-TA-JP=",
                "CAB-TA-250V-JP",
                "PWR-C1-715WAC-RF",
                "CAB-SPWR-30CM",
                "CAB-TA-DN=",
                "CAB-TA-JP",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "CAB-TA-AR",
                "WS-C3850-24T-S-RF",
                "STACK-T1-1M=",
                "CAB-SPWR-30CM-RF",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "CAB-TA-AP",
                "PWR-C1-440WDC=",
                "WS-C3850-24T-S-RL",
                "C3850-FAN-T1-RF",
                "CAB-TA-IN",
                "CAB-SPWR-150CM-RF",
                "PWR-C1-715WAC",
                "C1FUCAT38501K9",
                "WS-C3850-24T",
                "CAB-SPWR-150CM",
                "C1FACAT38501K9"
             ]
          },
          {  
             "mdfId":"284846035",
             "productName":"Cisco Catalyst 3650-48FS-S Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "NAL-C3650-48FWS-S",
                "WS-C3650-48FWD-S",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "C1FACAT36502K9",
                "PWR-C2-640WAC",
                "PWR-C2-640WDC-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WAC-RF",
                "NAL-C3650-48FS-S",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "C1-WS3650-48FS/K9",
                "4PT-KIT-T1-RF",
                "C3560-NEBS-KIT=",
                "C3650-RTU=",
                "PWR-C2-250WAC-RF",
                "C1AUCAT36502K9",
                "STACK-T2-3M",
                "WS-C3650-48FWSS-RF",
                "WS-C3650-48FS-S-WS",
                "WS-C3650-48FS-S",
                "C1-C3650-48-DNAA-O",
                "PWR-C2-640WDC",
                "C1-C3650-48-DNAE-O",
                "C1AACAT36502K9",
                "C3650-48FS-S++",
                "C1AUCAT36502K9=",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "C1FUCAT36502K9",
                "C1FUCAT36502K9=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "C1AACAT36502K9=",
                "PWR-C2-640WAC-WS",
                "RACK-KIT-T1-RF",
                "WS-C3650-48FWDS-RF",
                "PWR-C2-1025WAC",
                "C1FACAT36502K9=",
                "WS-C3650-48FS-S-RF",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "EDU-C3650-48FS-S",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "WS-C3650-48FWS-S",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "PWR-C2-250WAC-WS",
                "RACK-KIT-T1-WS",
                "WS-C3650-48FS",
                "PWR-C2-640WDC-WS",
                "RACK-KIT-T1=",
                "4PT-KIT-T1=",
                "ACC-KIT-T1=",
                "WSC365012X48URS-WS"
             ]
          },
          {  
             "mdfId":"284850365",
             "productName":"Cisco Catalyst 3650-48TD-L Switch",
             "productId":[  
                "WS-C3650-48TD",
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "WS-C3650-48FWD-S",
                "WS-C3650-48TD-L-WS",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "C1FACAT36502K9",
                "PWR-C2-640WAC",
                "PWR-C2-640WDC-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "C3650-48TD-L++",
                "WSC365012X48URS-RF",
                "4PT-KIT-T1-RF",
                "C3650-RTU=",
                "C3560-NEBS-KIT=",
                "PWR-C2-250WAC-RF",
                "C1AUCAT36502K9",
                "STACK-T2-3M",
                "WS-C3650-48TD-L-RF",
                "C1-C3650-48-DNAA-O",
                "PWR-C2-640WDC",
                "WS-C3650-48TD-L",
                "C1-C3650-48-DNAE-O",
                "NAL-C3650-48TD-L",
                "C1AACAT36502K9",
                "C1AUCAT36502K9=",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "C1FUCAT36502K9=",
                "C1FUCAT36502K9",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "C1AACAT36502K9=",
                "RACK-KIT-T1-RF",
                "WS-C3650-48FWDS-RF",
                "PWR-C2-1025WAC",
                "C1FACAT36502K9=",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "C1-WS3650-48TD/K9",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "RACK-KIT-T1-WS",
                "PWR-C2-250WAC-WS",
                "PWR-C2-640WDC-WS",
                "4PT-KIT-T1=",
                "WSC365012X48URS-WS",
                "ACC-KIT-T1=",
                "RACK-KIT-T1="
             ]
          },
          {  
             "mdfId":"284846001",
             "productName":"Cisco Catalyst 3650-24TS-L Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "C1FUCAT36501K9",
                "C1-WS3650-24TS/K9",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "WS-C3650-24TD-L-WS",
                "PWR-C2-640WDC-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "C3560-NEBS-KIT=",
                "C3650-RTU=",
                "4PT-KIT-T1-RF",
                "C1-C3650-24-DNAA-O",
                "PWR-C2-250WAC-RF",
                "NAL-C3650-24TS-L",
                "WS-C3650-24TS-L-RF",
                "STACK-T2-3M",
                "C1FUCAT36501K9=",
                "WS-C3650-24TD-L",
                "PWR-C2-640WDC",
                "WS-C3650-24TD-L-RF",
                "C1-C3650-24-DNAE-O",
                "C1AUCAT36501K9=",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "WS-C3650-24TS-L-WS",
                "WS-C3650-24TS",
                "C1FACAT36501K9",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "WS-C3650-24TS-L",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "C1AUCAT36501K9",
                "C1AACAT36501K9=",
                "C3650-24TS-L++",
                "C1FACAT36501K9=",
                "C1AACAT36501K9",
                "L-C3650-SLIC-U=",
                "PWR-C2-250WAC-WS",
                "RACK-KIT-T1-WS",
                "PWR-C2-640WDC-WS",
                "C1-WS3650-24XPD/K9",
                "WSC365012X48URS-WS",
                "RACK-KIT-T1=",
                "4PT-KIT-T1=",
                "ACC-KIT-T1="
             ]
          },
          {  
             "mdfId":"286257125",
             "productName":"Cisco Catalyst 3850-24S-S Switch",
             "productId":[  
                "FAN-T3-F=",
                "C3850-NM-4-1G",
                "C1FACAT38501K9=",
                "C3850-RTU=",
                "CAB-TA-CN=",
                "PWR-C1-440WDC",
                "CAB-TA-AR=",
                "C3850-4PT-KIT=",
                "C3850-SPWR-NONE",
                "CAB-SPWR-150CM-WS",
                "NAL-3850-24S-S",
                "PWR-C1-440WDC-RF",
                "C3850-NEBS-KIT=",
                "C1-C3850-24-DNAE-O",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "PWR-C3-750WAC-R/2",
                "FAN-T3-F",
                "CAB-AC-C15-KOR",
                "PWR-C1-1100WAC=",
                "L-C3850-RTU=",
                "C3850-NM-4-1G-RF",
                "C1FACAT38504K9",
                "C1AACAT38501K9=",
                "STACK-T1-50CM",
                "FAN-T3-R",
                "CAB-SPWR-30CM=",
                "C1-C3850-24-DNAA-O",
                "CAB-C15-CBN-JP=",
                "CAB-AC-C15-TW=",
                "C1AACAT38501K9",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "C3850-FAN-T1-WS",
                "CAB-TA-UK=",
                "C3850-STACK-NONE",
                "PWR-C1-1100WAC-WS",
                "CAB-TA-NA",
                "C1AACAT38504K9=",
                "PWR-C3-750WAC-F/2",
                "STACK-T1-3M=",
                "FAN-T3-F-WS",
                "CAB-TA-SW=",
                "CAB-TA-JP-RA",
                "CAB-TA-EU",
                "CAB-C15-CBN-JP",
                "C1FACAT38504K9=",
                "PWR-C1-715WAC-WS",
                "CAB-TA-EU=",
                "C1AUCAT38501K9=",
                "WS-C3850-24S-S",
                "WS-C3850-24S-S-WS",
                "CAB-TA-UK",
                "FAN-T3-R=",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "CAB-TA-JP-RA=",
                "C3850-NM-4-1G-WS",
                "STACK-T1-50CM-RF",
                "CAB-TA-TW",
                "CAB-SPWR-150CM=",
                "C3850-24S-S++",
                "STACK-T1-1M",
                "CAB-AC-C15-KOR=",
                "C1AACAT38504K9",
                "C3850-NM-4-1G=",
                "CAB-TA-CN",
                "PWR-C3-750WDC-F/2",
                "C1FUCAT38501K9=",
                "STACK-T1-1M-WS",
                "C1AUCAT38501K9",
                "CAB-TA-250V-JP=",
                "CAB-AC-C15-TW",
                "PWR-C3-750WDC-R/2",
                "CAB-TA-SW",
                "STACK-T1-3M-RF",
                "LL-C3850-24S-S=",
                "C3850-4PT-KIT-RF",
                "C3850-RACK-KIT-RF",
                "C3850-ACC-KIT=",
                "PWR-C1-1100WAC-RF",
                "L-C3850-SLIC-U=",
                "PWR-C1-1100WAC",
                "CAB-TA-IN=",
                "PWR-C1-715WAC-RF",
                "CAB-TA-250V-JP",
                "CAB-SPWR-30CM",
                "CAB-TA-DN=",
                "C1-WS3850-24S/K9",
                "WS-C3850-24S-S-RF",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "CAB-TA-AR",
                "STACK-T1-1M=",
                "CAB-SPWR-30CM-RF",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "CAB-TA-AP",
                "PWR-C1-440WDC=",
                "C3850-FAN-T1-RF",
                "CAB-TA-IN",
                "CAB-SPWR-150CM-RF",
                "PWR-C1-715WAC",
                "C1FUCAT38501K9",
                "WS-C3850-24S",
                "CAB-SPWR-150CM",
                "C1FACAT38501K9"
             ]
          },
          {  
             "mdfId":"284850738",
             "productName":"Cisco Catalyst 3650-48TQ-S Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "WS-C3650-48FWD-S",
                "WS-C3650-48TQ",
                "WS-C3650-48TQ-S-WS",
                "PWR-C2-640WAC=",
                "C1FACAT36502K9",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WDC-RF",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "C3560-NEBS-KIT=",
                "C3650-RTU=",
                "4PT-KIT-T1-RF",
                "PWR-C2-250WAC-RF",
                "C1AUCAT36502K9",
                "STACK-T2-3M",
                "LL-C3650-48TQ-S=",
                "S6650-48TQ-S",
                "C1-C3650-48-DNAA-O",
                "PWR-C2-640WDC",
                "C3650-48TQ-S++",
                "C1-C3650-48-DNAE-O",
                "C1AACAT36502K9",
                "C1AUCAT36502K9=",
                "NAL-C3650-48TQ-S",
                "PWR-C2-250WAC",
                "WS-C3650-48TQ-S",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "C1FUCAT36502K9",
                "C1FUCAT36502K9=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "C1AACAT36502K9=",
                "WS-C3650-48FWDS-RF",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "WS-C3650-48TQ-S-RF",
                "C1FACAT36502K9=",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "C1-WS3650-48TQ/K9",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "RACK-KIT-T1-WS",
                "PWR-C2-250WAC-WS",
                "PWR-C2-640WDC-WS",
                "WSC365012X48URS-WS",
                "ACC-KIT-T1=",
                "4PT-KIT-T1=",
                "RACK-KIT-T1="
             ]
          },
          {  
             "mdfId":"286280419",
             "productName":"Cisco Catalyst 3850-48XS-F-E Switch",
             "productId":[  
                "STACK-T1-50CM-RF",
                "FAN-T3-F=",
                "CAB-TA-TW",
                "C3850-RTU=",
                "CAB-TA-CN=",
                "STACK-T1-1M",
                "PWR-C1-440WDC",
                "CAB-AC-C15-KOR=",
                "C1AUCAT38502K9=",
                "CAB-TA-AR=",
                "C3850-4PT-KIT=",
                "C3850-SPWR-NONE",
                "C1AACAT38502K9",
                "WS-C3850-48XS",
                "PWR-C1-440WDC-RF",
                "PWR-C3-750WAC-F",
                "CAB-TA-CN",
                "STACK-T1-1M-WS",
                "C3850-NEBS-KIT=",
                "CAB-TA-250V-JP=",
                "C1AACAT38502K9=",
                "C1FACAT38502K9=",
                "CAB-AC-C15-TW",
                "CAB-TA-SW",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "STACK-T1-3M-RF",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "C1FUCAT38502K9",
                "C3850-48XS-F-E++",
                "FAN-T3-F",
                "C3850-4PT-KIT-RF",
                "PWR-C3-750WAC-F-RF",
                "L-C3850-RTU=",
                "PWR-C1-1100WAC=",
                "CAB-AC-C15-KOR",
                "C3850-RACK-KIT-RF",
                "C3850-ACC-KIT=",
                "L-C3850-SLIC-U=",
                "PWR-C1-1100WAC-RF",
                "FAN-T3-R",
                "STACK-T1-50CM",
                "PWR-C1-1100WAC",
                "C1FACAT38502K9",
                "CAB-TA-IN=",
                "CAB-TA-250V-JP",
                "PWR-C1-715WAC-RF",
                "CAB-C15-CBN-JP=",
                "CAB-AC-C15-TW=",
                "CAB-TA-DN=",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "C3850-FAN-T1-WS",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "CAB-TA-UK=",
                "CAB-TA-AR",
                "C3850-STACK-NONE",
                "PWR-C1-1100WAC-WS",
                "CAB-TA-NA",
                "STACK-T1-1M=",
                "PWR-C3-750WAC-F=",
                "C1AUCAT38502K9",
                "CAB-TA-IS",
                "STACK-T1-3M=",
                "CAB-TA-IT",
                "PWR-C3-750WAC-F-WS",
                "PWR-C1-715WAC=",
                "FAN-T3-F-WS",
                "CAB-TA-SW=",
                "CAB-TA-AP",
                "PWR-C1-440WDC=",
                "CAB-TA-JP-RA",
                "CAB-TA-EU",
                "CAB-C15-CBN-JP",
                "C3850-FAN-T1-RF",
                "WS-C3850-48XS-F-E",
                "CAB-TA-IN",
                "PWR-C1-715WAC",
                "PWR-C1-715WAC-WS",
                "CAB-TA-EU=",
                "CAB-TA-UK",
                "FAN-T3-R=",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "C1FUCAT38502K9=",
                "CAB-TA-JP-RA="
             ]
          },
          {  
             "mdfId":"284850739",
             "productName":"Cisco Catalyst 3650-48FQ-E Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "NAL-C3650-48FQ-E",
                "WS-C3650-48FWD-S",
                "WS-C3650-48FQ-E-HC",
                "PWR-C2-640WAC=",
                "C1FACAT36502K9",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "PWR-C2-640WDC-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "C3560-NEBS-KIT=",
                "4PT-KIT-T1-RF",
                "C3650-RTU=",
                "C1WS365048FQ/K9-RF",
                "PWR-C2-250WAC-RF",
                "C1AUCAT36502K9",
                "STACK-T2-3M",
                "WS-C3650-48FQ-HC",
                "C1-C3650-48-DNAA-O",
                "PWR-C2-640WDC",
                "C1-C3650-48-DNAE-O",
                "WS-C3650-48FQ-E-RF",
                "C1AACAT36502K9",
                "WS-C3650-48FQ-E",
                "C1AUCAT36502K9=",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "C1FUCAT36502K9",
                "C1FUCAT36502K9=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "C1AACAT36502K9=",
                "WS-C3650-48FWDS-RF",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "C1FACAT36502K9=",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "C1-WS3650-48FQ/K9",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "WS-C3650-48FQ-E-WS",
                "C3650-48FQ-E++",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "RACK-KIT-T1-WS",
                "PWR-C2-250WAC-WS",
                "PWR-C2-640WDC-WS",
                "WSC365012X48URS-WS",
                "RACK-KIT-T1=",
                "4PT-KIT-T1=",
                "ACC-KIT-T1="
             ]
          },
          {  
             "mdfId":"284850740",
             "productName":"Cisco Catalyst 3650-48PQ-E Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "C3650-48PQ-E++",
                "WS-C3650-48FWD-S",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "C1FACAT36502K9",
                "PWR-C2-640WAC",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WDC-RF",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "4PT-KIT-T1-RF",
                "C3560-NEBS-KIT=",
                "C3650-RTU=",
                "PWR-C2-250WAC-RF",
                "C1AUCAT36502K9",
                "STACK-T2-3M",
                "C1-WS3650-48PQ/K9",
                "WS-C3650-48PQM-S",
                "WS-C3650-48PQ",
                "C1-C3650-48-DNAA-O",
                "PWR-C2-640WDC",
                "C1-C3650-48-DNAE-O",
                "C1AACAT36502K9",
                "WS-C3650-48PQM-L",
                "WS-C3650-48PQM-E",
                "C1AUCAT36502K9=",
                "PWR-C2-250WAC",
                "WS-C3650-48PQ-E",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "C1FUCAT36502K9=",
                "C1FUCAT36502K9",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "C1AACAT36502K9=",
                "WS-C3650-48PQ-E-RF",
                "RACK-KIT-T1-RF",
                "WS-C3650-48FWDS-RF",
                "PWR-C2-1025WAC",
                "C1FACAT36502K9=",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "WS-C3650-48PQ-HC",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "WS-C3650-48PQ-E-WS",
                "L-C3650-SLIC-U=",
                "NAL-C3650-48PQ-E",
                "C1-WS3650-48XFD/K9",
                "PWR-C2-250WAC-WS",
                "RACK-KIT-T1-WS",
                "PWR-C2-640WDC-WS",
                "WSC365012X48URS-WS",
                "RACK-KIT-T1=",
                "4PT-KIT-T1=",
                "ACC-KIT-T1="
             ]
          },
          {  
             "mdfId":"286289959",
             "productName":"Cisco Catalyst 3650-8X24UQ-L Switch",
             "productId":[  
                "WS-C3650-8X24UQ-L",
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC",
                "PWR-C2-250WAC=",
                "WSC36508X24UQL-WS",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "C3650-8X24UQ-L++",
                "RACK-KIT-T1-RF",
                "PWR-C2-640WDC-RF",
                "PWR-C2-1025WAC",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WAC-RF",
                "WSC36508X24UQ-L-RF",
                "C3650-NEBS-KIT=",
                "STACK-T2-3M=",
                "WSC365012X48URS-RF",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "C3560-NEBS-KIT=",
                "PWR-C2-BLANK=",
                "PWR-C2-250WAC-RF",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "STACK-T2-3M",
                "NAL-C3650-8X24UQ-L",
                "PWR-C2-640WDC",
                "L-C3650-SLIC-U=",
                "RACK-KIT-T1-WS",
                "PWR-C2-250WAC-WS",
                "PWR-C2-640WDC-WS",
                "WSC365012X48URS-WS",
                "RACK-KIT-T1=",
                "C1-WS3650-24XPD/K9"
             ]
          },
          {  
             "mdfId":"286309910",
             "productName":"Cisco Catalyst 3650-12X48FD-L Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "STACK-T2-3M",
                "WS-C3650-48FWD-S",
                "WS-C3650-12X48UR-S",
                "WS-C3650-48FWDS-RF",
                "RACK-KIT-T1-RF",
                "STACK-T2-3M=",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "NAL-C3650-12X48FDL",
                "C1-WS3650-48XFD/K9",
                "STACK-T2-3M-WS",
                "C3560-NEBS-KIT=",
                "WSC365012X48URS-WS",
                "RACK-KIT-T1="
             ]
          },
          {  
             "mdfId":"286289909",
             "productName":"Cisco Catalyst 3650-12X48UR-S Switch",
             "productId":[  
                "PWR-C2-250WAC",
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-48FWD-S",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "NAL-C3650-12X48URS",
                "RACK-KIT-T1-RF",
                "PWR-C2-640WAC",
                "WS-C3650-48FWDS-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WDC-RF",
                "PWR-C2-1025WAC",
                "PWR-C2-640WAC-RF",
                "STACK-T2-3M=",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "C3560-NEBS-KIT=",
                "PWR-C2-BLANK=",
                "PWR-C2-250WAC-RF",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "STACK-T2-3M",
                "PWR-C2-640WDC",
                "C3650-12X48UR-S++",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "RACK-KIT-T1-WS",
                "PWR-C2-250WAC-WS",
                "PWR-C2-640WDC-WS",
                "RACK-KIT-T1=",
                "WSC365012X48URS-WS"
             ]
          },
          {  
             "mdfId":"284850385",
             "productName":"Cisco Catalyst 3650-24PD-L Switch",
             "productId":[  
                "WS-C3650-24PD-HC",
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "C1FUCAT36501K9",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "PWR-C2-640WDC-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "EDU-C3650-24PD-L",
                "4PT-KIT-T1-RF",
                "C3560-NEBS-KIT=",
                "C3650-RTU=",
                "WS-C3650-24PD-L-RF",
                "C1-C3650-24-DNAA-O",
                "PWR-C2-250WAC-RF",
                "STACK-T2-3M",
                "WS-C3650-24PD-L-WS",
                "C1FUCAT36501K9=",
                "WS-C3650-24PD-L",
                "PWR-C2-640WDC",
                "C1-WS3650-24PD/K9",
                "C3650-24PD-L++",
                "C1-C3650-24-DNAE-O",
                "C1AUCAT36501K9=",
                "WS-C3650-24PD",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "C1FACAT36501K9",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "STACK-T2-3M=",
                "NAL-C3650-24PD-L",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "C1AUCAT36501K9",
                "C1AACAT36501K9=",
                "C1FACAT36501K9=",
                "C1AACAT36501K9",
                "L-C3650-SLIC-U=",
                "PWR-C2-250WAC-WS",
                "RACK-KIT-T1-WS",
                "PWR-C2-640WDC-WS",
                "ACC-KIT-T1=",
                "4PT-KIT-T1=",
                "WSC365012X48URS-WS",
                "RACK-KIT-T1=",
                "C1-WS3650-24XPD/K9"
             ]
          },
          {  
             "mdfId":"286280288",
             "productName":"Cisco Catalyst 3850-24XS-E Switch",
             "productId":[  
                "FAN-T3-F=",
                "C1FACAT38501K9=",
                "CAB-TA-CN=",
                "C3850-RTU=",
                "PWR-C1-440WDC",
                "CAB-TA-AR=",
                "C3850-4PT-KIT=",
                "C3850-SPWR-NONE",
                "PWR-C1-440WDC-RF",
                "C1-WSC3850-24XS-S",
                "C3850-NEBS-KIT=",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "WS-C3850-24XS-E-WS",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "FAN-T3-F",
                "PWR-C1-1100WAC=",
                "CAB-AC-C15-KOR",
                "L-C3850-RTU=",
                "C1FACAT38504K9",
                "STACK-T1-50CM",
                "C1AACAT38501K9=",
                "FAN-T3-R",
                "CAB-C15-CBN-JP=",
                "C1AACAT38501K9",
                "CAB-AC-C15-TW=",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "C3850-FAN-T1-WS",
                "CAB-TA-UK=",
                "C3850-STACK-NONE",
                "PWR-C1-1100WAC-WS",
                "CAB-TA-NA",
                "C1AACAT38504K9=",
                "STACK-T1-3M=",
                "FAN-T3-F-WS",
                "CAB-TA-SW=",
                "CAB-TA-JP-RA",
                "CAB-TA-EU",
                "CAB-C15-CBN-JP",
                "C1FACAT38504K9=",
                "PWR-C1-715WAC-WS",
                "C1AUCAT38501K9=",
                "CAB-TA-EU=",
                "FAN-T3-R=",
                "CAB-TA-UK",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "CAB-TA-JP-RA=",
                "STACK-T1-50CM-RF",
                "CAB-TA-TW",
                "STACK-T1-1M",
                "CAB-AC-C15-KOR=",
                "WS-C3850-24XS-E-RF",
                "C1AACAT38504K9",
                "CAB-TA-CN",
                "C1FUCAT38501K9=",
                "STACK-T1-1M-WS",
                "C1AUCAT38501K9",
                "CAB-TA-250V-JP=",
                "CAB-AC-C15-TW",
                "LL-C3850-24XS-E=",
                "CAB-TA-SW",
                "STACK-T1-3M-RF",
                "C3850-4PT-KIT-RF",
                "C3850-RACK-KIT-RF",
                "PWR-C1-1100WAC-RF",
                "L-C3850-SLIC-U=",
                "C3850-ACC-KIT=",
                "PWR-C1-1100WAC",
                "CAB-TA-IN=",
                "CAB-TA-250V-JP",
                "PWR-C1-715WAC-RF",
                "CAB-TA-DN=",
                "WS-C3850-24XS-E",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "WS-C3850-24XS",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "CAB-TA-AR",
                "STACK-T1-1M=",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "PWR-C1-440WDC=",
                "CAB-TA-AP",
                "C3850-FAN-T1-RF",
                "CAB-TA-IN",
                "C3850-24XS-E++",
                "PWR-C1-715WAC",
                "C1FUCAT38501K9",
                "C1-WSC385024XSS-RF",
                "C1FACAT38501K9"
             ]
          },
          {  
             "mdfId":"286289919",
             "productName":"Cisco Catalyst 3650-12X48UR-E Switch",
             "productId":[  
                "PWR-C2-250WAC",
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "NAL-C3650-12X48URE",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "WS-C3650-48FWD-S",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "WS-C3650-48FWDS-RF",
                "RACK-KIT-T1-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-1025WAC",
                "PWR-C2-640WDC-RF",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "STACK-T2-3M=",
                "WSC365012X48URS-RF",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "C3560-NEBS-KIT=",
                "WSC365012X48URE-RF",
                "PWR-C2-BLANK=",
                "PWR-C2-250WAC-RF",
                "C3650-12X48UR-E++",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "STACK-T2-3M",
                "WSC365012X48URE-WS",
                "PWR-C2-640WDC",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "RACK-KIT-T1-WS",
                "PWR-C2-250WAC-WS",
                "PWR-C2-640WDC-WS",
                "WS-C3650-12X48UR-E",
                "WSC365012X48URS-WS",
                "RACK-KIT-T1="
             ]
          },
          {  
             "mdfId":"284850675",
             "productName":"Cisco Catalyst 3650-48TQ-L Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "WS-C3650-48FWD-S",
                "WS-C3650-48TQ",
                "PWR-C2-640WAC=",
                "C1FACAT36502K9",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "PWR-C2-640WDC-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "C3650-RTU=",
                "C3560-NEBS-KIT=",
                "4PT-KIT-T1-RF",
                "PWR-C2-250WAC-RF",
                "C1AUCAT36502K9",
                "STACK-T2-3M",
                "WS-C3650-48TQ-L-WS",
                "C1-C3650-48-DNAA-O",
                "PWR-C2-640WDC",
                "C1-C3650-48-DNAE-O",
                "C1AACAT36502K9",
                "C1AUCAT36502K9=",
                "WS-C3650-48TQ-L-RF",
                "PWR-C2-250WAC",
                "NAL-C3650-48TQ-L",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "C1FUCAT36502K9",
                "C1FUCAT36502K9=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "C1AACAT36502K9=",
                "PWR-C2-640WAC-WS",
                "WS-C3650-48FWDS-RF",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "C1FACAT36502K9=",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "C3650-48TQ-L++",
                "C1-WS3650-48TQ/K9",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "RACK-KIT-T1-WS",
                "PWR-C2-250WAC-WS",
                "PWR-C2-640WDC-WS",
                "WS-C3650-48TQ-L",
                "ACC-KIT-T1=",
                "WSC365012X48URS-WS",
                "4PT-KIT-T1=",
                "RACK-KIT-T1="
             ]
          },
          {  
             "mdfId":"286289828",
             "productName":"Cisco Catalyst 3650-12X48UQ-E Switch",
             "productId":[  
                "PWR-C2-250WAC",
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-48FWD-S",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "WSC365012X48UQE-RF",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "RACK-KIT-T1-RF",
                "WS-C3650-48FWDS-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WDC-RF",
                "PWR-C2-1025WAC",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "STACK-T2-3M=",
                "WSC365012X48URS-RF",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "C3650-12X48UQ-E++",
                "C3560-NEBS-KIT=",
                "PWR-C2-BLANK=",
                "PWR-C2-250WAC-RF",
                "PWR-C2-1025WAC-WS",
                "NAL-C3650-12X48UQE",
                "PWR-C2-1025WAC=",
                "STACK-T2-3M",
                "PWR-C2-640WDC",
                "L-C3650-SLIC-U=",
                "WSC365012X48UQE-WS",
                "C1-WS3650-48XFD/K9",
                "RACK-KIT-T1-WS",
                "PWR-C2-250WAC-WS",
                "PWR-C2-640WDC-WS",
                "WSC365012X48URS-WS",
                "RACK-KIT-T1=",
                "WS-C3650-12X48UQ-E"
             ]
          },
          {  
             "mdfId":"284455424",
             "productName":"Cisco Catalyst 3850-48U-L Switch",
             "productId":[  
                "FAN-T3-F=",
                "C3850-NM-4-1G",
                "CAB-TA-CN=",
                "C3850-RTU=",
                "PWR-C1-440WDC",
                "WS-C3850-12X48U-L",
                "CAB-TA-AR=",
                "C3850-4PT-KIT=",
                "C1AUCAT38502K9=",
                "C3850-SPWR-NONE",
                "WS-C3850E-12X48U-L",
                "C1AACAT38502K9",
                "CAB-SPWR-150CM-WS",
                "PWR-C1-440WDC-RF",
                "C3850-NEBS-KIT=",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "PWR-C3-750WAC-R/2",
                "EDU-C3850-48U-L-RF",
                "FAN-T3-F",
                "L-C3850-RTU=",
                "PWR-C1-1100WAC=",
                "CAB-AC-C15-KOR",
                "C3850-NM-4-1G-RF",
                "FAN-T3-R",
                "STACK-T1-50CM",
                "CAB-SPWR-30CM=",
                "CAB-C15-CBN-JP=",
                "CAB-AC-C15-TW=",
                "CAB-TA-IT=",
                "EDU-C3850-48U-L-WS",
                "STACK-T1-1M-RF",
                "C3850-FAN-T1-WS",
                "CAB-TA-UK=",
                "C3850-STACK-NONE",
                "PWR-C1-1100WAC-WS",
                "CAB-TA-NA",
                "WS-C385012X48UL-WS",
                "C1AUCAT38502K9",
                "STACK-T1-3M=",
                "PWR-C3-750WAC-F/2",
                "FAN-T3-F-WS",
                "CAB-TA-SW=",
                "CAB-TA-EU",
                "CAB-TA-JP-RA",
                "CAB-C15-CBN-JP",
                "C1-WS3850-48U/K9",
                "PWR-C1-715WAC-WS",
                "CAB-TA-EU=",
                "FAN-T3-R=",
                "CAB-TA-UK",
                "WS-C3850-12X48U",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "CAB-TA-JP-RA=",
                "STACK-T1-50CM-RF",
                "C3850-NM-4-1G-WS",
                "WS-C3850-48U-L",
                "CAB-SPWR-150CM=",
                "CAB-TA-TW",
                "STACK-T1-1M",
                "EDU-C3850-48U-L",
                "CAB-AC-C15-KOR=",
                "C3850-NM-4-1G=",
                "WS-C3850-48U-L-WS",
                "CAB-TA-CN",
                "PWR-C3-750WDC-F/2",
                "STACK-T1-1M-WS",
                "C1AACAT38502K9=",
                "CAB-TA-250V-JP=",
                "CAB-AC-C15-TW",
                "C1FACAT38502K9=",
                "PWR-C3-750WDC-R/2",
                "CAB-TA-SW",
                "STACK-T1-3M-RF",
                "WS-C3850-48U-L++",
                "C1FUCAT38502K9",
                "C3850-4PT-KIT-RF",
                "C3850-RACK-KIT-RF",
                "C3850-ACC-KIT=",
                "L-C3850-SLIC-U=",
                "PWR-C1-1100WAC-RF",
                "C1FACAT38502K9",
                "PWR-C1-1100WAC",
                "CAB-TA-IN=",
                "CAB-TA-JP=",
                "PWR-C1-715WAC-RF",
                "CAB-TA-250V-JP",
                "CAB-TA-DN=",
                "CAB-SPWR-30CM",
                "CAB-TA-JP",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "C1-C3850-48-DNAA-O",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "CAB-TA-AR",
                "C1-C3850-48-DNAE-O",
                "CAB-SPWR-30CM-RF",
                "STACK-T1-1M=",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "PWR-C1-440WDC=",
                "CAB-TA-AP",
                "C3850-FAN-T1-RF",
                "CAB-TA-IN",
                "CAB-SPWR-150CM-RF",
                "PWR-C1-715WAC",
                "WS-C3850-48U",
                "WS-C3850-48U-L-RF",
                "WS-C385012X48UL-RF",
                "CAB-SPWR-150CM",
                "C1FUCAT38502K9="
             ]
          },
          {  
             "mdfId":"286280338",
             "productName":"Cisco Catalyst 3850-48XS-F-S Switch",
             "productId":[  
                "FAN-T3-F=",
                "CAB-TA-CN=",
                "C3850-RTU=",
                "PWR-C1-440WDC",
                "WS-C3850-48W-S",
                "C1AUCAT38502K9=",
                "CAB-TA-AR=",
                "C3850-4PT-KIT=",
                "C3850-SPWR-NONE",
                "C1AACAT38502K9",
                "PWR-C1-440WDC-RF",
                "PWR-C3-750WAC-F",
                "C3850-48XS-F-S++",
                "C3850-NEBS-KIT=",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "FAN-T3-F",
                "CAB-AC-C15-KOR",
                "L-C3850-RTU=",
                "PWR-C1-1100WAC=",
                "STACK-T1-50CM",
                "FAN-T3-R",
                "CAB-C15-CBN-JP=",
                "CAB-AC-C15-TW=",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "C3850-FAN-T1-WS",
                "CAB-TA-UK=",
                "C3850-STACK-NONE",
                "CAB-TA-NA",
                "PWR-C1-1100WAC-WS",
                "C1AUCAT38502K9",
                "STACK-T1-3M=",
                "PWR-C3-750WAC-F-WS",
                "FAN-T3-F-WS",
                "CAB-TA-SW=",
                "CAB-TA-JP-RA",
                "CAB-TA-EU",
                "CAB-C15-CBN-JP",
                "PWR-C1-715WAC-WS",
                "CAB-TA-EU=",
                "CAB-TA-UK",
                "FAN-T3-R=",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "CAB-TA-JP-RA=",
                "STACK-T1-50CM-RF",
                "CAB-TA-TW",
                "STACK-T1-1M",
                "CAB-AC-C15-KOR=",
                "WS-C3850-48XS",
                "CAB-TA-CN",
                "STACK-T1-1M-WS",
                "CAB-TA-250V-JP=",
                "C1AACAT38502K9=",
                "C1FACAT38502K9=",
                "CAB-AC-C15-TW",
                "CAB-TA-SW",
                "STACK-T1-3M-RF",
                "C1FUCAT38502K9",
                "C3850-4PT-KIT-RF",
                "PWR-C3-750WAC-F-RF",
                "C3850-RACK-KIT-RF",
                "PWR-C1-1100WAC-RF",
                "L-C3850-SLIC-U=",
                "C3850-ACC-KIT=",
                "C1FACAT38502K9",
                "PWR-C1-1100WAC",
                "CAB-TA-IN=",
                "PWR-C1-715WAC-RF",
                "CAB-TA-250V-JP",
                "C1-WSC3850-48XS-FS",
                "S6850-48XS-F-S",
                "CAB-TA-DN=",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "CAB-TA-AR",
                "PWR-C3-750WAC-F=",
                "STACK-T1-1M=",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "CAB-TA-AP",
                "PWR-C1-440WDC=",
                "C3850-FAN-T1-RF",
                "CAB-TA-IN",
                "PWR-C1-715WAC",
                "WS-C3850-48XS-F-S",
                "C1FUCAT38502K9="
             ]
          },
          {  
             "mdfId":"284455426",
             "productName":"Cisco Catalyst 3850-48T-S Switch",
             "productId":[  
                "FAN-T3-F=",
                "C3850-NM-4-1G",
                "C3850-RTU=",
                "CAB-TA-CN=",
                "PWR-C1-440WDC",
                "C1AUCAT38502K9=",
                "WS-C3850-48W-S",
                "CAB-TA-AR=",
                "C3850-4PT-KIT=",
                "C3850-SPWR-NONE",
                "CAB-SPWR-150CM-WS",
                "C1AACAT38502K9",
                "PWR-C1-440WDC-RF",
                "C3850-NEBS-KIT=",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "PWR-C3-750WAC-R/2",
                "FAN-T3-F",
                "L-C3850-RTU=",
                "PWR-C1-1100WAC=",
                "CAB-AC-C15-KOR",
                "C3850-NM-4-1G-RF",
                "FAN-T3-R",
                "STACK-T1-50CM",
                "CAB-SPWR-30CM=",
                "CAB-C15-CBN-JP=",
                "CAB-AC-C15-TW=",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "C3850-FAN-T1-WS",
                "CAB-TA-UK=",
                "C3850-STACK-NONE",
                "CAB-TA-NA",
                "PWR-C1-1100WAC-WS",
                "WS-C3850-48T-S-BR",
                "C1AUCAT38502K9",
                "PWR-C3-750WAC-F/2",
                "STACK-T1-3M=",
                "CAB-TA-SW=",
                "FAN-T3-F-WS",
                "CAB-TA-EU",
                "CAB-TA-JP-RA",
                "CAB-C15-CBN-JP",
                "PWR-C1-715WAC-WS",
                "CAB-TA-EU=",
                "FAN-T3-R=",
                "WS-C3850-48T-S++",
                "CAB-TA-UK",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "CAB-TA-JP-RA=",
                "STACK-T1-50CM-RF",
                "C3850-NM-4-1G-WS",
                "CAB-SPWR-150CM=",
                "CAB-TA-TW",
                "STACK-T1-1M",
                "CAB-AC-C15-KOR=",
                "C3850-NM-4-1G=",
                "CAB-TA-CN",
                "PWR-C3-750WDC-F/2",
                "STACK-T1-1M-WS",
                "CAB-TA-250V-JP=",
                "C1AACAT38502K9=",
                "C1FACAT38502K9=",
                "CAB-AC-C15-TW",
                "PWR-C3-750WDC-R/2",
                "CAB-TA-SW",
                "STACK-T1-3M-RF",
                "UA-C3850-48T-S",
                "C1FUCAT38502K9",
                "C3850-4PT-KIT-RF",
                "WS-C3850-48T-S-RF",
                "C3850-RACK-KIT-RF",
                "L-C3850-SLIC-U=",
                "PWR-C1-1100WAC-RF",
                "C3850-ACC-KIT=",
                "WS-C3850-48T-S-RL",
                "C1FACAT38502K9",
                "PWR-C1-1100WAC",
                "CAB-TA-IN=",
                "CAB-TA-JP=",
                "PWR-C1-715WAC-RF",
                "CAB-TA-250V-JP",
                "CAB-TA-DN=",
                "CAB-SPWR-30CM",
                "C1-WS3850-48T/K9",
                "CAB-TA-JP",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "C1-C3850-48-DNAA-O",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "WS-C3850-48T-S",
                "CAB-TA-AR",
                "C1-C3850-48-DNAE-O",
                "STACK-T1-1M=",
                "CAB-SPWR-30CM-RF",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "CAB-TA-AP",
                "PWR-C1-440WDC=",
                "WS-C3850-48T-S-WS",
                "C3850-FAN-T1-RF",
                "CAB-TA-IN",
                "CAB-SPWR-150CM-RF",
                "PWR-C1-715WAC",
                "WS-C3850-48T",
                "CAB-SPWR-150CM",
                "C1FUCAT38502K9="
             ]
          },
          {  
             "mdfId":"284455434",
             "productName":"Cisco Catalyst 3850-48T-E Switch",
             "productId":[  
                "FAN-T3-F=",
                "C3850-NM-4-1G",
                "C3850-RTU=",
                "CAB-TA-CN=",
                "PWR-C1-440WDC",
                "C1AUCAT38502K9=",
                "CAB-TA-AR=",
                "C3850-4PT-KIT=",
                "C3850-SPWR-NONE",
                "C1AACAT38502K9",
                "CAB-SPWR-150CM-WS",
                "WS-C3850-48T-E-WS",
                "PWR-C1-440WDC-RF",
                "C3850-NEBS-KIT=",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "PWR-C3-750WAC-R/2",
                "FAN-T3-F",
                "L-C3850-RTU=",
                "PWR-C1-1100WAC=",
                "CAB-AC-C15-KOR",
                "C3850-NM-4-1G-RF",
                "STACK-T1-50CM",
                "FAN-T3-R",
                "CAB-SPWR-30CM=",
                "CAB-C15-CBN-JP=",
                "CAB-AC-C15-TW=",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "C3850-FAN-T1-WS",
                "CAB-TA-UK=",
                "C3850-STACK-NONE",
                "PWR-C1-1100WAC-WS",
                "CAB-TA-NA",
                "C1AUCAT38502K9",
                "STACK-T1-3M=",
                "PWR-C3-750WAC-F/2",
                "FAN-T3-F-WS",
                "CAB-TA-SW=",
                "CAB-TA-EU",
                "UA-C3850-48T-E",
                "CAB-TA-JP-RA",
                "CAB-C15-CBN-JP",
                "PWR-C1-715WAC-WS",
                "CAB-TA-EU=",
                "FAN-T3-R=",
                "CAB-TA-UK",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "CAB-TA-JP-RA=",
                "STACK-T1-50CM-RF",
                "C3850-NM-4-1G-WS",
                "CAB-SPWR-150CM=",
                "CAB-TA-TW",
                "STACK-T1-1M",
                "CAB-AC-C15-KOR=",
                "C3850-NM-4-1G=",
                "WS-C3850-48T-E-BR",
                "CAB-TA-CN",
                "PWR-C3-750WDC-F/2",
                "STACK-T1-1M-WS",
                "CAB-TA-250V-JP=",
                "C1AACAT38502K9=",
                "CAB-AC-C15-TW",
                "C1FACAT38502K9=",
                "PWR-C3-750WDC-R/2",
                "CAB-TA-SW",
                "STACK-T1-3M-RF",
                "C1FUCAT38502K9",
                "C3850-4PT-KIT-RF",
                "C3850-RACK-KIT-RF",
                "L-C3850-SLIC-U=",
                "PWR-C1-1100WAC-RF",
                "C3850-ACC-KIT=",
                "C1FACAT38502K9",
                "PWR-C1-1100WAC",
                "CAB-TA-IN=",
                "CAB-TA-JP=",
                "CAB-TA-250V-JP",
                "PWR-C1-715WAC-RF",
                "CAB-TA-DN=",
                "C1-WS3850-48T/K9",
                "CAB-SPWR-30CM",
                "CAB-TA-JP",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "C1-C3850-48-DNAA-O",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "C1-C3850-48-DNAE-O",
                "CAB-TA-AR",
                "STACK-T1-1M=",
                "CAB-SPWR-30CM-RF",
                "WS-C3850-48T-E",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "CAB-TA-AP",
                "PWR-C1-440WDC=",
                "C3850-FAN-T1-RF",
                "WS-C3850-48T-E++",
                "CAB-TA-IN",
                "CAB-SPWR-150CM-RF",
                "PWR-C1-715WAC",
                "WS-C3850-48T",
                "WS-C3850-48T-E-RL",
                "CAB-SPWR-150CM",
                "C1FUCAT38502K9=",
                "WS-C3850-48T-E-RF"
             ]
          },
          {  
             "mdfId":"284455429",
             "productName":"Cisco Catalyst 3850-48F-S Switch",
             "productId":[  
                "FAN-T3-F=",
                "C3850-NM-4-1G",
                "C1-WS385048F/K9-RF",
                "CAB-TA-CN=",
                "C3850-RTU=",
                "PWR-C1-440WDC",
                "C1AUCAT38502K9=",
                "WS-C3850-48W-S",
                "CAB-TA-AR=",
                "C3850-4PT-KIT=",
                "C3850-SPWR-NONE",
                "CAB-SPWR-150CM-WS",
                "C1AACAT38502K9",
                "PWR-C1-440WDC-RF",
                "C3850-NEBS-KIT=",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "WS-C3850-48F-S++",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "PWR-C3-750WAC-R/2",
                "FAN-T3-F",
                "CAB-AC-C15-KOR",
                "L-C3850-RTU=",
                "PWR-C1-1100WAC=",
                "C3850-NM-4-1G-RF",
                "STACK-T1-50CM",
                "FAN-T3-R",
                "WS-C3850-48F-S-BR",
                "CAB-SPWR-30CM=",
                "CAB-C15-CBN-JP=",
                "CAB-AC-C15-TW=",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "C3850-FAN-T1-WS",
                "CAB-TA-UK=",
                "C3850-STACK-NONE",
                "PWR-C1-1100WAC-WS",
                "CAB-TA-NA",
                "WS-C3850-48F-S-RL",
                "C1AUCAT38502K9",
                "WS-C3850-48F-S-RF",
                "PWR-C3-750WAC-F/2",
                "WS-C3850-48F-S",
                "STACK-T1-3M=",
                "FAN-T3-F-WS",
                "CAB-TA-SW=",
                "CAB-TA-EU",
                "CAB-TA-JP-RA",
                "CAB-C15-CBN-JP",
                "PWR-C1-715WAC-WS",
                "CAB-TA-EU=",
                "CAB-TA-UK",
                "FAN-T3-R=",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "CAB-TA-JP-RA=",
                "STACK-T1-50CM-RF",
                "C3850-NM-4-1G-WS",
                "CAB-SPWR-150CM=",
                "CAB-TA-TW",
                "STACK-T1-1M",
                "CAB-AC-C15-KOR=",
                "C3850-NM-4-1G=",
                "CAB-TA-CN",
                "PWR-C3-750WDC-F/2",
                "STACK-T1-1M-WS",
                "CAB-TA-250V-JP=",
                "C1AACAT38502K9=",
                "CAB-AC-C15-TW",
                "C1FACAT38502K9=",
                "PWR-C3-750WDC-R/2",
                "CAB-TA-SW",
                "STACK-T1-3M-RF",
                "C1FUCAT38502K9",
                "WS-C3850-48F-S-WS",
                "C3850-4PT-KIT-RF",
                "C3850-RACK-KIT-RF",
                "C3850-ACC-KIT=",
                "L-C3850-SLIC-U=",
                "PWR-C1-1100WAC-RF",
                "PWR-C1-1100WAC",
                "C1FACAT38502K9",
                "CAB-TA-IN=",
                "CAB-TA-JP=",
                "EDU-C3850-48F-S",
                "CAB-TA-250V-JP",
                "PWR-C1-715WAC-RF",
                "CAB-SPWR-30CM",
                "CAB-TA-DN=",
                "CAB-TA-JP",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "C1-C3850-48-DNAA-O",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "CAB-TA-AR",
                "C1-C3850-48-DNAE-O",
                "STACK-T1-1M=",
                "CAB-SPWR-30CM-RF",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "PWR-C1-440WDC=",
                "CAB-TA-AP",
                "UA-C3850-48F-S",
                "C3850-FAN-T1-RF",
                "CAB-TA-IN",
                "CAB-SPWR-150CM-RF",
                "PWR-C1-715WAC",
                "CAB-SPWR-150CM",
                "C1-WS3850-48F/K9",
                "WS-C3850-48F",
                "C1FUCAT38502K9="
             ]
          },
          {  
             "mdfId":"286280268",
             "productName":"Cisco Catalyst 3850-16XS-E Switch",
             "productId":[  
                "STACK-T1-50CM-RF",
                "FAN-T3-F=",
                "CAB-TA-TW",
                "C3850-RTU=",
                "CAB-TA-CN=",
                "STACK-T1-1M",
                "PWR-C1-440WDC",
                "CAB-AC-C15-KOR=",
                "C3850-4PT-KIT=",
                "CAB-TA-AR=",
                "C3850-SPWR-NONE",
                "PWR-C1-440WDC-RF",
                "CAB-TA-CN",
                "C3850-NEBS-KIT=",
                "STACK-T1-1M-WS",
                "CAB-TA-250V-JP=",
                "CAB-AC-C15-TW",
                "CAB-TA-IS=",
                "CAB-TA-SW",
                "STACK-T1-3M-RF",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "FAN-T3-F",
                "C3850-4PT-KIT-RF",
                "PWR-C1-1100WAC=",
                "CAB-AC-C15-KOR",
                "L-C3850-RTU=",
                "C3850-RACK-KIT-RF",
                "LL-C3850-16XS-E=",
                "C3850-ACC-KIT=",
                "PWR-C1-1100WAC-RF",
                "L-C3850-SLIC-U=",
                "STACK-T1-50CM",
                "FAN-T3-R",
                "PWR-C1-1100WAC",
                "CAB-TA-IN=",
                "CAB-TA-250V-JP",
                "CAB-C15-CBN-JP=",
                "PWR-C1-715WAC-RF",
                "CAB-AC-C15-TW=",
                "CAB-TA-DN=",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "STACK-T1-3M",
                "C3850-FAN-T1-WS",
                "WS-C3850-16XS-E",
                "CAB-TA-AP=",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "CAB-TA-UK=",
                "CAB-TA-AR",
                "C3850-STACK-NONE",
                "PWR-C1-1100WAC-WS",
                "CAB-TA-NA",
                "WS-C3850-16XS-E-RF",
                "STACK-T1-1M=",
                "STACK-T1-3M=",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "CAB-TA-SW=",
                "FAN-T3-F-WS",
                "CAB-TA-AP",
                "PWR-C1-440WDC=",
                "CAB-TA-JP-RA",
                "CAB-TA-EU",
                "CAB-C15-CBN-JP",
                "C3850-FAN-T1-RF",
                "CAB-TA-IN",
                "PWR-C1-715WAC",
                "PWR-C1-715WAC-WS",
                "CAB-TA-EU=",
                "WS-C3850-16XS-E-WS",
                "FAN-T3-R=",
                "CAB-TA-UK",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "CAB-TA-JP-RA="
             ]
          },
          {  
             "mdfId":"286257126",
             "productName":"Cisco Catalyst 3850-24S-E Switch",
             "productId":[  
                "FAN-T3-F=",
                "C3850-NM-4-1G",
                "C1FACAT38501K9=",
                "CAB-TA-CN=",
                "C3850-RTU=",
                "PWR-C1-440WDC",
                "C3850-4PT-KIT=",
                "CAB-TA-AR=",
                "C3850-SPWR-NONE",
                "CAB-SPWR-150CM-WS",
                "PWR-C1-440WDC-RF",
                "LL-C3850-24S-E=",
                "C3850-NEBS-KIT=",
                "NAL-3850-24S-E",
                "C1-C3850-24-DNAE-O",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "PWR-C3-750WAC-R/2",
                "FAN-T3-F",
                "C3850-24S-E++",
                "CAB-AC-C15-KOR",
                "L-C3850-RTU=",
                "PWR-C1-1100WAC=",
                "C3850-NM-4-1G-RF",
                "C1FACAT38504K9",
                "C1AACAT38501K9=",
                "FAN-T3-R",
                "STACK-T1-50CM",
                "CAB-SPWR-30CM=",
                "C1-C3850-24-DNAA-O",
                "CAB-C15-CBN-JP=",
                "CAB-AC-C15-TW=",
                "C1AACAT38501K9",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "C3850-FAN-T1-WS",
                "CAB-TA-UK=",
                "C3850-STACK-NONE",
                "CAB-TA-NA",
                "PWR-C1-1100WAC-WS",
                "C1AACAT38504K9=",
                "STACK-T1-3M=",
                "PWR-C3-750WAC-F/2",
                "FAN-T3-F-WS",
                "CAB-TA-SW=",
                "CAB-TA-JP-RA",
                "CAB-TA-EU",
                "CAB-C15-CBN-JP",
                "C1FACAT38504K9=",
                "PWR-C1-715WAC-WS",
                "WS-C3850-24S-E-RF",
                "CAB-TA-EU=",
                "C1AUCAT38501K9=",
                "FAN-T3-R=",
                "CAB-TA-UK",
                "WS-C3850-24S-E",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "CAB-TA-JP-RA=",
                "STACK-T1-50CM-RF",
                "C3850-NM-4-1G-WS",
                "CAB-TA-TW",
                "CAB-SPWR-150CM=",
                "STACK-T1-1M",
                "CAB-AC-C15-KOR=",
                "C1AACAT38504K9",
                "C3850-NM-4-1G=",
                "CAB-TA-CN",
                "C1FUCAT38501K9=",
                "PWR-C3-750WDC-F/2",
                "STACK-T1-1M-WS",
                "CAB-TA-250V-JP=",
                "C1AUCAT38501K9",
                "CAB-AC-C15-TW",
                "PWR-C3-750WDC-R/2",
                "CAB-TA-SW",
                "STACK-T1-3M-RF",
                "C3850-4PT-KIT-RF",
                "C3850-RACK-KIT-RF",
                "PWR-C1-1100WAC-RF",
                "L-C3850-SLIC-U=",
                "C3850-ACC-KIT=",
                "PWR-C1-1100WAC",
                "CAB-TA-IN=",
                "CAB-TA-250V-JP",
                "PWR-C1-715WAC-RF",
                "WS-C3850-24S-E-WS",
                "CAB-SPWR-30CM",
                "CAB-TA-DN=",
                "C1-WS3850-24S/K9",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "CAB-TA-AR",
                "STACK-T1-1M=",
                "CAB-SPWR-30CM-RF",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "CAB-TA-AP",
                "PWR-C1-440WDC=",
                "C3850-FAN-T1-RF",
                "CAB-TA-IN",
                "CAB-SPWR-150CM-RF",
                "PWR-C1-715WAC",
                "C1FUCAT38501K9",
                "WS-C3850-24S",
                "CAB-SPWR-150CM",
                "C1FACAT38501K9"
             ]
          },
          {  
             "mdfId":"286289969",
             "productName":"Cisco Catalyst 3650-8X24UQ-S Switch",
             "productId":[  
                "PWR-C2-250WAC",
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "PWR-C2-1025WAC-RF",
                "C3650-8X24UQ-S++",
                "WS-C36508X24UQS-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "NAL-C3650-8X24UQ-S",
                "PWR-C2-640WAC",
                "RACK-KIT-T1-RF",
                "WSC36508X24UQS-WS",
                "PWR-C2-640WDC-RF",
                "PWR-C2-1025WAC",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WAC-RF",
                "STACK-T2-3M=",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "C3560-NEBS-KIT=",
                "PWR-C2-BLANK=",
                "PWR-C2-250WAC-RF",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "STACK-T2-3M",
                "PWR-C2-640WDC",
                "L-C3650-SLIC-U=",
                "WS-C3650-8X24UQ-S",
                "PWR-C2-250WAC-WS",
                "RACK-KIT-T1-WS",
                "PWR-C2-640WDC-WS",
                "WSC365012X48URS-WS",
                "C1-WS3650-24XPD/K9",
                "RACK-KIT-T1="
             ]
          },
          {  
             "mdfId":"284455420",
             "productName":"Cisco Catalyst 3850-48F-L Switch",
             "productId":[  
                "FAN-T3-F=",
                "C3850-NM-4-1G",
                "C3850-RTU=",
                "C1-WS385048F/K9-RF",
                "CAB-TA-CN=",
                "PWR-C1-440WDC",
                "C3850-4PT-KIT=",
                "C1AUCAT38502K9=",
                "CAB-TA-AR=",
                "C3850-SPWR-NONE",
                "CAB-SPWR-150CM-WS",
                "C1AACAT38502K9",
                "PWR-C1-440WDC-RF",
                "C3850-NEBS-KIT=",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "WS-C3850-48F-L-WS",
                "PWR-C3-750WAC-R/2",
                "FAN-T3-F",
                "CAB-AC-C15-KOR",
                "L-C3850-RTU=",
                "PWR-C1-1100WAC=",
                "C3850-NM-4-1G-RF",
                "STACK-T1-50CM",
                "FAN-T3-R",
                "CAB-SPWR-30CM=",
                "CAB-C15-CBN-JP=",
                "CAB-AC-C15-TW=",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "C3850-FAN-T1-WS",
                "CAB-TA-UK=",
                "C3850-STACK-NONE",
                "PWR-C1-1100WAC-WS",
                "WS-C3850-48F-L",
                "CAB-TA-NA",
                "C1AUCAT38502K9",
                "STACK-T1-3M=",
                "PWR-C3-750WAC-F/2",
                "FAN-T3-F-WS",
                "CAB-TA-SW=",
                "CAB-TA-JP-RA",
                "CAB-TA-EU",
                "CAB-C15-CBN-JP",
                "PWR-C1-715WAC-WS",
                "CAB-TA-EU=",
                "WS-C3850-48F-L++",
                "CAB-TA-UK",
                "FAN-T3-R=",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "CAB-TA-JP-RA=",
                "STACK-T1-50CM-RF",
                "C3850-NM-4-1G-WS",
                "CAB-TA-TW",
                "CAB-SPWR-150CM=",
                "STACK-T1-1M",
                "CAB-AC-C15-KOR=",
                "C3850-NM-4-1G=",
                "CAB-TA-CN",
                "PWR-C3-750WDC-F/2",
                "STACK-T1-1M-WS",
                "CAB-TA-250V-JP=",
                "C1AACAT38502K9=",
                "C1FACAT38502K9=",
                "CAB-AC-C15-TW",
                "PWR-C3-750WDC-R/2",
                "CAB-TA-SW",
                "STACK-T1-3M-RF",
                "C1FUCAT38502K9",
                "C3850-4PT-KIT-RF",
                "C3850-RACK-KIT-RF",
                "C3850-ACC-KIT=",
                "L-C3850-SLIC-U=",
                "PWR-C1-1100WAC-RF",
                "C1FACAT38502K9",
                "PWR-C1-1100WAC",
                "CAB-TA-IN=",
                "CAB-TA-JP=",
                "CAB-TA-250V-JP",
                "PWR-C1-715WAC-RF",
                "CAB-TA-DN=",
                "CAB-SPWR-30CM",
                "CAB-TA-JP",
                "STACK-T1-3M",
                "EDU-C3850-48F-L",
                "WS-C3850-48F-L-RL",
                "CAB-TA-AP=",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "C1-C3850-48-DNAA-O",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "C1-C3850-48-DNAE-O",
                "CAB-TA-AR",
                "UA-C3850-48F-L",
                "STACK-T1-1M=",
                "CAB-SPWR-30CM-RF",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "WS-C3850-48F-L-BR",
                "PWR-C1-440WDC=",
                "CAB-TA-AP",
                "C3850-FAN-T1-RF",
                "CAB-TA-IN",
                "CAB-SPWR-150CM-RF",
                "PWR-C1-715WAC",
                "WS-C3850-48F-L-RF",
                "CAB-SPWR-150CM",
                "C1-WS3850-48F/K9",
                "C1FUCAT38502K9="
             ]
          },
          {  
             "mdfId":"284850621",
             "productName":"Cisco Catalyst 3650-24PD-E Switch",
             "productId":[  
                "WS-C3650-24PD-HC",
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "C1FUCAT36501K9",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "WS-C3650-24PD-E-WS",
                "PWR-C2-640WAC",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WDC-RF",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "4PT-KIT-T1-RF",
                "C3560-NEBS-KIT=",
                "C3650-RTU=",
                "C1-C3650-24-DNAA-O",
                "PWR-C2-250WAC-RF",
                "STACK-T2-3M",
                "C1FUCAT36501K9=",
                "PWR-C2-640WDC",
                "C1-WS3650-24PD/K9",
                "WS-C3650-24PD-E",
                "C1-C3650-24-DNAE-O",
                "C1AUCAT36501K9=",
                "C3650-24PD-E++",
                "WS-C3650-24PD",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "NAL-C3650-24PD-E",
                "C1FACAT36501K9",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "C1AUCAT36501K9",
                "C1AACAT36501K9=",
                "C1FACAT36501K9=",
                "C1AACAT36501K9",
                "L-C3650-SLIC-U=",
                "WS-C3650-24PD-E-RF",
                "RACK-KIT-T1-WS",
                "PWR-C2-250WAC-WS",
                "PWR-C2-640WDC-WS",
                "4PT-KIT-T1=",
                "C1-WS3650-24XPD/K9",
                "WSC365012X48URS-WS",
                "ACC-KIT-T1=",
                "RACK-KIT-T1="
             ]
          },
          {  
             "mdfId":"286309940",
             "productName":"Cisco Catalyst 3650-8X24PD-E Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "STACK-T2-3M",
                "WS-C3650-12X48UR-S",
                "RACK-KIT-T1-RF",
                "STACK-T2-3M=",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "C3560-NEBS-KIT=",
                "STACK-T2-3M-WS",
                "NAL-C3650-8X24PD-E",
                "RACK-KIT-T1=",
                "C1-WS3650-24XPD/K9",
                "WSC365012X48URS-WS"
             ]
          },
          {  
             "mdfId":"284455380",
             "productName":"Cisco Catalyst 3850-48P-L Switch",
             "productId":[  
                "FAN-T3-F=",
                "C3850-NM-4-1G",
                "WS-C3850-48P-L-WS",
                "CAB-TA-CN=",
                "C3850-RTU=",
                "PWR-C1-440WDC",
                "C1AUCAT38502K9=",
                "CAB-TA-AR=",
                "C3850-4PT-KIT=",
                "C3850-SPWR-NONE",
                "C1AACAT38502K9",
                "CAB-SPWR-150CM-WS",
                "PWR-C1-440WDC-RF",
                "C3850-NEBS-KIT=",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "PWR-C3-750WAC-R/2",
                "FAN-T3-F",
                "PWR-C1-1100WAC=",
                "L-C3850-RTU=",
                "CAB-AC-C15-KOR",
                "C3850-NM-4-1G-RF",
                "FAN-T3-R",
                "STACK-T1-50CM",
                "CAB-SPWR-30CM=",
                "CAB-C15-CBN-JP=",
                "CAB-AC-C15-TW=",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "C3850-FAN-T1-WS",
                "EDU-C3850-48P-L",
                "CAB-TA-UK=",
                "C3850-STACK-NONE",
                "CAB-TA-NA",
                "PWR-C1-1100WAC-WS",
                "C1AUCAT38502K9",
                "PWR-C3-750WAC-F/2",
                "STACK-T1-3M=",
                "FAN-T3-F-WS",
                "CAB-TA-SW=",
                "CAB-TA-JP-RA",
                "CAB-TA-EU",
                "CAB-C15-CBN-JP",
                "PWR-C1-715WAC-WS",
                "CAB-TA-EU=",
                "FAN-T3-R=",
                "CAB-TA-UK",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "CAB-TA-JP-RA=",
                "STACK-T1-50CM-RF",
                "C3850-NM-4-1G-WS",
                "CAB-TA-TW",
                "CAB-SPWR-150CM=",
                "STACK-T1-1M",
                "CAB-AC-C15-KOR=",
                "C3850-NM-4-1G=",
                "WS-C3850-48P-L-BR",
                "CAB-TA-CN",
                "PWR-C3-750WDC-F/2",
                "STACK-T1-1M-WS",
                "C1AACAT38502K9=",
                "CAB-TA-250V-JP=",
                "CAB-AC-C15-TW",
                "C1FACAT38502K9=",
                "PWR-C3-750WDC-R/2",
                "UA-C3850-48P-L",
                "CAB-TA-SW",
                "STACK-T1-3M-RF",
                "C1FUCAT38502K9",
                "WS-C3850-48P-L-RF",
                "WS-C3850-48P-L-RL",
                "C3850-4PT-KIT-RF",
                "C3850-RACK-KIT-RF",
                "C1-WS3850-48P/K9",
                "L-C3850-SLIC-U=",
                "PWR-C1-1100WAC-RF",
                "C3850-ACC-KIT=",
                "PWR-C1-1100WAC",
                "C1FACAT38502K9",
                "LL-C3850-48P-L=",
                "CAB-TA-IN=",
                "CAB-TA-JP=",
                "CAB-TA-250V-JP",
                "PWR-C1-715WAC-RF",
                "CAB-TA-DN=",
                "CAB-SPWR-30CM",
                "WS-C3850-48P-L",
                "CAB-TA-JP",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "C1-C3850-48-DNAA-O",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "CAB-TA-AR",
                "C1-C3850-48-DNAE-O",
                "CAB-SPWR-30CM-RF",
                "STACK-T1-1M=",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "CAB-TA-AP",
                "PWR-C1-440WDC=",
                "C3850-FAN-T1-RF",
                "CAB-TA-IN",
                "CAB-SPWR-150CM-RF",
                "WS-C3850-48P-L++",
                "PWR-C1-715WAC",
                "WS-C3850-48P",
                "CAB-SPWR-150CM",
                "C1FUCAT38502K9="
             ]
          },
          {  
             "mdfId":"286290763",
             "productName":"Cisco Catalyst 3650-48FQM-L Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "C3650-48FQM-L++",
                "WS-C3650-48FWD-S",
                "WS-C3650-48FQ-E-HC",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "C1FACAT36502K9",
                "PWR-C2-640WAC",
                "PWR-C2-640WDC-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "C3650-RTU=",
                "C3560-NEBS-KIT=",
                "4PT-KIT-T1-RF",
                "C1WS365048FQ/K9-RF",
                "PWR-C2-250WAC-RF",
                "C1AUCAT36502K9",
                "STACK-T2-3M",
                "WS-C3650-48FQML-RF",
                "WS-C3650-48FQM",
                "WS-C3650-48FQ-HC",
                "WS-C3650-48FQM-L",
                "PWR-C2-640WDC",
                "C1AACAT36502K9",
                "C1AUCAT36502K9=",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "C1FUCAT36502K9=",
                "C1FUCAT36502K9",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "C1AACAT36502K9=",
                "WS-C3650-48FQML-WS",
                "WS-C3650-48FWDS-RF",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "C1FACAT36502K9=",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "C1-WS3650-48FQ/K9",
                "STACK-T2-3M-WS",
                "C1-WS3650-48FQM/K9",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "PWR-C2-250WAC-WS",
                "RACK-KIT-T1-WS",
                "PWR-C2-640WDC-WS",
                "ACC-KIT-T1=",
                "4PT-KIT-T1=",
                "WSC365012X48URS-WS",
                "RACK-KIT-T1="
             ]
          },
          {  
             "mdfId":"286289949",
             "productName":"Cisco Catalyst 3650-12X48UZ-E Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC",
                "PWR-C2-250WAC=",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-48FWD-S",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "NAL-C3650-12X48UZE",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "RACK-KIT-T1-RF",
                "PWR-C2-640WAC",
                "WS-C3650-48FWDS-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WDC-RF",
                "PWR-C2-1025WAC",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "STACK-T2-3M=",
                "WSC365012X48UZE-RF",
                "WSC365012X48URS-RF",
                "PWR-C2-BLANK",
                "C3650-12X48UZ-E++",
                "STACK-T2-3M-WS",
                "C3560-NEBS-KIT=",
                "PWR-C2-BLANK=",
                "PWR-C2-250WAC-RF",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "STACK-T2-3M",
                "PWR-C2-640WDC",
                "WS-C3650-12X48UZ-E",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "RACK-KIT-T1-WS",
                "PWR-C2-250WAC-WS",
                "PWR-C2-640WDC-WS",
                "RACK-KIT-T1=",
                "WSC365012X48URS-WS"
             ]
          },
          {  
             "mdfId":"284850622",
             "productName":"Cisco Catalyst 3650-48PD-E Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "WS-C3650-48PD-E-WS",
                "WS-C3650-48PD",
                "WS-C3650-48FWD-S",
                "PWR-C2-640WAC=",
                "C1FACAT36502K9",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "WS-C3650-48PD-E",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WDC-RF",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "C1-WS3650-48PD/K9",
                "WSC365012X48URS-RF",
                "C3650-RTU=",
                "C3560-NEBS-KIT=",
                "4PT-KIT-T1-RF",
                "PWR-C2-250WAC-RF",
                "C1AUCAT36502K9",
                "STACK-T2-3M",
                "C1-C3650-48-DNAA-O",
                "PWR-C2-640WDC",
                "C1-C3650-48-DNAE-O",
                "NAL-C3650-48PD-E",
                "C1AACAT36502K9",
                "C1AUCAT36502K9=",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "C1FUCAT36502K9",
                "C1FUCAT36502K9=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "C1AACAT36502K9=",
                "PWR-C2-640WAC-WS",
                "RACK-KIT-T1-RF",
                "WS-C3650-48FWDS-RF",
                "PWR-C2-1025WAC",
                "C1FACAT36502K9=",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "C3650-48PD-E++",
                "WS-C3650-48PD-E-RF",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "PWR-C2-250WAC-WS",
                "RACK-KIT-T1-WS",
                "PWR-C2-640WDC-WS",
                "ACC-KIT-T1=",
                "RACK-KIT-T1=",
                "4PT-KIT-T1=",
                "WSC365012X48URS-WS"
             ]
          },
          {  
             "mdfId":"286280248",
             "productName":"Cisco Catalyst 3850-12XS-E Switch",
             "productId":[  
                "STACK-T1-50CM-RF",
                "FAN-T3-F=",
                "CAB-TA-TW",
                "C3850-RTU=",
                "CAB-TA-CN=",
                "PWR-C1-440WDC",
                "STACK-T1-1M",
                "CAB-AC-C15-KOR=",
                "C3850-4PT-KIT=",
                "CAB-TA-AR=",
                "C3850-SPWR-NONE",
                "PWR-C1-440WDC-RF",
                "CAB-TA-CN",
                "C3850-NEBS-KIT=",
                "STACK-T1-1M-WS",
                "CAB-TA-250V-JP=",
                "CAB-AC-C15-TW",
                "CAB-TA-IS=",
                "CAB-TA-SW",
                "STACK-T1-3M-RF",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "FAN-T3-F",
                "C3850-4PT-KIT-RF",
                "PWR-C1-1100WAC=",
                "CAB-AC-C15-KOR",
                "L-C3850-RTU=",
                "C3850-RACK-KIT-RF",
                "PWR-C1-1100WAC-RF",
                "L-C3850-SLIC-U=",
                "C3850-ACC-KIT=",
                "STACK-T1-50CM",
                "PWR-C1-1100WAC",
                "CAB-TA-IN=",
                "CAB-C15-CBN-JP=",
                "CAB-TA-250V-JP",
                "PWR-C1-715WAC-RF",
                "CAB-AC-C15-TW=",
                "C1FACAT38503K9=",
                "CAB-TA-DN=",
                "CAB-TA-IT=",
                "WS-C3850-12XS-E-RF",
                "STACK-T1-1M-RF",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "C3850-FAN-T1-WS",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "C1AACAT38503K9",
                "C3850-FAN-T1=",
                "CAB-TA-UK=",
                "CAB-TA-AR",
                "C3850-STACK-NONE",
                "PWR-C1-1100WAC-WS",
                "CAB-TA-NA",
                "STACK-T1-1M=",
                "CAB-TA-IS",
                "STACK-T1-3M=",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "CAB-TA-SW=",
                "FAN-T3-F-WS",
                "CAB-TA-AP",
                "PWR-C1-440WDC=",
                "CAB-TA-JP-RA",
                "CAB-TA-EU",
                "C3850-FAN-T1-RF",
                "CAB-C15-CBN-JP",
                "CAB-TA-IN",
                "WS-C3850-12XS",
                "PWR-C1-715WAC",
                "PWR-C1-715WAC-WS",
                "CAB-TA-EU=",
                "WS-C3850-12XS-E-WS",
                "WS-C3850-12XS-E",
                "LL-C3850-12XS-E=",
                "C3850-12XS-E++",
                "CAB-TA-UK",
                "C1-WS3850-12XS-S",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "C1FACAT38503K9",
                "STACK-T1-3M-WS",
                "C1AACAT38503K9=",
                "CAB-TA-JP-RA="
             ]
          },
          {  
             "mdfId":"284455438",
             "productName":"Cisco Catalyst 3850-24U-E Switch",
             "productId":[  
                "FAN-T3-F=",
                "C3850-NM-4-1G",
                "C1FACAT38501K9=",
                "CAB-TA-CN=",
                "C3850-RTU=",
                "PWR-C1-440WDC",
                "C3850-4PT-KIT=",
                "CAB-TA-AR=",
                "C3850-SPWR-NONE",
                "WS-C3850-24U-E++",
                "CAB-SPWR-150CM-WS",
                "PWR-C1-440WDC-RF",
                "C3850-NEBS-KIT=",
                "C1-C3850-24-DNAE-O",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "PWR-C3-750WAC-R/2",
                "FAN-T3-F",
                "WS-C3850-24U-E-RF",
                "PWR-C1-1100WAC=",
                "CAB-AC-C15-KOR",
                "L-C3850-RTU=",
                "C3850-NM-4-1G-RF",
                "C1FACAT38504K9",
                "FAN-T3-R",
                "C1AACAT38501K9=",
                "STACK-T1-50CM",
                "CAB-SPWR-30CM=",
                "C1-C3850-24-DNAA-O",
                "CAB-C15-CBN-JP=",
                "CAB-AC-C15-TW=",
                "C1AACAT38501K9",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "C3850-FAN-T1-WS",
                "CAB-TA-UK=",
                "C3850-STACK-NONE",
                "CAB-TA-NA",
                "PWR-C1-1100WAC-WS",
                "C1AACAT38504K9=",
                "PWR-C3-750WAC-F/2",
                "STACK-T1-3M=",
                "FAN-T3-F-WS",
                "CAB-TA-SW=",
                "CAB-TA-JP-RA",
                "CAB-TA-EU",
                "CAB-C15-CBN-JP",
                "C1FACAT38504K9=",
                "PWR-C1-715WAC-WS",
                "C1AUCAT38501K9=",
                "CAB-TA-EU=",
                "CAB-TA-UK",
                "FAN-T3-R=",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "CAB-TA-JP-RA=",
                "STACK-T1-50CM-RF",
                "C3850-NM-4-1G-WS",
                "CAB-TA-TW",
                "CAB-SPWR-150CM=",
                "STACK-T1-1M",
                "C1-WS3850-24U/K9",
                "CAB-AC-C15-KOR=",
                "C1AACAT38504K9",
                "C3850-NM-4-1G=",
                "WS-C3850-24U-E-WS",
                "CAB-TA-CN",
                "C1FUCAT38501K9=",
                "PWR-C3-750WDC-F/2",
                "STACK-T1-1M-WS",
                "C1AUCAT38501K9",
                "CAB-TA-250V-JP=",
                "CAB-AC-C15-TW",
                "PWR-C3-750WDC-R/2",
                "CAB-TA-SW",
                "STACK-T1-3M-RF",
                "C3850-4PT-KIT-RF",
                "C3850-RACK-KIT-RF",
                "PWR-C1-1100WAC-RF",
                "C3850-ACC-KIT=",
                "L-C3850-SLIC-U=",
                "PWR-C1-1100WAC",
                "CAB-TA-IN=",
                "CAB-TA-JP=",
                "CAB-TA-250V-JP",
                "PWR-C1-715WAC-RF",
                "CAB-SPWR-30CM",
                "CAB-TA-DN=",
                "UA-C3850-24U-E",
                "CAB-TA-JP",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "CAB-TA-AR",
                "STACK-T1-1M=",
                "CAB-SPWR-30CM-RF",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "PWR-C1-440WDC=",
                "CAB-TA-AP",
                "C3850-FAN-T1-RF",
                "WS-C3850-24U",
                "CAB-TA-IN",
                "WS-C3850-24U-E",
                "CAB-SPWR-150CM-RF",
                "PWR-C1-715WAC",
                "C1FUCAT38501K9",
                "CAB-SPWR-150CM",
                "C1FACAT38501K9"
             ]
          },
          {  
             "mdfId":"284455432",
             "productName":"Cisco Catalyst 3850-48U-S Switch",
             "productId":[  
                "FAN-T3-F=",
                "C3850-NM-4-1G",
                "CAB-TA-CN=",
                "C3850-RTU=",
                "PWR-C1-440WDC",
                "WS-C3850E-12X48U-S",
                "WS-C3850-12X48U-S",
                "CAB-TA-AR=",
                "C3850-4PT-KIT=",
                "WS-C3850-48W-S",
                "C1AUCAT38502K9=",
                "LL-C3850-48U-S=",
                "C3850-SPWR-NONE",
                "C1AACAT38502K9",
                "CAB-SPWR-150CM-WS",
                "PWR-C1-440WDC-RF",
                "C3850-NEBS-KIT=",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "WS-C3850-48U-S-WS",
                "PWR-C3-750WAC-R/2",
                "FAN-T3-F",
                "WS-C3850-48UW-S",
                "PWR-C1-1100WAC=",
                "CAB-AC-C15-KOR",
                "L-C3850-RTU=",
                "WS-C385012X48US-WS",
                "C3850-NM-4-1G-RF",
                "FAN-T3-R",
                "STACK-T1-50CM",
                "CAB-SPWR-30CM=",
                "CAB-C15-CBN-JP=",
                "CAB-AC-C15-TW=",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "C3850-FAN-T1-WS",
                "CAB-TA-UK=",
                "C3850-STACK-NONE",
                "CAB-TA-NA",
                "PWR-C1-1100WAC-WS",
                "C1AUCAT38502K9",
                "STACK-T1-3M=",
                "PWR-C3-750WAC-F/2",
                "FAN-T3-F-WS",
                "CAB-TA-SW=",
                "CAB-TA-JP-RA",
                "CAB-TA-EU",
                "CAB-C15-CBN-JP",
                "C1-WS3850-48U/K9",
                "PWR-C1-715WAC-WS",
                "CAB-TA-EU=",
                "WS-C385012X48US-RF",
                "UA-C3850-48U-S",
                "CAB-TA-UK",
                "FAN-T3-R=",
                "WS-C3850-12X48U",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "CAB-TA-JP-RA=",
                "STACK-T1-50CM-RF",
                "C3850-NM-4-1G-WS",
                "WS-C3850-48U-S-RF",
                "CAB-TA-TW",
                "CAB-SPWR-150CM=",
                "STACK-T1-1M",
                "WS-C3850-48U-S",
                "CAB-AC-C15-KOR=",
                "C3850-NM-4-1G=",
                "CAB-TA-CN",
                "PWR-C3-750WDC-F/2",
                "STACK-T1-1M-WS",
                "EDU-C3850-48U-S",
                "C1AACAT38502K9=",
                "CAB-TA-250V-JP=",
                "CAB-AC-C15-TW",
                "C1FACAT38502K9=",
                "PWR-C3-750WDC-R/2",
                "CAB-TA-SW",
                "STACK-T1-3M-RF",
                "WS-C3850-48UW-S-RF",
                "C1FUCAT38502K9",
                "C3850-4PT-KIT-RF",
                "C3850-RACK-KIT-RF",
                "C3850-ACC-KIT=",
                "L-C3850-SLIC-U=",
                "PWR-C1-1100WAC-RF",
                "PWR-C1-1100WAC",
                "C1FACAT38502K9",
                "CAB-TA-IN=",
                "CAB-TA-JP=",
                "CAB-TA-250V-JP",
                "PWR-C1-715WAC-RF",
                "CAB-SPWR-30CM",
                "CAB-TA-DN=",
                "CAB-TA-JP",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "C1-C3850-48-DNAA-O",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "C1-C3850-48-DNAE-O",
                "CAB-TA-AR",
                "STACK-T1-1M=",
                "CAB-SPWR-30CM-RF",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "CAB-TA-AP",
                "PWR-C1-440WDC=",
                "C3850-FAN-T1-RF",
                "WS-C3850-48U-S++",
                "CAB-TA-IN",
                "CAB-SPWR-150CM-RF",
                "PWR-C1-715WAC",
                "WS-C3850-48U",
                "WS-C3850-48UW-S-WS",
                "CAB-SPWR-150CM",
                "C1FUCAT38502K9="
             ]
          },
          {  
             "mdfId":"286309930",
             "productName":"Cisco Catalyst 3650-12X48FD-E Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "STACK-T2-3M",
                "NAL-C3650-12X48FDE",
                "WS-C3650-48FWD-S",
                "WS-C3650-12X48UR-S",
                "RACK-KIT-T1-RF",
                "WS-C3650-48FWDS-RF",
                "STACK-T2-3M=",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "C1-WS3650-48XFD/K9",
                "STACK-T2-3M-WS",
                "C3560-NEBS-KIT=",
                "WSC365012X48URS-WS",
                "RACK-KIT-T1="
             ]
          },
          {  
             "mdfId":"284850605",
             "productName":"Cisco Catalyst 3650-48PD-S Switch",
             "productId":[  
                "WS-C3650-48PWD-S",
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "WS-C3650-48PD-S",
                "WS-C3650-48PD-S-RF",
                "WS-C3650-48PD",
                "WS-C3650-48FWD-S",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "C1FACAT36502K9",
                "PWR-C2-640WAC",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WDC-RF",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "C1-WS3650-48PD/K9",
                "WSC365012X48URS-RF",
                "4PT-KIT-T1-RF",
                "C3560-NEBS-KIT=",
                "C3650-RTU=",
                "PWR-C2-250WAC-RF",
                "WS-C3650-24PD-S",
                "C1AUCAT36502K9",
                "STACK-T2-3M",
                "C1-C3650-48-DNAA-O",
                "PWR-C2-640WDC",
                "C1-C3650-48-DNAE-O",
                "C1AACAT36502K9",
                "NAL-C3650-48PD-S",
                "WS-C3650-24PD-S-RF",
                "C1AUCAT36502K9=",
                "EDU-C3650-48PD-S",
                "PWR-C2-250WAC",
                "WS-C3650-24PD-S-WS",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "C1FUCAT36502K9",
                "C1FUCAT36502K9=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "C1AACAT36502K9=",
                "WS-C3650-48FWDS-RF",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "C1FACAT36502K9=",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "C3650-48PD-S++",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "WS-C3650-48PD-S-WS",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "PWR-C2-250WAC-WS",
                "RACK-KIT-T1-WS",
                "PWR-C2-640WDC-WS",
                "RACK-KIT-T1=",
                "ACC-KIT-T1=",
                "4PT-KIT-T1=",
                "WSC365012X48URS-WS"
             ]
          },
          {  
             "mdfId":"286280318",
             "productName":"Cisco Catalyst 3850-48XS-S Switch",
             "productId":[  
                "FAN-T3-F=",
                "CAB-TA-CN=",
                "C3850-RTU=",
                "PWR-C1-440WDC",
                "C3850-4PT-KIT=",
                "CAB-TA-AR=",
                "C1AUCAT38502K9=",
                "WS-C3850-48W-S",
                "C3850-SPWR-NONE",
                "C1AACAT38502K9",
                "WS-C3850-48XS-S",
                "PWR-C1-440WDC-RF",
                "PWR-C3-750WAC-F",
                "C3850-NEBS-KIT=",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "FAN-T3-F",
                "CAB-AC-C15-KOR",
                "L-C3850-RTU=",
                "PWR-C1-1100WAC=",
                "FAN-T3-R",
                "STACK-T1-50CM",
                "CAB-C15-CBN-JP=",
                "CAB-AC-C15-TW=",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "C3850-FAN-T1-WS",
                "CAB-TA-UK=",
                "C3850-STACK-NONE",
                "PWR-C1-1100WAC-WS",
                "CAB-TA-NA",
                "C1AUCAT38502K9",
                "STACK-T1-3M=",
                "PWR-C3-750WAC-F-WS",
                "FAN-T3-F-WS",
                "CAB-TA-SW=",
                "CAB-TA-EU",
                "CAB-TA-JP-RA",
                "CAB-C15-CBN-JP",
                "PWR-C1-715WAC-WS",
                "CAB-TA-EU=",
                "WS-C3850-48XS-S-RF",
                "FAN-T3-R=",
                "CAB-TA-UK",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "CAB-TA-JP-RA=",
                "STACK-T1-50CM-RF",
                "CAB-TA-TW",
                "STACK-T1-1M",
                "CAB-AC-C15-KOR=",
                "C1-WSC3850-48XS-S",
                "WS-C3850-48XS",
                "LL-C3850-48XS-S=",
                "CAB-TA-CN",
                "STACK-T1-1M-WS",
                "C1AACAT38502K9=",
                "CAB-TA-250V-JP=",
                "CAB-AC-C15-TW",
                "C1FACAT38502K9=",
                "CAB-TA-SW",
                "STACK-T1-3M-RF",
                "C1FUCAT38502K9",
                "C3850-4PT-KIT-RF",
                "PWR-C3-750WAC-F-RF",
                "C3850-RACK-KIT-RF",
                "L-C3850-SLIC-U=",
                "C3850-ACC-KIT=",
                "PWR-C1-1100WAC-RF",
                "C3850-48XS-S++",
                "C1FACAT38502K9",
                "PWR-C1-1100WAC",
                "CAB-TA-IN=",
                "PWR-C1-715WAC-RF",
                "CAB-TA-250V-JP",
                "CAB-TA-DN=",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "CAB-TA-AR",
                "PWR-C3-750WAC-F=",
                "STACK-T1-1M=",
                "WS-C3850-48XS-S-WS",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "CAB-TA-AP",
                "PWR-C1-440WDC=",
                "C3850-FAN-T1-RF",
                "CAB-TA-IN",
                "PWR-C1-715WAC",
                "C1FUCAT38502K9=",
                "S6850-48XS-S"
             ]
          },
          {  
             "mdfId":"284455421",
             "productName":"Cisco Catalyst 3850-24U-L Switch",
             "productId":[  
                "FAN-T3-F=",
                "C3850-NM-4-1G",
                "C1FACAT38501K9=",
                "CAB-TA-CN=",
                "C3850-RTU=",
                "PWR-C1-440WDC",
                "C3850-4PT-KIT=",
                "CAB-TA-AR=",
                "C3850-SPWR-NONE",
                "CAB-SPWR-150CM-WS",
                "PWR-C1-440WDC-RF",
                "C3850-NEBS-KIT=",
                "C1-C3850-24-DNAE-O",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "PWR-C3-750WAC-R/2",
                "FAN-T3-F",
                "CAB-AC-C15-KOR",
                "L-C3850-RTU=",
                "PWR-C1-1100WAC=",
                "C3850-NM-4-1G-RF",
                "C1FACAT38504K9",
                "FAN-T3-R",
                "STACK-T1-50CM",
                "C1AACAT38501K9=",
                "CAB-SPWR-30CM=",
                "C1-C3850-24-DNAA-O",
                "CAB-C15-CBN-JP=",
                "CAB-AC-C15-TW=",
                "C1AACAT38501K9",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "C3850-FAN-T1-WS",
                "CAB-TA-UK=",
                "C3850-STACK-NONE",
                "PWR-C1-1100WAC-WS",
                "CAB-TA-NA",
                "C1AACAT38504K9=",
                "PWR-C3-750WAC-F/2",
                "STACK-T1-3M=",
                "CAB-TA-SW=",
                "FAN-T3-F-WS",
                "CAB-TA-EU",
                "CAB-TA-JP-RA",
                "CAB-C15-CBN-JP",
                "C1FACAT38504K9=",
                "UA-C3850-48U-L",
                "PWR-C1-715WAC-WS",
                "C1AUCAT38501K9=",
                "CAB-TA-EU=",
                "FAN-T3-R=",
                "WS-C3850-24U-L-WS",
                "CAB-TA-UK",
                "EDU-C3850-24U-L",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "CAB-TA-JP-RA=",
                "C3850-NM-4-1G-WS",
                "STACK-T1-50CM-RF",
                "CAB-SPWR-150CM=",
                "CAB-TA-TW",
                "STACK-T1-1M",
                "C1-WS3850-24U/K9",
                "CAB-AC-C15-KOR=",
                "C1AACAT38504K9",
                "WS-C3850-24U-L-RF",
                "C3850-NM-4-1G=",
                "CAB-TA-CN",
                "C1FUCAT38501K9=",
                "PWR-C3-750WDC-F/2",
                "STACK-T1-1M-WS",
                "C1AUCAT38501K9",
                "CAB-TA-250V-JP=",
                "CAB-AC-C15-TW",
                "PWR-C3-750WDC-R/2",
                "CAB-TA-SW",
                "STACK-T1-3M-RF",
                "C3850-4PT-KIT-RF",
                "C3850-RACK-KIT-RF",
                "L-C3850-SLIC-U=",
                "PWR-C1-1100WAC-RF",
                "C3850-ACC-KIT=",
                "PWR-C1-1100WAC",
                "CAB-TA-IN=",
                "CAB-TA-JP=",
                "PWR-C1-715WAC-RF",
                "CAB-TA-250V-JP",
                "CAB-TA-DN=",
                "UA-C3850-24U-L",
                "CAB-SPWR-30CM",
                "CAB-TA-JP",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "WS-C3850-24U-L++",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "CAB-TA-AR",
                "CAB-SPWR-30CM-RF",
                "STACK-T1-1M=",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "PWR-C1-440WDC=",
                "CAB-TA-AP",
                "C3850-FAN-T1-RF",
                "WS-C3850-24U-L",
                "WS-C3850-24U",
                "CAB-TA-IN",
                "CAB-SPWR-150CM-RF",
                "PWR-C1-715WAC",
                "C1FUCAT38501K9",
                "CAB-SPWR-150CM",
                "C1FACAT38501K9"
             ]
          },
          {  
             "mdfId":"284455433",
             "productName":"Cisco Catalyst 3850-24T-E Switch",
             "productId":[  
                "FAN-T3-F=",
                "C1FACAT38501K9=",
                "C3850-NM-4-1G",
                "C3850-RTU=",
                "CAB-TA-CN=",
                "PWR-C1-440WDC",
                "C3850-4PT-KIT=",
                "CAB-TA-AR=",
                "C3850-SPWR-NONE",
                "CAB-SPWR-150CM-WS",
                "PWR-C1-440WDC-RF",
                "WS-C3850-24T-E-RL",
                "WS-C3850-24T-E-RF",
                "C3850-NEBS-KIT=",
                "C1-C3850-24-DNAE-O",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "C1-WS3850-24T/K9",
                "STACK-T1-50CM-WS",
                "PWR-C3-750WAC-R/2",
                "FAN-T3-F",
                "CAB-AC-C15-KOR",
                "PWR-C1-1100WAC=",
                "L-C3850-RTU=",
                "C3850-NM-4-1G-RF",
                "C1FACAT38504K9",
                "STACK-T1-50CM",
                "FAN-T3-R",
                "C1AACAT38501K9=",
                "C1-C3850-24-DNAA-O",
                "CAB-SPWR-30CM=",
                "CAB-C15-CBN-JP=",
                "C1AACAT38501K9",
                "CAB-AC-C15-TW=",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "C3850-FAN-T1-WS",
                "CAB-TA-UK=",
                "C3850-STACK-NONE",
                "CAB-TA-NA",
                "PWR-C1-1100WAC-WS",
                "C1AACAT38504K9=",
                "PWR-C3-750WAC-F/2",
                "STACK-T1-3M=",
                "CAB-TA-SW=",
                "FAN-T3-F-WS",
                "CAB-TA-EU",
                "CAB-TA-JP-RA",
                "CAB-C15-CBN-JP",
                "C1FACAT38504K9=",
                "PWR-C1-715WAC-WS",
                "C1AUCAT38501K9=",
                "CAB-TA-EU=",
                "FAN-T3-R=",
                "CAB-TA-UK",
                "WS-C3850-24T-E-WS",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "CAB-TA-JP-RA=",
                "STACK-T1-50CM-RF",
                "C3850-NM-4-1G-WS",
                "WS-C3850-24T-E++",
                "C1-WS385024T/K9-RF",
                "CAB-SPWR-150CM=",
                "CAB-TA-TW",
                "STACK-T1-1M",
                "CAB-AC-C15-KOR=",
                "C1AACAT38504K9",
                "C3850-NM-4-1G=",
                "CAB-TA-CN",
                "PWR-C3-750WDC-F/2",
                "C1FUCAT38501K9=",
                "STACK-T1-1M-WS",
                "C1AUCAT38501K9",
                "CAB-TA-250V-JP=",
                "CAB-AC-C15-TW",
                "PWR-C3-750WDC-R/2",
                "CAB-TA-SW",
                "STACK-T1-3M-RF",
                "C3850-4PT-KIT-RF",
                "C3850-RACK-KIT-RF",
                "WS-C3850-24T-E",
                "PWR-C1-1100WAC-RF",
                "C3850-ACC-KIT=",
                "L-C3850-SLIC-U=",
                "PWR-C1-1100WAC",
                "CAB-TA-IN=",
                "CAB-TA-JP=",
                "CAB-TA-250V-JP",
                "PWR-C1-715WAC-RF",
                "CAB-SPWR-30CM",
                "CAB-TA-DN=",
                "CAB-TA-JP",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "CAB-TA-AR",
                "CAB-SPWR-30CM-RF",
                "STACK-T1-1M=",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "PWR-C1-440WDC=",
                "CAB-TA-AP",
                "C3850-FAN-T1-RF",
                "WS-C3850-24T-E-BR",
                "CAB-TA-IN",
                "CAB-SPWR-150CM-RF",
                "PWR-C1-715WAC",
                "C1FUCAT38501K9",
                "WS-C3850-24T",
                "CAB-SPWR-150CM",
                "UA-C3850-24T-E",
                "C1FACAT38501K9"
             ]
          },
          {  
             "mdfId":"286285449",
             "productName":"Cisco Catalyst 3850-24XU-E Switch",
             "productId":[  
                "FAN-T3-F=",
                "C1FACAT38501K9=",
                "CAB-TA-TW",
                "CAB-TA-CN=",
                "C3850-RTU=",
                "PWR-C1-440WDC",
                "CAB-AC-C15-KOR=",
                "CAB-TA-AR=",
                "C3850-4PT-KIT=",
                "C1AACAT38504K9",
                "C3850-SPWR-NONE",
                "PWR-C1-440WDC-RF",
                "WS-C3850-24XU-E++",
                "CAB-TA-CN",
                "C1FUCAT38501K9=",
                "C3850-NEBS-KIT=",
                "CAB-TA-250V-JP=",
                "C1AUCAT38501K9",
                "CAB-AC-C15-TW",
                "CAB-TA-IS=",
                "CAB-TA-SW",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "FAN-T3-F",
                "C3850-4PT-KIT-RF",
                "CAB-AC-C15-KOR",
                "L-C3850-RTU=",
                "C3850-RACK-KIT-RF",
                "L-C3850-SLIC-U=",
                "C3850-ACC-KIT=",
                "C1FACAT38504K9",
                "FAN-T3-R",
                "C1AACAT38501K9=",
                "CAB-TA-IN=",
                "CAB-TA-250V-JP",
                "CAB-C15-CBN-JP=",
                "C1AACAT38501K9",
                "CAB-AC-C15-TW=",
                "WS-C3850-24XU-EJ",
                "CAB-TA-DN=",
                "CAB-TA-IT=",
                "WS-C3850-24XU-E",
                "WS-C3850-24XU-E-RF",
                "C3850-FAN-T1-WS",
                "CAB-TA-AP=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "WS-C3850-24XU",
                "C3850-FAN-T1=",
                "CAB-TA-UK=",
                "CAB-TA-AR",
                "C3850-STACK-NONE",
                "CAB-TA-NA",
                "C1AACAT38504K9=",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "CAB-TA-SW=",
                "FAN-T3-F-WS",
                "PWR-C1-440WDC=",
                "CAB-TA-AP",
                "CAB-TA-JP-RA",
                "CAB-TA-EU",
                "C3850-FAN-T1-RF",
                "CAB-C15-CBN-JP",
                "WS-C3850E-24XU-E",
                "C1FACAT38504K9=",
                "CAB-TA-IN",
                "C1AUCAT38501K9=",
                "CAB-TA-EU=",
                "C1FUCAT38501K9",
                "WS-C3850-24XU-E-WS",
                "CAB-TA-UK",
                "FAN-T3-R=",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "LL-C3850-24XU-E=",
                "C1FACAT38501K9",
                "CAB-TA-JP-RA="
             ]
          },
          {  
             "mdfId":"284850400",
             "productName":"Cisco Catalyst 3650-48PD-L Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "WS-C3650-48PD",
                "WS-C3650-48FWD-S",
                "WS-C3650-48PD-L",
                "PWR-C2-640WAC=",
                "WS-C3650-48PD-L-RF",
                "L-C3650-ADD-12T=",
                "C1FACAT36502K9",
                "PWR-C2-640WAC",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WDC-RF",
                "C3650-48PD-L++",
                "PWR-C2-640WAC-RF",
                "C1-WS3650-48PD/K9",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "4PT-KIT-T1-RF",
                "C3650-RTU=",
                "C3560-NEBS-KIT=",
                "PWR-C2-250WAC-RF",
                "C1AUCAT36502K9",
                "STACK-T2-3M",
                "WS-C3650-48PD-L-WS",
                "C1-C3650-48-DNAA-O",
                "PWR-C2-640WDC",
                "NAL-C3650-48PD-L",
                "C1-C3650-48-DNAE-O",
                "C1AACAT36502K9",
                "C1AUCAT36502K9=",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "C1FUCAT36502K9",
                "C1FUCAT36502K9=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "C1AACAT36502K9=",
                "PWR-C2-640WAC-WS",
                "RACK-KIT-T1-RF",
                "WS-C3650-48FWDS-RF",
                "PWR-C2-1025WAC",
                "C1FACAT36502K9=",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "L-C3650-SLIC-U=",
                "EDU-C3650-48PD-L",
                "C1-WS3650-48XFD/K9",
                "RACK-KIT-T1-WS",
                "PWR-C2-250WAC-WS",
                "PWR-C2-640WDC-WS",
                "ACC-KIT-T1=",
                "WSC365012X48URS-WS",
                "4PT-KIT-T1=",
                "RACK-KIT-T1="
             ]
          },
          {  
             "mdfId":"284850616",
             "productName":"Cisco Catalyst 3650-48FD-S Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "WS-C3650-48FWD-S",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "C1FACAT36502K9",
                "C3650-48FD-S++",
                "PWR-C2-640WAC",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WDC-RF",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "C3560-NEBS-KIT=",
                "C3650-RTU=",
                "4PT-KIT-T1-RF",
                "PWR-C2-250WAC-RF",
                "C1AUCAT36502K9",
                "STACK-T2-3M",
                "C1-C3650-48-DNAA-O",
                "PWR-C2-640WDC",
                "C1-C3650-48-DNAE-O",
                "EDU-C3650-48FD-S",
                "C1AACAT36502K9",
                "C1-WS3650-48FD/K9",
                "NAL-C3650-48FWD-S",
                "WS-C3650-48FD-S-RF",
                "C1AUCAT36502K9=",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "NAL-C3650-48FD-S",
                "L-C3650-RTU=",
                "C1FUCAT36502K9=",
                "C1FUCAT36502K9",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "C1AACAT36502K9=",
                "WS-C3650-48FD",
                "PWR-C2-640WAC-WS",
                "WS-C3650-48FWDS-RF",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "C1FACAT36502K9=",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "WS-C3650-48FD-S-WS",
                "EDU-C3650-48FWD-S",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "WS-C3650-48FWDS-WS",
                "RACK-KIT-T1-WS",
                "PWR-C2-250WAC-WS",
                "PWR-C2-640WDC-WS",
                "WS-C3650-48FD-S",
                "ACC-KIT-T1=",
                "4PT-KIT-T1=",
                "WSC365012X48URS-WS",
                "RACK-KIT-T1="
             ]
          },
          {  
             "mdfId":"284846032",
             "productName":"Cisco Catalyst 3650-48PS-S Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "WS-C3650-48FWD-S",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "C1FACAT36502K9",
                "PWR-C2-640WAC",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WDC-RF",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "C3560-NEBS-KIT=",
                "4PT-KIT-T1-RF",
                "C3650-RTU=",
                "PWR-C2-250WAC-RF",
                "C1AUCAT36502K9",
                "STACK-T2-3M",
                "WS-C3650-48PS-S-WS",
                "C1-C3650-48-DNAA-O",
                "PWR-C2-640WDC",
                "C1-C3650-48-DNAE-O",
                "WS-C3650-48PS",
                "C1AACAT36502K9",
                "C1AUCAT36502K9=",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "NAL-C3650-48PS-S",
                "L-C3650-RTU=",
                "C1FUCAT36502K9=",
                "C1FUCAT36502K9",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "C1AACAT36502K9=",
                "WS-C3650-48PS-S-RF",
                "WS-C3650-48PWS-S",
                "WS-C3650-48FWDS-RF",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "C1FACAT36502K9=",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "C1-WS3650-48PS/K9",
                "C3650-48PS-S++",
                "WS-C3650-48PS-S",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "PWR-C2-250WAC-WS",
                "RACK-KIT-T1-WS",
                "PWR-C2-640WDC-WS",
                "4PT-KIT-T1=",
                "WSC365012X48URS-WS",
                "RACK-KIT-T1=",
                "ACC-KIT-T1="
             ]
          },
          {  
             "mdfId":"286289808",
             "productName":"Cisco Catalyst 3650-12X48UQ-L Switch",
             "productId":[  
                "PWR-C2-250WAC",
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "WSC365012X48UQL-WS",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-48FWD-S",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "WS-C3650-48FWDS-RF",
                "RACK-KIT-T1-RF",
                "C3650-12X48UQ-L++",
                "PWR-C2-640WAC",
                "L-C3650-ADD-12P=",
                "PWR-C2-1025WAC",
                "PWR-C2-640WDC-RF",
                "PWR-C2-640WAC-RF",
                "STACK-T2-3M=",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "C3560-NEBS-KIT=",
                "PWR-C2-BLANK=",
                "PWR-C2-250WAC-RF",
                "WSC365012X48UQL-RF",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "STACK-T2-3M",
                "PWR-C2-640WDC",
                "WS-C3650-12X48UQ-L",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "PWR-C2-250WAC-WS",
                "RACK-KIT-T1-WS",
                "C1-WS3650-48UQ/K9",
                "PWR-C2-640WDC-WS",
                "NAL-C3650-12X48UQL",
                "WSC365012X48URS-WS",
                "RACK-KIT-T1="
             ]
          },
          {  
             "mdfId":"284846125",
             "productName":"Cisco Catalyst 3650-48FS-E Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "NAL-C3650-48FS-E",
                "WS-C3650-48FWD-S",
                "PWR-C2-640WAC=",
                "WS-C3650-48FS-E-RF",
                "L-C3650-ADD-12T=",
                "C1FACAT36502K9",
                "PWR-C2-640WAC",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WDC-RF",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "C1-WS3650-48FS/K9",
                "C3560-NEBS-KIT=",
                "4PT-KIT-T1-RF",
                "C3650-RTU=",
                "PWR-C2-250WAC-RF",
                "C1AUCAT36502K9",
                "STACK-T2-3M",
                "C1-C3650-48-DNAA-O",
                "PWR-C2-640WDC",
                "C1-C3650-48-DNAE-O",
                "C1AACAT36502K9",
                "WS-C3650-48FS-E",
                "C1AUCAT36502K9=",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "C1FUCAT36502K9=",
                "C1FUCAT36502K9",
                "PWR-C2-1025WAC-RF",
                "C3650-48FS-E++",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "C1AACAT36502K9=",
                "WS-C3650-48FWDS-RF",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "C1FACAT36502K9=",
                "STACK-T2-3M=",
                "WS-C3650-48FS-E-WS",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "RACK-KIT-T1-WS",
                "PWR-C2-250WAC-WS",
                "PWR-C2-640WDC-WS",
                "WS-C3650-48FS",
                "WSC365012X48URS-WS",
                "ACC-KIT-T1=",
                "RACK-KIT-T1=",
                "4PT-KIT-T1="
             ]
          },
          {  
             "mdfId":"286290536",
             "productName":"Cisco Catalyst 3650-24PDM-E Switch",
             "productId":[  
                "WS-C3650-24PD-HC",
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "C1FUCAT36501K9",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "PWR-C2-640WDC-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "C3650-24PDM-E++",
                "C3650-RTU=",
                "C3560-NEBS-KIT=",
                "4PT-KIT-T1-RF",
                "PWR-C2-250WAC-RF",
                "WS-C3650-24PDM-E",
                "STACK-T2-3M",
                "C1FUCAT36501K9=",
                "PWR-C2-640WDC",
                "C1AUCAT36501K9=",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "C1FACAT36501K9",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "WS-C3650-24PDME-RF",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "C1AUCAT36501K9",
                "C1AACAT36501K9=",
                "C1FACAT36501K9=",
                "C1AACAT36501K9",
                "L-C3650-SLIC-U=",
                "WS-C3650-24PDM",
                "PWR-C2-250WAC-WS",
                "RACK-KIT-T1-WS",
                "PWR-C2-640WDC-WS",
                "RACK-KIT-T1=",
                "ACC-KIT-T1=",
                "4PT-KIT-T1=",
                "WSC365012X48URS-WS",
                "C1-WS3650-24XPD/K9"
             ]
          },
          {  
             "mdfId":"286290773",
             "productName":"Cisco Catalyst 3650-48FQM-S Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "WS-C3650-48FWD-S",
                "PWR-C2-640WAC=",
                "WS-C3650-48FQ-E-HC",
                "L-C3650-ADD-12T=",
                "C1FACAT36502K9",
                "PWR-C2-640WAC",
                "PWR-C2-640WDC-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "C3560-NEBS-KIT=",
                "C3650-RTU=",
                "C1WS365048FQ/K9-RF",
                "4PT-KIT-T1-RF",
                "PWR-C2-250WAC-RF",
                "C1AUCAT36502K9",
                "STACK-T2-3M",
                "WS-C3650-48FQM",
                "WS-C3650-48FQ-HC",
                "PWR-C2-640WDC",
                "C1AACAT36502K9",
                "WS-C3650-48FQM-S",
                "C1AUCAT36502K9=",
                "C3650-48FQM-S++",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "C1FUCAT36502K9",
                "C1FUCAT36502K9=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "C1AACAT36502K9=",
                "WS-C3650-48FWDS-RF",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "C1FACAT36502K9=",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "C1-WS3650-48FQ/K9",
                "STACK-T2-3M-WS",
                "WS-C3650-48FQMS-RF",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "WS-C3650-48FQMS-WS",
                "PWR-C2-1025WAC=",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "RACK-KIT-T1-WS",
                "PWR-C2-250WAC-WS",
                "PWR-C2-640WDC-WS",
                "WSC365012X48URS-WS",
                "4PT-KIT-T1=",
                "RACK-KIT-T1=",
                "ACC-KIT-T1="
             ]
          },
          {  
             "mdfId":"286290546",
             "productName":"Cisco Catalyst 3650-24PDM-S Switch",
             "productId":[  
                "WS-C3650-24PD-HC",
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "C1FUCAT36501K9",
                "C3650-24PDM-S++",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WDC-RF",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "WS-C3650-24PDM-S",
                "C3560-NEBS-KIT=",
                "4PT-KIT-T1-RF",
                "C3650-RTU=",
                "WS-C3650-24PDMS-RF",
                "PWR-C2-250WAC-RF",
                "STACK-T2-3M",
                "C1FUCAT36501K9=",
                "PWR-C2-640WDC",
                "C1AUCAT36501K9=",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "C1FACAT36501K9",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "C1AUCAT36501K9",
                "C1AACAT36501K9=",
                "C1FACAT36501K9=",
                "C1AACAT36501K9",
                "L-C3650-SLIC-U=",
                "WS-C3650-24PDM",
                "PWR-C2-250WAC-WS",
                "RACK-KIT-T1-WS",
                "PWR-C2-640WDC-WS",
                "C1-WS3650-24XPD/K9",
                "RACK-KIT-T1=",
                "4PT-KIT-T1=",
                "WSC365012X48URS-WS",
                "ACC-KIT-T1="
             ]
          },
          {  
             "mdfId":"284850336",
             "productName":"Cisco Catalyst 3650-24TD-L Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "C1-WS3650-24TD/K9",
                "C1FUCAT36501K9",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "WS-C3650-24TD-L-WS",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WDC-RF",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "4PT-KIT-T1-RF",
                "C3650-RTU=",
                "C3560-NEBS-KIT=",
                "C1-C3650-24-DNAA-O",
                "PWR-C2-250WAC-RF",
                "STACK-T2-3M",
                "C1FUCAT36501K9=",
                "WS-C3650-24TD-L",
                "PWR-C2-640WDC",
                "WS-C3650-24TD-L-RF",
                "C1-C3650-24-DNAE-O",
                "C1AUCAT36501K9=",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "C1FACAT36501K9",
                "RACK-KIT-T1-RF",
                "NAL-C3650-24TD-L",
                "PWR-C2-1025WAC",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "WS-C3650-24TD",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "C1AUCAT36501K9",
                "C1AACAT36501K9=",
                "C3650-24TD-L++",
                "C1FACAT36501K9=",
                "C1AACAT36501K9",
                "L-C3650-SLIC-U=",
                "PWR-C2-250WAC-WS",
                "RACK-KIT-T1-WS",
                "PWR-C2-640WDC-WS",
                "C1-WS3650-24XPD/K9",
                "ACC-KIT-T1=",
                "4PT-KIT-T1=",
                "RACK-KIT-T1=",
                "WSC365012X48URS-WS"
             ]
          },
          {  
             "mdfId":"284846029",
             "productName":"Cisco Catalyst 3650-24PS-S Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "WS-C3650-24PS-S-WS",
                "C1FUCAT36501K9",
                "WS-C3650-24PWSS-RF",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "PWR-C2-640WDC-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "C3650-24PS-S++",
                "C3560-NEBS-KIT=",
                "4PT-KIT-T1-RF",
                "C3650-RTU=",
                "C1-C3650-24-DNAA-O",
                "PWR-C2-250WAC-RF",
                "STACK-T2-3M",
                "NAL-C3650-24PS-S",
                "C1FUCAT36501K9=",
                "PWR-C2-640WDC",
                "C1-C3650-24-DNAE-O",
                "C1AUCAT36501K9=",
                "LL-C3650-24PS-S=",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "C1-WS3650-24PS/K9",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "C1FACAT36501K9",
                "WS-C3650-24PS-S-RF",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "WS-C3650-24PS-S",
                "WS-C3650-24PWSS-WS",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "C1AUCAT36501K9",
                "NAL-C3650-24PWS-S",
                "C1AACAT36501K9=",
                "WS-C3650-24PS",
                "WS-C3650-24PWS-S",
                "C1FACAT36501K9=",
                "C1AACAT36501K9",
                "L-C3650-SLIC-U=",
                "RACK-KIT-T1-WS",
                "PWR-C2-250WAC-WS",
                "PWR-C2-640WDC-WS",
                "4PT-KIT-T1=",
                "RACK-KIT-T1=",
                "C1-WS3650-24XPD/K9",
                "ACC-KIT-T1=",
                "WSC365012X48URS-WS"
             ]
          },
          {  
             "mdfId":"286285479",
             "productName":"Cisco Catalyst C3850-12X48U-E Switch",
             "productId":[  
                "FAN-T3-F=",
                "CAB-TA-TW",
                "CAB-TA-CN=",
                "C3850-RTU=",
                "PWR-C1-440WDC",
                "WS-C385012X48UE-RF",
                "CAB-AC-C15-KOR=",
                "CAB-TA-AR=",
                "C3850-4PT-KIT=",
                "C3850-SPWR-NONE",
                "PWR-C1-440WDC-RF",
                "CAB-TA-CN",
                "WS-C3850-12X48UW-S",
                "C3850-NEBS-KIT=",
                "WS-C3850-12X48U-E",
                "CAB-TA-250V-JP=",
                "CAB-AC-C15-TW",
                "CAB-TA-SW",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "WS-C3850-12X48U-EJ",
                "FAN-T3-F",
                "C3850-4PT-KIT-RF",
                "WSC385012X48UWS-WS",
                "CAB-AC-C15-KOR",
                "L-C3850-RTU=",
                "C3850-RACK-KIT-RF",
                "C3850-ACC-KIT=",
                "L-C3850-SLIC-U=",
                "FAN-T3-R",
                "CAB-TA-IN=",
                "CAB-TA-250V-JP",
                "CAB-C15-CBN-JP=",
                "CAB-AC-C15-TW=",
                "CAB-TA-DN=",
                "C1FACAT38503K9=",
                "CAB-TA-IT=",
                "C3850-FAN-T1-WS",
                "CAB-TA-AP=",
                "C3850-12X48U-E++",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "C1AACAT38503K9",
                "CAB-TA-UK=",
                "C3850-FAN-T1=",
                "CAB-TA-AR",
                "C3850-STACK-NONE",
                "CAB-TA-NA",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "WSC385012X48UWS-RF",
                "CAB-TA-SW=",
                "FAN-T3-F-WS",
                "PWR-C1-440WDC=",
                "CAB-TA-AP",
                "CAB-TA-JP-RA",
                "CAB-TA-EU",
                "C3850-FAN-T1-RF",
                "CAB-C15-CBN-JP",
                "CAB-TA-IN",
                "CAB-TA-EU=",
                "FAN-T3-R=",
                "CAB-TA-UK",
                "CAB-TA-DN",
                "C1FACAT38503K9",
                "C3850-RACK-KIT=",
                "LL-C3850-12X48U-E=",
                "WS-C385012X48UE-WS",
                "C1AACAT38503K9=",
                "CAB-TA-JP-RA="
             ]
          },
          {  
             "mdfId":"284455435",
             "productName":"Cisco Catalyst 3850-24P-E Switch",
             "productId":[  
                "FAN-T3-F=",
                "C3850-NM-4-1G",
                "C1FACAT38501K9=",
                "C3850-RTU=",
                "CAB-TA-CN=",
                "PWR-C1-440WDC",
                "WS-C3850-24P-E-WS",
                "CAB-TA-AR=",
                "C3850-4PT-KIT=",
                "C3850-SPWR-NONE",
                "CAB-SPWR-150CM-WS",
                "PWR-C1-440WDC-RF",
                "C3850-NEBS-KIT=",
                "C1-C3850-24-DNAE-O",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "PWR-C3-750WAC-R/2",
                "FAN-T3-F",
                "CAB-AC-C15-KOR",
                "L-C3850-RTU=",
                "PWR-C1-1100WAC=",
                "C3850-NM-4-1G-RF",
                "C1FACAT38504K9",
                "C1AACAT38501K9=",
                "STACK-T1-50CM",
                "FAN-T3-R",
                "C1-C3850-24-DNAA-O",
                "CAB-SPWR-30CM=",
                "CAB-C15-CBN-JP=",
                "C1AACAT38501K9",
                "CAB-AC-C15-TW=",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "C3850-FAN-T1-WS",
                "CAB-TA-UK=",
                "C3850-STACK-NONE",
                "PWR-C1-1100WAC-WS",
                "CAB-TA-NA",
                "C1AACAT38504K9=",
                "STACK-T1-3M=",
                "PWR-C3-750WAC-F/2",
                "FAN-T3-F-WS",
                "CAB-TA-SW=",
                "CAB-TA-JP-RA",
                "CAB-TA-EU",
                "CAB-C15-CBN-JP",
                "C1FACAT38504K9=",
                "WS-C3850-24P-E-RL",
                "PWR-C1-715WAC-WS",
                "C1AUCAT38501K9=",
                "CAB-TA-EU=",
                "WS-C3850-24P-E-RF",
                "FAN-T3-R=",
                "CAB-TA-UK",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "LL-C3850-24P-E=",
                "CAB-TA-JP-RA=",
                "STACK-T1-50CM-RF",
                "C3850-NM-4-1G-WS",
                "CAB-TA-TW",
                "CAB-SPWR-150CM=",
                "STACK-T1-1M",
                "WS-C3850-24P-E-BR",
                "CAB-AC-C15-KOR=",
                "C1AACAT38504K9",
                "C3850-NM-4-1G=",
                "CAB-TA-CN",
                "C1FUCAT38501K9=",
                "PWR-C3-750WDC-F/2",
                "STACK-T1-1M-WS",
                "C1AUCAT38501K9",
                "CAB-TA-250V-JP=",
                "CAB-AC-C15-TW",
                "PWR-C3-750WDC-R/2",
                "CAB-TA-SW",
                "STACK-T1-3M-RF",
                "C3850-4PT-KIT-RF",
                "C3850-RACK-KIT-RF",
                "C3850-ACC-KIT=",
                "PWR-C1-1100WAC-RF",
                "L-C3850-SLIC-U=",
                "WS-C3850-24P-E",
                "PWR-C1-1100WAC",
                "CAB-TA-IN=",
                "CAB-TA-JP=",
                "CAB-TA-250V-JP",
                "PWR-C1-715WAC-RF",
                "CAB-TA-DN=",
                "CAB-SPWR-30CM",
                "CAB-TA-JP",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "CAB-TA-AR",
                "CAB-SPWR-30CM-RF",
                "STACK-T1-1M=",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "WS-C3850-24P-E++",
                "PWR-C1-440WDC=",
                "CAB-TA-AP",
                "C3850-FAN-T1-RF",
                "CAB-TA-IN",
                "CAB-SPWR-150CM-RF",
                "PWR-C1-715WAC",
                "WS-C3850-24P",
                "C1FUCAT38501K9",
                "CAB-SPWR-150CM",
                "C1FACAT38501K9",
                "UA-C3850-24P-E"
             ]
          },
          {  
             "mdfId":"284846013",
             "productName":"Cisco Catalyst 3650-48TS-L Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "WS-C3650-48FWD-S",
                "PWR-C2-640WAC=",
                "C1FACAT36502K9",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WDC-RF",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "C3650-RTU=",
                "C3560-NEBS-KIT=",
                "4PT-KIT-T1-RF",
                "C1-WS3650-48TS/K9",
                "PWR-C2-250WAC-RF",
                "C1AUCAT36502K9",
                "STACK-T2-3M",
                "WS-C3650-48TS-L-RF",
                "C1-C3650-48-DNAA-O",
                "PWR-C2-640WDC",
                "WS-C3650-48TS",
                "C1-C3650-48-DNAE-O",
                "C1AACAT36502K9",
                "C1AUCAT36502K9=",
                "PWR-C2-250WAC",
                "WS-C3650-48TS-L-WS",
                "NAL-C3650-48TS-L",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "C1FUCAT36502K9=",
                "C1FUCAT36502K9",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "C1AACAT36502K9=",
                "WS-C3650-48FWDS-RF",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "C1FACAT36502K9=",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "C3650-48TS-L++",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "RACK-KIT-T1-WS",
                "PWR-C2-250WAC-WS",
                "PWR-C2-640WDC-WS",
                "WS-C3650-48TS-L",
                "ACC-KIT-T1=",
                "WSC365012X48URS-WS",
                "4PT-KIT-T1=",
                "RACK-KIT-T1="
             ]
          },
          {  
             "mdfId":"286289929",
             "productName":"Cisco Catalyst 3650-12X48UZ-L Switch",
             "productId":[  
                "PWR-C2-250WAC",
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "C3650-12X48UZ-L++",
                "NAL-C3650-12X48UZL",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "WS-C3650-48FWD-S",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "PWR-C2-640WAC=",
                "WS-C3650-12X48UZ-L",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "WS-C3650-48FWDS-RF",
                "RACK-KIT-T1-RF",
                "PWR-C2-640WDC-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-1025WAC",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "STACK-T2-3M=",
                "WSC365012X48URS-RF",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "WSC365012X48UZL-WS",
                "C3560-NEBS-KIT=",
                "PWR-C2-BLANK=",
                "PWR-C2-250WAC-RF",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "STACK-T2-3M",
                "PWR-C2-640WDC",
                "WSC365012X48UZL-RF",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "RACK-KIT-T1-WS",
                "PWR-C2-250WAC-WS",
                "PWR-C2-640WDC-WS",
                "RACK-KIT-T1=",
                "WSC365012X48URS-WS"
             ]
          },
          {  
             "mdfId":"284850617",
             "productName":"Cisco Catalyst 3650-24TD-E Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "WS-C3650-24TD-E-WS",
                "C1-WS3650-24TD/K9",
                "C1FUCAT36501K9",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "PWR-C2-640WDC-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "4PT-KIT-T1-RF",
                "C3560-NEBS-KIT=",
                "C3650-RTU=",
                "C1-C3650-24-DNAA-O",
                "PWR-C2-250WAC-RF",
                "STACK-T2-3M",
                "C1FUCAT36501K9=",
                "PWR-C2-640WDC",
                "WS-C3650-24TD-E",
                "C3650-24TD-E++",
                "C1-C3650-24-DNAE-O",
                "C1AUCAT36501K9=",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "NAL-C3650-24TD-E",
                "C1FACAT36501K9",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "WS-C3650-24TD",
                "WS-C3650-24TD-E-RF",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "C1AUCAT36501K9",
                "C1AACAT36501K9=",
                "C1FACAT36501K9=",
                "C1AACAT36501K9",
                "L-C3650-SLIC-U=",
                "PWR-C2-250WAC-WS",
                "RACK-KIT-T1-WS",
                "PWR-C2-640WDC-WS",
                "WSC365012X48URS-WS",
                "C1-WS3650-24XPD/K9",
                "4PT-KIT-T1=",
                "RACK-KIT-T1=",
                "ACC-KIT-T1="
             ]
          },
          {  
             "mdfId":"284455316",
             "productName":"Cisco Catalyst 3850-24P-L Switch",
             "productId":[  
                "FAN-T3-F=",
                "C1FACAT38501K9=",
                "C3850-NM-4-1G",
                "WS-C3850-24P-L++",
                "CAB-TA-CN=",
                "C3850-RTU=",
                "PWR-C1-440WDC",
                "CAB-TA-AR=",
                "C3850-4PT-KIT=",
                "WS-C3850-24P-L-RL",
                "C3850-SPWR-NONE",
                "CAB-SPWR-150CM-WS",
                "WS-C3850-24P-L-RF",
                "PWR-C1-440WDC-RF",
                "C3850-NEBS-KIT=",
                "C1-C3850-24-DNAE-O",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "PWR-C3-750WAC-R/2",
                "FAN-T3-F",
                "L-C3850-RTU=",
                "WS-C3850-24P-L-BR",
                "PWR-C1-1100WAC=",
                "CAB-AC-C15-KOR",
                "C3850-NM-4-1G-RF",
                "C1FACAT38504K9",
                "FAN-T3-R",
                "C1AACAT38501K9=",
                "STACK-T1-50CM",
                "CAB-SPWR-30CM=",
                "C1-C3850-24-DNAA-O",
                "CAB-C15-CBN-JP=",
                "C1AACAT38501K9",
                "CAB-AC-C15-TW=",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "C3850-FAN-T1-WS",
                "CAB-TA-UK=",
                "C3850-STACK-NONE",
                "CAB-TA-NA",
                "PWR-C1-1100WAC-WS",
                "LL-C3850-24P-L=",
                "C1AACAT38504K9=",
                "STACK-T1-3M=",
                "PWR-C3-750WAC-F/2",
                "FAN-T3-F-WS",
                "CAB-TA-SW=",
                "CAB-TA-JP-RA",
                "CAB-TA-EU",
                "CAB-C15-CBN-JP",
                "C1FACAT38504K9=",
                "PWR-C1-715WAC-WS",
                "C1AUCAT38501K9=",
                "CAB-TA-EU=",
                "CAB-TA-UK",
                "FAN-T3-R=",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "CAB-TA-JP-RA=",
                "STACK-T1-50CM-RF",
                "C3850-NM-4-1G-WS",
                "CAB-TA-TW",
                "CAB-SPWR-150CM=",
                "STACK-T1-1M",
                "CAB-AC-C15-KOR=",
                "C1AACAT38504K9",
                "C3850-NM-4-1G=",
                "CAB-TA-CN",
                "PWR-C3-750WDC-F/2",
                "C1FUCAT38501K9=",
                "STACK-T1-1M-WS",
                "CAB-TA-250V-JP=",
                "C1AUCAT38501K9",
                "CAB-AC-C15-TW",
                "PWR-C3-750WDC-R/2",
                "CAB-TA-SW",
                "WS-C3850-24P-L",
                "STACK-T1-3M-RF",
                "EDU-C3850-24P-L",
                "WS-C3850-24P-L-WS",
                "C3850-4PT-KIT-RF",
                "C3850-RACK-KIT-RF",
                "L-C3850-SLIC-U=",
                "C3850-ACC-KIT=",
                "PWR-C1-1100WAC-RF",
                "PWR-C1-1100WAC",
                "CAB-TA-IN=",
                "CAB-TA-JP=",
                "CAB-TA-250V-JP",
                "PWR-C1-715WAC-RF",
                "CAB-SPWR-30CM",
                "CAB-TA-DN=",
                "CAB-TA-JP",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "CAB-TA-AR",
                "CAB-SPWR-30CM-RF",
                "STACK-T1-1M=",
                "C1-WS3850-24P/K9",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "CAB-TA-AP",
                "PWR-C1-440WDC=",
                "C3850-FAN-T1-RF",
                "CAB-TA-IN",
                "CAB-SPWR-150CM-RF",
                "PWR-C1-715WAC",
                "WS-C3850-24P",
                "C1FUCAT38501K9",
                "CAB-SPWR-150CM",
                "C1FACAT38501K9"
             ]
          },
          {  
             "mdfId":"284846093",
             "productName":"Cisco Catalyst 3650-24TS-E Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "WS-C3650-24TD-E-WS",
                "C1FUCAT36501K9",
                "C1-WS3650-24TS/K9",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WDC-RF",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "4PT-KIT-T1-RF",
                "C3650-RTU=",
                "C3560-NEBS-KIT=",
                "NAL-C3650-24TS-E",
                "C1-C3650-24-DNAA-O",
                "PWR-C2-250WAC-RF",
                "C3650-24TS-E++",
                "STACK-T2-3M",
                "C1FUCAT36501K9=",
                "PWR-C2-640WDC",
                "WS-C3650-24TD-E",
                "C1-C3650-24-DNAE-O",
                "C1AUCAT36501K9=",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "WS-C3650-24TS-E-WS",
                "WS-C3650-24TS",
                "C1FACAT36501K9",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "WS-C3650-24TD-E-RF",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "C1AUCAT36501K9",
                "WS-C3650-24TS-E",
                "C1AACAT36501K9=",
                "C1FACAT36501K9=",
                "C1AACAT36501K9",
                "WS-C3650-24TS-E-RF",
                "L-C3650-SLIC-U=",
                "RACK-KIT-T1-WS",
                "PWR-C2-250WAC-WS",
                "PWR-C2-640WDC-WS",
                "ACC-KIT-T1=",
                "4PT-KIT-T1=",
                "WSC365012X48URS-WS",
                "RACK-KIT-T1="
             ]
          },
          {  
             "mdfId":"284850603",
             "productName":"Cisco Catalyst 3650-48TD-S Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "WS-C3650-48TD",
                "PWR-C2-250WAC=",
                "WS-C3650-48FWD-S",
                "PWR-C2-640WAC=",
                "C1FACAT36502K9",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "WS-C3650-48TD-S-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WDC-RF",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "4PT-KIT-T1-RF",
                "C3560-NEBS-KIT=",
                "C3650-RTU=",
                "PWR-C2-250WAC-RF",
                "C1AUCAT36502K9",
                "STACK-T2-3M",
                "C1-C3650-48-DNAA-O",
                "PWR-C2-640WDC",
                "C1-C3650-48-DNAE-O",
                "C1AACAT36502K9",
                "WS-C3650-48TD-S",
                "NAL-C3650-48TD-S",
                "C1AUCAT36502K9=",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "C1FUCAT36502K9",
                "C1FUCAT36502K9=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "C1AACAT36502K9=",
                "RACK-KIT-T1-RF",
                "WS-C3650-48FWDS-RF",
                "PWR-C2-1025WAC",
                "C1FACAT36502K9=",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "C1-WS3650-48TD/K9",
                "WS-C3650-48TD-S-WS",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "RACK-KIT-T1-WS",
                "PWR-C2-250WAC-WS",
                "PWR-C2-640WDC-WS",
                "4PT-KIT-T1=",
                "RACK-KIT-T1=",
                "WSC365012X48URS-WS",
                "ACC-KIT-T1="
             ]
          },
          {  
             "mdfId":"286290556",
             "productName":"Cisco Catalyst 3650-24PDM-L Switch",
             "productId":[  
                "WS-C3650-24PD-HC",
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "WS-C3650-24PDML-WS",
                "C1FUCAT36501K9",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "PWR-C2-640WDC-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "C3560-NEBS-KIT=",
                "4PT-KIT-T1-RF",
                "C3650-RTU=",
                "PWR-C2-250WAC-RF",
                "STACK-T2-3M",
                "C1FUCAT36501K9=",
                "WS-C3650-24PDM-L",
                "PWR-C2-640WDC",
                "C1AUCAT36501K9=",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "C3650-24PDM-L++",
                "C1FACAT36501K9",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "C1AUCAT36501K9",
                "C1AACAT36501K9=",
                "C1FACAT36501K9=",
                "C1AACAT36501K9",
                "L-C3650-SLIC-U=",
                "WS-C3650-24PDM",
                "RACK-KIT-T1-WS",
                "PWR-C2-250WAC-WS",
                "PWR-C2-640WDC-WS",
                "WS-C3650-24PDML-RF",
                "ACC-KIT-T1=",
                "4PT-KIT-T1=",
                "RACK-KIT-T1=",
                "WSC365012X48URS-WS",
                "C1-WS3650-24XPD/K9"
             ]
          },
          {  
             "mdfId":"284846105",
             "productName":"Cisco Catalyst 3650-48TS-E Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "WS-C3650-48TS-E-RF",
                "WS-C3650-48FWD-S",
                "PWR-C2-640WAC=",
                "C1FACAT36502K9",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "PWR-C2-640WDC-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "C3650-RTU=",
                "4PT-KIT-T1-RF",
                "C3560-NEBS-KIT=",
                "C1-WS3650-48TS/K9",
                "PWR-C2-250WAC-RF",
                "C1AUCAT36502K9",
                "STACK-T2-3M",
                "C1-C3650-48-DNAA-O",
                "PWR-C2-640WDC",
                "WS-C3650-48TS",
                "C1-C3650-48-DNAE-O",
                "C1AACAT36502K9",
                "C3650-48TS-E++",
                "WS-C3650-48TS-E-WS",
                "C1AUCAT36502K9=",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "C1FUCAT36502K9",
                "C1FUCAT36502K9=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "C1AACAT36502K9=",
                "PWR-C2-640WAC-WS",
                "WS-C3650-48FWDS-RF",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "C1FACAT36502K9=",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "WS-C3650-48TS-E",
                "RACK-KIT-T1-WS",
                "PWR-C2-250WAC-WS",
                "NAL-C3650-48TS-E",
                "PWR-C2-640WDC-WS",
                "RACK-KIT-T1=",
                "WSC365012X48URS-WS",
                "ACC-KIT-T1=",
                "4PT-KIT-T1="
             ]
          },
          {  
             "mdfId":"286285469",
             "productName":"Cisco Catalyst C3850-12X48U-S Switch",
             "productId":[  
                "FAN-T3-F=",
                "CAB-TA-TW",
                "C3850-RTU=",
                "CAB-TA-CN=",
                "PWR-C1-440WDC",
                "WS-C3850-12X48U-S",
                "CAB-AC-C15-KOR=",
                "C3850-4PT-KIT=",
                "CAB-TA-AR=",
                "C3850-SPWR-NONE",
                "PWR-C1-440WDC-RF",
                "CAB-TA-CN",
                "WS-C3850-12X48UW-S",
                "C3850-NEBS-KIT=",
                "CAB-TA-250V-JP=",
                "CAB-AC-C15-TW",
                "CAB-TA-IS=",
                "CAB-TA-SW",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "LL-C3850-12X48U-S=",
                "FAN-T3-F",
                "C3850-4PT-KIT-RF",
                "WSC385012X48UWS-WS",
                "L-C3850-RTU=",
                "CAB-AC-C15-KOR",
                "C3850-RACK-KIT-RF",
                "WS-C385012X48US-WS",
                "L-C3850-SLIC-U=",
                "C3850-ACC-KIT=",
                "FAN-T3-R",
                "CAB-TA-IN=",
                "C3850-12X48U-S++",
                "CAB-C15-CBN-JP=",
                "CAB-TA-250V-JP",
                "CAB-AC-C15-TW=",
                "CAB-TA-DN=",
                "C1FACAT38503K9=",
                "CAB-TA-IT=",
                "CAB-TA-AP=",
                "C3850-FAN-T1-WS",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "C1AACAT38503K9",
                "CAB-TA-UK=",
                "CAB-TA-AR",
                "C3850-STACK-NONE",
                "CAB-TA-NA",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "WSC385012X48UWS-RF",
                "CAB-TA-SW=",
                "FAN-T3-F-WS",
                "CAB-TA-AP",
                "PWR-C1-440WDC=",
                "CAB-TA-JP-RA",
                "CAB-TA-EU",
                "C3850-FAN-T1-RF",
                "CAB-C15-CBN-JP",
                "CAB-TA-IN",
                "CAB-TA-EU=",
                "WS-C385012X48US-RF",
                "CAB-TA-UK",
                "FAN-T3-R=",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "C1FACAT38503K9",
                "WS-C3850-12X48U-SJ",
                "C1AACAT38503K9=",
                "CAB-TA-JP-RA="
             ]
          },
          {  
             "mdfId":"286257123",
             "productName":"Cisco Catalyst 3850-12S-S Switch",
             "productId":[  
                "FAN-T3-F=",
                "C3850-NM-4-1G",
                "CAB-TA-CN=",
                "C3850-RTU=",
                "PWR-C1-440WDC",
                "C3850-4PT-KIT=",
                "CAB-TA-AR=",
                "C3850-SPWR-NONE",
                "CAB-SPWR-150CM-WS",
                "PWR-C1-440WDC-RF",
                "C3850-NEBS-KIT=",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "PWR-C3-750WAC-R/2",
                "FAN-T3-F",
                "L-C3850-RTU=",
                "PWR-C1-1100WAC=",
                "CAB-AC-C15-KOR",
                "C3850-NM-4-1G-RF",
                "STACK-T1-50CM",
                "FAN-T3-R",
                "CAB-SPWR-30CM=",
                "CAB-C15-CBN-JP=",
                "CAB-AC-C15-TW=",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "C3850-FAN-T1-WS",
                "CAB-TA-UK=",
                "C3850-STACK-NONE",
                "PWR-C1-1100WAC-WS",
                "CAB-TA-NA",
                "PWR-C3-750WAC-F/2",
                "STACK-T1-3M=",
                "FAN-T3-F-WS",
                "CAB-TA-SW=",
                "WS-C3850-12S-S-WS",
                "CAB-TA-JP-RA",
                "CAB-TA-EU",
                "NAL-3850-12S-S",
                "CAB-C15-CBN-JP",
                "C1-C3850-12-DNAA-O",
                "PWR-C1-715WAC-WS",
                "CAB-TA-EU=",
                "C1-WS3850-12S/K9",
                "CAB-TA-UK",
                "FAN-T3-R=",
                "CAB-TA-DN",
                "C1FACAT38503K9",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "C1-C3850-12-DNAE-O",
                "CAB-TA-JP-RA=",
                "C3850-NM-4-1G-WS",
                "STACK-T1-50CM-RF",
                "CAB-SPWR-150CM=",
                "CAB-TA-TW",
                "STACK-T1-1M",
                "CAB-AC-C15-KOR=",
                "C3850-NM-4-1G=",
                "CAB-TA-CN",
                "PWR-C3-750WDC-F/2",
                "STACK-T1-1M-WS",
                "CAB-TA-250V-JP=",
                "CAB-AC-C15-TW",
                "PWR-C3-750WDC-R/2",
                "CAB-TA-SW",
                "STACK-T1-3M-RF",
                "C3850-4PT-KIT-RF",
                "C3850-RACK-KIT-RF",
                "WS-C3850-12S-S-RF",
                "L-C3850-SLIC-U=",
                "PWR-C1-1100WAC-RF",
                "C3850-ACC-KIT=",
                "WS-C3850-12S-S",
                "PWR-C1-1100WAC",
                "CAB-TA-IN=",
                "CAB-TA-250V-JP",
                "PWR-C1-715WAC-RF",
                "C1FACAT38503K9=",
                "CAB-SPWR-30CM",
                "CAB-TA-DN=",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "C1AACAT38503K9",
                "CAB-TA-AR",
                "STACK-T1-1M=",
                "CAB-SPWR-30CM-RF",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "LL-C3850-12S-S=",
                "PWR-C1-440WDC=",
                "CAB-TA-AP",
                "C3850-FAN-T1-RF",
                "CAB-TA-IN",
                "CAB-SPWR-150CM-RF",
                "PWR-C1-715WAC",
                "WS-C3850-12S",
                "C3850-12S-S++",
                "CAB-SPWR-150CM",
                "C1AACAT38503K9="
             ]
          },
          {  
             "mdfId":"286280237",
             "productName":"Cisco Catalyst 3850-12XS-S Switch",
             "productId":[  
                "STACK-T1-50CM-RF",
                "FAN-T3-F=",
                "CAB-TA-TW",
                "CAB-TA-CN=",
                "C3850-RTU=",
                "PWR-C1-440WDC",
                "STACK-T1-1M",
                "C3850-12XS-S++",
                "CAB-AC-C15-KOR=",
                "C3850-4PT-KIT=",
                "CAB-TA-AR=",
                "C3850-SPWR-NONE",
                "PWR-C1-440WDC-RF",
                "WS-C3850-12XS-S-RF",
                "CAB-TA-CN",
                "STACK-T1-1M-WS",
                "C3850-NEBS-KIT=",
                "CAB-TA-250V-JP=",
                "CAB-AC-C15-TW",
                "CAB-TA-SW",
                "CAB-TA-IS=",
                "STACK-T1-3M-RF",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "LL-C3850-12XS-S=",
                "STACK-T1-50CM-WS",
                "FAN-T3-F",
                "WS-C3850-12XS-S-WS",
                "C3850-4PT-KIT-RF",
                "PWR-C1-1100WAC=",
                "CAB-AC-C15-KOR",
                "L-C3850-RTU=",
                "C3850-RACK-KIT-RF",
                "L-C3850-SLIC-U=",
                "C3850-ACC-KIT=",
                "PWR-C1-1100WAC-RF",
                "FAN-T3-R",
                "STACK-T1-50CM",
                "PWR-C1-1100WAC",
                "CAB-TA-IN=",
                "CAB-C15-CBN-JP=",
                "CAB-TA-250V-JP",
                "PWR-C1-715WAC-RF",
                "CAB-AC-C15-TW=",
                "CAB-TA-DN=",
                "C1FACAT38503K9=",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "STACK-T1-3M",
                "C3850-FAN-T1-WS",
                "CAB-TA-AP=",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "CAB-TA-UK=",
                "C1AACAT38503K9",
                "CAB-TA-AR",
                "C3850-STACK-NONE",
                "PWR-C1-1100WAC-WS",
                "CAB-TA-NA",
                "STACK-T1-1M=",
                "CAB-TA-IS",
                "STACK-T1-3M=",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "CAB-TA-SW=",
                "FAN-T3-F-WS",
                "PWR-C1-440WDC=",
                "CAB-TA-AP",
                "CAB-TA-EU",
                "CAB-TA-JP-RA",
                "CAB-C15-CBN-JP",
                "C3850-FAN-T1-RF",
                "WS-C3850-12XS",
                "CAB-TA-IN",
                "PWR-C1-715WAC",
                "PWR-C1-715WAC-WS",
                "CAB-TA-EU=",
                "CAB-TA-UK",
                "FAN-T3-R=",
                "C1-WS3850-12XS-S",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "C1FACAT38503K9",
                "STACK-T1-3M-WS",
                "WS-C3850-12XS-S",
                "C1AACAT38503K9=",
                "CAB-TA-JP-RA="
             ]
          },
          {  
             "mdfId":"286280258",
             "productName":"Cisco Catalyst 3850-16XS-S Switch",
             "productId":[  
                "STACK-T1-50CM-RF",
                "FAN-T3-F=",
                "CAB-TA-TW",
                "CAB-TA-CN=",
                "C3850-RTU=",
                "STACK-T1-1M",
                "PWR-C1-440WDC",
                "CAB-AC-C15-KOR=",
                "WS-C3850-16XS-S-WS",
                "CAB-TA-AR=",
                "C3850-4PT-KIT=",
                "C3850-SPWR-NONE",
                "PWR-C1-440WDC-RF",
                "CAB-TA-CN",
                "STACK-T1-1M-WS",
                "C3850-NEBS-KIT=",
                "CAB-TA-250V-JP=",
                "CAB-AC-C15-TW",
                "WS-C3850-16XS-S-RF",
                "CAB-TA-SW",
                "CAB-TA-IS=",
                "STACK-T1-3M-RF",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "FAN-T3-F",
                "C3850-4PT-KIT-RF",
                "C3850-16XS-S++",
                "CAB-AC-C15-KOR",
                "L-C3850-RTU=",
                "PWR-C1-1100WAC=",
                "C3850-RACK-KIT-RF",
                "C3850-ACC-KIT=",
                "L-C3850-SLIC-U=",
                "PWR-C1-1100WAC-RF",
                "WS-C3850-16XS-S",
                "FAN-T3-R",
                "STACK-T1-50CM",
                "PWR-C1-1100WAC",
                "CAB-TA-IN=",
                "CAB-TA-250V-JP",
                "CAB-C15-CBN-JP=",
                "PWR-C1-715WAC-RF",
                "CAB-AC-C15-TW=",
                "CAB-TA-DN=",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "C3850-FAN-T1-WS",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "CAB-TA-UK=",
                "CAB-TA-AR",
                "C3850-STACK-NONE",
                "CAB-TA-NA",
                "PWR-C1-1100WAC-WS",
                "STACK-T1-1M=",
                "STACK-T1-3M=",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "FAN-T3-F-WS",
                "CAB-TA-SW=",
                "PWR-C1-440WDC=",
                "CAB-TA-AP",
                "CAB-TA-EU",
                "CAB-TA-JP-RA",
                "C3850-FAN-T1-RF",
                "CAB-C15-CBN-JP",
                "CAB-TA-IN",
                "PWR-C1-715WAC",
                "PWR-C1-715WAC-WS",
                "CAB-TA-EU=",
                "FAN-T3-R=",
                "CAB-TA-UK",
                "LL-C3850-16XS-S=",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "CAB-TA-JP-RA="
             ]
          },
          {  
             "mdfId":"284850668",
             "productName":"Cisco Catalyst 3650-48FQ-L Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "WS-C3650-48FWD-S",
                "NAL-C3650-48FQ-L",
                "PWR-C2-640WAC=",
                "WS-C3650-48FQ-E-HC",
                "C1FACAT36502K9",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WDC-RF",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "C3560-NEBS-KIT=",
                "C3650-RTU=",
                "4PT-KIT-T1-RF",
                "C1WS365048FQ/K9-RF",
                "PWR-C2-250WAC-RF",
                "C1AUCAT36502K9",
                "STACK-T2-3M",
                "C3650-48FQ-L++",
                "WS-C3650-48FQ-HC",
                "C1-C3650-48-DNAA-O",
                "WS-C3650-48FQ-L",
                "PWR-C2-640WDC",
                "C1-C3650-48-DNAE-O",
                "C1AACAT36502K9",
                "C1AUCAT36502K9=",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "C1FUCAT36502K9=",
                "C1FUCAT36502K9",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "C1AACAT36502K9=",
                "PWR-C2-640WAC-WS",
                "WS-C3650-48FWDS-RF",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "C1FACAT36502K9=",
                "STACK-T2-3M=",
                "WS-C3650-48FQ-L-RF",
                "PWR-C2-BLANK",
                "C1-WS3650-48FQ/K9",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "WS-C3650-48FQ-L-WS",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "PWR-C2-250WAC-WS",
                "RACK-KIT-T1-WS",
                "PWR-C2-640WDC-WS",
                "RACK-KIT-T1=",
                "4PT-KIT-T1=",
                "WSC365012X48URS-WS",
                "ACC-KIT-T1="
             ]
          },
          {  
             "mdfId":"284455427",
             "productName":"Cisco Catalyst 3850-24P-S Switch",
             "productId":[  
                "FAN-T3-F=",
                "C1FACAT38501K9=",
                "C3850-NM-4-1G",
                "CAB-TA-CN=",
                "C3850-RTU=",
                "PWR-C1-440WDC",
                "C3850-4PT-KIT=",
                "CAB-TA-AR=",
                "C3850-SPWR-NONE",
                "UA-C3850-24P-S",
                "CAB-SPWR-150CM-WS",
                "PWR-C1-440WDC-RF",
                "C3850-NEBS-KIT=",
                "C1-C3850-24-DNAE-O",
                "CAB-TA-IS=",
                "C3850-FAN-T1",
                "FAN-T3-F-RF",
                "STACK-T1-50CM-WS",
                "PWR-C3-750WAC-R/2",
                "FAN-T3-F",
                "L-C3850-RTU=",
                "PWR-C1-1100WAC=",
                "CAB-AC-C15-KOR",
                "C3850-NM-4-1G-RF",
                "C1FACAT38504K9",
                "STACK-T1-50CM",
                "FAN-T3-R",
                "C1AACAT38501K9=",
                "C1-C3850-24-DNAA-O",
                "CAB-SPWR-30CM=",
                "CAB-C15-CBN-JP=",
                "CAB-AC-C15-TW=",
                "C1AACAT38501K9",
                "CAB-TA-IT=",
                "STACK-T1-1M-RF",
                "C3850-FAN-T1-WS",
                "WS-C3850-24P-S++",
                "CAB-TA-UK=",
                "C3850-STACK-NONE",
                "PWR-C1-1100WAC-WS",
                "CAB-TA-NA",
                "C1AACAT38504K9=",
                "PWR-C3-750WAC-F/2",
                "STACK-T1-3M=",
                "FAN-T3-F-WS",
                "CAB-TA-SW=",
                "CAB-TA-EU",
                "CAB-TA-JP-RA",
                "CAB-C15-CBN-JP",
                "C1FACAT38504K9=",
                "PWR-C1-715WAC-WS",
                "C1AUCAT38501K9=",
                "CAB-TA-EU=",
                "WS-C3850-24P-S-WS",
                "CAB-TA-UK",
                "FAN-T3-R=",
                "CAB-TA-DN",
                "C3850-RACK-KIT=",
                "STACK-T1-3M-WS",
                "CAB-TA-JP-RA=",
                "C3850-NM-4-1G-WS",
                "STACK-T1-50CM-RF",
                "CAB-SPWR-150CM=",
                "CAB-TA-TW",
                "STACK-T1-1M",
                "CAB-AC-C15-KOR=",
                "WS-C3850-24P-S-RF",
                "C1AACAT38504K9",
                "C3850-NM-4-1G=",
                "CAB-TA-CN",
                "C1FUCAT38501K9=",
                "PWR-C3-750WDC-F/2",
                "STACK-T1-1M-WS",
                "C1AUCAT38501K9",
                "CAB-TA-250V-JP=",
                "CAB-AC-C15-TW",
                "PWR-C3-750WDC-R/2",
                "CAB-TA-SW",
                "STACK-T1-3M-RF",
                "WS-C3850-24P-S",
                "LL-C3850-24P-S=",
                "C3850-4PT-KIT-RF",
                "C3850-RACK-KIT-RF",
                "L-C3850-SLIC-U=",
                "PWR-C1-1100WAC-RF",
                "C3850-ACC-KIT=",
                "EDU-C3850-24P-S",
                "PWR-C1-1100WAC",
                "CAB-TA-IN=",
                "CAB-TA-JP=",
                "CAB-TA-250V-JP",
                "PWR-C1-715WAC-RF",
                "CAB-TA-DN=",
                "CAB-SPWR-30CM",
                "CAB-TA-JP",
                "STACK-T1-3M",
                "CAB-TA-AP=",
                "STACK-T1-50CM=",
                "C3850-4PT-KIT-WS",
                "CAB-TA-NA=",
                "PWR-C1-440WDC-WS",
                "C3850-FAN-T1=",
                "CAB-TA-AR",
                "CAB-SPWR-30CM-RF",
                "STACK-T1-1M=",
                "CAB-TA-IS",
                "CAB-TA-IT",
                "PWR-C1-715WAC=",
                "CAB-TA-AP",
                "PWR-C1-440WDC=",
                "C3850-FAN-T1-RF",
                "CAB-TA-IN",
                "CAB-SPWR-150CM-RF",
                "WS-C3850-24P-S-BR",
                "PWR-C1-715WAC",
                "WS-C3850-24P",
                "C1FUCAT38501K9",
                "CAB-SPWR-150CM",
                "C1FACAT38501K9"
             ]
          },
          {  
             "mdfId":"284846023",
             "productName":"Cisco Catalyst 3650-48FS-L Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "C3650-48FS-L++",
                "WS-C3650-48FWD-S",
                "PWR-C2-640WAC=",
                "C1FACAT36502K9",
                "L-C3650-ADD-12T=",
                "NAL-C3650-48FS-L",
                "PWR-C2-640WAC",
                "PWR-C2-640WDC-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "C1-WS3650-48FS/K9",
                "C3650-RTU=",
                "4PT-KIT-T1-RF",
                "C3560-NEBS-KIT=",
                "PWR-C2-250WAC-RF",
                "C1AUCAT36502K9",
                "STACK-T2-3M",
                "C1-C3650-48-DNAA-O",
                "PWR-C2-640WDC",
                "C1-C3650-48-DNAE-O",
                "WS-C3650-48FS-L",
                "C1AACAT36502K9",
                "C1AUCAT36502K9=",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "C1FUCAT36502K9=",
                "C1FUCAT36502K9",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-48FS-L-WS",
                "WS-C3650-12X48UR-S",
                "C1AACAT36502K9=",
                "PWR-C2-640WAC-WS",
                "WS-C3650-48FWDS-RF",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "C1FACAT36502K9=",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "WS-C3650-48FS-L-RF",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "EDU-C3650-48FS-L",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "RACK-KIT-T1-WS",
                "PWR-C2-250WAC-WS",
                "PWR-C2-640WDC-WS",
                "WS-C3650-48FS",
                "RACK-KIT-T1=",
                "WSC365012X48URS-WS",
                "ACC-KIT-T1=",
                "4PT-KIT-T1="
             ]
          },
          {  
             "mdfId":"284850598",
             "productName":"Cisco Catalyst 3650-48FD-L Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "WS-C3650-48FWD-S",
                "PWR-C2-640WAC=",
                "L-C3650-ADD-12T=",
                "C1FACAT36502K9",
                "PWR-C2-640WAC",
                "PWR-C2-640WDC-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "C3650-RTU=",
                "4PT-KIT-T1-RF",
                "C3560-NEBS-KIT=",
                "WS-C3650-48FD-L-RF",
                "PWR-C2-250WAC-RF",
                "C1AUCAT36502K9",
                "STACK-T2-3M",
                "C1-C3650-48-DNAA-O",
                "PWR-C2-640WDC",
                "C1-C3650-48-DNAE-O",
                "WS-C3650-48FD-L-WS",
                "C1AACAT36502K9",
                "EDU-C3650-48FD-L",
                "C1-WS3650-48FD/K9",
                "NAL-C3650-48FD-L",
                "C1AUCAT36502K9=",
                "PWR-C2-250WAC",
                "WS-C3650-48FD-L",
                "C3650-48FD-L++",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "C1FUCAT36502K9",
                "C1FUCAT36502K9=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "PWR-C2-640WAC-WS",
                "WS-C3650-48FD",
                "C1AACAT36502K9=",
                "WS-C3650-48FWDS-RF",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "C1FACAT36502K9=",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "PWR-C2-250WAC-WS",
                "RACK-KIT-T1-WS",
                "PWR-C2-640WDC-WS",
                "4PT-KIT-T1=",
                "RACK-KIT-T1=",
                "ACC-KIT-T1=",
                "WSC365012X48URS-WS"
             ]
          },
          {  
             "mdfId":"284850677",
             "productName":"Cisco Catalyst 3650-48FQ-S Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "WS-C3650-48FWQS-RF",
                "WS-C3650-48FQ-S-WS",
                "WS-C3650-48FWD-S",
                "NAL-C3650-48FWQ-S",
                "PWR-C2-640WAC=",
                "WS-C3650-48FQ-E-HC",
                "C1FACAT36502K9",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "PWR-C2-640WDC-RF",
                "NAL-C3650-48FQ-S",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "4PT-KIT-T1-RF",
                "C1WS365048FQ/K9-RF",
                "C3650-RTU=",
                "C3560-NEBS-KIT=",
                "C3650-48FQ-S++",
                "PWR-C2-250WAC-RF",
                "C1AUCAT36502K9",
                "STACK-T2-3M",
                "WS-C3650-48FQ-S",
                "WS-C3650-48FQ-HC",
                "C1-C3650-48-DNAA-O",
                "PWR-C2-640WDC",
                "C1-C3650-48-DNAE-O",
                "C1AACAT36502K9",
                "WS-C3650-48FWQS-WS",
                "C1AUCAT36502K9=",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "C1FUCAT36502K9",
                "C1FUCAT36502K9=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "C1AACAT36502K9=",
                "PWR-C2-640WAC-WS",
                "WS-C3650-48FWDS-RF",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "C1FACAT36502K9=",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "C1-WS3650-48FQ/K9",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "WS-C3650-48FWQ-S",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "PWR-C2-250WAC-WS",
                "RACK-KIT-T1-WS",
                "PWR-C2-640WDC-WS",
                "WS-C3650-48FQ-S-RF",
                "WSC365012X48URS-WS",
                "RACK-KIT-T1=",
                "ACC-KIT-T1=",
                "4PT-KIT-T1="
             ]
          },
          {  
             "mdfId":"286309920",
             "productName":"Cisco Catalyst 3650-12X48FD-S Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "STACK-T2-3M",
                "WS-C3650-48FWD-S",
                "WS-C3650-12X48UR-S",
                "NAL-C3650-12X48FDS",
                "WS-C3650-48FWDS-RF",
                "RACK-KIT-T1-RF",
                "STACK-T2-3M=",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "C1-WS3650-48XFD/K9",
                "STACK-T2-3M-WS",
                "C3560-NEBS-KIT=",
                "RACK-KIT-T1=",
                "WSC365012X48URS-WS"
             ]
          },
          {  
             "mdfId":"284850743",
             "productName":"Cisco Catalyst 3650-48TQ-E Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "WS-C3650-48FWD-S",
                "WS-C3650-48TQ",
                "PWR-C2-640WAC=",
                "C1FACAT36502K9",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "PWR-C2-640WDC-RF",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "4PT-KIT-T1-RF",
                "C3650-RTU=",
                "C3560-NEBS-KIT=",
                "PWR-C2-250WAC-RF",
                "C1AUCAT36502K9",
                "C3650-48TQ-E++",
                "STACK-T2-3M",
                "C1-C3650-48-DNAA-O",
                "PWR-C2-640WDC",
                "C1-C3650-48-DNAE-O",
                "C1AACAT36502K9",
                "C1AUCAT36502K9=",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "C1FUCAT36502K9",
                "C1FUCAT36502K9=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "C1AACAT36502K9=",
                "PWR-C2-640WAC-WS",
                "WS-C3650-48TQ-E-WS",
                "RACK-KIT-T1-RF",
                "WS-C3650-48FWDS-RF",
                "PWR-C2-1025WAC",
                "C1FACAT36502K9=",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "WS-C3650-48TQ-E-RF",
                "C1-WS3650-48TQ/K9",
                "L-C3650-SLIC-U=",
                "WS-C3650-48TQ-E",
                "C1-WS3650-48XFD/K9",
                "RACK-KIT-T1-WS",
                "PWR-C2-250WAC-WS",
                "PWR-C2-640WDC-WS",
                "NAL-C3650-48TQ-E",
                "4PT-KIT-T1=",
                "WSC365012X48URS-WS",
                "RACK-KIT-T1=",
                "ACC-KIT-T1="
             ]
          },
          {  
             "mdfId":"284850669",
             "productName":"Cisco Catalyst 3650-48PQ-L Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "WS-C3650-48PQ-L-WS",
                "WS-C3650-48FWD-S",
                "PWR-C2-640WAC=",
                "C1FACAT36502K9",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WDC-RF",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "C3560-NEBS-KIT=",
                "4PT-KIT-T1-RF",
                "C3650-RTU=",
                "PWR-C2-250WAC-RF",
                "C1AUCAT36502K9",
                "STACK-T2-3M",
                "C1-WS3650-48PQ/K9",
                "WS-C3650-48PQM-S",
                "WS-C3650-48PQ",
                "C1-C3650-48-DNAA-O",
                "PWR-C2-640WDC",
                "C1-C3650-48-DNAE-O",
                "C1AACAT36502K9",
                "WS-C3650-48PQM-L",
                "WS-C3650-48PQM-E",
                "C1AUCAT36502K9=",
                "NAL-C3650-48PQ-L",
                "PWR-C2-250WAC",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "C1FUCAT36502K9=",
                "C1FUCAT36502K9",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "C1AACAT36502K9=",
                "PWR-C2-640WAC-WS",
                "RACK-KIT-T1-RF",
                "WS-C3650-48FWDS-RF",
                "PWR-C2-1025WAC",
                "C1FACAT36502K9=",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "WS-C3650-48PQ-HC",
                "STACK-T2-3M-WS",
                "C3650-48PQ-L++",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "WS-C3650-48PQ-L-RF",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "PWR-C2-250WAC-WS",
                "RACK-KIT-T1-WS",
                "PWR-C2-640WDC-WS",
                "WS-C3650-48PQ-L",
                "RACK-KIT-T1=",
                "ACC-KIT-T1=",
                "WSC365012X48URS-WS",
                "4PT-KIT-T1="
             ]
          },
          {  
             "mdfId":"284846019",
             "productName":"Cisco Catalyst 3650-48PS-L Switch",
             "productId":[  
                "STACK-T2-3M-RF",
                "PWR-C2-250WAC=",
                "EDU-C3650-48PS-L",
                "WS-C3650-48FWD-S",
                "PWR-C2-640WAC=",
                "C1FACAT36502K9",
                "L-C3650-ADD-12T=",
                "PWR-C2-640WAC",
                "L-C3650-ADD-12P=",
                "PWR-C2-640WDC-RF",
                "PWR-C2-640WAC-RF",
                "C3650-NEBS-KIT=",
                "WSC365012X48URS-RF",
                "4PT-KIT-T1-RF",
                "C3560-NEBS-KIT=",
                "C3650-RTU=",
                "PWR-C2-250WAC-RF",
                "C1AUCAT36502K9",
                "STACK-T2-3M",
                "WS-C3650-48PS-L-WS",
                "C1-C3650-48-DNAA-O",
                "PWR-C2-640WDC",
                "C1-C3650-48-DNAE-O",
                "WS-C3650-48PS",
                "C1AACAT36502K9",
                "C3650-48PS-L++",
                "C1AUCAT36502K9=",
                "NAL-C3650-48PS-L",
                "PWR-C2-250WAC",
                "LL-C3650-48PS-L=",
                "PWR-C2-640WDC=",
                "L-C3650-RTU=",
                "C1FUCAT36502K9",
                "C1FUCAT36502K9=",
                "PWR-C2-1025WAC-RF",
                "WS-C3650-12X48UR-S",
                "C1AACAT36502K9=",
                "PWR-C2-640WAC-WS",
                "WS-C3650-48FWDS-RF",
                "RACK-KIT-T1-RF",
                "PWR-C2-1025WAC",
                "C1FACAT36502K9=",
                "STACK-T2-3M=",
                "PWR-C2-BLANK",
                "STACK-T2-3M-WS",
                "PWR-C2-BLANK=",
                "PWR-C2-1025WAC-WS",
                "PWR-C2-1025WAC=",
                "FAN-T1=",
                "WS-C3650-48PS-L-RF",
                "C1-WS3650-48PS/K9",
                "L-C3650-SLIC-U=",
                "C1-WS3650-48XFD/K9",
                "PWR-C2-250WAC-WS",
                "RACK-KIT-T1-WS",
                "PWR-C2-640WDC-WS",
                "WS-C3650-48PS-L",
                "RACK-KIT-T1=",
                "4PT-KIT-T1=",
                "WSC365012X48URS-WS",
                "ACC-KIT-T1="
             ]
          }
       ],
       "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
       "ccorecommended":false,
       "ccolatest":false
    },
    {  
       "name":"cat3k_caa-universalk9ldpe.16.08.01a.SPA.bin",
       "imageUuid":"18895b61-e648-4345-b678-56954c136b5c",
       "version":"Fuji-16.8.1a",
       "softwareType":null,
       "imageType":"SYSTEM_SW",
       "isTaggedGolden":"true",
       "md5Checksum":"f3b52f18bbcb5a16ae0fe5407ed68a35",
       "shaCheckSum":"6b65bec2e61dd7a4ad66063ed2db761e8d243597b53f8317aca62880fc35dcccde8db5ba6fffa6edbd5f602cd9631a9a81b407433b2b570d19ab784c9b8654bd",
       "createdTime":"2018-06-05 03:04:37.925",
       "applicationType":"UNKNOWN",
       "feature":null,
       "fileServiceId":null,
       "imageSeries":null,
       "imageSource":"ftp://cisco:cisco123@10.16.0.70/ios/switch/cat3k_caa-universalk9ldpe.16.08.01a.SPA.bin",
       "imageSourceType":"Imported from Remote server URL",
       "imageFamily":"CAT3K_CAA",
       "vendor":"CISCO",
       "filesize":425937972,
       "deviceCount":0,
       "deviceUuidList":[  

       ],
       "smuCount":0,
       "imageAttributes":[  
          {  
             "propertyName":"release_fcs_date",
             "propertyValue":"06/Apr/2018"
          },
          {  
             "propertyName":"udi",
             "propertyValue":"PID: WS-C3850-24U-S VID: V05, SN: FOC2027X0TV"
          },
          {  
             "propertyName":"image_size",
             "propertyValue":"425937972"
          },
          {  
             "propertyName":"field_notices",
             "propertyValue":"Field Notices"
          },
          {  
             "propertyName":"image_guid",
             "propertyValue":"6CCE087B2778E57FDD3AEA0604DF4D9A860397F6"
          },
          {  
             "propertyName":"metadata_trans_id",
             "propertyValue":"127920231"
          },
          {  
             "propertyName":"field_notice_url",
             "propertyValue":"https://www.cisco.com/en/US/products/ps12686/prod_field_notices_list.html"
          },
          {  
             "propertyName":"min_dram",
             "propertyValue":"4096"
          },
          {  
             "propertyName":"deviceMNEId",
             "propertyValue":"119126"
          },
          {  
             "propertyName":"min_flash",
             "propertyValue":"2048"
          },
          {  
             "propertyName":"mdf_id",
             "propertyValue":"284455430"
          },
          {  
             "propertyName":"image_description",
             "propertyValue":"CAT3850/3650 UNIVERSAL W/O DTLS"
          },
          {  
             "propertyName":"encryption_software_indicator",
             "propertyValue":"Y"
          }
       ],
       "isSMUApplicable":false,
       "goldenTagInheritedFromSiteUuid":null,
       "goldenTagInheritedFromSiteName":null,
       "importSupported":true,
       "reasonForNotSupportingImport":null,
       "tagList":[  
          {  
             "taggedGolden":false,
             "role":"ALL",
             "inheritedSiteName":"",
             "inheritedSiteUuid":""
          },
          {  
             "taggedGolden":false,
             "role":"CORE",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"DISTRIBUTION",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"BORDER ROUTER",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"UNKNOWN",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":true,
             "role":"ACCESS",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          }
       ],
       "imageCategory":"PHYSICAL",
       "imageIntegrityStatus":"",
       "imageImported":true,
       "applicableDevicesForImage": [{
          "mdfId": "284455439",
          "productName": "Cisco Catalyst 3850-48U-E Switch",
          "productId": ["WS-C3850-12X48U-E"]
		  }
		],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
       "ccorecommended":false,
       "ccolatest":false
    },
    {  
       "name":"cat3k_caa-universalk9ldpe.SPA.03.06.08.E.152-2.E8.bin",
       "imageUuid":"57c550db-999d-454a-898a-0f4c95a7b807",
       "version":"3.6.8E",
       "softwareType":null,
       "imageType":"SYSTEM_SW",
       "isTaggedGolden":"false",
       "md5Checksum":"1c92058ddc31fb2cc6aaf7f14008314b",
       "shaCheckSum":"975dc8629dd8da0875aec3287f634319cc13777f99eb62aadb7a13a509c7548f15467082a36c88f1eecdf2b2100aa9e3c8a55e58465041e5f0331c4ed7579cc9",
       "createdTime":"2018-06-05 03:04:37.925",
       "applicationType":"UNKNOWN",
       "feature":null,
       "fileServiceId":null,
       "imageSeries":null,
       "imageSource":"CCO",
       "imageSourceType":"Imported from CCO",
       "imageFamily":"CAT3K_CAA",
       "vendor":"CISCO",
       "filesize":305290172,
       "deviceCount":0,
       "deviceUuidList":[  

       ],
       "smuCount":0,
       "imageAttributes":[  
          {  
             "propertyName":"udi",
             "propertyValue":"PID: WS-C3850-24U-S VID: V05, SN: FOC2027X0TV"
          },
          {  
             "propertyName":"field_notices",
             "propertyValue":"Field Notices"
          },
          {  
             "propertyName":"image_guid",
             "propertyValue":"DA302FEA286390D3BCEB5497F6E27FA2E036C8C7"
          },
          {  
             "propertyName":"metadata_trans_id",
             "propertyValue":"127920231"
          },
          {  
             "propertyName":"field_notice_url",
             "propertyValue":"https://www.cisco.com/en/US/products/ps12686/prod_field_notices_list.html"
          },
          {  
             "propertyName":"image_size",
             "propertyValue":"305290172"
          },
          {  
             "propertyName":"min_dram",
             "propertyValue":"4096"
          },
          {  
             "propertyName":"deviceMNEId",
             "propertyValue":"119126"
          },
          {  
             "propertyName":"release_fcs_date",
             "propertyValue":"30/Jan/2018"
          },
          {  
             "propertyName":"min_flash",
             "propertyValue":"2048"
          },
          {  
             "propertyName":"mdf_id",
             "propertyValue":"284455430"
          },
          {  
             "propertyName":"image_description",
             "propertyValue":"CAT3850/3650 UNIVERSAL W/O DTLS"
          },
          {  
             "propertyName":"encryption_software_indicator",
             "propertyValue":"Y"
          }
       ],
       "isSMUApplicable":false,
       "goldenTagInheritedFromSiteUuid":null,
       "goldenTagInheritedFromSiteName":null,
       "importSupported":true,
       "reasonForNotSupportingImport":null,
       "tagList":[  
          {  
             "taggedGolden":false,
             "role":"ALL",
             "inheritedSiteName":"",
             "inheritedSiteUuid":""
          },
          {  
             "taggedGolden":false,
             "role":"CORE",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"DISTRIBUTION",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"BORDER ROUTER",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"UNKNOWN",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"ACCESS",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          }
       ],
       "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
       "imageCategory":"PHYSICAL",
       "imageIntegrityStatus":"",
       "imageImported":true,
       "applicableDevicesForImage":null,
       "ccorecommended":true,
       "ccolatest":true
    },
    {  
       "name":"cat3k_caa-universalk9.SPA.03.06.08.E.152-2.E8.bin",
       "imageUuid":"6a3f2255-fa21-4ddf-8810-2e75c5fc78d7",
       "version":"3.6.8E",
       "softwareType":null,
       "imageType":"SYSTEM_SW",
       "isTaggedGolden":"false",
       "md5Checksum":"a04a54d69cb2b4d2867ed369e73598ae",
       "shaCheckSum":"565fd0730441dd9a9e98207ff78580c9a3f7c1ce5a3c50529fb605d9f17e413e868fb2c2181a555fb7d2e0b55289da6814cfd6c54df75d4d3949e093f39fd684",
       "createdTime":"2018-06-05 03:04:37.925",
       "applicationType":"UNKNOWN",
       "feature":null,
       "fileServiceId":null,
       "imageSeries":null,
       "imageSource":"CCO",
       "imageSourceType":"Imported from CCO",
       "imageFamily":"CAT3K_CAA",
       "vendor":"CISCO",
       "filesize":305292424,
       "deviceCount":0,
       "deviceUuidList":[  

       ],
       "smuCount":0,
       "imageAttributes":[  
          {  
             "propertyName":"udi",
             "propertyValue":"PID: WS-C3850-24U-S VID: V05, SN: FOC2027X0TV"
          },
          {  
             "propertyName":"field_notices",
             "propertyValue":"Field Notices"
          },
          {  
             "propertyName":"metadata_trans_id",
             "propertyValue":"127920231"
          },
          {  
             "propertyName":"field_notice_url",
             "propertyValue":"https://www.cisco.com/en/US/products/ps12686/prod_field_notices_list.html"
          },
          {  
             "propertyName":"min_dram",
             "propertyValue":"4096"
          },
          {  
             "propertyName":"deviceMNEId",
             "propertyValue":"119126"
          },
          {  
             "propertyName":"release_fcs_date",
             "propertyValue":"30/Jan/2018"
          },
          {  
             "propertyName":"image_description",
             "propertyValue":"CAT3850/3650 UNIVERSAL"
          },
          {  
             "propertyName":"image_size",
             "propertyValue":"305292424"
          },
          {  
             "propertyName":"min_flash",
             "propertyValue":"2048"
          },
          {  
             "propertyName":"image_guid",
             "propertyValue":"A4FB95ABDB520FDB86E402EF760C0DAACD1B37D0"
          },
          {  
             "propertyName":"mdf_id",
             "propertyValue":"284455430"
          },
          {  
             "propertyName":"encryption_software_indicator",
             "propertyValue":"Y"
          }
       ],
       "isSMUApplicable":false,
       "goldenTagInheritedFromSiteUuid":null,
       "goldenTagInheritedFromSiteName":null,
       "importSupported":true,
       "reasonForNotSupportingImport":null,
       "tagList":[  
          {  
             "taggedGolden":false,
             "role":"ALL",
             "inheritedSiteName":"",
             "inheritedSiteUuid":""
          },
          {  
             "taggedGolden":false,
             "role":"CORE",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"DISTRIBUTION",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"BORDER ROUTER",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"UNKNOWN",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"ACCESS",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          }
       ],
       "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
       "imageCategory":"PHYSICAL",
       "imageIntegrityStatus":"",
       "imageImported":true,
       "applicableDevicesForImage":null,
       "ccorecommended":true,
       "ccolatest":true
    },
    {  
       "name":"cat3k_caa-universalk9ldpe.16.06.03.SPA.bin",
       "imageUuid":"71cb2a8a-177a-4f08-b21d-83cb07bf1656",
       "version":"Everest-16.6.3",
       "softwareType":null,
       "imageType":"SYSTEM_SW",
       "isTaggedGolden":"false",
       "md5Checksum":"c8a71e6a5614f645a4c80ee82ae598ae",
       "shaCheckSum":"dbd7938fc57ea4045cd1fc0664c5a06d12024202066f816e3a41843bd29f5a5f005931a10454a74d047c8ef9a6c1ea1c46a239e341caa6745dbb5d80d2090920",
       "createdTime":"2018-06-05 03:04:37.925",
       "applicationType":"UNKNOWN",
       "feature":null,
       "fileServiceId":null,
       "imageSeries":null,
       "imageSource":"CCO",
       "imageSourceType":"Imported from CCO",
       "imageFamily":"CAT3K_CAA",
       "vendor":"CISCO",
       "filesize":410403458,
       "deviceCount":0,
       "deviceUuidList":[  

       ],
       "smuCount":0,
       "imageAttributes":[  
          {  
             "propertyName":"udi",
             "propertyValue":"PID: WS-C3850-24U-S VID: V05, SN: FOC2027X0TV"
          },
          {  
             "propertyName":"release_fcs_date",
             "propertyValue":"02/Mar/2018"
          },
          {  
             "propertyName":"field_notices",
             "propertyValue":"Field Notices"
          },
          {  
             "propertyName":"metadata_trans_id",
             "propertyValue":"127920231"
          },
          {  
             "propertyName":"image_guid",
             "propertyValue":"7D5594875A9AEC206F7E7A6C1A5C1C3A0E814660"
          },
          {  
             "propertyName":"field_notice_url",
             "propertyValue":"https://www.cisco.com/en/US/products/ps12686/prod_field_notices_list.html"
          },
          {  
             "propertyName":"min_dram",
             "propertyValue":"4096"
          },
          {  
             "propertyName":"deviceMNEId",
             "propertyValue":"119126"
          },
          {  
             "propertyName":"image_size",
             "propertyValue":"410403458"
          },
          {  
             "propertyName":"min_flash",
             "propertyValue":"2048"
          },
          {  
             "propertyName":"mdf_id",
             "propertyValue":"284455430"
          },
          {  
             "propertyName":"image_description",
             "propertyValue":"CAT3850/3650 UNIVERSAL W/O DTLS"
          },
          {  
             "propertyName":"encryption_software_indicator",
             "propertyValue":"Y"
          }
       ],
       "isSMUApplicable":false,
       "goldenTagInheritedFromSiteUuid":null,
       "goldenTagInheritedFromSiteName":null,
       "importSupported":true,
       "reasonForNotSupportingImport":null,
       "tagList":[  
          {  
             "taggedGolden":false,
             "role":"ALL",
             "inheritedSiteName":"",
             "inheritedSiteUuid":""
          },
          {  
             "taggedGolden":false,
             "role":"CORE",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"DISTRIBUTION",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"BORDER ROUTER",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"UNKNOWN",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"ACCESS",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          }
       ],
       "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
       "imageCategory":"PHYSICAL",
       "imageIntegrityStatus":"",
       "imageImported":true,
       "applicableDevicesForImage":null,
       "ccorecommended":false,
       "ccolatest":true
    },
    {  
       "name":"cat3k_caa-universalk9.16.08.01a.SPA.bin",
       "imageUuid":"79da04b8-304e-4d4d-a487-104ccb119851",
       "version":"Fuji-16.8.1a",
       "softwareType":null,
       "imageType":"SYSTEM_SW",
       "isTaggedGolden":"false",
       "md5Checksum":"ce4dd8f684f11a6605c6194395f28487",
       "shaCheckSum":"18219691aebd6d54237924bb3f4f75ac2836a1fae3dc83bb37658fcb779d7832983753546d81e02c39e50e5b29f7ed1832a62bfae1a51b19c5d935cc453687cd",
       "createdTime":"2018-06-05 03:04:37.925",
       "applicationType":"UNKNOWN",
       "feature":null,
       "fileServiceId":null,
       "imageSeries":null,
       "imageSource":"CCO",
       "imageSourceType":"Imported from CCO",
       "imageFamily":"CAT3K_CAA",
       "vendor":"CISCO",
       "filesize":425928073,
       "deviceCount":0,
       "deviceUuidList":[  

       ],
       "smuCount":0,
       "imageAttributes":[  
          {  
             "propertyName":"release_fcs_date",
             "propertyValue":"06/Apr/2018"
          },
          {  
             "propertyName":"image_size",
             "propertyValue":"425928073"
          },
          {  
             "propertyName":"udi",
             "propertyValue":"PID: WS-C3850-24U-S VID: V05, SN: FOC2027X0TV"
          },
          {  
             "propertyName":"field_notices",
             "propertyValue":"Field Notices"
          },
          {  
             "propertyName":"metadata_trans_id",
             "propertyValue":"127920231"
          },
          {  
             "propertyName":"field_notice_url",
             "propertyValue":"https://www.cisco.com/en/US/products/ps12686/prod_field_notices_list.html"
          },
          {  
             "propertyName":"image_guid",
             "propertyValue":"A40B3DE3CD5B7F008ED36C333B8A9ABCB5CD1439"
          },
          {  
             "propertyName":"min_dram",
             "propertyValue":"4096"
          },
          {  
             "propertyName":"deviceMNEId",
             "propertyValue":"119126"
          },
          {  
             "propertyName":"image_description",
             "propertyValue":"CAT3850/3650 UNIVERSAL"
          },
          {  
             "propertyName":"min_flash",
             "propertyValue":"2048"
          },
          {  
             "propertyName":"mdf_id",
             "propertyValue":"284455430"
          },
          {  
             "propertyName":"encryption_software_indicator",
             "propertyValue":"Y"
          }
       ],
       "isSMUApplicable":false,
       "goldenTagInheritedFromSiteUuid":null,
       "goldenTagInheritedFromSiteName":null,
       "importSupported":true,
       "reasonForNotSupportingImport":null,
       "tagList":[  
          {  
             "taggedGolden":false,
             "role":"ALL",
             "inheritedSiteName":"",
             "inheritedSiteUuid":""
          },
          {  
             "taggedGolden":false,
             "role":"CORE",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"DISTRIBUTION",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"BORDER ROUTER",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"UNKNOWN",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"ACCESS",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          }
       ],
       "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
       "imageCategory":"PHYSICAL",
       "imageIntegrityStatus":"",
       "imageImported":true,
       "applicableDevicesForImage":null,
       "ccorecommended":false,
       "ccolatest":true
    },
    {  
       "name":"cat3k_caa-universalk9ldpe.16.03.06.SPA.bin",
       "imageUuid":"a79e726d-cd23-4aa3-a14a-a02ce422403b",
       "version":"Denali-16.3.6",
       "softwareType":null,
       "imageType":"SYSTEM_SW",
       "isTaggedGolden":"false",
       "md5Checksum":"0d0076ca9b3f0e596bc99295b30a86d5",
       "shaCheckSum":"b12f5f01f32c1f3581f6315f7d164f463c4227189b52b10baa8a6a3bccf89ec558a8f898dd5ccc4bcb0001885a3cf4bc2054b8859b9c808dafb843baaec51705",
       "createdTime":"2018-06-05 03:04:37.925",
       "applicationType":"UNKNOWN",
       "feature":null,
       "fileServiceId":null,
       "imageSeries":null,
       "imageSource":"CCO",
       "imageSourceType":"Imported from CCO",
       "imageFamily":"CAT3K_CAA",
       "vendor":"CISCO",
       "filesize":537646690,
       "deviceCount":0,
       "deviceUuidList":[  

       ],
       "smuCount":0,
       "imageAttributes":[  
          {  
             "propertyName":"udi",
             "propertyValue":"PID: WS-C3850-24U-S VID: V05, SN: FOC2027X0TV"
          },
          {  
             "propertyName":"release_fcs_date",
             "propertyValue":"02/Mar/2018"
          },
          {  
             "propertyName":"field_notices",
             "propertyValue":"Field Notices"
          },
          {  
             "propertyName":"image_size",
             "propertyValue":"537646690"
          },
          {  
             "propertyName":"metadata_trans_id",
             "propertyValue":"127920231"
          },
          {  
             "propertyName":"field_notice_url",
             "propertyValue":"https://www.cisco.com/en/US/products/ps12686/prod_field_notices_list.html"
          },
          {  
             "propertyName":"min_dram",
             "propertyValue":"4096"
          },
          {  
             "propertyName":"image_guid",
             "propertyValue":"A07A5E2273BD7C5D079AA10C900ED71F3DF272B5"
          },
          {  
             "propertyName":"deviceMNEId",
             "propertyValue":"119126"
          },
          {  
             "propertyName":"min_flash",
             "propertyValue":"2048"
          },
          {  
             "propertyName":"mdf_id",
             "propertyValue":"284455430"
          },
          {  
             "propertyName":"image_description",
             "propertyValue":"CAT3850/3650 UNIVERSAL W/O DTLS"
          },
          {  
             "propertyName":"encryption_software_indicator",
             "propertyValue":"Y"
          }
       ],
       "isSMUApplicable":false,
       "goldenTagInheritedFromSiteUuid":null,
       "goldenTagInheritedFromSiteName":null,
       "importSupported":true,
       "reasonForNotSupportingImport":null,
       "tagList":[  
          {  
             "taggedGolden":false,
             "role":"ALL",
             "inheritedSiteName":"",
             "inheritedSiteUuid":""
          },
          {  
             "taggedGolden":false,
             "role":"CORE",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"DISTRIBUTION",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"BORDER ROUTER",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"UNKNOWN",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"ACCESS",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          }
       ],
       "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
       "imageCategory":"PHYSICAL",
       "imageIntegrityStatus":"",
       "imageImported":true,
       "applicableDevicesForImage":null,
       "ccorecommended":true,
       "ccolatest":true
    },
    {  
       "name":"cat3k_caa-universalk9.16.06.03.SPA.bin",
       "imageUuid":"b4af19a4-2132-4cfd-a27e-d55e5f4543f0",
       "version":"Everest-16.6.3",
       "softwareType":null,
       "imageType":"SYSTEM_SW",
       "isTaggedGolden":"false",
       "md5Checksum":"16fdc2dd9b8a5f7096a71e04ead0431d",
       "shaCheckSum":"9cc048b88d6ad96471f9b36e21e6f419ed636b8e56c89422b39270d40a966560fc57dee265a57d61b59cf0cf11d5c3fc6b0aa23efe047d71e60b624be368f2f0",
       "createdTime":"2018-06-05 03:04:37.925",
       "applicationType":"UNKNOWN",
       "feature":null,
       "fileServiceId":null,
       "imageSeries":null,
       "imageSource":"CCO",
       "imageSourceType":"Imported from CCO",
       "imageFamily":"CAT3K_CAA",
       "vendor":"CISCO",
       "filesize":410434678,
       "deviceCount":0,
       "deviceUuidList":[  

       ],
       "smuCount":0,
       "imageAttributes":[  
          {  
             "propertyName":"udi",
             "propertyValue":"PID: WS-C3850-24U-S VID: V05, SN: FOC2027X0TV"
          },
          {  
             "propertyName":"release_fcs_date",
             "propertyValue":"02/Mar/2018"
          },
          {  
             "propertyName":"field_notices",
             "propertyValue":"Field Notices"
          },
          {  
             "propertyName":"metadata_trans_id",
             "propertyValue":"127920231"
          },
          {  
             "propertyName":"field_notice_url",
             "propertyValue":"https://www.cisco.com/en/US/products/ps12686/prod_field_notices_list.html"
          },
          {  
             "propertyName":"image_size",
             "propertyValue":"410434678"
          },
          {  
             "propertyName":"min_dram",
             "propertyValue":"4096"
          },
          {  
             "propertyName":"deviceMNEId",
             "propertyValue":"119126"
          },
          {  
             "propertyName":"image_description",
             "propertyValue":"CAT3850/3650 UNIVERSAL"
          },
          {  
             "propertyName":"min_flash",
             "propertyValue":"2048"
          },
          {  
             "propertyName":"image_guid",
             "propertyValue":"09DBA219AF20B995A52E84CA07D908A209E9EF61"
          },
          {  
             "propertyName":"mdf_id",
             "propertyValue":"284455430"
          },
          {  
             "propertyName":"encryption_software_indicator",
             "propertyValue":"Y"
          }
       ],
       "isSMUApplicable":false,
       "goldenTagInheritedFromSiteUuid":null,
       "goldenTagInheritedFromSiteName":null,
       "importSupported":true,
       "reasonForNotSupportingImport":null,
       "tagList":[  
          {  
             "taggedGolden":false,
             "role":"ALL",
             "inheritedSiteName":"",
             "inheritedSiteUuid":""
          },
          {  
             "taggedGolden":false,
             "role":"CORE",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"DISTRIBUTION",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"BORDER ROUTER",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"UNKNOWN",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"ACCESS",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          }
       ],
       "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
       "imageCategory":"PHYSICAL",
       "imageIntegrityStatus":"",
       "imageImported":true,
       "applicableDevicesForImage":null,
       "ccorecommended":false,
       "ccolatest":true
    },
    {  
       "name":"cat3k_caa-universalk9.16.03.06.SPA.bin",
       "imageUuid":"f48fbe10-1ef0-4cbd-a416-f3e1920e4a29",
       "version":"Denali-16.3.6",
       "softwareType":null,
       "imageType":"SYSTEM_SW",
       "isTaggedGolden":"false",
       "md5Checksum":"a65a6308ec728a92f8463e5bfb6b79f0",
       "shaCheckSum":"3dd65d743da7600c65a38e8c287b8b2b10a01ecd1d766142cce7b495e7d5a5eaa6145813ec8789fed89dea69c3ff19ad084fdd9e225e65938def7b550718e39b",
       "createdTime":"2018-06-05 03:04:37.925",
       "applicationType":"UNKNOWN",
       "feature":null,
       "fileServiceId":null,
       "imageSeries":null,
       "imageSource":"CCO",
       "imageSourceType":"Imported from CCO",
       "imageFamily":"CAT3K_CAA",
       "vendor":"CISCO",
       "filesize":537577577,
       "deviceCount":0,
       "deviceUuidList":[  

       ],
       "smuCount":0,
       "imageAttributes":[  
          {  
             "propertyName":"udi",
             "propertyValue":"PID: WS-C3850-24U-S VID: V05, SN: FOC2027X0TV"
          },
          {  
             "propertyName":"release_fcs_date",
             "propertyValue":"02/Mar/2018"
          },
          {  
             "propertyName":"field_notices",
             "propertyValue":"Field Notices"
          },
          {  
             "propertyName":"image_guid",
             "propertyValue":"7250572E13B80F14BADD2F02C0B14554B79E9B05"
          },
          {  
             "propertyName":"metadata_trans_id",
             "propertyValue":"127920231"
          },
          {  
             "propertyName":"field_notice_url",
             "propertyValue":"https://www.cisco.com/en/US/products/ps12686/prod_field_notices_list.html"
          },
          {  
             "propertyName":"min_dram",
             "propertyValue":"4096"
          },
          {  
             "propertyName":"deviceMNEId",
             "propertyValue":"119126"
          },
          {  
             "propertyName":"image_size",
             "propertyValue":"537577577"
          },
          {  
             "propertyName":"image_description",
             "propertyValue":"CAT3850/3650 UNIVERSAL"
          },
          {  
             "propertyName":"min_flash",
             "propertyValue":"2048"
          },
          {  
             "propertyName":"mdf_id",
             "propertyValue":"284455430"
          },
          {  
             "propertyName":"encryption_software_indicator",
             "propertyValue":"Y"
          }
       ],
       "isSMUApplicable":false,
       "goldenTagInheritedFromSiteUuid":null,
       "goldenTagInheritedFromSiteName":null,
       "importSupported":true,
       "reasonForNotSupportingImport":null,
       "tagList":[  
          {  
             "taggedGolden":false,
             "role":"ALL",
             "inheritedSiteName":"",
             "inheritedSiteUuid":""
          },
          {  
             "taggedGolden":false,
             "role":"CORE",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"DISTRIBUTION",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"BORDER ROUTER",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"UNKNOWN",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"ACCESS",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          }
       ],
       "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
       "imageCategory":"PHYSICAL",
       "imageIntegrityStatus":"",
       "imageImported":true,
       "applicableDevicesForImage":null,
       "ccorecommended":true,
       "ccolatest":true
    }
 ]
});