define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/kairos/AI_EXCESSIVE_TIME',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/kairos/AI_TOTAL_RADIO_THROUGHPUT',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/kairos/AI_THROUGHPUT_SOCIAL',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/kairos/AI_THROUGHPUT_CLOUD',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/kairos/AI_RADIO_THROUGHPUT_MEDIA_APPS',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/kairos/AI_FAILURE_CONNECT',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/kairos/AI_EXCESSIVE_TIME_IPADDRESS'
], function (AI_EXCESSIVE_TIME, AI_TOTAL_RADIO_THROUGHPUT, AI_THROUGHPUT_SOCIAL, AI_THROUGHPUT_CLOUD, AI_RADIO_THROUGHPUT_MEDIA_APPS, AI_FAILURE_CONNECT,
            AI_EXCESSIVE_TIME_IPADDRESS) {
    return {
        getKairosIssueResponse: function(templateFileName, templateName) {
            var data = undefined;
            if(templateFileName != undefined) {
                var fileContent = eval(templateFileName);
                if(fileContent && fileContent[templateName]) {
                    data = JSON.parse(JSON.stringify(fileContent[templateName]));
                } 
            }
            return data;
        }
    };

});
