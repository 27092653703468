define({
    "Chassis_File_Template":{
        "stackVariable": "stacks",
        "baseModel": "C9300-48UXM.glb",
        "partsMap": "9300-Parts.json",
        "isStack": true,
        "areCards": false,
        "standardStackScale": "8.75 .80 1",
        "standardStackRotation": "0 0 0",
        "standardPortScale": ".5 .5 .5",
        "stackPositions": [
          "0 0 0",
          "0 2 0",
          "0 3 0",
          "0 4 0"
        ],
        "modulePositions": [
          "-7.445 0.025 5.500"
        ],
        "camera": {
          "frontView": {
            "position": {
              "x": -0.361,
              "y": 4.775,
              "z": 23
            },
            "rotation": {
              "x": -9.359234259249883,
              "y": 38.73636913558951,
              "z": -8.058662672519964e-16
            }
          },
          "backView": {
            "position": {
              "x": 0.476,
              "y": 2.08,
              "z": -24.0307
            },
            "rotation": {
              "x": 180,
              "y": 0,
              "z": 0
            }
          },
          "summaryView": {
            "position": {
              "x": -22.90286,
              "y": 4.75576,
              "z": -24.0307
            },
            "rotation": {
              "x": -9.359234259249883,
              "y": 225,
              "z": -8.058662672519963
            }
          }
        },
        "stacks": [
          {
            "deviceSeries": "Cisco Catalyst 9300",
            "model": "C9300-48UXM",
            "portCount": 48,
            "sysObjId": "1875-18923u0-kljhf9",
            "chassisModel": "/Cat9300-48T-3D-model.gltf",
            "baseUrl": "/images",
            "ports": [
              {
                "id": "Port1",
                "offset": "8.371 -0.314 5.5"
              },
              {
                "id": "Port2",
                "offset": "8.371 .35 5.5"
              },
              {
                "id": "Port3",
                "offset": "7.81 -0.314 5.5"
              },
              {
                "id": "Port4",
                "offset": "7.81 .35 5.5"
              },
              {
                "id": "Port5",
                "offset": "7.243 -0.314 5.5"
              },
              {
                "id": "Port6",
                "offset": "7.243 .35 5.5"
              },
              {
                "id": "Port7",
                "offset": "6.680 -0.314 5.5"
              },
              {
                "id": "Port8",
                "offset": "6.680 .35 5.5"
              },
              {
                "id": "Port9",
                "offset": "6.1 -0.314 5.5"
              },
              {
                "id": "Port10",
                "offset": "6.1 .35 5.5"
              },
              {
                "id": "Port11",
                "offset": "5.55 -0.314 5.5"
              },
              {
                "id": "Port12",
                "offset": "5.55 .35 5.5"
              },
              {
                "id": "Port13",
                "offset": "4.73 -0.314 5.5"
              },
              {
                "id": "Port14",
                "offset": "4.73 .35 5.5"
              },
              {
                "id": "Port15",
                "offset": "4.165 -0.314 5.5"
              },
              {
                "id": "Port16",
                "offset": "4.165 .35 5.5"
              },
              {
                "id": "Port17",
                "offset": "3.6 -0.314 5.5"
              },
              {
                "id": "Port18",
                "offset": "3.6 .35 5.5"
              },
              {
                "id": "Port19",
                "offset": "3.020 -0.314 5.5"
              },
              {
                "id": "Port20",
                "offset": "3.020 .35 5.5"
              },
              {
                "id": "Port21",
                "offset": "2.465 -0.314 5.5"
              },
              {
                "id": "Port22",
                "offset": "2.465 .35 5.5"
              },
              {
                "id": "Port23",
                "offset": "1.9 -0.314 5.5"
              },
              {
                "id": "Port24",
                "offset": "1.9 .35 5.5"
              },
              {
                "id": "Port25",
                "offset": "1.09 -0.314 5.5"
              },
              {
                "id": "Port26",
                "offset": "1.09 .35 5.5"
              },
              {
                "id": "Port27",
                "offset": "0.53 -0.314 5.5"
              },
              {
                "id": "Port28",
                "offset": "0.53 .35 5.5"
              },
              {
                "id": "Port29",
                "offset": "-.04 -0.314 5.5"
              },
              {
                "id": "Port30",
                "offset": "-.04 .35 5.5"
              },
              {
                "id": "Port31",
                "offset": "-.6 -0.314 5.5"
              },
              {
                "id": "Port32",
                "offset": "-.6 .35 5.5"
              },
              {
                "id": "Port33",
                "offset": "-1.17 -0.314 5.5"
              },
              {
                "id": "Port34",
                "offset": "-1.17 .35 5.5"
              },
              {
                "id": "Port35",
                "offset": "-1.72 -0.314 5.5"
              },
              {
                "id": "Port36",
                "offset": "-1.72 .35 5.5"
              },
              {
                "id": "Port37",
                "offset": "-2.550 -0.314 5.5"
              },
              {
                "id": "Port38",
                "offset": "-2.550 .35 5.5"
              },
              {
                "id": "Port39",
                "offset": "-3.110 -0.314 5.5"
              },
              {
                "id": "Port40",
                "offset": "-3.110 .35 5.5"
              },
              {
                "id": "Port41",
                "offset": "-3.669 -0.314 5.5"
              },
              {
                "id": "Port42",
                "offset": "-3.669 .35 5.5"
              },
              {
                "id": "Port43",
                "offset": "-4.240 -0.314 5.5"
              },
              {
                "id": "Port44",
                "offset": "-4.240 .35 5.5"
              },
              {
                "id": "Port45",
                "offset": "-4.812 -0.314 5.5"
              },
              {
                "id": "Port46",
                "offset": "-4.812 .35 5.5"
              },
              {
                "id": "Port47",
                "offset": "-5.394 -0.314 5.5"
              },
              {
                "id": "Port48",
                "offset": "-5.394 .35 5.5"
              },
              {
                "id": "mgmt-port",
                "offset": "-7.829 .28 -5.49",
                "rotation": "0 180 0"
              }
            ],
            "powerSupplies": [
              {
                "position": "-6.870 0.000 -5.470",
                "rotation": "0 180 0",
                "scale": "3.800 2.000 1.000"
              },
              {
                "position": "-3.210 0.000 -5.470",
                "rotation": "0 180 0",
                "scale": "3.800 2.000 1.000"
              }
            ],
            "fans": [
              {
                "position": "5.940 0.000 -5.470",
                "rotation": "0 180 0",
                "scale": "1.6 2 1"
              },
              {
                "position": "3.400 0.000 -5.470",
                "rotation": "0 180 0",
                "scale": "1.6 2 1"
              },
              {
                "position": "0.930 0.000 -5.470",
                "rotation": "0 180 0",
                "scale": "1.6 2 1"
              }
            ]
          }
        ]
      }
});