define({
    "WorkFlow_Status_Template": {
      "id": "995e894a-af05-447e-ab25-16082301c904",
      "name": "spf-provide-cfs-design",
      "workflowStatus": "SUCCESS",
      "startTime": "2018-04-16, 06:24:20.452",
      "endTime": "2018-04-16, 06:25:17.875",
      "duration": "0 hrs 0 min 57 secs 423 msc",
      "owner": "spf-service-manager-service",
      "beginSteps": [
        "PreProcess-Cfs-Step"
      ],
      "steps": [
        {
          "name": "Validate-Cfs-Step",
          "stepStatus": "SUCCESS",
          "beginTasks": [
            "Validate-Cfs-Task"
          ],
          "step.next": [
            {
              "name": "Process-Cfs-Step"
            }
          ],
          "tasks": [
            {
              "name": "Validate-Cfs-Task",
              "type": "spf.cfsValidatorTaskAdapter",
              "targets": [
                {
                  "id": "Default",
                  "targetStatus": "SUCCESS",
                  "startTime": "2018-04-16, 06:24:24.651",
                  "endTime": "2018-04-16, 06:24:30.512",
                  "duration": "0 hrs 0 min 5 secs 861 msc",
                  "type": "DEFAULT",
                  "onFailure": "ROLLBACK_ON_ERROR",
                  "statusMessage": "Completed execution of Validate CFS task",
                  "userDisplayMessages": [
                    {
                      "time": "2018-04-16, 06:24:24.691",
                      "message": "Validating the service parameters"
                    },
                    {
                      "time": "2018-04-16, 06:24:30.317",
                      "message": "Successfully validated user intent"
                    }
                  ],
                  "childWorkflows": [

                  ]
                }
              ],
              "task.next": [

              ]
            }
          ]
        },
        {
          "name": "Decision-Step",
          "stepStatus": "SUCCESS",
          "beginTasks": [
            "Decision-Cfs-Task"
          ],
          "step.next": [
            {
              "name": "Cfs-Aggregator-Step"
            }
          ],
          "tasks": [

          ]
        },
        {
          "name": "Cfs-Aggregator-Step",
          "stepStatus": "SUCCESS",
          "beginTasks": [
            "Cfs-Aggregator-Task"
          ],
          "step.next": [
            {
              "name": "Cfs-Status-Update-Step"
            }
          ],
          "tasks": [
            {
              "name": "Cfs-Aggregator-Task",
              "type": "spf.cfsAggregratorTaskAdapter",
              "targets": [
                {
                  "id": "Default",
                  "targetStatus": "SUCCESS",
                  "startTime": "2018-04-16, 06:24:49.254",
                  "endTime": "2018-04-16, 06:24:49.866",
                  "duration": "0 hrs 0 min 0 secs 612 msc",
                  "type": "DEFAULT",
                  "onFailure": "ROLLBACK_ON_ERROR",
                  "statusMessage": "Completed execution of Aggregate CFS task",
                  "childWorkflows": [
                    {
                      "id": "b8506ea2-3e23-44eb-92e7-32d75af98923",
                      "name": "spf-provide-rfs-design",
                      "workflowStatus": "SUCCESS",
                      "startTime": "2018-04-16, 06:24:49.735",
                      "endTime": "2018-04-16, 06:25:15.937",
                      "duration": "0 hrs 0 min 26 secs 202 msc",
                      "owner": "spf-service-manager-service",
                      "parentTask": "Cfs-Aggregator-Task",
                      "parentWorkflow": "995e894a-af05-447e-ab25-16082301c904",
                      "beginSteps": [
                        "Provide-Rfs-Step-1"
                      ],
                      "steps": [
                        {
                          "name": "Provide-Rfs-Step-1",
                          "stepStatus": "SUCCESS",
                          "beginTasks": [

                          ],
                          "step.next": [
                            {
                              "name": "Provide-Rfs-Step-2"
                            }
                          ],
                          "tasks": [

                          ]
                        },
                        {
                          "name": "Provide-Rfs-Step-2",
                          "stepStatus": "SUCCESS",
                          "beginTasks": [
                            "Translate-Rfs-Task"
                          ],
                          "step.next": [
                            {
                              "name": "Provide-Rfs-Step-3"
                            }
                          ],
                          "tasks": [
                            {
                              "name": "Deploy-Rfs-Task",
                              "type": "spf.rfsDeployerTaskAdapter",
                              "targets": [
                                {
                                  "id": "08b82c04-9cf3-431c-940b-4d4f79651a41",
                                  "targetStatus": "SUCCESS",
                                  "startTime": "2018-04-16, 06:24:50.040",
                                  "endTime": "2018-04-16, 06:25:15.042",
                                  "duration": "0 hrs 0 min 25 secs 002 msc",
                                  "type": "MANAGED_DEVICE_UUID",
                                  "onFailure": "CONTINUE_ON_ERROR",
                                  "statusMessage": "DeployRFS execution status is SUCCESS for cfs 47b7a98e-0857-452f-acbb-e895f8b1a9e5 in target 08b82c04-9cf3-431c-940b-4d4f79651a41",
                                  "userDisplayMessages": [
                                    {
                                      "time": "2018-04-16, 06:24:50.068",
                                      "message": "Deploying configuration onto the device Cat9k-4.cisco.com (12.25.15.56)"
                                    }
                                  ],
                                  "childWorkflows": [

                                  ]
                                }
                              ],
                              "task.next": [
                                {
                                  "name": "Rfs-Status-Updater-Task"
                                }
                              ]
                            },
                            {
                              "name": "Translate-Rfs-Task",
                              "type": "spf.rfsTranslatorTaskAdapter",
                              "targets": [
                                {
                                  "id": "08b82c04-9cf3-431c-940b-4d4f79651a41",
                                  "targetStatus": "SUCCESS",
                                  "startTime": "2018-04-16, 06:24:49.789",
                                  "endTime": "2018-04-16, 06:24:49.998",
                                  "duration": "0 hrs 0 min 0 secs 209 msc",
                                  "type": "MANAGED_DEVICE_UUID",
                                  "onFailure": "CONTINUE_ON_ERROR",
                                  "statusMessage": "Completed execution of RFS Translator task",
                                  "userDisplayMessages": [
                                    {
                                      "time": "2018-04-16, 06:24:49.820",
                                      "message": "Modularizing network intent"
                                    },
                                    {
                                      "time": "2018-04-16, 06:24:49.895",
                                      "message": "Completed execution of RFS Translator task"
                                    }
                                  ],
                                  "childWorkflows": [

                                  ]
                                }
                              ],
                              "task.next": [
                                {
                                  "name": "Deploy-Rfs-Task"
                                }
                              ]
                            }
                          ]
                        },
                        {
                          "name": "Provide-Rfs-Step-Default",
                          "stepStatus": "SUCCESS",
                          "beginTasks": [

                          ],
                          "step.next": [
                            {
                              "name": "Rfs-Merge-Step"
                            }
                          ],
                          "tasks": [

                          ]
                        },
                        {
                          "name": "Rfs-Merge-Step",
                          "stepStatus": "SUCCESS",
                          "beginTasks": [
                            "Rfs-Merge-Task"
                          ],
                          "step.next": [

                          ],
                          "tasks": [

                          ]
                        },
                        {
                          "name": "Provide-Rfs-Step-3",
                          "stepStatus": "SUCCESS",
                          "beginTasks": [

                          ],
                          "step.next": [
                            {
                              "name": "Provide-Rfs-Step-Default"
                            }
                          ],
                          "tasks": [

                          ]
                        }
                      ]
                    }
                  ]
                }
              ],
              "task.next": [

              ],
              "workflows": [
                {
                  "id": "b8506ea2-3e23-44eb-92e7-32d75af98923",
                  "name": "spf-provide-rfs-design",
                  "workflowStatus": "SUCCESS",
                  "owner": null
                }
              ]
            }
          ]
        },
        {
          "name": "Translate-Cfs-Step",
          "stepStatus": "SUCCESS",
          "beginTasks": [
            "Translate-Cfs-Task"
          ],
          "step.next": [
            {
              "name": "Decision-Step"
            }
          ],
          "tasks": [
            {
              "name": "Translate-Cfs-Task",
              "type": "spf.cfsTranslatorTaskAdapter",
              "targets": [
                {
                  "id": "Default",
                  "targetStatus": "SUCCESS",
                  "startTime": "2018-04-16, 06:24:33.839",
                  "endTime": "2018-04-16, 06:24:48.834",
                  "duration": "0 hrs 0 min 14 secs 995 msc",
                  "type": "DEFAULT",
                  "onFailure": "ROLLBACK_ON_ERROR",
                  "statusMessage": "Completed execution of Translate CFS task",
                  "userDisplayMessages": [
                    {
                      "time": "2018-04-16, 06:24:48.527",
                      "message": "Completed the translation of user intent to device intent"
                    },
                    {
                      "time": "2018-04-16, 06:24:33.865",
                      "message": "Translating user intent to device intent"
                    }
                  ],
                  "childWorkflows": [

                  ]
                }
              ],
              "task.next": [

              ]
            }
          ]
        },
        {
          "name": "PreProcess-Cfs-Step",
          "stepStatus": "SUCCESS",
          "beginTasks": [
            "PreProcess-Cfs-Task"
          ],
          "step.next": [
            {
              "name": "Validate-Cfs-Step"
            }
          ],
          "tasks": [

          ]
        },
        {
          "name": "Template-Deployment-Step",
          "stepStatus": "SUCCESS",
          "beginTasks": [
            "Prepare-Template-Deployment-Data-Task"
          ],
          "step.next": [

          ],
          "tasks": [
            {
              "name": "System-Defined-Template-Deployment-Task",
              "type": "tp.triggerTemplateDeployment",
              "targets": [
                {
                  "id": "Default",
                  "targetStatus": "SUCCESS",
                  "startTime": "2018-04-16, 06:25:16.733",
                  "endTime": "2018-04-16, 06:25:17.135",
                  "duration": "0 hrs 0 min 0 secs 402 msc",
                  "type": "DEFAULT",
                  "onFailure": "CONTINUE_ON_ERROR",
                  "statusMessage": "Child Workflow :CLIProvisioningWorkflowDesign Successfully Triggered for the template deployment",
                  "childWorkflows": [
                    {
                      "id": "285833c5-464e-49e9-a01f-da77b2f11ea8",
                      "name": "CLIProvisioningWorkflowDesign",
                      "workflowStatus": "SUCCESS",
                      "startTime": "2018-04-16, 06:25:17.083",
                      "endTime": "2018-04-16, 06:25:17.747",
                      "duration": "0 hrs 0 min 0 secs 664 msc",
                      "owner": "template-programmer-service",
                      "parentTask": "System-Defined-Template-Deployment-Task",
                      "parentWorkflow": "995e894a-af05-447e-ab25-16082301c904",
                      "beginSteps": [
                        "CLIProvisioningStep"
                      ],
                      "steps": [
                        {
                          "name": "CLIProvisioningStep",
                          "stepStatus": "SUCCESS",
                          "beginTasks": [
                            "CLIProvisioningTask"
                          ],
                          "step.next": [

                          ],
                          "tasks": [

                          ]
                        }
                      ]
                    }
                  ]
                }
              ],
              "task.next": [
                {
                  "name": "User-Defined-Template-Deployment-Task"
                }
              ],
              "workflows": [
                {
                  "id": "285833c5-464e-49e9-a01f-da77b2f11ea8",
                  "name": "CLIProvisioningWorkflowDesign",
                  "workflowStatus": "SUCCESS",
                  "owner": null
                }
              ]
            }
          ]
        },
        {
          "name": "Resolve-Target-Cfs-Step",
          "stepStatus": "SUCCESS",
          "beginTasks": [
            "Resolve-Target-Cfs-Task"
          ],
          "step.next": [
            {
              "name": "Translate-Cfs-Step"
            }
          ],
          "tasks": [

          ]
        },
        {
          "name": "Process-Cfs-Step",
          "stepStatus": "SUCCESS",
          "beginTasks": [
            "Process-Cfs-Task"
          ],
          "step.next": [
            {
              "name": "PostProcess-Cfs-Step"
            }
          ],
          "tasks": [
            {
              "name": "Process-Cfs-Task",
              "type": "spf.cfsProcessorTaskAdapter",
              "targets": [
                {
                  "id": "Default",
                  "targetStatus": "SUCCESS",
                  "startTime": "2018-04-16, 06:24:30.643",
                  "endTime": "2018-04-16, 06:24:32.441",
                  "duration": "0 hrs 0 min 1 secs 798 msc",
                  "type": "DEFAULT",
                  "onFailure": "ROLLBACK_ON_ERROR",
                  "statusMessage": "Completed execution of Process CFS task",
                  "userDisplayMessages": [
                    {
                      "time": "2018-04-16, 06:24:30.674",
                      "message": "Processing the service parameters for persistence"
                    },
                    {
                      "time": "2018-04-16, 06:24:32.375",
                      "message": "Completed processing of user intent for persistence"
                    }
                  ],
                  "childWorkflows": [

                  ]
                }
              ],
              "task.next": [

              ]
            }
          ]
        },
        {
          "name": "PostProcess-Cfs-Step",
          "stepStatus": "SUCCESS",
          "beginTasks": [
            "PostProcess-Cfs-Task"
          ],
          "step.next": [
            {
              "name": "Resolve-Target-Cfs-Step"
            }
          ],
          "tasks": [

          ]
        },
        {
          "name": "Cfs-Status-Update-Step",
          "stepStatus": "SUCCESS",
          "beginTasks": [
            "Cfs-Status-Update-Task"
          ],
          "step.next": [
            {
              "name": "Template-Deployment-Step"
            }
          ],
          "tasks": [

          ]
        }
      ]
    }
});