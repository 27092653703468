
// Cisco 5520 Series Wireless Controllers
define({
    "runningImageList": [
      {
        "name": "AIR-CT5520-K9-8-10-130-0.aes",
        "imageUuid": "ae0d146b-9999-4d74-ab4f-c73d51134ea5",
        "version": "8.10.130.0",
        "softwareType": null,
        "imageType": "WLC_RELEASE",
        "isTaggedGolden": "true",
        "md5Checksum": "44a8abc010a6294a5a2d28a034cfbfe3",
        "shaCheckSum": "360125892ec9adfdbdcd843ae3e7b7447a238d6e0bf3019dde21f55228dd2e98f1a8f8a3567c353347e4c4c1aabed2a45ce00a41248fd7453d530bea1101602a",
        "createdTime": "2020-10-16 14:46:14.00",
        "applicationType": null,
        "feature": null,
        "fileServiceId": "d15d786c-3434-43e8-aa32-f2619cebe212",
        "imageSeries": null,
        "imageSource": "CCO",
        "imageSourceType": "Imported from CCO",
        "imageFamily": "AIR-CT5520",
        "vendor": null,
        "filesize": 518178942,
        "deviceCount": 0,
        "deviceUuidList": [],
        "smuCount": 0,
        "imageAttributes": [],
        "isSMUApplicable": false,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": false,
        "reasonForNotSupportingImport": "Import from Cisco 5520 Series Wireless Controllers is not supported. Please import from url or local file.",
        "tagList": [
          {
            "taggedGolden": true,
            "role": "ALL",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "CORE",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "DISTRIBUTION",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "BORDER ROUTER",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "UNKNOWN",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "ACCESS",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": null,
        "imageImported": false,
        "applicableDevicesForImage": null,
        "ccorecommended": true,
        "ccolatest": true
      },
      {
        "name": "AIR-CT5520-K9-8-10-112-0.aes",
        "imageUuid": "ae0d146b-73bb-4d74-ab4f-c73d51134ea5",
        "version": "8.10.112.0",
        "softwareType": null,
        "imageType": "WLC_RELEASE",
        "isTaggedGolden": "false",
        "md5Checksum": "0a75f01304d4b812a1b1d4adbcc80dc6",
        "shaCheckSum": "d8390a490af06d08a78a6ad26d9fd21b05eefb6f6843a2fd18cba151db375a22dff0ccff1f9efaf024e529ce44d72edaa1b2e7d75dc5362e23dc90c1a8bc49a7",
        "createdTime": "2020-02-01 10:12:14.00",
        "applicationType": null,
        "feature": null,
        "fileServiceId": "d15d786c-1256-43e8-aa32-f2619cebe212",
        "imageSeries": null,
        "imageSource": "CCO",
        "imageSourceType": "Imported from CCO",
        "imageFamily": "AIR-CT5520",
        "vendor": null,
        "filesize": 315476858 ,
        "deviceCount": 0,
        "deviceUuidList": [],
        "smuCount": 0,
        "imageAttributes": [],
        "isSMUApplicable": false,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": false,
        "reasonForNotSupportingImport": "Import from Cisco 5520 Series Wireless Controllers is not supported. Please import from url or local file.",
        "tagList": [
          {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "CORE",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "DISTRIBUTION",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "BORDER ROUTER",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "UNKNOWN",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "ACCESS",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": null,
        "imageImported": false,
        "applicableDevicesForImage": null,
        "ccorecommended": false,
        "ccolatest": false
      },
      {
          "name": "Wireless Controller (8.8.104.83)",
          "imageUuid": "ae0d146b-73bb-4d74-ab4f-c73d51134ea5",
          "version": "8.8.104.83",
          "softwareType": null,
          "imageType": "WLC_RELEASE",
          "isTaggedGolden": "false",
          "md5Checksum": null,
          "shaCheckSum": null,
          "createdTime": null,
          "applicationType": null,
          "feature": null,
          "fileServiceId": "d15d786c-1256-43e8-aa32-f2619cebe212",
          "imageSeries": null,
          "imageSource": "CCO",
          "imageSourceType": "Imported from CCO",
          "imageFamily": "AIR-CT5520",
          "vendor": null,
          "filesize": 315476858,
          "deviceCount": 0,
          "deviceUuidList": [],
          "smuCount": 0,
          "imageAttributes": [],
          "isSMUApplicable": false,
          "goldenTagInheritedFromSiteUuid": null,
          "goldenTagInheritedFromSiteName": null,
          "importSupported": false,
          "reasonForNotSupportingImport": "Import from Cisco 5520 Series Wireless Controllers is not supported. Please import from url or local file.",
          "tagList": [
            {
              "taggedGolden": false,
              "role": "ALL",
              "inheritedSiteName": null,
              "inheritedSiteUuid": null
            },
            {
              "taggedGolden": false,
              "role": "CORE",
              "inheritedSiteName": null,
              "inheritedSiteUuid": null
            },
            {
              "taggedGolden": false,
              "role": "DISTRIBUTION",
              "inheritedSiteName": null,
              "inheritedSiteUuid": null
            },
            {
              "taggedGolden": false,
              "role": "BORDER ROUTER",
              "inheritedSiteName": null,
              "inheritedSiteUuid": null
            },
            {
              "taggedGolden": false,
              "role": "UNKNOWN",
              "inheritedSiteName": null,
              "inheritedSiteUuid": null
            },
            {
              "taggedGolden": false,
              "role": "ACCESS",
              "inheritedSiteName": null,
              "inheritedSiteUuid": null
            }
          ],
          "profileInfo": null,
          "physicallyAvailable": true,
          "assignCount": 0,
          "showUnassign": false,
          "disableUnassign": false,
          "imageCategory": "PHYSICAL",
          "imageIntegrityStatus": null,
          "imageImported": false,
          "applicableDevicesForImage": null,
          "ccorecommended": false,
          "ccolatest": false
        },
        {
          "name": "AIR-CT5520-K9-8-5-135-0.aes",
          "imageUuid": "59b7c549-d117-4355-af90-ed832da724e6",
          "version": "8.5.135.0",
          "softwareType": null,
          "imageType": "WLC_SW",
          "isTaggedGolden": "false",
          "md5Checksum": "98af211f2b56a3aa1ce065a3ace09a8b",
          "shaCheckSum": "5232ddb93c763e85bd754b3a291ab7ff257170253ccb78870a887daa290368511f93e1314f0d910dcd6e8b8b240bfaed9e74d4a1e05b1ccf8f65275d6225848a",
          "createdTime": "2018-09-26 04:25:21.735",
          "applicationType": "UNKNOWN",
          "feature": null,
          "fileServiceId": "d15d786c-1256-43e8-aa32-f2619cebe212",
          "imageSeries": null,
          "imageSource": "CCO",
          "imageSourceType": "Imported from CCO",
          "imageFamily": "AIR-CT5520",
          "vendor": "CISCO",
          "filesize": 317678498,
          "deviceCount": 0,
          "deviceUuidList": [],
          "smuCount": 0,
          "imageAttributes": [
            {
              "propertyName": "udi",
              "propertyValue": "PID: AIR-CT5520-K9 VID: V01, SN: FCH2034V4F2"
            },
            {
              "propertyName": "mdf_id",
              "propertyValue": "286284738"
            },
            {
              "propertyName": "image_guid",
              "propertyValue": "D4E28900D8017DD1F0F5C1F23F4D28A0F32CC3F9"
            },
            {
              "propertyName": "metadata_trans_id",
              "propertyValue": "143042664"
            },
            {
              "propertyName": "deviceMNEId",
              "propertyValue": "1210209"
            },
            {
              "propertyName": "encryption_software_indicator",
              "propertyValue": "N"
            },
            {
              "propertyName": "image_size",
              "propertyValue": "317678498"
            },
            {
              "propertyName": "image_description",
              "propertyValue": "Cisco 5520 Series Wireless Controllers Release 8.5 Software"
            },
            {
              "propertyName": "release_fcs_date",
              "propertyValue": "23/Jul/2018"
            }
          ],
          "isSMUApplicable": false,
          "goldenTagInheritedFromSiteUuid": null,
          "goldenTagInheritedFromSiteName": null,
          "importSupported": true,
          "reasonForNotSupportingImport": null,
          "tagList": [
            {
              "taggedGolden": false,
              "role": "ALL",
              "inheritedSiteName": "",
              "inheritedSiteUuid": ""
            },
            {
              "taggedGolden": false,
              "role": "CORE",
              "inheritedSiteName": null,
              "inheritedSiteUuid": null
            },
            {
              "taggedGolden": false,
              "role": "DISTRIBUTION",
              "inheritedSiteName": null,
              "inheritedSiteUuid": null
            },
            {
              "taggedGolden": false,
              "role": "BORDER ROUTER",
              "inheritedSiteName": null,
              "inheritedSiteUuid": null
            },
            {
              "taggedGolden": false,
              "role": "UNKNOWN",
              "inheritedSiteName": null,
              "inheritedSiteUuid": null
            },
            {
              "taggedGolden": false,
              "role": "ACCESS",
              "inheritedSiteName": null,
              "inheritedSiteUuid": null
            }
          ],
          "profileInfo": null,
          "physicallyAvailable": true,
          "assignCount": 0,
          "showUnassign": false,
          "disableUnassign": false,
          "imageCategory": "PHYSICAL",
          "imageIntegrityStatus": "",
          "imageImported": true,
          "applicableDevicesForImage": null,
          "ccorecommended": true,
          "ccolatest": true
        },
        {
          "name": "AIR-CT5520-K9-8-8-100-0.aes",
          "imageUuid": "87c96d2b-412b-4277-9fc9-08ffe910fcd9",
          "version": "8.8.100.0",
          "softwareType": null,
          "imageType": "WLC_SW",
          "isTaggedGolden": "false",
          "md5Checksum": "7953721fa3595bc6d24c9dc90fa12f48",
          "shaCheckSum": "d242b38269293271b8c21035bdca026cc314642ec6fefb189900022c5f845ba95b8f131338416175121b0eeeff761c208b242027945d2d87ee2ad919c7d2bf9d",
          "createdTime": "2018-09-26 04:25:21.735",
          "applicationType": "UNKNOWN",
          "feature": null,
          "fileServiceId": "d15d786c-1256-43e8-aa32-f2619cebe212",
          "imageSeries": null,
          "imageSource": "CCO",
          "imageSourceType": "Imported from CCO",
          "imageFamily": "AIR-CT5520",
          "vendor": "CISCO",
          "filesize": 317681278,
          "deviceCount": 0,
          "deviceUuidList": [],
          "smuCount": 0,
          "imageAttributes": [
            {
              "propertyName": "udi",
              "propertyValue": "PID: AIR-CT5520-K9 VID: V01, SN: FCH2034V4F2"
            },
            {
              "propertyName": "mdf_id",
              "propertyValue": "286284738"
            },
            {
              "propertyName": "metadata_trans_id",
              "propertyValue": "143042664"
            },
            {
              "propertyName": "image_description",
              "propertyValue": "Cisco 5520 Series Wireless Controllers Release 8.8 Software"
            },
            {
              "propertyName": "release_fcs_date",
              "propertyValue": "17/Aug/2018"
            },
            {
              "propertyName": "deviceMNEId",
              "propertyValue": "1210209"
            },
            {
              "propertyName": "encryption_software_indicator",
              "propertyValue": "N"
            },
            {
              "propertyName": "image_guid",
              "propertyValue": "3E25C3AA486EEF179C549A00DEB801F188105F7E"
            },
            {
              "propertyName": "image_size",
              "propertyValue": "317681278"
            }
          ],
          "isSMUApplicable": false,
          "goldenTagInheritedFromSiteUuid": null,
          "goldenTagInheritedFromSiteName": null,
          "importSupported": true,
          "reasonForNotSupportingImport": null,
          "tagList": [
            {
              "taggedGolden": false,
              "role": "ALL",
              "inheritedSiteName": "",
              "inheritedSiteUuid": ""
            },
            {
              "taggedGolden": false,
              "role": "CORE",
              "inheritedSiteName": null,
              "inheritedSiteUuid": null
            },
            {
              "taggedGolden": false,
              "role": "DISTRIBUTION",
              "inheritedSiteName": null,
              "inheritedSiteUuid": null
            },
            {
              "taggedGolden": false,
              "role": "BORDER ROUTER",
              "inheritedSiteName": null,
              "inheritedSiteUuid": null
            },
            {
              "taggedGolden": false,
              "role": "UNKNOWN",
              "inheritedSiteName": null,
              "inheritedSiteUuid": null
            },
            {
              "taggedGolden": false,
              "role": "ACCESS",
              "inheritedSiteName": null,
              "inheritedSiteUuid": null
            }
          ],
          "profileInfo": null,
          "physicallyAvailable": true,
          "assignCount": 0,
          "showUnassign": false,
          "disableUnassign": false,
          "imageCategory": "PHYSICAL",
          "imageIntegrityStatus": "",
          "imageImported": true,
          "applicableDevicesForImage": null,
          "ccorecommended": false,
          "ccolatest": true
        },
        {
          "name": "AIR-CT5520-K9-8-2-170-0.aes",
          "imageUuid": "bf2cb5cb-4302-40da-bbac-f87690dc2d00",
          "version": "8.2.170.0",
          "softwareType": null,
          "imageType": "WLC_SW",
          "isTaggedGolden": "false",
          "md5Checksum": "a9f475c40e96d3fdb498d04132ba9f5a",
          "shaCheckSum": "39a7ae873487e2f928b3051e328bc9801d2173e91056f9e01e51e6f27955ef74a4e5b79bf1af0b75af0683755a8cf29aa5f7520b4cf81632a28edcdcca155ec2",
          "createdTime": "2018-09-26 04:25:21.735",
          "applicationType": "UNKNOWN",
          "feature": null,
          "fileServiceId": "d15d786c-1256-43e8-aa32-f2619cebe212",
          "imageSeries": null,
          "imageSource": "CCO",
          "imageSourceType": "Imported from CCO",
          "imageFamily": "AIR-CT5520",
          "vendor": "CISCO",
          "filesize": 266988686,
          "deviceCount": 0,
          "deviceUuidList": [],
          "smuCount": 0,
          "imageAttributes": [
            {
              "propertyName": "udi",
              "propertyValue": "PID: AIR-CT5520-K9 VID: V01, SN: FCH2034V4F2"
            },
            {
              "propertyName": "mdf_id",
              "propertyValue": "286284738"
            },
            {
              "propertyName": "release_fcs_date",
              "propertyValue": "08/Jun/2018"
            },
            {
              "propertyName": "metadata_trans_id",
              "propertyValue": "143042664"
            },
            {
              "propertyName": "image_description",
              "propertyValue": "Cisco 5520 Series Wireless Controllers Release 8.2 Software"
            },
            {
              "propertyName": "deviceMNEId",
              "propertyValue": "1210209"
            },
            {
              "propertyName": "encryption_software_indicator",
              "propertyValue": "N"
            },
            {
              "propertyName": "image_guid",
              "propertyValue": "5B0E523AAEF4C2BF018092D444BF4DE2C152B2E4"
            },
            {
              "propertyName": "image_size",
              "propertyValue": "266988686"
            }
          ],
          "isSMUApplicable": false,
          "goldenTagInheritedFromSiteUuid": null,
          "goldenTagInheritedFromSiteName": null,
          "importSupported": true,
          "reasonForNotSupportingImport": null,
          "tagList": [
            {
              "taggedGolden": false,
              "role": "ALL",
              "inheritedSiteName": "",
              "inheritedSiteUuid": ""
            },
            {
              "taggedGolden": false,
              "role": "CORE",
              "inheritedSiteName": null,
              "inheritedSiteUuid": null
            },
            {
              "taggedGolden": false,
              "role": "DISTRIBUTION",
              "inheritedSiteName": null,
              "inheritedSiteUuid": null
            },
            {
              "taggedGolden": false,
              "role": "BORDER ROUTER",
              "inheritedSiteName": null,
              "inheritedSiteUuid": null
            },
            {
              "taggedGolden": false,
              "role": "UNKNOWN",
              "inheritedSiteName": null,
              "inheritedSiteUuid": null
            },
            {
              "taggedGolden": false,
              "role": "ACCESS",
              "inheritedSiteName": null,
              "inheritedSiteUuid": null
            }
          ],
          "profileInfo": null,
          "physicallyAvailable": true,
          "assignCount": 0,
          "showUnassign": false,
          "disableUnassign": false,
          "imageCategory": "PHYSICAL",
          "imageIntegrityStatus": "",
          "imageImported": true,
          "applicableDevicesForImage": null,
          "ccorecommended": false,
          "ccolatest": true
        },
        {
          "name": "AIR-CT5520-K9-8-3-143-0.aes",
          "imageUuid": "d299b3ed-8902-46e8-93a1-52e2ddae10ef",
          "version": "8.3.143.0",
          "softwareType": null,
          "imageType": "WLC_SW",
          "isTaggedGolden": "false",
          "md5Checksum": "4b9f260a7e0238ddba101dda0d904d40",
          "shaCheckSum": "9ab8953aa2e1ac2016cec0daf37cde3814c3263e0180c35249e86f3321b7067b51293576f10dc8f0fe9c533957eafec4a34b1765137f1043dc3217553ac1ce51",
          "createdTime": "2018-09-26 04:25:21.735",
          "applicationType": "UNKNOWN",
          "feature": null,
          "fileServiceId": "d15d786c-1256-43e8-aa32-f2619cebe212",
          "imageSeries": null,
          "imageSource": "CCO",
          "imageSourceType": "Imported from CCO",
          "imageFamily": "AIR-CT5520",
          "vendor": "CISCO",
          "filesize": 312917882,
          "deviceCount": 0,
          "deviceUuidList": [],
          "smuCount": 0,
          "imageAttributes": [
            {
              "propertyName": "udi",
              "propertyValue": "PID: AIR-CT5520-K9 VID: V01, SN: FCH2034V4F2"
            },
            {
              "propertyName": "mdf_id",
              "propertyValue": "286284738"
            },
            {
              "propertyName": "image_size",
              "propertyValue": "312917882"
            },
            {
              "propertyName": "metadata_trans_id",
              "propertyValue": "143042664"
            },
            {
              "propertyName": "image_guid",
              "propertyValue": "27EFB31890B0AA654B80A3BCD4ACFC276CF7CA56"
            },
            {
              "propertyName": "deviceMNEId",
              "propertyValue": "1210209"
            },
            {
              "propertyName": "encryption_software_indicator",
              "propertyValue": "N"
            },
            {
              "propertyName": "release_fcs_date",
              "propertyValue": "05/Jun/2018"
            },
            {
              "propertyName": "image_description",
              "propertyValue": "Cisco 5520 Series Wireless Controllers Release 8.3 Software"
            }
          ],
          "isSMUApplicable": false,
          "goldenTagInheritedFromSiteUuid": null,
          "goldenTagInheritedFromSiteName": null,
          "importSupported": true,
          "reasonForNotSupportingImport": null,
          "tagList": [
            {
              "taggedGolden": false,
              "role": "ALL",
              "inheritedSiteName": "",
              "inheritedSiteUuid": ""
            },
            {
              "taggedGolden": false,
              "role": "CORE",
              "inheritedSiteName": null,
              "inheritedSiteUuid": null
            },
            {
              "taggedGolden": false,
              "role": "DISTRIBUTION",
              "inheritedSiteName": null,
              "inheritedSiteUuid": null
            },
            {
              "taggedGolden": false,
              "role": "BORDER ROUTER",
              "inheritedSiteName": null,
              "inheritedSiteUuid": null
            },
            {
              "taggedGolden": false,
              "role": "UNKNOWN",
              "inheritedSiteName": null,
              "inheritedSiteUuid": null
            },
            {
              "taggedGolden": false,
              "role": "ACCESS",
              "inheritedSiteName": null,
              "inheritedSiteUuid": null
            }
          ],
          "profileInfo": null,
          "physicallyAvailable": true,
          "assignCount": 0,
          "showUnassign": false,
          "disableUnassign": false,
          "imageCategory": "PHYSICAL",
          "imageIntegrityStatus": "",
          "imageImported": true,
          "applicableDevicesForImage": null,
          "ccorecommended": true,
          "ccolatest": true
        }
    ]
});