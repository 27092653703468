define({
  "Template": {
    "version": "1.0",
    "response": {
      "id": "AWLB7TemUVFRaeYF-Qmu",
      "name": "switch_interface_input_output_errors",
      "entityType": "network_device",
      "entity": "192.168.139.157",
      "enabled": true,
      "flattened": true,
      "groupBy": "Unknown",
      "category": "Connected",
      "severity": "MEDIUM",
      "priority": "P3",
      "summary": "High input/output error on interface InterfaceUpPlaceHolder",
      "scope": "LOCALIZED",
      "groupId": "interface_io_error_high_grouping",
      "groupName": "High input/output error on Switch interfaces",
      "rootCause": "Unknown ",
      "timestamp": 1523671500000,
      "description": "High Input/Output error Rx 75.0% Tx 80.09% on interface InterfaceUpPlaceHolder",
      "suggestions": [
        {
          "message": "Use the following CLI commands to check whether the ports are error free: ",
          "steps": [
            {
              "entityId": "DeviceIdPlaceHolder",
              "description": "Check the affected interface ",
              "command": "show interface InterfaceUpPlaceHolder",
              "stepType": "command-Runner"
            },
            {
              "entityId": "DeviceIdPlaceHolder",
              "description": "Check error, oversize, undersize, underrun, overrun, collision frames on the affected interface on network device",
              "command": "show controller ethernet-controller InterfaceUpPlaceHolder",
              "stepType": "command-Runner"
            }
          ]
        },
        {
          "message": "Verify the DOM statistics on the port",
          "steps": [
            {
              "entityId": "DeviceIdPlaceHolder",
              "description": "Check transceiver detail on interface if affected interface is optical",
              "command": "show interfaces transceiver detail",
              "stepType": "command-Runner"
            }
          ]
        },
        {
          "message": "Verify if the configuration is compliant on the port.",
          "steps": [
            {
              "entityId": "DeviceIdPlaceHolder",
              "description": "Check running configuration on the affected interface on network device",
              "command": "sh run int InterfaceUpPlaceHolder",
              "stepType": "command-Runner"
            }
          ]
        },
        {
          "message": "For Layer1 errors, do the following: check the interface, check if the issue is with the patch panel, check the cable, or replace the SFP transceiver. ",
          "steps": [

          ]
        },
        {
          "message": "Verify whether it is a fabric port or an access port: If it is a fabric port, open Device 360 and check the status of other fabric ports. If it is an access port, open Application 360 and check for applicaiton that are not performing properly.",
          "steps": [

          ]
        },
        {
          "message": "If none of the above actions resolve the issue, create a tech support file, and then contact Cisco TAC.",
          "steps": [

          ]
        }
      ],
      "additionalParams": [
        {
          "key": "managementIpAddr",
          "value": "192.168.139.157"
        },
        {
          "key": "entityType",
          "value": "network_device"
        },
        {
          "key": "__entity_type__",
          "value": "NetworkDevice"
        },
        {
          "key": "entityId",
          "value": "192.168.139.157"
        },
        {
          "key": "txErrorPercent",
          "value": "80.087821489610973"
        },
        {
          "key": "threshold",
          "value": "1"
        },
        {
          "key": "rxErrorPercent",
          "value": "0.0"
        },
        {
          "key": "type",
          "value": "issue"
        },
        {
          "key": "tenantid",
          "value": "TNT0"
        },
        {
          "key": "interfaceName",
          "value": "GigabitEthernet1/1/1"
        },
        {
          "key": "category",
          "value": "connected"
        },
        {
          "key": "status",
          "value": "active"
        },
        {
          "key": "timestring",
          "value": "2018-04-14T02:05:00.000+0000"
        }
      ],
      "status": {
        "status": "ACTIVE",
        "updatedBy": "Unknown",
        "notes": "Unknown",
        "source": "Unknown",
        "updatedAt": null,
        "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
      },
      "siteQulaifiedName": null
    }
  }
})